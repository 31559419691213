/* eslint-disable react/no-access-state-in-setstate */
import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Tree } from 'antd';
import ReactDragListView from 'react-drag-listview';
import { compose } from 'redux';
// style
import './ProductCategoryPageStyle.scss';
// component
import {
  TextInput,
  ButtonMain,
  ButtonIconMain,
  LabelStatusMain,
  ModalAlertConfirmation,
  SnackBarSimple,
  AuthenticationAccessPages,
  SkeletonPagination
} from '../../../../components/Index';
// Helper
import { ProductCategoryHelper, GlobalCodeStatus, CommonHelper, PermissionModule, PermissionPage, PermissionAccess } from '../../../../helpers/Index';
// Api
import {
  getListProductCategoryPagination,
  setUpdateProductCategory,
  setDeleteProductCategory,
} from '../../../../services/api/ProductCategoryApi';

const modalAlertOption = {
  title: 'Confirmation',
  text: 'Are Your Sure want to DELETE this Category',
};

const optionToash = {
  vertical: 'top',
  horizontal: 'right',
};

let dataList = [];

class ProductCategoryPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      expandedKeys: [],
      selectedKeys: [],
      checkedKeys: [],
      gData: [],
      gDataClone: [],
      searchValue: '',
      hoverItem: '',
      isLoading: false,
      focusedParentItem: null,
      isDragAbel: false,
      isCheckAbel: false,
      autoExpandParent: false,
      toastInformation: {
        isOpen: false,
        message: '',
        snackbarType: 'warning',
      },
    };
  }

  componentDidMount() {
    this.getProductCategoryPagination();
  }

  componentWillUnmount() {}

  getProductCategoryPagination() {
    const { listProductCategoryPagination } = this.props;
    const param = {};
    this.setState({ isLoading: true }, () => {
      listProductCategoryPagination(param).then(response => {
        const data = response.data;
        // deep clone
        const tmpClone = JSON.parse(JSON.stringify(data));

        this.setState({ gData: data, gDataClone: tmpClone, isLoading: false }, () => {
          dataList = ProductCategoryHelper.generateList(data);
        });
      });
    });
  }

  setUpdateProductCategory(param = {}) {
    const { updateProductCategory } = this.props;

    updateProductCategory(param)
      .then(async response => {
        const message = response.messages;

        this.getProductCategoryPagination();
        this.processMessage(message, 'success');

        this.setState({
          isDragAbel: false,
          isCheckAbel: false,
          focusedParentItem: null,
          selectedKeys: [],
          expandedKeys: [],
          checkedKeys: [],
          autoExpandParent: false,
        });
      })
      .catch(error => {
        const message = error.data;
        this.processMessage(message.messages, 'error');
      });
  }

  setDeleteProductCategory(param = {}) {
    const { deleteProductCategory } = this.props;
    const { isCheckAbel } = this.state;

    deleteProductCategory(param)
      .then(async response => {
        const message = response.messages;

        this.getProductCategoryPagination();
        this.processMessage(message, 'success');

        this.setState({
          isCheckAbel: !isCheckAbel,
          focusedParentItem: null,
          checkedKeys: [],
        });
      })
      .catch(error => {
        const message = error.data;
        this.processMessage(message.messages, 'error');
      });
  }

  handleButtonCreate = () => {
    const { history } = this.props;
    history.push('/master-data/product-category/add');
  };

  handleChangeSearch = value => {
    const { gData } = this.state;
    let autoValue = false;

    const expandedKeys = dataList
      .map(item => {
        if (item.title.toLowerCase().indexOf(value) > -1) {
          autoValue = true;
          return ProductCategoryHelper.getParentKey(item.key, gData);
        }
        return null;
      })
      .filter((item, i, self) => item && self.indexOf(item) === i);

    this.setState({
      selectedKeys: [],
      expandedKeys,
      searchValue: value,
      autoExpandParent: autoValue,
    });
  };

  handleOnExpand = expandedKeys => {
    this.setState({
      expandedKeys,
      autoExpandParent: false,
    });
  };

  handleOnDragEnter = info => {
    this.setState({
      expandedKeys: info.expandedKeys,
    });
  };

  handleOnDrop = info => {
    let data = [...this.state.gData];
    data = ProductCategoryHelper.objectReMoved(data, info);

    this.setState({
      gData: data,
    });
  };

  handleItemButtonCreate = value => {
    const { history } = this.props;
    history.push(`/master-data/product-category/add/${value.key}`);
  };

  handleItemButtonEdit = value => {
    const { history } = this.props;
    history.push(`/master-data/product-category/edit/${value.key}`);
  };

  handleItemButtonSaveEdit = dataArray => {
    const { gData } = this.state;
    const data = ProductCategoryHelper.generateList([dataArray]);
    const highestParent = data[0];
    const indexOrder = gData.findIndex(item => item.key === highestParent.key);

    data[0].order = indexOrder + 1;

    const param = {
      data: {
        array: data,
      },
    };

    this.setUpdateProductCategory(param);
  };

  handleItemButtonSaveDelete = value => {
    const { checkedKeys } = this.state;

    if (value && checkedKeys.length > 0) {
      const param = {
        data: { category_id: checkedKeys },
      };

      this.setDeleteProductCategory(param);
    } else {
      setTimeout(() => {
        const message = 'Select at Last 1 Item';
        this.processMessage(message, 'warning');
      }, 900);
    }
  };

  handleButtonEdit = (value, data) => {
    const { isDragAbel } = this.state;
    this.setState({
      isDragAbel: !isDragAbel,
      focusedParentItem: value,
      selectedKeys: [data.key],
      expandedKeys: [data.key],
      autoExpandParent: true,
    });
  };

  handleButtonDeleted = (value, data) => {
    const { isCheckAbel } = this.state;
    this.setState({
      isCheckAbel: !isCheckAbel,
      focusedParentItem: value,
      selectedKeys: [data.key],
      expandedKeys: [data.key],
      autoExpandParent: true,
    });
  };

  handleOnSelect = (keys, index) => {
    // eslint-disable-next-line no-unused-vars
    const {
      gDataClone,
      gData,
      focusedParentItem,
      // selectedKeys,
      isCheckAbel,
      isDragAbel,
    } = this.state;

    const activeAction = isCheckAbel || isDragAbel;

    const regionKeyCheck = ProductCategoryHelper.getParentKey(keys[0], [
      gData[focusedParentItem || index],
    ]);

    if (!regionKeyCheck && keys.length !== 0) {
      if (focusedParentItem !== index) {
        this.setState({
          gData: gDataClone,
          checkedKeys: [],
          focusedParentItem: null,
          isCheckAbel: false,
          isDragAbel: false,
          selectedKeys: keys,
          expandedKeys: keys,
          autoExpandParent: true,
        });
      } else if (activeAction) {
        this.setState({
          selectedKeys: keys,
          expandedKeys: keys,
          autoExpandParent: true,
        });
      } else {
        this.setState({
          gData: gDataClone,
          checkedKeys: [],
          focusedParentItem: null,
          isCheckAbel: false,
          isDragAbel: false,
          selectedKeys: keys,
          expandedKeys: keys,
          autoExpandParent: true,
        });
      }
    } else if (regionKeyCheck && keys.length !== 0) {
      this.setState({
        selectedKeys: keys,
        expandedKeys: keys,
        autoExpandParent: true,
      });
    } else {
      this.setState({
        selectedKeys: [],
        expandedKeys: [],
        // autoExpandParent: selectedKeys.length > 0,
        autoExpandParent: false,
      });
    }
  };

  handleOnCheck = checkedKeys => {
    this.setState({ checkedKeys });
  };

  handleCloseToash = () => {
    const { toastInformation } = this.state;
    this.setState({ toastInformation: { ...toastInformation, isOpen: false } });
  };

  handleItemButtonParentCancel() {
    const { gDataClone } = this.state;
    this.setState({
      gData: gDataClone,
      isCheckAbel: false,
      isDragAbel: false,
      focusedParentItem: null,
      checkedKeys: [],
    });
  }

  handleToggleHover(keyItem) {
    this.setState({ hoverItem: keyItem });
  }

  handleToggleHoverLeave() {
    this.setState({ hoverItem: '' });
  }

  processMessage(messages, type) {
    const convertedMessage = CommonHelper.generateMessage(messages);

    const paramInformation = {
      isOpen: true,
      message: convertedMessage,
      snackbarType: type,
    };

    this.setState({
      toastInformation: paramInformation,
    });
  }

  renderFilter() {
    return (
      <Grid container justify="flex-start" className="container-filter">
        <Grid item lg={4} md={4} className="row-filter">
          <TextInput
            iconPrefix="ic-ffo-search"
            placeHolderText="Search"
            onChange={this.handleChangeSearch}
            size="md"
          />
        </Grid>
        <Grid item lg md>
          <Grid container justify="flex-end" alignItems="center">
            <Grid item lg={4} md={4}>
              <ButtonMain
                labelText="Create Category"
                onClick={this.handleButtonCreate}
                type="primary"
                size="md"
                startIcon="ic-ffo-add"
                requiredPermission={`${PermissionModule.MasterData}.${PermissionPage.ProductCategory}.${PermissionAccess.Add}`}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  renderStatus = value => {
    let renderElement = <LabelStatusMain value="Active" type="complete" />;
    if (value === GlobalCodeStatus.Deleted) {
      renderElement = <LabelStatusMain value="Deleted" type="danger" />;
    } else if (value === GlobalCodeStatus.Inactive) {
      renderElement = <LabelStatusMain value="Inactive" type="default" />;
    }
    return <div className="container-status">{renderElement}</div>;
  };

  renderCard() {
    const {
      searchValue,
      expandedKeys,
      selectedKeys,
      checkedKeys,
      autoExpandParent,
      gData,
      hoverItem,
      isDragAbel,
      isCheckAbel,
      focusedParentItem,
    } = this.state;
    let renderElement = null;

    // eslint-disable-next-line consistent-this
    const tmpThis = this;

    const dragProps = {
      onDragEnd(fromIndex, toIndex) {
        tmpThis.setState({ isLoading: true }, () => {
          const data = gData;
          const item = data.splice(fromIndex, 1)[0];
          data.splice(toIndex, 0, item);

          const converted = ProductCategoryHelper.generateListParentOrder(data);

          const param = {
            data: {
              array: converted,
            },
          };

          tmpThis.setUpdateProductCategory(param);
        });
      },
      nodeSelector: 'li',
      handleSelector: '.container-tree-item',
    };

    const loop = data =>
      data.map(item => {
        const indexSearch = item.title.toLowerCase().indexOf(searchValue);
        const beforeStr = item.title.substr(0, indexSearch);
        const afterStr = item.title.substr(indexSearch + searchValue.length);
        const visibleHover = item.key === hoverItem && !isDragAbel && !isCheckAbel;

        const textCompare = item.title.substr(indexSearch, searchValue.length);
        const displayTextNotFull = textCompare !== searchValue ? textCompare : searchValue;

        const displayText = beforeStr === '' && afterStr === '' ? item.title : displayTextNotFull;

        const renderElementText =
          indexSearch > -1 ? (
            <span>
              {beforeStr}
              <span className="site-tree-search-value">{displayText}</span>
              {afterStr}
            </span>
          ) : (
            <span>{item.title}</span>
          );

        const title = (
          <Grid
            container
            justify="space-between"
            onMouseEnter={() => this.handleToggleHover(item.key)}
            onMouseLeave={() => this.handleToggleHoverLeave()}
            className="row-tree-item"
          >
            <Grid item lg={10} md={10}>
              <span className="flex-row">
                <label
                  className={`${
                    item.parentId === 1 || item.children.length > 0 ? 'text-14 main' : 'text-12'
                  } wrapping-container`}
                >
                  {renderElementText}
                </label>
                {this.renderStatus(item.status)}
              </span>
            </Grid>
            {visibleHover ? (
              <Grid item lg={2} md={2}>
                <Grid container justify="space-between">
                  <Grid item lg={5} md={5}>
                    <ButtonMain
                      labelText="Add"
                      type="ghost"
                      size="sm"
                      onClick={() => this.handleItemButtonCreate(item)}
                    />
                  </Grid>
                  <Grid item />
                  <Grid item lg={5} md={5}>
                    <ButtonMain
                      labelText="Edit"
                      type="ghost"
                      size="sm"
                      onClick={() => this.handleItemButtonEdit(item)}
                    />
                  </Grid>
                </Grid>
              </Grid>
            ) : null}
          </Grid>
        );

        if (item.children) {
          return { title, key: item.key, children: loop(item.children) };
        }

        return {
          title,
          key: item.key,
        };
      });

    if (gData.length > 0) {
      renderElement = gData.map((item, index) => {
        const ActionVisible = focusedParentItem === index;
        const ActionItemVisibleEdit = !isDragAbel && !isCheckAbel;
        const ActionItemVisibleDelete = !isDragAbel && !isCheckAbel;
        const ActionItemVisibleDrag = ActionVisible && isDragAbel;
        const ActionItemVisibleCheck = ActionVisible && isCheckAbel;

        return (
          <li key={index} className="container-tree-item">
            {/* <Grid key={index} item lg md className="container-tree-item"> */}
            <Grid container justify="space-between">
              <Grid item lg={10} md={10}>
                <Tree
                  showLine
                  draggable={ActionItemVisibleDrag}
                  checkable={ActionItemVisibleCheck}
                  blockNode
                  onCheck={this.handleOnCheck}
                  onSelect={keys => this.handleOnSelect(keys, index)}
                  onDragEnter={this.handleOnDragEnter}
                  onDrop={this.handleOnDrop}
                  onExpand={this.handleOnExpand}
                  expandedKeys={expandedKeys}
                  checkedKeys={checkedKeys}
                  selectedKeys={selectedKeys}
                  autoExpandParent={autoExpandParent}
                  treeData={loop([gData[index]])}
                />
              </Grid>
              <Grid item lg={2} md={2}>
                <Grid
                  container
                  direction="column"
                  justify="space-between"
                  className="flex-wrap-unset tree-item-action"
                >
                  <Grid item lg md>
                    <Grid container justify="flex-end">
                      <Grid item lg={6} md={6}>
                        <div>
                          <Grid container justify="space-around">
                            <Grid
                              item
                              lg
                              md
                              className={clsx({
                                'disable-content': !ActionItemVisibleEdit,
                              })}
                            >
                              <ButtonIconMain
                                icon="ic-ffo-edit"
                                type="secondary"
                                size="sm"
                                onClick={() => this.handleButtonEdit(index, item)}
                                requiredPermission={`${PermissionModule.MasterData}.${PermissionPage.ProductCategory}.${PermissionAccess.Update}`}
                              />
                            </Grid>
                            <Grid
                              item
                              lg
                              md
                              className={clsx({
                                'disable-content': !ActionItemVisibleDelete,
                              })}
                            >
                              <ButtonIconMain
                                icon="ic-ffo-bin"
                                type="negative"
                                size="sm"
                                onClick={() => this.handleButtonDeleted(index, item)}
                                requiredPermission={`${PermissionModule.MasterData}.${PermissionPage.ProductCategory}.${PermissionAccess.Delete}`}
                              />
                            </Grid>
                          </Grid>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                  {ActionVisible ? (
                    <Grid item lg md className="flex-grow-unset">
                      <Grid container justify="space-evenly" alignItems="center">
                        <Grid item lg={5} md={5}>
                          {autoExpandParent ? (
                            <ButtonMain
                              labelText="Cancel"
                              type="ghost"
                              size="sm"
                              onClick={() => this.handleItemButtonParentCancel()}
                            />
                          ) : null}
                        </Grid>
                        <Grid item />
                        <Grid item lg={5} md={5}>
                          {isDragAbel && autoExpandParent ? (
                            <ButtonMain
                              labelText="Save"
                              type="primary"
                              size="sm"
                              onClick={() => this.handleItemButtonSaveEdit(gData[index])}
                            />
                          ) : null}
                          {isCheckAbel && autoExpandParent ? (
                            <ModalAlertConfirmation
                              label="Delete"
                              onClick={this.handleItemButtonSaveDelete}
                              type="danger"
                              size="sm"
                              optionModal={modalAlertOption}
                            />
                          ) : null}
                        </Grid>
                      </Grid>
                    </Grid>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
            {/* </Grid> */}
          </li>
        );
      });
    }

    return (
      // <Grid container direction="column" className="flex-wrap-unset">
      <div className="container-presentation-list-view">
        <ReactDragListView {...dragProps}>
          <ol>{renderElement}</ol>
        </ReactDragListView>
      </div>
      // </Grid>
    );
  }

  render() {
    const { isLoading, toastInformation } = this.state;
    let renderElement = (
      <div className="mt-18">
        <SkeletonPagination />
      </div>
    );

    if (!isLoading) {
      renderElement = (
              <Grid container direction="column" className="flex-wrap-unset">
                <Grid item lg md className="section-page-header">
                  {this.renderFilter()}
                </Grid>
                <Grid item lg md className="section-page-body">
                  {this.renderCard()}
                </Grid>
                <Grid item lg md className="section-page-footer" />
              </Grid>
      );
    }

    return (
      <div>
        <Helmet title="FITCO | Master Data - Product Category" />
        <div className="container-page-product-category scroll-container">
          <div className="container-page-scrolling-area">
            {renderElement}
          </div>
        </div>
        <SnackBarSimple
          open={toastInformation.isOpen}
          durationHide={2000}
          message={toastInformation.message}
          onClickClose={this.handleCloseToash}
          snackbarType={toastInformation.snackbarType}
          anchor={optionToash}
        />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  listProductCategoryPagination: params => getListProductCategoryPagination(dispatch, params),
  updateProductCategory: params => setUpdateProductCategory(dispatch, params),
  deleteProductCategory: params => setDeleteProductCategory(dispatch, params),
});

const mapStateToProps = ({ masterDataMain }) => ({ masterDataMain });

ProductCategoryPage.propTypes = {
  deleteProductCategory: PropTypes.func,
  history: PropTypes.object,
  listProductCategoryPagination: PropTypes.func,
  masterDataMain: PropTypes.object,
  updateProductCategory: PropTypes.func,
};

const shell = compose(connect(mapStateToProps, mapDispatchToProps));

export default shell(AuthenticationAccessPages(ProductCategoryPage));
