import React from 'react';
import { Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

const SkeletonDetails = () => {
  return (
    <Grid container spacing={2} direction="column" className="flex-wrap-unset pt-24">
      <Grid item lg md>
        <Grid container spacing={2} justify="flex-start">
          <Grid item xl={1} lg={2} md={2}>
            <Skeleton
              variant="rect"
              width="100%"
              height={20}
              animation="wave"
              className="skeletonRounded"
            />
          </Grid>
          <Grid item>/</Grid>
          <Grid item xl={2} lg={3} md={3}>
            <Skeleton
              variant="rect"
              width="100%"
              height={20}
              animation="wave"
              className="skeletonRounded"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg md>
        <div className="container-main-card p-16">
          <Grid container spacing={3}>
            <Grid item lg={3} md={3}>
              <Skeleton
                variant="rect"
                width="100%"
                height={215}
                animation="wave"
                className="skeletonRounded"
              />
            </Grid>
            <Grid item lg={9} md={9}>
              <Grid container spacing={2}>
                <Grid item lg={3} md={3}>
                  <Skeleton
                    variant="rect"
                    width="100%"
                    height={30}
                    animation="wave"
                    className="skeletonRounded"
                  />
                </Grid>
                <Grid item lg={9} md={9}>
                  <Skeleton
                    variant="rect"
                    width="100%"
                    height={30}
                    animation="wave"
                    className="skeletonRounded"
                  />
                </Grid>
                <Grid item lg={12} md={12}>
                  <Skeleton
                    variant="rect"
                    width="100%"
                    height={30}
                    animation="wave"
                    className="skeletonRounded"
                  />
                </Grid>
                <Grid item lg={4} md={4}>
                  <Skeleton
                    variant="rect"
                    width="100%"
                    height={30}
                    animation="wave"
                    className="skeletonRounded"
                  />
                </Grid>
                <Grid item lg={4} md={4}>
                  <Skeleton
                    variant="rect"
                    width="100%"
                    height={30}
                    animation="wave"
                    className="skeletonRounded"
                  />
                </Grid>
                <Grid item lg={4} md={4}>
                  <Skeleton
                    variant="rect"
                    width="100%"
                    height={30}
                    animation="wave"
                    className="skeletonRounded"
                  />
                </Grid>
                <Grid item lg={2} md={2}>
                  <Skeleton
                    variant="rect"
                    width="100%"
                    height={30}
                    animation="wave"
                    className="skeletonRounded"
                  />
                </Grid>
                <Grid item lg={10} md={10}>
                  <Skeleton
                    variant="rect"
                    width="100%"
                    height={30}
                    animation="wave"
                    className="skeletonRounded"
                  />
                </Grid>
                <Grid item lg={10} md={10}>
                  <Skeleton
                    variant="rect"
                    width="100%"
                    height={30}
                    animation="wave"
                    className="skeletonRounded"
                  />
                </Grid>
                <Grid item lg={2} md={2}>
                  <Skeleton
                    variant="rect"
                    width="100%"
                    height={30}
                    animation="wave"
                    className="skeletonRounded"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={12} md={12}>
              <Skeleton
                variant="rect"
                width="100%"
                height={30}
                animation="wave"
                className="skeletonRounded"
              />
            </Grid>
            <Grid item lg={8} md={8}>
              <Skeleton
                variant="rect"
                width="100%"
                height={30}
                animation="wave"
                className="skeletonRounded"
              />
            </Grid>
            <Grid item lg={4} md={4}>
              <Skeleton
                variant="rect"
                width="100%"
                height={30}
                animation="wave"
                className="skeletonRounded"
              />
            </Grid>
            <Grid item lg={6} md={6}>
              <Skeleton
                variant="rect"
                width="100%"
                height={30}
                animation="wave"
                className="skeletonRounded"
              />
            </Grid>
            <Grid item lg={3} md={3}>
              <Skeleton
                variant="rect"
                width="100%"
                height={30}
                animation="wave"
                className="skeletonRounded"
              />
            </Grid>
            <Grid item lg={3} md={3}>
              <Skeleton
                variant="rect"
                width="100%"
                height={30}
                animation="wave"
                className="skeletonRounded"
              />
            </Grid>
            <Grid item lg={3} md={3}>
              <Skeleton
                variant="rect"
                width="100%"
                height={30}
                animation="wave"
                className="skeletonRounded"
              />
            </Grid>
            <Grid item lg={9} md={9}>
              <Skeleton
                variant="rect"
                width="100%"
                height={30}
                animation="wave"
                className="skeletonRounded"
              />
            </Grid>
            <Grid item lg={12} md={12}>
              <Skeleton
                variant="rect"
                width="100%"
                height={30}
                animation="wave"
                className="skeletonRounded"
              />
            </Grid>
            <Grid item lg={12} md={12}>
              <Skeleton
                variant="rect"
                width="100%"
                height={30}
                animation="wave"
                className="skeletonRounded"
              />
            </Grid>
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
};

export default SkeletonDetails;
