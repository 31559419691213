import React from 'react';
import PropTypes from 'prop-types';
import { Grid, FormControl, FormLabel } from '@material-ui/core';
import _ from 'lodash';
// Style
import './CartItemBookingStyle.scss';
// Component
import {
  GridRowTableFourColumn,
  GridRowTableOneColumn,
  LabelStatusMain,
} from '../../../../../../../../components/Index';
// helper
import { CommonHelper, promoTypeCode, OrderHelper } from '../../../../../../../../helpers/Index';
// assets
import { Images } from '../../../../../../../../assets/Index';

const CartItemBooking = props => {
  const {
    form,
    isValidPromoCode,
    orderData: { promoCodeDetails },
    adminFee,
  } = props;
  const promoCode = form.promoCode;
  const isMembershipSpecial = form.isMembershipSpecial;
  const items = form.items;

  // discount
  const promoCodeInformation = `Promo Code ( ${promoCode} )`;
  let totalItem = 0;
  let grandTotal = OrderHelper.getGrandTotal(items);

  let discountAmount = 0;

  if (isValidPromoCode) {
    grandTotal = promoCodeDetails.totalPriceAfterReward;
    discountAmount = promoCodeDetails.cashback_amount;
  }

  if (!_.isEmpty(adminFee)) {
    grandTotal = +grandTotal + +adminFee.price;
  }

  const showDiscount =
    isValidPromoCode &&
    promoCodeDetails.promo_code_cashback_type_id === promoTypeCode.Discount &&
    discountAmount !== 0;
  const showAdminFee = !_.isEmpty(adminFee);

  const renderSpecialTreatment = () => {
    let passRenderElement = false;

    let wording = null;
    let type = null;
    let icon = null;

    if (!_.isEmpty(isMembershipSpecial)) {
      icon = Images.membershipUsage;
      wording = 'Membership Package Applied';
      type = 'membership';
      passRenderElement = true;
    }

    return passRenderElement ? (
      <span className={`container-special-label flex-row p-8 ${type}`}>
        <img src={icon} alt="membership_logo" />
        <label className="text-14 text-semi-bold ml-8">{wording}</label>
      </span>
    ) : null;
  };

  return (
    <FormControl
      component="fieldset"
      fullWidth
      margin="normal"
      className="container-booking-order-cart-item"
    >
      <FormLabel component="label" className="text-16 filed-title">
        Order Summary
      </FormLabel>

      <div className="flex-column">
        {renderSpecialTreatment()}
        <GridRowTableFourColumn
          customElementColumnFirst={<label className="text-14 header-title">Product Name</label>}
          customElementColumnSecond={
            <div className="text-center">
              <label className="text-14 header-title">Qty</label>
            </div>
          }
          customElementColumnThird={
            <div className="text-right">
              <label className="text-14 header-title">Total Price</label>
            </div>
          }
          columnFirst={7}
          columnSecond={2}
          columnThird={3}
        />
        <div className="scroll-container">
          <Grid container direction="column">
            {items.map((item, index) => {
              totalItem += item.quantity;
              const price = item.price || item.item_price;
              const priceTotal = item.quantity * parseInt(price, 10);

              return (
                <GridRowTableFourColumn
                  key={index}
                  customElementColumnFirst={
                    <div className="flex-column">
                      <div className="flex-row">
                        <label className="text-14 text-bold product-title mr-12">
                          {item.name || item.product_name}
                        </label>
                        {(item.type === 'point_booster' || item.product_type === 'point_booster') &&
                          priceTotal === 0 && (
                            <LabelStatusMain value={'Membership Applied'} type="complete" />
                          )}
                      </div>
                      <label className="text-14 text-rolling-stone mt-4">
                        {item.type === 'point_booster' || item.product_type === 'point_booster'
                          ? 'Membership'
                          : 'Single Session'}
                      </label>
                    </div>
                  }
                  customElementColumnSecond={
                    <div className="text-center">
                      <label className="text-14">{item.quantity}</label>
                    </div>
                  }
                  customElementColumnThird={
                    <div className="text-right">
                      <label className="text-14">
                        {priceTotal > 0 ? `IDR ${CommonHelper.formatCurrency(priceTotal)}` : 'Free'}
                      </label>
                    </div>
                  }
                  columnFirst={7}
                  columnSecond={2}
                  columnThird={3}
                />
              );
            })}
          </Grid>
        </div>
        {showDiscount && (
          <GridRowTableOneColumn
            customElementInitial={
              <div className="flex-column">
                <label className="text-14 row-item-title">Discount</label>
                <label className="text-10 ">{promoCodeInformation}</label>
              </div>
            }
            customElementColumn={
              <div className="text-right">
                <label className="text-14 discount">
                  IDR {CommonHelper.formatCurrency(discountAmount)}
                </label>
              </div>
            }
            columnLeft={10}
            columnRight={2}
          />
        )}
        {showAdminFee && (
          <GridRowTableOneColumn
            customElementInitial={
              <div className="flex-column">
                <label className="text-14 row-item-title">Admin Fee</label>
                <label className="text-10 ">{adminFee.name}</label>
              </div>
            }
            customElementColumn={
              <div className="text-right">
                <label className="text-14">IDR {CommonHelper.formatCurrency(adminFee.price)}</label>
              </div>
            }
            columnLeft={10}
            columnRight={2}
          />
        )}
        <GridRowTableOneColumn
          customElementInitial={
            <label className="text-16 row-item-title">Total Item(s) : {totalItem}</label>
          }
          customElementColumn={
            <div className="text-right">
              <label className="text-16 row-item-title">
                Grand Total &nbsp; &nbsp; &nbsp;{' '}
                {grandTotal !== 0 ? `IDR ${CommonHelper.formatCurrency(grandTotal)}` : 'Free'}
              </label>
            </div>
          }
          columnLeft={7}
          columnRight={5}
        />
      </div>
    </FormControl>
  );
};

CartItemBooking.propTypes = {
  adminFee: PropTypes.object,
  form: PropTypes.object,
  isValidPromoCode: PropTypes.bool,
  orderData: PropTypes.object,
};

export default CartItemBooking;
