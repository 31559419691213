import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// component
import { SelectInputSearchMain } from '../../Index';
// api
import { getClassList } from '../../../services/api/MasterDataApi';
// helper
import { CommonHelper } from '../../../helpers/Index';

class SelectInputClass extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    this.getMasterDataList();
  }

  componentDidUpdate(prevProps) {
    const { filter, onChange } = this.props;
    if (
      prevProps.filter.merchantId !== filter.merchantId ||
      prevProps.filter.branchId !== filter.branchId ||
      prevProps.filter.trainerId !== filter.trainerId ||
      prevProps.filter.category !== filter.category
    ) {
      this.getMasterDataList();
      onChange(null);
    }
  }

  componentWillUnmount() {}

  getMasterDataList() {
    const { apiDataList, filter } = this.props;

    if (filter.merchantId) {
      const param = {
        merchant_id: filter.merchantId,
        branch_id: filter.branchId,
        trainer_id: filter.trainerId,
        category: filter.category,
      };
      apiDataList(param);
    }
  }

  handleSelectItem = value => {
    const { onChange } = this.props;

    onChange(value);
  };

  generateListDataDisplay() {
    const { masterData } = this.props;
    const converted = [];
    const currentData = CommonHelper.renameKeyName(masterData.classList, 'product_id', 'value');

    let param = { value: null, name: 'Select Class / Product' };
    converted.push(param);

    if (currentData.length > 0) {
      currentData.forEach(item => {
        param = { value: item.value, name: item.name };
        converted.push(param);
      });
    }

    return converted;
  }

  render() {
    const {
      placeHolder,
      currentValue,
      defaultValue,
      errorMessage,
      validateStatus,
      size,
    } = this.props;

    const ListCustom = this.generateListDataDisplay();

    return (
      <SelectInputSearchMain
        options={ListCustom}
        onChange={item => {
          this.handleSelectItem(item);
        }}
        currentValue={currentValue}
        defaultValue={defaultValue}
        errorMessage={errorMessage}
        validateStatus={validateStatus}
        size={size}
        placeholder={placeHolder}
      />
    );
  }
}

const mapDispatchToProps = dispatch => ({
  apiDataList: params => getClassList(dispatch, params),
});

const mapStateToProps = ({ masterData }) => ({
  masterData,
});

SelectInputClass.propTypes = {
  apiDataList: PropTypes.func,
  currentValue: PropTypes.number,
  defaultValue: PropTypes.number,
  errorMessage: PropTypes.string,
  filter: PropTypes.object,
  masterData: PropTypes.object,
  onChange: PropTypes.func,
  placeHolder: PropTypes.string,
  size: PropTypes.string,
  validateStatus: PropTypes.string,
};

SelectInputClass.defaultProps = {
  placeHolder: '',
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectInputClass);
