import React from 'react';
import PropTypes from 'prop-types';
import { Grid, FormControl } from '@material-ui/core';
// Style
import './CartItemEatAtStoreStyle.scss';
// Component
import {
  GridRowTableFourColumn,
  GridRowTableOneColumn,
} from '../../../../../../../components/Index';
// helper
import {
  CommonHelper,
  OrderHelper,
  promoTypeCode,
  paymentMethodOptionCode,
} from '../../../../../../../helpers/Index';

const CartItemEatAtStore = props => {
  const {
    form: { items, deliveryServiceInfo, promoCode, paymentMethod, isUseAnonymous, isStaffCheckout },
    isValidPromoCode,
    orderData: { promoCodeDetails },
    onHandleMessages,
    orderTaxPercentage,
    orderServiceChargePercentage,
  } = props;

  // const shippingCost = CommonHelper.formatCurrency(deliveryServiceInfo.cost[0].value);
  // discount
  const promoCodeInformation = `Promo - ${promoCode}`;
  const totalItem = OrderHelper.getGrandTotalWeightAndFitMarket(items).item;
  let grandTotal = OrderHelper.getGrandTotalAtStore(
    items,
    paymentMethod,
    isUseAnonymous,
    isStaffCheckout,
  );
  const discountAmount = promoCodeDetails.cashback_amount_desc;

  if (isValidPromoCode) {
    grandTotal = promoCodeDetails.totalPriceAfterReward + deliveryServiceInfo.cost[0].value;
  }

  const showDiscount =
    isValidPromoCode && promoCodeDetails.promo_code_cashback_type_id === promoTypeCode.Discount;

  const isOnDemandDelivery =
    paymentMethod === paymentMethodOptionCode.gofood ||
    paymentMethod === paymentMethodOptionCode.grabfood ||
    paymentMethod === paymentMethodOptionCode.shopeefood;

  const taxAmount = isOnDemandDelivery ? 0 : orderTaxPercentage * grandTotal;
  const serviceChargeAmount = isOnDemandDelivery
    ? 0
    : orderServiceChargePercentage * (grandTotal + taxAmount);

  grandTotal = CommonHelper.formatCurrency(grandTotal + taxAmount + serviceChargeAmount);

  const showPopup = paymentType => {
    onHandleMessages(
      `One or more items does not have ${paymentType} price available. Please select another payment method.`,
      'warning',
      false,
    );
  };

  return (
    <FormControl
      component="fieldset"
      fullWidth
      margin="normal"
      className="container-order-cart-item"
    >
      <GridRowTableFourColumn
        customElementColumnFirst={<label className="text-14">Product</label>}
        customElementColumnSecond={<label className="text-14">Price</label>}
        customElementColumnThird={<label className="text-14">Quantity</label>}
        customElementColumnFourth={<label className="text-14">Subtotal</label>}
        columnFirst={5}
        columnSecond={2}
        columnThird={2}
        columnFourth={3}
      />
      <div className="scroll-container">
        <Grid container direction="column">
          {items.map((item, index) => {
            let priceItem = 0;
            switch (paymentMethod) {
              case paymentMethodOptionCode.gofood:
                priceItem = item.gofood_price ? item.gofood_price : showPopup('GoFood');
                break;
              case paymentMethodOptionCode.grabfood:
                priceItem = item.grabfood_price ? item.grabfood_price : showPopup('GrabFood');
                break;
              case paymentMethodOptionCode.shopeefood:
                priceItem = item.shopeefood_price ? item.shopeefood_price : showPopup('ShopeeFood');
                break;
              default:
                if (isStaffCheckout) {
                  priceItem = item.staff_price;
                } else if (isUseAnonymous) {
                  priceItem = item.normal_price;
                } else {
                  priceItem = item.price;
                }
                break;
            }

            const priceTotal = item.qty * parseInt(priceItem, 10);

            return (
              <GridRowTableFourColumn
                key={index}
                customElementColumnFirst={
                  <div className="flex-column">
                    <label className="text-14 row-item-title">
                      {item.name || item.product_name}
                    </label>
                    {item.notes && (
                      <label className="text-12 text-semi-bold">{`Meal notes: ${item.notes ||
                        ''}`}</label>
                    )}
                  </div>
                }
                customElementColumnSecond={
                  <label className="text-14">
                    IDR {CommonHelper.formatCurrency(priceItem) || '0'}
                  </label>
                }
                customElementColumnThird={<label className="text-14">{item.qty}</label>}
                customElementColumnFourth={
                  <label className="text-14">
                    IDR {CommonHelper.formatCurrency(priceTotal) || '0'}
                  </label>
                }
                columnFirst={5}
                columnSecond={2}
                columnThird={2}
                columnFourth={3}
              />
            );
          })}
        </Grid>
      </div>
      {/* <GridRowTableOneColumn
        customElementInitial={
          <div className="flex-column">
            <label className="text-14 row-item-title">Shipping Fee</label>
          </div>
        }
        customElementColumn={
          <label className="text-14">{shippingCost > 0 ? `IDR ${shippingCost}` : `Free`}</label>
        }
        columnLeft={10}
        columnRight={2}
      /> */}
      {showDiscount && (
        <GridRowTableOneColumn
          customElementInitial={
            <div className="flex-column">
              <label className="text-14 row-item-title">Discount</label>
              <label className="text-10 ">{promoCodeInformation}</label>
            </div>
          }
          customElementColumn={<label className="text-14 discount">{discountAmount}</label>}
          columnLeft={10}
          columnRight={2}
        />
      )}
      {taxAmount > 0 && (
        <GridRowTableOneColumn
          customElementInitial={
            <div className="flex-column">
              <label className="text-14 row-item-title">Tax</label>
              <label className="text-10 ">{orderTaxPercentage * 100}%</label>
            </div>
          }
          customElementColumn={
            <label className="text-14">IDR {CommonHelper.formatCurrency(taxAmount)}</label>
          }
          columnLeft={10}
          columnRight={2}
        />
      )}
      {serviceChargeAmount > 0 && (
        <GridRowTableOneColumn
          customElementInitial={
            <div className="flex-column">
              <label className="text-14 row-item-title">Service Charge</label>
              <label className="text-10 ">{orderServiceChargePercentage * 100}%</label>
            </div>
          }
          customElementColumn={
            <label className="text-14">
              IDR {CommonHelper.formatCurrency(serviceChargeAmount)}
            </label>
          }
          columnLeft={10}
          columnRight={2}
        />
      )}
      <GridRowTableOneColumn
        customElementInitial={
          <label className="text-16 row-item-title">Total Item(s) : {totalItem}</label>
        }
        customElementColumn={
          <label className="text-16 row-item-title">
            Grand Total &nbsp; &nbsp; &nbsp; IDR {grandTotal}
          </label>
        }
        columnLeft={4}
        columnRight={8}
      />
    </FormControl>
  );
};

CartItemEatAtStore.propTypes = {
  form: PropTypes.object,
  isValidPromoCode: PropTypes.bool,
  onHandleMessages: PropTypes.func,
  orderData: PropTypes.object,
  orderServiceChargePercentage: PropTypes.number,
  orderTaxPercentage: PropTypes.number,
};

export default CartItemEatAtStore;
