/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { TextField, InputAdornment, CircularProgress } from '@material-ui/core';
// style
import './TextInputStyle.scss';

const TextInput = props => {
  const {
    labelHeader,
    defaultValue,
    currentValue,
    isError,
    errorMessage,
    iconPrefix,
    iconPrefixEnd,
    onChange,
    onEnter,
    onBlur,
    placeHolderText,
    size,
    isDisable,
    isLoading,
    maxLength,
    numericOnly,
    alphabetOnly,
    type,
  } = props;

  const onChangeTextInput = event => {
    const result = event.target.value;
    onChange(result);
  };

  const onChangeTextInputBlur = event => {
    const result = event.target.value;
    onBlur(result);
  };

  const handleEnterTextInput = event => {
    if (event.key === 'Enter') {
      return onEnter ? onEnter() : null;
    }

    if (numericOnly) {
      const regex =  /^[0-9]*$/;
      const validNumber = regex.test(event.key);
      if(!validNumber) {

        event.preventDefault();
        return null;
      }
    }

    if (alphabetOnly) {
      const regex =  /^[a-zA-Z ]*$/;
      const validAlphabet = regex.test(event.key);
      if(!validAlphabet) {
        event.preventDefault();
        return null;
      }
    }

    return null;
  };

  const RenderIconEndAdornment = () => {
    let renderElement = null;

    if (iconPrefixEnd) {
      renderElement = (
        <InputAdornment position="start">
          <i className={`${iconPrefixEnd} container-icon-prefix ${size}`} />
        </InputAdornment>
      );
    } else if (isLoading) {
      renderElement = <CircularProgress className="btn-loading" size={14} />;
    }

    return renderElement;
  };

  return (
    <TextField
      className={`container-text-input ${size}`}
      label={labelHeader}
      disabled={isDisable}
      defaultValue={defaultValue}
      value={currentValue}
      helperText={errorMessage}
      placeholder={placeHolderText}
      variant="outlined"
      error={isError}
      InputProps={{
        startAdornment: iconPrefix ? (
          <InputAdornment position="start">
            <i className={`${iconPrefix} container-icon-prefix ${size}`} />
          </InputAdornment>
        ) : null,
        endAdornment: <RenderIconEndAdornment />,
      }}
      inputProps={{
        maxLength,
      }}
      type={type}
      onChange={onChange ? onChangeTextInput : null}
      onBlur={onBlur ? onChangeTextInputBlur : null}
      onKeyPress={handleEnterTextInput}
    />
  );
};

TextInput.propTypes = {
  alphabetOnly: PropTypes.bool,
  currentValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  errorMessage: PropTypes.string,
  iconPrefix: PropTypes.string,
  iconPrefixEnd: PropTypes.string,
  isDisable: PropTypes.bool,
  isError: PropTypes.bool,
  isLoading: PropTypes.bool,
  labelHeader: PropTypes.string,
  maxLength: PropTypes.number,
  numericOnly: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onEnter: PropTypes.func,
  placeHolderText: PropTypes.string,
  size: PropTypes.string,
  type: PropTypes.string,
};

TextInput.defaultProps = {
  size: 'xl',
};

export default TextInput;
