/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import _ from 'lodash';
// Components
import {
  GridRowTableOneColumn,
  UploadImage,
  ButtonIconMain,
} from '../../../../../../components/Index';
// Helpers
import { CommonHelper, MasterDataHelper } from '../../../../../../helpers/Index';
// Styles
import './CustomerItemBookingStyle.scss';

const genderOptions = MasterDataHelper.gender;

const CustomerItemBooking = ({
  customerList,
  isDraft,
  isComplete,
  onRemove,
  onSelect,
  onUpdatePhoto,
  onOpenModalImagePreview,
}) => {
  const [expandedItem, setExpandedItem] = useState(null);

  return (
    <Grid container direction="column" className="container-customer-booking-item">
      {customerList.map((customer, index) => {
        return (
          <CustomerList
            key={index.toString()}
            item={customer}
            index={index}
            expandedItem={expandedItem}
            setExpandedItem={setExpandedItem}
            onRemove={onRemove}
            onSelect={onSelect}
            onUpdatePhoto={onUpdatePhoto}
            isDraft={isDraft}
            isComplete={isComplete}
            onOpenModalImagePreview={onOpenModalImagePreview}
          />
        );
      })}
    </Grid>
  );
};

const CustomerList = ({
  item,
  index,
  expandedItem,
  setExpandedItem,
  onRemove,
  onSelect,
  onUpdatePhoto,
  isDraft,
  isComplete,
  onOpenModalImagePreview,
}) => {
  const [collapse, setCollapse] = useState(true);

  useEffect(() => {
    const isSelected = index === expandedItem;
    setCollapse(!isSelected);
  }, [expandedItem, index]);

  const onClickHandler = idx => {
    setCollapse(value => !value);
    setExpandedItem(idx !== index);
  };

  const onRemoveHandler = idx => {
    onRemove(idx);
  };

  const onSelectedDataHandler = value => {
    onSelect(value);
  };

  const onUpdatePhotoHandler = (imageBase64, idx) => {
    const datas = {
      ...item,
      identity_photo: imageBase64,
    };
    onUpdatePhoto(datas, idx);
  };

  const iconName = collapse ? 'ic-ffo-chevron-down' : 'ic-ffo-chevron-top';
  const gender = _.find(genderOptions, ['value', item.gender]);

  return (
    <Grid container className="container-row-booking-item">
      <Grid item lg={12} md={12}>
        <div className="container-row-booking-heading">
          <div
            onClick={() => onClickHandler(index)}
            role="button"
            onKeyDown={() => onClickHandler(index)}
            tabIndex={0}
            className="row-center"
          >
            <span className="header-title">
              {`#${index + 1}`} - {item.name}
            </span>
            <div>
              <i className={`${iconName} container-icon-prefix size-16`} />
            </div>
          </div>
          {index !== 0 && isDraft && (
            <ButtonIconMain
              icon="ic-ffo-bin"
              type="negative"
              size="sm"
              onClick={() => onRemoveHandler(index)}
              tooltip="Cancel"
            />
          )}
          {isComplete && (
            <ButtonIconMain
              icon="ic-ffo-edit"
              type="primary"
              size="sm"
              onClick={() => onSelectedDataHandler(item)}
              tooltip="Cancel"
            />
          )}
        </div>
      </Grid>
      {collapse ? null : (
        <Grid item lg={12} md={12} className="container-row-booking-detail">
          <Grid container spacing={3}>
            <Grid item lg={6} md={6}>
              <Grid
                container
                direction="column"
                className="container-row-booking-table details flex-wrap-unset"
              >
                <GridRowTableOneColumn
                  customElementInitial={<label className="text-14 title">Test</label>}
                  customElementColumn={<label className="text-14">{item.product_name}</label>}
                  columnLeft={5}
                  columnRight={7}
                />
                <GridRowTableOneColumn
                  customElementInitial={<label className="text-14 title">Date of Birth</label>}
                  customElementColumn={
                    <label className="text-14">
                      {CommonHelper.dateTimeParseNewFormat(item.birthday, 'DD MMM YYYY')}
                    </label>
                  }
                  columnLeft={5}
                  columnRight={7}
                />
                <GridRowTableOneColumn
                  customElementInitial={<label className="text-14 title">Gender</label>}
                  customElementColumn={<label className="text-14">{gender.name}</label>}
                  columnLeft={5}
                  columnRight={7}
                />
                <GridRowTableOneColumn
                  customElementInitial={<label className="text-14 title">Identity Type</label>}
                  customElementColumn={<label className="text-14">{item.identity_type}</label>}
                  columnLeft={5}
                  columnRight={7}
                />
                <GridRowTableOneColumn
                  customElementInitial={<label className="text-14 title">Nationality</label>}
                  customElementColumn={<label className="text-14">{item.nationality}</label>}
                  columnLeft={5}
                  columnRight={7}
                />
                <GridRowTableOneColumn
                  customElementInitial={<label className="text-14 title">Identity Number</label>}
                  customElementColumn={<label className="text-14">{item.identity_number}</label>}
                  columnLeft={5}
                  columnRight={7}
                />
                <GridRowTableOneColumn
                  customElementInitial={<label className="text-14 title">Kode Booking</label>}
                  customElementColumn={
                    <label className="text-14">{item.medical_record_number || '-'}</label>
                  }
                  columnLeft={5}
                  columnRight={7}
                />
              </Grid>
            </Grid>
            <Grid item lg={6} md={6}>
              {isDraft ? (
                <UploadImage
                  defaultValue={item.identity_photo}
                  maxSize={5}
                  onChange={data => onUpdatePhotoHandler(data, index)}
                />
              ) : (
                <div className="container-id-image">
                  {item.identity_photo ? (
                    <div
                      onClick={() => {
                        onOpenModalImagePreview(item.identity_photo);
                      }}
                    >
                      <img src={item.identity_photo} alt="ktp" />
                    </div>
                  ) : (
                    <label className="text-13 text-semi-bold">No image uploaded.</label>
                  )}
                </div>
              )}
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default CustomerItemBooking;
