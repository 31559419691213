import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, FormControlLabel } from '@material-ui/core';
// style
import './CheckInputStyle.scss';

const CheckInput = props => {
  const {
    labelText,
    // isError,
    // errorMessage,
    currentValue,
    colorCheck,
    onCheck,
    disabled,
  } = props;

  const handleItemChange = event => {
    const result = event.target.checked;
    onCheck(result);
  };

  return (
    <FormControlLabel
      control={<Checkbox checked={currentValue} onChange={handleItemChange} color={colorCheck} />}
      label={labelText}
      disabled={disabled}
    />
  );
};

CheckInput.propTypes = {
  // errorMessage: PropTypes.string,
  // isError: PropTypes.bool,
  colorCheck: PropTypes.string,
  currentValue: PropTypes.bool,
  disabled: PropTypes.bool,
  labelText: PropTypes.string,
  onCheck: PropTypes.func,
};

export default CheckInput;
