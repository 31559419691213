import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  Drawer,
  ListItemIcon,
  ListItemText,
  ListItem,
  List,
  IconButton,
  Collapse,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
// styles
import StylesJs from './SideBarStyle';
import './SideBarStyle.scss';
// assets
import { Images } from '../../../../assets/Index';
import { UserHelper } from '../../../../helpers/Index';

const SideBar = props => {
  const { open, onClose, onOpen, variant, pages, currentRoute } = props;

  const classes = StylesJs();
  const currentUsers = UserHelper.getCurrentUserInformation();
  const homeUrl = !_.isEmpty(currentUsers) ? currentUsers.callBackURL : '/';
  const activeRoute = currentRoute;

  function checkActiveRoute(itemUrl) {
    const result = activeRoute.includes(itemUrl);
    return result;
  }

  function checkIsParent(children, collapse) {
    if (children.length > 0) {
      return (
        <ExpandMore
          className={clsx({
            [classes.expandLess]: collapse,
            [classes.expandMore]: !collapse,
          })}
          style={{ color: 'white' }}
        />
      );
    }
    return false;
  }

  const [menu, setMenu] = useState({});

  function handleClickMenu(item) {
    if (open) {
      setMenu(prevState => {
        return { [item]: !prevState[item] };
      });
    }
  }

  function renderMenu(children) {
    return children.map(subOption => {
      const parent = subOption.children;
      if (!subOption.children) {
        return (
          <div key={subOption.name}>
            <Link to={subOption.url}>
              <ListItem
                button
                key={subOption.name}
                className={clsx({
                  [classes.drawerActive]: checkActiveRoute(subOption.url),
                })}
              >
                <ListItemIcon>
                  <div className="list-item-icon-container">
                    <i
                      className={`custom-icon-sidebar ${subOption.icon} ${classes.listItemIcon}`}
                    />
                  </div>
                </ListItemIcon>
                <ListItemText
                  className={`${
                    activeRoute === subOption.url
                      ? 'custom-icon-sidebar sub-menu'
                      : 'custom-icon-text-sidebar sub-menu'
                  }`}
                  primary={subOption.name}
                  style={{ flex: 'none' }}
                />
              </ListItem>
            </Link>
          </div>
        );
      }
      return (
        <div key={subOption.name}>
          <Link to={subOption.url}>
            <ListItem
              button
              onClick={() => handleClickMenu(subOption.name)}
              className={clsx({
                [classes.drawerActive]: checkActiveRoute(subOption.url),
              })}
            >
              <ListItemIcon style={{ minWidth: !open ? 52 : 32, marginRight: 3 }}>
                <div className="list-item-icon-container">
                  <i className={`custom-icon-sidebar ${subOption.icon} ${classes.listItemIcon}`} />
                </div>
              </ListItemIcon>
              <ListItemText
                className={`${
                  activeRoute === subOption.url ? 'custom-icon-sidebar' : 'custom-icon-text-sidebar'
                }`}
                primary={subOption.name}
                style={{ alignItems: 'center' }}
              />
              {checkIsParent(parent, menu[subOption.name])}
            </ListItem>
          </Link>
          <Collapse in={menu[subOption.name]} timeout="auto" unmountOnExit>
            {renderMenu(subOption.children)}
          </Collapse>
        </div>
      );
    });
  }

  return (
    <Drawer
      variant={variant}
      className={`container-side-bar ${clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}`}
      classes={{
        paper: clsx(classes.paper, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
    >
      <div className={classes.toolbar}>
        {open ? (
          <Link className="custom-image-header-container" to={homeUrl}>
            <img src={Images.fitcoLogoWhite} alt="fitco-logo" className="custom-image-header" />
          </Link>
        ) : (
          ''
        )}
        <IconButton
          onClick={open ? onClose : onOpen}
          className="custom-icon-header-button-container"
        >
          {open ? (
            <div className="custom-icon-header-container">
              <i className={`icon ic-ffo-close-box custom-icon-sidebar`} />
            </div>
          ) : (
            <div className="logo-close-section">
              <img
                src={Images.fitcoLogoRoundedWhite}
                alt="fitco-logo-rounded"
                className="custom-image-size01"
              />
              <i className="ic-ffo-chevron-right custom-icon-sidebar adjustment" />
            </div>
          )}
        </IconButton>
      </div>
      <List className="scroll-container-invisible">{renderMenu(pages)}</List>
    </Drawer>
  );
};

SideBar.propTypes = {
  currentRoute: PropTypes.string,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  open: PropTypes.bool.isRequired,
  pages: PropTypes.array,
  variant: PropTypes.string.isRequired,
};

export default SideBar;
