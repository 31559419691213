import * as dashboardAction from '../../redux/actions/DashboardAction';
import Api from '../../middleware/FetchMiddleware';
import { baseUrl } from '../../environments/Environment';
import { HttpAction } from '../../helpers/Index';

export const getSummarySalesMove = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(dashboardAction.getSummarySalesMoveRequest());
    Api.callApi(
      `${baseUrl}private/pos/v2/user-point-booster/total/newsales/move`,
      HttpAction.Get,
      params,
      {},
    )
      .then(response => {
        dispatch(dashboardAction.getSummarySalesMoveSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(dashboardAction.getSummarySalesMoveFail(e));
        reject(e);
      });
  });
};

export const getSummaryRecurringMembership = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(dashboardAction.getSummaryRecurringMembershipRequest());
    Api.callApi(
      `${baseUrl}private/pos/v2/user-point-booster/total/newsales/recurring`,
      HttpAction.Get,
      params,
      {},
    )
      .then(response => {
        dispatch(dashboardAction.getSummaryRecurringMembershipSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(dashboardAction.getSummaryRecurringMembershipFail(e));
        reject(e);
      });
  });
};

export const getSummaryConduct = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(dashboardAction.getSummaryConductRequest());
    Api.callApi(`${baseUrl}private/pos/v2/appointment/total/conduct`, HttpAction.Get, params, {})
      .then(response => {
        dispatch(dashboardAction.getSummaryConductSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(dashboardAction.getSummaryConductFail(e));
        reject(e);
      });
  });
};

export const getSummaryExpiredMembership = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(dashboardAction.getSummaryExpiredMembershipRequest());
    Api.callApi(
      `${baseUrl}private/pos/v2/user-point-booster/total/expired/membership`,
      HttpAction.Get,
      params,
      {},
    )
      .then(response => {
        dispatch(dashboardAction.getSummaryExpiredMembershipSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(dashboardAction.getSummaryExpiredMembershipFail(e));
        reject(e);
      });
  });
};

export const getDailyReportStatistic = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(dashboardAction.getDailyReportStatisticRequest());
    Api.callApi(
      `${baseUrl}private/pos/v2/user-point-booster/daily/report/summary`,
      HttpAction.Get,
      params,
      {},
    )
      .then(response => {
        dispatch(dashboardAction.getDailyReportStatisticSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(dashboardAction.getDailyReportStatisticFail(e));
        reject(e);
      });
  });
};

export const getMembershipStatusRecurringListPagination = (dispatch, urlKey, params) => {
  return new Promise((resolve, reject) => {
    dispatch(dashboardAction.getMembershipStatusRecurringListPaginationRequest());
    Api.callApi(
      `${baseUrl}private/pos/v2/user-point-booster/list/${urlKey}/memberships`,
      HttpAction.Get,
      params,
      {},
    )
      .then(response => {
        dispatch(dashboardAction.getMembershipStatusRecurringListPaginationSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(dashboardAction.getMembershipStatusRecurringListPaginationFail(e));
        reject(e);
      });
  });
};

export const getMembershipStatusRecurringPrint = (dispatch, urlKey, params) => {
  return new Promise((resolve, reject) => {
    dispatch(dashboardAction.getMembershipStatusRecurringPrintRequest());
    Api.callApi(
      `${baseUrl}private/pos/v2/user-point-booster/list/${urlKey}/memberships`,
      HttpAction.Get,
      params,
      {},
    )
      .then(response => {
        dispatch(dashboardAction.getMembershipStatusRecurringPrintSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(dashboardAction.getMembershipStatusRecurringPrintFail(e));
        reject(e);
      });
  });
};

export const getRecentCheckInListPagination = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(dashboardAction.getRecentCheckInListPaginationRequest());
    Api.callApi(
      `${baseUrl}private/pos/v2/appointment/top/recent/checkin`,
      HttpAction.Get,
      params,
      {},
    )
      .then(response => {
        dispatch(dashboardAction.getRecentCheckInListPaginationSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(dashboardAction.getRecentCheckInListPaginationFail(e));
        reject(e);
      });
  });
};

export const getTopSpenderList = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(dashboardAction.getTopSpenderListRequest());
    Api.callApi(`${baseUrl}private/pos/v2/orders/top/move/spender`, HttpAction.Get, params, {})
      .then(response => {
        dispatch(dashboardAction.getTopSpenderListSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(dashboardAction.getTopSpenderListFail(e));
        reject(e);
      });
  });
};

export const getTopSellingMembershipList = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(dashboardAction.getTopSellingMembershipListRequest());
    Api.callApi(
      `${baseUrl}private/pos/v2/orders/top/selling/membership`,
      HttpAction.Get,
      params,
      {},
    )
      .then(response => {
        dispatch(dashboardAction.getTopSellingMembershipListSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(dashboardAction.getTopSellingMembershipListFail(e));
        reject(e);
      });
  });
};

export const getDemoGraphicBookList = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(dashboardAction.getDemoGraphicBookListRequest());
    Api.callApi(
      `${baseUrl}private/pos/v2/appointment/demographic/user/book/move`,
      HttpAction.Get,
      params,
      {},
    )
      .then(response => {
        dispatch(dashboardAction.getDemoGraphicBookListSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(dashboardAction.getDemoGraphicBookListFail(e));
        reject(e);
      });
  });
};
