import * as MedicAction from '../../redux/actions/MedicAction';
import Api from '../../middleware/FetchMiddleware';
import { baseUrl } from '../../environments/Environment';
import { HttpAction } from '../../helpers/HttpHelper';

export const getListBookingLab = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(MedicAction.getListBookingLabRequest());
    Api.callApi(`${baseUrl}private/pos/medic/lab`, HttpAction.Post, {}, params)
      .then(response => {
        dispatch(MedicAction.getListBookingLabSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(MedicAction.getListBookingLabFail(e));
        reject(e);
      });
  });
};

export const getBranchCovidTest = dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(MedicAction.getBranchCovidTestRequest());
    Api.callApi(`${baseUrl}public/mobile/medic/covid-test/branch`, HttpAction.Get, {}, {})
      .then(response => {
        dispatch(MedicAction.getBranchCovidTestSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(MedicAction.getBranchCovidTestFail(e));
        reject(e);
      });
  });
};

export const getProductCovidTest = (dispatch, branchID) => {
  return new Promise((resolve, reject) => {
    dispatch(MedicAction.getProductCovidTestRequest());
    Api.callApi(
      `${baseUrl}public/mobile/medic/covid-test?branch_id=${branchID}`,
      HttpAction.Get,
      {},
      {},
    )
      .then(response => {
        dispatch(MedicAction.getProductCovidTestSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(MedicAction.getProductCovidTestFail(e));
        reject(e);
      });
  });
};

export const getProductByServiceLocation = (dispatch, category, params) => {
  return new Promise((resolve, reject) => {
    dispatch(MedicAction.getProductByServiceLocationRequest());
    Api.callApi(
      `${baseUrl}private/pos/medic/clinic-category/${category}/product`,
      HttpAction.Get,
      params,
      {},
    )
      .then(response => {
        dispatch(MedicAction.getProductByServiceLocationSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(MedicAction.getProductByServiceLocationFail(e));
        reject(e);
      });
  });
};

export const getApdProduct = dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(MedicAction.getApdProductRequest());
    Api.callApi(`${baseUrl}private/pos/medic/lab/apd-product`, HttpAction.Get, {}, {})
      .then(response => {
        dispatch(MedicAction.getApdProductSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(MedicAction.getApdProductFail(e));
        reject(e);
      });
  });
};

export const checkHomeServiceAreaCoverage = (dispatch, body) => {
  return new Promise((resolve, reject) => {
    dispatch(MedicAction.checkHomeServiceAreaCoverageRequest());
    Api.callApi(`${baseUrl}private/pos/medic/lab/homeservice-coverage`, HttpAction.Post, {}, body)
      .then(response => {
        dispatch(MedicAction.checkHomeServiceAreaCoverageSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(MedicAction.checkHomeServiceAreaCoverageFail(e));
        reject(e);
      });
  });
};

export const getBookingResult = (dispatch, salesOrderId) => {
  return new Promise((resolve, reject) => {
    dispatch(MedicAction.getBookingResultRequest());
    Api.callApi(`${baseUrl}private/pos/medic/lab/${salesOrderId}/result`, HttpAction.Get, {}, {})
      .then(response => {
        dispatch(MedicAction.getBookingResultSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(MedicAction.getBookingResultFail(e));
        reject(e);
      });
  });
};

export const setReschedulBookingLab = (dispatch, salesOrderId, params) => {
  return new Promise((resolve, reject) => {
    dispatch(MedicAction.setReschedulBookingLabRequest());
    Api.callApi(
      `${baseUrl}private/pos/medic/lab/${salesOrderId}/reschedule`,
      HttpAction.Post,
      {},
      params,
    )
      .then(response => {
        dispatch(MedicAction.setReschedulBookingLabSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(MedicAction.setReschedulBookingLabFail(e));
        reject(e);
      });
  });
};

export const sendResultLab = (dispatch, salesOrderId) => {
  return new Promise((resolve, reject) => {
    dispatch(MedicAction.sendResultLabRequest());
    Api.callApi(
      `${baseUrl}private/pos/medic/lab/${salesOrderId}/send-result`,
      HttpAction.Get,
      {},
      {},
    )
      .then(response => {
        dispatch(MedicAction.sendResultLabSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(MedicAction.sendResultLabFail(e));
        reject(e);
      });
  });
};

export const updateUserDetail = (dispatch, salesOrderId, param) => {
  return new Promise((resolve, reject) => {
    dispatch(MedicAction.updateUserDetailRequest());
    Api.callApi(
      `${baseUrl}private/pos/medic/lab/${salesOrderId}/update/user-details`,
      HttpAction.Post,
      {},
      param,
    )
      .then(response => {
        dispatch(MedicAction.updateUserDetailSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(MedicAction.updateUserDetailFail(e));
        reject(e);
      });
  });
};

export const updateUserBooking = (dispatch, salesOrderId, param) => {
  return new Promise((resolve, reject) => {
    dispatch(MedicAction.updateUserBookingRequest());
    Api.callApi(
      `${baseUrl}private/pos/medic/lab/${salesOrderId}/update/user-booking`,
      HttpAction.Post,
      {},
      param,
    )
      .then(response => {
        dispatch(MedicAction.updateUserBookingSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(MedicAction.updateUserBookingFail(e));
        reject(e);
      });
  });
};

export const verifyScanFace = (dispatch, data) => {
  return new Promise((resolve, reject) => {
    dispatch(MedicAction.verifyScanFaceRequest());
    Api.callApi(`${baseUrl}private/pos/medic/health-passport/scan/face`, HttpAction.Post, {}, data)
      .then(response => {
        dispatch(MedicAction.verifyScanFaceSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(MedicAction.verifyScanFaceFail(e));
        reject(e);
      });
  });
};

export const getHealthPassportActivities = dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(MedicAction.getHealthPassportActivityRequest());
    Api.callApi(`${baseUrl}private/pos/medic/health-passport/check-in`, HttpAction.Post, {}, {})
      .then(response => {
        dispatch(MedicAction.getHealthPassportActivitySuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(MedicAction.getHealthPassportActivityFail(e));
        reject(e);
      });
  });
};
