export const constants = {
  // #region conduct pt
  setDefaultReportExport: 'SET_DEFAULT_REPORT_EXPORT',
  getChartConductPTRequest: 'GET_CHART_CONDUCTPT_REQUEST',
  getChartConductPTSuccess: 'GET_CHART_CONDUCTPT_SUCCESS',
  getChartConductPTFail: 'GET_CHART_CONDUCTPT_FAIL',
  getInfoConductedRequest: 'GET_INFO_CONDUCTED_REQUEST',
  getInfoConductedSuccess: 'GET_INFO_CONDUCTED_SUCCESS',
  getInfoConductedFail: 'GET_INFO_CONDUCTED_FAIL',
  getTotalAppointmentConductPTRequest: 'GET_TOTAL_APPOINTMENT_CONDUCTPT_REQUEST',
  getTotalAppointmentConductPTSuccess: 'GET_TOTALAPPOINTMENT_CONDUCTPT_SUCCESS',
  getTotalAppointmentConductPTFail: 'GET_TOTAL_APPOINTMENT_CONDUCTPT_FAIL',
  getTopConductPTRequest: 'GET_TOP_CONDUCTPT_REQUEST',
  getTopConductPTSuccess: 'GET_TOP_CONDUCTPT_SUCCESS',
  getTopConductPTFail: 'GET_TOP_CONDUCTPT_FAIL',
  getConductReportPaginationRequest: 'GET_CONDUCT_REPORT_PAGINATION_REQUEST',
  getConductReportPaginationSuccess: 'GET_CONDUCT_REPORT_PAGINATION_SUCCESS',
  getConductReportPaginationFail: 'GET_CONDUCT_REPORT_PAGINATION_FAIL',
  getConductReportExportRequest: 'GET_CONDUCT_REPORT_EXPORT_REQUEST',
  getConductReportExportSuccess: 'GET_CONDUCT_REPORT_EXPORT_SUCCESS',
  getConductReportExportFail: 'GET_CONDUCT_REPORT_EXPORT_FAIL',
  getConductReportDetailByTrainerIdRequest: 'GET_CONDUCT_REPORT_BY-TRAINER_ID_REQUEST',
  getConductReportDetailByTrainerIdSuccess: 'GET_CONDUCT_REPORT_BY-TRAINER_ID_SUCCESS',
  getConductReportDetailByTrainerIdFail: 'GET_CONDUCT_REPORT_BY-TRAINER_ID_FAIL',
  getConductProductByTrainerIdRequest: 'GET_CONDUCT_PRODUCT_BY_TRAINER_ID_REQUEST',
  getConductProductByTrainerIdSuccess: 'GET_CONDUCT_PRODUCT_BY_TRAINER_ID_SUCCESS',
  getConductProductByTrainerIdFail: 'GET_CONDUCT_PRODUCT_BY_TRAINER_ID_FAIL',
  getConductParticipantByTrainerIdRequest: 'GET_CONDUCT_PARTICIPANT_BY_TRAINER_ID_REQUEST',
  getConductParticipantByTrainerIdSuccess: 'GET_CONDUCT_PARTICIPANT_BY_TRAINER_ID_SUCCESS',
  getConductParticipantByTrainerIdFail: 'GET_CONDUCT_PARTICIPANT_BY_TRAINER_ID_FAIL',
  getTotalAppointmentConductPTbyMerchantBranchRequest:
    'GET_TOTAL_APPOINTMENT_BY_MERCHANT_BRANCH_REQUEST',
  getTotalAppointmentConductPTbyMerchantBranchSuccess:
    'GET_TOTAL_APPOINTMENT_BY_MERCHANT_BRANCH_SUCCESS',
  getTotalAppointmentConductPTbyMerchantBranchFail: 'GET_TOTAL_APPOINTMENT_BY_MERCHANT_BRANCH_FAIL',
  getConductParticipantByTrainerIdExportRequest: 'GET_CONDUCT_REPORT_EXPORT_REQUEST',
  getConductParticipantByTrainerIdExportSuccess: 'GET_CONDUCT_REPORT_EXPORT_SUCCESS',
  getConductParticipantByTrainerIdExportFail: 'GET_CONDUCT_REPORT_EXPORT_FAIL',
  // #endregion
  // #region daily Check in
  getChartDailyCheckInRequest: 'GET_CHART_DAILY_CHECK_IN_REQUEST',
  getChartDailyCheckInSuccess: 'GET_CHART_DAILY_CHECK_IN_SUCCESS',
  getChartDailyCheckInFail: 'GET_CHART_DAILY_CHECK_IN_FAIL',
  getInfoDailyCheckInRequest: 'GET_INFO_DAILY_CHECK_IN_REQUEST',
  getInfoDailyCheckInSuccess: 'GET_INFO_DAILY_CHECK_IN_SUCCESS',
  getInfoDailyCheckInFail: 'GET_INFO_DAILY_CHECK_IN_FAIL',
  getTotalDailyCheckInRequest: 'GET_TOTAL_DAILY_CHECK_IN_REQUEST',
  getTotalDailyCheckInSuccess: 'GET_TOTAL_DAILY_CHECK_IN_SUCCESS',
  getTotalDailyCheckInFail: 'GET_TOTAL_DAILY_CHECK_IN_FAIL',
  getTopDailyCheckInRequest: 'GET_TOP_DAILY_CHECK_IN_REQUEST',
  getTopDailyCheckInSuccess: 'GET_TOP_DAILY_CHECK_IN_SUCCESS',
  getTopDailyCheckInFail: 'GET_TOP_DAILY_CHECK_IN_FAIL',
  getDailyCheckInReportPaginationRequest: 'GET_DAILY_CHECK_IN_REPORT_PAGINATION_REQUEST',
  getDailyCheckInReportPaginationSuccess: 'GET_DAILY_CHECK_IN_REPORT_PAGINATION_SUCCESS',
  getDailyCheckInReportPaginationFail: 'GET_DAILY_CHECK_IN_REPORT_PAGINATION_FAIL',
  getDailyCheckInReportExportRequest: 'GET_DAILY_CHECK_IN_REPORT_EXPORT_REQUEST',
  getDailyCheckInReportExportSuccess: 'GET_DAILY_CHECK_IN_REPORT_EXPORT_SUCCESS',
  getDailyCheckInReportExportFail: 'GET_DAILY_CHECK_IN_REPORT_EXPORT_FAIL',
  // #endregion
};

const initState = {
  fetching: false,
  fetchingExport: false,
  fetchingChart: false,
  fetchingConduct: false,
  fetchingSessions: false,
  fetchingTopConduct: false,
  fetchingTotalAppointmentBranch: false,
  dataChart: [],
  topPTList: [],
  reportPagination: [],
  reportExport: [],
  conductedDetails: {},
  totalAppintments: {},
  totalAppointmentBranch: {},
  total: 0,
  errorMessage: null,
  responseMessage: null,
  details: {},
  detailsProduct: [],
  participant: [],
  totalDailyCheckIn: {},
  fetchingDailyCheckIn: false,
  fetchingTopDailyCheckIn: false,
  topDailyCheckIn: [],
  dailyCheckInDetails: {},
};

export default function reportReducer(state = initState, action) {
  const { type, payload } = action;
  switch (type) {
    // #region conduct pt
    case constants.setDefaultReportExport:
      return { ...state, fetching: false, responseMessage: null, reportExport: [] };

    case constants.getChartConductPTRequest:
      return { ...state, fetchingChart: true, responseMessage: null };
    case constants.getChartConductPTSuccess:
      return {
        ...state,
        dataChart: payload.data,
        fetchingChart: false,
      };
    case constants.getChartConductPTFail:
      return { ...state, fetchingChart: false, errorMessage: payload };

    case constants.getInfoConductedRequest:
      return { ...state, fetchingConduct: true, responseMessage: null };
    case constants.getInfoConductedSuccess:
      return {
        ...state,
        conductedDetails: payload.data,
        fetchingConduct: false,
      };
    case constants.getInfoConductedFail:
      return { ...state, fetchingConduct: false, errorMessage: payload };

    case constants.getTotalAppointmentConductPTRequest:
      return { ...state, fetchingSessions: true, responseMessage: null };
    case constants.getTotalAppointmentConductPTSuccess:
      return {
        ...state,
        totalAppintments: payload.data,
        fetchingSessions: false,
      };
    case constants.getTotalAppointmentConductPTFail:
      return { ...state, fetchingSessions: false, errorMessage: payload };

    case constants.getTopConductPTRequest:
      return { ...state, fetchingTopConduct: true, responseMessage: null };
    case constants.getTopConductPTSuccess:
      return {
        ...state,
        topPTList: payload.data,
        fetchingTopConduct: false,
      };
    case constants.getTopConductPTFail:
      return { ...state, fetchingTopConduct: false, errorMessage: payload };

    case constants.getConductReportPaginationRequest:
      return { ...state, fetching: true, responseMessage: null };
    case constants.getConductReportPaginationSuccess:
      return {
        ...state,
        reportPagination: payload.data,
        fetching: false,
      };
    case constants.getConductReportPaginationFail:
      return { ...state, fetching: false, errorMessage: payload };

    case constants.getConductReportExportRequest:
      return { ...state, fetchingExport: true, responseMessage: null, reportExport: [] };
    case constants.getConductReportExportSuccess:
      return {
        ...state,
        reportExport: payload.data,
        fetchingExport: false,
      };
    case constants.getConductReportExportFail:
      return { ...state, fetchingExport: false, errorMessage: payload, reportExport: [] };

    case constants.getConductReportDetailByTrainerIdRequest:
      return { ...state, fetching: true, responseMessage: null };
    case constants.getConductReportDetailByTrainerIdSuccess:
      return {
        ...state,
        details: payload.data,
        detailsProduct: [],
        fetching: false,
      };
    case constants.getConductReportDetailByTrainerIdFail:
      return { ...state, fetching: false, errorMessage: payload };

    case constants.getConductProductByTrainerIdRequest:
      return { ...state, fetching: true, responseMessage: null };
    case constants.getConductProductByTrainerIdSuccess:
      return {
        ...state,
        detailsProduct: [...state.detailsProduct, ...payload.data],
        fetching: false,
      };
    case constants.getConductProductByTrainerIdFail:
      return { ...state, fetching: false, errorMessage: payload };

    case constants.getConductParticipantByTrainerIdRequest:
      return { ...state, fetching: true, responseMessage: null };
    case constants.getConductParticipantByTrainerIdSuccess:
      return {
        ...state,
        participant: payload.data,
        fetching: false,
      };
    case constants.getConductParticipantByTrainerIdFail:
      return { ...state, fetching: false, errorMessage: payload };

    case constants.getTotalAppointmentConductPTbyMerchantBranchRequest:
      return { ...state, fetchingTotalAppointmentBranch: true, responseMessage: null };
    case constants.getTotalAppointmentConductPTbyMerchantBranchSuccess:
      return {
        ...state,
        totalAppointmentBranch: payload.data,
        fetchingTotalAppointmentBranch: false,
      };
    case constants.getTotalAppointmentConductPTbyMerchantBranchFail:
      return { ...state, fetchingTotalAppointmentBranch: false, errorMessage: payload };

    case constants.getConductParticipantByTrainerIdExportRequest:
      return { ...state, fetchingExport: true, responseMessage: null, reportExport: [] };
    case constants.getConductParticipantByTrainerIdExportSuccess:
      return {
        ...state,
        reportExport: payload.data,
        fetchingExport: false,
      };
    case constants.getConductParticipantByTrainerIdExportFail:
      return { ...state, fetchingExport: false, errorMessage: payload, reportExport: [] };
    // #endregion
    // daily check in
    case constants.getChartDailyCheckInRequest:
      return { ...state, fetchingChart: true, responseMessage: null };
    case constants.getChartDailyCheckInSuccess:
      return {
        ...state,
        dataChart: payload.data,
        fetchingChart: false,
      };
    case constants.getChartDailyCheckInFail:
      return { ...state, fetchingChart: false, errorMessage: payload };

    case constants.getInfoDailyCheckInRequest:
      return { ...state, fetchingConduct: true, responseMessage: null };
    case constants.getInfoDailyCheckInSuccess:
      return {
        ...state,
        dailyCheckInDetails: payload.data,
        fetchingConduct: false,
      };
    case constants.getInfoDailyCheckInFail:
      return { ...state, fetchingConduct: false, errorMessage: payload };

    case constants.getTotalDailyCheckInRequest:
      return { ...state, fetchingSessions: true, responseMessage: null };
    case constants.getTotalDailyCheckInSuccess:
      return {
        ...state,
        totalDailyCheckIn: payload.data,
        fetchingSessions: false,
      };
    case constants.getTotalDailyCheckInFail:
      return { ...state, fetchingSessions: false, errorMessage: payload };

    case constants.getTopDailyCheckInRequest:
      return { ...state, fetchingTopDailyCheckIn: true, responseMessage: null };
    case constants.getTopDailyCheckInSuccess:
      return {
        ...state,
        topDailyCheckIn: payload.data,
        fetchingTopDailyCheckIn: false,
      };
    case constants.getTopDailyCheckInFail:
      return { ...state, fetchingTopDailyCheckIn: false, errorMessage: payload };

    case constants.getDailyCheckInReportPaginationRequest:
      return { ...state, fetching: true, responseMessage: null };
    case constants.getDailyCheckInReportPaginationSuccess:
      return {
        ...state,
        reportPagination: payload.data,
        fetching: false,
      };
    case constants.getDailyCheckInReportPaginationFail:
      return { ...state, fetching: false, errorMessage: payload };

    case constants.getDailyCheckInReportExportRequest:
      return { ...state, fetchingExport: true, responseMessage: null, reportExport: [] };
    case constants.getDailyCheckInReportExportSuccess:
      return {
        ...state,
        reportExport: payload.data,
        fetchingExport: false,
      };
    case constants.getDailyCheckInReportExportFail:
      return { ...state, fetchingExport: false, errorMessage: payload, reportExport: [] };
    // #endregion
    default:
      return state;
  }
}
