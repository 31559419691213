const DeepLinkHelper = {
  generateDeeplinkValue(dataArray) {
    const { deeplinkType, identifier, subIdentifier, webUrl, screen } = dataArray;
    let deeplink = null;

    if (deeplinkType === 'screen') {
      deeplink = {
        type: deeplinkType,
        screen_name: screen,
        item_id: identifier,
        subitem_id: subIdentifier,
      };
    }

    if (deeplinkType === 'webUrl') {
      deeplink = {
        type: deeplinkType,
        url: webUrl,
      };
    }

    return deeplink;
  },
};

export default DeepLinkHelper;
