import themes from '../themes/Index';
import CommonHelper from './CommonHelper';

const barColor = [
  themes.palette.cornflowerBlue,
  themes.palette.heliotrope,
  themes.palette.seaBuckthorn,
  themes.palette.pictonBlue,
  themes.palette.jade,
  themes.palette.shuttleGray,
  themes.palette.apricot,
];

const DashboardHelper = {
  generateDataStatisticBar(dataArray = []) {
    const converted = [];

    dataArray.forEach(item => {
      const param = { ...item, dateDisplay: CommonHelper.dateTimeParseNewFormat(item.date, 'DD') };
      converted.push(param);
    });

    return converted;
  },
  generateDataStatisticBarLine(dataArray = [], exclude = []) {
    const converted = [];
    // const radiusUp = [10, 10, 0, 0];
    // const radiusDown = [0, 0, 10, 10];
    // const totalKey = Object.keys(dataArray[0]).length - exclude.length;

    Object.keys(dataArray[0]).forEach(key => {
      exclude.forEach(item => {
        if (key !== item) {
          const currentObject = converted.length;
          // const activeRadius =
          //   currentObject > 0 && currentObject + 1 === totalKey ? radiusUp : null;
          // const finalActiveRadius = currentObject === 0 ? radiusDown : activeRadius;
          const param = {
            name: key,
            lineColor: barColor[currentObject],
            // radius: finalActiveRadius,
            radius: null,
          };
          converted.push(param);
        }
      });
    });

    return converted;
  },
  generateDataStatisticPie(dataArray = []) {
    const converted = [];
    let total = 0;

    dataArray.forEach((item, index) => {
      const param = {
        name: item.name,
        color: barColor[index],
        value: item.value,
      };
      total += item.value;
      converted.push(param);
    });

    converted.forEach(item => {
      const finalPercent = item.value ? parseFloat(item.value / total) * 100 : 0;
      item.percent = item.value ? finalPercent.toFixed(2) : 0;
    });

    return converted;
  },
};

export default DashboardHelper;
