import * as FeedAction from '../../redux/actions/FeedAction';
import Api from '../../middleware/FetchMiddleware';
import { baseUrl } from '../../environments/Environment';
import { HttpAction } from '../../helpers/HttpHelper';

export const getListFeedPagination = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(FeedAction.getListFeedPaginationRequest());
    Api.callApi(`${baseUrl}private/pos/v2/feeds`, HttpAction.Get, params, {})
      .then(response => {
        dispatch(FeedAction.getListFeedPaginationSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(FeedAction.getListFeedPaginationFail(e));
        reject(e);
      });
  });
};

export const getFeedDetails = (dispatch, id) => {
  return new Promise((resolve, reject) => {
    dispatch(FeedAction.getFeedDetailsRequest());
    Api.callApi(`${baseUrl}private/pos/v2/feeds/${id}`, HttpAction.Get, {}, {})
      .then(response => {
        dispatch(FeedAction.getFeedDetailsSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(FeedAction.getFeedDetailsFail(e));
        reject(e);
      });
  });
};

export const setCreateFeed = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(FeedAction.setCreateFeedRequest());
    Api.callApi(`${baseUrl}private/pos/v2/feeds`, HttpAction.Put, {}, params)
      .then(response => {
        dispatch(FeedAction.setCreateFeedSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(FeedAction.setCreateFeedFail(e));
        reject(e);
      });
  });
};

export const setUpdateFeed = (dispatch, params, id) => {
  return new Promise((resolve, reject) => {
    dispatch(FeedAction.setUpdateFeedRequest());
    Api.callApi(`${baseUrl}private/pos/v2/feeds/${id}`, HttpAction.Patch, {}, params)
      .then(response => {
        dispatch(FeedAction.setUpdateFeedSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(FeedAction.setUpdateFeedFail(e));
        reject(e);
      });
  });
};

// export const editTncSection = (dispatch, params) => {
//   return new Promise((resolve, reject) => {
//     dispatch(TncAction.editTncSectionRequest());
//     Api.callApi(`${baseUrl}private/pos/v2/tnc/edit`, HttpAction.Post, {}, params)
//       .then(response => {
//         dispatch(TncAction.editTncSectionSuccess(response));
//         return resolve(response);
//       })
//       .catch(e => {
//         dispatch(TncAction.editTncSectionFail(e));
//         reject(e);
//       });
//   });
// };
