import Axios from 'axios';
// helper
import { UserHelper } from '../helpers/Index';

function handleApiSuccess(res, resolve) {
  resolve(res.data);
}

function handleApiError(err, reject) {
  reject(err);
}

function callApi(url, method, params, body) {
  const userInfo = UserHelper.getCurrentUserInformation();
  const accessToken = userInfo ? userInfo.token.access_token : '';

  const response = new Promise((resolve, reject) => {
    Axios({
      url,
      method,
      params: params || '',
      data: body || '',
      headers: {
        Authorization: `Bearer ${`${accessToken}`}`,
        'Content-Type': 'application/json',
        Accept: 'application/json',
        API_VERSION: '1',
        USER_LANG: 'en',
      },
    })
      .then(res => {
        handleApiSuccess(res, resolve);
      })
      .catch(err => {
        handleApiError(err.response, reject);
      });
  });
  return response;
}

export default {
  callApi,
};
