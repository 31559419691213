import React from 'react';
import PropTypes from 'prop-types';
import { Grid, IconButton } from '@material-ui/core';
import { connect } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';
import ReactPlayer from 'react-player';
import _ from 'lodash';
import { Carousel } from 'antd';
// component
import {
  ModalInformationPopUp,
  ButtonMain,
  SelectInputMain,
  SnackBarSimple,
  ModalAlertAction,
  LabelStatusMain,
  SkeletonModalMedia,
} from '../../../../../components/Index';
// Style
import './ModalFeedDetailStyle.scss';
// helper
import {
  CommonHelper,
  MasterDataHelper,
  HttpStatusCode,
  GlobalCodeStatus,
  PermissionModule,
  PermissionPage,
  PermissionAccess,
} from '../../../../../helpers/Index';
import { getFeedDetails, setUpdateFeed } from '../../../../../services/api/FeedApi';

const optionToash = {
  vertical: 'top',
  horizontal: 'right',
};

const statusOptions = MasterDataHelper.statusGlobal;

class ModalFeedDetail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpenModalDelete: false,
      detailModal: {},
      isLoading: true,
      toastInformation: {
        isOpen: false,
        message: '',
        snackbarType: 'warning',
      },
      modalDetailDataDelete: {
        title: 'Confirmation',
        body: 'Are you sure want to Delete Feed ',
      },
    };
  }

  componentDidMount() {
    const { data } = this.props;

    if (!_.isEmpty(data)) {
      this.setState({ detailModal: data, isLoading: false });
    } else {
      this.getFeedDetails();
    }
  }

  getFeedDetails() {
    const { detailModal } = this.state;
    const { feedDetails, id } = this.props;

    feedDetails(id)
      .then(response => {
        const data = response.data;

        this.setState({
          isLoading: false,
          detailModal: {
            ...detailModal,
            feedsId: data.feeds_id,
            feedsTitle: data.feeds_title,
            feedsDescription: data.feeds_description,
            feedsDeeplink: data.feeds_deeplink,
            categoryId: data.category_id,
            mediaType: data.media_type,
            publishDate: data.publish_date,
            status: data.status,
            createdBy: data.created_by,
            updatedBy: data.updated_by,
            categoryName: data.category_name,
            media: data.media,
          },
        });
      })
      .catch(async error => {
        const serverMessage = error.data;
        const validationStatus = error.status === HttpStatusCode.InternalServerError;
        this.processMessage(
          validationStatus ? serverMessage.message : serverMessage.messages,
          'error',
        );
      });
  }

  setUpdateFeed() {
    const { updateFeed } = this.props;
    const { detailModal } = this.state;

    const params = {
      feeds_title: detailModal.feedsTitle,
      feeds_description: detailModal.feedsDescription,
      feeds_deeplink: detailModal.feedsDeeplink,
      category_id: detailModal.categoryId,
      media_type: detailModal.mediaType,
      media: detailModal.media,
      publish_date: detailModal.publishDate,
      status: detailModal.status,
    };
    updateFeed(params, detailModal.feedsId)
      .then(response => {
        this.processMessage(response.messages, response.status);
      })
      .catch(error => {
        const serverMessage = error.data;
        const validationStatus = error.status === HttpStatusCode.InternalServerError;
        this.processMessage(
          validationStatus ? serverMessage.message : serverMessage.messages,
          'error',
        );
      });
  }

  handleChangeStatus = value => {
    const { detailModal, modalDetailDataDelete } = this.state;
    const modifyModalDelete = value === GlobalCodeStatus.Deleted;
    const modifyModalStatus = value === GlobalCodeStatus.Deleted ? detailModal.status : value;

    this.setState(
      {
        isOpenModalDelete: modifyModalDelete,
        detailModal: { ...detailModal, status: modifyModalStatus },
        modalDetailDataDelete: {
          ...modalDetailDataDelete,
          body: `Are you sure want to Delete Feed id :${detailModal.feedsId}`,
        },
      },
      () => {
        if (!modifyModalDelete) {
          this.setUpdateFeed();
        }
      },
    );
  };

  handleClickEdit = () => {
    const { history } = this.props;
    const { detailModal } = this.state;
    history.push(`/marketing/feed/edit/${detailModal.feedsId}`);
  };

  handleButtonSubmitDelete = () => {
    const { detailModal } = this.state;
    this.setState({ detailModal: { ...detailModal, status: GlobalCodeStatus.Deleted } }, () => {
      this.setUpdateFeed();
    });
  };

  handleCloseModalDelete = () => {
    this.setState({
      isOpenModalDelete: false,
    });
  };

  handleCloseToash = () => {
    const { toastInformation } = this.state;
    this.setState({ toastInformation: { ...toastInformation, isOpen: false } });
  };

  handleClose = () => {
    const { onClose } = this.props;
    onClose(false);
  };

  processMessage(messages, type) {
    const convertedMessage = CommonHelper.generateMessage(messages);

    const paramInformation = {
      isOpen: true,
      message: convertedMessage,
      snackbarType: type,
    };

    this.setState({
      toastInformation: paramInformation,
    });
  }

  render() {
    const { detailModal, isLoading, toastInformation, isOpenModalDelete } = this.state;
    const { isOpen, isPreview } = this.props;

    let renderElement = <SkeletonModalMedia />;

    if (!isLoading && !_.isEmpty(detailModal)) {
      const sourceIndex = detailModal.media.length > 1 ? 1 : 0;
      const feedsDeepLinkType = !_.isEmpty(detailModal.feedsDeeplink)
        ? detailModal.feedsDeeplink.type
        : '';
      const ifUrlDeepLink = feedsDeepLinkType === MasterDataHelper.deeplinkType[2].value;
      const mediaSource = !_.isEmpty(detailModal.media)
        ? detailModal.media[sourceIndex].source
        : undefined;

      renderElement = (
        <Grid container direction="column" className="container-modal-feed-detail flex-wrap-unset">
          <Grid item lg={12} md={12} className="section-header-modal">
            <div className="image-header-container">
              {detailModal.mediaType !== MasterDataHelper.mediaOption[1].value ? (
                <Carousel>
                  {detailModal.media.map((item, index) => {
                    let renderElementImage = null;

                    if (index >= sourceIndex) {
                      renderElementImage = (
                        <div key={index} className="container-image">
                          <img
                            src={item.source}
                            alt="placeholder-state"
                            className="placeholder-image-size"
                          />
                        </div>
                      );
                    }
                    return renderElementImage;
                  })}
                </Carousel>
              ) : (
                <ReactPlayer url={mediaSource} height="33vh" width="59vh" />
              )}
              <div className="button-item-container">
                <IconButton onClick={this.handleClose}>
                  <i className="icon ic-ffo-close custom-icon-close" />
                </IconButton>
              </div>
            </div>
          </Grid>
          <Grid item className="section-body-modal">
            <Grid container spacing={3}>
              <Grid item lg={10} md={10}>
                <div className="flex-row">
                  <label className="text-12 title mr-4">
                    {`${CommonHelper.dateTimeParseNewFormat(
                      detailModal.publishDate,
                      'DD MMMM YYYY, h:MM A',
                    )} `}
                    &bull;
                    {` Created by ${detailModal.createdBy || ''}`}
                  </label>
                  <LabelStatusMain type="complete" value={detailModal.categoryName} />
                </div>
              </Grid>
              <Grid item lg={2} md={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                {!isPreview && (
                  <ButtonMain
                    labelText={'Edit'}
                    type={'primary'}
                    size={'sm'}
                    onClick={this.handleClickEdit}
                    requiredPermission={`${PermissionModule.Marketing}.${PermissionPage.Feeds}.${PermissionAccess.Update}`}
                  />
                )}
              </Grid>
              <Grid
                item
                lg={12}
                md={12}
                style={{ display: 'flex', flexDirection: 'column', paddingBottom: 0 }}
              >
                <div className="wrapping-container">
                  <h5 style={{ marginBottom: 0 }}>{detailModal.feedsTitle}</h5>
                </div>
              </Grid>
            </Grid>
            <Grid container justify="flex-start" className="pt-24 container-additional-information">
              <Grid item lg={4} md={4}>
                <div className="flex-row">
                  <label className="text-12 title mr-4">Set status as</label>
                  <SelectInputMain
                    options={statusOptions}
                    size="small"
                    currentValue={detailModal.status}
                    onChange={this.handleChangeStatus}
                    disabled={isPreview}
                  />
                </div>
              </Grid>
            </Grid>
            <Grid container className="scroll-container mh-16">
              <Grid item lg={12} md={12} className="pt-24 container-scrolling-area">
                <div className="flex-column">
                  <div className="pt-24">{ReactHtmlParser(detailModal.feedsDescription)}</div>
                  {ifUrlDeepLink && (
                    <Grid container justify="center">
                      <Grid item lg={2} md={2}>
                        <a href={detailModal.feedsDeeplink.url}>
                          <ButtonMain labelText={'Redirect'} type={'primary'} size={'md'} />
                        </a>
                      </Grid>
                    </Grid>
                  )}
                </div>
              </Grid>
            </Grid>
          </Grid>
          <SnackBarSimple
            open={toastInformation.isOpen}
            durationHide={2000}
            message={toastInformation.message}
            onClickClose={this.handleCloseToash}
            snackbarType={toastInformation.snackbarType}
            anchor={optionToash}
          />
          <ModalAlertAction
            onButtonSubmit={this.handleButtonSubmitDelete}
            onCloseModal={this.handleCloseModalDelete}
            modalDetail={this.state.modalDetailDataDelete}
            modalType="danger"
            buttonSubmitText="Submit"
            openModal={isOpenModalDelete}
            isLoading={isLoading}
          />
        </Grid>
      );
    }

    return (
      <ModalInformationPopUp
        isOpen={isOpen}
        onClose={this.handleClose}
        customElementProps={renderElement}
        size="medium"
      />
    );
  }
}

const mapDispatchToProps = dispatch => ({
  feedDetails: id => getFeedDetails(dispatch, id),
  updateFeed: (params, id) => setUpdateFeed(dispatch, params, id),
});

const mapStateToProps = ({ feedData }) => ({ feedData });

ModalFeedDetail.propTypes = {
  data: PropTypes.object,
  feedDetails: PropTypes.func,
  history: PropTypes.object,
  id: PropTypes.number,
  isOpen: PropTypes.bool,
  isPreview: PropTypes.bool,
  onClose: PropTypes.func,
  updateFeed: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalFeedDetail);
