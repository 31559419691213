/* eslint-disable import/prefer-default-export */
import * as MiscellaneousAction from '../../redux/actions/MiscellaneousAction';
import Api from '../../middleware/FetchMiddleware';
import { baseUrl } from '../../environments/Environment';
import { HttpAction } from '../../helpers/HttpHelper';

export const getGateConfiguration = (dispatch, branchId, command) => {
  return new Promise((resolve, reject) => {
    dispatch(MiscellaneousAction.getGateConfigurationRequest());
    Api.callApi(
      `${baseUrl}private/pos/v2/open-gym-gate/${branchId}/${command}`,
      HttpAction.Get,
      {},
      {},
    )
      .then(response => {
        dispatch(MiscellaneousAction.getGateConfigurationSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(MiscellaneousAction.getGateConfigurationFail(e));
        reject(e);
      });
  });
};
