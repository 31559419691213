import React from 'react';
import PropTypes from 'prop-types';
import { Select, Form } from 'antd';
import clsx from 'clsx';
// Style
import './SelectInputMainStyle.scss';

const { Option, OptGroup } = Select;

const SelectInputMain = props => {
  const {
    onChange,
    options,
    currentValue,
    defaultValue,
    size,
    mode,
    disabled,
    placeholder,
    validateStatus,
    errorMessage,
    isGroupOption,
  } = props;

  const handleItemChange = selectedValue => {
    onChange(selectedValue);
  };

  const renderOptionItem = optionItem => {
    let renderElement = optionItem.map((item, index) => (
      <Option key={index} value={item.value}>
        {item.name}
      </Option>
    ));

    if (isGroupOption) {
      renderElement = optionItem.map((item, index) => {
        let subGroup = null;
        if (item.label) {
          subGroup = (
            <OptGroup key={index} label={item.label}>
              {item.group.map((itemGroup, indexGroup) => {
                const modifyKey = parseInt(`${index}${indexGroup}`, 10);

                return (
                  <Option key={modifyKey} value={itemGroup.value}>
                    {itemGroup.name}
                  </Option>
                );
              })}
            </OptGroup>
          );
        } else {
          subGroup = (
            <Option key={index} value={item.value}>
              {item.name}
            </Option>
          );
        }
        return subGroup;
      });
    }

    return renderElement;
  };

  return (
    <Form.Item validateStatus={validateStatus} help={errorMessage}>
      <Select
        className={`container-select-input-main ${clsx({ [`text-${size}`]: size })}`}
        placeholder={placeholder}
        value={currentValue}
        defaultValue={defaultValue}
        size={size}
        mode={mode}
        allowClear
        style={{
          borderRadius: 20,
          width: '100%',
        }}
        disabled={disabled}
        onChange={handleItemChange}
      >
        {options && renderOptionItem(options)}
      </Select>
    </Form.Item>
  );
};

SelectInputMain.propTypes = {
  currentValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  errorMessage: PropTypes.string,
  isGroupOption: PropTypes.bool,
  mode: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  size: PropTypes.string,
  validateStatus: PropTypes.string,
};

SelectInputMain.defaultProps = {
  size: 'middle',
};

export default SelectInputMain;
