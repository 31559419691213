import React from 'react';
import { connect } from 'react-redux';
import { Grid, FormControl, FormLabel } from '@material-ui/core';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { Form } from 'antd';
// component
import {
  TextInput,
  RadioInput,
  SelectInputMain,
  TextAreaMain,
  CheckInputOutlineBackground,
  TextInputNumber,
  TextInputPrePost,
  PickerInputDate,
  PickerInputTime,
  FitcoPopover,
  SelectInputVoucherList,
} from '../../../../../../components/Index';
// style
import './StepPromoCodeDetailsStyle.scss';
// helper
import {
  CommonHelper,
  MasterDataHelper,
  ValidationHelper,
  HttpStatusCode,
  GlobalCodeStatus,
  currencyCode,
  FitcoPopoverTrigger,
  FitcoPopoverPlacement,
  radioButtonTrueFalseCode,
} from '../../../../../../helpers/Index';
// api
import { getCheckValidPromoCode } from '../../../../../../services/api/PromoCodeApi';

const statusOption = MasterDataHelper.statusGlobal.filter(
  item => item.value === GlobalCodeStatus.Active || item.value === GlobalCodeStatus.Inactive,
);
const promoTypeOption = MasterDataHelper.promoTypeOption;
const radioButtonTrueFalseOption = MasterDataHelper.radioButtonTrueFalse;

const currentDate = CommonHelper.currentDate('YYYY-MM-DD');
const currentTime = CommonHelper.currentDate('HH:mm');
const endDateValue = CommonHelper.getEndDateMonth(currentDate);

const initialValidation = {
  name: { isError: false, errorMessage: '' },
  promoCode: { isError: false, errorMessage: '' },
  description: { isError: '', errorMessage: '' },
  quota: { isError: false, errorMessage: '' },
  multiPromoCodePrefix: { isError: false, errorMessage: '' },
  discountPercentage: { isError: '', errorMessage: '' },
  maximumDiscount: { isError: '', errorMessage: '' },
  minimumPurchase: { isError: '', errorMessage: '' },
  discountPeriodStart: { isError: '', errorMessage: '' },
  discountPeriodEnd: { isError: '', errorMessage: '' },
  discountPeriodTimeStart: { isError: '', errorMessage: '' },
  discountPeriodTimeEnd: { isError: '', errorMessage: '' },
  discountPeriodDayTimeStart: { isError: '', errorMessage: '' },
  discountPeriodDayTimeEnd: { isError: '', errorMessage: '' },
  voucher: { isError: '', errorMessage: '' },
  voucherPrice: { isError: '', errorMessage: '' },
};

const initialForm = {
  name: '',
  promoCode: '',
  status: statusOption[0],
  description: '',
  type: promoTypeOption[0].value,
  quota: 0,
  isMultiCode: false,
  multiPromoCodePrefix: '',
  isOnTimeUsing: radioButtonTrueFalseOption[0],
  isReusableCode: radioButtonTrueFalseOption[0],
  discountPercentage: 0,
  maximumDiscount: 0,
  minimumPurchase: 0,
  discountPeriodStart: CommonHelper.objectCloning(currentDate),
  discountPeriodEnd: CommonHelper.objectCloning(endDateValue),
  discountPeriodTimeStart: '00:00',
  discountPeriodTimeEnd: CommonHelper.objectCloning(currentTime),
  discountPeriodDayTimeStart: '00:00',
  discountPeriodDayTimeEnd: CommonHelper.objectCloning(currentTime),
  voucherStatus: radioButtonTrueFalseOption[1],
  voucher: null,
  voucherPrice: 0,
};

class StepPromoCodeDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      form: CommonHelper.objectCloning(initialForm),
      validation: CommonHelper.objectCloning(initialValidation),
      minDate: currentDate,
      maxDate: null,
    };
    this.searchPromoCodeDebounce = _.debounce(this.searchPromoCodeDebounce, 600);
    this.descriptionDebounce = _.debounce(this.descriptionDebounce, 400);
    this.updateParentDebounce = _.debounce(this.setUpdateParentDebounce, 600);
  }

  componentDidMount() {
    const { parentValidation, parentForm } = this.props;
    const { form, validation } = this.state;
    this.setState({
      form: { ...form, ...parentForm },
      validation: { ...validation, ...parentValidation },
    });
  }

  componentWillReceiveProps(nextProps) {
    const { form, validation, minDate } = this.state;

    const isFormChange = _.isEqual(form, nextProps.parentForm);
    const isValidationChange = _.isEqual(validation, nextProps.parentValidation);

    if (!isFormChange) {
      const finalMergeForm = { ...form, ...nextProps.parentForm };
      this.setState({
        form: finalMergeForm,
      });
    }

    const minDateIsToday = minDate === currentDate;
    const modifyMinDate =
      minDateIsToday && CommonHelper.compareDateAndTime(minDate, form.discountPeriodStart, 'date');
    this.setState({
      minDate: modifyMinDate ? form.discountPeriodStart : minDate,
    });

    if (!isValidationChange) {
      const finalMergeValidation = { ...validation, ...nextProps.parentValidation };
      this.setState({
        validation: finalMergeValidation,
      });
    }
  }

  componentDidUpdate(prevProps, nextProps) {
    const { form } = this.state;

    const isCashbackIdExistForm = form.cashbackId
      ? { ...nextProps.form, cashbackId: form.cashbackId }
      : nextProps.form;

    const checkFormIsChanging = _.isEqual(form, isCashbackIdExistForm);
    if (!checkFormIsChanging) {
      this.updateParentDebounce();
    }
  }

  setUpdateParentDebounce() {
    const { form, validation } = this.state;
    const { onParameterUpdate } = this.props;
    // const updateParent = !form.cashbackId;

    // if (updateParent) {
    const formNewValue = CommonHelper.objectCloning(form);
    const validationNewValue = CommonHelper.objectCloning(validation);
    onParameterUpdate(formNewValue, validationNewValue);
    // }
  }

  searchPromoCodeDebounce = () => {
    const { checkValidPromoCode, onPopUpInformation } = this.props;
    const { form, validation } = this.state;

    const message = CommonHelper.objectCloning(initialValidation.promoCode);
    const param = { promo_code: form.promoCode };

    checkValidPromoCode(param)
      .then(response => {
        if (response.code !== HttpStatusCode.Success) {
          const convertedMessage = CommonHelper.generateMessage(response.messages);
          message.isError = true;
          message.errorMessage = convertedMessage;
          this.setState({ validation: { ...validation, promoCode: message } });
        }
      })
      .catch(error => {
        const data = error.data;
        if (data.code === HttpStatusCode.Forbidden) {
          const convertedMessage = CommonHelper.generateMessage(data.messages);
          message.isError = true;
          message.errorMessage = convertedMessage;
          this.setState({ validation: { ...validation, promoCode: message } });
        } else {
          const serverMessage = data;
          onPopUpInformation(serverMessage.messages, 'error');
        }
      });
  };

  handleChangePromoName = value => {
    const { form, validation } = this.state;
    const message = CommonHelper.objectCloning(initialValidation.name);
    message.isError = !value;
    message.errorMessage = !value ? 'Please enter Promo Title' : '';

    this.setState({
      form: { ...form, name: value },
      validation: { ...validation, name: message },
    });
  };

  handleChangePromoCode = value => {
    const { form, validation } = this.state;
    const message = CommonHelper.objectCloning(initialValidation.promoCode);
    message.isError = !value;
    message.errorMessage = !value ? 'Please enter Promo Code' : '';

    this.setState(
      {
        form: { ...form, promoCode: value },
        validation: { ...validation, promoCode: message },
      },
      () => {
        this.searchPromoCodeDebounce();
      },
    );
  };

  handleSelectStatus = value => {
    const { form } = this.state;
    this.setState({ form: { ...form, status: value } });
  };

  descriptionDebounce = () => {
    const { form, validation } = this.state;
    const message = CommonHelper.objectCloning(initialValidation.description);
    message.isError = !form.description ? 'error' : '';
    message.errorMessage = !form.description ? 'Please enter Description' : '';

    this.setState({
      validation: { ...validation, description: message },
    });
  };

  generateReuseUniqueCodePopoverTitle = () => {
    const title = <div className="p-5 text-semi-bold">Re-usable Unique Code</div>;
    return title;
  };

  generateReuseUniqueCodePopoverContent = () => {
    const title = (
      <div className="p-5 text-10">
        Unique Code can be reused multiple times by one customer given that it is still active
      </div>
    );
    return title;
  };

  generateOneTimePopoverTitle = () => {
    const title = <div className="p-5 text-semi-bold">One Time Use</div>;
    return title;
  };

  generateOneTimePopoverContent = () => {
    const title = <div className="p-5 text-10">This promo can be used only once per customer</div>;
    return title;
  };

  generateDiscountPeriodPopoverTitle = () => {
    const title = <div className="p-5 text-semi-bold">Discount Period</div>;
    return title;
  };

  generateDiscountPeriodPopoverContent = () => {
    const title = <div className="p-5 text-10">Promo Code period applies (active period)</div>;
    return title;
  };

  generateDiscountPeriodDuringTheDayPopoverTitle = () => {
    const title = <div className="p-5 text-semi-bold">Discount Period During The Day</div>;
    return title;
  };

  generateDiscountPeriodDuringTheDayPopoverContent = () => {
    const title = <div className="p-5 text-10">Time Promo Code period applies during the day</div>;
    return title;
  };

  handleChangeTypeBenefit = value => {
    const { form } = this.state;

    this.setState({
      form: { ...form, type: value },
    });
  };

  handleButtonNumberQuota = value => {
    const { form } = this.state;
    let modifyConvertedValue = parseInt(value, 10);

    if (modifyConvertedValue <= 0) {
      modifyConvertedValue = 0;
    }

    this.setState({
      form: { ...form, quota: modifyConvertedValue || 0 },
    });
  };

  handleCheckedMultipleCode = value => {
    const { form, validation } = this.state;
    const message = !value
      ? CommonHelper.objectCloning(initialValidation.multiPromoCodePrefix)
      : CommonHelper.objectCloning(validation.multiPromoCodePrefix);

    this.setState({
      form: { ...form, isMultiCode: value },
      validation: { ...validation, multiPromoCodePrefix: message },
    });
  };

  handleSelectVoucherStatus = value => {
    const { form } = this.state;
    let OneTimeUse = form.isOnTimeUsing;
    let reusableCode = form.isReusableCode;
    let multiCode = form.isMultiCode;

    if (value.value === radioButtonTrueFalseCode.Yes) {
      OneTimeUse = radioButtonTrueFalseOption[1];
      reusableCode = radioButtonTrueFalseOption[1];
      multiCode = true;
    } else {
      OneTimeUse = radioButtonTrueFalseOption[0];
      reusableCode = radioButtonTrueFalseOption[0];
      multiCode = false;
    }

    this.setState({
      form: {
        ...form,
        voucherStatus: value,
        voucher: null,
        voucherPrice: 0,
        isOnTimeUsing: OneTimeUse,
        isReusableCode: reusableCode,
        isMultiCode: multiCode,
      },
    });
  };

  handleSelectVoucher = value => {
    const { form } = this.state;

    this.setState({
      form: { ...form, voucher: value || null },
    });
  };

  handleChangeVoucherPrice = value => {
    const { form } = this.state;

    this.setState({
      form: { ...form, voucherPrice: value || null },
    });
  };

  handleChangePrefixPromoCode = value => {
    const { form, validation } = this.state;
    const message = CommonHelper.objectCloning(initialValidation.multiPromoCodePrefix);
    message.isError = !value;
    message.errorMessage = !value ? 'Please enter Prefix Promo Code' : '';

    this.setState({
      form: { ...form, multiPromoCodePrefix: value },
      validation: { ...validation, multiPromoCodePrefix: message },
    });
  };

  handleSelectOneTimeUsage = value => {
    const { form } = this.state;

    this.setState({ form: { ...form, isOnTimeUsing: value } });
  };

  handleSelectUniqueCode = value => {
    const { form } = this.state;

    this.setState({ form: { ...form, isReusableCode: value } });
  };

  handleTextChangeDiscountPercentage = value => {
    const { form } = this.state;
    let modifyDiscountPercentage = '';

    if (value) {
      const checkIsPercentage = ValidationHelper.validateNumberPercentage(value);
      const checkIsNumber = CommonHelper.checkNumberOnly(value);
      const finalCheckIsNumber = checkIsNumber && value >= 100 ? 100 : value;
      modifyDiscountPercentage = checkIsPercentage || checkIsNumber ? finalCheckIsNumber : '';
    }

    this.setState({
      form: { ...form, discountPercentage: modifyDiscountPercentage },
    });
  };

  handleTextChangeMaximumDiscount = value => {
    const { form, validation } = this.state;
    const message = CommonHelper.objectCloning(initialValidation.maximumDiscount);

    let modifyMaximumDiscount = '';

    if (value) {
      const checkIsNumber = CommonHelper.checkNumberOnly(value);
      modifyMaximumDiscount = checkIsNumber ? parseInt(value, 10) : '';

      // message.isError = !modifyMaximumDiscount ? 'error' : '';
      // message.errorMessage = !modifyMaximumDiscount ? 'Please enter Minimum Purchase' : '';
    }

    this.setState({
      form: { ...form, maximumDiscount: modifyMaximumDiscount },
      validation: { ...validation, maximumDiscount: message },
    });
  };

  handleTextChangeMinimumPurchase = value => {
    const { form, validation } = this.state;
    const message = CommonHelper.objectCloning(initialValidation.minimumPurchase);

    let modifyMinimumPurchase = '';

    if (value) {
      const checkIsNumber = CommonHelper.checkNumberOnly(value);
      modifyMinimumPurchase = checkIsNumber ? parseInt(value, 10) : 0;

      // message.isError = !modifyMinimumPurchase ? 'error' : '';
      // message.errorMessage = !modifyMinimumPurchase ? 'Please enter Minimum Purchase' : '';
    }

    this.setState({
      form: { ...form, minimumPurchase: modifyMinimumPurchase },
      validation: { ...validation, minimumPurchase: message },
    });
  };

  handleTextChangeDescription = value => {
    const { form } = this.state;

    this.setState(
      {
        form: { ...form, description: value },
      },
      () => {
        this.descriptionDebounce();
      },
    );
  };

  handleChangeDiscountPeriodStart = value => {
    const { form } = this.state;
    const discountPeriodStart = value || '';

    this.setState({
      form: { ...form, discountPeriodStart },
    });
  };

  handleChangeDiscountPeriodEnd = value => {
    const { form } = this.state;
    const discountPeriodEnd = value || '';

    this.setState({
      form: { ...form, discountPeriodEnd },
    });
  };

  handleChangeDiscountPeriodTimeStart = value => {
    const { form, validation } = this.state;
    const discountPeriodTimeStart = value || '';
    const message = CommonHelper.objectCloning(initialValidation.discountPeriodTimeStart);
    const checkValidTime = CommonHelper.compareDateAndTime(
      value,
      form.discountPeriodTimeEnd,
      'time',
    );

    message.isError = checkValidTime ? 'error' : '';
    message.errorMessage = checkValidTime ? 'Start Time must Lower thant End Time' : '';

    this.setState({
      form: { ...form, discountPeriodTimeStart },
      validation: { ...validation, discountPeriodTimeStart: message },
    });
  };

  handleChangeDiscountPeriodTimeEnd = value => {
    const { form, validation } = this.state;
    const discountPeriodTimeEnd = value || '';
    const message = CommonHelper.objectCloning(initialValidation.discountPeriodTimeEnd);
    const checkValidTime = CommonHelper.compareDateAndTime(
      value,
      form.discountPeriodTimeStart,
      'time',
    );

    message.isError = !checkValidTime ? 'error' : '';
    message.errorMessage = !checkValidTime ? 'End Time must Greater thant Start Time' : '';

    this.setState({
      form: { ...form, discountPeriodTimeEnd },
      validation: { ...validation, discountPeriodTimeEnd: message },
    });
  };

  handleChangeDiscountPeriodDayTimeStart = value => {
    const { form, validation } = this.state;
    const discountPeriodDayTimeStart = value || '';

    const message = CommonHelper.objectCloning(initialValidation.discountPeriodDayTimeStart);
    const checkValidTime = CommonHelper.compareDateAndTime(
      value,
      form.discountPeriodDayTimeEnd,
      'time',
    );

    message.isError = checkValidTime ? 'error' : '';
    message.errorMessage = checkValidTime ? 'Start Time must Lower thant End Time' : '';
    this.setState({
      form: { ...form, discountPeriodDayTimeStart },
      validation: { ...validation, discountPeriodDayTimeStart: message },
    });
  };

  handleChangeDiscountPeriodDayTimeEnd = value => {
    const { form, validation } = this.state;
    const discountPeriodDayTimeEnd = value || '';

    const message = CommonHelper.objectCloning(initialValidation.discountPeriodDayTimeEnd);
    const checkValidTime = CommonHelper.compareDateAndTime(
      value,
      form.discountPeriodDayTimeStart,
      'time',
    );

    message.isError = !checkValidTime ? 'error' : '';
    message.errorMessage = !checkValidTime ? 'End Time must Greater thant Start Time' : '';
    this.setState({
      form: { ...form, discountPeriodDayTimeEnd },
      validation: { ...validation, discountPeriodDayTimeEnd: message },
    });
  };

  render() {
    const { validation, form, minDate, maxDate } = this.state;
    const {
      customElementPropsFormLabel,
      customElementPropsStepper,
      promoCodeData: { fetching },
    } = this.props;

    return (
      <React.Fragment>
        <Grid item lg md className="container-main-card flex-column p-32 flex-wrap-unset mb-32">
          <div className="mb-32">{customElementPropsFormLabel}</div>
          <div className="mb-48">{customElementPropsStepper}</div>
          <Grid container spacing={2}>
            <Grid item lg={6} md={6}>
              <div className="flex-column">
                <Grid container>
                  <Grid item lg={12} md={12}>
                    <FormControl component="fieldset" fullWidth margin="normal">
                      <FormLabel component="label" className="text-12 field-title">
                        Promo Title
                      </FormLabel>
                      <TextInput
                        placeHolderText="Promo Tittle"
                        size="lg"
                        onChange={this.handleChangePromoName}
                        currentValue={form.name}
                        errorMessage={validation.name.errorMessage}
                        isError={validation.name.isError}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item lg={12} md={12}>
                    <FormControl component="fieldset" fullWidth margin="normal">
                      <FormLabel
                        component="label"
                        className="text-12 mb-12 text-rolling-stone  field-title"
                      >
                        Status
                      </FormLabel>
                      <RadioInput
                        data={statusOption}
                        onSelect={this.handleSelectStatus}
                        direction="row"
                        currentValue={form.status}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid item lg={6} md={6}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <FormLabel
                  component="label"
                  className="text-12 mb-12 text-rolling-stone  field-title"
                >
                  Description
                </FormLabel>
                <TextAreaMain
                  onChange={this.handleTextChangeDescription}
                  currentValue={form.description}
                  placeholder="Description"
                  rows={5}
                  validateStatus={validation.description.isError}
                  errorMessage={validation.description.errorMessage}
                  maxLength={500}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg md className="container-main-card flex-column p-32 flex-wrap-unset mb-32">
          <label className="text-16 text-rolling-stone text-bold mb-24">Condition</label>
          <Grid container spacing={2}>
            <Grid item lg={6} md={6}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <FormLabel component="label" className="text-12 field-title">
                  Type
                </FormLabel>
                <SelectInputMain
                  options={promoTypeOption}
                  currentValue={form.type}
                  onChange={this.handleChangeTypeBenefit}
                  size="large"
                />
              </FormControl>
            </Grid>
            <Grid item lg={6} md={6}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <FormLabel component="label" className="text-12 field-title text-transparent">
                  Unique Code
                </FormLabel>
                <CheckInputOutlineBackground
                  currentValue={form.isMultiCode}
                  labelText="Use Multiple Unique Code"
                  onCheck={this.handleCheckedMultipleCode}
                  size="large"
                  disabled={form.voucherStatus.value === radioButtonTrueFalseCode.Yes}
                />
              </FormControl>
            </Grid>
            <Grid item lg={6} md={6}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <FormLabel component="label" className="text-12 field-title">
                  Quota
                </FormLabel>
                <TextInputNumber
                  placeHolderText={'Quota'}
                  currentValue={form.quota}
                  onChange={this.handleButtonNumberQuota}
                  size="lg"
                  errorMessage={validation.quota.errorMessage}
                  isError={validation.quota.isError}
                />
              </FormControl>
            </Grid>
            <Grid item lg={6} md={6}>
              <FormControl component="fieldset" fullWidth margin="normal">
                {form.isMultiCode ? (
                  <React.Fragment>
                    <FormLabel component="label" className="text-12 field-title">
                      Code Prefix
                    </FormLabel>
                    <TextInput
                      placeHolderText="Promo Code"
                      size="lg"
                      onChange={this.handleChangePrefixPromoCode}
                      currentValue={form.multiPromoCodePrefix}
                      errorMessage={validation.multiPromoCodePrefix.errorMessage}
                      isError={validation.multiPromoCodePrefix.isError}
                      isDisable={!form.isMultiCode}
                      maxLength={5}
                    />
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <FormLabel component="label" className="text-12 field-title">
                      Code
                    </FormLabel>
                    <TextInput
                      placeHolderText="Promo Code"
                      size="lg"
                      onChange={this.handleChangePromoCode}
                      currentValue={form.promoCode}
                      errorMessage={validation.promoCode.errorMessage}
                      isError={validation.promoCode.isError}
                      isLoading={fetching}
                      isDisable={fetching}
                      maxLength={12}
                    />
                  </React.Fragment>
                )}
              </FormControl>
            </Grid>
            <Grid item lg={6} md={6}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <FormLabel component="label" className="text-12 field-title">
                  Is Voucher
                </FormLabel>
                <RadioInput
                  data={radioButtonTrueFalseOption}
                  onSelect={this.handleSelectVoucherStatus}
                  direction="row"
                  currentValue={form.voucherStatus}
                />
              </FormControl>
            </Grid>
            <Grid item lg={3} md={3}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <FormLabel component="label" className="text-12 field-title">
                  Select Voucher Category
                </FormLabel>
                <SelectInputVoucherList
                  onChange={this.handleSelectVoucher}
                  currentValue={form.voucher}
                  placeHolder={'Select Voucher'}
                  disabled={form.voucherStatus.value === radioButtonTrueFalseCode.No}
                  validateStatus={validation.voucher.isError}
                  errorMessage={validation.voucher.errorMessage}
                />
              </FormControl>
            </Grid>
            <Grid item lg={3} md={3}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <FormLabel component="label" className="text-12 field-title">
                  Voucher Price
                </FormLabel>
                <TextInputPrePost
                  placeHolderText="Voucher Price"
                  onChange={this.handleChangeVoucherPrice}
                  currentValue={form.voucherPrice}
                  customElementsBefore={currencyCode.idr}
                  disabled={form.voucherStatus.value === radioButtonTrueFalseCode.No}
                  validateStatus={validation.voucherPrice.isError}
                  errorMessage={validation.voucherPrice.errorMessage}
                  size="large"
                />
              </FormControl>
            </Grid>
            <Grid item lg={6} md={6}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <FormLabel component="label" className="text-12 field-title">
                  {form.type === 1 ? 'Discount' : 'Cashback'} Percentage
                </FormLabel>
                <TextInputPrePost
                  placeHolderText="Discount"
                  onChange={this.handleTextChangeDiscountPercentage}
                  currentValue={form.discountPercentage}
                  customElementsAfter={'%'}
                  validateStatus={validation.discountPercentage.isError}
                  errorMessage={validation.discountPercentage.errorMessage}
                  size="large"
                />
              </FormControl>
            </Grid>
            <Grid item lg={3} md={3}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <FormLabel component="label" className="text-12 field-title">
                  One Time Use
                  <FitcoPopover
                    title={this.generateOneTimePopoverTitle()}
                    content={this.generateOneTimePopoverContent()}
                    placement={FitcoPopoverPlacement.right}
                    trigger={FitcoPopoverTrigger.Hover}
                  />
                </FormLabel>
                <RadioInput
                  data={radioButtonTrueFalseOption}
                  onSelect={this.handleSelectOneTimeUsage}
                  direction="row"
                  currentValue={form.isOnTimeUsing}
                  isDisable={form.voucherStatus.value === radioButtonTrueFalseCode.Yes}
                />
              </FormControl>
            </Grid>
            <Grid item lg={3} md={3}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <FormLabel component="label" className="text-12 field-title">
                  Re-usable Unique Code
                  <FitcoPopover
                    title={this.generateReuseUniqueCodePopoverTitle()}
                    content={this.generateReuseUniqueCodePopoverContent()}
                    placement={FitcoPopoverPlacement.right}
                    trigger={FitcoPopoverTrigger.Hover}
                  />
                </FormLabel>
                <RadioInput
                  data={radioButtonTrueFalseOption}
                  onSelect={this.handleSelectUniqueCode}
                  direction="row"
                  currentValue={form.isReusableCode}
                  isDisable={
                    !form.isMultiCode || form.voucherStatus.value === radioButtonTrueFalseCode.Yes
                  }
                />
              </FormControl>
            </Grid>
            <Grid item lg={6} md={6}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <FormLabel component="label" className="text-12 field-title">
                  Maximum {form.type === 1 ? 'Discount' : 'Cashback'}
                </FormLabel>
                <TextInputPrePost
                  placeHolderText="Maximum Discount"
                  onChange={this.handleTextChangeMaximumDiscount}
                  currentValue={form.maximumDiscount}
                  customElementsBefore={currencyCode.idr}
                  validateStatus={validation.maximumDiscount.isError}
                  errorMessage={validation.maximumDiscount.errorMessage}
                  size="large"
                />
              </FormControl>
            </Grid>
            <Grid item lg={6} md={6}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <FormLabel component="label" className="text-12 field-title">
                  Minimum Purchase
                </FormLabel>
                <TextInputPrePost
                  placeHolderText="Minimum Purchase"
                  onChange={this.handleTextChangeMinimumPurchase}
                  currentValue={form.minimumPurchase}
                  customElementsBefore={currencyCode.idr}
                  validateStatus={validation.minimumPurchase.isError}
                  errorMessage={validation.minimumPurchase.errorMessage}
                  size="large"
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg md className="container-main-card flex-column p-32 flex-wrap-unset mb-32">
          <Grid container spacing={2}>
            <Grid item lg={6} md={6}>
              <div className="flex-column">
                <label className="text-16 text-bold text-rolling-stone mb-8">
                  Discount Period
                  <FitcoPopover
                    title={this.generateDiscountPeriodPopoverTitle()}
                    content={this.generateDiscountPeriodPopoverContent()}
                    placement={FitcoPopoverPlacement.Right}
                    trigger={FitcoPopoverTrigger.Hover}
                  />
                </label>
                <Grid container spacing={2}>
                  <Grid item lg={6} md={6}>
                    <FormControl component="fieldset" fullWidth margin="normal">
                      <FormLabel component="label" className="text-12 field-title">
                        Start Date
                      </FormLabel>
                      <PickerInputDate
                        customIcon="ic-ffo-date-pick"
                        dateFormat="dd/MM/yyyy"
                        minDate={minDate}
                        maxDate={form.discountPeriodEnd}
                        defaultValue={form.discountPeriodStart}
                        onChange={this.handleChangeDiscountPeriodStart}
                        toolbar={false}
                        size="lg"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item lg={6} md={6}>
                    <FormControl component="fieldset" fullWidth margin="normal">
                      <FormLabel component="label" className="text-12 field-title">
                        Start Time
                      </FormLabel>
                      <Form.Item
                        validateStatus={validation.discountPeriodTimeStart.isError}
                        help={validation.discountPeriodTimeStart.errorMessage}
                      >
                        <PickerInputTime
                          defaultValue={form.discountPeriodTimeStart}
                          onChange={this.handleChangeDiscountPeriodTimeStart}
                          customIcon="ic-ffo-timer"
                        />
                      </Form.Item>
                    </FormControl>
                  </Grid>
                  <Grid item lg={6} md={6}>
                    <FormControl component="fieldset" fullWidth margin="normal">
                      <FormLabel component="label" className="text-12 field-title">
                        End Date
                      </FormLabel>
                      <PickerInputDate
                        customIcon="ic-ffo-date-pick"
                        dateFormat="dd/MM/yyyy"
                        minDate={form.discountPeriodStart}
                        maxDate={maxDate}
                        defaultValue={form.discountPeriodEnd}
                        onChange={this.handleChangeDiscountPeriodEnd}
                        toolbar={false}
                        size="lg"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item lg={6} md={6}>
                    <FormControl component="fieldset" fullWidth margin="normal">
                      <FormLabel component="label" className="text-12 field-title">
                        End Time
                      </FormLabel>
                      <Form.Item
                        validateStatus={validation.discountPeriodTimeEnd.isError}
                        help={validation.discountPeriodTimeEnd.errorMessage}
                      >
                        <PickerInputTime
                          defaultValue={form.discountPeriodTimeEnd}
                          onChange={this.handleChangeDiscountPeriodTimeEnd}
                          customIcon="ic-ffo-timer"
                        />
                      </Form.Item>
                    </FormControl>
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid item lg={6} md={6}>
              <div className="flex-column">
                <label className="text-16 text-bold text-rolling-stone mb-8">
                  Discount Period During the Day
                  <FitcoPopover
                    title={this.generateDiscountPeriodDuringTheDayPopoverTitle()}
                    content={this.generateDiscountPeriodDuringTheDayPopoverContent()}
                    placement={FitcoPopoverPlacement.right}
                    trigger={FitcoPopoverTrigger.Hover}
                  />
                </label>
                <Grid container spacing={2}>
                  <Grid item lg={6} md={6}>
                    <FormControl component="fieldset" fullWidth margin="normal">
                      <FormLabel component="label" className="text-12 field-title">
                        Start Time
                      </FormLabel>
                      <Form.Item
                        validateStatus={validation.discountPeriodDayTimeStart.isError}
                        help={validation.discountPeriodDayTimeStart.errorMessage}
                      >
                        <PickerInputTime
                          defaultValue={form.discountPeriodDayTimeStart}
                          onChange={this.handleChangeDiscountPeriodDayTimeStart}
                          customIcon="ic-ffo-timer"
                        />
                      </Form.Item>
                    </FormControl>
                  </Grid>
                  <Grid item lg={6} md={6}>
                    <FormControl component="fieldset" fullWidth margin="normal">
                      <FormLabel component="label" className="text-12 field-title">
                        End Time
                      </FormLabel>
                      <Form.Item
                        validateStatus={validation.discountPeriodDayTimeEnd.isError}
                        help={validation.discountPeriodDayTimeEnd.errorMessage}
                      >
                        <PickerInputTime
                          defaultValue={form.discountPeriodDayTimeEnd}
                          onChange={this.handleChangeDiscountPeriodDayTimeEnd}
                          customIcon="ic-ffo-timer"
                        />
                      </Form.Item>
                    </FormControl>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  checkValidPromoCode: params => getCheckValidPromoCode(dispatch, params),
});

const mapStateToProps = ({ promoCodeData }) => ({ promoCodeData });

StepPromoCodeDetails.propTypes = {
  checkValidPromoCode: PropTypes.func,
  customElementPropsFormLabel: PropTypes.element,
  customElementPropsStepper: PropTypes.element,
  form: PropTypes.object,
  onParameterUpdate: PropTypes.func,
  onPopUpInformation: PropTypes.func,
  parentForm: PropTypes.object,
  parentValidation: PropTypes.object,
  promoCodeData: PropTypes.object,
};

const shell = compose(connect(mapStateToProps, mapDispatchToProps));

export default shell(StepPromoCodeDetails);
