/* eslint-disable no-unused-vars */
/* eslint-disable react/no-access-state-in-setstate */
import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import _ from 'lodash';
import { compose } from 'redux';
// Components
import {
  TextInput,
  ButtonMain,
  SelectInputMain,
  SnackBarSimple,
  LabelStatusMain,
  EmptyState,
  SkeletonMain,
  AuthenticationAccessPages,
  PrevStateValue,
} from '../../../../components/Index';
// Actions
import { getPromoCodePagination } from '../../../../services/api/PromoCodeApi';
// Helper
import {
  CommonHelper,
  MasterDataHelper,
  GlobalCodeStatus,
  promoTypeCode,
  PermissionModule,
  PermissionPage,
  PermissionAccess,
} from '../../../../helpers/Index';
// Style
import './PromoCodePageStyle.scss';
// Assets
import { Images } from '../../../../assets/Index';

const statusOption = [
  { value: null, name: 'Select All' },
  ...MasterDataHelper.statusGlobal.filter(
    item => item.value === GlobalCodeStatus.Active || item.value === GlobalCodeStatus.Inactive,
  ),
];
const typeBenefitOption = [
  { value: null, name: 'Select All' },
  ...MasterDataHelper.promoTypeOption,
];

const optionAlert = {
  vertical: 'top',
  horizontal: 'right',
};

const initialFilter = {
  search: '',
  status: statusOption[0].value,
  typeBenefit: typeBenefitOption[0].value,
};

const initialSort = {
  sortField: '',
  sortOrder: '',
};

class PromoCodePage extends React.Component {
  constructor(props) {
    super(props);

    const {
      usersReducer: { prevStateValue },
    } = props;

    const updateExitingFilter = {
      ...CommonHelper.objectCloning(initialFilter),
      ...prevStateValue,
    };

    this.state = {
      loading: true,
      pagination: {
        showSizeChanger: true,
        showTotal: (total, range) => `Showing ${range[0]}-${range[1]} From ${total}`,
        pageSizeOptions: ['10', '20', '30', '40'],
      },
      filter: updateExitingFilter,
      sort: CommonHelper.objectCloning(initialSort),
      limit: 10,
      page: 1,
      alertInformation: {
        isOpen: false,
        message: '',
        snackbarType: 'warning',
      },
    };
    this.searchDebounce = _.debounce(this.searchDebounce, 400);
  }

  componentDidMount() {
    this.getPromoCodePagination();
  }

  getPromoCodePagination = () => {
    const { promoCodePagination } = this.props;
    const { filter, pagination, page, sort, limit } = this.state;

    const params = {
      page,
      search: filter.search,
      promo_code_cashback_type: filter.typeBenefit,
      status: filter.status,
      sort: sort.sortOrder, // api got switch must fix
      order: sort.sortField,
      limit,
    };

    promoCodePagination(params).then(response => {
      this.setState({
        loading: false,
        pagination: { ...pagination, pageSize: limit, total: response.data.total },
      });
    });
  };

  handleTableChange = (pagination, filters, sorter) => {
    const { sort } = this.state;

    const isDescending = !sorter.order ? '' : 'desc';

    this.setState(
      {
        limit: pagination.pageSize,
        page: pagination.current,
        pagination: {
          ...this.state.pagination,
          current: pagination.current,
          pageSize: pagination.pageSize,
        },
        sort: {
          ...sort,
          sortField: sorter.field || initialSort.sortField,
          sortOrder: sorter.order === 'ascend' ? 'asc' : isDescending,
        },
      },
      () => {
        this.getPromoCodePagination();
      },
    );
  };

  handleChangeSearch = value => {
    const { filter } = this.state;
    this.setState(
      {
        filter: { ...filter, search: value },
        page: 1,
        pagination: { ...this.state.pagination, current: 1 },
      },
      () => {
        this.searchDebounce();
      },
    );
  };

  handleSelectChangeStatus = value => {
    const { filter } = this.state;
    this.setState(
      {
        filter: {
          ...filter,
          status: value,
        },
        page: 1,
        pagination: { ...this.state.pagination, current: 1 },
      },
      () => {
        this.getPromoCodePagination();
      },
    );
  };

  handleSelectChangePromoType = value => {
    const { filter } = this.state;
    this.setState(
      {
        filter: {
          ...filter,
          typeBenefit: value,
        },
        page: 1,
        pagination: { ...this.state.pagination, current: 1 },
      },
      () => {
        this.getPromoCodePagination();
      },
    );
  };

  searchDebounce = () => {
    this.getPromoCodePagination();
  };

  handleClickCreate = () => {
    const { onSetPrevStateValue, history } = this.props;
    const { filter } = this.state;

    onSetPrevStateValue({ ...filter, url: history.location.pathname });
    history.push('/marketing/promotion/create');
  };

  handleClickRow = value => {
    const { onSetPrevStateValue, history } = this.props;
    const { filter } = this.state;
    const param = CommonHelper.encryptObject({
      cashbackId: value,
    });

    onSetPrevStateValue({ ...filter, url: history.location.pathname });
    history.push(`/marketing/promotion/edit/${param}`);
  };

  handleCloseAlert = () => {
    const { alertInformation } = this.state;
    this.setState({ alertInformation: { ...alertInformation, isOpen: false } });
  };

  renderRowAdditional = data => {
    const startTime = CommonHelper.dateTimeParseNewFormat(data.start_time, 'DD MMM YYYY | HH:mm');
    const endTime = CommonHelper.dateTimeParseNewFormat(data.end_time, 'DD MMM YYYY | HH:mm');

    return (
      <Grid container justify="flex-start" spacing={2} className="additional-row">
        <Grid item>
          <div className="flex-column">
            <label className="text-12 mb-4 text-rolling-stone">Quota</label>
            <label className="text-12 text-mineShaft">{data.quota || '-'}</label>
          </div>
        </Grid>
        <Grid item>
          <div className="divider-hr-vertical" />
        </Grid>
        <Grid item>
          <div className="flex-column">
            <label className="text-12 mb-4 text-rolling-stone">Usage</label>
            <label className="text-12 text-mineShaft">{data.usage || '0'}</label>
          </div>
        </Grid>
        <Grid item>
          <div className="divider-hr-vertical" />
        </Grid>
        <Grid item lg={7} md={5}>
          <div className="flex-column">
            <label className="text-12 mb-4 text-rolling-stone">Active Period</label>
          </div>
          <div className="flex-row active-period">
            <label className="text-12 text-mineShaft start-period">{startTime} -</label>
            <label className="text-12 text-cinnabar end-period">{endTime}</label>
          </div>
        </Grid>
      </Grid>
    );
  };

  renderStatus = value => {
    let renderElement = <LabelStatusMain value="Active" type="complete" />;
    if (value === GlobalCodeStatus.Inactive) {
      renderElement = <LabelStatusMain value="Inactive" type="default" />;
    }
    return <div className="container-status">{renderElement}</div>;
  };

  renderColumns = () => {
    return [
      {
        title: 'Promo Name',
        dataIndex: 'name',
        sorter: true,
        render: (text, row) => {
          return (
            <Grid
              onClick={() => {
                this.handleClickRow(row.cashback_id);
              }}
              className="flex-wrap-unset flex-column"
            >
              <span className="flex-row">
                <label className="text-14 text-bold wrapping-container-break mb-3 mr-8">
                  {row.name}
                </label>
                {this.renderStatus(row.status)}
              </span>
              <label className="text-10 mb-22 text-bold text-rolling-stone">
                ID : {row.cashback_id}
              </label>
              {this.renderRowAdditional(row)}
            </Grid>
          );
        },
      },
      {
        title: 'Promo Code',
        dataIndex: 'promo_code',
        width: '20%',
        align: 'center',
        render: (text, row) => {
          const styleBenefit =
            row.promo_code_cashback_type_id === promoTypeCode.Discount
              ? 'text-jade'
              : 'text-cornflowerBlue';

          const wordingBenefit = `${`${row.percentage}%` || ''} ${row.promo_code_cashback_type}`;

          const renderElement = (
            <div className=" container-main-card-blue p-16 container-price-label flex-column">
              <label className="text-14 mb-4">{row.promo_code || '-'}</label>
              <label className={`text-12 wrapping-container ${styleBenefit}`}>
                {wordingBenefit.trim() || '-'}
              </label>
            </div>
          );

          return renderElement;
        },
      },
    ];
  };

  renderFilter() {
    const { filter } = this.state;

    return (
      <Grid container direction="row" justify="flex-start" className="container-filter">
        <Grid item xl={5} lg={3} md={3} className="row-filter">
          <TextInput
            iconPrefix="ic-ffo-search"
            placeHolderText="Search Promo Code"
            onChange={this.handleChangeSearch}
            currentValue={this.state.filter.search}
            size="md"
          />
        </Grid>
        <Grid item xl={2} lg={3} md={3} className="row-filter">
          <SelectInputMain
            options={statusOption}
            size="middle"
            currentValue={filter.status}
            onChange={this.handleSelectChangeStatus}
            placeholder={'Select Status Promo Code'}
          />
        </Grid>
        <Grid item xl={2} lg={3} md={3} className="row-filter">
          <SelectInputMain
            options={typeBenefitOption}
            size="middle"
            currentValue={filter.typeBenefit}
            onChange={this.handleSelectChangePromoType}
            placeholder={'Select Promo Code Benefit'}
          />
        </Grid>
        <Grid item xl={3} lg={3} md={3}>
          <Grid container direction="row" justify="flex-end" alignItems="center">
            <Grid item lg={10} md={8}>
              <ButtonMain
                labelText="Create Promotion"
                onClick={this.handleClickCreate}
                type="primary"
                size="md"
                startIcon="ic-ffo-add"
                requiredPermission={`${PermissionModule.Marketing}.${PermissionPage.PromoCode}.${PermissionAccess.Add}`}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  render() {
    const {
      promoCodeData: { list, fetching },
    } = this.props;
    const { loading, pagination, alertInformation } = this.state;

    let renderElement = <SkeletonMain />;

    if (!loading) {
      renderElement = (
        <div>
          <Helmet title="FITCO | Marketing - Promotions" />
          <div className="container-page-promo-code scroll-container-invisible">
            <div className="container-page-scrolling-area">
              <Grid container direction="column">
                <Grid item lg md className="section-page-header">
                  {this.renderFilter()}
                </Grid>
                <Grid item lg md className="section-page-body">
                  {_.isEmpty(list) ? (
                    <Grid container justify={'center'}>
                      <EmptyState
                        source={Images.emptyStatePlaceholder}
                        labelText={'No Promo Code Found yet'}
                      />
                    </Grid>
                  ) : (
                    <Table
                      columns={this.renderColumns()}
                      rowKey={record => record.cashback_id}
                      dataSource={list}
                      pagination={pagination}
                      loading={fetching}
                      onChange={this.handleTableChange}
                    />
                  )}
                </Grid>
                <Grid item lg md className="section-page-footer" />
              </Grid>
            </div>
          </div>
          <SnackBarSimple
            open={alertInformation.isOpen}
            durationHide={2000}
            message={alertInformation.message}
            onClickClose={this.handleCloseAlert}
            snackbarType={alertInformation.snackbarType}
            anchor={optionAlert}
          />
        </div>
      );
    }

    return renderElement;
  }
}

const mapDispatchToProps = dispatch => ({
  promoCodePagination: params => getPromoCodePagination(dispatch, params),
});

const mapStateToProps = ({ promoCodeData, usersReducer }) => ({
  promoCodeData,
  usersReducer,
});

PromoCodePage.propTypes = {
  history: PropTypes.object,
  onSetPrevStateValue: PropTypes.func,
  promoCodeData: PropTypes.object,
  promoCodePagination: PropTypes.func,
  usersReducer: PropTypes.object,
};

const shell = compose(connect(mapStateToProps, mapDispatchToProps));
const core = compose(AuthenticationAccessPages, PrevStateValue);

export default shell(core(PromoCodePage));
