import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
// Component
import { CalendarHeader, CalendarDetail } from '../Index';
// Helper
import { CalendarHelper, CommonHelper } from '../../../../../../helpers/Index';

const currentDate = CommonHelper.currentDate('YYYY-MM-DD HH:mm:ss');

class CalendarSummary extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      calendarDayList: [],
    };
  }

  componentDidMount() {
    const weekDay = CalendarHelper.getWeekDayByToday(currentDate);
    this.generateTimeLineHeader(weekDay, false);
  }

  componentWillUnmount() {}

  handleCalendarHeaderLeftClick = () => {
    const { data } = this.props;

    if (CalendarHelper.validateLoadingResult(data)) {
      const currentDateIncrement = CommonHelper.intervalDate(
        this.state.intervalDate,
        'YYYY-MM-DD',
        -6,
        'day',
      );
      const weekDay = CalendarHelper.getWeekDayByToday(currentDateIncrement);
      this.generateTimeLineHeader(weekDay, true);
    }
  };

  handleCalendarHeaderRightClick = () => {
    const { data } = this.props;

    if (CalendarHelper.validateLoadingResult(data)) {
      const currentDateIncrement = CommonHelper.intervalDate(
        this.state.intervalDate,
        'YYYY-MM-DD',
        8,
        'day',
      );
      const weekDay = CalendarHelper.getWeekDayByToday(currentDateIncrement);
      this.generateTimeLineHeader(weekDay, true);
    }
  };

  generateTimeLineHeader(weekDay, isButtonClick) {
    const { onButtonHeaderClick } = this.props;
    const listHeaderTimeLine = CalendarHelper.generateListWeek(weekDay);
    const converted = CalendarHelper.generateCalenderHeaderData(listHeaderTimeLine);

    this.setState(
      {
        intervalDate: weekDay.startWeek,
        calendarDayList: converted,
      },
      () => {
        if (isButtonClick) onButtonHeaderClick(converted);
      },
    );
  }

  render() {
    const { onCardClick, data, onClickBook, scheduleSummary, onReloadColumn } = this.props;
    const { calendarDayList } = this.state;

    return (
      <Grid container direction="row" className="container-schedule-summary">
        <Grid item lg md>
          <CalendarHeader
            data={calendarDayList}
            onButtonLeftClick={this.handleCalendarHeaderLeftClick}
            onButtonRightClick={this.handleCalendarHeaderRightClick}
            today={currentDate}
          />
          <CalendarDetail
            dataHeader={calendarDayList}
            dataTimeLine={data}
            today={currentDate}
            onCardClick={onCardClick}
            onClickBook={onClickBook}
            scheduleSummary={scheduleSummary}
            onReloadColumn={onReloadColumn}
          />
        </Grid>
      </Grid>
    );
  }
}

const mapDispatchToProps = () => ({});

const mapStateToProps = ({ scheduleSummary }) => ({ scheduleSummary });

CalendarSummary.propTypes = {
  data: PropTypes.object,
  onButtonHeaderClick: PropTypes.func,
  onCardClick: PropTypes.func,
  onClickBook: PropTypes.func,
  onReloadColumn: PropTypes.func,
  scheduleSummary: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(CalendarSummary);
