import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Grid, Breadcrumbs, Link } from '@material-ui/core';
import PropTypes from 'prop-types';
import { compose } from 'redux';
// style
import './UserRegisterStyle.scss';
// component
import {
  NavigationStep,
  SnackBarSimple,
  AuthenticationAccessPages,
  ButtonMain,
} from '../../../../components/Index';
import { ModalFaceScanner, ModalSelfieScanner } from '../components/Index';
import { StepRegisterUserDetails, StepRegisterUserAccess } from './views/Index';
// action
import { registerNewUser } from '../../../../services/api/UsersApi';
// helper
import {
  CommonHelper,
  PermissionModule,
  PermissionPage,
  PermissionAccess,
} from '../../../../helpers/Index';

const optionToast = {
  vertical: 'top',
  horizontal: 'right',
};

class UserRegister extends React.Component {
  constructor(props) {
    super(props);

    props.checkUserAccessPermission(
      PermissionModule.UserManagement,
      PermissionPage.Users,
      PermissionAccess.View,
    );

    this.state = {
      current: 0,
      stepList: [
        { key: 'user_detail', name: 'User Detail' },
        { key: 'user_access', name: 'User Access' },
      ],
      toastInformation: {
        isOpen: false,
        message: '',
        snackbarType: 'warning',
      },
      isOpen: false,
      validate: false,
      data: {},
      isOpenSelfie: false,
      validationStatus: {},
      isLoading: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.validationStatus !== this.state.validationStatus) {
      const { validationStatus } = this.state;
      // const { identityImage, selfieImage } = this.props;
      const validationKey = Object.keys(validationStatus);
      const errorValue = validationKey.map(key => validationStatus[key].isError);
      const isValidate = errorValue.every(item => item === false);

      // if (!identityImage || !selfieImage) {
      //   this.processMessage('Please upload identity and selfie image.', 'error');
      //   return;
      // }

      if (!isValidate) {
        this.processMessage('Please complete all data.', 'error');
        return;
      }

      this.userRegister();
    }
  }

  userRegister = async () => {
    const { registerUser, history } = this.props;
    const { data } = this.state;
    const { identityImage, selfieImage } = this.props;

    this.setState({
      isLoading: true,
    });

    try {
      const params = {
        contact_details: { ...data.contact_details },
        personal_details: {
          name: data.personal_information.name,
          identity_type: data.personal_information.identity_type,
          identity_number: data.personal_information.idNumber,
          place_of_birth: data.personal_information.birthPlaceBirthday,
          date_of_birth: data.personal_information.birthday,
          gender: data.personal_information.gender.value,
          religion: data.personal_information.religion,
          identity_address: {
            address: data.personal_information.address,
            rt: data.personal_information.rt,
            rw: data.personal_information.rw,
            village: data.personal_information.village,
            district: data.personal_information.district,
            city: data.personal_information.city,
            province: data.personal_information.province,
          },
          country_id: data.personal_information.country_id,
          marriage_status: data.personal_information.maritalStatus,
          occupation: data.personal_information.occupation,
          selfie_photo: selfieImage || null,
          identity_photo: identityImage || null,
        },
        user_settings: {
          user_type_id: 0,
          user_status_id: 10,
          role_id: null,
          is_staff: false,
          is_fitco_ops: 0,
          company_id: null,
          all_branch_and_merchant: false,
          merchant_access: [],
          branch_access: [],
        },
        covid_test: { ...data.covid_test },
      };
      const { messages, status } = await registerUser(params);
      this.processMessage(messages, status);
      this.setState({
        isLoading: false,
      });
      setTimeout(() => {
        history.push('/management/users');
      }, 2000);
    } catch (error) {
      this.processMessage(error.message, 'error');
      this.setState({
        isLoading: false,
      });
    }
  };

  handleFormUpdate = (payload, key) => {
    const { data } = this.state;
    this.setState({
      data: {
        ...data,
        [key]: payload,
      },
    });
  };

  handleValidationUpdate = (payload, key) => {
    const { validate } = this.state;

    if (validate) {
      this.setState({
        validate: !validate,
      });
    }

    this.setState(oldState => {
      return {
        validationStatus: {
          ...oldState.validationStatus,
          [key]: payload,
        },
      };
    });
  };

  handleClick = (event, value) => {
    const { history } = this.props;
    event.preventDefault();
    history.push(value);
  };

  handleButtonClickCancel = () => {
    const { history } = this.props;
    history.push('/management/users');
  };

  handleButtonClickNext = async () => {
    // const { current } = this.state;
    // const currentStep = current;
    // this.setState({ current: currentStep + 1 });
    this.setState({ validate: true });
  };

  handleButtonClickPrev = () => {
    const { current } = this.state;
    const currentStep = current;
    this.setState({ current: currentStep - 1 });
  };

  handleOpenCloseModal = (type, value) => {
    if (type === 'selfie') {
      this.setState({ isOpenSelfie: value });
    } else {
      this.setState({ isOpen: value });
    }
  };

  handleCloseToast = () => {
    const { toastInformation } = this.state;
    this.setState({ toastInformation: { ...toastInformation, isOpen: false } });
  };

  processMessage(messages, type) {
    const convertedMessage = CommonHelper.generateMessage(messages);

    const paramInformation = {
      isOpen: true,
      message: convertedMessage,
      snackbarType: type,
    };

    this.setState({
      toastInformation: paramInformation,
    });
  }

  renderActiveContent() {
    const { stepList, current, validate } = this.state;
    let renderElement = null;

    switch (stepList[current].key) {
      case stepList[1].key:
        renderElement = (
          <Grid item lg md>
            <StepRegisterUserAccess currentValue={current} totalStepper={2} />
          </Grid>
        );
        break;
      default:
        renderElement = (
          <Grid item lg md>
            <StepRegisterUserDetails
              onFormUpdate={this.handleFormUpdate}
              onValidationUpdate={this.handleValidationUpdate}
              validate={validate}
              currentValue={current}
              totalStepper={2}
            />
          </Grid>
        );
        break;
    }

    return renderElement;
  }

  render() {
    const { current, stepList, toastInformation, isOpen, isLoading, isOpenSelfie } = this.state;
    const { identityImage } = this.props;
    const prevUrl = '/management/users';

    const isDisabledSefieUpload = !identityImage;

    return (
      <div>
        <Helmet title="FITCO | User Managements - Create User" />
        <div className="container-page-register-user scroll-container-invisible">
          <div className="container-page-scrolling-area">
            <Grid container direction="column">
              <Grid item lg md className="section-page-header mv-24">
                <div className="breadcrumbs-section">
                  <Breadcrumbs aria-label="breadcrumb">
                    <Link
                      className="text-12"
                      color="inherit"
                      href={prevUrl}
                      onClick={event => {
                        this.handleClick(event, prevUrl);
                      }}
                    >
                      <i className="ic-ffo-calendar container-icon-prefix size-16" />
                      Health Passport
                    </Link>
                    <label className="text-12" color="inherit">
                      Register New User
                    </label>
                  </Breadcrumbs>
                </div>
              </Grid>
              <Grid item lg md className="section-page-body">
                <Grid container className="header-container">
                  <Grid item lg={8} md={8}>
                    <label className="text-16 text-bold text-rolling-stone">
                      Register New User
                    </label>
                  </Grid>
                  {current === 0 && (
                    <Grid item lg md>
                      <div className="row-center">
                        <div className="button-container">
                          <ButtonMain
                            type="primary"
                            labelText="Scan KTP"
                            onClick={() => this.handleOpenCloseModal('identity', true)}
                          />
                        </div>
                        <div className="button-container">
                          <ButtonMain
                            type="primary"
                            labelText="Take a Selfie"
                            onClick={() => this.handleOpenCloseModal('selfie', true)}
                            disabled={isDisabledSefieUpload}
                          />
                        </div>
                      </div>
                    </Grid>
                  )}
                </Grid>
                <Grid item lg={5} md={5} className="mb-24">
                  <NavigationStep currentValue={current} arrayLabel={stepList} />
                </Grid>
                {this.renderActiveContent()}
              </Grid>
              <Grid item lg md className="section-page-footer">
                <Grid item lg={12} md={12}>
                  <Grid container justify="flex-end">
                    <Grid item lg={5} md={6}>
                      <Grid container justify="flex-end">
                        <Grid item lg={4} md={4} className="pl-8">
                          <ButtonMain
                            type="negative"
                            size="xl"
                            labelText="Cancel"
                            isLoading={isLoading}
                            onClick={this.handleButtonClickCancel}
                          />
                        </Grid>
                        {/* {current > 0 && (
                          <Grid item lg={4} md={4} className="pl-8">
                            <ButtonMain
                              type="ghost"
                              size="xl"
                              labelText="Prev"
                              onClick={this.handleButtonClickPrev}
                            />
                          </Grid>
                        )} */}
                        <Grid item lg={4} md={4} className="pl-8">
                          <ButtonMain
                            type="primary"
                            size="xl"
                            labelText={'Submit'}
                            isLoading={isLoading}
                            onClick={this.handleButtonClickNext}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>
        <SnackBarSimple
          open={toastInformation.isOpen}
          durationHide={2000}
          message={toastInformation.message}
          onClickClose={this.handleCloseToast}
          snackbarType={toastInformation.snackbarType}
          anchor={optionToast}
        />
        <ModalFaceScanner
          isOpen={isOpen}
          onClose={() => this.handleOpenCloseModal('identity', false)}
        />
        <ModalSelfieScanner
          isOpen={isOpenSelfie}
          onClose={() => this.handleOpenCloseModal('selfie', false)}
        />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  registerUser: params => registerNewUser(dispatch, params),
});

const mapStateToProps = ({ usersReducer }) => ({
  identityImage: usersReducer.setIdentityImage,
  selfieImage: usersReducer.setSelfieImage,
});

UserRegister.propTypes = {
  checkUserAccessPermission: PropTypes.func,
  history: PropTypes.object,
  identityImage: PropTypes.bool,
  registerUser: PropTypes.func,
  selfieImage: PropTypes.bool,
};

const shell = compose(connect(mapStateToProps, mapDispatchToProps));

export default shell(AuthenticationAccessPages(UserRegister));
