import * as masterAction from '../../redux/actions/MasterAction';
import Api from '../../middleware/FetchMiddleware';
import { HttpAction } from '../../helpers/HttpHelper';
import { baseUrl } from '../../environments/Environment';

export const getMerchantList = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(masterAction.getMerchantListRequest());
    Api.callApi(`${baseUrl}private/pos/v2/merchant/filter/list`, HttpAction.Get, params, {})
      .then(response => {
        dispatch(masterAction.getMerchantListSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(masterAction.getMerchantListFail(e));
        reject(e);
      });
  });
};

export const getMerchantListV2 = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(masterAction.getMerchantListRequest());
    Api.callApi(`${baseUrl}private/pos/v2/merchant/list`, HttpAction.Get, params, {})
      .then(response => {
        dispatch(masterAction.getMerchantListSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(masterAction.getMerchantListFail(e));
        reject(e);
      });
  });
};

export const getBranchList = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(masterAction.getBranchListRequest());
    Api.callApi(`${baseUrl}private/pos/v2/branch/filter/list`, HttpAction.Get, params, {})
      .then(response => {
        dispatch(masterAction.getBranchListSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(masterAction.getBranchListFail(e));
        reject(e);
      });
  });
};

export const getCompanyList = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(masterAction.getCompanyListRequest());
    Api.callApi(`${baseUrl}private/pos/v2/company/filter/list`, HttpAction.Get, params, {})
      .then(response => {
        dispatch(masterAction.getCompanyListSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(masterAction.getCompanyListFail(e));
        reject(e);
      });
  });
};

export const getRoleList = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(masterAction.getRoleListRequest());
    Api.callApi(`${baseUrl}private/pos/v2/roles/filter/list`, HttpAction.Get, params, {})
      .then(response => {
        dispatch(masterAction.getRoleListSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(masterAction.getRoleListFail(e));
        reject(e);
      });
  });
};

export const getClassList = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(masterAction.getClassListRequest());
    Api.callApi(`${baseUrl}private/pos/v2/product/class/filter/list`, HttpAction.Get, params, {})
      .then(response => {
        dispatch(masterAction.getClassListSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(masterAction.getClassListFail(e));
        reject(e);
      });
  });
};

export const getCoachList = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(masterAction.getCoachListRequest());
    Api.callApi(`${baseUrl}private/pos/v2/trainer/filter/list`, HttpAction.Get, params, {})
      .then(response => {
        dispatch(masterAction.getCoachListSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(masterAction.getCoachListFail(e));
        reject(e);
      });
  });
};

export const getPermissionList = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(masterAction.getPermissionListRequest());
    Api.callApi(`${baseUrl}private/pos/v2/permission/list/available?`, HttpAction.Get, params, {})
      .then(response => {
        dispatch(masterAction.getPermissionListSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(masterAction.getPermissionListFail(e));
        reject(e);
      });
  });
};

export const getScreenDeeplinkList = dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(masterAction.getScreenDeeplinkListRequest());
    Api.callApi(`${baseUrl}marketing/deeplink-screens`, HttpAction.Get, {}, {})
      .then(response => {
        dispatch(masterAction.getScreenDeeplinkListSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(masterAction.getScreenDeeplinkListFail(e));
        reject(e);
      });
  });
};

export const getTimeRange = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    Api.callApi(`${baseUrl}private/pos/v2/schedule/time/filter/list`, HttpAction.Get, params, {})
      .then(response => {
        return resolve(response.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

export const getProvinceList = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(masterAction.getProvinceListRequest());
    Api.callApi(
      `${baseUrl}private/pos/v2/master-area/province/filter/list`,
      HttpAction.Get,
      params,
      {},
    )
      .then(response => {
        dispatch(masterAction.getProvinceListSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(masterAction.getProvinceListFail(e));
        reject(e);
      });
  });
};

export const getCityList = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(masterAction.getCityListRequest());
    Api.callApi(`${baseUrl}private/pos/v2/master-area/city/filter/list`, HttpAction.Get, params, {})
      .then(response => {
        dispatch(masterAction.getCityListSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(masterAction.getCityListFail(e));
        reject(e);
      });
  });
};

export const getDistrictList = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(masterAction.getDistrictListRequest());
    Api.callApi(
      `${baseUrl}private/pos/v2/master-area/district/filter/list`,
      HttpAction.Get,
      params,
      {},
    )
      .then(response => {
        dispatch(masterAction.getDistrictListSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(masterAction.getDistrictListFail(e));
        reject(e);
      });
  });
};

export const getVillageList = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(masterAction.getVillageListRequest());
    Api.callApi(
      `${baseUrl}private/pos/v2/master-area/village/filter/list`,
      HttpAction.Get,
      params,
      {},
    )
      .then(response => {
        dispatch(masterAction.getVillageListSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(masterAction.getVillageListFail(e));
        reject(e);
      });
  });
};

export const getBankDetails = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(masterAction.getBankDetailsRequest());
    Api.callApi(`${baseUrl}private/pos/v2/merchant/bank`, HttpAction.Get, params, {})
      .then(response => {
        dispatch(masterAction.getBankDetailsSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(masterAction.getBankDetailsFail(e));
        reject(e);
      });
  });
};

export const getSegmentList = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(masterAction.getSegmentListRequest());
    Api.callApi(`${baseUrl}private/pos/v2/user-segment/filter`, HttpAction.Get, params, {})
      .then(response => {
        dispatch(masterAction.getSegmentListSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(masterAction.getSegmentListFail(e));
        reject(e);
      });
  });
};

export const getFeedCategoryList = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(masterAction.getFeedCategoryListRequest());
    Api.callApi(`${baseUrl}private/pos/v2/feeds/categories`, HttpAction.Get, params, {})
      .then(response => {
        dispatch(masterAction.getFeedCategoryListSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(masterAction.getFeedCategoryListFail(e));
        reject(e);
      });
  });
};

export const getPaymentMethodList = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(masterAction.getPaymentMethodListRequest());
    Api.callApi(`${baseUrl}private/pos/v2/cashback/payment/method`, HttpAction.Get, params, {})
      .then(response => {
        dispatch(masterAction.getPaymentMethodListSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(masterAction.getPaymentMethodListFail(e));
        reject(e);
      });
  });
};

export const getProductEatPackageList = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(masterAction.getProductEatPackageListRequest());
    Api.callApi(
      `${baseUrl}private/pos/v2/cashback/product/eats/package`,
      HttpAction.Get,
      params,
      {},
    )
      .then(response => {
        dispatch(masterAction.getProductEatPackageListSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(masterAction.getProductEatPackageListFail(e));
        reject(e);
      });
  });
};

export const getProductTypeList = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(masterAction.getProductTypeListRequest());
    Api.callApi(
      `${baseUrl}private/pos/v2/cashback/product-type/type/list`,
      HttpAction.Get,
      params,
      {},
    )
      .then(response => {
        dispatch(masterAction.getProductTypeListSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(masterAction.getProductTypeListFail(e));
        reject(e);
      });
  });
};

export const getProductCategoryList = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(masterAction.getProductCategoryListRequest());
    Api.callApi(
      `${baseUrl}private/pos/v2/cashback/product/merchant/category`,
      HttpAction.Get,
      params,
      {},
    )
      .then(response => {
        dispatch(masterAction.getProductCategoryListSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(masterAction.getProductCategoryListFail(e));
        reject(e);
      });
  });
};

export const getProductPromoCodeMappingList = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(masterAction.getProductPromoCodeMappingListRequest());
    Api.callApi(`${baseUrl}private/pos/v2/cashback/product/search`, HttpAction.Get, params, {})
      .then(response => {
        dispatch(masterAction.getProductPromoCodeMappingListSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(masterAction.getProductPromoCodeMappingListFail(e));
        reject(e);
      });
  });
};

export const getProductSportClinic = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(masterAction.getProductSportClinicRequest());
    Api.callApi(`${baseUrl}public/mobile/move/sport-clinic`, HttpAction.Post, {}, params)
      .then(response => {
        dispatch(masterAction.getProductSportClinicSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(masterAction.getProductSportClinicFail(e));
        reject(e);
      });
  });
};

export const getConsultationBranchList = dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(masterAction.getBranchListRequest());
    Api.callApi(`${baseUrl}public/mobile/medic/consultation/branches`, HttpAction.Get, {}, {})
      .then(response => {
        dispatch(masterAction.getBranchListSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(masterAction.getBranchListFail(e));
        reject(e);
      });
  });
};

export const getMedicConsultationDetails = dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(masterAction.getMedicConsultationDetailsRequest());
    Api.callApi(`${baseUrl}public/mobile/medic/consultation`, HttpAction.Get, {}, {})
      .then(response => {
        dispatch(masterAction.getMedicConsultationDetailsSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(masterAction.getMedicConsultationDetailsFail(e));
        reject(e);
      });
  });
};

// Start Workout Category Master Data
export const getWorkoutCategoryTab = dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(masterAction.getWorkoutCategoryTabRequest());
    Api.callApi(`${baseUrl}private/pos/move/workout-category`, HttpAction.Get, {}, {})
      .then(response => {
        dispatch(masterAction.getWorkoutCategoryTabSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(masterAction.getWorkoutCategoryTabFail(e));
        reject(e);
      });
  });
};

export const getWorkoutCategoryProduct = (dispatch, category) => {
  return new Promise((resolve, reject) => {
    dispatch(masterAction.getWorkoutCategoryProductRequest());
    Api.callApi(
      `${baseUrl}private/pos/move/workout-category/${category}/product`,
      HttpAction.Get,
      {},
      {},
    )
      .then(response => {
        dispatch(masterAction.getWorkoutCategoryProductSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(masterAction.getWorkoutCategoryProductFail(e));
        reject(e);
      });
  });
};

export const getWorkoutCategoryBranch = (dispatch, category) => {
  return new Promise((resolve, reject) => {
    dispatch(masterAction.getWorkoutCategoryBranchRequest());
    Api.callApi(
      `${baseUrl}private/pos/move/workout-category/${category}/branch`,
      HttpAction.Get,
      {},
      {},
    )
      .then(response => {
        dispatch(masterAction.getWorkoutCategoryBranchSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(masterAction.getWorkoutCategoryBranchFail(e));
        reject(e);
      });
  });
};

export const getWorkoutCategoryStatus = dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(masterAction.getWorkoutCategoryStatusRequest());
    Api.callApi(
      `${baseUrl}private/pos/move/workout-category/schedule-status`,
      HttpAction.Get,
      {},
      {},
    )
      .then(response => {
        dispatch(masterAction.getWorkoutCategoryStatusSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(masterAction.getWorkoutCategoryStatusFail(e));
        reject(e);
      });
  });
};

export const getWorkoutCategoryAllCoach = (dispatch, category) => {
  return new Promise((resolve, reject) => {
    dispatch(masterAction.getWorkoutCategoryAllCoachRequest());
    Api.callApi(
      `${baseUrl}private/pos/move/workout-category/${category}/coach`,
      HttpAction.Get,
      {},
      {},
    )
      .then(response => {
        dispatch(masterAction.getWorkoutCategoryAllCoachSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(masterAction.getWorkoutCategoryAllCoachFail(e));
        reject(e);
      });
  });
};

export const getWorkoutProductByCoach = (dispatch, trainerId) => {
  return new Promise((resolve, reject) => {
    dispatch(masterAction.getWorkoutProductByCoachRequest());
    Api.callApi(`${baseUrl}private/pos/move/coach/${trainerId}/product`, HttpAction.Get, {}, {})
      .then(response => {
        dispatch(masterAction.getWorkoutProductByCoachSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(masterAction.getWorkoutProductByCoachFail(e));
        reject(e);
      });
  });
};
// End Workout Category Master Data

export const getCoachesByProduct = (dispatch, productId) => {
  return new Promise((resolve, reject) => {
    dispatch(masterAction.getCoachesByProductRequest());
    Api.callApi(`${baseUrl}private/pos/move/product/${productId}/coach`, HttpAction.Get, {}, {})
      .then(response => {
        dispatch(masterAction.getCoachesByProductSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(masterAction.getCoachesByProductFail(e));
        reject(e);
      });
  });
};
// End Coaches By Product

export const getBranchesByProduct = (dispatch, productId) => {
  return new Promise((resolve, reject) => {
    dispatch(masterAction.getBranchesByProductRequest());
    Api.callApi(`${baseUrl}private/pos/move/product/${productId}/branch`, HttpAction.Get, {}, {})
      .then(response => {
        dispatch(masterAction.getBranchesByProductSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(masterAction.getBranchesByProductFail(e));
        reject(e);
      });
  });
};
// End Branches By Product

export const getConsultationBranches = dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(masterAction.getConsultationBranchesRequest());
    Api.callApi(`${baseUrl}public/mobile/medic/consultation/branches`, HttpAction.Get, {}, {})
      .then(response => {
        dispatch(masterAction.getConsultationBranchesSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(masterAction.getConsultationBranchesFail(e));
        reject(e);
      });
  });
};
// End Consultation Branches

export const getConsultationProductType = dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(masterAction.getConsultationProductTypeRequest());
    Api.callApi(
      `${baseUrl}private/pos/medic/consultation/add-on/product-type`,
      HttpAction.Get,
      {},
      {},
    )
      .then(response => {
        dispatch(masterAction.getConsultationProductTypeSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(masterAction.getConsultationProductTypeFail(e));
        reject(e);
      });
  });
};
// End Consultation Product Type

export const getProductByProductType = (dispatch, productType) => {
  return new Promise((resolve, reject) => {
    dispatch(masterAction.getProductByProductTypeRequest());
    Api.callApi(
      `${baseUrl}private/pos/medic/consultation/add-on/product-type/${productType}`,
      HttpAction.Get,
      {},
      {},
    )
      .then(response => {
        dispatch(masterAction.getProductByProductTypeSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(masterAction.getProductByProductTypeFail(e));
        reject(e);
      });
  });
};
// End Product By Product Type

export const getConsultationProductDetail = dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(masterAction.getConsultationProductDetailRequest());
    Api.callApi(`${baseUrl}public/mobile/medic/consultation`, HttpAction.Get, {}, {})
      .then(response => {
        dispatch(masterAction.getConsultationProductDetailSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(masterAction.getConsultationProductDetailFail(e));
        reject(e);
      });
  });
};
// End Consultation Product Detail

export const getCountryList = dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(masterAction.getCountryListRequest());
    Api.callApi(`${baseUrl}public/mobile/master-data/country`, HttpAction.Get, {}, {})
      .then(response => {
        dispatch(masterAction.getCountryListSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(masterAction.getCountryListFail(e));
        reject(e);
      });
  });
};
// End Country List

export const getClinicBranch = (dispatch, category) => {
  return new Promise((resolve, reject) => {
    dispatch(masterAction.getClinicBranchRequest());
    Api.callApi(
      `${baseUrl}private/pos/medic/clinic-category/${category}/branch`,
      HttpAction.Get,
      {},
      {},
    )
      .then(response => {
        dispatch(masterAction.getClinicBranchSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(masterAction.getClinicBranchFail(e));
        reject(e);
      });
  });
};
// End Clinic Branch

export const getCustomerList = (dispatch, body) => {
  return new Promise((resolve, reject) => {
    dispatch(masterAction.getCustomerListRequest());
    Api.callApi(`${baseUrl}private/pos/v2/orders/check-users-identity`, HttpAction.Post, {}, body)
      .then(response => {
        dispatch(masterAction.getCustomerListSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(masterAction.getCustomerListFail(e));
        reject(e);
      });
  });
};
// End Customers List

export const getCovidTestMethod = dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(masterAction.getCovidTestMethodRequest());
    Api.callApi(
      `${baseUrl}public/mobile/health-passport/master-data/medical-record-type`,
      HttpAction.Get,
      {},
      {},
    )
      .then(response => {
        dispatch(masterAction.getCovidTestMethodSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(masterAction.getCovidTestMethodFail(e));
        reject(e);
      });
  });
};

export const getVoucherList = dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(masterAction.getVoucherListRequest());
    Api.callApi(
      `${baseUrl}v2/private/mobile/user/135064/inbox/voucher/category`,
      HttpAction.Get,
      {},
      {},
    )
      .then(response => {
        dispatch(masterAction.getVoucherListSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(masterAction.getVoucherListFail(e));
        reject(e);
      });
  });
};
