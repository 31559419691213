/* eslint-disable react/no-access-state-in-setstate */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Grid } from '@material-ui/core';
import { compose } from 'redux';
import { List } from 'antd';
import _ from 'lodash';
import ReactHtmlParser from 'react-html-parser';
// Style
import './FeedPageStyle.scss';
// component
import {
  SnackBarSimple,
  ButtonMain,
  LabelStatusMain,
  TextInput,
  AuthenticationAccessPages,
  CardGalleryContent,
  PaginationList,
  SelectInputFeedCategory,
  SkeletonGrid,
} from '../../../../components/Index';
import { ModalFeedDetail } from '../components/Index';
// Action
import { getListFeedPagination } from '../../../../services/api/FeedApi';
// Helper
import { CommonHelper, GlobalCodeStatus, MasterDataHelper, PermissionAccess, PermissionModule, PermissionPage } from '../../../../helpers/Index';

const optionToash = {
  vertical: 'top',
  horizontal: 'right',
};

const initialFilter = {
  search: '',
  categoryId: null,
};

const paginationGridOption = {
  gutter: 16,
  column: 3,
};

class FeedPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: CommonHelper.objectCloning(initialFilter),
      page: 1,
      limit: 9,
      pagination: {
        showSizeChanger: true,
        showTotal: (total, range) => `Showing ${range[0]}-${range[1]} From ${total}`,
        pageSizeOptions: ['9', '18', '27', '36'],
      },
      isLoading: true,
      isOpenModal: false,
      toastInformation: {
        isOpen: false,
        message: '',
        snackbarType: 'warning',
      },
    };
    this.searchDebounce = _.debounce(this.searchDebounce, 400);
  }

  componentDidMount() {
    this.getListFeedPagination();
  }

  handleCloseToash = () => {
    const { toastInformation } = this.state;
    this.setState({ toastInformation: { ...toastInformation, isOpen: false } });
  };

  handleChangeSearch = value => {
    const { filter } = this.state;

    this.setState(
      {
        filter: { ...filter, search: value },
        page: 1,
        pagination: { ...this.state.pagination, current: 1 },
      },
      () => {
        this.searchDebounce();
      },
    );
  };

  handleChangeCategory = value => {
    const { filter } = this.state;

    this.setState(
      {
        filter: { ...filter, categoryId: value },
        page: 1,
        pagination: { ...this.state.pagination, current: 1 },
      },
      () => {
        this.getListFeedPagination();
      },
    );
  };

  searchDebounce = () => {
    this.getListFeedPagination();
  };

  getListFeedPagination = () => {
    const { listFeedPagination } = this.props;
    const {
      limit,
      pagination,
      page,
      filter: { search, categoryId },
    } = this.state;

    const params = {
      search,
      category_id: categoryId,
      page,
      limit,
    };

    listFeedPagination(params).then(response => {
      this.setState({
        isLoading: false,
        pagination: { ...pagination, pageSize: limit, total: response.data.total },
      });
    });
  };

  handleButtonClickAdd = () => {
    const { history } = this.props;
    history.push('/marketing/feed/add');
  };

  handleCardClick = item => {
    this.setState({ isOpenModal: true, selectedModalItemId: item.feeds_id });
  };

  generateStatus = status => {
    switch (status) {
      case GlobalCodeStatus.Inactive:
        return (
          <div className="pt-8">
            <LabelStatusMain value={'Inactive'} type="danger" />
          </div>
        );
      case GlobalCodeStatus.Deleted:
        return (
          <div className="pt-8">
            <LabelStatusMain value={'Deleted'} type="danger" />
          </div>
        );
      default:
        return null;
    }
  };

  handleFeedModalClose = value => {
    this.setState({ isOpenModal: value }, () => {
      this.getListFeedPagination();
    });
  };

  handlePaginationPageChange = value => {
    this.setState({ page: value }, () => {
      this.getListFeedPagination();
    });
  };

  handlePaginationPageSizeChange = value => {
    const { pagination } = this.state;
    this.setState(
      {
        limit: value,
        page: 1,
        pagination: { ...pagination, pageSize: value },
      },
      () => {
        this.getListFeedPagination();
      },
    );
  };

  renderFilter() {
    return (
      <Grid container justify="flex-start">
        <Grid item lg={4} md={4} className="container-filter">
          <TextInput
            iconPrefix="ic-ffo-search"
            placeHolderText={'Search Feeds Name'}
            onChange={this.handleChangeSearch}
            size="md"
          />
        </Grid>
        <Grid item lg={3} md={3} className="container-filter">
          <SelectInputFeedCategory
            placeHolder="Select Category"
            onChange={this.handleChangeCategory}
            includeAllData
          />
        </Grid>
        <Grid item lg md>
          <Grid container justify="flex-end" alignItems="center">
            <Grid item>
              <ButtonMain
                labelText="Create Feed"
                onClick={this.handleButtonClickAdd}
                type="primary"
                startIcon="ic-ffo-add"
                requiredPermission={`${PermissionModule.Marketing}.${PermissionPage.Feeds}.${PermissionAccess.Add}`}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  renderModalDetail = () => {
    const { isOpenModal, selectedModalItemId } = this.state;
    const { history } = this.props;
    const elementRender = (
      <ModalFeedDetail
        isOpen={isOpenModal}
        id={selectedModalItemId}
        onClose={this.handleFeedModalClose}
        history={history}
      />
    );

    return isOpenModal ? elementRender : null;
  };

  render() {
    const { toastInformation, isLoading, pagination, isOpenModal } = this.state;
    const {
      feedData: { list, fetching },
    } = this.props;

    return (
      <div className="container-page-feed scroll-container">
        <Helmet title="FITCO | Marketing - Feed" />
        <div className="container-page-scrolling-area">
          <Grid container direction="column">
            <Grid item lg md className="section-page-header">
              {this.renderFilter()}
            </Grid>
            <Grid item lg md className="section-page-body">
              {isLoading ? (
                <SkeletonGrid />
              ) : (
                <PaginationList
                  data={list}
                  grid={paginationGridOption}
                  pagination={pagination}
                  onChange={this.handlePaginationPageChange}
                  onShowSizeChange={this.handlePaginationPageSizeChange}
                  loading={fetching}
                  renderItem={item => {
                    const ifUrlDeepLink = item.media_type === MasterDataHelper.mediaOption[1].value;
                    const mediaSource =
                      !_.isEmpty(item.media) && !ifUrlDeepLink ? item.media[0].source : undefined;
                    const resultDescription = CommonHelper.removeHTMLTag(item.feeds_description);

                    return (
                      <List.Item>
                        <CardGalleryContent
                          onClick={() => this.handleCardClick(item)}
                          image={mediaSource}
                          title={item.feeds_title}
                          body={ReactHtmlParser(resultDescription)}
                          customElementsImage={
                            <div className="flex-row container-text-image-information mt-8">
                              <label className="text-10 mr-4 wrapping-container-break wrapping-container second line">
                                {`Publish on ${CommonHelper.dateTimeParseNewFormat(
                                  item.publish_date,
                                  'DD MMMM YYYY, h:MM A',
                                )} `}
                                &bull;
                                {` Created by ${item.created_by}`}
                              </label>
                              <LabelStatusMain type="complete" value={item.category_name} />
                            </div>
                          }
                          customElementsHeader={this.generateStatus(item.status)}
                        />
                      </List.Item>
                    );
                  }}
                />
              )}
            </Grid>
            <Grid item lg md className="section-page-footer" />
          </Grid>
          <SnackBarSimple
            open={toastInformation.isOpen}
            durationHide={2000}
            message={toastInformation.message}
            onClickClose={this.handleCloseToash}
            snackbarType={toastInformation.snackbarType}
            anchor={optionToash}
          />
        </div>
        {isOpenModal && this.renderModalDetail()}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  listFeedPagination: params => getListFeedPagination(dispatch, params),
});

const mapStateToProps = ({ feedData }) => ({
  feedData,
});

FeedPage.propTypes = {
  feedData: PropTypes.object,
  history: PropTypes.object,
  listFeedPagination: PropTypes.func,
};

const shell = compose(connect(mapStateToProps, mapDispatchToProps));

export default shell(AuthenticationAccessPages(FeedPage));
