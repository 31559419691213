export const constants = {
  getMerchantListRequest: 'GET_MERCHANT_LIST_REQUEST',
  getMerchantListSuccess: 'GET_MERCHANT_LIST_SUCCESS',
  getMerchantListFail: 'GET_MERCHANT_LIST_FAIL',
  getBranchListRequest: 'GET_BRANCH_LIST_REQUEST',
  getBranchListSuccess: 'GET_BRANCH_LIST_SUCCESS',
  getBranchListFail: 'GET_BRANCH_LIST_FAIL',
  getCompanyListRequest: 'GET_COMPANY_LIST_REQUEST',
  getCompanyListSuccess: 'GET_COMPANY_LIST_SUCCESS',
  getCompanyListFail: 'GET_COMPANY_LIST_FAIL',
  getRoleListRequest: 'GET_ROLE_LIST_REQUEST',
  getRoleListSuccess: 'GET_ROLE_LIST_SUCCESS',
  getRoleListFail: 'GET_ROLE_LIST_FAIL',
  getCoachListRequest: 'GET_COACH_LIST_REQUEST',
  getCoachListSuccess: 'GET_COACH_LIST_SUCCESS',
  getCoachListFail: 'GET_COACH_LIST_FAIL',
  getClassListRequest: 'GET_CLASS_LIST_REQUEST',
  getClassListSuccess: 'GET_CLASS_LIST_SUCCESS',
  getClassListFail: 'GET_CLASS_LIST_FAIL',
  getPermissionListRequest: 'GET_PERMISSION_LIST_REQUEST',
  getPermissionListSuccess: 'GET_PERMISSION_LIST_SUCCESS',
  getPermissionListFail: 'GET_PERMISSION_LIST_FAIL',
  getScreenDeeplinkListRequest: 'GET_SCREEN_DEEPLINK_LIST_REQUEST',
  getScreenDeeplinkListSuccess: 'GET_SCREEN_DEEPLINK_LIST_SUCCESS',
  getScreenDeeplinkListFail: 'GET_SCREEN_DEEPLINK_LIST_FAIL',
  getProvinceListRequest: 'GET_PROVINCE_LIST_REQUEST',
  getProvinceListSuccess: 'GET_PROVINCE_LIST_SUCCESS',
  getProvinceListFail: 'GET_PROVINCE_LIST_FAIL',
  getCityListRequest: 'GET_CITY_LIST_REQUEST',
  getCityListSuccess: 'GET_CITY_LIST_SUCCESS',
  getCityListFail: 'GET_CITY_LIST_FAIL',
  getDistrictListRequest: 'GET_DISTRICT_LIST_REQUEST',
  getDistrictListSuccess: 'GET_DISTRICT_LIST_SUCCESS',
  getDistrictListFail: 'GET_DISTRICT_LIST_FAIL',
  getVillageListRequest: 'GET_VILLAGE_LIST_REQUEST',
  getVillageListSuccess: 'GET_VILLAGE_LIST_SUCCESS',
  getVillageListFail: 'GET_VILLAGE_LIST_FAIL',
  getBankDetailsRequest: 'GET_BANK_DETAILS_REQUEST',
  getBankDetailsSuccess: 'GET_BANK_DETAILS_SUCCESS',
  getBankDetailsFail: 'GET_BANK_DETAILS_FAIL',
  getSegmentListRequest: 'GET_SEGMENT_LIST_REQUEST',
  getSegmentListSuccess: 'GET_SEGMENT_LIST_SUCCESS',
  getSegmentListFail: 'GET_SEGMENT_LIST_FAIL',
  getFeedCategoryListRequest: 'GET_FEED_CATEGORY_LIST_REQUEST',
  getFeedCategoryListSuccess: 'GET_FEED_CATEGORY_LIST_SUCCESS',
  getFeedCategoryListFail: 'GET_FEED_CATEGORY_LIST_FAIL',
  getPaymentMethodListRequest: 'GET_PAYMENT_METHOD_LIST_REQUEST',
  getPaymentMethodListSuccess: 'GET_PAYMENT_METHOD_LIST_SUCCESS',
  getPaymentMethodListFail: 'GET_PAYMENT_METHOD_LIST_FAIL',
  getProductEatPackageListRequest: 'GET_PRODUCT_EAT_PACKAGE_LIST_REQUEST',
  getProductEatPackageListSuccess: 'GET_PRODUCT_EAT_PACKAGE_LIST_SUCCESS',
  getProductEatPackageListFail: 'GET_PRODUCT_EAT_PACKAGE_LIST_FAIL',
  getProductTypeListRequest: 'GET_PRODUCT_TYPE_LIST_REQUEST',
  getProductTypeListSuccess: 'GET_PRODUCT_TYPE_LIST_SUCCESS',
  getProductTypeListFail: 'GET_PRODUCT_TYPE_LIST_FAIL',
  getProductCategoryListRequest: 'GET_PRODUCT_CATEGORY_LIST_REQUEST',
  getProductCategoryListSuccess: 'GET_PRODUCT_CATEGORY_LIST_SUCCESS',
  getProductCategoryListFail: 'GET_PRODUCT_CATEGORY_LIST_FAIL',
  getProductPromoCodeMappingListRequest: 'GET_PRODUCT_PROMO_CODE_MAPPING_LIST_REQUEST',
  getProductPromoCodeMappingListSuccess: 'GET_PRODUCT_PROMO_CODE_MAPPING_LIST_SUCCESS',
  getProductPromoCodeMappingListFail: 'GET_PRODUCT_PROMO_CODE_MAPPING_LIST_FAIL',
  getProductSportClinicRequest: 'GET_PRODUCT_SPORT_CLINIC_REQUEST',
  getProductSportClinicSuccess: 'GET_PRODUCT_SPORT_CLINIC_SUCCESS',
  getProductSportClinicFail: 'GET_PRODUCT_SPORT_CLINIC_FAIL',
  getMedicConsultationDetailsRequest: 'GET_MEDIC_CONSULTATION_DETAILS_REQUEST',
  getMedicConsultationDetailsSuccess: 'GET_MEDIC_CONSULTATION_DETAILS_SUCCESS',
  getMedicConsultationDetailsFail: 'GET_MEDIC_CONSULTATION_DETAILS_FAIL',
  getWorkoutCategoryTabRequest: 'GET_WORKOUT_CATEGORY_TAB_REQUEST',
  getWorkoutCategoryTabSuccess: 'GET_WORKOUT_CATEGORY_TAB_SUCCESS',
  getWorkoutCategoryTabFail: 'GET_WORKOUT_CATEGORY_TAB_FAIL',
  getWorkoutCategoryProductRequest: 'GET_WORKOUT_CATEGORY_PRODUCT_REQUEST',
  getWorkoutCategoryProductSuccess: 'GET_WORKOUT_CATEGORY_PRODUCT_SUCCESS',
  getWorkoutCategoryProductFail: 'GET_WORKOUT_CATEGORY_PRODUCT_FAIL',
  getWorkoutCategoryBranchRequest: 'GET_WORKOUT_CATEGORY_BRANCH_REQUEST',
  getWorkoutCategoryBranchSuccess: 'GET_WORKOUT_CATEGORY_BRANCH_SUCCESS',
  getWorkoutCategoryBranchFail: 'GET_WORKOUT_CATEGORY_BRANCH_FAIL',
  getWorkoutCategoryStatusRequest: 'GET_WORKOUT_CATEGORY_STATUS_REQUEST',
  getWorkoutCategoryStatusSuccess: 'GET_WORKOUT_CATEGORY_STATUS_SUCCESS',
  getWorkoutCategoryStatusFail: 'GET_WORKOUT_CATEGORY_STATUS_FAIL',
  getCoachesByProductRequest: 'GET_COACHES_BY_PRODUCT_STATUS_REQUEST',
  getCoachesByProductSuccess: 'GET_COACHES_BY_PRODUCT_STATUS_SUCCESS',
  getCoachesByProductFail: 'GET_COACHES_BY_PRODUCT_STATUS_FAIL',
  getBranchesByProductRequest: 'GET_BRANCHES_BY_PRODUCT_STATUS_REQUEST',
  getBranchesByProductSuccess: 'GET_BRANCHES_BY_PRODUCT_STATUS_SUCCESS',
  getBranchesByProductFail: 'GET_BRANCHES_BY_PRODUCT_STATUS_FAIL',
  getWorkoutCategoryAllCoachRequest: 'GET_WORKOUT_CATEGORY_ALL_COACH_REQUEST',
  getWorkoutCategoryAllCoachSuccess: 'GET_WORKOUT_CATEGORY_ALL_COACH_SUCCESS',
  getWorkoutCategoryAllCoachFail: 'GET_WORKOUT_CATEGORY_ALL_COACH_FAIL',
  getWorkoutProductByCoachRequest: 'GET_WORKOUT_PRODUCT_BY_COACH_REQUEST',
  getWorkoutProductByCoachSuccess: 'GET_WORKOUT_PRODUCT_BY_COACH_SUCCESS',
  getWorkoutProductByCoachFail: 'GET_WORKOUT_PRODUCT_BY_COACH_FAIL',
  getConsultationBranchesRequest: 'GET_CONSULTATION_BRANCHES_REQUEST',
  getConsultationBranchesSuccess: 'GET_CONSULTATION_BRANCHES_SUCCESS',
  getConsultationBranchesFail: 'GET_CONSULTATION_BRANCHES_FAIL',
  getConsultationProductTypeRequest: 'GET_CONSULTATION_PRODUCT_TYPE_REQUEST',
  getConsultationProductTypeSuccess: 'GET_CONSULTATION_PRODUCT_TYPE_SUCCESS',
  getConsultationProductTypeFail: 'GET_CONSULTATION_PRODUCT_TYPE_FAIL',
  getProductByProductTypeRequest: 'GET_PRODUCT_BY_PRODUCT_TYPE_REQUEST',
  getProductByProductTypeSuccess: 'GET_PRODUCT_BY_PRODUCT_TYPE_SUCCESS',
  getProductByProductTypeFail: 'GET_PRODUCT_BY_PRODUCT_TYPE_FAIL',
  getConsultationProductDetailRequest: 'GET_CONSULTATION_PRODUCT_DETAIL_REQUEST',
  getConsultationProductDetailSuccess: 'GET_CONSULTATION_PRODUCT_DETAIL_SUCCESS',
  getConsultationProductDetailFail: 'GET_CONSULTATION_PRODUCT_DETAIL_FAIL',
  getCountryListRequest: 'GET_COUNTRY_LIST_REQUEST',
  getCountryListSuccess: 'GET_COUNTRY_LIST_SUCCESS',
  getCountryListFail: 'GET_COUNTRY_LIST_FAIL',
  getClinicBranchRequest: 'GET_CLINIC_BRANCH_REQUEST',
  getClinicBranchSuccess: 'GET_CLINIC_BRANCH_SUCCESS',
  getClinicBranchFail: 'GET_CLINIC_BRANCH_FAIL',
  getCustomerListRequest: 'GET_CUSTOMER_LIST_REQUEST',
  getCustomerListSuccess: 'GET_CUSTOMER_LIST_SUCCESS',
  getCustomerListFail: 'GET_CUSTOMER_LIST_FAIL',
  getCovidTestMethodRequest: 'GET_COVID_TEST_METHOD_REQUEST',
  getCovidTestMethodSuccess: 'GET_COVID_TEST_METHOD_SUCCESS',
  getCovidTestMethodFail: 'GET_COVID_TEST_METHOD_FAIL',
  getVoucherListRequest: 'GET_VOUCHER_LIST_REQUEST',
  getVoucherListSuccess: 'GET_VOUCHER_LIST_SUCCESS',
  getVoucherListFail: 'GET_VOUCHER_LIST_FAIL',
};

const initState = {
  fetching: false,
  merchantList: [],
  branchList: [],
  companyList: [],
  coachList: [],
  classList: [],
  roleList: [],
  permissionList: [],
  screenList: [],
  provinceList: [],
  cityList: [],
  districtList: [],
  villageList: [],
  bankDetails: {},
  segmentList: [],
  feedCategoryList: [],
  paymentMethodList: [],
  productEatPackageList: [],
  productTypeList: [],
  productCategoryList: [],
  productMappingList: [],
  productSportClinicList: [],
  medicConsultation: {},
  workoutCategoryTab: [],
  workoutCategoryProduct: [],
  workoutCategoryBranch: [],
  workoutCategoryStatus: [],
  coachesByProduct: [],
  branchesByProduct: [],
  workoutCategoryCoach: [],
  workoutProductByCoach: [],
  consultationBranches: [],
  consultationProductType: [],
  productByProductType: [],
  clinicBranch: [],
  countryList: [],
  errorFetch: false,
  errorMessage: null,
  customerList: [],
  covidTestMethod: [],
  voucherLists: [],
};

export default function masterReducer(state = initState, action) {
  const { type, payload } = action;
  switch (type) {
    case constants.getMerchantListRequest:
      return { ...state, fetching: true, merchantList: [] };
    case constants.getMerchantListSuccess:
      return {
        ...state,
        merchantList: payload.data,
        coachList: [],
        classList: [],
        fetching: false,
      };
    case constants.getMerchantListFail:
      return {
        ...state,
        fetching: false,
        errorFetch: true,
        errorMessage: payload,
      };
    case constants.getBranchListRequest:
      return { ...state, fetching: true };
    case constants.getBranchListSuccess:
      return {
        ...state,
        branchList: payload.data,
        coachList: [],
        classList: [],
        fetching: false,
      };
    case constants.getBranchListFail:
      return {
        ...state,
        fetching: false,
        errorFetch: true,
        errorMessage: payload,
      };
    case constants.getCompanyListRequest:
      return { ...state, fetching: true };
    case constants.getCompanyListSuccess:
      return {
        ...state,
        companyList: payload.data,
        fetching: false,
      };
    case constants.getCompanyListFail:
      return {
        ...state,
        fetching: false,
        errorFetch: true,
        errorMessage: payload,
      };
    case constants.getRoleListRequest:
      return { ...state, fetching: true };
    case constants.getRoleListSuccess:
      return {
        ...state,
        roleList: payload.data,
        fetching: false,
      };
    case constants.getRoleListFail:
      return {
        ...state,
        fetching: false,
        errorFetch: true,
        errorMessage: payload,
      };
    case constants.getCoachListRequest:
      return { ...state, fetching: true };
    case constants.getCoachListSuccess:
      return {
        ...state,
        coachList: payload.data,
        fetching: false,
      };
    case constants.getCoachListFail:
      return {
        ...state,
        fetching: false,
        errorFetch: true,
        errorMessage: payload,
      };
    case constants.getClassListRequest:
      return { ...state, fetching: true };
    case constants.getClassListSuccess:
      return {
        ...state,
        classList: payload.data,
        fetching: false,
      };
    case constants.getClassListFail:
      return {
        ...state,
        fetching: false,
        errorFetch: true,
        errorMessage: payload,
      };

    case constants.getPermissionListRequest:
      return { ...state, fetching: true };
    case constants.getPermissionListSuccess:
      return {
        ...state,
        permissionList: payload.data,
        fetching: false,
      };
    case constants.getPermissionListFail:
      return {
        ...state,
        fetching: false,
        errorFetch: true,
        errorMessage: payload,
      };

    case constants.getScreenDeeplinkListRequest:
      return { ...state, fetching: true };
    case constants.getScreenDeeplinkListSuccess:
      return {
        ...state,
        screenList: payload.data,
        fetching: false,
      };
    case constants.getScreenDeeplinkListFail:
      return {
        ...state,
        fetching: false,
        errorFetch: true,
        errorMessage: payload,
      };

    case constants.getProvinceListRequest:
      return { ...state, fetching: true };
    case constants.getProvinceListSuccess:
      return {
        ...state,
        provinceList: payload.data,
        fetching: false,
      };
    case constants.getProvinceListFail:
      return {
        ...state,
        fetching: false,
        errorFetch: true,
        errorMessage: payload,
      };

    case constants.getCityListRequest:
      return { ...state, fetching: true };
    case constants.getCityListSuccess:
      return {
        ...state,
        cityList: payload.data,
        fetching: false,
      };
    case constants.getCityListFail:
      return {
        ...state,
        fetching: false,
        errorFetch: true,
        errorMessage: payload,
      };

    case constants.getDistrictListRequest:
      return { ...state, fetching: true };
    case constants.getDistrictListSuccess:
      return {
        ...state,
        districtList: payload.data,
        fetching: false,
      };
    case constants.getDistrictListFail:
      return {
        ...state,
        fetching: false,
        errorFetch: true,
        errorMessage: payload,
      };

    case constants.getVillageListRequest:
      return { ...state, fetching: true };
    case constants.getVillageListSuccess:
      return {
        ...state,
        villageList: payload.data,
        fetching: false,
      };
    case constants.getVillageListFail:
      return {
        ...state,
        fetching: false,
        errorFetch: true,
        errorMessage: payload,
      };

    case constants.getBankDetailsRequest:
      return { ...state, fetching: true, responseMessage: null };
    case constants.getBankDetailsSuccess:
      return {
        ...state,
        bankDetails: payload.data,
        fetching: false,
      };
    case constants.getBankDetailsFail:
      return { ...state, fetching: false, errorMessage: payload };

    case constants.getSegmentListRequest:
      return { ...state, fetching: true };
    case constants.getSegmentListSuccess:
      return {
        ...state,
        segmentList: payload.data,
        fetching: false,
      };
    case constants.getSegmentListFail:
      return {
        ...state,
        fetching: false,
        errorFetch: true,
        errorMessage: payload,
      };

    case constants.getFeedCategoryListRequest:
      return { ...state, fetching: true };
    case constants.getFeedCategoryListSuccess:
      return {
        ...state,
        feedCategoryList: payload.data,
        fetching: false,
      };
    case constants.getFeedCategoryListFail:
      return {
        ...state,
        fetching: false,
        errorFetch: true,
        errorMessage: payload,
      };

    case constants.getPaymentMethodListRequest:
      return { ...state, fetching: true };
    case constants.getPaymentMethodListSuccess:
      return {
        ...state,
        paymentMethodList: payload.data,
        fetching: false,
      };
    case constants.getPaymentMethodListFail:
      return {
        ...state,
        fetching: false,
        errorFetch: true,
        errorMessage: payload,
      };

    case constants.getProductTypeListRequest:
      return { ...state, fetching: true };
    case constants.getProductTypeListSuccess:
      return {
        ...state,
        productTypeList: payload.data,
        fetching: false,
      };
    case constants.getProductTypeListFail:
      return {
        ...state,
        fetching: false,
        errorFetch: true,
        errorMessage: payload,
      };

    case constants.getProductEatPackageListRequest:
      return { ...state, fetching: true };
    case constants.getProductEatPackageListSuccess:
      return {
        ...state,
        productEatPackageList: payload.data,
        fetching: false,
      };
    case constants.getProductEatPackageListFail:
      return {
        ...state,
        fetching: false,
        errorFetch: true,
        errorMessage: payload,
      };

    case constants.getProductCategoryListRequest:
      return { ...state, fetching: true };
    case constants.getProductCategoryListSuccess:
      return {
        ...state,
        productCategoryList: payload.data,
        fetching: false,
      };
    case constants.getProductCategoryListFail:
      return {
        ...state,
        fetching: false,
        errorFetch: true,
        errorMessage: payload,
      };

    case constants.getProductPromoCodeMappingListRequest:
      return { ...state, fetching: true };
    case constants.getProductPromoCodeMappingListSuccess:
      return {
        ...state,
        productMappingList: payload.data,
        fetching: false,
      };
    case constants.getProductPromoCodeMappingListFail:
      return {
        ...state,
        fetching: false,
        errorFetch: true,
        errorMessage: payload,
      };

    case constants.getProductSportClinicRequest:
      return { ...state, fetching: true };
    case constants.getProductSportClinicSuccess:
      return {
        ...state,
        productSportClinicList: payload.data,
        fetching: false,
      };
    case constants.getProductSportClinicFail:
      return {
        ...state,
        fetching: false,
        errorFetch: true,
        errorMessage: payload,
      };

    case constants.getMedicConsultationDetailsRequest:
      return { ...state, fetching: true };
    case constants.getMedicConsultationDetailsSuccess:
      return {
        ...state,
        medicConsultation: payload.data,
        fetching: false,
      };
    case constants.getMedicConsultationDetailsFail:
      return {
        ...state,
        fetching: false,
        errorFetch: true,
        errorMessage: payload,
      };

    case constants.getWorkoutCategoryTabRequest:
      return { ...state, fetching: true };
    case constants.getWorkoutCategoryTabSuccess:
      return {
        ...state,
        workoutCategoryTab: payload.data,
        fetching: false,
      };
    case constants.getWorkoutCategoryTabFail:
      return {
        ...state,
        fetching: false,
        errorFetch: true,
        errorMessage: payload,
      };

    case constants.getWorkoutCategoryProductRequest:
      return { ...state, fetching: true };
    case constants.getWorkoutCategoryProductSuccess:
      return {
        ...state,
        workoutCategoryProduct: payload.data,
        fetching: false,
      };
    case constants.getWorkoutCategoryProductFail:
      return {
        ...state,
        fetching: false,
        errorFetch: true,
        errorMessage: payload,
      };

    case constants.getWorkoutCategoryBranchRequest:
      return { ...state, fetching: true };
    case constants.getWorkoutCategoryBranchSuccess:
      return {
        ...state,
        workoutCategoryBranch: payload.data,
        fetching: false,
      };
    case constants.getWorkoutCategoryBranchFail:
      return {
        ...state,
        fetching: false,
        errorFetch: true,
        errorMessage: payload,
      };

    case constants.getWorkoutCategoryStatusRequest:
      return { ...state, fetching: true };
    case constants.getWorkoutCategoryStatusSuccess:
      return {
        ...state,
        workoutCategoryStatus: payload.data,
        fetching: false,
      };
    case constants.getWorkoutCategoryStatusFail:
      return {
        ...state,
        fetching: false,
        errorFetch: true,
        errorMessage: payload,
      };

    case constants.getWorkoutCategoryAllCoachRequest:
      return { ...state, fetching: true };
    case constants.getWorkoutCategoryAllCoachSuccess:
      return {
        ...state,
        workoutCategoryCoach: payload.data,
        fetching: false,
      };
    case constants.getWorkoutCategoryAllCoachFail:
      return {
        ...state,
        fetching: false,
        errorFetch: true,
        errorMessage: payload,
      };

    case constants.getWorkoutProductByCoachRequest:
      return { ...state, fetching: true };
    case constants.getWorkoutProductByCoachSuccess:
      return {
        ...state,
        workoutProductByCoach: payload.data,
        fetching: false,
      };
    case constants.getWorkoutProductByCoachFail:
      return {
        ...state,
        fetching: false,
        errorFetch: true,
        errorMessage: payload,
      };

    case constants.getCoachesByProductRequest:
      return { ...state, fetching: true };
    case constants.getCoachesByProductSuccess:
      return {
        ...state,
        coachesByProduct: payload.data,
        fetching: false,
      };
    case constants.getCoachesByProductFail:
      return {
        ...state,
        fetching: false,
        errorFetch: true,
        errorMessage: payload,
      };

    case constants.getBranchesByProductRequest:
      return { ...state, fetching: true };
    case constants.getBranchesByProductSuccess:
      return {
        ...state,
        branchesByProduct: payload.data,
        fetching: false,
      };
    case constants.getBranchesByProductFail:
      return {
        ...state,
        fetching: false,
        errorFetch: true,
        errorMessage: payload,
      };

    case constants.getConsultationBranchesRequest:
      return { ...state, fetching: true };
    case constants.getConsultationBranchesSuccess:
      return {
        ...state,
        consultationBranches: payload.data,
        fetching: false,
      };
    case constants.getConsultationBranchesFail:
      return {
        ...state,
        fetching: false,
        errorFetch: true,
        errorMessage: payload,
      };

    case constants.getConsultationProductTypeRequest:
      return { ...state, fetching: true };
    case constants.getConsultationProductTypeSuccess:
      return {
        ...state,
        consultationProductType: payload.data,
        fetching: false,
      };
    case constants.getConsultationProductTypeFail:
      return {
        ...state,
        fetching: false,
        errorFetch: true,
        errorMessage: payload,
      };

    case constants.getProductByProductTypeRequest:
      return { ...state, fetching: true };
    case constants.getProductByProductTypeSuccess:
      return {
        ...state,
        productByProductType: payload.data,
        fetching: false,
      };
    case constants.getProductByProductTypeFail:
      return {
        ...state,
        fetching: false,
        errorFetch: true,
        errorMessage: payload,
      };

    case constants.getCountryListRequest:
      return { ...state, fetching: true };
    case constants.getCountryListSuccess:
      return {
        ...state,
        countryList: payload.data,
        fetching: false,
      };
    case constants.getCountryListFail:
      return {
        ...state,
        fetching: false,
        errorFetch: true,
        errorMessage: payload,
      };

    case constants.getClinicBranchRequest:
      return { ...state, fetching: true };
    case constants.getClinicBranchSuccess:
      return {
        ...state,
        clinicBranch: payload.data,
        fetching: false,
      };
    case constants.getClinicBranchFail:
      return {
        ...state,
        fetching: false,
        errorFetch: true,
        errorMessage: payload,
      };

    case constants.getCustomerListRequest:
      return { ...state, fetching: true };
    case constants.getCustomerListSuccess:
      return {
        ...state,
        customerList: payload.data,
        fetching: false,
      };
    case constants.getCustomerListFail:
      return {
        ...state,
        fetching: false,
        errorFetch: true,
        errorMessage: payload,
      };

    case constants.getCovidTestMethodRequest:
      return { ...state, fetching: true };
    case constants.getCovidTestMethodSuccess:
      return {
        ...state,
        covidTestMethod: payload.data,
        fetching: false,
      };
    case constants.getCovidTestMethodFail:
      return {
        ...state,
        fetching: false,
        errorFetch: true,
        errorMessage: payload,
      };

    case constants.getVoucherListRequest:
      return { ...state, fetching: true };
    case constants.getVoucherListSuccess:
      return {
        ...state,
        voucherLists: payload.data,
        fetching: false,
      };
    case constants.getVoucherListFail:
      return {
        ...state,
        fetching: false,
        errorFetch: true,
        errorMessage: payload,
      };

    default:
      return state;
  }
}
