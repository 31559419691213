const white = '#ffffff';
const black = '#000000';
const blueZodiac = '#0d1b34';
const matisse = '#1b4793';
const mystic = '#dfe3ec';
const mysticLight = '#ebeef4';
const rollingStone = '#7c858b';
const grayChateau = '#a2a8ac';
const mineShaft = '#202020';
const shuttleGray = '#57626A';
const apricot = '#ec7e72';
const orangeWhite = '#fefaea';
const seaBuckthorn = '#faa831';
const whiteLilac = '#f8f9fc';
const provincialPink = '#fce9e7';
const cornflowerBlue = '#4a85f7';
const downy = '#66d6b0';
const pictonBlue = '#59c5ec';
const malibu = '#72a0f9';
const cinnabar = '#e55646';
const seagull = '#86d3ef';
const heliotrope = '#c766ff';
const jade = '#00bb7b';
const lightGray = '#e7e7e7';

export default {
  black,
  white,
  blueZodiac,
  matisse,
  mystic,
  mysticLight,
  rollingStone,
  grayChateau,
  mineShaft,
  shuttleGray,
  apricot,
  orangeWhite,
  seaBuckthorn,
  whiteLilac,
  provincialPink,
  cornflowerBlue,
  downy,
  pictonBlue,
  malibu,
  cinnabar,
  seagull,
  heliotrope,
  jade,
  lightGray,
};
