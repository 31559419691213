import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, Grid } from '@material-ui/core';
// component
import { LabelStatusMain } from '../../../../../components/Index';
// Style
import './CardSessionConductStyle.scss';

const CardSessionConduct = props => {
  const { iconPrefix, labelText, bodyText, status, backgroundColor } = props;

  const renderStatus = () => {
    let renderElement = <div />;

    if (status) {
      renderElement = <LabelStatusMain value="Projected Value" type="projected" />;
    }

    return <div className="container-status">{renderElement}</div>;
  };

  return (
    <Card className="container-card-session-conduct general-shadow" elevation={0}>
      <CardContent>
        <Grid container justify="space-between" alignItems="center">
          <Grid item className={`container-icon ${backgroundColor}`}>
            <i className={`${iconPrefix} container-icon-prefix size-24`} />
          </Grid>
          <Grid item lg={6} md={5}>
            <span className="flex-column">
              <label className={`text-16 wrapping-container`}>{bodyText}</label>
              <label className={`text-12 wrapping-container`}>{labelText}</label>
            </span>
          </Grid>
          <Grid item lg={3} md={3}>
            <div>{renderStatus()}</div>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

CardSessionConduct.propTypes = {
  backgroundColor: PropTypes.string,
  bodyText: PropTypes.string,
  iconPrefix: PropTypes.string,
  labelText: PropTypes.string,
  status: PropTypes.bool,
};

export default CardSessionConduct;
