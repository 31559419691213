import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Skeleton } from '@material-ui/lab';
import { CircularProgress } from '@material-ui/core';
import _ from 'lodash';
// component
import { SelectInputSearchMain } from '../../Index';
// api
import { getPaymentMethodList } from '../../../services/api/MasterDataApi';
// helper
import { CommonHelper, paymentMethodOptionCode, promoTypeCode } from '../../../helpers/Index';
import SelectSearchMultiple from '../../presentational/select-search-multiple/SelectSearchMultiple';

const initialFilter = {
  search: '',
};

class SelectInputPaymentMethod extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: CommonHelper.objectCloning(initialFilter),
      listItem: [],
    };
    this.searchDebounce = _.debounce(this.searchDebounce, 600);
  }

  componentDidMount() {
    this.getMasterDataList();
  }

  componentDidUpdate(prevProps) {
    const { filter } = this.props;

    if (filter) {
      if (prevProps.filter.type !== filter.type) {
        this.setUpdateFilter();
      }
    }
  }

  setUpdateFilter() {
    const { filter } = this.props;
    const filterStatue = this.state.filter;

    const updateExistingFilter = { ...filterStatue, ...CommonHelper.objectCloning(filter) };

    this.setState(
      {
        filter: updateExistingFilter,
      },
      () => {
        this.getMasterDataList();
      },
    );
  }

  getMasterDataList() {
    const { apiDataList } = this.props;

    apiDataList().then(() => {
      const ListCustom = this.generateListDataDisplay();
      this.setState({ listItem: ListCustom });
    });
  }

  handleSelectItem = value => {
    const { onChange } = this.props;

    onChange(value);
  };

  handleItemSearch = value => {
    const { filter } = this.state;

    this.setState({ filter: { ...filter, search: value } }, () => {
      this.searchDebounce();
    });
  };

  searchDebounce() {
    this.getMasterDataList();
  }

  generateListDataDisplay() {
    const { masterData, includeAllData, isMultipleSelection, currentValue } = this.props;
    const { filter } = this.state;

    const converted = [];
    let param = [];
    const currentData = CommonHelper.renameKeyName(masterData.paymentMethodList, 'id', 'value');

    if (includeAllData && !isMultipleSelection) {
      param = { value: null, name: 'All Payment' };
      converted.push(param);
    }

    if (currentData.length > 0) {
      currentData.forEach(item => {
        let passCheck = true;

        if (
          filter.type === promoTypeCode.Cashback &&
          item.identifier === paymentMethodOptionCode.fit_point
        ) {
          passCheck = false;
        }

        if (passCheck) {
          const arrayParam = { value: item.value, name: item.name };
          converted.push(arrayParam);
        }
      });

      if (!currentValue || _.isEmpty(currentValue)) {
        const valueChange = isMultipleSelection ? [] : converted[0].value;
        if (!isMultipleSelection && !valueChange) {
          this.handleSelectItem(valueChange);
        }
      }
    }

    return converted;
  }

  renderSelector() {
    const {
      placeHolder,
      defaultValue,
      currentValue,
      errorMessage,
      validateStatus,
      size,
      isMultipleSelection,
      disabled,
      masterData: { fetching },
    } = this.props;

    const { listItem } = this.state;

    let renderElement = (
      <Skeleton
        variant="rect"
        width="100%"
        height={40}
        animation="wave"
        className="skeletonRounded"
      />
    );

    if (isMultipleSelection) {
      renderElement = (
        <SelectSearchMultiple
          options={listItem}
          onChange={item => {
            this.handleSelectItem(item);
          }}
          notFoundContent={fetching && <CircularProgress size={14} />}
          onSearch={this.handleItemSearch}
          currentValue={currentValue}
          defaultValue={defaultValue}
          size={size}
          disabled={!!_.isEmpty(listItem) || disabled}
          placeholder={placeHolder}
          errorMessage={errorMessage}
          validateStatus={validateStatus}
        />
      );
    } else {
      renderElement = (
        <SelectInputSearchMain
          options={listItem}
          onChange={item => {
            this.handleSelectItem(item);
          }}
          currentValue={currentValue}
          defaultValue={defaultValue}
          size={size}
          disabled={!!_.isEmpty(listItem) || disabled}
          placeholder={placeHolder}
          errorMessage={errorMessage}
          validateStatus={validateStatus}
        />
      );
    }

    return renderElement;
  }

  render() {
    return this.renderSelector();
  }
}

const mapDispatchToProps = dispatch => ({
  apiDataList: params => getPaymentMethodList(dispatch, params),
});

const mapStateToProps = ({ masterData }) => ({ masterData });

SelectInputPaymentMethod.propTypes = {
  apiDataList: PropTypes.func,
  currentValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
  disabled: PropTypes.bool,
  errorMessage: PropTypes.string,
  filter: PropTypes.object,
  includeAllData: PropTypes.bool,
  isMultipleSelection: PropTypes.bool,
  masterData: PropTypes.object,
  onChange: PropTypes.func,
  placeHolder: PropTypes.string,
  size: PropTypes.string,
  validateStatus: PropTypes.string,
};

SelectInputPaymentMethod.defaultProps = {
  placeHolder: '',
  isMultipleSelection: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectInputPaymentMethod);
