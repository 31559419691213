/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { Input, AutoComplete, Form, Select } from 'antd';
import _ from 'lodash';
// Style
import './SelectAutoCompleteStyle.scss';

const { Option } = Select;

const SelectAutoComplete = props => {
  const {
    onChange,
    onChangeSelectAddon,
    value,
    size,
    disabled,
    placeholder,
    validateStatus,
    errorMessage,
    suffix,
    prefix,
    defaultValueSelect,
    optionValueSelect,
    autoCompleteOption,
    isLoading,
  } = props;

  const handleChange = e => {
    onChange(e.target.value);
  };

  const handleChangeSelectAddon = e => {
    onChangeSelectAddon(e);
  };

  const renderDropDown = () => {
    return (
      <Select
        defaultValue={defaultValueSelect}
        onChange={handleChangeSelectAddon}
        style={{ width: '30%' }}
        size={size || 'large'}
      >
        {optionValueSelect.map((item, index) => (
          <Option key={index} value={item.value}>
            {item.name}
          </Option>
        ))}
      </Select>
    );
  };

  return (
    <Form.Item validateStatus={validateStatus} help={errorMessage}>
      <Input.Group compact>
        {renderDropDown()}
        <AutoComplete
          style={{ width: '70%' }}
          options={autoCompleteOption || []}
          size={size || 'large'}
        >
          <Input.Search
            placeholder={placeholder}
            size={size || 'large'}
            value={value}
            disabled={disabled}
            onChange={handleChange}
            loading={isLoading}
            onSelect={handleChange}
          />
        </AutoComplete>
      </Input.Group>
    </Form.Item>
  );
};

SelectAutoComplete.propTypes = {
  autoCompleteOption: PropTypes.array,
  defaultValueSelect: PropTypes.string,
  disabled: PropTypes.bool,
  errorMessage: PropTypes.string,
  isLoading: PropTypes.bool,
  onChange: PropTypes.func,
  onChangeSelectAddon: PropTypes.func,
  optionValueSelect: PropTypes.array,
  placeholder: PropTypes.string,
  prefix: PropTypes.string,
  size: PropTypes.string,
  suffix: PropTypes.object,
  validateStatus: PropTypes.string,
  value: PropTypes.string,
};

export default SelectAutoComplete;
