/* eslint-disable react/no-access-state-in-setstate */
import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { Table } from 'antd';
import { connect } from 'react-redux';
import _ from 'lodash';
// Style
import './CardMembershipListStyle.scss';
// component
import { ButtonMain, GeneratorExcel, NavigationButtonGroup } from '../../../../components/Index';
import { CommonHelper, currencyCode } from '../../../../helpers/Index';
// api
import { getMembershipStatusRecurringListPagination } from '../../../../services/api/DashboardApi';

const tabButtonData = [
  { value: 'recurring', name: 'Recurring' },
  { value: 'expiring', name: 'Expiring' },
];

const initialSort = {
  sortField: 'customer_name',
  sortOrder: 'asc',
};

const initialFilter = {
  membershipStatus: null,
};

class CardMembershipList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: CommonHelper.objectCloning(initialFilter),
      limit: 5,
      pagination: {
        hideOnSinglePage: true,
        showSizeChanger: false,
        pageSizeOptions: ['5'],
      },
      sort: CommonHelper.objectCloning(initialSort),
    };
  }

  componentDidUpdate(prevProps) {
    const { parentFilter } = this.props;

    const isEqual = _.isEqual(parentFilter, prevProps.parentFilter);

    if (parentFilter) {
      if (!isEqual) {
        this.setUpdateExistingFilter();
      }
    }
  }

  setUpdateExistingFilter() {
    const { parentFilter } = this.props;
    const { filter } = this.state;
    this.setState({ filter: { ...filter, ...parentFilter } }, () => {
      this.getMembershipStatusRecurringListPagination();
    });
  }

  getMembershipStatusRecurringListPagination() {
    const {
      filter: { membershipStatus, startDate, endDate },
      limit,
      sort,
    } = this.state;
    const {
      membershipStatusRecurringListPagination,
      dashboardData: { fetchingListRecurring },
    } = this.props;

    const params = {
      start_date: startDate,
      end_date: endDate,
      limit,
      sort: sort.sortField,
      sortOrder: sort.sortOrder,
    };

    if (!fetchingListRecurring && startDate && endDate) {
      membershipStatusRecurringListPagination(membershipStatus, params);
    }
  }

  handleOnGroupButtonClickStatusRecurring = value => {
    const { filter } = this.state;

    this.setState(
      {
        filter: { ...filter, membershipStatus: value },
      },
      () => {
        this.getMembershipStatusRecurringListPagination();
      },
    );
  };

  handleTableChange = (pagination, filters, sorter) => {
    const { sort } = this.state;

    this.setState(
      {
        pagination: {
          ...this.state.pagination,
          current: pagination.current,
          pageSize: pagination.pageSize,
        },
        sort: {
          ...sort,
          sortField: sorter.field || initialSort.sortField,
          sortOrder: sorter.order === 'ascend' || !sorter.order ? 'asc' : 'desc',
        },
      },
      () => {
        this.getMembershipStatusRecurringListPagination();
      },
    );
  };

  handleButtonClickViewAll = () => {
    const { onClickViewAll } = this.props;
    const { filter } = this.state;

    onClickViewAll(filter);
  };

  renderColumns = () => {
    const { filter } = this.state;
    const activeColumn = [
      {
        title: 'Customer',
        dataIndex: 'customer_name',
        sorter: true,
        render: (text, row) => {
          return (
            <div className="flex-column">
              <label className="text-12 text-semi-bold wrapping-container second line wrapping-container-break mb-8">
                {row.customer_name}
              </label>
              <label className="text-10 text-semi-bold text-cornflowerBlue">
                {row.product_name}
              </label>
            </div>
          );
        },
      },
      {
        title: 'Contact',
        dataIndex: 'customer_phone',
        width: '18%',
        align: 'center',
        sorter: true,
        render: (text, row) => {
          return (
            <div className="flex-column">
              <label className="text-12 text-semi-bold mb-8">{row.customer_phone}</label>
              <label className="text-10 text-semi-bold text-rolling-stone">
                {row.customer_email}
              </label>
            </div>
          );
        },
      },
      {
        title: 'Price',
        dataIndex: 'total',
        width: '18%',
        align: 'center',
        sorter: true,
        render: (text, row) => {
          const wordingPrice = `${currencyCode.idr} ${CommonHelper.formatCurrency(row.total)}`;

          return <label className="text-12 text-semi-bold">{wordingPrice}</label>;
        },
      },
    ];

    if (filter.membershipStatus === tabButtonData[0].value) {
      activeColumn.push({
        title: 'Recurring Date',
        dataIndex: 'recurring_date',
        width: '18%',
        align: 'center',
        sorter: true,
        render: (text, row) => {
          const completedDate =
            CommonHelper.dateTimeParseNewFormat(row.recurring_date, 'DD MMM YYYY') || '-';

          return <label className="text-12 text-semi-bold text-jade">{completedDate}</label>;
        },
      });
    } else {
      activeColumn.push({
        title: 'Expired Date',
        dataIndex: 'expired_at',
        width: '18%',
        align: 'center',
        sorter: true,
        render: (text, row) => {
          const completedDate =
            CommonHelper.dateTimeParseNewFormat(row.expired_at, 'DD MMM YYYY') || '-';

          return (
            <label className="text-12 text-semi-bold text-sea-buckthorn">{completedDate}</label>
          );
        },
      });
    }

    return activeColumn;
  };

  render() {
    const { filter, pagination } = this.state;
    const {
      dashboardData: { fetchingListRecurring, listRecurring },
    } = this.props;

    let dataPrint = [];

    if (!_.isEmpty(listRecurring)) {
      dataPrint = listRecurring;
    }

    return (
      <div className="container-card-membership-list container-main-card flex-column pv-24">
        <div className="ph-16">
          <Grid container className="flex-wrap-unset" justify="space-between" alignItems="center">
            <Grid item>
              <NavigationButtonGroup
                currentValue={filter.membershipStatus}
                onClick={this.handleOnGroupButtonClickStatusRecurring}
                data={tabButtonData}
              />
            </Grid>
            <Grid item>
              <GeneratorExcel
                buttonSize="xl"
                dataSetArray={dataPrint}
                fileName={`membership_list_${filter.startDate}_${filter.endDate}`}
                iconPrefix="ic-ffo-download"
                isButtonIcon
              />
            </Grid>
          </Grid>
        </div>
        <Table
          columns={this.renderColumns()}
          rowKey={record => record.sales_order_id || record.user_point_booster_id}
          dataSource={listRecurring}
          pagination={pagination}
          loading={fetchingListRecurring}
          onChange={this.handleTableChange}
        />
        <Grid container className="flex-wrap-unset mt-24" justify="center" alignItems="center">
          <Grid item lg={2} md={2}>
            <ButtonMain
              labelText="View All"
              onClick={this.handleButtonClickViewAll}
              type="secondary"
              size="xl"
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  membershipStatusRecurringListPagination: (urlKey, params) =>
    getMembershipStatusRecurringListPagination(dispatch, urlKey, params),
});

const mapStateToProps = ({ dashboardData }) => ({
  dashboardData,
});

CardMembershipList.propTypes = {
  dashboardData: PropTypes.object,
  membershipStatusRecurringListPagination: PropTypes.func,
  onClickViewAll: PropTypes.func,
  parentFilter: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(CardMembershipList);
