/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
// import SVG, { Circle, Defs, G, Rect, Use } from 'react-svg-draw';
import { CircularProgressbar } from 'react-circular-progressbar';
// Style
import './LoaderCircleStyle.scss';
import 'react-circular-progressbar/dist/styles.css';

const LoaderCircle = props => {
  const { size, color, strokeWidth, isInnerCircle, progress } = props;
  // const { size, progress, strokeWidth, circleOneStroke, circleTwoStroke } = props;
  // const center = size / 2;
  // const radius = size / 2 - strokeWidth / 2;
  // const circumference = 2 * Math.PI * radius;
  // const progressOffset = ((100 - progress) / 100) * circumference;

  return (
    // <SVG className="container-svg-circle" width={size} height={size}>
    //   <Circle
    //     className="svg-circle-bg"
    //     cx={center.toString()}
    //     cy={center.toString()}
    //     r={(radius / 3).toString()}
    //     style={{ stroke: '#fff', fill: '#fff' }}
    //     strokeWidth={strokeWidth / 3}
    //   />
    //   {isInnerCircle && (
    //     <Circle
    //       className="svg-circle"
    //       cx={center.toString()}
    //       cy={center.toString()}
    //       r={radius.toString()}
    //       style={{ stroke: color, fill: color }}
    //       strokeWidth={strokeWidth}
    //       strokeDasharray={circumference}
    //       strokeDashoffset={progressOffset}
    //     />
    //   )}
    // </SVG>
    <CircularProgressbar
      value={progress}
      strokeWidth={strokeWidth}
      styles={{
        path: {
          stroke: color,
        },
      }}
    />
  );
};

LoaderCircle.propTypes = {
  color: PropTypes.string,
  isInnerCircle: PropTypes.bool,
  progress: PropTypes.number,
  size: PropTypes.number,
  strokeWidth: PropTypes.number,
};

LoaderCircle.defaultProps = {
  // size: '30',
  // strokeWidth: 25,
  // isInnerCircle: true,
  // progress: 70,
};

export default LoaderCircle;
