import React from 'react';
import { connect } from 'react-redux';
import { Grid, FormControl } from '@material-ui/core';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { Table } from 'antd';
// component
import {
  PrevNextStepperCount,
  ButtonMain,
  LabelStatusMain,
  ButtonIconMain,
  ModalAlertAction,
  UploadImage,
  TextInput,
  LabelInput,
  SwitchInput,
  SelectInputMain,
  TextInputPrePost,
  TextAreaMain,
  TextInputNumber,
} from '../../../../../components/Index';
// helpers
import {
  CommonHelper,
  GlobalCodeStatus,
  MasterDataHelper,
  currencyCode,
} from '../../../../../helpers/Index';
// style
import '../../EventAddStyle.scss';

const statusOption = MasterDataHelper.statusEvent.filter(val => val.name !== 'All');

const modalDetailDataCreate = {
  title: 'Ticket Information',
};

const initialForm = {
  product_id: null,
  banner: null,
  name: '',
  quota: '',
  minAge: '',
  hasKit: true,
  hasPriceTiering: false,
  price: '',
  indonesianPrice: '',
  foreignerPrice: '',
  status: statusOption[0].value,
  description: '',
  descImage: null,
};

class StepCreateTicket extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpenModal: false,
      formModal: CommonHelper.objectCloning(initialForm),
      validation: {},
      editIndex: null,
    };
  }

  handleButtonCreate = () => {
    this.setState({ isOpenModal: true });
  };

  handleButtonEdit = (productId, index) => {
    const { form } = this.props;
    let findContent;
    let editIndex = null;

    if (productId) {
      findContent = form.tickets.find(val => val.product_id === productId);
      editIndex = form.tickets.findIndex(val => val.product_id === productId);
    } else {
      findContent = form.tickets[index];
      editIndex = index;
    }

    this.setState({
      formModal: { ...findContent },
      isOpenModal: true,
      editIndex,
    });
  };

  handleButtonDelete = (productId, index) => {
    const { form, onChange } = this.props;
    let updatedTickets;

    if (productId) {
      updatedTickets = form.tickets.filter(ticket => ticket.product_id !== productId);
    } else {
      updatedTickets = form.tickets.filter((_, i) => i !== index);
    }

    onChange('tickets', updatedTickets);
  };

  handleCloseModal = () => {
    this.setState({
      isOpenModal: false,
      formModal: CommonHelper.objectCloning(initialForm),
      validation: {},
    });
  };

  handleSubmit = () => {
    const { form, onChange } = this.props;
    const { formModal, editIndex } = this.state;

    if (this.validateCurrentStep()) {
      let updatedTickets = [...form.tickets];

      if (editIndex !== null) {
        updatedTickets[editIndex] = formModal;
      } else {
        updatedTickets = [...form.tickets, formModal];
      }

      onChange('tickets', updatedTickets);
      this.setState({
        isOpenModal: false,
        formModal: CommonHelper.objectCloning(initialForm),
        validation: {},
        editIndex: null,
      });
    }
  };

  handleFormChange = (key, value) => {
    const { formModal } = this.state;

    this.setState({
      formModal: { ...formModal, [key]: value },
    });
  };

  validateCurrentStep = () => {
    const { formModal } = this.state;
    const errors = {};
    let requiredFields = ['banner', 'name', 'quota', 'price', 'description', 'descImage'];

    if (formModal.hasPriceTiering) {
      requiredFields = [
        'banner',
        'name',
        'quota',
        'indonesianPrice',
        'foreignerPrice',
        'description',
        'descImage',
      ];
    }

    requiredFields.forEach(field => {
      const value = this.state.formModal[field];
      if (value === null || value === '') {
        errors[field] = `Field is required`;
      }
    });

    this.setState({ validation: errors });
    return Object.keys(errors).length === 0;
  };

  renderStatus = value => {
    let renderElement = <LabelStatusMain value="Active" type="complete" />;
    if (value === GlobalCodeStatus.Deleted) {
      renderElement = <LabelStatusMain value="Deleted" type="danger" />;
    } else if (value === GlobalCodeStatus.Inactive) {
      renderElement = <LabelStatusMain value="Inactive" type="default" />;
    }
    return <div className="container-status">{renderElement}</div>;
  };

  renderColumns = () => {
    return [
      {
        title: 'No',
        dataIndex: 'id',
        key: 'id',
        render: (text, row, index) => index + 1,
      },
      {
        title: 'Ticket Category',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Price (IDR)',
        dataIndex: 'price',
        key: 'price',
        render: (text, row) => {
          if (row.hasPriceTiering) {
            return (
              <div className="flex-column">
                <label className="text-14 text-medium text-black mb-8">
                  {`Rp ${CommonHelper.formatCurrency(row.indonesianPrice)} (Indonesian)`}
                </label>
                <label className="text-14 text-medium text-black">
                  {`Rp ${CommonHelper.formatCurrency(row.foreignerPrice)} (Foreigner)`}
                </label>
              </div>
            );
          }

          return (
            <label className="text-14 text-medium text-black">
              Rp {CommonHelper.formatCurrency(row.price)}
            </label>
          );
        },
      },
      {
        title: 'Quota',
        dataIndex: 'quota',
        key: 'quota',
      },
      {
        title: 'Status',
        dataIndex: 'status',

        render: (text, row) => {
          return this.renderStatus(row.status);
        },
      },
      {
        align: 'center',
        render: (text, row, index) => {
          return (
            <Grid container justify="center">
              <Grid item>
                <ButtonIconMain
                  icon="ic-ffo-edit"
                  type="secondary"
                  size="sm"
                  onClick={() => this.handleButtonEdit(row.product_id, index)}
                />
              </Grid>
              <Grid item>
                <ButtonIconMain
                  icon="ic-ffo-bin"
                  type="negative"
                  size="sm"
                  onClick={() => this.handleButtonDelete(row.product_id, index)}
                />
              </Grid>
            </Grid>
          );
        },
      },
    ];
  };

  renderModalElement = () => {
    const {
      formModal: {
        banner,
        name,
        quota,
        minAge,
        hasKit,
        hasPriceTiering,
        price,
        indonesianPrice,
        foreignerPrice,
        status,
        description,
        descImage,
      },
      validation,
    } = this.state;

    return (
      <Grid container spacing={2}>
        <Grid item lg={6} md={6}>
          <FormControl component="fieldset" fullWidth margin="normal">
            <LabelInput labelText="Ticket Name" isRequired />
            <TextInput
              placeHolderText="Enter Ticket Name"
              currentValue={name}
              size="md"
              isError={!!validation.name}
              errorMessage={validation.name}
              onChange={value => this.handleFormChange('name', value)}
            />
          </FormControl>
        </Grid>
        <Grid item lg={6} md={6}>
          <Grid container spacing={2}>
            <Grid item lg={6} md={6}>
              <Grid container spacing={2}>
                <Grid item lg={6} md={6}>
                  <FormControl component="fieldset" fullWidth margin="normal">
                    <LabelInput labelText="Quota" isRequired />
                    <TextInputNumber
                      placeHolderText="Enter Quota"
                      defaultValue={quota}
                      size="md"
                      isError={!!validation.quota}
                      errorMessage={validation.quota}
                      onChange={value => this.handleFormChange('quota', value)}
                    />
                  </FormControl>
                </Grid>
                <Grid item lg={6} md={6}>
                  <FormControl component="fieldset" fullWidth margin="normal">
                    <LabelInput labelText="Minimum Age" />
                    <TextInputNumber
                      placeHolderText="Enter Minimum Age"
                      defaultValue={minAge}
                      size="md"
                      onChange={value => this.handleFormChange('minAge', value)}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={6} md={6}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <LabelInput labelText="Status" isRequired />
                <SelectInputMain
                  options={statusOption}
                  currentValue={status}
                  onChange={value => this.handleFormChange('status', value)}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={6} md={6}>
          <Grid container spacing={2}>
            <Grid item lg={6} md={6}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <LabelInput labelText="Banner Image" isRequired />
                <div className="container-image-action">
                  <UploadImage
                    defaultValue={banner}
                    height={94}
                    maxSize={1}
                    validateStatus={!!validation.banner}
                    errorMessage={validation.banner}
                    onChange={item => this.handleFormChange('banner', item)}
                  />
                </div>
                <label className="text-12 mt-8" style={{ color: 'rgba(0, 0, 0, 0.45)' }}>
                  This image will appear as ticket banner on event landing page
                </label>
              </FormControl>
            </Grid>
            <Grid item lg={6} md={6}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <LabelInput labelText="Ticket Detail" isRequired />
                <div className="container-image-action">
                  <UploadImage
                    defaultValue={descImage}
                    height={94}
                    maxSize={1}
                    validateStatus={!!validation.descImage}
                    errorMessage={validation.descImage}
                    onChange={item => this.handleFormChange('descImage', item)}
                  />
                </div>
                <label className="text-12 mt-8" style={{ color: 'rgba(0, 0, 0, 0.45)' }}>
                  This image will appear as ticket detail such as race route
                </label>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={6} md={6}>
          <FormControl component="fieldset" fullWidth margin="normal">
            <LabelInput labelText="Description" isRequired />
            <TextAreaMain
              placeholder="Enter Description"
              currentValue={description}
              rows={5}
              validateStatus={!!validation.description}
              errorMessage={validation.description}
              onChange={value => this.handleFormChange('description', value)}
            />
          </FormControl>
        </Grid>
        <Grid item lg={12} md={12}>
          <FormControl component="fieldset" fullWidth margin={'normal'}>
            <div className="flex-row align-center" style={{ gap: '8px' }}>
              <label className="text-semi-bold text-12 text-rolling-stone">Has Kit</label>
              <SwitchInput
                currentValue={hasKit}
                onChange={value => this.handleFormChange('hasKit', value)}
              />
            </div>
          </FormControl>
        </Grid>
        <Grid item lg={12} md={12}>
          <FormControl component="fieldset" fullWidth margin={'normal'}>
            <div className="flex-row align-center" style={{ gap: '8px' }}>
              <label className="text-semi-bold text-12 text-rolling-stone">Has Price Tiering</label>
              <SwitchInput
                currentValue={hasPriceTiering}
                onChange={value => this.handleFormChange('hasPriceTiering', value)}
              />
            </div>
          </FormControl>
        </Grid>
        <Grid container spacing={2}>
          <Grid item lg={12} md={12}>
            {hasPriceTiering ? (
              <>
                <Grid container spacing={2}>
                  <Grid item lg={6} md={6}>
                    <FormControl component="fieldset" fullWidth margin="normal">
                      <LabelInput labelText="Indonesian Citizen" isRequired />
                      <TextInputPrePost
                        placeHolderText="Enter Price"
                        currentValue={indonesianPrice}
                        customElementsBefore={currencyCode.idr}
                        validateStatus={!!validation.indonesianPrice}
                        errorMessage={validation.indonesianPrice}
                        onChange={value => this.handleFormChange('indonesianPrice', value)}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item lg={6} md={6}>
                    <FormControl component="fieldset" fullWidth margin="normal">
                      <LabelInput labelText="Non Indonesian Citizen" isRequired />
                      <TextInputPrePost
                        placeHolderText="Enter Price"
                        currentValue={foreignerPrice}
                        customElementsBefore={currencyCode.idr}
                        validateStatus={!!validation.foreignerPrice}
                        errorMessage={validation.foreignerPrice}
                        onChange={value => this.handleFormChange('foreignerPrice', value)}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </>
            ) : (
              <FormControl component="fieldset" fullWidth margin="normal">
                <LabelInput labelText="Price" isRequired />
                <TextInputPrePost
                  placeHolderText="Enter Price"
                  currentValue={price}
                  customElementsBefore={currencyCode.idr}
                  validateStatus={!!validation.price}
                  errorMessage={validation.price}
                  onChange={value => this.handleFormChange('price', value)}
                />
              </FormControl>
            )}
          </Grid>
        </Grid>
      </Grid>
    );
  };

  render() {
    const {
      form: { tickets },
      validation,
    } = this.props;
    const { isOpenModal } = this.state;

    return (
      <>
        <div className="container-step-create-ticket flex-column mt-24" style={{ gap: '24px' }}>
          <Grid
            container
            justify="space-between"
            alignItems="center"
            className="container-filter flex-wrap-unset ph-32 pv-16 title-container"
          >
            <Grid item lg md>
              <label className="text-16 text-black text-semi-bold">Event Tickets</label>
            </Grid>
            <Grid item xl={2} lg={2} md={2} className="row-filter">
              <ButtonMain
                labelText="Add Ticket"
                onClick={this.handleButtonCreate}
                type="primary"
                size="md"
                startIcon="ic-ffo-add"
              />
            </Grid>
          </Grid>
          <div className="ph-32 pb-32">
            <Table
              columns={this.renderColumns()}
              rowKey={record => record.ticket_id}
              dataSource={tickets}
            />
          </div>

          {isOpenModal ? (
            <ModalAlertAction
              openModal={isOpenModal}
              modalDetail={modalDetailDataCreate}
              size={'large'}
              buttonSubmitText="Save"
              customElementProps={this.renderModalElement()}
              onCloseModal={this.handleCloseModal}
              onButtonSubmit={this.handleSubmit}
            />
          ) : null}
        </div>
        {validation.tickets ? (
          <label className="text-12 text-regular ml-16" style={{ color: '#f44336' }}>
            {validation.tickets}
          </label>
        ) : null}
      </>
    );
  }
}

StepCreateTicket.propTypes = {
  form: PropTypes.object,
  onChange: PropTypes.func,
  validation: PropTypes.object,
};

const shell = compose(connect());

export default shell(PrevNextStepperCount(StepCreateTicket));
