/* eslint-disable react/no-access-state-in-setstate */
import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Table, Tooltip } from 'antd';
import { Grid, Breadcrumbs, Link } from '@material-ui/core';
import _ from 'lodash';
// Components
import {
  SnackBarSimple,
  AuthenticationAccessPages,
  PrevStateValue,
  TextInput,
  SkeletonMain,
  LabelStatusMain,
  ButtonMain,
  ModalAlertAction,
} from '../../../components/Index';
import ModalParticipantDetails from './views/EventParticipantModal';
// Api
import {
  getEventParticipantDetails,
  getEventDetails,
  getEventStatistic,
  getEventParticipantData,
  updateParticipantData,
  resendEmail,
} from '../../../services/api/EventApi';
// Helpers
import { CommonHelper } from '../../../helpers/Index';
// Style
import './EventParticipantDetails.scss';

const optionToast = {
  vertical: 'top',
  horizontal: 'right',
};

const initialSort = {
  sortField: null,
  sortOrder: 'asc',
};

const initialFilter = {
  search: '',
};

const modalDetailDataCreate = {
  title: 'Participant Detail',
};

class EventParticipantDetailsPage extends React.Component {
  constructor(props) {
    super(props);
    const {
      usersReducer: { prevStateValue },
    } = props;

    const {
      match: { params },
    } = this.props;

    const updateExitingFilter = {
      ...CommonHelper.objectCloning(initialFilter),
      ...prevStateValue,
    };
    this.state = {
      eventId: CommonHelper.decryptObject(params.event_id).eventId,
      loading: true,
      isLoading: true,
      fetchParticipantData: true,
      filter: updateExitingFilter,
      page: 1,
      limit: 10,
      sort: CommonHelper.objectCloning(initialSort),
      isOpenModal: false,
      selectedRowId: null,
      participantDetail: {},
      updating: false,
      isEditing: false,
      resending: false,
      pagination: {
        showSizeChanger: true,
        showTotal: (total, range) => `Showing ${range[0]}-${range[1]} from ${total}`,
        pageSizeOptions: ['10', '20', '30', '40'],
      },
      toastInformation: {
        isOpen: false,
        message: '',
        snackbarType: 'warning',
      },
    };
    this.searchDebounce = _.debounce(this.searchDebounce, 400);
  }

  componentDidMount() {
    const {
      onResetPrevStateValue,
      history: { location },
    } = this.props;

    this.getDetails();
    this.getStats();
    this.getList();

    onResetPrevStateValue({ url: location.pathname });
  }

  componentDidUpdate(_prevProps, prevState) {
    if (prevState.selectedRowId !== this.state.selectedRowId && this.state.selectedRowId !== null) {
      this.fetchDataParticipant();
    }
  }

  getList = async () => {
    const { eventParticipantDetails } = this.props;
    const { eventId, page, limit, pagination, sort, filter } = this.state;

    this.setState({ loading: true });
    try {
      const param = {
        page,
        limit,
        sort: sort.sortField,
        sortOrder: sort.sortOrder,
        search: filter.search,
      };
      const { data } = await eventParticipantDetails(eventId, param);
      if (data) {
        this.setState({
          loading: false,
          isLoading: false,
          pagination: { ...pagination, pageSize: limit, total: data.total },
        });
      }
    } catch (error) {
      this.setState({
        loading: false,
        isLoading: false,
      });
    }
  };

  getDetails = async () => {
    const { eventDetails } = this.props;
    const { eventId } = this.state;

    try {
      const { data } = await eventDetails(eventId);
      if (data) {
        this.setState({
          loading: false,
          isLoading: false,
        });
      }
    } catch (error) {
      this.setState({
        loading: false,
        isLoading: false,
      });
    }
  };

  getStats = async () => {
    const { eventStatistic } = this.props;
    const { eventId } = this.state;

    try {
      const { data } = await eventStatistic(eventId);
      if (data) {
        this.setState({
          loading: false,
          isLoading: false,
        });
      }
    } catch (error) {
      this.setState({
        loading: false,
        isLoading: false,
      });
    }
  };

  fetchDataParticipant = async () => {
    const { eventParticipantData } = this.props;
    const { eventId, selectedRowId } = this.state;
    try {
      this.setState({ fetchParticipantData: true });
      const { data } = await eventParticipantData(eventId, selectedRowId);
      if (data) {
        this.setState({ fetchParticipantData: false });
      }
    } catch (error) {
      this.setState({ fetchParticipantData: false });
    }
  };

  handleChangeSearch = value => {
    const { filter } = this.state;

    this.setState(
      {
        filter: { ...filter, search: value },
        page: 1,
        pagination: { ...this.state.pagination, current: 1 },
      },
      () => {
        this.searchDebounce();
      },
    );
  };

  searchDebounce = () => {
    this.getList();
  };

  handleSubmit = async () => {
    const { updateData } = this.props;
    const { eventId, selectedRowId, participantDetail } = this.state;
    const simplifiedPayload = this.transformPayload(participantDetail);

    this.setState({ updating: true });
    try {
      const response = await updateData(eventId, selectedRowId, simplifiedPayload);
      this.processMessage(response.messages[0], response.status);
      this.getList();
    } catch (error) {
      this.processMessage(error.messages, 'error');
    } finally {
      this.setState({ updating: false, isOpenModal: false, selectedRowId: null });
      this.handleCloseModal();
    }
  };

  handleResendEmail = async () => {
    const { resendEmailData } = this.props;
    const { eventId, selectedRowId } = this.state;

    this.setState({ resending: true });
    try {
      const response = await resendEmailData(eventId, selectedRowId, {});
      this.processMessage(response.messages, response.status);
    } catch (error) {
      this.processMessage(error.messages, 'error');
    } finally {
      this.setState({ resending: false });
    }
  };

  transformPayload = payload => {
    const result = {};

    Object.keys(payload).forEach(key => {
      if (payload[key] && typeof payload[key] === 'object' && 'value' in payload[key]) {
        result[key] = payload[key].value;
      }
    });

    return result;
  };

  handleClick = (event, value) => {
    const { history } = this.props;
    event.preventDefault();
    history.push(value);
  };

  handleCloseToast = () => {
    const { toastInformation } = this.state;
    this.setState({ toastInformation: { ...toastInformation, isOpen: false } });
  };

  handleTableChange = (pagination, filters, sorter) => {
    const { sort } = this.state;

    this.setState(
      {
        limit: pagination.pageSize,
        pagination: {
          ...this.state.pagination,
          current: pagination.current,
          pageSize: pagination.pageSize,
        },
        sort: {
          ...sort,
          sortField: sorter.field || initialSort.sortField,
          sortOrder: sorter.order === 'ascend' || !sorter.order ? 'asc' : 'desc',
        },
        page: pagination.current,
      },
      () => {
        this.getList();
      },
    );
  };

  handleButtonClaim = () => {
    const { history } = this.props;
    const { eventId } = this.state;
    const param = CommonHelper.encryptObject({ eventId });
    history.push(`/event/participant/claim/${param}`);
  };

  handleCloseModal = () => {
    this.setState({
      isOpenModal: false,
      selectedRowId: null,
    });
  };

  handleEditForm = val => {
    this.setState({ isEditing: val });
  };

  handleShowParticipantDetail = record => {
    this.setState({ isOpenModal: true, selectedRowId: record.sales_invoice_item_id });
  };

  handleSaveParticipantDetails = updatedFields => {
    this.setState({ participantDetail: updatedFields });
  };

  processMessage(messages, type) {
    const convertedMessage = CommonHelper.generateMessage(messages);

    const paramInformation = {
      isOpen: true,
      message: convertedMessage,
      snackbarType: type,
    };

    this.setState({
      toastInformation: paramInformation,
    });
  }

  renderStatus = value => {
    let text;
    let type;

    if (value === 1) {
      text = 'Claimed';
      type = 'complete';
    } else if (value === 0) {
      text = 'Not Claimed';
      type = 'danger';
    }

    const renderElement = <LabelStatusMain value={text} type={type} />;
    return <div className="container-status">{renderElement}</div>;
  };

  renderFilter = () => {
    const { filter } = this.state;

    return (
      <div className="flex-column">
        <Grid
          container
          justify="space-between"
          alignItems="center"
          className="container-filter flex-wrap-unset"
        >
          <Grid item lg md>
            <Grid container justify="flex-start" alignItems="center">
              <Grid item xl={6} lg={6} md={6} className="row-filter">
                <TextInput
                  iconPrefix="ic-ffo-search"
                  placeHolderText="Search by participant’s name, phone, email and/or registration id"
                  onChange={this.handleChangeSearch}
                  currentValue={filter.search}
                  size="md"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xl={2} lg={2} md={2} className="row-filter">
            <ButtonMain
              labelText="Claim Kit"
              onClick={this.handleButtonClaim}
              type="primary"
              size="md"
              startIcon="ic-ffo-user-shared"
            />
          </Grid>
        </Grid>
      </div>
    );
  };

  renderColumns = () => {
    return [
      {
        title: 'Name & Registration ID',
        dataIndex: 'name',
        sorter: true,
        render: (text, row) => {
          return (
            <div className="flex-column">
              <label className="text-14 text-medium text-black wrapping-container second line wrapping-container-break mb-8">
                {row.name}
              </label>
              <label className="text-14 text-medium text-rolling-stone wrapping-container second line wrapping-container-break">
                {row.registration_id}
              </label>
            </div>
          );
        },
      },
      {
        title: 'Ticket',
        dataIndex: 'ticket_category',
        sorter: true,
        render: (text, row) => {
          return (
            <div className="flex-column">
              <label className="text-14 text-medium text-black wrapping-container second line wrapping-container-break mb-8">
                {row.ticket_category}
              </label>
              <label className="text-14 text-medium text-rolling-stone wrapping-container second line wrapping-container-break">
                Size: {row.kit_category}
              </label>
            </div>
          );
        },
      },
      {
        title: 'Contact Info',
        dataIndex: 'contact_email',
        render: (text, row) => {
          return (
            <div className="flex-column">
              <label className="text-14 text-medium text-black wrapping-container second line wrapping-container-break mb-8">
                {row.contact_email}
              </label>
              <label className="text-14 text-medium text-rolling-stone wrapping-container second line wrapping-container-break">
                {row.contact_phone}
              </label>
            </div>
          );
        },
      },
      {
        title: 'Claim Status',
        dataIndex: 'claimed',
        width: '180px',
        render: (text, row) => {
          return (
            <div className="flex-row align-center">
              {this.renderStatus(row.claimed)}
              {row.claimed ? (
                <Tooltip
                  color="white"
                  placement="leftTop"
                  getPopupContainer={() => document.body}
                  overlayClassName="participant-details-tooltip"
                  title={
                    <div>
                      <div
                        className="flex-row align-center pv-16 ph-24"
                        style={{
                          backgroundColor: '#F6FFED',
                          gap: '8px',
                          borderRadius: '8px 8px 0px 0px',
                        }}
                      >
                        <LabelStatusMain
                          value={row.delegate_information ? 'Delegated Claim' : 'Self Claim'}
                          type={'information'}
                        />
                        <label>
                          on{' '}
                          {CommonHelper.dateTimeParseNewFormat(
                            row.claimed_at,
                            'dddd, DD MMM YYYY, HH:mm A',
                          )}
                        </label>
                      </div>

                      <div className="p-24">
                        <Grid container spacing={2}>
                          <Grid
                            item
                            lg={6}
                            md={6}
                            style={{ display: 'flex', flexDirection: 'column' }}
                          >
                            <label
                              className="text-semi-bold text-12"
                              style={{ color: 'rgba(0, 0, 0, 0.50)', marginBottom: '3px' }}
                            >
                              {`Delegation's Name`}
                            </label>
                            <label
                              className="text-regular text-14"
                              style={{ color: 'rgba(0, 0, 0, 0.85)' }}
                            >
                              {row.delegate_information
                                ? row.delegate_information.name
                                : row.name || '-'}
                            </label>
                          </Grid>
                          <Grid
                            item
                            lg={6}
                            md={6}
                            style={{ display: 'flex', flexDirection: 'column' }}
                          >
                            <label
                              className="text-semi-bold text-12"
                              style={{ color: 'rgba(0, 0, 0, 0.50)', marginBottom: '3px' }}
                            >
                              {`Delegation's eKTP/SIM/Passport Number`}
                            </label>
                            <label
                              className="text-regular text-14"
                              style={{ color: 'rgba(0, 0, 0, 0.85)' }}
                            >
                              {row.delegate_information
                                ? row.delegate_information.identity_number
                                : row.identity_number || '-'}
                            </label>
                          </Grid>
                          <Grid
                            item
                            lg={6}
                            md={6}
                            style={{ display: 'flex', flexDirection: 'column' }}
                          >
                            <label
                              className="text-semi-bold text-12"
                              style={{ color: 'rgba(0, 0, 0, 0.50)', marginBottom: '3px' }}
                            >
                              Email
                            </label>
                            <label
                              className="text-regular text-14"
                              style={{ color: 'rgba(0, 0, 0, 0.85)' }}
                            >
                              {row.delegate_information
                                ? row.delegate_information.email
                                : row.contact_email || '-'}
                            </label>
                          </Grid>
                          <Grid
                            item
                            lg={6}
                            md={6}
                            style={{ display: 'flex', flexDirection: 'column' }}
                          >
                            <label
                              className="text-semi-bold text-12"
                              style={{ color: 'rgba(0, 0, 0, 0.50)', marginBottom: '3px' }}
                            >
                              Phone
                            </label>
                            <label
                              className="text-regular text-14"
                              style={{ color: 'rgba(0, 0, 0, 0.85)' }}
                            >
                              {row.delegate_information
                                ? row.delegate_information.phone
                                : row.contact_phone || '-'}
                            </label>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  }
                >
                  <i className="ic-ffo-alert container-icon-prefix size-20" />
                </Tooltip>
              ) : null}
            </div>
          );
        },
      },
      {
        title: 'Last Update',
        dataIndex: 'updated_at',
        align: 'right',
        sorter: true,
        render: (text, row) => {
          const claimDate =
            CommonHelper.dateTimeParseNewFormat(row.updated_at, 'ddd, DD MMM YYYY, HH:mm A') || '-';

          return (
            <div className="flex-column">
              <label className="text-14 text-medium">{claimDate}</label>
              <label className="text-14 text-medium text-rolling-stone wrapping-container second line wrapping-container-break">
                by {row.updated_by || '-'}
              </label>
            </div>
          );
        },
      },
    ];
  };

  render() {
    const {
      eventData: { participants, details, statistics, participantDetails },
    } = this.props;
    const {
      isLoading,
      loading,
      fetchParticipantData,
      pagination,
      isOpenModal,
      updating,
      isEditing,
      resending,
      toastInformation,
    } = this.state;
    const prevUrl = '/event/participant';

    let renderElement = <SkeletonMain />;

    if (!isLoading) {
      renderElement = (
        <div>
          <Helmet title={`FITCO | Event Participants Claim Lists`} />
          <div className="container-page-participant scroll-container">
            <div className="container-page-scrolling-area">
              <Grid container direction="column" className="flex-wrap-unset">
                <Grid item lg md sm className="section-page-header">
                  <div className="breadcrumbs-section">
                    <Breadcrumbs aria-label="breadcrumb">
                      <Link
                        className="text-12"
                        color="inherit"
                        href={prevUrl}
                        onClick={event => {
                          this.handleClick(event, prevUrl);
                        }}
                      >
                        <i className="ic-ffo-coupon container-icon-prefix size-16" />
                        Participant List
                      </Link>
                      <label className="text-12" color="inherit">
                        Event Detail
                      </label>
                    </Breadcrumbs>
                  </div>
                </Grid>
                <Grid item lg md className="section-page-body">
                  <div
                    style={{
                      borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
                      padding: '20px 32px',
                    }}
                  >
                    <label className="text-bold text-20 text-mine-shaft">{details.name}</label>
                  </div>
                  <Grid container spacing={2} style={{ padding: '20px 32px' }}>
                    <Grid item xl={3} lg={3} md={3}>
                      <div className="flex-column" style={{ gap: '10px' }}>
                        <label
                          className="text-medium text-14"
                          style={{ color: 'rgba(0, 0, 0, 0.45)' }}
                        >
                          Category
                        </label>
                        <label
                          className="text-semi-bold text-14"
                          style={{ color: 'rgba(0, 0, 0, 0.85)' }}
                        >
                          {details.event_category}
                        </label>
                      </div>
                    </Grid>
                    <Grid item xl={3} lg={3} md={3}>
                      <div className="flex-column" style={{ gap: '10px' }}>
                        <label
                          className="text-medium text-14"
                          style={{ color: 'rgba(0, 0, 0, 0.45)' }}
                        >
                          Venue
                        </label>
                        <label
                          className="text-semi-bold text-14"
                          style={{ color: 'rgba(0, 0, 0, 0.85)' }}
                        >
                          {details.address}
                        </label>
                      </div>
                    </Grid>
                    <Grid item xl={3} lg={3} md={3}>
                      <div className="flex-column" style={{ gap: '10px' }}>
                        <label
                          className="text-medium text-14"
                          style={{ color: 'rgba(0, 0, 0, 0.45)' }}
                        >
                          Date & Time
                        </label>
                        <label
                          className="text-semi-bold text-14"
                          style={{ color: 'rgba(0, 0, 0, 0.85)' }}
                        >{`${CommonHelper.dateTimeParseNewFormat(
                          details.start_date,
                          'DD MMMM YYYY',
                        )}, ${details.start_time}`}</label>
                      </div>
                    </Grid>
                    <Grid item xl={3} lg={3} md={3}>
                      <div className="flex-column" style={{ gap: '10px' }}>
                        <label
                          className="text-medium text-14"
                          style={{ color: 'rgba(0, 0, 0, 0.45)' }}
                        >
                          Event Page
                        </label>
                        <a
                          href={details.web_url}
                          target="blank"
                          className="text-semi-bold text-14"
                          style={{ color: '#1890FF' }}
                        >
                          Visit Website
                        </a>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} style={{ marginBottom: '32px' }}>
                    <Grid item xl={4} lg={4} md={4}>
                      <div className="total-participant-card-container">
                        <div className="flex-column">
                          <label
                            className="text-medium text-14 text-rolling-stone"
                            style={{ marginBottom: '5px' }}
                          >
                            Total Participant vs Quota
                          </label>
                          <label
                            className="text-regular text-16"
                            style={{ color: 'rgba(0, 0, 0, 0.45)' }}
                          >
                            <label
                              className="text-medium text-28"
                              style={{ color: 'rgba(0, 0, 0, 0.85)' }}
                            >
                              {CommonHelper.formatCurrency(statistics.total_participant)}
                            </label>
                            /{CommonHelper.formatCurrency(statistics.total_ticket)}
                          </label>
                        </div>
                        <div className="flex-row align-center" style={{ gap: '10px' }}>
                          <div className="percentage-container">
                            <div
                              style={{
                                height: 6,
                                width: `${(statistics.total_participant / statistics.total_ticket) *
                                  100}%`,
                                backgroundColor: '#1890FF',
                                borderRadius: '100px',
                              }}
                            />
                          </div>
                          <label
                            className="text-medium text-14"
                            style={{ color: 'rgba(0, 0, 0, 0.85)' }}
                          >
                            {(
                              (statistics.total_participant / statistics.total_ticket) *
                              100
                            ).toFixed()}
                            %
                          </label>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xl={4} lg={4} md={4}>
                      <div className="total-claim-kit-card-container">
                        <div className="flex-column">
                          <label
                            className="text-medium text-14 text-rolling-stone"
                            style={{ marginBottom: '5px' }}
                          >
                            Claimed Kit vs Total Participant
                          </label>
                          <label
                            className="text-regular text-16"
                            style={{ color: 'rgba(0, 0, 0, 0.45)' }}
                          >
                            <label
                              className="text-medium text-28"
                              style={{ color: 'rgba(0, 0, 0, 0.85)' }}
                            >
                              {CommonHelper.formatCurrency(statistics.total_claimed)}
                            </label>
                            /{CommonHelper.formatCurrency(statistics.total_participant)}
                          </label>
                        </div>
                        <div className="flex-row align-center" style={{ gap: '10px' }}>
                          <div className="percentage-container">
                            <div
                              style={{
                                height: 6,
                                width: `${(statistics.total_claimed /
                                  statistics.total_participant) *
                                  100}%`,
                                backgroundColor: '#52C41A',
                                borderRadius: '100px',
                              }}
                            />
                          </div>
                          <label
                            className="text-medium text-14"
                            style={{ color: 'rgba(0, 0, 0, 0.85)' }}
                          >
                            {(
                              (statistics.total_claimed / statistics.total_participant) *
                              100
                            ).toFixed()}
                            %
                          </label>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xl={4} lg={4} md={4}>
                      <div className="total-sales-card-container">
                        <div className="flex-column">
                          <label
                            className="text-medium text-14 text-rolling-stone"
                            style={{ marginBottom: '5px' }}
                          >
                            Total Sales (IDR)
                          </label>
                          <label
                            className="text-medium text-28"
                            style={{ color: 'rgba(0, 0, 0, 0.85)' }}
                          >
                            {CommonHelper.formatCurrency(statistics.total_sales)}
                          </label>
                        </div>
                        <div className="lines" />
                      </div>
                    </Grid>
                  </Grid>
                  <div style={{ marginBottom: '32px' }}>{this.renderFilter()}</div>
                  <Table
                    columns={this.renderColumns()}
                    rowKey={record => record.sales_invoice_item_id}
                    dataSource={participants}
                    pagination={pagination}
                    loading={loading}
                    onChange={this.handleTableChange}
                    onRow={record => ({
                      onClick: () => this.handleShowParticipantDetail(record),
                    })}
                  />
                </Grid>
              </Grid>
            </div>
          </div>
          {isOpenModal ? (
            <ModalAlertAction
              openModal={isOpenModal}
              modalDetail={modalDetailDataCreate}
              size={'large'}
              isLoading={updating || resending}
              disableApplyBtn={isEditing}
              additionalButton
              buttonAdditionalText="Resend Email"
              buttonSubmitText="Save"
              customElementProps={
                <ModalParticipantDetails
                  loading={fetchParticipantData}
                  data={participantDetails}
                  onEditing={this.handleEditForm}
                  onSave={updatedFields => {
                    this.handleSaveParticipantDetails(updatedFields);
                  }}
                />
              }
              onCloseModal={this.handleCloseModal}
              onButtonAdditionalSubmit={this.handleResendEmail}
              onButtonSubmit={this.handleSubmit}
            />
          ) : null}
          <SnackBarSimple
            open={toastInformation.isOpen}
            durationHide={2000}
            message={toastInformation.message}
            onClickClose={this.handleCloseToast}
            snackbarType={toastInformation.snackbarType}
            anchor={optionToast}
          />
        </div>
      );
    }

    return renderElement;
  }
}

const mapDispatchToProps = dispatch => ({
  eventParticipantDetails: (eventId, params) =>
    getEventParticipantDetails(dispatch, eventId, params),
  eventDetails: eventId => getEventDetails(dispatch, eventId),
  eventStatistic: eventId => getEventStatistic(dispatch, eventId),
  eventParticipantData: (eventId, invoiceItemId) =>
    getEventParticipantData(dispatch, eventId, invoiceItemId),
  updateData: (eventId, invoiceItemId, params) =>
    updateParticipantData(dispatch, eventId, invoiceItemId, params),
  resendEmailData: (eventId, invoiceItemId, params) =>
    resendEmail(dispatch, eventId, invoiceItemId, params),
});

const mapStateToProps = ({ usersReducer, eventData }) => ({
  usersReducer,
  eventData,
});

EventParticipantDetailsPage.propTypes = {
  eventData: PropTypes.object,
  eventDetails: PropTypes.func,
  eventParticipantData: PropTypes.func,
  eventParticipantDetails: PropTypes.func,
  eventStatistic: PropTypes.func,
  history: PropTypes.object,
  match: PropTypes.object,
  onResetPrevStateValue: PropTypes.func,
  resendEmailData: PropTypes.func,
  updateData: PropTypes.func,
  usersReducer: PropTypes.object,
};

const shell = compose(connect(mapStateToProps, mapDispatchToProps));
const core = compose(AuthenticationAccessPages, PrevStateValue);

export default shell(core(EventParticipantDetailsPage));
