import { makeStyles } from '@material-ui/core/styles';

const StylesJs = makeStyles(theme => ({
  drawer: {
    width: theme.drawerWidth,
    zIndex: theme.zIndex.drawer + 1,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  paper: {
    background: theme.palette.blueZodiac,
  },
  drawerOpen: {
    width: theme.drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) - 2,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) - 2,
    },
  },
  drawerActive: {
    background: theme.palette.matisse,
    fontFamily: theme.fonts.fontBold,
  },
  expandMore: {
    transition: '0.2s linear',
    transform: 'rotate(-90deg)',
  },
  expandLess: {
    transition: '0.2s linear',
    transform: 'rotate(0deg)',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    minHeight: '62px !important',
  },
  listItemIcon: {
    fontSize: 16,
    // alignItems: 'center',
  },
}));

export default StylesJs;
