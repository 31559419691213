import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Grid, Breadcrumbs, Link } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import _ from 'lodash';
import { compose } from 'redux';
// Style
import './HandoverDetailsStyle.scss';
// Component
import { ButtonMain, GeneratorPdf, LabelStatusMain, AuthenticationAccessPages, PrevStateValue } from '../../../../components/Index';
// Action
import { getHandoverDetails, getDataPrintHandover } from '../../../../services/api/OrdersApi';
// Helpers
import { CommonHelper, PermissionModule, PermissionPage, PermissionAccess  } from '../../../../helpers/Index';

class HandoverDetails extends React.Component {
  constructor(props) {
    super(props);

    props.checkUserAccessPermission(
      PermissionModule.Orders,
      PermissionPage.Handover,
      PermissionAccess.View,
    );

    this.state = {
      dataPrintListHandover: [],
    };
  }

  async componentDidMount() {
    const { getDetails } = this;
    await getDetails();
  }

  componentWillUnmount() {}

  getDetails = () => {
    const {
      getHandover,
      match: { params },
    } = this.props;

    const orderHandoverId = params.order_handover_id;
    getHandover(orderHandoverId);
  };

  handleClick = (event, value) => {
    const { history } = this.props;
    event.preventDefault();
    history.push(value);
  };

  handleButtonCancel = () => {
    const { history } = this.props;
    history.push('/order/handover');
  };

  handleButtonPrint = async dataDetails => {
    const { getPrintHandover } = this.props;
    const orderHandoverId = dataDetails.order_handover_id;

    await getPrintHandover(orderHandoverId)
      .then(response => {
        const data = response.data;
        this.setState({
          dataPrintListHandover: data,
        });
      })
      .catch(() => {
        // console.log('error', e);
      });
  };

  renderStatus = value => {
    let renderElement = <LabelStatusMain value="Not Yet" type="default" />;
    if (value) {
      renderElement = <LabelStatusMain value="Printed" type="complete" />;
    }
    return <div className="container-status">{renderElement}</div>;
  };

  renderOrderCard = () => {
    const {
      orderData: { handoverDetails },
    } = this.props;

    return (
      <Grid container className="handover-card-details" direction="row" wrap={'nowrap'}>
        <Grid item lg={10} md={10}>
          <Grid container direction="column">
            <Grid item className="pt-16">
              <Grid container spacing={2} justify="space-between">
                <Grid item lg={2} md={3} className="flex-column">
                  <label className="text-12 text-opacity-50">Handover ID</label>
                  <label className="text-12 pt-4 text-bold">
                    {handoverDetails.order_handover_id}
                  </label>
                </Grid>
                <Grid item lg={2} md={3} className="flex-column">
                  <label className="text-12 text-opacity-50">Handover Date</label>
                  <label className="text-12 pt-4 text-bold">
                    {`${CommonHelper.dateTimeParseNewFormat(
                      handoverDetails.handover_date,
                      'DD MMM YYYY',
                    )}`}
                  </label>
                </Grid>
                <Grid item lg={2} md={3} className="flex-column">
                  <label className="text-12 text-opacity-50">Delivery Services</label>
                  <label className="text-12 pt-4 text-bold">
                    {handoverDetails.delivery_services}
                  </label>
                </Grid>
                <Grid item lg={3} md={3} className="flex-column">
                  <label className="text-12 text-opacity-50">Created By</label>
                  <label className="text-12 pt-4 text-bold">{handoverDetails.created_by}</label>
                </Grid>
                <Grid item lg={2} md={3} className="flex-column">
                  <label className="text-12 text-opacity-50 mb-4">Print Status</label>
                  {this.renderStatus(handoverDetails.is_printed)}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={2} md={2} className="flex-column flex-center">
          <label className="text-16 text-bold">{handoverDetails.total_order}</label>
          <label className="text-14 pt-4 text-opacity-50">
            {handoverDetails.total_order > 1
              ? `${handoverDetails.total_order} Order List`
              : `${handoverDetails.total_order} Order`}
          </label>
        </Grid>
      </Grid>
    );
  };

  renderColumns = () => {
    return [
      {
        title: 'Customer Name',
        dataIndex: 'name',
        sorter: true,
        width: '68%',
        render: (text, row) => {
          return (
            <Grid container key={row.id}>
              <Grid item lg={1} md={1} className="section-icon">
                <div>
                  <i
                    className={`ic-ffo-lg-shop container-icon-prefix size-40 orders-icon-purchase`}
                  />
                </div>
              </Grid>
              <Grid item lg={11} md={11}>
                <Grid
                  container
                  direction="column"
                  className="container-row-content flex-wrap-unset"
                >
                  <Grid item className="pb-24">
                    <Grid container direction="row" className="container-text-header">
                      <Grid item lg={12} md={12} className="left">
                        <Grid container direction="column">
                          <Grid item>
                            <label className="text-14 text-product-name">{row.products}</label>
                          </Grid>
                          <Grid item>
                            <label className="text-12 text-opacity-50">
                              ID {row.sales_order_id}
                            </label>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container direction="row" spacing={2}>
                      <Grid item xl={3} lg={4} md={4} className="flex-column">
                        <label className="text-12 text-opacity-50">Customer</label>
                        <label className="text-12 pt-4">{row.customer_name}</label>
                      </Grid>
                      <Grid item xl={3} lg={4} md={4} className="flex-column">
                        <label className="text-12 text-opacity-50">W-Order ID</label>
                        <label className="text-12 pt-4">{row.external_order_id}</label>
                      </Grid>
                      <Grid item xl={3} lg={4} md={4} className="flex-column">
                        <label className="text-12 text-opacity-50">Order ID</label>
                        <label className="text-12 pt-4">{row.sales_order_id}</label>
                      </Grid>
                      <Grid item xl={3} lg={4} md={4} className="flex-column">
                        <label className="text-12 text-opacity-50">Airwaybill Number</label>
                        <label className="text-12 pt-4">{row.airway_bill_number || '-'}</label>
                      </Grid>
                      <Grid item xl={3} lg={4} md={4} className="flex-column">
                        <label className="text-12 text-opacity-50">Order Created</label>
                        <label className="text-12 pt-4">{row.order_date}</label>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          );
        },
      },
      {
        title: 'Qty Box',
        width: '14%',
        sorter: false,
        align: 'center',
        render: (text, row) => {
          return <label className="text-16 text-bold">{row.qty_box}</label>;
        },
      },
      {
        title: 'Qty Order',
        dataIndex: 'total_products',
        width: '20%',
        sorter: false,
        align: 'center',
        render: (text, row) => {
          const processG = row.total_weight * 1000;
          const totalKg = (processG * row.total_products) / 1000;
          return (
            <Grid container direction={'column'} justify={'center'}>
              <Grid item>
                <label className="text-16 text-bold">
                  {row.total_products > 1
                    ? `${row.total_products} Orders`
                    : `${row.total_products} Order`}
                </label>
              </Grid>
              <Grid item>{`(${row.total_products}x ${processG.toFixed(2)}g ) ${totalKg.toFixed(
                2,
              )}KG`}</Grid>
              <Grid item className="pt-16">
                <div className="label-items">
                  <label className="text-12">{row.destination_code}</label>
                </div>
              </Grid>
            </Grid>
          );
        },
      },
    ];
  };

  renderOrderList = () => {
    const {
      orderData: { handoverDetails },
    } = this.props;

    const orderHandoverListItem = !_.isEmpty(handoverDetails) ? handoverDetails.order_list : [];

    return (
      <Grid container direction="row">
        <Grid item lg={12} md={12}>
          <Table
            columns={this.renderColumns()}
            rowKey={(record, index) => index}
            dataSource={orderHandoverListItem}
            onChange={this.handleTableChange}
          />
        </Grid>
      </Grid>
    );
  };

  render() {
    const {
      orderData: { handoverDetails },
    } = this.props;

    return (
      <div>
        <Helmet title="FITCO | Orders - Handover Details" />
        <div className="container-page-handover-details scroll-container-invisible">
          <div className="container-page-scrolling-area">
            <Grid container direction="column">
              <Grid item lg md className="section-page-header">
                <Grid container>
                  <Grid item>
                    <div className="breadcrumbs-section">
                      <Breadcrumbs aria-label="breadcrumb">
                        <Link
                          className="text-12"
                          color="inherit"
                          href="/orders"
                          onClick={event => {
                            this.handleClick(event, '/orders');
                          }}
                        >
                          <i className="icon-slot ic-ffo-reports" /> Order
                        </Link>
                        <Link
                          className="text-12"
                          color="inherit"
                          href="/orders"
                          onClick={event => {
                            this.handleClick(event, '/order/handover');
                          }}
                        >
                          Handover
                        </Link>
                        <label className="text-12" color="inherit">
                          Handover Details
                        </label>
                      </Breadcrumbs>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item lg md className="section-page-body">
                <div className="handover-details-card">
                  <Grid className="pb-24" container justify="space-between" spacing={3}>
                    <Grid item lg md>
                      <label className="text-16 text-opacity-50">
                        Handover {handoverDetails.name}
                      </label>
                    </Grid>
                    <Grid item lg={4} md={4}>
                      <Grid container justify="flex-end" spacing={3}>
                        <Grid item lg={6} md={6}>
                          <ButtonMain
                            type="ghost"
                            size="xl"
                            labelText="Back"
                            onClick={this.handleButtonCancel}
                          />
                        </Grid>
                        <Grid item lg={6} md={6}>
                          <GeneratorPdf
                            data={this.state.dataPrintListHandover}
                            buttonType="primary"
                            buttonLabel="Print"
                            buttonSize="xl"
                            customLabel="canvas-handover-list"
                            onClick={() => this.handleButtonPrint(handoverDetails)}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container>{this.renderOrderCard()}</Grid>
                </div>
                <div className="handover-details-card mt-24">{this.renderOrderList()}</div>
              </Grid>
              <Grid item lg md className="section-page-footer" />
            </Grid>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getHandover: orderHandoverId => getHandoverDetails(dispatch, orderHandoverId),
  getPrintHandover: params => getDataPrintHandover(dispatch, params),
});

const mapStateToProps = ({ orderData }) => ({
  orderData,
});

HandoverDetails.propTypes = {
  checkUserAccessPermission: PropTypes.func,
  getHandover: PropTypes.func,
  getPrintHandover: PropTypes.func,
  history: PropTypes.object,
  match: PropTypes.object,
  orderData: PropTypes.object,
};

const shell = compose(connect(mapStateToProps, mapDispatchToProps));
const core = compose(AuthenticationAccessPages, PrevStateValue);

export default shell(core(HandoverDetails));
