import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Grid, CardContent, ExpansionPanel, ExpansionPanelSummary, Fade } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// Style
import './CardPermissionDetailStyle.scss';
// Component
import { LabelIcon, ModalAlertConfirmation, CheckInput } from '../../../../../../components/Index';
import { CardSubPermissionDetail, CardPermissionAdd } from '../Index';
import { PermissionAccess, PermissionModule, PermissionPage } from '../../../../../../helpers/PermissionHelper';

const CardPermissionDetail = props => {
  const {
    onDeletePermissionParent,
    onDeletePermissionChildren,
    dataArray,
    isEdit,
    onCheckedPermissionAccessParent,
    onCheckedPermissionAccessChildren,
    isExpand,
    onExpanded,
    onButtonClickAdd,
  } = props;

  const listChildren = dataArray.children;
  const listAccess = dataArray.access;

  const modalAlertOption = {
    title: 'Confirmation',
    text: `Are Your Sure want to DELETE ${dataArray.name} Permission `,
    icon: 'warning',
    successMessage: 'Deleted Permission Success',
  };

  const timeInterval = 300;

  const handleItemChange = itemValue => value => {
    const result = { value: itemValue, checked: value };
    onCheckedPermissionAccessParent(result);
  };

  const handleItemButtonDelete = value => {
    const result = value;
    onDeletePermissionChildren(result);
  };

  const handleExpandChange = panel => (event, isExpanded) => {
    onExpanded(isExpanded ? panel : '');
  };

  const renderSubChildren = () => {
    let renderElement = null;

    if (listChildren.length > 0) {
      renderElement = (
        <Grid container>
          {listChildren.map((item, index) => (
            <Grid key={index} item lg={3} md={3} className="container-sub-card-permission">
              <CardSubPermissionDetail
                dataArray={item}
                onDeletePermission={() => handleItemButtonDelete(item)}
                isEdit={isEdit}
                onCheckedPermissionAccess={onCheckedPermissionAccessChildren}
              />
            </Grid>
          ))}
          {!isEdit ? (
            <Grid item lg={3} md={3} className="container-sub-card-permission">
              <CardPermissionAdd valueName={dataArray.value} onButtonClickAdd={onButtonClickAdd} />
            </Grid>
          ) : null}
        </Grid>
      );
    } else {
      renderElement = (
        <Grid container>
          <Grid item lg={3} md={3}>
            <Grid container direction="column" className="flex-wrap-unset">
              {listAccess.map((item, index) =>
                !isEdit ? (
                  <Grid
                    item
                    lg
                    md
                    key={index}
                    className={`container-sub-row ${clsx({ 'is-in-active': !item.checked })}`}
                  >
                    <LabelIcon iconPrefix="ic-ffo-check-mark" labelText={item.name} size="md" />
                  </Grid>
                ) : (
                  <Grid item lg={3} md={3} key={index}>
                    <CheckInput
                      currentValue={item.checked}
                      colorCheck="primary"
                      labelText={item.name}
                      onCheck={handleItemChange(item.value)}
                    />
                  </Grid>
                ),
              )}
            </Grid>
          </Grid>
          <Fade in={isEdit} timeout={timeInterval}>
            <Grid item lg={3} md={3}>
              <CardPermissionAdd valueName={dataArray.value} onButtonClickAdd={onButtonClickAdd} />
            </Grid>
          </Fade>
        </Grid>
      );
    }
    return renderElement;
  };

  return (
    <ExpansionPanel
      className={clsx({ 'container-last-chile-active': isEdit })}
      expanded={isExpand === dataArray.name}
      onChange={handleExpandChange(dataArray.name)}
    >
      <Grid container direction="column">
        <Grid item>
          <div className="expansion-header-text">
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <label className="text-16">{dataArray.name}</label>
            </ExpansionPanelSummary>
            <Fade in={!isEdit} timeout={timeInterval}>
              <div className="button-option">
                {!isEdit ? (
                  <ModalAlertConfirmation
                    icon="ic-ffo-bin"
                    onClick={onDeletePermissionParent}
                    type="negative"
                    size="sm"
                    optionModal={modalAlertOption}
                    isButtonIcon
                    requiredPermission={`${PermissionModule.UserManagement}.${PermissionPage.RolesAndPermissions}.${PermissionAccess.Delete}`}
                  />
                ) : null}
              </div>
            </Fade>
          </div>
        </Grid>
      </Grid>
      <CardContent>{renderSubChildren()}</CardContent>
    </ExpansionPanel>
  );
};

CardPermissionDetail.propTypes = {
  dataArray: PropTypes.object,
  isEdit: PropTypes.bool,
  isExpand: PropTypes.string,
  onButtonClickAdd: PropTypes.func,
  onCheckedPermissionAccessChildren: PropTypes.func,
  onCheckedPermissionAccessParent: PropTypes.func,
  onDeletePermissionChildren: PropTypes.func,
  onDeletePermissionParent: PropTypes.func,
  onExpanded: PropTypes.func,
};

export default CardPermissionDetail;
