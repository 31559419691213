import React from 'react';
import PropTypes from 'prop-types';
// Style
import './CardAlertStyle.scss';
import { Grid } from '@material-ui/core';

const CardAlert = props => {
  const { iconPrefix, alertType, customElements } = props;

  return (
    <Grid container className={`container-card-alert ${alertType} flex-wrap-unset `}>
      <Grid item className="container-transition parent">
        <div className="transition-chile">
          <i className={`${iconPrefix} ${alertType} container-icon-prefix size-56`} />
        </div>
      </Grid>
      <Grid item lg md className="container-body">
        {customElements}
      </Grid>
    </Grid>
  );
};

CardAlert.propTypes = {
  alertType: PropTypes.string,
  customElements: PropTypes.element,
  iconPrefix: PropTypes.string,
};

export default CardAlert;
