import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
// style
import './MaintenanceStyle.scss';
// assets
// import { Images } from '../../../assets/Index';

class Maintenance extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    return (
      <div>
        <Helmet title="FITCO | Maintenance" />
        <div className="container-page-maintenance scroll-container-invisible">
          <div className="container-page-scrolling-area">
            <Grid container direction="column">
              <Grid item lg md className="section-page-header">
                Maintenance
              </Grid>
              <Grid item lg md className="section-page-body" />
              <Grid item lg md className="section-page-footer" />
            </Grid>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = () => ({});

const mapStateToProps = () => ({});

Maintenance.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  history: PropTypes.object,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Maintenance);
