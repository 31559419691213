/* eslint-disable no-lonely-if */
import React from 'react';
import { connect } from 'react-redux';
import { Grid, FormControl, FormLabel, Fade } from '@material-ui/core';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import * as Actions from '../../../../../redux/actions/OrderAction';
// component
import {
  TextInput,
  ButtonMain,
  PrevNextStepperCount,
  RadioInput,
  SelectInputMain,
  TextAreaMain,
  CheckInputOutlineBackground,
  TextInputAutocomplete,
  LabelAlert,
  TextInputPrePost,
} from '../../../../../components/Index';
// style
import './StepUserDetailsStyle.scss';
// helper
import {
  CommonHelper,
  currencyCode,
  MasterDataHelper,
  ValidationHelper,
  OrderHelper,
  HttpStatusCode,
  UserHelper,
} from '../../../../../helpers/Index';
// api
import {
  getUserDetailByPhone,
  getOrderShippingAddress,
  getOrderShippingAddressInfo,
  getOrderShippingCost,
  getGuestUser,
} from '../../../../../services/api/OrdersApi';
import { searchPatientByMedicalRecordNumber } from '../../../../../services/api/EatApi';
import UserCheckField from '../../../../../components/higher-order-components/UserCheckField';

const genderOptions = MasterDataHelper.gender;
const addressTypeValue = MasterDataHelper.addressTypeOrder;
const productTypeOrderOption = MasterDataHelper.productTypeForOrder;

const initialValidation = {
  phone: { isError: false, errorMessage: '' },
  medicalRecordNumber: { isError: false, errorMessage: '' },
  firstName: { isError: false, errorMessage: '' },
  lastName: { isError: false, errorMessage: '' },
  email: { isError: false, errorMessage: '' },
  address: { isError: false, errorMessage: '' },
  addressArea: { isError: '', errorMessage: '' },
  recipientName: { isError: false, errorMessage: '' },
  recipientPhone: { isError: false, errorMessage: '' },
  note: { isError: '', errorMessage: '' },
  deliveryService: { isError: '', errorMessage: '' },
  labelAlert: { isError: '', errorMessage: '', iconPrefix: 'ic-ffo-check-mark' },
};

const initialForm = {
  isFirstTimePurchase: false,
  isExisting: false,
  userId: null,
  phone: '',
  medicalRecordNumber: '',
  firstName: '',
  lastName: '',
  email: '',
  isReseller: false,
  gender: genderOptions[0],
  addressType: addressTypeValue[0].value,
  deliveryService: '',
  deliveryServiceInfo: null,
  addressId: '',
  address: '',
  placeId: '',
  addressInfo: {},
  note: '',
  isRecipientChange: false,
  recipientName: '',
  recipientPhone: '',
  isUseAnonymous: false,
  isStaffCheckout: false, 
};

const initialFreeDelivery = [
  {
    cost: [{ value: 0, etd: '1-3', note: '' }],
    courier: 'free',
    description: 'Free',
    icon: 'free',
    service: 'Delivery',
  },
];

const timeInterval = 300;

class StepUserDetails extends React.Component {
  constructor(props) {
    super(props);

    const currentUsers = UserHelper.getCurrentUserInformation();

    this.state = {
      form: CommonHelper.objectCloning(initialForm),
      validation: CommonHelper.objectCloning(initialValidation),
      isEditAddress: true,
      isSelectService: false,
      currentUsers,
      isResellerLogin: currentUsers && currentUsers.isResellerLogin,
      freeDelivery: CommonHelper.objectCloning(initialFreeDelivery),
      isDisabledNextBtn: false,
    };
    this.searchPhoneDebounce = _.debounce(this.searchPhoneDebounce, 600);
    this.searchEmailDebounce = _.debounce(this.searchEmailDebounce, 400);
    this.searchAddressDebounce = _.debounce(this.searchAddressDebounce, 400);
    this.searchAddressAreaDebounce = _.debounce(this.searchAddressAreaDebounce, 400);
    this.searchMedicalRecordNumberDebounce = _.debounce(
      this.searchMedicalRecordNumberDebounce,
      600,
    );
  }

  componentDidMount() {
    const {
      parentValidation,
      parentForm,
      parentFilter,
      storeDetailsSelected,
      orderData: { deliveryServiceOption, addressTypeOption },
      setCurrentAddressTypeOption,
      useAnonymous,
    } = this.props;
    const { isResellerLogin, currentUsers, form, validation } = this.state;
    let modifyIsEditAddress = false;
    let modifyIsSelectService = false;

    const isSpecialStoreSelected =
      parentFilter.orderType === productTypeOrderOption[1].value &&
      parentFilter.orderEatType === 'atstore' &&
      storeDetailsSelected.identification.includes('fitco-eats-catering-rspad');

    if (isResellerLogin) {
      parentForm.phone = currentUsers.phone;
    }

    if (parentForm.addressType === addressTypeValue[0].value) {
      modifyIsEditAddress = true;
    }
    if (!_.isEmpty(deliveryServiceOption) && parentForm.address) {
      modifyIsSelectService = true;
    }
    this.setState(
      {
        form: { ...form, ...parentForm, isUseAnonymous: useAnonymous },
        validation: { ...validation, ...parentValidation },
        isEditAddress: modifyIsEditAddress,
        isSelectService: modifyIsSelectService,
      },
      async () => {
        if (_.isEmpty(addressTypeOption)) {
          await setCurrentAddressTypeOption(addressTypeValue);
        }
        if (!_.isEmpty(parentForm.phone && !isSpecialStoreSelected)) {
          this.searchPhoneDebounce();
        }
      },
    );
  }

  componentWillReceiveProps(nextProps) {
    const { validation } = this.state;

    const isValidationChange = _.isEqual(validation, nextProps.parentValidation);

    if (!isValidationChange) {
      const finalMergeValidation = { ...validation, ...nextProps.parentValidation };
      this.setState({
        validation: finalMergeValidation,
      });
    }
  }

  componentWillUnmount() {}

  onProcessPlaceId() {
    const {
      form: { placeId },
      form,
    } = this.state;
    const { getShippingAddressInfo, onPopUpInformation } = this.props;

    const param = {
      place_id: placeId,
    };

    getShippingAddressInfo(param)
      .then(response => {
        const data = response.data;

        this.setState({ form: { ...form, addressInfo: data } }, () => {
          this.onProcessShippingCost();
        });
      })
      .catch(error => {
        const serverMessage = error.data;
        onPopUpInformation(serverMessage.messages, 'error');
      });
  }

  onProcessShippingCost() {
    const {
      form: { userId, addressType, items, addressInfo, addressId, orderType },
      form,
      validation,
    } = this.state;
    const {
      getShippingCost,
      onPopUpInformation,
      setCurrentDeliveryService,
      onParameterUpdate,
      parentFilter,
    } = this.props;
    const weightAndFItMarket = OrderHelper.getGrandTotalWeightAndFitMarket(items);
    let param = {};
    let userAddressId = null;
    let shippingAddress = null;

    if (addressType === addressTypeValue[0].value) {
      userAddressId = null;
      shippingAddress = addressInfo;
    } else {
      userAddressId = addressId;
      shippingAddress = null;
    }

    const isEatAtStoreOnly =
      parentFilter.orderType === productTypeOrderOption[1].value &&
      parentFilter.orderEatType === 'atstore';

    param = {
      data: {
        order_type: orderType,
        weight: weightAndFItMarket.weight,
        user_address_id: userAddressId,
        shipping_address: shippingAddress,
        is_contain_fitmarket: weightAndFItMarket.isFitMarket,
        user_id: userId,
      },
    };

    if (shippingAddress || userAddressId) {
      getShippingCost(param)
        .then(response => {
          const data = response.data;
          const deliveryServiceOption = OrderHelper.reformatDeliveryServiceOption(data);

          this.setState(
            {
              isSelectService: true,
            },
            async () => {
              let valueChange = deliveryServiceOption[0].value;
              if (isEatAtStoreOnly) {
                valueChange = deliveryServiceOption[2].value;
              }

              await setCurrentDeliveryService(deliveryServiceOption);
              await this.handleSelectDeliveryService(valueChange);
            },
          );
        })
        .catch(error => {
          const formNewValue = CommonHelper.objectCloning(form);
          const resetValidation = CommonHelper.objectCloning(validation);

          formNewValue.deliveryService = '';
          resetValidation.deliveryService.isError = 'error';
          resetValidation.deliveryService.errorMessage = 'Area not Covered';

          this.setState(
            {
              form: { ...form, deliveryService: '' },
              isSelectService: false,
            },
            async () => {
              const serverMessage = error.data;
              const validationStatus = error.status === HttpStatusCode.InternalServerError;
              await setCurrentDeliveryService([]);
              await onPopUpInformation(
                validationStatus ? serverMessage.message : serverMessage.messages,
                'error',
              );
              await onParameterUpdate(formNewValue, resetValidation);
            },
          );
        });
    }
  }

  searchPhoneDebounce = async () => {
    const {
      getUserByPhone,
      onPopUpInformation,
      setCurrentAddressTypeOption,
      setCurrentAddressTypeOptionRaw,
      setCurrentDeliveryService,
      parentForm,
      parentFilter,
    } = this.props;
    const {
      form: { phone },
      form,
      validation,
      freeDelivery,
    } = this.state;
    const formNewValue = CommonHelper.objectCloning(form);
    const resetValidation = CommonHelper.objectCloning(validation);
    const fullResetValidation = CommonHelper.objectCloning(initialValidation);
    const modifyLabeMessage = CommonHelper.objectCloning(initialValidation.labelAlert);
    const message = CommonHelper.objectCloning(initialValidation.phone);

    const isEatAtStoreOnly =
      parentFilter.orderType === productTypeOrderOption[1].value &&
      parentFilter.orderEatType === 'atstore';

    const param = { phone };

    await getUserByPhone(param)
      .then(async response => {
        const data = response.data;
        let finalAddressTypeOption = [addressTypeValue[0]];
        let addressTypeOptionRaw = [];

        if (!_.isEmpty(data.user_details)) {
          formNewValue.isFirstTimePurchase = data.is_first_time_purchase;
          formNewValue.isExisting = data.is_user_exists;
          formNewValue.userId = data.user_details.user_id;
          formNewValue.firstName = data.user_details.first_name;
          formNewValue.lastName = data.user_details.last_name || '';
          formNewValue.email = data.user_details.email || '';
          formNewValue.isReseller = data.is_reseller;
          formNewValue.isStaffCheckout = data.is_staff;
          formNewValue.gender = _.find(genderOptions, ['value', data.user_details.gender]);
          formNewValue.recipientName = `${data.user_details.first_name} ${data.user_details
            .last_name || ''}`.trim();
          formNewValue.recipientPhone = phone;

          let addressTypeOption = OrderHelper.generateAddressOrderOption(
            data.user_details.user_address,
          );
          addressTypeOptionRaw = CommonHelper.objectCloning(addressTypeOption);

          addressTypeOption = OrderHelper.generateAddressOrderGroupOption(addressTypeOption);

          finalAddressTypeOption = OrderHelper.generateAddressOrderGroupSameOption(
            addressTypeOption,
          );

          finalAddressTypeOption.push(addressTypeValue[0]);
          modifyLabeMessage.errorMessage = `Yay! We found matching the user with the phone: '${phone}'`;
          modifyLabeMessage.isError = `success`;
          modifyLabeMessage.iconPrefix = 'ic-ffo-check-mark';

          resetValidation.firstName = fullResetValidation.firstName;
          resetValidation.lastName = fullResetValidation.lastName;
          resetValidation.email = fullResetValidation.email;
          resetValidation.isReseller = fullResetValidation.isReseller;
          resetValidation.note = fullResetValidation.note;
        } else if (data.is_required_new_number) {
          message.isError = true;
          message.errorMessage = "Phone Can't be use, change another Number";
          resetValidation.phone = message;
        } else {
          const resetData = CommonHelper.objectCloning({ ...initialForm, ...parentForm });
          formNewValue.isFirstTimePurchase = data.is_first_time_purchase;
          formNewValue.isExisting = data.is_user_exists;
          formNewValue.userId = resetData.userId;
          formNewValue.firstName = resetData.firstName;
          formNewValue.lastName = resetData.lastName;
          formNewValue.email = resetData.email;
          formNewValue.isReseller = resetData.isReseller;
          formNewValue.isStaff = resetData.isStaffCheckout;
          formNewValue.gender = resetData.gender || initialForm.gender;
          formNewValue.recipientName = resetData.recipientName;
          formNewValue.addressType = resetData.addressType;
          formNewValue.address = resetData.address;
          formNewValue.note = resetData.note;
          formNewValue.recipientPhone = phone;
          formNewValue.deliveryService = resetData.deliveryService;

          modifyLabeMessage.errorMessage = `No matching user found with the phone: '${phone}', please complete the details`;
          modifyLabeMessage.isError = `error`;
          modifyLabeMessage.iconPrefix = 'ic-ffo-close-box';
        }

        resetValidation.address = validation.address;
        resetValidation.recipientName = validation.recipientName;
        resetValidation.recipientPhone = validation.recipientPhone;
        resetValidation.phone = message;
        resetValidation.labelAlert = modifyLabeMessage;

        await setCurrentAddressTypeOption(finalAddressTypeOption);
        await setCurrentAddressTypeOptionRaw(addressTypeOptionRaw);
        await this.setState(
          {
            form: formNewValue,
            validation: resetValidation,
          },
          async () => {
            let valueChange = null;
            if (data.is_user_exists) {
              if (!data.user_details) {
                await setCurrentDeliveryService([]);
              }
              //  else {
              //   formNewValue.firstName = data.user_details.first_name;
              // }

              valueChange = !_.isEmpty(finalAddressTypeOption[0].group)
                ? finalAddressTypeOption[0].group[0].value
                : addressTypeValue[0].value;
            } else {
              const deliveryServiceOption = OrderHelper.reformatDeliveryServiceOption(freeDelivery);
              const checkExistingOption = finalAddressTypeOption.filter(
                item => item.value === parentForm.addressType,
              );

              if (!_.isEmpty(checkExistingOption)) {
                valueChange = parentForm.typeActionBooster;
              } else if (isEatAtStoreOnly) {
                valueChange = deliveryServiceOption[0].value;
              } else {
                valueChange = addressTypeValue[0].value;
              }

              if (formNewValue.email) {
                await this.searchEmailDebounce();
              }
            }

            // const finalValueChange = _.isEmpty(formNewValue.addressInfo);

            // if (finalValueChange && valueChange) {
            if (valueChange) {
              await this.handleSelectAddressType(valueChange);
            }
          },
        );
      })
      .catch(error => {
        const serverMessage = error.data;
        onPopUpInformation(serverMessage.messages, 'error');
      });
  };

  searchMedicalRecordNumberDebounce = async medicalNumber => {
    const {
      patientByMedicalRecordNumber,
      onPopUpInformation,
      storeDetailsSelected,
      setCurrentDeliveryService,
    } = this.props;
    const { form, validation } = this.state;
    const resetValidation = CommonHelper.objectCloning(validation);
    const modifyLabelMessage = CommonHelper.objectCloning(initialValidation.labelAlert);
    const reformatDelivery = CommonHelper.objectCloning(initialFreeDelivery);
    try {
      const { data } = await patientByMedicalRecordNumber(medicalNumber);

      modifyLabelMessage.errorMessage = `Yay! We found matching the user with the Nomor Rekam Medis (NRM): '${medicalNumber}'`;
      modifyLabelMessage.isError = `success`;
      resetValidation.labelAlert = modifyLabelMessage;

      const gender = genderOptions.find(item => item.value === +data.gender);
      const wordingNotes = `${data.room_detail.room_type} - ${data.room_detail.room_name} - ${data.room_detail.room_number}`;

      const deliveryServiceOption = OrderHelper.reformatDeliveryServiceOption(reformatDelivery);

      this.setState(
        {
          form: {
            ...form,
            firstName: data.first_name,
            lastName: data.last_name,
            recipientName: `${data.first_name} ${data.last_name}`,
            gender,
            email: data.email,
            phone: data.phone,
            addressType: storeDetailsSelected.name,
            address: storeDetailsSelected.address,
            note: wordingNotes,
            recipientPhone: data.phone,
            userId: data.user_id,
            userRoomId: data.room_detail.user_room_id,
          },
          validation: resetValidation,
          freeDelivery: deliveryServiceOption,
          isDisabledNextBtn: false,
        },
        async () => {
          const valueChange = deliveryServiceOption[0].value;
          await setCurrentDeliveryService(deliveryServiceOption);
          await this.handleSelectDeliveryService(valueChange);
        },
      );
    } catch (error) {
      const message = error.data;
      onPopUpInformation(message.messages, 'error');
      modifyLabelMessage.errorMessage = message.messages[0];
      modifyLabelMessage.isError = `error`;
      modifyLabelMessage.iconPrefix = 'ic-ffo-close-box';
      resetValidation.labelAlert = modifyLabelMessage;
      this.setState({
        form: {
          ...CommonHelper.objectCloning(initialForm),
          medicalRecordNumber: form.medicalRecordNumber,
        },
        validation: resetValidation,
        isDisabledNextBtn: true,
      });
    }
  };

  searchEmailDebounce = () => {
    const { checkUserPhoneOrEmail } = this.props;
    const { validation } = this.state;
    const {
      form: { email },
    } = this.state;

    const param = {
      data: {
        email,
      },
    };

    checkUserPhoneOrEmail(param).catch(error => {
      const message = CommonHelper.objectCloning(initialValidation.email);
      const convertedMessage = CommonHelper.generateMessage(error.message);
      message.isError = true;
      message.errorMessage = convertedMessage;

      this.setState({ validation: { ...validation, email: message } });
    });
  };

  searchAddressDebounce = () => {
    const { form, validation } = this.state;
    const { getShippingAddress, onPopUpInformation } = this.props;

    const param = {
      search_term: form.address,
    };

    const message = CommonHelper.objectCloning(initialValidation.address);
    message.isError = !form.address;
    message.errorMessage = !form.address ? 'Please enter Address' : '';

    this.setState(
      {
        form: { ...form, address: form.address },
        validation: { ...validation, address: message },
      },
      () => {
        getShippingAddress(param).catch(error => {
          const serverMessage = error.data;
          onPopUpInformation(serverMessage.messages, 'error');
        });
      },
    );
  };

  searchAddressAreaDebounce = () => {
    const { form, validation } = this.state;
    const message = CommonHelper.objectCloning(initialValidation.address);
    message.isError = !form.address ? 'error' : '';
    message.errorMessage = !form.address ? 'Please enter Address' : '';

    this.setState({
      form: { ...form, address: form.address },
      validation: { ...validation, address: message },
    });
  };

  handleChangePhone = value => {
    const { form, validation } = this.state;
    const checkPhoneFormat = ValidationHelper.validatePhone(value.trim());
    const resetForm = CommonHelper.objectCloning(initialForm);
    const message = CommonHelper.objectCloning(initialValidation.phone);
    message.isError = !!(!value || !checkPhoneFormat);
    message.errorMessage =
      !value || !checkPhoneFormat ? 'Please enter Phone or wrong Phone Format' : '';

    const modifyForm = !value ? resetForm : { ...form, phone: value };

    this.setState(
      {
        form: modifyForm,
        validation: { ...validation, phone: message },
      },
      () => {
        if (checkPhoneFormat) {
          this.searchPhoneDebounce();
        }
      },
    );
  };

  handleChangeMedicalRecordNumber = value => {
    const { form, validation } = this.state;
    const resetForm = CommonHelper.objectCloning(initialForm);
    const message = CommonHelper.objectCloning(initialValidation.medicalRecordNumber);
    message.isError = !value;
    message.errorMessage = !value ? 'Please enter Nomor Rekam Medis (NRM)' : '';

    const modifyForm = !value ? resetForm : { ...form, medicalRecordNumber: value };

    this.setState(
      {
        form: modifyForm,
        validation: { ...validation, medicalRecordNumber: message },
      },
      () => {
        if (value) {
          this.searchMedicalRecordNumberDebounce(value);
        }
      },
    );
  };

  handleChangeFirstName = value => {
    const { form, validation } = this.state;
    const message = CommonHelper.objectCloning(initialValidation.firstName);
    let modifyRecipientName = CommonHelper.objectCloning(form.recipientName) || '';

    message.isError = !value;
    message.errorMessage = !value ? 'Please enter First Name' : '';

    if (form.lastName.length === 0 && !form.isExisting) {
      modifyRecipientName = value;
    } else if (form.lastName.length > 0 && !form.isExisting) {
      modifyRecipientName = `${value} ${form.lastName}`;
    }

    this.setState({
      form: { ...form, firstName: value, recipientName: modifyRecipientName },
      validation: { ...validation, firstName: message },
    });
  };

  handleChangeLastName = value => {
    const { form, validation } = this.state;
    const message = CommonHelper.objectCloning(initialValidation.lastName);
    let modifyRecipientName = CommonHelper.objectCloning(form.recipientName) || '';
    // message.isError = !value;
    // message.errorMessage = !value ? 'Please enter Last Name' : '';

    if (form.firstName.length === 0 && !form.isExisting) {
      modifyRecipientName = value;
    } else if (form.firstName.length > 0 && !form.isExisting) {
      modifyRecipientName = `${form.firstName} ${value}`;
    }

    this.setState({
      form: { ...form, lastName: value, recipientName: modifyRecipientName },
      validation: { ...validation, lastName: message },
    });
  };

  handleChangeEmail = value => {
    const { parentFilter, storeDetailsSelected } = this.props;
    const { form, validation } = this.state;
    const message = CommonHelper.objectCloning(initialValidation.email);
    const checkerEmailFormat = ValidationHelper.validateEmail(value.trim());
    const isSpecialStoreSelected =
      parentFilter.orderType === productTypeOrderOption[1].value &&
      parentFilter.orderEatType === 'atstore' &&
      storeDetailsSelected.identification.includes('fitco-eats-catering-rspad');

    if (value && !checkerEmailFormat && !isSpecialStoreSelected) {
      message.isError = true;
      message.errorMessage = 'Please enter Email, or wrong Email Format';
    }

    this.setState(
      {
        form: { ...form, email: value },
        validation: { ...validation, email: message },
      },
      () => {
        if (!form.isExisting && checkerEmailFormat) {
          this.searchEmailDebounce();
        }
      },
    );
  };

  handleChangeGender = value => {
    const { form, validation } = this.state;
    const { onParameterUpdate } = this.props;
    const formNewValue = CommonHelper.objectCloning(form);

    this.setState({ form: { ...form, gender: value } }, () => {
      formNewValue.gender = value;
      onParameterUpdate(formNewValue, validation);
    });
  };

  handleSelectAddressType = value => {
    const { form, isSelectService, freeDelivery } = this.state;
    const {
      onParameterUpdate,
      orderData: { addressTypeOptionRaw, deliveryServiceOption },
      setCurrentDeliveryService,
      parentFilter,
      storeDetailsSelected,
    } = this.props;
    const formNewValue = CommonHelper.objectCloning(form);
    const resetValidation = CommonHelper.objectCloning(this.state.validation);

    const isEatAtStoreOnly =
      parentFilter.orderType === productTypeOrderOption[1].value &&
      parentFilter.orderEatType === 'atstore';

    let isEditAddress = false;
    let modifySelectService = isSelectService;
    let modifyDeliveryServiceOption = deliveryServiceOption;
    let modifyDeliveryService = form.deliveryService;
    let address = '';
    let addressType = '';
    let addressId = '';
    let note = '';

    if (value === addressTypeValue[0].value) {
      isEditAddress = true;
      modifySelectService = false;
      modifyDeliveryServiceOption = [];
      modifyDeliveryService = '';
    } else if (!_.isEmpty(addressTypeOptionRaw) && !isEatAtStoreOnly) {
      let findMatchData = _.find(addressTypeOptionRaw, ['value', value]);

      if (!findMatchData) {
        findMatchData = OrderHelper.findAddressOrderGroupOption(addressTypeOptionRaw, value);
      }

      address = findMatchData.additional.address;
      addressId = findMatchData.additional.user_address_id;
      note = findMatchData.additional.notes;
    } else if (isEatAtStoreOnly) {
      addressType = storeDetailsSelected.name;
      address = storeDetailsSelected.address;
      note = '-';
    }

    this.setState(
      {
        form: {
          ...form,
          addressType: isEatAtStoreOnly ? addressType : value,
          address,
          addressId,
          deliveryService: modifyDeliveryService,
          note,
        },
        isEditAddress,
        isSelectService: modifySelectService,
      },
      async () => {
        formNewValue.addressType = value;
        formNewValue.address = address;
        formNewValue.addressId = addressId;
        formNewValue.note = note;
        resetValidation.deliveryService.isError = '';
        resetValidation.deliveryService.errorMessage = '';

        await setCurrentDeliveryService(modifyDeliveryServiceOption);
        await onParameterUpdate(formNewValue, resetValidation);

        if (isEatAtStoreOnly) {
          const deliveryServiceOptionChange = OrderHelper.reformatDeliveryServiceOption(
            freeDelivery,
          );
          this.setState({ freeDelivery }, async () => {
            const valueChange = deliveryServiceOptionChange[0].value;
            await setCurrentDeliveryService(deliveryServiceOptionChange);
            await this.handleSelectDeliveryService(valueChange);
          });
        } else if (value !== addressTypeValue[0].value) {
          await this.onProcessShippingCost();
        }
      },
    );
  };

  handleSelectDeliveryService = value => {
    const {
      orderData: { deliveryServiceOption },
    } = this.props;
    const { form, validation } = this.state;
    const selectedValue = _.find(deliveryServiceOption, ['value', value]);
    const message = CommonHelper.objectCloning(initialValidation.deliveryService);

    if (value === '' || value === undefined) {
      message.isError = 'error';
      message.errorMessage = 'Area not Covered';
    }

    this.setState({
      form: {
        ...form,
        deliveryService: value,
        deliveryServiceInfo: selectedValue.additional || null,
      },
      validation: { ...validation, deliveryService: message },
    });
  };

  handleTextChangeAddress = value => {
    const { form } = this.state;
    this.setState(
      {
        form: { ...form, address: value },
      },
      () => {
        this.searchAddressDebounce();
      },
    );
  };

  handleTextChangeAddressArea = value => {
    const { form } = this.state;

    this.setState(
      {
        form: { ...form, address: value },
      },
      () => {
        this.searchAddressAreaDebounce();
      },
    );
  };

  handleSelectedItemAddress = (value, option) => {
    const { form, validation } = this.state;
    const { onParameterUpdate } = this.props;

    this.setState({ form: { ...form, address: option.address, placeId: option.place_id } }, () => {
      const message = CommonHelper.objectCloning(initialValidation.address);
      const formNewValue = CommonHelper.objectCloning(form);

      validation.address = message;
      formNewValue.address = option.address;
      formNewValue.placeId = option.place_id;
      onParameterUpdate(formNewValue, validation);

      this.onProcessPlaceId();
    });
  };

  handleTextChangeNote = value => {
    const {
      form: { note },
      form,
      validation,
    } = this.state;
    const message = CommonHelper.objectCloning(initialValidation.note);

    if (note === '' || note === undefined) {
      message.isError = 'error';
      message.errorMessage = 'Please enter note Address';
    }

    this.setState({
      form: { ...form, note: value },
      validation: { ...validation, note: message },
    });
  };

  handleCheckedModifyUseAnonymous = async value => {
    const { freeDelivery, validation } = this.state;
    const {
      processGetGuestUser,
      storeDetailsSelected,
      setCurrentDeliveryService,
      onSetAnonymous,
    } = this.props;

    const resetForm = CommonHelper.objectCloning(initialForm);
    const resetValidation = CommonHelper.objectCloning(validation);
    const fullResetValidation = CommonHelper.objectCloning(initialValidation);
    const modifyLabeMessage = CommonHelper.objectCloning(initialValidation.labelAlert);
    const message = CommonHelper.objectCloning(initialValidation.phone);
    const deliveryServiceOption = OrderHelper.reformatDeliveryServiceOption(freeDelivery);

    if (value) {
      const response = await processGetGuestUser();
      resetValidation.firstName = fullResetValidation.firstName;
      resetValidation.lastName = fullResetValidation.lastName;
      resetValidation.email = fullResetValidation.email;
      resetValidation.note = fullResetValidation.note;
      resetValidation.address = fullResetValidation.address;
      resetValidation.recipientName = fullResetValidation.recipientName;
      resetValidation.recipientPhone = fullResetValidation.recipientPhone;
      resetValidation.phone = message;
      resetValidation.labelAlert = modifyLabeMessage;
      this.setState(
        state => {
          return {
            form: {
              ...state.form,
              userId: response.data.user_id,
              firstName: response.data.first_name,
              lastName: response.data.last_name || '',
              email: response.data.email || '',
              phone: response.data.phone,
              recipientName: response.data.first_name,
              recipientPhone: response.data.phone,
              note: response.data.first_name,
              isUseAnonymous: value,
              isStaffCheckout: false,
              address: storeDetailsSelected.address,
              addressType: storeDetailsSelected.name,
            },
            validation: resetValidation,
          };
        },
        async () => {
          const valueChange = deliveryServiceOption[0].value;
          await setCurrentDeliveryService(deliveryServiceOption);
          await this.handleSelectAddressType(valueChange);
        },
      );
    } else {
      resetValidation.firstName = fullResetValidation.firstName;
      resetValidation.lastName = fullResetValidation.lastName;
      resetValidation.email = fullResetValidation.email;
      resetValidation.note = fullResetValidation.note;
      resetValidation.address = fullResetValidation.address;
      resetValidation.recipientName = fullResetValidation.recipientName;
      resetValidation.recipientPhone = fullResetValidation.recipientPhone;
      resetValidation.phone = message;
      resetValidation.labelAlert = modifyLabeMessage;
      this.setState(
        {
          form: {
            ...resetForm,
            isUseAnonymous: value,
            isStaffCheckout: false,
            address: storeDetailsSelected.address,
            addressType: storeDetailsSelected.name,
          },
          validation: resetValidation,
        },
        async () => {
          const valueChange = deliveryServiceOption[0].value;
          await setCurrentDeliveryService(deliveryServiceOption);
          await this.handleSelectAddressType(valueChange);
        },
      );
    }

    onSetAnonymous(value);
  };

  handleCheckedModifyRecipient = value => {
    const { form, validation } = this.state;
    const formNewValue = CommonHelper.objectCloning(form);
    let recipientName = formNewValue.recipientName;
    let recipientPhone = formNewValue.recipientPhone;

    let message01 = CommonHelper.objectCloning(validation.recipientName);
    let message02 = CommonHelper.objectCloning(validation.recipientPhone);

    if (!value) {
      recipientName = `${formNewValue.firstName} ${formNewValue.lastName || ''}`.trim();
      recipientPhone = formNewValue.phone;

      message01 = CommonHelper.objectCloning(initialValidation.recipientName);
      message02 = CommonHelper.objectCloning(initialValidation.recipientPhone);
    } else {
      recipientName = '';
      recipientPhone = '';
    }

    this.setState({
      form: { ...form, isRecipientChange: value, recipientName, recipientPhone },
      validation: { ...validation, recipientName: message01, recipientPhone: message02 },
    });
  };

  handleChangeRecipientName = value => {
    const { form, validation } = this.state;
    const message = CommonHelper.objectCloning(initialValidation.recipientName);

    if (value === '' || value === undefined) {
      message.isError = true;
      message.errorMessage = 'Please enter Recipient Name';
    }

    this.setState({
      form: { ...form, recipientName: value },
      validation: { ...validation, recipientName: message },
    });
  };

  handleChangeRecipientPhone = value => {
    const { form, validation } = this.state;
    const message = CommonHelper.objectCloning(initialValidation.recipientPhone);
    const checkPhoneFormat = ValidationHelper.validatePhone(value.trim());

    if (value === '' || value === undefined || !checkPhoneFormat) {
      message.isError = true;
      message.errorMessage = 'Please enter Recipient Phone or wrong phone format';
    }

    this.setState({
      form: { ...form, recipientPhone: value },
      validation: { ...validation, recipientPhone: message },
    });
  };

  handlePrevClick = async () => {
    const { onPrevClick, onParameterUpdate } = this.props;
    const { form, validation } = this.state;
    const formNewValue = CommonHelper.objectCloning(form);
    const validationNewValue = CommonHelper.objectCloning(validation);

    await onParameterUpdate(formNewValue, validationNewValue);
    await onPrevClick();
  };

  handleNextClick = async () => {
    const { onNextClick, onParameterUpdate } = this.props;
    const { form, validation } = this.state;
    const formNewValue = CommonHelper.objectCloning(form);
    const validationNewValue = CommonHelper.objectCloning(validation);

    await onParameterUpdate(formNewValue, validationNewValue);
    await onNextClick();
  };

  handleChangeShippingCost = value => {
    const { form, validation } = this.state;
    const { onParameterUpdate } = this.props;

    const formNewValue = CommonHelper.objectCloning(form);
    const updateDeliveryService = CommonHelper.objectCloning(form.deliveryServiceInfo);
    updateDeliveryService.cost[0].value = parseFloat(value);

    this.setState(
      {
        form: {
          ...form,
          deliveryServiceInfo: updateDeliveryService,
        },
      },
      () => {
        formNewValue.deliveryServiceInfo.cost[0].value = parseFloat(value);
        onParameterUpdate(formNewValue, validation);
      },
    );
  };

  render() {
    const {
      validation,
      form: {
        isExisting,
        isRecipientChange,
        phone,
        orderType,
        medicalRecordNumber,
        isUseAnonymous,
      },
      isEditAddress,
      isSelectService,
      isResellerLogin,
      isDisabledNextBtn,
    } = this.state;
    const {
      onButtonClickCancel,
      orderData: { fetching, fetchingShippingAddress, addressTypeOption, deliveryServiceOption },
      orderData,
      parentFilter,
      storeDetailsSelected,
    } = this.props;
    const ListCustom = orderData.listShippingAddress;
    const modifyIsExisting = phone === '' ? true : isExisting;
    // Hardcode for eat at store only RSPAD
    const isEatAtStoreOnly =
      parentFilter.orderType === productTypeOrderOption[1].value &&
      parentFilter.orderEatType === 'atstore';
    const isSpecialStoreSelected =
      isEatAtStoreOnly && storeDetailsSelected.identification.includes('fitco-eats-catering-rspad');

    return (
      <Grid container className="container-step-user-details">
        <Grid item lg={12} md={12} className="mb-4 mt-32">
          <label className="text-12 section-main-title">CUSTOMER DETAILS</label>
        </Grid>
        {isEatAtStoreOnly && !isSpecialStoreSelected ? (
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Grid container spacing={2}>
              <Grid item lg={6} md={6} sm={6}>
                <FormControl component="fieldset" fullWidth margin="normal" className="mt-41">
                  <CheckInputOutlineBackground
                    labelText={`Checkout as Guest`}
                    onCheck={this.handleCheckedModifyUseAnonymous}
                    currentValue={isUseAnonymous}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        ) : null}
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Grid container spacing={2}>
            <Grid item lg={6} md={6} sm={6}>
              {isSpecialStoreSelected ? (
                <FormControl component="fieldset" fullWidth margin="normal">
                  <FormLabel component="label" className="text-12 filed-title">
                    Medical Record Number
                  </FormLabel>
                  <TextInput
                    placeHolderText="Medical Record Number"
                    size="md"
                    onChange={this.handleChangeMedicalRecordNumber}
                    currentValue={this.state.form.medicalRecordNumber}
                    errorMessage={validation.medicalRecordNumber.errorMessage}
                    isError={validation.medicalRecordNumber.isError}
                    isLoading={fetching}
                  />
                </FormControl>
              ) : (
                <FormControl component="fieldset" fullWidth margin="normal">
                  <FormLabel component="label" className="text-12 filed-title">
                    Phone Number
                  </FormLabel>
                  <TextInput
                    placeHolderText="Phone Number"
                    size="md"
                    onChange={this.handleChangePhone}
                    currentValue={this.state.form.phone}
                    errorMessage={validation.phone.errorMessage}
                    isError={validation.phone.isError}
                    isLoading={fetching}
                    isDisable={isResellerLogin || fetching || isUseAnonymous}
                    maxLength={14}
                  />
                </FormControl>
              )}
            </Grid>
            <Fade
              in={
                (phone !== '' || medicalRecordNumber !== '') &&
                validation.labelAlert.errorMessage !== '' &&
                !isUseAnonymous
              }
              timeout={timeInterval}
            >
              <Grid item lg={6} md={6} sm={6} className="mt-43">
                <LabelAlert
                  message={validation.labelAlert.errorMessage}
                  type={validation.labelAlert.isError}
                  iconPrefix={validation.labelAlert.iconPrefix}
                />
              </Grid>
            </Fade>
          </Grid>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Grid container spacing={2}>
            <Grid item lg={6} md={6} sm={6}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <FormLabel component="label" className="text-12 filed-title">
                  First Name
                </FormLabel>
                <TextInput
                  placeHolderText="First Name"
                  size="md"
                  onChange={this.handleChangeFirstName}
                  currentValue={this.state.form.firstName}
                  errorMessage={validation.firstName.errorMessage}
                  isError={validation.firstName.isError}
                  isDisable={modifyIsExisting || isSpecialStoreSelected || isUseAnonymous}
                  maxLength={25}
                />
              </FormControl>
            </Grid>
            <Grid item lg={6} md={6} sm={6}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <FormLabel component="label" className="text-12 filed-title">
                  Last Name
                </FormLabel>
                <TextInput
                  placeHolderText="Last Name"
                  size="md"
                  onChange={this.handleChangeLastName}
                  currentValue={this.state.form.lastName}
                  errorMessage={validation.lastName.errorMessage}
                  isError={validation.lastName.isError}
                  isDisable={modifyIsExisting || isSpecialStoreSelected || isUseAnonymous}
                  maxLength={25}
                />
              </FormControl>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <FormLabel component="label" className="text-12 filed-title">
                  Email
                </FormLabel>
                <TextInput
                  placeHolderText="Email"
                  size="md"
                  onChange={this.handleChangeEmail}
                  currentValue={this.state.form.email}
                  errorMessage={validation.email.errorMessage}
                  isError={validation.email.isError}
                  isDisable={modifyIsExisting || isSpecialStoreSelected || isUseAnonymous}
                  maxLength={100}
                />
              </FormControl>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <FormLabel component="label" className="text-12 filed-title">
                  Gender
                </FormLabel>
                <RadioInput
                  data={genderOptions}
                  onSelect={this.handleChangeGender}
                  direction="column"
                  currentValue={this.state.form.gender}
                  isDisable={modifyIsExisting || isSpecialStoreSelected || isUseAnonymous}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        {!isEatAtStoreOnly && (
          <Grid item lg={12} md={12} className="mb-4 mt-32">
            <label className="text-12 section-main-title">SHIPPING DETAILS</label>
          </Grid>
        )}
        {!isEatAtStoreOnly && (
          <Grid item lg={12} md={12}>
            <Grid container spacing={2}>
              <Grid item lg={6} md={6}>
                <FormControl component="fieldset" fullWidth margin="normal">
                  <FormLabel component="label" className="text-12 filed-title">
                    Select Address from
                  </FormLabel>
                  <SelectInputMain
                    options={addressTypeOption}
                    currentValue={this.state.form.addressType || addressTypeValue[0].value}
                    onChange={this.handleSelectAddressType}
                    isGroupOption
                    disabled={isSpecialStoreSelected || isEatAtStoreOnly}
                  />
                </FormControl>
                <FormControl component="fieldset" fullWidth margin="normal">
                  <FormLabel component="label" className="text-12 filed-title">
                    Delivery Address
                  </FormLabel>
                  {isEditAddress ? (
                    <TextInputAutocomplete
                      size="md"
                      onChange={this.handleTextChangeAddress}
                      currentValue={this.state.form.address}
                      placeholder="Address"
                      onSelect={this.handleSelectedItemAddress}
                      isLoading={fetchingShippingAddress}
                      data={ListCustom}
                      isCustomDisplay
                      customDisplayClass="custom-address-option-item"
                      isError={this.state.validation.address.isError}
                      errorMessage={this.state.validation.address.errorMessage}
                    />
                  ) : (
                    <TextAreaMain
                      onChange={this.handleTextChangeAddressArea}
                      currentValue={this.state.form.address}
                      placeholder="Address"
                      rows={3}
                      isDisable
                      validateStatus={this.state.validation.addressArea.isError}
                      errorMessage={this.state.validation.addressArea.errorMessage}
                      maxLength={500}
                    />
                  )}
                </FormControl>
                <FormControl component="fieldset" fullWidth margin="normal">
                  <FormLabel component="label" className="text-12 filed-title">
                    {isSpecialStoreSelected ? 'Notes' : 'Address Notes'}
                  </FormLabel>
                  <TextAreaMain
                    onChange={this.handleTextChangeNote}
                    currentValue={this.state.form.note}
                    placeholder={
                      isSpecialStoreSelected
                        ? '(eg. VVIP - Kartika II - 219)'
                        : '(exp: Building number, house No. building floor)'
                    }
                    rows={3}
                    validateStatus={this.state.validation.note.isError}
                    errorMessage={this.state.validation.note.errorMessage}
                    maxLength={99}
                    isDisable={isSpecialStoreSelected || isEatAtStoreOnly}
                  />
                </FormControl>
                <Grid container spacing={2}>
                  <Grid item lg={6} md={6}>
                    <FormControl component="fieldset" fullWidth margin="normal">
                      <FormLabel component="label" className="text-12 filed-title">
                        Shipping Service
                      </FormLabel>
                      <SelectInputMain
                        options={deliveryServiceOption}
                        currentValue={this.state.form.deliveryService}
                        onChange={this.handleSelectDeliveryService}
                        disabled={!isSelectService || isSpecialStoreSelected || isEatAtStoreOnly}
                        validateStatus={this.state.validation.deliveryService.isError}
                        errorMessage={this.state.validation.deliveryService.errorMessage}
                      />
                    </FormControl>
                  </Grid>
                  {isSelectService && !isEatAtStoreOnly && (
                    <Grid item lg={6} md={6}>
                      <FormControl component="fieldset" fullWidth margin="normal">
                        <FormLabel component="label" className="text-12 filed-title">
                          {orderType === 'eat' ? 'Shipping Cost per Day' : 'Shipping Cost'}
                        </FormLabel>
                        <TextInputPrePost
                          placeHolderText="Enter Shipping Cost"
                          onChange={this.handleChangeShippingCost}
                          currentValue={
                            this.state.form.deliveryServiceInfo
                              ? this.state.form.deliveryServiceInfo.cost[0].value
                              : 0
                          }
                          customElementsBefore={currencyCode.idr}
                          disabled={isResellerLogin}
                        />
                      </FormControl>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item lg={6} md={6}>
                <FormControl component="fieldset" fullWidth margin="normal" className="mt-41">
                  <CheckInputOutlineBackground
                    labelText={`I'd like to use different Recipient Details`}
                    onCheck={this.handleCheckedModifyRecipient}
                    disabled={_.isEmpty(deliveryServiceOption)}
                  />
                </FormControl>
                <FormControl component="fieldset" fullWidth margin="normal">
                  <FormLabel component="label" className="text-12 filed-title">
                    Recipient Name
                  </FormLabel>
                  <TextInput
                    placeHolderText="Recipient Name"
                    size="md"
                    onChange={this.handleChangeRecipientName}
                    currentValue={this.state.form.recipientName}
                    errorMessage={validation.recipientName.errorMessage}
                    isError={validation.recipientName.isError}
                    isDisable={!isRecipientChange}
                    maxLength={50}
                  />
                </FormControl>
                <FormControl component="fieldset" fullWidth margin="normal">
                  <FormLabel component="label" className="text-12 filed-title">
                    Phone Number
                  </FormLabel>
                  <TextInput
                    placeHolderText="Phone Number"
                    size="md"
                    onChange={this.handleChangeRecipientPhone}
                    currentValue={this.state.form.recipientPhone}
                    errorMessage={validation.recipientPhone.errorMessage}
                    isError={validation.recipientPhone.isError}
                    isDisable={!isRecipientChange}
                    maxLength={12}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid item lg={12} md={12} sm={12} className="mt-24">
          <Grid container justify="flex-end">
            <Grid item lg={5} md={6}>
              <Grid container justify="space-around">
                <Grid item lg={4} md={4} className="pl-8">
                  <ButtonMain
                    type="ghost"
                    size="xl"
                    labelText="Prev"
                    onClick={this.handlePrevClick}
                  />
                </Grid>
                <Grid item lg={4} md={4} className="pl-8">
                  <ButtonMain
                    type="negative"
                    size="xl"
                    labelText="Cancel"
                    onClick={onButtonClickCancel}
                  />
                </Grid>
                <Grid item lg={4} md={4} className="pl-8">
                  <ButtonMain
                    type="primary"
                    size="xl"
                    labelText="Next"
                    onClick={this.handleNextClick}
                    disabled={isDisabledNextBtn}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getUserByPhone: params => getUserDetailByPhone(dispatch, params),
  getShippingAddress: params => getOrderShippingAddress(dispatch, params),
  getShippingAddressInfo: params => getOrderShippingAddressInfo(dispatch, params),
  getShippingCost: params => getOrderShippingCost(dispatch, params),
  setCurrentAddressTypeOption: params => dispatch(Actions.setCurrentAddressTypeOption(params)),
  setCurrentAddressTypeOptionRaw: params =>
    dispatch(Actions.setCurrentAddressTypeOptionRaw(params)),
  setCurrentDeliveryService: params => dispatch(Actions.setCurrentDeliveryService(params)),
  patientByMedicalRecordNumber: medicalRecordNumber =>
    searchPatientByMedicalRecordNumber(dispatch, medicalRecordNumber),
  processGetGuestUser: () => getGuestUser(dispatch),
});

const mapStateToProps = ({ orderData }) => ({ orderData });

StepUserDetails.propTypes = {
  checkUserPhoneOrEmail: PropTypes.func,
  getShippingAddress: PropTypes.func,
  getShippingAddressInfo: PropTypes.func,
  getShippingCost: PropTypes.func,
  getUserByPhone: PropTypes.func,
  onButtonClickCancel: PropTypes.func,
  onNextClick: PropTypes.func,
  onParameterUpdate: PropTypes.func,
  onPopUpInformation: PropTypes.func,
  onPrevClick: PropTypes.func,
  onSetAnonymous: PropTypes.func,
  orderData: PropTypes.object,
  parentFilter: PropTypes.object,
  parentForm: PropTypes.object,
  parentValidation: PropTypes.object,
  patientByMedicalRecordNumber: PropTypes.func,
  processGetGuestUser: PropTypes.func,
  setCurrentAddressTypeOption: PropTypes.func,
  setCurrentAddressTypeOptionRaw: PropTypes.func,
  setCurrentDeliveryService: PropTypes.func,
  storeDetailsSelected: PropTypes.object,
  useAnonymous: PropTypes.bool,
};

const shell = compose(connect(mapStateToProps, mapDispatchToProps));

const core = compose(UserCheckField, PrevNextStepperCount);

export default shell(core(StepUserDetails));
