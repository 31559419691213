/* eslint-disable react/no-access-state-in-setstate */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid, Breadcrumbs, Link, FormControl, FormLabel } from '@material-ui/core';
import { Steps } from 'antd';
import _ from 'lodash';
import { compose } from 'redux';
// style
import './BranchAddStyle.scss';
// component
import {
  InputMain,
  SnackBarSimple,
  SelectInputCompany,
  ButtonMain,
  RadioInput,
  CheckInputOutlineBackground,
  SelectInputSearchMain,
  AuthenticationAccessPages,
  PrevStateValue,
} from '../../../../../components/Index';
// api
import { setCreateBranch } from '../../../../../services/api/MasterDataMainAPi';
import {
  getProvinceList,
  getCityList,
  getDistrictList,
  getVillageList,
  getMerchantListV2,
  getBankDetails,
} from '../../../../../services/api/MasterDataApi';
// helper
import {
  MasterDataHelper,
  CommonHelper,
  ValidationHelper,
  PermissionModule,
  PermissionPage,
  PermissionAccess,
} from '../../../../../helpers/Index';

const { Step } = Steps;
const optionToash = {
  vertical: 'top',
  horizontal: 'right',
};

const resetValidation = { isError: '', errorMessage: '' };

const radioButtonOption = MasterDataHelper.statusGlobal;

const initialValidation = {
  company: { isError: '', errorMessage: '' },
  merchant: { isError: '', errorMessage: '' },
  branchName: { isError: '', errorMessage: '' },
  identification: { isError: '', errorMessage: '' },
  email: { isError: '', errorMessage: '' },
  whatsapp: { isError: '', errorMessage: '' },
  phone: { isError: '', errorMessage: '' },
  status: { isError: '', errorMessage: '' },

  bankName: { isError: '', errorMessage: '' },
  bankAccountName: { isError: '', errorMessage: '' },
  bankAccountNumber: { isError: '', errorMessage: '' },

  address: { isError: '', errorMessage: '' },
  area: { isError: '', errorMessage: '' },
  coordinate: { isError: '', errorMessage: '' },

  province: { isError: '', errorMessage: '' },
  city: { isError: '', errorMessage: '' },
  district: { isError: '', errorMessage: '' },
  village: { isError: '', errorMessage: '' },
};

const initialForm = {
  companyId: undefined,
  merchantId: undefined,
  branchName: '',
  identification: '',
  email: '',
  whatsapp: '',
  phone: '',
  status: radioButtonOption[0],
  isUseBankMerchant: false,
  bankName: '',
  bankAccountName: '',
  bankAccountNumber: '',
  address: '',
  area: '',
  coordinate: '',
  province: undefined,
  city: undefined,
  district: undefined,
  village: undefined,
};

class BranchAdd extends React.Component {
  constructor(props) {
    super(props);

    props.checkUserAccessPermission(
      PermissionModule.MasterData,
      PermissionPage.Branch,
      PermissionAccess.Add,
    );

    this.state = {
      validation: initialValidation,
      form: initialForm,
      current: 0,
      stepList: [
        { key: 'company_information', name: 'Company Information' },
        { key: 'bank_account', name: 'Bank Account' },
        { key: 'address', name: 'Address' },
      ],
      isLoading: false,
      toastInformation: {
        isOpen: false,
        message: '',
        snackbarType: 'warning',
      },
    };
  }

  componentDidMount() {
    const { getProvince } = this.props;
    getProvince({ country_id: 1 });
  }

  componentWillUnmount() {}

  handleCloseToash = () => {
    const { toastInformation } = this.state;
    this.setState({ toastInformation: { ...toastInformation, isOpen: false } });
  };

  handleClick = (event, value) => {
    const { history } = this.props;
    event.preventDefault();
    history.push(value);
  };

  handleSelectCompany = value => {
    const { form, validation } = this.state;
    const { getMerchants } = this.props;

    const message = {
      isError: '',
      errorMessage: '',
    };

    const params = { company_id: value };

    getMerchants(params);

    if (!value || value === '') {
      message.isError = 'error';
      message.errorMessage = 'Please choose Company';
      this.setState({
        form: { ...form, companyId: '' },
        validation: { ...validation, company: message },
      });
    } else {
      this.setState({
        form: { ...form, companyId: value, merchantId: undefined },
        validation: { ...validation, company: message },
      });
    }
  };

  handleSelectMerchant = value => {
    const { form, validation } = this.state;
    const { getBank } = this.props;

    const message = {
      isError: '',
      errorMessage: '',
    };

    getBank({ merchant_id: value });

    if (!value || value === '') {
      message.isError = 'error';
      message.errorMessage = 'Please choose Merchant';
      this.setState({
        form: { ...form, merchantId: '' },
        validation: { ...validation, merchant: message },
      });
    } else {
      this.setState({
        form: { ...form, merchantId: value },
        validation: { ...validation, merchant: message },
      });
    }
  };

  handleTextChangeBranchName = value => {
    const { form, validation } = this.state;
    const message = {
      isError: '',
      errorMessage: '',
    };

    if (!value || value === '') {
      message.isError = 'error';
      message.errorMessage = 'Branch Name Empty';
      this.setState({
        form: { ...form, branchName: '' },
        validation: { ...validation, branchName: message },
      });
    } else {
      this.setState({
        form: { ...form, branchName: value },
        validation: { ...validation, branchName: message },
      });
    }
  };

  handleTextChangeIdentification = value => {
    const { form, validation } = this.state;
    const message = {
      isError: '',
      errorMessage: '',
    };

    if (!value || value === '') {
      message.isError = 'error';
      message.errorMessage = 'Identification Name Empty';
      this.setState({
        form: { ...form, identification: '' },
        validation: { ...validation, identification: message },
      });
    } else {
      this.setState({
        form: { ...form, identification: value },
        validation: { ...validation, identification: message },
      });
    }
  };

  handleTextChangeEmail = value => {
    const { form, validation } = this.state;
    const message = {
      isError: '',
      errorMessage: '',
    };

    if (!value || value === '') {
      message.isError = 'error';
      message.errorMessage = 'Email Name Empty';
      this.setState({
        form: { ...form, email: '' },
        validation: { ...validation, email: message },
      });
    } else {
      this.setState({
        form: { ...form, email: value },
        validation: { ...validation, email: message },
      });
    }
  };

  handleTextChangeWhatsapp = value => {
    const whatsappValidation = ValidationHelper.validatePhone(value.trim());
    const { form, validation } = this.state;
    const message = {
      isError: '',
      errorMessage: '',
    };

    this.setState(
      {
        form: { ...form, whatsapp: value.trim() },
      },
      () => {
        if (!whatsappValidation) {
          message.isError = 'error';
          message.errorMessage = 'please check your whatsapp, and try again';
        }
        this.setState({
          validation: { ...validation, whatsapp: message },
        });
      },
    );
  };

  handleTextChangePhone = value => {
    const phoneValidation = ValidationHelper.validatePhone(value.trim());
    const { form, validation } = this.state;
    const message = {
      isError: '',
      errorMessage: '',
    };

    this.setState(
      {
        form: { ...form, phone: value.trim() },
      },
      () => {
        if (!phoneValidation) {
          message.isError = 'error';
          message.errorMessage = 'please check your Phone, and try again';
        }
        this.setState({
          validation: { ...validation, phone: message },
        });
      },
    );
  };

  handleSelectStatus = value => {
    const { form } = this.state;
    this.setState({ form: { ...form, status: value } });
  };

  handleIsUseBankMerchant = value => {
    const { form, validation } = this.state;
    const message = resetValidation;
    const {
      masterData: { bankDetails },
    } = this.props;

    this.setState({
      form: {
        ...form,
        isUseBankMerchant: value,
        bankName: value ? bankDetails.bank_name || '' : '',
        bankAccountName: value ? bankDetails.bank_account_name || '' : '',
        bankAccountNumber: value ? bankDetails.account_number || '' : '',
      },
      validation: {
        ...validation,
        bankName: message,
        bankAccountName: message,
        bankAccountNumber: message,
      },
    });
  };

  handleTextChangeBankName = value => {
    const { validation, form } = this.state;

    const message = {
      isError: '',
      errorMessage: '',
    };

    this.setState(
      {
        form: { ...form, bankName: value },
      },
      () => {
        if (!value) {
          message.isError = 'error';
          message.errorMessage = 'Bank Name is Empty...';
        }
        this.setState({
          validation: { ...validation, bankName: message },
        });
      },
    );
  };

  handleTextChangeBankAccountName = value => {
    const { validation, form } = this.state;

    const message = {
      isError: '',
      errorMessage: '',
    };

    this.setState(
      {
        form: { ...form, bankAccountName: value },
      },
      () => {
        if (!value) {
          message.isError = 'error';
          message.errorMessage = 'Bank Account Name is Empty...';
        }
        this.setState({
          validation: { ...validation, bankAccountName: message },
        });
      },
    );
  };

  handleTextChangeBankAccountNumber = value => {
    const { validation, form } = this.state;
    const checkIsNumber = CommonHelper.checkNumberOnly(value.trim());

    const message = {
      isError: '',
      errorMessage: '',
    };

    if (checkIsNumber) {
      this.setState({
        form: { ...form, bankAccountNumber: value.trim() },
        validation: { ...validation, bankAccountNumber: message },
      });
    } else {
      message.isError = 'error';
      message.errorMessage = 'Bank Account Number must number';

      if (!value) {
        message.isError = 'error';
        message.errorMessage = 'Bank Account Number must number and not empty...';
      }
      this.setState({
        validation: { ...validation, bankAccountNumber: message },
      });
    }
  };

  handleTextChangeAddress = value => {
    const { form, validation } = this.state;
    const message = {
      isError: '',
      errorMessage: '',
    };

    if (!value || value === '') {
      message.isError = 'error';
      message.errorMessage = 'Address Name Empty';
      this.setState({
        form: { ...form, address: '' },
        validation: { ...validation, address: message },
      });
    } else {
      this.setState({
        form: { ...form, address: value },
        validation: { ...validation, address: message },
      });
    }
  };

  handleTextChangeArea = value => {
    const { form, validation } = this.state;
    const message = {
      isError: '',
      errorMessage: '',
    };

    if (!value || value === '') {
      message.isError = 'error';
      message.errorMessage = 'Area Name Empty';
      this.setState({
        form: { ...form, area: '' },
        validation: { ...validation, area: message },
      });
    } else {
      this.setState({
        form: { ...form, area: value },
        validation: { ...validation, area: message },
      });
    }
  };

  handleTextChangeCoordinate = value => {
    const coordinateValidation = ValidationHelper.validateCoordinates(value.trim());
    const { form, validation } = this.state;
    const message = {
      isError: '',
      errorMessage: '',
    };

    if (!value || value === '') {
      message.isError = 'error';
      message.errorMessage = 'Coordinate Empty';
      this.setState({
        form: { ...form, coordinate: '' },
        validation: { ...validation, coordinate: message },
      });
    } else {
      this.setState(
        {
          form: { ...form, coordinate: value.trim() },
        },
        () => {
          if (!coordinateValidation) {
            message.isError = 'error';
            message.errorMessage = 'please check your coordinate, and try again';
          }
          this.setState({
            validation: { ...validation, coordinate: message },
          });
        },
      );
    }
  };

  handleChangeProvince = value => {
    const { form, validation } = this.state;
    const { getCity } = this.props;
    const params = { province_id: value };

    getCity(params);

    const message = {
      isError: '',
      errorMessage: '',
    };

    if (!value || value === undefined) {
      message.isError = 'error';
      message.errorMessage = 'Please choose Province';
      this.setState({
        form: { ...form, province: '' },
        validation: { ...validation, province: message },
      });
    } else {
      this.setState({
        form: {
          ...form,
          province: value,
          city: undefined,
          district: undefined,
          village: undefined,
        },
        validation: { ...validation, province: message },
      });
    }
  };

  handleChangeCity = value => {
    const { form, validation } = this.state;
    const { getDistrict } = this.props;
    const params = { city_id: value };

    getDistrict(params);

    const message = {
      isError: '',
      errorMessage: '',
    };

    if (!value || value === undefined) {
      message.isError = 'error';
      message.errorMessage = 'Please choose City';
      this.setState({
        form: { ...form, city: '' },
        validation: { ...validation, city: message },
      });
    } else {
      this.setState({
        form: { ...form, city: value, district: undefined, village: undefined },
        validation: { ...validation, city: message },
      });
    }
  };

  handleChangeDistrict = value => {
    const { form, validation } = this.state;
    const { getVillage } = this.props;
    const params = { district_id: value };

    getVillage(params);

    const message = {
      isError: '',
      errorMessage: '',
    };

    if (!value || value === undefined) {
      message.isError = 'error';
      message.errorMessage = 'Please choose District';
      this.setState({
        form: { ...form, district: '' },
        validation: { ...validation, district: message },
      });
    } else {
      this.setState({
        form: { ...form, district: value, village: undefined },
        validation: { ...validation, district: message },
      });
    }
  };

  handleChangeVillage = value => {
    const { form, validation } = this.state;

    const message = {
      isError: '',
      errorMessage: '',
    };

    if (!value || value === undefined) {
      message.isError = 'error';
      message.errorMessage = 'Please choose Village';
      this.setState({
        form: { ...form, village: '' },
        validation: { ...validation, village: message },
      });
    } else {
      this.setState({
        form: { ...form, village: value },
        validation: { ...validation, village: message },
      });
    }
  };

  handleButtonCancel = () => {
    const { history } = this.props;
    history.push('/master-data/main-data');
  };

  handleButtonSubmit = () => {
    const { createBranch, history } = this.props;
    const { form } = this.state;

    if (this.validationAddress()) {
      const param = {
        data: {
          merchant_id: form.merchantId,
          name: form.branchName,
          identification: form.identification,
          email: form.email,
          whatsapp: form.whatsapp,
          phone: form.phone,
          status: form.status.value,
          is_merchant_bank: form.isUseBankMerchant,
          bank_name: form.bankName,
          bank_account_name: form.bankAccountName,
          account_number: form.bankAccountNumber,
          address: form.address,
          area: form.area,
          coordinate: form.coordinate,
          province_id: form.province,
          city_id: form.city,
          district_id: form.district,
          village_id: form.village,
        },
      };

      this.setState({ isLoading: true }, () => {
        createBranch(param)
          .then(response => {
            const message = response.messages;

            this.processMessage(message, 'success');
            this.setState({ isLoading: false }, () => {
              setTimeout(async () => {
                await history.push('/master-data/main-data');
              }, 2000);
            });
          })
          .catch(error => {
            const message = error.data;
            this.setState({ isLoading: false });
            this.processMessage(message.messages, 'error');
          });
      });
    }
  };

  processMessage(messages, type) {
    const convertedMessage = CommonHelper.generateMessage(messages);

    const paramInformation = {
      isOpen: true,
      message: convertedMessage,
      snackbarType: type,
    };

    this.setState({
      toastInformation: paramInformation,
    });
  }

  validationEmptyField() {
    const {
      form: { merchantName, bankName, bankAccountName, bankAccountNumber },
      validation,
    } = this.state;

    let passCheck = true;
    let paramValidation01 = resetValidation;
    let paramValidation02 = resetValidation;
    let paramValidation03 = resetValidation;
    let paramValidation04 = resetValidation;

    if (merchantName === '') {
      if (!paramValidation01.isError) {
        paramValidation01 = {
          isError: true,
          errorMessage: 'merchantName Empty...',
        };
      }
      passCheck = false;
    }

    if (bankName === '') {
      if (!paramValidation02.isError) {
        paramValidation02 = {
          isError: true,
          errorMessage: 'Bank Name Empty...',
        };
      }
      passCheck = false;
    }

    if (bankAccountName === '') {
      paramValidation03 = {
        isError: true,
        errorMessage: 'Bank Account Name Empty',
      };
      passCheck = false;
    }

    if (bankAccountNumber === '') {
      paramValidation04 = {
        isError: true,
        errorMessage: 'Bank Account Number Empty',
      };
      passCheck = false;
    }

    if (!passCheck) {
      this.setState({
        validation: {
          ...validation,
          merchantName: paramValidation01,
          bankName: paramValidation02,
          bankAccountName: paramValidation03,
          bankAccountNumber: paramValidation04,
        },
      });
    }

    return passCheck;
  }

  validationStepCompanyInformation() {
    const { form, validation } = this.state;

    let passCheck = true;
    let paramValidation01 = resetValidation;
    let paramValidation02 = resetValidation;
    let paramValidation03 = resetValidation;
    let paramValidation04 = resetValidation;
    let paramValidation05 = resetValidation;
    let paramValidation06 = resetValidation;
    let paramValidation07 = resetValidation;

    if (form.companyId === undefined) {
      paramValidation01 = {
        isError: 'error',
        errorMessage: 'Please Select Company ',
      };

      passCheck = false;
    }

    if (form.merchantId === undefined) {
      paramValidation02 = {
        isError: 'error',
        errorMessage: 'Please Select Merchant ',
      };

      passCheck = false;
    }

    if (_.isEmpty(form.branchName)) {
      paramValidation03 = {
        isError: 'error',
        errorMessage: 'Please enter your Branch Name',
      };

      passCheck = false;
    }

    if (_.isEmpty(form.identification)) {
      paramValidation04 = {
        isError: 'error',
        errorMessage: 'Please enter your Identification',
      };

      passCheck = false;
    }

    if (_.isEmpty(form.email)) {
      paramValidation05 = {
        isError: 'error',
        errorMessage: 'Please enter your Email',
      };

      passCheck = false;
    }

    if (_.isEmpty(form.whatsapp)) {
      paramValidation06 = {
        isError: 'error',
        errorMessage: 'Please enter your Email',
      };

      passCheck = false;
    }

    if (_.isEmpty(form.phone)) {
      paramValidation07 = {
        isError: 'error',
        errorMessage: 'Please enter your Phone',
      };

      passCheck = false;
    }

    if (!passCheck) {
      this.setState({
        validation: {
          ...validation,
          company: paramValidation01,
          merchant: paramValidation02,
          branchName: paramValidation03,
          identification: paramValidation04,
          email: paramValidation05,
          whatsapp: paramValidation06,
          phone: paramValidation07,
        },
      });
    }

    return passCheck;
  }

  validationBankAccount() {
    const { form, validation } = this.state;

    let passCheck = true;
    let paramValidation01 = resetValidation;
    let paramValidation02 = resetValidation;
    let paramValidation03 = resetValidation;

    if (_.isEmpty(form.bankName)) {
      paramValidation01 = {
        isError: 'error',
        errorMessage: 'Please enter your Bank Name',
      };

      passCheck = false;
    }

    if (_.isEmpty(form.bankAccountName)) {
      paramValidation02 = {
        isError: 'error',
        errorMessage: 'Please enter your Bank Account Name',
      };

      passCheck = false;
    }

    if (_.isEmpty(form.bankAccountNumber)) {
      paramValidation03 = {
        isError: 'error',
        errorMessage: 'Please enter your Bank Account Number',
      };

      passCheck = false;
    }

    if (!passCheck) {
      this.setState({
        validation: {
          ...validation,
          bankName: paramValidation01,
          bankAccountName: paramValidation02,
          bankAccountNumber: paramValidation03,
        },
      });
    }

    return passCheck;
  }

  validationAddress() {
    const { form, validation } = this.state;

    let passCheck = true;
    let paramValidation01 = resetValidation;
    let paramValidation02 = resetValidation;
    let paramValidation03 = resetValidation;
    let paramValidation04 = resetValidation;
    let paramValidation05 = resetValidation;
    let paramValidation06 = resetValidation;
    let paramValidation07 = resetValidation;

    if (_.isEmpty(form.address)) {
      paramValidation01 = {
        isError: 'error',
        errorMessage: 'Please enter your Address',
      };

      passCheck = false;
    }

    if (_.isEmpty(form.area)) {
      paramValidation02 = {
        isError: 'error',
        errorMessage: 'Please enter your Area',
      };

      passCheck = false;
    }

    if (_.isEmpty(form.coordinate)) {
      paramValidation03 = {
        isError: 'error',
        errorMessage: 'Please enter your Coordinate',
      };

      passCheck = false;
    }

    if (form.province === undefined) {
      paramValidation04 = {
        isError: 'error',
        errorMessage: 'Please Select Province',
      };

      passCheck = false;
    }

    if (form.city === undefined) {
      paramValidation05 = {
        isError: 'error',
        errorMessage: 'Please Select City',
      };

      passCheck = false;
    }
    if (form.district === undefined) {
      paramValidation06 = {
        isError: 'error',
        errorMessage: 'Please Select District',
      };

      passCheck = false;
    }
    if (form.village === undefined) {
      paramValidation07 = {
        isError: 'error',
        errorMessage: 'Please Select Vilage',
      };

      passCheck = false;
    }

    if (!passCheck) {
      this.setState({
        // isLoading: true,
        validation: {
          ...validation,
          address: paramValidation01,
          area: paramValidation02,
          coordinate: paramValidation03,
          province: paramValidation04,
          city: paramValidation05,
          district: paramValidation06,
          village: paramValidation07,
        },
      });
    }

    return passCheck;
  }

  handleNext() {
    const {
      current,
      form: { isUseBankMerchant },
    } = this.state;
    let isNext = false;

    switch (current) {
      case 0:
        isNext = this.validationStepCompanyInformation();
        break;
      case 1:
        if (isUseBankMerchant) {
          isNext = this.validationBankAccount();
        } else {
          isNext = true;
        }
        break;
      default:
        break;
    }

    if (isNext) {
      const newCurrent = this.state.current + 1;
      this.setState({ current: newCurrent });
    }
  }

  handlePrev() {
    const current = this.state.current - 1;
    this.setState({ current });
  }

  renderStep = step => {
    const {
      masterData: { provinceList, cityList, districtList, villageList, merchantList },
    } = this.props;

    const provinceListCustom = CommonHelper.renameKeyName(provinceList, 'province_id', 'value');
    const cityListCustom = CommonHelper.renameKeyName(cityList, 'city_id', 'value');
    const districtListCustom = CommonHelper.renameKeyName(districtList, 'district_id', 'value');
    const villageListCustom = CommonHelper.renameKeyName(villageList, 'village_id', 'value');
    const merchantListCustom = CommonHelper.renameKeyName(merchantList, 'merchant_id', 'value');

    switch (step.key) {
      case 'company_information':
        return (
          <Grid container spacing={3}>
            <Grid item lg={6} md={6}>
              <Grid container direction="column">
                <Grid item>
                  <FormControl component="fieldset" fullWidth margin={'normal'}>
                    <FormLabel component="label" className="form-label">
                      Company Name
                    </FormLabel>
                    <div className="container-remove-margin">
                      <SelectInputCompany
                        placeHolder="Select Company"
                        currentValue={this.state.form.companyId}
                        onChange={this.handleSelectCompany}
                        errorMessage={this.state.validation.company.errorMessage}
                        validateStatus={this.state.validation.company.isError}
                      />
                    </div>
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl component="fieldset" fullWidth margin={'normal'}>
                    <FormLabel component="label" className="form-label">
                      Merchant
                    </FormLabel>
                    <div className="container-remove-margin">
                      <SelectInputSearchMain
                        options={merchantListCustom}
                        onChange={this.handleSelectMerchant}
                        currentValue={this.state.form.merchantId}
                        size={'large'}
                        placeholder={'Select Merchant'}
                        errorMessage={this.state.validation.merchant.errorMessage}
                        validateStatus={this.state.validation.merchant.isError}
                      />
                    </div>
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl component="fieldset" fullWidth margin={'normal'}>
                    <FormLabel component="label" className="form-label">
                      Name
                    </FormLabel>
                    <InputMain
                      placeholder="Enter Name"
                      onChange={this.handleTextChangeBranchName}
                      value={this.state.form.branchName}
                      errorMessage={this.state.validation.branchName.errorMessage}
                      validateStatus={this.state.validation.branchName.isError}
                    />
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl component="fieldset" fullWidth margin={'normal'}>
                    <FormLabel component="label" className="form-label">
                      Identification
                    </FormLabel>
                    <InputMain
                      placeholder="Enter Identification"
                      onChange={this.handleTextChangeIdentification}
                      value={this.state.form.identification}
                      errorMessage={this.state.validation.identification.errorMessage}
                      validateStatus={this.state.validation.identification.isError}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={6} md={6}>
              <Grid container direction="column">
                <Grid item>
                  <FormControl component="fieldset" fullWidth margin={'normal'}>
                    <FormLabel component="label" className="form-label">
                      Email
                    </FormLabel>
                    <InputMain
                      placeholder="Enter Email"
                      onChange={this.handleTextChangeEmail}
                      value={this.state.form.email}
                      errorMessage={this.state.validation.email.errorMessage}
                      validateStatus={this.state.validation.email.isError}
                    />
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl component="fieldset" fullWidth margin={'normal'}>
                    <FormLabel component="label" className="form-label">
                      Whatsapp
                    </FormLabel>
                    <InputMain
                      placeholder="Enter Whatsapp"
                      onChange={this.handleTextChangeWhatsapp}
                      value={this.state.form.whatsapp}
                      errorMessage={this.state.validation.whatsapp.errorMessage}
                      validateStatus={this.state.validation.whatsapp.isError}
                    />
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl component="fieldset" fullWidth margin={'normal'}>
                    <FormLabel component="label" className="form-label">
                      Phone
                    </FormLabel>
                    <InputMain
                      placeholder="Enter Phone"
                      onChange={this.handleTextChangePhone}
                      value={this.state.form.phone}
                      errorMessage={this.state.validation.phone.errorMessage}
                      validateStatus={this.state.validation.phone.isError}
                    />
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl component="fieldset" fullWidth margin={'normal'}>
                    <FormLabel component="label" className="form-label">
                      Status
                    </FormLabel>
                    <Grid container direction="column">
                      <Grid item>
                        <div className="container-remove-margin">
                          <RadioInput
                            data={radioButtonOption}
                            onSelect={this.handleSelectStatus}
                            direction="column"
                            defaultValue={this.state.form.status}
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        );

      case 'bank_account':
        return (
          <Grid container spacing={3}>
            <Grid item lg={4} md={4}>
              <Grid container direction="column">
                <Grid item>
                  <FormControl component="fieldset" fullWidth margin={'normal'}>
                    <div className="container-remove-margin">
                      <CheckInputOutlineBackground
                        labelText="Using Merchant Bank"
                        onCheck={this.handleIsUseBankMerchant}
                        currentValue={this.state.form.isUseBankMerchant}
                      />
                    </div>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              lg={12}
              md={12}
              className={this.state.form.isUseBankMerchant ? 'disabled' : ''}
            >
              <Grid container direction="row" spacing={3}>
                <Grid item lg={6} md={6}>
                  <FormControl component="fieldset" fullWidth margin={'normal'}>
                    <FormLabel component="label" className="form-label">
                      Bank Name
                    </FormLabel>
                    <InputMain
                      placeholder="Enter Bank Name"
                      onChange={this.handleTextChangeBankName}
                      value={this.state.form.bankName}
                      errorMessage={this.state.validation.bankName.errorMessage}
                      validateStatus={this.state.validation.bankName.isError}
                    />
                  </FormControl>
                </Grid>
                <Grid item lg={6} md={6}>
                  <FormControl component="fieldset" fullWidth margin={'normal'}>
                    <FormLabel component="label" className="form-label">
                      Bank Account Name
                    </FormLabel>
                    <InputMain
                      placeholder="Enter Account Name"
                      onChange={this.handleTextChangeBankAccountName}
                      value={this.state.form.bankAccountName}
                      errorMessage={this.state.validation.bankAccountName.errorMessage}
                      validateStatus={this.state.validation.bankAccountName.isError}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={3}>
                <Grid item lg={6} md={6}>
                  <FormControl component="fieldset" fullWidth margin={'normal'}>
                    <FormLabel component="label" className="form-label">
                      Bank Account Number
                    </FormLabel>
                    <InputMain
                      placeholder="Enter Account Number"
                      onChange={this.handleTextChangeBankAccountNumber}
                      value={this.state.form.bankAccountNumber}
                      errorMessage={this.state.validation.bankAccountNumber.errorMessage}
                      validateStatus={this.state.validation.bankAccountNumber.isError}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        );

      case 'address':
        return (
          <Grid container spacing={3}>
            <Grid item lg={6} md={6}>
              <Grid container direction="column">
                <Grid item>
                  <FormControl component="fieldset" fullWidth margin={'normal'}>
                    <FormLabel component="label" className="form-label">
                      Address
                    </FormLabel>
                    <InputMain
                      placeholder="Enter Address"
                      onChange={this.handleTextChangeAddress}
                      value={this.state.form.address}
                      errorMessage={this.state.validation.address.errorMessage}
                      validateStatus={this.state.validation.address.isError}
                    />
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl component="fieldset" fullWidth margin={'normal'}>
                    <FormLabel component="label" className="form-label">
                      Area
                    </FormLabel>
                    <InputMain
                      placeholder="Enter Area"
                      onChange={this.handleTextChangeArea}
                      value={this.state.form.area}
                      errorMessage={this.state.validation.area.errorMessage}
                      validateStatus={this.state.validation.area.isError}
                    />
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl component="fieldset" fullWidth margin={'normal'}>
                    <FormLabel component="label" className="form-label">
                      Coordinate
                    </FormLabel>
                    <InputMain
                      placeholder="Ex : -6.2123751,106.8205536"
                      onChange={this.handleTextChangeCoordinate}
                      value={this.state.form.coordinate}
                      errorMessage={this.state.validation.coordinate.errorMessage}
                      validateStatus={this.state.validation.coordinate.isError}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={6} md={6}>
              <Grid container direction="column">
                <Grid item>
                  <FormControl component="fieldset" fullWidth margin={'normal'}>
                    <FormLabel component="label" className="form-label">
                      Province
                    </FormLabel>
                    <SelectInputSearchMain
                      options={provinceListCustom}
                      onChange={this.handleChangeProvince}
                      currentValue={this.state.form.province}
                      size={'large'}
                      placeholder={'Select Province'}
                      errorMessage={this.state.validation.province.errorMessage}
                      validateStatus={this.state.validation.province.isError}
                    />
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl component="fieldset" fullWidth margin={'normal'}>
                    <FormLabel component="label" className="form-label">
                      City
                    </FormLabel>
                    <SelectInputSearchMain
                      options={cityListCustom}
                      onChange={this.handleChangeCity}
                      currentValue={this.state.form.city}
                      size={'large'}
                      placeholder={'Select City'}
                      errorMessage={this.state.validation.city.errorMessage}
                      validateStatus={this.state.validation.city.isError}
                    />
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl component="fieldset" fullWidth margin={'normal'}>
                    <FormLabel component="label" className="form-label">
                      District
                    </FormLabel>
                    <SelectInputSearchMain
                      options={districtListCustom}
                      onChange={this.handleChangeDistrict}
                      currentValue={this.state.form.district}
                      size={'large'}
                      placeholder={'Select District'}
                      errorMessage={this.state.validation.district.errorMessage}
                      validateStatus={this.state.validation.district.isError}
                    />
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl component="fieldset" fullWidth margin={'normal'}>
                    <FormLabel component="label" className="form-label">
                      Village
                    </FormLabel>
                    <SelectInputSearchMain
                      options={villageListCustom}
                      onChange={this.handleChangeVillage}
                      currentValue={this.state.form.village}
                      size={'large'}
                      placeholder={'Select Village'}
                      errorMessage={this.state.validation.village.errorMessage}
                      validateStatus={this.state.validation.village.isError}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        );

      default:
        return null;
    }
  };

  render() {
    const { toastInformation, current, stepList } = this.state;

    return (
      <div className="container-page-branch-add  scroll-container-invisible">
        <div className="container-scrolling-area">
          <Grid container direction="column">
            <Grid item lg md className="section-page-header">
              <Grid container>
                <Grid item>
                  <div className="breadcrumbs-section">
                    <Breadcrumbs aria-label="breadcrumb">
                      <Link
                        className="text-12"
                        color="inherit"
                        href="/master-data/main-data"
                        onClick={event => {
                          this.handleClick(event, '/master-data/main-data');
                        }}
                      >
                        <i className="icon-slot ic-ffo-master-data" /> Main Data
                      </Link>
                      <label className="text-12" color="inherit">
                        Create Branch
                      </label>
                    </Breadcrumbs>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg md className="section-page-body">
              <div className="merchant-details-card">
                <Grid container spacing={3}>
                  <label className="text-16 text-bold text-rolling-stone">Create Branch</label>
                </Grid>
                <Grid className="pt-24" container spacing={3}>
                  <Grid item lg={8} md={8}>
                    <Steps current={current} size="small">
                      {stepList.map(item => (
                        <Step key={item.key} title={item.name} />
                      ))}
                    </Steps>
                  </Grid>
                  {this.renderStep(stepList[current])}
                </Grid>
              </div>
            </Grid>
            <Grid item lg md className="section-page-footer">
              <Grid container justify="flex-end" spacing={3}>
                <Grid item lg={2} md={2}>
                  <ButtonMain
                    type="ghost"
                    size="xl"
                    labelText="Cancel"
                    onClick={this.handleButtonCancel}
                  />
                </Grid>
                <Grid item lg={2} md={2}>
                  <ButtonMain
                    type="secondary"
                    size="xl"
                    labelText="Back"
                    disabled={!(current > 0)}
                    onClick={() => this.handlePrev()}
                  />
                </Grid>
                <Grid item lg={2} md={2}>
                  {current < stepList.length - 1 && (
                    <ButtonMain
                      type="primary"
                      size="xl"
                      labelText="Next"
                      onClick={() => this.handleNext()}
                    />
                  )}
                  {current === stepList.length - 1 && (
                    <ButtonMain
                      type="primary"
                      size="xl"
                      labelText="Create"
                      onClick={this.handleButtonSubmit}
                      isLoading={this.state.isLoading}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <SnackBarSimple
            open={toastInformation.isOpen}
            durationHide={2000}
            message={toastInformation.message}
            onClickClose={this.handleCloseToash}
            snackbarType={toastInformation.snackbarType}
            anchor={optionToash}
          />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  createBranch: params => setCreateBranch(dispatch, params),
  getBank: params => getBankDetails(dispatch, params),
  getProvince: params => getProvinceList(dispatch, params),
  getCity: params => getCityList(dispatch, params),
  getDistrict: params => getDistrictList(dispatch, params),
  getVillage: params => getVillageList(dispatch, params),
  getMerchants: params => getMerchantListV2(dispatch, params),
});

const mapStateToProps = ({ masterData }) => ({ masterData });

BranchAdd.propTypes = {
  checkUserAccessPermission: PropTypes.func,
  createBranch: PropTypes.func,
  getBank: PropTypes.func,
  getCity: PropTypes.func,
  getDistrict: PropTypes.func,
  getMerchants: PropTypes.func,
  getProvince: PropTypes.func,
  getVillage: PropTypes.func,
  history: PropTypes.object,
  masterData: PropTypes.object,
};

const shell = compose(connect(mapStateToProps, mapDispatchToProps));
const core = compose(AuthenticationAccessPages, PrevStateValue);

export default shell(core(BranchAdd));
