import React from 'react';
import PropTypes from 'prop-types';
// Style
import './LabelAlertStyle.scss';

const LabelAlert = props => {
  const { iconPrefix, type, message, size } = props;

  let currentText = 'text-12';
  let currentIcon = 'size-16';

  switch (size) {
    case 'large':
      currentText = 'text-16';
      currentIcon = 'size-24';
      break;
    case 'middle':
      currentText = 'text-14';
      currentIcon = 'size-20';
      break;
    default:
      currentText = 'text-12';
      currentIcon = 'size-16';
      break;
  }

  return (
    <div className={`container-promo-notification ${type} flex-row`}>
      {iconPrefix ? <i className={`${iconPrefix} container-icon-prefix ${currentIcon}`} /> : null}
      <label className={`${currentText} wrapping-container second line `}>{message}</label>
    </div>
  );
};

LabelAlert.propTypes = {
  iconPrefix: PropTypes.string,
  message: PropTypes.string,
  size: PropTypes.string,
  type: PropTypes.string,
};

LabelAlert.defaultProps = {
  size: 'middle',
};

export default LabelAlert;
