import React from 'react';
import { Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

const SkeletonDetailsV02 = () => {
  return (
    <Grid container spacing={2} direction="column" className="flex-wrap-unset pt-24">
      <Grid item lg md>
        <Grid container spacing={2} justify="flex-start">
          <Grid item xl={1} lg={2} md={2}>
            <Skeleton
              variant="rect"
              width="100%"
              height={20}
              animation="wave"
              className="skeletonRounded"
            />
          </Grid>
          <Grid item>/</Grid>
          <Grid item xl={2} lg={3} md={3}>
            <Skeleton
              variant="rect"
              width="100%"
              height={20}
              animation="wave"
              className="skeletonRounded"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg md>
        <div className="container-main-card p-16">
          <Grid container spacing={3}>
            <Grid item lg={3} md={3}>
              <Skeleton
                variant="rect"
                width="100%"
                height={215}
                animation="wave"
                className="skeletonRounded"
              />
            </Grid>
            <Grid item lg={7} md={7}>
              <Grid container spacing={2} direction="column">
                <Grid item lg md>
                  <Skeleton
                    variant="rect"
                    width="100%"
                    height={40}
                    animation="wave"
                    className="skeletonRounded"
                  />
                </Grid>
                <Grid item lg md>
                  <Skeleton
                    variant="rect"
                    width="100%"
                    height={158}
                    animation="wave"
                    className="skeletonRounded"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={2} md={2}>
              <Skeleton
                variant="rect"
                width="100%"
                height={40}
                animation="wave"
                className="skeletonRounded"
              />
              <div style={{ marginTop: 24 }}>
                <Skeleton
                  variant="rect"
                  width="100%"
                  height={40}
                  animation="wave"
                  className="skeletonRounded"
                />
              </div>
              <div style={{ marginTop: 16 }}>
                <Skeleton
                  variant="rect"
                  width="100%"
                  height={40}
                  animation="wave"
                  className="skeletonRounded"
                />
              </div>
              <div style={{ marginTop: 16 }}>
                <Skeleton
                  variant="rect"
                  width="100%"
                  height={40}
                  animation="wave"
                  className="skeletonRounded"
                />
              </div>
            </Grid>
          </Grid>
        </div>
      </Grid>
      <Grid item lg md>
        <div className="container-main-card p-16">
          <Grid container spacing={3}>
            <Grid item lg={3} md={3}>
              <Grid container spacing={2} direction="column">
                <Grid item lg md>
                  <Skeleton
                    variant="rect"
                    width="100%"
                    height={30}
                    animation="wave"
                    className="skeletonRounded"
                  />
                </Grid>
                <Grid item lg md>
                  <Skeleton
                    variant="rect"
                    width="100%"
                    height={30}
                    animation="wave"
                    className="skeletonRounded"
                  />
                </Grid>
                <Grid item lg md>
                  <Skeleton
                    variant="rect"
                    width="100%"
                    height={30}
                    animation="wave"
                    className="skeletonRounded"
                  />
                </Grid>
                <Grid item lg md>
                  <Skeleton
                    variant="rect"
                    width="100%"
                    height={30}
                    animation="wave"
                    className="skeletonRounded"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={9} md={9}>
              <Grid container spacing={2} direction="column">
                <Grid item lg md>
                  <Skeleton
                    variant="rect"
                    width="100%"
                    height={30}
                    animation="wave"
                    className="skeletonRounded"
                  />
                </Grid>
                <Grid item lg md>
                  <Skeleton
                    variant="rect"
                    width="100%"
                    height={30}
                    animation="wave"
                    className="skeletonRounded"
                  />
                </Grid>
                <Grid item lg md>
                  <Skeleton
                    variant="rect"
                    width="100%"
                    height={30}
                    animation="wave"
                    className="skeletonRounded"
                  />
                </Grid>
                <Grid item lg md>
                  <Skeleton
                    variant="rect"
                    width="100%"
                    height={30}
                    animation="wave"
                    className="skeletonRounded"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
};

export default SkeletonDetailsV02;
