/* eslint-disable no-unused-vars */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable import/no-unresolved */
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Breadcrumbs, FormControl, Grid, Link } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import { Form } from 'antd';
import PropTypes from 'prop-types';
import _ from 'lodash';
// component
import {
  AuthenticationAccessPages,
  LabelInput,
  TextInput,
  PickerInputDate,
  PickerInputTime,
  RadioInput,
  UploadDocument,
  SelectInputMain,
  SnackBarSimple,
} from '../../../components/Index';
import { ButtonActionFooter } from './components/Index';
// helper
import {
  CommonHelper,
  MasterDataHelper,
  HttpStatusCode,
  PermissionModule,
  PermissionPage,
  PermissionAccess,
} from '../../../helpers/Index';
// api
import {
  getRoomType,
  getDietType,
  setCreatePatient,
  getPatientDetail,
  updatePatientDetail,
  searchPatientByMedicalRecordNumber,
} from '../../../services/api/EatApi';
// style
import './MealPlanAddStyle.scss';

const currentDate = CommonHelper.currentDate('YYYY-MM-DD');
const genderOption = MasterDataHelper.gender;
const titleNameOption = MasterDataHelper.titleName;

const initialFilter = {
  search: '',
  searchCompanyName: '',
};

const initialForm = {
  titleName: titleNameOption[0].value,
  firstName: '',
  lastName: '',
  birthday: CommonHelper.objectCloning(currentDate),
  gender: genderOption[0],
  medicalRecordNumber: '',
  roomTypeId: 1,
  roomName: '',
  roomNumber: '',
  doctor: '',
  dietNotes: '',
  documents: [],
  roomType: 1,
  dietType: 0,
  checkInTime: '00:00',
  checkInDate: CommonHelper.objectCloning(currentDate),
  userId: null,
};

const initialValidation = {
  titleName: { isError: false, errorMessage: '' },
  firstName: { isError: false, errorMessage: '' },
  lastName: { isError: false, errorMessage: '' },
  gender: { isError: '', errorMessage: '' },
  medicalRecordNumber: { isError: false, errorMessage: '' },
  roomName: { isError: false, errorMessage: '' },
  roomNumber: { isError: false, errorMessage: '' },
  // doctor: { isError: false, errorMessage: '' },
  dietNotes: { isError: false, errorMessage: '' },
  documents: { isError: '', errorMessage: '' },
};

const optionToash = {
  vertical: 'top',
  horizontal: 'right',
};

class MealPlanAdd extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      form: CommonHelper.objectCloning(initialForm),
      validation: CommonHelper.objectCloning(initialValidation),
      filter: CommonHelper.objectCloning(initialFilter),

      isLoadingSubmit: false,
      toastInformation: {
        isOpen: false,
        message: '',
        snackbarType: 'warning',
      },
      roomTypeOption: [],
      dietTypeOption: [],
    };
    this.searchMedicalRecordNumberDebounce = _.debounce(
      this.searchMedicalRecordNumberDebounce,
      600,
    );
  }

  async componentDidMount() {
    const {
      match: { params },
      checkUserAccessPermission,
    } = this.props;

    const ifExistParamObject = params.param_object
      ? CommonHelper.decryptObject(params.param_object)
      : {};

    const updateExitingForm = {
      ...CommonHelper.objectCloning(initialForm),
      ...ifExistParamObject,
    };

    await this.getRoomType();
    await this.getDietType();

    if (updateExitingForm.userRoomId) {
      checkUserAccessPermission(
        PermissionModule.Eat,
        PermissionPage.InPatient,
        PermissionAccess.Update,
      );
      this.setState(
        {
          form: updateExitingForm,
        },
        () => {
          this.getPatientDetail();
        },
      );
    } else {
      checkUserAccessPermission(
        PermissionModule.Eat,
        PermissionPage.InPatient,
        PermissionAccess.Add,
      );
    }
  }

  getPatientDetail() {
    const { patientDetail } = this.props;
    const { form, filter } = this.state;

    patientDetail(form.userRoomId)
      .then(response => {
        const data = response.data;
        const modifyDocument = [];

        if (!_.isEmpty(data.attachments)) {
          data.attachments.forEach((item, index) => {
            const formatParam = {
              uid: index,
              name: item.name,
              type: item.attachment_type,
              status: 'done',
              url: item.url,
            };

            modifyDocument.push(formatParam);
          });
        }

        const gender = genderOption.find(item => item.value === +data.gender);

        this.setState({
          form: {
            ...form,
            titleName: data.title,
            firstName: data.first_name,
            lastName: data.last_name,
            medicalRecordNumber: data.medical_record_number,
            roomTypeId: data.room_type_id,
            roomName: data.room_name,
            roomNumber: data.room_number,
            checkInTime: CommonHelper.dateTimeParseNewFormat(data.check_in_time, 'HH:mm'),
            doctor: data.doctor,
            dietNotes: data.diet_notes,
            documents: modifyDocument,
            roomType: data.room_type_id,
            dietType: data.diet_type,
            checkInDate: data.check_in_time,
            birthday: data.birthday,
            gender,
          },
          filter: { ...filter },
        });
      })
      .catch(error => {
        const message = error.data;

        this.processMessage(message.messages, 'error');
      });
  }

  getRoomType = async () => {
    const { form } = this.state;
    const { listRoomType } = this.props;
    try {
      const { data } = await listRoomType();
      const roomTypeOption = data.map(item => ({ value: item.room_type_id, name: item.room_type }));
      this.setState({
        form: { ...form, roomType: roomTypeOption[0].value },
        roomTypeOption,
      });
    } catch (error) {
      const message = error.data;
      this.processMessage(message.messages, 'error');
    }
  };

  getDietType = async () => {
    const { form } = this.state;
    const { listDietType } = this.props;
    try {
      const { data } = await listDietType();
      const dietTypeOption = data.map(item => ({ value: item.product_id, name: item.name }));
      this.setState({
        form: { ...form, dietType: dietTypeOption[0].value },
        dietTypeOption,
      });
    } catch (error) {
      const message = error.data;
      this.processMessage(message.messages, 'error');
    }
  };

  searchMedicalRecordNumberDebounce = async medicalNumber => {
    const { patientByMedicalRecordNumber } = this.props;
    const { form, filter } = this.state;
    try {
      const { data } = await patientByMedicalRecordNumber(medicalNumber);

      const modifyDocument = [];
      if (!_.isEmpty(data.attachments)) {
        data.attachments.forEach((item, index) => {
          const formatParam = {
            uid: index,
            name: item.name,
            type: item.attachment_type,
            status: 'done',
            url: item.url,
          };

          modifyDocument.push(formatParam);
        });
      }
      const gender = genderOption.find(item => item.value === +data.gender);

      this.setState({
        form: {
          ...form,
          firstName: data.first_name,
          lastName: data.last_name,
          birthday: data.birthday,
          gender,
          userId: data.user_id,
          documents: modifyDocument,
        },
        filter: { ...filter },
      });
      this.processMessage(
        `Patient detail found with Nomor Rekam Medis (NRM) : ${medicalNumber}`,
        'success',
      );
    } catch (error) {
      const message = error.data;
      this.processMessage(message.messages, 'error');
    }
  };

  handleChangeGender = value => {
    const { form } = this.state;

    this.setState({ form: { ...form, gender: value } });
  };

  handleChangeFile = fileBase64 => {
    const { form } = this.state;
    const converted = [];

    fileBase64.forEach((item, index) => {
      const formatParam = {
        uid: item.uid,
        order: index,
        name: item.name,
        status: 'done',
        url: item.url || item.base64file,
      };
      converted.push(formatParam);
    });

    this.setState({
      form: { ...form, documents: converted },
    });
  };

  handleChangeText = (value, id) => {
    const { validation, form } = this.state;
    if (id !== 'doctor') {
      const message = CommonHelper.objectCloning(initialValidation[id]);
      message.isError = !value;
      message.errorMessage = !value ? 'Field is empty' : '';
      this.setState({
        validation: { ...validation, [id]: message },
      });
    }

    if (id === 'medicalRecordNumber') {
      this.searchMedicalRecordNumberDebounce(value);
    }

    this.setState({
      form: { ...form, [id]: value },
    });
  };

  validateForm = () => {
    const {
      form: { firstName, lastName, medicalRecordNumber, roomName, roomNumber, doctor, dietNotes },
      validation,
    } = this.state;

    const messageV1 = { isError: false, errorMessage: '' };
    const firstNameValidation = CommonHelper.objectCloning(messageV1);
    const medicalRecordNumberValidation = CommonHelper.objectCloning(messageV1);
    const roomNameValidation = CommonHelper.objectCloning(messageV1);
    const roomNumberValidation = CommonHelper.objectCloning(messageV1);
    // const doctorValidation = CommonHelper.objectCloning(messageV1);
    const dietNotesValidation = CommonHelper.objectCloning(messageV1);

    firstNameValidation.isError = !firstName;
    firstNameValidation.errorMessage = !firstName ? 'First Name is empty' : '';

    medicalRecordNumberValidation.isError = !medicalRecordNumber;
    medicalRecordNumberValidation.errorMessage = !medicalRecordNumber
      ? 'Medical record number is empty'
      : '';

    roomNameValidation.isError = !roomName;
    roomNameValidation.errorMessage = !roomName ? 'Room name is empty' : '';

    roomNumberValidation.isError = !roomNumber;
    roomNumberValidation.errorMessage = !roomNumber ? 'Room number is empty' : '';

    // doctorValidation.isError = !doctor;
    // doctorValidation.errorMessage = !doctor ? 'Doctor name is empty' : '';

    dietNotesValidation.isError = !dietNotes;
    dietNotesValidation.errorMessage = !dietNotes ? 'Diet notes is empty' : '';

    if (
      !firstNameValidation.isError &&
      !medicalRecordNumberValidation.isError &&
      !roomNameValidation.isError &&
      !roomNumberValidation.isError &&
      // !doctorValidation.isError &&
      !dietNotesValidation.isError
    ) {
      return true;
    }

    this.setState({
      validation: {
        ...validation,
        firstName: firstNameValidation,
        medicalRecordNumber: medicalRecordNumberValidation,
        roomName: roomNameValidation,
        roomNumber: roomNumberValidation,
        // doctor: doctorValidation,
        dietNotes: dietNotesValidation,
      },
    });

    return false;
  };

  handleSelectChangeRoomType = value => {
    const { form } = this.state;

    this.setState({
      form: {
        ...form,
        roomType: value,
      },
    });
  };

  handleSelectChangeDietType = value => {
    const { form } = this.state;

    this.setState({
      form: {
        ...form,
        dietType: value,
      },
    });
  };

  handleSelectChangeTitleName = value => {
    const { form } = this.state;

    this.setState({
      form: {
        ...form,
        titleName: value,
      },
    });
  };

  handleChangeCheckInTime = value => {
    const { form } = this.state;
    const checkInTime = value || '';
    this.setState({
      form: { ...form, checkInTime },
    });
  };

  handleButtonClickCancel = () => {
    const { history } = this.props;
    history.push('/eat/in-patient');
  };

  handleButtonClickSubmit = async () => {
    const { form, dietTypeOption, roomTypeOption } = this.state;
    const { processCreatePatient, processUpdatePatient, history } = this.props;

    const isValid = this.validateForm();
    if (isValid) {
      const params = {
        title: form.titleName,
        first_name: form.firstName,
        last_name: form.lastName,
        birthday: form.birthday,
        gender: form.gender.value,
        medical_record_number: form.medicalRecordNumber,
        room_type_id: form.roomType || roomTypeOption[0].value,
        room_name: form.roomName,
        room_number: form.roomNumber,
        diet_type: form.dietType || dietTypeOption[0].value,
        diet_notes: form.dietNotes,
        doctor: form.doctor,
        check_in_time: `${form.checkInDate} ${form.checkInTime}`,
        attachments: form.documents,
        user_id: form.userId,
      };

      try {
        this.setState({ isLoadingSubmit: true });
        let response;
        if (form.userRoomId) {
          const id = form.userRoomId;
          response = await processUpdatePatient(id, params);
        } else {
          response = await processCreatePatient(params);
        }
        this.processMessage(response.messages, response.status);
        if (response.code === HttpStatusCode.Success) {
          setTimeout(async () => {
            history.push('/eat/in-patient');
          }, 2000);
        }
      } catch (error) {
        this.setState({ isLoadingSubmit: false });
        const serverMessage = error.data;
        const validationStatus = error.status === HttpStatusCode.InternalServerError;
        this.processMessage(
          validationStatus ? serverMessage.message : serverMessage.messages,
          'error',
        );
      }
    }
  };

  handleChangeBirthday = value => {
    const { form } = this.state;
    const birthday = value || '';

    this.setState({
      form: { ...form, birthday },
    });
  };

  handleChangeCheckInDate = value => {
    const { form } = this.state;
    const checkInDate = value || '';

    this.setState({
      form: { ...form, checkInDate },
    });
  };

  handleCloseToash = () => {
    const { toastInformation } = this.state;
    this.setState({ toastInformation: { ...toastInformation, isOpen: false } });
  };

  handleClick = (event, value) => {
    const { history } = this.props;
    event.preventDefault();
    history.push(value);
  };

  processMessage(messages, type) {
    const convertedMessage = CommonHelper.generateMessage(messages);

    const paramInformation = {
      isOpen: true,
      message: convertedMessage,
      snackbarType: type,
    };

    this.setState({
      toastInformation: paramInformation,
    });
  }

  render() {
    const {
      eatData: { fetching },
    } = this.props;
    const {
      form,
      validation,
      isLoadingSubmit,
      roomTypeOption,
      dietTypeOption,
      toastInformation,
    } = this.state;
    const prevUrl = '/eat/in-patient';

    const isEditMode = form.userRoomId;

    return (
      <div>
        <Helmet title="FITCO | Eats - In-Patient - Create Patient" />
        <div className="container-page-in-patient-add scroll-container">
          <div className="container-page-scrolling-area include-footer">
            <Grid container direction="column" className="flex-wrap-unset">
              <Grid item lg md className="section-page-header">
                <div className="breadcrumbs-section">
                  <Breadcrumbs aria-label="breadcrumb">
                    <Link
                      className="text-12"
                      color="inherit"
                      href={prevUrl}
                      onClick={event => {
                        this.handleClick(event, prevUrl);
                      }}
                    >
                      <i className="ic-ffo-eats container-icon-prefix size-16" />
                      Eat
                    </Link>
                    <Link
                      className="text-12"
                      color="inherit"
                      href={prevUrl}
                      onClick={event => {
                        this.handleClick(event, prevUrl);
                      }}
                    >
                      Meal Plan
                    </Link>
                    <label className="text-12" color="inherit">
                      {form.userRoomId ? 'Edit Patient' : 'Add Patient'}
                    </label>
                  </Breadcrumbs>
                </div>
              </Grid>
              <Grid item lg md className="section-page-body mb-24">
                <div className="container-main-card p-32 flex-column">
                  <label className="text-12 text-rolling-stone text-bold mb-4">
                    PATIENT DETAIL
                  </label>
                  <Grid container spacing={3} className="mb-32">
                    <Grid item lg={6} md={6}>
                      <Grid container spacing={2} direction="column">
                        <Grid item lg md>
                          <FormControl component="fieldset" fullWidth margin={'normal'}>
                            <LabelInput labelText="Nomor Rekam Medis (NRM)" isRequired />
                            <TextInput
                              placeHolderText="Enter Nomor Rekam Medis (NRM)"
                              size="md"
                              onChange={value => {
                                this.handleChangeText(value, 'medicalRecordNumber');
                              }}
                              currentValue={form.medicalRecordNumber}
                              errorMessage={validation.medicalRecordNumber.errorMessage}
                              isError={validation.medicalRecordNumber.isError}
                              isLoading={fetching}
                              isDisable={fetching}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item lg md>
                          <Grid container spacing={2}>
                            <Grid item lg={4} md={4}>
                              <FormControl component="fieldset" fullWidth margin={'normal'}>
                                <LabelInput labelText="Date of Birth" isRequired />
                                <PickerInputDate
                                  customIcon="ic-ffo-date-pick"
                                  dateFormat="dd/MM/yyyy"
                                  defaultValue={form.birthday}
                                  onChange={this.handleChangeBirthday}
                                  toolbar={false}
                                />
                              </FormControl>
                            </Grid>
                            <Grid item lg={'auto'} md={'auto'}>
                              <FormControl component="fieldset" fullWidth margin={'normal'}>
                                <LabelInput labelText="Gender" />
                                <RadioInput
                                  data={genderOption}
                                  onSelect={this.handleChangeGender}
                                  direction="row"
                                  currentValue={form.gender}
                                />
                              </FormControl>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item lg md>
                          <FormControl component="fieldset" fullWidth margin={'normal'}>
                            <LabelInput labelText="Document" />
                            <UploadDocument
                              labelText="Upload Document (pdf/img/word)"
                              onFileChange={this.handleChangeFile}
                              fileType="pdf/img/doc"
                              size="md"
                              fileList={form.documents}
                              maxFiles={4}
                              errorMessage={validation.documents.errorMessage}
                              validateStatus={validation.documents.isError}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item lg={6} md={6}>
                      <Grid container direction="column" spacing={2}>
                        <Grid item lg md>
                          <FormControl component="fieldset" fullWidth margin={'normal'}>
                            <LabelInput labelText="Title" isRequired />
                            <SelectInputMain
                              options={titleNameOption}
                              currentValue={form.titleName}
                              onChange={this.handleSelectChangeTitleName}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item lg md>
                          <FormControl component="fieldset" fullWidth margin={'normal'}>
                            <LabelInput labelText="First Name" isRequired />
                            <TextInput
                              placeHolderText="Enter Patient First Name"
                              size="md"
                              onChange={value => {
                                this.handleChangeText(value, 'firstName');
                              }}
                              currentValue={form.firstName}
                              errorMessage={validation.firstName.errorMessage}
                              isError={validation.firstName.isError}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item lg md>
                          <FormControl component="fieldset" fullWidth margin={'normal'}>
                            <LabelInput labelText="Last Name" />
                            <TextInput
                              placeHolderText="Enter Patient Last Name"
                              size="md"
                              onChange={value => {
                                this.handleChangeText(value, 'lastName');
                              }}
                              currentValue={form.lastName}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <label className="text-12 text-rolling-stone text-bold mb-4">ROOM DETAIL</label>
                  <Grid container spacing={3} className="mb-32">
                    <Grid item lg={6} md={6}>
                      <Grid container spacing={2} direction="column">
                        <Grid item lg md>
                          <FormControl component="fieldset" fullWidth margin={'normal'}>
                            <LabelInput labelText="Room Type" isRequired />
                            <SelectInputMain
                              options={roomTypeOption}
                              currentValue={form.roomType}
                              onChange={this.handleSelectChangeRoomType}
                              disabled={isEditMode}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item lg md>
                          <Grid container spacing={2}>
                            <Grid item lg md>
                              <FormControl component="fieldset" fullWidth margin={'normal'}>
                                <LabelInput labelText="Check-in Date" isRequired />
                                <PickerInputDate
                                  customIcon="ic-ffo-date-pick"
                                  dateFormat="dd/MM/yyyy"
                                  defaultValue={form.checkInDate}
                                  onChange={this.handleChangeCheckInDate}
                                  toolbar={false}
                                />
                              </FormControl>
                            </Grid>
                            <Grid item lg md>
                              <FormControl component="fieldset" fullWidth margin={'normal'}>
                                <LabelInput labelText="Check-in Time" isRequired />
                                <Form.Item>
                                  <PickerInputTime
                                    defaultValue={form.checkInTime}
                                    onChange={this.handleChangeCheckInTime}
                                    customIcon="ic-ffo-timer"
                                    size="md"
                                  />
                                </Form.Item>
                              </FormControl>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item lg={6} md={6}>
                      <Grid container direction="column" spacing={2}>
                        <Grid item lg md>
                          <Grid container spacing={2}>
                            <Grid item lg md>
                              <FormControl component="fieldset" fullWidth margin={'normal'}>
                                <LabelInput labelText="Room Name" isRequired />
                                <TextInput
                                  placeHolderText="eg. Kartika 1"
                                  size="md"
                                  onChange={value => {
                                    this.handleChangeText(value, 'roomName');
                                  }}
                                  currentValue={form.roomName}
                                  errorMessage={validation.roomName.errorMessage}
                                  isError={validation.roomName.isError}
                                />
                              </FormControl>
                            </Grid>
                            <Grid item lg md>
                              <FormControl component="fieldset" fullWidth margin={'normal'}>
                                <LabelInput labelText="Room Number" isRequired />
                                <TextInput
                                  placeHolderText="eg. 1301"
                                  size="md"
                                  onChange={value => {
                                    this.handleChangeText(value, 'roomNumber');
                                  }}
                                  currentValue={form.roomNumber}
                                  errorMessage={validation.roomNumber.errorMessage}
                                  isError={validation.roomNumber.isError}
                                />
                              </FormControl>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item lg md>
                          <FormControl component="fieldset" fullWidth margin={'normal'}>
                            <LabelInput labelText="Doctor Name" />
                            <TextInput
                              placeHolderText="Enter Doctor Name"
                              size="md"
                              onChange={value => {
                                this.handleChangeText(value, 'doctor');
                              }}
                              currentValue={form.doctor}
                              // errorMessage={validation.doctor.errorMessage}
                              // isError={validation.doctor.isError}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <label className="text-12 text-rolling-stone text-bold mb-4">MEAL DETAIL</label>
                  <Grid container spacing={3}>
                    <Grid item lg={6} md={6}>
                      <Grid container spacing={2} direction="column">
                        <Grid item lg md>
                          <FormControl component="fieldset" fullWidth margin={'normal'}>
                            <LabelInput labelText="Diet Type" isRequired />
                            <SelectInputMain
                              options={dietTypeOption}
                              currentValue={form.dietType}
                              onChange={this.handleSelectChangeDietType}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item lg={6} md={6}>
                      <Grid container direction="column" spacing={2}>
                        <Grid item lg md>
                          <FormControl component="fieldset" fullWidth margin={'normal'}>
                            <LabelInput labelText="Diet Notes" isRequired />
                            <TextInput
                              placeHolderText="Enter Diet Notes"
                              size="md"
                              onChange={value => {
                                this.handleChangeText(value, 'dietNotes');
                              }}
                              currentValue={form.dietNotes}
                              errorMessage={validation.dietNotes.errorMessage}
                              isError={validation.dietNotes.isError}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
              <Grid item lg md className="section-page-footer" />
            </Grid>
          </div>
        </div>
        <div className="container-page-purchase-order-add-container-floating">
          <ButtonActionFooter
            isEditMode={isEditMode}
            loadingButtonSubmit={isLoadingSubmit}
            onButtonClickCancel={this.handleButtonClickCancel}
            onButtonClickSubmit={this.handleButtonClickSubmit}
          />
        </div>
        <SnackBarSimple
          open={toastInformation.isOpen}
          durationHide={2000}
          message={toastInformation.message}
          onClickClose={this.handleCloseToash}
          snackbarType={toastInformation.snackbarType}
          anchor={optionToash}
        />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  listDietType: () => getDietType(dispatch),
  listRoomType: () => getRoomType(dispatch),
  patientDetail: id => getPatientDetail(dispatch, id),
  processCreatePatient: params => setCreatePatient(dispatch, params),
  processUpdatePatient: (id, params) => updatePatientDetail(dispatch, id, params),
  patientByMedicalRecordNumber: medicalRecordNumber =>
    searchPatientByMedicalRecordNumber(dispatch, medicalRecordNumber),
});

const mapStateToProps = ({ eatData }) => ({ eatData });

MealPlanAdd.propTypes = {
  checkUserAccessPermission: PropTypes.func,
  eatData: PropTypes.object,
  history: PropTypes.object,
  listDietType: PropTypes.func,
  listRoomType: PropTypes.func,
  match: PropTypes.object,
  patientByMedicalRecordNumber: PropTypes.func,
  patientDetail: PropTypes.func,
  processCreatePatient: PropTypes.func,
  processUpdatePatient: PropTypes.func,
};

const shell = compose(connect(mapStateToProps, mapDispatchToProps));
const core = compose(AuthenticationAccessPages);

export default shell(core(MealPlanAdd));
