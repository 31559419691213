/* eslint-disable react/no-access-state-in-setstate */
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Breadcrumbs, FormControl, Grid, Link, Checkbox } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Table } from 'antd';
import { v4 as uuidv4 } from 'uuid';
// component
import {
  AuthenticationAccessPages,
  LabelInput,
  PickerInputDate,
  RadioInput,
  SelectInputMain,
  SnackBarSimple,
  TextAreaMain,
  TextInput,
  TextInputAutocomplete,
  TextInputNumber,
  TextInputPrePost,
  UploadDocument,
  LabelStatusMain,
  ButtonIconMain,
  ModalAlertAction,
} from '../../../../components/Index';
import { ModalCompanyCreate } from '../../../master-data/master-data-main-page/company/components/Index';
// helper
import {
  CommonHelper,
  currencyCode,
  HttpStatusCode,
  MasterDataHelper,
  OrderHelper,
  ValidationHelper,
  PermissionModule,
  PermissionPage,
  permissionAccessPurchaseOrder,
} from '../../../../helpers/Index';
// api
import { getListCompanyPagination } from '../../../../services/api/MasterDataMainAPi';
import {
  getOrderProductPagination,
  getPurchaseOrderDetails,
  setProcessCreatePurchaseOrder,
  setProcessUpdatePurchaseOrder,
} from '../../../../services/api/OrdersApi';
// style
import './PurchaseOrderAddStyle.scss';
import { ButtonActionFooterV02, TermOfPaymentItem } from './components/Index';

const currentDate = CommonHelper.currentDate('YYYY-MM-DD');
const startDateValue = CommonHelper.intervalDate(currentDate, 'YYYY-MM-DD', 'day', -7);
const shippingMethodOption = MasterDataHelper.shippingMethod.filter(item => item.value !== 'jnt');
const radioButtonTrueFalseOption = MasterDataHelper.radioButtonTrueFalse;
const termOfPaymentOption = MasterDataHelper.termOfPaymentOption;

const initialTermOfPaymentOption = [
  {
    value: null,
    name: 'Select applicable terms',
  },
  ...termOfPaymentOption,
];

const initialFilter = {
  search: '',
  searchCompanyName: '',
};

const initialForm = {
  purchaseOrderId: null,
  poNumber: '',
  companyName: '',
  companyId: '',
  addressInfo: '',
  placeId: '',
  phone: '',
  email: '',
  companyAddress: '',
  note: '',
  shippingMethod: shippingMethodOption[0].value,
  shippingFee: 0,
  deliveryDate: CommonHelper.objectCloning(currentDate),
  items: [],
  documents: [],
  ppnInclude: radioButtonTrueFalseOption[0],
  termOfPayment: null,
  reason: '',
};

const initialValidation = {
  phone: { isError: false, errorMessage: '' },
  email: { isError: false, errorMessage: '' },
  poNumber: { isError: false, errorMessage: '' },
  companyName: { isError: false, errorMessage: '' },
  companyAddress: { isError: '', errorMessage: '' },
  note: { isError: '', errorMessage: '' },
  shippingMethod: { isError: '', errorMessage: '' },
  shippingFee: { isError: '', errorMessage: '' },
  documents: { isError: '', errorMessage: '' },
  listItem: { isError: false, errorMessage: '' },
  reason: { isError: '', errorMessage: '' },
};

const optionToash = {
  vertical: 'top',
  horizontal: 'right',
};

const initialSort = {
  sortField: 'name',
  sortOrder: '',
};

class PurchaseOrderAdd extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      form: CommonHelper.objectCloning(initialForm),
      validation: CommonHelper.objectCloning(initialValidation),
      filter: CommonHelper.objectCloning(initialFilter),
      sort: CommonHelper.objectCloning(initialSort),

      listCompany: [],
      isOpenModalCompanyCreate: false,
      limit: 99,
      isLoadingSubmit: false,
      grandTotal: 0,
      pagination: {
        hideOnSinglePage: true,
        showSizeChanger: false,
        pageSizeOptions: ['10', '20', '30', '40'],
      },
      toastInformation: {
        isOpen: false,
        message: '',
        snackbarType: 'warning',
      },
      listItem: [],
      isOpenModalDelete: false,
      modalDetailDataDelete: {
        title: 'Confirmation',
        body: 'Are you sure want to Delete Product ',
      },
      selectedDataDelete: null,
      termOfPaymentAmount: {},
      terms: [],
    };
    this.searchCompanyDebounce = _.debounce(this.searchCompanyDebounce, 400);
    this.searchProductDebounce = _.debounce(this.searchProductDebounce, 400);
  }

  componentDidMount() {
    const {
      match: { params },
      checkUserAccessPermission,
    } = this.props;

    const ifExistParamObject = params.param_object
      ? CommonHelper.decryptObject(params.param_object)
      : {};

    const updateExitingForm = {
      ...CommonHelper.objectCloning(initialForm),
      ...ifExistParamObject,
    };

    if (updateExitingForm.purchaseOrderId) {
      checkUserAccessPermission(
        PermissionModule.Orders,
        PermissionPage.PurchaseOrder,
        permissionAccessPurchaseOrder.UpdatePurchaseOrder,
      );
      this.setState(
        {
          form: updateExitingForm,
        },
        () => {
          this.getPurchaseOrderDetails();
        },
      );
    } else {
      checkUserAccessPermission(
        PermissionModule.Orders,
        PermissionPage.PurchaseOrder,
        permissionAccessPurchaseOrder.CreatePurchaseOrder,
      );
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.form.termOfPayment !== this.state.form.termOfPayment) {
      return true;
    }

    if (nextState.termOfPaymentAmount !== this.state.termOfPaymentAmount) {
      return false;
    }
    return true;
  }

  getPurchaseOrderDetails() {
    const { purchaseOrderDetails } = this.props;
    const { form, filter } = this.state;

    purchaseOrderDetails(form.purchaseOrderId)
      .then(response => {
        const data = response.data;
        const modifyDocument = [];

        if (!_.isEmpty(data.attachment_list)) {
          data.attachment_list.forEach((item, index) => {
            const formatParam = {
              uid: index,
              name: item.name,
              type: item.attachment_type,
              status: 'done',
              url: item.url,
            };

            modifyDocument.push(formatParam);
          });
        }

        const marUpItems = CommonHelper.renameKeyName(data.items, 'quantity', 'qty');

        const grandTotal = OrderHelper.getGrandTotalFloatPrice(marUpItems);

        const termOfPaymentAmount = {};
        if (!_.isEmpty(data.terms)) {
          data.terms.forEach((item, index) => {
            Object.assign(termOfPaymentAmount, {
              ...termOfPaymentAmount,
              [`term-${index + 1}`]: {
                amount: +item.amount,
                due_date: item.due_date,
                order: index,
              },
            });
          });
        }

        this.setState(
          {
            form: {
              ...form,
              poNumber: data.details.purchase_order_no,
              companyId: data.details.company_id,
              companyName: data.details.company_name,
              phone: data.details.phone,
              email: data.details.email,
              note: data.shipping_details.shipping_notes,
              shippingMethod: data.details.shipping_method,
              shippingFee: data.details.shipping_cost,
              deliveryDate: data.shipping_details.shipping_date,
              items: marUpItems,
              documents: modifyDocument,
              reason: data.details.update_reason,
              termOfPayment: data.details.term_of_payment,
              ppnInclude: +data.details.total_ppn
                ? radioButtonTrueFalseOption[0]
                : radioButtonTrueFalseOption[1],
              companyAddress: data.shipping_details.shipping_address,
            },
            grandTotal,
            filter: { ...filter, searchCompanyName: data.details.company_name },
            terms: data.terms,
            termOfPaymentAmount,
          },
          () => {
            this.searchCompanyDebounce();
          },
        );
      })
      .catch(error => {
        const message = error.data;

        this.processMessage(message.messages, 'error');
      });
  }

  setProcessCreatePurchaseOrder(params) {
    const { processCreatePurchaseOrder, history } = this.props;

    this.setState(
      {
        isLoadingSubmit: true,
      },
      () => {
        processCreatePurchaseOrder(params)
          .then(async response => {
            await this.processMessage(response.messages, response.status);

            if (response.code === HttpStatusCode.Success) {
              setTimeout(async () => {
                await history.push('/order/purchase-order');
              }, 2000);
            }
          })
          .catch(error => {
            this.setState({
              isLoadingSubmit: false,
            });
            const serverMessage = error.data;
            const validationStatus = error.status === HttpStatusCode.InternalServerError;
            this.processMessage(
              validationStatus ? serverMessage.message : serverMessage.messages,
              'error',
            );
          });
      },
    );
  }

  setProcessUpdatePurchaseOrder(params) {
    const { form } = this.state;
    const { processUpdatePurchaseOrder, history } = this.props;

    this.setState(
      {
        isLoadingSubmit: true,
      },
      () => {
        processUpdatePurchaseOrder(form.purchaseOrderId, params)
          .then(async response => {
            this.processMessage(response.messages, response.status);
            this.setState({
              isLoadingSubmit: false,
            });
            if (response.code === HttpStatusCode.Success) {
              setTimeout(async () => {
                await history.push('/order/purchase-order');
              }, 2000);
            }
          })
          .catch(error => {
            this.setState({
              isLoadingSubmit: false,
            });
            const serverMessage = error.data;
            const validationStatus = error.status === HttpStatusCode.InternalServerError;
            this.processMessage(
              validationStatus ? serverMessage.message : serverMessage.messages,
              'error',
            );
          });
      },
    );
  }

  handleChangePPNStatus = value => {
    const { form } = this.state;

    this.setState({ form: { ...form, ppnInclude: value } });
  };

  handleClick = (event, value) => {
    const { history } = this.props;
    event.preventDefault();
    history.push(value);
  };

  handleButtonClickCancel = () => {
    const { history } = this.props;
    history.push('/order/purchase-order');
  };

  handleTextChangePONumber = value => {
    const { form, validation } = this.state;
    const message = CommonHelper.objectCloning(initialValidation.poNumber);
    message.isError = !value;
    message.errorMessage = !value ? 'PO Number is empty' : '';

    this.setState({
      form: { ...form, poNumber: value },
      validation: {
        ...validation,
        poNumber: message,
      },
    });
  };

  handleTextChangeName = value => {
    const { filter } = this.state;

    this.setState(
      {
        filter: { ...filter, searchCompanyName: value },
      },
      () => {
        this.searchCompanyDebounce();
      },
    );
  };

  handleSelectedItemName = (value, option) => {
    const { form, validation } = this.state;
    const messageName = CommonHelper.objectCloning(initialValidation.companyName);
    const messageAddress = CommonHelper.objectCloning(initialValidation.companyAddress);

    if (value === 'new') {
      this.setState({ isOpenModalCompanyCreate: true });
    } else {
      const dataInfo = option.address_details;
      if (!_.isEmpty(dataInfo) && dataInfo.place_id) {
        this.setState({
          form: {
            ...form,
            companyId: option.company_id,
            companyName: option.name,
            phone: option.phone || form.phone,
            email: option.email || form.email,
            addressInfo: option.address_details,
            placeId: option.address_details.place_id,
            companyAddress: option.address,
          },
          validation: { ...validation, companyName: messageName, companyAddress: messageAddress },
          filter: { ...initialFilter, searchCompanyName: option.name },
        });
      } else {
        this.processMessage(
          'There is some Problem, to process with your selected Address, please contact support for more information ',
          'warning',
        );
      }
    }
  };

  handleTextChangeAddressNote = value => {
    const { validation, form } = this.state;
    const message = CommonHelper.objectCloning(initialValidation.note);
    message.isError = !value ? 'error' : '';
    message.errorMessage = !value ? 'Address Note is empty' : '';

    this.setState({
      form: { ...form, note: value },
      validation: { ...validation, note: message },
    });
  };

  handleTextChangeReasonToEdit = value => {
    const { validation, form } = this.state;
    const message = CommonHelper.objectCloning(initialValidation.reason);

    message.isError = _.isEmpty(value) ? 'error' : '';
    message.errorMessage = _.isEmpty(value)
      ? 'Please provide reason for editing Purchase Order'
      : '';

    this.setState({
      form: { ...form, reason: value },
      validation: { ...validation, reason: message },
    });
  };

  handleSelectChangeShippingMethod = value => {
    const { form } = this.state;

    this.setState({
      form: {
        ...form,
        shippingMethod: value,
      },
    });
  };

  handleTextChangePhone = value => {
    const { form, validation } = this.state;
    const phoneValidation = ValidationHelper.validatePhone(value.trim());

    const message = CommonHelper.objectCloning(initialValidation.phone);
    message.isError = !phoneValidation;
    message.errorMessage = !phoneValidation ? 'please check your Phone, and try again' : '';

    this.setState({
      form: { ...form, phone: value.trim() },
      validation: { ...validation, phone: message },
    });
  };

  handleTextChangeEmail = value => {
    const { validation, form } = this.state;
    const emailValidation = ValidationHelper.validateEmail(value.trim());

    const message = CommonHelper.objectCloning(initialValidation.email);
    message.isError = !emailValidation;
    message.errorMessage = !emailValidation ? 'please check email, and try again' : '';

    this.setState({
      form: { ...form, email: value.trim() },
      validation: { ...validation, email: message },
    });
  };

  handleTextChangeShippingFee = value => {
    const { form } = this.state;

    const numberValidation = CommonHelper.checkNumberOnly(value.trim());

    if (numberValidation) {
      this.setState({
        form: { ...form, shippingFee: parseInt(value.trim(), 10) },
      });
    }
  };

  handleChangeDeliveryDate = value => {
    const { form } = this.state;
    const deliveryDate = value || '';

    this.setState({
      form: { ...form, deliveryDate },
    });
  };

  handleChangeFile = fileBase64 => {
    const { form } = this.state;
    const converted = [];

    fileBase64.forEach((item, index) => {
      const formatParam = {
        uid: item.uid,
        order: index,
        name: item.name,
        status: 'done',
        url: item.url || item.base64file,
      };
      converted.push(formatParam);
    });

    this.setState({
      form: { ...form, documents: converted },
    });
  };

  handleCloseModalCreate = () => {
    this.setState(
      {
        isOpenModalCompanyCreate: false,
      },
      () => {
        this.searchCompanyDebounce();
      },
    );
  };

  handleChangeTextProduct = value => {
    const { filter } = this.state;

    this.setState(
      {
        filter: { ...filter, search: value },
      },
      () => {
        this.searchProductDebounce();
      },
    );
  };

  handleSelectedItem = (value, option) => {
    const { filter, form } = this.state;

    const currentItems = [...form.items];
    if (option.stock_status === 'instock') {
      Object.assign(option, {
        id: uuidv4(),
        qty: 1,
      });
      currentItems.push(option);
      const grandTotal = OrderHelper.getGrandTotalFloatPrice(currentItems);
      this.setState({
        filter: { ...filter, search: '' },
        form: { ...form, items: currentItems },
        grandTotal,
        listItem: [],
      });
    } else {
      this.processMessage('Selected Product Is Out of Stock', 'warning');
    }

    // const checkExistingItems = _.find(form.items, ['product_id', option.product_id]);

    // if (option.stock_status === 'instock') {
    //   // if (!checkExistingItems) {
    //   option.qty = 1;
    //   const finalSelected = [option, ...form.items];
    //   const grandTotal = OrderHelper.getGrandTotal(finalSelected);

    //   this.setState({
    //     filter: { ...filter, search: '' },
    //     form: { ...form, items: finalSelected },
    //     grandTotal,
    //   });
    // }
    // else {
    //   const existingItem = CommonHelper.objectCloning(form.items);
    //   const index = OrderHelper.finderIndexProductById(existingItem, option.product_id);
    //   existingItem[index].qty += 1;
    //   const grandTotal = OrderHelper.getGrandTotal(existingItem);

    //   this.setState({
    //     filter: { ...filter, search: '' },
    //     form: { ...form, items: existingItem },
    //     grandTotal,
    //   });
    // }
    // } else {
    //   this.processMessage('Selected Product Is Out of Stock', 'warning');
    // }
  };

  handleTextChangeProductPrice = id => value => {
    const { form, grandTotal } = this.state;
    const numberValidation = CommonHelper.checkNumberOnly(value.trim());
    let modifyValue = value;
    let modifyGrandTotal = grandTotal;

    const existingItem = CommonHelper.objectCloning(form.items);
    // const index = OrderHelper.finderIndexProductById(existingItem, productId);
    const index = existingItem.findIndex(item => {
      let ids;
      if (item.purchase_order_item_id) {
        ids = item.purchase_order_item_id === id;
      } else {
        ids = item.id === id;
      }
      return ids;
    });

    if (numberValidation) {
      const checkPrice = existingItem[index].regular_price || existingItem[index].normal_price;
      if (modifyValue > checkPrice) {
        modifyValue = checkPrice;
      }

      if (modifyValue === 0) {
        existingItem[index].is_complementary_product = true;
      }

      existingItem[index].price = modifyValue;
      modifyGrandTotal = OrderHelper.getGrandTotalFloatPrice(existingItem);
    }

    this.setState({
      form: { ...form, items: existingItem },
      grandTotal: modifyGrandTotal,
    });
  };

  handleTableChange = (pagination, filters, sorter) => {
    const { form, sort } = this.state;

    let modifyItem = CommonHelper.objectCloning(form.items);

    const finalValue = sorter.order === 'ascend' ? 'asc' : 'desc';
    modifyItem = CommonHelper.dataArrayOrder(modifyItem, sorter.field, finalValue);

    this.setState({
      form: { ...form, items: modifyItem },
      limit: pagination.pageSize,
      pagination: {
        ...this.state.pagination,
        current: pagination.current,
        pageSize: pagination.pageSize,
      },
      sort: { ...sort, sortField: sorter.field, sortOrder: sorter.order },
    });
  };

  handlePopUpInformation = (message, typeMessage) => {
    this.processMessage(message, typeMessage);
  };

  searchProductDebounce = () => {
    const { orderProductPagination } = this.props;
    const {
      filter: { search, orderType },
      sort: { sortField, sortOrder },
      pagination,
      limit,
      validation,
    } = this.state;

    if (search) {
      const param = {
        order_type: orderType,
        page: 1,
        search,
        limit: 50,
        sort: sortField,
        sortOrder: sortOrder === 'ascend' ? '' : 'desc',
      };

      orderProductPagination(param)
        .then(response => {
          const data = response.data;
          const message = CommonHelper.objectCloning(initialValidation.listItem);

          this.setState({ listItem: data });

          if (_.isEmpty(data)) {
            message.errorMessage =
              'Sorry, but there’s no product found matching your search criteria';
          }

          this.setState({
            pagination: { ...pagination, pageSize: limit, total: response.total },
            validation: { ...validation, listItem: message },
          });
        })

        .catch(error => {
          const serverMessage = error.data;
          const validationStatus = error.status === HttpStatusCode.InternalServerError;
          this.processMessage(
            validationStatus ? serverMessage.message : serverMessage.messages,
            'error',
          );
        });
    }
  };

  handleButtonNumberStepper = id => value => {
    const { form } = this.state;
    const existingItem = CommonHelper.objectCloning(form.items);
    // const index = existingItem.findIndex(item => item.id === id);
    const index = existingItem.findIndex(item => {
      let ids;
      if (item.purchase_order_item_id) {
        ids = item.purchase_order_item_id === id;
      } else {
        ids = item.id === id;
      }
      return ids;
    });
    const convertedValue = parseInt(value, 10);

    if (convertedValue >= 0) {
      if (convertedValue === 0 || value === '') {
        existingItem.splice(index, 1);
      } else {
        existingItem[index].qty = convertedValue;
      }
      const grandTotal = OrderHelper.getGrandTotalFloatPrice(existingItem);

      this.setState({
        form: { ...form, items: existingItem },
        grandTotal,
      });
    }
  };

  handleSetAsComplimentaryByProductId = e => {
    const isChecked = e.target.checked;
    const id = e.target.value;
    const { form } = this.state;
    const existingItem = CommonHelper.objectCloning(form.items);
    const index = existingItem.findIndex(item => {
      let ids;
      if (item.purchase_order_item_id) {
        ids = item.purchase_order_item_id.toString() === id;
      } else {
        ids = item.id.toString() === id;
      }
      return ids;
    });
    if (isChecked) {
      existingItem[index].is_complementary_product = true;
      existingItem[index].price = 0;
    } else {
      delete existingItem[index].is_complementary_product;
      existingItem[index].price =
        existingItem[index].normal_price || existingItem[index].regular_price;
    }
    const grandTotal = OrderHelper.getGrandTotalFloatPrice(existingItem);
    this.setState({
      form: { ...form, items: existingItem },
      grandTotal,
    });
  };

  handleButtonDeleted = data => {
    const { modalDetailDataDelete } = this.state;

    this.setState({
      isOpenModalDelete: true,
      selectedDataDelete: data,
      modalDetailDataDelete: {
        ...modalDetailDataDelete,
        body: `Are you sure want to Delete Product ${data.product_name}? `,
      },
    });
  };

  handleCloseModalDelete = () => {
    this.setState({
      isOpenModalDelete: false,
    });
  };

  handleButtonSubmitDelete = () => {
    const { selectedDataDelete, form } = this.state;
    const existingItem = CommonHelper.objectCloning(form.items);
    const index = existingItem.findIndex(item => {
      let ids;
      if (item.purchase_order_item_id) {
        ids = item.purchase_order_item_id === selectedDataDelete.purchase_order_item_id;
      } else {
        ids = item.id === selectedDataDelete.id;
      }
      return ids;
    });
    existingItem.splice(index, 1);
    const grandTotal = OrderHelper.getGrandTotalFloatPrice(existingItem);
    this.setState({
      form: { ...form, items: existingItem },
      grandTotal,
      isOpenModalDelete: false,
    });
  };

  handleButtonClickSubmit = () => {
    const { form, termOfPaymentAmount } = this.state;

    if (this.validationForm()) {
      const itemSubmit = OrderHelper.formatItemSubmitPurchaseOrder(form.items);
      const terms = OrderHelper.formatTermOfPaymentData(termOfPaymentAmount);

      const param = {
        purchase_order_no: form.poNumber,
        company_id: form.companyId,
        company_name: form.companyName,
        email: form.email,
        phone: form.phone,
        shipping_method: form.shippingMethod,
        shipping_cost: form.shippingFee,
        shipping_address_details: form.addressInfo,
        shipping_date: form.deliveryDate,
        shipping_notes: form.note,
        ppn_status: form.ppnInclude.value,
        items: itemSubmit,
        attachment: form.documents,
        update_reason: form.reason,
        term_of_payment: form.termOfPayment,
        terms,
      };

      if (form.purchaseOrderId) {
        this.setProcessUpdatePurchaseOrder(param);
      } else {
        this.setProcessCreatePurchaseOrder(param);
      }
    }
  };

  handleSelectChangeTermOfPayment = value => {
    const { form } = this.state;

    this.setState({
      form: {
        ...form,
        termOfPayment: value,
      },
      termOfPaymentAmount: {},
      terms: [],
    });
  };

  handleChangeAmountTermOfPayment = terms => {
    const { termOfPaymentAmount } = this.state;
    const currentTermOfPayment = { ...termOfPaymentAmount };
    const updateSelectedTop = {
      ...currentTermOfPayment,
      ...terms,
    };
    this.setState({ termOfPaymentAmount: updateSelectedTop });
  };

  handleCloseToash = () => {
    const { toastInformation } = this.state;
    this.setState({ toastInformation: { ...toastInformation, isOpen: false } });
  };

  validationForm() {
    const {
      form: {
        purchaseOrderId,
        poNumber,
        companyId,
        addressInfo,
        placeId,
        phone,
        email,
        deliveryDate,
        items,
        documents,
        note,
        shippingFee,
        ppnInclude,
        termOfPayment,
        reason,
      },
      validation,
      termOfPaymentAmount,
      grandTotal,
    } = this.state;

    const messageV1 = { isError: false, errorMessage: '' };
    const messageV2 = { isError: '', errorMessage: '' };

    let passCheck = false;
    let passCheckGroup01 = true;
    let passCheckGroup02 = true;
    let passCheckTop = true;
    let passCheckTopDate = true;

    const paramValidation01 = CommonHelper.objectCloning(messageV1);
    const paramValidation02 = CommonHelper.objectCloning(messageV1);
    const paramValidation03 = CommonHelper.objectCloning(messageV2);
    const paramValidation04 = CommonHelper.objectCloning(messageV1);
    const paramValidation05 = CommonHelper.objectCloning(messageV1);
    const paramValidation06 = CommonHelper.objectCloning(messageV2);
    const paramValidation08 = CommonHelper.objectCloning(messageV2);
    const paramValidation09 = CommonHelper.objectCloning(messageV2);

    paramValidation01.isError = !!(!poNumber || validation.poNumber.isError);
    paramValidation01.errorMessage =
      !poNumber || validation.poNumber.isError ? 'Please enter PO Number' : '';

    paramValidation02.isError = !companyId;
    paramValidation02.errorMessage = !companyId
      ? 'Please enter Company Name or Company not Valid'
      : '';

    paramValidation03.isError = _.isEmpty(addressInfo) && !placeId ? 'error' : '';
    paramValidation03.errorMessage =
      _.isEmpty(addressInfo) && !placeId ? 'Company Address not Valid' : '';

    passCheckGroup01 = CommonHelper.checkValidDate(deliveryDate);

    paramValidation04.isError = !phone;
    paramValidation04.errorMessage = !phone ? 'Please enter Phone Number' : '';

    paramValidation05.isError = !email;
    paramValidation05.errorMessage = !email ? 'Please enter Email' : '';

    paramValidation06.isError = _.isEmpty(documents) ? 'error' : '';
    paramValidation06.errorMessage = _.isEmpty(documents) ? 'Document is Empty' : '';

    paramValidation08.isError = !note ? 'error' : '';
    paramValidation08.errorMessage = !note ? 'Document is Empty' : '';

    paramValidation09.isError = purchaseOrderId && !reason ? 'error' : '';
    paramValidation09.errorMessage =
      purchaseOrderId && !reason ? 'Please provide reason for editing Purchase Order' : '';

    passCheckGroup02 = !_.isEmpty(items);

    if (termOfPayment !== 1) {
      if (_.isEmpty(termOfPaymentAmount)) {
        passCheckTopDate = false;
      } else if (Object.keys(termOfPaymentAmount).length !== termOfPayment) {
        passCheckTopDate = false;
      } else {
        const totalPPN = ppnInclude.value ? grandTotal * 0.11 : 0;
        const finalGrandTotal = totalPPN + grandTotal + parseInt(shippingFee, 10);
        const termsPaymentValid = OrderHelper.validateTermOfPayment(
          termOfPaymentAmount,
          finalGrandTotal,
        );
        passCheckTop = termsPaymentValid;
        passCheckTopDate = true;
      }
    }

    if (
      !paramValidation01.isError &&
      !paramValidation02.isError &&
      !paramValidation03.isError &&
      !paramValidation04.isError &&
      !paramValidation05.isError &&
      !paramValidation06.isError &&
      !paramValidation08.isError &&
      !paramValidation09.isError &&
      passCheckGroup01 &&
      passCheckGroup02 &&
      passCheckTop &&
      passCheckTopDate
    ) {
      passCheck = true;
    }

    this.setState(
      {
        validation: {
          ...validation,
          poNumber: paramValidation01,
          companyName: paramValidation02,
          companyAddress: paramValidation03,
          phone: paramValidation04,
          email: paramValidation05,
          documents: paramValidation06,
          note: paramValidation08,
          reason: paramValidation09,
        },
      },
      () => {
        let wordingAlert = '';
        let displayAlert = false;

        if (!passCheckGroup01) {
          wordingAlert += ' Date not Valid';
          displayAlert = true;
        }

        if (!passCheckGroup02) {
          wordingAlert += wordingAlert ? ', No Item Selected' : 'No Item Selected';
          displayAlert = true;
        }

        if (displayAlert) {
          this.processMessage(wordingAlert.trim(), 'warning');
        }

        if (!passCheckTopDate) {
          this.processMessage('Please input term of payment data', 'warning');
        }

        if (!passCheckTop) {
          this.processMessage('Term Of Payment total amount is not valid', 'warning');
        }
      },
    );

    return passCheck;
  }

  searchCompanyDebounce() {
    const { listCompanyPagination } = this.props;
    const { validation } = this.state;
    const { form, filter } = this.state;

    const param = {
      search: filter.searchCompanyName,
    };

    listCompanyPagination(param)
      .then(response => {
        const data = response.data;
        const modifyList = data;

        if (_.isEmpty(data)) {
          const paramList = {
            company_id: 'new',
            name: 'Create New Company',
            email: '',
            address: '',
          };
          modifyList.push(paramList);
        }

        this.setState(
          {
            listCompany: modifyList,
          },
          () => {
            const checkCreateCompany = modifyList.filter(item => item.value === 'new');
            const shouldUpdateAddress =
              form.purchaseOrderId && !_.isEmpty(modifyList) && _.isEmpty(checkCreateCompany);

            if (shouldUpdateAddress) {
              const selectedAddress = modifyList.find(x => x.company_id === form.companyId);
              if (!_.isEmpty(selectedAddress)) {
                this.handleSelectedItemName(selectedAddress.name, selectedAddress);
              }
            }
          },
        );
      })
      .catch(error => {
        const message = CommonHelper.objectCloning(initialValidation.name);
        const convertedMessage = CommonHelper.generateMessage(error.message);
        message.isError = true;
        message.errorMessage = convertedMessage;

        this.setState({ validation: { ...validation, name: message } });
      });
  }

  processMessage(messages, type) {
    const convertedMessage = CommonHelper.generateMessage(messages);

    const paramInformation = {
      isOpen: true,
      message: convertedMessage,
      snackbarType: type,
    };

    this.setState({
      toastInformation: paramInformation,
      isLoadingSubmit: false,
    });
  }

  renderModalDelete() {
    const { isOpenModalDelete } = this.state;
    const {
      orderData: { fetching },
    } = this.props;
    let elementRender = null;

    if (isOpenModalDelete) {
      elementRender = (
        <ModalAlertAction
          onButtonSubmit={this.handleButtonSubmitDelete}
          onCloseModal={this.handleCloseModalDelete}
          modalDetail={this.state.modalDetailDataDelete}
          modalType="danger"
          buttonSubmitText="Submit"
          openModal={isOpenModalDelete}
          isLoading={fetching}
        />
      );
    }

    return elementRender;
  }

  renderColumns = () => {
    const columns = [
      {
        title: 'Product Name',
        dataIndex: 'name',
        sorter: true,
        render: (text, row) => {
          return (
            <span className="container-item-cart flex-row">
              <img src={row.images} className="custom-image mr-16" alt="item_image" />
              <div className="flex-column">
                <label className="text-14">{row.name || row.product_name}</label>
                <label className="text-10 pt-2 item-info">Product ID: {row.product_id}</label>
              </div>
            </span>
          );
        },
      },
      {
        title: 'Normal Price',
        width: '14%',
        align: 'center',
        render: (text, row) => {
          const wordingNormalPrice = `${currencyCode.idr} ${CommonHelper.formatCurrency(
            row.regular_price || row.normal_price,
          )}`;
          return (
            <div className="container-item-cart">
              <label className="text-14">{wordingNormalPrice}</label>
            </div>
          );
        },
      },
      {
        title: 'Price',
        width: '15%',
        align: 'center',
        render: (text, row) => {
          return (
            <div className="container-item-cart">
              <TextInput
                onChange={this.handleTextChangeProductPrice(
                  row.purchase_order_item_id ? row.purchase_order_item_id : row.id,
                )}
                currentValue={row.price}
                isDisable={row.is_complementary_product}
              />
            </div>
          );
        },
      },
      {
        title: 'Quantity',
        width: '12%',
        align: 'center',
        render: (text, row) => {
          return (
            <TextInputNumber
              placeHolderText={'Qty'}
              currentValue={row.qty}
              onChange={this.handleButtonNumberStepper(
                row.purchase_order_item_id ? row.purchase_order_item_id : row.id,
              )}
            />
            // <TextInputNumberV02
            //   placeHolderText={'Qty'}
            //   currentValue={row.qty}
            //   onChange={this.handleButtonNumberStepper(row.product_id)}
            // />
          );
        },
      },
      {
        title: 'Set as Complimentary',
        width: '13%',
        align: 'center',
        render: (text, row) => {
          return (
            <div className="container-item-cart">
              <Checkbox
                color="primary"
                value={row.purchase_order_item_id ? row.purchase_order_item_id : row.id}
                checked={row.is_complementary_product ? row.is_complementary_product : false}
                onChange={this.handleSetAsComplimentaryByProductId}
              />
            </div>
          );
        },
      },
      {
        title: 'Subtotal',
        width: '15%',
        align: 'right',
        render: (text, row) => {
          // const totalAmount = parseInt(row.price, 10) * row.qty;
          const totalAmount = row.price * row.qty;

          return (
            <div className="container-item-cart price">
              <label className="text-14">{`${
                currencyCode.idr
              } ${CommonHelper.formatCurrencyWithDecimals(totalAmount.toFixed(2))}`}</label>
            </div>
          );
        },
      },
      {
        title: '',
        width: '3%',
        align: 'right',
        render: (text, row) => {
          return (
            <ButtonIconMain
              icon="ic-ffo-bin"
              type="negative"
              size="sm"
              onClick={() => this.handleButtonDeleted(row)}
            />
          );
        },
      },
    ];

    return columns;
  };

  renderListItem() {
    const { orderData } = this.props;
    const {
      validation,
      pagination,
      form,
      filter: { search },
      grandTotal,
      listItem,
    } = this.state;
    // const ListCustom = orderData.listItem;
    const ListSelectedItem = form.items;

    const disablePPn = form.ppnInclude.value === radioButtonTrueFalseOption[1].value;
    const totalPPN = !disablePPn ? grandTotal * 0.11 : 0;
    const finalGrandTotal = totalPPN + grandTotal + parseInt(form.shippingFee, 10);
    const wordingPPN = `${currencyCode.idr} ${CommonHelper.formatCurrencyWithDecimals(
      totalPPN.toFixed(2),
    )}`;
    // const wordingGrandTotal = `${currencyCode.idr} ${CommonHelper.formatCurrency(finalGrandTotal)}`;
    const wordingGrandTotal = `${currencyCode.idr} ${CommonHelper.formatCurrencyWithDecimals(
      finalGrandTotal.toFixed(2),
    )}`;

    const renderElement = (
      <Grid container direction="column" className="container-table-header mt-24">
        <Grid item lg={5} md={5}>
          <TextInputAutocomplete
            placeHolderText="Search product name or product SKU"
            size="md"
            onChange={this.handleChangeTextProduct}
            onSelect={this.handleSelectedItem}
            currentValue={search}
            isLoading={orderData.fetching}
            data={listItem}
            isCustomDisplay
            customDisplayClass="custom-simple-option-item"
            errorMessage={validation.listItem.errorMessage}
            isError={validation.listItem.isError}
          />
        </Grid>
        <Table
          columns={this.renderColumns()}
          rowKey={record => record.id}
          dataSource={ListSelectedItem}
          pagination={pagination}
          onChange={this.handleTableChange}
        />
        {!disablePPn && (
          <Grid item lg md className="pt-24">
            <Grid container spacing={3} justify="flex-end">
              <Grid item>
                <label className="text-14">PPN (11%)</label>
              </Grid>
              <Grid item>
                <label className="text-14">{wordingPPN}</label>
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid item lg md className="pt-24">
          <Grid container spacing={3} justify="flex-end">
            <Grid item>
              <label className="text-14 text-bold">Grand Total</label>
            </Grid>
            <Grid item>
              <label className="text-14 text-bold">{wordingGrandTotal}</label>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );

    return renderElement;
  }

  renderModalCreate() {
    const { isOpenModalCompanyCreate } = this.state;
    let elementRender = null;

    elementRender = (
      <ModalCompanyCreate
        isOpen={isOpenModalCompanyCreate}
        onModalClose={this.handleCloseModalCreate}
        onPopUpInformation={this.handlePopUpInformation}
      />
    );

    return isOpenModalCompanyCreate ? elementRender : null;
  }

  renderTermOfPaymentItem = () => {
    const { grandTotal, form, disablePPn, terms } = this.state;
    const totalPPN = !disablePPn ? grandTotal * 0.11 : 0;
    const finalGrandTotal = totalPPN + grandTotal + parseInt(form.shippingFee, 10);
    if (form.termOfPayment && finalGrandTotal) {
      return (
        <TermOfPaymentItem
          defaultTermValue={terms}
          onChangeAmount={this.handleChangeAmountTermOfPayment}
          selectedTerm={form.termOfPayment}
        />
      );
    }
    return null;
  };

  render() {
    const { form, validation, listCompany, toastInformation, filter, isLoadingSubmit } = this.state;
    const { masterDataMain, orderData } = this.props;
    const prevUrl = '/order/purchase-order';

    const showTermOfPaymentSection = form.termOfPayment && form.termOfPayment > 1;

    return (
      <div>
        <Helmet title="FITCO | Orders - Create Purchase Order" />
        <div className="container-page-purchase-order-add scroll-container">
          <div className="container-page-scrolling-area include-footer">
            <Grid container direction="column" className="flex-wrap-unset">
              <Grid item lg md className="section-page-header">
                <div className="breadcrumbs-section">
                  <Breadcrumbs aria-label="breadcrumb">
                    <Link
                      className="text-12"
                      color="inherit"
                      href={prevUrl}
                      onClick={event => {
                        this.handleClick(event, prevUrl);
                      }}
                    >
                      <i className="ic-ffi-orders container-icon-prefix size-16" />
                      Order
                    </Link>
                    <Link
                      className="text-12"
                      color="inherit"
                      href={prevUrl}
                      onClick={event => {
                        this.handleClick(event, prevUrl);
                      }}
                    >
                      Purchase Order
                    </Link>
                    <label className="text-12" color="inherit">
                      {form.purchaseOrderId ? 'Edit' : 'Create'}
                    </label>
                  </Breadcrumbs>
                </div>
              </Grid>
              <Grid item lg md className="section-page-body mb-24">
                <div className="container-main-card p-32 flex-column">
                  {form.purchaseOrderId ? (
                    <span className="flex-row mb-32">
                      <label className="text-14 text-rolling-stone mr-8">Purchase Order ID</label>
                      <div className="container-status">
                        <LabelStatusMain value={form.purchaseOrderId.toString()} type="complete" />
                      </div>
                    </span>
                  ) : (
                    <label className="text-14 text-rolling-stone mb-32">Purchase Order</label>
                  )}
                  <Grid container spacing={3}>
                    <Grid item lg={6} md={6}>
                      <Grid container spacing={2} direction="column">
                        <Grid item lg md>
                          <FormControl component="fieldset" fullWidth margin={'normal'}>
                            <LabelInput labelText="Purchase Order Number" isRequired />
                            <TextInput
                              placeHolderText="Enter PO Number"
                              onChange={this.handleTextChangePONumber}
                              currentValue={form.poNumber}
                              errorMessage={validation.poNumber.errorMessage}
                              isError={validation.poNumber.isError}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item lg md>
                          <FormControl component="fieldset" fullWidth margin={'normal'}>
                            <LabelInput labelText="Customer" isRequired />
                            <TextInputAutocomplete
                              size="md"
                              onChange={this.handleTextChangeName}
                              currentValue={filter.searchCompanyName}
                              placeholder="Enter Name"
                              onSelect={this.handleSelectedItemName}
                              isLoading={masterDataMain.fetching}
                              data={listCompany}
                              isCustomDisplay
                              customDisplayClass="custom-company-option-item"
                              isError={validation.companyName.isError}
                              errorMessage={validation.companyName.errorMessage}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item lg md>
                          <FormControl component="fieldset" fullWidth margin={'normal'}>
                            <LabelInput labelText="Shipping Address" isRequired />
                            <TextAreaMain
                              currentValue={form.companyAddress}
                              placeholder="Enter Address"
                              rows={5}
                              isDisable
                              validateStatus={validation.companyAddress.isError}
                              errorMessage={validation.companyAddress.errorMessage}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item lg md>
                          <FormControl component="fieldset" fullWidth margin={'normal'}>
                            <LabelInput labelText="Address Notes" isRequired />
                            <TextAreaMain
                              onChange={this.handleTextChangeAddressNote}
                              currentValue={form.note}
                              placeholder="Enter Address Notes"
                              rows={3}
                              maxLength={250}
                              validateStatus={validation.note.isError}
                              errorMessage={validation.note.errorMessage}
                            />
                          </FormControl>
                        </Grid>
                        {form.purchaseOrderId && (
                          <Grid item lg md>
                            <FormControl component="fieldset" fullWidth margin={'normal'}>
                              <LabelInput labelText="Reason to Edit" isRequired />
                              <TextAreaMain
                                onChange={this.handleTextChangeReasonToEdit}
                                currentValue={form.reason}
                                placeholder="Enter Reason"
                                rows={3}
                                maxLength={250}
                                validateStatus={validation.reason.isError}
                                errorMessage={validation.reason.errorMessage}
                              />
                            </FormControl>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                    <Grid item lg={6} md={6}>
                      <Grid container direction="column" spacing={2}>
                        <Grid item lg md>
                          <FormControl component="fieldset" fullWidth margin={'normal'}>
                            <LabelInput labelText="PIC Phone number" isRequired />
                            <TextInput
                              placeHolderText="Enter PIC Phone Number"
                              onChange={this.handleTextChangePhone}
                              currentValue={form.phone}
                              maxLength={14}
                              errorMessage={validation.phone.errorMessage}
                              isError={validation.phone.isError}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item lg md>
                          <FormControl component="fieldset" fullWidth margin={'normal'}>
                            <LabelInput labelText="PIC E-mail" isRequired />
                            <TextInput
                              placeHolderText="Enter PIC E-mail"
                              onChange={this.handleTextChangeEmail}
                              currentValue={form.email}
                              errorMessage={validation.email.errorMessage}
                              isError={validation.email.isError}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item lg md>
                          <FormControl component="fieldset" fullWidth margin={'normal'}>
                            <LabelInput labelText="Term Of Payment" isRequired />
                            <SelectInputMain
                              options={initialTermOfPaymentOption}
                              currentValue={form.termOfPayment}
                              onChange={this.handleSelectChangeTermOfPayment}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item lg md>
                          <FormControl component="fieldset" fullWidth margin={'normal'}>
                            <LabelInput labelText="Shipping Date" isRequired />
                            <PickerInputDate
                              customIcon="ic-ffo-date-pick"
                              dateFormat="dd/MM/yyyy"
                              minDate={startDateValue}
                              defaultValue={form.deliveryDate}
                              onChange={this.handleChangeDeliveryDate}
                              toolbar={false}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item lg md>
                          <FormControl component="fieldset" fullWidth margin={'normal'}>
                            <LabelInput labelText="Document" isRequired />
                            <UploadDocument
                              labelText="Upload Document (pdf/img/word)"
                              onFileChange={this.handleChangeFile}
                              fileType="pdf/img/doc"
                              size="md"
                              fileList={form.documents}
                              maxFiles={4}
                              errorMessage={validation.documents.errorMessage}
                              validateStatus={validation.documents.isError}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item lg md>
                          <FormControl component="fieldset" fullWidth margin={'normal'}>
                            <LabelInput labelText="Shipping Method" isRequired />
                            <SelectInputMain
                              options={shippingMethodOption}
                              currentValue={form.shippingMethod}
                              onChange={this.handleSelectChangeShippingMethod}
                              validateStatus={validation.shippingMethod.isError}
                              errorMessage={validation.shippingMethod.errorMessage}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item lg md>
                          <FormControl component="fieldset" fullWidth margin={'normal'}>
                            <LabelInput labelText="Shipping Fee" />
                            <TextInputPrePost
                              placeHolderText="Enter Fee"
                              onChange={this.handleTextChangeShippingFee}
                              currentValue={form.shippingFee}
                              customElementsBefore={currencyCode.idr}
                              errorMessage={validation.shippingFee.errorMessage}
                              validateStatus={validation.shippingFee.isError}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item lg md>
                          <FormControl component="fieldset" fullWidth margin={'normal'}>
                            <LabelInput labelText="Include PPN (11%)" />
                            <RadioInput
                              data={radioButtonTrueFalseOption}
                              onSelect={this.handleChangePPNStatus}
                              direction="row"
                              currentValue={form.ppnInclude}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item lg md>
                      {this.renderListItem()}
                    </Grid>
                    {showTermOfPaymentSection && this.renderTermOfPaymentItem()}
                  </Grid>
                </div>
              </Grid>
              <Grid item lg md className="section-page-footer" />
            </Grid>
          </div>
        </div>
        <div className="container-page-purchase-order-add-container-floating">
          <ButtonActionFooterV02
            loadingButtonSubmit={isLoadingSubmit || orderData.fetching}
            onButtonClickCancel={this.handleButtonClickCancel}
            onButtonClickSubmit={this.handleButtonClickSubmit}
          />
        </div>
        {this.renderModalCreate()}
        {this.renderModalDelete()}
        <SnackBarSimple
          open={toastInformation.isOpen}
          durationHide={2000}
          message={toastInformation.message}
          onClickClose={this.handleCloseToash}
          snackbarType={toastInformation.snackbarType}
          anchor={optionToash}
        />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  purchaseOrderDetails: id => getPurchaseOrderDetails(dispatch, id),
  listCompanyPagination: params => getListCompanyPagination(dispatch, params),
  orderProductPagination: params => getOrderProductPagination(dispatch, params),
  processCreatePurchaseOrder: params => setProcessCreatePurchaseOrder(dispatch, params),
  processUpdatePurchaseOrder: (id, params) => setProcessUpdatePurchaseOrder(dispatch, id, params),
});

const mapStateToProps = ({ masterDataMain, orderData }) => ({ masterDataMain, orderData });

PurchaseOrderAdd.propTypes = {
  checkUserAccessPermission: PropTypes.func,
  history: PropTypes.object,
  listCompanyPagination: PropTypes.func,
  masterDataMain: PropTypes.object,
  match: PropTypes.object,
  orderData: PropTypes.object,
  orderProductPagination: PropTypes.func,
  processCreatePurchaseOrder: PropTypes.func,
  processUpdatePurchaseOrder: PropTypes.func,
  purchaseOrderDetails: PropTypes.func,
};

const shell = compose(connect(mapStateToProps, mapDispatchToProps));
const core = compose(AuthenticationAccessPages);

export default shell(core(PurchaseOrderAdd));
