import { createMuiTheme } from '@material-ui/core/styles';
import palette from './Palette';

const fontRegular = {
  fontFamily: 'Montserrat',
  fontDisplay: 'swap',
  fontWeight: 400,
};
const fontMedium = {
  fontFamily: 'Montserrat',
  fontDisplay: 'swap',
  fontWeight: 600,
};
const fontBold = {
  fontFamily: 'Montserrat',
  fontDisplay: 'swap',
  fontWeight: 900,
};

const fonts = {
  fontRegular,
  fontMedium,
  fontBold,
};

const themes = createMuiTheme({
  zIndex: {
    appBar: 0,
    drawer: 1,
  },
  palette,
  fonts,
  drawerWidth: 250,
});

export default themes;
