/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import clsx from 'clsx';
// style
import './CalendarDetailEventStyle.scss';
// helper
import { CalendarHelper, CommonHelper } from '../../../../../../helpers/Index';
// component
import { ButtonMain } from '../../../../../../components/Index';

const currentDate = CommonHelper.currentDate('YYYY-MM-DD');

class CalendarDetailEvent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      intervalDate: '',
      timeLineHeaderData: [],
    };
  }

  componentDidMount() {
    const weekDay = CalendarHelper.getWeekDayByToday(currentDate);
    this.generateTimeLineHeader(weekDay);
  }

  componentWillUnmount() {}

  handleButtonLeftClick = () => {
    const currentDateIncrement = CommonHelper.intervalDate(
      this.state.intervalDate,
      'YYYY-MM-DD',
      -6,
      'day',
    );
    const weekDay = CalendarHelper.getWeekDayByToday(currentDateIncrement);
    this.generateTimeLineHeader(weekDay);
  };

  handleButtonRightClick = () => {
    const currentDateIncrement = CommonHelper.intervalDate(
      this.state.intervalDate,
      'YYYY-MM-DD',
      8,
      'day',
    );
    const weekDay = CalendarHelper.getWeekDayByToday(currentDateIncrement);
    this.generateTimeLineHeader(weekDay);
  };

  handleButtonTodayClick = () => {
    const weekDay = CalendarHelper.getWeekDayByToday(currentDate);
    this.generateTimeLineHeader(weekDay);
  };

  generateTimeLineHeader(weekDay) {
    const listHeaderTimeLine = CalendarHelper.generateListWeek(weekDay);
    const converted = CalendarHelper.generateCalenderHeaderData(listHeaderTimeLine);

    this.setState({
      intervalDate: weekDay.startWeek,
      timeLineHeaderData: converted,
    });
  }

  renderCalendarDate() {
    const { timeLineHeaderData } = this.state;

    return timeLineHeaderData ? (
      <Grid container>
        {timeLineHeaderData.map((item, index) => (
          <Grid key={index} item className="custom-grid-col">
            <div
              className={`calendar-item pv-10 ${clsx({
                'is-active': currentDate === item.value,
              })}`}
            >
              <label className="text-12 text-semi-bold mb-4">{item.day}</label>
              <label className="text-12 text-rolling-stone">{item.date}</label>
            </div>
          </Grid>
        ))}
      </Grid>
    ) : null;
  }

  renderCalendarCard(dataBody) {
    const { timeLineHeaderData } = this.state;

    return (
      <Grid container>
        {timeLineHeaderData.map((item, index) => (
          <Grid
            item
            key={index}
            className={`custom-grid-col calendar-container-body ${
              currentDate === item.value ? 'active' : ''
            }`}
          >
            <div className="scroll-container-invisible container-item-schedule">
              <Grid container direction="column" justify="center" alignItems="center">
                {this.renderScheduleCard(dataBody, item.value)}
              </Grid>
            </div>
          </Grid>
        ))}
      </Grid>
    );
  }

  renderTextTimeLineRange() {
    const { data } = this.props;
    let result = '';

    if (data) {
      if (data.length > 0) {
        result = CalendarHelper.getTimeLineRange(data[0].start, data[data.length - 1].start);
      }
    }

    return result;
  }

  renderSessionText() {
    const { data } = this.props;
    let totalSession = 0;
    let totalDay = 0;
    let sessionDay = 0;

    if (data) {
      if (data.length > 0) {
        const dataSession = CalendarHelper.countTotalSession(data);

        totalSession = dataSession.totalSession;
        totalDay = dataSession.totalDay;
        sessionDay = dataSession.sessionDay;
      }
    }

    return (
      <Grid item lg md className="container-footer-text">
        <div className="flex-row">
          <span className="flex-row">
            <label className="text-14">Total session / days : </label>
            <label className="text-14">{sessionDay}</label>
          </span>
          <span className="flex-row">
            <label className="text-14">Total of days : </label>
            <label className="text-14">{totalDay}</label>
          </span>
          <span className="flex-row">
            <label className="text-14">Total Sessions : </label>
            <label className="text-14">{totalSession}</label>
          </span>
        </div>
      </Grid>
    );
  }

  renderScheduleCard = (scheduleList, dateOnRender) => {
    let renderElement = null;
    const finalRenderTimeLine = [];

    if (scheduleList) {
      if (scheduleList.length > 0) {
        scheduleList.forEach(item => {
          if (CommonHelper.dateTimeParseNewFormat(item.start, 'YYYY-MM-DD') === dateOnRender) {
            finalRenderTimeLine.push(item);
          }
        });
      }
    } else {
      renderElement = <Grid item className="custom-grid-col" />;
    }

    if (finalRenderTimeLine.length > 0) {
      renderElement = finalRenderTimeLine.map((item, index) => (
        <Grid key={index} item lg spac>
          <label className="text-12">{item.title}</label>
        </Grid>
      ));
    } else {
      renderElement = <Grid item className="custom-grid-col" />;
    }

    return renderElement;
  };

  render() {
    const { data } = this.props;

    return (
      <Grid container direction="column" className="flex-wrap-unset">
        <Grid item lg md>
          <Grid
            container
            justify="flex-start"
            alignItems="center"
            className="container-header-action"
          >
            <Grid item lg md style={{ display: 'flex' }}>
              <label className="text-14 text">{this.renderTextTimeLineRange()}</label>
            </Grid>
            <Grid item lg={3} md={3}>
              <Grid container justify="flex-end">
                <Grid item lg md>
                  <ButtonMain
                    type="ghost"
                    size="md"
                    labelText="Today"
                    onClick={this.handleButtonTodayClick}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg md>
          <Grid container alignItems="center">
            <Grid item className="container-icon-calendar-header">
              <i
                className="ic-ffi-chevron-left container-icon-prefix size-24 left"
                onClick={this.handleButtonLeftClick}
              />
            </Grid>
            <Grid item lg md>
              {this.renderCalendarDate()}
            </Grid>
            <Grid item className="container-icon-calendar-header">
              <i
                className="ic-ffi-chevron-right container-icon-prefix size-24 right"
                onClick={this.handleButtonRightClick}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg md>
          {this.renderCalendarCard(data)}
        </Grid>
        {this.renderSessionText()}
      </Grid>
    );
  }
}

const mapDispatchToProps = () => ({});

const mapStateToProps = () => ({});

CalendarDetailEvent.propTypes = {
  data: PropTypes.array,
};

export default connect(mapStateToProps, mapDispatchToProps)(CalendarDetailEvent);
