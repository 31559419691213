import React from 'react';
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';
// Component
import { Main as MainLayout, Minimal as MinimalLayout, RouteWithLayout } from './layouts/Index';
// Pages
import {
  PageNotFound as PageNotFoundView,
  Maintenance as MaintenanceView,
  SignIn as SignInView,
  ForgotPassword as ForgotPasswordView,
  Home as HomeView,
  Dashboard as DashboardView,
  SchedulePages as SchedulePagesView,
  ScheduleAdd as ScheduleAddView,
  ScheduleBook as ScheduleBookView,
  UserPage as UserPageView,
  // UserAdd as UserAddView,
  UserEdit as UserEditView,
  UserDetails as UserDetailsView,
  RoleAndPermission as RoleAndPermissionView,
  TncSectionPage as TncSectionPageView,
  TncSectionAdd as TncSectionAddView,
  TncSectionEdit as TncSectionEditView,
  PopupScreenPage as PopupScreenPageView,
  PopupScreenAdd as PopupScreenAddView,
  PopupScreenEdit as PopupScreenEditView,
  MasterDataMainPage as MasterDataMainPageView,
  MerchantAdd as MerchantAddView,
  MerchantEdit as MerchantEditView,
  MerchantDetails as MerchantDetailsView,
  BranchAdd as BranchAddView,
  BranchEdit as BranchEditView,
  BranchDetails as BranchDetailsView,
  ProductCategoryPage as ProductCategoryPageView,
  ProductCategoryAdd as ProductCategoryAddView,
  ProductCategoryEdit as ProductCategoryEditView,
  ProductWholesalePage as ProductWholesalePageView,
  ConductPTPage as ConductPTPageView,
  ConductPTDetails as ConductPTDetailsView,
  UserFreezeMembership as UserFreezeMembershipView,
  MembershipRequestPage as MembershipRequestPageView,
  RequestDetails as MembershipRequestDetailsView,
  DailyCheckInPage as DailyCheckInPageView,
  SegmentPage as SegmentPageView,
  SegmentView as SegmentViewView,
  OrderMainPage as OrderMainPageView,
  OrderAdd as OrderAddView,
  OrderDetails as OrdersDetailsView,
  CampaignMainPage as CampaignMainPageView,
  CampaignAdd as CampaignAddView,
  OrderShipmentPage as OrderShipmentPageView,
  OrderSummaryPage as OrderSummaryPageView,
  ShippingDetails as ShippingDetailsView,
  FeedPage as FeedPageView,
  FeedAdd as FeedAddView,
  HandoverPage as HandoverPageView,
  HandoverDetails as HandoverDetailsView,
  HandoverCreate as HandoverCreateView,
  MembershipPurchaseMain as MembershipPurchasePageView,
  MembershipPurchaseAdd as MembershipPurchaseAddView,
  MembershipPurchaseDetails as MembershipPurchaseDetailsView,
  MembershipChangeRecurring as MembershipChangeRecurringView,
  InboxMainPage as InboxMainPageView,
  InboxAdd as InboxAddView,
  EatPage as EatPageView,
  EatAdd as EatAddView,
  EatDetails as EatDetailsView,
  MembershipDashboardPagination as MembershipDashboardPaginationView,
  PurchaseOrderMainPage as PurchaseOrderPageView,
  PurchaseOrderAdd as PurchaseOrderAddView,
  PurchaseOrderDetail as PurchaseOrderDetailView,
  PromoCodePage as PromoCodePageView,
  PromoCodeAdd as PromoCodeAddView,
  MealDeliveryMainPage as MealDeliveryMainPageView,
  MealDeliveryDetail as MealDeliveryDetailView,
  MealPlan as MealPlanView,
  MealPlanAdd as MealPlanAddView,
  PatientMealPlan as PatientMealPlanView,
  PatientMealPlanAdd as PatientMealPlanAddView,
  ClinicSchedulePages as ClinicSchedulePagesView,
  ClinicScheduleAdd as ClinicScheduleAddView,
  ClinicScheduleBook as ClinicScheduleBookView,
  AppointmentPages as AppointmentPagesView,
  AppointmentDetail as AppointmentDetailView,
  OpenGymGate as OpenGymGateView,
  LabMainPage as LabMainPageView,
  LabBook as LabBookView,
  LabDetails as LabDetailsView,
  UserRegister as UserRegisterView,
  HealthPassport as HealthPassportView,
  EventAdd as EventAddMainPageView,
  EventList as EventMainPageView,
  EventParticipantList as EventParticipantMainPageView,
  EventParticipantDetails as EventParticipantDetailsMainPageView,
  EventParticipantClaim as EventParticipantClaimMainPageView,
} from './views/Index';

const Routes = () => {
  return (
    <Router>
      <Switch>
        <RouteWithLayout
          component={MaintenanceView}
          exact
          layout={MinimalLayout}
          path="/maintenance"
        />
        <RouteWithLayout component={SignInView} exact layout={MinimalLayout} path="/sign-in" />
        <RouteWithLayout
          component={ForgotPasswordView}
          exact
          layout={MinimalLayout}
          path="/forgot-password"
        />
        <RouteWithLayout component={HomeView} exact layout={MainLayout} path="/" />
        <RouteWithLayout component={DashboardView} exact layout={MainLayout} path="/dashboard" />
        <RouteWithLayout
          component={SchedulePagesView}
          exact
          layout={MainLayout}
          path="/move/workout-schedule"
        />
        <RouteWithLayout
          component={ScheduleAddView}
          exact
          layout={MainLayout}
          path="/move/workout-schedule/add"
        />
        <RouteWithLayout
          component={ScheduleBookView}
          exact
          layout={MainLayout}
          path="/move/workout-schedule/book/:param_object"
        />
        <RouteWithLayout
          component={UserPageView}
          exact
          layout={MainLayout}
          path="/management/users"
        />
        {/* <RouteWithLayout
          component={UserAddView}
          exact
          layout={MainLayout}
          path="/management/users/add"
        /> */}
        <RouteWithLayout
          component={UserRegisterView}
          exact
          layout={MainLayout}
          path="/management/users/add"
        />
        <RouteWithLayout
          component={UserDetailsView}
          exact
          layout={MainLayout}
          path="/management/users/details/:user_id"
        />
        <RouteWithLayout
          component={UserEditView}
          exact
          layout={MainLayout}
          path="/management/users/edit/:user_id"
        />

        <RouteWithLayout
          component={UserFreezeMembershipView}
          exact
          layout={MainLayout}
          path="/management/users/freezemembership/:user_point_booster_id"
        />
        <RouteWithLayout
          component={RoleAndPermissionView}
          exact
          layout={MainLayout}
          path="/management/roles-permissions"
        />

        <RouteWithLayout
          component={TncSectionPageView}
          exact
          layout={MainLayout}
          path="/marketing/tnc-section"
        />

        <RouteWithLayout
          component={TncSectionAddView}
          exact
          layout={MainLayout}
          path="/marketing/tnc-section/add"
        />

        <RouteWithLayout
          component={TncSectionEditView}
          exact
          layout={MainLayout}
          path="/marketing/tnc-section/edit/:tnc_id"
        />

        <RouteWithLayout
          component={PopupScreenPageView}
          exact
          layout={MainLayout}
          path="/marketing/popup-screen"
        />
        <RouteWithLayout
          component={PopupScreenAddView}
          exact
          layout={MainLayout}
          path="/marketing/popup-screen/add"
        />
        <RouteWithLayout
          component={PopupScreenEditView}
          exact
          layout={MainLayout}
          path="/marketing/popup-screen/edit/:popup_screen_id"
        />

        <RouteWithLayout
          component={PromoCodePageView}
          exact
          layout={MainLayout}
          path="/marketing/promotion"
        />

        <RouteWithLayout
          component={PromoCodeAddView}
          exact
          layout={MainLayout}
          path="/marketing/promotion/create"
        />

        <RouteWithLayout
          component={PromoCodeAddView}
          exact
          layout={MainLayout}
          path="/marketing/promotion/Edit/:param_object"
        />

        <RouteWithLayout
          component={MasterDataMainPageView}
          exact
          layout={MainLayout}
          path="/master-data/main-data"
        />

        <RouteWithLayout
          component={MerchantAddView}
          exact
          layout={MainLayout}
          path="/master-data/main-data/merchant/add"
        />

        <RouteWithLayout
          component={MerchantEditView}
          exact
          layout={MainLayout}
          path="/master-data/main-data/merchant/edit/:merchant_id"
        />

        <RouteWithLayout
          component={MerchantDetailsView}
          exact
          layout={MainLayout}
          path="/master-data/main-data/merchant/details/:merchant_id"
        />

        <RouteWithLayout
          component={BranchAddView}
          exact
          layout={MainLayout}
          path="/master-data/main-data/branch/add"
        />

        <RouteWithLayout
          component={BranchEditView}
          exact
          layout={MainLayout}
          path="/master-data/main-data/branch/edit/:branch_id"
        />

        <RouteWithLayout
          component={BranchDetailsView}
          exact
          layout={MainLayout}
          path="/master-data/main-data/branch/details/:branch_id"
        />

        <RouteWithLayout
          component={ProductCategoryPageView}
          exact
          layout={MainLayout}
          path="/master-data/product-category"
        />

        <RouteWithLayout
          component={ProductCategoryAddView}
          exact
          layout={MainLayout}
          path="/master-data/product-category/add"
        />

        <RouteWithLayout
          component={ProductCategoryAddView}
          exact
          layout={MainLayout}
          path="/master-data/product-category/add/:parent_id"
        />

        <RouteWithLayout
          component={ProductCategoryEditView}
          exact
          layout={MainLayout}
          path="/master-data/product-category/edit/:category_id"
        />

        <RouteWithLayout
          component={ProductWholesalePageView}
          exact
          layout={MainLayout}
          path="/master-data/product-wholesale"
        />

        <RouteWithLayout
          component={ConductPTPageView}
          exact
          layout={MainLayout}
          path="/reports/conduct-pt"
        />
        <RouteWithLayout
          component={ConductPTDetailsView}
          exact
          layout={MainLayout}
          path="/reports/conduct-pt/details/:param_object"
        />

        <RouteWithLayout
          component={MembershipRequestPageView}
          exact
          layout={MainLayout}
          path="/membership/request"
        />

        <RouteWithLayout
          component={MembershipRequestDetailsView}
          exact
          layout={MainLayout}
          path="/membership/request/details/:request_id"
        />

        <RouteWithLayout
          component={DailyCheckInPageView}
          exact
          layout={MainLayout}
          path="/reports/daily-check-in"
        />

        <RouteWithLayout component={OrderMainPageView} exact layout={MainLayout} path="/orders" />

        <RouteWithLayout
          component={DailyCheckInPageView}
          exact
          layout={MainLayout}
          path="/reports/daily-check-in"
        />

        <RouteWithLayout
          component={OrdersDetailsView}
          exact
          layout={MainLayout}
          path="/orders/details/:sales_order_id"
        />

        <RouteWithLayout component={OrderAddView} exact layout={MainLayout} path="/order/add" />

        <RouteWithLayout
          component={CampaignMainPageView}
          exact
          layout={MainLayout}
          path="/marketing/campaign"
        />
        <RouteWithLayout
          component={CampaignAddView}
          exact
          layout={MainLayout}
          path="/marketing/campaign/add"
        />
        <RouteWithLayout
          component={CampaignAddView}
          exact
          layout={MainLayout}
          path="/marketing/campaign/edit/:campaign_id"
        />

        <RouteWithLayout
          component={InboxMainPageView}
          exact
          layout={MainLayout}
          path="/marketing/inbox"
        />
        <RouteWithLayout
          component={InboxAddView}
          exact
          layout={MainLayout}
          path="/marketing/inbox/add"
        />
        <RouteWithLayout
          component={InboxAddView}
          exact
          layout={MainLayout}
          path="/marketing/inbox/edit/:inbox_id"
        />

        <RouteWithLayout
          component={SegmentPageView}
          exact
          layout={MainLayout}
          path="/marketing/segment"
        />

        <RouteWithLayout
          component={SegmentViewView}
          exact
          layout={MainLayout}
          path="/marketing/segment/create"
        />

        <RouteWithLayout
          component={SegmentViewView}
          exact
          layout={MainLayout}
          path="/marketing/segment/edit/:segment_id"
        />

        <RouteWithLayout
          component={OrderShipmentPageView}
          exact
          layout={MainLayout}
          path="/order/shipment"
        />

        <RouteWithLayout
          component={OrderSummaryPageView}
          exact
          layout={MainLayout}
          path="/order/summary"
        />

        <RouteWithLayout
          component={ShippingDetailsView}
          exact
          layout={MainLayout}
          path="/order/shipment/details/:sales_invoice_id"
        />

        <RouteWithLayout
          component={HandoverPageView}
          exact
          layout={MainLayout}
          path="/order/handover"
        />

        <RouteWithLayout
          component={HandoverDetailsView}
          exact
          layout={MainLayout}
          path="/order/handover/details/:order_handover_id"
        />

        <RouteWithLayout
          component={HandoverCreateView}
          exact
          layout={MainLayout}
          path="/order/handover/create"
        />

        <RouteWithLayout
          component={PurchaseOrderPageView}
          exact
          layout={MainLayout}
          path="/order/purchase-order"
        />

        <RouteWithLayout
          component={PurchaseOrderDetailView}
          exact
          layout={MainLayout}
          path="/order/purchase-order/details/:param_object"
        />

        <RouteWithLayout
          component={FeedPageView}
          exact
          layout={MainLayout}
          path="/marketing/feed"
        />
        <RouteWithLayout
          component={FeedAddView}
          exact
          layout={MainLayout}
          path="/marketing/feed/add"
        />
        <RouteWithLayout
          component={FeedAddView}
          exact
          layout={MainLayout}
          path="marketing/feed/edit/:feed_id"
        />

        <RouteWithLayout
          component={MembershipPurchasePageView}
          exact
          layout={MainLayout}
          path="/membership/purchase"
        />

        <RouteWithLayout
          component={MembershipPurchaseAddView}
          exact
          layout={MainLayout}
          path="/membership/purchase/create"
        />

        <RouteWithLayout
          component={MembershipPurchaseAddView}
          exact
          layout={MainLayout}
          path="/membership/purchase/renewal/:param_object"
        />

        <RouteWithLayout
          component={MembershipPurchaseDetailsView}
          exact
          layout={MainLayout}
          path="/membership/purchase/details/:param_object"
        />

        <RouteWithLayout
          component={MembershipChangeRecurringView}
          exact
          layout={MainLayout}
          path="/membership/users/change-payment/:param_object"
        />

        <RouteWithLayout component={EatPageView} exact layout={MainLayout} path="/eat/food" />

        <RouteWithLayout
          component={MealDeliveryMainPageView}
          exact
          layout={MainLayout}
          path="/eat/meal-delivery"
        />

        <RouteWithLayout
          component={MealDeliveryDetailView}
          exact
          layout={MainLayout}
          path="/eat/meal-delivery/details/:param_object"
        />

        <RouteWithLayout component={EatAddView} exact layout={MainLayout} path="/eat/add" />

        <RouteWithLayout
          component={EatAddView}
          exact
          layout={MainLayout}
          path="/eat/edit/:param_object"
        />

        <RouteWithLayout
          component={EatDetailsView}
          exact
          layout={MainLayout}
          path="/eat/details/:param_object"
        />

        <RouteWithLayout
          component={PatientMealPlanView}
          exact
          layout={MainLayout}
          path="/eat/in-patient/patient/details/:param_object"
        />

        <RouteWithLayout
          component={PatientMealPlanAddView}
          exact
          layout={MainLayout}
          path="/eat/in-patient/meal/create/:param_object"
        />

        <RouteWithLayout
          component={MembershipDashboardPaginationView}
          exact
          layout={MainLayout}
          path="/dashboard/membership/list/:param_object"
        />

        <RouteWithLayout
          component={PurchaseOrderAddView}
          exact
          layout={MainLayout}
          path="/order/purchase-order/create"
        />

        <RouteWithLayout
          component={PurchaseOrderAddView}
          exact
          layout={MainLayout}
          path="/order/purchase-order/edit/:param_object"
        />

        <RouteWithLayout
          component={MealPlanView}
          exact
          layout={MainLayout}
          path="/eat/in-patient"
        />

        <RouteWithLayout
          component={MealPlanAddView}
          exact
          layout={MainLayout}
          path="/eat/in-patient/create"
        />

        <RouteWithLayout
          component={MealPlanAddView}
          exact
          layout={MainLayout}
          path="/eat/in-patient/edit/:param_object"
        />

        <RouteWithLayout
          component={ClinicSchedulePagesView}
          exact
          layout={MainLayout}
          path="/medic/clinic-schedule"
        />
        <RouteWithLayout
          component={ClinicScheduleAddView}
          exact
          layout={MainLayout}
          path="/medic/clinic-schedule/add"
        />
        <RouteWithLayout
          component={ClinicScheduleBookView}
          exact
          layout={MainLayout}
          path="/medic/clinic-schedule/book/:param_object"
        />
        <RouteWithLayout
          component={AppointmentPagesView}
          exact
          layout={MainLayout}
          path="/medic/consultation"
        />
        <RouteWithLayout
          component={AppointmentDetailView}
          exact
          layout={MainLayout}
          path="/medic/consultation/detail/:param_object"
        />

        <RouteWithLayout
          component={OpenGymGateView}
          exact
          layout={MainLayout}
          path="/misclellaneous/open-gym-gate"
        />

        <RouteWithLayout component={LabMainPageView} exact layout={MainLayout} path="/medic/lab" />
        <RouteWithLayout
          component={LabBookView}
          exact
          layout={MainLayout}
          path="/medic/lab/book/:param_object"
        />
        <RouteWithLayout
          component={LabDetailsView}
          exact
          layout={MainLayout}
          path="/medic/lab/details/:sales_order_id"
        />
        <RouteWithLayout
          component={HealthPassportView}
          exact
          layout={MainLayout}
          path="/medic/health-passport"
        />

        <RouteWithLayout
          component={EventMainPageView}
          exact
          layout={MainLayout}
          path="/event/list"
        />
        <RouteWithLayout
          component={EventAddMainPageView}
          exact
          layout={MainLayout}
          path="/event/add"
        />
        <RouteWithLayout
          component={EventAddMainPageView}
          exact
          layout={MainLayout}
          path="/event/edit/:param_object"
        />

        <RouteWithLayout
          component={EventParticipantMainPageView}
          exact
          layout={MainLayout}
          path="/event/participant"
        />
        <RouteWithLayout
          component={EventParticipantDetailsMainPageView}
          exact
          layout={MainLayout}
          path="/event/participant/details/:event_id"
        />
        <RouteWithLayout
          component={EventParticipantClaimMainPageView}
          exact
          layout={MainLayout}
          path="/event/participant/claim/:event_id"
        />

        <Route component={PageNotFoundView} />
      </Switch>
    </Router>
  );
};

export default Routes;
