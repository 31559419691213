import React from 'react';
import PropTypes from 'prop-types';
import { Grid, FormControl, FormLabel } from '@material-ui/core';
import _ from 'lodash';
// Style
import './CartItemMembershipStyle.scss';
// Component
import {
  GridRowTableFourColumn,
  GridRowTableOneColumn,
} from '../../../../../../../../../components/Index';
import { CommonHelper, OrderHelper, promoTypeCode } from '../../../../../../../../../helpers/Index';
// helper

const CartItemMembership = props => {
  const {
    form: { items, paymentMethod, paymentTerm, promoCode },
    isValidPromoCode,
    orderData: { promoCodeDetails },
  } = props;

  // discount
  const promoCodeInformation = `Promo Code ( ${promoCode} )`;
  const totalItem = paymentTerm;
  let grandTotal = OrderHelper.getGrandTotalTerm(items, paymentTerm);
  const checkDiscount = OrderHelper.getSpecialDiscount(
    items[0],
    paymentMethod,
    paymentTerm,
    grandTotal,
  );
  let discountAmount = 0;
  let discountAmountSpecial = 0;

  if (!_.isEmpty(checkDiscount)) {
    grandTotal = checkDiscount.afterDiscount;
    discountAmountSpecial = checkDiscount.amountDiscount;
  }

  if (isValidPromoCode) {
    grandTotal = promoCodeDetails.totalPriceAfterReward;
    discountAmount = promoCodeDetails.cashback_amount;
  }

  const showDiscount =
    isValidPromoCode && promoCodeDetails.promo_code_cashback_type_id === promoTypeCode.Discount;

  return (
    <FormControl
      component="fieldset"
      fullWidth
      margin="normal"
      className="container-order-cart-item"
    >
      <FormLabel component="label" className="text-16 filed-title">
        Order Summary
      </FormLabel>
      <GridRowTableFourColumn
        customElementColumnFirst={<label className="text-14 header-title">Product Name</label>}
        customElementColumnSecond={<label className="text-14 header-title">Price</label>}
        customElementColumnThird={<label className="text-14 header-title">Qty</label>}
        customElementColumnFourth={<label className="text-14 header-title">Total Price</label>}
        columnFirst={5}
        columnSecond={2}
        columnThird={2}
        columnFourth={3}
      />
      <div className="scroll-container">
        <Grid container direction="column">
          {items.map((item, index) => {
            const priceTotal = paymentTerm * parseInt(item.price, 10);
            const priceItem = CommonHelper.formatCurrency(item.price);

            return (
              <GridRowTableFourColumn
                key={index}
                customElementColumnFirst={
                  <label className="text-14 row-item-title">{item.name || item.product_name}</label>
                }
                customElementColumnSecond={<label className="text-14">IDR {priceItem}</label>}
                customElementColumnThird={<label className="text-14">{paymentTerm}</label>}
                customElementColumnFourth={
                  <label className="text-14">IDR {CommonHelper.formatCurrency(priceTotal)}</label>
                }
                columnFirst={5}
                columnSecond={2}
                columnThird={2}
                columnFourth={3}
              />
            );
          })}
        </Grid>
      </div>
      {showDiscount && (
        <GridRowTableOneColumn
          customElementInitial={
            <div className="flex-column">
              <label className="text-14 row-item-title">Discount</label>
              <label className="text-10 ">{promoCodeInformation}</label>
            </div>
          }
          customElementColumn={
            <label className="text-14 discount">
              IDR {CommonHelper.formatCurrency(discountAmount)}
            </label>
          }
          columnLeft={10}
          columnRight={2}
        />
      )}
      {discountAmountSpecial !== 0 && (
        <GridRowTableOneColumn
          customElementInitial={
            <div className="flex-column">
              <label className="text-14 row-item-title">Discount</label>
              <label className="text-10 ">Special Package</label>
            </div>
          }
          customElementColumn={
            <label className="text-14 discount">
              IDR {CommonHelper.formatCurrency(discountAmountSpecial)}
            </label>
          }
          columnLeft={10}
          columnRight={2}
        />
      )}
      <GridRowTableOneColumn
        customElementInitial={
          <label className="text-16 row-item-title">Total Item(s) : {totalItem}</label>
        }
        customElementColumn={
          <label className="text-16 row-item-title">
            Grand Total &nbsp; &nbsp; &nbsp; IDR {CommonHelper.formatCurrency(grandTotal)}
          </label>
        }
        columnLeft={7}
        columnRight={5}
      />
    </FormControl>
  );
};

CartItemMembership.propTypes = {
  form: PropTypes.object,
  isValidPromoCode: PropTypes.bool,
  orderData: PropTypes.object,
};

export default CartItemMembership;
