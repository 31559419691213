import { makeStyles } from '@material-ui/core/styles';

const StylesJs = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  isActiveButton: {
    background: theme.palette.matisse,
    color: theme.palette.white,
    '&:hover': {
      color: theme.palette.matisse,
    },
  },
}));

export default StylesJs;
