import { StyleSheet } from '@react-pdf/renderer';
import Themes from '../../../../../themes/Index';

const StylesJs = StyleSheet.create({
  containerBarcode: { height: 60 },
  containerLogo: { height: 20 },
  containerPage: { width: 630, backgroundColor: 'red', height: 20 },
  containerWrapping: {
    flexWrap: 'wrap',
  },
  labelTextTitle: {
    color: Themes.palette.rollingStone,
    fontSize: 12,
    textAlign: 'center',
    fontWeight: 'bold',
  },
  labelTextTitleRecipient: {
    color: Themes.palette.mineShaft,
    fontSize: 12,
    textAlign: 'justify',
    fontWeight: 'semibold',
  },
  labelText: {
    color: Themes.palette.mineShaft,
    fontSize: 14,
    textAlign: 'justify',
    fontWeight: 'normal',
  },
  alignItem: {
    alignItems: 'center',
  },
  justifyContent: {
    justifyContent: 'center',
  },
  mv8: {
    marginTop: 8,
    marginBottom: 8,
  },
  mv4: {
    marginTop: 4,
    marginBottom: 4,
  },
  mt32: {
    marginTop: 32,
  },
  pot20: {
    top: 20,
  },
  pot10: {
    top: 10,
  },
  mt8: {
    marginTop: 8,
  },
  mb4: {
    marginBottom: 4,
  },
  mb8: {
    marginBottom: 8,
  },
  mb12: {
    marginBottom: 12,
  },
  mb22: {
    marginBottom: 22,
  },
  mh49: {
    marginLeft: 49,
    marginRight: 49,
  },
  ml17: {
    marginLeft: 17,
  },
  ml54: {
    marginLeft: 54,
  },
  mr17: {
    marginRight: 17,
  },
  mr54: {
    marginRight: 54,
  },
  p12: {
    padding: 12,
  },
  section: { color: 'white', textAlign: 'center', margin: 30 },
  containerRow: {
    flexDirection: 'row',
  },
  containerColumn: {
    flexDirection: 'column',
  },
  dividerHr: {
    borderTop: 1,
    borderTopColor: Themes.palette.mysticLight,
  },
  textSize18: {
    fontSize: 18,
    color: Themes.palette.mineShaft,
  },
  textSize16: {
    fontSize: 16,
    color: Themes.palette.mineShaft,
  },
  textSize10: {
    fontSize: 10,
    color: Themes.palette.mineShaft,
  },
  fwBold: {
    fontWeight: 'bold',
  },
  containerDestination: {
    backgroundColor: Themes.palette.mysticLight,
  },
});

export default StylesJs;
