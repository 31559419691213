import { Grid } from '@material-ui/core';
import { Spin } from 'antd';
import React from 'react';
import Webcam from 'react-webcam';
import ButtonMain from '../button-main/ButtonMain';
import PropTypes from 'prop-types';
import './ScanFaceStyle.scss';

const ScanFace = ({
  isSubmitting,
  localStorageKey,
  onSubmitPhoto,
  type,
  onSetIdentityImage,
  onSetSelfieImage,
  onUploadFile,
}) => {
  const webcamRef = React.useRef(null);
  const [imgSrc, setImgSrc] = React.useState(null);
  const [base64, setBase64] = React.useState('');

  const captureImage = React.useCallback(() => {
    const image = webcamRef.current.getScreenshot();
    const reformatBase64 = image.replace('data:image/png;base64,', '');
    setImgSrc(image);
    setBase64(reformatBase64);
  }, [webcamRef, setImgSrc]);

  const recaptureImage = React.useCallback(() => {
    const image = null;
    setImgSrc(image);
  }, [setImgSrc]);

  const onUpdateImageState = (imageType, data) => {
    switch (imageType) {
      case 'identity':
        if (onSetIdentityImage) {
          onSetIdentityImage(data);
        }
        localStorage.setItem(`${localStorageKey}-formattedbase64`, JSON.stringify(base64));
        break;
      default:
        if (onSetSelfieImage) {
          onSetSelfieImage(data);
        }
        break;
    }
    localStorage.setItem(localStorageKey, JSON.stringify(imgSrc));
  };

  const uploadFileHandler = async file => {
    try {
      const body = {
        prefix_file_name: '',
        file_path: '/app/public/images/identity/',
        file,
      };
      const response = await onUploadFile(body);
      onUpdateImageState(type, response.data);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  const onSubmitHandler = () => {
    if (type) {
      onUpdateImageState(type, false);
      uploadFileHandler(base64);
    }

    setTimeout(() => {
      onSubmitPhoto(base64);
    }, 400);
  };

  const renderGuide = () => {
    switch (type) {
      case 'identity':
        return <div className="guide-border" />;

      default:
        return <div className="selfie-guide-border" />;
    }
  };

  return (
    <Grid container className="scan-face-container">
      <Grid item lg md>
        <div className="photo-container">
          {!imgSrc && <Webcam audio={false} ref={webcamRef} screenshotFormat="image/png" />}
          {imgSrc && <img src={imgSrc} alt="" />}
          <div className="guide-container">{renderGuide()}</div>
        </div>
        <div className="button-container">
          {!isSubmitting && (
            <>
              <ButtonMain
                labelText={imgSrc ? 'Retake Photo' : 'Capture Photo'}
                onClick={imgSrc ? recaptureImage : captureImage}
                type={imgSrc ? 'outlined-primary' : 'primary'}
                size="md"
              />
              {imgSrc && (
                <ButtonMain
                  labelText="Use Photo"
                  onClick={onSubmitHandler}
                  type="primary"
                  size="md"
                />
              )}
            </>
          )}
          {isSubmitting && (
            <div className="loading-container">
              <Spin />
            </div>
          )}
        </div>
      </Grid>
    </Grid>
  );
};

ScanFace.propTypes = {
  isSubmitting: PropTypes.bool,
  localStorageKey: PropTypes.string,
  onSetIdentityImage: PropTypes.func,
  onSetSelfieImage: PropTypes.func,
  onSubmitPhoto: PropTypes.func,
  onUploadFile: PropTypes.func,
  type: PropTypes.string,
};

export default ScanFace;
