import React from 'react';
import PropTypes from 'prop-types';
import { Page, Text, View, Document, Font } from '@react-pdf/renderer';
import _ from 'lodash';
// Style
import StylesJs from './FoodlistSummaryLabelStyle';
// assets
import { StyleJs as StyleJsGlobal } from '../../../../../assets/Index';
// Register font
Font.register({
  family: 'Roboto',
  fonts: [
    { src: './src/assets/fonts/montserrat/Montserrat-Regular.ttf' },
    { src: './src/assets/fonts/montserrat/Montserrat-Bold.ttf', fontWeight: 700 },
  ],
});

const FoodlistSummaryLabel = props => {
  const { data } = props;
  const dataDetails = !_.isEmpty(data) ? data : [];
  const chunkedData = _.chunk(dataDetails, 10);

  return (
    <Document>
      {chunkedData.map((page, pageIndex) => {
        return (
          <Page
            size={['14.5cm', '21.5cm']}
            orientation="landscape"
            key={pageIndex}
            style={[StylesJs.pageContainer]}
          >
            <View style={[StylesJs.row, StylesJs.cardWrapper]}>
              {page.map((item, index) => {
                const isLastIndex = index === page.length - 1;
                return (
                  <View
                    key={index}
                    style={[
                      StyleJsGlobal.pv8,
                      StyleJsGlobal.ph8,
                      StylesJs.cardContainer,
                      {
                        marginBottom: 4,
                        marginRight: index === 4 || isLastIndex ? 0 : 4,
                      },
                    ]}
                  >
                    <View style={[{ marginBottom: 8, paddingHorizontal: 12 }]}>
                      <View style={[StylesJs.containerTitle]}>
                        <Text
                          style={[
                            StylesJs.text,
                            StylesJs.fontBold,
                            {
                              textAlign: 'center',
                              textTransform: 'uppercase',
                              color: '#FFFFFF',
                              fontSize: 6,
                            },
                          ]}
                        >
                          {item.lunch_or_dinner}
                        </Text>
                      </View>
                    </View>
                    <Text style={[StylesJs.titleText, StyleJsGlobal.mb2]}>{item.foodlist}</Text>
                    <Text
                      style={[
                        StylesJs.titleText,
                        StyleJsGlobal.mb2,
                        { color: '#078776', fontSize: 5 },
                      ]}
                    >
                      {item.name}
                    </Text>
                    <Text style={[StylesJs.text, { color: '#000', fontSize: 4 }]}>
                      {item.description}
                    </Text>
                    <View style={[StylesJs.divider, { marginVertical: 4 }]} />
                    <Text
                      style={[
                        StylesJs.text,
                        {
                          fontWeight: 600,
                          paddingBottom: 4,
                          borderBottomWidth: 0.3,
                          borderBottomColor: '#C4C4C4',
                          fontSize: 4,
                        },
                      ]}
                    >
                      Amount Per Serving Average Calories Per Meal According to Nutrisurvey
                    </Text>
                    <View style={[StylesJs.listContainer]}>
                      <Text style={[StylesJs.text]}>Calories</Text>
                      <Text style={[StylesJs.text, { fontWeight: 600 }]}>{item.calories} kcal</Text>
                    </View>
                    <View style={[StylesJs.listContainer]}>
                      <Text style={[StylesJs.text]}>Protein</Text>
                      <Text style={[StylesJs.text, { fontWeight: 600 }]}>{item.protein} g</Text>
                    </View>
                    <View style={[StylesJs.listContainer]}>
                      <Text style={[StylesJs.text]}>Total Fat</Text>
                      <Text style={[StylesJs.text, { fontWeight: 600 }]}>{item.fat} g</Text>
                    </View>
                    <View style={[StylesJs.listContainer]}>
                      <Text style={[StylesJs.text]}>Carbohydrate</Text>
                      <Text style={[StylesJs.text, { fontWeight: 600 }]}>{item.carbs} g</Text>
                    </View>
                    <View style={[StylesJs.listContainer]}>
                      <Text style={[StylesJs.text]}>Fiber</Text>
                      <Text style={[StylesJs.text, { fontWeight: 600 }]}>{item.fibre} g</Text>
                    </View>
                  </View>
                );
              })}
            </View>
          </Page>
        );
      })}
    </Document>
  );
};

FoodlistSummaryLabel.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default FoodlistSummaryLabel;
