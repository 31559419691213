import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Grid, Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { withStyles } from '@material-ui/styles';
import { Helmet } from 'react-helmet';
import _ from 'lodash';
// Style
import './SignInStyle.scss';
import StylesJs from './SignInStyle';
// Assets
import { Images } from '../../../assets/Index';
// Component
import {
  TextInput,
  TextInputPassword,
  // ButtonTextUnderline,
  ButtonMain,
  AuthenticationAccessPages,
} from '../../../components/Index';
// helper
import { ValidationHelper, HttpStatusCode, UserHelper } from '../../../helpers/Index';
// api
import { userLogin } from '../../../services/api/UsersApi';
import { compose } from 'redux';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class SignIn extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      isErrorUsername: false,
      isErrorPassword: false,
      errorMessageUsername: '',
      errorMessagePassword: '',
      isLoading: false,
      isError: false,
      errorMessage: '',
      snackbarType: 'warning',
    };
  }

  componentWillUnmount() {}

  handleChangeUserName = email => {
    const emailValidation = ValidationHelper.validateEmail(email.trim());

    if (!emailValidation) {
      this.setState({
        email: '',
        isErrorUsername: true,
        errorMessageUsername: 'please check your email, and try again',
      });
    } else {
      this.setState({
        email: email.trim(),
        isErrorUsername: false,
        errorMessageUsername: '',
      });
    }
  };

  handleChangePassword = password => {
    const passwordValidation = ValidationHelper.validatePassword(password.trim());
    if (!passwordValidation) {
      this.setState({
        password: '',
        isErrorPassword: true,
        errorMessagePassword: 'please check your Password, and try again',
      });
    } else {
      this.setState({
        password: password.trim(),
        isErrorPassword: false,
        errorMessagePassword: '',
      });
    }
  };

  handleErrorSignIn = messages => {
    let errorMessage = '';
    if (messages[0].includes('email/phone')) {
      errorMessage = messages[0];
      this.setState({
        email: '',
        isErrorUsername: true,
        errorMessageUsername: errorMessage,
      });
    }
    if (messages[0].includes('password')) {
      errorMessage = messages[0];
      this.setState({
        password: '',
        isErrorPassword: true,
        errorMessagePassword: errorMessage,
      });
    }
    // if (messages.hasOwnProperty('errorMessage')) {
    //   this.setState({
    //     isError: true,
    //     errorMessage: messages.errorMessage,
    //   });
    // }
  };

  handleClickButtonSignIn = () => {
    const { logIn, history } = this.props;
    const { email, password, isErrorUsername, isErrorPassword } = this.state;
    const { handleErrorSignIn } = this;

    const params = {
      data: {
        username: email,
        password,
        app: 'pos',
      },
    };

    this.setState(
      {
        isLoading: true,
      },
      async () => {
        if (!isErrorUsername && !isErrorPassword && !_.isEmpty(email) && !_.isEmpty(password)) {
          logIn(params)
            .then(response => {
              if (response.length === 0) {
                handleErrorSignIn({ errorMessage: 'Invalid Username Or Password In Mock' });
                return;
              }
              const currentUsers = UserHelper.getCurrentUserInformation();
              history.push(currentUsers.callBackURL || '/');
            })
            .catch(error => {
              const { data } = error;
              const { messages } = data;
              this.setState({ isLoading: false });
              if (data.code === HttpStatusCode.Unauthorized) {
                handleErrorSignIn(messages);
                // this.setState({ isLoading: false, isError: true, errorMessage })
              }
            });
        } else {
          this.setState({ isLoading: false }, () => {
            this.handleChangeUserName(email);
            this.handleChangePassword(password);
          });
        }
      },
    );
  };

  handleClickButtonForgotPass = () => {};

  handleClose = () => {
    this.setState({ isError: false });
  };

  render() {
    const { classes } = this.props;
    const visibilityIcon = {
      normal: 'ic-ffo-hide-pass',
      hover: 'ic-ffo-show-pass',
    };
    const {
      isErrorUsername,
      isErrorPassword,
      errorMessageUsername,
      errorMessagePassword,
      isError,
      errorMessage,
      snackbarType,
    } = this.state;

    return (
      <div className={classes.mainImageContent}>
        <Helmet title="Fitco POS | Login" />
        <Snackbar open={isError} autoHideDuration={2000} onClose={this.handleClose}>
          <Alert onClose={this.handleClose} severity={snackbarType}>
            {errorMessage}
          </Alert>
        </Snackbar>
        <div className="container-form-sign-in-position">
          <Grid container direction="column" justify="center" alignItems="stretch">
            <Grid item md={7} sm={6} xs={12} className="container-title-and-sub">
              <img src={Images.fitcoLogo} alt="fitco-logo" />
              <h5>DASHBOARD</h5>
            </Grid>
            <Grid item md={7} sm={6} xs={12} className="form-filed-spacing">
              <TextInput
                placeHolderText={'Email'}
                iconPrefix={'ic-ffo-mail'}
                onChange={this.handleChangeUserName}
                isError={isErrorUsername}
                errorMessage={errorMessageUsername}
              />
            </Grid>
            <Grid item md={7} sm={6} xs={12} className="form-filed-spacing">
              <TextInputPassword
                placeHolderText={'Password'}
                iconPrefixStart={'ic-ffo-pass'}
                iconPrefixEnd={visibilityIcon}
                onEnter={this.handleClickButtonSignIn}
                onChange={this.handleChangePassword}
                isError={isErrorPassword}
                errorMessage={errorMessagePassword}
              />
            </Grid>
            <Grid item md={7} sm={6} xs={12} className="form-filed-spacing">
              <ButtonMain
                labelText={'Login'}
                onClick={this.handleClickButtonSignIn}
                size="xl"
                type="primary"
                isLoading={this.state.isLoading}
              />
            </Grid>
            {/* un comment at next enhancement */}
            {/* <Grid item md={7} sm={6} xs={12} className="form-filed-spacing">
              <ButtonMain
                labelText={'Forgot Password'}
                onClick={this.handleClickButtonForgotPass}
                size="xl"
                type="text"
              />
            </Grid> */}
          </Grid>
        </div>
        <div className="container-copy-right">
          <label className="text-12">&copy; 2020 The Fit Company. All right reserved</label>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  logIn: params => userLogin(dispatch, params),
});

const mapStateToProps = ({ userData }) => ({
  userData,
});

SignIn.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object,
  logIn: PropTypes.func,
};

const shell = compose(connect(mapStateToProps, mapDispatchToProps));
const core = compose(AuthenticationAccessPages, withStyles(StylesJs));

export default shell(core(SignIn));
