import React from 'react';
import PropTypes from 'prop-types';
// component
import { SelectInputSearchMain } from '../../Index';
// helpers
import { CommonHelper } from '../../../helpers/Index';

class SelectInputTime extends React.Component {
  handleSelectItem = value => {
    const { onChange } = this.props;
    onChange(value);
  };

  generateListDataDisplay() {
    const { includeAllData, data } = this.props;
    const converted = [];
    let param = [];
    const currentData = CommonHelper.renameKeyName(data, 'start_time', 'value');

    if (includeAllData) {
      param = { value: null, name: 'Select Time' };
      converted.push(param);
    }

    if (currentData.length > 0) {
      currentData.forEach(item => {
        param = { value: item.value, name: `${item.value} - ${item.end_time}` };
        converted.push(param);
      });
    }

    return converted;
  }

  render() {
    const {
      placeHolder,
      defaultValue,
      currentValue,
      errorMessage,
      validateStatus,
      size,
      disabled,
    } = this.props;

    const ListCustom = this.generateListDataDisplay();

    return (
      <SelectInputSearchMain
        options={ListCustom}
        onChange={item => {
          this.handleSelectItem(item);
        }}
        currentValue={currentValue}
        defaultValue={defaultValue}
        errorMessage={errorMessage}
        validateStatus={validateStatus}
        size={size}
        placeholder={placeHolder}
        disabled={disabled}
      />
    );
  }
}

SelectInputTime.propTypes = {
  currentValue: PropTypes.any,
  data: PropTypes.array,
  defaultValue: PropTypes.number,
  disabled: PropTypes.bool,
  errorMessage: PropTypes.string,
  includeAllData: PropTypes.bool,
  onChange: PropTypes.func,
  placeHolder: PropTypes.string,
  size: PropTypes.string,
  validateStatus: PropTypes.string,
};

SelectInputTime.defaultProps = {
  placeHolder: '',
};

export default SelectInputTime;
