import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import { useSpring, animated } from 'react-spring/web.cjs';
// style
import stylesJs from './ModalInformationPopUpStyle';
import './ModalInformationPopUpStyles.scss';

const Fade = React.forwardRef(function Fade(props, ref) {
  const { in: open, children, onEnter, onExited, ...other } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter();
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited();
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {children}
    </animated.div>
  );
});

const ModalInformationPopUp = props => {
  const { isOpen, customElementProps, onClose, size } = props;
  const classes = stylesJs();

  const [openState, setOpenState] = React.useState(isOpen || false);

  React.useEffect(() => {
    const updateState = async () => {
      setOpenState(isOpen);
    };
    updateState();
  }, [isOpen]);

  return (
    <Modal
      aria-labelledby="spring-modal-title"
      aria-describedby="spring-modal-description"
      className={classes.modal}
      open={openState}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openState}>
        <div className={`container-modal-information-pop-up ${classes.paper} ${size}`}>
          <div className="modal-scrollable-content">{customElementProps}</div>
        </div>
      </Fade>
    </Modal>
  );
};

ModalInformationPopUp.propTypes = {
  customElementProps: PropTypes.element,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  size: PropTypes.string,
};

ModalInformationPopUp.defaultProps = {
  size: 'small',
};

Fade.propTypes = {
  children: PropTypes.element,
  in: PropTypes.bool.isRequired,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
};

export default ModalInformationPopUp;
