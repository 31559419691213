import * as ScheduleAction from '../../redux/actions/ScheduleAction';
import Api from '../../middleware/FetchMiddleware';
import { baseUrl } from '../../environments/Environment';
import { HttpAction } from '../../helpers/Index';

export const getScheduleByDate = (dispatch, category, params, columNumber) => {
  dispatch(ScheduleAction.getScheduleDateRequest(columNumber));
  return new Promise((resolve, reject) => {
    Api.callApi(
      `${baseUrl}private/pos/move/workout-category/${category}/schedule`,
      HttpAction.Post,
      {},
      params,
    )
      .then(response => {
        dispatch(ScheduleAction.getScheduleDateSuccess(response, columNumber));
        return resolve(response);
      })
      .catch(e => {
        dispatch(ScheduleAction.getScheduleDateFail(e, columNumber));
        reject(e);
      });
  });
};

export const getScheduleByIdDetails = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    Api.callApi(`${baseUrl}private/pos/v2/schedule/details`, HttpAction.Get, params, {})
      .then(response => {
        return resolve(response.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

export const setScheduleByIdDetailsTrainer = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    Api.callApi(`${baseUrl}private/pos/v2/schedule/trainer`, HttpAction.Put, {}, params)
      .then(response => {
        return resolve(response);
      })
      .catch(e => {
        reject(e);
      });
  });
};

export const setScheduleByIdDetailsTrainerEms = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    Api.callApi(
      `${baseUrl}private/pos/v2/schedule/trainer/by/appointment`,
      HttpAction.Put,
      {},
      params,
    )
      .then(response => {
        return resolve(response);
      })
      .catch(e => {
        reject(e);
      });
  });
};

export const setScheduleSingle = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    Api.callApi(`${baseUrl}private/pos/v2/schedule`, HttpAction.Post, {}, params)
      .then(response => {
        return resolve(response);
      })
      .catch(e => {
        reject(e);
      });
  });
};

export const setScheduleBulk = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    Api.callApi(`${baseUrl}private/pos/v2/schedule/bulk`, HttpAction.Post, {}, params)
      .then(response => {
        return resolve(response);
      })
      .catch(e => {
        reject(e);
      });
  });
};

export const setScheduleDetailStatus = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    Api.callApi(`${baseUrl}private/pos/v2/schedule/detail/status`, HttpAction.Put, {}, params)
      .then(response => {
        return resolve(response);
      })
      .catch(e => {
        reject(e);
      });
  });
};

export const getScheduleUserDetailsByPhone = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(ScheduleAction.getScheduleUserDetailsByPhoneRequest());
    Api.callApi(`${baseUrl}private/pos/v2/orders/check-users-exists?`, HttpAction.Get, params, {})
      .then(response => {
        dispatch(ScheduleAction.getScheduleUserDetailsByPhoneSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(ScheduleAction.getScheduleUserDetailsByPhoneFail(e));
        reject(e);
      });
  });
};

export const getMembershipDetailsByBook = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    // dispatch(ScheduleAction.getMembershipDetailsByBookRequest());
    Api.callApi(
      `${baseUrl}private/mobile/user/membership/average-price`,
      HttpAction.Get,
      params,
      {},
    )
      .then(response => {
        // dispatch(ScheduleAction.getMembershipDetailsByBookSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        // dispatch(ScheduleAction.getMembershipDetailsByBookFail(e));
        reject(e);
      });
  });
};

export const setProcessCreateOrderBooking = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(ScheduleAction.setProcessCreateOrderBookingRequest());
    Api.callApi(`${baseUrl}private/pos/v2/orders/process`, HttpAction.Post, {}, params)
      .then(response => {
        dispatch(ScheduleAction.setProcessCreateOrderBookingSuccess());
        return resolve(response);
      })
      .catch(e => {
        dispatch(ScheduleAction.setProcessCreateOrderBookingFail(e));
        reject(e);
      });
  });
};

export const setProcessCancelOrderBooking = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    Api.callApi(`${baseUrl}private/pos/v2/appointment/cancel/admin`, HttpAction.Post, {}, params)
      .then(response => {
        return resolve(response);
      })
      .catch(e => {
        reject(e);
      });
  });
};

export const setProcessCompleteOrderBookingClass = (dispatch, branchID, params) => {
  return new Promise((resolve, reject) => {
    Api.callApi(
      `${baseUrl}appointment/attendance/branch/${branchID}`,
      HttpAction.Post,
      null,
      params,
    )
      .then(response => {
        return resolve(response);
      })
      .catch(e => {
        reject(e);
      });
  });
};

export const setProcessCompleteOrderBookingCoach = (dispatch, trainerUserId, params) => {
  return new Promise((resolve, reject) => {
    Api.callApi(
      `${baseUrl}appointment/attendance/trainer/${trainerUserId}`,
      HttpAction.Post,
      null,
      params,
    )
      .then(response => {
        return resolve(response);
      })
      .catch(e => {
        reject(e);
      });
  });
};

export const getConsultationScheduleByDate = (dispatch, params, columNumber) => {
  dispatch(ScheduleAction.getConsultationScheduleDateRequest(columNumber));
  return new Promise((resolve, reject) => {
    Api.callApi(`${baseUrl}private/pos/medic/consultation/schedules`, HttpAction.Post, {}, params)
      .then(response => {
        dispatch(ScheduleAction.getConsultationScheduleDateSuccess(response, columNumber));
        return resolve(response);
      })
      .catch(e => {
        dispatch(ScheduleAction.getConsultationScheduleDateFail(e, columNumber));
        reject(e);
      });
  });
};

export const checkMedicConsultation = (dispatch, params) => {
  dispatch(ScheduleAction.checkMedicConsultationRequest());
  return new Promise((resolve, reject) => {
    Api.callApi(`${baseUrl}public/mobile/medic/consultation/check`, HttpAction.Post, {}, params)
      .then(response => {
        dispatch(ScheduleAction.checkMedicConsultationSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(ScheduleAction.checkMedicConsultationFail(e));
        reject(e);
      });
  });
};

export const getBookingAdminFee = dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(ScheduleAction.getBookingAdminFeeRequest());
    Api.callApi(`${baseUrl}public/mobile/medic/consultation/admin-fee`, HttpAction.Get, {}, {})
      .then(response => {
        dispatch(ScheduleAction.getBookingAdminFeeSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(ScheduleAction.getBookingAdminFeeFail(e));
        reject(e);
      });
  });
};

export const setRescheduleWorkout = (dispatch, appointmentId, params) => {
  return new Promise((resolve, reject) => {
    dispatch(ScheduleAction.setRescheduleWorkoutRequest());
    Api.callApi(
      `${baseUrl}/private/pos/move/appointment/${appointmentId}/reschedule`,
      HttpAction.Patch,
      {},
      params,
    )
      .then(response => {
        dispatch(ScheduleAction.setRescheduleWorkoutSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(ScheduleAction.setRescheduleWorkoutFail(e));
        reject(e);
      });
  });
};

export const getAvailableScheduleHourByDate = (dispatch, trainerId, params) => {
  return new Promise((resolve, reject) => {
    dispatch(ScheduleAction.getAvailableHourByDateRequest());
    Api.callApi(
      `${baseUrl}coach/on-demand/${trainerId}/schedule/by/services`,
      HttpAction.Get,
      params,
      {},
    )
      .then(response => {
        dispatch(ScheduleAction.getAvailableHourByDateSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(ScheduleAction.getAvailableHourByDateFail(e));
        reject(e);
      });
  });
};

export const rescheduleTime = (dispatch, appointmentId, params) => {
  return new Promise((resolve, reject) => {
    dispatch(ScheduleAction.rescheduleTimeRequest());
    Api.callApi(
      `${baseUrl}private/pos/move/appointment/${appointmentId}/reschedule`,
      HttpAction.Patch,
      {},
      params,
    )
      .then(response => {
        dispatch(ScheduleAction.rescheduleTimeSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(ScheduleAction.rescheduleTimeFail(e));
        reject(e);
      });
  });
};

export const getClassScheduleByDate = (dispatch, category, params) => {
  dispatch(ScheduleAction.getAvailableClassHourByDateRequest());
  return new Promise((resolve, reject) => {
    Api.callApi(
      `${baseUrl}private/pos/move/workout-category/${category}/schedule`,
      HttpAction.Post,
      {},
      params,
    )
      .then(response => {
        dispatch(ScheduleAction.getAvailableClassHourByDateSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(ScheduleAction.getAvailableClassHourByDateFail(e));
        reject(e);
      });
  });
};

export const getConsultation = (dispatch, body) => {
  dispatch(ScheduleAction.getConsultationRequest());
  return new Promise((resolve, reject) => {
    Api.callApi(`${baseUrl}private/pos/medic/consultation`, HttpAction.Post, {}, body)
      .then(response => {
        dispatch(ScheduleAction.getConsultationSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(ScheduleAction.getConsultationFail(e));
        reject(e);
      });
  });
};

export const getAvailableClassSchedule = (dispatch, productId, params) => {
  return new Promise((resolve, reject) => {
    dispatch(ScheduleAction.getAvailableClassScheduleRequest());
    Api.callApi(`${baseUrl}training/schedule/list/product/${productId}`, HttpAction.Get, params, {})
      .then(response => {
        dispatch(ScheduleAction.getAvailableClassScheduleSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(ScheduleAction.getAvailableClassScheduleFail(e));
        reject(e);
      });
  });
};

export const cancelConsultationSchedule = (dispatch, salesOrderId) => {
  return new Promise((resolve, reject) => {
    dispatch(ScheduleAction.cancelConsultationScheduleRequest());
    Api.callApi(
      `${baseUrl}private/pos/medic/consultation/${salesOrderId}/cancel`,
      HttpAction.Post,
      {},
      {},
    )
      .then(response => {
        dispatch(ScheduleAction.cancelConsultationScheduleSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(ScheduleAction.cancelConsultationScheduleFail(e));
        reject(e);
      });
  });
};

export const rescheduleConsultation = (dispatch, salesOrderId, body) => {
  return new Promise((resolve, reject) => {
    dispatch(ScheduleAction.rescheduleConsultationRequest());
    Api.callApi(
      `${baseUrl}private/pos/medic/consultation/${salesOrderId}/reschedule`,
      HttpAction.Post,
      {},
      body,
    )
      .then(response => {
        dispatch(ScheduleAction.rescheduleConsultationSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(ScheduleAction.rescheduleConsultationFail(e));
        reject(e);
      });
  });
};

export const setMedicTrainer = (dispatch, salesOrderId, params) => {
  return new Promise((resolve, reject) => {
    Api.callApi(
      `${baseUrl}private/pos/medic/consultation/${salesOrderId}/doctor`,
      HttpAction.Patch,
      {},
      params,
    )
      .then(response => {
        return resolve(response);
      })
      .catch(e => {
        reject(e);
      });
  });
};

export const getLabSchedule = (dispatch, body) => {
  return new Promise((resolve, reject) => {
    dispatch(ScheduleAction.getLabScheduleRequest());
    Api.callApi(`${baseUrl}public/mobile/medic/covid-test/schedule`, HttpAction.Post, {}, body)
      .then(response => {
        dispatch(ScheduleAction.getLabScheduleSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(ScheduleAction.getLabScheduleFail(e));
        reject(e);
      });
  });
};
