/* eslint-disable no-unused-vars */
/* eslint-disable react/no-access-state-in-setstate */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Table } from 'antd';
import _ from 'lodash';
import { Grid, IconButton } from '@material-ui/core';
import { compose } from 'redux';
import clsx from 'clsx';
// Component
import {
  SnackBarSimple,
  SelectInputMain,
  ButtonMain,
  PrevStateValue,
  GeneratorExcel,
  AuthenticationAccessPages,
  TextInput,
  ButtonMultipleMain,
  LabelStatusMain,
  ModalAlertAction,
  SkeletonMain,
} from '../../../components/Index';
import { ModalEatMenuQrCode } from '../components/Index';
// Api
import {
  getListEatPagination,
  setDeleteEat,
  getListEatReportExport,
} from '../../../services/api/EatApi';
// Helpers
import {
  CommonHelper,
  MasterDataHelper,
  eatActionCode,
  HttpStatusCode,
  GlobalCodeStatus,
  PrintHelper,
  PermissionModule,
  PermissionPage,
  PermissionAccess,
  PermissionHelper
} from '../../../helpers/Index';
// Style
import './EatPageStyle.scss';
import { Images } from '../../../assets/Index';

const pickUpOption = MasterDataHelper.pickUpOption;
const statusOption = MasterDataHelper.statusGlobal.filter(
  item => item.value === GlobalCodeStatus.Active || item.value === GlobalCodeStatus.Inactive,
);
const eatActionOptions = [];

const hasEditPermission = PermissionHelper.checkUserHasPermission(PermissionModule.Eat, PermissionPage.Food, PermissionAccess.Update);
if(hasEditPermission) {
  eatActionOptions.push(MasterDataHelper.eatActionOptions.find(x => x.value === 'edit'));
}

const hasDeletePermission = PermissionHelper.checkUserHasPermission(PermissionModule.Eat, PermissionPage.Food, PermissionAccess.Delete);
if(hasDeletePermission) {
  eatActionOptions.push(MasterDataHelper.eatActionOptions.find(x => x.value === 'delete'));
}

const initialSort = {
  sortField: 'product_name',
  sortOrder: 'asc',
};

const optionToash = {
  vertical: 'top',
  horizontal: 'right',
};

const initialFilter = {
  search: '',
  pickUp: null,
  status: statusOption[0].value,
};

class EatPage extends React.Component {
  constructor(props) {
    super(props);
    const {
      usersReducer: { prevStateValue },
    } = props;

    const updateExitingFilter = {
      ...CommonHelper.objectCloning(initialFilter),
      ...prevStateValue,
    };

    this.state = {
      filter: updateExitingFilter,
      page: 1,
      limit: 10,
      sort: CommonHelper.objectCloning(initialSort),
      pagination: {
        showSizeChanger: true,
        showTotal: (total, range) => `Showing ${range[0]}-${range[1]} from ${total}`,
        pageSizeOptions: ['10', '20', '30', '40'],
      },
      loading: true,
      isLoading: true,
      toastInformation: {
        isOpen: false,
        message: '',
        snackbarType: 'warning',
      },
      selectedRowKeys: [],
      selectedRowPrint: [],
      selectedPrint: [],
      isOpenModalPrint: false,
      isOpenModalDelete: false,
      selectedDeleteId: null,
      modalDetailData: {
        title: 'Confirmation',
        body: 'Are you sure want to delete',
      },
    };
    this.searchDebounce = _.debounce(this.searchDebounce, 400);
  }

  componentDidMount() {
    const {
      onResetPrevStateValue,
      history: { location },
    } = this.props;

    this.getListEatPagination();

    onResetPrevStateValue({ url: location.pathname });
  }

  getListEatPagination = () => {
    const { listEatPagination } = this.props;
    const { limit, page, pagination, sort, filter } = this.state;

    const param = {
      page,
      limit,
      sort: sort.sortField,
      sortOrder: sort.sortOrder,
      search: filter.search,
      is_pickup_at_store: filter.pickUp,
      status: filter.status,
    };

    this.setState({ loading: true }, () => {
      listEatPagination(param).then(response => {
        this.setState({
          loading: false,
          isLoading: false,
          pagination: { ...pagination, pageSize: limit, total: response.data.total },
        });
      });
    });
  };

  handleChangeSearch = value => {
    const { filter } = this.state;

    this.setState(
      {
        filter: { ...filter, search: value },
        page: 1,
        pagination: { ...this.state.pagination, current: 1 },
      },
      () => {
        this.searchDebounce();
      },
    );
  };

  searchDebounce = () => {
    this.getListEatPagination();
  };

  handleSelectChangePickUp = value => {
    const { filter } = this.state;
    this.setState(
      {
        filter: {
          ...filter,
          pickUp: value,
          page: 1,
          pagination: { ...this.state.pagination, current: 1 },
        },
      },
      () => {
        this.getListEatPagination();
      },
    );
  };

  handleSelectChangeStatus = value => {
    const { filter } = this.state;
    this.setState(
      {
        filter: {
          ...filter,
          status: value,
          page: 1,
          pagination: { ...this.state.pagination, current: 1 },
        },
      },
      () => {
        this.getListEatPagination();
      },
    );
  };

  handleButtonSubmitDelete = () => {
    const { deleteEat } = this.props;
    const { selectedDeleteId } = this.state;

    deleteEat(selectedDeleteId)
      .then(response => {
        const message = response.messages;
        this.processMessage(message, 'success');
        this.handleCloseModalDelete();
        this.getListEatPagination();
      })
      .catch(async error => {
        const serverMessage = error.data;
        const validationStatus = error.status === HttpStatusCode.InternalServerError;
        this.processMessage(
          validationStatus ? serverMessage.message : serverMessage.messages,
          'error',
        );
      });
  };

  handleCloseModalDelete = () => {
    this.setState({
      isOpenModalDelete: false,
    });
  };

  handleButtonDownload = () => {
    const {
      filter: { search, pickUp, status },
    } = this.state;
    const {
      listEatReportExport,
      eatData: { fetchingExport },
    } = this.props;

    const params = {
      search,
      is_pickup_at_store: pickUp,
      status,
    };

    if (!fetchingExport) {
      listEatReportExport(params);
    }
  };

  handleCloseToash = () => {
    const { toastInformation } = this.state;
    this.setState({ toastInformation: { ...toastInformation, isOpen: false } });
  };

  handleButtonCreate = () => {
    const { history } = this.props;
    history.push('/eat/add');
  };

  handleTableChange = (pagination, filters, sorter) => {
    const { sort } = this.state;

    this.setState(
      {
        limit: pagination.pageSize,
        pagination: {
          ...this.state.pagination,
          current: pagination.current,
          pageSize: pagination.pageSize,
        },
        sort: {
          ...sort,
          sortField: sorter.field || initialSort.sortField,
          sortOrder: sorter.order === 'ascend' || !sorter.order ? 'asc' : 'desc',
        },
        page: pagination.current,
      },
      () => {
        this.getListEatPagination();
      },
    );
  };

  handleSelectChangeRow = selectedRowKeys => {
    const {
      eatData: { list },
    } = this.props;
    const generatedData = PrintHelper.generateDataPrintMenuEat(selectedRowKeys, list);
    this.setState({ selectedRowKeys, selectedRowPrint: generatedData });
  };

  handleButtonClickCancel = () => {
    this.setState({
      selectedRowKeys: [],
      selectedRowPrint: [],
    });
  };

  handleButtonClickPrint = () => {
    this.setState({
      isOpenModalPrint: true,
    });
  };

  handleButtonClickShowQr = key => {
    const {
      eatData: { list },
    } = this.props;
    const generatedData = PrintHelper.generateDataPrintMenuEat([key], list);
    
    this.setState({
      isOpenModalPrint: true,
      selectedPrint: generatedData,
    });
  };

  handleCloseModalPrint = () => {
    this.setState({
      isOpenModalPrint: false,
    });
  };

  handleClickRow = value => {
    const { history, onSetPrevStateValue } = this.props;
    const { filter } = this.state;
    const param = CommonHelper.encryptObject({ foodId: value });

    onSetPrevStateValue({ ...filter });
    history.push(`/eat/details/${param}`);
  };

  handleClickButtonAction = (value, item) => {
    const { modalDetailData, filter } = this.state;
    const { history, onSetPrevStateValue } = this.props;
    let paramEncrypt = '';

    switch (value) {
      case eatActionCode.Delete:
        this.setState({
          isOpenModalDelete: true,
          selectedDeleteId: item.food_id,
          modalDetailData: {
            ...modalDetailData,
            body: `Are you sure want to delete ${item.product_name} ?`,
          },
        });
        break;
      default:
        paramEncrypt = CommonHelper.encryptObject({ foodId: item.food_id });

        onSetPrevStateValue({ ...filter });
        history.push(`/eat/edit/${paramEncrypt}`);
        break;
    }
  };

  processMessage(messages, type) {
    const convertedMessage = CommonHelper.generateMessage(messages);

    const paramInformation = {
      isOpen: true,
      message: convertedMessage,
      snackbarType: type,
    };

    this.setState({
      toastInformation: paramInformation,
    });
  }

  renderStatus = value => {
    let renderElement = <LabelStatusMain value="Active" type="complete" />;
    if (value === GlobalCodeStatus.Deleted) {
      renderElement = <LabelStatusMain value="Deleted" type="danger" />;
    } else if (value === GlobalCodeStatus.Inactive) {
      renderElement = <LabelStatusMain value="Inactive" type="default" />;
    }
    return <div className="container-status">{renderElement}</div>;
  };

  renderColumns = () => {
    const { selectedRowKeys } = this.state;

    return [
      {
        title: 'Name',
        dataIndex: 'product_name',
        sorter: true,
        render: (text, row) => {
          const image = !_.isEmpty(row.product_images)
            ? row.product_images[0].image_url
            : Images.imageEatNotFound;

          return (
            <Grid
              container
              justify="space-between"
              spacing={2}
              className="container-row-eat pv-16 flex-wrap-unset"
              onClick={() => this.handleClickRow(row.food_id)}
            >
              <Grid item lg md>
                <img src={image} className="custom-image mr-16" alt="item_image" />
              </Grid>
              <Grid item lg={10} md={10}>
                <div className="flex-column">
                  <span className="flex-row">
                    <label className="text-16 text-bold wrapping-container second line wrapping-container-break mb-8 mr-8">
                      {row.product_name}
                    </label>
                    {this.renderStatus(row.status)}
                  </span>
                  <label className="text-10 text-semi-bold text-rolling-stone mb-4">{`Product ID : ${row.product_id}`}</label>
                  <span className="flex-row">
                    <label className="text-10 text-bold text-rolling-stone">{`${row.calories} Cals`}</label>
                  </span>
                </div>
              </Grid>
            </Grid>
          );
        },
      },
      {
        title: 'Price',
        dataIndex: 'price',
        width: '15%',
        sorter: true,
        align: 'center',
        render: (text, row) => {
          const amount = CommonHelper.formatCurrency(row.price);

          return <label className="text-14 text-bold mb-4">{`IDR ${amount}`}</label>;
        },
      },
      {
        title: 'Available at Store',
        dataIndex: 'is_pickup_at_store',
        width: '15%',
        sorter: true,
        align: 'center',
        render: (text, row) => {
          const ifPickUp = !!row.is_pickup_at_store;
          const wording = ifPickUp ? 'Yes' : 'No';

          return (
            <label
              className={`text-14 text-semi-bold mb-4 ${clsx({
                'text-rolling-stone': !ifPickUp,
              })}`}
            >
              {wording}
            </label>
          );
        },
      },
      {
        width: '25%',
        align: 'center',
        render: (text, row) => {
          const isDisable = !_.isEmpty(selectedRowKeys) || !row.is_pickup_at_store;
          if(_.isEmpty(eatActionOptions)) {
            return null;
          }

          return (
            <Grid container justify="space-between" alignItems="center" className="flex-wrap-unset">
              <Grid item lg md>
                {
                  !isDisable && (
                    <IconButton onClick={() => this.handleButtonClickShowQr(row.food_id)} size="small">
                      <i className="ic-ffo-qr-code container-icon-prefix size-20 text-black qr-container" />
                    </IconButton>
                  )
                }
              </Grid>
              <Grid item lg md>
                <ButtonMultipleMain
                  options={eatActionOptions}
                  icon="ic-ffo-more-dot"
                  type="secondary"
                  size="xxl"
                  onClick={item => this.handleClickButtonAction(item, row)}
                />
              </Grid>
            </Grid>
          );
        },
      },
    ];
  };

  renderFilter() {
    const { filter, sort, selectedRowKeys } = this.state;
    const {
      eatData: { reportExport, fetchingExport },
    } = this.props;
    const resultShowButton = selectedRowKeys.length > 0;

    return (
      <div className="flex-column">
        <Grid
          container
          justify="space-between"
          alignItems="center"
          className="container-filter flex-wrap-unset"
        >
          <Grid item lg md>
            <Grid container justify="flex-start" alignItems="center">
              <Grid item xl={3} lg={4} md={4} className="row-filter">
                <TextInput
                  iconPrefix="ic-ffo-search"
                  placeHolderText="Search Eat Name"
                  onChange={this.handleChangeSearch}
                  currentValue={filter.search}
                  size="md"
                />
              </Grid>
              <Grid item xl={2} lg={3} md={3} className="row-filter">
                <SelectInputMain
                  options={pickUpOption}
                  currentValue={filter.pickUp}
                  onChange={this.handleSelectChangePickUp}
                />
              </Grid>
              <Grid item xl={2} lg={3} md={3} className="row-filter">
                <SelectInputMain
                  options={statusOption}
                  currentValue={filter.status}
                  onChange={this.handleSelectChangeStatus}
                />
              </Grid>
              <Grid item className="row-filter">
                <GeneratorExcel
                  buttonSize="xl"
                  isLoading={fetchingExport}
                  dataSetArray={reportExport}
                  fileName={`eat_menu_Report`}
                  iconPrefix="ic-ffo-download"
                  isButtonIcon
                  onClick={this.handleButtonDownload}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xl={2} lg={2} md={2} className="row-filter">
            <ButtonMain
              labelText="Create Food"
              onClick={this.handleButtonCreate}
              type="primary"
              size="md"
              startIcon="ic-ffo-add"
              requiredPermission={`${PermissionModule.Eat}.${PermissionPage.Food}.${PermissionAccess.Add}`}
            />
          </Grid>
        </Grid>
        {resultShowButton && (
          <Grid container alignItems="flex-start" className="mt-24">
            <Grid item>
              <ButtonMain
                labelText="Print"
                onClick={this.handleButtonClickPrint}
                type="primary"
                size="md"
              />
            </Grid>
            <Grid item className="pl-16">
              <ButtonMain
                labelText="Cancel"
                onClick={this.handleButtonClickCancel}
                type="ghost"
                size="md"
              />
            </Grid>
          </Grid>
        )}
      </div>
    );
  }

  render() {
    const {
      eatData: { list },
    } = this.props;
    const {
      isLoading,
      loading,
      pagination,
      toastInformation,
      selectedRowKeys,
      isOpenModalPrint,
      selectedRowPrint,
      selectedPrint,
      isOpenModalDelete,
      modalDetailData,
    } = this.state;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.handleSelectChangeRow,
      getCheckboxProps: record => ({
        disabled: !record.is_pickup_at_store,
      }),
    };

    const finalDataPrint = !_.isEmpty(selectedRowPrint) ? selectedRowPrint : selectedPrint;

    let renderElement = <SkeletonMain />;

    if (!isLoading) {
      renderElement = (
        <div>
          <Helmet title="FITCO | Eats - Foods" />
          <div className="container-page-eat scroll-container">
            <div className="container-page-scrolling-area">
              <Grid container direction="column">
                <Grid item lg md sm className="section-page-header">
                  {this.renderFilter()}
                </Grid>
                <Grid item lg md className="section-page-body">
                  {/* {_.isEmpty(orderShipmentList) ? (
                  <Grid container justify={'center'}>
                    <EmptyState source={Images.emptyStateNoHistory} labelText={'No Order Found'} />
                  </Grid>
                ) : ( */}
                  <Table
                    rowSelection={{
                      type: 'checkbox',
                      ...rowSelection,
                    }}
                    columns={this.renderColumns()}
                    rowKey={record => record.food_id}
                    dataSource={list}
                    pagination={pagination}
                    loading={loading}
                    onChange={this.handleTableChange}
                  />
                  {/* )} */}
                </Grid>
              </Grid>
              <SnackBarSimple
                open={toastInformation.isOpen}
                durationHide={2000}
                message={toastInformation.message}
                onClickClose={this.handleCloseToash}
                snackbarType={toastInformation.snackbarType}
                anchor={optionToash}
              />
              {isOpenModalPrint && (
                <ModalEatMenuQrCode
                  isOpen={isOpenModalPrint}
                  data={finalDataPrint}
                  onClose={this.handleCloseModalPrint}
                />
              )}
              {isOpenModalDelete && (
                <ModalAlertAction
                  onButtonSubmit={this.handleButtonSubmitDelete}
                  onCloseModal={this.handleCloseModalDelete}
                  modalDetail={modalDetailData}
                  modalType="danger"
                  buttonSubmitText="Submit"
                  openModal={isOpenModalDelete}
                />
              )}
            </div>
          </div>
        </div>
      );
    }
    return renderElement;
  }
}

const mapDispatchToProps = dispatch => ({
  listEatPagination: params => getListEatPagination(dispatch, params),
  deleteEat: params => setDeleteEat(dispatch, params),
  listEatReportExport: params => getListEatReportExport(dispatch, params),
});

const mapStateToProps = ({ masterDataMain, eatData, usersReducer }) => ({
  masterDataMain,
  eatData,
  usersReducer,
});

EatPage.propTypes = {
  deleteEat: PropTypes.func,
  eatData: PropTypes.object,
  history: PropTypes.object,
  listEatPagination: PropTypes.func,
  listEatReportExport: PropTypes.func,
  onResetPrevStateValue: PropTypes.func,
  onSetPrevStateValue: PropTypes.func,
  usersReducer: PropTypes.object,
};

const shell = compose(connect(mapStateToProps, mapDispatchToProps));
const core = compose(AuthenticationAccessPages, PrevStateValue);

export default shell(core(EatPage));
