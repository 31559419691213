// import { CommonHelper } from './Index';

const ProductCategoryHelper = {
  generateListParentOrder(data) {
    const converted = [];
    for (let i = 0; i < data.length; i += 1) {
      const node = data[i];
      const { key, title, parentId } = node;

      converted.push({ key, title, parentId, order: i + 1 });
    }
    const result = converted;

    return result;
  },
  generateList(data, existingData, parentValue) {
    let result = [];
    const converted = existingData || [];

    for (let i = 0; i < data.length; i += 1) {
      const node = data[i];
      const { key, title, parentId } = node;
      let checkChile = false;
      let pushOnly = false;
      let tmpParent = parentValue || parentId;
      let tmpKey = key || parentId;

      if (node.children.length > 0) {
        if (data.length > 1) {
          checkChile = true;
        } else {
          const parentIsNull = parentValue || parentId;
          tmpParent = node.children.length > 0 ? parentIsNull : parentId;
          tmpKey = key;
          checkChile = true;
        }

        if (checkChile) {
          const parseParentId = parseInt(tmpParent, 10);
          converted.push({ key, title, parentId: parseParentId, order: i + 1 });
          this.generateList(node.children, converted, tmpKey);
        }
      } else {
        if (data.length > 1) {
          tmpParent = parentValue;
        }
        pushOnly = true;
      }

      if (pushOnly) {
        converted.push({ key, title, parentId: parseInt(tmpParent, 10), order: i + 1 });
      }
    }

    result = converted;
    return result;
  },
  getParentKey(key, tree) {
    let parentKey;
    for (let i = 0; i < tree.length; i += 1) {
      const node = tree[i];
      if (node.children) {
        if (node.children.some(item => item.key === key)) {
          parentKey = node.key;
        } else if (this.getParentKey(key, node.children)) {
          parentKey = this.getParentKey(key, node.children);
        }
      }
    }
    return parentKey;
  },
  loopDataOrder(data, key, callback) {
    for (let i = 0; i < data.length; i += 1) {
      if (data[i].key === key) {
        return callback(data[i], i, data);
      }
      if (data[i].children) {
        this.loopDataOrder(data[i].children, key, callback);
      }
    }
    return null;
  },
  objectReMoved(data, info) {
    const dropKey = info.node.props.eventKey;
    const dragKey = info.dragNode.props.eventKey;
    const dropPos = info.node.props.pos.split('-');
    const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);

    // Find dragObject
    let dragObj;
    this.loopDataOrder(data, dragKey, (item, index, arr) => {
      arr.splice(index, 1);
      dragObj = item;
    });

    if (!info.dropToGap) {
      // Drop on the content
      this.loopDataOrder(data, dropKey, item => {
        item.children = item.children || [];
        // where to insert
        item.children.push(dragObj);
      });
    } else if (
      (info.node.props.children || []).length > 0 && // Has children
      info.node.props.expanded && // Is expanded
      dropPosition === 1 // On the bottom gap
    ) {
      this.loopDataOrder(data, dropKey, item => {
        item.children = item.children || [];
        // where to insert
        item.children.unshift(dragObj);
      });
    } else {
      let ar;
      let i;
      this.loopDataOrder(data, dropKey, (item, index, arr) => {
        ar = arr;
        i = index;
      });
      if (dropPosition === -1) {
        ar.splice(i, 0, dragObj);
      } else {
        ar.splice(i + 1, 0, dragObj);
      }
    }

    return data;
  },
};

export default ProductCategoryHelper;
