import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Grid } from '@material-ui/core';
// component
import { HeaderRoleAndPermission } from './components/Index';
import {
  SnackBarSimple,
  ModalAlertAction,
  TextInput,
  LabelMultiple,
  AuthenticationAccessPages,
} from '../../../components/Index';
// view
import { RolePage, PermissionPage } from './Index';
// Api
import { setCreateRole, setCreatePermission } from '../../../services/api/UserManagement';
// Helper
import { ValidationHelper, CommonHelper } from '../../../helpers/Index';
import { compose } from 'redux';

const permissionNote = {
  title: 'Note :',
  body: [
    '- (parent name).(module_name)',
    '- (parent name).(module name)',
    '- (module name)',
    '- (module_name)',
  ],
};

const optionToash = {
  vertical: 'top',
  horizontal: 'right',
};

const modalDetailDataCreate = {
  title: '',
};

const maxStringLength = 100;
const dataTabs = [{ name: 'Role Lists' }, { name: 'Permission' }];

class RoleAndPermission extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tabsValue: 0,
      isOpenModal: false,
      name: '',
      isLoading: false,
      currentStringLength: 0,
      validation: { isError: false, errorMessage: '' },
      toastInformation: {
        isOpen: false,
        message: '',
        snackbarType: 'warning',
      },
    };
  }

  componentDidMount() {}

  componentWillUnmount() {}

  handleTabChange = value => {
    this.setState({ tabsValue: value });
  };

  handleButtonCreate = () => {
    this.setState({ isOpenModal: true });
  };

  handleCloseModalCreate = () => {
    const { validation } = this.state;

    this.setState({
      isOpenModal: false,
      name: null,
      validation: { ...validation, isError: false, errorMessage: '' },
    });
  };

  handleTextChangeRole = value => {
    const { validation } = this.state;

    if (!CommonHelper.checkStringLengthOver(value, maxStringLength)) {
      this.setState({
        name: value,
        currentStringLength: value.length,
        validation: { ...validation, isError: false, errorMessage: '' },
      });
    }
  };

  handleCloseToash = () => {
    const { toastInformation } = this.state;
    this.setState({ toastInformation: { ...toastInformation, isOpen: false } });
  };

  handleButtonSaveRole = () => {
    const { name, validation } = this.state;
    const { createRole } = this.props;

    if (!name) {
      this.setState({
        validation: { ...validation, isError: true, errorMessage: 'Role name Still Empty' },
      });
    } else {
      const param = {
        data: {
          name,
        },
      };

      this.setState({ isLoading: true }, () => {
        createRole(param)
          .then(async response => {
            const message = response.messages;
            await this.setState({ name: null, isOpenModal: false, isLoading: false }, () => {
              this.processMessage(message, 'success');
            });
          })
          .catch(error => {
            const message = error.data;
            this.processMessage(message.messages, 'error');
          });
      });
    }
  };

  handleTextChangePermission = value => {
    const { validation } = this.state;

    this.setState(
      {
        name: value.trim(),
      },
      () => {
        if (value === '') {
          let message = '';
          message = `Permission name Still Empty`;

          this.setState({ validation: { ...validation, isError: true, errorMessage: message } });
        }
      },
    );
  };

  handleButtonSavePermission = () => {
    const { name, validation } = this.state;
    const { createPermission } = this.props;

    if (
      !validation.isError &&
      name &&
      name !== '' &&
      ValidationHelper.validatePermissionName(name)
    ) {
      const param = {
        data: {
          name,
        },
      };

      this.setState({ isLoading: true }, () => {
        createPermission(param)
          .then(async response => {
            const message = response.messages;
            await this.setState({ name: null, isOpenModal: false, isLoading: false }, () => {
              this.processMessage(message, 'success');
            });
          })
          .catch(error => {
            const message = error.data;
            this.processMessage(message.messages, 'error');
            this.setState({ isLoading: false });
          });
      });
    } else {
      let message = '';
      if (name === '' || !name) message = `Permission name Still Empty`;
      else message = `Wrong Format`;

      this.setState({ validation: { ...validation, isError: true, errorMessage: message } });
    }
  };

  processMessage(messages, type) {
    const convertedMessage = CommonHelper.generateMessage(messages);

    const paramInformation = {
      isOpen: true,
      message: convertedMessage,
      snackbarType: type,
    };

    this.setState({
      toastInformation: paramInformation,
    });
  }

  renderPages = () => {
    const { tabsValue, isLoading } = this.state;
    let renderElement = null;

    switch (dataTabs[tabsValue]) {
      case dataTabs[0]:
        renderElement = <RolePage isLoadingParent={isLoading} />;
        break;
      default:
        renderElement = <PermissionPage isLoadingParent={isLoading} />;
        break;
    }

    return renderElement;
  };

  renderModalCreate() {
    const { isOpenModal, tabsValue, isLoading } = this.state;
    let elementRenderCustom;
    let functionCreate;

    switch (dataTabs[tabsValue].name) {
      case dataTabs[0].name:
        functionCreate = this.handleButtonSaveRole;
        modalDetailDataCreate.title = 'Create New Role';

        elementRenderCustom = (
          <Grid container direction="column">
            <Grid item lg md>
              <TextInput
                placeHolderText="Role Name"
                onChange={this.handleTextChangeRole}
                currentValue={this.state.name}
                errorMessage={this.state.validation.errorMessage}
                isError={this.state.validation.isError}
              />
            </Grid>
            <Grid item lg md>
              <Grid container justify="flex-end">
                <Grid item>
                  <label className="text-10">{`${this.state.currentStringLength} / ${maxStringLength}`}</label>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        );
        break;
      default:
        functionCreate = this.handleButtonSavePermission;
        modalDetailDataCreate.title = 'Create New Permission';

        elementRenderCustom = (
          <Grid container direction="column">
            {permissionNote ? (
              <Grid item lg md className="container-note">
                <Grid container direction="column">
                  <Grid item lg md>
                    <label>*{permissionNote.title}</label>
                  </Grid>
                </Grid>
                <Grid item lg md>
                  <LabelMultiple dataArray={permissionNote.body} />
                </Grid>
              </Grid>
            ) : null}
            <Grid item lg md>
              <TextInput
                placeHolderText="Permission Name"
                onChange={this.handleTextChangePermission}
                currentValue={this.state.name}
                errorMessage={this.state.validation.errorMessage}
                isError={this.state.validation.isError}
              />
            </Grid>
          </Grid>
        );
        break;
    }

    return isOpenModal ? (
      <ModalAlertAction
        onButtonSubmit={functionCreate}
        onCloseModal={this.handleCloseModalCreate}
        modalDetail={modalDetailDataCreate}
        buttonSubmitText="Save"
        openModal={isOpenModal}
        isLoading={isLoading}
        customElementProps={elementRenderCustom}
      />
    ) : null;
  }

  render() {
    const { toastInformation } = this.state;

    return (
      <div>
        <Helmet title={`FITCO | User Managements - Roles & Permissons`} />
        <div className="row">
          <HeaderRoleAndPermission
            data={dataTabs}
            currentTab={this.state.tabsValue}
            onChangeTab={this.handleTabChange}
            onButtonClickPermission={this.handleButtonCreate}
            onButtonClickRole={this.handleButtonCreate}
          />
          {this.renderPages()}
          {this.renderModalCreate()}
        </div>
        <SnackBarSimple
          open={toastInformation.isOpen}
          durationHide={2000}
          message={toastInformation.message}
          onClickClose={this.handleCloseToash}
          snackbarType={toastInformation.snackbarType}
          anchor={optionToash}
        />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  createRole: params => setCreateRole(dispatch, params),
  createPermission: params => setCreatePermission(dispatch, params),
});

const mapStateToProps = () => ({});

RoleAndPermission.propTypes = {
  createPermission: PropTypes.func,
  createRole: PropTypes.func,
};

const shell = compose(connect(mapStateToProps, mapDispatchToProps));

export default shell(AuthenticationAccessPages(RoleAndPermission));
