import { constants } from '../reducers/SegmentReducer';

export function getSegmentPaginationRequest() {
  return { type: constants.getSegmentPaginationRequest };
}

export function getSegmentPaginationSuccess(response) {
  return { type: constants.getSegmentPaginationSuccess, payload: response };
}

export function getSegmentPaginationFail(response) {
  return { type: constants.getSegmentPaginationFail, payload: response.data };
}

export function getSegmentPreviewQueryRequest() {
  return { type: constants.getSegmentPreviewQueryRequest };
}

export function getSegmentPreviewQuerySuccess(response) {
  return { type: constants.getSegmentPreviewQuerySuccess, payload: response };
}

export function getSegmentPreviewQueryFail(response) {
  return { type: constants.getSegmentPreviewQueryFail, payload: response.data };
}

export function getSegmentDetailsRequest() {
  return { type: constants.getSegmentDetailsRequest };
}

export function getSegmentDetailsSuccess(response) {
  return { type: constants.getSegmentDetailsSuccess, payload: response };
}

export function getSegmentDetailsFail(response) {
  return { type: constants.getSegmentDetailsFail, payload: response.data };
}

export function createSegmentRequest() {
  return { type: constants.createSegmentRequest };
}

export function createSegmentSuccess(response) {
  return { type: constants.createSegmentSuccess, payload: response };
}

export function createSegmentFail(response) {
  return { type: constants.createSegmentFail, payload: response.data };
}

export function editSegmentRequest() {
  return { type: constants.editSegmentRequest };
}

export function editSegmentSuccess(response) {
  return { type: constants.editSegmentSuccess, payload: response };
}

export function editSegmentFail(response) {
  return { type: constants.editSegmentFail, payload: response.data };
}

export function deleteSegmentRequest() {
  return { type: constants.deleteSegmentRequest };
}

export function deleteSegmentSuccess(response) {
  return { type: constants.deleteSegmentSuccess, payload: response };
}

export function deleteSegmentFail(response) {
  return { type: constants.deleteSegmentFail, payload: response };
}

export function resetSegmentPreview() {
  return { type: constants.resetSegmentPreview };
}
