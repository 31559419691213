export const constants = {
  getPromoCodePaginationRequest: 'GET_PROMO_CODE_PAGINATION_REQUEST',
  getPromoCodePaginationSuccess: 'GET_PROMO_CODE_PAGINATION_SUCCESS',
  getPromoCodePaginationFail: 'GET_PROMO_CODE_PAGINATION_FAIL',
  getCheckValidPromoCodeRequest: 'GET_CHECK_VALID_PROMO_CODE_REQUEST',
  getCheckValidPromoCodeSuccess: 'GET_CHECK_VALID_PROMO_CODE_SUCCESS',
  getCheckValidPromoCodeFail: 'GET_CHECK_VALID_PROMO_CODE_FAIL',
  getPromoCodeExportReportRequest: 'GET_PROMO_CODE_EXPORT_REPORT_REQUEST',
  getPromoCodeExportReportSuccess: 'GET_PROMO_CODE_EXPORT_REPORT_SUCCESS',
  getPromoCodeExportReportFail: 'GET_PROMO_CODE_ EXPORT_REPORT_FAIL',
  getPromoCodeDetailsRequest: 'GET_PROMO_CODE_DETAILS_REQUEST',
  getPromoCodeDetailsSuccess: 'GET_PROMO_CODE_DETAILS_SUCCESS',
  getPromoCodeDetailsFail: 'GET_PROMO_CODE_DETAILS_FAIL',
  setCreatePromoCodeRequest: 'SET_CREATE_PROMO_CODE_REQUEST',
  setCreatePromoCodeSuccess: 'SET_CREATE_PROMO_CODE_SUCCESS',
  setCreatePromoCodeFail: 'SET_CREATE_PROMO_CODE_FAIL',
  setUpdatePromoCodeRequest: 'SET_UPDATE_PROMO_CODE_REQUEST',
  setUpdatePromoCodeSuccess: 'SET_UPDATE_PROMO_CODE_SUCCESS',
  setUpdatePromoCodeFail: 'SET_UPDATE_PROMO_CODE_FAIL',
  deletePromoCodeRequest: 'DELETE_PROMO_CODE_REQUEST',
  deletePromoCodeSuccess: 'DELETE_PROMO_CODE_SUCCESS',
  deletePromoCodeFail: 'DELETE_PROMO_CODE_FAIL',
  resetPromoCodePreview: 'RESET_PROMO_CODE_PREVIEW',
};

const initState = {
  fetching: false,
  list: [],
  details: {},
  reportExport: [],
  errorFetch: false,
  errorMessage: null,
  responseMessage: null,
};

export default function promoCodeReducer(state = initState, action) {
  const { type, payload } = action;
  switch (type) {
    case constants.getPromoCodePaginationRequest:
      return { ...state, fetching: true };
    case constants.getPromoCodePaginationSuccess:
      return {
        ...state,
        list: payload.data,
        fetching: false,
      };
    case constants.getPromoCodePaginationFail:
      return {
        ...state,
        fetching: false,
        errorFetch: true,
        errorMessage: payload,
      };

    case constants.getCheckValidPromoCodeRequest:
      return { ...state, fetching: true };
    case constants.getCheckValidPromoCodeSuccess:
      return {
        ...state,
        fetching: false,
      };
    case constants.getCheckValidPromoCodeFail:
      return {
        ...state,
        fetching: false,
        errorFetch: true,
        errorMessage: payload,
      };

    case constants.getPromoCodeExportReportRequest:
      return { ...state, fetchingExport: true };
    case constants.getPromoCodeExportReportSuccess:
      return {
        ...state,
        reportExport: payload.data,
        fetchingExport: false,
      };
    case constants.getPromoCodeExportReportFail:
      return {
        ...state,
        fetchingExport: false,
        errorFetch: true,
        errorMessage: payload,
      };

    case constants.getPromoCodeDetailsRequest:
      return { ...state, fetching: true };
    case constants.getPromoCodeDetailsSuccess:
      return {
        ...state,
        details: payload.data,
        fetching: false,
      };
    case constants.getPromoCodeDetailsFail:
      return {
        ...state,
        fetching: false,
        errorFetch: true,
        errorMessage: payload,
      };

    case constants.setCreatePromoCodeRequest:
      return { ...state, fetching: true, responseMessage: null };
    case constants.setCreatePromoCodeSuccess:
      return {
        ...state,
        responseMessage: payload.messages,
        fetching: false,
      };
    case constants.setCreatePromoCodeFail:
      return { ...state, fetching: false, errorMessage: payload };

    case constants.setUpdatePromoCodeRequest:
      return { ...state, fetching: true, responseMessage: null };
    case constants.setUpdatePromoCodeSuccess:
      return {
        ...state,
        responseMessage: payload.messages,
        fetching: false,
      };
    case constants.setUpdatePromoCodeFail:
      return { ...state, fetching: false, errorMessage: payload };

    case constants.deletePromoCodeRequest:
      return { ...state, fetching: true, responseMessage: null };
    case constants.deletePromoCodeSuccess:
      return {
        ...state,
        responseMessage: payload.messages,
        fetching: false,
      };
    case constants.deletePromoCodeFail:
      return { ...state, fetching: false, errorMessage: payload };

    case constants.resetPromoCodePreview:
      return { ...state, previewQueryResult: [] };
    default:
      return state;
  }
}
