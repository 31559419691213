import React from 'react';
import { connect } from 'react-redux';
import { Grid, FormControl, FormLabel } from '@material-ui/core';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { compose } from 'redux';
// component
import {
  ButtonMain,
  PrevNextStepperCount,
  TextInput,
  SelectInputMain,
  LabelInput,
  TextAreaMain,
  TextEditor,
  RadioInput,
} from '../../../../../components/Index';
// // style
import './StepAddEatDetailsStyle.scss';
// helper
import { CommonHelper, MasterDataHelper, GlobalCodeStatus } from '../../../../../helpers/Index';
// api

const merchantOption = [{ value: 6, name: 'FITGOURMET' }];
const statusOption = MasterDataHelper.statusGlobal.filter(
  item => item.value === GlobalCodeStatus.Active || item.value === GlobalCodeStatus.Inactive,
);
const radioButtonTrueFalseOption = MasterDataHelper.radioButtonTrueFalse;

const initialValidation = {
  name: { isError: false, errorMessage: '' },
  sku: { isError: false, errorMessage: '' },
  merchantId: { isError: '', errorMessage: '' },
  pickUp: { isError: '', errorMessage: '' },
  status: { isError: '', errorMessage: '' },
  shortDescription: { isError: '', errorMessage: '' },
  description: { isError: '', errorMessage: '' },
};

const initialForm = {
  name: '',
  sku: '',
  merchantId: merchantOption[0].value,
  pickUp: radioButtonTrueFalseOption[0],
  status: statusOption[0],
  shortDescription: '',
  description: '',
  descriptionHtml: '',
};

class StepAddEatDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      form: CommonHelper.objectCloning(initialForm),
      validation: CommonHelper.objectCloning(initialValidation),
    };
  }

  componentDidMount() {
    const { parentForm, parentValidation } = this.props;
    const { form, validation } = this.state;

    this.setState({
      form: { ...form, ...parentForm },
      validation: { ...validation, ...parentValidation },
    });
  }

  componentWillReceiveProps(nextProps) {
    const { form, validation } = this.state;

    const isFormChange = _.isEqual(form, nextProps.parentForm);
    const isValidationChange = _.isEqual(validation, nextProps.parentValidation);

    if (!isFormChange) {
      const finalMergeForm = { ...form, ...nextProps.parentForm };
      this.setState({
        form: finalMergeForm,
      });
    }

    if (!isValidationChange) {
      const finalMergeValidation = { ...validation, ...nextProps.parentValidation };
      this.setState({
        validation: finalMergeValidation,
      });
    }
  }

  componentWillUnmount() {}

  handleTextChangeName = value => {
    const { form, validation } = this.state;
    const message = CommonHelper.objectCloning(initialValidation.name);
    message.isError = !value;
    message.errorMessage = !value ? 'Name is empty' : '';

    this.setState({
      form: { ...form, name: value },
      validation: { ...validation, name: message },
    });
  };

  handleTextChangeSKU = value => {
    const { form, validation } = this.state;
    const message = CommonHelper.objectCloning(initialValidation.sku);
    message.isError = !value;
    message.errorMessage = !value ? 'SKU is empty' : '';

    this.setState({
      form: { ...form, sku: value },
      validation: { ...validation, sku: message },
    });
  };

  handleSelectChangeMerchant = value => {
    const { form } = this.state;

    this.setState({
      form: { ...form, merchantId: value },
    });
  };

  handleTextChangeShortDescription = value => {
    const { form, validation } = this.state;
    const message = CommonHelper.objectCloning(initialValidation.shortDescription);
    message.isError = !value ? 'error' : '';
    message.errorMessage = !value ? 'Short Description is empty' : '';

    this.setState({
      form: {
        ...form,
        shortDescription: value,
      },
      validation: { ...validation, shortDescription: message },
    });
  };

  handleTextChangeDescription = (content, string) => {
    const { form, validation } = this.state;
    const message = CommonHelper.objectCloning(initialValidation.description);
    message.isError = !string ? 'error' : '';
    message.errorMessage = !string ? 'Description is empty' : '';

    this.setState({
      form: {
        ...form,
        description: string,
        descriptionHtml: content,
      },
      validation: { ...validation, description: message },
    });
  };

  handleRadioChangePickUp = value => {
    const { form } = this.state;

    this.setState({ form: { ...form, pickUp: value } });
  };

  handleRadioChangeStatus = value => {
    const { form } = this.state;

    this.setState({ form: { ...form, status: value } });
  };

  handleNextClick = async () => {
    const { onNextClick, onParameterUpdate } = this.props;
    const { form } = this.state;

    await onParameterUpdate(form);
    await onNextClick();
  };

  render() {
    const {
      form: { name, sku, merchantId, pickUp, status, shortDescription, descriptionHtml },
      validation,
    } = this.state;
    const { onButtonClickCancel } = this.props;

    return (
      <Grid container className="container-step-eat-details">
        <Grid item lg={12} md={12} className="mt-16">
          <Grid container spacing={2}>
            <Grid item xl={6} lg={12} md={12}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <LabelInput labelText="Name" isRequired />
                <TextInput
                  placeHolderText="Enter Eat Name"
                  onChange={this.handleTextChangeName}
                  currentValue={name}
                  isError={validation.name.isError}
                  errorMessage={validation.name.errorMessage}
                  size="md"
                />
              </FormControl>
            </Grid>
            <Grid item xl={6} lg={12} md={12}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <LabelInput labelText="SKU" isRequired />
                <TextInput
                  placeHolderText="Enter SKU"
                  onChange={this.handleTextChangeSKU}
                  currentValue={sku}
                  isError={validation.sku.isError}
                  errorMessage={validation.sku.errorMessage}
                  size="md"
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid item lg={12} md={12}>
            <Grid container spacing={2}>
              <Grid item xl={6} lg={8} md={8}>
                <div className="flex-column">
                  <FormControl component="fieldset" fullWidth margin="normal">
                    <FormLabel component="label" className="text-12 filed-title">
                      Merchant
                    </FormLabel>
                    <SelectInputMain
                      options={merchantOption}
                      currentValue={merchantId}
                      onChange={this.handleSelectChangeMerchant}
                    />
                  </FormControl>
                  <FormControl component="fieldset" fullWidth margin="normal">
                    <LabelInput labelText="Short Description" isRequired />
                    <TextAreaMain
                      onChange={this.handleTextChangeShortDescription}
                      currentValue={shortDescription}
                      placeholder="Enter Short Description"
                      rows={3}
                      validateStatus={validation.shortDescription.isError}
                      errorMessage={validation.shortDescription.errorMessage}
                    />
                  </FormControl>
                  <FormControl component="fieldset" fullWidth margin="normal">
                    <LabelInput labelText="Description" isRequired />
                    <TextEditor
                      currentValue={descriptionHtml}
                      onChange={this.handleTextChangeDescription}
                      errorMessage={validation.description.errorMessage}
                      validateStatus={validation.description.isError}
                    />
                  </FormControl>
                </div>
              </Grid>
              <Grid item xl={6} lg={4} md={4}>
                <div className="flex-column">
                  <FormControl component="fieldset" fullWidth margin="normal">
                    <FormLabel component="label" className="text-12 filed-title">
                      Pick Up Store
                    </FormLabel>
                    <RadioInput
                      data={radioButtonTrueFalseOption}
                      onSelect={this.handleRadioChangePickUp}
                      direction="row"
                      currentValue={pickUp}
                    />
                  </FormControl>
                  <FormControl component="fieldset" fullWidth margin="normal">
                    <FormLabel component="label" className="text-12 filed-title">
                      Status
                    </FormLabel>
                    <RadioInput
                      data={statusOption}
                      onSelect={this.handleRadioChangeStatus}
                      direction="row"
                      currentValue={status}
                    />
                  </FormControl>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={12} md={12} className="mt-18">
          <Grid container justify="flex-end">
            <Grid item lg={5} md={6}>
              <Grid container justify="flex-end">
                <Grid item lg={4} md={4} className="pl-8">
                  <ButtonMain
                    type="negative"
                    size="xl"
                    labelText="Cancel"
                    onClick={onButtonClickCancel}
                  />
                </Grid>
                <Grid item lg={4} md={4} className="pl-8">
                  <ButtonMain
                    type="primary"
                    size="xl"
                    labelText="Next"
                    onClick={this.handleNextClick}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

const mapDispatchToProps = () => ({});

const mapStateToProps = () => ({});

StepAddEatDetails.propTypes = {
  onButtonClickCancel: PropTypes.func,
  onNextClick: PropTypes.func,
  onParameterUpdate: PropTypes.func,
  parentForm: PropTypes.object,
  parentValidation: PropTypes.object,
};

const shell = compose(connect(mapStateToProps, mapDispatchToProps));

export default shell(PrevNextStepperCount(StepAddEatDetails));
