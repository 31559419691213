import React from 'react';
import PropTypes from 'prop-types';
import { Page, View, Document, Image } from '@react-pdf/renderer';
// Style
import StylesJs from './CanvasPortraitThreeRowStyle';
// assets
import { StyleJs as StyleJsGlobal } from '../../../../../assets/Index';

const CanvasPortraitThreeRow = props => {
  const { data } = props;

  return (
    <Document>
      <Page size="A4">
        <View style={[StyleJsGlobal.gridRow, { width: 600 }]}>
          {data.map((item, index) => {
            return (
              <View key={index} style={[StyleJsGlobal.gridItem4]}>
                <Image src={item} style={[StylesJs.pages]} />
              </View>
            );
          })}
        </View>
      </Page>
    </Document>
  );
};

CanvasPortraitThreeRow.propTypes = {
  data: PropTypes.array,
};

export default CanvasPortraitThreeRow;
