/* eslint-disable no-empty */
import React from 'react';
import ReactExport from 'react-export-excel';
import PropTypes from 'prop-types';
import _ from 'lodash';
// Style
import './GeneratorExcelStyle.scss';
// Component
import { ButtonMain, ButtonIconMain } from '../../Index';
// helper
import { ExportHelper } from '../../../helpers/Index';

const GeneratorExcel = props => {
  const {
    dataSetArray,
    isButtonIcon,
    fileName,
    buttonSize,
    iconPrefix,
    onClick,
    isLoading,
    type,
    labelText,
  } = props;
  const [isDownload, setDownload] = React.useState(false);
  const [exportedData, setExportedData] = React.useState([]);

  React.useEffect(() => {
    let dataConverted = [];
    let dataSet = [];
    if (!_.isEmpty(dataSetArray)) {
      dataConverted = ExportHelper.generateCellStyle(dataSetArray);
      dataSet = [
        {
          columns: dataConverted.header || [],
          ySteps: 5,
          data: dataConverted.body || [],
        },
      ];
    }

    setExportedData(dataSet);
  }, [dataSetArray]);

  React.useEffect(() => {
    if (isDownload && !_.isEmpty(exportedData)) {
      setDownload(false);
    }
  }, [isDownload, exportedData]);

  const handleButtonClickGenerateExcel = async () => {
    setExportedData([]);
    try {
      if (onClick) {
        setDownload(true);
        await onClick();
      } else {
        setDownload(true);
      }
    } catch (error) {}
  };

  let renderElement = (
    <ButtonMain
      labelText={labelText || 'Download Report'}
      type={type || 'primary'}
      size={buttonSize}
      startIcon={iconPrefix}
      onClick={handleButtonClickGenerateExcel}
      isLoading={isDownload || isLoading}
    />
  );

  if (isButtonIcon) {
    renderElement = (
      <ButtonIconMain
        icon={iconPrefix}
        type={type || 'ghost'}
        size={buttonSize}
        onClick={handleButtonClickGenerateExcel}
        isLoading={isDownload || isLoading}
      />
    );
  }

  return (
    <React.Fragment>
      {renderElement}
      {!_.isEmpty(exportedData) && isDownload ? (
        <ExcelExporter dataSet={exportedData} filename={fileName} />
      ) : null}
    </React.Fragment>
  );
};

const ExcelExporter = ({ fileName, dataSet }) => {
  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  return (
    <ExcelFile hideElement fileExtension="xlsx" filename={fileName}>
      <ExcelSheet dataSet={dataSet} name="Sheet1" />
    </ExcelFile>
  );
};

ExcelExporter.propTypes = {
  dataSet: PropTypes.array,
  fileName: PropTypes.string,
};

GeneratorExcel.propTypes = {
  buttonSize: PropTypes.string,
  dataSetArray: PropTypes.array,
  fileName: PropTypes.string,
  iconPrefix: PropTypes.string,
  isButtonIcon: PropTypes.bool,
  isLoading: PropTypes.bool,
  labelText: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.string,
};

export default GeneratorExcel;
