import React from 'react';
import PropTypes from 'prop-types';
import { Grid, IconButton } from '@material-ui/core';
// style
import './ModalAlertInformationStyle.scss';
// component
import { ModalInformationPopUp } from '../../Index';

const ModalAlertInformation = props => {
  const { isOpen, optionModal, onCloseModal, typeModal, customElementProps } = props;

  const renderElement = (
    <Grid container direction="column">
      <Grid item lg md className={`section-header-modal-alert ${typeModal}`}>
        <Grid container>
          <Grid item lg={11} md={11}>
            <label className="text-18 wrapping-container">{optionModal.title}</label>
          </Grid>
          <Grid item>
            <Grid container direction="column" justify="flex-end">
              <Grid item>
                <div className="container-button-close">
                  <IconButton onClick={onCloseModal}>
                    <i className="ic-ffo-close container-icon-prefix size-20" />
                  </IconButton>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item className="section-body-modal-alert">
        <Grid container direction="column">
          <Grid item lg md>
            <label className="text-18">{optionModal.text}</label>
            {customElementProps}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <ModalInformationPopUp
      isOpen={isOpen}
      onClose={onCloseModal}
      customElementProps={renderElement}
    />
  );
};

ModalAlertInformation.propTypes = {
  customElementProps: PropTypes.element,
  isOpen: PropTypes.bool,
  onCloseModal: PropTypes.func,
  optionModal: PropTypes.object,
  typeModal: PropTypes.string,
};

export default ModalAlertInformation;
