import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Grid, Breadcrumbs, Link, FormControl, FormLabel } from '@material-ui/core';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { compose } from 'redux';
// Components
import {
  ButtonMain,
  GridRowTableOneColumn,
  AuthenticationAccessPages,
  RadioInputImage,
  SnackBarSimple,
  SelectInputMain,
} from '../../../components/Index';
// Style
import './MembershipChangeRecurringStyle.scss';
// Helper
import {
  CommonHelper,
  PermissionModule,
  PermissionPage,
  PermissionAccess,
  HttpStatusCode,
  MasterDataHelper,
  paymentMethodOptionCode,
  currencyCode,
} from '../../../helpers/Index';
// Action
import {
  setMembershipRecurringById,
  getMembershipDetailsById,
} from '../../../services/api/MembershipApi';
import { Icons } from '../../../assets/Index';

const bankTransferOption = MasterDataHelper.bankTransferOption;
const paymentMethodOption = MasterDataHelper.paymentMethodOption.filter(
  item =>
    item.value === paymentMethodOptionCode.bank_transfer ||
    item.value === paymentMethodOptionCode.credit_card ||
    item.value === paymentMethodOptionCode.fit_point,
);

const initialForm = {
  paymentMethod: paymentMethodOptionCode.bank_transfer,
  bank: bankTransferOption[0],
};

const optionToash = {
  vertical: 'top',
  horizontal: 'right',
};

class MembershipChangeRecurring extends React.Component {
  constructor(props) {
    super(props);
    const {
      match: { params },
    } = this.props;

    props.checkUserAccessPermission(
      PermissionModule.Orders,
      PermissionPage.List,
      PermissionAccess.View,
    );

    const updateExitingForm = {
      ...CommonHelper.objectCloning(initialForm),
      ...CommonHelper.decryptObject(params.param_object),
    };

    this.state = {
      isLoading: true,
      form: updateExitingForm,
      toastInformation: {
        isOpen: false,
        message: '',
        snackbarType: 'warning',
      },
    };
  }

  componentDidMount() {
    this.getMembershipDetailsById();
  }

  async componentDidUpdate() {
    const {
      membership: { fetching, errorMessage },
      history,
    } = this.props;
    const {
      form: { backUrl },
    } = this.state;

    if (!fetching && errorMessage && errorMessage.code === HttpStatusCode.Forbidden) {
      history.push(backUrl || '/');
    }
  }

  getMembershipDetailsById() {
    const { membershipDetailsById } = this.props;
    const { form } = this.state;

    const param = {
      user_point_booster_id: form.membershipId,
    };

    membershipDetailsById(param)
      .then(response => {
        const data = response.data;
        this.setState({
          isLoading: false,
          form: { ...form, paymentMethod: data.recurring_payment_details.payment_type },
        });
      })
      .catch(async error => {
        const serverMessage = error.data;
        const validationStatus = error.status === HttpStatusCode.InternalServerError;
        this.processMessage(
          validationStatus ? serverMessage.message : serverMessage.messages,
          'error',
        );
      });
  }

  handleClick = (event, value) => {
    const { history } = this.props;
    event.preventDefault();
    history.push(value);
  };

  handleSelectPaymentMethod = value => {
    const { form } = this.state;

    this.setState(
      {
        form: { ...form, paymentMethod: value },
      },
      () => {
        if (value === paymentMethodOptionCode.bank_transfer) {
          const valueChange = bankTransferOption[0];
          this.handleChangeBankTransfer(valueChange);
        }
      },
    );
  };

  handleChangeBankTransfer = value => {
    const { form } = this.state;

    this.setState({ form: { ...form, bank: value } });
  };

  handleButtonCancel = () => {
    const { history } = this.props;
    const {
      form: { backUrl },
    } = this.state;

    history.push(backUrl || '/');
  };

  handleButtonSave = () => {
    const {
      membershipRecurringById,
      membership: { details },
    } = this.props;
    const { form } = this.state;

    const param = {
      payment_type: form.paymentMethod,
      // bank: !_.isEmpty(form.bank) ? form.bank.value : null,
    };

    if (form.paymentMethod !== paymentMethodOptionCode.credit_card) {
      param.bank = !_.isEmpty(form.bank) ? form.bank.value : null;
    }

    membershipRecurringById(details.recurring_details.recurring_id, param)
      .then(async response => {
        const message = response.messages;
        await this.processMessage(message, 'success');
        await this.getMembershipDetailsById();
      })
      .catch(async error => {
        const serverMessage = error.data;
        const validationStatus = error.status === HttpStatusCode.InternalServerError;
        this.processMessage(
          validationStatus ? serverMessage.message : serverMessage.messages,
          'error',
        );
      });
  };

  handleCloseToash = () => {
    const { toastInformation } = this.state;
    this.setState({ toastInformation: { ...toastInformation, isOpen: false } });
  };

  processMessage(messages, type) {
    const convertedMessage = CommonHelper.generateMessage(messages);

    const paramInformation = {
      isOpen: true,
      message: convertedMessage,
      snackbarType: type,
    };

    this.setState({
      toastInformation: paramInformation,
    });
  }

  renderRecurringPaymentDetails = recurringDetails => {
    let renderElement = null;
    if (recurringDetails) {
      const membershipPrice = recurringDetails.amount || null;
      const paymentMethod = MasterDataHelper.paymentMethodOption.filter(
        item => item.value === recurringDetails.payment_type,
      );
      const finalPaymentMethod = !_.isEmpty(paymentMethod) ? paymentMethod[0].name : '-';
      const paymentPlan = MasterDataHelper.paymentPlanOption.filter(
        item => item.value === recurringDetails.payment_method,
      );
      const finalPaymentPlan = !_.isEmpty(paymentPlan) ? paymentPlan[0].name : '-';

      renderElement = (
        <Grid container justify="flex-start" className="mt-36 mb-24" spacing={2}>
          <Grid item>
            <div className="flex-column">
              <label className="text-12 text-rolling-stone text-semi-bold mb-4">
                Membership Price
              </label>
              <span className="flex-row text-center">
                {recurringDetails.payment_type === paymentMethodOptionCode.fit_point ? (
                  <img src={Icons.fitPoints} alt="Fit Points" className="pr-8" />
                ) : (
                  <label className="text-14 text-semi-bold pr-8">{currencyCode.idr}</label>
                )}
                <label className="text-14 text-semi-bold wrapping-container third line">
                  {membershipPrice !== 0 ? CommonHelper.formatCurrency(membershipPrice) : 0}
                </label>
              </span>
            </div>
          </Grid>
          <Grid item>
            <div className="flex-column">
              <label className="text-12 text-rolling-stone text-semi-bold mb-4 wrapping-container third line">
                Payment Method
              </label>
              <label className="text-14 text-semi-bold">{finalPaymentMethod}</label>
            </div>
          </Grid>
          <Grid item>
            <div className="flex-column">
              <label className="text-12 text-rolling-stone text-semi-bold mb-4">Payment Plan</label>
              <label className="text-14 text-semi-bold">{finalPaymentPlan}</label>
            </div>
          </Grid>
          <Grid item>
            <div className="flex-column">
              <label className="text-12 text-rolling-stone text-semi-bold mb-4">Payment Term</label>
              <label className="text-14 text-semi-bold">{recurringDetails.payment_terms}</label>
            </div>
          </Grid>
          <Grid item>
            <div className="flex-column">
              <label className="text-12 text-rolling-stone text-semi-bold mb-4">
                Purchase Date
              </label>
              <label className="text-14 text-semi-bold">
                {CommonHelper.dateTimeParseNewFormat(
                  recurringDetails.created_at,
                  'ddd, DD MMM YYYY',
                ) || '-'}
              </label>
            </div>
          </Grid>
        </Grid>
      );
    }
    return renderElement;
  };

  renderMembershipCardDetails = membershipDetails => {
    let renderElement = null;

    if (membershipDetails) {
      renderElement = (
        <Grid
          container
          justify="space-between"
          className="container-main-card-shadow p-24"
          alignItems="center"
        >
          <Grid item lg md>
            <div className="flex-column">
              <label className="text-16 text-bold text-rolling-stone mb-24">
                {membershipDetails.product_name}
              </label>
              <Grid container justify="flex-start">
                <Grid item>
                  <div className="flex-column">
                    <label className="text-12 text-rolling-stone text-semi-bold mb-4">
                      Start Date
                    </label>
                    <label className="text-14 text-semi-bold">
                      {CommonHelper.dateTimeParseNewFormat(
                        membershipDetails.start_date,
                        'ddd, DD MMM YYYY',
                      ) || '-'}
                    </label>
                  </div>
                </Grid>
                <Grid item className="ph-10">
                  <div className="divider-hr-vertical " />
                </Grid>
                <Grid item>
                  <div className="flex-column">
                    <label className="text-12 text-rolling-stone text-semi-bold mb-4">
                      End Date
                    </label>
                    <label className="text-14 text-semi-bold">
                      {CommonHelper.dateTimeParseNewFormat(
                        membershipDetails.end_date,
                        'ddd, DD MMM YYYY',
                      ) || '-'}
                    </label>
                  </div>
                </Grid>
                <Grid item className="ph-10">
                  <div className="divider-hr-vertical" />
                </Grid>
                <Grid item>
                  <div className="flex-column">
                    <label className="text-12 text-rolling-stone text-semi-bold mb-4">Renew</label>
                    <label className="text-14 text-semi-bold text-downy">
                      {CommonHelper.dateTimeParseNewFormat(
                        membershipDetails.renew_date,
                        'ddd, DD MMM YYYY',
                      ) || '-'}
                    </label>
                  </div>
                </Grid>
                <Grid item className="ph-10">
                  <div className="divider-hr-vertical " />
                </Grid>
                <Grid item>
                  <div className="flex-column">
                    <label className="text-12 text-semi-bold text-rolling-stone mb-4">Quota</label>
                    <label className="text-14 text-semi-bold">
                      {membershipDetails.quota || '-'}
                    </label>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item lg={2} md={2}>
            <div className="flex-column text-center">
              {/* <label className="text-16  text-semi-bold mb-8"> */}
              <label className="text-16 text-bold">
                {`${membershipDetails.full_period} Days` || '-'}
              </label>
              {/* <label className="text-14  text-semi-bold text-rolling-stone">
                {`${membershipDetails.remaining_period} Remaining` || '-'}
              </label> */}
            </div>
          </Grid>
        </Grid>
      );
    }

    return renderElement;
  };

  render() {
    const {
      membership: { details, fetching },
    } = this.props;

    const {
      form: { backUrl, paymentMethod, bank },
      isLoading,
      toastInformation,
    } = this.state;

    const membershipDetailsData = !_.isEmpty(details) ? details : {};
    const prevUrl = backUrl;

    return (
      <div>
        <Helmet
          title={`FITCO | Membership - Payment Details ${
            !_.isEmpty(membershipDetailsData.recurring_details)
              ? membershipDetailsData.recurring_details.recurring_id
              : ''
          } `}
        />
        <div className="container-page-membership-payment-details scroll-container-invisible">
          <div className="container-page-scrolling-area">
            <Grid container direction="column">
              <Grid item lg md className="section-page-header">
                <div className="breadcrumbs-section">
                  <Breadcrumbs aria-label="breadcrumb">
                    <Link
                      className="text-12"
                      color="inherit"
                      href={prevUrl}
                      onClick={event => {
                        this.handleClick(event, prevUrl);
                      }}
                    >
                      <i className="ic-ffo-memberships container-icon-prefix size-16" />
                      User Details
                    </Link>
                    <Link
                      className="text-12"
                      color="inherit"
                      href={prevUrl}
                      onClick={event => {
                        this.handleClick(event, prevUrl);
                      }}
                    >
                      Membership Payment
                    </Link>
                    <label className="text-12" color="inherit">
                      Details
                    </label>
                  </Breadcrumbs>
                </div>
              </Grid>
              <Grid item lg md className="section-page-body">
                {isLoading ? null : ( // <SkeletonOrderDetails />
                  <div className="container-main-card flex-column p-32">
                    <Grid container justify="flex-start" className="mb-16">
                      <Grid item lg md>
                        <label className="text-16 text-bold text-rolling-stone">
                          Details Payment
                        </label>
                      </Grid>
                    </Grid>
                    {this.renderMembershipCardDetails(membershipDetailsData)}
                    {this.renderRecurringPaymentDetails(
                      membershipDetailsData.recurring_payment_details,
                    )}
                    <Grid container direction="column">
                      <Grid item lg={12} md={12}>
                        <Grid container spacing={2}>
                          <Grid item lg={6} md={6}>
                            <FormControl component="fieldset" fullWidth margin="normal">
                              <FormLabel component="label" className="text-12 filed-title">
                                Payment Method
                              </FormLabel>
                              <SelectInputMain
                                options={paymentMethodOption}
                                currentValue={
                                  paymentMethod || paymentMethodOptionCode.bank_transfer
                                }
                                onChange={this.handleSelectPaymentMethod}
                              />
                            </FormControl>
                          </Grid>
                          {paymentMethod === paymentMethodOptionCode.bank_transfer && (
                            <Grid item lg={6} md={6} className="mt-27">
                              <FormControl component="fieldset" fullWidth margin="normal">
                                <RadioInputImage
                                  data={bankTransferOption}
                                  onSelect={this.handleChangeBankTransfer}
                                  direction="column"
                                  currentValue={bank}
                                  imageWidth="60px"
                                />
                              </FormControl>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                      {/* <Grid item lg={12} md={12}>
                        <Grid container spacing={2}>
                          <Grid item lg={6} md={6}>
                            <FormControl component="fieldset" fullWidth margin="normal">
                              <FormLabel component="label" className="text-12 filed-title">
                                Payment Term
                              </FormLabel>
                              <div className="container-remove-margin">
                                <SelectInputMain
                                  options={paymentTermOption}
                                  currentValue={form.paymentTerm}
                                  onChange={this.handleChangePaymentTerm}
                                  disabled={disableContent}
                                />
                              </div>
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Grid> */}
                    </Grid>
                    <label className="text-12 text-opacity-50 text-bold mt-32 mb-16">
                      Member Details
                    </label>
                    <Grid
                      container
                      direction="column"
                      className="container-row-table details flex-wrap-unset"
                    >
                      <GridRowTableOneColumn
                        customElementInitial={
                          <label className="text-14 title text-rolling-stone pl-12">Name</label>
                        }
                        customElementColumn={
                          <label className="text-14">
                            {membershipDetailsData.member_details.member_name}
                          </label>
                        }
                        columnLeft={3}
                        columnRight={9}
                      />
                      <GridRowTableOneColumn
                        customElementInitial={
                          <label className="text-14 title text-rolling-stone pl-12">Email</label>
                        }
                        customElementColumn={
                          <label className="text-14 wrapping-container-break">
                            {membershipDetailsData.member_details.email || '-'}
                          </label>
                        }
                        columnLeft={3}
                        columnRight={9}
                      />
                      <GridRowTableOneColumn
                        customElementInitial={
                          <label className="text-14 title text-rolling-stone pl-12">
                            Phone Number
                          </label>
                        }
                        customElementColumn={
                          <label className="text-14">
                            {membershipDetailsData.member_details.phone || '-'}
                          </label>
                        }
                        columnLeft={3}
                        columnRight={9}
                      />
                      <GridRowTableOneColumn
                        customElementInitial={
                          <label className="text-14 title text-rolling-stone pl-12">
                            Recurring Date
                          </label>
                        }
                        customElementColumn={
                          <label className="text-14">
                            {membershipDetailsData.member_details.recurring_date || '-'}
                          </label>
                        }
                        columnLeft={3}
                        columnRight={9}
                      />
                    </Grid>
                    <Grid container spacing={2} justify="flex-end">
                      <Grid item lg={2} md={2}>
                        <ButtonMain
                          type="ghost"
                          size="xl"
                          labelText="Back"
                          onClick={this.handleButtonCancel}
                          disabled={fetching}
                        />
                      </Grid>
                      <Grid item lg={2} md={2}>
                        <ButtonMain
                          type="primary"
                          size="xl"
                          labelText="Save"
                          onClick={this.handleButtonSave}
                          disabled={fetching}
                        />
                      </Grid>
                    </Grid>
                  </div>
                )}
              </Grid>
              <Grid item lg md className="section-page-footer" />
            </Grid>
          </div>
        </div>
        <SnackBarSimple
          open={toastInformation.isOpen}
          durationHide={2000}
          message={toastInformation.message}
          onClickClose={this.handleCloseToash}
          snackbarType={toastInformation.snackbarType}
          anchor={optionToash}
        />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  membershipDetailsById: params => getMembershipDetailsById(dispatch, params),
  membershipRecurringById: (id, params) => setMembershipRecurringById(dispatch, id, params),
});

const mapStateToProps = ({ membership }) => ({ membership });

MembershipChangeRecurring.propTypes = {
  checkUserAccessPermission: PropTypes.func,
  history: PropTypes.object,
  match: PropTypes.object,
  membership: PropTypes.object,
  membershipDetailsById: PropTypes.func,
  membershipRecurringById: PropTypes.func,
};

const shell = compose(connect(mapStateToProps, mapDispatchToProps));
const core = compose(AuthenticationAccessPages);

export default shell(core(MembershipChangeRecurring));
