import React from 'react';
import PropTypes from 'prop-types';
import { Paper, Grid } from '@material-ui/core';
// style
import './HeaderRoleAndPermissionStyle.scss';
// component
import { ButtonMain, TabNavigation } from '../../../../../components/Index';
import { PermissionAccess, PermissionModule, PermissionPage } from '../../../../../helpers/PermissionHelper';

const HeaderRoleAndPermission = props => {
  const { onChangeTab, onButtonClickPermission, onButtonClickRole, data, currentTab } = props;
  let dynamicColumn;

  const handleChangeTab = value => {
    onChangeTab(value);
  };

  const handleButtonClickPermission = value => {
    onButtonClickPermission(value);
  };

  const handleButtonClickRole = value => {
    onButtonClickRole(value);
  };

  const renderButtonActive = () => {
    let renderElement = null;
    switch (data[currentTab].name) {
      case data[0].name:
        renderElement = (
          <ButtonMain
            labelText="Create Role"
            onClick={handleButtonClickRole}
            type="primary"
            size="md"
            startIcon="ic-ffo-add"
            requiredPermission={`${PermissionModule.UserManagement}.${PermissionPage.RolesAndPermissions}.${PermissionAccess.Add}`}
          />
        );
        break;

      default:
        dynamicColumn = 3;
        renderElement = (
          <ButtonMain
            labelText="Create Permission"
            onClick={handleButtonClickPermission}
            type="primary"
            size="md"
            startIcon="ic-ffo-add"
            requiredPermission={`${PermissionModule.UserManagement}.${PermissionPage.RolesAndPermissions}.${PermissionAccess.Add}`}
          />
        );
        break;
    }

    return renderElement;
  };

  return (
    <Paper square className="container-tab-header-role-and-permission">
      <Grid container>
        <Grid item lg md>
          <TabNavigation data={data} onChange={handleChangeTab} currentTab={currentTab} />
        </Grid>
        <Grid item lg={dynamicColumn} md={dynamicColumn} className="container-header-button">
          {renderButtonActive()}
        </Grid>
      </Grid>
    </Paper>
  );
};

HeaderRoleAndPermission.propTypes = {
  currentTab: PropTypes.number,
  data: PropTypes.array,
  onButtonClickPermission: PropTypes.func,
  onButtonClickRole: PropTypes.func,
  onChangeTab: PropTypes.func,
};

export default HeaderRoleAndPermission;
