import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';

const GridRowTableOneColumn = props => {
  const { customElementColumn, customElementInitial, columnLeft, columnRight, paddingLeft,  paddingRight } = props;

  return (
    <Grid item className="row-table">
      <Grid container>
        <Grid item lg={columnLeft} md={columnLeft} sm={columnLeft} className={`left ${[paddingLeft]}`}>
          {customElementInitial}
        </Grid>
        <Grid item lg={columnRight} md={columnRight} sm={columnRight} className={`right ${[paddingRight]}`}>
          {customElementColumn}
        </Grid>
      </Grid>
    </Grid>
  );
};

GridRowTableOneColumn.propTypes = {
  columnLeft: PropTypes.number,
  columnRight: PropTypes.number,
  customElementColumn: PropTypes.element,
  customElementInitial: PropTypes.element,
  paddingLeft: PropTypes.string,
  paddingRight: PropTypes.string
};

GridRowTableOneColumn.defaultProps = {
  columnLeft: 3,
  columnRight: 9,
  paddingLeft: 'pl-8',
  paddingRight: 'pr-8',
};

export default GridRowTableOneColumn;
