import React from 'react';
import PropTypes from 'prop-types';
import { Grid, FormControl, FormLabel } from '@material-ui/core';
import _ from 'lodash';
// component
import { ButtonMain } from '../../../../../../../../components/Index';
// helper
import { CommonHelper } from '../../../../../../../../helpers/Index';
// Style
import './DetailsAppointmentStyle.scss';

const DetailsAppointment = props => {
  const { orderData, handleButtonReschedule } = props;
  const appointmentDetails = !_.isEmpty(orderData) ? orderData : {};

  return (
    <Grid container className="container-appointment-details" direction="column">
      <FormControl component="fieldset" fullWidth margin="normal">
        <FormLabel component="label" className="text-12 filed-title">
          Appointment Details
        </FormLabel>
        <Grid item lg={12} md={12} className="order-card-details">
          <Grid container direction="column">
            <Grid item lg={12} md={12}>
              <Grid container direction="row">
                <Grid item lg={6} md={6}>
                  <div className="flex-column pb-24">
                    <label className="text-12 text-bold text-rolling-stone wrapping-container wrapping-container-break mb-8">
                      Service
                    </label>
                    <label className="text-14 wrapping-container wrapping-container-break">
                      {appointmentDetails.serviceLocation === 'in_store'
                        ? 'In Store Service'
                        : 'Home Service'}
                    </label>
                  </div>
                </Grid>
                <Grid item lg={4} md={4} className="mt-4">
                  <ButtonMain
                    labelText="Reschedule"
                    onClick={handleButtonReschedule}
                    type="primary"
                    size="sm"
                    startIcon="ic-ffo-calendar"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={12} md={12}>
              <Grid container direction="row">
                <Grid item lg={6} md={6}>
                  <div className="flex-column pb-24">
                    <label className="text-12 text-bold text-rolling-stone wrapping-container wrapping-container-break mb-8">
                      Date
                    </label>
                    <label className="text-14 wrapping-container wrapping-container-break">
                      {`${CommonHelper.dateTimeParseNewFormat(
                        appointmentDetails.form.date,
                        'dddd',
                      )}, ${CommonHelper.dateTimeParseNewFormat(
                        appointmentDetails.form.date,
                        'DD MMM YYYY',
                      )}`}
                    </label>
                  </div>
                </Grid>
                <Grid item lg={6} md={6}>
                  <div className="flex-column pb-24">
                    <label className="text-12 text-bold text-rolling-stone wrapping-container wrapping-container-break mb-8">
                      Time
                    </label>
                    <label className="text-14 wrapping-container wrapping-container-break">
                      {`${appointmentDetails.form.selectedTime.start_time} - ${appointmentDetails.form.selectedTime.end_time}`}
                    </label>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={12} md={12}>
              <div className="flex-column pb-24">
                <label className="text-12 text-bold text-rolling-stone wrapping-container wrapping-container-break mb-8">
                  Address
                </label>
                <label className="text-14 wrapping-container third line wrapping-container-break">
                  {appointmentDetails.form.address || appointmentDetails.selectedService.address}
                </label>
              </div>
            </Grid>
            <Grid item lg={12} md={12}>
              <div className="flex-column pb-24">
                <label className="text-12 text-bold text-rolling-stone wrapping-container wrapping-container-break mb-8">
                  Notes
                </label>
                <label className="text-14 wrapping-container third line wrapping-container-break">
                  {appointmentDetails.form.addressNotes || appointmentDetails.selectedService.name}
                </label>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </FormControl>
    </Grid>
  );
};

DetailsAppointment.propTypes = {
  handleButtonReschedule: PropTypes.func,
  orderData: PropTypes.object,
};

export default DetailsAppointment;
