import React from 'react';
import { Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
// style
import './SkeletonConductPTStyle.scss';

export const SkeletonChartConduct = () => {
  return (
    <Grid item lg={12} className="pl-24">
      <Skeleton variant="rect" width={'100%'} height={300} animation="wave" />
    </Grid>
  );
};

export const SkeletonConduct = () => {
  return (
    <Grid item lg={12}>
      <Skeleton variant="rect" height={80} animation="wave" />
    </Grid>
  );
};

export const SkeletonSessions = () => {
  return (
    <Grid container direction={'column'} spacing={3} className="pt-24">
      <Grid item>
        <Skeleton variant="rect" height={40} animation="wave" />
      </Grid>
      <Grid item>
        <Skeleton variant="rect" height={40} animation="wave" />
      </Grid>
      <Grid item>
        <Skeleton variant="rect" height={40} animation="wave" />
      </Grid>
    </Grid>
  );
};

export const SkeletonTopPT = () => {
  return (
    <Grid container direction={'column'} spacing={1} className="pt-24">
      <Grid item>
        <Skeleton variant="rect" height={40} animation="wave" />
      </Grid>
      <Grid item>
        <Skeleton variant="rect" height={40} animation="wave" />
      </Grid>
      <Grid item>
        <Skeleton variant="rect" height={40} animation="wave" />
      </Grid>
      <Grid item>
        <Skeleton variant="rect" height={40} animation="wave" />
      </Grid>
      <Grid item>
        <Skeleton variant="rect" height={40} animation="wave" />
      </Grid>
    </Grid>
  );
};

