import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
// Style
import './CardGalleryContentStyle.scss';
import { Grid } from '@material-ui/core';

const CardGalleryContent = props => {
  const {
    image,
    title,
    body,
    customElementsImage,
    customElementsHeader,
    customElementsBody,
    onClick,
  } = props;

  return (
    <Grid container className="container-card-gallery-content" onClick={onClick}>
      <Grid item lg md className="section-card">
        <div className="flex-column">
          {image && (
            <div className="container-image">
              <img src={image} alt="popup-screen" />
            </div>
          )}
          {customElementsImage}
          <label className="text-14 title pt-8">{title}</label>
          {customElementsHeader}
          {!_.isEmpty(body) ? (
            <label className="text-12 body pt-8 wrapping-container-break wrapping-container third line">
              {body}
            </label>
          ) : null}
          {customElementsBody}
        </div>
      </Grid>
    </Grid>
  );
};

CardGalleryContent.propTypes = {
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  customElementsBody: PropTypes.element,
  customElementsHeader: PropTypes.element,
  customElementsImage: PropTypes.element,
  image: PropTypes.string,
  onClick: PropTypes.func,
  title: PropTypes.string,
};

export default CardGalleryContent;
