import React from 'react';
import PropTypes from 'prop-types';
import swal from 'sweetalert';
// style
import './ModalAlertConfirmationStyle.scss';
// component
import { ButtonMain, ButtonIconMain } from '../../Index';

const ModalAlertConfirmation = props => {
  const { label, size, type, onClick, optionModal, icon, isButtonIcon, disabled, requiredPermission } = props;

  const handleButtonClick = () => {
    swal({
      title: optionModal.title,
      text: optionModal.text,
      buttons: true,
      dangerMode: true,
    }).then(value => {
      if (value) {
        onClick(value);
      }
    });
  };

  let renderElement = (
    <ButtonMain
      labelText={label}
      onClick={handleButtonClick}
      type={type}
      size={size}
      icon={icon}
      disabled={disabled}
      requiredPermission={requiredPermission}
    />
  );

  if (isButtonIcon) {
    renderElement = (
      <ButtonIconMain
        icon={icon}
        type={type}
        size={size}
        onClick={handleButtonClick}
        disabled={disabled}
        requiredPermission={requiredPermission}
      />
    );
  }

  return renderElement;
};

ModalAlertConfirmation.propTypes = {
  disabled: PropTypes.bool,
  icon: PropTypes.string,
  isButtonIcon: PropTypes.bool,
  label: PropTypes.string,
  onClick: PropTypes.func,
  optionModal: PropTypes.object,
  requiredPermission: PropTypes.string,
  size: PropTypes.string,
  type: PropTypes.string,
};

export default ModalAlertConfirmation;
