import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Grid } from '@material-ui/core';
// Style
import './MediaInputStyle.scss';
// component
import { RadioInput, TextInput } from '../../Index';
// helper
import { CommonHelper, MasterDataHelper } from '../../../helpers/Index';
import UploadImage from '../upload-image/UploadImage';
import ButtonMain from '../button-main/ButtonMain';

const mediaOption = MasterDataHelper.mediaOption;

const initialForm = {
  activeMedia: null,
  base64: [],
  url: '',
  hoverItem: '',
};

const initialValidation = {
  base64: { isError: '', errorMessage: '' },
  url: { isError: false, errorMessage: '' },
};

class MediaInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      form: CommonHelper.objectCloning(initialForm),
      validation: CommonHelper.objectCloning(initialValidation),
    };
    this.parameterUpdateDebounce = _.debounce(this.parameterUpdateDebounce, 600);
  }

  componentDidMount() {}

  componentWillReceiveProps(prevProps) {
    const { form, validation } = this.state;
    const isEqualFormParent = _.isEqual(form, prevProps.parentForm);
    const isEqualValidationParent = _.isEqual(validation, prevProps.parentValidation);

    if (!isEqualFormParent || !isEqualValidationParent) {
      this.setState({
        form: { ...form, ...prevProps.parentForm },
        validation: prevProps.parentValidation,
      });
    }
  }

  handleChangeMediaUpload = value => {
    const { form, validation } = this.state;
    const formNewValue = CommonHelper.objectCloning(form);

    this.setState({ form: { ...form, activeMedia: value } }, () => {
      formNewValue.activeMedia = value;
      this.parameterUpdateDebounce(formNewValue, validation);
    });
  };

  handleToggleHover = keyItem => {
    this.setState({ hoverItem: keyItem });
  };

  handleToggleHoverLeave = () => {
    this.setState({ hoverItem: '' });
  };

  handleButtonDeleteImage = index => {
    const { form, validation } = this.state;
    const formNewValue = CommonHelper.objectCloning(form);

    formNewValue.base64.splice(index, 1);

    this.setState({ form: { ...form, base64: formNewValue.base64 } }, () => {
      this.parameterUpdateDebounce(formNewValue, validation);
    });
  };

  handleChangeImage = imageBase64 => {
    const { form, validation } = this.state;
    const formNewValue = CommonHelper.objectCloning(form);
    const resetValidation = CommonHelper.objectCloning(validation);
    let modifyBase64 = [];
    const message = CommonHelper.objectCloning(initialValidation.base64);

    if (!imageBase64 || imageBase64 === '') {
      message.isError = 'error';
      message.errorMessage = 'Please choose Image';
    } else {
      modifyBase64 = [...form.base64, imageBase64];
    }

    this.setState(
      {
        form: { ...form, base64: modifyBase64 },
        validation: { ...validation, base64: message },
      },
      () => {
        formNewValue.base64 = modifyBase64;
        resetValidation.base64 = message;
        this.parameterUpdateDebounce(formNewValue, resetValidation);
      },
    );
  };

  handleTextChangeUrl = value => {
    const { form, validation } = this.state;
    const formNewValue = CommonHelper.objectCloning(form);
    const resetValidation = CommonHelper.objectCloning(validation);
    const message = CommonHelper.objectCloning(initialValidation.url);
    const valid = !value;
    message.isError = valid ? 'error' : message.isError;
    message.errorMessage = valid ? 'URL is empty' : message.errorMessage;

    const resultValue = !value ? '' : value;

    this.setState(
      {
        form: { ...form, url: resultValue.trim() },
        validation: { ...validation, url: message },
      },
      () => {
        formNewValue.url = resultValue.trim();
        resetValidation.url = message;
        this.parameterUpdateDebounce(formNewValue, resetValidation);
      },
    );
  };

  parameterUpdateDebounce = (form, validation) => {
    const { onParameterUpdate } = this.props;
    onParameterUpdate(form, validation);
  };

  renderMedia() {
    const { form, hoverItem } = this.state;

    let renderElement = (
      <Grid
        container
        justify="flex-start"
        className="container-media-gallery-input scroll-container"
      >
        {form.base64.map((item, index) => {
          const visibleButton = hoverItem === index;
          return (
            <Grid
              key={index}
              onMouseEnter={() => this.handleToggleHover(index)}
              onMouseLeave={() => this.handleToggleHoverLeave()}
              item
              lg={4}
              md={4}
              className="p-12"
            >
              <div className="container-gallery">
                <img
                  src={item}
                  className={`custom-image-file ${visibleButton && 'opacity035'}`}
                  alt="file"
                />
                {visibleButton ? (
                  <div className="container-button-floating">
                    <ButtonMain
                      type="ghost"
                      size="md"
                      labelText="Delete"
                      onClick={() => this.handleButtonDeleteImage(index)}
                    />
                  </div>
                ) : null}
              </div>
            </Grid>
          );
        })}
        <Grid item lg={4} md={4} className="p-12">
          <UploadImage
            onChange={item => this.handleChangeImage(item)}
            maxSize={1}
            validateStatus={this.state.validation.base64.isError}
            errorMessage={this.state.validation.base64.errorMessage}
          />
        </Grid>
      </Grid>
    );

    if (form.activeMedia) {
      switch (form.activeMedia.value) {
        case mediaOption[1].value:
          renderElement = (
            <TextInput
              placeHolderText="exp: https://(video Url)"
              onChange={this.handleTextChangeUrl}
              currentValue={form.url}
              errorMessage={this.state.validation.url.errorMessage}
              isError={this.state.validation.url.isError}
              size="md"
              maxLength={50}
            />
          );
          break;

        default:
          break;
      }
    }

    return renderElement;
  }

  render() {
    const { form } = this.state;
    return (
      <Grid container direction="column" className="container-media-input">
        <Grid item lg md className="mb-8">
          <RadioInput
            data={mediaOption}
            onSelect={this.handleChangeMediaUpload}
            direction="row"
            currentValue={form.activeMedia}
          />
        </Grid>
        <Grid item lg md>
          {this.renderMedia()}
        </Grid>
      </Grid>
    );
  }
}

MediaInput.propTypes = {
  onParameterUpdate: PropTypes.func,
  parentForm: PropTypes.object,
  parentValidation: PropTypes.object,
};

export default MediaInput;
