import React from 'react';
import PropTypes from 'prop-types';
import { Page, Text, View, Document, Font, Image } from '@react-pdf/renderer';
import _ from 'lodash';
// Style
import StylesJs from './EatCateringInvoiceStyle';
// assets
import { Images, StyleJs as StyleJsGlobal } from '../../../../../assets/Index';
// Helpers
import { CommonHelper } from '../../../../../helpers/Index';

// Register font
Font.register({
  family: 'Roboto',
  fonts: [
    { src: './src/assets/fonts/montserrat/Montserrat-Regular.ttf' },
    { src: './src/assets/fonts/montserrat/Montserrat-Bold.ttf', fontWeight: 700 },
  ],
});

const EatCateringInvoice = props => {
  const { data } = props;

  const letterheadLogo = Images.fitcoLogoLocal;
  const customerDetails = !_.isEmpty(data.customer_details) ? data.customer_details : {};
  const shippingDetails = !_.isEmpty(data.shipping_details) ? data.shipping_details : {};
  const orderDetails = !_.isEmpty(data.order_details) ? data.order_details : {};
  const orderSummary = !_.isEmpty(data.order_summary) ? data.order_summary : {};
  const roomDetails = shippingDetails.shipping_notes.split(', ');

  return (
    <Document>
      <Page size="A4" orientation="portrait">
        <View style={[StyleJsGlobal.gridColumn, StyleJsGlobal.ph24, { width: 596 }]}>
          <View style={[StyleJsGlobal.gridItem12, StyleJsGlobal.mb20, { paddingTop: 24 }]} fixed>
            <View style={[StyleJsGlobal.gridRow, StyleJsGlobal.justifyContentSpaceBetween]}>
              <View style={[StyleJsGlobal.gridItem9]}>
                <Text style={[StylesJs.titleText]}>{'INVOICE DINE IN'}</Text>
              </View>
              <View style={[StyleJsGlobal.gridItem3, { flex: 1, alignItems: 'flex-end' }]}>
                <Image src={letterheadLogo} style={[StylesJs.containerLogo]} />
              </View>
            </View>
          </View>
          <View style={[StyleJsGlobal.gridItem6, StyleJsGlobal.mb20]}>
            <Text style={[StylesJs.text, StylesJs.fontBold, StylesJs.marginBottomThin]}>
              {shippingDetails.shipping_title}
            </Text>
            <Text style={[StylesJs.text]}>{shippingDetails.shipping_address}</Text>
          </View>
          <View style={[StyleJsGlobal.gridItem12, StyleJsGlobal.mb20]}>
            <View style={[StyleJsGlobal.gridRow, StyleJsGlobal.justifyContentSpaceBetween]}>
              <View style={[StyleJsGlobal.gridItem6]}>
                <Text style={[StylesJs.subTitleText]}>{'Bill To'}</Text>
                <Text style={[StylesJs.text, StylesJs.fontNormal]}>
                  {customerDetails.customer_name}
                </Text>
                <Text style={[StylesJs.text, StylesJs.fontNormal]}>{roomDetails[0]}</Text>
                <Text style={[StylesJs.text, StylesJs.fontNormal]}>{roomDetails[1]}</Text>
                <Text style={[StylesJs.text, StylesJs.fontNormal]}>{'RSPAD Gatot Soebroto'}</Text>
              </View>
              <View style={[StyleJsGlobal.gridItem6, { flex: 1 }]}>
                <View style={[StyleJsGlobal.flexColumn]}>
                  <View style={[StyleJsGlobal.gridRow]}>
                    <View style={[StyleJsGlobal.gridItem6, { paddingRight: 10, flex: 1 }]}>
                      <Text style={[StylesJs.subTitleText]}>{'Invoice Number'}</Text>
                    </View>
                    <View style={[StyleJsGlobal.gridItem6, { flex: 1 }]}>
                      <Text style={[StylesJs.text, StylesJs.fontNormal, StyleJsGlobal.textRight]}>
                        {orderDetails.order_no}
                      </Text>
                    </View>
                  </View>
                  <View style={[StyleJsGlobal.gridRow]}>
                    <View style={[StyleJsGlobal.gridItem6, { paddingRight: 10, flex: 1 }]}>
                      <Text style={[StylesJs.subTitleText]}>{'Invoice Date'}</Text>
                    </View>
                    <View style={[StyleJsGlobal.gridItem6]}>
                      <Text style={[StylesJs.text, StylesJs.fontNormal, StyleJsGlobal.textRight]}>
                        {CommonHelper.dateTimeParseNewFormat(orderDetails.order_date, 'DD/MM/YYYY')}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
          <View style={[StyleJsGlobal.gridItem12, StyleJsGlobal.mb20]}>
            <View
              style={[
                StyleJsGlobal.gridRow,
                StyleJsGlobal.alignItemCenter,
                StyleJsGlobal.mb8,
                StylesJs.paddingVertical15,
                StylesJs.paddingHorizontal8,
                StylesJs.borderVertical,
              ]}
            >
              <View style={[StyleJsGlobal.gridItem6, { width: '50%' }]}>
                <Text style={[StylesJs.subTitleText]}>Product Name</Text>
              </View>
              <View style={[StyleJsGlobal.gridItem3, StyleJsGlobal.alignItemCenter, { flex: 1 }]}>
                <Text style={[StylesJs.subTitleText]}>Quantity</Text>
              </View>
              <View style={[StyleJsGlobal.gridItem3, { flex: 1, alignItems: 'flex-end' }]}>
                <Text style={[StylesJs.subTitleText]}>Price</Text>
              </View>
            </View>
            {orderSummary.product_details.map(item => {
              return (
                <View
                  style={[
                    StyleJsGlobal.gridRow,
                    StyleJsGlobal.alignItemCenter,
                    StylesJs.paddingVertical8,
                    StylesJs.paddingHorizontal8,
                  ]}
                >
                  <View style={[StyleJsGlobal.gridItem6, { width: '50%' }]}>
                    <Text style={[StylesJs.text, StylesJs.fontNormal]}>{item.product_name}</Text>
                  </View>
                  <View
                    style={[StyleJsGlobal.gridItem3, StyleJsGlobal.alignItemCenter, { flex: 1 }]}
                  >
                    <Text style={[StylesJs.text, StylesJs.fontNormal]}>{item.quantity}</Text>
                  </View>
                  <View style={[StyleJsGlobal.gridItem3, { flex: 1, alignItems: 'flex-end' }]}>
                    <Text
                      style={[StylesJs.text, StylesJs.fontNormal]}
                    >{`Rp ${CommonHelper.formatCurrency(item.item_price) || '0'}`}</Text>
                  </View>
                </View>
              );
            })}
          </View>
          <View style={[StyleJsGlobal.gridItem12]}>
            {orderSummary.promo_code && (
              <View style={{ flex: 1, justifyContent: 'flex-start' }}>
                <Text style={[StylesJs.text, StylesJs.fontBold, { marginBottom: 8 }]}>
                  {'Promo Code Applied'}
                </Text>
                <Text style={[StylesJs.text]}>{orderSummary.promo_code}</Text>
              </View>
            )}
            {orderSummary.price_discount > 0 && (
              <View style={[StyleJsGlobal.gridRow, { flex: 1, justifyContent: 'flex-end' }]}>
                <View style={[StyleJsGlobal.gridItem3, { flex: 1, alignItems: 'flex-end' }]}>
                  <Text style={[StylesJs.text, StyleJsGlobal.mr24]}>{'Discount'}</Text>
                </View>
                <View style={[StyleJsGlobal.gridItem3, { flex: 1, alignItems: 'flex-end' }]}>
                  <Text style={[StylesJs.text]}>{`Rp ${CommonHelper.formatCurrency(
                    orderSummary.price_discount,
                  )}`}</Text>
                </View>
              </View>
            )}
            <View style={[StyleJsGlobal.gridRow, { flex: 1, justifyContent: 'flex-end' }]}>
              <View style={[StyleJsGlobal.gridItem3, { flex: 1, alignItems: 'flex-end' }]}>
                <Text style={[StylesJs.subTitleText, StyleJsGlobal.mr24]}>{'Grand Total'}</Text>
              </View>
              <View style={[StyleJsGlobal.gridItem3, { flex: 1, alignItems: 'flex-end' }]}>
                <Text style={[StylesJs.subTitleText]}>{`Rp ${CommonHelper.formatCurrency(
                  orderSummary.grand_total,
                ) || '0'}`}</Text>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

EatCateringInvoice.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default EatCateringInvoice;
