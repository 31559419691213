import React from 'react';
import PropTypes from 'prop-types';
import { Grid, IconButton, Checkbox } from '@material-ui/core';
import { connect } from 'react-redux';
// style
import './ModalTermConditionsStyle.scss';
// component
import { ModalInformationPopUp } from '../../../../../../../../components/Index';

const ModalTermConditions = ({ onClose, isOpen, onHandleCheckAgreement, checked, data }) => {
  const renderElement = (
    <Grid container direction="column" className="flex-wrap-unset container-modal-term-conditions">
      <Grid item lg md className="section-header-modal">
        <Grid container justify="space-between">
          <Grid item lg={11} md={11}>
            <h5 className="text-10 text-bold">Patient Agreement</h5>
          </Grid>
          <Grid item>
            <div className="container-button-close">
              <IconButton onClick={onClose}>
                <i className="ic-ffo-close container-icon-prefix size-21" />
              </IconButton>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg md className="section-body-modal">
        <Grid container>
          <Grid item>
            <label className="text-14 text-semi-bold">
              I, the undersigned, do hereby acknowledge:
            </label>
            <div className="scroll-container mt-8 pr-10">
              {data.map((item, index) => {
                return (
                  <div className="container-section" key={index}>
                    <ul>
                      <li>
                        <label className="text-14">{item.text}</label>
                      </li>
                    </ul>
                  </div>
                );
              })}
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg md className="section-footer-modal">
        <Grid container direction="row">
          <Grid item>
            <Checkbox
              color="primary"
              className="custom-checkbox"
              checked={checked}
              onChange={onHandleCheckAgreement}
            />
          </Grid>
          <Grid item className="flex-column ml-12">
            <div>
              <label className="text-14 text-rolling-stone">
                {`I agree to Fitco and Eminence Term & Conditions`}
              </label>
            </div>
            <div>
              <label className="text-12 text-rolling-stone">
                {`Please read correctly and carefully patient agreement.`}
              </label>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <ModalInformationPopUp
      isOpen={isOpen}
      onClose={onClose}
      customElementProps={renderElement}
      size="small"
    />
  );
};

const mapDispatchToProps = () => ({});

const mapStateToProps = () => ({});

ModalTermConditions.propTypes = {
  checked: PropTypes.bool,
  data: PropTypes.array,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onHandleCheckAgreement: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalTermConditions);
