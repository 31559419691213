import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Helmet } from 'react-helmet';
import { Grid, Breadcrumbs, Link } from '@material-ui/core';
import _ from 'lodash';
// Style
import './EatDetailsStyle.scss';
// component
import {
  SnackBarSimple,
  ButtonMain,
  LabelStatusMain,
  ModalAlertAction,
  AuthenticationAccessPages,
  GridRowTableOneColumn,
} from '../../../components/Index';
import { ModalEatMenuQrCode } from '../components/Index';
import { SkeletonDetailsV02 } from '../../../components/skeleton/Index';
// api
import { getEatDetails, setDeleteEat } from '../../../services/api/EatApi';
// Helper
import {
  CommonHelper,
  MasterDataHelper,
  UserStatus,
  HttpStatusCode,
  PermissionModule,
  PermissionPage,
  PermissionAccess,
  currencyCode,
  PrintHelper,
} from '../../../helpers/Index';

const optionToash = {
  vertical: 'top',
  horizontal: 'right',
};

class EatDetails extends React.Component {
  constructor(props) {
    super(props);
    const {
      match: { params },
    } = this.props;

    props.checkUserAccessPermission(
      PermissionModule.Eat,
      PermissionPage.Food,
      PermissionAccess.View,
    );

    this.state = {
      foodId: CommonHelper.decryptObject(params.param_object).foodId,
      isOpenModalPrint: false,
      selectedPrint: [],
      toastInformation: {
        isOpen: false,
        message: '',
        snackbarType: 'warning',
      },
      // isLoading: true,
      isOpenModalDelete: false,
      modalDetailData: {
        title: 'Confirmation',
        body: 'Are you sure want to delete',
      },
    };
  }

  componentDidMount() {
    this.getEatDetails();
  }

  getEatDetails() {
    const { eatDetails } = this.props;
    const { foodId } = this.state;

    eatDetails(foodId).catch(error => {
      const serverMessage = error.data;
      const validationStatus = error.status === HttpStatusCode.InternalServerError;
      this.processMessage(
        validationStatus ? serverMessage.message : serverMessage.messages,
        'error',
      );
    });
  }

  setDeleteEat(params = {}) {
    const { deleteEat, history } = this.props;

    deleteEat(params)
      .then(() => {
        const {
          eatData: { responseMessage },
        } = this.props;

        this.setState(
          {
            isOpenModalDelete: false,
          },
          async () => {
            await this.processMessage(responseMessage, 'success');
            await setTimeout(async () => {
              await history.push('/eat/food');
            }, 2000);
          },
        );
      })
      .catch(error => {
        const message = error.data;
        this.processMessage(message.messages, 'error');
      });
  }

  handleClick = (event, value) => {
    const { history } = this.props;
    event.preventDefault();
    history.push(value);
  };

  handleClickEdit = () => {
    const {
      history,
      eatData: { details },
    } = this.props;

    const param = CommonHelper.encryptObject({ foodId: details.food_id });
    history.push(`/eat/edit/${param}`);
  };

  handleButtonClickShowQr = key => {
    const {
      eatData: { details },
    } = this.props;
    const generatedData = PrintHelper.generateDataPrintMenuEat([key], [details]);

    this.setState({
      isOpenModalPrint: true,
      selectedPrint: generatedData,
    });
  };

  handleCloseModalPrint = () => {
    this.setState({
      isOpenModalPrint: false,
    });
  };

  handleClickDelete = () => {
    const { modalDetailData } = this.state;
    const {
      eatData: { details },
    } = this.props;

    this.setState({
      isOpenModalDelete: true,
      modalDetailData: {
        ...modalDetailData,
        body: `Are you sure want to delete ${details.product_name} ?`,
      },
    });
  };

  handleCloseModal = () => {
    this.setState({
      isOpenModalDelete: false,
    });
  };

  handleButtonSubmitDelete = () => {
    const {
      eatData: { details },
    } = this.props;

    this.setDeleteEat(details.food_id);
  };

  handleCloseToash = () => {
    const { toastInformation } = this.state;
    this.setState({ toastInformation: { ...toastInformation, isOpen: false } });
  };

  processMessage(messages, type) {
    const convertedMessage = CommonHelper.generateMessage(messages);

    const paramInformation = {
      isOpen: true,
      message: convertedMessage,
      snackbarType: type,
    };

    this.setState({
      toastInformation: paramInformation,
    });
  }

  renderStatus = userStatus => {
    let renderElement = (
      <LabelStatusMain value={MasterDataHelper.statusGlobal[0].name} type="complete" />
    );
    if (userStatus === UserStatus.Deleted) {
      renderElement = (
        <LabelStatusMain value={MasterDataHelper.statusGlobal[2].name} type="danger" />
      );
    } else if (userStatus === UserStatus.Inactive) {
      renderElement = (
        <LabelStatusMain value={MasterDataHelper.statusGlobal[1].name} type="default" />
      );
    }
    return <div className="container-status">{renderElement}</div>;
  };

  renderInformation = () => {
    const {
      eatData: { details },
    } = this.props;

    const wordingSpecialPricePeriod =
      details.special_from && details.special_to
        ? `${CommonHelper.dateTimeParseNewFormat(
            details.special_from,
            'DD MMM YYYY',
          )} - ${CommonHelper.dateTimeParseNewFormat(details.special_to, 'DD MMM YYYY')}`
        : '-';

    const wordingPrice = details.normal_price
      ? `${currencyCode.idr} ${CommonHelper.formatCurrency(details.normal_price)}`
      : '-';

    const wordingSpecialPrice = details.normal_price
      ? `${currencyCode.idr} ${CommonHelper.formatCurrency(details.special_price)}`
      : '-';

    return (
      <Grid
        container
        className="user-details-card container-tab flex-wrap-unset"
        direction="column"
        justify="flex-start"
      >
        <Grid item className="container-section-information">
          <Grid container direction="column" className="flex-wrap-unset">
            <Grid item className="title-table">
              <label className="text-12">DETAIL PRICE</label>
            </Grid>
            <Grid item>
              <Grid container direction="column" className="container-row-table flex-wrap-unset">
                <GridRowTableOneColumn
                  customElementInitial={<label className="text-14">Normal Price</label>}
                  customElementColumn={<label className="text-14">{wordingPrice}</label>}
                />
                <GridRowTableOneColumn
                  customElementInitial={<label className="text-14">Special Price</label>}
                  customElementColumn={<label className="text-14">{wordingSpecialPrice}</label>}
                />
                <GridRowTableOneColumn
                  customElementInitial={<label className="text-14">Special Price Period</label>}
                  customElementColumn={
                    <label className="text-14">{wordingSpecialPricePeriod}</label>
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item className="container-section-information">
          <Grid container direction="column" className="flex-wrap-unset">
            <Grid item className="title-table">
              <label className="text-12">PRODUCT DESCRIPTION</label>
            </Grid>
            <Grid item>
              <Grid container direction="column" className="container-row-table flex-wrap-unset">
                <GridRowTableOneColumn
                  customElementInitial={<label className="text-14">SKU</label>}
                  customElementColumn={<label className="text-14">{details.sku || '-'}</label>}
                />
                <GridRowTableOneColumn
                  customElementInitial={<label className="text-14">Short Description</label>}
                  customElementColumn={
                    <label className="text-14">{details.short_description || '-'}</label>
                  }
                />
                <GridRowTableOneColumn
                  customElementInitial={<label className="text-14">Description</label>}
                  customElementColumn={
                    <label className="text-14">{details.description || '-'}</label>
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item className="container-section-information">
          <Grid container direction="column" className="flex-wrap-unset">
            <Grid item className="title-table">
              <label className="text-12">DETAILS NUTRITION</label>
            </Grid>
            <Grid item>
              <Grid container direction="column" className="container-row-table flex-wrap-unset">
                <GridRowTableOneColumn
                  customElementInitial={<label className="text-14">Calories</label>}
                  customElementColumn={<label className="text-14">{details.calories || '-'}</label>}
                />
                <GridRowTableOneColumn
                  customElementInitial={<label className="text-14">Carbohydrate</label>}
                  customElementColumn={<label className="text-14">{details.carbs || '-'}</label>}
                />
                <GridRowTableOneColumn
                  customElementInitial={<label className="text-14">Fat</label>}
                  customElementColumn={<label className="text-14">{details.fat || '-'}</label>}
                />
                <GridRowTableOneColumn
                  customElementInitial={<label className="text-14">Protein</label>}
                  customElementColumn={<label className="text-14">{details.protein || '-'}</label>}
                />
                <GridRowTableOneColumn
                  customElementInitial={<label className="text-14">Fiber</label>}
                  customElementColumn={<label className="text-14">{details.fiber || '-'}</label>}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item className="container-section-information">
          <Grid container direction="column" className="flex-wrap-unset">
            <Grid item className="title-table">
              <label className="text-12">COMPANY</label>
            </Grid>
            <Grid item>
              <Grid container direction="column" className="container-row-table flex-wrap-unset">
                <GridRowTableOneColumn
                  customElementInitial={<label className="text-14">Company</label>}
                  customElementColumn={
                    <label className="text-14">{details.company_name || '-'}</label>
                  }
                />
                <GridRowTableOneColumn
                  customElementInitial={<label className="text-14">Merchant</label>}
                  customElementColumn={
                    <label className="text-14">{details.merchant_name || '-'}</label>
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  renderInformationMain = () => {
    const {
      eatData: { details },
    } = this.props;

    const imageExisting = !_.isEmpty(details.product_images)
      ? details.product_images[0].image_url
      : '';

    const caloriesWording = details.calories ? `${details.calories} Cals` : '-';
    const pickUpWording = details.is_pickup_at_store ? 'Yes' : 'No';
    const priceWording = details.price
      ? `${currencyCode.idr} ${CommonHelper.formatCurrency(details.price)}`
      : '-';

    const isDisable = !details.is_pickup_at_store;

    return (
      <Grid container justify="space-between">
        <Grid item lg={3} md={3}>
          {imageExisting ? (
            <div className="container-profile-image">
              <img src={imageExisting} alt="profile" />
            </div>
          ) : (
            <div className="container-profile-image dotted">
              <label className="text-16">No Image</label>
            </div>
          )}
        </Grid>
        <Grid item lg={7} md={7}>
          <Grid
            container
            direction="column"
            justify="flex-start"
            className="container-adjust-padding"
          >
            <Grid item lg md>
              <Grid container direction="column" className="user">
                <Grid item lg md>
                  <span className="flex-row">
                    <h5 className="wrapping-container second line wrapping-container-break mr-8">
                      {details.product_name}
                    </h5>
                    {this.renderStatus(details.status)}
                  </span>
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg md>
              <div className="divider-hr" />
              <Grid container spacing={3} justify="space-around" alignItems="center">
                <Grid item lg={4} md={6}>
                  <div className="flex-column">
                    <label className="text-12 text-semi-bold text-rolling-stone">
                      Pick Up At Store
                    </label>
                    <label className="text-14 text-semi-bold">{pickUpWording}</label>
                  </div>
                </Grid>
                <Grid item lg={3} md={6}>
                  <div className="flex-column">
                    <label className="text-12 text-semi-bold text-rolling-stone">Calories</label>
                    <label className="text-14 text-semi-bold">{caloriesWording}</label>
                  </div>
                </Grid>

                <Grid item lg={4} md={6}>
                  <div className="container-main-card-blue align-center p-16 flex-column">
                    <label className="text-12 text-semi-bold text-rolling-stone">
                      Active Price
                    </label>
                    <label className="text-14 text-bold">{priceWording}</label>
                  </div>
                </Grid>
              </Grid>
              <div className="divider-hr" />
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={2} md>
          <Grid container className="container-user-action" justify="space-around">
            <Grid item lg={12} md>
              <div className="container-user-id">
                <label className="text-14 text-semi-bold">ID: {details.product_id} </label>
              </div>
            </Grid>
            <Grid item lg={12} md>
              <ButtonMain
                type="ghost"
                labelText="Show QR code"
                size="md"
                onClick={() => this.handleButtonClickShowQr(details.food_id)}
                disabled={isDisable}
              />
            </Grid>
            <Grid item lg={12} md>
              <ButtonMain
                type="primary"
                labelText="Edit"
                size="md"
                onClick={this.handleClickEdit}
                requiredPermission={`${PermissionModule.Eat}.${PermissionPage.Food}.${PermissionAccess.Update}`}
              />
            </Grid>
            <Grid item lg={12} md>
              <ButtonMain
                type="danger"
                labelText="Delete"
                size="md"
                onClick={this.handleClickDelete}
                requiredPermission={`${PermissionModule.Eat}.${PermissionPage.Food}.${PermissionAccess.Delete}`}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  render() {
    const {
      toastInformation,
      modalDetailData,
      isOpenModalDelete,
      isOpenModalPrint,
      selectedPrint,
    } = this.state;
    const {
      eatData: { details, fetching },
    } = this.props;
    const prevUrl = '/eat/food';

    let renderElement = (
      <div className="container-page-scrolling-area">
        <SkeletonDetailsV02 />
      </div>
    );

    if (!fetching && details) {
      renderElement = (
        <div>
          <Helmet title="FITCO | Eats - Food Details" />
          <div className="container-page-eat-detail scroll-container">
            <div className="container-page-scrolling-area">
              <Grid container direction="column">
                <Grid item lg md className="section-page-header">
                  <div className="breadcrumbs-section">
                    <Breadcrumbs aria-label="breadcrumb">
                      <Link
                        className="text-12"
                        color="inherit"
                        href={prevUrl}
                        onClick={event => {
                          this.handleClick(event, prevUrl);
                        }}
                      >
                        <i className="ic-ffo-eats container-icon-prefix size-16" />
                        Eat
                      </Link>
                      <label className="text-12" color="inherit">
                        Details
                      </label>
                    </Breadcrumbs>
                  </div>
                </Grid>
                <Grid item lg md className="section-page-body">
                  <Grid container direction="column" className="flex-wrap-unset">
                    <Grid item className="container-main-card container-information-main">
                      {this.renderInformationMain()}
                    </Grid>
                    <Grid item className="container-main-card container-information-log">
                      {this.renderInformation()}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item lg md className="section-page-footer" />
              </Grid>
            </div>
          </div>
          <SnackBarSimple
            open={toastInformation.isOpen}
            durationHide={2000}
            message={toastInformation.message}
            onClickClose={this.handleCloseToash}
            snackbarType={toastInformation.snackbarType}
            anchor={optionToash}
          />
          {isOpenModalDelete && (
            <ModalAlertAction
              onButtonSubmit={this.handleButtonSubmitDelete}
              onCloseModal={this.handleCloseModal}
              modalDetail={modalDetailData}
              modalType="danger"
              buttonSubmitText="Submit"
              openModal={isOpenModalDelete}
            />
          )}
          {isOpenModalPrint && (
            <ModalEatMenuQrCode
              isOpen={isOpenModalPrint}
              data={selectedPrint}
              onClose={this.handleCloseModalPrint}
            />
          )}
        </div>
      );
    }

    return renderElement;
  }
}

const mapDispatchToProps = dispatch => ({
  eatDetails: params => getEatDetails(dispatch, params),
  deleteEat: params => setDeleteEat(dispatch, params),
});

const mapStateToProps = ({ eatData }) => ({
  eatData,
});

EatDetails.propTypes = {
  checkUserAccessPermission: PropTypes.func,
  deleteEat: PropTypes.func,
  eatData: PropTypes.object,
  eatDetails: PropTypes.func,
  history: PropTypes.object,
  match: PropTypes.object,
};

const shell = compose(connect(mapStateToProps, mapDispatchToProps));

export default shell(AuthenticationAccessPages(EatDetails));
