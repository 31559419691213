import { constants } from '../reducers/EatReducer';

export function getListEatReportExportRequest() {
  return { type: constants.getListEatReportExportRequest };
}
export function getListEatReportExportSuccess(response) {
  return { type: constants.getListEatReportExportSuccess, payload: response };
}
export function getListEatReportExportFail(response) {
  return { type: constants.getListEatReportExportFail, payload: response.data };
}

export function getListEatPaginationRequest() {
  return { type: constants.getListEatPaginationRequest };
}
export function getListEatPaginationSuccess(response) {
  return { type: constants.getListEatPaginationSuccess, payload: response };
}
export function getListEatPaginationFail(response) {
  return { type: constants.getListEatPaginationFail, payload: response.data };
}

export function getEatDetailsRequest() {
  return { type: constants.getEatDetailsRequest };
}
export function getEatDetailsSuccess(response) {
  return { type: constants.getEatDetailsSuccess, payload: response };
}
export function getEatDetailsFail(response) {
  return { type: constants.getEatDetailsFail, payload: response.data };
}

export function setDeleteEatRequest() {
  return { type: constants.setDeleteEatRequest };
}
export function setDeleteEatSuccess(response) {
  return { type: constants.setDeleteEatSuccess, payload: response };
}
export function setDeleteEatFail(response) {
  return { type: constants.setDeleteEatFail, payload: response.data };
}

export function setCreateEatRequest() {
  return { type: constants.setCreateEatRequest };
}
export function setCreateEatSuccess(response) {
  return { type: constants.setCreateEatSuccess, payload: response };
}
export function setCreateEatFail(response) {
  return { type: constants.setCreateEatFail, payload: response.data };
}

export function setUpdateEatRequest() {
  return { type: constants.setUpdateEatRequest };
}
export function setUpdateEatSuccess(response) {
  return { type: constants.setUpdateEatSuccess, payload: response };
}
export function setUpdateEatFail(response) {
  return { type: constants.setUpdateEatFail, payload: response.data };
}

export function getListMealDeliveryPaginationRequest() {
  return { type: constants.getListMealDeliveryPaginationRequest };
}
export function getListMealDeliveryPaginationSuccess(response) {
  return { type: constants.getListMealDeliveryPaginationSuccess, payload: response };
}
export function getListMealDeliveryPaginationFail(response) {
  return { type: constants.getListMealDeliveryPaginationFail, payload: response.data };
}

export function getMealDeliveryDetailRequest() {
  return { type: constants.getMealDeliveryDetailRequest };
}
export function getMealDeliveryDetailSuccess(response) {
  return { type: constants.getMealDeliveryDetailSuccess, payload: response };
}
export function getMealDeliveryDetailFail(response) {
  return { type: constants.getMealDeliveryDetailFail, payload: response.data };
}

export function getListMealDeliveryExportRequest() {
  return { type: constants.getListMealDeliveryExportRequest };
}
export function getListMealDeliveryExportSuccess(response) {
  return { type: constants.getListMealDeliveryExportSuccess, payload: response };
}
export function getListMealDeliveryExportFail(response) {
  return { type: constants.getListMealDeliveryExportFail, payload: response.data };
}

export function getListFoodlistRequest() {
  return { type: constants.getListFoodlistRequest };
}
export function getListFoodlistSuccess(response) {
  return { type: constants.getListFoodlistSuccess, payload: response };
}
export function getListFoodlistFail(response) {
  return { type: constants.getListFoodlistFail, payload: response.data };
}

export function changeMealDeliveryDetailsRequest() {
  return { type: constants.changeMealDeliveryDetailsRequest };
}
export function changeMealDeliveryDetailsSuccess(response) {
  return { type: constants.changeMealDeliveryDetailsSuccess, payload: response };
}
export function changeMealDeliveryDetailsFail(response) {
  return { type: constants.changeMealDeliveryDetailsFail, payload: response.data };
}

export function skipMealDeliveryRequest() {
  return { type: constants.skipMealDeliveryRequest };
}
export function skipMealDeliverySuccess(response) {
  return { type: constants.skipMealDeliverySuccess, payload: response };
}
export function skipMealDeliveryFail(response) {
  return { type: constants.skipMealDeliveryFail, payload: response.data };
}

export function changeMealDeliveryAddressRequest() {
  return { type: constants.changeMealDeliveryAddressRequest };
}
export function changeMealDeliveryAddressSuccess(response) {
  return { type: constants.changeMealDeliveryAddressSuccess, payload: response };
}
export function changeMealDeliveryAddressFail(response) {
  return { type: constants.changeMealDeliveryAddressFail, payload: response.data };
}

export function getRoomTypeRequest() {
  return { type: constants.getRoomTypeRequest };
}
export function getRoomTypeSuccess(response) {
  return { type: constants.getRoomTypeSuccess, payload: response };
}
export function getRoomTypeFail(response) {
  return { type: constants.getRoomTypeFail, payload: response.data };
}

export function getDietTypeRequest() {
  return { type: constants.getDietTypeRequest };
}
export function getDietTypeSuccess(response) {
  return { type: constants.getDietTypeSuccess, payload: response };
}
export function getDietTypeFail(response) {
  return { type: constants.getDietTypeFail, payload: response.data };
}

export function getCuisineTypeRequest() {
  return { type: constants.getCuisineTypeRequest };
}
export function getCuisineTypeSuccess(response) {
  return { type: constants.getCuisineTypeSuccess, payload: response };
}
export function getCuisineTypeFail(response) {
  return { type: constants.getCuisineTypeFail, payload: response.data };
}

export function setCreatePatientRequest() {
  return { type: constants.setCreatePatientRequest };
}
export function setCreatePatientSuccess(response) {
  return { type: constants.setCreatePatientSuccess, payload: response };
}
export function setCreatePatientFail(response) {
  return { type: constants.setCreatePatientFail, payload: response.data };
}

export function getPatientListPaginationRequest() {
  return { type: constants.getPatientListPaginationRequest };
}
export function getPatientListPaginationSuccess(response) {
  return { type: constants.getPatientListPaginationSuccess, payload: response };
}
export function getPatientListPaginationFail(response) {
  return { type: constants.getListMealDeliveryPaginationFail, payload: response.data };
}

export function getPatientDetailRequest() {
  return { type: constants.getPatientDetailRequest };
}
export function getPatientDetailSuccess(response) {
  return { type: constants.getPatientDetailSuccess, payload: response };
}
export function getPatientDetailFail(response) {
  return { type: constants.getPatientDetailFail, payload: response.data };
}

export function getPatientMealListRequest() {
  return { type: constants.getPatientMealListRequest };
}
export function getPatientMealListSuccess(response) {
  return { type: constants.getPatientMealListSuccess, payload: response };
}
export function getPatientMealListFail(response) {
  return { type: constants.getPatientMealListFail, payload: response.data };
}

export function createPatientMealPlanRequest() {
  return { type: constants.createPatientMealPlanRequest };
}
export function createPatientMealPlanSuccess(response) {
  return { type: constants.createPatientMealPlanSuccess, payload: response };
}
export function createPatientMealPlanFail(response) {
  return { type: constants.createPatientMealPlanFail, payload: response.data };
}

export function updatePatientDetailRequest() {
  return { type: constants.updatePatientDetailRequest };
}
export function updatePatientDetailSuccess(response) {
  return { type: constants.updatePatientDetailSuccess, payload: response };
}
export function updatePatientDetailFail(response) {
  return { type: constants.updatePatientDetailFail, payload: response.data };
}

export function setCheckoutPatientRequest() {
  return { type: constants.setCheckoutPatientRequest };
}
export function setCheckoutPatientSuccess(response) {
  return { type: constants.setCheckoutPatientSuccess, payload: response };
}
export function setCheckoutPatientFail(response) {
  return { type: constants.setCheckoutPatientFail, payload: response.data };
}

export function exportPatientMealPlanRequest() {
  return { type: constants.exportPatientMealPlanRequest };
}
export function exportPatientMealPlanSuccess(response) {
  return { type: constants.exportPatientMealPlanSuccess, payload: response };
}
export function exportPatientMealPlanFail(response) {
  return { type: constants.exportPatientMealPlanFail, payload: response.data };
}

export function getListPatientExportRequest() {
  return { type: constants.getListPatientExportRequest };
}
export function getListPatientExportSuccess(response) {
  return { type: constants.getListPatientExportSuccess, payload: response };
}
export function getListPatientExportFail(response) {
  return { type: constants.getListPatientExportFail, payload: response.data };
}

export function printPatientLabelRequest() {
  return { type: constants.printPatientLabelRequest };
}
export function printPatientLabelSuccess(response) {
  return { type: constants.printPatientLabelSuccess, payload: response };
}
export function printPatientLabelFail(response) {
  return { type: constants.printPatientLabelFail, payload: response.data };
}

export function searchPatientByMedicalRecordNumberRequest() {
  return { type: constants.searchPatientByMedicalRecordNumberRequest };
}
export function searchPatientByMedicalRecordNumberSuccess(response) {
  return { type: constants.searchPatientByMedicalRecordNumberSuccess, payload: response };
}
export function searchPatientByMedicalRecordNumberFail(response) {
  return { type: constants.searchPatientByMedicalRecordNumberFail, payload: response.data };
}

export function searchNearestEatStoreLocationRequest() {
  return { type: constants.searchNearestEatStoreLocationRequest };
}
export function searchNearestEatStoreLocationSuccess(response) {
  return { type: constants.searchNearestEatStoreLocationSuccess, payload: response };
}
export function searchNearestEatStoreLocationFail(response) {
  return { type: constants.searchNearestEatStoreLocationFail, payload: response.data };
}

export function getRoomNameListRequest() {
  return { type: constants.getRoomNameListRequest };
}
export function getRoomNameListSuccess(response) {
  return { type: constants.getRoomNameListSuccess, payload: response };
}
export function getRoomNameListFail(response) {
  return { type: constants.getRoomNameListFail, payload: response.data };
}

export function getFoodlistSummaryRequest() {
  return { type: constants.getFoodlistSummaryRequest };
}
export function getFoodlistSummarySuccess(response) {
  return { type: constants.getFoodlistSummarySuccess, payload: response };
}
export function getFoodlistSummaryFail(response) {
  return { type: constants.getFoodlistSummaryFail, payload: response.data };
}

export function getFoodlistSummaryPrintRequest() {
  return { type: constants.getFoodlistSummaryPrintRequest };
}
export function getFoodlistSummaryPrintSuccess(response) {
  return { type: constants.getFoodlistSummaryPrintSuccess, payload: response };
}
export function getFoodlistSummaryPrintFail(response) {
  return { type: constants.getFoodlistSummaryPrintFail, payload: response.data };
}
