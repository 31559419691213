/* eslint-disable react/no-access-state-in-setstate */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Table } from 'antd';
import _ from 'lodash';
import { Grid, Fade } from '@material-ui/core';
// style
import './CampaignPageStyle.scss';
// component
import {
  TextInput,
  SnackBarSimple,
  PickerInputDate,
  ButtonIconMain,
  ModalAlertAction,
  LabelStatusMain,
  PrevStateValue,
  SkeletonPagination
} from '../../../components/Index';
// api
import { getCampaignPagination, setDeleteCampaign } from '../../../services/api/CampaignApi';
// helper
import { CommonHelper, PermissionAccess, PermissionModule, PermissionPage } from '../../../helpers/Index';
import { compose } from 'redux';

const currentDate = CommonHelper.currentDate('YYYY-MM-DD');
const startDateValue = CommonHelper.getStartDateMonth(currentDate);
const endDateValue = CommonHelper.getEndDateMonth(currentDate);
const timeInterval = 300;

const initialFilter = {
  merchantId: null,
  search: '',
  requestStatus: 0,
  startDate: startDateValue,
  endDate: endDateValue,
};

const initialSort = {
  sortField: 'campaign_title',
  sortOrder: '',
};

const initialValidation = {
  reason: { isError: '', errorMessage: '' },
};

const optionToash = {
  vertical: 'top',
  horizontal: 'right',
};

class CampaignPage extends React.Component {
  constructor(props) {
    super(props);
    const {
      usersReducer: { prevStateValue },
    } = props;

    const updateExitingFilter = { ...CommonHelper.objectCloning(initialFilter), ...prevStateValue };

    this.state = {
      filter: updateExitingFilter,
      page: 1,
      limit: 10,
      selectedId: null,
      isOpenModalDelete: false,
      selectedDataDelete: null,
      sort: CommonHelper.objectCloning(initialSort),
      pagination: {
        showSizeChanger: true,
        showTotal: (total, range) => `Showing ${range[0]}-${range[1]} from ${total}`,
        pageSizeOptions: ['10', '20', '30', '40'],
      },
      validation: CommonHelper.objectCloning(initialValidation),
      isLoading: true,
      toastInformation: {
        isOpen: false,
        message: '',
        snackbarType: 'warning',
      },
      modalDetailDataDelete: {
        title: 'Confirmation',
        body: 'Are you sure want to Delete Campaign ',
      },
    };
    this.searchDebounce = _.debounce(this.searchDebounce, 400);
  }

  componentDidMount() {
    this.getCampaignPagination();
  }

  componentWillReceiveProps(nextProps) {
    const { usersReducer } = nextProps;
    const {
      onSetPrevStateValue,
      history: { location },
    } = this.props;

    if (_.isEmpty(usersReducer.prevStateValue)) {
      const newValue = {
        url: location.pathname,
      };

      onSetPrevStateValue(newValue);

      this.setState({
        filter: CommonHelper.objectCloning(initialFilter),
      });
    }
  }

  componentDidUpdate(nextProps) {
    const {
      pageStatus,
      usersReducer: { prevStateValue },
    } = this.props;
    const isEqualActiveTab = _.isEqual(pageStatus, nextProps.pageStatus);
    const isEqualActivePrevStateUrl = _.isEqual(
      prevStateValue.url,
      nextProps.usersReducer.prevStateValue.url,
    );

    if (!isEqualActiveTab || !isEqualActivePrevStateUrl) {
      this.resetPropChange();
    }
  }

  getCampaignPagination() {
    const { campaignPagination } = this.props;
    const {
      limit,
      pagination,
      page,
      filter: { search, startDate, endDate, requestStatus },
      sort,
    } = this.state;

    const params = {
      search,
      start_date: startDate,
      end_date: endDate,
      is_draft: requestStatus,
      page,
      limit,
      sort: sort.sortField,
      sortOrder: sort.sortOrder,
    };

    campaignPagination(params).then(response => {
      this.setState({
        isLoading: false,
        pagination: { ...pagination, pageSize: limit, total: response.data.total },
      });
    });
  }

  setDeleteCampaign(param) {
    const { deleteCampaign } = this.props;
    const { page, sort, filter, limit } = this.state;

    deleteCampaign(param)
      .then(async response => {
        const message = response.messages;

        this.handleCloseModalDelete();
        await this.getCampaignPagination({
          page,
          sort: sort.sortField,
          sortOrder: sort.sortOrder,
          search: filter.search,
          limit,
        });
        await this.processMessage(message, 'success');
      })
      .catch(error => {
        const message = error.data;
        this.processMessage(message.messages, 'error');
      });
  }

  handleChangeSearch = value => {
    const { filter } = this.state;

    this.setState(
      {
        filter: { ...filter, search: value, sort: JSON.parse(JSON.stringify(initialSort)) },
        page: 1,
        pagination: { ...this.state.pagination, current: 1 },
      },
      () => {
        this.searchDebounce();
      },
    );
  };

  handleChangeStartDate = value => {
    const { filter } = this.state;
    const { onSetPrevStateValue } = this.props;
    const startDate = value || '';
    const endDate = _.isEmpty(startDate) ? filter.endDate : CommonHelper.getEndDateMonth(value);

    onSetPrevStateValue({ ...filter, startDate, endDate });

    this.setState(
      {
        filter: { ...filter, startDate, endDate },
      },
      () => {
        this.getCampaignPagination();
      },
    );
  };

  handleChangeEndDate = value => {
    const { filter } = this.state;
    const { onSetPrevStateValue } = this.props;
    const endDate = value || '';

    onSetPrevStateValue({ ...filter, endDate });

    this.setState(
      {
        filter: { ...filter, endDate },
      },
      () => {
        this.getCampaignPagination();
      },
    );
  };

  handleMouseEnter = value => {
    this.setState({ selectedId: value.campaign_id });
  };

  handleMouseLeave = () => {
    this.setState({ selectedId: null });
  };

  handleCloseToash = () => {
    const { toastInformation } = this.state;
    this.setState({ toastInformation: { ...toastInformation, isOpen: false } });
  };

  handleTableChange = (pagination, filters, sorter) => {
    const { sort } = this.state;

    this.setState(
      {
        limit: pagination.pageSize,
        pagination: {
          ...this.state.pagination,
          current: pagination.current,
          pageSize: pagination.pageSize,
        },
        sort: {
          ...sort,
          sortField: sorter.field,
          sortOrder: sorter.order === 'ascend' ? 'asc' : 'desc',
        },
        page: pagination.current,
      },
      () => {
        this.getCampaignPagination();
      },
    );
  };

  searchDebounce = () => {
    const { filter } = this.state;
    const { onSetPrevStateValue } = this.props;

    onSetPrevStateValue({ ...filter });
    this.getCampaignPagination();
  };

  handleButtonEdit = data => {
    const { history } = this.props;
    history.push(`/marketing/campaign/edit/${data.campaign_id}`);
  };

  handleButtonDeleted = data => {
    const { modalDetailDataDelete } = this.state;

    this.setState({
      isOpenModalDelete: true,
      selectedDataDelete: data,
      modalDetailDataDelete: {
        ...modalDetailDataDelete,
        body: `Are you sure want to Delete Campaign ${data.campaign_title} ? `,
      },
    });
  };

  handleCloseModalDelete = () => {
    const { validation } = this.state;
    const message = { isError: '', errorMessage: '' };

    this.setState({
      isOpenModalDelete: false,
      validation: { ...validation, reason: message },
    });
  };

  handleButtonSubmitDelete = () => {
    const { selectedDataDelete } = this.state;
    const param = selectedDataDelete.campaign_id;

    if (param) {
      this.setDeleteCampaign(param);
    }
  };

  processMessage(messages, type) {
    const convertedMessage = CommonHelper.generateMessage(messages);

    const paramInformation = {
      isOpen: true,
      message: convertedMessage,
      snackbarType: type,
    };

    this.setState({
      toastInformation: paramInformation,
    });
  }

  resetPropChange() {
    const { pageStatus } = this.props;
    const { pagination } = this.state;

    const resetParam = CommonHelper.objectCloning(initialFilter);
    resetParam.requestStatus = pageStatus;

    this.setState(
      {
        isLoading: true,
        filter: resetParam,
        limit: 10,
        pagination: { ...pagination, pageSize: 10 },
      },
      () => {
        this.getCampaignPagination();
      },
    );
  }

  renderModalDelete() {
    const { isOpenModalDelete } = this.state;
    const {
      campaignData: { fetching },
    } = this.props;
    let elementRender = null;

    if (isOpenModalDelete) {
      elementRender = (
        <ModalAlertAction
          onButtonSubmit={this.handleButtonSubmitDelete}
          onCloseModal={this.handleCloseModalDelete}
          modalDetail={this.state.modalDetailDataDelete}
          modalType="danger"
          buttonSubmitText="Submit"
          openModal={isOpenModalDelete}
          isLoading={fetching}
        />
      );
    }

    return elementRender;
  }

  renderStatus = value => {
    let renderElement = null;
    if (!value) {
      renderElement = <LabelStatusMain value="Future" type="projected" />;
    }
    return <div className="container-status">{renderElement}</div>;
  };

  renderRowAdditional = data => {
    const publishDate = CommonHelper.dateTimeParseNewFormat(data.send_time, 'DD MMM YYYY | HH:mm');
    const createDate = CommonHelper.dateTimeParseNewFormat(data.created_at, 'DD MMM YYYY | HH:mm');

    return (
      <Grid container justify="space-between">
        <Grid item lg={3} md={2}>
          <div className="flex-column">
            <label className="text-12 title">Segment</label>
            <label className="text-12">{data.segment_name || '-'}</label>
          </div>
        </Grid>
        <Grid item>
          <div className="divider-hr-vertical" />
        </Grid>
        <Grid item lg={3} md={3}>
          <div className="flex-column">
            <span className="flex-row">
              <label className="text-12 title mr-4">Publish Date</label>
              {this.renderStatus(data.send_now)}
            </span>
            <label className="text-12 wrapping-container">{publishDate}</label>
          </div>
        </Grid>
        <Grid item>
          <div className="divider-hr-vertical" />
        </Grid>
        <Grid item lg={3} md={3}>
          <div className="flex-column">
            <label className="text-12 title">Create Date</label>
            <label className="text-12 wrapping-container">{createDate}</label>
          </div>
        </Grid>
      </Grid>
    );
  };

  renderColumns = () => {
    const { selectedId } = this.state;
    return [
      {
        title: 'Campaign',
        dataIndex: 'campaign_title',
        sorter: true,
        render: (text, row) => {
          return (
            <Grid container direction="column" className="container-row-campaign flex-wrap-unset">
              <Grid item lg md className="container-info-header">
                <div className="flex-column">
                  <label className="text-14 title wrapping-container-break wrapping-container second line">
                    {row.campaign_title}
                  </label>
                  <label className="text-10">ID : {row.campaign_id}</label>
                </div>
              </Grid>
              <Grid item lg md className="container-info-body">
                <Grid container justify="flex-start">
                  <Grid item lg={8} md={8}>
                    {this.renderRowAdditional(row)}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          );
        },
      },
      {
        title: 'Last Modify',
        width: '15%',
        align: 'center',
        render: (text, row) => {
          const inSelect = row.campaign_id === selectedId;
          const modify = CommonHelper.dateTimeParseNewFormat(row.updated_at, 'll');
          let renderElement = <label className="text-13 text-semi-bold">{modify}</label>;

          if (inSelect) {
            renderElement = (
              <Fade in={inSelect} timeout={timeInterval}>
                <Grid container justify="center">
                  <Grid item>
                    <ButtonIconMain
                      icon="ic-ffo-edit"
                      type="secondary"
                      size="sm"
                      onClick={() => this.handleButtonEdit(row)}
                      requiredPermission={`${PermissionModule.Marketing}.${PermissionPage.Campaign}.${PermissionAccess.Update}`}
                    />
                  </Grid>
                  <Grid item>
                    <ButtonIconMain
                      icon="ic-ffo-bin"
                      type="negative"
                      size="sm"
                      onClick={() => this.handleButtonDeleted(row)}
                      requiredPermission={`${PermissionModule.Marketing}.${PermissionPage.Campaign}.${PermissionAccess.Delete}`}
                    />
                  </Grid>
                </Grid>
              </Fade>
            );
          }

          return renderElement;
        },
      },
    ];
  };

  renderFilter() {
    const {
      filter: { search, startDate, endDate },
    } = this.state;

    return (
      <Grid container justify="space-between" className="container-filter">
        <Grid item lg={3} md={3} className="row-filter">
          <TextInput
            iconPrefix="ic-ffo-search"
            placeHolderText="Search Campaign Name"
            onChange={this.handleChangeSearch}
            currentValue={search}
            size="md"
          />
        </Grid>
        <Grid item lg={5} md={5}>
          <Grid container justify="flex-end" alignItems="center">
            <Grid item>
              <label className="text-14 pr-8">Date</label>
            </Grid>
            <Grid item lg={4} md={4}>
              <PickerInputDate
                customIcon="ic-ffo-date-pick"
                dateFormat="dd/MM/yyyy"
                defaultValue={startDate}
                onChange={this.handleChangeStartDate}
                toolbar={false}
              />
            </Grid>
            <Grid item>
              <label className="text-14 pr-8 pl-8"> - </label>
            </Grid>
            <Grid item lg={4} md={4}>
              <PickerInputDate
                customIcon="ic-ffo-date-pick"
                dateFormat="dd/MM/yyyy"
                minDate={startDate}
                defaultValue={endDate}
                onChange={this.handleChangeEndDate}
                toolbar={false}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  render() {
    const {
      campaignData: { list, fetching },
    } = this.props;
    const { isLoading, pagination, toastInformation } = this.state;
    // eslint-disable-next-line no-unused-vars
    const adjustTableHeight = window.innerHeight - 350;
    const ListCustom = !_.isEmpty(list) ? list.data : [];
    const tableLoading = fetching;

    let renderElement = (
      <div className="mt-18">
        <SkeletonPagination />
      </div>
    );

    if (!isLoading) {
      renderElement = (
            <Grid container direction="column" className="flex-wrap-unset">
              <Grid item lg md sm className="section-page-header">
                {this.renderFilter()}
              </Grid>
              <Grid item lg md className="section-table-body">
                <Table
                  columns={this.renderColumns()}
                  rowKey={record => record.campaign_id}
                  dataSource={ListCustom}
                  pagination={pagination}
                  loading={tableLoading}
                  // scroll={{ y: adjustTableHeight }}
                  onChange={this.handleTableChange}
                  onRow={record => {
                    return {
                      onMouseEnter: () => {
                        this.handleMouseEnter(record);
                      }, // mouse enter row
                      onMouseLeave: () => {
                        this.handleMouseLeave();
                      },
                    };
                  }}
                />
              </Grid>
            </Grid>
      );
    }
    return (
      <div className="container-page-campaign scroll-container">
        <div className="container-page-scrolling-area include-tab">
          {renderElement}
        </div>
        {this.renderModalDelete()}
        <SnackBarSimple
          open={toastInformation.isOpen}
          durationHide={2000}
          message={toastInformation.message}
          onClickClose={this.handleCloseToash}
          snackbarType={toastInformation.snackbarType}
          anchor={optionToash}
        />
      </div>
      );
  }
}

const mapDispatchToProps = dispatch => ({
  campaignPagination: params => getCampaignPagination(dispatch, params),
  deleteCampaign: params => setDeleteCampaign(dispatch, params),
});

const mapStateToProps = ({ campaignData, usersReducer }) => ({
  campaignData,
  usersReducer,
});

CampaignPage.propTypes = {
  campaignData: PropTypes.object,
  campaignPagination: PropTypes.func,
  deleteCampaign: PropTypes.func,
  history: PropTypes.object,
  onSetPrevStateValue: PropTypes.func,
  pageStatus: PropTypes.number,
  usersReducer: PropTypes.object,
};

const shell = compose(connect(mapStateToProps, mapDispatchToProps));

export default shell(PrevStateValue(CampaignPage));
