import React from 'react';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { Table } from 'antd';
import { find, isEqual } from 'lodash';
// component
import {
  PrevNextStepperCount,
  ButtonIconMain,
  TextInput,
  SelectInputProductMapping,
} from '../../../../../components/Index';
// helpers
import { CommonHelper } from '../../../../../helpers/Index';
// style
import '../../EventAddStyle.scss';

const initialForm = {
  price: '',
};

const initialFilter = {
  type: null,
  merchantId: null,
  productType: null,
  categoryId: null,
};

class StepCreateAddOn extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      formAddOn: CommonHelper.objectCloning(initialForm),
      filter: CommonHelper.objectCloning(initialFilter),
      limit: 10,
      page: 1,
      pagination: {
        showSizeChanger: true,
        showTotal: (total, range) => `Showing ${range[0]}-${range[1]} From ${total}`,
        pageSizeOptions: ['10', '20', '30', '40'],
      },
      selectedProduct: null,
      editIndex: null,
    };
  }

  componentDidUpdate(prevProps) {
    const { addOn: prevAddOn } = prevProps.form;
    const { addOn } = this.props.form;

    if (!isEqual(prevAddOn, addOn) && addOn !== undefined && addOn !== null) {
      this.updateSelectedProductState(addOn);
    }
  }

  updateSelectedProductState = addOn => {
    const {
      masterData: { productMappingList },
    } = this.props;
    const { pagination, limit } = this.state;

    const selectedProduct = addOn.map(item => {
      const findProduct = find(productMappingList, ['product_id', item.productId]);

      return {
        productId: findProduct.product_id,
        price: findProduct.normal_price,
        productName: findProduct ? findProduct.name : 'Unknown Product',
        merchantId: findProduct ? findProduct.merchant_id : null,
        merchantName: findProduct ? findProduct.merchant_name : 'Unknown Merchant',
        type: findProduct ? findProduct.type : null,
        typeName: findProduct ? CommonHelper.renameRemoveCharacter(findProduct.type, '_') : 'N/A',
      };
    });

    if (!isEqual(this.state.selectedProduct, selectedProduct)) {
      this.setState({
        selectedProduct,
        pagination: { ...pagination, pageSize: limit, total: addOn.length },
      });
    }
  };

  handleButtonEdit = index => {
    const {
      form: { addOn },
    } = this.props;

    const selectedAddOn = addOn[index];

    this.setState({
      editIndex: index,
      formAddOn: {
        price: selectedAddOn.price || '',
      },
    });
  };

  handleButtonDelete = productId => {
    const { onChange } = this.props;
    const { selectedProduct } = this.state;

    let modifySelectedProduct = CommonHelper.objectCloning(selectedProduct);

    if (productId) {
      modifySelectedProduct = modifySelectedProduct.filter(item => item.productId !== productId);
      onChange('addOn', modifySelectedProduct);

      this.setState({
        editIndex: null,
        formAddOn: CommonHelper.objectCloning(initialForm),
        selectedProduct: modifySelectedProduct,
      });
    }
  };

  handleButtonSave = () => {
    const {
      form: { addOn },
      onChange,
    } = this.props;
    const { formAddOn, editIndex, selectedProduct } = this.state;

    const updatedAddOn = [...addOn];
    updatedAddOn[editIndex] = { ...formAddOn };
    onChange('addOn', updatedAddOn);

    const updatedSelectedProduct = [...selectedProduct];
    if (updatedSelectedProduct[editIndex]) {
      updatedSelectedProduct[editIndex] = {
        ...updatedSelectedProduct[editIndex],
        price: formAddOn.price,
      };
    }

    this.setState({
      editIndex: null,
      formAddOn: CommonHelper.objectCloning(initialForm),
      selectedProduct: updatedSelectedProduct,
    });
  };

  handleButtonCancel = () => {
    const {
      form: { addOn },
      onChange,
    } = this.props;
    const { editIndex, formAddOn } = this.state;

    const isNewRow = !formAddOn.price;

    if (isNewRow) {
      const updatedAddOn = addOn.filter((_, index) => index !== editIndex);
      onChange('addOn', updatedAddOn);

      this.setState({
        editIndex: null,
        formAddOn: CommonHelper.objectCloning(initialForm),
      });
    } else {
      this.setState({
        editIndex: null,
        formAddOn: CommonHelper.objectCloning(initialForm),
      });
    }
  };

  handleSelectProduct = value => {
    const {
      masterData: { productMappingList },
      onChange,
    } = this.props;

    const converted = [];

    value.forEach(item => {
      const findProduct = find(productMappingList, ['product_id', item]);

      const param = {
        productId: findProduct.product_id,
        productName: findProduct.name,
        merchantId: findProduct.merchant_id,
        merchantName: findProduct.merchant_name,
        price: findProduct.normal_price,
        type: findProduct.type,
        typeName: CommonHelper.renameRemoveCharacter(findProduct.type, '_'),
      };

      converted.push(param);
    });
    onChange('addOn', converted);
  };

  handleFormChange = (key, value) => {
    const { formAddOn } = this.state;

    this.setState({
      formAddOn: { ...formAddOn, [key]: value },
    });
  };

  renderColumns = () => {
    const { formAddOn, editIndex } = this.state;
    const isFormComplete = formAddOn.price;

    return [
      {
        title: 'Product ID',
        dataIndex: 'productId',
        key: 'productId',
      },
      {
        title: 'Name',
        dataIndex: 'productName',
        key: 'productName',
      },
      {
        title: 'Price',
        dataIndex: 'price',
        key: 'price',
        align: 'right',
        render: (text, row, index) => {
          const amount = CommonHelper.formatCurrency(row.price);

          return editIndex === index ? (
            <TextInput
              placeHolderText="Enter Price"
              currentValue={formAddOn.price}
              size="md"
              onChange={value => this.handleFormChange('price', value)}
            />
          ) : (
            `IDR ${amount}`
          );
        },
      },
      {
        align: 'center',
        render: (text, row, index) => {
          return editIndex === index ? (
            <Grid container justify="center">
              <Grid item>
                <ButtonIconMain
                  icon="ic-ffi-save"
                  type="secondary"
                  size="sm"
                  disabled={!isFormComplete}
                  onClick={this.handleButtonSave}
                />
              </Grid>
              <Grid item>
                <ButtonIconMain
                  icon="ic-ffo-close"
                  type="negative"
                  size="sm"
                  onClick={this.handleButtonCancel}
                />
              </Grid>
            </Grid>
          ) : (
            <Grid container justify="center">
              <Grid item>
                <ButtonIconMain
                  icon="ic-ffo-edit"
                  type="secondary"
                  size="sm"
                  onClick={() => this.handleButtonEdit(index)}
                />
              </Grid>
              <Grid item>
                <ButtonIconMain
                  icon="ic-ffo-bin"
                  type="negative"
                  size="sm"
                  onClick={() => this.handleButtonDelete(row.productId)}
                />
              </Grid>
            </Grid>
          );
        },
      },
    ];
  };

  render() {
    const { filter, selectedProduct, limit, page } = this.state;
    const customTablePagination = CommonHelper.getArrayByLimit(selectedProduct, limit, page);
    const currentValue = selectedProduct && selectedProduct.map(product => product.productId);

    return (
      <div>
        <Grid container spacing={2} alignItems="center">
          <Grid item lg={3} md={3}>
            <label className="text-12 text-semi-bold text-rolling-stone">Select Product</label>
          </Grid>
          <Grid item lg={9} md={9}>
            <SelectInputProductMapping
              onChange={this.handleSelectProduct}
              placeholder={'Select Product'}
              filter={filter}
              currentValue={currentValue || []}
              isMultipleSelection
            />
          </Grid>
        </Grid>
        <div className="container-step-create-addon flex-column mt-24" style={{ gap: '24px' }}>
          <div className="ph-32 pb-32">
            <Table
              columns={this.renderColumns()}
              rowKey={record => record.productId}
              dataSource={customTablePagination}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ masterData }) => ({ masterData });

StepCreateAddOn.propTypes = {
  form: PropTypes.object,
  masterData: PropTypes.object,
  onChange: PropTypes.func,
};

const shell = compose(connect(mapStateToProps));

export default shell(PrevNextStepperCount(StepCreateAddOn));
