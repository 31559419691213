/* eslint-disable import/prefer-default-export */
import * as usersAction from '../../redux/actions/UsersAction';
import Api from '../../middleware/FetchMiddleware';
import { baseUrl } from '../../environments/Environment';
import { HttpAction } from '../../helpers/HttpHelper';

export const userLogin = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    Api.callApi(`${baseUrl}public/pos/v2/user/login`, HttpAction.Post, {}, params)
      .then(response => {
        dispatch(usersAction.loginSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(usersAction.loginFail(e));
        reject(e);
      });
  });
};

export const userTokenValidation = dispatch => {
  return new Promise((resolve, reject) => {
    Api.callApi(`${baseUrl}private/pos/v2/user/auth`, HttpAction.Get, {}, {})
      .then(response => {
        dispatch(usersAction.reAuthenticationSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(usersAction.reAuthenticationFail(e));
        reject(e);
      });
  });
};

export const checkUserPhoneOrEmailExist = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    Api.callApi(
      `${baseUrl}private/pos/v2/user/verify/email-or-phone-available`,
      HttpAction.Post,
      {},
      params,
    )
      .then(response => {
        return resolve(response);
      })
      .catch(e => {
        reject(e);
      });
  });
};

export const registerNewUser = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(usersAction.registerNewUserRequest());
    Api.callApi(`${baseUrl}private/pos/user/register`, HttpAction.Put, {}, params)
      .then(response => {
        dispatch(usersAction.registerNewUserSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(usersAction.registerNewUserFail(e));
        reject(e);
      });
  });
};

export const userCovidTest = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(usersAction.userCovidTestRequest());
    Api.callApi(
      `${baseUrl}private/pos/medic/health-passport/covid-19/result/nik`,
      HttpAction.Post,
      {},
      params,
    )
      .then(response => {
        dispatch(usersAction.userCovidTestSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(usersAction.userCovidTestFail(e));
        reject(e);
      });
  });
};
