/* eslint-disable react/no-access-state-in-setstate */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Table } from 'antd';
import _ from 'lodash';
import { Grid } from '@material-ui/core';
import { compose } from 'redux';
// Component
import {
  SnackBarSimple,
  PickerInputDate,
  LabelStatusPayment,
  EmptyState,
  SelectInputMain,
  ButtonMain,
  PrevStateValue,
  SelectAutoComplete,
  SkeletonMain,
} from '../../../../../components/Index';
// Api
import { getOrderList, getCategoryList } from '../../../../../services/api/OrdersApi';
// Helpers
import {
  CommonHelper,
  paymentMethod,
  MasterDataHelper,
  productTypeForOrderCode,
  UserHelper,
  UserRoleName,
} from '../../../../../helpers/Index';
// Assets
import { Icons, Images } from '../../../../../assets/Index';
// Style
import './OrderPageStyle.scss';
import { IconHelper } from '../../../../../helpers/IconHelper';

const currentDate = CommonHelper.currentDate('YYYY-MM-DD');
const startDateValue = CommonHelper.getStartDateMonth(currentDate);
const endDateValue = CommonHelper.getEndDateMonth(currentDate);
const currentUsers = UserHelper.getCurrentUserInformation();

const optionSearchType = MasterDataHelper.optionSearchType;
const optionStatusPayment = MasterDataHelper.optionStatusPayment;
const optionTransactionSource = MasterDataHelper.optionTransactionSource;
const optionOrderType = MasterDataHelper.optionOrderType;
const optionOrderStatus = MasterDataHelper.orderStatus;

const initialSort = {
  sortField: 'name',
  sortOrder: '',
};

const optionToash = {
  vertical: 'top',
  horizontal: 'right',
};

const initialFilter = {
  search: '',
  startDate: startDateValue,
  endDate: endDateValue,
  status: optionOrderStatus[0].value,
  selectedStatusPayment: undefined,
  transactionSource: undefined,
  orderType: undefined,
  searchType: optionSearchType[1].value,
};

class OrderPage extends React.Component {
  constructor(props) {
    super(props);
    const {
      usersReducer: { prevStateValue },
      activeTab,
    } = props;

    const updateExitingFilter = {
      ...CommonHelper.objectCloning(initialFilter),
      ...prevStateValue,
    };

    this.state = {
      filter: {
        ...updateExitingFilter,
        status: activeTab,
      },
      page: 1,
      limit: 10,
      sort: initialSort,
      pagination: {
        showSizeChanger: true,
        showTotal: (total, range) => `Showing ${range[0]}-${range[1]} from ${total}`,
        pageSizeOptions: ['10', '20', '30', '40'],
      },
      loading: true,
      isLoading: true,
      toastInformation: {
        isOpen: false,
        message: '',
        snackbarType: 'warning',
      },
      isLoadingAutoComplete: false,
      isResellerLogin: currentUsers && currentUsers.isResellerLogin,
    };
    this.searchDebounce = _.debounce(this.searchDebounce, 400);
  }

  componentDidMount() {
    const { getOrderListPagination } = this;
    getOrderListPagination();
  }

  componentWillReceiveProps(nextProps) {
    const { usersReducer } = nextProps;
    const {
      onSetPrevStateValue,
      history: { location },
    } = this.props;

    if (_.isEmpty(usersReducer.prevStateValue)) {
      const newValue = {
        url: location.pathname,
      };

      onSetPrevStateValue(newValue);

      this.setState({
        filter: CommonHelper.objectCloning(initialFilter),
        page: 1,
      });
    }
  }

  componentDidUpdate(nextProps) {
    const {
      activeTab,
      usersReducer: { prevStateValue },
    } = this.props;
    const isEqualActiveTab = _.isEqual(activeTab, nextProps.activeTab);
    const isEqualActivePrevStateUrl = _.isEqual(
      prevStateValue.url,
      nextProps.usersReducer.prevStateValue.url,
    );

    if (!isEqualActiveTab || !isEqualActivePrevStateUrl) {
      this.resetPropChange();
    }
  }

  getOrderListPagination = () => {
    const { orderListPagination } = this.props;
    const { limit, page, pagination, sort, filter } = this.state;

    const payload = {
      page,
      limit,
      sort: sort.sortField,
      sortOrder: sort.sortOrder,
      search: filter.search,
      start_date: filter.startDate,
      end_date: filter.endDate,
      order_type: filter.orderType,
      status: filter.status,
      payment_status: filter.selectedStatusPayment,
      transaction_source: filter.transactionSource,
      search_type: filter.searchType,
    };

    this.setState({ loading: true }, () => {
      orderListPagination(payload).then(response => {
        this.setState({
          loading: false,
          isLoading: false,
          pagination: { ...pagination, pageSize: limit, total: response.total },
        });
      });
    });
  };

  handleChangeSearch = value => {
    const { filter } = this.state;

    this.setState(
      {
        filter: { ...filter, search: value },
        page: 1,
        pagination: { ...this.state.pagination, current: 1 },
      },
      () => {
        this.searchDebounce();
      },
    );
  };

  handleCloseToash = () => {
    const { toastInformation } = this.state;
    this.setState({ toastInformation: { ...toastInformation, isOpen: false } });
  };

  handleTableChange = (pagination, filters, sorter) => {
    const { sort } = this.state;

    this.setState(
      {
        limit: pagination.pageSize,
        pagination: {
          ...this.state.pagination,
          current: pagination.current,
          pageSize: pagination.pageSize,
        },
        sort: {
          ...sort,
          sortField: sorter.field,
          sortOrder: sorter.order === 'ascend' ? '' : 'desc',
        },
        page: pagination.current,
      },
      () => {
        this.getOrderListPagination();
      },
    );
  };

  handleClickRow = value => {
    const { history } = this.props;
    const isDoctorConsultation = value.products.toLowerCase() === 'doctor consultation';
    const param = CommonHelper.encryptObject({
      salesOrderId: value.sales_order_id,
      isDoctorConsultation,
    });
    if (value.is_covid_test && value.transaction_source !== 'app') {
      history.push(`/medic/lab/details/${param}`);
    } else {
      history.push(`/orders/details/${param}`);
    }
  };

  handleChangeStartDate = value => {
    const { filter } = this.state;
    const { getOrderListPagination } = this;
    const { onSetPrevStateValue } = this.props;
    const endDate = CommonHelper.getEndDateMonth(value);

    onSetPrevStateValue({ ...filter, startDate: value, endDate });

    this.setState(
      {
        filter: { ...filter, startDate: value, endDate },
      },
      () => {
        getOrderListPagination();
      },
    );
  };

  handleChangeEndDate = value => {
    const { filter } = this.state;
    const { getOrderListPagination } = this;
    const { onSetPrevStateValue } = this.props;

    onSetPrevStateValue({ ...filter, endDate: value });

    this.setState(
      {
        filter: { ...filter, endDate: value },
      },
      () => {
        getOrderListPagination();
      },
    );
  };

  getCategoryOption = () => {
    const { filter } = this.state;
    const { getCategoryOption } = this.props;

    const params = {
      search: filter.search,
      product_type: 'retail',
      page: 1,
      limit: 500,
    };

    this.setState(
      {
        isLoadingAutoComplete: true,
      },
      () => {
        getCategoryOption(params).then(() => {
          this.setState({ isLoadingAutoComplete: false });
        });
      },
    );
  };

  searchDebounce = () => {
    const { filter } = this.state;
    const { getOrderListPagination, getCategoryOption } = this;
    const { onSetPrevStateValue } = this.props;

    onSetPrevStateValue({ ...filter, search: filter.search });

    this.setState(
      {
        page: 1,
      },
      () => {
        if (filter.searchType === 'category') {
          getCategoryOption();
        }
        getOrderListPagination();
      },
    );
  };

  handleChangeSearchType = value => {
    const { filter } = this.state;
    const { getOrderListPagination } = this;

    this.setState(
      {
        filter: {
          ...filter,
          searchType: value,
          orderType:
            value === optionSearchType[5].value ? optionOrderType[2].value : filter.orderType,
        },
      },
      () => {
        getOrderListPagination();
      },
    );
  };

  handleChangePaymentStatus = value => {
    const { filter } = this.state;
    const { getOrderListPagination } = this;
    const { onSetPrevStateValue } = this.props;

    onSetPrevStateValue({ ...filter, selectedStatusPayment: value });

    this.setState(
      {
        filter: { ...filter, selectedStatusPayment: value },
      },
      () => {
        getOrderListPagination();
      },
    );
  };

  handleChangeTransactionSource = value => {
    const { filter } = this.state;
    const { getOrderListPagination } = this;
    const { onSetPrevStateValue } = this.props;

    onSetPrevStateValue({ ...filter, transactionSource: value });

    this.setState(
      {
        filter: { ...filter, transactionSource: value },
      },
      () => {
        getOrderListPagination();
      },
    );
  };

  handleChangeOrderType = value => {
    const { filter } = this.state;
    const { getOrderListPagination } = this;
    const { onSetPrevStateValue } = this.props;

    onSetPrevStateValue({ ...filter, orderType: value });

    this.setState(
      {
        filter: { ...filter, orderType: value },
      },
      () => {
        getOrderListPagination();
      },
    );
  };

  handleClickReset = () => {
    const { filter } = this.state;
    const { getOrderListPagination } = this;

    this.setState(
      {
        filter: {
          ...filter,
          orderType: undefined,
          transactionSource: undefined,
          selectedStatusPayment: undefined,
          page: 1,
        },
      },
      () => {
        getOrderListPagination();
      },
    );
  };

  resetPropChange() {
    const { activeTab } = this.props;
    const { pagination } = this.state;

    const resetParam = CommonHelper.objectCloning(initialFilter);
    resetParam.status = activeTab;

    this.setState(
      {
        isLoading: true,
        filter: resetParam,
        limit: 10,
        pagination: { ...pagination, pageSize: 10 },
      },
      () => {
        this.getOrderListPagination();
      },
    );
  }

  renderColumns = () => {
    return [
      {
        title: 'Customer Name',
        dataIndex: 'name',
        sorter: true,
        render: (text, row) => {
          let icon = '';
          switch (row.order_type) {
            case productTypeForOrderCode.eat:
              icon = 'ic-ffo-lg-eats';
              break;
            case productTypeForOrderCode.shop:
              icon = 'ic-ffo-lg-shop';
              break;
            case productTypeForOrderCode.move:
              icon = 'ic-ffo-lg-book';
              break;
            default:
              icon = 'ic-ffo-memberships';
              break;
          }

          return (
            <Grid container key={row.sales_order_id}>
              <Grid item lg={1} md={1} className="section-icon">
                <div>
                  <i className={`${icon} container-icon-prefix size-40 orders-icon-purchase`} />
                </div>
              </Grid>
              <Grid item lg={11} md={11}>
                <Grid
                  onClick={() => {
                    this.handleClickRow(row);
                  }}
                  container
                  direction="column"
                  className="container-row-content flex-wrap-unset"
                >
                  <Grid item className="pb-24">
                    <Grid container direction="row" className="container-text-header">
                      <Grid item lg={8} md={8} className="left">
                        <Grid container direction="column">
                          <Grid item>
                            <label className="text-14 text-bold">{row.user_name}</label>
                          </Grid>
                          <Grid item>
                            <label className="text-12 text-product-name">{row.products}</label>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item lg={4} md={4} className="right">
                        <Grid container justify="flex-end" direction="row">
                          <Grid item className="section-currency">
                            {row.payment_method === paymentMethod.FitPoint ? (
                              <img src={Icons.fitPoints} alt="Fit Points" className="pr-8" />
                            ) : (
                              <label className="text-18 text-bold currency pr-8">
                                {row.total_paid > 0 ? 'IDR' : ''}
                              </label>
                            )}
                            <label className="text-18 text-bold currency pr-8">
                              {row.total_paid > 0
                                ? CommonHelper.formatCurrency(row.total_paid)
                                : `Free`}
                            </label>
                            <LabelStatusPayment status={row.payment_status} />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container justify="space-between" spacing={2}>
                      <Grid item lg={6} md={12} className="left additional-info-container">
                        <Grid container>
                          {row.additional_details.map((item, index) => {
                            let checkIconName = '';
                            let currentValue = item.value;

                            switch (item.name) {
                              case 'Delivery Address':
                              case 'Location':
                                checkIconName = 'pin';
                                break;
                              case 'Appointment Date':
                                checkIconName = 'time';
                                break;
                              default:
                                checkIconName = item.name;
                                break;
                            }

                            const currentIcon = IconHelper.checkActiveIconOrder(checkIconName);

                            if (CommonHelper.checkValidDate(currentValue)) {
                              currentValue = CommonHelper.dateTimeParseNewFormat(
                                item.value,
                                'DD MMM YYYY | LT',
                              );
                            }

                            return (
                              <Grid key={index} item lg={6} md={4} sm={6} className="pr-24">
                                <div className="flex-column">
                                  <label className="text-12 text-opacity-50 text-bold mb-4">
                                    {item.name}
                                  </label>
                                  <span className="flex-row">
                                    {!_.isEmpty(currentIcon) && (
                                      <i
                                        className={`${currentIcon} container-icon-prefix size-14 mr-4`}
                                      />
                                    )}
                                    <label className="text-12 wrapping-container-break wrapping-container second line mt-4">
                                      {currentValue || '-'}
                                    </label>
                                  </span>
                                </div>
                              </Grid>
                            );
                          })}
                        </Grid>
                      </Grid>
                      <Grid item lg={5} md={12}>
                        <Grid container spacing={2} className="order-detail-container">
                          <Grid item lg={4} md={4}>
                            <Grid container direction="column">
                              <Grid item>
                                <label className="text-10 text-bold text-silver-sand">
                                  Order Number
                                </label>
                              </Grid>
                              <Grid item>
                                <label className="text-12 text-bold text-opacity-50">
                                  {row.order_no}
                                </label>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item lg={4} md={4} className="grid-left-hr">
                            <Grid container direction="column">
                              <Grid item>
                                <label className="text-10 text-bold text-silver-sand">
                                  Date &amp; Time
                                </label>
                              </Grid>
                              <Grid item>
                                <label className="text-12 text-bold text-opacity-50">
                                  {`${CommonHelper.dateTimeParseNewFormat(
                                    row.order_date,
                                    'DD MMM YYYY, hh:mm A',
                                  )}`}
                                </label>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item lg={3} md={3} className="grid-left-hr">
                            <Grid container direction="column">
                              <Grid item>
                                <label className="text-10 text-bold text-silver-sand">
                                  Payment method
                                </label>
                              </Grid>
                              <Grid item>
                                <label className="text-12 text-bold text-opacity-50">
                                  {row.payment_type_title}
                                </label>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item className="pt-24">
                    <Grid container direction="row" justify="flex-start">
                      <Grid item className="section-label-filter">
                        <label className="text-12 text-bold">Order ID: {row.sales_order_id}</label>
                      </Grid>
                      <Grid item className="section-label-filter">
                        <label className="text-12 text-bold">
                          Order Type: {MasterDataHelper.getOrderTypeDescription(row.order_type)}
                        </label>
                      </Grid>
                      <Grid item className="section-label-filter">
                        <label className="text-12 text-bold">
                          Source: {_.capitalize(row.transaction_source)}
                        </label>
                      </Grid>
                      {row.external_order_id && (
                        <Grid item className="section-label-filter">
                          <label className="text-12 text-bold">
                            W-Order ID: {row.external_order_id}
                          </label>
                        </Grid>
                      )}
                      {row.tracking_number && (
                        <Grid item className="section-label-filter">
                          <label className="text-12 text-bold">
                            Tracking Number: {row.tracking_number}
                          </label>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          );
        },
      },
    ];
  };

  renderFilter() {
    const { filter, isResellerLogin } = this.state;
    const {
      orderData: { categoryList },
    } = this.props;

    const categoryOption = CommonHelper.renameKeyName(categoryList, 'name', 'value');

    const shouldHideAdvanceFilter =
      isResellerLogin || (currentUsers && currentUsers.role_name === UserRoleName.EatExpressAdmin);

    return (
      <Grid container direction="column" className="flex-wrap-unset">
        <Grid item>
          <Grid container justify="space-between" alignItems="center" className="container-filter">
            <Grid item lg={5} md={8}>
              <div className="flex-column">
                <SelectAutoComplete
                  placeholder={'Search Order'}
                  value={filter.search}
                  onChange={this.handleChangeSearch}
                  optionValueSelect={optionSearchType}
                  onChangeSelectAddon={this.handleChangeSearchType}
                  defaultValueSelect={this.state.filter.searchType}
                  prefix={'ic-ffo-search'}
                  size="middle"
                  autoCompleteOption={categoryOption}
                  isLoading={this.state.isLoadingAutoComplete}
                />
              </div>
            </Grid>
            <Grid item lg="auto" md={8} className="second-row-filter">
              <div className="flex-column">
                <Grid container justify="flex-end" alignItems="center">
                  <Grid item lg={3} md={3} className="text-right">
                    <label className="text-12 pr-16">Showing Date</label>
                  </Grid>
                  <Grid item lg={4} md={4}>
                    <div className="container-remove-margin">
                      <PickerInputDate
                        customIcon="ic-ffo-date-pick"
                        dateFormat="dd/MM/yyyy"
                        defaultValue={this.state.filter.startDate}
                        onChange={this.handleChangeStartDate}
                        toolbar={false}
                      />
                    </div>
                  </Grid>
                  <label className="text-12 pr-8 pl-8"> - </label>
                  <Grid item lg={4} md={4}>
                    <div className="container-remove-margin">
                      <PickerInputDate
                        customIcon="ic-ffo-date-pick"
                        dateFormat="dd/MM/yyyy"
                        minDate={filter.startDate}
                        defaultValue={this.state.filter.endDate}
                        onChange={this.handleChangeEndDate}
                        toolbar={false}
                      />
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </Grid>
        {!shouldHideAdvanceFilter && (
          <Grid item className="mt-24">
            <Grid container justify="space-between" className="section-filter" alignItems="center">
              <Grid item lg={8} md={8}>
                <Grid container justify="flex-start" className="container-filter">
                  {filter.status === 'completed' && (
                    <Grid item lg={4} md={4} className="row-filter">
                      <div className="flex-column">
                        <SelectInputMain
                          options={optionStatusPayment}
                          size="middle"
                          currentValue={filter.selectedStatusPayment}
                          onChange={this.handleChangePaymentStatus}
                          placeholder={'Select Payment Status'}
                        />
                      </div>
                    </Grid>
                  )}
                  <Grid item lg={4} md={4} className="row-filter">
                    <div className="flex-column">
                      <SelectInputMain
                        options={optionTransactionSource}
                        size="middle"
                        currentValue={filter.transactionSource}
                        onChange={this.handleChangeTransactionSource}
                        placeholder={'Select Source Type'}
                      />
                    </div>
                  </Grid>
                  <Grid item lg={4} md={4} className="row-filter">
                    <div className="flex-column">
                      <SelectInputMain
                        options={optionOrderType}
                        size="middle"
                        currentValue={filter.orderType}
                        onChange={this.handleChangeOrderType}
                        placeholder={'Select Order Type'}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item lg="auto" md="auto">
                <ButtonMain
                  type="ghost"
                  size="md"
                  labelText="Clear Filter"
                  onClick={this.handleClickReset}
                  startIcon="ic-ffo-uncheck-mark"
                />
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    );
  }

  render() {
    const {
      orderData: { orderList },
    } = this.props;
    const { isLoading, loading, pagination, toastInformation } = this.state;

    let renderElement = <SkeletonMain />;

    if (!isLoading) {
      renderElement = (
        <div className="container-page-content scroll-container">
          <div className="container-page-scrolling-area include-tab">
            <Grid container direction="column" className="flex-wrap-unset">
              <Grid item lg md sm className="section-page-header">
                {this.renderFilter()}
              </Grid>
              <Grid item lg md className="section-table-body">
                {_.isEmpty(orderList) ? (
                  <Grid container justify={'center'}>
                    <EmptyState source={Images.emptyStateNoHistory} labelText={'No Order Found'} />
                  </Grid>
                ) : (
                  <Table
                    columns={this.renderColumns()}
                    rowKey={record => record.sales_order_id}
                    dataSource={orderList}
                    pagination={pagination}
                    loading={loading}
                    onChange={this.handleTableChange}
                  />
                )}
              </Grid>
            </Grid>
            <SnackBarSimple
              open={toastInformation.isOpen}
              durationHide={2000}
              message={toastInformation.message}
              onClickClose={this.handleCloseToash}
              snackbarType={toastInformation.snackbarType}
              anchor={optionToash}
            />
          </div>
        </div>
      );
    }
    return renderElement;
  }
}

const mapDispatchToProps = dispatch => ({
  orderListPagination: params => getOrderList(dispatch, params),
  getCategoryOption: params => getCategoryList(dispatch, params),
});

const mapStateToProps = ({ masterDataMain, orderData, usersReducer }) => ({
  masterDataMain,
  orderData,
  usersReducer,
});

OrderPage.propTypes = {
  activeTab: PropTypes.string.isRequired,
  getCategoryOption: PropTypes.func,
  history: PropTypes.object,
  onSetPrevStateValue: PropTypes.func,
  orderData: PropTypes.object,
  orderListPagination: PropTypes.func,
  usersReducer: PropTypes.object,
};

const shell = compose(connect(mapStateToProps, mapDispatchToProps));

export default shell(PrevStateValue(OrderPage));
