import { constants } from '../reducers/MedicReducer';

export function getListBookingLabRequest() {
  return { type: constants.getListBookingLabRequest };
}
export function getListBookingLabSuccess(response) {
  return { type: constants.getListBookingLabSuccess, payload: response.data };
}
export function getListBookingLabFail(response) {
  return { type: constants.getListBookingLabFail, payload: response.data };
}

export function getBranchCovidTestRequest() {
  return { type: constants.getBranchCovidTestRequest };
}
export function getBranchCovidTestSuccess(response) {
  return { type: constants.getBranchCovidTestSuccess, payload: response };
}
export function getBranchCovidTestFail(response) {
  return { type: constants.getBranchCovidTestFail, payload: response.data };
}

export function getProductCovidTestRequest() {
  return { type: constants.getProductCovidTestRequest };
}
export function getProductCovidTestSuccess(response) {
  return { type: constants.getProductCovidTestSuccess, payload: response };
}
export function getProductCovidTestFail(response) {
  return { type: constants.getProductCovidTestFail, payload: response.data };
}

export function getProductByServiceLocationRequest() {
  return { type: constants.getProductByServiceLocationRequest };
}
export function getProductByServiceLocationSuccess(response) {
  return { type: constants.getProductByServiceLocationSuccess, payload: response };
}
export function getProductByServiceLocationFail(response) {
  return { type: constants.getProductByServiceLocationFail, payload: response.data };
}

export function getApdProductRequest() {
  return { type: constants.getApdProductRequest };
}
export function getApdProductSuccess(response) {
  return { type: constants.getApdProductSuccess, payload: response };
}
export function getApdProductFail(response) {
  return { type: constants.getApdProductFail, payload: response.data };
}

export function checkHomeServiceAreaCoverageRequest() {
  return { type: constants.checkHomeServiceAreaCoverageRequest };
}
export function checkHomeServiceAreaCoverageSuccess(response) {
  return { type: constants.checkHomeServiceAreaCoverageSuccess, payload: response };
}
export function checkHomeServiceAreaCoverageFail(response) {
  return { type: constants.checkHomeServiceAreaCoverageFail, payload: response.data };
}

export function getBookingResultRequest() {
  return { type: constants.getBookingResultRequest };
}
export function getBookingResultSuccess(response) {
  return { type: constants.getBookingResultSuccess, payload: response };
}
export function getBookingResultFail(response) {
  return { type: constants.getBookingResultFail, payload: response.data };
}

export function setReschedulBookingLabRequest() {
  return { type: constants.setReschedulBookingLabRequest };
}
export function setReschedulBookingLabSuccess(response) {
  return { type: constants.setReschedulBookingLabSuccess, payload: response };
}
export function setReschedulBookingLabFail(response) {
  return { type: constants.setReschedulBookingLabFail, payload: response.data };
}

export function sendResultLabRequest() {
  return { type: constants.sendResultLabRequest };
}
export function sendResultLabSuccess(response) {
  return { type: constants.sendResultLabSuccess, payload: response };
}
export function sendResultLabFail(response) {
  return { type: constants.sendResultLabFail, payload: response.data };
}

export function updateUserDetailRequest() {
  return { type: constants.updateUserDetailRequest };
}
export function updateUserDetailSuccess(response) {
  return { type: constants.updateUserDetailSuccess, payload: response };
}
export function updateUserDetailFail(response) {
  return { type: constants.updateUserDetailFail, payload: response.data };
}

export function updateUserBookingRequest() {
  return { type: constants.updateUserBookingRequest };
}
export function updateUserBookingSuccess(response) {
  return { type: constants.updateUserBookingSuccess, payload: response };
}
export function updateUserBookingFail(response) {
  return { type: constants.updateUserBookingFail, payload: response.data };
}

export function verifyScanFaceRequest() {
  return { type: constants.verifyScanFaceRequest };
}
export function verifyScanFaceSuccess(response) {
  return { type: constants.verifyScanFaceSuccess, payload: response };
}
export function verifyScanFaceFail(response) {
  return { type: constants.verifyScanFaceFail, payload: response.data };
}

export function getHealthPassportActivityRequest() {
  return { type: constants.getHealthPassportActivityRequest };
}
export function getHealthPassportActivitySuccess(response) {
  return { type: constants.getHealthPassportActivitySuccess, payload: response };
}
export function getHealthPassportActivityFail(response) {
  return { type: constants.getHealthPassportActivityFail, payload: response.data };
}
