import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Grid, IconButton } from '@material-ui/core';
import _ from 'lodash';
// Style
import './ModalEditDataStyle.scss';
// Component
import {
  SnackBarSimple,
  ButtonMain,
  ModalInformationPopUp,
} from '../../../../../../components/Index';
import { BookingDetailsForm, ContactPersonForm } from './components/Index';
// api
import { updateUserDetail, updateUserBooking } from '../../../../../../services/api/MedicApi';
// helper
import { CommonHelper } from '../../../../../../helpers/Index';

const optionToast = {
  vertical: 'top',
  horizontal: 'right',
};

class ModalEditData extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      validate: false,
      data: {},
      toastInformation: {
        isOpen: false,
        message: '',
        snackbarType: 'warning',
      },
    };
  }

  handleSubmitData = async () => {
    const {
      editType,
      salesOrderId,
      selectedCustomer,
      setUpdateUserBooking,
      setUpdateUserDetail,
      onSuccessReschedule,
    } = this.props;
    const { data } = this.state;
    let param = {};
    let response = {};

    this.setState({
      loading: true,
    });
    try {
      switch (editType) {
        case 'bookingDetails':
          param = {
            ...data,
            user_identity_id: selectedCustomer.user_identity_id,
            user_id: selectedCustomer.user_id,
            is_primary: selectedCustomer.is_primary,
            country_id: selectedCustomer.country_id,
            identity_address: selectedCustomer.identity_address,
            identity_address_info: selectedCustomer.identity_address_info,
            residential_address: selectedCustomer.residential_address,
            nationality: selectedCustomer.nationality,
          };
          response = await setUpdateUserBooking(salesOrderId, param);
          onSuccessReschedule(response.messages);
          this.setState({
            loading: false,
          });
          break;
        default:
          param = {
            first_name: data.firstName,
            last_name: data.lastName,
            phone: data.phone,
            email: data.email,
            birthday: data.birthday,
            gender: data.gender.value,
          };
          response = await setUpdateUserDetail(salesOrderId, param);
          onSuccessReschedule(response.messages);
          this.setState({
            loading: false,
          });
          break;
      }
    } catch (error) {
      this.processMessage(error.data, 'error');
      this.setState({
        loading: false,
      });
    }
  };

  handleCallbackMessage = (message, type) => {
    this.processMessage(message, type);
  };

  handleUpdateValidation = payload => {
    const { validate } = this.state;

    if (validate) {
      this.setState({ validate: !validate });
    }

    if (payload.isError) {
      this.processMessage('Please complete all data.', 'error');
    } else {
      this.handleSubmitData();
    }
  };

  handleParameterUpdate = data => {
    this.setState({ data });
  };

  handleCloseToast = () => {
    const { toastInformation } = this.state;
    this.setState({ toastInformation: { ...toastInformation, isOpen: false } });
  };

  processMessage(messages, type) {
    const convertedMessage = CommonHelper.generateMessage(messages);

    const paramInformation = {
      isOpen: true,
      message: convertedMessage,
      snackbarType: type,
    };

    this.setState({
      toastInformation: paramInformation,
    });
  }

  render() {
    const { isOpen, onClose, editType, data, selectedCustomer } = this.props;
    const { loading, validate, toastInformation } = this.state;
    const customerDetailsData = !_.isEmpty(data) ? data.customer_details : {};

    let renderBodyElement = null;

    switch (editType) {
      case 'contactPerson':
        renderBodyElement = (
          <ContactPersonForm
            datas={customerDetailsData}
            validate={validate}
            onCallbackMessage={this.handleCallbackMessage}
            onValidationUpdate={this.handleUpdateValidation}
            onParameterUpdate={this.handleParameterUpdate}
          />
        );
        break;
      case 'bookingDetails':
        renderBodyElement = (
          <BookingDetailsForm
            selectedCustomer={selectedCustomer}
            validate={validate}
            onCallbackMessage={this.handleCallbackMessage}
            onValidationUpdate={this.handleUpdateValidation}
            onParameterUpdate={this.handleParameterUpdate}
          />
        );
        break;

      default:
        break;
    }

    const elementRenderCustom = (
      <Grid container direction="column" className="flex-wrap-unset">
        <Grid item lg md className="section-header-modal">
          <Grid container>
            <Grid item lg={11} md={11}>
              <h5 className="wrapping-container second line">Edit Data</h5>
            </Grid>
            <Grid item g={1} md={1}>
              <Grid container direction="column" justify="flex-end">
                <IconButton onClick={onClose}>
                  <i className="ic-ffo-close container-icon-prefix size-21" />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg md className="section-body-modal">
          <div className="scroll-container">{renderBodyElement}</div>
        </Grid>
        <Grid item className="section-footer-modal">
          <Grid container justify="flex-end" spacing={2}>
            <Grid item lg={3} md={3}>
              <ButtonMain
                labelText="Cancel"
                onClick={onClose}
                type="ghost"
                size="md"
                isLoading={loading}
              />
            </Grid>
            <Grid item lg={3} md={3}>
              <ButtonMain
                labelText="Save"
                onClick={() => {
                  this.setState({ validate: true });
                }}
                type="primary"
                size="md"
                isLoading={loading}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );

    return (
      <>
        <ModalInformationPopUp
          isOpen={isOpen}
          onClose={onClose}
          customElementProps={elementRenderCustom}
          size="large"
        />
        <SnackBarSimple
          open={toastInformation.isOpen}
          durationHide={2000}
          message={toastInformation.message}
          onClickClose={this.handleCloseToast}
          snackbarType={toastInformation.snackbarType}
          anchor={optionToast}
        />
      </>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  setUpdateUserDetail: (salesOrderId, params) => updateUserDetail(dispatch, salesOrderId, params),
  setUpdateUserBooking: (salesOrderId, params) => updateUserBooking(dispatch, salesOrderId, params),
});

ModalEditData.propTypes = {
  data: PropTypes.object,
  editType: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onSuccessReschedule: PropTypes.func,
  salesOrderId: PropTypes.number,
  selectedCustomer: PropTypes.object,
  setUpdateUserBooking: PropTypes.func,
  setUpdateUserDetail: PropTypes.func,
};

export default connect(null, mapDispatchToProps)(ModalEditData);
