import React from 'react';
import PropTypes from 'prop-types';
import { Skeleton } from '@material-ui/lab';
import { Skeleton as SkeletonAnt } from 'antd';
// component
import { ChartPieGraphic, LoaderCircle } from '../../../../components/Index';
import { TooltipChatPieCustom } from '../../../../components/presentational/chart-pie-graphic/components/Index';
// helper
import { CommonHelper, currencyCode } from '../../../../helpers/Index';
// Style
import './CardDashboardSummaryStyle.scss';
import 'react-circular-progressbar/dist/styles.css';
import { Grid } from '@material-ui/core';

const CardDashboardSummary = props => {
  const { data, isContentNotUsingCurrency, isLoading } = props;

  let elementRender = (
    <Grid container spacing={1} direction="column">
      <Grid item lg md>
        <Skeleton
          variant="rect"
          width="100%"
          height={200}
          animation="wave"
          className="skeletonRounded"
        />
      </Grid>
      <Grid item lg md>
        <SkeletonAnt
          variant="rect"
          width="100%"
          height={300}
          animation="wave"
          className="skeletonRounded"
        />
      </Grid>
    </Grid>
  );

  if (!isLoading) {
    elementRender = (
      <div className="flex-column">
        <ChartPieGraphic
          dataArray={data}
          height={200}
          tooltipCustom={<TooltipChatPieCustom />}
          innerRadius="30%"
          outerRadius="85%"
        />
        {data.map((item, index) => {
          const wordingValue = isContentNotUsingCurrency
            ? `${item.value}`
            : `${currencyCode.idr} ${CommonHelper.formatCurrency(item.value)}`;
          const wordingName = CommonHelper.renameRemoveCharacter(item.name, '_');

          return (
            <Grid key={index} container justify="flex-start" spacing={1} className="pv-16">
              <Grid item className="container-custom-loading-circle">
                <LoaderCircle color={item.color} strokeWidth={25} progress={100} />
              </Grid>
              <Grid item lg={6} md={6}>
                <div className="flex-column">
                  <label className="text-14 text-bold mb-4">{wordingValue}</label>
                  <label className="text-14 text-semi-bold text-rolling-stone">{wordingName}</label>
                </div>
              </Grid>
              <Grid item lg md>
                <Grid container justify="flex-end">
                  <label className="text-14 text-bold text-right wrapping-container-break">
                    {item.percent}%
                  </label>
                </Grid>
              </Grid>
            </Grid>
          );
        })}
      </div>
    );
  }

  return (
    <div className="container-dashboard-summary container-main-card ph-16 pt-24 pb-16 flex-column">
      <label className="text-14 text-semi-bold mb-13">Summary</label>
      {elementRender}
    </div>
  );
};

CardDashboardSummary.propTypes = {
  data: PropTypes.array,
  isContentNotUsingCurrency: PropTypes.bool,
  isLoading: PropTypes.bool,
};

export default CardDashboardSummary;
