/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-properties */
/* eslint-disable radix */

import { CommonHelper } from './Index';
import { MasterDataHelper } from './MasterDataHelper';

const PointBoosterHelper = {};

const PointBoosterStatus = {
  Active: 10,
  Freeze: 6,
  Suspended: 4,
  Inactive: 0,
  Stopped: -1,
  NoQuota: 1,
  NotStarted: 2,
};

const PointBoosterStatusDescription = [
  { value: 10, name: 'Active' },
  { value: 6, name: 'Freeze' },
  { value: 4, name: 'Suspended' },
  { value: 2, name: 'Not Started' },
  { value: 1, name: 'No Quota' },
  { value: 0, name: 'Inactive' },
  { value: -1, name: 'Deleted' },
];

export { PointBoosterHelper, PointBoosterStatus, PointBoosterStatusDescription };
