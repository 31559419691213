/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import { Grid, IconButton } from '@material-ui/core';
import { connect } from 'react-redux';
// api
import {
  getScheduleByIdDetails,
  setScheduleByIdDetailsTrainer,
  setScheduleDetailStatus,
  setProcessCancelOrderBooking,
  setProcessCompleteOrderBookingCoach,
} from '../../../../../../services/api/ScheduleDataApi';
// component
import {
  ModalInformationPopUp,
  ButtonMain,
  ModalAlertConfirmation,
  SelectInputMain,
  SnackBarSimple,
  ModalAlertInformation,
  ButtonIconMain,
  SelectInputCoachByProduct,
} from '../../../../../../components/Index';
import { SkeletonScheduleDetails } from '../Index';
// helper
import {
  CalendarHelper,
  CommonHelper,
  ClassStatusHelper,
  MasterDataHelper,
  UserStatus,
  ScheduleStatus,
  orderBookActionCode,
  HttpStatusCode,
} from '../../../../../../helpers/Index';

const initialParticipant = {
  clientName: null,
  bookingDate: null,
};
const initialDetail = {
  scheduleId: null,
  scheduleName: null,
  dateStart: null,
  dateEnd: null,
  duration: null,
  slot: null,
  coachName: null,
  appointmentDate: null,
  locationActivity: null,
  participant: [initialParticipant],
};

const initialFilter = {
  name: null,
  productId: null,
  trainerId: null,
  merchantId: null,
  timeRangeValue: null,
  dayValue: null,
};

const modalAlertOption = {
  title: 'Confirmation',
  text: 'Are Your Sure want to DELETE this Schedule',
  icon: 'warning',
  successMessage: 'Deleted Schedule Success',
};

const modalAlertOptionInformation = {
  title: 'Information',
  text: `You can't Perform this Action, Please Cancel all Appointment Thant Already Made, before changing schedule status`,
};

const initialValidation = {
  trainer: { isError: '', errorMessage: '' },
};

const resetValidation = { isError: '', errorMessage: '' };

const optionToash = {
  vertical: 'top',
  horizontal: 'right',
};

const dataStatus = MasterDataHelper.statusSchedule.slice(
  0,
  MasterDataHelper.statusSchedule.length - 1,
);
const today = CommonHelper.currentDate('YYYY-MM-DD HH:mm:ss');
const currentDate = CommonHelper.dateTimeParseNewFormat(today, 'YYYY-MM-DD');
const currentTime = CommonHelper.dateTimeParseNewFormat(today, 'HH:mm:ss');

class ModalScheduleDetailCoach extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      openModal: false,
      detailSchedule: initialDetail,
      isOpenModalInformation: false,
      validation: initialValidation,
      isLoading: false,
      isEdit: false,
      trainerId: null,
      filter: initialFilter,
      toastInformation: {
        isOpen: false,
        message: '',
        snackbarType: 'warning',
      },
    };
  }

  componentDidMount() {
    const { isOpen, scheduleId } = this.props;

    this.setState({ openModal: isOpen });
    if (scheduleId) {
      this.getScheduleByIdDetails(scheduleId);
    }
  }

  componentWillUnmount() {}

  getScheduleByIdDetails(selectedId) {
    const { scheduleById } = this.props;
    const param = {
      training_schedule_id: selectedId,
    };

    this.setState({ isLoading: true }, () => {
      scheduleById(param)
        .then(response => {
          const data = response;
          const converted = CalendarHelper.generateCalenderModalDetails(data);
          const updatedFilter = initialFilter;
          updatedFilter.merchantId = data.merchant_id;
          updatedFilter.branchId = data.branch_id;
          updatedFilter.productId = data.product_id;

          this.setState({
            detailSchedule: converted,
            isLoading: false,
            filter: updatedFilter,
            trainerId: data.trainer_id,
          });
        })
        .catch(async error => {
          const message = error.data;
          this.processMessage(message.messages, 'error');
        });
    });
  }

  setScheduleByIdDetailsTrainer(trainerId) {
    const { scheduleByIdSetTrainer, scheduleId } = this.props;
    const param = {
      data: {
        training_schedule_id: scheduleId,
        trainer_id: trainerId,
      },
    };

    this.setState({ isLoading: true }, () => {
      scheduleByIdSetTrainer(param)
        .then(async response => {
          const message = response.messages;
          await this.processMessage(message, 'success');
          this.getScheduleByIdDetails(scheduleId);
        })
        .catch(async error => {
          const message = error.data;
          this.setState(
            {
              isLoading: false,
            },
            () => {
              this.processMessage(message.messages, 'error');
            },
          );
        });
    });
  }

  setScheduleDetailStatus(param) {
    const { scheduleDetailStatus, scheduleId } = this.props;

    scheduleDetailStatus(param)
      .then(async response => {
        const message = response.messages;
        await this.processMessage(message, 'success');
        await this.getScheduleByIdDetails(scheduleId);
      })
      .catch(async error => {
        const message = error.data;
        this.processMessage(message.messages, 'error');
      });
  }

  handleClose = () => {
    const { onClose } = this.props;
    onClose(false);
    this.setState({ openModal: false });
  };

  handleChangeStatus = async value => {
    const { scheduleId } = this.props;
    const { detailSchedule } = this.state;

    const param = { data: { training_schedule_id: scheduleId, status: value } };

    if (detailSchedule.participant.length === 0) {
      await this.setScheduleDetailStatus(param);
      await this.getScheduleByIdDetails(scheduleId);
    } else {
      this.setState({ isOpenModalInformation: true });
    }
  };

  handleButtonSetTrainerClick = () => {
    const { isEdit } = this.state;
    this.setState({ ...isEdit, isEdit: !isEdit });
  };

  handleButtonDelete = async value => {
    const { scheduleId } = this.props;
    const { detailSchedule } = this.state;

    if (detailSchedule.participant.length === 0) {
      if (value) {
        const param = { data: { training_schedule_id: scheduleId, status: UserStatus.Deleted } };

        await this.setScheduleDetailStatus(param);
        await setTimeout(() => {
          this.handleClose();
        }, 1500);
      }
    } else {
      this.setState({ isOpenModalInformation: true });
    }
  };

  handleSelectCoach = value => {
    const { validation } = this.state;

    this.setState({
      trainerId: value,
      validation: {
        ...validation,
        trainer: resetValidation,
      },
    });
  };

  handleClickButtonAction = (value, appointmentId, trainerUserId) => {
    const { processCancelOrderBooking, processCompleteOrderBooking, scheduleId } = this.props;
    const param = {
      data: {
        appointment_id: appointmentId,
      },
    };

    switch (value) {
      case orderBookActionCode.Cancel:
        processCancelOrderBooking(param)
          .then(response => {
            const message = response.messages;
            this.processMessage(message, 'success');
            this.getScheduleByIdDetails(scheduleId);
          })
          .catch(async error => {
            const serverMessage = error.data;
            const validationStatus = error.status === HttpStatusCode.InternalServerError;
            this.processMessage(
              validationStatus ? serverMessage.message : serverMessage.messages,
              'error',
            );
          });
        break;
      case orderBookActionCode.Complete:
        processCompleteOrderBooking(trainerUserId, param)
          .then(response => {
            const message = response.messages;
            this.processMessage(message, 'success');
            this.getScheduleByIdDetails(scheduleId);
          })
          .catch(async error => {
            const serverMessage = error.data;
            const validationStatus = error.status === HttpStatusCode.InternalServerError;
            this.processMessage(
              validationStatus ? serverMessage.message : serverMessage.messages,
              'error',
            );
          });
        break;
      default:
        break;
    }
  };

  handleButtonSaveTrainerClick = async () => {
    const { isEdit, trainerId, validation } = this.state;

    if (trainerId) {
      await this.setScheduleByIdDetailsTrainer(trainerId);
      await this.setState({ ...isEdit, isEdit: !isEdit });
    } else {
      const errorMessage = {
        isError: 'error',
        errorMessage: 'Please Select Your Coach...',
      };

      this.setState({
        validation: {
          ...validation,
          trainer: errorMessage,
        },
      });
    }
  };

  handleCloseToash = () => {
    const { toastInformation } = this.state;
    this.setState({ toastInformation: { ...toastInformation, isOpen: false } });
  };

  validationPassSchedule = (date, time, isAvailable) => {
    let result = false;
    if (currentDate === date) {
      if (
        !CommonHelper.compareDateAndTime(currentDate, date, 'date') &&
        !CommonHelper.compareDateAndTime(currentTime, `${time}:00`, 'time')
      ) {
        if (isAvailable) result = true;
      }
    } else if (!CommonHelper.compareDateAndTime(currentDate, date, 'date')) {
      if (isAvailable) result = true;
    }

    return result;
  };

  processMessage(messages, type) {
    const convertedMessage = CommonHelper.generateMessage(messages);

    const paramInformation = {
      isOpen: true,
      message: convertedMessage,
      snackbarType: type,
    };

    this.setState({
      toastInformation: paramInformation,
    });
  }

  // eslint-disable-next-line no-unused-vars
  renderTrainer(passDisplay) {
    const { detailSchedule, isEdit, filter } = this.state;

    const coachFilter = {
      productId: filter.productId,
    };

    return !isEdit ? (
      <Grid container>
        <Grid item lg md>
          <label className="text-14">{detailSchedule.coachName || ''}</label>
        </Grid>
        {/* <Grid item lg={3} md={4}>
          {!passDisplay ? (
            <ButtonMain
              labelText="Set Trainer"
              onClick={this.handleButtonSetTrainerClick}
              type="ghost"
              size="sm"
            />
          ) : null}
        </Grid> */}
      </Grid>
    ) : (
      <Grid container>
        <Grid item lg md>
          <SelectInputCoachByProduct
            placeHolder="Select Coach"
            onChange={this.handleSelectCoach}
            errorMessage={this.state.validation.trainer.errorMessage}
            validateStatus={this.state.validation.trainer.isError}
            filter={coachFilter}
          />
        </Grid>
        <Grid item lg={3} md={4} className="container-button-update-coach">
          <ButtonMain
            labelText="Save"
            onClick={this.handleButtonSaveTrainerClick}
            type="primary"
            size="xl"
          />
        </Grid>
      </Grid>
    );
  }

  renderParticipant() {
    const { detailSchedule, filter } = this.state;
    const { onClickReschedule } = this.props;

    return detailSchedule.participant ? (
      <Grid container direction="column" className="container-table flex-wrap-unset">
        <Grid item lg md>
          <Grid container className="pr-15">
            <Grid item md={6}>
              <label className="text-12 title">Participant</label>
            </Grid>
            <Grid item md={3}>
              <label className="text-12 title">Booking Date</label>
            </Grid>
            <Grid item md={3} className="text-center">
              <label className="text-12 title">Action</label>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg>
          <div className="divider-hr" />
        </Grid>
        <Grid item lg md>
          <div className="scroll-container container-table-body">
            {detailSchedule.participant.map((item, index) => (
              <Grid key={index} container direction="column" className="table-row-modal">
                <Grid item lg md>
                  <Grid container direction="row" alignItems="center">
                    <Grid item md={6}>
                      <label className="text-14">{item.clientName}</label>
                      <span className="flex-row align-center">
                        <i className="ic-ffo-phone container-icon-prefix size-14 mr-6" />
                        <label className="text-10 text-regular text-mine-shaft">
                          {item.clientPhone}
                        </label>
                      </span>
                    </Grid>
                    <Grid item md={3}>
                      <label className="text-12 date">{item.bookingDate}</label>
                    </Grid>
                    <Grid item md={3}>
                      <Grid container justify="center" alignItems="center">
                        <Grid item>
                          <ButtonIconMain
                            icon="ic-ffo-check-mark"
                            type="complete"
                            size="sm"
                            onClick={() => {
                              this.handleClickButtonAction(
                                orderBookActionCode.Complete,
                                item.appointmentId,
                                item.trainer.user_id,
                              );
                            }}
                            tooltip="Complete Appointment"
                          />
                        </Grid>
                        <Grid item>
                          <ButtonIconMain
                            icon="ic-ffo-calendar"
                            type="primary"
                            size="sm"
                            onClick={() => {
                              const params = {
                                ...item,
                                productId: filter.productId,
                                branchId: filter.branchId,
                                location: detailSchedule.locationActivity,
                                dateStart: detailSchedule.dateStart,
                                dateEnd: detailSchedule.dateEnd,
                                scheduleName: detailSchedule.scheduleName,
                                appointmentDate: detailSchedule.appointmentDate,
                              };
                              onClickReschedule(params);
                            }}
                            tooltip="Reschedule Appointment"
                          />
                        </Grid>
                        <Grid item>
                          <ButtonIconMain
                            icon="ic-ffo-bin"
                            type="negative"
                            size="sm"
                            onClick={() => {
                              this.handleClickButtonAction(
                                orderBookActionCode.Cancel,
                                item.appointmentId,
                                null,
                              );
                            }}
                            tooltip="Cancel Appointment"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item lg md>
                  <div className="divider-hr" />
                </Grid>
              </Grid>
            ))}
            {detailSchedule.participant.length === 0 && (
              <Grid>
                <label className="text-14">No bookings yet</label>
              </Grid>
            )}
          </div>
        </Grid>
      </Grid>
    ) : null;
  }

  renderTimeSlot(passDisplay) {
    const { detailSchedule } = this.state;
    const activeStyle = !passDisplay
      ? detailSchedule.isAvailable
        ? ''
        : 'apricot'
      : 'rolling-stone';

    return (
      <Grid container direction="column" className="container-time-and-slot">
        <Grid item lg md className="container-time">
          <label className="text-16">
            {`${detailSchedule.dateStart} - ${detailSchedule.dateEnd}  `}
            <span className="dot" /> {detailSchedule.duration}
          </label>
        </Grid>
        <Grid item lg md>
          <div className="divider-hr" />
        </Grid>
        <Grid item lg md className="container-slot">
          <span className="flex-row">
            <i className={`ic-ffo-check-mark container-icon-prefix size-16 text-${activeStyle}`} />
            <label className={`text-16 text-${activeStyle}`}>{detailSchedule.slot}</label>
          </span>
        </Grid>
      </Grid>
    );
  }

  render() {
    const {
      openModal,
      detailSchedule,
      isLoading,
      toastInformation,
      isOpenModalInformation,
      isEdit,
    } = this.state;
    const { onClickBook } = this.props;

    let renderElement = <SkeletonScheduleDetails />;
    const passDisplay = !this.validationPassSchedule(
      detailSchedule.date,
      detailSchedule.dateStart,
      detailSchedule.isAvailable,
    );

    const isStatusDisable =
      detailSchedule.status !== ScheduleStatus.Active &&
      detailSchedule.status !== ScheduleStatus.waiting;

    const validateButtonBookDisable = !detailSchedule.isAvailable || isEdit;

    if (!isLoading) {
      renderElement = (
        <Grid container direction="column" className="flex-wrap-unset">
          <Grid item lg md className="section-header-modal">
            <Grid container>
              <Grid item lg={11} md={11}>
                <h5 className="wrapping-container second line">{detailSchedule.scheduleName}</h5>
              </Grid>
              <Grid item>
                <Grid container direction="column" justify="flex-end">
                  <Grid item>
                    <div className="container-button-close">
                      <IconButton onClick={this.handleClose}>
                        <i className="ic-ffo-close container-icon-prefix size-21" />
                      </IconButton>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg md className="section-body-modal">
            <Grid container direction="column" className="flex-wrap-unset">
              <Grid item lg md>
                <Grid container justify="space-between" alignItems="center">
                  <Grid item lg={3} md={3}>
                    {detailSchedule.shiftStatus !== null ? (
                      <label className="text-16 shift">
                        {ClassStatusHelper.statusInAndOutShift(detailSchedule.shiftStatus)}
                      </label>
                    ) : null}
                  </Grid>
                  <Grid item lg md>
                    <Grid container justify="flex-end">
                      {!passDisplay ? (
                        <Grid item lg md className="container-detail-status">
                          <div className="flex-row coach">
                            <label className="text-12">Status</label>
                            <div
                              className={`${
                                detailSchedule.status === ScheduleStatus.waiting
                                  ? 'disable-content'
                                  : ''
                              }`}
                            >
                              <SelectInputMain
                                options={dataStatus}
                                size="small"
                                currentValue={detailSchedule.status}
                                onChange={this.handleChangeStatus}
                              />
                            </div>
                          </div>
                        </Grid>
                      ) : null}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item lg md>
                {this.renderTimeSlot(passDisplay)}
              </Grid>
              <Grid item lg md className="row-modal">
                <label className="text-12 title">Trainer</label>
                {this.renderTrainer(passDisplay)}
              </Grid>
              <Grid item lg md className="row-modal">
                <div className="flex-column">
                  <label className="text-12 title">Appointment</label>
                  <label className="text-14">{detailSchedule.appointmentDate}</label>
                </div>
              </Grid>
              <Grid item lg md className="row-modal">
                <div className="flex-column">
                  <label className="text-12 title">Location</label>
                  <label className="text-14">{detailSchedule.locationActivity}</label>
                </div>
              </Grid>
              <Grid item lg md className="row-modal">
                {this.renderParticipant()}
              </Grid>
            </Grid>
          </Grid>
          <Grid item className="section-footer-modal">
            {!passDisplay && !isStatusDisable && (
              <Grid container justify="flex-end" spacing={2}>
                <Grid item lg={3} md={3}>
                  <ModalAlertConfirmation
                    label="Delete"
                    onClick={this.handleButtonDelete}
                    type="danger"
                    size="xl"
                    optionModal={modalAlertOption}
                  />
                </Grid>
                <Grid item lg={3} md={3}>
                  <ButtonMain
                    labelText="Book"
                    onClick={onClickBook}
                    type="primary"
                    size="xl"
                    disabled={validateButtonBookDisable}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
          <SnackBarSimple
            open={toastInformation.isOpen}
            durationHide={2000}
            message={toastInformation.message}
            onClickClose={this.handleCloseToash}
            snackbarType={toastInformation.snackbarType}
            anchor={optionToash}
          />
          <ModalAlertInformation
            optionModal={modalAlertOptionInformation}
            isOpen={isOpenModalInformation}
            onCloseModal={this.handleCloseModalInformation}
            typeModal={'information'}
          />
        </Grid>
      );
    }

    return (
      <ModalInformationPopUp
        isOpen={openModal}
        onClose={this.handleClose}
        customElementProps={renderElement}
      />
    );
  }
}

const mapDispatchToProps = dispatch => ({
  scheduleById: params => getScheduleByIdDetails(dispatch, params),
  scheduleByIdSetTrainer: params => setScheduleByIdDetailsTrainer(dispatch, params),
  scheduleDetailStatus: params => setScheduleDetailStatus(dispatch, params),
  processCancelOrderBooking: params => setProcessCancelOrderBooking(dispatch, params),
  processCompleteOrderBooking: (trainerID, params) =>
    setProcessCompleteOrderBookingCoach(dispatch, trainerID, params),
});

const mapStateToProps = () => ({});

ModalScheduleDetailCoach.propTypes = {
  isOpen: PropTypes.bool,
  onClickBook: PropTypes.func,
  onClickReschedule: PropTypes.func,
  onClose: PropTypes.func,
  processCancelOrderBooking: PropTypes.func,
  processCompleteOrderBooking: PropTypes.func,
  scheduleById: PropTypes.func,
  scheduleByIdSetTrainer: PropTypes.func,
  scheduleDetailStatus: PropTypes.func,
  scheduleId: PropTypes.number,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalScheduleDetailCoach);
