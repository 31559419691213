import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Grid, Breadcrumbs, Link, FormControl } from '@material-ui/core';
import _ from 'lodash';
import { compose } from 'redux';
// Style
import './FeedAddStyle.scss';
import {
  SnackBarSimple,
  ButtonMain,
  TextEditor,
  TextInput,
  DeepLink,
  PickerInputDateAndTime,
  MediaInput,
  RadioInput,
  SelectInputFeedCategory,
  AuthenticationAccessPages,
  PrevStateValue,
  LabelInput,
} from '../../../../components/Index';
// Action
import { setCreateFeed, getFeedDetails, setUpdateFeed } from '../../../../services/api/FeedApi';
// Helper
import {
  CommonHelper,
  MasterDataHelper,
  HttpStatusCode,
  PermissionModule,
  PermissionPage,
  PermissionAccess,
} from '../../../../helpers/Index';
import DeepLinkHelper from '../../../../helpers/DeepLinkHelper';
import { ModalFeedDetail } from '../components/Index';

const deeplinkOptions = MasterDataHelper.deeplinkType;
const mediaOption = MasterDataHelper.mediaOption;
const statusOption = MasterDataHelper.statusGlobal.slice(0, 2);
const currentDate = CommonHelper.currentDate('YYYY-MM-DD HH:mm');

const optionToash = {
  vertical: 'top',
  horizontal: 'right',
};

const initialValidation = {
  title: { isError: false, errorMessage: '' },
  description: { isError: '', errorMessage: '' },
  category: { isError: '', errorMessage: '' },
  identifier: { isError: '', errorMessage: '' },
  screen: { isError: '', errorMessage: '' },
  webUrl: { isError: '', errorMessage: '' },
  base64: { isError: '', errorMessage: '' },
  url: { isError: false, errorMessage: '' },
};

const initialForm = {
  feedId: null,
  title: '',
  description: '',
  descriptionHtml: '',
  categoryId: null,
  publishDate: CommonHelper.intervalTime(currentDate, 'YYYY-MM-DD HH:mm', 5, 'hour'),
  deeplinkType: deeplinkOptions[0].value,
  identifier: null,
  subIdentifier: null,
  webUrl: null,
  screen: null,
  disabledIdentifier: true,
  base64: [],
  url: '',
  status: statusOption[0],
  activeMedia: mediaOption[0],
};

class FeedAdd extends React.Component {
  constructor(props) {
    super(props);

    props.checkUserAccessPermission(
      PermissionModule.Marketing,
      PermissionPage.Feeds,
      PermissionAccess.Add,
    );

    this.state = {
      pagesTitle: {
        helmet: 'FITCO | Maketing - Create Feed',
        breadcrumbs: 'Create Feed',
        card: 'Marketing - Feed',
      },
      isLoading: false,
      isOpenModal: false,
      form: CommonHelper.objectCloning(initialForm),
      previewData: {},
      validation: CommonHelper.objectCloning(initialValidation),
      toastInformation: {
        isOpen: false,
        message: '',
        snackbarType: 'warning',
      },
    };
  }

  componentDidMount() {
    const {
      feedDetails,
      match: { params },
    } = this.props;
    const { form, pagesTitle } = this.state;
    const feedId = params.feed_id;
    if (feedId) {
      feedDetails(feedId)
        .then(response => {
          const data = response.data;
          const publishDate =
            CommonHelper.dateTimeParseNewFormat(data.publish_date, 'YYYY-MM-DD HH:mm') ||
            currentDate;
          const deepLink = !_.isEmpty(data.feeds_deeplink) ? data.feeds_deeplink : {};
          const mediaType = _.find(mediaOption, ['value', data.media_type]);
          const mediaSource = {};
          if (mediaType.value === mediaOption[0].value) {
            const converted = [];
            data.media.forEach(item => {
              converted.push(item.source);
            });
            mediaSource.base64 = converted;
          } else {
            mediaSource.url = data.media[0].source;
          }
          const status = _.find(statusOption, ['value', data.status]);

          this.setState({
            form: {
              ...form,
              feedId: data.feeds_id,
              title: data.feeds_title,
              description: data.feeds_description,
              descriptionHtml: data.feeds_description,
              categoryId: data.category_id,
              publishDate,
              activeMedia: mediaType,
              deeplinkType: deepLink.type || deeplinkOptions[0].value,
              identifier: deepLink.item_id || null,
              subIdentifier: deepLink.subitem_id || null,
              webUrl: deepLink.url || null,
              screen: deepLink.screen_name || null,
              status,
              ...mediaSource,
            },
            pagesTitle: {
              ...pagesTitle,
              helmet: 'FITCO | Marketing - Edit Feed',
              breadcrumbs: 'Edit Feed',
              card: 'Marketing - Feed',
            },
          });
        })
        .catch(error => {
          const serverMessage = error.data;
          const validationStatus = error.status === HttpStatusCode.InternalServerError;
          this.processMessage(
            validationStatus ? serverMessage.message : serverMessage.messages,
            'error',
          );
        });
    }
  }

  componentDidUpdate(prevProps) {
    const { masterData } = this.props;
    const isEqual = _.isEqual(prevProps.masterData.feedCategoryList, masterData.feedCategoryList);

    if (!isEqual) {
      const defaultCategory = masterData.feedCategoryList.length
        ? masterData.feedCategoryList[0].feeds_category_id
        : null;

      this.handleSelectCategory(defaultCategory);
    }
  }

  setCreateFeed(params) {
    const { createFeed, history } = this.props;
    const {
      form: { feedId },
    } = this.state;
    this.setState({ isLoading: true }, () => {
      createFeed(params)
        .then(async response => {
          const message = response.messages;
          await this.processMessage(message, 'success');

          if (!feedId) {
            setTimeout(() => {
              history.push('/marketing/feed');
            }, 2000);
          } else {
            await this.setState({ isLoading: false });
          }
        })
        .catch(async error => {
          const serverMessage = error.data;
          const validationStatus = error.status === HttpStatusCode.InternalServerError;
          this.processMessage(
            validationStatus ? serverMessage.message : serverMessage.messages,
            'error',
          );
        });
    });
  }

  setUpdateFeed(params, id) {
    const { updateFeed } = this.props;
    updateFeed(params, id)
      .then(async response => {
        const message = response.messages;
        await this.processMessage(message, response.status);
      })
      .catch(async error => {
        const serverMessage = error.data;
        const validationStatus = error.status === HttpStatusCode.InternalServerError;
        this.processMessage(
          validationStatus ? serverMessage.message : serverMessage.messages,
          'error',
        );
      });
  }

  handleChangeImage = imageBase64 => {
    const { form, validation } = this.state;
    const message = CommonHelper.objectCloning(initialValidation.base64);
    const valid = !imageBase64;
    message.isError = !valid ? '' : 'error';
    message.errorMessage = valid ? 'Please choose image' : message.errorMessage;

    this.setState({
      form: { ...form, imageBase64 },
      validation: { ...validation, imageBase64: message },
    });
  };

  handleCloseToash = () => {
    const { toastInformation } = this.state;
    this.setState({ toastInformation: { ...toastInformation, isOpen: false } });
  };

  handleBack = () => {
    this.props.history.goBack();
  };

  handleTextChangeFeedTitle = value => {
    const { form, validation } = this.state;
    const message = CommonHelper.objectCloning(initialValidation);
    const valid = !value;
    message.isError = valid;
    message.errorMessage = valid ? 'Title is Empty' : message.errorMessage;

    this.setState({
      form: { ...form, title: value },
      validation: { ...validation, title: message },
    });
  };

  handleTextChangeDescription = (content, string) => {
    const { form, validation } = this.state;
    const message = CommonHelper.objectCloning(initialValidation.description);
    const valid = !string.trim();
    message.isError = valid ? 'error' : message.isError;
    message.errorMessage = valid ? 'Description is Empty' : message.errorMessage;

    this.setState({
      form: { ...form, description: string.trim(), descriptionHtml: content },
      validation: { ...validation, description: message },
    });
  };

  handleSelectCategory = value => {
    const { form, validation } = this.state;
    const message = CommonHelper.objectCloning(initialValidation.category);
    const valid = !value;
    message.isError = valid ? 'error' : message.isError;
    message.errorMessage = valid ? 'Category is Empty' : message.errorMessage;

    this.setState({
      form: { ...form, categoryId: value },
      validation: { ...validation, category: message },
    });
  };

  handleParameterUpdateDeepLink = (formValue, ValidationValue) => {
    const { form } = this.state;
    this.setState({
      form: {
        ...form,
        deeplinkType: formValue.deeplinkType,
        identifier: formValue.identifier,
        subIdentifier: formValue.subIdentifier,
        webUrl: formValue.webUrl,
        screen: formValue.screen,
        disabledIdentifier: formValue.disabledIdentifier,
      },
      validation: ValidationValue,
    });
  };

  handleParameterUpdateMedia = (formValue, ValidationValue) => {
    const { form } = this.state;
    this.setState({
      form: {
        ...form,
        activeMedia: formValue.activeMedia || mediaOption[0],
        base64: formValue.base64,
        url: formValue.url,
      },
      validation: ValidationValue,
    });
  };

  handleChangePublishDate = value => {
    const { form } = this.state;
    const modifyDate = CommonHelper.checkValidDate(value) ? value : currentDate;

    this.setState({
      form: { ...form, publishDate: modifyDate },
    });
  };

  handleChangeStatus = value => {
    const { form } = this.state;

    this.setState({ form: { ...form, status: value } });
  };

  handleFeedModalClose = value => {
    this.setState({ isOpenModal: value });
  };

  handleButtonCancel = () => {
    const { history } = this.props;
    history.push('/marketing/feed');
  };

  handleButtonPreview = () => {
    const { form } = this.state;
    const {
      masterData: { feedCategoryList },
    } = this.props;
    const media = [];

    const check = this.validationForm(false);

    if (check) {
      if (form.activeMedia.value !== mediaOption[1].value) {
        form.base64.forEach(item => {
          const paramMedia = { source: item };
          media.push(paramMedia);
        });
      } else {
        const paramMedia = { source: form.url };
        media.push(paramMedia);
      }

      const categoryOptionValue = _.find(feedCategoryList, ['feeds_category_id', form.categoryId]);

      const params = {
        feeds_title: form.title,
        feeds_description: form.descriptionHtml,
        feeds_deeplink: DeepLinkHelper.generateDeeplinkValue(form),
        category_id: form.categoryId,
        category_name: categoryOptionValue.name,
        media_type: form.activeMedia.value,
        media,
        publish_date: form.publishDate,
        status: form.status.value,
      };

      this.setState({ previewData: params, isOpenModal: true });
    }
  };

  handleButtonPublish = () => {
    const { form } = this.state;
    const media = [];

    const check = this.validationForm(false);

    if (check) {
      if (form.activeMedia.value !== mediaOption[1].value) {
        form.base64.forEach(item => {
          const paramMedia = { source: item };
          media.push(paramMedia);
        });
      } else {
        const paramMedia = { source: form.url };
        media.push(paramMedia);
      }

      const params = {
        feeds_title: form.title,
        feeds_description: form.descriptionHtml,
        feeds_deeplink: DeepLinkHelper.generateDeeplinkValue(form),
        category_id: form.categoryId,
        media_type: form.activeMedia.value,
        media,
        publish_date: form.publishDate,
        status: form.status.value,
      };

      if (form.feedId) {
        this.setUpdateFeed(params, form.feedId);
      } else {
        this.setState({ isLoading: true }, () => {
          this.setCreateFeed(params);
        });
      }
    }
  };

  validationForm() {
    const { form, validation } = this.state;
    let passCheck = true;
    let passCheckGroup01 = true;
    let passCheckGroup02 = true;
    let passCheckGroup03 = true;

    const paramValidation01 = CommonHelper.objectCloning(initialValidation.title);
    const paramValidation02 = CommonHelper.objectCloning(initialValidation.description);
    const paramValidation03 = CommonHelper.objectCloning(initialValidation.category);
    const paramValidation04 = CommonHelper.objectCloning(initialValidation.screen);
    const paramValidation05 = CommonHelper.objectCloning(initialValidation.identifier);
    const paramValidation06 = CommonHelper.objectCloning(initialValidation.webUrl);
    const paramValidation07 = {};

    paramValidation01.isError = !form.title;
    paramValidation01.errorMessage = form.title ? '' : 'Please enter Title';

    const messageTrim = form.description.trim();
    paramValidation02.isError = messageTrim ? '' : 'error';
    paramValidation02.errorMessage = messageTrim ? '' : 'Please enter Description';

    paramValidation03.isError = form.categoryId ? '' : 'error';
    paramValidation03.errorMessage = form.categoryId ? '' : 'Please select Category';

    if (form.deeplinkType === deeplinkOptions[1].value) {
      paramValidation04.isError = form.screen ? '' : 'error';
      paramValidation04.errorMessage = form.screen ? '' : 'Please enter Screen';
      passCheckGroup01 = !!form.screen;

      if (form.disabledIdentifier === false) {
        paramValidation05.isError = form.identifier ? '' : 'error';
        paramValidation05.errorMessage = form.identifier ? '' : 'Please enter Identifier';
        passCheckGroup01 = !!form.identifier;
      }
    } else if (form.deeplinkType === deeplinkOptions[2].value) {
      paramValidation06.isError = form.webUrl ? '' : 'error';
      paramValidation06.errorMessage = form.webUrl ? '' : 'Please enter WebUrl';
      passCheckGroup01 = !!form.webUrl;
    }

    if (form.activeMedia.value !== mediaOption[1].value) {
      paramValidation07.base64 = {
        isError: !_.isEmpty(form.base64) ? '' : 'error',
        errorMessage: !_.isEmpty(form.base64) ? '' : 'Please Upload Image',
      };
      passCheckGroup02 = !_.isEmpty(form.base64);
    } else {
      paramValidation07.url = {
        isError: !form.url,
        errorMessage: form.url ? '' : 'Please enter Url',
      };
      passCheckGroup02 = !!form.url;
    }

    passCheckGroup03 =
      CommonHelper.checkValidDate(form.publishDate) &&
      CommonHelper.compareDateAndTime(form.publishDate, currentDate, 'date');

    if (!passCheckGroup03) {
      this.processMessage('Please check your time, time must greater than now', 'warning');
    }

    if (
      !form.title ||
      !messageTrim ||
      !form.categoryId ||
      !passCheckGroup01 ||
      !passCheckGroup02 ||
      !passCheckGroup03
    ) {
      passCheck = false;
    }

    this.setState({
      validation: {
        ...validation,
        title: paramValidation01,
        description: paramValidation02,
        category: paramValidation03,
        screen: paramValidation04,
        identifier: paramValidation05,
        webUrl: paramValidation06,
        ...paramValidation07,
      },
    });
    return passCheck;
  }

  processMessage(messages, type) {
    const convertedMessage = CommonHelper.generateMessage(messages);

    const paramInformation = {
      isOpen: true,
      message: convertedMessage,
      snackbarType: type,
    };

    this.setState({
      toastInformation: paramInformation,
    });
  }

  renderModalDetail() {
    const { isOpenModal, previewData } = this.state;
    const { history } = this.props;

    const param = {
      feedsTitle: previewData.feeds_title,
      feedsDescription: previewData.feeds_description,
      feedsDeeplink: previewData.feeds_deeplink,
      categoryId: previewData.category_id,
      mediaType: previewData.media_type,
      publishDate: previewData.publish_date,
      status: previewData.status,
      categoryName: previewData.category_name,
      media: previewData.media,
    };

    const elementRender = (
      <ModalFeedDetail
        isOpen={isOpenModal}
        data={param}
        onClose={this.handleFeedModalClose}
        history={history}
        isPreview
      />
    );

    return isOpenModal ? elementRender : null;
  }

  render() {
    const { toastInformation, form, pagesTitle, isLoading, isOpenModal } = this.state;
    const {
      feedData: { fetching },
    } = this.props;
    const prevUrl = '/marketing/feed';

    return (
      <div className="container-page-feed-add scroll-container-invisible">
        <div className="container-scrolling-area">
          <Helmet title={pagesTitle.helmet} />
          <Grid container direction="column">
            <Grid item lg md className="section-page-header">
              <div className="breadcrumbs-section">
                <Breadcrumbs aria-label="breadcrumb">
                  <Link
                    className="text-12"
                    color="inherit"
                    href={prevUrl}
                    onClick={event => {
                      this.handleClick(event, prevUrl);
                    }}
                  >
                    <i className="ic-ffo-attention container-icon-prefix size-16" />
                    feed
                  </Link>
                  <label className="text-12" color="inherit">
                    {pagesTitle.breadcrumbs}
                  </label>
                </Breadcrumbs>
              </div>
            </Grid>
            <Grid item lg md className="section-page-body">
              <div className="container-card">
                <Grid container direction="column">
                  <Grid item lg md>
                    <label className="text-16 text-bold text-rolling-stone title mb-16">
                      {pagesTitle.card}
                    </label>
                  </Grid>
                  <Grid item lg md>
                    <Grid container spacing={3}>
                      <Grid item lg={6} md={6}>
                        <div className="flex-column">
                          <FormControl component="fieldset" fullWidth margin={'normal'}>
                            <LabelInput labelText={'Title'} isRequired />
                            <TextInput
                              placeHolderText="Title"
                              onChange={this.handleTextChangeFeedTitle}
                              currentValue={form.title}
                              errorMessage={this.state.validation.title.errorMessage}
                              isError={this.state.validation.title.isError}
                              size="md"
                              maxLength={50}
                            />
                          </FormControl>
                          <FormControl component="fieldset" fullWidth margin={'normal'}>
                            <LabelInput labelText={'Description'} isRequired />
                            <TextEditor
                              currentValue={form.descriptionHtml}
                              onChange={this.handleTextChangeDescription}
                              errorMessage={this.state.validation.description.errorMessage}
                              validateStatus={this.state.validation.description.isError}
                            />
                          </FormControl>
                        </div>
                      </Grid>
                      <Grid item lg={6} md={6}>
                        <div className="flex-column">
                          <FormControl component="fieldset" fullWidth margin={'normal'}>
                            <LabelInput labelText={'Category'} isRequired />
                            <SelectInputFeedCategory
                              onChange={this.handleSelectCategory}
                              currentValue={form.categoryId}
                              placeholder="Category"
                              errorMessage={this.state.validation.category.errorMessage}
                              validateStatus={this.state.validation.category.isError}
                            />
                          </FormControl>

                          <DeepLink
                            parentForm={this.state.form}
                            parentValidation={this.state.validation}
                            onParameterUpdate={this.handleParameterUpdateDeepLink}
                          />
                          <FormControl component="fieldset" fullWidth margin={'normal'}>
                            <LabelInput labelText={'Add Media'} isRequired />
                            <MediaInput
                              parentForm={this.state.form}
                              parentValidation={this.state.validation}
                              onParameterUpdate={this.handleParameterUpdateMedia}
                            />
                          </FormControl>
                          <FormControl component="fieldset" fullWidth margin={'normal'}>
                            <LabelInput labelText={'Publish Time'} />
                            <PickerInputDateAndTime
                              customIcon="ic-ffo-date-pick"
                              dateFormat="dd/MM/yyyy hh:mm"
                              minDate={currentDate}
                              defaultValue={form.publishDate}
                              onChange={this.handleChangePublishDate}
                              toolbar={false}
                            />
                          </FormControl>
                          <FormControl component="fieldset" fullWidth margin={'normal'}>
                            <LabelInput labelText={'Status'} />
                            <RadioInput
                              data={statusOption}
                              onSelect={this.handleChangeStatus}
                              direction="row"
                              currentValue={form.status}
                            />
                          </FormControl>
                        </div>
                      </Grid>
                      <Grid item lg={12} md={12}>
                        <Grid container justify="flex-end">
                          <Grid item lg={7} md={7}>
                            <Grid container justify="space-between" spacing={2}>
                              <Grid item lg={4} md={4}>
                                <ButtonMain
                                  type="negative"
                                  labelText="Cancel"
                                  onClick={this.handleButtonCancel}
                                  isLoading={isLoading}
                                />
                              </Grid>
                              <Grid item lg={4} md={4}>
                                <ButtonMain
                                  type="ghost"
                                  labelText="Preview"
                                  onClick={this.handleButtonPreview}
                                />
                              </Grid>
                              <Grid item lg={4} md={4}>
                                <ButtonMain
                                  type="primary"
                                  labelText="Publish"
                                  onClick={this.handleButtonPublish}
                                  isLoading={isLoading || fetching}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid item lg md className="section-page-footer" />
          </Grid>
          <SnackBarSimple
            open={toastInformation.isOpen}
            durationHide={2000}
            message={toastInformation.message}
            onClickClose={this.handleCloseToash}
            snackbarType={toastInformation.snackbarType}
            anchor={optionToash}
          />
        </div>
        {isOpenModal && this.renderModalDetail()}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  createFeed: params => setCreateFeed(dispatch, params),
  feedDetails: params => getFeedDetails(dispatch, params),
  updateFeed: (params, id) => setUpdateFeed(dispatch, params, id),
});

const mapStateToProps = ({ masterData, feedData }) => ({
  masterData,
  feedData,
});

FeedAdd.propTypes = {
  checkUserAccessPermission: PropTypes.func,
  createFeed: PropTypes.func,
  feedData: PropTypes.object,
  feedDetails: PropTypes.func,
  history: PropTypes.object,
  masterData: PropTypes.object,
  match: PropTypes.object,
  updateFeed: PropTypes.func,
};

const shell = compose(connect(mapStateToProps, mapDispatchToProps));
const core = compose(AuthenticationAccessPages, PrevStateValue);

export default shell(core(FeedAdd));
