export const constants = {
  // #region company
  getListCompanyPaginationRequest: 'GET_LIST_COMPANY_PAGINATION_REQUEST',
  getListCompanyPaginationSuccess: 'GET_LIST_COMPANY_PAGINATION_SUCCESS',
  getListCompanyPaginationFail: 'GET_LIST_COMPANY_PAGINATION_FAIL',
  createCompanyRequest: 'CREATE_COMPANY_REQUEST',
  createCompanySuccess: 'CREATE_COMPANY_SUCCESS',
  createCompanyFail: 'CREATE_COMPANY_FAIL',
  updateCompanyRequest: 'UPDATE_COMPANY_REQUEST',
  updateCompanySuccess: 'UPDATE_COMPANY_SUCCESS',
  updateCompanyFail: 'UPDATE_COMPANY_FAIL',
  deleteCompanyRequest: 'DELETE_COMPANY_REQUEST',
  deleteCompanySuccess: 'DELETE_COMPANY_SUCCESS',
  deleteCompanyFail: 'DELETE_COMPANY_FAIL',
  // #endregion

// #region merchant
  getListMerchantPaginationRequest: 'GET_LIST_MERCHANT_PAGINATION_REQUEST',
  getListMerchantPaginationSuccess: 'GET_LIST_MERCHANT_PAGINATION_SUCCESS',
  getListMerchantPaginationFail: 'GET_LIST_MERCHANT_PAGINATION_FAIL',
  deleteMerchantRequest: 'DELETE_MERCHANT_REQUEST',
  deleteMerchantSuccess: 'DELETE_MERCHANT_SUCCESS',
  deleteMerchantFail: 'DELETE_MERCHANT_FAIL',
  createMerchantRequest: 'CREATE_MERCHANT_REQUEST',
  createMerchantSuccess: 'CREATE_MERCHANT_SUCCESS',
  createMerchantFail: 'CREATE_MERCHANT_FAIL',
  updateMerchantRequest: 'UPDATE_MERCHANT_REQUEST',
  updateMerchantSuccess: 'UPDATE_MERCHANT_SUCCESS',
  updateMerchantFail: 'UPDATE_MERCHANT_FAIL',
  getMerchantDetailsRequest: 'GET_MERCHANT_DETAILS_REQUEST',
  getMerchantDetailsSuccess: 'GET_MERCHANT_DETAILS_SUCCESS',
  getMerchantDetailsFail: 'GET_MERCHANT_DETAILS_FAIL',
  // #endregion

  // #region branch
  createBranchRequest: 'CREATE_BRANCH_REQUEST',
  createBranchSuccess: 'CREATE_BRANCH_SUCCESS',
  createBranchFail: 'CREATE_BRANCH_FAIL',
  updateBranchRequest: 'UPDATE_BRANCH_REQUEST',
  updateBranchSuccess: 'UPDATE_BRANCH_SUCCESS',
  updateBranchFail: 'UPDATE_BRANCH_FAIL',
  getListBranchPaginationRequest: 'GET_LIST_BRANCH_PAGINATION_REQUEST',
  getListBranchPaginationSuccess: 'GET_LIST_BRANCH_PAGINATION_SUCCESS',
  getListBranchPaginationFail: 'GET_LIST_BRANCH_PAGINATION_FAIL',
  deleteBranchRequest: 'DELETE_BRANCH_REQUEST',
  deleteBranchSuccess: 'DELETE_BRANCH_SUCCESS',
  deleteBranchFail: 'DELETE_BRANCH_FAIL',
  getBranchDetailsRequest: 'GET_BRANCH_DETAILS_REQUEST',
  getBranchDetailsSuccess: 'GET_BRANCH_DETAILS_SUCCESS',
  getBranchDetailsFail: 'GET_BRANCH_DETAILS_FAIL',
  // #endregion

  // #region product category
  createProductCategoryRequest: 'CREATE_PRODUCT_CATEGORY_REQUEST',
  createProductCategorySuccess: 'CREATE_PRODUCT_CATEGORY_SUCCESS',
  createProductCategoryFail: 'CREATE_PRODUCT_CATEGORY_FAIL',
  updateProductCategoryRequest: 'UPDATE_PRODUCT_CATEGORY_REQUEST',
  updateProductCategorySuccess: 'UPDATE_PRODUCT_CATEGORY_SUCCESS',
  updateProductCategoryFail: 'UPDATE_PRODUCT_CATEGORY_FAIL',
  getProductCategoryDetailsRequest: 'GET_PRODUCT_CATEGORY_DETAILS_REQUEST',
  getProductCategoryDetailsSuccess: 'GET_PRODUCT_CATEGORY_DETAILS_SUCCESS',
  getProductCategoryDetailsFail: 'GET_PRODUCT_CATEGORY_DETAILS_FAIL',
  // #endregion

  // #region product category
  getProductWholesaleRequest: 'GET_PRODUCT_WHOLESALE_REQUEST',
  getProductWholesaleSuccess: 'GET_PRODUCT_WHOLESALE_SUCCESS',
  getProductWholesaleFail: 'GET_PRODUCT_WHOLESALE_FAIL',
  // #endregion
};

const initState = {
  fetching: false,
  list: [],
  total: 0,
  errorMessage: null,
  responseMessage: null,
  details: {},
  productWholesale: {
    products: [],
    total: 0
  }
};

export default function masterDataMain(state = initState, action) {
  const { type, payload } = action;
  switch (type) {
    // #region company
    case constants.createCompanyRequest:
      return { ...state, fetching: true, responseMessage: null };
    case constants.createCompanySuccess:
      return {
        ...state,
        responseMessage: payload.messages,
        fetching: false,
      };
    case constants.createCompanyFail:
      return { ...state, fetching: false, errorMessage: payload };

    case constants.updateCompanyRequest:
      return { ...state, fetching: true, responseMessage: null };
    case constants.updateCompanySuccess:
      return {
        ...state,
        responseMessage: payload.messages,
        fetching: false,
      };
    case constants.updateCompanyFail:
      return { ...state, fetching: false, errorMessage: payload };

    case constants.deleteCompanyRequest:
      return { ...state, fetching: true, responseMessage: null };
    case constants.deleteCompanySuccess:
      return {
        ...state,
        responseMessage: payload.messages,
        fetching: false,
      };
    case constants.deleteCompanyFail:
      return { ...state, fetching: false, errorMessage: payload };

    case constants.getListCompanyPaginationRequest:
      return { ...state, fetching: true, responseMessage: null };
    case constants.getListCompanyPaginationSuccess:
      return {
        ...state,
        list: payload.data,
        total: payload.total,
        fetching: false,
      };
    case constants.getListCompanyPaginationFail:
      return { ...state, fetching: false, errorMessage: payload };
    // #endregion

    // #region merchant
    case constants.deleteMerchantRequest:
      return { ...state, fetching: true, responseMessage: null };
    case constants.deleteMerchantSuccess:
      return {
        ...state,
        responseMessage: payload.messages,
        fetching: false,
      };
    case constants.deleteMerchantFail:
      return { ...state, fetching: false, errorMessage: payload };

    case constants.getListMerchantPaginationRequest:
      return { ...state, fetching: true, responseMessage: null };
    case constants.getListMerchantPaginationSuccess:
      return {
        ...state,
        list: payload.data,
        total: payload.total,
        fetching: false,
      };
    case constants.getListMerchantPaginationFail:
      return { ...state, fetching: false, errorMessage: payload };

    case constants.createMerchantRequest:
      return { ...state, fetching: true, responseMessage: null };
    case constants.createMerchantSuccess:
      return {
        ...state,
        responseMessage: payload.messages,
        fetching: false,
      };
    case constants.createMerchantFail:
      return { ...state, fetching: false, errorMessage: payload };

    case constants.updateMerchantRequest:
      return { ...state, fetching: true, responseMessage: null };
    case constants.updateMerchantSuccess:
      return {
        ...state,
        responseMessage: payload.messages,
        fetching: false,
      };
    case constants.updateMerchantFail:
      return { ...state, fetching: false, errorMessage: payload };

    case constants.getMerchantDetailsRequest:
      return { ...state, fetching: true, responseMessage: null };
    case constants.getMerchantDetailsSuccess:
      return {
        ...state,
        details: payload.data,
        fetching: false,
      };
    case constants.getMerchantDetailsFail:
      return { ...state, fetching: false, errorMessage: payload };
    // #endregion

    // #region branch    
    case constants.createBranchRequest:
      return { ...state, fetching: true, responseMessage: null };
    case constants.createBranchSuccess:
      return {
        ...state,
        responseMessage: payload.messages,
        fetching: false,
      };
    case constants.createBranchFail:
      return { ...state, fetching: false, errorMessage: payload };
          
    case constants.deleteBranchRequest:
      return { ...state, fetching: true, responseMessage: null };
    case constants.deleteBranchSuccess:
      return {
        ...state,
        responseMessage: payload.messages,
        fetching: false,
      };
    case constants.deleteBranchFail:
      return { ...state, fetching: false, errorMessage: payload };

    case constants.updateBranchRequest:
      return { ...state, fetching: true, responseMessage: null };
    case constants.updateBranchSuccess:
      return {
        ...state,
        responseMessage: payload.messages,
        fetching: false,
      };
    case constants.updateBranchFail:
        return { ...state, fetching: false, errorMessage: payload };
        
    case constants.getListBranchPaginationRequest:
      return { ...state, fetching: true, responseMessage: null };
    case constants.getListBranchPaginationSuccess:
      return {
        ...state,
        list: payload.data,
        total: payload.total,
        fetching: false,
      };
    case constants.getListBranchPaginationFail:
      return { ...state, fetching: false, errorMessage: payload };
      
    case constants.getBranchDetailsRequest:
      return { ...state, fetching: true, responseMessage: null };
    case constants.getBranchDetailsSuccess:
      return {
        ...state,
        details: payload.data,
        fetching: false,
      };
    case constants.getBranchDetailsFail:
      return { ...state, fetching: false, errorMessage: payload };
    // #endregion
    
    // #region product category    
    case constants.createProductCategoryRequest:
      return { ...state, fetching: true, responseMessage: null };
    case constants.createProductCategorySuccess:
      return {
        ...state,
        responseMessage: payload.messages,
        fetching: false,
      };
    case constants.createProductCategoryFail:
      return { ...state, fetching: false, errorMessage: payload };
    case constants.updateProductCategoryRequest:
      return { ...state, fetching: true, responseMessage: null };
    case constants.updateProductCategorySuccess:
      return {
        ...state,
        responseMessage: payload.messages,
        fetching: false,
      };
    case constants.updateProductCategoryFail:
        return { ...state, fetching: false, errorMessage: payload };
    case constants.getProductCategoryDetailsRequest:
      return { ...state, fetching: true, responseMessage: null };
    case constants.getProductCategoryDetailsSuccess:
      return {
        ...state,
        details: payload.data,
        fetching: false,
      };
    case constants.getProductCategoryDetailsFail:
      return { ...state, fetching: false, errorMessage: payload };
    // #endregion

    // #region product wholesale 
    case constants.getProductWholesaleRequest:
      return { ...state, fetching: true, responseMessage: null };
    case constants.getProductWholesaleSuccess:
      return { ...state, fetching: false, productWholesale: payload.data };
    case constants.getProductWholesaleFail:
      return { ...state, fetching: false, errorMessage: payload };
    // #endregion

    default:
      return state;
  }
}
