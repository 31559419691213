/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import { Grid, IconButton } from '@material-ui/core';
import { connect } from 'react-redux';
import _ from 'lodash';
// api
import {
  getScheduleByIdDetails,
  setScheduleByIdDetailsTrainerEms,
  setScheduleDetailStatus,
  setProcessCancelOrderBooking,
  setProcessCompleteOrderBookingClass,
} from '../../../../../../services/api/ScheduleDataApi';
// component
import {
  ModalInformationPopUp,
  ButtonMain,
  ModalAlertConfirmation,
  SelectInputMain,
  SnackBarSimple,
  ModalAlertInformation,
  ButtonIconMain,
  SelectInputCoachByProduct,
} from '../../../../../../components/Index';
import { SkeletonScheduleDetails } from '../Index';
// helper
import {
  CalendarHelper,
  CommonHelper,
  MasterDataHelper,
  UserStatus,
  HttpStatusCode,
  orderBookActionCode,
  ScheduleStatus,
} from '../../../../../../helpers/Index';

const initialParticipant = {
  clientName: null,
  trainer: null,
  trainingScheduleId: null,
  bookingDate: null,
};
const initialDetail = {
  scheduleId: null,
  scheduleName: null,
  dateStart: null,
  dateEnd: null,
  duration: null,
  slot: null,
  coachName: null,
  appointmentDate: null,
  locationActivity: null,
  participant: [initialParticipant],
};

const initialFilter = {
  name: null,
  productId: null,
  trainerId: null,
  merchantId: null,
  timeRangeValue: null,
  dayValue: null,
};

const modalAlertOption = {
  title: 'Confirmation',
  text: 'Are Your Sure want to DELETE this Schedule',
  icon: 'warning',
  successMessage: 'Deleted Schedule Success',
};

const modalAlertOptionInformation = {
  title: 'Information',
  text: `You can't Perform this Action, Please Cancel all Appointment Thant Already Made, before changing schedule status`,
};

const optionToash = {
  vertical: 'top',
  horizontal: 'right',
};

const dataStatus = MasterDataHelper.statusGlobal.slice(0, MasterDataHelper.statusGlobal.length - 1);
const today = CommonHelper.currentDate('YYYY-MM-DD HH:mm:ss');
const currentDate = CommonHelper.dateTimeParseNewFormat(today, 'YYYY-MM-DD');
const currentTime = CommonHelper.dateTimeParseNewFormat(today, 'HH:mm:ss');

class ModalScheduleDetailEms extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      openModal: false,
      detailSchedule: initialDetail,
      isOpenModalInformation: false,
      isLoading: false,
      isEdit: false,
      filter: initialFilter,
      toastInformation: {
        isOpen: false,
        message: '',
        snackbarType: 'warning',
      },
    };
  }

  componentDidMount() {
    const { isOpen, scheduleId } = this.props;

    this.setState({ openModal: isOpen });
    if (scheduleId) {
      this.getScheduleByIdDetails(scheduleId);
    }
  }

  componentWillUnmount() {}

  getScheduleByIdDetails(selectedId) {
    const { scheduleById } = this.props;
    const param = {
      training_schedule_id: selectedId,
    };

    this.setState({ isLoading: true }, () => {
      scheduleById(param)
        .then(async response => {
          const data = response;
          const converted = CalendarHelper.generateCalenderModalDetails(data);
          const updatedFilter = initialFilter;
          updatedFilter.merchantId = data.merchant_id;
          updatedFilter.branchId = data.branch_id;
          updatedFilter.productId = data.product_id;

          this.setState({
            detailSchedule: converted,
            isLoading: false,
            filter: updatedFilter,
          });
        })
        .catch(async error => {
          const message = error.data;
          this.processMessage(message.messages, 'error');
        });
    });
  }

  setScheduleByIdDetailsTrainer(trainerIdArray) {
    const { scheduleByIdSetTrainer, scheduleId } = this.props;

    const param = {
      data: {
        appointments: trainerIdArray,
      },
    };

    this.setState({ isLoading: true }, () => {
      scheduleByIdSetTrainer(param)
        .then(async response => {
          const message = response.messages;
          await this.processMessage(message, 'success');
          await this.getScheduleByIdDetails(scheduleId);
        })
        .catch(async error => {
          const message = error.data;
          this.setState(
            {
              isLoading: false,
            },
            () => {
              this.processMessage(message.messages, 'error');
            },
          );
        });
    });
  }

  setScheduleDetailStatus(param) {
    const { scheduleDetailStatus, scheduleId } = this.props;

    scheduleDetailStatus(param)
      .then(async response => {
        const message = response.messages;
        await this.processMessage(message, 'success');
        await this.getScheduleByIdDetails(scheduleId);
      })
      .catch(async error => {
        const message = error.data;
        this.processMessage(message.messages, 'error');
      });
  }

  handleClose = () => {
    const { onClose } = this.props;
    onClose(false);
    this.setState({ openModal: false });
  };

  handleCloseModalInformation = () => {
    this.setState({ isOpenModalInformation: false });
  };

  handleChangeStatus = async value => {
    const { scheduleId } = this.props;
    const { detailSchedule } = this.state;

    if (detailSchedule.participant.length === 0) {
      const param = { data: { training_schedule_id: scheduleId, status: value } };

      await this.setScheduleDetailStatus(param);
      await this.getScheduleByIdDetails(scheduleId);
    } else {
      this.setState({ isOpenModalInformation: true });
    }
  };

  handleButtonSetTrainerClick = () => {
    const { isEdit, detailSchedule } = this.state;

    if (detailSchedule.participant.length > 0) {
      this.setState({ ...isEdit, isEdit: !isEdit });
    }
  };

  handleButtonDelete = async value => {
    const { scheduleId } = this.props;
    const { detailSchedule } = this.state;

    if (detailSchedule.participant.length === 0) {
      if (value) {
        const param = { data: { training_schedule_id: scheduleId, status: UserStatus.Deleted } };
        await this.setScheduleDetailStatus(param);
        await setTimeout(() => {
          this.handleClose();
        }, 1500);
      }
    } else {
      this.setState({ isOpenModalInformation: true });
    }
  };

  handleSelectCoach = indexParticipant => value => {
    const { masterData } = this.props;
    const { detailSchedule } = this.state;
    const tmpParticipant = detailSchedule;
    const tmpListCoach = masterData.coachesByProduct;

    const indexCheck = tmpListCoach.findIndex(item => item.trainer_id === value);

    if (indexCheck !== -1) {
      const selectedItem = tmpListCoach[indexCheck];
      const param = { trainerId: selectedItem.trainer_id, name: selectedItem.name };

      tmpParticipant.participant[parseInt(indexParticipant, 10)].trainer = param;

      this.setState({
        detailSchedule: tmpParticipant,
      });
    }
  };

  handleButtonSaveTrainerClick = async () => {
    const { isEdit, detailSchedule } = this.state;
    const allParticipant = detailSchedule.participant;
    const converted = [];
    let flagPassNotNull = false;

    allParticipant.forEach(item => {
      let trainerId = null;
      if (item.trainer) {
        flagPassNotNull = true;
        trainerId = item.trainer.trainerId;
      }

      const param = {
        appointment_id: item.appointmentId,
        trainer_id: trainerId,
      };
      converted.push(param);
    });

    if (flagPassNotNull) {
      await this.setScheduleByIdDetailsTrainer(converted);
      this.setState({ ...isEdit, isEdit: !isEdit });
    } else {
      const message = 'Trainer must be Assign at Last one';
      this.processMessage(message, 'error');
    }
  };

  handleClickButtonAction = (value, appointmentId) => {
    const { processCancelOrderBooking, processCompleteOrderBooking, scheduleId } = this.props;
    const { filter } = this.state;
    const param = {
      data: {
        appointment_id: appointmentId,
      },
    };

    switch (value) {
      case orderBookActionCode.Cancel:
        processCancelOrderBooking(param)
          .then(response => {
            const message = response.messages;
            this.processMessage(message, 'success');
            this.getScheduleByIdDetails(scheduleId);
          })
          .catch(async error => {
            const serverMessage = error.data;
            const validationStatus = error.status === HttpStatusCode.InternalServerError;
            this.processMessage(
              validationStatus ? serverMessage.message : serverMessage.messages,
              'error',
            );
          });
        break;
      case orderBookActionCode.Complete:
        processCompleteOrderBooking(filter.branchId, param)
          .then(response => {
            const message = response.messages;
            this.processMessage(message, 'success');
            this.getScheduleByIdDetails(scheduleId);
          })
          .catch(async error => {
            const serverMessage = error.data;
            const validationStatus = error.status === HttpStatusCode.InternalServerError;
            this.processMessage(
              validationStatus ? serverMessage.message : serverMessage.messages,
              'error',
            );
          });
        break;
      default:
        break;
    }
  };

  handleCloseToash = () => {
    const { toastInformation } = this.state;
    this.setState({ toastInformation: { ...toastInformation, isOpen: false } });
  };

  processMessage(messages, type) {
    const convertedMessage = CommonHelper.generateMessage(messages);

    const paramInformation = {
      isOpen: true,
      message: convertedMessage,
      snackbarType: type,
    };

    this.setState({
      toastInformation: paramInformation,
    });
  }

  renderSetTrainer(passDisplay) {
    const { isEdit, detailSchedule } = this.state;

    let existingCoach = false;
    detailSchedule.participant.forEach(item => {
      if (item.trainerId) existingCoach = true;
      else existingCoach = false;
    });

    const allowEdit = CalendarHelper.checkGracePeriodEditCoachSchedule(
      detailSchedule.date,
      currentDate,
      passDisplay,
      existingCoach,
    );

    return allowEdit && !_.isEmpty(detailSchedule.participant) ? (
      !isEdit ? (
        <ButtonMain
          labelText="Set Trainer"
          onClick={this.handleButtonSetTrainerClick}
          type="ghost"
          size="md"
        />
      ) : (
        <ButtonMain
          labelText="Save"
          onClick={this.handleButtonSaveTrainerClick}
          type="primary"
          size="md"
        />
      )
    ) : null;
  }

  renderParticipant(passDisplay) {
    const { detailSchedule, filter } = this.state;
    const { onClickReschedule } = this.props;

    const coachFilter = {
      productId: filter.productId,
      branchId: filter.branchId,
    };

    return detailSchedule.participant ? (
      <Grid container direction="column" className="container-table flex-wrap-unset">
        <Grid item lg md>
          <Grid container className="pr-15">
            <Grid item md={6}>
              <label className="text-12 title">Participant</label>
            </Grid>
            <Grid item md={3}>
              <label className="text-12 title">Coach</label>
            </Grid>
            <Grid item md={3} className="text-center">
              <label className="text-12 title">Action</label>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg>
          <div className="divider-hr" />
        </Grid>
        <div className="scroll-container container-table-body">
          <Grid item lg md>
            {detailSchedule.participant.map((item, index) => {
              const participantTrainer = item.trainer ? item.trainer.trainerId : false;

              const allowEdit = CalendarHelper.checkGracePeriodEditCoachSchedule(
                detailSchedule.date,
                currentDate,
                passDisplay,
                !!participantTrainer,
              );

              return (
                <ParticipantList
                  allowEdit={allowEdit}
                  key={index}
                  item={item}
                  index={index}
                  coachFilter={coachFilter}
                  handleSelectCoach={this.handleSelectCoach(index)}
                  onHandleSaveCoach={this.handleButtonSaveTrainerClick}
                  onHandleDelete={appointmentId =>
                    this.handleClickButtonAction(orderBookActionCode.Cancel, appointmentId)
                  }
                  onHandleComplete={appointmentId =>
                    this.handleClickButtonAction(orderBookActionCode.Complete, appointmentId)
                  }
                  onHandleReschedule={() => {
                    onClickReschedule({
                      ...item,
                      productId: coachFilter.productId,
                      branchId: coachFilter.branchId,
                      location: detailSchedule.locationActivity,
                      dateStart: detailSchedule.dateStart,
                      dateEnd: detailSchedule.dateEnd,
                      scheduleName: detailSchedule.scheduleName,
                      appointmentDate: detailSchedule.appointmentDate,
                    });
                  }}
                />
              );
            })}
            {detailSchedule.participant.length === 0 && (
              <Grid>
                <label className="text-14">No bookings yet</label>
              </Grid>
            )}
          </Grid>
        </div>
      </Grid>
    ) : null;
  }

  renderTimeSlot(passDisplay) {
    const { detailSchedule } = this.state;
    const activeStyle = !passDisplay
      ? detailSchedule.isAvailable
        ? ''
        : 'apricot'
      : 'rolling-stone';

    return (
      <Grid container className="container-time-and-slot-coach">
        <Grid item lg md>
          <div className="flex-column">
            <label className="text-12 title text-rolling-stone">Time Schedule</label>
            <label className="text-13">{`${detailSchedule.dateStart} - ${detailSchedule.dateEnd}  `}</label>
          </div>
        </Grid>
        <Grid item lg md>
          <div className="flex-column">
            <label className="text-12 title text-rolling-stone">Total Slots</label>
            <label className="text-13">{detailSchedule.capacity} Slots</label>
          </div>
        </Grid>
        <Grid item lg md>
          <div className="flex-column">
            <label className="text-12 title text-rolling-stone">Available Slots</label>
            <label className={`text-13 text-${activeStyle}`}>
              {detailSchedule.availableSlot} Slot
            </label>
          </div>
        </Grid>
      </Grid>
    );
  }

  render() {
    const {
      openModal,
      detailSchedule,
      isLoading,
      toastInformation,
      isOpenModalInformation,
      isEdit,
    } = this.state;
    const { onClickBook } = this.props;

    let renderElement = <SkeletonScheduleDetails />;

    const passDisplay = !CalendarHelper.validationPassSchedule(
      currentDate,
      currentTime,
      detailSchedule.date,
      detailSchedule.dateStart,
      // detailSchedule.isAvailable, will be use only in booking
    );

    const isStatusDisable =
      detailSchedule.status !== ScheduleStatus.Active &&
      detailSchedule.status !== ScheduleStatus.waiting;

    const validateButtonBookDisable = !detailSchedule.isAvailable || isEdit;

    if (!isLoading) {
      renderElement = (
        <Grid container direction="column" className="flex-wrap-unset">
          <Grid item lg md className="section-header-modal">
            <Grid container>
              <Grid item lg={11} md={11}>
                <h5 className="wrapping-container second line">{detailSchedule.scheduleName}</h5>
              </Grid>
              <Grid item>
                <Grid container direction="column" justify="flex-end">
                  <Grid item>
                    <div className="container-button-close">
                      <IconButton onClick={this.handleClose}>
                        <i className="ic-ffo-close container-icon-prefix size-21" />
                      </IconButton>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg md className="section-body-modal">
            <Grid container direction="column" className="flex-wrap-unset">
              {!passDisplay ? (
                <Grid item lg md className="container-detail-status">
                  <div className="flex-row">
                    <label className="text-12">Status</label>
                    <div>
                      <SelectInputMain
                        options={dataStatus}
                        size="small"
                        currentValue={detailSchedule.status}
                        onChange={this.handleChangeStatus}
                      />
                    </div>
                  </div>
                </Grid>
              ) : null}
              <Grid item lg md>
                {this.renderTimeSlot(passDisplay)}
              </Grid>
              <Grid item lg md className="row-modal">
                <Grid container className="flex-wrap-unset">
                  <Grid item lg md>
                    <div className="flex-column">
                      <label className="text-12 title text-rolling-stone">Appointment Date</label>
                      <label className="text-13">{detailSchedule.appointmentDate}</label>
                    </div>
                  </Grid>
                  <Grid item lg md>
                    <div className="flex-column">
                      <label className="text-12 title text-rolling-stone">Location</label>
                      <label className="text-13">{detailSchedule.locationActivity}</label>
                    </div>
                  </Grid>
                  <Grid item lg md />
                </Grid>
              </Grid>

              <Grid item lg md className="row-modal">
                {this.renderParticipant(passDisplay)}
              </Grid>
            </Grid>
          </Grid>
          <Grid item className="section-footer-modal">
            {!passDisplay && !isStatusDisable && (
              <Grid container justify="flex-end" spacing={2}>
                <Grid item lg={3} md={3}>
                  <ModalAlertConfirmation
                    label="Delete"
                    onClick={this.handleButtonDelete}
                    type="danger"
                    size="xl"
                    optionModal={modalAlertOption}
                    disabled={isEdit}
                  />
                </Grid>
                <Grid item lg={3} md={3}>
                  <ButtonMain
                    labelText="Book"
                    onClick={onClickBook}
                    type="primary"
                    size="xl"
                    disabled={validateButtonBookDisable}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
          <SnackBarSimple
            open={toastInformation.isOpen}
            durationHide={2000}
            message={toastInformation.message}
            onClickClose={this.handleCloseToash}
            snackbarType={toastInformation.snackbarType}
            anchor={optionToash}
          />
          <ModalAlertInformation
            optionModal={modalAlertOptionInformation}
            isOpen={isOpenModalInformation}
            onCloseModal={this.handleCloseModalInformation}
            typeModal={'information'}
          />
        </Grid>
      );
    }

    return (
      <ModalInformationPopUp
        isOpen={openModal}
        onClose={this.handleClose}
        customElementProps={renderElement}
      />
    );
  }
}

const mapDispatchToProps = dispatch => ({
  scheduleById: params => getScheduleByIdDetails(dispatch, params),
  scheduleByIdSetTrainer: params => setScheduleByIdDetailsTrainerEms(dispatch, params),
  scheduleDetailStatus: params => setScheduleDetailStatus(dispatch, params),
  processCancelOrderBooking: params => setProcessCancelOrderBooking(dispatch, params),
  processCompleteOrderBooking: (branchID, params) =>
    setProcessCompleteOrderBookingClass(dispatch, branchID, params),
});

const mapStateToProps = ({ masterData }) => ({
  masterData,
});

ModalScheduleDetailEms.propTypes = {
  isOpen: PropTypes.bool,
  masterData: PropTypes.object,
  onClickBook: PropTypes.func,
  onClickReschedule: PropTypes.func,
  onClose: PropTypes.func,
  processCancelOrderBooking: PropTypes.func,
  processCompleteOrderBooking: PropTypes.func,
  scheduleById: PropTypes.func,
  scheduleByIdSetTrainer: PropTypes.func,
  scheduleDetailStatus: PropTypes.func,
  scheduleId: PropTypes.number,
};

const ParticipantList = ({
  allowEdit,
  item,
  index,
  coachFilter,
  handleSelectCoach,
  onHandleDelete,
  onHandleComplete,
  onHandleReschedule,
  onHandleSaveCoach,
}) => {
  const [editCoach, setEditCoach] = React.useState(false);
  const handleOnChange = value => handleSelectCoach(value);
  return (
    <Grid container direction="column" className="table-row-modal">
      <Grid item lg md>
        <Grid container justify="space-between" alignItems="center">
          <Grid item md={6}>
            <label className="text-14">{item.clientName}</label>
            <span className="flex-row align-center">
              <i className="ic-ffo-phone container-icon-prefix size-14 mr-6" />
              <label className="text-10 text-regular text-mine-shaft">{item.clientPhone}</label>
            </span>
          </Grid>
          <Grid item md={3} alignItems="center">
            {!editCoach || !allowEdit ? (
              <label className="text-12 text-rolling-stone">
                {item.trainer ? item.trainer.name : '-'}
              </label>
            ) : (
              <SelectInputCoachByProduct
                placeHolder="Select Coach"
                onChange={handleOnChange}
                currentValue={item.trainer.trainerId}
                selectedItem={index.toString()}
                filter={coachFilter}
              />
            )}
          </Grid>
          <Grid item md={3} alignItems="center">
            {!editCoach || !allowEdit ? (
              <Grid container justify="center">
                <Grid item md={3}>
                  <ButtonIconMain
                    icon="ic-ffo-edit"
                    type="secondary"
                    size="sm"
                    onClick={() => {
                      setEditCoach(oldValue => !oldValue);
                    }}
                    tooltip="Set Trainer"
                  />
                </Grid>
                <Grid item md={3}>
                  <ButtonIconMain
                    icon="ic-ffo-check-mark"
                    type="complete"
                    size="sm"
                    onClick={() => {
                      onHandleComplete(item.appointmentId);
                    }}
                    tooltip="Complete Appointment"
                  />
                </Grid>
                <Grid item md={3}>
                  <ButtonIconMain
                    icon="ic-ffo-calendar"
                    type="primary"
                    size="sm"
                    onClick={() => {
                      onHandleReschedule();
                    }}
                    tooltip="Reschedule Appointment"
                  />
                </Grid>
                <Grid item md={3}>
                  <ButtonIconMain
                    icon="ic-ffo-bin"
                    type="negative"
                    size="sm"
                    onClick={() => {
                      onHandleDelete(item.appointmentId);
                    }}
                    tooltip="Delete Appointment"
                  />
                </Grid>
              </Grid>
            ) : (
              allowEdit && (
                <Grid container justify="center">
                  <Grid item lg={4} md={4}>
                    <ButtonMain
                      labelText="Save"
                      onClick={() => {
                        setEditCoach(oldValue => !oldValue);
                        onHandleSaveCoach();
                      }}
                      type="primary"
                      size="md"
                    />
                  </Grid>
                </Grid>
              )
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg md>
        <div className="divider-hr" />
      </Grid>
    </Grid>
  );
};

ParticipantList.propTypes = {
  allowEdit: PropTypes.bool,
  coachFilter: PropTypes.object,
  handleSelectCoach: PropTypes.func,
  index: PropTypes.number,
  item: PropTypes.object,
  onHandleComplete: PropTypes.func,
  onHandleDelete: PropTypes.func,
  onHandleReschedule: PropTypes.func,
  onHandleSaveCoach: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalScheduleDetailEms);
