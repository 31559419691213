import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Grid, Breadcrumbs, Link } from '@material-ui/core';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import _ from 'lodash';
// style
import './ScheduleBookStyle.scss';
// component
import {
  NavigationStep,
  SnackBarSimple,
  AuthenticationAccessPages,
} from '../../../../components/Index';
import {
  StepBookingUserDetails,
  StepBookingProductDetails,
  StepBookingSummaryAndPayment,
} from './views/Index';
// helper
import {
  CommonHelper,
  HttpStatusCode,
  PermissionModule,
  PermissionPage,
  PermissionAccess,
  MasterDataHelper,
  productTypeForOrderCode,
  paymentMethodOptionCode,
  OrderHelper,
  serviceLocationCode,
} from '../../../../helpers/Index';
// api
import { setProcessCreateOrderBooking } from '../../../../services/api/ScheduleDataApi';

const addressTypeValue = MasterDataHelper.addressTypeOrder;

const optionToast = {
  vertical: 'top',
  horizontal: 'right',
};

class ScheduleBook extends React.Component {
  constructor(props) {
    super(props);

    const {
      match: { params },
    } = this.props;

    props.checkUserAccessPermission(
      PermissionModule.Orders,
      PermissionPage.List,
      PermissionAccess.View,
    );

    const updateForm = {
      ...CommonHelper.decryptObject(params.param_object),
      orderType: productTypeForOrderCode.move,
    };

    this.state = {
      current: 0,
      form: updateForm,
      validation: {},
      stepList: [
        { key: 'user_detail', name: 'User Details' },
        { key: 'booking_detail', name: 'Booking Details' },
        { key: 'payment_detail', name: 'Payment Details' },
      ],
      toastInformation: {
        isOpen: false,
        message: '',
        snackbarType: 'warning',
      },
      checked: false,
      isShowAgreement: false,
    };
  }

  componentDidMount() {}

  async componentDidUpdate() {
    const {
      membership: { fetching, errorMessage },
      history,
    } = this.props;

    if (!fetching && errorMessage && errorMessage.code === HttpStatusCode.Forbidden) {
      history.push('/membership/purchase');
    }
  }

  componentWillUnmount() {}

  handleClick = (event, value) => {
    const { history } = this.props;
    event.preventDefault();
    history.push(value);
  };

  handleButtonClickCancel = () => {
    const { history } = this.props;
    history.push('/move/workout-schedule');
  };

  handleButtonClickNext = value => {
    let checkPass = false;

    switch (value - 1) {
      case 0:
        checkPass = this.validationStepUserDetails();
        break;
      case 1:
        checkPass = true;
        break;
      default:
        break;
    }

    if (checkPass) {
      this.setState({ current: value });
    }
  };

  handleButtonClickPrev = value => {
    this.setState({ current: value });
  };

  handleParameterUpdateStepUserDetails = (formValue, ValidationValue) => {
    const { form, validation } = this.state;
    this.setState({
      form: {
        ...form,
        isExisting: formValue.isExisting,
        userId: formValue.userId,
        phone: formValue.phone,
        firstName: formValue.firstName,
        lastName: formValue.lastName,
        email: formValue.email,
        gender: formValue.gender,
        addressType: formValue.addressType,
        addressId: formValue.addressId,
        address: formValue.address,
        placeId: formValue.placeId,
        addressInfo: formValue.addressInfo,
        note: formValue.note,
      },
      validation: {
        ...validation,
        ...ValidationValue,
      },
    });
  };

  handleParameterUpdateStepMembershipDetails = (formValue, ValidationValue) => {
    const { form, validation } = this.state;
    this.setState({
      form: {
        ...form,
        typeActionBooster: formValue.typeActionBooster,
        merchantId: formValue.merchantId,
        branchId: formValue.branchId,
        membershipId: formValue.membershipId,
        startDate: formValue.startDate,
        selectedMembershipDetails: formValue.selectedMembershipDetails,
        items: formValue.items,
      },
      validation: { ...validation, email: ValidationValue.email },
    });
  };

  handleParameterUpdateStepBookingDetails = (formValue, key) => {
    const { form } = this.state;
    this.setState({
      form: {
        ...form,
        [key]: formValue,
      },
    });
  };

  handleParameterUpdateStepBookingSummary = (
    formValue,
    validationValue,
    checked,
    isShowAgreement,
  ) => {
    const { form, validation } = this.state;
    this.setState({
      form: {
        ...form,
        paymentMethod: formValue.paymentMethod,
        bank: formValue.bank,
        membershipInfo: formValue.membershipInfo,
        promoCode: formValue.promoCode,
      },
      validation: {
        ...validation,
        promoCode: validationValue.promoCode,
      },
      checked,
      isShowAgreement,
    });
  };

  handleButtonClickSubmit = () => {
    const { processCreateOrderBooking, history } = this.props;
    const {
      form,
      validation: { promoCode },
      checked,
      isShowAgreement,
    } = this.state;
    let userDetails = {};

    if (!checked && isShowAgreement) {
      this.processMessage('Please read our Term & Conditions', 'error');
      return;
    }

    if (!promoCode.isError) {
      const modifyPayment = {
        payment_type: form.paymentMethod, // in back payment method reccornize as payment type
      };

      if (
        !_.isEmpty(form.membershipInfo) &&
        form.paymentMethod === paymentMethodOptionCode.membership
      ) {
        modifyPayment.user_point_booster_id =
          form.membershipInfo.membership_details.user_point_booster_id;
      } else if (form.paymentMethod === paymentMethodOptionCode.bank_transfer) {
        modifyPayment.bank = form.bank.value || '';
      }

      if (form.isExisting) {
        userDetails = {
          user_details: {
            user_id: form.userId || '',
          },
        };
      } else {
        userDetails = {
          user_details: {
            user_id: null,
            first_name: form.firstName,
            last_name: form.lastName,
            phone: form.phone,
            email: form.email,
            gender: form.gender.value,
          },
        };
      }

      if (form.step_booking_product_details.serviceLocation !== serviceLocationCode.InStore) {
        let coordinate = form.step_booking_product_details.addressInfo.coordinate;
        if (!coordinate) {
          coordinate = `${form.step_booking_product_details.addressInfo.latitude},${form.step_booking_product_details.addressInfo.longitude}`;
        }
        userDetails.user_details.address = form.step_booking_product_details.addressInfo.address;
        userDetails.user_details.notes =
          form.step_booking_product_details.addressInfo.notes ||
          form.step_booking_product_details.note;
        userDetails.user_details.coordinate = coordinate;
        userDetails.user_details.user_address_id =
          form.step_booking_product_details.addressInfo.user_address_id || null;
      }

      const items = form.step_booking_product_details.selectedProduct;

      const param = {
        data: {
          order_type: form.orderType,
          user_details: userDetails.user_details,
          items: OrderHelper.formatItemSubmit(items, form.membershipInfo),
          payment_data: modifyPayment,
          promo_code: form.promoCode,
        },
      };

      processCreateOrderBooking(param)
        .then(async response => {
          const message = response.messages;
          await this.processMessage(message, 'success');
          await setTimeout(async () => {
            await history.push('/move/workout-schedule');
          }, 1600);
        })
        .catch(error => {
          const serverMessage = error.data;
          const validationStatus = error.status === HttpStatusCode.InternalServerError;
          this.processMessage(
            validationStatus ? serverMessage.message : serverMessage.messages,
            'error',
          );
        });
    } else {
      this.processMessage('Promo Code not Valid, Check your Promo Code', 'warning');
    }
  };

  handlePopUpInformation = (message, typeMessage) => {
    this.processMessage(message, typeMessage);
  };

  handleCloseToast = () => {
    const { toastInformation } = this.state;
    this.setState({ toastInformation: { ...toastInformation, isOpen: false } });
  };

  validationStepUserDetails() {
    const {
      form: { phone, firstName, email, address, addressType, note, items },
      validation,
    } = this.state;
    const selectedItem = !_.isEmpty(items) ? items[0] : {};

    const message = { isError: false, errorMessage: '' };
    const messageV2 = { isError: '', errorMessage: '' };

    let passCheck = false;
    let groupCheck = true;
    const paramValidation01 = CommonHelper.objectCloning(message);
    const paramValidation02 = CommonHelper.objectCloning(message);
    const paramValidation03 = CommonHelper.objectCloning(message);
    let paramValidation04 = CommonHelper.objectCloning(message);
    let paramValidation05 = CommonHelper.objectCloning(messageV2);
    const paramValidation06 = CommonHelper.objectCloning(messageV2);

    paramValidation01.isError = !phone;
    paramValidation01.errorMessage = !phone ? 'Please enter Phone' : '';

    paramValidation02.isError = !firstName;
    paramValidation02.errorMessage = !firstName ? 'Please enter First Name' : '';

    if (!validation.email.isError) {
      paramValidation03.isError = !email;
      paramValidation03.errorMessage = !email ? 'Please enter Email' : '';
    } else {
      paramValidation03.isError = validation.email.isError;
      paramValidation03.errorMessage = validation.email.errorMessage;
    }

    if (selectedItem.outside_shift) {
      paramValidation06.isError = !note ? 'error' : '';
      paramValidation06.errorMessage = !note ? 'Please enter note Address' : '';

      if (!address || address === '') {
        if (addressType === addressTypeValue[0].value) {
          paramValidation04 = {
            isError: true,
            errorMessage: 'Please enter Address',
          };
          paramValidation05 = {
            isError: '',
            errorMessage: '',
          };
        } else {
          paramValidation04 = {
            isError: false,
            errorMessage: '',
          };
          paramValidation05 = {
            isError: 'error',
            errorMessage: 'Please enter Address',
          };
        }

        groupCheck = false;
      }
    }

    if (
      !paramValidation01.isError &&
      !paramValidation02.isError &&
      !paramValidation03.isError &&
      !paramValidation06.isError &&
      groupCheck
    ) {
      passCheck = true;
    }

    this.setState({
      validation: {
        ...validation,
        phone: paramValidation01,
        firstName: paramValidation02,
        email: paramValidation03,
        address: paramValidation04,
        addressArea: paramValidation05,
        note: paramValidation06,
      },
    });

    return passCheck;
  }

  processMessage(messages, type) {
    const convertedMessage = CommonHelper.generateMessage(messages);

    const paramInformation = {
      isOpen: true,
      message: convertedMessage,
      snackbarType: type,
    };

    this.setState({
      toastInformation: paramInformation,
    });
  }

  renderActiveContent() {
    const { stepList, current, form, validation } = this.state;
    let renderElement = null;

    switch (stepList[current].key) {
      case stepList[1].key:
        renderElement = (
          <Grid item lg md>
            <StepBookingProductDetails
              parentForm={form}
              parentValidation={validation}
              currentValue={current}
              totalStepper={3}
              onParameterUpdate={this.handleParameterUpdateStepBookingDetails}
              onButtonClickCancel={this.handleButtonClickCancel}
              onButtonClickPrev={this.handleButtonClickPrev}
              onButtonClickNext={this.handleButtonClickNext}
              onPopUpInformation={this.handlePopUpInformation}
            />
          </Grid>
        );
        break;
      case stepList[2].key:
        renderElement = (
          <Grid item lg md>
            <StepBookingSummaryAndPayment
              parentForm={form}
              parentValidation={validation}
              currentValue={current}
              totalStepper={3}
              onParameterUpdate={this.handleParameterUpdateStepBookingSummary}
              onButtonClickCancel={this.handleButtonClickCancel}
              onButtonClickPrev={this.handleButtonClickPrev}
              onPopUpInformation={this.handlePopUpInformation}
              onButtonClickSubmit={this.handleButtonClickSubmit}
            />
          </Grid>
        );
        break;
      default:
        renderElement = (
          <Grid item lg md>
            <StepBookingUserDetails
              parentForm={form}
              parentValidation={validation}
              currentValue={current}
              totalStepper={3}
              onParameterUpdate={this.handleParameterUpdateStepUserDetails}
              onButtonClickCancel={this.handleButtonClickCancel}
              onButtonClickNext={this.handleButtonClickNext}
              onPopUpInformation={this.handlePopUpInformation}
            />
          </Grid>
        );
        break;
    }

    return renderElement;
  }

  render() {
    const { current, stepList, toastInformation } = this.state;
    const prevUrl = '/move/workout-schedule';

    return (
      <div>
        <Helmet title="FITCO | Move - Create Booking" />
        <div className="container-page-membership-purchase-add scroll-container-invisible">
          <div className="container-page-scrolling-area">
            <Grid container direction="column">
              <Grid item lg md className="section-page-header mv-24">
                <div className="breadcrumbs-section">
                  <Breadcrumbs aria-label="breadcrumb">
                    <Link
                      className="text-12"
                      color="inherit"
                      href={prevUrl}
                      onClick={event => {
                        this.handleClick(event, prevUrl);
                      }}
                    >
                      <i className="ic-ffo-calendar container-icon-prefix size-16" />
                      Schedules
                    </Link>
                    <label className="text-12" color="inherit">
                      Move Book
                    </label>
                  </Breadcrumbs>
                </div>
              </Grid>
              <Grid item lg md className="section-page-body">
                <div className="flex-column container-main-card p-32">
                  <label className="text-16 text-bold text-rolling-stone  mb-32">Create Book</label>
                  <Grid item lg md>
                    <NavigationStep currentValue={current} arrayLabel={stepList} />
                  </Grid>
                  {this.renderActiveContent()}
                </div>
              </Grid>
              <Grid item lg md className="section-page-footer" />
            </Grid>
          </div>
        </div>
        <SnackBarSimple
          open={toastInformation.isOpen}
          durationHide={2000}
          message={toastInformation.message}
          onClickClose={this.handleCloseToast}
          snackbarType={toastInformation.snackbarType}
          anchor={optionToast}
        />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  processCreateOrderBooking: params => setProcessCreateOrderBooking(dispatch, params),
});

const mapStateToProps = ({ membership }) => ({ membership });

ScheduleBook.propTypes = {
  checkUserAccessPermission: PropTypes.func,
  history: PropTypes.object,
  match: PropTypes.object,
  membership: PropTypes.object,
  processCreateOrderBooking: PropTypes.func,
};

const shell = compose(connect(mapStateToProps, mapDispatchToProps));

export default shell(AuthenticationAccessPages(ScheduleBook));
