import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import _ from 'lodash';
import { Grid } from '@material-ui/core';
import { compose } from 'redux';
// style
import './SchedulePagesStyle.scss';
// Component
import {
  HeaderSchedule,
  CalendarSummary,
  ModalScheduleDetailClass,
  ModalRescheduleEms,
} from './components/Index';
import {
  TextInput,
  AuthenticationAccessPages,
  SnackBarSimple,
  SkeletonMain,
  ButtonIconMain,
  SelectInputGeneral,
} from '../../../../components/Index';
// api
import { getConsultationScheduleByDate } from '../../../../services/api/ScheduleDataApi';
import {
  getConsultationBranchList,
  getWorkoutCategoryStatus,
} from '../../../../services/api/MasterDataApi';
import * as Actions from '../../../../redux/actions/ScheduleAction';
// helper
import { CalendarHelper, CommonHelper, MasterDataHelper } from '../../../../helpers/Index';

const dataTabs = MasterDataHelper.categoryMedicOption;
const currentDate = CommonHelper.currentDate('YYYY-MM-DD');

const initialFilter = {
  search: '',
  merchantId: null,
  branchId: null,
  timeRangeStartWeek: null,
  timeRangeEndWeek: null,
  category: null,
  weekDayList: null,
  status: null,
};

const optionToast = {
  vertical: 'top',
  horizontal: 'right',
};

class SchedulePages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: CommonHelper.objectCloning(initialFilter),
      selectedModalItemId: null,
      selectedModalItem: null,
      isOpenModal: false,
      tabsValue: 0,
      isLoading: true,
      limit: 10,
      toastInformation: {
        isOpen: false,
        message: '',
        snackbarType: 'warning',
      },
      isOpenModalReschedule: false,
      selectedScheduleDetail: {},
    };
    this.searchDebounce = _.debounce(this.searchDebounce, 400);
  }

  componentDidMount() {
    this.getBranchByTab();
  }

  setDefaultCard() {
    const { tabsValue } = this.state;
    const { setCurrentAddressTypeOption } = this.props;

    setCurrentAddressTypeOption(dataTabs[tabsValue].name);
  }

  getBranchByTab = async () => {
    const { consultationBranch } = this.props;
    const { tabsValue } = this.state;
    try {
      await consultationBranch();
      await this.getCategoryStatusApi();
      this.handleTabChange(tabsValue);
    } catch (error) {
      const serverMessage = error.data;
      this.processMessage(serverMessage.messages, 'error');
    }
  };

  getCategoryStatusApi = async () => {
    const { callCategoryStatusApi } = this.props;
    try {
      await callCategoryStatusApi();
    } catch (error) {
      const serverMessage = error.data;
      this.processMessage(serverMessage.messages, 'error');
    }
  };

  getScheduleByDate(selectedDate, indexParent, page = 1, limit = 10) {
    const { scheduleByDate } = this.props;
    const { filter } = this.state;

    const params = {
      date: selectedDate,
      search_term: filter.search,
      branch_location: filter.branchId,
      schedule_status: filter.status,
      page,
      limit,
      sortBy: '',
      order: 'asc',
    };

    scheduleByDate(params, indexParent).then(() => {
      this.setState({ isLoading: false });
    });
  }

  getCalendarDataList(weekDayList) {
    const { filter, limit } = this.state;
    const {
      scheduleSummary: {
        fetchingConsultation01,
        fetchingConsultation02,
        fetchingConsultation03,
        fetchingConsultation04,
        fetchingConsultation05,
        fetchingConsultation06,
        fetchingConsultation07,
      },
    } = this.props;

    if (!weekDayList) {
      const weekDay = CalendarHelper.getWeekDayByToday(this.state.filter.timeRangeStartWeek);
      const listHeaderTimeLine = CalendarHelper.generateListWeek(weekDay);
      weekDayList = CalendarHelper.generateCalenderHeaderData(listHeaderTimeLine);
    }

    const allDateFetching =
      !fetchingConsultation01 &&
      !fetchingConsultation02 &&
      !fetchingConsultation03 &&
      !fetchingConsultation04 &&
      !fetchingConsultation05 &&
      !fetchingConsultation06 &&
      !fetchingConsultation07;

    if (allDateFetching && filter.merchantId && filter.branchId && weekDayList) {
      this.setState(
        {
          weekDayList,
        },
        () => {
          weekDayList.forEach((item, index) => {
            this.getScheduleByDate(item.value, index + 1, undefined, limit);
          });
        },
      );
    }
  }

  searchDebounce = () => {
    this.setDefaultCard();
    this.getCalendarDataList(null);
  };

  handleReloadColumn = index => {
    const { weekDayList, limit } = this.state;
    const {
      scheduleSummary: {
        fetchingConsultation01,
        fetchingConsultation02,
        fetchingConsultation03,
        fetchingConsultation04,
        fetchingConsultation05,
        fetchingConsultation06,
        fetchingConsultation07,
        currentPageConsultation01,
        currentPageConsultation02,
        currentPageConsultation03,
        currentPageConsultation04,
        currentPageConsultation05,
        currentPageConsultation06,
        currentPageConsultation07,
      },
    } = this.props;
    let checkPass;
    let pages = 0;

    switch (index) {
      case 1:
        checkPass = !fetchingConsultation01;
        pages = currentPageConsultation01 + 1;
        break;
      case 2:
        checkPass = !fetchingConsultation02;
        pages = currentPageConsultation02 + 1;
        break;
      case 3:
        checkPass = !fetchingConsultation03;
        pages = currentPageConsultation03 + 1;
        break;
      case 4:
        checkPass = !fetchingConsultation04;
        pages = currentPageConsultation04 + 1;
        break;
      case 5:
        checkPass = !fetchingConsultation05;
        pages = currentPageConsultation05 + 1;
        break;
      case 6:
        checkPass = !fetchingConsultation06;
        pages = currentPageConsultation06 + 1;
        break;

      default:
        checkPass = !fetchingConsultation07;
        pages = currentPageConsultation07 + 1;
        break;
    }

    if (checkPass) {
      this.getScheduleByDate(weekDayList[index - 1].value, index, pages, limit);
    }
  };

  handleSearch = value => {
    const { filter } = this.state;
    this.setState({ filter: { ...filter, search: value } }, () => {
      this.searchDebounce();
    });
  };

  handleSelectBranch = value => {
    const { filter } = this.state;

    this.setDefaultCard();
    this.setState(
      {
        filter: { ...filter, branchId: value || null },
      },
      () => {
        this.getCalendarDataList(null);
      },
    );
  };

  handleTabChange = value => {
    const { masterData, usersReducer } = this.props;

    const weekDay = CalendarHelper.getWeekDayByToday(currentDate);
    const listHeaderTimeLine = CalendarHelper.generateListWeek(weekDay);
    const converted = CalendarHelper.generateCalenderHeaderData(listHeaderTimeLine);
    const currentBranch = !_.isEmpty(masterData.branchList)
      ? masterData.branchList[0].branch_id
      : null;
    const currentStatus = !_.isEmpty(masterData.workoutCategoryStatus)
      ? masterData.workoutCategoryStatus[1].schedule_status
      : null;

    const initialFilterFinal = {
      ...CommonHelper.objectCloning(initialFilter),
      merchantId: usersReducer.activeMerchant,
      branchId: currentBranch,
      category: dataTabs[value].value,
      timeRangeStartWeek: weekDay.startWeek,
      timeRangeEndWeek: weekDay.endWeek,
      status: currentStatus,
    };

    this.setDefaultCard();
    this.setState(
      {
        tabsValue: value,
        filter: initialFilterFinal,
        isLoading: true,
        weekDayList: converted,
      },
      () => {
        this.getCalendarDataList(converted);
      },
    );
  };

  handleButtonAddClick = () => {
    const { history } = this.props;

    history.push('/medic/clinic-schedule/add');
  };

  handleCalendarHeaderClick = value => {
    const { filter } = this.state;
    this.setDefaultCard();
    this.setState({ filter: { ...filter, timeRangeStartWeek: value[0].value } }, () => {
      this.getCalendarDataList(value);
    });
  };

  handleScheduleCardClick = item => {
    if (item.scheduleId) {
      this.setState({
        isOpenModal: true,
        selectedModalItemId: item.scheduleId,
        selectedModalItem: item,
      });
    }
  };

  handleScheduleClickBook = () => {
    const { history } = this.props;
    const param = {};

    const paramFilter = CommonHelper.encryptObject(param);
    history.push(`/medic/clinic-schedule/book/${paramFilter}`);
  };

  handleScheduleCardClose = value => {
    this.setDefaultCard();
    this.setState({ isOpenModal: value }, () => {
      this.getCalendarDataList(null);
    });
  };

  handleCloseRescheduleModal = () => {
    this.setState({ isOpenModalReschedule: false, isOpenModal: true, selectedScheduleDetail: {} });
  };

  handleSuccessRescheduleTime = messages => {
    this.setState({ isOpenModalReschedule: false, selectedScheduleDetail: {} });
    this.processMessage(messages, 'success');
    this.handleScheduleCardClose();
  };

  handleOpenRescheduleModal = item => {
    this.setState({
      isOpenModalReschedule: true,
      isOpenModal: false,
      selectedScheduleDetail: item,
    });
  };

  handleSelectStatus = value => {
    const { filter } = this.state;

    this.setDefaultCard();
    this.setState(
      {
        filter: { ...filter, status: value || null },
      },
      () => {
        this.getCalendarDataList(null);
      },
    );
  };

  handleCloseToast = () => {
    const { toastInformation } = this.state;
    this.setState({ toastInformation: { ...toastInformation, isOpen: false } });
  };

  processMessage(messages, type) {
    const convertedMessage = CommonHelper.generateMessage(messages);

    const paramInformation = {
      isOpen: true,
      message: convertedMessage,
      snackbarType: type,
    };

    this.setState({
      toastInformation: paramInformation,
    });
  }

  renderFilter() {
    const {
      masterData: { branchList, workoutCategoryStatus },
    } = this.props;
    const { filter, tabsValue } = this.state;

    return (
      <Grid container direction="row" justify="space-between" alignItems="center">
        <Grid item lg md>
          <Grid container direction="row">
            <Grid item lg={3} md={3} className="mr-12">
              <div className="container-remove-margin">
                <SelectInputGeneral
                  placeHolder="Select Branch"
                  currentValue={filter.branchId}
                  dataList={branchList}
                  primaryKey="branch_id"
                  onChange={this.handleSelectBranch}
                />
              </div>
            </Grid>
            <Grid item lg={3} md={3} className="mr-12">
              <div className="container-remove-margin">
                <SelectInputGeneral
                  placeHolder="Select Status"
                  currentValue={filter.status}
                  dataList={workoutCategoryStatus}
                  primaryKey="schedule_status"
                  onChange={this.handleSelectStatus}
                />
              </div>
            </Grid>
            <Grid item lg={4} md={4}>
              <TextInput
                iconPrefix="ic-ffo-search"
                placeHolderText={'Search Coach Name'}
                onChange={this.handleSearch}
                size="md"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={2} md={3}>
          <Grid container direction="row">
            <Grid item lg md className="container-header-button">
              <ButtonIconMain
                icon="ic-ffo-refresh"
                type="ghost"
                size="md"
                onClick={() => this.handleTabChange(tabsValue)}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  renderModalDetail() {
    const { isOpenModal, selectedModalItemId, selectedModalItem } = this.state;
    let elementRender = null;

    if (isOpenModal) {
      elementRender = (
        <ModalScheduleDetailClass
          isOpen={isOpenModal}
          scheduleId={selectedModalItemId}
          onClose={this.handleScheduleCardClose}
          onClickBook={() => this.handleScheduleClickBook(selectedModalItem)}
          onClickReschedule={this.handleOpenRescheduleModal}
        />
      );
    }
    return elementRender;
  }

  render() {
    const { scheduleSummary } = this.props;
    const {
      isLoading,
      toastInformation,
      isOpenModalReschedule,
      selectedScheduleDetail,
    } = this.state;
    let elementRender = <SkeletonMain />;

    if (!isLoading) {
      elementRender = (
        <div>
          <Helmet title="FITCO | Schedules" />
          <HeaderSchedule
            data={dataTabs}
            currentTab={this.state.tabsValue}
            onChangeTab={this.handleTabChange}
            onButtonClick={this.handleButtonAddClick}
            onButtonClickBook={this.handleScheduleClickBook}
          />
          <div className="container-page-schedule">
            <div className="container-page-scrolling-area include-tab">
              <Grid container direction="column">
                <Grid item lg md className="section-page-header">
                  {this.renderFilter()}
                </Grid>
                <Grid item lg md className="section-page-body">
                  <CalendarSummary
                    onCardClick={this.handleScheduleCardClick}
                    onClickBook={this.handleScheduleClickBook}
                    onButtonHeaderClick={this.handleCalendarHeaderClick}
                    data={scheduleSummary}
                    onReloadColumn={this.handleReloadColumn}
                  />
                </Grid>
                <Grid item lg md className="section-page-footer">
                  {this.renderModalDetail()}
                </Grid>
                <Grid item lg md className="section-page-footer">
                  <ModalRescheduleEms
                    isOpen={isOpenModalReschedule}
                    onClose={this.handleCloseRescheduleModal}
                    selectedScheduleDetail={selectedScheduleDetail}
                    onSuccessReschedule={this.handleSuccessRescheduleTime}
                  />
                </Grid>
              </Grid>
            </div>
          </div>
          <SnackBarSimple
            open={toastInformation.isOpen}
            durationHide={2000}
            message={toastInformation.message}
            onClickClose={this.handleCloseToast}
            snackbarType={toastInformation.snackbarType}
            anchor={optionToast}
          />
        </div>
      );
    }

    return elementRender;
  }
}

const mapDispatchToProps = dispatch => ({
  scheduleByDate: (params, indexParent) =>
    getConsultationScheduleByDate(dispatch, params, indexParent),
  setCurrentAddressTypeOption: params => dispatch(Actions.setCurrentScheduleTabActive(params)),
  consultationBranch: () => getConsultationBranchList(dispatch),
  callCategoryStatusApi: () => getWorkoutCategoryStatus(dispatch),
});

const mapStateToProps = ({ scheduleSummary, masterData, usersReducer }) => ({
  scheduleSummary,
  masterData,
  usersReducer,
});

SchedulePages.propTypes = {
  callCategoryStatusApi: PropTypes.func,
  consultationBranch: PropTypes.func,
  history: PropTypes.object,
  masterData: PropTypes.object,
  scheduleByDate: PropTypes.func,
  scheduleSummary: PropTypes.object,
  setCurrentAddressTypeOption: PropTypes.func,
  usersReducer: PropTypes.object,
};

const shell = compose(connect(mapStateToProps, mapDispatchToProps));

export default shell(AuthenticationAccessPages(SchedulePages));
