import React from 'react';
import PropTypes from 'prop-types';
import { Paper, Grid } from '@material-ui/core';
// style
import './HeaderOrdersPageStyle.scss';
// component
import { ButtonMain, TabNavigation } from '../../../../../components/Index';
import { PermissionAccess, PermissionModule, PermissionPage } from '../../../../../helpers/PermissionHelper';

const HeaderOrdersPage = props => {
  const {
    onChangeTab,
    onClickCreateOrder,
    data,
    currentTab,
  } = props;

  const handleChangeTab = value => {
    onChangeTab(value);
  };

  const handleClickCreateOrder = value => {
    onClickCreateOrder(value);
  };

  return (
    <Paper square className="container-tab-header-orders">
      <Grid container>
        <Grid item lg md>
          <TabNavigation data={data} onChange={handleChangeTab} currentTab={currentTab} />
        </Grid>
        <Grid item lg={2} md={3} className="container-header-button">
          <ButtonMain
            labelText="Create Order"
            onClick={handleClickCreateOrder}
            type="primary"
            size="md"
            startIcon="ic-ffo-add"
            requiredPermission={`${PermissionModule.Orders}.${PermissionPage.List}.${PermissionAccess.Add}`}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

HeaderOrdersPage.propTypes = {
  currentTab: PropTypes.number,
  data: PropTypes.array,
  onChangeTab: PropTypes.func,
  onClickCreateOrder: PropTypes.func,
};

export default HeaderOrdersPage;
