/* eslint-disable no-unused-vars */
/* eslint-disable react/no-access-state-in-setstate */
import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import _ from 'lodash';
// Component
import {
  SnackBarSimple,
  PickerInputDate,
  EmptyState,
  SkeletonMain,
  GeneratorExcel,
  TextInputPrePost,
  SelectInputMain,
  ButtonMain,
  AuthenticationAccessPages,
} from '../../../../components/Index';
// Api
import {
  getOrderSummaryList,
  getOrderSummaryReportExport,
} from '../../../../services/api/OrdersApi';
// Helpers
import { CommonHelper, MasterDataHelper, OrderHelper } from '../../../../helpers/Index';
// Style
import './OrderSummaryPageStyle.scss';
// Assets
import { Images } from '../../../../assets/Index';
import { compose } from 'redux';

const currentDate = CommonHelper.currentDate('YYYY-MM-DD');
const startDateValue = CommonHelper.getStartDateMonth(currentDate);
const endDateValue = CommonHelper.getEndDateMonth(currentDate);
const optionSearchTypeOrderSummary = MasterDataHelper.optionSearchTypeOrderSummary;
const optionTransactionSource = MasterDataHelper.optionTransactionSource;
const optionOrderType = MasterDataHelper.optionOrderType.slice(2, 4);

const optionAlert = {
  vertical: 'top',
  horizontal: 'right',
};

const initialSort = {
  sortField: 'order_date',
  sortOrder: '',
};

const initialFilter = {
  search: null,
  orderType: optionOrderType[0].value,
  transactionSource: undefined,
  searchType: optionSearchTypeOrderSummary[1].value,
  startDate: startDateValue,
  endDate: endDateValue,
};

class OrderSummaryPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: CommonHelper.objectCloning(initialFilter),
      page: 1,
      limit: 10,
      sort: CommonHelper.objectCloning(initialSort),
      pagination: {
        showSizeChanger: true,
        showTotal: (total, range) => `Showing ${range[0]}-${range[1]} from ${total}`,
        pageSizeOptions: ['10', '20', '30', '40'],
      },
      isLoading: true,
      toastInformation: {
        isOpen: false,
        message: '',
        snackbarType: 'warning',
      },
    };
    this.searchDebounce = _.debounce(this.searchDebounce, 400);
  }

  componentDidMount() {
    this.getOrderSummaryListPagination();
  }

  getOrderSummaryListPagination = () => {
    const { orderSummaryListPagination } = this.props;
    const { limit, page, pagination, sort, filter } = this.state;

    const payload = {
      page,
      limit,
      sort: sort.sortField,
      sortOrder: sort.sortOrder,
      search: filter.search,
      start_date: filter.startDate,
      end_date: filter.endDate,
      search_type: filter.searchType,
      order_type: filter.orderType,
      transaction_source: filter.transactionSource,
    };

    orderSummaryListPagination(payload).then(response => {
      this.setState({
        isLoading: false,
        pagination: { ...pagination, pageSize: limit, total: response.total },
      });
    });
  };

  handleChangeSearch = value => {
    const { filter, pagination } = this.state;

    this.setState(
      {
        filter: { ...filter, search: value },
        page: 1,
        pagination: { ...pagination, current: 1 },
      },
      () => {
        this.searchDebounce();
      },
    );
  };

  searchDebounce = () => {
    this.getOrderSummaryListPagination();
  };

  handleChangeStartDate = value => {
    const { filter, pagination } = this.state;
    const endDate = CommonHelper.getEndDateMonth(value);

    this.setState(
      {
        filter: { ...filter, startDate: value, endDate },
        page: 1,
        pagination: { ...pagination, current: 1 },
      },
      () => {
        this.getOrderSummaryListPagination();
      },
    );
  };

  handleChangeEndDate = value => {
    const { filter, pagination } = this.state;

    this.setState(
      {
        filter: { ...filter, endDate: value },
        page: 1,
        pagination: { ...pagination, current: 1 },
      },
      () => {
        this.getOrderSummaryListPagination();
      },
    );
  };

  handleChangeSearchType = value => {
    const { filter, pagination } = this.state;

    this.setState(
      {
        filter: { ...filter, searchType: value },
        page: 1,
        pagination: { ...pagination, current: 1 },
      },
      () => {
        this.getOrderSummaryListPagination();
      },
    );
  };

  handleTableChange = (pagination, filters, sorter) => {
    const { sort } = this.state;

    this.setState(
      {
        limit: pagination.pageSize,
        pagination: {
          ...this.state.pagination,
          current: pagination.current,
          pageSize: pagination.pageSize,
        },
        sort: {
          ...sort,
          sortField: sorter.field,
          sortOrder: sorter.order === 'ascend' ? '' : 'desc',
        },
        page: pagination.current,
      },
      () => {
        this.getOrderSummaryListPagination();
      },
    );
  };

  handleCloseToash = () => {
    const { toastInformation } = this.state;
    this.setState({ toastInformation: { ...toastInformation, isOpen: false } });
  };

  handleChangeTransactionSource = value => {
    const { filter } = this.state;

    this.setState(
      {
        filter: { ...filter, transactionSource: value },
        page: 1,
        pagination: { ...this.state.pagination, current: 1 },
      },
      () => {
        this.getOrderSummaryListPagination();
      },
    );
  };

  handleChangeOrderType = value => {
    const { filter } = this.state;

    this.setState(
      {
        filter: { ...filter, orderType: value },
        page: 1,
        pagination: { ...this.state.pagination, current: 1 },
      },
      () => {
        this.getOrderSummaryListPagination();
      },
    );
  };

  handleClickReset = () => {
    const { filter } = this.state;

    this.setState(
      {
        filter: {
          ...filter,
          search: null,
          orderType: undefined,
          transactionSource: undefined,
          searchType: optionSearchTypeOrderSummary[1].value,
          startDate: startDateValue,
          endDate: endDateValue,
          page: 1,
          pagination: { ...this.state.pagination, current: 1 },
        },
      },
      () => {
        this.getOrderSummaryListPagination();
      },
    );
  };

  handleButtonDownload = () => {
    const {
      filter: { search, startDate, endDate, searchType, orderType, transactionSource },
    } = this.state;
    const {
      getOrderSummaryExport,
      orderData: { fetchingExport },
    } = this.props;

    const params = {
      start_date: startDate,
      end_date: endDate,
      search,
      search_type: searchType,
      order_type: orderType,
      transaction_source: transactionSource,
    };

    if (!fetchingExport) {
      getOrderSummaryExport(params);
    }
  };

  renderColumns = () => {
    const {
      filter: { orderType },
    } = this.state;

    const rowRender = [
      {
        title: 'Order Date',
        dataIndex: 'order_date',
        fixed: true,
        width: 180,
        key: 'order_date',
      },
      {
        title: 'Product ID',
        dataIndex: 'product_id',
        key: 'product_id',
        fixed: true,
        width: 100,
      },
      {
        title: 'SKU',
        dataIndex: 'product_sku',
        key: 'product_sku',
        width: 180,
      },
      {
        title: 'Name',
        dataIndex: 'product_name',
        key: 'product_name',
        width: 200,
      },
      {
        title: 'Quantity',
        dataIndex: 'total_quantity',
        key: 'total_quantity',
        width: 100,
        align: 'center',
      },
      {
        title: 'Weight',
        dataIndex: 'total_weight',
        key: 'total_weight',
        width: 100,
        align: 'center',
        render: text => <label>{text} KG</label>,
      },
    ];

    if (orderType === optionOrderType[1].value) {
      rowRender.splice(rowRender.length - 1, 1);
      rowRender.splice(0, 1, {
        title: 'Shipment Date',
        dataIndex: 'shipment_date',
        fixed: true,
        width: 180,
        key: 'shipment_date',
        render: (text, row) => {
          const date =
            CommonHelper.dateTimeParseNewFormat(row.shipment_date, 'DD MMM YYYY | HH:mm A') || '-';

          return <label className="text-12">{`${date}`}</label>;
        },
      });

      const tmpRowRender = [
        {
          title: 'Extras',
          dataIndex: 'extras',
          key: 'extras',
          width: 180,
          align: 'center',
          render: (text, row) => {
            const wording = OrderHelper.generateExtraWordingDetails(row.extras);

            return (
              <div className="flex-column">
                <label className="mb-8">{row.total_extras}</label>
                <label>{`( ${wording} )`}</label>
              </div>
            );
          },
        },
      ];

      rowRender.push(...tmpRowRender);
    }

    return rowRender;
  };

  renderFilter() {
    const {
      orderData: { orderSummaryReportExport, fetchingExport },
    } = this.props;
    const {
      filter: { startDate, endDate, search, searchType, transactionSource, orderType },
    } = this.state;

    return (
      <Grid container direction="column">
        <Grid item>
          <Grid container justify="space-between" className="container-filter">
            <Grid item lg={4} md={4}>
              <div className="container-pre-post-custom">
                <TextInputPrePost
                  placeholder="Search Order"
                  currentValue={search}
                  onChange={this.handleChangeSearch}
                  prefix="ic-ffo-search"
                  customElementsBefore={
                    <div className="container-custom-select">
                      <SelectInputMain
                        options={optionSearchTypeOrderSummary}
                        currentValue={searchType}
                        onChange={this.handleChangeSearchType}
                      />
                    </div>
                  }
                />
              </div>
            </Grid>
            <Grid item xl={6} lg={7} md={7}>
              <Grid container justify="flex-end" alignItems="center">
                <Grid item className="text-right pr-16">
                  <label className="text-12">Showing Date</label>
                </Grid>
                <Grid item xl={3} lg={4} md={4}>
                  <div className="container-remove-margin">
                    <PickerInputDate
                      customIcon="ic-ffo-date-pick"
                      dateFormat="dd/MM/yyyy"
                      defaultValue={this.state.filter.startDate}
                      onChange={this.handleChangeStartDate}
                      toolbar={false}
                    />
                  </div>
                </Grid>
                <label className="text-12 pr-8 pl-8"> - </label>
                <Grid item xl={3} lg={4} md={4}>
                  <div className="container-remove-margin">
                    <PickerInputDate
                      customIcon="ic-ffo-date-pick"
                      dateFormat="dd/MM/yyyy"
                      minDate={this.state.filter.startDate}
                      defaultValue={this.state.filter.endDate}
                      onChange={this.handleChangeEndDate}
                      toolbar={false}
                    />
                  </div>
                </Grid>
                <Grid item>
                  <GeneratorExcel
                    buttonSize="xl"
                    dataSetArray={orderSummaryReportExport}
                    fileName={`Order_Summary_Period_${startDate}_${endDate}`}
                    iconPrefix="ic-ffo-download"
                    isButtonIcon
                    isLoading={fetchingExport}
                    onClick={this.handleButtonDownload}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item className="mt-24">
          <Grid
            container
            justify="space-between"
            className="section-filter flex-wrap-unset"
            alignItems="center"
          >
            <Grid item lg={8} md={8}>
              <Grid container justify="flex-start" className="container-filter">
                <Grid item lg={4} md={4} className="row-filter flex-column">
                  <div className="flex-column">
                    <label className="text-14 text-semi-bold text-gray mb-8">Source Type</label>
                    <SelectInputMain
                      options={optionTransactionSource}
                      size="middle"
                      currentValue={transactionSource}
                      onChange={this.handleChangeTransactionSource}
                      placeholder={'Select Source Type'}
                    />
                  </div>
                </Grid>
                <Grid item lg={4} md={4} className="row-filter flex-column">
                  <div className="flex-column">
                    <label className="text-14 text-semi-bold text-gray mb-8">Order Type</label>
                    <SelectInputMain
                      options={optionOrderType}
                      size="middle"
                      currentValue={orderType}
                      onChange={this.handleChangeOrderType}
                      placeholder={'Select Order Type'}
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg="auto" md="auto">
              <ButtonMain
                type="ghost"
                size="md"
                labelText="Clear Filter"
                onClick={this.handleClickReset}
                startIcon="ic-ffo-uncheck-mark"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  render() {
    const {
      orderData: { orderSummaryList, fetching },
    } = this.props;
    const { isLoading, pagination, toastInformation } = this.state;

    if (isLoading) {
      return <SkeletonMain />;
    }

    return (
      <div>
        <Helmet title="FITCO | Orders - Order Summary" />
        <div className="container-page-order-summary scroll-container-invisible">
          <div className="container-page-scrolling-area">
            <Grid container direction="column">
              <Grid item lg md className="section-page-header">
                {this.renderFilter()}
              </Grid>
              <Grid item lg md className="section-page-body">
                {_.isEmpty(orderSummaryList) ? (
                  <Grid container justify={'center'}>
                    <EmptyState source={Images.emptyStateNoHistory} labelText={'No Order Found'} />
                  </Grid>
                ) : (
                  <Table
                    columns={this.renderColumns()}
                    rowKey={(record, index) => index}
                    dataSource={orderSummaryList}
                    pagination={pagination}
                    loading={fetching}
                    onChange={this.handleTableChange}
                  />
                )}
              </Grid>
              <Grid item lg md className="section-page-footer" />
            </Grid>
          </div>
          <SnackBarSimple
            open={toastInformation.isOpen}
            durationHide={2000}
            message={toastInformation.message}
            onClickClose={this.handleCloseToash}
            snackbarType={toastInformation.snackbarType}
            anchor={optionAlert}
          />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  orderSummaryListPagination: params => getOrderSummaryList(dispatch, params),
  getOrderSummaryExport: params => getOrderSummaryReportExport(dispatch, params),
});

const mapStateToProps = ({ masterDataMain, orderData, usersReducer }) => ({
  masterDataMain,
  orderData,
  usersReducer,
});

OrderSummaryPage.propTypes = {
  getOrderSummaryExport: PropTypes.func,
  orderData: PropTypes.object,
  orderSummaryListPagination: PropTypes.func,
};

const shell = compose(connect(mapStateToProps, mapDispatchToProps));

export default shell(AuthenticationAccessPages(OrderSummaryPage));
