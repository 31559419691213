/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, FormControl, FormLabel } from '@material-ui/core';
import _ from 'lodash';
// Component
import { TextInput, RadioInput, PickerInputDate } from '../../../../../../../components/Index';
// helper
import {
  MasterDataHelper,
  CommonHelper,
  ValidationHelper,
} from '../../../../../../../helpers/Index';

const genderOptions = MasterDataHelper.gender;
const currentDate = CommonHelper.currentDate('YYYY-MM-DD');
const defaultBirthday = CommonHelper.intervalDate(currentDate, 'YYYY-MM-DD', -30, 'year');

const initialForm = {
  phone: '',
  firstName: '',
  lastName: '',
  email: '',
  gender: genderOptions[0],
  birthday: defaultBirthday,
};

const initialValidation = {
  phone: { isError: false, errorMessage: '' },
  firstName: { isError: false, errorMessage: '' },
  lastName: { isError: false, errorMessage: '' },
  email: { isError: false, errorMessage: '' },
};

const ContactPersonForm = ({ datas, validate, onValidationUpdate, onParameterUpdate }) => {
  const [form, setForm] = useState(initialForm);
  const [validation, setValidation] = useState(initialValidation);

  const onValidationHandler = payload => {
    let validationNewValue = CommonHelper.objectCloning(validation);
    const excludeValidationKeys = [];

    const keys = Object.keys(payload).filter(key => {
      return excludeValidationKeys.indexOf(key) === -1;
    });

    const errorValidationKeys = Object.keys(validationNewValue).filter(key => {
      return excludeValidationKeys.indexOf(key) === -1;
    });

    const errorKeys = keys.filter(key => {
      return !payload[key];
    });

    errorValidationKeys.forEach(key => {
      const isError = errorKeys.indexOf(key) !== -1;
      let errorDetail = {};
      switch (key) {
        case 'phone':
          errorDetail = { isError, errorMessage: isError ? 'Please fill phone number' : '' };
          break;
        case 'firstName':
          errorDetail = { isError, errorMessage: isError ? 'Please fill First Name' : '' };
          break;
        case 'lastName':
          errorDetail = { isError, errorMessage: isError ? 'Please fill Last Name' : '' };
          break;
        case 'email':
          errorDetail = {
            isError,
            errorMessage: isError ? 'Please fill Email' : '',
          };
          break;
        default:
          errorDetail = {
            isError,
            errorMessage: isError ? 'Please fill input' : '',
          };
          break;
      }
      validationNewValue = {
        ...validationNewValue,
        [key]: errorDetail,
      };
    });

    return { isError: Boolean(errorKeys.length), validationNewValue };
  };

  useEffect(() => {
    setForm(() => ({
      phone: datas.customer_phone,
      firstName: datas.customer_first_name,
      lastName: datas.customer_last_name,
      email: datas.customer_email,
      gender: _.find(genderOptions, ['name', datas.customer_gender]) || genderOptions[0],
      birthday: datas.customer_birthday || defaultBirthday,
    }));
  }, [datas]);

  useEffect(() => {
    const payload = {
      ...form,
    };
    onParameterUpdate(payload);
  }, [form]);

  useEffect(() => {
    if (validate) {
      const error = onValidationHandler(form);
      setValidation(error.validationNewValue);
      const payload = {
        ...error,
      };
      onValidationUpdate(payload);
    }
  }, [validate]);

  const onHandleChangeForm = (value, key) => {
    const message = CommonHelper.objectCloning(initialValidation[key]);

    switch (key) {
      case 'firstName':
        if (value === '' || value === undefined) {
          message.isError = true;
          message.errorMessage = 'Please enter First Name';
        }
        break;
      case 'lastName':
        if (value === '' || value === undefined) {
          message.isError = true;
          message.errorMessage = 'Please enter Last Name';
        }
        break;
      case 'email': {
        const checkerEmailFormat = ValidationHelper.validateEmail(value.trim());
        if (value === '' || value === undefined || !checkerEmailFormat) {
          message.isError = true;
          message.errorMessage = 'Please enter Email, or wrong Email Format';
        }
        break;
      }
      default:
        break;
    }

    setForm(oldForm => ({
      ...oldForm,
      [key]: value,
    }));

    setValidation(oldValidation => ({
      ...oldValidation,
      [key]: message,
    }));
  };

  return (
    <>
      <Grid item lg={12} md={12} className="mb-4">
        <label className="text-12 text-bold text-rolling-stone">CONTACT PERSON</label>
      </Grid>
      <Grid item lg={12} md={12}>
        <Grid container spacing={2}>
          <Grid item lg={6} md={6}>
            <FormControl component="fieldset" fullWidth margin="normal">
              <FormLabel component="label" className="text-12 filed-title">
                Phone Number
              </FormLabel>
              <TextInput
                placeHolderText="Phone Number"
                size="md"
                onChange={value => {
                  onHandleChangeForm(value, 'phone');
                }}
                currentValue={form.phone}
                errorMessage={validation.phone.errorMessage}
                isError={validation.phone.isError}
                maxLength={14}
                numericOnly
              />
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg={12} md={12}>
        <Grid container spacing={2}>
          <Grid item lg={6} md={6}>
            <FormControl component="fieldset" fullWidth margin="normal">
              <FormLabel component="label" className="text-12 filed-title">
                First Name
              </FormLabel>
              <TextInput
                placeHolderText="First Name"
                size="md"
                onChange={value => {
                  onHandleChangeForm(value, 'firstName');
                }}
                currentValue={form.firstName}
                errorMessage={validation.firstName.errorMessage}
                isError={validation.firstName.isError}
                maxLength={25}
              />
            </FormControl>
          </Grid>
          <Grid item lg={6} md={6}>
            <FormControl component="fieldset" fullWidth margin="normal">
              <FormLabel component="label" className="text-12 filed-title">
                Last Name
              </FormLabel>
              <TextInput
                placeHolderText="Last Name"
                size="md"
                onChange={value => {
                  onHandleChangeForm(value, 'lastName');
                }}
                currentValue={form.lastName}
                errorMessage={validation.lastName.errorMessage}
                isError={validation.lastName.isError}
                maxLength={25}
              />
            </FormControl>
          </Grid>
          <Grid item lg={6} md={6}>
            <FormControl component="fieldset" fullWidth margin="normal">
              <FormLabel component="label" className="text-12 filed-title">
                Email
              </FormLabel>
              <TextInput
                placeHolderText="Email"
                size="md"
                onChange={value => {
                  onHandleChangeForm(value, 'email');
                }}
                currentValue={form.email}
                errorMessage={validation.email.errorMessage}
                isError={validation.email.isError}
                maxLength={100}
              />
            </FormControl>
          </Grid>
          <Grid item lg={6} md={6}>
            <Grid container direction="row" justify="space-between" spacing={6}>
              <Grid item lg={6} md={6}>
                <FormControl component="fieldset" fullWidth margin="normal">
                  <FormLabel component="label" className="text-12 filed-title">
                    Date of Birth
                  </FormLabel>
                  <PickerInputDate
                    customIcon="ic-ffo-date-pick"
                    dateFormat="dd-MM-yyyy"
                    maxDate={currentDate}
                    defaultValue={form.birthday}
                    onChange={value => {
                      onHandleChangeForm(value, 'birthday');
                    }}
                    toolbar={false}
                  />
                </FormControl>
              </Grid>
              <Grid item lg={6} md={6}>
                <FormControl component="fieldset" fullWidth margin="normal">
                  <FormLabel component="label" className="text-12 filed-title">
                    Gender
                  </FormLabel>
                  <RadioInput
                    data={genderOptions}
                    onSelect={value => {
                      onHandleChangeForm(value, 'gender');
                    }}
                    direction="column"
                    currentValue={form.gender}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

ContactPersonForm.propTypes = {
  datas: PropTypes.object,
  onParameterUpdate: PropTypes.func,
  onValidationUpdate: PropTypes.func,
  validate: PropTypes.bool,
};

export default ContactPersonForm;
