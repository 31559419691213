export const constants = {
  getPopupScreenListRequest: 'GET_POPUP_SCREEN_LIST_REQUEST',
  getPopupScreenListSuccess: 'GET_POPUP_SCREEN_LIST_SUCCESS',
  getPopupScreenListFail: 'GET_POPUP_SCREEN_LIST_FAIL',
  getPopupScreenDetailsRequest: 'GET_POPUP_SCREEN_DETAILS_REQUEST',
  getPopupScreenDetailsSuccess: 'GET_POPUP_SCREEN_DETAILS_SUCCESS',
  getPopupScreenDetailsFail: 'GET_POPUP_SCREEN_DETAILS_FAIL',
  createPopupScreenRequest: 'CREATE_POPUP_SCREEN_SECTION_REQUEST',
  createPopupScreenSuccess: 'CREATE_POPUP_SCREEN_SECTION_SUCCESS',
  createPopupScreenFail: 'CREATE_POPUP_SCREEN_SECTION_FAIL',
  updateStatusPopupScreenRequest: 'UPDATE_STATUS_POPUP_SCREEN_SECTION_REQUEST',
  updateStatusPopupScreenSuccess: 'UPDATE_STATUS_POPUP_SCREEN_SECTION_SUCCESS',
  updateStatusPopupScreenFail: 'UPDATE_STATUS_POPUP_SCREEN_SECTION_FAIL',
  editPopupScreenRequest: 'EDIT_POPUP_SCREEN_SECTION_REQUEST',
  editPopupScreenSuccess: 'EDIT_POPUP_SCREEN_SECTION_SUCCESS',
  editPopupScreenFail: 'EDIT_POPUP_SCREEN_SECTION_FAIL',
};

const initState = {
  fetching: false,
  popupScreenList: [],
  popupScreenDetails: {},
  errorFetch: false,
  errorMessage: null,
  responseMessage: null,
};

export default function popupScreenReducer(state = initState, action) {
  const { type, payload } = action;
  switch (type) {
    case constants.getPopupScreenListRequest:
      return { ...state, fetching: true };
    case constants.getPopupScreenListSuccess:
      return {
        ...state,
        popupScreenList: payload.data,
        fetching: false,
      };
    case constants.getPopupScreenListFail:
      return {
        ...state,
        fetching: false,
        errorFetch: true,
        errorMessage: payload,
      };
    case constants.getPopupScreenDetailsRequest:
      return { ...state, fetching: true };
    case constants.getPopupScreenDetailsSuccess:
      return {
        ...state,
        popupScreenDetails: payload.data,
        fetching: false,
      };
    case constants.getPopupScreenDetailsFail:
      return {
        ...state,
        fetching: false,
        errorFetch: true,
        errorMessage: payload,
      };
    case constants.createPopupScreenRequest:
      return { ...state, fetching: true, responseMessage: null };
    case constants.createPopupScreenSuccess:
      return {
        ...state,
        responseMessage: payload.messages,
        fetching: false,
      };
    case constants.createPopupScreenFail:
      return { ...state, fetching: false, errorMessage: payload };

    case constants.updateStatusPopupScreenRequest:
      return { ...state, fetching: true, responseMessage: null };
    case constants.updateStatusPopupScreenSuccess:
      return {
        ...state,
        responseMessage: payload.messages,
        fetching: false,
      };
    case constants.updateStatusPopupScreenFail:
      return { ...state, fetching: false, errorMessage: payload };

    case constants.editPopupScreenRequest:
      return { ...state, fetching: true, responseMessage: null };
    case constants.editPopupScreenSuccess:
      return {
        ...state,
        responseMessage: payload.messages,
        fetching: false,
      };
    case constants.editPopupScreenFail:
      return { ...state, fetching: false, errorMessage: payload };
    default:
      return state;
  }
}
