import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Grid, Breadcrumbs, Link } from '@material-ui/core';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import _ from 'lodash';
// style
import './AppointmentDetailStyle.scss';
// component
import {
  NavigationStep,
  SnackBarSimple,
  AuthenticationAccessPages,
  SkeletonMain,
} from '../../../../components/Index';
import { StepBookingAddProduct, StepBookingSummary } from './views/Index';
// Api
import { submitOpenBill, getConsultationOrderDetails } from '../../../../services/api/OrdersApi';
// helper
import {
  CommonHelper,
  PermissionModule,
  PermissionPage,
  PermissionAccess,
  productTypeForOrderCode,
  paymentMethodOptionCode,
} from '../../../../helpers/Index';
import { orderStatusCode } from '../../../../helpers/MasterDataHelper';

const optionToast = {
  vertical: 'top',
  horizontal: 'right',
};

class AppointmentDetail extends React.Component {
  constructor(props) {
    super(props);

    const {
      match: { params },
    } = this.props;

    props.checkUserAccessPermission(
      PermissionModule.Orders,
      PermissionPage.List,
      PermissionAccess.View,
    );

    const updateForm = {
      ...CommonHelper.decryptObject(params.param_object),
      orderType: productTypeForOrderCode.medic,
      currentItems: [],
      items: [],
    };

    this.state = {
      current: 0,
      form: updateForm,
      stepList: [
        { key: 'appointment_add_product', name: 'Add Product' },
        { key: 'appointment_summary', name: 'Summary' },
      ],
      toastInformation: {
        isOpen: false,
        message: '',
        snackbarType: 'warning',
      },
      isLoading: true,
    };
  }

  componentDidMount() {
    const { form } = this.state;
    this.getConsultationOrderDetailsApi(form.sales_order_id);
  }

  getConsultationOrderDetailsApi = async salesOrderId => {
    const { callGetConsultationDetailsApi } = this.props;
    const { form } = this.state;
    try {
      const { data } = await callGetConsultationDetailsApi(salesOrderId);
      const { additional_items: additionalItems, consultation_details: consultationDetails } = data;
      const consultationItem = [
        {
          product_id: consultationDetails.product_id,
          qty: 1,
          training_schedule_id: consultationDetails.training_schedule_id,
          details: {
            trainer_id:
              (consultationDetails.doctor_details &&
                consultationDetails.doctor_details.trainer_id) ||
              null,
          },
        },
      ];
      const items = additionalItems.map(item => {
        if (item.type === 'workout_session') {
          return {
            sales_order_item_id: item.sales_order_item_id,
            product_id: item.product_id,
            qty: 1,
            training_schedule_id: item.details.training_schedule_id,
            details: {
              trainer_id: item.details.trainer.trainer_id,
            },
          };
        }
        return {
          sales_order_item_id: item.sales_order_item_id,
          product_id: item.product_id,
          qty: 1,
          details: {
            start_date: item.details.start_date,
            recurring: item.details.recurring,
          },
        };
      });
      const currentItems = [...consultationItem, ...items];
      let orderItems = [consultationDetails, ...additionalItems];
      orderItems = orderItems.map(item => {
        const isConsultation = item.type === 'consultation';
        const details = isConsultation ? item.doctor_details : item.details;
        return { ...item, quantity: 1, details };
      });
      this.setState({
        isLoading: false,
        form: {
          ...form,
          currentItems,
          items: orderItems,
        },
      });
    } catch (error) {
      const message = error.messages;
      this.processMessage(message, 'error');
      this.setState({
        isLoading: false,
      });
    }
  };

  handleClick = (event, value) => {
    const { history } = this.props;
    event.preventDefault();
    history.push(value);
  };

  handleCloseToast = () => {
    const { toastInformation } = this.state;
    this.setState({ toastInformation: { ...toastInformation, isOpen: false } });
  };

  handleButtonClickCancel = () => {
    const { history } = this.props;
    history.push('/medic/consultation');
  };

  handleButtonClickNext = value => {
    this.setState({ current: value });
  };

  handleButtonClickPrev = value => {
    this.setState({ current: value });
  };

  handleButtonClickSubmit = async () => {
    const { callSubmitOrder, history } = this.props;
    const { form } = this.state;
    const itemToSubmit = form.step_booking_summary;
    const products = form.currentItems;
    const modifyPayment = this.getPaymentType(itemToSubmit);

    try {
      const params = {
        promo_code: itemToSubmit.promoCode,
        items: products,
        payment_data: modifyPayment,
      };
      await callSubmitOrder(form.sales_order_id, params);
      this.processMessage('Order Successfully Saved', 'success');
      setTimeout(async () => {
        history.push('/medic/consultation');
      }, 2000);
    } catch (error) {
      const errors = error.data;
      this.processMessage(errors.messages, 'error');
    }
  };

  handleParameterUpdateStepBookingAddProduct = (formValue, key) => {
    const { form } = this.state;
    this.setState({
      form: {
        ...form,
        [key]: formValue,
      },
    });
  };

  handleParameterUpdateStepBookingSummary = (formValue, key) => {
    const { form } = this.state;
    this.setState({
      form: {
        ...form,
        [key]: formValue,
      },
    });
  };

  handleSuccessAddProduct = () => {
    const { form } = this.state;
    this.getConsultationOrderDetailsApi(form.sales_order_id);
  };

  getPaymentType = items => {
    let modifyPayment = null;
    switch (items.paymentMethod) {
      case paymentMethodOptionCode.bank_transfer:
        modifyPayment = {
          payment_type: items.paymentMethod,
          bank: items.bank.value,
        };
        break;
      case paymentMethodOptionCode.credit_card:
        modifyPayment = {
          payment_type: items.paymentMethod,
          payment_channel: 'xendit',
          payment_status: 0,
        };
        break;
      default:
        modifyPayment = {
          payment_type: items.paymentMethod,
        };
        break;
    }

    return modifyPayment;
  };

  formatItemSubmit = dataArray => {
    const converted = [];
    dataArray.forEach(item => {
      const param = {
        qty: item.qty || item.quantity,
        product_id: item.product_id,
      };

      if (item.training_schedule_id || item.details.training_schedule_id) {
        param.training_schedule_id = item.training_schedule_id || item.details.training_schedule_id;
      }

      if (
        item.trainer_id ||
        item.details.trainer_id ||
        (item.details.trainer && item.details.trainer.trainer_id)
      ) {
        param.details = {
          trainer_id:
            item.trainer_id ||
            item.details.trainer_id ||
            (item.details.trainer && item.details.trainer.trainer_id),
        };
      }

      converted.push(param);
    });
    const result = converted;
    return result;
  };

  processMessage(messages, type) {
    const convertedMessage = CommonHelper.generateMessage(messages);

    const paramInformation = {
      isOpen: true,
      message: convertedMessage,
      snackbarType: type,
    };

    this.setState({
      toastInformation: paramInformation,
    });
  }

  renderActiveContent() {
    const {
      orderData: { consultationDetails },
    } = this.props;
    const { stepList, current, form } = this.state;
    let renderElement = null;
    const orderDetailsData = !_.isEmpty(consultationDetails)
      ? consultationDetails.order_details
      : {};
    const isDraft = orderDetailsData.order_status === orderStatusCode.Draft;

    switch (stepList[current].key) {
      case 'appointment_summary':
        renderElement = (
          <Grid item lg md>
            <StepBookingSummary
              parentForm={form}
              totalStepper={stepList.length}
              currentValue={current}
              onParameterUpdate={this.handleParameterUpdateStepBookingSummary}
              onButtonClickCancel={this.handleButtonClickCancel}
              onButtonClickPrev={this.handleButtonClickPrev}
              onButtonClickSubmit={this.handleButtonClickSubmit}
            />
          </Grid>
        );
        break;
      default:
        renderElement = (
          <Grid item lg md>
            <StepBookingAddProduct
              parentForm={form}
              totalStepper={stepList.length}
              currentValue={current}
              isDraft={isDraft}
              onButtonClickCancel={this.handleButtonClickCancel}
              onButtonClickPrev={this.handleButtonClickPrev}
              onButtonClickNext={this.handleButtonClickNext}
              onParameterUpdate={this.handleParameterUpdateStepBookingAddProduct}
              onSuccessAddProduct={this.handleSuccessAddProduct}
            />
          </Grid>
        );
        break;
    }

    return renderElement;
  }

  render() {
    const { current, stepList, toastInformation, isLoading } = this.state;
    const prevUrl = '/medic/consultation';

    let renderElement = <SkeletonMain />;

    if (!isLoading) {
      renderElement = (
        <div>
          <Helmet title="FITCO | Move - Create Booking" />
          <div className="container-page-membership-purchase-add scroll-container-invisible">
            <div className="container-page-scrolling-area">
              <Grid container direction="column">
                <Grid item lg md className="section-page-header mv-24">
                  <div className="breadcrumbs-section">
                    <Breadcrumbs aria-label="breadcrumb">
                      <Link
                        className="text-12"
                        color="inherit"
                        href={prevUrl}
                        onClick={event => {
                          this.handleClick(event, prevUrl);
                        }}
                      >
                        <i className="ic-ffo-calendar container-icon-prefix size-16" />
                        Medic
                      </Link>
                      <label className="text-12" color="inherit">
                        Medic Appointment Book
                      </label>
                    </Breadcrumbs>
                  </div>
                </Grid>
                <Grid item lg md className="section-page-body">
                  <div className="flex-column container-main-card p-32">
                    <label className="text-16 text-bold text-rolling-stone  mb-32">
                      Create Booking
                    </label>
                    <div className="content-navigation-step-custom">
                      <NavigationStep currentValue={current} arrayLabel={stepList} />
                    </div>
                    {this.renderActiveContent()}
                  </div>
                </Grid>
                <Grid item lg md className="section-page-footer" />
              </Grid>
            </div>
          </div>
          <SnackBarSimple
            open={toastInformation.isOpen}
            durationHide={2000}
            message={toastInformation.message}
            onClickClose={this.handleCloseToast}
            snackbarType={toastInformation.snackbarType}
            anchor={optionToast}
          />
        </div>
      );
    }
    return renderElement;
  }
}

const mapDispatchToProps = dispatch => ({
  callSubmitOrder: (salesOrderID, params) => submitOpenBill(dispatch, salesOrderID, params),
  callGetConsultationDetailsApi: salesOrderId =>
    getConsultationOrderDetails(dispatch, salesOrderId),
});

const mapStateToProps = ({ orderData }) => ({ orderData });

AppointmentDetail.propTypes = {
  callGetConsultationDetailsApi: PropTypes.func,
  callSubmitOrder: PropTypes.func,
  checkUserAccessPermission: PropTypes.func,
  history: PropTypes.object,
  match: PropTypes.object,
  orderData: PropTypes.object,
};

const shell = compose(connect(mapStateToProps, mapDispatchToProps));

export default shell(AuthenticationAccessPages(AppointmentDetail));
