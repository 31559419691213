import React from 'react';
import { connect } from 'react-redux';
import { Grid, FormControl, FormLabel, Fade } from '@material-ui/core';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { compose } from 'redux';
// component
import {
  TextInput,
  ButtonMain,
  PrevNextStepperCount,
  RadioInput,
  LabelAlert,
} from '../../../../../../../components/Index';
import UserCheckField from '../../../../../../../components/higher-order-components/UserCheckField';
// helper
import {
  CommonHelper,
  MasterDataHelper,
  ValidationHelper,
} from '../../../../../../../helpers/Index';
// api
import { getMembershipPurchaseDetailsByPhone } from '../../../../../../../services/api/MembershipApi';
// style
import './StepMembershipPurchaseAddUserDetailsStyle.scss';

const genderOptions = MasterDataHelper.gender;

const initialValidation = {
  phone: { isError: false, errorMessage: '' },
  firstName: { isError: false, errorMessage: '' },
  lastName: { isError: false, errorMessage: '' },
  email: { isError: false, errorMessage: '' },
  labelAlert: { isError: '', errorMessage: '' },
};

const initialForm = {
  isExisting: true,
  userId: null,
  phone: '',
  firstName: '',
  lastName: '',
  email: '',
  gender: genderOptions[0],
};

const timeInterval = 300;

class StepMembershipPurchaseAddUserDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      form: CommonHelper.objectCloning(initialForm),
      validation: CommonHelper.objectCloning(initialValidation),
    };
    this.searchPhoneDebounce = _.debounce(this.searchPhoneDebounce, 400);
    this.searchEmailDebounce = _.debounce(this.searchEmailDebounce, 400);
  }

  componentDidMount() {
    const { parentForm, parentValidation } = this.props;
    const { form, validation } = this.state;

    this.setState(
      {
        form: { ...form, ...parentForm },
        validation: { ...validation, ...parentValidation },
      },
      () => {
        if (parentForm.phone) {
          this.searchPhoneDebounce();
        }
      },
    );
  }

  componentWillReceiveProps(nextProps) {
    const { validation } = this.state;

    const isValidationChange = _.isEqual(validation, nextProps.parentValidation);

    if (!isValidationChange) {
      const finalMergeValidation = { ...validation, ...nextProps.parentValidation };
      this.setState({
        validation: finalMergeValidation,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.parentForm !== prevProps.parentForm && this.props.parentForm) {
      this.updateForm(this.props.parentForm);
    }
  }

  updateForm = parentForm => {
    const { form } = this.state;
    this.setState(
      {
        form: { ...form, ...parentForm },
      },
      () => {
        if (parentForm.phone) {
          this.searchPhoneDebounce();
        }
      },
    );
  };

  searchPhoneDebounce = async () => {
    const { membershipPurchaseDetailsByPhone, onPopUpInformation, parentForm } = this.props;
    const {
      form: { phone },
      form,
      validation,
    } = this.state;
    const formNewValue = CommonHelper.objectCloning(form);
    const resetValidation = CommonHelper.objectCloning(validation);
    const fullResetValidation = CommonHelper.objectCloning(initialValidation);
    const modifyLabeMessage = CommonHelper.objectCloning(initialValidation.labelAlert);

    const param = { phone };

    const checkPhoneFormat = ValidationHelper.validatePhone(phone.trim());
    const message = CommonHelper.objectCloning(initialValidation.phone);

    if (phone === '' || phone === undefined || !checkPhoneFormat) {
      message.isError = true;
      message.errorMessage = 'Please enter Phone or wrong Phone Format';
    }

    await membershipPurchaseDetailsByPhone(param)
      .then(response => {
        const data = response.data;

        if (!_.isEmpty(data.user_details)) {
          formNewValue.isExisting = data.is_user_exists;
          formNewValue.userId = data.user_details.user_id;
          formNewValue.firstName = data.user_details.first_name;
          formNewValue.lastName = data.user_details.last_name || '';
          formNewValue.email = data.user_details.email || '';
          formNewValue.gender = _.find(genderOptions, ['value', data.user_details.gender]);

          modifyLabeMessage.errorMessage = `Yay! We found matching the user with the phone: '${phone}'`;
          modifyLabeMessage.isError = `success`;

          resetValidation.firstName = fullResetValidation.firstName;
          resetValidation.lastName = fullResetValidation.lastName;
          resetValidation.email = fullResetValidation.email;
          resetValidation.isReseller = fullResetValidation.isReseller;
          resetValidation.note = fullResetValidation.note;
        } else if (data.is_required_new_number) {
          message.isError = true;
          message.errorMessage = "Phone Can't be use, change another Number";
          resetValidation.phone = message;
        } else {
          const resetData = CommonHelper.objectCloning({ ...initialForm, ...parentForm });
          formNewValue.isExisting = data.is_user_exists;
          formNewValue.userId = resetData.userId;
          formNewValue.firstName = resetData.firstName;
          formNewValue.lastName = resetData.lastName;
          formNewValue.email = resetData.email;
          formNewValue.gender = resetData.gender;

          modifyLabeMessage.errorMessage = `No matching user found with the phone: '${phone}', please complete the details`;
          modifyLabeMessage.isError = `error`;
        }

        resetValidation.phone = message;
        resetValidation.labelAlert = modifyLabeMessage;

        this.setState(
          {
            form: formNewValue,
            validation: resetValidation,
          },
          () => {
            if (!data.is_user_exists) {
              this.searchEmailDebounce();
            }
          },
        );
      })
      .catch(error => {
        const serverMessage = error.data;
        onPopUpInformation(serverMessage.messages, 'error');
      });
  };

  searchEmailDebounce = () => {
    const { checkUserPhoneOrEmail } = this.props;
    const { validation } = this.state;
    const {
      form: { email },
    } = this.state;

    const param = {
      data: {
        email,
      },
    };

    checkUserPhoneOrEmail(param).catch(error => {
      const message = CommonHelper.objectCloning(initialValidation.email);
      const convertedMessage = CommonHelper.generateMessage(error.message);
      message.isError = true;
      message.errorMessage = convertedMessage;

      this.setState({ validation: { ...validation, email: message } });
    });
  };

  handleChangePhone = value => {
    const { form } = this.state;
    const checkPhoneFormat = ValidationHelper.validatePhone(value.trim());

    this.setState(
      {
        form: { ...form, phone: value },
      },
      () => {
        if (checkPhoneFormat) {
          this.searchPhoneDebounce();
        }
      },
    );
  };

  handleChangeFirstName = value => {
    const { form, validation } = this.state;
    const message = CommonHelper.objectCloning(initialValidation.firstName);

    if (value === '' || value === undefined) {
      message.isError = true;
      message.errorMessage = 'Please enter First Name';
    }

    this.setState({
      form: { ...form, firstName: value },
      validation: { ...validation, firstName: message },
    });
  };

  handleChangeLastName = value => {
    const { form, validation } = this.state;
    const message = CommonHelper.objectCloning(initialValidation.lastName);

    if (value === '' || value === undefined) {
      message.isError = true;
      message.errorMessage = 'Please enter Last Name';
    }

    this.setState({
      form: { ...form, lastName: value },
      validation: { ...validation, lastName: message },
    });
  };

  handleChangeEmail = value => {
    const { form, validation } = this.state;
    const message = CommonHelper.objectCloning(initialValidation.email);
    const checkerEmailFormat = ValidationHelper.validateEmail(value.trim());

    if (value === '' || value === undefined || !checkerEmailFormat) {
      message.isError = true;
      message.errorMessage = 'Please enter Email, or wrong Email Format';
    }

    this.setState(
      {
        form: { ...form, email: value },
        validation: { ...validation, email: message },
      },
      () => {
        if (!form.isExisting && checkerEmailFormat) {
          this.searchEmailDebounce();
        }
      },
    );
  };

  handleChangeGender = value => {
    const { form } = this.state;

    this.setState({ form: { ...form, gender: value } });
  };

  handleNextClick = async () => {
    const { onNextClick, onParameterUpdate } = this.props;
    const { form, validation } = this.state;
    const formNewValue = CommonHelper.objectCloning(form);
    const validationNewValue = CommonHelper.objectCloning(validation);

    await onParameterUpdate(formNewValue, validationNewValue);
    await onNextClick();
  };

  render() {
    const {
      validation,
      form: { isExisting, phone, firstName, lastName, email, gender },
      isResellerLogin,
    } = this.state;
    const {
      onButtonClickCancel,
      membership: { fetching },
    } = this.props;
    const modifyIsExisting = phone === '' ? true : isExisting;

    return (
      <Grid container className="container-step-membership-user-details">
        <Grid item lg={12} md={12} className="mt-32">
          <Grid container spacing={2}>
            <Grid item lg={6} md={6}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <FormLabel component="label" className="text-12 filed-title">
                  Phone Number
                </FormLabel>
                <TextInput
                  placeHolderText="Phone Number"
                  size="md"
                  onChange={this.handleChangePhone}
                  currentValue={phone}
                  errorMessage={validation.phone.errorMessage}
                  isError={validation.phone.isError}
                  isLoading={fetching}
                  isDisable={isResellerLogin}
                  maxLength={14}
                />
              </FormControl>
            </Grid>
            <Fade
              in={phone !== '' && validation.labelAlert.errorMessage !== ''}
              timeout={timeInterval}
            >
              <Grid item lg={6} md={6} className="mt-43">
                <LabelAlert
                  message={validation.labelAlert.errorMessage}
                  type={validation.labelAlert.isError}
                />
              </Grid>
            </Fade>
          </Grid>
        </Grid>
        <Grid item lg={12} md={12}>
          <Grid container spacing={2}>
            <Grid item lg={6} md={6}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <FormLabel component="label" className="text-12 filed-title">
                  First Name
                </FormLabel>
                <TextInput
                  placeHolderText="First Name"
                  size="md"
                  onChange={this.handleChangeFirstName}
                  currentValue={firstName}
                  errorMessage={validation.firstName.errorMessage}
                  isError={validation.firstName.isError}
                  isDisable={modifyIsExisting}
                  maxLength={25}
                />
              </FormControl>
            </Grid>
            <Grid item lg={6} md={6}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <FormLabel component="label" className="text-12 filed-title">
                  Last Name
                </FormLabel>
                <TextInput
                  placeHolderText="Last Name"
                  size="md"
                  onChange={this.handleChangeLastName}
                  currentValue={lastName}
                  errorMessage={validation.lastName.errorMessage}
                  isError={validation.lastName.isError}
                  isDisable={modifyIsExisting}
                  maxLength={25}
                />
              </FormControl>
            </Grid>
            <Grid item lg={6} md={6}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <FormLabel component="label" className="text-12 filed-title">
                  Email
                </FormLabel>
                <TextInput
                  placeHolderText="Email"
                  size="md"
                  onChange={this.handleChangeEmail}
                  currentValue={email}
                  errorMessage={validation.email.errorMessage}
                  isError={validation.email.isError}
                  isDisable={modifyIsExisting}
                  maxLength={100}
                />
              </FormControl>
            </Grid>
            <Grid item lg={6} md={6}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <FormLabel component="label" className="text-12 filed-title">
                  Gender
                </FormLabel>
                <RadioInput
                  data={genderOptions}
                  onSelect={this.handleChangeGender}
                  direction="column"
                  currentValue={gender}
                  isDisable={modifyIsExisting}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={12} md={12}>
          <Grid container justify="flex-end">
            <Grid item lg={5} md={6}>
              <Grid container justify="flex-end">
                <Grid item lg={4} md={4} className="pl-8">
                  <ButtonMain
                    type="negative"
                    size="xl"
                    labelText="Cancel"
                    onClick={onButtonClickCancel}
                  />
                </Grid>
                <Grid item lg={4} md={4} className="pl-8">
                  <ButtonMain
                    type="primary"
                    size="xl"
                    labelText="Next"
                    onClick={this.handleNextClick}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  membershipPurchaseDetailsByPhone: params => getMembershipPurchaseDetailsByPhone(dispatch, params),
});

const mapStateToProps = ({ membership }) => ({ membership });

StepMembershipPurchaseAddUserDetails.propTypes = {
  checkUserPhoneOrEmail: PropTypes.func,
  membership: PropTypes.object,
  membershipPurchaseDetailsByPhone: PropTypes.func,
  onButtonClickCancel: PropTypes.func,
  onNextClick: PropTypes.func,
  onParameterUpdate: PropTypes.func,
  onPopUpInformation: PropTypes.func,
  parentForm: PropTypes.object,
  parentValidation: PropTypes.object,
};

const shell = compose(connect(mapStateToProps, mapDispatchToProps));
const core = compose(UserCheckField, PrevNextStepperCount);

export default shell(core(StepMembershipPurchaseAddUserDetails));
