/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Grid, FormControl, FormLabel } from '@material-ui/core';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
// Components
import {
  TextInput,
  PickerInputDate,
  RadioInput,
  SelectInputMain,
  SelectInputGeneral,
  UploadImage,
  CheckInputOutlineBackground,
  ButtonMain,
  TextInputAutocomplete,
} from '../../../../../../../components/Index';
// Helpers
import { CommonHelper, MasterDataHelper } from '../../../../../../../helpers/Index';

const currentDate = CommonHelper.currentDate('YYYY-MM-DD');
const defaultBirthday = currentDate;
const genderOptions = MasterDataHelper.gender;
const idTypeOptions = MasterDataHelper.idTypeOption;

const initialForm = {
  product_id: undefined,
  details: {},
  identity_details: {
    birthday: defaultBirthday,
    country_id: 1,
    residential_address: '',
    gender: 1,
    identity_address: '',
    identity_number: '',
    identity_photo: '',
    identity_type: 'ktp',
    name: '',
    is_primary: false,
    user_identity_id: null,
    product_id: undefined,
  },
  imageBase64: '',
  gender: genderOptions[0],
  productDetails: {},
  countryDetails: { code: 'ID', country_id: 1, name: 'Indonesia' },
};

const initialValidation = {
  name: { isError: false, errorMessage: '' },
  residential_address: { isError: false, errorMessage: '' },
  identity_address: { isError: false, errorMessage: '' },
  identity_number: { isError: false, errorMessage: '' },
  product_id: { isError: false, errorMessage: '' },
};

const onValidationHandler = payload => {
  let validationNewValue = CommonHelper.objectCloning(initialValidation);
  const excludeValidationKeys = ['user_identity_id', 'identity_photo', 'is_primary'];

  const keys = Object.keys(payload).filter(key => {
    return excludeValidationKeys.indexOf(key) === -1;
  });

  const errorValidationKeys = Object.keys(validationNewValue).filter(key => {
    return excludeValidationKeys.indexOf(key) === -1;
  });

  const errorKeys = keys.filter(key => {
    return !payload[key];
  });

  errorValidationKeys.forEach(key => {
    const isError = errorKeys.indexOf(key) !== -1;
    let errorDetail = {};
    switch (key) {
      case 'name':
        errorDetail = { isError, errorMessage: isError ? 'Please fill customer name' : '' };
        break;
      case 'identity_number':
        errorDetail = { isError, errorMessage: isError ? 'Please fill ID number' : '' };
        break;
      case 'identity_address':
        errorDetail = { isError, errorMessage: isError ? 'Please fill ID address' : '' };
        break;
      case 'residential_address':
        errorDetail = {
          isError,
          errorMessage: isError ? 'Please fill your residential address' : '',
        };
        break;
      case 'product_id':
        errorDetail = { isError, errorMessage: isError ? 'Please select test method' : '' };
        break;
      default:
        errorDetail = {
          isError,
          errorMessage: isError ? 'Please fill input' : '',
        };
        break;
    }
    validationNewValue = {
      ...validationNewValue,
      [key]: errorDetail,
    };
  });

  return { isError: Boolean(errorKeys.length), validationNewValue };
};

const AddUserDetailForm = ({
  callGetCustomerListApi,
  defaultValue,
  id,
  label,
  isPrimary,
  masterData: { countryList },
  onParameterUpdate,
  onRemove,
  onValidationUpdate,
  products,
  userDetail,
  validate,
  withRemove,
}) => {
  const [form, setForm] = useState(initialForm);
  const [validation, setValidation] = useState(initialValidation);
  const [useSameUserDetail, setUseSameUserDetail] = useState(undefined);
  const [isEqualAddress, setIsEqualAddress] = useState(undefined);
  const [fetching, setFetching] = useState(false);
  const [customerList, setCustomerList] = useState([]);

  const getMatchDataByKey = useCallback(
    (key, value) => {
      let findMatchData;
      switch (key) {
        case 'product_id':
          findMatchData = products.find(item => item.product_id === value);
          break;
        case 'country_id':
          findMatchData = countryList.find(item => item.country_id === value);
          break;

        default:
          break;
      }
      return findMatchData;
    },
    [products, countryList],
  );

  const sendQuery = async keyword => {
    try {
      setFetching(true);
      const body = {
        user_id: userDetail.userId,
        search_term: keyword,
      };
      const response = await callGetCustomerListApi(body);
      setCustomerList(response.data);
      setFetching(false);
    } catch (error) {
      setFetching(false);
    }
  };

  const delayedQuery = useRef(debounce(q => sendQuery(q), 500)).current;

  useEffect(() => {
    if (defaultValue.product_id) {
      const isPrimarySelected = defaultValue.identity_details.is_primary;
      const isSameCurrentAddress =
        defaultValue.identity_details.residential_address ===
        defaultValue.identity_details.identity_address;
      if (isPrimarySelected) {
        setForm({
          ...defaultValue,
          identity_details: {
            ...defaultValue.identity_details,
            name: `${userDetail.firstName} ${userDetail.lastName}`,
            birthday: userDetail.birthday,
            gender: userDetail.gender.value,
          },
          gender: userDetail.gender,
        });
      } else {
        setForm(defaultValue);
      }

      if (isPrimarySelected) {
        setUseSameUserDetail(isPrimarySelected);
      }
      if (isSameCurrentAddress) {
        setIsEqualAddress(isSameCurrentAddress);
      }
    }
  }, [defaultValue]);

  useEffect(() => {
    if (validate) {
      const error = onValidationHandler(form.identity_details);
      setValidation(error.validationNewValue);
      const payload = {
        id,
        label,
        ...error,
      };
      onValidationUpdate(id, payload);
    }
  }, [validate]);

  useEffect(() => {
    const payload = {
      id,
      label,
      isPrimary,
      ...form,
    };
    onParameterUpdate(id, payload);
  }, [form]);

  useEffect(() => {
    const countryDetails =
      getMatchDataByKey('country_id', form.identity_details.country_id) ||
      initialForm.countryDetails;
    setForm(oldForm => ({
      ...oldForm,
      countryDetails,
    }));
  }, [form.identity_details.country_id]);

  const onChangeHandler = (value, key) => {
    setForm(oldForm => ({
      ...oldForm,
      identity_details: {
        ...oldForm.identity_details,
        [key]: value,
      },
    }));
  };

  const onChangePrimaryDetailHandler = value => {
    let identityDetails = { ...initialForm.identity_details };
    let gender = initialForm.gender;
    let isSameCurrentAddress = false;

    if (value && userDetail) {
      const userIdentityExist = userDetail.userIdentity;
      isSameCurrentAddress =
        userIdentityExist &&
        userIdentityExist.residential_address === userIdentityExist.identity_address;

      identityDetails = {
        birthday: userDetail.birthday || defaultBirthday,
        country_id: (userIdentityExist && userIdentityExist.country_id) || 1,
        residential_address: (userIdentityExist && userIdentityExist.residential_address) || '',
        gender: (userIdentityExist && userIdentityExist.gender) || userDetail.gender.value || 1,
        identity_address: (userIdentityExist && userIdentityExist.identity_address) || '',
        identity_number: (userIdentityExist && userIdentityExist.identity_number) || '',
        identity_photo: (userIdentityExist && userIdentityExist.identity_photo_url) || '',
        identity_type: (userIdentityExist && userIdentityExist.identity_type) || 'ktp',
        name: `${userDetail.firstName} ${userDetail.lastName}`,
        is_primary: value,
        user_identity_id: (userIdentityExist && userIdentityExist.user_identity_id) || null,
        product_id:
          (defaultValue.product_id && defaultValue.identity_details.product_id) || undefined,
      };
      gender = (defaultValue.product_id && defaultValue.gender) || userDetail.gender;
    }

    setForm(oldForm => ({
      ...oldForm,
      identity_details: identityDetails,
      imageBase64: identityDetails.identity_photo,
      gender,
    }));
    setUseSameUserDetail(value);
    setIsEqualAddress(isSameCurrentAddress);
  };

  const onChangeGenderHandler = gender => {
    setForm(oldForm => ({
      ...oldForm,
      identity_details: {
        ...oldForm.identity_details,
        gender: gender.value,
      },
      gender,
    }));
  };

  const onChangeImageHandler = imageBase64 => {
    setForm(oldForm => ({
      ...oldForm,
      identity_details: {
        ...oldForm.identity_details,
        identity_photo: imageBase64,
      },
      imageBase64,
    }));
  };

  const onChangeProductHandler = value => {
    const productDetails = getMatchDataByKey('product_id', value);

    setForm(oldForm => ({
      ...oldForm,
      identity_details: {
        ...oldForm.identity_details,
        product_id: value,
      },
      product_id: value,
      productDetails: { ...productDetails, qty: 1 },
    }));
  };

  const onChangeCurrentAddressHandler = value => {
    let residentialAddress = '';
    if (value) {
      residentialAddress = form.identity_details.identity_address;
    }

    setForm(oldForm => ({
      ...oldForm,
      identity_details: {
        ...oldForm.identity_details,
        residential_address: residentialAddress,
      },
    }));
    setIsEqualAddress(value);
  };

  const onChangeIdentityType = value => {
    setForm(oldForm => ({
      ...oldForm,
      identity_details: {
        ...oldForm.identity_details,
        identity_type: value,
        identity_number: '',
      },
    }));
  };

  const onRemoveHandler = bookingId => {
    onRemove(bookingId);
  };

  const onSearchCustomerHandler = value => {
    setForm(oldForm => ({
      ...oldForm,
      identity_details: {
        ...oldForm.identity_details,
        name: value,
      },
    }));
    delayedQuery(value);
  };

  const onSelectedCustomerHandler = (value, option) => {
    const isSameCurrentAddress = option.residential_address === option.identity_address;
    const identityDetails = {
      birthday: option.birthday || defaultBirthday,
      country_id: option.country_id,
      residential_address: option.residential_address,
      gender: option.gender || userDetail.gender.value,
      identity_address: option.identity_address,
      identity_number: option.identity_number,
      identity_photo: option.identity_photo_url,
      identity_type: option.identity_type,
      name: option.name,
      is_primary: false,
      user_identity_id: option.user_identity_id,
      product_id: undefined,
    };
    const payload = {
      ...initialForm,
      imageBase64: identityDetails.identity_photo,
      identity_details: identityDetails,
    };
    setForm(payload);
    if (isSameCurrentAddress) {
      setIsEqualAddress(isSameCurrentAddress);
    }
  };

  const renderInputName = () => {
    const userIdExist = userDetail.userId;
    if (userIdExist) {
      return (
        <TextInputAutocomplete
          placeHolderText="Name"
          size="md"
          onChange={onSearchCustomerHandler}
          onSelect={onSelectedCustomerHandler}
          currentValue={form.identity_details.name}
          isLoading={fetching}
          data={customerList}
          isCustomDisplay
          customDisplayClass="custom-simple-option-customer-item"
          errorMessage={validation.name.errorMessage}
          isError={validation.name.isError}
          isDisable={useSameUserDetail}
        />
      );
    }
    return (
      <TextInput
        placeHolderText="Name"
        size="md"
        onChange={value => onChangeHandler(value, 'name')}
        currentValue={form.identity_details.name}
        errorMessage={validation.name.errorMessage}
        isError={validation.name.isError}
        isDisable={useSameUserDetail}
      />
    );
  };

  return (
    <Grid item lg={12} md={12}>
      <Grid container spacing={3}>
        <Grid item lg={12} md={12} className="mb-4 mt-32">
          <label className="text-12 text-bold text-rolling-stone">{label}</label>
        </Grid>
      </Grid>
      <Grid container spacing={3} alignItems="center">
        <Grid item lg={6} md={6}>
          <FormControl component="fieldset" fullWidth margin="normal">
            <CheckInputOutlineBackground
              labelText="This customer same as user details"
              onCheck={value => onChangePrimaryDetailHandler(value)}
              currentValue={useSameUserDetail}
            />
          </FormControl>
        </Grid>
        {withRemove && (
          <Grid item lg={6} md={6}>
            <ButtonMain
              type="negative"
              size="md"
              labelText="Remove this customer"
              onClick={() => onRemoveHandler(id)}
              startIcon="ic-ffo-bin"
            />
          </Grid>
        )}
      </Grid>
      <Grid container spacing={3}>
        <Grid item lg={6} md={6}>
          <FormControl component="fieldset" fullWidth margin="normal">
            <FormLabel component="label" className="text-12 filed-title">
              Name
            </FormLabel>
            {renderInputName()}
          </FormControl>
        </Grid>
        <Grid item lg={6} md={6}>
          <FormControl component="fieldset" fullWidth margin="normal">
            <FormLabel component="label" className="text-12 filed-title">
              Test Method
            </FormLabel>
            <div className="container-remove-margin">
              <SelectInputGeneral
                placeHolder="Select Test Method"
                currentValue={form.product_id}
                dataList={products}
                primaryKey="product_id"
                onChange={value => onChangeProductHandler(value)}
                errorMessage={validation.product_id.errorMessage}
                validateStatus={validation.product_id.isError ? 'error' : ''}
              />
            </div>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item lg={6} md={6}>
          <FormControl component="fieldset" fullWidth margin="normal">
            <FormLabel component="label" className="text-12 filed-title">
              Identity Type
            </FormLabel>
            <SelectInputMain
              options={idTypeOptions}
              currentValue={form.identity_details.identity_type}
              onChange={value => onChangeIdentityType(value)}
            />
          </FormControl>
        </Grid>
        <Grid item lg={6} md={6}>
          <Grid container spacing={3}>
            <Grid item lg={6} md={6}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <FormLabel component="label" className="text-12 filed-title">
                  Date of Birth
                </FormLabel>
                <PickerInputDate
                  customIcon="ic-ffo-date-pick"
                  dateFormat="dd-MM-yyyy"
                  maxDate={currentDate}
                  defaultValue={form.identity_details.birthday}
                  onChange={value => onChangeHandler(value, 'birthday')}
                  toolbar={false}
                  disabled={useSameUserDetail}
                />
              </FormControl>
            </Grid>
            <Grid item lg={6} md={6}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <FormLabel component="label" className="text-12 filed-title">
                  Gender
                </FormLabel>
                <RadioInput
                  data={genderOptions}
                  onSelect={value => onChangeGenderHandler(value)}
                  direction="column"
                  currentValue={form.gender}
                  isDisable={useSameUserDetail}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item lg={6} md={6}>
          <FormControl component="fieldset" fullWidth margin="normal">
            <FormLabel component="label" className="text-12 filed-title">
              Identity Number
            </FormLabel>
            <TextInput
              placeHolderText="Please enter your Identity Number"
              size="md"
              onChange={value => onChangeHandler(value, 'identity_number')}
              currentValue={form.identity_details.identity_number}
              maxLength={
                form.identity_details.identity_type === 'ktp' ||
                form.identity_details.identity_type === 'kia'
                  ? 16
                  : 10
              }
              type={form.identity_details.identity_type === idTypeOptions[0].value ? 'tel' : 'text'}
              errorMessage={validation.identity_number.errorMessage}
              isError={validation.identity_number.isError}
              numericOnly={form.identity_details.identity_type !== 'passport'}
            />
          </FormControl>
        </Grid>
        <Grid item lg={6} md={6}>
          <FormControl component="fieldset" fullWidth margin="normal">
            <FormLabel component="label" className="text-12 filed-title">
              Nationality
            </FormLabel>
            <div className="container-remove-margin">
              <SelectInputGeneral
                placeHolder="Select Country"
                currentValue={form.identity_details.country_id}
                dataList={countryList}
                primaryKey="country_id"
                onChange={value => onChangeHandler(value, 'country_id')}
              />
            </div>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item lg={6} md={6}>
          <FormControl component="fieldset" fullWidth margin="normal">
            <FormLabel component="label" className="text-12 filed-title">
              Identity photo
            </FormLabel>
            <UploadImage
              onChange={item => onChangeImageHandler(item)}
              defaultValue={form.imageBase64}
              maxSize={5}
            />
          </FormControl>
        </Grid>
        <Grid item lg={6} md={6} className="mt-26">
          <FormControl component="fieldset" fullWidth margin="normal">
            <FormLabel component="label" className="text-12 filed-title">
              Address as shown in Identity Card
            </FormLabel>
            <TextInput
              placeHolderText="Enter your address as shown in Identity Card"
              size="md"
              onChange={value => onChangeHandler(value, 'identity_address')}
              currentValue={form.identity_details.identity_address}
              errorMessage={validation.identity_address.errorMessage}
              isError={validation.identity_address.isError}
            />
          </FormControl>
          <FormControl component="fieldset" fullWidth margin="normal">
            <CheckInputOutlineBackground
              labelText="Current residential address is same as identity card"
              onCheck={value => onChangeCurrentAddressHandler(value)}
              currentValue={isEqualAddress}
              disabled={!form.identity_details.identity_address}
            />
          </FormControl>
          <FormControl component="fieldset" fullWidth margin="normal">
            <FormLabel component="label" className="text-12 filed-title">
              Current Residential Address
            </FormLabel>
            <TextInput
              placeHolderText="Enter your current residential address"
              size="md"
              onChange={value => onChangeHandler(value, 'residential_address')}
              currentValue={form.identity_details.residential_address}
              errorMessage={validation.residential_address.errorMessage}
              isError={validation.residential_address.isError}
            />
          </FormControl>
        </Grid>
      </Grid>
    </Grid>
  );
};

AddUserDetailForm.propTypes = {
  callGetCustomerListApi: PropTypes.func,
  defaultValue: PropTypes.object,
  id: PropTypes.number,
  isPrimary: PropTypes.bool,
  label: PropTypes.string,
  masterData: PropTypes.object,
  onParameterUpdate: PropTypes.func,
  onRemove: PropTypes.func,
  onValidationUpdate: PropTypes.func,
  products: PropTypes.array,
  userDetail: PropTypes.object,
  validate: PropTypes.bool,
  withRemove: PropTypes.bool,
};

export default AddUserDetailForm;
