import React from 'react';
import PropTypes from 'prop-types';
import DateFnsUtils from '@date-io/date-fns';
import 'date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
// style
import './PickerInputDateStyle.scss';
// function
import { CommonHelper } from '../../../helpers/Index';

const PickerInputDate = props => {
  const {
    labelHeader,
    isError,
    errorMessage,
    toolbar,
    defaultValue,
    dateFormat,
    onChange,
    maxDate,
    minDate,
    customIcon,
    size,
    disabled,
    isDisableSunday,
    isDisableWeekend,
    customEnableDay,
  } = props;

  const [selectedDate, setSelectedDate] = React.useState(
    defaultValue ? new Date(defaultValue) : new Date(),
  );

  const currentDate = CommonHelper.currentDate('YYYY-MM-DD');
  const defaultMaxDate = CommonHelper.intervalDate(currentDate, 'YYYY-MM-DD', 1, 'year');
  const convertDateMax = maxDate ? new Date(maxDate) : new Date(defaultMaxDate);
  const convertDateMin = minDate ? new Date(minDate) : new Date('1900-01-01');

  React.useEffect(() => {
    const updateState = () => {
      if (defaultValue) {
        setSelectedDate(new Date(defaultValue));
      } else {
        setSelectedDate(new Date());
      }
    };
    updateState();
  }, [defaultValue]);

  const disableWeekends = date => {
    let conditon = false;
    if (isDisableWeekend) {
      conditon = date.getDay() === 0 || date.getDay() === 6;
    }
    if (isDisableSunday) {
      conditon = date.getDay() === 0;
    }
    if (customEnableDay) {
      conditon = date.getDay() !== parseInt(customEnableDay, 10);
    }
    return conditon;
  };

  const handleSelectChange = date => {
    const result = CommonHelper.dateTimeParseNewFormat(date, 'YYYY-MM-DD');
    setSelectedDate(date);
    onChange(result);
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        disableToolbar={toolbar}
        variant="inline"
        format={dateFormat}
        className={`custom-outline-size-date ${size}`}
        label={labelHeader}
        inputVariant="outlined"
        maxDate={convertDateMax}
        minDate={convertDateMin}
        value={selectedDate}
        onChange={handleSelectChange}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
        keyboardIcon={<i className={`${customIcon} container-icon-prefix ${size}`} />}
        autoOk
        disabled={disabled}
        shouldDisableDate={disableWeekends}
        error={isError}
        helperText={errorMessage}
      />
    </MuiPickersUtilsProvider>
  );
};

PickerInputDate.propTypes = {
  customEnableDay: PropTypes.string,
  customIcon: PropTypes.string,
  dateFormat: PropTypes.string,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  errorMessage: PropTypes.string,
  isDisableSunday: PropTypes.bool,
  isDisableWeekend: PropTypes.bool,
  isError: PropTypes.bool,
  labelHeader: PropTypes.string,
  maxDate: PropTypes.string,
  minDate: PropTypes.string,
  onChange: PropTypes.func,
  size: PropTypes.string,
  toolbar: PropTypes.bool,
};

PickerInputDate.defaultProps = {
  defaultValue: null,
  maxDate: null,
  minDate: null,
  size: 'md',
};

export default PickerInputDate;
