import React from 'react';
import PropTypes from 'prop-types';
import { TextField, IconButton, InputAdornment } from '@material-ui/core';
// style
import './TextInputPasswordStyle.scss';

const TextInputPassword = props => {
  const [values, setValues] = React.useState({
    password: '',
    showPassword: false,
  });
  const {
    labelHeader,
    defaultValue,
    isError,
    errorMessage,
    onChange,
    onEnter,
    iconPrefixStart,
    iconPrefixEnd,
    placeHolderText,
    size,
  } = props;

  const onChangeTextInput = event => {
    const result = event.target.value;
    onChange(result);
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const handleEnterTextInput = event => {
    if (event.key === 'Enter') onEnter();
  };

  const RenderButtonVisibility = () => {
    return iconPrefixEnd ? (
      <InputAdornment position="end">
        <IconButton
          aria-label="toggle password visibility"
          onClick={handleClickShowPassword}
          onMouseDown={handleMouseDownPassword}
          size="small"
          edge="end"
        >
          <i
            className={`${
              values.showPassword ? iconPrefixEnd.normal : iconPrefixEnd.hover
            } container-icon-prefix ${size}`}
          />
        </IconButton>
      </InputAdornment>
    ) : null;
  };

  return (
    <TextField
      className={`container-text-input-password ${size}`}
      label={labelHeader}
      size="small"
      type={values.showPassword ? 'text' : 'password'}
      defaultValue={defaultValue}
      helperText={errorMessage}
      placeholder={placeHolderText}
      variant="outlined"
      error={isError}
      InputProps={{
        startAdornment: iconPrefixStart ? (
          <InputAdornment position="start">
            <i className={`${iconPrefixStart} container-icon-prefix ${size}`} />
          </InputAdornment>
        ) : null,
        endAdornment: <RenderButtonVisibility />,
      }}
      onChange={onChangeTextInput}
      onKeyPress={onEnter ? handleEnterTextInput : null}
    />
  );
};

TextInputPassword.propTypes = {
  defaultValue: PropTypes.string,
  errorMessage: PropTypes.string,
  iconPrefixEnd: PropTypes.object,
  iconPrefixStart: PropTypes.string,
  isError: PropTypes.bool,
  labelHeader: PropTypes.string,
  onChange: PropTypes.func,
  onEnter: PropTypes.func,
  placeHolderText: PropTypes.string,
  size: PropTypes.string,
};

TextInputPassword.defaultProps = {
  size: 'xl',
};

export default TextInputPassword;
