/* eslint-disable no-case-declarations */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// import _ from 'lodash';
// api
import { checkUserPhoneOrEmailExist } from '../../services/api/UsersApi';
// helper
// import { PermissionHelper, HttpStatusCode, UserHelper } from '../../helpers/Index';

const UserCheckField = WrappedComponent => {
  class NewComponent extends React.Component {
    constructor(props) {
      super(props);

      this.state = {};
    }

    checkUserPhoneOrEmail = parameter => {
      const { userPhoneOrEmailExist } = this.props;

      return new Promise((resolve, reject) => {
        userPhoneOrEmailExist(parameter)
          .then(response => {
            const converted = {
              code: response.code,
              status: response.status,
              message: response.messages,
            };

            resolve(converted);
          })
          .catch(error => {
            const dataError = error.data;
            const converted = {
              code: dataError.code,
              status: dataError.status,
              message: dataError.messages,
            };

            reject(converted);
          });
      });
    };

    render() {
      return (
        <WrappedComponent checkUserPhoneOrEmail={this.checkUserPhoneOrEmail} {...this.props} />
      );
    }
  }

  const mapDispatchToProps = dispatch => ({
    userPhoneOrEmailExist: params => checkUserPhoneOrEmailExist(dispatch, params),
  });

  const mapStateToProps = ({ usersReducer }) => ({ usersReducer });

  NewComponent.propTypes = {
    history: PropTypes.object,
    userPhoneOrEmailExist: PropTypes.func,
  };

  return connect(mapStateToProps, mapDispatchToProps)(NewComponent);
};

export default UserCheckField;
