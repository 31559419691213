/* eslint-disable react/no-access-state-in-setstate */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Table } from 'antd';
import _ from 'lodash';
import { Grid } from '@material-ui/core';
// style
import './RequestPageStyle.scss';
// component
import {
  TextInput,
  SelectInputMain,
  SnackBarSimple,
  SelectInputBranch,
  PickerInputDate,
  AuthenticationAccessPages,
  PrevStateValue,
  SkeletonMain
} from '../../../../../components/Index';
// api
import { getMembershipRequestPagination } from '../../../../../services/api/MembershipApi';
// helper
import {
  MasterDataHelper,
  CommonHelper,
  membershipRequestTypeStatusCode,
  membershipRequestTypeCode,
} from '../../../../../helpers/Index';
import { compose } from 'redux';

const currentDate = CommonHelper.currentDate('YYYY-MM-DD');
const startDateValue = CommonHelper.getStartDateMonth(currentDate);
const endDateValue = CommonHelper.getEndDateMonth(currentDate);

const initialFilter = {
  merchantId: null,
  search: null,
  branchId: null,
  requestType: membershipRequestTypeCode.freeze,
  requestStatus: null,
  startDate: startDateValue,
  endDate: endDateValue,
};

const initialSort = {
  sortField: 'user_name',
  sortOrder: '',
};

const optionToash = {
  vertical: 'top',
  horizontal: 'right',
};

const membershipRequestTypeOption = MasterDataHelper.membershipRequestType;

class RequestPage extends React.Component {
  constructor(props) {
    super(props);
    const {
      usersReducer: { prevStateValue },
    } = props;

    const updateExitingFilter = {
      ...CommonHelper.objectCloning(initialFilter),
      ...prevStateValue,
    };

    this.state = {
      filter: updateExitingFilter,
      page: 1,
      limit: 10,
      sort: CommonHelper.objectCloning(initialSort),
      pagination: {
        showSizeChanger: true,
        showTotal: (total, range) => `Showing ${range[0]}-${range[1]} From ${total}`,
        pageSizeOptions: ['10', '20', '30', '40'],
      },
      isLoading: true,
      toastInformation: {
        isOpen: false,
        message: '',
        snackbarType: 'warning',
      },
    };
    this.searchDebounce = _.debounce(this.searchDebounce, 400);
  }

  componentDidMount() {
    const { filter } = this.state;
    const { usersReducer, pageStatus } = this.props;

    this.setState(
      {
        filter: {
          ...filter,
          merchantId: usersReducer.activeMerchant,
          requestStatus: pageStatus,
        },
      },
      () => {
        this.getMembershipRequestPagination();
      },
    );
  }

  componentDidUpdate(prevProps) {
    const { filter } = this.state;
    const { pageStatus, usersReducer, masterData } = this.props;
    const currentMerchant = usersReducer.activeMerchant;

    if (pageStatus !== filter.requestStatus) {
      this.resetPropChange();
    } else if (
      currentMerchant !== filter.merchantId ||
      prevProps.masterData.branchList.length !== masterData.branchList.length
    ) {
      this.resetMerchantBranch();
    }
  }

  getMembershipRequestPagination() {
    const { getRequestPagination } = this.props;
    const {
      limit,
      pagination,
      page,
      filter: { search, startDate, endDate, requestType, requestStatus },
      sort,
    } = this.state;

    const params = {
      merchant_id: this.state.filter.merchantId,
      branch_id: this.state.filter.branchId,
      search,
      start_date: startDate,
      end_date: endDate,
      request_status: requestStatus,
      request_type: requestType,
      page,
      limit,
      sort: sort.sortField,
      sortOrder: sort.sortOrder,
    };

    getRequestPagination(params).then(response => {
      this.setState({
        isLoading: false,
        pagination: { ...pagination, pageSize: limit, total: response.total },
      });
    });
  }

  handleChangeSearch = value => {
    const { filter } = this.state;

    this.setState(
      {
        filter: { ...filter, search: value, sort: JSON.parse(JSON.stringify(initialSort)) },
        page: 1,
        pagination: { ...this.state.pagination, current: 1 },
      },
      () => {
        this.searchDebounce();
      },
    );
  };

  handleChangeStartDate = value => {
    const { filter } = this.state;
    const endDate = CommonHelper.getEndDateMonth(value);

    this.setState(
      {
        filter: { ...filter, startDate: value, endDate },
      },
      () => {
        this.getMembershipRequestPagination();
      },
    );
  };

  handleChangeEndDate = value => {
    const { filter } = this.state;

    this.setState(
      {
        filter: { ...filter, endDate: value },
      },
      () => {
        this.getMembershipRequestPagination();
      },
    );
  };

  handleCloseToash = () => {
    const { toastInformation } = this.state;
    this.setState({ toastInformation: { ...toastInformation, isOpen: false } });
  };

  handleTableChange = (pagination, filters, sorter) => {
    const { sort } = this.state;

    this.setState(
      {
        limit: pagination.pageSize,
        pagination: {
          ...this.state.pagination,
          current: pagination.current,
          pageSize: pagination.pageSize,
        },
        sort: {
          ...sort,
          sortField: sorter.field,
          sortOrder: sorter.order === 'ascend' ? 'asc' : 'desc',
        },
        page: pagination.current,
      },
      () => {
        this.getMembershipRequestPagination();
      },
    );
  };

  searchDebounce = () => {
    this.getMembershipRequestPagination();
  };

  handleSelectMembershipRequestType = value => {
    const { filter } = this.state;

    this.setState(
      {
        page: 1,
        pagination: { ...this.state.pagination, current: 1 },
        filter: {
          ...filter,
          requestType: value || null,
        },
      },
      () => {
        this.getMembershipRequestPagination();
      },
    );
  };

  handleClickCard = value => {
    const { onSetPrevStateValue, history } = this.props;
    const { filter } = this.state;

    onSetPrevStateValue({ ...filter, url: history.location.pathname });
    history.push(`/membership/request/details/${value}`);
  };

  handleSelectBranch = value => {
    const { filter } = this.state;

    if (value) {
      this.setState(
        {
          filter: { ...filter, branchId: value || null },
          page: 1,
          pagination: { ...this.state.pagination, current: 1 },
        },
        () => {
          this.getMembershipRequestPagination();
        },
      );
    }
  };

  resetPropChange() {
    const { usersReducer, pageStatus } = this.props;

    const resetParam = JSON.parse(JSON.stringify(initialFilter));
    resetParam.requestStatus = pageStatus;
    resetParam.merchantId = usersReducer.activeMerchant;
    resetParam.branchId = null;

    this.setState(
      {
        isLoading: true,
        filter: resetParam,
      },
      () => {
        this.getMembershipRequestPagination();
      },
    );
  }

  resetMerchantBranch() {
    const { filter } = this.state;
    const { usersReducer } = this.props;

    this.setState(
      {
        isLoading: true,
        filter: {
          ...filter,
          merchantId: usersReducer.activeMerchant,
          branchId: null,
        },
      },
      () => {
        this.getMembershipRequestPagination();
      },
    );
  }

  processMessage(messages, type) {
    const convertedMessage = CommonHelper.generateMessage(messages);

    const paramInformation = {
      isOpen: true,
      message: convertedMessage,
      snackbarType: type,
    };

    this.setState({
      toastInformation: paramInformation,
    });
  }

  renderRowAdditional = data => {
    const requestDate = CommonHelper.dateTimeParseNewFormat(
      data.requested_date,
      'DD MMM YYYY HH:mm',
    );

    return (
      <Grid container justify="space-between">
        <Grid item lg={3} md={2}>
          <div className="flex-column">
            <label className="text-12 title">Request Date</label>
            <label className="text-12">{requestDate}</label>
          </div>
        </Grid>
        {data.details.map((item, index) => {
          const currentCol = index > 1 ? 3 : 2;

          return (
            <React.Fragment key={index}>
              <Grid item>
                <div className="divider-hr-vertical" />
              </Grid>
              <Grid item lg={currentCol} md={currentCol}>
                <div className="flex-column">
                  <label className="text-12 title">{item.name}</label>
                  <label className="text-12 wrapping-container">{item.value}</label>
                </div>
              </Grid>
            </React.Fragment>
          );
        })}
      </Grid>
    );
  };

  renderColumns = () => {
    const { pageStatus } = this.props;

    return [
      {
        title: 'Username',
        dataIndex: 'user_name',
        sorter: true,
        render: (text, row) => {
          return (
            <Grid
              container
              direction="column"
              onClick={() => this.handleClickCard(row.request_id)}
              className="container-row-membership-review flex-wrap-unset"
            >
              <Grid item lg md className="container-info-header">
                <div className="flex-column">
                  <label className="text-14 title wrapping-container">{row.user_name}</label>
                  <label className="text-10">ID : {row.request_id}</label>
                </div>
              </Grid>
              <Grid item lg md className="container-info-body">
                <Grid container justify="flex-start">
                  <Grid item lg={8} md={8}>
                    {this.renderRowAdditional(row)}
                  </Grid>
                  {pageStatus === membershipRequestTypeStatusCode.decline ? (
                    <Grid item lg={4} md={4}>
                      <div className="container-delete flex-column">
                        <label className="text-12 title">Decline Reason</label>
                        <label className="text-12">{row.declined_reason || '-'}</label>
                      </div>
                    </Grid>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
          );
        },
      },
    ];
  };

  renderFilter() {
    return (
      <Grid container justify="flex-start" className="container-filter">
        <Grid item lg={3} md={3} className="row-filter">
          <TextInput
            iconPrefix="ic-ffo-search"
            placeHolderText="Search user"
            onChange={this.handleChangeSearch}
            size="md"
          />
        </Grid>
        <Grid item lg={2} md={2} className="row-filter">
          <div className="container-remove-margin">
            <SelectInputMain
              options={membershipRequestTypeOption}
              currentValue={this.state.filter.requestType}
              onChange={this.handleSelectMembershipRequestType}
            />
          </div>
        </Grid>

        <Grid item lg={2} md={2} className="row-filter">
          <div className="container-remove-margin">
            <SelectInputBranch
              placeHolder="Select Branch"
              onChange={this.handleSelectBranch}
              currentValue={this.state.filter.branchId}
              filter={this.state.filter}
              includeAllData
            />
          </div>
        </Grid>

        <Grid item lg md>
          <Grid container justify="flex-end" alignItems="center">
            <Grid item>
              <label className="text-14 pr-8">Date</label>
            </Grid>
            <Grid item lg={4} md={4}>
              <PickerInputDate
                customIcon="ic-ffo-date-pick"
                dateFormat="dd/MM/yyyy"
                defaultValue={this.state.filter.startDate}
                onChange={this.handleChangeStartDate}
                toolbar={false}
              />
            </Grid>
            <Grid item>
              <label className="text-14 pr-8 pl-8"> - </label>
            </Grid>
            <Grid item lg={4} md={4}>
              <PickerInputDate
                customIcon="ic-ffo-date-pick"
                dateFormat="dd/MM/yyyy"
                minDate={this.state.filter.startDate}
                defaultValue={this.state.filter.endDate}
                onChange={this.handleChangeEndDate}
                toolbar={false}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  render() {
    const { membership } = this.props;
    const { isLoading, pagination, toastInformation } = this.state;
    // eslint-disable-next-line no-unused-vars
    const adjustTableHeight = window.innerHeight - 350;
    const ListCustom = membership.list;
    const tableLoading = membership.fetching;

    let renderElement = <SkeletonMain />;

    if (!isLoading) {
      renderElement = (
        <div className="container-page-membership-review scroll-container">
          <div className="container-page-scrolling-area include-tab">
            <Grid container direction="column" className="flex-wrap-unset">
              <Grid item lg md sm className="section-page-header">
                {this.renderFilter()}
              </Grid>
              <Grid item lg md className="section-table-body">
                <Table
                  columns={this.renderColumns()}
                  rowKey={record => record.request_id}
                  dataSource={ListCustom}
                  pagination={pagination}
                  loading={tableLoading}
                  // scroll={{ y: adjustTableHeight }}
                  onChange={this.handleTableChange}
                />
              </Grid>
            </Grid>
          </div>
          <SnackBarSimple
            open={toastInformation.isOpen}
            durationHide={2000}
            message={toastInformation.message}
            onClickClose={this.handleCloseToash}
            snackbarType={toastInformation.snackbarType}
            anchor={optionToash}
          />
        </div>
      );
    }
    return renderElement;
  }
}

const mapDispatchToProps = dispatch => ({
  getRequestPagination: params => getMembershipRequestPagination(dispatch, params),
});

const mapStateToProps = ({ usersReducer, membership, masterData }) => ({
  usersReducer,
  membership,
  masterData,
});

RequestPage.propTypes = {
  getRequestPagination: PropTypes.func,
  history: PropTypes.object,
  masterData: PropTypes.object,
  membership: PropTypes.object,
  onSetPrevStateValue: PropTypes.func,
  pageStatus: PropTypes.number,
  usersReducer: PropTypes.object,
};

const shell = compose(connect(mapStateToProps, mapDispatchToProps));
const core = compose(AuthenticationAccessPages, PrevStateValue);

export default shell(core(RequestPage));
