import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
// helper
import { CommonHelper, pointBoosterActionOptionCode } from '../../../../../../../../helpers/Index';

const CardMembershipLabel = props => {
  const {
    form: { typeActionBooster, selectedMembershipDetails },
  } = props;

  const membershipTitle =
    selectedMembershipDetails.option.product_name || selectedMembershipDetails.option.name;

  const period = `${selectedMembershipDetails.option.period} ${selectedMembershipDetails.option.period_type}`;
  const quota = `${selectedMembershipDetails.option.remaining_quota ||
    selectedMembershipDetails.option.quota}`;

  const price = selectedMembershipDetails.option.price;

  return (
    <div className="container-main-card-shadow p-24 mt-40">
      <Grid container justify="space-between">
        <Grid item lg={8} md={8}>
          <div className="flex-column">
            <label className="text-14 text-bold mb-16">{membershipTitle}</label>
            <Grid container justify="flex-start">
              <Grid item lg={4} md={4}>
                <div className="flex-column">
                  <label className="text-12 text-semi-bold text-rolling-stone mb-4">Period</label>
                  <label className="text-14 text-semi-bold">{period}</label>
                </div>
              </Grid>
              <Grid item lg={4} md={4} className="ml-16">
                <div className="flex-column">
                  <label className="text-12 text-semi-bold text-rolling-stone mb-4">Quota</label>
                  <label className="text-14 text-semi-bold">{quota}</label>
                </div>
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid item lg={4} md={4}>
          {typeActionBooster !== pointBoosterActionOptionCode.renew && (
            <div className="flex-column p-16 container-main-card-blue text-center">
              <label className="text-14 mb-4 text-bold">
                IDR {CommonHelper.formatCurrency(price)}
              </label>
              <label className="text-12 text-rolling-stone text-semi-bold">1 Month</label>
            </div>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

CardMembershipLabel.propTypes = {
  form: PropTypes.object,
};

export default CardMembershipLabel;
