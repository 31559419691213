import React from 'react';
import { connect } from 'react-redux';
import { Grid, FormControl, FormLabel } from '@material-ui/core';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import _ from 'lodash';
// component
import {
  ButtonMain,
  PrevNextStepperCount,
  RadioInput,
  SelectInputMain,
  PickerInputDate,
  SelectInputScheduleHour,
  TextAreaMain,
  TextInputAutocomplete,
  SelectInputGeneral,
} from '../../../../../../components/Index';
import UserCheckField from '../../../../../../components/higher-order-components/UserCheckField';
// api
import {
  getWorkoutCategoryAllCoach,
  getWorkoutProductByCoach,
  getWorkoutCategoryProduct,
  getBranchesByProduct,
} from '../../../../../../services/api/MasterDataApi';
import {
  getOrderShippingAddress,
  getOrderShippingAddressInfo,
} from '../../../../../../services/api/OrdersApi';
import {
  getAvailableScheduleHourByDate,
  getAvailableClassSchedule,
} from '../../../../../../services/api/ScheduleDataApi';
// helper
import {
  CommonHelper,
  MasterDataHelper,
  OrderHelper,
  workoutCategoryProductCode,
  serviceLocationCode,
} from '../../../../../../helpers/Index';
// style
import './StepBookingProductDetailsStyle.scss';

const today = CommonHelper.currentDate('YYYY-MM-DD HH:mm:ss');
const currentDate = CommonHelper.dateTimeParseNewFormat(today, 'YYYY-MM-DD');
const minDate = CommonHelper.intervalDate(currentDate, 'YYYY-MM-DD', -7, 'day');

const productOptions = [
  { value: 'class', name: 'Class' },
  { value: 'coach', name: 'Coach' },
];

const addressTypeValue = MasterDataHelper.addressTypeOrder;

const initialValidation = {
  trainerId: { isError: false, errorMessage: '' },
  productId: { isError: false, errorMessage: '' },
  branchId: { isError: false, errorMessage: '' },
  address: { isError: false, errorMessage: '' },
  note: { isError: '', errorMessage: '' },
  startDate: { isError: '', errorMessage: '' },
  trainingScheduleId: { isError: '', errorMessage: '' },
};

const initialForm = {
  product: productOptions[0],
  trainerId: null,
  productId: null,
  branchId: null,
  addressType: addressTypeValue[0].value,
  addressInfo: {},
  addressId: '',
  address: '',
  placeId: '',
  note: '',
  startDate: currentDate,
  trainingScheduleId: null,
  serviceLocation: null,
};

class StepBookingProductDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      form: CommonHelper.objectCloning(initialForm),
      validation: CommonHelper.objectCloning(initialValidation),
      selectedBranch: {},
      selectedProduct: {},
      hideProductType: false,
    };
    this.searchAddressDebounce = _.debounce(this.searchAddressDebounce, 400);
  }

  componentDidMount() {
    const {
      parentForm,
      orderData: { addressTypeOption },
    } = this.props;
    const { form } = this.state;
    const selectedProduct = !_.isEmpty(parentForm.items) ? parentForm.items[0] : null;
    let setInitialForm = initialForm;
    let address = '';
    let addressType = '';
    let addressId = '';
    let note = '';
    let addressInfo = {};
    let modifyProductOptions = productOptions[0];
    let hideProductType = false;

    // Start populate address
    if (!_.isEmpty(parentForm.addressInfo)) {
      address = parentForm.addressInfo.address;
      addressType = parentForm.addressInfo.address_type;
      addressId = parentForm.addressInfo.user_address_id;
      note = parentForm.addressInfo.notes;
      addressInfo = parentForm.addressInfo;

      addressTypeOption.find(item => {
        if (item.group) {
          addressType = item.group.find(value => {
            return value.additional.user_address_id === addressId;
          });
        } else {
          addressType = item.value;
          address = parentForm.address;
          note = parentForm.note;
        }
        return addressType;
      });
    }
    // End populate address

    // Populate auto select product type
    switch (parentForm.categoryType) {
      case 'pt-at-home':
      case 'virtufit':
        modifyProductOptions = productOptions[1];
        hideProductType = true;
        break;
      case 'gym-access':
      case 'classes':
        hideProductType = true;
        break;
      default:
        modifyProductOptions = productOptions[0];
        break;
    }
    // End auto populate product type

    // Start auto populate field
    if (selectedProduct) {
      const startDate = CommonHelper.dateTimeParseNewFormat(
        selectedProduct.start_time,
        'YYYY-MM-DD',
      );
      setInitialForm = {
        productId: selectedProduct.product_id,
        trainerId: selectedProduct.trainer_id,
        branchId: selectedProduct.branch_id,
        startDate,
        trainingScheduleId: selectedProduct.training_schedule_id,
      };
    }
    // End auto populate field

    // Check if updated from this step
    if (parentForm.step_booking_product_details) {
      if (parentForm.step_booking_product_details.product) {
        // Check product type selected
        switch (parentForm.step_booking_product_details.product.value) {
          case workoutCategoryProductCode.Coach:
            modifyProductOptions = productOptions[1];
            break;
          default:
            modifyProductOptions = productOptions[0];
            break;
        }
      }

      // Start populate address
      if (!_.isEmpty(parentForm.step_booking_product_details.addressInfo)) {
        address = parentForm.step_booking_product_details.addressInfo.address;
        addressType = parentForm.step_booking_product_details.addressInfo.address_type;
        addressId = parentForm.step_booking_product_details.addressInfo.user_address_id;
        note = parentForm.step_booking_product_details.addressInfo.notes;

        addressTypeOption.find(item => {
          if (item.group) {
            addressType = item.group.find(value => {
              return value.additional.user_address_id === addressId;
            });
          } else {
            addressType = item.value;
            address = parentForm.step_booking_product_details.address;
            note = parentForm.step_booking_product_details.note;
          }
          return addressType;
        });
      }
      // End populate address

      const startDate = CommonHelper.dateTimeParseNewFormat(
        parentForm.step_booking_product_details.startDate,
        'YYYY-MM-DD',
      );

      setInitialForm = {
        productId: parentForm.step_booking_product_details.productId,
        trainerId: parentForm.step_booking_product_details.trainerId,
        branchId: parentForm.step_booking_product_details.branchId,
        startDate,
        trainingScheduleId: parentForm.step_booking_product_details.trainingScheduleId,
        serviceLocation: parentForm.step_booking_product_details.serviceLocation,
        addressInfo: parentForm.step_booking_product_details.addressInfo,
      };
    }

    this.setState(
      {
        form: {
          ...form,
          ...setInitialForm,
          address,
          addressId,
          addressType: addressType.value,
          addressInfo,
          note,
          product: modifyProductOptions,
        },
        hideProductType,
      },
      async () => {
        const date = this.state.form.startDate;
        const productType = this.state.form.product.value;
        const productId = this.state.form.productId;
        const trainerId = this.state.form.trainerId;
        switch (productType) {
          case workoutCategoryProductCode.Class:
            await this.getProductByCategory();
            await this.getBranchByProductId(productId);
            await this.getAvailableClassScheduleApi(date);
            break;
          case workoutCategoryProductCode.Coach:
            await this.getAllCoach();
            await this.getProductByCoach(trainerId);
            break;
          default:
            break;
        }
      },
    );
  }

  onProcessPlaceId() {
    const { form, validation } = this.state;
    const { getShippingAddressInfo, onPopUpInformation } = this.props;

    const param = {
      place_id: form.placeId,
    };

    getShippingAddressInfo(param)
      .then(response => {
        const data = response.data;

        this.setState({ form: { ...form, addressInfo: data }, validation: { ...validation } });
      })
      .catch(error => {
        const serverMessage = error.data;
        onPopUpInformation(serverMessage.messages, 'error');
      });
  }

  getAllCoach = async () => {
    const { getCoach, parentForm } = this.props;
    await getCoach(parentForm.categoryType);
  };

  getProductByCoach = async value => {
    const { productByCoach } = this.props;
    const { form } = this.state;
    await productByCoach(value);
    const productDetail = this.getMatchDataByKey('product_id', form.productId);

    this.setState({
      form: {
        ...form,
        serviceLocation: (productDetail && productDetail.service_location) || null,
      },
      selectedProduct: productDetail,
    });
  };

  getProductByCategory = async () => {
    const { categoryProduct, parentForm } = this.props;
    const { form } = this.state;
    await categoryProduct(parentForm.categoryType);
    const productDetail = this.getMatchDataByKey('product_id', form.productId);

    this.setState({
      form: {
        ...form,
        serviceLocation: (productDetail && productDetail.service_location) || null,
      },
      selectedProduct: productDetail,
    });
  };

  getBranchByProductId = async value => {
    const { branchByProductId } = this.props;
    const { form } = this.state;
    await branchByProductId(value);
    const branchDetail = this.getMatchDataByKey('branch_id', form.branchId);

    this.setState({
      selectedBranch: branchDetail,
    });
  };

  getAvailableScheduleHour = async date => {
    const { form } = this.state;
    const { getScheduleHour } = this.props;
    const trainerId = form.trainerId;
    const params = {
      date,
      product_id: form.productId,
      branch_id: form.branchId,
      is_dashboard: true,
    };
    await getScheduleHour(trainerId, params);
  };

  getAvailableClassScheduleApi = async date => {
    const { form } = this.state;
    const { callGetAvailableClassScheduleApi } = this.props;
    const productId = form.productId;
    const branchId = form.branchId;

    const isOfflineClass = form.serviceLocation === serviceLocationCode.InStore;
    if (isOfflineClass && !branchId) {
      return;
    }

    const params = { date, branch_id: branchId, is_dashboard: true };
    await callGetAvailableClassScheduleApi(productId, params);
  };

  searchAddressDebounce = async () => {
    const { form, validation } = this.state;
    const { getShippingAddress, onPopUpInformation, onParameterUpdate } = this.props;

    const param = {
      search_term: form.address,
    };

    const message = CommonHelper.objectCloning(initialValidation.address);
    const formNewValue = CommonHelper.objectCloning(form);

    if (form.address === '' || form.address === undefined) {
      message.isError = true;
      message.errorMessage = 'Please enter Address';
    }

    validation.address = message;
    formNewValue.address = form.address;

    await onParameterUpdate(formNewValue, validation);
    await getShippingAddress(param).catch(error => {
      const serverMessage = error.data;
      onPopUpInformation(serverMessage.messages, 'error');
    });
  };

  getMatchDataByKey = (key, value) => {
    const {
      availableHours,
      availableClassSchedule,
      masterData: { workoutCategoryProduct, branchesByProduct, workoutProductByCoach },
    } = this.props;
    const { form } = this.state;
    let findMatchData;
    switch (key) {
      case 'training_schedule_id':
        if (form.product.value === workoutCategoryProductCode.Class) {
          findMatchData = _.find(availableClassSchedule, ['training_schedule_id', value]);
        } else {
          findMatchData = _.find(availableHours, ['training_schedule_id', value]);
        }
        break;
      case 'branch_id':
        findMatchData = _.find(branchesByProduct, ['branch_id', value]);
        break;
      case 'product_id':
        if (form.product.value === workoutCategoryProductCode.Class) {
          findMatchData = _.find(workoutCategoryProduct, ['product_id', value]);
        } else {
          findMatchData = _.find(workoutProductByCoach, ['product_id', value]);
        }
        break;
      default:
        break;
    }
    return findMatchData;
  };

  handleChangeProductType = value => {
    const { parentForm } = this.props;

    this.setState(
      {
        form: {
          ...initialForm,
          product: value,
          address: parentForm.addressInfo.address,
          addressType: parentForm.addressInfo.address_type,
          addressId: parentForm.addressInfo.user_address_id,
          addressInfo: parentForm.addressInfo,
          note: parentForm.addressInfo.notes,
        },
        validation: initialValidation,
      },
      async () => {
        const productType = this.state.form.product.value;
        switch (productType) {
          case workoutCategoryProductCode.Class:
            await this.getProductByCategory();
            break;
          case workoutCategoryProductCode.Coach:
            await this.getAllCoach();
            break;
          default:
            break;
        }
      },
    );
  };

  handleSelectTrainer = value => {
    const { parentForm } = this.props;
    const { form } = this.state;

    this.setState(
      {
        form: {
          ...initialForm,
          product: form.product,
          trainerId: value,
          address: parentForm.addressInfo.address,
          addressType: parentForm.addressInfo.address_type,
          addressId: parentForm.addressInfo.user_address_id,
          addressInfo: parentForm.addressInfo,
          note: parentForm.addressInfo.notes,
        },
      },
      async () => {
        await this.getProductByCoach(value);
      },
    );
  };

  handleSelectProduct = value => {
    const { form } = this.state;
    const productDetail = this.getMatchDataByKey('product_id', value);

    this.setState(
      {
        form: {
          ...form,
          productId: value,
          branchId: null,
          trainingScheduleId: null,
          serviceLocation: productDetail.service_location || null,
        },
        selectedProduct: productDetail,
        validation: initialValidation,
      },
      () => {
        if (productDetail.service_location === serviceLocationCode.InStore) {
          this.getBranchByProductId(value);
        }
        if (form.product.value === workoutCategoryProductCode.Coach) {
          this.getAvailableScheduleHour(form.startDate);
        }
      },
    );
  };

  handleSelectBranch = value => {
    const { form } = this.state;
    const branchDetail = this.getMatchDataByKey('branch_id', value);

    this.setState(
      {
        form: {
          ...form,
          branchId: value,
          trainingScheduleId: null,
        },
        selectedBranch: branchDetail,
      },
      async () => {
        switch (form.product.value) {
          case workoutCategoryProductCode.Class:
            await this.getAvailableClassScheduleApi(form.startDate);
            break;
          case workoutCategoryProductCode.Coach:
            await this.getAvailableScheduleHour(form.startDate);
            break;
          default:
            break;
        }
      },
    );
  };

  handleSelectAddressType = value => {
    const { form } = this.state;
    const {
      orderData: { addressTypeOptionRaw },
    } = this.props;

    let address = '';
    let addressId = '';
    let note = '';
    let addressInfo = {};
    let findMatchData;

    if (!_.isEmpty(addressTypeOptionRaw)) {
      findMatchData = addressTypeOptionRaw.find(item => {
        return value.includes(item.value, 0);
      });

      if (!findMatchData) {
        findMatchData = OrderHelper.findAddressOrderGroupOption(addressTypeOptionRaw, value);
        address = '';
        addressId = '';
        note = '';
      } else {
        address = findMatchData.additional.address;
        addressId = findMatchData.additional.user_address_id;
        note = findMatchData.additional.notes;
        addressInfo = findMatchData.additional;
      }
    }

    this.setState({
      form: {
        ...form,
        addressType: value,
        address,
        addressId,
        addressInfo,
        note,
      },
    });
  };

  handleTextChangeAddress = value => {
    const { form } = this.state;
    this.setState(
      {
        form: { ...form, address: value },
      },
      () => {
        this.searchAddressDebounce();
      },
    );
  };

  handleSelectedItemAddress = (value, option) => {
    const { form } = this.state;

    this.setState(
      {
        form: { ...form, address: option.address, placeId: option.place_id },
      },
      () => {
        this.onProcessPlaceId();
      },
    );
  };

  handleTextChangeNote = value => {
    const { form, validation } = this.state;
    const message = CommonHelper.objectCloning(initialValidation.note);

    if (value === '' || value === undefined) {
      message.isError = 'error';
      message.errorMessage = 'Please enter note Address';
    }

    this.setState({
      form: { ...form, note: value },
      validation: { ...validation, note: message },
    });
  };

  handleSelectStartDateChange = value => {
    const { form } = this.state;

    this.setState(
      {
        form: { ...form, startDate: value, trainingScheduleId: null },
      },
      () => {
        switch (form.product.value) {
          case workoutCategoryProductCode.Coach:
            this.getAvailableScheduleHour(value);
            break;
          case workoutCategoryProductCode.Class:
            this.getAvailableClassScheduleApi(value);
            break;
          default:
            break;
        }
      },
    );
  };

  handleSelectTime = value => {
    const { form } = this.state;

    this.setState({
      form: {
        ...form,
        trainingScheduleId: value,
      },
    });
  };

  handleValidation = form => {
    const {
      form: { serviceLocation },
    } = this.state;
    let validationNewValue = CommonHelper.objectCloning(initialValidation);
    let excludeValidationKeys = [];
    const isClasses = form.product.value === workoutCategoryProductCode.Class;
    const isCoaches = form.product.value === workoutCategoryProductCode.Coach;

    if (isClasses) {
      if (serviceLocation === serviceLocationCode.InStore) {
        excludeValidationKeys = ['trainerId', 'placeId', 'addressId', 'address', 'note'];
      } else {
        excludeValidationKeys = [
          'trainerId',
          'branchId',
          'placeId',
          'addressId',
          'address',
          'note',
        ];
      }
    }

    if (isCoaches) {
      switch (serviceLocation) {
        case serviceLocationCode.AtHome:
        case serviceLocationCode.Virtual:
          excludeValidationKeys = ['branchId', 'placeId', 'addressId'];
          break;
        case serviceLocationCode.InStore:
          excludeValidationKeys = ['placeId', 'addressId', 'address', 'note'];
          break;
        default:
          break;
      }
    }

    const keys = Object.keys(form).filter(key => {
      return excludeValidationKeys.indexOf(key) === -1;
    });

    const errorValidationKeys = Object.keys(validationNewValue).filter(key => {
      return excludeValidationKeys.indexOf(key) === -1;
    });

    const errorKeys = keys.filter(key => {
      return form[key] === null || form[key] === '';
    });

    errorValidationKeys.forEach(key => {
      const isError = errorKeys.indexOf(key) !== -1;
      let errorDetail = {};
      switch (key) {
        case 'productId':
          errorDetail = { isError, errorMessage: isError ? 'Please choose product' : '' };
          break;
        case 'branchId':
          errorDetail = { isError, errorMessage: isError ? 'Please choose training location' : '' };
          break;
        case 'trainerId':
          errorDetail = { isError, errorMessage: isError ? 'Please choose coach' : '' };
          break;
        case 'trainingScheduleId':
          errorDetail = { isError, errorMessage: isError ? 'Please choose training time' : '' };
          break;
        case 'address':
          errorDetail = { isError, errorMessage: isError ? 'Please fill address' : '' };
          break;
        case 'note':
          errorDetail = {
            isError: isError ? 'error' : '',
            errorMessage: isError ? 'Please fill note address' : '',
          };
          break;
        default:
          errorDetail = { isError, errorMessage: isError ? 'Please fill input' : '' };
          break;
      }
      validationNewValue = {
        ...validationNewValue,
        [key]: errorDetail,
      };
    });

    this.setState({
      validation: { ...validationNewValue },
    });

    return { isError: errorKeys.length, validationNewValue };
  };

  handlePrevClick = async () => {
    const { onPrevClick, onParameterUpdate } = this.props;
    const { form, selectedBranch, selectedProduct } = this.state;
    const formNewValue = CommonHelper.objectCloning(form);
    const finalFormNewValue = { ...formNewValue, selectedBranch, selectedProduct };

    await onParameterUpdate(finalFormNewValue, 'step_booking_product_details');
    await onPrevClick();
  };

  handleNextClick = async () => {
    const { onNextClick, onParameterUpdate } = this.props;
    const { form, selectedBranch, selectedProduct } = this.state;
    const formNewValue = CommonHelper.objectCloning(form);
    const trainingTime = this.getMatchDataByKey(
      'training_schedule_id',
      formNewValue.trainingScheduleId,
    );
    const finalSelectedProduct = [
      { ...trainingTime, ...selectedProduct, qty: 1, trainer_id: form.trainerId },
    ];
    const finalFormNewValue = {
      ...formNewValue,
      selectedBranch,
      selectedProduct: finalSelectedProduct,
    };

    const { isError } = this.handleValidation(formNewValue);
    await onParameterUpdate(finalFormNewValue, 'step_booking_product_details');

    if (!isError) {
      await onNextClick();
    }
  };

  renderSelectOption = () => {
    const {
      masterData: { workoutCategoryProduct, workoutCategoryCoach, workoutProductByCoach },
      parentForm,
    } = this.props;
    const { form, validation } = this.state;
    let renderElement = null;

    switch (form.product.value) {
      case workoutCategoryProductCode.Class:
        renderElement = (
          <Grid item lg={12} md={12}>
            <Grid item lg={6} md={6}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <FormLabel component="label" className="text-12 filed-title">
                  Product
                </FormLabel>
                <SelectInputGeneral
                  placeHolder="Select Product"
                  currentValue={form.productId}
                  dataList={workoutCategoryProduct}
                  primaryKey="product_id"
                  onChange={this.handleSelectProduct}
                  errorMessage={validation.productId.errorMessage}
                  validateStatus={validation.productId.isError ? 'error' : ''}
                />
              </FormControl>
            </Grid>
          </Grid>
        );
        break;
      default:
        renderElement = (
          <>
            <Grid item lg={6} md={6}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <FormLabel component="label" className="text-12 filed-title">
                  Coach / Therapist
                </FormLabel>
                <SelectInputGeneral
                  placeHolder="Select Coach / Therapist"
                  currentValue={form.trainerId}
                  dataList={workoutCategoryCoach}
                  primaryKey="trainer_id"
                  onChange={this.handleSelectTrainer}
                  errorMessage={validation.trainerId.errorMessage}
                  validateStatus={validation.trainerId.isError ? 'error' : ''}
                />
              </FormControl>
            </Grid>
            {form.trainerId && (
              <Grid item lg={6} md={6}>
                <FormControl component="fieldset" fullWidth margin="normal">
                  <FormLabel component="label" className="text-12 filed-title">
                    Product
                  </FormLabel>
                  <SelectInputGeneral
                    placeHolder="Select Product"
                    currentValue={form.productId}
                    dataList={workoutProductByCoach}
                    primaryKey="product_id"
                    onChange={this.handleSelectProduct}
                    errorMessage={validation.productId.errorMessage}
                    validateStatus={validation.productId.isError ? 'error' : ''}
                    productCategory={parentForm.categoryType}
                  />
                </FormControl>
              </Grid>
            )}
          </>
        );
        break;
    }

    return renderElement;
  };

  renderLocationDetails = () => {
    const {
      orderData: { addressTypeOption, fetchingShippingAddress, listShippingAddress },
      masterData: { branchesByProduct },
    } = this.props;
    const {
      form: { addressType, note, address, branchId, serviceLocation },
      validation,
    } = this.state;
    const ListCustom = listShippingAddress;
    let renderElement = null;

    switch (serviceLocation) {
      case serviceLocationCode.AtHome:
        renderElement = (
          <Grid container spacing={2} direction="column">
            <Grid item lg={6} md={6}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <FormLabel component="label" className="text-12 filed-title">
                  Select Address from
                </FormLabel>
                <SelectInputMain
                  options={addressTypeOption}
                  currentValue={addressType || addressTypeValue[0].value}
                  onChange={this.handleSelectAddressType}
                  isGroupOption
                />
              </FormControl>
            </Grid>
            <Grid item lg={6} md={6}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <FormLabel component="label" className="text-12 filed-title">
                  Appointment Address
                </FormLabel>
                <TextInputAutocomplete
                  size="md"
                  onChange={this.handleTextChangeAddress}
                  currentValue={address}
                  placeholder="Address"
                  onSelect={this.handleSelectedItemAddress}
                  isLoading={fetchingShippingAddress}
                  data={ListCustom}
                  isCustomDisplay
                  customDisplayClass="custom-address-option-item"
                  isError={this.state.validation.address.isError}
                  errorMessage={this.state.validation.address.errorMessage}
                />
              </FormControl>
            </Grid>
            <Grid item lg={6} md={6}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <FormLabel component="label" className="text-12 filed-title">
                  Address Notes
                </FormLabel>
                <TextAreaMain
                  onChange={this.handleTextChangeNote}
                  currentValue={note}
                  placeholder="(exp: Building number, house No. building floor)"
                  rows={3}
                  validateStatus={this.state.validation.note.isError}
                  errorMessage={this.state.validation.note.errorMessage}
                  maxLength={99}
                />
              </FormControl>
            </Grid>
          </Grid>
        );
        break;
      case serviceLocationCode.InStore:
        renderElement = (
          <Grid item lg={6} md={6}>
            <FormControl component="fieldset" fullWidth margin="normal">
              <FormLabel component="label" className="text-12 filed-title">
                Location
              </FormLabel>
              <SelectInputGeneral
                placeHolder="Select Branch"
                currentValue={branchId}
                dataList={branchesByProduct}
                primaryKey="branch_id"
                onChange={this.handleSelectBranch}
                errorMessage={validation.branchId.errorMessage}
                validateStatus={validation.branchId.isError ? 'error' : ''}
              />
            </FormControl>
          </Grid>
        );
        break;
      default:
        renderElement = null;
        break;
    }

    return (
      <Grid item lg={12} md={12}>
        {renderElement}
      </Grid>
    );
  };

  renderSelectDateTimeDetail = () => {
    const {
      form: { startDate, product, trainingScheduleId },
      validation,
    } = this.state;

    return (
      <Grid item lg={12} md={12}>
        <Grid item lg={6} md={6}>
          <Grid container spacing={2}>
            <Grid item lg={6} md={6}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <FormLabel component="label" className="text-12 filed-title">
                  Date
                </FormLabel>
                <PickerInputDate
                  customIcon="ic-ffo-date-pick"
                  dateFormat="dd-MM-yyyy"
                  minDate={minDate}
                  onChange={this.handleSelectStartDateChange}
                  defaultValue={startDate}
                  toolbar
                />
              </FormControl>
            </Grid>
            <Grid item lg={6} md={6}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <FormLabel component="label" className="text-12 filed-title">
                  Time
                </FormLabel>
                <SelectInputScheduleHour
                  placeHolder="Select Time"
                  currentValue={trainingScheduleId}
                  onChange={this.handleSelectTime}
                  isClasses={product.value === workoutCategoryProductCode.Class}
                  errorMessage={validation.trainingScheduleId.errorMessage}
                  validateStatus={validation.trainingScheduleId.isError ? 'error' : ''}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={6} md={6} />
      </Grid>
    );
  };

  render() {
    const {
      form: { product, branchId, scheduleId, productId, serviceLocation },
      hideProductType,
    } = this.state;
    const { onButtonClickCancel } = this.props;
    let showScheduleTime = false;
    switch (product.value) {
      case workoutCategoryProductCode.Coach:
        if (
          productId ||
          serviceLocation === serviceLocationCode.AtHome ||
          serviceLocation === serviceLocationCode.Virtual
        ) {
          showScheduleTime = true;
        }
        break;
      case workoutCategoryProductCode.Class:
        if (
          scheduleId ||
          branchId ||
          serviceLocation === serviceLocationCode.AtHome ||
          serviceLocation === serviceLocationCode.Virtual
        ) {
          showScheduleTime = true;
        }
        break;
      default:
        break;
    }

    return (
      <Grid container className="container-step-product-details">
        <Grid item lg={12} md={12} className="mt-32">
          <label className="text-12 text-bold text-rolling-stone">PRODUCT DETAILS</label>
        </Grid>
        <Grid item lg={12} md={12}>
          <Grid container spacing={2}>
            {!hideProductType && (
              <Grid item lg={12} md={12}>
                <Grid item lg={6} md={6}>
                  <FormControl component="fieldset" fullWidth margin="normal">
                    <FormLabel component="label" className="text-12 filed-title">
                      Product Type
                    </FormLabel>
                    <RadioInput
                      data={productOptions}
                      onSelect={this.handleChangeProductType}
                      direction="column"
                      currentValue={product}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            )}
            {this.renderSelectOption()}
            {serviceLocation &&
              serviceLocation !== serviceLocationCode.Virtual &&
              this.renderLocationDetails()}
            {showScheduleTime && this.renderSelectDateTimeDetail()}
          </Grid>
        </Grid>
        <Grid item lg={12} md={12} className="mt-24">
          <Grid container justify="flex-end">
            <Grid item lg={5} md={6}>
              <Grid container justify="flex-end" spacing={2}>
                <Grid item lg={4} md={4}>
                  <ButtonMain
                    type="negative"
                    size="xl"
                    labelText="Cancel"
                    onClick={onButtonClickCancel}
                  />
                </Grid>
                <Grid item lg={4} md={4}>
                  <ButtonMain
                    type="ghost"
                    size="xl"
                    labelText="Prev"
                    onClick={this.handlePrevClick}
                  />
                </Grid>
                <Grid item lg={4} md={4}>
                  <ButtonMain
                    type="primary"
                    size="xl"
                    labelText="Next"
                    onClick={this.handleNextClick}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getCoach: category => getWorkoutCategoryAllCoach(dispatch, category),
  productByCoach: trainerId => getWorkoutProductByCoach(dispatch, trainerId),
  categoryProduct: category => getWorkoutCategoryProduct(dispatch, category),
  branchByProductId: category => getBranchesByProduct(dispatch, category),
  getShippingAddress: params => getOrderShippingAddress(dispatch, params),
  getShippingAddressInfo: params => getOrderShippingAddressInfo(dispatch, params),
  getScheduleHour: (trainerId, params) =>
    getAvailableScheduleHourByDate(dispatch, trainerId, params),
  callGetAvailableClassScheduleApi: (productId, params) =>
    getAvailableClassSchedule(dispatch, productId, params),
});

const mapStateToProps = ({ masterData, orderData, scheduleSummary }) => ({
  availableHours: scheduleSummary.availableHours,
  availableClassSchedule: scheduleSummary.availableClassSchedule,
  masterData,
  orderData,
});

StepBookingProductDetails.propTypes = {
  availableClassSchedule: PropTypes.array,
  availableHours: PropTypes.array,
  branchByProductId: PropTypes.func,
  callGetAvailableClassScheduleApi: PropTypes.func,
  categoryProduct: PropTypes.func,
  getCoach: PropTypes.func,
  getScheduleHour: PropTypes.func,
  getShippingAddress: PropTypes.func,
  getShippingAddressInfo: PropTypes.func,
  masterData: PropTypes.object,
  onButtonClickCancel: PropTypes.func,
  onNextClick: PropTypes.func,
  onParameterUpdate: PropTypes.func,
  onPopUpInformation: PropTypes.func,
  onPrevClick: PropTypes.func,
  orderData: PropTypes.object,
  parentForm: PropTypes.object,
  productByCoach: PropTypes.func,
};

const shell = compose(connect(mapStateToProps, mapDispatchToProps));

const core = compose(UserCheckField, PrevNextStepperCount);

export default shell(core(StepBookingProductDetails));
