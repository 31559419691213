/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-access-state-in-setstate */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Table } from 'antd';
import _ from 'lodash';
import { Grid, Link } from '@material-ui/core';
import { compose } from 'redux';
// Component
import {
  SnackBarSimple,
  PickerInputDate,
  LabelStatusPayment,
  EmptyState,
  SelectInputMain,
  ButtonMain,
  PrevStateValue,
  GeneratorExcel,
  GeneratorPdf,
  TextInputPrePost,
  SkeletonMain
} from '../../../../../components/Index';
import {
  ModalPreviewPrintLabel,
  ModalShipmentTracking,
} from '../../components/Index';
// Api
import { getOrderShipmentList, getShipmentExport } from '../../../../../services/api/OrdersApi';
// Helpers
import {
  CommonHelper,
  paymentMethod,
  MasterDataHelper,
  UserHelper,
  productTypeForOrderCode,
} from '../../../../../helpers/Index';
// Assets
import { Icons, Images } from '../../../../../assets/Index';
// Style
import './ShipmentContentPageStyle.scss';

const currentDate = CommonHelper.currentDate('YYYY-MM-DD');
const startDateValue = CommonHelper.getStartDateMonth(currentDate);
const endDateValue = CommonHelper.getEndDateMonth(currentDate);

const optionSearchType = MasterDataHelper.optionSearchType;
const optionTransactionSource = MasterDataHelper.optionTransactionSource;
const optionOrderType = MasterDataHelper.optionOrderType.slice(2, 3);
const orderShipmentStatus = MasterDataHelper.orderShipmentStatus;

const initialSort = {
  sortField: 'name',
  sortOrder: '',
};

const optionToash = {
  vertical: 'top',
  horizontal: 'right',
};

const initialFilter = {
  search: '',
  startDate: startDateValue,
  endDate: endDateValue,
  status: null,
  transactionSource: undefined,
  orderType: undefined,
  searchType: optionSearchType[1].value,
};

class ShipmentContentPage extends React.Component {
  constructor(props) {
    super(props);
    const {
      usersReducer: { prevStateValue },
    } = props;

    const updateExitingFilter = {
      ...CommonHelper.objectCloning(initialFilter),
      status: props.activeTab,
      ...prevStateValue,
    };

    const currentUsers = UserHelper.getCurrentUserInformation();

    this.state = {
      filter: updateExitingFilter,
      page: 1,
      limit: 10,
      sort: initialSort,
      pagination: {
        showSizeChanger: true,
        showTotal: (total, range) => `Showing ${range[0]}-${range[1]} from ${total}`,
        pageSizeOptions: ['10', '20', '30', '40'],
      },
      loading: true,
      isLoading: true,
      toastInformation: {
        isOpen: false,
        message: '',
        snackbarType: 'warning',
      },
      selectedRowKeys: [],
      selectedRowPrint: [],
      selectedRowPrintSingle: {},
      selectedId: null,
      selectedIdTracking: null,
      isOpenModalPrint: false,
      isOpenModalTracking: false,
      isResellerLogin: currentUsers && currentUsers.isResellerLogin,
    };
    this.searchDebounce = _.debounce(this.searchDebounce, 400);
  }

  componentDidMount() {
    this.getOrderListPagination();
  }

  componentWillReceiveProps(nextProps) {
    const { usersReducer } = nextProps;
    const {
      onSetPrevStateValue,
      history: { location },
      activeTab,
      usersReducer: { prevStateValue },
    } = this.props;

    const isEqualActiveTab = _.isEqual(activeTab, nextProps.activeTab);
    const isEqualActivePrevStateUrl = _.isEqual(
      prevStateValue.url,
      nextProps.usersReducer.prevStateValue.url,
    );

    if (_.isEmpty(usersReducer.prevStateValue)) {
      const newValue = {
        url: location.pathname,
      };

      onSetPrevStateValue(newValue);

      this.setState({
        filter: CommonHelper.objectCloning(initialFilter),
        page: 1,
      });
    }

    if (!isEqualActiveTab || !isEqualActivePrevStateUrl) {
      this.resetPropChange(nextProps.activeTab);
    }
  }

  // componentDidUpdate(nextProps) {
  //   const {
  //     activeTab,
  //     usersReducer: { prevStateValue },
  //   } = this.props;
  //   const isEqualActiveTab = _.isEqual(activeTab, nextProps.activeTab);
  //   const isEqualActivePrevStateUrl = _.isEqual(
  //     prevStateValue.url,
  //     nextProps.usersReducer.prevStateValue.url,
  //   );

  //   if (!isEqualActiveTab || !isEqualActivePrevStateUrl) {
  //     this.resetPropChange();
  //   }
  // }

  getOrderListPagination = () => {
    const { orderListPagination, activeTab } = this.props;
    const { limit, page, pagination, sort, filter } = this.state;

    const payload = {
      page,
      limit,
      sort: sort.sortField,
      sortOrder: sort.sortOrder,
      search: filter.search,
      start_date: filter.startDate,
      end_date: filter.endDate,
      order_type: filter.orderType,
      order_status: filter.status || activeTab,
      transaction_source: filter.transactionSource,
      search_type: filter.searchType,
    };

    this.setState({ loading: true }, () => {
      orderListPagination(payload).then(response => {
        this.setState({
          loading: false,
          isLoading: false,
          pagination: { ...pagination, pageSize: limit, total: response.data.total },
        });
      });
    });
  };

  handleChangeSearch = value => {
    const { filter } = this.state;

    this.setState(
      {
        filter: { ...filter, search: value },
        page: 1,
        pagination: { ...this.state.pagination, current: 1 },
      },
      () => {
        this.searchDebounce();
      },
    );
  };

  handleCloseToash = () => {
    const { toastInformation } = this.state;
    this.setState({ toastInformation: { ...toastInformation, isOpen: false } });
  };

  handleTableChange = (pagination, filters, sorter) => {
    const { sort } = this.state;

    this.setState(
      {
        limit: pagination.pageSize,
        pagination: {
          ...this.state.pagination,
          current: pagination.current,
          pageSize: pagination.pageSize,
        },
        sort: {
          ...sort,
          sortField: sorter.field,
          sortOrder: sorter.order === 'ascend' ? '' : 'desc',
        },
        page: pagination.current,
      },
      () => {
        this.getOrderListPagination();
      },
    );
  };

  handleClickRow = salesInvoiceId => {
    const { history } = this.props;
    history.push(`/order/shipment/details/${salesInvoiceId}`);
  };

  handleChangeStartDate = value => {
    const { filter } = this.state;
    const { getOrderListPagination } = this;
    const { onSetPrevStateValue } = this.props;

    const endDate = CommonHelper.getEndDateMonth(value);

    onSetPrevStateValue({ ...filter, startDate: value, endDate });

    this.setState(
      {
        filter: { ...filter, startDate: value, endDate },
      },
      () => {
        getOrderListPagination();
      },
    );
  };

  handleChangeEndDate = value => {
    const { filter } = this.state;
    const { getOrderListPagination } = this;
    const { onSetPrevStateValue } = this.props;

    onSetPrevStateValue({ ...filter, endDate: value });

    this.setState(
      {
        filter: { ...filter, endDate: value },
      },
      () => {
        getOrderListPagination();
      },
    );
  };

  searchDebounce = () => {
    const { filter } = this.state;
    const { getOrderListPagination } = this;
    const { onSetPrevStateValue } = this.props;

    onSetPrevStateValue({ ...filter, search: filter.search });

    this.setState(
      {
        page: 1,
      },
      () => {
        getOrderListPagination();
      },
    );
  };

  handleChangeSearchType = value => {
    const { filter } = this.state;
    const { getOrderListPagination } = this;

    this.setState(
      {
        filter: { ...filter, searchType: value },
      },
      () => {
        getOrderListPagination();
      },
    );
  };

  handleChangeTransactionSource = value => {
    const { filter } = this.state;
    const { getOrderListPagination } = this;
    const { onSetPrevStateValue } = this.props;

    onSetPrevStateValue({ ...filter, transactionSource: value });

    this.setState(
      {
        filter: { ...filter, transactionSource: value },
      },
      () => {
        getOrderListPagination();
      },
    );
  };

  handleChangeOrderType = value => {
    const { filter } = this.state;
    const { getOrderListPagination } = this;
    const { onSetPrevStateValue } = this.props;

    onSetPrevStateValue({ ...filter, orderType: value });

    this.setState(
      {
        filter: { ...filter, orderType: value },
      },
      () => {
        getOrderListPagination();
      },
    );
  };

  handleClickReset = () => {
    const { filter } = this.state;
    const { getOrderListPagination } = this;

    this.setState(
      {
        filter: {
          ...filter,
          orderType: undefined,
          transactionSource: undefined,
          selectedStatusPayment: undefined,
          page: 1,
        },
      },
      () => {
        getOrderListPagination();
      },
    );
  };

  handleSelectChangeRow = selectedRowKeys => {
    const {
      orderData: { orderShipmentList },
    } = this.props;
    const converted = [];

    selectedRowKeys.forEach(item => {
      const param = _.find(orderShipmentList, ['sales_order_id', item]);

      if (param) {
        const ifShop = param.order_type === productTypeForOrderCode.shop;

        if (!ifShop) {
          delete param.label_data.notes;
        }
        converted.push(param.label_data);
      }
    });

    this.setState({ selectedRowKeys, selectedRowPrint: converted });
  };

  handleButtonClickCancel = () => {
    this.setState({
      selectedRowKeys: [],
      selectedRowPrint: [],
    });
  };

  handleMouseEnter = record => {
    this.setState({ selectedId: record.sales_order_id });
  };

  handleMouseLeave = () => {
    this.setState({ selectedId: null });
  };

  handleButtonClickPrintLabel = data => {
    this.setState({ selectedRowPrintSingle: data, isOpenModalPrint: true });
  };

  handleCloseModal = value => {
    this.setState({ isOpenModalPrint: value, selectedRowPrintSingle: {} });
  };

  handleCloseModalTracking = value => {
    this.setState({ isOpenModalTracking: value, selectedIdTracking: null });
  };

  handleClickAirwayBill = salesInvoiceId => {
    this.setState({ isOpenModalTracking: true, selectedIdTracking: salesInvoiceId });
  };

  handleButtonDownload = () => {
    const {
      filter: { search, startDate, endDate, searchType, status },
      sort: { sortField, sortOrder },
    } = this.state;
    const {
      getShipmentReportExport,
      orderData: { fetchingExport },
    } = this.props;

    const params = {
      start_date: startDate,
      end_date: endDate,
      search,
      search_type: searchType,
      order_status: status,
      sort: sortField,
      sortOrder,
    };

    if (!fetchingExport) {
      getShipmentReportExport(params);
    }
  };

  resetPropChange(activeTab) {
    const { pagination } = this.state;
    const resetParam = CommonHelper.objectCloning(initialFilter);
    resetParam.status = activeTab;

    this.setState(
      {
        isLoading: true,
        filter: resetParam,
        limit: 10,
        pagination: { ...pagination, pageSize: 10 },
        selectedRowKeys: [],
        selectedRowPrint: [],
      },
      () => {
        this.getOrderListPagination();
      },
    );
  }

  renderInformationCard = row => {
    const {
      filter: { status },
    } = this.state;

    let label1 = 'Air Waybill Number';
    let label2 = 'Delivery Status';
    let value1 = row.tracking_number;
    let value2 = row.delivery_status;

    if (status === orderShipmentStatus[0].value) {
      label1 = 'Delivery Service';
      label2 = 'Air Waybill Number';
      value1 = `${_.upperCase(row.shipping_method)} - ${row.shipping_title}`;
      value2 = row.tracking_number;
    }

    return (
      <Grid container direction="row">
        <Grid item lg={6} md={6} className="pr-24">
          <Grid container direction="column">
            <Grid item>
              <label className="text-12 text-opacity-50 text-bold">{label1}</label>
            </Grid>
            <Grid item>
              <span className="label-icon-prefix">
                <i className="ic-ffo-delivery-date container-icon-prefix size-14" />
              </span>
              {label1 === 'Air Waybill Number' ? (
                <Link onClick={() => this.handleClickAirwayBill(row.sales_invoice_id)}>
                  <label className="text-12 text-airwaybill">{value1 || 'Not Available'}</label>
                </Link>
              ) : (
                <label className="text-12">{value1}</label>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={6} md={6}>
          <Grid container direction="column">
            <Grid item>
              <label className="text-12 text-opacity-50 text-bold">{label2}</label>
            </Grid>
            <Grid item>
              <span className="label-icon-prefix">
                <i className="ic-ffo-pin container-icon-prefix size-14" />
              </span>
              {label2 === 'Air Waybill Number' ? (
                <Link onClick={() => this.handleClickAirwayBill(row.sales_invoice_id)}>
                  <label className="text-12 text-airwaybill">{value2 || 'Not Available'}</label>
                </Link>
              ) : (
                <label className="text-12 wrapping-container-break wrapping-container second line">
                  {value2}
                </label>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  renderColumns = () => {
    const { selectedId, isResellerLogin } = this.state;

    return [
      {
        title: 'Buyer Name',
        dataIndex: 'name',
        sorter: true,
        render: (text, row) => {
          const ifShop = row.order_type === productTypeForOrderCode.shop;
          const dataPrintLabel = row.label_data;

          if (!ifShop) {
            delete dataPrintLabel.notes;
          }

          return (
            <Grid container key={row.sales_order_id}>
              <Grid item lg={1} md={1} className="section-icon">
                <div>
                  <i
                    className={`ic-ffo-lg-shop container-icon-prefix size-40 orders-icon-purchase`}
                  />
                </div>
              </Grid>
              <Grid item lg={11} md={11}>
                <Grid
                  container
                  direction="column"
                  className="container-row-content flex-wrap-unset"
                >
                  <Grid item className="pb-24">
                    <Grid container className="container-text-header">
                      <Grid
                        item
                        lg={8}
                        md={8}
                        className="left"
                        onClick={() => {
                          this.handleClickRow(row.sales_invoice_id);
                        }}
                      >
                        <div className="flex-column">
                          <label className="text-14 text-bold wrapping-container wrapping-container-break mb-8">
                            {row.user_name}
                          </label>
                          <label className="text-12 text-product-name">{row.products}</label>
                        </div>
                      </Grid>
                      <Grid item lg={4} md={4} className="right">
                        <Grid container justify="flex-end" direction="row">
                          <Grid item className="section-currency">
                            {row.payment_method === paymentMethod.FitPoint ? (
                              <img src={Icons.fitPoints} alt="Fit Points" className="pr-8" />
                            ) : (
                              <label className="text-18 text-bold currency pr-8">IDR</label>
                            )}
                            <label className="text-18 text-bold currency pr-8">
                              {row.total_paid !== 0
                                ? CommonHelper.formatCurrency(row.total_paid)
                                : 0}
                            </label>
                            <LabelStatusPayment status={parseInt(row.payment_status, 10)} />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container justify="space-between" spacing={2}>
                      <Grid item lg={6} md={6} className="left">
                        {this.renderInformationCard(row)}
                      </Grid>
                      <Grid item lg={6} md={6}>
                        {row.sales_order_id !== selectedId ? (
                          <Grid container justify="flex-end" spacing={2}>
                            <Grid item lg={4} md={4}>
                              <Grid container direction="column">
                                <Grid item>
                                  <label className="text-10 text-bold text-silver-sand">
                                    Order Number
                                  </label>
                                </Grid>
                                <Grid item>
                                  <label className="text-12 text-bold text-opacity-50">
                                    {row.order_no}
                                  </label>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item lg={4} md={4} className="grid-left-hr">
                              <Grid container direction="column">
                                <Grid item>
                                  <label className="text-10 text-bold text-silver-sand">Date</label>
                                </Grid>
                                <Grid item>
                                  <label className="text-12 text-bold text-opacity-50">
                                    {`${CommonHelper.dateTimeParseNewFormat(
                                      row.order_date,
                                      'DD MMM YYYY',
                                    )}`}
                                  </label>
                                </Grid>
                                <Grid item>
                                  <label className="text-10 text-bold text-silver-sand">Time</label>
                                </Grid>
                                <Grid item>
                                  <label className="text-12 text-bold text-opacity-50">
                                    {`${CommonHelper.dateTimeParseNewFormat(
                                      row.order_date,
                                      'hh:mm A',
                                    )}`}
                                  </label>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item lg={3} md={3} className="grid-left-hr">
                              <Grid container direction="column">
                                <Grid item>
                                  <label className="text-10 text-bold text-silver-sand">
                                    Payment method
                                  </label>
                                </Grid>
                                <Grid item>
                                  <label className="text-12 text-bold text-opacity-50">
                                    {row.payment_method_title}
                                  </label>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        ) : (
                          !isResellerLogin && (
                            <Grid container justify="flex-end">
                              <Grid item lg={4} md={4}>
                                <ButtonMain
                                  labelText="Print Label"
                                  onClick={() => this.handleButtonClickPrintLabel(dataPrintLabel)}
                                  type="ghost"
                                  size="md"
                                />
                              </Grid>
                            </Grid>
                          )
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item className="pt-24">
                    <Grid container direction="row" justify="flex-start">
                      <Grid item className="section-label-filter">
                        <label className="text-12 text-bold">
                          Order ID: {row.sales_order_id || '-'}
                        </label>
                      </Grid>
                      <Grid item className="section-label-filter">
                        <label className="text-12 text-bold">
                          Source: {_.capitalize(row.transaction_source)}
                        </label>
                      </Grid>
                      <Grid item className="section-label-filter">
                        <label className="text-12 text-bold">
                          W-Order ID: {row.external_order_id || '-'}
                        </label>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          );
        },
      },
    ];
  };

  renderFilter() {
    const { filter, selectedRowKeys, selectedRowPrint, isResellerLogin } = this.state;
    const {
      orderData: { shipmentExport, fetchingExport },
      activeTab,
    } = this.props;
    const resultShowButton = selectedRowKeys.length > 0;

    const customOption = _.filter(optionSearchType, ({ value }) => value !== 'category');
    return (
      <Grid container direction="column" className="flex-wrap-unset">
        <Grid item>
          <Grid container justify="space-between" alignItems="center" className="container-filter">
            <Grid item lg={4} md={4} className="row-filter">
              <div className="container-pre-post-custom">
                <TextInputPrePost
                  placeholder="Search Order"
                  currentValue={filter.search}
                  onChange={this.handleChangeSearch}
                  prefix={'ic-ffo-search'}
                  customElementsBefore={
                    <div className="container-custom-select">
                      <SelectInputMain
                        options={customOption}
                        currentValue={this.state.filter.searchType}
                        onChange={this.handleChangeSearchType}
                      />
                    </div>
                  }
                />
              </div>
            </Grid>
            <Grid item lg="auto" md="auto">
              <div className="flex-column">
                <Grid container justify="flex-end" alignItems="center">
                  <Grid item lg={3} md={3} className="text-right">
                    <label className="text-12 pr-16">Showing Date</label>
                  </Grid>
                  <Grid item lg={4} md={4}>
                    <div className="container-remove-margin">
                      <PickerInputDate
                        customIcon="ic-ffo-date-pick"
                        dateFormat="dd/MM/yyyy"
                        defaultValue={this.state.filter.startDate}
                        onChange={this.handleChangeStartDate}
                        toolbar={false}
                      />
                    </div>
                  </Grid>
                  <label className="text-12 pr-8 pl-8"> - </label>
                  <Grid item lg={4} md={4}>
                    <div className="container-remove-margin">
                      <PickerInputDate
                        customIcon="ic-ffo-date-pick"
                        dateFormat="dd/MM/yyyy"
                        minDate={filter.startDate}
                        defaultValue={this.state.filter.endDate}
                        onChange={this.handleChangeEndDate}
                        toolbar={false}
                      />
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </Grid>
        {!isResellerLogin && (
          <Grid item className="mt-24">
            <Grid container justify="space-between" className="section-filter" alignItems="center">
              <Grid item lg={8} md={8}>
                <Grid container justify="flex-start" className="container-filter">
                  <Grid item lg={4} md={4} className="row-filter flex-column">
                    <SelectInputMain
                      options={optionTransactionSource}
                      size="middle"
                      currentValue={filter.transactionSource}
                      onChange={this.handleChangeTransactionSource}
                      placeholder={'Select Source Type'}
                    />
                  </Grid>
                  <Grid item lg={4} md={4} className="row-filter flex-column">
                    <SelectInputMain
                      options={optionOrderType}
                      size="middle"
                      currentValue={filter.orderType}
                      onChange={this.handleChangeOrderType}
                      placeholder={'Select Order Type'}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item lg="auto" md="auto">
                <ButtonMain
                  type="ghost"
                  size="md"
                  labelText="Clear Filter"
                  onClick={this.handleClickReset}
                  startIcon="ic-ffo-uncheck-mark"
                />
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid item className="mt-24">
          <Grid container justify="space-between" alignItems="center" className="container-filter">
            <Grid item lg={5} md={5} className="row-filter">
              {resultShowButton && activeTab === MasterDataHelper.orderShipmentStatus[0].value && (
                <Grid container alignItems="flex-start">
                  <Grid item>
                    <GeneratorPdf
                      data={selectedRowPrint}
                      buttonType="primary"
                      buttonLabel="Print Label"
                      customLabel="label-shipping-02"
                    />
                  </Grid>
                  <Grid item className="pl-16">
                    <ButtonMain
                      labelText="Cancel"
                      onClick={this.handleButtonClickCancel}
                      type="ghost"
                      size="md"
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
            {!isResellerLogin && (
              <Grid item lg="auto" md="auto">
                <Grid container justify="flex-end" alignItems="center">
                  <Grid item className="grid-btn-download">
                    <GeneratorExcel
                      buttonSize="md"
                      dataSetArray={shipmentExport}
                      fileName={`Shipment_Report_${this.state.filter.status}_${this.state.filter.startDate}_${this.state.filter.endDate}`}
                      iconPrefix="ic-ffo-download"
                      isLoading={fetchingExport}
                      onClick={this.handleButtonDownload}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    );
  }

  render() {
    const {
      orderData: { orderShipmentList },
      activeTab,
    } = this.props;
    const {
      isLoading,
      loading,
      pagination,
      toastInformation,
      selectedRowKeys,
      isOpenModalPrint,
      selectedRowPrintSingle,
      selectedIdTracking,
      isOpenModalTracking,
    } = this.state;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.handleSelectChangeRow,
    };

    const disablePrintButton = activeTab !== MasterDataHelper.orderShipmentStatus[0].value;

    let renderElement = <SkeletonMain />;

    if (!isLoading) {
      renderElement = (
        <div className="container-page-shipment-content-page scroll-container">
          <div className="container-page-scrolling-area include-tab">
            <Grid container direction="column">
              <Grid item lg md sm className="section-page-header">
                {this.renderFilter()}
              </Grid>
              <Grid item lg md className="section-table-body">
                {_.isEmpty(orderShipmentList) ? (
                  <Grid container justify={'center'}>
                    <EmptyState source={Images.emptyStateNoHistory} labelText={'No Order Found'} />
                  </Grid>
                ) : (
                  <Table
                    rowSelection={{
                      type: 'checkbox',
                      ...rowSelection,
                    }}
                    columns={this.renderColumns()}
                    rowKey={record => record.sales_order_id}
                    dataSource={orderShipmentList}
                    pagination={pagination}
                    loading={loading}
                    onChange={this.handleTableChange}
                    onRow={record => {
                      return {
                        onMouseEnter: () => {
                          this.handleMouseEnter(record);
                        }, // mouse enter row
                        onMouseLeave: () => {
                          this.handleMouseLeave();
                        },
                      };
                    }}
                  />
                )}
              </Grid>
            </Grid>
            <SnackBarSimple
              open={toastInformation.isOpen}
              durationHide={2000}
              message={toastInformation.message}
              onClickClose={this.handleCloseToash}
              snackbarType={toastInformation.snackbarType}
              anchor={optionToash}
            />
            <ModalPreviewPrintLabel
              isOpen={isOpenModalPrint}
              data={selectedRowPrintSingle}
              onClose={this.handleCloseModal}
              disableButton={disablePrintButton}
            />
            {isOpenModalTracking ? (
              <ModalShipmentTracking
                isOpen={isOpenModalTracking}
                orderId={selectedIdTracking}
                onClose={this.handleCloseModalTracking}
              />
            ) : null}
          </div>
        </div>
      );
    }
    return renderElement;
  }
}

const mapDispatchToProps = dispatch => ({
  orderListPagination: params => getOrderShipmentList(dispatch, params),
  getShipmentReportExport: params => getShipmentExport(dispatch, params),
});

const mapStateToProps = ({ masterDataMain, orderData, usersReducer }) => ({
  masterDataMain,
  orderData,
  usersReducer,
});

ShipmentContentPage.propTypes = {
  activeTab: PropTypes.string.isRequired,
  getShipmentReportExport: PropTypes.func,
  history: PropTypes.object,
  onSetPrevStateValue: PropTypes.func,
  orderData: PropTypes.object,
  orderListPagination: PropTypes.func,
  usersReducer: PropTypes.object,
};

const shell = compose(connect(mapStateToProps, mapDispatchToProps));

export default shell(PrevStateValue(ShipmentContentPage));
