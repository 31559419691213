/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Card, CardContent } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// Style
import './CardPermissionAddStyle.scss';
// Component
import { ButtonMain } from '../../../../../../components/Index';
import { PermissionAccess, PermissionModule, PermissionPage } from '../../../../../../helpers/PermissionHelper';

const CardPermissionAdd = props => {
  const { onButtonClickAdd, valueName } = props;

  const handleButtonClick = () => {
    onButtonClickAdd(valueName);
  };

  return (
    <Card className="container-card-add">
      <CardContent>
        <Grid container justify="center" alignItems="stretch">
          <Grid item>
            <div className="container-card-add-body">
              <ButtonMain
                labelText="Add Permission"
                onClick={handleButtonClick}
                type="primary"
                startIcon="ic-ffo-add"
                size="md"
                requiredPermission={`${PermissionModule.UserManagement}.${PermissionPage.RolesAndPermissions}.${PermissionAccess.Add}`}
              />
            </div>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

CardPermissionAdd.propTypes = {
  onButtonClickAdd: PropTypes.func,
  valueName: PropTypes.string,
};

CardPermissionAdd.defaultProps = {
  valueName: null,
};

export default CardPermissionAdd;
