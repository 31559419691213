import { StyleSheet } from '@react-pdf/renderer';
import Themes from '../../../../../themes/Index';

const StylesJs = StyleSheet.create({
  containerLogo: { height: 28, width: '90%' },
  marginBottomThin: {
    marginBottom: 2.5,
  },
  fontBold: {
    fontWeight: 'bold',
  },
  fontNormal: {
    fontWeight: 'normal',
  },
  titleText: {
    color: Themes.palette.black,
    fontSize: 26,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
  },
  subTitleText: {
    color: Themes.palette.black,
    fontSize: 15,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
  },
  text: {
    fontSize: 12,
    color: Themes.palette.black,
    fontFamily: 'Roboto',
    lineHeight: 1.3,
  },
  borderVertical: {
    borderTop: 1,
    borderTopColor: Themes.palette.black,
    borderBottom: 1,
    borderBottomColor: Themes.palette.black,
  },
  paddingVertical15: {
    paddingTop: 15,
    paddingBottom: 15,
  },
  paddingVertical8: {
    paddingTop: 8,
    paddingBottom: 8,
  },
  paddingHorizontal8: {
    paddingLeft: 8,
    paddingRight: 8,
  },
});

export default StylesJs;
