/* eslint-disable react/no-access-state-in-setstate */
import React from 'react';
import PropTypes from 'prop-types';
import { Breadcrumbs, Grid, Link } from '@material-ui/core';
import { Table } from 'antd';
import { connect } from 'react-redux';
import { compose } from 'redux';
import _ from 'lodash';
// Style
import './MembershipDashboardPaginationStyle.scss';
// component
import {
  AuthenticationAccessPages,
  GeneratorExcel,
  NavigationButtonGroup,
  PickerInputDate,
  SnackBarSimple,
} from '../../../../components/Index';
import { CommonHelper, currencyCode } from '../../../../helpers/Index';
// api
import {
  getMembershipStatusRecurringListPagination,
  getMembershipStatusRecurringPrint,
} from '../../../../services/api/DashboardApi';
import { Helmet } from 'react-helmet';

const tabButtonData = [
  { value: 'recurring', name: 'Recurring' },
  { value: 'expiring', name: 'Expiring' },
];

const initialSort = {
  sortField: 'customer_name',
  sortOrder: 'asc',
};

const initialFilter = {
  membershipStatus: null,
};

const optionToash = {
  vertical: 'top',
  horizontal: 'right',
};

class MembershipDashboardPagination extends React.Component {
  constructor(props) {
    super(props);
    const {
      match: { params },
    } = this.props;

    const updateExitingForm = {
      ...CommonHelper.objectCloning(initialFilter),
      ...CommonHelper.decryptObject(params.param_object),
    };

    this.state = {
      filter: updateExitingForm,
      limit: 10,
      page: 1,
      pagination: {
        showSizeChanger: true,
        showTotal: (total, range) => `Showing ${range[0]}-${range[1]} from ${total}`,
        pageSizeOptions: ['10', '20', '30', '40'],
      },
      sort: CommonHelper.objectCloning(initialSort),
      toastInformation: {
        isOpen: false,
        message: '',
        snackbarType: 'warning',
      },
    };
  }

  componentDidMount() {
    this.getMembershipStatusRecurringListPagination();
  }

  getMembershipStatusRecurringListPagination() {
    const {
      filter: { membershipStatus, startDate, endDate },
      page,
      limit,
      pagination,
      sort,
    } = this.state;
    const {
      membershipStatusRecurringListPagination,
      dashboardData: { fetchingListRecurring },
    } = this.props;

    const params = {
      start_date: startDate,
      end_date: endDate,
      page,
      limit,
      sort: sort.sortField,
      sortOrder: sort.sortOrder,
    };

    if (!fetchingListRecurring && startDate && endDate) {
      membershipStatusRecurringListPagination(membershipStatus, params).then(response => {
        this.setState({
          pagination: { ...pagination, pageSize: limit, total: response.data.total },
        });
      });
    }
  }

  handleClick = (event, value) => {
    const { history } = this.props;
    event.preventDefault();
    history.push(value);
  };

  handleOnGroupButtonClickStatusRecurring = value => {
    const { filter } = this.state;

    this.setState(
      {
        filter: { ...filter, membershipStatus: value },
        page: 1,
      },
      () => {
        this.getMembershipStatusRecurringListPagination();
      },
    );
  };

  handleChangeStartDate = value => {
    const { filter } = this.state;
    const endDate = CommonHelper.getEndDateMonth(value);
    
    this.setState(
      {
        filter: { ...filter, startDate: value, endDate },
      },
      () => {
        this.getMembershipStatusRecurringListPagination();
      },
    );
  };

  handleChangeEndDate = value => {
    const { filter } = this.state;

    this.setState(
      {
        filter: { ...filter, endDate: value },
      },
      () => {
        this.getMembershipStatusRecurringListPagination();
      },
    );
  };

  // handleButtonPrint = () => {
  handleButtonDownload = () => {
    const {
      filter: { membershipStatus, startDate, endDate },
      sort,
    } = this.state;
    const {
      membershipStatusRecurringPrint,
      dashboardData: { fetchingPrint },
    } = this.props;

    const params = {
      start_date: startDate,
      end_date: endDate,
      sort: sort.sortField,
      sortOrder: sort.sortOrder,
    };

    if (!fetchingPrint && startDate && endDate) {
      membershipStatusRecurringPrint(membershipStatus, params);
    }
  };

  handleTableChange = (pagination, filters, sorter) => {
    const { sort } = this.state;

    this.setState(
      {
        limit: pagination.pageSize,
        pagination: {
          ...this.state.pagination,
          current: pagination.current,
          pageSize: pagination.pageSize,
        },
        sort: {
          ...sort,
          sortField: sorter.field || initialSort.sortField,
          sortOrder: sorter.order === 'ascend' || !sorter.order ? 'asc' : 'desc',
        },
        page: pagination.current,
      },
      () => {
        this.getMembershipStatusRecurringListPagination();
      },
    );
  };

  renderColumns = () => {
    const { filter } = this.state;
    const activeColumn = [
      {
        title: 'Customer',
        dataIndex: 'customer_name',
        sorter: true,
        render: (text, row) => {
          return (
            <div className="flex-column">
              <label className="text-12 text-semi-bold wrapping-container second line wrapping-container-break mb-8">
                {row.customer_name}
              </label>
              <label className="text-10 text-semi-bold text-rolling-stone">
                {row.product_name}
              </label>
            </div>
          );
        },
      },
      {
        title: 'Contact',
        dataIndex: 'customer_phone',
        width: '18%',
        align: 'center',
        sorter: true,
        render: (text, row) => {
          return (
            <div className="flex-column">
              <label className="text-12 text-semi-bold mb-8">{row.customer_phone}</label>
              <label className="text-10 text-semi-bold text-rolling-stone">
                {row.customer_email}
              </label>
            </div>
          );
        },
      },
      {
        title: 'Price',
        dataIndex: 'total',
        width: '18%',
        align: 'center',
        sorter: true,
        render: (text, row) => {
          const wordingPrice = `${currencyCode.idr} ${CommonHelper.formatCurrency(row.total)}`;

          return <label className="text-12 text-semi-bold">{wordingPrice}</label>;
        },
      },
    ];

    if (filter.membershipStatus === tabButtonData[0].value) {
      activeColumn.push({
        title: 'Recurring Date',
        dataIndex: 'recurring_date',
        width: '18%',
        align: 'center',
        sorter: true,
        render: (text, row) => {
          const completedDate =
            CommonHelper.dateTimeParseNewFormat(row.recurring_date, 'DD MMM YYYY') || '-';

          return <label className="text-12 text-semi-bold text-jade">{completedDate}</label>;
        },
      });
    } else {
      activeColumn.push({
        title: 'Expired Date',
        dataIndex: 'expired_at',
        width: '18%',
        align: 'center',
        sorter: true,
        render: (text, row) => {
          const completedDate =
            CommonHelper.dateTimeParseNewFormat(row.expired_at, 'DD MMM YYYY') || '-';

          return (
            <label className="text-12 text-semi-bold text-sea-buckthorn">{completedDate}</label>
          );
        },
      });
    }

    return activeColumn;
  };

  renderTable = () => {
    const { filter, pagination } = this.state;
    const {
      dashboardData: { fetchingListRecurring, listRecurring, fetchingPrint, documentPrint },
    } = this.props;

    let dataPrint = {};

    if (!_.isEmpty(documentPrint)) {
      dataPrint = documentPrint;
    }

    return (
      <div className="flex-column">
        <div className="ph-16 pt-24 container-main-card-half-top">
          <Grid container className="flex-wrap-unset" justify="space-between" alignItems="center">
            <Grid item>
              <NavigationButtonGroup
                currentValue={filter.membershipStatus}
                onClick={this.handleOnGroupButtonClickStatusRecurring}
                data={tabButtonData}
              />
            </Grid>
            <Grid item>
              <GeneratorExcel
                buttonSize="xl"
                dataSetArray={dataPrint}
                fileName={`membership_list_${filter.startDate}_${filter.endDate}`}
                iconPrefix="ic-ffo-download"
                isButtonIcon
                onClick={this.handleButtonDownload}
                isLoading={fetchingPrint}
              />
            </Grid>
          </Grid>
        </div>
        <Table
          columns={this.renderColumns()}
          rowKey={record => record.sales_order_id || record.user_point_booster_id}
          dataSource={listRecurring}
          pagination={pagination}
          loading={fetchingListRecurring}
          onChange={this.handleTableChange}
        />
      </div>
    );
  };

  renderFilter = () => {
    const { filter } = this.state;

    return (
      <Grid container spacing={2} justify="flex-end" alignItems="center">
        <Grid item lg={3} md={3}>
          <label className="text-14">Showing Period</label>
        </Grid>
        <Grid item lg={4} md={4}>
          <PickerInputDate
            customIcon="ic-ffo-date-pick"
            dateFormat="dd/MM/yyyy"
            maxDate={filter.endDate}
            defaultValue={filter.startDate}
            onChange={this.handleChangeStartDate}
            toolbar={false}
          />
        </Grid>
        <Grid>
          <label className="text-14 pr-8 pl-8">-</label>
        </Grid>
        <Grid item lg={4} md={4}>
          <PickerInputDate
            customIcon="ic-ffo-date-pick"
            dateFormat="dd/MM/yyyy"
            minDate={filter.startDate}
            defaultValue={filter.endDate}
            onChange={this.handleChangeEndDate}
            toolbar={false}
          />
        </Grid>
      </Grid>
    );
  };

  render() {
    const { toastInformation } = this.state;
    const prevUrl = '/dashboard';

    return (
      <div>
        <Helmet title="FITCO | Membership" />
        <div className="container-page-dashboard-membership-list scroll-container">
          <div className="container-page-scrolling-area">
            <Grid container direction="column" className="flex-wrap-unset">
              <Grid item lg md className="section-page-header">
                <Grid container justify="space-between" alignItems="center">
                  <Grid item lg md>
                    <div className="breadcrumbs-section">
                      <Breadcrumbs aria-label="breadcrumb">
                        <Link
                          className="text-12"
                          color="inherit"
                          href={prevUrl}
                          onClick={event => {
                            this.handleClick(event, prevUrl);
                          }}
                        >
                          <i className="ic-ffo-dashboard container-icon-prefix size-16" />
                          Dashboard
                        </Link>
                        <label className="text-12" color="inherit">
                          Membership List
                        </label>
                      </Breadcrumbs>
                    </div>
                  </Grid>
                  <Grid item xl={5} lg={6} md={6}>
                    {this.renderFilter()}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item lg md className="section-page-body">
                {this.renderTable()}
              </Grid>
              <Grid item lg md className="section-page-footer" />
            </Grid>
          </div>
        </div>
        <SnackBarSimple
          open={toastInformation.isOpen}
          durationHide={2000}
          message={toastInformation.message}
          onClickClose={this.handleCloseToash}
          snackbarType={toastInformation.snackbarType}
          anchor={optionToash}
        />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  membershipStatusRecurringListPagination: (urlKey, params) =>
    getMembershipStatusRecurringListPagination(dispatch, urlKey, params),
  membershipStatusRecurringPrint: (urlKey, params) =>
    getMembershipStatusRecurringPrint(dispatch, urlKey, params),
});

const mapStateToProps = ({ dashboardData }) => ({
  dashboardData,
});

MembershipDashboardPagination.propTypes = {
  dashboardData: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
  membershipStatusRecurringListPagination: PropTypes.func,
  membershipStatusRecurringPrint: PropTypes.func,
};

const shell = compose(connect(mapStateToProps, mapDispatchToProps));

export default shell(AuthenticationAccessPages(MembershipDashboardPagination));
