/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React from 'react';
import { ResponsiveContainer, PieChart, Pie, Cell, Tooltip } from 'recharts';
import PropTypes from 'prop-types';
// Style
import './ChartPieGraphicStyle.scss';

const ChartPieGraphic = props => {
  const {
    dataArray,
    tooltipCustom,
    height,
    innerRadius,
    outerRadius,
    activeShape,
    labelPieCustomProps,
  } = props;

  const [activeIndex, setActiveIndex] = React.useState();

  const handlePieChartEnter = (a, b, c) => {
    console.log(a, b, c);
  };

  const onPieEnter = (data, index, e) => {
    setActiveIndex(index);
  };

  const onPieLeave = (data, index, e) => {
    setActiveIndex(null);
  };

  return (
    <ResponsiveContainer width="100%" height={height}>
      <PieChart onMouseEnter={handlePieChartEnter}>
        {tooltipCustom && <Tooltip content={tooltipCustom} />}
        <Pie
          data={dataArray}
          dataKey="value"
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          activeIndex={activeIndex}
          activeShape={activeShape}
          onMouseEnter={onPieEnter}
          onMouseLeave={onPieLeave}
          isAnimationActive={false}
          startAngle={0}
        >
          {dataArray.map((item, index) => (
            <Cell key={`slice-${index}`} fill={item.color} percent={item.percent} />
          ))}
          {labelPieCustomProps}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};

ChartPieGraphic.propTypes = {
  activeShape: PropTypes.element,
  dataArray: PropTypes.array,
  height: PropTypes.number,
  innerRadius: PropTypes.string,
  labelPieCustomProps: PropTypes.element,
  outerRadius: PropTypes.string,
  tooltipCustom: PropTypes.element,
};

ChartPieGraphic.defaultProps = {
  innerRadius: '25%',
  outerRadius: '40%',
};

export default ChartPieGraphic;
