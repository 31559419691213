import React from 'react';
import PropTypes from 'prop-types';
import { Page, Text, View, Document, Font, Image } from '@react-pdf/renderer';
import _ from 'lodash';
// Style
import StylesJs from './EatAtStoreInvoiceStyle';
// assets
import { Images, StyleJs as StyleJsGlobal } from '../../../../../assets/Index';
// Helpers
import { CommonHelper, paymentMethodOptionCode } from '../../../../../helpers/Index';

// Register font
Font.register({
  family: 'Roboto',
  fonts: [
    { src: './src/assets/fonts/montserrat/Montserrat-Regular.ttf' },
    { src: './src/assets/fonts/montserrat/Montserrat-Bold.ttf', fontWeight: 700 },
  ],
});

const EatAtStoreInvoice = props => {
  const { data } = props;

  const letterheadLogo = Images.fitcoLogoLocal;
  const instagramLogo = Images.instagramLogo;

  const customerDetails = !_.isEmpty(data.customer_details) ? data.customer_details : {};
  const shippingDetails = !_.isEmpty(data.shipping_details) ? data.shipping_details : {};
  const orderDetails = !_.isEmpty(data.order_details) ? data.order_details : {};
  const orderSummary = !_.isEmpty(data.order_summary) ? data.order_summary : {};
  const paymentDetails = !_.isEmpty(data.payment_details) ? data.payment_details : {};
  const branchDetails = !_.isEmpty(data.branch_details) ? data.branch_details : {};

  const showPromo = !_.isEmpty(paymentDetails.promo_code);
  const showQRIS =
    paymentDetails.payment_type === paymentMethodOptionCode.qris &&
    !_.isEmpty(paymentDetails.qr_string);

  let additionalPaymentText = null;
  let additionalPaymentValue = null;
  let qrCodeCanvas = null;
  let qrCodeDataUri = null;
  let isOnDemandDelivery = false;

  switch (paymentDetails.payment_type) {
    case paymentMethodOptionCode.bank_transfer:
      additionalPaymentText = 'VA Number';
      additionalPaymentValue = orderDetails.va_number;
      break;
    case paymentMethodOptionCode.gofood:
      additionalPaymentText = 'GoFood Ref.';
      additionalPaymentValue = paymentDetails.transaction_id;
      isOnDemandDelivery = true;
      break;
    case paymentMethodOptionCode.grabfood:
      additionalPaymentText = 'GrabFood Ref.';
      additionalPaymentValue = paymentDetails.transaction_id;
      isOnDemandDelivery = true;
      break;
    case paymentMethodOptionCode.shopeefood:
      additionalPaymentText = 'ShopeeFood Ref.';
      additionalPaymentValue = paymentDetails.transaction_id;
      isOnDemandDelivery = true;
      break;
    case paymentMethodOptionCode.shopeepay:
      additionalPaymentText = 'ShopeePay Ref.';
      additionalPaymentValue = paymentDetails.transaction_id;
      break;
    case paymentMethodOptionCode.qris:
      qrCodeCanvas = document.querySelector('canvas');
      qrCodeDataUri = qrCodeCanvas.toDataURL('image/jpg', 0.3);
      break;
    default:
      break;
  }

  let subtotal = orderDetails.subtotal / 1.11;
  let ppn = subtotal * 0.11;

  const isPanin = branchDetails && branchDetails.identification === 'fitco-eats-express-panin';
  if (isPanin && !isOnDemandDelivery) {
    subtotal = orderDetails.subtotal;
    ppn = orderDetails.tax;
  }

  return (
    <Document>
      <Page size={['55mm', 'auto']} orientation="portrait">
        <View style={[StyleJsGlobal.gridColumn, StyleJsGlobal.ph8, StyleJsGlobal.pv8]}>
          <View style={{ flex: 1, alignItems: 'center' }}>
            <Image src={letterheadLogo} style={[StylesJs.containerLogo]} />
            <Text
              style={[
                StylesJs.text,
                StylesJs.fontBold,
                StyleJsGlobal.mh8,
                { textTransform: 'uppercase' },
              ]}
            >
              Eats
            </Text>
            <View style={[StyleJsGlobal.ph8]}>
              <Text
                style={[
                  StylesJs.text,
                  StyleJsGlobal.mb8,
                  { fontWeight: '600', textAlign: 'center' },
                ]}
              >
                {shippingDetails.shipping_address}
              </Text>
            </View>
          </View>
          <View style={[StylesJs.divider, StyleJsGlobal.mh4]} />
          <Text style={[StylesJs.titleText, { textTransform: 'uppercase', textAlign: 'center' }]}>
            {'This is a Copy'}
          </Text>
          <View style={[StylesJs.divider, StyleJsGlobal.mh4]} />
          <View style={[StyleJsGlobal.ph4, StyleJsGlobal.pv4]}>
            <View style={[StyleJsGlobal.gridRow, StyleJsGlobal.justifyContentSpaceBetween]}>
              <View style={[StyleJsGlobal.gridItem6]}>
                <Text style={[StylesJs.text, { fontWeight: '600' }]}>{'Receipt Number'}</Text>
              </View>
              <View style={[StyleJsGlobal.gridItem6, { flex: 1, alignItems: 'flex-end' }]}>
                <Text style={[StylesJs.text, { fontWeight: '600' }]}>
                  {orderDetails.sales_order_id}
                </Text>
              </View>
            </View>
            <View style={[StyleJsGlobal.gridRow, StyleJsGlobal.justifyContentSpaceBetween]}>
              <View style={[StyleJsGlobal.gridItem6]}>
                <Text style={[StylesJs.text, { fontWeight: '600' }]}>{'Order Ref.'}</Text>
              </View>
              <View style={[StyleJsGlobal.gridItem6, { flex: 1, alignItems: 'flex-end' }]}>
                <Text style={[StylesJs.text, { fontWeight: '600' }]}>{orderDetails.order_no}</Text>
              </View>
            </View>
            <View style={[StyleJsGlobal.gridRow, StyleJsGlobal.justifyContentSpaceBetween]}>
              <View style={[StyleJsGlobal.gridItem6]}>
                <Text style={[StylesJs.text, { fontWeight: '600' }]}>{'Date & Time'}</Text>
              </View>
              <View style={[StyleJsGlobal.gridItem6, { flex: 1, alignItems: 'flex-end' }]}>
                <Text style={[StylesJs.text, { fontWeight: '600' }]}>
                  {CommonHelper.dateTimeParseNewFormat(
                    orderDetails.order_date,
                    'DD MMM YYYY, HH:mm',
                  )}
                </Text>
              </View>
            </View>
            <View style={[StyleJsGlobal.gridRow, StyleJsGlobal.justifyContentSpaceBetween]}>
              <View style={[StyleJsGlobal.gridItem6]}>
                <Text style={[StylesJs.text, { fontWeight: '600' }]}>{'Customer'}</Text>
              </View>
              <View style={[StyleJsGlobal.gridItem6, { flex: 1, alignItems: 'flex-end' }]}>
                <Text style={[StylesJs.text, { fontWeight: '600' }]}>
                  {customerDetails.customer_name}
                </Text>
              </View>
            </View>
            <View style={[StyleJsGlobal.gridRow, StyleJsGlobal.justifyContentSpaceBetween]}>
              <View style={[StyleJsGlobal.gridItem6]}>
                <Text style={[StylesJs.text, { fontWeight: '600' }]}>{'Served By'}</Text>
              </View>
              <View style={[StyleJsGlobal.gridItem6, { flex: 1, alignItems: 'flex-end' }]}>
                <Text style={[StylesJs.text, { fontWeight: '600' }]}>
                  {orderDetails.creator_name}
                </Text>
              </View>
            </View>
            <View style={[StyleJsGlobal.gridRow, StyleJsGlobal.justifyContentSpaceBetween]}>
              <View style={[StyleJsGlobal.gridItem6]}>
                <Text style={[StylesJs.text, { fontWeight: '600' }]}>{'Payment'}</Text>
              </View>
              <View style={[StyleJsGlobal.gridItem6, { flex: 1, alignItems: 'flex-end' }]}>
                <Text style={[StylesJs.text, { fontWeight: '600' }]}>
                  {orderDetails.payment_type_title}
                </Text>
              </View>
            </View>
            {additionalPaymentText && (
              <View style={[StyleJsGlobal.gridRow, StyleJsGlobal.justifyContentSpaceBetween]}>
                <View style={[StyleJsGlobal.gridItem6]}>
                  <Text style={[StylesJs.text, { fontWeight: '600' }]}>
                    {additionalPaymentText}
                  </Text>
                </View>
                <View style={[StyleJsGlobal.gridItem6, { flex: 1, alignItems: 'flex-end' }]}>
                  <Text style={[StylesJs.text, { fontWeight: '600' }]}>
                    {additionalPaymentValue}
                  </Text>
                </View>
              </View>
            )}
          </View>
          <View style={[StylesJs.divider, StyleJsGlobal.mh4]} />
          <View style={[StyleJsGlobal.ph4, StyleJsGlobal.pv4]}>
            <View style={[StyleJsGlobal.mb2]}>
              <Text
                style={[StylesJs.titleText, { textTransform: 'uppercase', textAlign: 'center' }]}
              >
                {'ORDER SUMMARY'}
              </Text>
            </View>
            {orderSummary.product_details.map(item => {
              const notes = item.details.notes;
              const word1 = notes && notes.length > 19 ? notes.substring(0, 19) : notes;
              let word2 = null;
              if (notes && notes.length > 19) {
                word2 = notes.length > 19 ? notes.substring(19) : null;
                word2 =
                  !_.isEmpty(word2) && word2.length > 27 ? `${word2.substring(0, 24)}...` : word2;
              }
              return (
                <View
                  key={item.product_id}
                  style={[StyleJsGlobal.gridItem12, { marginTop: 2, marginBottom: 2 }]}
                >
                  <View style={[StyleJsGlobal.gridItem9]}>
                    <Text style={[StylesJs.text]}>{item.product_name}</Text>
                  </View>
                  {word1 && (
                    <View style={[StyleJsGlobal.gridItem9, { marginBottom: 2 }]}>
                      <View style={[StyleJsGlobal.flexColumn]}>
                        <Text style={[StylesJs.text]}>{`Note: ${word1.toLowerCase()}`}</Text>
                        {word2 && <Text style={[StylesJs.text]}>{word2.toLowerCase()}</Text>}
                      </View>
                    </View>
                  )}
                  <View style={[StyleJsGlobal.gridRow, StyleJsGlobal.justifyContentSpaceBetween]}>
                    <View style={[StyleJsGlobal.gridItem6]}>
                      <Text style={[StylesJs.text]}>{`${
                        item.quantity
                      }x @ Rp ${CommonHelper.formatCurrency(item.item_price) || '0'}`}</Text>
                    </View>
                    <View style={[StyleJsGlobal.gridItem6, { flex: 1, alignItems: 'flex-end' }]}>
                      <Text style={[StylesJs.text]}>
                        {`Rp ${CommonHelper.formatCurrencyWithDecimals(
                          item.quantity * item.item_price,
                        )}`}
                      </Text>
                    </View>
                  </View>
                </View>
              );
            })}
          </View>
          <View style={[StylesJs.divider, StyleJsGlobal.mh4]} />
          <View style={[StyleJsGlobal.ph4, StyleJsGlobal.pv4]}>
            <View style={[StyleJsGlobal.gridRow, StyleJsGlobal.justifyContentSpaceBetween]}>
              <View style={[StyleJsGlobal.gridItem6]}>
                <Text style={[StylesJs.text, { fontWeight: '600' }]}>{'Subtotal'}</Text>
              </View>
              <View style={[StyleJsGlobal.gridItem6, { flex: 1, alignItems: 'flex-end' }]}>
                <Text style={[StylesJs.text, { fontWeight: '600' }]}>
                  {`Rp ${CommonHelper.formatCurrencyWithDecimals(subtotal)}`}
                </Text>
              </View>
            </View>
            <View style={[StyleJsGlobal.gridRow, StyleJsGlobal.justifyContentSpaceBetween]}>
              <View style={[StyleJsGlobal.gridItem6]}>
                <Text style={[StylesJs.text, { fontWeight: '600' }]}>{'Tax'}</Text>
              </View>
              <View style={[StyleJsGlobal.gridItem6, { flex: 1, alignItems: 'flex-end' }]}>
                <Text
                  style={[StylesJs.text, { fontWeight: '600' }]}
                >{`Rp ${CommonHelper.formatCurrencyWithDecimals(ppn)}`}</Text>
              </View>
            </View>
            {parseFloat(orderDetails.service_charge) > 0 && (
              <View style={[StyleJsGlobal.gridRow, StyleJsGlobal.justifyContentSpaceBetween]}>
                <View style={[StyleJsGlobal.gridItem6]}>
                  <Text style={[StylesJs.text, { fontWeight: '600' }]}>{'Service Charge'}</Text>
                </View>
                <View style={[StyleJsGlobal.gridItem6, { flex: 1, alignItems: 'flex-end' }]}>
                  <Text
                    style={[StylesJs.text, { fontWeight: '600' }]}
                  >{`Rp ${CommonHelper.formatCurrencyWithDecimals(
                    orderDetails.service_charge,
                  )}`}</Text>
                </View>
              </View>
            )}
            {showPromo && (
              <View style={[StyleJsGlobal.gridItem12, { marginTop: 4 }]}>
                <View style={[StyleJsGlobal.gridItem9]}>
                  <Text style={[StylesJs.textBold]}>{`Promo - ${paymentDetails.promo_code}`}</Text>
                </View>
                <View style={[StyleJsGlobal.gridRow, StyleJsGlobal.justifyContentSpaceBetween]}>
                  <View style={[StyleJsGlobal.gridItem6]}>
                    <Text style={[StylesJs.text, { fontWeight: '600' }]}>{`${
                      paymentDetails.is_cashback ? 'Cashback' : 'Discount'
                    } (${paymentDetails.cashback_percentage}%)`}</Text>
                  </View>
                  <View style={[StyleJsGlobal.gridItem6, { flex: 1, alignItems: 'flex-end' }]}>
                    <Text style={[StylesJs.text, { fontWeight: '600' }]}>
                      {paymentDetails.is_cashback
                        ? `${CommonHelper.formatCurrencyWithDecimals(
                            paymentDetails.cashback_amount,
                          )} FIT Points`
                        : `-Rp ${CommonHelper.formatCurrencyWithDecimals(
                            paymentDetails.cashback_amount,
                          )}`}
                    </Text>
                  </View>
                </View>
              </View>
            )}
          </View>
          <View style={[StylesJs.divider, StyleJsGlobal.mh4]} />
          <View style={[StyleJsGlobal.ph4, StyleJsGlobal.pv4]}>
            <View style={[StyleJsGlobal.gridRow, StyleJsGlobal.justifyContentSpaceBetween]}>
              <View style={[StyleJsGlobal.gridItem6]}>
                <Text style={[StylesJs.textBold]}>{'Grand Total'}</Text>
              </View>
              <View style={[StyleJsGlobal.gridItem6, { flex: 1, alignItems: 'flex-end' }]}>
                <Text style={[StylesJs.text, { fontWeight: '600' }]}>
                  {`Rp ${CommonHelper.formatCurrencyWithDecimals(orderDetails.grand_total)}`}
                </Text>
              </View>
            </View>
          </View>
          <View style={[StylesJs.divider, StyleJsGlobal.mh4]} />
          <View>
            {showQRIS && (
              <View style={[StyleJsGlobal.ph8, StyleJsGlobal.pv8, StyleJsGlobal.mt8]}>
                <Text style={[StylesJs.text, { fontSize: 8, textAlign: 'center' }]}>
                  {'Scan this QR to pay'}
                </Text>
                <Image src={qrCodeDataUri} style={[StylesJs.pages]} />
              </View>
            )}
            <Text
              style={[
                StylesJs.titleText,
                StyleJsGlobal.mv4,
                { textTransform: 'uppercase', textAlign: 'center' },
              ]}
            >
              {'THANK YOU!'}
            </Text>
          </View>

          <View style={[StylesJs.divider, StyleJsGlobal.mh4]} />
          <View style={[StyleJsGlobal.mt4]}>
            <View style={[StyleJsGlobal.gridRow, StyleJsGlobal.alignItemCenter]}>
              <Image src={instagramLogo} />
              <Text style={[StylesJs.textFooter, { fontWeight: '600', marginLeft: 4 }]}>
                {'fitco.id'}
              </Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

EatAtStoreInvoice.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default EatAtStoreInvoice;
