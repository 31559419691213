/* eslint-disable no-plusplus */
/* eslint-disable radix */
import Moment from 'moment';
import { CommonHelper } from './Index';
import { UserRoleName, UserHelper } from './UserHelper';

Moment.locale('id');

const dayList = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

const CalendarHelper = {
  getWeekDayByToday(date) {
    let result = null;
    const today = Moment(date);
    const fromDate = today
      .startOf('week')
      .format('YYYY-MM-DD')
      .toString();
    const toDate = today
      .endOf('week')
      .format('YYYY-MM-DD')
      .toString();

    // commented because old moment start date is start from monday // now start from sunday for 1 week
    // this function to get next week start dan end date
    // if (toDate === CommonHelper.currentDate('YYYY-MM-DD')) {
    //   today = Moment(CommonHelper.intervalDate(date, 'YYYY-MM-DD', 1, 'day'));
    //   fromDate = today
    //     .startOf('week')
    //     .format('YYYY-MM-DD')
    //     .toString();
    //   toDate = today
    //     .endOf('week')
    //     .format('YYYY-MM-DD')
    //     .toString();
    // }

    const formDateInterval = CommonHelper.intervalDate(fromDate, 'YYYY-MM-DD', 0, 'day');
    const toDateInterval = CommonHelper.intervalDate(toDate, 'YYYY-MM-DD', 0, 'day');

    const param = {
      startWeek: formDateInterval,
      endWeek: toDateInterval,
    };

    result = param;
    return result;
  },
  generateCalenderHeaderData(dataArrayDay) {
    const converted = [];

    if (dataArrayDay.length > 0) {
      dataArrayDay.forEach(item => {
        const dayValue = Moment(item.date)
          .locale('en')
          .format('ddd')
          .toString();
        const sortDayValue = Moment(item.date)
          .locale('en')
          .format('DD MMM')
          .toString();

        const params = {
          day: dayValue.toUpperCase(),
          date: sortDayValue,
          value: item.date,
          isLoading: true,
        };

        converted.push(params);
      });
    }

    const result = converted;
    return result;
  },
  generateCalendarCardList(dataArray) {
    const result = [];

    dataArray.forEach(item => {
      const paramConvert = CalendarHelper.generateCalenderCardSummary(item);
      result.push(paramConvert);
    });

    return result;
  },
  generateCalenderCardSummary(dataArraySchedule) {
    let params = [];

    if (dataArraySchedule) {
      const dateSchedule = CommonHelper.dateTimeParseNewFormat(
        dataArraySchedule.start_time,
        'YYYY-MM-DD',
      );
      const startTime = CommonHelper.dateTimeParseNewFormat(dataArraySchedule.start_time, 'HH:mm');
      const endTime = CommonHelper.dateTimeParseNewFormat(dataArraySchedule.end_time, 'HH:mm');

      const parseDuration = CommonHelper.getTimeDuration(
        dataArraySchedule.start_time,
        dataArraySchedule.end_time,
      );

      let appointment = dataArraySchedule.total_appointment;
      if (dataArraySchedule.total_appointment === undefined) {
        appointment = dataArraySchedule.appointment;
      }

      params = {
        scheduleId: dataArraySchedule.training_schedule_id,
        option: dataArraySchedule,
        date: dateSchedule,
        scheduleName: dataArraySchedule.name,
        dateStart: startTime,
        dateEnd: endTime,
        status: dataArraySchedule.status,
        duration: `${parseDuration} min`,
        slot: `${appointment}/${dataArraySchedule.capacity} Slots`,
        isAvailable: appointment !== dataArraySchedule.capacity,
        coachName: dataArraySchedule.coach_name || '-',
      };
    }
    const result = params;

    return result;
  },
  generateCalenderModalDetails(dataArrayScheduleDaily) {
    let params = null;

    if (dataArrayScheduleDaily) {
      const dateSchedule = CommonHelper.dateTimeParseNewFormat(
        dataArrayScheduleDaily.start_time,
        'YYYY-MM-DD',
      );
      const startTime = CommonHelper.dateTimeParseNewFormat(
        dataArrayScheduleDaily.start_time,
        'HH:mm',
      );
      const endTime = CommonHelper.dateTimeParseNewFormat(dataArrayScheduleDaily.end_time, 'HH:mm');
      const parseDuration = CommonHelper.getTimeDuration(
        dataArrayScheduleDaily.start_time,
        dataArrayScheduleDaily.end_time,
      );
      const slotLeft = dataArrayScheduleDaily.capacity - dataArrayScheduleDaily.appointment;
      const participantDataFinal = [];

      dataArrayScheduleDaily.participants.forEach(item => {
        const modifyTrainer = CommonHelper.renameKeyName([item.trainer], 'trainer_id', 'trainerId');

        const param = {
          clientName: item.client_name,
          clientPhone: CommonHelper.formatPhone(item.client_phone),
          clientEmail: item.client_email,
          trainer: modifyTrainer[0],
          appointmentId: item.appointment_id,
          trainingScheduleId: item.training_schedule_id,
          bookingDate: CommonHelper.dateTimeParseNewFormat(
            item.booking_date,
            'DD MMM YYYY, HH:mm',
          ).toUpperCase(),
          salesOrderId: item.sales_order_id,
        };
        participantDataFinal.push(param);
      });

      params = {
        scheduleId: dataArrayScheduleDaily.training_schedule_id,
        date: dateSchedule,
        shiftStatus: dataArrayScheduleDaily.outside_shift,
        scheduleName:
          dataArrayScheduleDaily.product_name ||
          `Session with ${dataArrayScheduleDaily.coach_name}`,
        dateStart: startTime,
        dateEnd: endTime,
        status: dataArrayScheduleDaily.status,
        duration: `${parseDuration} min`,
        slot: `${slotLeft} Slots Available`,
        coachName: dataArrayScheduleDaily.coach_name || '',
        isAvailable: dataArrayScheduleDaily.appointment !== dataArrayScheduleDaily.capacity,
        appointmentDate: CommonHelper.dateTimeParseNewFormat(dateSchedule, 'dddd, DD MMMM YYYY'),
        locationActivity: dataArrayScheduleDaily.location,
        participant: participantDataFinal,
        availableSlot: slotLeft,
        capacity: dataArrayScheduleDaily.capacity,
      };
    }
    const result = params;

    return result;
  },
  generateScheduleData(params) {
    const startDate = params.startDate;
    const repeatWeekDiff = 7 - CommonHelper.countLeftDayInWeek(startDate);
    const repeatWeekFinal = params.repeatWeek - repeatWeekDiff;

    const time = params.startTime;
    const to = params.endTime;
    const repeatDay = params.repeatDay;
    const repeatWeek = repeatWeekFinal;
    const repeatTime = params.repeatTime;
    const startTime = new Date(`${startDate} ${time}`);
    const endTime = new Date(`${startDate} ${to}`);
    const repeatHour = CalendarHelper.getRepeatHour(startTime, endTime, repeatTime);
    const scheduleTimeLine = [];

    if (time && to && startDate && repeatTime !== null && repeatDay && repeatWeek) {
      const newDate = new Date(startDate);
      for (let loopDay = 0; loopDay < repeatWeek; loopDay++) {
        const nextDate = this.getNextDay(loopDay, newDate);
        const newStartTime = new Date(`${nextDate} ${time}`);
        const newEndTime = new Date(`${nextDate} ${to}`);

        if (repeatDay.indexOf(dayList[newDate.getDay()]) !== -1) {
          if (repeatWeek) {
            if (repeatHour === 1) {
              const startTimeTitle = CommonHelper.dateTimeParseNewFormat(
                newStartTime.toString(),
                'HH:mm',
              );
              const endTimeTitle = CommonHelper.dateTimeParseNewFormat(
                newEndTime.toString(),
                'HH:mm',
              );
              const startTimeValue = CommonHelper.dateTimeParseNewFormat(
                newStartTime,
                'YYYY-MM-DD HH:mm:ss',
              );
              const endTimeValue = CommonHelper.dateTimeParseNewFormat(
                newEndTime,
                'YYYY-MM-DD HH:mm:ss',
              );

              scheduleTimeLine.push({
                title: `${startTimeTitle} - ${endTimeTitle}`,
                start: startTimeValue,
                end: endTimeValue,
              });
            } else {
              for (let addNewHour = 0; addNewHour < Math.ceil(repeatHour); addNewHour++) {
                const newStartTimeOther = new Date(`${nextDate} ${time}`);
                const newEndTimeOther = new Date(`${nextDate} ${time}`);
                newEndTimeOther.setTime(newEndTimeOther.getTime() + repeatTime * 60000);

                if (addNewHour > 0) {
                  const addHour = addNewHour * repeatTime;
                  newStartTimeOther.setTime(newStartTimeOther.getTime() + addHour * 60000);
                  newEndTimeOther.setTime(newEndTimeOther.getTime() + addHour * 60000);
                }
                const endDateCompare = new Date(`${nextDate} ${to}`);

                if (
                  !CommonHelper.compareDateAndTime(
                    newStartTimeOther.toString(),
                    endDateCompare.toString(),
                    'date',
                  ) &&
                  !CommonHelper.compareDateAndTime(
                    newEndTimeOther.toString(),
                    endDateCompare.toString(),
                    'date',
                  )
                ) {
                  const startTimeTitle = CommonHelper.dateTimeParseNewFormat(
                    newStartTimeOther.toString(),
                    'HH:mm',
                  );
                  const endTimeTitle = CommonHelper.dateTimeParseNewFormat(
                    newEndTimeOther.toString(),
                    'HH:mm',
                  );
                  const startTimeValue = CommonHelper.dateTimeParseNewFormat(
                    newStartTimeOther,
                    'YYYY-MM-DD HH:mm:ss',
                  );
                  const endTimeValue = CommonHelper.dateTimeParseNewFormat(
                    newEndTimeOther,
                    'YYYY-MM-DD HH:mm:ss',
                  );
                  scheduleTimeLine.push({
                    title: `${startTimeTitle} - ${endTimeTitle}`,
                    start: startTimeValue,
                    end: endTimeValue,
                  });
                }
              }
            }
          } else {
            const startTimeTitle = CommonHelper.dateTimeParseNewFormat(
              newStartTime.toString(),
              'HH:mm',
            );
            const endTimeTitle = CommonHelper.dateTimeParseNewFormat(
              newEndTime.toString(),
              'HH:mm',
            );
            const startTimeValue = CommonHelper.dateTimeParseNewFormat(
              newStartTime,
              'YYYY-MM-DD HH:mm:ss',
            );
            const endTimeValue = CommonHelper.dateTimeParseNewFormat(
              newEndTime,
              'YYYY-MM-DD HH:mm:ss',
            );
            scheduleTimeLine.push({
              title: `${startTimeTitle} - ${endTimeTitle}`,
              start: startTimeValue,
              end: endTimeValue,
            });
          }
        }
      }
    }

    return scheduleTimeLine;
  },
  getRepeatHour(startTime, endTime, repeatTime) {
    let repeatHour = 0;

    if (repeatTime === 0) {
      repeatHour = 1;
    } else {
      const hours = Math.abs(startTime.getTime() - endTime.getTime()) / 36e5;
      repeatHour = Math.ceil(hours) / (repeatTime / 60);
    }

    return repeatHour;
  },
  getNextDay(index, date) {
    if (index > 0) {
      date.setDate(date.getDate() + 1);
    }

    const result = CommonHelper.dateTimeParseNewFormat(date.toString(), 'YYYY-MM-DD');

    return result;
  },
  generateListWeek(param) {
    const startDate = param.startWeek;
    // const endDate = param.endWeek;
    const listWeek = [];

    for (let index = 0; index < 7; index++) {
      let dateValue = null;
      let flagPass = false;

      if (index === 0) {
        dateValue = startDate;
        flagPass = true;
      } else {
        const currentDate = CommonHelper.dateTimeParseNewFormat(startDate, 'YYYY-MM-DD');
        const intervalDate = CommonHelper.intervalDate(currentDate, 'YYYY-MM-DD', index, 'day');

        dateValue = intervalDate;
        flagPass = true;
      }

      if (flagPass) {
        const paramInsert = {
          date: dateValue,
        };
        listWeek.push(paramInsert);
      }
    }

    const result = listWeek;

    return result;
  },
  countTotalSession(dataArray) {
    const tmpTotalDay = [];
    const tmpTotalSessionInDay = [];

    dataArray.forEach((item, index) => {
      if (index === 0) tmpTotalDay.push(item.start);
      else {
        const compare01 = CommonHelper.dateTimeParseNewFormat(item.start, 'YYYY-MM-DD');
        const compare02 = CommonHelper.dateTimeParseNewFormat(
          tmpTotalDay[tmpTotalDay.length - 1],
          'YYYY-MM-DD',
        );
        if (compare01 !== compare02) {
          tmpTotalDay.push(item.start);
        }
      }
    });

    dataArray.forEach((item, index) => {
      if (index === 0) tmpTotalSessionInDay.push(item.start);
      else {
        const compare01 = CommonHelper.dateTimeParseNewFormat(item.start, 'YYYY-MM-DD');
        const compare02 = CommonHelper.dateTimeParseNewFormat(
          tmpTotalSessionInDay[tmpTotalSessionInDay.length - 1],
          'YYYY-MM-DD',
        );
        if (compare01 === compare02) {
          tmpTotalSessionInDay.push(item.start);
        }
      }
    });

    const param = {
      totalDay: tmpTotalDay.length,
      sessionDay: tmpTotalSessionInDay.length,
      totalSession: dataArray.length,
    };

    const result = param;
    return result;
  },
  getTimeLineRange(date01, date02) {
    const getDate01 = CommonHelper.dateTimeParseNewFormat(date01, 'DD MMM YYYY');
    const getDate02 = CommonHelper.dateTimeParseNewFormat(date02, 'DD MMM YYYY');

    const result = getDate01 === getDate02 ? getDate01 : `${getDate01} - ${getDate02}`;
    return result;
  },
  getTimeLineParam(dateArray) {
    const result = [];

    if (dateArray.length > 0) {
      dateArray.forEach(item => {
        const param = {
          start: item.start,
          end: item.end,
        };
        result.push(param);
      });
    }

    return result;
  },
  validateLoadingResult(dataArray) {
    let result = false;
    if (
      !dataArray.fetching01 &&
      !dataArray.fetching02 &&
      !dataArray.fetching03 &&
      !dataArray.fetching04 &&
      !dataArray.fetching05 &&
      !dataArray.fetching06 &&
      !dataArray.fetching07
    ) {
      result = true;
    }

    return result;
  },
  validationPassSchedule(currentDate, currentTime, date, time, isAvailable = true) {
    let result = false;
    if (currentDate === date) {
      if (
        !CommonHelper.compareDateAndTime(currentDate, date, 'date') &&
        !CommonHelper.compareDateAndTime(currentTime, `${time}:00`, 'time')
      ) {
        if (isAvailable) result = true;
      }
    } else if (!CommonHelper.compareDateAndTime(currentDate, date, 'date')) {
      if (isAvailable) result = true;
    }

    return result;
  },
  checkGracePeriodEditCoachSchedule(date, currentDate, passDisplay, existingCoach) {
    let gracePeriod = false;

    const graceDate = CommonHelper.intervalDate(date, 'YYYY-MM-DD', 7, 'day');
    const inGracePeriod = !CommonHelper.compareDateAndTime(currentDate, graceDate, 'date');
    gracePeriod = inGracePeriod;

    if (existingCoach) {
      const currentUser = UserHelper.getCurrentUserInformation();
      gracePeriod = currentUser.role_name === UserRoleName.SuperAdmin && inGracePeriod;
    }

    const allowEdit = !passDisplay || gracePeriod;
    const result = allowEdit;

    return result;
  },
};

export default CalendarHelper;
