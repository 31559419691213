export const constants = {
  getListEatReportExportRequest: 'GET_LIST_EAT_REPORT_EXPORT_REQUEST',
  getListEatReportExportSuccess: 'GET_LIST_EAT_REPORT_EXPORT_SUCCESS',
  getListEatReportExportFail: 'GET_LIST_EAT_REPORT_EXPORT_FAIL',
  getListEatPaginationRequest: 'GET_LIST_EAT_PAGINATION_REQUEST',
  getListEatPaginationSuccess: 'GET_LIST_EAT_PAGINATION_SUCCESS',
  getListEatPaginationFail: 'GET_LIST_EAT_PAGINATION_FAIL',
  getEatDetailsRequest: 'GET_EAT_DETAILS_REQUEST',
  getEatDetailsSuccess: 'GET_EAT_DETAILS_SUCCESS',
  getEatDetailsFail: 'GET_EAT_DETAILS_FAIL',
  setDeleteEatRequest: 'SET_DELETE_EAT_REQUEST',
  setDeleteEatSuccess: 'SET_DELETE_EAT_SUCCESS',
  setDeleteEatFail: 'SET_DELETE_EAT_FAIL',
  setCreateEatRequest: 'SET_CREATE_EAT_REQUEST',
  setCreateEatSuccess: 'SET_CREATE_EAT_SUCCESS',
  setCreateEatFail: 'SET_CREATE_EAT_FAIL',
  setUpdateEatRequest: 'SET_UPDATE_EAT_REQUEST',
  setUpdateEatSuccess: 'SET_UPDATE_EAT_SUCCESS',
  setUpdateEatFail: 'SET_UPDATE_EAT_FAIL',
  getListMealDeliveryPaginationRequest: 'GET_LIST_MEAL_DELIVERY_PAGINATION_REQUEST',
  getListMealDeliveryPaginationSuccess: 'GET_LIST_MEAL_DELIVERY_PAGINATION_SUCCESS',
  getListMealDeliveryPaginationFail: 'GET_LIST_MEAL_DELIVERY_PAGINATION_FAIL',
  getMealDeliveryDetailRequest: 'GET_MEAL_DELIVERY_DETAIL_REQUEST',
  getMealDeliveryDetailSuccess: 'GET_MEAL_DELIVERY_DETAIL_SUCCESS',
  getMealDeliveryDetailFail: 'GET_MEAL_DELIVERY_DETAIL_FAIL',
  getListMealDeliveryExportRequest: 'GET_LIST_MEAL_DELIVERY_EXPORT_REQUEST',
  getListMealDeliveryExportSuccess: 'GET_LIST_MEAL_DELIVERY_EXPORT_SUCCESS',
  getListMealDeliveryExportFail: 'GET_LIST_MEAL_DELIVERY_EXPORT_FAIL',
  getListFoodlistRequest: 'GET_LIST_FOODLIST_REQUEST',
  getListFoodlistSuccess: 'GET_LIST_FOODLIST_SUCCESS',
  getListFoodlistFail: 'GET_LIST_FOODLIST_FAIL',
  changeMealDeliveryDetailsRequest: 'CHANGE_MEAL_DELIVERY_DETAILS_REQUEST',
  changeMealDeliveryDetailsSuccess: 'CHANGE_MEAL_DELIVERY_DETAILS_SUCCESS',
  changeMealDeliveryDetailsFail: 'CHANGE_MEAL_DELIVERY_DETAILS_FAIL',
  skipMealDeliveryRequest: 'SKIP_MEAL_DELIVERY_REQUEST',
  skipMealDeliverySuccess: 'SKIP_MEAL_DELIVERY_SUCCESS',
  skipMealDeliveryFail: 'SKIP_MEAL_DELIVERY_FAIL',
  changeMealDeliveryAddressRequest: 'CHANGE_MEAL_DELIVERY_DATE_REQUEST',
  changeMealDeliveryAddressSuccess: 'CHANGE_MEAL_DELIVERY_DATE_SUCCESS',
  changeMealDeliveryAddressFail: 'CHANGE_MEAL_DELIVERY_DATE_FAIL',
  getRoomTypeRequest: 'GET_ROOM_TYPE_REQUEST',
  getRoomTypeSuccess: 'GET_ROOM_TYPE_SUCCESS',
  getRoomTypeFail: 'GET_ROOM_TYPE_FAIL',
  getDietTypeRequest: 'GET_DIET_TYPE_REQUEST',
  getDietTypeSuccess: 'GET_DIET_TYPE_SUCCESS',
  getDietTypeFail: 'GET_DIET_TYPE_FAIL',
  getCuisineTypeRequest: 'GET_CUISINE_TYPE_REQUEST',
  getCuisineTypeSuccess: 'GET_CUISINE_TYPE_SUCCESS',
  getCuisineTypeFail: 'GET_CUISINE_TYPE_FAIL',
  setCreatePatientRequest: 'SET_CREATE_PATIENT_REQUEST',
  setCreatePatientSuccess: 'SET_CREATE_PATIENT_SUCCESS',
  setCreatePatientFail: 'SET_CREATE_PATIENT_FAIL',
  getPatientListPaginationRequest: 'GET_PATIENT_LIST_PAGINATION_REQUEST',
  getPatientListPaginationSuccess: 'GET_PATIENT_LIST_PAGINATION_SUCCESS',
  getPatientListPaginationFail: 'GET_PATIENT_LIST_PAGINATION_FAIL',
  getPatientDetailRequest: 'GET_PATIENT_DETAIL_REQUEST',
  getPatientDetailSuccess: 'GET_PATIENT_DETAIL_SUCCESS',
  getPatientDetailFail: 'GET_PATIENT_DETAIL_FAIL',
  getPatientMealListRequest: 'GET_PATIENT_MEAL_LIST_REQUEST',
  getPatientMealListSuccess: 'GET_PATIENT_MEAL_LIST_SUCCESS',
  getPatientMealListFail: 'GET_PATIENT_MEAL_LIST_FAIL',
  createPatientMealPlanRequest: 'CREATE_PATIENT_MEAL_PLAN_REQUEST',
  createPatientMealPlanSuccess: 'CREATE_PATIENT_MEAL_PLAN_SUCCESS',
  createPatientMealPlanFail: 'CREATE_PATIENT_MEAL_PLAN_FAIL',
  updatePatientDetailRequest: 'UPDATE_PATIENT_DETAIL_REQUEST',
  updatePatientDetailSuccess: 'UPDATE_PATIENT_DETAIL_SUCCESS',
  updatePatientDetailFail: 'UPDATE_PATIENT_DETAIL_FAIL',
  setCheckoutPatientRequest: 'SET_CHECKOUT_PATIENT_REQUEST',
  setCheckoutPatientSuccess: 'SET_CHECKOUT_PATIENT_SUCCESS',
  setCheckoutPatientFail: 'SET_CHECKOUT_PATIENT_FAIL',
  exportPatientMealPlanRequest: 'EXPORT_PATIENT_MEAL_PLAN_REQUEST',
  exportPatientMealPlanSuccess: 'EXPORT_PATIENT_MEAL_PLAN_SUCCESS',
  exportPatientMealPlanFail: 'EXPORT_PATIENT_MEAL_PLAN_FAIL',
  getListPatientExportRequest: 'GET_LIST_PATIENT_EXPORT_REQUEST',
  getListPatientExportSuccess: 'GET_LIST_PATIENT_EXPORT_SUCCESS',
  getListPatientExportFail: 'GET_LIST_PATIENT_EXPORT_FAIL',
  printPatientLabelRequest: 'PRINT_PATIENT_LABEL_REQUEST',
  printPatientLabelSuccess: 'PRINT_PATIENT_LABEL_SUCCESS',
  printPatientLabelFail: 'PRINT_PATIENT_LABEL_FAIL',
  searchPatientByMedicalRecordNumberRequest: 'SEARCH_PATIENT_BY_MEDICAL_RECORD_NUMBER_REQUEST',
  searchPatientByMedicalRecordNumberSuccess: 'SEARCH_PATIENT_BY_MEDICAL_RECORD_NUMBER_SUCCESS',
  searchPatientByMedicalRecordNumberFail: 'SEARCH_PATIENT_BY_MEDICAL_RECORD_NUMBER_FAIL',
  searchNearestEatStoreLocationRequest: 'SEARCH_NEAREST_EAT_STORE_LOCATION_REQUEST',
  searchNearestEatStoreLocationSuccess: 'SEARCH_NEAREST_EAT_STORE_LOCATION_SUCCESS',
  searchNearestEatStoreLocationFail: 'SEARCH_NEAREST_EAT_STORE_LOCATION_FAIL',
  getRoomNameListRequest: 'GET_ROOM_NAME_LIST_REQUEST',
  getRoomNameListSuccess: 'GET_ROOM_NAME_LIST_SUCCESS',
  getRoomNameListFail: 'GET_ROOM_NAME_LIST_FAIL',
  getFoodlistSummaryRequest: 'GET_FOODLIST_SUMMARY_REQUEST',
  getFoodlistSummarySuccess: 'GET_FOODLIST_SUMMARY_SUCCESS',
  getFoodlistSummaryFail: 'GET_FOODLIST_SUMMARY_FAIL',
  getFoodlistSummaryPrintRequest: 'GET_FOODLIST_SUMMARY_PRINT_REQUEST',
  getFoodlistSummaryPrintSuccess: 'GET_FOODLIST_SUMMARY_PRINT_SUCCESS',
  getFoodlistSummaryPrintFail: 'GET_FOODLIST_SUMMARY_PRINT_FAIL',
};

const initState = {
  fetching: false,
  fetchingExport: false,
  list: [],
  details: {},
  reportExport: [],
  errorFetch: false,
  errorMessage: null,
  responseMessage: null,
  mealDeliveryList: [],
  mealDetails: {},
  mealDeliveryExport: [],
  foodlist: [],
  roomType: [],
  dietType: [],
  cuisineType: [],
  patientList: [],
  patientDetail: {},
  patientMealList: [],
  exportPatientMealPlanList: [],
  printPatientLabel: [],
  patientByMedicalRecordNumber: {},
  eatStore: [],
  roomName: [],
  foodlistSummary: [],
  foodlistSummaryPrint: [],
};

export default function eatReducer(state = initState, action) {
  const { type, payload } = action;
  switch (type) {
    case constants.getListEatReportExportRequest:
      return { ...state, fetchingExport: true, responseMessage: null, reportExport: [] };
    case constants.getListEatReportExportSuccess:
      return {
        ...state,
        reportExport: payload.data,
        fetchingExport: false,
      };
    case constants.getListEatReportExportFail:
      return { ...state, fetchingExport: false, errorMessage: payload, reportExport: [] };

    case constants.getListEatPaginationRequest:
      return { ...state, fetching: true };
    case constants.getListEatPaginationSuccess:
      return {
        ...state,
        list: payload.data.data,
        fetching: false,
      };
    case constants.getListEatPaginationFail:
      return {
        ...state,
        fetching: false,
        errorFetch: true,
        errorMessage: payload,
      };

    case constants.getEatDetailsRequest:
      return { ...state, fetching: true };
    case constants.getEatDetailsSuccess:
      return {
        ...state,
        details: payload.data,
        fetching: false,
      };
    case constants.getEatDetailsFail:
      return {
        ...state,
        fetching: false,
        errorFetch: true,
        errorMessage: payload,
      };

    case constants.setDeleteEatRequest:
      return { ...state, fetching: true, responseMessage: null };
    case constants.setDeleteEatSuccess:
      return {
        ...state,
        responseMessage: payload.messages,
        fetching: false,
      };
    case constants.setDeleteEatFail:
      return { ...state, fetching: false, errorMessage: payload };

    case constants.setCreateEatRequest:
      return { ...state, fetching: true, responseMessage: null };
    case constants.setCreateEatSuccess:
      return {
        ...state,
        responseMessage: payload.messages,
        fetching: false,
      };
    case constants.setCreateEatFail:
      return { ...state, fetching: false, errorMessage: payload };

    case constants.setUpdateEatRequest:
      return { ...state, fetching: true, responseMessage: null };
    case constants.setUpdateEatSuccess:
      return {
        ...state,
        responseMessage: payload.messages,
        fetching: false,
      };
    case constants.setUpdateEatFail:
      return { ...state, fetching: false, errorMessage: payload };

    case constants.getListMealDeliveryPaginationRequest:
      return { ...state, fetching: true };
    case constants.getListMealDeliveryPaginationSuccess:
      return {
        ...state,
        mealDeliveryList: payload.data.data,
        fetching: false,
      };
    case constants.getListMealDeliveryPaginationFail:
      return {
        ...state,
        fetching: false,
        errorFetch: true,
        errorMessage: payload,
      };

    case constants.getMealDeliveryDetailRequest:
      return { ...state, fetching: true };
    case constants.getMealDeliveryDetailSuccess:
      return {
        ...state,
        mealDetails: payload.data,
        fetching: false,
      };
    case constants.getMealDeliveryDetailFail:
      return {
        ...state,
        fetching: false,
        errorFetch: true,
        errorMessage: payload,
      };

    case constants.getListMealDeliveryExportRequest:
      return { ...state, fetchingExport: true, responseMessage: null, mealDeliveryExport: [] };
    case constants.getListMealDeliveryExportSuccess:
      return {
        ...state,
        mealDeliveryExport: payload.data,
        fetchingExport: false,
      };
    case constants.getListMealDeliveryExportFail:
      return { ...state, fetchingExport: false, errorMessage: payload, mealDeliveryExport: [] };

    case constants.getListFoodlistRequest:
      return { ...state, fetching: true };
    case constants.getListFoodlistSuccess:
      return {
        ...state,
        foodlist: payload.data,
        fetching: false,
      };
    case constants.getListFoodlistFail:
      return { ...state, fetching: false, errorFetch: true, errorMessage: payload };

    case constants.changeMealDeliveryDetailsRequest:
      return { ...state, fetching: true };
    case constants.changeMealDeliveryDetailsSuccess:
      return {
        ...state,
        responseMessage: payload.messages,
        fetching: false,
      };
    case constants.changeMealDeliveryDetailsFail:
      return { ...state, fetching: false, errorFetch: true, errorMessage: payload };

    case constants.skipMealDeliveryRequest:
      return { ...state, fetching: true };
    case constants.skipMealDeliverySuccess:
      return {
        ...state,
        responseMessage: payload.messages,
        fetching: false,
      };
    case constants.skipMealDeliveryFail:
      return { ...state, fetching: false, errorFetch: true, errorMessage: payload };

    case constants.changeMealDeliveryAddressRequest:
      return { ...state, fetching: true };
    case constants.changeMealDeliveryAddressSuccess:
      return {
        ...state,
        responseMessage: payload.messages,
        fetching: false,
      };
    case constants.changeMealDeliveryAddressFail:
      return { ...state, fetching: false, errorFetch: true, errorMessage: payload };

    case constants.getRoomTypeRequest:
      return { ...state, fetching: true };
    case constants.getRoomTypeSuccess:
      return {
        ...state,
        roomType: payload.data,
        fetching: false,
      };
    case constants.getRoomTypeFail:
      return { ...state, fetching: false, errorFetch: true, errorMessage: payload };

    case constants.getDietTypeRequest:
      return { ...state, fetching: true };
    case constants.getDietTypeSuccess:
      return {
        ...state,
        dietType: payload.data,
        fetching: false,
      };
    case constants.getDietTypeFail:
      return { ...state, fetching: false, errorFetch: true, errorMessage: payload };

    case constants.getCuisineTypeRequest:
      return { ...state, fetching: true };
    case constants.getCuisineTypeSuccess:
      return {
        ...state,
        cuisineType: payload.data,
        fetching: false,
      };
    case constants.getCuisineTypeFail:
      return { ...state, fetching: false, errorFetch: true, errorMessage: payload };

    case constants.setCreatePatientRequest:
      return { ...state, fetching: true, responseMessage: null };
    case constants.setCreatePatientSuccess:
      return {
        ...state,
        responseMessage: payload.messages,
        fetching: false,
      };
    case constants.setCreatePatientFail:
      return { ...state, fetching: false, errorMessage: payload };

    case constants.getPatientListPaginationRequest:
      return { ...state, fetching: true };
    case constants.getPatientListPaginationSuccess:
      return {
        ...state,
        patientList: payload.data.data,
        fetching: false,
      };
    case constants.getPatientListPaginationFail:
      return {
        ...state,
        fetching: false,
        errorFetch: true,
        errorMessage: payload,
      };

    case constants.getPatientDetailRequest:
      return { ...state, fetching: true };
    case constants.getPatientDetailSuccess:
      return {
        ...state,
        patientDetail: payload.data,
        fetching: false,
      };
    case constants.getPatientDetailFail:
      return { ...state, fetching: false, errorFetch: true, errorMessage: payload };

    case constants.getPatientMealListRequest:
      return { ...state, fetching: true };
    case constants.getPatientMealListSuccess:
      return {
        ...state,
        patientMealList: payload.data,
        fetching: false,
      };
    case constants.getPatientMealListFail:
      return { ...state, fetching: false, errorFetch: true, errorMessage: payload };

    case constants.createPatientMealPlanRequest:
      return { ...state, fetching: false };
    case constants.createPatientMealPlanSuccess:
      return {
        ...state,
        responseMessage: payload.messages,
        fetching: false,
      };
    case constants.createPatientMealPlanFail:
      return { ...state, fetching: false, errorFetch: true, errorMessage: payload };

    case constants.updatePatientDetailRequest:
      return { ...state, fetching: true };
    case constants.updatePatientDetailSuccess:
      return {
        ...state,
        patientDetail: payload.data,
        fetching: false,
      };
    case constants.updatePatientDetailFail:
      return { ...state, fetching: false, errorFetch: true, errorMessage: payload };

    case constants.setCheckoutPatientRequest:
      return { ...state, fetching: true };
    case constants.setCheckoutPatientSuccess:
      return {
        ...state,
        responseMessage: payload.messages,
        fetching: false,
      };
    case constants.setCheckoutPatientFail:
      return { ...state, fetching: false, errorFetch: true, errorMessage: payload };

    case constants.exportPatientMealPlanRequest:
      return { ...state, exportPatientMealPlanList: [], fetching: true };
    case constants.exportPatientMealPlanSuccess:
      return {
        ...state,
        exportPatientMealPlanList: payload.data,
        fetching: false,
      };
    case constants.exportPatientMealPlanFail:
      return { ...state, fetching: false, errorFetch: true, errorMessage: payload };

    case constants.getListPatientExportRequest:
      return { ...state, fetchingExport: true, responseMessage: null, patientListExport: [] };
    case constants.getListPatientExportSuccess:
      return {
        ...state,
        patientListExport: payload.data,
        fetchingExport: false,
      };
    case constants.getListPatientExportFail:
      return { ...state, fetchingExport: false, errorMessage: payload, patientListExport: [] };

    case constants.printPatientLabelRequest:
      return { ...state, fetching: true };
    case constants.printPatientLabelSuccess:
      return {
        ...state,
        printPatientLabel: payload.data,
        fetching: false,
      };
    case constants.printPatientLabelFail:
      return { ...state, fetching: false, errorFetch: true, errorMessage: payload };

    case constants.searchPatientByMedicalRecordNumberRequest:
      return { ...state, fetching: true };
    case constants.searchPatientByMedicalRecordNumberSuccess:
      return {
        ...state,
        patientByMedicalRecordNumber: payload.data,
        fetching: false,
      };
    case constants.searchPatientByMedicalRecordNumberFail:
      return { ...state, fetching: false, errorFetch: true, errorMessage: payload };

    case constants.searchNearestEatStoreLocationRequest:
      return { ...state };
    case constants.searchNearestEatStoreLocationSuccess:
      return {
        ...state,
        eatStore: payload.data,
        fetching: false,
      };
    case constants.searchNearestEatStoreLocationFail:
      return { ...state, fetching: false, errorFetch: true, errorMessage: payload };

    case constants.getRoomNameListRequest:
      return { ...state, fetching: true };
    case constants.getRoomNameListSuccess:
      return {
        ...state,
        roomName: payload.data,
        fetching: false,
      };
    case constants.getRoomNameListFail:
      return { ...state, fetching: false, errorFetch: true, errorMessage: payload };

    case constants.getFoodlistSummaryRequest:
      return { ...state, fetching: true };
    case constants.getFoodlistSummarySuccess:
      return {
        ...state,
        foodlistSummary: payload.data,
        fetching: false,
      };
    case constants.getFoodlistSummaryFail:
      return { ...state, fetching: false, errorFetch: true, errorMessage: payload };

    case constants.getFoodlistSummaryPrintRequest:
      return { ...state, fetching: true };
    case constants.getFoodlistSummaryPrintSuccess:
      return {
        ...state,
        foodlistSummaryPrint: payload.data,
        fetching: false,
      };
    case constants.getFoodlistSummaryPrintFail:
      return { ...state, fetching: false, errorFetch: true, errorMessage: payload };

    default:
      return state;
  }
}
