import React from 'react';
import PropTypes from 'prop-types';
import { Grid, FormControl, FormLabel } from '@material-ui/core';
import _ from 'lodash';
// Style
import './CartItemDetailsEatsStyle.scss';
// Component
import { GridRowTableOneColumn, GridRowTableFiveColumn } from '../../../../../components/Index';
// helper
import {
  CommonHelper,
  OrderHelper,
  PermissionHelper,
  PermissionModule,
  PermissionPage,
  PermissionAccess,
} from '../../../../../helpers/Index';

const CartItemDetailsEats = props => {
  const { orderData, onClickEditMealBtn, onClickRefundBtn } = props;

  const hasUpdatePermission = PermissionHelper.checkUserHasPermission(
    PermissionModule.Eat,
    PermissionPage.MealDelivery,
    PermissionAccess.Update,
  );
  const hasDeletePermission = PermissionHelper.checkUserHasPermission(
    PermissionModule.Eat,
    PermissionPage.MealDelivery,
    PermissionAccess.Delete,
  );

  const orderSummaryData = !_.isEmpty(orderData) ? orderData : {};
  const orderItems = orderSummaryData.meal_items || [];
  const totalItem = !_.isEmpty(orderSummaryData.meal_items)
    ? orderSummaryData.meal_items.length
    : 0;

  const handleClickEditMeal = (notes, time, userCateringScheduleId) => {
    onClickEditMealBtn(notes, time, userCateringScheduleId);
  };

  const handleClickRefund = () => {
    onClickRefundBtn();
  };

  return (
    <FormControl
      component="fieldset"
      fullWidth
      margin="normal"
      className="container-order-cart-item-details-eats"
    >
      <FormLabel component="label" className="text-16 filed-title mb-8">
        Order Summary
      </FormLabel>
      <div className="flex-column">
        <GridRowTableFiveColumn
          customElementColumnFirst={<label className="text-14 header-title">Product Name</label>}
          customElementColumnSecond={
            <div className="text-center">
              <label className="text-14 header-title">Date</label>
            </div>
          }
          customElementColumnThird={
            <div className="text-center">
              <label className="text-14 header-title">Extras</label>
            </div>
          }
          customElementColumnFourth={
            <div className="text-center">
              <label className="text-14 header-title">Item</label>
            </div>
          }
          customElementColumnFifth={
            <div className="text-right">
              <label className="text-14 header-title">Total Price</label>
            </div>
          }
          columnFirst={4}
          columnSecond={3}
          columnThird={2}
          columnFourth={1}
          columnFifth={2}
        />
        <div className="scroll-container">
          <Grid container direction="column" className="flex-wrap-unset">
            {orderItems.map((item, index) => {
              const modifyDate = CommonHelper.dateTimeParseNewFormat(item.date, 'DD MMM YYYY');

              const wordingExtra = !_.isEmpty(item.extras)
                ? OrderHelper.generateExtraWordingDetails(item.extras)
                : '-';

              const priceItem = item.total_price * item.quantity;

              const wordingProductName = item.is_pax ? `${item.name} - ( Package )` : item.name;

              return (
                <GridRowTableFiveColumn
                  key={index}
                  customElementColumnFirst={
                    <div className="flex-column">
                      <div className="flex-row">
                        <label className="text-14 row-item-title wrapping-container mb-8 mr-8">
                          {wordingProductName}
                        </label>
                        {orderData.is_editable && hasUpdatePermission && (
                          <span
                            className="label-main-action-button primary mr-8"
                            role="button"
                            tabIndex={0}
                            onClick={() => {
                              handleClickEditMeal(
                                item.notes,
                                item.lunch_or_dinner,
                                item.user_catering_schedule_id,
                              );
                            }}
                            onKeyPress={() => {
                              handleClickEditMeal(
                                item.notes,
                                item.lunch_or_dinner,
                                item.user_catering_schedule_id,
                              );
                            }}
                          >
                            EDIT
                          </span>
                        )}
                        {orderData.is_editable && hasDeletePermission && (
                          <span
                            className="label-main-action-button danger"
                            role="button"
                            tabIndex={0}
                            onClick={() => {
                              handleClickRefund();
                            }}
                            onKeyPress={() => {
                              handleClickRefund();
                            }}
                          >
                            REFUND
                          </span>
                        )}
                      </div>
                      <span className="mb-8">
                        <label className="text-10 text-semi-bold mr-8">
                          TID: {item.member_point_transaction_id}
                        </label>
                      </span>
                      {item.notes && (
                        <span className="mb-8">
                          <label className="text-12">{item.notes}</label>
                        </span>
                      )}
                    </div>
                  }
                  customElementColumnSecond={
                    <div className="text-center">
                      <label className="text-14 text-semi-bold">{`${_.capitalize(
                        item.lunch_or_dinner,
                      )}, ${modifyDate}`}</label>
                    </div>
                  }
                  customElementColumnThird={
                    <div className="text-center">
                      <label className="text-14 text-semi-bold">{wordingExtra}</label>
                    </div>
                  }
                  customElementColumnFourth={
                    <div className="text-center">
                      <label className="text-14 text-semi-bold">{item.quantity}</label>
                    </div>
                  }
                  customElementColumnFifth={
                    <div className="text-right">
                      <label className="text-14 text-semi-bold">
                        {priceItem > 0 ? `IDR ${CommonHelper.formatCurrency(priceItem)}` : `Free`}
                      </label>
                    </div>
                  }
                  columnFirst={4}
                  columnSecond={3}
                  columnThird={2}
                  columnFourth={1}
                  columnFifth={2}
                />
              );
            })}
          </Grid>
        </div>
        <GridRowTableOneColumn
          customElementInitial={
            <div className="flex-column">
              <label className="text-14 row-item-title">Shipping Fee</label>
              <label className="text-10">{orderSummaryData.shipping_title || '-'}</label>
            </div>
          }
          customElementColumn={
            <div className="text-right">
              <label className="text-14 text-semi-bold">
                {orderSummaryData.shipping_fee > 0
                  ? `IDR ${CommonHelper.formatCurrency(orderSummaryData.shipping_fee)}`
                  : `Free`}
              </label>
            </div>
          }
          columnLeft={10}
          columnRight={2}
        />
        {orderSummaryData.discount !== 0 ? (
          <GridRowTableOneColumn
            customElementInitial={
              <div className="flex-column">
                <label className="text-14 row-item-title">Discount</label>
                {/* <label className="text-10 ">{orderSummaryData.promo_code}</label> */}
              </div>
            }
            customElementColumn={
              <div className="text-right">
                <label className="text-14 text-semi-bold discount">
                  IDR {CommonHelper.formatCurrency(orderSummaryData.discount)}
                </label>
              </div>
            }
            columnLeft={10}
            columnRight={2}
          />
        ) : null}
        <GridRowTableOneColumn
          customElementInitial={
            <Grid container>
              <Grid item lg={5} md={5}>
                <label className="text-16 row-item-title">Total Item(s) : {totalItem}</label>
              </Grid>
            </Grid>
          }
          customElementColumn={
            <div className="text-right">
              <label className="text-16 row-item-title">
                Grand Total &nbsp; &nbsp; &nbsp;{' '}
                {orderSummaryData.grand_total > 0
                  ? `IDR ${CommonHelper.formatCurrency(orderSummaryData.grand_total)}`
                  : `Free`}
              </label>
            </div>
          }
          columnLeft={7}
          columnRight={5}
        />
      </div>
    </FormControl>
  );
};

CartItemDetailsEats.propTypes = {
  onClickEditMealBtn: PropTypes.func,
  onClickRefundBtn: PropTypes.func,
  orderData: PropTypes.object,
};

export default CartItemDetailsEats;
