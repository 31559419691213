import Axios from 'axios';
// ENV
import { UPLOAD_IMAGE_URL, UPLOAD_IMAGE_SALT } from '../environments/Environment';

function handleApiSuccess(res, resolve) {
  resolve(res.data);
}

function handleApiError(err, reject) {
  reject(err);
}

function callUploadApi(url, method, params, body) {
  const response = new Promise((resolve, reject) => {
    Axios({
      method,
      url: `${UPLOAD_IMAGE_URL}/${url}`,
      headers: {
        Authorization: UPLOAD_IMAGE_SALT,
      },
      params: params || '',
      data: body || '',
    })
      .then(res => {
        handleApiSuccess(res, resolve);
      })
      .catch(err => {
        handleApiError(err.response, reject);
      });
  });
  return response;
}

export default {
  callUploadApi,
};
