/* eslint-disable no-unused-vars */
/* eslint-disable react/no-access-state-in-setstate */
import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Grid, Breadcrumbs, Link } from '@material-ui/core';
import PropTypes from 'prop-types';
import _ from 'lodash';
// Components
import {
  ButtonMain,
  LabelStatusMain,
  TextInput,
  SelectInputMain,
  GeneratorExcel,
  AuthenticationAccessPages,
} from '../../../../components/Index';
// import { SkeletonShippingDetails } from './components/Index';
// Style
import './ConductPTDetailsStyle.scss';
// Helper
import {
  MasterDataHelper,
  CommonHelper,
  HttpStatusCode,
  PermissionModule,
  PermissionPage,
  PermissionAccess,
} from '../../../../helpers/Index';
// Action
import {
  getConductReportDetailByTrainerId,
  getConductProductByTrainerId,
  getConductParticipantByTrainerId,
  getConductParticipantByTrainerIdExport,
} from '../../../../services/api/ReportApi';
import { Table } from 'antd';
import { compose } from 'redux';

const sessionTypeOption = MasterDataHelper.sessionTypeOption;

const initialFilter = {
  search: '',
  type: sessionTypeOption[0].value,
};

class ConductPtDetails extends React.Component {
  constructor(props) {
    super(props);
    const {
      match: { params },
    } = this.props;

    props.checkUserAccessPermission(
      PermissionModule.Report,
      PermissionPage.Conduct,
      PermissionAccess.View,
    );

    const updateExitingFilter = {
      ...CommonHelper.objectCloning(initialFilter),
      ...CommonHelper.decryptObject(params.param_object),
    };

    this.state = {
      filter: updateExitingFilter,
      isLoading: true,
      page: 1,
      limit: 10,
      countClick: 1,
      pagination: {
        showSizeChanger: true,
        showTotal: (total, range) => `Showing ${range[0]}-${range[1]} From ${total}`,
        pageSizeOptions: ['10', '20', '30', '40'],
      },
    };

    this.searchDebounce = _.debounce(this.searchDebounce, 400);
  }

  async componentDidMount() {
    await this.getConductReportDetailByTrainerId();
    await this.getConductProductByTrainerId();
    await this.getConductParticipantByTrainerId();
  }

  async getConductReportDetailByTrainerId() {
    const { filter } = this.state;
    const { conductReportDetailByTrainerId } = this.props;

    if (filter.trainerId) {
      const param = {
        trainer_id: filter.trainerId,
        start_date: filter.startDate,
        end_date: filter.endDate,
        branch_id: filter.branchId,
      };

      await conductReportDetailByTrainerId(param)
        .then(() => {
          this.setState({ isLoading: false });
        })
        .catch(error => {
          const serverMessage = error.data;
          const validationStatus = error.status === HttpStatusCode.InternalServerError;
          this.processMessage(
            validationStatus ? serverMessage.message : serverMessage.messages,
            'error',
          );
        });
    }
  }

  async getConductProductByTrainerId() {
    const { filter, countClick } = this.state;
    const { conductProductByTrainerId } = this.props;

    if (filter.trainerId) {
      const param = {
        trainer_id: filter.trainerId,
        start_date: filter.startDate,
        end_date: filter.endDate,
        branch_id: filter.branchId,
        page: countClick,
      };

      await conductProductByTrainerId(param)
        .then(response => {
          const modifyCountClick = _.isEmpty(response.data) ? countClick - 1 : countClick;
          this.setState({ isLoading: false, countClick: modifyCountClick });
        })
        .catch(error => {
          const serverMessage = error.data;
          const validationStatus = error.status === HttpStatusCode.InternalServerError;
          this.processMessage(
            validationStatus ? serverMessage.message : serverMessage.messages,
            'error',
          );
        });
    }
  }

  getConductParticipantByTrainerId() {
    const { conductParticipantByTrainerId } = this.props;
    const {
      limit,
      pagination,
      page,
      filter: { search, type, trainerId, startDate, endDate, branchId },
    } = this.state;

    const params = {
      search,
      type,
      trainer_id: trainerId,
      start_date: startDate,
      end_date: endDate,
      branch_id: branchId,
      page,
      limit,
    };

    conductParticipantByTrainerId(params).then(response => {
      this.setState({
        isLoading: false,
        pagination: { ...pagination, pageSize: limit, total: response.total },
      });
    });
  }

  handleClick = (event, value) => {
    const { history } = this.props;
    event.preventDefault();
    history.push(value);
  };

  handleButtonBack = () => {
    const { history } = this.props;
    history.push('/reports/conduct-pt');
  };

  handleButtonShowMoreConduct = () => {
    const { countClick } = this.state;
    this.setState({ countClick: countClick + 1 }, () => {
      this.getConductProductByTrainerId();
    });
  };

  handleChangeSearch = value => {
    const { filter } = this.state;

    this.setState(
      {
        filter: { ...filter, search: value },
        page: 1,
        pagination: { ...this.state.pagination, current: 1 },
      },
      () => {
        this.searchDebounce();
      },
    );
  };

  handleChangeStatus = value => {
    const { filter } = this.state;

    this.setState(
      {
        filter: { ...filter, type: value },
        page: 1,
        pagination: { ...this.state.pagination, current: 1 },
      },
      () => {
        this.getConductParticipantByTrainerId();
      },
    );
  };

  handleButtonDownload = () => {
    const {
      filter: { search, startDate, endDate, type, trainerId, branchId },
    } = this.state;
    const {
      conductParticipantByTrainerIdExport,
      reportData: { fetchingExport },
    } = this.props;

    const params = {
      search,
      type,
      trainer_id: trainerId,
      start_date: startDate,
      end_date: endDate,
      branch_id: branchId,
    };

    if (!fetchingExport) {
      conductParticipantByTrainerIdExport(params);
    }
  };

  searchDebounce = () => {
    this.getConductParticipantByTrainerId();
  };

  handleTableChange = (pagination, filters, sorter) => {
    const { sort } = this.state;

    this.setState(
      {
        limit: pagination.pageSize,
        pagination: {
          ...this.state.pagination,
          current: pagination.current,
          pageSize: pagination.pageSize,
        },
        // sort: {
        //   ...sort,
        //   sortField: sorter.field,
        //   sortOrder: sorter.order === 'ascend' ? 'asc' : 'desc',
        // },
        page: pagination.current,
      },
      () => {
        this.getConductParticipantByTrainerId();
      },
    );
  };

  renderColumns = () => {
    return [
      {
        title: 'Name',
        width: '30%',
        render: (text, row) => {
          return <label className="text-14 title">{row.name}</label>;
        },
      },
      {
        title: 'Memberships',
        width: '45%',
        render: (text, row) => {
          return <label className="text-14 normal">{row.memberships}</label>;
        },
      },
      {
        title: 'Type',
        width: '10%',
        align: 'center',
        render: (text, row) => {
          return <label className="text-14 normal">{row.type}</label>;
        },
      },
      {
        title: 'Booking Date',
        width: '15%',
        align: 'center',
        render: (text, row) => {
          const modify = CommonHelper.dateTimeParseNewFormat(row.booking_date, 'll');

          return <label className="text-14 normal">{modify}</label>;
        },
      },
    ];
  };

  renderFilter() {
    const {
      filter: { search, type, trainerId, startDate, endDate, branchId },
    } = this.state;
    const {
      reportData: { reportExport, fetchingExport },
    } = this.props;

    return (
      <Grid container justify="space-between" className="container-filter">
        <Grid item lg md>
          <Grid container justify="flex-start" alignItems="center">
            <Grid item className="row-filter">
              <label className="text-16 main-title">Participant</label>
            </Grid>
            <Grid item lg={5} md={5} className="row-filter">
              <TextInput
                iconPrefix="ic-ffo-search"
                placeHolderText="Search Campaign Name"
                onChange={this.handleChangeSearch}
                currentValue={search}
                size="md"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={5} md={5}>
          <Grid container justify="flex-end" alignItems="center">
            <Grid item lg={4} md={4}>
              <SelectInputMain
                options={sessionTypeOption}
                currentValue={type}
                onChange={this.handleChangeStatus}
              />
            </Grid>
            <Grid item>
              <GeneratorExcel
                buttonSize="xl"
                dataSetArray={reportExport}
                fileName={`Conduct_Report_details_Period_${startDate}_${endDate}`}
                iconPrefix="ic-ffo-download"
                isButtonIcon
                isLoading={fetchingExport}
                onClick={this.handleButtonDownload}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  renderStatus = value => {
    let renderElement = null;

    if (value) {
      renderElement = <LabelStatusMain value="Projected Value" type="projected" />;
    }

    return <div className="container-status">{renderElement}</div>;
  };

  render() {
    const {
      reportData: { fetching, details, participant, detailsProduct },
    } = this.props;
    const { filter, pagination, isLoading } = this.state;

    return (
      <div>
        <Helmet title={`FITCO | Reports - PT Conduct Details`} />
        <div className="container-page-conduct-pt-details scroll-container-invisible">
          <div className="container-page-scrolling-area">
            <Grid container direction="column" className="flex-wrap-unset">
              <Grid item lg md className="section-page-header">
                <Grid container>
                  <Grid item>
                    <div className="breadcrumbs-section">
                      <Breadcrumbs aria-label="breadcrumb">
                        <Link
                          className="text-12"
                          color="inherit"
                          href="/reports/conduct-pt"
                          onClick={event => {
                            this.handleClick(event, '/reports/conduct-pt');
                          }}
                        >
                          <i className="icon-slot ic-ffo-reports" /> Report
                        </Link>
                        <Link
                          className="text-12"
                          color="inherit"
                          href="/reports/conduct-pt"
                          onClick={event => {
                            this.handleClick(event, '/reports/conduct-pt');
                          }}
                        >
                          Conduct PT
                        </Link>
                        <label className="text-12" color="inherit">
                          Details
                        </label>
                      </Breadcrumbs>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item lg md className="section-page-body">
                {isLoading ? null : ( // <SkeletonShippingDetails />
                  <div className="flex-column">
                    <div className="container-details-card p-33 mb-32">
                      <div className="flex-column">
                        <Grid container justify="space-between" className="mb-32">
                          <Grid item lg md>
                            <div>
                              <label className="text-16 mb-8 main-title">Detail Conduct</label>
                              <span className="flex-row">
                                <label className="text-12 mr-8 title">Date :</label>
                                <label className="text-12">{`${CommonHelper.dateTimeParseNewFormat(
                                  filter.startDate,
                                  'DD MMM YYYY',
                                )} - ${CommonHelper.dateTimeParseNewFormat(
                                  filter.endDate,
                                  'DD MMM YYYY',
                                )}`}</label>
                              </span>
                            </div>
                          </Grid>
                          <Grid item lg={2} md={2}>
                            <ButtonMain
                              type="ghost"
                              size="xl"
                              labelText="Back"
                              onClick={this.handleButtonBack}
                            />
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          justify="space-between"
                          alignItems="center"
                          className="container-summary p-24 mb-32"
                        >
                          <Grid item lg md>
                            <div className="flex-column">
                              <div className="flex-row mb-8">
                                <label className="text-16 mr-8">{details.trainer_name}</label>
                                {this.renderStatus(details.projected_value)}
                              </div>
                              <Grid container spacing={3} justify="flex-start">
                                {/* <Grid item lg={2} md={2}> will un command in next development
                                  <div className="flex-column">
                                    <label className="text-12 mb-8 title">Base</label>
                                    <label className="text-12">{`IDR ${CommonHelper.formatCurrency(
                                      details.base_commission,
                                    )}`}</label>
                                  </div>
                                </Grid>
                                <Grid item>
                                  <div className="divider-hr-vertical" />
                                </Grid> */}
                                <Grid item lg={2} md={2}>
                                  <div className="flex-column">
                                    <label className="text-12 mb-8 title">Commission</label>
                                    <label className="text-12">{`IDR ${CommonHelper.formatCurrency(
                                      details.commission,
                                    )}`}</label>
                                  </div>
                                </Grid>
                                {/* <Grid item> will un command in next development
                                  <div className="divider-hr-vertical" />
                                </Grid>
                                <Grid item lg={2} md={2}>
                                  <div className="flex-column">
                                    <label className="text-12 mb-8 title">Penalty</label>
                                    <label className="text-12 deviation-text">{`IDR ${CommonHelper.formatCurrency(
                                      details.penalty,
                                    )}`}</label>
                                  </div>
                                </Grid> */}
                              </Grid>
                            </div>
                          </Grid>
                          <Grid item lg={2} md={2}>
                            <div className="flex-column text-align-center">
                              <label className="text-16 mb-4">{`IDR ${CommonHelper.formatCurrency(
                                details.conduct_value,
                              )}`}</label>
                              <label className="text-12 value">Conduct Value</label>
                            </div>
                          </Grid>
                        </Grid>
                        <Grid container justify="flex-start" alignItems="center" className="mb-32">
                          {/* <Grid item lg={2} md={2}> will un command in next development
                            <div className="flex-column">
                              <label className="text-12 mb-8 title">Target (Monthly)</label>
                              <label className="text-12">{details.target || 0}</label>
                            </div>
                          </Grid> */}
                          <Grid item lg={2} md={2}>
                            <div className="flex-column">
                              <label className="text-12 mb-8 title">Total Appointment</label>
                              <label className="text-12">{details.total_appointment || 0}</label>
                            </div>
                          </Grid>
                          <Grid item lg={2} md={2}>
                            <div className="flex-column">
                              <label className="text-12 mb-8 title">Total Conduct</label>
                              <label className="text-12 ">{details.conduct || 0}</label>
                            </div>
                          </Grid>
                          {/* <Grid item lg={2} md={2}> will un command in next development
                            <div className="flex-column">
                              <label className="text-12 mb-8 title">Bonus</label>
                              <label className="text-12 ">{`IDR ${CommonHelper.formatCurrency(
                                details.bonus || 0,
                              )}`}</label>
                            </div>
                          </Grid> */}
                          {/* <Grid item lg={2} md={2}>
                            <div className="flex-column">
                              <label className="text-12 mb-8 title">Average</label>
                              <label className="text-12 ">{`IDR ${CommonHelper.formatCurrency(
                                details.average || 0,
                              )}`}</label>
                            </div>
                          </Grid> */}
                        </Grid>
                        <Grid container direction="column" className="flex-wrap-unset">
                          <Grid item lg={12} md={12} className="mb-24">
                            <Grid
                              container
                              direction="column"
                              className="container-summary p-24 flex-wrap-unset"
                            >
                              <Grid item lg md className="mb-16">
                                <label className="text-14 mb-4">Conduct Product</label>
                              </Grid>
                              <Grid item lg md>
                                <Grid
                                  container
                                  justify="flex-start"
                                  spacing={1}
                                  className="scroll-container container-scrolling-area"
                                >
                                  {detailsProduct.map((item, index) => {
                                    return (
                                      <Grid key={index} item lg={4} md={4}>
                                        <div className="container-card-item p-22 flex-column">
                                          <label className="text-14 mb-8">
                                            {CommonHelper.renameRemoveCharacter(
                                              item.conducted_product,
                                              '-',
                                            )}
                                          </label>
                                          <div className="divider-hr" />
                                          <Grid container justify="space-between">
                                            <Grid item lg={8} md={8}>
                                              <label className="text-12 value">Total Session</label>
                                            </Grid>
                                            <Grid item lg={4} md={4} className="text-right">
                                              <label className="text-12">{item.total}</label>
                                            </Grid>
                                          </Grid>
                                          <div className="divider-hr" />
                                          <Grid container justify="space-between">
                                            <Grid item lg={8} md={8}>
                                              <label className="text-12 value">
                                                Commission Value
                                              </label>
                                            </Grid>
                                            <Grid item lg={4} md={4} className="text-right">
                                              <label className="text-12">
                                                IDR {CommonHelper.formatCurrency(item.commission)}
                                              </label>
                                            </Grid>
                                          </Grid>
                                        </div>
                                      </Grid>
                                    );
                                  })}
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item lg={12} md={12}>
                            <Grid container justify="center" alignItems="center">
                              <Grid item lg={2} md={2}>
                                <ButtonMain
                                  type="ghost"
                                  labelText="Show More"
                                  onClick={this.handleButtonShowMoreConduct}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                    <div className="container-details-card p-33">
                      <div className="flex-column">{this.renderFilter()}</div>
                      <Table
                        columns={this.renderColumns()}
                        rowKey={record => record.appointment_id}
                        dataSource={participant}
                        pagination={pagination}
                        loading={fetching}
                        rowClassName={(record, index) => (index % 2 === 0 ? 'odd-row-strip' : '')}
                        // scroll={{ y: adjustTableHeight }}
                        onChange={this.handleTableChange}
                      />
                    </div>
                  </div>
                )}
              </Grid>
              <Grid item lg md className="section-page-footer" />
            </Grid>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  conductReportDetailByTrainerId: params => getConductReportDetailByTrainerId(dispatch, params),
  conductProductByTrainerId: params => getConductProductByTrainerId(dispatch, params),
  conductParticipantByTrainerId: params => getConductParticipantByTrainerId(dispatch, params),
  conductParticipantByTrainerIdExport: params =>
    getConductParticipantByTrainerIdExport(dispatch, params),
});

const mapStateToProps = ({ reportData }) => ({
  reportData,
});

ConductPtDetails.propTypes = {
  checkUserAccessPermission: PropTypes.func,
  conductParticipantByTrainerId: PropTypes.func,
  conductParticipantByTrainerIdExport: PropTypes.func,
  conductProductByTrainerId: PropTypes.func,
  conductReportDetailByTrainerId: PropTypes.func,
  history: PropTypes.object,
  match: PropTypes.object,
  reportData: PropTypes.object,
};

const shell = compose(connect(mapStateToProps, mapDispatchToProps));

export default shell(AuthenticationAccessPages(ConductPtDetails));
