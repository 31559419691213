import React from 'react';
import { Helmet } from 'react-helmet';
// import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withStyles } from '@material-ui/styles';
// style
import './PageNotFoundStyle.scss';
import StylesJs from './PageNotFoundStyle';

class PageNotFound extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  // componentDidMount() {}

  // componentWillUnmount() {}

  render() {
    const { classes } = this.props;

    return (
      <div>
        <Helmet title="FITCO | Page Not Found" />
        <div className="container-page-not-found">
          <Grid container justify={'center'}>
            <Grid item md={8} sm={6} xs={12} className="image-not-found">
              <div className={classes.mainImageContent} />
            </Grid>
            <Grid item md={8} sm={6} xs={12} className="title-and-sub">
              <h5>Error</h5>
              <h6>404 The requested wasn’t found on this server</h6>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

// const mapDispatchToProps = () => ({});

// const mapStateToProps = () => ({});

PageNotFound.propTypes = {
  classes: PropTypes.object.isRequired,
};

const core = compose(withStyles(StylesJs));

export default core(PageNotFound);
