export const constants = {
  getListRequest: 'GET_LIST_USER_REQUEST',
  getListSuccess: 'GET_LIST_USER_SUCCESS',
  getListFail: 'GET_LIST_USER_FAIL',
  validateEmailOrPhoneRequest: 'VALIDATE_EMAIL_OR_PHONE_REQUEST',
  validateEmailOrPhoneSuccess: 'VALIDATE_EMAIL_OR_PHONE_SUCCESS',
  validateEmailOrPhoneFail: 'VALIDATE_EMAIL_OR_PHONE_FAIL',
  createUserRequest: 'CREATE_USER_REQUEST',
  createUserSuccess: 'CREATE_USER_SUCCESS',
  createUserFail: 'CREATE_USER_FAIL',
  updateUserRequest: 'UPDATE_USER_REQUEST',
  updateUserSuccess: 'UPDATE_USER_SUCCESS',
  updateUserFail: 'UPDATE_USER_FAIL',
  deleteMultipleRequest: 'DELETE_MULTIPLE_REQUEST',
  deleteMultipleSuccess: 'DELETE_MULTIPLE_SUCCESS',
  deleteMultipleFail: 'DELETE_MULTIPLE_FAIL',
  deleteUserRequest: 'DELETE_USER_REQUEST',
  deleteUserSuccess: 'DELETE_USER_SUCCESS',
  deleteUserFail: 'DELETE_USER_FAIL',
  setUpdateUserStatusRequest: 'SET_UPDATE_USER_STATUS_REQUEST',
  setUpdateUserStatusSuccess: 'SET_UPDATE_USER_STATUS_SUCCESS',
  setUpdateUserStatusFail: 'SET_UPDATE_USER_STATUS_FAIL',
  sendVerifyOtpRequest: 'SEND_VERIFY_OTP_REQUEST',
  sendVerifyOtpSuccess: 'SEND_VERIFY_OTP_SUCCESS',
  sendVerifyOtpFail: 'SEND_VERIFY_OTP_FAIL',
  getRolePaginationRequest: 'GET_PAGINATION_REQUEST',
  getRolePaginationSuccess: 'GET_PAGINATION_SUCCESS',
  getRolePaginationFail: 'GET_PAGINATION_FAIL',
  createRoleRequest: 'CREATE_ROLE_REQUEST',
  createRoleSuccess: 'CREATE_ROLE_SUCCESS',
  createRoleFail: 'CREATE_ROLE_FAIL',
  deleteRoleRequest: 'DELETE_ROLE_REQUEST',
  deleteRoleSuccess: 'DELETE_ROLE_SUCCESS',
  deleteRoleFail: 'DELETE_ROLE_FAIL',
  updateRoleRequest: 'UPDATE_ROLE_REQUEST',
  updateRoleSuccess: 'UPDATE_ROLE_SUCCESS',
  updateRoleFail: 'UPDATE_ROLE_FAIL',
  getUserDetailsByIdRequest: 'GET_USER_DETAILS_REQUEST',
  getUserDetailsByIdSuccess: 'GET_USER_DETAILS_SUCCESS',
  getUserDetailsByIdFail: 'GET_USER_DETAILS_FAIL',
  getMembershipPaginationRequest: 'GET_MEMBERSHIP_PAGINATION_REQUEST',
  getMembershipPaginationSuccess: 'GET_MEMBERSHIP_PAGINATION_SUCCESS',
  getMembershipPaginationFail: 'GET_MEMBERSHIP_PAGINATION_FAIL',
  getMembershipBundleDetailRequest: 'GET_MEMBERSHIP_DETAIL_BUNDLE_REQUEST',
  getMembershipBundleDetailSuccess: 'GET_MEMBERSHIP_DETAIL_BUNDLE_SUCCESS',
  getMembershipBundleDetailFail: 'GET_MEMBERSHIP_DETAIL_BUNDLE_FAIL',
  getUserActivityPaginationRequest: 'GET_ACTIVITY_PAGINATION_REQUEST',
  getUserActivityPaginationSuccess: 'GET_ACTIVITY_PAGINATION_SUCCESS',
  getUserActivityPaginationFail: 'GET_ACTIVITY_PAGINATION_FAIL',
  getUserActivityDetailRequest: 'GET_ACTIVITY_DETAIL_REQUEST',
  getUserActivityDetailSuccess: 'GET_ACTIVITY_DETAIL_SUCCESS',
  getUserActivityDetailFail: 'GET_ACTIVITY_DETAIL_FAIL',
  createPermissionRequest: 'CREATE_PERMISSION_REQUEST',
  createPermissionSuccess: 'CREATE_PERMISSION_SUCCESS',
  createPermissionFail: 'CREATE_PERMISSION_FAIL',
  deletePermissionRoleRequest: 'DELETE_PERMISSION_ROLE_REQUEST',
  deletePermissionRoleSuccess: 'DELETE_PERMISSION_ROLE_SUCCESS',
  deletePermissionRoleFail: 'DELETE_PERMISSION_ROLE_FAIL',
  getPermissionGroupByRoleRequest: 'GET_PERMISSION_GROUP_BY_ROLE_REQUEST',
  getPermissionGroupByRoleSuccess: 'GET_PERMISSION_GROUP_BY_ROLE_SUCCESS',
  getPermissionGroupByRoleFail: 'GET_PERMISSION_GROUP_BY_ROLE_FAIL',
  setPermissionToRoleRequest: 'SET_PERMISSION_TO_ROLE_REQUEST',
  setPermissionToRoleSuccess: 'SET_PERMISSION_TO_ROLE_SUCCESS',
  setPermissionToRoleFail: 'SET_PERMISSION_TO_ROLE_FAIL',
  setEditPermissionToRoleRequest: 'SET_EDIT PERMISSION_TO_ROLE_REQUEST',
  setEditPermissionToRoleSuccess: 'SET_EDIT PERMISSION_TO_ROLE_SUCCESS',
  setEditPermissionToRoleFail: 'SET_EDIT PERMISSION_TO_ROLE_FAIL',
  getMembershipDetailsRequest: 'GET_MEMBERSHIP_DETAILS_REQUEST',
  getMembershipDetailsSuccess: 'GET_MEMBERSHIP_DETAILS_SUCCESS',
  getMembershipDetailsFail: 'GET_MEMBERSHIP_DETAILS_FAIL',
  getMembershipPurchasedPriceRequest: 'GET_MEMBERSHIP_PURCHASED_PRICE_DETAILS_REQUEST',
  getMembershipPurchasedPriceSuccess: 'GET_MEMBERSHIP_PURCHASED_PRICE_DETAILS_SUCCESS',
  getMembershipPurchasedPriceFail: 'GET_MEMBERSHIP_PURCHASED_PRICE_DETAILS_FAIL',
  getMemberDetailsRequest: 'GET_MEMBER_DETAILS_REQUEST',
  getMemberDetailsSuccess: 'GET_MEMBER_DETAILS_SUCCESS',
  getMemberDetailsFail: 'GET_MEMBER_DETAILS_FAIL',
  requestFreezemembershipRequest: 'REQUEST_FREEZE_MEMBERSHIP_REQUEST',
  requestFreezemembershipSuccess: 'REQUEST_FREEZE_MEMBERSHIP_SUCCESS',
  requestFreezemembershipFail: 'REQUEST_FREEZE_MEMBERSHIP_FAIL',
};

const initState = {
  fetching: false,
  list: [],
  total: 0,
  errorMessage: null,
  responseMessage: null,
  userDetails: {},
  membershipsBundleDetail: {},
  listMemberships: [],
  listActivity: [],
  activityDetail: null,
  membershipDetails: {},
  membershipPurchasedPriceDetails: {},
  memberDetails: {}
};

export default function userManagement(state = initState, action) {
  const { type, payload } = action;
  switch (type) {
    case constants.getListRequest:
      return { ...state, fetching: true };
    case constants.getListSuccess:
      return {
        ...state,
        ...payload,
        list: payload.data,
        total: payload.total,
        fetching: false,
      };
    case constants.getListFail:
      return { ...state, fetching: false, loginError: payload };

    case constants.validateEmailOrPhoneRequest:
      return { ...state, fetching: true };
    case constants.validateEmailOrPhoneSuccess:
      return {
        ...state,
        responseMessage: payload.data,
        fetching: false,
      };
    case constants.validateEmailOrPhoneFail:
      return { ...state, fetching: false, errorMessage: payload };

    case constants.createUserRequest:
      return { ...state, fetching: true, responseMessage: null };
    case constants.createUserSuccess:
      return {
        ...state,
        responseMessage: payload.messages,
        fetching: false,
      };
    case constants.createUserFail:
      return { ...state, fetching: false, errorMessage: payload };
    case constants.updateUserRequest:
      return { ...state, fetching: true, responseMessage: null };
    case constants.updateUserSuccess:
      return {
        ...state,
        responseMessage: payload.messages,
        fetching: false,
      };
    case constants.updateUserFail:
      return { ...state, fetching: false, errorMessage: payload };

    case constants.deleteMultipleRequest:
      return { ...state, fetching: true, responseMessage: null };
    case constants.deleteMultipleSuccess:
      return {
        ...state,
        responseMessage: payload.messages,
        fetching: false,
      };
    case constants.deleteMultipleFail:
      return { ...state, fetching: false, errorMessage: payload };

    case constants.deleteUserRequest:
      return { ...state, fetching: true, responseMessage: null };
    case constants.deleteUserSuccess:
      return {
        ...state,
        responseMessage: payload.messages,
        fetching: false,
      };
    case constants.deleteUserFail:
      return { ...state, fetching: false, errorMessage: payload };
    case constants.setUpdateUserStatusRequest:
      return { ...state, fetching: true, responseMessage: null };
    case constants.setUpdateUserStatusSuccess:
      return {
        ...state,
        responseMessage: payload.messages,
        fetching: false,
      };
    case constants.setUpdateUserStatusFail:
      return { ...state, fetching: false, errorMessage: payload };
    case constants.sendVerifyOtpRequest:
      return { ...state, fetching: true, responseMessage: null };
    case constants.sendVerifyOtpSuccess:
      return {
        ...state,
        responseMessage: payload.messages,
        fetching: false,
      };
    case constants.sendVerifyOtpFail:
      return { ...state, fetching: false, errorMessage: payload };

    case constants.getRolePaginationRequest:
      return { ...state, fetching: true };
    case constants.getRolePaginationSuccess:
      return {
        ...state,
        ...payload,
        list: payload.data,
        total: payload.total,
        fetching: false,
      };
    case constants.getRolePaginationFail:
      return { ...state, fetching: false, loginError: payload };
    case constants.createRoleRequest:
      return { ...state, fetching: true };
    case constants.createRoleSuccess:
      return {
        ...state,
        responseMessage: payload.messages,
        fetching: false,
      };
    case constants.createRoleFail:
      return { ...state, fetching: false, loginError: payload };
    case constants.deleteRoleRequest:
      return { ...state, fetching: true };
    case constants.deleteRoleSuccess:
      return {
        ...state,
        responseMessage: payload.messages,
        fetching: false,
      };
    case constants.deleteRoleFail:
      return { ...state, fetching: false, loginError: payload };
    case constants.updateRoleRequest:
      return { ...state, fetching: true };
    case constants.updateRoleSuccess:
      return {
        ...state,
        responseMessage: payload.messages,
        fetching: false,
      };
    case constants.updateRoleFail:
      return { ...state, fetching: false, loginError: payload };
    case constants.getUserDetailsByIdRequest:
      return { 
        ...state, 
        fetching: true, 
        errorMessage: null,
        responseMessage: null,
        userDetails: null, 
      };
    case constants.getUserDetailsByIdSuccess:
      return {
        ...state,
        fetching: false,
        errorMessage: null,
        userDetails: payload.data,
      };
    case constants.getUserDetailsByIdFail:
      return { 
        ...state, 
        fetching: false, 
        errorMessage: payload,
        userDetails: null
      };
    case constants.getMembershipPaginationRequest:
      return { ...state, fetching: true, responseMessage: null };
    case constants.getMembershipPaginationSuccess:
      return {
        ...state,
        listMemberships: payload.data,
        total: payload.total,
        fetching: false,
      };
    case constants.getMembershipPaginationFail:
      return { ...state, fetching: false, errorMessage: payload };

    case constants.getMembershipBundleDetailRequest:
      return { ...state, fetching: true, responseMessage: null };
    case constants.getMembershipBundleDetailSuccess:
      return {
        ...state,
        membershipsBundleDetail: payload.data,
        fetching: false,
      };
    case constants.getMembershipBundleDetailFail:
      return { ...state, fetching: false, errorMessage: payload };

    case constants.getUserActivityPaginationRequest:
      return { ...state, fetching: true, responseMessage: null };
    case constants.getUserActivityPaginationSuccess:
      return {
        ...state,
        listActivity: payload.data,
        total: payload.total,
        fetching: false,
      };
    case constants.getUserActivityPaginationFail:
      return { ...state, fetching: false, errorMessage: payload };

    case constants.getUserActivityDetailRequest:
      return { ...state, fetching: true, responseMessage: null };
    case constants.getUserActivityDetailSuccess:
      return {
        ...state,
        activityDetail: payload.data,
        fetching: false,
      };
    case constants.getUserActivityDetailFail:
      return { ...state, fetching: false, errorMessage: payload };

    case constants.createPermissionRequest:
      return { ...state, fetching: true };
    case constants.createPermissionSuccess:
      return {
        ...state,
        responseMessage: payload.messages,
        fetching: false,
      };
    case constants.createPermissionFail:
      return { ...state, fetching: false, loginError: payload };
    case constants.deletePermissionRoleRequest:
      return { ...state, fetching: true };
    case constants.deletePermissionRoleSuccess:
      return {
        ...state,
        responseMessage: payload.messages,
        fetching: false,
      };
    case constants.deletePermissionRoleFail:
      return { ...state, fetching: false, loginError: payload };
    case constants.getPermissionGroupByRoleRequest:
      return { ...state, fetching: true };
    case constants.getPermissionGroupByRoleSuccess:
      return {
        ...state,
        ...payload,
        list: payload.data,
        fetching: false,
      };
    case constants.getPermissionGroupByRoleFail:
      return { ...state, fetching: false, loginError: payload };
    case constants.setPermissionToRoleRequest:
      return { ...state, fetching: true };
    case constants.setPermissionToRoleSuccess:
      return {
        ...state,
        responseMessage: payload.messages,
        fetching: false,
      };
    case constants.setPermissionToRoleFail:
      return { ...state, fetching: false, loginError: payload };
    case constants.setEditPermissionToRoleRequest:
      return { ...state, fetching: true };
    case constants.setEditPermissionToRoleSuccess:
      return {
        ...state,
        responseMessage: payload.messages,
        fetching: false,
      };
    case constants.setEditPermissionToRoleFail:
      return { ...state, fetching: false, loginError: payload };

    case constants.getMembershipDetailsRequest:
      return { ...state, fetching: true, responseMessage: null };
    case constants.getMembershipDetailsSuccess:
      return {
        ...state,
        membershipDetails: payload.data,
        fetching: false,
      };
    case constants.getMembershipDetailsFail:
      return { ...state, fetching: false, errorMessage: payload }; 

    case constants.getMembershipPurchasedPriceRequest:
      return { ...state, fetching: true, responseMessage: null };
    case constants.getMembershipPurchasedPriceSuccess:
      return {
        ...state,
        membershipPurchasedPriceDetails: payload.data,
        fetching: false,
      };
    case constants.getMembershipPurchasedPriceFail:
      return { ...state, fetching: false, errorMessage: payload }; 

    case constants.getMemberDetailsRequest:
      return { ...state, fetching: true, responseMessage: null };
    case constants.getMemberDetailsSuccess:
      return {
        ...state,
        memberDetails: payload.data,
        fetching: false,
      };
    case constants.getMemberDetailsFail:
      return { ...state, fetching: false, errorMessage: payload }; 

    case constants.requestFreezemembershipRequest:
      return { ...state, fetching: true, responseMessage: null };
    case constants.requestFreezemembershipSuccess:
      return {
        ...state,
        responseMessage: payload.messages,
        fetching: false,
      };
    case constants.requestFreezemembershipFail:
      return { ...state, fetching: false, errorMessage: payload };
    default:
      return state;
  }
}
