/* eslint-disable react/no-unused-state */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Grid, Breadcrumbs, Link, FormControl, FormLabel, IconButton } from '@material-ui/core';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { QRCode } from 'react-qrcode-logo';
import { compose } from 'redux';
// Components
import {
  LabelStatusPayment,
  RadioInputImage,
  ButtonMain,
  TextInput,
  GridRowTableOneColumn,
  AuthenticationAccessPages,
  PrevStateValue,
  SkeletonDetailsV03,
  GeneratorPdf,
  SnackBarSimple,
  SelectInputMain,
  UploadImage,
} from '../../../components/Index';
import {
  CartItemDetailRetails,
  CartItemDetailMembership,
  CartItemDetailsEats,
  CartItemDetailsEatsAtStore,
  DetailsShipping,
  DetailsAppointment,
  ModalOrderQris,
  ModalUpdateOrder,
  ModalAddFood,
  ModalImagePreview,
} from './components/Index';
import { ModalAddPromoCode } from '../order-add/sub-step-view/step-summary-and-payment/components/Index';
// Style
import './OrderDetailsStyle.scss';
// Helper
import {
  MasterDataHelper,
  CommonHelper,
  PermissionModule,
  PermissionPage,
  PermissionAccess,
  HttpStatusCode,
  productTypeForOrderCode,
  OrderHelper,
  OrderType,
} from '../../../helpers/Index';
// Action
import {
  getOrderDetails,
  cancelOrder,
  checkPromoCode,
  getAvailablePromoCode,
  saveOpenBill,
  submitOpenBill,
  setPrintCaptainOrder,
  changeOrderPayment,
  getOrderTaxPercentage,
  getOrderServiceChargePercentage,
} from '../../../services/api/OrdersApi';
import { getListEatPagination } from '../../../services/api/EatApi';
import { getBookingAdminFee } from '../../../services/api/ScheduleDataApi';
// Assets
import { Images, Icons } from '../../../assets/Index';
import {
  paymentMethodOptionCode,
  statusPaymentCode,
  orderStatusCode,
} from '../../../helpers/MasterDataHelper';

const bankTransferOption = MasterDataHelper.bankTransferOption;

const initialFilter = {
  search: '',
};

const initialForm = {
  items: [],
  paymentMethod: paymentMethodOptionCode.bank_transfer,
  bank: bankTransferOption[0],
  promoCode: '',
  transactionReference: '',
  phoneNumberPayment: '',
  imageBase64: null,
};

const initialValidation = {
  promoCode: { isError: false, errorMessage: '' },
  transactionReference: { isError: false, errorMessage: '' },
  phoneNumberPayment: { isError: false, errorMessage: '' },
  imageBase64: { isError: '', errorMessage: '' },
};

const initialSort = {
  sortField: 'name',
  sortOrder: '',
};

const optionToast = {
  vertical: 'top',
  horizontal: 'right',
};

class OrderDetails extends React.Component {
  constructor(props) {
    super(props);

    props.checkUserAccessPermission(
      PermissionModule.Orders,
      PermissionPage.List,
      PermissionAccess.View,
    );
    const {
      match: { params },
    } = this.props;

    const decryptedObject = CommonHelper.decryptObject(params.sales_order_id);

    this.state = {
      isFetching: true,
      isLoading: false,
      loading: false,
      salesOrderId: decryptedObject.salesOrderId,
      hasAccess: true,
      isOpenQrisModal: false,
      isOpenCancelOrderModal: false,
      isOpenChangeOrderPaymentModal: false,
      isOpenAddFoodModal: false,
      toastInformation: {
        isOpen: false,
        message: '',
        snackbarType: 'warning',
      },
      detailOrderData: {},
      itemsToPrint: [],
      isAllChecked: false,
      filter: CommonHelper.objectCloning(initialFilter),
      form: CommonHelper.objectCloning(initialForm),
      sort: CommonHelper.objectCloning(initialSort),
      validation: CommonHelper.objectCloning(initialValidation),
      pagination: {
        hideOnSinglePage: true,
        showSizeChanger: false,
        pageSizeOptions: ['3', '6', '9', '12'],
      },
      paymentTypeOption: [],
      isSuccess: '',
      isValidPromoCode: false,
      isOpenModalPromoCode: false,
      selectedPromoCode: null,
      searchAvailablePromoCode: null,
      promoCodeDetails: {},
      isHideButton: false,
      isOpenImagePreviewModal: false,
      isDoctorConsultation: decryptedObject.isDoctorConsultation,
      orderTaxPercentage: 0,
      orderServiceChargePercentage: 0,
    };

    this.searchFoodDebounce = _.debounce(this.searchFoodDebounce, 400);
    this.searchPromoCodeDebounce = _.debounce(this.searchPromoCodeDebounce, 400);
    this.searchAvailablePromoCodeDebounce = _.debounce(this.searchAvailablePromoCodeDebounce, 400);
  }

  componentDidMount() {
    const { isDoctorConsultation } = this.state;
    this.getDetails();
    if (isDoctorConsultation) {
      this.getMedicConsultationInfo();
    }
  }

  async componentDidUpdate() {
    const {
      orderData: { fetching, errorMessage },
      history,
    } = this.props;

    if (!fetching && errorMessage && errorMessage.code === HttpStatusCode.Forbidden) {
      history.push('/orders');
    }
  }

  getTax() {
    const { checkTax } = this.props;
    checkTax().then(response => {
      this.setState({
        orderTaxPercentage: response.data,
      });
    });
  }

  getServiceCharge() {
    const { checkServiceCharge } = this.props;
    checkServiceCharge().then(response => {
      this.setState({
        orderServiceChargePercentage: response.data,
      });
    });
  }

  getDetails = async () => {
    const { getOrder } = this.props;
    const { salesOrderId, form } = this.state;

    try {
      const param = {
        sales_order_id: salesOrderId,
      };
      const { data } = await getOrder(param);
      const tempOptionPayment = MasterDataHelper.paymentMethodOption.find(item => {
        return item.value === data.order_details.payment_type;
      });
      let tempBankOptionPayment = form.bank;
      if (data.order_details.payment_type === paymentMethodOptionCode.bank_transfer) {
        tempBankOptionPayment = bankTransferOption.find(item => {
          return item.value === data.payment_details.bank;
        });
      }
      let defaultOptionPayment = tempOptionPayment;
      const promoCode =
        data.order_summary.price_discount > 0 || data.order_summary.promo_code
          ? data.order_summary.promo_code
          : '';
      const isSuccess =
        data.order_summary.price_discount > 0 || data.order_summary.promo_code ? 'success' : '';
      const isGuest = data.customer_email === 'guest@fitco.id';
      const isDraft = data.order_details.order_status === orderStatusCode.Draft;
      const isMedicOrder = data.order_details.order_type === productTypeForOrderCode.medic;
      const phoneNumberPayment = data.payment_details.phone ? data.payment_details.phone : '';
      const transactionReference = data.payment_details.transaction_reference
        ? data.payment_details.transaction_reference
        : '';

      const isEatAtStoreTransaction =
        data.order_details.order_type === OrderType.Eat && data.order_details.at_store;
      const isDineInAtPanin =
        isEatAtStoreTransaction &&
        data.branch_details &&
        data.branch_details.identification === 'fitco-eats-express-panin';

      if (isDraft && isGuest) {
        defaultOptionPayment = MasterDataHelper.paymentMethodOption.filter(
          item =>
            item.value === paymentMethodOptionCode.bank_transfer ||
            item.value === paymentMethodOptionCode.edc ||
            item.value === paymentMethodOptionCode.ovo ||
            item.value === paymentMethodOptionCode.shopeepay ||
            item.value === paymentMethodOptionCode.qris ||
            item.value === paymentMethodOptionCode.giveaway,
        );
      } else if (isDraft && !isGuest) {
        defaultOptionPayment = MasterDataHelper.paymentMethodOption.filter(
          item =>
            item.value === paymentMethodOptionCode.credit_card ||
            item.value === paymentMethodOptionCode.bank_transfer ||
            item.value === paymentMethodOptionCode.fit_point ||
            item.value === paymentMethodOptionCode.edc ||
            item.value === paymentMethodOptionCode.ovo ||
            item.value === paymentMethodOptionCode.shopeepay ||
            item.value === paymentMethodOptionCode.qris ||
            item.value === paymentMethodOptionCode.giveaway,
        );
      }

      if (isDraft && isMedicOrder) {
        defaultOptionPayment = MasterDataHelper.paymentMethodOption.filter(
          item =>
            item.value === paymentMethodOptionCode.fit_point ||
            item.value === paymentMethodOptionCode.bank_transfer ||
            item.value === paymentMethodOptionCode.qris ||
            item.value === paymentMethodOptionCode.cash ||
            item.value === paymentMethodOptionCode.edc ||
            item.value === paymentMethodOptionCode.corporate_deal,
        );
      }

      if (isDraft && isDineInAtPanin) {
        this.getTax();
        this.getServiceCharge();
      }

      this.setState({
        isFetching: false,
        detailOrderData: data,
        paymentTypeOption: defaultOptionPayment,
        form: {
          ...form,
          paymentMethod: tempOptionPayment.value,
          bank: tempBankOptionPayment,
          promoCode,
          phoneNumberPayment,
          transactionReference,
          imageBase64: (data.identity_details && data.identity_details.identity_photo) || null,
        },
        isValidPromoCode: data.order_summary.price_discount > 0,
        isSuccess,
      });
    } catch (error) {
      this.setState({
        isFetching: false,
      });
      const errors = error.data;
      this.processMessage(errors.messages, 'error');
    }
  };

  getMedicConsultationInfo = async () => {
    const { bookingAdminFee } = this.props;
    await bookingAdminFee();
  };

  searchFoodDebounce = () => {
    const { listEatPagination } = this.props;
    const {
      filter: { search },
    } = this.state;
    const param = {
      page: 1,
      limit: 10,
      sort: 'product_name',
      sortOrder: 'asc',
      search,
      is_pickup_at_store: true,
      status: 10,
    };

    if (search) {
      this.setState({ loading: true }, () => {
        listEatPagination(param)
          .then(() => {
            this.setState({
              loading: false,
            });
          })
          .catch(error => {
            const serverMessage = error.data;
            const validationStatus = error.status === HttpStatusCode.InternalServerError;
            this.processMessage(
              validationStatus ? serverMessage.message : serverMessage.messages,
              'error',
            );
            this.setState({
              loading: false,
            });
          });
      });
    }
  };

  searchPromoCodeDebounce = () => {
    const { checkPromo } = this.props;
    const { validation, form, detailOrderData } = this.state;
    const itemList = [];
    detailOrderData.order_summary.product_details.forEach(item => {
      itemList.push({
        product_id: item.product_id,
        qty: item.quantity,
      });
    });
    const params = {
      data: {
        user_id: detailOrderData.customer_details.customer_id,
        promo_code: form.promoCode,
        payment_type: form.paymentMethod,
        items: !_.isEmpty(itemList) ? itemList : null,
        at_store: detailOrderData.order_details.at_store,
        branch_id: detailOrderData.order_details.branch_id,
      },
    };
    const message = CommonHelper.objectCloning(initialValidation.promoCode);

    if (_.isEmpty(form.promoCode)) {
      this.setState({
        isValidPromoCode: false,
        isSuccess: '',
        validation: {
          ...validation,
          promoCode: { isError: false, errorMessage: '' },
        },
      });
      return;
    }

    checkPromo(params)
      .then(response => {
        const promoData = response.data;
        const promoMessage =
          promoData.promo_code_cashback_type_id === 2
            ? `Potential cashback of ${CommonHelper.formatCurrency(
                promoData.cashback_amount,
              )} FIT Points`
            : `Potential discount of IDR ${CommonHelper.formatCurrency(promoData.cashback_amount)}`;
        message.errorMessage = `Yay! promotion applied. ${promoMessage}`;

        this.setState({
          promoCodeDetails: promoData,
          isValidPromoCode: true,
          isSuccess: 'success',
          validation: {
            ...validation,
            promoCode: message,
          },
        });
      })
      .catch(e => {
        if (e.status !== HttpStatusCode.Success) {
          message.isError = true;
          message.errorMessage = e.data.messages;

          this.setState({
            validation: {
              ...validation,
              promoCode: message,
            },
            isValidPromoCode: false,
            isSuccess: 'error',
          });
        }
      });
  };

  searchAvailablePromoCodeDebounce = (value = null) => {
    const { checkAvailablePromoCode } = this.props;
    const { detailOrderData, form } = this.state;
    try {
      const params = {
        order_type: detailOrderData.order_details.order_type,
        payment_type: form.paymentMethod,
        at_store: detailOrderData.order_details.at_store,
        branch_id: detailOrderData.order_details.branch_id,
        search_term: value,
        limit: 20,
        page: 1,
        sortBy: '',
        order: '',
      };
      checkAvailablePromoCode(params);
      this.setState({
        isOpenModalPromoCode: true,
        loading: false,
      });
    } catch (error) {
      this.setState({
        isOpenModalPromoCode: false,
        loading: false,
      });
    }
  };

  handleClick = (event, value) => {
    const { history } = this.props;
    event.preventDefault();
    history.push(value);
  };

  handleButtonCancel = () => {
    // const { history } = this.props;
    // history.push('/orders');
    this.props.history.goBack();
  };

  handleOpenModalQris = () => {
    this.setState({
      isOpenQrisModal: true,
    });
  };

  handleCloseModalQris = () => {
    this.setState({
      isOpenQrisModal: false,
    });
  };

  handleCloseModalCancelOrder = () => {
    this.setState({
      isOpenCancelOrderModal: false,
    });
  };

  handleCloseModalChangePayment = () => {
    this.setState({
      isOpenChangeOrderPaymentModal: false,
    });
  };

  handleOpenModalImagePreview = () => {
    this.setState({
      isOpenImagePreviewModal: true,
    });
  };

  handleCloseModalImagePreview = () => {
    this.setState({
      isOpenImagePreviewModal: false,
    });
  };

  onHandleCancelOrder = async () => {
    const { salesOrderId } = this.state;
    const { processCancelOrder } = this.props;
    try {
      await processCancelOrder(salesOrderId);
      this.getDetails();
      this.processMessage('Order Successfully Canceled', 'success');
      this.handleCloseModalCancelOrder();
    } catch (error) {
      const errors = error.data;
      this.processMessage(errors.messages, 'error');
      this.handleCloseModalCancelOrder();
    }
  };

  onHandleChangeOrderPayment = async () => {
    const { salesOrderId } = this.state;
    const { processChangeOrderPayment } = this.props;
    try {
      await processChangeOrderPayment(salesOrderId);
      this.setState(
        {
          paymentTypeOption: [],
        },
        () => {
          this.getDetails();
          this.processMessage(
            'Order Successfully Updated to Draft, please complete payment',
            'success',
          );
          this.handleCloseModalChangePayment();
        },
      );
    } catch (error) {
      const errors = error.data;
      this.processMessage(errors.messages, 'error');
      this.handleCloseModalChangePayment();
    }
  };

  getPaymentType = () => {
    const { form, detailOrderData } = this.state;
    let modifyPayment = null;
    switch (form.paymentMethod) {
      case paymentMethodOptionCode.bank_transfer:
        modifyPayment = {
          payment_type: form.paymentMethod,
          bank: form.bank.value,
          transaction_reference: form.transactionReference,
        };
        break;
      case paymentMethodOptionCode.edc:
        modifyPayment = {
          payment_type: form.paymentMethod,
          bank: `${detailOrderData.branch_details.merchant_id}-${detailOrderData.branch_details.merchant_name}`,
          account_number: `${detailOrderData.branch_details.branch_id}-${detailOrderData.branch_details.name}`,
          name: `${detailOrderData.customer_details.customer_name}`,
          transaction_reference: form.transactionReference,
        };
        break;
      case paymentMethodOptionCode.credit_card:
        modifyPayment = {
          payment_type: form.paymentMethod,
          payment_channel: 'xendit',
          payment_status: 0,
          transaction_reference: form.transactionReference,
        };
        break;
      case paymentMethodOptionCode.ovo:
        modifyPayment = {
          payment_type: form.paymentMethod,
          phone: form.phoneNumberPayment,
          transaction_reference: form.transactionReference,
        };
        break;
      case paymentMethodOptionCode.grabfood:
      case paymentMethodOptionCode.gofood:
      case paymentMethodOptionCode.shopeefood:
      case paymentMethodOptionCode.shopeepay:
        modifyPayment = {
          payment_type: form.paymentMethod,
          transaction_id: form.transactionReference,
          transaction_reference: form.transactionReference,
        };
        break;
      default:
        modifyPayment = {
          payment_type: form.paymentMethod,
          transaction_reference: form.transactionReference,
        };
        break;
    }

    return modifyPayment;
  };

  handleSaveOpenBill = async () => {
    const { salesOrderId, form, detailOrderData } = this.state;
    const { processSaveOpenBill } = this.props;
    const isAtStore = detailOrderData.order_details.at_store;
    const modifyPayment = this.getPaymentType();
    const isCheckPassed = this.validationSummaryAndPayment();
    let identityDetails = {};

    if (!_.isEmpty(detailOrderData.identity_details)) {
      identityDetails = {
        ...detailOrderData.identity_details,
        identity_photo: form.imageBase64,
      };
    }

    if (!isCheckPassed) {
      return;
    }

    try {
      const params = {
        promo_code: form.promoCode,
        items: OrderHelper.formatItemSubmit(
          detailOrderData.order_summary.product_details,
          {},
          isAtStore,
        ),
        payment_data: modifyPayment,
        identity_details: identityDetails,
      };
      await processSaveOpenBill(salesOrderId, params);
      await this.processMessage('Order Successfully Saved', 'success');
      this.setState({ isFetching: true }, async () => {
        await this.getDetails();
        this.setState({ isHideButton: false, isAllChecked: false });
      });
    } catch (error) {
      const errors = error.data;
      this.processMessage(errors.messages, 'error');
    }
  };

  handleSubmitOpenBill = async () => {
    const { salesOrderId, form, detailOrderData } = this.state;
    const { processSubmitOpenBill, history } = this.props;
    const isAtStore = detailOrderData.order_details.at_store;
    const modifyPayment = this.getPaymentType();
    const isCheckPassed = this.validationSummaryAndPayment();
    let identityDetails = {};

    if (!_.isEmpty(detailOrderData.identity_details)) {
      identityDetails = {
        ...detailOrderData.identity_details,
        identity_photo: form.imageBase64,
      };
    }

    if (!isCheckPassed) {
      return;
    }

    try {
      const params = {
        promo_code: form.promoCode,
        items: OrderHelper.formatItemSubmit(
          detailOrderData.order_summary.product_details,
          {},
          isAtStore,
        ),
        payment_data: modifyPayment,
        identity_details: identityDetails,
      };
      await processSubmitOpenBill(salesOrderId, params);
      await this.processMessage('Order Successfully Submitted', 'success');
      await setTimeout(async () => {
        await history.push('/orders');
      }, 2000);
    } catch (error) {
      const errors = error.data;
      this.processMessage(errors.messages, 'error');
    }
  };

  handleButtonPrint = async () => {
    const { processPrintCaptainOrder } = this.props;
    const { detailOrderData } = this.state;
    try {
      const printedItems = [];
      const products = detailOrderData.items_for_printing
        ? detailOrderData.items_for_printing
        : detailOrderData.order_summary.product_details;
      products.forEach(item => {
        const obj = {};
        obj.sales_order_item_id = item.sales_order_item_id;
        obj.printed_quantity = item.quantity;

        return printedItems.push(obj);
      });

      const params = {
        printed_items: printedItems,
      };
      await processPrintCaptainOrder(params);
    } catch (error) {
      const errors = error.data;
      this.processMessage(errors.messages, 'error');
    }
  };

  handleOpenModalAddFood = () => {
    const { form } = this.state;
    this.setState({
      isOpenAddFoodModal: true,
      form: { ...form, items: [] },
    });
  };

  handleCloseModalAddFood = () => {
    this.setState({
      isOpenAddFoodModal: false,
    });
  };

  handleButtonClickAddFood = () => {
    const { detailOrderData, form } = this.state;
    const customerDetailsData = !_.isEmpty(detailOrderData.customer_details)
      ? detailOrderData.customer_details
      : {};
    const isGuest = customerDetailsData.customer_email === 'guest@fitco.id';
    let result = 0;
    let discount = 0;

    const finalSelected = [...detailOrderData.order_summary.product_details, ...form.items];
    finalSelected.forEach(item => {
      let selectedPrice = item.special_price || item.price;
      selectedPrice = isGuest ? item.normal_price : selectedPrice;
      result +=
        parseInt(item.quantity, 10) *
        (parseInt(selectedPrice, 10) || parseInt(item.item_price, 10));
    });
    discount =
      detailOrderData.order_summary.price_discount > 0
        ? detailOrderData.order_summary.price_discount
        : discount;
    result -= discount;

    this.setState(
      {
        isOpenAddFoodModal: false,
        isHideButton: true,
        detailOrderData: {
          ...detailOrderData,
          order_summary: {
            ...detailOrderData.order_summary,
            product_details: finalSelected,
            grand_total: result,
            total_items: finalSelected.length,
          },
        },
      },
      () => {
        this.searchPromoCodeDebounce();
      },
    );
  };

  handleQueryProduct = value => {
    const { filter } = this.state;

    this.setState(
      {
        filter: { ...filter, search: value },
      },
      () => {
        this.searchFoodDebounce();
      },
    );
  };

  handleSelectItem = (value, option) => {
    const { filter, form } = this.state;
    const checkExistingItems = _.find(form.items, ['product_id', option.product_id]);

    if (option.stock_status === 'instock' || option.status === 10) {
      if (!checkExistingItems) {
        option.quantity = 1;
        const finalSelected = [option, ...form.items];

        this.setState({
          filter: { ...filter, search: '' },
          form: { ...form, items: finalSelected },
        });
      } else {
        const existingItem = CommonHelper.objectCloning(form.items);
        const index = existingItem.findIndex(item => item.product_id === option.product_id);
        existingItem[index].quantity += 1;

        this.setState({
          filter: { ...filter, search: '' },
          form: { ...form, items: existingItem },
        });
      }
    } else {
      this.processMessage('Selected Product Is Out of Stock', 'warning');
    }
  };

  handleNotesEatAtStore = productId => value => {
    const { form } = this.state;
    const existingItem = CommonHelper.objectCloning(form.items);
    const index = existingItem.findIndex(item => item.product_id === productId);
    const temp = { ...existingItem[index], details: { notes: value } };
    existingItem[index] = temp;

    this.setState({
      form: { ...form, items: existingItem },
    });
  };

  handleButtonNumberStepper = productId => value => {
    const { form } = this.state;
    const existingItem = CommonHelper.objectCloning(form.items);
    const index = existingItem.findIndex(item => item.product_id === productId);
    const convertedValue = parseInt(value, 10);

    if (convertedValue >= 0) {
      if (convertedValue === 0 || value === '') {
        existingItem.splice(index, 1);
      } else {
        existingItem[index].quantity = convertedValue;
      }

      this.setState({
        form: { ...form, items: existingItem },
      });
    }
  };

  handleTableChange = (pagination, filters, sorter) => {
    const { form, sort } = this.state;

    let modifyItem = CommonHelper.objectCloning(form.items);

    const finalValue = sorter.order === 'ascend' ? 'asc' : 'desc';
    modifyItem = CommonHelper.dataArrayOrder(modifyItem, sorter.field, finalValue);

    this.setState({
      form: { ...form, items: modifyItem },
      limit: pagination.pageSize,
      pagination: {
        ...this.state.pagination,
        current: pagination.current,
        pageSize: pagination.pageSize,
      },
      sort: { ...sort, sortField: sorter.field, sortOrder: sorter.order },
      page: pagination.current,
    });
  };

  handleSelectPaymentMethod = value => {
    const { form } = this.state;
    const promoCode = CommonHelper.objectCloning(form.promoCode);

    this.setState(
      {
        form: { ...form, paymentMethod: value },
      },
      () => {
        if (value === paymentMethodOptionCode.bank_transfer) {
          const valueChange = bankTransferOption[0];
          this.handleChangeBankTransfer(valueChange);
        }
        this.handleChangePromoCodeEntry(promoCode);
      },
    );
  };

  handleChangeBankTransfer = value => {
    const { form } = this.state;
    this.setState({ form: { ...form, bank: value } });
  };

  handleChangeTransactionReference = value => {
    const { form } = this.state;

    this.setState({
      form: { ...form, transactionReference: value },
    });
  };

  handleChangePhoneNumberPayment = value => {
    const { form, validation } = this.state;
    const message = CommonHelper.objectCloning(initialValidation.phoneNumberPayment);
    message.isError = !value;
    message.errorMessage = !value ? 'Please Enter Phone Number For Payment' : '';

    this.setState({
      form: { ...form, phoneNumberPayment: value },
      validation: { ...validation, phoneNumberPayment: message },
    });
  };

  handleChangePromoCodeEntry = value => {
    const { form, selectedPromoCode } = this.state;
    const selectedCode = _.isEmpty(value) ? null : selectedPromoCode;

    this.setState(
      {
        selectedPromoCode: selectedCode,
        form: { ...form, promoCode: value },
      },
      () => {
        this.searchPromoCodeDebounce();
      },
    );
  };

  handleButtonClickAddPromo = () => {
    this.setState({ loading: true }, () => {
      this.searchAvailablePromoCodeDebounce();
    });
  };

  handleQueryAvailablePromoCode = value => {
    this.setState({ searchAvailablePromoCode: value }, () => {
      this.searchAvailablePromoCodeDebounce(value);
    });
  };

  handleCloseModalPromoCode = () => {
    this.setState({
      isOpenModalPromoCode: false,
      searchAvailablePromoCode: null,
    });
  };

  handleButtonClickApply = () => {
    const {
      orderData: { availablePromoCode },
    } = this.props;
    const { selectedPromoCode } = this.state;

    if (!selectedPromoCode) {
      this.setState({
        selectedPromoCode: null,
        searchAvailablePromoCode: null,
        isOpenModalPromoCode: false,
      });
      return;
    }

    if (_.isEmpty(availablePromoCode.data)) {
      this.setState({
        searchAvailablePromoCode: null,
        isOpenModalPromoCode: false,
      });
      return;
    }

    const selectedObj = availablePromoCode.data.find(
      item => item.cashback_id === selectedPromoCode,
    );
    this.setState({ isOpenModalPromoCode: false }, () => {
      this.handleChangePromoCodeEntry(selectedObj.promo_code);
    });
  };

  handleSelectPromoCode = value => {
    this.setState({
      selectedPromoCode: value,
    });
  };

  handleSelectAllData = value => {
    const { detailOrderData } = this.state;
    if (value) {
      this.setState({
        isAllChecked: value,
        detailOrderData: {
          ...detailOrderData,
          items_for_printing: detailOrderData.order_summary.product_details,
        },
        itemsToPrint: detailOrderData.order_summary.product_details,
      });
    } else {
      this.setState({
        isAllChecked: value,
        detailOrderData: { ...detailOrderData, items_for_printing: [] },
        itemsToPrint: [],
      });
    }
  };

  handleSelectedDataToBePrinted = value => {
    const { detailOrderData, itemsToPrint, isAllChecked } = this.state;
    const checkExistingItems = _.find(itemsToPrint, ['sales_order_item_id', parseInt(value, 10)]);
    if (isAllChecked) {
      this.setState({
        isAllChecked: false,
      });
    }
    if (!checkExistingItems) {
      const findProductDetail = detailOrderData.order_summary.product_details.find(item => {
        return item.sales_order_item_id.toString() === value;
      });
      const finalSelected = [findProductDetail, ...itemsToPrint];
      let checkedAll = false;
      if (finalSelected.length === detailOrderData.order_summary.product_details.length) {
        checkedAll = true;
      }
      this.setState({
        detailOrderData: { ...detailOrderData, items_for_printing: finalSelected },
        itemsToPrint: finalSelected,
        isAllChecked: checkedAll,
      });
    } else {
      const existingItem = CommonHelper.objectCloning(itemsToPrint);
      const index = existingItem.findIndex(
        item => item.sales_order_item_id === parseInt(value, 10),
      );
      existingItem.splice(index, 1);
      this.setState({
        detailOrderData: { ...detailOrderData, items_for_printing: existingItem },
        itemsToPrint: existingItem,
      });
    }
  };

  handleAddQuantityProduct = ids => value => {
    const { detailOrderData } = this.state;
    const existingItem = CommonHelper.objectCloning(detailOrderData.order_summary.product_details);
    const index = existingItem.findIndex(item => {
      if (!item.sales_order_item_id) {
        return item.product_id === ids;
      }
      return item.sales_order_item_id === ids;
    });
    const convertedValue = parseInt(value, 10);
    let result = 0;

    if (convertedValue >= 0) {
      if (convertedValue === 0 || value === '') {
        existingItem.splice(index, 1);
      } else {
        existingItem[index].quantity = convertedValue;
      }

      existingItem.forEach(item => {
        result += parseInt(item.quantity, 10) * parseInt(item.item_price, 10);
      });

      this.setState(
        {
          isHideButton: true,
          detailOrderData: {
            ...detailOrderData,
            order_summary: {
              ...detailOrderData.order_summary,
              product_details: existingItem,
              grand_total: result,
            },
          },
        },
        () => {
          this.searchPromoCodeDebounce();
        },
      );
    }
  };

  handleChangeImage = imageBase64 => {
    const { form, validation } = this.state;
    const message = CommonHelper.objectCloning(initialValidation.imageBase64);
    message.isError = !imageBase64 ? 'error' : '';
    message.errorMessage = !imageBase64 ? 'Please choose Image' : '';

    this.setState({
      form: { ...form, imageBase64 },
      validation: { ...validation, imageBase64: message },
    });
  };

  handleCloseToast = () => {
    const { toastInformation } = this.state;
    this.setState({ toastInformation: { ...toastInformation, isOpen: false } });
  };

  validationSummaryAndPayment() {
    const {
      form: { paymentMethod, transactionReference, phoneNumberPayment },
      validation,
    } = this.state;
    let passCheck = false;
    const message = { isError: false, errorMessage: '' };
    const paramValidation = CommonHelper.objectCloning(message);

    if (
      paymentMethod === paymentMethodOptionCode.grabfood ||
      paymentMethod === paymentMethodOptionCode.gofood ||
      paymentMethod === paymentMethodOptionCode.shopeefood ||
      paymentMethod === paymentMethodOptionCode.shopeepay
    ) {
      paramValidation.isError = !!(
        !transactionReference || validation.transactionReference.isError
      );
      paramValidation.errorMessage =
        !transactionReference || validation.transactionReference.isError
          ? 'Please Enter Transaction Reference'
          : '';
    } else if (paymentMethod === paymentMethodOptionCode.ovo) {
      paramValidation.isError = !!(!phoneNumberPayment || validation.phoneNumberPayment.isError);
      paramValidation.errorMessage =
        !phoneNumberPayment || validation.phoneNumberPayment.isError
          ? 'Please Enter Phone Number For Payment'
          : '';
    }

    if (!paramValidation.isError) {
      passCheck = true;
    }

    this.setState({
      validation: {
        ...validation,
        transactionReference: paramValidation,
        phoneNumberPayment: paramValidation,
      },
    });

    return passCheck;
  }

  processMessage(messages, type) {
    const convertedMessage = CommonHelper.generateMessage(messages);

    const paramInformation = {
      isOpen: true,
      message: convertedMessage,
      snackbarType: type,
    };

    this.setState({
      toastInformation: paramInformation,
    });
  }

  renderMembership() {
    const {
      orderData: { orderDetails },
    } = this.props;

    const paymentDetailsData = !_.isEmpty(orderDetails) ? orderDetails.payment_details : {};

    let renderElement = null;

    if (paymentDetailsData.membership_name) {
      renderElement = (
        <Grid item lg={6} md={6} className="mt-27">
          <Grid container justify="space-between" className="container-membership-information p-24">
            <Grid item lg={9} md={9}>
              <div className="flex-column ">
                <label className="text-12 text-semi-bold title">Membership</label>
                <label className="text-12 text-semi-bold wrapping-container second line">
                  {paymentDetailsData.membership_name || '-'}
                </label>
              </div>
            </Grid>
            {/* <Grid item lg={2} md={2}>
              <div className="flex-column text-center">
                <label className="text-12 text-semi-bold title">Quota</label>
                <label className="text-12 text-semi-bold">
                  {form.membershipInfo.remaining_quota || '-'}
                </label>
              </div>
            </Grid> */}
          </Grid>
        </Grid>
      );
    }

    return renderElement;
  }

  renderOrderCard = () => {
    const { detailOrderData, isValidPromoCode, promoCodeDetails, paymentMethod } = this.state;
    const orderDetailsData = !_.isEmpty(detailOrderData) ? detailOrderData.order_details : {};
    const orderSummaryData = !_.isEmpty(detailOrderData) ? detailOrderData.order_summary : {};
    const paymentDetailsData = !_.isEmpty(detailOrderData) ? detailOrderData.payment_details : {};
    const customerDetailsData = !_.isEmpty(detailOrderData) ? detailOrderData.customer_details : {};
    const orderItems = orderSummaryData.product_details || [];
    const isDraft = orderDetailsData.order_status === orderStatusCode.Draft;
    const isGuest = customerDetailsData.customer_email === 'guest@fitco.id';
    const showQRIS =
      orderDetailsData.payment_type === paymentMethodOptionCode.qris &&
      !_.isEmpty(paymentDetailsData.qr_string);

    const renderValuePayment = () => {
      let renderElement = null;

      switch (orderDetailsData.payment_type) {
        case paymentMethodOptionCode.fit_point:
          renderElement = <img src={Icons.fitPoints} alt="Fit Points" />;
          break;
        default:
          break;
      }

      return renderElement;
    };

    const getItemPrice = item => {
      if (!isDraft) {
        return item.item_price;
      }

      switch (paymentMethod) {
        case paymentMethodOptionCode.grabfood:
          return item.sales_order_item_id ? item.price_data.grabfood_price : item.grabfood_price;
        case paymentMethodOptionCode.gofood:
          return item.sales_order_item_id ? item.price_data.gofood_price : item.gofood_price;
        case paymentMethodOptionCode.shopeefood:
          return item.sales_order_item_id
            ? item.price_data.shopeefood_price
            : item.shopeefood_price;
        default:
          if (item.sales_order_item_id) {
            let price = 0;
            if (!_.isEmpty(item.price_data)) {
              price =
                isGuest || _.isEmpty(item.special_price)
                  ? item.price_data.normal_price
                  : item.price_data.special_price;
            } else {
              price = item.item_price;
            }
            return price;
          }
          return isGuest || _.isEmpty(item.special_price) ? item.normal_price : item.special_price;
      }
    };

    const getGrandTotal = () => {
      if (!isDraft) {
        return orderSummaryData.grand_total;
      }

      if (isValidPromoCode) {
        if (!_.isEmpty(promoCodeDetails)) {
          return promoCodeDetails.totalPriceAfterReward;
        }
        return orderSummaryData.grand_total;
      }

      let grandTotal = 0;
      orderItems.forEach(item => {
        const itemPrice = getItemPrice(item);
        const itemSubtotal = itemPrice * item.quantity;

        grandTotal += itemSubtotal;
      });

      return grandTotal;
    };
    const orderGrandTotal = getGrandTotal();

    return (
      <Grid container className="order-card-details" direction="row" wrap={'nowrap'}>
        <Grid item lg={10} md={8}>
          <Grid container direction="column">
            <Grid item className="pt-16">
              <Grid container spacing={2} direction="row">
                <Grid item lg={3} md={6} className="order-items-text">
                  <label className="text-12 text-opacity-50">Transaction Date</label>
                  <label className="text-12 pt-4 text-bold">
                    {CommonHelper.dateTimeParseNewFormat(
                      orderDetailsData.order_date,
                      'DD MMM YYYY | LT',
                    )}
                  </label>
                </Grid>
                <Grid item lg={3} md={6} className="order-items-text">
                  <label className="text-12 text-opacity-50">Payment Method</label>
                  <label className="text-12 pt-4 text-bold">
                    {orderDetailsData.payment_type_title}
                  </label>
                </Grid>
                {showQRIS && (
                  <Grid item lg={3} md={6} className="order-items-text">
                    <div className="text-right">
                      <IconButton size="small" onClick={this.handleOpenModalQris}>
                        <i className="ic-ffo-qr-code container-icon-prefix size-20 text-black qr-code" />
                      </IconButton>
                    </div>
                  </Grid>
                )}
                {orderDetailsData.payment_type === paymentMethodOptionCode.bank_transfer && (
                  <Grid item lg={3} md={6} className="order-items-text">
                    <label className="text-12 text-opacity-50">VA Number</label>
                    <label className="text-12 pt-4 text-bold">
                      {orderDetailsData.va_number || '-'}
                    </label>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={2} md={4}>
          <Grid container direction="row" className="order-amount-section">
            <Grid item lg={12} md={12}>
              <Grid
                container
                direction="column"
                justify="center"
                className="order-items-amount-text"
              >
                <Grid item lg md className="vertical-align-center">
                  <span className="flex-row text-center">
                    {renderValuePayment()}
                    <label className="text-16 text-bold currency">
                      {orderDetailsData.total_paid !== 0
                        ? `IDR ${CommonHelper.formatCurrency(orderGrandTotal)}`
                        : 'Free'}
                    </label>
                  </span>
                </Grid>
                <Grid item lg md className="pt-4 vertical-align-start">
                  <LabelStatusPayment status={orderDetailsData.payment_status} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  renderCustomerDetails = () => {
    const {
      orderData: { orderDetails },
    } = this.props;
    const customerDetailsData = !_.isEmpty(orderDetails) ? orderDetails.customer_details : {};
    const orderDetailsData = !_.isEmpty(orderDetails) ? orderDetails.order_details : {};
    const roomDetail =
      !_.isEmpty(orderDetails) &&
      !_.isEmpty(orderDetails.shipping_details) &&
      orderDetails.shipping_details.shipping_notes;

    return (
      <Grid container className="pt-24 flex-wrap-unset" direction="column">
        <Grid item lg={12} md={12} sm={12} className="pb-16">
          <label className="text-12 text-opacity-50 text-bold">Customer Details</label>
        </Grid>
        <Grid item lg={12} md={12} sm={12}>
          <Grid
            container
            direction="column"
            className="container-row-table details flex-wrap-unset"
          >
            <GridRowTableOneColumn
              customElementInitial={
                <label className="text-14 title text-rolling-stone pl-12">Name</label>
              }
              customElementColumn={
                <label className="text-14">{customerDetailsData.customer_name}</label>
              }
              columnLeft={5}
              columnRight={7}
            />
            <GridRowTableOneColumn
              customElementInitial={
                <label className="text-14 title text-rolling-stone pl-12">
                  {customerDetailsData.medical_record_number ? 'Room Detail' : 'Email'}
                </label>
              }
              customElementColumn={
                <label className="text-14 wrapping-container-break">
                  {customerDetailsData.medical_record_number
                    ? roomDetail
                    : customerDetailsData.customer_email || '-'}
                </label>
              }
              columnLeft={5}
              columnRight={7}
            />
            <GridRowTableOneColumn
              customElementInitial={
                <label className="text-14 title text-rolling-stone pl-12">
                  {customerDetailsData.medical_record_number ? 'No. Rekam Medis' : 'Phone'}
                </label>
              }
              customElementColumn={
                <label className="text-14">
                  {customerDetailsData.medical_record_number
                    ? customerDetailsData.medical_record_number
                    : customerDetailsData.customer_phone || '-'}
                </label>
              }
              columnLeft={5}
              columnRight={7}
            />
            <GridRowTableOneColumn
              customElementInitial={
                <label className="text-14 title text-rolling-stone pl-12">Gender</label>
              }
              customElementColumn={
                <label className="text-14">
                  {_.isEmpty(customerDetailsData.customer_gender)
                    ? '-'
                    : customerDetailsData.customer_gender}
                </label>
              }
              columnLeft={5}
              columnRight={7}
            />
            <GridRowTableOneColumn
              customElementInitial={
                <label className="text-14 text-rolling-stone title text-rolling-stone pl-12">
                  Order Type
                </label>
              }
              customElementColumn={
                <label className="text-14">
                  {orderDetailsData.at_store
                    ? 'Eat - At Store'
                    : MasterDataHelper.getOrderTypeDescription(customerDetailsData.order_type)}
                </label>
              }
              columnLeft={5}
              columnRight={7}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  };

  renderCustomerIdentity = () => {
    const {
      orderData: { orderDetails },
    } = this.props;
    const customerIdentityData = !_.isEmpty(orderDetails) ? orderDetails.identity_details : {};

    return (
      <Grid container className="pt-24 flex-wrap-unset" direction="column">
        <Grid item lg={12} md={12} sm={12} className="pb-16">
          <label className="text-12 text-opacity-50 text-bold">Customer Identity</label>
        </Grid>
        <Grid item lg={12} md={12} sm={12}>
          <Grid
            container
            direction="column"
            className="container-row-table details flex-wrap-unset"
          >
            <GridRowTableOneColumn
              customElementInitial={
                <label className="text-14 title text-rolling-stone pl-12">Type</label>
              }
              customElementColumn={
                <label className="text-14 uppercase">{customerIdentityData.identity_type}</label>
              }
              columnLeft={5}
              columnRight={7}
            />
            <GridRowTableOneColumn
              customElementInitial={
                <label className="text-14 title text-rolling-stone pl-12">Number</label>
              }
              customElementColumn={
                <label className="text-14 wrapping-container-break">
                  {customerIdentityData.identity_number || '-'}
                </label>
              }
              columnLeft={5}
              columnRight={7}
            />
            <GridRowTableOneColumn
              customElementInitial={
                <label className="text-14 title text-rolling-stone pl-12">Nationality</label>
              }
              customElementColumn={
                <label className="text-14">{customerIdentityData.nationality}</label>
              }
              columnLeft={5}
              columnRight={7}
            />
            <GridRowTableOneColumn
              customElementInitial={
                <label className="text-14 title text-rolling-stone pl-12">Address</label>
              }
              customElementColumn={
                <label className="text-14">{customerIdentityData.identity_address || '-'}</label>
              }
              columnLeft={5}
              columnRight={7}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  };

  renderPaymentDetails = () => {
    const {
      orderData: { orderDetails },
    } = this.props;
    const { isSuccess, form } = this.state;

    const paymentDetailsData = !_.isEmpty(orderDetails) ? orderDetails.payment_details : {};
    const orderDetailsData = !_.isEmpty(orderDetails) ? orderDetails.order_details : {};
    const selectedBank = _.find(bankTransferOption, ['value', paymentDetailsData.bank]);
    const successIcon = 'ic-ffi-check-mark';

    return (
      <Grid container className="pt-24" direction="row" spacing={4}>
        <Grid item lg={6} md={6}>
          <Grid item className="disabled">
            <FormControl component="fieldset" fullWidth margin={'normal'}>
              <FormLabel component="label" className="form-label">
                Payment Method
              </FormLabel>
              <TextInput size="md" currentValue={orderDetailsData.payment_type_title || '-'} />
            </FormControl>
          </Grid>
          <Grid item className="disabled">
            <FormControl component="fieldset" fullWidth margin={'normal'}>
              <FormLabel component="label" className="form-label">
                Promo Code
              </FormLabel>
              <div className={`container-promo ${isSuccess}`}>
                <TextInput
                  size="md"
                  currentValue={paymentDetailsData.promo_code || '-'}
                  iconPrefixEnd={paymentDetailsData.promo_code ? successIcon : ''}
                />
              </div>
            </FormControl>
          </Grid>
        </Grid>
        <Grid item lg={6} md={6} className="disabled">
          {orderDetailsData.payment_type === paymentMethodOptionCode.bank_transfer ? (
            <Grid item className="pt-32 ml-8">
              <FormControl component="fieldset" fullWidth margin={'normal'}>
                <RadioInputImage
                  data={bankTransferOption}
                  direction="column"
                  defaultValue={selectedBank}
                  imageWidth="60px"
                />
              </FormControl>
            </Grid>
          ) : (
            this.renderMembership()
          )}
          {/* {paymentDetailsData.potential_cashback && (
            <Grid item lg={12} md={12} className="mt-43">
              <LabelAlert
                message={paymentDetailsData.potential_cashback}
                type="success"
                iconPrefix="ic-ffo-check-mark"
              />
            </Grid>
          )} */}
          {form.paymentMethod === paymentMethodOptionCode.ovo && (
            <Grid item className="disabled">
              <FormControl component="fieldset" fullWidth margin={'normal'}>
                <FormLabel component="label" className="form-label">
                  Phone Number
                </FormLabel>
                <TextInput size="md" currentValue={paymentDetailsData.phone || '-'} />
              </FormControl>
            </Grid>
          )}
          <Grid item className="disabled">
            <FormControl component="fieldset" fullWidth margin="normal">
              <FormLabel component="label" className="form-label">
                Transaction Reference
              </FormLabel>
              <div className="container-promo">
                <TextInput size="md" currentValue={paymentDetailsData.transaction_reference} />
              </div>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  renderPaymentDetailsEnable = () => {
    const {
      paymentTypeOption,
      form,
      validation,
      isValidPromoCode,
      loading,
      isSuccess,
    } = this.state;
    const successIcon = 'ic-ffi-check-mark';
    return (
      <Grid container className="pt-24">
        <Grid item lg={12} md={12}>
          <Grid container spacing={2}>
            <Grid item lg={6} md={6}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <FormLabel component="label" className="text-12 filed-title">
                  Payment Method
                </FormLabel>
                <SelectInputMain
                  options={paymentTypeOption}
                  currentValue={form.paymentMethod}
                  onChange={this.handleSelectPaymentMethod}
                />
              </FormControl>
            </Grid>
            {form.paymentMethod === paymentMethodOptionCode.ovo && (
              <Grid item lg={6} md={6}>
                <FormControl component="fieldset" fullWidth margin="normal">
                  <FormLabel component="label" className="text-12 filed-title">
                    Phone Number
                  </FormLabel>
                  <div className="container-promo">
                    <TextInput
                      placeHolderText="Phone Number"
                      size="md"
                      onChange={this.handleChangePhoneNumberPayment}
                      currentValue={form.phoneNumberPayment}
                      errorMessage={validation.phoneNumberPayment.errorMessage}
                      isError={validation.phoneNumberPayment.isError}
                    />
                  </div>
                </FormControl>
              </Grid>
            )}
            {form.paymentMethod === paymentMethodOptionCode.bank_transfer ? (
              <Grid item lg={12} md={12} className="mt-27">
                <FormControl component="fieldset" fullWidth margin="normal">
                  <RadioInputImage
                    data={bankTransferOption}
                    onSelect={this.handleChangeBankTransfer}
                    direction="column"
                    currentValue={form.bank}
                    imageWidth="60px"
                  />
                </FormControl>
              </Grid>
            ) : (
              this.renderMembership()
            )}
            <Grid item lg={6} md={6}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <FormLabel component="label" className="text-12 filed-title">
                  Transaction Reference
                </FormLabel>
                <div className="container-promo">
                  <TextInput
                    placeHolderText="Transaction Reference"
                    size="md"
                    onChange={this.handleChangeTransactionReference}
                    currentValue={form.transactionReference}
                    errorMessage={validation.transactionReference.errorMessage}
                    isError={validation.transactionReference.isError}
                  />
                </div>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={12} md={12} sm={12}>
          <Grid container spacing={2}>
            <Grid item lg={6} md={6} sm={6}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <FormLabel component="label" className="text-12 filed-title">
                  Promo Code
                </FormLabel>
                <div className={`container-promo ${isSuccess}`}>
                  <TextInput
                    placeHolderText="Promo Code"
                    size="md"
                    onChange={this.handleChangePromoCodeEntry}
                    currentValue={form.promoCode}
                    errorMessage={validation.promoCode.errorMessage}
                    isError={validation.promoCode.isError}
                    iconPrefixEnd={isValidPromoCode ? successIcon : ''}
                  />
                </div>
              </FormControl>
            </Grid>
            <Grid item lg={3} md={4} sm={6} className="mt-42">
              <ButtonMain
                type="primary"
                size="md"
                labelText="Add Promo"
                onClick={this.handleButtonClickAddPromo}
                startIcon="ic-ffo-add"
                isLoading={loading}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  renderModalAddPromoCode() {
    const {
      orderData: { availablePromoCode },
    } = this.props;
    const { isOpenModalPromoCode, selectedPromoCode, searchAvailablePromoCode } = this.state;
    let renderElement = null;

    if (!_.isEmpty(availablePromoCode)) {
      renderElement = (
        <ModalAddPromoCode
          isOpen={isOpenModalPromoCode}
          availablePromoCode={availablePromoCode.data}
          selectedPromoCode={selectedPromoCode}
          searchAvailablePromoCode={searchAvailablePromoCode}
          onCloseModal={this.handleCloseModalPromoCode}
          onApplyButton={this.handleButtonClickApply}
          onSelectPromoCode={this.handleSelectPromoCode}
          onSearchPromoCode={this.handleQueryAvailablePromoCode}
        />
      );
    }

    return renderElement;
  }

  renderOrderSummary = () => {
    const {
      orderData,
      scheduleSummary: { bookingAdminFee },
    } = this.props;
    const {
      detailOrderData,
      form,
      isAllChecked,
      isValidPromoCode,
      promoCodeDetails,
      orderTaxPercentage,
      orderServiceChargePercentage,
    } = this.state;
    const orderDetailsData = !_.isEmpty(orderData.orderDetails)
      ? orderData.orderDetails.order_details
      : {};
    const isDraft = orderDetailsData.order_status === orderStatusCode.Draft;

    let renderElement = null;

    if (
      orderDetailsData.order_type === productTypeForOrderCode.shop ||
      orderDetailsData.order_type === productTypeForOrderCode.move ||
      orderDetailsData.order_type === productTypeForOrderCode.medic
    ) {
      renderElement = (
        <CartItemDetailRetails orderData={orderData.orderDetails} adminFee={bookingAdminFee} />
      );
    } else if (orderDetailsData.order_type === productTypeForOrderCode.eat) {
      if (orderDetailsData.at_store) {
        renderElement = (
          <CartItemDetailsEatsAtStore
            orderData={detailOrderData}
            form={form}
            promoCodeDetails={promoCodeDetails}
            isAllChecked={isAllChecked}
            isDraft={isDraft}
            isValidPromoCode={isValidPromoCode}
            onHandleSelectAllData={this.handleSelectAllData}
            onHandleSelectData={this.handleSelectedDataToBePrinted}
            onHandleOpenModalAddFood={this.handleOpenModalAddFood}
            onHandleAddQuantity={this.handleAddQuantityProduct}
            orderTaxPercentage={orderTaxPercentage}
            orderServiceChargePercentage={orderServiceChargePercentage}
          />
        );
      } else {
        renderElement = <CartItemDetailsEats orderData={orderData.orderDetails} />;
      }
    } else if (orderDetailsData.order_type === productTypeForOrderCode.merchantMembership) {
      renderElement = <CartItemDetailMembership orderData={orderData.orderDetails} />;
    }

    return renderElement;
  };

  renderCancelButton = () => {
    const {
      orderData: { orderDetails },
    } = this.props;
    const orderDetailsData = !_.isEmpty(orderDetails) ? orderDetails.order_details : {};
    const paymentType = orderDetailsData.payment_type;
    const paymentStatus = orderDetailsData.payment_status;

    let renderCancel = orderDetailsData.order_status === orderStatusCode.Draft;

    switch (paymentType) {
      case paymentMethodOptionCode.gofood:
      case paymentMethodOptionCode.grabfood:
      case paymentMethodOptionCode.shopeefood:
      case paymentMethodOptionCode.shopeepay:
        switch (paymentStatus) {
          case statusPaymentCode.Cancelled:
          case statusPaymentCode.Expired:
          case statusPaymentCode.Declined:
            break;
          default:
            renderCancel = true;
            break;
        }
        break;
      default:
        break;
    }

    if (!renderCancel) {
      return null;
    }

    return (
      <Grid item className="mr-8">
        <ButtonMain
          type="negative"
          size="md"
          labelText="Cancel Order"
          onClick={() => {
            this.setState({ isOpenCancelOrderModal: true });
          }}
          requiredPermission={`${PermissionModule.Orders}.${PermissionPage.List}.${PermissionAccess.Delete}`}
        />
      </Grid>
    );
  };

  renderUpdatePaymentButton = () => {
    const {
      orderData: { orderDetails },
    } = this.props;
    const orderDetailsData = !_.isEmpty(orderDetails) ? orderDetails.order_details : {};

    const isDraft = orderDetailsData.order_status === orderStatusCode.Draft;
    if (isDraft) {
      return null;
    }

    const paymentType = orderDetailsData.payment_type;
    const paymentStatus = orderDetailsData.payment_status;

    let renderChangePaymentButton = false;

    switch (paymentType) {
      case paymentMethodOptionCode.gofood:
      case paymentMethodOptionCode.grabfood:
      case paymentMethodOptionCode.shopeefood:
        renderChangePaymentButton = false;
        break;
      default:
        switch (paymentStatus) {
          case statusPaymentCode.Awaiting_Token:
          case statusPaymentCode.Token_Generated:
          case statusPaymentCode.Virtual_Bank_Account_Generated:
          case statusPaymentCode.Credit_Card_Charged:
          case statusPaymentCode.GoPay_QR_And_Deeplink_Generated:
          case statusPaymentCode.Pending:
          case statusPaymentCode.Expired:
          case statusPaymentCode.Declined:
          case statusPaymentCode.Cancelled:
            renderChangePaymentButton = true;
            break;
          default:
            renderChangePaymentButton = false;
            break;
        }
        break;
    }

    if (!renderChangePaymentButton) {
      return null;
    }

    return (
      <Grid item className="mr-8">
        <ButtonMain
          type="light"
          size="md"
          labelText="Change Payment"
          onClick={() => {
            this.setState({ isOpenChangeOrderPaymentModal: true });
          }}
          requiredPermission={`${PermissionModule.Orders}.${PermissionPage.List}.${PermissionAccess.Delete}`}
        />
      </Grid>
    );
  };

  renderAdditionalButton = () => {
    const {
      orderData: { fetching },
    } = this.props;
    const { isHideButton } = this.state;
    return (
      <Grid item lg={12} md={12} sm={12} className="mt-24">
        <Grid container justify="flex-end">
          <Grid item lg={4} md={5}>
            <Grid container justify={isHideButton ? 'flex-end' : 'space-around'}>
              <Grid item lg={6} md={6} className="pl-8">
                <ButtonMain
                  type="outlined-primary"
                  size="xl"
                  labelText="Save"
                  isLoading={fetching}
                  onClick={this.handleSaveOpenBill}
                  requiredPermission={`${PermissionModule.Orders}.${PermissionPage.List}.${PermissionAccess.Update}`}
                />
              </Grid>
              {!isHideButton && (
                <Grid item lg={6} md={6} className="pl-8">
                  <ButtonMain
                    type="primary"
                    size="xl"
                    labelText="Submit"
                    isLoading={fetching}
                    onClick={this.handleSubmitOpenBill}
                    requiredPermission={`${PermissionModule.Orders}.${PermissionPage.List}.${PermissionAccess.Add}`}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  render() {
    const {
      isOpenQrisModal,
      isOpenCancelOrderModal,
      isOpenChangeOrderPaymentModal,
      isOpenAddFoodModal,
      toastInformation,
      detailOrderData,
      filter,
      form,
      loading,
      pagination,
      isOpenModalPromoCode,
      isFetching,
      isHideButton,
      validation,
      isOpenImagePreviewModal,
    } = this.state;
    const {
      orderData: { orderDetails },
      orderData,
      eatData,
    } = this.props;

    const orderDetailsData = !_.isEmpty(orderDetails) ? orderDetails.order_details : {};
    const customerDetailsData = !_.isEmpty(orderDetails) ? orderDetails.customer_details : {};
    const paymentDetailsData = !_.isEmpty(orderDetails) ? orderDetails.payment_details : {};
    const identityDetailsData = !_.isEmpty(orderDetails) ? orderDetails.identity_details : {};

    const isEatAtStoreTransaction =
      customerDetailsData.order_type === productTypeForOrderCode.eat && orderDetailsData.at_store;

    const renderShipping =
      customerDetailsData.order_type === productTypeForOrderCode.move ||
      customerDetailsData.order_type === productTypeForOrderCode.eat ||
      customerDetailsData.order_type === productTypeForOrderCode.shop ||
      customerDetailsData.order_type === productTypeForOrderCode.medic;

    const isDraft = orderDetailsData.order_status === orderStatusCode.Draft;
    const isGuest = customerDetailsData.customer_email === 'guest@fitco.id';
    const showQRIS =
      orderDetailsData.payment_type === paymentMethodOptionCode.qris &&
      !_.isEmpty(paymentDetailsData.qr_string);
    const ListMenu = eatData.list;

    const qrOption = {
      logoImage: Images.fitcoLogoRounded,
      logoWidth: 400,
      logoHeight: 400,
      size: 2000,
      ecLevel: 'Q',
    };

    return (
      <div>
        <Helmet
          title={`FITCO | Orders - Order Details ${
            orderDetails ? orderDetails.sales_order_id : ''
          } `}
        />
        <div className="container-page-order-details scroll-container-invisible">
          <div className="container-page-scrolling-area">
            <Grid container direction="column">
              <Grid item lg md className="section-page-header">
                <Grid container justify="space-between" alignItems="center" spacing={3}>
                  <Grid item sm xs>
                    <div className="breadcrumbs-section">
                      <Breadcrumbs aria-label="breadcrumb">
                        <Link
                          className="text-12"
                          color="inherit"
                          href="/orders"
                          onClick={event => {
                            this.handleClick(event, '/orders');
                          }}
                        >
                          <i className="icon-slot ic-ffo-reports mr-4" /> Order
                        </Link>
                        <label className="text-12" color="inherit">
                          Order Details
                        </label>
                      </Breadcrumbs>
                    </div>
                  </Grid>
                  <Grid item lg={'auto'} md={'auto'}>
                    <Grid container direction="row" justify="flex-end" alignItems="center">
                      <Grid item className="mr-8">
                        <ButtonMain
                          type="ghost"
                          size="md"
                          labelText="Back"
                          onClick={this.handleButtonCancel}
                        />
                      </Grid>
                      {!isHideButton && this.renderCancelButton()}
                      {!isHideButton && this.renderUpdatePaymentButton()}
                      {isEatAtStoreTransaction && isDraft && !isHideButton ? (
                        <Grid item className="mr-8">
                          <GeneratorPdf
                            data={detailOrderData}
                            buttonType="primary"
                            buttonLabel="Print Capt. Order"
                            customLabel={'captain-order'}
                            onClick={() => this.handleButtonPrint()}
                          />
                        </Grid>
                      ) : null}
                      {isEatAtStoreTransaction && !isDraft ? (
                        <Grid item>
                          <GeneratorPdf
                            data={orderDetails}
                            buttonType="primary"
                            buttonLabel="Print Invoice"
                            customLabel={
                              customerDetailsData.medical_record_number ||
                              customerDetailsData.user_room_id
                                ? 'catering-rspad'
                                : 'eat-at-store-invoice'
                            }
                          />
                        </Grid>
                      ) : null}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item lg md className="section-page-body">
                {isFetching ? (
                  <SkeletonDetailsV03 />
                ) : (
                  <div className="order-details-card">
                    <Grid
                      className="pb-24"
                      container
                      justify="space-between"
                      alignItems="center"
                      spacing={3}
                    >
                      <Grid item lg md>
                        <Grid container direction="row">
                          <Grid item className="flex-column order-info">
                            <label className="text-12 text-opacity-50">Order ID</label>
                            <label className="text-12 text-bold pt-4">
                              {orderDetailsData.sales_order_id}
                            </label>
                          </Grid>
                          <Grid item className="flex-column order-info">
                            <label className="text-12 text-opacity-50">Order Number</label>
                            <label className="text-12 text-bold pt-4">
                              {orderDetailsData.order_no}
                            </label>
                          </Grid>
                          <Grid item className="flex-column order-info">
                            <label className="text-12 text-opacity-50">Order Type</label>
                            <label className="text-12 text-bold pt-4">
                              {MasterDataHelper.getOrderTypeDescription(
                                orderDetailsData.order_type,
                              )}
                            </label>
                          </Grid>
                          {isDraft && (
                            <Grid item className="flex-column order-info">
                              <label className="text-12 text-opacity-50">Order Status</label>
                              <label className="text-12 text-bold pt-4">
                                {orderDetailsData.order_status_title}
                              </label>
                            </Grid>
                          )}
                          <Grid item className="flex-column order-info">
                            <label className="text-12 text-opacity-50">Source</label>
                            <label className="text-12 text-bold pt-4">
                              {_.capitalize(orderDetailsData.transaction_source)}
                            </label>
                          </Grid>
                          {orderDetailsData.external_order_id && (
                            <Grid item className="flex-column order-info">
                              <label className="text-12 text-opacity-50">W-Order ID</label>
                              <label className="text-12 text-bold pt-4">
                                {orderDetailsData.external_order_id}
                              </label>
                            </Grid>
                          )}
                          {orderDetailsData.tracking_number && (
                            <Grid item className="flex-column order-info">
                              <label className="text-12 text-opacity-50">Tracking Number</label>
                              <label className="text-12 text-bold pt-4">
                                {orderDetailsData.tracking_number}
                              </label>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container>{this.renderOrderCard()}</Grid>
                    <Grid container spacing={4}>
                      <Grid item lg={6} md={6} sm={12}>
                        {this.renderCustomerDetails()}
                      </Grid>
                      {renderShipping && (
                        <Grid item lg={6} md={6} sm={12}>
                          {orderDetailsData.order_type === productTypeForOrderCode.move ||
                          orderDetailsData.order_type === productTypeForOrderCode.medic ? (
                            <DetailsAppointment orderData={orderData} />
                          ) : (
                            <DetailsShipping orderData={orderData} />
                          )}
                        </Grid>
                      )}
                    </Grid>
                    {!_.isEmpty(identityDetailsData) && (
                      <Grid container spacing={4}>
                        <Grid item lg={6} md={6} sm={12}>
                          {this.renderCustomerIdentity()}
                        </Grid>
                        <Grid item lg={6} md={6} sm={12}>
                          <Grid container className="pt-24 flex-wrap-unset" direction="column">
                            <Grid item lg={12} md={12} sm={12}>
                              {isDraft ? (
                                <div className="container-image-action pt-36">
                                  <UploadImage
                                    onChange={item => this.handleChangeImage(item)}
                                    defaultValue={form.imageBase64}
                                    maxSize={5}
                                    validateStatus={validation.imageBase64.isError}
                                    errorMessage={validation.imageBase64.errorMessage}
                                  />
                                </div>
                              ) : (
                                <div className="container-id-image pt-36">
                                  {form.imageBase64 ? (
                                    <div onClick={this.handleOpenModalImagePreview}>
                                      <img src={form.imageBase64} alt="ktp" />
                                    </div>
                                  ) : (
                                    <label className="text-13 text-semi-bold">
                                      No image uploaded.
                                    </label>
                                  )}
                                </div>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                    {isDraft ? this.renderPaymentDetailsEnable() : this.renderPaymentDetails()}
                    {this.renderOrderSummary()}
                    {isDraft && this.renderAdditionalButton()}
                  </div>
                )}
              </Grid>
              <Grid item lg md className="section-page-footer" />
              {showQRIS && (
                <div className="shadow-qr-container">
                  <QRCode
                    value={paymentDetailsData.qr_string}
                    logoImage={qrOption.logoImage}
                    logoWidth={qrOption.logoWidth}
                    logoHeight={qrOption.logoHeight}
                    size={qrOption.size}
                    ecLevel={qrOption.ecLevel}
                  />
                </div>
              )}
              {isOpenQrisModal && (
                <ModalOrderQris
                  isOpen={isOpenQrisModal}
                  data={paymentDetailsData.qr_string}
                  onClose={this.handleCloseModalQris}
                />
              )}
              {isOpenCancelOrderModal && (
                <ModalUpdateOrder
                  handleCTA={this.onHandleCancelOrder}
                  isOpen={isOpenCancelOrderModal}
                  onClose={this.handleCloseModalCancelOrder}
                  subtitle={'Are you sure you want to cancel this order?'}
                  title={'Cancel Order'}
                  ctaStyle={'negative'}
                />
              )}
              {isOpenChangeOrderPaymentModal && (
                <ModalUpdateOrder
                  handleCTA={this.onHandleChangeOrderPayment}
                  isOpen={isOpenChangeOrderPaymentModal}
                  onClose={this.handleCloseModalChangePayment}
                  subtitle={
                    "This order will be moved to 'Draft' to enable payment change. Are you sure you want to continue?"
                  }
                  title={'Change Payment'}
                  ctaStyle={'primary'}
                />
              )}
              {isOpenAddFoodModal && (
                <ModalAddFood
                  isOpen={isOpenAddFoodModal}
                  searchTerm={filter.search}
                  loading={loading}
                  lists={ListMenu}
                  listSelectedItem={form.items}
                  pagination={pagination}
                  isGuest={isGuest}
                  onCloseModal={this.handleCloseModalAddFood}
                  onApplyButton={this.handleButtonClickAddFood}
                  onHandleQueryProduct={this.handleQueryProduct}
                  onHandleSelectItem={this.handleSelectItem}
                  onHandleTableChange={this.handleTableChange}
                  onHandleNotesEatAtStore={this.handleNotesEatAtStore}
                  onHandleButtonNumberStepper={this.handleButtonNumberStepper}
                />
              )}
              {isOpenModalPromoCode && this.renderModalAddPromoCode()}
              {isOpenImagePreviewModal && (
                <ModalImagePreview
                  isOpen={isOpenImagePreviewModal}
                  onClose={this.handleCloseModalImagePreview}
                  imageUrl={form.imageBase64}
                />
              )}
            </Grid>
            <SnackBarSimple
              open={toastInformation.isOpen}
              durationHide={2000}
              message={toastInformation.message}
              onClickClose={this.handleCloseToast}
              snackbarType={toastInformation.snackbarType}
              anchor={optionToast}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getOrder: params => getOrderDetails(dispatch, params),
  processCancelOrder: salesOrderID => cancelOrder(dispatch, salesOrderID),
  processChangeOrderPayment: salesOrderID => changeOrderPayment(dispatch, salesOrderID),
  listEatPagination: params => getListEatPagination(dispatch, params),
  checkPromo: params => checkPromoCode(dispatch, params),
  checkAvailablePromoCode: params => getAvailablePromoCode(dispatch, params),
  checkTax: () => getOrderTaxPercentage(dispatch),
  checkServiceCharge: () => getOrderServiceChargePercentage(dispatch),
  processSaveOpenBill: (salesOrderID, params) => saveOpenBill(dispatch, salesOrderID, params),
  processSubmitOpenBill: (salesOrderID, params) => submitOpenBill(dispatch, salesOrderID, params),
  processPrintCaptainOrder: params => setPrintCaptainOrder(dispatch, params),
  bookingAdminFee: () => getBookingAdminFee(dispatch),
});

const mapStateToProps = ({ orderData, eatData, scheduleSummary }) => ({
  orderData,
  eatData,
  scheduleSummary,
});

OrderDetails.propTypes = {
  bookingAdminFee: PropTypes.func,
  checkAvailablePromoCode: PropTypes.func,
  checkPromo: PropTypes.func,
  checkServiceCharge: PropTypes.func,
  checkTax: PropTypes.func,
  checkUserAccessPermission: PropTypes.func,
  eatData: PropTypes.object,
  getOrder: PropTypes.func,
  history: PropTypes.object,
  listEatPagination: PropTypes.func,
  match: PropTypes.object,
  orderData: PropTypes.object,
  processCancelOrder: PropTypes.func,
  processChangeOrderPayment: PropTypes.func,
  processPrintCaptainOrder: PropTypes.func,
  processSaveOpenBill: PropTypes.func,
  processSubmitOpenBill: PropTypes.func,
  scheduleSummary: PropTypes.object,
};

const shell = compose(connect(mapStateToProps, mapDispatchToProps));
const core = compose(AuthenticationAccessPages, PrevStateValue);

export default shell(core(OrderDetails));
