/* eslint-disable react/no-unused-state */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable no-lonely-if */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-case-declarations */
import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Grid, Breadcrumbs, Link, FormControl, FormLabel, Fade } from '@material-ui/core';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Table, List } from 'antd';
import clsx from 'clsx';
import Slider from 'react-slick';
// style
import './OrderAddStyle.scss';
// component
import {
  NavigationStep,
  SelectInputMain,
  SnackBarSimple,
  TextInputAutocomplete,
  TextInputNumber,
  TabNavigation,
  PickerInputDate,
  TextInput,
  PaginationList,
  FitcoPopover,
  CheckInputButton,
  SelectSearchMultiple,
  ButtonMain,
  TextAreaMain,
} from '../../../components/Index';
import {
  ButtonActionFooter,
  ItemOrderEat,
  ItemOrderEatCard,
  ModalSummaryEatOrders,
  ModalSelectVariant,
} from './components/Index';
import { StepUserDetails, StepSummaryAndPayment } from './sub-step-view/Index';
// helper
import {
  MasterDataHelper,
  CommonHelper,
  OrderHelper,
  HttpStatusCode,
  FitcoPopoverTrigger,
  FitcoPopoverPlacement,
  eatSessionTypeCode,
  productTypeForOrderCode,
  UserHelper,
} from '../../../helpers/Index';
// api
import {
  getOrderProductPagination,
  setProcessCreateOrder,
  getOrderEatListByType,
  getOrderEatListByTypeProduct,
  getOrderEatListExtra,
  getFavoriteMenus,
} from '../../../services/api/OrdersApi';
import { searchNearestEatStoreLocation, getListEatPagination } from '../../../services/api/EatApi';
import { paymentMethodOptionCode } from '../../../helpers/MasterDataHelper';

const productTypeOrderOption = MasterDataHelper.productTypeForOrder;
const productTypeOrderEatsOption = MasterDataHelper.productTypeForOrderEats;
const addressTypeValue = MasterDataHelper.addressTypeOrder;
const currentDate = CommonHelper.intervalDate(
  CommonHelper.currentDate('YYYY-MM-DD'),
  'YYYY-MM-DD',
  1,
  'day',
);
const startDateValue = CommonHelper.startWorkingDayInWeek(currentDate);
const endDateValue = CommonHelper.getEndDateMonth(startDateValue);
const maxEntryDateEats = CommonHelper.intervalDate(
  CommonHelper.currentDate('YYYY-MM-DD'),
  'YYYY-MM-DD',
  40,
  'day',
);

const timeInterval = 300;

const initialFilter = {
  orderType: productTypeForOrderCode.shop,
  orderEatType: '',
  orderEatTypeProductTab: 0,
  search: '',
  startDate: CommonHelper.objectCloning(startDateValue),
  endDate: CommonHelper.objectCloning(endDateValue),
  daysShow: [],
  storeSelected: null,
};

const filterDaysOption = MasterDataHelper.repeatDayData;

const initialForm = {
  startDate: CommonHelper.objectCloning(startDateValue),
  listExtra: [],
  selectedPackage: {},
  items: [],
  globalExtraValue: [],
  isFirstTimePurchase: false,
  isExisting: false,
  orderType: productTypeOrderOption[0].value,
  orderEatType: null,
  isStaffCheckout: false,
};

const initialValidation = {
  listItem: { isError: false, errorMessage: '' },
};

const initialSort = {
  sortField: 'name',
  sortOrder: '',
};

const optionToast = {
  vertical: 'top',
  horizontal: 'right',
};

class OrderAdd extends React.Component {
  constructor(props) {
    super(props);

    const currentUsers = UserHelper.getCurrentUserInformation();

    this.state = {
      current: 0,
      listItems: [],
      stepList: [
        { key: 'cart', name: 'Cart' },
        { key: 'user_detail', name: 'User Details' },
        { key: 'summary', name: 'Summary' },
      ],
      limit: 99,
      pagination: {
        hideOnSinglePage: true,
        showSizeChanger: false,
        pageSizeOptions: ['10', '20', '30', '40'],
      },
      sort: CommonHelper.objectCloning(initialSort),
      form: CommonHelper.objectCloning(initialForm),
      isRecipientChange: false,
      filter: CommonHelper.objectCloning(initialFilter),
      validation: CommonHelper.objectCloning(initialValidation),
      grandTotal: 0,
      isVisibleGlobalExtra: false,
      isOpenModalEatSummary: false,
      eatOrderTypeOption: [],
      totalWholesaleDiscount: 0,
      toastInformation: {
        isOpen: false,
        message: '',
        snackbarType: 'warning',
      },
      isResellerLogin: currentUsers && currentUsers.isResellerLogin,
      storeOption: [],
      loading: false,
      storeDetailsSelected: null,
      eligibleToPay: true,
      useAnonymous: false,
      isOpenModalSelectVariant: false,
      optionList: {},
    };

    this.searchProductDebounce = _.debounce(this.searchProductDebounce, 400);
    this.searchFoodDebounce = _.debounce(this.searchFoodDebounce, 400);
    this.searchDebounce = _.debounce(this.searchDebounce, 400);
  }

  componentDidMount() {
    this.getDefaultFilter();
  }

  componentWillUnmount() {}

  getDefaultFilter = () => {
    const { filter } = this.state;
    const currentUsers = UserHelper.getCurrentUserInformation();
    const userRoleName = currentUsers && currentUsers.role_name;
    const userMerchants = currentUsers && currentUsers.user_merchants;
    const {
      orderType,
      orderEatType,
      selectedStore,
    } = OrderHelper.getAutoFilterByRoleNameAndUserMerchant(userRoleName, userMerchants);

    this.setState(
      {
        form: {
          ...CommonHelper.objectCloning(initialForm),
          orderType,
          orderEatType,
        },
        filter: {
          ...filter,
          orderType,
          orderEatType,
          storeSelected: selectedStore ? selectedStore.branch_id : null,
        },
        storeDetailsSelected: selectedStore,
      },
      async () => {
        if (orderEatType === productTypeOrderEatsOption[2].value) {
          await this.getEatStoreLocation();
          await this.getFavoriteMenusAtStore();
        }
      },
    );
  };

  getOrderEatListByType() {
    const { orderEatListByType } = this.props;
    const {
      filter: { orderEatType },
    } = this.state;

    orderEatListByType(orderEatType)
      .then(response => {
        const data = response.data;
        const currentData = OrderHelper.generateEatProductOption(data);

        this.setState(
          {
            eatOrderTypeOption: currentData,
          },
          async () => {
            await this.getOrderEatListExtra();
            await this.handleTabChangeTypeProduct(0);
          },
        );
      })
      .catch(error => {
        const serverMessage = error.data;
        const validationStatus = error.status === HttpStatusCode.InternalServerError;
        this.processMessage(
          validationStatus ? serverMessage.message : serverMessage.messages,
          'error',
        );
      });
  }

  async getOrderEatListExtra() {
    const { orderEatListExtra } = this.props;
    const { form } = this.state;

    await orderEatListExtra({})
      .then(async response => {
        const data = response.data;

        await this.setState({
          form: { ...form, listExtra: data },
        });
      })
      .catch(error => {
        const serverMessage = error.data;
        const validationStatus = error.status === HttpStatusCode.InternalServerError;
        this.processMessage(
          validationStatus ? serverMessage.message : serverMessage.messages,
          'error',
        );
      });
  }

  async getOrderEatListByTypeProduct() {
    const { orderEatListByTypeProduct } = this.props;
    const {
      filter: { orderEatType, orderEatTypeProductTab, search, startDate, endDate, daysShow },
      eatOrderTypeOption,
      form,
      grandTotal,
    } = this.state;
    let defaultQty = 0;
    let defaultPrice = 0;
    let defaultExtra = [];
    let defaultNotes = '';

    const param = {
      search,
      page: 1,
      limit: 80,
      start_date: startDate,
      days: daysShow,
    };

    if (orderEatType === productTypeOrderEatsOption[1].value) {
      const checkHasDefaultValue = OrderHelper.validateEatPackageExtraHasDefault(
        form.selectedPackage.extraSession,
      );
      defaultQty = 1;
      defaultExtra = form.globalExtraValue;
      defaultPrice = form.selectedPackage.isContainLunchDinner
        ? form.selectedPackage.lunch_and_dinner_price
        : form.selectedPackage.normal_price;
      defaultNotes = form.notes;
      param.pax = form.selectedPackage.pax;
      param.time =
        checkHasDefaultValue.isExist && checkHasDefaultValue.total === 2
          ? ''
          : form.selectedPackage.extraSession[0];
    } else {
      param.end_date = endDate;
    }

    const paramId = eatOrderTypeOption[orderEatTypeProductTab].value;

    await orderEatListByTypeProduct(paramId, orderEatType, param)
      .then(async response => {
        const data = response.data;
        const dataRestore = OrderHelper.restoreEatList(data, form.items);
        const converted = OrderHelper.generateEatListGrouping(
          dataRestore,
          [],
          defaultQty,
          defaultExtra,
          defaultPrice,
          defaultNotes,
        );
        let total = grandTotal;
        if (orderEatType === productTypeOrderEatsOption[0].value) {
          const totalExtra = OrderHelper.getGrandTotalExtra(form.items, form.listExtra);
          total = OrderHelper.getGrandTotal(form.items, 0, 0, totalExtra);
        }

        await this.setState({
          grandTotal: total,
          listItems: converted,
        });
      })
      .catch(error => {
        const serverMessage = error.data;
        const validationStatus = error.status === HttpStatusCode.InternalServerError;
        this.processMessage(
          validationStatus ? serverMessage.message : serverMessage.messages,
          'error',
        );
      });
  }

  getEatStoreLocation = async () => {
    const {
      nearestEatStoreLocation,
      usersReducer: { coordinate },
    } = this.props;
    const { storeDetailsSelected, filter } = this.state;
    try {
      const params = {
        latitude: !_.isEmpty(coordinate) ? coordinate.coords.latitude : '',
        longitude: !_.isEmpty(coordinate) ? coordinate.coords.longitude : '',
      };
      const { data } = await nearestEatStoreLocation(params.latitude, params.longitude);
      const storeOption = data.map(item => ({ ...item, value: item.branch_id }));

      this.setState({
        storeOption,
        storeDetailsSelected: storeDetailsSelected || storeOption[0],
        filter: { ...filter, storeSelected: filter.storeSelected || storeOption[0].value },
      });
    } catch (error) {
      const errors = error.data;
      this.processMessage(errors.messages, 'error');
    }
  };

  getFavoriteMenusAtStore = async () => {
    const { favoriteMenus } = this.props;
    const { storeDetailsSelected } = this.state;
    try {
      const params = {
        branch_id: storeDetailsSelected.branch_id,
      };
      await favoriteMenus(params);
    } catch (error) {
      const errors = error.data;
      this.processMessage(errors.messages, 'error');
    }
  };

  handleClick = (event, value) => {
    const { history } = this.props;
    event.preventDefault();
    history.push(value);
  };

  handleSelectChangeOrderType = value => {
    const { filter } = this.state;
    this.setState(
      {
        form: { ...CommonHelper.objectCloning(initialForm), orderType: value },
        listItem: [],
        filter: {
          ...filter,
          orderType: value,
          orderEatType: '',
          orderEatTypeProductTab: 0,
          startDate: CommonHelper.objectCloning(startDateValue),
          search: '',
          storeSelected: null,
        },
        grandTotal: 0,
        totalWholesaleDiscount: 0,
        storeDetailsSelected: null,
        validation: CommonHelper.objectCloning(initialValidation),
      },
      () => {
        if (value === productTypeOrderOption[1].value) {
          this.handleSelectChangeOrderEatType(productTypeOrderEatsOption[0].value);
        }
      },
    );
  };

  handleSelectChangeOrderEatType = value => {
    const { form, filter } = this.state;
    this.setState(
      {
        form: { ...form, orderEatType: value, items: [], listExtra: [], selectedPackage: {} },
        filter: {
          ...filter,
          orderEatType: value,
          startDate: startDateValue,
          search: '',
          storeSelected: null,
        },
        listItem: [],
        grandTotal: 0,
        storeDetailsSelected: null,
        validation: CommonHelper.objectCloning(initialValidation),
      },
      async () => {
        if (value === productTypeOrderEatsOption[2].value) {
          await this.getEatStoreLocation();
          await this.getFavoriteMenusAtStore();
        } else {
          this.getOrderEatListByType();
        }
      },
    );
  };

  handleSelectChangeStore = value => {
    const { form, filter, storeOption, useAnonymous } = this.state;
    const stores = _.find(storeOption, ['branch_id', value]);
    const isNotSpecialStores = !stores.identification.includes('fitco-eats-catering-rspad');
    if (useAnonymous && !isNotSpecialStores) {
      this.setState({
        filter: { ...filter, storeSelected: value },
        storeDetailsSelected: stores,
        form: CommonHelper.objectCloning(initialForm),
        validation: CommonHelper.objectCloning(initialValidation),
        useAnonymous: false,
      });
    } else {
      this.setState({
        filter: { ...filter, storeSelected: value },
        storeDetailsSelected: stores,
        form: { ...form, addressType: stores.name, address: stores.address },
        validation: CommonHelper.objectCloning(initialValidation),
        useAnonymous,
      });
    }
  };

  handleTabChangeTypeProduct = value => {
    const { filter, eatOrderTypeOption, form } = this.state;
    const ifNotPackage = filter.orderEatType === productTypeOrderEatsOption[0].value;
    let modifyOptionPackage = CommonHelper.objectCloning(eatOrderTypeOption);

    if (!ifNotPackage && !_.isEmpty(eatOrderTypeOption)) {
      modifyOptionPackage = OrderHelper.generateEatProductPackageOption(modifyOptionPackage);
    }

    this.setState(
      {
        form: { ...form, globalExtraValue: [] },
        isVisibleGlobalExtra: false,
        filter: { ...filter, orderEatTypeProductTab: value },
        eatOrderTypeOption: modifyOptionPackage,
      },
      () => {
        if (ifNotPackage) {
          this.getOrderEatListByTypeProduct();
        } else {
          const paxValue =
            eatOrderTypeOption[value].option.packages &&
            eatOrderTypeOption[value].option.packages.length > 0
              ? eatOrderTypeOption[value].option.packages[0].pax
              : null;
          this.handleRadioCheckClick({ pax: paxValue });
        }
      },
    );
  };

  handleChangeSearch = value => {
    const { filter } = this.state;

    this.setState(
      {
        filter: { ...filter, search: value },
      },
      () => {
        this.searchDebounce();
      },
    );
  };

  searchDebounce = () => {
    this.getOrderEatListByTypeProduct();
  };

  handleChangeStartDate = value => {
    const { filter, form, listItems } = this.state;
    const startDate = value || '';
    const endDate = _.isEmpty(startDate) ? filter.endDate : CommonHelper.getEndDateMonth(value);
    let modifyItem = CommonHelper.objectCloning(form.items);

    const ifNotPackage = filter.orderEatType === productTypeOrderEatsOption[0].value;
    if (ifNotPackage) {
      modifyItem = OrderHelper.generateEatOrderInFullListSelected(listItems, form.items);
    }

    this.setState(
      {
        filter: { ...filter, startDate, endDate },
        form: { ...form, startDate, items: modifyItem },
      },
      () => {
        this.getOrderEatListByTypeProduct();
      },
    );
  };

  handleChangeEndDate = value => {
    const { filter, form, listItems } = this.state;
    const endDate = value || '';
    let modifyItem = form.items;

    const ifNotPackage = filter.orderEatType === productTypeOrderEatsOption[0].value;
    if (ifNotPackage) {
      modifyItem = OrderHelper.generateEatOrderInFullListSelected(listItems, form.items);
    }

    this.setState(
      {
        filter: { ...filter, endDate },
        form: { ...form, items: modifyItem },
      },
      () => {
        this.getOrderEatListByTypeProduct();
      },
    );
  };

  handleChangeFilterByDays = value => {
    const { filter } = this.state;

    this.setState(
      {
        filter: { ...filter, daysShow: value },
      },
      () => {
        if (filter.orderEatType === productTypeOrderEatsOption[0].value) {
          this.getOrderEatListByTypeProduct();
        }
      },
    );
  };

  handleChangeTextProduct = value => {
    const { filter } = this.state;

    this.setState(
      {
        filter: { ...filter, search: value },
      },
      () => {
        if (filter.orderType === productTypeForOrderCode.eat) {
          this.searchFoodDebounce();
        } else {
          this.searchProductDebounce();
        }
      },
    );
  };

  handleSelectedItem = (value, option) => {
    const { filter, form } = this.state;
    const isEatProduct = filter.orderType === 'eat';
    const isRetailProduct = filter.orderType === 'shop';

    const checkExistingItems = _.find(form.items, ['product_id', option.product_id]);

    const validRetailStock = option.stock_status === 'instock' || option.stock_quantity > 0;
    if (isRetailProduct && !validRetailStock) {
      this.processMessage('Selected Product Is Out of Stock', 'warning');
      return;
    }

    const isUsingProductVariant = !_.isEmpty(option.variants);
    if (isRetailProduct && isUsingProductVariant) {
      this.setState({
        isOpenModalSelectVariant: true,
        optionList: option,
      });

      return;
    }

    if (!checkExistingItems) {
      option.qty = 1;
      const finalSelected = [option, ...form.items];
      const grandTotal = OrderHelper.getGrandTotal(finalSelected);
      let totalWholesaleDiscount = 0;
      if (!isEatProduct) {
        totalWholesaleDiscount = OrderHelper.getWholesaleDiscount(finalSelected);
      }

      this.setState({
        filter: { ...filter, search: '' },
        form: { ...form, items: finalSelected },
        grandTotal,
        totalWholesaleDiscount,
      });
    } else {
      const existingItem = CommonHelper.objectCloning(form.items);
      const index = existingItem.findIndex(item => item.product_id === option.product_id);
      existingItem[index].qty += 1;
      const grandTotal = OrderHelper.getGrandTotal(existingItem);
      let totalWholesaleDiscount = 0;
      if (!isEatProduct) {
        totalWholesaleDiscount = OrderHelper.getWholesaleDiscount(existingItem);
      }

      this.setState({
        filter: { ...filter, search: '' },
        form: { ...form, items: existingItem },
        grandTotal,
        totalWholesaleDiscount,
      });
    }
  };

  searchProductDebounce = () => {
    const { listOrderProductPagination } = this.props;
    const {
      filter: { search, orderType },
      sort: { sortField, sortOrder },
      pagination,
      limit,
      validation,
    } = this.state;

    if (search) {
      const param = {
        order_type: orderType,
        page: 1,
        search,
        limit: 50,
        sort: sortField,
        sortOrder: sortOrder === 'ascend' || !sortOrder ? 'asc' : 'desc',
      };

      listOrderProductPagination(param)
        .then(response => {
          const data = response.data;
          const message = CommonHelper.objectCloning(initialValidation.listItem);

          if (_.isEmpty(data)) {
            message.errorMessage =
              'Sorry, but there’s no product found matching your search criteria';
          }

          this.setState({
            pagination: { ...pagination, pageSize: limit, total: response.total },
            validation: { ...validation, listItem: message },
          });
        })

        .catch(error => {
          const serverMessage = error.data;
          const validationStatus = error.status === HttpStatusCode.InternalServerError;
          this.processMessage(
            validationStatus ? serverMessage.message : serverMessage.messages,
            'error',
          );
        });
    }
  };

  searchFoodDebounce = () => {
    const { listEatPagination } = this.props;
    const {
      filter: { search },
      validation,
    } = this.state;
    const param = {
      page: 1,
      limit: 10,
      sort: 'product_name',
      sortOrder: 'asc',
      search,
      is_pickup_at_store: true,
      status: 10,
    };

    if (search) {
      this.setState({ loading: true }, () => {
        listEatPagination(param)
          .then(response => {
            const data = response.data;
            const message = CommonHelper.objectCloning(initialValidation.listItem);

            if (_.isEmpty(data)) {
              message.errorMessage =
                'Sorry, but there’s no menu found matching your search criteria';
            }

            this.setState({
              validation: { ...validation, listItem: message },
              loading: false,
            });
          })
          .catch(error => {
            const serverMessage = error.data;
            const validationStatus = error.status === HttpStatusCode.InternalServerError;
            this.processMessage(
              validationStatus ? serverMessage.message : serverMessage.messages,
              'error',
            );
            this.setState({
              loading: false,
            });
          });
      });
    }
  };

  handleButtonNumberStepper = productId => value => {
    const { form, filter } = this.state;
    const isShopProduct = filter.orderType === productTypeOrderOption[0].value;
    const existingItem = CommonHelper.objectCloning(form.items);
    const index = existingItem.findIndex(item => item.product_id === productId);
    const convertedValue = parseInt(value, 10);

    if (convertedValue >= 0) {
      if (convertedValue === 0 || value === '') {
        existingItem.splice(index, 1);
      } else {
        existingItem[index].qty = convertedValue;
      }

      const grandTotal = OrderHelper.getGrandTotal(existingItem);
      let totalWholesaleDiscount = 0;
      if (isShopProduct) {
        totalWholesaleDiscount = OrderHelper.getWholesaleDiscount(existingItem);
      }

      this.setState({
        form: { ...form, items: existingItem },
        grandTotal,
        totalWholesaleDiscount,
      });
    }
  };

  handleButtonNumberStepperEat = (cateringScheduleId, date) => value => {
    const { listItems, form, filter } = this.state;

    const existingItem = CommonHelper.objectCloning(listItems);
    const convertedValue = parseInt(value, 10);
    let modifyItem = CommonHelper.objectCloning(form.items);

    if (convertedValue >= 0) {
      const index = existingItem.findIndex(item => item.date === date);
      const resultItemParent = existingItem[index]; // grouping update to parent

      const indexChile = resultItemParent.data.findIndex(
        item => item.catering_schedule_id === cateringScheduleId,
      );

      const resultItemChile = resultItemParent.data[indexChile]; // grouping update to chile
      resultItemChile.qty = convertedValue;

      const ifNotPackage = filter.orderEatType === productTypeOrderEatsOption[0].value;
      if (ifNotPackage) {
        modifyItem = OrderHelper.generateEatOrderInFullListSelected(existingItem, form.items);
      }

      const totalExtra = OrderHelper.getGrandTotalExtra(modifyItem, form.listExtra);
      const grandTotal = OrderHelper.getGrandTotal(modifyItem, 0, 0, totalExtra);

      this.setState({ listItems: existingItem, grandTotal }); // because not deep clone
    }
  };

  handleButtonAddExtraEat = (cateringScheduleId, date, extras) => {
    const { listItems, form, filter } = this.state;

    const existingItem = CommonHelper.objectCloning(listItems);
    let modifyItem = CommonHelper.objectCloning(form.items);

    const index = existingItem.findIndex(item => item.date === date);
    const resultItemParent = existingItem[index]; // grouping update to parent

    const indexChile = resultItemParent.data.findIndex(
      item => item.catering_schedule_id === cateringScheduleId,
    );

    const resultItemChile = resultItemParent.data[indexChile]; // grouping update to chile
    resultItemChile.extras = extras;

    const ifNotPackage = filter.orderEatType === productTypeOrderEatsOption[0].value;
    if (ifNotPackage) {
      modifyItem = OrderHelper.generateEatOrderInFullListSelected(existingItem, form.items);
    }

    const totalExtra = OrderHelper.getGrandTotalExtra(modifyItem, form.listExtra);
    const grandTotal = OrderHelper.getGrandTotal(modifyItem, 0, 0, totalExtra);

    this.setState({ listItems: existingItem, grandTotal }); // because not deep clone
  };

  handleNotesEat = (cateringScheduleId, date, notes) => {
    const { listItems } = this.state;

    const existingItem = CommonHelper.objectCloning(listItems);

    const index = existingItem.findIndex(item => item.date === date);
    const resultItemParent = existingItem[index]; // grouping update to parent

    const indexChile = resultItemParent.data.findIndex(
      item => item.catering_schedule_id === cateringScheduleId,
    );

    const resultItemChile = resultItemParent.data[indexChile]; // grouping update to chile
    resultItemChile.notes = notes;

    this.setState({ listItems: existingItem }); // because not deep clone
  };

  handleNotesEatPackage = notes => {
    const { form } = this.state;

    this.setState({
      form: { ...form, notes },
    });
  };

  handleNotesEatAtStore = productId => value => {
    const { form } = this.state;
    const existingItem = CommonHelper.objectCloning(form.items);
    const index = existingItem.findIndex(item => item.product_id === productId);
    existingItem[index].notes = value;

    this.setState({
      form: { ...form, items: existingItem },
    });
  };

  handleRadioCheckClick = value => {
    const {
      eatOrderTypeOption,
      filter: { orderEatTypeProductTab },
      form,
    } = this.state;
    const existingItem = CommonHelper.objectCloning(eatOrderTypeOption);
    let grandTotal = 0;
    let selectedPackage = {};

    existingItem[orderEatTypeProductTab].option.packages.forEach(item => {
      if (item.pax === value.pax) {
        item.isChecked = true;
        item.extraSession = [eatSessionTypeCode.lunch];
        grandTotal = item.totalPriceExtraSession;
        selectedPackage = item;
      } else {
        item.isChecked = false;
        item.extraSession = [];
      }
      item.totalPriceExtraSession = item.normal_price * item.pax;
      item.extras = [];
    });

    this.setState({
      eatOrderTypeOption: existingItem,
      isVisibleGlobalExtra: false,
      form: { ...form, globalExtraValue: [], selectedPackage },
      grandTotal,
    });
  };

  handleButtonAddKindOption = (foodListId, pax, value) => {
    const {
      eatOrderTypeOption,
      filter: { orderEatTypeProductTab },
      form,
    } = this.state;
    const existingItem = CommonHelper.objectCloning(eatOrderTypeOption);
    const modifyValue = !_.isEmpty(value) ? value : [eatSessionTypeCode.lunch];
    let modifyExtra = false;
    let modifyExtraGlobal = CommonHelper.objectCloning(form.globalExtraValue);
    const checkHasDefaultValue = OrderHelper.validateEatPackageExtraHasDefault(modifyValue);
    let grandTotal = 0;

    const index = existingItem[orderEatTypeProductTab].option.packages.findIndex(
      item => item.pax === pax,
    );
    const resultItem = existingItem[orderEatTypeProductTab].option.packages[index];
    resultItem.extraSession = checkHasDefaultValue.isExist
      ? modifyValue
      : [...value, eatSessionTypeCode.lunch];
    resultItem.isContainLunchDinner = checkHasDefaultValue.total > 1;

    // Validate new rule price for package
    let modifyPrice = resultItem.normal_price;
    if (resultItem.isContainLunchDinner) {
      modifyPrice = resultItem.lunch_and_dinner_price;
    }
    resultItem.totalPriceExtraSession =
      (checkHasDefaultValue.total || 1) * modifyPrice * resultItem.pax;

    const ifExistExtra = value.filter(item => item === eatSessionTypeCode.extra);

    if (!_.isEmpty(ifExistExtra)) {
      modifyExtra = true;
    } else {
      modifyExtraGlobal = [];
    }

    const totalExtra =
      OrderHelper.getTotalExtra(modifyExtraGlobal, form.listExtra) *
      resultItem.pax *
      (checkHasDefaultValue.total || 1);
    grandTotal = resultItem.totalPriceExtraSession + totalExtra;

    this.setState({
      eatOrderTypeOption: existingItem,
      isVisibleGlobalExtra: modifyExtra,
      grandTotal,
      form: { ...form, selectedPackage: resultItem, globalExtraValue: modifyExtraGlobal },
    });
  };

  handleButtonAddGlobalExtraOption = value => {
    const { form } = this.state;
    const checkHasDefaultValue = OrderHelper.validateEatPackageExtraHasDefault(
      form.selectedPackage.extraSession,
    );

    const totalExtra =
      OrderHelper.getTotalExtra(value, form.listExtra) *
      form.selectedPackage.pax *
      (checkHasDefaultValue.total || 1);

    let modifyPrice = form.selectedPackage.normal_price;
    if (form.selectedPackage.isContainLunchDinner) {
      modifyPrice = form.selectedPackage.lunch_and_dinner_price;
    }

    const grandTotalRecalculate =
      totalExtra + form.selectedPackage.pax * modifyPrice * (checkHasDefaultValue.total || 1);

    this.setState({
      form: { ...form, globalExtraValue: value },
      grandTotal: grandTotalRecalculate,
    });
  };

  handleTableChange = (pagination, filters, sorter) => {
    const { form, sort } = this.state;

    let modifyItem = CommonHelper.objectCloning(form.items);

    const finalValue = sorter.order === 'ascend' ? 'asc' : 'desc';
    modifyItem = CommonHelper.dataArrayOrder(modifyItem, sorter.field, finalValue);

    this.setState({
      form: { ...form, items: modifyItem },
      limit: pagination.pageSize,
      pagination: {
        ...this.state.pagination,
        current: pagination.current,
        pageSize: pagination.pageSize,
      },
      sort: { ...sort, sortField: sorter.field, sortOrder: sorter.order },
      page: pagination.current,
    });
  };

  handleButtonClickCancel = () => {
    const { history } = this.props;
    history.push('/orders');
  };

  handleButtonClickNext = async value => {
    let checkPass = false;
    const {
      filter: { orderType, orderEatType },
    } = this.state;
    let isRetail = null;
    let isEatAtStore = null;

    switch (value - 1) {
      case 0:
        if (orderEatType === productTypeOrderEatsOption[1].value) {
          await this.getOrderEatListByTypeProduct();
        }
        isRetail = orderType === productTypeOrderOption[0].value;
        isEatAtStore = orderEatType === productTypeOrderEatsOption[2].value;
        if (isRetail) {
          checkPass = this.validationStepCartItem();
        } else if (isEatAtStore) {
          checkPass = this.validationEatAtStore();
        } else {
          checkPass = await this.generateSummaryOrderEats();
        }
        break;
      case 1:
        checkPass = this.validationStepUserDetails();
        break;
      default:
        break;
    }

    if (checkPass) {
      this.setState({ current: value });
    }
  };

  handleButtonClickPrev = value => {
    const currentUsers = UserHelper.getCurrentUserInformation();
    const userRoleName = currentUsers && currentUsers.role_name;
    const userMerchants = currentUsers && currentUsers.user_merchants;
    const { selectedStore } = OrderHelper.getAutoFilterByRoleNameAndUserMerchant(
      userRoleName,
      userMerchants,
    );

    if (selectedStore) {
      this.setState({
        storeDetailsSelected: selectedStore,
      });
    }
    this.setState({
      current: value,
    });
  };

  handlePopUpInformation = (message, typeMessage) => {
    this.processMessage(message, typeMessage);
  };

  handleParameterUpdateStepUserDetails = (formValue, ValidationValue) => {
    const { form } = this.state;
    this.setState({
      form: {
        ...form,
        isFirstTimePurchase: formValue.isFirstTimePurchase,
        isExisting: formValue.isExisting,
        userId: formValue.userId,
        phone: formValue.phone,
        firstName: formValue.firstName,
        lastName: formValue.lastName,
        email: formValue.email,
        isReseller: formValue.isReseller,
        isStaffCheckout: formValue.isStaffCheckout,
        gender: formValue.gender,
        addressType: formValue.addressType,
        deliveryService: formValue.deliveryService,
        deliveryServiceInfo: formValue.deliveryServiceInfo,
        addressId: formValue.addressId,
        address: formValue.address,
        placeId: formValue.placeId,
        addressInfo: formValue.addressInfo,
        note: formValue.note,
        isRecipientChange: formValue.isRecipientChange,
        recipientName: formValue.recipientName,
        recipientPhone: formValue.recipientPhone,
        medicalRecordNumber: formValue.medicalRecordNumber,
        userRoomId: formValue.userRoomId,
        isUseAnonymous: formValue.isUseAnonymous,
      },
      validation: ValidationValue,
    });
  };

  handleParameterUpdateStepUserPayment = (formValue, ValidationValue) => {
    const { form } = this.state;
    this.setState({
      form: {
        ...form,
        paymentMethod: formValue.paymentMethod,
        bank: formValue.bank,
        promoCode: formValue.promoCode,
        membershipInfo: formValue.membershipInfo,
        transactionReference: formValue.transactionReference,
        phoneNumberPayment: formValue.phoneNumberPayment,
      },
      validation: ValidationValue,
    });
  };

  handleButtonClickSubmit = isSavedAsDraft => {
    const { processCreateOrder, history } = this.props;
    const {
      form,
      validation: { promoCode },
      filter,
      storeDetailsSelected,
      eligibleToPay,
    } = this.state;
    let userDetails = {};
    let modifyPayment = null;
    let modifyPackageEats = null;
    let atStore;
    let branchId;
    let shippingDetails = null;

    const isAtStore = filter.orderEatType === productTypeOrderEatsOption[2].value;

    const isAddressChange = form.addressType === addressTypeValue[0].value;
    const coordinate = !_.isEmpty(form.addressInfo)
      ? `${form.addressInfo.latitude},${form.addressInfo.longitude}`
      : null;

    const isCheckPassed = this.validationSummaryAndPayment();

    if (!eligibleToPay) {
      return;
    }

    if (promoCode.isError) {
      this.processMessage('Promo Code not Valid, Check your Promo Code', 'warning');
      return;
    }

    if (!isCheckPassed) {
      return;
    }

    const existId = form.addressId !== '' ? form.addressId : null;

    if (form.isExisting) {
      userDetails = {
        user_detail: {
          user_id: form.userId,
          user_address_id: existId,
        },
      };
    } else {
      userDetails = {
        user_detail: {
          user_id: form.userId || null,
          user_address_id: null,
          first_name: form.firstName,
          last_name: form.lastName,
          phone: form.phone,
          email: form.email,
          gender: form.gender.value,
          medical_record_number: form.medicalRecordNumber,
          user_room_id: form.userRoomId || null,
        },
      };
    }

    userDetails.user_detail.notes = !isAtStore && !_.isEmpty(form.note) ? form.note : null;
    userDetails.user_detail.address = isAtStore ? null : form.address;
    userDetails.user_detail.coordinate = coordinate;

    if (form.isRecipientChange || isAddressChange || !form.isExisting) {
      userDetails.user_detail.is_recipient_change = form.isRecipientChange;
      userDetails.user_detail.recipient_name = isAtStore ? null : form.recipientName;
      userDetails.user_detail.recipient_phone = isAtStore ? null : form.recipientPhone;
      userDetails.user_detail.is_address_change = isAddressChange;
      userDetails.user_detail.addressType = isAtStore ? null : form.addressType;
    }

    switch (form.paymentMethod) {
      case paymentMethodOptionCode.bank_transfer:
        modifyPayment = {
          payment_type: form.paymentMethod,
          bank: form.bank.value,
          transaction_reference: form.transactionReference,
        };
        break;
      case paymentMethodOptionCode.membership:
        modifyPayment = {
          payment_type: form.paymentMethod,
          user_point_booster_id: form.membershipInfo.user_point_booster_id,
          transaction_reference: form.transactionReference,
        };
        break;
      case paymentMethodOptionCode.edc:
        let bankName = '7-FITSHOP';
        let accountNumber = '1000104-FITSHOP';

        if (form.orderType === productTypeForOrderCode.eat) {
          bankName = `${storeDetailsSelected.merchant_id}-${storeDetailsSelected.merchant_name}`;
          accountNumber = `${storeDetailsSelected.branch_id}-${storeDetailsSelected.name}`;
        }

        modifyPayment = {
          payment_type: form.paymentMethod,
          bank: bankName,
          account_number: accountNumber,
          name: `${form.firstName}${!_.isEmpty(form.lastName) ? ` ${form.lastName}` : ``}`,
          transaction_reference: form.transactionReference,
        };
        break;
      case paymentMethodOptionCode.credit_card:
        modifyPayment = {
          payment_type: form.paymentMethod,
          payment_channel: 'xendit',
          payment_status: 0,
          transaction_reference: form.transactionReference,
        };
        break;
      case paymentMethodOptionCode.ovo:
      case paymentMethodOptionCode.xendit_invoice:
        modifyPayment = {
          payment_type: form.paymentMethod,
          phone: form.phoneNumberPayment,
          transaction_reference: form.transactionReference,
        };
        break;
      case paymentMethodOptionCode.grabfood:
      case paymentMethodOptionCode.gofood:
      case paymentMethodOptionCode.shopeefood:
      case paymentMethodOptionCode.shopeepay:
        modifyPayment = {
          payment_type: form.paymentMethod,
          transaction_id: form.transactionReference,
          transaction_reference: form.transactionReference,
        };
        break;
      default:
        modifyPayment = {
          payment_type: form.paymentMethod,
          transaction_reference: form.transactionReference,
        };
        break;
    }

    if (!_.isEmpty(form.selectedPackage)) {
      const currentKindPack = form.selectedPackage.extraSession;

      const modifyLunch = !_.isEmpty(
        currentKindPack.filter(item => item === eatSessionTypeCode.lunch),
      );
      const modifyDinner = !_.isEmpty(
        currentKindPack.filter(item => item === eatSessionTypeCode.dinner),
      );

      modifyPackageEats = {
        quantity: 1,
        pax: true,
        days: form.selectedPackage.pax,
        day_filter: filter.daysShow || null,
        purchase_lunch: modifyLunch,
        purchase_dinner: modifyDinner,
        extras: form.globalExtraValue,
        start_date: filter.startDate,
        shipping_cost: form.deliveryServiceInfo.cost[0].value,
        foodlist_id: form.selectedPackage.foodlist_id,
        details: {
          notes: form.notes,
        },
      };
    }

    if (isAtStore) {
      atStore = true;
      branchId = storeDetailsSelected.branch_id;
    } else {
      atStore = false;
      branchId = null;
      shippingDetails = {
        shipping_method: form.deliveryServiceInfo.courier,
        shipping_title: form.deliveryServiceInfo.description,
        shipping_cost:
          modifyPayment.payment_type === paymentMethodOptionCode.membership
            ? 0
            : form.deliveryServiceInfo.cost[0].value,
      };
    }

    const param = {
      data: {
        order_type: form.orderType,
        save_draft: isSavedAsDraft,
        user_details: userDetails.user_detail,
        items: _.isEmpty(form.selectedPackage)
          ? OrderHelper.formatItemSubmit(form.items, {}, isAtStore)
          : null,
        package: modifyPackageEats,
        shipping_details: shippingDetails,
        payment_data: modifyPayment,
        promo_code: form.promoCode,
        at_store: atStore,
        branch_id: branchId,
      },
    };

    processCreateOrder(param)
      .then(async response => {
        const message = response.messages;
        await this.processMessage(message, 'success');
        await setTimeout(async () => {
          await history.push('/orders');
        }, 2000);
      })
      .catch(error => {
        const serverMessage = error.data;
        const validationStatus = error.status === HttpStatusCode.InternalServerError;
        this.processMessage(
          validationStatus ? serverMessage.message : serverMessage.messages,
          'error',
        );
      });
  };

  handleCallbackMessages = (message, type, eligibleToPay) => {
    this.setState({ eligibleToPay });
    if (!eligibleToPay) {
      this.processMessage(message, type);
    }
  };

  handleCloseToast = () => {
    const { toastInformation } = this.state;
    this.setState({ toastInformation: { ...toastInformation, isOpen: false } });
  };

  handleSetAnonymous = value => {
    this.setState({ useAnonymous: value });
  };

  handleButtonAddFavoriteProducts = option => {
    const { filter, form } = this.state;

    const isEatProduct = filter.orderType === productTypeOrderOption[1].value;
    const checkExistingItems = _.find(form.items, ['product_id', option.product_id]);

    if (!checkExistingItems) {
      option.qty = 1;
      const finalSelected = [option, ...form.items];
      const grandTotal = OrderHelper.getGrandTotal(finalSelected);
      let totalWholesaleDiscount = 0;
      if (!isEatProduct) {
        totalWholesaleDiscount = OrderHelper.getWholesaleDiscount(finalSelected);
      }

      this.setState({
        filter: { ...filter, search: '' },
        form: { ...form, items: finalSelected },
        grandTotal,
        totalWholesaleDiscount,
      });
    } else {
      const existingItem = CommonHelper.objectCloning(form.items);
      const index = existingItem.findIndex(item => item.product_id === option.product_id);
      existingItem[index].qty += 1;
      const grandTotal = OrderHelper.getGrandTotal(existingItem);
      let totalWholesaleDiscount = 0;
      if (!isEatProduct) {
        totalWholesaleDiscount = OrderHelper.getWholesaleDiscount(existingItem);
      }

      this.setState({
        filter: { ...filter, search: '' },
        form: { ...form, items: existingItem },
        grandTotal,
        totalWholesaleDiscount,
      });
    }
  };

  handleAddToCart = obj => {
    const { filter, form } = this.state;
    const isEatProduct = filter.orderType === productTypeOrderOption[1].value;
    const checkExistingItems = _.find(form.items, ['product_id', obj.product_id]);

    if (!checkExistingItems) {
      const finalSelected = [obj, ...form.items];
      const grandTotal = OrderHelper.getGrandTotal(finalSelected);
      let totalWholesaleDiscount = 0;

      if (!isEatProduct) {
        totalWholesaleDiscount = OrderHelper.getWholesaleDiscount(finalSelected);
      }

      this.setState({
        filter: { ...filter, search: '' },
        form: { ...form, items: finalSelected },
        grandTotal,
        totalWholesaleDiscount,
        isOpenModalSelectVariant: false,
      });
    } else {
      const existingItem = CommonHelper.objectCloning(form.items);
      const index = existingItem.findIndex(item => item.product_id === obj.product_id);
      existingItem[index].qty += obj.qty;
      const grandTotal = OrderHelper.getGrandTotal(existingItem);
      let totalWholesaleDiscount = 0;

      if (!isEatProduct) {
        totalWholesaleDiscount = OrderHelper.getWholesaleDiscount(existingItem);
      }

      this.setState({
        filter: { ...filter, search: '' },
        form: { ...form, items: existingItem },
        grandTotal,
        totalWholesaleDiscount,
        isOpenModalSelectVariant: false,
      });
    }
  };

  generateWholesaleDiscountPopoverTitle = () => {
    const title = <div className="p-8 text-semi-bold">Wholesale Discount</div>;
    return title;
  };

  generateWholesaleDiscountPopoverContent = product => {
    const content = (
      <div>
        <Table
          columns={this.renderWholesaleDiscount()}
          rowKey={(record, index) => index}
          dataSource={product.wholesale_discount}
          pagination={false}
        />
      </div>
    );

    return content;
  };

  renderWholesaleDiscount = () => {
    return [
      {
        title: 'Min. Quantity',
        dataIndex: 'minimum_quantity',
        key: 'minimum_quantity',
      },
      {
        title: 'Discount',
        dataIndex: 'price_discount',
        key: 'price_discount',
        render: text => <span>{text}%</span>,
      },
    ];
  };

  handleCloseModalSummary = () => {
    this.setState({
      isOpenModalEatSummary: false,
    });
  };

  handleCloseModalVariant = () => {
    this.setState({
      isOpenModalSelectVariant: false,
    });
  };

  validationStepCartItem() {
    const {
      form: { items },
      isResellerLogin,
    } = this.state;

    let passCheck = true;

    if (_.isEmpty(items)) {
      this.processMessage(
        'Sorry, please add some items to your cart in order to continue.',
        'warning',
      );
      passCheck = false;
    }

    if (!isResellerLogin) {
      return passCheck;
    }

    const minimumItems = 6;
    let cartItem = 0;
    items.forEach(item => {
      cartItem += item.qty;
    });

    if (cartItem < minimumItems) {
      this.processMessage(
        `Sorry, there's a minimum of ${minimumItems} boxes in total in order to continue.`,
        'warning',
      );
      passCheck = false;
    }

    return passCheck;
  }

  validationEatAtStore() {
    const {
      form: { items },
    } = this.state;

    let passCheck = true;

    if (_.isEmpty(items)) {
      this.processMessage(
        'Sorry, please add some items to your cart in order to continue.',
        'warning',
      );
      passCheck = false;
    }

    return passCheck;
  }

  validationStepUserDetails() {
    const {
      form: {
        phone,
        firstName,
        email,
        address,
        isRecipientChange,
        recipientName,
        recipientPhone,
        addressType,
        note,
        deliveryService,
        medicalRecordNumber,
      },
      validation,
      storeDetailsSelected,
      filter: { orderType, orderEatType },
    } = this.state;

    const messageV1 = { isError: false, errorMessage: '' };
    const messageV2 = { isError: '', errorMessage: '' };

    let passCheck = false;
    let groupCheck = true;
    const paramValidation01 = CommonHelper.objectCloning(messageV1);
    const paramValidation02 = CommonHelper.objectCloning(messageV1);
    const paramValidation03 = CommonHelper.objectCloning(messageV1);
    // shipping
    let paramValidation04 = CommonHelper.objectCloning(messageV1);
    let paramValidation05 = CommonHelper.objectCloning(messageV2);
    const paramValidation06 = CommonHelper.objectCloning(messageV1);
    const paramValidation07 = CommonHelper.objectCloning(messageV1);
    const paramValidation08 = CommonHelper.objectCloning(messageV2);
    const paramValidation09 = CommonHelper.objectCloning(messageV2);

    const isEatAtStoreOrder =
      orderType === productTypeOrderOption[1].value &&
      orderEatType === productTypeOrderEatsOption[2].value;

    const isSpecialStoreSelected =
      storeDetailsSelected &&
      storeDetailsSelected.identification.includes('fitco-eats-catering-rspad');

    if (isSpecialStoreSelected) {
      paramValidation01.isError = !!(
        !medicalRecordNumber || validation.medicalRecordNumber.isError
      );
      paramValidation01.errorMessage =
        !medicalRecordNumber || validation.medicalRecordNumber.isError
          ? 'Please enter Medical Record Number'
          : '';
    } else {
      paramValidation01.isError = !!(!phone || validation.phone.isError);
      paramValidation01.errorMessage =
        !phone || validation.phone.isError ? 'Please enter Phone' : '';
    }

    paramValidation02.isError = !firstName;
    paramValidation02.errorMessage = !firstName ? 'Please enter First Name' : '';

    if (!isEatAtStoreOrder && !validation.email.isError) {
      paramValidation03.isError = !email;
      paramValidation03.errorMessage = !email ? 'Please enter Email' : '';
    } else {
      paramValidation03.isError = validation.email.isError;
      paramValidation03.errorMessage = validation.email.errorMessage;
    }

    if (!isEatAtStoreOrder && _.isEmpty(address)) {
      if (addressType === addressTypeValue[0].value) {
        paramValidation04 = {
          isError: true,
          errorMessage: 'Please enter Address',
        };
        paramValidation05 = {
          isError: '',
          errorMessage: '',
        };
      } else {
        paramValidation04 = {
          isError: false,
          errorMessage: '',
        };
        paramValidation05 = {
          isError: 'error',
          errorMessage: 'Please enter Address',
        };
      }

      groupCheck = false;
    }

    paramValidation08.isError = !isEatAtStoreOrder && !note ? 'error' : '';
    paramValidation08.errorMessage = !isEatAtStoreOrder && !note ? 'Please enter note' : '';

    if (isRecipientChange) {
      paramValidation06.isError = !recipientName;
      paramValidation06.errorMessage = !recipientName ? 'Please enter New Recipient Name' : '';

      paramValidation07.isError = !!(!recipientPhone || validation.recipientPhone.isError);
      paramValidation07.errorMessage =
        !recipientPhone || validation.recipientPhone.isError
          ? 'Please enter New Recipient Name'
          : '';
    }

    paramValidation09.isError =
      !deliveryService || validation.deliveryService.isError ? 'error' : '';
    paramValidation09.errorMessage =
      !deliveryService || validation.deliveryService.isError ? 'Area not Covered' : '';

    if (
      !paramValidation01.isError &&
      !paramValidation02.isError &&
      !paramValidation03.isError &&
      !paramValidation06.isError &&
      !paramValidation07.isError &&
      !paramValidation08.isError &&
      !paramValidation09.isError &&
      groupCheck
    ) {
      passCheck = true;
    }

    this.setState({
      validation: {
        ...validation,
        phone: paramValidation01,
        medicalRecordNumber: paramValidation01,
        firstName: paramValidation02,
        email: paramValidation03,
        address: paramValidation04,
        addressArea: paramValidation05,
        recipientName: paramValidation06,
        recipientPhone: paramValidation07,
        note: paramValidation08,
        deliveryService: paramValidation09,
      },
    });

    return passCheck;
  }

  validationSummaryAndPayment() {
    const {
      form: { paymentMethod, transactionReference, phoneNumberPayment },
      validation,
    } = this.state;
    let passCheck = false;
    const message = { isError: false, errorMessage: '' };
    const paramValidation = CommonHelper.objectCloning(message);

    if (
      paymentMethod === paymentMethodOptionCode.grabfood ||
      paymentMethod === paymentMethodOptionCode.gofood ||
      paymentMethod === paymentMethodOptionCode.shopeefood ||
      paymentMethod === paymentMethodOptionCode.shopeepay
    ) {
      paramValidation.isError = !!(
        !transactionReference || validation.transactionReference.isError
      );
      paramValidation.errorMessage =
        !transactionReference || validation.transactionReference.isError
          ? 'Please Enter Transaction Reference'
          : '';
    } else if (paymentMethod === paymentMethodOptionCode.ovo) {
      paramValidation.isError = !!(!phoneNumberPayment || validation.phoneNumberPayment.isError);
      paramValidation.errorMessage =
        !phoneNumberPayment || validation.phoneNumberPayment.isError
          ? 'Please Enter Phone Number For Payment'
          : '';
    }

    if (!paramValidation.isError) {
      passCheck = true;
    }

    this.setState({
      validation: {
        ...validation,
        transactionReference: paramValidation,
        phoneNumberPayment: paramValidation,
      },
    });

    return passCheck;
  }

  generateSummaryOrderEats() {
    const { isOpenModalEatSummary, form, listItems } = this.state;
    let resultValidate = false;
    let modifyIsOpenModal = isOpenModalEatSummary;
    let modifyItem = [];

    if (!isOpenModalEatSummary) {
      modifyItem = OrderHelper.generateEatOrderInFullListSelected(listItems);
      modifyIsOpenModal = true;
    } else {
      modifyIsOpenModal = false;
      modifyItem = form.items;
      resultValidate = this.validationStepCartItem();
    }

    this.setState({
      isOpenModalEatSummary: modifyIsOpenModal,
      form: { ...form, items: modifyItem },
    });

    return resultValidate;
  }

  processMessage(messages, type) {
    const convertedMessage = CommonHelper.generateMessage(messages);

    const paramInformation = {
      isOpen: true,
      message: convertedMessage,
      snackbarType: type,
    };

    this.setState({
      toastInformation: paramInformation,
    });
  }

  renderModalSummaryOrderEats() {
    const { isOpenModalEatSummary, form, isLoading } = this.state;

    return (
      <ModalSummaryEatOrders
        isOpen={isOpenModalEatSummary}
        form={form}
        isLoading={isLoading}
        onCloseModal={this.handleCloseModalSummary}
        onNext={() => this.handleButtonClickNext(1)}
      />
    );
  }

  renderModalSelectVariant() {
    const { isOpenModalSelectVariant, optionList } = this.state;

    return (
      <ModalSelectVariant
        isOpen={isOpenModalSelectVariant}
        data={optionList}
        onCloseModal={this.handleCloseModalVariant}
        onHandleAddToCart={this.handleAddToCart}
      />
    );
  }

  renderColumns = () => {
    const { filter } = this.state;
    const isShopProduct = filter.orderType === productTypeOrderOption[0].value;
    const isEatProduct = filter.orderType === productTypeOrderOption[1].value;
    const columns = [
      {
        title: 'Product',
        dataIndex: 'name',
        sorter: true,
        render: (text, row) => {
          return (
            <span className="container-item-cart flex-row">
              <img
                src={row.images || row.product_images[0].image_url}
                className="custom-image mr-16"
                alt="item_image"
              />
              <div className="flex-column full-width">
                <label className="text-14">
                  {row.name || row.product_name}
                  {row.wholesale_discount && row.wholesale_discount.length > 0 && (
                    <FitcoPopover
                      content={this.generateWholesaleDiscountPopoverContent(row)}
                      placement={FitcoPopoverPlacement.Right}
                      title={this.generateWholesaleDiscountPopoverTitle()}
                      trigger={FitcoPopoverTrigger.Hover}
                    />
                  )}
                </label>
                <label className="text-10 pt-2 item-info">Product ID: {row.product_id}</label>
                {isEatProduct && (
                  <div>
                    <FormControl component="fieldset" fullWidth margin="dense">
                      <TextAreaMain
                        onChange={this.handleNotesEatAtStore(row.product_id)}
                        placeholder="Add Notes"
                        rows={2}
                        maxLength={50}
                      />
                    </FormControl>
                  </div>
                )}
              </div>
            </span>
          );
        },
      },
      {
        title: 'Price',
        width: '20%',
        align: 'right',
        render: (text, row) => {
          const amount = CommonHelper.formatCurrency(row.price);

          return (
            <div className="container-item-cart">
              <label className="text-14">{`IDR ${amount}`}</label>
            </div>
          );
        },
      },
      {
        title: 'Quantity',
        width: '10%',
        align: 'center',
        render: (text, row) => {
          return (
            <TextInputNumber
              placeHolderText={'Qty'}
              currentValue={row.qty}
              onChange={this.handleButtonNumberStepper(row.product_id)}
            />
          );
        },
      },
    ];

    if (isShopProduct) {
      columns.push({
        title: 'Discount',
        width: '15%',
        align: 'center',
        render: (text, row) => {
          let discount = 0;
          let discountAmount = 0;
          row.wholesale_discount.forEach(record => {
            if (row.qty >= record.minimum_quantity) {
              discount = record.price_discount;
              discountAmount =
                (parseInt(row.price, 10) * row.qty * parseFloat(record.price_discount)) / 100;
              discountAmount = Math.floor(discountAmount);
              discountAmount = CommonHelper.formatCurrency(discountAmount);
            }
          });

          return discount > 0 ? (
            <span className="container-item-cart flex-row">
              <div className="flex-column" style={{ width: '100%' }}>
                <label className="text-14 text-discount text-center">
                  {`IDR ${discountAmount}`}
                  <sup>*</sup>
                </label>
                <label className="text-10 pt-2 item-info text-center">{`(${discount}%) `}</label>
              </div>
            </span>
          ) : (
            <div className="container-item-cart">
              <label className="text-14">-</label>
            </div>
          );
        },
      });
    }

    if (isShopProduct) {
      columns.push({
        title: 'Subtotal',
        width: '20%',
        align: 'center',
        render: (text, row) => {
          let discountAmount = 0;
          row.wholesale_discount.forEach(record => {
            if (row.qty >= record.minimum_quantity) {
              discountAmount = Math.floor(
                (parseInt(row.price, 10) * row.qty * parseFloat(record.price_discount)) / 100,
              );
            }
          });

          const totalAmount = parseInt(row.price, 10) * row.qty;
          const finalTotalAmount = CommonHelper.formatCurrency(totalAmount - discountAmount);

          return (
            <div className="container-item-cart price">
              <label className="text-14">{`IDR ${finalTotalAmount}`}</label>
            </div>
          );
        },
      });
    }

    if (isEatProduct) {
      columns.push({
        title: 'Subtotal',
        width: '20%',
        align: 'center',
        render: (text, row) => {
          const totalAmount = parseInt(row.price, 10) * row.qty;

          return (
            <div className="container-item-cart price">
              <label className="text-14">{`IDR ${CommonHelper.formatCurrency(totalAmount)}`}</label>
            </div>
          );
        },
      });
    }

    return columns;
  };

  renderFilter() {
    const {
      filter: { search, startDate, endDate, daysShow },
    } = this.state;

    return (
      <Grid container justify="space-between" className="container-filter">
        <Grid item lg={5} md={4} sm={12} className="">
          <TextInput
            iconPrefix="ic-ffo-search"
            placeHolderText="Search Menu"
            onChange={this.handleChangeSearch}
            currentValue={search}
            size="md"
          />
        </Grid>
        <Grid item lg={7} md={8} sm={12} className="second-row-filter">
          <Grid container justify="flex-end" alignItems="center">
            <Grid item>
              <label className="text-14 pr-8">Date</label>
            </Grid>
            <Grid item lg={3} md={3} sm={3}>
              <PickerInputDate
                customIcon="ic-ffo-date-pick"
                dateFormat="dd/MM/yyyy"
                minDate={startDateValue}
                maxDate={endDate}
                defaultValue={startDate}
                onChange={this.handleChangeStartDate}
                toolbar={false}
              />
            </Grid>
            <Grid item>
              <label className="text-14 pr-8 pl-8"> - </label>
            </Grid>
            <Grid item lg={3} md={3} sm={3}>
              <PickerInputDate
                customIcon="ic-ffo-date-pick"
                dateFormat="dd/MM/yyyy"
                minDate={startDate}
                maxDate={maxEntryDateEats}
                defaultValue={endDate}
                onChange={this.handleChangeEndDate}
                toolbar={false}
              />
            </Grid>
            <Grid item lg={4} md={4} sm={4} className="pl-8">
              <SelectSearchMultiple
                options={filterDaysOption}
                onChange={items => {
                  this.handleChangeFilterByDays(items);
                }}
                currentValue={daysShow}
                placeholder={'Select Days'}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  renderListItem() {
    const {
      orderData: { fetching },
      orderData,
      eatData,
    } = this.props;
    const {
      validation,
      pagination,
      form: { items, globalExtraValue, listExtra },
      filter: { orderType, orderEatType, orderEatTypeProductTab },
      listItems,
      eatOrderTypeOption,
      isVisibleGlobalExtra,
      loading,
    } = this.state;
    const ListCustom = orderData.listItem;
    const ListMenu = eatData.list;
    const ListSelectedItem = items;

    let renderElement = (
      <React.Fragment>
        <Grid container direction="column" className="container-table-header mt-24">
          <Grid item lg={5} md={5}>
            <TextInputAutocomplete
              placeHolderText="Search product name or product SKU"
              size="md"
              onChange={this.handleChangeTextProduct}
              onSelect={this.handleSelectedItem}
              currentValue={this.state.filter.search}
              isLoading={fetching}
              data={ListCustom}
              isCustomDisplay
              customDisplayClass="custom-simple-option-item"
              errorMessage={validation.listItem.errorMessage}
              isError={validation.listItem.isError}
            />
          </Grid>
          <Table
            columns={this.renderColumns()}
            rowKey={record => record.product_id}
            dataSource={ListSelectedItem}
            pagination={pagination}
            onChange={this.handleTableChange}
          />
        </Grid>
      </React.Fragment>
    );

    const isEatCateringOrder =
      orderType === productTypeOrderOption[1].value &&
      orderEatType !== productTypeOrderEatsOption[2].value;
    const isEatAtStoreOrder =
      orderType === productTypeOrderOption[1].value &&
      orderEatType === productTypeOrderEatsOption[2].value;

    if (isEatCateringOrder) {
      const paginationGridOption = {
        gutter: 16,
        column: 2,
      };

      const itemLayout = 'vertical';
      const isAlaCarteCatering = orderEatType === productTypeOrderEatsOption[0].value;
      const isPackageCatering = orderEatType === productTypeOrderEatsOption[1].value;
      const listPackageByType = !_.isEmpty(eatOrderTypeOption)
        ? eatOrderTypeOption[orderEatTypeProductTab].option.packages
        : [];

      const existingItemExtraOption = CommonHelper.objectCloning(listExtra);

      existingItemExtraOption.forEach(itemExtra => {
        itemExtra.value = itemExtra.product_id;
        itemExtra.isVisible = false;
      });

      renderElement = (
        <div className="flex-column">
          {isAlaCarteCatering && (
            <React.Fragment>
              <div className="pt-24">{this.renderFilter()}</div>
              <PaginationList
                data={listItems}
                itemLayout={itemLayout}
                pagination={pagination}
                loading={fetching}
                renderItem={item => {
                  return (
                    <List.Item>
                      <ItemOrderEat
                        data={item}
                        dataExtra={listExtra}
                        onButtonNumberStepper={this.handleButtonNumberStepperEat}
                        onButtonAddExtra={this.handleButtonAddExtraEat}
                        onHandleNotesField={this.handleNotesEat}
                      />
                    </List.Item>
                  );
                }}
              />
            </React.Fragment>
          )}
          {isPackageCatering && !_.isEmpty(listPackageByType) && (
            <div className="pv-24">
              <PaginationList
                data={listPackageByType}
                grid={paginationGridOption}
                pagination={pagination}
                loading={fetching}
                renderItem={item => {
                  return (
                    <List.Item>
                      <ItemOrderEatCard
                        data={item}
                        onRadioCheckClick={this.handleRadioCheckClick}
                        onButtonAddKindOption={this.handleButtonAddKindOption}
                        onHandleNotesField={this.handleNotesEatPackage}
                      />
                    </List.Item>
                  );
                }}
              />
              <Fade in={isVisibleGlobalExtra} timeout={timeInterval}>
                <div className="mt-24">
                  <CheckInputButton
                    title="Extra :"
                    data={globalExtraValue}
                    dataOption={existingItemExtraOption}
                    onButtonClickAdd={this.handleButtonAddGlobalExtraOption}
                    disabled={fetching && !isAlaCarteCatering}
                  />
                </div>
              </Fade>
            </div>
          )}
          {isPackageCatering && _.isEmpty(listPackageByType) && (
            <div className="mt-24 card-container text-center">
              Sorry, but catering package is not available for the selected foodlist.
            </div>
          )}
        </div>
      );
    }

    if (isEatAtStoreOrder) {
      renderElement = (
        <React.Fragment>
          <Grid container direction="column" className="container-table-header mt-24">
            <Grid item lg={5} md={5}>
              <TextInputAutocomplete
                placeHolderText="Search Product"
                size="md"
                onChange={this.handleChangeTextProduct}
                onSelect={this.handleSelectedItem}
                currentValue={this.state.filter.search}
                isLoading={loading}
                data={ListMenu}
                isCustomDisplay
                customDisplayClass="custom-simple-option-item-menu"
                errorMessage={validation.listItem.errorMessage}
                isError={validation.listItem.isError}
              />
            </Grid>
            <Table
              columns={this.renderColumns()}
              rowKey={record => record.product_id}
              dataSource={ListSelectedItem}
              pagination={pagination}
              onChange={this.handleTableChange}
            />
          </Grid>
        </React.Fragment>
      );
    }

    return renderElement;
  }

  renderActiveContent(visibleSelection = false) {
    const {
      filter,
      filter: { daysShow, storeSelected },
      stepList,
      current,
      form,
      validation,
      eatOrderTypeOption,
      isResellerLogin,
      storeOption,
      storeDetailsSelected,
      useAnonymous,
    } = this.state;
    const { history } = this.props;
    let renderElement = null;

    const ifPackages = filter.orderEatType === productTypeOrderEatsOption[1].value;
    const isAtStore = filter.orderEatType === productTypeOrderEatsOption[2].value;
    const isVisibleTabOption =
      filter.orderType === productTypeForOrderCode.eat && !isAtStore && current === 0;
    const isVisibleMargin = filter.orderType === productTypeForOrderCode.eat && current > 0;

    switch (stepList[current].key) {
      case stepList[1].key:
        renderElement = (
          <Grid
            item
            lg
            md
            className={`${clsx({
              'mb-32': isVisibleMargin,
            })}`}
          >
            <StepUserDetails
              history={history}
              parentForm={form}
              parentFilter={filter}
              storeDetailsSelected={storeDetailsSelected}
              parentValidation={validation}
              currentValue={current}
              totalStepper={3}
              useAnonymous={useAnonymous}
              onButtonClickCancel={this.handleButtonClickCancel}
              onButtonClickNext={this.handleButtonClickNext}
              onButtonClickPrev={this.handleButtonClickPrev}
              onParameterUpdate={this.handleParameterUpdateStepUserDetails}
              onPopUpInformation={this.handlePopUpInformation}
              onSetAnonymous={this.handleSetAnonymous}
            />
          </Grid>
        );
        break;

      case stepList[2].key:
        renderElement = (
          <Grid
            item
            lg
            md
            className={`${clsx({
              'mb-32': isVisibleMargin,
            })}`}
          >
            <StepSummaryAndPayment
              history={history}
              parentForm={form}
              parentValidation={validation}
              storeDetailsSelected={storeDetailsSelected}
              currentValue={current}
              totalStepper={3}
              onButtonClickCancel={this.handleButtonClickCancel}
              onButtonClickSubmit={this.handleButtonClickSubmit}
              onButtonClickPrev={this.handleButtonClickPrev}
              onParameterUpdate={this.handleParameterUpdateStepUserPayment}
              onPopUpInformation={this.handlePopUpInformation}
              onHandleMessages={this.handleCallbackMessages}
            />
          </Grid>
        );
        break;

      default:
        renderElement = !isResellerLogin ? (
          <Grid
            item
            lg
            md
            className={`mt-24 ${clsx({
              'mb-32': isVisibleMargin,
            })}`}
          >
            <div className="flex-column">
              <Grid container justify="flex-start" spacing={2}>
                <Grid item lg={3} md={3} sm={3}>
                  <FormControl component="fieldset" fullWidth margin="normal">
                    <FormLabel component="label" className="text-12 title mb-8">
                      Order Type
                    </FormLabel>
                    <SelectInputMain
                      options={productTypeOrderOption}
                      currentValue={filter.orderType}
                      onChange={this.handleSelectChangeOrderType}
                    />
                  </FormControl>
                </Grid>
                {visibleSelection && (
                  <Grid item lg={3} md={3} sm={3}>
                    <FormControl component="fieldset" fullWidth margin="normal">
                      <FormLabel component="label" className="text-12 title mb-8">
                        Eat Type
                      </FormLabel>
                      <SelectInputMain
                        options={productTypeOrderEatsOption}
                        currentValue={filter.orderEatType}
                        onChange={this.handleSelectChangeOrderEatType}
                      />
                    </FormControl>
                  </Grid>
                )}
                {isAtStore && (
                  <Grid item lg={4} md={6} sm={6}>
                    <FormControl component="fieldset" fullWidth margin="normal">
                      <FormLabel component="label" className="text-12 title mb-8">
                        Store Location
                      </FormLabel>
                      <SelectInputMain
                        options={storeOption}
                        currentValue={storeSelected}
                        onChange={this.handleSelectChangeStore}
                      />
                    </FormControl>
                  </Grid>
                )}
                {ifPackages && (
                  <Grid item lg={3} md={3} sm={3}>
                    <FormControl component="fieldset" fullWidth margin="normal">
                      <FormLabel component="label" className="text-12 title mb-8">
                        Date
                      </FormLabel>
                      <PickerInputDate
                        customIcon="ic-ffo-date-pick"
                        dateFormat="dd/MM/yyyy"
                        minDate={startDateValue}
                        defaultValue={filter.startDate}
                        onChange={this.handleChangeStartDate}
                        toolbar={false}
                      />
                    </FormControl>
                  </Grid>
                )}
                {ifPackages && (
                  <Grid item lg={3} md={3} sm={3}>
                    <FormControl component="fieldset" fullWidth margin="normal">
                      <FormLabel component="label" className="text-12 title mb-8">
                        Days
                      </FormLabel>
                      <SelectSearchMultiple
                        options={filterDaysOption}
                        onChange={items => {
                          this.handleChangeFilterByDays(items);
                        }}
                        currentValue={daysShow}
                        placeholder={'Select Days'}
                      />
                    </FormControl>
                  </Grid>
                )}
              </Grid>
              {isVisibleTabOption && (
                <TabNavigation
                  data={eatOrderTypeOption}
                  onChange={this.handleTabChangeTypeProduct}
                  currentTab={filter.orderEatTypeProductTab}
                />
              )}
            </div>
          </Grid>
        ) : null;
        break;
    }

    return renderElement;
  }

  renderFavoriteProducts() {
    const {
      orderData: { favoriteMenus },
    } = this.props;
    const settings = {
      infinite: true,
      slidesToShow: 4,
      responsive: [
        {
          breakpoint: 320,
          settings: { slidesToShow: 1, slidesToScroll: 1, infinite: true },
        },
        {
          breakpoint: 768,
          settings: { slidesToShow: 2, slidesToScroll: 1, infinite: true },
        },
        {
          breakpoint: 1024,
          settings: { slidesToShow: 3, slidesToScroll: 1, infinite: true },
        },
      ],
    };
    let renderElement = null;
    if (!_.isEmpty(favoriteMenus)) {
      renderElement = (
        <div className="slider-section">
          <div className="mb-10">
            <label className="title text-16">Favorite Products</label>
          </div>
          <Slider {...settings}>
            {favoriteMenus.map((item, index) => {
              return (
                <Grid item lg md sm className="container-product" key={index}>
                  <div className="container-product-item content-direction">
                    <img
                      src={item.product_images[0].image_url}
                      className="custom-images"
                      alt="item_image"
                    />
                    <div className="flex-column full-width">
                      <div className="flex-column description">
                        <label className="text-title">{item.name}</label>
                        <label className="text-info pt-2">Product ID - {item.product_id}</label>
                      </div>
                      <div className="flex-column">
                        <label className="text-price pb-4">{`IDR ${CommonHelper.formatCurrency(
                          item.price,
                        )}`}</label>
                        <ButtonMain
                          type="primary"
                          size="sm"
                          labelText="Add"
                          onClick={() => {
                            this.handleButtonAddFavoriteProducts(item);
                          }}
                          startIcon="ic-ffo-add"
                        />
                      </div>
                    </div>
                  </div>
                </Grid>
              );
            })}
          </Slider>
        </div>
      );
    }
    return renderElement;
  }

  render() {
    const {
      current,
      stepList,
      grandTotal,
      toastInformation,
      filter,
      totalWholesaleDiscount,
      isOpenModalEatSummary,
      isOpenModalSelectVariant,
    } = this.state;

    const {
      orderData: { fetching },
    } = this.props;

    const isStepFirst = stepList[0].key === stepList[current].key;
    const isLoadingEatPackage = filter.orderEatType === productTypeOrderEatsOption[1].value;
    const visibleSelectionEats = filter.orderType === productTypeForOrderCode.eat;
    const isAtStore = filter.orderEatType === productTypeOrderEatsOption[2].value;

    return (
      <div>
        <Helmet title="FITCO | Orders - Create Order" />
        <div className="container-page-order-create scroll-container-invisible">
          <div className={`container-page-scrolling-area ${isStepFirst ? 'include-footer' : ''}`}>
            <Grid container direction="column" className="flex-wrap-unset">
              <Grid item lg md className="section-page-header">
                <div className="breadcrumbs-section">
                  <Breadcrumbs aria-label="breadcrumb">
                    <Link
                      className="text-12"
                      color="inherit"
                      href="/orders"
                      onClick={event => {
                        this.handleClick(event, '/orders');
                      }}
                    >
                      <i className="ic-ffo-reports container-icon-prefix size-16 pr-4" /> Order
                    </Link>
                    <label className="text-12" color="inherit">
                      Create Order
                    </label>
                  </Breadcrumbs>
                </div>
              </Grid>
              <Grid item lg md className="section-page-body">
                <Grid container direction="column" className="flex-wrap-unset">
                  <Grid item lg md>
                    <Grid
                      container
                      direction="column"
                      className={`main-card stepper flex-wrap-unset ${clsx({
                        'pb-unset': visibleSelectionEats && !isAtStore,
                      })}`}
                    >
                      <Grid item lg md>
                        <NavigationStep currentValue={current} arrayLabel={stepList} />
                      </Grid>
                      {this.renderActiveContent(visibleSelectionEats)}
                    </Grid>
                  </Grid>
                  {isStepFirst && visibleSelectionEats && isAtStore && (
                    <Grid item lg md sm className="mt-24 mh-24">
                      {this.renderFavoriteProducts()}
                    </Grid>
                  )}
                  {isStepFirst ? (
                    <Grid item lg md>
                      {this.renderListItem()}
                    </Grid>
                  ) : null}
                </Grid>
              </Grid>
              <Grid item lg md className={`section-page-footer ${!isStepFirst ? 'active' : ''}`} />
            </Grid>
          </div>
        </div>
        {isStepFirst ? (
          <div className="container-page-order-create-container-floating">
            <ButtonActionFooter
              customElementProps={
                <div className="container-grant-total ml-53">
                  <div className="flex-column">
                    <label className="text-14 title">Grand Total</label>
                    <label className="text-21">
                      IDR {CommonHelper.formatCurrency(grandTotal - totalWholesaleDiscount)}
                      {totalWholesaleDiscount > 0 ? <sup>*</sup> : null}
                    </label>
                  </div>
                </div>
              }
              currentValue={current}
              totalStepper={3}
              loadingButtonNext={fetching && isLoadingEatPackage}
              onButtonClickCancel={this.handleButtonClickCancel}
              onButtonClickNext={this.handleButtonClickNext}
              customNote={
                totalWholesaleDiscount > 0
                  ? 'Grand total is calculated based on possible wholesale discount if any and it will only apply to reseller.'
                  : null
              }
            />
          </div>
        ) : null}
        {isOpenModalEatSummary && this.renderModalSummaryOrderEats()}
        {isOpenModalSelectVariant && this.renderModalSelectVariant()}
        <SnackBarSimple
          open={toastInformation.isOpen}
          durationHide={2000}
          message={toastInformation.message}
          onClickClose={this.handleCloseToast}
          snackbarType={toastInformation.snackbarType}
          anchor={optionToast}
        />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  listOrderProductPagination: params => getOrderProductPagination(dispatch, params),
  processCreateOrder: params => setProcessCreateOrder(dispatch, params),
  orderEatListByType: type => getOrderEatListByType(dispatch, type),
  orderEatListByTypeProduct: (id, type, params) =>
    getOrderEatListByTypeProduct(dispatch, id, type, params),
  orderEatListExtra: type => getOrderEatListExtra(dispatch, type),
  nearestEatStoreLocation: (lat, lng) => searchNearestEatStoreLocation(dispatch, lat, lng),
  listEatPagination: params => getListEatPagination(dispatch, params),
  favoriteMenus: params => getFavoriteMenus(dispatch, params),
});

const mapStateToProps = ({ orderData, usersReducer, eatData }) => ({
  orderData,
  usersReducer,
  eatData,
});

OrderAdd.propTypes = {
  eatData: PropTypes.object,
  favoriteMenus: PropTypes.func,
  history: PropTypes.object,
  listEatPagination: PropTypes.func,
  listOrderProductPagination: PropTypes.func,
  nearestEatStoreLocation: PropTypes.func,
  orderData: PropTypes.object,
  orderEatListByType: PropTypes.func,
  orderEatListByTypeProduct: PropTypes.func,
  orderEatListExtra: PropTypes.func,
  processCreateOrder: PropTypes.func,
  usersReducer: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderAdd);
