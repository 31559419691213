import React from 'react';
import { connect } from 'react-redux';
import { Grid, FormControl, FormLabel, Fade } from '@material-ui/core';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { compose } from 'redux';
// component
import {
  TextInput,
  ButtonMain,
  PrevNextStepperCount,
  RadioInput,
  LabelAlert,
  PickerInputDate,
} from '../../../../../../components/Index';
import UserCheckField from '../../../../../../components/higher-order-components/UserCheckField';
// helper
import { CommonHelper, MasterDataHelper, ValidationHelper } from '../../../../../../helpers/Index';
// api
import { getScheduleUserDetailsByPhone } from '../../../../../../services/api/ScheduleDataApi';
// style
import './StepBookingUserDetailsStyle.scss';

const genderOptions = MasterDataHelper.gender;
const currentDate = CommonHelper.currentDate('YYYY-MM-DD');
const defaultBirthday = CommonHelper.intervalDate(currentDate, 'YYYY-MM-DD', -30, 'year');

const initialValidation = {
  phone: { isError: false, errorMessage: '' },
  firstName: { isError: false, errorMessage: '' },
  lastName: { isError: false, errorMessage: '' },
  email: { isError: false, errorMessage: '' },
  labelAlert: { isError: '', errorMessage: '' },
};

const initialForm = {
  isExisting: false,
  userId: null,
  phone: '',
  firstName: '',
  lastName: '',
  email: '',
  gender: genderOptions[0],
  birthday: defaultBirthday,
};

const timeInterval = 300;

class StepBookingUserDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      form: CommonHelper.objectCloning(initialForm),
      validation: CommonHelper.objectCloning(initialValidation),
    };
    this.searchPhoneDebounce = _.debounce(this.searchPhoneDebounce, 400);
    this.searchEmailDebounce = _.debounce(this.searchEmailDebounce, 400);
  }

  componentDidMount() {
    const { parentForm } = this.props;
    let setInitialForm = initialForm;

    if (parentForm.step_booking_user_details) {
      setInitialForm = { ...parentForm.step_booking_user_details };
      this.setState({
        form: {
          ...setInitialForm,
        },
      });
    }
  }

  searchPhoneDebounce = async () => {
    const { scheduleUserDetailsByPhone, onPopUpInformation } = this.props;
    const { form } = this.state;
    try {
      const phone = form.phone;

      let userDetail = { ...initialForm, phone, userIdentity: null };
      let labelAlert = { isError: '', errorMessage: '' };

      if (!phone) {
        this.setState({
          form: {
            ...form,
            ...userDetail,
          },
          validation: {
            ...initialValidation,
            labelAlert,
          },
        });
        return;
      }

      const param = { phone };
      const { data } = await scheduleUserDetailsByPhone(param);

      if (data.is_user_exists) {
        const primaryUserIdentity =
          data.user_details.user_identity &&
          data.user_details.user_identity.find(item => item.is_primary);

        userDetail = {
          isExisting: data.is_user_exists,
          userId: data.user_details.user_id,
          phone,
          firstName: data.user_details.first_name || '',
          lastName: data.user_details.last_name || '',
          email: data.user_details.email,
          gender: _.find(genderOptions, ['value', data.user_details.gender]) || genderOptions[0],
          birthday: data.user_details.birthday || defaultBirthday,
          userIdentity: primaryUserIdentity,
        };
        labelAlert = {
          isError: 'success',
          errorMessage: `Yay! We found matching the user with the phone: '${phone}'`,
        };
      } else if (data.is_required_new_number) {
        labelAlert = {
          isError: 'error',
          errorMessage: `Phone Can't be use, change another Number`,
        };
      } else {
        labelAlert = {
          isError: 'error',
          errorMessage: `No matching user found with the phone: '${phone}', please complete the details`,
        };
      }

      this.setState({
        form: {
          ...form,
          ...userDetail,
        },
        validation: {
          ...initialValidation,
          labelAlert,
        },
      });
    } catch (error) {
      const serverMessage = error.data;
      onPopUpInformation(serverMessage.messages, 'error');
    }
  };

  searchEmailDebounce = () => {
    const { checkUserPhoneOrEmail } = this.props;
    const { validation } = this.state;
    const {
      form: { email },
    } = this.state;

    const param = {
      data: {
        email,
      },
    };

    checkUserPhoneOrEmail(param).catch(error => {
      const message = CommonHelper.objectCloning(initialValidation.email);
      const convertedMessage = CommonHelper.generateMessage(error.message);
      message.isError = true;
      message.errorMessage = convertedMessage;

      this.setState({ validation: { ...validation, email: message } });
    });
  };

  handleChangePhone = value => {
    const { form } = this.state;

    this.setState(
      {
        form: { ...form, phone: value },
      },
      () => {
        this.searchPhoneDebounce();
      },
    );
  };

  handleChangeFirstName = value => {
    const { form, validation } = this.state;
    const message = CommonHelper.objectCloning(initialValidation.firstName);

    if (value === '' || value === undefined) {
      message.isError = true;
      message.errorMessage = 'Please enter First Name';
    }

    this.setState({
      form: { ...form, firstName: value },
      validation: { ...validation, firstName: message },
    });
  };

  handleChangeLastName = value => {
    const { form, validation } = this.state;
    const message = CommonHelper.objectCloning(initialValidation.lastName);

    if (value === '' || value === undefined) {
      message.isError = true;
      message.errorMessage = 'Please enter Last Name';
    }

    this.setState({
      form: { ...form, lastName: value },
      validation: { ...validation, lastName: message },
    });
  };

  handleChangeEmail = value => {
    const { form, validation } = this.state;
    const message = CommonHelper.objectCloning(initialValidation.email);
    const checkerEmailFormat = ValidationHelper.validateEmail(value.trim());

    if (value === '' || value === undefined || !checkerEmailFormat) {
      message.isError = true;
      message.errorMessage = 'Please enter Email, or wrong Email Format';
    }

    this.setState(
      {
        form: { ...form, email: value },
        validation: { ...validation, email: message },
      },
      () => {
        if (!form.isExisting && checkerEmailFormat) {
          this.searchEmailDebounce();
        }
      },
    );
  };

  handleChangeGender = value => {
    const { form } = this.state;

    this.setState({ form: { ...form, gender: value } });
  };

  handleChangeBirthday = value => {
    const { form } = this.state;
    this.setState({
      form: { ...form, birthday: value },
    });
  };

  handleValidation = form => {
    let validationNewValue = CommonHelper.objectCloning(initialValidation);
    const excludeValidationKeys = ['lastName', 'labelAlert', 'userId', 'userIdentity'];

    const keys = Object.keys(form).filter(key => {
      return excludeValidationKeys.indexOf(key) === -1;
    });

    const errorValidationKeys = Object.keys(validationNewValue).filter(key => {
      return excludeValidationKeys.indexOf(key) === -1;
    });

    const errorKeys = keys.filter(key => {
      return form[key] === null || form[key] === '';
    });

    errorValidationKeys.forEach(key => {
      const isError = errorKeys.indexOf(key) !== -1;
      let errorDetail = {};
      switch (key) {
        case 'phone':
          errorDetail = { isError, errorMessage: isError ? 'Please enter Phone' : '' };
          break;
        case 'firstName':
          errorDetail = {
            isError,
            errorMessage: isError ? 'Please enter First Name' : '',
          };
          break;
        case 'email':
          errorDetail = { isError, errorMessage: isError ? 'Please enter Email' : '' };
          break;
        default:
          errorDetail = { isError, errorMessage: isError ? 'Please fill input' : '' };
          break;
      }
      validationNewValue = {
        ...validationNewValue,
        [key]: errorDetail,
      };
    });

    this.setState({
      validation: { ...validationNewValue },
    });

    return { isError: errorKeys.length, validationNewValue };
  };

  handleNextClick = async () => {
    const { onNextClick, onParameterUpdate } = this.props;
    const { form } = this.state;
    const formNewValue = CommonHelper.objectCloning(form);

    const { isError } = this.handleValidation(formNewValue);
    await onParameterUpdate(formNewValue, 'step_booking_user_details');

    if (!isError) {
      await onNextClick();
    }
  };

  render() {
    const {
      validation,
      form: { isExisting, phone, firstName, lastName, email, gender, birthday },
    } = this.state;
    const { onButtonClickCancel, scheduleSummary } = this.props;
    const modifyIsExisting = isExisting;

    return (
      <Grid container className="container-step-membership-user-details">
        <Grid item lg={12} md={12} className="mb-4 mt-32">
          <label className="text-12 text-bold text-rolling-stone">CUSTOMER DETAILS</label>
        </Grid>
        <Grid item lg={12} md={12}>
          <Grid container spacing={2}>
            <Grid item lg={5} md={5}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <FormLabel component="label" className="text-12 filed-title">
                  Phone Number
                </FormLabel>
                <TextInput
                  placeHolderText="Phone Number"
                  size="md"
                  onChange={this.handleChangePhone}
                  currentValue={phone}
                  errorMessage={validation.phone.errorMessage}
                  isError={validation.phone.isError}
                  isLoading={scheduleSummary.fetching}
                  maxLength={14}
                  numericOnly
                />
              </FormControl>
            </Grid>
            <Fade
              in={phone !== '' && validation.labelAlert.errorMessage !== ''}
              timeout={timeInterval}
            >
              <Grid item lg={7} md={7} className="mt-43">
                <LabelAlert
                  message={validation.labelAlert.errorMessage}
                  type={validation.labelAlert.isError}
                />
              </Grid>
            </Fade>
          </Grid>
        </Grid>
        <Grid item lg={12} md={12}>
          <Grid container spacing={2}>
            <Grid item lg={5} md={5}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <FormLabel component="label" className="text-12 filed-title">
                  First Name
                </FormLabel>
                <TextInput
                  placeHolderText="First Name"
                  size="md"
                  onChange={this.handleChangeFirstName}
                  currentValue={firstName}
                  errorMessage={validation.firstName.errorMessage}
                  isError={validation.firstName.isError}
                  isDisable={modifyIsExisting}
                  maxLength={25}
                />
              </FormControl>
            </Grid>
            <Grid item lg={7} md={7}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <FormLabel component="label" className="text-12 filed-title">
                  Last Name
                </FormLabel>
                <TextInput
                  placeHolderText="Last Name"
                  size="md"
                  onChange={this.handleChangeLastName}
                  currentValue={lastName}
                  errorMessage={validation.lastName.errorMessage}
                  isError={validation.lastName.isError}
                  isDisable={modifyIsExisting}
                  maxLength={25}
                />
              </FormControl>
            </Grid>
            <Grid item lg={5} md={5}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <FormLabel component="label" className="text-12 filed-title">
                  Email
                </FormLabel>
                <TextInput
                  placeHolderText="Email"
                  size="md"
                  onChange={this.handleChangeEmail}
                  currentValue={email}
                  errorMessage={validation.email.errorMessage}
                  isError={validation.email.isError}
                  isDisable={modifyIsExisting}
                  maxLength={100}
                />
              </FormControl>
            </Grid>
            <Grid item lg={7} md={7}>
              <Grid container direction="row" justify="space-between" spacing={6}>
                <Grid item lg={6} md={6}>
                  <FormControl component="fieldset" fullWidth margin="normal">
                    <FormLabel component="label" className="text-12 filed-title">
                      Date of Birth
                    </FormLabel>
                    <PickerInputDate
                      customIcon="ic-ffo-date-pick"
                      dateFormat="dd-MM-yyyy"
                      maxDate={currentDate}
                      defaultValue={birthday}
                      onChange={this.handleChangeBirthday}
                      toolbar={false}
                    />
                  </FormControl>
                </Grid>
                <Grid item lg={6} md={6}>
                  <FormControl component="fieldset" fullWidth margin="normal">
                    <FormLabel component="label" className="text-12 filed-title">
                      Gender
                    </FormLabel>
                    <RadioInput
                      data={genderOptions}
                      onSelect={this.handleChangeGender}
                      direction="column"
                      currentValue={gender}
                      isDisable={modifyIsExisting}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={12} md={12} className="mt-24">
          <Grid container justify="flex-end">
            <Grid item lg={5} md={6}>
              <Grid container justify="flex-end">
                <Grid item lg={4} md={4} className="pl-8">
                  <ButtonMain
                    type="negative"
                    size="xl"
                    labelText="Cancel"
                    onClick={onButtonClickCancel}
                  />
                </Grid>
                <Grid item lg={4} md={4} className="pl-8">
                  <ButtonMain
                    type="primary"
                    size="xl"
                    labelText="Next"
                    onClick={this.handleNextClick}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  scheduleUserDetailsByPhone: params => getScheduleUserDetailsByPhone(dispatch, params),
});

const mapStateToProps = ({ scheduleSummary }) => ({ scheduleSummary });

StepBookingUserDetails.propTypes = {
  checkUserPhoneOrEmail: PropTypes.func,
  onButtonClickCancel: PropTypes.func,
  onNextClick: PropTypes.func,
  onParameterUpdate: PropTypes.func,
  onPopUpInformation: PropTypes.func,
  parentForm: PropTypes.object,
  scheduleSummary: PropTypes.object,
  scheduleUserDetailsByPhone: PropTypes.func,
};

const shell = compose(connect(mapStateToProps, mapDispatchToProps));

const core = compose(UserCheckField, PrevNextStepperCount);

export default shell(core(StepBookingUserDetails));
