import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Grid } from '@material-ui/core';
// style
import './CalendarHeaderStyle.scss';
// helper
import { CommonHelper } from '../../../../../helpers/Index';

const CalendarHeader = props => {
  const { data, onButtonLeftClick, onButtonRightClick, today } = props;

  const currentDate = CommonHelper.dateTimeParseNewFormat(today, 'YYYY-MM-DD');

  return (
    <Grid container className="container-calendar-header" alignItems="center">
      <Grid item className="container-icon-calendar-header" onClick={onButtonLeftClick}>
        <i className="ic-ffi-chevron-left container-icon-prefix size-24 left" />
      </Grid>
      <Grid item lg md>
        <Grid container>
          {data.map((item, index) => (
            <Grid key={index} item className="custom-grid-col">
              <div
                className={`calendar-item pv-10 ${clsx({
                  'is-active': currentDate === item.value,
                })}`}
              >
                <label className="text-12 text-semi-bold mb-4">{item.day}</label>
                <label className="text-12 text-rolling-stone">{item.date}</label>
              </div>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item className="container-icon-calendar-header" onClick={onButtonRightClick}>
        <i className="ic-ffi-chevron-right container-icon-prefix size-24 right" />
      </Grid>
    </Grid>
  );
};

CalendarHeader.propTypes = {
  data: PropTypes.array,
  onButtonLeftClick: PropTypes.func,
  onButtonRightClick: PropTypes.func,
  today: PropTypes.string,
};

export default CalendarHeader;
