/* eslint-disable react/no-access-state-in-setstate */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid, Accordion, AccordionSummary, Card, CardContent } from '@material-ui/core';
import { Table } from 'antd';
import _ from 'lodash';
// Style
import './UserDetailMembershipPageStyle.scss';
import themes from '../../../../../../themes/Index';
// component
import {
  TextInput,
  SelectInputMerchant,
  LabelStatusMain,
  ChartLineBarQuota,
  ButtonMultipleMain,
  SkeletonMain,
  SnackBarSimple,
  SelectInputMain,
} from '../../../../../../components/Index';
import { ModalStopRecurring, ModalUnfreeze } from '../../../components/Index';
// Action
import {
  getMembershipPagination,
  getMembershipBundleDetail,
} from '../../../../../../services/api/UserManagement';
import {
  stopMembershipRecurringById,
  unfreezeMembership,
} from '../../../../../../services/api/MembershipApi';
// Helper
import {
  CommonHelper,
  MasterDataHelper,
  PointBoosterStatus,
  membershipActionCode,
  PointBoosterStatusDescription,
} from '../../../../../../helpers/Index';

const initialFilter = {
  search: null,
  merchantId: null,
  category: null,
  status: null,
};

const initialSort = {
  sortField: 'name',
  sortOrder: '',
};

const optionToast = {
  vertical: 'top',
  horizontal: 'right',
};

const membershipActionOptions = MasterDataHelper.membershipActionOptions;
const membershipFilterOption = MasterDataHelper.membershipFilterOption;

class UserDetailMembershipPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: CommonHelper.objectCloning(initialFilter),
      page: 1,
      limit: 10,
      isExpand: '',
      sort: CommonHelper.objectCloning(initialSort),
      pagination: {
        showSizeChanger: true,
        showTotal: (total, range) => `Showing ${range[0]}-${range[1]} From ${total}`,
        pageSizeOptions: ['10', '20', '30', '40'],
      },
      loading: true,
      isLoading: true,
      isOpenStopRecurringModal: false,
      isOpenUnfreezeModal: false,
      recurringId: null,
      freezeRequestId: null,
      productName: '',
      toastInformation: {
        isOpen: false,
        message: '',
        snackbarType: 'warning',
      },
    };
    this.searchDebounce = _.debounce(this.searchDebounce, 400);
  }

  componentDidMount() {
    this.getMembershipPagination();
  }

  getMembershipPagination() {
    const { userDetailsInfo, membershipPagination } = this.props;
    const userId = userDetailsInfo.user_id;
    const {
      limit,
      pagination,
      page,
      filter: { search, merchantId, status },
      sort,
    } = this.state;

    const params = {
      search,
      user_id: userId,
      merchant_id: merchantId,
      page,
      limit,
      sort: sort.sortField,
      sortOrder: sort.sortOrder,
      status,
    };

    this.setState({ isLoading: true }, () => {
      membershipPagination(params)
        .then(response => {
          this.setState({
            loading: false,
            isLoading: false,
            pagination: { ...pagination, pageSize: limit, total: response.total },
          });
        })
        .catch(error => {
          const message = error.data;
          this.processMessage(message.messages, 'error');
        });
    });
  }

  handleChangeSearch = value => {
    const { filter } = this.state;

    this.setState(
      {
        filter: { ...filter, search: value },
        page: 1,
        pagination: { ...this.state.pagination, current: 1 },
      },
      () => {
        this.searchDebounce();
      },
    );
  };

  handleSelectMerchant = value => {
    const { filter } = this.state;

    this.setState(
      {
        page: 1,
        pagination: { ...this.state.pagination, current: 1 },
        filter: {
          ...filter,
          merchantId: value || null,
        },
      },
      () => {
        this.getMembershipPagination();
      },
    );
  };

  handleSelectFilter = value => {
    const { filter } = this.state;

    this.setState(
      {
        page: 1,
        pagination: { ...this.state.pagination, current: 1 },
        filter: {
          ...filter,
          status: value || null,
        },
      },
      () => {
        this.getMembershipPagination();
      },
    );
  };

  handleClickButtonAction = (value, row) => {
    const { history, userDetailsInfo } = this.props;
    const membershipId = row.user_point_booster_id;

    let param = null;
    let currentPath = null;

    switch (value) {
      case membershipActionCode.stopRecurring:
        this.setState({
          isOpenStopRecurringModal: true,
          recurringId: row.recurring_details.recurring_id,
          productName: row.product_name,
        });
        break;
      case membershipActionCode.unfreeze:
        this.setState({
          isOpenUnfreezeModal: true,
          freezeRequestId: row.freeze_details.freeze_request_id,
          productName: row.product_name,
        });
        break;
      case membershipActionCode.renew:
        param = CommonHelper.encryptObject({
          phone: userDetailsInfo.phone,
          minDate: row.earliest_renew_date,
          membershipId,
        });
        currentPath = `/membership/purchase/renewal/${param}`;
        break;
      case membershipActionCode.freeze:
        param = CommonHelper.encryptObject({
          membershipId,
          backUrl: history.location.pathname,
        });
        currentPath = `/management/users/freezemembership/${membershipId}`;
        break;
      case membershipActionCode.changePayment:
        param = CommonHelper.encryptObject({
          membershipId,
          backUrl: history.location.pathname,
        });
        currentPath = `/membership/users/change-payment/${param}`;
        break;
      case membershipActionCode.viewDetails:
        window.open(
          `https://metabase.fitco.id/public/dashboard/4164fefa-0e83-432b-b391-eb6af743642d?user_point_booster_id=${membershipId}`,
          '_blank',
        );
        break;
      default:
        break;
    }

    if (currentPath) {
      history.push(currentPath);
    }
  };

  handleExpandChange = (panel, valueBundling, valueBoosterId) => (event, isExpanded) => {
    const { membershipBundleDetail } = this.props;
    const param = {
      user_point_booster_id: valueBoosterId,
    };

    const finalPanel = valueBundling ? panel : '';
    this.setState({ isLoading: true, isExpand: isExpanded ? finalPanel : '' }, () => {
      if (valueBundling) {
        membershipBundleDetail(param)
          .then(() => {
            this.setState({
              isLoading: false,
            });
          })
          .catch(error => {
            const message = error.data;
            this.processMessage(message.messages, 'error');
          });
      }
    });
  };

  handleShowMore = value => {
    const { membershipBundleDetail } = this.props;
    const param = {
      user_point_booster_id: value,
    };

    this.setState({ isLoading: true }, () => {
      membershipBundleDetail(param)
        .then(() => {
          this.setState({
            isLoading: false,
          });
        })
        .catch(error => {
          const message = error.data;
          this.processMessage(message.messages, 'error');
        });
    });
  };

  handleTableChange = (pagination, filters, sorter) => {
    const { sort } = this.state;

    this.setState(
      {
        limit: pagination.pageSize,
        pagination: {
          ...this.state.pagination,
          current: pagination.current,
          pageSize: pagination.pageSize,
        },
        sort: {
          ...sort,
          sortField: sorter.field,
          sortOrder: sorter.order === 'ascend' ? 'asc' : 'desc',
        },
        page: pagination.current,
      },
      () => {
        this.getMembershipPagination();
      },
    );
  };

  handleCloseToast = () => {
    const { toastInformation } = this.state;
    this.setState({ toastInformation: { ...toastInformation, isOpen: false } });
  };

  handleCloseStopRecurringModal = () => {
    this.setState({
      isOpenStopRecurringModal: false,
    });
  };

  handleStopRecurring = async recurringId => {
    const { callStopMembershipRecurringById } = this.props;
    try {
      await callStopMembershipRecurringById(recurringId, {});
      this.setState({ recurringId: '', productName: '' });
      this.processMessage('Recurring Successfully Stopped', 'success');
      this.handleCloseStopRecurringModal();
      this.getMembershipPagination();
    } catch (error) {
      const errors = error.data;
      this.processMessage(errors.messages, 'error');
      this.handleCloseStopRecurringModal();
    }
  };

  handleCloseUnfreezeModal = () => {
    this.setState({
      isOpenUnfreezeModal: false,
    });
  };

  handleUnfreeze = async freezeRequestId => {
    const { callUnfreezeMembership } = this.props;
    try {
      const body = {
        freeze_request_id: freezeRequestId,
        active_date: CommonHelper.currentDate('YYYY-MM-DD'),
      };

      await callUnfreezeMembership(body);
      this.setState({ freezeRequestId: '', productName: '' });
      this.processMessage('Unfreeze Membership is successful', 'success');
      this.handleCloseUnfreezeModal();
      this.getMembershipPagination();
    } catch (error) {
      const errors = error.data;
      this.processMessage(errors.messages, 'error');
      this.handleCloseUnfreezeModal();
    }
  };

  processMessage(messages, type) {
    const convertedMessage = CommonHelper.generateMessage(messages);

    const paramInformation = {
      isOpen: true,
      message: convertedMessage,
      snackbarType: type,
    };

    this.setState({
      toastInformation: paramInformation,
    });
  }

  searchDebounce() {
    this.getMembershipPagination();
  }

  renderExpandInformation() {
    const { isLoading } = this.state;
    const {
      userManagement: { membershipsBundleDetail },
    } = this.props;
    const boosterBundled = membershipsBundleDetail.booster_bundled;
    const startDate = CommonHelper.dateTimeParseNewFormat(
      membershipsBundleDetail.start_date,
      'DD MMM YYYY',
    );
    const endDate = CommonHelper.dateTimeParseNewFormat(
      membershipsBundleDetail.end_date,
      'DD MMM YYYY',
    );
    let renderElement = <SkeletonMain />;

    if (!isLoading && boosterBundled) {
      renderElement = (
        <Grid container direction="column">
          <Grid item>
            <div className="divider-hr" />
          </Grid>
          <Grid item>
            <Grid container direction="column">
              <Grid item className="container-membership parent">
                <ChartLineBarQuota
                  colorBar={themes.palette.downy}
                  customElementSubProps={
                    <div className="text-sub-title-booster">
                      <label className="text-12">{`${membershipsBundleDetail.full_period} Days - ${membershipsBundleDetail.remaining_period} Days remaining`}</label>
                    </div>
                  }
                  customElementInfoProps={
                    <Grid container justify="space-between">
                      <Grid item>
                        <div className="text-info-booster">
                          <label className="text-12">Start At</label>
                          <label className="text-12">{startDate}</label>
                        </div>
                      </Grid>
                      <Grid item>
                        <div className="text-info-booster">
                          <label className="text-12">End Date</label>
                          <label className="text-12">{endDate}</label>
                        </div>
                      </Grid>
                    </Grid>
                  }
                  infoBar={false}
                  totalUsage={membershipsBundleDetail.usage || 0}
                />
              </Grid>
              <Grid item>
                <Grid container direction="row" className="scroll-container container-bundle">
                  {boosterBundled.map((item, index) => (
                    <Grid
                      item
                      lg={4}
                      md={4}
                      key={index}
                      style={{ padding: 6, display: 'flex', flex: 1 }}
                    >
                      <Card style={{ display: 'flex', flex: 1 }}>
                        <CardContent
                          className="container-membership"
                          style={{ display: 'flex', flex: 1 }}
                        >
                          <ChartLineBarQuota
                            iconPrefix="ic-ffo-check-mark"
                            labelText={item.product_name}
                            colorBar={themes.palette.cornflowerBlue}
                            customElementSubProps={
                              <div className="text-sub-title-booster">
                                <label className="text-12">{`${item.full_period} - ${item.remaining_period}`}</label>
                              </div>
                            }
                            customElementInfoProps={
                              <Grid container justify="space-between">
                                <Grid item>
                                  <div className="text-info-booster">
                                    <label className="text-10">Start At</label>
                                    <label className="text-10">
                                      {CommonHelper.dateTimeParseNewFormat(
                                        item.start_date,
                                        'DD MMM YYYY',
                                      )}
                                    </label>
                                  </div>
                                </Grid>
                                <Grid item>
                                  <div className="text-info-booster">
                                    <label className="text-10">End Date</label>
                                    <label className="text-10">
                                      {CommonHelper.dateTimeParseNewFormat(
                                        item.end_date,
                                        'DD MMM YYYY',
                                      )}
                                    </label>
                                  </div>
                                </Grid>
                              </Grid>
                            }
                            infoBar={false}
                            totalUsage={item.usage || 0}
                          />
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      );
    }

    return renderElement;
  }

  renderStatus = value => {
    const status = PointBoosterStatusDescription.find(item => {
      return value.status === item.value;
    });

    if (value.recurring_details) {
      return (
        <div className="container-status">
          <LabelStatusMain value={'Recurring Active'} type="warning" />
        </div>
      );
    }

    if (value.status === PointBoosterStatus.Active) {
      return (
        <div className="container-status">
          <LabelStatusMain value={status && status.name} type="complete" />
        </div>
      );
    }

    if (
      value.status === PointBoosterStatus.Stopped ||
      value.status === PointBoosterStatus.Suspended
    ) {
      return (
        <div className="container-status">
          <LabelStatusMain value={status && status.name} type="danger" />
        </div>
      );
    }

    if (value.status === PointBoosterStatus.Inactive) {
      return (
        <div className="container-status">
          <LabelStatusMain value={status && status.name} type="default" />
        </div>
      );
    }

    return (
      <div className="container-status">
        <LabelStatusMain value={status && status.name} type="warning" />
      </div>
    );
  };

  renderColumns() {
    return [
      {
        title: 'Membership Name',
        dataIndex: 'name',
        sorter: true,
        render: (text, row) => {
          const startDate = CommonHelper.dateTimeParseNewFormat(row.start_date, 'DD MMM YYYY');
          const endDate = CommonHelper.dateTimeParseNewFormat(row.end_date, 'DD MMM YYYY');
          const recurringDate = row.recurring_date
            ? CommonHelper.dateTimeParseNewFormat(row.recurring_date, 'DD MMM YYYY')
            : '';

          // const recurringRow = row.is_can_change_recurring_payment ? 10 : '12';
          const maxColumn = 12;
          const paymentMethod = row.recurring_payment_details
            ? MasterDataHelper.paymentMethodOption.filter(
                item => item.value === row.recurring_payment_details.payment_type,
              )[0].name
            : null;

          const modifyMembershipActionOptions = [];
          modifyMembershipActionOptions.push(
            membershipActionOptions.find(x => x.value === membershipActionCode.viewDetails),
          );

          if (row.is_renewable) {
            modifyMembershipActionOptions.push(
              membershipActionOptions.find(x => x.value === membershipActionCode.renew),
            );
          }

          const onFreeze = row.status === 6;
          if (!row.is_can_change_recurring_payment && !onFreeze) {
            modifyMembershipActionOptions.push(
              membershipActionOptions.find(x => x.value === membershipActionCode.freeze),
            );
          }

          if (onFreeze) {
            modifyMembershipActionOptions.push(
              membershipActionOptions.find(x => x.value === membershipActionCode.unfreeze),
            );
          }

          if (
            row.recurring_details &&
            row.recurring_payment_details.payment_method !== 'installment'
          ) {
            modifyMembershipActionOptions.push(
              membershipActionOptions.find(x => x.value === membershipActionCode.stopRecurring),
            );
          }

          return (
            <Accordion
              className="container-expand-row-membership"
              expanded={this.state.isExpand === row.product_name}
              onChange={this.handleExpandChange(
                row.product_name,
                row.is_bundling,
                row.user_point_booster_id,
              )}
            >
              <AccordionSummary>
                <Grid
                  container
                  className="container-table-row flex-wrap-unset"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item lg={maxColumn} md={maxColumn} className="title">
                    <div className="flex-column">
                      <div className="flex-row justify-between mb-24">
                        <Grid item>
                          <span className="flex-row">
                            <label className="text-14 mr-8 wrapping-container second line wrapping-container-break ">
                              {row.product_name}
                            </label>
                            {this.renderStatus(row)}
                          </span>
                        </Grid>
                        <Grid item>
                          <ButtonMultipleMain
                            options={modifyMembershipActionOptions}
                            icon="ic-ffo-more-dot"
                            type="secondary"
                            size="xxl"
                            onClick={item => this.handleClickButtonAction(item, row)}
                          />
                        </Grid>
                      </div>
                      <Grid
                        container
                        justify="space-between"
                        className="flex-wrap-unset"
                        spacing={1}
                      >
                        <Grid item>
                          <div className="flex-column container-column-text col-start-date">
                            <label className="text-12 title mb-6">Membership Period</label>
                            <label className="text-12 bold">{`${startDate} - ${endDate}`}</label>
                          </div>
                        </Grid>
                        <Grid>
                          <div className="divider-hr-vertical" />
                        </Grid>
                        <Grid item>
                          <div className="flex-column container-column-text col-end-date">
                            <label className="text-12 title mb-6">Membership Term</label>
                            <label className="text-12 bold">{`${row.period} ${row.period_type}`}</label>
                          </div>
                        </Grid>
                        <Grid>
                          <div className="divider-hr-vertical" />
                        </Grid>
                        <Grid item>
                          <div className="flex-column container-column-text col-end-date">
                            <label className="text-12 title mb-6">Auto Recurring</label>
                            {row.recurring_details ? (
                              <label className="text-12 bold renew">{'ON'}</label>
                            ) : (
                              <label className="text-12 bold not-recurring">{'OFF'}</label>
                            )}
                          </div>
                        </Grid>
                        <Grid item>
                          <div className="flex-column container-column-text col-end-date">
                            <label className="text-12 title mb-6">Recurring Date</label>
                            <label className="text-12 bold">{recurringDate || 'N/A'}</label>
                          </div>
                        </Grid>
                        <Grid>
                          <div className="divider-hr-vertical" />
                        </Grid>
                        <Grid item>
                          <div className="flex-column container-column-text col-end-date">
                            <label className="text-12 title mb-6">Current Quota</label>
                            <label className="text-12 bold">
                              {row.quota ? `${row.quota - row.used} out of ${row.quota}` : 'N/A'}
                            </label>
                          </div>
                        </Grid>
                        <Grid item>
                          <div className="flex-column container-column-text col-end-date">
                            <label className="text-12 title mb-6">Refreshed On</label>
                            <label className="text-12 bold">{row.next_reset_date || 'N/A'}</label>
                          </div>
                        </Grid>
                        {row.is_bundling ? (
                          <Grid>
                            <div className="divider-hr-vertical" />
                          </Grid>
                        ) : null}
                        {row.is_bundling ? (
                          <Grid item>
                            <div className="flex-column container-column-text col-multiple-member">
                              <label className="text-12 title mb-6">Membership Bundle</label>
                              <label className="text-12 bold show-more">( Show More )</label>
                            </div>
                          </Grid>
                        ) : null}
                      </Grid>
                    </div>
                  </Grid>
                  {paymentMethod && row.recurring_payment_details.modify_by && (
                    <Grid item lg={2} md={2}>
                      <div className="container-main-card-shadow align-center flex-column p-10">
                        <label className="text-12 text-semi-bold mb-4 text-center">
                          Payment Change to
                        </label>
                        <label className="text-14 text-bold m-4 text-center">{paymentMethod}</label>
                        <label className="text-12 text-semi-bold">
                          {CommonHelper.dateTimeParseNewFormat(
                            row.recurring_payment_details.updated_at,
                            'DD MMM YYYY',
                          )}
                        </label>
                      </div>
                    </Grid>
                  )}
                </Grid>
              </AccordionSummary>
              {this.renderExpandInformation()}
            </Accordion>
          );
        },
      },
    ];
  }

  renderFilter() {
    return (
      <Grid container justify="flex-start" className="container-filter">
        <Grid item lg={4} md={4} className="row-filter">
          <TextInput
            iconPrefix="ic-ffo-search"
            placeHolderText="Search"
            onChange={this.handleChangeSearch}
            size="md"
          />
        </Grid>
        <Grid item lg={3} md={3} className="row-filter">
          <div className="container-remove-margin">
            <SelectInputMerchant
              placeHolder="Select Merchant"
              onChange={this.handleSelectMerchant}
              currentValue={this.state.filter.merchantId}
              filter={this.state.filter}
              includeAllData
            />
          </div>
        </Grid>
        <Grid item lg={3} md={3} className="row-filter">
          <div className="container-remove-margin">
            <SelectInputMain
              options={membershipFilterOption}
              currentValue={this.state.filter.status}
              onChange={this.handleSelectFilter}
            />
          </div>
        </Grid>
      </Grid>
    );
  }

  render() {
    const {
      loading,
      pagination,
      isOpenStopRecurringModal,
      isOpenUnfreezeModal,
      productName,
      recurringId,
      freezeRequestId,
      toastInformation,
    } = this.state;
    const { userManagement } = this.props;
    const ListCustom = userManagement.listMemberships;

    return (
      <>
        <Grid container direction="column" className="container-tab-membership">
          <Grid item lg md className="section-table-header">
            {this.renderFilter()}
          </Grid>
          <Grid item lg md className="section-table-body">
            <Table
              className="container-table"
              columns={this.renderColumns()}
              rowKey={record => record.user_point_booster_id}
              dataSource={ListCustom}
              pagination={pagination}
              loading={loading}
              // scroll={{ y: adjustTableHeight }}
              onChange={this.handleTableChange}
            />
          </Grid>
        </Grid>
        <SnackBarSimple
          open={toastInformation.isOpen}
          durationHide={2000}
          message={toastInformation.message}
          onClickClose={this.handleCloseToast}
          snackbarType={toastInformation.snackbarType}
          anchor={optionToast}
        />
        {isOpenStopRecurringModal && (
          <ModalStopRecurring
            handleCancel={() => {
              this.handleStopRecurring(recurringId);
            }}
            isOpen={isOpenStopRecurringModal}
            name={productName}
            onClose={this.handleCloseStopRecurringModal}
          />
        )}
        {isOpenUnfreezeModal && (
          <ModalUnfreeze
            handleUnfreeze={() => {
              this.handleUnfreeze(freezeRequestId);
            }}
            isOpen={isOpenUnfreezeModal}
            name={productName}
            onClose={this.handleCloseUnfreezeModal}
          />
        )}
      </>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  membershipPagination: params => getMembershipPagination(dispatch, params),
  membershipBundleDetail: params => getMembershipBundleDetail(dispatch, params),
  callStopMembershipRecurringById: (recurringId, params) =>
    stopMembershipRecurringById(dispatch, recurringId, params),
  callUnfreezeMembership: body => unfreezeMembership(dispatch, body),
});

const mapStateToProps = ({ userManagement }) => ({
  userManagement,
});

UserDetailMembershipPage.propTypes = {
  callStopMembershipRecurringById: PropTypes.func,
  callUnfreezeMembership: PropTypes.func,
  history: PropTypes.object,
  membershipBundleDetail: PropTypes.func,
  membershipPagination: PropTypes.func,
  userDetailsInfo: PropTypes.object,
  userManagement: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserDetailMembershipPage);
