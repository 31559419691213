export const constants = {
  getListProductCategoryPaginationRequest: 'GET_LIST_PRODUCT_CATEGORY_PAGINATION_REQUEST',
  getListProductCategoryPaginationSuccess: 'GET_LIST_PRODUCT_CATEGORY_PAGINATION_SUCCESS',
  getListProductCategoryPaginationFail: 'GET_LIST_PRODUCT_CATEGORY_PAGINATION_FAIL',
  updateProductCategoryRequest: 'UPDATE_PRODUCT_CATEGORY_REQUEST',
  updateProductCategorySuccess: 'UPDATE_PRODUCT_CATEGORY_SUCCESS',
  updateProductCategoryFail: 'UPDATE_PRODUCT_CATEGORY_FAIL',
  deleteProductCategoryRequest: 'DELETE_PRODUCT_CATEGORY_REQUEST',
  deleteProductCategorySuccess: 'DELETE_PRODUCT_CATEGORY_SUCCESS',
  deleteProductCategoryFail: 'DELETE_PRODUCT_CATEGORY_FAIL',
};

const initState = {
  fetching: false,
  list: [],
  total: 0,
  errorMessage: null,
  responseMessage: null,
  details: {},
};

export default function productCategory(state = initState, action) {
  const { type, payload } = action;
  switch (type) {
    case constants.updateProductCategoryRequest:
      return { ...state, fetching: true, responseMessage: null };
    case constants.updateProductCategorySuccess:
      return {
        ...state,
        responseMessage: payload.messages,
        fetching: false,
      };
    case constants.updateProductCategoryFail:
      return { ...state, fetching: false, errorMessage: payload };

    case constants.deleteProductCategoryRequest:
      return { ...state, fetching: true, responseMessage: null };
    case constants.deleteProductCategorySuccess:
      return {
        ...state,
        responseMessage: payload.messages,
        fetching: false,
      };
    case constants.deleteProductCategoryFail:
      return { ...state, fetching: false, errorMessage: payload };

    case constants.getListProductCategoryPaginationRequest:
      return { ...state, fetching: true, responseMessage: null };
    case constants.getListProductCategoryPaginationSuccess:
      return {
        ...state,
        list: payload.data,
        total: payload.total,
        fetching: false,
      };
    case constants.getListProductCategoryPaginationFail:
      return { ...state, fetching: false, errorMessage: payload };

    default:
      return state;
  }
}
