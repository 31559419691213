export const constants = {
  getListFeedPaginationRequest: 'GET_LIST_FEED_PAGINATION_REQUEST',
  getListFeedPaginationSuccess: 'GET_LIST_FEED_PAGINATION_SUCCESS',
  getListFeedPaginationFail: 'GET_LIST_FEED_PAGINATION_FAIL',
  getFeedDetailsRequest: 'GET_FEED_DETAILS_REQUEST',
  getFeedDetailsSuccess: 'GET_FEED_DETAILS_SUCCESS',
  getFeedDetailsFail: 'GET_FEED_DETAILS_FAIL',
  setCreateFeedRequest: 'SET_CREATE_FEED_REQUEST',
  setCreateFeedSuccess: 'SET_CREATE_FEED_SUCCESS',
  setCreateFeedFail: 'SET_CREATE_FEED_FAIL',
  setUpdateFeedRequest: 'SET_UPDATE_FEED_REQUEST',
  setUpdateFeedSuccess: 'SET_UPDATE_FEED_SUCCESS',
  setUpdateFeedFail: 'SET_UPDATE_FEED_FAIL',
  // editTncSectionRequest: 'EDIT_TNC_SECTION_REQUEST',
  // editTncSectionSuccess: 'EDIT_TNC_SECTION_SUCCESS',
  // editTncSectionFail: 'EDIT_TNC_SECTION_FAIL',
};

const initState = {
  fetching: false,
  list: [],
  details: {},
  errorFetch: false,
  errorMessage: null,
  responseMessage: null,
};

export default function feedReducer(state = initState, action) {
  const { type, payload } = action;
  switch (type) {
    case constants.getListFeedPaginationRequest:
      return { ...state, fetching: true };
    case constants.getListFeedPaginationSuccess:
      return {
        ...state,
        list: payload.data.data,
        fetching: false,
      };
    case constants.getListFeedPaginationFail:
      return {
        ...state,
        fetching: false,
        errorFetch: true,
        errorMessage: payload,
      };
    case constants.getFeedDetailsRequest:
      return { ...state, fetching: true };
    case constants.getFeedDetailsSuccess:
      return {
        ...state,
        details: payload.data,
        fetching: false,
      };
    case constants.getFeedDetailsFail:
      return {
        ...state,
        fetching: false,
        errorFetch: true,
        errorMessage: payload,
      };
    case constants.setCreateFeedRequest:
      return { ...state, fetching: true, responseMessage: null };
    case constants.setCreateFeedSuccess:
      return {
        ...state,
        responseMessage: payload.messages,
        fetching: false,
      };
    case constants.setCreateFeedFail:
      return { ...state, fetching: false, errorMessage: payload };

    case constants.setUpdateFeedRequest:
      return { ...state, fetching: true, responseMessage: null };
    case constants.setUpdateFeedSuccess:
      return {
        ...state,
        responseMessage: payload.messages,
        fetching: false,
      };
    case constants.setUpdateFeedFail:
      return { ...state, fetching: false, errorMessage: payload };

    // case constants.editTncSectionRequest:
    //   return { ...state, fetching: true, responseMessage: null };
    // case constants.editTncSectionSuccess:
    //   return {
    //     ...state,
    //     responseMessage: payload.messages,
    //     fetching: false,
    //   };
    // case constants.editTncSectionFail:
    //   return { ...state, fetching: false, errorMessage: payload };
    default:
      return state;
  }
}
