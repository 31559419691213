/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, FormControl, FormLabel, Fade } from '@material-ui/core';
import _ from 'lodash';
// component
import { TextInput, LabelAlert } from '../../../../../../../../components/Index';
// helper
import { CommonHelper, ValidationHelper } from '../../../../../../../../helpers/Index';

const initialForm = {
  phone: '',
  email: '',
  referral: '',
};

const initialValidation = {
  phone: { isError: false, errorMessage: '' },
  email: { isError: false, errorMessage: '' },
  labelAlert: { isError: '', errorMessage: '' },
};

const timeInterval = 300;

const onValidationHandler = payload => {
  let validationNewValue = CommonHelper.objectCloning(initialValidation);
  const excludeValidationKeys = ['referral', 'labelAlert'];

  const keys = Object.keys(payload).filter(key => {
    return excludeValidationKeys.indexOf(key) === -1;
  });

  const errorValidationKeys = Object.keys(validationNewValue).filter(key => {
    return excludeValidationKeys.indexOf(key) === -1;
  });

  const errorKeys = keys.filter(key => {
    return !payload[key];
  });

  errorValidationKeys.forEach(key => {
    const isError = errorKeys.indexOf(key) !== -1;
    let errorDetail = {};
    switch (key) {
      case 'phone':
        errorDetail = { isError, errorMessage: isError ? 'Please fill phone' : '' };
        break;
      case 'email':
        errorDetail = { isError, errorMessage: isError ? 'Please fill email' : '' };
        break;
      default:
        errorDetail = {
          isError,
          errorMessage: isError ? 'Please fill input' : '',
        };
        break;
    }
    validationNewValue = {
      ...validationNewValue,
      [key]: errorDetail,
    };
  });

  return { isError: Boolean(errorKeys.length), validationNewValue };
};

const ContactInformationForm = ({
  checkEmailOrPhone,
  onParameterUpdate,
  onValidationUpdate,
  validate,
}) => {
  const [form, setForm] = useState(initialForm);
  const [validation, setValidation] = useState(initialValidation);
  const [fetching, setFetching] = useState({ phone: false, email: false });

  useEffect(() => {
    if (validate) {
      const error = onValidationHandler(form);
      setValidation(error.validationNewValue);
      const payload = {
        ...error,
      };
      onValidationUpdate(payload, 'contact_details');
    }
  }, [validate]);

  useEffect(() => {
    const payload = {
      ...form,
    };
    onParameterUpdate(payload, 'contact_details');
  }, [form]);

  const searchDebounce = async (key, value) => {
    let labelAlert = { ...initialValidation.labelAlert };

    if (!value) {
      setValidation(() => ({
        ...initialValidation,
      }));
      setFetching(oldFetching => ({
        ...oldFetching,
        [key]: false,
      }));
      return;
    }

    try {
      setFetching(oldFetching => ({
        ...oldFetching,
        [key]: true,
      }));
      const params = {
        data: {
          [key]: value.trim(),
        },
      };
      const { messages } = await checkEmailOrPhone(params);

      if (messages.hasOwnProperty('phone')) {
        const convertedMessage = CommonHelper.generateMessage(messages);
        labelAlert = {
          isError: 'success',
          errorMessage: convertedMessage,
        };
        setValidation(oldValidation => ({
          ...oldValidation,
          labelAlert,
        }));
      }

      setFetching(oldFetching => ({
        ...oldFetching,
        [key]: false,
      }));
    } catch (error) {
      const { data } = error;
      const convertedMessage = CommonHelper.generateMessage(data.messages);

      if (data.messages.hasOwnProperty('phone')) {
        labelAlert = {
          isError: 'error',
          errorMessage: convertedMessage,
        };
        setValidation(oldValidation => ({
          ...oldValidation,
          labelAlert,
        }));
      }

      setValidation(oldValidation => ({
        ...oldValidation,
        [key]: {
          isError: true,
          errorMessage: convertedMessage,
        },
      }));
      setFetching(oldFetching => ({
        ...oldFetching,
        [key]: false,
      }));
    }
  };

  const searchPhoneOrEmail = useRef(_.debounce((key, value) => searchDebounce(key, value), 500))
    .current;

  const onHandleChangeForm = (value, key) => {
    const message = CommonHelper.objectCloning(initialValidation[key]);

    switch (key) {
      case 'phone': {
        const phoneValidation = ValidationHelper.validatePhone(value.trim());
        if (value === '' || value === undefined || !phoneValidation) {
          message.isError = true;
          message.errorMessage = 'Please check your Phone, and try again';
        }
        break;
      }
      case 'email': {
        const checkerEmailFormat = ValidationHelper.validateEmail(value.trim());
        if (value === '' || value === undefined || !checkerEmailFormat) {
          message.isError = true;
          message.errorMessage = 'Please enter Email, or wrong Email Format';
        }
        break;
      }
      default:
        break;
    }

    setForm(oldForm => ({
      ...oldForm,
      [key]: value,
    }));

    setValidation(oldValidation => ({
      ...oldValidation,
      [key]: message,
    }));

    if (!message.isError && (key === 'phone' || key === 'email')) {
      searchPhoneOrEmail(key, value);
    }
  };

  return (
    <>
      <Grid item lg={12} md={12} className="mb-4">
        <label className="text-12 text-bold text-rolling-stone">CONTACT INFORMATION</label>
      </Grid>
      <Grid container>
        <Grid item lg={12} md={12}>
          <Grid container spacing={6}>
            <Grid item lg={6} md={6}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <FormLabel component="label" className="text-12 filed-title" required>
                  Phone Number
                </FormLabel>
                <TextInput
                  placeHolderText="Phone Number"
                  size="md"
                  onChange={value => {
                    onHandleChangeForm(value, 'phone');
                  }}
                  currentValue={form.phone}
                  errorMessage={validation.phone.errorMessage}
                  isError={validation.phone.isError}
                  isLoading={fetching.phone}
                  maxLength={14}
                  numericOnly
                  required
                />
              </FormControl>
            </Grid>
            <Fade
              in={form.phone !== '' && validation.labelAlert.errorMessage !== ''}
              timeout={timeInterval}
            >
              <Grid item lg={6} md={6} className="mt-43">
                <LabelAlert
                  message={validation.labelAlert.errorMessage}
                  type={validation.labelAlert.isError}
                />
              </Grid>
            </Fade>
          </Grid>
        </Grid>
        <Grid item lg={12} md={12}>
          <Grid container spacing={6}>
            <Grid item lg={6} md={6}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <FormLabel component="label" className="text-12 filed-title" required>
                  Email
                </FormLabel>
                <TextInput
                  placeHolderText="Email"
                  size="md"
                  onChange={value => {
                    onHandleChangeForm(value, 'email');
                  }}
                  currentValue={form.email}
                  errorMessage={validation.email.errorMessage}
                  isError={validation.email.isError}
                  isLoading={fetching.email}
                  maxLength={100}
                  required
                />
              </FormControl>
            </Grid>
            <Grid item lg={6} md={6}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <FormLabel component="label" className="text-12 filed-title">
                  Install Code
                </FormLabel>
                <TextInput
                  placeHolderText="Install Code if any"
                  size="md"
                  onChange={value => {
                    onHandleChangeForm(value, 'referral');
                  }}
                  currentValue={form.referral}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

ContactInformationForm.propTypes = {
  checkEmailOrPhone: PropTypes.func,
  onParameterUpdate: PropTypes.func,
  onValidationUpdate: PropTypes.func,
  validate: PropTypes.bool,
};

export default ContactInformationForm;
