import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as Actions from '../../redux/actions/UsersAction';
import _ from 'lodash';

const PrevStateValue = WrappedComponent => {
  class NewComponent extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        filter: {},
      };
    }

    handleResetPrevStateValue = (prevStateValue, key = 'global') => {
      const { setPrevStateValue, usersReducer } = this.props;
      let modifyValue = { url: prevStateValue.url };

      if (!_.isEmpty(usersReducer.prevStateValue)) {
        const CheckIfEqualUrl = usersReducer.prevStateValue.url === prevStateValue.url;
        modifyValue = { [key]: { ...usersReducer.prevStateValue[key], ...prevStateValue } };

        if (!CheckIfEqualUrl) {
          modifyValue = {};
        }
      }

      setPrevStateValue(modifyValue);
    };

    handleSetPrevStateValueTabs = (prevStateValue, key = 'global') => {
      const { setPrevStateValue, usersReducer } = this.props;

      const newFilter = { [key]: { ...usersReducer.prevStateValue[key], ...prevStateValue } };

      setPrevStateValue(newFilter);
    };

    handleSetPrevStateValue = (prevStateValue, key = 'global') => {
      const { setPrevStateValue, usersReducer } = this.props;

      const newFilter = { [key]: { ...usersReducer.prevStateValue[key], ...prevStateValue } };

      setPrevStateValue(newFilter);
    };

    render() {
      return (
        <WrappedComponent
          prevFilter={this.state.filter}
          onResetPrevStateValue={this.handleResetPrevStateValue}
          onSetPrevStateValueTabs={this.handleSetPrevStateValueTabs}
          onSetPrevStateValue={this.handleSetPrevStateValue}
          {...this.props}
        />
      );
    }
  }

  const mapDispatchToProps = dispatch => ({
    setPrevStateValue: params => dispatch(Actions.setPrevState(params)),
  });

  const mapStateToProps = ({ usersReducer }) => ({ usersReducer });

  NewComponent.propTypes = {
    setPrevStateValue: PropTypes.func,
    usersReducer: PropTypes.object,
  };

  return connect(mapStateToProps, mapDispatchToProps)(NewComponent);
};

export default PrevStateValue;
