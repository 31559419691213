import { makeStyles } from '@material-ui/core/styles';

const StylesJs = makeStyles({
  content: {
    height: '100vh',
    width: '100%',
  },
});

export default StylesJs;
