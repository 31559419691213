/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
// Style
import './GalleryDocumentStyle.scss';
import { Grid } from '@material-ui/core';
// asset
import { Icons } from '../../../assets/Index';
// component
import { ButtonMain } from '../../Index';

const GalleryDocument = props => {
  const { dataFile } = props;
  const [isActiveIndex, setActive] = React.useState(null);

  const handleToggleHover = keyItem => {
    setActive(keyItem);
  };

  const handleToggleHoverLeave = () => {
    setActive(null);
  };

  const handleButtonClick = url => {
    window.open(url, '_blank');
  };

  return (
    <Grid container className="container-gallery-document">
      {dataFile.map((item, index) => {
        let imageType = 'pdf';

        switch (item.type) {
          case 'word':
          case 'pdf':
            imageType = Icons.fileDocument;
            break;
          default:
            imageType = Icons.fileImage;
            break;
        }

        return (
          <Grid
            key={index}
            item
            lg={3}
            md={4}
            className="container-item"
            onMouseEnter={() => handleToggleHover(index)}
            onMouseLeave={() => handleToggleHoverLeave()}
          >
            <a href={item.url} target="_blank" className="content">
              <img src={imageType} className="custom-image-file" alt="file" />
              {isActiveIndex === index ? (
                <Grid container className="container-floating-button" justify="center">
                  <Grid item lg={10} md={10}>
                    <ButtonMain
                      type="ghost"
                      size="sm"
                      labelText="View Document"
                      onClick={() => {
                        handleButtonClick(item.url);
                      }}
                    />
                  </Grid>
                </Grid>
              ) : null}
            </a>
          </Grid>
        );
      })}
    </Grid>
  );
};

GalleryDocument.propTypes = {
  dataFile: PropTypes.array,
};

export default GalleryDocument;
