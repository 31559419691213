import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Grid, Card, CardContent, CardHeader, Fade } from '@material-ui/core';
// Style
import './CardSubPermissionDetailStyle.scss';
// Component
import { LabelIcon, ModalAlertConfirmation, CheckInput } from '../../../../../../components/Index';
import { PermissionAccess, PermissionModule, PermissionPage } from '../../../../../../helpers/PermissionHelper';

const CardSubPermissionDetail = props => {
  const { onDeletePermission, dataArray, isEdit, onCheckedPermissionAccess } = props;

  const listAccess = dataArray.access;

  const modalAlertOption = {
    title: 'Confirmation',
    text: `Are Your Sure want to DELETE ${dataArray.name} Permission `,
    icon: 'warning',
    successMessage: 'Deleted Permission Success',
  };

  const timeInterval = 300;

  const handleItemChange = itemValue => value => {
    const result = { valueChildren: dataArray.value, value: itemValue, checked: value };
    onCheckedPermissionAccess(result);
  };

  return (
    <Card>
      <CardHeader
        title={dataArray.name}
        action={
          <Fade in={!isEdit} timeout={timeInterval}>
            <div>
              <ModalAlertConfirmation
                icon="ic-ffo-bin"
                onClick={onDeletePermission}
                type="negative"
                size="sm"
                optionModal={modalAlertOption}
                isButtonIcon
                requiredPermission={`${PermissionModule.UserManagement}.${PermissionPage.RolesAndPermissions}.${PermissionAccess.Update}`}
              />
            </div>
          </Fade>
        }
      />
      <CardContent>
        <Grid container direction="column">
          {listAccess.map((item, index) =>
            !isEdit ? (
              <Grid
                item
                lg
                md
                key={index}
                className={`container-sub-row ${clsx({ 'is-in-active': !item.checked })}`}
              >
                <LabelIcon iconPrefix="ic-ffo-check-mark" labelText={item.name} size="md" />
              </Grid>
            ) : (
              <Grid item lg={3} md={3} key={index}>
                <CheckInput
                  currentValue={item.checked}
                  colorCheck="primary"
                  labelText={item.name}
                  onCheck={handleItemChange(item.value)}
                />
              </Grid>
            ),
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

CardSubPermissionDetail.propTypes = {
  dataArray: PropTypes.object,
  isEdit: PropTypes.bool,
  onCheckedPermissionAccess: PropTypes.func,
  onDeletePermission: PropTypes.func,
};

export default CardSubPermissionDetail;
