/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroller';
import _ from 'lodash';
// style
import './CalendarDetailStyle.scss';
import { Grid } from '@material-ui/core';
import { Spin } from 'antd';
// component
import { SkeletonScheduleDailySingle } from '../Index';
// helper
import { CommonHelper } from '../../../../../helpers/Index';
import { ButtonMain } from '../../../../../components/Index';

const CalendarDetail = props => {
  const [selectState, setSelectState] = React.useState('');
  const [selectStateIndexDate, setSelectStateIndexDate] = React.useState('');

  const { dataHeader, dataTimeLine, today, onClickUpdate, onReloadColumn } = props;
  const currentDate = CommonHelper.dateTimeParseNewFormat(today, 'YYYY-MM-DD');

  const handleToggleHover = (keyItem, indexDate) => {
    setSelectState(keyItem);
    setSelectStateIndexDate(indexDate);
  };

  const handleToggleHoverLeave = () => {
    setSelectState('');
    setSelectStateIndexDate('');
  };

  const handleOnReloadColumn = index => {
    let dataCard = [];

    switch (index) {
      case 0:
        dataCard = dataTimeLine.summaryData01;
        break;
      case 1:
        dataCard = dataTimeLine.summaryData02;
        break;
      case 2:
        dataCard = dataTimeLine.summaryData03;
        break;
      case 3:
        dataCard = dataTimeLine.summaryData04;
        break;
      case 4:
        dataCard = dataTimeLine.summaryData05;
        break;
      case 5:
        dataCard = dataTimeLine.summaryData06;
        break;

      default:
        dataCard = dataTimeLine.summaryData07;
        break;
    }

    const page = parseInt(dataCard.length / 10, 10);
    onReloadColumn(index, page + 1);
  };

  const renderScheduleCard = (scheduleList, indexDate) => {
    let renderElement = <SkeletonScheduleDailySingle />;
    if (!_.isEmpty(scheduleList)) {
      renderElement = scheduleList.map((item, index) => {
        let mealTime;
        switch (item.meal_time) {
          case 'breakfast':
            mealTime = 'Breakfast';
            break;
          case 'morning-snack':
            mealTime = 'Morning Snack';
            break;
          case 'lunch':
            mealTime = 'Lunch';
            break;
          case 'afternoon-snack':
            mealTime = 'Afternoon Snack';
            break;
          case 'dinner':
            mealTime = 'Dinner';
            break;
          default:
            break;
        }
        const isEligibleToEdit = item.date >= currentDate;

        return (
          <Grid
            item
            key={index}
            onMouseEnter={() => handleToggleHover(index, indexDate)}
            onMouseLeave={() => handleToggleHoverLeave()}
            className="custom-grid-col-row flex-wrap-unset"
          >
            <div className="calendar-card-meal-plan">
              <Grid className="flex-column mb-8">
                <label
                  className={
                    selectState === index && selectStateIndexDate === indexDate
                      ? 'text-12 hovered wrapping-container'
                      : 'text-12 wrapping-container'
                  }
                >
                  {mealTime}
                </label>
                <label
                  className={
                    selectState === index && selectStateIndexDate === indexDate
                      ? 'text-10 hovered'
                      : 'text-10'
                  }
                >
                  {item.diet_type_description}
                </label>
                <label
                  className={
                    selectState === index && selectStateIndexDate === indexDate
                      ? 'text-10 hovered'
                      : 'text-10'
                  }
                >
                  {item.cuisine_type_description}
                </label>
              </Grid>
              {selectState === index && selectStateIndexDate === indexDate && isEligibleToEdit && (
                <ButtonMain
                  labelText="Update"
                  onClick={() => onClickUpdate(item.date)}
                  type="primary"
                />
              )}
            </div>
          </Grid>
        );
      });
    }

    return renderElement;
  };

  const renderScheduleColumn = () => {
    return dataHeader.map((item, index) => {
      let dataCard = null;
      let moreDataCard = null;

      switch (index) {
        case 0:
          dataCard = dataTimeLine.summaryData01;
          moreDataCard = dataTimeLine.loadMore01;
          break;
        case 1:
          dataCard = dataTimeLine.summaryData02;
          moreDataCard = dataTimeLine.loadMore02;
          break;
        case 2:
          dataCard = dataTimeLine.summaryData03;
          moreDataCard = dataTimeLine.loadMore03;
          break;
        case 3:
          dataCard = dataTimeLine.summaryData04;
          moreDataCard = dataTimeLine.loadMore04;
          break;
        case 4:
          dataCard = dataTimeLine.summaryData05;
          moreDataCard = dataTimeLine.loadMore05;
          break;
        case 5:
          dataCard = dataTimeLine.summaryData06;
          moreDataCard = dataTimeLine.loadMore06;
          break;

        default:
          dataCard = dataTimeLine.summaryData07;
          moreDataCard = dataTimeLine.loadMore07;
          break;
      }

      return (
        <Grid
          key={index}
          item
          className={`custom-grid-col ${
            dataHeader !== undefined ? (currentDate === item.value ? 'is-active' : '') : ''
          }`}
        >
          <div className="scroll-container-invisible container-item-schedule">
            <InfiniteScroll
              pageStart={1}
              loadMore={() => handleOnReloadColumn(index + 1)}
              hasMore={moreDataCard}
              useWindow={false}
              loader={
                <div className="loader" key={index + 1}>
                  <Spin />
                </div>
              }
            >
              <Grid container direction="column">
                {renderScheduleCard(dataCard, index)}
              </Grid>
            </InfiniteScroll>
          </div>
        </Grid>
      );
    });
  };

  return (
    <div className="container-calendar-detail">
      {!_.isEmpty(dataHeader) && <Grid container>{renderScheduleColumn()}</Grid>}
    </div>
  );
};

CalendarDetail.propTypes = {
  dataHeader: PropTypes.array,
  dataTimeLine: PropTypes.object,
  onClickUpdate: PropTypes.func,
  onReloadColumn: PropTypes.func,
  today: PropTypes.string,
};

CalendarDetail.defaultProps = {
  dataHeader: [],
  dataTimeLine: [],
};

export default CalendarDetail;
