import React from 'react';
import PropTypes from 'prop-types';
// component
import { LabelStatusMain } from '../../Index';
import { MasterDataHelper, paymentStatus } from '../../../helpers/Index';

const LabelStatusPayment = props => {
  const { status } = props;

  let labelType = 'default';
  let statusDescription = status
    ? MasterDataHelper.statusPayment.find(x => x.value === status).name
    : MasterDataHelper.statusPayment[0].name;

  switch (status) {
    case paymentStatus.Pending:
    case paymentStatus.AwaitingToken:
    case paymentStatus.CreditCardCharged:
    case paymentStatus.TokenGenerated:
    case paymentStatus.VirtualBankAccountGenerated:
      labelType = 'warning';
      statusDescription = 'Pending';
      break;
    case paymentStatus.Refund:
      labelType = 'warning';
      break;
    case paymentStatus.Cancelled:
    case paymentStatus.Expired:
    case paymentStatus.Declined:
    case paymentStatus.Suspended:
      labelType = 'danger';
      break;
    case paymentStatus.Confirmation:
    case paymentStatus.Complete:
    case paymentStatus.Settlement:
      labelType = 'complete';
      break;
    default:
      labelType = 'default';
      break;
  }

  return (
    <div className="container-status">
      <LabelStatusMain value={statusDescription} type={labelType} />
    </div>
  );
};

LabelStatusPayment.propTypes = {
  status: PropTypes.number,
};

export default LabelStatusPayment;
