import { StyleSheet } from '@react-pdf/renderer';
import Themes from '../../../../../themes/Index';

const StylesJs = StyleSheet.create({
  containerCardLabel: {
    width: '90%',
  },
  containerLabel: {
    height: '60mm',
    borderWidth: 1,
    borderColor: '#000000',
    paddingVertical: 8,
    paddingHorizontal: 8,
    marginBottom: '5mm',
  },
  flex1: {
    flex: 1,
  },
  containerName: {
    marginTop: 10,
  },
  containerLogo: {
    height: 14,
    marginTop: 4,
  },
  textHeader: {
    color: Themes.palette.black,
    fontSize: 16,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
  },
  textRoomName: {
    fontSize: 10,
    fontWeight: 'normal',
    color: Themes.palette.mineShaft,
    fontFamily: 'Roboto',
  },
  textRoomNumber: {
    color: Themes.palette.black,
    fontSize: 10,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
  },
  textCustomerName: {
    color: Themes.palette.black,
    fontSize: 12,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
  },
  textName: {
    color: Themes.palette.black,
    fontSize: 6,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
  },
  textDietType: {
    color: Themes.palette.black,
    fontSize: 5,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
  },
  textHeading: {
    fontSize: 8,
    fontWeight: 'bold',
    color: Themes.palette.mineShaft,
    marginBottom: 2,
    fontFamily: 'Roboto',
  },
  textLabel: {
    fontSize: 8,
    fontWeight: 'normal',
    color: Themes.palette.rollingStone,
    fontFamily: 'Roboto',
  },
  textExtras: {
    fontSize: 8,
    fontWeight: 'bold',
    color: Themes.palette.rollingStone,
    paddingTop: 6,
    fontFamily: 'Roboto',
  },
  text: {
    fontSize: 7,
    fontWeight: 'normal',
    color: Themes.palette.mineShaft,
    fontFamily: 'Roboto',
  },
  textDate: {
    fontSize: 7,
    fontWeight: 'normal',
    color: Themes.palette.mineShaft,
    fontFamily: 'Roboto',
  },
  textPax: {
    fontSize: 7,
    fontWeight: 'normal',
    color: Themes.palette.rollingStone,
    fontFamily: 'Roboto',
  },
  textNotes: {
    fontSize: 9,
    fontWeight: 'normal',
    color: Themes.palette.mineShaft,
    fontFamily: 'Roboto',
  },
  textNotesBold: {
    fontSize: 9,
    fontWeight: 'bold',
    color: Themes.palette.mineShaft,
    fontFamily: 'Roboto',
  },
  textBirthday: {
    fontSize: 7,
    fontWeight: 'normal',
    color: Themes.palette.mineShaft,
    fontFamily: 'Roboto',
    marginTop: 2,
  },
  textSubHeading: {
    fontSize: 7,
    fontWeight: 'bold',
    color: Themes.palette.mineShaft,
    marginBottom: 2,
    fontFamily: 'Roboto',
  },
  textMealName: {
    color: Themes.palette.rollingStone,
    fontSize: 5.5,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
  },
  textSmallHeading: {
    fontSize: 8,
    fontWeight: 'bold',
    color: Themes.palette.rollingStone,
    fontFamily: 'Roboto',
  },
  textSmallNotes: {
    fontSize: 8,
    fontWeight: 'normal',
    color: Themes.palette.mineShaft,
    fontFamily: 'Roboto',
  },
  textRoomType: {
    color: Themes.palette.rollingStone,
    fontSize: 6,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
  },
  textBiggerNotes: {
    fontSize: 12,
    fontWeight: 'normal',
    color: Themes.palette.mineShaft,
    fontFamily: 'Roboto',
  },
});

export default StylesJs;
