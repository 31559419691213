import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import _ from 'lodash';
// Style
import './DetailsAppointmentStyles.scss';

const DetailsAppointment = props => {
  const { orderData } = props;
  const appointmentDetails = !_.isEmpty(orderData) ? orderData : {};

  return (
    <Grid container className="container-appointment-details pt-24" direction="column">
      <Grid item lg={12} md={12} className="pb-16">
        <label className="text-12 text-bold text-rolling-stone">Appointment Details</label>
      </Grid>
      <Grid item lg={12} md={12} className="order-card-details">
        <Grid container direction="column">
          <Grid item lg={12} md={12}>
            <div className="flex-column pb-24">
              <label className="text-12 text-bold text-rolling-stone wrapping-container wrapping-container-break mb-8">
                Product
              </label>
              <label className="text-14 text-semi-bold wrapping-container wrapping-container-break">
                {appointmentDetails.productName}
              </label>
            </div>
          </Grid>
          <Grid item lg={12} md={12} className="pb-24">
            <Grid container>
              <Grid item lg={6} md={6}>
                <div className="flex-column">
                  <label className="text-12 text-bold text-rolling-stone wrapping-container wrapping-container-break mb-8">
                    Date
                  </label>
                  <label className="text-14 text-semi-bold wrapping-container wrapping-container-break">
                    {appointmentDetails.appointmentDate}
                  </label>
                </div>
              </Grid>
              <Grid item lg={6} md={6}>
                <div className="flex-column">
                  <label className="text-12 text-bold text-rolling-stone wrapping-container wrapping-container-break mb-8">
                    Time
                  </label>
                  <label className="text-14 text-semi-bold wrapping-container wrapping-container-break">
                    {appointmentDetails.appointmentTime}
                  </label>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={12} md={12}>
            <div className="flex-column">
              <label className="text-12 text-bold text-rolling-stone mb-8">Location</label>
              <label className="text-14 text-semi-bold wrapping-container third line wrapping-container-break capitalize">
                {appointmentDetails.location}
              </label>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

DetailsAppointment.propTypes = {
  orderData: PropTypes.object,
};

export default DetailsAppointment;
