import React from 'react';
import PropTypes from 'prop-types';
import { Grid, IconButton } from '@material-ui/core';
import { connect } from 'react-redux';
// style
import './ModalImagePreviewStyle.scss';
// component
import { ModalInformationPopUp } from '../../../../../../components/Index';

class ModalImagePreview extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { onClose, isOpen, imageUrl } = this.props;

    const renderElement = (
      <Grid container direction="column" className="flex-wrap-unset container-modal-image-preview">
        <Grid item lg md className="section-header-modal">
          <Grid container justify="space-between">
            <Grid item lg={11} md={11}>
              <h5 className="wrapping-container second line">Image Preview</h5>
            </Grid>
            <Grid item>
              <div className="container-button-close">
                <IconButton onClick={onClose}>
                  <i className="ic-ffo-close container-icon-prefix size-21" />
                </IconButton>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg md className="section-body-modal">
          <div className="container-id-image">
            <img src={imageUrl} alt="ktp" />
          </div>
        </Grid>
      </Grid>
    );

    return (
      <ModalInformationPopUp
        isOpen={isOpen}
        onClose={onClose}
        customElementProps={renderElement}
        size="medium"
      />
    );
  }
}

const mapDispatchToProps = () => ({});

const mapStateToProps = () => ({});

ModalImagePreview.propTypes = {
  imageUrl: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalImagePreview);
