/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { Grid, FormControl } from '@material-ui/core';
import _ from 'lodash';
// Style
import './CartItemEatsStyle.scss';
// Component
import {
  GridRowTableFourColumn,
  GridRowTableOneColumn,
  LabelStatusMain,
} from '../../../../../../../components/Index';
// helper
import { CommonHelper, OrderHelper, eatSessionTypeCode } from '../../../../../../../helpers/Index';
// assets
import { Images } from '../../../../../../../assets/Index';
import {
  paymentMethodOptionCode,
  promoTypeCode,
} from '../../../../../../../helpers/MasterDataHelper';

const CartItemEats = props => {
  const {
    form: {
      items,
      deliveryServiceInfo,
      promoCode,
      listExtra,
      selectedPackage,
      globalExtraValue,
      isFirstTimePurchase,
      membershipInfo,
      paymentMethod,
    },
    isValidPromoCode,
    orderData: { promoCodeDetails },
  } = props;

  let days = 0;
  let date = null;
  items.forEach(item => {
    if (item.date !== date) {
      date = item.date;
      days += 1;
    }
  });

  const isUsingMembership =
    !_.isEmpty(membershipInfo) && paymentMethod === paymentMethodOptionCode.membership;
  const shippingInformation =
    !isUsingMembership && deliveryServiceInfo.cost[0].value > 0
      ? `${days} day(s) x IDR ${CommonHelper.formatCurrency(deliveryServiceInfo.cost[0].value)}`
      : `Free Delivery`;
  const shippingCost = !isUsingMembership ? deliveryServiceInfo.cost[0].value * days : 0;
  // discount
  const promoCodeInformation = `Promo - ${promoCode}`;
  const discountAmount = promoCodeDetails.cashback_amount_desc;

  const showDiscount =
    isValidPromoCode && promoCodeDetails.promo_code_cashback_type_id === promoTypeCode.Discount;

  const checkHasDefaultValue = OrderHelper.validateEatPackageExtraHasDefault(
    selectedPackage.extraSession || [],
  );

  let isFirstTime = selectedPackage.normal_price;
  if (selectedPackage.isContainLunchDinner) {
    isFirstTime = selectedPackage.lunch_and_dinner_price;
  }
  const packPrice = isFirstTime || 0;
  const packType = selectedPackage.pax || 0;
  const packPriceExtra =
    OrderHelper.getTotalExtra(globalExtraValue, listExtra) *
    parseInt(packType, 10) *
    checkHasDefaultValue.total;
  let grandTotalItemExtra = packType * globalExtraValue.length * checkHasDefaultValue.total;
  let grandTotalItem = packType * checkHasDefaultValue.total;
  let grandTotalPrice =
    packPrice * parseInt(packType, 10) * checkHasDefaultValue.total + packPriceExtra + shippingCost;

  if (isValidPromoCode) {
    grandTotalPrice = promoCodeDetails.totalPriceAfterReward + shippingCost;
  }

  if (isUsingMembership) {
    grandTotalPrice = 0;
  }

  const renderSpecialTreatment = () => {
    let passRenderElement = false;

    let wording = null;
    let type = null;
    let icon = null;

    if (isUsingMembership) {
      icon = Images.membershipUsage;
      wording = 'Membership Package Applied';
      type = 'membership';
      passRenderElement = true;
    }

    return passRenderElement ? (
      <span className={`container-special-label flex-row p-8 ${type}`}>
        <img src={icon} alt="membership_logo" />
        <label className="text-14 text-semi-bold ml-8">{wording}</label>
      </span>
    ) : null;
  };

  const renderStatus = value => {
    let renderElement = <LabelStatusMain value="LUNCH" type="warning" />;

    if (value === eatSessionTypeCode.dinner) {
      renderElement = <LabelStatusMain value="DINNER" type="primary" />;
    }

    return <div className="container-status">{renderElement}</div>;
  };

  return (
    <FormControl
      component="fieldset"
      fullWidth
      margin="normal"
      className="container-order-cart-item-eats"
    >
      <div className="flex-column">
        {renderSpecialTreatment()}
        <GridRowTableFourColumn
          customElementColumnFirst={<label className="text-14">Product</label>}
          customElementColumnSecond={
            <div className="text-center">
              <label className="text-14">Extra</label>
            </div>
          }
          customElementColumnThird={
            <div className="text-center">
              <label className="text-14">Quantity</label>
            </div>
          }
          customElementColumnFourth={
            <div className="text-right">
              <label className="text-14">Subtotal</label>
            </div>
          }
          columnFirst={5}
          columnSecond={2}
          columnThird={2}
          columnFourth={3}
        />
        <div className="scroll-container">
          <Grid container direction="column" className="flex-wrap-unset">
            {items.map((item, index) => {
              let selectedPackagePrice =
                !_.isEmpty(selectedPackage) && selectedPackage.normal_price;
              if (selectedPackage.isContainLunchDinner) {
                selectedPackagePrice = selectedPackage.lunch_and_dinner_price;
              }
              const isFirstTimeItem = isFirstTimePurchase ? selectedPackagePrice : item.price;
              const totalExtra =
                OrderHelper.getTotalExtra(item.extras, listExtra) * parseInt(item.qty, 10);
              const totalExtraItem = item.extras ? item.extras.length * parseInt(item.qty, 10) : 0;
              const priceItem =
                (parseFloat(isFirstTimeItem, 0) || 0) * parseInt(item.qty, 10) + totalExtra;
              const modifyDate = CommonHelper.dateTimeParseNewFormat(item.date, 'dd, DD MMM YYYY');
              const wordingExtra = OrderHelper.generateExtraWording(item.extras, listExtra);

              if (_.isEmpty(selectedPackage)) {
                grandTotalItem += parseInt(item.qty, 10);
                grandTotalItemExtra += totalExtraItem;
                if (!isValidPromoCode) {
                  grandTotalPrice += priceItem;
                }
              }

              return (
                <GridRowTableFourColumn
                  key={index}
                  customElementColumnFirst={
                    <div className="flex-column">
                      <label className="text-14 row-item-title wrapping-container mb-8">
                        {item.food_details.name}
                      </label>
                      <span className="flex-row mb-8">
                        <label className="text-10 mr-8">{modifyDate}</label>
                        {renderStatus(item.lunch_or_dinner)}
                      </span>
                      <label className="text-12 mb-8">{item.notes}</label>
                    </div>
                  }
                  customElementColumnSecond={
                    <div className="text-center">
                      <label className="text-14">{wordingExtra}</label>
                    </div>
                  }
                  customElementColumnThird={
                    <div className="text-center">
                      <label className="text-14">{item.qty}</label>
                    </div>
                  }
                  customElementColumnFourth={
                    <div className="text-right">
                      <label className="text-14">
                        {isUsingMembership
                          ? 'Free'
                          : `IDR ${CommonHelper.formatCurrency(priceItem)}`}
                      </label>
                    </div>
                  }
                  columnFirst={5}
                  columnSecond={2}
                  columnThird={2}
                  columnFourth={3}
                />
              );
            })}
          </Grid>
        </div>
        <GridRowTableOneColumn
          customElementInitial={
            <div className="flex-column">
              <label className="text-14 row-item-title">Shipping Fee</label>
              <label className="text-10">{shippingInformation}</label>
            </div>
          }
          customElementColumn={
            <div className="text-right">
              <label className="text-14">
                {shippingCost > 0 ? `IDR ${CommonHelper.formatCurrency(shippingCost)}` : `Free`}
              </label>
            </div>
          }
          columnLeft={10}
          columnRight={2}
        />
        {showDiscount && (
          <GridRowTableOneColumn
            customElementInitial={
              <div className="flex-column">
                <label className="text-14 row-item-title">Discount</label>
                <label className="text-10 ">{promoCodeInformation}</label>
              </div>
            }
            customElementColumn={
              <div className="text-right">
                <label className="text-14 discount">{discountAmount}</label>
              </div>
            }
            columnLeft={10}
            columnRight={2}
          />
        )}
        <GridRowTableOneColumn
          customElementInitial={
            <Grid container>
              <Grid item lg={5} md={5}>
                <label className="text-16 row-item-title">Total Item(s) : {grandTotalItem}</label>
              </Grid>
              <Grid item lg={5} md={5}>
                <label className="text-16 row-item-title">
                  Total Extra : {grandTotalItemExtra}
                </label>
              </Grid>
            </Grid>
          }
          customElementColumn={
            <div className="text-right">
              <label className="text-16 row-item-title">
                Grand Total &nbsp; &nbsp; &nbsp;{' '}
                {isUsingMembership ? `Free` : `IDR ${CommonHelper.formatCurrency(grandTotalPrice)}`}
              </label>
            </div>
          }
          columnLeft={4}
          columnRight={8}
        />
      </div>
    </FormControl>
  );
};

CartItemEats.propTypes = {
  form: PropTypes.object,
  isValidPromoCode: PropTypes.bool,
  orderData: PropTypes.object,
};

export default CartItemEats;
