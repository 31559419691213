import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid, Breadcrumbs, Link, FormControl, FormLabel } from '@material-ui/core';
import { compose } from 'redux';
// style
import './MerchantEditStyle.scss';
// component
import {
  TextInput,
  SnackBarSimple,
  SelectInputCompany,
  ButtonMain,
  RadioInput,
  TextInputPrePost,
  TextAreaMain,
  ModalAlertAction,
  AuthenticationAccessPages,
  PrevStateValue,
  SkeletonDetailsV03
} from '../../../../../components/Index';
// api
import {
  setUpdateMerchant,
  getMerchantDetails,
} from '../../../../../services/api/MasterDataMainAPi';
// helper
import {
  MasterDataHelper,
  CommonHelper,
  ValidationHelper,
  GlobalCodeStatus,
  PermissionModule,
  PermissionPage,
  PermissionAccess,
} from '../../../../../helpers/Index';

const initialValidation = {
  merchantName: { isError: false, errorMessage: '' },
  bankName: { isError: false, errorMessage: '' },
  bankAccountName: { isError: false, errorMessage: '' },
  bankAccountNumber: { isError: false, errorMessage: '' },
  reason: { isError: '', errorMessage: '' },
};

const initialForm = {
  merchantId: null,
  merchantName: '',
  companyId: 0,
  systemIntegrated: null,
  bankName: '',
  bankAccountName: '',
  bankAccountNumber: '',
  commission: 0,
  status: null,
  key: null,
};

const optionToash = {
  vertical: 'top',
  horizontal: 'right',
};

const resetValidation = { isError: false, errorMessage: '' };

const radioButtonOption = MasterDataHelper.radioButtonTrueFalse;
const radioButtonStatusOption = MasterDataHelper.statusGlobal;

class MerchantEdit extends React.Component {
  constructor(props) {
    super(props);

    props.checkUserAccessPermission(
      PermissionModule.MasterData,
      PermissionPage.Merchant,
      PermissionAccess.Update,
    );

    this.state = {
      validation: initialValidation,
      form: initialForm,
      isLoading: false,
      isOpenModal: false,
      lockoutReason: '',
      toastInformation: {
        isOpen: false,
        message: '',
        snackbarType: 'warning',
      },
      modalDetailDataDelete: {
        title: 'Confirmation',
        body: 'Are you sure want to Delete Merchant ',
      },
    };
  }

  componentDidMount() {
    this.getMerchantDetails();
  }

  componentDidUpdate(prevProps) {
    const { masterData } = this.props;

    if (prevProps.masterData.companyList.length !== masterData.companyList.length) {
      this.loadDefaultValue();
    }
  }

  componentWillUnmount() {}

  getMerchantDetails() {
    const {
      merchantDetails,
      match: { params },
    } = this.props;
    const { form } = this.state;
    const merchantId = params.merchant_id;
    const param = { merchant_id: merchantId };

    this.setState({ isLoading: true }, () => {
      merchantDetails(param)
        .then(async response => {
          const data = response.data;
          const index = radioButtonStatusOption.findIndex(item => item.value === data.status);
          const indexSystem = radioButtonOption.findIndex(
            item => item.value === data.is_system_integrated,
          );

          this.setState({
            form: {
              ...form,
              merchantId: data.merchant_id,
              merchantName: data.name,
              companyId: data.company_id,
              bankName: data.bank_name,
              bankAccountName: data.bank_account_name,
              bankAccountNumber: data.bank_account_number,
              commission: data.fitco_commission,
              systemIntegrated: radioButtonOption[indexSystem],
              status: radioButtonStatusOption[index],
              key: data.key,
            },
            isLoading: false,
          });
        })
        .catch(error => {
          const message = error.data;
          this.processMessage(message.messages, 'error');
        });
    });
  }

  handleCloseToash = () => {
    const { toastInformation } = this.state;
    this.setState({ toastInformation: { ...toastInformation, isOpen: false } });
  };

  handleClick = (event, value) => {
    const { history } = this.props;
    event.preventDefault();
    history.push(value);
  };

  handleTextChangeMerchantName = value => {
    const { validation, form } = this.state;

    const message = {
      isError: false,
      errorMessage: '',
    };

    this.setState({
      form: { ...form, merchantName: value },
      validation: { ...validation, merchantName: message },
    });
  };

  handleSelectCompany = value => {
    const { form } = this.state;

    this.setState({
      form: {
        ...form,
        companyId: value || null,
      },
    });
  };

  handleSelectSystemIntegrated = value => {
    const { form } = this.state;
    this.setState({ form: { ...form, systemIntegrated: value } });
  };

  handleSelectStatus = value => {
    const { form } = this.state;
    this.setState({ form: { ...form, status: value } });
  };

  handleTextChangeBankName = value => {
    const { validation, form } = this.state;

    const message = {
      isError: false,
      errorMessage: '',
    };

    this.setState(
      {
        form: { ...form, bankName: value },
      },
      () => {
        if (!value) {
          message.isError = true;
          message.errorMessage = 'Bank Name is Empty...';
        }
        this.setState({
          validation: { ...validation, bankName: message },
        });
      },
    );
  };

  handleTextChangeBankAccountName = value => {
    const { validation, form } = this.state;

    const message = {
      isError: false,
      errorMessage: '',
    };

    this.setState(
      {
        form: { ...form, bankAccountName: value },
      },
      () => {
        if (!value) {
          message.isError = true;
          message.errorMessage = 'Bank Account Name is Empty...';
        }
        this.setState({
          validation: { ...validation, bankAccountName: message },
        });
      },
    );
  };

  handleTextChangeBankAccountNumber = value => {
    const { validation, form } = this.state;
    const checkIsNumber = CommonHelper.checkNumberOnly(value.trim());

    const message = {
      isError: false,
      errorMessage: '',
    };

    if (checkIsNumber) {
      this.setState({
        form: { ...form, bankAccountNumber: value.trim() },
        validation: { ...validation, bankAccountNumber: message },
      });
    } else {
      message.isError = true;
      message.errorMessage = 'Bank Account Number must number';

      if (!value) {
        message.isError = true;
        message.errorMessage = 'Bank Account Number must number and not empty...';
      }
      this.setState({
        validation: { ...validation, bankAccountNumber: message },
      });
    }
  };

  handleTextChangeFitcoCommision = value => {
    const { form } = this.state;

    const commissionValidation = ValidationHelper.validateNumberPercentage(value.trim());

    if (commissionValidation) {
      this.setState({
        form: { ...form, commission: parseInt(value.trim(), 10) },
      });
    } else {
      let forceValue = 0;
      if (parseInt(value, 10) > 100) forceValue = 100;

      this.setState({
        form: { ...form, commission: forceValue },
      });
    }
  };

  handleTextChangeReason = value => {
    const { validation } = this.state;
    const message = {
      isError: '',
      errorMessage: '',
    };

    this.setState({
      lockoutReason: value,
      validation: { ...validation, reason: message },
    });
  };

  handleButtonSubmitDelete = () => {
    const { lockoutReason, validation } = this.state;

    if (!lockoutReason || lockoutReason === '') {
      const message = { isError: 'error', errorMessage: 'Reason Still Empty' };

      this.setState({
        validation: { ...validation, reason: message },
      });
    } else {
      this.processUpdateMerchant();
    }
  };

  handleCloseModalDelete = () => {
    const { validation } = this.state;
    const message = { isError: '', errorMessage: '' };

    this.setState({
      isOpenModal: false,
      lockoutReason: '',
      validation: { ...validation, reason: message },
    });
  };

  handleButtonCancel = () => {
    const { history } = this.props;
    history.push('/master-data/main-data');
  };

  handleButtonSubmit = () => {
    const { form, modalDetailDataDelete } = this.state;

    if (this.validationEmptyField()) {
      if (form.status.value === GlobalCodeStatus.Deleted) {
        const message = `Are you sure want to Delete Merchant ${form.merchantName}`;

        this.setState({
          isOpenModal: true,
          modalDetailDataDelete: { ...modalDetailDataDelete, body: message },
        });
      } else this.processUpdateMerchant();
    }
  };

  processUpdateMerchant() {
    const { updateMerchant, history } = this.props;
    const { form, lockoutReason } = this.state;

    const statusCode = form.status.value;
    const systemIntegrated = form.systemIntegrated.value;

    const param = {
      data: {
        merchant_id: form.merchantId,
        company_id: form.companyId,
        name: form.merchantName.trim(),
        fitco_commission: form.commission,
        bank_name: form.bankName.trim(),
        bank_account_name: form.bankAccountName.trim(),
        bank_account_number: form.bankAccountNumber,
        is_system_integrated: systemIntegrated,
        status: statusCode,
        lockout_reason: lockoutReason || null,
      },
    };
    const key = form.key;

    this.setState({ isLoading: true }, () => {
      updateMerchant(param, key)
        .then(response => {
          const message = response.messages;

          this.processMessage(message, 'success');

          this.setState({ isLoading: false, isOpenModal: false }, () => {
            setTimeout(() => {
              history.push('/master-data/main-data');
            }, 2000);
          });
        })
        .catch(error => {
          const message = error.data;
          this.setState({ isLoading: false });
          this.processMessage(message.messages, 'error');
        });
    });
  }

  loadDefaultValue() {
    const { form } = this.state;
    const { masterData } = this.props;
    if (form.companyId === null) {
      this.setState({ form: { ...form, companyId: masterData.companyList[0].company_id } });
    }
  }

  processMessage(messages, type) {
    const convertedMessage = CommonHelper.generateMessage(messages);

    const paramInformation = {
      isOpen: true,
      message: convertedMessage,
      snackbarType: type,
    };

    this.setState({
      toastInformation: paramInformation,
    });
  }

  validationEmptyField() {
    const {
      form: { merchantName, bankName, bankAccountName, bankAccountNumber },
      validation,
    } = this.state;

    let passCheck = true;
    let paramValidation01 = resetValidation;
    let paramValidation02 = resetValidation;
    let paramValidation03 = resetValidation;
    let paramValidation04 = resetValidation;

    if (merchantName === undefined || merchantName === '') {
      if (!paramValidation01.isError) {
        paramValidation01 = {
          isError: true,
          errorMessage: 'merchantName Empty...',
        };
      }
      passCheck = false;
    }

    if (bankName === undefined || bankName === '') {
      if (!paramValidation02.isError) {
        paramValidation02 = {
          isError: true,
          errorMessage: 'Bank Name Empty...',
        };
      }
      passCheck = false;
    }

    if (bankAccountName === undefined || bankAccountName === '') {
      paramValidation03 = {
        isError: true,
        errorMessage: 'Bank Account Name Empty',
      };
      passCheck = false;
    }

    if (bankAccountNumber === undefined || bankAccountNumber === '') {
      paramValidation04 = {
        isError: true,
        errorMessage: 'Bank Account Number Empty',
      };
      passCheck = false;
    }

    if (!passCheck) {
      this.setState({
        validation: {
          ...validation,
          merchantName: paramValidation01,
          bankName: paramValidation02,
          bankAccountName: paramValidation03,
          bankAccountNumber: paramValidation04,
        },
      });
    }

    return passCheck;
  }

  renderModalDelete() {
    const { isOpenModal, isLoading, lockoutReason } = this.state;
    let elementRender = null;

    if (isOpenModal) {
      elementRender = (
        <ModalAlertAction
          onButtonSubmit={this.handleButtonSubmitDelete}
          onCloseModal={this.handleCloseModalDelete}
          modalDetail={this.state.modalDetailDataDelete}
          modalType="danger"
          buttonSubmitText="Submit"
          openModal={isOpenModal}
          isLoading={isLoading}
          customElementProps={
            <TextAreaMain
              onChange={this.handleTextChangeReason}
              placeholder="Reason"
              rows={3}
              currentValue={lockoutReason}
              validateStatus={this.state.validation.reason.isError}
              errorMessage={this.state.validation.reason.errorMessage}
            />
          }
        />
      );
    }

    return elementRender;
  }

  render() {
    const { toastInformation, isLoading } = this.state;
    let renderElement = <SkeletonDetailsV03 />;

    if (!isLoading) {
      renderElement = (
        <div className="container-page-merchant-edit  scroll-container">
          <div className="container-page-scrolling-area">
            <Grid container direction="column">
              <Grid item lg md className="section-page-header">
                <Grid container>
                  <Grid item>
                    <div className="breadcrumbs-section">
                      <Breadcrumbs aria-label="breadcrumb">
                        <Link
                          className="text-12"
                          color="inherit"
                          href="/master-data/main-data"
                          onClick={event => {
                            this.handleClick(event, '/master-data/main-data');
                          }}
                        >
                          <i className="ic-ffo-merchant container-icon-prefix size-16" /> Main Data
                        </Link>
                        <label className="text-12" color="inherit">
                          Edit Merchant
                        </label>
                      </Breadcrumbs>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item lg md className="section-page-body">
                <div className="merchant-details-card">
                  <Grid container spacing={3}>
                    <Grid item lg={6} md={6}>
                      <Grid container direction="column">
                        <Grid item className="main-title">
                          <label className="text-16">Merchant Information</label>
                        </Grid>
                        <Grid item>
                          <FormControl component="fieldset" fullWidth margin={'normal'}>
                            <FormLabel component="label" className="text-12 title">
                              Merchant Name
                            </FormLabel>
                            <TextInput
                              placeHolderText="Merchant Name"
                              onChange={this.handleTextChangeMerchantName}
                              currentValue={this.state.form.merchantName}
                              errorMessage={this.state.validation.merchantName.errorMessage}
                              isError={this.state.validation.merchantName.isError}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item>
                          <FormControl component="fieldset" fullWidth margin={'normal'}>
                            <FormLabel component="label" className="text-12 title">
                              Company Name
                            </FormLabel>
                            <div className="container-remove-margin">
                              <SelectInputCompany
                                placeHolder="Select Company"
                                currentValue={this.state.form.companyId}
                                onChange={this.handleSelectCompany}
                              />
                            </div>
                          </FormControl>
                        </Grid>
                        <Grid item>
                          <FormControl component="fieldset" fullWidth margin={'normal'}>
                            <FormLabel component="label" className="text-12 title">
                              Status
                            </FormLabel>
                            <RadioInput
                              data={radioButtonStatusOption}
                              onSelect={this.handleSelectStatus}
                              direction="column"
                              currentValue={this.state.form.status}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item>
                          <FormControl component="fieldset" fullWidth margin={'normal'}>
                            <FormLabel component="label" className="text-12 title">
                              System Integrated
                            </FormLabel>
                            <Grid container direction="column">
                              <Grid item>
                                <div className="container-remove-margin">
                                  <RadioInput
                                    data={radioButtonOption}
                                    onSelect={this.handleSelectSystemIntegrated}
                                    direction="column"
                                    currentValue={this.state.form.systemIntegrated}
                                  />
                                </div>
                              </Grid>
                              <Grid item>
                                <label className="text-12 note">
                                  {`If set to Yes, all 'MOVE' bookings made to this merchant will be
                                automatically set into 'Confirmed' status, otherwise it will be on
                                'Waiting Confirmation' status`}
                                </label>
                              </Grid>
                            </Grid>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item lg={6} md={6}>
                      <Grid item className="main-title">
                        <label className="text-16">Bank Information</label>
                      </Grid>
                      <Grid item>
                        <FormControl component="fieldset" fullWidth margin={'normal'}>
                          <FormLabel component="label" className="text-12 title">
                            Bank Name
                          </FormLabel>
                          <TextInput
                            placeHolderText="Bank Name"
                            onChange={this.handleTextChangeBankName}
                            currentValue={this.state.form.bankName}
                            errorMessage={this.state.validation.bankName.errorMessage}
                            isError={this.state.validation.bankName.isError}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item>
                        <FormControl component="fieldset" fullWidth margin={'normal'}>
                          <FormLabel component="label" className="text-12 title">
                            Bank Account Name
                          </FormLabel>
                          <TextInput
                            placeHolderText="Bank Account Name"
                            onChange={this.handleTextChangeBankAccountName}
                            currentValue={this.state.form.bankAccountName}
                            errorMessage={this.state.validation.bankAccountName.errorMessage}
                            isError={this.state.validation.bankAccountName.isError}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item>
                        <FormControl component="fieldset" fullWidth margin={'normal'}>
                          <FormLabel component="label" className="text-12 title">
                            Bank Account Number
                          </FormLabel>
                          <TextInput
                            placeHolderText="Bank Account Number"
                            onChange={this.handleTextChangeBankAccountNumber}
                            currentValue={this.state.form.bankAccountNumber}
                            errorMessage={this.state.validation.bankAccountNumber.errorMessage}
                            isError={this.state.validation.bankAccountNumber.isError}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item>
                        <FormControl component="fieldset" fullWidth margin={'normal'}>
                          <FormLabel component="label" className="text-12 title">
                            Fitco Commission
                          </FormLabel>
                          <TextInputPrePost
                            placeHolderText="Fitco Commission"
                            onChange={this.handleTextChangeFitcoCommision}
                            currentValue={this.state.form.commission}
                            customElementsAfter={'%'}
                            size="large"
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
              <Grid item lg md className="section-page-footer">
                <Grid container justify="flex-end" spacing={3}>
                  <Grid item lg={2} md={2}>
                    <ButtonMain
                      type="ghost"
                      size="xl"
                      labelText="Cancel"
                      onClick={this.handleButtonCancel}
                    />
                  </Grid>
                  <Grid item lg={2} md={2}>
                    <ButtonMain
                      type="primary"
                      size="xl"
                      labelText="Save"
                      onClick={this.handleButtonSubmit}
                      isLoading={this.state.isLoading}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {this.renderModalDelete()}
            <SnackBarSimple
              open={toastInformation.isOpen}
              durationHide={2000}
              message={toastInformation.message}
              onClickClose={this.handleCloseToash}
              snackbarType={toastInformation.snackbarType}
              anchor={optionToash}
            />
          </div>
        </div>
      );
    }

    return renderElement;
  }
}

const mapDispatchToProps = dispatch => ({
  updateMerchant: (params, key) => setUpdateMerchant(dispatch, params, key),
  merchantDetails: params => getMerchantDetails(dispatch, params),
});

const mapStateToProps = ({ masterData }) => ({ masterData });

MerchantEdit.propTypes = {
  checkUserAccessPermission: PropTypes.func,
  history: PropTypes.object,
  masterData: PropTypes.object,
  match: PropTypes.object,
  merchantDetails: PropTypes.func,
  updateMerchant: PropTypes.func,
};

const shell = compose(connect(mapStateToProps, mapDispatchToProps));
const core = compose(AuthenticationAccessPages, PrevStateValue);

export default shell(core(MerchantEdit));
