const Icons = {
  fitPoints: '../../src/assets/icons/svg/fit_points.svg',
  scheduleBooked: '../../src/assets/icons/svg/fit_points.svg',
  membershipPurchase: '../../src/assets/icons/svg/fit_points.svg',
  topUp: '../../src/assets/icons/svg/fit_points.svg',
  eatsPurchase: '../../src/assets/icons/svg/fit_points.svg',
  shopsPurchase: '../../src/assets/icons/svg/fit_points.svg',
  fileImage: '../../src/assets/icons/svg/ic-doc-jpg.svg',
  fileDocument: '../../src/assets/icons/svg/ic-doc-pdf.svg',
};

export default Icons;
