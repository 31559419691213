import React from 'react';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import _ from 'lodash';
// component
import { GridRowTableOneColumn } from '../../../../../components/Index';
import { SkeletonTopPT } from '../Index';
// Helper
import { CommonHelper } from '../../../../../helpers/Index';
// Action
import { getTopConductPT } from '../../../../../services/api/ReportApi';
// style
import './CardConductPTTopStyle.scss';

const initialFilter = {
  ranking: 6,
};

class CardConductPTTop extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: CommonHelper.objectCloning(initialFilter),
    };
  }

  componentDidUpdate(prevProps) {
    const { parentFilter } = this.props;

    const isEqual = _.isEqual(parentFilter, prevProps.parentFilter);

    if (parentFilter) {
      if (!isEqual) {
        this.setUpdateExistingFilter();
      }
    }
  }

  setUpdateExistingFilter() {
    const { parentFilter } = this.props;
    const { filter } = this.state;
    this.setState({ filter: { ...filter, ...parentFilter } }, () => {
      this.getTopConductPT();
    });
  }

  getTopConductPT = () => {
    const {
      topConductPT,
      reportData: { fetchingTopConduct },
    } = this.props;
    const {
      filter: { startDate, endDate, ranking, branchId, merchantId },
    } = this.state;

    const params = {
      start_date: startDate,
      end_date: endDate,
      merchant_id: merchantId,
      branch_id: branchId,
      ranking,
    };

    if (!fetchingTopConduct && merchantId && startDate && endDate) {
      topConductPT(params);
    }
  };

  render() {
    const {
      reportData: { fetchingTopConduct, topPTList },
    } = this.props;

    return (
      <Grid container direction="column" className="container-page-conduct-pt-top flex-wrap-unset">
        <Grid item lg md>
          <label className="text-12 text-bold">Top PT Conduct</label>
        </Grid>
        <Grid item lg md className="header">
          <GridRowTableOneColumn
            customElementInitial={
              <label className="text-10 text-rolling-stone mb-4">Personal Trainer</label>
            }
            customElementColumn={
              <div className="text-center">
                <label className="text-10 text-rolling-stone mb-4">Total Conduct</label>
              </div>
            }
            columnLeft={7}
            columnRight={5}
          />
        </Grid>
        {fetchingTopConduct ? (
          <SkeletonTopPT />
        ) : (
          topPTList.map((item, index) => {
            return (
              <GridRowTableOneColumn
                key={index}
                customElementInitial={
                  <label className="text-12 text-bold">{item.trainer_name}</label>
                }
                customElementColumn={
                  <div className="text-center">
                    <label className="text-12 text-bold">{item.total_appointment}</label>
                  </div>
                }
                columnLeft={7}
                columnRight={5}
              />
            );
          })
        )}
      </Grid>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  topConductPT: params => getTopConductPT(dispatch, params),
});

const mapStateToProps = ({ reportData }) => ({
  reportData,
});

CardConductPTTop.propTypes = {
  parentFilter: PropTypes.object,
  reportData: PropTypes.object,
  topConductPT: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(CardConductPTTop);
