import React from 'react';
import PropTypes from 'prop-types';
import { Grid, FormControl } from '@material-ui/core';
// Style
import './CartItemRetailsStyle.scss';
// Component
import {
  GridRowTableFourColumn,
  GridRowTableOneColumn,
} from '../../../../../../../components/Index';
import { CommonHelper, OrderHelper, promoTypeCode } from '../../../../../../../helpers/Index';
// helper

const CartItemRetails = props => {
  const {
    form: { items, deliveryServiceInfo, promoCode, isReseller },
    isValidPromoCode,
    orderData: { promoCodeDetails },
  } = props;

  const shippingMethod = deliveryServiceInfo.description;
  const shippingDuration = deliveryServiceInfo.cost[0].etd;
  const shippingInformation = `${shippingMethod} - approximately ${shippingDuration} days`;
  const shippingCost = CommonHelper.formatCurrency(deliveryServiceInfo.cost[0].value);
  // discount
  const promoCodeInformation = `Promo - ${promoCode}`;
  const totalItem = OrderHelper.getGrandTotalWeightAndFitMarket(items).item;
  let grandTotal = OrderHelper.getGrandTotal(items, deliveryServiceInfo.cost[0].value);
  const discountAmount = promoCodeDetails.cashback_amount_desc;

  if (isValidPromoCode) {
    grandTotal = promoCodeDetails.totalPriceAfterReward + deliveryServiceInfo.cost[0].value;
  }

  const showDiscount =
    isValidPromoCode && promoCodeDetails.promo_code_cashback_type_id === promoTypeCode.Discount;

  let totalWholesaleDiscount = 0;

  if (isReseller) {
    items.forEach(item => {
      let itemWholesaleDiscount = 0;
      item.wholesale_discount.forEach(record => {
        if (item.qty >= record.minimum_quantity) {
          itemWholesaleDiscount = Math.floor(
            (parseInt(item.price, 10) * item.qty * parseFloat(record.price_discount)) / 100,
          );
        }
      });
      totalWholesaleDiscount += itemWholesaleDiscount;
      grandTotal -= itemWholesaleDiscount;
    });
  }

  grandTotal = CommonHelper.formatCurrency(grandTotal);

  return (
    <FormControl
      component="fieldset"
      fullWidth
      margin="normal"
      className="container-order-cart-item"
    >
      <GridRowTableFourColumn
        customElementColumnFirst={<label className="text-14">Product</label>}
        customElementColumnSecond={<label className="text-14">Price</label>}
        customElementColumnThird={<label className="text-14">Quantity</label>}
        customElementColumnFourth={<label className="text-14">Subtotal</label>}
        columnFirst={5}
        columnSecond={2}
        columnThird={2}
        columnFourth={3}
      />
      <div className="scroll-container">
        <Grid container direction="column">
          {items.map((item, index) => {
            const priceTotal = CommonHelper.formatCurrency(item.qty * parseInt(item.price, 10));
            const priceItem = CommonHelper.formatCurrency(item.price);

            return (
              <GridRowTableFourColumn
                key={index}
                customElementColumnFirst={
                  <label className="text-14 row-item-title">{item.name}</label>
                }
                customElementColumnSecond={<label className="text-14">IDR {priceItem}</label>}
                customElementColumnThird={<label className="text-14">{item.qty}</label>}
                customElementColumnFourth={<label className="text-14">IDR {priceTotal}</label>}
                columnFirst={5}
                columnSecond={2}
                columnThird={2}
                columnFourth={3}
              />
            );
          })}
        </Grid>
      </div>
      <GridRowTableOneColumn
        customElementInitial={
          <div className="flex-column">
            <label className="text-14 row-item-title">Shipping Fee</label>
            <label className="text-10">{shippingInformation}</label>
          </div>
        }
        customElementColumn={
          <label className="text-14">{shippingCost > 0 ? `IDR ${shippingCost}` : `Free`}</label>
        }
        columnLeft={10}
        columnRight={2}
      />
      {showDiscount && (
        <GridRowTableOneColumn
          customElementInitial={
            <div className="flex-column">
              <label className="text-14 row-item-title">Discount</label>
              <label className="text-10 ">{promoCodeInformation}</label>
            </div>
          }
          customElementColumn={<label className="text-14 discount">{discountAmount}</label>}
          columnLeft={10}
          columnRight={2}
        />
      )}
      {totalWholesaleDiscount > 0 && (
        <GridRowTableOneColumn
          customElementInitial={
            <div className="flex-column">
              <label className="text-14 row-item-title">Discount</label>
              <label className="text-10 ">Wholesale discount</label>
            </div>
          }
          customElementColumn={
            <label className="text-14 discount">
              IDR {CommonHelper.formatCurrency(totalWholesaleDiscount)}
            </label>
          }
          columnLeft={10}
          columnRight={2}
        />
      )}
      <GridRowTableOneColumn
        customElementInitial={
          <label className="text-16 row-item-title">Total Item(s) : {totalItem}</label>
        }
        customElementColumn={
          <label className="text-16 row-item-title">
            Grand Total &nbsp; &nbsp; &nbsp; IDR {grandTotal}
          </label>
        }
        columnLeft={4}
        columnRight={8}
      />
    </FormControl>
  );
};

CartItemRetails.propTypes = {
  form: PropTypes.object,
  isValidPromoCode: PropTypes.bool,
  orderData: PropTypes.object,
};

export default CartItemRetails;
