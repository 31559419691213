import React from 'react';
import PropTypes from 'prop-types';
import { Grid, FormControl } from '@material-ui/core';
import _ from 'lodash';
// Style
import './ItemOrderEatStyle.scss';
// Component
import {
  LabelStatusMain,
  TextInputNumber,
  CheckInputButton,
  TextAreaMain,
} from '../../../../../components/Index';
// helper
import { CommonHelper, eatSessionTypeCode, OrderHelper } from '../../../../../helpers/Index';

const ItemOrderEat = props => {
  const { data, dataExtra, onButtonNumberStepper, onButtonAddExtra, onHandleNotesField } = props;

  const dateMenuReady = CommonHelper.dateTimeParseNewFormat(data.date, 'ddd, DD MMM YYYY');

  const handleButtonAddExtra = (cateringScheduleId, date) => value => {
    onButtonAddExtra(cateringScheduleId, date, value);
  };

  const handleAddNotes = (cateringScheduleId, date) => value => {
    onHandleNotesField(cateringScheduleId, date, value);
  };

  const renderStatus = value => {
    let renderElement = <LabelStatusMain value="LUNCH" type="warning" />;

    if (value === eatSessionTypeCode.dinner) {
      renderElement = <LabelStatusMain value="DINNER" type="primary" />;
    }

    return <div className="container-status">{renderElement}</div>;
  };

  const renderItem = () => {
    return data.data.map((itemChildren, index) => {
      const totalEachItem = parseInt(itemChildren.qty, 10) * parseFloat(itemChildren.price, 0);
      const totalExtra =
        OrderHelper.getTotalExtra(itemChildren.extras, dataExtra) * parseInt(itemChildren.qty, 10);

      const grandTotalEachItem = totalExtra + totalEachItem;
      const converted = [];
      const existingItem = CommonHelper.objectCloning(dataExtra);

      existingItem.forEach(itemExtra => {
        itemExtra.value = itemExtra.product_id;
        itemExtra.isVisible = false;
        converted.push(itemExtra);
      });

      const imageDisplay = !_.isEmpty(itemChildren.food_details.images)
        ? itemChildren.food_details.images[0].image_url
        : '';

      return (
        <Grid key={index} item lg md className="container-body-order-eat mt-16">
          <div className="flex-column">
            <Grid container alignItems="center">
              <Grid item lg={6} md={6}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item>
                    <img src={imageDisplay} className="custom-image" alt="item_image" />
                  </Grid>
                  <Grid item lg md>
                    <div className="flex-column">
                      <label className="text-16 main text-bold mb-4 wrapping-container">
                        {itemChildren.food_details.name}
                      </label>
                      <label className="text-12 mb-8">
                        Product ID: {itemChildren.food_details.product_id}
                      </label>
                      <span className="flex-row">
                        {renderStatus(itemChildren.lunch_or_dinner)}
                        <label className="text-10 ml-8 text-bold">
                          {itemChildren.food_details.calories} Cals
                        </label>
                      </span>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item lg={2} md={2} className="text-center">
                <label className="text-14">
                  IDR {CommonHelper.formatCurrency(itemChildren.price)}
                </label>
              </Grid>
              <Grid item lg={1} md={1}>
                <TextInputNumber
                  placeHolderText={'Qty'}
                  currentValue={itemChildren.qty}
                  onChange={onButtonNumberStepper(itemChildren.catering_schedule_id, data.date)}
                />
              </Grid>
              <Grid item lg={3} md={3} className="text-right">
                <label className="text-14 mr-16">
                  IDR {CommonHelper.formatCurrency(totalEachItem)}
                </label>
              </Grid>
            </Grid>
            <Grid container justify="space-between" alignItems="center" className="pv-16">
              <Grid item lg={7} md={7}>
                <CheckInputButton
                  title="Add Extras"
                  data={itemChildren.extras}
                  dataOption={converted}
                  disabled={!(itemChildren.qty > 0)}
                  onButtonClickAdd={handleButtonAddExtra(
                    itemChildren.catering_schedule_id,
                    data.date,
                  )}
                />
              </Grid>
              <Grid item lg={3} md={3} className="text-right">
                <label className="text-14 text-bold mr-16">
                  IDR {CommonHelper.formatCurrency(grandTotalEachItem)}
                </label>
              </Grid>
            </Grid>
            <Grid item lg={7} md={7} className="pb-16">
              <Grid container direction="row" alignItems="center">
                <Grid item className="notes-container">
                  <label className="text-14 title">Add Notes</label>
                </Grid>
                <Grid item className="notes-container">
                  <FormControl component="fieldset" fullWidth margin="dense">
                    <TextAreaMain
                      onChange={handleAddNotes(itemChildren.catering_schedule_id, data.date)}
                      placeholder="Add Notes"
                      rows={3}
                      maxLength={250}
                      isDisable={!(itemChildren.qty > 0)}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Grid>
      );
    });
  };

  return (
    <Grid container direction="column" className="container-item-order-eat p-16 flex-wrap-unset">
      <Grid item lg md className="container-header-order-eat">
        <Grid container>
          <Grid item lg={6} md={6}>
            <label className="text-14 title text-bold">{dateMenuReady}</label>
          </Grid>
          <Grid item lg={2} md={2} className="text-center">
            <label className="text-12 title">Price</label>
          </Grid>
          <Grid item lg={1} md={1} className="text-center">
            <label className="text-12 title">Quantity</label>
          </Grid>
          <Grid item lg={3} md={3} className="text-right">
            <label className="text-12 mr-16 title">Total</label>
          </Grid>
        </Grid>
      </Grid>
      {renderItem()}
    </Grid>
  );
};

ItemOrderEat.propTypes = {
  data: PropTypes.object,
  dataExtra: PropTypes.array,
  onButtonAddExtra: PropTypes.func,
  onButtonNumberStepper: PropTypes.func,
  onHandleNotesField: PropTypes.func,
};

export default ItemOrderEat;
