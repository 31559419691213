/* eslint-disable consistent-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { Tabs } from 'antd';
import { EditOutlined, SaveOutlined } from '@ant-design/icons';
import { TextInput, SelectInputMain } from '../../../../components/Index';
import './EventParticipantModal.scss';

const { TabPane } = Tabs;

const tabFieldsMapping = {
  'race-related-information': ['ticket_category', 'shirt_size', 'bib_name', 'bib_number'],
  'personal-information': [
    'email',
    'phone',
    'address',
    'occupation',
    'organization',
    'emergency_contact_name',
    'emergency_contact_phone',
  ],
  'health-questionaire': ['questionaires'],
};

const editableFields = [
  'email',
  'phone',
  'emergency_contact_name',
  'emergency_contact_phone',
  'shirt_size',
  'bib_name',
  'bib_number',
];

const fieldsToRender = [
  { label: 'Full Name', key: 'name' },
  { label: 'Race Category', key: 'ticket_category' },
  { label: 'Gender', key: 'gender' },
  { label: 'Nationality', key: 'nationality' },
  { label: 'Date of Birth', key: 'date_of_birth' },
  { label: 'Identity Number', key: 'identity_number' },
  { label: 'Registration ID', key: 'registration_id' },
];

const shirtSizeOption = [
  { value: 'XS', name: 'XS' },
  { value: 'S', name: 'S' },
  { value: 'M', name: 'M' },
  { value: 'L', name: 'L' },
  { value: 'XL', name: 'XL' },
  { value: '2XL', name: '2XL' },
  { value: '3XL', name: '3XL' },
];

const EventParticipantModal = props => {
  const { loading, data } = props;
  const [defaultActiveKey] = useState('race-related-information');
  const [fields, setFields] = useState({});

  const updateFieldsWithDetails = details => {
    const updatedFields = {};

    const flattenDetails = (obj, prefix = '') => {
      Object.keys(obj).forEach(key => {
        const value = obj[key];
        const prefixedKey = prefix ? (prefix === 'details' ? `${key}` : `${prefix}_${key}`) : key;

        if (typeof value === 'object' && value !== null) {
          if (Array.isArray(value)) {
            updatedFields[prefixedKey] = {
              value,
              isEditing: false,
            };
          } else {
            flattenDetails(value, prefixedKey, true);
          }
        } else {
          updatedFields[prefixedKey] = {
            value,
            isEditing: false,
          };
        }
      });
    };

    flattenDetails(details);

    setFields(updatedFields);
    props.onSave(updatedFields);
  };

  const handleEditToggle = fieldKey => {
    if (!editableFields.includes(fieldKey)) return;

    setFields(prevFields => {
      const isEditingNow = !prevFields[fieldKey].isEditing;
      props.onEditing(isEditingNow);
      return {
        ...prevFields,
        [fieldKey]: {
          ...prevFields[fieldKey],
          isEditing: isEditingNow,
        },
      };
    });
  };

  const handleFieldChange = (e, fieldKey) => {
    if (!editableFields.includes(fieldKey)) return;

    setFields(prevFields => {
      const updatedFields = {
        ...prevFields,
        [fieldKey]: {
          ...prevFields[fieldKey],
          value: e,
        },
      };

      props.onSave(updatedFields);
      return updatedFields;
    });
  };

  const handleUpdateQuestionnaires = updatedQuestionnaires => {
    setFields(prevFields => {
      const updatedFields = {
        ...prevFields,
        questionaires: {
          ...prevFields.questionaires,
          value: updatedQuestionnaires,
        },
      };

      props.onSave(updatedFields);
      return updatedFields;
    });
  };

  useEffect(() => {
    if (data) {
      updateFieldsWithDetails(data);
    }
  }, [data]);

  if (loading) return <p>Loading...</p>;

  return (
    <Grid container spacing={2} className="container-modal-participant-details">
      {fieldsToRender.map(({ label, key }) => (
        <FieldDisplay key={key} label={label} value={fields[key].value} />
      ))}
      <Tabs defaultActiveKey={defaultActiveKey} className="custom-tabs">
        {Object.keys(tabFieldsMapping).map(tabKey => (
          <TabPane
            tab={tabKey.replace(/-/g, ' ').replace(/\b\w/g, char => char.toUpperCase())}
            key={tabKey}
          >
            {tabKey === 'health-questionaire' ? (
              <HealthQuestionnaire
                questionnaires={
                  Array.isArray(fields.questionaires.value) ? fields.questionaires.value : []
                }
                onEditing={props.onEditing}
                onUpdate={handleUpdateQuestionnaires}
              />
            ) : (
              tabFieldsMapping[tabKey].map(fieldKey => {
                const field = fields[fieldKey] || {};
                const fieldValue = field.value;

                return (
                  <EditableField
                    key={fieldKey}
                    fieldKey={fieldKey}
                    label={fieldKey.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase())}
                    value={fieldValue}
                    editable={editableFields.includes(fieldKey)}
                    isEditing={field.isEditing || false}
                    isEligibleToEdit={fields.claimed && fields.claimed.value}
                    onChange={e => handleFieldChange(e, fieldKey)}
                    onEditToggle={() => handleEditToggle(fieldKey)}
                  />
                );
              })
            )}
          </TabPane>
        ))}
      </Tabs>
    </Grid>
  );
};

EventParticipantModal.propTypes = {
  data: PropTypes.object,
  loading: PropTypes.bool,
};

export default EventParticipantModal;

const FieldDisplay = ({ label, value }) => {
  return (
    <Grid item lg={6} md={6} sm={6}>
      <Grid container spacing={2}>
        <Grid item lg={6} md={6} sm={6}>
          <label className="modal-title">{label}</label>
        </Grid>
        <Grid item lg={6} md={6} sm={6}>
          <label className="modal-value">{value || '-'}</label>
        </Grid>
      </Grid>
    </Grid>
  );
};

const EditableField = ({
  fieldKey,
  label,
  value,
  editable,
  isEditing,
  isEligibleToEdit,
  onChange,
  onEditToggle,
}) => {
  const hideEditBtn = fieldKey === 'shirt_size' && Boolean(isEligibleToEdit);

  const renderElement = () => {
    if (isEditing && editable) {
      if (fieldKey === 'shirt_size') {
        return (
          <div style={{ width: '100%' }}>
            <SelectInputMain options={shirtSizeOption} currentValue={value} onChange={onChange} />
          </div>
        );
      }

      return (
        <TextInput
          placeHolderText={`Enter ${label}`}
          currentValue={value}
          size="md"
          onChange={onChange}
        />
      );
    }

    return <label className="modal-value">{value || '-'}</label>;
  };

  const renderButton = () => {
    if (hideEditBtn) return;
    if (!editable) return;

    return (
      <button type="button" className="modal-action-button" onClick={onEditToggle}>
        {isEditing ? <SaveOutlined /> : <EditOutlined />}
      </button>
    );
  };

  return (
    <Grid container spacing={2}>
      <Grid item lg={6} md={6} sm={6}>
        <label className="modal-title">{label}</label>
      </Grid>
      <Grid item lg md sm>
        <div className="editable-field-container">
          {renderElement()}
          {renderButton()}
          {/* {editable && (
            <button type="button" className="modal-action-button" onClick={onEditToggle}>
              {isEditing ? <SaveOutlined /> : <EditOutlined />}
            </button>
          )} */}
        </div>
      </Grid>
    </Grid>
  );
};

const HealthQuestionnaire = ({ questionnaires, onEditing, onUpdate }) => {
  const [questionnaireState, setQuestionnaireState] = useState(
    Array.isArray(questionnaires)
      ? questionnaires.map(question => ({ ...question, isEditing: false, error: '' }))
      : [],
  );

  const handleAnswerChange = (e, index, field) => {
    const { value } = e.target;
    const updatedQuestionnaires = [...questionnaireState];

    if (updatedQuestionnaires[index].type === 'radio') {
      if (field === 'answer') {
        updatedQuestionnaires[index].answer = value;

        if (value === 'yes' && !updatedQuestionnaires[index].notes) {
          updatedQuestionnaires[index].error = 'Notes are required when Yes is selected';
        } else {
          updatedQuestionnaires[index].error = '';
        }

        if (value === 'no') {
          updatedQuestionnaires[index].notes = '';
          updatedQuestionnaires[index].error = '';
        }
      } else if (field === 'notes') {
        updatedQuestionnaires[index].notes = value;

        if (updatedQuestionnaires[index].answer === 'yes' && !value) {
          updatedQuestionnaires[index].error = 'Notes are required when Yes is selected';
        } else {
          updatedQuestionnaires[index].error = '';
        }
      }
    } else if (updatedQuestionnaires[index].type === 'field') {
      updatedQuestionnaires[index].answer = value;
    }

    setQuestionnaireState(updatedQuestionnaires);
    onUpdate(updatedQuestionnaires);
  };

  const handleEditToggle = index => {
    const updatedQuestionnaires = [...questionnaireState];

    if (updatedQuestionnaires[index].error) {
      return;
    }

    updatedQuestionnaires[index].isEditing = !updatedQuestionnaires[index].isEditing;
    const isEditingNow = updatedQuestionnaires.some(q => q.isEditing);
    onEditing(isEditingNow);
  };

  return (
    <Grid container spacing={2} style={{ marginBottom: '10px' }}>
      {questionnaireState.map((question, index) => (
        <Grid item xs={12} key={index}>
          <label className="modal-title">{question.question}</label>

          <div className="editable-field-container" style={{ marginTop: '10px' }}>
            {question.isEditing ? (
              <div style={{ width: '100%' }}>
                {question.type === 'radio' && (
                  <div className="radio-group">
                    <div className="radio-label-container">
                      <input
                        type="radio"
                        id={`${question.name}_yes`}
                        name={question.name}
                        value="yes"
                        checked={question.answer === 'yes'}
                        onChange={e => handleAnswerChange(e, index, 'answer')}
                      />
                      <label htmlFor={`${question.name}_yes`} className="radio-label">
                        Yes
                      </label>
                    </div>

                    <div className="radio-label-container">
                      <input
                        type="radio"
                        id={`${question.name}_no`}
                        name={question.name}
                        value="no"
                        checked={question.answer === 'no'}
                        onChange={e => handleAnswerChange(e, index, 'answer')}
                      />
                      <label htmlFor={`${question.name}_no`} className="radio-label">
                        No
                      </label>
                    </div>
                  </div>
                )}

                {question.type === 'field' && (
                  <div style={{ marginTop: '10px' }}>
                    <textarea
                      placeholder="Please declare"
                      value={question.answer}
                      onChange={e => handleAnswerChange(e, index, 'answer')}
                      rows="3"
                      style={{
                        width: '100%',
                        padding: '8px',
                        borderRadius: '5px',
                        border: '1px solid #ccc',
                      }}
                    />
                  </div>
                )}

                {question.type === 'radio' && question.answer === 'yes' && (
                  <div style={{ marginTop: '10px' }}>
                    <textarea
                      placeholder="Please describe your conditions clearly"
                      value={question.notes || ''}
                      onChange={e => handleAnswerChange(e, index, 'notes')}
                      rows="3"
                      style={{
                        width: '100%',
                        padding: '8px',
                        borderRadius: '5px',
                        border: '1px solid #ccc',
                      }}
                    />
                    {question.error && <p style={{ color: 'red' }}>{question.error}</p>}
                  </div>
                )}
              </div>
            ) : (
              <div className="flex-column" style={{ width: '100%' }}>
                <label className="modal-value">{question.answer}</label>
                {question.type === 'radio' && question.answer === 'yes' && (
                  <div style={{ marginTop: '10px' }}>
                    <label className="modal-value">Notes: {question.notes || '-'}</label>
                  </div>
                )}
              </div>
            )}

            <button
              type="button"
              className="modal-action-button"
              disabled={!!question.error}
              style={{ marginLeft: '10px' }}
              onClick={() => handleEditToggle(index)}
            >
              {question.isEditing ? <SaveOutlined /> : <EditOutlined />}
            </button>
          </div>
        </Grid>
      ))}
    </Grid>
  );
};
