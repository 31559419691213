import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import _ from 'lodash';
// component
import { HeaderPurchaseOrderPage } from './components/Index';
import { AuthenticationAccessPages, PrevStateValue } from '../../../components/Index';
// view
import { PurchaseOrderPage } from './Index';
// helper
import {
  MasterDataHelper,
  PermissionModule,
  PermissionPage,
  permissionAccessPurchaseOrder,
} from '../../../helpers/Index';

const dataTabs = MasterDataHelper.purchaseOrderTab;

class PurchaseOrderMainPage extends React.Component {
  constructor(props) {
    super(props);
    const {
      usersReducer: { prevStateValue },
      checkUserAccessPermissionAction,
    } = props;

    this.state = {
      tabsValue: prevStateValue.activeTab || 0,
      isButtonCreatePurchaseDisable: !checkUserAccessPermissionAction(
        PermissionModule.Orders,
        PermissionPage.PurchaseOrder,
        permissionAccessPurchaseOrder.CreatePurchaseOrder,
      ),
    };
  }

  componentDidMount() {
    const {
      onResetPrevStateValue,
      history: { location },
    } = this.props;
    onResetPrevStateValue({ url: location.pathname });
  }

  componentWillReceiveProps(nextProps) {
    const { usersReducer } = nextProps;

    if (_.isEmpty(usersReducer.prevStateValue)) {
      this.handleTabChange(0);
    }
  }

  handleTabChange = value => {
    const {
      onSetPrevStateValueTabs,
      history: { location },
    } = this.props;

    this.setState({ tabsValue: value }, () => {
      onSetPrevStateValueTabs({ activeTab: value, url: location.pathname });
    });
  };

  handleButtonCreatePurchaseOrder = () => {
    const { history } = this.props;

    history.push('/order/purchase-order/create');
  };

  renderPages = () => {
    const { tabsValue } = this.state;
    const { history } = this.props;
    const Status = dataTabs[tabsValue].value;

    let renderElement = null;

    switch (dataTabs[tabsValue]) {
      default:
        renderElement = <PurchaseOrderPage history={history} pageTab={Status} {...this.props} />;
        break;
    }

    return renderElement;
  };

  render() {
    const { isButtonCreatePurchaseDisable } = this.state;

    return (
      <div>
        <Helmet title={`FITCO | Orders - Purchase Order`} />
        <div>
          <HeaderPurchaseOrderPage
            data={dataTabs}
            currentTab={this.state.tabsValue}
            onChangeTab={this.handleTabChange}
            onButtonClickPO={this.handleButtonCreatePurchaseOrder}
            disabled={isButtonCreatePurchaseDisable}
          />
          {this.renderPages()}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = () => ({});

const mapStateToProps = () => ({});

PurchaseOrderMainPage.propTypes = {
  checkUserAccessPermissionAction: PropTypes.func,
  history: PropTypes.object,
  onResetPrevStateValue: PropTypes.func,
  onSetPrevStateValueTabs: PropTypes.func,
  usersReducer: PropTypes.object,
};

const shell = compose(connect(mapStateToProps, mapDispatchToProps));
const core = compose(AuthenticationAccessPages, PrevStateValue);

export default shell(core(PurchaseOrderMainPage));
