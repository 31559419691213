import _ from 'lodash';

const IconOption = ['ic-ffo-delivery-date', 'ic-ffo-pin', 'ic-ffo-timer'];

const IconHelper = {
  checkActiveIconOrder(param) {
    const splitValue = param.split(' ');
    let result = '';

    splitValue.every(itemSplit => {
      result = IconOption.filter(item => item.includes(itemSplit.toLowerCase()));
      if (!_.isEmpty(result)) {
        return false;
      }
      return true;
    });

    return result;
  },
};

export { IconHelper, IconOption };
