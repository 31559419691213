/* eslint-disable react/no-access-state-in-setstate */
import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { Table } from 'antd';
import { connect } from 'react-redux';
import _ from 'lodash';
// Style
import './CardRecentCheckInListStyle.scss';
// component
import { GeneratorExcel } from '../../../../components/Index';
import { CommonHelper } from '../../../../helpers/Index';
// api
import { getRecentCheckInListPagination } from '../../../../services/api/DashboardApi';

const initialSort = {
  sortField: 'customer_name',
  sortOrder: 'asc',
};

class CardRecentCheckInList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: {},
      limit: 5,
      pagination: {
        hideOnSinglePage: true,
        showSizeChanger: false,
        pageSizeOptions: ['5'],
      },
      sort: CommonHelper.objectCloning(initialSort),
    };
  }

  componentDidUpdate(prevProps) {
    const { parentFilter } = this.props;

    const isEqual = _.isEqual(parentFilter, prevProps.parentFilter);

    if (parentFilter) {
      if (!isEqual) {
        this.setUpdateExistingFilter();
      }
    }
  }

  setUpdateExistingFilter() {
    const { parentFilter } = this.props;
    const { filter } = this.state;
    this.setState({ filter: { ...filter, ...parentFilter } }, () => {
      this.getRecentCheckInListPagination();
    });
  }

  getRecentCheckInListPagination() {
    const {
      filter: { startDate, endDate, branchId },
      limit,
      sort,
    } = this.state;
    const {
      recentCheckInListPagination,
      dashboardData: { fetchingListRecurring },
    } = this.props;

    const params = {
      start_date: startDate,
      end_date: endDate,
      limit,
      branch_id: branchId,
      sort: sort.sortField,
      sortOrder: sort.sortOrder,
    };

    if (!fetchingListRecurring && startDate && endDate) {
      recentCheckInListPagination(params);
    }
  }

  handleTableChange = (pagination, filters, sorter) => {
    const { sort } = this.state;

    this.setState(
      {
        pagination: {
          ...this.state.pagination,
          current: pagination.current,
          pageSize: pagination.pageSize,
        },
        sort: {
          ...sort,
          sortField: sorter.field || initialSort.sortField,
          sortOrder: sorter.order === 'ascend' || !sorter.order ? 'asc' : 'desc',
        },
      },
      () => {
        this.getRecentCheckInListPagination();
      },
    );
  };

  renderColumns = () => {
    const activeColumn = [
      {
        title: 'Customer',
        dataIndex: 'customer_name',
        sorter: true,
        render: (text, row) => {
          return (
            <div className="flex-column">
              <label className="text-12 text-semi-bold wrapping-container second line wrapping-container-break mb-8">
                {row.customer_name}
              </label>
              <label className="text-10 text-semi-bold text-cornflowerBlue">
                {row.product_name}
              </label>
            </div>
          );
        },
      },
      {
        title: 'Product',
        dataIndex: 'product_name',
        width: '22%',
        align: 'center',
        sorter: true,
        render: (text, row) => {
          const wordingClassType = CommonHelper.renameRemoveCharacter(row.product_type, '_');
          return (
            <div className="flex-column">
              <label className="text-12 text-semi-bold mb-8">{wordingClassType}</label>
              <label className="text-10 text-semi-bold ">{row.product_name}</label>
            </div>
          );
        },
      },
      {
        title: 'Check In Time',
        dataIndex: 'latest_time_checkin',
        width: '20%',
        align: 'center',
        sorter: true,
        render: (text, row) => {
          const wordingDate =
            CommonHelper.dateTimeParseNewFormat(row.latest_time_checkin, 'LT / DD MMM YYYY') || '-';
          return (
            <div className="flex-column">
              <label className="text-12 text-semi-bold mb-8">{row.branch_name}</label>
              <label className="text-10 text-semi-bold text-rolling-stone">{wordingDate}</label>
            </div>
          );
        },
      },
      {
        title: 'Total Check In',
        dataIndex: 'total_appointment',
        width: '10%',
        align: 'center',
        sorter: true,
        render: (text, row) => {
          return <label className="text-12 text-semi-bold mb-8">{row.total_appointment}</label>;
        },
      },
    ];

    return activeColumn;
  };

  render() {
    const { filter, pagination } = this.state;
    const {
      dashboardData: { fetchingCheckIn, listCheckIn },
    } = this.props;

    let dataPrint = [];

    if (!_.isEmpty(listCheckIn)) {
      dataPrint = listCheckIn;
    }

    return (
      <div className="container-recent-check-in-card container-main-card flex-column pv-24">
        <div className="ph-16">
          <Grid container className="flex-wrap-unset" justify="space-between" alignItems="center">
            <Grid item>
              <label className="text-14 text-semi-bold">Recent Check-In</label>
            </Grid>
            <Grid item>
              <GeneratorExcel
                buttonSize="xl"
                dataSetArray={dataPrint}
                fileName={`user_recent_check_in${filter.startDate}_${filter.endDate}`}
                iconPrefix="ic-ffo-download"
                isButtonIcon
              />
            </Grid>
          </Grid>
        </div>
        <Table
          columns={this.renderColumns()}
          rowKey={(record, key) => parseInt(`${record.user_id}${record.product_id}${key}`, 10)}
          dataSource={listCheckIn}
          pagination={pagination}
          loading={fetchingCheckIn}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  recentCheckInListPagination: params => getRecentCheckInListPagination(dispatch, params),
});

const mapStateToProps = ({ dashboardData }) => ({
  dashboardData,
});

CardRecentCheckInList.propTypes = {
  dashboardData: PropTypes.object,
  parentFilter: PropTypes.object,
  recentCheckInListPagination: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(CardRecentCheckInList);
