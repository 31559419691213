import React from 'react';
import PropTypes from 'prop-types';
import { Paper, Grid } from '@material-ui/core';
// style
import './HeaderInboxStyle.scss';
// component
import { TabNavigation, ButtonMain } from '../../../../components/Index';
import { PermissionAccess, PermissionModule, PermissionPage } from '../../../../helpers/PermissionHelper';

const HeaderInbox = props => {
  const { onChangeTab, data, currentTab, onButtonClickInbox } = props;

  const handleChangeTab = value => {
    onChangeTab(value);
  };

  const handleButtonClickBranch = value => {
    onButtonClickInbox(value);
  };

  return (
    <Paper square className="container-tab-header-inbox">
      <Grid container>
        <Grid item lg md>
          <TabNavigation data={data} onChange={handleChangeTab} currentTab={currentTab} />
        </Grid>
        <Grid item lg={3} md={3} className="container-header-button">
          <ButtonMain
            labelText="Create Inbox"
            onClick={handleButtonClickBranch}
            type="primary"
            size="md"
            startIcon="ic-ffo-add"
            requiredPermission={`${PermissionModule.Marketing}.${PermissionPage.Inbox}.${PermissionAccess.Add}`}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

HeaderInbox.propTypes = {
  currentTab: PropTypes.number,
  data: PropTypes.array,
  onButtonClickInbox: PropTypes.func,
  onChangeTab: PropTypes.func,
};

export default HeaderInbox;
