/* eslint-disable react/no-access-state-in-setstate */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Table } from 'antd';
import _ from 'lodash';
import { Grid, Fade } from '@material-ui/core';
// style
import './MerchantPageStyle.scss';
// component
import {
  TextInput,
  SnackBarSimple,
  ButtonIconMain,
  ModalAlertAction,
  LabelStatusMain,
  SelectInputCompany,
  TextAreaMain,
  PrevStateValue,
  SkeletonPagination
} from '../../../../../components/Index';
// api
import {
  getListMerchantPagination,
  setDeleteMerchant,
} from '../../../../../services/api/MasterDataMainAPi';
// helper
import { CommonHelper, PermissionAccess, PermissionModule, PermissionPage, UserStatus } from '../../../../../helpers/Index';
import { compose } from 'redux';

const initialFilter = {
  search: '',
  companyId: null,
};

const initialValidation = {
  reason: { isError: '', errorMessage: '' },
};

const initialSort = {
  sortField: 'name',
  sortOrder: '',
};

const optionToash = {
  vertical: 'top',
  horizontal: 'right',
};

const timeInterval = 300;

class MerchantPage extends React.Component {
  constructor(props) {
    super(props);
    const {
      usersReducer: { prevStateValue },
    } = props;

    const updateExitingFilter = { ...CommonHelper.objectCloning(initialFilter), ...prevStateValue };

    this.state = {
      filter: updateExitingFilter,
      page: 1,
      limit: 10,
      sort: CommonHelper.objectCloning(initialSort),
      validation: CommonHelper.objectCloning(initialValidation),
      pagination: {
        showSizeChanger: true,
        showTotal: (total, range) => `Showing ${range[0]}-${range[1]} From ${total}`,
        pageSizeOptions: ['10', '20', '30', '40'],
      },
      selectedDataDelete: null,
      selectedId: null,
      loading: true,
      isLoading: true,
      isOpenModalDelete: false,
      lockoutReason: '',
      toastInformation: {
        isOpen: false,
        message: '',
        snackbarType: 'warning',
      },
      modalDetailDataDelete: {
        title: 'Confirmation',
        body: 'Are you sure want to Merchant ',
      },
    };
    this.searchDebounce = _.debounce(this.searchDebounce, 400);
  }

  componentDidMount() {
    this.getListMerchantPagination();
  }

  componentWillReceiveProps(nextProps) {
    const { usersReducer } = nextProps;
    const {
      onSetPrevStateValue,
      history: { location },
    } = this.props;

    if (_.isEmpty(usersReducer.prevStateValue)) {
      const newValue = {
        url: location.pathname,
      };

      onSetPrevStateValue(newValue);

      this.setState({
        filter: CommonHelper.objectCloning(initialFilter),
      });
    }
  }

  getListMerchantPagination = () => {
    const { listMerchantPagination } = this.props;
    const {
      limit,
      pagination,
      page,
      filter: { search, companyId },
      sort,
    } = this.state;

    const params = {
      search,
      company_id: companyId,
      page,
      limit,
      sort: sort.sortField,
      sortOrder: sort.sortOrder,
    };

    this.setState({ loading: true }, () => {
      listMerchantPagination(params).then(response => {
        this.setState({
          loading: false,
          isLoading: false,
          selectedId: null,
          pagination: { ...pagination, pageSize: limit, total: response.total },
        });
      });
    });
  };

  setDeleteMerchant(param = {}, key = null) {
    const { deleteMerchant } = this.props;

    deleteMerchant(param, key)
      .then(async response => {
        const message = response.messages;

        this.handleCloseModalDelete();
        await this.getListMerchantPagination();
        await this.processMessage(message, 'success');
      })
      .catch(error => {
        const message = error.data;
        this.processMessage(message.messages, 'error');
      });
  }

  handleChangeSearch = value => {
    const { filter } = this.state;

    this.setState(
      {
        filter: { ...filter, search: value },
        page: 1,
        pagination: { ...this.state.pagination, current: 1 },
      },
      () => {
        this.searchDebounce();
      },
    );
  };

  handleMouseEnter = value => {
    this.setState({ selectedId: value.merchant_id });
  };

  handleMouseLeave = () => {
    this.setState({ selectedId: null });
  };

  handleCloseToash = () => {
    const { toastInformation } = this.state;
    this.setState({ toastInformation: { ...toastInformation, isOpen: false } });
  };

  handleCloseModalDelete = () => {
    const { validation } = this.state;
    const message = { isError: '', errorMessage: '' };

    this.setState({
      isOpenModalDelete: false,
      lockoutReason: '',
      validation: { ...validation, reason: message },
    });
  };

  handleSelectCompany = value => {
    const { filter } = this.state;
    const { onSetPrevStateValue } = this.props;
    const companyId = value || null;

    onSetPrevStateValue({ ...filter, companyId });
    this.setState(
      {
        filter: {
          ...filter,
          companyId,
        },
        page: 1,
        pagination: { ...this.state.pagination, current: 1 },
      },
      () => {
        this.getListMerchantPagination();
      },
    );
  };

  handleButtonEdit = value => {
    const { history } = this.props;
    history.push(`/master-data/main-data/merchant/edit/${value}`);
  };

  handleClickRow = value => {
    const { history } = this.props;
    history.push(`/master-data/main-data/merchant/details/${value}`);
  };

  handleButtonDeleted = data => {
    const { modalDetailDataDelete } = this.state;

    this.setState({
      isOpenModalDelete: true,
      selectedDataDelete: data,
      modalDetailDataDelete: {
        ...modalDetailDataDelete,
        body: `Are you sure want to delete Company ${data.name} ? `,
      },
    });
  };

  handleButtonSubmitDelete = () => {
    const { lockoutReason, validation, selectedDataDelete } = this.state;

    if (!lockoutReason || lockoutReason === '') {
      const message = { isError: 'error', errorMessage: 'Reason Still Empty' };

      this.setState({
        validation: { ...validation, reason: message },
      });
    } else {
      const param = {
        data: {
          merchant_id: selectedDataDelete.merchant_id,
          lockout_reason: lockoutReason,
        },
      };
      const key = selectedDataDelete.key;
      this.setDeleteMerchant(param, key);
    }
  };

  handleTableChange = (pagination, filters, sorter) => {
    const { sort } = this.state;

    this.setState(
      {
        limit: pagination.pageSize,
        pagination: {
          ...this.state.pagination,
          current: pagination.current,
          pageSize: pagination.pageSize,
        },
        sort: {
          ...sort,
          sortField: sorter.field,
          sortOrder: sorter.order === 'ascend' ? 'asc' : 'desc',
        },
        page: pagination.current,
      },
      () => {
        this.getListMerchantPagination();
      },
    );
  };

  handleTextChangeReason = value => {
    const { validation } = this.state;
    const message = {
      isError: '',
      errorMessage: '',
    };

    this.setState({
      lockoutReason: value,
      validation: { ...validation, reason: message },
    });
  };

  searchDebounce = () => {
    const { filter } = this.state;
    const { onSetPrevStateValue } = this.props;

    onSetPrevStateValue({ ...filter });
    this.getListMerchantPagination();
  };

  processMessage(messages, type) {
    const convertedMessage = CommonHelper.generateMessage(messages);

    const paramInformation = {
      isOpen: true,
      message: convertedMessage,
      snackbarType: type,
    };

    this.setState({
      toastInformation: paramInformation,
    });
  }

  renderModalDelete() {
    const { isOpenModalDelete, isLoading, lockoutReason } = this.state;
    let elementRender = null;

    if (isOpenModalDelete) {
      elementRender = (
        <ModalAlertAction
          onButtonSubmit={this.handleButtonSubmitDelete}
          onCloseModal={this.handleCloseModalDelete}
          modalDetail={this.state.modalDetailDataDelete}
          modalType="danger"
          buttonSubmitText="Submit"
          openModal={isOpenModalDelete}
          isLoading={isLoading}
          customElementProps={
            <TextAreaMain
              onChange={this.handleTextChangeReason}
              placeholder="Reason"
              rows={3}
              currentValue={lockoutReason}
              validateStatus={this.state.validation.reason.isError}
              errorMessage={this.state.validation.reason.errorMessage}
            />
          }
        />
      );
    }

    return elementRender;
  }

  renderStatus = value => {
    let renderElement = <LabelStatusMain value="Active" type="complete" />;
    if (value === UserStatus.Deleted) {
      renderElement = <LabelStatusMain value="Deleted" type="danger" />;
    } else if (value === UserStatus.Inactive) {
      renderElement = <LabelStatusMain value="Inactive" type="default" />;
    }
    return <div className="container-status">{renderElement}</div>;
  };

  renderColumns = () => {
    const { selectedId } = this.state;

    return [
      {
        title: 'Merchant Name',
        dataIndex: 'name',
        sorter: true,
        render: (text, row) => {
          return (
            <Grid
              container
              direction="column"
              className="container-row-merchant flex-wrap-unset"
              onClick={() => {
                this.handleClickRow(row.merchant_id);
              }}
            >
              <Grid item lg md className="container-info-header">
                <div className="flex-column">
                  <span className="flex-row">
                    <label className="text-14 title wrapping-container">{row.name}</label>
                    {this.renderStatus(row.status)}
                  </span>
                  <label className="text-10">ID : {row.merchant_id}</label>
                </div>
              </Grid>
              <Grid item lg md>
                <Grid container justify="flex-start" className="container-info-body">
                  <Grid item lg={3} md={4}>
                    <div className="flex-column">
                      <label className="text-12">Company</label>
                      <span className="flex-row">
                        <i className="ic-ffo-merchant container-icon-prefix size-16" />
                        <label className="text-12 wrapping-container">{row.company_name}</label>
                      </span>
                    </div>
                  </Grid>
                  <Grid item lg={3} md={4}>
                    <div className="flex-column">
                      <label className="text-12">Branch</label>
                      <span className="flex-row">
                        <i className="ic-ffo-merchant container-icon-prefix size-16" />
                        <label className="text-12 wrapping-container">{`${row.branches} Total Branches`}</label>
                      </span>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          );
        },
      },
      {
        width: '15%',
        align: 'center',
        render: (text, row) => {
          const inSelect = row.merchant_id === selectedId;

          return (
            <Fade in={inSelect} timeout={timeInterval}>
              <Grid container justify="center">
                <Grid item>
                  <ButtonIconMain
                    icon="ic-ffo-edit"
                    type="secondary"
                    size="sm"
                    onClick={() => this.handleButtonEdit(row.merchant_id)}
                    requiredPermission={`${PermissionModule.MasterData}.${PermissionPage.Merchant}.${PermissionAccess.Update}`}
                  />
                </Grid>
                <Grid item>
                  <ButtonIconMain
                    icon="ic-ffo-bin"
                    type="negative"
                    size="sm"
                    onClick={() => this.handleButtonDeleted(row)}
                    requiredPermission={`${PermissionModule.MasterData}.${PermissionPage.Merchant}.${PermissionAccess.Delete}`}
                  />
                </Grid>
              </Grid>
            </Fade>
          );
        },
      },
    ];
  };

  renderFilter() {
    const {
      filter: { search, companyId },
    } = this.state;

    return (
      <Grid container direction="row" justify="flex-start" className="container-filter">
        <Grid item lg={4} md={4} className="row-filter">
          <TextInput
            iconPrefix="ic-ffo-search"
            placeHolderText="Search"
            currentValue={search || ''}
            onChange={this.handleChangeSearch}
            size="md"
          />
        </Grid>
        <Grid item lg={3} md={3} className="row-filter">
          <div className="container-remove-margin">
            <SelectInputCompany
              placeHolder="Select Company"
              onChange={this.handleSelectCompany}
              currentValue={companyId}
              filter={this.state.filter}
              includeAllData
            />
          </div>
        </Grid>
      </Grid>
    );
  }

  render() {
    const { masterDataMain } = this.props;
    const { isLoading, loading, pagination, toastInformation } = this.state;
    // eslint-disable-next-line no-unused-vars
    const adjustTableHeight = window.innerHeight - 350;
    const ListCustom = masterDataMain.list;

    let renderElement = (
      <div className="mt-18">
        <SkeletonPagination />
      </div>
    );

    if (!isLoading) {
      renderElement = (
            <Grid container direction="column">
              <Grid item lg md sm className="section-page-header">
                {this.renderFilter()}
              </Grid>
              <Grid item lg md className="section-table-body">
                <Table
                  columns={this.renderColumns()}
                  rowKey={record => record.merchant_id}
                  dataSource={ListCustom}
                  pagination={pagination}
                  loading={loading}
                  // scroll={{ y: adjustTableHeight }}
                  onChange={this.handleTableChange}
                  onRow={record => {
                    return {
                      onMouseEnter: () => {
                        this.handleMouseEnter(record);
                      }, // mouse enter row
                      onMouseLeave: () => {
                        this.handleMouseLeave();
                      },
                    };
                  }}
                />
              </Grid>
            </Grid>
      );
    }
    return (
      <div className="container-page-merchant scroll-container">
        <div className="container-page-scrolling-area include-tab">
          {renderElement}
        </div>
        {this.renderModalDelete()}
          <SnackBarSimple
            open={toastInformation.isOpen}
            durationHide={2000}
            message={toastInformation.message}
            onClickClose={this.handleCloseToash}
            snackbarType={toastInformation.snackbarType}
            anchor={optionToash}
          />
      </div>
      );
  }
}

const mapDispatchToProps = dispatch => ({
  listMerchantPagination: params => getListMerchantPagination(dispatch, params),
  deleteMerchant: (params, key) => setDeleteMerchant(dispatch, params, key),
});

const mapStateToProps = ({ masterDataMain }) => ({
  masterDataMain,
});

MerchantPage.propTypes = {
  deleteMerchant: PropTypes.func,
  history: PropTypes.object,
  listMerchantPagination: PropTypes.func,
  masterDataMain: PropTypes.object,
  onSetPrevStateValue: PropTypes.func,
  usersReducer: PropTypes.object,
};

const shell = compose(connect(mapStateToProps, mapDispatchToProps));

export default shell(PrevStateValue(MerchantPage));
