import React from 'react';
import PropTypes from 'prop-types';
import { Page, Text, View, Document, Font, Image } from '@react-pdf/renderer';
import _ from 'lodash';
// Style
import StylesJs from './EatCaptainOrderInvoiceStyle';
// assets
import { Images, StyleJs as StyleJsGlobal } from '../../../../../assets/Index';
// Helpers
import { CommonHelper, paymentMethodOptionCode } from '../../../../../helpers/Index';

// Register font
Font.register({
  family: 'Roboto',
  fonts: [
    { src: './src/assets/fonts/montserrat/Montserrat-Regular.ttf' },
    { src: './src/assets/fonts/montserrat/Montserrat-Bold.ttf', fontWeight: 700 },
  ],
});

const EatCaptainOrderInvoice = props => {
  const { data } = props;

  const letterheadLogo = Images.fitcoLogoLocal;
  const customerDetails = !_.isEmpty(data.customer_details) ? data.customer_details : {};
  const orderDetails = !_.isEmpty(data.order_details) ? data.order_details : {};
  const orderSummary = !_.isEmpty(data.order_summary) ? data.order_summary : {};
  const paymentDetails = !_.isEmpty(data.payment_details) ? data.payment_details : {};

  const itemsForPrinting =
    data.items_for_printing && !_.isEmpty(data.items_for_printing) ? data.items_for_printing : [];
  const finalDataToPrint = !_.isEmpty(itemsForPrinting)
    ? itemsForPrinting
    : orderSummary.product_details;

  // Calculate all total quantity
  let totalQty = 0;
  finalDataToPrint.forEach(item => {
    const qty = item.quantity - item.details.served;
    totalQty += qty;
  });

  const isNewOrder =
    _.isEmpty(itemsForPrinting) || itemsForPrinting.length === orderSummary.product_details.length;
  const textTitle = isNewOrder ? 'New Order' : 'Additional Order';

  let additionalPaymentText = null;
  let additionalPaymentValue = null;

  switch (paymentDetails.payment_type) {
    case paymentMethodOptionCode.gofood:
      additionalPaymentText = 'GoFood Ref.';
      additionalPaymentValue = paymentDetails.transaction_id;
      break;
    case paymentMethodOptionCode.grabfood:
      additionalPaymentText = 'GrabFood Ref.';
      additionalPaymentValue = paymentDetails.transaction_id;
      break;
    case paymentMethodOptionCode.shopeefood:
      additionalPaymentText = 'ShopeeFood Ref.';
      additionalPaymentValue = paymentDetails.transaction_id;
      break;
    case paymentMethodOptionCode.shopeepay:
      additionalPaymentText = 'ShopeePay Ref.';
      additionalPaymentValue = paymentDetails.transaction_id;
      break;
    default:
      break;
  }

  return (
    <Document>
      <Page size={['55mm', 'auto']} orientation="portrait">
        <View style={[StyleJsGlobal.gridColumn, StyleJsGlobal.ph8, StyleJsGlobal.pv20]}>
          <View style={{ flex: 1, alignItems: 'center' }}>
            <Image src={letterheadLogo} style={[StylesJs.containerLogo]} />
            <Text
              style={[
                StylesJs.text,
                StylesJs.fontBold,
                StyleJsGlobal.mh8,
                { textTransform: 'uppercase' },
              ]}
            >
              {textTitle}
            </Text>
          </View>
          <View style={[StyleJsGlobal.ph4, StyleJsGlobal.pv4]}>
            <View style={[StyleJsGlobal.gridRow, StyleJsGlobal.justifyContentSpaceBetween]}>
              <View style={[StyleJsGlobal.gridItem6]}>
                <Text style={[StylesJs.text, { fontWeight: '600' }]}>{'Date & Time'}</Text>
              </View>
              <View style={[StyleJsGlobal.gridItem6, { flex: 1, alignItems: 'flex-end' }]}>
                <Text style={[StylesJs.text, { fontWeight: '600' }]}>
                  {CommonHelper.dateTimeParseNewFormat(
                    orderDetails.order_date,
                    'DD MMM YYYY, HH:mm',
                  )}
                </Text>
              </View>
            </View>
            <View style={[StyleJsGlobal.gridRow, StyleJsGlobal.justifyContentSpaceBetween]}>
              <View style={[StyleJsGlobal.gridItem6]}>
                <Text style={[StylesJs.text, { fontWeight: '600' }]}>{'Receipt Number'}</Text>
              </View>
              <View style={[StyleJsGlobal.gridItem6, { flex: 1, alignItems: 'flex-end' }]}>
                <Text style={[StylesJs.text, { fontWeight: '600' }]}>
                  {orderDetails.sales_order_id}
                </Text>
              </View>
            </View>
            <View style={[StyleJsGlobal.gridRow, StyleJsGlobal.justifyContentSpaceBetween]}>
              <View style={[StyleJsGlobal.gridItem6]}>
                <Text style={[StylesJs.text, { fontWeight: '600' }]}>{'Customer'}</Text>
              </View>
              <View style={[StyleJsGlobal.gridItem6, { flex: 1, alignItems: 'flex-end' }]}>
                <Text style={[StylesJs.text, { fontWeight: '600' }]}>
                  {customerDetails.customer_name}
                </Text>
              </View>
            </View>
            <View style={[StyleJsGlobal.gridRow, StyleJsGlobal.justifyContentSpaceBetween]}>
              <View style={[StyleJsGlobal.gridItem6]}>
                <Text style={[StylesJs.text, { fontWeight: '600' }]}>{'Payment'}</Text>
              </View>
              <View style={[StyleJsGlobal.gridItem6, { flex: 1, alignItems: 'flex-end' }]}>
                <Text style={[StylesJs.text, { fontWeight: '600' }]}>
                  {orderDetails.payment_type_title}
                </Text>
              </View>
            </View>
            {additionalPaymentText && (
              <View style={[StyleJsGlobal.gridRow, StyleJsGlobal.justifyContentSpaceBetween]}>
                <View style={[StyleJsGlobal.gridItem6]}>
                  <Text style={[StylesJs.text, { fontWeight: '600' }]}>
                    {additionalPaymentText}
                  </Text>
                </View>
                <View style={[StyleJsGlobal.gridItem6, { flex: 1, alignItems: 'flex-end' }]}>
                  <Text style={[StylesJs.text, { fontWeight: '600' }]}>
                    {additionalPaymentValue}
                  </Text>
                </View>
              </View>
            )}
            <View style={[StyleJsGlobal.gridRow, StyleJsGlobal.justifyContentSpaceBetween]}>
              <View style={[StyleJsGlobal.gridItem6]}>
                <Text style={[StylesJs.text, { fontWeight: '600' }]}>{'Served By'}</Text>
              </View>
              <View style={[StyleJsGlobal.gridItem6, { flex: 1, alignItems: 'flex-end' }]}>
                <Text style={[StylesJs.text, { fontWeight: '600' }]}>
                  {orderDetails.creator_name}
                </Text>
              </View>
            </View>
          </View>
          <View style={[StylesJs.divider, StyleJsGlobal.mh4]} />
          <View style={[StyleJsGlobal.ph4, StyleJsGlobal.pv4]}>
            {totalQty === 0 && (
              <View style={{ flex: 1, alignItems: 'center' }}>
                <Text style={[StylesJs.text]}>No item to be printed</Text>
              </View>
            )}
            {totalQty > 0 &&
              finalDataToPrint.map(item => {
                const itemQty = item.quantity - item.details.served;
                if (itemQty === 0) {
                  return null;
                }
                return (
                  <View
                    key={item.sales_order_item_id}
                    style={[StyleJsGlobal.gridItem12, { marginTop: 2, marginBottom: 2 }]}
                  >
                    <View style={[StyleJsGlobal.gridRow, StyleJsGlobal.justifyContentSpaceBetween]}>
                      <View style={[StyleJsGlobal.gridItem9]}>
                        <Text style={[StylesJs.text]}>{item.product_name}</Text>
                      </View>
                      <View style={[StyleJsGlobal.gridItem3, { flex: 1, alignItems: 'flex-end' }]}>
                        <Text style={[StylesJs.text]}>{`x${item.quantity -
                          item.details.served}`}</Text>
                      </View>
                    </View>
                    {item.details.notes && (
                      <View style={[StyleJsGlobal.gridItem10]}>
                        <Text style={[StylesJs.text]}>{`Note: ${item.details.notes}`}</Text>
                      </View>
                    )}
                  </View>
                );
              })}
          </View>
        </View>
      </Page>
    </Document>
  );
};

EatCaptainOrderInvoice.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default EatCaptainOrderInvoice;
