import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid, FormControl, FormLabel } from '@material-ui/core';
import { Form } from 'antd';
import _ from 'lodash';
// component
import { SelectInputMain, InputMain } from '../../Index';
// api
import { getScreenDeeplinkList } from '../../../services/api/MasterDataApi';
// helper
import { CommonHelper, MasterDataHelper } from '../../../helpers/Index';

const deeplinkOptions = MasterDataHelper.deeplinkType;

const initialValidation = {
  identifier: { isError: '', errorMessage: '' },
  screen: { isError: '', errorMessage: '' },
  webUrl: { isError: '', errorMessage: '' },
};

const initialForm = {
  deeplinkType: deeplinkOptions[0].value,
  identifier: null,
  subIdentifier: null,
  webUrl: null,
  screen: null,
  disabledIdentifier: true,
};

class DeepLink extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      form: CommonHelper.objectCloning(initialForm),
      validation: CommonHelper.objectCloning(initialValidation),
    };
    this.parameterUpdateDebounce = _.debounce(this.parameterUpdateDebounce, 600);
  }

  componentDidMount() {
    const {
      form: { screen },
    } = this.state;

    this.getScreenDeeplinkList();
    if (!screen) {
      this.setDefaultScreenDeeplink();
    }
  }

  componentWillReceiveProps(prevProps) {
    const { form, validation } = this.state;
    const isEqualFormParent = _.isEqual(form, prevProps.parentForm);
    const isEqualValidationParent = _.isEqual(validation, prevProps.parentValidation);

    if (!isEqualFormParent || !isEqualValidationParent) {
      this.setState(
        {
          form: prevProps.parentForm,
          validation: prevProps.parentValidation,
        },
        () => {
          const valueChange = prevProps.parentForm.screen || null;
          this.handleChangeScreenType(valueChange);
        },
      );
    }
  }

  componentDidUpdate(prevProps) {
    const isEqualScreeListOption = _.isEqual(
      this.props.masterData.screenList,
      prevProps.masterData.screenList,
    );

    if (!isEqualScreeListOption) {
      this.setDefaultScreenDeeplink();
    }
  }

  getScreenDeeplinkList() {
    const { getScreenList } = this.props;
    getScreenList();
  }

  setDefaultScreenDeeplink() {
    const {
      masterData: { screenList },
      parentForm,
      parentValidation,
    } = this.props;
    const defaultValue = !_.isEmpty(screenList) ? screenList[0].value : '';

    this.setState(
      {
        form: parentForm,
        validation: parentValidation,
      },
      () => {
        this.handleChangeScreenType(defaultValue);
      },
    );
  }

  parameterUpdateDebounce = (form, validation) => {
    const { onParameterUpdate } = this.props;
    onParameterUpdate(form, validation);
  };

  handleChangeDeeplinkType = value => {
    const { form } = this.state;
    const {
      masterData: { screenList },
    } = this.props;
    const defaultValue = !_.isEmpty(screenList) ? screenList[0].value : '';

    this.setState({ form: { ...form, deeplinkType: value } }, () => {
      this.handleChangeScreenType(defaultValue);
    });
  };

  handleChangeScreenType = value => {
    const {
      masterData: { screenList },
      onParameterUpdate,
    } = this.props;
    const { form, validation } = this.state;
    const message01 = CommonHelper.objectCloning(initialValidation.screen);
    const message02 = CommonHelper.objectCloning(initialValidation.screen);
    const formNewValue = CommonHelper.objectCloning(form);
    const resetValidation = CommonHelper.objectCloning(validation);
    let selectedScreen = {};
    let modifyIdentifier = formNewValue.identifier;
    let modifySubIdentifier = formNewValue.subIdentifier;

    message01.isError = !value ? 'error' : '';
    message01.errorMessage = !value ? 'Please choose Screen' : '';
    message02.isError = !value ? 'error' : '';
    message02.errorMessage = !value ? 'Identifier is empty' : '';
    const resultValue = !value ? '' : value;

    if (value) {
      selectedScreen = _.find(screenList, ['value', value]);
      modifyIdentifier = selectedScreen.identifier_mandatory ? modifyIdentifier : null;
      modifySubIdentifier = selectedScreen.identifier_mandatory ? modifyIdentifier : null;

      if (value !== form.screen) {
        modifyIdentifier = null;
        modifySubIdentifier = null;
      }
    }

    const modifyDisableIdentifier = !_.isEmpty(selectedScreen)
      ? !selectedScreen.identifier_mandatory
      : true;

    this.setState(
      {
        form: {
          ...form,
          screen: resultValue,
          identifier: modifyIdentifier,
          subIdentifier: modifySubIdentifier,
          disabledIdentifier: modifyDisableIdentifier,
        },
        validation: { ...validation, screen: message01, identifier: message02 },
      },
      () => {
        formNewValue.screen = resultValue;
        formNewValue.identifier = modifyIdentifier;
        formNewValue.subIdentifier = modifySubIdentifier;
        formNewValue.disabledIdentifier = modifyDisableIdentifier;
        resetValidation.screen = message01;
        resetValidation.identifier = message02;
        onParameterUpdate(formNewValue, resetValidation);
      },
    );
  };

  handleTextChangeIdentifier = value => {
    const { form, validation } = this.state;
    const formNewValue = CommonHelper.objectCloning(form);
    const resetValidation = CommonHelper.objectCloning(validation);
    const message = CommonHelper.objectCloning(initialValidation.identifier);

    if (form.disabledIdentifier === false) {
      message.isError = !value ? 'error' : '';
      message.errorMessage = !value ? 'Identifier is empty' : '';
    }

    const resultValue = !value ? '' : value;

    this.setState(
      {
        form: { ...form, identifier: resultValue.trim() },
        validation: { ...validation, identifier: message },
      },
      () => {
        formNewValue.identifier = resultValue.trim();
        resetValidation.identifier = message;
        this.parameterUpdateDebounce(formNewValue, resetValidation);
      },
    );
  };

  handleTextChangeSubIdentifier = value => {
    const { form, validation } = this.state;
    const formNewValue = CommonHelper.objectCloning(form);

    this.setState(
      {
        form: { ...form, subIdentifier: value.trim() },
      },
      () => {
        formNewValue.subIdentifier = value.trim();
        this.parameterUpdateDebounce(formNewValue, validation);
      },
    );
  };

  handleTextChangeWebUrl = value => {
    const { form, validation } = this.state;
    const formNewValue = CommonHelper.objectCloning(form);
    const resetValidation = CommonHelper.objectCloning(validation);
    const message = CommonHelper.objectCloning(initialValidation.webUrl);

    message.isError = !value ? 'error' : '';
    message.errorMessage = !value ? 'Web URL is empty' : '';
    const resultValue = !value ? '' : value;

    this.setState(
      {
        form: { ...form, webUrl: resultValue },
        validation: { ...validation, webUrl: message },
      },
      () => {
        formNewValue.webUrl = resultValue.trim();
        resetValidation.webUrl = message;
        this.parameterUpdateDebounce(formNewValue, validation);
      },
    );
  };

  render() {
    const {
      form: { deeplinkType, screen, webUrl, identifier, subIdentifier, disabledIdentifier },
    } = this.state;
    const { masterData } = this.props;
    const screenListRenameKey = CommonHelper.renameKeyName(
      masterData.screenList,
      'display_text',
      'name',
    );

    return (
      <Grid container direction="column">
        <Grid item lg md>
          <FormControl component="fieldset" fullWidth margin={'normal'}>
            <FormLabel component="label" className="form-label">
              Deeplink Type
            </FormLabel>
            <Form.Item className="mb-8">
              <SelectInputMain
                options={deeplinkOptions}
                currentValue={deeplinkType}
                onChange={this.handleChangeDeeplinkType}
              />
            </Form.Item>
          </FormControl>
        </Grid>
        {deeplinkType === 'screen' && (
          <React.Fragment>
            <Grid item lg md>
              <FormControl component="fieldset" fullWidth margin={'normal'}>
                <FormLabel component="label" className="form-label">
                  Screen Name
                </FormLabel>
                <Form.Item className="mb-8">
                  <SelectInputMain
                    options={screenListRenameKey}
                    currentValue={screen}
                    onChange={this.handleChangeScreenType}
                    validateStatus={this.state.validation.screen.isError}
                    errorMessage={this.state.validation.screen.errorMessage}
                  />
                </Form.Item>
              </FormControl>
            </Grid>
            <Grid item lg md>
              <FormControl component="fieldset" fullWidth margin={'normal'}>
                <FormLabel component="label" className="form-label">
                  Identifier
                </FormLabel>
                <Form.Item className="mb-8">
                  <InputMain
                    placeholder={'Identifier'}
                    value={identifier}
                    onChange={this.handleTextChangeIdentifier}
                    validateStatus={this.state.validation.identifier.isError}
                    errorMessage={this.state.validation.identifier.errorMessage}
                    isNumber
                    disabled={disabledIdentifier}
                  />
                </Form.Item>
              </FormControl>
            </Grid>
            <Grid item lg md>
              <FormControl component="fieldset" fullWidth margin={'normal'}>
                <FormLabel component="label" className="form-label">
                  Sub-identifier
                </FormLabel>
                <Form.Item className="mb-8">
                  <InputMain
                    placeholder={'Sub-identifier'}
                    value={subIdentifier}
                    onChange={this.handleTextChangeSubIdentifier}
                    isNumber
                    disabled={disabledIdentifier}
                  />
                </Form.Item>
              </FormControl>
            </Grid>
          </React.Fragment>
        )}
        {deeplinkType === 'webUrl' && (
          <Grid item lg md>
            <FormControl component="fieldset" fullWidth margin={'normal'}>
              <FormLabel component="label" className="form-label">
                Web URL
              </FormLabel>
              <Form.Item className="mb-8">
                <InputMain
                  placeholder={'Web URL'}
                  value={webUrl}
                  onChange={this.handleTextChangeWebUrl}
                  validateStatus={this.state.validation.webUrl.isError}
                  errorMessage={this.state.validation.webUrl.errorMessage}
                />
              </Form.Item>
            </FormControl>
          </Grid>
        )}
      </Grid>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getScreenList: () => getScreenDeeplinkList(dispatch),
});

const mapStateToProps = ({ masterData }) => ({
  masterData,
});

DeepLink.propTypes = {
  getScreenList: PropTypes.func,
  masterData: PropTypes.object,
  onParameterUpdate: PropTypes.func,
  parentForm: PropTypes.object,
  parentValidation: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(DeepLink);
