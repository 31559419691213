import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Button } from 'antd';
import { Grid, Breadcrumbs, Link } from '@material-ui/core';
import { QRCode } from 'react-qrcode-logo';
import { compose } from 'redux';
// style
import './BranchDetailsStyle.scss';
// component
import {
  SnackBarSimple,
  ButtonMain,
  GridRowTableOneColumn,
  ModalAlertInformation,
  AuthenticationAccessPages,
  PrevStateValue,
  SkeletonDetailsV03
} from '../../../../../components/Index';
// api
import { getBranchDetails } from '../../../../../services/api/MasterDataMainAPi';
// helper
import { MasterDataHelper, CommonHelper, PermissionModule, PermissionPage, PermissionAccess } from '../../../../../helpers/Index';
// assets
import { Images } from '../../../../../assets/Index';

const initialForm = {
  branchId: null,
  merchantName: '',
  companyName: '',
  bankName: '',
  bankAccountName: '',
  bankAccountNumber: '',
  status: 0,
  branchName: '',
  address: '',
  city: '',
  coordinate: '',
  district: '',
  email: '',
  phone: '',
  whatsapp: '',
  area: '',
  province: '',
  village: '',
  qrValue: '',
};

const optionToash = {
  vertical: 'top',
  horizontal: 'right',
};

const modalAlertOptionInformation = {
  title: 'QR Code',
  text: '',
};

const radioButtonStatusOption = MasterDataHelper.statusGlobal;
const qrOption = {
  logoImage: Images.fitcoLogoRounded,
  logoWidth: 50,
  logoHeight: 50,
  size: 360,
  ecLevel: 'Q',
};

class BranchDetails extends React.Component {
  constructor(props) {
    super(props);

    props.checkUserAccessPermission(
      PermissionModule.MasterData,
      PermissionPage.Branch,
      PermissionAccess.View,
    );

    this.state = {
      form: initialForm,
      isLoading: false,
      toastInformation: {
        isOpen: false,
        message: '',
        snackbarType: 'warning',
      },
    };
  }

  componentDidMount() {
    this.getBranchDetails();
  }

  componentWillUnmount() {}

  getBranchDetails() {
    const {
      branchDetails,
      match: { params },
    } = this.props;
    const { form } = this.state;
    const branchId = params.branch_id;
    const param = { branch_id: branchId };

    branchDetails(param)
      .then(async response => {
        const data = response.data;
        const index = radioButtonStatusOption.findIndex(item => item.value === data.status);

        this.setState({
          form: {
            ...form,
            branchId: data.branch_id,
            branchName: data.branch_name,
            merchantName: data.merchant_name,
            companyName: data.company_name,
            bankName: data.bank_name,
            bankAccountName: data.bank_account_name,
            bankAccountNumber: data.account_number,
            status: radioButtonStatusOption[index].name,
            address: data.address,
            city: data.city_name,
            coordinate: data.coordinate,
            district: data.district_name,
            email: data.email,
            phone: data.phone,
            whatsapp: data.whatsapp,
            area: data.area,
            province: data.province_name,
            village: data.village_name,
            qrValue: data.qr_code_url,
          },
        });
      })
      .catch(error => {
        const message = error.data;
        this.processMessage(message.messages, 'error');
      });
  }

  handleCloseToash = () => {
    const { toastInformation } = this.state;
    this.setState({ toastInformation: { ...toastInformation, isOpen: false } });
  };

  handleCloseModalQr = () => {
    this.setState({ isOpenModalQr: false });
  };

  handleButtonQr = () => {
    this.setState({ isOpenModalQr: true });
  };

  handleClick = (event, value) => {
    const { history } = this.props;
    event.preventDefault();
    history.push(value);
  };

  handleButtonBack = () => {
    const { history } = this.props;
    history.push('/master-data/main-data');
  };

  handleButtonEdit = () => {
    const { history } = this.props;
    const {
      form: { branchId },
    } = this.state;

    history.push(`/master-data/main-data/branch/edit/${branchId}`);
  };

  processMessage(messages, type) {
    const convertedMessage = CommonHelper.generateMessage(messages);

    const paramInformation = {
      isOpen: true,
      message: convertedMessage,
      snackbarType: type,
    };

    this.setState({
      toastInformation: paramInformation,
    });
  }

  renderModalQR() {
    const {
      isOpenModalQr,
      form: { qrValue },
    } = this.state;
    let elementRender = null;

    if (isOpenModalQr) {
      elementRender = (
        <ModalAlertInformation
          optionModal={modalAlertOptionInformation}
          isOpen={isOpenModalQr}
          onCloseModal={this.handleCloseModalQr}
          typeModal="information"
          customElementProps={
            <div className="container-qr-canvas">
              <QRCode
                value={qrValue}
                logoImage={qrOption.logoImage}
                logoWidth={qrOption.logoWidth}
                logoHeight={qrOption.logoHeight}
                size={qrOption.size}
                ecLevel={qrOption.ecLevel}
              />
            </div>
          }
        />
      );
    }

    return elementRender;
  }

  render() {
    const {
      toastInformation,
      form: {
        merchantName,
        companyName,
        bankName,
        bankAccountName,
        bankAccountNumber,
        status,
        branchName,
        address,
        city,
        coordinate,
        district,
        email,
        phone,
        whatsapp,
        area,
        province,
        village,
        qrValue,
      },
      isLoading,
    } = this.state;

    let renderElement = <SkeletonDetailsV03 />;

    if (!isLoading) {
      renderElement = (
        <div>
          <Helmet title="FITCO | Master Data - Branch Details" />
          <div className="container-page-branch-details scroll-container">
            <div className="container-page-scrolling-area">
              <Grid container direction="column" className="flex-wrap-unset">
                <Grid item lg md className="section-page-header">
                  <div className="breadcrumbs-section">
                    <Breadcrumbs aria-label="breadcrumb">
                      <Link
                        className="text-12"
                        color="inherit"
                        href="/master-data/main-data"
                        onClick={event => {
                          this.handleClick(event, '/master-data/main-data');
                        }}
                      >
                        <i className="ic-ffo-branch container-icon-prefix size-16" /> Main Data
                      </Link>
                      <label className="text-12" color="inherit">
                        Branch details
                      </label>
                    </Breadcrumbs>
                  </div>
                </Grid>
                <Grid item lg md className="section-page-body">
                  <div className="branch-details-card">
                    <Grid container spacing={3}>
                      <Grid item lg md>
                        <Grid
                          container
                          direction="column"
                          className="container-row-table flex-wrap-unset"
                        >
                          <Grid item>
                            <Grid container justify="flex-end" spacing={3}>
                              <Grid item lg={2} md={2}>
                                <ButtonMain
                                  type="ghost"
                                  size="xl"
                                  labelText="Back"
                                  onClick={this.handleButtonBack}
                                />
                              </Grid>
                              <Grid item lg={2} md={2}>
                                <ButtonMain
                                  type="ghost"
                                  size="xl"
                                  labelText="Edit"
                                  onClick={this.handleButtonEdit}
                                  requiredPermission={`${PermissionModule.MasterData}.${PermissionPage.Branch}.${PermissionAccess.Update}`}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item className="title-table">
                            <label className="text-12">Company Information</label>
                          </Grid>
                          <Grid item>
                            <GridRowTableOneColumn
                              customElementInitial={<label className="text-14">Company</label>}
                              customElementColumn={
                                <label className="text-14">{companyName || '-'}</label>
                              }
                            />
                            <GridRowTableOneColumn
                              customElementInitial={<label className="text-14">Merchant</label>}
                              customElementColumn={
                                <label className="text-14">{merchantName || '-'}</label>
                              }
                            />
                            <GridRowTableOneColumn
                              customElementInitial={<label className="text-14">Branch</label>}
                              customElementColumn={
                                <label className="text-14">{branchName || '-'}</label>
                              }
                            />
                            <GridRowTableOneColumn
                              customElementInitial={<label className="text-14">Email</label>}
                              customElementColumn={
                                <label className="text-14">{email || '-'}</label>
                              }
                            />
                            <GridRowTableOneColumn
                              customElementInitial={<label className="text-14">Whatsapp</label>}
                              customElementColumn={
                                <label className="text-14">{whatsapp || '-'}</label>
                              }
                            />
                            <GridRowTableOneColumn
                              customElementInitial={<label className="text-14">phone</label>}
                              customElementColumn={
                                <label className="text-14">{phone || '-'}</label>
                              }
                            />
                            <GridRowTableOneColumn
                              customElementInitial={<label className="text-14">Status</label>}
                              customElementColumn={
                                <label className="text-14">{status || '-'}</label>
                              }
                            />
                            <GridRowTableOneColumn
                              customElementInitial={<label className="text-14">QR code</label>}
                              customElementColumn={
                                qrValue ? (
                                  <div className="container-button-custom">
                                    <Button
                                      type="link"
                                      className="button-link text-14"
                                      block
                                      onClick={this.handleButtonQr}
                                    >
                                      Show QR Code
                                    </Button>
                                  </div>
                                ) : (
                                  <label className="text-14">-</label>
                                )
                              }
                            />
                          </Grid>
                          <Grid item className="title-table margin-space">
                            <label className="text-12">Bank Information</label>
                          </Grid>
                          <Grid item>
                            <GridRowTableOneColumn
                              customElementInitial={<label className="text-14">Bank Name</label>}
                              customElementColumn={
                                <label className="text-14">{bankName || '-'}</label>
                              }
                            />
                            <GridRowTableOneColumn
                              customElementInitial={
                                <label className="text-14">Bank Account Name</label>
                              }
                              customElementColumn={
                                <label className="text-14">{bankAccountName || '-'}</label>
                              }
                            />
                            <GridRowTableOneColumn
                              customElementInitial={
                                <label className="text-14">Bank Account Number</label>
                              }
                              customElementColumn={
                                <label className="text-14">{bankAccountNumber || '-'}</label>
                              }
                            />
                          </Grid>
                          <Grid item className="title-table margin-space">
                            <label className="text-12">Address</label>
                          </Grid>
                          <Grid item>
                            <GridRowTableOneColumn
                              customElementInitial={<label className="text-14">Address</label>}
                              customElementColumn={
                                <label className="text-14">{address || '-'}</label>
                              }
                            />
                            <GridRowTableOneColumn
                              customElementInitial={<label className="text-14">Area</label>}
                              customElementColumn={<label className="text-14">{area || '-'}</label>}
                            />
                            <GridRowTableOneColumn
                              customElementInitial={<label className="text-14">Coordinate</label>}
                              customElementColumn={
                                <label className="text-14">{coordinate || '-'}</label>
                              }
                            />
                            <GridRowTableOneColumn
                              customElementInitial={<label className="text-14">Province</label>}
                              customElementColumn={
                                <label className="text-14">{province || '-'}</label>
                              }
                            />
                            <GridRowTableOneColumn
                              customElementInitial={<label className="text-14">District</label>}
                              customElementColumn={
                                <label className="text-14">{district || '-'}</label>
                              }
                            />
                            <GridRowTableOneColumn
                              customElementInitial={<label className="text-14">City</label>}
                              customElementColumn={<label className="text-14">{city || '-'}</label>}
                            />
                            <GridRowTableOneColumn
                              customElementInitial={<label className="text-14">Village</label>}
                              customElementColumn={
                                <label className="text-14">{village || '-'}</label>
                              }
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
                <Grid item lg md className="section-page-footer" />
              </Grid>
            </div>
          </div>
          {this.renderModalQR()}
          <SnackBarSimple
            open={toastInformation.isOpen}
            durationHide={2000}
            message={toastInformation.message}
            onClickClose={this.handleCloseToash}
            snackbarType={toastInformation.snackbarType}
            anchor={optionToash}
          />
        </div>
      );
    }

    return renderElement;
  }
}

const mapDispatchToProps = dispatch => ({
  branchDetails: params => getBranchDetails(dispatch, params),
});

const mapStateToProps = ({ masterData }) => ({ masterData });

BranchDetails.propTypes = {
  branchDetails: PropTypes.func,
  checkUserAccessPermission: PropTypes.func,
  history: PropTypes.object,
  match: PropTypes.object,
};

const shell = compose(connect(mapStateToProps, mapDispatchToProps));
const core = compose(AuthenticationAccessPages, PrevStateValue);

export default shell(core(BranchDetails));
