import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Skeleton } from '@material-ui/lab';
import { CircularProgress } from '@material-ui/core';
// component
import { SelectInputSearchMain } from '../../Index';
// api
import { getProductCategoryList } from '../../../services/api/MasterDataApi';
// helper
import { CommonHelper } from '../../../helpers/Index';
import SelectSearchMultiple from '../../presentational/select-search-multiple/SelectSearchMultiple';

const initialFilter = {
  search: '',
};

class SelectInputProductCategory extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: CommonHelper.objectCloning(initialFilter),
      listItem: [],
    };
    this.searchDebounce = _.debounce(this.searchDebounce, 600);
  }

  componentDidMount() {
    this.getMasterDataList();
  }

  componentDidUpdate(prevProps) {
    const { filter } = this.props;

    if (filter) {
      if (
        prevProps.filter.merchantId !== filter.merchantId ||
        prevProps.filter.productType !== filter.productType
      ) {
        this.setUpdateFilter();
      }
    }
  }

  setUpdateFilter() {
    const { filter } = this.props;
    const filterStatue = this.state.filter;

    const updateExistingFilter = { ...filterStatue, ...CommonHelper.objectCloning(filter) };

    this.setState(
      {
        filter: updateExistingFilter,
      },
      () => {
        this.getMasterDataList();
      },
    );
  }

  getMasterDataList() {
    const { apiDataList } = this.props;
    const {
      filter: { search, merchantId, productType },
    } = this.state;

    const param = {
      merchant_id: merchantId || null,
      product_types: productType || null,
      search: search || null,
    };

    apiDataList(param).then(() => {
      const ListCustom = this.generateListDataDisplay();
      this.setState({ listItem: ListCustom });
    });
  }

  handleSelectItem = value => {
    const { onChange } = this.props;

    onChange(value);
  };

  handleItemSearch = value => {
    const { filter } = this.state;

    this.setState({ filter: { ...filter, search: value } }, () => {
      this.searchDebounce();
    });
  };

  searchDebounce() {
    this.getMasterDataList();
  }

  generateListDataDisplay() {
    const { masterData, includeAllData, isMultipleSelection, currentValue } = this.props;
    const converted = [];
    let param = [];
    const currentData = CommonHelper.renameKeyName(
      masterData.productCategoryList,
      'category_id',
      'value',
    );

    if (includeAllData && !isMultipleSelection) {
      param = { value: null, name: 'Select Product Category' };
      converted.push(param);
    }

    if (!_.isEmpty(currentData)) {
      currentData.forEach(item => {
        param = { value: item.value, name: item.name };
        converted.push(param);
      });

      if (!currentValue || _.isEmpty(currentValue)) {
        const valueChange = isMultipleSelection ? [] : converted[0].value;
        if (!isMultipleSelection && !valueChange) {
          this.handleSelectItem(valueChange);
        }
      }
    } else if (_.isEmpty(currentData) && !_.isEmpty(currentValue) && isMultipleSelection) {
      this.handleSelectItem([]);
    }

    return converted;
  }

  renderSelector() {
    const {
      placeHolder,
      defaultValue,
      currentValue,
      errorMessage,
      validateStatus,
      size,
      isMultipleSelection,
      disabled,
      masterData: { fetching },
    } = this.props;
    const { listItem } = this.state;

    let renderElement = (
      <Skeleton
        variant="rect"
        width="100%"
        height={40}
        animation="wave"
        className="skeletonRounded"
      />
    );

    if (isMultipleSelection) {
      renderElement = (
        <SelectSearchMultiple
          options={listItem}
          onChange={item => {
            this.handleSelectItem(item);
          }}
          notFoundContent={fetching && <CircularProgress size={14} />}
          onSearch={this.handleItemSearch}
          currentValue={currentValue}
          defaultValue={defaultValue}
          size={size}
          disabled={!!_.isEmpty(listItem) || disabled}
          placeholder={placeHolder}
          errorMessage={errorMessage}
          validateStatus={validateStatus}
        />
      );
    } else {
      renderElement = (
        <SelectInputSearchMain
          options={listItem}
          onChange={item => {
            this.handleSelectItem(item);
          }}
          currentValue={currentValue}
          defaultValue={defaultValue}
          size={size}
          disabled={!!_.isEmpty(listItem) || disabled}
          placeholder={placeHolder}
          errorMessage={errorMessage}
          validateStatus={validateStatus}
        />
      );
    }

    return renderElement;
  }

  render() {
    return this.renderSelector();
  }
}

const mapDispatchToProps = dispatch => ({
  apiDataList: params => getProductCategoryList(dispatch, params),
});

const mapStateToProps = ({ masterData }) => ({ masterData });

SelectInputProductCategory.propTypes = {
  apiDataList: PropTypes.func,
  currentValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
  disabled: PropTypes.bool,
  errorMessage: PropTypes.string,
  filter: PropTypes.object,
  includeAllData: PropTypes.bool,
  isMultipleSelection: PropTypes.bool,
  masterData: PropTypes.object,
  onChange: PropTypes.func,
  placeHolder: PropTypes.string,
  size: PropTypes.string,
  validateStatus: PropTypes.string,
};

SelectInputProductCategory.defaultProps = {
  placeHolder: '',
  isMultipleSelection: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectInputProductCategory);
