/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable no-plusplus */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Form } from 'antd';
import { Grid, Breadcrumbs, Link, FormControl, FormLabel } from '@material-ui/core';
import _ from 'lodash';
import { compose } from 'redux';
// Style
import './UserEditStyle.scss';
import {
  ButtonMain,
  UploadImage,
  SelectInputSearchMain,
  SelectSearchMultiple,
  // TextAreaMain,
  SnackBarSimple,
  ButtonIconMain,
  PickerInputDate,
  SelectInputMain,
  TextInput,
  CheckInputOutlineBackground,
  SelectInputCompany,
  SelectInputRole,
  CheckInput,
  AuthenticationAccessPages,
  FitcoPopover,
} from '../../../../components/Index';
import { SkeletonDetails } from '../../../../components/skeleton/Index';
// Helper
import {
  CommonHelper,
  ValidationHelper,
  MasterDataHelper,
  UserHelper,
  HttpStatusCode,
  PermissionModule,
  PermissionPage,
  PermissionAccess,
  FitcoPopoverTrigger,
  FitcoPopoverPlacement,
} from '../../../../helpers/Index';
// Action
import { getMerchantList, getBranchList } from '../../../../services/api/MasterDataApi';
import {
  validateEmailOrPhone,
  getUserDetailsById,
  updateUser,
} from '../../../../services/api/UserManagement';

const optionToast = {
  vertical: 'top',
  horizontal: 'right',
};

const allBranchOption = { value: 'all_branch', name: 'All Branch' };
const defaultMerchant = { merchant_id: null, all_branch: false, branch_id: [] };
const currentUsers = UserHelper.getCurrentUserInformation();

const initialValidation = {
  firstName: { isError: false, errorMessage: '' },
  lastName: { isError: false, errorMessage: '' },
  birthday: { isError: false, errorMessage: '' },
  company: { isError: false, errorMessage: '' },
  role: { isError: false, errorMessage: '' },
  merchant: { isError: false, errorMessage: '' },
  branch: { isError: false, errorMessage: '' },
  email: { isError: false, errorMessage: '' },
  phone: { isError: false, errorMessage: '' },
  address: { isError: false, errorMessage: '' },
};

const genderOptions = MasterDataHelper.gender;
const currentDate = CommonHelper.currentDate('YYYY-MM-DD');

class UserEdit extends React.Component {
  constructor(props) {
    super(props);
    const {
      match: { params },
    } = this.props;

    props.checkUserAccessPermission(
      PermissionModule.UserManagement,
      PermissionPage.Users,
      PermissionAccess.Update,
    );

    this.state = {
      form: {
        userId: CommonHelper.decryptObject(params.user_id).userId || null,
        isStaff: false,
        isFitcoOps: false,
        allMerchantAndBranch: false,
        firstName: '',
        lastName: '',
        merchantId: null,
        branchId: null,
        companyId: null,
        roleId: null,
        birthday: '',
        gender: '',
        email: '',
        phone: '',
        address: '',
        imageBase64: null,
      },
      setRole: false,
      validation: initialValidation,
      toastInformation: {
        isOpen: false,
        message: '',
        snackbarType: 'warning',
      },
      isLoading: false,
      branchOptions: [],
      userMerchants: [],
      isResellerLogin: currentUsers && currentUsers.isResellerLogin,
    };
    this.searchDebounce = _.debounce(this.searchDebounce, 400);
  }

  componentDidMount() {
    this.getUserDetails();
  }

  componentWillUnmount() {}

  getUserDetails() {
    const { getUserDetails, getMerchants, getBranches, history } = this.props;
    const {
      form: { userId },
      isResellerLogin,
    } = this.state;
    const param = { user_id: userId };

    this.setState({ isLoading: true }, () => {
      getUserDetails(param)
        .then(async response => {
          const data = response.data;
          const converted = UserHelper.generateUserDetails(data);
          const setRole = !!converted.roleId;
          const userMerchants = UserHelper.generateUserCompanyDetail(
            data.company_details.user_merchants,
          );

          const params = { company_id: converted.companyId };
          await getMerchants(params);
          await this.setState(
            {
              isLoading: false,
              form: converted,
              userMerchants,
              setRole,
            },
            async () => {
              userMerchants.forEach(async item => {
                getBranches({
                  merchant_id: item.merchant_id === 'all_merchant' ? null : item.merchant_id,
                }).then(response => {
                  this.setState({
                    branchOptions: [...this.state.branchOptions, response.data],
                  });
                });
              });
            },
          );
        })
        .catch(error => {
          const message = error.data;

          if (error.data.code === HttpStatusCode.Forbidden) {
            const redirectUrl = isResellerLogin ? `/` : '/management/users';
            history.push(redirectUrl);
          }

          this.processMessage(message.messages, 'error');
        });
    });
  }

  // Start Handle Change Field

  handleClick = (event, value) => {
    const { history } = this.props;
    event.preventDefault();
    history.push(value);
  };

  handleChangeFirstName = value => {
    const { form, validation } = this.state;
    const firstNameValidation = { isError: false, errorMessage: '' };

    if (_.isEmpty(value)) {
      firstNameValidation.isError = true;
      firstNameValidation.errorMessage = 'Please enter your First Name';
    }

    this.setState({
      form: { ...form, firstName: _.isEmpty(value) ? null : value.trim() },
      validation: { ...validation, firstName: firstNameValidation },
    });
  };

  handleChangeLastName = value => {
    const { form } = this.state;

    this.setState({
      form: { ...form, lastName: _.isEmpty(value) ? null : value.trim() },
    });
  };

  handleChangeGender = value => {
    const { form } = this.state;
    this.setState({ form: { ...form, gender: value } });
  };

  handleChangeBirthday = value => {
    const { form } = this.state;
    this.setState({
      form: { ...form, birthday: value },
    });
  };

  handleChangeCompany = value => {
    const { form, validation } = this.state;
    const { getMerchants } = this.props;

    this.setState(
      {
        form: {
          ...form,
          companyId: value || null,
          allMerchantAndBranch: false,
        },
        userMerchants: [],
        validation: {
          ...validation,
          company: { isError: false, errorMessage: '' },
        },
      },
      () => {
        const params = { company_id: value };
        getMerchants(params);
      },
    );
  };

  handleChangeRole = value => {
    const { form, validation } = this.state;
    this.setState({
      form: { ...form, roleId: value },
      validation: {
        ...validation,
        role: { isError: false, errorMessage: '' },
      },
    });
  };

  handleChangeMerchant = (merchantId, index) => {
    const { getBranches } = this.props;
    const { validation } = this.state;
    let flagPass = true;
    const userMerchants = [...this.state.userMerchants];

    userMerchants.forEach(item => {
      if (item.merchant_id === merchantId) flagPass = false;
    });

    if (flagPass) {
      const item = { ...userMerchants[index] };
      item.merchant_id = merchantId;
      item.all_branch = false;
      item.branch_id = [];
      userMerchants[index] = item;

      this.setState({ userMerchants }, () => {
        if (index === 0) {
          let message = { isError: false, errorMessage: '' };

          if (!this.state.userMerchants[index].merchant_id) {
            message = {
              isError: 'Error',
              errorMessage: 'please input your Merchant',
            };
          }

          this.setState(
            {
              validation: {
                ...validation,
                merchant: message,
              },
            },
            () => {
              if (merchantId === 'all_merchant') {
                for (let i = 0; i < userMerchants.length; i++) {
                  this.handleRemoveMerchantAndBranch(userMerchants.length - i);
                }
              }
            },
          );
        }
      });

      if (merchantId) {
        getBranches({ merchant_id: merchantId === 'all_merchant' ? null : merchantId }).then(
          response => {
            const branchOptions = this.state.branchOptions;
            branchOptions[index] = response.data;

            this.setState({
              branchOptions,
            });
          },
        );
      }
    } else {
      const item = { ...userMerchants[index] };
      item.merchant_id = null;
      item.all_branch = false;
      item.branch_id = [];
      userMerchants[index] = item;

      this.setState({ userMerchants }, () => {
        const message = `Can't Select Same Merchant!!!`;
        this.processMessage(message, 'warning');
      });
    }
  };

  handleChangeBranch = (branchId, index) => {
    const { validation } = this.state;
    const userMerchants = [...this.state.userMerchants];
    const item = { ...userMerchants[index] };
    item.all_branch = branchId[0] === 'all_branch';
    item.branch_id = branchId;
    userMerchants[index] = item;

    this.setState({ userMerchants }, () => {
      if (index === 0) {
        let message = { isError: false, errorMessage: '' };

        if (this.state.userMerchants[index].branch_id.length === 0) {
          message = {
            isError: 'Error',
            errorMessage: 'please input your branch',
          };
        }

        this.setState({
          validation: {
            ...validation,
            branch: message,
          },
        });
      }
    });
  };

  // handleChangeAddress = value => {
  //   const { form, validation } = this.state;
  //   const message = {
  //     isError: false,
  //     errorMessage: '',
  //   };

  //   this.setState(
  //     {
  //       form: { ...form, address: value },
  //     },
  //     () => {
  //       if (!value || value === '') {
  //         message.isError = 'error';
  //         message.errorMessage = 'Address Empty';
  //       }
  //       this.setState({
  //         validation: { ...validation, address: message },
  //       });
  //     },
  //   );
  // };

  handleChangeEmail = value => {
    const emailValidation = ValidationHelper.validateEmail(value.trim());

    const { form, validation } = this.state;
    const message = {
      isError: false,
      errorMessage: '',
    };

    this.setState(
      {
        form: { ...form, email: value.trim() },
      },
      () => {
        if (!emailValidation) {
          message.isError = true;
          message.errorMessage = 'please check your email, and try again';
        }
        this.setState(
          {
            validation: { ...validation, email: message },
          },
          () => {
            const params = {
              data: {
                email: value.trim(),
              },
            };
            this.searchDebounce(params);
          },
        );
      },
    );
  };

  handleChangePhone = value => {
    const phoneValidation = ValidationHelper.validatePhone(value.trim());
    const { form, validation } = this.state;
    const message = {
      isError: false,
      errorMessage: '',
    };

    this.setState(
      {
        form: { ...form, phone: value.trim() },
      },
      () => {
        if (!phoneValidation) {
          message.isError = true;
          message.errorMessage = 'please check your Phone, and try again';
        }
        this.setState(
          {
            validation: { ...validation, phone: message },
          },
          () => {
            const params = {
              data: {
                phone: value.trim(),
              },
            };
            this.searchDebounce(params);
          },
        );
      },
    );
  };

  handleChangeImage = imageBase64 => {
    const { form } = this.state;

    this.setState({
      form: { ...form, imageBase64 },
    });
  };

  handleClose = () => {
    const { toastInformation } = this.state;
    this.setState({ toastInformation: { ...toastInformation, isOpen: false } });
  };

  searchDebounce = params => {
    const { checkEmailOrPhone } = this.props;
    const { validation } = this.state;

    checkEmailOrPhone(params).catch(response => {
      const { messages } = response.data;
      let paramValidation01 = {
        isError: false,
        errorMessage: '',
      };
      let paramValidation02 = {
        isError: false,
        errorMessage: '',
      };

      if (messages.hasOwnProperty('email')) {
        const convertedMessage = CommonHelper.generateMessage(messages);
        paramValidation01 = {
          isError: true,
          errorMessage: convertedMessage,
        };

        this.setState({
          validation: { ...validation, email: paramValidation01 },
        });
      }

      if (messages.hasOwnProperty('phone')) {
        const convertedMessage = CommonHelper.generateMessage(messages);
        paramValidation02 = {
          isError: true,
          errorMessage: convertedMessage,
        };
        this.setState({
          validation: { ...validation, phone: paramValidation02 },
        });
      }
    });
  };

  handleIsStaff = value => {
    const { form, validation } = this.state;
    const message = { isError: false, errorMessage: '' };

    this.setState({
      form: { ...form, isStaff: value },
      validation: { ...validation, company: message, branch: message },
    });
  };

  handleIsFitcoOps = value => {
    const { form, validation } = this.state;
    const message = { isError: false, errorMessage: '' };

    this.setState({
      form: { ...form, isFitcoOps: value },
      validation: { ...validation, company: message, branch: message },
    });
  };

  handleIsAllMerchantAndBranch = value => {
    const { form } = this.state;
    const { getMerchants } = this.props;

    this.setState(
      {
        form: {
          ...form,
          allMerchantAndBranch: value,
        },
        userMerchants: [],
      },
      () => {
        const params = { company_id: form.companyId };
        getMerchants(params);
      },
    );
  };

  handleSetRole = value => {
    this.setState({
      setRole: value,
    });
  };

  handleAddMerchantAndBranch = () => {
    const { userMerchants } = this.state;
    const {
      masterData: { merchantList },
    } = this.props;

    if (userMerchants.length < merchantList.length) {
      this.setState({
        userMerchants: [...this.state.userMerchants, defaultMerchant],
      });
    } else {
      const message = `There's no more available merchant to be added to the user.`;
      this.processMessage(message, 'warning');
    }
  };

  handleRemoveMerchantAndBranch = index => {
    this.setState(
      {
        userMerchants: this.state.userMerchants.filter((_, i) => i !== index),
        branchOptions: this.state.branchOptions.filter((_, i) => i !== index),
      },
      () => {},
    );
  };

  handleButtonSave = () => {
    const { editUser } = this.props;

    const {
      form: {
        userId,
        firstName,
        lastName,
        gender,
        birthday,
        companyId,
        roleId,
        email,
        phone,
        address,
        isStaff,
        isFitcoOps,
        allMerchantAndBranch,
        imageBase64,
      },
      userMerchants,
    } = this.state;

    const profilePageUrl = `/management/users/details/${CommonHelper.encryptObject({ userId })}`;

    const params = {
      data: {
        user_id: userId,
        user_details: {
          phone,
          email,
        },
        personal_details: {
          first_name: firstName,
          last_name: lastName,
          gender,
          date_of_birth: birthday,
          image: imageBase64,
        },
        user_settings: {
          is_staff: isStaff,
          is_fitco_ops: isFitcoOps,
          role_id: roleId,
          company_id: companyId,
          all_merchant_and_branch: allMerchantAndBranch,
          merchant_access: userMerchants,
        },
        user_address: {
          address: address.trim(),
        },
      },
    };

    if (this.validationStepPersonalInformation()) {
      this.setState(
        {
          isLoading: true,
        },
        () => {
          editUser(params)
            .then(() => {
              const {
                userManagement: { responseMessage },
                history,
              } = this.props;

              this.processMessage(responseMessage, 'success');
              this.setState(
                {
                  isLoading: false,
                },
                () => {
                  setTimeout(() => {
                    history.push(profilePageUrl);
                  }, 2000);
                },
              );
            })
            .catch(async error => {
              const message = error.data;
              await this.processMessage(message.messages, 'error');
              await this.setState({ isLoading: false });
            });
        },
      );
    }
  };
  // End Handle Change Field

  generatePopoverContent = text => {
    const content = <div className="p-5 text-10">{text}</div>;
    return content;
  };

  generateBranchOptions(index) {
    const { branchOptions } = this.state;

    const options = CommonHelper.renameKeyName(branchOptions[index] || [], 'branch_id', 'value');

    return options;
  }

  validationStepPersonalInformation() {
    const { form, validation } = this.state;

    let validForm = true;
    const firstNameValidation = { isError: false, errorMessage: '' };
    const birthdayValidation = { isError: false, errorMessage: '' };

    if (_.isEmpty(form.firstName)) {
      firstNameValidation.isError = true;
      firstNameValidation.errorMessage = 'Please enter your First Name';

      validForm = false;
    }

    if (_.isEmpty(form.birthday)) {
      birthdayValidation.isError = true;
      birthdayValidation.errorMessage = 'Please enter your Birthday';

      validForm = false;
    }

    this.setState({
      validation: {
        ...validation,
        firstName: firstNameValidation,
        birthday: birthdayValidation,
      },
    });

    return validForm;
  }

  generateContentStep() {
    const {
      form: {
        firstName,
        lastName,
        gender,
        birthday,
        isStaff,
        isFitcoOps,
        allMerchantAndBranch,
        // address,
        companyId,
        email,
        phone,
        roleId,
      },
      userMerchants,
      isResellerLogin,
    } = this.state;
    const {
      masterData: { merchantList },
    } = this.props;

    const merchantListCustom = CommonHelper.renameKeyName(merchantList, 'merchant_id', 'value');

    return (
      <Grid container direction="column" className="flex-wrap-unset">
        <Grid item lg md className="container-information">
          <Grid container spacing={2} direction="column">
            <Grid item lg md>
              <label className="text-12 main-title">Personal Information</label>
            </Grid>
            <Grid item lg md>
              <Grid container spacing={2}>
                <Grid item lg={6} md={6}>
                  <FormControl component="fieldset" fullWidth margin="normal">
                    <FormLabel component="label" className="text-12 title">
                      First Name
                    </FormLabel>
                    <TextInput
                      placeHolderText={'First Name'}
                      currentValue={firstName}
                      onChange={this.handleChangeFirstName}
                      isError={this.state.validation.firstName.isError}
                      errorMessage={this.state.validation.firstName.errorMessage}
                    />
                  </FormControl>
                </Grid>
                <Grid item lg={6} md={6}>
                  <FormControl component="fieldset" fullWidth margin="normal">
                    <FormLabel component="label" className="text-12 title">
                      Last Name
                    </FormLabel>
                    <TextInput
                      placeHolderText={'Last Name'}
                      currentValue={lastName}
                      onChange={this.handleChangeLastName}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item lg={6} md={6}>
                  <FormControl component="fieldset" fullWidth margin="normal">
                    <FormLabel component="label" className="text-12 title">
                      Email
                    </FormLabel>
                    <TextInput
                      placeHolderText={'Email'}
                      onChange={this.handleChangeEmail}
                      currentValue={email}
                      isError={this.state.validation.email.isError}
                      errorMessage={this.state.validation.email.errorMessage}
                      isDisable={isResellerLogin}
                    />
                  </FormControl>
                </Grid>
                <Grid item lg={6} md={6}>
                  <FormControl component="fieldset" fullWidth margin="normal">
                    <FormLabel component="label" className="text-12 title">
                      Phone Number
                    </FormLabel>
                    <TextInput
                      placeHolderText={'Phone Number'}
                      onChange={this.handleChangePhone}
                      currentValue={phone}
                      isError={this.state.validation.phone.isError}
                      errorMessage={this.state.validation.phone.errorMessage}
                      isDisable={isResellerLogin}
                      maxLength={14}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item lg={6} md={6}>
                  <FormControl component="fieldset" fullWidth margin="normal">
                    <FormLabel component="label" className="text-12 title">
                      Gender
                    </FormLabel>
                    <SelectInputMain
                      options={genderOptions}
                      size="large"
                      currentValue={gender}
                      onChange={this.handleChangeGender}
                    />
                  </FormControl>
                </Grid>
                <Grid item lg={6} md={6}>
                  <FormControl component="fieldset" fullWidth margin="normal">
                    <FormLabel component="label" className="text-12 title">
                      Birthday
                    </FormLabel>
                    <Form.Item>
                      <PickerInputDate
                        customIcon="ic-ffo-date-pick"
                        dateFormat="dd-MM-yyyy"
                        maxDate={currentDate}
                        defaultValue={birthday}
                        onChange={this.handleChangeBirthday}
                        toolbar={false}
                        isError={this.state.validation.birthday.isError}
                        errorMessage={this.state.validation.birthday.errorMessage}
                      />
                    </Form.Item>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <div className="divider-hr" />
            </Grid>
          </Grid>
        </Grid>
        {!isResellerLogin && (
          <Grid item lg md className="container-information">
            <Grid container spacing={2} direction="column" className="flex-wrap-unset">
              <Grid item>
                <label className="text-12 main-title">Admin Access</label>
              </Grid>
              <Grid item>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <FormControl component="fieldset" fullWidth margin="normal">
                      <FormLabel component="label">
                        <span className="flex-row">
                          <CheckInput
                            colorCheck="primary"
                            labelText="Has Admin Role"
                            onCheck={this.handleSetRole}
                            currentValue={this.state.setRole}
                          />
                        </span>
                      </FormLabel>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl component="fieldset" fullWidth margin="normal">
                      <SelectInputRole
                        onChange={this.handleChangeRole}
                        currentValue={roleId}
                        errorMessage={this.state.validation.role.errorMessage}
                        validateStatus={this.state.validation.role.isError}
                        includeAllData
                        disabled={!this.state.setRole}
                      />
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <span className="flex-row">
                      <CheckInput
                        colorCheck="primary"
                        labelText="Staff Benefit"
                        onCheck={this.handleIsStaff}
                        currentValue={isStaff}
                      />
                      <FitcoPopover
                        content={this.generatePopoverContent(
                          "Tick this to give exclusive staff benefits (eg. Gate Access & 20FIT Staff's Membership).",
                        )}
                        placement={FitcoPopoverPlacement.right}
                        trigger={FitcoPopoverTrigger.Hover}
                      />
                    </span>
                  </Grid>
                  <Grid item xs={6}>
                    <span className="flex-row">
                      <CheckInput
                        colorCheck="primary"
                        labelText="Daily Operation Access"
                        onCheck={this.handleIsFitcoOps}
                        currentValue={isFitcoOps}
                      />
                      <FitcoPopover
                        content={this.generatePopoverContent(
                          'Tick this to give exclusive access to all companies, merchants & branches.',
                        )}
                        placement={FitcoPopoverPlacement.right}
                        trigger={FitcoPopoverTrigger.Hover}
                      />
                    </span>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <div className="divider-hr" />
              </Grid>
            </Grid>
          </Grid>
        )}
        {!isResellerLogin && (
          <Grid item lg md className="container-information">
            <Grid container spacing={2} direction="column" className="flex-wrap-unset">
              <Grid item>
                <label className="text-12 main-title">Company, Merchant & Branch Access</label>
              </Grid>
              <Grid item>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <FormControl component="fieldset" fullWidth margin="normal">
                      <FormLabel component="label" className="text-12 title">
                        Company
                      </FormLabel>
                      <SelectInputCompany
                        placeHolder="Select Company"
                        onChange={this.handleChangeCompany}
                        currentValue={companyId}
                        errorMessage={this.state.validation.company.errorMessage}
                        validateStatus={this.state.validation.company.isError}
                        includeAllData
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl component="fieldset" fullWidth margin="normal">
                      <FormLabel
                        component="label"
                        className="text-12 title"
                        style={{ height: '14px' }}
                      >
                        Merchant & Branch
                        <FitcoPopover
                          content={this.generatePopoverContent(
                            'Use this flag to give unlimited access to all merchants and branches.',
                          )}
                          placement={FitcoPopoverPlacement.right}
                          trigger={FitcoPopoverTrigger.Hover}
                        />
                      </FormLabel>
                      <CheckInputOutlineBackground
                        labelText="All Access"
                        onCheck={this.handleIsAllMerchantAndBranch}
                        currentValue={allMerchantAndBranch}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                {userMerchants.map((record, index) => {
                  return (
                    <div style={{ padding: '0 8px' }} key={index}>
                      <Grid container spacing={2} className="container-merchant-branch">
                        <Grid item lg={6} md={6}>
                          <FormControl component="fieldset" fullWidth margin="normal">
                            <FormLabel component="label" className="text-12 title">
                              Merchant
                            </FormLabel>
                            <SelectInputSearchMain
                              options={[...merchantListCustom]}
                              onChange={selectedMerchantID => {
                                this.handleChangeMerchant(selectedMerchantID, index);
                              }}
                              currentValue={record.merchant_id}
                              size={'large'}
                              placeholder={'Select Merchant'}
                              errorMessage={this.state.validation.merchant.errorMessage}
                              validateStatus={this.state.validation.merchant.isError}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item lg={6} md={6}>
                          <FormControl component="fieldset" fullWidth margin="normal">
                            <FormLabel component="label" className="text-12 title">
                              Branch
                            </FormLabel>
                            <Grid container spacing={2}>
                              <Grid item xs={10}>
                                <SelectSearchMultiple
                                  options={[allBranchOption, ...this.generateBranchOptions(index)]}
                                  onChange={selectedBranchID => {
                                    this.handleChangeBranch(selectedBranchID, index);
                                  }}
                                  currentValue={record.branch_id}
                                  size={'large'}
                                  placeholder={'Select Branch'}
                                  errorMessage={this.state.validation.branch.errorMessage}
                                  validateStatus={this.state.validation.branch.isError}
                                />
                              </Grid>
                              <Grid item xs={2}>
                                <div style={{ marginTop: 3 }}>
                                  <ButtonIconMain
                                    icon="ic-ffo-bin"
                                    type="negative"
                                    size="sm"
                                    onClick={() => this.handleRemoveMerchantAndBranch(index)}
                                  />
                                </div>
                              </Grid>
                            </Grid>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </div>
                  );
                })}
                <Grid container spacing={2} direction="column" className={`flex-wrap-unset`}>
                  <Grid item style={{ marginTop: '20px' }}>
                    <ButtonMain
                      type="primary"
                      startIcon="ic-ffo-add"
                      size="md"
                      labelText="Add Merchant & Branch"
                      onClick={this.handleAddMerchantAndBranch}
                      disabled={allMerchantAndBranch}
                    />
                  </Grid>
                </Grid>
              </Grid>
              {/* <Grid item>
                <div className="divider-hr" />
              </Grid> */}
            </Grid>
          </Grid>
        )}
        {/* <Grid item lg md className="container-information">
          <Grid container spacing={2} direction="column">
            <Grid item>
              <label className="text-12 main-title">Contact Information</label>
            </Grid>
            <Grid item>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FormControl component="fieldset" fullWidth margin="normal">
                    <FormLabel component="label" className="text-12 title">
                      Address
                    </FormLabel>
                    <TextAreaMain
                      onChange={item => {
                        this.handleChangeAddress(item);
                      }}
                      currentValue={address}
                      placeholder="Please enter your address"
                      rows={4}
                      validateStatus={this.state.validation.address.isError}
                      errorMessage={this.state.validation.address.errorMessage}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid> */}
      </Grid>
    );
  }

  processMessage(messages, type) {
    const convertedMessage = CommonHelper.generateMessage(messages);

    const paramInformation = {
      isOpen: true,
      message: convertedMessage,
      snackbarType: type,
    };

    this.setState({
      toastInformation: paramInformation,
    });
  }

  render() {
    const { form, toastInformation, isLoading } = this.state;
    const profilePageUrl = `/management/users/details/${CommonHelper.encryptObject({
      userId: form.userId,
    })}`;
    let renderElement = (
      <div className="container-page-scrolling-area">
        <SkeletonDetails />
      </div>
    );

    if (!isLoading) {
      renderElement = (
        <div>
          <Helmet title="FITCO | User Managements - Edit User" />
          <div className="container-page-user-edit scroll-container">
            <div className="container-page-scrolling-area">
              <Grid container direction="column" className="flex-wrap-unset">
                <Grid item lg md className="section-page-header">
                  <div className="breadcrumbs-section">
                    <Breadcrumbs aria-label="breadcrumb" className="text-12">
                      <Link
                        className="text-12"
                        color="inherit"
                        href={profilePageUrl}
                        onClick={event => {
                          this.handleClick(event, profilePageUrl);
                        }}
                      >
                        <i className="ic-ffo-account container-icon-prefix size-16" /> User
                        Management
                      </Link>
                      <label className="text-12" color="inherit">
                        Edit User
                      </label>
                    </Breadcrumbs>
                  </div>
                </Grid>
                <Grid item lg md className="section-page-body">
                  <div className="main-card dotted">
                    <Grid container>
                      <Grid item lg={3} md={4}>
                        <div className="container-profile">
                          <Grid container direction="column" justify="flex-start">
                            <Grid item lg md className="image">
                              <UploadImage
                                onChange={item => this.handleChangeImage(item)}
                                defaultValue={form.imageBase64}
                                maxSize={1}
                              />
                            </Grid>
                            <Grid item lg md className="action">
                              <ButtonMain
                                type="primary"
                                size="md"
                                labelText="Save"
                                onClick={this.handleButtonSave}
                                isLoading={this.state.isLoading}
                              />
                            </Grid>
                            <Grid item lg md>
                              <ButtonMain
                                type="ghost"
                                size="md"
                                labelText="Cancel"
                                onClick={event => this.handleClick(event, profilePageUrl)}
                              />
                            </Grid>
                          </Grid>
                        </div>
                      </Grid>
                      <Grid item lg={9} md={8}>
                        {this.generateContentStep()}
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
                <Grid item lg md className="section-page-footer" />
              </Grid>
            </div>
          </div>
          <SnackBarSimple
            open={toastInformation.isOpen}
            durationHide={2000}
            message={toastInformation.message}
            onClickClose={this.handleClose}
            snackbarType={toastInformation.snackbarType}
            anchor={optionToast}
          />
        </div>
      );
    }

    return renderElement;
  }
}

const mapDispatchToProps = dispatch => ({
  getMerchants: params => getMerchantList(dispatch, params),
  getUserDetails: params => getUserDetailsById(dispatch, params),
  getBranches: params => getBranchList(dispatch, params),
  checkEmailOrPhone: params => validateEmailOrPhone(dispatch, params),
  editUser: params => updateUser(dispatch, params),
});

const mapStateToProps = ({ masterData, userManagement }) => ({
  masterData,
  userManagement,
});

UserEdit.propTypes = {
  checkEmailOrPhone: PropTypes.func,
  checkUserAccessPermission: PropTypes.func,
  editUser: PropTypes.func,
  getBranches: PropTypes.func,
  getMerchants: PropTypes.func,
  getUserDetails: PropTypes.func,
  history: PropTypes.object,
  masterData: PropTypes.object,
  match: PropTypes.object,
  userManagement: PropTypes.object,
};

const shell = compose(connect(mapStateToProps, mapDispatchToProps));

export default shell(AuthenticationAccessPages(UserEdit));
