/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unused-state */
import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import {
  Grid,
  Breadcrumbs,
  Link,
  FormControl,
  FormLabel,
  ExpansionPanel,
  ExpansionPanelSummary,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import _ from 'lodash';
// Components
import {
  InputMain,
  SnackBarSimple,
  ButtonMain,
  RadioInputImage,
  SelectInputMain,
  CheckInputOutlineBackground,
  UploadDocument,
  LabelStatusMain,
  ChartLineBarQuota,
  SelectInputSearchMain,
  TextAreaMain,
  PickerInputDate
} from '../../../../components/Index';
// Style
import './UserFreezeMembershipStyle.scss';
import themes from '../../../../themes/Index';
// Helper
import {
  MasterDataHelper,
  CommonHelper,
  PointBoosterStatus,
  paymentMethodOptionCode,
} from '../../../../helpers/Index';
// Action
import {
  getMembershipDetails,
  getMembershipPurchasedPrice,
  getMemberDetails,
  requestFreezemembership,
} from '../../../../services/api/UserManagement';
import { getBranchList } from '../../../../services/api/MasterDataApi';

const optionToash = {
  vertical: 'top',
  horizontal: 'right',
};

const resetValidation = { isError: '', errorMessage: '' };

const freezePeriodOptions = MasterDataHelper.periodInMonthOption();
const freezeTypeOption = MasterDataHelper.freezeTypeOption;
const paymentTypeOption = MasterDataHelper.paymentMethodOption.filter(
  item =>
    item.value === paymentMethodOptionCode.bank_transfer ||
    item.value === paymentMethodOptionCode.credit_card ||
    item.value === paymentMethodOptionCode.giveaway,
);
const bankTransferOption = MasterDataHelper.bankTransferOption;
const reasonOption = MasterDataHelper.reasonOption;
const currentDate = CommonHelper.currentDate('YYYY-MM-DD');

const initialValidation = {
  paymentMethod: { isError: '', errorMessage: '' },
  otherReason: { isError: '', errorMessage: '' },
  fileBase64: { isError: '', errorMessage: '' },
  bank: { isError: '', errorMessage: '' },
  branchId: { isError: '', errorMessage: '' },
  traceNumber: { isError: '', errorMessage: '' },
  imageReceipt: { isError: '', errorMessage: '' },
  reasonType: { isError: '', errorMessage: '' },
};

const initialForm = {
  freezePeriod: freezePeriodOptions[0].value,
  startDate: currentDate,
  paymentMethod: paymentTypeOption[0].value,
  bank: bankTransferOption[0],
  otherReason: null,
  freezeType: freezeTypeOption[1],
  isMedicalReason: false,
  fileBase64: null,
  merchantId: null,
  branchId: null,
  traceNumber: null,
  imageReceipt: null,
  reasonType: reasonOption[0].value,
  applyExemption: true
};

class UserFreezeMembership extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      validation: CommonHelper.objectCloning(initialValidation),
      form: CommonHelper.objectCloning(initialForm),
      isLoading: false,
      isLoadingGetData: false,
      toastInformation: {
        isOpen: false,
        message: '',
        snackbarType: 'warning',
      },
      isExpand: false,
      userId: null,
    };
  }

  async componentDidMount() {
    const { form } = this.state;
    const { usersReducer } = this.props;

    await this.setState({ form: { ...form, merchantId: usersReducer.activeMerchant } });
    await this.getNewBranchList();
    await this.getAllData();
  }

  async componentWillReceiveProps(nextProps) {
    const { form } = this.state;
    const { usersReducer } = this.props;
    const { getNewBranchList, getAllData } = this;

    const isEqualActiveMerchantId = _.isEqual(
      usersReducer.activeMerchant,
      nextProps.usersReducer.activeMerchant,
    );

    if (!isEqualActiveMerchantId) {
      await this.setState({ form: { ...form, merchantId: nextProps.usersReducer.activeMerchant } });
      await getNewBranchList();
      await getAllData();
    }
  }

  componentWillUnmount() {}

  getNewBranchList = async () => {
    const {
      form,
      form: { merchantId },
    } = this.state;
    const { getBranch } = this.props;
    await getBranch({
      merchant_id: merchantId,
    }).then(async () => {
      const {
        masterData: { branchList },
      } = this.props;
      await this.setState({
        form: {
          ...form,
          branchId: branchList.length > 0 ? branchList[0].branch_id || undefined : undefined,
        },
      });
    });
  };

  getAllData = () => {
    const {
      getMembership,
      getMembershipPurchased,
      getMember,
      match: { params },
    } = this.props;

    const param = {
      user_point_booster_id: params.user_point_booster_id,
    };
    getMembership(param);
    getMembershipPurchased(param);
    getMember(param).then(respone => {
      this.setState({ userId: respone.data.user_id });
    });
  };

  handleClick = (event, value) => {
    const { history } = this.props;
    event.preventDefault();
    history.push(value);
  };

  handleCloseToash = () => {
    const { toastInformation } = this.state;
    this.setState({ toastInformation: { ...toastInformation, isOpen: false } });
  };

  handleButtonCancel = () => {
    const { history } = this.props;
    history.push(
      `/management/users/details/${CommonHelper.encryptObject({ userId: this.state.userId })}`,
    );
  };

  handleChangeStartDate = startDate => {
    const { form } = this.state;

    this.setState({
      form: { ...form, startDate },
    });

  }

  handleChangeFreezePeriod = freezePeriod => {
    const { form, validation } = this.state;

    this.setState({
      form: { ...form, freezePeriod },
    });
  };

  handleChangePaymentMethod = paymentMethod => {
    const { form, validation } = this.state;
    const message = CommonHelper.objectCloning(initialValidation.paymentMethod);
    message.isError = !paymentMethod ? 'error' : '';
    message.errorMessage = !paymentMethod ? 'Please choose Period' : '';

    this.setState({
      form: { ...form, paymentMethod },
      validation: { ...validation, paymentMethod: message },
    });
  };

  handleChangeOtherReason = otherReason => {
    const { form, validation } = this.state;
    const message = CommonHelper.objectCloning(initialValidation.otherReason);
    message.isError = !otherReason ? 'error' : '';
    message.errorMessage = !otherReason ? 'Please enter Other Reason' : '';

    this.setState({
      form: { ...form, otherReason },
      validation: { ...validation, otherReason: message },
    });
  };

  handleChangeReasonType = reasonType => {
    const { form, validation } = this.state;
    const message = CommonHelper.objectCloning(initialValidation.reasonType);
    message.isError = !reasonType ? 'error' : '';
    message.errorMessage = !reasonType ? 'Please select Reason Type' : '';

    this.setState({
      form: { ...form, reasonType, applyExemption: reasonType !== 'others' },
      validation: { ...validation, reasonType: message },
    });
  };

  handleChangeFreezeType = value => {
    const { form } = this.state;
    this.setState({ form: { ...form, freezeType: value } });
  };

  handleIsMedicalReason = value => {
    const { form } = this.state;

    this.setState({
      form: {
        ...form,
        isMedicalReason: value,
      },
    });
  };

  handleApplyExemption = value => {
    const { form } = this.state;

    this.setState({
      form: {
        ...form,
        applyExemption: value,
        fileBase64: null
      },
    });
  }

  handleChangeFile = fileBase64 => {
    const { form, validation } = this.state;
    const message = CommonHelper.objectCloning(initialValidation.fileBase64);
    message.isError = !fileBase64 ? 'error' : '';
    message.errorMessage = !fileBase64 ? 'Please choose Image' : '';

    this.setState({
      form: { ...form, fileBase64: fileBase64[0].base64file },
      validation: { ...validation, fileBase64: message },
    });
  };

  handleChangeImageRecept = imageReceipt => {
    const { form, validation } = this.state;
    const message = CommonHelper.objectCloning(initialValidation.imageReceipt);
    message.isError = !imageReceipt ? 'error' : '';
    message.errorMessage = !imageReceipt ? 'Please choose Image' : '';

    this.setState({
      form: { ...form, imageReceipt: imageReceipt[0].base64file },
      validation: { ...validation, imageReceipt: message },
    });
  };

  getPayload = () => {
    const { form } = this.state;
    const {
      match: { params },
    } = this.props;

    const payload = {
      user_point_booster_id: params.user_point_booster_id,
      period: form.freezePeriod,
      start_date: form.startDate,
      notes: form.reasonType === 'others' ? form.otherReason : form.reasonType,
      apply_exemption: form.applyExemption,
      attachment: form.fileBase64,
      branch_id: form.branchId,
      payment_data: {
        payment_type: form.paymentMethod,
        bank: form.paymentMethod === 'bank_transfer' ? form.bank.value : null,
      },
    };

    return payload;
  };

  handleButtonSubmit = () => {
    const { history, crateRequest } = this.props;
    const { getPayload, validationForm } = this;

    if (validationForm()) {
      const param = getPayload();

      this.setState({ isLoading: true }, () => {
        crateRequest(param)
          .then(response => {
            const message = response.messages;

            this.processMessage(message, 'success');
            this.setState({ isLoading: false }, () => {
              setTimeout(async () => {
                await history.push(
                  `/management/users/details/${CommonHelper.encryptObject({
                    userId: this.state.userId,
                  })}`,
                );
              }, 2000);
            });
          })
          .catch(error => {
            const message = error.data;
            this.setState({ isLoading: false });
            this.processMessage(message.messages, 'error');
          });
      });
    }
  };

  handleExpandChange = (event, isExpanded) => {
    this.setState({ isExpand: isExpanded });
  };

  handleSelectBank = value => {
    const { form } = this.state;
    this.setState({ form: { ...form, bank: value } });
  };

  handleSelectChangeBranch = value => {
    const { form } = this.state;
    this.setState({ form: { ...form, branchId: value } });
  };

  handleChangeTraceNumber = value => {
    const { form, validation } = this.state;
    const message = CommonHelper.objectCloning(initialValidation.traceNumber);
    message.isError = !value ? 'error' : '';
    message.errorMessage = !value ? 'trace Number is empty' : '';
    const valueChange = value.length <= 100 ? value.trim() : '';

    this.setState({
      form: { ...form, traceNumber: valueChange },
      validation: { ...validation, traceNumber: message },
    });
  };

  validationForm = () => {
    this.setState({
      isLoading: true,
    });

    const { form, validation } = this.state;
    const {
      userManagement: { membershipPurchasedPriceDetails },
    } = this.props;

    let passCheck = true;
    let paramValidation02 = resetValidation;
    let paramValidation03 = resetValidation;
    let paramValidation04 = resetValidation;
    let paramValidation05 = resetValidation;
    let paramValidation06 = resetValidation;
    let paramValidation07 = resetValidation;


    if (form.reasonType === 'others' && _.isEmpty(form.otherReason)) {
      paramValidation02 = {
        isError: 'error',
        errorMessage: 'Please enter reason',
      };

      passCheck = false;
    }

    if (form.paymentMethod === undefined) {
      paramValidation03 = {
        isError: 'error',
        errorMessage: 'Please select payment method',
      };

      passCheck = false;
    }

    if (form.paymentMethod === paymentMethodOptionCode.edc) {
      if (form.branchId === undefined) {
        paramValidation04 = {
          isError: 'error',
          errorMessage: 'Please select Branch',
        };

        passCheck = false;
      }
      if (_.isEmpty(form.traceNumber)) {
        paramValidation05 = {
          isError: 'error',
          errorMessage: 'Please enter Trace Number',
        };

        passCheck = false;
      }
      if (_.isEmpty(form.imageReceipt)) {
        paramValidation06 = {
          isError: 'error',
          errorMessage: 'Please choose Image Receipt',
        };

        passCheck = false;
      }
    }

    if(form.paymentMethod === 'giveaway' && _.isEmpty(form.fileBase64)) {
      paramValidation07 = {
        isError: 'error',
        errorMessage: 'Please choose File',
      };

      passCheck = false;
    }

    if (!passCheck) {
      this.setState({
        isLoading: false,
        validation: {
          ...validation,
          otherReason: paramValidation02,
          paymentMethod: paramValidation03,
          branchId: paramValidation04,
          traceNumber: paramValidation05,
          imageReceipt: paramValidation06,
          fileBase64: paramValidation07,
        },
      });
    }

    return passCheck;
  };

  processMessage(messages, type) {
    const convertedMessage = CommonHelper.generateMessage(messages);

    const paramInformation = {
      isOpen: true,
      message: convertedMessage,
      snackbarType: type,
    };

    this.setState({
      toastInformation: paramInformation,
    });
  }

  renderStatus = value => {
    let renderElement = null;
    if (value === PointBoosterStatus.Freeze) {
      renderElement = (
        <LabelStatusMain value={MasterDataHelper.statusPointBooster[0].name} type="default" />
      );
    } else if (value === PointBoosterStatus.Stopped) {
      renderElement = (
        <LabelStatusMain value={MasterDataHelper.statusPointBooster[1].name} type="danger" />
      );
    } else if (value === PointBoosterStatus.Active) {
      renderElement = (
        <LabelStatusMain value={MasterDataHelper.statusPointBooster[2].name} type="complete" />
      );
    } else if (value === PointBoosterStatus.Inactive) {
      renderElement = (
        <LabelStatusMain value={MasterDataHelper.statusPointBooster[3].name} type="default" />
      );
    }
    return <div className="container-status">{renderElement}</div>;
  };

  renderMembershipCard = () => {
    const {
      userManagement: { membershipDetails },
    } = this.props;
    const boosterBundled = membershipDetails.booster_bundled || [];

    const startDate = CommonHelper.dateTimeParseNewFormat(
      membershipDetails.start_date,
      'DD MMM YYYY',
    );
    const endDate = CommonHelper.dateTimeParseNewFormat(membershipDetails.end_date, 'DD MMM YYYY');
    const RenewDate = membershipDetails.renew_date
      ? CommonHelper.dateTimeParseNewFormat(membershipDetails.renew_date, 'DD MMM YYYY')
      : '';

    return (
      <ExpansionPanel
        className="membership-card-details"
        expanded={membershipDetails.is_bundling ? this.state.isExpand : false}
        onChange={this.handleExpandChange}
      >
        <ExpansionPanelSummary>
          <Grid container direction="row" wrap={'nowrap'}>
            <Grid item lg={10} md={10}>
              <Grid container direction="column">
                <Grid item lg md className="membership-title-text">
                  <label className="text-14 text-bold pr-8">
                    {membershipDetails.product_name}{' '}
                  </label>
                  {this.renderStatus(membershipDetails.status)}
                </Grid>
                <Grid item className="pt-16">
                  <Grid container>
                    <Grid item lg={2} md={2} className="membership-items-text">
                      <label className="text-12 text-opacity-50">Start Date</label>
                      <label className="text-12 text-bold pt-4">{startDate}</label>
                    </Grid>
                    <Grid>
                      <div className="divider-hr-vertical" />
                    </Grid>
                    <Grid item lg={2} md={2} className="membership-items-text">
                      <label className="text-12 text-opacity-50">End Date</label>
                      <label className="text-12 text-bold pt-4 color-downy">{endDate}</label>
                    </Grid>
                    <Grid>
                      <div className="divider-hr-vertical" />
                    </Grid>
                    {membershipDetails.renew_date && (
                      <Grid item lg={2} md={2} className="membership-items-text">
                        <label className="text-12 text-opacity-50">Renew</label>
                        <label className="text-12 text-bold pt-4 color-downy">{RenewDate}</label>
                      </Grid>
                    )}
                    {membershipDetails.is_bundling ? (
                      <Grid item lg={3} md={3} className="membership-items-text">
                        <label className="text-12 text-opacity-50">Multiple membership</label>
                        <label className="text-12 pt-4">( Click this Row expand )</label>
                        {/* <Link onClick={() => this.handleShowMore(row.user_point_booster_id)}>
                              Show More
                            </Link> */}
                      </Grid>
                    ) : (
                      <Grid item lg={3} md={3} className="membership-items-text">
                        <label className="text-12 text-opacity-50">{membershipDetails.quota ? 'Quota' : ''}</label>
                        <label className="text-12 text-bold pt-4 color-downy">{membershipDetails.quota ? membershipDetails.additional_info : ''}</label>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={2} md={2} className="membership-items-text-duration">
              <Grid container direction="row">
                <Grid item lg={12} md={12}>
                  <Grid
                    container
                    direction="column"
                    justify="center"
                    className="membership-items-text-duration"
                  >
                    <Grid item lg md>
                      <label className="text-18 text-bold">
                        {membershipDetails.full_period} Days
                      </label>
                    </Grid>
                    <Grid item lg md>
                      <label className="text-14">
                        {membershipDetails.remaining_period} days remaining
                      </label>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ExpansionPanelSummary>
        <Grid container direction="column">
          <Grid>
            <div className="divider-hr" />
          </Grid>
          <Grid item>
            <Grid container direction="column" spacing={3} className="membership-card-bundle">
              <Grid item lg={8} md={8} className="container">
                <ChartLineBarQuota
                  colorBar={themes.palette.downy}
                  customElementSubProps={
                    <label className="text-12">{`${membershipDetails.full_period} Days - ${membershipDetails.remaining_period} days remaining`}</label>
                  }
                  customElementInfoProps={
                    <Grid container justify="space-between">
                      <Grid item>
                        <label className="text-12 text-opacity-50">Start At </label>
                        <label className="text-12 text-bold">{startDate}</label>
                      </Grid>
                      <Grid item>
                        <label className="text-12 text-opacity-50">End Date </label>
                        <label className="text-12 text-bold">{endDate}</label>
                      </Grid>
                    </Grid>
                  }
                  infoBar={false}
                  totalUsage={membershipDetails.usage || 0}
                />
              </Grid>
              <Grid item lg={12} md={12}>
                <Grid container direction="row" spacing={2}>
                  {!_.isEmpty(boosterBundled) ? (
                    <>
                      {boosterBundled.map((item, index) => (
                        <Grid
                          item
                          lg={4}
                          md={4}
                          key={index}
                          className="membership-card-bundle-details"
                        >
                          <ChartLineBarQuota
                            iconPrefix="ic-ffo-check-mark"
                            labelText={item.product_name}
                            colorBar={themes.palette.cornflowerBlue}
                            customElementSubProps={
                              <label className="text-12">{`${item.full_period} - ${item.remaining_period}`}</label>
                            }
                            customElementInfoProps={
                              <Grid container justify="space-between">
                                <Grid item>
                                  <label className="text-10 text-opacity-50">Start At </label>
                                  <label className="text-10 text-bold">
                                    {CommonHelper.dateTimeParseNewFormat(
                                      item.start_date,
                                      'DD MMM YYYY',
                                    )}
                                  </label>
                                </Grid>
                                <Grid item>
                                  <label className="text-10 text-opacity-50">End Date </label>
                                  <label className="text-10 text-bold">
                                    {CommonHelper.dateTimeParseNewFormat(
                                      item.end_date,
                                      'DD MMM YYYY',
                                    )}
                                  </label>
                                </Grid>
                              </Grid>
                            }
                            infoBar={false}
                            totalUsage={item.usage || 0}
                          />
                        </Grid>
                      ))}
                    </>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ExpansionPanel>
    );
  };

  renderPaymentMethod = () => {
    return (
      <>
        <Grid item>
          <FormControl component="fieldset" fullWidth margin={'normal'} className="mt-8">
            <FormLabel component="label" className="form-label">
              Payment Method
            </FormLabel>
            <SelectInputMain
              placeholder="Select Payment Method"
              options={paymentTypeOption}
              size="large"
              currentValue={this.state.form.paymentMethod}
              onChange={this.handleChangePaymentMethod}
              errorMessage={this.state.validation.paymentMethod.errorMessage}
              validateStatus={this.state.validation.paymentMethod.isError}
            />
          </FormControl>
        </Grid>
        {this.state.form.paymentMethod === 'bank_transfer' && (
          <Grid item className="ml-8">
            <FormControl component="fieldset" fullWidth>
              <RadioInputImage
                data={bankTransferOption}
                onSelect={this.handleSelectBank}
                direction="column"
                defaultValue={this.state.form.bank}
                imageWidth="60px"
              />
            </FormControl>
          </Grid>
        )}
        {this.state.form.paymentMethod === paymentMethodOptionCode.edc && (
          <>
            <Grid item>
              <FormControl component="fieldset" fullWidth margin={'normal'} className="mt-8">
                <FormLabel component="label" className="form-label">
                  Invoice Trace Number
                </FormLabel>
                <InputMain
                  placeholder={'Enter Trace Number'}
                  value={this.state.form.traceNumber}
                  onChange={this.handleChangeTraceNumber}
                  validateStatus={this.state.validation.traceNumber.isError}
                  errorMessage={this.state.validation.traceNumber.errorMessage}
                  isNumber
                />
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl component="fieldset" fullWidth margin={'normal'} className="mt-8">
                <UploadDocument
                  labelText="Upload Document (img)"
                  onFileChange={this.handleChangeImageRecept}
                  fileType="img"
                  errorMessage={this.state.validation.imageReceipt.errorMessage}
                  validateStatus={this.state.validation.imageReceipt.isError}
                />
              </FormControl>
            </Grid>
          </>
        )}
      </>
    );
  };

  renderFreezeType = () => {
    const {
      userManagement: { membershipPurchasedPriceDetails, membershipDetails },
    } = this.props;

    const {
      form: { freezePeriod, applyExemption, paymentMethod },
    } = this.state;

    return (
      <Grid container direction="column">
        <Grid item className="pt-24">
          <FormControl component="fieldset">
            <FormLabel component="label" className="form-label membership-items-text">
              <label className="text-12">Total Freeze Fee Charge</label>
              <label className="text-16 text-bold">
                Rp {CommonHelper.formatCurrency(membershipDetails.freeze_fee * freezePeriod)}
              </label>
            </FormLabel>
          </FormControl>
        </Grid>
        {this.renderPaymentMethod()}
        <Grid item>
            <FormControl component="fieldset" fullWidth>
              <FormLabel component="label" className="text-12 field-title text-transparent">Unique Code</FormLabel>
              <CheckInputOutlineBackground
                currentValue={applyExemption}
                labelText="Apply Freeze Exemption"
                onCheck={this.handleApplyExemption}
                size="large"
              />
            </FormControl>              
          </Grid>
          <Grid item lg={12} md={12} className="pt-16">
            <UploadDocument
              labelText="Upload Document (pdf/img)"
              onFileChange={this.handleChangeFile}
              fileType="pdf/img"
              errorMessage={this.state.validation.fileBase64.errorMessage}
              validateStatus={this.state.validation.fileBase64.isError}
            />
          </Grid>
      </Grid>
    );
  };

  renderPurchasedPriceDetails = () => {
    const {
      userManagement: { membershipPurchasedPriceDetails },
    } = this.props;

    return (
      <Grid container className="pt-24">
        <Grid item lg={2} md={2} className="membership-items-text">
          <label className="text-12 text-opacity-50">Membership Price</label>
          <label className="text-12 text-bold pt-4">
            Rp {CommonHelper.formatCurrency(membershipPurchasedPriceDetails.membership_price)}
          </label>
        </Grid>
        <Grid item lg={2} md={2} className="membership-items-text">
          <label className="text-12 text-opacity-50">Payment Method</label>
          <label className="text-12 text-bold pt-4">
            {membershipPurchasedPriceDetails.payment_method}
          </label>
        </Grid>
        <Grid item lg={8} md={8} className="membership-items-text">
          <label className="text-12 text-opacity-50">Purchased Date</label>
          <label className="text-12 text-bold pt-4">
            {CommonHelper.dateTimeParseNewFormat(
              membershipPurchasedPriceDetails.purchased_date,
              'DD MMMM YYYY',
            )}
          </label>
        </Grid>
      </Grid>
    );
  };

  renderMemberDetails = () => {
    const {
      userManagement: { memberDetails },
    } = this.props;

    return (
      <Grid container className="pt-24" direction="column">
        <Grid item lg={12} md={12}>
          <label className="text-12 text-opacity-50 text-bold">MEMBER DETAILS</label>
        </Grid>
        <Grid item lg={12} md={12}>
          <Grid container direction="row" className="membership-member-details white-lilac">
            <Grid item lg={4} md={4}>
              <label className="text-12">Name</label>
            </Grid>
            <Grid item lg={8} md={8}>
              <label className="text-12 text-bold">{memberDetails.user_name}</label>
            </Grid>
          </Grid>
          <Grid container direction="row" className="membership-member-details">
            <Grid item lg={4} md={4}>
              <label className="text-12">Email</label>
            </Grid>
            <Grid item lg={8} md={8}>
              <label className="text-12 text-bold">{memberDetails.email}</label>
            </Grid>
          </Grid>
          <Grid container direction="row" className="membership-member-details white-lilac">
            <Grid item lg={4} md={4}>
              <label className="text-12">Phone Number</label>
            </Grid>
            <Grid item lg={8} md={8}>
              <label className="text-12 text-bold">{memberDetails.phone}</label>
            </Grid>
          </Grid>
          <Grid container direction="row" className="membership-member-details">
            <Grid item lg={4} md={4}>
              <label className="text-12">Recurring Date</label>
            </Grid>
            <Grid item lg={8} md={8}>
              <label className="text-12 text-bold">{memberDetails.recurring_date}</label>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  render() {
    const {
      masterData: { branchList },
      userManagement: { membershipPurchasedPriceDetails },
    } = this.props;
    const newBranchList = CommonHelper.renameKeyName(branchList, 'branch_id', 'value');

    return (
      <div>
        <Helmet title="FITCO | Membership - Freeze Membership" />
        <div className="container-page-user-freeze-membership scroll-container-invisible">
          <div className="container-page-scrolling-area">
            <Grid container direction="column">
              <Grid item lg md className="section-page-header">
                <Grid container>
                  <Grid item>
                    <div className="breadcrumbs-section">
                      <Breadcrumbs aria-label="breadcrumb">
                        <Link
                          className="text-12"
                          color="inherit"
                          href="/master-data/product-category"
                          onClick={event => {
                            this.handleClick(event, '/management/users');
                          }}
                        >
                          <i className="icon-slot ic-ffo-account" /> Users Managements
                        </Link>
                        <Link
                          className="text-12"
                          color="inherit"
                          href="/master-data/product-category"
                          onClick={event => {
                            this.handleClick(
                              event,
                              `/management/users/details/${CommonHelper.encryptObject({
                                userId: this.state.userId,
                              })}`,
                            );
                          }}
                        >
                          User Profile
                        </Link>
                        <label className="text-12" color="inherit">
                          Freeze Membership
                        </label>
                      </Breadcrumbs>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item lg md className="section-page-body">
                <div className="freeze-membership-details-card">
                  <Grid container>
                    <label className="text-16 text-bold text-opacity-50">Freeze Membership</label>
                  </Grid>
                  <Grid container className="pt-4">
                    {this.renderMembershipCard()}
                  </Grid>
                  {this.renderPurchasedPriceDetails()}
                  {this.renderMemberDetails()}
                  <Grid className="pt-24" container>
                    <Grid container spacing={3}>
                      <Grid item lg={6} md={6}>
                        <Grid container direction="column">
                          <Grid container spacing={2}>
                            <Grid item lg={6} md={6}>
                              <FormControl component="fieldset" fullWidth>
                                <FormLabel component="label" className="form-label">
                                  Start Date
                                </FormLabel>
                                <PickerInputDate
                                  customIcon="ic-ffo-date-pick"
                                  dateFormat="dd/MM/yyyy"
                                  defaultValue={this.state.form.startDate}
                                  onChange={this.handleChangeStartDate}
                                  toolbar={false}
                                />
                              </FormControl>
                            </Grid>
                            <Grid item lg={6} md={6}>
                              <FormControl component="fieldset" fullWidth>
                                <FormLabel component="label" className="form-label">
                                  Freeze Period
                                </FormLabel>
                                <SelectInputMain
                                  options={freezePeriodOptions}
                                  currentValue={this.state.form.freezePeriod}
                                  onChange={this.handleChangeFreezePeriod}
                                />
                              </FormControl>
                            </Grid>
                          </Grid>
                          <Grid item>
                            <FormControl component="fieldset" fullWidth margin={'normal'}>
                              <FormLabel component="label" className="form-label">
                                Reason
                              </FormLabel>
                              <SelectInputMain
                                placeholder="--Select Reason--"
                                options={reasonOption}
                                size="large"
                                onChange={this.handleChangeReasonType}
                                currentValue={this.state.form.reasonType}
                                errorMessage={this.state.validation.reasonType.errorMessage}
                                validateStatus={this.state.validation.reasonType.isError}
                              />
                            </FormControl>
                          </Grid>
                          {this.state.form.reasonType === 'others' && (
                            <Grid item>
                              <FormControl component="fieldset" fullWidth margin={'normal'}>
                                <FormLabel component="label" className="form-label">
                                  Other Reason
                                </FormLabel>
                                <TextAreaMain
                                  onChange={this.handleChangeOtherReason}
                                  placeholder="Other Reason"
                                  rows={4}
                                  currentValue={this.state.form.otherReason}
                                  validateStatus={this.state.validation.otherReason.isError}
                                  errorMessage={this.state.validation.otherReason.errorMessage}
                                />
                              </FormControl>
                            </Grid>
                          )}
                          <Grid item>
                            <FormControl component="fieldset" fullWidth margin={'normal'}>
                              <FormLabel component="label" className="form-label">
                                Branch
                              </FormLabel>
                              <SelectInputSearchMain
                                options={newBranchList}
                                onChange={this.handleSelectChangeBranch}
                                currentValue={this.state.form.branchId}
                                size={'large'}
                                placeholder={'Select Branch'}
                                errorMessage={this.state.validation.branchId.errorMessage}
                                validateStatus={this.state.validation.branchId.isError}
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item lg={6} md={6} className="freeze-type-card">
                        {this.renderFreezeType(this.state.form.freezeType)}
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
              <Grid item lg md className="section-page-footer">
                <Grid container justify="flex-end" spacing={3}>
                  <Grid item lg={2} md={2}>
                    <ButtonMain
                      type="ghost"
                      size="xl"
                      labelText="Cancel"
                      onClick={this.handleButtonCancel}
                    />
                  </Grid>
                  <Grid item lg={2} md={2}>
                    <ButtonMain
                      type="primary"
                      size="xl"
                      labelText="Submit"
                      onClick={this.handleButtonSubmit}
                      isLoading={this.state.isLoading}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <SnackBarSimple
              open={this.state.toastInformation.isOpen}
              durationHide={2000}
              message={this.state.toastInformation.message}
              onClickClose={this.handleCloseToash}
              snackbarType={this.state.toastInformation.snackbarType}
              anchor={optionToash}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getBranch: params => getBranchList(dispatch, params),
  getMembership: params => getMembershipDetails(dispatch, params),
  getMembershipPurchased: params => getMembershipPurchasedPrice(dispatch, params),
  getMember: params => getMemberDetails(dispatch, params),
  crateRequest: params => requestFreezemembership(dispatch, params),
});

const mapStateToProps = ({ userManagement, usersReducer, masterData }) => ({
  userManagement,
  usersReducer,
  masterData,
});

UserFreezeMembership.propTypes = {
  crateRequest: PropTypes.func,
  getBranch: PropTypes.func,
  getMember: PropTypes.func,
  getMembership: PropTypes.func,
  getMembershipPurchased: PropTypes.func,
  history: PropTypes.object,
  masterData: PropTypes.object,
  match: PropTypes.object,
  userManagement: PropTypes.object,
  usersReducer: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserFreezeMembership);
