const MembershipHelper = {
  generateMembershipList(dataArray) {
    const converted = [];
    dataArray.forEach(item => {
      const value = item.point_booster_id || item.user_point_booster_id;
      const name = item.name || item.product_name;
      const param = { value, name, option: item };

      converted.push(param);
    });

    const result = converted;

    return result;
  },
};

export default MembershipHelper;
