/* eslint-disable react/no-access-state-in-setstate */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Table } from 'antd';
import _ from 'lodash';
import { Grid } from '@material-ui/core';
import { compose } from 'redux';
// Component
import {
  SnackBarSimple,
  LabelStatusPayment,
  EmptyState,
  SelectInputGeneral,
  TextInput,
  PrevStateValue,
  SkeletonMain,
  PickerInputDate,
  ButtonMain,
  ButtonIconMain,
} from '../../../../../../components/Index';
import { ModalCustomerDetail } from '../../components/Index';
// Api
import {
  getBranchCovidTest,
  getListBookingLab,
  getProductCovidTest,
  getBookingResult,
  sendResultLab,
} from '../../../../../../services/api/MedicApi';
// Helpers
import { CommonHelper, paymentStatus } from '../../../../../../helpers/Index';
// Assets
import { Images } from '../../../../../../assets/Index';
// Style
import './LabPageStyle.scss';

const currentDate = CommonHelper.currentDate('YYYY-MM-DD');
const startDateValue = CommonHelper.getStartDateMonth(currentDate);
const endDateValue = CommonHelper.getEndDateMonth(currentDate);

const initialSort = {
  sortField: 'name',
  sortOrder: '',
};

const optionToast = {
  vertical: 'top',
  horizontal: 'right',
};

const initialFilter = {
  branchId: null,
  startDate: startDateValue,
  endDate: endDateValue,
  productId: null,
  search: '',
};

class LabPage extends React.Component {
  constructor(props) {
    super(props);
    const { activeTab } = props;

    const updateExitingFilter = {
      ...CommonHelper.objectCloning(initialFilter),
    };

    this.state = {
      filter: {
        ...updateExitingFilter,
        status: activeTab,
      },
      page: 1,
      limit: 10,
      sort: initialSort,
      pagination: {
        showSizeChanger: true,
        showTotal: (total, range) => `Showing ${range[0]}-${range[1]} from ${total}`,
        pageSizeOptions: ['10', '20', '30', '40'],
      },
      loading: true,
      isLoading: true,
      toastInformation: {
        isOpen: false,
        message: '',
        snackbarType: 'warning',
      },
      isOpenCustomerDetail: false,
      customerList: [],
    };
    this.searchDebounce = _.debounce(this.searchDebounce, 400);
    this.handleSearchDebounce = _.debounce(this.handleSearchDebounce, 400);
  }

  componentDidMount() {
    this.getBranchList();
  }

  componentDidUpdate(prevProps, prevState) {
    const { activeTab, onSetPrevStateValue, tabsValue } = this.props;
    const { filter } = this.state;
    const isEqualActiveTab = _.isEqual(prevProps.activeTab, activeTab);
    const isEqualFilter = _.isEqual(prevState.filter, filter);

    if (!isEqualActiveTab) {
      this.resetPropChange();
    }

    if (!isEqualFilter) {
      onSetPrevStateValue({ ...filter, activeTab: tabsValue }, 'lab');
    }
  }

  getProductLabList = async () => {
    const { filter } = this.state;
    const { productLabList } = this.props;
    try {
      await productLabList(filter.branchId);
      this.setState(
        {
          filter: {
            ...filter,
          },
        },
        () => {
          this.getBookingList();
        },
      );
    } catch (error) {
      const errors = error.data;
      this.processMessage(errors.messages, 'error');
    }
  };

  getBranchList = async () => {
    const { branchListLab, usersReducer } = this.props;
    const { filter } = this.state;

    try {
      const { data } = await branchListLab();
      let initialSetFilter = { ...filter, branchId: data[0].branch_id };

      const prevValueExist = usersReducer.prevStateValue && usersReducer.prevStateValue.lab;
      if (prevValueExist) {
        initialSetFilter = { ...prevValueExist };
      }

      this.setState(
        {
          filter: {
            ...initialSetFilter,
          },
        },
        () => {
          this.getProductLabList();
        },
      );
    } catch (error) {
      const errors = error.data;
      this.processMessage(errors.messages, 'error');
    }
  };

  getBookingList = async () => {
    const { listBookingLab } = this.props;
    const { filter, page, limit, pagination } = this.state;

    try {
      const params = {
        branch_location: filter.branchId,
        start_date: filter.startDate,
        end_date: filter.endDate,
        product_id: filter.productId,
        search_term: filter.search,
        booking_status: filter.status,
        page,
        limit,
        sortBy: '',
        order: 'asc',
      };
      this.setState({ loading: true });
      const { data } = await listBookingLab(params);
      this.setState({
        isLoading: false,
        loading: false,
        pagination: { ...pagination, pageSize: limit, total: data.total },
      });
    } catch (error) {
      const errors = error.data;
      this.processMessage(errors.messages, 'error');
      this.setState({ isLoading: false, loading: false });
    }
  };

  searchDebounce = () => {
    this.getBookingList();
  };

  handleTableChange = (pagination, filters, sorter) => {
    const { sort } = this.state;

    this.setState(
      {
        limit: pagination.pageSize,
        pagination: {
          ...this.state.pagination,
          current: pagination.current,
          pageSize: pagination.pageSize,
        },
        sort: {
          ...sort,
          sortField: sorter.field,
          sortOrder: sorter.order === 'ascend' ? '' : 'desc',
        },
        page: pagination.current,
      },
      () => {
        this.getBookingList();
      },
    );
  };

  handleSelectBranch = value => {
    const { filter, pagination } = this.state;

    this.setState(
      {
        filter: { ...filter, branchId: value, productId: null },
        page: 1,
        pagination: { ...pagination, current: 1 },
      },
      () => {
        this.getProductLabList();
      },
    );
  };

  handleSelectProduct = value => {
    const { filter, pagination } = this.state;

    this.setState(
      {
        filter: { ...filter, productId: value },
        page: 1,
        pagination: { ...pagination, current: 1 },
      },
      () => {
        this.getBookingList();
      },
    );
  };

  handleSearch = value => {
    const { filter, pagination } = this.state;
    this.setState(
      {
        filter: { ...filter, search: value },
        page: 1,
        pagination: { ...pagination, current: 1 },
      },
      () => {
        this.handleSearchDebounce();
      },
    );
  };

  handleSearchDebounce = () => {
    this.getBookingList();
  };

  handleChangeStartDate = value => {
    const { filter } = this.state;
    const endDate = CommonHelper.getEndDateMonth(value);

    this.setState(
      {
        filter: { ...filter, startDate: value, endDate },
        page: 1,
        pagination: { ...this.state.pagination, current: 1 },
      },
      () => {
        this.getBookingList();
      },
    );
  };

  handleChangeEndDate = value => {
    const { filter } = this.state;

    this.setState(
      {
        filter: { ...filter, endDate: value },
        page: 1,
        pagination: { ...this.state.pagination, current: 1 },
      },
      () => {
        this.getBookingList();
      },
    );
  };

  handleClickRow = value => {
    const { history } = this.props;
    const param = CommonHelper.encryptObject({ salesOrderId: value });
    history.push(`/medic/lab/details/${param}`);
  };

  handleViewResult = async value => {
    try {
      const { getBookingResultApi } = this.props;
      const { data } = await getBookingResultApi(value);
      this.setState({
        customerList: data,
      });
      this.handleOpenModalCustomerDetail();
    } catch (error) {
      const errors = error.data;
      this.processMessage(errors.messages, 'error');
    }
  };

  handleOpenPDFResult = data => {
    window.open(data);
  };

  handleOpenModalCustomerDetail = () => {
    this.setState({
      isOpenCustomerDetail: true,
    });
  };

  handleCloseModalCustomerDetail = () => {
    this.setState({
      isOpenCustomerDetail: false,
    });
  };

  handleCloseToast = () => {
    const { toastInformation } = this.state;
    this.setState({ toastInformation: { ...toastInformation, isOpen: false } });
  };

  handleRefresh = () => {
    this.setState({ isLoading: true }, () => {
      this.getBookingList();
    });
  };

  processMessage = (messages, type) => {
    const convertedMessage = CommonHelper.generateMessage(messages);

    const paramInformation = {
      isOpen: true,
      message: convertedMessage,
      snackbarType: type,
    };

    this.setState({
      toastInformation: paramInformation,
    });
  };

  resetPropChange() {
    const { activeTab } = this.props;
    const { pagination, filter, limit } = this.state;

    this.setState(
      {
        isLoading: true,
        filter: { ...filter, status: activeTab },
        limit,
        pagination: { ...pagination, pageSize: 10 },
      },
      async () => {
        await this.getBookingList();
      },
    );
  }

  renderFilter() {
    const {
      medicData: { branchList, productList },
    } = this.props;
    const { filter } = this.state;

    return (
      <Grid item lg={12} md={12}>
        <Grid container direction="row" justify="space-between" alignItems="center">
          <Grid item lg={6} md={6}>
            <Grid container direction="row" spacing={2}>
              <Grid item xl={4} lg={4} md={4}>
                <div className="container-remove-margin">
                  <SelectInputGeneral
                    placeHolder="Select Branch"
                    currentValue={filter.branchId}
                    dataList={branchList}
                    primaryKey="branch_id"
                    onChange={this.handleSelectBranch}
                  />
                </div>
              </Grid>
              <Grid item xl={4} lg={4} md={4}>
                <div className="container-remove-margin">
                  <SelectInputGeneral
                    placeHolder="All Products"
                    currentValue={filter.productId}
                    dataList={productList}
                    primaryKey="product_id"
                    onChange={this.handleSelectProduct}
                    includeAllData
                  />
                </div>
              </Grid>
              <Grid item xl={4} lg={4} md={4}>
                <div className="container-remove-margin">
                  <TextInput
                    iconPrefix="ic-ffo-search"
                    placeHolderText={'Search Patient'}
                    onChange={this.handleSearch}
                    size="md"
                    currentValue={filter.search}
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>

          <Grid item lg={6} md={6}>
            <div className="flex-column">
              <Grid container justify="flex-end" alignItems="center" spacing={1}>
                <Grid item lg={3} md={3} className="text-right">
                  <label className="text-12 pr-16">Showing Date</label>
                </Grid>
                <Grid item lg={3} md={3}>
                  <div className="container-remove-margin">
                    <PickerInputDate
                      customIcon="ic-ffo-date-pick"
                      dateFormat="dd/MM/yyyy"
                      defaultValue={filter.startDate}
                      onChange={this.handleChangeStartDate}
                      toolbar={false}
                    />
                  </div>
                </Grid>
                <label className="text-12 pr-8 pl-8"> - </label>
                <Grid item lg={3} md={3}>
                  <div className="container-remove-margin">
                    <PickerInputDate
                      customIcon="ic-ffo-date-pick"
                      dateFormat="dd/MM/yyyy"
                      minDate={filter.startDate}
                      defaultValue={filter.endDate}
                      onChange={this.handleChangeEndDate}
                      toolbar={false}
                    />
                  </div>
                </Grid>
                <Grid item>
                  <ButtonIconMain
                    icon="ic-ffo-refresh"
                    type="ghost"
                    size="md"
                    onClick={this.handleRefresh}
                  />
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  renderRightColumnContent = (text, row) => {
    const { activeTab, sendResultLabApi } = this.props;
    const isCompletedTab = activeTab === 'completed';

    if (isCompletedTab) {
      return (
        <Grid container direction="column">
          <Grid item lg={12} md={12} className="mb-8">
            <ButtonMain
              labelText="View Result"
              onClick={() => {
                this.handleViewResult(row.sales_order_id);
              }}
              type="primary"
            />
          </Grid>
          <SendResultButton
            row={row}
            sendResultLabApi={sendResultLabApi}
            processMessage={this.processMessage}
          />
        </Grid>
      );
    }

    return (
      <Grid item>
        <Grid container direction="row" className="order-amount-section">
          <Grid item lg md>
            <Grid container direction="column" justify="center" className="order-items-amount-text">
              <Grid item lg md className="vertical-align-center">
                <span className="flex-row text-center">
                  <label className="text-16 text-bold currency">
                    IDR {CommonHelper.formatCurrency(row.total_paid)}
                  </label>
                </span>
              </Grid>
              <Grid item lg md className="pt-4 vertical-align-start">
                <LabelStatusPayment status={row.payment_status} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {this.renderViewResultButton(row)}
      </Grid>
    );
  };

  renderColumns = () => {
    const { activeTab } = this.props;
    const isCompletedTab = activeTab === 'completed';
    return [
      {
        title: 'Patient Name',
        dataIndex: 'name',
        sorter: true,
        render: (text, row) => {
          return (
            <Grid
              container
              direction="column"
              spacing={3}
              className="pv-16 flex-wrap-unset"
              onClick={() => {
                this.handleClickRow(row.sales_order_id);
              }}
            >
              <Grid item>
                <Grid container>
                  <Grid item>
                    <div className="flex-column">
                      <label className="text-15 text-bold mb-4">{row.name}</label>
                      <label className="text-12 text-semi-bold text-rolling-stone">
                        Order ID: {row.sales_order_id}
                      </label>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container direction="row" spacing={2}>
                  <Grid item>
                    <div className="flex-column">
                      <label className="text-12 text-semi-bold text-rolling-stone mb-8">
                        Product
                      </label>
                      <label className="text-12 text-semi-bold text-mine-shaft">
                        {row.product_name}
                      </label>
                    </div>
                  </Grid>
                  <Grid item>
                    <div className="divider-hr-vertical" />
                  </Grid>
                  <Grid item>
                    <div className="flex-column">
                      <label className="text-12 text-semi-bold text-rolling-stone mb-8">
                        Location
                      </label>
                      <label className="text-12 text-semi-bold text-mine-shaft">
                        {row.branch_name}
                      </label>
                    </div>
                  </Grid>
                  <Grid item>
                    <div className="divider-hr-vertical" />
                  </Grid>
                  <Grid item>
                    <div className="flex-column">
                      <label className="text-12 text-semi-bold text-rolling-stone mb-8">
                        Booking Date
                      </label>
                      <label className="text-12 text-semi-bold text-mine-shaft">{`${row.date} | ${row.time}`}</label>
                    </div>
                  </Grid>
                  <Grid item>
                    <div className="divider-hr-vertical" />
                  </Grid>
                  <Grid item>
                    <div className="flex-column">
                      <label className="text-12 text-semi-bold text-rolling-stone mb-8">
                        Booking Code
                      </label>
                      <label className="text-12 text-semi-bold text-mine-shaft">
                        {row.medical_record_number || '-'}
                      </label>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item className="pt-24">
                <Grid container direction="row" justify="flex-start">
                  <Grid item className="section-label-filter">
                    <span className="flex-row align-center">
                      <i className="ic-ffo-mail container-icon-prefix size-16 mr-6" />
                      <label className="text-12 text-semi-bold text-mine-shaft">{row.email}</label>
                    </span>
                  </Grid>
                  <Grid item className="section-label-filter">
                    <span className="flex-row align-center">
                      <i className="ic-ffo-phone container-icon-prefix size-16 mr-6" />
                      <label className="text-12 text-semi-bold text-mine-shaft">{row.phone}</label>
                    </span>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          );
        },
      },
      {
        title: isCompletedTab ? 'Action' : 'Grand Total',
        align: 'center',
        render: this.renderRightColumnContent,
      },
    ];
  };

  renderViewResultButton = row => {
    switch (row.payment_status) {
      case paymentStatus.Confirmation:
      case paymentStatus.Complete:
      case paymentStatus.Settlement:
        return (
          <Grid item lg={12} md={12} className="mb-8 mt-6">
            <ButtonMain
              labelText="View Patient"
              onClick={() => {
                this.handleViewResult(row.sales_order_id);
              }}
              type="primary"
            />
          </Grid>
        );
      default:
        return null;
    }
  };

  render() {
    const {
      medicData: { listBooking },
    } = this.props;
    const {
      isLoading,
      loading,
      pagination,
      toastInformation,
      isOpenCustomerDetail,
      customerList,
    } = this.state;

    let renderElement = <SkeletonMain />;

    if (!isLoading) {
      renderElement = (
        <div className="container-page-content scroll-container">
          <div className="container-page-scrolling-area include-tab">
            <Grid container direction="column" className="flex-wrap-unset">
              <Grid item lg md sm className="section-page-header">
                {this.renderFilter()}
              </Grid>
              <Grid item lg md className="section-table-body">
                {_.isEmpty(listBooking) ? (
                  <Grid container justify={'center'}>
                    <EmptyState source={Images.emptyStateNoHistory} labelText={'No Data Found'} />
                  </Grid>
                ) : (
                  <Table
                    columns={this.renderColumns()}
                    rowKey={record => record.sales_order_id}
                    dataSource={listBooking}
                    pagination={pagination}
                    loading={loading}
                    onChange={this.handleTableChange}
                  />
                )}
              </Grid>
            </Grid>
            <SnackBarSimple
              open={toastInformation.isOpen}
              durationHide={2000}
              message={toastInformation.message}
              onClickClose={this.handleCloseToast}
              snackbarType={toastInformation.snackbarType}
              anchor={optionToast}
            />
            <ModalCustomerDetail
              isOpen={isOpenCustomerDetail}
              data={customerList}
              onClose={this.handleCloseModalCustomerDetail}
            />
          </div>
        </div>
      );
    }
    return renderElement;
  }
}

const mapDispatchToProps = dispatch => ({
  branchListLab: () => getBranchCovidTest(dispatch),
  listBookingLab: params => getListBookingLab(dispatch, params),
  productLabList: branchID => getProductCovidTest(dispatch, branchID),
  getBookingResultApi: salesOrderId => getBookingResult(dispatch, salesOrderId),
  sendResultLabApi: salesOrderId => sendResultLab(dispatch, salesOrderId),
});

const mapStateToProps = ({ usersReducer, medicData }) => ({
  usersReducer,
  medicData,
});

LabPage.propTypes = {
  activeTab: PropTypes.string.isRequired,
  branchListLab: PropTypes.func,
  getBookingResultApi: PropTypes.func,
  history: PropTypes.object,
  listBookingLab: PropTypes.func,
  medicData: PropTypes.object,
  onSetPrevStateValue: PropTypes.func,
  productLabList: PropTypes.func,
  sendResultLabApi: PropTypes.func,
  tabsValue: PropTypes.number,
  usersReducer: PropTypes.object,
};

const shell = compose(connect(mapStateToProps, mapDispatchToProps));

export default shell(PrevStateValue(LabPage));

const SendResultButton = ({ row, sendResultLabApi, processMessage }) => {
  const [loading, setLoading] = useState(false);

  const handleSendResult = async salesOrderId => {
    try {
      setLoading(true);
      await sendResultLabApi(salesOrderId);
      processMessage('Result successfully send to email!', 'success');
      setLoading(false);
    } catch (error) {
      const errors = error.data;
      processMessage(errors.messages, 'error');
    }
  };

  return (
    <Grid item lg={12} md={12} className="mb-8">
      <ButtonMain
        labelText="Send Result"
        onClick={() => {
          handleSendResult(row.sales_order_id);
        }}
        type="secondary"
        isLoading={loading}
      />
    </Grid>
  );
};

SendResultButton.propTypes = {
  processMessage: PropTypes.func,
  row: PropTypes.object,
  sendResultLabApi: PropTypes.func,
};
