import React from 'react';
import PropTypes from 'prop-types';
import DateFnsUtils from '@date-io/date-fns';
import 'date-fns';
import { MuiPickersUtilsProvider, KeyboardTimePicker } from '@material-ui/pickers';
// style
import './PickerInputTimeStyle.scss';
// helper
import { CommonHelper } from '../../../helpers/Index';

const PickerInputTime = props => {
  const {
    labelHeader,
    // isError,
    // errorMessage,
    defaultValue,
    onChange,
    customIcon,
    size,
  } = props;

  const currentDate = CommonHelper.currentDate('YYYY-MM-DD');

  const [selectedDate, setSelectedDate] = React.useState(
    defaultValue ? new Date(`${currentDate} ${defaultValue}`) : new Date(),
  );

  React.useEffect(() => {
    const updateState = () => {
      setSelectedDate(new Date(`${currentDate} ${defaultValue}`));
    };
    updateState();
  }, [defaultValue, currentDate]);

  const handleSelectChange = time => {
    const convertTime = CommonHelper.dateTimeParseNewFormat(time, 'HH:mm:ss');
    const result = convertTime;
    setSelectedDate(time);
    onChange(result);
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardTimePicker
        variant="inline"
        ampm={false}
        label={labelHeader}
        className={`custom-outline-size-time ${size}`}
        inputVariant="outlined"
        value={selectedDate}
        onChange={handleSelectChange}
        KeyboardButtonProps={{
          'aria-label': 'change time',
        }}
        autoOk
        keyboardIcon={<i className={`${customIcon} container-icon-prefix ${size}`} />}
      />
    </MuiPickersUtilsProvider>
  );
};

PickerInputTime.propTypes = {
  // errorMessage: PropTypes.string,
  // isError: PropTypes.bool,
  customIcon: PropTypes.string,
  defaultValue: PropTypes.string,
  labelHeader: PropTypes.string,
  onChange: PropTypes.func,
  size: PropTypes.string,
};

PickerInputTime.defaultProps = {
  size: 'lg',
};

export default PickerInputTime;
