import React, { useState, useRef, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import orderBy from 'lodash/orderBy';
// Components
import { ButtonMain } from '../../../../../../../components/Index';
import { AddUserDetailForm } from '../Index';
// Style
import './AddUserDetailStyle.scss';

const maxBookingItem = 50;

const initialBookingList = [
  {
    id: 1,
    label: 'BOOKING FOR (1)',
    isPrimary: true,
  },
];

const AddUserDetail = ({
  callGetCustomerListApi,
  customersDetail,
  masterData,
  onCancelHandler,
  onNextHandler,
  onPrevHandler,
  products,
  userDetail,
}) => {
  const customersList = customersDetail.length ? customersDetail : initialBookingList;
  const [bookingList, setBookingList] = useState(customersList);
  const [validate, setValidate] = useState(false);
  const bookingItem = useRef(customersList);
  const errorItem = useRef(customersList);

  useEffect(() => {
    if (validate) {
      setValidate(false);
    }
  }, [validate]);

  const onAddBookingListHandler = value => {
    setBookingList(currentBookingList => [...currentBookingList, value]);
  };

  const onParameterUpdate = (id, value) => {
    let filterItem = bookingItem.current.filter(item => item.id !== id);
    filterItem = [...filterItem, value];
    const orderById = orderBy(filterItem, ['id'], ['asc']);
    bookingItem.current = orderById;
  };

  const onRemoveHandler = id => {
    const filterItem = bookingItem.current.filter(item => item.id !== id);
    const filterErrorItem = errorItem.current.filter(item => item.id !== id);
    const orderById = orderBy(filterItem, ['id'], ['asc']);
    const orderErrorById = orderBy(filterErrorItem, ['id'], ['asc']);
    bookingItem.current = orderById;
    errorItem.current = orderErrorById;
    setBookingList(orderById);
  };

  const onValidationHandler = (id, value) => {
    let filterItem = errorItem.current.filter(item => item.id !== id);
    filterItem = [...filterItem, value];
    errorItem.current = filterItem;
  };

  const onSubmitHandler = () => {
    setValidate(true);
    setTimeout(() => {
      const currentErrorItem = errorItem.current;
      const isError = currentErrorItem.some(item => item.isError);
      if (isError) return;
      onNextHandler(bookingItem.current);
    }, 100);
  };

  const renderBookingList = () => {
    return bookingList.map((item, index) => {
      return (
        <AddUserDetailForm
          callGetCustomerListApi={callGetCustomerListApi}
          defaultValue={item}
          key={item.id.toString()}
          id={item.id}
          isPrimary={item.isPrimary}
          label={item.label}
          masterData={masterData}
          onParameterUpdate={onParameterUpdate}
          onRemove={onRemoveHandler}
          onValidationUpdate={onValidationHandler}
          products={products}
          userDetail={userDetail}
          validate={validate}
          withRemove={index !== 0}
        />
      );
    });
  };

  const isHidden = bookingList.length === maxBookingItem;

  return (
    <Grid item lg={12} md={12} className="mt-16">
      {renderBookingList()}
      {!isHidden && (
        <Grid item lg md className="section-add-product mt-24">
          <Grid container justify="center">
            <Grid item>
              <ButtonMain
                labelText="Add More Booking"
                onClick={() => {
                  const { id } = bookingList[bookingList.length - 1];
                  const itemId = id + 1;
                  onAddBookingListHandler({
                    id: itemId,
                    label: `BOOKING FOR (${itemId})`,
                    isPrimary: false,
                  });
                }}
                type="primary"
                size="md"
                startIcon="ic-ffo-add"
              />
            </Grid>
          </Grid>
        </Grid>
      )}
      <Grid item lg={12} md={12} className="mt-24">
        <Grid container justify="flex-end">
          <Grid item lg={5} md={6}>
            <Grid container justify="flex-end" spacing={2}>
              <Grid item lg={4} md={4}>
                <ButtonMain
                  type="negative"
                  size="xl"
                  labelText="Cancel"
                  onClick={onCancelHandler}
                />
              </Grid>
              <Grid item lg={4} md={4}>
                <ButtonMain type="ghost" size="xl" labelText="Prev" onClick={onPrevHandler} />
              </Grid>
              <Grid item lg={4} md={4}>
                <ButtonMain type="primary" size="xl" labelText="Next" onClick={onSubmitHandler} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

AddUserDetail.propTypes = {
  callGetCustomerListApi: PropTypes.func,
  customersDetail: PropTypes.array,
  masterData: PropTypes.object,
  onCancelHandler: PropTypes.func,
  onNextHandler: PropTypes.func,
  onPrevHandler: PropTypes.func,
  products: PropTypes.array,
  userDetail: PropTypes.object,
};

export default AddUserDetail;
