import React from 'react';
import _ from 'lodash';
// Style
import './CustomSimpleAddressStyle.scss';
import { CommonHelper } from '../../../../../helpers/Index';

const CustomSimpleAddress = (title, additional) => {
  const formatTitle = CommonHelper.renameRemoveCharacter(title, '_');
  return (
    <span className="flex-column">
      <label className="text-14 pt-4">{formatTitle}</label>
      <label className="text-12 wrapping-container-break description-text pb-4">{additional}</label>
    </span>
  );
};

const CustomSimpleAddressV02 = (title, email, additional) => {
  const formatTitle = CommonHelper.renameRemoveCharacter(title, '_');
  return (
    <div className="flex-column">
      <span className="flex-row mb-4">
        <label className="text-14 mr-8">{formatTitle}</label>
        <label className="text-12 ">{!_.isEmpty(email) ? `(${email})` : ``}</label>
      </span>
      <label className="text-12 wrapping-container-break description-text">{additional}</label>
    </div>
  );
};

export { CustomSimpleAddress, CustomSimpleAddressV02 };
