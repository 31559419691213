import { constants } from '../reducers/TncReducer';

export function getTncSectionListRequest() {
  return { type: constants.getTncListRequest };
}

export function getTncSectionListSuccess(response) {
  return { type: constants.getTncListSuccess, payload: response };
}

export function getTncSectionListFail(response) {
  return { type: constants.getTncListFail, payload: response.data };
}

export function getTncSectionDetailsRequest() {
  return { type: constants.getTncDetailsRequest };
}

export function getTncSectionDetailsSuccess(response) {
  return { type: constants.getTncDetailsSuccess, payload: response };
}

export function getTncSectionDetailsFail(response) {
  return { type: constants.getTncDetailsFail, payload: response.data };
}

export function createTncSectionRequest() {
  return { type: constants.createTncSectionRequest };
}

export function createTncSectionSuccess(response) {
  return { type: constants.createTncSectionSuccess, payload: response };
}

export function createTncSectionFail(response) {
  return { type: constants.createTncSectionFail, payload: response.data };
}

export function updateStatusTncSectionRequest() {
  return { type: constants.updateStatusTncSectionRequest };
}

export function updateStatusTncSectionSuccess(response) {
  return { type: constants.updateStatusTncSectionSuccess, payload: response };
}

export function updateStatusTncSectionFail(response) {
  return { type: constants.updateStatusTncSectionFail, payload: response.data };
}

export function editTncSectionRequest() {
  return { type: constants.editTncSectionRequest };
}

export function editTncSectionSuccess(response) {
  return { type: constants.editTncSectionSuccess, payload: response };
}

export function editTncSectionFail(response) {
  return { type: constants.editTncSectionFail, payload: response.data };
}
