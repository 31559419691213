import React from 'react';
import PropTypes from 'prop-types';
// styles
import StylesJs from './MinimalStyle';

const Minimal = props => {
  const { children } = props;

  const classes = StylesJs();

  return <main className={classes.content}>{children}</main>;
};

Minimal.propTypes = {
  children: PropTypes.node,
};

export default Minimal;
