import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Grid, Breadcrumbs, Link, FormControl } from '@material-ui/core';
import _ from 'lodash';
import { compose } from 'redux';
// style
import './CampaignAddStyle.scss';
// component
import {
  SnackBarSimple,
  ButtonMain,
  NavigationButtonGroup,
  TextInput,
  TextEditor,
  RadioInput,
  DeepLink,
  PickerInputDateAndTime,
  SelectInputSegment,
  ContentMobileDeviceNotification,
  AuthenticationAccessPages,
  PrevStateValue,
  LabelInput,
  SkeletonDetailsV02
} from '../../../components/Index';
// api
import {
  setCreateCampaign,
  setPushNotifCampaignTest,
  getCampaignDetails,
  setUpdateCampaign,
} from '../../../services/api/CampaignApi';
// helper
import {
  CommonHelper,
  MasterDataHelper,
  HttpStatusCode,
  PermissionModule,
  PermissionPage,
  PermissionAccess,
} from '../../../helpers/Index';
import DeepLinkHelper from '../../../helpers/DeepLinkHelper';

const tabButtonData = MasterDataHelper.deviceTabs;
const deeplinkOptions = MasterDataHelper.deeplinkType;
const publishTimeOption = MasterDataHelper.publishOption;
const currentDate = CommonHelper.currentDate('YYYY-MM-DD HH:mm');

const optionToash = {
  vertical: 'top',
  horizontal: 'right',
};

const initialValidation = {
  title: { isError: false, errorMessage: '' },
  message: { isError: '', errorMessage: '' },
  campaignName: { isError: false, errorMessage: '' },
  segment: { isError: '', errorMessage: '' },
  identifier: { isError: '', errorMessage: '' },
  screen: { isError: '', errorMessage: '' },
  webUrl: { isError: '', errorMessage: '' },
};

const initialForm = {
  campaignId: null,
  title: '',
  message: '',
  messageHtml: '',
  campaignName: '',
  segment: null,
  publishType: publishTimeOption[0],
  publishDate: currentDate,
  deeplinkType: deeplinkOptions[0].value,
  identifier: null,
  subIdentifier: null,
  webUrl: null,
  screen: null,
  disabledIdentifier: true,
};

class CampaignAdd extends React.Component {
  constructor(props) {
    super(props);

    props.checkUserAccessPermission(
      PermissionModule.Marketing,
      PermissionPage.Campaign,
      PermissionAccess.Add,
    );

    this.state = {
      tabsValue: tabButtonData[0].value,
      form: CommonHelper.objectCloning(initialForm),
      validation: CommonHelper.objectCloning(initialValidation),
      toastInformation: {
        isOpen: false,
        message: '',
        snackbarType: 'warning',
      },
      pagesTitle: {
        helmet: 'FITCO | Marketing - Create Campaign',
        breadcrumbs: 'Create Campaign',
        card: 'Marketing - Campaign',
      },
    };
    this.validateDebounce = _.debounce(this.validateDebounce, 400);
  }

  componentDidMount() {
    const {
      campaignDetails,
      match: { params },
    } = this.props;

    const { form, pagesTitle } = this.state;

    const campaignId = params.campaign_id;
    if (campaignId) {
      campaignDetails(campaignId)
        .then(response => {
          const data = response.data;

          const findMatchPublishType = _.find(publishTimeOption, ['value', Boolean(data.send_now)]);
          const publishDate =
            CommonHelper.dateTimeParseNewFormat(data.send_time, 'YYYY-MM-DD HH:mm') || currentDate;
          const deepLink = !_.isEmpty(data.content_deeplink) ? data.content_deeplink : {};

          this.setState({
            form: {
              ...form,
              campaignId: data.campaign_id,
              title: data.content_title,
              message: data.content_description,
              messageHtml: data.content_description,
              campaignName: data.campaign_title,
              segment: data.user_segment_id || null,
              publishType: findMatchPublishType || publishTimeOption[0],
              publishDate,
              deeplinkType: deepLink.type || deeplinkOptions[0].value,
              identifier: deepLink.item_id || null,
              subIdentifier: deepLink.subitem_id || null,
              webUrl: deepLink.url || null,
              screen: deepLink.screen_name || null,
            },
            pagesTitle: {
              ...pagesTitle,
              helmet: 'FITCO | Marketing - Edit Campaign',
              breadcrumbs: 'Edit Campaign',
              card: 'Marketing - Campaign',
            },
          });
        })
        .catch(error => {
          const serverMessage = error.data;
          const validationStatus = error.status === HttpStatusCode.InternalServerError;
          this.processMessage(
            validationStatus ? serverMessage.message : serverMessage.messages,
            'error',
          );
        });
    }
  }

  componentWillUnmount() {}

  setCreateCampaign(params) {
    const { createCampaign, history } = this.props;
    const {
      form: { campaignId },
    } = this.state;

    createCampaign(params)
      .then(async response => {
        const message = response.messages;
        await this.processMessage(message, 'success');
        if (!campaignId) {
          setTimeout(async () => {
            await history.push('/marketing/campaign');
          }, 2000);
        }
      })
      .catch(async error => {
        const serverMessage = error.data;
        const validationStatus = error.status === HttpStatusCode.InternalServerError;
        this.processMessage(
          validationStatus ? serverMessage.message : serverMessage.messages,
          'error',
        );
      });
  }

  setUpdateCampaign(params, id) {
    const { updateCampaign } = this.props;

    updateCampaign(params, id)
      .then(async response => {
        const message = response.messages;
        await this.processMessage(message, 'success');
      })
      .catch(async error => {
        const serverMessage = error.data;
        const validationStatus = error.status === HttpStatusCode.InternalServerError;
        this.processMessage(
          validationStatus ? serverMessage.message : serverMessage.messages,
          'error',
        );
      });
  }

  handleClickGroupButton = value => {
    this.setState({
      tabsValue: value,
    });
  };

  handleCloseToash = () => {
    const { toastInformation } = this.state;
    this.setState({ toastInformation: { ...toastInformation, isOpen: false } });
  };

  handleClick = (event, value) => {
    const { history } = this.props;
    event.preventDefault();
    history.push(value);
  };

  handleTextChangeTitle = value => {
    const { form, validation } = this.state;
    const message = CommonHelper.objectCloning(initialValidation.title);

    this.setState({
      form: { ...form, title: value },
      validation: { ...validation, title: message },
    });
  };

  handleTextChangeMessage = (content, string) => {
    const { form, validation } = this.state;
    const message = CommonHelper.objectCloning(initialValidation.message);

    this.setState({
      form: { ...form, message: string, messageHtml: content },
      validation: { ...validation, message },
    });
  };

  handleTextChangeCampaignName = value => {
    const { form, validation } = this.state;
    const message = CommonHelper.objectCloning(initialValidation.campaignName);

    this.setState({
      form: { ...form, campaignName: value },
      validation: { ...validation, campaignName: message },
    });
  };

  handleParameterUpdateDeepLink = (formValue, ValidationValue) => {
    this.setState({
      form: {
        ...formValue,
        deeplinkType: formValue.deeplinkType,
        identifier: formValue.identifier,
        subIdentifier: formValue.subIdentifier,
        webUrl: formValue.webUrl,
        screen: formValue.screen,
        disabledIdentifier: formValue.disabledIdentifier,
      },
      validation: ValidationValue,
    });
  };

  handleChangeSegment = value => {
    const { form, validation } = this.state;
    const message = CommonHelper.objectCloning(initialValidation.segment);

    this.setState({
      form: { ...form, segment: value },
      validation: { ...validation, segment: message },
    });
  };

  handleChangePublishType = value => {
    const { form } = this.state;

    this.setState({ form: { ...form, publishType: value } });
  };

  handleChangePublishDate = value => {
    const { form } = this.state;
    const modifyDate = CommonHelper.checkValidDate(value) ? value : currentDate;

    this.setState({
      form: { ...form, publishDate: modifyDate },
    });
  };

  handleButtonCancel = () => {
    const { history } = this.props;
    history.push('/marketing/campaign');
  };

  handleButtonSaveDraft = () => {
    const { form } = this.state;

    const check = this.validationForm(false);
    if (check) {
      const params = {
        campaign_title: form.campaignName,
        content_title: form.title,
        content_description: form.message,
        content_deeplink: DeepLinkHelper.generateDeeplinkValue(form),
        is_all_user: !form.segment,
        user_segment_id: form.segment,
        send_now: form.publishType.value,
        send_time: form.publishDate,
        is_draft: true,
      };

      if (form.campaignId) {
        this.setUpdateCampaign(params, form.campaignId);
      } else {
        this.setCreateCampaign(params);
      }
    }
  };

  handleButtonPublish = () => {
    const { form } = this.state;

    const check = this.validationForm(false);

    if (check) {
      const params = {
        campaign_title: form.campaignName,
        content_title: form.title,
        content_description: form.message,
        content_deeplink: DeepLinkHelper.generateDeeplinkValue(form),
        is_all_user: !form.segment,
        user_segment_id: form.segment,
        send_now: form.publishType.value,
        send_time: form.publishDate,
        is_draft: false,
      };

      if (form.campaignId) {
        this.setUpdateCampaign(params, form.campaignId);
      } else {
        this.setCreateCampaign(params);
      }
    }
  };

  handleButtonSendTest = () => {
    const { form } = this.state;
    const { pushNotifCampaignTest } = this.props;

    const check = this.validationForm(false);
    if (check) {
      const params = {
        campaign_title: form.campaignName,
        content_title: form.title,
        content_description: form.message,
        content_deeplink: DeepLinkHelper.generateDeeplinkValue(form),
      };

      pushNotifCampaignTest(params)
        .then(async response => {
          const message = response.messages;
          await this.processMessage(message, 'success');
        })
        .catch(async error => {
          const serverMessage = error.data;
          const validationStatus = error.status === HttpStatusCode.InternalServerError;
          this.processMessage(
            validationStatus ? serverMessage.message : serverMessage.messages,
            'error',
          );
        });
    }
  };

  validateDebounce() {
    this.validationForm();
  }

  validationForm(includeSegment = true) {
    const { form, validation } = this.state;
    let passCheck = true;
    let passCheckGroup01 = true;
    let passCheckGroup02 = true;
    let passCheckGroup03 = true;

    const paramValidation01 = CommonHelper.objectCloning(initialValidation.title);
    const paramValidation02 = CommonHelper.objectCloning(initialValidation.message);
    const paramValidation03 = CommonHelper.objectCloning(initialValidation.campaignName);
    const paramValidation04 = CommonHelper.objectCloning(initialValidation.screen);
    const paramValidation05 = CommonHelper.objectCloning(initialValidation.identifier);
    const paramValidation06 = CommonHelper.objectCloning(initialValidation.webUrl);
    const paramValidation07 = CommonHelper.objectCloning(initialValidation.segment);

    paramValidation01.isError = !form.title;
    paramValidation01.errorMessage = form.title ? '' : 'Please enter Title';

    const messageTrim = form.message.trim();
    paramValidation02.isError = messageTrim ? '' : 'error';
    paramValidation02.errorMessage = messageTrim ? '' : 'Please enter Message';

    paramValidation03.isError = !form.campaignName;
    paramValidation03.errorMessage = form.campaignName ? '' : 'Please enter Campaign Name';

    if (form.deeplinkType === deeplinkOptions[1].value) {
      paramValidation04.isError = form.screen ? '' : 'error';
      paramValidation04.errorMessage = form.screen ? '' : 'Please enter Screen';
      passCheckGroup01 = !!form.screen;

      if (form.disabledIdentifier === false) {
        paramValidation05.isError = form.identifier ? '' : 'error';
        paramValidation05.errorMessage = form.identifier ? '' : 'Please enter Identifier';
        passCheckGroup01 = !!form.identifier;
      }
    } else if (form.deeplinkType === deeplinkOptions[2].value) {
      paramValidation06.isError = form.webUrl ? '' : 'error';
      paramValidation06.errorMessage = form.webUrl ? '' : 'Please enter WebUrl';
      passCheckGroup01 = !!form.webUrl;
    }

    if (includeSegment) {
      paramValidation07.isError = form.segment ? '' : 'error';
      paramValidation07.errorMessage = form.segment ? '' : 'Please select Existing Segment';
      passCheckGroup02 = !!form.segment;
    }

    if (form.publishType.value === publishTimeOption[1].value) {
      passCheckGroup03 = CommonHelper.checkValidDate(form.publishDate);
    }

    if (
      !form.title ||
      !messageTrim ||
      !form.campaignName ||
      !passCheckGroup01 ||
      !passCheckGroup02 ||
      !passCheckGroup03
    ) {
      passCheck = false;
    }

    this.setState({
      validation: {
        ...validation,
        title: paramValidation01,
        message: paramValidation02,
        campaignName: paramValidation03,
        screen: paramValidation04,
        identifier: paramValidation05,
        webUrl: paramValidation06,
        segment: paramValidation07,
      },
    });
    return passCheck;
  }

  processMessage(messages, type) {
    const convertedMessage = CommonHelper.generateMessage(messages);

    const paramInformation = {
      isOpen: true,
      message: convertedMessage,
      snackbarType: type,
    };

    this.setState({
      toastInformation: paramInformation,
    });
  }

  render() {
    const { toastInformation, form, validation, tabsValue, pagesTitle } = this.state;
    const {
      campaignData: { fetching },
    } = this.props;
    const prevUrl = '/marketing/campaign';

    let renderElement = (
      <div className="container-page-scrolling-area">
        <SkeletonDetailsV02 />
      </div>
    );

    // if (!fetching && !_.isEmpty(details)) {
    renderElement = (
      <div>
        <Helmet title={pagesTitle.helmet} />
        <div className="container-page-request-details scroll-container">
          <div className="container-page-scrolling-area">
            <Grid container direction="column" className="flex-wrap-unset">
              <Grid item lg md className="section-page-header">
                <div className="breadcrumbs-section">
                  <Breadcrumbs aria-label="breadcrumb">
                    <Link
                      className="text-12"
                      color="inherit"
                      href={prevUrl}
                      onClick={event => {
                        this.handleClick(event, prevUrl);
                      }}
                    >
                      <i className="ic-ffi-campaign-notif container-icon-prefix size-16" />
                      Campaign
                    </Link>
                    <label className="text-12" color="inherit">
                      {pagesTitle.breadcrumbs}
                    </label>
                  </Breadcrumbs>
                </div>
              </Grid>
              <Grid item lg md className="section-page-body">
                <div className="campaign-card">
                  <Grid container spacing={8}>
                    <Grid item lg={4} md={4}>
                      <div className="flex-column">
                        <label className="text-16 text-bold text-rolling-stone title mb-16">
                          {pagesTitle.card}
                        </label>
                        <NavigationButtonGroup
                          onClick={this.handleClickGroupButton}
                          data={tabButtonData}
                          currentValue={tabsValue}
                        />
                        <div className="mt-16">
                          <ContentMobileDeviceNotification
                            deviceType={tabsValue}
                            messageTitle={form.title}
                            message={form.messageHtml}
                          />
                        </div>
                      </div>
                    </Grid>
                    <Grid item lg={8} md={8}>
                      <Grid container direction="column">
                        <Grid item lg={4} md={4} className="mt-36">
                          <ButtonMain
                            type="ghost"
                            labelText="Send Test Push"
                            onClick={this.handleButtonSendTest}
                            isLoading={fetching}
                          />
                        </Grid>
                        <Grid item lg={8} md={8}>
                          <FormControl component="fieldset" fullWidth margin={'normal'}>
                            <LabelInput labelText={'Campaign Name'} isRequired />
                            <TextInput
                              placeHolderText="Campaign Name"
                              onChange={this.handleTextChangeCampaignName}
                              currentValue={form.campaignName}
                              errorMessage={this.state.validation.campaignName.errorMessage}
                              isError={this.state.validation.campaignName.isError}
                              size="md"
                              maxLength={50}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item lg={8} md={8}>
                          <FormControl component="fieldset" fullWidth margin={'normal'}>
                            <LabelInput labelText={'Message Title'} isRequired />
                            <TextInput
                              placeHolderText="Message Title"
                              onChange={this.handleTextChangeTitle}
                              currentValue={form.title}
                              errorMessage={validation.title.errorMessage}
                              isError={validation.title.isError}
                              size="md"
                              maxLength={50}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item lg={8} md={8}>
                          <FormControl component="fieldset" fullWidth margin={'normal'}>
                            <LabelInput labelText={'Message'} isRequired />
                            <TextEditor
                              currentValue={form.messageHtml}
                              onChange={this.handleTextChangeMessage}
                              errorMessage={this.state.validation.message.errorMessage}
                              validateStatus={this.state.validation.message.isError}
                              maxLength={150}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item lg={8} md={8}>
                          <DeepLink
                            parentForm={this.state.form}
                            parentValidation={this.state.validation}
                            onParameterUpdate={this.handleParameterUpdateDeepLink}
                          />
                        </Grid>
                        <Grid item lg={8} md={8}>
                          <FormControl component="fieldset" fullWidth margin={'normal'}>
                            <LabelInput labelText={'Segment'} />
                            <SelectInputSegment
                              onChange={this.handleChangeSegment}
                              currentValue={form.segment}
                              errorMessage={this.state.validation.segment.errorMessage}
                              validateStatus={this.state.validation.segment.isError}
                              includeAllData
                            />
                          </FormControl>
                        </Grid>
                        <Grid item lg={8} md={8}>
                          <FormControl component="fieldset" fullWidth margin={'normal'}>
                            <LabelInput labelText={'Publish Time'} />
                            <RadioInput
                              data={publishTimeOption}
                              onSelect={this.handleChangePublishType}
                              direction="row"
                              currentValue={form.publishType}
                            />
                          </FormControl>
                        </Grid>
                        {form.publishType.value === publishTimeOption[1].value ? (
                          <Grid item lg={8} md={8}>
                            <FormControl component="fieldset" fullWidth margin={'normal'}>
                              <PickerInputDateAndTime
                                customIcon="ic-ffo-date-pick"
                                dateFormat="dd/MM/yyyy hh:mm"
                                minDate={currentDate}
                                defaultValue={form.publishDate}
                                onChange={this.handleChangePublishDate}
                                toolbar={false}
                              />
                            </FormControl>
                          </Grid>
                        ) : null}
                      </Grid>
                    </Grid>
                    <Grid item lg={12} md={12}>
                      <Grid container justify="flex-end">
                        <Grid item lg={7} md={7}>
                          <Grid container justify="space-between" spacing={2}>
                            <Grid item lg={4} md={4}>
                              <ButtonMain
                                type="negative"
                                labelText="Cancel"
                                onClick={this.handleButtonCancel}
                                isLoading={fetching}
                              />
                            </Grid>
                            <Grid item lg={4} md={4}>
                              <ButtonMain
                                type="ghost"
                                labelText="Save as Draft"
                                onClick={this.handleButtonSaveDraft}
                                isLoading={fetching}
                              />
                            </Grid>
                            <Grid item lg={4} md={4}>
                              <ButtonMain
                                type="primary"
                                labelText="Publish"
                                onClick={this.handleButtonPublish}
                                isLoading={fetching}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
              <Grid item lg md className="section-page-footer" />
            </Grid>
          </div>
        </div>
        <SnackBarSimple
          open={toastInformation.isOpen}
          durationHide={2000}
          message={toastInformation.message}
          onClickClose={this.handleCloseToash}
          snackbarType={toastInformation.snackbarType}
          anchor={optionToash}
        />
      </div>
    );
    // }

    return renderElement;
  }
}

const mapDispatchToProps = dispatch => ({
  createCampaign: params => setCreateCampaign(dispatch, params),
  pushNotifCampaignTest: params => setPushNotifCampaignTest(dispatch, params),
  campaignDetails: params => getCampaignDetails(dispatch, params),
  updateCampaign: (params, id) => setUpdateCampaign(dispatch, params, id),
});

const mapStateToProps = ({ campaignData }) => ({
  campaignData,
});

CampaignAdd.propTypes = {
  campaignData: PropTypes.object,
  campaignDetails: PropTypes.func,
  checkUserAccessPermission: PropTypes.func,
  createCampaign: PropTypes.func,
  history: PropTypes.object,
  match: PropTypes.object,
  pushNotifCampaignTest: PropTypes.func,
  updateCampaign: PropTypes.func,
};

const shell = compose(connect(mapStateToProps, mapDispatchToProps));
const core = compose(AuthenticationAccessPages, PrevStateValue);

export default shell(core(CampaignAdd));
