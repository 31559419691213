import React from 'react';
import PropTypes from 'prop-types';
import { Grid, FormControl, FormLabel } from '@material-ui/core';
import _ from 'lodash';
// component
import { ButtonMain, ButtonIconMain } from '../../../../../../components/Index';
// helper
import { serviceLocationCode } from '../../../../../../helpers/Index';
// Style
import './DetailsAppointmentStyle.scss';

const DetailsAppointment = props => {
  const { orderData, shippingData, isDraft, isComplete, onButtonReschedule } = props;
  const appointmentDetails = !_.isEmpty(orderData) ? orderData : {};
  const shippingDetails = !_.isEmpty(shippingData) ? shippingData : {};

  return (
    <Grid container className="container-appointment-details" direction="column">
      <FormControl component="fieldset" fullWidth margin="normal">
        <div className="row-between mb-10">
          <FormLabel component="label" className="text-12 filed-title">
            Appointment Details
          </FormLabel>
          {isComplete && (
            <div
              className={
                appointmentDetails.service_location !== serviceLocationCode.AtHome ? 'disabled' : ''
              }
            >
              <ButtonIconMain
                icon="ic-ffo-edit"
                type="primary"
                size="sm"
                onClick={onButtonReschedule}
                tooltip="Edit"
              />
            </div>
          )}
        </div>
        <Grid item lg={12} md={12} className="order-card-details">
          <Grid container direction="column">
            <Grid item lg={12} md={12}>
              <Grid container direction="row">
                <Grid item lg={6} md={6}>
                  <div className="flex-column pb-24">
                    <label className="text-12 text-bold text-rolling-stone wrapping-container wrapping-container-break mb-8">
                      Service
                    </label>
                    <label className="text-14 wrapping-container wrapping-container-break">
                      {appointmentDetails.service_location === serviceLocationCode.AtHome
                        ? 'Home Service'
                        : 'In Store Service'}
                    </label>
                  </div>
                </Grid>
                {isDraft && (
                  <Grid item lg={4} md={4} className="mt-4">
                    <ButtonMain
                      labelText="Reschedule"
                      onClick={onButtonReschedule}
                      type="primary"
                      size="sm"
                      startIcon="ic-ffo-calendar"
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item lg={12} md={12}>
              <Grid container direction="row">
                <Grid item lg={6} md={6}>
                  <div className="flex-column pb-24">
                    <label className="text-12 text-bold text-rolling-stone wrapping-container wrapping-container-break mb-8">
                      Date
                    </label>
                    <label className="text-14 wrapping-container wrapping-container-break">
                      {appointmentDetails.date}
                    </label>
                  </div>
                </Grid>
                <Grid item lg={6} md={6}>
                  <div className="flex-column pb-24">
                    <label className="text-12 text-bold text-rolling-stone wrapping-container wrapping-container-break mb-8">
                      Time
                    </label>
                    <label className="text-14 wrapping-container wrapping-container-break">
                      {`${appointmentDetails.start_time} - ${appointmentDetails.end_time}`}
                    </label>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            {appointmentDetails.service_location === serviceLocationCode.AtHome ? (
              <Grid item lg={12} md={12}>
                <div className="flex-column pb-24">
                  <label className="text-12 text-bold text-rolling-stone wrapping-container wrapping-container-break mb-8">
                    Address
                  </label>
                  <label className="text-14 wrapping-container third line wrapping-container-break mb-6">
                    {shippingDetails.shipping_address}
                  </label>
                </div>
              </Grid>
            ) : (
              <Grid item lg={12} md={12}>
                <div className="flex-column pb-24">
                  <label className="text-12 text-bold text-rolling-stone wrapping-container wrapping-container-break mb-8">
                    Address
                  </label>
                  <label className="text-14 wrapping-container third line wrapping-container-break mb-6">
                    {appointmentDetails.location}
                  </label>
                  <label className="text-12 wrapping-container third line wrapping-container-break">
                    {appointmentDetails.location_address}
                  </label>
                </div>
              </Grid>
            )}
            <Grid item lg={12} md={12}>
              <div className="flex-column pb-24">
                <label className="text-12 text-bold text-rolling-stone wrapping-container wrapping-container-break mb-8">
                  Notes
                </label>
                <label className="text-14 wrapping-container third line wrapping-container-break">
                  {shippingDetails.shipping_notes || '-'}
                </label>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </FormControl>
    </Grid>
  );
};

DetailsAppointment.propTypes = {
  isComplete: PropTypes.bool,
  isDraft: PropTypes.bool,
  onButtonReschedule: PropTypes.func,
  orderData: PropTypes.object,
  shippingData: PropTypes.object,
};

export default DetailsAppointment;
