import React from 'react';
import PropTypes from 'prop-types';
import { AutoComplete } from 'antd';
import { TextField, InputAdornment, CircularProgress } from '@material-ui/core';
// style
import './TextInputAutocompleteStyle.scss';
// component
import { CustomSimpleLabel, CustomSimpleAddress, CustomSimpleAddressV02 } from './components/Index';

const TextInputAutocomplete = props => {
  const {
    defaultValue,
    currentValue,
    isError,
    errorMessage,
    onChange,
    onBlur,
    placeHolderText,
    size,
    iconPrefixEnd,
    isDisable,
    onSelect,
    isLoading,
    data,
    isCustomDisplay,
    customDisplayClass,
  } = props;

  const [stateValue, setState] = React.useState(data);

  React.useEffect(() => {
    const updateSelectState = async () => {
      const converted = [];

      if (isCustomDisplay) {
        switch (customDisplayClass) {
          case 'custom-simple-option-item':
            data.forEach(item => {
              const param = {
                value: item.name,
                label: CustomSimpleLabel(item.name, item.stock_status),
                option_details: item,
              };

              converted.push(param);
            });

            break;

          case 'custom-simple-option-item-menu':
            data.forEach(item => {
              const param = {
                value: item.product_name,
                label: CustomSimpleLabel(item.product_name, `${item.calories} cals`),
                option_details: item,
              };

              converted.push(param);
            });

            break;

          case 'custom-address-option-item':
            data.forEach(item => {
              const param = {
                value: item.address,
                label: CustomSimpleAddress(item.main_text, item.address),
                option_details: item,
              };

              converted.push(param);
            });

            break;

          case 'custom-company-option-item':
            data.forEach(item => {
              const param = {
                value: item.company_id,
                label: CustomSimpleAddressV02(item.name, item.email, item.address),
                option_details: item,
              };

              converted.push(param);
            });

            break;

          case 'custom-simple-option-customer-item':
            data.forEach(item => {
              const param = {
                value: item.user_identity_id,
                label: CustomSimpleLabel(item.name, `${item.identity_type.toUpperCase()} | ${item.identity_number}`),
                option_details: item,
              };

              converted.push(param);
            });

            break;

          default:
            break;
        }

        setState(converted);
      }
    };

    updateSelectState();
  }, [customDisplayClass, data, isCustomDisplay]);

  const handleChangeTextInput = value => {
    const result = value;
    onChange(result);
  };

  const handleChangeTextInputBlur = value => {
    const result = value;
    onBlur(result);
  };

  const handleSelectTextInput = (value, option) => {
    onSelect(value, option.option_details);
  };

  const RenderIconEndAdornment = () => {
    let renderElement = null;

    if (iconPrefixEnd) {
      renderElement = (
        <InputAdornment position="start">
          <i className={`${iconPrefixEnd} container-icon-prefix ${size}`} />
        </InputAdornment>
      );
    } else if (isLoading) {
      renderElement = <CircularProgress className="btn-loading" size={14} />;
    }

    return renderElement;
  };

  return (
    <AutoComplete
      className={`container-text-input-auto-complete ${size}`}
      options={stateValue}
      disabled={isDisable}
      // filterOption={(inputValue, option) =>
      //   option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
      // }
      onSelect={onSelect ? handleSelectTextInput : null}
      onChange={onChange ? handleChangeTextInput : null}
      onBlur={onBlur ? handleChangeTextInputBlur : null}
      defaultValue={defaultValue}
      value={currentValue}
    >
      <TextField
        disabled={isDisable}
        defaultValue={defaultValue}
        value={currentValue}
        helperText={errorMessage}
        placeholder={placeHolderText}
        variant="outlined"
        error={isError}
        InputProps={{
          endAdornment: <RenderIconEndAdornment />,
        }}
      />
    </AutoComplete>
  );
};

TextInputAutocomplete.propTypes = {
  currentValue: PropTypes.string,
  customDisplayClass: PropTypes.string,
  data: PropTypes.array,
  defaultValue: PropTypes.string,
  errorMessage: PropTypes.string,
  iconPrefixEnd: PropTypes.string,
  isCustomDisplay: PropTypes.bool,
  isDisable: PropTypes.bool,
  isError: PropTypes.bool,
  isLoading: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onSelect: PropTypes.func,
  placeHolderText: PropTypes.string,
  size: PropTypes.string,
};

TextInputAutocomplete.defaultProps = {
  size: 'md',
  isDisable: false,
};

export default TextInputAutocomplete;
