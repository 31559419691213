/* eslint-disable react/no-access-state-in-setstate */
import React from 'react';
import { Table } from 'antd';
import PropTypes from 'prop-types';
import { Grid, Fade } from '@material-ui/core';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import _ from 'lodash';
// Action
import {
  getListUser,
  deleteMultipleUser,
  deleteUser,
} from '../../../../services/api/UserManagement';
// style
import './UserPageStyle.scss';
// Assets
import { Icons } from '../../../../assets/Index';
// helper
import {
  CommonHelper,
  PermissionAccess,
  PermissionModule,
  PermissionPage,
  UserHelper,
  UserStatus,
} from '../../../../helpers/Index';
// Component
import {
  TextInput,
  SelectInputRole,
  ButtonMain,
  ButtonIconMain,
  LabelStatusMain,
  SnackBarSimple,
  ModalAlertAction,
  TextAreaMain,
  LabelIcon,
  AuthenticationAccessPages,
  SkeletonMain,
  SelectInputMain,
} from '../../../../components/Index';
import { compose } from 'redux';

const optionUserStatus = UserHelper.statusGlobal;

const optionToast = {
  vertical: 'top',
  horizontal: 'right',
};

const timeInterval = 300;

const initialSort = {
  sortField: 'first_name',
  sortOrder: '',
};
const initialFilter = {
  roleId: null,
  userStatus: optionUserStatus[1].value,
  search: '',
};

class UserPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      pagination: {
        showSizeChanger: true,
        showTotal: (total, range) => `Showing ${range[0]}-${range[1]} From ${total}`,
        pageSizeOptions: ['10', '20', '30', '40'],
      },
      sort: CommonHelper.objectCloning(initialSort),
      filter: CommonHelper.objectCloning(initialFilter),
      limit: 10,
      page: 1,
      isOpenModal: false,
      isOpenModalMultiple: false,
      selectedDataDelete: null,
      selectedId: null,
      selectedRowKeys: [],
      toastInformation: {
        isOpen: false,
        message: '',
        snackbarType: 'warning',
      },
      lockoutReason: '',
      validation: { isError: '', errorMessage: '' },
      modalDetailData: {
        title: 'Confirmation',
        body: 'Are you sure want to delete User ID',
      },
    };
    this.searchDebounce = _.debounce(this.searchDebounce, 400);
  }

  componentDidMount() {
    this.getListUser();
  }

  componentWillUnmount() {}

  getListUser() {
    const {
      limit,
      pagination,
      page,
      filter: { search, roleId, userStatus },
      sort,
    } = this.state;
    const { listUser } = this.props;

    const params = {
      search,
      role_id: roleId,
      user_status_id: userStatus,
      page,
      limit,
      sort: sort.sortField,
      sortOrder: sort.sortOrder,
    };

    listUser(params).then(response => {
      pagination.total = response.total;
      pagination.pageSize = limit;
      this.setState({
        isLoading: false,
        pagination: { ...pagination, pageSize: limit, total: response.total },
      });
    });
  }

  setDeleteUser(params = {}) {
    const { deleteSingleUser } = this.props;
    deleteSingleUser(params).then(() => {
      const {
        userManagement: { responseMessage },
      } = this.props;
      const paramInformation = {
        isOpen: true,
        message: responseMessage,
        snackbarType: 'success',
      };
      this.setState(
        {
          toastInformation: paramInformation,
          isOpenModal: false,
          selectedDataDelete: null,
        },
        () => {
          this.getListUser();
        },
      );
    });
  }

  setDeleteUserMultiple(params = {}) {
    const { deleteMultiple } = this.props;
    deleteMultiple(params).then(() => {
      const {
        userManagement: { responseMessage },
      } = this.props;
      const paramInformation = {
        isOpen: true,
        message: responseMessage,
        snackbarType: 'success',
      };

      this.setState(
        {
          toastInformation: paramInformation,
          isOpenModalMultiple: false,
          selectedDataDelete: null,
        },
        () => {
          this.getListUser();
        },
      );
    });
  }

  handleChangeRole = value => {
    const { filter } = this.state;
    this.setState(
      {
        filter: { ...filter, roleId: value },
        page: 1,
        pagination: { ...this.state.pagination, current: 1 },
      },
      () => {
        this.getListUser();
      },
    );
  };

  handleChangeUserStatus = value => {
    const { filter } = this.state;
    this.setState(
      {
        filter: { ...filter, userStatus: value },
        page: 1,
        pagination: { ...this.state.pagination, current: 1 },
      },
      () => {
        this.getListUser();
      },
    );
  };

  handleButtonClickAdd = () => {
    const { history } = this.props;
    history.push('/management/users/add');
  };

  handleMouseEnter = record => {
    this.setState({ selectedId: record.user_id });
  };

  handleMouseLeave = () => {
    this.setState({ selectedId: null });
  };

  handleButtonClickCancel = () => {
    this.setState({
      selectedRowKeys: [],
    });
  };

  handleButtonClickDeleted = () => {
    const { modalDetailData } = this.state;

    this.setState({
      isOpenModalMultiple: true,
      modalDetailData: {
        ...modalDetailData,
        body: `Are you sure want to delete?`,
      },
    });
  };

  handleClickCard = value => {
    const { history } = this.props;
    const param = CommonHelper.encryptObject({ userId: value });

    history.push(`/management/users/details/${param}`);
  };

  handleClickRowEdit = value => {
    const { history } = this.props;
    const param = CommonHelper.encryptObject({ userId: value.user_id });

    history.push(`/management/users/edit/${param}`);
  };

  handleClickRowDelete = data => {
    const { modalDetailData } = this.state;

    this.setState({
      isOpenModal: true,
      selectedDataDelete: data,
      modalDetailData: {
        ...modalDetailData,
        body: `Are you sure want to delete User ID ${data.user_name} ? `,
      },
    });
  };

  handleCloseModal = () => {
    const message = { isError: '', errorMessage: '' };

    this.setState({
      isOpenModal: false,
      lockoutReason: '',
      validation: message,
    });
  };

  handleCloseModalMultiple = () => {
    const message = { isError: '', errorMessage: '' };

    this.setState({
      isOpenModalMultiple: false,
      lockoutReason: '',
      validation: message,
    });
  };

  handleTextChangeReason = value => {
    const { validation } = this.state;

    this.setState({
      lockoutReason: value,
      validation: { ...validation, isError: '', errorMessage: '' },
    });
  };

  handleButtonSubmitDeleteMultiple = () => {
    const { lockoutReason, validation, selectedRowKeys } = this.state;

    if (!lockoutReason || lockoutReason === '') {
      this.setState({
        validation: { ...validation, isError: 'error', errorMessage: 'Reason Still Empty' },
      });
    } else {
      const users = [];
      selectedRowKeys.map(item => {
        users.push({
          user_id: item,
          lockout_reason: lockoutReason,
        });
        return false;
      });

      const param = { data: { users } };
      this.setDeleteUserMultiple(param);
    }
  };

  handleButtonSubmitDelete = () => {
    const { lockoutReason, validation, selectedDataDelete } = this.state;

    if (!lockoutReason || lockoutReason === '') {
      this.setState({
        validation: { ...validation, isError: 'error', errorMessage: 'Reason Still Empty' },
      });
    } else {
      const param = {
        data: {
          user_id: selectedDataDelete.user_id,
          lockout_reason: lockoutReason,
        },
      };
      this.setDeleteUser(param);
    }
  };

  handleChangeSearch = value => {
    const { filter } = this.state;

    this.setState(
      {
        filter: { ...filter, search: value, sort: JSON.parse(JSON.stringify(initialSort)) },
        page: 1,
        pagination: { ...this.state.pagination, current: 1 },
      },
      () => {
        this.searchDebounce();
      },
    );
  };

  searchDebounce = () => {
    this.getListUser();
  };

  handleSelectChangeRow = selectedRowKeys => {
    this.setState({ selectedRowKeys });
  };

  handleClose = () => {
    const { toastInformation } = this.state;
    this.setState({ toastInformation: { ...toastInformation, isOpen: false } });
  };

  handleTableChange = (pagination, filters, sorter) => {
    const { sort } = this.state;

    this.setState(
      {
        limit: pagination.pageSize,
        pagination: {
          ...this.state.pagination,
          current: pagination.current,
          pageSize: pagination.pageSize,
        },
        sort: {
          ...sort,
          sortField: sorter.field,
          sortOrder: sorter.order === 'ascend' ? 'asc' : 'desc',
        },
        page: pagination.current,
      },
      () => {
        this.getListUser();
      },
    );
  };

  renderMembership = data => {
    const { memberships } = data;
    let renderElement = null;

    if (data.user_type_id === 0) {
      if (memberships.length > 0) {
        const membershipInformation =
          memberships.length > 1 ? 'Multiple Memberships' : memberships[0].product_name;

        renderElement = (
          <Grid container direction="column" className="container-information membership">
            <Grid item lg md>
              <div>
                <LabelIcon iconPrefix="ic-ffo-memberships" labelText="Memberships" />
                <label className="text-12">{membershipInformation}</label>
              </div>
            </Grid>
          </Grid>
        );
      }
    } else {
      renderElement = (
        <Grid container direction="column" className="container-information merchant">
          <Grid item>
            <Grid container justify="flex-start">
              <Grid item lg={4} md={4}>
                <LabelIcon iconPrefix="ic-ffo-merchant" labelText={data.merchants || '-'} />
              </Grid>
              <Grid item lg={4} md={4}>
                <LabelIcon iconPrefix="ic-ffo-branch" labelText={data.branches || '-'} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <label className="text-12 wrapping-container">{data.role_name || '-'}</label>
          </Grid>
        </Grid>
      );
    }

    return renderElement;
  };

  renderStatus = value => {
    let renderElement = <LabelStatusMain value="Active" type="complete" />;

    if (value === UserStatus.Deleted) {
      renderElement = <LabelStatusMain value="Deleted" type="danger" />;
    } else if (value === UserStatus.Inactive) {
      renderElement = <LabelStatusMain value="Inactive" type="default" />;
    }

    return <div className="container-status">{renderElement}</div>;
  };

  renderColumns = () => {
    const { selectedId } = this.state;
    const { handleClickRowEdit, handleClickRowDelete, handleClickCard } = this;
    return [
      {
        title: 'Name',
        dataIndex: 'first_name',
        sorter: true,
        render: (text, row) => {
          return (
            <Grid
              container
              onClick={() => {
                handleClickCard(row.user_id);
              }}
              className="container-table-column"
            >
              <Grid item lg={12} md={12} className="container-information user">
                <span className="flex-row">
                  <label className="text-14 title-main">{row.user_name || '-'}</label>
                  {this.renderStatus(row.status)}
                </span>
                <label className="text-10">ID : {row.user_id}</label>
              </Grid>
              <Grid item lg={3} md={3} className="container-information user-contact">
                <div className="flex-column">
                  <LabelIcon iconPrefix="ic-ffo-mail" labelText={row.email || '-'} />
                  <LabelIcon iconPrefix="ic-ffo-phone" labelText={row.phone || '-'} />
                </div>
              </Grid>
              <Grid item lg={9} md={9}>
                {row.status === UserStatus.Deleted ? (
                  <span className="container-text-lock-reason">
                    <label className="text-12">Reason :</label>
                    <label className="text-12">{row.lockout_reason}</label>
                  </span>
                ) : (
                  this.renderMembership(row)
                )}
              </Grid>
            </Grid>
          );
        },
      },
      {
        title: 'FIT Points',
        dataIndex: 'fit_point',
        width: '20%',
        align: 'center',
        render: (text, row) => {
          const currentFitPoint = CommonHelper.formatCurrency(text);

          let renderElement = (
            <Grid container justify="space-around" className="container-table-column">
              <Grid item>
                <div className="container-action-button">
                  <span>
                    <img src={Icons.fitPoints} alt="Fit Points" />
                    <label className="text-16">{currentFitPoint}</label>
                  </span>
                  <label className="text-12">FIT Points Balance </label>
                </div>
              </Grid>
            </Grid>
          );

          if (row.user_id === selectedId) {
            renderElement = (
              <Grid container justify="center">
                <Grid item lg={3} md={3}>
                  <ButtonIconMain
                    icon="ic-ffo-edit"
                    type="secondary"
                    size="sm"
                    onClick={() => handleClickRowEdit(row)}
                    requiredPermission={`${PermissionModule.UserManagement}.${PermissionPage.Users}.${PermissionAccess.Update}`}
                  />
                </Grid>
                {row.status !== UserStatus.Deleted && (
                  <Grid item lg={3} md={3}>
                    <ButtonIconMain
                      icon="ic-ffo-bin"
                      type="negative"
                      size="sm"
                      onClick={() => handleClickRowDelete(row)}
                      requiredPermission={`${PermissionModule.UserManagement}.${PermissionPage.Users}.${PermissionAccess.Delete}`}
                    />
                  </Grid>
                )}
              </Grid>
            );
          }

          return renderElement;
        },
      },
    ];
  };

  renderFilter() {
    const {
      filter: { roleId, userStatus },
    } = this.state;
    return (
      <Grid container justify="flex-start" className="container-filter">
        <Grid item lg={3} md={3} className="row-filter">
          <TextInput
            iconPrefix="ic-ffo-search"
            placeHolderText={'Search'}
            onChange={this.handleChangeSearch}
            size="md"
          />
        </Grid>
        <Grid item lg={3} md={3} className="row-filter">
          <div className="container-remove-margin">
            <SelectInputRole
              onChange={this.handleChangeRole}
              currentValue={roleId}
              includeAllData
            />
          </div>
        </Grid>
        <Grid item lg={2} md={2} className="row-filter">
          <div className="container-remove-margin">
            <SelectInputMain
              options={optionUserStatus}
              size="middle"
              currentValue={userStatus}
              onChange={this.handleChangeUserStatus}
              placeholder={'Select User Status'}
            />
          </div>
        </Grid>
        <Grid item lg md>
          <Grid container justify="flex-end" alignItems="center">
            <Grid item lg={6} md={6}>
              <ButtonMain
                labelText="Create User"
                onClick={this.handleButtonClickAdd}
                type="primary"
                size="md"
                startIcon="ic-ffo-add"
                requiredPermission={`${PermissionModule.UserManagement}.${PermissionPage.Users}.${PermissionAccess.Add}`}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  renderTableHeader() {
    const { selectedRowKeys } = this.state;
    const resultShowButton = selectedRowKeys.length > 0;

    return (
      <Grid container justify="space-between" className="container-filter">
        <Grid item lg={3} md={3}>
          {resultShowButton && (
            <Fade in={resultShowButton} timeout={timeInterval}>
              <Grid container justify="space-around" alignItems="flex-start">
                <Grid item>
                  <ButtonMain
                    labelText="Delete"
                    onClick={this.handleButtonClickDeleted}
                    type="negative"
                    size="md"
                  />
                </Grid>
                <Grid item>
                  <ButtonMain
                    labelText="Cancel"
                    onClick={this.handleButtonClickCancel}
                    type="ghost"
                    size="md"
                  />
                </Grid>
              </Grid>
            </Fade>
          )}
        </Grid>
      </Grid>
    );
  }

  render() {
    const {
      isLoading,
      pagination,
      selectedRowKeys,
      toastInformation,
      isOpenModal,
      isOpenModalMultiple,
      modalDetailData,
      validation,
      lockoutReason,
    } = this.state;
    const {
      userManagement: { list, fetching },
    } = this.props;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.handleSelectChangeRow,
    };

    let renderElement = <SkeletonMain />;

    if (!isLoading) {
      renderElement = (
        <div>
          <Helmet title="FITCO | User Managements - Users" />
          <div className="container-page-user scroll-container">
            <div className="container-page-scrolling-area">
              <Grid container direction="column">
                <Grid item lg md className="section-page-header">
                  {this.renderFilter()}
                </Grid>
                <Grid item lg md className="section-page-body">
                  <Grid container direction="column" className="flex-wrap-unset">
                    <Grid item lg md className="container-table-header">
                      {this.renderTableHeader()}
                    </Grid>
                    <Grid item lg md>
                      <Table
                        rowSelection={{
                          type: 'checkbox',
                          ...rowSelection,
                        }}
                        columns={this.renderColumns()}
                        rowKey={record => record.user_id}
                        dataSource={list}
                        pagination={pagination}
                        loading={fetching}
                        // scroll={{ y: '53vh' }}
                        onChange={this.handleTableChange}
                        onRow={record => {
                          return {
                            onMouseEnter: () => {
                              this.handleMouseEnter(record);
                            }, // mouse enter row
                            onMouseLeave: () => {
                              this.handleMouseLeave();
                            },
                          };
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item lg md className="section-page-footer" />
              </Grid>
            </div>
          </div>
          <SnackBarSimple
            open={toastInformation.isOpen}
            durationHide={2000}
            message={toastInformation.message}
            onClickClose={this.handleClose}
            snackbarType={toastInformation.snackbarType}
            anchor={optionToast}
          />
          {isOpenModal ? (
            <ModalAlertAction
              onButtonSubmit={this.handleButtonSubmitDelete}
              onCloseModal={this.handleCloseModal}
              modalDetail={modalDetailData}
              modalType="danger"
              buttonSubmitText="Submit"
              openModal={isOpenModal}
              isLoading={isLoading}
              customElementProps={
                <TextAreaMain
                  onChange={this.handleTextChangeReason}
                  placeholder="Reason"
                  rows={3}
                  currentValue={lockoutReason}
                  validateStatus={validation.isError}
                  errorMessage={validation.errorMessage}
                />
              }
            />
          ) : null}
          {isOpenModalMultiple ? (
            <ModalAlertAction
              onButtonSubmit={this.handleButtonSubmitDeleteMultiple}
              onCloseModal={this.handleCloseModalMultiple}
              modalDetail={modalDetailData}
              modalType="danger"
              buttonSubmitText="Submit"
              openModal={isOpenModalMultiple}
              isLoading={isLoading}
              customElementProps={
                <TextAreaMain
                  onChange={this.handleTextChangeReason}
                  placeholder="Reason"
                  rows={3}
                  currentValue={lockoutReason}
                  validateStatus={validation.isError}
                  errorMessage={validation.errorMessage}
                />
              }
            />
          ) : null}
        </div>
      );
    }

    return renderElement;
  }
}

UserPage.propTypes = {
  deleteMultiple: PropTypes.func,
  deleteSingleUser: PropTypes.func,
  history: PropTypes.object,
  listUser: PropTypes.func,
  userManagement: PropTypes.object,
};

const mapDispatchToProps = dispatch => ({
  listUser: params => getListUser(dispatch, params),
  deleteMultiple: params => deleteMultipleUser(dispatch, params),
  deleteSingleUser: params => deleteUser(dispatch, params),
});

const mapStateToProps = ({ userManagement }) => ({
  userManagement,
});

const shell = compose(connect(mapStateToProps, mapDispatchToProps));

export default shell(AuthenticationAccessPages(UserPage));
