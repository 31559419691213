/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { Grid, IconButton } from '@material-ui/core';
import { connect } from 'react-redux';
import _ from 'lodash';
// style
import './ModalPreviewPrintLabelStyle.scss';
// component
import {
  ModalInformationPopUp,
  LabelBarcode,
  GeneratorHtmlToPdf,
} from '../../../../../components/Index';
// helper
import {
  CommonHelper,
  MasterDataHelper,
  UserHelper,
  paymentStatus,
  TransactionStatus,
} from '../../../../../helpers/Index';
// assets
import { Images } from '../../../../../assets/Index';

class ModalPreviewPrintLabel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  componentWillUnmount() {}

  handleClose = () => {
    const { onClose } = this.props;
    onClose(false);
  };

  render() {
    const { isOpen, data, disableButton } = this.props;

    let ShipMethod = '';

    switch (data.shipping_method) {
      case 'jne':
        ShipMethod = Images.ShippingLogoJne;
        break;

      default:
        break;
    }

    const recipient = data.recipient_name
      ? `${data.recipient_name} - ${data.recipient_phone}`
      : '-';

    const renderElement = (
      <Grid container direction={'column'} className="flex-wrap-unset">
        <Grid item lg md className="section-header-modal-shipping-label">
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
            className="flex-wrap-unset"
          >
            <Grid item lg={11} md={11}>
              <label className="text-18 wrapping-container">Shipping Label</label>
            </Grid>
            <Grid item>
              <Grid container justify="flex-end">
                <Grid item>
                  <div className="container-button-close">
                    <IconButton onClick={this.handleClose}>
                      <i className="ic-ffo-close custom-icon-close" />
                    </IconButton>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <div className="divider-hr" />
        </Grid>
        <Grid
          item
          className="section-body-modal-shipping-label scroll-container container-scrolling-area"
        >
          <Grid container justify="center">
            <Grid item>
              <Grid container direction="column" id="labelShipping">
                <Grid item lg={12} md={12} className="section-1">
                  <Grid container justify="center" alignItems="center">
                    <Grid item lg={6} md={6} className="container-logo">
                      <div className="flex-column">
                        <img
                          src={Images.shippingLogoFitco}
                          className="custom-image-file mb-24"
                          alt="file"
                        />
                        {!_.isEmpty(ShipMethod) ? (
                          <img src={ShipMethod} className="custom-image-file" alt="file" />
                        ) : (
                          <label className="text-12 title wrapping-container-break">{`${_.capitalize(
                            data.shipping_method,
                          )} - ${data.shipping_title}`}</label>
                        )}
                      </div>
                    </Grid>
                    <Grid item lg={6} md={6}>
                      <div className="flex-column">
                        <label className="text-12 title">W-ORDER ID</label>
                        <LabelBarcode value={data.external_order_id || ''} heightBarcode={60} />
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <div className="divider-hr" />
                </Grid>
                <Grid item lg={9} md={9} className="section-2">
                  <div className="flex-column">
                    <label className="text-12 title mb-8">FROM</label>
                    <label className="text-14 mb-32 text-justify">{data.from_address || '-'}</label>
                    <label className="text-12 title mb-8">TO</label>
                    <label className="text-12 recipient mb-4">{recipient}</label>
                    <label className="text-14 text-justify">{data.delivery_address || '-'}</label>
                    {data.notes && (
                      <React.Fragment>
                        <label className="text-12 title mb-8">Note</label>
                        <label className="text-14 mb-8 text-justify">{data.notes || '-'}</label>
                      </React.Fragment>
                    )}
                  </div>
                </Grid>
                <Grid item>
                  <div className="divider-hr" />
                </Grid>
                <Grid item lg={12} md={12} className="section-3">
                  <Grid container justify="center">
                    <Grid item lg={4} md={4}>
                      <div className="flex-column">
                        <label className="text-12 title mb-8">W-ORDER ID</label>
                        <label className="text-14">{data.external_order_id || '-'}</label>
                      </div>
                    </Grid>
                    <Grid item lg={4} md={4}>
                      <div className="flex-column">
                        <label className="text-12 title mb-8">QTY</label>
                        <label className="text-14">{data.item_quantity || '0'}</label>
                      </div>
                    </Grid>
                    <Grid item lg={4} md={4}>
                      <div className="flex-column">
                        <label className="text-12 title mb-8">WEIGHT</label>
                        <label className="text-14">{data.total_weight || '0'} KG</label>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item lg={12} md={12} className="container-direction">
                  <label className="text-18 destination"> {data.destination_code || '-'}</label>
                </Grid>
                <Grid item lg={12} md={12}>
                  <Grid container justify="center">
                    <Grid item lg={8} md={8} className="section-4">
                      <div className="flex-column">
                        <label className="text-12 title">AIRWAY BILL NUMBER</label>
                        <LabelBarcode value={data.airway_bill_number || ''} heightBarcode={60} />
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <div className="divider-hr" />
        </Grid>
        <Grid item className="section-footer-modal-shipping-label">
          {!disableButton && (
            <Grid container justify="flex-end">
              <Grid item lg={3} md={3}>
                <GeneratorHtmlToPdf
                  buttonLabel="Print Label"
                  buttonType="primary"
                  customLabel="canvas-shipping-03"
                  targetId="labelShipping"
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    );

    return (
      <ModalInformationPopUp
        isOpen={isOpen}
        onClose={this.handleClose}
        size="medium"
        customElementProps={renderElement}
      />
    );
  }
}

const mapDispatchToProps = () => ({});

const mapStateToProps = () => ({});

ModalPreviewPrintLabel.propTypes = {
  data: PropTypes.object,
  disableButton: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalPreviewPrintLabel);
