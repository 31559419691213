import React from 'react';
import { connect } from 'react-redux';
import { Grid, FormControl, FormLabel } from '@material-ui/core';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { compose } from 'redux';
// component
import {
  ButtonMain,
  PrevNextStepperCount,
  TextInput,
  LabelInput,
} from '../../../../../components/Index';
// // style
import './StepAddEatNutritionStyle.scss';
// helper
import { 
  CommonHelper, 
  PermissionModule, 
  PermissionPage, 
  PermissionAccess 
} from '../../../../../helpers/Index';

const initialValidation = {
  calories: { isError: false, errorMessage: '' },
  carbohydrate: { isError: false, errorMessage: '' },
  fat: { isError: false, errorMessage: '' },
  protein: { isError: false, errorMessage: '' },
  fiber: { isError: false, errorMessage: '' },
};

const initialForm = {
  calories: '',
  carbohydrate: '',
  fat: '',
  protein: '',
  fiber: '',
};

class StepAddEatNutrition extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      form: CommonHelper.objectCloning(initialForm),
      validation: CommonHelper.objectCloning(initialValidation),
    };
  }

  componentDidMount() {
    const { parentForm, parentValidation } = this.props;
    const { form, validation } = this.state;

    this.setState({
      form: { ...form, ...parentForm },
      validation: { ...validation, ...parentValidation },
    });
  }

  componentWillReceiveProps(nextProps) {
    const { validation } = this.state;

    const isValidationChange = _.isEqual(validation, nextProps.parentValidation);

    if (!isValidationChange) {
      const finalMergeValidation = { ...validation, ...nextProps.parentValidation };
      this.setState({
        validation: finalMergeValidation,
      });
    }
  }

  componentWillUnmount() {}

  handleTextChangeCalories = value => {
    const { form, validation } = this.state;
    const message = CommonHelper.objectCloning(initialValidation.calories);
    message.isError = !value;
    message.errorMessage = !value ? 'Calories is empty' : '';

    this.setState({
      form: { ...form, calories: value },
      validation: {
        ...validation,
        calories: message,
      },
    });
  };

  handleTextChangeCarbohydrate = value => {
    const { form, validation } = this.state;
    const message = CommonHelper.objectCloning(initialValidation.carbohydrate);

    this.setState({
      form: { ...form, carbohydrate: value },
      validation: {
        ...validation,
        carbohydrate: message,
      },
    });
  };

  handleTextChangeFat = value => {
    const { form, validation } = this.state;
    const message = CommonHelper.objectCloning(initialValidation.fat);

    this.setState({
      form: { ...form, fat: value },
      validation: {
        ...validation,
        fat: message,
      },
    });
  };

  handleTextChangeProtein = value => {
    const { form, validation } = this.state;
    const message = CommonHelper.objectCloning(initialValidation.protein);

    this.setState({
      form: { ...form, protein: value },
      validation: {
        ...validation,
        protein: message,
      },
    });
  };

  handleTextChangeFiber = value => {
    const { form, validation } = this.state;
    const message = CommonHelper.objectCloning(initialValidation.fiber);

    this.setState({
      form: { ...form, fiber: value },
      validation: {
        ...validation,
        fiber: message,
      },
    });
  };

  handlePrevClick = async () => {
    const { onPrevClick, onParameterUpdate } = this.props;
    const { form } = this.state;

    await onParameterUpdate(form);
    await onPrevClick();
  };

  handleSubmitClick = async () => {
    const { onButtonClickSubmit, onParameterUpdate } = this.props;
    const { form } = this.state;
    const formNewValue = CommonHelper.objectCloning(form);

    await onParameterUpdate(formNewValue);
    await onButtonClickSubmit();
  };

  render() {
    const {
      form: { calories, carbohydrate, fat, protein, fiber },
      validation,
    } = this.state;
    const { onButtonClickCancel, isLoadingSubmit } = this.props;

    return (
      <Grid container className="container-step-eat-details">
        <Grid item lg={12} md={12} className="mt-16">
          <Grid container spacing={2}>
            <Grid item xl={4} lg={6} md={6}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <LabelInput labelText="Calories" isRequired />
                <TextInput
                  placeHolderText="Enter Eat Calories"
                  onChange={this.handleTextChangeCalories}
                  currentValue={calories}
                  isError={validation.calories.isError}
                  errorMessage={validation.calories.errorMessage}
                  size="md"
                />
              </FormControl>
            </Grid>
            <Grid item xl={4} lg={6} md={6}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <FormLabel component="label" className="text-12 filed-title">
                  Carbohydrate
                </FormLabel>
                <TextInput
                  placeHolderText="Enter Eat Carbohydrate"
                  onChange={this.handleTextChangeCarbohydrate}
                  currentValue={carbohydrate}
                  isError={validation.carbohydrate.isError}
                  errorMessage={validation.carbohydrate.errorMessage}
                  size="md"
                />
              </FormControl>
            </Grid>
            <Grid item xl={4} lg={6} md={6}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <FormLabel component="label" className="text-12 filed-title">
                  Fat
                </FormLabel>
                <TextInput
                  placeHolderText="Enter Eat Fat"
                  onChange={this.handleTextChangeFat}
                  currentValue={fat}
                  isError={validation.fat.isError}
                  errorMessage={validation.fat.errorMessage}
                  size="md"
                />
              </FormControl>
            </Grid>
            <Grid item xl={4} lg={6} md={6}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <FormLabel component="label" className="text-12 filed-title">
                  Protein
                </FormLabel>
                <TextInput
                  placeHolderText="Enter Eat Protein"
                  onChange={this.handleTextChangeProtein}
                  currentValue={protein}
                  isError={validation.protein.isError}
                  errorMessage={validation.protein.errorMessage}
                  size="md"
                />
              </FormControl>
            </Grid>
            <Grid item xl={4} lg={6} md={6}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <FormLabel component="label" className="text-12 filed-title">
                  Fiber
                </FormLabel>
                <TextInput
                  placeHolderText="Enter Eat Fiber"
                  onChange={this.handleTextChangeFiber}
                  currentValue={fiber}
                  isError={validation.fiber.isError}
                  errorMessage={validation.fiber.errorMessage}
                  size="md"
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={12} md={12} className="mt-18">
          <Grid container justify="flex-end">
            <Grid item lg={5} md={6}>
              <Grid container justify="flex-end">
                <Grid item lg={4} md={4} className="pl-8">
                  <ButtonMain
                    type="negative"
                    size="xl"
                    labelText="Cancel"
                    onClick={onButtonClickCancel}
                  />
                </Grid>
                <Grid item lg={4} md={4} className="pl-8">
                  <ButtonMain
                    type="ghost"
                    size="xl"
                    labelText="Prev"
                    onClick={this.handlePrevClick}
                  />
                </Grid>
                <Grid item lg={4} md={4} className="pl-8">
                  <ButtonMain
                    type="primary"
                    size="xl"
                    labelText="Submit"
                    onClick={this.handleSubmitClick}
                    disabled={isLoadingSubmit}
                    requiredPermission={`${PermissionModule.Eat}.${PermissionPage.Food}.${PermissionAccess.Add}`}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

const mapDispatchToProps = () => ({});

const mapStateToProps = () => ({});

StepAddEatNutrition.propTypes = {
  isLoadingSubmit: PropTypes.bool,
  onButtonClickCancel: PropTypes.func,
  onButtonClickSubmit: PropTypes.func,
  onParameterUpdate: PropTypes.func,
  onPrevClick: PropTypes.func,
  parentForm: PropTypes.object,
  parentValidation: PropTypes.object,
};

const shell = compose(connect(mapStateToProps, mapDispatchToProps));

export default shell(PrevNextStepperCount(StepAddEatNutrition));
