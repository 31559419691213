/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import { Upload, message, Form } from 'antd';
import { LoadingOutlined, PlusOutlined, FilePdfOutlined } from '@ant-design/icons';
import { FileHelper } from '../../../helpers/Index';
import './UploadImageStyle.scss';
// ENV
import { UPLOAD_URL } from '../../../environments/Environment';

function beforeUpload(file, maxSize) {
  const isJpgOrPngOrPdf =
    file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'application/pdf';

  if (!isJpgOrPngOrPdf) {
    message.error('You can only upload JPG, PNG, or PDF file!');
  }

  const isSize = file.size / 1024 / 1024 < maxSize;
  if (!isSize) {
    message.error(`File must be smaller than ${maxSize}MB!`);
  }

  return isJpgOrPngOrPdf && isSize;
}

const dummyUrl = UPLOAD_URL;

class UploadImage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      fileType: null,
    };
  }

  handleChange = info => {
    const { onChange } = this.props;
    if (info.file.status === 'uploading') {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === 'done') {
      // Determine file type
      const fileType = info.file.type;
      this.setState({ fileType });

      // Get this url from response in real world.
      FileHelper.getBase64(info.file.originFileObj, imageUrl => {
        onChange(imageUrl);
        this.setState({
          loading: false,
        });
      });
    }
  };

  render() {
    const {
      defaultValue,
      onChange,
      maxSize,
      errorMessage,
      validateStatus,
      setWidth,
      height,
    } = this.props;
    const { fileType } = this.state;

    const uploadButton = (
      <div>
        {this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div className="ant-upload-text">Choose Image...</div>
      </div>
    );

    return (
      <Form.Item validateStatus={validateStatus} help={errorMessage}>
        <div className="container-upload-image" style={{ height: height || 225 }}>
          <Upload
            listType="picture-card"
            showUploadList={false}
            action={dummyUrl}
            beforeUpload={file => beforeUpload(file, maxSize)}
            onChange={this.handleChange}
          >
            {defaultValue ? (
              <>
                {fileType === 'application/pdf' ? (
                  <div className="flex-column">
                    <FilePdfOutlined style={{ fontSize: '48px', color: '#f5222d' }} />
                    <a className="text-12 mt-8" onClick={() => {}}>
                      Change File...
                    </a>
                  </div>
                ) : (
                  <div className="container-img">
                    <img src={defaultValue} alt="avatar" style={{ width: setWidth || '100%' }} />
                    <a
                      className="text-12"
                      onClick={() => {
                        onChange(null);
                      }}
                    >
                      Change Image...
                    </a>
                  </div>
                )}
              </>
            ) : (
              uploadButton
            )}
          </Upload>
        </div>
      </Form.Item>
    );
  }
}

UploadImage.propTypes = {
  defaultValue: PropTypes.string,
  errorMessage: PropTypes.string,
  height: PropTypes.string,
  maxSize: PropTypes.number,
  onChange: PropTypes.func,
  setWidth: PropTypes.string,
  validateStatus: PropTypes.string,
};

export default UploadImage;
