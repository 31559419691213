/* eslint-disable react/no-access-state-in-setstate */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Table } from 'antd';
import _ from 'lodash';
import { Grid } from '@material-ui/core';
import { compose } from 'redux';
// Component
import {
  SnackBarSimple,
  SelectInputMain,
  PrevStateValue,
  GeneratorExcel,
  AuthenticationAccessPages,
  TextInput,
  LabelStatusMain,
  PickerInputDate,
  SkeletonMain,
  GeneratorPdf,
  EmptyState,
} from '../../../../../components/Index';
// Api
import {
  getListMealDeliveryPagination,
  getListMealDeliveryExport,
  getListFoodlist,
} from '../../../../../services/api/EatApi';
// Helpers
import { CommonHelper, MasterDataHelper, mealTimeTypeCode } from '../../../../../helpers/Index';
// Assets
import { Images } from '../../../../../assets/Index';
// Style
import './MealDeliveryListStyle.scss';

const currentDate = CommonHelper.currentDate('YYYY-MM-DD');
const endDateValue = CommonHelper.getEndDateMonth(currentDate);
const eatSessionOption = MasterDataHelper.eatSessionTime;
const optionTransactionSource = MasterDataHelper.optionTransactionSource;

const initialFoodlistOption = [
  {
    value: null,
    name: 'All Foodlist',
  },
];

const initialSort = {
  sortField: 'name',
  sortOrder: 'asc',
};

const optionToast = {
  vertical: 'top',
  horizontal: 'right',
};

const initialFilter = {
  search_term: '',
  foodlist: null,
  lunch_or_dinner: null,
  transaction_source: null,
  start_date: currentDate,
  end_date: endDateValue,
  limit: 10,
};

class MealDeliveryList extends React.Component {
  constructor(props) {
    super(props);
    const {
      usersReducer: { prevStateValue },
    } = props;

    const updateExitingFilter = {
      ...CommonHelper.objectCloning(initialFilter),
      ...prevStateValue,
    };

    this.state = {
      filter: updateExitingFilter,
      foodlistOption: initialFoodlistOption,
      sort: CommonHelper.objectCloning(initialSort),
      page: 1,
      limit: 10,
      pagination: {
        showSizeChanger: true,
        showTotal: (total, range) => `Showing ${range[0]}-${range[1]} from ${total}`,
        pageSizeOptions: ['10', '20', '30', '40'],
      },
      loading: true,
      isLoading: true,
      toastInformation: {
        isOpen: false,
        message: '',
        snackbarType: 'warning',
      },
      selectedRowKeys: [],
      selectedRowPrint: [],
    };
    this.searchDebounce = _.debounce(this.searchDebounce, 400);
  }

  componentDidMount() {
    const {
      onResetPrevStateValue,
      history: { location },
    } = this.props;

    this.getListFoodlist();
    this.getListMealPagination();

    onResetPrevStateValue({ url: location.pathname });
  }

  getListMealPagination = () => {
    const { listMealDeliveryPagination } = this.props;
    const { filter, pagination, limit, page } = this.state;

    const param = {
      search_term: filter.search_term,
      foodlist: filter.foodlist,
      lunch_or_dinner: filter.lunch_or_dinner,
      transaction_source: filter.transaction_source,
      start_date: filter.start_date,
      end_date: filter.end_date,
      page,
      limit,
    };

    this.setState({ loading: true }, () => {
      listMealDeliveryPagination(param).then(response => {
        const printLabel = response.data.data.map(item => {
          const labels = item.labels.map(datas => {
            return datas;
          });
          return {
            ...labels[0],
            customer_name: item.name,
            phone: item.phone,
            foodlist: item.foodlist,
            email: item.email,
            address: item.address,
            delivery_notes: item.notes,
          };
        });

        this.setState({
          loading: false,
          isLoading: false,
          pagination: { ...pagination, pageSize: param.limit, total: response.data.total },
          selectedRowPrint: printLabel,
        });
      });
    });
  };

  getListFoodlist = () => {
    const { listFoodlist } = this.props;
    listFoodlist().then(response => {
      const obj = response.data.map(item => {
        return { value: item.foodlist_id, name: item.name };
      });
      this.setState({
        loading: false,
        isLoading: false,
        foodlistOption: [...this.state.foodlistOption, ...obj],
      });
    });
  };

  searchDebounce = () => {
    this.getListMealPagination();
  };

  handleChangeSearch = value => {
    const { filter } = this.state;

    this.setState(
      {
        filter: { ...filter, search_term: value },
        page: 1,
        pagination: { ...this.state.pagination, current: 1 },
      },
      () => {
        this.searchDebounce();
      },
    );
  };

  handleSelectChangeFoodlist = value => {
    const { filter } = this.state;
    this.setState(
      {
        filter: {
          ...filter,
          foodlist: value,
        },
        page: 1,
        pagination: { ...this.state.pagination, current: 1 },
      },
      () => {
        this.getListMealPagination();
      },
    );
  };

  handleSelectChangeSession = value => {
    const { filter } = this.state;
    this.setState(
      {
        filter: {
          ...filter,
          lunch_or_dinner: value,
        },
        page: 1,
        pagination: { ...this.state.pagination, current: 1 },
      },
      () => {
        this.getListMealPagination();
      },
    );
  };

  handleSelectChangeTransactionSource = value => {
    const { filter } = this.state;
    this.setState(
      {
        filter: {
          ...filter,
          transaction_source: value,
        },
        page: 1,
        pagination: { ...this.state.pagination, current: 1 },
      },
      () => {
        this.getListMealPagination();
      },
    );
  };

  handleChangeStartDate = value => {
    const { filter } = this.state;
    const { onSetPrevStateValue } = this.props;
    const endDate = CommonHelper.getEndDateMonth(value);

    onSetPrevStateValue({ ...filter, start_date: value, end_date: endDate });

    this.setState(
      {
        filter: { ...filter, start_date: value, end_date: endDate },
        page: 1,
        pagination: { ...this.state.pagination, current: 1 },
      },
      () => {
        this.getListMealPagination();
      },
    );
  };

  handleChangeEndDate = value => {
    const { filter } = this.state;
    const { onSetPrevStateValue } = this.props;

    onSetPrevStateValue({ ...filter, end_date: value });

    this.setState(
      {
        filter: { ...filter, end_date: value },
        page: 1,
        pagination: { ...this.state.pagination, current: 1 },
      },
      () => {
        this.getListMealPagination();
      },
    );
  };

  handleButtonDownload = () => {
    const { listMealDeliveryExport } = this.props;
    const { filter, limit } = this.state;
    const param = {
      search_term: filter.search_term,
      foodlist: filter.foodlist,
      lunch_or_dinner: filter.lunch_or_dinner,
      transaction_source: filter.transaction_source,
      start_date: filter.start_date,
      end_date: filter.end_date,
      limit,
    };
    listMealDeliveryExport(param);
  };

  handleCloseToast = () => {
    const { toastInformation } = this.state;
    this.setState({ toastInformation: { ...toastInformation, isOpen: false } });
  };

  handleTableChange = (pagination, filters, sorter) => {
    const { sort } = this.state;

    this.setState(
      {
        limit: pagination.pageSize,
        pagination: {
          ...this.state.pagination,
          current: pagination.current,
          pageSize: pagination.pageSize,
        },
        sort: {
          ...sort,
          sortField: sorter.field || initialSort.sortField,
          sortOrder: sorter.order === 'ascend' || !sorter.order ? 'asc' : 'desc',
        },
        page: pagination.current,
      },
      () => {
        this.getListMealPagination();
      },
    );
  };

  handleClickRow = value => {
    const { history } = this.props;
    const param = {
      orderNo: value.order_no,
      deliveryDate: value.delivery_date,
    };
    const paramFilter = CommonHelper.encryptObject(param);
    history.push(`/eat/meal-delivery/details/${paramFilter}`);
  };

  handleSelectChangeRow = selectedRowKeys => {
    const {
      eatData: { mealDeliveryList },
    } = this.props;
    let converted = [];

    selectedRowKeys.forEach(item => {
      const param = mealDeliveryList.find(meal => {
        return meal.order_no + meal.delivery_date === item;
      });

      if (param) {
        const labels = param.labels.map(label => {
          return {
            ...label,
            customer_name: param.name,
            phone: param.phone,
            foodlist: param.foodlist,
            email: param.email,
            address: param.address,
            delivery_notes: param.notes,
          };
        });
        converted = [...converted, ...labels];
      }
    });

    this.setState({ selectedRowKeys, selectedRowPrint: converted });
  };

  onHandleCallbackMessage = params => {
    this.setState({
      toastInformation: params,
    });
  };

  renderColumns = () => {
    return [
      {
        title: 'Customer Name',
        dataIndex: 'name',
        render: (text, row) => {
          let type = '';
          switch (row.meal_time) {
            case mealTimeTypeCode.lunch:
              type = 'warning';
              break;
            case mealTimeTypeCode.dinner:
              type = 'primary';
              break;
            default:
              type = 'complete';
              break;
          }
          return (
            <Grid container key={row.order_no}>
              <Grid item lg={12} md={12}>
                <Grid
                  onClick={() => {
                    this.handleClickRow(row);
                  }}
                  container
                  direction="column"
                  className="container-row-content flex-wrap-unset"
                >
                  <Grid item className="pb-24">
                    <Grid
                      container
                      direction="row"
                      justify="space-between"
                      className="container-text-header"
                    >
                      <Grid item lg={8} md={8} className="left">
                        <Grid container direction="column">
                          <Grid item>
                            <label className="text-14 text-bold">{row.name}</label>
                          </Grid>
                          <Grid item>
                            <label className="text-11 subtitle">
                              {row.email} / {row.phone}
                            </label>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item lg={4} md={4} className="right">
                        <Grid container direction="column" justify="flex-end">
                          <Grid item>
                            <div className="margin-customize">
                              <label className="text-12 foodlist">{row.foodlist}</label>
                            </div>
                            <div className="margin-customize">
                              <LabelStatusMain value={row.meal_time} type={type} />
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item lg md className="container-info-body">
                    <Grid container justify="flex-start">
                      <Grid item lg={12} md={12}>
                        {this.renderRowAdditional(row)}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          );
        },
      },
    ];
  };

  renderRowAdditional = data => {
    const deliveryDate = CommonHelper.dateTimeParseNewFormat(data.delivery_date, 'DD MMMM YYYY');
    const lastUpdate = CommonHelper.dateTimeParseNewFormat(data.updated_at, 'DD MMMM YYYY | HH:mm');

    return (
      <Grid container justify="space-between">
        <Grid item lg={2} md={2}>
          <div className="flex-column">
            <label className="text-12 title">Delivery Date</label>
            <label className="text-12">{deliveryDate}</label>
          </div>
        </Grid>
        <Grid item>
          <div className="divider-hr-vertical" />
        </Grid>
        <Grid item lg={4} md={4}>
          <div className="flex-column">
            <label className="text-12 title">Delivery Address</label>
            <label className="text-12 mb-10">{data.address}</label>
            <label className="text-10 text-opacity-50 mb-4">Delivery Notes</label>
            <label className="text-10">{data.notes || '-'}</label>
          </div>
        </Grid>
        <Grid item>
          <div className="divider-hr-vertical" />
        </Grid>
        <Grid item lg={2} md={2}>
          <div className="flex-column">
            <label className="text-12 title">Order Number</label>
            <label className="text-12 mb-10">{data.order_no}</label>
            <label className="text-12 title">Transaction Source</label>
            <label className="text-12">{_.capitalize(data.transaction_source)}</label>
          </div>
        </Grid>
        <Grid item>
          <div className="divider-hr-vertical" />
        </Grid>
        <Grid item lg={2} md={2}>
          <div className="flex-column">
            <label className="text-12 title">Last Updated</label>
            <label className="text-12 mb-4">{data.updated_by}</label>
            <label className="text-12">{lastUpdate}</label>
          </div>
        </Grid>
      </Grid>
    );
  };

  renderFilter() {
    const { filter, foodlistOption, selectedRowPrint } = this.state;
    const {
      eatData: { mealDeliveryExport, fetchingExport },
    } = this.props;

    return (
      <Grid container direction="column" className="flex-wrap-unset">
        <Grid item>
          <Grid container justify="space-between" alignItems="center" className="container-filter">
            <Grid item lg={5} md={5}>
              <TextInput
                iconPrefix="ic-ffo-search"
                placeHolderText="Search by Name, Email, Phone or Order Number"
                onChange={this.handleChangeSearch}
                currentValue={filter.search_term}
                size="md"
              />
            </Grid>
            <Grid item lg={6} md={6}>
              <Grid container direction="row" justify="flex-end" alignItems="center">
                <Grid item className="mr-8">
                  <GeneratorPdf
                    data={selectedRowPrint}
                    buttonType="primary"
                    buttonLabel="Print Address"
                    customLabel="label-meal-address"
                    callbackMessage={this.onHandleCallbackMessage}
                  />
                </Grid>
                <Grid item className="mr-8">
                  <GeneratorPdf
                    data={selectedRowPrint}
                    buttonType="primary"
                    buttonLabel="Print Label"
                    customLabel="label-meal"
                    callbackMessage={this.onHandleCallbackMessage}
                  />
                </Grid>
                <Grid item>
                  <GeneratorExcel
                    labelText="Export Meal Order"
                    buttonSize="md"
                    dataSetArray={mealDeliveryExport}
                    fileName={`Meal_Delivery_Report_${filter.start_date}_${filter.end_date}`}
                    iconPrefix="ic-ffo-download"
                    isLoading={fetchingExport}
                    onClick={this.handleButtonDownload}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item className="mt-24">
          <Grid container justify="space-between" alignItems="center">
            <Grid item lg={5} md={5}>
              <Grid container justify="flex-start" alignItems="center" className="container-filter">
                <Grid item lg={4} md={4} className="row-filter">
                  <SelectInputMain
                    options={foodlistOption}
                    currentValue={filter.foodlist}
                    onChange={this.handleSelectChangeFoodlist}
                  />
                </Grid>
                <Grid item lg={4} md={4} className="row-filter">
                  <SelectInputMain
                    options={eatSessionOption}
                    currentValue={filter.lunch_or_dinner}
                    onChange={this.handleSelectChangeSession}
                  />
                </Grid>
                <Grid item lg={4} md={4} className="row-filter">
                  <SelectInputMain
                    options={optionTransactionSource}
                    currentValue={filter.transaction_source}
                    onChange={this.handleSelectChangeTransactionSource}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg="auto" md="auto">
              <div className="flex-column">
                <Grid container justify="flex-end" alignItems="center">
                  <Grid item lg={3} md={3} className="text-right">
                    <label className="text-12 pr-16">Showing Date</label>
                  </Grid>
                  <Grid item lg={4} md={4}>
                    <div className="container-remove-margin">
                      <PickerInputDate
                        customIcon="ic-ffo-date-pick"
                        dateFormat="dd/MM/yyyy"
                        defaultValue={filter.start_date}
                        onChange={this.handleChangeStartDate}
                        toolbar={false}
                      />
                    </div>
                  </Grid>
                  <label className="text-12 pr-8 pl-8"> - </label>
                  <Grid item lg={4} md={4}>
                    <div className="container-remove-margin">
                      <PickerInputDate
                        customIcon="ic-ffo-date-pick"
                        dateFormat="dd/MM/yyyy"
                        minDate={filter.start_date}
                        defaultValue={filter.end_date}
                        onChange={this.handleChangeEndDate}
                        toolbar={false}
                      />
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  render() {
    const {
      eatData: { mealDeliveryList },
    } = this.props;
    const { isLoading, loading, pagination, toastInformation, selectedRowKeys } = this.state;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.handleSelectChangeRow,
    };

    let renderElement = <SkeletonMain />;

    if (!isLoading) {
      renderElement = (
        <div className="container-page-meal scroll-container">
          <div className="container-page-scrolling-area include-tab">
            <Grid container direction="column" className="flex-wrap-unset">
              <Grid item lg md sm className="section-page-header">
                {this.renderFilter()}
              </Grid>
              <Grid item lg md className="section-page-body">
                {_.isEmpty(mealDeliveryList) ? (
                  <Grid container justify={'center'}>
                    <EmptyState source={Images.emptyStateNoHistory} labelText={'No Order Found'} />
                  </Grid>
                ) : (
                  <Table
                    rowSelection={{
                      type: 'checkbox',
                      ...rowSelection,
                    }}
                    columns={this.renderColumns()}
                    rowKey={record => record.order_no + record.delivery_date}
                    dataSource={mealDeliveryList}
                    pagination={pagination}
                    loading={loading}
                    onChange={this.handleTableChange}
                  />
                )}
              </Grid>
            </Grid>
            <SnackBarSimple
              open={toastInformation.isOpen}
              durationHide={2000}
              message={toastInformation.message}
              onClickClose={this.handleCloseToast}
              snackbarType={toastInformation.snackbarType}
              anchor={optionToast}
            />
          </div>
        </div>
      );
    }
    return renderElement;
  }
}

const mapDispatchToProps = dispatch => ({
  listMealDeliveryPagination: params => getListMealDeliveryPagination(dispatch, params),
  listMealDeliveryExport: params => getListMealDeliveryExport(dispatch, params),
  listFoodlist: () => getListFoodlist(dispatch),
});

const mapStateToProps = ({ masterDataMain, eatData, usersReducer }) => ({
  masterDataMain,
  eatData,
  usersReducer,
});

MealDeliveryList.propTypes = {
  eatData: PropTypes.object,
  history: PropTypes.object,
  listFoodlist: PropTypes.func,
  listMealDeliveryExport: PropTypes.func,
  listMealDeliveryPagination: PropTypes.func,
  onResetPrevStateValue: PropTypes.func,
  onSetPrevStateValue: PropTypes.func,
  usersReducer: PropTypes.object,
};

const shell = compose(connect(mapStateToProps, mapDispatchToProps));
const core = compose(AuthenticationAccessPages, PrevStateValue);

export default shell(core(MealDeliveryList));
