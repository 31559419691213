import React from 'react';
import PropTypes from 'prop-types';
// style
import './CheckInputOutlineBackgroundStyle.scss';
// component
import { CheckInput } from '../../Index';

const CheckInputOutlineBackground = props => {
  const { labelText, onCheck, currentValue, size, disabled } = props;

  const [isMultiple, setMultiple] = React.useState(
    currentValue === undefined ? false : currentValue || false,
  );

  const handleItemChange = value => {
    const result = value;
    setMultiple(result);
    onCheck(result);
  };

  React.useEffect(() => {
    const updateState = () => {
      setMultiple(currentValue === undefined ? false : currentValue);
    };

    updateState();
  }, [currentValue]);

  return (
    <div
      className={`checkbox-outline-background ${size} text-14 ${
        isMultiple ? 'active' : 'in-active'
      } ${disabled ? 'disabled' : ''}`}
    >
      <CheckInput
        currentValue={isMultiple}
        labelText={labelText}
        onCheck={handleItemChange}
        colorCheck="default"
        disabled={disabled}
      />
    </div>
  );
};

CheckInputOutlineBackground.propTypes = {
  currentValue: PropTypes.bool,
  disabled: PropTypes.bool,
  labelText: PropTypes.string,
  onCheck: PropTypes.func,
  size: PropTypes.string,
};

CheckInputOutlineBackground.defaultProps = {
  size: 'middle',
};

export default CheckInputOutlineBackground;
