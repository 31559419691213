import { constants } from '../reducers/OrderReducer';

// #region retail
export function getOrderListRequest() {
  return { type: constants.getOrderListRequest };
}
export function getOrderListSuccess(response) {
  return { type: constants.getOrderListSuccess, payload: response };
}
export function getOrderListFail(response) {
  return { type: constants.getOrderListFail, payload: response.data };
}
// #region

// #region eat
export function getOrderEatListByTypeRequest() {
  return { type: constants.getOrderEatListByTypeRequest };
}
export function getOrderEatListByTypeSuccess(response) {
  return { type: constants.getOrderEatListByTypeSuccess, payload: response };
}
export function getOrderEatListByTypeFail(response) {
  return { type: constants.getOrderEatListByTypeFail, payload: response.data };
}

export function getOrderEatListByTypeProductRequest() {
  return { type: constants.getOrderEatListByTypeProductRequest };
}
export function getOrderEatListByTypeProductSuccess(response) {
  return { type: constants.getOrderEatListByTypeProductSuccess, payload: response };
}
export function getOrderEatListByTypeProductFail(response) {
  return { type: constants.getOrderEatListByTypeProductFail, payload: response.data };
}
// #region

export function getOrderDetailsRequest() {
  return { type: constants.getOrderDetailsRequest };
}
export function getOrderDetailsSuccess(response) {
  return { type: constants.getOrderDetailsSuccess, payload: response };
}
export function getOrderDetailsFail(response) {
  return { type: constants.getOrderDetailsFail, payload: response.data };
}

export function getUserDetailByPhoneRequest() {
  return { type: constants.getUserDetailByPhoneRequest };
}
export function getUserDetailByPhoneSuccess(response) {
  return { type: constants.getUserDetailByPhoneSuccess, payload: response };
}
export function getUserDetailByPhoneFail(response) {
  return { type: constants.getUserDetailByPhoneFail, payload: response.data };
}

export function getOrderProductPaginationRequest() {
  return { type: constants.getOrderProductPaginationRequest };
}
export function getOrderProductPaginationSuccess(response) {
  return { type: constants.getOrderProductPaginationSuccess, payload: response };
}
export function getOrderProductPaginationFail(response) {
  return { type: constants.getOrderProductPaginationFail, payload: response.data };
}

export function getOrderShippingAddressRequest() {
  return { type: constants.getOrderShippingAddressRequest };
}
export function getOrderShippingAddressSuccess(response) {
  return { type: constants.getOrderShippingAddressSuccess, payload: response };
}
export function getOrderShippingAddressFail(response) {
  return { type: constants.getOrderShippingAddressFail, payload: response.data };
}

export function getOrderShippingAddressInfoRequest() {
  return { type: constants.getOrderShippingAddressRequest };
}
export function getOrderShippingAddressInfoSuccess(response) {
  return { type: constants.getOrderShippingAddressInfoSuccess, payload: response };
}
export function getOrderShippingAddressInfoFail(response) {
  return { type: constants.getOrderShippingAddressInfoFail, payload: response.data };
}

export function getOrderShippingCostRequest() {
  return { type: constants.getOrderShippingCostRequest };
}
export function getOrderShippingCostSuccess(response) {
  return { type: constants.getOrderShippingCostSuccess, payload: response };
}
export function getOrderShippingCostFail(response) {
  return { type: constants.getOrderShippingCostFail, payload: response.data };
}

export function setCurrentAddressTypeOption(param) {
  return { type: constants.setCurrentAddressTypeOption, payload: param };
}

export function setCurrentAddressTypeOptionRaw(param) {
  return { type: constants.setCurrentAddressTypeOptionRaw, payload: param };
}

export function setCurrentDeliveryService(param) {
  return { type: constants.setCurrentDeliveryService, payload: param };
}

export function checkPromoCodeRequest() {
  return { type: constants.checkPromoCodeRequest };
}
export function checkPromoCodeSuccess(response) {
  return { type: constants.checkPromoCodeSuccess, payload: response };
}
export function checkPromoCodeFail(response) {
  return { type: constants.checkPromoCodeFail, payload: response.data };
}

export function setProcessCreateOrderRequest() {
  return { type: constants.setProcessCreateOrderRequest };
}
export function setProcessCreateOrderSuccess() {
  return { type: constants.setProcessCreateOrderSuccess };
}
export function setProcessCreateOrderFail(response) {
  return { type: constants.setProcessCreateOrderFail, payload: response.data };
}

export function getOrderShipmentListRequest() {
  return { type: constants.getOrderShipmentListRequest };
}
export function getOrderShipmentListSuccess(response) {
  return { type: constants.getOrderShipmentListSuccess, payload: response };
}
export function getOrderShipmentListFail(response) {
  return { type: constants.getOrderShipmentListFail, payload: response.data };
}

export function getOrderShipmentDetailsRequest() {
  return { type: constants.getOrderShipmentDetailsRequest };
}
export function getOrderShipmentDetailsSuccess(response) {
  return { type: constants.getOrderShipmentDetailsSuccess, payload: response };
}
export function getOrderShipmentDetailsFail(response) {
  return { type: constants.getOrderShipmentDetailsFail, payload: response.data };
}

export function getOrderSummaryListRequest() {
  return { type: constants.getOrderSummaryListRequest };
}
export function getOrderSummaryListSuccess(response) {
  return { type: constants.getOrderSummaryListSuccess, payload: response };
}
export function getOrderSummaryListFail(response) {
  return { type: constants.getOrderSummaryListFail, payload: response.data };
}

export function getOrderSummaryReportExportRequest() {
  return { type: constants.getOrderSummaryReportExportRequest };
}
export function getOrderSummaryReportExportSuccess(response) {
  return { type: constants.getOrderSummaryReportExportSuccess, payload: response };
}
export function getOrderSummaryReportExportFail(response) {
  return { type: constants.getOrderSummaryReportExportFail, payload: response.data };
}

export function getOrderShipmentTrackingDetailsRequest() {
  return { type: constants.getOrderShipmentTrackingDetailsRequest };
}
export function getOrderShipmentTrackingDetailsSuccess(response) {
  return { type: constants.getOrderShipmentTrackingDetailsSuccess, payload: response };
}
export function getOrderShipmentTrackingDetailsFail(response) {
  return { type: constants.getOrderShipmentTrackingDetailsFail, payload: response.data };
}

export function getShipmentDeliveryReportRequest() {
  return { type: constants.getShipmentDeliveryReportRequest };
}
export function getShipmentDeliveryReportSuccess(response) {
  return { type: constants.getShipmentDeliveryReportSuccess, payload: response };
}
export function getShipmentDeliveryReportFail(response) {
  return { type: constants.getShipmentDeliveryReportFail, payload: response.data };
}

export function getShipmentDeliveryReportExportRequest() {
  return { type: constants.getShipmentDeliveryReportExportRequest };
}
export function getShipmentDeliveryReportExportSuccess(response) {
  return { type: constants.getShipmentDeliveryReportExportSuccess, payload: response };
}
export function getShipmentDeliveryReportExportFail(response) {
  return { type: constants.getShipmentDeliveryReportExportFail, payload: response.data };
}

export function getShipmentExportRequest() {
  return { type: constants.getShipmentExportRequest };
}
export function getShipmentExportSuccess(response) {
  return { type: constants.getShipmentExportSuccess, payload: response };
}
export function getShipmentExportFail(response) {
  return { type: constants.getShipmentExportFail, payload: response.data };
}

export function getShippingDetailsRequest() {
  return { type: constants.getShippingDetailsRequest };
}
export function getShippingDetailsSuccess(response) {
  return { type: constants.getShippingDetailsSuccess, payload: response };
}
export function getShippingDetailsFail(response) {
  return { type: constants.getShippingDetailsFail, payload: response.data };
}

export function getHandoverListRequest() {
  return { type: constants.getHandoverListRequest };
}
export function getHandoverListSuccess(response) {
  return { type: constants.getHandoverListSuccess, payload: response };
}
export function getHandoverListFail(response) {
  return { type: constants.getHandoverListFail, payload: response.data };
}

export function getHandoverDetailsRequest() {
  return { type: constants.getHandoverDetailsRequest };
}
export function getHandoverDetailsSuccess(response) {
  return { type: constants.getHandoverDetailsSuccess, payload: response };
}
export function getHandoverDetailsFail(response) {
  return { type: constants.getHandoverDetailsFail, payload: response.data };
}

export function getCategoryListRequest() {
  return { type: constants.getCategoryListRequest };
}
export function getCategoryListSuccess(response) {
  return { type: constants.getCategoryListSuccess, payload: response };
}
export function getCategoryListFail(response) {
  return { type: constants.getCategoryListFail, payload: response.data };
}

export function deleteHandoverRequest() {
  return { type: constants.deleteHandoverRequest };
}
export function deleteHandoverSuccess(response) {
  return { type: constants.deleteHandoverSuccess, payload: response };
}
export function deleteHandoverFail(response) {
  return { type: constants.deleteHandoverFail, payload: response.data };
}

export function getDataPrintHandoverRequest() {
  return { type: constants.getDataPrintHandoverRequest };
}
export function getDataPrintHandoverSuccess(response) {
  return { type: constants.getDataPrintHandoverSuccess, payload: response };
}
export function getDataPrintHandoverFail(response) {
  return { type: constants.getDataPrintHandoverFail, payload: response.data };
}

export function PostHandoverRequest() {
  return { type: constants.PostHandoverRequest };
}
export function PostHandoverSuccess(response) {
  return { type: constants.PostHandoverSuccess, payload: response };
}
export function PostHandoverFail(response) {
  return { type: constants.PostHandoverFail, payload: response.data };
}

export function getNameHandoverRequest() {
  return { type: constants.getNameHandoverRequest };
}
export function getNameHandoverSuccess(response) {
  return { type: constants.getNameHandoverSuccess, payload: response };
}
export function getNameHandoverFail(response) {
  return { type: constants.getNameHandoverFail, payload: response.data };
}

// Purchase Order
export function getPurchaseOrderPaginationRequest() {
  return { type: constants.getPurchaseOrderPaginationRequest };
}
export function getPurchaseOrderPaginationSuccess(response) {
  return { type: constants.getPurchaseOrderPaginationSuccess, payload: response };
}
export function getPurchaseOrderPaginationFail(response) {
  return { type: constants.getPurchaseOrderPaginationFail, payload: response.data };
}

export function setDeletePurchaseOrderRequest() {
  return { type: constants.setDeletePurchaseOrderRequest };
}
export function setDeletePurchaseOrderSuccess(response) {
  return { type: constants.setDeletePurchaseOrderSuccess, payload: response };
}
export function setDeletePurchaseOrderFail(response) {
  return { type: constants.setDeletePurchaseOrderFail, payload: response.data };
}

export function getPurchaseOrderDetailsRequest() {
  return { type: constants.getPurchaseOrderDetailsRequest };
}
export function getPurchaseOrderDetailsSuccess(response) {
  return { type: constants.getPurchaseOrderDetailsSuccess, payload: response };
}
export function getPurchaseOrderDetailsFail(response) {
  return { type: constants.getPurchaseOrderDetailsFail, payload: response.data };
}

export function getPurchaseOrderPrintRequest() {
  return { type: constants.getPurchaseOrderPrintRequest };
}
export function getPurchaseOrderPrintSuccess(response) {
  return { type: constants.getPurchaseOrderPrintSuccess, payload: response };
}
export function getPurchaseOrderPrintFail(response) {
  return { type: constants.getPurchaseOrderPrintFail, payload: response.data };
}

export function getAvailablePromoCodeRequest() {
  return { type: constants.getAvailablePromoCodeRequest };
}
export function getAvailablePromoCodeSuccess(response) {
  return { type: constants.getAvailablePromoCodeSuccess, payload: response };
}
export function getAvailablePromoCodeFail(response) {
  return { type: constants.getAvailablePromoCodeFail, payload: response.data };
}

export function getGuestUserRequest() {
  return { type: constants.getGuestUserRequest };
}
export function getGuestUserSuccess(response) {
  return { type: constants.getGuestUserSuccess, payload: response };
}
export function getGuestUserFail(response) {
  return { type: constants.getGuestUserFail, payload: response.data };
}

export function getFavoriteMenusRequest() {
  return { type: constants.getFavoriteMenusRequest };
}
export function getFavoriteMenusSuccess(response) {
  return { type: constants.getFavoriteMenusSuccess, payload: response };
}
export function getFavoriteMenusFail(response) {
  return { type: constants.getFavoriteMenusFail, payload: response.data };
}

export function cancelOrderRequest() {
  return { type: constants.cancelOrderRequest };
}
export function cancelOrderSuccess(response) {
  return { type: constants.cancelOrderSuccess, payload: response };
}
export function cancelOrderFail(response) {
  return { type: constants.cancelOrderFail, payload: response.data };
}

export function saveOpenBillRequest() {
  return { type: constants.saveOpenBillRequest };
}
export function saveOpenBillSuccess(response) {
  return { type: constants.saveOpenBillSuccess, payload: response };
}
export function saveOpenBillFail(response) {
  return { type: constants.saveOpenBillFail, payload: response.data };
}

export function submitOpenBillRequest() {
  return { type: constants.submitOpenBillRequest };
}
export function submitOpenBillSuccess(response) {
  return { type: constants.submitOpenBillSuccess, payload: response };
}
export function submitOpenBillFail(response) {
  return { type: constants.submitOpenBillFail, payload: response.data };
}

export function setPrintCaptainOrderRequest() {
  return { type: constants.setPrintCaptainOrderRequest };
}
export function setPrintCaptainOrderSuccess(response) {
  return { type: constants.setPrintCaptainOrderSuccess, payload: response };
}
export function setPrintCaptainOrderFail(response) {
  return { type: constants.setPrintCaptainOrderFail, payload: response.data };
}

export function getConsultationOrderDetailRequest() {
  return { type: constants.getConsultationOrderDetailRequest };
}
export function getConsultationOrderDetailSuccess(response) {
  return { type: constants.getConsultationOrderDetailSuccess, payload: response };
}
export function getConsultationOrderDetailFail(response) {
  return { type: constants.getConsultationOrderDetailFail, payload: response.data };
}

export function changeOrderPaymentRequest() {
  return { type: constants.changeOrderPaymentRequest };
}
export function changeOrderPaymentSuccess(response) {
  return { type: constants.changeOrderPaymentSuccess, payload: response };
}
export function changeOrderPaymentFail(response) {
  return { type: constants.changeOrderPaymentFail, payload: response.data };
}

export function getOrderTaxPercentageRequest() {
  return { type: constants.getOrderTaxPercentageRequest };
}
export function getOrderTaxPercentageSuccess(response) {
  return { type: constants.getOrderTaxPercentageSuccess, payload: response };
}
export function getOrderTaxPercentageFail(response) {
  return { type: constants.getOrderTaxPercentageFail, payload: response.data };
}

export function getOrderServiceChargePercentageRequest() {
  return { type: constants.getOrderServiceChargePercentageRequest };
}
export function getOrderServiceChargePercentageSuccess(response) {
  return { type: constants.getOrderServiceChargePercentageSuccess, payload: response };
}
export function getOrderServiceChargePercentageFail(response) {
  return { type: constants.getOrderServiceChargePercentageFail, payload: response.data };
}
