import { CommonHelper, MasterDataHelper, TransactionStatus, OrderType } from './Index';

const UserHelper = {
  getCurrentUserInformation() {
    const result = JSON.parse(localStorage.getItem('user_info'));

    return result;
  },
  getMerchantActiveUser() {
    const currentUser = this.getCurrentUserInformation();
    const merchantActive = currentUser.user_merchants;
    const converted = [];

    merchantActive.forEach(item => {
      converted.push(item.merchant_id);
    });
    const result = converted;

    return result;
  },
  getUserPagesAccess() {
    const currentUser = this.getCurrentUserInformation();
    const result = currentUser ? currentUser.pages_access || currentUser.page_access : [];

    return result;
  },
  reUpdateUserAuthentication(data) {
    const currentUser = this.getCurrentUserInformation();
    const updatedUser = { ...currentUser, ...data };

    const result = JSON.stringify({
      ...updatedUser,
      fetching: false,
      isLoggedIn: true,
      loginError: false,
    });

    return result;
  },
  generateUserDetails(dataArray) {
    let params = null;

    if (dataArray) {
      const currentDate = CommonHelper.currentDate('YYYY-MM-DD');
      const defaultBirthday = CommonHelper.intervalDate(currentDate, 'YYYY-MM-DD', -3, 'year');
      const birthday = dataArray.birthday
        ? CommonHelper.dateTimeParseNewFormat(dataArray.birthday, 'YYYY-MM-DD')
        : defaultBirthday;
      const currentName = dataArray.user_name.trim().split(' ');

      params = {
        userId: dataArray.user_id,
        isStaff: !!dataArray.is_staff,
        firstName: currentName[0] || '',
        lastName: currentName[1] || '',
        merchantId: null,
        branchId: null,
        companyId: dataArray.company_id || null,
        roleId: dataArray.role_id || null,
        birthday,
        nation: dataArray.nation || MasterDataHelper.nationality[0].value,
        gender: dataArray.gender || MasterDataHelper.gender[0].value,
        email: dataArray.email || '',
        phone: dataArray.phone || '',
        address: dataArray.user_address ? dataArray.user_address.address : '',
        imageBase64: dataArray.image || null,
      };
    }
    const result = params;

    return result;
  },
  generateUserCompanyDetail(dataArray) {
    const converted = [];

    if (dataArray) {
      dataArray.forEach(item => {
        const branchConverted = [];
        item.branch_id.forEach(itemBranch => {
          branchConverted.push(itemBranch.branch_id);
        });

        const param = {
          merchant_id: item.merchant_id,
          all_branch: false,
          branch_id: branchConverted,
        };

        converted.push(param);
      });
    }
    const result = converted;

    return result;
  },
  generateUserActivity(data) {
    let params = null;
    const converted = [];
    let transactionIcon = '';
    let transactionIconColor = '';
    let firstInformationIcon = '';
    let secondInformationIcon = '';

    if (data) {
      switch (data.product_type) {
        case OrderType.Move:
          transactionIcon = 'ic-ffo-lg-book';
          transactionIconColor = 'booked';
          firstInformationIcon = 'ic-ffo-appointment-date';
          secondInformationIcon = 'ic-ffo-pin';
          break;
        case OrderType.FitPointMembership:
        case OrderType.FreezeMembership:
          transactionIcon = 'ic-ffo-memberships';
          transactionIconColor = 'topUp';
          break;
        case OrderType.TopUpPoint:
          transactionIcon = 'ic-ffo-lg-topup';
          transactionIconColor = 'topUp';
          firstInformationIcon = 'ic-ffo-debit-card';
          secondInformationIcon = 'ic-ffo-id-card';
          break;
        case OrderType.Eat:
          transactionIcon = 'ic-ffo-lg-eats';
          transactionIconColor = 'purchase';
          firstInformationIcon = 'ic-ffo-delivery-date';
          secondInformationIcon = 'ic-ffo-pin';
          break;
        case OrderType.Shop:
          transactionIcon = 'ic-ffo-lg-shop';
          transactionIconColor = 'purchase';
          firstInformationIcon = 'ic-ffo-delivery-date';
          secondInformationIcon = 'ic-ffo-pin';
          break;

        default:
          break;
      }

      if (data.details.length > 0) {
        const detailArray = data.details;
        detailArray.forEach((item, index) => {
          const valueDetail = item.value;
          let newValue = item.value;

          if (CommonHelper.checkValidDate(valueDetail)) {
            newValue = CommonHelper.dateTimeParseNewFormat(valueDetail, 'DD MMM YYYY | LT');
          }

          const secondIcon = index > 1 ? '' : secondInformationIcon;
          const param = {
            icon: index === 0 ? firstInformationIcon : secondIcon,
            name: item.name,
            value: newValue === 'Invalid date' ? valueDetail : newValue,
          };

          converted.push(param);
        });
      }

      const transactionDate = CommonHelper.dateTimeParseNewFormat(
        data.transaction_date,
        'DD MMM YYYY | LT',
      );

      params = {
        transactionIcon,
        transactionIconColor,
        salesOrderId: data.sales_order_id,
        orderNo: data.order_no,
        transactionDate,
        activityTypeName: data.activity_type_name,
        paymentMethod: data.payment_method,
        totalPaid: data.total_paid,
        paymentStatus: data.payment_status,
        productName: data.product_name,
        productType: data.product_type,
        details: converted,
      };
    }

    const result = params;

    return result;
  },
  generateUserActivityDetails(data) {
    let params = null;
    const convertedInformation = [];
    const convertedProduct = [];
    let totalDiscount = 0;
    let transactionIcon = '';
    let transactionIconColor = '';
    let firstInformationIcon = '';
    let secondInformationIcon = '';

    if (data) {
      switch (data.product_type) {
        case TransactionStatus.WorkoutSession:
        case TransactionStatus.CoachSession:
          transactionIcon = 'ic-ffo-lg-book';
          transactionIconColor = 'booked';
          firstInformationIcon = 'ic-ffo-appointment-date';
          secondInformationIcon = 'ic-ffo-pin';
          break;
        case TransactionStatus.PointBooster:
          transactionIcon = 'ic-ffo-memberships';
          transactionIconColor = 'topUp';
          break;
        case TransactionStatus.TopUpPoint:
          transactionIcon = 'ic-ffo-lg-topup';
          transactionIconColor = 'topUp';
          firstInformationIcon = 'ic-ffo-debit-card';
          secondInformationIcon = 'ic-ffo-id-card';
          break;
        case TransactionStatus.Food:
          transactionIcon = 'ic-ffo-lg-eats';
          transactionIconColor = 'purchase';
          firstInformationIcon = 'ic-ffo-delivery-date';
          secondInformationIcon = 'ic-ffo-pin';
          break;
        case TransactionStatus.Retail:
          transactionIcon = 'ic-ffo-lg-shop';
          transactionIconColor = 'purchase';
          firstInformationIcon = 'ic-ffo-delivery-date';
          secondInformationIcon = 'ic-ffo-pin';
          break;
        default:
          break;
      }

      if (data.details.length > 0) {
        const detailArray = data.details;
        detailArray.forEach((item, index) => {
          const valueDetail = item.value;
          let newValue = item.value;

          if (CommonHelper.checkValidDate(valueDetail)) {
            newValue = CommonHelper.dateTimeParseNewFormat(valueDetail, 'DD MMM YYYY | LT');
          }

          const secondIcon = index > 1 ? '' : secondInformationIcon;
          const param = {
            icon: index === 0 ? firstInformationIcon : secondIcon,
            name: item.name,
            value: newValue === 'Invalid date' ? valueDetail : newValue,
          };

          convertedInformation.push(param);
        });
      }

      if (data.product_details.length > 0) {
        const detailArray = data.product_details;
        detailArray.forEach(item => {
          const param = {
            productName: item.product_name,
            productPrice: item.product_price,
            qty: item.qty,
            priceDiscount: item.price_discount,
            deliveryDate: item.delivery_date || null,
            totalProductPrice: item.total_product_price,
          };

          totalDiscount += item.price_discount;
          convertedProduct.push(param);
        });
      }

      const transactionDate = CommonHelper.dateTimeParseNewFormat(
        data.transaction_date,
        'DD MMM YYYY | LT',
      );

      params = {
        transactionIcon,
        transactionIconColor,
        salesOrderId: data.sales_order_id,
        orderNo: data.order_no,
        transactionDate,
        activityTypeName: data.activity_type_name,
        paymentMethod: data.payment_method,
        totalPaid: data.total_paid,
        shippingCost: data.shipping_cost,
        totalDiscount,
        productType: data.product_type,
        paymentStatus: data.payment_status,
        productDetails: convertedProduct,
        details: convertedInformation,
      };
    }

    const result = params;

    return result;
  },

  statusGlobal: [
    { value: null, name: 'All Status' },
    { value: 10, name: 'Active' },
    { value: 0, name: 'Inactive' },
    { value: -1, name: 'Deleted' },
  ],
};

const UserStatus = {
  Active: 10,
  Inactive: 0,
  Deleted: -1,
};

const UserType = {
  User: 0,
  Coach: 1,
  Admin: 2,
};

const UserRoleName = {
  SuperAdmin: 'Super Admin',
  Reseller: 'Reseller',
  EatExpressAdmin: 'FITCO Eats - Express Admin',
};

export { UserHelper, UserStatus, UserType, UserRoleName };
