import React from 'react';
import PropTypes from 'prop-types';
import { Grid, IconButton } from '@material-ui/core';
import { connect } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';
// component
import {
  ModalInformationPopUp,
  ButtonMain,
  SelectInputMain,
  SnackBarSimple,
  ModalAlertInformation,
  SkeletonModalMedia,
} from '../../../../../components/Index';
// Style
import './ModalTncSectionDetailStyle.scss';
// helper
import {
  CommonHelper,
  MasterDataHelper,
  PermissionAccess,
  PermissionModule,
  PermissionPage,
  PermissionHelper,
} from '../../../../../helpers/Index';
import {
  getTncSectionDetails,
  updateStatusTncSection,
} from '../../../../../services/api/TncSection';

const modalAlertOptionInformation = {
  title: 'Information',
  text: `You can't Perform this Action, Please Cancel all Appointment Thant Already Made, before changing schedule status`,
};

const optionToash = {
  vertical: 'top',
  horizontal: 'right',
};

const actionOptions = MasterDataHelper.statusGlobal;

class ModalTncSectionDetail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      openModal: false,
      isOpenModalInformation: false,
      isLoading: false,
      toastInformation: {
        isOpen: false,
        message: '',
        snackbarType: 'warning',
      },
    };
  }

  componentDidMount() {
    const { isOpen, tncId, getTncDetails } = this.props;

    this.setState({ openModal: isOpen, isLoading: true });
    if (tncId) {
      getTncDetails(tncId).then(() => {
        this.setState({ isLoading: false });
      });
    }
  }

  handleClose = () => {
    const { onClose } = this.props;
    onClose(false);
    this.setState({ openModal: false });
  };

  handleCloseModalInformation = () => {
    this.setState({ isOpenModalInformation: false });
  };

  handleCloseToash = () => {
    const { toastInformation } = this.state;
    this.setState({ toastInformation: { ...toastInformation, isOpen: false } });
  };

  handleClickEdit = () => {
    const { history, tncId } = this.props;
    history.push(`/marketing/tnc-section/edit/${tncId}`);
  };

  handleChangeAction = value => {
    const { updateStatusTnc, tncId, onReload } = this.props;
    const params = {
      data: {
        id: tncId,
        status: value,
      },
    };

    updateStatusTnc(params)
      .then(() => {
        const {
          tncData: { responseMessage },
        } = this.props;

        this.setState(
          {
            isOpenModalInformation: false,
          },
          async () => {
            await this.processMessage(responseMessage, 'success');
            onReload();
          },
        );
      })
      .catch(error => {
        const message = error.data;
        this.processMessage(message.messages, 'error');
      });
  };

  processMessage(messages, type) {
    const convertedMessage = CommonHelper.generateMessage(messages);

    const paramInformation = {
      isOpen: true,
      message: convertedMessage,
      snackbarType: type,
    };

    this.setState({
      toastInformation: paramInformation,
    });
  }

  render() {
    const { openModal, isLoading, toastInformation, isOpenModalInformation } = this.state;

    const {
      tncData: { tncDetails },
    } = this.props;
    const actionSelected = tncDetails.status;
    const hasEditPermission = PermissionHelper.checkUserHasPermission(
      PermissionModule.Marketing,
      PermissionPage.TnCSection,
      PermissionAccess.Update,
    );

    let renderElement = <SkeletonModalMedia />;

    if (!isLoading) {
      renderElement = (
        <Grid container direction={'column'} className="flex-wrap-unset container-modal-tnc-detail">
          <Grid container>
            <Grid item xs={12} className="image-header-container">
              <img
                src={tncDetails.image}
                alt="placeholder-state"
                className="placeholder-image-size"
              />
              <div className="button-item-container-tnc">
                <IconButton onClick={this.handleClose}>
                  <i className="icon ic-ffo-close custom-icon-close" />
                </IconButton>
              </div>
            </Grid>
          </Grid>
          <Grid item className="section-body-modal">
            <Grid container spacing={3}>
              <Grid
                item
                lg={12}
                md={12}
                style={{ display: 'flex', flexDirection: 'column', paddingBottom: 0 }}
              >
                <div className="wrapping-container">
                  <h5 style={{ marginBottom: 0 }}>{tncDetails.title}</h5>
                </div>
              </Grid>
              <Grid item lg={10} md={10} style={{ display: 'flex', flexDirection: 'column' }}>
                <div>
                  <label className="text-12 text-gray">
                    {' '}
                    Created by {tncDetails.created_by} &bull;{' '}
                    {CommonHelper.dateTimeParseNewFormat(
                      tncDetails.created_at,
                      'DD MMMM YYYY, h:MM A',
                    )}
                    <br />
                    Last modified by {tncDetails.updated_by} &bull;{' '}
                    {CommonHelper.dateTimeParseNewFormat(
                      tncDetails.updated_at,
                      'DD MMMM YYYY, h:MM A',
                    )}
                  </label>
                </div>
              </Grid>
              <Grid item lg={2} md={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <ButtonMain
                  labelText={'Edit'}
                  type={'primary'}
                  size={'sm'}
                  onClick={this.handleClickEdit}
                  requiredPermission={`${PermissionModule.Marketing}.${PermissionPage.TnCSection}.${PermissionAccess.Update}`}
                />
              </Grid>
            </Grid>
            <Grid container className="pt-24">
              <Grid item xs={3}>
                <label className="text-12 text-gray">Start Date</label>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <i className="icon-slot ic-ffo-calendar" />
                  <label className="text-13 text-bold pl-4">
                    {CommonHelper.dateTimeParseNewFormat(tncDetails.start_date, 'DD MMMM YYYY')}
                  </label>
                </div>
              </Grid>
              <Grid item xs={3}>
                <label className="text-12 text-gray">End Date</label>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <i className="icon-slot ic-ffo-calendar" />
                  <label className="text-13 text-bold pl-4">
                    {CommonHelper.dateTimeParseNewFormat(tncDetails.end_date, 'DD MMMM YYYY')}
                  </label>
                </div>
              </Grid>
              <Grid item xs={2}>
                <label className="text-12 text-gray">TNC ID</label>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <label className="text-13 text-bold">{tncDetails.id}</label>
                </div>
              </Grid>
              <Grid item xs={2}>
                <label className="text-12 text-gray">Promo ID</label>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <label className="text-13 text-bold">
                    {tncDetails.cashback_id ? tncDetails.cashback_id : '-'}
                  </label>
                </div>
              </Grid>
              <Grid item xs={2}>
                <label className="text-12 text-gray">Set status as</label>
                <div>
                  <SelectInputMain
                    options={actionOptions}
                    size="small"
                    defaultValue={actionSelected}
                    onChange={this.handleChangeAction}
                    disabled={!hasEditPermission}
                  />
                </div>
              </Grid>
            </Grid>
            <Grid container className="scroll-container mh-16">
              <Grid item xs={12} className="pt-24 container-scrolling-area">
                {ReactHtmlParser(tncDetails.description)}
              </Grid>
            </Grid>
          </Grid>
          <SnackBarSimple
            open={toastInformation.isOpen}
            durationHide={2000}
            message={toastInformation.message}
            onClickClose={this.handleCloseToash}
            snackbarType={toastInformation.snackbarType}
            anchor={optionToash}
          />
          <ModalAlertInformation
            optionModal={modalAlertOptionInformation}
            isOpen={isOpenModalInformation}
            onCloseModal={this.handleCloseModalInformation}
            typeModal={'information'}
          />
        </Grid>
      );
    }

    return (
      <ModalInformationPopUp
        isOpen={openModal}
        onClose={this.handleClose}
        customElementProps={renderElement}
        size="medium"
      />
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getTncDetails: tncId => getTncSectionDetails(dispatch, tncId),
  updateStatusTnc: params => updateStatusTncSection(dispatch, params),
});

const mapStateToProps = ({ tncData }) => ({
  tncData,
});

ModalTncSectionDetail.propTypes = {
  getTncDetails: PropTypes.func,
  history: PropTypes.object,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onReload: PropTypes.func,
  tncData: PropTypes.object,
  tncId: PropTypes.number,
  updateStatusTnc: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalTncSectionDetail);
