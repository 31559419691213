import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { compose } from 'redux';
// Components
import { HeaderLab } from './components/Index';
import {
  SnackBarSimple,
  AuthenticationAccessPages,
  PrevStateValue,
} from '../../../../components/Index';
// Components View
import { LabPage } from './Index';
// Helper
import { MasterDataHelper, CommonHelper } from '../../../../helpers/Index';

const dataTabs = MasterDataHelper.labStatus;

const optionToast = {
  vertical: 'top',
  horizontal: 'right',
};

class LabMainPage extends React.Component {
  constructor(props) {
    super(props);
    const {
      usersReducer: { prevStateValue },
    } = this.props;
    const tabsValue = (prevStateValue.lab && prevStateValue.lab.activeTab) || 0;
    this.state = {
      tabsValue,
      toastInformation: {
        isOpen: false,
        message: '',
        snackbarType: 'warning',
      },
    };
  }

  handleTabChange = value => {
    const {
      onSetPrevStateValueTabs,
      history: { location },
    } = this.props;

    this.setState({ tabsValue: value }, () => {
      onSetPrevStateValueTabs({ activeTab: value, url: location.pathname });
    });
  };

  handleClickBook = () => {
    const { history } = this.props;
    const param = {};

    const paramFilter = CommonHelper.encryptObject(param);
    history.push(`/medic/lab/book/${paramFilter}`);
  };

  handleCloseToast = () => {
    const { toastInformation } = this.state;
    this.setState({ toastInformation: { ...toastInformation, isOpen: false } });
  };

  renderPages = () => {
    const { tabsValue } = this.state;
    const { history } = this.props;

    return (
      <LabPage history={history} activeTab={dataTabs[tabsValue].value} tabsValue={tabsValue} />
    );
  };

  render() {
    const { toastInformation, tabsValue } = this.state;

    return (
      <div>
        <Helmet title={`FITCO | Labs`} />
        <div>
          <HeaderLab
            data={dataTabs}
            currentTab={tabsValue}
            onChangeTab={this.handleTabChange}
            onClickCreateBook={this.handleClickBook}
          />
          {this.renderPages()}
        </div>
        <SnackBarSimple
          open={toastInformation.isOpen}
          durationHide={2000}
          message={toastInformation.message}
          onClickClose={this.handleCloseToast}
          snackbarType={toastInformation.snackbarType}
          anchor={optionToast}
        />
      </div>
    );
  }
}

const mapDispatchToProps = () => ({});

const mapStateToProps = ({ usersReducer }) => ({ usersReducer });

LabMainPage.propTypes = {
  history: PropTypes.object,
  onSetPrevStateValueTabs: PropTypes.func,
  usersReducer: PropTypes.object,
};

const shell = compose(connect(mapStateToProps, mapDispatchToProps));
const core = compose(AuthenticationAccessPages, PrevStateValue);

export default shell(core(LabMainPage));
