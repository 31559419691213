import React from 'react';
import PropTypes from 'prop-types';
import { Page, Text, View, Document, Image, StyleSheet } from '@react-pdf/renderer';
import _ from 'lodash';
import {
  Table,
  TableHeader,
  TableCell,
  TableBody,
  DataTableCell,
} from '@david.kucsai/react-pdf-table';
// Style
import StylesJs from './CanvasDeliverySlipStyle';
// helper
import { CommonHelper, GeneratorHelper } from '../../../../../helpers/Index';
// assets
import { Images, StyleJs as StyleJsGlobal } from '../../../../../assets/Index';
import Themes from '../../../../../themes/Index';

const styles = StyleSheet.create({
  tableRowHeader: {
    backgroundColor: '#7c858b',
    color: '#FFF',
    padding: 4,
    paddingVertical: 8,
  },
  tableRowBody: {
    padding: 4,
  },
  footer: {
    position: 'absolute',
    fontSize: 12,
    top: 10,
    left: 0,
    right: 0,
    color: 'grey',
  },
});

const widthNo = 0.06;
const widthCell01 = 1;
const widthCell02 = 0.1;

const CanvasDeliverySlip = props => {
  const { data } = props;

  const dataDetails = !_.isEmpty(data.details) ? data.details : {};
  const dataList = !_.isEmpty(data.items) ? GeneratorHelper.generateNumberTable(data.items) : [];
  const dataInfo = !_.isEmpty(data.additional_info) ? data.additional_info : {};
  const dataInfoCompany = !_.isEmpty(dataInfo.company_details) ? dataInfo.company_details : {};
  const dataShipping = !_.isEmpty(data.shipping_details) ? data.shipping_details : {};

  const letterhead = dataInfoCompany.name;
  const letterheadLogo = Images.fitmeLogo;
  const defaultCompany = dataInfoCompany.name;
  const defaultCompanyAddress = dataInfoCompany.address;
  const defaultCompanyPhone = dataInfoCompany.phone;
  const defaultCompanyEmail = dataInfoCompany.email;

  return (
    <Document>
      <Page size="A4">
        <View style={[StyleJsGlobal.gridColumn, StyleJsGlobal.ph24, { width: 596 }]}>
          <View style={[StyleJsGlobal.gridItem12, StyleJsGlobal.mb20, StyleJsGlobal.pv24]} fixed>
            <View style={[StyleJsGlobal.gridRow, StyleJsGlobal.justifyContentSpaceBetween]}>
              <View style={[StyleJsGlobal.gridItem9]}>
                <Text style={[StyleJsGlobal.text24]}>{letterhead.toUpperCase()}</Text>
              </View>
              <View style={[StyleJsGlobal.gridItem3]}>
                <Image src={letterheadLogo} style={[StylesJs.containerLogo]} />
              </View>
            </View>
          </View>
          <View style={[StyleJsGlobal.gridItem12, StyleJsGlobal.mb12]}>
            <View style={[StyleJsGlobal.gridRow, StyleJsGlobal.justifyContentSpaceBetween]}>
              <View style={[StyleJsGlobal.gridItem6]}>
                <Text style={[StyleJsGlobal.text12]}>{defaultCompany.toUpperCase()}</Text>
                <Text style={[StyleJsGlobal.text12]}>{defaultCompanyAddress}</Text>
                <Text style={[StyleJsGlobal.text12]}>{`Phone : ${defaultCompanyPhone}`}</Text>
                <Text style={[StyleJsGlobal.text12]}>{`Email : ${defaultCompanyEmail}`}</Text>
              </View>
              <View style={[StyleJsGlobal.gridItem5, { flex: 1 }]}>
                <View style={[StyleJsGlobal.flexColumn]}>
                  <View style={[StyleJsGlobal.gridRow]}>
                    <View style={[StyleJsGlobal.gridItem4, { paddingRight: 10, flex: 1 }]}>
                      <Text style={[StyleJsGlobal.text10]}>DELIVERY#</Text>
                    </View>
                    <View style={[StyleJsGlobal.gridItem8, { flex: 1 }]}>
                      <Text style={[StyleJsGlobal.text10, StyleJsGlobal.textRight]}>
                        {dataDetails.purchase_order_no}
                      </Text>
                    </View>
                  </View>
                  <View style={[StyleJsGlobal.gridRow]}>
                    <View style={[StyleJsGlobal.gridItem4, { paddingRight: 10, flex: 1 }]}>
                      <Text style={[StyleJsGlobal.text10]}>DATE</Text>
                    </View>
                    <View style={[StyleJsGlobal.gridItem8]}>
                      <Text style={[StyleJsGlobal.text10, StyleJsGlobal.textRight]}>
                        {CommonHelper.dateTimeParseNewFormat(
                          dataShipping.shipping_date,
                          'DD/MM/YYYY',
                        )}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
          <View style={[StyleJsGlobal.gridItem12, StyleJsGlobal.mb12]}>
            <View
              style={[
                StyleJsGlobal.gridRow,
                StyleJsGlobal.justifyContentSpaceBetween,
                { alignItems: 'center' },
              ]}
            >
              <View
                style={[
                  StyleJsGlobal.gridItem4,
                  StyleJsGlobal.dividerHr,
                  { flex: 1, borderColor: Themes.palette.mineShaft },
                ]}
              />
              <View style={[StyleJsGlobal.gridItem6]}>
                <Text style={[StyleJsGlobal.text24]}>{`DELIVERY SLIP`}</Text>
              </View>
              <View
                style={[
                  StyleJsGlobal.gridItem4,
                  StyleJsGlobal.dividerHr,
                  { flex: 1, borderColor: Themes.palette.mineShaft },
                ]}
              />
            </View>
          </View>
          <View style={[StyleJsGlobal.gridItem12, StyleJsGlobal.mb4]}>
            <Text style={[StyleJsGlobal.text12]}>CUSTOMER</Text>
          </View>
          <View style={[StyleJsGlobal.gridItem12, StyleJsGlobal.mb4]}>
            <View style={[StyleJsGlobal.gridRow, StyleJsGlobal.justifyContentSpaceBetween]}>
              <View style={[StyleJsGlobal.gridItem7, { flex: 1 }]}>
                <View style={[StyleJsGlobal.gridColumn, StyleJsGlobal.tableBorder]}>
                  <View style={[StyleJsGlobal.gridItem12]}>
                    <View style={[StyleJsGlobal.gridRow]}>
                      <View style={[StyleJsGlobal.gridItem3, { marginRight: 20, flex: 1 }]}>
                        <Text style={[StyleJsGlobal.text12]}>NAME</Text>
                      </View>
                      <View style={[StyleJsGlobal.gridItem8]}>
                        <Text style={[StyleJsGlobal.text12]}>{dataDetails.company_name}</Text>
                      </View>
                    </View>
                  </View>
                  <View style={[StyleJsGlobal.gridItem12]}>
                    <View style={[StyleJsGlobal.gridRow]}>
                      <View style={[StyleJsGlobal.gridItem3, { marginRight: 20, flex: 1 }]}>
                        <Text style={[StyleJsGlobal.text12]}>ADDRESS</Text>
                      </View>
                      <View style={[StyleJsGlobal.gridItem8]}>
                        <Text style={[StyleJsGlobal.text12]}>{dataShipping.shipping_address}</Text>
                      </View>
                    </View>
                  </View>
                  <View style={[StyleJsGlobal.gridItem12, { flex: 1 }]}>
                    <View style={[StyleJsGlobal.gridRow]}>
                      <View style={[StyleJsGlobal.gridItem3, { marginRight: 20, flex: 1 }]}>
                        <Text style={[StyleJsGlobal.text12]}>NOTE</Text>
                      </View>
                      <View style={[StyleJsGlobal.gridItem8]}>
                        <Text style={[StyleJsGlobal.text12]}>{dataShipping.shipping_notes}</Text>
                      </View>
                    </View>
                  </View>
                  <View style={[StyleJsGlobal.gridItem12]}>
                    <View style={[StyleJsGlobal.gridRow]}>
                      <View style={[StyleJsGlobal.gridItem3, { marginRight: 20, flex: 1 }]}>
                        <Text style={[StyleJsGlobal.text12]}>PIC PHONE</Text>
                      </View>
                      <View style={[StyleJsGlobal.gridItem8]}>
                        <Text style={[StyleJsGlobal.text12]}>{dataDetails.phone}</Text>
                      </View>
                    </View>
                  </View>
                  <View style={[StyleJsGlobal.gridItem12]}>
                    <View style={[StyleJsGlobal.gridRow]}>
                      <View style={[StyleJsGlobal.gridItem3, { marginRight: 20, flex: 1 }]}>
                        <Text style={[StyleJsGlobal.text12]}>PIC EMAIL</Text>
                      </View>
                      <View style={[StyleJsGlobal.gridItem8]}>
                        <Text style={[StyleJsGlobal.text12]}>{dataDetails.email}</Text>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
              <View style={[StyleJsGlobal.gridItem4, StyleJsGlobal.tableBorder]}>
                <View style={[StyleJsGlobal.flexColumn]}>
                  <Text style={[StyleJsGlobal.text12, StyleJsGlobal.mb4]}>ORDER#</Text>
                  <Text style={[StyleJsGlobal.text12, StyleJsGlobal.mb8]}>
                    {dataDetails.purchase_order_no}
                  </Text>
                  <Text style={[StyleJsGlobal.text12, StyleJsGlobal.mb4]}>DATE </Text>
                  <Text style={[StyleJsGlobal.text12]}>
                    {CommonHelper.dateTimeParseNewFormat(dataShipping.shipping_date, 'DD/MM/YYYY')}
                  </Text>
                </View>
              </View>
            </View>
          </View>
          <View style={[StyleJsGlobal.gridItem12, StyleJsGlobal.mb20]}>
            <Table data={dataList}>
              <TableHeader>
                <TableCell
                  weighting={widthNo}
                  style={[StyleJsGlobal.text10, StyleJsGlobal.textCenter, styles.tableRowHeader]}
                >
                  No
                </TableCell>
                <TableCell
                  weighting={widthCell01}
                  style={[StyleJsGlobal.text10, StyleJsGlobal.textCenter, styles.tableRowHeader]}
                >
                  Product Name
                </TableCell>
                <TableCell
                  weighting={widthCell02}
                  style={[StyleJsGlobal.text10, StyleJsGlobal.textCenter, styles.tableRowHeader]}
                >
                  QTY
                </TableCell>
              </TableHeader>
              <TableBody>
                <DataTableCell
                  weighting={widthNo}
                  style={[StyleJsGlobal.text10, StyleJsGlobal.textCenter, styles.tableRowBody]}
                  getContent={r => r.no}
                />
                <DataTableCell
                  weighting={widthCell01}
                  style={[StyleJsGlobal.text10, styles.tableRowBody]}
                  getContent={r => r.product_name}
                />
                <DataTableCell
                  weighting={widthCell02}
                  style={[StyleJsGlobal.text10, StyleJsGlobal.textCenter, styles.tableRowBody]}
                  getContent={r => `${r.quantity || '-'} Pcs`}
                />
              </TableBody>
            </Table>
          </View>
          <View style={[StyleJsGlobal.gridItem12, StyleJsGlobal.mb32]} wrap={false}>
            <View style={[StyleJsGlobal.gridRow, StyleJsGlobal.justifyContentFlexStart]}>
              <View style={[StyleJsGlobal.gridItem7, { flex: 1 }]}>
                <View style={[StyleJsGlobal.gridColumn]}>
                  <View
                    style={[StyleJsGlobal.gridItem12, StyleJsGlobal.tableBorder, StyleJsGlobal.mb8]}
                  >
                    <View style={[StyleJsGlobal.gridColumn]}>
                      <View style={[StyleJsGlobal.gridItem12, { flex: 1 }]}>
                        <Text style={[StyleJsGlobal.text10]}>MESSAGE</Text>
                      </View>
                      <View style={[StyleJsGlobal.gridItem12, { flex: 1 }]}>
                        <Text style={[StyleJsGlobal.text10]}>{`${dataInfo.message || '-'}`}</Text>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
          <View style={[StyleJsGlobal.gridItem12]} wrap={false}>
            <View style={[StyleJsGlobal.gridRow, StyleJsGlobal.justifyContentFlexEnd]}>
              <View
                style={[
                  StyleJsGlobal.gridItem4,
                  StyleJsGlobal.dividerHr,
                  StyleJsGlobal.ph19,
                  { flex: 1, borderColor: Themes.palette.mineShaft },
                ]}
              />
            </View>
          </View>
        </View>
        <View style={[styles.footer]}>
          <View
            style={[
              StyleJsGlobal.gridRow,
              StyleJsGlobal.justifyContentSpaceBetween,
              { width: 600 },
            ]}
          >
            <View style={[StyleJsGlobal.gridItem4, { flex: 1, paddingLeft: 24 }]}>
              <Text style={[StyleJsGlobal.text10]} fixed>
                {`Sales Delivery ${dataDetails.invoice_no}`}
              </Text>
            </View>
            <View style={[StyleJsGlobal.gridItem4, { flex: 1, paddingRight: 24 }]}>
              <Text
                style={[StyleJsGlobal.text10, StyleJsGlobal.textRight]}
                render={({ pageNumber, totalPages }) =>
                  `Page ${pageNumber} of ${totalPages || '1'}`
                }
                fixed
              />
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

CanvasDeliverySlip.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default CanvasDeliverySlip;
