/* eslint-disable no-case-declarations */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
// api
import { userTokenValidation } from '../../services/api/UsersApi';
import * as Actions from '../../redux/actions/UsersAction';
// helper
import { PermissionHelper, HttpStatusCode, UserHelper } from '../../helpers/Index';

const AuthenticationAccessPages = WrappedComponent => {
  class NewComponent extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        tabPermissionAccess: [],
        // isGrantedAccess: false,
      };
    }

    componentDidMount() {
      this.userTokenValidation();
      this.getUserLocation();
    }

    getUserLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(this.showPosition);
      }
    };

    showPosition = position => {
      const { userLocationCoordinate } = this.props;
      userLocationCoordinate(position);
    };

    checkUserAccessPermission = (module, page, requiredPermission) => {
      const { history } = this.props;
      const hasAccess = PermissionHelper.checkUserHasPermission(module, page, requiredPermission);

      if (!hasAccess && history !== undefined) {
        const redirectPath = PermissionHelper.getUnauthorizedRedirectPath();
        if (redirectPath === '/sign-in') {
          localStorage.clear();
          history.index = 0;
        }

        history.push(redirectPath);
      }
    };

    checkUserAccessPermissionAction = (module, page, requiredPermission) => {
      const hasAccess = PermissionHelper.checkUserHasPermission(module, page, requiredPermission);

      return hasAccess;
    };

    authenticationUser(httpCode = HttpStatusCode.Success) {
      const { history } = this.props;
      const currentUsers = UserHelper.getCurrentUserInformation();
      let tabAccess = null;

      const validToken =
        currentUsers && currentUsers.token.access_token && httpCode !== HttpStatusCode.Unauthorized;
      if (!validToken) {
        localStorage.clear();
        history.index = 0;
        history.push('/sign-in');
      }

      switch (history.location.pathname) {
        case '/move/workout-schedule':
        case '/marketing/campaign':
        case '/marketing/segment':
        case '/orders':
        case '/order/shipment':
        case '/order/handover':
        case '/order/summary':
        case '/order/purchase-order':
          tabAccess = PermissionHelper.generatePermissionPurchaseOrder(history.location.pathname);
          this.setState({ tabPermissionAccess: tabAccess });
          break;
        case '/reports/daily-check-in':
        case '/reports/conduct-pt':
        case '/master-data/main-data':
        case '/master-data/product-category':
        case '/management/users':
        case '/management/roles-permissions':
        case '/marketing/popup-screen':
        case '/marketing/tnc-section':
        case '/marketing/inbox':
        case '/master-data/product-wholesale':
        case '/membership/purchase':
        case '/marketing/feed':
          const checkAccess = PermissionHelper.checkAuthorizeAccessUrl(history.location.pathname);
          if (!checkAccess) {
            history.push(currentUsers.callBackURL || '/');
          }
          break;
        case '/membership/request':
          tabAccess = PermissionHelper.generateTabPermissionMembershipRequest(
            history.location.pathname,
          );
          this.setState({ tabPermissionAccess: tabAccess });
          break;
        case '/sign-in':
          const defaultUrl = !_.isEmpty(currentUsers)
            ? currentUsers.callBackURL
            : history.location.pathname;
          history.push(defaultUrl);
          break;
        default:
          break;
      }
    }

    userTokenValidation() {
      const { userToken, history } = this.props;
      const currentPageAccess = UserHelper.getUserPagesAccess();
      const currentUsers = UserHelper.getCurrentUserInformation();

      userToken()
        .then(async response => {
          const { code, data } = response;
          const isEqualPageAccess = await _.isEqual(data.page_access, currentPageAccess);
          if (isEqualPageAccess) {
            this.authenticationUser(code);
          } else {
            history.push(currentUsers.callBackURL || '/');
          }
        })
        .catch(error => {
          const { status } = error;
          this.authenticationUser(status);
        });
    }

    render() {
      const { tabPermissionAccess } = this.state;
      return (
        <WrappedComponent
          tabPermissionAccess={tabPermissionAccess}
          checkUserAccessPermission={this.checkUserAccessPermission}
          checkUserAccessPermissionAction={this.checkUserAccessPermissionAction}
          {...this.props}
        />
      );
    }
  }

  const mapDispatchToProps = dispatch => ({
    userToken: () => userTokenValidation(dispatch),
    userLocationCoordinate: params => dispatch(Actions.setUserLocationCoordinate(params)),
  });

  const mapStateToProps = ({ usersReducer }) => ({ usersReducer });

  NewComponent.propTypes = {
    history: PropTypes.object,
    userLocationCoordinate: PropTypes.func,
    userToken: PropTypes.func,
  };

  return connect(mapStateToProps, mapDispatchToProps)(NewComponent);
};

export default AuthenticationAccessPages;
