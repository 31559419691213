import React from 'react';
import { connect } from 'react-redux';
import { Grid, FormControl, FormLabel, Fade } from '@material-ui/core';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { compose } from 'redux';
// import clsx from 'clsx';
// component
import {
  TextInput,
  ButtonMain,
  PrevNextStepperCount,
  SelectInputMain,
  GridRowTableOneColumn,
  RadioInputImage,
  LabelAlert,
} from '../../../../../../../components/Index';
import { CartItemMembership } from './components/Index';
import { CardMembershipLabel } from '../components/Index';
// style
import './StepMembershipPurchaseSummaryAndPaymentStyle.scss';
import {
  CommonHelper,
  MasterDataHelper,
  // OrderHelper,
  HttpStatusCode,
  paymentMethodOptionCode,
  // pointBoosterActionOptionCode,
  OrderHelper,
  productTypeForOrderCode,
  UserHelper,
  promoTypeCode,
} from '../../../../../../../helpers/Index';
// api
import { checkPromoCode } from '../../../../../../../services/api/OrdersApi';

const bankTransferOption = MasterDataHelper.bankTransferOption;
const currentUsers = UserHelper.getCurrentUserInformation();

const initialValidation = {
  promoCode: { isError: false, errorMessage: '' },
};

const initialForm = {
  paymentMethod: '',
  bank: bankTransferOption[0],
  paymentTerm: undefined,
  promoCode: '',
};

const timeInterval = 300;

class StepMembershipPurchaseSummaryAndPayment extends React.Component {
  constructor(props) {
    super(props);

    const updateExitingForm = {
      ...CommonHelper.objectCloning(initialForm),
      paymentMethod: currentUsers && currentUsers.isResellerLogin ? 'bank_transfer' : null,
    };

    this.state = {
      form: updateExitingForm,
      validation: CommonHelper.objectCloning(initialValidation),
      isSuccess: '',
      isValidPromoCode: false,
      disablePromoCode: false,
      paymentTermOption: [],
      paymentTypeOption: [],
    };
    this.searchPromoCodeDebounce = _.debounce(this.searchPromoCodeDebounce, 400);
  }

  componentDidMount() {
    const { parentForm, parentValidation } = this.props;
    const { form, validation } = this.state;
    let modifyPaymentTypeOption = MasterDataHelper.paymentMethodOption.filter(
      item =>
        item.value === paymentMethodOptionCode.bank_transfer ||
        item.value === paymentMethodOptionCode.credit_card ||
        item.value === paymentMethodOptionCode.fit_point ||
        item.value === paymentMethodOptionCode.cash ||
        item.value === paymentMethodOptionCode.corporate_deal
    );

    const modifyPaymentTermOption = OrderHelper.specialCaseIdPaymentTerm(
      parentForm.selectedMembershipDetails,
    );

    const modifyDefaultTerm = modifyPaymentTermOption.filter(
      item => item.value === parentForm.paymentTerm,
    );

    if (!parentForm.isExisting) {
      modifyPaymentTypeOption = MasterDataHelper.paymentMethodOption.filter(
        item =>
          item.value === paymentMethodOptionCode.bank_transfer ||
          item.value === paymentMethodOptionCode.credit_card || 
          item.value === paymentMethodOptionCode.cash || 
          item.value === paymentMethodOptionCode.corporate_deal
      );
    }

    this.setState(
      {
        form: {
          ...form,
          ...parentForm,
          paymentTerm: !_.isEmpty(modifyDefaultTerm)
            ? modifyDefaultTerm[0].value
            : modifyPaymentTermOption[0].value,
        },
        validation: { ...validation, ...parentValidation },
        paymentTermOption: modifyPaymentTermOption,
        paymentTypeOption: modifyPaymentTypeOption,
      },
      () => {
        if (!parentForm.paymentMethod) {
          this.handleSelectPaymentMethod(paymentMethodOptionCode.bank_transfer);
        } else if (!_.isEmpty(parentForm.promoCode)) {
          this.searchPromoCodeDebounce();
        }
      },
    );
  }

  componentWillReceiveProps(nextProps) {
    const { validation } = this.state;
    const isValidationChange = _.isEqual(validation, nextProps.parentValidation);

    if (!isValidationChange) {
      const finalMergeValidation = { ...validation, ...nextProps.parentValidation };
      this.setState({
        validation: finalMergeValidation,
      });
    }
  }

  searchPromoCodeDebounce = () => {
    const { checkPromo } = this.props;
    const { validation, form } = this.state;
    const params = this.getPayloadPromoCode();
    const message = CommonHelper.objectCloning(initialValidation.promoCode);

    if (!_.isEmpty(form.promoCode)) {
      checkPromo(params)
        .then(() => {
          message.errorMessage = 'Promo Code Applied';
          this.setState({
            isValidPromoCode: true,
            isSuccess: 'success',
            validation: {
              ...validation,
              promoCode: message,
            },
          });
        })
        .catch(e => {
          if (e.status === HttpStatusCode.NotFound) {
            message.isError = true;
            message.errorMessage = `${e.data.messages}, you will get no benefit if Process this transaction`;

            this.setState({
              validation: {
                ...validation,
                promoCode: message,
              },
              isValidPromoCode: false,
              isSuccess: 'error',
            });
          }
        });
    } else {
      this.setState({
        isValidPromoCode: false,
        isSuccess: '',
        validation: {
          ...validation,
          promoCode: { isError: false, errorMessage: '' },
        },
      });
    }
  };

  getPayloadPromoCode = () => {
    const {
      form: { paymentTerm, paymentMethod, promoCode, userId, items, startDate },
    } = this.state;
    const itemList = [];

    items.forEach(item => {
      itemList.push({
        product_id: item.product_id,
        qty: paymentTerm,
        details: {
          start_date: startDate,
        },
      });
    });

    const payload = {
      data: {
        user_id: userId,
        promo_code: promoCode,
        payment_type: paymentMethod,
        items: !_.isEmpty(itemList) ? itemList : null,
      },
    };
    return payload;
  };

  handleSelectPaymentMethod = value => {
    const { form } = this.state;
    const modifyPromoCode = CommonHelper.objectCloning(form.promoCode);
    const modifyDisablePromoCode = false;

    // if (
    //   value === paymentMethodOptionCode.credit_card ||
    //   form.typeActionBooster === pointBoosterActionOptionCode.renew
    // ) {
    //   modifyPromoCode = '';
    //   modifyDisablePromoCode = true;
    // }

    this.setState(
      {
        form: { ...form, paymentMethod: value },
        disablePromoCode: modifyDisablePromoCode,
      },
      () => {
        if (value === paymentMethodOptionCode.bank_transfer) {
          const valueChange = bankTransferOption[0];
          this.handleChangeBankTransfer(valueChange);
        } else if (value === paymentMethodOptionCode.credit_card) {
          this.handleChangePromoCodeEntry(modifyPromoCode);
        }
      },
    );
  };

  handleChangeBankTransfer = value => {
    const { form } = this.state;

    this.setState({ form: { ...form, bank: value } });
  };

  handleChangePaymentTerm = value => {
    const { form } = this.state;

    this.setState({ form: { ...form, paymentTerm: value } }, () => {
      const valueChange = form.promoCode || '';
      this.handleChangePromoCodeEntry(valueChange);
    });
  };

  handleChangePromoCodeEntry = value => {
    const { form } = this.state;
    this.setState(
      {
        form: { ...form, promoCode: value },
      },
      () => {
        this.searchPromoCodeDebounce();
      },
    );
  };

  handlePrevClick = async () => {
    const { onPrevClick, onParameterUpdate } = this.props;
    const { form, validation } = this.state;
    const formNewValue = CommonHelper.objectCloning(form);
    const validationNewValue = CommonHelper.objectCloning(validation);
    formNewValue.paymentMethod = null;
    formNewValue.promoCode = '';

    await onParameterUpdate(formNewValue, validationNewValue);
    await onPrevClick();
  };

  handleSubmitClick = async () => {
    const { onButtonClickSubmit, onParameterUpdate } = this.props;
    const { form, validation } = this.state;
    const formNewValue = CommonHelper.objectCloning(form);
    const validationNewValue = CommonHelper.objectCloning(validation);

    await onParameterUpdate(formNewValue, validationNewValue);
    await onButtonClickSubmit();
  };

  renderCartItem() {
    const { form, isValidPromoCode } = this.state;
    const { orderData } = this.props;

    return (
      <CartItemMembership form={form} orderData={orderData} isValidPromoCode={isValidPromoCode} />
    );
  }

  renderMembershipCard() {
    const { form } = this.state;
    let renderElement = null;

    if (!_.isEmpty(form.selectedMembershipDetails)) {
      renderElement = <CardMembershipLabel form={form} />;
    }

    return renderElement;
  }

  renderPaymentTypeAction() {
    const { membership } = this.props;
    const {
      form: { selectedMembershipDetails },
    } = this.state;
    const disableContent = membership.fetching;
    let renderElement = null;

    if (this.state.form.paymentMethod === paymentMethodOptionCode.bank_transfer) {
      renderElement = (
        <Grid item lg={6} md={6} className="mt-27">
          <FormControl component="fieldset" fullWidth margin="normal">
            <RadioInputImage
              data={bankTransferOption}
              onSelect={this.handleChangeBankTransfer}
              direction="column"
              currentValue={this.state.form.bank}
              disabled={disableContent}
              imageWidth="60px"
            />
          </FormControl>
        </Grid>
      );
    } else if (this.state.form.paymentMethod === paymentMethodOptionCode.credit_card) {
      if (
        selectedMembershipDetails.option.is_recurring &&
        !selectedMembershipDetails.option.one_time_purchase_only
      ) {
        renderElement = (
          <Grid item lg={6} md={6} className="mt-45">
            <LabelAlert message="Your Payment will auto Recurring" type="warning" size="middle" />
          </Grid>
        );
      }
    }

    return renderElement;
  }

  render() {
    const {
      validation,
      form,
      isValidPromoCode,
      isSuccess,
      disablePromoCode,
      paymentTermOption,
      paymentTypeOption,
    } = this.state;
    const { onButtonClickCancel, orderData, membership } = this.props;

    const name =
      form.firstName || form.lastName ? `${form.firstName} ${form.lastName}`.trim() : '-';
    const gender = !_.isEmpty(form.gender) ? form.gender.name : '-';
    const orderType = CommonHelper.renameRemoveCharacter(
      productTypeForOrderCode.merchantMembership,
      '_',
    );

    const successIcon = 'ic-ffi-check-mark';
    const promoDesc = orderData.promoCodeDetails.cashback_amount_desc;
    const potentialPromo =
      orderData.promoCodeDetails.promo_code_cashback_type_id === promoTypeCode.Discount
        ? `Potential Discount${promoDesc}`
        : `Potential Cashback${promoDesc}`;

    const disableContent = membership.fetching;
    const isDisablePromoCode = orderData.fetching || disablePromoCode;

    return (
      <Grid
        container
        direction="column"
        className="container-step-membership-summary-payment flex-wrap-unset"
      >
        <Grid item lg={12} md={12}>
          <Grid container spacing={2}>
            <Grid item lg={6} md={6} className="mt-24">
              <FormControl component="fieldset" fullWidth margin="normal">
                <FormLabel component="label" className="text-12 filed-title">
                  Customer Details
                </FormLabel>
                <Grid
                  container
                  direction="column"
                  className="container-row-table details flex-wrap-unset"
                >
                  <GridRowTableOneColumn
                    customElementInitial={<label className="text-14 title">Name</label>}
                    customElementColumn={<label className="text-14">{name}</label>}
                    columnLeft={5}
                    columnRight={7}
                  />
                  <GridRowTableOneColumn
                    customElementInitial={<label className="text-14 title">Email</label>}
                    customElementColumn={<label className="text-14">{form.email || '-'}</label>}
                    columnLeft={5}
                    columnRight={7}
                  />
                  <GridRowTableOneColumn
                    customElementInitial={<label className="text-14 title">Phone Number</label>}
                    customElementColumn={<label className="text-14">{form.phone || '-'}</label>}
                    columnLeft={5}
                    columnRight={7}
                  />
                  <GridRowTableOneColumn
                    customElementInitial={<label className="text-14 title">Gender</label>}
                    customElementColumn={<label className="text-14">{gender}</label>}
                    columnLeft={5}
                    columnRight={7}
                  />
                  <GridRowTableOneColumn
                    customElementInitial={<label className="text-14 title">Order Type</label>}
                    customElementColumn={<label className="text-14">{orderType}</label>}
                    columnLeft={5}
                    columnRight={7}
                  />
                </Grid>
              </FormControl>
            </Grid>
            <Grid item lg={6} md={6} className="mt-24 mb-26">
              {this.renderMembershipCard()}
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={12} md={12}>
          <Grid container spacing={2}>
            <Grid item lg={6} md={6}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <FormLabel component="label" className="text-12 filed-title">
                  Payment Type
                </FormLabel>
                <SelectInputMain
                  options={paymentTypeOption}
                  currentValue={
                    this.state.form.paymentMethod || paymentMethodOptionCode.bank_transfer
                  }
                  onChange={this.handleSelectPaymentMethod}
                  disabled={disableContent}
                />
              </FormControl>
            </Grid>
            {this.renderPaymentTypeAction()}
          </Grid>
        </Grid>
        <Grid item lg={12} md={12}>
          <Grid container spacing={2}>
            <Grid item lg={6} md={6}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <FormLabel component="label" className="text-12 filed-title">
                  Payment Term
                </FormLabel>
                <div className="container-remove-margin">
                  <SelectInputMain
                    options={paymentTermOption}
                    currentValue={form.paymentTerm}
                    onChange={this.handleChangePaymentTerm}
                    disabled={disableContent}
                  />
                </div>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={12} md={12}>
          <Grid container spacing={2}>
            <Grid item lg={6} md={6}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <FormLabel component="label" className="text-12 filed-title">
                  Promo Code
                </FormLabel>
                <div className={`container-promo ${isSuccess}`}>
                  <TextInput
                    placeHolderText="Promo Code"
                    size="md"
                    onChange={this.handleChangePromoCodeEntry}
                    currentValue={this.state.form.promoCode}
                    errorMessage={validation.promoCode.errorMessage}
                    isError={validation.promoCode.isError}
                    iconPrefixEnd={isValidPromoCode ? successIcon : ''}
                    isDisable={isDisablePromoCode || disableContent}
                  />
                </div>
              </FormControl>
            </Grid>
            <Fade in={isSuccess === 'success'} timeout={timeInterval}>
              <Grid item lg={6} md={6} className="mt-43">
                <LabelAlert message={potentialPromo} type="success" size="middle" />
              </Grid>
            </Fade>
          </Grid>
        </Grid>
        <Grid item lg={12} md={12}>
          {!_.isEmpty(form.items) && this.renderCartItem()}
        </Grid>
        <Grid item lg={12} md={12}>
          <Grid container justify="flex-end">
            <Grid item lg={5} md={6}>
              <Grid container justify="space-around">
                <Grid item lg={4} md={4} className="pl-8">
                  <ButtonMain
                    type="negative"
                    size="xl"
                    labelText="Cancel"
                    isLoading={disableContent}
                    onClick={onButtonClickCancel}
                  />
                </Grid>
                <Grid item lg={4} md={4} className="pl-8">
                  <ButtonMain
                    type="ghost"
                    size="xl"
                    labelText="Prev"
                    isLoading={disableContent}
                    onClick={this.handlePrevClick}
                  />
                </Grid>
                <Grid item lg={4} md={4} className="pl-8">
                  <ButtonMain
                    type="primary"
                    size="xl"
                    labelText="Submit"
                    isLoading={disableContent}
                    onClick={this.handleSubmitClick}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  checkPromo: params => checkPromoCode(dispatch, params),
});

const mapStateToProps = ({ orderData, membership }) => ({ orderData, membership });

StepMembershipPurchaseSummaryAndPayment.propTypes = {
  checkPromo: PropTypes.func,
  membership: PropTypes.object,
  onButtonClickCancel: PropTypes.func,
  onButtonClickSubmit: PropTypes.func,
  onParameterUpdate: PropTypes.func,
  onPrevClick: PropTypes.func,
  orderData: PropTypes.object,
  parentForm: PropTypes.object,
  parentValidation: PropTypes.object,
};

const shell = compose(connect(mapStateToProps, mapDispatchToProps));

export default shell(PrevNextStepperCount(StepMembershipPurchaseSummaryAndPayment));
