/* eslint-disable react/no-access-state-in-setstate */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Grid } from '@material-ui/core';
import _ from 'lodash';
import { List } from 'antd';
// Style
import './TncSectionPageStyle.scss';
// asset
import { Images } from '../../../../assets/Index';
// component
import {
  SnackBarSimple,
  ButtonMain,
  LabelStatusMain,
  TextInput,
  EmptyState,
  AuthenticationAccessPages,
  PaginationList,
  CardGalleryContent,
  SkeletonGrid,
} from '../../../../components/Index';
import { ModalTncSectionDetail } from '../components/Index';
// Action
import { getTncSectionList } from '../../../../services/api/TncSection';
// Helper
import { CommonHelper, PermissionAccess, PermissionModule, PermissionPage } from '../../../../helpers/Index';
import { compose } from 'redux';

const optionToash = {
  vertical: 'top',
  horizontal: 'right',
};

const paginationGridOption = {
  gutter: 16,
  column: 3,
};
const initialFilter = {
  search: '',
};

class TncSectionPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: CommonHelper.objectCloning(initialFilter),
      page: 1,
      limit: 9,
      pagination: {
        showSizeChanger: true,
        showTotal: (total, range) => `Showing ${range[0]}-${range[1]} From ${total}`,
        pageSizeOptions: ['9', '18', '27', '36'],
      },
      isLoading: true,
      selectedModalItemId: null,
      isOpenModal: false,
      toastInformation: {
        isOpen: false,
        message: '',
        snackbarType: 'warning',
      },
    };
    this.searchDebounce = _.debounce(this.searchDebounce, 400);
  }

  componentDidMount() {
    this.getTncSectionList();
  }

  handleCloseToash = () => {
    const { toastInformation } = this.state;
    this.setState({ toastInformation: { ...toastInformation, isOpen: false } });
  };

  handleChangeSearch = value => {
    const { filter } = this.state;

    this.setState(
      {
        page: 1,
        filter: { ...filter, search: value },
        pagination: { ...this.state.pagination, current: 1 },
      },
      () => {
        this.searchDebounce();
      },
    );
  };

  searchDebounce = () => {
    this.getTncSectionList();
  };

  getTncSectionList = () => {
    const { tncSectionList } = this.props;
    const {
      limit,
      pagination,
      page,
      filter: { search },
    } = this.state;

    const params = {
      search,
      page,
      limit,
    };

    tncSectionList(params).then(response => {
      this.setState({
        isLoading: false,
        pagination: { ...pagination, pageSize: limit, total: response.total },
      });
    });
  };

  handleButtonClickAdd = () => {
    const { history } = this.props;
    history.push('/marketing/tnc-section/add');
  };

  handleCardClick = item => {
    if (item.id) {
      this.setState({ isOpenModal: true, selectedModalItemId: item.id });
    }
  };

  handleReload = () => {
    this.getTncSectionList();
  };

  generateStatus = status => {
    switch (status) {
      case 0:
        return (
          <div className="pt-8">
            <LabelStatusMain value={'Inactive'} type="default" />
          </div>
        );
      case -1:
        return (
          <div className="pt-8">
            <LabelStatusMain value={'Deleted'} type="danger" />
          </div>
        );
      default:
        return null;
    }
  };

  handlePaginationPageChange = value => {
    const { pagination } = this.state;
    this.setState(
      {
        page: value,
        pagination: {
          ...pagination,
          current: value,
        },
      },
      () => {
        this.getTncSectionList();
      },
    );
  };

  handlePaginationPageSizeChange = value => {
    const { pagination } = this.state;
    this.setState(
      {
        limit: value,
        page: 1,
        pagination: { ...pagination, pageSize: value },
      },
      () => {
        this.getTncSectionList();
      },
    );
  };

  handleTncModalClose = value => {
    this.setState({ isOpenModal: value }, () => {
      //
    });
  };

  generateTncCard = () => {
    const { pagination } = this.state;
    const {
      tncData: { tncList, fetching },
    } = this.props;
    let renderElement = (
      <Grid container justify={'center'}>
        <EmptyState source={Images.emptyStatePlaceholder} labelText={'There is no TNC'} />
      </Grid>
    );

    if (!_.isEmpty(tncList)) {
      renderElement = (
        <PaginationList
          data={tncList}
          grid={paginationGridOption}
          pagination={pagination}
          onChange={this.handlePaginationPageChange}
          onShowSizeChange={this.handlePaginationPageSizeChange}
          loading={fetching}
          renderItem={item => {
            const resultDescription = CommonHelper.removeHTMLTag(item.description);

            return (
              <List.Item>
                <CardGalleryContent
                  onClick={() => this.handleCardClick(item)}
                  title={item.title}
                  body={resultDescription}
                  customElementsHeader={
                    <div className="flex-column">
                      <label className="text-12 text-gray center pt-8">
                        Created by {item.created_by}
                      </label>
                      {this.generateStatus(item.status)}
                      <div className="flex-column pt-16">
                        <label className="text-12 text-gray center">Start &amp; end date </label>
                        <div className="flex-row pt-4">
                          <i className="container-icon-prefix ic-ffo-calendar size-14" />
                          <label className="text-13 center pl-4 date-text">
                            {`${CommonHelper.dateTimeParseNewFormat(
                              item.start_date,
                              'DD MMMM YYYY',
                            )} - ${CommonHelper.dateTimeParseNewFormat(
                              item.end_date,
                              'DD MMMM YYYY',
                            )}`}
                          </label>
                        </div>
                      </div>
                    </div>
                  }
                />
              </List.Item>
            );
          }}
        />
      );
    }
    return renderElement;
  };

  renderFilter() {
    const { query } = this.state;

    return (
      <Grid container justify="flex-start">
        <Grid item lg={4} md={4} className="container-filter">
          <TextInput
            iconPrefix="ic-ffo-search"
            placeHolderText={'Search TNC Name'}
            onChange={this.handleChangeSearch}
            defaultValue={query}
            size="md"
          />
        </Grid>
        <Grid item lg={3} md={3} className="container-filter" />
        <Grid item lg md>
          <Grid container justify="flex-end" alignItems="center">
            <Grid item className="container-header-button">
              <ButtonMain
                labelText="Create Terms &amp; Conditions"
                onClick={this.handleButtonClickAdd}
                type="primary"
                startIcon="ic-ffo-add"
                requiredPermission={`${PermissionModule.Marketing}.${PermissionPage.TnCSection}.${PermissionAccess.Add}`}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  renderModalDetail = () => {
    const { isOpenModal, selectedModalItemId } = this.state;
    const { history } = this.props;
    const elementRender = (
      <ModalTncSectionDetail
        isOpen={isOpenModal}
        tncId={selectedModalItemId}
        onClose={this.handleTncModalClose}
        history={history}
        onReload={this.handleReload}
      />
    );

    return isOpenModal ? elementRender : null;
  };

  render() {
    const { toastInformation, isLoading } = this.state;

    return (
      <div>
        <Helmet title="FITCO | Marketing - Terms &amp; Conditions" />
        <div className="container-page-tnc scroll-container">
          <div className="container-page-scrolling-area">
            <Grid container direction="column">
              <Grid item lg md className="section-page-header">
                {this.renderFilter()}
              </Grid>
              <Grid item lg md className="section-page-body">
                {isLoading ? (
                  <SkeletonGrid />
                ) : (
                  <Grid container spacing={2}>
                    {this.generateTncCard()}
                  </Grid>
                )}
              </Grid>
              <Grid item lg md className="section-page-footer" />
            </Grid>
          </div>
          <SnackBarSimple
            open={toastInformation.isOpen}
            durationHide={2000}
            message={toastInformation.message}
            onClickClose={this.handleCloseToash}
            snackbarType={toastInformation.snackbarType}
            anchor={optionToash}
          />
        </div>
        {this.renderModalDetail()}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  tncSectionList: params => getTncSectionList(dispatch, params),
});

const mapStateToProps = ({ tncData }) => ({
  tncData,
});

TncSectionPage.propTypes = {
  history: PropTypes.object,
  tncData: PropTypes.object,
  tncSectionList: PropTypes.func,
};

const shell = compose(connect(mapStateToProps, mapDispatchToProps));
export default shell(AuthenticationAccessPages(TncSectionPage));
