import { Images } from '../../../assets/Index';

const StylesJs = () => ({
  mainImageContent: {
    height: '100vh',
    width: '100%',
    backgroundImage: `url(${Images.mainPageSignIn})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
});

export default StylesJs;
