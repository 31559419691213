import React from 'react';
import PropTypes from 'prop-types';
import { Paper, Grid } from '@material-ui/core';
// style
import './HeaderMasterDataMainStyle.scss';
// component
import { ButtonMain, TabNavigation } from '../../../../../components/Index';
import { PermissionAccess, PermissionModule, PermissionPage } from '../../../../../helpers/Index';

const HeaderMasterDataMain = props => {
  const {
    onChangeTab,
    onButtonClickBranch,
    onButtonClickCompany,
    onButtonClickMerchant,
    data,
    currentTab,
  } = props;

  const handleChangeTab = value => {
    onChangeTab(value);
  };

  const handleButtonClickCompany = value => {
    onButtonClickCompany(value);
  };

  const handleButtonClickMerchant = value => {
    onButtonClickMerchant(value);
  };

  const handleButtonClickBranch = value => {
    onButtonClickBranch(value);
  };

  const renderButtonActive = () => {
    let renderElement = null;
    switch (data[currentTab].name) {
      case data[0].name:
        renderElement = (
          <ButtonMain
            labelText="Create Company"
            onClick={handleButtonClickCompany}
            type="primary"
            size="md"
            startIcon="ic-ffo-add"
            requiredPermission={`${PermissionModule.MasterData}.${PermissionPage.Company}.${PermissionAccess.Add}`}
          />
        );
        break;
      case data[1].name:
        renderElement = (
          <ButtonMain
            labelText="Create Merchant"
            onClick={handleButtonClickMerchant}
            type="primary"
            size="md"
            startIcon="ic-ffo-add"
            requiredPermission={`${PermissionModule.MasterData}.${PermissionPage.Merchant}.${PermissionAccess.Add}`}
          />
        );
        break;

      default:
        renderElement = (
          <ButtonMain
            labelText="Create Branch"
            onClick={handleButtonClickBranch}
            type="primary"
            size="md"
            startIcon="ic-ffo-add"
            requiredPermission={`${PermissionModule.MasterData}.${PermissionPage.Branch}.${PermissionAccess.Add}`}
          />
        );
        break;
    }

    return renderElement;
  };

  return (
    <Paper square className="container-tab-header-master-data">
      <Grid container>
        <Grid item lg md>
          <TabNavigation data={data} onChange={handleChangeTab} currentTab={currentTab} />
        </Grid>
        <Grid item lg={3} md={3} className="container-header-button">
          {renderButtonActive()}
        </Grid>
      </Grid>
    </Paper>
  );
};

HeaderMasterDataMain.propTypes = {
  currentTab: PropTypes.number,
  data: PropTypes.array,
  onButtonClickBranch: PropTypes.func,
  onButtonClickCompany: PropTypes.func,
  onButtonClickMerchant: PropTypes.func,
  onChangeTab: PropTypes.func,
};

export default HeaderMasterDataMain;
