/* eslint-disable react/no-access-state-in-setstate */
import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Grid, Breadcrumbs, Link } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import _ from 'lodash';
import { compose } from 'redux';
// Style
import './HandoverCreateStyle.scss';
// Component
import {
  SelectInputMain,
  InputMain,
  PickerInputDate,
  SnackBarSimple,
  ButtonMain,
  ModalAlertAction,
  TextAreaMain,
  TextInputNumber,
  AuthenticationAccessPages,
  PrevStateValue,
} from '../../../../components/Index';
import { ButtonActionFooterHandover } from '../components/Index';
// Action
import {
  getOrderShipmentList,
  PostHandover,
  getNameHandover,
} from '../../../../services/api/OrdersApi';
// Helpers
import {
  CommonHelper,
  MasterDataHelper,
  PermissionModule,
  PermissionPage,
  PermissionAccess,
} from '../../../../helpers/Index';

const currentDate = CommonHelper.currentDate('YYYY-MM-DD');
const startDateValue = CommonHelper.getStartDateMonth(currentDate);
const endDateValue = CommonHelper.getEndDateMonth(currentDate);

const optionAlert = {
  vertical: 'top',
  horizontal: 'right',
};

const initialValidation = {
  handoverName: { isError: '', errorMessage: '' },
  deliveryService: { isError: '', errorMessage: '' },
  remarkValue: { isError: '', errorMessage: '' },
};

const optionSearchType = MasterDataHelper.optionSearchType;
const optionShippingMethod = MasterDataHelper.shippingMethod;
const optionOrderType = MasterDataHelper.optionOrderType;

const initialSort = {
  sortField: 'name',
  sortOrder: '',
};

const initialFilter = {
  search: '',
  startDate: startDateValue,
  endDate: endDateValue,
  orderType: optionOrderType[2].value,
  searchType: optionSearchType[1].value,
};

class HandoverCreate extends React.Component {
  constructor(props) {
    super(props);

    props.checkUserAccessPermission(
      PermissionModule.Orders,
      PermissionPage.Handover,
      PermissionAccess.Add,
    );

    this.state = {
      form: {
        deliveryService: optionShippingMethod[0].value,
        handoverName: null,
        orderShipmentList: [],
      },
      filter: initialFilter,
      validation: initialValidation,
      page: 1,
      limit: 10,
      sort: initialSort,
      pagination: {
        showSizeChanger: true,
        showTotal: (total, range) => `Showing ${range[0]}-${range[1]} From ${total}`,
        pageSizeOptions: ['10', '20', '30', '40'],
      },
      loading: true,
      selectedRowKeys: [],
      isLoadingCreate: false,
      toastInformation: {
        isOpen: false,
        message: '',
        snackbarType: 'warning',
      },
      selectedId: null,
      isLoading: false,
      selectedDataRemark: null,
      remarkValue: null,
      dataRemark: [],
      modalDetailData: {
        title: 'Confirmation',
        body: 'Are you sure want to remark?',
      },
    };
  }

  async componentDidMount() {
    await this.getHandoverName();
    await this.getOrderListPagination();
  }

  componentWillUnmount() {}

  getHandoverName = async () => {
    const { getName } = this.props;
    const { form } = this.state;
    await getName().then(response => {
      const data = response.data;

      this.setState({
        form: { ...form, handoverName: data.name },
      });
    });
  };

  getOrderListPagination = () => {
    const { getShipmentList } = this.props;
    const { limit, page, pagination, sort, filter, form } = this.state;

    const payload = {
      page,
      limit,
      sort: sort.sortField,
      sortOrder: sort.sortOrder,
      search: filter.search,
      start_date: filter.startDate,
      end_date: filter.endDate,
      order_type: filter.orderType,
      search_type: filter.searchType,
      shipping_method: form.deliveryService,
      order_status: 'processing',
    };

    this.setState({ loading: true }, () => {
      getShipmentList(payload).then(response => {
        const data = response.data;
        this.setState({
          form: { ...form, orderShipmentList: data.data },
          loading: false,
          pagination: { ...pagination, pageSize: limit, total: data.total },
        });
      });
    });
  };

  handleTableChange = (pagination, filters, sorter) => {
    const { sort } = this.state;

    this.setState(
      {
        limit: pagination.pageSize,
        pagination: {
          ...this.state.pagination,
          current: pagination.current,
          pageSize: pagination.pageSize,
        },
        sort: {
          ...sort,
          sortField: sorter.field,
          sortOrder: sorter.order === 'ascend' ? '' : 'desc',
        },
        page: pagination.current,
      },
      () => {
        this.getOrderListPagination();
      },
    );
  };

  handleMouseEnter = record => {
    this.setState({ selectedId: record.sales_order_id });
  };

  handleMouseLeave = () => {
    this.setState({ selectedId: null });
  };

  handleClick = (event, value) => {
    const { history } = this.props;
    event.preventDefault();
    history.push(value);
  };

  handleButtonCancel = () => {
    const { history } = this.props;
    history.push('/order/handover');
  };

  getPayload = () => {
    const { form, selectedRowKeys, dataRemark } = this.state;
    const orderList = selectedRowKeys.map(item => {
      const selectedArray = form.orderShipmentList.filter(
        itemFilter => itemFilter.sales_order_id === item,
      );
      return {
        sales_order_id: item,
        remark: dataRemark[item] || null,
        qty_box: selectedArray[0].qtyBox || 1,
      };
    });

    const payload = {
      name: form.handoverName,
      delivery_services: form.deliveryService,
      order_list: orderList,
    };

    return payload;
  };

  handleButtonSubmit = () => {
    const { createHandover, history } = this.props;
    const params = this.getPayload();

    const checkValidation = this.validationForm();
    if (checkValidation) {
      this.setState(
        {
          isLoadingCreate: true,
        },
        () => {
          createHandover(params)
            .then(() => {
              const {
                orderData: { responseMessage },
              } = this.props;
              this.handleProcessMessage(responseMessage, 'success');
              this.setState(
                {
                  isLoadingCreate: false,
                },
                () => {
                  setTimeout(() => {
                    history.push('/order/handover');
                  }, 2000);
                },
              );
            })
            .catch(async error => {
              const message = error.data;
              await this.handleProcessMessage(message.messages, 'error');
              await this.setState({ isLoadingCreate: false });
            });
        },
      );
    }
  };

  handleCloseToash = () => {
    const { toastInformation } = this.state;
    this.setState({ toastInformation: { ...toastInformation, isOpen: false } });
  };

  validationForm = () => {
    this.setState({
      isLoadingCreate: true,
    });

    const { form, validation, selectedRowKeys } = this.state;

    let passCheck = true;
    let paramValidation01 = CommonHelper.objectCloning(initialValidation.handoverName);

    if (_.isEmpty(form.handoverName)) {
      paramValidation01 = {
        isError: 'error',
        errorMessage: 'Please Enter Handover Name',
      };

      passCheck = false;
    }

    if (_.isEmpty(selectedRowKeys)) {
      this.handleProcessMessage('Please Select Order', 'warning');
      passCheck = false;
    }

    if (!passCheck) {
      this.setState({
        isLoadingCreate: false,
        validation: {
          ...validation,
          handoverName: paramValidation01,
        },
      });
    }

    return passCheck;
  };

  handleChangeDeliveryService = value => {
    const { form } = this.state;

    this.setState(
      {
        form: { ...form, deliveryService: value },
      },
      () => {
        this.getOrderListPagination();
      },
    );
  };

  handleChangeHandoverName = value => {
    const { form, validation } = this.state;
    const message = {
      isError: '',
      errorMessage: '',
    };

    this.setState({
      form: { ...form, handoverName: value },
      validation: {
        ...validation,
        handoverName: _.isEmpty(value)
          ? { isError: 'error', errorMessage: 'Please Enter Handover Name' }
          : message,
      },
    });
  };

  handleChangeSearch = value => {
    const { filter } = this.state;

    this.setState(
      {
        filter: { ...filter, search: value },
      },
      () => {
        this.getOrderListPagination();
      },
    );
  };

  handleChangeStartDate = value => {
    const { filter } = this.state;
    const { getOrderListPagination } = this;
    const endDate = CommonHelper.getEndDateMonth(value);

    this.setState(
      {
        filter: { ...filter, startDate: value, endDate },
      },
      () => {
        getOrderListPagination();
      },
    );
  };

  handleChangeEndDate = value => {
    const { filter } = this.state;
    const { getOrderListPagination } = this;

    this.setState(
      {
        filter: { ...filter, endDate: value },
      },
      () => {
        getOrderListPagination();
      },
    );
  };

  handleSelectChangeRow = selectedRowKeys => {
    this.setState({ selectedRowKeys });
  };

  handleClickRemarkRow = data => {
    const { modalDetailData, dataRemark } = this.state;

    const defaultValue = dataRemark[data.sales_order_id] || null;

    this.setState({
      isOpenModal: true,
      selectedDataRemark: data,
      remarkValue: defaultValue,
      modalDetailData: {
        ...modalDetailData,
        body: `Are you sure want to remark ? `,
      },
    });
  };

  handleButtonSubmitRemark = () => {
    const { remarkValue, validation, selectedDataRemark, dataRemark } = this.state;

    if (!remarkValue || remarkValue === '') {
      this.setState({
        validation: {
          ...validation,
          remarkValue: { isError: 'error', errorMessage: 'Remark Still Empty' },
        },
      });
    } else {
      this.setState({
        dataRemark: { ...dataRemark, [selectedDataRemark.sales_order_id]: remarkValue },
        isOpenModal: false,
        selectedDataRemark: null,
      });
    }
  };

  handleCloseModal = () => {
    const { validation } = this.state;
    const message = { isError: '', errorMessage: '' };

    this.setState({
      isOpenModal: false,
      remarkValue: '',
      validation: { ...validation, remarkValue: message },
    });
  };

  handleTextChangeRemark = value => {
    const { validation } = this.state;

    if (value.length <= 50) {
      this.setState({
        remarkValue: value,
        validation: {
          ...validation,
          remarkValue: { isError: '', errorMessage: '' },
        },
      });
    }
  };

  handleQuantityChange = salesOrderId => value => {
    const { form } = this.state;
    const existingItem = CommonHelper.objectCloning(form.orderShipmentList);
    const index = existingItem.findIndex(item => item.sales_order_id === salesOrderId);
    const convertedValue = parseInt(value, 10);

    if (convertedValue >= 1) {
      existingItem[index].qtyBox = convertedValue;

      this.setState({
        form: { ...form, orderShipmentList: existingItem },
      });
    }
  };

  handleProcessMessage(messages, type) {
    const paramInformation = {
      isOpen: true,
      message: messages,
      snackbarType: type,
    };

    this.setState({
      toastInformation: paramInformation,
    });
  }

  renderColumns = () => {
    const { selectedId } = this.state;
    return [
      {
        title: 'Product',
        dataIndex: 'name',
        sorter: true,
        width: '68%',
        render: (text, row) => {
          return (
            <Grid container key={row.id}>
              <Grid item lg={1} md={1} className="section-icon">
                <div>
                  <i
                    className={`ic-ffo-lg-shop container-icon-prefix size-40 orders-icon-purchase`}
                  />
                </div>
              </Grid>
              <Grid item lg={11} md={11}>
                <Grid
                  container
                  direction="column"
                  className="container-row-content flex-wrap-unset"
                >
                  <Grid item className="pb-24">
                    <Grid container direction="row" className="container-text-header">
                      <Grid item lg={12} md={12} className="left">
                        <Grid container direction="column">
                          <Grid item>
                            <label className="text-14 text-product-name">{row.products}</label>
                          </Grid>
                          <Grid item>
                            <label className="text-12 text-opacity-50">
                              ID {row.sales_order_id}
                            </label>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container spacing={2} justify="flex-start">
                      <Grid item xl={3} lg={4} md={4} className="flex-column">
                        <label className="text-12 text-opacity-50">Customer</label>
                        <label className="text-12 pt-4">{row.user_name}</label>
                      </Grid>
                      <Grid item xl={3} lg={4} md={4} className="flex-column">
                        <label className="text-12 text-opacity-50">W-Order ID</label>
                        <label className="text-12 pt-4">{row.external_order_id}</label>
                      </Grid>
                      <Grid item xl={3} lg={4} md={4} className="flex-column">
                        <label className="text-12 text-opacity-50">Order ID</label>
                        <label className="text-12 pt-4">{row.sales_order_id}</label>
                      </Grid>
                      <Grid item xl={3} lg={4} md={4} className="flex-column">
                        <label className="text-12 text-opacity-50">Airwaybill Number</label>
                        <label className="text-12 pt-4">{row.airway_bill_number || '-'}</label>
                      </Grid>
                      <Grid item xl={3} lg={4} md={4} className="flex-column">
                        <label className="text-12 text-opacity-50">Order Created</label>
                        <label className="text-12 pt-4">
                          {`${CommonHelper.dateTimeParseNewFormat(row.order_date, 'DD MMM YYYY')}`}
                        </label>
                      </Grid>
                    </Grid>
                  </Grid>
                  {this.state.dataRemark[row.sales_order_id] && (
                    <Grid item className="pt-24">
                      <Grid container direction="column">
                        <Grid item className="flex-row label-remark">
                          <i className="icon-slot ic-ffo-marketing" />
                          <label className="text-14 label-remark pl-4">Remark Order</label>
                        </Grid>
                        <Grid item>
                          <label className="text-12 text-opacity-50">
                            {this.state.dataRemark[row.sales_order_id]}
                          </label>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          );
        },
      },
      {
        title: 'Qty Box',
        width: '14%',
        sorter: false,
        align: 'center',
        render: (text, row) => {
          return (
            <TextInputNumber
              placeHolderText={'Qty'}
              currentValue={row.qtyBox || 1}
              onChange={this.handleQuantityChange(row.sales_order_id)}
            />
          );
        },
      },
      {
        title: 'Qty Order',
        dataIndex: 'total_products',
        width: '18%',
        sorter: false,
        align: 'center',
        render: (text, row) => {
          const processG = row.total_weight * 1000;
          const totalKg = (processG * row.total_products) / 1000;
          if (row.sales_order_id === selectedId) {
            return (
              <ButtonMain
                type="ghost"
                size="md"
                labelText={'Remark Order'}
                onClick={() => this.handleClickRemarkRow(row)}
              />
            );
          }
          return (
            <Grid container direction={'column'} justify={'center'}>
              <Grid item>
                <label className="text-16 text-bold">
                  {row.total_products > 1
                    ? `${row.total_products} Orders`
                    : `${row.total_products} Order`}
                </label>
              </Grid>
              <Grid item>{`(${row.total_products}x ${processG.toFixed(2)}g) ${totalKg.toFixed(
                2,
              )}KG`}</Grid>
              <Grid item className="pt-16">
                <div className="label-items">
                  <label className="text-12">{row.label_data.destination_code}</label>
                </div>
              </Grid>
            </Grid>
          );
        },
      },
    ];
  };

  renderOrderList = () => {
    const { loading, selectedRowKeys, filter, pagination, form } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.handleSelectChangeRow,
    };

    return (
      <Grid container direction="row">
        <Grid item lg={12} md={12}>
          <Grid container justify="space-between" alignItems="center" className="container-filter">
            <Grid item lg={5} md={5} className="row-filter">
              <div className="flex-row" style={{ alignItems: 'center' }}>
                <label className="text-16 text-bold pr-16">Select Orders</label>
                <InputMain
                  placeholder={'Search By Order ID'}
                  value={filter.search}
                  onChange={this.handleChangeSearch}
                  prefix={'ic-ffo-search'}
                  size="middle"
                />
              </div>
            </Grid>
            <Grid item lg={7} md={7}>
              <div className="flex-column">
                <Grid container justify="flex-end" alignItems="center">
                  <Grid item lg={3} md={3} className="text-right">
                    <label className="text-12 pr-16">Showing Date</label>
                  </Grid>
                  <Grid item lg={4} md={4}>
                    <div className="container-remove-margin">
                      <PickerInputDate
                        customIcon="ic-ffo-date-pick"
                        dateFormat="dd/MM/yyyy"
                        defaultValue={this.state.filter.startDate}
                        onChange={this.handleChangeStartDate}
                        toolbar={false}
                      />
                    </div>
                  </Grid>
                  <label className="text-12 pr-8 pl-8"> - </label>
                  <Grid item lg={4} md={4}>
                    <div className="container-remove-margin">
                      <PickerInputDate
                        customIcon="ic-ffo-date-pick"
                        dateFormat="dd/MM/yyyy"
                        minDate={filter.startDate}
                        defaultValue={this.state.filter.endDate}
                        onChange={this.handleChangeEndDate}
                        toolbar={false}
                      />
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={12} md={12} className="pt-16">
          <Table
            columns={this.renderColumns()}
            rowKey={record => record.sales_order_id}
            dataSource={form.orderShipmentList}
            onChange={this.handleTableChange}
            loading={loading}
            pagination={pagination}
            rowSelection={{
              type: 'checkbox',
              ...rowSelection,
            }}
            onRow={record => {
              return {
                onMouseEnter: () => {
                  this.handleMouseEnter(record);
                }, // mouse enter row
                onMouseLeave: () => {
                  this.handleMouseLeave();
                },
              };
            }}
          />
        </Grid>
      </Grid>
    );
  };

  render() {
    const { form } = this.state;

    return (
      <div>
        <Helmet title="FITCO | Orders - Create Handover" />
        <div className="container-page-handover-create scroll-container-invisible">
          <div className="container-page-scrolling-area">
            <Grid container direction="column">
              <Grid item lg md className="section-page-header">
                <Grid container>
                  <Grid item>
                    <div className="breadcrumbs-section">
                      <Breadcrumbs aria-label="breadcrumb">
                        <Link
                          className="text-12"
                          color="inherit"
                          href="/orders"
                          onClick={event => {
                            this.handleClick(event, '/orders');
                          }}
                        >
                          <i className="icon-slot ic-ffo-reports" /> Order
                        </Link>
                        <Link
                          className="text-12"
                          color="inherit"
                          href="/orders"
                          onClick={event => {
                            this.handleClick(event, '/order/handover');
                          }}
                        >
                          Handover
                        </Link>
                        <label className="text-12" color="inherit">
                          Create Handover
                        </label>
                      </Breadcrumbs>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item lg md className="section-page-body">
                <div className="handover-create-card">
                  <Grid className="pb-24" container direction={'row'} spacing={3}>
                    <Grid item lg={12} md={12} className="pb-32">
                      <label className="text-16 text-bold text-opacity-50">Create Handover</label>
                    </Grid>
                    <Grid item lg={6} md={6}>
                      <div className="flex-column">
                        <label className="text-12 pb-8">Handover Name</label>
                        <InputMain
                          placeholder={'Handover Name'}
                          value={form.handoverName}
                          onChange={this.handleChangeHandoverName}
                          validateStatus={this.state.validation.handoverName.isError}
                          errorMessage={this.state.validation.handoverName.errorMessage}
                          size="large"
                          disabled
                        />
                      </div>
                    </Grid>
                    <Grid item lg={6} md={6}>
                      <div className="flex-column">
                        <label className="text-12 pb-8">Delivery Service</label>
                        <SelectInputMain
                          options={optionShippingMethod}
                          size="large"
                          currentValue={form.deliveryService}
                          onChange={this.handleChangeDeliveryService}
                          placeholder={'Select Delivery Service'}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </div>
                <div className="handover-create-card mt-24">{this.renderOrderList()}</div>
              </Grid>
              <Grid item lg md className="section-page-footer" />
            </Grid>
            {this.state.isOpenModal ? (
              <ModalAlertAction
                onButtonSubmit={this.handleButtonSubmitRemark}
                onCloseModal={this.handleCloseModal}
                modalDetail={this.state.modalDetailData}
                modalType="danger"
                buttonSubmitText="Yes"
                openModal={this.state.isOpenModal}
                isLoading={this.state.isLoading}
                customElementProps={
                  <TextAreaMain
                    onChange={this.handleTextChangeRemark}
                    placeholder="Enter reason why remark this order"
                    rows={3}
                    currentValue={this.state.remarkValue}
                    validateStatus={this.state.validation.isError}
                    errorMessage={this.state.validation.errorMessage}
                  />
                }
              />
            ) : null}
            <SnackBarSimple
              open={this.state.toastInformation.isOpen}
              durationHide={2000}
              message={this.state.toastInformation.message}
              onClickClose={this.handleCloseToash}
              snackbarType={this.state.toastInformation.snackbarType}
              anchor={optionAlert}
            />
          </div>
        </div>
        <div className="container-page-handover-create-container-floating">
          <ButtonActionFooterHandover
            customElementProps={
              <div className="container-order-total ml-53">
                <div className="flex-column">
                  <label className="text-14 title">Total Order List</label>
                  <label className="text-21">
                    {this.state.selectedRowKeys.length > 1
                      ? `${this.state.selectedRowKeys.length} Orders`
                      : `${this.state.selectedRowKeys.length} Order`}
                  </label>
                </div>
              </div>
            }
            onButtonClickCancel={this.handleButtonCancel}
            onButtonClickSubmit={this.handleButtonSubmit}
            isLoadingCreate={this.state.isLoadingCreate}
          />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getShipmentList: params => getOrderShipmentList(dispatch, params),
  createHandover: params => PostHandover(dispatch, params),
  getName: () => getNameHandover(dispatch),
});

const mapStateToProps = ({ orderData }) => ({
  orderData,
});

HandoverCreate.propTypes = {
  checkUserAccessPermission: PropTypes.func,
  createHandover: PropTypes.func,
  getName: PropTypes.func,
  getShipmentList: PropTypes.func,
  history: PropTypes.object,
  orderData: PropTypes.object,
};

const shell = compose(connect(mapStateToProps, mapDispatchToProps));
const core = compose(AuthenticationAccessPages, PrevStateValue);

export default shell(core(HandoverCreate));
