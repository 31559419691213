import { constants } from '../reducers/PromoCodeReducer';

export function getPromoCodePaginationRequest() {
  return { type: constants.getPromoCodePaginationRequest };
}
export function getPromoCodePaginationSuccess(response) {
  return { type: constants.getPromoCodePaginationSuccess, payload: response.data };
}
export function getPromoCodePaginationFail(response) {
  return { type: constants.getPromoCodePaginationFail, payload: response };
}

export function getCheckValidPromoCodeRequest() {
  return { type: constants.getCheckValidPromoCodeRequest };
}
export function getCheckValidPromoCodeSuccess(response) {
  return { type: constants.getCheckValidPromoCodeSuccess, payload: response };
}
export function getCheckValidPromoCodeFail(response) {
  return { type: constants.getCheckValidPromoCodeFail, payload: response };
}

export function getPromoCodeExportReportRequest() {
  return { type: constants.getPromoCodeExportReportRequest };
}
export function getPromoCodeExportReportSuccess(response) {
  return { type: constants.getPromoCodeExportReportSuccess, payload: response };
}
export function getPromoCodeExportReportFail(response) {
  return { type: constants.getPromoCodeExportReportFail, payload: response.data };
}

export function getPromoCodeDetailsRequest() {
  return { type: constants.getPromoCodeDetailsRequest };
}
export function getPromoCodeDetailsSuccess(response) {
  return { type: constants.getPromoCodeDetailsSuccess, payload: response };
}
export function getPromoCodeDetailsFail(response) {
  return { type: constants.getPromoCodeDetailsFail, payload: response.data };
}

export function setCreatePromoCodeRequest() {
  return { type: constants.setCreatePromoCodeRequest };
}
export function setCreatePromoCodeSuccess(response) {
  return { type: constants.setCreatePromoCodeSuccess, payload: response };
}
export function setCreatePromoCodeFail(response) {
  return { type: constants.setCreatePromoCodeFail, payload: response.data };
}

export function setUpdatePromoCodeRequest() {
  return { type: constants.setUpdatePromoCodeRequest };
}
export function setUpdatePromoCodeSuccess(response) {
  return { type: constants.setUpdatePromoCodeSuccess, payload: response };
}
export function setUpdatePromoCodeFail(response) {
  return { type: constants.setUpdatePromoCodeFail, payload: response.data };
}
