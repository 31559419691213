import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { Table } from 'antd';
import _ from 'lodash';
// style
import './HealthPassportStyle.scss';
// component
import { AuthenticationAccessPages, ButtonMain, FitcoPopover } from '../../../components/Index';
import { ModalFaceScanner, ModalScanResult } from './components/Index';
import { getHealthPassportActivities } from '../../../services/api/MedicApi';
import { FitcoPopoverPlacement, FitcoPopoverTrigger } from '../../../helpers/ComponentHelper';
import { healthStatusCode } from '../../../helpers/MasterDataHelper';

class HealthPassport extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpenModalFaceScanner: false,
      isOpenModalScanResult: false,
      scanResult: null,
      activities: [],
      tableLoading: false,
      tablePagination: {
        showSizeChanger: true,
        showTotal: (total, range) => `Showing ${range[0]}-${range[1]} from ${total}`,
        pageSizeOptions: ['10', '20', '30', '40'],
      },
    };
  }

  componentDidMount() {
    this.getActivititiesData();
  }

  componentDidUpdate() {}

  // #region API Function
  getActivititiesData() {
    const { getActivities } = this.props;

    this.setState(
      {
        tableLoading: true,
      },
      () => {
        getActivities().then(response => {
          this.setState({
            activities: response.data,
            tableLoading: false,
          });
        });
      },
    );
  }
  // #endregion

  // #region Button Handler
  handleOpenModalFaceScanner = () => {
    this.setState({
      isOpenModalFaceScanner: true,
    });
  };

  handleCloseModalFaceScanner = () => {
    this.setState({
      isOpenModalFaceScanner: false,
    });
  };

  handleOpenModalScanResult = data => {
    this.setState({
      isOpenModalScanResult: true,
      scanResult: data,
    });
  };

  handleCloseModalScanResult = () => {
    this.setState(
      {
        isOpenModalScanResult: false,
        scanResult: null,
      },
      () => {
        this.getActivititiesData();
      },
    );
  };
  // #endregion

  // #region Render Function

  generatePopoverContent = healthStatus => {
    let text = null;
    switch (healthStatus) {
      case healthStatusCode.Healthy:
        text = 'Last COVID-19 test is still within 7 days.';
        break;
      case healthStatusCode.Risky:
        text = 'Last COVID-19 test is still within 14 days.';
        break;
      case healthStatusCode.HighRisk:
        text = 'Last COVID-19 test is more than 14 days ago.';
        break;
      case healthStatusCode.Unknown:
      default:
        text = 'No record found related to the user.';
        break;
    }

    const content = <div className="p-5 text-10">{text}</div>;
    return content;
  };

  renderFilterSection() {
    return (
      <div className="flex-column">
        <Grid
          container
          justify="space-between"
          alignItems="center"
          className="container-filter flex-wrap-unset mb-32"
        >
          <Grid item xl={2} lg={2} md={2} className="row-filter">
            <ButtonMain
              labelText="Scan"
              onClick={this.handleOpenModalFaceScanner}
              type="primary"
              size="md"
            />
          </Grid>
        </Grid>
      </div>
    );
  }

  renderActivites() {
    const { activities, tablePagination, tableLoading } = this.state;

    return (
      <Table
        columns={this.renderColumns()}
        rowKey={(record, index) => index}
        dataSource={activities}
        pagination={tablePagination}
        loading={tableLoading}
        onChange={this.handleTableChange}
      />
    );
  }

  renderColumns = () => {
    return [
      {
        title: 'No.',
        dataIndex: 'health_passport_activity_id',
        key: 'health_passport_activity_id',
      },
      {
        title: 'Name',
        dataIndex: 'name',
        render: (text, row) => {
          return (
            <Grid container direction="column">
              <Grid item lg md>
                <div className="flex-column">
                  <label
                    className={`text-14 ${
                      row.health_status === healthStatusCode.Unknown
                        ? 'text-rolling-stone'
                        : 'text-matisse'
                    }`}
                  >
                    {row.name ? row.name : 'Not Found'}
                  </label>
                </div>
              </Grid>
            </Grid>
          );
        },
      },
      {
        title: 'Identitas',
        align: 'right',
        dataIndex: 'identity_number',
        render: (text, row) => {
          return (
            <Grid container direction="column">
              <Grid item lg md>
                <div className="flex-column">
                  <label className="text-14">
                    {row.identity_number ? row.identity_number : 'N/A'}
                  </label>
                  <label className="text-10 mt-5 text-shuttle-gray">
                    {_.upperCase(row.identity_type)}
                  </label>
                </div>
              </Grid>
            </Grid>
          );
        },
      },
      {
        title: 'Location',
        align: 'right',
        dataIndex: 'branch_name',
        render: (text, row) => {
          return (
            <Grid container direction="column">
              <Grid item lg md>
                <div className="flex-column">
                  <label className="text-14">{row.branch_name}</label>
                  <label className="text-10 mt-5 text-shuttle-gray">{row.gate_number}</label>
                </div>
              </Grid>
            </Grid>
          );
        },
      },
      {
        title: 'Date & Time',
        align: 'right',
        dataIndex: 'date',
        render: (text, row) => {
          return (
            <Grid container direction="column">
              <Grid item lg md>
                <div className="flex-column">
                  <label className="text-14">{row.time}</label>
                  <label className="text-10 mt-5 text-shuttle-gray">{row.date}</label>
                </div>
              </Grid>
            </Grid>
          );
        },
      },
      {
        title: 'Status',
        align: 'center',
        dataIndex: 'health_status',
        render: (text, row) => {
          return (
            <Grid container direction="column">
              <Grid item lg md>
                <div className="flex-column">
                  <label className={`text-14 ${row.health_status} text-bold`}>
                    {_.upperCase(row.health_status)}
                  </label>
                </div>
              </Grid>
            </Grid>
          );
        },
      },
      {
        title: '',
        align: 'center',
        dataIndex: 'health_status',
        render: (text, row) => {
          return (
            <FitcoPopover
              content={this.generatePopoverContent(row.health_status)}
              placement={FitcoPopoverPlacement.Top}
              trigger={FitcoPopoverTrigger.Hover}
            />
          );
        },
      },
    ];
  };
  // #endregion

  render() {
    const { isOpenModalFaceScanner, isOpenModalScanResult, scanResult } = this.state;

    return (
      <div>
        <Helmet title="FITCO | Lab Booking" />
        <div className="container-page-eat scroll-container">
          <div className="container-page-scrolling-area">
            <Grid container direction="column">
              <Grid item lg md sm className="section-page-header">
                {this.renderFilterSection()}
                {this.renderActivites()}
              </Grid>
            </Grid>
            {isOpenModalFaceScanner && (
              <ModalFaceScanner
                isOpen={isOpenModalFaceScanner}
                onClose={this.handleCloseModalFaceScanner}
                verifyHandler={data => {
                  this.handleOpenModalScanResult(data);
                }}
              />
            )}
            {isOpenModalScanResult && (
              <ModalScanResult
                isOpen={isOpenModalScanResult}
                onClose={this.handleCloseModalScanResult}
                data={scanResult}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getActivities: () => getHealthPassportActivities(dispatch),
});

const mapStateToProps = () => ({});

HealthPassport.propTypes = {
  getActivities: PropTypes.func,
};

const shell = compose(connect(mapStateToProps, mapDispatchToProps));

export default shell(AuthenticationAccessPages(HealthPassport));
