import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import _ from 'lodash';
// import { Grid, FormControl, FormLabel } from '@material-ui/core';
// component
import { HeaderMasterDataMain } from './components/Index';
import {
  SnackBarSimple,
  PrevStateValue,
  AuthenticationAccessPages,
} from '../../../components/Index';
// view
import { CompanyPage, MerchantPage, BranchPage } from './Index';
// Helper
import { CommonHelper } from '../../../helpers/Index';
import { compose } from 'redux';
import ModalCompanyCreate from './company/components/modal-company-create/ModalCompanyCreate';

const dataTabs = [{ name: 'Company' }, { name: 'Merchant' }, { name: 'Branch' }];

const optionToash = {
  vertical: 'top',
  horizontal: 'right',
};

// const modalDetailData = {
//   title: 'Confirmation',
// };

// const initialValidation = {
//   name: { isError: false, errorMessage: '' },
//   email: { isError: false, errorMessage: '' },
//   phone: { isError: false, errorMessage: '' },
// };

// const initialForm = {
//   name: null,
//   email: null,
//   phone: null,
// };

// const resetValidation = { isError: false, errorMessage: '' };
// const maxStringLength = 100;

class MasterDataMainPage extends React.Component {
  constructor(props) {
    super(props);
    const {
      usersReducer: { prevStateValue },
    } = props;

    this.state = {
      tabsValue: prevStateValue.activeTab || 0,
      isOpenModal: false,
      // form: initialForm,
      isLoading: false,
      // currentStringLength: 0,
      // validation: initialValidation,
      toastInformation: {
        isOpen: false,
        message: '',
        snackbarType: 'warning',
      },
    };
  }

  componentDidMount() {
    const {
      onResetPrevStateValue,
      history: { location },
    } = this.props;
    onResetPrevStateValue({ url: location.pathname });
  }

  componentWillReceiveProps(nextProps) {
    const { usersReducer } = nextProps;

    if (_.isEmpty(usersReducer.prevStateValue)) {
      this.handleTabChange(0);
    }
  }

  componentWillUnmount() {}

  handleTabChange = value => {
    const {
      onSetPrevStateValueTabs,
      history: { location },
    } = this.props;

    onSetPrevStateValueTabs({ activeTab: value, url: location.pathname });
    this.setState({ tabsValue: value });
  };

  handleButtonCreateClickCompany = () => {
    this.setState({ isOpenModal: true });
  };

  handleButtonCreateClickMerchant = () => {
    const { history } = this.props;
    history.push('/master-data/main-data/merchant/add');
  };

  handleButtonCreateClickBranch = () => {
    const { history } = this.props;
    history.push('/master-data/main-data/branch/add');
  };

  handleCloseModalCreate = () => {
    this.setState({
      isOpenModal: false,
    });
  };

  handleCloseToash = () => {
    const { toastInformation } = this.state;
    this.setState({ toastInformation: { ...toastInformation, isOpen: false } });
  };

  handlePopUpInformation = (message, typeMessage) => {
    this.processMessage(message, typeMessage);
  };

  processMessage(messages, type) {
    const convertedMessage = CommonHelper.generateMessage(messages);

    const paramInformation = {
      isOpen: true,
      message: convertedMessage,
      snackbarType: type,
    };

    this.setState({
      toastInformation: paramInformation,
    });
  }

  renderPages = () => {
    const { tabsValue, isLoading } = this.state;
    const { history } = this.props;
    let renderElement = null;

    switch (dataTabs[tabsValue]) {
      case dataTabs[0]:
        renderElement = <CompanyPage isLoadingParent={isLoading} />;
        break;
      case dataTabs[1]:
        renderElement = <MerchantPage history={history} />;
        break;
      case dataTabs[2]:
        renderElement = <BranchPage history={history} />;
        break;
      default:
        renderElement = <CompanyPage isLoadingParent={isLoading} />;
        break;
    }

    return renderElement;
  };

  renderModalCreate() {
    const { isOpenModal, tabsValue } = this.state;
    let elementRender = null;

    switch (dataTabs[tabsValue].name) {
      case dataTabs[0].name:
        elementRender = isOpenModal ? (
          <ModalCompanyCreate
            isOpen={isOpenModal}
            onModalClose={this.handleCloseModalCreate}
            onPopUpInformation={this.handlePopUpInformation}
          />
        ) : null;
        break;
      default:
        break;
    }
    return isOpenModal ? elementRender : null;
  }

  render() {
    const { toastInformation } = this.state;

    return (
      <div>
        <Helmet title={`FITCO | Master Data - Entities`} />
        <div>
          <HeaderMasterDataMain
            data={dataTabs}
            currentTab={this.state.tabsValue}
            onChangeTab={this.handleTabChange}
            onButtonClickBranch={this.handleButtonCreateClickBranch}
            onButtonClickCompany={this.handleButtonCreateClickCompany}
            onButtonClickMerchant={this.handleButtonCreateClickMerchant}
          />
          {this.renderPages()}
          {this.renderModalCreate()}
        </div>
        <SnackBarSimple
          open={toastInformation.isOpen}
          durationHide={2000}
          message={toastInformation.message}
          onClickClose={this.handleCloseToash}
          snackbarType={toastInformation.snackbarType}
          anchor={optionToash}
        />
      </div>
    );
  }
}

const mapDispatchToProps = () => ({});

const mapStateToProps = () => ({});

MasterDataMainPage.propTypes = {
  history: PropTypes.object,
  onResetPrevStateValue: PropTypes.func,
  onSetPrevStateValueTabs: PropTypes.func,
  usersReducer: PropTypes.object,
};

const shell = compose(connect(mapStateToProps, mapDispatchToProps));
const core = compose(AuthenticationAccessPages, PrevStateValue);

export default shell(core(MasterDataMainPage));
