import { constants } from '../reducers/ScheduleReducer';

export function getScheduleDateRequest(columNumber) {
  let result = null;

  switch (columNumber) {
    case 1:
      result = { type: constants.scheduleDate01Request };
      break;
    case 2:
      result = { type: constants.scheduleDate02Request };
      break;
    case 3:
      result = { type: constants.scheduleDate03Request };
      break;
    case 4:
      result = { type: constants.scheduleDate04Request };
      break;
    case 5:
      result = { type: constants.scheduleDate05Request };
      break;
    case 6:
      result = { type: constants.scheduleDate06Request };
      break;

    default:
      result = { type: constants.scheduleDate07Request };
      break;
  }

  return result;
}
export function getScheduleDateSuccess(response, columNumber) {
  let result = null;

  switch (columNumber) {
    case 1:
      result = { type: constants.scheduleDate01Success, payload: response.data };
      break;
    case 2:
      result = { type: constants.scheduleDate02Success, payload: response.data };
      break;
    case 3:
      result = { type: constants.scheduleDate03Success, payload: response.data };
      break;
    case 4:
      result = { type: constants.scheduleDate04Success, payload: response.data };
      break;
    case 5:
      result = { type: constants.scheduleDate05Success, payload: response.data };
      break;
    case 6:
      result = { type: constants.scheduleDate06Success, payload: response.data };
      break;

    default:
      result = { type: constants.scheduleDate07Success, payload: response.data };
      break;
  }

  return result;
}
export function getScheduleDateFail(response, columNumber) {
  let result = null;

  switch (columNumber) {
    case 1:
      result = { type: constants.scheduleDate01Fail, payload: response.data };
      break;
    case 2:
      result = { type: constants.scheduleDate02Fail, payload: response.data };
      break;
    case 3:
      result = { type: constants.scheduleDate03Fail, payload: response.data };
      break;
    case 4:
      result = { type: constants.scheduleDate04Fail, payload: response.data };
      break;
    case 5:
      result = { type: constants.scheduleDate05Fail, payload: response.data };
      break;
    case 6:
      result = { type: constants.scheduleDate06Fail, payload: response.data };
      break;

    default:
      result = { type: constants.scheduleDate07Fail, payload: response.data };
      break;
  }

  return result;
}

export function getScheduleUserDetailsByPhoneRequest() {
  return { type: constants.getScheduleUserDetailsByPhoneRequest };
}
export function getScheduleUserDetailsByPhoneSuccess(response) {
  return { type: constants.getScheduleUserDetailsByPhoneSuccess, payload: response };
}
export function getScheduleUserDetailsByPhoneFail(response) {
  return { type: constants.getScheduleUserDetailsByPhoneFail, payload: response.data };
}

export function setProcessCreateOrderBookingRequest() {
  return { type: constants.setProcessCreateOrderBookingRequest };
}
export function setProcessCreateOrderBookingSuccess(response) {
  return { type: constants.setProcessCreateOrderBookingSuccess, payload: response };
}
export function setProcessCreateOrderBookingFail(response) {
  return { type: constants.setProcessCreateOrderBookingFail, payload: response.data };
}

export function setCurrentScheduleTabActive(param) {
  return { type: constants.setCurrentScheduleTabActive, payload: param };
}

export function getConsultationScheduleDateRequest(columNumber) {
  let result = null;

  switch (columNumber) {
    case 1:
      result = { type: constants.consultationScheduleDate01Request };
      break;
    case 2:
      result = { type: constants.consultationScheduleDate02Request };
      break;
    case 3:
      result = { type: constants.consultationScheduleDate03Request };
      break;
    case 4:
      result = { type: constants.consultationScheduleDate04Request };
      break;
    case 5:
      result = { type: constants.consultationScheduleDate05Request };
      break;
    case 6:
      result = { type: constants.consultationScheduleDate06Request };
      break;

    default:
      result = { type: constants.consultationScheduleDate07Request };
      break;
  }

  return result;
}
export function getConsultationScheduleDateSuccess(response, columNumber) {
  let result = null;

  switch (columNumber) {
    case 1:
      result = { type: constants.consultationScheduleDate01Success, payload: response.data };
      break;
    case 2:
      result = { type: constants.consultationScheduleDate02Success, payload: response.data };
      break;
    case 3:
      result = { type: constants.consultationScheduleDate03Success, payload: response.data };
      break;
    case 4:
      result = { type: constants.consultationScheduleDate04Success, payload: response.data };
      break;
    case 5:
      result = { type: constants.consultationScheduleDate05Success, payload: response.data };
      break;
    case 6:
      result = { type: constants.consultationScheduleDate06Success, payload: response.data };
      break;

    default:
      result = { type: constants.consultationScheduleDate07Success, payload: response.data };
      break;
  }

  return result;
}
export function getConsultationScheduleDateFail(response, columNumber) {
  let result = null;

  switch (columNumber) {
    case 1:
      result = { type: constants.consultationScheduleDate01Fail, payload: response.data };
      break;
    case 2:
      result = { type: constants.consultationScheduleDate02Fail, payload: response.data };
      break;
    case 3:
      result = { type: constants.consultationScheduleDate03Fail, payload: response.data };
      break;
    case 4:
      result = { type: constants.consultationScheduleDate04Fail, payload: response.data };
      break;
    case 5:
      result = { type: constants.consultationScheduleDate05Fail, payload: response.data };
      break;
    case 6:
      result = { type: constants.consultationScheduleDate06Fail, payload: response.data };
      break;

    default:
      result = { type: constants.consultationScheduleDate07Fail, payload: response.data };
      break;
  }

  return result;
}

export function checkMedicConsultationRequest() {
  return { type: constants.checkMedicConsultationRequest };
}
export function checkMedicConsultationSuccess(response) {
  return { type: constants.checkMedicConsultationSuccess, payload: response };
}
export function checkMedicConsultationFail(response) {
  return { type: constants.checkMedicConsultationFail, payload: response.data };
}

export function getBookingAdminFeeRequest() {
  return { type: constants.getBookingAdminFeeRequest };
}
export function getBookingAdminFeeSuccess(response) {
  return { type: constants.getBookingAdminFeeSuccess, payload: response };
}
export function getBookingAdminFeeFail(response) {
  return { type: constants.getBookingAdminFeeFail, payload: response.data };
}

export function setRescheduleWorkoutRequest() {
  return { type: constants.setRescheduleWorkoutRequest };
}
export function setRescheduleWorkoutSuccess(response) {
  return { type: constants.setRescheduleWorkoutSuccess, payload: response };
}
export function setRescheduleWorkoutFail(response) {
  return { type: constants.setRescheduleWorkoutFail, payload: response.data };
}

export function getAvailableHourByDateRequest() {
  return { type: constants.getAvailableHourByDateRequest };
}
export function getAvailableHourByDateSuccess(response) {
  return { type: constants.getAvailableHourByDateSuccess, payload: response };
}
export function getAvailableHourByDateFail(response) {
  return { type: constants.getAvailableHourByDateFail, payload: response.data };
}

export function rescheduleTimeRequest() {
  return { type: constants.rescheduleTimeRequest };
}
export function rescheduleTimeSuccess(response) {
  return { type: constants.rescheduleTimeSuccess, payload: response };
}
export function rescheduleTimeFail(response) {
  return { type: constants.rescheduleTimeFail, payload: response.data };
}

export function getAvailableClassHourByDateRequest() {
  return { type: constants.getAvailableClassHourByDateRequest };
}
export function getAvailableClassHourByDateSuccess(response) {
  return { type: constants.getAvailableClassHourByDateSuccess, payload: response.data };
}
export function getAvailableClassHourByDateFail(response) {
  return { type: constants.getAvailableClassHourByDateFail, payload: response.data };
}

export function getConsultationRequest() {
  return { type: constants.getConsultationRequest };
}
export function getConsultationSuccess(response) {
  return { type: constants.getConsultationSuccess, payload: response.data };
}
export function getConsultationFail(response) {
  return { type: constants.getConsultationFail, payload: response.data };
}

export function getAvailableClassScheduleRequest() {
  return { type: constants.getAvailableClassScheduleRequest };
}
export function getAvailableClassScheduleSuccess(response) {
  return { type: constants.getAvailableClassScheduleSuccess, payload: response.data };
}
export function getAvailableClassScheduleFail(response) {
  return { type: constants.getAvailableClassScheduleFail, payload: response.data };
}

export function cancelConsultationScheduleRequest() {
  return { type: constants.cancelConsultationScheduleRequest };
}
export function cancelConsultationScheduleSuccess(response) {
  return { type: constants.cancelConsultationScheduleSuccess, payload: response.data };
}
export function cancelConsultationScheduleFail(response) {
  return { type: constants.cancelConsultationScheduleFail, payload: response.data };
}

export function rescheduleConsultationRequest() {
  return { type: constants.rescheduleConsultationRequest };
}
export function rescheduleConsultationSuccess(response) {
  return { type: constants.rescheduleConsultationSuccess, payload: response.data };
}
export function rescheduleConsultationFail(response) {
  return { type: constants.rescheduleConsultationFail, payload: response.data };
}

export function getLabScheduleRequest() {
  return { type: constants.getLabScheduleRequest };
}
export function getLabScheduleSuccess(response) {
  return { type: constants.getLabScheduleSuccess, payload: response.data };
}
export function getLabScheduleFail(response) {
  return { type: constants.getLabScheduleFail, payload: response.data };
}
