import * as CampaignAction from '../../redux/actions/CampaignAction';
import Api from '../../middleware/FetchMiddleware';
import { baseUrl } from '../../environments/Environment';
import { HttpAction } from '../../helpers/HttpHelper';

export const getCampaignPagination = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(CampaignAction.getCampaignPaginationRequest());
    Api.callApi(`${baseUrl}private/pos/v2/campaign`, HttpAction.Get, params, {})
      .then(response => {
        dispatch(CampaignAction.getCampaignPaginationSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(CampaignAction.getCampaignPaginationFail(e));
        reject(e);
      });
  });
};

export const setDeleteCampaign = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(CampaignAction.setDeleteCampaignRequest());
    const mergeParam = `${baseUrl}private/pos/v2/campaign/${params}`;
    Api.callApi(mergeParam, HttpAction.Delete, {}, {})
      .then(response => {
        dispatch(CampaignAction.setDeleteCampaignSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(CampaignAction.setDeleteCampaignFail(e));
        reject(e);
      });
  });
};

export const getCampaignDetails = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(CampaignAction.getCampaignDetailsRequest());
    const mergeParam = `${baseUrl}private/pos/v2/campaign/${params}`;
    Api.callApi(mergeParam, HttpAction.Get, params, {})
      .then(response => {
        dispatch(CampaignAction.getCampaignDetailsSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(CampaignAction.getCampaignDetailsFail(e));
        reject(e);
      });
  });
};

export const setUpdateCampaign = (dispatch, params, id) => {
  return new Promise((resolve, reject) => {
    dispatch(CampaignAction.setUpdateCampaignRequest());
    Api.callApi(`${baseUrl}private/pos/v2/campaign/${id}`, HttpAction.Patch, {}, params)
      .then(response => {
        dispatch(CampaignAction.setUpdateCampaignSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(CampaignAction.setUpdateCampaignFail(e));
        reject(e);
      });
  });
};

export const setCreateCampaign = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(CampaignAction.setCreateCampaignRequest());
    Api.callApi(`${baseUrl}private/pos/v2/campaign`, HttpAction.Put, {}, params)
      .then(response => {
        dispatch(CampaignAction.setCreateCampaignSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(CampaignAction.setCreateCampaignFail(e));
        reject(e);
      });
  });
};

export const setPushNotifCampaignTest = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(CampaignAction.setPushNotifCampaignTestRequest());
    Api.callApi(`${baseUrl}private/pos/v2/campaign-tester/send`, HttpAction.Post, {}, params)
      .then(response => {
        dispatch(CampaignAction.setPushNotifCampaignTestSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(CampaignAction.setPushNotifCampaignTestFail(e));
        reject(e);
      });
  });
};
