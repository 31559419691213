import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
// Style
import './ButtonDashboardHeaderCardStyle.scss';
import StylesJs from './ButtonDashboardHeaderCardStyle';

const ButtonDashboardHeaderCard = props => {
  const {
    isActive,
    isImprove,
    labelText,
    labelTextPercentage,
    subLabelText,
    onClick,
    activeBackground,
  } = props;

  const classes = StylesJs();

  const modifyIconImprove = isImprove ? 'ic-ffi-arrow-up' : 'ic-ffi-arrow-down';

  return (
    <Grid
      className="container-button-dashboard-header-card container-main-card p-22"
      style={{ ...classes.mainImageContent, backgroundImage: `url(${activeBackground})` }}
      onClick={onClick}
    >
      <div className="flex-column">
        <div className="mb-8 justify-start">{isActive && <hr className="hr-active-card" />}</div>
        <span className="flex-row mb-8 justify-start">
          <label className="text-18 text-white wrapping-container second line wrapping-container-break">
            {labelText}
          </label>
          <div>
            <span className="flex-row align-center">
              <i className={`ml-8 ${modifyIconImprove} container-icon-prefix text-white size-14`} />
              <label className="ml-2 text-12 text-white">{labelTextPercentage}</label>
            </span>
          </div>
        </span>
        <span className="flex-row align-center">
          <label className="text-14 text-white">{subLabelText}</label>
          <i className={`ic-ffo-chevron-right container-icon-prefix text-white size-14`} />
        </span>
      </div>
    </Grid>
  );
};

ButtonDashboardHeaderCard.propTypes = {
  activeBackground: PropTypes.string,
  isActive: PropTypes.bool,
  isImprove: PropTypes.bool,
  labelText: PropTypes.string,
  labelTextPercentage: PropTypes.string,
  onClick: PropTypes.func,
  subLabelText: PropTypes.string,
};

export default ButtonDashboardHeaderCard;
