import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { ButtonGroup, Button } from '@material-ui/core';
// style
import stylesJs from './NavigationButtonGroupStyle';

const NavigationButtonGroup = props => {
  const { data, onClick, currentValue } = props;
  const [isActive, setActive] = React.useState(currentValue || data[0].value);
  const classes = stylesJs();

  React.useEffect(() => {
    const updateState = async () => {
      setActive(currentValue);
      if (currentValue === null || currentValue === undefined) {
        onClick(data[0].value);
      }
    };
    updateState();
  }, [currentValue, onClick, data]);

  const handelItemClick = value => {
    setActive(value);
    onClick(value);
  };

  return (
    <ButtonGroup color="primary" aria-label="outlined primary button group">
      {data.map((item, index) => (
        <Button
          key={index}
          onClick={() => handelItemClick(item.value)}
          className={clsx(classes.root, { [classes.isActiveButton]: isActive === item.value })}
        >
          {item.name}
        </Button>
      ))}
    </ButtonGroup>
  );
};

NavigationButtonGroup.propTypes = {
  currentValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  data: PropTypes.array,
  onClick: PropTypes.func,
};

export default NavigationButtonGroup;
