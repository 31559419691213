import React from 'react';
import { connect } from 'react-redux';
import { Grid, FormControl, FormLabel } from '@material-ui/core';
import { Form } from 'antd';
import PropTypes from 'prop-types';
import { compose } from 'redux';
// component
import {
  PrevNextStepperCount,
  UploadImage,
  LabelInput,
  TextInput,
  SelectInputMain,
  TextEditor,
  PickerInputDate,
  PickerInputTime,
} from '../../../../../components/Index';
// style
import '../../EventAddStyle.scss';

const statusOpt = [
  { value: 0, name: 'Draft' },
  { value: 10, name: 'Active' },
  { value: -1, name: 'Inactive' },
];

class StepCreateEvent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const {
      form: {
        banner,
        name,
        category,
        status,
        description,
        venue,
        startDate,
        endDate,
        startTime,
        endTime,
      },
      validation,
      categories,
      onChange,
    } = this.props;

    return (
      <Grid item lg md className="container-step-create-event">
        <Grid container spacing={2}>
          <Grid item xl={6} lg={6} md={6}>
            <FormControl component="fieldset" fullWidth margin="normal" style={{ marginTop: 0 }}>
              <div className="container-image-action">
                <UploadImage
                  defaultValue={banner}
                  maxSize={1}
                  validateStatus={!!validation.banner}
                  errorMessage={validation.banner}
                  onChange={item => onChange('banner', item)}
                />
              </div>
            </FormControl>
          </Grid>
          <Grid item xl={6} lg={6} md={6}>
            <div className="information-container">
              <div className="flex-row align-center" style={{ gap: '6px' }}>
                <div className="bullets" />
                <label className="text-12" style={{ color: 'rgba(0, 0, 0, 0.50)' }}>
                  This is the main poster, will be used in Activity Card
                </label>
              </div>
              <div className="flex-row align-center" style={{ gap: '6px' }}>
                <div className="bullets" />
                <label className="text-12" style={{ color: 'rgba(0, 0, 0, 0.50)' }}>
                  Will also appear in succesful event registration email
                </label>
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xl={6} lg={6} md={6}>
            <FormControl component="fieldset" fullWidth margin="normal">
              <LabelInput labelText="Event Name" isRequired />
              <TextInput
                placeHolderText="Enter Event Name"
                currentValue={name}
                size="md"
                isError={!!validation.name}
                errorMessage={validation.name}
                onChange={value => onChange('name', value)}
              />
            </FormControl>
          </Grid>
          <Grid item xl={6} lg={6} md={6}>
            <Grid container spacing={2}>
              <Grid item xl={6} lg={6} md={6}>
                <FormControl component="fieldset" fullWidth margin="normal">
                  <FormLabel
                    component="label"
                    className="container-label-input form-label"
                    required
                  >
                    Category
                  </FormLabel>
                  <SelectInputMain
                    placeholder="Select Category"
                    options={categories}
                    currentValue={category}
                    validateStatus={!!validation.category}
                    errorMessage={validation.category}
                    onChange={value => onChange('category', value)}
                  />
                </FormControl>
              </Grid>
              <Grid item xl={6} lg={6} md={6}>
                <FormControl component="fieldset" fullWidth margin="normal">
                  <FormLabel
                    component="label"
                    className="container-label-input form-label"
                    required
                  >
                    Status
                  </FormLabel>
                  <SelectInputMain
                    placeholder="Select Status"
                    options={statusOpt}
                    currentValue={status}
                    validateStatus={!!validation.status}
                    errorMessage={validation.status}
                    onChange={value => onChange('status', value)}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xl={6} lg={6} md={6}>
            <FormControl component="fieldset" fullWidth margin="normal">
              <LabelInput labelText="Description" isRequired />
              <TextEditor
                currentValue={description}
                validateStatus={!!validation.description}
                errorMessage={validation.description}
                onChange={content => onChange('description', content)}
              />
            </FormControl>
          </Grid>
          <Grid item xl={6} lg={6} md={6}>
            <Grid container spacing={2}>
              <Grid item xl={12} lg={12} md={12}>
                <FormControl component="fieldset" fullWidth margin="normal">
                  <LabelInput labelText="Venue" isRequired />
                  <TextInput
                    placeHolderText="Enter Venue"
                    currentValue={venue}
                    size="md"
                    isError={!!validation.venue}
                    errorMessage={validation.venue}
                    onChange={value => onChange('venue', value)}
                  />
                </FormControl>
              </Grid>
              <Grid item xl={12} lg={12} md={12}>
                <Grid container spacing={2}>
                  <Grid item lg md>
                    <FormControl component="fieldset" fullWidth margin="normal">
                      <LabelInput labelText="Start Date" isRequired />
                      <PickerInputDate
                        customIcon="ic-ffo-date-pick"
                        dateFormat="dd/MM/yyyy"
                        defaultValue={startDate}
                        minDate={null}
                        toolbar={false}
                        onChange={value => onChange('startDate', value)}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item lg md>
                    <FormControl component="fieldset" fullWidth margin="normal">
                      <LabelInput labelText="End Date" isRequired />
                      <PickerInputDate
                        customIcon="ic-ffo-date-pick"
                        dateFormat="dd/MM/yyyy"
                        defaultValue={endDate}
                        minDate={null}
                        toolbar={false}
                        onChange={value => onChange('endDate', value)}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xl={12} lg={12} md={12}>
                <Grid container spacing={2}>
                  <Grid item lg md>
                    <FormControl component="fieldset" fullWidth margin="normal">
                      <FormLabel component="label" className="text-12 field-title">
                        Start Time
                      </FormLabel>
                      <Form.Item>
                        <PickerInputTime
                          customIcon="ic-ffo-timer"
                          defaultValue={startTime}
                          onChange={value => onChange('startTime', value)}
                        />
                      </Form.Item>
                    </FormControl>
                  </Grid>
                  <Grid item lg md>
                    <FormControl component="fieldset" fullWidth margin="normal">
                      <FormLabel component="label" className="text-12 field-title">
                        End Time
                      </FormLabel>
                      <Form.Item>
                        <PickerInputTime
                          customIcon="ic-ffo-timer"
                          defaultValue={endTime}
                          onChange={value => onChange('endTime', value)}
                        />
                      </Form.Item>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

StepCreateEvent.propTypes = {
  categories: PropTypes.array,
  form: PropTypes.object,
  onChange: PropTypes.func,
  validation: PropTypes.object,
};

const shell = compose(connect());

export default shell(PrevNextStepperCount(StepCreateEvent));
