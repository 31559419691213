import { constants } from '../reducers/UserManagementReducer';

export function getListSuccess(response) {
  return { type: constants.getListSuccess, payload: response };
}
export function getListFail(response) {
  return { type: constants.getListFail, payload: response.data };
}

export function validateEmailOrPhoneRequest() {
  return { type: constants.validateEmailOrPhoneRequest };
}

export function validateEmailOrPhoneSuccess(response) {
  return { type: constants.validateEmailOrPhoneSuccess, payload: response };
}

export function validateEmailOrPhoneFail(response) {
  return { type: constants.getListFail, payload: response.data };
}

export function createUserRequest() {
  return { type: constants.createUserRequest };
}

export function createUserSuccess(response) {
  return { type: constants.createUserSuccess, payload: response };
}

export function createUserFail(response) {
  return { type: constants.updateUserFail, payload: response.data };
}
export function updateUserRequest() {
  return { type: constants.updateUserRequest };
}

export function updateUserSuccess(response) {
  return { type: constants.updateUserSuccess, payload: response };
}

export function updateUserFail(response) {
  return { type: constants.updateUserFail, payload: response.data };
}

export function deleteMultipleRequest() {
  return { type: constants.deleteMultipleRequest };
}

export function deleteMultipleSuccess(response) {
  return { type: constants.deleteMultipleSuccess, payload: response };
}

export function deleteMultipleFail(response) {
  return { type: constants.deleteMultipleFail, payload: response.data };
}

export function deleteUserRequest() {
  return { type: constants.deleteUserRequest };
}

export function deleteUserSuccess(response) {
  return { type: constants.deleteUserSuccess, payload: response };
}

export function deleteUserFail(response) {
  return { type: constants.getListFail, payload: response.data };
}

export function setUpdateUserStatusRequest() {
  return { type: constants.setUpdateUserStatusRequest };
}

export function setUpdateUserStatusSuccess(response) {
  return { type: constants.setUpdateUserStatusSuccess, payload: response };
}

export function setUpdateUserStatusFail(response) {
  return { type: constants.setUpdateUserStatusFail, payload: response.data };
}

export function sendVerifyOtpRequest() {
  return { type: constants.sendVerifyOtpRequest };
}

export function sendVerifyOtpSuccess(response) {
  return { type: constants.sendVerifyOtpSuccess, payload: response };
}

export function sendVerifyOtpFail(response) {
  return { type: constants.sendVerifyOtpFail, payload: response.data };
}

export function getRolePaginationRequest() {
  return { type: constants.getRolePaginationRequest };
}
export function getRolePaginationSuccess(response) {
  return { type: constants.getRolePaginationSuccess, payload: response };
}
export function getRolePaginationFail(response) {
  return { type: constants.getRolePaginationFail, payload: response.data };
}

export function createRoleRequest() {
  return { type: constants.createRoleRequest };
}
export function createRoleSuccess(response) {
  return { type: constants.createRoleSuccess, payload: response };
}
export function createRoleFail(response) {
  return { type: constants.createRoleFail, payload: response.data };
}

export function deleteRoleRequest() {
  return { type: constants.deleteRoleRequest };
}
export function deleteRoleSuccess(response) {
  return { type: constants.deleteRoleSuccess, payload: response };
}
export function deleteRoleFail(response) {
  return { type: constants.deleteRoleFail, payload: response.data };
}

export function updateRoleRequest() {
  return { type: constants.updateRoleRequest };
}
export function updateRoleSuccess(response) {
  return { type: constants.updateRoleSuccess, payload: response };
}
export function updateRoleFail(response) {
  return { type: constants.updateRoleFail, payload: response.data };
}

export function getUserDetailsByIdRequest() {
  return { type: constants.getUserDetailsByIdRequest };
}
export function getUserDetailsByIdSuccess(response) {
  return { type: constants.getUserDetailsByIdSuccess, payload: response };
}
export function getUserDetailsByIdFail(response) {
  return { type: constants.getUserDetailsByIdFail, payload: response.data };
}

export function getMembershipPaginationRequest() {
  return { type: constants.getMembershipPaginationRequest };
}
export function getMembershipPaginationSuccess(response) {
  return { type: constants.getMembershipPaginationSuccess, payload: response };
}
export function getMembershipPaginationFail(response) {
  return { type: constants.getMembershipPaginationFail, payload: response.data };
}

export function getMembershipBundleDetailRequest() {
  return { type: constants.getMembershipBundleDetailRequest };
}
export function getMembershipBundleDetailSuccess(response) {
  return { type: constants.getMembershipBundleDetailSuccess, payload: response };
}
export function getMembershipBundleDetailFail(response) {
  return { type: constants.getMembershipBundleDetailFail, payload: response.data };
}

export function getUserActivityPaginationRequest() {
  return { type: constants.getUserActivityPaginationRequest };
}
export function getUserActivityPaginationSuccess(response) {
  return { type: constants.getUserActivityPaginationSuccess, payload: response };
}
export function getUserActivityPaginationFail(response) {
  return { type: constants.getUserActivityPaginationFail, payload: response.data };
}

export function getUserActivityDetailRequest() {
  return { type: constants.getUserActivityDetailRequest };
}
export function getUserActivityDetailSuccess(response) {
  return { type: constants.getUserActivityDetailSuccess, payload: response };
}
export function getUserActivityDetailFail(response) {
  return { type: constants.getUserActivityDetailFail, payload: response.data };
}

export function createPermissionRequest() {
  return { type: constants.createPermissionRequest };
}
export function createPermissionSuccess(response) {
  return { type: constants.createPermissionSuccess, payload: response };
}
export function createPermissionFail(response) {
  return { type: constants.createPermissionFail, payload: response.data };
}

export function deletePermissionRoleRequest() {
  return { type: constants.deletePermissionRoleRequest };
}
export function deletePermissionRoleSuccess(response) {
  return { type: constants.deletePermissionRoleSuccess, payload: response };
}
export function deletePermissionRoleFail(response) {
  return { type: constants.deletePermissionRoleFail, payload: response.data };
}
export function getPermissionGroupByRoleRequest() {
  return { type: constants.getPermissionGroupByRoleRequest };
}
export function getPermissionGroupByRoleSuccess(response) {
  return { type: constants.getPermissionGroupByRoleSuccess, payload: response };
}
export function getPermissionGroupByRoleFail(response) {
  return { type: constants.getPermissionGroupByRoleFail, payload: response.data };
}
export function setPermissionToRoleRequest() {
  return { type: constants.setPermissionToRoleRequest };
}
export function setPermissionToRoleSuccess(response) {
  return { type: constants.setPermissionToRoleSuccess, payload: response };
}
export function setPermissionToRoleFail(response) {
  return { type: constants.setPermissionToRoleFail, payload: response.data };
}
export function setEditPermissionToRoleRequest() {
  return { type: constants.setEditPermissionToRoleRequest };
}
export function setEditPermissionToRoleSuccess(response) {
  return { type: constants.setEditPermissionToRoleSuccess, payload: response };
}
export function setEditPermissionToRoleFail(response) {
  return { type: constants.setEditPermissionToRoleFail, payload: response.data };
}

export function getMembershipDetailsRequest() {
  return { type: constants.getMembershipDetailsRequest };
}
export function getMembershipDetailsSuccess(response) {
  return { type: constants.getMembershipDetailsSuccess, payload: response };
}
export function getMembershipDetailsFail(response) {
  return { type: constants.getMembershipDetailsFail, payload: response.data };
}

export function getMembershipPurchasedPriceRequest() {
  return { type: constants.getMembershipPurchasedPriceRequest };
}
export function getMembershipPurchasedPriceSuccess(response) {
  return { type: constants.getMembershipPurchasedPriceSuccess, payload: response };
}
export function getMembershipPurchasedPriceFail(response) {
  return { type: constants.getMembershipPurchasedPriceFail, payload: response.data };
}

export function getMemberDetailsRequest() {
  return { type: constants.getMemberDetailsRequest };
}
export function getMemberDetailsSuccess(response) {
  return { type: constants.getMemberDetailsSuccess, payload: response };
}
export function getMemberDetailsFail(response) {
  return { type: constants.getMemberDetailsFail, payload: response.data };
}

export function requestFreezemembershipRequest() {
  return { type: constants.requestFreezemembershipRequest };
}
export function requestFreezemembershipSuccess(response) {
  return { type: constants.requestFreezemembershipSuccess, payload: response };
}
export function requestFreezemembershipFail(response) {
  return { type: constants.requestFreezemembershipFail, payload: response.data };
}