import * as ReportAction from '../../redux/actions/ReportAction';
import Api from '../../middleware/FetchMiddleware';
import { baseUrl } from '../../environments/Environment';
import { HttpAction } from '../../helpers/HttpHelper';

// #region conduct pt
export const getChartConductPT = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(ReportAction.getChartConductPTRequest());
    Api.callApi(
      `${baseUrl}private/pos/v2/reports/conduct-pt/appointment-statistic`,
      HttpAction.Get,
      params,
      {},
    )
      .then(response => {
        dispatch(ReportAction.getChartConductPTSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(ReportAction.getChartConductPTFail(e));
        reject(e);
      });
  });
};

export const getInfoConducted = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(ReportAction.getInfoConductedRequest());
    Api.callApi(
      `${baseUrl}private/pos/v2/reports/conduct-pt/info-conducted`,
      HttpAction.Get,
      params,
      {},
    )
      .then(response => {
        dispatch(ReportAction.getInfoConductedSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(ReportAction.getInfoConductedFail(e));
        reject(e);
      });
  });
};

export const getTotalAppointmentConductPT = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(ReportAction.getTotalAppointmentConductPTRequest());
    Api.callApi(
      `${baseUrl}private/pos/v2/reports/conduct-pt/total-appointment`,
      HttpAction.Get,
      params,
      {},
    )
      .then(response => {
        dispatch(ReportAction.getTotalAppointmentConductPTSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(ReportAction.getTotalAppointmentConductPTFail(e));
        reject(e);
      });
  });
};

export const getTopConductPT = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(ReportAction.getTopConductPTRequest());
    Api.callApi(
      `${baseUrl}private/pos/v2/reports/conduct-pt/top-conduct`,
      HttpAction.Get,
      params,
      {},
    )
      .then(response => {
        dispatch(ReportAction.getTopConductPTSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(ReportAction.getTopConductPTFail(e));
        reject(e);
      });
  });
};

export const getConductReportPagination = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(ReportAction.getConductReportPaginationRequest());
    Api.callApi(`${baseUrl}private/pos/v2/reports/conduct-pt/list?`, HttpAction.Get, params, {})
      .then(response => {
        dispatch(ReportAction.getConductReportPaginationSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(ReportAction.getConductReportPaginationFail(e));
        reject(e);
      });
  });
};

export const getConductReportExport = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(ReportAction.getConductReportExportRequest());
    Api.callApi(
      `${baseUrl}private/pos/v2/reports/conduct-pt/list/download?`,
      HttpAction.Get,
      params,
      {},
    )
      .then(response => {
        dispatch(ReportAction.getConductReportExportSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(ReportAction.getConductReportExportFail(e));
        reject(e);
      });
  });
};

export const getConductReportDetailByTrainerId = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(ReportAction.getConductReportDetailByTrainerIdRequest());
    Api.callApi(`${baseUrl}private/pos/v2/reports/conduct-pt/detail?`, HttpAction.Get, params, {})
      .then(response => {
        dispatch(ReportAction.getConductReportDetailByTrainerIdSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(ReportAction.getConductReportDetailByTrainerIdFail(e));
        reject(e);
      });
  });
};

export const getConductProductByTrainerId = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(ReportAction.getConductProductByTrainerIdRequest());
    Api.callApi(`${baseUrl}private/pos/v2/reports/conduct-pt/product?`, HttpAction.Get, params, {})
      .then(response => {
        dispatch(ReportAction.getConductProductByTrainerIdSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(ReportAction.getConductProductByTrainerIdFail(e));
        reject(e);
      });
  });
};

export const getConductParticipantByTrainerId = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(ReportAction.getConductParticipantByTrainerIdRequest());
    Api.callApi(
      `${baseUrl}private/pos/v2/reports/conduct-pt/participants`,
      HttpAction.Get,
      params,
      {},
    )
      .then(response => {
        dispatch(ReportAction.getConductParticipantByTrainerIdSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(ReportAction.getConductParticipantByTrainerIdFail(e));
        reject(e);
      });
  });
};

export const getConductParticipantByTrainerIdExport = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(ReportAction.getConductParticipantByTrainerIdExportRequest());
    Api.callApi(
      `${baseUrl}private/pos/v2/reports/conduct-pt/participants/download`,
      HttpAction.Get,
      params,
      {},
    )
      .then(response => {
        dispatch(ReportAction.getConductParticipantByTrainerIdExportSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(ReportAction.getConductParticipantByTrainerIdExportFail(e));
        reject(e);
      });
  });
};

export const getTotalAppointmentConductPTbyMerchantBranch = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(ReportAction.getTotalAppointmentConductPTbyMerchantBranchRequest());
    Api.callApi(
      `${baseUrl}private/pos/v2/reports/conduct-pt/total-appointment`,
      HttpAction.Get,
      params,
      {},
    )
      .then(response => {
        dispatch(ReportAction.getTotalAppointmentConductPTbyMerchantBranchSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(ReportAction.getTotalAppointmentConductPTbyMerchantBranchFail(e));
        reject(e);
      });
  });
};
// #endregion

// #region check in
export const getChartDailyCheckIn = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(ReportAction.getChartDailyCheckInRequest());
    Api.callApi(
      `${baseUrl}private/pos/v2/reports/daily-checkin/statistic`,
      HttpAction.Get,
      params,
      {},
    )
      .then(response => {
        dispatch(ReportAction.getChartDailyCheckInSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(ReportAction.getChartDailyCheckInFail(e));
        reject(e);
      });
  });
};

export const getInfoDailyCheckIn = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(ReportAction.getInfoDailyCheckInRequest());
    Api.callApi(`${baseUrl}private/pos/v2/reports/daily-checkin/info`, HttpAction.Get, params, {})
      .then(response => {
        dispatch(ReportAction.getInfoDailyCheckInSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(ReportAction.getInfoDailyCheckInFail(e));
        reject(e);
      });
  });
};

export const getTotalDailyCheckIn = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(ReportAction.getTotalDailyCheckInRequest());
    Api.callApi(
      `${baseUrl}private/pos/v2/reports/daily-checkin/total-checkin`,
      HttpAction.Get,
      params,
      {},
    )
      .then(response => {
        dispatch(ReportAction.getTotalDailyCheckInSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(ReportAction.getTotalDailyCheckInFail(e));
        reject(e);
      });
  });
};

export const getTopDailyCheckIn = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(ReportAction.getTopDailyCheckInRequest());
    Api.callApi(
      `${baseUrl}private/pos/v2/reports/daily-checkin/top-checkin`,
      HttpAction.Get,
      params,
      {},
    )
      .then(response => {
        dispatch(ReportAction.getTopDailyCheckInSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(ReportAction.getTopDailyCheckInFail(e));
        reject(e);
      });
  });
};

export const getDailyCheckInReportPagination = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(ReportAction.getDailyCheckInReportPaginationRequest());
    Api.callApi(`${baseUrl}private/pos/v2/reports/daily-checkin/list?`, HttpAction.Get, params, {})
      .then(response => {
        dispatch(ReportAction.getDailyCheckInReportPaginationSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(ReportAction.getDailyCheckInReportPaginationFail(e));
        reject(e);
      });
  });
};

export const getDailyCheckInReportExport = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(ReportAction.getDailyCheckInReportExportRequest());
    Api.callApi(
      `${baseUrl}private/pos/v2/reports/daily-checkin/list/download?`,
      HttpAction.Get,
      params,
      {},
    )
      .then(response => {
        dispatch(ReportAction.getDailyCheckInReportExportSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(ReportAction.getDailyCheckInReportExportFail(e));
        reject(e);
      });
  });
};

// #endregion
