import React from 'react';
import { Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
// style
import './SkeletonScheduleDetailsStyle.scss';
// import stylesJs from './SkeletonScheduleDetailsStyle';

const SkeletonScheduleDetails = () => {
  // const classes = stylesJs();

  return (
    <div className="row skeleton-container">
      <Grid container direction={'column'}>
        <Grid item lg>
          <Grid container direction={'row'}>
            <Grid item lg className="item-container">
              <Skeleton
                variant="rect"
                width="100%"
                animation="wave"
                height={24}
                className="skeletonRounded"
              />
            </Grid>
            <Grid item lg={1} className="item-container">
              <Skeleton
                variant="rect"
                width="100%"
                animation="wave"
                height={24}
                className="skeletonRounded"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg>
          <Grid container direction={'column'} className="item-container">
            <Grid item lg className="item-container">
              <Skeleton
                variant="rect"
                width="100%"
                animation="wave"
                height={42}
                className="skeletonRounded"
              />
            </Grid>
            <Grid item lg>
              <div className="divider-hr" />
            </Grid>
            <Grid item lg className="item-container">
              <Skeleton
                variant="rect"
                width="100%"
                animation="wave"
                height={42}
                className="skeletonRounded"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg className="item-container">
          <Skeleton
            variant="rect"
            width="100%"
            animation="wave"
            height={42}
            className="skeletonRounded"
          />
        </Grid>
        <Grid container direction={'column'} className="item-container">
          <Grid item lg>
            <Grid container direction={'row'}>
              <Grid item lg className="item-container">
                <Skeleton
                  variant="rect"
                  width="100%"
                  animation="wave"
                  height={24}
                  className="skeletonRounded"
                />
              </Grid>
              <Grid item lg={3} className="item-container">
                <Skeleton
                  variant="rect"
                  width="100%"
                  animation="wave"
                  height={24}
                  className="skeletonRounded"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg>
            <div className="divider-hr" />
          </Grid>
          <Grid item lg>
            <Grid container direction={'row'} className="item-container">
              <Grid item lg className="item-container">
                <Skeleton
                  variant="rect"
                  width="100%"
                  animation="wave"
                  height={24}
                  className="skeletonRounded"
                />
              </Grid>
              <Grid item lg={3} className="item-container">
                <Skeleton
                  variant="rect"
                  width="100%"
                  animation="wave"
                  height={24}
                  className="skeletonRounded"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default SkeletonScheduleDetails;
