import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
// Style
import './ButtonActionFooterHandoverStyle.scss';
// Component
import { ButtonMain, PrevNextStepperCount } from '../../../../../components/Index';

const ButtonActionFooterHandover = props => {
  const { customElementProps, onButtonClickCancel, onButtonClickSubmit, isLoadingCreate } = props;

  return (
    <Grid container className="container-card-button-action">
      <Grid item lg md>
        <Grid container justify="space-between" alignItems="center">
          <Grid item lg={3} md={4}>
            {customElementProps}
          </Grid>
          <Grid item>
            <div className="container-button-action">
              <div className="container-button">
                <ButtonMain
                  type="ghost"
                  size="xl"
                  labelText="Cancel"
                  onClick={onButtonClickCancel}
                />
              </div>
              <div className="container-button">
                <ButtonMain type="primary" size="xl" labelText="Submit" onClick={onButtonClickSubmit} isLoading={isLoadingCreate} />
              </div>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

ButtonActionFooterHandover.propTypes = {
  customElementProps: PropTypes.element,
  isLoadingCreate: PropTypes.bool,
  onButtonClickCancel: PropTypes.func,
  onButtonClickSubmit: PropTypes.func,
};

export default PrevNextStepperCount(ButtonActionFooterHandover);
