import * as masterAction from '../../redux/actions/ProductCategoryAction';
import Api from '../../middleware/FetchMiddleware';
import { baseUrl } from '../../environments/Environment';
import { HttpAction } from '../../helpers/Index';

export const getListProductCategoryPagination = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(masterAction.getListProductCategoryPaginationRequest());
    Api.callApi(`${baseUrl}private/pos/v2/category/list?`, HttpAction.Get, params, {})
      .then(response => {
        dispatch(masterAction.getListProductCategoryPaginationSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(masterAction.getListProductCategoryPaginationFail(e));
        reject(e);
      });
  });
};

export const setUpdateProductCategory = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(masterAction.updateProductCategoryRequest());
    Api.callApi(`${baseUrl}private/pos/v2/category/order`, HttpAction.Put, {}, params)
      .then(response => {
        dispatch(masterAction.updateProductCategorySuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(masterAction.updateProductCategoryFail(e));
        reject(e);
      });
  });
};

export const setDeleteProductCategory = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(masterAction.deleteProductCategoryRequest());
    Api.callApi(`${baseUrl}private/pos/v2/category`, HttpAction.Delete, {}, params)
      .then(response => {
        dispatch(masterAction.deleteProductCategorySuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(masterAction.deleteProductCategoryFail(e));
        reject(e);
      });
  });
};
