import React from 'react';
import PropTypes from 'prop-types';
import { Grid, FormControl, FormLabel } from '@material-ui/core';
// import _ from 'lodash';
// Style
import './CartItemBookingStyle.scss';
// Component
import {
  GridRowTableFourColumn,
  GridRowTableOneColumn,
} from '../../../../../../../../components/Index';
// helper
import { CommonHelper, promoTypeCode, OrderHelper } from '../../../../../../../../helpers/Index';
// assets
import { Images } from '../../../../../../../../assets/Index';

const CartItemBooking = props => {
  const {
    form: { promoCode, isMembershipSpecial },
    isValidPromoCode,
    orderData: { promoCodeDetails },
  } = props;

  const items = props.form.step_booking_product_details.selectedProduct;

  // discount
  const promoCodeInformation = `Promo Code ( ${promoCode} )`;
  let totalItem = 0;
  let grandTotal = OrderHelper.getGrandTotal(items);

  let discountAmount = 0;

  if (isValidPromoCode) {
    grandTotal = promoCodeDetails.totalPriceAfterReward;
    discountAmount = promoCodeDetails.cashback_amount;
  }

  const showDiscount =
    (isValidPromoCode && promoCodeDetails.promo_code_cashback_type_id === promoTypeCode.Discount) ||
    discountAmount !== 0;

  const renderMembershipCard = () => {
    return isMembershipSpecial ? (
      <span className="container-special-label flex-row p-8 membership">
        <img src={Images.membershipUsage} alt="membership_logo" />
        <label className="text-14 text-semi-bold ml-8">Membership Package Applied</label>
      </span>
    ) : null;
  };

  return (
    <FormControl
      component="fieldset"
      fullWidth
      margin="normal"
      className="container-booking-order-cart-item"
    >
      <FormLabel component="label" className="text-16 filed-title">
        Order Summary
      </FormLabel>

      <div className="flex-column">
        <GridRowTableFourColumn
          customElementColumnFirst={<label className="text-14 header-title">Product Name</label>}
          customElementColumnSecond={
            <div className="text-center">
              <label className="text-14 header-title">Price</label>
            </div>
          }
          customElementColumnThird={
            <div className="text-center">
              <label className="text-14 header-title">Qty</label>
            </div>
          }
          customElementColumnFourth={
            <div className="text-right">
              <label className="text-14 header-title">Total Price</label>
            </div>
          }
          columnFirst={5}
          columnSecond={2}
          columnThird={2}
          columnFourth={3}
        />
        <div className="scroll-container">
          <Grid container direction="column">
            {items.map((item, index) => {
              totalItem += item.qty;
              const price = item.price || item.normal_price;
              const priceTotal = item.qty * parseInt(price, 10);
              const priceItem = CommonHelper.formatCurrency(price);

              return (
                <GridRowTableFourColumn
                  key={index}
                  customElementColumnFirst={
                    <label className="text-14 row-item-title">
                      {item.name || item.product_name}
                    </label>
                  }
                  customElementColumnSecond={
                    <div className="text-center">
                      <label className="text-14">IDR {priceItem}</label>
                    </div>
                  }
                  customElementColumnThird={
                    <div className="text-center">
                      <label className="text-14">{item.qty}</label>
                    </div>
                  }
                  customElementColumnFourth={
                    <div className="text-right">
                      <label className="text-14">
                        IDR {CommonHelper.formatCurrency(priceTotal)}
                      </label>
                    </div>
                  }
                  columnFirst={5}
                  columnSecond={2}
                  columnThird={2}
                  columnFourth={3}
                />
              );
            })}
          </Grid>
        </div>
        {showDiscount && (
          <GridRowTableOneColumn
            customElementInitial={
              <div className="flex-column">
                <label className="text-14 row-item-title">Discount</label>
                <label className="text-10 ">{promoCodeInformation}</label>
              </div>
            }
            customElementColumn={
              <div className="text-right">
                <label className="text-14 discount">
                  IDR {CommonHelper.formatCurrency(discountAmount)}
                </label>
              </div>
            }
            columnLeft={10}
            columnRight={2}
          />
        )}
        <GridRowTableOneColumn
          customElementInitial={
            <label className="text-16 row-item-title">Total Item(s) : {totalItem}</label>
          }
          customElementColumn={
            <div className="text-right">
              <label className="text-16 row-item-title">
                Grand Total &nbsp; &nbsp; &nbsp;{' '}
                {grandTotal !== 0 ? `IDR ${CommonHelper.formatCurrency(grandTotal)}` : 'Free'}
              </label>
            </div>
          }
          columnLeft={7}
          columnRight={5}
        />
        {renderMembershipCard()}
      </div>
    </FormControl>
  );
};

CartItemBooking.propTypes = {
  form: PropTypes.object,
  isValidPromoCode: PropTypes.bool,
  orderData: PropTypes.object,
};

export default CartItemBooking;
