/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, FormControl, FormLabel } from '@material-ui/core';
import _ from 'lodash';
// component
import {
  TextInput,
  SelectInputGeneral,
  PickerInputDate,
} from '../../../../../../../../components/Index';
// helper
import { CommonHelper, MasterDataHelper } from '../../../../../../../../helpers/Index';

const currentDate = CommonHelper.currentDate('YYYY-MM-DD');
const resultOption = MasterDataHelper.resultStatus;

const initialForm = {
  test_method: null,
  test_result: null,
  location: '',
  date: currentDate,
};

const CovidInformationForm = ({ list, latestCovidTest, onParameterUpdate }) => {
  const [form, setForm] = useState(initialForm);

  useEffect(() => {
    if (!_.isEmpty(latestCovidTest)) {
      const method = list.find(item => {
        return item.id === latestCovidTest.last_checkup.medical_record_type;
      });
      const result =
        latestCovidTest.last_checkup.result === 'negatif'
          ? resultOption[1].value
          : resultOption[0].value;
      const formattedDate = CommonHelper.dateTimeParseNewFormat(
        latestCovidTest.last_checkup.date,
        'YYYY-MM-DD',
      );
      setForm(oldForm => ({
        ...oldForm,
        test_method: method.id,
        test_result: result,
        location: latestCovidTest.last_checkup.location,
        date: formattedDate,
      }));
    }
  }, [latestCovidTest]);

  useEffect(() => {
    const payload = {
      ...form,
    };
    onParameterUpdate(payload, 'covid_test');
  }, [form]);

  const onHandleChangeForm = (value, key) => {
    setForm(oldForm => ({
      ...oldForm,
      [key]: value,
    }));
  };

  return (
    <>
      <Grid item lg={12} md={12} className="mb-4">
        <label className="text-12 text-bold text-rolling-stone">HASIL TES COVID-19 TERAKHIR</label>
      </Grid>
      <Grid item lg={12} md={12}>
        <Grid container spacing={6}>
          <Grid item lg={6} md={6}>
            <Grid item lg={12} md={12}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <FormLabel component="label" className="text-12 filed-title">
                  Metode
                </FormLabel>
                <div className="container-remove-margin">
                  <SelectInputGeneral
                    placeHolder="Pilih Metode Tes COVID-19"
                    currentValue={form.test_method}
                    dataList={list}
                    primaryKey="id"
                    includeAllData
                    onChange={value => onHandleChangeForm(value, 'test_method')}
                  />
                </div>
              </FormControl>
            </Grid>
            <Grid item lg={12} md={12}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <FormLabel component="label" className="text-12 filed-title">
                  Lokasi
                </FormLabel>
                <TextInput
                  placeHolderText="eg. FITCO Medic, Gedung Panin Sudirman"
                  size="md"
                  onChange={value => {
                    onHandleChangeForm(value, 'location');
                  }}
                  currentValue={form.location}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid item lg={6} md={6}>
            <Grid item lg={12} md={12}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <FormLabel component="label" className="text-12 filed-title">
                  Hasil
                </FormLabel>
                <div className="container-remove-margin">
                  <SelectInputGeneral
                    placeHolder="Pilih Hasil Tes COVID-19"
                    currentValue={form.test_result}
                    dataList={resultOption}
                    primaryKey="value"
                    includeAllData
                    onChange={value => onHandleChangeForm(value, 'test_result')}
                  />
                </div>
              </FormControl>
            </Grid>
            <Grid item lg={12} md={12}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <FormLabel component="label" className="text-12 filed-title">
                  Tanggal
                </FormLabel>
                <PickerInputDate
                  customIcon="ic-ffo-date-pick"
                  dateFormat="dd-MM-yyyy"
                  maxDate={currentDate}
                  defaultValue={form.date}
                  onChange={value => onHandleChangeForm(value, 'date')}
                  toolbar={false}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

CovidInformationForm.propTypes = {
  latestCovidTest: PropTypes.object,
  list: PropTypes.array,
  onParameterUpdate: PropTypes.func,
};

export default CovidInformationForm;
