import React from 'react';
import { Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

const SkeletonModalUserDetailActivityTopUp = () => {
  return (
    <Grid container direction={'column'} className="flex-wrap-unset">
      <Grid item lg md className="section-header-modal-activity">
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          className="flex-wrap-unset"
        >
          <Grid item lg={1} md={1}>
            <Skeleton
              variant="rect"
              width="100%"
              height={40}
              animation="wave"
              className="skeletonRounded"
            />
          </Grid>
          <Grid item lg={10} md={10} className="wrapping-container second line">
            <Skeleton
              variant="rect"
              width="100%"
              height={40}
              animation="wave"
              className="skeletonRounded"
            />
          </Grid>
          <Grid item lg={1} md={1}>
            <Grid container justify="flex-end">
              <Grid item>
                <Skeleton
                  variant="rect"
                  width="100%"
                  height={40}
                  animation="wave"
                  className="skeletonRounded"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item className="section-body-modal-activity">
        <Grid item lg md>
          <Grid
            container
            direction="row"
            justify="flex-start"
            className="flex-wrap-unset container-details container-payment"
          >
            <Grid item lg={9} md={9}>
              <Grid container direction="row">
                <Grid item lg={4} md={4}>
                  <Skeleton
                    variant="rect"
                    width="100%"
                    height={40}
                    animation="wave"
                    className="skeletonRounded"
                  />
                </Grid>
                <Grid item lg={4} md={4}>
                  <Skeleton
                    variant="rect"
                    width="100%"
                    height={40}
                    animation="wave"
                    className="skeletonRounded"
                  />
                </Grid>
                <Grid item lg={4} md={4}>
                  <Skeleton
                    variant="rect"
                    width="100%"
                    height={40}
                    animation="wave"
                    className="skeletonRounded"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={3} md={3}>
              <Grid container justify="flex-end">
                <Grid item lg={6} md={6}>
                  <Grid container direction="column" alignItems="center">
                    <Skeleton
                      variant="rect"
                      width="100%"
                      height={40}
                      animation="wave"
                      className="skeletonRounded"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg md>
          <Grid
            container
            direction="column"
            className="flex-wrap-unset container-details container-product"
          >
            <Grid item lg md>
              <Grid container direction="row">
                <Grid item lg={8} md={8}>
                  <Skeleton
                    variant="rect"
                    width="100%"
                    height={20}
                    animation="wave"
                    className="skeletonRounded"
                  />
                </Grid>
              </Grid>
              <Grid item lg={4} md={4}>
                <Grid container justify="flex-end">
                  <Grid item>
                    <Skeleton
                      variant="rect"
                      width="100%"
                      height={20}
                      animation="wave"
                      className="skeletonRounded"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg md className="container-details container-payed">
          <Grid container direction="row" justify="space-between">
            <Grid item lg={4} md={4}>
              <Skeleton
                variant="rect"
                width="100%"
                height={20}
                animation="wave"
                className="skeletonRounded"
              />
            </Grid>
            <Grid item lg={4} md={4}>
              <Skeleton
                variant="rect"
                width="100%"
                height={20}
                animation="wave"
                className="skeletonRounded"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item className="section-footer-modal-activity" />
    </Grid>
  );
};

export default SkeletonModalUserDetailActivityTopUp;
