import { constants } from '../reducers/ReportReducer';

// #region conduct pt
export function setDefaultReportExport() {
  return { type: constants.setDefaultReportExport };
}

export function getChartConductPTRequest() {
  return { type: constants.getChartConductPTRequest };
}
export function getChartConductPTSuccess(response) {
  return { type: constants.getChartConductPTSuccess, payload: response };
}
export function getChartConductPTFail(response) {
  return { type: constants.getChartConductPTFail, payload: response.data };
}

export function getInfoConductedRequest() {
  return { type: constants.getInfoConductedRequest };
}
export function getInfoConductedSuccess(response) {
  return { type: constants.getInfoConductedSuccess, payload: response };
}
export function getInfoConductedFail(response) {
  return { type: constants.getInfoConductedFail, payload: response.data };
}

export function getTotalAppointmentConductPTRequest() {
  return { type: constants.getTotalAppointmentConductPTRequest };
}
export function getTotalAppointmentConductPTSuccess(response) {
  return { type: constants.getTotalAppointmentConductPTSuccess, payload: response };
}
export function getTotalAppointmentConductPTFail(response) {
  return { type: constants.getTotalAppointmentConductPTFail, payload: response.data };
}

export function getTopConductPTRequest() {
  return { type: constants.getTopConductPTRequest };
}
export function getTopConductPTSuccess(response) {
  return { type: constants.getTopConductPTSuccess, payload: response };
}
export function getTopConductPTFail(response) {
  return { type: constants.getTopConductPTFail, payload: response.data };
}

export function getConductReportPaginationRequest() {
  return { type: constants.getConductReportPaginationRequest };
}
export function getConductReportPaginationSuccess(response) {
  return { type: constants.getConductReportPaginationSuccess, payload: response };
}
export function getConductReportPaginationFail(response) {
  return { type: constants.getConductReportPaginationFail, payload: response.data };
}

export function getConductReportExportRequest() {
  return { type: constants.getConductReportExportRequest };
}
export function getConductReportExportSuccess(response) {
  return { type: constants.getConductReportExportSuccess, payload: response };
}
export function getConductReportExportFail(response) {
  return { type: constants.getConductReportExportFail, payload: response.data };
}

export function getConductReportDetailByTrainerIdRequest() {
  return { type: constants.getConductReportDetailByTrainerIdRequest };
}
export function getConductReportDetailByTrainerIdSuccess(response) {
  return { type: constants.getConductReportDetailByTrainerIdSuccess, payload: response };
}
export function getConductReportDetailByTrainerIdFail(response) {
  return { type: constants.getConductReportDetailByTrainerIdFail, payload: response.data };
}

export function getConductProductByTrainerIdRequest() {
  return { type: constants.getConductProductByTrainerIdRequest };
}
export function getConductProductByTrainerIdSuccess(response) {
  return { type: constants.getConductProductByTrainerIdSuccess, payload: response };
}
export function getConductProductByTrainerIdFail(response) {
  return { type: constants.getConductProductByTrainerIdFail, payload: response.data };
}

export function getConductParticipantByTrainerIdRequest() {
  return { type: constants.getConductParticipantByTrainerIdRequest };
}
export function getConductParticipantByTrainerIdSuccess(response) {
  return { type: constants.getConductParticipantByTrainerIdSuccess, payload: response };
}
export function getConductParticipantByTrainerIdFail(response) {
  return { type: constants.getConductParticipantByTrainerIdFail, payload: response.data };
}

export function getConductParticipantByTrainerIdExportRequest() {
  return { type: constants.getConductParticipantByTrainerIdExportRequest };
}
export function getConductParticipantByTrainerIdExportSuccess(response) {
  return { type: constants.getConductParticipantByTrainerIdExportSuccess, payload: response };
}
export function getConductParticipantByTrainerIdExportFail(response) {
  return { type: constants.getConductParticipantByTrainerIdExportFail, payload: response.data };
}

export function getTotalAppointmentConductPTbyMerchantBranchRequest() {
  return { type: constants.getTotalAppointmentConductPTbyMerchantBranchRequest };
}
export function getTotalAppointmentConductPTbyMerchantBranchSuccess(response) {
  return { type: constants.getTotalAppointmentConductPTbyMerchantBranchSuccess, payload: response };
}
export function getTotalAppointmentConductPTbyMerchantBranchFail(response) {
  return {
    type: constants.getTotalAppointmentConductPTbyMerchantBranchFail,
    payload: response.data,
  };
}
// #endregion

// #region daily check in
export function getChartDailyCheckInRequest() {
  return { type: constants.getChartDailyCheckInRequest };
}

export function getChartDailyCheckInSuccess(response) {
  return { type: constants.getChartDailyCheckInSuccess, payload: response };
}

export function getChartDailyCheckInFail(response) {
  return { type: constants.getChartDailyCheckInFail, payload: response.data };
}

export function getInfoDailyCheckInRequest() {
  return { type: constants.getInfoDailyCheckInRequest };
}

export function getInfoDailyCheckInSuccess(response) {
  return { type: constants.getInfoDailyCheckInSuccess, payload: response };
}

export function getInfoDailyCheckInFail(response) {
  return { type: constants.getInfoDailyCheckInFail, payload: response.data };
}

export function getTotalDailyCheckInRequest() {
  return { type: constants.getTotalDailyCheckInRequest };
}

export function getTotalDailyCheckInSuccess(response) {
  return { type: constants.getTotalDailyCheckInSuccess, payload: response };
}

export function getTotalDailyCheckInFail(response) {
  return { type: constants.getTotalDailyCheckInFail, payload: response.data };
}

export function getTopDailyCheckInRequest() {
  return { type: constants.getTopDailyCheckInRequest };
}

export function getTopDailyCheckInSuccess(response) {
  return { type: constants.getTopDailyCheckInSuccess, payload: response };
}

export function getTopDailyCheckInFail(response) {
  return { type: constants.getTopDailyCheckInFail, payload: response.data };
}
export function getDailyCheckInReportPaginationRequest() {
  return { type: constants.getDailyCheckInReportPaginationRequest };
}

export function getDailyCheckInReportPaginationSuccess(response) {
  return { type: constants.getDailyCheckInReportPaginationSuccess, payload: response };
}

export function getDailyCheckInReportPaginationFail(response) {
  return { type: constants.getDailyCheckInReportPaginationFail, payload: response.data };
}

export function getDailyCheckInReportExportRequest() {
  return { type: constants.getDailyCheckInReportExportRequest };
}

export function getDailyCheckInReportExportSuccess(response) {
  return { type: constants.getDailyCheckInReportExportSuccess, payload: response };
}

export function getDailyCheckInReportExportFail(response) {
  return { type: constants.getDailyCheckInReportExportFail, payload: response.data };
}
// #endregion
