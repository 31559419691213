import React from 'react';
import { Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

const SkeletonModalMedia = () => {
  return (
    <Grid container spacing={5} direction="column" justify="space-between" className="ph-22">
      <Grid item lg={12} md={12}>
        <Skeleton
          variant="rect"
          width="100%"
          height={250}
          animation="wave"
          className="skeletonRounded"
        />
      </Grid>
      <Grid item lg={12} md={12}>
        <Grid container spacing={1} justify="space-between">
          <Grid item lg={9} md={9}>
            <Skeleton
              variant="rect"
              width="100%"
              height={20}
              animation="wave"
              className="skeletonRounded"
            />
          </Grid>
          <Grid item lg={2} md={2}>
            <Skeleton
              variant="rect"
              width="100%"
              height={20}
              animation="wave"
              className="skeletonRounded"
            />
          </Grid>
          <Grid item lg={12} md={12}>
            <Skeleton
              variant="rect"
              width="100%"
              height={20}
              animation="wave"
              className="skeletonRounded"
            />
          </Grid>
          <Grid item lg={12} md={12}>
            <Grid
              container
              spacing={1}
              direction="column"
              justify="space-between"
              className="pt-16"
            >
              <Grid item lg={10} md={10}>
                <Skeleton
                  variant="rect"
                  width="100%"
                  height={20}
                  animation="wave"
                  className="skeletonRounded"
                />
              </Grid>
              <Grid item lg={12} md={12}>
                <Skeleton
                  variant="rect"
                  width="100%"
                  height={20}
                  animation="wave"
                  className="skeletonRounded"
                />
              </Grid>
              <Grid item lg={8} md={8}>
                <Skeleton
                  variant="rect"
                  width="100%"
                  height={20}
                  animation="wave"
                  className="skeletonRounded"
                />
              </Grid>
              <Grid item lg={6} md={6}>
                <Skeleton
                  variant="rect"
                  width="100%"
                  height={20}
                  animation="wave"
                  className="skeletonRounded"
                />
              </Grid>
              <Grid item lg={12} md={12}>
                <Skeleton
                  variant="rect"
                  width="100%"
                  height={20}
                  animation="wave"
                  className="skeletonRounded"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SkeletonModalMedia;
