import React from 'react';
import PropTypes from 'prop-types';
import { RadioGroup, Radio, FormControlLabel, Grid } from '@material-ui/core';

const RadioInput = props => {
  const {
    // isError,
    // errorMessage,
    data,
    currentValue,
    defaultValue,
    direction,
    onSelect,
    isDisable,
  } = props;

  const [selectedValue, setSelectedValue] = React.useState(defaultValue ? defaultValue.name : '');

  const handleItemChange = event => {
    const result = event.target.value;

    const index = data.findIndex(item => item.name === result);
    const resultItem = data[index];

    setSelectedValue(result);
    onSelect(resultItem);
  };

  React.useEffect(() => {
    if (currentValue) setSelectedValue(currentValue.name);
  }, [currentValue, selectedValue]);

  return (
    <RadioGroup value={selectedValue} onChange={handleItemChange}>
      <Grid container direction={direction}>
        <Grid item lg sm>
          {data.map((item, index) => (
            <FormControlLabel
              key={index}
              value={item.name}
              control={<Radio color="primary" />}
              label={item.name}
              labelPlacement="end"
              disabled={isDisable}
            />
          ))}
        </Grid>
      </Grid>
    </RadioGroup>
  );
};

RadioInput.propTypes = {
  // errorMessage: PropTypes.string,
  // isError: PropTypes.bool,
  currentValue: PropTypes.object,
  data: PropTypes.array,
  defaultValue: PropTypes.object,
  direction: PropTypes.string,
  isDisable: PropTypes.bool,
  onSelect: PropTypes.func,
};

RadioInput.defaultProps = {
  direction: 'column',
  defaultValue: null,
};

export default RadioInput;
