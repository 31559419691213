import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import _ from 'lodash';
// Components
import { ButtonIconMain } from '../../../../../components/Index';
// Style
import './DetailsShippingStyle.scss';

const DetailsShipping = props => {
  const {
    orderData: { mealDetails },
    onClickEditBtn,
  } = props;
  const shippingDetailsData = !_.isEmpty(mealDetails) ? mealDetails : {};

  const recipient = !_.isEmpty(shippingDetailsData)
    ? `${shippingDetailsData.name} - ${shippingDetailsData.phone}`
    : '-';

  const handleClickEditAddress = () => {
    onClickEditBtn();
  };

  return (
    <Grid container className="pt-24" direction="column">
      <Grid item lg={12} md={12} className="pb-16">
        <label className="text-12 text-opacity-50 text-bold">Delivery Details</label>
      </Grid>
      <Grid item lg={12} md={12} className="order-card-details">
        <Grid container direction="row" justify="space-between">
          <Grid
            item
            lg={shippingDetailsData.is_editable ? 11 : 'auto'}
            md={shippingDetailsData.is_editable ? 11 : 'auto'}
          >
            <Grid container direction="column">
              <div className="flex-column">
                <label className="text-12 text-bold text-opacity-50 wrapping-container wrapping-container-break mb-4">
                  {recipient}
                </label>
                <label className="text-14 wrapping-container wrapping-container-break shipping-address">
                  {shippingDetailsData.address}
                </label>
              </div>
              <div className="flex-column pt-12">
                <label className="text-12 text-bold text-opacity-50 mb-4">Address Notes</label>
                <label className="text-14 wrapping-container wrapping-container-break">
                  {shippingDetailsData.address_notes || '-'}
                </label>
              </div>
            </Grid>
          </Grid>
          {shippingDetailsData.is_editable && (
            <Grid item lg={1} md={1}>
              <ButtonIconMain
                icon="ic-ffo-edit"
                type="secondary"
                size="sm"
                onClick={() => handleClickEditAddress()}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

DetailsShipping.propTypes = {
  onClickEditBtn: PropTypes.func,
  orderData: PropTypes.object,
};

export default DetailsShipping;
