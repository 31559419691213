import React from 'react';
import PropTypes from 'prop-types';
// Style
import './LabelStatusMainStyle.scss';

const LabelStatusMain = props => {
  const { type, value } = props;

  return (
    <div className={`label-main ${type}`}>
      <label className="text-10">{value}</label>
    </div>
  );
};

LabelStatusMain.propTypes = {
  type: PropTypes.string,
  value: PropTypes.string,
};

export default LabelStatusMain;
