import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid, Fade } from '@material-ui/core';
// style
import './PermissionPageStyle.scss';
// component
import { SelectInputRole, ButtonMain, SnackBarSimple, SkeletonMain, } from '../../../../../components/Index';
import {
  CardPermissionDetail,
  CardPermissionAdd,
  ButtonSaveFooter,
  ModalAssignPermission,
} from '../components/Index';
// api
import {
  setDeletePermissionRole,
  getPermissionGroupByRole,
  setEditPermissionToRole,
} from '../../../../../services/api/UserManagement';
// helper
import { CommonHelper, PermissionHelper, PermissionModule, PermissionPage as PermissionPageHelper, PermissionAccess } from '../../../../../helpers/Index';

const optionToash = {
  vertical: 'top',
  horizontal: 'right',
};

const timeInterval = 300;

class PermissionPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      roleId: null,
      isEdit: false,
      list: [],
      permissionName: null,
      isExpand: '',
      isOpenModal: false,
      isLoading: false,
      validation: { isError: false, errorMessage: '' },
      toastInformation: {
        isOpen: false,
        message: '',
        snackbarType: 'warning',
      },
    };
  }

  componentDidMount() {
    this.handleSelectRole(2);
  }

  componentDidUpdate(prevProps) {
    const { isLoadingParent } = this.props;

    if (prevProps.isLoadingParent !== isLoadingParent) {
      this.getPermissionListByRole();
    }
  }

  componentWillUnmount() {}

  getPermissionListByRole() {
    const { roleId } = this.state;
    const { permissionGroupByRole } = this.props;

    const param = {
      role_id: roleId,
    };

    this.setState({ isLoading: true }, () => {
      permissionGroupByRole(param)
        .then(response => {
          const list = PermissionHelper.generatePermissionGroup(response.data);

          this.setState({
            isLoading: false,
            list,
          });
        })
        .catch(error => {
          const message = error.data;
          this.processMessage(message.messages, 'error');
        });
    });
  }

  handleSelectRole = value => {
    this.setState(
      {
        roleId: value,
      },
      () => {
        this.getPermissionListByRole();
      },
    );
  };

  handleButtonEditClick = () => {
    const { isEdit } = this.state;
    this.setState({ ...isEdit, isEdit: !isEdit });
  };

  handleClickRowDeleteParent = value => {
    const { roleId } = this.state;

    this.processDeletePermissionRole({
      role_id: roleId,
      name: value.toUpperCase(),
    });
  };

  handleClickRowDeleteChildren = chileValue => {
    const { roleId } = this.state;

    this.processDeletePermissionRole({
      role_id: roleId,
      name: chileValue.value.toUpperCase(),
    });
  };

  handleButtonClickAddPermission = value => {
    this.setState({
      isOpenModal: true,
      permissionName: value,
    });
  };

  handleSelectPermissionAccessParent = parentValue => value => {
    const { list } = this.state;

    const indexCheck = list.findIndex(item => item.value === parentValue);
    const indexChildrenCheck = list[indexCheck].access.findIndex(
      item => item.value === value.value,
    );

    list[indexCheck].access[indexChildrenCheck].checked = value.checked;

    this.setState({ list });
  };

  handleSelectPermissionAccessChildren = parentValue => value => {
    const { list } = this.state;

    const indexCheck = list.findIndex(item => item.value === parentValue);
    const indexChildrenCheck = list[indexCheck].children.findIndex(
      item => item.value === value.valueChildren,
    );
    const indexChildrenAccessCheck = list[indexCheck].children[indexChildrenCheck].access.findIndex(
      item => item.value === value.value,
    );

    list[indexCheck].children[indexChildrenCheck].access[indexChildrenAccessCheck].checked =
      value.checked;

    this.setState({ list });
  };

  handleExpandCardDetail = value => {
    this.setState({ isExpand: value });
  };

  handleCloseToash = () => {
    const { toastInformation } = this.state;
    this.setState({ toastInformation: { ...toastInformation, isOpen: false } });
  };

  handleButtonClickCancel = () => {
    const { isEdit } = this.state;
    this.setState({ ...isEdit, isEdit: !isEdit });
  };

  handleButtonClickSave = () => {
    const { isEdit, list, roleId } = this.state;
    const { editPermissionToRole } = this.props;
    this.setState({ ...isEdit, isEdit: !isEdit });

    const formatPermission = PermissionHelper.generateAssignPermissionRole(list);
    const param = {
      data: {
        role_id: roleId,
        name: formatPermission,
      },
    };

    this.setState({ isLoading: true }, () => {
      editPermissionToRole(param)
        .then(async response => {
          const message = response.messages;
          await this.setState({ isLoading: false }, () => {
            this.getPermissionListByRole();
            this.processMessage(message, 'success');
          });
        })
        .catch(error => {
          const message = error.data;
          this.processMessage(message.messages, 'error');
        });
    });
  };

  handleCloseModalAssignment = () => {
    this.setState({ isOpenModal: false }, () => {
      this.getPermissionListByRole();
    });
  };

  processDeletePermissionRole(param = {}) {
    const { deletePermissionRole } = this.props;
    this.setState({ isLoading: true }, () => {
      deletePermissionRole(param)
        .then(async response => {
          const message = response.messages;
          this.getPermissionListByRole();
          this.processMessage(message, 'success');
        })
        .catch(error => {
          const message = error.data;
          this.processMessage(message.messages, 'error');
        });
    });
  }

  processMessage(messages, type) {
    const convertedMessage = CommonHelper.generateMessage(messages);

    const paramInformation = {
      isOpen: true,
      message: convertedMessage,
      snackbarType: type,
    };

    this.setState({
      toastInformation: paramInformation,
    });
  }

  renderFilter() {
    const { isEdit, list } = this.state;
    const isVisible = !isEdit && list.length > 0;

    return (
      <Grid container justify="space-between">
        <Grid item lg={3} md={3}>
          <SelectInputRole
            onChange={this.handleSelectRole}
            currentValue={this.state.roleId}
            disabled={!isVisible}
          />
        </Grid>
        <Grid item lg md>
          <Grid container justify="flex-end" alignItems="center">
            <Grid item lg={2} md={2}>
              <Fade in={isVisible} timeout={timeInterval}>
                <div>
                  <ButtonMain
                    type="ghost"
                    size="xl"
                    labelText="Edit"
                    onClick={this.handleButtonEditClick}
                    requiredPermission={`${PermissionModule.UserManagement}.${PermissionPageHelper.RolesAndPermissions}.${PermissionAccess.Update}`}
                  />
                </div>
              </Fade>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  render() {
    const {
      toastInformation,
      isEdit,
      list,
      isExpand,
      isOpenModal,
      permissionName,
      isLoading,
      roleId,
    } = this.state;
    let renderElement = <SkeletonMain />;

    if (!isLoading) {
      renderElement = (
        <React.Fragment>
          <div className="container-page-permission scroll-container">
            <div className={`container-page-scrolling-area include-tab${isEdit ? '-footer' : ''}`}>
              <Grid container direction="column">
                <Grid item className="section-page-header">
                  {this.renderFilter()}
                </Grid>
                <Grid item className="section-page-body">
                  <Fade in={!isEdit} timeout={timeInterval}>
                    {!isEdit ? (
                      <div
                        style={{
                          height: '35%',
                        }}
                      >
                        <CardPermissionAdd onButtonClickAdd={this.handleButtonClickAddPermission} />
                      </div>
                    ) : (
                      <div />
                    )}
                  </Fade>
                  {list.map((item, index) => (
                    <CardPermissionDetail
                      key={index}
                      dataArray={item}
                      isEdit={isEdit}
                      onDeletePermissionParent={() => this.handleClickRowDeleteParent(item.value)}
                      onDeletePermissionChildren={this.handleClickRowDeleteChildren}
                      onCheckedPermissionAccessParent={this.handleSelectPermissionAccessParent(
                        item.value,
                      )}
                      onCheckedPermissionAccessChildren={this.handleSelectPermissionAccessChildren(
                        item.value,
                      )}
                      isExpand={isExpand}
                      onExpanded={this.handleExpandCardDetail}
                      onButtonClickAdd={this.handleButtonClickAddPermission}
                    />
                  ))}
                </Grid>
                <Grid item className="section-page-footer" />
              </Grid>
              <SnackBarSimple
                open={toastInformation.isOpen}
                durationHide={2000}
                message={toastInformation.message}
                onClickClose={this.handleCloseToash}
                snackbarType={toastInformation.snackbarType}
                anchor={optionToash}
              />
              {isOpenModal ? (
                <ModalAssignPermission
                  onClose={this.handleCloseModalAssignment}
                  modalTitle="Add Permission to Role"
                  isOpen={isOpenModal}
                  moduleName={permissionName}
                  currentRoleId={roleId}
                  validation={this.state.validation}
                />
              ) : null}
            </div>
          </div>
          <div className="container-page-permission-container-floating">
            <Fade in={isEdit} timeout={timeInterval}>
              <div>
                {isEdit ? (
                  <ButtonSaveFooter
                    onButtonClickCancel={this.handleButtonClickCancel}
                    onButtonClickSave={this.handleButtonClickSave}
                  />
                ) : null}
              </div>
            </Fade>
          </div>
        </React.Fragment>
      );
    }

    return renderElement;
  }
}

const mapDispatchToProps = dispatch => ({
  deletePermissionRole: params => setDeletePermissionRole(dispatch, params),
  permissionGroupByRole: params => getPermissionGroupByRole(dispatch, params),
  editPermissionToRole: params => setEditPermissionToRole(dispatch, params),
});

const mapStateToProps = () => ({});

PermissionPage.propTypes = {
  deletePermissionRole: PropTypes.func,
  editPermissionToRole: PropTypes.func,
  isLoadingParent: PropTypes.bool,
  permissionGroupByRole: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(PermissionPage);
