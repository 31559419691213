/* eslint-disable react/no-access-state-in-setstate */
import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Table } from 'antd';
import { Grid } from '@material-ui/core';
import _ from 'lodash';
// Components
import {
  SnackBarSimple,
  AuthenticationAccessPages,
  PrevStateValue,
  TextInput,
  SelectInputMain,
  SkeletonMain,
} from '../../../components/Index';
// Api
import { getListEventParticipantPagination } from '../../../services/api/EventApi';
// Helpers
import { CommonHelper, MasterDataHelper } from '../../../helpers/Index';
// Style
import './EventParticipantList.scss';

const statusOption = MasterDataHelper.statusEvent;

const optionToast = {
  vertical: 'top',
  horizontal: 'right',
};

const initialSort = {
  sortField: null,
  sortOrder: 'asc',
};

const initialFilter = {
  search: '',
  status: statusOption[0].value,
};

class EventParticipantListPage extends React.Component {
  constructor(props) {
    super(props);
    const {
      usersReducer: { prevStateValue },
    } = props;

    const updateExitingFilter = {
      ...CommonHelper.objectCloning(initialFilter),
      ...prevStateValue,
    };
    this.state = {
      loading: true,
      isLoading: true,
      filter: updateExitingFilter,
      page: 1,
      limit: 10,
      sort: CommonHelper.objectCloning(initialSort),
      pagination: {
        showSizeChanger: true,
        showTotal: (total, range) => `Showing ${range[0]}-${range[1]} from ${total}`,
        pageSizeOptions: ['10', '20', '30', '40'],
      },
      toastInformation: {
        isOpen: false,
        message: '',
        snackbarType: 'warning',
      },
    };
    this.searchDebounce = _.debounce(this.searchDebounce, 400);
  }

  componentDidMount() {
    const {
      onResetPrevStateValue,
      history: { location },
    } = this.props;

    this.getListPagination();

    onResetPrevStateValue({ url: location.pathname });
  }

  getListPagination = () => {
    const { listEventPagination } = this.props;
    const { limit, page, pagination, sort, filter } = this.state;

    const param = {
      page,
      limit,
      sort: sort.sortField,
      sortOrder: sort.sortOrder,
      search: filter.search,
      status: filter.status,
    };

    this.setState({ loading: true }, () => {
      listEventPagination(param)
        .then(response => {
          this.setState({
            loading: false,
            isLoading: false,
            pagination: { ...pagination, pageSize: limit, total: response.data.total },
          });
        })
        .catch(() => {
          this.setState({
            loading: false,
            isLoading: false,
          });
        });
    });
  };

  handleChangeSearch = value => {
    const { filter } = this.state;

    this.setState(
      {
        filter: { ...filter, search: value },
        page: 1,
        pagination: { ...this.state.pagination, current: 1 },
      },
      () => {
        this.searchDebounce();
      },
    );
  };

  handleSelectChangeStatus = value => {
    const { filter } = this.state;
    this.setState(
      {
        filter: {
          ...filter,
          status: value,
          page: 1,
          pagination: { ...this.state.pagination, current: 1 },
        },
      },
      () => {
        this.getListPagination();
      },
    );
  };

  searchDebounce = () => {
    this.getListPagination();
  };

  handleCloseToast = () => {
    const { toastInformation } = this.state;
    this.setState({ toastInformation: { ...toastInformation, isOpen: false } });
  };

  handleClickRow = value => {
    const { history, onSetPrevStateValue } = this.props;
    const { filter } = this.state;
    const param = CommonHelper.encryptObject({ eventId: value });

    onSetPrevStateValue({ ...filter });
    history.push(`/event/participant/details/${param}`);
  };

  handleTableChange = (pagination, filters, sorter) => {
    const { sort } = this.state;

    this.setState(
      {
        limit: pagination.pageSize,
        pagination: {
          ...this.state.pagination,
          current: pagination.current,
          pageSize: pagination.pageSize,
        },
        sort: {
          ...sort,
          sortField: sorter.field || initialSort.sortField,
          sortOrder: sorter.order === 'ascend' || !sorter.order ? 'asc' : 'desc',
        },
        page: pagination.current,
      },
      () => {
        this.getListPagination();
      },
    );
  };

  renderFilter = () => {
    const { filter } = this.state;

    return (
      <div className="flex-column">
        <Grid
          container
          justify="space-between"
          alignItems="center"
          className="container-filter flex-wrap-unset"
        >
          <Grid item lg md>
            <Grid container justify="flex-start" alignItems="center">
              <Grid item xl={3} lg={4} md={4} className="row-filter">
                <TextInput
                  iconPrefix="ic-ffo-search"
                  placeHolderText="Search by event name"
                  onChange={this.handleChangeSearch}
                  currentValue={filter.search}
                  size="md"
                />
              </Grid>
              <Grid item xl={2} lg={3} md={3} className="row-filter">
                <SelectInputMain
                  options={statusOption}
                  currentValue={filter.status}
                  onChange={this.handleSelectChangeStatus}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  };

  renderColumns = () => {
    return [
      {
        title: 'Event Name',
        dataIndex: 'name',
        sorter: true,
        render: (text, row) => {
          return (
            <Grid onClick={() => this.handleClickRow(row.event_id)}>
              <label className="text-14 text-medium wrapping-container second line wrapping-container-break event-name">
                {row.name}
              </label>
            </Grid>
          );
        },
      },
      {
        title: 'Total Participant',
        dataIndex: 'total_participant',
      },
      {
        title: 'Category & Venue',
        dataIndex: 'category',
        render: (text, row) => {
          return (
            <div className="flex-column">
              <label className="text-14 text-medium text-black wrapping-container second line wrapping-container-break mb-8">
                {row.event_category}
              </label>
              <label className="text-14 text-medium text-rolling-stone wrapping-container second line wrapping-container-break">
                {row.address}
              </label>
            </div>
          );
        },
      },
      {
        title: 'Event Date',
        dataIndex: 'start_time',
        sorter: true,
        width: '180px',
        render: (text, row) => {
          const eventDate =
            CommonHelper.dateTimeParseNewFormat(row.start_time, 'ddd, D MMM YYYY, HH:mm A') || '-';

          return <label className="text-14 text-medium text-right">{eventDate}</label>;
        },
      },
      {
        title: 'Last Update',
        dataIndex: 'updated_at',
        align: 'right',
        sorter: true,
        render: (text, row) => {
          const createdDate =
            CommonHelper.dateTimeParseNewFormat(row.updated_at, 'ddd, DD MMM YYYY, HH:mm A') || '-';

          return (
            <div className="flex-column">
              <label className="text-14 text-medium">{createdDate}</label>
              <label className="text-14 text-medium text-rolling-stone wrapping-container second line wrapping-container-break">
                by {row.updated_by}
              </label>
            </div>
          );
        },
      },
    ];
  };

  render() {
    const {
      eventData: { list },
    } = this.props;
    const { isLoading, loading, pagination, toastInformation } = this.state;

    let renderElement = <SkeletonMain />;

    if (!isLoading) {
      renderElement = (
        <div>
          <Helmet title={`FITCO | Event Participants`} />
          <div className="container-page-participant scroll-container">
            <div className="container-page-scrolling-area">
              <Grid container direction="column">
                <Grid item lg md sm className="section-page-header">
                  {this.renderFilter()}
                </Grid>
                <Grid item lg md className="section-page-body">
                  <Table
                    columns={this.renderColumns()}
                    rowKey={record => record.event_id}
                    dataSource={list}
                    pagination={pagination}
                    loading={loading}
                    onChange={this.handleTableChange}
                  />
                </Grid>
              </Grid>
            </div>
          </div>
          <SnackBarSimple
            open={toastInformation.isOpen}
            durationHide={2000}
            message={toastInformation.message}
            onClickClose={this.handleCloseToast}
            snackbarType={toastInformation.snackbarType}
            anchor={optionToast}
          />
        </div>
      );
    }

    return renderElement;
  }
}

const mapDispatchToProps = dispatch => ({
  listEventPagination: params => getListEventParticipantPagination(dispatch, params),
});

const mapStateToProps = ({ usersReducer, eventData }) => ({
  usersReducer,
  eventData,
});

EventParticipantListPage.propTypes = {
  eventData: PropTypes.object,
  history: PropTypes.object,
  listEventPagination: PropTypes.func,
  onResetPrevStateValue: PropTypes.func,
  onSetPrevStateValue: PropTypes.func,
  usersReducer: PropTypes.object,
};

const shell = compose(connect(mapStateToProps, mapDispatchToProps));
const core = compose(AuthenticationAccessPages, PrevStateValue);

export default shell(core(EventParticipantListPage));
