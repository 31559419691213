/* eslint-disable radix */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Helmet } from 'react-helmet';
import { Grid, FormControl, FormLabel, Divider } from '@material-ui/core';
import { Events, scroller, Element } from 'react-scroll';
import _ from 'lodash';
// style
import './ScheduleAddStyle.scss';
// Component
import { CalendarDetailEvent } from './components/Index';
import {
  CheckInputOutlineBackground,
  SelectInputMain,
  PickerInputDate,
  PickerInputTime,
  ButtonMain,
  CheckInputMultiple,
  TextInputNumber,
  SelectInputBranch,
  SnackBarSimple,
  AuthenticationAccessPages,
  PrevStateValue,
} from '../../../../components/Index';
// helper
import {
  CommonHelper,
  ValidationHelper,
  CalendarHelper,
  UserHelper,
  MasterDataHelper,
  PermissionModule,
  PermissionPage,
  PermissionAccess,
} from '../../../../helpers/Index';
// api
import { setScheduleSingle, setScheduleBulk } from '../../../../services/api/ScheduleDataApi';

const repeatTimeData = MasterDataHelper.repeatTimeData;
const repeatDayData = MasterDataHelper.repeatDayData;
const repeatWeekData = MasterDataHelper.repeatWeekData;

const initialFilter = {
  productId: null,
  merchantId: null,
  branchId: null,
  category: null,
};

const initialForm = {
  productId: null,
  merchantId: null,
  branchId: null,
  capacity: 1,
  shift: 0,
  scheduleData: [],
};

const initialValidation = {
  capacity: { isError: false, errorMessage: '' },
  merchant: { isError: '', errorMessage: '' },
  branch: { isError: '', errorMessage: '' },
};

const resetValidation = { isError: '', errorMessage: '' };

const currentDate = CommonHelper.currentDate('YYYY-MM-DD');
const optionToast = {
  vertical: 'top',
  horizontal: 'right',
};

class ScheduleAdd extends React.Component {
  constructor(props) {
    super(props);

    props.checkUserAccessPermission(
      PermissionModule.Move,
      PermissionPage.Schedule,
      PermissionAccess.Add,
    );

    this.state = {
      filter: initialFilter,
      form: initialForm,
      validation: initialValidation,
      startDate: currentDate,
      startTime: `06:00:00`,
      endTime: `20:00:00`,
      repeatTime: null,
      repeatDay: [],
      repeatWeek: null,
      showMultiple: false,
      isLoading: false,
      toastInformation: {
        isOpen: false,
        message: '',
        snackbarType: 'warning',
      },
    };
  }

  componentDidMount() {
    const { history } = this.props;
    const currentUsers = UserHelper.getCurrentUserInformation();

    if (currentUsers && currentUsers.token.access_token) {
      this.handleClickGroupButton();
      Events.scrollEvent.register('begin');
      Events.scrollEvent.register('end');
    } else {
      localStorage.clear();
      history.index = 0;
      history.push('/sign-in');
    }
  }

  componentDidUpdate(nextProps) {
    const { masterData } = this.props;

    if (nextProps.masterData.medicConsultation !== masterData.medicConsultation) {
      this.handleClickGroupButton();
    }
  }

  componentWillUnmount() {
    Events.scrollEvent.remove('begin');
    Events.scrollEvent.remove('end');
  }

  handleCheckedMultiple = value => {
    const { form } = this.state;

    this.setState(
      {
        showMultiple: value,
      },
      () => {
        if (value) this.scrollTo();
        else {
          this.setState({
            form: {
              ...form,
              scheduleData: [],
            },
            repeatDay: [],
            repeatTime: null,
            repeatWeek: null,
          });
        }
      },
    );
  };

  handleClickGroupButton = () => {
    const { form } = this.state;
    const { masterData } = this.props;
    const currentMerchant = !_.isEmpty(masterData.medicConsultation)
      ? masterData.medicConsultation.branches[0]
      : null;
    const currentProduct = !_.isEmpty(masterData.medicConsultation)
      ? masterData.medicConsultation.product_id
      : null;

    this.setState(
      {
        validation: initialValidation,
        form: {
          ...form,
          merchantId: currentMerchant,
          productId: currentProduct,
        },
      },
      () => {
        this.handleSelectMerchant(currentMerchant);
      },
    );
  };

  handleSelectMerchant = value => {
    const { filter, form, validation } = this.state;

    this.setState({
      filter: {
        ...filter,
        merchantId: value || null,
        branchId: null,
      },
      form: {
        ...form,
        merchantId: value || null,
        branchId: null,
      },
      validation: {
        ...validation,
        merchant: resetValidation,
      },
    });
  };

  handleSelectBranch = value => {
    const { filter, form, validation } = this.state;
    if (value) {
      this.setState({
        filter: { ...filter, branchId: value || null },
        form: { ...form, branchId: value || null },
        validation: {
          ...validation,
          branch: resetValidation,
        },
      });
    }
  };

  handleSelectStartDateChange = value => {
    this.setState({ startDate: value }, () => {
      this.generateScheduleData();
    });
  };

  handleSelectStartTimeChange = value => {
    this.setState({ startTime: value }, () => {
      this.generateScheduleData();
    });
  };

  handleSelectEndTimeChange = value => {
    this.setState({ endTime: value }, () => {
      this.generateScheduleData();
    });
  };

  handleTextNumberChanges = value => {
    const { form, validation } = this.state;
    let paramValidation = {
      isError: false,
      errorMessage: '',
    };

    if (ValidationHelper.validateNumberCapacity(value)) {
      this.setState({
        form: { ...form, capacity: parseInt(value, 10) || null },
        validation: { ...validation, capacity: paramValidation },
      });
    } else {
      let message = '';
      if (value === '' || value === '0') message = `Capacity Can't be 0 or empty`;
      else if (parseInt(value, 10) < 0) message = `Capacity must be greater than 0 `;
      else message = `Maximum Capacity is 99`;

      paramValidation = {
        isError: true,
        errorMessage: message,
      };
      this.setState({ validation: { ...validation, capacity: paramValidation } });
    }
  };

  handleSelectRepeatTime = value => {
    this.setState({ repeatTime: value }, () => {
      this.generateScheduleData();
    });
  };

  handleSelectRepeatDay = value => {
    const { repeatDay } = this.state;
    const index = repeatDayData.findIndex(item => item.name === value.name);
    const indexCheck = repeatDay.findIndex(item => item === repeatDayData[index].value);

    if (indexCheck === -1 && value.checked) {
      this.setState({ repeatDay: [...repeatDay, repeatDayData[index].value] }, () => {
        this.generateScheduleData();
      });
    } else if (indexCheck !== -1 && !value.checked) {
      const reUpdate = repeatDay;
      reUpdate.splice(indexCheck, 1);
      this.setState({ repeatDay: reUpdate }, () => {
        this.generateScheduleData();
      });
    }
  };

  handleSelectRepeatWeek = value => {
    this.setState({ repeatWeek: value }, () => {
      this.generateScheduleData();
    });
  };

  handleCloseToast = () => {
    const { toastInformation } = this.state;
    this.setState({ toastInformation: { ...toastInformation, isOpen: false } });
  };

  handleButtonSaveClick = () => {
    const { showMultiple, form } = this.state;

    if (this.checkValidationForm()) {
      if (showMultiple) {
        if (form.scheduleData.length > 0) {
          this.saveScheduleBulk();
        } else {
          const message = 'Your Time Line not create yet!!!';
          this.processMessage(message, 'warning');
        }
      } else {
        this.saveScheduleSingle();
      }
    }
  };

  handleButtonCancelClick = () => {
    const { history } = this.props;

    history.push('/medic/clinic-schedule');
  };

  checkValidationForm() {
    const { form, validation } = this.state;
    let passCheck = true;

    let paramValidation01 = resetValidation;
    let paramValidation02 = resetValidation;

    if (!form.merchantId) {
      paramValidation01 = {
        isError: 'error',
        errorMessage: 'Please Select Your merchant...',
      };
      passCheck = false;
    }

    if (!form.branchId) {
      paramValidation02 = {
        isError: 'error',
        errorMessage: 'Please Select Your Branch...',
      };
      passCheck = false;
    }

    if (validation.capacity.isError) passCheck = false;

    if (!passCheck) {
      this.setState({
        validation: {
          ...validation,
          merchant: paramValidation01,
          branch: paramValidation02,
        },
      });
    }

    return passCheck;
  }

  processMessage(messages, type) {
    const convertedMessage = CommonHelper.generateMessage(messages);

    const paramInformation = {
      isOpen: true,
      message: convertedMessage,
      snackbarType: type,
    };

    this.setState({
      toastInformation: paramInformation,
    });
  }

  saveScheduleSingle() {
    const { startDate, startTime, endTime, form } = this.state;
    const { scheduleSingle, masterData, history } = this.props;

    const param = {
      data: {
        merchant_id: masterData.medicConsultation.merchant_id,
        branch_id: form.branchId,
        product_id: form.productId,
        capacity: form.capacity,
        start_time: `${startDate} ${startTime}`,
        end_time: `${startDate} ${endTime}`,
      },
    };

    this.setState({ isLoading: true }, () => {
      scheduleSingle(param)
        .then(async response => {
          const message = response.messages;
          await this.processMessage(message, 'success');
          setTimeout(async () => {
            await this.setState({ isLoading: false });
            await history.push('/medic/clinic-schedule');
          }, 2000);
        })
        .catch(async error => {
          const message = error.data;
          await this.processMessage(message.messages, 'error');
          await this.setState({ isLoading: false });
        });
    });
  }

  saveScheduleBulk() {
    const { form } = this.state;
    const { scheduleBulk, masterData, history } = this.props;

    const param = {
      data: {
        merchant_id: masterData.medicConsultation.merchant_id,
        branch_id: form.branchId,
        product_id: form.productId,
        capacity: form.capacity,
        time_list: CalendarHelper.getTimeLineParam(form.scheduleData),
      },
    };

    this.setState({ isLoading: true }, () => {
      scheduleBulk(param)
        .then(async response => {
          const message = response.messages;
          await this.processMessage(message, 'success');
          setTimeout(async () => {
            await this.setState({ isLoading: false });
            await history.push('/medic/clinic-schedule');
          }, 2000);
        })
        .catch(async error => {
          const message = error.data;
          await this.processMessage(message.messages, 'error');
          await this.setState({ isLoading: false });
        });
    });
  }

  // eslint-disable-next-line class-methods-use-this
  scrollTo() {
    const optionScroll = {
      duration: 700,
      delay: 0,
      smooth: 'easeInOutQuart',
    };

    scroller.scrollTo('scroll-to-multiple-schedule', optionScroll);
  }

  generateScheduleData() {
    const { form, repeatDay, repeatTime, repeatWeek, startDate, startTime, endTime } = this.state;
    const param = { repeatDay, repeatTime, repeatWeek, startDate, startTime, endTime };
    const scheduleData = CalendarHelper.generateScheduleData(param);

    this.setState({ form: { ...form, scheduleData } });
  }

  renderMultipleSchedule = () => {
    return (
      <Element name="scroll-to-multiple-schedule" className="schedule-card second">
        <Grid container spacing={4}>
          <Grid item lg={3} md={3}>
            <Grid container direction="column">
              <Grid item lg={10} md={10}>
                <label className="text-16 title">Multiple Schedule</label>
              </Grid>
              <Grid item lg={11} md={11} className="schedule-card-option">
                <FormControl component="fieldset" fullWidth margin="normal">
                  <FormLabel component="label" className="text-12 title">
                    Repeat Time
                  </FormLabel>
                  <SelectInputMain
                    options={repeatTimeData}
                    defaultValue={this.state.repeatTime}
                    size="large"
                    onChange={this.handleSelectRepeatTime}
                  />
                </FormControl>
                <FormControl component="fieldset" fullWidth margin="normal">
                  <FormLabel component="label" className="text-12 title">
                    Repeat Day
                  </FormLabel>
                  <CheckInputMultiple data={repeatDayData} onCheck={this.handleSelectRepeatDay} />
                </FormControl>
                <FormControl component="fieldset" fullWidth margin="normal">
                  <FormLabel component="label" className="text-12 title">
                    Repeat Week
                  </FormLabel>
                  <SelectInputMain
                    options={repeatWeekData}
                    size="large"
                    defaultValue={this.state.repeatWeek}
                    onChange={this.handleSelectRepeatWeek}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg md className="section-multi-table-right">
            <CalendarDetailEvent data={this.state.form.scheduleData} />
          </Grid>
        </Grid>
      </Element>
    );
  };

  render() {
    const { showMultiple, toastInformation, isLoading } = this.state;

    return (
      <div>
        <Helmet title="FITCO | Add Schedules" />
        <div className="container-page-schedule-add scroll-container">
          <div className="container-page-scrolling-area">
            <Grid container direction="column">
              <Grid item lg md className="section-page-header" />
              <Grid item lg md className="section-page-body">
                <Grid container direction="column">
                  <Grid item lg md>
                    <Grid container className="schedule-card first">
                      <Grid item lg={6} md={6}>
                        <Grid container direction="column">
                          <Grid item lg={10} md={10}>
                            <label className="text-16 title">
                              Create Consultation Schedules for
                            </label>
                          </Grid>
                          <Grid item lg={10} md={10} className="column-selection">
                            <FormControl component="fieldset" fullWidth margin="normal">
                              <FormLabel component="label" className="text-12 title">
                                Branch
                              </FormLabel>
                              <SelectInputBranch
                                placeHolder="Select Branch"
                                onChange={this.handleSelectBranch}
                                currentValue={this.state.filter.branchId}
                                errorMessage={this.state.validation.branch.errorMessage}
                                validateStatus={this.state.validation.branch.isError}
                                filter={this.state.filter}
                                includeAllData
                                isMedical
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item lg={6} md={6}>
                        <Grid container direction="column">
                          <Grid item lg md className="section-schedule-setup-date">
                            <Grid container justify="flex-end" alignItems="center">
                              <Grid item lg={11} md={11}>
                                <span className="flex-row">
                                  <label className="text-16">Set Up Date </label>
                                  <Divider style={{ width: '72%' }} />
                                </span>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item lg md>
                            <Grid container justify="space-between">
                              <Grid item />
                              <Grid item lg={5} md={5}>
                                <FormControl component="fieldset" fullWidth margin="normal">
                                  <FormLabel component="label" className="text-12 title">
                                    Start Date
                                  </FormLabel>
                                  <PickerInputDate
                                    customIcon="ic-ffo-date-pick"
                                    dateFormat="dd-MM-yyyy"
                                    minDate={currentDate}
                                    onChange={this.handleSelectStartDateChange}
                                    toolbar
                                  />
                                </FormControl>
                              </Grid>
                              <Grid item lg={5} md={5}>
                                <FormControl component="fieldset" fullWidth margin="normal">
                                  <FormLabel component="label" className="text-12 title">
                                    Capacity
                                  </FormLabel>
                                  <TextInputNumber
                                    placeHolderText={'Capacity'}
                                    defaultValue={this.state.form.capacity}
                                    isError={this.state.validation.capacity.isError}
                                    errorMessage={this.state.validation.capacity.errorMessage}
                                    onChange={this.handleTextNumberChanges}
                                  />
                                </FormControl>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item>
                            <Grid container direction="row" justify="space-between">
                              <Grid item />
                              <Grid item lg={5} md={5}>
                                <FormControl component="fieldset" fullWidth margin="normal">
                                  <FormLabel component="label" className="text-12 title">
                                    Start Time
                                  </FormLabel>
                                  <PickerInputTime
                                    defaultValue={this.state.startTime}
                                    onChange={this.handleSelectStartTimeChange}
                                    customIcon="ic-ffo-timer"
                                  />
                                </FormControl>
                              </Grid>
                              <Grid item lg={5} md={5}>
                                <FormControl component="fieldset" fullWidth margin="normal">
                                  <FormLabel component="label" className="text-12 title">
                                    End Time
                                  </FormLabel>
                                  <PickerInputTime
                                    defaultValue={this.state.endTime}
                                    onChange={this.handleSelectEndTimeChange}
                                    customIcon="ic-ffo-timer"
                                  />
                                </FormControl>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item>
                            <Grid
                              container
                              justify="flex-end"
                              alignItems="center"
                              style={{ marginTop: 20 }}
                            >
                              <Grid item lg={11} md={11}>
                                <FormControl component="fieldset" fullWidth margin="normal">
                                  <CheckInputOutlineBackground
                                    labelText="Make this multiple schedules"
                                    onCheck={this.handleCheckedMultiple}
                                  />
                                </FormControl>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item lg md>
                    {showMultiple && this.renderMultipleSchedule()}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item lg md className="section-page-footer">
                <Grid container justify="flex-end" spacing={2}>
                  <Grid item lg={3} md={3}>
                    <ButtonMain
                      type="ghost"
                      size="md"
                      labelText="Cancel"
                      onClick={this.handleButtonCancelClick}
                      isLoading={isLoading}
                    />
                  </Grid>
                  <Grid item lg={3} md={3}>
                    <ButtonMain
                      type="primary"
                      size="md"
                      labelText="Create"
                      onClick={this.handleButtonSaveClick}
                      isLoading={isLoading}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>
        <SnackBarSimple
          open={toastInformation.isOpen}
          durationHide={2000}
          message={toastInformation.message}
          onClickClose={this.handleCloseToast}
          snackbarType={toastInformation.snackbarType}
          anchor={optionToast}
        />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  scheduleSingle: params => setScheduleSingle(dispatch, params),
  scheduleBulk: params => setScheduleBulk(dispatch, params),
});

const mapStateToProps = ({ masterData }) => ({ masterData });

ScheduleAdd.propTypes = {
  checkUserAccessPermission: PropTypes.func,
  history: PropTypes.object,
  masterData: PropTypes.object,
  scheduleBulk: PropTypes.func,
  scheduleSingle: PropTypes.func,
};

const shell = compose(connect(mapStateToProps, mapDispatchToProps));
const core = compose(AuthenticationAccessPages, PrevStateValue);

export default shell(core(ScheduleAdd));
