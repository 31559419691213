import { makeStyles } from '@material-ui/core/styles';

const StylesJs = makeStyles(theme => ({
  customAvatar: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    marginRight: theme.spacing(1),
  },
}));

export default StylesJs;
