import React from 'react';
import { connect } from 'react-redux';
import { Grid, FormControl, FormLabel, Fade } from '@material-ui/core';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { compose } from 'redux';
// component
import {
  TextInput,
  ButtonMain,
  PrevNextStepperCount,
  SelectInputMain,
  GridRowTableOneColumn,
  RadioInputImage,
  LabelAlert,
  SnackBarSimple,
} from '../../../../../../components/Index';
import { CartItemBooking, DetailsAppointment, CustomerItemBooking } from './components/Index';
// style
import './StepBookingSummaryAndPaymentStyle.scss';
import {
  CommonHelper,
  MasterDataHelper,
  HttpStatusCode,
  promoTypeCode,
  paymentMethodOptionCode,
  serviceLocationCode,
} from '../../../../../../helpers/Index';
// api
import { checkPromoCode } from '../../../../../../services/api/OrdersApi';

const bankTransferOption = MasterDataHelper.bankTransferOption;

const initialValidation = {
  promoCode: { isError: false, errorMessage: '' },
};

const initialForm = {
  paymentMethod: 'bank_transfer',
  bank: bankTransferOption[0],
  transactionReference: undefined,
  promoCode: '',
  apdProductFee: {},
  transportFeeProduct: {
    name: 'Home Service Fee',
    qty: 1,
    price: '250000.00', // Free Home Service Fee until 7 August 2021
    service_location: 'at_home',
  },
};

const optionToast = {
  vertical: 'top',
  horizontal: 'right',
};

const timeInterval = 300;

const defaultPayment = [
  paymentMethodOptionCode.fit_point,
  paymentMethodOptionCode.credit_card,
  paymentMethodOptionCode.bank_transfer,
  paymentMethodOptionCode.edc,
  paymentMethodOptionCode.cash,
  paymentMethodOptionCode.qris,
  paymentMethodOptionCode.corporate_deal,
  paymentMethodOptionCode.xendit_invoice,
  paymentMethodOptionCode.traveloka,
  paymentMethodOptionCode.tiket,
  paymentMethodOptionCode.tokopedia,
  paymentMethodOptionCode.blibli,
];

const existingDefaultPayment = [
  paymentMethodOptionCode.bank_transfer,
  paymentMethodOptionCode.edc,
  paymentMethodOptionCode.cash,
  paymentMethodOptionCode.qris,
  paymentMethodOptionCode.corporate_deal,
  paymentMethodOptionCode.xendit_invoice,
  paymentMethodOptionCode.traveloka,
  paymentMethodOptionCode.tiket,
  paymentMethodOptionCode.tokopedia,
  paymentMethodOptionCode.blibli,
];

const homeServiceExceptPayment = [paymentMethodOptionCode.edc, paymentMethodOptionCode.cash];

class StepBookingSummaryAndPayment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      form: CommonHelper.objectCloning(initialForm),
      validation: CommonHelper.objectCloning(initialValidation),
      paymentMethodOption: [],
      isSuccess: '',
      isValidPromoCode: false,
      disablePromoCode: false,
      toastInformation: {
        isOpen: false,
        message: '',
        snackbarType: 'warning',
      },
    };
    this.searchPromoCodeDebounce = _.debounce(this.searchPromoCodeDebounce, 400);
  }

  componentDidMount() {
    const {
      parentForm,
      medicData: { apdProduct },
    } = this.props;
    let setInitialForm = initialForm;

    let modifyPaymentMethodOption = MasterDataHelper.paymentMethodOption.filter(
      item => defaultPayment.indexOf(item.value) !== -1,
    );

    if (!parentForm.step_booking_user_details.isExisting) {
      modifyPaymentMethodOption = MasterDataHelper.paymentMethodOption.filter(
        item => existingDefaultPayment.indexOf(item.value) !== -1,
      );
    }

    if (
      apdProduct &&
      parentForm.step_booking_product_details.serviceLocation === serviceLocationCode.AtHome
    ) {
      setInitialForm = { ...setInitialForm, apdProductFee: apdProduct };
      modifyPaymentMethodOption = modifyPaymentMethodOption.filter(
        item => homeServiceExceptPayment.indexOf(item.value) === -1,
      );
    }

    if (parentForm.step_booking_product_details.serviceLocation === serviceLocationCode.InStore) {
      setInitialForm = { ...setInitialForm, transportFeeProduct: {} };
    }

    if (parentForm.step_booking_summary) {
      setInitialForm = { ...parentForm.step_booking_summary };
    }

    this.setState(
      {
        paymentMethodOption: modifyPaymentMethodOption,
        form: {
          ...setInitialForm,
        },
      },
      () => {
        if (
          parentForm.step_booking_summary &&
          !_.isEmpty(parentForm.step_booking_summary.promoCode)
        ) {
          this.searchPromoCodeDebounce();
        }
      },
    );
  }

  searchPromoCodeDebounce = () => {
    const { checkPromo } = this.props;
    const { validation, form } = this.state;
    const params = this.getPayloadPromoCode();
    const message = CommonHelper.objectCloning(initialValidation.promoCode);

    if (!_.isEmpty(form.promoCode)) {
      checkPromo(params)
        .then(() => {
          message.errorMessage = 'Promo Code Applied';
          this.setState({
            isValidPromoCode: true,
            isSuccess: 'success',
            validation: {
              ...validation,
              promoCode: message,
            },
          });
        })
        .catch(e => {
          if (e.status === HttpStatusCode.NotFound) {
            message.isError = true;
            message.errorMessage = `${e.data.messages}, you will get no benefit if Process this transaction`;

            this.setState({
              validation: {
                ...validation,
                promoCode: message,
              },
              isValidPromoCode: false,
              isSuccess: 'error',
            });
          }
        });
    } else {
      this.setState({
        isValidPromoCode: false,
        isSuccess: '',
        validation: {
          ...validation,
          promoCode: { isError: false, errorMessage: '' },
        },
      });
    }
  };

  getPayloadPromoCode = () => {
    const { parentForm } = this.props;
    const { form } = this.state;
    const itemList = [];
    const items = parentForm.step_booking_customer_details;

    items.forEach(item => {
      itemList.push({
        product_id: item.product_id,
        qty: 1,
      });
    });

    const payload = {
      data: {
        user_id: parentForm.step_booking_user_details.userId,
        promo_code: form.promoCode,
        payment_type: form.paymentMethod,
        items: !_.isEmpty(itemList) ? itemList : null,
      },
    };
    return payload;
  };

  handleSelectPaymentMethod = value => {
    const { form } = this.state;

    this.setState(
      {
        form: {
          ...form,
          paymentMethod: value,
        },
      },
      async () => {
        if (value === paymentMethodOptionCode.bank_transfer) {
          const valueChange = bankTransferOption[0];
          this.handleChangeBankTransfer(valueChange);
        }
        if (!_.isEmpty(form.promoCode)) {
          this.searchPromoCodeDebounce();
        }
      },
    );
  };

  handleChangeBankTransfer = value => {
    const { form } = this.state;

    this.setState({ form: { ...form, bank: value } });
  };

  handleChangeTransactionReference = value => {
    const { form } = this.state;

    this.setState({ form: { ...form, transactionReference: value } });
  };

  handleChangePromoCodeEntry = value => {
    const { form } = this.state;
    this.setState(
      {
        form: { ...form, promoCode: value },
      },
      () => {
        this.searchPromoCodeDebounce();
      },
    );
  };

  handleRemoveCustomer = async id => {
    const { parentForm, onParameterUpdate } = this.props;
    let currentCustomerList = [...parentForm.step_booking_customer_details];
    currentCustomerList = currentCustomerList.filter(item => item.id !== id);
    await onParameterUpdate(currentCustomerList, 'step_booking_customer_details');
  };

  handleUpdatePhoto = async data => {
    const { parentForm, onParameterUpdate } = this.props;
    const dataUpdated = [];
    dataUpdated.push(data);
    let currentCustomerList = [...parentForm.step_booking_customer_details];
    currentCustomerList = currentCustomerList.map(
      value => dataUpdated.find(item => item.id === value.id) || value,
    );
    await onParameterUpdate(currentCustomerList, 'step_booking_customer_details');
  };

  handleTransportPriceUpdated = price => {
    const { form } = this.state;

    this.setState({
      form: { ...form, transportFeeProduct: { ...form.transportFeeProduct, price } },
    });
  };

  handlePrevClick = async () => {
    const { onPrevClick, onParameterUpdate } = this.props;
    const { form } = this.state;
    const formNewValue = CommonHelper.objectCloning(form);
    formNewValue.promoCode = '';

    await onParameterUpdate(formNewValue, 'step_booking_summary');
    await onPrevClick();
  };

  handleSubmitClick = async () => {
    const { onButtonClickSubmit, onParameterUpdate } = this.props;
    const {
      form,
      validation: { promoCode },
    } = this.state;
    const formNewValue = CommonHelper.objectCloning(form);

    await onParameterUpdate(formNewValue, 'step_booking_summary');

    if (promoCode.isError) {
      this.processMessage('Promo Code not Valid, Check your Promo Code', 'warning');
      return;
    }

    await onButtonClickSubmit(formNewValue);
  };

  handleCloseToast = () => {
    const { toastInformation } = this.state;
    this.setState({ toastInformation: { ...toastInformation, isOpen: false } });
  };

  processMessage(messages, type) {
    const convertedMessage = CommonHelper.generateMessage(messages);

    const paramInformation = {
      isOpen: true,
      message: convertedMessage,
      snackbarType: type,
    };

    this.setState({
      toastInformation: paramInformation,
    });
  }

  renderCartItem() {
    const { form, isValidPromoCode } = this.state;
    const { orderData, parentForm } = this.props;

    return (
      <CartItemBooking
        form={form}
        parentForm={parentForm}
        orderData={orderData}
        isValidPromoCode={isValidPromoCode}
        transportFeeProduct={form.transportFeeProduct}
        onPriceTransportUpdated={this.handleTransportPriceUpdated}
      />
    );
  }

  render() {
    const {
      form,
      validation,
      isValidPromoCode,
      isSuccess,
      disablePromoCode,
      paymentMethodOption,
      toastInformation,
    } = this.state;
    const {
      onButtonClickCancel,
      onButtonClickReschedule,
      orderData,
      scheduleSummary,
      parentForm,
    } = this.props;

    const successIcon = 'ic-ffi-check-mark';
    const promoDesc = orderData.promoCodeDetails.cashback_amount_desc;
    const potentialPromo =
      orderData.promoCodeDetails.promo_code_cashback_type_id === promoTypeCode.Discount
        ? `Potential Discount${promoDesc}`
        : `Potential Cashback${promoDesc}`;

    const disableContent = scheduleSummary.fetching;
    const isDisablePromoCode = orderData.fetching || disablePromoCode;

    const name = `${parentForm.step_booking_user_details.firstName} ${parentForm.step_booking_user_details.lastName}`;
    const showBankTransferOption = form.paymentMethod === paymentMethodOptionCode.bank_transfer;

    return (
      <Grid
        container
        direction="column"
        className="container-step-booking-summary-payment flex-wrap-unset"
      >
        <Grid item lg={12} md={12}>
          <Grid container spacing={3}>
            <Grid item lg={6} md={6} className="mt-24">
              <FormControl component="fieldset" fullWidth margin="normal">
                <FormLabel component="label" className="text-12 filed-title">
                  Contact Person
                </FormLabel>
                <Grid
                  container
                  direction="column"
                  className="container-row-table details flex-wrap-unset"
                >
                  <GridRowTableOneColumn
                    customElementInitial={<label className="text-14 title">Name</label>}
                    customElementColumn={<label className="text-14">{name}</label>}
                    columnLeft={5}
                    columnRight={7}
                  />
                  <GridRowTableOneColumn
                    customElementInitial={<label className="text-14 title">Email</label>}
                    customElementColumn={
                      <label className="text-14">
                        {parentForm.step_booking_user_details.email}
                      </label>
                    }
                    columnLeft={5}
                    columnRight={7}
                  />
                  <GridRowTableOneColumn
                    customElementInitial={<label className="text-14 title">Phone Number</label>}
                    customElementColumn={
                      <label className="text-14">
                        {parentForm.step_booking_user_details.phone}
                      </label>
                    }
                    columnLeft={5}
                    columnRight={7}
                  />
                  <GridRowTableOneColumn
                    customElementInitial={<label className="text-14 title">Gender</label>}
                    customElementColumn={
                      <label className="text-14">
                        {(parentForm.step_booking_user_details.gender &&
                          parentForm.step_booking_user_details.gender.name) ||
                          'Male'}
                      </label>
                    }
                    columnLeft={5}
                    columnRight={7}
                  />
                  <GridRowTableOneColumn
                    customElementInitial={<label className="text-14 title">Order Type</label>}
                    customElementColumn={
                      <label className="text-14 capitalize">{parentForm.orderType}</label>
                    }
                    columnLeft={5}
                    columnRight={7}
                  />
                </Grid>
              </FormControl>
            </Grid>
            {parentForm.step_booking_product_details &&
              !_.isEmpty(parentForm.step_booking_product_details) && (
                <Grid item lg={6} md={6} className="mt-24">
                  <DetailsAppointment
                    orderData={parentForm.step_booking_product_details}
                    handleButtonReschedule={onButtonClickReschedule}
                  />
                </Grid>
              )}
          </Grid>
        </Grid>
        <Grid item lg={12} md={12} className="mt-24">
          <FormControl component="fieldset" fullWidth margin="normal">
            <FormLabel component="label" className="text-12 filed-title">
              Booking Details
            </FormLabel>
            <CustomerItemBooking
              customerList={parentForm.step_booking_customer_details}
              onRemove={this.handleRemoveCustomer}
              onUpdatePhoto={this.handleUpdatePhoto}
            />
          </FormControl>
        </Grid>
        <Grid item lg={12} md={12}>
          <Grid container spacing={2}>
            <Grid item lg={6} md={6}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <FormLabel component="label" className="text-12 filed-title">
                  Payment Method
                </FormLabel>
                <SelectInputMain
                  options={paymentMethodOption}
                  currentValue={form.paymentMethod || paymentMethodOptionCode.bank_transfer}
                  onChange={this.handleSelectPaymentMethod}
                  disabled={disableContent}
                />
              </FormControl>
            </Grid>
            {showBankTransferOption && (
              <Grid item lg={6} md={6} className="mt-27">
                <FormControl component="fieldset" fullWidth margin="normal">
                  <RadioInputImage
                    data={bankTransferOption}
                    onSelect={this.handleChangeBankTransfer}
                    direction="column"
                    currentValue={form.bank}
                    disabled={disableContent}
                    imageWidth="60px"
                  />
                </FormControl>
              </Grid>
            )}
            <Grid item lg={6} md={6}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <FormLabel component="label" className="text-12 filed-title">
                  Transaction Reference
                </FormLabel>
                <div className="container-promo">
                  <TextInput
                    placeHolderText="Please enter Transaction Ref. if any"
                    size="md"
                    onChange={this.handleChangeTransactionReference}
                    currentValue={form.transactionReference}
                  />
                </div>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={12} md={12}>
          <Grid container spacing={2}>
            <Grid item lg={6} md={6}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <FormLabel component="label" className="text-12 filed-title">
                  Promo Code
                </FormLabel>
                <div className={`container-promo ${isSuccess}`}>
                  <TextInput
                    placeHolderText="Promo Code"
                    size="md"
                    onChange={this.handleChangePromoCodeEntry}
                    currentValue={form.promoCode}
                    errorMessage={validation.promoCode.errorMessage}
                    isError={validation.promoCode.isError}
                    iconPrefixEnd={isValidPromoCode ? successIcon : ''}
                    isDisable={isDisablePromoCode || disableContent}
                  />
                </div>
              </FormControl>
            </Grid>
            <Fade in={isSuccess === 'success'} timeout={timeInterval}>
              <Grid item lg={6} md={6} className="mt-43">
                <LabelAlert message={potentialPromo} type="success" size="middle" />
              </Grid>
            </Fade>
          </Grid>
        </Grid>
        <Grid item lg={12} md={12}>
          {!_.isEmpty(parentForm.step_booking_customer_details) && this.renderCartItem()}
        </Grid>
        <SnackBarSimple
          open={toastInformation.isOpen}
          durationHide={2000}
          message={toastInformation.message}
          onClickClose={this.handleCloseToast}
          snackbarType={toastInformation.snackbarType}
          anchor={optionToast}
        />
        <Grid item lg={12} md={12}>
          <Grid container justify="flex-end">
            <Grid item lg={5} md={6}>
              <Grid container justify="space-around">
                <Grid item lg={4} md={4} className="pl-8">
                  <ButtonMain
                    type="negative"
                    size="xl"
                    labelText="Cancel"
                    isLoading={disableContent}
                    onClick={onButtonClickCancel}
                  />
                </Grid>
                <Grid item lg={4} md={4} className="pl-8">
                  <ButtonMain
                    type="ghost"
                    size="xl"
                    labelText="Prev"
                    isLoading={disableContent}
                    onClick={this.handlePrevClick}
                  />
                </Grid>
                <Grid item lg={4} md={4} className="pl-8">
                  <ButtonMain
                    type="primary"
                    size="xl"
                    labelText="Save"
                    isLoading={disableContent}
                    onClick={this.handleSubmitClick}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  checkPromo: params => checkPromoCode(dispatch, params),
});

const mapStateToProps = ({ orderData, scheduleSummary, medicData }) => ({
  orderData,
  scheduleSummary,
  medicData,
});

StepBookingSummaryAndPayment.propTypes = {
  checkPromo: PropTypes.func,
  medicData: PropTypes.object,
  onButtonClickCancel: PropTypes.func,
  onButtonClickReschedule: PropTypes.func,
  onButtonClickSubmit: PropTypes.func,
  onParameterUpdate: PropTypes.func,
  onPrevClick: PropTypes.func,
  orderData: PropTypes.object,
  parentForm: PropTypes.object,
  scheduleSummary: PropTypes.object,
};

const shell = compose(connect(mapStateToProps, mapDispatchToProps));

export default shell(PrevNextStepperCount(StepBookingSummaryAndPayment));
