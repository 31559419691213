import React from 'react';
import PropTypes from 'prop-types';
import { Page, Text, View, Document, Font } from '@react-pdf/renderer';
// Style
import StylesJs from './LabelMealStyle';
// assets
import { StyleJs as StyleJsGlobal } from '../../../../../assets/Index';
// Helpers
import { CommonHelper } from '../../../../../helpers/Index';

// Register font
Font.register({
  family: 'Roboto',
  fonts: [
    { src: './src/assets/fonts/montserrat/Montserrat-Regular.ttf' },
    { src: './src/assets/fonts/montserrat/Montserrat-Bold.ttf', fontWeight: 700 },
  ],
});

const LabelMeal = props => {
  const { data } = props;

  return (
    <Document>
      <Page size="A4" orientation="portrait" style={{ paddingVertical: 22, paddingHorizontal: 0 }}>
        <View
          style={[
            StyleJsGlobal.gridRow,
            {
              width: 845,
            },
          ]}
        >
          {data.map((item, index) => {
            const date = CommonHelper.dateTimeParseNewFormat(item.date, 'DD MMMM YYYY');
            const isOdd = (index + 1) % 2;
            return (
              <View
                key={index}
                style={[
                  StyleJsGlobal.gridItem6,
                  {
                    width: '100%',
                  },
                ]}
                wrap={false}
              >
                <View style={[StyleJsGlobal.gridColumn]}>
                  <View
                    style={[
                      StylesJs.containerCardLabel,
                      isOdd
                        ? { alignSelf: 'flex-end', paddingRight: 3 }
                        : { alignSelf: 'flex-start', paddingLeft: 3 },
                    ]}
                  >
                    <View style={[StylesJs.containerLabel]}>
                      <View
                        style={[StyleJsGlobal.flexRow, StyleJsGlobal.justifyContentSpaceBetween]}
                      >
                        <View
                          style={[
                            StyleJsGlobal.flexRow,
                            StyleJsGlobal.alignItemCenter,
                            StylesJs.flex1,
                          ]}
                        >
                          <Text style={[StylesJs.textCustomerName, { marginRight: 5 }]}>
                            {item.customer_name}
                          </Text>
                          <Text style={[StylesJs.textPax]}>{item.pax}</Text>
                        </View>
                        <View style={{ alignItems: 'flex-end' }}>
                          <Text style={[StylesJs.textDate]}>{date}</Text>
                        </View>
                      </View>

                      <View style={[StylesJs.containerName]}>
                        <Text style={[StylesJs.textHeading]}>
                          {item.foodlist.toLowerCase() === 'lokalori'
                            ? item.foodlist
                            : `FITCO Eats - ${item.foodlist}`}
                        </Text>
                        <Text style={[StylesJs.textName]}>
                          {item.lunch_or_dinner.toUpperCase()} - {item.name}
                        </Text>
                        {item.extras && <Text style={[StylesJs.text]}>{item.extras}</Text>}
                      </View>

                      <Text style={[StylesJs.textHeading]}>Meal Notes</Text>
                      {item.notes ? (
                        <Text style={[StylesJs.textNotes]}>{item.notes}</Text>
                      ) : (
                        <Text style={[StylesJs.textNotes]}>-</Text>
                      )}
                    </View>
                  </View>
                </View>
              </View>
            );
          })}
        </View>
      </Page>
    </Document>
  );
};

LabelMeal.propTypes = {
  data: PropTypes.array,
};

export default LabelMeal;
