import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { compose } from 'redux';
// component
import { HeaderMembershipPurchase } from './components/Index';
// view
import { MembershipPurchasePage } from './Index';

class MembershipPurchaseMain extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  componentWillUnmount() {}

  handleButtonPurchaseMembership = () => {
    const { history } = this.props;
    history.push('/membership/purchase/create');
  };

  renderPages = () => {
    const { history } = this.props;

    return <MembershipPurchasePage history={history} />;
  };

  render() {
    return (
      <div>
        <Helmet title={`FITCO | Membership - Request`} />
        <div>
          <HeaderMembershipPurchase onButtonCreateClick={this.handleButtonPurchaseMembership} />
          {this.renderPages()}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = () => ({});

const mapStateToProps = () => ({});

MembershipPurchaseMain.propTypes = {
  history: PropTypes.object,
};

const shell = compose(connect(mapStateToProps, mapDispatchToProps));

export default shell(MembershipPurchaseMain);
