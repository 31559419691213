import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
// Style
import './CheckInputButtonStyle.scss';
// component
import ButtonMain from '../button-main/ButtonMain';
import { CommonHelper } from '../../../helpers/Index';

const CheckInputButton = props => {
  const { title, data, dataOption, direction, onButtonClickAdd, col, disabled } = props;
  const [selectState, setSelectState] = React.useState(data);

  React.useEffect(() => {
    const updateSelectState = async () => {
      const existingItem = CommonHelper.objectCloning(dataOption);

      data.forEach(item => {
        const index = existingItem.findIndex(itemOption => itemOption.value === item);
        if (index >= 0) {
          const resultItem = existingItem[index]; // grouping update
          resultItem.isVisible = true;
        }
      });

      setSelectState(existingItem);
    };
    updateSelectState();
  }, [data, dataOption]);

  const handleButtonClickAdd = value => {
    const existingItem = CommonHelper.objectCloning(data);
    let modifySelect = existingItem;

    const index = existingItem.findIndex(item => item === value);
    if (index !== -1) {
      modifySelect.splice(index, 1);
    } else {
      modifySelect = [...existingItem, value];
    }

    onButtonClickAdd(modifySelect);
  };

  return (
    <Grid
      container
      direction={direction}
      className="container-check-input-button"
      alignItems="center"
    >
      {title && (
        <Grid item className="container-item">
          <label className="text-14 title">{title}</label>
        </Grid>
      )}
      {selectState.map((item, index) => {
        const isActiveType = item.isVisible ? 'primary' : 'ghost';
        const isActiveIcon = item.isVisible ? 'ic-ffo-check-mark' : 'ic-ffo-add';

        return (
          <Grid key={index} item lg={col} md={col} className="container-item">
            <ButtonMain
              labelText={item.name}
              onClick={() => handleButtonClickAdd(item.value)}
              type={isActiveType}
              startIcon={isActiveIcon}
              disabled={disabled}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

CheckInputButton.propTypes = {
  col: PropTypes.number,
  data: PropTypes.array,
  dataOption: PropTypes.array,
  direction: PropTypes.string,
  disabled: PropTypes.bool,
  onButtonClickAdd: PropTypes.func,
  title: PropTypes.string,
};

export default CheckInputButton;
