/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-lonely-if */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, FormControl, FormLabel } from '@material-ui/core';
import _ from 'lodash';
// Style
import './CartItemBookingStyle.scss';
// Component
import {
  GridRowTableFiveColumn,
  GridRowTableOneColumn,
  ButtonIconMain,
  TextInput,
} from '../../../../../../components/Index';
// helper
import {
  CommonHelper,
  OrderHelper,
  serviceLocationCode,
  promoTypeCode,
} from '../../../../../../helpers/Index';

const calculateDraftGrandTotal = (items, isValidPromoCode, promoCodeDetails, transportFee) => {
  let grandTotal = OrderHelper.getGrandTotal(items);
  let discountAmount = 0;
  if (!_.isEmpty(promoCodeDetails) && isValidPromoCode) {
    grandTotal = promoCodeDetails.totalPriceAfterReward;
    discountAmount = promoCodeDetails.cashback_amount;
  }
  return {
    grandTotal: grandTotal + +transportFee,
    discountAmount,
  };
};

const CartItemBooking = props => {
  const {
    form: { promoCode },
    orderData: { promoCodeDetails },
    detailOrderData,
    isDraft,
    isValidPromoCode,
    onPriceTransportUpdated,
  } = props;

  const [transportFee, setTransportFee] = useState(detailOrderData.order_summary.shipping_fee || 0);

  useEffect(() => {
    setTransportFee(transportFee);
  }, [transportFee]);

  const onChangeTransportPriceHandler = price => {
    setTransportFee(price);
    onPriceTransportUpdated(price);
  };

  const items = detailOrderData.order_summary.product_details.filter(item => {
    return item.product_sku !== 'fitco-medic-apd-fee';
  });
  let transportFeeProduct = {
    price: detailOrderData.order_summary.shipping_fee,
    name: detailOrderData.order_summary.shipping_title,
  };
  if (detailOrderData.appointment_details.service_location === serviceLocationCode.InStore) {
    transportFeeProduct = {};
  }

  let grandTotal = 0;
  let totalItem = 0;
  let discountAmount = 0;

  // Calculate grand total and discount
  if (isDraft) {
    const { grandTotal: total, discountAmount: discount } = calculateDraftGrandTotal(
      items,
      isValidPromoCode,
      promoCodeDetails,
      transportFee,
    );
    grandTotal = total;
    discountAmount = discount;
  } else {
    grandTotal = detailOrderData.order_summary.grand_total;
    discountAmount = detailOrderData.order_summary.price_discount;

    if (detailOrderData.payment_details.promo_code && detailOrderData.payment_details.is_cashback) {
      discountAmount = detailOrderData.payment_details.cashback_amount;
    }
  }

  const promoCodeInformation = `Promo Code ( ${promoCode} )`;

  return (
    <FormControl
      component="fieldset"
      fullWidth
      margin="normal"
      className="container-booking-lab-cart-item mt-32"
    >
      <FormLabel component="label" className="text-16 filed-title">
        Order Summary
      </FormLabel>

      <div className="flex-column">
        <GridRowTableFiveColumn
          customElementColumnFirst={<label className="text-14 header-title">Product Name</label>}
          customElementColumnSecond={<label className="text-14 header-title">Customer Name</label>}
          customElementColumnThird={
            <div className="text-center">
              <label className="text-14 header-title">Qty</label>
            </div>
          }
          customElementColumnFourth={
            <div className="text-right">
              <label className="text-14 header-title">Total Price</label>
            </div>
          }
          columnFirst={3}
          columnSecond={3}
          columnThird={2}
          columnFourth={2}
          columnFifth={2}
        />
        <div className="scroll-container">
          <Grid container direction="column" className="flex-wrap-unset">
            {items.map((item, index) => {
              totalItem += item.quantity;
              const price = item.item_price;
              const priceTotal = item.quantity * parseInt(price, 10);

              return (
                <GridRowTableFiveColumn
                  key={index}
                  customElementColumnFirst={
                    <label className="text-14 row-item-title">{item.product_name}</label>
                  }
                  customElementColumnSecond={
                    <label className="text-14">{(item.details && item.details.name) || ''}</label>
                  }
                  customElementColumnThird={
                    <div className="text-center">
                      <label className="text-14">{item.quantity}</label>
                    </div>
                  }
                  customElementColumnFourth={
                    <div className="text-right">
                      <label className="text-14">
                        IDR {CommonHelper.formatCurrency(priceTotal)}
                      </label>
                    </div>
                  }
                  columnFirst={3}
                  columnSecond={3}
                  columnThird={2}
                  columnFourth={2}
                  columnFifth={2}
                />
              );
            })}
          </Grid>
        </div>
        {!_.isEmpty(transportFeeProduct) && (
          <TransportItem
            transportItem={transportFeeProduct}
            onChangePrice={onChangeTransportPriceHandler}
            isDraft={isDraft}
          />
        )}
        {isValidPromoCode && (
          <GridRowTableOneColumn
            customElementInitial={
              <div className="flex-column">
                <label className="text-14 row-item-title">
                  {promoCodeDetails.promo_code_cashback_type_id === promoTypeCode.Discount
                    ? 'Discount'
                    : 'Cashback'}
                </label>
                <label className="text-10 ">{promoCodeInformation}</label>
              </div>
            }
            customElementColumn={
              <div className="text-right">
                <label className="text-14 discount">
                  {promoCodeDetails.promo_code_cashback_type_id === promoTypeCode.Discount
                    ? `IDR ${CommonHelper.formatCurrency(discountAmount)}`
                    : `${CommonHelper.formatCurrency(discountAmount)} FIT Points`}
                </label>
              </div>
            }
            columnLeft={8}
            columnRight={2}
            paddingRight=""
          />
        )}
        <GridRowTableOneColumn
          customElementInitial={
            <label className="text-16 row-item-title">Total Item(s) : {totalItem}</label>
          }
          customElementColumn={
            <div className="text-right">
              <label className="text-16 row-item-title">
                Grand Total &nbsp; &nbsp; &nbsp;{' '}
                {grandTotal !== 0 ? `IDR ${CommonHelper.formatCurrency(grandTotal)}` : 'Free'}
              </label>
            </div>
          }
          columnLeft={5}
          columnRight={5}
          paddingRight=""
        />
      </div>
    </FormControl>
  );
};

CartItemBooking.propTypes = {
  detailOrderData: PropTypes.object,
  form: PropTypes.object,
  isDraft: PropTypes.bool,
  isValidPromoCode: PropTypes.bool,
  onPriceTransportUpdated: PropTypes.func,
  orderData: PropTypes.object,
};

export default CartItemBooking;

const TransportItem = ({ transportItem, onChangePrice, isDraft }) => {
  const [isEdit, setIsEdit] = useState(false);
  const [remove, setRemove] = useState(false);
  const [transportFee, setTransportFee] = useState(transportItem.price);

  useEffect(() => {
    if (remove) {
      onChangePrice(0);
    }
  }, [remove]);

  useEffect(() => {
    setTransportFee(transportItem.price);
  }, [transportItem.price]);

  const setEditAmount = () => {
    setIsEdit(!isEdit);
  };

  const setDeleteAmount = () => {
    setRemove(true);
  };

  const handleChangeAmount = value => {
    setTransportFee(value);
    onChangePrice(value);
  };

  return (
    <GridRowTableFiveColumn
      customElementColumnFirst={
        <label className="text-14 row-item-title">{transportItem.name}</label>
      }
      customElementColumnSecond={
        <div className="text-center">
          <label className="text-14">{''}</label>
        </div>
      }
      customElementColumnThird={
        <div className="text-center">
          <label className="text-14">{transportItem.qty || 1}</label>
        </div>
      }
      customElementColumnFourth={
        <div className="text-right">
          {isEdit ? (
            <TextInput
              onChange={value => {
                handleChangeAmount(+value);
              }}
              currentValue={transportFee}
              size="md"
              numericOnly
            />
          ) : (
            <label className="text-14">IDR {CommonHelper.formatCurrency(transportFee)}</label>
          )}
        </div>
      }
      customElementColumnFifth={
        <>
          {isDraft && (
            <Grid container justify="flex-end">
              <Grid item lg={3} md={3}>
                <ButtonIconMain
                  icon="ic-ffo-edit"
                  type="secondary"
                  size="xs"
                  onClick={() => {
                    setEditAmount();
                  }}
                  tooltip="Edit"
                />
              </Grid>
              <Grid item lg={3} md={3}>
                <ButtonIconMain
                  icon="ic-ffo-bin"
                  type="negative"
                  size="xs"
                  onClick={() => {
                    setDeleteAmount();
                  }}
                  tooltip="Delete"
                />
              </Grid>
            </Grid>
          )}
        </>
      }
      columnFirst={3}
      columnSecond={3}
      columnThird={2}
      columnFourth={2}
      columnFifth={2}
    />
  );
};

TransportItem.propTypes = {
  isDraft: PropTypes.bool,
  onChangePrice: PropTypes.func,
  transportItem: PropTypes.object,
};
