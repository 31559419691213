/* eslint-disable no-plusplus */
// import { CommonHelper, MasterDataHelper } from './Index';
import _ from 'lodash';
import CommonHelper from './CommonHelper';
import { eatSessionTypeCode, MasterDataHelper, paymentMethodOptionCode } from './MasterDataHelper';
import { OrderType } from './TransactionHelper';
import { UserRoleName } from './UserHelper';

const OrderHelper = {
  getGrandTotal(dataArray, shippingCost = 0, discount = 0, extra = 0) {
    let result = 0;

    dataArray.forEach(item => {
      result +=
        (+item.qty || +item.quantity) *
        (+item.price || +item.normal_price || +item.item_price || 0);
    });

    result = result + extra + shippingCost - discount;
    return result;
  },
  getGrandTotalLab(dataArray) {
    let result = 0;

    dataArray.forEach(item => {
      result += (+item.productDetails.qty || 1) * (+item.productDetails.price || 0);
    });

    return result;
  },
  getGrandTotalAtStore(dataArray, paymentMethod, isGuestCheckout = false, isStaffCheckout = false) {
    let result = 0;

    dataArray.forEach(item => {
      let itemPrice = 0;
      switch (paymentMethod) {
        case paymentMethodOptionCode.gofood:
          itemPrice = item.gofood_price;
          break;
        case paymentMethodOptionCode.grabfood:
          itemPrice = item.grabfood_price;
          break;
        case paymentMethodOptionCode.shopeefood:
          itemPrice = item.shopeefood_price;
          break;
        default:
          if (isStaffCheckout) {
            itemPrice = item.staff_price;
          } else if (isGuestCheckout) {
            itemPrice = item.normal_price;
          } else {
            itemPrice = item.price;
          }
          break;
      }

      result += parseInt(item.qty, 10) * parseInt(itemPrice, 10);
    });

    return result;
  },
  getGrandTotalFloatPrice(dataArray, shippingCost = 0, discount = 0, extra = 0) {
    let result = 0;

    dataArray.forEach(item => {
      result += item.qty * item.price;
    });

    result = result + extra + shippingCost - discount;
    return result;
  },
  getGrandTotalTerm(dataArray = [], term = 0) {
    let result = 0;

    dataArray.forEach(item => {
      result += parseInt(term, 10) * parseInt(item.price, 10);
    });

    return result;
  },
  getWholesaleDiscount(dataArray) {
    let totalWholesaleDiscount = 0;

    dataArray.forEach(item => {
      let itemWholesaleDiscount = 0;
      item.wholesale_discount.forEach(record => {
        if (item.qty >= record.minimum_quantity) {
          itemWholesaleDiscount = Math.floor(
            (parseInt(item.price, 10) * item.qty * parseInt(record.price_discount, 10)) / 100,
          );
        }
      });

      totalWholesaleDiscount += itemWholesaleDiscount;
    });

    return totalWholesaleDiscount;
  },
  getGrandTotalWeightAndFitMarket(dataArray) {
    let totalWeight = 0;
    let totalItem = 0;
    let isFitMarket = false;

    dataArray.forEach(item => {
      totalWeight += item.qty * item.weight;
      totalItem += item.qty;
      if (item.is_fit_market) {
        isFitMarket = true;
      }
    });

    const param = {
      weight: totalWeight,
      item: totalItem,
      isFitMarket,
    };

    const result = param;

    return result;
  },
  generateAddressOrderOption(dataArray, dataExisting, keyParent = null) {
    const converted = dataExisting || [];

    Object.keys(dataArray).forEach(key => {
      const additional = dataArray[key];
      const modifyKey = keyParent || key;

      if (additional) {
        const name = additional.address_name;
        const value = additional.address_type;
        const param = { value, name, additional, key: modifyKey };

        if (value !== undefined) {
          converted.push(param);
        } else if (value === undefined) {
          const checkIfArray = _.every(additional, _.isObject);

          if (checkIfArray) {
            this.generateAddressOrderOption(additional, converted, key);
          }
        }
      }
    });

    const result = converted;

    return result;
  },
  generateAddressOrderGroupOption(dataArray) {
    const converted = dataArray;
    const reConverted = [];
    let countFind = 0;

    for (let index = 0; index < converted.length; index++) {
      const itemFinder = CommonHelper.objectCloning(converted[index]);
      const labelGroup = itemFinder.key;
      const sliceIndex = index + 1 !== converted.length ? index + 1 : 0;
      const dataCheck = CommonHelper.objectCloning(converted).slice(sliceIndex, converted.length);
      const checkIfArraySameValue = _.find(dataCheck, ['value', itemFinder.value]);

      if (checkIfArraySameValue) {
        countFind += 1;
        itemFinder.value = `${itemFinder.value} ${countFind}`;
        itemFinder.name = `${itemFinder.name} ${countFind}`;
      }

      const param = {
        label: labelGroup,
        group: [itemFinder],
      };

      reConverted.push(param);
    }

    const result = reConverted;
    return result;
  },
  generateAddressOrderGroupSameOption(dataArray) {
    const result = Object.values(
      dataArray.reduce((c, { label, group }) => {
        c[label] = c[label] || { label, group: [] };
        c[label].group = c[label].group.concat(Array.isArray(group) ? group : [group]);
        return c;
      }, {}),
    );

    return result;
  },
  findAddressOrderGroupOption(dataArray, matchValue) {
    const splitValue = matchValue.split(' ');

    const allDataContainSplit0 = [];

    dataArray.forEach(item => {
      if (item.value === splitValue[0]) {
        allDataContainSplit0.push(item);
      }
    });

    const result = allDataContainSplit0[parseInt(splitValue[1], 10) - 1];

    return result;
  },
  reformatDeliveryServiceOption(dataArray) {
    const converted = [];

    dataArray.forEach(item => {
      const courier = CommonHelper.renameFirstUpperCase(item.courier);
      // let price = 0;
      // switch(item.cost[0].value) {
      //   case null:
      //     price = `( Custom Shipping )`;
      //     break;
      //   case 0:
      //     price = `( Free )`;
      //     break;
      //   default:
      //     price = `( IDR ${CommonHelper.formatCurrency(item.cost[0].value)} )`;
      //     break;
      // }

      // let name = `${courier} ${item.service} ${price}`;
      // name = item.service === 'Free' ? `${item.service} ${price}` : name;

      const name = item.service === 'Free' ? `${item.service}` : `${courier} ${item.service}`;

      const param = {
        additional: item,
        value: item.service,
        name,
      };

      converted.push(param);
    });

    const result = converted;

    return result;
  },
  getSummaryFinalRecipient(dataArray) {
    const name = dataArray.recipientName || '-';
    const phone = dataArray.recipientPhone || '-';
    const addressType = dataArray.addressType
      ? CommonHelper.renameRemoveCharacter(dataArray.addressType, '_')
      : '-';

    const result = `${name} - ${phone} [${addressType}]`;

    return result;
  },
  formatItemSubmit(dataArray, membershipInfo = {}, isAtStore) {
    const converted = [];
    dataArray.forEach(item => {
      const param = {
        qty: item.qty || item.quantity,
      };

      if (item.training_schedule_id) {
        param.product_id = item.product_id;
        param.training_schedule_id = item.training_schedule_id;
        if (!_.isEmpty(membershipInfo)) {
          param.details = {
            average_price: membershipInfo.average_price_membership,
            is_average_price: membershipInfo.is_average_price_membership,
          };
        }
        if (item.trainer_id) {
          param.details = {
            trainer_id: item.trainer_id,
          };
        } else {
          param.details = { ...item.details };
        }
      } else if (item.catering_schedule_id) {
        let extras = [];
        if (item.extras) {
          extras = item.extras;
        } else if (item.details && item.details.extras) {
          item.details.extras.forEach(record => {
            extras.push(record.product_id);
          });
        }

        param.catering_schedule_id = item.catering_schedule_id;
        param.product_id = item.product_id ? item.product_id : item.food_details.product_id;
        param.extras = extras;
        param.details = {
          notes: item.notes || (item.details && item.details.notes),
        };
      } else if (isAtStore) {
        param.product_id = item.product_id;
        param.details = {
          notes: item.notes || (item.details && item.details.notes),
          served: item.details && item.details.served,
        };
      } else {
        param.product_id = item.product_id;
      }

      converted.push(param);
    });
    const result = converted;
    return result;
  },
  formatItemSubmitLab(dataArray = []) {
    const converted = [];
    dataArray.forEach(item => {
      let identityDetails = {
        ...item.identity_details,
      };

      if (!item.identity_details) {
        identityDetails = {
          ...item.details,
        };
      }

      const param = {
        details: {},
        identity_details: identityDetails,
        product_id: item.product_id,
      };

      converted.push(param);
    });
    const result = converted;
    return result;
  },
  formatItemSubmitPurchaseOrder(dataArray = []) {
    const converted = [];
    dataArray.forEach(item => {
      const param = {
        product_id: item.product_id,
        special_price: item.price,
        qty: item.qty,
        is_complementary_product: item.is_complementary_product
          ? item.is_complementary_product
          : false,
        purchase_order_item_id: item.purchase_order_item_id ? item.purchase_order_item_id : null,
      };

      converted.push(param);
    });
    const result = converted;
    return result;
  },
  generateEatProductOption(data) {
    const converted = [];
    data.forEach(item => {
      const param = {
        value: item.foodlist_id,
        name: item.name,
        option: item,
      };

      converted.push(param);
    });

    const result = converted;
    return result;
  },
  generateEatListGrouping(
    data,
    existingDat = [],
    qty = 0,
    extras = [],
    singlePrice = 0,
    notes = '',
  ) {
    const converted = [];
    const finalConverted = existingDat;
    let flagPush = false;
    let currentDate = '';
    const temporaryData = CommonHelper.objectCloning(data);

    if (!_.isEmpty(data)) {
      for (let index = 0; index < data.length; index++) {
        const item = data[index];
        item.qty = item.qty || qty;
        item.extras = item.extras || extras;
        item.price = singlePrice === 0 ? item.price : singlePrice;
        item.notes = item.notes || notes;

        if (!currentDate) {
          currentDate = item.date;
          converted.push(item);
          temporaryData.splice(0, 1);

          if (temporaryData.length === 0) {
            flagPush = true;
          }
        } else if (currentDate === item.date) {
          currentDate = item.date;
          converted.push(item);
          temporaryData.splice(0, 1);

          if (temporaryData.length === 0) {
            flagPush = true;
          }
        } else {
          flagPush = true;
        }

        if (flagPush) {
          index = data.length;

          const param = {
            date: currentDate,
            data: converted,
          };
          finalConverted.push(param);

          this.generateEatListGrouping(
            temporaryData,
            finalConverted,
            qty,
            extras,
            singlePrice,
            notes,
          );
        }
      }
    }

    const result = finalConverted;
    return result;
  },
  restoreEatList(dataArray = [], existingDataArray = []) {
    if (!_.isEmpty(existingDataArray)) {
      dataArray.forEach(item => {
        for (let index = 0; index < existingDataArray.length; index++) {
          const itemExist = existingDataArray[index];
          if (itemExist.catering_schedule_id === item.catering_schedule_id) {
            item.qty = itemExist.qty;
            // item.extras = itemExist.extras;
            index = existingDataArray.length;
          }
        }
      });
    }

    return dataArray;
  },
  generateEatOrderInFullList(data) {
    const converted = [];

    data.forEach(item => {
      converted.push(...item.data);
    });

    const result = converted;
    return result;
  },
  generateEatOrderInFullListSelected(data, existingData = []) {
    let converted = [];
    const restored = CommonHelper.objectCloning(existingData);
    const fullList = this.generateEatOrderInFullList(data);

    fullList.forEach(item => {
      if (item.qty > 0) {
        const pushItem = item;
        if (!_.isEmpty(existingData)) {
          for (let index = 0; index < existingData.length; index++) {
            const itemExist = existingData[index];
            if (itemExist.catering_schedule_id === item.catering_schedule_id) {
              index = existingData.length;

              const findIndex = restored.findIndex(
                itemIndex => itemIndex.catering_schedule_id === itemExist.catering_schedule_id,
              );

              if (findIndex !== -1) {
                restored.splice(findIndex, 1);
              }
            }
          }
        }
        converted.push(pushItem);
      }
    });

    converted = [...converted, ...restored];

    const result = converted;
    return result;
  },
  getGrandTotalExtra(data, dataExtra) {
    let total = 0;

    data.forEach(item => {
      const totalExtra = this.getTotalExtra(item.extras, dataExtra);
      total += totalExtra * item.qty || 0;
    });
    const result = total;
    return result;
  },
  getTotalExtra(data = [], dataExtra) {
    let total = 0;

    data.forEach(item => {
      const filterParam = dataExtra.filter(itemExtraOption => itemExtraOption.product_id === item);

      if (!_.isEmpty(filterParam)) {
        total += parseFloat(filterParam[0].normal_price, 0);
      }
    });

    const result = total;
    return result;
  },
  generateEatProductPackageOption(data) {
    const existingItem = CommonHelper.objectCloning(data);

    existingItem.forEach(item => {
      const tmpPackage = item.option.packages;

      tmpPackage.forEach((itemPackage, index) => {
        itemPackage.isChecked = index === 0;
        itemPackage.extraSession = index === 0 ? [eatSessionTypeCode.lunch] : [];
        itemPackage.totalPriceExtraSession = itemPackage.normal_price * itemPackage.pax;
      });
    });

    const result = existingItem;
    return result;
  },
  validateEatPackageExtraHasDefault(data) {
    const existingItemOption = CommonHelper.objectCloning(MasterDataHelper.eatSessionType).splice(
      0,
      2,
    );
    let checkHasDefaultValue = false;
    let countSession = 0;

    data.forEach(item => {
      existingItemOption.forEach(itemOption => {
        if (itemOption.value === item) {
          checkHasDefaultValue = true;
          countSession += 1;
        }
      });
    });

    const result = { isExist: checkHasDefaultValue, total: countSession };
    return result;
  },
  generateExtraWording(data, dataExtra) {
    let wording = '-';
    data.forEach((item, index) => {
      dataExtra.forEach(itemExtraOption => {
        if (item === itemExtraOption.product_id) {
          if (index === 0) {
            wording = `${itemExtraOption.name}`;
          } else if (index > 0) {
            if (data.length === index + 1) {
              wording += ` & ${itemExtraOption.name}`;
            } else {
              wording += `, ${itemExtraOption.name}`;
            }
          }
        }
      });
    });

    const result = wording;
    return result;
  },
  generateExtraWordingDetails(data = []) {
    let wording = '-';
    data.forEach((item, index) => {
      if (index === 0) {
        wording = `${item.name}`;
      } else if (index > 0) {
        if (data.length === index + 1) {
          wording += ` & ${item.name}`;
        } else {
          wording += `, ${item.name}`;
        }
      }
    });

    const result = wording;
    return result;
  },
  getCateringListItems(data = [], packages = {}) {
    const converted = [];
    let totalItem = 0;
    let foodlistId = '';

    data.forEach(item => {
      foodlistId = item.foodlist_id;

      if (_.isEmpty(packages)) {
        totalItem += item.qty;
      } else {
        totalItem += 1;
      }
      converted.push(item.catering_schedule_id);
    });

    const result = {
      foodlist_id: foodlistId,
      catering_schedules: converted,
      total_quantity: totalItem,
    };
    return result;
  },
  getSpecialDiscount(data, paymentType, paymentTerm, totalPrice) {
    let result = {};
    let discountProduct = 0;
    let finalPriceDiscount = 0;

    if (paymentType === paymentMethodOptionCode.bank_transfer) {
      discountProduct = data.debit_or_transfer_discount * 0.01 || 0;
    } else if (paymentType === paymentMethodOptionCode.credit_card) {
      discountProduct = data.creditcard_discount * 0.01 || 0;
    }

    if (!_.isEmpty(data)) {
      if (data.period === 1 && data.period_type === 'month') {
        if (paymentTerm === MasterDataHelper.paymentTermOption[0].value) {
          discountProduct += data.one_months_discount * 0.01 || 0;
        } else if (paymentTerm === MasterDataHelper.paymentTermOption[1].value) {
          discountProduct += data.three_months_discount * 0.01 || 0;
        } else if (paymentTerm === MasterDataHelper.paymentTermOption[2].value) {
          discountProduct += data.six_months_discount * 0.01 || 0;
        } else if (paymentTerm === MasterDataHelper.paymentTermOption[3].value) {
          discountProduct += data.twelve_months_discount * 0.01 || 0;
        }

        const actualPrice = totalPrice;
        finalPriceDiscount = actualPrice * discountProduct;

        result = {
          beforeDiscount: actualPrice,
          afterDiscount: actualPrice - finalPriceDiscount,
          amountDiscount: finalPriceDiscount,
        };
      }
    }

    return result;
  },
  specialCaseIdPaymentTerm(data) {
    let result = [];
    const optionActiveTerm = CommonHelper.objectCloning(MasterDataHelper.paymentTermOption);

    if (!_.isEmpty(data)) {
      const specialProductId = [6237, 6238]; //  temporary for some product in production

      const filterId = specialProductId.filter(item => item === data.option.product_id);

      if (!_.isEmpty(filterId)) {
        optionActiveTerm.splice(0, 1);
      } else if (data.option.period_type !== 'month' && data.option.period !== 1) {
        optionActiveTerm.splice(1, optionActiveTerm.length);
      }
    }

    result = optionActiveTerm;

    return result;
  },
  membershipMarkUpItems(dataArray, membershipInfo) {
    let passCheck = false;

    if (!_.isEmpty(dataArray) && !_.isEmpty(membershipInfo)) {
      dataArray.forEach(item => {
        if (membershipInfo.membership_details.remaining_quota > 0) {
          item.price = 0;
          passCheck = true;
        } else if (
          !membershipInfo.membership_details.quota &&
          membershipInfo.membership_details.used > 0
        ) {
          item.price = membershipInfo.average_price_membership;
          passCheck = true;
        } else if (
          !membershipInfo.membership_details.quota &&
          !membershipInfo.membership_details.used
        ) {
          item.price = 0;
          passCheck = true;
        } else {
          item.price = membershipInfo.average_price_membership;
          passCheck = false;
        }
      });
    }

    const result = {
      valid: passCheck,
      items: dataArray,
    };

    return result;
  },
  finderIndexProductById(dataArray, productId) {
    const result = dataArray.findIndex(item => item.product_id === productId);
    return result;
  },
  membershipGroupingItem(dataArray) {
    const newData = CommonHelper.objectCloning(dataArray);
    const converted = [];
    let totalPriceItem = 0;

    newData.forEach(item => {
      if (item.item_price === 0) {
        converted.push(item);
      } else {
        totalPriceItem += item.item_price;
      }
    });

    converted[0].item_price = totalPriceItem;

    return converted;
  },
  formatTermOfPaymentData(payload = {}) {
    const keys = Object.keys(payload);
    const formatTermOfPayment = keys.map(key => {
      return {
        amount: payload[key].amount,
        due_date: payload[key].due_date,
        order: payload[key].order,
      };
    });
    const orderedValue = CommonHelper.dataArrayOrder(formatTermOfPayment, 'order', 'asc');
    return orderedValue;
  },
  validateTermOfPayment: (data, grandTotal) => {
    const keys = Object.keys(data);
    if (!keys.length) {
      return false;
    }
    const accumulativeAmount = keys.map(key => +data[key].amount);
    const totalAmount = accumulativeAmount.reduce((a, b) => a + b, 0);
    return totalAmount === +grandTotal.toFixed(2);
  },
  getAutoFilterByRoleNameAndUserMerchant: (roleName, userMerchants) => {
    let config = {};

    switch (roleName) {
      case UserRoleName.EatExpressAdmin:
        config = {
          orderType: OrderType.Eat,
          orderEatType: MasterDataHelper.productTypeForOrderEats[2].value,
          selectedStore:
            userMerchants && userMerchants.length > 0 && userMerchants[0].branches.length > 0
              ? userMerchants[0].branches[0]
              : null,
        };
        break;
      default:
        config = {
          orderType: OrderType.Shop,
          orderEatType: null,
          selectedStore: null,
        };
        break;
    }
    return config;
  },
};

export default OrderHelper;
