/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Grid, FormControl, FormLabel } from '@material-ui/core';
import PropTypes from 'prop-types';
import _ from 'lodash';
// Components
import {
  TextInput,
  PickerInputDate,
  RadioInput,
  SelectInputMain,
  UploadImage,
} from '../../../../../../../components/Index';
// Helpers
import { CommonHelper, MasterDataHelper } from '../../../../../../../helpers/Index';

const currentDate = CommonHelper.currentDate('YYYY-MM-DD');
const defaultBirthday = currentDate;
const genderOptions = MasterDataHelper.gender;
const idTypeOptions = MasterDataHelper.idTypeOption;

const initialForm = {
  birthday: defaultBirthday,
  gender: 1,
  identity_number: '',
  identity_photo: '',
  identity_type: 'ktp',
  name: '',
};

const initialValidation = {
  name: { isError: false, errorMessage: '' },
  identity_number: { isError: false, errorMessage: '' },
};

const onValidationHandler = payload => {
  let validationNewValue = CommonHelper.objectCloning(initialValidation);
  const excludeValidationKeys = [];

  const keys = Object.keys(payload).filter(key => {
    return excludeValidationKeys.indexOf(key) === -1;
  });

  const errorValidationKeys = Object.keys(validationNewValue).filter(key => {
    return excludeValidationKeys.indexOf(key) === -1;
  });

  const errorKeys = keys.filter(key => {
    return !payload[key];
  });

  errorValidationKeys.forEach(key => {
    const isError = errorKeys.indexOf(key) !== -1;
    let errorDetail = {};
    switch (key) {
      case 'name':
        errorDetail = { isError, errorMessage: isError ? 'Please fill customer name' : '' };
        break;
      case 'identity_number':
        errorDetail = { isError, errorMessage: isError ? 'Please fill ID number' : '' };
        break;
      default:
        errorDetail = {
          isError,
          errorMessage: isError ? 'Please fill input' : '',
        };
        break;
    }
    validationNewValue = {
      ...validationNewValue,
      [key]: errorDetail,
    };
  });

  return { isError: Boolean(errorKeys.length), validationNewValue };
};

const BookingDetailsForm = ({
  selectedCustomer,
  validate,
  onValidationUpdate,
  onParameterUpdate,
}) => {
  const [form, setForm] = useState(initialForm);
  const [validation, setValidation] = useState(initialValidation);

  useEffect(() => {
    if (selectedCustomer) {
      const data = {
        birthday: selectedCustomer.birthday,
        gender: selectedCustomer.gender,
        identity_number: selectedCustomer.identity_number,
        identity_photo: selectedCustomer.identity_photo,
        identity_type: selectedCustomer.identity_type,
        name: selectedCustomer.name,
      };
      setForm(data);
    }
  }, [selectedCustomer]);

  useEffect(() => {
    const payload = {
      ...form,
    };
    onParameterUpdate(payload);
  }, [form]);

  useEffect(() => {
    if (validate) {
      const error = onValidationHandler(form);
      setValidation(error.validationNewValue);
      const payload = {
        ...error,
      };
      onValidationUpdate(payload);
    }
  }, [validate]);

  const onChangeHandler = (value, key) => {
    setForm(oldForm => ({
      ...oldForm,
      [key]: value,
    }));
  };

  const onChangeGenderHandler = gender => {
    setForm(oldForm => ({
      ...oldForm,
      gender: gender.value,
    }));
  };

  const onChangeIdentityType = value => {
    setForm(oldForm => ({
      ...oldForm,
      identity_type: value,
      identity_number: '',
    }));
  };

  const onChangeImageHandler = imageBase64 => {
    setForm(oldForm => ({
      ...oldForm,
      identity_photo: imageBase64,
    }));
  };

  const genderValue = _.find(genderOptions, ['value', form.gender]);

  return (
    <Grid item lg={12} md={12}>
      <Grid item lg={12} md={12} className="mb-4">
        <label className="text-12 text-bold text-rolling-stone">BOOKING DETAILS</label>
      </Grid>
      <Grid container spacing={2}>
        <Grid item lg={6} md={6}>
          <FormControl component="fieldset" fullWidth margin="normal">
            <FormLabel component="label" className="text-12 filed-title">
              Name
            </FormLabel>
            <TextInput
              placeHolderText="Name"
              size="md"
              onChange={value => onChangeHandler(value, 'name')}
              currentValue={form.name}
              errorMessage={validation.name.errorMessage}
              isError={validation.name.isError}
            />
          </FormControl>
        </Grid>
        <Grid item lg={6} md={6}>
          <Grid container direction="row" justify="space-between" spacing={6}>
            <Grid item lg={6} md={6}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <FormLabel component="label" className="text-12 filed-title">
                  Date of Birth
                </FormLabel>
                <PickerInputDate
                  customIcon="ic-ffo-date-pick"
                  dateFormat="dd-MM-yyyy"
                  maxDate={currentDate}
                  defaultValue={form.birthday}
                  onChange={value => onChangeHandler(value, 'birthday')}
                  toolbar={false}
                />
              </FormControl>
            </Grid>
            <Grid item lg={6} md={6}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <FormLabel component="label" className="text-12 filed-title">
                  Gender
                </FormLabel>
                <RadioInput
                  data={genderOptions}
                  onSelect={value => onChangeGenderHandler(value)}
                  direction="column"
                  currentValue={genderValue}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={6} md={6}>
          <FormControl component="fieldset" fullWidth margin="normal">
            <FormLabel component="label" className="text-12 filed-title">
              Identity Type
            </FormLabel>
            <SelectInputMain
              options={idTypeOptions}
              currentValue={form.identity_type}
              onChange={value => onChangeIdentityType(value)}
            />
          </FormControl>
        </Grid>
        <Grid item lg={6} md={6}>
          <FormControl component="fieldset" fullWidth margin="normal">
            <FormLabel component="label" className="text-12 filed-title">
              Identity Number
            </FormLabel>
            <TextInput
              placeHolderText="Please enter your Identity Number"
              size="md"
              onChange={value => onChangeHandler(value, 'identity_number')}
              currentValue={form.identity_number}
              maxLength={form.identity_type === 'ktp' || form.identity_type === 'kia' ? 16 : 10}
              type={form.identity_type === idTypeOptions[0].value ? 'tel' : 'text'}
              errorMessage={validation.identity_number.errorMessage}
              isError={validation.identity_number.isError}
              numericOnly={form.identity_type !== 'passport'}
            />
          </FormControl>
        </Grid>
        <Grid item lg={6} md={6}>
          <FormControl component="fieldset" fullWidth margin="normal">
            <FormLabel component="label" className="text-12 filed-title">
              Identity photo
            </FormLabel>
            <UploadImage
              onChange={item => onChangeImageHandler(item)}
              defaultValue={form.identity_photo}
              maxSize={5}
            />
          </FormControl>
        </Grid>
      </Grid>
    </Grid>
  );
};

BookingDetailsForm.propTypes = {
  onParameterUpdate: PropTypes.func,
  onValidationUpdate: PropTypes.func,
  selectedCustomer: PropTypes.object,
  validate: PropTypes.bool,
};

export default BookingDetailsForm;
