/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */
/* eslint-disable radix */
import React from 'react';
import PropTypes from 'prop-types';
import { TextField, ButtonGroup, IconButton } from '@material-ui/core';
// style
import './TextInputNumberV02Style.scss';

const TextInputNumberV02 = props => {
  const { currentValue, onChange, max, min, disabled } = props;
  const [stateValue, setStateValue] = React.useState(currentValue);

  React.useEffect(() => {
    const updateValue = async () => {
      setStateValue(currentValue);
    };
    updateValue();
    // const feedbackValue = async () => {
    //   onChange(stateValue);
    // };
    // feedbackValue();
  }, [currentValue]);

  const onChangeTextInput = event => {
    const result = event.target.value;
    setStateValue(result);
  };

  const handleIncrementViceVersa = value => {
    const currentIncrementNumber = stateValue + value;
    setStateValue(currentIncrementNumber);
  };

  const disabledMax = max ? !(stateValue <= max) : false;
  const disabledMin = !(stateValue >= min);

  return (
    <TextField
      // disabled={disabled}
      value={stateValue}
      variant="outlined"
      type="number"
      inputProps={{
        max,
        min,
      }}
      onChange={onChangeTextInput}
      InputProps={{
        startAdornment: <IconButton onClick={() => handleIncrementViceVersa(-1)}>-</IconButton>,
        endAdornment: <IconButton onClick={() => handleIncrementViceVersa(1)}>+</IconButton>,
      }}
    />
  );
};

TextInputNumberV02.propTypes = {
  currentValue: PropTypes.number,
  disabled: PropTypes.bool,
  max: PropTypes.number,
  min: PropTypes.number,
  onChange: PropTypes.func,
};

TextInputNumberV02.defaultProps = {
  min: 0,
  currentValue: 0,
};

export default TextInputNumberV02;
