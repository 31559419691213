import React from 'react';
import PropTypes from 'prop-types';
import { Paper, Grid } from '@material-ui/core';
// style
import './HeaderMealPlanStyle.scss';
// component
import {
  PickerInputDate,
  GeneratorPdf,
  GeneratorExcel,
  SelectInputMain,
  SelectSearchMultiple,
} from '../../../../components/Index';

const HeaderMealPlan = ({
  startDate,
  endDate,
  handleChangeStartDate,
  handleChangeEndDate,
  handleExport,
  selectedRowPrint,
  dataSetArray,
  fetchingExport,
  roomNameOption,
  currentRoomName,
  handleSelectChangeRoomName,
  mealTimeOption,
  currentMealTime,
  handleSelectChangeMealTime,
  callbackMessage,
}) => {
  return (
    <Paper square className="container-tab-header-meal-plan">
      <Grid container justify="space-between">
        <Grid item lg={8} md={8}>
          <Grid container flex="column">
            <Grid item lg={9} md={9} className="mb-15">
              <Grid container justify="space-between" alignItems="center" spacing={1}>
                {/* <Grid item lg={2} md={2} className="text-left">
                  <label className="text-12 pr-16">Showing Date</label>
                </Grid> */}
                <Grid item lg={4} md={4}>
                  <div className="container-remove-margin">
                    <PickerInputDate
                      customIcon="ic-ffo-date-pick"
                      dateFormat="dd/MM/yyyy"
                      defaultValue={startDate}
                      onChange={handleChangeStartDate}
                      toolbar={false}
                    />
                  </div>
                </Grid>
                <label className="text-12"> - </label>
                <Grid item lg={4} md={4}>
                  <div className="container-remove-margin">
                    <PickerInputDate
                      customIcon="ic-ffo-date-pick"
                      dateFormat="dd/MM/yyyy"
                      minDate={startDate}
                      defaultValue={endDate}
                      onChange={handleChangeEndDate}
                      toolbar={false}
                    />
                  </div>
                </Grid>
                <Grid item lg={3} md={3} className="row-filter">
                  <SelectInputMain
                    options={roomNameOption}
                    currentValue={currentRoomName}
                    onChange={handleSelectChangeRoomName}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={9} md={9}>
              <SelectSearchMultiple
                options={mealTimeOption}
                onChange={handleSelectChangeMealTime}
                currentValue={currentMealTime}
                placeholder={'All Meal Times'}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={'auto'} md={'auto'} className="container-header-button-meal-plan">
          <Grid container direction="row" justify="flex-end" alignItems="center">
            <Grid item className="mr-8">
              <GeneratorPdf
                data={selectedRowPrint}
                roomName={currentRoomName}
                buttonType="primary"
                buttonLabel="Print Label"
                customLabel="label-patient"
                callbackMessage={callbackMessage}
              />
            </Grid>
            <Grid item>
              <GeneratorExcel
                labelText="Export Meal"
                buttonSize="md"
                dataSetArray={dataSetArray}
                fileName={`Patient_List_Report_${startDate}_${endDate}`}
                iconPrefix="ic-ffo-download"
                isLoading={fetchingExport}
                onClick={handleExport}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

HeaderMealPlan.propTypes = {
  callbackMessage: PropTypes.func,
  currentMealTime: PropTypes.string,
  currentRoomName: PropTypes.string,
  dataSetArray: PropTypes.array,
  endDate: PropTypes.string,
  fetchingExport: PropTypes.bool,
  handleChangeEndDate: PropTypes.func,
  handleChangeStartDate: PropTypes.func,
  handleExport: PropTypes.func,
  handleSelectChangeMealTime: PropTypes.func,
  handleSelectChangeRoomName: PropTypes.func,
  mealTimeOption: PropTypes.array,
  roomNameOption: PropTypes.array,
  selectedRowPrint: PropTypes.array,
  startDate: PropTypes.string,
};

export default HeaderMealPlan;
