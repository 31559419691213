import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Grid, IconButton, FormControl, FormLabel } from '@material-ui/core';
import _ from 'lodash';
// Style
import './ModalRescheduleStyle.scss';
// Component
import {
  ModalInformationPopUp,
  ButtonMain,
  PickerInputDate,
  SelectInputTime,
  TextInputAutocomplete,
  TextAreaMain,
  SnackBarSimple,
} from '../../../../../../components/Index';
import { SkeletonScheduleDetails } from '../Index';
// api
import { getLabSchedule } from '../../../../../../services/api/ScheduleDataApi';
import {
  getOrderShippingAddress,
  getOrderShippingAddressInfo,
} from '../../../../../../services/api/OrdersApi';
import {
  setReschedulBookingLab,
  checkHomeServiceAreaCoverage,
} from '../../../../../../services/api/MedicApi';
// helper
import { CommonHelper, serviceLocationCode } from '../../../../../../helpers/Index';

const today = CommonHelper.currentDate('YYYY-MM-DD HH:mm:ss');
const currentDate = CommonHelper.dateTimeParseNewFormat(today, 'YYYY-MM-DD');

const initialForm = {
  address: '',
  addressNotes: '',
  addressInfo: {},
  date: currentDate,
  time: null,
  placeId: '',
};

const initialValidation = {
  address: { isError: false, errorMessage: '' },
  addressNotes: { isError: '', errorMessage: '' },
  time: { isError: false, errorMessage: '' },
};

const optionToast = {
  vertical: 'top',
  horizontal: 'right',
};

class ModalReschedule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      loading: false,
      addressNotCoverage: false,
      form: CommonHelper.objectCloning(initialForm),
      validation: CommonHelper.objectCloning(initialValidation),
      toastInformation: {
        isOpen: false,
        message: '',
        snackbarType: 'warning',
      },
    };
    this.searchAddressDebounce = _.debounce(this.searchAddressDebounce, 400);
  }

  componentDidMount() {
    this.setInitialFilter();
  }

  getLabScheduleApi = async date => {
    const { callGetLabScheduleApi, data } = this.props;
    const { form } = this.state;
    const appointmentDetailsData = !_.isEmpty(data) ? data.appointment_details : {};
    const branchId = data.branch_details.branch_id;
    let modifyTime = null;
    if (!branchId) return;
    const params = { date, branch_id: branchId };
    const response = await callGetLabScheduleApi(params);
    const findMatchTime = response.data.find(item => {
      return item.start_time === appointmentDetailsData.start_time;
    });
    if (!_.isEmpty(findMatchTime)) {
      modifyTime = findMatchTime.start_time;
    } else {
      modifyTime = `${appointmentDetailsData.start_time} - ${appointmentDetailsData.end_time}`;
    }
    this.setState({ isLoading: false, form: { ...form, time: modifyTime } });
  };

  searchAddressDebounce = async () => {
    const { form } = this.state;
    const { getShippingAddress } = this.props;

    const param = {
      search_term: form.address,
    };

    await getShippingAddress(param).catch(error => {
      const serverMessage = error.data;
      this.processMessage(serverMessage.messages, 'error');
    });
  };

  onProcessPlaceId = () => {
    const { form } = this.state;
    const { getShippingAddressInfo } = this.props;

    const param = {
      place_id: form.placeId,
    };

    getShippingAddressInfo(param)
      .then(response => {
        const data = response.data;
        this.setState({ form: { ...form, addressInfo: data } });
      })
      .catch(error => {
        const serverMessage = error.data;
        this.processMessage(serverMessage.messages, 'error');
      });
  };

  handleSubmitSchedule = async () => {
    const { reschedulBooking, data, onSuccessReschedule, availableLabSchedule } = this.props;
    const { form } = this.state;
    const formNewValue = CommonHelper.objectCloning(form);
    const { isError } = this.handleValidation(formNewValue);
    const appointmentDetailsData = !_.isEmpty(data) ? data.appointment_details : {};
    let modifyTime = form.time;

    if (_.isEmpty(availableLabSchedule)) {
      modifyTime = appointmentDetailsData.start_time;
    }

    if (!isError) {
      this.setState({
        loading: true,
      });
      try {
        const salesOrderId = data.sales_order_id;
        const params = {
          service_location: data.appointment_details.service_location,
          date: form.date,
          time: modifyTime,
          address: form.addressInfo,
          notes: form.addressNotes,
        };
        const response = await reschedulBooking(salesOrderId, params);
        const message = response.messages;
        onSuccessReschedule(message);
        this.setState({
          form: { ...initialForm },
          loading: false,
        });
      } catch (error) {
        this.processMessage(error.data, 'error');
        this.setState({
          loading: false,
        });
      }
    }
  };

  setInitialFilter = () => {
    const { data } = this.props;
    const setInitialForm = initialForm;
    const appointmentDetailsData = !_.isEmpty(data) ? data.appointment_details : {};
    const shippingDetailsData = !_.isEmpty(data) ? data.shipping_details : {};
    const modifyDate = CommonHelper.dateTimeParseNewFormat(
      appointmentDetailsData.date,
      'YYYY-MM-DD',
    );

    this.setState(
      {
        form: {
          ...setInitialForm,
          date: modifyDate,
          address: shippingDetailsData && shippingDetailsData.shipping_address,
          addressInfo: shippingDetailsData && shippingDetailsData.shipping_address_info,
          addressNotes: shippingDetailsData && shippingDetailsData.shipping_notes,
        },
      },
      async () => {
        const date = this.state.form.date;
        await this.getLabScheduleApi(date);
      },
    );
  };

  handleValidation = form => {
    const { data } = this.props;
    const { addressNotCoverage } = this.state;
    let validationNewValue = CommonHelper.objectCloning(initialValidation);
    let excludeValidationKeys = ['placeId'];
    const isHomeService = data.appointment_details.service_location === serviceLocationCode.AtHome;

    if (!isHomeService) {
      excludeValidationKeys = ['address', 'addressNotes', 'addressInfo', 'placeId'];
    }

    const keys = Object.keys(form).filter(key => {
      return excludeValidationKeys.indexOf(key) === -1;
    });

    const errorValidationKeys = Object.keys(validationNewValue).filter(key => {
      return excludeValidationKeys.indexOf(key) === -1;
    });

    const errorKeys = keys.filter(key => {
      return form[key] === null || form[key] === '';
    });

    errorValidationKeys.forEach(key => {
      const isError = errorKeys.indexOf(key) !== -1;
      let errorDetail = {};
      let addressError = {};
      switch (key) {
        case 'address':
          addressError = {
            isError,
            errorMessage: isError ? 'Please fill this field' : '',
          };
          if (addressNotCoverage) {
            addressError = {
              isError: true,
              errorMessage: 'Sorry, your area is not covered for Home Service.',
            };
          }
          errorDetail = addressError;
          break;
        case 'addressNotes':
          errorDetail = {
            isError: isError ? 'error' : '',
            errorMessage: isError ? 'Please fill this field' : '',
          };
          break;
        default:
          errorDetail = { isError, errorMessage: isError ? 'Please fill this field' : '' };
          break;
      }
      validationNewValue = {
        ...validationNewValue,
        [key]: errorDetail,
      };
    });

    this.setState({
      validation: { ...validationNewValue },
    });

    return { isError: Boolean(errorKeys.length), validationNewValue };
  };

  handleSelectDateChange = value => {
    const { form } = this.state;

    this.setState(
      {
        form: { ...form, date: value, time: null },
      },
      () => {
        this.getLabScheduleApi(value);
      },
    );
  };

  handleSelectTime = value => {
    const { form } = this.state;

    this.setState({
      form: {
        ...form,
        time: value,
      },
    });
  };

  handleChangeAddress = value => {
    const { form } = this.state;

    this.setState(
      {
        form: {
          ...form,
          address: value,
        },
      },
      () => {
        this.searchAddressDebounce();
      },
    );
  };

  handleSelectedItemAddress = async (value, option) => {
    const { callCheckHomeServiceAreaCoverageApi } = this.props;
    const { form, validation } = this.state;

    try {
      const body = { place_id: option.place_id };
      await callCheckHomeServiceAreaCoverageApi(body);
      const validationNewValue = {
        ...validation,
        address: { isError: false, errorMessage: '' },
      };
      this.setState(
        {
          form: { ...form, address: option.address, placeId: option.place_id },
          validation: { ...validationNewValue },
          addressNotCoverage: false,
        },
        () => {
          this.onProcessPlaceId();
        },
      );
    } catch (error) {
      const errorMessage = error.data.messages[0];
      const validationNewValue = {
        ...validation,
        address: { isError: true, errorMessage },
      };
      this.setState({
        validation: { ...validationNewValue },
        addressNotCoverage: true,
      });
      this.processMessage(errorMessage, 'error');
    }
  };

  handleChangeAddressNotes = value => {
    const { form, validation } = this.state;
    const message = CommonHelper.objectCloning(initialValidation.addressNotes);
    message.isError = !value ? 'error' : '';
    message.errorMessage = !value ? 'Please fill this field' : '';

    this.setState({
      form: {
        ...form,
        addressNotes: value,
      },
      validation: { ...validation, addressNotes: message },
    });
  };

  handleCloseToast = () => {
    const { toastInformation } = this.state;
    this.setState({ toastInformation: { ...toastInformation, isOpen: false } });
  };

  processMessage(messages, type) {
    const convertedMessage = CommonHelper.generateMessage(messages);

    const paramInformation = {
      isOpen: true,
      message: convertedMessage,
      snackbarType: type,
    };

    this.setState({
      toastInformation: paramInformation,
    });
  }

  renderAddressForm = () => {
    const {
      orderData: { fetchingShippingAddress, listShippingAddress },
    } = this.props;
    const { form, validation } = this.state;
    const ListCustom = listShippingAddress;

    return (
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <FormControl component="fieldset" fullWidth margin="normal">
            <FormLabel component="label" className="text-12 filed-title">
              Address
            </FormLabel>
            <TextInputAutocomplete
              size="md"
              onChange={this.handleChangeAddress}
              currentValue={form.address}
              placeholder="Address"
              onSelect={this.handleSelectedItemAddress}
              isLoading={fetchingShippingAddress}
              data={ListCustom}
              isCustomDisplay
              customDisplayClass="custom-address-option-item"
              errorMessage={validation.address.errorMessage}
              isError={validation.address.isError}
              placeHolderText="Please enter address for Home Service"
            />
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl component="fieldset" fullWidth margin="normal">
            <FormLabel component="label" className="text-12 filed-title">
              Notes
            </FormLabel>
            <TextAreaMain
              onChange={this.handleChangeAddressNotes}
              currentValue={form.addressNotes}
              placeholder="Enter address notes"
              rows={3}
              validateStatus={validation.addressNotes.isError}
              errorMessage={validation.addressNotes.errorMessage}
            />
          </FormControl>
        </Grid>
      </Grid>
    );
  };

  render() {
    const { data, isOpen, isComplete, onClose, availableLabSchedule } = this.props;
    const { isLoading, loading, form, validation, toastInformation } = this.state;
    const customerDetailsData = !_.isEmpty(data) ? data.customer_details : {};
    const appointmentDetailsData = !_.isEmpty(data) ? data.appointment_details : {};
    const summaryDetailsData = !_.isEmpty(data) ? data.order_summary : {};
    const totalCustomer = summaryDetailsData.product_details.filter(item => {
      return item.product_sku !== 'fitco-medic-apd-fee';
    });

    let elementRenderCustom = <SkeletonScheduleDetails />;

    if (!isLoading) {
      elementRenderCustom = (
        <Grid container direction="column" className="flex-wrap-unset">
          <Grid item lg md className="section-header-modal">
            <Grid container>
              <Grid item lg={11} md={11}>
                <h5 className="wrapping-container second line">Reschedule Booking</h5>
              </Grid>
              <Grid item>
                <Grid container direction="column" justify="flex-end">
                  <Grid item>
                    <div className="container-button-close">
                      <IconButton onClick={onClose}>
                        <i className="ic-ffo-close container-icon-prefix size-21" />
                      </IconButton>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg md className="section-body-modal">
            <Grid container direction="column" className="flex-wrap-unset">
              <Grid item lg md className="mb-13">
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  className="container-details-booking"
                >
                  <Grid item lg md>
                    <div className="flex-column">
                      <label className="text-12 title text-rolling-stone mb-4">Customer Name</label>
                      <label className="text-13">{customerDetailsData.customer_name}</label>
                    </div>
                  </Grid>
                  <Grid item lg md>
                    <div className="flex-column">
                      <label className="text-12 title text-rolling-stone mb-4">Product</label>
                      <label className="text-13">
                        {appointmentDetailsData.service_location === serviceLocationCode.AtHome
                          ? 'Home Service'
                          : 'In Store Service'}
                      </label>
                    </div>
                  </Grid>
                  <Grid item lg md>
                    <div className="flex-column">
                      <label className="text-12 title text-rolling-stone mb-4">
                        Total Customer
                      </label>
                      <label className="text-13">{`${totalCustomer.length} Customer`}</label>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item lg md className="mb-13">
                <Grid container className="flex-wrap-unset">
                  <Grid item lg md>
                    <div className="flex-column">
                      <label className="text-12 text-semi-bold text-rolling-stone mb-6">
                        Current Appointment
                      </label>
                      <label className="text-13 text-semi-bold">
                        {`${appointmentDetailsData.date} |
                        ${appointmentDetailsData.start_time} - ${appointmentDetailsData.end_time}`}
                      </label>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item lg md className="mb-13">
                <Grid container direction="row" justify="space-between" spacing={2}>
                  <Grid item lg={6} md={6}>
                    <FormControl component="fieldset" fullWidth margin="normal">
                      <FormLabel component="label" className="text-12 filed-title">
                        Date
                      </FormLabel>
                      <PickerInputDate
                        customIcon="ic-ffo-date-pick"
                        dateFormat="dd-MM-yyyy"
                        minDate={!isComplete ? currentDate : null}
                        onChange={this.handleSelectDateChange}
                        defaultValue={form.date}
                        toolbar
                        disabled={isComplete}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item lg={6} md={6}>
                    <FormControl component="fieldset" fullWidth margin="normal">
                      <FormLabel component="label" className="text-12 filed-title">
                        Time
                      </FormLabel>
                      <SelectInputTime
                        data={availableLabSchedule}
                        placeHolder="Select Time"
                        currentValue={form.time}
                        onChange={this.handleSelectTime}
                        errorMessage={validation.time.errorMessage}
                        validateStatus={validation.time.isError ? 'error' : ''}
                        disabled={isComplete}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item lg md className="mb-13">
                {appointmentDetailsData.service_location === serviceLocationCode.AtHome &&
                  this.renderAddressForm()}
              </Grid>
            </Grid>
          </Grid>
          <Grid item className="section-footer-modal">
            <Grid container justify="flex-end" spacing={2}>
              <Grid item lg={3} md={3}>
                <ButtonMain
                  labelText="Cancel"
                  onClick={onClose}
                  type="ghost"
                  size="md"
                  isLoading={loading}
                />
              </Grid>
              <Grid item lg={3} md={3}>
                <ButtonMain
                  labelText="Save"
                  onClick={this.handleSubmitSchedule}
                  type="primary"
                  size="md"
                  isLoading={loading}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      );
    }

    return (
      <>
        <ModalInformationPopUp
          isOpen={isOpen}
          onClose={onClose}
          customElementProps={elementRenderCustom}
          size="medium"
        />
        <SnackBarSimple
          open={toastInformation.isOpen}
          durationHide={2000}
          message={toastInformation.message}
          onClickClose={this.handleCloseToast}
          snackbarType={toastInformation.snackbarType}
          anchor={optionToast}
        />
      </>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  callGetLabScheduleApi: body => getLabSchedule(dispatch, body),
  getShippingAddress: params => getOrderShippingAddress(dispatch, params),
  getShippingAddressInfo: params => getOrderShippingAddressInfo(dispatch, params),
  reschedulBooking: (salesOrderId, params) =>
    setReschedulBookingLab(dispatch, salesOrderId, params),
  callCheckHomeServiceAreaCoverageApi: body => checkHomeServiceAreaCoverage(dispatch, body),
});

const mapStateToProps = ({ orderData, scheduleSummary }) => ({
  orderData,
  availableLabSchedule: scheduleSummary.availableLabSchedule,
});

ModalReschedule.propTypes = {
  availableLabSchedule: PropTypes.array,
  callCheckHomeServiceAreaCoverageApi: PropTypes.func,
  callGetLabScheduleApi: PropTypes.func,
  data: PropTypes.object,
  getShippingAddress: PropTypes.func,
  getShippingAddressInfo: PropTypes.func,
  isComplete: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onSuccessReschedule: PropTypes.func,
  orderData: PropTypes.object,
  reschedulBooking: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalReschedule);
