import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import _ from 'lodash';
// Style
import './CardTopSellingMembershipListStyle.scss';
// component
import { ChartPieGraphic, LoaderCircle } from '../../../../components/Index';
import { CommonHelper, currencyCode, DashboardHelper } from '../../../../helpers/Index';
// api
import { getTopSellingMembershipList } from '../../../../services/api/DashboardApi';
import { Skeleton } from '@material-ui/lab';
import { TooltipChatPieCustom } from '../../../../components/presentational/chart-pie-graphic/components/Index';

class CardTopSellingMembershipList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: {},
      dataGraphic: [],
      dataAmount: 0,
      limit: 6,
    };
  }

  componentDidUpdate(prevProps) {
    const { parentFilter } = this.props;

    const isEqual = _.isEqual(parentFilter, prevProps.parentFilter);

    if (parentFilter) {
      if (!isEqual) {
        this.setUpdateExistingFilter();
      }
    }
  }

  setUpdateExistingFilter() {
    const { parentFilter } = this.props;
    const { filter } = this.state;
    this.setState({ filter: { ...filter, ...parentFilter } }, () => {
      this.getTopSellingMembershipList();
    });
  }

  getTopSellingMembershipList() {
    const {
      filter: { startDate, endDate },
      limit,
    } = this.state;
    const {
      topSellingMembershipList,
      dashboardData: { fetchingSellingMembershipList },
    } = this.props;

    const params = {
      start_date: startDate,
      end_date: endDate,
      limit,
    };

    if (!fetchingSellingMembershipList && startDate && endDate) {
      topSellingMembershipList(params).then(response => {
        const data = response.data;

        const convertedStatisticPie = DashboardHelper.generateDataStatisticPie(data.summary);

        this.setState({
          dataGraphic: convertedStatisticPie,
          dataAmount: data.addtional_info.total_membership_sales || 0,
        });
      });
    }
  }

  render() {
    const { dataGraphic, dataAmount, filter } = this.state;
    const {
      dashboardData: { fetchingSellingMembershipList },
    } = this.props;

    let elementRender = (
      <Skeleton
        variant="rect"
        width="100%"
        height={300}
        animation="wave"
        className="skeletonRounded"
      />
    );

    if (!fetchingSellingMembershipList) {
      const wordingPeriod = `From ${CommonHelper.dateTimeParseNewFormat(
        filter.startDate,
        'DD/MM/YYYY',
      )} - ${CommonHelper.dateTimeParseNewFormat(filter.endDate, 'DD/MM/YYYY')}`;
      const wordingAmount = `${currencyCode.idr} ${CommonHelper.formatCurrency(dataAmount)}`;

      elementRender = (
        <div className="flex-column">
          <Grid container spacing={2} alignItems="center">
            <Grid item xl={4} lg={5} md={5}>
              <div className="flex-column">
                <ChartPieGraphic
                  dataArray={dataGraphic}
                  height={220}
                  tooltipCustom={<TooltipChatPieCustom />}
                  innerRadius="30%"
                  outerRadius="80%"
                />
                <label className="text-12 text-semi-bold mb-4 text-center">
                  Total Sales Membership
                </label>
                <label className="text-10 text-semi-bold text-rolling-stone mb-8  text-center">
                  {wordingPeriod}
                </label>
                <label className="text-14 text-bold  text-center">{wordingAmount}</label>
              </div>
            </Grid>
            <Grid item lg md>
              <Grid container spacing={4}>
                {dataGraphic.map((item, index) => {
                  const wordingValue = `${currencyCode.idr} ${CommonHelper.formatCurrency(
                    item.value,
                  )}`;
                  const wordingName = CommonHelper.renameRemoveCharacter(item.name, '_');

                  return (
                    <Grid key={index} item lg={6} md={6}>
                      <div className="flex-column">
                        <span className="flex-row mb-4">
                          <div className="container-custom-loading-circle mr-8 align-center">
                            <LoaderCircle
                              color={item.color}
                              strokeWidth={25}
                              progress={parseFloat(item.percent)}
                            />
                          </div>
                          <label className="text-18 text-bold">{item.percent}%</label>
                        </span>
                        <label className="text-14 text-bold mb-4">{wordingValue}</label>
                        <label className="text-10 text-semi-bold text-rolling-stone">
                          {wordingName}
                        </label>
                      </div>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </div>
      );
    }

    return (
      <div className="container-card-top-selling-membership container-main-card ph-16 pt-24 pb-16 flex-column">
        <label className="text-14 text-semi-bold mb-13">Top Selling Membership</label>
        {elementRender}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  topSellingMembershipList: params => getTopSellingMembershipList(dispatch, params),
});

const mapStateToProps = ({ dashboardData }) => ({
  dashboardData,
});

CardTopSellingMembershipList.propTypes = {
  dashboardData: PropTypes.object,
  parentFilter: PropTypes.object,
  topSellingMembershipList: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(CardTopSellingMembershipList);
