export const constants = {
  getGateConfigurationRequest: 'GET_GATE_CONFIGURATION_REQUEST',
  getGateConfigurationSuccess: 'GET_GATE_CONFIGURATION_SUCCESS',
  getGateConfigurationFail: 'GET_GATE_CONFIGURATION_FAIL',
};

const initState = {
  fetching: false,
  errorMessage: null,
  gate: {},
};

export default function MiscellaneousReducer(state = initState, action) {
  const { type, payload } = action;
  switch (type) {
    case constants.getGateConfigurationRequest:
      return { ...state, fetching: true };
    case constants.getGateConfigurationSuccess:
      return {
        ...state,
        gate: payload.data,
        fetching: false,
      };
    case constants.getGateConfigurationFail:
      return { ...state, fetching: false, errorMessage: payload };

    default:
      return state;
  }
}
