import * as PopupScreenAction from '../../redux/actions/PopupScreenAction';
import Api from '../../middleware/FetchMiddleware';
import { baseUrl } from '../../environments/Environment';
import { HttpAction } from '../../helpers/HttpHelper';

export const getPopupScreenList = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(PopupScreenAction.getPopupScreenListRequest());
    Api.callApi(`${baseUrl}private/pos/v2/marketing-popup/list`, HttpAction.Get, params, {})
      .then(response => {
        dispatch(PopupScreenAction.getPopupScreenListSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(PopupScreenAction.getPopupScreenListFail(e));
        reject(e);
      });
  });
};

export const getPopupScreenDetails = (dispatch, marketingPopupId) => {
  return new Promise((resolve, reject) => {
    dispatch(PopupScreenAction.getPopupScreenDetailsRequest());
    Api.callApi(
      `${baseUrl}private/pos/v2/marketing-popup/details/${marketingPopupId}`,
      HttpAction.Get,
      {},
      {},
    )
      .then(response => {
        dispatch(PopupScreenAction.getPopupScreenDetailsSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(PopupScreenAction.getPopupScreenDetailsFail(e));
        reject(e);
      });
  });
};

export const createPopupScreen = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(PopupScreenAction.createPopupScreenRequest());
    Api.callApi(`${baseUrl}private/pos/v2/marketing-popup/create`, HttpAction.Post, {}, params)
      .then(response => {
        dispatch(PopupScreenAction.createPopupScreenSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(PopupScreenAction.createPopupScreenFail(e));
        reject(e);
      });
  });
};

export const updateStatusPopupScreen = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(PopupScreenAction.updateStatusPopupScreenRequest());
    Api.callApi(
      `${baseUrl}private/pos/v2/marketing-popup/update-status`,
      HttpAction.Post,
      {},
      params,
    )
      .then(response => {
        dispatch(PopupScreenAction.updateStatusPopupScreenSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(PopupScreenAction.updateStatusPopupScreenFail(e));
        reject(e);
      });
  });
};

export const editPopupScreen = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(PopupScreenAction.editPopupScreenRequest());
    Api.callApi(`${baseUrl}private/pos/v2/marketing-popup/update`, HttpAction.Post, {}, params)
      .then(response => {
        dispatch(PopupScreenAction.editPopupScreenSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(PopupScreenAction.editPopupScreenFail(e));
        reject(e);
      });
  });
};
