import React from 'react';
import { connect } from 'react-redux';
import { Grid, FormControl, FormLabel, Fade, Checkbox } from '@material-ui/core';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { compose } from 'redux';
// component
import {
  TextInput,
  ButtonMain,
  PrevNextStepperCount,
  SelectInputMain,
  GridRowTableOneColumn,
  RadioInputImage,
  LabelAlert,
} from '../../../../../../components/Index';
import { CartItemBooking, DetailsAppointment, ModalTermConditions } from './components/Index';
// style
import './StepBookingSummaryAndPaymentStyle.scss';
import {
  CommonHelper,
  MasterDataHelper,
  HttpStatusCode,
  promoTypeCode,
  paymentMethodOptionCode,
  // OrderHelper,
  UserHelper,
  serviceLocationCode,
} from '../../../../../../helpers/Index';
// api
import { checkPromoCode } from '../../../../../../services/api/OrdersApi';
import {
  getMembershipDetailsByBook,
  checkMedicConsultation,
} from '../../../../../../services/api/ScheduleDataApi';
// json
import TnCSportClinic from '../../../../../../services/file/TnCSportclinic.json';

const bankTransferOption = MasterDataHelper.bankTransferOption;
const currentUsers = UserHelper.getCurrentUserInformation();

const initialValidation = {
  promoCode: { isError: false, errorMessage: '' },
};

const initialForm = {
  paymentMethod: currentUsers && currentUsers.isResellerLogin ? 'bank_transfer' : null,
  bank: bankTransferOption[0],
  promoCode: '',
  isMembershipSpecial: false,
};

const timeInterval = 300;

class StepBookingSummaryAndPayment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      form: CommonHelper.objectCloning(initialForm),
      validation: CommonHelper.objectCloning(initialValidation),
      paymentMethodOption: [],
      isSuccess: '',
      isValidPromoCode: false,
      disablePromoCode: false,
      checked: false,
      isOpenModal: false,
      isNotEligible: false,
    };
    this.searchPromoCodeDebounce = _.debounce(this.searchPromoCodeDebounce, 400);
  }

  async componentDidMount() {
    const { parentForm, parentValidation } = this.props;
    const { form, validation } = this.state;
    let changeValue = paymentMethodOptionCode.bank_transfer;

    let modifyPaymentMethodOption = [];
    let modifyActiveMembership = false;
    try {
      modifyActiveMembership = parentForm.isExisting && (await this.getMembershipDetailsByBook());
    } catch (error) {
      modifyActiveMembership = false;
    }

    const includedProductId = [7272, 7271, 7274];
    const isNeedToCallServices =
      !_.isEmpty(parentForm.step_booking_product_details) &&
      includedProductId.indexOf(
        parentForm.step_booking_product_details.selectedProduct[0].product_id,
      ) !== -1;
    if (isNeedToCallServices) {
      await this.getMedicConsultationInfo();
    }

    if (!parentForm.isExisting) {
      modifyPaymentMethodOption = MasterDataHelper.paymentMethodOption.filter(
        item =>
          item.value === paymentMethodOptionCode.bank_transfer ||
          item.value === paymentMethodOptionCode.credit_card ||
          item.value === paymentMethodOptionCode.edc,
      );
      changeValue = paymentMethodOptionCode.bank_transfer;
    } else if (
      (modifyActiveMembership &&
        modifyActiveMembership.code === HttpStatusCode.NotFound &&
        parentForm.isExisting) ||
      !modifyActiveMembership
    ) {
      modifyPaymentMethodOption = MasterDataHelper.paymentMethodOption.filter(
        item =>
          item.value === paymentMethodOptionCode.bank_transfer ||
          item.value === paymentMethodOptionCode.credit_card ||
          item.value === paymentMethodOptionCode.fit_point ||
          item.value === paymentMethodOptionCode.edc,
      );
    } else if (
      parentForm.isExisting &&
      modifyActiveMembership &&
      modifyActiveMembership.code !== HttpStatusCode.NotFound &&
      modifyActiveMembership.data.membership_active &&
      !modifyActiveMembership.data.is_average_price_membership
    ) {
      modifyPaymentMethodOption = MasterDataHelper.paymentMethodOption.filter(
        item => item.value === paymentMethodOptionCode.membership && item.name === 'Membership',
      );
      changeValue = paymentMethodOptionCode.membership;
    }

    this.setState(
      {
        form: {
          ...form,
          ...parentForm,
          membershipInfo: modifyActiveMembership ? modifyActiveMembership.data : {},
        },
        paymentMethodOption: modifyPaymentMethodOption,
        validation: { ...validation, ...parentValidation },
      },
      () => {
        if (!parentForm.paymentMethod) {
          this.handleSelectPaymentMethod(changeValue);
        } else if (!_.isEmpty(parentForm.promoCode)) {
          this.searchPromoCodeDebounce();
        }
      },
    );
  }

  componentWillReceiveProps(nextProps) {
    const { validation } = this.state;
    const isValidationChange = _.isEqual(validation, nextProps.parentValidation);

    if (!isValidationChange) {
      const finalMergeValidation = { ...validation, ...nextProps.parentValidation };
      this.setState({
        validation: finalMergeValidation,
      });
    }
  }

  async getMembershipDetailsByBook() {
    try {
      const { membershipDetailsByBook, parentForm } = this.props;

      const params = {
        user_id: parentForm.userId,
        branch_id: parentForm.step_booking_product_details.selectedProduct[0].branch_id,
        check_date: CommonHelper.dateTimeParseNewFormat(
          parentForm.step_booking_product_details.selectedProduct[0].start_time,
          'YYYY-MM-DD',
        ),
        product_id: parentForm.step_booking_product_details.selectedProduct[0].product_id,
      };

      return await membershipDetailsByBook(params);
    } catch (error) {
      return false;
    }
  }

  getMedicConsultationInfo = async () => {
    const { medicConsultation, parentForm, onPopUpInformation } = this.props;

    try {
      const params = {
        user_id: parentForm.userId,
        product_id: parentForm.step_booking_product_details.selectedProduct[0].product_id,
      };
      const response = await medicConsultation(params);
      if (response.data) {
        this.setState({ isNotEligible: true });
      }
    } catch (error) {
      const serverMessage = error.data;
      const validationStatus = error.status === HttpStatusCode.InternalServerError;
      onPopUpInformation(
        validationStatus ? serverMessage.message : serverMessage.messages,
        'error',
      );
    }
  };

  searchPromoCodeDebounce = () => {
    const { checkPromo } = this.props;
    const { validation, form } = this.state;
    const params = this.getPayloadPromoCode();
    const message = CommonHelper.objectCloning(initialValidation.promoCode);

    if (!_.isEmpty(form.promoCode)) {
      checkPromo(params)
        .then(() => {
          message.errorMessage = 'Promo Code Applied';
          this.setState({
            isValidPromoCode: true,
            isSuccess: 'success',
            validation: {
              ...validation,
              promoCode: message,
            },
          });
        })
        .catch(e => {
          if (e.status === HttpStatusCode.NotFound) {
            message.isError = true;
            message.errorMessage = `${e.data.messages}, you will get no benefit if Process this transaction`;

            this.setState({
              validation: {
                ...validation,
                promoCode: message,
              },
              isValidPromoCode: false,
              isSuccess: 'error',
            });
          }
        });
    } else {
      this.setState({
        isValidPromoCode: false,
        isSuccess: '',
        validation: {
          ...validation,
          promoCode: { isError: false, errorMessage: '' },
        },
      });
    }
  };

  getPayloadPromoCode = () => {
    const { form } = this.state;
    const itemList = [];
    const items = form.step_booking_product_details.selectedProduct;

    items.forEach(item => {
      itemList.push({
        product_id: item.product_id,
        qty: item.qty,
      });
    });

    const payload = {
      data: {
        user_id: form.userId,
        promo_code: form.promoCode,
        payment_type: form.paymentMethod, // in back payment method reccornize as payment type
        items: !_.isEmpty(itemList) ? itemList : null,
      },
    };
    return payload;
  };

  handleSelectPaymentMethod = value => {
    const { form } = this.state;
    let modifyPromoCode = CommonHelper.objectCloning(form.promoCode);
    let modifyDisablePromoCode = false;

    // let modifyItems = CommonHelper.objectCloning(form.items);
    // let modifyPaymentMethod = value;
    // let modifyPaymentMethodOption = !_.isEmpty(paymentMethodOption)
    //   ? CommonHelper.objectCloning(paymentMethodOption)
    //   : [];
    let modifyIsMembershipSpecial = false;

    if (value === paymentMethodOptionCode.membership) {
      modifyPromoCode = '';
      modifyDisablePromoCode = true;
      modifyIsMembershipSpecial = true;

      // const checkValidMembership = OrderHelper.membershipMarkUpItems(
      //   modifyItems,
      //   form.membershipInfo,
      // );

      // modifyItems = checkValidMembership.items;
      // modifyPaymentMethod = !checkValidMembership.valid
      //   ? paymentMethodOptionCode.bank_transfer
      //   : value;

      // if (!checkValidMembership.valid) {
      //   modifyIsMembershipSpecial = true;
      //   modifyPaymentMethodOption = MasterDataHelper.paymentMethodOption.filter(
      //     item =>
      //       item.value === paymentMethodOptionCode.bank_transfer ||
      //       item.value === paymentMethodOptionCode.credit_card,
      //   );
      // }
    }

    this.setState(
      {
        form: {
          ...form,
          paymentMethod: value,
          // items: modifyItems,
          isMembershipSpecial: modifyIsMembershipSpecial,
        },
        disablePromoCode: modifyDisablePromoCode,
        // paymentMethodOption: modifyPaymentMethodOption,
      },
      () => {
        if (value === paymentMethodOptionCode.bank_transfer) {
          const valueChange = bankTransferOption[0];
          this.handleChangeBankTransfer(valueChange);
        } else if (value === paymentMethodOptionCode.credit_card) {
          this.handleChangePromoCodeEntry(modifyPromoCode);
        }
      },
    );
  };

  handleChangeBankTransfer = value => {
    const { form } = this.state;

    this.setState({ form: { ...form, bank: value } });
  };

  handleChangePromoCodeEntry = value => {
    const { form } = this.state;
    this.setState(
      {
        form: { ...form, promoCode: value },
      },
      () => {
        this.searchPromoCodeDebounce();
      },
    );
  };

  handlePrevClick = async () => {
    const { onPrevClick, onParameterUpdate } = this.props;
    const { form, validation } = this.state;
    const formNewValue = CommonHelper.objectCloning(form);
    const validationNewValue = CommonHelper.objectCloning(validation);
    formNewValue.paymentMethod = null;
    formNewValue.promoCode = '';

    await onParameterUpdate(formNewValue, validationNewValue);
    await onPrevClick();
  };

  handleSubmitClick = async () => {
    const { onButtonClickSubmit, onParameterUpdate, onPopUpInformation } = this.props;
    const { form, validation, checked, isNotEligible } = this.state;
    const formNewValue = CommonHelper.objectCloning(form);
    const validationNewValue = CommonHelper.objectCloning(validation);
    const includedProductId = [7272, 7271, 7274];
    const items = form.step_booking_product_details.selectedProduct;
    const isShowAgreement =
      !_.isEmpty(items) && includedProductId.indexOf(items[0].product_id) !== -1;

    if (isNotEligible) {
      onPopUpInformation(
        'Please do consultation with our doctor first before purchase this product.',
        'error',
      );
      return;
    }

    await onParameterUpdate(formNewValue, validationNewValue, checked, isShowAgreement);
    await onButtonClickSubmit();
  };

  handleCheckAgreement = () => {
    const { isOpenModal } = this.state;
    this.setState(prevState => {
      let isOpenModalFlag = isOpenModal;
      if (isOpenModal) {
        isOpenModalFlag = false;
      }
      return { checked: !prevState.checked, isOpenModal: isOpenModalFlag };
    });
  };

  handleCloseModal = () => {
    this.setState({
      isOpenModal: false,
    });
  };

  handleOpenModal = () => {
    this.setState({
      isOpenModal: true,
    });
  };

  renderCartItem() {
    const { form, isValidPromoCode } = this.state;
    const { orderData } = this.props;

    return (
      <CartItemBooking form={form} orderData={orderData} isValidPromoCode={isValidPromoCode} />
    );
  }

  renderPaymentMethodAction() {
    const { scheduleSummary } = this.props;
    const {
      form: { membershipInfo, paymentMethod },
    } = this.state;
    const disableContent = scheduleSummary.fetching;
    let renderElement = null;

    if (paymentMethod === paymentMethodOptionCode.bank_transfer) {
      renderElement = (
        <Grid item lg={6} md={6} className="mt-27">
          <FormControl component="fieldset" fullWidth margin="normal">
            <RadioInputImage
              data={bankTransferOption}
              onSelect={this.handleChangeBankTransfer}
              direction="column"
              currentValue={this.state.form.bank}
              disabled={disableContent}
              imageWidth="60px"
            />
          </FormControl>
        </Grid>
      );
    } else if (paymentMethod === paymentMethodOptionCode.membership) {
      const ifQuotaBase = membershipInfo.membership_details.quota;
      const maxColumn = ifQuotaBase ? 2 : 3;

      if (!_.isEmpty(membershipInfo)) {
        renderElement = (
          <Grid item lg={6} md={6} className="mt-42">
            <Grid
              container
              justify="space-between"
              className="container-membership-information p-24"
            >
              <Grid item lg={8} md={8}>
                <div className="flex-column ">
                  <label className="text-12 text-semi-bold title">Membership</label>
                  <label className="text-12 text-semi-bold wrapping-container second line">
                    {membershipInfo.membership_details.name || '-'}
                  </label>
                </div>
              </Grid>
              <Grid item lg={maxColumn} md={maxColumn}>
                <div className="flex-column text-center">
                  {ifQuotaBase ? (
                    <React.Fragment>
                      <label className="text-12 text-semi-bold title">Quota</label>
                      <label className="text-12 text-semi-bold">
                        {membershipInfo.membership_details.remaining_quota || '-'}
                      </label>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <label className="text-12 text-semi-bold title">Period Expired</label>
                      <label className="text-12 text-semi-bold">
                        {CommonHelper.dateTimeParseNewFormat(
                          membershipInfo.membership_details.expired_at,
                          'DD MMM YYYY',
                        ) || '-'}
                      </label>
                    </React.Fragment>
                  )}
                </div>
              </Grid>
            </Grid>
          </Grid>
        );
      }
    }

    return renderElement;
  }

  renderSectionAgreement() {
    const { checked } = this.state;
    return (
      <Grid container direction="row">
        <Grid item>
          <Checkbox
            color="primary"
            className="custom-checkbox"
            checked={checked}
            onChange={this.handleCheckAgreement}
          />
        </Grid>
        <Grid item className="flex-column ml-12">
          <div>
            <label className="text-14 text-rolling-stone">
              <p className="remove-margin-bottom">
                I agree to{' '}
                <span
                  className="text-primary pointer"
                  role="button"
                  tabIndex={0}
                  onClick={() => {
                    this.handleOpenModal();
                  }}
                  onKeyPress={() => {
                    this.handleOpenModal();
                  }}
                >{`Fitco and Eminence Term & Conditions`}</span>
              </p>
            </label>
          </div>
          <div>
            <label className="text-12 text-rolling-stone">
              {`Please read correctly and carefully patient agreement.`}
            </label>
          </div>
        </Grid>
      </Grid>
    );
  }

  render() {
    const {
      validation,
      form,
      isValidPromoCode,
      isSuccess,
      disablePromoCode,
      paymentMethodOption,
      isOpenModal,
      checked,
    } = this.state;
    const { onButtonClickCancel, orderData, scheduleSummary } = this.props;

    const name =
      form.firstName || form.lastName ? `${form.firstName} ${form.lastName}`.trim() : '-';
    const gender = !_.isEmpty(form.gender) ? form.gender.name : '-';
    const orderType = CommonHelper.renameFirstUpperCase(form.orderType);

    const successIcon = 'ic-ffi-check-mark';
    const promoDesc = orderData.promoCodeDetails.cashback_amount_desc;
    const potentialPromo =
      orderData.promoCodeDetails.promo_code_cashback_type_id === promoTypeCode.Discount
        ? `Potential Discount${promoDesc}`
        : `Potential Cashback${promoDesc}`;

    const disableContent = scheduleSummary.fetching;
    const isDisablePromoCode = orderData.fetching || disablePromoCode;
    let location;
    if (form.step_booking_product_details) {
      switch (form.step_booking_product_details.serviceLocation) {
        case serviceLocationCode.InStore:
          location = `${form.step_booking_product_details.selectedBranch.name} - ${form.step_booking_product_details.selectedBranch.address}`;
          break;
        case serviceLocationCode.Virtual:
          location = `Online Workout Session`;
          break;
        default:
          location = `${form.step_booking_product_details.addressType || 'New Address'} - ${
            form.step_booking_product_details.address
          }`;
          break;
      }
    }
    const appointmentDetails = {
      appointment_date:
        form.step_booking_product_details &&
        !_.isEmpty(form.step_booking_product_details.selectedProduct)
          ? form.step_booking_product_details.selectedProduct[0].start_time
          : '',
      location,
    };
    const includedProductId = [7272, 7271, 7274];
    const isShowAgreement =
      form.step_booking_product_details &&
      !_.isEmpty(form.step_booking_product_details.selectedProduct) &&
      includedProductId.indexOf(form.step_booking_product_details.selectedProduct[0].product_id) !==
        -1;

    return (
      <Grid
        container
        direction="column"
        className="container-step-booking-summary-payment flex-wrap-unset"
      >
        <Grid item lg={12} md={12}>
          <Grid container spacing={2}>
            <Grid item lg={6} md={6} className="mt-24">
              <FormControl component="fieldset" fullWidth margin="normal">
                <FormLabel component="label" className="text-12 filed-title">
                  Customer Details
                </FormLabel>
                <Grid
                  container
                  direction="column"
                  className="container-row-table details flex-wrap-unset"
                >
                  <GridRowTableOneColumn
                    customElementInitial={<label className="text-14 title">Name</label>}
                    customElementColumn={<label className="text-14">{name}</label>}
                    columnLeft={5}
                    columnRight={7}
                  />
                  <GridRowTableOneColumn
                    customElementInitial={<label className="text-14 title">Email</label>}
                    customElementColumn={<label className="text-14">{form.email || '-'}</label>}
                    columnLeft={5}
                    columnRight={7}
                  />
                  <GridRowTableOneColumn
                    customElementInitial={<label className="text-14 title">Phone Number</label>}
                    customElementColumn={<label className="text-14">{form.phone || '-'}</label>}
                    columnLeft={5}
                    columnRight={7}
                  />
                  <GridRowTableOneColumn
                    customElementInitial={<label className="text-14 title">Gender</label>}
                    customElementColumn={<label className="text-14">{gender}</label>}
                    columnLeft={5}
                    columnRight={7}
                  />
                  <GridRowTableOneColumn
                    customElementInitial={<label className="text-14 title">Order Type</label>}
                    customElementColumn={<label className="text-14">{orderType}</label>}
                    columnLeft={5}
                    columnRight={7}
                  />
                </Grid>
              </FormControl>
            </Grid>
            <Grid item lg={6} md={6}>
              <DetailsAppointment orderData={appointmentDetails} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={12} md={12}>
          <Grid container spacing={2}>
            <Grid item lg={6} md={6}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <FormLabel component="label" className="text-12 filed-title">
                  Payment Type
                </FormLabel>
                <SelectInputMain
                  options={paymentMethodOption}
                  currentValue={
                    this.state.form.paymentMethod || paymentMethodOptionCode.bank_transfer
                  }
                  onChange={this.handleSelectPaymentMethod}
                  disabled={disableContent}
                />
              </FormControl>
            </Grid>
            {this.renderPaymentMethodAction()}
          </Grid>
        </Grid>
        <Grid item lg={12} md={12}>
          <Grid container spacing={2}>
            <Grid item lg={6} md={6}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <FormLabel component="label" className="text-12 filed-title">
                  Promo Code
                </FormLabel>
                <div className={`container-promo ${isSuccess}`}>
                  <TextInput
                    placeHolderText="Promo Code"
                    size="md"
                    onChange={this.handleChangePromoCodeEntry}
                    currentValue={this.state.form.promoCode}
                    errorMessage={validation.promoCode.errorMessage}
                    isError={validation.promoCode.isError}
                    iconPrefixEnd={isValidPromoCode ? successIcon : ''}
                    isDisable={isDisablePromoCode || disableContent}
                  />
                </div>
              </FormControl>
            </Grid>
            <Fade in={isSuccess === 'success'} timeout={timeInterval}>
              <Grid item lg={6} md={6} className="mt-43">
                <LabelAlert message={potentialPromo} type="success" size="middle" />
              </Grid>
            </Fade>
          </Grid>
        </Grid>
        <Grid item lg={12} md={12} className="mt-16">
          {isShowAgreement && this.renderSectionAgreement()}
        </Grid>
        <Grid item lg={12} md={12}>
          {form.step_booking_product_details &&
            !_.isEmpty(form.step_booking_product_details.selectedProduct) &&
            this.renderCartItem()}
        </Grid>
        <Grid item lg={12} md={12}>
          <Grid container justify="flex-end">
            <Grid item lg={5} md={6}>
              <Grid container justify="space-around">
                <Grid item lg={4} md={4} className="pl-8">
                  <ButtonMain
                    type="negative"
                    size="xl"
                    labelText="Cancel"
                    isLoading={disableContent}
                    onClick={onButtonClickCancel}
                  />
                </Grid>
                <Grid item lg={4} md={4} className="pl-8">
                  <ButtonMain
                    type="ghost"
                    size="xl"
                    labelText="Prev"
                    isLoading={disableContent}
                    onClick={this.handlePrevClick}
                  />
                </Grid>
                <Grid item lg={4} md={4} className="pl-8">
                  <ButtonMain
                    type="primary"
                    size="xl"
                    labelText="Submit"
                    isLoading={disableContent}
                    onClick={this.handleSubmitClick}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {isOpenModal && (
          <ModalTermConditions
            isOpen={isOpenModal}
            onClose={this.handleCloseModal}
            onHandleCheckAgreement={this.handleCheckAgreement}
            checked={checked}
            data={TnCSportClinic}
          />
        )}
      </Grid>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  checkPromo: params => checkPromoCode(dispatch, params),
  membershipDetailsByBook: params => getMembershipDetailsByBook(dispatch, params),
  medicConsultation: params => checkMedicConsultation(dispatch, params),
});

const mapStateToProps = ({ orderData, scheduleSummary }) => ({ orderData, scheduleSummary });

StepBookingSummaryAndPayment.propTypes = {
  checkPromo: PropTypes.func,
  medicConsultation: PropTypes.func,
  membershipDetailsByBook: PropTypes.func,
  onButtonClickCancel: PropTypes.func,
  onButtonClickSubmit: PropTypes.func,
  onParameterUpdate: PropTypes.func,
  onPopUpInformation: PropTypes.func,
  onPrevClick: PropTypes.func,
  orderData: PropTypes.object,
  parentForm: PropTypes.object,
  parentValidation: PropTypes.object,
  scheduleSummary: PropTypes.object,
};

const shell = compose(connect(mapStateToProps, mapDispatchToProps));

export default shell(PrevNextStepperCount(StepBookingSummaryAndPayment));
