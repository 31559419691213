/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-case-declarations */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, FormControl, FormLabel } from '@material-ui/core';
// component
import {
  TextInput,
  SelectInputMain,
  PickerInputDate,
  RadioInput,
  TextAreaMain,
  SelectInputGeneral,
} from '../../../../../../../../components/Index';
// helper
import { MasterDataHelper, CommonHelper } from '../../../../../../../../helpers/Index';
// style
import './PersonalInformationStyle.scss';

const currentDate = CommonHelper.currentDate('YYYY-MM-DD');
const defaultBirthday = currentDate;
const genderOptions = MasterDataHelper.gender;
const idTypeOptions = MasterDataHelper.idTypeOption;
const religionOption = MasterDataHelper.religionOption;
const maritalStatusOption = MasterDataHelper.maritalStatusOption;

const initialForm = {
  name: '',
  identity_type: 'ktp',
  idNumber: '',
  birthPlaceBirthday: '',
  birthday: defaultBirthday,
  gender: genderOptions[0],
  address: '',
  rt: '',
  rw: '',
  village: '',
  district: '',
  city: '',
  province: '',
  occupation: '',
  country_id: 1,
  religion: 'islam',
  maritalStatus: 'single',
};

const initialValidation = {
  name: { isError: false, errorMessage: '' },
  gender: { isError: false, errorMessage: '' },
  birthday: { isError: false, errorMessage: '' },
  // idNumber: { isError: false, errorMessage: '' },
  // birthPlaceBirthday: { isError: false, errorMessage: '' },
  // address: { isError: '', errorMessage: '' },
  // occupation: { isError: false, errorMessage: '' },
  // rt: { isError: false, errorMessage: '' },
  // rw: { isError: false, errorMessage: '' },
  // province: { isError: false, errorMessage: '' },
  // village: { isError: false, errorMessage: '' },
  // city: { isError: false, errorMessage: '' },
  // district: { isError: false, errorMessage: '' },
};

const onValidationHandler = payload => {
  let validationNewValue = CommonHelper.objectCloning(initialValidation);
  const excludeValidationKeys = [
    'identity_type',
    'idNumber',
    'birthPlaceBirthday',
    'birthday',
    'gender',
    'address',
    'rt',
    'rw',
    'village',
    'district',
    'city',
    'province',
    'occupation',
    'country_id',
    'religion',
    'maritalStatus',
  ];

  const keys = Object.keys(payload).filter(key => {
    return excludeValidationKeys.indexOf(key) === -1;
  });

  const errorValidationKeys = Object.keys(validationNewValue).filter(key => {
    return excludeValidationKeys.indexOf(key) === -1;
  });

  const errorKeys = keys.filter(key => {
    return !payload[key];
  });

  errorValidationKeys.forEach(key => {
    const isError = errorKeys.indexOf(key) !== -1;
    let errorDetail = {};
    switch (key) {
      case 'name':
        errorDetail = { isError, errorMessage: isError ? 'Please fill name' : '' };
        break;
      case 'gender':
        errorDetail = { isError, errorMessage: isError ? 'Please select gender' : '' };
        break;
      case 'birthday':
        errorDetail = { isError, errorMessage: isError ? 'Please fill birthday' : '' };
        break;
      // case 'idNumber':
      //   errorDetail = { isError, errorMessage: isError ? 'Please fill ID number' : '' };
      //   break;
      // case 'birthPlaceBirthday':
      //   errorDetail = { isError, errorMessage: isError ? 'Please fill birth place' : '' };
      //   break;
      // case 'address':
      //   errorDetail = { isError, errorMessage: isError ? 'Please fill address' : '' };
      //   break;
      // case 'occupation':
      //   errorDetail = { isError, errorMessage: isError ? 'Please fill occupation' : '' };
      //   break;
      // case 'rt':
      //   errorDetail = { isError, errorMessage: isError ? 'Please fill RT' : '' };
      //   break;
      // case 'rw':
      //   errorDetail = { isError, errorMessage: isError ? 'Please fill RW' : '' };
      //   break;
      // case 'province':
      //   errorDetail = { isError, errorMessage: isError ? 'Please fill province' : '' };
      //   break;
      // case 'city':
      //   errorDetail = { isError, errorMessage: isError ? 'Please fill city' : '' };
      //   break;
      // case 'village':
      //   errorDetail = { isError, errorMessage: isError ? 'Please fill village' : '' };
      //   break;
      // case 'district':
      //   errorDetail = { isError, errorMessage: isError ? 'Please fill district' : '' };
      //   break;
      default:
        errorDetail = {
          isError,
          errorMessage: isError ? 'Please fill input' : '',
        };
        break;
    }
    validationNewValue = {
      ...validationNewValue,
      [key]: errorDetail,
    };
  });

  return { isError: Boolean(errorKeys.length), validationNewValue };
};

const PersonalInformationForm = ({
  // faceSimilarity,
  masterData: { countryList },
  ocrResult,
  identityImageValue,
  selfieImageValue,
  // checkUserCovidTest,
  onParameterUpdate,
  onValidationUpdate,
  validate,
}) => {
  const [form, setForm] = useState(initialForm);
  const [validation, setValidation] = useState(initialValidation);
  const [identityImage, setIdentityImage] = useState(null);
  const [selfieImage, setSelfieImage] = useState(null);
  // const [fetching, setFetching] = useState(false);

  useEffect(() => {
    localStorage.removeItem('ocrImageSrc');
    localStorage.removeItem('ocrImageSrc-formattedbase64');
    localStorage.removeItem('selfieImageSrc');
  }, []);

  useEffect(() => {
    if (identityImageValue) {
      const ocrImageSrc = JSON.parse(localStorage.getItem('ocrImageSrc'));
      setIdentityImage(ocrImageSrc);
    } else {
      setIdentityImage(null);
    }
  }, [identityImageValue]);

  useEffect(() => {
    if (selfieImageValue) {
      const selfieImageSrc = JSON.parse(localStorage.getItem('selfieImageSrc'));
      setSelfieImage(selfieImageSrc);
    } else {
      setSelfieImage(null);
    }
  }, [selfieImageValue]);

  useEffect(() => {
    if (ocrResult) {
      let validResult = {};
      const resultKey = Object.keys(ocrResult).filter(key => ocrResult[key]);

      resultKey.forEach(item => {
        const value = ocrResult[item];
        switch (item) {
          case 'birthPlaceBirthday':
            const [place, birthday] = value.split(',');
            const [day, month, year] = birthday.split('-');
            const formattedDate = `${year}-${month}-${day.trim()}`;
            validResult = {
              ...validResult,
              birthPlaceBirthday: place,
              birthday: formattedDate,
            };
            break;
          case 'rtrw':
            const [rt, rw] = value.split('/');
            validResult = { ...validResult, rt, rw };
            break;
          case 'religion':
            validResult = { ...validResult, religion: value.toLowerCase() };
            break;
          case 'gender':
            const selectedGender = value === 'LAKI-LAKI' ? genderOptions[0] : genderOptions[1];
            validResult = { ...validResult, gender: selectedGender };
            break;
          case 'maritalStatus':
            const maritalStatus = maritalStatusOption.find(
              marital => marital.name.toLowerCase() === value.toLowerCase(),
            );
            validResult = {
              ...validResult,
              maritalStatus: (maritalStatus && maritalStatus.value) || 'single',
            };
            break;

          default:
            validResult = { ...validResult, [item]: value };
            break;
        }
      });

      setForm(oldForm => ({
        ...oldForm,
        ...validResult,
        identity_type: 'ktp',
        country_id: 1,
      }));
    }
  }, [ocrResult]);

  useEffect(() => {
    if (validate) {
      const error = onValidationHandler(form);
      setValidation(error.validationNewValue);
      const payload = {
        ...error,
      };
      onValidationUpdate(payload, 'personal_information');
    }
  }, [validate]);

  useEffect(() => {
    const payload = {
      ...form,
    };
    onParameterUpdate(payload, 'personal_information');
  }, [form]);

  // const sendQuery = async keyword => {
  //   try {
  //     setFetching(true);
  //     const body = {
  //       nik: keyword,
  //     };
  //     await checkUserCovidTest(body);
  //     setFetching(false);
  //   } catch (error) {
  //     setFetching(false);
  //   }
  // };

  // const getCovidTest = useRef(_.debounce(q => sendQuery(q), 800)).current;

  const onHandleChangeForm = (value, key) => {
    setForm(oldForm => ({
      ...oldForm,
      [key]: value,
    }));

    // if (key === 'idNumber' && value) {
    //   getCovidTest(value);
    // }
  };

  return (
    <>
      <Grid item lg={12} md={12} className="mb-4">
        <label className="text-12 text-bold text-rolling-stone">PERSONAL INFORMATION</label>
      </Grid>
      <Grid item lg={12} md={12} className="personal-information-form-container">
        <Grid container spacing={6}>
          <Grid item lg={6} md={6}>
            <Grid item lg={12} md={12}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <FormLabel component="label" className="text-12 filed-title" required>
                  Nama
                </FormLabel>
                <TextInput
                  placeHolderText="Name"
                  size="md"
                  onChange={value => {
                    onHandleChangeForm(value, 'name');
                  }}
                  currentValue={form.name}
                  errorMessage={validation.name.errorMessage}
                  isError={validation.name.isError}
                />
              </FormControl>
            </Grid>
            <Grid item lg={12} md={12}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <FormLabel component="label" className="text-12 filed-title">
                  Tipe Identitas
                </FormLabel>
                <SelectInputMain
                  options={idTypeOptions}
                  currentValue={form.identity_type}
                  onChange={value => onHandleChangeForm(value, 'identity_type')}
                />
              </FormControl>
            </Grid>
            <Grid item lg={12} md={12}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <FormLabel component="label" className="text-12 filed-title">
                  Nomor Identitas
                </FormLabel>
                <TextInput
                  placeHolderText="Please enter your Identity Number"
                  size="md"
                  // onChange={value => onHandleChangeForm(value, 'idNumber')}
                  currentValue={form.idNumber}
                  maxLength={form.identity_type === 'ktp' || form.identity_type === 'kia' ? 16 : 10}
                  type={form.identity_type === idTypeOptions[0].value ? 'tel' : 'text'}
                  // errorMessage={validation.idNumber.errorMessage}
                  // isError={validation.idNumber.isError}
                  numericOnly={form.identity_type !== 'passport'}
                  // isLoading={fetching}
                />
              </FormControl>
            </Grid>
            <Grid item lg={12} md={12}>
              <Grid container spacing={4}>
                <Grid item lg={6} md={6}>
                  <Grid item lg={12} md={12}>
                    <FormControl component="fieldset" fullWidth margin="normal">
                      <FormLabel component="label" className="text-12 filed-title">
                        Tempat Lahir
                      </FormLabel>
                      <TextInput
                        placeHolderText="Masukkan tempat lahir"
                        size="md"
                        onChange={value => {
                          onHandleChangeForm(value, 'birthPlaceBirthday');
                        }}
                        currentValue={form.birthPlaceBirthday}
                        // errorMessage={validation.birthPlaceBirthday.errorMessage}
                        // isError={validation.birthPlaceBirthday.isError}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item lg={12} md={12}>
                    <FormControl component="fieldset" fullWidth margin="normal">
                      <FormLabel component="label" className="text-12 filed-title" required>
                        Jenis Kelamin
                      </FormLabel>
                      <RadioInput
                        data={genderOptions}
                        onSelect={value => onHandleChangeForm(value, 'gender')}
                        direction="column"
                        currentValue={form.gender}
                        errorMessage={validation.gender.errorMessage}
                        isError={validation.gender.isError}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid item lg={6} md={6}>
                  <Grid item lg={12} md={12}>
                    <FormControl component="fieldset" fullWidth margin="normal">
                      <FormLabel component="label" className="text-12 filed-title" required>
                        Tanggal Lahir
                      </FormLabel>
                      <PickerInputDate
                        customIcon="ic-ffo-date-pick"
                        dateFormat="dd-MM-yyyy"
                        maxDate={currentDate}
                        defaultValue={form.birthday}
                        onChange={value => onHandleChangeForm(value, 'birthday')}
                        toolbar={false}
                        errorMessage={validation.birthday.errorMessage}
                        isError={validation.birthday.isError}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item lg={12} md={12}>
                    <FormControl component="fieldset" fullWidth margin="normal">
                      <FormLabel component="label" className="text-12 filed-title">
                        Agama
                      </FormLabel>
                      <SelectInputMain
                        options={religionOption}
                        currentValue={form.religion}
                        onChange={value => onHandleChangeForm(value, 'religion')}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={12} md={12}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <FormLabel component="label" className="text-12 filed-title">
                  Alamat Sesuai Identitas
                </FormLabel>
                <TextAreaMain
                  onChange={value => onHandleChangeForm(value, 'address')}
                  currentValue={form.address}
                  placeholder="Enter Short Description"
                  rows={3}
                  // validateStatus={validation.address.isError ? 'error' : ''}
                  // errorMessage={validation.address.errorMessage}
                />
              </FormControl>
            </Grid>
            <Grid item lg={12} md={12}>
              <Grid container spacing={4}>
                <Grid item lg={6} md={6}>
                  <Grid item lg={12} md={12}>
                    <FormControl component="fieldset" fullWidth margin="normal">
                      <FormLabel component="label" className="text-12 filed-title">
                        RT
                      </FormLabel>
                      <TextInput
                        placeHolderText="eg. 001"
                        size="md"
                        onChange={value => {
                          onHandleChangeForm(value, 'rt');
                        }}
                        currentValue={form.rt}
                        // errorMessage={validation.rt.errorMessage}
                        // isError={validation.rt.isError}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item lg={12} md={12}>
                    <FormControl component="fieldset" fullWidth margin="normal">
                      <FormLabel component="label" className="text-12 filed-title">
                        Kelurahan / Desa
                      </FormLabel>
                      <TextInput
                        placeHolderText="eg. Pluit"
                        size="md"
                        onChange={value => {
                          onHandleChangeForm(value, 'village');
                        }}
                        currentValue={form.village}
                        // errorMessage={validation.village.errorMessage}
                        // isError={validation.village.isError}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item lg={12} md={12}>
                    <FormControl component="fieldset" fullWidth margin="normal">
                      <FormLabel component="label" className="text-12 filed-title">
                        Kota / Kabupaten
                      </FormLabel>
                      <TextInput
                        placeHolderText="eg. Jakarta Utara"
                        size="md"
                        onChange={value => {
                          onHandleChangeForm(value, 'city');
                        }}
                        currentValue={form.city}
                        // errorMessage={validation.city.errorMessage}
                        // isError={validation.city.isError}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid item lg={6} md={6}>
                  <Grid item lg={12} md={12}>
                    <FormControl component="fieldset" fullWidth margin="normal">
                      <FormLabel component="label" className="text-12 filed-title">
                        RW
                      </FormLabel>
                      <TextInput
                        placeHolderText="eg. 001"
                        size="md"
                        onChange={value => {
                          onHandleChangeForm(value, 'rw');
                        }}
                        currentValue={form.rw}
                        // errorMessage={validation.rw.errorMessage}
                        // isError={validation.rw.isError}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item lg={12} md={12}>
                    <FormControl component="fieldset" fullWidth margin="normal">
                      <FormLabel component="label" className="text-12 filed-title">
                        Kecamatan
                      </FormLabel>
                      <TextInput
                        placeHolderText="eg. Penjaringan"
                        size="md"
                        onChange={value => {
                          onHandleChangeForm(value, 'district');
                        }}
                        currentValue={form.district}
                        // errorMessage={validation.district.errorMessage}
                        // isError={validation.district.isError}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item lg={12} md={12}>
                    <FormControl component="fieldset" fullWidth margin="normal">
                      <FormLabel component="label" className="text-12 filed-title">
                        Provinsi
                      </FormLabel>
                      <TextInput
                        placeHolderText="eg. DKI Jakarta"
                        size="md"
                        onChange={value => {
                          onHandleChangeForm(value, 'province');
                        }}
                        currentValue={form.province}
                        // errorMessage={validation.province.errorMessage}
                        // isError={validation.province.isError}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={12} md={12}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <FormLabel component="label" className="text-12 filed-title">
                  Kewarganegaraan
                </FormLabel>
                <div className="container-remove-margin">
                  <SelectInputGeneral
                    placeHolder="Select Country"
                    currentValue={form.country_id}
                    dataList={countryList}
                    primaryKey="country_id"
                    onChange={value => onHandleChangeForm(value, 'country_id')}
                  />
                </div>
              </FormControl>
            </Grid>
            <Grid item lg={12} md={12}>
              <Grid container spacing={4}>
                <Grid item lg={6} md={6}>
                  <Grid item lg={12} md={12}>
                    <FormControl component="fieldset" fullWidth margin="normal">
                      <FormLabel component="label" className="text-12 filed-title">
                        Status Perkawinan
                      </FormLabel>
                      <SelectInputMain
                        options={maritalStatusOption}
                        currentValue={form.maritalStatus}
                        onChange={value => onHandleChangeForm(value, 'maritalStatus')}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid item lg={6} md={6}>
                  <Grid item lg={12} md={12}>
                    <FormControl component="fieldset" fullWidth margin="normal">
                      <FormLabel component="label" className="text-12 filed-title">
                        Pekerjaan
                      </FormLabel>
                      <TextInput
                        placeHolderText="Pilih Pekerjaan"
                        size="md"
                        onChange={value => {
                          onHandleChangeForm(value, 'occupation');
                        }}
                        currentValue={form.occupation}
                        // errorMessage={validation.occupation.errorMessage}
                        // isError={validation.occupation.isError}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={6} md={6}>
            <IdentityPhoto
              imgSrc={selfieImage}
              title="Foto Selfie"
              text="Foto selfie anda akan tampil di area ini"
            />
            <IdentityPhoto
              imgSrc={identityImage}
              title="Foto Identitas"
              text="Foto KTP anda akan tampil di area ini"
            />
            {/* {selfieImage && identityImage && faceSimilarity ? (
              <Grid item lg={12} md={12}>
                <FormControl component="fieldset" fullWidth margin="normal">
                  <FormLabel component="label" className="text-12 filed-title">
                    Tingkat Kemiripan
                  </FormLabel>
                  <ProgressBar value={faceSimilarity} />
                </FormControl>
              </Grid>
            ) : null} */}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

PersonalInformationForm.propTypes = {
  // checkUserCovidTest: PropTypes.func,
  // faceSimilarity: PropTypes.number,
  identityImageValue: PropTypes.bool,
  masterData: PropTypes.object,
  ocrResult: PropTypes.any,
  onParameterUpdate: PropTypes.func,
  onValidationUpdate: PropTypes.func,
  selfieImageValue: PropTypes.bool,
  validate: PropTypes.bool,
};

export default PersonalInformationForm;

const IdentityPhoto = ({ imgSrc, title, text }) => {
  return (
    <Grid item lg={12} md={12}>
      <FormControl component="fieldset" fullWidth margin="normal">
        <FormLabel component="label" className="text-12 filed-title">
          {title}
        </FormLabel>
        <div className="image-container">
          {imgSrc ? (
            <img src={imgSrc} alt="" />
          ) : (
            <label className="text-12 filed-title">{text}</label>
          )}
        </div>
      </FormControl>
    </Grid>
  );
};

IdentityPhoto.propTypes = {
  imgSrc: PropTypes.any,
  text: PropTypes.string,
  title: PropTypes.string,
};

const ProgressBar = ({ value }) => {
  const barWidth = (value / 100) * 100;
  const isLessThanThreshold = value < 70;
  const classColor = isLessThanThreshold ? 'secondary' : 'primary';
  let labelText = 'Verifikasi Selfie dan Foto Identitas sukses.';

  if (isLessThanThreshold) {
    labelText = 'Verifikasi Selfie dan FOTO Identitas gagal, lakukan pengecekan manual.';
  }

  return (
    <div className="similarity-progressbar">
      <label className={`text-12`}>{labelText}</label>
      <div className="similarity-progressbar--container-row">
        <div className="similarity-progressbar--container">
          <div
            className={`similarity-progressbar--bar ${classColor}`}
            style={{ width: `${barWidth}%` }}
          />
        </div>
        <span>{value}%</span>
      </div>
    </div>
  );
};

ProgressBar.propTypes = {
  value: PropTypes.number,
};
