/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { Link } from 'react-router-dom';
import { Avatar, Grid, Menu, MenuItem, Fade } from '@material-ui/core';
import PropTypes from 'prop-types';
// styles
import StylesJs from './ProfileOptionStyle';
import './ProfileOptionStyle.scss';
import { CommonHelper } from '../../../../../../helpers/Index';

const ProfileOption = props => {
  const { imagesUrl, fullName, jobName, innerTitleEmailHeader, onClick, userID } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const classes = StylesJs();

  const handleOpenMenu = event => {
    const isOpen = event.currentTarget;
    setAnchorEl(isOpen);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const DrawerMenu = () => {
    const isOpen = Boolean(anchorEl);
    const param = CommonHelper.encryptObject({ userId: userID });
    const profilePageUrl = `/management/users/details/${param}`;

    return (
      <Menu
        id="profile-menu"
        anchorEl={anchorEl}
        keepMounted
        open={isOpen}
        onClose={handleCloseMenu}
        elevation={2}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        TransitionComponent={Fade}
      >
        <div className="container-inner-pop-up-menu wrapping-container">
          <div>
            <label className="text-10 text-opacity-50">Logged in as</label>
          </div>
          <div>
            <label className="text-14 header">{innerTitleEmailHeader}</label>
          </div>
        </div>
        <MenuItem>
          <Link to={profilePageUrl}>
            <label className="text-12 text-opacity-50">Profile</label>
          </Link>
        </MenuItem>
        <MenuItem onClick={onClick}>
          <label className="text-12 text-opacity-50">Logout</label>
        </MenuItem>
      </Menu>
    );
  };

  return (
    <div>
      <Grid container aria-controls="profile-menu" onClick={handleOpenMenu}>
        <Grid item lg md xs>
          <Grid container justify="flex-end" alignItems="center" className="container-profile">
            <Grid item lg="auto" md="auto" xs={4}>
              <Avatar alt="profile image" src={imagesUrl} className={classes.customAvatar} />
            </Grid>
            <Grid item lg={9} md={8} xs={8} className="container-text-main">
              <Grid container justify="space-between">
                <Grid item lg={10} md={9} xs={9}>
                  <div className="flex-column">
                    <label className="text-14 main wrapping-container">{fullName}</label>
                    <label className="text-10">{jobName}</label>
                  </div>
                </Grid>
                <Grid item lg="auto" md="auto" xs={3}>
                  <Grid container justify="flex-end">
                    <Grid item>
                      <div>
                        <i className="ic-ffo-chevron-down container-icon-prefix size-16" />
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <DrawerMenu />
    </div>
  );
};

ProfileOption.propTypes = {
  fullName: PropTypes.string,
  imagesUrl: PropTypes.string,
  innerTitleEmailHeader: PropTypes.string,
  jobName: PropTypes.string,
  onClick: PropTypes.func,
  userID: PropTypes.number,
};

export default ProfileOption;
