import React from 'react';
import PropTypes from 'prop-types';
// Style
import themes from '../../../../../themes/Index';

const LabelTotalCustom = props => {
  const {
    viewBox: { cx, cy },
    valueP1,
    valueP2,
  } = props;

  return (
    <g>
      <text
        x={cx}
        y={cy}
        fontSize={16}
        fontFamily="Montserrat"
        fontWeight={700}
        textAnchor="middle"
        fill={themes.palette.mineShaft}
      >
        {valueP1}
      </text>
      <text
        x={cx}
        y={cy + 16}
        dy={-4}
        fontSize={10}
        fontFamily="Montserrat"
        fontWeight={700}
        textAnchor="middle"
        fill={themes.palette.rollingStone}
      >
        {valueP2}
      </text>
    </g>
  );
};

LabelTotalCustom.propTypes = {
  valueP1: PropTypes.string,
  valueP2: PropTypes.string,
  viewBox: PropTypes.object,
};

export default LabelTotalCustom;
