import React from 'react';
import PropTypes from 'prop-types';
import { Page, Text, View, Document, Font } from '@react-pdf/renderer';
// Style
import StylesJs from './LabelMealStyle';
// assets
import { StyleJs as StyleJsGlobal } from '../../../../../assets/Index';
// Helpers
import { CommonHelper } from '../../../../../helpers/Index';

// Register font
Font.register({
  family: 'Roboto',
  fonts: [
    { src: './src/assets/fonts/montserrat/Montserrat-Regular.ttf' },
    { src: './src/assets/fonts/montserrat/Montserrat-Bold.ttf', fontWeight: 700 },
  ],
});

const LabelMealAddress = props => {
  const { data } = props;

  return (
    <Document>
      <Page size="A4" orientation="portrait" style={{ paddingVertical: 22, paddingHorizontal: 0 }}>
        <View
          style={[
            StyleJsGlobal.gridRow,
            {
              width: 845,
            },
          ]}
        >
          {data.map((item, index) => {
            const date = CommonHelper.dateTimeParseNewFormat(item.date, 'DD MMMM YYYY');
            const isOdd = (index + 1) % 2;
            return (
              <View
                key={index}
                style={[
                  StyleJsGlobal.gridItem6,
                  {
                    width: '100%',
                  },
                ]}
                wrap={false}
              >
                <View style={[StyleJsGlobal.gridColumn]}>
                  <View
                    style={[
                      StylesJs.containerCardLabel,
                      isOdd
                        ? { alignSelf: 'flex-end', paddingRight: 3 }
                        : { alignSelf: 'flex-start', paddingLeft: 3 },
                    ]}
                  >
                    <View style={[StylesJs.containerLabel]}>
                      <View
                        style={[
                          StyleJsGlobal.flexRow,
                          StyleJsGlobal.justifyContentSpaceBetween,
                          { marginBottom: 5 },
                        ]}
                      >
                        <View style={[StylesJs.flex1]}>
                          <Text style={[StylesJs.textCustomerName, { marginBottom: 2 }]}>
                            {item.customer_name}
                          </Text>
                          <Text style={[StylesJs.textNotes]}>{item.phone}</Text>
                        </View>
                        <View style={{ alignItems: 'flex-end' }}>
                          <Text style={[StylesJs.textDate]}>{date}</Text>
                        </View>
                      </View>

                      <Text
                        style={[StylesJs.textNotes, { fontWeight: 'semibold', marginBottom: 5 }]}
                      >
                        {item.address}
                      </Text>
                      <Text style={[StylesJs.textNotes]}>Note: {item.delivery_notes || '-'}</Text>
                    </View>
                  </View>
                </View>
              </View>
            );
          })}
        </View>
      </Page>
    </Document>
  );
};

LabelMealAddress.propTypes = {
  data: PropTypes.array,
};

export default LabelMealAddress;
