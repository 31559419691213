/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { compose } from 'redux';
import _ from 'lodash';
// Components
import { HeaderOrderShipmentPage } from './components/Index';
import {
  SnackBarSimple,
  AuthenticationAccessPages,
  PrevStateValue,
} from '../../../components/Index';
// Components View
import { ShipmentContentPage, ShipmentDeliveryReport } from './Index';
// Action
import { setPrevState } from '../../../redux/actions/UsersAction';
// Helper
import { MasterDataHelper, CommonHelper, UserHelper } from '../../../helpers/Index';

const currentUsers = UserHelper.getCurrentUserInformation();
let dataTabs = CommonHelper.objectCloning(MasterDataHelper.orderShipmentStatus);
dataTabs = currentUsers && currentUsers.isResellerLogin ? dataTabs.splice(0, 3) : dataTabs;

const optionAlert = {
  vertical: 'top',
  horizontal: 'right',
};

class OrderShipmentPage extends React.Component {
  constructor(props) {
    super(props);
    const {
      usersReducer: { prevStateValue },
    } = props;

    this.state = {
      tabsValue: prevStateValue.activeTab || 0,
      alertInformation: {
        isOpen: false,
        message: '',
        snackbarType: 'warning',
      },
    };
  }

  componentDidMount() {
    const {
      onResetPrevStateValue,
      history: { location },
    } = this.props;
    onResetPrevStateValue({ url: location.pathname });
  }

  componentWillReceiveProps(nextProps) {
    const { usersReducer } = nextProps;

    if (_.isEmpty(usersReducer.prevStateValue)) {
      this.handleTabChange(0);
    }
  }

  handleTabChange = value => {
    const {
      onSetPrevStateValueTabs,
      history: { location },
    } = this.props;

    this.setState({ tabsValue: value }, () => {
      onSetPrevStateValueTabs({ activeTab: value, url: location.pathname });
    });
  };

  handleClickCreateOrder = () => {
    const { history } = this.props;
    history.push('/order/add');
  };

  handleCloseAlert = () => {
    const { alertInformation } = this.state;
    this.setState({ alertInformation: { ...alertInformation, isOpen: false } });
  };

  render() {
    const { alertInformation } = this.state;
    const { history } = this.props;

    return (
      <div>
        <Helmet title={`FITCO | Orders - Shipments`} />
        <div>
          <HeaderOrderShipmentPage
            data={dataTabs}
            currentTab={this.state.tabsValue}
            onChangeTab={this.handleTabChange}
          />
          {dataTabs[this.state.tabsValue].value === 'delivery_report' ? (
            <ShipmentDeliveryReport history={history} />
          ) : (
            <ShipmentContentPage
              history={history}
              activeTab={dataTabs[this.state.tabsValue].value}
            />
          )}
        </div>
        <SnackBarSimple
          open={alertInformation.isOpen}
          durationHide={2000}
          message={alertInformation.message}
          onClickClose={this.handleCloseAlert}
          snackbarType={alertInformation.snackbarType}
          anchor={optionAlert}
        />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  setPrevStateValue: params => dispatch(setPrevState(params)),
});

const mapStateToProps = ({ usersReducer }) => ({ usersReducer });

OrderShipmentPage.propTypes = {
  history: PropTypes.object,
  onResetPrevStateValue: PropTypes.func,
  onSetPrevStateValueTabs: PropTypes.func,
  usersReducer: PropTypes.object,
};

const shell = compose(connect(mapStateToProps, mapDispatchToProps));
const core = compose(AuthenticationAccessPages, PrevStateValue);

export default shell(core(OrderShipmentPage));
