import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import _ from 'lodash';

const DetailsAppointment = props => {
  const {
    orderData: { orderDetails },
  } = props;
  const appointmentDetails = !_.isEmpty(orderDetails) ? orderDetails.appointment_details : {};

  return (
    <Grid container className="pt-24" direction="column">
      <Grid item lg={12} md={12} className="pb-16">
        <label className="text-12 text-opacity-50 text-bold">Appointment Details</label>
      </Grid>
      <Grid item lg={12} md={12} className="order-card-details">
        <Grid container direction="column">
          <Grid item lg={12} md={12}>
            <div className="flex-column pb-24">
              <label className="text-12 text-bold text-opacity-50 wrapping-container wrapping-container-break mb-8">
                Schedule
              </label>
              <label className="text-14 wrapping-container wrapping-container-break">
                {`${appointmentDetails.date} | ${appointmentDetails.start_time} - ${appointmentDetails.end_time}`}
              </label>
            </div>
          </Grid>
          <Grid item lg={12} md={12}>
            <div className="flex-column pt-8">
              <label className="text-12 text-bold text-opacity-50 mb-8">Location</label>
              <label className="text-14 wrapping-container third line wrapping-container-break mb-6">
                {appointmentDetails.location || '-'}
              </label>
              <label className="text-12 wrapping-container third line wrapping-container-break">
                {appointmentDetails.location_address || '-'}
              </label>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

DetailsAppointment.propTypes = {
  orderData: PropTypes.object,
};

export default DetailsAppointment;
