export const isMock = false;
export const baseUrl = 'https://productionapi.fitco.id/';

export const production = true;
export const cdn = 'https://cdn.fit2go.id/app/public/';

// #S3
export const FILESYSTEM_DRIVER = 's3';
export const FILESYSTEM_CLOUD = 's3';
export const AWS_ACCESS_KEY_ID = 'AKIAUM65UQB5X5ISRXUB';
export const AWS_SECRET_ACCESS_KEY = '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';
export const AWS_DEFAULT_REGION = 'ap-southeast-1';
export const AWS_BUCKET = 's3-padawan-production';
export const AWS_URL = 'https://cdn.fit2go.id/app/public';

// Upload Url
export const UPLOAD_URL = 'https://productionapi.fitco.id/public/pos/v2/mock/presave/images';
export const UPLOAD_SALT = 'be4eef34e9e9bdc7a4bbb7b102de8f2595284e22';
export const UPLOAD_ENV = 'production';

// Upload Image Service
export const UPLOAD_IMAGE_URL = 'https://upload.fitco.id';
export const UPLOAD_IMAGE_SALT = '737eb385f428e24c30167be154f30c0ea48dde49';
export const UPLOAD_IMAGE_ENV = 'production';
