import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
// Component
import { CalendarHeader, CalendarDetail } from '../Index';
// Helper
import { CalendarHelper, CommonHelper } from '../../../../../helpers/Index';

const currentDate = CommonHelper.currentDate('YYYY-MM-DD HH:mm:ss');

class CalendarSummary extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      calendarDayList: [],
    };
  }

  componentDidMount() {
    const { dayValue } = this.props;
    const weekDay = CalendarHelper.getWeekDayByToday(dayValue);
    this.generateTimeLineHeader(weekDay, false);
  }

  componentDidUpdate(prevProps) {
    if (this.props.dayValue !== prevProps.dayValue) {
      const weekDay = CalendarHelper.getWeekDayByToday(this.props.dayValue);
      this.generateTimeLineHeader(weekDay, false);
    }
  }

  handleCalendarHeaderLeftClick = () => {
    const currentDateIncrement = CommonHelper.intervalDate(
      this.state.intervalDate,
      'YYYY-MM-DD',
      -6,
      'day',
    );
    const weekDay = CalendarHelper.getWeekDayByToday(currentDateIncrement);
    this.generateTimeLineHeader(weekDay, true);
  };

  handleCalendarHeaderRightClick = () => {
    const currentDateIncrement = CommonHelper.intervalDate(
      this.state.intervalDate,
      'YYYY-MM-DD',
      8,
      'day',
    );
    const weekDay = CalendarHelper.getWeekDayByToday(currentDateIncrement);
    this.generateTimeLineHeader(weekDay, true);
  };

  generateTimeLineHeader(weekDay, isButtonClick) {
    const { onButtonHeaderClick } = this.props;
    const listHeaderTimeLine = CalendarHelper.generateListWeek(weekDay);
    const converted = CalendarHelper.generateCalenderHeaderData(listHeaderTimeLine);

    this.setState({ intervalDate: weekDay.startWeek, calendarDayList: converted });
    if (isButtonClick) {
      onButtonHeaderClick(converted);
    }
  }

  render() {
    const { data, onClickUpdate, onReloadColumn } = this.props;
    const { calendarDayList } = this.state;

    return (
      <Grid container direction="row" className="container-schedule-summary">
        <Grid item lg md>
          <CalendarHeader
            data={calendarDayList}
            onButtonLeftClick={this.handleCalendarHeaderLeftClick}
            onButtonRightClick={this.handleCalendarHeaderRightClick}
            today={currentDate}
          />
          <CalendarDetail
            dataHeader={calendarDayList}
            dataTimeLine={data}
            today={currentDate}
            onClickUpdate={onClickUpdate}
            onReloadColumn={onReloadColumn}
          />
        </Grid>
      </Grid>
    );
  }
}

const mapDispatchToProps = () => ({});

const mapStateToProps = () => ({});

CalendarSummary.propTypes = {
  data: PropTypes.object,
  dayValue: PropTypes.string,
  onButtonHeaderClick: PropTypes.func,
  onClickUpdate: PropTypes.func,
  onReloadColumn: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(CalendarSummary);
