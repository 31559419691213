/* eslint-disable no-plusplus */
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Page, View, Document, Text, StyleSheet } from '@react-pdf/renderer';
import {
  Table,
  TableHeader,
  TableCell,
  TableBody,
  DataTableCell,
} from '@david.kucsai/react-pdf-table';
// Helper
import { CommonHelper, currencyCode, GeneratorHelper } from '../../../../../helpers/Index';
import { StyleJs as StyleJsGlobal } from '../../../../../assets/Index';

const currentDateTime = CommonHelper.currentDate('YYYY-MM-DD | hh:mm A');

const styles = StyleSheet.create({
  textWhite: {
    color: '#FFF',
  },
  tableRowHeader: {
    backgroundColor: '#7c858b',
    color: '#FFF',
    padding: 4,
    paddingVertical: 8,
  },
  tableRowBody: {
    padding: 4,
  },
});

const CanvasDashboardMembershipList = props => {
  const { data } = props;
  const dataDetails = !_.isEmpty(data) ? data.details : {};
  const dataList = !_.isEmpty(data) ? GeneratorHelper.generateNumberTable(data.list) : [];
  let wordingHeaderDate = '';

  if (!_.isEmpty(dataDetails)) {
    wordingHeaderDate = dataDetails.isRecurring ? 'Recurring Date' : 'Expired Date';
  }

  const widthNo = 0.06;
  const widthName = 0.2;
  const widthPhone = 0.17;
  const widthEmail = 0.3;
  const widthMembership = 0.4;
  const widthPrice = 0.26;
  const widthDate = 0.17;

  return (
    <Document>
      <Page size="A4" orientation="landscape">
        <View style={[StyleJsGlobal.flexColumn, { padding: 24, width: 845 }]}>
          <Text style={[StyleJsGlobal.text18, StyleJsGlobal.textCenter, StyleJsGlobal.mb32]}>
            Membership List
          </Text>
          <Table data={dataList}>
            <TableHeader>
              <TableCell
                weighting={widthNo}
                style={[
                  StyleJsGlobal.text10,
                  StyleJsGlobal.textCenter,
                  styles.textWhite,
                  styles.tableRowHeader,
                ]}
              >
                No
              </TableCell>
              <TableCell
                weighting={widthName}
                style={[
                  StyleJsGlobal.text10,
                  styles.textWhite,
                  StyleJsGlobal.textCenter,
                  styles.tableRowHeader,
                ]}
              >
                Customer Name
              </TableCell>
              <TableCell
                weighting={widthPhone}
                style={[
                  StyleJsGlobal.text10,
                  styles.textWhite,
                  StyleJsGlobal.textCenter,
                  styles.tableRowHeader,
                ]}
              >
                Customer Phone
              </TableCell>
              <TableCell
                weighting={widthEmail}
                style={[
                  StyleJsGlobal.text10,
                  styles.textWhite,
                  StyleJsGlobal.textCenter,
                  styles.tableRowHeader,
                ]}
              >
                Customer Email
              </TableCell>
              <TableCell
                weighting={widthMembership}
                style={[
                  StyleJsGlobal.text10,
                  styles.textWhite,
                  StyleJsGlobal.textCenter,
                  styles.tableRowHeader,
                ]}
              >
                Membership Name
              </TableCell>
              <TableCell
                weighting={widthPrice}
                style={[
                  StyleJsGlobal.text10,
                  styles.textWhite,
                  StyleJsGlobal.textCenter,
                  styles.tableRowHeader,
                ]}
              >
                Membership Price
              </TableCell>
              <TableCell
                weighting={widthDate}
                style={[
                  StyleJsGlobal.text10,
                  styles.textWhite,
                  StyleJsGlobal.textCenter,
                  styles.tableRowHeader,
                ]}
              >
                {wordingHeaderDate}
              </TableCell>
            </TableHeader>
            <TableBody>
              <DataTableCell
                weighting={widthNo}
                style={[StyleJsGlobal.text10, StyleJsGlobal.textCenter, styles.tableRowBody]}
                getContent={r => r.no}
              />
              <DataTableCell
                weighting={widthName}
                style={[StyleJsGlobal.text10, styles.tableRowBody]}
                getContent={r => r.customer_name}
              />
              <DataTableCell
                weighting={widthPhone}
                style={[StyleJsGlobal.text10, StyleJsGlobal.textCenter, styles.tableRowBody]}
                getContent={r => r.customer_phone}
              />
              <DataTableCell
                weighting={widthEmail}
                style={[StyleJsGlobal.text10, StyleJsGlobal.textCenter, styles.tableRowBody]}
                getContent={r => r.customer_email}
              />
              <DataTableCell
                weighting={widthMembership}
                style={[StyleJsGlobal.text10, styles.tableRowBody]}
                getContent={r => r.product_name}
              />
              <DataTableCell
                weighting={widthPrice}
                style={[StyleJsGlobal.text10, StyleJsGlobal.textCenter, styles.tableRowBody]}
                getContent={r => {
                  const wordingPrice = `${currencyCode.idr} ${CommonHelper.formatCurrency(
                    r.total,
                  )}`;
                  return wordingPrice;
                }}
              />
              <DataTableCell
                weighting={widthDate}
                style={[StyleJsGlobal.text10, StyleJsGlobal.textCenter, styles.tableRowBody]}
                getContent={r => {
                  const wordingDate = CommonHelper.dateTimeParseNewFormat(
                    r.recurring_date || r.expired_at,
                    'DD MMM YYYY',
                  );
                  return wordingDate;
                }}
              />
            </TableBody>
          </Table>
          <Text style={[StyleJsGlobal.text10, StyleJsGlobal.mt16, StyleJsGlobal.textCenter]}>
            Print Date {currentDateTime}
          </Text>
        </View>
      </Page>
    </Document>
  );
};

CanvasDashboardMembershipList.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default CanvasDashboardMembershipList;
