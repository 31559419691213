import React from 'react';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import _ from 'lodash';
// component
import { GridRowTableOneColumn } from '../../../../components/Index';
// Helper
import { CommonHelper, currencyCode } from '../../../../helpers/Index';
// Action
import { getTopSpenderList } from '../../../../services/api/DashboardApi';
// style
import './CardTopSpenderStyle.scss';
import { Skeleton } from 'antd';

const initialFilter = {
  limit: 5,
};

class CardTopSpender extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: CommonHelper.objectCloning(initialFilter),
    };
  }

  componentDidUpdate(prevProps) {
    const { parentFilter } = this.props;

    const isEqual = _.isEqual(parentFilter, prevProps.parentFilter);

    if (parentFilter) {
      if (!isEqual) {
        this.setUpdateExistingFilter();
      }
    }
  }

  setUpdateExistingFilter() {
    const { parentFilter } = this.props;
    const { filter } = this.state;
    this.setState({ filter: { ...filter, ...parentFilter } }, () => {
      this.getTopSpenderList();
    });
  }

  getTopSpenderList = () => {
    const {
      topSpenderList,
      dashboardData: { fetchingTopSpender },
    } = this.props;
    const {
      filter: { startDate, endDate, limit, branchId, merchantId },
    } = this.state;

    const params = {
      start_date: startDate,
      end_date: endDate,
      merchant_id: merchantId,
      branch_id: branchId,
      limit,
    };

    if (!fetchingTopSpender && startDate && endDate) {
      topSpenderList(params);
    }
  };

  render() {
    const {
      dashboardData: { fetchingTopSpender, listTopSpender },
    } = this.props;

    return (
      <Grid container direction="column" className="container-page-top-spender flex-wrap-unset">
        <Grid item lg md>
          <label className="text-12 text-bold">Top Spender</label>
        </Grid>
        <Grid item lg md className="header">
          <GridRowTableOneColumn
            customElementInitial={
              <label className="text-10 text-rolling-stone mb-4">Customer Name</label>
            }
            customElementColumn={
              <div className="text-center">
                <label className="text-10 text-rolling-stone mb-4">Total Spends</label>
              </div>
            }
            columnLeft={7}
            columnRight={5}
          />
        </Grid>
        {fetchingTopSpender ? (
          <Grid container direction="column" spacing={1} className="pt-24">
            <Grid item>
              <Skeleton variant="rect" height={40} animation="wave" />
            </Grid>
            <Grid item>
              <Skeleton variant="rect" height={40} animation="wave" />
            </Grid>
            <Grid item>
              <Skeleton variant="rect" height={40} animation="wave" />
            </Grid>
            <Grid item>
              <Skeleton variant="rect" height={40} animation="wave" />
            </Grid>
            <Grid item>
              <Skeleton variant="rect" height={40} animation="wave" />
            </Grid>
          </Grid>
        ) : (
          listTopSpender.map((item, index) => {
            const wordingSpends = `${currencyCode.idr} ${CommonHelper.formatCurrency(
              item.total_sales,
            )}`;

            return (
              <GridRowTableOneColumn
                key={index}
                customElementInitial={
                  <div className="flex-column">
                    <label className="text-12 text-bold mb-4 wrapping-container first line wrapping-container-break">
                      {item.customer_name}
                    </label>
                    <label className="text-10 text-semi-bold text-cornflowerBlue">
                      {item.product_name}
                    </label>
                  </div>
                }
                customElementColumn={
                  <div className="text-center">
                    <label className="text-12 text-bold">{wordingSpends}</label>
                  </div>
                }
                columnLeft={7}
                columnRight={5}
              />
            );
          })
        )}
      </Grid>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  topSpenderList: params => getTopSpenderList(dispatch, params),
});

const mapStateToProps = ({ dashboardData }) => ({
  dashboardData,
});

CardTopSpender.propTypes = {
  dashboardData: PropTypes.object,
  parentFilter: PropTypes.object,
  topSpenderList: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(CardTopSpender);
