import React from 'react';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { compose } from 'redux';
// component
import { PrevNextStepperCount } from '../../../../../../components/Index';
import UserCheckField from '../../../../../../components/higher-order-components/UserCheckField';
import { AddUserDetail } from '../components/Index';
// api
import {
  getProductByServiceLocation,
  getApdProduct,
} from '../../../../../../services/api/MedicApi';
import { getCountryList, getCustomerList } from '../../../../../../services/api/MasterDataApi';
// style
import './StepBookingProductDetailsStyle.scss';

class StepBookingProductDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    const { parentForm } = this.props;
    let branchID = null;

    if (parentForm.step_booking_product_details) {
      branchID = parentForm.step_booking_product_details.selectedService.branch_id;
    }

    this.getData(branchID);
  }

  getCountry = async () => {
    const { allCountryList } = this.props;
    await allCountryList();
  };

  getProductByServiceLocation = async (category, branchID) => {
    const { callGetProductByServiceLocationApi } = this.props;
    const params = {
      branch_id: branchID,
    };

    await callGetProductByServiceLocationApi(category, params);
  };

  getApdProductApi = async () => {
    const { callGetApdProductApi } = this.props;
    await callGetApdProductApi();
  };

  getData = async branchID => {
    await this.getCountry();
    await this.getApdProductApi();
    await this.getProductByServiceLocation('covid-19', branchID);
  };

  handlePrevClick = async () => {
    const { onPrevClick } = this.props;
    await onPrevClick();
  };

  handleNextClick = async value => {
    const { onNextClick, onParameterUpdate } = this.props;
    await onParameterUpdate(value, 'step_booking_customer_details');
    await onNextClick();
  };

  render() {
    const {
      onButtonClickCancel,
      medicData: { productByService },
      masterData,
      parentForm,
      callGetCustomerList,
    } = this.props;

    return (
      <Grid container className="container-step-product-details">
        <AddUserDetail
          callGetCustomerListApi={callGetCustomerList}
          customersDetail={parentForm.step_booking_customer_details || []}
          masterData={masterData}
          onCancelHandler={onButtonClickCancel}
          onNextHandler={this.handleNextClick}
          onPrevHandler={this.handlePrevClick}
          products={productByService}
          userDetail={parentForm.step_booking_user_details || {}}
        />
      </Grid>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  allCountryList: () => getCountryList(dispatch),
  callGetProductByServiceLocationApi: (category, params) =>
    getProductByServiceLocation(dispatch, category, params),
  callGetApdProductApi: () => getApdProduct(dispatch),
  callGetCustomerList: body => getCustomerList(dispatch, body),
});

const mapStateToProps = ({ masterData, medicData }) => ({
  masterData,
  medicData,
});

StepBookingProductDetails.propTypes = {
  allCountryList: PropTypes.func,
  callGetApdProductApi: PropTypes.func,
  callGetCustomerList: PropTypes.func,
  callGetProductByServiceLocationApi: PropTypes.func,
  masterData: PropTypes.object,
  medicData: PropTypes.object,
  onButtonClickCancel: PropTypes.func,
  onNextClick: PropTypes.func,
  onParameterUpdate: PropTypes.func,
  onPrevClick: PropTypes.func,
  parentForm: PropTypes.object,
};

const shell = compose(connect(mapStateToProps, mapDispatchToProps));

const core = compose(UserCheckField, PrevNextStepperCount);

export default shell(core(StepBookingProductDetails));
