import { constants } from '../reducers/DashboardReducer';

export function setListSummary(param) {
  return { type: constants.setListSummary, payload: param };
}

export function getSummarySalesMoveRequest() {
  return { type: constants.getSummarySalesMoveRequest };
}
export function getSummarySalesMoveSuccess(response) {
  return { type: constants.getSummarySalesMoveSuccess, payload: response };
}
export function getSummarySalesMoveFail(response) {
  return { type: constants.getSummarySalesMoveFail, payload: response.data };
}

export function getSummaryRecurringMembershipRequest() {
  return { type: constants.getSummaryRecurringMembershipRequest };
}
export function getSummaryRecurringMembershipSuccess(response) {
  return { type: constants.getSummaryRecurringMembershipSuccess, payload: response };
}
export function getSummaryRecurringMembershipFail(response) {
  return { type: constants.getSummaryRecurringMembershipFail, payload: response.data };
}

export function getSummaryConductRequest() {
  return { type: constants.getSummaryConductRequest };
}
export function getSummaryConductSuccess(response) {
  return { type: constants.getSummaryConductSuccess, payload: response };
}
export function getSummaryConductFail(response) {
  return { type: constants.getSummaryConductFail, payload: response.data };
}

export function getSummaryExpiredMembershipRequest() {
  return { type: constants.getSummaryExpiredMembershipRequest };
}
export function getSummaryExpiredMembershipSuccess(response) {
  return { type: constants.getSummaryExpiredMembershipSuccess, payload: response };
}
export function getSummaryExpiredMembershipFail(response) {
  return { type: constants.getSummaryExpiredMembershipFail, payload: response.data };
}

export function getDailyReportStatisticRequest() {
  return { type: constants.getDailyReportStatisticRequest };
}
export function getDailyReportStatisticSuccess(response) {
  return {
    type: constants.getDailyReportStatisticSuccess,
    payload: response,
  };
}
export function getDailyReportStatisticFail(response) {
  return { type: constants.getDailyReportStatisticFail, payload: response.data };
}

export function getMembershipStatusRecurringListPaginationRequest() {
  return { type: constants.getMembershipStatusRecurringListPaginationRequest };
}
export function getMembershipStatusRecurringListPaginationSuccess(response) {
  return {
    type: constants.getMembershipStatusRecurringListPaginationSuccess,
    payload: response,
  };
}
export function getMembershipStatusRecurringListPaginationFail(response) {
  return { type: constants.getMembershipStatusRecurringListPaginationFail, payload: response.data };
}

export function getMembershipStatusRecurringPrintRequest() {
  return { type: constants.getMembershipStatusRecurringPrintRequest };
}
export function getMembershipStatusRecurringPrintSuccess(response) {
  return {
    type: constants.getMembershipStatusRecurringPrintSuccess,
    payload: response,
  };
}
export function getMembershipStatusRecurringPrintFail(response) {
  return { type: constants.getMembershipStatusRecurringPrintFail, payload: response.data };
}

export function getRecentCheckInListPaginationRequest() {
  return { type: constants.getRecentCheckInListPaginationRequest };
}
export function getRecentCheckInListPaginationSuccess(response) {
  return {
    type: constants.getRecentCheckInListPaginationSuccess,
    payload: response,
  };
}
export function getRecentCheckInListPaginationFail(response) {
  return { type: constants.getRecentCheckInListPaginationFail, payload: response.data };
}

export function getTopSpenderListRequest() {
  return { type: constants.getTopSpenderListRequest };
}
export function getTopSpenderListSuccess(response) {
  return {
    type: constants.getTopSpenderListSuccess,
    payload: response,
  };
}
export function getTopSpenderListFail(response) {
  return { type: constants.getTopSpenderListFail, payload: response.data };
}

export function getTopSellingMembershipListRequest() {
  return { type: constants.getTopSellingMembershipListRequest };
}
export function getTopSellingMembershipListSuccess(response) {
  return {
    type: constants.getTopSellingMembershipListSuccess,
    payload: response,
  };
}
export function getTopSellingMembershipListFail(response) {
  return { type: constants.getTopSellingMembershipListFail, payload: response.data };
}

export function getDemoGraphicBookListRequest() {
  return { type: constants.getDemoGraphicBookListRequest };
}
export function getDemoGraphicBookListSuccess(response) {
  return {
    type: constants.getDemoGraphicBookListSuccess,
    payload: response,
  };
}
export function getDemoGraphicBookListFail(response) {
  return { type: constants.getDemoGraphicBookListFail, payload: response.data };
}
