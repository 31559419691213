/* eslint-disable no-plusplus */
// import { CommonHelper } from './Index';
import * as html2canvas from '@trainiac/html2canvas';
import JsBarcode from 'jsbarcode';

const GeneratorHelper = {
  textToBase64Barcode(text) {
    const canvas = document.createElement('canvas');
    JsBarcode(canvas, text, { format: 'CODE128' });
    return canvas.toDataURL('image/png');
  },
  async htmlToCanvas(domId, option = {}, success) {
    const input = await document.getElementById(domId);

    const activeOption = {
      scale: 2,
      ...option,
    };

    await html2canvas(input, activeOption)
      .then(async canvas => {
        const result = canvas.toDataURL('image/png');

        await success(result);
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.log('error pdf :', error);
      });
  },
  async processArrayLoop(dataArray, success) {
    const data = [];
    dataArray.forEach(item => {
      GeneratorHelper.htmlToCanvas(item.id, {}, async response => {
        const result = await response;
        data.push(result);

        if (data.length === dataArray.length) {
          success(data);
        }
      });
    });
  },
  async MultipleIdHtmlToCanvas(dataArray = [], success) {
    const converted = await GeneratorHelper.processArrayLoop(dataArray, success);
    success(converted);
  },
  generateNumberTable(dataArray) {
    dataArray.forEach((item, index) => {
      item.no = index + 1;
    });

    return dataArray;
  },
};

export default GeneratorHelper;
