/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'antd';
import './PaginationListStyles.scss';

const PaginationList = props => {
  const {
    data,
    pagination,
    renderItem,
    onChange,
    onShowSizeChange,
    grid,
    itemLayout,
    loading,
  } = props;

  return (
    <List
      itemLayout={itemLayout}
      className="container-pagination-list"
      grid={grid}
      dataSource={data}
      loading={loading}
      pagination={{
        ...pagination,
        onChange: (page, pageSize) => {
          onChange(page);
        },
        onShowSizeChange: (current, size) => {
          onShowSizeChange(size);
        },
      }}
      renderItem={renderItem}
    />
  );
};

PaginationList.propTypes = {
  data: PropTypes.array.isRequired,
  grid: PropTypes.object,
  itemLayout: PropTypes.string,
  loading: PropTypes.bool,
  onChange: PropTypes.func,
  onShowSizeChange: PropTypes.func,
  pagination: PropTypes.object,
  renderItem: PropTypes.func.isRequired,
};

PaginationList.defaultProps = {
  pagination: {
    showSizeChanger: true,
    showTotal: (total, range) => `Showing ${range[0]}-${range[1]} From ${total}`,
    pageSizeOptions: ['10', '20', '30', '40'],
  },
};

export default PaginationList;
