import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Grid, Breadcrumbs, Link } from '@material-ui/core';
import _ from 'lodash';
// component
import {
  AuthenticationAccessPages,
  ButtonMain,
  LabelInput,
  ModalAlertAction,
  PrevStateValue,
  SnackBarSimple,
  TextAreaMain,
  TextInputNumber,
  UploadDocument,
  SkeletonDetailsV03,
  GeneratorPdf,
} from '../../../../components/Index';
import {
  PurchaseOrderItemDetails,
  PurchaseOrderAttachmentList,
  PurchaseOrderTermsList,
} from '../components/Index';
// style
import './PurchaseOrderDetailStyle.scss';
// helpers
import {
  CommonHelper,
  purchaseOrderStatusCode,
  PermissionModule,
  PermissionPage,
  permissionAccessPurchaseOrder,
  HttpStatusCode,
  PermissionAccess,
  PurchaseOrderActionCode,
} from '../../../../helpers/Index';
// api
import {
  getPurchaseOrderDetails,
  setProcessCreateInvoice,
  setProcessProformaInvoice,
  setProcessPurchaseOrderChangeStatus,
  setProcessSaveDocumentFiles,
  setProcessSaveItemQty,
  getPurchaseOrderPrint,
} from '../../../../services/api/OrdersApi';

const optionToash = {
  vertical: 'top',
  horizontal: 'right',
};

const currentDate = CommonHelper.currentDate('YYYY-MM-DD');
const currentDateInterval = CommonHelper.intervalDate(currentDate, 'YYYY-MM-DD', 1, 'day');

const initialValidation = {
  reason: { isError: '', errorMessage: '' },
};

const initialForm = {
  reason: '',
  qty: 0,
};

class PurchaseOrderDetail extends React.Component {
  constructor(props) {
    super(props);
    const {
      match: { params },
    } = this.props;

    props.checkUserAccessPermission(
      PermissionModule.Orders,
      PermissionPage.PurchaseOrder,
      PermissionAccess.View,
    );

    this.state = {
      isModalEditProduct: false,
      selectedPurchaseOrderItemId: null,
      purchaseOrderId: CommonHelper.decryptObject(params.param_object).purchaseOrderId,
      visibleButtonProformaInvoice: false,
      visibleButtonShipment: false,
      visibleButtonDelivered: false,
      visibleButtonUploadShipment: false,
      visibleButtonUploadDelivered: false,
      visibleButtonUploadInvoice: false,
      visibleButtonUploadInvoiceEvidence: false,
      visibleButtonUploadPaymentReceipt: false,
      visibleButtonFlagPaid: false,
      visibleButtonEditQty: false,
      isLoadingButton: false,
      disableButtonCreate: false,
      disableButtonFlagShipping: false,
      disableButtonFlagDelivered: false,
      disableButtonFlagPayment: false,
      document: '',
      form: CommonHelper.objectCloning(initialForm),
      validation: CommonHelper.objectCloning(initialValidation),
      dueDate: CommonHelper.objectCloning(currentDateInterval),
      toastInformation: {
        isOpen: false,
        message: '',
        snackbarType: 'warning',
      },
      modalDetailData: {
        title: 'Confirmation',
        body: 'Are you sure want to delete',
      },
      isUsingTermsOfPayment: false,
      buttonClickPrint: false,
      activeCanvasGeneratorPDF: '',
      termOfPaymentInvoice: {},
      topDueDate: [],
    };
  }

  componentDidMount() {
    this.getPurchaseOrderDetails();
  }

  componentWillUnmount() {}

  getPurchaseOrderDetails() {
    const { purchaseOrderDetails, checkUserAccessPermissionAction } = this.props;
    const { purchaseOrderId, document } = this.state;
    let modifyDisableButtonFlagDelivered = false;
    let modifyDisableButtonFlagShipping = false;
    let modifyDisableButtonCreate = false;
    let modifyDisableButtonFlagPaid = false;
    let modifyDisableButtonFlagPayment = false;
    let messageAlert = '';
    let hadAccess = '';

    this.setState(
      {
        isLoadingButton: true,
      },
      () => {
        purchaseOrderDetails(purchaseOrderId)
          .then(response => {
            const data = response.data;
            const modifyVisibleButton = checkUserAccessPermissionAction(
              PermissionModule.Orders,
              PermissionPage.PurchaseOrder,
              permissionAccessPurchaseOrder.CreateProformaInvoice,
            );

            const modifyVisibleButtonShipment = checkUserAccessPermissionAction(
              PermissionModule.Orders,
              PermissionPage.PurchaseOrder,
              permissionAccessPurchaseOrder.UpdateShipment,
            );

            const modifyVisibleButtonDelivered = checkUserAccessPermissionAction(
              PermissionModule.Orders,
              PermissionPage.PurchaseOrder,
              permissionAccessPurchaseOrder.UpdateDelivered,
            );

            const modifyVisibleButtonUploadShipment = checkUserAccessPermissionAction(
              PermissionModule.Orders,
              PermissionPage.PurchaseOrder,
              permissionAccessPurchaseOrder.UploadShippingEvidence,
            );

            const modifyVisibleButtonUploadDelivered = checkUserAccessPermissionAction(
              PermissionModule.Orders,
              PermissionPage.PurchaseOrder,
              permissionAccessPurchaseOrder.UploadDeliveredEvidence,
            );

            const modifyVisibleButtonUploadInvoice = checkUserAccessPermissionAction(
              PermissionModule.Orders,
              PermissionPage.PurchaseOrder,
              permissionAccessPurchaseOrder.UploadInvoice,
            );

            const modifyVisibleButtonCreateInvoice = checkUserAccessPermissionAction(
              PermissionModule.Orders,
              PermissionPage.PurchaseOrder,
              permissionAccessPurchaseOrder.CreateInvoice,
            );

            const modifyVisibleButtonUploadInvoiceEvidence = checkUserAccessPermissionAction(
              PermissionModule.Orders,
              PermissionPage.PurchaseOrder,
              permissionAccessPurchaseOrder.UploadInvoiceEvidence,
            );

            const modifyVisibleButtonFlagPayment = checkUserAccessPermissionAction(
              PermissionModule.Orders,
              PermissionPage.PurchaseOrder,
              permissionAccessPurchaseOrder.UpdatePaymentStatus,
            );

            const modifyVisibleButtonUploadPaymentReceipt = checkUserAccessPermissionAction(
              PermissionModule.Orders,
              PermissionPage.PurchaseOrder,
              permissionAccessPurchaseOrder.UploadPaymentReceipt,
            );

            const modifyVisibleButtonFlagPaid = checkUserAccessPermissionAction(
              PermissionModule.Orders,
              PermissionPage.PurchaseOrder,
              permissionAccessPurchaseOrder.UpdatePaidStatus,
            );

            const modifyVisibleButtonEditQty = checkUserAccessPermissionAction(
              PermissionModule.Orders,
              PermissionPage.PurchaseOrder,
              permissionAccessPurchaseOrder.UpdatePurchaseOrderQty,
            );

            switch (data.details.purchase_order_status) {
              case purchaseOrderStatusCode.ProcessingPO:
                modifyDisableButtonFlagShipping = !document;
                modifyDisableButtonFlagDelivered = true;

                hadAccess = modifyVisibleButtonUploadShipment
                  ? 'Please Upload The Shipping Evidence before made any Progress'
                  : '';

                messageAlert = !document ? hadAccess : '';
                break;

              case purchaseOrderStatusCode.OnShipmentPO:
                modifyDisableButtonFlagShipping = true;
                modifyDisableButtonFlagDelivered = !document;

                hadAccess = modifyVisibleButtonUploadDelivered
                  ? 'Please Upload The Receive Evidence before made any Progress'
                  : '';

                messageAlert = !document ? hadAccess : '';
                break;

              case purchaseOrderStatusCode.DeliveredPO:
                modifyDisableButtonCreate = true;

                break;
              case purchaseOrderStatusCode.InvoicingPO:
                modifyDisableButtonFlagPayment = true;

                break;

              case purchaseOrderStatusCode.AwaitingPaymentPO:
                modifyDisableButtonFlagPaid = true;

                break;

              default:
                break;
            }

            const isUsingTermsOfPayment = !_.isEmpty(data.terms);

            // Check if terms already paid all;
            const checkTermsPayment = data.terms.filter(term => term.attachment === null);
            if (_.isEmpty(checkTermsPayment)) {
              modifyDisableButtonFlagPaid = false;
            }

            this.setState(
              {
                isLoadingButton: false,
                visibleButtonProformaInvoice: modifyVisibleButton,
                disableButtonCreate: modifyDisableButtonCreate,
                disableButtonFlagDelivered: modifyDisableButtonFlagDelivered,
                disableButtonFlagShipping: modifyDisableButtonFlagShipping,
                disableButtonFlagPaid: modifyDisableButtonFlagPaid,
                disableButtonFlagPayment: modifyDisableButtonFlagPayment,
                visibleButtonShipment: modifyVisibleButtonShipment,
                visibleButtonDelivered: modifyVisibleButtonDelivered,
                visibleButtonUploadShipment: modifyVisibleButtonUploadShipment,
                visibleButtonUploadDelivered: modifyVisibleButtonUploadDelivered,
                visibleButtonCreateInvoice: modifyVisibleButtonCreateInvoice,
                visibleButtonFlagPayment: modifyVisibleButtonFlagPayment,
                visibleButtonFlagPaid: modifyVisibleButtonFlagPaid,
                visibleButtonUploadInvoice: modifyVisibleButtonUploadInvoice,
                visibleButtonUploadInvoiceEvidence: modifyVisibleButtonUploadInvoiceEvidence,
                visibleButtonUploadPaymentReceipt: modifyVisibleButtonUploadPaymentReceipt,
                visibleButtonEditQty: modifyVisibleButtonEditQty,
                isUsingTermsOfPayment,
              },
              () => {
                if (messageAlert) {
                  this.processMessage(messageAlert, 'warning');
                }
              },
            );
          })
          .catch(error => {
            this.setState({
              isLoadingButton: false,
            });
            const message = error.data;

            this.processMessage(message.messages, 'error');
          });
      },
    );
  }

  handleButtonClickCreateProformaInvoice = () => {
    const { processProformaInvoice } = this.props;
    const { purchaseOrderId } = this.state;

    this.setState({ isLoadingButton: true }, () => {
      processProformaInvoice(purchaseOrderId)
        .then(response => {
          this.processMessage(response.messages, response.status);
          this.setState({ isLoadingButton: false, disableButtonCreate: true }, () => {
            this.getPurchaseOrderDetails();
          });
        })
        .catch(error => {
          this.setState({ isLoadingButton: false });
          const message = error.data;
          this.processMessage(message.messages, 'error');
        });
    });
  };

  handleButtonClickCreateInvoice = () => {
    const { processCreateInvoice } = this.props;
    const { purchaseOrderId, topDueDate } = this.state;

    this.setState({ isLoadingButton: true }, () => {
      const params = {
        terms: topDueDate,
      };

      processCreateInvoice(purchaseOrderId, params)
        .then(response => {
          this.processMessage(response.messages, response.status);
          this.setState({ isLoadingButton: false, disableButtonCreate: true }, () => {
            this.getPurchaseOrderDetails();
          });
        })
        .catch(error => {
          this.setState({ isLoadingButton: false });
          const message = error.data;
          this.processMessage(message.messages, 'error');
        });
    });

    // const isDueDateValid = CommonHelper.checkValidDate(dueDate);
    // const isDueDateGreater = CommonHelper.compareDateAndTime(dueDate, currentDate, 'date');

    // if (isDueDateValid && isDueDateGreater) {
    //   this.setState({ isLoadingButton: true }, () => {
    //     const params = {
    //       due_date: dueDate,
    //     };

    //     processCreateInvoice(purchaseOrderId, params)
    //       .then(response => {
    //         this.processMessage(response.messages, response.status);
    //         this.setState({ isLoadingButton: false, disableButtonCreate: true }, () => {
    //           this.getPurchaseOrderDetails();
    //         });
    //       })
    //       .catch(error => {
    //         this.setState({ isLoadingButton: false });
    //         const message = error.data;
    //         this.processMessage(message.messages, 'error');
    //       });
    //   });
    // } else {
    //   this.processMessage('Due Date is Not Valid', 'warning');
    // }
  };

  handleButtonClickCreateStatusChange = status => {
    const { processPurchaseOrderChangeStatus } = this.props;
    const { purchaseOrderId, document } = this.state;
    let modifyKey = '';

    switch (status) {
      case purchaseOrderStatusCode.ProcessingPO:
        modifyKey = 'mark-as-onshipment';
        break;
      case purchaseOrderStatusCode.OnShipmentPO:
        modifyKey = 'mark-as-delivered';
        break;
      case purchaseOrderStatusCode.InvoicingPO:
        modifyKey = 'mark-as-waiting-payment';
        break;
      case purchaseOrderStatusCode.AwaitingPaymentPO:
        modifyKey = 'mark-as-paid';
        break;

      default:
        break;
    }

    if (modifyKey) {
      this.setState({ isLoadingButton: true }, () => {
        processPurchaseOrderChangeStatus(purchaseOrderId, modifyKey)
          .then(response => {
            const data = response;
            let modifyDocument = document;
            if (data.code === HttpStatusCode.Success) {
              this.processMessage(data.messages, data.status);
              modifyDocument = '';
              setTimeout(() => {
                this.setState({ isLoadingButton: false, document: modifyDocument }, () => {
                  this.getPurchaseOrderDetails();
                });
              }, 2000);
            } else {
              this.setState({ isLoadingButton: false });
              this.processMessage(data.messages, 'error');
            }
          })
          .catch(error => {
            this.setState({ isLoadingButton: false });
            const message = error.data;
            this.processMessage(message.messages, 'error');
          });
      });
    }
  };

  handleChangeFile = fileBase64 => {
    this.setState({
      document: (fileBase64 && fileBase64[0] && fileBase64[0].base64file) || '',
    });
  };

  handleButtonClickSaveUploadDocument = () => {
    const {
      processSaveDocumentFiles,
      orderData: { detailPO },
    } = this.props;
    const { purchaseOrderId, document, dueDate, isUsingTermsOfPayment } = this.state;
    const params = {
      attachment_type: '',
      attachment: document,
    };
    let modifyDisableButtonFlagDelivered = true;
    let modifyDisableButtonFlagShipping = true;
    let modifyDisableButtonCreate = true;
    let modifyDisableButtonFlagPaid = true;
    let modifyDisableButtonFlagPayment = true;
    const isDueDateValid = CommonHelper.checkValidDate(dueDate);
    const isDueDateGreater = CommonHelper.compareDateAndTime(dueDate, currentDate, 'date');

    if (document) {
      switch (detailPO.details.purchase_order_status) {
        case purchaseOrderStatusCode.ProcessingPO:
          params.attachment_type = 'delivery_slip';
          modifyDisableButtonFlagShipping = false;
          break;
        case purchaseOrderStatusCode.OnShipmentPO:
          params.attachment_type = 'delivery_approval';
          modifyDisableButtonFlagDelivered = false;
          break;
        case purchaseOrderStatusCode.DeliveredPO:
          params.attachment_type = 'invoice';
          modifyDisableButtonCreate = !(isDueDateValid && isDueDateGreater);
          break;
        case purchaseOrderStatusCode.InvoicingPO:
          params.attachment_type = 'invoice_received_approval';
          modifyDisableButtonFlagPayment = false;
          break;
        case purchaseOrderStatusCode.AwaitingPaymentPO:
          params.attachment_type = 'payment_receipt';
          modifyDisableButtonFlagPaid = false;
          break;

        default:
          break;
      }

      this.setState({ isLoadingButton: true }, () => {
        processSaveDocumentFiles(purchaseOrderId, params)
          .then(response => {
            this.processMessage(response.messages, response.status);
            this.setState({
              isLoadingButton: false,
              disableButtonFlagShipping: modifyDisableButtonFlagShipping,
              disableButtonFlagDelivered: modifyDisableButtonFlagDelivered,
              disableButtonCreate: modifyDisableButtonCreate,
              disableButtonFlagPayment: modifyDisableButtonFlagPayment,
              disableButtonFlagPaid: modifyDisableButtonFlagPaid,
            });
            if (
              isUsingTermsOfPayment &&
              detailPO.details.purchase_order_status === purchaseOrderStatusCode.AwaitingPaymentPO
            ) {
              setTimeout(() => {
                this.getPurchaseOrderDetails();
              }, 2000);
            }
          })
          .catch(error => {
            this.setState({ isLoadingButton: false });
            const message = error.data;
            this.processMessage(message.messages, 'error');
          });
      });
    } else {
      this.processMessage('Document Not Found', 'warning');
    }
  };

  handlePickerChangeDueDate = value => {
    const { document } = this.state;
    const dueDate = value || '';
    let modifyDisableButtonCreate = true;

    if (document) {
      modifyDisableButtonCreate = false;
    }

    this.setState({
      dueDate,
      disableButtonCreate: modifyDisableButtonCreate,
    });
  };

  handleClick = (event, value) => {
    const { history } = this.props;
    event.preventDefault();
    history.push(value);
  };

  handleButtonClickEdit = (productId, purchaseOrderItemId) => {
    const { modalDetailData, form } = this.state;
    const {
      orderData: { detailPO },
    } = this.props;
    const findItem = _.find(detailPO.items, ['purchase_order_item_id', purchaseOrderItemId]);

    if (!_.isEmpty(findItem)) {
      this.setState({
        selectedPurchaseOrderItemId: purchaseOrderItemId,
        isModalEditProduct: true,
        form: {
          ...form,
          reason: '',
          qty: findItem.quantity,
        },
        modalDetailData: {
          ...modalDetailData,
          body: `Are you sure want to Edit Qty of Product Id : ${productId} ?`,
        },
      });
    }
  };

  handleButtonNumberStepper = value => {
    const { form } = this.state;
    const convertedValue = parseInt(value, 10);
    const qty = convertedValue > 0 ? convertedValue : 1;

    this.setState({ form: { ...form, qty } });
  };

  handleTextChangeReason = value => {
    const { validation, form } = this.state;
    const message = CommonHelper.objectCloning(initialValidation.reason);
    message.isError = !value ? 'error' : '';
    message.errorMessage = !value ? 'Reason must not Empty' : '';

    this.setState({
      form: { ...form, reason: value },
      validation: { ...validation, reason: message },
    });
  };

  handleButtonSubmitEdit = () => {
    const { form, purchaseOrderId, selectedPurchaseOrderItemId } = this.state;
    const {
      orderData: { detailPO },
      processSaveItemQty,
    } = this.props;

    const findItem = _.find(detailPO.items, [
      'purchase_order_item_id',
      selectedPurchaseOrderItemId,
    ]);

    if (this.validationForm() && !_.isEmpty(findItem)) {
      const param = {
        items: [
          {
            purchase_order_item_id: findItem.purchase_order_item_id,
            product_id: findItem.product_id,
            special_price: findItem.price,
            qty: form.qty,
            notes: form.reason,
          },
        ],
      };

      this.setState(
        {
          isLoadingButton: true,
        },
        () => {
          processSaveItemQty(purchaseOrderId, param)
            .then(async response => {
              const data = response;
              await this.processMessage(data.messages, data.status);
              await this.handleCloseModal();
              await this.setState({
                isLoadingButton: false,
              });
              await setTimeout(() => {
                this.getPurchaseOrderDetails();
              }, 1200);
            })
            .catch(error => {
              const message = error.data;
              this.processMessage(message.messages, 'error');
            });
        },
      );
    }
  };

  handleCloseModal = () => {
    const { form } = this.state;
    this.setState({
      form: { ...form, reason: '', qty: 1 },
      isModalEditProduct: false,
    });
  };

  handleCloseToash = () => {
    const { toastInformation } = this.state;
    this.setState({ toastInformation: { ...toastInformation, isOpen: false } });
  };

  handlePrintTermOfPaymentInvoice = invoiceParams => {
    const { purchaseOrderId } = this.state;
    const { purchaseOrderPrint } = this.props;
    this.setState({ activeCanvasGeneratorPDF: 'canvas-purchaser-order-invoice' }, async () => {
      try {
        const paramPrint = {
          document_type: PurchaseOrderActionCode.printInvoice,
          payment_id: invoiceParams.payment_id,
        };
        const response = await purchaseOrderPrint(purchaseOrderId, paramPrint);
        this.setState({
          termOfPaymentInvoice: { ...response.data, ...invoiceParams },
          buttonClickPrint: true,
        });
        setTimeout(() => {
          this.setState({
            buttonClickPrint: false,
          });
        }, 1400);
      } catch (error) {
        this.processMessage('Error while print invoice', 'warning');
      }
    });
  };

  handleChangeTopDueDate = topDueDate => {
    this.setState({ topDueDate });
  };

  validationForm() {
    const { form, validation } = this.state;
    let passCheck = true;

    const paramValidation01 = CommonHelper.objectCloning(initialValidation.reason);

    paramValidation01.isError = !form.reason ? 'error' : '';
    paramValidation01.errorMessage = !form.reason ? 'Please enter Reason' : '';

    if (!form.reason) {
      passCheck = false;
    }

    this.setState({
      validation: {
        ...validation,
        reason: paramValidation01,
      },
    });

    return passCheck;
  }

  processMessage(messages, type) {
    const convertedMessage = CommonHelper.generateMessage(messages);

    const paramInformation = {
      isOpen: true,
      message: convertedMessage,
      snackbarType: type,
    };

    this.setState({
      toastInformation: paramInformation,
    });
  }

  renderTitle = value => {
    let renderValue = null;
    if (value === purchaseOrderStatusCode.newPO) {
      renderValue = 'Purchase Order';
    } else if (
      value === purchaseOrderStatusCode.ProcessingPO ||
      value === purchaseOrderStatusCode.OnShipmentPO
    ) {
      renderValue = 'Delivery';
    } else if (
      value === purchaseOrderStatusCode.DeliveredPO ||
      value === purchaseOrderStatusCode.InvoicingPO ||
      value === purchaseOrderStatusCode.AwaitingPaymentPO
    ) {
      renderValue = 'Invoice';
    } else if (value === purchaseOrderStatusCode.CompletedPO) {
      renderValue = 'Completed';
    } else if (value === purchaseOrderStatusCode.Canceled) {
      renderValue = 'Canceled';
    }
    return renderValue;
  };

  renderOrderSummary = () => {
    const { orderData } = this.props;
    const { visibleButtonEditQty } = this.state;
    const disableButton =
      orderData.detailPO.details.purchase_order_status === purchaseOrderStatusCode.OnShipmentPO &&
      visibleButtonEditQty;

    return (
      <PurchaseOrderItemDetails
        orderData={orderData.detailPO}
        onEditProduct={this.handleButtonClickEdit}
        disableItemEdit={!disableButton}
      />
    );
  };

  renderActivityList = () => {
    const { orderData } = this.props;

    return <PurchaseOrderAttachmentList orderData={orderData.detailPO} />;
  };

  renderTermsList = () => {
    const { orderData } = this.props;
    const { isUsingTermsOfPayment, buttonClickPrint } = this.state;

    if (isUsingTermsOfPayment) {
      return (
        <PurchaseOrderTermsList
          buttonClickPrint={buttonClickPrint}
          orderData={orderData.detailPO}
          onPrintHandler={this.handlePrintTermOfPaymentInvoice}
          onChangeDueDateHandler={this.handleChangeTopDueDate}
        />
      );
    }

    return null;
  };

  renderUploadButtonActionHeader() {
    const {
      isLoadingButton,
      visibleButtonUploadShipment,
      visibleButtonUploadDelivered,
      visibleButtonUploadInvoice,
      visibleButtonUploadInvoiceEvidence,
      visibleButtonUploadPaymentReceipt,
    } = this.state;
    const {
      orderData: { detailPO },
    } = this.props;

    let passDisplay = false;
    let wordingDocumentLabel = '';

    switch (detailPO.details.purchase_order_status) {
      case purchaseOrderStatusCode.ProcessingPO:
        wordingDocumentLabel = 'Delivery Slip';
        passDisplay = visibleButtonUploadShipment && visibleButtonUploadDelivered;
        break;
      case purchaseOrderStatusCode.OnShipmentPO:
        wordingDocumentLabel = 'Delivery Approval';
        passDisplay = visibleButtonUploadShipment && visibleButtonUploadDelivered;
        break;
      case purchaseOrderStatusCode.DeliveredPO:
        wordingDocumentLabel = 'Invoice';
        passDisplay =
          visibleButtonUploadInvoice &&
          visibleButtonUploadInvoiceEvidence &&
          visibleButtonUploadPaymentReceipt;
        break;
      case purchaseOrderStatusCode.InvoicingPO:
        wordingDocumentLabel = 'Invoice Approval';
        passDisplay =
          visibleButtonUploadInvoice &&
          visibleButtonUploadInvoiceEvidence &&
          visibleButtonUploadPaymentReceipt;
        break;
      case purchaseOrderStatusCode.AwaitingPaymentPO:
        wordingDocumentLabel = 'Payment Receipt';
        passDisplay =
          visibleButtonUploadInvoice &&
          visibleButtonUploadInvoiceEvidence &&
          visibleButtonUploadPaymentReceipt;
        break;

      default:
        break;
    }

    return (
      passDisplay && (
        <Grid item lg={12} md={12} className="container-upload-panel container-main-card p-24">
          <Grid container justify="space-between" spacing={3} alignItems="center">
            <Grid item lg={4} md={4}>
              <div className="flex-column">
                <LabelInput labelText={wordingDocumentLabel} isRequired />
                <UploadDocument
                  labelText="Upload Document (pdf/img/word)"
                  onFileChange={this.handleChangeFile}
                  fileType="pdf/img/doc"
                  size="md"
                />
              </div>
            </Grid>
            <Grid item xl={2} lg={3} md={3}>
              <ButtonMain
                type="primary"
                size="xl"
                labelText="Save"
                onClick={() => this.handleButtonClickSaveUploadDocument()}
                isLoading={isLoadingButton}
                disabled={isLoadingButton}
              />
            </Grid>
          </Grid>
        </Grid>
      )
    );
  }

  renderButtonActionHeader() {
    const {
      orderData: { detailPO },
    } = this.props;
    const {
      visibleButtonProformaInvoice,
      visibleButtonCreateInvoice,
      visibleButtonShipment,
      visibleButtonDelivered,
      visibleButtonFlagPayment,
      visibleButtonFlagPaid,
      isLoadingButton,
      disableButtonCreate,
      disableButtonFlagDelivered,
      disableButtonFlagShipping,
      disableButtonFlagPaid,
      disableButtonFlagPayment,
    } = this.state;
    let elementRender = null;

    switch (detailPO.details.purchase_order_status) {
      case purchaseOrderStatusCode.newPO:
        elementRender = visibleButtonProformaInvoice && (
          <Grid item xl={3} lg={4} md={4}>
            <ButtonMain
              type="primary"
              size="xl"
              labelText="Create Proforma Invoice"
              onClick={this.handleButtonClickCreateProformaInvoice}
              isLoading={isLoadingButton}
              disabled={disableButtonCreate}
            />
          </Grid>
        );
        break;
      case purchaseOrderStatusCode.ProcessingPO:
        elementRender = visibleButtonShipment && (
          <Grid item xl={2} lg={3} md={3}>
            <ButtonMain
              type="primary"
              size="xl"
              labelText="Mark As On Shipment"
              onClick={() =>
                this.handleButtonClickCreateStatusChange(detailPO.details.purchase_order_status)
              }
              isLoading={isLoadingButton}
              disabled={disableButtonFlagShipping}
            />
          </Grid>
        );
        break;
      case purchaseOrderStatusCode.OnShipmentPO:
        elementRender = visibleButtonDelivered && (
          <Grid item xl={2} lg={3} md={3}>
            <ButtonMain
              type="primary"
              size="xl"
              labelText="Mark As Delivered"
              onClick={() =>
                this.handleButtonClickCreateStatusChange(detailPO.details.purchase_order_status)
              }
              isLoading={isLoadingButton}
              disabled={disableButtonFlagDelivered}
            />
          </Grid>
        );
        break;
      case purchaseOrderStatusCode.DeliveredPO:
        elementRender = visibleButtonCreateInvoice && (
          <Grid item xl={2} lg={3} md={3}>
            <ButtonMain
              type="primary"
              size="xl"
              labelText="Create Invoice"
              onClick={this.handleButtonClickCreateInvoice}
              isLoading={isLoadingButton}
              disabled={disableButtonCreate}
            />
          </Grid>
        );
        break;

      case purchaseOrderStatusCode.InvoicingPO:
        elementRender = visibleButtonFlagPayment && (
          <Grid item xl={3} lg={4} md={4}>
            <ButtonMain
              type="primary"
              size="xl"
              labelText="Mark as Awaiting Payment"
              onClick={() =>
                this.handleButtonClickCreateStatusChange(detailPO.details.purchase_order_status)
              }
              isLoading={isLoadingButton}
              disabled={disableButtonFlagPayment}
            />
          </Grid>
        );
        break;
      case purchaseOrderStatusCode.AwaitingPaymentPO:
        elementRender = visibleButtonFlagPaid && (
          <Grid item xl={3} lg={4} md={4}>
            <ButtonMain
              type="primary"
              size="xl"
              labelText="Mark as Paid"
              onClick={() =>
                this.handleButtonClickCreateStatusChange(detailPO.details.purchase_order_status)
              }
              isLoading={isLoadingButton}
              disabled={disableButtonFlagPaid}
            />
          </Grid>
        );
        break;

      default:
        break;
    }

    return elementRender;
  }

  renderDetailsPurchaseOrder() {
    const {
      orderData: { detailPO },
    } = this.props;

    const styleStatusPO =
      detailPO.details.purchase_order_status !== purchaseOrderStatusCode.CompletedPO
        ? 'process'
        : 'completed';

    return (
      <div className="container-main-card p-13 container-po-details-card flex-column">
        <Grid
          container
          justify="space-between"
          className={`flex-wrap-unset header ${styleStatusPO} p-40`}
          spacing={3}
        >
          <Grid item lg md className="container-main-title">
            <label className="text-16 text-white text-bold">
              {this.renderTitle(detailPO.details.purchase_order_status)}
            </label>
          </Grid>
          <Grid item lg={8} md={8}>
            <Grid container justify="flex-end" spacing={2}>
              <Grid item>
                <div className="flex-column">
                  <label className="text-12 text-rolling-stone text-semi-bold mb-4" color="inherit">
                    Invoice Number
                  </label>
                  <label className="text-12 text-white">{detailPO.details.invoice_no}</label>
                </div>
              </Grid>
              <Grid item>
                <div className="divider-hr-vertical" />
              </Grid>
              <Grid item>
                <div className="flex-column">
                  <label className="text-12 text-rolling-stone text-semi-bold mb-4">
                    Created by
                  </label>
                  <label className="text-12 text-white">{detailPO.details.created_by}</label>
                </div>
              </Grid>
              <Grid item>
                <div className="divider-hr-vertical" />
              </Grid>
              <Grid item>
                <div className="flex-column">
                  <label className="text-12 text-rolling-stone text-semi-bold mb-4">
                    Created Date
                  </label>
                  <label className="text-12 text-white">
                    {CommonHelper.dateTimeParseNewFormat(
                      detailPO.details.created_at,
                      'DD MMM YYYY | HH:MM',
                    ) || '-'}
                  </label>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          justify="space-between"
          alignItems="center"
          className="flex-wrap-unset details p-40"
          spacing={3}
        >
          <Grid item lg={6} md={6} className="container-client-address ph-20 pv-24">
            <Grid container direction="column">
              <Grid item lg md className="mb-4">
                <div className="flex-column">
                  <label className="text-12 text-rolling-stone text-semi-bold mb-4">Name</label>
                  <label className="text-16 main-title wrapping-container">
                    {detailPO.details.company_name}
                  </label>
                </div>
              </Grid>
              <Grid item lg md>
                <div className="flex-column pt-20">
                  <label className="text-12 text-rolling-stone text-semi-bold mb-4">
                    Shipping Address
                  </label>
                  <div className="text-14 mb-16">{detailPO.shipping_details.shipping_address}</div>
                  <label className="text-12 text-rolling-stone text-semi-bold mb-4">Note</label>
                  <div className="text-14">{detailPO.shipping_details.shipping_notes}</div>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={3} md={3}>
            <Grid container direction="column">
              <Grid item lg md>
                <div className="flex-column">
                  <label className="text-12 text-rolling-stone text-semi-bold mb-4">
                    Delivery Number
                  </label>
                  <label className="text-14 wrapping-container">
                    {detailPO.shipping_details.shipping_no}
                  </label>
                </div>
              </Grid>
              <Grid item lg md>
                <div className="flex-column pt-32">
                  <label className="text-12 text-rolling-stone text-semi-bold mb-4">
                    Delivery Date
                  </label>
                  <label className="text-14 wrapping-container">
                    {CommonHelper.dateTimeParseNewFormat(
                      detailPO.shipping_details.shipping_date,
                      'DD MMM YYYY',
                    ) || '-'}
                  </label>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={3} md={3}>
            <Grid container direction="column">
              <Grid item lg md>
                <div className="flex-column">
                  <label className="text-12 text-rolling-stone text-semi-bold mb-4">
                    Purchase Order Number
                  </label>
                  <label className="text-14 wrapping-container">
                    {detailPO.details.purchase_order_no}
                  </label>
                </div>
              </Grid>
              <Grid item lg md>
                <div className="flex-column pt-32">
                  <label className="text-12 text-rolling-stone text-semi-bold mb-4">Due Date</label>
                  <label className="text-14">
                    {CommonHelper.dateTimeParseNewFormat(
                      detailPO.details.due_date,
                      'DD MMM YYYY',
                    ) || '-'}
                  </label>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {this.renderOrderSummary()}
      </div>
    );
  }

  render() {
    const {
      orderData: { detailPO, fetching },
    } = this.props;
    const {
      toastInformation,
      isModalEditProduct,
      modalDetailData,
      form,
      validation,
      isLoadingButton,
      activeCanvasGeneratorPDF,
      termOfPaymentInvoice,
      buttonClickPrint,
    } = this.state;
    const prevUrl = '/order/purchase-order';

    let renderElement = <SkeletonDetailsV03 />;

    if (!fetching && !_.isEmpty(detailPO)) {
      renderElement = (
        <div>
          <Helmet title="FITCO | Orders - Purchase Order Detail" />
          <div className="container-page-po-detail scroll-container-invisible">
            <div className="container-page-scrolling-area">
              <Grid container direction="column" className="flex-wrap-unset">
                <Grid item lg md className="section-page-header">
                  <Grid
                    container
                    justify="space-between"
                    alignItems="center"
                    spacing={3}
                    className="p-13"
                  >
                    <Grid item lg md>
                      <div className="breadcrumbs-section">
                        <Breadcrumbs aria-label="breadcrumb">
                          <Link
                            className="text-12"
                            color="inherit"
                            href={prevUrl}
                            onClick={event => {
                              this.handleClick(event, prevUrl);
                            }}
                          >
                            <i className="ic-ffi-orders container-icon-prefix size-16" />
                            Order
                          </Link>
                          <Link
                            className="text-12"
                            color="inherit"
                            href={prevUrl}
                            onClick={event => {
                              this.handleClick(event, prevUrl);
                            }}
                          >
                            Purchase Order
                          </Link>
                          <label className="text-12" color="inherit">
                            Details
                          </label>
                        </Breadcrumbs>
                      </div>
                    </Grid>
                    {this.renderButtonActionHeader()}
                    {this.renderUploadButtonActionHeader()}
                  </Grid>
                </Grid>
                <Grid item lg md className="section-page-body flex-column">
                  {this.renderDetailsPurchaseOrder()}
                  <Grid container spacing={3} className="p-13">
                    {this.renderTermsList()}
                  </Grid>
                  <Grid container spacing={3} className="p-13">
                    {this.renderActivityList()}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item lg md className="section-page-footer" />
            </div>
          </div>
          <SnackBarSimple
            open={toastInformation.isOpen}
            durationHide={2000}
            message={toastInformation.message}
            onClickClose={this.handleCloseToash}
            snackbarType={toastInformation.snackbarType}
            anchor={optionToash}
          />
          {isModalEditProduct && (
            <ModalAlertAction
              onButtonSubmit={this.handleButtonSubmitEdit}
              onCloseModal={this.handleCloseModal}
              modalDetail={modalDetailData}
              isLoading={isLoadingButton}
              modalType="danger"
              buttonSubmitText="Submit"
              openModal={isModalEditProduct}
              customElementProps={
                <div className="flex-column">
                  <Grid container alignItems="center" className="mb-8" spacing={2}>
                    <Grid item>
                      <label className="text-12 text-semi-bold">Qty :</label>
                    </Grid>
                    <Grid item lg={4} md={4}>
                      <TextInputNumber
                        placeHolderText={'Qty'}
                        currentValue={form.qty}
                        onChange={this.handleButtonNumberStepper}
                      />
                    </Grid>
                  </Grid>
                  <TextAreaMain
                    onChange={this.handleTextChangeReason}
                    placeholder="Reason"
                    rows={3}
                    maxLength={250}
                    currentValue={form.reason}
                    validateStatus={validation.reason.isError}
                    errorMessage={validation.reason.errorMessage}
                  />
                </div>
              }
            />
          )}
          {!_.isEmpty(termOfPaymentInvoice) && buttonClickPrint && (
            <GeneratorPdf
              isFuncOnly
              data={termOfPaymentInvoice}
              customLabel={activeCanvasGeneratorPDF}
            />
          )}
        </div>
      );
    }

    return renderElement;
  }
}

const mapDispatchToProps = dispatch => ({
  purchaseOrderDetails: id => getPurchaseOrderDetails(dispatch, id),
  processProformaInvoice: id => setProcessProformaInvoice(id),
  processPurchaseOrderChangeStatus: (id, key) => setProcessPurchaseOrderChangeStatus(id, key),
  processSaveDocumentFiles: (id, params) => setProcessSaveDocumentFiles(id, params),
  processCreateInvoice: (id, params) => setProcessCreateInvoice(id, params),
  processSaveItemQty: (id, params) => setProcessSaveItemQty(id, params),
  purchaseOrderPrint: (id, params) => getPurchaseOrderPrint(dispatch, id, params),
});

const mapStateToProps = ({ orderData }) => ({
  orderData,
});

PurchaseOrderDetail.propTypes = {
  checkUserAccessPermission: PropTypes.func,
  checkUserAccessPermissionAction: PropTypes.func,
  history: PropTypes.object,
  match: PropTypes.object,
  orderData: PropTypes.object,
  processCreateInvoice: PropTypes.func,
  processProformaInvoice: PropTypes.func,
  processPurchaseOrderChangeStatus: PropTypes.func,
  processSaveDocumentFiles: PropTypes.func,
  processSaveItemQty: PropTypes.func,
  purchaseOrderDetails: PropTypes.func,
  purchaseOrderPrint: PropTypes.func,
};

const shell = compose(connect(mapStateToProps, mapDispatchToProps));
const core = compose(AuthenticationAccessPages, PrevStateValue);

export default shell(core(PurchaseOrderDetail));
