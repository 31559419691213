import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
// Style
import './ButtonSaveFooterStyle.scss';
// Component
import { ButtonMain } from '../../../../../../components/Index';

const ButtonSaveFooter = props => {
  const { onButtonClickCancel, onButtonClickSave } = props;

  return (
    <Grid container className="container-card-button-action">
      <Grid item lg md>
        <Grid container justify="flex-end" alignItems="center">
          <Grid item>
            <div className="container-button-action">
              <div className="container-button">
                <ButtonMain
                  type="ghost"
                  size="xl"
                  labelText="Cancel"
                  onClick={onButtonClickCancel}
                />
              </div>
              <div className="container-button">
                <ButtonMain type="primary" size="xl" labelText="Save" onClick={onButtonClickSave} />
              </div>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

ButtonSaveFooter.propTypes = {
  onButtonClickCancel: PropTypes.func,
  onButtonClickSave: PropTypes.func,
};

export default ButtonSaveFooter;
