/* eslint-disable react/no-access-state-in-setstate */
import React from 'react';
import { connect } from 'react-redux';
import { Grid, Fade } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import _ from 'lodash';
import { QRCode } from 'react-qrcode-logo';
import { compose } from 'redux';
// style
import './BranchPageStyle.scss';
// component
import {
  TextInput,
  TextAreaMain,
  SelectInputMerchant,
  ButtonIconMain,
  SnackBarSimple,
  ModalAlertAction,
  ModalAlertInformation,
  LabelStatusMain,
  ButtonMain,
  PrevStateValue,
  SkeletonPagination
} from '../../../../../components/Index';
// api
import {
  getListBranchPagination,
  setDeleteBranch,
} from '../../../../../services/api/MasterDataMainAPi';
// helper
import { CommonHelper, PermissionAccess, PermissionModule, PermissionPage, UserStatus } from '../../../../../helpers/Index';
// assets
import { Images } from '../../../../../assets/Index';

const initialFilter = {
  search: '',
  merchantId: null,
};

const initialValidation = {
  reason: { isError: '', errorMessage: '' },
};

const initialSort = {
  sortField: 'name',
  sortOrder: '',
};

const optionToash = {
  vertical: 'top',
  horizontal: 'right',
};

const modalAlertOptionInformation = {
  title: 'QR Code',
  text: '',
};

const timeInterval = 300;
const qrOption = {
  logoImage: Images.fitcoLogoRounded,
  logoWidth: 50,
  logoHeight: 50,
  size: 360,
  ecLevel: 'Q',
};

class BranchPage extends React.Component {
  constructor(props) {
    super(props);
    const {
      usersReducer: { prevStateValue },
    } = props;

    const updateExitingFilter = { ...CommonHelper.objectCloning(initialFilter), ...prevStateValue };

    this.state = {
      filter: updateExitingFilter,
      page: 1,
      limit: 10,
      sort: CommonHelper.objectCloning(initialSort),
      validation: CommonHelper.objectCloning(initialValidation),
      pagination: {
        showSizeChanger: true,
        showTotal: (total, range) => `Showing ${range[0]}-${range[1]} From ${total}`,
        pageSizeOptions: ['10', '20', '30', '40'],
      },
      selectedDataDelete: null,
      selectedId: null,
      selectedKey: null,
      qrValue: '',
      loading: true,
      isLoading: true,
      isOpenModalDelete: false,
      isOpenModalQr: false,
      lockoutReason: '',
      toastInformation: {
        isOpen: false,
        message: '',
        snackbarType: 'warning',
      },
      modalDetailDataDelete: {
        title: 'Confirmation',
        body: 'Are you sure want to Branch ',
      },
    };
    this.searchDebounce = _.debounce(this.searchDebounce, 400);
  }

  componentDidMount() {
    this.getListBranchPagination();
  }

  componentWillReceiveProps(nextProps) {
    const { usersReducer } = nextProps;
    const {
      onSetPrevStateValue,
      history: { location },
    } = this.props;

    if (_.isEmpty(usersReducer.prevStateValue)) {
      const newValue = {
        url: location.pathname,
      };

      onSetPrevStateValue(newValue);

      this.setState({
        filter: CommonHelper.objectCloning(initialFilter),
      });
    }
  }

  componentWillUnmount() {}

  getListBranchPagination = () => {
    const { listBranchPagination } = this.props;
    const {
      limit,
      pagination,
      page,
      filter: { search, merchantId },
      sort,
    } = this.state;

    const params = {
      search,
      merchant_id: merchantId,
      page,
      limit,
      sort: sort.sortField,
      sortOrder: sort.sortOrder,
    };

    this.setState({ loading: true }, () => {
      listBranchPagination(params).then(response => {
        this.setState({
          loading: false,
          isLoading: false,
          selectedId: null,
          pagination: { ...pagination, pageSize: limit, total: response.total },
        });
      });
    });
  };

  setDeleteBranch(param = {}) {
    const { deleteBranch } = this.props;

    deleteBranch(param)
      .then(async response => {
        const message = response.messages;

        this.handleCloseModalDelete();
        await this.getListBranchPagination();
        await this.processMessage(message, 'success');
      })
      .catch(error => {
        const message = error.data;
        this.processMessage(message.messages, 'error');
      });
  }

  handleChangeSearch = value => {
    const { filter } = this.state;

    this.setState(
      {
        filter: { ...filter, search: value },
        page: 1,
        pagination: { ...this.state.pagination, current: 1 },
      },
      () => {
        this.searchDebounce();
      },
    );
  };

  handleSelectMerchant = value => {
    const { filter } = this.state;
    const { onSetPrevStateValue } = this.props;
    const merchantId = value || null;

    onSetPrevStateValue({ ...filter, merchantId });
    this.setState(
      {
        filter: {
          ...filter,
          merchantId,
          page: 1,
          pagination: { ...this.state.pagination, current: 1 },
        },
      },
      () => {
        this.getListBranchPagination();
      },
    );
  };

  handleMouseEnter = value => {
    this.setState({ selectedId: value.branch_id });
  };

  handleMouseLeave = () => {
    this.setState({ selectedId: null });
  };

  handleCloseToash = () => {
    const { toastInformation } = this.state;
    this.setState({ toastInformation: { ...toastInformation, isOpen: false } });
  };

  handleCloseModalDelete = () => {
    const { validation } = this.state;
    const message = { isError: '', errorMessage: '' };

    this.setState({
      isOpenModalDelete: false,
      lockoutReason: '',
      validation: { ...validation, reason: message },
    });
  };

  handleCloseModalQr = () => {
    this.setState({ isOpenModalQr: false });
  };

  handleButtonQr = value => {
    this.setState({ qrValue: value, isOpenModalQr: true });
  };

  handleButtonEdit = value => {
    const { history } = this.props;
    history.push(`/master-data/main-data/branch/edit/${value}`);
  };

  handleClickRow = value => {
    const { history } = this.props;
    history.push(`/master-data/main-data/branch/details/${value}`);
  };

  handleButtonDeleted = data => {
    const { modalDetailDataDelete } = this.state;

    this.setState({
      isOpenModalDelete: true,
      selectedDataDelete: data.branch_id,
      selectedKey: data.key,
      modalDetailDataDelete: {
        ...modalDetailDataDelete,
        body: `Are you sure want to delete Branch ${data.branch_name} ? `,
      },
    });
  };

  handleButtonSubmitDelete = () => {
    const { lockoutReason, validation, selectedDataDelete, selectedKey } = this.state;

    if (!lockoutReason || lockoutReason === '') {
      const message = { isError: 'error', errorMessage: 'Reason Still Empty' };

      this.setState({
        validation: { ...validation, reason: message },
      });
    } else {
      const param = {
        data: {
          branch_id: selectedDataDelete,
          lockout_reason: lockoutReason,
          key: selectedKey,
        },
      };
      this.setDeleteBranch(param);
    }
  };

  handleTableChange = (pagination, filters, sorter) => {
    const { sort } = this.state;

    this.setState(
      {
        limit: pagination.pageSize,
        pagination: {
          ...this.state.pagination,
          current: pagination.current,
          pageSize: pagination.pageSize,
        },
        sort: {
          ...sort,
          sortField: sorter.field,
          sortOrder: sorter.order === 'ascend' ? 'asc' : 'desc',
        },
        page: pagination.current,
      },
      () => {
        this.getListBranchPagination();
      },
    );
  };

  handleTextChangeReason = value => {
    const { validation } = this.state;
    const message = {
      isError: '',
      errorMessage: '',
    };

    this.setState({
      lockoutReason: value,
      validation: { ...validation, reason: message },
    });
  };

  searchDebounce = () => {
    const { filter } = this.state;
    const { onSetPrevStateValue } = this.props;

    onSetPrevStateValue({ ...filter });
    this.getListBranchPagination();
  };

  processMessage(messages, type) {
    const convertedMessage = CommonHelper.generateMessage(messages);

    const paramInformation = {
      isOpen: true,
      message: convertedMessage,
      snackbarType: type,
    };

    this.setState({
      toastInformation: paramInformation,
    });
  }

  renderFilter() {
    const {
      filter: { search, merchantId },
    } = this.state;

    return (
      <Grid container direction="row" justify="flex-start" className="container-filter">
        <Grid item lg={4} md={4} className="row-filter">
          <TextInput
            iconPrefix="ic-ffo-search"
            placeHolderText="Search"
            currentValue={search || ''}
            onChange={this.handleChangeSearch}
            size="md"
          />
        </Grid>
        <Grid item lg={3} md={3} className="row-filter">
          <div className="container-remove-margin">
            <SelectInputMerchant
              placeHolder="Select Merchant"
              onChange={this.handleSelectMerchant}
              currentValue={merchantId}
              filter={this.state.filter}
              includeAllData
            />
          </div>
        </Grid>
      </Grid>
    );
  }

  renderColumns = () => {
    const { selectedId } = this.state;

    return [
      {
        title: 'Branch Name',
        dataIndex: 'name',
        sorter: true,
        render: (text, row) => {
          return (
            <Grid
              container
              direction="column"
              className="container-row-branch flex-wrap-unset"
              onClick={() => {
                this.handleClickRow(row.branch_id);
              }}
            >
              <Grid item lg md className="container-info-header">
                <div className="flex-column">
                  <span className="flex-row">
                    <label className="text-14 title wrapping-container">{row.branch_name}</label>
                    {this.renderStatus(row.status)}
                  </span>
                  <label className="text-10">ID : {row.branch_id}</label>
                </div>
              </Grid>
              <Grid item lg md>
                <Grid container justify="flex-start" className="container-info-body">
                  <Grid item lg={3} md={2}>
                    <div className="flex-column">
                      <label className="text-12">Company</label>
                      <span className="flex-row">
                        <i className="ic-ffo-merchant container-icon-prefix size-16" />
                        <label className="text-12 wrapping-container">{row.company_name}</label>
                      </span>
                    </div>
                  </Grid>
                  <Grid item lg={3} md={2}>
                    <div className="flex-column">
                      <label className="text-12">Merchant</label>
                      <span className="flex-row">
                        <i className="ic-ffo-merchant container-icon-prefix size-16" />
                        <label className="text-12 wrapping-container">{row.merchant_name}</label>
                      </span>
                    </div>
                  </Grid>
                  <Grid item lg={6} md={3}>
                    <div className="flex-column">
                      <label className="text-12">Address</label>
                      <span className="flex-row">
                        <i className="ic-ffo-pin container-icon-prefix size-16" />
                        <label className="text-12 wrapping-container address">{row.address}</label>
                      </span>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          );
        },
      },
      {
        width: '20%',
        align: 'center',
        render: (text, row) => {
          const inSelect = row.branch_id === selectedId;

          return (
            <Fade in={inSelect} timeout={timeInterval}>
              <Grid container justify="center" alignItems="center">
                <Grid item lg={6} md={6}>
                  <ButtonMain
                    labelText="Show QR"
                    onClick={() => this.handleButtonQr(row.qr_code_url)}
                    type="ghost"
                    size="sm"
                  />
                </Grid>
                <Grid item>
                  <ButtonIconMain
                    icon="ic-ffo-edit"
                    type="secondary"
                    size="sm"
                    onClick={() => this.handleButtonEdit(row.branch_id)}
                    requiredPermission={`${PermissionModule.MasterData}.${PermissionPage.Branch}.${PermissionAccess.Update}`}
                  />
                </Grid>
                {row.status !== UserStatus.Deleted && (
                  <Grid item>
                    <ButtonIconMain
                      icon="ic-ffo-bin"
                      type="negative"
                      size="sm"
                      onClick={() => this.handleButtonDeleted(row)}
                      requiredPermission={`${PermissionModule.MasterData}.${PermissionPage.Branch}.${PermissionAccess.Delete}`}
                    />
                  </Grid>
                )}
              </Grid>
            </Fade>
          );
        },
      },
    ];
  };

  renderModalDelete() {
    const { isOpenModalDelete, isLoading, lockoutReason } = this.state;
    let elementRender = null;

    if (isOpenModalDelete) {
      elementRender = (
        <ModalAlertAction
          onButtonSubmit={this.handleButtonSubmitDelete}
          onCloseModal={this.handleCloseModalDelete}
          modalDetail={this.state.modalDetailDataDelete}
          modalType="danger"
          buttonSubmitText="Submit"
          openModal={isOpenModalDelete}
          isLoading={isLoading}
          customElementProps={
            <TextAreaMain
              onChange={this.handleTextChangeReason}
              placeholder="Reason"
              rows={3}
              currentValue={lockoutReason}
              validateStatus={this.state.validation.reason.isError}
              errorMessage={this.state.validation.reason.errorMessage}
            />
          }
        />
      );
    }

    return elementRender;
  }

  renderModalQR() {
    const { isOpenModalQr, qrValue } = this.state;
    let elementRender = null;

    if (isOpenModalQr) {
      elementRender = (
        <ModalAlertInformation
          optionModal={modalAlertOptionInformation}
          isOpen={isOpenModalQr}
          onCloseModal={this.handleCloseModalQr}
          typeModal="information"
          customElementProps={
            <div className="container-qr-canvas">
              <QRCode
                value={qrValue}
                logoImage={qrOption.logoImage}
                logoWidth={qrOption.logoWidth}
                logoHeight={qrOption.logoHeight}
                size={qrOption.size}
                ecLevel={qrOption.ecLevel}
              />
            </div>
          }
        />
      );
    }

    return elementRender;
  }

  renderStatus = value => {
    let renderElement = <LabelStatusMain value="Active" type="complete" />;
    if (value === UserStatus.Deleted) {
      renderElement = <LabelStatusMain value="Deleted" type="danger" />;
    } else if (value === UserStatus.Inactive) {
      renderElement = <LabelStatusMain value="Inactive" type="default" />;
    }
    return <div className="container-status">{renderElement}</div>;
  };

  render() {
    const { masterDataMain } = this.props;
    const { isLoading, loading, pagination, toastInformation } = this.state;
    // eslint-disable-next-line no-unused-vars
    const adjustTableHeight = window.innerHeight - 350;
    const ListCustom = masterDataMain.list;

    let renderElement = (
      <div className="mt-18">
        <SkeletonPagination />
      </div>
    );

    if (!isLoading) {
      renderElement = (
        <Grid container direction="column">
          <Grid item lg md sm className="section-page-header">
            {this.renderFilter()}
          </Grid>
          <Grid item lg md className="section-table-body">
            <Table
              columns={this.renderColumns()}
              rowKey={record => record.branch_id}
              dataSource={ListCustom}
              pagination={pagination}
              loading={loading}
              // scroll={{ y: adjustTableHeight }}
              onChange={this.handleTableChange}
              onRow={record => {
                return {
                  onMouseEnter: () => {
                    this.handleMouseEnter(record);
                  }, // mouse enter row
                  onMouseLeave: () => {
                    this.handleMouseLeave();
                  },
                };
              }}
            />
          </Grid>
        </Grid>
      );
    }
    return (
      <div className="container-page-branch scroll-container">
        <div className="container-page-scrolling-area include-tab">
          {renderElement}
        </div>
        {this.renderModalDelete()}
        {this.renderModalQR()}
        <SnackBarSimple
          open={toastInformation.isOpen}
          durationHide={2000}
          message={toastInformation.message}
          onClickClose={this.handleCloseToash}
          snackbarType={toastInformation.snackbarType}
          anchor={optionToash}
        />
      </div>
      );
  }
}

const mapDispatchToProps = dispatch => ({
  listBranchPagination: params => getListBranchPagination(dispatch, params),
  deleteBranch: params => setDeleteBranch(dispatch, params),
});

const mapStateToProps = ({ masterDataMain }) => ({ masterDataMain });

BranchPage.propTypes = {
  deleteBranch: PropTypes.func,
  history: PropTypes.object,
  listBranchPagination: PropTypes.func,
  masterDataMain: PropTypes.object,
  onSetPrevStateValue: PropTypes.func,
  usersReducer: PropTypes.object,
};

const shell = compose(connect(mapStateToProps, mapDispatchToProps));

export default shell(PrevStateValue(BranchPage));
