/* eslint-disable react/no-access-state-in-setstate */
import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import _ from 'lodash';
import './DailyCheckInPageStyle.scss';
import * as Actions from '../../../../redux/actions/ReportAction';
// component
import {
  SnackBarSimple,
  PickerInputDate,
  LabelStatusMain,
  TextInput,
  ButtonIconMain,
  GeneratorExcel,
  ChartAreaGraphic,
  GridRowTableOneColumn,
  SelectSearchMultiple,
  AuthenticationAccessPages,
  SelectInputBranch,
} from '../../../../components/Index';
import {
  SkeletonChartConduct,
  SkeletonConduct,
  SkeletonSessions,
  SkeletonTopPT,
} from '../components/Index';
// Helper
import { CommonHelper, MasterDataHelper } from '../../../../helpers/Index';
// Action
import {
  getChartDailyCheckIn,
  getInfoDailyCheckIn,
  getTotalDailyCheckIn,
  getTopDailyCheckIn,
  getDailyCheckInReportPagination,
  getDailyCheckInReportExport,
} from '../../../../services/api/ReportApi';
// asset
import themes from '../../../../themes/Index';
import { compose } from 'redux';

const optionToash = {
  vertical: 'top',
  horizontal: 'right',
};

const initialSort = {
  sortField: 'client_name',
  sortOrder: '',
};

const currentDate = CommonHelper.currentDate('YYYY-MM-DD');
const startDateValue = CommonHelper.getStartDateMonth(currentDate);
const endDateValue = CommonHelper.getEndDateMonth(currentDate);

const initialFilter = {
  merchantId: null,
  branchId: null,
  checkInType: [],
  search: null,
  startDate: startDateValue,
  endDate: endDateValue,
  ranking: 6,
};

const categoryMoveOption = MasterDataHelper.categoryMoveOption;

class DailyCheckInPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pagination: {
        showSizeChanger: true,
        showTotal: (total, range) => `Showing ${range[0]}-${range[1]} from ${total}`,
        pageSizeOptions: ['10', '20', '30', '40'],
      },
      page: 1,
      limit: 10,
      sort: CommonHelper.objectCloning(initialSort),
      toastInformation: {
        isOpen: false,
        message: '',
        snackbarType: 'warning',
      },
      filter: CommonHelper.objectCloning(initialFilter),
    };
    this.searchDebounce = _.debounce(this.searchDebounce, 400);
  }

  componentDidMount() {}

  componentWillReceiveProps(nextProps) {
    const { filter } = this.state;
    const { usersReducer } = this.props;

    const isEqualActiveMerchantId = _.isEqual(
      usersReducer.activeMerchant,
      nextProps.usersReducer.activeMerchant,
    );

    if (!isEqualActiveMerchantId) {
      this.setState({
        filter: { ...filter, merchantId: nextProps.usersReducer.activeMerchant },
      });
    }
  }

  componentDidUpdate(prevProps, nextProps) {
    const {
      usersReducer,
      masterData,
      reportData: { fetching },
    } = this.props;

    const currentMerchant = usersReducer.activeMerchant;

    const ifMerchantChange = currentMerchant !== nextProps.filter.merchantId;
    const ifBranchEqual = _.isEqual(prevProps.masterData.branchList, masterData.branchList);

    if ((ifMerchantChange || !ifBranchEqual) && !fetching) {
      this.resetMerchantBranch(ifMerchantChange);
    }
  }

  componentWillUnmount() {
    this.props.setDefaultReportExport();
  }

  getAllData = () => {
    const { getChart, getDailyCheckIn, getTotalDaily, getTopDaily } = this.props;
    const {
      filter: { startDate, endDate, ranking, branchId, merchantId },
    } = this.state;

    const params = {
      start_date: startDate,
      end_date: endDate,
      merchant_id: merchantId,
      branch_id: branchId,
    };

    getChart(params);
    getDailyCheckIn(params);
    getTotalDaily(params);

    params.ranking = ranking;
    getTopDaily(params);

    this.getDailyCheckInReportPagination();
  };

  getDailyCheckInReportPagination() {
    const { dailyCheckInReportPagination } = this.props;
    const {
      filter: { search, startDate, endDate, merchantId, branchId, checkInType },
      sort: { sortField, sortOrder },
      limit,
      page,
      pagination,
    } = this.state;

    const param = {
      page,
      search,
      sort: sortField,
      sortOrder,
      start_date: startDate,
      end_date: endDate,
      merchant_id: merchantId,
      branch_id: branchId,
      check_in_type: checkInType,
      limit,
    };

    dailyCheckInReportPagination(param).then(response => {
      this.setState({
        pagination: { ...pagination, pageSize: limit, total: response.total },
      });
    });
  }

  handleChangeStartDate = value => {
    const { filter } = this.state;
    const { getAllData } = this;
    const endDate = CommonHelper.getEndDateMonth(value);

    this.setState(
      {
        filter: { ...filter, startDate: value, endDate },
        page: 1,
        pagination: { ...this.state.pagination, current: 1 },
      },
      () => {
        getAllData();
      },
    );
  };

  handleChangeEndDate = value => {
    const { filter } = this.state;
    const { getAllData } = this;

    this.setState(
      {
        filter: { ...filter, endDate: value },
        page: 1,
        pagination: { ...this.state.pagination, current: 1 },
      },
      () => {
        getAllData();
      },
    );
  };

  handleButtonCreate = () => {
    const { history } = this.props;
    history.push('/master-data/product-category/add');
  };

  handleCloseToash = () => {
    const { toastInformation } = this.state;
    this.setState({ toastInformation: { ...toastInformation, isOpen: false } });
  };

  handleChangeSearch = value => {
    const { filter } = this.state;

    this.setState(
      {
        filter: { ...filter, search: value },
        page: 1,
        pagination: { ...this.state.pagination, current: 1 },
      },
      () => {
        this.searchDebounce();
      },
    );
  };

  handleTableChange = (pagination, filters, sorter) => {
    const { sort } = this.state;

    this.setState(
      {
        limit: pagination.pageSize,
        pagination: {
          ...this.state.pagination,
          current: pagination.current,
          pageSize: pagination.pageSize,
        },
        sort: {
          ...sort,
          sortField: sorter.field || initialSort.sortField,
          sortOrder: sorter.order === 'ascend' || !sorter.order ? 'asc' : 'desc',
        },
        page: pagination.current,
      },
      () => {
        this.getDailyCheckInReportPagination();
      },
    );
  };

  searchDebounce = () => {
    this.getDailyCheckInReportPagination();
  };

  handleSelectChangeBranch = value => {
    const { filter } = this.state;

    this.setState(
      {
        filter: { ...filter, branchId: value },
        page: 1,
        pagination: { ...this.state.pagination, current: 1 },
      },
      () => {
        this.getAllData();
      },
    );
  };

  handleChangeCheckInType = value => {
    const { filter } = this.state;

    this.setState(
      {
        page: 1,
        filter: { ...filter, checkInType: value },
        pagination: { ...this.state.pagination, current: 1 },
      },
      () => {
        this.getDailyCheckInReportPagination();
      },
    );
  };

  handleButtonRefresh = () => {
    const { filter, sort } = this.state;
    const { masterData } = this.props;
    const resetBranch = masterData.branchList[0].branch_id;

    this.setState(
      {
        filter: {
          ...filter,
          branchId: resetBranch,
          startDate: initialFilter.startDate,
          endDate: initialFilter.endDate,
        },
        sort: { ...sort, sortField: initialSort.sortField, sortOrder: initialSort.sortOrder },
        pagination: { ...this.state.pagination, current: 1 },
        page: 1,
      },
      () => {
        this.getAllData();
      },
    );
  };

  handleButtonDownload = () => {
    const {
      filter: { merchantId, startDate, endDate, branchId, checkInType },
    } = this.state;
    const {
      getDailyCheckInExport,
      reportData: { fetchingExport },
    } = this.props;

    const params = {
      start_date: startDate,
      end_date: endDate,
      merchant_id: merchantId,
      branch_id: branchId,
      check_in_type: checkInType,
    };

    if (!fetchingExport) {
      getDailyCheckInExport(params);
    }
  };

  handleRowTableClick = value => {
    const { history } = this.props;
    const param = CommonHelper.encryptObject({ userId: value });

    history.push(`/management/users/details/${param}`);
  };

  resetMerchantBranch(resetBranch = false) {
    const { filter } = this.state;
    const {
      usersReducer,
      masterData,
      reportData: { fetching },
    } = this.props;

    const ifBranchListExist = !_.isEmpty(masterData.branchList)
      ? masterData.branchList[0].branch_id
      : null;

    this.setState(
      {
        filter: {
          ...filter,
          merchantId: usersReducer.activeMerchant,
          branchId: resetBranch ? null : ifBranchListExist,
        },
      },
      () => {
        if (!fetching && !masterData.fetching) {
          this.getAllData();
        }
      },
    );
  }

  processMessage(messages, type) {
    const convertedMessage = CommonHelper.generateMessage(messages);

    const paramInformation = {
      isOpen: true,
      message: convertedMessage,
      snackbarType: type,
    };

    this.setState({
      toastInformation: paramInformation,
    });
  }

  renderFilter = () => {
    const { filter } = this.state;
    const branchFilter = {
      merchantId: filter.merchantId,
    };

    return (
      <Grid container justify="flex-start" className="align-content-center">
        <Grid item lg={2} md={2}>
          <label className="text-14">Showing Statistic</label>
        </Grid>
        <Grid item lg={2} md={2} style={{ marginLeft: -30 }}>
          <PickerInputDate
            customIcon="ic-ffo-date-pick"
            dateFormat="dd/MM/yyyy"
            defaultValue={this.state.filter.startDate}
            onChange={this.handleChangeStartDate}
            toolbar={false}
          />
        </Grid>
        <label className="text-14 pr-8 pl-8"> - </label>
        <Grid item lg={2} md={2}>
          <PickerInputDate
            customIcon="ic-ffo-date-pick"
            dateFormat="dd/MM/yyyy"
            minDate={this.state.filter.startDate}
            defaultValue={this.state.filter.endDate}
            onChange={this.handleChangeEndDate}
            toolbar={false}
          />
        </Grid>
        <Grid item lg md>
          <Grid container justify="flex-end" alignItems="center">
            <Grid item lg={6} md={6}>
              <SelectInputBranch
                placeHolder="Select Branch"
                onChange={this.handleSelectChangeBranch}
                currentValue={this.state.filter.branchId}
                filter={branchFilter}
                includeAllData
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  renderCard = () => {
    const { reportData } = this.props;
    const arryLine = [
      {
        id: 'noshow',
        gradientColor: themes.palette.cinnabar,
        lineColor: themes.palette.cinnabar,
      },
      {
        id: 'completed',
        gradientColor: themes.palette.seagull,
        lineColor: themes.palette.seagull,
      },
      {
        id: 'upcoming',
        gradientColor: themes.palette.downy,
        lineColor: themes.palette.downy,
      },
    ];

    return (
      <Grid container justify="space-around" className="check-in-details-card">
        <Grid item lg={7} md={7} className="container-chat">
          <Grid container direction="column" className="flex-wrap-unset">
            <Grid item lg md className="pl-24">
              <label className="text-12 text-bold">Check-in Statistic</label>
            </Grid>
            <Grid item lg md>
              <Grid container spacing={2}>
                <Grid item lg={9} md={9}>
                  {reportData.fetchingChart ? (
                    <SkeletonChartConduct />
                  ) : (
                    <ChartAreaGraphic
                      dataArray={reportData.dataChart}
                      dataArrayLine={arryLine}
                      height={300}
                      tooltipCustom="tooltip-daily-check-in"
                    />
                  )}
                </Grid>
                <Grid item lg={3} md={3}>
                  <Grid container direction="column">
                    {reportData.fetchingDailyCheckIn ? (
                      <SkeletonConduct />
                    ) : (
                      <Grid item lg={12} className="check-in-statistic-days-card">
                        <label className="text-10 text-center text-opacity-50">Check-in/days</label>
                        <Grid container spacing={2} direction={'row'} className="pt-16 pl-8">
                          <Grid item lg={4} md={4} className="session-day-item">
                            <label className="text-14 text-bold">
                              {reportData.dailyCheckInDetails.average}
                            </label>
                            <label className="text-10">Average</label>
                          </Grid>
                          <Grid item lg={4} md={4} className="session-day-item">
                            <label className="text-14 text-bold">
                              {reportData.dailyCheckInDetails.max}
                            </label>
                            <label className="text-10">Max</label>
                          </Grid>
                          <Grid item lg={4} md={4} className="session-day-item">
                            <label className="text-14 text-bold">
                              {reportData.dailyCheckInDetails.min}
                            </label>
                            <label className="text-10">Min</label>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                    {reportData.fetchingSessions ? (
                      <SkeletonSessions />
                    ) : (
                      <Grid
                        container
                        direction={'column'}
                        spacing={3}
                        className="text-center pt-24"
                      >
                        <Grid item className="session-day-item">
                          <label className="text-14 text-bold">
                            {reportData.totalDailyCheckIn.total_completed} Sessions
                          </label>
                          <label className="text-10 pt-4">Total Completed</label>
                        </Grid>
                        <Grid item className="session-day-item">
                          <label className="text-14 text-bold">
                            {reportData.totalDailyCheckIn.total_noshow} Sessions
                          </label>
                          <label className="text-10 pt-4">Total No Show</label>
                        </Grid>
                        <Grid item className="session-day-item">
                          <label className="text-14 text-bold">
                            {reportData.totalDailyCheckIn.total_upcoming} Sessions
                          </label>
                          <label className="text-10 pt-4">Total Upcoming</label>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <div className="divider-hr-vertical" />
        </Grid>
        <Grid item lg={4} md={4} className="container-chat">
          <Grid container direction="column" className="pr-16 flex-wrap-unset">
            <Grid item>
              <label className="text-12 text-bold">Top Check-in</label>
            </Grid>
            <Grid item className="pl-16 pt-16">
              <Grid container>
                <Grid item xl={7} md={7} style={{ display: 'flex' }}>
                  <label className="text-10 text-opacity-50">Session Type</label>
                </Grid>
                <Grid item xl={5} md={5} className="content-center">
                  <label className="text-10 text-opacity-50">Total Check-in</label>
                </Grid>
              </Grid>
            </Grid>
            {reportData.fetchingTopConduct ? (
              <SkeletonTopPT />
            ) : (
              reportData.topDailyCheckIn.map((item, index) => {
                return (
                  <GridRowTableOneColumn
                    key={index}
                    customElementInitial={
                      <label className="text-12 text-bold">{item.session_type}</label>
                    }
                    customElementColumn={
                      <label className="text-12 text-bold">{item.total_checkin}</label>
                    }
                    columnLeft={7}
                    columnRight={5}
                  />
                );
              })
            )}
          </Grid>
        </Grid>
      </Grid>
    );
  };

  renderFilterTable() {
    const {
      filter: { startDate, endDate, checkInType },
    } = this.state;
    const {
      reportData: { reportExport, fetchingExport },
    } = this.props;

    return (
      <Grid container direction="row" justify="flex-start" className="container-filter">
        <Grid item lg={4} md={4} className="row-filter">
          <TextInput
            iconPrefix="ic-ffo-search"
            placeHolderText="Search"
            onChange={this.handleChangeSearch}
            size="md"
          />
        </Grid>
        <Grid item lg={3} md={3} className="row-filter">
          <div className="container-remove-margin">
            <SelectSearchMultiple
              options={categoryMoveOption}
              onChange={items => {
                this.handleChangeCheckInType(items);
              }}
              currentValue={checkInType}
              placeholder={'Select Check In Type'}
            />
          </div>
        </Grid>
        <Grid item lg md>
          <Grid container justify="flex-end">
            <Grid item>
              <ButtonIconMain
                icon="ic-ffo-refresh"
                type="ghost"
                size="xl"
                onClick={this.handleButtonRefresh}
              />
            </Grid>
            <Grid item>
              <GeneratorExcel
                buttonSize="xl"
                dataSetArray={reportExport}
                fileName={`Report_Daily_check_In_${startDate}_${endDate}`}
                iconPrefix="ic-ffo-download"
                isButtonIcon
                isLoading={fetchingExport}
                onClick={this.handleButtonDownload}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  renderStatus = value => {
    let renderElement = null;

    if (value) {
      renderElement = <LabelStatusMain value="Upcoming" type="projected" />;
    }

    return <div className="container-status">{renderElement}</div>;
  };

  renderColumns = () => {
    return [
      {
        title: 'Participant',
        dataIndex: 'client_name',
        sorter: true,
        render: (text, row) => {
          const completedDate =
            CommonHelper.dateTimeParseNewFormat(row.completed_date, 'DD MMM YYYY | HH:mm') || '-';

          return (
            <Grid
              container
              alignItems="center"
              justify="space-between"
              className="container-table-column-check-in left"
              onClick={() => {
                this.handleRowTableClick(row.user_id);
              }}
            >
              <Grid container direction="column" className="flex-wrap-unset">
                <Grid item lg={12} md={12} className="container-main">
                  <span className="flex-column">
                    <label className="text-14 title-main">{row.client_name || '-'}</label>
                    <label className="text-10">Booking ID :{row.appointment_id || '-'}</label>
                  </span>
                </Grid>
                <Grid item lg md>
                  <Grid container justify="flex-start">
                    <Grid item lg={3} md={3}>
                      <div className="flex-column">
                        <div className="flex-row">
                          <label className="text-12 title">Completed Date</label>
                          {this.renderStatus(row.is_upcoming)}
                        </div>
                        <label className="text-12">{completedDate}</label>
                      </div>
                    </Grid>
                    <Grid item lg={3} md={3}>
                      <div className="flex-column">
                        <label className="text-12 title">Session</label>
                        <label className="text-12">{row.product_name || '-'}</label>
                      </div>
                    </Grid>
                    <Grid item lg={2} md={3}>
                      <div className="flex-column">
                        <label className="text-12 title">Branch</label>
                        <label className="text-12">{row.branch_name || '-'}</label>
                      </div>
                    </Grid>
                    <Grid item lg={3} md={3}>
                      <div className="flex-column">
                        <label className="text-12 title">Membership</label>
                        <label className="text-12">{row.memberships || '-'}</label>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          );
        },
      },
      {
        title: 'Check In Type',
        dataIndex: 'check_in_type',
        sorter: true,
        width: '18%',
        align: 'center',
        render: (text, row) => {
          return (
            <Grid container className="container-table-column-check-in right">
              <Grid item lg md>
                <label className="text-14 title">{row.check_in_type || '-'}</label>
              </Grid>
            </Grid>
          );
        },
      },
    ];
  };

  render() {
    const { toastInformation, pagination } = this.state;
    const {
      reportData: { reportPagination, fetching },
    } = this.props;

    return (
      <div>
        <Helmet title="FITCO | Reports - Daily Check-In" />
        <div className="container-page-daily-check-in scroll-container">
          <div className="container-page-scrolling-area">
            <Grid container direction="column" className="flex-wrap-unset">
              <Grid item lg md className="section-page-header">
                {this.renderFilter()}
              </Grid>
              <Grid item lg md className="section-page-body">
                <Grid container direction="column" className="flex-wrap-unset">
                  <Grid item lg md className="mb-15">
                    {this.renderCard()}
                  </Grid>
                  <Grid item lg md className="mv-15">
                    {this.renderFilterTable()}
                  </Grid>
                  <Grid item lg md className="mv-15">
                    <Table
                      columns={this.renderColumns()}
                      rowKey={record => record.appointment_id}
                      dataSource={reportPagination}
                      pagination={pagination}
                      loading={fetching}
                      // scroll={{ y: adjustTableHeight }}
                      onChange={this.handleTableChange}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item lg md className="section-page-footer" />
            </Grid>
          </div>
        </div>
        <SnackBarSimple
          open={toastInformation.isOpen}
          durationHide={2000}
          message={toastInformation.message}
          onClickClose={this.handleCloseToash}
          snackbarType={toastInformation.snackbarType}
          anchor={optionToash}
        />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getChart: params => getChartDailyCheckIn(dispatch, params),
  getDailyCheckIn: params => getInfoDailyCheckIn(dispatch, params),
  getTotalDaily: params => getTotalDailyCheckIn(dispatch, params),
  getTopDaily: params => getTopDailyCheckIn(dispatch, params),
  dailyCheckInReportPagination: params => getDailyCheckInReportPagination(dispatch, params),
  getDailyCheckInExport: params => getDailyCheckInReportExport(dispatch, params),
  setDefaultReportExport: () => dispatch(Actions.setDefaultReportExport()),
});

const mapStateToProps = ({ reportData, usersReducer, masterData }) => ({
  reportData,
  usersReducer,
  masterData,
});

DailyCheckInPage.propTypes = {
  dailyCheckInReportPagination: PropTypes.func,
  filter: PropTypes.object,
  getChart: PropTypes.func,
  getDailyCheckIn: PropTypes.func,
  getDailyCheckInExport: PropTypes.func,
  getTopDaily: PropTypes.func,
  getTotalDaily: PropTypes.func,
  history: PropTypes.object,
  masterData: PropTypes.object,
  reportData: PropTypes.object,
  setDefaultReportExport: PropTypes.func,
  usersReducer: PropTypes.object,
};

const shell = compose(connect(mapStateToProps, mapDispatchToProps));

export default shell(AuthenticationAccessPages(DailyCheckInPage));
