import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Label } from 'recharts';
// Style
import './CardDemographicBookDetailsStyle.scss';
// component
import { ChartPieGraphic, GridRowTableOneColumn } from '../../../../components/Index';
import { DashboardHelper } from '../../../../helpers/Index';
// api
import { getDemoGraphicBookList } from '../../../../services/api/DashboardApi';
import { Skeleton } from '@material-ui/lab';
import { LabelTotalCustom } from '../../../../components/presentational/chart-pie-graphic/components/Index';

class CardDemographicBookDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: {},
      totalUser: 0,
      dataGraphic: [],
      dataRangeAge: [],
      limit: 6,
    };
  }

  componentDidUpdate(prevProps) {
    const { parentFilter } = this.props;

    const isEqual = _.isEqual(parentFilter, prevProps.parentFilter);

    if (parentFilter) {
      if (!isEqual) {
        this.setUpdateExistingFilter();
      }
    }
  }

  setUpdateExistingFilter() {
    const { parentFilter } = this.props;
    const { filter } = this.state;
    this.setState({ filter: { ...filter, ...parentFilter } }, () => {
      this.getDemoGraphicBookList();
    });
  }

  getDemoGraphicBookList() {
    const {
      filter: { startDate, endDate },
      limit,
    } = this.state;
    const {
      demoGraphicBookList,
      dashboardData: { fetchingDemoGraphicList },
    } = this.props;

    const params = {
      start_date: startDate,
      end_date: endDate,
      limit,
    };

    if (!fetchingDemoGraphicList && startDate && endDate) {
      demoGraphicBookList(params).then(response => {
        const data = response.data;
        let totalUser = 0;

        const convertedStatisticPie = DashboardHelper.generateDataStatisticPie(
          data.demographic_user_by_gender,
        );

        convertedStatisticPie.forEach(item => {
          totalUser += item.value;
        });

        this.setState({
          dataGraphic: convertedStatisticPie,
          dataRangeAge: data.demographic_user_by_age,
          totalUser,
        });
      });
    }
  }

  render() {
    const { dataGraphic, dataRangeAge, totalUser } = this.state;
    const {
      dashboardData: { fetchingDemoGraphicList },
    } = this.props;

    let elementRender = (
      <Skeleton
        variant="rect"
        width="100%"
        height={300}
        animation="wave"
        className="skeletonRounded"
      />
    );

    if (!fetchingDemoGraphicList) {
      elementRender = (
        <div className="flex-column">
          <Grid container spacing={2} alignItems="center" className="mb-8">
            <Grid item xl={5} lg={12} md={12}>
              <ChartPieGraphic
                dataArray={dataGraphic}
                height={150}
                innerRadius="80%"
                outerRadius="100%"
                labelPieCustomProps={
                  <Label
                    width={100}
                    position="center"
                    content={
                      <LabelTotalCustom valueP1={totalUser.toString()} valueP2={'Total User'} />
                    }
                  />
                }
              />
            </Grid>
            <Grid item lg md>
              <div className="flex-column">
                {dataGraphic.map((item, index) => {
                  return (
                    <Grid
                      key={index}
                      container
                      spacing={1}
                      justify="flex-start"
                      alignItems="center"
                    >
                      <Grid item>
                        <span className="dot" style={{ backgroundColor: item.color }} />
                      </Grid>
                      <Grid item lg={6} md={6}>
                        <label className="text-10 text-bold">{item.name}</label>
                      </Grid>
                      <Grid item lg md>
                        <Grid container justify="flex-end">
                          <label className="text-13 text-bold">{item.percent}%</label>
                        </Grid>
                      </Grid>
                    </Grid>
                  );
                })}
              </div>
            </Grid>
          </Grid>
          {dataRangeAge.map((item, index) => {
            return (
              <GridRowTableOneColumn
                key={index}
                customElementInitial={
                  <label className="text-12 text-semi-bold text-rolling-stone mb-4 ">
                    {item.name}
                  </label>
                }
                customElementColumn={
                  <div className="text-right">
                    <label className="text-12 text-bold">{item.value}</label>
                  </div>
                }
                columnLeft={7}
                columnRight={5}
              />
            );
          })}
        </div>
      );
    }

    return (
      <div className="container-card-demographic-book container-main-card ph-16 pt-24 pb-16 flex-column">
        <label className="text-14 text-semi-bold mb-13">Demographic</label>
        <div className="divider-hr" />
        {elementRender}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  demoGraphicBookList: params => getDemoGraphicBookList(dispatch, params),
});

const mapStateToProps = ({ dashboardData }) => ({
  dashboardData,
});

CardDemographicBookDetails.propTypes = {
  dashboardData: PropTypes.object,
  demoGraphicBookList: PropTypes.func,
  parentFilter: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(CardDemographicBookDetails);
