import React from 'react';
import PropTypes from 'prop-types';
import { Grid, IconButton } from '@material-ui/core';
import { connect } from 'react-redux';
// style
import './ModalUpdateOrderStyle.scss';
// component
import { ModalInformationPopUp, ButtonMain } from '../../../../../../components/Index';

const ModalUpdateOrder = ({ title, subtitle, onClose, isOpen, ctaStyle, handleCTA }) => {
  const renderElement = (
    <Grid container direction="column" className="flex-wrap-unset container-modal-eat-cancel-order">
      <Grid item lg md className="section-header-modal">
        <Grid container justify="space-between">
          <Grid item lg={11} md={11}>
            <h5 className="text-10 text-bold">{title}</h5>
          </Grid>
          <Grid item>
            <div className="container-button-close">
              <IconButton onClick={onClose}>
                <i className="ic-ffo-close container-icon-prefix size-21" />
              </IconButton>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg md className="section-body-modal">
        <p className="text-8">{subtitle}</p>
      </Grid>
      <Grid item lg md className="section-footer-modal">
        <Grid container direction="row" justify="flex-end" alignItems="center">
          <Grid item className="mr-8">
            <ButtonMain type="ghost" size="md" labelText="Back" onClick={onClose} />
          </Grid>
          <Grid item>
            <ButtonMain type={ctaStyle} size="md" labelText={title} onClick={handleCTA} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <ModalInformationPopUp
      isOpen={isOpen}
      onClose={onClose}
      customElementProps={renderElement}
      size="small"
    />
  );
};

const mapDispatchToProps = () => ({});

const mapStateToProps = () => ({});

ModalUpdateOrder.propTypes = {
  ctaStyle: PropTypes.string,
  handleCTA: PropTypes.func,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  subtitle: PropTypes.string,
  title: PropTypes.string,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalUpdateOrder);
