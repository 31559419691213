import React from 'react';
import PropTypes from 'prop-types';
import { Grid, IconButton, FormControl, FormLabel } from '@material-ui/core';
import { connect } from 'react-redux';
import _ from 'lodash';
// component
import {
  ModalInformationPopUp,
  ButtonMain,
  SnackBarSimple,
  SelectInputMain,
  SelectInputGeneral,
  PickerInputDate,
} from '../../../../../../../../components/Index';
// Api
import {
  getConsultationProductType,
  getProductByProductType,
  getCoachesByProduct,
  getBranchesByProduct,
} from '../../../../../../../../services/api/MasterDataApi';
import { getAvailableScheduleHourByDate } from '../../../../../../../../services/api/ScheduleDataApi';
// helper
import { CommonHelper, MasterDataHelper } from '../../../../../../../../helpers/Index';
// style
import './ModalAddProductStyle.scss';

const today = CommonHelper.currentDate('YYYY-MM-DD HH:mm:ss');
const currentDate = CommonHelper.dateTimeParseNewFormat(today, 'YYYY-MM-DD');
const optionRecurring = MasterDataHelper.radioButtonTrueFalse;

const initialFilter = {
  productType: null,
  productId: null,
  startDate: currentDate,
  recurring: 1,
  trainingScheduleId: null,
  trainerId: null,
  branchId: null,
};

const initialValidation = {
  productType: { isError: false, errorMessage: '' },
  productId: { isError: false, errorMessage: '' },
  trainingScheduleId: { isError: false, errorMessage: '' },
  trainerId: { isError: false, errorMessage: '' },
  branchId: { isError: false, errorMessage: '' },
};

const optionToash = {
  vertical: 'top',
  horizontal: 'right',
};

class ModalAddProduct extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: initialFilter,
      validation: initialValidation,
      toastInformation: {
        isOpen: false,
        message: '',
        snackbarType: 'warning',
      },
      selectedProduct: {},
      selectedCoachTherapis: {},
      selectedBranch: {},
      loading: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.isOpen !== prevProps.isOpen) {
      const { isOpen } = this.props;
      if (isOpen) {
        this.getConsultationProductTypeApi();
      }
      this.handleResetFilter();
    }
  }

  getConsultationProductTypeApi = async () => {
    const { callGetConsultationProductTypeApi } = this.props;
    await callGetConsultationProductTypeApi();
  };

  getProductByProductTypeApi = async productType => {
    const { callGetProductByProductTypeApi } = this.props;
    await callGetProductByProductTypeApi(productType);
  };

  getCoachesByProductApi = async productId => {
    const { callGetCoachesByProductApi } = this.props;
    await callGetCoachesByProductApi(productId);
  };

  getBranchesByProductApi = async productId => {
    const { callGetBranchesByProductApi } = this.props;
    await callGetBranchesByProductApi(productId);
  };

  getAvailableScheduleHourApi = async date => {
    const { filter } = this.state;
    const { callGetAvailableScheduleHourByDateApi } = this.props;
    const trainerId = filter.trainerId;
    const params = { date, product_id: filter.productId, branch_id: filter.branchId };
    await callGetAvailableScheduleHourByDateApi(trainerId, params);
  };

  handleResetFilter = () => {
    this.setState({
      filter: initialFilter,
      validation: initialValidation,
      selectedProduct: {},
      selectedCoachTherapis: {},
      selectedBranch: {},
      loading: false,
    });
  };

  handleClose = () => {
    const { onClose } = this.props;
    onClose(false);
  };

  handleCloseToash = () => {
    const { toastInformation } = this.state;
    this.setState({ toastInformation: { ...toastInformation, isOpen: false } });
  };

  handleChangeProductType = value => {
    const { filter } = this.state;

    this.setState(
      {
        selectedProduct: {},
        filter: { ...filter, productType: value, productId: null, trainerId: null },
      },
      () => {
        this.getProductByProductTypeApi(value);
      },
    );
  };

  handleChangeProduct = value => {
    const { filter } = this.state;
    const {
      masterData: { productByProductType },
    } = this.props;
    const findMatchData = _.find(productByProductType, ['product_id', value]);

    this.setState(
      {
        selectedProduct: findMatchData,
        filter: {
          ...filter,
          productId: value,
          trainerId: null,
          branchId: null,
          trainingScheduleId: null,
        },
      },
      () => {
        switch (filter.productType) {
          case 'workout_session':
            this.getCoachesByProductApi(value);
            this.getBranchesByProductApi(value);
            break;

          default:
            break;
        }
      },
    );
  };

  handleChangeDate = value => {
    const { filter } = this.state;

    this.setState(
      {
        filter: { ...filter, startDate: value, trainingScheduleId: null },
      },
      () => {
        switch (filter.productType) {
          case 'workout_session':
            this.getAvailableScheduleHourApi(value);
            break;

          default:
            break;
        }
      },
    );
  };

  handleChangeRecurringStatus = value => {
    const { filter } = this.state;

    this.setState({
      filter: { ...filter, recurring: value },
    });
  };

  handleChangeTrainer = value => {
    const { filter } = this.state;
    const {
      masterData: { coachesByProduct },
    } = this.props;
    const findMatchData = _.find(coachesByProduct, ['trainer_id', value]);

    this.setState({
      selectedCoachTherapis: findMatchData,
      filter: { ...filter, trainerId: value, branchId: null, trainingScheduleId: null },
    });
  };

  handleChangeBranch = value => {
    const { filter } = this.state;
    const {
      masterData: { branchesByProduct },
    } = this.props;
    const findMatchData = _.find(branchesByProduct, ['branch_id', value]);

    this.setState(
      {
        selectedBranch: findMatchData,
        filter: { ...filter, branchId: value, trainingScheduleId: null },
      },
      () => {
        switch (filter.productType) {
          case 'workout_session':
            this.getAvailableScheduleHourApi(filter.startDate);
            break;

          default:
            break;
        }
      },
    );
  };

  handleChangeTrainingSchedule = value => {
    const { filter } = this.state;

    this.setState({
      filter: { ...filter, trainingScheduleId: value },
    });
  };

  handleValidation = form => {
    const { filter } = this.state;
    let validationNewValue = CommonHelper.objectCloning(initialValidation);
    let excludeValidationKeys = [];

    if (filter.productType === 'point_booster') {
      excludeValidationKeys = ['trainingScheduleId', 'trainerId', 'branchId'];
    }

    const keys = Object.keys(form).filter(key => {
      return excludeValidationKeys.indexOf(key) === -1;
    });

    const errorValidationKeys = Object.keys(validationNewValue).filter(key => {
      return excludeValidationKeys.indexOf(key) === -1;
    });

    const errorKeys = keys.filter(key => {
      return form[key] === null || form[key] === '';
    });

    errorValidationKeys.forEach(key => {
      const isError = errorKeys.indexOf(key) !== -1;
      let errorDetail = {};
      switch (key) {
        case 'productType':
          errorDetail = { isError, errorMessage: isError ? 'Please select product type' : '' };
          break;
        case 'productId':
          errorDetail = { isError, errorMessage: isError ? 'Please select product' : '' };
          break;
        case 'trainerId':
          errorDetail = { isError, errorMessage: isError ? 'Please select coach / therapist' : '' };
          break;
        case 'branchId':
          errorDetail = { isError, errorMessage: isError ? 'Please select training location' : '' };
          break;
        case 'trainingScheduleId':
          errorDetail = { isError, errorMessage: isError ? 'Please select training time' : '' };
          break;
        default:
          errorDetail = { isError, errorMessage: isError ? 'Please fill input' : '' };
          break;
      }
      validationNewValue = {
        ...validationNewValue,
        [key]: errorDetail,
      };
    });

    this.setState({
      validation: { ...validationNewValue },
    });

    return { isError: errorKeys.length, validationNewValue };
  };

  handleAddProduct = () => {
    const { filter, selectedProduct, selectedCoachTherapis, selectedBranch } = this.state;
    const { onHandleAddProduct } = this.props;

    const { isError } = this.handleValidation(filter);
    if (isError) {
      return;
    }

    let params = {};

    switch (filter.productType) {
      case 'point_booster':
        params = {
          product: selectedProduct,
          productType: filter.productType,
          item: {
            product_id: filter.productId,
            qty: 1,
            details: {
              start_date: filter.startDate,
              recurring: Boolean(filter.recurring),
            },
          },
        };
        break;

      default:
        params = {
          product: selectedProduct,
          productType: filter.productType,
          item: {
            product_id: filter.productId,
            qty: 1,
            training_schedule_id: filter.trainingScheduleId,
            details: {
              trainer_id: filter.trainerId,
            },
          },
          trainer: selectedCoachTherapis,
          branch: selectedBranch,
          appointmentDate: filter.startDate,
        };
        break;
    }

    this.setState({ loading: true });

    setTimeout(() => {
      onHandleAddProduct(params);
    }, 1000);
  };

  processMessage(messages, type) {
    const convertedMessage = CommonHelper.generateMessage(messages);

    const paramInformation = {
      isOpen: true,
      message: convertedMessage,
      snackbarType: type,
    };

    this.setState({
      toastInformation: paramInformation,
    });
  }

  renderAdditionalInfo = filter => {
    const { selectedProduct, validation } = this.state;
    const {
      masterData: { coachesByProduct, branchesByProduct },
      availableHours,
    } = this.props;
    switch (filter.productType) {
      case 'point_booster':
        if (selectedProduct.product_id) {
          return (
            <>
              <Grid item lg md>
                <Grid container spacing={2}>
                  <Grid item lg md>
                    <FormControl component="fieldset" fullWidth margin="normal">
                      <FormLabel component="label" className="text-12 title mb-8">
                        Start Date
                      </FormLabel>
                      <PickerInputDate
                        customIcon="ic-ffo-date-pick"
                        dateFormat="dd/MM/yyyy"
                        minDate={currentDate}
                        onChange={this.handleChangeDate}
                        defaultValue={filter.startDate}
                        toolbar
                      />
                    </FormControl>
                  </Grid>
                  <Grid item lg md>
                    <FormControl component="fieldset" fullWidth margin="normal">
                      <FormLabel component="label" className="text-12 title mb-8">
                        Auto Recurring
                      </FormLabel>
                      <SelectInputMain
                        options={optionRecurring}
                        size="middle"
                        currentValue={filter.recurring}
                        onChange={this.handleChangeRecurringStatus}
                        placeholder="Select Auto Recurring"
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item lg md className="mt-8">
                <div className="custom-label label-warning">
                  <label className="text-12">
                    Membership will be active for{' '}
                    <span className="text-bold">
                      {selectedProduct.period} {selectedProduct.period_type}
                      {selectedProduct.period > 1 ? 's' : ''}
                    </span>{' '}
                    from start date.
                  </label>
                </div>
              </Grid>
            </>
          );
        }
        return null;
      default:
        if (selectedProduct.product_id) {
          return (
            <>
              <Grid item lg md>
                <FormControl component="fieldset" fullWidth margin="normal">
                  <FormLabel component="label" className="text-12 title mb-8">
                    Select Therapist
                  </FormLabel>
                  <div className="container-remove-margin">
                    <SelectInputGeneral
                      placeHolder="Select Therapist"
                      currentValue={filter.trainerId}
                      dataList={coachesByProduct}
                      primaryKey="trainer_id"
                      onChange={this.handleChangeTrainer}
                      errorMessage={validation.trainerId.errorMessage}
                      validateStatus={validation.trainerId.isError ? 'error' : ''}
                    />
                  </div>
                </FormControl>
              </Grid>
              <Grid item lg md>
                <FormControl component="fieldset" fullWidth margin="normal">
                  <FormLabel component="label" className="text-12 title mb-8">
                    Locations
                  </FormLabel>
                  <div className="container-remove-margin">
                    <SelectInputGeneral
                      placeHolder="Select Location"
                      currentValue={filter.branchId}
                      dataList={branchesByProduct}
                      primaryKey="branch_id"
                      onChange={this.handleChangeBranch}
                      errorMessage={validation.branchId.errorMessage}
                      validateStatus={validation.branchId.isError ? 'error' : ''}
                      disabled={!filter.trainerId}
                    />
                  </div>
                </FormControl>
              </Grid>
              <Grid item lg md>
                <Grid container spacing={2}>
                  <Grid item lg md>
                    <FormControl component="fieldset" fullWidth margin="normal">
                      <FormLabel component="label" className="text-12 title mb-8">
                        Appointment Date
                      </FormLabel>
                      <PickerInputDate
                        customIcon="ic-ffo-date-pick"
                        dateFormat="dd/MM/yyyy"
                        minDate={currentDate}
                        onChange={this.handleChangeDate}
                        defaultValue={filter.startDate}
                        toolbar
                        disabled={!filter.branchId}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item lg md>
                    <FormControl component="fieldset" fullWidth margin="normal">
                      <FormLabel component="label" className="text-12 title mb-8">
                        Time
                      </FormLabel>
                      <div className="container-remove-margin">
                        <SelectInputGeneral
                          placeHolder="Select Time"
                          currentValue={filter.trainingScheduleId}
                          dataList={availableHours}
                          primaryKey="training_schedule_id"
                          onChange={this.handleChangeTrainingSchedule}
                          isTrainingSchedule
                          availableSlotOnly
                          errorMessage={validation.trainingScheduleId.errorMessage}
                          validateStatus={validation.trainingScheduleId.isError ? 'error' : ''}
                          disabled={!filter.branchId}
                        />
                      </div>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </>
          );
        }
        return null;
    }
  };

  render() {
    const {
      isOpen,
      masterData: { consultationProductType, productByProductType },
    } = this.props;
    const { toastInformation, filter, validation, loading } = this.state;

    const renderElement = (
      <Grid container direction="column" className="flex-wrap-unset">
        <Grid item lg md className="section-header-modal">
          <Grid container>
            <Grid item lg={11} md={11}>
              <h5 className="wrapping-container second line">Additional Product</h5>
            </Grid>
            <Grid item>
              <Grid container direction="column" justify="flex-end">
                <Grid item>
                  <div className="container-button-close">
                    <IconButton onClick={this.handleClose}>
                      <i className="ic-ffo-close container-icon-prefix size-21" />
                    </IconButton>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg md className="section-body-modal">
          <Grid container direction="column">
            <Grid item lg md>
              <FormControl component="fieldset" fullWidth margin="normal">
                <FormLabel component="label" className="text-12 title mb-8">
                  Product Type
                </FormLabel>
                <div className="container-remove-margin">
                  <SelectInputGeneral
                    placeHolder="Select Product Type"
                    currentValue={filter.productType}
                    dataList={consultationProductType}
                    primaryKey="product_type"
                    valueKey="product_type_description"
                    onChange={this.handleChangeProductType}
                    errorMessage={validation.productType.errorMessage}
                    validateStatus={validation.productType.isError ? 'error' : ''}
                  />
                </div>
              </FormControl>
            </Grid>
            <Grid item lg md>
              <FormControl component="fieldset" fullWidth margin="normal">
                <FormLabel component="label" className="text-12 title mb-8">
                  Product Name
                </FormLabel>
                <div className="container-remove-margin">
                  <SelectInputGeneral
                    placeHolder="Select Product"
                    currentValue={filter.productId}
                    dataList={productByProductType}
                    primaryKey="product_id"
                    onChange={this.handleChangeProduct}
                    errorMessage={validation.productId.errorMessage}
                    validateStatus={validation.productId.isError ? 'error' : ''}
                  />
                </div>
              </FormControl>
            </Grid>
            {this.renderAdditionalInfo(filter)}
          </Grid>
        </Grid>
        <Grid item className="section-footer-modal mt-45">
          <Grid container justify="flex-end" spacing={2}>
            <Grid item lg={3} md={3}>
              <ButtonMain labelText="Cancel" onClick={this.handleClose} type="ghost" size="xl" />
            </Grid>
            <Grid item lg={3} md={3}>
              <ButtonMain
                labelText="Add"
                onClick={this.handleAddProduct}
                type="primary"
                size="xl"
                isLoading={loading}
              />
            </Grid>
          </Grid>
        </Grid>
        <SnackBarSimple
          open={toastInformation.isOpen}
          durationHide={2000}
          message={toastInformation.message}
          onClickClose={this.handleCloseToash}
          snackbarType={toastInformation.snackbarType}
          anchor={optionToash}
        />
      </Grid>
    );

    return (
      <ModalInformationPopUp
        isOpen={isOpen}
        onClose={this.handleClose}
        customElementProps={renderElement}
      />
    );
  }
}

const mapDispatchToProps = dispatch => ({
  callGetConsultationProductTypeApi: () => getConsultationProductType(dispatch),
  callGetProductByProductTypeApi: productType => getProductByProductType(dispatch, productType),
  callGetCoachesByProductApi: productId => getCoachesByProduct(dispatch, productId),
  callGetBranchesByProductApi: productId => getBranchesByProduct(dispatch, productId),
  callGetAvailableScheduleHourByDateApi: (trainerId, params) =>
    getAvailableScheduleHourByDate(dispatch, trainerId, params),
});

const mapStateToProps = ({ masterData, scheduleSummary }) => ({
  masterData,
  availableHours: scheduleSummary.availableHours,
});

ModalAddProduct.propTypes = {
  availableHours: PropTypes.array,
  callGetAvailableScheduleHourByDateApi: PropTypes.func,
  callGetBranchesByProductApi: PropTypes.func,
  callGetCoachesByProductApi: PropTypes.func,
  callGetConsultationProductTypeApi: PropTypes.func,
  callGetProductByProductTypeApi: PropTypes.func,
  isOpen: PropTypes.bool,
  masterData: PropTypes.object,
  onClose: PropTypes.func,
  onHandleAddProduct: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalAddProduct);
