import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import _ from 'lodash';
// Style
import './CartItemDetailRetailsStyle.scss';
// Component
import { GridRowTableFourColumn, GridRowTableOneColumn } from '../../../../../components/Index';
import { CommonHelper } from '../../../../../helpers/Index';
// helper

const CartItemDetailRetails = props => {
  const { orderData, adminFee } = props;

  const orderSummaryData = !_.isEmpty(orderData) ? orderData.order_summary : {};
  const orderPaymentDetails = !_.isEmpty(orderData) ? orderData.payment_details : {};
  const orderItems = orderSummaryData.product_details || [];
  const showAdminFee = !_.isEmpty(adminFee);

  return (
    <Grid container className="pt-24" direction="row" spacing={4}>
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        className="container-order-cart-item-details-retails pt-32"
      >
        <GridRowTableFourColumn
          customElementColumnFirst={
            <label className="text-14 wrapping-container third line wrapping-container-break">
              Product
            </label>
          }
          customElementColumnSecond={
            <div className="text-center">
              <label className="text-14">Price</label>
            </div>
          }
          customElementColumnThird={
            <div className="text-center">
              <label className="text-14">Quantity</label>
            </div>
          }
          customElementColumnFourth={
            <div className="text-right">
              <label className="text-14">Subtotal</label>
            </div>
          }
          columnFirst={5}
          columnSecond={2}
          columnThird={2}
          columnFourth={3}
        />
        <div className="scroll-container">
          <Grid container direction="column" className="flex-wrap-unset">
            {orderItems.map((item, index) => {
              const priceTotal = CommonHelper.formatCurrency(
                item.quantity * parseInt(item.item_price, 10),
              );
              const priceItem = CommonHelper.formatCurrency(item.item_price);

              return (
                <GridRowTableFourColumn
                  key={index}
                  customElementColumnFirst={
                    <label className="text-14 row-item-title wrapping-container third line wrapping-container-break">
                      {item.product_name}
                    </label>
                  }
                  customElementColumnSecond={
                    <div className="text-center">
                      <label className="text-14">IDR {priceItem}</label>
                    </div>
                  }
                  customElementColumnThird={
                    <div className="text-center">
                      <label className="text-14">{item.quantity}</label>
                    </div>
                  }
                  customElementColumnFourth={
                    <div className="text-right">
                      <label className="text-14">IDR {priceTotal}</label>
                    </div>
                  }
                  columnFirst={5}
                  columnSecond={2}
                  columnThird={2}
                  columnFourth={3}
                />
              );
            })}
          </Grid>
        </div>
        {orderSummaryData.shipping_title && (
          <GridRowTableOneColumn
            customElementInitial={
              <div className="flex-column">
                <label className="text-14 row-item-title">Shipping Fee</label>
                <label className="text-10">{orderSummaryData.shipping_title}</label>
              </div>
            }
            customElementColumn={
              <div className="text-right">
                <label className="text-14">
                  {orderSummaryData.shipping_fee > 0
                    ? `IDR ${CommonHelper.formatCurrency(orderSummaryData.shipping_fee)}`
                    : `Free`}
                </label>
              </div>
            }
            columnLeft={10}
            columnRight={2}
          />
        )}
        {orderSummaryData.price_discount !== 0 ? (
          <GridRowTableOneColumn
            customElementInitial={
              <div className="flex-column">
                <label className="text-14 row-item-title">Discount</label>
                <label className="text-10 ">Promo - {orderSummaryData.promo_code}</label>
              </div>
            }
            customElementColumn={
              <div className="text-right">
                <label className="text-14 discount">
                  IDR {CommonHelper.formatCurrency(orderSummaryData.price_discount)}
                </label>
              </div>
            }
            columnLeft={9}
            columnRight={3}
          />
        ) : null}
        {showAdminFee && (
          <GridRowTableOneColumn
            customElementInitial={
              <div className="flex-column">
                <label className="text-14 row-item-title">Admin Fee</label>
                <label className="text-10 ">{adminFee.name}</label>
              </div>
            }
            customElementColumn={
              <div className="text-right">
                <label className="text-14">IDR {CommonHelper.formatCurrency(adminFee.price)}</label>
              </div>
            }
            columnLeft={10}
            columnRight={2}
          />
        )}
        {orderPaymentDetails.promo_code && orderPaymentDetails.is_cashback ? (
          <GridRowTableOneColumn
            customElementInitial={
              <div className="flex-column">
                <label className="text-14 row-item-title">Cashback</label>
                <label className="text-10 ">Promo - {orderSummaryData.promo_code}</label>
              </div>
            }
            customElementColumn={
              <div className="text-right">
                <label className="text-14">
                  {CommonHelper.formatCurrency(orderPaymentDetails.cashback_amount)} FIT Points
                </label>
              </div>
            }
            columnLeft={9}
            columnRight={3}
          />
        ) : null}
        <GridRowTableOneColumn
          customElementInitial={
            <label className="text-16 row-item-title">
              Total Item(s): {orderSummaryData.total_items}
            </label>
          }
          customElementColumn={
            <div className="text-right">
              <label className="text-16 row-item-title">
                Grand Total &nbsp; &nbsp; &nbsp; IDR&nbsp;
                {CommonHelper.formatCurrency(orderSummaryData.grand_total)}
              </label>
            </div>
          }
          columnLeft={6}
          columnRight={6}
        />
      </Grid>
    </Grid>
  );
};

CartItemDetailRetails.propTypes = {
  adminFee: PropTypes.object,
  orderData: PropTypes.object,
};

export default CartItemDetailRetails;
