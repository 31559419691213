import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Button } from '@material-ui/core';
import _ from 'lodash';
// Style
import './ModalAddPromoCodeStyle.scss';
// Component
import { ModalAlertAction, TextInput, EmptyState } from '../../../../../../../components/Index';
// Assets
import { Images } from '../../../../../../../assets/Index';

const ModalAddPromoCode = props => {
  const {
    isOpen,
    availablePromoCode,
    onCloseModal,
    onApplyButton,
    onSelectPromoCode,
    selectedPromoCode,
    onSearchPromoCode,
    searchAvailablePromoCode,
  } = props;

  const modalDetailDataCreate = {
    title: 'Choose Promo',
  };

  const selectPromoCode = value => {
    onSelectPromoCode(value);
  };

  const searchPromoCode = value => {
    onSearchPromoCode(value);
  };

  const elementRenderCustom = (
    <div className="container-modal-add-promo-code flex-column">
      <Grid container>
        <Grid item xl={6} lg={6} md={6} className="row-filter">
          <TextInput
            iconPrefix="ic-ffo-search"
            placeHolderText="Search Promo Code"
            onChange={searchPromoCode}
            currentValue={searchAvailablePromoCode}
            size="md"
          />
        </Grid>
      </Grid>
      {_.isEmpty(availablePromoCode) ? (
        <Grid container justify={'center'}>
          <EmptyState source={Images.emptyStatePlaceholder} labelText={'No Promo Code Found yet'} />
        </Grid>
      ) : (
        <div className="scroll-container mt-24">
          <Grid container direction="row">
            <Grid item xl={12} lg={12} md={12} className="pr-10">
              <Grid container spacing={2}>
                {availablePromoCode.map((item, index) => {
                  const selected = selectedPromoCode === item.cashback_id;
                  return (
                    <Grid item xl={3} lg={3} md={3} key={index}>
                      <Button
                        className={`items-container ${selected ? 'selected' : ''}`}
                        onClick={() => {
                          selectPromoCode(item.cashback_id);
                        }}
                      >
                        <label className={`text-14 text-bold ${selected ? 'selected' : ''}`}>
                          {item.promo_code}
                        </label>
                      </Button>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </div>
      )}
    </div>
  );

  return (
    <ModalAlertAction
      onButtonSubmit={onApplyButton}
      onCloseModal={onCloseModal}
      modalDetail={modalDetailDataCreate}
      buttonSubmitText="Apply"
      openModal={isOpen}
      customElementProps={elementRenderCustom}
      size={'middle'}
      disableApplyBtn={!availablePromoCode || _.isEmpty(availablePromoCode)}
    />
  );
};

ModalAddPromoCode.propTypes = {
  availablePromoCode: PropTypes.array,
  isOpen: PropTypes.bool,
  onApplyButton: PropTypes.func,
  onCloseModal: PropTypes.func,
  onSearchPromoCode: PropTypes.func,
  onSelectPromoCode: PropTypes.func,
  searchAvailablePromoCode: PropTypes.string,
  selectedPromoCode: PropTypes.number,
};

export default ModalAddPromoCode;
