/* eslint-disable no-lonely-if */
import React from 'react';
import { connect } from 'react-redux';
import { Grid, FormControl, FormLabel } from '@material-ui/core';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { compose } from 'redux';
// component
import {
  TextInput,
  ButtonMain,
  PrevNextStepperCount,
  SelectInputMain,
  GridRowTableOneColumn,
  RadioInputImage,
} from '../../../../../components/Index';
// style
import './StepSummaryAndPaymentStyle.scss';
import {
  CommonHelper,
  MasterDataHelper,
  OrderHelper,
  HttpStatusCode,
  productTypeForOrderCode,
  UserHelper,
} from '../../../../../helpers/Index';
import {
  checkPromoCode,
  getUserMembershipEats,
  getAvailablePromoCode,
  getOrderTaxPercentage,
  getOrderServiceChargePercentage,
} from '../../../../../services/api/OrdersApi';
import {
  CartItemRetails,
  CartItemEats,
  CartItemEatAtStore,
  ModalAddPromoCode,
} from './components/Index';
import {
  paymentMethodOptionCode,
  eatSessionTypeCode,
} from '../../../../../helpers/MasterDataHelper';

const bankTransferOption = MasterDataHelper.bankTransferOption;
const productTypeOrderOption = MasterDataHelper.productTypeForOrder;

const initialValidation = {
  promoCode: { isError: false, errorMessage: '' },
  membership: { isError: false, errorMessage: '' },
  transactionReference: { isError: false, errorMessage: '' },
  phoneNumberPayment: { isError: false, errorMessage: '' },
};

const initialForm = {
  paymentMethod: null,
  bank: bankTransferOption[0],
  promoCode: '',
  transactionReference: '',
  phoneNumberPayment: '',
  taxAmount: 0,
  serviceChargeAmount: 0,
};

class StepSummaryAndPayment extends React.Component {
  constructor(props) {
    super(props);

    const currentUsers = UserHelper.getCurrentUserInformation();

    this.state = {
      form: CommonHelper.objectCloning(initialForm),
      validation: CommonHelper.objectCloning(initialValidation),
      isSuccess: '',
      isValidPromoCode: false,
      disablePromoCode: false,
      paymentTypeOption: [],
      isResellerLogin: currentUsers && currentUsers.isResellerLogin,
      isOpenModalPromoCode: false,
      loading: false,
      selectedPromoCode: null,
      searchAvailablePromoCode: null,
      isSavedAsDraft: true,
      orderTaxPercentage: 0,
      orderServiceChargePercentage: 0,
    };
    this.searchPromoCodeDebounce = _.debounce(this.searchPromoCodeDebounce, 400);
    this.searchAvailablePromoCodeDebounce = _.debounce(this.searchAvailablePromoCodeDebounce, 400);
  }

  componentDidMount() {
    const { validation, form } = this.state;
    const { parentValidation, parentForm, storeDetailsSelected } = this.props;

    const isEatAtStore = parentForm.orderType === 'eat' && parentForm.orderEatType === 'atstore';
    const isDineInAtPanin =
      isEatAtStore &&
      storeDetailsSelected &&
      storeDetailsSelected.identification === 'fitco-eats-express-panin';
    if (isDineInAtPanin) {
      this.getTax();
      this.getServiceCharge();
    }

    const defaultOptionPayment = this.populatePaymentOptions();

    this.setState(
      {
        form: {
          ...form,
          ...parentForm,
          phoneNumberPayment: parentForm.phone,
          paymentMethod: defaultOptionPayment[0].value,
        },
        validation: { ...parentValidation, ...validation },
        paymentTypeOption: defaultOptionPayment,
      },
      () => {
        if (!parentForm.paymentMethod) {
          const cateringOrderForExistingUser =
            parentForm.orderType === productTypeForOrderCode.eat &&
            !isEatAtStore &&
            parentForm.userId;
          if (cateringOrderForExistingUser) {
            this.getUserMembershipEats(parentForm);
          } else {
            this.handleSelectPaymentMethod(defaultOptionPayment[0].value);
          }
        } else if (!_.isEmpty(parentForm.promoCode)) {
          this.searchPromoCodeDebounce();
        }
      },
    );
  }

  componentWillReceiveProps(nextProps) {
    const { validation } = this.state;

    const isValidationChange = _.isEqual(validation, nextProps.parentValidation);

    if (!isValidationChange) {
      const finalMergeValidation = { ...validation, ...nextProps.parentValidation };
      this.setState({
        validation: finalMergeValidation,
      });
    }
  }

  componentWillUnmount() {
    const { form, validation } = this.state;
    const { onParameterUpdate } = this.props;
    const formNewValue = CommonHelper.objectCloning(form);

    formNewValue.paymentMethod = null;
    formNewValue.promoCode = '';
    formNewValue.transactionReference = '';
    onParameterUpdate(formNewValue, validation);
  }

  getTax() {
    const { checkTax } = this.props;
    checkTax().then(response => {
      this.setState({
        orderTaxPercentage: response.data,
      });
    });
  }

  getServiceCharge() {
    const { checkServiceCharge } = this.props;
    checkServiceCharge().then(response => {
      this.setState({
        orderServiceChargePercentage: response.data,
      });
    });
  }

  getUserMembershipEats(parentForm = {}) {
    const { form } = this.state;
    const { checkUserMembershipEats } = this.props;

    const params = OrderHelper.getCateringListItems(parentForm.items, parentForm.selectedPackage);

    checkUserMembershipEats(parentForm.userId, params)
      .then(response => {
        const data = response.data;

        if (form.selectedPackage && data) {
          const defaultOptionPayment = MasterDataHelper.paymentMethodOption.filter(
            item =>
              item.value === paymentMethodOptionCode.bank_transfer ||
              (item.value === paymentMethodOptionCode.membership && item.name === 'Membership') ||
              item.value === paymentMethodOptionCode.fit_point,
          );

          this.setState(
            {
              form: {
                ...form,
                membershipInfo: data,
              },
              paymentTypeOption: defaultOptionPayment,
            },
            () => {
              this.handleSelectPaymentMethod(paymentMethodOptionCode.membership);
            },
          );
        } else {
          this.handleSelectPaymentMethod(paymentMethodOptionCode.bank_transfer);
        }
      })
      .catch(() => {
        this.handleSelectPaymentMethod(paymentMethodOptionCode.bank_transfer);
      });
  }

  searchPromoCodeDebounce = () => {
    const { checkPromo, onParameterUpdate } = this.props;
    const { validation, form } = this.state;
    const params = this.getPayloadPromoCode();
    const formNewValue = CommonHelper.objectCloning(form);
    const resetValidation = CommonHelper.objectCloning(validation);
    const message = CommonHelper.objectCloning(initialValidation.promoCode);

    if (_.isEmpty(form.promoCode)) {
      this.setState(
        {
          isValidPromoCode: false,
          isSuccess: '',
          validation: {
            ...validation,
            promoCode: { isError: false, errorMessage: '' },
          },
        },
        () => {
          resetValidation.promoCode.isError = false;
          resetValidation.promoCode.errorMessage = '';
          onParameterUpdate(formNewValue, resetValidation);
        },
      );

      return;
    }

    checkPromo(params)
      .then(response => {
        const promoData = response.data;
        const promoMessage =
          promoData.promo_code_cashback_type_id === 2
            ? `Potential cashback of ${CommonHelper.formatCurrency(
                promoData.cashback_amount,
              )} FIT Points`
            : `Potential discount of IDR ${CommonHelper.formatCurrency(promoData.cashback_amount)}`;
        message.errorMessage = `Yay! promotion applied. ${promoMessage}`;

        this.setState(
          {
            isValidPromoCode: true,
            isSuccess: 'success',
            validation: {
              ...validation,
              promoCode: message,
            },
          },
          () => {
            resetValidation.promoCode = message;
            formNewValue.promoCode = form.promoCode;
            onParameterUpdate(formNewValue, resetValidation);
          },
        );
      })
      .catch(e => {
        if (e.status !== HttpStatusCode.Success) {
          message.isError = true;
          message.errorMessage = e.data.messages;

          this.setState(
            {
              validation: {
                ...validation,
                promoCode: message,
              },
              isValidPromoCode: false,
              isSuccess: 'error',
            },
            () => {
              resetValidation.promoCode = message;
              onParameterUpdate(formNewValue, resetValidation);
            },
          );
        }
      });
  };

  searchAvailablePromoCodeDebounce = (value = null) => {
    const { checkAvailablePromoCode, storeDetailsSelected } = this.props;
    const { form } = this.state;
    try {
      const isAtStore =
        form.orderType === productTypeOrderOption[1].value && form.orderEatType === 'atstore';
      const params = {
        order_type: form.orderType,
        payment_type: form.paymentMethod,
        at_store: isAtStore,
        branch_id: storeDetailsSelected ? storeDetailsSelected.branch_id : null,
        search_term: value,
        limit: 20,
        page: 1,
        sortBy: '',
        order: '',
      };
      checkAvailablePromoCode(params);
      this.setState({
        isOpenModalPromoCode: true,
        loading: false,
      });
    } catch (error) {
      this.setState({
        isOpenModalPromoCode: false,
        loading: false,
      });
    }
  };

  getPayloadPromoCode = () => {
    const {
      form: {
        paymentMethod,
        items,
        promoCode,
        userId,
        orderType,
        selectedPackage,
        globalExtraValue,
        note,
        deliveryServiceInfo,
        phone,
        startDate,
        orderEatType,
      },
    } = this.state;
    const { storeDetailsSelected } = this.props;
    const itemList = [];
    let packageDetails = null;

    const isAtStore = orderType === productTypeOrderOption[1].value && orderEatType === 'atstore';

    items.forEach(item => {
      if (orderType === productTypeForOrderCode.shop) {
        itemList.push({ product_id: item.product_id, qty: item.qty });
      } else if (orderType === productTypeForOrderCode.eat) {
        if (_.isEmpty(selectedPackage) && !isAtStore) {
          itemList.push({
            catering_schedule_id: item.catering_schedule_id,
            product_id: item.food_details.product_id,
            qty: item.qty,
            extras: item.extras,
          });
        } else if (isAtStore) {
          itemList.push({
            product_id: item.product_id,
            qty: item.qty,
          });
        }
      }
    });

    if (!_.isEmpty(selectedPackage) && orderType === productTypeForOrderCode.eat) {
      const currentKindPack = selectedPackage.extraSession;

      const modifyLunch = !_.isEmpty(
        currentKindPack.filter(item => item === eatSessionTypeCode.lunch),
      );
      const modifyDinner = !_.isEmpty(
        currentKindPack.filter(item => item === eatSessionTypeCode.dinner),
      );

      packageDetails = {
        quantity: 1,
        pax: true,
        user_address_id: null,
        notes: !_.isEmpty(note) ? note : '-',
        phone,
        days: selectedPackage.pax,
        purchase_lunch: modifyLunch,
        purchase_dinner: modifyDinner,
        extras: globalExtraValue,
        start_date: startDate,
        shipping_cost: deliveryServiceInfo.cost[0].value,
        foodlist_id: selectedPackage.foodlist_id,
      };
    }

    const payload = {
      data: {
        user_id: userId,
        promo_code: promoCode,
        payment_type: paymentMethod,
        items: !_.isEmpty(itemList) ? itemList : null,
        package: packageDetails,
        at_store: isAtStore ? 1 : 0,
        branch_id: isAtStore ? storeDetailsSelected.branch_id : null,
      },
    };
    return payload;
  };

  handleSelectPaymentMethod = value => {
    const { onHandleMessages } = this.props;
    const { form } = this.state;
    const promoCode = CommonHelper.objectCloning(form.promoCode);
    const disablePromoCode = false;

    // if (
    //   value === paymentMethodOptionCode.membership ||
    //   value === paymentMethodOptionCode.credit_card
    // ) {
    //   promoCode = '';
    //   disablePromoCode = true;
    // }

    this.setState(
      {
        form: { ...form, paymentMethod: value },
        disablePromoCode,
      },
      () => {
        if (value === paymentMethodOptionCode.bank_transfer) {
          const valueChange = bankTransferOption[0];
          this.handleChangeBankTransfer(valueChange);
        }

        this.handleChangePromoCodeEntry(promoCode);
      },
    );
    onHandleMessages(null, null, true);
  };

  handleChangeBankTransfer = value => {
    const { form, validation } = this.state;
    const { onParameterUpdate } = this.props;
    const formNewValue = CommonHelper.objectCloning(form);

    this.setState({ form: { ...form, bank: value } }, () => {
      formNewValue.bank = value;
      onParameterUpdate(formNewValue, validation);
    });
  };

  handleChangePromoCodeEntry = value => {
    const { form, selectedPromoCode } = this.state;
    const selectedCode = _.isEmpty(value) ? null : selectedPromoCode;

    this.setState(
      {
        selectedPromoCode: selectedCode,
        form: { ...form, promoCode: value },
      },
      () => {
        this.searchPromoCodeDebounce();
      },
    );
  };

  handleChangeTransactionReference = value => {
    const { onParameterUpdate } = this.props;
    const { form, validation } = this.state;
    const formNewValue = CommonHelper.objectCloning(form);
    const resetValidation = CommonHelper.objectCloning(validation);
    const message = CommonHelper.objectCloning(initialValidation.transactionReference);

    this.setState(
      {
        form: { ...form, transactionReference: value },
        validation: { ...validation, transactionReference: message },
      },
      () => {
        resetValidation.transactionReference = message;
        formNewValue.transactionReference = value;
        onParameterUpdate(formNewValue, resetValidation);
      },
    );
  };

  handleChangePhoneNumberPayment = value => {
    const { onParameterUpdate } = this.props;
    const { form, validation } = this.state;
    const formNewValue = CommonHelper.objectCloning(form);
    const resetValidation = CommonHelper.objectCloning(validation);
    const message = CommonHelper.objectCloning(initialValidation.phoneNumberPayment);
    message.isError = !value;
    message.errorMessage = !value ? 'Please Enter Phone Number For Payment' : '';

    this.setState(
      {
        form: { ...form, phoneNumberPayment: value },
        validation: { ...validation, phoneNumberPayment: message },
      },
      () => {
        resetValidation.phoneNumberPayment = message;
        formNewValue.phoneNumberPayment = value;
        onParameterUpdate(formNewValue, resetValidation);
      },
    );
  };

  handleButtonClickAddPromo = () => {
    this.setState({ loading: true }, () => {
      this.searchAvailablePromoCodeDebounce();
    });
  };

  handleQueryAvailablePromoCode = value => {
    this.setState({ searchAvailablePromoCode: value }, () => {
      this.searchAvailablePromoCodeDebounce(value);
    });
  };

  handleCloseModalPromoCode = () => {
    this.setState({
      isOpenModalPromoCode: false,
      searchAvailablePromoCode: null,
    });
  };

  handleButtonClickApply = () => {
    const {
      orderData: { availablePromoCode },
    } = this.props;
    const { selectedPromoCode } = this.state;

    if (!selectedPromoCode) {
      this.setState({
        selectedPromoCode: null,
        searchAvailablePromoCode: null,
        isOpenModalPromoCode: false,
      });
      return;
    }

    if (_.isEmpty(availablePromoCode.data)) {
      this.setState({
        searchAvailablePromoCode: null,
        isOpenModalPromoCode: false,
      });
      return;
    }

    const selectedObj = availablePromoCode.data.find(
      item => item.cashback_id === selectedPromoCode,
    );
    this.setState({ isOpenModalPromoCode: false }, () => {
      this.handleChangePromoCodeEntry(selectedObj.promo_code);
    });
  };

  handleSelectPromoCode = value => {
    this.setState({
      selectedPromoCode: value,
    });
  };

  populatePaymentOptions() {
    const { parentForm, storeDetailsSelected } = this.props;

    const isExpress = parentForm.orderType === 'eat' && parentForm.orderEatType === 'atstore';
    const isExpressRSPAD =
      isExpress && storeDetailsSelected.identification.includes('fitco-eats-express-rspad');

    let paymentOptions = [];
    switch (parentForm.orderType) {
      case 'eat':
        if (isExpressRSPAD) {
          paymentOptions = MasterDataHelper.paymentMethodOption.filter(
            item =>
              item.value === paymentMethodOptionCode.bank_transfer ||
              item.value === paymentMethodOptionCode.edc ||
              item.value === paymentMethodOptionCode.cash,
          );
        } else if (isExpress) {
          paymentOptions = MasterDataHelper.paymentMethodOption.filter(
            item =>
              item.value === paymentMethodOptionCode.bank_transfer ||
              item.value === paymentMethodOptionCode.edc ||
              item.value === paymentMethodOptionCode.qris ||
              item.value === paymentMethodOptionCode.ovo ||
              item.value === paymentMethodOptionCode.grabfood ||
              item.value === paymentMethodOptionCode.gofood ||
              item.value === paymentMethodOptionCode.shopeefood ||
              item.value === paymentMethodOptionCode.shopeepay ||
              item.value === paymentMethodOptionCode.xendit_invoice,
          );
        } else {
          paymentOptions = MasterDataHelper.paymentMethodOption.filter(
            item =>
              item.value === paymentMethodOptionCode.bank_transfer ||
              item.value === paymentMethodOptionCode.cash ||
              item.value === paymentMethodOptionCode.corporate_deal ||
              item.value === paymentMethodOptionCode.giveaway ||
              item.value === paymentMethodOptionCode.xendit_invoice,
          );
        }
        break;
      case 'shop':
        paymentOptions = MasterDataHelper.paymentMethodOption.filter(
          item =>
            item.value === paymentMethodOptionCode.bank_transfer ||
            item.value === paymentMethodOptionCode.edc,
        );
        break;
      default:
        paymentOptions = MasterDataHelper.paymentMethodOption.filter(
          item => item.value === paymentMethodOptionCode.bank_transfer,
        );
        break;
    }

    const enableFITPoints = !parentForm.isUseAnonymous && parentForm.isExisting;
    if (enableFITPoints) {
      paymentOptions.push(
        MasterDataHelper.paymentMethodOption.find(
          x => x.value === paymentMethodOptionCode.fit_point,
        ),
      );
    }

    return paymentOptions;
  }

  renderModalAddPromoCode() {
    const {
      orderData: { availablePromoCode },
    } = this.props;
    const { isOpenModalPromoCode, selectedPromoCode, searchAvailablePromoCode } = this.state;
    let renderElement = null;

    if (!_.isEmpty(availablePromoCode)) {
      renderElement = (
        <ModalAddPromoCode
          isOpen={isOpenModalPromoCode}
          availablePromoCode={availablePromoCode.data}
          selectedPromoCode={selectedPromoCode}
          searchAvailablePromoCode={searchAvailablePromoCode}
          onCloseModal={this.handleCloseModalPromoCode}
          onApplyButton={this.handleButtonClickApply}
          onSelectPromoCode={this.handleSelectPromoCode}
          onSearchPromoCode={this.handleQueryAvailablePromoCode}
        />
      );
    }

    return renderElement;
  }

  renderMembership() {
    const { form } = this.state;
    let renderElement = null;

    if (!_.isEmpty(form.membershipInfo)) {
      renderElement = (
        <Grid item lg={6} md={6} className="mt-27">
          <Grid container justify="space-between" className="container-membership-information p-24">
            <Grid item lg={9} md={9}>
              <div className="flex-column ">
                <label className="text-12 text-semi-bold title">Membership</label>
                <label className="text-12 text-semi-bold wrapping-container second line">
                  {form.membershipInfo.membership_name || '-'}
                </label>
              </div>
            </Grid>
            <Grid item lg={2} md={2}>
              <div className="flex-column text-center">
                <label className="text-12 text-semi-bold title">Quota</label>
                <label className="text-12 text-semi-bold">
                  {form.membershipInfo.remaining_quota || '-'}
                </label>
              </div>
            </Grid>
          </Grid>
        </Grid>
      );
    }

    return renderElement;
  }

  renderCartItem() {
    const { form, isValidPromoCode, orderTaxPercentage, orderServiceChargePercentage } = this.state;
    const { orderData, onHandleMessages } = this.props;
    let renderElement = null;

    if (form.orderType === productTypeForOrderCode.shop) {
      renderElement = (
        <CartItemRetails form={form} isValidPromoCode={isValidPromoCode} orderData={orderData} />
      );
    } else if (form.orderType === productTypeForOrderCode.eat && form.orderEatType !== 'atstore') {
      renderElement = (
        <CartItemEats form={form} isValidPromoCode={isValidPromoCode} orderData={orderData} />
      );
    } else if (form.orderType === productTypeForOrderCode.eat && form.orderEatType === 'atstore') {
      renderElement = (
        <CartItemEatAtStore
          form={form}
          isValidPromoCode={isValidPromoCode}
          orderData={orderData}
          onHandleMessages={onHandleMessages}
          orderTaxPercentage={orderTaxPercentage}
          orderServiceChargePercentage={orderServiceChargePercentage}
        />
      );
    }

    return renderElement;
  }

  render() {
    const {
      validation,
      form,
      isValidPromoCode,
      isSuccess,
      isResellerLogin,
      disablePromoCode,
      paymentTypeOption,
      isOpenModalPromoCode,
      loading,
      isSavedAsDraft,
    } = this.state;
    const {
      onButtonClickCancel,
      onButtonClickSubmit,
      onPrevClick,
      orderData: { fetching },
      storeDetailsSelected,
    } = this.props;

    const isEatAtStore =
      form.orderType === productTypeOrderOption[1].value && form.orderEatType === 'atstore';
    const isSpecialStoreSelected =
      isEatAtStore && storeDetailsSelected.identification.includes('fitco-eats-catering-rspad');

    const name =
      form.firstName || form.lastName ? `${form.firstName} ${form.lastName}`.trim() : '-';
    const gender = !_.isEmpty(form.gender) ? form.gender.name : '-';
    let orderType = form.orderType
      ? _.find(productTypeOrderOption, ['value', form.orderType]).name
      : '-';

    if (isEatAtStore) {
      orderType = 'Eat - At Store';
    }

    let recipient = OrderHelper.getSummaryFinalRecipient(form);
    if (isSpecialStoreSelected) {
      recipient = `${form.recipientName} - [${form.medicalRecordNumber}]`;
    }

    const successIcon = 'ic-ffi-check-mark';
    // const promoDesc = promoCodeDetails.cashback_amount_desc;
    // const potentialPromo =
    //   promoCodeDetails.promo_code_cashback_type_id === promoTypeCode.Discount
    //     ? `Potential Discount${promoDesc}`
    //     : `Potential Cashback${promoDesc}`;

    const shippingMethod = !_.isEmpty(form.deliveryServiceInfo)
      ? form.deliveryServiceInfo.description
      : '-';

    const isThirdPartyPlatformOrder =
      form.paymentMethod === paymentMethodOptionCode.grabfood ||
      form.paymentMethod === paymentMethodOptionCode.gofood ||
      form.paymentMethod === paymentMethodOptionCode.shopeefood;

    return (
      <Grid container className="container-step-user-summary-payment">
        <Grid item lg={12} md={12}>
          <Grid container spacing={2}>
            <Grid item lg={6} md={6} sm={12} className="mt-24">
              <FormControl component="fieldset" fullWidth margin="normal">
                <FormLabel component="label" className="text-12 filed-title">
                  Customer Details
                </FormLabel>
                <Grid
                  container
                  direction="column"
                  className="container-row-table details flex-wrap-unset"
                >
                  <GridRowTableOneColumn
                    customElementInitial={<label className="text-14 title">Name</label>}
                    customElementColumn={<label className="text-14">{name}</label>}
                    columnLeft={5}
                    columnRight={7}
                  />
                  {isSpecialStoreSelected ? (
                    <GridRowTableOneColumn
                      customElementInitial={<label className="text-14 title">Room Details</label>}
                      customElementColumn={<label className="text-14">{form.note || '-'}</label>}
                      columnLeft={5}
                      columnRight={7}
                    />
                  ) : (
                    <GridRowTableOneColumn
                      customElementInitial={<label className="text-14 title">Email</label>}
                      customElementColumn={<label className="text-14">{form.email || '-'}</label>}
                      columnLeft={5}
                      columnRight={7}
                    />
                  )}
                  {isSpecialStoreSelected ? (
                    <GridRowTableOneColumn
                      customElementInitial={
                        <label className="text-14 title">Medical Record Number</label>
                      }
                      customElementColumn={
                        <label className="text-14">{form.medicalRecordNumber || '-'}</label>
                      }
                      columnLeft={5}
                      columnRight={7}
                    />
                  ) : (
                    <GridRowTableOneColumn
                      customElementInitial={<label className="text-14 title">Phone</label>}
                      customElementColumn={<label className="text-14">{form.phone || '-'}</label>}
                      columnLeft={5}
                      columnRight={7}
                    />
                  )}
                  <GridRowTableOneColumn
                    customElementInitial={<label className="text-14 title">Gender</label>}
                    customElementColumn={<label className="text-14">{gender}</label>}
                    columnLeft={5}
                    columnRight={7}
                  />
                  <GridRowTableOneColumn
                    customElementInitial={<label className="text-14 title">Order Type</label>}
                    customElementColumn={<label className="text-14">{orderType}</label>}
                    columnLeft={5}
                    columnRight={7}
                  />
                </Grid>
              </FormControl>
            </Grid>

            <Grid item lg={6} md={6} className="mt-24 mb-26">
              <FormControl component="fieldset" fullWidth margin="normal">
                <FormLabel component="label" className="text-12 filed-title">
                  {isEatAtStore ? 'Store Details' : 'Shipping Details'}
                </FormLabel>
                <div className="flex-column container-shipping pv-24 ph-16">
                  <label className="text-14 text-bold">
                    {isEatAtStore ? storeDetailsSelected.name : shippingMethod}
                  </label>
                  <label className="text-12 title mt-16 mb-8">
                    {isEatAtStore ? shippingMethod : recipient}
                  </label>
                  <label className="text-14 ">
                    {isEatAtStore ? storeDetailsSelected.address : form.address || '-'}
                  </label>
                  {!isEatAtStore && <label className="text-12 title mt-16 mb-8">Note</label>}
                  {!isEatAtStore && (
                    <label className="text-14 wrapping-container-break">{form.note || '-'}</label>
                  )}
                </div>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={12} md={12}>
          <Grid container spacing={2}>
            <Grid item lg={6} md={6}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <FormLabel component="label" className="text-12 filed-title">
                  Payment Method
                </FormLabel>
                <SelectInputMain
                  options={paymentTypeOption}
                  currentValue={this.state.form.paymentMethod}
                  onChange={this.handleSelectPaymentMethod}
                  disabled={isResellerLogin}
                />
              </FormControl>
            </Grid>
            {this.state.form.paymentMethod === paymentMethodOptionCode.ovo ||
              (this.state.form.paymentMethod === paymentMethodOptionCode.xendit_invoice && (
                <Grid item lg={6} md={6}>
                  <FormControl component="fieldset" fullWidth margin="normal">
                    <FormLabel component="label" className="text-12 filed-title">
                      Phone Number
                    </FormLabel>
                    <div className="container-promo">
                      <TextInput
                        placeHolderText="Phone Number"
                        size="md"
                        onChange={this.handleChangePhoneNumberPayment}
                        currentValue={this.state.form.phoneNumberPayment}
                        errorMessage={validation.phoneNumberPayment.errorMessage}
                        isError={validation.phoneNumberPayment.isError}
                      />
                    </div>
                  </FormControl>
                </Grid>
              ))}
            {this.state.form.paymentMethod === paymentMethodOptionCode.bank_transfer ? (
              <Grid item lg={6} md={6} className="mt-27">
                <FormControl component="fieldset" fullWidth margin="normal">
                  <RadioInputImage
                    data={bankTransferOption}
                    onSelect={this.handleChangeBankTransfer}
                    direction="column"
                    currentValue={this.state.form.bank}
                    imageWidth="60px"
                  />
                </FormControl>
              </Grid>
            ) : (
              this.renderMembership()
            )}
            <Grid item lg={6} md={6}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <FormLabel component="label" className="text-12 filed-title">
                  Transaction Reference
                </FormLabel>
                <div className="container-promo">
                  <TextInput
                    placeHolderText="Transaction Reference"
                    size="md"
                    onChange={this.handleChangeTransactionReference}
                    currentValue={this.state.form.transactionReference}
                    errorMessage={validation.transactionReference.errorMessage}
                    isError={validation.transactionReference.isError}
                  />
                </div>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={12} md={12} sm={12}>
          <Grid container spacing={2}>
            <Grid item lg={6} md={6} sm={6}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <FormLabel component="label" className="text-12 filed-title">
                  Promo Code
                </FormLabel>
                <div className={`container-promo ${isSuccess}`}>
                  <TextInput
                    placeHolderText="Promo Code"
                    size="md"
                    onChange={this.handleChangePromoCodeEntry}
                    currentValue={this.state.form.promoCode}
                    errorMessage={validation.promoCode.errorMessage}
                    isError={validation.promoCode.isError}
                    iconPrefixEnd={isValidPromoCode ? successIcon : ''}
                    isDisable={disablePromoCode}
                  />
                </div>
              </FormControl>
            </Grid>
            <Grid item lg={3} md={4} sm={6} className="mt-42">
              <ButtonMain
                type="primary"
                size="md"
                labelText="Add Promo"
                onClick={this.handleButtonClickAddPromo}
                startIcon="ic-ffo-add"
                isLoading={loading}
                disabled={disablePromoCode}
              />
            </Grid>
            {/* <Fade in={isSuccess === 'success'} timeout={timeInterval}>
              <Grid item lg={6} md={6} className="mt-43">
                <LabelAlert message={potentialPromo} type="success" size="middle" />
              </Grid>
            </Fade> */}
          </Grid>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          {!_.isEmpty(form.items) && this.renderCartItem()}
        </Grid>
        {isOpenModalPromoCode && this.renderModalAddPromoCode()}
        <Grid item lg={12} md={12} sm={12} className="mt-24">
          <Grid container justify="flex-end">
            <Grid item lg={isEatAtStore ? 8 : 5} md={isEatAtStore ? 8 : 6}>
              <Grid container justify="flex-end">
                <Grid item lg={isEatAtStore ? 3 : 4} md={isEatAtStore ? 3 : 4} className="pl-8">
                  <ButtonMain
                    type="ghost"
                    size="xl"
                    labelText="Prev"
                    isLoading={fetching}
                    onClick={onPrevClick}
                  />
                </Grid>
                <Grid item lg={isEatAtStore ? 3 : 4} md={isEatAtStore ? 3 : 4} className="pl-8">
                  <ButtonMain
                    type="negative"
                    size="xl"
                    labelText="Cancel"
                    isLoading={fetching}
                    onClick={onButtonClickCancel}
                  />
                </Grid>
                {isEatAtStore && !isThirdPartyPlatformOrder && (
                  <Grid item lg={3} md={3} className="pl-8">
                    <ButtonMain
                      type="outlined-primary"
                      size="xl"
                      labelText="Save"
                      isLoading={fetching}
                      onClick={() => {
                        onButtonClickSubmit(isSavedAsDraft);
                      }}
                    />
                  </Grid>
                )}
                <Grid item lg={isEatAtStore ? 3 : 4} md={isEatAtStore ? 3 : 4} className="pl-8">
                  <ButtonMain
                    type="primary"
                    size="xl"
                    labelText="Submit"
                    isLoading={fetching}
                    onClick={() => {
                      onButtonClickSubmit(!isSavedAsDraft);
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  checkPromo: params => checkPromoCode(dispatch, params),
  checkUserMembershipEats: (id, params) => getUserMembershipEats(dispatch, id, params),
  checkAvailablePromoCode: params => getAvailablePromoCode(dispatch, params),
  checkTax: () => getOrderTaxPercentage(dispatch),
  checkServiceCharge: () => getOrderServiceChargePercentage(dispatch),
});

const mapStateToProps = ({ orderData }) => ({ orderData });

StepSummaryAndPayment.propTypes = {
  checkAvailablePromoCode: PropTypes.func,
  checkPromo: PropTypes.func,
  checkServiceCharge: PropTypes.func,
  checkTax: PropTypes.func,
  checkUserMembershipEats: PropTypes.func,
  onButtonClickCancel: PropTypes.func,
  onButtonClickSubmit: PropTypes.func,
  onHandleMessages: PropTypes.func,
  onParameterUpdate: PropTypes.func,
  onPrevClick: PropTypes.func,
  orderData: PropTypes.object,
  parentForm: PropTypes.object,
  parentValidation: PropTypes.object,
  storeDetailsSelected: PropTypes.object,
};

const shell = compose(connect(mapStateToProps, mapDispatchToProps));

export default shell(PrevNextStepperCount(StepSummaryAndPayment));
