import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
// style
import './HomeStyle.scss';
import { UserHelper } from '../../helpers/Index';

class Home extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    this.authenticationUser();
  }

  componentWillUnmount() {}

  authenticationUser() {
    const { history } = this.props;
    const currentUsers = UserHelper.getCurrentUserInformation();

    if (currentUsers && currentUsers.token.access_token) {
      history.push('/');
    } else {
      localStorage.clear();
      history.index = 0;
      history.push('/sign-in');
    }
  }

  render() {
    return (
      <div>
        <Helmet title="FITCO | Home" />
        <div className="container-page-home scroll-container-invisible">
          <div className="container-page-scrolling-area">
            <Grid container direction="column">
              <Grid item lg md className="section-page-header">
                Home
              </Grid>
              <Grid item lg md className="section-page-body" />
              <Grid item lg md className="section-page-footer" />
            </Grid>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = () => ({});

const mapStateToProps = () => ({});

Home.propTypes = {
  history: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
