import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { Progress } from 'antd';
// Style
import './ChartLineBarQuotaStyle.scss';

const ChartLineBarQuota = props => {
  const {
    iconPrefix,
    labelText,
    customElementSubProps,
    totalUsage,
    customElementInfoProps,
    colorBar,
    infoBar,
    formatBar,
  } = props;

  return (
    <Grid container direction="column" justify="space-between" className="container-progress-bar">
      <Grid item>
        <Grid item>
          <span className="flex-row">
            <div>
              <i className={`${iconPrefix} container-icon-prefix size-16`} />
            </div>
            <label className="text-14 wrapping-container second line">{labelText}</label>
          </span>
        </Grid>
        <Grid item>{customElementSubProps}</Grid>
      </Grid>
      <Grid item>
        <Grid item>
          <Progress
            className="container-progress-bar"
            percent={totalUsage}
            showInfo={infoBar}
            strokeColor={colorBar}
            format={formatBar}
          />
        </Grid>
        <Grid item>{customElementInfoProps}</Grid>
      </Grid>
    </Grid>
  );
};

ChartLineBarQuota.propTypes = {
  colorBar: PropTypes.string,
  customElementInfoProps: PropTypes.element,
  customElementSubProps: PropTypes.element,
  formatBar: PropTypes.func,
  iconPrefix: PropTypes.string,
  infoBar: PropTypes.bool,
  labelText: PropTypes.string,
  totalUsage: PropTypes.number,
};

export default ChartLineBarQuota;
