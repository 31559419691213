import React from 'react';
import { connect } from 'react-redux';
import { Grid, FormControl, FormLabel } from '@material-ui/core';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import _ from 'lodash';
// component
import {
  ButtonMain,
  PrevNextStepperCount,
  SelectInputGeneral,
  PickerInputDate,
  SelectInputScheduleHour,
} from '../../../../../../components/Index';
import UserCheckField from '../../../../../../components/higher-order-components/UserCheckField';
// api
import {
  getCoachesByProduct,
  getConsultationBranchList,
} from '../../../../../../services/api/MasterDataApi';
import { getAvailableClassSchedule } from '../../../../../../services/api/ScheduleDataApi';
// helper
import { CommonHelper } from '../../../../../../helpers/Index';
// style
import './StepBookingProductDetailsStyle.scss';

const today = CommonHelper.currentDate('YYYY-MM-DD HH:mm:ss');
const currentDate = CommonHelper.dateTimeParseNewFormat(today, 'YYYY-MM-DD');

const initialValidation = {
  productId: { isError: false, errorMessage: '' },
  trainerId: { isError: false, errorMessage: '' },
  branchId: { isError: false, errorMessage: '' },
  startDate: { isError: '', errorMessage: '' },
  trainingScheduleId: { isError: '', errorMessage: '' },
};

const initialForm = {
  productId: 7270,
  trainerId: null,
  branchId: null,
  startDate: currentDate,
  trainingScheduleId: null,
  appointmentDetails: {
    startTime: currentDate,
    branchName: null,
    location: null,
  },
};

class StepBookingProductDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      form: CommonHelper.objectCloning(initialForm),
      validation: CommonHelper.objectCloning(initialValidation),
    };
  }

  componentDidMount() {
    const { parentForm } = this.props;
    const { form } = this.state;
    const selectedProduct =
      !_.isEmpty(parentForm.items) && parentForm.items[0].training_schedule_id
        ? parentForm.items[0]
        : null;

    let setInitialForm = initialForm;

    // Check if auto fullfill from calendar card
    if (selectedProduct) {
      const startDate = CommonHelper.dateTimeParseNewFormat(
        selectedProduct.start_time,
        'YYYY-MM-DD',
      );
      setInitialForm = {
        productId: 7270,
        trainerId: selectedProduct.trainer_id,
        branchId: selectedProduct.branch_id,
        startDate,
        trainingScheduleId: selectedProduct.training_schedule_id,
      };
    }

    // Check if updated from this step
    if (parentForm.step_booking_product_details) {
      const startDate = CommonHelper.dateTimeParseNewFormat(
        parentForm.step_booking_product_details.startDate,
        'YYYY-MM-DD',
      );
      setInitialForm = {
        productId: 7270,
        trainerId: parentForm.step_booking_product_details.trainerId,
        branchId: parentForm.step_booking_product_details.branchId,
        startDate,
        trainingScheduleId: parentForm.step_booking_product_details.trainingScheduleId,
      };
    }

    this.setState(
      {
        form: {
          ...form,
          ...setInitialForm,
        },
      },
      async () => {
        const date = this.state.form.startDate;
        await this.getDoctorByProduct();
        await this.getBranchByProduct();
        await this.getAvailableClassScheduleApi(date);
      },
    );
  }

  getDoctorByProduct = async () => {
    const { doctorByProduct } = this.props;
    const {
      form: { productId },
    } = this.state;
    await doctorByProduct(productId);
  };

  getBranchByProduct = async () => {
    const { consultationBranch } = this.props;
    await consultationBranch();
  };

  getAvailableClassScheduleApi = async date => {
    const { form } = this.state;
    const { callGetAvailableClassScheduleApi } = this.props;
    const productId = form.productId;
    const branchId = form.branchId;
    if (!branchId) return;
    const params = { date, branch_id: branchId, is_dashboard: true };
    await callGetAvailableClassScheduleApi(productId, params);
  };

  getMatchDataByKey = (key, value) => {
    const {
      availableClassSchedule,
      masterData: { branchList },
    } = this.props;
    let findMatchData;
    switch (key) {
      case 'training_schedule_id':
        findMatchData = _.find(availableClassSchedule, ['training_schedule_id', value]);
        break;
      case 'branch_id':
        findMatchData = _.find(branchList, ['branch_id', value]);
        break;

      default:
        break;
    }
    return findMatchData;
  };

  handleSelectProduct = value => {
    const { form } = this.state;

    this.setState({
      form: {
        ...form,
        productId: value,
      },
    });
  };

  handleSelectDoctor = value => {
    const { form } = this.state;

    this.setState({
      form: {
        ...form,
        trainerId: value,
      },
    });
  };

  handleSelectBranch = value => {
    const { form } = this.state;

    this.setState(
      {
        form: {
          ...form,
          branchId: value,
          trainingScheduleId: null,
        },
      },
      () => {
        this.getAvailableClassScheduleApi(form.startDate);
      },
    );
  };

  handleSelectStartDateChange = value => {
    const { form } = this.state;
    this.setState(
      {
        form: { ...form, startDate: value, trainingScheduleId: null },
      },
      () => {
        this.getAvailableClassScheduleApi(value);
      },
    );
  };

  handleSelectTime = value => {
    const { form } = this.state;

    this.setState({
      form: {
        ...form,
        trainingScheduleId: value,
      },
    });
  };

  handleValidation = form => {
    let validationNewValue = CommonHelper.objectCloning(initialValidation);
    const excludeValidationKeys = [];

    const keys = Object.keys(form).filter(key => {
      return excludeValidationKeys.indexOf(key) === -1;
    });

    const errorValidationKeys = Object.keys(validationNewValue).filter(key => {
      return excludeValidationKeys.indexOf(key) === -1;
    });

    const errorKeys = keys.filter(key => {
      return form[key] === null || form[key] === '';
    });

    errorValidationKeys.forEach(key => {
      const isError = errorKeys.indexOf(key) !== -1;
      let errorDetail = {};
      switch (key) {
        case 'productId':
          errorDetail = { isError, errorMessage: isError ? 'Please choose product' : '' };
          break;
        case 'branchId':
          errorDetail = {
            isError,
            errorMessage: isError ? 'Please choose consultation location' : '',
          };
          break;
        case 'trainerId':
          errorDetail = { isError, errorMessage: isError ? 'Please choose doctor' : '' };
          break;
        case 'trainingScheduleId':
          errorDetail = { isError, errorMessage: isError ? 'Please choose appointment time' : '' };
          break;
        default:
          errorDetail = { isError, errorMessage: isError ? 'Please fill input' : '' };
          break;
      }
      validationNewValue = {
        ...validationNewValue,
        [key]: errorDetail,
      };
    });

    this.setState({
      validation: { ...validationNewValue },
    });

    return { isError: errorKeys.length, validationNewValue };
  };

  handlePrevClick = async () => {
    const { onPrevClick, onParameterUpdate } = this.props;
    const { form } = this.state;
    const formNewValue = CommonHelper.objectCloning(form);

    await onParameterUpdate(formNewValue, 'step_booking_product_details');
    await onPrevClick();
  };

  handleNextClick = async () => {
    const { onNextClick, onParameterUpdate } = this.props;
    const { form } = this.state;
    const formNewValue = CommonHelper.objectCloning(form);

    const branchDetail = this.getMatchDataByKey('branch_id', formNewValue.branchId);
    const trainingTime = this.getMatchDataByKey(
      'training_schedule_id',
      formNewValue.trainingScheduleId,
    );
    const appointmentDetails = {
      branchName: branchDetail && branchDetail.name,
      location: branchDetail && branchDetail.address,
      startTime: trainingTime && trainingTime.start_time,
    };

    Object.assign(formNewValue, { appointmentDetails });

    const { isError } = this.handleValidation(formNewValue);
    await onParameterUpdate(formNewValue, 'step_booking_product_details');

    if (!isError) {
      await onNextClick();
    }
  };

  renderSelectDateTimeDetail = () => {
    const {
      form: { startDate, trainingScheduleId, branchId },
      validation,
    } = this.state;

    return (
      <Grid item lg={12} md={12}>
        <Grid item lg={6} md={6}>
          <Grid container spacing={2}>
            <Grid item lg={6} md={6}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <FormLabel component="label" className="text-12 filed-title">
                  Date
                </FormLabel>
                <PickerInputDate
                  customIcon="ic-ffo-date-pick"
                  dateFormat="dd-MM-yyyy"
                  minDate={currentDate}
                  onChange={this.handleSelectStartDateChange}
                  defaultValue={startDate}
                  toolbar
                  errorMessage={validation.startDate.errorMessage}
                  validateStatus={validation.startDate.isError ? 'error' : ''}
                />
              </FormControl>
            </Grid>
            <Grid item lg={6} md={6}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <FormLabel component="label" className="text-12 filed-title">
                  Time
                </FormLabel>
                <SelectInputScheduleHour
                  placeHolder="Select Time"
                  currentValue={trainingScheduleId}
                  onChange={this.handleSelectTime}
                  errorMessage={validation.trainingScheduleId.errorMessage}
                  validateStatus={validation.trainingScheduleId.isError ? 'error' : ''}
                  disabled={!branchId}
                  availableSlotOnly
                  isClasses
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={6} md={6} />
      </Grid>
    );
  };

  render() {
    const {
      onButtonClickCancel,
      masterData: { coachesByProduct, branchList },
    } = this.props;
    const { form, validation } = this.state;
    const dataList = [
      {
        product_id: 7270,
        name: 'Doctor Consultation',
      },
    ];

    return (
      <Grid container className="container-step-product-details">
        <Grid item lg={12} md={12} className="mb-4 mt-32">
          <label className="text-12 text-bold text-rolling-stone">PRODUCT DETAILS</label>
        </Grid>
        <Grid item lg={12} md={12}>
          <Grid container spacing={2}>
            <Grid item lg={6} md={6}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <FormLabel component="label" className="text-12 filed-title">
                  Product
                </FormLabel>
                <SelectInputGeneral
                  placeHolder="Select Product"
                  currentValue={form.productId}
                  dataList={dataList}
                  primaryKey="product_id"
                  onChange={this.handleSelectProduct}
                  errorMessage={validation.productId.errorMessage}
                  validateStatus={validation.productId.isError ? 'error' : ''}
                />
              </FormControl>
            </Grid>
            <Grid item lg={6} md={6}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <FormLabel component="label" className="text-12 filed-title">
                  Doctor
                </FormLabel>
                <SelectInputGeneral
                  placeHolder="Select Doctor"
                  currentValue={form.trainerId}
                  dataList={coachesByProduct}
                  primaryKey="trainer_id"
                  onChange={this.handleSelectDoctor}
                  errorMessage={validation.trainerId.errorMessage}
                  validateStatus={validation.trainerId.isError ? 'error' : ''}
                />
              </FormControl>
            </Grid>
            <Grid item lg={6} md={6}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <FormLabel component="label" className="text-12 filed-title">
                  Branch
                </FormLabel>
                <SelectInputGeneral
                  placeHolder="Select Branch"
                  currentValue={form.branchId}
                  dataList={branchList}
                  primaryKey="branch_id"
                  onChange={this.handleSelectBranch}
                  errorMessage={validation.branchId.errorMessage}
                  validateStatus={validation.branchId.isError ? 'error' : ''}
                />
              </FormControl>
            </Grid>
            {this.renderSelectDateTimeDetail()}
          </Grid>
        </Grid>
        <Grid item lg={12} md={12} className="mt-24">
          <Grid container justify="flex-end">
            <Grid item lg={5} md={6}>
              <Grid container justify="flex-end" spacing={2}>
                <Grid item lg={4} md={4}>
                  <ButtonMain
                    type="negative"
                    size="xl"
                    labelText="Cancel"
                    onClick={onButtonClickCancel}
                  />
                </Grid>
                <Grid item lg={4} md={4}>
                  <ButtonMain
                    type="ghost"
                    size="xl"
                    labelText="Prev"
                    onClick={this.handlePrevClick}
                  />
                </Grid>
                <Grid item lg={4} md={4}>
                  <ButtonMain
                    type="primary"
                    size="xl"
                    labelText="Next"
                    onClick={this.handleNextClick}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  doctorByProduct: productId => getCoachesByProduct(dispatch, productId),
  consultationBranch: () => getConsultationBranchList(dispatch),
  callGetAvailableClassScheduleApi: (productId, params) =>
    getAvailableClassSchedule(dispatch, productId, params),
});

const mapStateToProps = ({ masterData, scheduleSummary }) => ({
  masterData,
  availableClassSchedule: scheduleSummary.availableClassSchedule,
});

StepBookingProductDetails.propTypes = {
  availableClassSchedule: PropTypes.array,
  callGetAvailableClassScheduleApi: PropTypes.func,
  consultationBranch: PropTypes.func,
  doctorByProduct: PropTypes.func,
  masterData: PropTypes.object,
  onButtonClickCancel: PropTypes.func,
  onNextClick: PropTypes.func,
  onParameterUpdate: PropTypes.func,
  onPrevClick: PropTypes.func,
  parentForm: PropTypes.object,
};

const shell = compose(connect(mapStateToProps, mapDispatchToProps));

const core = compose(UserCheckField, PrevNextStepperCount);

export default shell(core(StepBookingProductDetails));
