import React from 'react';
import _ from 'lodash';
import { PropTypes } from 'prop-types';
// Style
import './TooltipChatCustomStyle.scss';
import { CommonHelper } from '../../../../../helpers/Index';

const TooltipChatCustom = props => {
  const { currency, payload } = props;
  let renderElement = <div />;

  if (!_.isEmpty(payload)) {
    const date = CommonHelper.dateTimeParseNewFormat(payload[0].payload.date, 'DD MMM YYYY');

    renderElement = (
      <div className="custom-tooltip-bar-custom flex-column p-8">
        <label className="text-8 mb-8">{date}</label>
        {payload.map((item, index) => {
          const wordingLabel = CommonHelper.renameRemoveCharacter(item.name, '_');
          const valueFormat = currency
            ? CommonHelper.currencyWordingValue(CommonHelper.formatCurrency(item.value))
            : item.value;
          const wordingValue = `${currency || ''} ${valueFormat}`;

          return (
            <label key={index} className="text-10 bm-4">
              <span className="dot" style={{ backgroundColor: item.color }} />
              {wordingLabel} : {wordingValue}
            </label>
          );
        })}
      </div>
    );
  }

  return renderElement;
};

TooltipChatCustom.propTypes = {
  currency: PropTypes.string,
};

export default TooltipChatCustom;
