import React from 'react';
import { 
  // Router, 
  BrowserRouter 
} from 'react-router-dom';
import Routes from './Routes';
import { ThemeProvider } from '@material-ui/styles';
// import { createBrowserHistory } from 'history';
// style
import './assets/scss/Styles.scss';
import themes from './themes/Index';

// const browserHistory = createBrowserHistory();

function App() {
  return (
    <ThemeProvider theme={themes}>
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
