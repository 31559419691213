/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import isEmpty from 'lodash/isEmpty';
// Style
import './PurchaseOrderTermsList.scss';
// Component
import {
  ButtonIconMain,
  GridRowTableFiveColumn,
  PickerInputDate,
} from '../../../../../components/Index';
// helper
import { CommonHelper, purchaseOrderStatusCode } from '../../../../../helpers/Index';

const currentDate = CommonHelper.currentDate('YYYY-MM-DD');

const PurchaseOrderTermsListItem = ({
  buttonClickPrint,
  index,
  item,
  onChangeDueDate,
  onPrintHandler,
  visibleDueDate,
}) => {
  const [loading, setLoading] = React.useState(false);
  const [dueDateParam, setDueDateParam] = useState({});
  const dueDate = CommonHelper.dateTimeParseNewFormat(item.due_date, 'DD MMMM YYYY');
  const amount = CommonHelper.formatCurrencyWithDecimals(item.amount);

  React.useEffect(() => {
    if (!buttonClickPrint) {
      setLoading(false);
    }
  }, [buttonClickPrint]);

  useEffect(() => {
    onChangeDueDate(dueDateParam);
  }, [dueDateParam]);

  return (
    <GridRowTableFiveColumn
      key={index}
      customElementColumnFirst={
        <label className="text-14 row-item-title">Payment {index + 1}</label>
      }
      customElementColumnSecond={
        visibleDueDate ? (
          <PickerInputDate
            customIcon="ic-ffo-date-pick"
            dateFormat="dd/MM/yyyy"
            minDate={item.due_date ? dueDate : currentDate}
            defaultValue={item.due_date ? dueDate : currentDate}
            onChange={value => {
              setDueDateParam({
                due_date: value,
                amount: item.amount,
                id: index,
              });
            }}
            toolbar={false}
          />
        ) : (
          <div className="text-center">
            <label className="text-14 row-item-date">{dueDate}</label>
          </div>
        )
      }
      customElementColumnThird={
        <div className="text-center">
          <label className="text-14 row-item-title">IDR {amount}</label>
        </div>
      }
      customElementColumnFourth={
        <div className="text-center">
          <ButtonIconMain
            icon="ic-ffo-download"
            type="ghost"
            size="xl"
            onClick={() => {
              const invoiceParams = {
                termOfPayment: {
                  label: `Payment ${index + 1}`,
                  due_date: `${dueDate}`,
                  isSingleInvoice: true,
                },
                payment_id: item.purchase_order_payment_id,
                balance_due: amount,
              };
              onPrintHandler(invoiceParams);
              setLoading(true);
            }}
            isLoading={loading}
          />
        </div>
      }
      customElementColumnFifth={
        !item.attachment ? null : (
          <div className="text-center">
            <div className="text-14">
              <a href={item.attachment_url} target="_blank" className="content" rel="noreferrer">
                {item.attachment}
              </a>
            </div>
          </div>
        )
      }
      columnFirst={2}
      columnSecond={2}
      columnThird={3}
      columnFourth={1}
      columnFifth={3}
    />
  );
};

PurchaseOrderTermsListItem.propTypes = {
  buttonClickPrint: PropTypes.bool,
  index: PropTypes.number,
  item: PropTypes.object,
  onChangeDueDate: PropTypes.func,
  onPrintHandler: PropTypes.func,
  visibleDueDate: PropTypes.bool,
};

const getDueDateParams = terms => {
  const dueDateParams = terms.map((item, index) => {
    return { due_date: item.due_date || currentDate, amount: item.amount, id: index };
  });
  return dueDateParams;
};

const PurchaseOrderTermsList = ({
  buttonClickPrint,
  orderData,
  onPrintHandler,
  onChangeDueDateHandler,
}) => {
  const [dueDateParams, setDueDateParams] = useState([]);
  const orderItems = orderData.terms || [];
  const orderDetails = orderData.details || {};
  const visibleDueDate = orderDetails.purchase_order_status === purchaseOrderStatusCode.DeliveredPO;

  useEffect(() => {
    const dueDateArray = getDueDateParams(orderData.terms);
    const orderedValue = CommonHelper.dataArrayOrder(dueDateArray, 'id', 'asc');
    setDueDateParams(orderedValue);
    onChangeDueDateHandler(orderedValue);
  }, []);

  const onChangeDueDate = dueDate => {
    if (!isEmpty(dueDate)) {
      const currentDueDateParams = [...dueDateParams];
      const index = dueDateParams.findIndex(item => item.id === dueDate.id);
      if (index !== -1) {
        currentDueDateParams.splice(index, 1);
      }
      currentDueDateParams.push(dueDate);
      const orderedValue = CommonHelper.dataArrayOrder(currentDueDateParams, 'id', 'asc');
      setDueDateParams(orderedValue);
      onChangeDueDateHandler(orderedValue);
    }
  };

  return (
    <Grid container direction="row" spacing={4}>
      <Grid item lg={12} md={12} className="container-term-of-payment-list">
        <div className="table-head">
          <GridRowTableFiveColumn
            customElementColumnFirst={
              <label className="text-14 header-title">TERM OF PAYMENT</label>
            }
            customElementColumnSecond={
              <div className="text-center">
                <label className="text-14 header-title">DUE DATE</label>
              </div>
            }
            customElementColumnThird={
              <div className="text-center">
                <label className="text-14 header-title">AMOUNT</label>
              </div>
            }
            customElementColumnFourth={
              <div className="text-center">
                <label className="text-14 header-title">INVOICE</label>
              </div>
            }
            customElementColumnFifth={
              <div className="text-right">
                <label className="text-14 header-title">DOCUMENT</label>
              </div>
            }
            columnFirst={2}
            columnSecond={2}
            columnThird={3}
            columnFourth={1}
            columnFifth={3}
          />
        </div>
        <Grid container direction="column" className="flex-wrap-unset">
          {orderItems.map((item, index) => {
            return (
              <PurchaseOrderTermsListItem
                key={index}
                index={index}
                buttonClickPrint={buttonClickPrint}
                item={item}
                visibleDueDate={visibleDueDate}
                onPrintHandler={invoiceParams => {
                  Object.assign(invoiceParams, {
                    termOfPayment: {
                      ...invoiceParams.termOfPayment,
                      terms: orderItems,
                    },
                  });
                  onPrintHandler(invoiceParams);
                }}
                onChangeDueDate={onChangeDueDate}
              />
            );
          })}
        </Grid>
      </Grid>
    </Grid>
  );
};

PurchaseOrderTermsList.propTypes = {
  buttonClickPrint: PropTypes.bool,
  onChangeDueDateHandler: PropTypes.func,
  onPrintHandler: PropTypes.func,
  orderData: PropTypes.object,
};

export default PurchaseOrderTermsList;
