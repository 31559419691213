import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, IconButton } from '@material-ui/core';
import { connect } from 'react-redux';
// style
import './ModalCustomerDetail.scss';
// component
import { ModalInformationPopUp, ButtonMain } from '../../../../../../components/Index';
import ModalOrderQris from '../modal-order-qris/ModalOrderQris';

const ModalCustomerDetail = ({ onClose, isOpen, data }) => {
  const [openQr, setOpenQr] = useState(false);
  const [resultUrl, setResultUrl] = useState('');

  const openLinkHandler = url => {
    window.open(url, '_blank');
  };

  const renderElement = (
    <Grid container direction="column" className="flex-wrap-unset container-modal-customer-detail">
      <Grid item lg md className="section-header-modal">
        <Grid container justify="space-between">
          <Grid item lg={11} md={11}>
            <h5 className="wrapping-container second line">Result </h5>
          </Grid>
          <Grid item>
            <div className="container-button-close">
              <IconButton onClick={onClose}>
                <i className="ic-ffo-close container-icon-prefix size-21" />
              </IconButton>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg md className="section-body-modal">
        <Grid container className="container-detail scroll-container">
          {data.map(item => {
            return (
              <Grid
                item
                lg={12}
                md={12}
                key={item.sales_invoice_item_id.toString()}
                className="container-customer-detail"
              >
                <Grid container>
                  <Grid item lg={6} md={6}>
                    <label className="text-15 text-bold mb-4">
                      {item.details && item.details.name}
                    </label>
                    <label className="text-12 text-semi-bold text-rolling-stone">
                      Kode Booking: {item.medical_record_number}
                    </label>
                  </Grid>
                  {item.result_url && (
                    <Grid item lg={6} md={6}>
                      <Grid container justify="flex-end" spacing={2}>
                        <Grid item>
                          <ButtonMain
                            labelText="Show QR"
                            onClick={() => {
                              setResultUrl(item.result_url);
                              setOpenQr(true);
                            }}
                            type="ghost"
                          />
                        </Grid>
                        <Grid item>
                          <ButtonMain
                            labelText="Open Link"
                            onClick={() => openLinkHandler(item.result_url)}
                            type="primary"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
                <Grid container className="container-customer-detail__result">
                  <Grid item lg={12} md={12}>
                    <label className="text-15 text-bold text-rolling-stone">Link</label>
                    {item.result_url ? (
                      <a href={item.result_url} className="text-12 text-semi-bold">
                        {item.result_url}
                      </a>
                    ) : (
                      '-'
                    )}
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <>
      <ModalInformationPopUp
        isOpen={isOpen}
        onClose={onClose}
        customElementProps={renderElement}
        size="medium"
      />
      <ModalOrderQris isOpen={openQr} data={resultUrl} onClose={() => setOpenQr(false)} />
    </>
  );
};

const mapDispatchToProps = () => ({});

const mapStateToProps = () => ({});

ModalCustomerDetail.propTypes = {
  data: PropTypes.array,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalCustomerDetail);
