import React from 'react';
import { connect } from 'react-redux';
import { Grid, FormControl, FormLabel } from '@material-ui/core';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { Table } from 'antd';
// component
import {
  ButtonMain,
  PrevNextStepperCount,
  GridRowTableOneColumn,
  ButtonIconMain,
  SnackBarSimple,
  LabelStatusMain,
} from '../../../../../../components/Index';
import { DetailsAppointment, ModalAddProduct } from './components/Index';
// Api
import { saveOpenBill } from '../../../../../../services/api/OrdersApi';
// style
import './StepBookingAddProductStyle.scss';
import { CommonHelper } from '../../../../../../helpers/Index';

const initialForm = {
  products: [],
  orderItems: [],
};

const optionToast = {
  vertical: 'top',
  horizontal: 'right',
};

class StepBookingAddProduct extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      form: CommonHelper.objectCloning(initialForm),
      loading: false,
      openModal: false,
      toastInformation: {
        isOpen: false,
        message: '',
        snackbarType: 'warning',
      },
    };
  }

  componentDidMount() {
    const { parentForm } = this.props;
    const { form } = this.state;
    if (parentForm.step_booking_add_products) {
      this.setState({
        form: {
          ...form,
          ...parentForm.step_booking_add_products,
        },
      });
    }
  }

  saveOpenBillApi = async (salesOrderId, orderItems, isDelete = false) => {
    const { callSaveOpenBillApi, onSuccessAddProduct } = this.props;
    try {
      const items = [...orderItems];
      const params = {
        promo_code: '',
        items,
        payment_data: {
          payment_type: 'edc',
        },
      };
      await callSaveOpenBillApi(salesOrderId, params);
      const message = isDelete ? 'Successfully delete product' : 'Successfully add product';
      this.processMessage(message, 'success');
      onSuccessAddProduct();
    } catch (error) {
      const message = error.data.messages;
      this.processMessage(message, 'error');
    }
  };

  handleCloseToast = () => {
    const { toastInformation } = this.state;
    this.setState({ toastInformation: { ...toastInformation, isOpen: false } });
  };

  handlePrevClick = async () => {
    const { onPrevClick } = this.props;

    await onPrevClick();
  };

  handleNextClick = async () => {
    const { form } = this.state;
    const { onNextClick, onParameterUpdate } = this.props;

    const formNewValue = CommonHelper.objectCloning(form);

    onParameterUpdate(formNewValue, 'step_booking_add_products');
    await onNextClick();
  };

  handleOpenAddProductModal = () => {
    this.setState({ openModal: true });
  };

  handleRemoveProduct = salesOrderItemId => {
    const { parentForm } = this.props;
    const updatedItems = parentForm.currentItems.filter(
      item => item.sales_order_item_id !== salesOrderItemId,
    );
    this.saveOpenBillApi(parentForm.sales_order_id, updatedItems, true);
  };

  handleCloseAddProductModal = () => {
    this.setState({ openModal: false });
  };

  handleAddProduct = value => {
    const { form } = this.state;
    const { parentForm } = this.props;
    this.setState(
      {
        form: { ...form },
        openModal: false,
      },
      () => {
        const updatedItems = [...parentForm.currentItems, value.item];
        this.saveOpenBillApi(parentForm.sales_order_id, updatedItems);
      },
    );
  };

  processMessage(messages, type) {
    const convertedMessage = CommonHelper.generateMessage(messages);

    const paramInformation = {
      isOpen: true,
      message: convertedMessage,
      snackbarType: type,
    };

    this.setState({
      toastInformation: paramInformation,
    });
  }

  renderAdditionalInformation = row => {
    switch (row.type) {
      case 'workout_session':
        return <MedicProduct data={row} />;

      default:
        return <MembershipProduct data={row} />;
    }
  };

  renderColumns = () => {
    return [
      {
        title: 'Additional Product',
        dataIndex: 'name',
        render: (text, row) => {
          return (
            <Grid container direction="column" className="pv-12 flex-wrap-unset ph-8">
              <Grid item lg md>
                <div className="mb-15">
                  <div>
                    <label className="text-15 text-bold">{row.name}</label>
                  </div>
                </div>
              </Grid>
              {this.renderAdditionalInformation(row)}
            </Grid>
          );
        },
      },
      {
        title: 'Price',
        align: 'center',
        render: (text, row) => {
          let price = row.price;
          const isMembershipApplied = row.details.membership_applied;
          if (isMembershipApplied) {
            price = 'Free';
          } else {
            price = `IDR ${CommonHelper.formatCurrency(price)}`;
          }
          return (
            <Grid container direction="row">
              <Grid item lg md>
                <Grid
                  container
                  direction="column"
                  justify="center"
                  className="order-amount-section"
                >
                  <Grid item lg md className="vertical-align-center">
                    <span className="flex-row text-center">
                      <label className="text-16 text-bold currency">{price}</label>
                    </span>
                  </Grid>
                  {isMembershipApplied && (
                    <Grid item lg md className="pt-4 vertical-align-start">
                      <LabelStatusMain value="Memberships Applied" type="complete" />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          );
        },
      },
      {
        title: 'Action',
        align: 'center',
        render: (text, row) => {
          return (
            <Grid container justify="center">
              <Grid item>
                <ButtonIconMain
                  icon="ic-ffo-bin"
                  type="negative"
                  size="sm"
                  onClick={() => {
                    this.handleRemoveProduct(row.sales_order_item_id);
                  }}
                  tooltip="Delete"
                />
              </Grid>
            </Grid>
          );
        },
      },
    ];
  };

  renderProductList = isEmptyProduct => {
    const { loading } = this.state;
    const { consultationDetails } = this.props;
    const { additional_items: additionalItems } = consultationDetails;

    if (isEmptyProduct) return null;
    return (
      <Table
        columns={this.renderColumns()}
        rowKey={product => product.product_id}
        dataSource={additionalItems}
        pagination={{ disabled: true, hideOnSinglePage: true }}
        loading={loading}
        onChange={() => {}}
      />
    );
  };

  render() {
    const { openModal, toastInformation } = this.state;
    const { onButtonClickCancel, consultationDetails, isDraft } = this.props;

    const {
      user_details: userDetails,
      order_details: orderDetails,
      additional_items: additionalItems,
      consultation_details: appointment,
    } = consultationDetails;

    const appointmentDetails = {
      productName: appointment.name,
      appointmentDate: `${appointment.appointment_date}, ${appointment.appointment_time}`,
      location: appointment.branch_name,
    };

    const isEmptyProduct = !additionalItems.length;

    return (
      <Grid
        container
        direction="column"
        className="container-step-booking-add-product flex-wrap-unset"
      >
        <Grid item lg={12} md={12} className="mb-48">
          <Grid container spacing={2}>
            <Grid item lg={6} md={6} className="mt-24">
              <FormControl component="fieldset" fullWidth margin="normal">
                <FormLabel component="label" className="text-12 filed-title">
                  Customer Details
                </FormLabel>
                <Grid
                  container
                  direction="column"
                  className="container-row-table details flex-wrap-unset"
                >
                  <GridRowTableOneColumn
                    customElementInitial={<label className="text-14 title">Name</label>}
                    customElementColumn={<label className="text-14">{userDetails.name}</label>}
                    columnLeft={5}
                    columnRight={7}
                  />
                  <GridRowTableOneColumn
                    customElementInitial={<label className="text-14 title">Email</label>}
                    customElementColumn={<label className="text-14">{userDetails.email}</label>}
                    columnLeft={5}
                    columnRight={7}
                  />
                  <GridRowTableOneColumn
                    customElementInitial={<label className="text-14 title">Phone Number</label>}
                    customElementColumn={<label className="text-14">{userDetails.phone}</label>}
                    columnLeft={5}
                    columnRight={7}
                  />
                  <GridRowTableOneColumn
                    customElementInitial={<label className="text-14 title">Gender</label>}
                    customElementColumn={
                      <label className="text-14">{userDetails.gender || '-'}</label>
                    }
                    columnLeft={5}
                    columnRight={7}
                  />
                  <GridRowTableOneColumn
                    customElementInitial={<label className="text-14 title">Order Type</label>}
                    customElementColumn={
                      <label className="text-14 capitalize">{orderDetails.order_type}</label>
                    }
                    columnLeft={5}
                    columnRight={7}
                  />
                </Grid>
              </FormControl>
            </Grid>
            <Grid item lg={6} md={6}>
              <DetailsAppointment orderData={appointmentDetails} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg md className="section-page-body">
          {this.renderProductList(isEmptyProduct)}
        </Grid>
        <Grid item lg md className="section-add-product mt-24">
          <Grid container justify="center">
            <Grid item>
              <ButtonMain
                labelText="Add Item"
                onClick={this.handleOpenAddProductModal}
                type="primary"
                size="md"
                startIcon="ic-ffo-add"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={12} md={12} className="mt-42">
          <Grid container justify="flex-end">
            <Grid item lg={5} md={6}>
              <Grid container justify="flex-end">
                <Grid item lg={4} md={4} className="pl-8">
                  <ButtonMain
                    type="ghost"
                    size="xl"
                    labelText="Cancel"
                    onClick={onButtonClickCancel}
                  />
                </Grid>
                <Grid item lg={4} md={4} className="pl-8">
                  <ButtonMain
                    type="primary"
                    size="xl"
                    labelText="Next"
                    onClick={this.handleNextClick}
                    disabled={!isDraft}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg md>
          <ModalAddProduct
            isOpen={openModal}
            onClose={this.handleCloseAddProductModal}
            onHandleAddProduct={this.handleAddProduct}
          />
        </Grid>
        <SnackBarSimple
          open={toastInformation.isOpen}
          durationHide={2000}
          message={toastInformation.message}
          onClickClose={this.handleCloseToast}
          snackbarType={toastInformation.snackbarType}
          anchor={optionToast}
        />
      </Grid>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  callSaveOpenBillApi: (salesOrderID, params) => saveOpenBill(dispatch, salesOrderID, params),
});

const mapStateToProps = ({ orderData }) => ({ consultationDetails: orderData.consultationDetails });

StepBookingAddProduct.propTypes = {
  callSaveOpenBillApi: PropTypes.func,
  consultationDetails: PropTypes.object,
  isDraft: PropTypes.bool,
  onButtonClickCancel: PropTypes.func,
  onNextClick: PropTypes.func,
  onParameterUpdate: PropTypes.func,
  onPrevClick: PropTypes.func,
  onSuccessAddProduct: PropTypes.func,
  parentForm: PropTypes.object,
};

const MembershipProduct = ({ data }) => {
  const startDate = CommonHelper.dateTimeParseNewFormat(
    data.details && data.details.start_date,
    'DD MMM YYYY',
  );
  const recurring = data.details && data.details.recurring;
  return (
    <Grid item lg md>
      <Grid container direction="row" spacing={2}>
        <Grid item>
          <div>
            <div className="">
              <label className="text-12 text-medium text-rolling-stone">Period</label>
            </div>
            <div>
              <label className="text-12 text-bold text-mine-shaft">
                {data.details.period} {data.details.period_type}
                {data.details.period > 1 ? 's' : ''}
              </label>
            </div>
          </div>
        </Grid>
        <Grid item>
          <div className="divider-hr-vertical" />
        </Grid>
        <Grid item>
          <div>
            <div className="">
              <label className="text-12 text-medium text-rolling-stone">Auto Recurring</label>
            </div>
            <div>
              <label className={`text-12 text-bold ${recurring ? 'text-jade' : 'text-cinnabar'}`}>
                {recurring ? 'Yes' : 'No'}
              </label>
            </div>
          </div>
        </Grid>
        <Grid item>
          <div className="divider-hr-vertical" />
        </Grid>
        <Grid item lg md>
          <div>
            <div className="">
              <label className="text-12 text-medium text-rolling-stone">Start Date</label>
            </div>
            <div>
              <label className="text-12 text-bold text-mine-shaft">{startDate || '-'}</label>
            </div>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};

MembershipProduct.propTypes = {
  data: PropTypes.object,
};

const MedicProduct = ({ data }) => {
  const appointmentDate = CommonHelper.dateTimeParseNewFormat(
    data.details && data.details.appointment_date,
    'DD MMM YYYY',
  );
  return (
    <Grid item lg md>
      <Grid container direction="row" spacing={2}>
        <Grid item>
          <div>
            <div className="">
              <label className="text-12 text-medium text-rolling-stone">Appointment Date</label>
            </div>
            <div>
              <label className="text-12 text-bold text-mine-shaft">{appointmentDate}</label>
            </div>
          </div>
        </Grid>
        <Grid item>
          <div className="divider-hr-vertical" />
        </Grid>
        <Grid item>
          <div>
            <div className="">
              <label className="text-12 text-medium text-rolling-stone">Location</label>
            </div>
            <div>
              <label className="text-12 text-bold text-mine-shaft">
                {(data.details && data.details.branch_name) || '-'}
              </label>
            </div>
          </div>
        </Grid>
        <Grid item>
          <div className="divider-hr-vertical" />
        </Grid>
        <Grid item lg md>
          <div>
            <div className="">
              <label className="text-12 text-medium text-rolling-stone">Therapist Name</label>
            </div>
            <div>
              <label className="text-12 text-bold text-mine-shaft">
                {(data.details.trainer && data.details.trainer.name) || '-'}
              </label>
            </div>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};

MedicProduct.propTypes = {
  data: PropTypes.object,
};

const shell = compose(connect(mapStateToProps, mapDispatchToProps));

export default shell(PrevNextStepperCount(StepBookingAddProduct));
