/* eslint-disable no-unused-vars */
/* eslint-disable no-plusplus */
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Page, View, Document, Text, StyleSheet } from '@react-pdf/renderer';
import {
  Table,
  TableHeader,
  TableCell,
  TableBody,
  DataTableCell,
} from '@david.kucsai/react-pdf-table';
// Helper
import { CommonHelper, GeneratorHelper } from '../../../../../helpers/Index';
import { StyleJs as StyleJsGlobal } from '../../../../../assets/Index';

const currentDateTime = CommonHelper.currentDate('YYYY-MM-DD | hh:mm A');

const styles = StyleSheet.create({
  textWhite: {
    color: '#FFF',
  },
  tableRowHeader: {
    backgroundColor: '#7c858b',
    color: '#FFF',
    padding: 4,
    paddingVertical: 8,
  },
  tableRowBody: {
    padding: 4,
  },
});

const widthNo = 0.06;
const widthName = 0.2;
const widthMembership = 0.3;
const widthProduct = 0.4;
const widthAppointment = 0.2;
const widthDate = 0.24;

const CanvasDashboardMembershipList = props => {
  const { data } = props;
  // const dataDetails = !_.isEmpty(data) ? data.details : {};
  const dataList = !_.isEmpty(data) ? GeneratorHelper.generateNumberTable(data.list) : [];

  return (
    <Document>
      <Page size="A4" orientation="landscape">
        <View style={[StyleJsGlobal.flexColumn, { padding: 24, width: 845 }]}>
          <Text style={[StyleJsGlobal.text18, StyleJsGlobal.textCenter, StyleJsGlobal.mb32]}>
            Recent Check In
          </Text>
          <Table data={dataList}>
            <TableHeader>
              <TableCell
                weighting={widthNo}
                style={[
                  StyleJsGlobal.text10,
                  StyleJsGlobal.textCenter,
                  styles.textWhite,
                  styles.tableRowHeader,
                ]}
              >
                No
              </TableCell>
              <TableCell
                weighting={widthName}
                style={[
                  StyleJsGlobal.text10,
                  styles.textWhite,
                  StyleJsGlobal.textCenter,
                  styles.tableRowHeader,
                ]}
              >
                Customer Name
              </TableCell>
              <TableCell
                weighting={widthMembership}
                style={[
                  StyleJsGlobal.text10,
                  styles.textWhite,
                  StyleJsGlobal.textCenter,
                  styles.tableRowHeader,
                ]}
              >
                Membership
              </TableCell>
              <TableCell
                weighting={widthProduct}
                style={[
                  StyleJsGlobal.text10,
                  styles.textWhite,
                  StyleJsGlobal.textCenter,
                  styles.tableRowHeader,
                ]}
              >
                Product Name
              </TableCell>
              <TableCell
                weighting={widthDate}
                style={[
                  StyleJsGlobal.text10,
                  styles.textWhite,
                  StyleJsGlobal.textCenter,
                  styles.tableRowHeader,
                ]}
              >
                Check In Time
              </TableCell>
              <TableCell
                weighting={widthAppointment}
                style={[
                  StyleJsGlobal.text10,
                  styles.textWhite,
                  StyleJsGlobal.textCenter,
                  styles.tableRowHeader,
                ]}
              >
                Total Check In
              </TableCell>
            </TableHeader>
            <TableBody>
              <DataTableCell
                weighting={widthNo}
                style={[StyleJsGlobal.text10, StyleJsGlobal.textCenter, styles.tableRowBody]}
                getContent={r => r.no}
              />
              <DataTableCell
                weighting={widthName}
                style={[StyleJsGlobal.text10, styles.tableRowBody]}
                getContent={r => r.customer_name}
              />
              <DataTableCell
                weighting={widthMembership}
                style={[StyleJsGlobal.text10, StyleJsGlobal.textCenter, styles.tableRowBody]}
                getContent={r => r.membership_name || '-'}
              />
              <DataTableCell
                weighting={widthProduct}
                style={[StyleJsGlobal.text10, styles.tableRowBody]}
                getContent={r => {
                  const wordingProduct = `${r.product_type} - ${r.product_name}`;

                  return wordingProduct;
                }}
              />
              <DataTableCell
                weighting={widthDate}
                style={[StyleJsGlobal.text10, StyleJsGlobal.textCenter, styles.tableRowBody]}
                getContent={r => {
                  const wordingDate = CommonHelper.dateTimeParseNewFormat(
                    r.latest_time_checkin,
                    'LT / DD MMM YYYY',
                  );
                  const checkIn = `${r.branch_name} - ${wordingDate}`;

                  return checkIn;
                }}
              />
              <DataTableCell
                weighting={widthAppointment}
                style={[StyleJsGlobal.text10, StyleJsGlobal.textCenter, styles.tableRowBody]}
                getContent={r => r.total_appointment}
              />
            </TableBody>
          </Table>
          <Text style={[StyleJsGlobal.text10, StyleJsGlobal.mt16, StyleJsGlobal.textCenter]}>
            Print Date {currentDateTime}
          </Text>
        </View>
      </Page>
    </Document>
  );
};

CanvasDashboardMembershipList.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default CanvasDashboardMembershipList;
