import { constants } from '../reducers/ProductCategoryReducer';

export function updateProductCategoryRequest() {
  return { type: constants.updateProductCategoryRequest };
}
export function updateProductCategorySuccess(response) {
  return { type: constants.updateProductCategorySuccess, payload: response };
}
export function updateProductCategoryFail(response) {
  return { type: constants.updateProductCategoryFail, payload: response.data };
}

export function deleteProductCategoryRequest() {
  return { type: constants.deleteProductCategoryRequest };
}
export function deleteProductCategorySuccess(response) {
  return { type: constants.deleteProductCategorySuccess, payload: response };
}
export function deleteProductCategoryFail(response) {
  return { type: constants.deleteProductCategoryFail, payload: response.data };
}

export function getListProductCategoryPaginationRequest() {
  return { type: constants.getListProductCategoryPaginationRequest };
}

export function getListProductCategoryPaginationSuccess(response) {
  return { type: constants.getListProductCategoryPaginationSuccess, payload: response };
}

export function getListProductCategoryPaginationFail(response) {
  return { type: constants.getListProductCategoryPaginationFail, payload: response.data };
}
