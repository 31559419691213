import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
// Style
import './LabelMultipleStyle.scss';

const LabelMultiple = props => {
  const { dataArray } = props;

  return (
    <Grid container direction="column" className="container-label-multiple">
      {dataArray.map((item, index) => (
        <Grid key={index} item>
          <label className="text-12">{item}</label>
        </Grid>
      ))}
    </Grid>
  );
};

LabelMultiple.propTypes = {
  dataArray: PropTypes.array,
};

export default LabelMultiple;
