export const constants = {
  getEventPaginationRequest: 'GET_EVENT_PAGINATION_REQUEST',
  getEventPaginationSuccess: 'GET_EVENT_PAGINATION_SUCCESS',
  getEventPaginationFail: 'GET_EVENT_PAGINATION_FAIL',
  getEventCategoryRequest: 'GET_EVENT_CATEGORY_REQUEST',
  getEventCategorySuccess: 'GET_EVENT_CATEGORY_SUCCESS',
  getEventCategoryFail: 'GET_EVENT_CATEGORY_FAIL',
  createEventRequest: 'CREATE_EVENT_REQUEST',
  createEventSuccess: 'CREATE_EVENT_SUCCESS',
  createEventFail: 'CREATE_EVENT_FAIL',
  getEventParticipantPaginationRequest: 'GET_EVENT_PARTICIPANT_PAGINATION_REQUEST',
  getEventParticipantPaginationSuccess: 'GET_EVENT_PARTICIPANT_PAGINATION_SUCCESS',
  getEventParticipantPaginationFail: 'GET_EVENT_PARTICIPANT_PAGINATION_FAIL',
  getEventParticipantDetailsRequest: 'GET_EVENT_PARTICIPANT_DETAILS_REQUEST',
  getEventParticipantDetailsSuccess: 'GET_EVENT_PARTICIPANT_DETAILS_SUCCESS',
  getEventParticipantDetailsFail: 'GET_EVENT_PARTICIPANT_DETAILS_FAIL',
  getEventDetailsRequest: 'GET_EVENT_DETAILS_REQUEST',
  getEventDetailsSuccess: 'GET_EVENT_DETAILS_SUCCESS',
  getEventDetailsFail: 'GET_EVENT_DETAILS_FAIL',
  getEventStatisticRequest: 'GET_EVENT_STATISTIC_REQUEST',
  getEventStatisticSuccess: 'GET_EVENT_STATISTIC_SUCCESS',
  getEventStatisticFail: 'GET_EVENT_STATISTIC_FAIL',
  resetParticipantDetails: 'RESET_PARTICIPANT_DETAILS',
  setSelfClaimRequest: 'SET_SELF_CLAIM_REQUEST',
  setSelfClaimSuccess: 'SET_SELF_CLAIM_SUCCESS',
  setSelfClaimFail: 'SET_SELF_CLAIM_FAIL',
  setDelegateClaimRequest: 'SET_DELEGATE_CLAIM_REQUEST',
  setDelegateClaimSuccess: 'SET_DELEGATE_CLAIM_SUCCESS',
  setDelegateClaimFail: 'SET_DELEGATE_CLAIM_FAIL',
  getEventParticipantDataRequest: 'GET_EVENT_PARTICIPANT_DATA_REQUEST',
  getEventParticipantDataSuccess: 'GET_EVENT_PARTICIPANT_DATA_SUCCESS',
  getEventParticipantDataFail: 'GET_EVENT_PARTICIPANT_DATA_FAIL',
  updateParticipantDataRequest: 'UPDATE_PARTICIPANT_DATA_REQUEST',
  updateParticipantDataSuccess: 'UPDATE_PARTICIPANT_DATA_SUCCESS',
  updateParticipantDataFail: 'UPDATE_PARTICIPANT_DATA_FAIL',
  resendEmailRequest: 'RESEND_EMAIL_REQUEST',
  resendEmailSuccess: 'RESEND_EMAIL_SUCCESS',
  resendEmailFail: 'RESEND_EMAIL_FAIL',
};

const initState = {
  fetching: false,
  events: [],
  eventCategories: [],
  list: [],
  participants: [],
  details: {},
  statistics: {},
  participantDetails: {},
  responseMessage: null,
  errorFetch: false,
  errorMessage: null,
};

export default function eventReducer(state = initState, action) {
  const { type, payload } = action;

  switch (type) {
    case constants.getEventPaginationRequest:
      return { ...state, fetching: true };
    case constants.getEventPaginationSuccess:
      return {
        ...state,
        events: payload.data.data,
        fetching: false,
      };
    case constants.getEventPaginationFail:
      return {
        ...state,
        fetching: false,
        errorFetch: true,
        errorMessage: payload,
      };

    case constants.getEventCategoryRequest:
      return { ...state, fetching: true };
    case constants.getEventCategorySuccess:
      return {
        ...state,
        eventCategories: payload,
        fetching: false,
      };
    case constants.getEventCategoryFail:
      return {
        ...state,
        fetching: false,
        errorFetch: true,
        errorMessage: payload,
      };

    case constants.getEventParticipantPaginationRequest:
      return { ...state, fetching: true };
    case constants.getEventParticipantPaginationSuccess:
      return {
        ...state,
        list: payload.data.data,
        fetching: false,
      };
    case constants.getEventParticipantPaginationFail:
      return {
        ...state,
        fetching: false,
        errorFetch: true,
        errorMessage: payload,
      };

    case constants.getEventParticipantDetailsRequest:
      return { ...state, fetching: true };
    case constants.getEventParticipantDetailsSuccess:
      return {
        ...state,
        participants: payload.data.data,
        fetching: false,
      };
    case constants.getEventParticipantDetailsFail:
      return {
        ...state,
        fetching: false,
        errorFetch: true,
        errorMessage: payload,
      };

    case constants.resetParticipantDetails:
      return {
        ...state,
        participants: [],
        fetching: false,
      };

    case constants.getEventStatisticRequest:
      return { ...state, fetching: true };
    case constants.getEventStatisticSuccess:
      return {
        ...state,
        statistics: payload.data,
        fetching: false,
      };
    case constants.getEventStatisticFail:
      return {
        ...state,
        fetching: false,
        errorFetch: true,
        errorMessage: payload,
      };

    case constants.getEventDetailsRequest:
      return { ...state, fetching: true };
    case constants.getEventDetailsSuccess:
      return {
        ...state,
        details: payload.data,
        fetching: false,
      };
    case constants.getEventDetailsFail:
      return {
        ...state,
        fetching: false,
        errorFetch: true,
        errorMessage: payload,
      };

    case constants.setSelfClaimRequest:
      return { ...state, fetching: true, responseMessage: null };
    case constants.setSelfClaimSuccess:
      return {
        ...state,
        responseMessage: payload.messages,
        fetching: false,
      };
    case constants.setSelfClaimFail:
      return { ...state, fetching: false, errorMessage: payload };

    case constants.setDelegateClaimRequest:
      return { ...state, fetching: true, responseMessage: null };
    case constants.setDelegateClaimSuccess:
      return {
        ...state,
        responseMessage: payload.messages,
        fetching: false,
      };
    case constants.setDelegateClaimFail:
      return { ...state, fetching: false, errorMessage: payload };

    case constants.getEventParticipantDataRequest:
      return { ...state, fetching: true };
    case constants.getEventParticipantDataSuccess:
      return {
        ...state,
        participantDetails: payload.data,
        fetching: false,
      };
    case constants.getEventParticipantDataFail:
      return {
        ...state,
        fetching: false,
        errorFetch: true,
        errorMessage: payload,
      };

    case constants.updateParticipantDataRequest:
      return { ...state, fetching: true, responseMessage: null };
    case constants.updateParticipantDataSuccess:
      return {
        ...state,
        responseMessage: payload.messages,
        fetching: false,
      };
    case constants.updateParticipantDataFail:
      return { ...state, fetching: false, errorMessage: payload };

    default:
      return state;
  }
}
