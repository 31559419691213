import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Form } from 'antd';
import { Grid, Breadcrumbs, Link, FormControl } from '@material-ui/core';
import _ from 'lodash';
import { compose } from 'redux';
// Style
import './PopupScreenAddStyle.scss';
import {
  SnackBarSimple,
  ButtonMain,
  UploadImage,
  SelectInputMain,
  InputMain,
  AuthenticationAccessPages,
  PrevStateValue,
  LabelInput,
} from '../../../../components/Index';
// Action
import { getScreenDeeplinkList } from '../../../../services/api/MasterDataApi';
import { createPopupScreen } from '../../../../services/api/PopupScreen';
// Helper
import {
  CommonHelper,
  MasterDataHelper,
  PermissionModule,
  PermissionPage,
  PermissionAccess,
} from '../../../../helpers/Index';

const optionToash = {
  vertical: 'top',
  horizontal: 'right',
};

const resetValidation = { isError: '', errorMessage: '' };

const initialValidation = {
  name: { isError: '', errorMessage: '' },
  starDate: { isError: '', errorMessage: '' },
  endDate: { isError: '', errorMessage: '' },
  description: { isError: '', errorMessage: '' },
  imageBase64: { isError: '', errorMessage: '' },

  deeplink: { isError: '', errorMessage: '' },
  identifier: { isError: '', errorMessage: '' },
  screen: { isError: '', errorMessage: '' },
  webUrl: { isError: '', errorMessage: '' },
};

const deeplinkOptions = MasterDataHelper.deeplinkType;

class PopupScreenAdd extends React.Component {
  constructor(props) {
    super(props);

    props.checkUserAccessPermission(
      PermissionModule.Marketing,
      PermissionPage.PopUpScreen,
      PermissionAccess.Add,
    );

    this.state = {
      toastInformation: {
        isOpen: false,
        message: '',
        isLoading: true,
        snackbarType: 'warning',
      },
      form: {
        name: '',
        deeplink: null,
        deeplinkType: deeplinkOptions[0].value,
        identifier: null,
        subidentifier: null,
        webUrl: null,
        screen: null,
        imageBase64: null,
      },
      validation: initialValidation,
      disabledIdentifier: true,
    };
  }

  componentDidMount() {
    const { getScreenList } = this.props;
    getScreenList();
  }

  handleChangePopupName = value => {
    const { form, validation } = this.state;
    const message = {
      isError: '',
      errorMessage: '',
    };

    if (!value || value === '') {
      message.isError = 'error';
      message.errorMessage = 'Popup Screen Name Empty';
      this.setState({
        form: { ...form, name: '' },
        validation: { ...validation, name: message },
      });
    } else {
      this.setState({
        form: { ...form, name: value },
        validation: { ...validation, name: message },
      });
    }
  };

  handleChangeIdentifier = value => {
    const { form, validation, disabledIdentifier } = this.state;
    const message = {
      isError: '',
      errorMessage: '',
    };

    if (disabledIdentifier === false) {
      if (!value || value === '') {
        message.isError = 'error';
        message.errorMessage = 'Identifier is empty';
        this.setState({
          form: { ...form, identifier: '' },
          validation: { ...validation, identifier: message },
        });
      } else {
        this.setState({
          form: { ...form, identifier: value.trim() },
          validation: { ...validation, identifier: message },
        });
      }
    } else {
      this.setState({
        form: { ...form, identifier: value.trim() },
        validation: { ...validation, identifier: message },
      });
    }
  };

  handleChangeSubIdentifier = value => {
    const { form } = this.state;
    this.setState({
      form: { ...form, subidentifier: value.trim() },
    });
  };

  handleChangeWebUrl = value => {
    const { form, validation } = this.state;
    const message = {
      isError: '',
      errorMessage: '',
    };

    if (!value || value === '') {
      message.isError = 'error';
      message.errorMessage = 'Web URL is empty';
      this.setState({
        form: { ...form, webUrl: '' },
        validation: { ...validation, webUrl: message },
      });
    } else {
      this.setState({
        form: { ...form, webUrl: value },
        validation: { ...validation, webUrl: message },
      });
    }
  };

  handleChangeImage = imageBase64 => {
    const { form, validation } = this.state;
    const message = {
      isError: '',
      errorMessage: '',
    };

    if (!imageBase64 || imageBase64 === '') {
      message.isError = 'error';
      message.errorMessage = 'Please choose Image';
      this.setState({
        form: { ...form, imageBase64: '' },
        validation: { ...validation, imageBase64: message },
      });
    } else {
      this.setState({
        form: { ...form, imageBase64 },
        validation: { ...validation, imageBase64: message },
      });
    }
  };

  handleChangeDeeplinkType = value => {
    const { form } = this.state;
    this.setState({ form: { ...form, deeplinkType: value } });
  };

  handleChangeScreenType = value => {
    const {
      masterData: { screenList },
    } = this.props;
    const { form, validation } = this.state;

    const message = {
      isError: '',
      errorMessage: '',
    };

    if (!value || value === '') {
      message.isError = 'error';
      message.errorMessage = 'Please choose Screen';
      this.setState({
        form: { ...form, screen: '' },
        validation: { ...validation, screen: message },
      });
    } else {
      const selectedScreen = _.find(screenList, ['value', value]);
      this.setState({
        form: { ...form, screen: value, identifier: null, subidentifier: null },
        disabledIdentifier: !selectedScreen.identifier_mandatory,
        validation: { ...validation, screen: message, identifier: message },
      });
    }
  };

  handleCloseToash = () => {
    const { toastInformation } = this.state;
    this.setState({ toastInformation: { ...toastInformation, isOpen: false } });
  };

  handleBack = () => {
    this.props.history.goBack();
  };

  generateDeeplinkValue = () => {
    const {
      form: { deeplinkType, identifier, subidentifier, webUrl, screen },
    } = this.state;
    let deeplink = null;

    if (deeplinkType === 'screen') {
      deeplink = {
        type: deeplinkType,
        screen_name: screen,
        item_id: identifier,
        subitem_id: subidentifier,
      };
    }

    if (deeplinkType === 'webUrl') {
      deeplink = {
        type: deeplinkType,
        url: webUrl,
      };
    }

    return deeplink;
  };

  handleSubmit = () => {
    const { createPopup } = this.props;

    const {
      form: { name, imageBase64 },
    } = this.state;

    const params = {
      data: {
        title: name,
        image: imageBase64,
        deeplink: this.generateDeeplinkValue(),
      },
    };

    if (this.validationForm()) {
      this.setState(
        {
          isLoading: true,
        },
        () => {
          createPopup(params)
            .then(() => {
              const {
                popupScreenData: { responseMessage },
                history,
              } = this.props;

              this.processMessage(responseMessage, 'success');
              this.setState(
                {
                  isLoading: false,
                },
                () => {
                  setTimeout(() => {
                    history.push('/marketing/popup-screen');
                  }, 2000);
                },
              );
            })
            .catch(async error => {
              const message = error.data;
              await this.processMessage(message.messages, 'error');
              await this.setState({ isLoading: false });
            });
        },
      );
    }
  };

  processMessage(messages, type) {
    const paramInformation = {
      isOpen: true,
      message: messages,
      snackbarType: type,
    };

    this.setState({
      toastInformation: paramInformation,
    });
  }

  validationForm() {
    this.setState({
      isLoading: true,
    });

    const { form, validation, disabledIdentifier } = this.state;

    let passCheck = true;
    let paramValidation01 = resetValidation;
    let paramValidation05 = resetValidation;
    let paramValidation06 = resetValidation;
    let paramValidation07 = resetValidation;
    let paramValidation08 = resetValidation;

    if (_.isEmpty(form.name)) {
      paramValidation01 = {
        isError: 'error',
        errorMessage: 'Please enter Popup Name',
      };

      passCheck = false;
    }

    if (form.deeplinkType === 'screen') {
      if (_.isEmpty(form.screen)) {
        paramValidation05 = {
          isError: 'error',
          errorMessage: 'Please enter Screen',
        };

        passCheck = false;
      }

      if (disabledIdentifier === false) {
        if (_.isEmpty(form.identifier)) {
          paramValidation06 = {
            isError: 'error',
            errorMessage: 'Please enter Identifier',
          };

          passCheck = false;
        }
      }
    }

    if (form.deeplinkType === 'webUrl') {
      if (_.isEmpty(form.webUrl)) {
        paramValidation07 = {
          isError: 'error',
          errorMessage: 'Please enter WebUrl',
        };

        passCheck = false;
      }
    }

    if (_.isEmpty(form.imageBase64)) {
      paramValidation08 = {
        isError: 'error',
        errorMessage: 'Please choose Image',
      };

      passCheck = false;
    }

    if (!passCheck) {
      this.setState({
        isLoading: false,
        validation: {
          ...validation,
          name: paramValidation01,
          screen: paramValidation05,
          identifier: paramValidation06,
          webUrl: paramValidation07,
          imageBase64: paramValidation08,
        },
      });
    }

    return passCheck;
  }

  render() {
    const {
      toastInformation,
      form: { name, deeplinkType, screen, webUrl, identifier, subidentifier, imageBase64 },
      disabledIdentifier,
    } = this.state;

    const { masterData } = this.props;

    const screenListRenameKey = CommonHelper.renameKeyName(
      masterData.screenList,
      'display_text',
      'name',
    );

    return (
      <div className="container-page-popup-screen-add scroll-container-invisible">
        <div className="container-scrolling-area">
          <Helmet title="FITCO | Marketing - Create PopUp Screen" />
          <Grid container direction="column">
            <Grid item lg md className="section-page-header">
              <Grid container>
                <Grid item>
                  <div className="breadcrumbs-section">
                    <Breadcrumbs aria-label="breadcrumb">
                      <Link className="text-12" color="inherit" href="/marketing/popup-screen">
                        <i className="icon-slot ic-ffo-master-data" /> Popup Screen
                      </Link>
                      <label className="text-12" color="inherit">
                        Create
                      </label>
                    </Breadcrumbs>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg md className="section-page-body">
              <div className="popup-screen-details-card dotted">
                <Grid container spacing={3}>
                  <label className="text-16 text-bold text-rolling-stone">
                    Create Popup Screen
                  </label>
                </Grid>
                <Grid className="pt-24" container spacing={3}>
                  <Grid container spacing={3}>
                    <Grid item lg={6} md={6}>
                      <FormControl component="fieldset" fullWidth margin={'normal'}>
                        <LabelInput labelText={'Popup Screen Image'} isRequired />
                        <div className="container-image-action">
                          <UploadImage
                            onChange={item => this.handleChangeImage(item)}
                            defaultValue={imageBase64}
                            maxSize={1}
                            validateStatus={this.state.validation.imageBase64.isError}
                            errorMessage={this.state.validation.imageBase64.errorMessage}
                          />
                        </div>
                      </FormControl>
                    </Grid>
                    <Grid item lg={6} md={6}>
                      <Grid item>
                        <FormControl component="fieldset" fullWidth margin={'normal'}>
                          <LabelInput labelText={'Pupup Name'} isRequired />
                          <InputMain
                            placeholder={'Popup Name'}
                            value={name}
                            onChange={this.handleChangePopupName}
                            validateStatus={this.state.validation.name.isError}
                            errorMessage={this.state.validation.name.errorMessage}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item>
                        <FormControl component="fieldset" fullWidth margin={'normal'}>
                          <LabelInput labelText={'Deeplink Type'} additionalText={'( Optional )'} />
                          <Form.Item style={{ marginBottom: 8 }}>
                            <SelectInputMain
                              options={deeplinkOptions}
                              size="large"
                              currentValue={deeplinkType}
                              onChange={this.handleChangeDeeplinkType}
                            />
                          </Form.Item>
                        </FormControl>
                      </Grid>
                      {deeplinkType === 'screen' && (
                        <>
                          <Grid item>
                            <FormControl component="fieldset" fullWidth margin={'normal'}>
                              <LabelInput labelText={'Screen Name'} isRequired />
                              <Form.Item style={{ marginBottom: 8 }}>
                                <SelectInputMain
                                  options={screenListRenameKey}
                                  size="large"
                                  defaultValue={screen}
                                  onChange={this.handleChangeScreenType}
                                  validateStatus={this.state.validation.screen.isError}
                                  errorMessage={this.state.validation.screen.errorMessage}
                                />
                              </Form.Item>
                            </FormControl>
                          </Grid>
                          <Grid item>
                            <FormControl component="fieldset" fullWidth margin={'normal'}>
                              <LabelInput labelText={'Identifier'} isRequired />
                              <Form.Item style={{ marginBottom: 8 }}>
                                <InputMain
                                  placeholder={'Identifier'}
                                  value={identifier}
                                  onChange={this.handleChangeIdentifier}
                                  validateStatus={this.state.validation.identifier.isError}
                                  errorMessage={this.state.validation.identifier.errorMessage}
                                  isNumber
                                  disabled={disabledIdentifier}
                                />
                              </Form.Item>
                            </FormControl>
                          </Grid>
                          <Grid item>
                            <FormControl component="fieldset" fullWidth margin={'normal'}>
                              <LabelInput labelText={'Sub-identifier'} isRequired />
                              <Form.Item style={{ marginBottom: 8 }}>
                                <InputMain
                                  placeholder={'Sub-identifier'}
                                  value={subidentifier}
                                  onChange={this.handleChangeSubIdentifier}
                                  isNumber
                                  disabled={disabledIdentifier}
                                />
                              </Form.Item>
                            </FormControl>
                          </Grid>
                        </>
                      )}
                      {deeplinkType === 'webUrl' && (
                        <Grid item>
                          <FormControl component="fieldset" fullWidth margin={'normal'}>
                            <LabelInput labelText={'Web URL'} isRequired />
                            <Form.Item style={{ marginBottom: 8 }}>
                              <InputMain
                                placeholder={'Web URL'}
                                value={webUrl}
                                onChange={this.handleChangeWebUrl}
                                validateStatus={this.state.validation.webUrl.isError}
                                errorMessage={this.state.validation.webUrl.errorMessage}
                              />
                            </Form.Item>
                          </FormControl>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid item lg md className="section-page-footer">
              <Grid container justify="flex-end" spacing={3}>
                <Grid item lg={2} md={2}>
                  <ButtonMain type="ghost" size="xl" labelText="Cancel" onClick={this.handleBack} />
                </Grid>
                <Grid item lg={2} md={2}>
                  <ButtonMain
                    type="primary"
                    s
                    size="xl"
                    labelText="Create"
                    onClick={this.handleSubmit}
                    isLoading={this.state.isLoading}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <SnackBarSimple
            open={toastInformation.isOpen}
            durationHide={2000}
            message={toastInformation.message}
            onClickClose={this.handleCloseToash}
            snackbarType={toastInformation.snackbarType}
            anchor={optionToash}
          />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getScreenList: () => getScreenDeeplinkList(dispatch),
  createPopup: params => createPopupScreen(dispatch, params),
});

const mapStateToProps = ({ masterData, popupScreenData }) => ({
  masterData,
  popupScreenData,
});

PopupScreenAdd.propTypes = {
  checkUserAccessPermission: PropTypes.func,
  createPopup: PropTypes.func,
  getScreenList: PropTypes.func,
  history: PropTypes.object,
  masterData: PropTypes.object,
  popupScreenData: PropTypes.object,
};

const shell = compose(connect(mapStateToProps, mapDispatchToProps));
const core = compose(AuthenticationAccessPages, PrevStateValue);

export default shell(core(PopupScreenAdd));
