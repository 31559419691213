import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import _ from 'lodash';
import { Grid } from '@material-ui/core';
import { compose } from 'redux';
// style
import './SchedulePagesStyle.scss';
// Component
import {
  HeaderSchedule,
  CalendarSummary,
  ModalScheduleDetailClass,
  ModalScheduleDetailCoach,
  ModalScheduleDetailEms,
  ModalRescheduleEms,
} from './components/Index';
import {
  TextInput,
  AuthenticationAccessPages,
  SnackBarSimple,
  SkeletonMain,
  SelectInputGeneral,
  ButtonIconMain,
  SelectInputBranch,
} from '../../../../components/Index';
// api
import { getScheduleByDate } from '../../../../services/api/ScheduleDataApi';
import {
  getWorkoutCategoryTab,
  getWorkoutCategoryProduct,
  getWorkoutCategoryBranch,
  getWorkoutCategoryStatus,
} from '../../../../services/api/MasterDataApi';
import * as Actions from '../../../../redux/actions/ScheduleAction';
// helper
import { CalendarHelper, CommonHelper, MoveHelper } from '../../../../helpers/Index';

const currentDate = CommonHelper.currentDate('YYYY-MM-DD');

const initialFilter = {
  productId: null,
  search: null,
  merchantId: null,
  branchId: null,
  timeRangeStartWeek: null,
  timeRangeEndWeek: null,
  category: null,
  dayValue: null,
  weekDayList: null,
  status: null,
};

const optionToast = {
  vertical: 'top',
  horizontal: 'right',
};

class SchedulePages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: CommonHelper.objectCloning(initialFilter),
      selectedModalItemId: null,
      selectedModalItem: null,
      isOpenModal: false,
      dataTabs: [],
      tabsValue: 0,
      isLoading: true,
      limit: 10,
      toastInformation: {
        isOpen: false,
        message: '',
        snackbarType: 'warning',
      },
      isOpenModalReschedule: false,
      selectedScheduleDetail: {},
    };
    this.searchDebounce = _.debounce(this.searchDebounce, 400);
  }

  componentDidMount() {
    this.getCategoryTab();
  }

  setDefaultCard() {
    const { dataTabs, tabsValue } = this.state;
    const { setCurrentAddressTypeOption } = this.props;

    setCurrentAddressTypeOption(dataTabs[tabsValue].name);
  }

  getCategoryTab = async () => {
    const { categoryTab } = this.props;
    const { tabsValue } = this.state;
    try {
      const { data } = await categoryTab();
      const converted = [];
      data.forEach(item => {
        const param = { value: item.workout_category, name: item.description };
        converted.push(param);
      });
      this.setState({ dataTabs: converted }, async () => {
        await this.getWorkoutMasterData();
        this.handleTabChange(tabsValue);
      });
    } catch (error) {
      const serverMessage = error.data;
      this.processMessage(serverMessage.messages, 'error');
    }
  };

  getProductByTab = async () => {
    const { categoryProduct } = this.props;
    const { dataTabs, tabsValue } = this.state;
    try {
      const category = dataTabs[tabsValue].value;
      await categoryProduct(category);
    } catch (error) {
      const serverMessage = error.data;
      this.processMessage(serverMessage.messages, 'error');
    }
  };

  getBranchByTab = async () => {
    const { categoryBranch } = this.props;
    const { dataTabs, tabsValue } = this.state;
    try {
      const category = dataTabs[tabsValue].value;
      await categoryBranch(category);
    } catch (error) {
      const serverMessage = error.data;
      this.processMessage(serverMessage.messages, 'error');
    }
  };

  getStatusByTab = async () => {
    const { categoryStatus } = this.props;
    try {
      await categoryStatus();
    } catch (error) {
      const serverMessage = error.data;
      this.processMessage(serverMessage.messages, 'error');
    }
  };

  getWorkoutMasterData = async () => {
    await this.getProductByTab();
    await this.getStatusByTab();
    await this.getBranchByTab();
  };

  getScheduleByDate(selectedDate, indexParent, page = 1, limit = 8) {
    const { scheduleByDate } = this.props;
    const { dataTabs, tabsValue, filter } = this.state;
    const category = dataTabs[tabsValue].value;

    const param = {
      product_id: filter.productId,
      date: selectedDate,
      search_term: filter.search,
      branch_location: filter.branchId,
      schedule_status: filter.status,
      page,
      limit,
      sortBy: '',
      order: 'asc',
    };

    scheduleByDate(category, param, indexParent).then(() => {
      this.setState({ isLoading: false });
    });
  }

  getCalendarDataList(weekDayList) {
    const { filter, limit } = this.state;
    const {
      scheduleSummary: {
        fetching01,
        fetching02,
        fetching03,
        fetching04,
        fetching05,
        fetching06,
        fetching07,
      },
    } = this.props;

    if (!weekDayList) {
      const weekDay = CalendarHelper.getWeekDayByToday(this.state.filter.timeRangeStartWeek);
      const listHeaderTimeLine = CalendarHelper.generateListWeek(weekDay);
      weekDayList = CalendarHelper.generateCalenderHeaderData(listHeaderTimeLine);
    }

    const allDateFetching =
      !fetching01 &&
      !fetching02 &&
      !fetching03 &&
      !fetching04 &&
      !fetching05 &&
      !fetching06 &&
      !fetching07;

    if (allDateFetching && filter.merchantId && weekDayList) {
      this.setState(
        {
          weekDayList,
        },
        () => {
          weekDayList.forEach((item, index) => {
            this.getScheduleByDate(item.value, index + 1, undefined, limit);
          });
        },
      );
    }
  }

  handleGetWorkoutMasterData = value => {
    this.setState(
      {
        tabsValue: value,
        isLoading: true,
      },
      async () => {
        await this.getWorkoutMasterData();
        this.handleTabChange(value);
      },
    );
  };

  searchDebounce = () => {
    this.setDefaultCard();
    this.getCalendarDataList(null);
  };

  handleReloadColumn = index => {
    const { weekDayList, limit } = this.state;
    const {
      scheduleSummary: {
        fetching01,
        fetching02,
        fetching03,
        fetching04,
        fetching05,
        fetching06,
        fetching07,
        currentPage01,
        currentPage02,
        currentPage03,
        currentPage04,
        currentPage05,
        currentPage06,
        currentPage07,
      },
    } = this.props;
    let checkPass;
    let pages = 0;

    switch (index) {
      case 1:
        checkPass = !fetching01;
        pages = currentPage01 + 1;
        break;
      case 2:
        checkPass = !fetching02;
        pages = currentPage02 + 1;
        break;
      case 3:
        checkPass = !fetching03;
        pages = currentPage03 + 1;
        break;
      case 4:
        checkPass = !fetching04;
        pages = currentPage04 + 1;
        break;
      case 5:
        checkPass = !fetching05;
        pages = currentPage05 + 1;
        break;
      case 6:
        checkPass = !fetching06;
        pages = currentPage06 + 1;
        break;

      default:
        checkPass = !fetching07;
        pages = currentPage07 + 1;
        break;
    }

    if (checkPass) {
      this.getScheduleByDate(weekDayList[index - 1].value, index, pages, limit);
    }
  };

  handleCloseToast = () => {
    const { toastInformation } = this.state;
    this.setState({ toastInformation: { ...toastInformation, isOpen: false } });
  };

  handleSearch = value => {
    const { filter } = this.state;
    this.setState({ filter: { ...filter, search: value } }, () => {
      this.searchDebounce();
    });
  };

  handleSelectClass = value => {
    const { filter } = this.state;
    this.setDefaultCard();
    this.setState(
      {
        filter: { ...filter, productId: value || null },
      },
      () => {
        this.getCalendarDataList(null);
      },
    );
  };

  handleSelectBranch = value => {
    const { filter } = this.state;

    this.setDefaultCard();
    this.setState(
      {
        filter: { ...filter, branchId: value || null },
      },
      () => {
        this.getCalendarDataList(null);
      },
    );
  };

  handleSelectProduct = value => {
    const { filter } = this.state;

    this.setDefaultCard();
    this.setState(
      {
        filter: { ...filter, productId: value || null },
      },
      () => {
        this.getCalendarDataList(null);
      },
    );
  };

  handleSelectStatus = value => {
    const { filter } = this.state;

    this.setDefaultCard();
    this.setState(
      {
        filter: { ...filter, status: value || null },
      },
      () => {
        this.getCalendarDataList(null);
      },
    );
  };

  handleSelectDayRange = value => {
    const { filter } = this.state;
    this.setState({ filter: { ...filter, dayValue: value } });
  };

  handleTabChange = value => {
    const { masterData, usersReducer } = this.props;
    const { dataTabs } = this.state;

    const weekDay = CalendarHelper.getWeekDayByToday(currentDate);
    const listHeaderTimeLine = CalendarHelper.generateListWeek(weekDay);
    const converted = CalendarHelper.generateCalenderHeaderData(listHeaderTimeLine);
    // const currentBranch =
    //   !_.isEmpty(masterData.workoutCategoryBranch) &&
    //   dataTabs[tabsValue] !== dataTabs[3] &&
    //   dataTabs[tabsValue] !== dataTabs[4]
    //     ? masterData.workoutCategoryBranch[0].branch_id
    //     : null;
    // const currentProduct =
    //   !_.isEmpty(masterData.workoutCategoryProduct) &&
    //   dataTabs[tabsValue] !== dataTabs[3] &&
    //   dataTabs[tabsValue] !== dataTabs[4]
    //     ? masterData.workoutCategoryProduct[0].product_id
    //     : null;
    const currentStatus = !_.isEmpty(masterData.workoutCategoryStatus)
      ? masterData.workoutCategoryStatus[1].schedule_status
      : null;

    const initialFilterFinal = {
      ...CommonHelper.objectCloning(initialFilter),
      merchantId: usersReducer.activeMerchant,
      branchId: null,
      productId: null,
      category: dataTabs[value].value,
      timeRangeStartWeek: weekDay.startWeek,
      timeRangeEndWeek: weekDay.endWeek,
      status: currentStatus,
    };

    this.setDefaultCard();
    this.setState(
      {
        tabsValue: value,
        filter: initialFilterFinal,
        isLoading: true,
        weekDayList: converted,
      },
      () => {
        this.getCalendarDataList(converted);
      },
    );
  };

  handleButtonAddClick = () => {
    const { history } = this.props;

    history.push('/move/workout-schedule/add');
  };

  handleCalendarHeaderClick = value => {
    const { filter } = this.state;
    this.setDefaultCard();
    this.setState({ filter: { ...filter, timeRangeStartWeek: value[0].value } }, () => {
      this.getCalendarDataList(value);
    });
  };

  handleScheduleCardClick = item => {
    if (item.scheduleId) {
      this.setState({
        isOpenModal: true,
        selectedModalItemId: item.scheduleId,
        selectedModalItem: item,
      });
    }
  };

  handleScheduleClickBook = itemValue => {
    const { history } = this.props;
    const { dataTabs, tabsValue } = this.state;
    const category = dataTabs[tabsValue].value;
    let param = {
      categoryType: category,
    };
    let checkPass = false;

    if (itemValue) {
      param = {
        ...param,
        scheduleId: itemValue.scheduleId,
        items: [{ ...itemValue.option, qty: 1 }],
      };
    }

    if (dataTabs[tabsValue] === dataTabs[3]) {
      if (itemValue) {
        param = {
          ...param,
          scheduleId: itemValue.scheduleId,
          items: [{ ...itemValue.option, qty: 1 }],
        };
      } else {
        param = {
          ...param,
        };
      }
      checkPass = true;
    } else {
      checkPass = true;
    }

    if (checkPass) {
      const paramFilter = CommonHelper.encryptObject(param);
      history.push(`/move/workout-schedule/book/${paramFilter}`);
    } else {
      this.processMessage('This Product Required district, made district filter first', 'warning');
    }
  };

  handleScheduleCardClose = value => {
    this.setDefaultCard();
    this.setState({ isOpenModal: value }, () => {
      this.getCalendarDataList(null);
    });
  };

  handleCloseRescheduleModal = () => {
    this.setState({ isOpenModalReschedule: false, isOpenModal: true, selectedScheduleDetail: {} });
  };

  handleSuccessRescheduleTime = messages => {
    this.setState({ isOpenModalReschedule: false, selectedScheduleDetail: {} });
    this.processMessage(messages, 'success');
    this.handleScheduleCardClose();
  };

  handleOpenRescheduleModal = item => {
    this.setState({
      isOpenModalReschedule: true,
      isOpenModal: false,
      selectedScheduleDetail: item,
    });
  };

  processMessage(messages, type) {
    const convertedMessage = CommonHelper.generateMessage(messages);

    const paramInformation = {
      isOpen: true,
      message: convertedMessage,
      snackbarType: type,
    };

    this.setState({
      toastInformation: paramInformation,
    });
  }

  renderRightFilter = () => {
    const { tabsValue } = this.state;
    return (
      <Grid container direction="row">
        <Grid item lg md className="container-header-button">
          <ButtonIconMain
            icon="ic-ffo-refresh"
            type="ghost"
            size="md"
            onClick={() => this.handleTabChange(tabsValue)}
          />
        </Grid>
      </Grid>
    );
  };

  renderFilter() {
    const {
      masterData: { workoutCategoryProduct, workoutCategoryStatus },
    } = this.props;
    const { filter, dataTabs } = this.state;

    const selectedTab = dataTabs[this.state.tabsValue].value;
    const renderBranchFilter =
      selectedTab === MoveHelper.WorkoutCategory.GymAccess ||
      selectedTab === MoveHelper.WorkoutCategory.Classes ||
      selectedTab === MoveHelper.WorkoutCategory.PTatFITCO;
    const renderProductFilter =
      selectedTab === MoveHelper.WorkoutCategory.Classes ||
      selectedTab === MoveHelper.WorkoutCategory.PTatHome ||
      selectedTab === MoveHelper.WorkoutCategory.PTOnline;

    const isPTAtHomeOrOnline =
      selectedTab === MoveHelper.WorkoutCategory.PTatHome ||
      selectedTab === MoveHelper.WorkoutCategory.PTOnline;

    return (
      <Grid container direction="row" justify="space-between" alignItems="center">
        <Grid item lg md>
          <Grid container direction="row">
            {renderBranchFilter && (
              <Grid item md={3} className="mr-12">
                <div className="container-remove-margin">
                  <SelectInputBranch
                    placeHolder="Select Branch"
                    onChange={this.handleSelectBranch}
                    currentValue={filter.branchId}
                    filter={filter}
                  />
                </div>
              </Grid>
            )}

            {renderProductFilter && (
              <Grid item lmd={isPTAtHomeOrOnline ? 5 : 2} className="mr-12">
                <div className="container-remove-margin">
                  <SelectInputGeneral
                    placeHolder="Select Product"
                    currentValue={filter.productId}
                    dataList={workoutCategoryProduct}
                    primaryKey="product_id"
                    onChange={this.handleSelectProduct}
                  />
                </div>
              </Grid>
            )}

            <Grid item md={2} className="mr-12">
              <div className="container-remove-margin">
                <SelectInputGeneral
                  placeHolder="Select Status"
                  currentValue={filter.status}
                  dataList={workoutCategoryStatus}
                  primaryKey="schedule_status"
                  onChange={this.handleSelectStatus}
                />
              </div>
            </Grid>

            <Grid item md={4}>
              <div className="container-remove-margin">
                <TextInput
                  iconPrefix="ic-ffo-search"
                  placeHolderText={
                    selectedTab === MoveHelper.WorkoutCategory.GymAccess
                      ? 'Search Member'
                      : 'Search Member and/or Coach'
                  }
                  onBlur={this.handleSearch}
                  size="md"
                />
              </div>
            </Grid>
          </Grid>
        </Grid>

        <Grid item lg={1} md={2}>
          {this.renderRightFilter()}
        </Grid>
      </Grid>
    );
  }

  renderModalDetail() {
    const { isOpenModal, selectedModalItemId, tabsValue, dataTabs, selectedModalItem } = this.state;
    // let elementRender = null;

    if (!isOpenModal) {
      return null;
    }

    const selectedTab = dataTabs[tabsValue].value;
    let elementRender = null;

    switch (selectedTab) {
      case MoveHelper.WorkoutCategory.PTOnline:
      case MoveHelper.WorkoutCategory.PTatHome:
        elementRender = (
          <ModalScheduleDetailCoach
            isOpen={isOpenModal}
            scheduleId={selectedModalItemId}
            onClose={this.handleScheduleCardClose}
            onClickBook={() => this.handleScheduleClickBook(selectedModalItem)}
            onClickReschedule={this.handleOpenRescheduleModal}
          />
        );
        break;
      case MoveHelper.WorkoutCategory.PTatFITCO:
        elementRender = (
          <ModalScheduleDetailEms
            isOpen={isOpenModal}
            scheduleId={selectedModalItemId}
            onClose={this.handleScheduleCardClose}
            onClickBook={() => this.handleScheduleClickBook(selectedModalItem)}
            onClickReschedule={this.handleOpenRescheduleModal}
          />
        );
        break;
      case MoveHelper.WorkoutCategory.GymAccess:
      case MoveHelper.WorkoutCategory.Classes:
      default:
        elementRender = (
          <ModalScheduleDetailClass
            isOpen={isOpenModal}
            scheduleId={selectedModalItemId}
            onClose={this.handleScheduleCardClose}
            onClickBook={() => this.handleScheduleClickBook(selectedModalItem)}
            onClickReschedule={this.handleOpenRescheduleModal}
          />
        );
        break;
    }

    return elementRender;
  }

  renderHeaderTab = () => {
    const { dataTabs } = this.state;
    return (
      <HeaderSchedule
        data={dataTabs}
        currentTab={this.state.tabsValue}
        onChangeTab={this.handleGetWorkoutMasterData}
        onButtonClick={this.handleButtonAddClick}
        onButtonClickBook={this.handleScheduleClickBook}
      />
    );
  };

  render() {
    const { scheduleSummary } = this.props;
    const {
      isLoading,
      toastInformation,
      dataTabs,
      isOpenModalReschedule,
      selectedScheduleDetail,
      tabsValue,
    } = this.state;
    let elementRender = <SkeletonMain />;

    const activeTabs = dataTabs[tabsValue];

    if (!isLoading) {
      elementRender = (
        <div>
          <Helmet title="FITCO | Move - Schedules" />
          {!_.isEmpty(dataTabs) && this.renderHeaderTab()}
          <div className="container-page-schedule">
            <div className="container-page-scrolling-area include-tab">
              <Grid container direction="column">
                <Grid item lg md className="section-page-header">
                  {this.renderFilter()}
                </Grid>
                <Grid item lg md className="section-page-body">
                  <CalendarSummary
                    onCardClick={this.handleScheduleCardClick}
                    onClickBook={this.handleScheduleClickBook}
                    onButtonHeaderClick={this.handleCalendarHeaderClick}
                    data={scheduleSummary}
                    onReloadColumn={this.handleReloadColumn}
                  />
                </Grid>
                <Grid item lg md className="section-page-footer">
                  {this.renderModalDetail()}
                </Grid>
                <Grid item lg md className="section-page-footer">
                  <ModalRescheduleEms
                    isOpen={isOpenModalReschedule}
                    onClose={this.handleCloseRescheduleModal}
                    selectedScheduleDetail={selectedScheduleDetail}
                    onSuccessReschedule={this.handleSuccessRescheduleTime}
                    categoryType={activeTabs && activeTabs.value}
                  />
                </Grid>
              </Grid>
            </div>
          </div>
          <SnackBarSimple
            open={toastInformation.isOpen}
            durationHide={2000}
            message={toastInformation.message}
            onClickClose={this.handleCloseToast}
            snackbarType={toastInformation.snackbarType}
            anchor={optionToast}
          />
        </div>
      );
    }

    return elementRender;
  }
}

const mapDispatchToProps = dispatch => ({
  scheduleByDate: (category, params, indexParent) =>
    getScheduleByDate(dispatch, category, params, indexParent),
  setCurrentAddressTypeOption: params => dispatch(Actions.setCurrentScheduleTabActive(params)),
  categoryTab: () => getWorkoutCategoryTab(dispatch),
  categoryProduct: category => getWorkoutCategoryProduct(dispatch, category),
  categoryBranch: category => getWorkoutCategoryBranch(dispatch, category),
  categoryStatus: () => getWorkoutCategoryStatus(dispatch),
});

const mapStateToProps = ({ scheduleSummary, masterData, usersReducer }) => ({
  scheduleSummary,
  masterData,
  usersReducer,
});

SchedulePages.propTypes = {
  categoryBranch: PropTypes.func,
  categoryProduct: PropTypes.func,
  categoryStatus: PropTypes.func,
  categoryTab: PropTypes.func,
  history: PropTypes.object,
  masterData: PropTypes.object,
  scheduleByDate: PropTypes.func,
  scheduleSummary: PropTypes.object,
  setCurrentAddressTypeOption: PropTypes.func,
  usersReducer: PropTypes.object,
};

const shell = compose(connect(mapStateToProps, mapDispatchToProps));

export default shell(AuthenticationAccessPages(SchedulePages));
