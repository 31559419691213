/* eslint-disable react/sort-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Grid, IconButton } from '@material-ui/core';
import _ from 'lodash';
import { connect } from 'react-redux';
// api
import { getOrderShipmentTrackingDetails } from '../../../../../services/api/OrdersApi';
// style
import './ModalShipmentTrackingStyle.scss';
// component
import {
  ModalInformationPopUp,
  SnackBarSimple,
  GridRowTableOneColumn,
} from '../../../../../components/Index';
// helper
import { CommonHelper, HttpStatusCode } from '../../../../../helpers/Index';

const optionToash = {
  vertical: 'top',
  horizontal: 'right',
};

class ModalShipmentTracking extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      toastInformation: {
        isOpen: false,
        message: '',
        snackbarType: 'warning',
      },
    };
  }

  componentDidMount() {
    const { orderId } = this.props;

    if (orderId) {
      this.getTrackingDetailsById(orderId);
    }
  }

  componentWillUnmount() {}

  getTrackingDetailsById(selectedId) {
    const { orderShipmentTrackingDetails } = this.props;
    const param = {
      sales_invoice_id: selectedId,
    };

    this.setState({ isLoading: true }, () => {
      orderShipmentTrackingDetails(param)
        .then(() => {
          this.setState({
            isLoading: false,
          });
        })
        .catch(async error => {
          const serverMessage = error.data;
          const validationStatus = error.status === HttpStatusCode.InternalServerError;
          this.processMessage(
            validationStatus ? serverMessage.message : serverMessage.messages,
            'error',
          );
        });
    });
  }

  handleClose = () => {
    const { onClose } = this.props;
    onClose(false);
  };

  handleCloseToash = () => {
    const { toastInformation } = this.state;
    this.setState({ toastInformation: { ...toastInformation, isOpen: false } });
  };

  processMessage(messages, type) {
    const convertedMessage = CommonHelper.generateMessage(messages);

    const paramInformation = {
      isOpen: true,
      message: convertedMessage,
      snackbarType: type,
    };

    this.setState({
      toastInformation: paramInformation,
    });
  }

  renderHistory = dataArray => {
    return (
      <Grid container direction="column" className="scroll-container section-2 flex-wrap-unset">
        {dataArray.map((item, index) => (
          <GridRowTableOneColumn
            key={index}
            customElementInitial={
              <label className="text-14 text-rolling-stone">{item.timestamp || '-'}</label>
            }
            customElementColumn={<label className="text-14">{item.description || '-'}</label>}
            columnLeft={4}
            columnRight={8}
          />
        ))}
      </Grid>
    );
  };

  render() {
    const { isLoading, toastInformation } = this.state;
    const { isOpen, orderData } = this.props;

    let renderElement = null;

    if (!isLoading && !_.isEmpty(orderData.orderTrackingDetails)) {
      const details = orderData.orderTrackingDetails;
      renderElement = (
        <Grid container direction="column" className="flex-wrap-unset">
          <Grid item lg md className="section-header-modal-tracking">
            <Grid container justify="flex-start" alignItems="center" className="flex-wrap-unset">
              <Grid item lg={11} md={11}>
                <label className="text-18 wrapping-container">Order Tracking</label>
              </Grid>
              <Grid item>
                <Grid container justify="flex-end">
                  <Grid item>
                    <div className="container-button-close">
                      <IconButton onClick={this.handleClose}>
                        <i className="ic-ffo-close custom-icon-close" />
                      </IconButton>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item className="section-body-modal-tracking">
            <Grid item lg md>
              <Grid container justify="flex-start" className="flex-wrap-unset section-1 mb-32">
                <Grid item lg={9} md={9}>
                  <Grid container>
                    <Grid item lg={4} md={4}>
                      <div className="flex-column">
                        <label className="text-12">Order Number</label>
                        <label className="text-12">{details.order_no || '-'}</label>
                      </div>
                    </Grid>
                    <Grid item lg={4} md={4}>
                      <div className="flex-column">
                        <label className="text-12">Airway Bill Number</label>
                        <label className="text-12">
                          {details.tracking_number || 'Not Available'}
                        </label>
                      </div>
                    </Grid>
                    <Grid item lg={4} md={4}>
                      <div className="flex-column">
                        <label className="text-12">Shipment Date</label>
                        <label className="text-12">
                          {details.shipment_date
                            ? CommonHelper.dateTimeParseNewFormat(
                                details.shipment_date,
                                'DD-MMM-YYYY | HH:mm',
                              )
                            : '-'}
                        </label>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg md className="pb-16">
              <label className="text-14 history-title">History Tracking Shipment</label>
            </Grid>
            <Grid item lg md>
              {this.renderHistory(details.history)}
            </Grid>
          </Grid>
          <Grid item className="section-footer-modal-tracking" />
          <SnackBarSimple
            open={toastInformation.isOpen}
            durationHide={2000}
            message={toastInformation.message}
            onClickClose={this.handleCloseToash}
            snackbarType={toastInformation.snackbarType}
            anchor={optionToash}
          />
        </Grid>
      );
    }

    return (
      <ModalInformationPopUp
        isOpen={isOpen}
        onClose={this.handleClose}
        size="medium"
        customElementProps={renderElement}
      />
    );
  }
}

const mapDispatchToProps = dispatch => ({
  orderShipmentTrackingDetails: params => getOrderShipmentTrackingDetails(dispatch, params),
});

const mapStateToProps = ({ orderData }) => ({ orderData });

ModalShipmentTracking.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  orderId: PropTypes.number,
  orderData: PropTypes.object,
  orderShipmentTrackingDetails: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalShipmentTracking);
