import React from 'react';
import PropTypes from 'prop-types';
import { Grid, IconButton } from '@material-ui/core';
import { connect } from 'react-redux';
// style
import './ModalAssignPermissionStyle.scss';
// component
import {
  ModalInformationPopUp,
  ButtonMain,
  ChipForm,
  SelectInputPermission,
  SnackBarSimple,
} from '../../../../../../components/Index';
// api
import { setPermissionToRole } from '../../../../../../services/api/UserManagement';
// helper
import { CommonHelper, PermissionHelper } from '../../../../../../helpers/Index';

const initialFilter = {
  moduleName: null,
  roleId: null,
};

const optionToash = {
  vertical: 'top',
  horizontal: 'right',
};

class ModalAssignPermission extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      list: [],
      filter: initialFilter,
      toastInformation: {
        isOpen: false,
        message: '',
        snackbarType: 'warning',
      },
    };
  }

  componentDidMount() {
    const { filter } = this.state;
    const { currentRoleId, moduleName } = this.props;

    this.setState({ filter: { ...filter, roleId: currentRoleId, moduleName } });
  }

  componentWillUnmount() {}

  handleSelectPermission = value => {
    this.processItemToListedPermission(value);
  };

  handleButtonClickSave = () => {
    const { onClose, permissionToRole, currentRoleId } = this.props;
    const { list } = this.state;
    if (list.length > 0) {
      const listAssigned = PermissionHelper.generateAssignNewPermissionRole(list);
      const param = {
        data: {
          role_id: currentRoleId,
          name: listAssigned,
        },
      };

      this.setState({ isLoading: true }, () => {
        permissionToRole(param)
          .then(async response => {
            const message = response.messages;
            this.processMessage(message, 'success');

            setTimeout(async () => {
              await this.setState({ isLoading: false });
              await onClose();
            }, 2000);
          })
          .catch(error => {
            const message = error.data;
            this.processMessage(message.messages, 'error');
          });
      });
    } else {
      this.processMessage('Add Some Permission First', 'warning');
    }
  };

  handleButtonClickDeleteSelection = value => {
    const { list } = this.state;
    const indexCheck = list.findIndex(item => item.value === value.value);

    if (indexCheck !== -1) {
      list.splice(indexCheck, 1);
      this.setState({ list });
    }
  };

  handleCloseToash = () => {
    const { toastInformation } = this.state;
    this.setState({ toastInformation: { ...toastInformation, isOpen: false } });
  };

  processItemToListedPermission(selectedItem) {
    const { list } = this.state;
    const { masterData } = this.props;
    const currentList = masterData.permissionList;

    const indexCheck = list.findIndex(item => item.value === selectedItem);

    if (indexCheck === -1) {
      const index = currentList.findIndex(item => item.value === selectedItem);
      const param = currentList[index];

      this.setState({ list: [...list, param] });
    } else {
      this.processMessage('Permission Already Assigned', 'warning');
    }
  }

  processMessage(messages, type) {
    const convertedMessage = CommonHelper.generateMessage(messages);

    const paramInformation = {
      isOpen: true,
      message: convertedMessage,
      snackbarType: type,
    };

    this.setState({
      toastInformation: paramInformation,
    });
  }

  renderModalBody() {
    const { modalTitle, onClose } = this.props;
    const { isLoading, list, filter, toastInformation } = this.state;
    let renderElement = null;

    renderElement = (
      <Grid container direction="column">
        <Grid item lg md className="section-header-modal-action">
          <Grid container>
            <Grid item lg={11} md={11}>
              <label className="text-18 wrapping-container">{modalTitle}</label>
            </Grid>
            <Grid item>
              <Grid container direction="column" justify="flex-end">
                <Grid item>
                  <div className="container-button-close">
                    <IconButton onClick={onClose}>
                      <i className="ic-ffo-close container-icon-prefix size-16" />
                    </IconButton>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item className="section-body-modal-action">
          <Grid container direction="column">
            <Grid item lg md className="row-modal">
              <SelectInputPermission
                placeHolder="Select Permission"
                onChange={this.handleSelectPermission}
                filter={filter}
              />
            </Grid>
            <Grid item lg md className="row-modal">
              <div className="scroll-container-invisible main-card dotted">
                {list.length > 0 ? (
                  <ChipForm
                    data={list}
                    onButtonClickDelete={this.handleButtonClickDeleteSelection}
                  />
                ) : (
                  <label className="text-12">There is no Permission Listed.</label>
                )}
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg md>
          <div className="divider-hr" />
        </Grid>
        <Grid item lg md className="section-footer-modal-action">
          <Grid container justify="flex-end">
            <Grid item lg={5} md={5}>
              <ButtonMain
                labelText="Save"
                onClick={this.handleButtonClickSave}
                type="primary"
                isLoading={isLoading}
                size="xl"
              />
            </Grid>
          </Grid>
        </Grid>
        <SnackBarSimple
          open={toastInformation.isOpen}
          durationHide={2000}
          message={toastInformation.message}
          onClickClose={this.handleCloseToash}
          snackbarType={toastInformation.snackbarType}
          anchor={optionToash}
        />
      </Grid>
    );

    return renderElement;
  }

  render() {
    const { isOpen, onClose } = this.props;

    return (
      <ModalInformationPopUp
        isOpen={isOpen}
        onClose={onClose}
        customElementProps={this.renderModalBody()}
      />
    );
  }
}

const mapDispatchToProps = dispatch => ({
  permissionToRole: params => setPermissionToRole(dispatch, params),
});

const mapStateToProps = ({ masterData }) => ({ masterData });

ModalAssignPermission.propTypes = {
  currentRoleId: PropTypes.number,
  isOpen: PropTypes.bool,
  masterData: PropTypes.object,
  modalTitle: PropTypes.string,
  moduleName: PropTypes.string,
  onClose: PropTypes.func,
  permissionToRole: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalAssignPermission);
