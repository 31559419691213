export const constants = {
  getTncListRequest: 'GET_TNC_LIST_REQUEST',
  getTncListSuccess: 'GET_TNC_LIST_SUCCESS',
  getTncListFail: 'GET_TNC_LIST_FAIL',
  getTncDetailsRequest: 'GET_TNC_DETAILS_REQUEST',
  getTncDetailsSuccess: 'GET_TNC_DETAILS_SUCCESS',
  getTncDetailsFail: 'GET_TNC_DETAILS_FAIL',
  createTncSectionRequest: 'CREATE_TNC_SECTION_REQUEST',
  createTncSectionSuccess: 'CREATE_TNC_SECTION_SUCCESS',
  createTncSectionFail: 'CREATE_TNC_SECTION_FAIL',
  updateStatusTncSectionRequest: 'UPDATE_STATUS_TNC_SECTION_REQUEST',
  updateStatusTncSectionSuccess: 'UPDATE_STATUS_TNC_SECTION_SUCCESS',
  updateStatusTncSectionFail: 'UPDATE_STATUS_TNC_SECTION_FAIL',
  editTncSectionRequest: 'EDIT_TNC_SECTION_REQUEST',
  editTncSectionSuccess: 'EDIT_TNC_SECTION_SUCCESS',
  editTncSectionFail: 'EDIT_TNC_SECTION_FAIL',
};

const initState = {
  fetching: false,
  tncList: [],
  tncDetails: {},
  errorFetch: false,
  errorMessage: null,
  responseMessage: null,
};

export default function tncReducer(state = initState, action) {
  const { type, payload } = action;
  switch (type) {
    case constants.getTncListRequest:
      return { ...state, fetching: true };
    case constants.getTncListSuccess:
      return {
        ...state,
        tncList: payload.data,
        fetching: false,
      };
    case constants.getTncListFail:
      return {
        ...state,
        fetching: false,
        errorFetch: true,
        errorMessage: payload,
      };
    case constants.getTncDetailsRequest:
      return { ...state, fetching: true };
    case constants.getTncDetailsSuccess:
      return {
        ...state,
        tncDetails: payload.data,
        fetching: false,
      };
    case constants.getTncDetailsFail:
      return {
        ...state,
        fetching: false,
        errorFetch: true,
        errorMessage: payload,
      };
    case constants.createTncSectionRequest:
      return { ...state, fetching: true, responseMessage: null };
    case constants.createTncSectionSuccess:
      return {
        ...state,
        responseMessage: payload.messages,
        fetching: false,
      };
    case constants.createTncSectionFail:
      return { ...state, fetching: false, errorMessage: payload };

    case constants.updateStatusTncSectionRequest:
      return { ...state, fetching: true, responseMessage: null };
    case constants.updateStatusTncSectionSuccess:
      return {
        ...state,
        responseMessage: payload.messages,
        fetching: false,
      };
    case constants.updateStatusTncSectionFail:
      return { ...state, fetching: false, errorMessage: payload };

    case constants.editTncSectionRequest:
      return { ...state, fetching: true, responseMessage: null };
    case constants.editTncSectionSuccess:
      return {
        ...state,
        responseMessage: payload.messages,
        fetching: false,
      };
    case constants.editTncSectionFail:
      return { ...state, fetching: false, errorMessage: payload };
    default:
      return state;
  }
}
