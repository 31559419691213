import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// component
import { SelectInputSearchMain } from '../../Index';
// api
import { getPermissionList } from '../../../services/api/MasterDataApi';

class SelectInputPermission extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    this.getMasterDataList();
  }

  componentDidUpdate(prevProps) {
    const { filter } = this.props;
    if (
      prevProps.filter.roleId !== filter.roleId ||
      prevProps.filter.moduleName !== filter.moduleName
    ) {
      this.getMasterDataList();
    }
  }

  componentWillUnmount() {}

  async getMasterDataList() {
    const { apiDataList, filter } = this.props;
    const param = { role_id: filter.roleId, module: filter.moduleName };

    apiDataList(param);
  }

  handleSelectItem = value => {
    const { onChange } = this.props;

    onChange(value);
  };

  render() {
    const {
      placeHolder,
      defaultValue,
      masterData,
      errorMessage,
      validateStatus,
      currentValue,
    } = this.props;

    return (
      <SelectInputSearchMain
        options={masterData.permissionList}
        onChange={item => {
          this.handleSelectItem(item);
        }}
        currentValue={currentValue}
        defaultValue={defaultValue}
        size={'large'}
        placeholder={placeHolder}
        errorMessage={errorMessage}
        validateStatus={validateStatus}
      />
    );
  }
}

const mapDispatchToProps = dispatch => ({
  apiDataList: params => getPermissionList(dispatch, params),
});

const mapStateToProps = ({ masterData }) => ({ masterData });

SelectInputPermission.propTypes = {
  apiDataList: PropTypes.func,
  currentValue: PropTypes.array,
  defaultValue: PropTypes.array,
  errorMessage: PropTypes.string,
  filter: PropTypes.object,
  masterData: PropTypes.object,
  onChange: PropTypes.func,
  placeHolder: PropTypes.string,
  validateStatus: PropTypes.string,
};

SelectInputPermission.defaultProps = {
  placeHolder: '',
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SelectInputPermission);
