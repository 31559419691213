import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// component
import { SelectInputSearchMain } from '../../Index';
// api
import { getCoachList } from '../../../services/api/MasterDataApi';
// helper
import { CommonHelper } from '../../../helpers/Index';

class SelectInputCoach extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    this.getMasterDataList();
  }

  componentDidUpdate(prevProps) {
    const { filter } = this.props;
    if (
      prevProps.filter.merchantId !== filter.merchantId ||
      prevProps.filter.branchId !== filter.branchId
    ) {
      this.getMasterDataList();
    }
  }

  componentWillUnmount() {}

  async getMasterDataList() {
    const { apiDataList, filter } = this.props;

    if (filter.merchantId) {
      const param = {
        merchant_id: filter.merchantId,
        branch_id: filter.branchId,
      };
      apiDataList(param);
    }
  }

  handleSelectItem = value => {
    const { onChange } = this.props;

    onChange(value);
  };

  generateListDataDisplay() {
    const { masterData } = this.props;
    const converted = [];
    const currentData = CommonHelper.renameKeyName(masterData.coachList, 'trainer_id', 'value');

    let param = { value: null, name: 'Select Coach / Therapist' };
    converted.push(param);

    if (currentData.length > 0) {
      currentData.forEach(item => {
        param = { value: item.value, name: item.name };
        converted.push(param);
      });
    }

    return converted;
  }

  render() {
    const {
      placeHolder,
      defaultValue,
      currentValue,
      errorMessage,
      validateStatus,
      size,
    } = this.props;

    const ListCustom = this.generateListDataDisplay();

    return (
      <SelectInputSearchMain
        options={ListCustom}
        onChange={item => {
          this.handleSelectItem(item);
        }}
        currentValue={currentValue}
        defaultValue={defaultValue}
        errorMessage={errorMessage}
        validateStatus={validateStatus}
        size={size}
        placeholder={placeHolder}
      />
    );
  }
}

const mapDispatchToProps = dispatch => ({
  apiDataList: params => getCoachList(dispatch, params),
});

const mapStateToProps = ({ masterData }) => ({ masterData });

SelectInputCoach.propTypes = {
  apiDataList: PropTypes.func,
  currentValue: PropTypes.number,
  defaultValue: PropTypes.number,
  errorMessage: PropTypes.string,
  filter: PropTypes.object,
  masterData: PropTypes.object,
  onChange: PropTypes.func,
  placeHolder: PropTypes.string,
  size: PropTypes.string,
  validateStatus: PropTypes.string,
};

SelectInputCoach.defaultProps = {
  placeHolder: '',
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectInputCoach);
