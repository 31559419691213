import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import _ from 'lodash';
// Style
import './ModalSummaryEatOrdersStyle.scss';
// Component
import {
  ModalAlertAction,
  GridRowTableFourColumn,
  LabelStatusMain,
} from '../../../../../components/Index';
// helper
import { CommonHelper, OrderHelper, eatSessionTypeCode } from '../../../../../helpers/Index';

const ModalSummaryEatOrders = props => {
  const {
    isOpen,
    form: { items, globalExtraValue, listExtra, selectedPackage },
    isLoading,
    onCloseModal,
    onNext,
  } = props;

  const modalDetailDataCreate = {
    title: 'Order Summary',
  };

  const checkHasDefaultValue = OrderHelper.validateEatPackageExtraHasDefault(
    selectedPackage.extraSession || [],
  );

  const packagePax = selectedPackage.pax || 0;
  const packPrice = selectedPackage.isContainLunchDinner
    ? selectedPackage.lunch_and_dinner_price
    : selectedPackage.normal_price || 0;
  const packPriceExtra =
    OrderHelper.getTotalExtra(globalExtraValue, listExtra) *
    parseInt(packagePax, 10) *
    checkHasDefaultValue.total;
  let grandTotalItem = packagePax * checkHasDefaultValue.total;
  let grandTotalPrice =
    packPrice * parseInt(packagePax, 10) * checkHasDefaultValue.total + packPriceExtra;

  const renderStatus = value => {
    let renderElement = <LabelStatusMain value="LUNCH" type="warning" />;

    if (value === eatSessionTypeCode.dinner) {
      renderElement = <LabelStatusMain value="DINNER" type="primary" />;
    }

    return <div className="container-status">{renderElement}</div>;
  };

  const elementRenderCustom = (
    <div className="container-modal-summary-eat-order flex-column">
      <GridRowTableFourColumn
        customElementColumnFirst={<label className="text-14 text-semi-bold">Product Name</label>}
        customElementColumnSecond={
          <div className="text-center">
            <label className="text-14 text-semi-bold">Extras</label>
          </div>
        }
        customElementColumnThird={
          <div className="text-center">
            <label className="text-14 text-semi-bold">Item</label>
          </div>
        }
        customElementColumnFourth={
          <div className="text-right">
            <label className="text-14 text-semi-bold">Total Price</label>
          </div>
        }
        columnFirst={6}
        columnSecond={3}
        columnThird={1}
        columnFourth={2}
      />
      <div className="scroll-container mt-16">
        <Grid container direction="column" className="flex-wrap-unset">
          {items.map((item, index) => {
            const totalExtra =
              OrderHelper.getTotalExtra(item.extras, listExtra) * parseInt(item.qty, 10);
            const priceItem = parseFloat(item.price, 0) * parseInt(item.qty, 10) + totalExtra;
            const modifyDate = CommonHelper.dateTimeParseNewFormat(item.date, 'dd, DD MMM YYYY');
            const wordingExtra = OrderHelper.generateExtraWording(item.extras, listExtra);

            if (_.isEmpty(selectedPackage)) {
              grandTotalItem += parseInt(item.qty, 10);
              grandTotalPrice += priceItem;
            }

            return (
              <GridRowTableFourColumn
                key={index}
                customElementColumnFirst={
                  <div className="flex-column">
                    <label className="text-14 main text-bold wrapping-container mb-8">
                      {item.food_details.name}
                    </label>
                    <span className="flex-row mb-8">
                      <label className="text-10 mr-8 text-semi-bold">{modifyDate}</label>
                      {renderStatus(item.lunch_or_dinner)}
                    </span>
                    <label className="text-12 wrapping-container mb-8">{item.notes}</label>
                  </div>
                }
                customElementColumnSecond={
                  <div className="text-center">
                    <label className="text-14 text-semi-bold">{wordingExtra}</label>
                  </div>
                }
                customElementColumnThird={
                  <div className="text-center">
                    <label className="text-14 text-semi-bold">{item.qty}</label>
                  </div>
                }
                customElementColumnFourth={
                  <div className="text-right">
                    <label className="text-14 text-semi-bold">
                      IDR {CommonHelper.formatCurrency(priceItem)}
                    </label>
                  </div>
                }
                columnFirst={6}
                columnSecond={3}
                columnThird={1}
                columnFourth={2}
              />
            );
          })}
        </Grid>
      </div>
      <Grid container justify="space-between" className="pt-20 pl-16 pr-16">
        <Grid item lg={3} md={3}>
          <label className="text-16 text-bold">Total Item : {grandTotalItem}</label>
        </Grid>
        <Grid item lg={5} md={5}>
          <div className="text-right">
            <label className="text-16 text-bold">
              Grand Total &nbsp; &nbsp; &nbsp; IDR&nbsp;
              {CommonHelper.formatCurrency(grandTotalPrice)}
            </label>
          </div>
        </Grid>
      </Grid>
    </div>
  );

  return (
    <ModalAlertAction
      onButtonSubmit={onNext}
      onCloseModal={onCloseModal}
      modalDetail={modalDetailDataCreate}
      buttonSubmitText="Next"
      openModal={isOpen}
      isLoading={isLoading}
      customElementProps={elementRenderCustom}
      size={'large'}
    />
  );
};

ModalSummaryEatOrders.propTypes = {
  form: PropTypes.object,
  isLoading: PropTypes.bool,
  isOpen: PropTypes.bool,
  onCloseModal: PropTypes.func,
  onNext: PropTypes.func,
};

export default ModalSummaryEatOrders;
