import { constants } from '../reducers/MasterReducer';

export function getMerchantListRequest() {
  return { type: constants.getMerchantListRequest };
}
export function getMerchantListSuccess(response) {
  return { type: constants.getMerchantListSuccess, payload: response };
}
export function getMerchantListFail(response) {
  return { type: constants.getMerchantListFail, payload: response.data };
}

export function getBranchListRequest() {
  return { type: constants.getBranchListRequest };
}
export function getBranchListSuccess(response) {
  return { type: constants.getBranchListSuccess, payload: response };
}
export function getBranchListFail(response) {
  return { type: constants.getBranchListFail, payload: response.data };
}

export function getCompanyListRequest() {
  return { type: constants.getCompanyListRequest };
}
export function getCompanyListSuccess(response) {
  return { type: constants.getCompanyListSuccess, payload: response };
}
export function getCompanyListFail(response) {
  return { type: constants.getCompanyListFail, payload: response.data };
}

export function getRoleListRequest() {
  return { type: constants.getRoleListRequest };
}
export function getRoleListSuccess(response) {
  return { type: constants.getRoleListSuccess, payload: response };
}
export function getRoleListFail(response) {
  return { type: constants.getRoleListFail, payload: response.data };
}

export function getCoachListRequest() {
  return { type: constants.getCoachListRequest };
}
export function getCoachListSuccess(response) {
  return { type: constants.getCoachListSuccess, payload: response };
}
export function getCoachListFail(response) {
  return { type: constants.getCoachListFail, payload: response.data };
}

export function getClassListRequest() {
  return { type: constants.getClassListRequest };
}
export function getClassListSuccess(response) {
  return { type: constants.getClassListSuccess, payload: response };
}
export function getClassListFail(response) {
  return { type: constants.getClassListFail, payload: response.data };
}

export function getPermissionListRequest() {
  return { type: constants.getPermissionListRequest };
}
export function getPermissionListSuccess(response) {
  return { type: constants.getPermissionListSuccess, payload: response };
}
export function getPermissionListFail(response) {
  return { type: constants.getPermissionListFail, payload: response.data };
}

export function getScreenDeeplinkListRequest() {
  return { type: constants.getScreenDeeplinkListRequest };
}
export function getScreenDeeplinkListSuccess(response) {
  return { type: constants.getScreenDeeplinkListSuccess, payload: response };
}
export function getScreenDeeplinkListFail(response) {
  return { type: constants.getScreenDeeplinkListFail, payload: response.data };
}

export function getProvinceListRequest() {
  return { type: constants.getProvinceListRequest };
}
export function getProvinceListSuccess(response) {
  return { type: constants.getProvinceListSuccess, payload: response };
}
export function getProvinceListFail(response) {
  return { type: constants.getProvinceListFail, payload: response.data };
}

export function getCityListRequest() {
  return { type: constants.getCityListRequest };
}
export function getCityListSuccess(response) {
  return { type: constants.getCityListSuccess, payload: response };
}
export function getCityListFail(response) {
  return { type: constants.getCityListFail, payload: response.data };
}

export function getDistrictListRequest() {
  return { type: constants.getDistrictListRequest };
}
export function getDistrictListSuccess(response) {
  return { type: constants.getDistrictListSuccess, payload: response };
}
export function getDistrictListFail(response) {
  return { type: constants.getDistrictListFail, payload: response.data };
}

export function getVillageListRequest() {
  return { type: constants.getVillageListRequest };
}
export function getVillageListSuccess(response) {
  return { type: constants.getVillageListSuccess, payload: response };
}
export function getVillageListFail(response) {
  return { type: constants.getVillageListFail, payload: response.data };
}

export function getBankDetailsRequest() {
  return { type: constants.getBankDetailsRequest };
}
export function getBankDetailsSuccess(response) {
  return { type: constants.getBankDetailsSuccess, payload: response };
}
export function getBankDetailsFail(response) {
  return { type: constants.getBankDetailsFail, payload: response.data };
}

export function getSegmentListRequest() {
  return { type: constants.getSegmentListRequest };
}
export function getSegmentListSuccess(response) {
  return { type: constants.getSegmentListSuccess, payload: response };
}
export function getSegmentListFail(response) {
  return { type: constants.getSegmentListFail, payload: response.data };
}

export function getFeedCategoryListRequest() {
  return { type: constants.getFeedCategoryListRequest };
}
export function getFeedCategoryListSuccess(response) {
  return { type: constants.getFeedCategoryListSuccess, payload: response };
}
export function getFeedCategoryListFail(response) {
  return { type: constants.getFeedCategoryListFail, payload: response.data };
}

export function getPaymentMethodListRequest() {
  return { type: constants.getPaymentMethodListRequest };
}
export function getPaymentMethodListSuccess(response) {
  return { type: constants.getPaymentMethodListSuccess, payload: response };
}
export function getPaymentMethodListFail(response) {
  return { type: constants.getPaymentMethodListFail, payload: response.data };
}

export function getProductEatPackageListRequest() {
  return { type: constants.getProductEatPackageListRequest };
}
export function getProductEatPackageListSuccess(response) {
  return { type: constants.getProductEatPackageListSuccess, payload: response };
}
export function getProductEatPackageListFail(response) {
  return { type: constants.getProductEatPackageListFail, payload: response.data };
}

export function getProductTypeListRequest() {
  return { type: constants.getProductTypeListRequest };
}
export function getProductTypeListSuccess(response) {
  return { type: constants.getProductTypeListSuccess, payload: response };
}
export function getProductTypeListFail(response) {
  return { type: constants.getProductTypeListFail, payload: response.data };
}

export function getProductCategoryListRequest() {
  return { type: constants.getProductCategoryListRequest };
}
export function getProductCategoryListSuccess(response) {
  return { type: constants.getProductCategoryListSuccess, payload: response };
}
export function getProductCategoryListFail(response) {
  return { type: constants.getProductCategoryListFail, payload: response.data };
}

export function getProductPromoCodeMappingListRequest() {
  return { type: constants.getProductPromoCodeMappingListRequest };
}
export function getProductPromoCodeMappingListSuccess(response) {
  return { type: constants.getProductPromoCodeMappingListSuccess, payload: response };
}
export function getProductPromoCodeMappingListFail(response) {
  return { type: constants.getProductPromoCodeMappingListFail, payload: response.data };
}

export function getProductSportClinicRequest() {
  return { type: constants.getProductSportClinicRequest };
}
export function getProductSportClinicSuccess(response) {
  return { type: constants.getProductSportClinicSuccess, payload: response };
}
export function getProductSportClinicFail(response) {
  return { type: constants.getProductSportClinicFail, payload: response.data };
}

export function getMedicConsultationDetailsRequest() {
  return { type: constants.getMedicConsultationDetailsRequest };
}
export function getMedicConsultationDetailsSuccess(response) {
  return { type: constants.getMedicConsultationDetailsSuccess, payload: response };
}
export function getMedicConsultationDetailsFail(response) {
  return { type: constants.getMedicConsultationDetailsFail, payload: response.data };
}

export function getWorkoutCategoryTabRequest() {
  return { type: constants.getWorkoutCategoryTabRequest };
}
export function getWorkoutCategoryTabSuccess(response) {
  return { type: constants.getWorkoutCategoryTabSuccess, payload: response };
}
export function getWorkoutCategoryTabFail(response) {
  return { type: constants.getWorkoutCategoryTabFail, payload: response.data };
}

export function getWorkoutCategoryProductRequest() {
  return { type: constants.getWorkoutCategoryProductRequest };
}
export function getWorkoutCategoryProductSuccess(response) {
  return { type: constants.getWorkoutCategoryProductSuccess, payload: response };
}
export function getWorkoutCategoryProductFail(response) {
  return { type: constants.getWorkoutCategoryProductFail, payload: response.data };
}

export function getWorkoutCategoryBranchRequest() {
  return { type: constants.getWorkoutCategoryBranchRequest };
}
export function getWorkoutCategoryBranchSuccess(response) {
  return { type: constants.getWorkoutCategoryBranchSuccess, payload: response };
}
export function getWorkoutCategoryBranchFail(response) {
  return { type: constants.getWorkoutCategoryBranchFail, payload: response.data };
}

export function getWorkoutCategoryStatusRequest() {
  return { type: constants.getWorkoutCategoryStatusRequest };
}
export function getWorkoutCategoryStatusSuccess(response) {
  return { type: constants.getWorkoutCategoryStatusSuccess, payload: response };
}
export function getWorkoutCategoryStatusFail(response) {
  return { type: constants.getWorkoutCategoryStatusFail, payload: response.data };
}

export function getWorkoutCategoryAllCoachRequest() {
  return { type: constants.getWorkoutCategoryAllCoachRequest };
}
export function getWorkoutCategoryAllCoachSuccess(response) {
  return { type: constants.getWorkoutCategoryAllCoachSuccess, payload: response };
}
export function getWorkoutCategoryAllCoachFail(response) {
  return { type: constants.getWorkoutCategoryAllCoachFail, payload: response.data };
}

export function getWorkoutProductByCoachRequest() {
  return { type: constants.getWorkoutProductByCoachRequest };
}
export function getWorkoutProductByCoachSuccess(response) {
  return { type: constants.getWorkoutProductByCoachSuccess, payload: response };
}
export function getWorkoutProductByCoachFail(response) {
  return { type: constants.getWorkoutProductByCoachFail, payload: response.data };
}

export function getCoachesByProductRequest() {
  return { type: constants.getCoachesByProductRequest };
}
export function getCoachesByProductSuccess(response) {
  return { type: constants.getCoachesByProductSuccess, payload: response };
}
export function getCoachesByProductFail(response) {
  return { type: constants.getCoachesByProductFail, payload: response.data };
}

export function getBranchesByProductRequest() {
  return { type: constants.getBranchesByProductRequest };
}
export function getBranchesByProductSuccess(response) {
  return { type: constants.getBranchesByProductSuccess, payload: response };
}
export function getBranchesByProductFail(response) {
  return { type: constants.getBranchesByProductFail, payload: response.data };
}

export function getConsultationBranchesRequest() {
  return { type: constants.getConsultationBranchesRequest };
}
export function getConsultationBranchesSuccess(response) {
  return { type: constants.getConsultationBranchesSuccess, payload: response };
}
export function getConsultationBranchesFail(response) {
  return { type: constants.getConsultationBranchesFail, payload: response.data };
}

export function getConsultationProductTypeRequest() {
  return { type: constants.getConsultationProductTypeRequest };
}
export function getConsultationProductTypeSuccess(response) {
  return { type: constants.getConsultationProductTypeSuccess, payload: response };
}
export function getConsultationProductTypeFail(response) {
  return { type: constants.getConsultationProductTypeFail, payload: response.data };
}

export function getProductByProductTypeRequest() {
  return { type: constants.getProductByProductTypeRequest };
}
export function getProductByProductTypeSuccess(response) {
  return { type: constants.getProductByProductTypeSuccess, payload: response };
}
export function getProductByProductTypeFail(response) {
  return { type: constants.getProductByProductTypeFail, payload: response.data };
}

export function getConsultationProductDetailRequest() {
  return { type: constants.getConsultationProductDetailRequest };
}
export function getConsultationProductDetailSuccess(response) {
  return { type: constants.getConsultationProductDetailSuccess, payload: response };
}
export function getConsultationProductDetailFail(response) {
  return { type: constants.getConsultationProductDetailFail, payload: response.data };
}

export function getCountryListRequest() {
  return { type: constants.getCountryListRequest };
}
export function getCountryListSuccess(response) {
  return { type: constants.getCountryListSuccess, payload: response };
}
export function getCountryListFail(response) {
  return { type: constants.getCountryListFail, payload: response.data };
}

export function getClinicBranchRequest() {
  return { type: constants.getClinicBranchRequest };
}
export function getClinicBranchSuccess(response) {
  return { type: constants.getClinicBranchSuccess, payload: response };
}
export function getClinicBranchFail(response) {
  return { type: constants.getClinicBranchFail, payload: response.data };
}

export function getCustomerListRequest() {
  return { type: constants.getCustomerListRequest };
}
export function getCustomerListSuccess(response) {
  return { type: constants.getCustomerListSuccess, payload: response };
}
export function getCustomerListFail(response) {
  return { type: constants.getCustomerListFail, payload: response.data };
}

export function getCovidTestMethodRequest() {
  return { type: constants.getCovidTestMethodRequest };
}
export function getCovidTestMethodSuccess(response) {
  return { type: constants.getCovidTestMethodSuccess, payload: response };
}
export function getCovidTestMethodFail(response) {
  return { type: constants.getCovidTestMethodFail, payload: response.data };
}

export function getVoucherListRequest() {
  return { type: constants.getVoucherListRequest };
}
export function getVoucherListSuccess(response) {
  return { type: constants.getVoucherListSuccess, payload: response };
}
export function getVoucherListFail(response) {
  return { type: constants.getVoucherListFail, payload: response.data };
}
