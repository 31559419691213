import React from 'react';
import PropTypes from 'prop-types';
import { FormLabel } from '@material-ui/core';
// style
import './LabelInputStyle.scss';

const LabelInput = props => {
  const { additionalText, isRequired, labelText } = props;

  const wording = `${labelText} ${additionalText}`;

  return (
    <FormLabel component="label" className="container-label-input form-label" required={isRequired}>
      {wording.trim()}
    </FormLabel>
  );
};

LabelInput.propTypes = {
  additionalText: PropTypes.string,
  isRequired: PropTypes.bool,
  labelText: PropTypes.string,
};

LabelInput.defaultProps = {
  additionalText: '',
  isRequired: false,
};

export default LabelInput;
