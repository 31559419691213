import * as OrderAction from '../../redux/actions/OrderAction';
import Api from '../../middleware/FetchMiddleware';
import { baseUrl } from '../../environments/Environment';
import { HttpAction } from '../../helpers/HttpHelper';

// #region order add

// #region retail
export const getOrderList = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(OrderAction.getOrderListRequest());
    Api.callApi(`${baseUrl}private/pos/v2/orders/list`, HttpAction.Get, params, {})
      .then(response => {
        dispatch(OrderAction.getOrderListSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(OrderAction.getOrderListFail(e));
        reject(e);
      });
  });
};
// #endregion

// #region eat
export const getOrderEatListByType = (dispatch, type) => {
  return new Promise((resolve, reject) => {
    dispatch(OrderAction.getOrderEatListByTypeRequest());
    Api.callApi(`${baseUrl}private/pos/v2/eat/foodlist/list/${type}`, HttpAction.Get, {}, {})
      .then(response => {
        dispatch(OrderAction.getOrderEatListByTypeSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(OrderAction.getOrderEatListByTypeFail(e));
        reject(e);
      });
  });
};

export const getOrderEatListByTypeProduct = (dispatch, id, type, params) => {
  return new Promise((resolve, reject) => {
    dispatch(OrderAction.getOrderEatListByTypeProductRequest());
    Api.callApi(
      `${baseUrl}private/pos/v2/eat/catering-schedule/foodlist/${id}/${type}`,
      HttpAction.Get,
      params,
      {},
    )
      .then(response => {
        dispatch(OrderAction.getOrderEatListByTypeProductSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(OrderAction.getOrderEatListByTypeProductFail(e));
        reject(e);
      });
  });
};

export const getOrderEatListExtra = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    // dispatch(OrderAction.getOrderEatListByTypeProductRequest());
    Api.callApi(`${baseUrl}private/pos/v2/eat/extras`, HttpAction.Get, params, {})
      .then(response => {
        // dispatch(OrderAction.getOrderEatListByTypeProductSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        // dispatch(OrderAction.getOrderEatListByTypeProductFail(e));
        reject(e);
      });
  });
};

export const getUserMembershipEats = (dispatch, id, params) => {
  return new Promise((resolve, reject) => {
    // dispatch(OrderAction.getOrderEatListByTypeProductRequest());
    Api.callApi(`${baseUrl}private/pos/v2/eat/membership/user/${id}`, HttpAction.Post, {}, params)
      .then(response => {
        // dispatch(OrderAction.getOrderEatListByTypeProductSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        // dispatch(OrderAction.getOrderEatListByTypeProductFail(e));
        reject(e);
      });
  });
};

// #endregion

export const getOrderDetails = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(OrderAction.getOrderDetailsRequest());
    const salesOrderID = params.sales_order_id;
    Api.callApi(`${baseUrl}private/pos/v2/orders/${salesOrderID}`, HttpAction.Get, params, {})
      .then(response => {
        dispatch(OrderAction.getOrderDetailsSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(OrderAction.getOrderDetailsFail(e));
        reject(e);
      });
  });
};

export const getUserDetailByPhone = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(OrderAction.getUserDetailByPhoneRequest());
    Api.callApi(`${baseUrl}private/pos/v2/orders/check-users-exists`, HttpAction.Get, params, {})
      .then(response => {
        dispatch(OrderAction.getUserDetailByPhoneSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(OrderAction.getUserDetailByPhoneFail(e));
        reject(e);
      });
  });
};

export const getOrderProductPagination = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(OrderAction.getOrderProductPaginationRequest());
    Api.callApi(`${baseUrl}private/pos/v2/orders/product/list`, HttpAction.Get, params, {})
      .then(response => {
        dispatch(OrderAction.getOrderProductPaginationSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(OrderAction.getOrderProductPaginationFail(e));
        reject(e);
      });
  });
};

export const getOrderShippingAddress = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(OrderAction.getOrderShippingAddressRequest());
    Api.callApi(`${baseUrl}public/map/search/address`, HttpAction.Get, params, {})
      .then(response => {
        dispatch(OrderAction.getOrderShippingAddressSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(OrderAction.getOrderShippingAddressFail(e));
        reject(e);
      });
  });
};

export const getOrderShippingAddressInfo = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(OrderAction.getOrderShippingAddressInfoRequest());
    Api.callApi(`${baseUrl}public/map/search/place`, HttpAction.Get, params, {})
      .then(response => {
        dispatch(OrderAction.getOrderShippingAddressInfoSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(OrderAction.getOrderShippingAddressInfoFail(e));
        reject(e);
      });
  });
};

export const getOrderShippingCost = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(OrderAction.getOrderShippingCostRequest());
    Api.callApi(`${baseUrl}private/pos/v2/orders/shipping-cost`, HttpAction.Post, {}, params)
      .then(response => {
        dispatch(OrderAction.getOrderShippingCostSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(OrderAction.getOrderShippingCostFail(e));
        reject(e);
      });
  });
};

export const setProcessCreateOrder = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(OrderAction.setProcessCreateOrderRequest());
    Api.callApi(`${baseUrl}private/pos/v2/orders/process`, HttpAction.Post, {}, params)
      .then(response => {
        dispatch(OrderAction.setProcessCreateOrderSuccess());
        return resolve(response);
      })
      .catch(e => {
        dispatch(OrderAction.setProcessCreateOrderFail(e));
        reject(e);
      });
  });
};

export const checkPromoCode = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(OrderAction.checkPromoCodeRequest());
    Api.callApi(`${baseUrl}private/pos/v2/orders/validate-promo-code`, HttpAction.Post, {}, params)
      .then(response => {
        dispatch(OrderAction.checkPromoCodeSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(OrderAction.checkPromoCodeFail(e));
        reject(e);
      });
  });
};

export const getAvailablePromoCode = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(OrderAction.getAvailablePromoCodeRequest());
    Api.callApi(`${baseUrl}private/mobile/cashback/available`, HttpAction.Post, {}, params)
      .then(response => {
        dispatch(OrderAction.getAvailablePromoCodeSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(OrderAction.getAvailablePromoCodeFail(e));
        reject(e);
      });
  });
};

// #endregion

// #region Order Shipment
export const getOrderShipmentList = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(OrderAction.getOrderShipmentListRequest());
    Api.callApi(`${baseUrl}private/pos/v2/shipment/list`, HttpAction.Get, params, {})
      .then(response => {
        dispatch(OrderAction.getOrderShipmentListSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(OrderAction.getOrderShipmentListFail(e));
        reject(e);
      });
  });
};

export const getOrderSummaryList = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(OrderAction.getOrderSummaryListRequest());
    Api.callApi(`${baseUrl}private/pos/v2/orders/summary`, HttpAction.Get, params, {})
      .then(response => {
        dispatch(OrderAction.getOrderSummaryListSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(OrderAction.getOrderSummaryListFail(e));
        reject(e);
      });
  });
};

export const getOrderShipmentDetails = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(OrderAction.getOrderShipmentDetailsRequest());
    Api.callApi(`${baseUrl}private/pos/v2/shipment/list`, HttpAction.Get, params, {})
      .then(response => {
        dispatch(OrderAction.getOrderShipmentDetailsSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(OrderAction.getOrderShipmentDetailsFail(e));
        reject(e);
      });
  });
};

export const getOrderShipmentTrackingDetails = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(OrderAction.getOrderShipmentTrackingDetailsRequest());
    Api.callApi(`${baseUrl}private/pos/v2/shipment/tracking`, HttpAction.Get, params, {})
      .then(response => {
        dispatch(OrderAction.getOrderShipmentTrackingDetailsSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(OrderAction.getOrderShipmentTrackingDetailsFail(e));
        reject(e);
      });
  });
};

export const getOrderSummaryReportExport = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(OrderAction.getOrderSummaryReportExportRequest());
    Api.callApi(`${baseUrl}private/pos/v2/orders/summary/download?`, HttpAction.Get, params, {})
      .then(response => {
        dispatch(OrderAction.getOrderSummaryReportExportSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(OrderAction.getOrderSummaryReportExportFail(e));
        reject(e);
      });
  });
};

export const getShipmentDeliveryReport = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(OrderAction.getShipmentDeliveryReportRequest());
    Api.callApi(`${baseUrl}private/pos/v2/shipment/delivery/report?`, HttpAction.Get, params, {})
      .then(response => {
        dispatch(OrderAction.getShipmentDeliveryReportSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(OrderAction.getShipmentDeliveryReportFail(e));
        reject(e);
      });
  });
};

export const getShipmentDeliveryReportExport = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(OrderAction.getShipmentDeliveryReportExportRequest());
    Api.callApi(
      `${baseUrl}private/pos/v2/shipment/delivery/report/download?`,
      HttpAction.Get,
      params,
      {},
    )
      .then(response => {
        dispatch(OrderAction.getShipmentDeliveryReportExportSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(OrderAction.getShipmentDeliveryReportExportFail(e));
        reject(e);
      });
  });
};

export const getShipmentExport = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(OrderAction.getShipmentExportRequest());
    Api.callApi(`${baseUrl}private/pos/v2/shipment/download?`, HttpAction.Get, params, {})
      .then(response => {
        dispatch(OrderAction.getShipmentExportSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(OrderAction.getShipmentExportFail(e));
        reject(e);
      });
  });
};

export const getShippingDetails = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(OrderAction.getShippingDetailsRequest());
    Api.callApi(`${baseUrl}private/pos/v2/shipment`, HttpAction.Get, params, {})
      .then(response => {
        dispatch(OrderAction.getShippingDetailsSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(OrderAction.getShippingDetailsFail(e));
        reject(e);
      });
  });
};

// #endregion

// #region handover
export const getHandoverList = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(OrderAction.getHandoverListRequest());
    Api.callApi(`${baseUrl}private/pos/v2/handover`, HttpAction.Get, params, {})
      .then(response => {
        dispatch(OrderAction.getHandoverListSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(OrderAction.getHandoverListFail(e));
        reject(e);
      });
  });
};

export const getHandoverDetails = (dispatch, orderHandoverId) => {
  return new Promise((resolve, reject) => {
    dispatch(OrderAction.getHandoverDetailsRequest());
    Api.callApi(`${baseUrl}private/pos/v2/handover/${orderHandoverId}`, HttpAction.Get, {}, {})
      .then(response => {
        dispatch(OrderAction.getHandoverDetailsSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(OrderAction.getHandoverDetailsFail(e));
        reject(e);
      });
  });
};

export const getCategoryList = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(OrderAction.getCategoryListRequest());
    Api.callApi(
      `${baseUrl}private/pos/v2/orders/product/category/search`,
      HttpAction.Get,
      params,
      {},
    )
      .then(response => {
        dispatch(OrderAction.getCategoryListSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(OrderAction.getCategoryListFail(e));
        reject(e);
      });
  });
};

export const deleteHandover = (dispatch, orderHandoverId) => {
  return new Promise((resolve, reject) => {
    dispatch(OrderAction.deleteHandoverRequest());
    Api.callApi(`${baseUrl}private/pos/v2/handover/${orderHandoverId}`, HttpAction.Delete, {}, {})
      .then(response => {
        dispatch(OrderAction.deleteHandoverSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(OrderAction.deleteHandoverFail(e));
        reject(e);
      });
  });
};

export const getDataPrintHandover = (dispatch, orderHandoverId) => {
  return new Promise((resolve, reject) => {
    dispatch(OrderAction.getDataPrintHandoverRequest());
    Api.callApi(
      `${baseUrl}private/pos/v2/handover/${orderHandoverId}/print`,
      HttpAction.Get,
      {},
      {},
    )
      .then(response => {
        dispatch(OrderAction.getDataPrintHandoverSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(OrderAction.getDataPrintHandoverFail(e));
        reject(e);
      });
  });
};

export const setUpdateDataPrintHandoverById = (dispatch, orderHandoverId) => {
  return new Promise((resolve, reject) => {
    dispatch(OrderAction.getDataPrintHandoverRequest());
    Api.callApi(
      `${baseUrl}private/pos/v2/handover/${orderHandoverId}/print`,
      HttpAction.put,
      {},
      {},
    )
      .then(response => {
        dispatch(OrderAction.getDataPrintHandoverSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(OrderAction.getDataPrintHandoverFail(e));
        reject(e);
      });
  });
};

export const PostHandover = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(OrderAction.PostHandoverRequest());
    Api.callApi(`${baseUrl}private/pos/v2/handover`, HttpAction.Put, {}, params)
      .then(response => {
        dispatch(OrderAction.PostHandoverSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(OrderAction.PostHandoverFail(e));
        reject(e);
      });
  });
};

export const getNameHandover = dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(OrderAction.getNameHandoverRequest());
    Api.callApi(`${baseUrl}private/pos/v2/handover/generate/`, HttpAction.Get, {}, {})
      .then(response => {
        dispatch(OrderAction.getNameHandoverSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(OrderAction.getNameHandoverFail(e));
        reject(e);
      });
  });
};

// #endregion

// #region purchase order

export const setProcessCreatePurchaseOrder = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    // dispatch(OrderAction.setProcessCreatePurchaseOrderRequest());
    Api.callApi(`${baseUrl}private/pos/v2/purchase-order`, HttpAction.Put, {}, params)
      .then(response => {
        // dispatch(OrderAction.setProcessCreatePurchaseOrderSuccess());
        return resolve(response);
      })
      .catch(e => {
        // dispatch(OrderAction.setProcessCreatePurchaseOrderFail(e));
        reject(e);
      });
  });
};

export const setProcessUpdatePurchaseOrder = (dispatch, id, params) => {
  return new Promise((resolve, reject) => {
    // dispatch(OrderAction.setProcessCreatePurchaseOrderRequest());
    Api.callApi(`${baseUrl}private/pos/v2/purchase-order/${id}`, HttpAction.Patch, {}, params)
      .then(response => {
        // dispatch(OrderAction.setProcessCreatePurchaseOrderSuccess());
        return resolve(response);
      })
      .catch(e => {
        // dispatch(OrderAction.setProcessCreatePurchaseOrderFail(e));
        reject(e);
      });
  });
};

export const getPurchaseOrderListPagination = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(OrderAction.getPurchaseOrderPaginationRequest());
    Api.callApi(`${baseUrl}private/pos/v2/purchase-order?`, HttpAction.Get, params, {})
      .then(response => {
        dispatch(OrderAction.getPurchaseOrderPaginationSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(OrderAction.getPurchaseOrderPaginationFail(e));
        reject(e);
      });
  });
};

export const setDeletePurchaseOrder = (dispatch, id) => {
  return new Promise((resolve, reject) => {
    dispatch(OrderAction.setDeletePurchaseOrderRequest());
    Api.callApi(`${baseUrl}private/pos/v2/purchase-order/${id}`, HttpAction.Delete, {}, {})
      .then(response => {
        dispatch(OrderAction.setDeletePurchaseOrderSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(OrderAction.setDeletePurchaseOrderFail(e));
        reject(e);
      });
  });
};

export const getPurchaseOrderDetails = (dispatch, id) => {
  return new Promise((resolve, reject) => {
    dispatch(OrderAction.getPurchaseOrderDetailsRequest());
    const mergeParam = `${baseUrl}private/pos/v2/purchase-order/${id}`;
    Api.callApi(mergeParam, HttpAction.Get, {}, {})
      .then(response => {
        dispatch(OrderAction.getPurchaseOrderDetailsSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(OrderAction.getPurchaseOrderDetailsFail(e));
        reject(e);
      });
  });
};

export const setProcessProformaInvoice = id => {
  return new Promise((resolve, reject) => {
    // dispatch(OrderAction.setProcessProformaInvoiceRequest());
    const mergeParam = `${baseUrl}private/pos/v2/purchase-order/${id}/process/create-proforma-invoice`;
    Api.callApi(mergeParam, HttpAction.Patch, {}, {})
      .then(response => {
        // dispatch(OrderAction.setProcessProformaInvoiceSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        // dispatch(OrderAction.setProcessProformaInvoiceFail(e));
        reject(e);
      });
  });
};

export const getPurchaseOrderPrint = (dispatch, id, params) => {
  return new Promise((resolve, reject) => {
    dispatch(OrderAction.getPurchaseOrderPrintRequest());
    const mergeParam = `${baseUrl}private/pos/v2/purchase-order/${id}/print/document`;
    Api.callApi(mergeParam, HttpAction.get, params, {})
      .then(response => {
        dispatch(OrderAction.getPurchaseOrderPrintSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(OrderAction.getPurchaseOrderPrintFail(e));
        reject(e);
      });
  });
};

export const setProcessPurchaseOrderChangeStatus = (id, key) => {
  return new Promise((resolve, reject) => {
    // dispatch(OrderAction.setProcessProformaInvoiceRequest());
    const mergeParam = `${baseUrl}private/pos/v2/purchase-order/${id}/process/${key}`;
    Api.callApi(mergeParam, HttpAction.Patch, {}, {})
      .then(response => {
        // dispatch(OrderAction.setProcessProformaInvoiceSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        // dispatch(OrderAction.setProcessProformaInvoiceFail(e));
        reject(e);
      });
  });
};

export const setProcessSaveDocumentFiles = (id, params) => {
  return new Promise((resolve, reject) => {
    // dispatch(OrderAction.setProcessSaveDocumentFilesRequest());
    const mergeParam = `${baseUrl}private/pos/v2/purchase-order/${id}/upload/document`;
    Api.callApi(mergeParam, HttpAction.Patch, {}, params)
      .then(response => {
        // dispatch(OrderAction.setProcessSaveDocumentFilesSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        // dispatch(OrderAction.setProcessSaveDocumentFilesFail(e));
        reject(e);
      });
  });
};

export const setProcessCreateInvoice = (id, params) => {
  return new Promise((resolve, reject) => {
    // dispatch(OrderAction.setProcessSaveDocumentFilesRequest());
    const mergeParam = `${baseUrl}private/pos/v2/purchase-order/${id}/process/create-invoice`;
    Api.callApi(mergeParam, HttpAction.Patch, {}, params)
      .then(response => {
        // dispatch(OrderAction.setProcessSaveDocumentFilesSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        // dispatch(OrderAction.setProcessSaveDocumentFilesFail(e));
        reject(e);
      });
  });
};

export const setProcessSaveItemQty = (id, params) => {
  return new Promise((resolve, reject) => {
    // dispatch(OrderAction.setProcessSaveDocumentFilesRequest());
    const mergeParam = `${baseUrl}private/pos/v2/purchase-order/${id}/process/update-qty`;
    Api.callApi(mergeParam, HttpAction.Patch, {}, params)
      .then(response => {
        // dispatch(OrderAction.setProcessSaveDocumentFilesSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        // dispatch(OrderAction.setProcessSaveDocumentFilesFail(e));
        reject(e);
      });
  });
};
// #endregion

export const getGuestUser = dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(OrderAction.getGuestUserRequest());
    Api.callApi(`${baseUrl}private/pos/user/guest`, HttpAction.Get, {}, {})
      .then(response => {
        dispatch(OrderAction.getGuestUserSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(OrderAction.getGuestUserFail(e));
        reject(e);
      });
  });
};

export const getFavoriteMenus = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(OrderAction.getFavoriteMenusRequest());
    Api.callApi(`${baseUrl}public/mobile/eat/store/favorite-menus`, HttpAction.Post, {}, params)
      .then(response => {
        dispatch(OrderAction.getFavoriteMenusSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(OrderAction.getFavoriteMenusFail(e));
        reject(e);
      });
  });
};

export const cancelOrder = (dispatch, salesOrderID) => {
  return new Promise((resolve, reject) => {
    dispatch(OrderAction.cancelOrderRequest());
    Api.callApi(`${baseUrl}private/pos/v2/orders/${salesOrderID}/cancel`, HttpAction.Post, null)
      .then(response => {
        dispatch(OrderAction.cancelOrderSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(OrderAction.cancelOrderFail(e));
        reject(e);
      });
  });
};

export const saveOpenBill = (dispatch, salesOrderID, params) => {
  return new Promise((resolve, reject) => {
    dispatch(OrderAction.saveOpenBillRequest());
    Api.callApi(`${baseUrl}private/pos/v2/orders/${salesOrderID}`, HttpAction.Patch, {}, params)
      .then(response => {
        dispatch(OrderAction.saveOpenBillSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(OrderAction.saveOpenBillFail(e));
        reject(e);
      });
  });
};

export const submitOpenBill = (dispatch, salesOrderID, params) => {
  return new Promise((resolve, reject) => {
    dispatch(OrderAction.submitOpenBillRequest());
    Api.callApi(`${baseUrl}private/pos/v2/orders/${salesOrderID}`, HttpAction.Put, {}, params)
      .then(response => {
        dispatch(OrderAction.submitOpenBillSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(OrderAction.submitOpenBillFail(e));
        reject(e);
      });
  });
};

export const setPrintCaptainOrder = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(OrderAction.setPrintCaptainOrderRequest());
    Api.callApi(
      `${baseUrl}private/pos/v2/orders/:sales_order_id/captain-order`,
      HttpAction.Post,
      {},
      params,
    )
      .then(response => {
        dispatch(OrderAction.setPrintCaptainOrderSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(OrderAction.setPrintCaptainOrderFail(e));
        reject(e);
      });
  });
};

export const getConsultationOrderDetails = (dispatch, salesOrderId) => {
  return new Promise((resolve, reject) => {
    dispatch(OrderAction.getConsultationOrderDetailRequest());
    Api.callApi(`${baseUrl}private/pos/medic/consultation/${salesOrderId}`, HttpAction.Get, {}, {})
      .then(response => {
        dispatch(OrderAction.getConsultationOrderDetailSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(OrderAction.getConsultationOrderDetailFail(e));
        reject(e);
      });
  });
};

export const changeOrderPayment = (dispatch, salesOrderID) => {
  return new Promise((resolve, reject) => {
    dispatch(OrderAction.changeOrderPaymentRequest());
    Api.callApi(
      `${baseUrl}private/pos/v2/orders/${salesOrderID}/change-payment`,
      HttpAction.Post,
      null,
    )
      .then(response => {
        dispatch(OrderAction.changeOrderPaymentSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(OrderAction.changeOrderPaymentFail(e));
        reject(e);
      });
  });
};

export const getOrderTaxPercentage = dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(OrderAction.getOrderTaxPercentageRequest());
    Api.callApi(`${baseUrl}public/payment/tax`, HttpAction.Get, null)
      .then(response => {
        dispatch(OrderAction.getOrderTaxPercentageSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(OrderAction.getOrderTaxPercentageFail(e));
        reject(e);
      });
  });
};

export const getOrderServiceChargePercentage = dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(OrderAction.getOrderServiceChargePercentageRequest());
    Api.callApi(`${baseUrl}public/payment/service-charge`, HttpAction.Get, null)
      .then(response => {
        dispatch(OrderAction.getOrderServiceChargePercentageSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(OrderAction.getOrderServiceChargePercentageFail(e));
        reject(e);
      });
  });
};
