/* eslint-disable no-unused-vars */
import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
// Style
import './TooltipChatPieCustomStyle.scss';
import { CommonHelper } from '../../../../../helpers/Index';

const TooltipChatPieCustom = props => {
  const { currency, payload } = props;
  let renderElement = <div />;

  if (!_.isEmpty(payload)) {
    const date = CommonHelper.dateTimeParseNewFormat(payload[0].payload.date, 'DD MMM YYYY');

    renderElement = (
      <div className="custom-tooltip-bar-custom flex-column p-8">
        <label className="text-8 mb-8">{date}</label>
        {payload.map((item, index) => {
          const wordingLabel = CommonHelper.renameRemoveCharacter(item.name, '_');
          // const valueFormat = currency
          //   ? CommonHelper.currencyWordingValue(CommonHelper.formatCurrency(item.value))
          //   : item.value;
          // const wordingValue = `${currency || ''} ${valueFormat}`;

          return (
            <div key={index} className="flex-column">
              <label className="text-12 text-semi-bold bm-4">
                <span className="dot" style={{ backgroundColor: item.payload.color }} />
                {item.payload.percent}%
              </label>
              <label className="text-9 text-semi-bold text-rolling-stone">{wordingLabel}</label>
            </div>
          );
        })}
      </div>
    );
  }

  return renderElement;
};

TooltipChatPieCustom.propTypes = {
  currency: PropTypes.string,
};

export default TooltipChatPieCustom;
