import { constants } from '../reducers/FeedReducer';

export function getListFeedPaginationRequest() {
  return { type: constants.getListFeedPaginationRequest };
}
export function getListFeedPaginationSuccess(response) {
  return { type: constants.getListFeedPaginationSuccess, payload: response };
}
export function getListFeedPaginationFail(response) {
  return { type: constants.getListFeedPaginationFail, payload: response.data };
}

export function getFeedDetailsRequest() {
  return { type: constants.getFeedDetailsRequest };
}
export function getFeedDetailsSuccess(response) {
  return { type: constants.getFeedDetailsSuccess, payload: response };
}
export function getFeedDetailsFail(response) {
  return { type: constants.getFeedDetailsFail, payload: response.data };
}

export function setCreateFeedRequest() {
  return { type: constants.setCreateFeedRequest };
}
export function setCreateFeedSuccess(response) {
  return { type: constants.setCreateFeedSuccess, payload: response };
}
export function setCreateFeedFail(response) {
  return { type: constants.setCreateFeedFail, payload: response.data };
}

export function setUpdateFeedRequest() {
  return { type: constants.setUpdateFeedRequest };
}
export function setUpdateFeedSuccess(response) {
  return { type: constants.setUpdateFeedSuccess, payload: response };
}
export function setUpdateFeedFail(response) {
  return { type: constants.setUpdateFeedFail, payload: response.data };
}

// export function editTncSectionRequest() {
//   return { type: constants.editTncSectionRequest };
// }

// export function editTncSectionSuccess(response) {
//   return { type: constants.editTncSectionSuccess, payload: response };
// }

// export function editTncSectionFail(response) {
//   return { type: constants.editTncSectionFail, payload: response.data };
// }
