import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import _ from 'lodash';
import { compose } from 'redux';
// Components
import { HeaderOrdersPage } from './components/Index';
import {
  SnackBarSimple,
  AuthenticationAccessPages,
  PrevStateValue,
} from '../../../components/Index';
// Components View
import { OrderPage } from './Index';
import { MasterDataHelper } from '../../../helpers/Index';

const dataTabs = MasterDataHelper.orderStatus;

const optionToash = {
  vertical: 'top',
  horizontal: 'right',
};

class OrderMainPage extends React.Component {
  constructor(props) {
    super(props);
    const {
      usersReducer: { prevStateValue },
    } = props;
    this.state = {
      tabsValue: prevStateValue.activeTab || 0,
      toastInformation: {
        isOpen: false,
        message: '',
        snackbarType: 'warning',
      },
    };
  }

  componentDidMount() {
    const {
      onResetPrevStateValue,
      history: { location },
    } = this.props;
    onResetPrevStateValue({ url: location.pathname });
  }

  componentWillReceiveProps(nextProps) {
    const { usersReducer } = nextProps;

    if (_.isEmpty(usersReducer.prevStateValue)) {
      this.handleTabChange(0);
    }
  }

  handleTabChange = value => {
    const {
      onSetPrevStateValueTabs,
      history: { location },
    } = this.props;

    this.setState({ tabsValue: value }, () => {
      onSetPrevStateValueTabs({ activeTab: value, url: location.pathname });
    });
  };

  handleClickCreateOrder = () => {
    const { history } = this.props;
    history.push('/order/add');
  };

  handleCloseToast = () => {
    const { toastInformation } = this.state;
    this.setState({ toastInformation: { ...toastInformation, isOpen: false } });
  };

  renderPages = () => {
    const { tabsValue } = this.state;
    const { history } = this.props;

    let renderElement = null;

    switch (dataTabs[tabsValue]) {
      case dataTabs[0]:
      case dataTabs[1]:
      case dataTabs[2]:
        renderElement = <OrderPage history={history} activeTab={dataTabs[tabsValue].value} />;
        break;
      default:
        renderElement = <OrderPage history={history} activeTab={dataTabs[0].value} />;
        break;
    }

    return renderElement;
  };

  render() {
    const { toastInformation } = this.state;

    return (
      <div>
        <Helmet title={`FITCO | Orders`} />
        <div>
          <HeaderOrdersPage
            data={dataTabs}
            currentTab={this.state.tabsValue}
            onChangeTab={this.handleTabChange}
            onClickCreateOrder={this.handleClickCreateOrder}
          />
          {this.renderPages()}
        </div>
        <SnackBarSimple
          open={toastInformation.isOpen}
          durationHide={2000}
          message={toastInformation.message}
          onClickClose={this.handleCloseToast}
          snackbarType={toastInformation.snackbarType}
          anchor={optionToash}
        />
      </div>
    );
  }
}

const mapDispatchToProps = () => ({});

const mapStateToProps = ({ usersReducer }) => ({ usersReducer });

OrderMainPage.propTypes = {
  history: PropTypes.object,
  onResetPrevStateValue: PropTypes.func,
  onSetPrevStateValueTabs: PropTypes.func,
  usersReducer: PropTypes.object,
};

const shell = compose(connect(mapStateToProps, mapDispatchToProps));
const core = compose(AuthenticationAccessPages, PrevStateValue);

export default shell(core(OrderMainPage));
