/* eslint-disable react/no-access-state-in-setstate */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Table } from 'antd';
import _ from 'lodash';
import { Grid } from '@material-ui/core';
import { compose } from 'redux';
// Component
import {
  SnackBarSimple,
  SelectInputMain,
  PrevStateValue,
  GeneratorExcel,
  AuthenticationAccessPages,
  PickerInputDate,
  SkeletonMain,
  GeneratorPdf,
} from '../../../../../components/Index';
// Api
import {
  getListFoodlist,
  getFoodlistSummary,
  getFoodlistSummaryPrint,
} from '../../../../../services/api/EatApi';
// Helpers
import { CommonHelper, MasterDataHelper } from '../../../../../helpers/Index';
// Style
import './MealDeliverySummaryStyle.scss';

const currentDate = CommonHelper.currentDate('YYYY-MM-DD');
const optionTransactionSource = MasterDataHelper.optionTransactionSource;

const initialFoodlistOption = [
  {
    value: null,
    name: 'All Foodlist',
  },
];

const initialSort = {
  sortField: 'foodlist',
  sortOrder: 'asc',
};

const optionToast = {
  vertical: 'top',
  horizontal: 'right',
};

const initialFilter = {
  foodlist: null,
  transaction_source: null,
  start_date: currentDate,
  end_date: currentDate,
};

class MealDeliverySummary extends React.Component {
  constructor(props) {
    super(props);
    const {
      usersReducer: { prevStateValue },
    } = props;

    const updateExitingFilter = {
      ...CommonHelper.objectCloning(initialFilter),
      ...prevStateValue,
    };

    this.state = {
      filter: updateExitingFilter,
      foodlistOption: initialFoodlistOption,
      sort: CommonHelper.objectCloning(initialSort),
      loading: true,
      isLoading: true,
      fetchingExport: false,
      isLoadingPrint: false,
      isDisabled: false,
      toastInformation: {
        isOpen: false,
        message: '',
        snackbarType: 'warning',
      },
      selectedRowKeys: [],
      selectedRowPrint: [],
      dataDownload: [],
    };
  }

  componentDidMount() {
    const {
      onResetPrevStateValue,
      history: { location },
    } = this.props;

    this.getListFoodlist();
    this.getFoodlistSummaryList();

    onResetPrevStateValue({ url: location.pathname });
  }

  getListFoodlist = async () => {
    const { listFoodlist } = this.props;

    try {
      const response = await listFoodlist();
      const obj = response.data.map(item => {
        return { value: item.foodlist_id, name: item.name };
      });
      this.setState({
        foodlistOption: [...this.state.foodlistOption, ...obj],
      });
    } catch (error) {
      const errors = error.data;
      this.processMessage(errors.messages, 'error');
    }
  };

  getFoodlistSummaryList = () => {
    const { summaryFoodlist } = this.props;
    const { filter } = this.state;

    this.setState({ loading: true }, async () => {
      try {
        const param = {
          foodlist: filter.foodlist,
          transaction_source: filter.transaction_source,
          start_date: filter.start_date,
          end_date: filter.end_date,
        };
        let isDisabled = false;
        const { data } = await summaryFoodlist(param);
        if (_.isEmpty(data.meals_delivery_summary)) {
          isDisabled = true;
        }
        this.setState({
          loading: false,
          isLoading: false,
          isDisabled,
        });
      } catch (error) {
        const errors = error.data;
        this.processMessage(errors.messages, 'error');
      }
    });
  };

  getMealDetails = async () => {
    const { foodlistSummaryPrint } = this.props;
    const { filter, selectedRowKeys } = this.state;

    try {
      let param = {
        foodlist: filter.foodlist,
        transaction_source: filter.transaction_source,
        date: filter.start_date,
        limit: 1000,
      };

      if (!_.isEmpty(selectedRowKeys)) {
        param = {
          ...param,
          foodlist_list: selectedRowKeys,
        };
      }
      const { data } = await foodlistSummaryPrint(param);
      this.setState({ selectedRowPrint: data, isLoadingPrint: false });
    } catch (error) {
      const errors = error.data;
      this.processMessage(errors.messages, 'error');
    }
  };

  handleSelectChangeFoodlist = value => {
    const { filter } = this.state;
    this.setState(
      {
        filter: {
          ...filter,
          foodlist: value,
        },
        selectedRowKeys: [],
      },
      () => {
        this.getFoodlistSummaryList();
      },
    );
  };

  handleSelectChangeTransactionSource = value => {
    const { filter } = this.state;
    this.setState(
      {
        filter: {
          ...filter,
          transaction_source: value,
        },
        selectedRowKeys: [],
      },
      () => {
        this.getFoodlistSummaryList();
      },
    );
  };

  handleChangeStartDate = value => {
    const { filter } = this.state;

    const updateEndDate = CommonHelper.compareDateAndTime(value, filter.end_date, 'date');

    this.setState(
      {
        filter: {
          ...filter,
          start_date: value,
          end_date: updateEndDate ? value : filter.end_date,
        },
        selectedRowKeys: [],
      },
      () => {
        this.getFoodlistSummaryList();
      },
    );
  };

  handleChangeEndDate = value => {
    const { filter } = this.state;
    this.setState(
      {
        filter: { ...filter, end_date: value },
        selectedRowKeys: [],
      },
      () => {
        this.getFoodlistSummaryList();
      },
    );
  };

  handleButtonDownload = () => {
    const {
      eatData: { foodlistSummary },
    } = this.props;
    const { selectedRowKeys } = this.state;

    this.setState({ fetchingExport: true }, () => {
      let data = [];
      let foodListData = foodlistSummary.meals_delivery_summary || [];

      if (!_.isEmpty(selectedRowKeys) && !_.isEmpty(foodListData)) {
        foodListData = foodlistSummary.meals_delivery_summary.filter(item => {
          return selectedRowKeys.indexOf(item.foodlist_id) !== -1;
        });
      }

      if (!_.isEmpty(foodListData)) {
        data = foodListData.map(item => {
          return {
            meal_variant: item.foodlist,
            lunch: item.summary.total_lunch > 0 ? item.summary.total_lunch : '0',
            dinner: item.summary.total_dinner > 0 ? item.summary.total_dinner : '0',
          };
        });
      }

      this.setState({ dataDownload: data, fetchingExport: false });
    });
  };

  handleButtonPrint = () => {
    this.setState(
      {
        isLoadingPrint: true,
      },
      async () => {
        await this.getMealDetails();
      },
    );
  };

  handleTableChange = (pagination, filters, sorter) => {
    const { sort } = this.state;

    this.setState({
      sort: {
        ...sort,
        sortField: sorter.field || initialSort.sortField,
        sortOrder: sorter.order === 'ascend' ? 'asc' : 'desc',
      },
    });
  };

  handleSelectChangeRow = selectedRowKeys => {
    this.setState({ selectedRowKeys });
  };

  handleCloseToast = () => {
    const { toastInformation } = this.state;
    this.setState({ toastInformation: { ...toastInformation, isOpen: false } });
  };

  processMessage = (messages, type) => {
    const convertedMessage = CommonHelper.generateMessage(messages);

    const paramInformation = {
      isOpen: true,
      message: convertedMessage,
      snackbarType: type,
    };

    this.setState({
      toastInformation: paramInformation,
    });
  };

  renderFilter() {
    const { filter, fetchingExport, dataDownload, isDisabled } = this.state;
    const {
      eatData: { foodlistSummary },
    } = this.props;

    return (
      <Grid item lg={12} md={12}>
        <Grid container direction="row" justify="space-between" alignItems="flex-end">
          <Grid item xs={6}>
            <Grid container direction="row" alignItems="flex-end" className="container-filter">
              <Grid item md={3} className="row-filter">
                <label className="filter-label">Transaction Source</label>
                <SelectInputMain
                  options={optionTransactionSource}
                  currentValue={filter.transaction_source}
                  onChange={this.handleSelectChangeTransactionSource}
                />
              </Grid>
              <Grid item className="row-filter">
                <label className="filter-label">Start Date</label>
                <div className="container-remove-margin">
                  <PickerInputDate
                    customIcon="ic-ffo-date-pick"
                    dateFormat="dd/MM/yyyy"
                    defaultValue={filter.start_date}
                    onChange={this.handleChangeStartDate}
                    toolbar={false}
                  />
                </div>
              </Grid>
              <Grid item className="row-filter">
                <label className="filter-label mb-8">-</label>
              </Grid>
              <Grid item>
                <label className="filter-label">End Date</label>
                <div className="container-remove-margin">
                  <PickerInputDate
                    customIcon="ic-ffo-date-pick"
                    dateFormat="dd/MM/yyyy"
                    minDate={filter.start_date}
                    defaultValue={filter.end_date}
                    onChange={this.handleChangeEndDate}
                    toolbar={false}
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid
              container
              direction="row"
              justify="flex-end"
              alignItems="center"
              className="container-filter"
            >
              <Grid item lg={4} md={4} className="row-filter">
                <div className="container-total-foodlist">
                  <label className="text-14 text-semi-bold">{`Total Pax: ${foodlistSummary.total_deliveries}`}</label>
                </div>
              </Grid>
              <Grid item lg={4} md={4} className={`row-filter ${isDisabled ? 'disabled' : ''}`}>
                <GeneratorExcel
                  labelText="Download Report"
                  buttonSize="md"
                  type="primary"
                  dataSetArray={dataDownload}
                  fileName={`Meal_Delivery_Summary_${filter.start_date}`}
                  iconPrefix="ic-ffo-download"
                  isLoading={fetchingExport}
                  onClick={this.handleButtonDownload}
                />
              </Grid>
              <Grid item lg={4} md={4} className={`row-filter ${isDisabled ? 'disabled' : ''}`}>
                <GeneratorPdf
                  data={this.state.selectedRowPrint}
                  buttonType="primary"
                  buttonLabel="Print Label"
                  customLabel="foodlist-summary-label"
                  iconPrefix="ic-ffo-print"
                  isLoading={this.state.isLoadingPrint}
                  onClick={() => this.handleButtonPrint()}
                  isDisabled={filter.start_date !== filter.end_date}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  renderColumns = () => {
    return [
      {
        title: 'Foodlist',
        dataIndex: 'foodlist',
        sorter: (a, b) => a.foodlist_id - b.foodlist_id,
        render: (text, row) => {
          return (
            <Grid container key={row.foodlist_id}>
              <Grid item lg={12} md={12}>
                <Grid
                  container
                  direction="column"
                  className="container-row-content flex-wrap-unset"
                >
                  <Grid item>
                    <Grid item lg={12} md={12}>
                      <label className="text-14 text-semi-bold text-primary">{row.foodlist}</label>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          );
        },
      },
      {
        title: 'Total Lunch',
        dataIndex: 'total_lunch',
        align: 'right',
        width: '20%',
        render: (text, row) => {
          return (
            <Grid container>
              <Grid item lg={12} md={12}>
                <Grid
                  container
                  direction="column"
                  className="container-row-content flex-wrap-unset"
                >
                  <label className="text-14">{row.summary.total_lunch}</label>
                </Grid>
              </Grid>
            </Grid>
          );
        },
      },
      {
        title: 'Total Dinner',
        dataIndex: 'total_dinner',
        align: 'right',
        width: '20%',
        render: (text, row) => {
          return (
            <Grid container>
              <Grid item lg={12} md={12}>
                <Grid
                  container
                  direction="column"
                  className="container-row-content flex-wrap-unset"
                >
                  <label className="text-14">{row.summary.total_dinner}</label>
                </Grid>
              </Grid>
            </Grid>
          );
        },
      },
    ];
  };

  render() {
    const {
      eatData: { foodlistSummary },
    } = this.props;
    const { isLoading, loading, toastInformation, selectedRowKeys } = this.state;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.handleSelectChangeRow,
    };

    let renderElement = <SkeletonMain />;

    if (!isLoading) {
      renderElement = (
        <div className="container-page-meal-summary">
          <div className="container-page-scrolling-area include-tab">
            <Grid container direction="column" className="flex-wrap-unset">
              <Grid item lg md sm className="section-page-header">
                {this.renderFilter()}
              </Grid>
              <Grid item lg md className="section-page-body">
                <Table
                  rowSelection={{
                    type: 'checkbox',
                    ...rowSelection,
                  }}
                  columns={this.renderColumns()}
                  rowKey={record => record.foodlist_id}
                  dataSource={foodlistSummary.meals_delivery_summary}
                  loading={loading}
                  onChange={this.handleTableChange}
                />
              </Grid>
            </Grid>
            <SnackBarSimple
              open={toastInformation.isOpen}
              durationHide={2000}
              message={toastInformation.message}
              onClickClose={this.handleCloseToast}
              snackbarType={toastInformation.snackbarType}
              anchor={optionToast}
            />
          </div>
        </div>
      );
    }
    return renderElement;
  }
}

const mapDispatchToProps = dispatch => ({
  listFoodlist: () => getListFoodlist(dispatch),
  summaryFoodlist: body => getFoodlistSummary(dispatch, body),
  foodlistSummaryPrint: params => getFoodlistSummaryPrint(dispatch, params),
});

const mapStateToProps = ({ masterDataMain, eatData, usersReducer }) => ({
  masterDataMain,
  eatData,
  usersReducer,
});

MealDeliverySummary.propTypes = {
  eatData: PropTypes.object,
  foodlistSummaryPrint: PropTypes.func,
  history: PropTypes.object,
  listFoodlist: PropTypes.func,
  onResetPrevStateValue: PropTypes.func,
  summaryFoodlist: PropTypes.func,
  usersReducer: PropTypes.object,
};

const shell = compose(connect(mapStateToProps, mapDispatchToProps));
const core = compose(AuthenticationAccessPages, PrevStateValue);

export default shell(core(MealDeliverySummary));
