/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Grid, Breadcrumbs, Link, FormControl, FormLabel } from '@material-ui/core';
import _ from 'lodash';
import { compose } from 'redux';
// style
import './RequestDetailsStyle.scss';
// component
import {
  SnackBarSimple,
  ButtonMain,
  GridRowTableOneColumn,
  LabelStatusMain,
  GalleryDocument,
  ModalAlertAction,
  TextAreaMain,
  CardAlert,
  UploadDocument,
  LabelStatusPayment,
  AuthenticationAccessPages,
  PrevStateValue,
  SkeletonDetailsV03,
} from '../../../../../components/Index';
// api
import {
  getMembershipRequestDetails,
  setUpdateMembershipRequestApproveDecline,
} from '../../../../../services/api/MembershipApi';
// helper
import {
  CommonHelper,
  membershipRequestTypeStatusCode,
  MasterDataHelper,
  membershipRequestTypeCode,
  PermissionModule,
  PermissionPage,
} from '../../../../../helpers/Index';

const optionToash = {
  vertical: 'top',
  horizontal: 'right',
};

const initialValidation = {
  reason: { isError: '', errorMessage: '' },
};

class RequestDetails extends React.Component {
  constructor(props) {
    super(props);

    props.checkUserAccessPermission(
      PermissionModule.Memberships,
      PermissionPage.Request,
      'VIEW_REQUEST_DETAILS',
    );

    this.state = {
      requestId: null,
      reason: null,
      isOpenModal: false,
      isLoading: false,
      validation: CommonHelper.objectCloning(initialValidation),
      attachment: '',
      toastInformation: {
        isOpen: false,
        message: '',
        snackbarType: 'warning',
      },
      modalDetailDataDecline: {
        title: 'Confirmation',
        body: 'Are you sure want to Decline this request ',
      },
    };
  }

  componentDidMount() {
    this.getMembershipRequestDetails();
  }

  componentWillUnmount() {}

  getMembershipRequestDetails() {
    const {
      getRequestDetails,
      match: { params },
    } = this.props;
    const requestId = params.request_id;
    const param = { request_id: requestId };

    this.setState({ requestId }, () => {
      getRequestDetails(param).catch(error => {
        const message = error.data;
        this.processMessage(message.messages, 'error');
      });
    });
  }

  setUpdateMembershipRequestApproveDecline(param) {
    const { setUpdateRequestApproveDecline, history } = this.props;

    setUpdateRequestApproveDecline(param)
      .then(response => {
        const message = response.messages;

        this.processMessage(message, 'success');
        setTimeout(async () => {
          await history.push('/membership/request');
        }, 2000);
      })
      .catch(error => {
        const message = error.data;
        this.processMessage(message.messages, 'error');
        this.setState({ isLoading: false });
      });
  }

  handleCloseToash = () => {
    const { toastInformation } = this.state;
    this.setState({ toastInformation: { ...toastInformation, isOpen: false } });
  };

  handleClick = (event, value) => {
    const { history } = this.props;
    event.preventDefault();
    history.push(value);
  };

  handleButtonCancel = () => {
    const { requestId } = this.state;
    const {
      membership: { details },
    } = this.props;

    const param = {
      data: {
        request_id: requestId,
        action: 'cancel',
        note: `${details.request_info.reason} (This Request has been Canceled)`,
      },
    };

    this.setState({ isLoading: true }, () => {
      this.setUpdateMembershipRequestApproveDecline(param);
    });
  };

  handleButtonApprove = () => {
    const { requestId } = this.state;

    const param = {
      data: {
        request_id: requestId,
        action: 'approve',
        declined_reason: null,
      },
    };

    this.setState({ isLoading: true }, () => {
      this.setUpdateMembershipRequestApproveDecline(param);
    });
  };

  handleButtonDecline = () => {
    const { modalDetailDataDecline, requestId } = this.state;

    this.setState({
      isOpenModal: true,
      modalDetailDataDecline: {
        ...modalDetailDataDecline,
        body: `Are you sure want to Decline Request from ID ${requestId} ? `,
      },
    });
  };

  handleTextChangeReason = value => {
    const { validation } = this.state;
    const message = CommonHelper.objectCloning(initialValidation.reason);

    this.setState({
      reason: value,
      validation: { ...validation, reason: message },
    });
  };

  handleCloseModalDecline = () => {
    const { validation } = this.state;
    const message = CommonHelper.objectCloning(initialValidation.reason);

    this.setState({
      isOpenModal: false,
      reason: '',
      validation: { ...validation, reason: message },
    });
  };

  handleButtonDeclineSubmit = () => {
    const { requestId, reason, validation } = this.state;

    if (!reason || reason === '') {
      const message = { isError: 'error', errorMessage: 'Reason Still Empty' };

      this.setState({ isLoading: false, validation: { ...validation, reason: message } });
    } else {
      const param = {
        data: {
          request_id: requestId,
          action: 'decline',
          declined_reason: reason,
        },
      };

      this.setState({ isLoading: true }, () => {
        this.setUpdateMembershipRequestApproveDecline(param);
      });
    }
  };

  handleButtonReSubmit = () => {
    const { requestId, attachment } = this.state;

    const param = {
      data: {
        request_id: requestId,
        action: 'resubmit',
        attachment,
      },
    };

    this.setState({ isLoading: true }, () => {
      this.setUpdateMembershipRequestApproveDecline(param);
    });
  };

  handleChangeFile = fileBase64 => {
    this.setState({
      attachment: fileBase64[0].base64file,
    });
  };

  processMessage(messages, type) {
    const convertedMessage = CommonHelper.generateMessage(messages);

    const paramInformation = {
      isOpen: true,
      message: convertedMessage,
      snackbarType: type,
    };

    this.setState({
      toastInformation: paramInformation,
    });
  }

  renderButtonRequestAction() {
    const {
      membership: { details },
    } = this.props;
    const { isLoading } = this.state;

    let renderElement = null;

    const paymentMethodValid =
      MasterDataHelper.paymentMethodOption[7].name === details.payment_details.payment_method;

    switch (details.request_status) {
      case membershipRequestTypeStatusCode.decline:
        renderElement = (
          <React.Fragment>
            {paymentMethodValid ? (
              <Grid item lg={2} md={2}>
                <ButtonMain type="ghost" labelText="Cancel" onClick={this.handleButtonCancel} />
              </Grid>
            ) : null}
            <Grid item lg={2} md={2}>
              <ButtonMain
                type="primary"
                labelText="Submit"
                onClick={this.handleButtonReSubmit}
                isLoading={isLoading}
              />
            </Grid>
          </React.Fragment>
        );
        break;
      case membershipRequestTypeStatusCode.review:
        renderElement = (
          <React.Fragment>
            <Grid item lg={2} md={2}>
              <ButtonMain
                type="danger"
                labelText="Decline"
                onClick={this.handleButtonDecline}
                isLoading={isLoading}
              />
            </Grid>
            <Grid item lg={2} md={2}>
              <ButtonMain
                type="primary"
                labelText="Approve"
                onClick={this.handleButtonApprove}
                isLoading={isLoading}
              />
            </Grid>
          </React.Fragment>
        );
        break;
      default:
        break;
    }

    return renderElement;
  }

  renderModalDecline() {
    const { isOpenModal, reason } = this.state;
    const {
      membership: { fetching },
    } = this.props;
    let elementRender = null;

    if (isOpenModal) {
      elementRender = (
        <ModalAlertAction
          onButtonSubmit={this.handleButtonDeclineSubmit}
          onCloseModal={this.handleCloseModalDecline}
          modalDetail={this.state.modalDetailDataDecline}
          modalType="danger"
          buttonSubmitText="Submit"
          openModal={isOpenModal}
          isLoading={fetching}
          customElementProps={
            <TextAreaMain
              onChange={this.handleTextChangeReason}
              placeholder="Reason"
              rows={3}
              currentValue={reason}
              validateStatus={this.state.validation.reason.isError}
              errorMessage={this.state.validation.reason.errorMessage}
            />
          }
        />
      );
    }

    return elementRender;
  }

  renderStatus = value => {
    let renderElement = <LabelStatusMain value="On Process" type="default" />;

    if (
      value === membershipRequestTypeStatusCode.decline ||
      value === membershipRequestTypeStatusCode.cancel
    ) {
      let wording = 'Declined';

      if (value === membershipRequestTypeStatusCode.cancel) {
        wording = 'Cancel';
      }
      renderElement = <LabelStatusMain value={wording} type="danger" />;
    } else if (value === membershipRequestTypeStatusCode.complete) {
      renderElement = <LabelStatusMain value="Approved" type="complete" />;
    }

    return <div className="container-status">{renderElement}</div>;
  };

  renderContainerRequestDetail = details => {
    let renderElement = null;
    if (!_.isEmpty(details)) {
      renderElement = (
        <Grid item lg md>
          <Grid container justify="flex-start">
            {details.map((item, index) => {
              let convertedValue = item.value;
              const currentCol = index !== 1 && index !== 2 ? 3 : 2;

              if (CommonHelper.checkValidDate(convertedValue)) {
                convertedValue = CommonHelper.dateTimeParseNewFormat(
                  convertedValue,
                  'DD MMM YYYY HH:mm',
                );
              }

              return (
                <React.Fragment key={index}>
                  {index !== 0 ? (
                    <Grid item>
                      <div className="divider-hr-vertical" />
                    </Grid>
                  ) : null}
                  <Grid item lg={currentCol} md={currentCol}>
                    <div className="flex-column">
                      <label className="text-12 title">{item.name}</label>
                      <label className="text-12">{convertedValue}</label>
                    </div>
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </Grid>
      );
    }
    return renderElement;
  };

  renderContainerRequestPayment = details => {
    let renderElement = null;
    if (!_.isEmpty(details)) {
      renderElement = (
        <Grid item lg={4} md={4}>
          <Grid container justify="space-between" alignItems="center" className="container-payment">
            <Grid item lg={6} md={6}>
              <div className="flex-column method">
                <label className="text-12 title">Payment Method</label>
                <label className={`text-12 wrapping-container-break`}>
                  {`${details.payment_method} ${
                    details.trace_number ? ` - ${details.trace_number}` : ''
                  }`}
                </label>
              </div>
            </Grid>
            <Grid item lg={6} md={6}>
              <div className="flex-column amount">
                <label className="text-12 title">Fee Amount</label>
                <label className={`text-14 body`}>
                  {`IDR ${CommonHelper.formatCurrency(details.payment_amount) || 0}`}
                </label>
                <LabelStatusPayment status={details.payment_status} />
              </div>
            </Grid>
          </Grid>
        </Grid>
      );
    }
    return renderElement;
  };

  render() {
    const { toastInformation } = this.state;
    const {
      membership: { details, fetching },
    } = this.props;
    const prevUrl = '/membership/request';

    let renderElement = <SkeletonDetailsV03 />;

    if (!fetching && !_.isEmpty(details)) {
      renderElement = (
        <div>
          <Helmet title="FITCO | Membership - Request Details" />
          <div className="container-page-request-details scroll-container">
            <div className="container-page-scrolling-area">
              <Grid container direction="column" className="flex-wrap-unset">
                <Grid item lg md className="section-page-header">
                  <div className="breadcrumbs-section">
                    <Breadcrumbs aria-label="breadcrumb">
                      <Link
                        className="text-12"
                        color="inherit"
                        href={prevUrl}
                        onClick={event => {
                          this.handleClick(event, prevUrl);
                        }}
                      >
                        <i className="ic-ffo-memberships container-icon-prefix size-16" />
                        Membership
                      </Link>
                      <Link
                        className="text-12"
                        color="inherit"
                        href={prevUrl}
                        onClick={event => {
                          this.handleClick(event, prevUrl);
                        }}
                      >
                        Request
                      </Link>
                      <label className="text-12" color="inherit">
                        Details
                      </label>
                    </Breadcrumbs>
                  </div>
                </Grid>
                <Grid item lg md className="section-page-body">
                  <div className="request-details-card">
                    <Grid container spacing={3}>
                      <Grid item lg md>
                        <Grid
                          container
                          direction="column"
                          className="container-row-table flex-wrap-unset"
                        >
                          <Grid item lg md className="container-main-title">
                            <label className="text-16 main-title">
                              {details.request_type_name}
                            </label>
                          </Grid>
                          <Grid item lg md>
                            <Grid
                              container
                              justify="flex-start"
                              className="flex-wrap-unset container-details"
                            >
                              <Grid item lg={2} md={2}>
                                <div className="flex-column">
                                  <label className="text-12 title">Requested by</label>
                                  <label className="text-14 wrapping-container second line">
                                    {details.requested_by || '-'}
                                  </label>
                                </div>
                              </Grid>
                              <Grid item lg={2} md={2}>
                                <div className="flex-column">
                                  <label className="text-12 title">Initiated by</label>
                                  <label className="text-14 wrapping-container second line">
                                    {details.initiate_by || '-'}
                                  </label>
                                </div>
                              </Grid>
                              <Grid item lg={2} md={2}>
                                <div className="flex-column">
                                  <label className="text-12 title">Status</label>
                                  {this.renderStatus(details.request_status)}
                                </div>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item lg md>
                            <Grid
                              container
                              justify="space-between"
                              alignItems="center"
                              className="flex-wrap-unset container-details container-product"
                            >
                              <Grid item lg md>
                                <Grid container direction="column">
                                  <Grid item lg md>
                                    <label className="text-14 title-product wrapping-container second line">
                                      {details.product_name}
                                    </label>
                                  </Grid>
                                  {this.renderContainerRequestDetail(details.request_details)}
                                </Grid>
                              </Grid>
                              {this.renderContainerRequestPayment(details.payment_details)}
                            </Grid>
                          </Grid>
                          {details.request_status === membershipRequestTypeStatusCode.decline ? (
                            <Grid item lg md className="container-details">
                              <CardAlert
                                iconPrefix="ic-ffo-alert"
                                alertType="danger"
                                customElements={
                                  <label className="text-14">
                                    {details.declined_reason || '-'}
                                  </label>
                                }
                              />
                            </Grid>
                          ) : null}
                          <Grid item lg md className="title-table">
                            <label className="text-12">Request Info</label>
                          </Grid>
                          <Grid item className="container-table">
                            <GridRowTableOneColumn
                              customElementInitial={<label className="text-14">Name</label>}
                              customElementColumn={
                                <label className="text-14">
                                  {details.request_info.name || '-'}
                                </label>
                              }
                            />
                            <GridRowTableOneColumn
                              customElementInitial={<label className="text-14">Email</label>}
                              customElementColumn={
                                <label className="text-14">
                                  {details.request_info.email || '-'}
                                </label>
                              }
                            />
                            <GridRowTableOneColumn
                              customElementInitial={<label className="text-14">Phone Number</label>}
                              customElementColumn={
                                <label className="text-14">
                                  {details.request_info.phone_number || '-'}
                                </label>
                              }
                            />
                            <GridRowTableOneColumn
                              customElementInitial={
                                <label className="text-14">Purchase Date</label>
                              }
                              customElementColumn={
                                <label className="text-14">
                                  {CommonHelper.dateTimeParseNewFormat(
                                    details.request_info.purchase_date,
                                    'DD MMM YYYY',
                                  ) || '-'}
                                </label>
                              }
                            />
                            <GridRowTableOneColumn
                              customElementInitial={<label className="text-14">branch</label>}
                              customElementColumn={
                                <label className="text-14">
                                  {details.request_info.branch_name || '-'}
                                </label>
                              }
                            />
                            <GridRowTableOneColumn
                              customElementInitial={<label className="text-14">Reason</label>}
                              customElementColumn={
                                <label className="text-14">
                                  {details.request_info.reason || '-'}
                                </label>
                              }
                            />
                            <GridRowTableOneColumn
                              customElementInitial={<label className="text-14">Document</label>}
                              customElementColumn={
                                <Grid container direction="column">
                                  {details.request_status ===
                                  membershipRequestTypeStatusCode.decline ? (
                                    <Grid item lg={3} md={3}>
                                      {details.request_type ===
                                      membershipRequestTypeCode.purchase ? (
                                        <UploadDocument
                                          labelText="Upload Document (img)"
                                          onFileChange={this.handleChangeFile}
                                          fileType="img"
                                          size="md"
                                        />
                                      ) : (
                                        <UploadDocument
                                          labelText="Upload Document (pdf/img)"
                                          onFileChange={this.handleChangeFile}
                                          fileType="pdf/img"
                                          size="md"
                                        />
                                      )}
                                    </Grid>
                                  ) : null}
                                  <Grid item lg={12} md={12}>
                                    {details.attachment_url.length !== 0 ? (
                                      <FormControl component="fieldset" fullWidth margin={'normal'}>
                                        <FormLabel component="label" className="form-label">
                                          Existing Document
                                        </FormLabel>
                                        <GalleryDocument dataFile={details.attachment_url} />
                                      </FormControl>
                                    ) : details.request_status !==
                                      membershipRequestTypeStatusCode.decline ? (
                                      <label className="text-14">-</label>
                                    ) : null}
                                  </Grid>
                                </Grid>
                              }
                            />
                          </Grid>
                          <Grid item>
                            <Grid container justify="flex-end" spacing={3}>
                              {this.renderButtonRequestAction()}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
                <Grid item lg md className="section-page-footer" />
              </Grid>
            </div>
          </div>
          {this.renderModalDecline()}
          <SnackBarSimple
            open={toastInformation.isOpen}
            durationHide={2000}
            message={toastInformation.message}
            onClickClose={this.handleCloseToash}
            snackbarType={toastInformation.snackbarType}
            anchor={optionToash}
          />
        </div>
      );
    }

    return renderElement;
  }
}

const mapDispatchToProps = dispatch => ({
  getRequestDetails: params => getMembershipRequestDetails(dispatch, params),
  setUpdateRequestApproveDecline: params =>
    setUpdateMembershipRequestApproveDecline(dispatch, params),
});

const mapStateToProps = ({ membership }) => ({
  membership,
});

RequestDetails.propTypes = {
  checkUserAccessPermission: PropTypes.func,
  getRequestDetails: PropTypes.func,
  history: PropTypes.object,
  match: PropTypes.object,
  membership: PropTypes.object,
  setUpdateRequestApproveDecline: PropTypes.func,
};

const shell = compose(connect(mapStateToProps, mapDispatchToProps));
const core = compose(AuthenticationAccessPages, PrevStateValue);

export default shell(core(RequestDetails));
