import * as EatAction from '../../redux/actions/EatAction';
import Api from '../../middleware/FetchMiddleware';
import { baseUrl } from '../../environments/Environment';
import { HttpAction } from '../../helpers/HttpHelper';

export const getListEatReportExport = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(EatAction.getListEatReportExportRequest());
    Api.callApi(`${baseUrl}private/pos/v2/product/food/download`, HttpAction.Get, params, {})
      .then(response => {
        dispatch(EatAction.getListEatReportExportSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(EatAction.getListEatReportExportFail(e));
        reject(e);
      });
  });
};

export const getListEatPagination = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(EatAction.getListEatPaginationRequest());
    Api.callApi(`${baseUrl}private/pos/v2/product/food`, HttpAction.Get, params, {})
      .then(response => {
        dispatch(EatAction.getListEatPaginationSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(EatAction.getListEatPaginationFail(e));
        reject(e);
      });
  });
};

export const getEatDetails = (dispatch, id) => {
  return new Promise((resolve, reject) => {
    dispatch(EatAction.getEatDetailsRequest());
    Api.callApi(`${baseUrl}private/pos/v2/product/food/${id}`, HttpAction.Get, {}, {})
      .then(response => {
        dispatch(EatAction.getEatDetailsSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(EatAction.getEatDetailsFail(e));
        reject(e);
      });
  });
};

export const setDeleteEat = (dispatch, id) => {
  return new Promise((resolve, reject) => {
    dispatch(EatAction.setDeleteEatRequest());
    Api.callApi(`${baseUrl}private/pos/v2/product/food/${id}`, HttpAction.Delete, {}, {})
      .then(response => {
        dispatch(EatAction.setDeleteEatSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(EatAction.setDeleteEatFail(e));
        reject(e);
      });
  });
};

export const setCreateEat = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(EatAction.setCreateEatRequest());
    Api.callApi(`${baseUrl}private/pos/v2/product/food`, HttpAction.Put, {}, params)
      .then(response => {
        dispatch(EatAction.setCreateEatSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(EatAction.setCreateEatFail(e));
        reject(e);
      });
  });
};

export const setUpdateEat = (dispatch, params, id) => {
  return new Promise((resolve, reject) => {
    dispatch(EatAction.setUpdateEatRequest());
    Api.callApi(`${baseUrl}private/pos/v2/product/food/${id}`, HttpAction.Patch, {}, params)
      .then(response => {
        dispatch(EatAction.setUpdateEatSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(EatAction.setUpdateEatFail(e));
        reject(e);
      });
  });
};

export const getListMealDeliveryPagination = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(EatAction.getListMealDeliveryPaginationRequest());
    Api.callApi(`${baseUrl}private/pos/eat/meal-delivery`, HttpAction.Post, {}, params)
      .then(response => {
        dispatch(EatAction.getListMealDeliveryPaginationSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(EatAction.getListMealDeliveryPaginationFail(e));
        reject(e);
      });
  });
};

export const getMealDeliveryDetail = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(EatAction.getMealDeliveryDetailRequest());
    Api.callApi(`${baseUrl}private/pos/eat/meal-delivery/details`, HttpAction.Post, {}, params)
      .then(response => {
        dispatch(EatAction.getMealDeliveryDetailSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(EatAction.getMealDeliveryDetailFail(e));
        reject(e);
      });
  });
};

export const getListMealDeliveryExport = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(EatAction.getListMealDeliveryExportRequest());
    Api.callApi(`${baseUrl}private/pos/eat/meal-delivery/export`, HttpAction.Post, {}, params)
      .then(response => {
        dispatch(EatAction.getListMealDeliveryExportSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(EatAction.getListMealDeliveryExportFail(e));
        reject(e);
      });
  });
};

export const getListFoodlist = dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(EatAction.getListFoodlistRequest());
    Api.callApi(`${baseUrl}private/pos/eat/foodlist/options`, HttpAction.Get, {}, {})
      .then(response => {
        dispatch(EatAction.getListFoodlistSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(EatAction.getListFoodlistFail(e));
        reject(e);
      });
  });
};

export const changeMealDeliveryDetails = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(EatAction.changeMealDeliveryDetailsRequest());
    Api.callApi(`${baseUrl}eat/meal/change-date`, HttpAction.Post, {}, params)
      .then(response => {
        dispatch(EatAction.changeMealDeliveryDetailsSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(EatAction.changeMealDeliveryDetailsFail(e));
        reject(e);
      });
  });
};

export const skipMealDelivery = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(EatAction.skipMealDeliveryRequest());
    Api.callApi(`${baseUrl}eat/meal/skip/admin`, HttpAction.Post, {}, params)
      .then(response => {
        dispatch(EatAction.skipMealDeliverySuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(EatAction.skipMealDeliveryFail(e));
        reject(e);
      });
  });
};

export const changeMealDeliveryAddress = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(EatAction.changeMealDeliveryAddressRequest());
    Api.callApi(`${baseUrl}eat/meal/change-address`, HttpAction.Post, {}, params)
      .then(response => {
        dispatch(EatAction.changeMealDeliveryAddressSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(EatAction.changeMealDeliveryAddressFail(e));
        reject(e);
      });
  });
};

export const getRoomType = dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(EatAction.getRoomTypeRequest());
    Api.callApi(`${baseUrl}private/pos/eat/in-patient/room-type`, HttpAction.Get, {}, {})
      .then(response => {
        dispatch(EatAction.getRoomTypeSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(EatAction.getRoomTypeFail(e));
        reject(e);
      });
  });
};

export const getDietType = dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(EatAction.getDietTypeRequest());
    Api.callApi(`${baseUrl}private/pos/eat/in-patient/diet-type`, HttpAction.Get, {}, {})
      .then(response => {
        dispatch(EatAction.getDietTypeSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(EatAction.getDietTypeFail(e));
        reject(e);
      });
  });
};

export const getCuisineType = dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(EatAction.getCuisineTypeRequest());
    Api.callApi(`${baseUrl}private/pos/eat/in-patient/cuisine-type`, HttpAction.Get, {}, {})
      .then(response => {
        dispatch(EatAction.getCuisineTypeSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(EatAction.getCuisineTypeFail(e));
        reject(e);
      });
  });
};

export const setCreatePatient = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(EatAction.setCreatePatientRequest());
    Api.callApi(`${baseUrl}private/pos/eat/in-patient/patient/register`, HttpAction.Put, {}, params)
      .then(response => {
        dispatch(EatAction.setCreatePatientSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(EatAction.setCreatePatientFail(e));
        reject(e);
      });
  });
};

export const getPatientListPagination = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(EatAction.getPatientListPaginationRequest());
    Api.callApi(`${baseUrl}private/pos/eat/in-patient/patient`, HttpAction.Post, {}, params)
      .then(response => {
        dispatch(EatAction.getPatientListPaginationSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(EatAction.getPatientListPaginationFail(e));
        reject(e);
      });
  });
};

export const getPatientDetail = (dispatch, userRoomId) => {
  return new Promise((resolve, reject) => {
    dispatch(EatAction.getPatientDetailRequest());
    Api.callApi(
      `${baseUrl}private/pos/eat/in-patient/patient/${userRoomId}`,
      HttpAction.Get,
      {},
      {},
    )
      .then(response => {
        dispatch(EatAction.getPatientDetailSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(EatAction.getPatientDetailFail(e));
        reject(e);
      });
  });
};

export const getPatientMealList = (dispatch, userRoomId, params) => {
  return new Promise((resolve, reject) => {
    dispatch(EatAction.getPatientMealListRequest());
    Api.callApi(
      `${baseUrl}private/pos/eat/in-patient/patient/${userRoomId}/meal`,
      HttpAction.Get,
      params,
      {},
    )
      .then(response => {
        dispatch(EatAction.getPatientMealListSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(EatAction.getPatientMealListFail(e));
        reject(e);
      });
  });
};

export const setCreatePatientMealPlan = (dispatch, userRoomId, params) => {
  return new Promise((resolve, reject) => {
    dispatch(EatAction.createPatientMealPlanRequest());
    Api.callApi(
      `${baseUrl}private/pos/eat/in-patient/patient/${userRoomId}/meal`,
      HttpAction.Put,
      {},
      params,
    )
      .then(response => {
        dispatch(EatAction.createPatientMealPlanSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(EatAction.createPatientMealPlanFail(e));
        reject(e);
      });
  });
};

export const updatePatientDetail = (dispatch, id, params) => {
  return new Promise((resolve, reject) => {
    dispatch(EatAction.updatePatientDetailRequest());
    Api.callApi(`${baseUrl}private/pos/eat/in-patient/patient/${id}`, HttpAction.Patch, {}, params)
      .then(response => {
        dispatch(EatAction.updatePatientDetailSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(EatAction.updatePatientDetailFail(e));
        reject(e);
      });
  });
};

export const setCheckoutPatient = (dispatch, userRoomId) => {
  return new Promise((resolve, reject) => {
    dispatch(EatAction.setCheckoutPatientRequest());
    Api.callApi(
      `${baseUrl}private/pos/eat/in-patient/patient/${userRoomId}/check-out`,
      HttpAction.Post,
      {},
      {},
    )
      .then(response => {
        dispatch(EatAction.setCheckoutPatientSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(EatAction.setCheckoutPatientFail(e));
        reject(e);
      });
  });
};

export const getPatientMealPlanExport = (dispatch, userRoomId) => {
  return new Promise((resolve, reject) => {
    dispatch(EatAction.exportPatientMealPlanRequest());
    Api.callApi(
      `${baseUrl}private/pos/eat/in-patient/patient/${userRoomId}/meal/summary`,
      HttpAction.Get,
      {},
      {},
    )
      .then(response => {
        dispatch(EatAction.exportPatientMealPlanSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(EatAction.exportPatientMealPlanFail(e));
        reject(e);
      });
  });
};

export const getListPatientExport = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(EatAction.getListPatientExportRequest());
    Api.callApi(`${baseUrl}private/pos/eat/in-patient/summary`, HttpAction.Post, {}, params)
      .then(response => {
        dispatch(EatAction.getListPatientExportSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(EatAction.getListPatientExportFail(e));
        reject(e);
      });
  });
};

export const printPatientLabel = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(EatAction.printPatientLabelRequest());
    Api.callApi(`${baseUrl}private/pos/eat/in-patient/label`, HttpAction.Post, {}, params)
      .then(response => {
        dispatch(EatAction.printPatientLabelSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(EatAction.printPatientLabelFail(e));
        reject(e);
      });
  });
};

export const searchPatientByMedicalRecordNumber = (dispatch, medicalRecordNumber) => {
  return new Promise((resolve, reject) => {
    dispatch(EatAction.searchPatientByMedicalRecordNumberRequest());
    Api.callApi(
      `${baseUrl}private/pos/eat/in-patient/patient/medical-record/${medicalRecordNumber}`,
      HttpAction.GET,
      {},
      {},
    )
      .then(response => {
        dispatch(EatAction.searchPatientByMedicalRecordNumberSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(EatAction.searchPatientByMedicalRecordNumberFail(e));
        reject(e);
      });
  });
};

export const searchNearestEatStoreLocation = (dispatch, lat, lng) => {
  return new Promise((resolve, reject) => {
    dispatch(EatAction.searchNearestEatStoreLocationRequest());
    Api.callApi(
      `${baseUrl}public/mobile/eat/store?latitude=${lat}&longitude=${lng}&is_dashboard=true`,
      HttpAction.Post,
      {},
      {},
    )
      .then(response => {
        dispatch(EatAction.searchNearestEatStoreLocationSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(EatAction.searchNearestEatStoreLocationFail(e));
        reject(e);
      });
  });
};

export const getRoomNameList = dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(EatAction.getRoomNameListRequest());
    Api.callApi(`${baseUrl}private/pos/eat/in-patient/room-name`, HttpAction.Get, {}, {})
      .then(response => {
        dispatch(EatAction.getRoomNameListSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(EatAction.getRoomNameListFail(e));
        reject(e);
      });
  });
};

export const getFoodlistSummary = (dispatch, body) => {
  return new Promise((resolve, reject) => {
    dispatch(EatAction.getFoodlistSummaryRequest());
    Api.callApi(`${baseUrl}private/pos/eat/meal-delivery/summary`, HttpAction.Post, {}, body)
      .then(response => {
        dispatch(EatAction.getFoodlistSummarySuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(EatAction.getFoodlistSummaryFail(e));
        reject(e);
      });
  });
};

export const getFoodlistSummaryPrint = (dispatch, body) => {
  return new Promise((resolve, reject) => {
    dispatch(EatAction.getFoodlistSummaryPrintRequest());
    Api.callApi(`${baseUrl}private/pos/eat/meal-delivery/summary/print`, HttpAction.Post, {}, body)
      .then(response => {
        dispatch(EatAction.getFoodlistSummaryPrintSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(EatAction.getFoodlistSummaryPrintFail(e));
        reject(e);
      });
  });
};
