import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Grid, Breadcrumbs, Link } from '@material-ui/core';
import PropTypes from 'prop-types';
import { compose } from 'redux';
// style
import './LabBookStyle.scss';
// component
import {
  NavigationStep,
  SnackBarSimple,
  AuthenticationAccessPages,
} from '../../../../components/Index';
import {
  StepBookingUserDetails,
  StepBookingSummaryAndPayment,
  StepBookingProductDetails,
  StepBookingServiceDetails,
} from './views/Index';
// helper
import {
  CommonHelper,
  HttpStatusCode,
  PermissionModule,
  PermissionPage,
  PermissionAccess,
  productTypeForOrderCode,
  paymentMethodOptionCode,
  OrderHelper,
  serviceLocationCode,
} from '../../../../helpers/Index';
// api
import { setProcessCreateOrderBooking } from '../../../../services/api/ScheduleDataApi';

const optionToast = {
  vertical: 'top',
  horizontal: 'right',
};

class LabBook extends React.Component {
  constructor(props) {
    super(props);

    const {
      match: { params },
    } = this.props;

    props.checkUserAccessPermission(
      PermissionModule.Orders,
      PermissionPage.List,
      PermissionAccess.View,
    );

    const updateForm = {
      ...CommonHelper.decryptObject(params.param_object),
      orderType: productTypeForOrderCode.medic,
    };

    this.state = {
      current: 0,
      form: updateForm,
      stepList: [
        { key: 'user_detail', name: 'User Details' },
        { key: 'service_detail', name: 'Service Details' },
        { key: 'booking_detail', name: 'Booking Details' },
        { key: 'summary', name: 'Summary' },
      ],
      toastInformation: {
        isOpen: false,
        message: '',
        snackbarType: 'warning',
      },
    };
  }

  componentDidUpdate() {
    const {
      membership: { fetching, errorMessage },
      history,
    } = this.props;

    if (!fetching && errorMessage && errorMessage.code === HttpStatusCode.Forbidden) {
      history.push('/membership/purchase');
    }
  }

  handleClick = (event, value) => {
    const { history } = this.props;
    event.preventDefault();
    history.push(value);
  };

  handleButtonClickCancel = () => {
    const { history } = this.props;
    history.push('/medic/lab');
  };

  handleButtonClickNext = value => {
    this.setState({ current: value });
  };

  handleButtonClickPrev = value => {
    this.setState({ current: value });
  };

  handleButtonClickReschedule = () => {
    this.setState({ current: 1 });
  };

  handleParameterUpdate = (formValue, key) => {
    const { form } = this.state;
    this.setState({
      form: {
        ...form,
        [key]: formValue,
      },
    });
  };

  getPaymentTypePayload = formBookingSummary => {
    const { form } = this.state;
    let modifyPayment = {};

    switch (formBookingSummary.paymentMethod) {
      case paymentMethodOptionCode.bank_transfer:
        modifyPayment = {
          payment_type: formBookingSummary.paymentMethod,
          bank: formBookingSummary.bank.value,
          transaction_reference: formBookingSummary.transactionReference,
        };
        break;
      case paymentMethodOptionCode.edc:
        modifyPayment = {
          payment_type: formBookingSummary.paymentMethod,
          bank: `${form.step_booking_product_details.selectedService.merchant_id}-${form.step_booking_product_details.selectedService.merchant_name}`,
          account_number: `${form.step_booking_product_details.selectedService.branch_id}-${form.step_booking_product_details.selectedService.name}`,
          name: `${form.step_booking_user_details.firstName} ${form.step_booking_user_details
            .lastName || ''}`,
          transaction_reference: formBookingSummary.transactionReference,
        };
        break;
      case paymentMethodOptionCode.credit_card:
        modifyPayment = {
          payment_type: formBookingSummary.paymentMethod,
          payment_channel: 'xendit',
          payment_status: 0,
          transaction_reference: formBookingSummary.transactionReference,
        };
        break;
      default:
        modifyPayment = {
          payment_type: formBookingSummary.paymentMethod,
          transaction_reference: formBookingSummary.transactionReference,
        };
        break;
    }

    return modifyPayment;
  };

  handleButtonClickSubmit = formBookingSummary => {
    const { processCreateOrderBooking, history } = this.props;
    const { form } = this.state;
    const isHomeService =
      form.step_booking_product_details.serviceLocation === serviceLocationCode.AtHome;

    const serviceDetails = {
      service_location: form.step_booking_product_details.serviceLocation,
      date: form.step_booking_product_details.form.date,
      time: form.step_booking_product_details.form.time,
      branch_id: form.step_booking_product_details.form.branchId,
    };
    let userDetails = {
      user_id: form.step_booking_user_details.userId,
      first_name: form.step_booking_user_details.firstName,
      last_name: form.step_booking_user_details.lastName,
      email: form.step_booking_user_details.email,
      phone: form.step_booking_user_details.phone,
      birthday: form.step_booking_user_details.birthday,
      gender: form.step_booking_user_details.gender.value,
    };
    const modifyPayment = this.getPaymentTypePayload(formBookingSummary);
    const shippingDetails = {
      shipping_method: 'fitco',
      shipping_title: 'Home Service Fee',
      shipping_cost: formBookingSummary.transportFeeProduct.price,
    };

    const items = OrderHelper.formatItemSubmitLab(form.step_booking_customer_details);

    if (isHomeService) {
      userDetails = {
        ...userDetails,
        address: {
          ...form.step_booking_product_details.form.addressInfo,
        },
        notes: form.step_booking_product_details.form.addressNotes,
      };
    }

    const body = {
      service_details: serviceDetails,
      items,
      save_draft: true,
      order_type: form.orderType,
      user_details: userDetails,
      payment_data: modifyPayment,
      promo_code: formBookingSummary.promoCode,
    };

    if (isHomeService) {
      Object.assign(body, {
        shipping_details: shippingDetails,
      });
    }

    const param = { data: body };

    processCreateOrderBooking(param)
      .then(response => {
        const message = response.messages;
        this.processMessage(message, 'success');
        setTimeout(async () => {
          history.push('/medic/lab');
        }, 1600);
      })
      .catch(error => {
        const serverMessage = error.data;
        const validationStatus = error.status === HttpStatusCode.InternalServerError;
        this.processMessage(
          validationStatus ? serverMessage.message : serverMessage.messages,
          'error',
        );
      });
  };

  handlePopUpInformation = (message, typeMessage) => {
    this.processMessage(message, typeMessage);
  };

  handleCloseToast = () => {
    const { toastInformation } = this.state;
    this.setState({ toastInformation: { ...toastInformation, isOpen: false } });
  };

  processMessage(messages, type) {
    const convertedMessage = CommonHelper.generateMessage(messages);

    const paramInformation = {
      isOpen: true,
      message: convertedMessage,
      snackbarType: type,
    };

    this.setState({
      toastInformation: paramInformation,
    });
  }

  renderActiveContent() {
    const { stepList, current, form } = this.state;
    let renderElement = null;

    switch (stepList[current].key) {
      case stepList[1].key:
        renderElement = (
          <Grid item lg md>
            <StepBookingServiceDetails
              parentForm={form}
              currentValue={current}
              totalStepper={4}
              onParameterUpdate={this.handleParameterUpdate}
              onButtonClickCancel={this.handleButtonClickCancel}
              onButtonClickPrev={this.handleButtonClickPrev}
              onButtonClickNext={this.handleButtonClickNext}
              onPopUpInformation={this.handlePopUpInformation}
            />
          </Grid>
        );
        break;
      case stepList[2].key:
        renderElement = (
          <Grid item lg md>
            <StepBookingProductDetails
              parentForm={form}
              currentValue={current}
              totalStepper={4}
              onParameterUpdate={this.handleParameterUpdate}
              onButtonClickCancel={this.handleButtonClickCancel}
              onButtonClickPrev={this.handleButtonClickPrev}
              onButtonClickNext={this.handleButtonClickNext}
              onPopUpInformation={this.handlePopUpInformation}
            />
          </Grid>
        );
        break;
      case stepList[3].key:
        renderElement = (
          <Grid item lg md>
            <StepBookingSummaryAndPayment
              parentForm={form}
              currentValue={current}
              totalStepper={4}
              onParameterUpdate={this.handleParameterUpdate}
              onButtonClickCancel={this.handleButtonClickCancel}
              onButtonClickPrev={this.handleButtonClickPrev}
              onPopUpInformation={this.handlePopUpInformation}
              onButtonClickSubmit={this.handleButtonClickSubmit}
              onButtonClickReschedule={this.handleButtonClickReschedule}
            />
          </Grid>
        );
        break;
      default:
        renderElement = (
          <Grid item lg md>
            <StepBookingUserDetails
              parentForm={form}
              currentValue={current}
              totalStepper={4}
              onParameterUpdate={this.handleParameterUpdate}
              onButtonClickCancel={this.handleButtonClickCancel}
              onButtonClickNext={this.handleButtonClickNext}
              onPopUpInformation={this.handlePopUpInformation}
            />
          </Grid>
        );
        break;
    }

    return renderElement;
  }

  render() {
    const { current, stepList, toastInformation } = this.state;
    const prevUrl = '/medic/lab';

    return (
      <div>
        <Helmet title="FITCO | Lab Booking" />
        <div className="container-page-membership-purchase-add scroll-container-invisible">
          <div className="container-page-scrolling-area">
            <Grid container direction="column">
              <Grid item lg md className="section-page-header mv-24">
                <div className="breadcrumbs-section">
                  <Breadcrumbs aria-label="breadcrumb">
                    <Link
                      className="text-12"
                      color="inherit"
                      href={prevUrl}
                      onClick={event => {
                        this.handleClick(event, prevUrl);
                      }}
                    >
                      <i className="ic-ffo-calendar container-icon-prefix size-16" />
                      Lab
                    </Link>
                    <label className="text-12" color="inherit">
                      Book Lab
                    </label>
                  </Breadcrumbs>
                </div>
              </Grid>
              <Grid item lg md className="section-page-body">
                <div className="flex-column container-main-card p-32">
                  <label className="text-16 text-bold text-rolling-stone  mb-32">Create Book</label>
                  <Grid item lg md>
                    <NavigationStep currentValue={current} arrayLabel={stepList} />
                  </Grid>
                  {this.renderActiveContent()}
                </div>
              </Grid>
              <Grid item lg md className="section-page-footer" />
            </Grid>
          </div>
        </div>
        <SnackBarSimple
          open={toastInformation.isOpen}
          durationHide={2000}
          message={toastInformation.message}
          onClickClose={this.handleCloseToast}
          snackbarType={toastInformation.snackbarType}
          anchor={optionToast}
        />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  processCreateOrderBooking: params => setProcessCreateOrderBooking(dispatch, params),
});

const mapStateToProps = ({ membership }) => ({ membership });

LabBook.propTypes = {
  checkUserAccessPermission: PropTypes.func,
  history: PropTypes.object,
  match: PropTypes.object,
  membership: PropTypes.object,
  processCreateOrderBooking: PropTypes.func,
};

const shell = compose(connect(mapStateToProps, mapDispatchToProps));

export default shell(AuthenticationAccessPages(LabBook));
