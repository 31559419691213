import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// component
import { SelectInputSearchMain } from '../../Index';
// api
import { getRoleList } from '../../../services/api/MasterDataApi';
// helper
import { CommonHelper } from '../../../helpers/Index';

class SelectInputRole extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    this.getMasterDataList();
  }

  componentWillUnmount() {}

  async getMasterDataList() {
    const { apiDataList } = this.props;
    const param = {};

    apiDataList(param);
  }

  handleSelectItem = value => {
    const { onChange } = this.props;

    onChange(value);
  };

  generateListDataDisplay() {
    const { masterData, includeAllData } = this.props;
    const converted = [];
    let param = [];
    const currentData = CommonHelper.renameKeyName(masterData.roleList, 'id', 'value');

    if (includeAllData) {
      param = { value: null, name: 'Select Role' };
      converted.push(param);
    }

    if (currentData.length > 0) {
      currentData.forEach(item => {
        param = { value: item.value, name: item.name };
        converted.push(param);
      });
    }

    return converted;
  }

  render() {
    const {
      placeHolder,
      defaultValue,
      currentValue,
      errorMessage,
      validateStatus,
      disabled,
    } = this.props;

    const ListCustom = this.generateListDataDisplay();

    return (
      <SelectInputSearchMain
        options={ListCustom}
        onChange={item => {
          this.handleSelectItem(item);
        }}
        currentValue={currentValue}
        defaultValue={defaultValue}
        placeholder={placeHolder}
        errorMessage={errorMessage}
        validateStatus={validateStatus}
        disabled={disabled}
      />
    );
  }
}

const mapDispatchToProps = dispatch => ({
  apiDataList: params => getRoleList(dispatch, params),
});

const mapStateToProps = ({ masterData }) => ({ masterData });

SelectInputRole.propTypes = {
  apiDataList: PropTypes.func,
  currentValue: PropTypes.number,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  errorMessage: PropTypes.string,
  includeAllData: PropTypes.bool,
  masterData: PropTypes.object,
  onChange: PropTypes.func,
  placeHolder: PropTypes.string,
  validateStatus: PropTypes.string,
};

SelectInputRole.defaultProps = {
  placeHolder: '',
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectInputRole);
