/* eslint-disable react/no-access-state-in-setstate */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Table } from 'antd';
import _ from 'lodash';
import { Grid, FormControl, FormLabel, Fade } from '@material-ui/core';
// style
import './CompanyPageStyle.scss';
// component
import {
  TextInput,
  SelectInputMain,
  SnackBarSimple,
  ButtonIconMain,
  ModalAlertAction,
  LabelStatusMain,
  TextAreaMain,
  PrevStateValue,
  SkeletonPagination,
} from '../../../../../components/Index';
// api
import {
  getListCompanyPagination,
  setDeleteCompany,
  setUpdateCompany,
} from '../../../../../services/api/MasterDataMainAPi';
// helper
import {
  MasterDataHelper,
  CommonHelper,
  ValidationHelper,
  UserStatus,
  GlobalCodeStatus,
  PermissionModule,
  PermissionPage,
  PermissionAccess,
} from '../../../../../helpers/Index';
import { compose } from 'redux';

const initialFilter = {
  search: '',
};

const initialForm = {
  name: null,
  email: null,
  phone: null,
  status: GlobalCodeStatus.Active,
  key: null,
};

const modalDetailDataEdit = {
  title: 'Edit Company',
};
const initialValidation = {
  name: { isError: false, errorMessage: '' },
  email: { isError: false, errorMessage: '' },
  phone: { isError: false, errorMessage: '' },
  reason: { isError: '', errorMessage: '' },
};

const resetValidation = { isError: false, errorMessage: '' };

const initialSort = {
  sortField: 'name',
  sortOrder: '',
};

const optionToash = {
  vertical: 'top',
  horizontal: 'right',
};

const maxStringLength = 100;
const timeInterval = 300;
const optionStatus = MasterDataHelper.statusGlobal.slice(
  0,
  MasterDataHelper.statusGlobal.length - 1,
);

class CompanyPage extends React.Component {
  constructor(props) {
    super(props);
    const {
      usersReducer: { prevStateValue },
    } = props;

    const updateExitingFilter = { ...CommonHelper.objectCloning(initialFilter), ...prevStateValue };

    this.state = {
      filter: updateExitingFilter,
      page: 1,
      limit: 10,
      sort: CommonHelper.objectCloning(initialSort),
      pagination: {
        showSizeChanger: true,
        showTotal: (total, range) => `Showing ${range[0]}-${range[1]} From ${total}`,
        pageSizeOptions: ['10', '20', '30', '40'],
      },
      selectedDataDelete: null,
      selectedId: null,
      loading: true,
      isLoading: true,
      isOpenModalEdit: false,
      isOpenModalDelete: false,
      form: CommonHelper.objectCloning(initialForm),
      validation: CommonHelper.objectCloning(initialValidation),
      lockoutReason: '',
      currentStringLength: 0,
      toastInformation: {
        isOpen: false,
        message: '',
        snackbarType: 'warning',
      },
      modalDetailDataDelete: {
        title: 'Confirmation',
        body: 'Are you sure want to Company ',
      },
    };
    this.searchDebounce = _.debounce(this.searchDebounce, 400);
  }

  componentDidMount() {
    this.getListCompanyPagination();
  }

  componentDidUpdate(prevProps) {
    const { isLoadingParent } = this.props;

    if (prevProps.isLoadingParent !== isLoadingParent) {
      this.getListCompanyPagination();
    }
  }

  componentWillUnmount() {}

  getListCompanyPagination = () => {
    const { listCompanyPagination } = this.props;
    const {
      limit,
      pagination,
      page,
      filter: { search },
      sort,
    } = this.state;

    const params = {
      search,
      page,
      limit,
      sort: sort.sortField,
      sortOrder: sort.sortOrder,
    };

    this.setState({ loading: true }, () => {
      listCompanyPagination(params).then(response => {
        this.setState({
          loading: false,
          isLoading: false,
          selectedId: null,
          pagination: { ...pagination, pageSize: limit, total: response.total },
        });
      });
    });
  };

  setDeleteCompany(param = {}, key = null) {
    const { deleteCompany } = this.props;

    deleteCompany(param, key)
      .then(async response => {
        const message = response.messages;

        this.handleCloseModalDelete();
        await this.getListCompanyPagination();
        await this.processMessage(message, 'success');
      })
      .catch(error => {
        const message = error.data;
        this.processMessage(message.messages, 'error');
      });
  }

  handleChangeSearch = value => {
    const { filter } = this.state;

    this.setState(
      {
        filter: { ...filter, search: value },
        page: 1,
        pagination: { ...this.state.pagination, current: 1 },
      },
      () => {
        this.searchDebounce();
      },
    );
  };

  handleSelectStatus = value => {
    const { form } = this.state;

    this.setState({ form: { ...form, status: value } });
  };

  handleMouseEnter = value => {
    this.setState({ selectedId: value.company_id });
  };

  handleMouseLeave = () => {
    this.setState({ selectedId: null });
  };

  handleCloseToash = () => {
    const { toastInformation } = this.state;
    this.setState({ toastInformation: { ...toastInformation, isOpen: false } });
  };

  handleButtonEdit = data => {
    const { form } = this.state;

    this.setState({
      isOpenModalEdit: true,
      form: {
        ...form,
        company_id: data.company_id,
        name: data.name,
        email: data.email || '',
        phone: data.phone || '',
        status: data.status,
        key: data.key,
      },
      currentStringLength: data.name.length,
    });
  };

  handleCloseModalEdit = () => {
    this.setState({ isOpenModalEdit: false });
  };

  handleCloseModalDelete = () => {
    const { validation } = this.state;
    const message = { isError: '', errorMessage: '' };

    this.setState({
      isOpenModalDelete: false,
      lockoutReason: '',
      validation: { ...validation, reason: message },
    });
  };

  handleButtonSaveCompany = () => {
    const { filter, sort, page, limit, form } = this.state;
    const { updateCompany } = this.props;

    if (this.checkValidationForm()) {
      const param = {
        data: {
          company_id: form.company_id,
          name: form.name.trim(),
          email: form.email,
          status: form.status,
          phone: form.phone,
        },
      };
      const key = form.key;

      this.setState({ isLoading: true }, () => {
        updateCompany(param, key)
          .then(async response => {
            const message = response.messages;
            await this.getListCompanyPagination({
              page,
              sort: sort.sortField,
              sortOrder: sort.sortOrder,
              search: filter.search,
              limit,
            });
            await this.setState(
              {
                form: { ...form, name: null, email: null, phone: null },
                isOpenModalEdit: false,
                isLoading: false,
              },
              () => {
                this.processMessage(message, 'success');
              },
            );
          })
          .catch(error => {
            const message = error.data;
            this.setState(
              {
                isLoading: false,
              },
              () => {
                this.processMessage(message.messages, 'error');
              },
            );
          });
      });
    }
  };

  handleButtonDeleted = data => {
    const { modalDetailDataDelete } = this.state;

    this.setState({
      isOpenModalDelete: true,
      selectedDataDelete: data,
      modalDetailDataDelete: {
        ...modalDetailDataDelete,
        body: `Are you sure want to delete Company ${data.name} ? `,
      },
    });
  };

  handleButtonSubmitDelete = () => {
    const { lockoutReason, validation, selectedDataDelete } = this.state;

    if (!lockoutReason || lockoutReason === '') {
      const message = { isError: 'error', errorMessage: 'Reason Still Empty' };

      this.setState({
        validation: { ...validation, reason: message },
      });
    } else {
      const param = {
        data: {
          company_id: selectedDataDelete.company_id,
          lockout_reason: lockoutReason.trim(),
        },
      };
      const key = selectedDataDelete.key;
      this.setDeleteCompany(param, key);
    }
  };

  handleTableChange = (pagination, filters, sorter) => {
    const { sort } = this.state;

    this.setState(
      {
        limit: pagination.pageSize,
        pagination: {
          ...this.state.pagination,
          current: pagination.current,
          pageSize: pagination.pageSize,
        },
        sort: {
          ...sort,
          sortField: sorter.field,
          sortOrder: sorter.order === 'ascend' ? 'asc' : 'desc',
        },
        page: pagination.current,
      },
      () => {
        this.getListCompanyPagination();
      },
    );
  };

  handleCloseModalEdit = () => {
    const { validation, form } = this.state;
    const message = { isError: false, errorMessage: '' };

    this.setState({
      isOpenModalEdit: false,
      form: { ...form, name: '', email: '', phone: '' },
      validation: {
        ...validation,
        reason: message,
        name: message,
        email: message,
        phone: message,
      },
    });
  };

  handleTextChangeCompanyName = value => {
    const { validation, form } = this.state;
    const message = {
      isError: false,
      errorMessage: '',
    };

    if (!CommonHelper.checkStringLengthOver(value, maxStringLength)) {
      this.setState({
        form: { ...form, name: value },
        currentStringLength: value.length,
        validation: { ...validation, name: message },
      });
    }
  };

  handleTextChangeCompanyEmail = value => {
    const { validation, form } = this.state;
    const emailValidation = ValidationHelper.validateEmail(value.trim());

    const message = {
      isError: false,
      errorMessage: '',
    };

    this.setState(
      {
        form: { ...form, email: value.trim() },
      },
      () => {
        if (!emailValidation && value) {
          message.isError = true;
          message.errorMessage = 'Please check email, and try again';
        }
        this.setState({
          validation: { ...validation, email: message },
        });
      },
    );
  };

  handleTextChangeCompanyPhone = value => {
    const { validation, form } = this.state;

    const phoneValidation = ValidationHelper.validatePhone(value.trim());
    const phoneValidationLocal = ValidationHelper.validatePhoneLocal(value.trim());

    const message = {
      isError: false,
      errorMessage: '',
    };

    this.setState(
      {
        form: { ...form, phone: value.trim() },
      },
      () => {
        if (!phoneValidation && value) {
          if (!phoneValidationLocal && value) {
            message.isError = true;
            message.errorMessage = 'Please check Phone, and try again';
          }
        }
        this.setState({
          validation: { ...validation, phone: message },
        });
      },
    );
  };

  handleTextChangeReason = value => {
    const { validation } = this.state;
    const message = {
      isError: '',
      errorMessage: '',
    };

    this.setState({
      lockoutReason: value,
      validation: { ...validation, reason: message },
    });
  };

  searchDebounce = () => {
    const { filter } = this.state;
    const { onSetPrevStateValue } = this.props;

    onSetPrevStateValue({ ...filter });
    this.getListCompanyPagination();
  };

  processMessage(messages, type) {
    const convertedMessage = CommonHelper.generateMessage(messages);

    const paramInformation = {
      isOpen: true,
      message: convertedMessage,
      snackbarType: type,
    };

    this.setState({
      toastInformation: paramInformation,
    });
  }

  checkValidationForm() {
    const { form, validation } = this.state;
    let passCheck = true;

    let paramValidation01 = resetValidation;

    if (!form.name) {
      paramValidation01 = {
        isError: true,
        errorMessage: 'Company Name Still Empty...',
      };
      passCheck = false;
    }

    if (validation.email.isError) passCheck = false;
    if (validation.phone.isError) passCheck = false;

    if (!passCheck) {
      this.setState({
        validation: {
          ...validation,
          name: paramValidation01,
        },
      });
    }

    return passCheck;
  }

  renderModalUpdate() {
    const { isOpenModalEdit, isLoading } = this.state;
    let elementRender = null;

    if (isOpenModalEdit) {
      elementRender = (
        <ModalAlertAction
          onButtonSubmit={this.handleButtonSaveCompany}
          onCloseModal={this.handleCloseModalEdit}
          modalDetail={modalDetailDataEdit}
          buttonSubmitText="Save"
          openModal={isOpenModalEdit}
          isLoading={isLoading}
          customElementProps={
            <Grid container direction="column">
              <Grid item lg md>
                <FormControl component="fieldset" fullWidth margin={'normal'}>
                  <FormLabel component="label" className="form-label text-12">
                    Company Name
                  </FormLabel>
                  <Grid container direction="column">
                    <Grid item lg md>
                      <TextInput
                        placeHolderText="Company Name"
                        onChange={this.handleTextChangeCompanyName}
                        currentValue={this.state.form.name}
                        errorMessage={this.state.validation.name.errorMessage}
                        isError={this.state.validation.name.isError}
                      />
                    </Grid>
                    <Grid item lg md>
                      <Grid container justify="flex-end">
                        <Grid item>
                          <label className="text-10">{`${this.state.currentStringLength} / ${maxStringLength}`}</label>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </FormControl>
              </Grid>
              <Grid item lg md>
                <FormControl component="fieldset" fullWidth margin={'normal'}>
                  <FormLabel component="label" className="form-label text-12">
                    Company Email <label>( Optional )</label>
                  </FormLabel>
                  <TextInput
                    placeHolderText="Company Email"
                    onChange={this.handleTextChangeCompanyEmail}
                    currentValue={this.state.form.email}
                    errorMessage={this.state.validation.email.errorMessage}
                    isError={this.state.validation.email.isError}
                  />
                </FormControl>
              </Grid>
              <Grid item lg md>
                <FormControl component="fieldset" fullWidth margin={'normal'}>
                  <FormLabel component="label" className="form-label text-12">
                    Company Phone <label>( Optional )</label>
                  </FormLabel>
                  <TextInput
                    placeHolderText="Company Phone Number"
                    onChange={this.handleTextChangeCompanyPhone}
                    currentValue={this.state.form.phone}
                    errorMessage={this.state.validation.phone.errorMessage}
                    isError={this.state.validation.phone.isError}
                  />
                </FormControl>
                <FormControl component="fieldset" fullWidth margin={'normal'}>
                  <FormLabel component="label" className="form-label text-12">
                    Status
                  </FormLabel>
                  <SelectInputMain
                    options={optionStatus}
                    currentValue={this.state.form.status}
                    onChange={this.handleSelectStatus}
                    size="large"
                  />
                </FormControl>
              </Grid>
            </Grid>
          }
        />
      );
    }

    return elementRender;
  }

  renderModalDelete() {
    const { isOpenModalDelete, isLoading, lockoutReason } = this.state;
    let elementRender = null;

    if (isOpenModalDelete) {
      elementRender = (
        <ModalAlertAction
          onButtonSubmit={this.handleButtonSubmitDelete}
          onCloseModal={this.handleCloseModalDelete}
          modalDetail={this.state.modalDetailDataDelete}
          modalType="danger"
          buttonSubmitText="Submit"
          openModal={isOpenModalDelete}
          isLoading={isLoading}
          customElementProps={
            <TextAreaMain
              onChange={this.handleTextChangeReason}
              placeholder="Reason"
              rows={3}
              currentValue={lockoutReason}
              validateStatus={this.state.validation.reason.isError}
              errorMessage={this.state.validation.reason.errorMessage}
            />
          }
        />
      );
    }

    return elementRender;
  }

  renderStatus = value => {
    let renderElement = <LabelStatusMain value="Active" type="complete" />;
    if (value === UserStatus.Deleted) {
      renderElement = <LabelStatusMain value="Deleted" type="danger" />;
    } else if (value === UserStatus.Inactive) {
      renderElement = <LabelStatusMain value="Inactive" type="default" />;
    }
    return <div className="container-status">{renderElement}</div>;
  };

  renderColumns = () => {
    const { selectedId } = this.state;

    return [
      {
        title: 'Company Name',
        dataIndex: 'name',
        sorter: true,
        render: (text, row) => {
          return (
            <Grid container direction="column" className="container-row-company flex-wrap-unset">
              <Grid item lg md className="container-info-header">
                <div className="flex-column">
                  <span className="flex-row">
                    <label className="text-14 title wrapping-container">{row.name}</label>
                    {this.renderStatus(row.status)}
                  </span>
                  <label className="text-10">ID : {row.company_id}</label>
                </div>
              </Grid>
              <Grid item lg md>
                <Grid container justify="flex-start" className="container-info-body">
                  <Grid item lg={3} md={4}>
                    <span className="flex-row">
                      <i className="ic-ffo-mail container-icon-prefix size-16" />
                      <label className="text-12 wrapping-container">{row.email || '-'}</label>
                    </span>
                    <span className="flex-row">
                      <i className="ic-ffo-phone container-icon-prefix size-16" />
                      <label className="text-12 wrapping-container">{row.phone || '-'}</label>
                    </span>
                  </Grid>
                  <Grid item lg={3} md={4}>
                    <div className="flex-column">
                      <label className="text-12">Merchant</label>
                      <span className="flex-row">
                        <i className="ic-ffo-merchant container-icon-prefix size-16" />
                        <label className="text-12 wrapping-container">{`${row.merchants} Total Merchant`}</label>
                      </span>
                    </div>
                  </Grid>
                  <Grid item lg={3} md={4}>
                    <div className="flex-column">
                      <label className="text-12">Branch</label>
                      <span className="flex-row">
                        <i className="ic-ffo-merchant container-icon-prefix size-16" />
                        <label className="text-12 wrapping-container">{`${row.branches} Total Branches`}</label>
                      </span>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          );
        },
      },
      {
        width: '15%',
        align: 'center',
        render: (text, row) => {
          const inSelect = row.company_id === selectedId;

          return (
            <Fade in={inSelect} timeout={timeInterval}>
              <Grid container justify="center">
                <Grid item>
                  <ButtonIconMain
                    icon="ic-ffo-edit"
                    type="secondary"
                    size="sm"
                    onClick={() => this.handleButtonEdit(row)}
                    requiredPermission={`${PermissionModule.MasterData}.${PermissionPage.Company}.${PermissionAccess.Update}`}
                  />
                </Grid>
                {row.status !== UserStatus.Deleted && (
                  <Grid item>
                    <ButtonIconMain
                      icon="ic-ffo-bin"
                      type="negative"
                      size="sm"
                      onClick={() => this.handleButtonDeleted(row)}
                      requiredPermission={`${PermissionModule.MasterData}.${PermissionPage.Company}.${PermissionAccess.Delete}`}
                    />
                  </Grid>
                )}
              </Grid>
            </Fade>
          );
        },
      },
    ];
  };

  renderFilter() {
    return (
      <Grid container direction="row" justify="flex-start" className="container-filter">
        <Grid item lg={4} md={4} className="row-filter">
          <TextInput
            iconPrefix="ic-ffo-search"
            placeHolderText="Search"
            onChange={this.handleChangeSearch}
            size="md"
          />
        </Grid>
      </Grid>
    );
  }

  render() {
    const { masterDataMain } = this.props;
    const { isLoading, loading, pagination, toastInformation } = this.state;
    // eslint-disable-next-line no-unused-vars
    const adjustTableHeight = window.innerHeight - 350;
    const ListCustom = masterDataMain.list;

    let renderElement = (
      <div className="mt-18">
        <SkeletonPagination />
      </div>
    );

    if (!isLoading) {
      renderElement = (
        <div className="container-body-table">
          <div className="container-table">
            <Grid container direction="column">
              <Grid item lg md sm className="section-page-header">
                {this.renderFilter()}
              </Grid>
              <Grid item lg md className="section-table-body">
                <Table
                  columns={this.renderColumns()}
                  rowKey={record => record.company_id}
                  dataSource={ListCustom}
                  pagination={pagination}
                  loading={loading}
                  // scroll={{ y: adjustTableHeight }}
                  onChange={this.handleTableChange}
                  onRow={record => {
                    return {
                      onMouseEnter: () => {
                        this.handleMouseEnter(record);
                      }, // mouse enter row
                      onMouseLeave: () => {
                        this.handleMouseLeave();
                      },
                    };
                  }}
                />
              </Grid>
            </Grid>
            {this.renderModalUpdate()}
            {this.renderModalDelete()}
            <SnackBarSimple
              open={toastInformation.isOpen}
              durationHide={2000}
              message={toastInformation.message}
              onClickClose={this.handleCloseToash}
              snackbarType={toastInformation.snackbarType}
              anchor={optionToash}
            />
          </div>
        </div>
      );
    }
    return (
      <div className="container-page-company scroll-container">
        <div className="container-page-scrolling-area include-tab">
          {renderElement}
        </div>
        {this.renderModalUpdate()}
        {this.renderModalDelete()}
        <SnackBarSimple
          open={toastInformation.isOpen}
          durationHide={2000}
          message={toastInformation.message}
          onClickClose={this.handleCloseToash}
          snackbarType={toastInformation.snackbarType}
          anchor={optionToash}
        />
      </div>
      );
  }
}

const mapDispatchToProps = dispatch => ({
  listCompanyPagination: params => getListCompanyPagination(dispatch, params),
  deleteCompany: (params, key) => setDeleteCompany(dispatch, params, key),
  updateCompany: (params, key) => setUpdateCompany(dispatch, params, key),
});

const mapStateToProps = ({ masterDataMain }) => ({
  masterDataMain,
});

CompanyPage.propTypes = {
  deleteCompany: PropTypes.func,
  isLoadingParent: PropTypes.bool,
  listCompanyPagination: PropTypes.func,
  masterDataMain: PropTypes.object,
  onSetPrevStateValue: PropTypes.func,
  updateCompany: PropTypes.func,
  usersReducer: PropTypes.object,
};

const shell = compose(connect(mapStateToProps, mapDispatchToProps));

export default shell(PrevStateValue(CompanyPage));
