import { Images } from '../../../assets/Index';

const StylesJs = () => ({
  mainImageContent: {
    height: '50vh',
    width: '100%',
    backgroundImage: `url(${Images.mainPagePageNotFound})`,
    backgroundSize: 'contain',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
  },
});

export default StylesJs;
