import React from 'react';
import PropTypes from 'prop-types';
// Style
import './EmptyStateStyle.scss';

const EmptyState = props => {
  const { labelText, source } = props;

  return (
    <div className="" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <img
        src={source}
        alt="empty-state"
        className="empty-state-size"
      />
      <div>
        <label className="empty-state-text">{labelText}</label>
      </div>
    </div>
  );
};

EmptyState.propTypes = {
  labelText: PropTypes.string,
  source: PropTypes.string,
};

export default EmptyState;
