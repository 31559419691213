import { combineReducers } from 'redux';
import usersReducer from './UsersReducer';
import UserManagementReducer from './UserManagementReducer';
import MasterReducer from './MasterReducer';
import scheduleReducer from './ScheduleReducer';
import TncReducer from './TncReducer';
import PopupScreenReducer from './PopupScreenReducer';
import MasterDataMainReducer from './MasterDataMainReducer';
import ProductCategoryReducer from './ProductCategoryReducer';
import ReportReducer from './ReportReducer';
import MembershipReducer from './MembershipReducer';
import SegmentReducer from './SegmentReducer';
import OrderReducer from './OrderReducer';
import CampaignReducer from './CampaignReducer';
import FeedReducer from './FeedReducer';
import EatReducer from './EatReducer';
import InboxReducer from './InboxReducer';
import DashboardReducer from './DashboardReducer';
import SettingData from './SettingData';
import PromoCodeReducer from './PromoCodeReducer';
import MiscellaneousReducer from './MiscellaneousReducer';
import MedicReducer from './MedicReducer';
import EventReducer from './EventReducer';

const rootReducer = combineReducers({
  usersReducer,
  userManagement: UserManagementReducer,
  masterData: MasterReducer,
  productCategory: ProductCategoryReducer,
  scheduleSummary: scheduleReducer,
  tncData: TncReducer,
  popupScreenData: PopupScreenReducer,
  masterDataMain: MasterDataMainReducer,
  reportData: ReportReducer,
  membership: MembershipReducer,
  segmentData: SegmentReducer,
  orderData: OrderReducer,
  campaignData: CampaignReducer,
  feedData: FeedReducer,
  eatData: EatReducer,
  inboxData: InboxReducer,
  dashboardData: DashboardReducer,
  settingData: SettingData,
  promoCodeData: PromoCodeReducer,
  miscellaneousData: MiscellaneousReducer,
  medicData: MedicReducer,
  eventData: EventReducer,
});

export default rootReducer;
