import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
// Component
import { CheckInput } from '../../Index';

const CheckInputMultiple = props => {
  const {
    data,
    // isError,
    // errorMessage,
    onCheck,
  } = props;

  const handleItemChange = name => value => {
    const result = { name, checked: value };
    onCheck(result);
  };

  return (
    <Grid container direction={'column'}>
      {data.map((item, index) => (
        <Grid item key={index}>
          <CheckInput
            colorCheck="primary"
            labelText={item.name}
            onCheck={handleItemChange(item.name)}
          />
        </Grid>
      ))}
    </Grid>
  );
};

CheckInputMultiple.propTypes = {
  // errorMessage: PropTypes.string,
  // isError: PropTypes.bool,
  data: PropTypes.array,
  onCheck: PropTypes.func,
};

export default CheckInputMultiple;
