import React from 'react';
import PropTypes from 'prop-types';
// Style
import './LabelIconStyle.scss';

const LabelIcon = props => {
  const { iconPrefix, labelText, size } = props;
  let currentText = 'text-12';
  let currentIcon = 'size-16';

  switch (size) {
    case 'xl':
      currentText = 'text-16';
      currentIcon = 'size-24';
      break;
    case 'md':
      currentText = 'text-14';
      currentIcon = 'size-16';
      break;
    default:
      currentText = 'text-12';
      currentIcon = 'size-16';
      break;
  }

  return (
    <span className="container-label-icon flex-row">
      <i className={`${iconPrefix} container-icon-prefix ${currentIcon}`} />
      <label className={`${currentText} wrapping-container `}>{labelText}</label>
    </span>
  );
};

LabelIcon.propTypes = {
  iconPrefix: PropTypes.string,
  labelText: PropTypes.string,
  size: PropTypes.string,
};

export default LabelIcon;
