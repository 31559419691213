import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
// Style
import './ButtonActionFooterStyle.scss';
// Component
import { ButtonMain } from '../../../../../components/Index';

const ButtonActionFooterV02 = props => {
  const {
    customElementProps,
    onButtonClickCancel,
    onButtonClickSubmit,
    loadingButtonSubmit,
    isEditMode,
  } = props;

  return (
    <Grid container className="container-card-button-action">
      <Grid item lg md>
        <Grid container justify="space-between" alignItems="center">
          <Grid item lg={3} md={4}>
            {customElementProps}
          </Grid>
          <Grid item lg={3} md={3}>
            <div className="container-button-action">
              <div className="container-button">
                <ButtonMain
                  type="negative"
                  size="xl"
                  labelText="Cancel"
                  onClick={onButtonClickCancel}
                  isLoading={loadingButtonSubmit}
                  disabled={loadingButtonSubmit}
                />
              </div>
              <div className="container-button">
                <ButtonMain
                  type="primary"
                  size="xl"
                  labelText={isEditMode ? 'Update' : 'Submit'}
                  onClick={onButtonClickSubmit}
                  isLoading={loadingButtonSubmit}
                  disabled={loadingButtonSubmit}
                />
              </div>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

ButtonActionFooterV02.propTypes = {
  customElementProps: PropTypes.element,
  isEditMode: PropTypes.bool,
  loadingButtonSubmit: PropTypes.bool,
  onButtonClickCancel: PropTypes.func,
  onButtonClickSubmit: PropTypes.func,
};

export default ButtonActionFooterV02;
