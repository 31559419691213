import * as masterAction from '../../redux/actions/MasterDataMainAction';
import Api from '../../middleware/FetchMiddleware';
import { baseUrl } from '../../environments/Environment';
import { HttpAction } from '../../helpers/Index';

// #region company
export const getListCompanyPagination = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(masterAction.getListCompanyPaginationRequest());
    Api.callApi(`${baseUrl}private/pos/v2/company/list?`, HttpAction.Get, params, {})
      .then(response => {
        dispatch(masterAction.getListCompanyPaginationSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(masterAction.getListCompanyPaginationFail(e));
        reject(e);
      });
  });
};

export const setCreateCompany = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(masterAction.createCompanyRequest());
    Api.callApi(`${baseUrl}private/pos/v2/company`, HttpAction.Post, {}, params)
      .then(response => {
        dispatch(masterAction.createCompanySuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(masterAction.createCompanyFail(e));
        reject(e);
      });
  });
};

export const setUpdateCompany = (dispatch, params, key) => {
  return new Promise((resolve, reject) => {
    dispatch(masterAction.updateCompanyRequest());
    Api.callApi(`${baseUrl}private/pos/v2/company/${key}`, HttpAction.Put, {}, params)
      .then(response => {
        dispatch(masterAction.updateCompanySuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(masterAction.updateCompanyFail(e));
        reject(e);
      });
  });
};

export const setDeleteCompany = (dispatch, params, key) => {
  return new Promise((resolve, reject) => {
    dispatch(masterAction.deleteCompanyRequest());
    Api.callApi(`${baseUrl}private/pos/v2/company/${key}`, HttpAction.Delete, {}, params)
      .then(response => {
        dispatch(masterAction.deleteCompanySuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(masterAction.deleteCompanyFail(e));
        reject(e);
      });
  });
};
// #endregion

// #region merchant
export const getListMerchantPagination = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(masterAction.getListMerchantPaginationRequest());
    Api.callApi(`${baseUrl}private/pos/v2/merchant/list?`, HttpAction.Get, params, {})
      .then(response => {
        dispatch(masterAction.getListMerchantPaginationSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(masterAction.getListMerchantPaginationFail(e));
        reject(e);
      });
  });
};

export const setDeleteMerchant = (dispatch, params, key) => {
  return new Promise((resolve, reject) => {
    dispatch(masterAction.deleteMerchantRequest());
    Api.callApi(`${baseUrl}private/pos/v2/merchant/${key}`, HttpAction.Delete, {}, params)
      .then(response => {
        dispatch(masterAction.deleteMerchantSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(masterAction.deleteMerchantFail(e));
        reject(e);
      });
  });
};

export const setCreateMerchant = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(masterAction.createMerchantRequest());
    Api.callApi(`${baseUrl}private/pos/v2/merchant`, HttpAction.Post, {}, params)
      .then(response => {
        dispatch(masterAction.createMerchantSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(masterAction.createMerchantFail(e));
        reject(e);
      });
  });
};

export const setUpdateMerchant = (dispatch, params, key) => {
  return new Promise((resolve, reject) => {
    dispatch(masterAction.updateMerchantRequest());
    Api.callApi(`${baseUrl}private/pos/v2/merchant/${key}`, HttpAction.Put, {}, params)
      .then(response => {
        dispatch(masterAction.updateMerchantSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(masterAction.updateMerchantFail(e));
        reject(e);
      });
  });
};

export const getMerchantDetails = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(masterAction.getMerchantDetailsRequest());
    Api.callApi(`${baseUrl}private/pos/v2/merchant`, HttpAction.get, params, {})
      .then(response => {
        dispatch(masterAction.getMerchantDetailsSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(masterAction.getMerchantDetailsFail(e));
        reject(e);
      });
  });
};
// #endregion

// #region branch

export const setCreateBranch = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(masterAction.createBranchRequest());
    Api.callApi(`${baseUrl}private/pos/v2/branch`, HttpAction.Post, {}, params)
      .then(response => {
        dispatch(masterAction.createBranchSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(masterAction.createBranchFail(e));
        reject(e);
      });
  });
};

export const getListBranchPagination = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(masterAction.getListBranchPaginationRequest());
    Api.callApi(`${baseUrl}private/pos/v2/branch/list?`, HttpAction.Get, params, {})
      .then(response => {
        dispatch(masterAction.getListBranchPaginationSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(masterAction.getListBranchPaginationFail(e));
        reject(e);
      });
  });
};

export const setUpdateBranch = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(masterAction.updateBranchRequest());
    Api.callApi(`${baseUrl}private/pos/v2/branch`, HttpAction.Put, {}, params)
      .then(response => {
        dispatch(masterAction.updateBranchSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(masterAction.updateBranchFail(e));
        reject(e);
      });
  });
};

export const setDeleteBranch = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(masterAction.deleteBranchRequest());
    Api.callApi(`${baseUrl}private/pos/v2/branch`, HttpAction.Delete, {}, params)
      .then(response => {
        dispatch(masterAction.deleteBranchSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(masterAction.deleteBranchFail(e));
        reject(e);
      });
  });
};

export const getBranchDetails = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(masterAction.getBranchDetailsRequest());
    Api.callApi(`${baseUrl}private/pos/v2/branch`, HttpAction.get, params, {})
      .then(response => {
        dispatch(masterAction.getBranchDetailsSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(masterAction.getBranchDetailsFail(e));
        reject(e);
      });
  });
};
// #endregion

// #region product category

export const setCreateProductCategory = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(masterAction.createProductCategoryRequest());
    Api.callApi(`${baseUrl}private/pos/v2/category`, HttpAction.Post, {}, params)
      .then(response => {
        dispatch(masterAction.createProductCategorySuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(masterAction.createProductCategoryFail(e));
        reject(e);
      });
  });
};

export const setUpdateProductCategory = (dispatch, params, key) => {
  return new Promise((resolve, reject) => {
    dispatch(masterAction.updateProductCategoryRequest());
    Api.callApi(`${baseUrl}private/pos/v2/category/${key}`, HttpAction.Put, {}, params)
      .then(response => {
        dispatch(masterAction.updateProductCategorySuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(masterAction.updateProductCategoryFail(e));
        reject(e);
      });
  });
};

export const getProductCategoryDetails = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(masterAction.getProductCategoryDetailsRequest());
    Api.callApi(`${baseUrl}private/pos/v2/category`, HttpAction.get, params, {})
      .then(response => {
        dispatch(masterAction.getProductCategoryDetailsSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(masterAction.getProductCategoryDetailsFail(e));
        reject(e);
      });
  });
};
// #endregion

// #region Product Wholesale
export const getProductWholesale = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(masterAction.getProductWholesaleRequest());
    Api.callApi(`${baseUrl}private/pos/v2/product/wholesale-catalog`, HttpAction.Post, null, params)
      .then(response => {
        dispatch(masterAction.getProductWholesaleSuccess(response));
        return resolve(response);
      })
      .catch(error => {
        dispatch(masterAction.getProductWholesaleFail(error));
        reject(error);
      });
  });
};
// #endregion
