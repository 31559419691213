import React from 'react';
import PropTypes from 'prop-types';
import { Grid, FormControl } from '@material-ui/core';
import { Table } from 'antd';
// Style
import './ModalAddFoodStyle.scss';
// Component
import {
  ModalAlertAction,
  TextInputAutocomplete,
  TextAreaMain,
  TextInputNumber,
} from '../../../../../components/Index';
// helper
import { CommonHelper } from '../../../../../helpers/Index';

const ModalAddFood = props => {
  const {
    isOpen,
    searchTerm,
    loading,
    lists,
    listSelectedItem,
    pagination,
    isGuest,
    onCloseModal,
    onApplyButton,
    onHandleQueryProduct,
    onHandleSelectItem,
    onHandleTableChange,
    onHandleNotesEatAtStore,
    onHandleButtonNumberStepper,
  } = props;

  const modalDetailDataCreate = {
    title: 'Add More Items',
  };

  const handleChangeTextProduct = value => {
    onHandleQueryProduct(value);
  };

  const renderColumns = () => {
    const columns = [
      {
        title: 'Product',
        dataIndex: 'name',
        sorter: true,
        render: (text, row) => {
          return (
            <span className="container-item-cart flex-row">
              <img
                src={row.images || row.product_images[0].image_url}
                className="custom-image mr-16"
                alt="item_image"
              />
              <div className="flex-column full-width">
                <label className="text-14">{row.name || row.product_name}</label>
                <label className="text-10 pt-2 item-info">Product ID: {row.product_id}</label>
                <div className="mb-10">
                  <FormControl component="fieldset" fullWidth margin="dense">
                    <TextAreaMain
                      onChange={onHandleNotesEatAtStore(row.product_id)}
                      placeholder="Add Notes"
                      rows={2}
                      maxLength={50}
                    />
                  </FormControl>
                </div>
              </div>
            </span>
          );
        },
      },
      {
        title: 'Price',
        width: '20%',
        align: 'right',
        render: (text, row) => {
          const selectedPrice = isGuest ? row.normal_price : row.special_price || row.price;
          const amount = CommonHelper.formatCurrency(selectedPrice);

          return (
            <div className="container-item-cart">
              <label className="text-14">{`IDR ${amount}`}</label>
            </div>
          );
        },
      },
      {
        title: 'Quantity',
        width: '10%',
        align: 'center',
        render: (text, row) => {
          return (
            <TextInputNumber
              placeHolderText={'Qty'}
              currentValue={row.quantity}
              onChange={onHandleButtonNumberStepper(row.product_id)}
            />
          );
        },
      },
      {
        title: 'Subtotal',
        width: '20%',
        align: 'center',
        render: (text, row) => {
          const selectedPrice = isGuest ? row.normal_price : row.special_price || row.price;
          const totalAmount = parseInt(selectedPrice, 10) * row.quantity;

          return (
            <div className="container-item-cart price">
              <label className="text-14">{`IDR ${CommonHelper.formatCurrency(totalAmount)}`}</label>
            </div>
          );
        },
      },
    ];

    return columns;
  };

  const elementRenderCustom = (
    <div className="container-modal-add-food flex-column">
      <Grid item lg md>
        <Grid container direction="column">
          <Grid item xl={6} lg={6} md={6} className="mb-16">
            <TextInputAutocomplete
              placeHolderText="Search Food"
              size="md"
              onChange={handleChangeTextProduct}
              onSelect={onHandleSelectItem}
              currentValue={searchTerm}
              isLoading={loading}
              data={lists}
              isCustomDisplay
              customDisplayClass="custom-simple-option-item-menu"
            />
          </Grid>
          <Table
            columns={renderColumns()}
            rowKey={record => record.product_id}
            dataSource={listSelectedItem}
            pagination={pagination}
            scroll={{ y: 400 }}
            onChange={onHandleTableChange}
          />
        </Grid>
      </Grid>
    </div>
  );

  return (
    <ModalAlertAction
      onButtonSubmit={onApplyButton}
      onCloseModal={onCloseModal}
      modalDetail={modalDetailDataCreate}
      buttonSubmitText="Add"
      openModal={isOpen}
      customElementProps={elementRenderCustom}
      size={'large'}
    />
  );
};

ModalAddFood.propTypes = {
  isGuest: PropTypes.bool,
  isOpen: PropTypes.bool,
  lists: PropTypes.array,
  listSelectedItem: PropTypes.array,
  loading: PropTypes.bool,
  onApplyButton: PropTypes.func,
  onCloseModal: PropTypes.func,
  onHandleButtonNumberStepper: PropTypes.func,
  onHandleNotesEatAtStore: PropTypes.func,
  onHandleQueryProduct: PropTypes.func,
  onHandleSelectItem: PropTypes.func,
  onHandleTableChange: PropTypes.func,
  pagination: PropTypes.object,
  searchTerm: PropTypes.string,
};

export default ModalAddFood;
