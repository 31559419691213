import { StyleSheet } from '@react-pdf/renderer';
import Themes from '../../../../../themes/Index';

const StylesJs = StyleSheet.create({
  containerCardLabel: {
    width: '75mm',
  },
  containerLabel: {
    height: '35mm',
    borderRadius: 8,
    paddingVertical: 7,
    paddingHorizontal: 7,
    marginBottom: '5mm',
  },
  flex1: {
    flex: 1,
  },
  containerName: {
    marginVertical: 3,
  },
  textCustomerName: {
    color: Themes.palette.black,
    fontSize: 8,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
  },
  textName: {
    color: Themes.palette.black,
    fontSize: 8,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
  },
  textHeading: {
    fontSize: 7,
    fontWeight: 'bold',
    color: Themes.palette.rollingStone,
    marginBottom: 1,
    fontFamily: 'Roboto',
  },
  textLabel: {
    fontSize: 7,
    fontWeight: 'normal',
    color: Themes.palette.rollingStone,
    fontFamily: 'Roboto',
  },
  textExtras: {
    fontSize: 7,
    fontWeight: 'bold',
    color: Themes.palette.rollingStone,
    paddingTop: 6,
    fontFamily: 'Roboto',
  },
  text: {
    fontSize: 6,
    fontWeight: 'normal',
    color: Themes.palette.mineShaft,
    fontFamily: 'Roboto',
  },
  textDate: {
    fontSize: 6,
    fontWeight: 'normal',
    color: Themes.palette.mineShaft,
    fontFamily: 'Roboto',
  },
  textPax: {
    fontSize: 6,
    fontWeight: 'normal',
    color: Themes.palette.rollingStone,
    fontFamily: 'Roboto',
  },
  textNotes: {
    fontSize: 6,
    fontWeight: 'normal',
    color: Themes.palette.mineShaft,
    fontFamily: 'Roboto',
  },
});

export default StylesJs;
