import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import _ from 'lodash';
// helper
import { CommonHelper } from '../../../../../../../../helpers/Index';
// Style
import './DetailsAppointmentStyle.scss';

const DetailsAppointment = props => {
  const { orderData } = props;
  const appointmentDetails = !_.isEmpty(orderData) ? orderData : {};

  return (
    <Grid container className="container-appointment-details pt-24" direction="column">
      <Grid item lg={12} md={12} className="pb-16">
        <label className="text-12 text-bold text-rolling-stone">Appointment Details</label>
      </Grid>
      <Grid item lg={12} md={12} className="order-card-details">
        <Grid container direction="column">
          <Grid item lg={12} md={12}>
            <div className="flex-column pb-24">
              <label className="text-12 text-bold text-rolling-stone wrapping-container wrapping-container-break mb-8">
                Schedule
              </label>
              <label className="text-14 wrapping-container wrapping-container-break">
                {CommonHelper.dateTimeParseNewFormat(
                  appointmentDetails.appointment_date,
                  'DD MMM YYYY | LT',
                )}
              </label>
            </div>
          </Grid>
          <Grid item lg={12} md={12}>
            <div className="flex-column pb-24">
              <label className="text-12 text-bold text-rolling-stone mb-8">Location</label>
              <label className="text-14 wrapping-container third line wrapping-container-break">
                {appointmentDetails.location || '-'}
              </label>
            </div>
          </Grid>
          <Grid item lg={12} md={12}>
            <div className="flex-column">
              <label className="text-12 text-bold text-rolling-stone mb-8">Address</label>
              <label className="text-14 wrapping-container third line wrapping-container-break">
                {appointmentDetails.address || '-'}
              </label>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

DetailsAppointment.propTypes = {
  orderData: PropTypes.object,
};

export default DetailsAppointment;
