import React from 'react';
import PropTypes from 'prop-types';
import { Grid, FormControl } from '@material-ui/core';
import _ from 'lodash';
// Style
import './CartItemDetailsEatsStyle.scss';
// Component
import {
  GridRowTableFiveColumn,
  GridRowTableOneColumn,
  LabelStatusMain,
} from '../../../../../components/Index';
// helper
import { CommonHelper, OrderHelper, eatSessionTypeCode } from '../../../../../helpers/Index';
// assets
import { Images } from '../../../../../assets/Index';
import { paymentMethodOptionCode } from '../../../../../helpers/MasterDataHelper';

const CartItemDetailsEats = props => {
  const { orderData } = props;

  const orderSummaryData = !_.isEmpty(orderData.order_summary) ? orderData.order_summary : {};
  const orderDetailsData = !_.isEmpty(orderData.order_details) ? orderData.order_details : {};
  const orderPaymentDetails = !_.isEmpty(orderData.payment_details) ? orderData.payment_details : {};
  const orderItems = orderSummaryData.product_details || [];
  let grandTotalItemExtra = 0;

  const isHide = orderDetailsData.at_store;

  const renderSpecialTreatment = () => {
    let passRenderElement = false;

    let wording = null;
    let type = null;
    let icon = null;

    if (
      !_.isEmpty(orderPaymentDetails) &&
      orderPaymentDetails.payment_method === paymentMethodOptionCode.membership
    ) {
      icon = Images.membershipUsage;
      wording = 'Membership Package Applied';
      type = 'membership';
      passRenderElement = true;
    }
    // else if (orderPaymentDetails.is_first_time) {
    //   icon = Images.firstTimePurchaseUsage;
    //   wording = 'First time purchase promo applied';
    //   type = 'first-time-purchase';
    //   passRenderElement = true;
    // }

    return passRenderElement ? (
      <span className={`container-special-label flex-row p-8 ${type}`}>
        <img src={icon} alt="membership_logo" />
        <label className="text-14 text-semi-bold ml-8">{wording}</label>
      </span>
    ) : null;
  };

  const renderStatus = value => {
    let renderElement = <LabelStatusMain value="LUNCH" type="warning" />;

    if (value === eatSessionTypeCode.dinner) {
      renderElement = <LabelStatusMain value="DINNER" type="primary" />;
    }

    return <div className="container-status">{renderElement}</div>;
  };

  return (
    <FormControl
      component="fieldset"
      fullWidth
      margin="normal"
      className="container-order-cart-item-details-eats"
    >
      <div className="flex-column">
        {renderSpecialTreatment()}
        <GridRowTableFiveColumn
          customElementColumnFirst={<label className="text-14">Product</label>}
          customElementColumnSecond={
            <div>
              <label className="text-14">{!isHide && 'Delivery Date'}</label>
            </div>
          }
          customElementColumnThird={
            <div className="text-center">
              <label className="text-14">{!isHide && 'Extra'}</label>
            </div>
          }
          customElementColumnFourth={
            <div className="text-center">
              <label className="text-14">Quantity</label>
            </div>
          }
          customElementColumnFifth={
            <div className="text-right">
              <label className="text-14">Subtotal</label>
            </div>
          }
          columnFirst={4}
          columnSecond={2}
          columnThird={2}
          columnFourth={2}
          columnFifth={2}
        />
        <div className="scroll-container">
          <Grid container direction="column" className="flex-wrap-unset">
            {orderItems.map((item, index) => {
              const modifyDate = CommonHelper.dateTimeParseNewFormat(
                item.details.date,
                'dddd, DD MMM YYYY',
              );
              const wordingExtra = OrderHelper.generateExtraWordingDetails(item.details.extras);
              grandTotalItemExtra += !_.isEmpty(item.details.extras)
                ? item.details.extras.length
                : 0;

              const priceItem = item.item_price * item.quantity;
              // if (item.details.pax) {
              //   priceItem = 0;
              // }

              const wordingProductName = item.details.pax
                ? `${item.product_name} - ( Package )`
                : item.product_name;

              return (
                <GridRowTableFiveColumn
                  key={index}
                  customElementColumnFirst={
                    <div className="flex-column">
                      <label className="text-14 row-item-title wrapping-container mb-8">
                        {wordingProductName}
                      </label>
                      {item.details && !_.isEmpty(item.details.notes) && <label className="text-12 text-semi-bold">{`Meal notes: ${item.details.notes}`}</label>}
                    </div>
                  }
                  customElementColumnSecond={
                    <div>{!isHide && (
                      <span className="flex-column">
                        <label className="text-14 text-semi-bold mb-8">{modifyDate}</label>
                        {renderStatus(item.details.time)}
                      </span>
                    )}
                    </div>
                  }
                  customElementColumnThird={
                    <div className="text-center">
                      <label className="text-14 text-semi-bold">{!isHide && wordingExtra}</label>
                    </div>
                  }
                  customElementColumnFourth={
                    <div className="text-center">
                      <label className="text-14 text-semi-bold">{item.quantity}</label>
                    </div>
                  }
                  customElementColumnFifth={
                    <div className="text-right">
                      <label className="text-14 text-semi-bold">
                        {priceItem > 0 ? `IDR ${CommonHelper.formatCurrency(priceItem)}` : `Free`}
                      </label>
                    </div>
                  }
                  columnFirst={4}
                  columnSecond={2}
                  columnThird={2}
                  columnFourth={2}
                  columnFifth={2}
                />
              );
            })}
          </Grid>
        </div>
        <GridRowTableOneColumn
          customElementInitial={
            <div className="flex-column">
              <label className="text-14 row-item-title">Shipping Fee</label>
              <label className="text-10">{orderSummaryData.shipping_title || 'Not Applicable'}</label>
            </div>
          }
          customElementColumn={
            <div className="text-right">
              <label className="text-14 text-semi-bold">
                {orderSummaryData.shipping_fee > 0
                  ? `IDR ${CommonHelper.formatCurrency(orderSummaryData.shipping_fee)}`
                  : `Free`}
              </label>
            </div>
          }
          columnLeft={10}
          columnRight={2}
        />
        {orderSummaryData.price_discount !== 0 ? (
          <GridRowTableOneColumn
            customElementInitial={
              <div className="flex-column">
                <label className="text-14 row-item-title">Discount</label>
                <label className="text-10 ">Promo - {orderSummaryData.promo_code}</label>
              </div>
            }
            customElementColumn={
              <div className="text-right">
                <label className="text-14 text-semi-bold discount">
                  IDR {CommonHelper.formatCurrency(orderSummaryData.price_discount)}
                </label>
              </div>
            }
            columnLeft={9}
            columnRight={3}
          />
        ) : null}
        { orderPaymentDetails.promo_code && orderPaymentDetails.is_cashback  ? (
          <GridRowTableOneColumn
            customElementInitial={
              <div className="flex-column">
                <label className="text-14 row-item-title">Cashback</label>
                <label className="text-10 ">Promo - {orderSummaryData.promo_code}</label>
              </div>
            }
            customElementColumn={
              <div className="text-right">
                <label className="text-14">
                  {CommonHelper.formatCurrency(orderPaymentDetails.cashback_amount)} FIT Points
                </label>
              </div>
            }
            columnLeft={9}
            columnRight={3}
          />
        ) : null}
        <GridRowTableOneColumn
          customElementInitial={
            <Grid container>
              <Grid item lg={5} md={5}>
                <label className="text-16 row-item-title">
                  Total Item(s) : {orderSummaryData.total_items}
                </label>
              </Grid>
              {!isHide && (
                <Grid item lg={5} md={5}>
                  <label className="text-16 row-item-title">
                    Total Extra : {grandTotalItemExtra}
                  </label>
                </Grid>
              )}
            </Grid>
          }
          customElementColumn={
            <div className="text-right">
              <label className="text-16 row-item-title">
                Grand Total &nbsp; &nbsp; &nbsp;{' '}
                {orderSummaryData.grand_total > 0
                  ? `IDR ${CommonHelper.formatCurrency(orderSummaryData.grand_total)}`
                  : `Free`}
              </label>
            </div>
          }
          columnLeft={7}
          columnRight={5}
        />
      </div>
    </FormControl>
  );
};

CartItemDetailsEats.propTypes = {
  orderData: PropTypes.object,
};

export default CartItemDetailsEats;
