import React from 'react';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroller';
import _ from 'lodash';
// style
import './CalendarDetailStyle.scss';
import { Grid } from '@material-ui/core';
import { Spin } from 'antd';
// component
import { SkeletonScheduleDailySingle } from '../Index';
// helper
import {
  CalendarHelper,
  CommonHelper,
  MasterDataHelper,
  ScheduleStatus,
} from '../../../../../../helpers/Index';
import { LabelStatusMain, ButtonMain } from '../../../../../../components/Index';

const CalendarDetail = props => {
  const [selectState, setSelectState] = React.useState('');

  const {
    dataHeader,
    dataTimeLine,
    today,
    onCardClick,
    onClickBook,
    scheduleSummary,
    onReloadColumn,
  } = props;
  const currentDate = CommonHelper.dateTimeParseNewFormat(today, 'YYYY-MM-DD');
  const currentTime = CommonHelper.dateTimeParseNewFormat(today, 'HH:mm:ss');

  const validationPassSchedule = (date, time, isAvailable) => {
    let result = 'pass-schedule';
    if (currentDate === date) {
      if (
        !CommonHelper.compareDateAndTime(currentDate, date, 'date') &&
        !CommonHelper.compareDateAndTime(currentTime, `${time}:00`, 'time')
      ) {
        if (isAvailable) result = '';
        else result = 'not-available';
      }
    } else if (!CommonHelper.compareDateAndTime(currentDate, date, 'date')) {
      if (isAvailable) result = '';
      else result = 'not-available';
    }

    return result;
  };

  const handleToggleHover = keyItem => {
    setSelectState(keyItem);
  };

  const handleToggleHoverLeave = () => {
    setSelectState('');
  };

  const handleOnReloadColumn = index => {
    let dataCard = [];

    switch (index) {
      case 0:
        dataCard = dataTimeLine.summaryDataConsultation01;
        break;
      case 1:
        dataCard = dataTimeLine.summaryDataConsultation02;
        break;
      case 2:
        dataCard = dataTimeLine.summaryDataConsultation03;
        break;
      case 3:
        dataCard = dataTimeLine.summaryDataConsultation04;
        break;
      case 4:
        dataCard = dataTimeLine.summaryDataConsultation05;
        break;
      case 5:
        dataCard = dataTimeLine.summaryDataConsultation06;
        break;

      default:
        dataCard = dataTimeLine.summaryDataConsultation07;
        break;
    }

    const page = parseInt(dataCard.length / 10, 10);

    onReloadColumn(index, page + 1);
  };

  const renderStatus = item => {
    const additionalClass = validationPassSchedule(item.date, item.dateStart, item.isAvailable);

    let renderElement = (
      <span>
        <i className={`ic-ffo-check-mark container-icon-prefix size-16 ${additionalClass}`} />
        <label className={`text-13 ${additionalClass}`}>{item.slot}</label>
      </span>
    );

    if (item.status === ScheduleStatus.waiting) {
      renderElement = (
        <div style={{ margin: 2.5 }}>
          <LabelStatusMain value={MasterDataHelper.statusSchedule[1].name} type="warning" />
        </div>
      );
    } else if (item.status === ScheduleStatus.Deleted) {
      renderElement = (
        <div style={{ margin: 2.5 }}>
          <LabelStatusMain value={MasterDataHelper.statusSchedule[3].name} type="danger" />
        </div>
      );
    } else if (item.status === ScheduleStatus.Inactive) {
      renderElement = (
        <div style={{ margin: 2.5 }}>
          <LabelStatusMain value={MasterDataHelper.statusSchedule[2].name} type="default" />
        </div>
      );
    }

    return <div className="container-text-slot">{renderElement}</div>;
  };

  const renderScheduleCard = scheduleList => {
    let renderElement = <SkeletonScheduleDailySingle />;
    const dataArray = CalendarHelper.generateCalendarCardList(scheduleList);

    renderElement = dataArray.map((item, index) => {
      const isDisable = !!validationPassSchedule(item.date, item.dateStart, item.isAvailable);
      const isStatusDisable =
        item.status !== ScheduleStatus.Active && item.status !== ScheduleStatus.waiting;

      const renderCoachName = item.coachName && (
        <label className="text-12 wrapping-container">{item.coachName}</label>
      );

      const renderNameProduct =
        scheduleSummary.scheduleActiveTab === 'PT at Home' ? null : item.scheduleName;

      return (
        <Grid
          item
          key={index}
          onMouseEnter={() => handleToggleHover(item.scheduleId)}
          onMouseLeave={() => handleToggleHoverLeave()}
          className="custom-grid-col-row flex-wrap-unset"
        >
          <div className="calendar-card">
            <Grid className="flex-column" onClick={() => onCardClick(item)}>
              {renderNameProduct && (
                <label className="text-12 wrapping-container">{renderNameProduct}</label>
              )}
              <label className="text-10">
                {`${item.dateStart} - ${item.dateEnd}`} <span className="dot" /> {item.duration}
              </label>
              {renderStatus(item)}
            </Grid>
            {selectState === item.scheduleId && !isDisable && !isStatusDisable ? (
              <ButtonMain labelText="Book" onClick={() => onClickBook(item)} type="primary" />
            ) : (
              renderCoachName
            )}
          </div>
        </Grid>
      );
    });

    return renderElement;
  };

  const renderScheduleColumn = () => {
    return dataHeader.map((item, index) => {
      let dataCard = null;
      let moreDataCard = null;

      switch (index) {
        case 0:
          dataCard = dataTimeLine.summaryDataConsultation01;
          moreDataCard = dataTimeLine.loadMoreConsultation01;
          break;
        case 1:
          dataCard = dataTimeLine.summaryDataConsultation02;
          moreDataCard = dataTimeLine.loadMoreConsultation02;
          break;
        case 2:
          dataCard = dataTimeLine.summaryDataConsultation03;
          moreDataCard = dataTimeLine.loadMoreConsultation03;
          break;
        case 3:
          dataCard = dataTimeLine.summaryDataConsultation04;
          moreDataCard = dataTimeLine.loadMoreConsultation04;
          break;
        case 4:
          dataCard = dataTimeLine.summaryDataConsultation05;
          moreDataCard = dataTimeLine.loadMoreConsultation05;
          break;
        case 5:
          dataCard = dataTimeLine.summaryDataConsultation06;
          moreDataCard = dataTimeLine.loadMoreConsultation06;
          break;

        default:
          dataCard = dataTimeLine.summaryDataConsultation07;
          moreDataCard = dataTimeLine.loadMoreConsultation07;
          break;
      }

      return (
        <Grid
          key={index}
          item
          className={`custom-grid-col ${
            dataHeader !== undefined && currentDate === item.value ? 'is-active' : ''
          }`}
        >
          <div className="scroll-container-invisible container-item-schedule">
            <InfiniteScroll
              pageStart={1}
              loadMore={() => handleOnReloadColumn(index + 1)}
              hasMore={moreDataCard}
              useWindow={false}
              loader={
                <div className="loader" key={index + 1}>
                  <Spin />
                </div>
              }
            >
              <Grid container direction="column">
                {renderScheduleCard(dataCard)}
              </Grid>
            </InfiniteScroll>
          </div>
        </Grid>
      );
    });
  };

  return (
    <div className="container-calendar-detail">
      {!_.isEmpty(dataHeader) && <Grid container>{renderScheduleColumn()}</Grid>}
    </div>
  );
};

CalendarDetail.propTypes = {
  dataHeader: PropTypes.array,
  dataTimeLine: PropTypes.object,
  onCardClick: PropTypes.func,
  onClickBook: PropTypes.func,
  onReloadColumn: PropTypes.func,
  scheduleSummary: PropTypes.object,
  today: PropTypes.string,
};

CalendarDetail.defaultProps = {
  dataHeader: [],
  dataTimeLine: [],
};

export default CalendarDetail;
