export const constants = {
  getInboxPaginationRequest: 'GET_INBOX_PAGINATION_REQUEST',
  getInboxPaginationSuccess: 'GET_INBOX_PAGINATION_SUCCESS',
  getInboxPaginationFail: 'GET_INBOX_PAGINATION_FAIL',
  setDeleteInboxRequest: 'SET_DELETE_INBOX_REQUEST',
  setDeleteInboxSuccess: 'SET_DELETE_INBOX_SUCCESS',
  setDeleteInboxFail: 'SET_DELETE_INBOX_FAIL',
  getInboxDetailsRequest: 'GET_INBOX_DETAILS_REQUEST',
  getInboxDetailsSuccess: 'GET_INBOX_DETAILS_SUCCESS',
  getInboxDetailsFail: 'GET_INBOX_DETAILS_FAIL',
  setUpdateInboxRequest: 'SET_UPDATE_INBOX_REQUEST',
  setUpdateInboxSuccess: 'SET_UPDATE_INBOX_SUCCESS',
  setUpdateInboxFail: 'SET_UPDATE_INBOX_FAIL',
  setCreateInboxRequest: 'SET_CREATE_INBOX_REQUEST',
  setCreateInboxSuccess: 'SET_CREATE_INBOX_SUCCESS',
  setCreateInboxFail: 'SET_CREATE_INBOX_FAIL',
  setPushNotifInboxTestRequest: 'SET_PUSH_NOTIF_INBOX_TEST_REQUEST',
  setPushNotifInboxTestSuccess: 'SET_PUSH_NOTIF_INBOX_TEST_SUCCESS',
  setPushNotifInboxTestFail: 'SET_PUSH_NOTIF_INBOX_TEST_FAIL',
  getInboxSendTestRequest: 'GET_INBOX_SEND_TEST_REQUEST',
  getInboxSendTestSuccess: 'GET_INBOX_SEND_TEST_SUCCESS',
  getInboxSendTestFail: 'GET_INBOX_SEND_TEST_FAIL',
};

const initState = {
  fetching: false,
  segmentListOption: [],
  details: {},
  list: [],
  errorFetch: false,
  errorMessage: null,
};

export default function InboxReducer(state = initState, action) {
  const { type, payload } = action;
  switch (type) {
    case constants.getInboxPaginationRequest:
      return { ...state, fetching: true };
    case constants.getInboxPaginationSuccess:
      return {
        ...state,
        list: payload.data,
        fetching: false,
      };
    case constants.getInboxPaginationFail:
      return {
        ...state,
        fetching: false,
        errorFetch: true,
        errorMessage: payload,
      };

    case constants.setDeleteInboxRequest:
      return { ...state, fetching: true };
    case constants.setDeleteInboxSuccess:
      return {
        ...state,
        fetching: false,
      };
    case constants.setDeleteInboxFail:
      return {
        ...state,
        fetching: false,
        errorFetch: true,
        errorMessage: payload,
      };

    case constants.getInboxDetailsRequest:
      return { ...state, fetching: true };
    case constants.getInboxDetailsSuccess:
      return {
        ...state,
        details: payload.data,
        fetching: false,
      };
    case constants.getInboxDetailsFail:
      return {
        ...state,
        fetching: false,
        errorFetch: true,
        errorMessage: payload,
      };

    case constants.setUpdateInboxRequest:
      return { ...state, fetching: true };
    case constants.setUpdateInboxSuccess:
      return {
        ...state,
        fetching: false,
      };
    case constants.setUpdateInboxFail:
      return {
        ...state,
        fetching: false,
        errorFetch: true,
        errorMessage: payload,
      };

    case constants.setCreateInboxRequest:
      return { ...state, fetching: true };
    case constants.setCreateInboxSuccess:
      return {
        ...state,
        fetching: false,
      };
    case constants.setCreateInboxFail:
      return {
        ...state,
        fetching: false,
        errorFetch: true,
        errorMessage: payload,
      };

    case constants.setPushNotifInboxTestRequest:
      return { ...state, fetching: true };
    case constants.setPushNotifInboxTestSuccess:
      return {
        ...state,
        fetching: false,
      };
    case constants.setPushNotifInboxTestFail:
      return {
        ...state,
        fetching: false,
        errorFetch: true,
        errorMessage: payload,
      };

    case constants.getInboxSendTestRequest:
      return { ...state, fetching: true };
    case constants.getInboxSendTestSuccess:
      return {
        ...state,
        fetching: false,
      };
    case constants.getInboxSendTestFail:
      return {
        ...state,
        fetching: false,
        errorFetch: true,
        errorMessage: payload,
      };

    default:
      return state;
  }
}
