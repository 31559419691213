import { constants } from '../reducers/PopupScreenReducer';

export function getPopupScreenListRequest() {
  return { type: constants.getPopupScreenListRequest };
}

export function getPopupScreenListSuccess(response) {
  return { type: constants.getPopupScreenListSuccess, payload: response };
}

export function getPopupScreenListFail(response) {
  return { type: constants.getPopupScreenListFail, payload: response.data };
}

export function getPopupScreenDetailsRequest() {
  return { type: constants.getPopupScreenDetailsRequest };
}

export function getPopupScreenDetailsSuccess(response) {
  return { type: constants.getPopupScreenDetailsSuccess, payload: response };
}

export function getPopupScreenDetailsFail(response) {
  return { type: constants.getPopupScreenDetailsFail, payload: response.data };
}

export function createPopupScreenRequest() {
  return { type: constants.createPopupScreenRequest };
}

export function createPopupScreenSuccess(response) {
  return { type: constants.createPopupScreenSuccess, payload: response };
}

export function createPopupScreenFail(response) {
  return { type: constants.createPopupScreenFail, payload: response.data };
}

export function updateStatusPopupScreenRequest() {
  return { type: constants.updateStatusPopupScreenRequest };
}

export function updateStatusPopupScreenSuccess(response) {
  return { type: constants.updateStatusPopupScreenSuccess, payload: response };
}

export function updateStatusPopupScreenFail(response) {
  return { type: constants.updateStatusPopupScreenFail, payload: response.data };
}

export function editPopupScreenRequest() {
  return { type: constants.editPopupScreenRequest };
}

export function editPopupScreenSuccess(response) {
  return { type: constants.editPopupScreenSuccess, payload: response };
}

export function editPopupScreenFail(response) {
  return { type: constants.editPopupScreenFail, payload: response.data };
}
