// Glossary
// 1. Popover

// #region 1. Popover
// For reference: https://ant.design/components/popover/
const FitcoPopoverTrigger = {
    Click: "click",
    Hover: "hover"
};

const FitcoPopoverPlacement = {
    TopLeft: "topLeft",
    Top: "top",
    TopRight: "topRight",

    BottomLeft: "bottomLeft",
    Bottom: "bottom",
    BottomRight: "bottomRight",

    LeftTop: "leftTop",
    Left: "left",
    LeftBottom: "leftBottom",

    RightTop: "rightTop",
    Right: "right",
    RightBottom: "rightBottom",
};
// #endregion

export { FitcoPopoverTrigger, FitcoPopoverPlacement };