import React from 'react';
import { connect } from 'react-redux';
import { Grid, FormControl, FormLabel } from '@material-ui/core';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import _ from 'lodash';
// component
import {
  ButtonMain,
  PrevNextStepperCount,
  SelectInputGeneral,
  PickerInputDate,
  TextInput,
  SelectInputTime,
  TextInputAutocomplete,
  SnackBarSimple,
} from '../../../../../../components/Index';
import UserCheckField from '../../../../../../components/higher-order-components/UserCheckField';
// api
import { getLabSchedule } from '../../../../../../services/api/ScheduleDataApi';
import { getClinicBranch } from '../../../../../../services/api/MasterDataApi';
import { checkHomeServiceAreaCoverage } from '../../../../../../services/api/MedicApi';
import {
  getOrderShippingAddress,
  getOrderShippingAddressInfo,
} from '../../../../../../services/api/OrdersApi';
// helper
import { CommonHelper } from '../../../../../../helpers/Index';
// style
import './StepBookingServiceDetailsStyle.scss';

const today = CommonHelper.currentDate('YYYY-MM-DD HH:mm:ss');
const currentDate = CommonHelper.dateTimeParseNewFormat(today, 'YYYY-MM-DD');

const tomorrow = new Date(currentDate);
tomorrow.setDate(tomorrow.getDate() + 1);

const tomorrowDate = CommonHelper.dateTimeParseNewFormat(tomorrow, 'YYYY-MM-DD');

const initialValidation = {
  branchId: { isError: false, errorMessage: '' },
  time: { isError: false, errorMessage: '' },
  address: { isError: false, errorMessage: '' },
  addressNotes: { isError: false, errorMessage: '' },
};

const initialForm = {
  address: '',
  addressNotes: '',
  addressInfo: {},
  branchId: undefined,
  date: currentDate,
  time: undefined,
  placeId: '',
};

const homeServiceIdentification = ['fitco-medic-home-service'];

const optionToast = {
  vertical: 'top',
  horizontal: 'right',
};

class StepBookingServiceDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      form: CommonHelper.objectCloning(initialForm),
      validation: CommonHelper.objectCloning(initialValidation),
      selectedService: {},
      serviceLocation: '',
      addressNotCoverage: false,
      toastInformation: {
        isOpen: false,
        message: '',
        snackbarType: 'warning',
      },
    };

    this.searchAddressDebounce = _.debounce(this.searchAddressDebounce, 400);
  }

  componentDidMount() {
    const { parentForm } = this.props;

    let setInitialForm = initialForm;
    let selectedService = {};
    let serviceLocation = '';
    let startDate = null;

    // Check if updated from this step
    if (parentForm.step_booking_product_details) {
      startDate = CommonHelper.dateTimeParseNewFormat(
        parentForm.step_booking_product_details.form.date,
        'YYYY-MM-DD',
      );
      setInitialForm = { ...parentForm.step_booking_product_details.form };
      selectedService = parentForm.step_booking_product_details.selectedService;
      serviceLocation = parentForm.step_booking_product_details.serviceLocation;
    }

    this.setState(
      {
        form: {
          ...setInitialForm,
        },
        selectedService,
        serviceLocation,
      },
      async () => {
        const date = startDate || this.state.form.date;
        await this.getClinicBranchApi('covid-19');
        await this.getLabScheduleApi(date);
      },
    );
  }

  searchAddressDebounce = async () => {
    const { form } = this.state;
    const { getShippingAddress, onPopUpInformation } = this.props;

    const param = {
      search_term: form.address,
    };

    await getShippingAddress(param).catch(error => {
      const serverMessage = error.data;
      onPopUpInformation(serverMessage.messages, 'error');
    });
  };

  onProcessPlaceId = () => {
    const { form, validation } = this.state;
    const { getShippingAddressInfo, onPopUpInformation } = this.props;

    const param = {
      place_id: form.placeId,
    };

    getShippingAddressInfo(param)
      .then(response => {
        const data = response.data;
        this.setState({ form: { ...form, addressInfo: data }, validation: { ...validation } });
      })
      .catch(error => {
        const serverMessage = error.data;
        onPopUpInformation(serverMessage.messages, 'error');
      });
  };

  getClinicBranchApi = async category => {
    const { callGetClinicBranchApi } = this.props;
    await callGetClinicBranchApi(category);
  };

  getLabScheduleApi = async date => {
    const { callGetLabScheduleApi } = this.props;
    const { form } = this.state;
    const branchId = form.branchId;
    if (!branchId) return;
    const params = { date, branch_id: branchId };
    await callGetLabScheduleApi(params);
  };

  getMatchDataByKey = (key, value) => {
    const {
      masterData: { clinicBranch },
      availableLabSchedule,
    } = this.props;
    let findMatchData;
    switch (key) {
      case 'branch_id':
        findMatchData = _.find(clinicBranch, ['branch_id', value]);
        break;
      case 'start_time':
        findMatchData = _.find(availableLabSchedule, ['start_time', value]);
        break;

      default:
        break;
    }
    return findMatchData;
  };

  handleSelectServiceLocation = value => {
    const { form } = this.state;

    const selectedService = this.getMatchDataByKey('branch_id', value);
    const isHomeService =
      homeServiceIdentification.indexOf(selectedService && selectedService.identification) !== -1;
    const serviceLocation = isHomeService ? 'at_home' : 'in_store';

    let date = form.date;
    if (isHomeService && date === currentDate) {
      date = tomorrowDate;
    }

    this.setState(
      {
        form: {
          ...form,
          branchId: value,
          date,
          time: undefined,
          address: '',
          addressInfo: {},
          addressNotes: '',
          placeId: '',
        },
        selectedService,
        serviceLocation,
      },
      () => {
        this.getLabScheduleApi(date);
      },
    );
  };

  handleSelectDateChange = value => {
    const { form } = this.state;

    this.setState(
      {
        form: { ...form, date: value, time: undefined },
      },
      () => {
        this.getLabScheduleApi(value);
      },
    );
  };

  handleSelectTime = value => {
    const { form } = this.state;

    this.setState({
      form: {
        ...form,
        time: value,
      },
    });
  };

  handleChangeAddress = value => {
    const { form } = this.state;

    this.setState(
      {
        form: {
          ...form,
          address: value,
        },
      },
      () => {
        this.searchAddressDebounce();
      },
    );
  };

  handleSelectedItemAddress = async (value, option) => {
    const { form, validation } = this.state;
    const { callCheckHomeServiceAreaCoverageApi } = this.props;

    try {
      const body = { place_id: option.place_id };
      await callCheckHomeServiceAreaCoverageApi(body);
      const validationNewValue = {
        ...validation,
        address: { isError: false, errorMessage: '' },
      };
      this.setState(
        {
          form: { ...form, address: option.address, placeId: option.place_id },
          validation: { ...validationNewValue },
          addressNotCoverage: false,
        },
        () => {
          this.onProcessPlaceId();
        },
      );
    } catch (error) {
      const errorMessage = error.data.messages[0];
      const validationNewValue = {
        ...validation,
        address: { isError: true, errorMessage },
      };
      this.setState({
        validation: { ...validationNewValue },
        addressNotCoverage: true,
      });
      this.processMessage(errorMessage, 'error');
    }
  };

  handleChangeAddressNotes = value => {
    const { form } = this.state;

    this.setState({
      form: {
        ...form,
        addressNotes: value,
      },
    });
  };

  handleValidation = form => {
    const { selectedService, addressNotCoverage } = this.state;
    let validationNewValue = CommonHelper.objectCloning(initialValidation);
    let excludeValidationKeys = ['addressInfo'];
    const isHomeService =
      homeServiceIdentification.indexOf(selectedService && selectedService.identification) !== -1;

    if (!isHomeService) {
      excludeValidationKeys = ['address', 'addressNotes', 'addressInfo', 'placeId'];
    }

    let hasError = false;

    const keys = Object.keys(form).filter(key => {
      return excludeValidationKeys.indexOf(key) === -1;
    });

    keys.forEach(key => {
      const formValue = typeof form[key] === 'number' ? (form[key]).toString() : form[key];
      const skipCheck = excludeValidationKeys.indexOf(key) !== -1;
      const isError =  skipCheck ? false : _.isEmpty(formValue);
      if(isError) {
        hasError = true;
      }

      let errorDetail = {};

      switch (key) {
        case 'branchId':
          errorDetail = { isError, errorMessage: isError ? 'Please choose service location.' : '' };
          break;
        case 'time':
          errorDetail = { isError, errorMessage: isError ? 'Please choose time.' : '' };
          break;
        case 'placeId':
          errorDetail = { isError, errorMessage: isError ? 'Please select a valid address from search result.' : '' };
          key = 'address';
          break;
        case 'address':
          errorDetail = { isError, errorMessage: isError ? 'Please enter address for Home Service.' : '' };

          if (addressNotCoverage) {
            errorDetail = { isError: true, errorMessage: 'Sorry, your area is not covered for Home Service.' };
          }
          break;
        case 'addressNotes':
          errorDetail = { isError, errorMessage: isError ? 'Please enter address for home service.' : '' };
          break;
        default:
          errorDetail = { isError, errorMessage: isError ? 'This field is required.' : '' };
          break;
      }

      validationNewValue = {
        ...validationNewValue,
        [key]: errorDetail,
      };
    });

    this.setState({
      validation: { ...validationNewValue },
    });

    return { isError: hasError, validationNewValue };
  };

  handlePrevClick = async () => {
    const { onPrevClick, onParameterUpdate } = this.props;
    const { form, selectedService, serviceLocation } = this.state;
    const formNewValue = CommonHelper.objectCloning(form);
    const payload = {
      form: { ...formNewValue },
      serviceLocation,
      selectedService,
    };
    await onParameterUpdate(payload, 'step_booking_product_details');
    await onPrevClick();
  };

  handleNextClick = async () => {
    const { onNextClick, onParameterUpdate } = this.props;
    const { form, selectedService, serviceLocation } = this.state;
    const formNewValue = CommonHelper.objectCloning(form);
    const selectedTime = this.getMatchDataByKey('start_time', form.time);
    const { isError } = this.handleValidation(formNewValue);
    const payload = {
      form: { ...formNewValue, selectedTime },
      serviceLocation,
      selectedService,
    };
    await onParameterUpdate(payload, 'step_booking_product_details');
    if (!isError) {
      await onNextClick();
    }
  };

  handleCloseToast = () => {
    const { toastInformation } = this.state;
    this.setState({ toastInformation: { ...toastInformation, isOpen: false } });
  };

  processMessage(messages, type) {
    const convertedMessage = CommonHelper.generateMessage(messages);

    const paramInformation = {
      isOpen: true,
      message: convertedMessage,
      snackbarType: type,
    };

    this.setState({
      toastInformation: paramInformation,
    });
  }

  renderSelectDateTimeDetail = () => {
    const {
      form: { date, time, branchId, addressNotes },
      validation,
      selectedService,
    } = this.state;
    const { availableLabSchedule } = this.props;

    const isHomeService =
      homeServiceIdentification.indexOf(selectedService && selectedService.identification) !== -1;

    return (
      <Grid item lg={12} md={12}>
        <Grid container spacing={3}>
          <Grid item lg={6} md={6}>
            <Grid container spacing={3}>
              <Grid item lg={6} md={6}>
                <FormControl component="fieldset" fullWidth margin="normal">
                  <FormLabel component="label" className="text-12 filed-title">
                    Date
                  </FormLabel>
                  <PickerInputDate
                    customIcon="ic-ffo-date-pick"
                    dateFormat="dd-MM-yyyy"
                    minDate={currentDate}
                    onChange={this.handleSelectDateChange}
                    defaultValue={date}
                    toolbar
                  />
                </FormControl>
              </Grid>
              <Grid item lg={6} md={6}>
                <FormControl component="fieldset" fullWidth margin="normal">
                  <FormLabel component="label" className="text-12 filed-title">
                    Time
                  </FormLabel>
                  <SelectInputTime
                    data={availableLabSchedule}
                    placeHolder="Select Time"
                    currentValue={time}
                    onChange={this.handleSelectTime}
                    disabled={!branchId}
                    errorMessage={validation.time.errorMessage}
                    validateStatus={validation.time.isError ? 'error' : ''}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={6} md={6}>
            {branchId && isHomeService && (
              <FormControl component="fieldset" fullWidth margin="normal">
                <FormLabel component="label" className="text-12 filed-title">
                  Notes
                </FormLabel>
                <TextInput
                  placeHolderText="Enter address notes"
                  size="md"
                  onChange={this.handleChangeAddressNotes}
                  currentValue={addressNotes}
                  isDisable={!isHomeService || !branchId}
                  errorMessage={validation.addressNotes.errorMessage}
                  isError={validation.addressNotes.isError}
                />
              </FormControl>
            )}
          </Grid>
        </Grid>
      </Grid>
    );
  };

  render() {
    const {
      onButtonClickCancel,
      masterData: { clinicBranch },
      orderData: { fetchingShippingAddress, listShippingAddress },
    } = this.props;
    const { form, validation, selectedService, toastInformation } = this.state;

    const ListCustom = listShippingAddress;

    const isHomeService =
      homeServiceIdentification.indexOf(selectedService && selectedService.identification) !== -1;

    return (
      <Grid container className="container-step-service-details">
        <Grid item lg={12} md={12} className="mb-4 mt-32">
          <label className="text-12 text-bold text-rolling-stone">PRODUCT DETAILS</label>
        </Grid>
        <Grid item lg={12} md={12}>
          <Grid container spacing={3}>
            <Grid item lg={6} md={6}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <FormLabel component="label" className="text-12 filed-title">
                  Service Location
                </FormLabel>
                <SelectInputGeneral
                  placeHolder="Select Service"
                  currentValue={form.branchId}
                  dataList={clinicBranch}
                  primaryKey="branch_id"
                  onChange={this.handleSelectServiceLocation}
                  errorMessage={validation.branchId.errorMessage}
                  validateStatus={validation.branchId.isError ? 'error' : ''}
                />
              </FormControl>
            </Grid>
            <Grid item lg={6} md={6}>
              {form.branchId && isHomeService && (
                <FormControl component="fieldset" fullWidth margin="normal">
                  <FormLabel component="label" className="text-12 filed-title">
                    Address
                  </FormLabel>
                  <TextInputAutocomplete
                    size="md"
                    onChange={this.handleChangeAddress}
                    currentValue={form.address}
                    placeholder="Address"
                    onSelect={this.handleSelectedItemAddress}
                    isLoading={fetchingShippingAddress}
                    data={ListCustom}
                    isCustomDisplay
                    customDisplayClass="custom-address-option-item"
                    errorMessage={validation.address.errorMessage}
                    isError={validation.address.isError}
                    isDisable={!isHomeService || !form.branchId}
                    placeHolderText="Please enter address for Home Service"
                  />
                </FormControl>
              )}
            </Grid>
          </Grid>
        </Grid>
        {this.renderSelectDateTimeDetail()}
        <Grid item lg={12} md={12} className="mt-24">
          <Grid container justify="flex-end">
            <Grid item lg={5} md={6}>
              <Grid container justify="flex-end" spacing={2}>
                <Grid item lg={4} md={4}>
                  <ButtonMain
                    type="negative"
                    size="xl"
                    labelText="Cancel"
                    onClick={onButtonClickCancel}
                  />
                </Grid>
                <Grid item lg={4} md={4}>
                  <ButtonMain
                    type="ghost"
                    size="xl"
                    labelText="Prev"
                    onClick={this.handlePrevClick}
                  />
                </Grid>
                <Grid item lg={4} md={4}>
                  <ButtonMain
                    type="primary"
                    size="xl"
                    labelText="Next"
                    onClick={this.handleNextClick}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <SnackBarSimple
          open={toastInformation.isOpen}
          durationHide={2000}
          message={toastInformation.message}
          onClickClose={this.handleCloseToast}
          snackbarType={toastInformation.snackbarType}
          anchor={optionToast}
        />
      </Grid>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  callGetClinicBranchApi: category => getClinicBranch(dispatch, category),
  callGetLabScheduleApi: body => getLabSchedule(dispatch, body),
  getShippingAddress: params => getOrderShippingAddress(dispatch, params),
  callCheckHomeServiceAreaCoverageApi: body => checkHomeServiceAreaCoverage(dispatch, body),
  getShippingAddressInfo: params => getOrderShippingAddressInfo(dispatch, params),
});

const mapStateToProps = ({ masterData, orderData, scheduleSummary }) => ({
  masterData,
  orderData,
  availableLabSchedule: scheduleSummary.availableLabSchedule,
});

StepBookingServiceDetails.propTypes = {
  availableLabSchedule: PropTypes.array,
  callCheckHomeServiceAreaCoverageApi: PropTypes.func,
  callGetClinicBranchApi: PropTypes.func,
  callGetLabScheduleApi: PropTypes.func,
  getShippingAddress: PropTypes.func,
  getShippingAddressInfo: PropTypes.func,
  masterData: PropTypes.object,
  onButtonClickCancel: PropTypes.func,
  onNextClick: PropTypes.func,
  onParameterUpdate: PropTypes.func,
  onPopUpInformation: PropTypes.func,
  onPrevClick: PropTypes.func,
  orderData: PropTypes.object,
  parentForm: PropTypes.object,
};

const shell = compose(connect(mapStateToProps, mapDispatchToProps));

const core = compose(UserCheckField, PrevNextStepperCount);

export default shell(core(StepBookingServiceDetails));
