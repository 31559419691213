import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Input } from 'antd';
// Style
import './TextAreaMainStyle.scss';
import { Grid } from '@material-ui/core';

const { TextArea } = Input;

const TextAreaMain = props => {
  const {
    onChange,
    currentValue,
    defaultValue,
    rows,
    placeholder,
    validateStatus,
    errorMessage,
    isDisable,
    maxLength,
  } = props;
  const [StringLength, setStringLength] = React.useState(0);

  useEffect(() => {
    if (currentValue) {
      setStringLength(currentValue.length);
    }
  }, [currentValue]);

  const handleChange = e => {
    const stringOnly = e.target.value;

    if (maxLength) {
      // const stringLength = ValidationHelper.validateWord(stringOnly);
      setStringLength(stringOnly.length);
    }

    onChange(stringOnly);
  };

  return (
    <Form.Item validateStatus={validateStatus} help={errorMessage} className="container-text-area">
      <TextArea
        placeholder={placeholder}
        value={currentValue}
        defaultValue={defaultValue}
        rows={rows}
        onChange={handleChange}
        disabled={isDisable}
        maxLength={maxLength}
      />
      {maxLength && (
        <Grid container justify="flex-end">
          <Grid item lg={3} md={3} className="container-content-length">
            <label className="text-10">{`${StringLength} / ${maxLength}`}</label>
          </Grid>
        </Grid>
      )}
    </Form.Item>
  );
};

TextAreaMain.propTypes = {
  currentValue: PropTypes.string,
  defaultValue: PropTypes.string,
  errorMessage: PropTypes.string,
  isDisable: PropTypes.bool,
  maxLength: PropTypes.number,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  rows: PropTypes.number,
  validateStatus: PropTypes.string,
};

export default TextAreaMain;
