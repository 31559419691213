import React from 'react';
import PropTypes from 'prop-types';
import { Paper, Grid } from '@material-ui/core';
// style
import './HeaderMembershipRequestStyle.scss';
// component
import { TabNavigation } from '../../../../../components/Index';

const HeaderMembershipRequest = props => {
  const { onChangeTab, data, currentTab } = props;

  const handleChangeTab = value => {
    onChangeTab(value);
  };

  return (
    <Paper square className="container-tab-header-membership-request">
      <Grid container>
        <Grid item lg md>
          <TabNavigation data={data} onChange={handleChangeTab} currentTab={currentTab} />
        </Grid>
      </Grid>
    </Paper>
  );
};

HeaderMembershipRequest.propTypes = {
  currentTab: PropTypes.number,
  data: PropTypes.array,
  onChangeTab: PropTypes.func,
};

export default HeaderMembershipRequest;
