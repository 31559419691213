/* eslint-disable radix */
import React from 'react';
import PropTypes from 'prop-types';
import { TextField, InputAdornment } from '@material-ui/core';
// style
import './TextInputNumberStyle.scss';

const TextInputNumber = props => {
  const {
    labelHeader,
    defaultValue,
    currentValue,
    isError,
    errorMessage,
    iconPrefix,
    onChange,
    onEnter,
    placeHolderText,
    size,
  } = props;

  const [valueState, setState] = React.useState(currentValue);

  React.useEffect(() => {
    const updateState = async () => {
      setState(currentValue);
    };
    updateState();
  }, [currentValue]);

  const onChangeTextInput = event => {
    const result = event.target.value;
    onChange(result);
  };

  const handleEnterTextInput = event => {
    if (event.key === 'Enter') onEnter();
  };

  return (
    <TextField
      className={`container-text-input-number ${size}`}
      label={labelHeader}
      size="small"
      type="number"
      defaultValue={defaultValue}
      value={valueState}
      helperText={errorMessage}
      placeholder={placeHolderText}
      variant="outlined"
      error={isError}
      InputProps={{
        startAdornment: iconPrefix ? (
          <InputAdornment position="start">
            <i className={`${iconPrefix} container-icon-prefix`} />
          </InputAdornment>
        ) : null,
      }}
      InputLabelProps={{ shrink: true }}
      onChange={onChangeTextInput}
      onKeyPress={onEnter ? handleEnterTextInput : null}
    />
  );
};

TextInputNumber.propTypes = {
  currentValue: PropTypes.number,
  defaultValue: PropTypes.number,
  errorMessage: PropTypes.string,
  iconPrefix: PropTypes.string,
  isError: PropTypes.bool,
  labelHeader: PropTypes.string,
  onChange: PropTypes.func,
  onEnter: PropTypes.func,
  placeHolderText: PropTypes.string,
  size: PropTypes.string,
};

export default TextInputNumber;
