import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import _ from 'lodash';
// component
import { HeaderMembershipRequest } from './components/Index';
import { AuthenticationAccessPages, PrevStateValue } from '../../../components/Index';
// view
import { RequestPage } from './Index';
// helper
import { compose } from 'redux';

class MembershipRequestPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tabsValue: 0,
    };
  }

  componentDidMount() {
    const {
      onResetPrevStateValue,
      history: { location },
    } = this.props;
    onResetPrevStateValue({ url: location.pathname });
  }

  componentWillUnmount() {}

  handleTabChange = value => {
    const {
      onSetPrevStateValueTabs,
      history: { location },
    } = this.props;

    this.setState({ tabsValue: value }, () => {
      onSetPrevStateValueTabs({ activeTab: value, url: location.pathname });
    });
  };

  renderPages = () => {
    const { tabsValue } = this.state;
    const { history, tabPermissionAccess } = this.props;
    let renderElement = null;

    if (!_.isEmpty(tabPermissionAccess)) {
      const StatusCode = tabPermissionAccess[tabsValue].value;
      renderElement = <RequestPage history={history} pageStatus={StatusCode} />;
    }

    return renderElement;
  };

  render() {
    const { tabPermissionAccess } = this.props;

    return (
      <div>
        <Helmet title={`FITCO | Membership - Request`} />
        <div>
          <HeaderMembershipRequest
            data={tabPermissionAccess}
            currentTab={this.state.tabsValue}
            onChangeTab={this.handleTabChange}
          />
          {this.renderPages()}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = () => ({});

const mapStateToProps = () => ({});

MembershipRequestPage.propTypes = {
  history: PropTypes.object,
  onResetPrevStateValue: PropTypes.func,
  onSetPrevStateValueTabs: PropTypes.func,
  tabPermissionAccess: PropTypes.array,
};

const shell = compose(connect(mapStateToProps, mapDispatchToProps));
const core = compose(AuthenticationAccessPages, PrevStateValue);

export default shell(core(MembershipRequestPage));
