export const constants = {
  // #region retail
  getOrderListRequest: 'GET_ORDER_LIST_REQUEST',
  getOrderListSuccess: 'GET_ORDER_LIST_SUCCESS',
  getOrderListFail: 'GET_ORDER_LIST_FAIL',
  // #endregion

  // #region eat
  getOrderEatListByTypeRequest: 'GET_ORDER_EAT_LIST_BY_TYPE_REQUEST',
  getOrderEatListByTypeSuccess: 'GET_ORDER_EAT_LIST_BY_TYPE_SUCCESS',
  getOrderEatListByTypeFail: 'GET_ORDER_EAT_LIST_BY_TYPE_FAIL',
  getOrderEatListByTypeProductRequest: 'GET_ORDER_EAT_LIST_BY_TYPE_PRODUCT_REQUEST',
  getOrderEatListByTypeProductSuccess: 'GET_ORDER_EAT_LIST_BY_TYPE_PRODUCT_SUCCESS',
  getOrderEatListByTypeProductFail: 'GET_ORDER_EAT_LIST_BY_TYPE_PRODUCT_FAIL',
  // #endregion
  getOrderDetailsRequest: 'GET_ORDER_DETAILS_REQUEST',
  getOrderDetailsSuccess: 'GET_ORDER_DETAILS_SUCCESS',
  getOrderDetailsFail: 'GET_ORDER_DETAILS_FAIL',
  getUserDetailByPhoneRequest: 'GET_USER_DETAILS_BY_PHONE_REQUEST',
  getUserDetailByPhoneSuccess: 'GET_USER_DETAILS_BY_PHONE_SUCCESS',
  getUserDetailByPhoneFail: 'GET_USER_DETAILS_BY_PHONE_FAIL',
  getOrderProductPaginationRequest: 'GET_ORDER_PRODUCT_PAGINATION_REQUEST',
  getOrderProductPaginationSuccess: 'GET_ORDER_PRODUCT_PAGINATION_SUCCESS',
  getOrderProductPaginationFail: 'GET_ORDER_PRODUCT_PAGINATION_FAIL',
  getOrderShippingAddressRequest: 'GET_ORDER_SHIPPING_ADDRESS_REQUEST',
  getOrderShippingAddressSuccess: 'GET_ORDER_SHIPPING_ADDRESS_SUCCESS',
  getOrderShippingAddressFail: 'GET_ORDER_SHIPPING_ADDRESS_FAIL',
  getOrderShippingAddressInfoRequest: 'GET_ORDER_SHIPPING_ADDRESS_INFO_REQUEST',
  getOrderShippingAddressInfoSuccess: 'GET_ORDER_SHIPPING_ADDRESS_INFO_SUCCESS',
  getOrderShippingAddressInfoFail: 'GET_ORDER_SHIPPING_ADDRESS_INFO_FAIL',
  getOrderShippingCostRequest: 'GET_ORDER_SHIPPING_COST_REQUEST',
  getOrderShippingCostSuccess: 'GET_ORDER_SHIPPING_COST_SUCCESS',
  getOrderShippingCostFail: 'GET_ORDER_SHIPPING_COST_FAIL',
  setCurrentAddressTypeOption: 'SET_CURRENT_ADDRESS_TYPE_OPTION',
  setCurrentAddressTypeOptionRaw: 'SET_CURRENT_ADDRESS_TYPE_OPTION_RAW',
  setCurrentDeliveryService: 'SET_CURRENT_DELIVERY_OPTION',
  setProcessOrderRequest: 'SET_PROCESS_ORDER_REQUEST',
  setProcessOrderSuccess: 'SET_PROCESS_ORDER_SUCCESS',
  setProcessOrderFail: 'SET_PROCESS_ORDER_FAIL',
  checkPromoCodeRequest: 'CHECK_PROMO_CODE_REQUEST',
  checkPromoCodeSuccess: 'CHECK_PROMO_CODE_SUCCESS',
  checkPromoCodeFail: 'CHECK_PROMO_CODE_FAIL',
  setProcessCreateOrderRequest: 'SET_PROCESS_CREATE_ORDER_REQUEST',
  setProcessCreateOrderSuccess: 'SET_PROCESS_CREATE_ORDER_SUCCESS',
  setProcessCreateOrderFail: 'SET_PROCESS_CREATE_ORDER_FAIL',
  getOrderShipmentListRequest: 'GET_ORDER_SHIPMENT_LIST_REQUEST',
  getOrderShipmentListSuccess: 'GET_ORDER_SHIPMENT_LIST_SUCCESS',
  getOrderShipmentListFail: 'GET_ORDER_SHIPMENT_LIST_FAIL',
  getOrderShipmentDetailsRequest: 'GET_ORDER_SHIPMENT_DETAILS_REQUEST',
  getOrderShipmentDetailsSuccess: 'GET_ORDER_SHIPMENT_DETAILS_SUCCESS',
  getOrderShipmentDetailsFail: 'GET_ORDER_SHIPMENT_DETAILS_FAIL',
  getOrderSummaryListRequest: 'GET_ORDER_SUMMARY_LIST_REQUEST',
  getOrderSummaryListSuccess: 'GET_ORDER_SUMMARY_LIST_SUCCESS',
  getOrderSummaryListFail: 'GET_ORDER_SUMMARY_LIST_FAIL',
  getOrderSummaryReportExportRequest: 'GET_ORDER_SUMMARY_EXPORT_REPORT_REQUEST',
  getOrderSummaryReportExportSuccess: 'GET_ORDER_SUMMARY_EXPORT_REPORT_SUCCESS',
  getOrderSummaryReportExportFail: 'GET_ORDER_SUMMARY_EXPORT_REPORT_FAIL',
  getOrderShipmentTrackingDetailsRequest: 'GET_ORDER_SHIPMENT_TRACKING_DETAILS_REQUEST',
  getOrderShipmentTrackingDetailsSuccess: 'GET_ORDER_SHIPMENT_TRACKING_DETAILS_SUCCESS',
  getOrderShipmentTrackingDetailsFail: 'GET_ORDER_SHIPMENT_TRACKING_DETAILS_FAIL',
  getShipmentDeliveryReportRequest: 'GET_SHIPMENT_DELIVERY_REPORT_REQUEST',
  getShipmentDeliveryReportSuccess: 'GET_SHIPMENT_DELIVERY_REPORT_SUCCESS',
  getShipmentDeliveryReportFail: 'GET_SHIPMENT_DELIVERY_REPORT_FAIL',
  getShipmentDeliveryReportExportRequest: 'GET_SHIPMENT_DELIVERY_EXPORT_REPORT_REQUEST',
  getShipmentDeliveryReportExportSuccess: 'GET_SHIPMENT_DELIVERY_EXPORT_REPORT_SUCCESS',
  getShipmentDeliveryReportExportFail: 'GET_SHIPMENT_DELIVERY_EXPORT_REPORT_FAIL',
  getShipmentExportRequest: 'GET_SHIPMENT_EXPORT_REQUEST',
  getShipmentExportSuccess: 'GET_SHIPMENT_EXPORT_SUCCESS',
  getShipmentExportFail: 'GET_SHIPMENT_EXPORT_FAIL',
  getShippingDetailsRequest: 'GET_SHIPPING_DETAILS_REQUEST',
  getShippingDetailsSuccess: 'GET_SHIPPING_DETAILS_SUCCESS',
  getShippingDetailsFail: 'GET_SHIPPING_DETAILS_FAIL',
  getHandoverListRequest: 'GET_HANDOVER_LIST_REQUEST',
  getHandoverListSuccess: 'GET_HANDOVER_LIST_SUCCESS',
  getHandoverListFail: 'GET_HANDOVER_LIST_FAIL',
  getHandoverDetailsRequest: 'GET_HANDOVER_DETAILS_REQUEST',
  getHandoverDetailsSuccess: 'GET_HANDOVER_DETAILS_SUCCESS',
  getHandoverDetailsFail: 'GET_SHIPPING_DETAILS_FAIL',
  getCategoryListRequest: 'GET_CATEGORY_LIST_REQUEST',
  getCategoryListSuccess: 'GET_CATEGORY_LIST_SUCCESS',
  getCategoryListFail: 'GET_CATEGORY_LIST_FAIL',
  deleteHandoverRequest: 'DELETE_HANDOVER_REQUEST',
  deleteHandoverSuccess: 'DELETE_HANDOVER_SUCCESS',
  deleteHandoverFail: 'DELETE_HANDOVER_FAIL',
  getDataPrintHandoverRequest: 'GET_DATA_PRINT_HANDOVER_REQUEST',
  getDataPrintHandoverSuccess: 'GET_DATA_PRINT_HANDOVER_SUCCESS',
  getDataPrintHandoverFail: 'GET_DATA_PRINT_HANDOVER_FAIL',
  PostHandoverRequest: 'POST_HANDOVER_REQUEST',
  PostHandoverSuccess: 'POST_HANDOVER_SUCCESS',
  PostHandoverFail: 'POST_HANDOVER_FAIL',
  getNameHandoverRequest: 'GET_NAME_HANDOVER_REQUEST',
  getNameHandoverSuccess: 'GET_NAME_HANDOVER_SUCCESS',
  getNameHandoverFail: 'GET_NAME_HANDOVER_FAIL',
  // #region purchase order
  getPurchaseOrderPaginationRequest: 'GET_PURCHASE_ORDER_PAGINATION_REQUEST',
  getPurchaseOrderPaginationSuccess: 'GET_PURCHASE_ORDER_PAGINATION_SUCCESS',
  getPurchaseOrderPaginationFail: 'GET_PURCHASE_ORDER_PAGINATION_FAIL',
  setDeletePurchaseOrderRequest: 'SET_DELETE_PURCHASE_ORDER_REQUEST',
  setDeletePurchaseOrderSuccess: 'SET_DELETE_PURCHASE_ORDER_SUCCESS',
  setDeletePurchaseOrderFail: 'SET_DELETE_PURCHASE_ORDER_FAIL',
  getPurchaseOrderDetailsRequest: 'GET_PURCHASE_ORDER_DETAILS_REQUEST',
  getPurchaseOrderDetailsSuccess: 'GET_PURCHASE_ORDER_DETAILS_SUCCESS',
  getPurchaseOrderDetailsFail: 'GET_PURCHASE_ORDER_DETAILS_FAIL',
  getPurchaseOrderPrintRequest: 'GET_PURCHASE_ORDER_PRINT_REQUEST',
  getPurchaseOrderPrintSuccess: 'GET_PURCHASE_ORDER_PRINT_SUCCESS',
  getPurchaseOrderPrintFail: 'GET_PURCHASE_ORDER_PRINT_FAIL',
  // #endregion
  getAvailablePromoCodeRequest: 'GET_AVAILABLE_PROMO_CODE_REQUEST',
  getAvailablePromoCodeSuccess: 'GET_AVAILABLE_PROMO_CODE_SUCCESS',
  getAvailablePromoCodeFail: 'GET_AVAILABLE_PROMO_CODE_FAIL',
  // #region guest user
  getGuestUserRequest: 'GET_GUEST_USER_REQUEST',
  getGuestUserSuccess: 'GET_GUEST_USER_SUCCESS',
  getGuestUserFail: 'GET_GUEST_USER_FAIL',
  // #region get favorite menu
  getFavoriteMenusRequest: 'GET_FAVORITE_MENUS_REQUEST',
  getFavoriteMenusSuccess: 'GET_FAVORITE_MENUS_SUCCESS',
  getFavoriteMenusFail: 'GET_FAVORITE_MENUS_FAIL',
  // #region cancel order
  cancelOrderRequest: 'CANCEL_ORDER_REQUEST',
  cancelOrderSuccess: 'CANCEL_ORDER_SUCCESS',
  cancelOrderFail: 'CANCEL_ORDER_FAIL',
  // #region open bill
  saveOpenBillRequest: 'SAVE_OPEN_BILL_REQUEST',
  saveOpenBillSuccess: 'SAVE_OPEN_BILL_SUCCESS',
  saveOpenBillFail: 'SAVE_OPEN_BILL_FAIL',
  submitOpenBillRequest: 'SUBMIT_OPEN_BILL_REQUEST',
  submitOpenBillSuccess: 'SUBMIT_OPEN_BILL_SUCCESS',
  submitOpenBillFail: 'SUBMIT_OPEN_BILL_FAIL',
  setPrintCaptainOrderRequest: 'SET_PRINT_CAPTAIN_ORDER_REQUEST',
  setPrintCaptainOrderSuccess: 'SET_PRINT_CAPTAIN_ORDER_SUCCESS',
  setPrintCaptainOrderFail: 'SET_PRINT_CAPTAIN_ORDER_FAIL',
  getConsultationOrderDetailRequest: 'GET_CONSULTATION_ORDER_DETAIL_REQUEST',
  getConsultationOrderDetailSuccess: 'GET_CONSULTATION_ORDER_DETAIL_SUCCESS',
  getConsultationOrderDetailFail: 'GET_CONSULTATION_ORDER_DETAIL_FAIL',
  // #region update order payment
  changeOrderPaymentRequest: 'CHANGE_ORDER_PAYMENT_REQUEST',
  changeOrderPaymentSuccess: 'CHANGE_ORDER_PAYMENT_SUCCESS',
  changeOrderPaymentFail: 'CHANGE_ORDER_PAYMENT_FAIL',

  // #region Tax & Service Charge
  getOrderTaxPercentageRequest: 'GET_ORDER_TAX_REQUEST',
  getOrderTaxPercentageSuccess: 'GET_ORDER_TAX_SUCCESS',
  getOrderTaxPercentageFail: 'GET_ORDER_TAX_FAIL',
  getOrderServiceChargePercentageRequest: 'GET_ORDER_SERVICE_CHARGE_REQUEST',
  getOrderServiceChargePercentageSuccess: 'GET_ORDER_SERVICE_CHARGE_SUCCESS',
  getOrderServiceChargePercentageFail: 'GET_ORDER_SERVICE_CHARGE_FAIL',
  // #endregion
};

const initState = {
  fetching: false,
  fetchingExport: false,
  fetchingShippingAddress: false,
  fetchingShippingCost: false,
  fetchingPromoCode: false,
  fetchingPrinting: false,
  orderList: [],
  orderDetails: {},
  orderTrackingDetails: {},
  userDetails: {},
  listItem: [],
  listShippingAddress: [],
  shippingAddressInfo: {},
  addressTypeOption: [],
  addressTypeOptionRaw: [],
  deliveryServiceOption: [],
  eatOrderTypeOption: [],
  shippingCost: [],
  errorFetch: false,
  errorMessage: null,
  responseMessage: null,
  promoCodeDetails: {},
  orderSHIPPINGList: [],
  orderShipmentDetails: {},
  orderSummaryList: [],
  orderSummaryReportExport: [],
  shipmentDeliveryReportList: [],
  shipmentDeliveryReportExport: [],
  shipmentExport: [],
  shippingDetails: {},
  handoverList: [],
  handoverDetails: {},
  categoryList: [],
  handoverPrintList: [],
  handoverName: null,
  listPO: [],
  detailPO: [],
  detailsPrint: {},
  availablePromoCode: [],
  guestUser: {},
  favoriteMenus: [],
  consultationDetails: {},
};

export default function OrderReducer(state = initState, action) {
  const { type, payload } = action;
  switch (type) {
    // retail start
    case constants.getOrderListRequest:
      return { ...state, fetching: true };
    case constants.getOrderListSuccess:
      return {
        ...state,
        orderList: payload.data,
        fetching: false,
      };
    case constants.getOrderListFail:
      return {
        ...state,
        fetching: false,
        errorFetch: true,
        errorMessage: payload,
      };
    // retail end

    // eat start
    case constants.getOrderEatListByTypeRequest:
      return { ...state, fetching: true };
    case constants.getOrderEatListByTypeSuccess:
      return {
        ...state,
        eatOrderTypeOption: payload.data,
        fetching: false,
      };
    case constants.getOrderEatListByTypeFail:
      return {
        ...state,
        fetching: false,
        errorFetch: true,
        errorMessage: payload,
      };

    case constants.getOrderEatListByTypeProductRequest:
      return { ...state, fetching: true };
    case constants.getOrderEatListByTypeProductSuccess:
      return {
        ...state,
        listItem: payload.data,
        fetching: false,
      };
    case constants.getOrderEatListByTypeProductFail:
      return {
        ...state,
        fetching: false,
        errorFetch: true,
        errorMessage: payload,
      };
    // eat end

    case constants.getOrderDetailsRequest:
      return {
        ...state,
        fetching: true,
        orderDetails: null,
        errorMessage: null,
      };
    case constants.getOrderDetailsSuccess:
      return {
        ...state,
        orderDetails: payload.data,
        fetching: false,
        errorMessage: null,
      };
    case constants.getOrderDetailsFail:
      return {
        ...state,
        fetching: false,
        orderDetails: null,
        errorFetch: true,
        errorMessage: payload,
      };

    case constants.getUserDetailByPhoneRequest:
      return { ...state, fetching: true };
    case constants.getUserDetailByPhoneSuccess:
      return {
        ...state,
        userDetails: payload.data,
        fetching: false,
      };
    case constants.getUserDetailByPhoneFail:
      return {
        ...state,
        fetching: false,
        errorFetch: true,
        errorMessage: payload,
      };

    case constants.getOrderProductPaginationRequest:
      return { ...state, fetching: true };
    case constants.getOrderProductPaginationSuccess:
      return {
        ...state,
        listItem: payload.data,
        fetching: false,
      };
    case constants.getOrderProductPaginationFail:
      return {
        ...state,
        fetching: false,
        errorFetch: true,
        errorMessage: payload,
      };

    case constants.getOrderShippingAddressRequest:
      return { ...state, fetchingShippingAddress: true };
    case constants.getOrderShippingAddressSuccess:
      return {
        ...state,
        listShippingAddress: payload.data,
        fetchingShippingAddress: false,
      };
    case constants.getOrderShippingAddressFail:
      return {
        ...state,
        fetchingShippingAddress: false,
        errorFetch: true,
        errorMessage: payload,
      };

    case constants.getOrderShippingAddressInfoRequest:
      return { ...state, fetchingShippingAddress: false };
    case constants.getOrderShippingAddressInfoSuccess:
      return {
        ...state,
        shippingAddressInfo: payload.data,
        fetchingShippingAddress: false,
      };
    case constants.getOrderShippingAddressInfoFail:
      return {
        ...state,
        fetchingShippingAddress: false,
        errorFetch: true,
        errorMessage: payload,
      };

    case constants.getOrderShippingCostRequest:
      return { ...state, fetchingShippingCost: true };
    case constants.getOrderShippingCostSuccess:
      return {
        ...state,
        shippingCost: payload.data.data,
        fetchingShippingCost: false,
      };
    case constants.getOrderShippingCostFail:
      return {
        ...state,
        fetchingShippingCost: false,
        errorFetch: true,
        errorMessage: payload,
      };

    case constants.setCurrentAddressTypeOption:
      return { ...state, fetching: false, addressTypeOption: payload };
    case constants.setCurrentAddressTypeOptionRaw:
      return { ...state, fetching: false, addressTypeOptionRaw: payload };
    case constants.setCurrentDeliveryService:
      return { ...state, fetching: false, deliveryServiceOption: payload };

    case constants.checkPromoCodeRequest:
      return { ...state, fetchingPromoCode: true };
    case constants.checkPromoCodeSuccess:
      return {
        ...state,
        promoCodeDetails: payload.data,
        fetchingPromoCode: false,
      };
    case constants.checkPromoCodeFail:
      return {
        ...state,
        fetchingPromoCode: false,
        errorFetch: true,
        errorMessage: payload,
      };

    case constants.setProcessCreateOrderRequest:
      return { ...state, fetching: true };
    case constants.setProcessCreateOrderSuccess:
      return {
        ...state,
        fetching: false,
      };
    case constants.setProcessCreateOrderFail:
      return {
        ...state,
        fetching: false,
        errorFetch: true,
        errorMessage: payload,
      };
    case constants.getOrderShipmentListRequest:
      return { ...state, fetching: true };
    case constants.getOrderShipmentListSuccess:
      return {
        ...state,
        orderShipmentList: payload.data.data,
        fetching: false,
      };
    case constants.getOrderShipmentListFail:
      return {
        ...state,
        fetching: false,
        errorFetch: true,
        errorMessage: payload,
      };
    case constants.getOrderShipmentDetailsRequest:
      return { ...state, fetching: true };
    case constants.getOrderShipmentDetailsSuccess:
      return {
        ...state,
        orderShipmentDetails: payload.data,
        fetching: false,
      };
    case constants.getOrderShipmentDetailsFail:
      return {
        ...state,
        fetching: false,
        errorFetch: true,
        errorMessage: payload,
      };
    case constants.getOrderSummaryListRequest:
      return { ...state, fetching: true };
    case constants.getOrderSummaryListSuccess:
      return {
        ...state,
        orderSummaryList: payload.data,
        fetching: false,
      };
    case constants.getOrderSummaryListFail:
      return {
        ...state,
        fetching: false,
        errorFetch: true,
        errorMessage: payload,
      };

    case constants.getOrderShipmentTrackingDetailsRequest:
      return { ...state, fetching: true, responseMessage: null };
    case constants.getOrderShipmentTrackingDetailsSuccess:
      return {
        ...state,
        orderTrackingDetails: payload.data,
        fetching: false,
      };
    case constants.getOrderShipmentTrackingDetailsFail:
      return { ...state, fetching: false, errorMessage: payload };

    case constants.getOrderSummaryReportExportRequest:
      return {
        ...state,
        fetchingExport: true,
        responseMessage: null,
        orderSummaryReportExport: [],
      };
    case constants.getOrderSummaryReportExportSuccess:
      return {
        ...state,
        orderSummaryReportExport: payload.data,
        fetchingExport: false,
      };
    case constants.getOrderSummaryReportExportFail:
      return {
        ...state,
        fetchingExport: false,
        errorMessage: payload,
        orderSummaryReportExport: [],
      };

    case constants.getShipmentDeliveryReportRequest:
      return { ...state, fetching: true };
    case constants.getShipmentDeliveryReportSuccess:
      return {
        ...state,
        shipmentDeliveryReportList: payload.data.data,
        fetching: false,
      };
    case constants.getShipmentDeliveryReportFail:
      return {
        ...state,
        fetching: false,
        errorFetch: true,
        errorMessage: payload,
      };

    case constants.getShipmentDeliveryReportExportRequest:
      return {
        ...state,
        fetchingExport: true,
        responseMessage: null,
        shipmentDeliveryReportExport: [],
      };
    case constants.getShipmentDeliveryReportExportSuccess:
      return {
        ...state,
        shipmentDeliveryReportExport: payload.data,
        fetchingExport: false,
      };
    case constants.getShipmentDeliveryReportExportFail:
      return {
        ...state,
        fetchingExport: false,
        errorMessage: payload,
        shipmentDeliveryReportExport: [],
      };

    case constants.getShipmentExportRequest:
      return { ...state, fetchingExport: true, responseMessage: null, shipmentExport: [] };
    case constants.getShipmentExportSuccess:
      return {
        ...state,
        shipmentExport: payload.data,
        fetchingExport: false,
      };
    case constants.getShipmentExportFail:
      return { ...state, fetchingExport: false, errorMessage: payload, shipmentExport: [] };

    case constants.getShippingDetailsRequest:
      return {
        ...state,
        fetching: true,
        errorMessage: null,
        shippingDetails: null,
      };
    case constants.getShippingDetailsSuccess:
      return {
        ...state,
        fetching: false,
        errorMessage: null,
        shippingDetails: payload.data,
      };
    case constants.getShippingDetailsFail:
      return {
        ...state,
        fetching: false,
        errorFetch: true,
        errorMessage: payload,
        shippingDetails: null,
      };

    case constants.getHandoverListRequest:
      return { ...state, fetching: true };
    case constants.getHandoverListSuccess:
      return {
        ...state,
        handoverList: payload.data.data,
        fetching: false,
      };
    case constants.getHandoverListFail:
      return {
        ...state,
        fetching: false,
        errorFetch: true,
        errorMessage: payload,
      };
    case constants.getCategoryListRequest:
      return { ...state, fetching: true };
    case constants.getCategoryListSuccess:
      return {
        ...state,
        categoryList: payload.data,
        fetching: false,
      };
    case constants.getCategoryListFail:
      return {
        ...state,
        fetching: false,
        errorFetch: true,
        errorMessage: payload,
      };

    case constants.getHandoverDetailsRequest:
      return { ...state, fetching: true };
    case constants.getHandoverDetailsSuccess:
      return {
        ...state,
        handoverDetails: payload.data,
        fetching: false,
      };
    case constants.getHandoverDetailsFail:
      return {
        ...state,
        fetching: false,
        errorFetch: true,
        errorMessage: payload,
      };
    case constants.deleteHandoverRequest:
      return { ...state, fetching: true, responseMessage: null };
    case constants.deleteHandoverSuccess:
      return {
        ...state,
        responseMessage: payload.messages,
        fetching: false,
      };
    case constants.deleteHandoverFail:
      return { ...state, fetching: false, errorMessage: payload };

    case constants.getDataPrintHandoverRequest:
      return { ...state, fetching: true };
    case constants.getDataPrintHandoverSuccess:
      return {
        ...state,
        handoverPrintList: payload.data,
        fetching: false,
      };
    case constants.getDataPrintHandoverFail:
      return {
        ...state,
        fetching: false,
        errorFetch: true,
        errorMessage: payload,
      };

    case constants.PostHandoverRequest:
      return { ...state, fetching: true };
    case constants.PostHandoverSuccess:
      return {
        ...state,
        fetching: false,
        responseMessage: payload.messages,
      };
    case constants.PostHandoverFail:
      return {
        ...state,
        fetching: false,
        errorFetch: true,
        errorMessage: payload,
      };

    case constants.getNameHandoverRequest:
      return { ...state, fetching: true };
    case constants.getNameHandoverSuccess:
      return {
        ...state,
        handoverName: payload.data.name,
        fetching: false,
      };
    case constants.getNameHandoverFail:
      return {
        ...state,
        fetching: false,
        errorFetch: true,
        errorMessage: payload,
      };

    // #region PURCHASE ORDER
    case constants.getPurchaseOrderPaginationRequest:
      return { ...state, fetching: true, responseMessage: null };
    case constants.getPurchaseOrderPaginationSuccess:
      return {
        ...state,
        listPO: payload.data.data,
        total: payload.data.total,
        fetching: false,
      };
    case constants.getPurchaseOrderPaginationFail:
      return { ...state, fetching: false, errorMessage: payload };

    case constants.setDeletePurchaseOrderRequest:
      return { ...state, fetching: true, responseMessage: null };
    case constants.setDeletePurchaseOrderSuccess:
      return {
        ...state,
        fetching: false,
      };
    case constants.setDeletePurchaseOrderFail:
      return { ...state, fetching: false, errorMessage: payload };

    case constants.getPurchaseOrderDetailsRequest:
      return { ...state, fetching: true, responseMessage: null };
    case constants.getPurchaseOrderDetailsSuccess:
      return {
        ...state,
        detailPO: payload.data,
        fetching: false,
      };
    case constants.getPurchaseOrderDetailsFail:
      return { ...state, fetching: false, errorMessage: payload };

    case constants.getPurchaseOrderPrintRequest:
      return { ...state, fetchingPrinting: true, responseMessage: null, detailsPrint: [] };
    case constants.getPurchaseOrderPrintSuccess:
      return {
        ...state,
        detailsPrint: payload.data,
        fetchingPrinting: false,
      };
    case constants.getPurchaseOrderPrintFail:
      return { ...state, fetchingPrinting: false, errorMessage: payload, detailsPrint: [] };
    // #endregion

    case constants.getAvailablePromoCodeRequest:
      return { ...state, fetching: true, availablePromoCode: null, responseMessage: null };
    case constants.getAvailablePromoCodeSuccess:
      return {
        ...state,
        availablePromoCode: payload.data,
        fetching: false,
      };
    case constants.getAvailablePromoCodeFail:
      return { ...state, fetching: false, errorMessage: payload };

    case constants.getGuestUserRequest:
      return { ...state, fetching: true, responseMessage: null };
    case constants.getGuestUserSuccess:
      return {
        ...state,
        guestUser: payload.data,
        fetching: false,
      };
    case constants.getGuestUserFail:
      return { ...state, fetching: false, errorMessage: payload };

    case constants.getFavoriteMenusRequest:
      return { ...state, fetching: true, responseMessage: null };
    case constants.getFavoriteMenusSuccess:
      return {
        ...state,
        favoriteMenus: payload.data,
        fetching: false,
      };
    case constants.getFavoriteMenusFail:
      return { ...state, fetching: false, errorMessage: payload };

    case constants.saveOpenBillRequest:
      return { ...state, fetching: true };
    case constants.saveOpenBillSuccess:
      return {
        ...state,
        fetching: false,
      };
    case constants.saveOpenBillFail:
      return {
        ...state,
        fetching: false,
        errorFetch: true,
        errorMessage: payload,
      };

    case constants.submitOpenBillRequest:
      return { ...state, fetching: true };
    case constants.submitOpenBillSuccess:
      return {
        ...state,
        fetching: false,
      };
    case constants.submitOpenBillFail:
      return {
        ...state,
        fetching: false,
        errorFetch: true,
        errorMessage: payload,
      };

    case constants.setPrintCaptainOrderRequest:
      return { ...state, fetching: true };
    case constants.setPrintCaptainOrderSuccess:
      return {
        ...state,
        fetching: false,
      };
    case constants.setPrintCaptainOrderFail:
      return {
        ...state,
        fetching: false,
        errorFetch: true,
        errorMessage: payload,
      };

    case constants.getConsultationOrderDetailRequest:
      return { ...state, fetching: true };
    case constants.getConsultationOrderDetailSuccess:
      return {
        ...state,
        fetching: false,
        consultationDetails: payload.data,
      };
    case constants.getConsultationOrderDetailFail:
      return {
        ...state,
        fetching: false,
        errorFetch: true,
        errorMessage: payload,
      };

    default:
      return state;
  }
}
