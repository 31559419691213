import React from 'react';
import PropTypes from 'prop-types';
import { RadioGroup, Radio, FormControlLabel, Grid } from '@material-ui/core';
import './RadioInputImageStyle.scss';

const RadioInputImage = props => {
  const { data, currentValue, defaultValue, direction, onSelect, disabled, imageWidth } = props;

  const [selectedValue, setSelectedValue] = React.useState(defaultValue ? defaultValue.name : '');

  const handleItemChange = event => {
    const result = event.target.value;
    const index = data.findIndex(item => item.name === result);
    const resultItem = data[index];

    setSelectedValue(result);
    onSelect(resultItem);
  };

  React.useEffect(() => {
    if (currentValue) {
      setSelectedValue(currentValue.name);
    }
  }, [currentValue, selectedValue]);

  return (
    <RadioGroup value={selectedValue} onChange={handleItemChange}>
      <Grid container direction={direction}>
        <Grid item lg sm>
          {data.map((item, index) => (
            <FormControlLabel
              className="radio-option"
              key={index}
              value={item.name}
              control={<Radio color="primary" />}
              label={<img src={item.imageSource} alt="bank-logo" className="bank-logo" width={imageWidth} />}
              labelPlacement="end"
              disabled={disabled}
            />
          ))}
        </Grid>
      </Grid>
    </RadioGroup>
  );
};

RadioInputImage.propTypes = {
  currentValue: PropTypes.object,
  data: PropTypes.array,
  defaultValue: PropTypes.object,
  direction: PropTypes.string,
  disabled: PropTypes.bool,
  imageWidth: PropTypes.string,
  onSelect: PropTypes.func,
};

RadioInputImage.defaultProps = {
  direction: 'column',
  defaultValue: null,
  imageWidth: '100%'
};

export default RadioInputImage;
