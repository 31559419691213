import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Grid, Breadcrumbs, Link } from '@material-ui/core';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { compose } from 'redux';
// Components
import {
  ButtonMain,
  AuthenticationAccessPages,
  PrevStateValue,
  SkeletonDetailsV03,
  LabelStatusMain,
  PickerInputDate,
  ModalAlertAction,
  SnackBarSimple,
  SelectInputMain,
  TextInput,
} from '../../../components/Index';
// Style
import './PatientMealPlanAddStyle.scss';
// Helper
import {
  CommonHelper,
  PermissionModule,
  PermissionPage,
  PermissionAccess,
  HttpStatusCode,
  MasterDataHelper,
  roomTypeCode,
} from '../../../helpers/Index';
// Action
import {
  getDietType,
  getCuisineType,
  getPatientDetail,
  getPatientMealList,
  setCreatePatientMealPlan,
  skipMealDelivery,
} from '../../../services/api/EatApi';

const currentDate = CommonHelper.currentDate('YYYY-MM-DD');

const initialMealPlan = [
  {
    diet_type: null,
    diet_type_description: null,
    meal_time: 'breakfast',
    cuisine_type: null,
    cuisine_type_description: null,
    diet_notes: null,
  },
  {
    diet_type: null,
    diet_type_description: null,
    meal_time: 'morning-snack',
    cuisine_type: null,
    cuisine_type_description: null,
    diet_notes: null,
  },
  {
    diet_type: null,
    diet_type_description: null,
    meal_time: 'lunch',
    cuisine_type: null,
    cuisine_type_description: null,
    diet_notes: null,
  },
  {
    diet_type: null,
    diet_type_description: null,
    meal_time: 'afternoon-snack',
    cuisine_type: null,
    cuisine_type_description: null,
    diet_notes: null,
  },
  {
    diet_type: null,
    diet_type_description: null,
    meal_time: 'dinner',
    cuisine_type: null,
    cuisine_type_description: null,
    diet_notes: null,
  },
];

const optionToast = {
  vertical: 'top',
  horizontal: 'right',
};

class PatientMealPlanAdd extends React.Component {
  constructor(props) {
    super(props);

    props.checkUserAccessPermission(
      PermissionModule.Eat,
      PermissionPage.InPatient,
      PermissionAccess.Update,
    );

    const {
      match: { params },
    } = this.props;

    const mealPlanObject = {
      ...CommonHelper.decryptObject(params.param_object),
    };

    this.state = {
      isLoading: true,
      isOpenModal: false,
      toastInformation: {
        isOpen: false,
        message: '',
        snackbarType: 'warning',
      },
      userRoomId: mealPlanObject.roomId,
      mealDate: mealPlanObject.dateSelected,
      dietType: [],
      cuisine: [],
      mealPlanList: CommonHelper.objectCloning(initialMealPlan),
      userCateringScheduleId: null,
      isEligibleForSubmit: true,
    };
  }

  async componentDidMount() {
    await this.getPatientData();
  }

  getPatientData = async () => {
    const { patientDetail } = this.props;
    const { userRoomId } = this.state;
    try {
      await patientDetail(userRoomId);
      this.setState({
        isLoading: false,
      });
      this.getPatientMealPlanList();
      this.getDietType();
      this.getCuisineType();
    } catch (error) {
      const message = error.data;
      this.processMessage(message.messages, 'error');
    }
  };

  getPatientMealPlanList = () => {
    const {
      patientMealList,
      eatData: { patientDetail },
    } = this.props;
    const { userRoomId, mealDate, mealPlanList } = this.state;
    const param = {
      date: mealDate,
    };

    let tempMealPlanList = mealPlanList;
    if (patientDetail.room_type === roomTypeCode.CVC) {
      tempMealPlanList = mealPlanList.filter(
        item => item.meal_time === 'lunch' || item.meal_time === 'dinner',
      );
      this.setState({ mealPlanList: tempMealPlanList });
    }

    patientMealList(userRoomId, param).then(response => {
      const res = response.data;
      if (_.isEmpty(res)) {
        const tempData = tempMealPlanList.map(item => {
          return {
            ...item,
            diet_type: patientDetail.diet_type,
            diet_type_description: patientDetail.diet_type_description,
            diet_notes: patientDetail.diet_notes,
          };
        });
        this.setState({
          mealPlanList: tempData,
          isLoading: false,
          isEligibleForSubmit: false,
        });
      } else {
        const mergedList = _.map(tempMealPlanList, item => {
          let filtered = _.extend(item, _.find(res, { meal_time: item.meal_time }));
          if (!filtered.user_catering_schedule_id) {
            filtered = {
              ...filtered,
              diet_type: patientDetail.diet_type,
              diet_type_description: patientDetail.diet_type_description,
              notes: patientDetail.diet_notes,
            };
          }
          return filtered;
        });
        const tempData = mergedList.map(item => {
          return {
            ...item,
            diet_notes: item.notes,
          };
        });
        this.setState({
          mealPlanList: tempData,
          isLoading: false,
          isEligibleForSubmit: true,
        });
      }
    });
  };

  getDietType = async () => {
    const { listDietType } = this.props;
    try {
      const { data } = await listDietType();
      const dietType = data.map(item => ({ value: item.product_id, name: item.name }));
      this.setState({
        dietType,
        isLoading: false,
      });
    } catch (error) {
      const message = error.data;
      this.processMessage(message.messages, 'error');
    }
  };

  getCuisineType = async () => {
    const { cuisineType } = this.props;
    try {
      const { data } = await cuisineType();
      const cuisine = data.map(item => ({ value: item.product_id, name: item.name }));
      this.setState({
        cuisine,
        isLoading: false,
      });
    } catch (error) {
      const message = error.data;
      this.processMessage(message.messages, 'error');
    }
  };

  handleClick = (event, value) => {
    const { history } = this.props;
    event.preventDefault();
    history.push(value);
  };

  handleChangeDate = value => {
    this.setState(
      { mealDate: value, mealPlanList: CommonHelper.objectCloning(initialMealPlan) },
      () => {
        this.getPatientMealPlanList();
      },
    );
  };

  handleChangeDietType = (value, index) => {
    const { mealPlanList, dietType } = this.state;
    const temp = mealPlanList;
    const data = dietType.filter(item => {
      return item.value === value;
    });
    temp[index].diet_type = value;
    temp[index].diet_type_description = data[0].name;
    this.setState({ mealPlanList: temp });
  };

  handleChangeCuisine = (value, index) => {
    const { mealPlanList, cuisine } = this.state;
    const temp = mealPlanList;
    const data = cuisine.filter(item => {
      return item.value === value;
    });
    temp[index].cuisine_type = value;
    temp[index].cuisine_type_description = data[0].name;
    this.setState({ mealPlanList: temp, isEligibleForSubmit: true });
  };

  handleTextChangeMealNotes = (value, index) => {
    const { mealPlanList } = this.state;
    const temp = mealPlanList;
    temp[index].diet_notes = value;
    this.setState({ mealPlanList: temp });
  };

  handleOpenModalConfirmation = (userCateringScheduleId = null) => {
    this.setState({
      isOpenModal: true,
      userCateringScheduleId,
    });
  };

  handleCloseModalConfirmation = () => {
    this.setState({ isOpenModal: false });
  };

  handleBackButton = () => {
    const { history } = this.props;
    const { userRoomId } = this.state;
    const param = CommonHelper.encryptObject({ userRoomId });
    history.push(`/eat/in-patient/patient/details/${param}`);
  };

  handleButtonSkipMeal = () => {
    const { skipMeal } = this.props;
    const { userCateringScheduleId } = this.state;
    const arrTemp = [];
    arrTemp.push(userCateringScheduleId);
    const param = {
      user_catering_schedule_ids: arrTemp,
    };
    skipMeal(param)
      .then(async response => {
        const message = response.messages;
        await this.setState({ isOpenModal: false }, () => {
          this.processMessage(message, 'success');
          if (response.code === HttpStatusCode.Success) {
            setTimeout(async () => {
              this.handleBackButton();
            }, 2000);
          }
        });
      })
      .catch(error => {
        const message = error.data;
        this.processMessage(message.messages, 'error');
      });
  };

  handleCreatePatientMealPlan = () => {
    const { mealPlanList, mealDate, userRoomId, isEligibleForSubmit } = this.state;
    const { createPatientMealPlan } = this.props;
    const params = mealPlanList.map(item => {
      if (!item.cuisine_type) {
        this.setState({ isEligibleForSubmit: false });
      }
      return {
        diet_type: item.diet_type,
        meal_time: item.meal_time,
        cuisine: item.cuisine_type,
        diet_notes: item.diet_notes,
      };
    });

    if (!isEligibleForSubmit) {
      this.processMessage('Please choose cuisine at least one of meal time available.', 'error');
    } else {
      const request = {
        date: mealDate,
        meal_plans: params,
      };
      this.setState({ isLoading: true });
      createPatientMealPlan(userRoomId, request)
        .then(async response => {
          await this.processMessage(response.messages, response.status);
          this.setState(
            {
              isLoading: false,
            },
            () => {
              if (response.code === HttpStatusCode.Success) {
                setTimeout(async () => {
                  this.handleBackButton();
                }, 2000);
              }
            },
          );
        })
        .catch(async error => {
          const serverMessage = error.data;
          const validationStatus = error.status === HttpStatusCode.InternalServerError;
          this.processMessage(
            validationStatus ? serverMessage.message : serverMessage.messages,
            'error',
          );
          this.setState({
            isLoading: false,
          });
        });
    }
  };

  processMessage(messages, type) {
    const convertedMessage = CommonHelper.generateMessage(messages);

    const paramInformation = {
      isOpen: true,
      message: convertedMessage,
      snackbarType: type,
    };

    this.setState({
      toastInformation: paramInformation,
    });
  }

  renderModalConfirmation = () => {
    const { isOpenModal, userCateringScheduleId } = this.state;
    const modalDetailDataCreate = {
      title: '',
      body: '',
    };
    modalDetailDataCreate.title = 'Confirmation';
    modalDetailDataCreate.body = `Are you sure want to refund this meal with catering schedule id number: ${userCateringScheduleId} ?`;

    return (
      <ModalAlertAction
        onButtonSubmit={this.handleButtonSkipMeal}
        onCloseModal={this.handleCloseModalConfirmation}
        modalDetail={modalDetailDataCreate}
        modalType="danger"
        buttonSubmitText="Yes"
        buttonType="negative"
        openModal={isOpenModal}
      />
    );
  };

  renderPatientInformation() {
    const {
      eatData: { patientDetail },
    } = this.props;
    const { mealDate } = this.state;
    const birthday = CommonHelper.dateTimeParseNewFormat(patientDetail.birthday, 'DD MMMM YYYY');
    let type = '';
    switch (patientDetail.room_type) {
      case roomTypeCode.VVIP:
        type = 'primary';
        break;
      case roomTypeCode.VIP:
        type = 'complete';
        break;
      case roomTypeCode.Kelas1:
        type = 'warning';
        break;
      default:
        type = 'default';
        break;
    }
    return (
      <Grid item>
        <Grid container direction="row" justify="space-between">
          <Grid item lg={6} md={6}>
            <Grid container direction="column">
              <Grid item lg md className="mb-13">
                <label className="text-14 text-bold">{patientDetail.name}</label>
              </Grid>
              <Grid item lg={4} md={4}>
                <div className="flex-column">
                  <label className="text-12 title text-semi-bold">Meal date</label>
                  <div className="container-remove-margin">
                    <PickerInputDate
                      customIcon="ic-ffo-date-pick"
                      dateFormat="dd/MM/yyyy"
                      defaultValue={mealDate}
                      onChange={this.handleChangeDate}
                      toolbar={false}
                    />
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg="auto" md="auto">
            <Grid container direction="column">
              <Grid item className="mb-13">
                <Grid container direction="column" justify="flex-end">
                  <Grid item>
                    <div className="margin-custom">
                      <LabelStatusMain value={patientDetail.room_type} type={type} />
                    </div>
                    <div className="margin-custom">
                      <label className="text-12 text-semi-bold">{`${patientDetail.room_name} - ${patientDetail.room_number}`}</label>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container direction="column" justify="flex-end">
                  <Grid item>
                    <div className="margin-custom">
                      <label className="text-12 title text-semi-bold">
                        {patientDetail.gender &&
                          MasterDataHelper.gender[patientDetail.gender - 1].name}
                      </label>
                    </div>
                    <div className="margin-custom">
                      <label className="text-12 text-semi-bold">{birthday}</label>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  renderMealPlanList() {
    const { mealPlanList, dietType, cuisine, isLoading } = this.state;
    return (
      !_.isEmpty(mealPlanList) &&
      mealPlanList.map((item, index) => {
        let mealTime;
        let cuisineFiltered = cuisine;
        switch (item.meal_time) {
          case 'breakfast':
            mealTime = 'BREAKFAST';
            cuisineFiltered = cuisine.filter(value => value.name.toLowerCase() !== 'snack');
            break;
          case 'morning-snack':
            mealTime = 'MORNING SNACK';
            cuisineFiltered = cuisine.filter(value => value.name.toLowerCase() === 'snack');
            break;
          case 'lunch':
            mealTime = 'LUNCH';
            cuisineFiltered = cuisine.filter(value => value.name.toLowerCase() !== 'snack');
            break;
          case 'afternoon-snack':
            mealTime = 'AFTERNOON SNACK';
            cuisineFiltered = cuisine.filter(value => value.name.toLowerCase() === 'snack');
            break;
          case 'dinner':
            mealTime = 'DINNER';
            cuisineFiltered = cuisine.filter(value => value.name.toLowerCase() !== 'snack');
            break;
          default:
            break;
        }
        return (
          <div className="card-container mb-32" key={index}>
            <Grid item className="mb-10">
              <Grid container direction="row" justify="space-between">
                <Grid item lg={6} md={6}>
                  <label className="text-12 text-opacity-50 text-bold">{mealTime}</label>
                </Grid>
                {item.user_catering_schedule_id && item.date >= currentDate && (
                  <Grid item lg="auto" md="auto">
                    <ButtonMain
                      labelText="Cancel"
                      isLoading={isLoading}
                      onClick={() => {
                        this.handleOpenModalConfirmation(item.user_catering_schedule_id);
                      }}
                      type="error"
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item lg={12} md={12}>
              <Grid container direction="row">
                <Grid item lg md className="mr-16">
                  <div className="flex-column">
                    <label className="text-12 title text-semi-bold">Diet Type</label>
                    <SelectInputMain
                      options={dietType}
                      size="middle"
                      currentValue={item.diet_type_description}
                      onChange={value => {
                        this.handleChangeDietType(value, index);
                      }}
                      placeholder={'Select Diet Type'}
                    />
                  </div>
                </Grid>
                <Grid item lg={3} md={3} className="mr-16">
                  <div className="flex-column">
                    <label className="text-12 title text-semi-bold">Cuisine</label>
                    <SelectInputMain
                      options={cuisineFiltered}
                      size="middle"
                      currentValue={item.cuisine_type_description}
                      onChange={value => {
                        this.handleChangeCuisine(value, index);
                      }}
                      placeholder={'Select Cuisine'}
                    />
                  </div>
                </Grid>
                <Grid item lg md>
                  <div className="flex-column">
                    <label className="text-12 title text-semi-bold">Meal Notes</label>
                    <TextInput
                      placeHolderText="Enter Meal Notes"
                      onChange={value => {
                        this.handleTextChangeMealNotes(value, index);
                      }}
                      currentValue={item.diet_notes}
                      size="md"
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </div>
        );
      })
    );
  }

  render() {
    const {
      eatData: { fetching },
    } = this.props;
    const { isLoading, toastInformation, userRoomId, mealDate } = this.state;
    const param = CommonHelper.encryptObject({ userRoomId });

    return (
      <div>
        <Helmet title={`FITCO | Eats - In-Patient - Create Meal Plan`} />
        <div className="container-page-patient-in-patient-create scroll-container-invisible">
          <div className="container-page-scrolling-area">
            <Grid container direction="column">
              <Grid item lg md className="section-page-header">
                <Grid container>
                  <Grid item>
                    <div className="breadcrumbs-section">
                      <Breadcrumbs aria-label="breadcrumb">
                        <Link
                          className="text-12"
                          color="inherit"
                          href={`/eat/in-patient/patient/details/${param}`}
                          onClick={event => {
                            this.handleClick(event, `/eat/in-patient/patient/details/${param}`);
                          }}
                        >
                          <i className="ic-ffo-calendar container-icon-prefix size-16" /> Patient
                          Meal Plan
                        </Link>
                        <label className="text-12" color="inherit">
                          Add New Meal Plan
                        </label>
                      </Breadcrumbs>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item lg md className="section-page-body">
                {fetching ? (
                  <SkeletonDetailsV03 />
                ) : (
                  <div>
                    <div className="card-container mb-32">{this.renderPatientInformation()}</div>
                    {this.renderMealPlanList()}
                    <Grid container direction="row" justify="flex-end">
                      <Grid item className="mr-16">
                        <ButtonMain
                          labelText="Cancel"
                          isLoading={isLoading}
                          onClick={() => {
                            this.handleBackButton();
                          }}
                          type="ghost"
                        />
                      </Grid>
                      <Grid item>
                        <ButtonMain
                          labelText="Submit"
                          isLoading={isLoading}
                          disabled={mealDate < currentDate}
                          onClick={() => {
                            this.handleCreatePatientMealPlan();
                          }}
                          type="primary"
                        />
                      </Grid>
                    </Grid>
                  </div>
                )}
              </Grid>
              <Grid item lg md className="section-page-footer" />
            </Grid>
            <SnackBarSimple
              open={toastInformation.isOpen}
              durationHide={2000}
              message={toastInformation.message}
              onClickClose={this.handleCloseToast}
              snackbarType={toastInformation.snackbarType}
              anchor={optionToast}
            />
            {this.renderModalConfirmation()}
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  cuisineType: () => getCuisineType(dispatch),
  listDietType: () => getDietType(dispatch),
  patientDetail: userRoomId => getPatientDetail(dispatch, userRoomId),
  patientMealList: (userRoomId, params) => getPatientMealList(dispatch, userRoomId, params),
  createPatientMealPlan: (userRoomId, params) =>
    setCreatePatientMealPlan(dispatch, userRoomId, params),
  skipMeal: params => skipMealDelivery(dispatch, params),
});

const mapStateToProps = ({ eatData }) => ({ eatData });

PatientMealPlanAdd.propTypes = {
  checkUserAccessPermission: PropTypes.func,
  createPatientMealPlan: PropTypes.func,
  cuisineType: PropTypes.func,
  eatData: PropTypes.object,
  history: PropTypes.object,
  listDietType: PropTypes.func,
  match: PropTypes.object,
  patientDetail: PropTypes.func,
  patientMealList: PropTypes.func,
  skipMeal: PropTypes.func,
};

const shell = compose(connect(mapStateToProps, mapDispatchToProps));
const core = compose(AuthenticationAccessPages, PrevStateValue);

export default shell(core(PatientMealPlanAdd));
