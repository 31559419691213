import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
// helper
import { CommonHelper } from '../../../../../helpers/Index';
// Style
import './TickYAxisCurrencyStyle.scss';

const TickYAxisCurrency = props => {
  const { currency, x, y } = props;
  let renderElement = <div />;

  if (!_.isEmpty(props.payload)) {
    const data = props.payload;
    // const valueFormat = currency
    //   ? CommonHelper.currencyWordingValue(CommonHelper.formatCurrency(data.value))
    //   : data.value;
    // const wordingValue = `${currency || ''} ${valueFormat}`;
    const valueFormat = CommonHelper.currencyWordingValue(CommonHelper.formatCurrency(data.value));
    const wordingValue = `${currency || ''} ${valueFormat}`;

    renderElement = (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={9}
          textAnchor="end"
          fill="#666"
          className={'text-10 text-rolling-stone'}
        >
          {wordingValue.trim()}
        </text>
      </g>
    );
  }

  return renderElement;
};

TickYAxisCurrency.propTypes = {
  currency: PropTypes.string,
};

export default TickYAxisCurrency;
