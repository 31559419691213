/* eslint-disable no-restricted-properties */
/* eslint-disable radix */
// import { CommonHelper, MasterDataHelper, TransactionStatus } from './Index';
// import { Icons } from '../assets/Index';
// import themes from '../themes/Index';

const TransactionHelper = {};

const TransactionStatus = {
  MembershipPackage: 'membership_package',
  TopUpPoint: 'top_up_points',

  // Point Booster
  PointBooster: 'point_booster',

  // Move Vertical
  WorkoutSession: 'workout_session',
  CoachSession: 'coach_session',

  // Eat Vertical
  FoodList: 'foodlist',
  Food: 'food',
  FoodExtras: 'food_extras',

  // Event
  Event: 'event',
  EventTicket: 'event_ticket',

  // Retail
  Retail: 'retail',

  // Freeze Fee
  FreezeFee: 'freeze_fee',
};

const paymentStatus = {
  Cancelled: -1,
  AwaitingToken: 0,
  TokenGenerated: 1,
  VirtualBankAccountGenerated: 2,
  CreditCardCharged: 3,
  Expired: 4,
  Pending: 5,
  Declined: 6,
  Suspended: 7,
  Confirmation: 8,
  Refund: 9,
  Complete: 10,
  Settlement: 11,
  GoPayQRAndDeeplinkGenerated: 12,
};

const paymentMethod = {
  CreditCard: 'Credit Card',
  BankTransfer: 'Bank Transfer',
  EDC: 'EDC',
  FitPoint: 'FIT Points',
  PointBooster: 'Booster',
  MarkAsPaid: 'Marked as Paid',
  CorporateDeal: 'Corporate Deal',
  Giveaway: 'Giveaway',
  GoPay: 'GoPay',
  OVO: 'OVO',
  DANA: 'DANA',
  LinkAja: 'LinkAja',
};

const OrderType = {
  Move: 'move',
  Shop: 'shop',
  Eat: 'eat',
  TopUpPoint: 'top_up_point',
  FitPointMembership: 'fitpoint_membership',
  MerchantMembership: 'merchant_membership',
  FreezeMembership: 'freeze_membership',
};

const currencyCode = {
  idr: 'IDR',
};

export {
  TransactionHelper,
  TransactionStatus,
  paymentStatus,
  paymentMethod,
  OrderType,
  currencyCode,
};
