/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { compose } from 'redux';
// component
import { PrevNextStepperCount } from '../../../../../../components/Index';
// style
import './StepRegisterUserAccessStyle.scss';

class StepRegisterUserAccess extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <Grid container className="flex-column">
        <Grid container className="container-step-register-user-access">
          <Grid item lg={12} md={12} className="container-main-card p-32 mb-22">
            <Grid item lg={12} md={12} className="mb-4">
              <label className="text-12 text-bold text-rolling-stone">USER SETTINGS</label>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

const mapDispatchToProps = () => ({});

const mapStateToProps = ({ masterData }) => ({ masterData });

StepRegisterUserAccess.propTypes = {
  masterData: PropTypes.object,
};

const shell = compose(connect(mapStateToProps, mapDispatchToProps));

const core = compose(PrevNextStepperCount);

export default shell(core(StepRegisterUserAccess));
