import React from 'react';
import PropTypes from 'prop-types';
import { Paper, Grid } from '@material-ui/core';
// style
import './HeaderMembershipPurchaseStyle.scss';
// component
import { ButtonMain } from '../../../../../components/Index';
import { PermissionAccess, PermissionModule, PermissionPage } from '../../../../../helpers/PermissionHelper';

const HeaderMembershipPurchase = props => {
  const { onButtonCreateClick } = props;

  return (
    <Paper square className="container-tab-header-membership-purchase">
      <Grid container justify="flex-end" alignItems="center" className="container-content">
        <Grid item xl={2} lg={3} md={3}>
          <ButtonMain
            labelText="Purchase Membership"
            onClick={onButtonCreateClick}
            type="primary"
            size="md"
            startIcon="ic-ffo-add"
            requiredPermission={`${PermissionModule.Memberships}.${PermissionPage.Purchase}.${PermissionAccess.Add}`}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

HeaderMembershipPurchase.propTypes = {
  onButtonCreateClick: PropTypes.func,
};

export default HeaderMembershipPurchase;
