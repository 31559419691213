import React from 'react';
// Style
import './CustomSimpleOptionStyle.scss';

const CustomSimpleLabel = (title, additional) => {
  return (
    <span className="flex-row">
      <label className="text-14">{title}</label>
      <label className="text-14">&nbsp;-&nbsp;</label>
      <label className="text-14">{additional}</label>
    </span>
  );
};

// eslint-disable-next-line no-unused-vars
const CustomSimpleOption = (title, additional) => ({
  //   value: title,
  //   label: (
  //     <span className="flex-row">
  //       <label className="text-14">{title}</label>
  //       <label className="text-14">{additional}</label>
  //     </span>
  //   ),
});

export { CustomSimpleLabel, CustomSimpleOption };
