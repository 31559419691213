import React from 'react';
import PropTypes from 'prop-types';
import DateFnsUtils from '@date-io/date-fns';
import 'date-fns';
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from '@material-ui/pickers';
// style
import './PickerInputDateAndTimeStyle.scss';
// helper
import { CommonHelper } from '../../../helpers/Index';

const PickerInputDateAndTime = props => {
  const {
    labelHeader,
    // isError,
    // errorMessage,
    maxDate,
    minDate,
    defaultValue,
    onChange,
    customIcon,
    size,
  } = props;

  const currentDate = CommonHelper.currentDate('YYYY-MM-DD HH:mm');
  const defaultMaxDate = CommonHelper.intervalDate(currentDate, 'YYYY-MM-DD HH:mm', 1, 'year');
  const convertDateMax = maxDate ? new Date(maxDate) : new Date(defaultMaxDate);
  const convertDateMin = minDate ? new Date(minDate) : new Date('1900-01-01 00:00');

  const [selectedDate, setSelectedDate] = React.useState(
    defaultValue ? new Date(defaultValue) : new Date(),
  );

  React.useEffect(() => {
    const updateValue = async () => {
      setSelectedDate(defaultValue);
    };
    updateValue();
  }, [defaultValue]);

  const handleSelectChange = dateTime => {
    const convertTime = CommonHelper.dateTimeParseNewFormat(dateTime, 'YYYY-MM-DD HH:mm');
    const result = convertTime;
    setSelectedDate(dateTime);
    onChange(result);
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDateTimePicker
        variant="inline"
        ampm={false}
        label={labelHeader}
        className={`custom-outline-size-date-time ${size}`}
        inputVariant="outlined"
        value={selectedDate}
        maxDate={convertDateMax}
        minDate={convertDateMin}
        onChange={handleSelectChange}
        KeyboardButtonProps={{
          'aria-label': 'change time',
        }}
        keyboardIcon={<i className={`${customIcon} container-icon-prefix ${size}`} />}
        autoOk
      />
    </MuiPickersUtilsProvider>
  );
};

PickerInputDateAndTime.propTypes = {
  // errorMessage: PropTypes.string,
  // isError: PropTypes.bool,
  customIcon: PropTypes.string,
  defaultValue: PropTypes.string,
  labelHeader: PropTypes.string,
  maxDate: PropTypes.string,
  minDate: PropTypes.string,
  onChange: PropTypes.func,
  size: PropTypes.string,
};

PickerInputDateAndTime.defaultProps = {
  defaultValue: null,
  maxDate: null,
  minDate: null,
  size: 'md',
};

export default PickerInputDateAndTime;
