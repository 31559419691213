import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import PropTypes from 'prop-types';
import { Form } from 'antd';
// Style
import './TextEditorStyle.scss';
import { Grid } from '@material-ui/core';
import ValidationHelper from '../../../helpers/ValidationHelper';

const TextEditor = props => {
  const {
    currentValue,
    // defaultValue,
    errorMessage,
    maxLength,
    validateStatus,
    onChange,
    modules,
  } = props;

  const [valueState, setValue] = React.useState(currentValue || '');
  const [StringLength, setStringLength] = React.useState(0);

  React.useEffect(() => {
    const updateValue = async () => {
      setValue(currentValue);
    };
    updateValue();
  }, [currentValue]);

  const onChangeTextInput = (content, delta, source, editor) => {
    let stringOnly = editor.getText();
    // let stringLength = editor.getLength() - 1;
    const stringLength = ValidationHelper.validateWord(stringOnly);
    let ifPass = false;

    if (maxLength) {
      if (stringLength <= maxLength) {
        ifPass = true;
      } else {
        content = valueState;
        // stringLength -= 1;
        stringOnly = stringOnly.slice(0, -1);
      }
    } else {
      ifPass = true;
    }

    if (ifPass) {
      setValue(content);
    }

    if (content === '<p><br></p>') {
      content = '';
    }

    setStringLength(stringLength);
    onChange(content, stringOnly, stringLength);
  };

  return (
    <Form.Item
      validateStatus={validateStatus}
      help={errorMessage}
      className="container-text-editor"
    >
      <ReactQuill
        className={validateStatus === 'error' ? 'quill-error' : ''}
        theme="snow"
        modules={modules}
        value={valueState}
        onChange={onChangeTextInput}
      />
      {maxLength ? (
        <Grid container justify="flex-end">
          <Grid item lg={3} md={3} className="container-content-length">
            <label className="text-10">{`${StringLength} / ${maxLength}`}</label>
          </Grid>
        </Grid>
      ) : null}
    </Form.Item>
  );
};

TextEditor.propTypes = {
  currentValue: PropTypes.string,
  // defaultValue: PropTypes.string,
  errorMessage: PropTypes.string,
  maxLength: PropTypes.number,
  modules: PropTypes.array,
  onChange: PropTypes.func,
  validateStatus: PropTypes.string,
};

export default TextEditor;
