import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { compose } from 'redux';
// component
import {
  HeaderAppointment,
  DraftAppointmentList,
  CompletedAppointmentList,
} from './components/Index';
import { SnackBarSimple, AuthenticationAccessPages } from '../../../../components/Index';
// Helper
import { CommonHelper } from '../../../../helpers/Index';

const optionToast = {
  vertical: 'top',
  horizontal: 'right',
};

const dataTabs = [{ name: 'Upcoming' }, { name: 'History' }];

class AppointmentPages extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tabsValue: 0,
      toastInformation: {
        isOpen: false,
        message: '',
        snackbarType: 'warning',
      },
    };
  }

  handleTabChange = value => {
    this.setState({ tabsValue: value });
  };

  handleCloseToast = () => {
    const { toastInformation } = this.state;
    this.setState({ toastInformation: { ...toastInformation, isOpen: false } });
  };

  handleClickAppointment = param => {
    const { history } = this.props;
    const paramFilter = CommonHelper.encryptObject(param);
    history.push(`/medic/consultation/detail/${paramFilter}`);
  };

  handleScheduleClickBook = itemValue => {
    const { history } = this.props;
    let param = {};

    if (itemValue) {
      param = {
        scheduleId: itemValue.scheduleId,
        items: [{ ...itemValue.option, qty: 1 }],
      };
    }

    const paramFilter = CommonHelper.encryptObject(param);
    history.push(`/medic/clinic-schedule/book/${paramFilter}`);
  };

  processMessage(messages, type) {
    const convertedMessage = CommonHelper.generateMessage(messages);

    const paramInformation = {
      isOpen: true,
      message: convertedMessage,
      snackbarType: type,
    };

    this.setState({
      toastInformation: paramInformation,
    });
  }

  renderPages = () => {
    const { tabsValue } = this.state;
    let renderElement = null;

    switch (dataTabs[tabsValue]) {
      case dataTabs[0]:
        renderElement = <DraftAppointmentList onClickAppointment={this.handleClickAppointment} />;
        break;
      default:
        renderElement = <CompletedAppointmentList />;
        break;
    }

    return renderElement;
  };

  render() {
    const { toastInformation } = this.state;

    return (
      <div>
        <Helmet title={`FITCO | Medic - Consultation`} />
        <div className="row">
          <HeaderAppointment
            data={dataTabs}
            currentTab={this.state.tabsValue}
            onChangeTab={this.handleTabChange}
            onButtonClickBooking={this.handleScheduleClickBook}
          />
          {this.renderPages()}
        </div>
        <SnackBarSimple
          open={toastInformation.isOpen}
          durationHide={2000}
          message={toastInformation.message}
          onClickClose={this.handleCloseToast}
          snackbarType={toastInformation.snackbarType}
          anchor={optionToast}
        />
      </div>
    );
  }
}

const mapDispatchToProps = () => ({});

// const mapStateToProps = () => ({});

AppointmentPages.propTypes = {
  history: PropTypes.object,
};

const shell = compose(connect(null, mapDispatchToProps));

export default shell(AuthenticationAccessPages(AppointmentPages));
