/* eslint-disable react/no-unused-state */
import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Grid, Breadcrumbs, Link } from '@material-ui/core';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { compose } from 'redux';
// Components
import {
  LabelStatusPayment,
  ButtonMain,
  GridRowTableOneColumn,
  GridRowTableFourColumn,
  ShippingStatus,
  AuthenticationAccessPages,
  PrevStateValue,
  SkeletonDetailsV03,
} from '../../../../components/Index';
// Style
import './ShippingDetailsStyle.scss';
// Helper
import {
  MasterDataHelper,
  CommonHelper,
  PermissionModule,
  PermissionPage,
  PermissionAccess,
  HttpStatusCode,
} from '../../../../helpers/Index';
// Action
import { getShippingDetails } from '../../../../services/api/OrdersApi';
// Assets
import { Icons } from '../../../../assets/Index';

const paymentMethodOption = MasterDataHelper.paymentMethodOption;

class ShippingDetails extends React.Component {
  constructor(props) {
    super(props);

    props.checkUserAccessPermission(
      PermissionModule.Orders,
      PermissionPage.Shipment,
      PermissionAccess.View,
    );

    this.state = {
      isLoading: false,
      salesOrderId: null,
    };
  }

  async componentDidMount() {
    const { getDetails } = this;
    await getDetails();
  }

  async componentDidUpdate() {
    const {
      orderData: { fetching, errorMessage },
      history,
    } = this.props;

    if (!fetching && errorMessage && errorMessage.code === HttpStatusCode.Forbidden) {
      history.push('/order/shipment');
    }
  }

  getDetails = () => {
    const {
      getShipping,
      match: { params },
    } = this.props;

    const param = {
      sales_invoice_id: params.sales_invoice_id,
    };
    getShipping(param);
  };

  handleClick = (event, value) => {
    const { history } = this.props;
    event.preventDefault();
    history.push(value);
  };

  handleButtonCancel = () => {
    const { history } = this.props;
    history.push('/order/shipment');
  };

  renderOrderCard = () => {
    const {
      orderData: { shippingDetails },
    } = this.props;
    const orderDetailsData = !_.isEmpty(shippingDetails) ? shippingDetails.order_details : {};
    const shippingDetailsData = !_.isEmpty(shippingDetails) ? shippingDetails.shipping_details : {};

    return (
      <Grid container className="order-card-details" direction="row" wrap={'nowrap'}>
        <Grid item lg={10} md={10}>
          <Grid container direction="column">
            <Grid item className="pt-16">
              <Grid container spacing={2} direction="row">
                <Grid item lg={3} md={3} className="order-items-text">
                  <label className="text-12 text-opacity-50">Order Number</label>
                  <label className="text-12 pt-4 text-bold">{orderDetailsData.order_no}</label>
                </Grid>
                <Grid item lg={2} md={2} className="order-items-text">
                  <label className="text-12 text-opacity-50">W-Order ID</label>
                  <label className="text-12 pt-4 text-bold">
                    {orderDetailsData.external_order_id || '-'}
                  </label>
                </Grid>
                <Grid item lg={3} md={3} className="order-items-text">
                  <label className="text-12 text-opacity-50">Delivery Service</label>
                  <label className="text-12 pt-4 text-bold">
                    {`${_.capitalize(orderDetailsData.shipping_method)} ${
                      orderDetailsData.shipping_title
                    } `}
                  </label>
                </Grid>
                <Grid item lg={2} md={2} className="flex-column">
                  <label className="text-12 text-opacity-50">Shipment Date</label>
                  <label className="text-12 text-bold pt-4">
                    {shippingDetailsData.shipment_date
                      ? `${CommonHelper.dateTimeParseNewFormat(
                          shippingDetailsData.shipment_date,
                          'DD MMM YYYY',
                        )}`
                      : `-`}
                  </label>
                </Grid>
                <Grid item lg={2} md={2} className="order-items-text">
                  <label className="text-12 text-opacity-50">Air Waybill Number</label>
                  <label className="text-12 pt-4 text-bold">
                    {orderDetailsData.airway_bill_number || 'Not Available'}
                  </label>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={2} md={2}>
          <Grid container direction="row" className="order-amount-section">
            <Grid item lg={12} md={12}>
              <Grid
                container
                direction="column"
                justify="center"
                className="order-items-amount-text"
              >
                <Grid item lg md>
                  {orderDetailsData.payment_method === paymentMethodOption[3].value ? (
                    <img src={Icons.fitPoints} alt="Fit Points" />
                  ) : (
                    <label className="text-16 text-bold currency">IDR </label>
                  )}
                  <label className="text-16 text-bold currency">
                    {orderDetailsData.total_paid !== 0
                      ? CommonHelper.formatCurrency(orderDetailsData.total_paid)
                      : 0}
                  </label>
                </Grid>
                <Grid item lg md>
                  <LabelStatusPayment status={parseInt(orderDetailsData.payment_status, 10)} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  renderShippingTrack = () => {
    const {
      orderData: { shippingDetails },
    } = this.props;
    const shippingDetailsData = !_.isEmpty(shippingDetails) ? shippingDetails.shipping_details : {};

    return (
      <Grid container className="pt-24" direction="column">
        <Grid item lg={12} md={12} className="pt-32">
          <Grid container direction="column">
            <ShippingStatus selectedStatus={parseInt(shippingDetailsData.order_status, 10)} />
          </Grid>
        </Grid>
        <Grid item lg={12} md={12} className="pt-32">
          <Grid container direction="column">
            <Grid item className="flex-column">
              <label className="text-12 text-opacity-50">Delivery Status</label>
              <label className="text-14 pt-4 delivery-date">{`${CommonHelper.dateTimeParseNewFormat(
                shippingDetailsData.delivery_status_updated_at,
                'DD MMM YYYY',
              )} | ${CommonHelper.dateTimeParseNewFormat(
                shippingDetailsData.delivery_status_updated_at,
                'hh:mm A',
              )}`}</label>
              <label className="text-14 pt-4 delivery-status-info">
                {shippingDetailsData.delivery_status}
              </label>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  renderShippingDetails = () => {
    const {
      orderData: { shippingDetails },
    } = this.props;
    const shippingDetailsData = !_.isEmpty(shippingDetails) ? shippingDetails.shipping_details : {};
    const shippingOrderDetailsData = !_.isEmpty(shippingDetails)
      ? shippingDetails.order_details
      : {};

    const recipient = !_.isEmpty(shippingDetailsData)
      ? `${shippingDetailsData.shipping_receiver} - ${shippingDetailsData.recipient_phone}`
      : '-';

    return (
      <Grid container className="pt-24" direction="column">
        <Grid item lg={12} md={12} className="pb-16">
          <label className="text-12 text-opacity-50 text-bold">Shipping Details</label>
        </Grid>
        <Grid item lg={12} md={12} className="order-card-details">
          <Grid container direction="column">
            <Grid item lg={12} md={12}>
              <label className="text-14 text-bold">
                {_.capitalize(shippingOrderDetailsData.shipping_method)} -{' '}
                {shippingOrderDetailsData.shipping_title}
              </label>
            </Grid>
            <Grid item lg={12} md={12}>
              <Grid container direction="column">
                <Grid item className="pb-8 pt-16">
                  <label className="text-12 text-bold text-opacity-50 wrap">{recipient}</label>
                </Grid>
                <Grid item>
                  <label className="text-14 wrap">{shippingDetailsData.delivery_address}</label>
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={12} md={12}>
              <Grid container direction="column">
                <Grid item className="pt-8">
                  <label className="text-12 text-bold text-opacity-50">Notes</label>
                </Grid>
                <Grid item>
                  <label className="text-14 wrap">{shippingDetailsData.notes || '-'}</label>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  renderOrderSummary = () => {
    const {
      orderData: { shippingDetails },
    } = this.props;

    const orderSummaryData = !_.isEmpty(shippingDetails) ? shippingDetails.order_summary : {};
    const orderDetailsData = !_.isEmpty(shippingDetails) ? shippingDetails.order_details : {};

    const orderItems = orderSummaryData.product_details || [];

    return (
      <Grid container className="pt-24" direction="row" spacing={4}>
        <Grid item lg={12} md={12}>
          <label className="text-16 text-opacity-50">Order Summary</label>
        </Grid>
        <Grid item lg={12} md={12} className="container-order-cart-item pt-32">
          <GridRowTableFourColumn
            customElementColumnFirst={<label className="text-14 header-title">Product Name</label>}
            customElementColumnSecond={<label className="text-14 header-title">Price</label>}
            customElementColumnThird={<label className="text-14 header-title">Qty</label>}
            customElementColumnFourth={<label className="text-14 header-title">Total Price</label>}
            columnFirst={5}
            columnSecond={2}
            columnThird={2}
            columnFourth={3}
          />
          <div className="scroll-container">
            <Grid container direction="column">
              {orderItems.map((item, index) => {
                const priceTotal = CommonHelper.formatCurrency(
                  item.quantity * parseInt(item.item_price, 10),
                );
                const priceItem = CommonHelper.formatCurrency(item.item_price);

                return (
                  <GridRowTableFourColumn
                    key={index}
                    customElementColumnFirst={
                      <label className="text-14 row-item-title">{item.product_name}</label>
                    }
                    customElementColumnSecond={<label className="text-14">IDR {priceItem}</label>}
                    customElementColumnThird={<label className="text-14">{item.quantity}</label>}
                    customElementColumnFourth={<label className="text-14">IDR {priceTotal}</label>}
                    columnFirst={5}
                    columnSecond={2}
                    columnThird={2}
                    columnFourth={3}
                  />
                );
              })}
            </Grid>
          </div>
          <GridRowTableOneColumn
            customElementInitial={
              <div className="flex-column">
                <label className="text-14 row-item-title">Shipping Fee</label>
                <label className="text-10">
                  {_.capitalize(orderDetailsData.shipping_method)} -{' '}
                  {orderDetailsData.shipping_title}
                </label>
              </div>
            }
            customElementColumn={
              <label className="text-14">
                {orderSummaryData.shipping_cost > 0
                  ? `IDR ${CommonHelper.formatCurrency(orderSummaryData.shipping_cost)}`
                  : `Free`}
              </label>
            }
            columnLeft={10}
            columnRight={2}
          />
          {orderSummaryData.price_discount !== 0 ? (
            <GridRowTableOneColumn
              customElementInitial={
                <div className="flex-column">
                  <label className="text-14 row-item-title">Discount</label>
                  <label className="text-10 ">{orderSummaryData.promo_code}</label>
                </div>
              }
              customElementColumn={
                <label className="text-14 discount">
                  IDR {CommonHelper.formatCurrency(orderSummaryData.discount)}
                </label>
              }
              columnLeft={10}
              columnRight={2}
            />
          ) : null}
          <GridRowTableOneColumn
            customElementInitial={
              <label className="text-16 row-item-title">
                Total Item(s): {orderSummaryData.total_items}
              </label>
            }
            customElementColumn={
              <label className="text-16 row-item-title">
                Grand Total &nbsp; &nbsp; &nbsp; IDR{' '}
                {CommonHelper.formatCurrency(orderSummaryData.grand_total)}
              </label>
            }
            columnLeft={7}
            columnRight={5}
          />
        </Grid>
      </Grid>
    );
  };

  render() {
    const {
      orderData: { fetching },
    } = this.props;

    let renderElement = <SkeletonDetailsV03 />
    
    if (!fetching) {
      renderElement = (
        <div>
          <Helmet title={`FITCO | Orders - Shipping Details`} />
          <div className="container-page-shipping-details scroll-container-invisible">
            <div className="container-page-scrolling-area">
              <Grid container direction="column">
                <Grid item lg md className="section-page-header">
                  <Grid container>
                    <Grid item>
                      <div className="breadcrumbs-section">
                        <Breadcrumbs aria-label="breadcrumb">
                          <Link
                            className="text-12"
                            color="inherit"
                            href="/orders"
                            onClick={event => {
                              this.handleClick(event, '/orders');
                            }}
                          >
                            <i className="icon-slot ic-ffo-reports" /> Order
                          </Link>
                          <Link
                            className="text-12"
                            color="inherit"
                            href="/orders"
                            onClick={event => {
                              this.handleClick(event, '/order/shipment');
                            }}
                          >
                            Shipment
                          </Link>
                          <label className="text-12" color="inherit">
                            Shipping Details
                          </label>
                        </Breadcrumbs>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item lg md className="section-page-body">
                  <div className="order-details-card">
                    <Grid className="pb-24" container justify="flex-end" spacing={3}>
                      <Grid item lg={3} md={3}>
                        <label className="text-16 text-opacity-50">Shipping Details</label>
                      </Grid>
                      <Grid item lg={8} md={8} />
                      <Grid item lg={1} md={1}>
                        <ButtonMain
                          type="ghost"
                          size="xl"
                          labelText="Back"
                          onClick={this.handleButtonCancel}
                        />
                      </Grid>
                    </Grid>
                    <Grid container>{this.renderOrderCard()}</Grid>
                    <Grid container spacing={4}>
                      <Grid item lg={6} md={6}>
                        {this.renderShippingTrack()}
                      </Grid>
                      <Grid item lg={6} md={6}>
                        {this.renderShippingDetails()}
                      </Grid>
                    </Grid>
                  </div>
                  <div className="order-details-card mt-24">{this.renderOrderSummary()}</div>
                </Grid>
                <Grid item lg md className="section-page-footer" />
              </Grid>
            </div>
          </div>
        </div>
      );
    }

    return renderElement
  }
}

const mapDispatchToProps = dispatch => ({
  getShipping: params => getShippingDetails(dispatch, params),
});

const mapStateToProps = ({ orderData }) => ({
  orderData,
});

ShippingDetails.propTypes = {
  checkUserAccessPermission: PropTypes.func,
  getShipping: PropTypes.func,
  history: PropTypes.object,
  match: PropTypes.object,
  orderData: PropTypes.object,
};

const shell = compose(connect(mapStateToProps, mapDispatchToProps));
const core = compose(AuthenticationAccessPages, PrevStateValue);

export default shell(core(ShippingDetails));
