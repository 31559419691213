export const constants = {
  getCampaignPaginationRequest: 'GET_CAMPAIGN_PAGINATION_REQUEST',
  getCampaignPaginationSuccess: 'GET_CAMPAIGN_PAGINATION_SUCCESS',
  getCampaignPaginationFail: 'GET_CAMPAIGN_PAGINATION_FAIL',
  setDeleteCampaignRequest: 'SET_DELETE_CAMPAIGN_REQUEST',
  setDeleteCampaignSuccess: 'SET_DELETE_CAMPAIGN_SUCCESS',
  setDeleteCampaignFail: 'SET_DELETE_CAMPAIGN_FAIL',
  getCampaignDetailsRequest: 'GET_CAMPAIGN_DETAILS_REQUEST',
  getCampaignDetailsSuccess: 'GET_CAMPAIGN_DETAILS_SUCCESS',
  getCampaignDetailsFail: 'GET_CAMPAIGN_DETAILS_FAIL',
  setUpdateCampaignRequest: 'SET_UPDATE_CAMPAIGN_REQUEST',
  setUpdateCampaignSuccess: 'SET_UPDATE_CAMPAIGN_SUCCESS',
  setUpdateCampaignFail: 'SET_UPDATE_CAMPAIGN_FAIL',
  setCreateCampaignRequest: 'SET_CREATE_CAMPAIGN_REQUEST',
  setCreateCampaignSuccess: 'SET_CREATE_CAMPAIGN_SUCCESS',
  setCreateCampaignFail: 'SET_CREATE_CAMPAIGN_FAIL',
  setPushNotifCampaignTestRequest: 'SET_PUSH_NOTIF_CAMPAIGN_TEST_REQUEST',
  setPushNotifCampaignTestSuccess: 'SET_PUSH_NOTIF_CAMPAIGN_TEST_SUCCESS',
  setPushNotifCampaignTestFail: 'SET_PUSH_NOTIF_CAMPAIGN_TEST_FAIL',
};

const initState = {
  fetching: false,
  segmentListOption: [],
  details: {},
  list: [],
  errorFetch: false,
  errorMessage: null,
};

export default function CampaignReducer(state = initState, action) {
  const { type, payload } = action;
  switch (type) {
    case constants.getCampaignPaginationRequest:
      return { ...state, fetching: true };
    case constants.getCampaignPaginationSuccess:
      return {
        ...state,
        list: payload.data,
        fetching: false,
      };
    case constants.getCampaignPaginationFail:
      return {
        ...state,
        fetching: false,
        errorFetch: true,
        errorMessage: payload,
      };

    case constants.setDeleteCampaignRequest:
      return { ...state, fetching: true };
    case constants.setDeleteCampaignSuccess:
      return {
        ...state,
        fetching: false,
      };
    case constants.setDeleteCampaignFail:
      return {
        ...state,
        fetching: false,
        errorFetch: true,
        errorMessage: payload,
      };

    case constants.getCampaignDetailsRequest:
      return { ...state, fetching: true };
    case constants.getCampaignDetailsSuccess:
      return {
        ...state,
        details: payload.data,
        fetching: false,
      };
    case constants.getCampaignDetailsFail:
      return {
        ...state,
        fetching: false,
        errorFetch: true,
        errorMessage: payload,
      };

    case constants.setUpdateCampaignRequest:
      return { ...state, fetching: true };
    case constants.setUpdateCampaignSuccess:
      return {
        ...state,
        fetching: false,
      };
    case constants.setUpdateCampaignFail:
      return {
        ...state,
        fetching: false,
        errorFetch: true,
        errorMessage: payload,
      };

    case constants.setCreateCampaignRequest:
      return { ...state, fetching: true };
    case constants.setCreateCampaignSuccess:
      return {
        ...state,
        fetching: false,
      };
    case constants.setCreateCampaignFail:
      return {
        ...state,
        fetching: false,
        errorFetch: true,
        errorMessage: payload,
      };

    case constants.setPushNotifCampaignTestRequest:
      return { ...state, fetching: true };
    case constants.setPushNotifCampaignTestSuccess:
      return {
        ...state,
        fetching: false,
      };
    case constants.setPushNotifCampaignTestFail:
      return {
        ...state,
        fetching: false,
        errorFetch: true,
        errorMessage: payload,
      };

    default:
      return state;
  }
}
