/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

// Style
import './SnackBarSimpleStyle.scss';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const SnackBarSimple = props => {
  const { open, onClickClose, snackbarType, message, durationHide, anchor } = props;

  return (
    <Snackbar
      open={open}
      autoHideDuration={durationHide}
      onClose={onClickClose}
      anchorOrigin={anchor}
    >
      <Alert onClose={onClickClose} severity={snackbarType}>
        <label className="text-16">{message}</label>
      </Alert>
    </Snackbar>
  );
};

SnackBarSimple.propTypes = {
  anchor: PropTypes.object,
  durationHide: PropTypes.number,
  message: PropTypes.string,
  onClickClose: PropTypes.func,
  open: PropTypes.bool,
  snackbarType: PropTypes.string,
};

export default SnackBarSimple;
