/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { Steps } from 'antd';
// Style
import './ShippingStatusStyle.scss';
import { Images } from '../../../assets/Index';

const { Step } = Steps;

const ShippingStatus = props => {
  const { selectedStatus } = props;
  let currentStep = null;
  let isActiveIconArrived = false;
  let isActiveIconPackaging = false;
  let isActiveIconDelivery = false;
  switch (selectedStatus) {
    case 3:
      currentStep = 0;
      isActiveIconPackaging = true;
      isActiveIconDelivery = false;
      isActiveIconArrived = false;
      break;
    case 5:
      currentStep = 1;
      isActiveIconPackaging = true;
      isActiveIconDelivery = true;
      isActiveIconArrived = false;
      break;
    case 10 || 11:
      currentStep = 2;
      isActiveIconPackaging = true;
      isActiveIconDelivery = true;
      isActiveIconArrived = true;
      break;
    default:
      break;
  }

  return (
    <Grid container direction="row">
      <Grid item lg={12} md={12}>
        <Grid container direction="row" spacing={5}>
          <Grid item lg={4} md={4} className="flex-center">
            <img
              src={
                isActiveIconPackaging ? Images.ShippingPackagingActive : Images.ShippingPackaging
              }
              alt="shipping-status"
              className="shipping-status-size"
            />
          </Grid>
          <Grid item lg={4} md={4} className="flex-center">
            <img
              src={isActiveIconDelivery ? Images.ShippingDeliveryActive : Images.ShippingDelivery}
              alt="shipping-status"
              className="shipping-status-size"
            />
          </Grid>
          <Grid item lg={4} md={4} className="flex-center">
            <img
              src={isActiveIconArrived ? Images.ShippingArrivedActive : Images.ShippingArrived}
              alt="shipping-status"
              className="shipping-status-size"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg={12} md={12} className="pt-8">
        <Steps current={currentStep} progressDot>
          <Step title="Processing" />
          <Step title="On Shipment" />
          <Step title="Delivered" />
        </Steps>
      </Grid>
    </Grid>
  );
};

ShippingStatus.propTypes = {
  selectedStatus: PropTypes.number,
};

export default ShippingStatus;
