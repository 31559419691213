import React from 'react';
import PropTypes from 'prop-types';
import { Grid, IconButton } from '@material-ui/core';
import { connect } from 'react-redux';
import clsx from 'clsx';
// api
import { getConductReportDetailByTrainerId } from '../../../../../services/api/ReportApi';
// style
import './ModalDetailConductStyle.scss';
// component
import {
  ModalInformationPopUp,
  SnackBarSimple,
  LabelStatusMain,
  GridRowTableOneColumn,
} from '../../../../../components/Index';
import { SkeletonModalDetailConduct } from '../Index';
// helper
import { CommonHelper } from '../../../../../helpers/Index';

const optionToash = {
  vertical: 'top',
  horizontal: 'right',
};

class ModalDetailConduct extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      toastInformation: {
        isOpen: false,
        message: '',
        snackbarType: 'warning',
      },
    };
  }

  componentDidMount() {
    const { trainerId, startDate, endDate, branchId, getConductReportDetail } = this.props;

    if (trainerId) {
      const param = {
        start_date: startDate,
        end_date: endDate,
        trainer_id: trainerId,
        branch_id: branchId,
      };

      getConductReportDetail(param);
    }
  }

  componentWillUnmount() {}

  handleClose = () => {
    const { onClose } = this.props;
    onClose(false);
  };

  handleCloseToash = () => {
    const { toastInformation } = this.state;
    this.setState({ toastInformation: { ...toastInformation, isOpen: false } });
  };

  processMessage(messages, type) {
    const convertedMessage = CommonHelper.generateMessage(messages);

    const paramInformation = {
      isOpen: true,
      message: convertedMessage,
      snackbarType: type,
    };

    this.setState({
      toastInformation: paramInformation,
    });
  }

  renderStatus = value => {
    let renderElement = null;

    if (value) {
      renderElement = <LabelStatusMain value="Projected Value" type="projected" />;
    }

    return <div className="container-status">{renderElement}</div>;
  };

  render() {
    const { toastInformation } = this.state;
    const {
      isOpen,
      reportData: { details, fetching },
      startDate,
      endDate,
    } = this.props;

    let renderElement = <SkeletonModalDetailConduct />;

    if (!fetching && details) {
      const convertedStartDate = CommonHelper.dateTimeParseNewFormat(startDate, 'DD MMM YYYY');
      const convertedEndDate = CommonHelper.dateTimeParseNewFormat(endDate, 'DD MMM YYYY');
      const convertedBaseCommission = CommonHelper.formatCurrency(details.base_commission);
      const convertedCommission = CommonHelper.formatCurrency(details.commission);
      const convertedPenalty = details.penalty
        ? `IDR ${CommonHelper.formatCurrency(details.penalty)}`
        : 'None';
      const conductValue = CommonHelper.formatCurrency(details.conduct_value);

      renderElement = (
        <Grid container direction="column" className="flex-wrap-unset">
          <Grid item lg md className="section-header-modal-conduct">
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
              className="flex-wrap-unset"
            >
              <Grid item lg={10} md={10}>
                <span className="flex-row">
                  <label className="text-18 title-main wrapping-container second line">
                    {details.trainer_name || '-'}
                  </label>
                  {this.renderStatus(details.projected_value)}
                </span>
              </Grid>
              <Grid item>
                <Grid container justify="flex-end">
                  <Grid item>
                    <div className="container-button-close">
                      <IconButton onClick={this.handleClose}>
                        <i className="ic-ffo-close custom-icon-close" />
                      </IconButton>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item className="section-body-modal-conduct">
            <Grid item lg md>
              <span className="flex-row transaction-date">
                <label className="text-12 title">Date</label>
                <label className="text-12">{`${convertedStartDate} - ${convertedEndDate}`}</label>
              </span>
            </Grid>
            <Grid item lg md>
              <Grid
                container
                justify="flex-start"
                className="flex-wrap-unset container-details container-payment"
              >
                <Grid item lg={9} md={9}>
                  <Grid container>
                    <Grid item lg={4} md={4}>
                      <div className="flex-column">
                        <label className="text-12">Base</label>
                        <label className="text-12">IDR {convertedBaseCommission}</label>
                      </div>
                    </Grid>
                    <Grid item lg={4} md={4}>
                      <div className="flex-column">
                        <label className="text-12">Commission</label>
                        <label
                          className={`text-12 ${clsx({
                            'deviation-text': CommonHelper.checkNegativeValue(convertedCommission),
                          })}`}
                        >
                          IDR {convertedCommission}
                        </label>
                      </div>
                    </Grid>
                    <Grid item lg={4} md={4}>
                      <div className="flex-column">
                        <label className="text-12">Penalty</label>
                        <label className="text-12 deviation-text">{convertedPenalty}</label>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item lg={3} md={3}>
                  <Grid container justify="flex-end" alignItems="center">
                    <Grid item lg={10} md={10}>
                      <div className="flex-column">
                        <label className="text-16 main">IDR {conductValue}</label>
                        <label className="text-12">Conduct Value</label>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg md>
              <Grid container direction="column" className="container-row-table flex-wrap-unset">
                <GridRowTableOneColumn
                  customElementInitial={<label className="text-14">Target (Monthly)</label>}
                  customElementColumn={<label className="text-14">{details.target || '-'}</label>}
                />
                <GridRowTableOneColumn
                  customElementInitial={<label className="text-14">Total Appointment</label>}
                  customElementColumn={
                    <label className="text-14">{details.total_appointment}</label>
                  }
                />
                <GridRowTableOneColumn
                  customElementInitial={<label className="text-14">Total Conducted</label>}
                  customElementColumn={<label className="text-14">{details.conduct}</label>}
                />
                <GridRowTableOneColumn
                  customElementInitial={<label className="text-14">Bonus</label>}
                  customElementColumn={<label className="text-14">{details.bonus}</label>}
                />{' '}
                <GridRowTableOneColumn
                  customElementInitial={<label className="text-14">Average</label>}
                  customElementColumn={<label className="text-14">{details.average}</label>}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item className="section-footer-modal-conduct" />
          <SnackBarSimple
            open={toastInformation.isOpen}
            durationHide={2000}
            message={toastInformation.message}
            onClickClose={this.handleCloseToash}
            snackbarType={toastInformation.snackbarType}
            anchor={optionToash}
          />
        </Grid>
      );
    }

    return (
      <ModalInformationPopUp
        isOpen={isOpen}
        onClose={this.handleClose}
        size="medium"
        customElementProps={renderElement}
      />
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getConductReportDetail: params => getConductReportDetailByTrainerId(dispatch, params),
});

const mapStateToProps = ({ reportData }) => ({ reportData });

ModalDetailConduct.propTypes = {
  branchId: PropTypes.number,
  endDate: PropTypes.string,
  getConductReportDetail: PropTypes.func,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  reportData: PropTypes.object,
  startDate: PropTypes.string,
  trainerId: PropTypes.number,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalDetailConduct);
