import React from 'react';
import { connect } from 'react-redux';
import { Grid, FormControl, FormLabel, Fade } from '@material-ui/core';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { compose } from 'redux';
// component
import {
  ButtonMain,
  PrevNextStepperCount,
  GridRowTableOneColumn,
  SelectInputMain,
  RadioInputImage,
  LabelAlert,
  TextInput,
  SnackBarSimple,
} from '../../../../../../components/Index';
import { DetailsAppointment, CartItemBooking } from './components/Index';
// api
import { getBookingAdminFee } from '../../../../../../services/api/ScheduleDataApi';
import { checkPromoCode } from '../../../../../../services/api/OrdersApi';
// style
import './StepBookingSummaryStyle.scss';
// helper
import {
  CommonHelper,
  MasterDataHelper,
  paymentMethodOptionCode,
  promoTypeCode,
  HttpStatusCode,
} from '../../../../../../helpers/Index';

const bankTransferOption = MasterDataHelper.bankTransferOption;

const initialValidation = {
  promoCode: { isError: false, errorMessage: '' },
};

const initialForm = {
  paymentMethod: 'bank_transfer',
  bank: bankTransferOption[0],
  promoCode: '',
  isMembershipSpecial: false,
};

const optionToast = {
  vertical: 'top',
  horizontal: 'right',
};

const timeInterval = 300;

class StepBookingSummary extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      form: CommonHelper.objectCloning(initialForm),
      validation: CommonHelper.objectCloning(initialValidation),
      paymentMethodOption: [],
      isSuccess: '',
      isValidPromoCode: false,
      disablePromoCode: false,
      toastInformation: {
        isOpen: false,
        message: '',
        snackbarType: 'warning',
      },
    };
    this.searchPromoCodeDebounce = _.debounce(this.searchPromoCodeDebounce, 400);
  }

  componentDidMount() {
    const { parentForm } = this.props;
    const { form } = this.state;
    const changeValue = paymentMethodOptionCode.bank_transfer;
    let modifyPaymentMethodOption = [];
    modifyPaymentMethodOption = MasterDataHelper.paymentMethodOption.filter(
      item =>
        item.value === paymentMethodOptionCode.bank_transfer ||
        item.value === paymentMethodOptionCode.credit_card ||
        item.value === paymentMethodOptionCode.fit_point ||
        item.value === paymentMethodOptionCode.edc,
    );
    this.getMedicConsultationInfo();

    this.setState(
      {
        form: {
          ...form,
          ...parentForm,
          ...parentForm.step_booking_summary,
        },
        paymentMethodOption: modifyPaymentMethodOption,
      },
      () => {
        if (!parentForm.paymentMethod) {
          this.handleSelectPaymentMethod(changeValue);
        } else if (!_.isEmpty(parentForm.promoCode)) {
          this.searchPromoCodeDebounce();
        }
      },
    );
  }

  getMedicConsultationInfo = async () => {
    const { bookingAdminFee } = this.props;
    await bookingAdminFee();
  };

  searchPromoCodeDebounce = () => {
    const { checkPromo } = this.props;
    const { validation, form } = this.state;
    const params = this.getPayloadPromoCode();
    const message = CommonHelper.objectCloning(initialValidation.promoCode);

    if (!_.isEmpty(form.promoCode)) {
      checkPromo(params)
        .then(() => {
          message.errorMessage = 'Promo Code Applied';
          this.setState({
            isValidPromoCode: true,
            isSuccess: 'success',
            validation: {
              ...validation,
              promoCode: message,
            },
          });
        })
        .catch(e => {
          if (e.status === HttpStatusCode.NotFound) {
            message.isError = true;
            message.errorMessage = `${e.data.messages}, you will get no benefit if Process this transaction`;

            this.setState({
              validation: {
                ...validation,
                promoCode: message,
              },
              isValidPromoCode: false,
              isSuccess: 'error',
            });
          }
        });
    } else {
      this.setState({
        isValidPromoCode: false,
        isSuccess: '',
        validation: {
          ...validation,
          promoCode: { isError: false, errorMessage: '' },
        },
      });
    }
  };

  getPayloadPromoCode = () => {
    const { form } = this.state;
    const { parentForm } = this.props;
    const userId = form.user_id;
    const items = parentForm.currentItems;

    const payload = {
      data: {
        user_id: userId,
        promo_code: form.promoCode,
        payment_type: form.paymentMethod, // in back payment method reccornize as payment type
        items: !_.isEmpty(items) ? items : null,
      },
    };
    return payload;
  };

  handleSelectPaymentMethod = value => {
    const { form, paymentMethodOption } = this.state;
    let modifyPromoCode = CommonHelper.objectCloning(form.promoCode);
    let modifyDisablePromoCode = false;

    if (value === paymentMethodOptionCode.membership) {
      modifyPromoCode = '';
      modifyDisablePromoCode = true;
    }

    const modifyPaymentMethod = value;
    const modifyPaymentMethodOption = !_.isEmpty(paymentMethodOption)
      ? CommonHelper.objectCloning(paymentMethodOption)
      : [];

    this.setState(
      {
        form: {
          ...form,
          paymentMethod: modifyPaymentMethod,
        },
        disablePromoCode: modifyDisablePromoCode,
        paymentMethodOption: modifyPaymentMethodOption,
      },
      () => {
        if (value === paymentMethodOptionCode.bank_transfer) {
          const valueChange = bankTransferOption[0];
          this.handleChangeBankTransfer(valueChange);
        } else if (value === paymentMethodOptionCode.credit_card) {
          this.handleChangePromoCodeEntry(modifyPromoCode);
        }
      },
    );
  };

  handleChangeBankTransfer = value => {
    const { form } = this.state;

    this.setState({ form: { ...form, bank: value } });
  };

  handleChangePromoCodeEntry = value => {
    const { form } = this.state;
    this.setState(
      {
        form: { ...form, promoCode: value },
      },
      () => {
        this.searchPromoCodeDebounce();
      },
    );
  };

  handlePrevClick = async () => {
    const { onPrevClick, onParameterUpdate } = this.props;
    const { form } = this.state;
    const formNewValue = CommonHelper.objectCloning(form);
    formNewValue.paymentMethod = null;
    formNewValue.promoCode = '';

    await onParameterUpdate(formNewValue, 'step_booking_summary');
    await onPrevClick();
  };

  handleSubmitClick = async () => {
    const { onButtonClickSubmit, onParameterUpdate } = this.props;
    const { form, validation } = this.state;
    const formNewValue = CommonHelper.objectCloning(form);
    await onParameterUpdate(formNewValue, 'step_booking_summary');

    if (!validation.promoCode.isError) {
      await onButtonClickSubmit();
    } else {
      this.processMessage('Promo Code not Valid, Check your Promo Code', 'warning');
    }
  };

  handleCloseToast = () => {
    const { toastInformation } = this.state;
    this.setState({ toastInformation: { ...toastInformation, isOpen: false } });
  };

  processMessage(messages, type) {
    const convertedMessage = CommonHelper.generateMessage(messages);

    const paramInformation = {
      isOpen: true,
      message: convertedMessage,
      snackbarType: type,
    };

    this.setState({
      toastInformation: paramInformation,
    });
  }

  renderPaymentMethodAction() {
    const {
      form: { membershipInfo, paymentMethod, bank },
    } = this.state;
    let renderElement = null;

    if (paymentMethod === paymentMethodOptionCode.bank_transfer) {
      renderElement = (
        <Grid item lg={6} md={6} className="mt-27">
          <FormControl component="fieldset" fullWidth margin="normal">
            <RadioInputImage
              data={bankTransferOption}
              onSelect={this.handleChangeBankTransfer}
              direction="column"
              currentValue={bank}
              imageWidth="60px"
            />
          </FormControl>
        </Grid>
      );
    } else if (paymentMethod === paymentMethodOptionCode.membership) {
      const ifQuotaBase = membershipInfo.membership_details.quota;
      const maxColumn = ifQuotaBase ? 2 : 3;

      if (!_.isEmpty(membershipInfo)) {
        renderElement = (
          <Grid item lg={6} md={6} className="mt-42">
            <Grid
              container
              justify="space-between"
              className="container-membership-information p-24"
            >
              <Grid item lg={8} md={8}>
                <div className="flex-column ">
                  <label className="text-12 text-semi-bold title">Membership</label>
                  <label className="text-12 text-semi-bold wrapping-container second line">
                    {(membershipInfo.membership_details &&
                      membershipInfo.membership_details.name) ||
                      '-'}
                  </label>
                </div>
              </Grid>
              <Grid item lg={maxColumn} md={maxColumn}>
                <div className="flex-column text-center">
                  {ifQuotaBase ? (
                    <React.Fragment>
                      <label className="text-12 text-semi-bold title">Quota</label>
                      <label className="text-12 text-semi-bold">
                        {membershipInfo.membership_details.remaining_quota || '-'}
                      </label>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <label className="text-12 text-semi-bold title">Period Expired</label>
                      <label className="text-12 text-semi-bold">
                        {CommonHelper.dateTimeParseNewFormat(
                          membershipInfo.membership_details.expired_at,
                          'DD MMM YYYY',
                        ) || '-'}
                      </label>
                    </React.Fragment>
                  )}
                </div>
              </Grid>
            </Grid>
          </Grid>
        );
      }
    }

    return renderElement;
  }

  renderCartItem() {
    const { isValidPromoCode, form } = this.state;
    const {
      orderData,
      scheduleSummary: { bookingAdminFee },
      parentForm,
    } = this.props;

    const updatedForm = {
      ...form,
      ...parentForm,
    };

    return (
      <CartItemBooking
        form={updatedForm}
        orderData={orderData}
        isValidPromoCode={isValidPromoCode}
        adminFee={bookingAdminFee}
      />
    );
  }

  render() {
    const { parentForm, orderData } = this.props;
    const {
      form,
      paymentMethodOption,
      isSuccess,
      validation,
      isValidPromoCode,
      disablePromoCode,
      toastInformation,
    } = this.state;

    const gender = !_.isEmpty(parentForm.gender) ? parentForm.gender.name : '-';
    const appointmentDetails = {
      productName: `Consultation with ${(parentForm.doctor && parentForm.doctor.name) || ''}`,
      appointmentDate: parentForm.date,
      appointmentTime: parentForm.time,
      location: parentForm.branch_name,
    };
    const isDisablePromoCode = orderData.fetching || disablePromoCode;
    const successIcon = 'ic-ffi-check-mark';
    const promoDesc = orderData.promoCodeDetails.cashback_amount_desc;
    const potentialPromo =
      orderData.promoCodeDetails.promo_code_cashback_type_id === promoTypeCode.Discount
        ? `Potential Discount${promoDesc}`
        : `Potential Cashback${promoDesc}`;

    return (
      <Grid
        container
        direction="column"
        className="container-step-booking-add-product flex-wrap-unset"
      >
        <Grid item lg={12} md={12}>
          <Grid container spacing={2}>
            <Grid item lg={6} md={6} className="mt-24">
              <FormControl component="fieldset" fullWidth margin="normal">
                <FormLabel component="label" className="text-12 filed-title">
                  Customer Details
                </FormLabel>
                <Grid
                  container
                  direction="column"
                  className="container-row-table details flex-wrap-unset"
                >
                  <GridRowTableOneColumn
                    customElementInitial={<label className="text-14 title">Name</label>}
                    customElementColumn={<label className="text-14">{parentForm.name}</label>}
                    columnLeft={5}
                    columnRight={7}
                  />
                  <GridRowTableOneColumn
                    customElementInitial={<label className="text-14 title">Email</label>}
                    customElementColumn={<label className="text-14">{parentForm.email}</label>}
                    columnLeft={5}
                    columnRight={7}
                  />
                  <GridRowTableOneColumn
                    customElementInitial={<label className="text-14 title">Phone Number</label>}
                    customElementColumn={<label className="text-14">{parentForm.phone}</label>}
                    columnLeft={5}
                    columnRight={7}
                  />
                  <GridRowTableOneColumn
                    customElementInitial={<label className="text-14 title">Gender</label>}
                    customElementColumn={<label className="text-14">{gender}</label>}
                    columnLeft={5}
                    columnRight={7}
                  />
                  <GridRowTableOneColumn
                    customElementInitial={<label className="text-14 title">Order Type</label>}
                    customElementColumn={
                      <label className="text-14 capitalize">{parentForm.orderType}</label>
                    }
                    columnLeft={5}
                    columnRight={7}
                  />
                </Grid>
              </FormControl>
            </Grid>
            <Grid item lg={6} md={6}>
              <DetailsAppointment orderData={appointmentDetails} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={12} md={12}>
          <Grid container spacing={2}>
            <Grid item lg={6} md={6}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <FormLabel component="label" className="text-12 filed-title">
                  Payment Method
                </FormLabel>
                <SelectInputMain
                  options={paymentMethodOption}
                  currentValue={form.paymentMethod || paymentMethodOptionCode.bank_transfer}
                  onChange={this.handleSelectPaymentMethod}
                />
              </FormControl>
            </Grid>
            {this.renderPaymentMethodAction()}
          </Grid>
        </Grid>
        <Grid item lg={12} md={12}>
          <Grid container spacing={2}>
            <Grid item lg={6} md={6}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <FormLabel component="label" className="text-12 filed-title">
                  Promo Code
                </FormLabel>
                <div className={`container-promo ${isSuccess}`}>
                  <TextInput
                    placeHolderText="Promo Code"
                    size="md"
                    onChange={this.handleChangePromoCodeEntry}
                    currentValue={form.promoCode}
                    errorMessage={validation.promoCode.errorMessage}
                    isError={validation.promoCode.isError}
                    iconPrefixEnd={isValidPromoCode ? successIcon : ''}
                    isDisable={isDisablePromoCode}
                  />
                </div>
              </FormControl>
            </Grid>
            <Fade in={isSuccess === 'success'} timeout={timeInterval}>
              <Grid item lg={6} md={6} className="mt-43">
                <LabelAlert message={potentialPromo} type="success" size="middle" />
              </Grid>
            </Fade>
          </Grid>
        </Grid>
        <Grid item lg={12} md={12} className="mv-15">
          <label className="text-14 text-semi-bold text-mine-shaft">
            NB : Every booking fitco medic, Fitco will charge IDR 100.000 for Admin Fee
          </label>
        </Grid>
        <Grid item lg={12} md={12}>
          {!_.isEmpty(parentForm.items) && this.renderCartItem()}
        </Grid>
        <Grid item lg={12} md={12} className="mt-24">
          <Grid container justify="flex-end">
            <Grid item lg={5} md={6}>
              <Grid container justify="flex-end">
                <Grid item lg={4} md={4} className="pl-8">
                  <ButtonMain
                    type="ghost"
                    size="xl"
                    labelText="Back"
                    isLoading={orderData.fetching}
                    onClick={this.handlePrevClick}
                  />
                </Grid>
                <Grid item lg={4} md={4} className="pl-8">
                  <ButtonMain
                    type="primary"
                    size="xl"
                    labelText="Submit"
                    isLoading={orderData.fetching}
                    onClick={this.handleSubmitClick}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <SnackBarSimple
          open={toastInformation.isOpen}
          durationHide={2000}
          message={toastInformation.message}
          onClickClose={this.handleCloseToast}
          snackbarType={toastInformation.snackbarType}
          anchor={optionToast}
        />
      </Grid>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  checkPromo: params => checkPromoCode(dispatch, params),
  bookingAdminFee: () => getBookingAdminFee(dispatch),
});

const mapStateToProps = ({ orderData, scheduleSummary }) => ({ orderData, scheduleSummary });

StepBookingSummary.propTypes = {
  bookingAdminFee: PropTypes.func,
  checkPromo: PropTypes.func,
  onButtonClickSubmit: PropTypes.func,
  onParameterUpdate: PropTypes.func,
  onPrevClick: PropTypes.func,
  orderData: PropTypes.object,
  parentForm: PropTypes.object,
  scheduleSummary: PropTypes.object,
};

const shell = compose(connect(mapStateToProps, mapDispatchToProps));

export default shell(PrevNextStepperCount(StepBookingSummary));
