/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../../../../redux/actions/UsersAction';
import { AppBar, Toolbar, Grid } from '@material-ui/core';
import _ from 'lodash';
// styles
import StylesJs from './HeaderStyle';
import './HeaderStyle.scss';
// component
import { ProfileOption } from './components/Index';
import { SelectInputMerchant, SelectInputSearchMain } from '../../../../components/Index';
// Helper
import { CommonHelper } from '../../../../helpers/Index';

const initialFilter = {
  merchantId: undefined,
};

class Header extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      filter: CommonHelper.objectCloning(initialFilter),
      user: {},
    };
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, nextProps) {
    const { currentUserLogin } = this.props;

    const isEqualUser = _.isEqual(currentUserLogin, nextProps.user);

    if (!isEqualUser) {
      this.setInitialUser(currentUserLogin);
    }
  }

  setInitialUser(value) {
    this.setState({ user: value }, () => {
      const valueChange = value.activeMerchant;
      this.handleSelectMerchant(valueChange);
    });
  }

  handleSelectMerchant = value => {
    const { filter } = this.state;

    this.setState(
      {
        filter: { ...filter, merchantId: value },
      },
      () => {
        this.props.setCurrentActiveMerchant(value);
      },
    );
  };

  handleOnClickLogout = () => {
    const { onClickLogout, resetActiveMerchant } = this.props;
    resetActiveMerchant();
    onClickLogout();
  };

  render() {
    const {
      open,
      classes,
      usersReducer: { activeMerchant },
    } = this.props;
    const { user, filter } = this.state;
    const merchantList = user.userMerchants;
    const merchantListCustom = CommonHelper.renameKeyName(merchantList, 'merchant_id', 'value');

    return (
      <AppBar
        elevation={0}
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar className={`container-toolbar ${open ? '' : 'is-open-drawer'}`}>
          <Grid container className="container-header-profile" justify="space-between">
            {/* <Grid item lg={1} md={1} xs={6} className="title-page">
            <HeaderName routeName={currentRoute} />
          </Grid> */}
            <Grid item lg={9} md={9} xs={6}>
              <Grid container justify="flex-end" alignItems="center">
                {!_.isEmpty(user.userMerchants) && (
                  <Grid item lg={4} md={4} xs>
                    <SelectInputSearchMain
                      options={merchantListCustom}
                      onChange={this.handleSelectMerchant}
                      currentValue={activeMerchant || filter.merchantId}
                      size={'large'}
                      placeholder={'Select Merchant'}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item lg={3} md={3} xs={6}>
              <Grid container justify="flex-end" alignItems="center">
                <Grid item lg={10} md={10} xs={10}>
                  <ProfileOption
                    imagesUrl={user.imageUrl}
                    fullName={user.name}
                    jobName={user.job}
                    innerTitleEmailHeader={user.email}
                    onClick={this.handleOnClickLogout}
                    userID={user.user_id}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    );
  }
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  currentUserLogin: PropTypes.object,
  masterData: PropTypes.object,
  onClickLogout: PropTypes.func,
  open: PropTypes.bool,
  resetActiveMerchant: PropTypes.func,
  setCurrentActiveMerchant: PropTypes.func,
  user: PropTypes.object,
  usersReducer: PropTypes.object,
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(Actions, dispatch);
};

const mapStateToProps = ({ usersReducer, masterData }) => ({
  usersReducer,
  masterData,
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(StylesJs)(Header));
