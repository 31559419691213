import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import _ from 'lodash';
import { Grid } from '@material-ui/core';
import { compose } from 'redux';
// Components
import {
  PrevStateValue,
  AuthenticationAccessPages,
  SelectInputBranch,
  ButtonMain,
  SnackBarSimple,
} from '../../../components/Index';
// Api
import { getGateConfiguration } from '../../../services/api/MiscellaneousApi';
// helper
import { CommonHelper } from '../../../helpers/Index';
// Style
import './OpenGymGateStyle.scss';

const initialFilter = {
  merchantId: null,
  branchId: null,
};

const optionToast = {
  vertical: 'top',
  horizontal: 'right',
};

class OpenGymGate extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: CommonHelper.objectCloning(initialFilter),
      toastInformation: {
        isOpen: false,
        message: '',
        snackbarType: 'warning',
      },
    };
  }

  componentDidMount() {
    const { masterData } = this.props;
    const currentBranch = !_.isEmpty(masterData.branchList)
      ? masterData.branchList[0].branch_id
      : null;

    const initialFilterFinal = {
      ...CommonHelper.objectCloning(initialFilter),
      merchantId: 1,
      branchId: currentBranch,
    };
    this.setState({
      filter: initialFilterFinal,
    });
  }

  handleSelectBranch = value => {
    const { filter } = this.state;
    this.setState({
      filter: { ...filter, branchId: value },
    });
  };

  handleButtonClick = async type => {
    const { gateConfiguration } = this.props;
    const { filter } = this.state;
    try {
      const { messages } = await gateConfiguration(filter.branchId, type);
      this.processMessage(messages[0], 'success');
    } catch (error) {
      this.processMessage(error.messages[0], 'warning');
    }
  };

  handleCloseToast = () => {
    const { toastInformation } = this.state;
    this.setState({ toastInformation: { ...toastInformation, isOpen: false } });
  };

  processMessage(messages, type) {
    const convertedMessage = CommonHelper.generateMessage(messages);

    const paramInformation = {
      isOpen: true,
      message: convertedMessage,
      snackbarType: type,
    };

    this.setState({
      toastInformation: paramInformation,
    });
  }

  renderFilter() {
    const { filter } = this.state;
    return (
      <Grid container className="mv-24">
        <Grid item lg={4} md={4}>
          <div className="container-remove-margin">
            <SelectInputBranch
              placeHolder="Select Branch"
              onChange={this.handleSelectBranch}
              currentValue={filter.branchId}
              filter={filter}
            />
          </div>
        </Grid>
      </Grid>
    );
  }

  render() {
    const {
      miscellaneousData: { fetching },
    } = this.props;
    const { filter, toastInformation } = this.state;
    return (
      <div>
        <Helmet title="FITCO | Eats - Meal Delivery" />
        <div className="container-page-open-gym-gate scroll-container">
          <div className="container-page-scrolling-area">
            <Grid container direction="column">
              <Grid item lg md sm className="section-page-header">
                {this.renderFilter()}
              </Grid>
              <Grid item lg md className="section-page-body">
                <Grid container>
                  <Grid item lg={4} md={4}>
                    <Grid container direction="row" justify="space-between">
                      <Grid item lg={5} md={5} className="container-header-button">
                        <ButtonMain
                          labelText="Out"
                          onClick={() => {
                            this.handleButtonClick('out');
                          }}
                          type="negative"
                          size="xl"
                          disabled={!filter.branchId}
                          isLoading={fetching}
                        />
                      </Grid>
                      <Grid item lg={5} md={5} className="container-header-button">
                        <ButtonMain
                          labelText="In"
                          onClick={() => {
                            this.handleButtonClick('in');
                          }}
                          type="primary"
                          size="xl"
                          disabled={!filter.branchId}
                          isLoading={fetching}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>
        <SnackBarSimple
          open={toastInformation.isOpen}
          durationHide={2000}
          message={toastInformation.message}
          onClickClose={this.handleCloseToast}
          snackbarType={toastInformation.snackbarType}
          anchor={optionToast}
        />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  gateConfiguration: (branchId, command) => getGateConfiguration(dispatch, branchId, command),
});

const mapStateToProps = ({ masterData, miscellaneousData }) => ({
  masterData,
  miscellaneousData,
});

OpenGymGate.propTypes = {
  gateConfiguration: PropTypes.func,
  masterData: PropTypes.object,
  miscellaneousData: PropTypes.object,
};

const shell = compose(connect(mapStateToProps, mapDispatchToProps));
const core = compose(AuthenticationAccessPages, PrevStateValue);

export default shell(core(OpenGymGate));
