/* eslint-disable no-plusplus */
/* eslint-disable no-console */
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Page, View, Document, Text, StyleSheet } from '@react-pdf/renderer';
// Helper
import { CommonHelper } from '../../../../../helpers/Index';
import { StyleJs as StyleJsGlobal } from '../../../../../assets/Index';

const currentDate = CommonHelper.currentDate('YYYY-MM-DD');
const currentTime = CommonHelper.currentDate('hh:mm A');

const styles = StyleSheet.create({
  tableBorder: {
    borderStyle: 'solid',
    borderWidth: 1,
    padding: 6,
    flex: 1,
  },
  tableNoBorder: {
    padding: 6,
    flex: 1,
  },
  textWhite: {
    color: '#FFF',
  },
  tableRowHeader: {
    backgroundColor: '#7c858b',
    color: '#FFF',
  },
});

const CanvasHandoverList = props => {
  const { data } = props;
  console.log('data---', data);
  const dataDetails = !_.isEmpty(data) ? data.details : {};
  const dataList = !_.isEmpty(data) ? data.order_list : [];
  let totalQtyBox = 0;
  let totalQty = 0;
  let totalWeight = 0;

  return (
    <Document>
      <Page size="A4" orientation="landscape">
        <View style={[StyleJsGlobal.flexColumn, { padding: 24, width: 845 }]}>
          <Text style={[StyleJsGlobal.text18, StyleJsGlobal.textCenter]}>Handover List</Text>
          <View style={[StyleJsGlobal.gridRow, StyleJsGlobal.justifyContentSpaceBetween]}>
            <View style={[StyleJsGlobal.gridItem4]}>
              <View style={[StyleJsGlobal.flexColumn]}>
                <Text style={[StyleJsGlobal.text10]}>
                  {`Tanggal: ${CommonHelper.dateTimeParseNewFormat(
                    dataDetails.handover_date,
                    'DD MMM YYYY',
                  )}`}
                </Text>
                <Text style={[StyleJsGlobal.text10]}>Customer: {dataDetails.customer}</Text>
              </View>
            </View>
            <View style={[StyleJsGlobal.gridItem4, { flex: 1 }]}>
              <View style={[StyleJsGlobal.flexColumn]}>
                <Text style={[StyleJsGlobal.text10, StyleJsGlobal.textRight]}>
                  Branch: {dataDetails.branch}
                </Text>
                <Text style={[StyleJsGlobal.text10, StyleJsGlobal.textRight]}>
                  Cust ID: {dataDetails.customer_id}
                </Text>
              </View>
            </View>
          </View>
          <View style={[StyleJsGlobal.gridColumn, StyleJsGlobal.mt32]}>
            <View style={[StyleJsGlobal.gridItem12]}>
              <View style={[StyleJsGlobal.gridRow, styles.tableRowHeader]}>
                <View style={[StyleJsGlobal.gridItem05, styles.tableBorder]}>
                  <Text style={[StyleJsGlobal.text10, StyleJsGlobal.textCenter, styles.textWhite]}>
                    No
                  </Text>
                </View>
                <View style={[StyleJsGlobal.gridItem2, styles.tableBorder]}>
                  <Text style={[StyleJsGlobal.text10, StyleJsGlobal.textCenter, styles.textWhite]}>
                    Ref No
                  </Text>
                </View>
                <View style={[StyleJsGlobal.gridItem2, styles.tableBorder]}>
                  <Text style={[StyleJsGlobal.text10, StyleJsGlobal.textCenter, styles.textWhite]}>
                    Airwaybill
                  </Text>
                </View>
                <View style={[StyleJsGlobal.gridItem08, styles.tableBorder]}>
                  <Text style={[StyleJsGlobal.text10, StyleJsGlobal.textCenter, styles.textWhite]}>
                    Qty Box
                  </Text>
                </View>
                <View style={[StyleJsGlobal.gridItem08, styles.tableBorder]}>
                  <Text style={[StyleJsGlobal.text10, StyleJsGlobal.textCenter, styles.textWhite]}>
                    Qty Order
                  </Text>
                </View>
                <View style={[StyleJsGlobal.gridItem08, styles.tableBorder]}>
                  <Text style={[StyleJsGlobal.text10, StyleJsGlobal.textCenter, styles.textWhite]}>
                    Weight
                  </Text>
                </View>
                <View style={[StyleJsGlobal.gridItem1, styles.tableBorder]}>
                  <Text style={[StyleJsGlobal.text10, StyleJsGlobal.textCenter, styles.textWhite]}>
                    Dest
                  </Text>
                </View>
                <View style={[StyleJsGlobal.gridItem2, styles.tableBorder]}>
                  <Text style={[StyleJsGlobal.text10, StyleJsGlobal.textCenter, styles.textWhite]}>
                    Good Desc
                  </Text>
                </View>
                <View style={[StyleJsGlobal.gridItem2, styles.tableBorder]}>
                  <Text style={[StyleJsGlobal.text10, StyleJsGlobal.textCenter, styles.textWhite]}>
                    Remark
                  </Text>
                </View>
                <View style={[StyleJsGlobal.gridItem07, styles.tableBorder]}>
                  <Text style={[StyleJsGlobal.text10, StyleJsGlobal.textCenter, styles.textWhite]}>
                    Check
                  </Text>
                </View>
              </View>
            </View>
            {dataList.map(item => {
              totalQtyBox += item.qty_box;
              totalQty += item.total_products;
              totalWeight += item.total_weight;

              return (
                <View style={[StyleJsGlobal.gridItem12, { flex: 1 }]} key={item.no}>
                  <View style={[StyleJsGlobal.gridRow]}>
                    <View style={[StyleJsGlobal.gridItem05, styles.tableBorder]}>
                      <Text style={[StyleJsGlobal.text10, StyleJsGlobal.textCenter]}>
                        {item.no}
                      </Text>
                    </View>
                    <View style={[StyleJsGlobal.gridItem2, styles.tableBorder]}>
                      <Text style={[StyleJsGlobal.text8, StyleJsGlobal.textCenter]}>
                        {item.reference_number}
                      </Text>
                    </View>
                    <View style={[StyleJsGlobal.gridItem2, styles.tableBorder]}>
                      <Text style={[StyleJsGlobal.text8, StyleJsGlobal.textCenter]}>
                        {item.airway_bill_number}
                      </Text>
                    </View>
                    <View style={[StyleJsGlobal.gridItem08, styles.tableBorder]}>
                      <Text style={[StyleJsGlobal.text10, StyleJsGlobal.textCenter]}>
                        {item.qty_box}
                      </Text>
                    </View>
                    <View style={[StyleJsGlobal.gridItem08, styles.tableBorder]}>
                      <Text style={[StyleJsGlobal.text10, StyleJsGlobal.textCenter]}>
                        {item.total_products}
                      </Text>
                    </View>
                    <View style={[StyleJsGlobal.gridItem08, styles.tableBorder]}>
                      <Text style={[StyleJsGlobal.text10, StyleJsGlobal.textCenter]}>
                        {item.total_weight.toFixed(2)}
                      </Text>
                    </View>
                    <View style={[StyleJsGlobal.gridItem1, styles.tableBorder]}>
                      <Text style={[StyleJsGlobal.text8, StyleJsGlobal.textCenter]}>
                        {item.destination_code}
                      </Text>
                    </View>
                    <View style={[StyleJsGlobal.gridItem2, styles.tableBorder]}>
                      <Text style={[StyleJsGlobal.text8, StyleJsGlobal.textCenter]}>
                        {item.good_desc}
                      </Text>
                    </View>
                    <View style={[StyleJsGlobal.gridItem2, styles.tableBorder]}>
                      <Text style={[StyleJsGlobal.text8, StyleJsGlobal.textCenter]}>
                        {item.remark}
                      </Text>
                    </View>
                    <View style={[StyleJsGlobal.gridItem07, styles.tableBorder]} />
                  </View>
                </View>
              );
            })}
            <View style={[StyleJsGlobal.gridItem12]}>
              <View style={[StyleJsGlobal.gridRow]}>
                <View style={[StyleJsGlobal.gridItem05, styles.tableNoBorder]} />
                <View style={[StyleJsGlobal.gridItem2, styles.tableNoBorder]}>
                  <Text style={[StyleJsGlobal.text10, StyleJsGlobal.textCenter]}>Total</Text>
                </View>
                <View style={[StyleJsGlobal.gridItem2, styles.tableNoBorder]} />
                <View style={[StyleJsGlobal.gridItem08, styles.tableNoBorder]}>
                  <Text style={[StyleJsGlobal.text10, StyleJsGlobal.textCenter]}>
                    {totalQtyBox}
                  </Text>
                </View>
                <View style={[StyleJsGlobal.gridItem08, styles.tableNoBorder]}>
                  <Text style={[StyleJsGlobal.text10, StyleJsGlobal.textCenter]}>{totalQty}</Text>
                </View>
                <View style={[StyleJsGlobal.gridItem08, styles.tableNoBorder]}>
                  <Text style={[StyleJsGlobal.text10, StyleJsGlobal.textCenter]}>
                    {totalWeight.toFixed(2)}
                  </Text>
                </View>
                <View style={[StyleJsGlobal.gridItem1, styles.tableNoBorder]} />
                <View style={[StyleJsGlobal.gridItem2, styles.tableNoBorder]} />
                <View style={[StyleJsGlobal.gridItem2, styles.tableNoBorder]} />
                <View style={[StyleJsGlobal.gridItem07, styles.tableNoBorder]} />
              </View>
            </View>
          </View>
          <View
            style={[
              StyleJsGlobal.gridRow,
              StyleJsGlobal.mt32,
              StyleJsGlobal.justifyContentSpaceBetween,
            ]}
          >
            <View style={[StyleJsGlobal.gridItem3, { flex: 1 }]}>
              <View style={[StyleJsGlobal.flexColumn]}>
                <Text style={[StyleJsGlobal.text10, StyleJsGlobal.textCenter]}>Customer</Text>
                <Text style={[StyleJsGlobal.text10, StyleJsGlobal.textCenter, StyleJsGlobal.mt32]}>
                  (_______________)
                </Text>
              </View>
            </View>
            <View style={[StyleJsGlobal.gridItem3, { flex: 1 }]}>
              <View style={[StyleJsGlobal.flexColumn]}>
                <Text style={[StyleJsGlobal.text10, StyleJsGlobal.textCenter]}>Courier Pickup</Text>
                <Text style={[StyleJsGlobal.text10, StyleJsGlobal.textCenter, StyleJsGlobal.mt32]}>
                  (_______________)
                </Text>
              </View>
            </View>
          </View>
          <Text style={[StyleJsGlobal.text10, StyleJsGlobal.textCenter]}>
            Print Date {currentDate} {currentTime}
          </Text>
        </View>
      </Page>
    </Document>
  );
};

CanvasHandoverList.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default CanvasHandoverList;
