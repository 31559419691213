import { constants } from '../reducers/MiscellaneousReducer';

export function getGateConfigurationRequest() {
  return { type: constants.getGateConfigurationRequest };
}
export function getGateConfigurationSuccess(response) {
  return { type: constants.getGateConfigurationSuccess, payload: response };
}
export function getGateConfigurationFail(response) {
  return { type: constants.getGateConfigurationFail, payload: response.data };
}
