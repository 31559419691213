const MoveHelper = {
  WorkoutCategory: {
    Classes: 'classes',
    GymAccess: 'gym-access',
    PTatFITCO: 'pt-at-fitco',
    PTatHome: 'pt-at-home',
    PTOnline: 'pt-online',
  },
};

export default MoveHelper;
