/* eslint-disable react/no-access-state-in-setstate */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Table } from 'antd';
import _ from 'lodash';
import { Grid } from '@material-ui/core';
// style
import './RolePageStyle.scss';
// component
import {
  TextInput,
  SnackBarSimple,
  ButtonIconMain,
  ModalAlertConfirmation,
  ModalAlertAction,
  SkeletonMain
} from '../../../../../components/Index';
// api
import {
  getRolePagination,
  setDeleteRole,
  setUpdateRole,
} from '../../../../../services/api/UserManagement';
// helper
import { CommonHelper, PermissionAccess, PermissionModule, PermissionPage } from '../../../../../helpers/Index';

const initialFilter = {
  search: null,
};

const initialSort = {
  sortField: 'name',
  sortOrder: '',
};

const optionToash = {
  vertical: 'top',
  horizontal: 'right',
};

const modalAlertOption = {
  title: 'Confirmation',
  text: 'Are Your Sure want to DELETE this Role',
  icon: 'warning',
  successMessage: 'Deleted Role Success',
};

const modalDetailDataCreate = {
  title: 'Edit Role Name',
};

const maxStringLength = 100;

class RolePage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: CommonHelper.objectCloning(initialFilter),
      page: 1,
      limit: 10,
      sort: CommonHelper.objectCloning(initialSort),
      pagination: {
        showSizeChanger: true,
        showTotal: (total, range) => `Showing ${range[0]}-${range[1]} From ${total}`,
        pageSizeOptions: ['10', '20', '30', '40'],
      },
      isLoading: true,
      isOpenModal: false,
      dataRole: null,
      currentStringLength: 0,
      validation: { isError: false, errorMessage: '' },
      toastInformation: {
        isOpen: false,
        message: '',
        snackbarType: 'warning',
      },
    };
    this.searchDebounce = _.debounce(this.searchDebounce, 400);
  }

  componentDidMount() {
    this.getRolePagination();
  }

  componentDidUpdate(prevProps) {
    const { isLoadingParent } = this.props;

    if (prevProps.isLoadingParent !== isLoadingParent) {
      this.getRolePagination();
    }
  }

  componentWillUnmount() {}

  getRolePagination = () => {
    const { rolePagination } = this.props;
    const {
      limit,
      pagination,
      page,
      filter: { search },
      sort,
    } = this.state;

    const params = {
      search,
      page,
      limit,
      sort: sort.sortField,
      sortOrder: sort.sortOrder,
    };

    rolePagination(params).then(response => {
      this.setState({
        isLoading: false,
        pagination: { ...pagination, pageSize: limit, total: response.total },
      });
    });
  };

  handleChangeSearch = value => {
    const { filter } = this.state;

    this.setState(
      {
        filter: { ...filter, search: value },
        page: 1,
        pagination: { ...this.state.pagination, current: 1 },
      },
      () => {
        this.searchDebounce();
      },
    );
  };

  handleMouseEnter = value => {
    this.setState({ selectedId: value.id });
  };

  handleMouseLeave = () => {
    this.setState({ selectedId: null });
  };

  handleCloseToash = () => {
    const { toastInformation } = this.state;
    this.setState({ toastInformation: { ...toastInformation, isOpen: false } });
  };

  handleButtonEdit = data => {
    this.setState({ isOpenModal: true, dataRole: data });
  };

  handleCloseModalEdit = () => {
    this.setState({ isOpenModal: false });
  };

  handleTextChangeRole = value => {
    const { validation, dataRole } = this.state;

    this.setState({
      dataRole: { ...dataRole, name: value },
      validation: { ...validation, isError: false, errorMessage: '' },
    });
  };

  searchDebounce = () => {
    this.getCampaignPagination();
  };

  handleButtonSaveRole = () => {
    const { filter, sort, page, limit, dataRole, validation } = this.state;
    const { updateRole } = this.props;

    if (!dataRole.name) {
      this.setState({
        validation: { ...validation, isError: true, errorMessage: 'Role name Still Empty' },
      });
    } else {
      const param = {
        data: {
          role_id: dataRole.id,
          name: dataRole.name,
        },
      };

      updateRole(param)
        .then(async response => {
          const message = response.messages;
          await this.getRolePagination({
            page,
            sort: sort.sortField,
            sortOrder: sort.sortOrder,
            search: filter.search,
            limit,
          });
          await this.setState({ dataRole: null, isOpenModal: false }, () => {
            this.processMessage(message, 'success');
          });
        })
        .catch(error => {
          const message = error.data;
          this.processMessage(message.messages, 'error');
        });
    }
  };

  handleButtonDeleted = data => {
    const { deleteRole } = this.props;
    const { filter, sort, page, limit } = this.state;
    if (data) {
      const param = {
        role_id: data.id,
      };

      deleteRole(param)
        .then(async response => {
          const message = response.messages;
          await this.getRolePagination({
            page,
            sort: sort.sortField,
            sortOrder: sort.sortOrder,
            search: filter.search,
            limit,
          });
          await this.processMessage(message, 'success');
        })
        .catch(error => {
          const message = error.data;
          this.processMessage(message.messages, 'error');
        });
    }
  };

  handleTableChange = (pagination, filters, sorter) => {
    const { sort } = this.state;

    this.setState(
      {
        limit: pagination.pageSize,
        pagination: {
          ...this.state.pagination,
          current: pagination.current,
          pageSize: pagination.pageSize,
        },
        sort: {
          ...sort,
          sortField: sorter.field,
          sortOrder: sorter.order === 'ascend' ? 'asc' : 'desc',
        },
        page: pagination.current,
      },
      () => {
        this.getRolePagination();
      },
    );
  };

  searchDebounce = () => {
    this.getRolePagination();
  };

  processMessage(messages, type) {
    const convertedMessage = CommonHelper.generateMessage(messages);

    const paramInformation = {
      isOpen: true,
      message: convertedMessage,
      snackbarType: type,
    };

    this.setState({
      toastInformation: paramInformation,
    });
  }

  renderColumns = () => {
    const { selectedId } = this.state;

    return [
      {
        title: 'Name',
        dataIndex: 'name',
        sorter: true,
        render: (text, row) => {
          const { id, name } = row;

          return (
            <Grid container className="container-row-role mv-8">
              <Grid item lg md>
                <div className="flex-column">
                  <label className="text-14">{name}</label>
                  <label className="text-12">Role ID : {id}</label>
                </div>
              </Grid>
            </Grid>
          );
        },
      },
      {
        title: 'Last Modify',
        width: '15%',
        align: 'center',
        render: (text, row) => {
          const { id, updatedAt, name } = row;
          const modify = CommonHelper.dateTimeParseNewFormat(updatedAt, 'll');
          let renderElement = <label className="text-13">{modify}</label>;

          if (id === selectedId) {
            modalAlertOption.text = `Are Your Sure want to DELETE this Role : ${name}`;

            renderElement = (
              <Grid container justify="center">
                <Grid item>
                  <ButtonIconMain
                    icon="ic-ffo-edit"
                    type="secondary"
                    size="sm"
                    onClick={() => this.handleButtonEdit(row)}
                    requiredPermission={`${PermissionModule.UserManagement}.${PermissionPage.RolesAndPermissions}.${PermissionAccess.Update}`}
                  />
                </Grid>
                <Grid item>
                  <ModalAlertConfirmation
                    icon="ic-ffo-bin"
                    onClick={() => this.handleButtonDeleted(row)}
                    type="negative"
                    size="sm"
                    optionModal={modalAlertOption}
                    isButtonIcon
                    requiredPermission={`${PermissionModule.UserManagement}.${PermissionPage.RolesAndPermissions}.${PermissionAccess.Delete}`}
                  />
                </Grid>
              </Grid>
            );
          }

          return renderElement;
        },
      },
    ];
  };

  renderModalEdit() {
    const { isOpenModal, dataRole } = this.state;

    const currentValueText = dataRole ? dataRole.name : '';

    const elementRender = (
      <ModalAlertAction
        onButtonSubmit={this.handleButtonSaveRole}
        onCloseModal={this.handleCloseModalEdit}
        modalDetail={modalDetailDataCreate}
        buttonSubmitText="Save"
        openModal={isOpenModal}
        customElementProps={
          <Grid container direction="column">
            <Grid item lg md>
              <TextInput
                placeHolderText="Enter Role Name..."
                onChange={this.handleTextChangeRole}
                currentValue={currentValueText}
                errorMessage={this.state.validation.errorMessage}
                isError={this.state.validation.isError}
              />
            </Grid>
            <Grid item lg md>
              <Grid container justify="flex-end">
                <Grid item>
                  <label className="text-10">{`${this.state.currentStringLength} / ${maxStringLength}`}</label>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
      />
    );

    return isOpenModal ? elementRender : null;
  }

  renderFilter() {
    return (
      <Grid container direction="row" justify="flex-start" className="container-filter">
        <Grid item lg={4} md={4} className="row-filter">
          <TextInput
            iconPrefix="ic-ffo-search"
            placeHolderText="Search"
            onChange={this.handleChangeSearch}
            size="md"
          />
        </Grid>
      </Grid>
    );
  }

  render() {
    const { isLoading, pagination, toastInformation } = this.state;
    const {
      userManagement: { list, fetching },
    } = this.props;
    const ListCustom = CommonHelper.renameKeyName(list, 'updated_at', 'updatedAt');

    let renderElement = <SkeletonMain />;

    if (!isLoading) {
      renderElement = (
        <div className="container-page-role scroll-container">
          <div className="container-page-scrolling-area include-tab">
            <Grid container direction="column" className="flex-wrap-unset">
              <Grid item lg md sm className="section-page-header">
                {this.renderFilter()}
              </Grid>
              <Grid item lg md className="section-page-body">
                <Table
                  columns={this.renderColumns()}
                  rowKey={record => record.id}
                  dataSource={ListCustom}
                  pagination={pagination}
                  loading={fetching}
                  // scroll={{ y: '53vh' }}
                  onChange={this.handleTableChange}
                  onRow={record => {
                    return {
                      onMouseEnter: () => {
                        this.handleMouseEnter(record);
                      }, // mouse enter row
                      onMouseLeave: () => {
                        this.handleMouseLeave();
                      },
                    };
                  }}
                />
              </Grid>
            </Grid>
            <SnackBarSimple
              open={toastInformation.isOpen}
              durationHide={2000}
              message={toastInformation.message}
              onClickClose={this.handleCloseToash}
              snackbarType={toastInformation.snackbarType}
              anchor={optionToash}
            />
            {this.renderModalEdit()}
          </div>
        </div>
      );
    }

    return renderElement;
  }
}

const mapDispatchToProps = dispatch => ({
  rolePagination: params => getRolePagination(dispatch, params),
  deleteRole: params => setDeleteRole(dispatch, params),
  updateRole: params => setUpdateRole(dispatch, params),
});

const mapStateToProps = ({ userManagement }) => ({
  userManagement,
});

RolePage.propTypes = {
  deleteRole: PropTypes.func,
  isLoadingParent: PropTypes.bool,
  rolePagination: PropTypes.func,
  updateRole: PropTypes.func,
  userManagement: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(RolePage);
