// import { makeStyles } from '@material-ui/core/styles';

// const StylesJs = makeStyles(theme => ({
//   // const StylesJs = makeStyles({
//   root: {
//     display: 'flex',
//     position: 'relative',
//   },
//   content: {
//     flexGrow: 1,
//     height: 'calc(100vh - 70px)',
//     marginTop: 70,
//     background: theme.palette.whiteLilac,
//   },
// }));

const StylesJs = theme => ({
  root: {
    display: 'flex',
    position: 'relative',
  },
  content: {
    flexGrow: 1,
    height: 'calc(100vh - 70px)',
    marginTop: 70,
    background: theme.palette.whiteLilac,
    overflow: 'hidden',
  },
});

export default StylesJs;
