import { createStore, applyMiddleware, compose } from 'redux';
import rootReducer from './reducers/Index';
// import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';
import logger from 'redux-logger';
import thunk from 'redux-thunk';

export default function configureStore() {
  const store = createStore(rootReducer);

  if (process.env.NODE_ENV !== 'production') {
    // eslint-disable-next-line no-underscore-dangle
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // add support for Redux dev tools

    const middlewares = [
      thunk,
      // reduxImmutableStateInvariant()
    ];

    store.replaceReducer(rootReducer, composeEnhancers(applyMiddleware(...middlewares, logger)));
  }

  return store;
}
