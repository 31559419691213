import React from 'react';
import PropTypes from 'prop-types';
import { Paper, Grid } from '@material-ui/core';
// style
import './HeaderLabStyle.scss';
// component
import { ButtonMain, TabNavigation } from '../../../../../../components/Index';
import {
  PermissionAccess,
  PermissionModule,
  PermissionPage,
} from '../../../../../../helpers/PermissionHelper';

const HeaderLab = props => {
  const { onChangeTab, onClickCreateBook, data, currentTab } = props;
  let dynamicColumn;

  const handleChangeTab = value => {
    onChangeTab(value);
  };

  const handleButtonClickAddBooking = () => {
    onClickCreateBook();
  };

  return (
    <Paper square className="container-tab-header-appointment">
      <Grid container>
        <Grid item lg md>
          <TabNavigation data={data} onChange={handleChangeTab} currentTab={currentTab} />
        </Grid>
        <Grid item lg={dynamicColumn} md={dynamicColumn} className="container-header-button">
          <ButtonMain
            labelText="Add Booking"
            onClick={handleButtonClickAddBooking}
            type="primary"
            size="md"
            startIcon="ic-ffo-add"
            requiredPermission={`${PermissionModule.UserManagement}.${PermissionPage.RolesAndPermissions}.${PermissionAccess.Add}`}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

HeaderLab.propTypes = {
  currentTab: PropTypes.number,
  data: PropTypes.array,
  onChangeTab: PropTypes.func,
  onClickCreateBook: PropTypes.func,
};

export default HeaderLab;
