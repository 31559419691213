/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Table } from 'antd';
import _ from 'lodash';
import { Grid } from '@material-ui/core';
import { compose } from 'redux';
// Component
import {
  SnackBarSimple,
  SelectInputMain,
  PrevStateValue,
  AuthenticationAccessPages,
  TextInput,
  LabelStatusMain,
  SkeletonMain,
  ButtonMain,
} from '../../../components/Index';
import { HeaderMealPlan } from '../components/Index';
// Api
import {
  getPatientListPagination,
  getRoomType,
  getListPatientExport,
  printPatientLabel,
  getRoomNameList,
} from '../../../services/api/EatApi';
// Helpers
import { CommonHelper, MasterDataHelper, roomTypeCode, PermissionModule, PermissionPage, PermissionAccess } from '../../../helpers/Index';
// Style
import './MealPlanPage.scss';

const currentDate = CommonHelper.currentDate('YYYY-MM-DD');
const startDatevalue = CommonHelper.getStartDateMonth(currentDate);
const endDateValue = CommonHelper.getEndDateMonth(currentDate);
const optionPatientStatus = MasterDataHelper.optionPatientStatus;
const mealTimeOption = MasterDataHelper.mealTimeOption;

const initialRoomClassOption = [
  {
    value: null,
    name: 'All Room Type',
  },
];

const initialSort = {
  sortField: 'name',
  sortOrder: 'asc',
};

const optionToast = {
  vertical: 'top',
  horizontal: 'right',
};

const initialFilter = {
  search_term: '',
  room_type_id: null,
  room_name: null,
  room_status: 'checked_in',
  meal_time: [],
  start_date: startDatevalue,
  end_date: endDateValue,
  limit: 10,
};

class MealPlan extends React.Component {
  constructor(props) {
    super(props);
    const {
      usersReducer: { prevStateValue },
    } = props;

    const updateExitingFilter = {
      ...CommonHelper.objectCloning(initialFilter),
      ...prevStateValue,
    };

    this.state = {
      filter: updateExitingFilter,
      roomClassOption: initialRoomClassOption,
      roomNameOption: [],
      patientStatusOption: optionPatientStatus,
      mealTimeOpt: mealTimeOption,
      sort: CommonHelper.objectCloning(initialSort),
      page: 1,
      limit: 10,
      pagination: {
        showSizeChanger: true,
        showTotal: (total, range) => `Showing ${range[0]}-${range[1]} from ${total}`,
        pageSizeOptions: ['10', '20', '30', '40'],
      },
      loading: true,
      isLoading: true,
      toastInformation: {
        isOpen: false,
        message: '',
        snackbarType: 'warning',
      },
      selectedRowPrint: [],
    };
    this.searchDebounce = _.debounce(this.searchDebounce, 400);
  }

  componentDidMount() {
    const {
      onResetPrevStateValue,
      history: { location },
    } = this.props;

    this.getListRoomType();
    this.getPatientListPagination();
    this.getListPatientLabel();
    this.getListRoomName();

    onResetPrevStateValue({ url: location.pathname });
  }

  getPatientListPagination = () => {
    const { listPatientPagination } = this.props;
    const { filter, pagination, limit, page } = this.state;

    const param = {
      search_term: filter.search_term,
      room_type_id: filter.room_type_id,
      room_status: filter.room_status,
      start_date: filter.start_date,
      end_date: filter.end_date,
      limit,
      page,
    };

    this.setState({ loading: true }, () => {
      listPatientPagination(param).then(response => {
        this.setState({
          loading: false,
          isLoading: false,
          pagination: { ...pagination, pageSize: param.limit, total: response.data.total },
        });
      });
    });
  };

  getListRoomType = () => {
    const { listRoomType } = this.props;
    listRoomType().then(response => {
      const obj = response.data.map(item => {
        return { value: item.room_type_id, name: item.room_type };
      });
      this.setState({
        isLoading: false,
        roomClassOption: [...this.state.roomClassOption, ...obj],
      });
    });
  };

  getListRoomName = () => {
    const { listRoomName } = this.props;
    listRoomName().then(response => {
      const roomNameOption = response.data.map(item => {
        return { value: item.value, name: item.display_text };
      });
      this.setState({
        isLoading: false,
        roomNameOption,
      });
    });
  };

  getListPatientLabel = () => {
    const { filter } = this.state;
    const { listPatientLabel } = this.props;
    const params = {
      start_date: filter.start_date,
      end_date: filter.end_date,
      room_name: filter.room_name,
      meal_time: filter.meal_time,
    };
    listPatientLabel(params).then(response => {
      const data = response.data;
      this.setState({ selectedRowPrint: data });
    });
  };

  searchDebounce = () => {
    this.getPatientListPagination();
  };

  handleChangeSearch = value => {
    const { filter } = this.state;

    this.setState(
      {
        filter: { ...filter, search_term: value },
        page: 1,
        pagination: { ...this.state.pagination, current: 1 },
      },
      () => {
        this.searchDebounce();
      },
    );
  };

  handleSelectChangeRoomClass = value => {
    const { filter } = this.state;
    this.setState(
      {
        filter: {
          ...filter,
          room_type_id: value,
        },
        page: 1,
        pagination: { ...this.state.pagination, current: 1 },
      },
      () => {
        this.getPatientListPagination();
      },
    );
  };

  handleSelectChangePatientStatus = value => {
    const { filter } = this.state;
    this.setState(
      {
        filter: {
          ...filter,
          room_status: value,
        },
        page: 1,
        pagination: { ...this.state.pagination, current: 1 },
      },
      () => {
        this.getPatientListPagination();
      },
    );
  };

  onHandleChangeStartDate = value => {
    const { filter } = this.state;
    const { onSetPrevStateValue } = this.props;
    const endDate = CommonHelper.getEndDateMonth(value);

    onSetPrevStateValue({ ...filter, start_date: value, end_date: endDate });

    this.setState(
      {
        filter: { ...filter, start_date: value, end_date: endDate },
        page: 1,
        pagination: { ...this.state.pagination, current: 1 },
      },
      () => {
        this.getListPatientLabel();
        this.getPatientListPagination();
      },
    );
  };

  onHandleChangeEndDate = value => {
    const { filter } = this.state;
    const { onSetPrevStateValue } = this.props;

    onSetPrevStateValue({ ...filter, end_date: value });

    this.setState(
      {
        filter: { ...filter, end_date: value },
        page: 1,
        pagination: { ...this.state.pagination, current: 1 },
      },
      () => {
        this.getListPatientLabel();
        this.getPatientListPagination();
      },
    );
  };

  onHandleButtonDownload = () => {
    const { listPatientExport } = this.props;
    const { filter } = this.state;
    const param = {
      start_date: filter.start_date,
      end_date: filter.end_date,
      room_name: filter.room_name,
      meal_time: filter.meal_time,
    };
    listPatientExport(param);
  };

  onHandleSelectChangeRoomName = value => {
    const { filter } = this.state;
    this.setState(
      {
        filter: {
          ...filter,
          room_name: value,
        },
        page: 1,
        pagination: { ...this.state.pagination, current: 1 },
      },
      () => {
        this.getListPatientLabel();
        this.getPatientListPagination();
      },
    );
  };

  onHandleSelectChangeMealTime = value => {
    const { filter } = this.state;
    this.setState(
      {
        filter: {
          ...filter,
          meal_time: value,
        },
        page: 1,
        pagination: { ...this.state.pagination, current: 1 },
      },
      () => {
        this.getListPatientLabel();
        this.getPatientListPagination();
      },
    );
  };

  onHandleCallbackMessage = params => {
    this.setState({
      toastInformation: params,
    });
  };

  handleCloseToast = () => {
    const { toastInformation } = this.state;
    this.setState({ toastInformation: { ...toastInformation, isOpen: false } });
  };

  handleTableChange = (pagination, sorter) => {
    const { sort } = this.state;

    this.setState(
      {
        limit: pagination.pageSize,
        pagination: {
          ...this.state.pagination,
          current: pagination.current,
          pageSize: pagination.pageSize,
        },
        sort: {
          ...sort,
          sortField: sorter.field || initialSort.sortField,
          sortOrder: sorter.order === 'ascend' || !sorter.order ? 'asc' : 'desc',
        },
        page: pagination.current,
      },
      () => {
        this.getPatientListPagination();
      },
    );
  };

  handleClickRow = value => {
    const { history } = this.props;
    const param = CommonHelper.encryptObject({ userRoomId: value.user_room_id });
    history.push(`/eat/in-patient/patient/details/${param}`);
  };

  renderColumns = () => {
    return [
      {
        title: 'Patient Name',
        dataIndex: 'name',
        sorter: false,
        render: (text, row) => {
          let type = '';
          switch (row.room_type) {
            case roomTypeCode.VVIP:
              type = 'primary';
              break;
            case roomTypeCode.VIP:
              type = 'complete';
              break;
            case roomTypeCode.Kelas1:
              type = 'warning';
              break;
            default:
              type = 'default';
              break;
          }
          return (
            <Grid container key={row.order_no}>
              <Grid item lg={12} md={12}>
                <Grid
                  onClick={() => {
                    this.handleClickRow(row);
                  }}
                  container
                  direction="column"
                  className="container-row-content flex-wrap-unset"
                >
                  <Grid item className="pb-24">
                    <Grid
                      container
                      direction="row"
                      justify="space-between"
                      className="container-text-header"
                    >
                      <Grid item lg={8} md={8} className="left">
                        <Grid container direction="column">
                          <Grid item>
                            <div className="flex-row align-center">
                              <label className="text-14 text-bold">{`${row.title || ''} ${
                                row.name
                              }`}</label>
                              <div className="label-margin-custom">
                                <LabelStatusMain value={row.room_type} type={type} />
                              </div>
                            </div>
                          </Grid>
                          <Grid item>
                            No RM -{' '}
                            <label className="text-11 subtitle">{row.medical_record_number}</label>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item lg md className="container-info-body">
                    <Grid container justify="flex-start">
                      <Grid item lg={12} md={12}>
                        {this.renderRowAdditional(row)}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          );
        },
      },
    ];
  };

  renderRowAdditional = data => {
    const checkInDate = CommonHelper.dateTimeParseNewFormat(
      data.check_in_time,
      'DD MMMM YYYY | HH:mm',
    );
    const checkOutDate = data.check_out_time
      ? CommonHelper.dateTimeParseNewFormat(data.check_out_time, 'DD MMMM YYYY | HH:mm')
      : '-';
    const lastUpdate = CommonHelper.dateTimeParseNewFormat(data.updated_at, 'DD MMMM YYYY | HH:mm');

    return (
      <Grid container justify="space-between">
        <Grid item lg={2} md={2}>
          <div className="flex-column">
            <label className="text-12 title">Check-in Date</label>
            <label className="text-12 mb-10">{checkInDate}</label>
            <label className="text-12 title">Check-out Date</label>
            <label className="text-12">{checkOutDate}</label>
          </div>
        </Grid>
        <Grid item>
          <div className="divider-hr-vertical" />
        </Grid>
        <Grid item lg={4} md={4}>
          <div className="flex-column">
            <label className="text-12 title">Diet Type</label>
            <label className="text-12 mb-10">{data.diet_type_description}</label>
            <label className="text-12 title">Diet Notes</label>
            <label className="text-12">{data.diet_notes || '-'}</label>
          </div>
        </Grid>
        <Grid item>
          <div className="divider-hr-vertical" />
        </Grid>
        <Grid item lg={2} md={2}>
          <div className="flex-column">
            <label className="text-12 title">Room Number</label>
            <label className="text-12 mb-10">{`${data.room_name} - ${data.room_number}`}</label>
            <label className="text-12 title">Doctor Name</label>
            <label className="text-12">{data.doctor || '-'}</label>
          </div>
        </Grid>
        <Grid item>
          <div className="divider-hr-vertical" />
        </Grid>
        <Grid item lg={2} md={2}>
          <div className="flex-column">
            <label className="text-12 title">Last Updated</label>
            <label className="text-12 mb-4">{data.last_updater}</label>
            <label className="text-12">{lastUpdate}</label>
          </div>
        </Grid>
      </Grid>
    );
  };

  handleButtonMealPlan = () => {
    const { history } = this.props;
    history.push('/eat/in-patient/create');
  };

  renderFilter() {
    const { filter, roomClassOption, patientStatusOption } = this.state;

    return (
      <div>
        <div className="flex-column">
          <Grid
            container
            justify="space-between"
            alignItems="center"
            className="container-filter flex-wrap-unset"
          >
            <Grid item lg={8} md={8}>
              <Grid container justify="flex-start" alignItems="center">
                <Grid xl={6} lg={6} md={6} className="row-filter">
                  <TextInput
                    iconPrefix="ic-ffo-search"
                    placeHolderText="Search by Patient Name"
                    onChange={this.handleChangeSearch}
                    currentValue={filter.search_term}
                    size="md"
                  />
                </Grid>
                <Grid item xl={3} lg={3} md={3} className="row-filter">
                  <SelectInputMain
                    options={roomClassOption}
                    currentValue={filter.room_type_id}
                    onChange={this.handleSelectChangeRoomClass}
                  />
                </Grid>
                <Grid item xl={3} lg={3} md={3} className="row-filter">
                  <SelectInputMain
                    options={patientStatusOption}
                    currentValue={filter.room_status}
                    onChange={this.handleSelectChangePatientStatus}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xl={'auto'} lg={'auto'} md={'auto'} justify="flex-end">
              <ButtonMain
                labelText="Add Patient"
                type="primary"
                size="md"
                startIcon="ic-ffo-add"
                onClick={this.handleButtonMealPlan}
                requiredPermission={`${PermissionModule.Eat}.${PermissionPage.InPatient}.${PermissionAccess.Add}`}
              />
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }

  render() {
    const {
      eatData: { patientList, patientListExport, fetchingExport },
    } = this.props;
    const {
      isLoading,
      loading,
      pagination,
      toastInformation,
      filter,
      selectedRowPrint,
      roomNameOption,
      mealTimeOpt,
    } = this.state;

    let renderElement = <SkeletonMain />;

    if (!isLoading) {
      renderElement = (
        <div>
          <Helmet title="FITCO | Eats - In-Patient" />
          <HeaderMealPlan
            startDate={filter.start_date}
            endDate={filter.end_date}
            handleChangeStartDate={this.onHandleChangeStartDate}
            handleChangeEndDate={this.onHandleChangeEndDate}
            handleExport={this.onHandleButtonDownload}
            selectedRowPrint={selectedRowPrint}
            dataSetArray={patientListExport}
            fetchingExport={fetchingExport}
            roomNameOption={roomNameOption}
            currentRoomName={filter.room_name}
            handleSelectChangeRoomName={this.onHandleSelectChangeRoomName}
            mealTimeOption={mealTimeOpt}
            currentMealTime={filter.meal_time}
            handleSelectChangeMealTime={this.onHandleSelectChangeMealTime}
            callbackMessage={this.onHandleCallbackMessage}
          />
          <div className="container-page-meal scroll-container">
            <div className="container-page-scrolling-area include-tab-adjusment">
              <Grid container direction="column">
                <Grid item lg md sm className="section-page-header">
                  {this.renderFilter()}
                </Grid>
                <Grid item lg md className="section-page-body">
                  <Table
                    columns={this.renderColumns()}
                    rowKey={record => record.user_room_id}
                    dataSource={patientList}
                    pagination={pagination}
                    loading={loading}
                    onChange={this.handleTableChange}
                  />
                </Grid>
              </Grid>
            </div>
          </div>
          <SnackBarSimple
            open={toastInformation.isOpen}
            durationHide={2000}
            message={toastInformation.message}
            onClickClose={this.handleCloseToast}
            snackbarType={toastInformation.snackbarType}
            anchor={optionToast}
          />
        </div>
      );
    }
    return renderElement;
  }
}

const mapDispatchToProps = dispatch => ({
  listPatientPagination: params => getPatientListPagination(dispatch, params),
  listPatientExport: params => getListPatientExport(dispatch, params),
  listPatientLabel: params => printPatientLabel(dispatch, params),
  listRoomType: () => getRoomType(dispatch),
  listRoomName: () => getRoomNameList(dispatch),
});

const mapStateToProps = ({ masterDataMain, eatData, usersReducer }) => ({
  masterDataMain,
  eatData,
  usersReducer,
});

MealPlan.propTypes = {
  eatData: PropTypes.object,
  history: PropTypes.object,
  listPatientExport: PropTypes.func,
  listPatientLabel: PropTypes.func,
  listPatientPagination: PropTypes.func,
  listRoomName: PropTypes.func,
  listRoomType: PropTypes.func,
  onResetPrevStateValue: PropTypes.func,
  onSetPrevStateValue: PropTypes.func,
  usersReducer: PropTypes.object,
};

const shell = compose(connect(mapStateToProps, mapDispatchToProps));
const core = compose(AuthenticationAccessPages, PrevStateValue);

export default shell(core(MealPlan));
