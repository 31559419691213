/* eslint-disable react/no-access-state-in-setstate */
import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import _ from 'lodash'; // Component
import { EmptyState, SkeletonMain, AuthenticationAccessPages } from '../../../../components/Index';
// Api
import { getProductWholesale } from '../../../../services/api/MasterDataMainAPi';
// Style
import './ProductWholesalePageStyle.scss';
// Assets
import { Images } from '../../../../assets/Index';
import { CommonHelper } from '../../../../helpers/Index';
import { compose } from 'redux';

class ProductWholesalePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      tableLoading: true,
      page: 1,
      limit: 10,
    };
  }

  componentDidMount() {
    const { getProductWholesaleData } = this;
    getProductWholesaleData();
  }

  getProductWholesaleData = () => {
    const { retrieveProductWholesale } = this.props;
    const { limit, page, pagination } = this.state;
    const payload = {
      page,
      limit,
      sortBy: null,
      order: null,
      pagination: {
        showSizeChanger: true,
        showTotal: (total, range) => `Showing ${range[0]}-${range[1]} from ${total}`,
        pageSizeOptions: ['10', '20', '30', '40'],
      },
    };

    this.setState({ tableLoading: true }, () => {
      retrieveProductWholesale(payload).then(response => {
        this.setState({
          isLoading: false,
          tableLoading: false,
          pagination: {
            ...pagination,
            pageSize: limit,
            total: response.data.total,
          },
        });
      });
    });
  };

  handleTableChange = pagination => {
    this.setState(
      {
        limit: pagination.pageSize,
        pagination: {
          ...this.state.pagination,
          current: pagination.current,
          pageSize: pagination.pageSize,
        },
        page: pagination.current,
      },
      () => {
        this.getProductWholesaleData();
      },
    );
  };

  renderColumns = () => {
    return [
      {
        title: 'Product Name',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Product ID',
        align: 'center',
        dataIndex: 'product_id',
        key: 'product_id',
      },
      {
        title: 'SKU',
        align: 'right',
        dataIndex: 'sku',
        key: 'sku',
      },
      {
        title: 'Price / item',
        align: 'right',
        dataIndex: 'normal_price',
        key: 'normal_price',
        render: text => (
          <label className="text-regular">IDR {CommonHelper.formatCurrency(text)}</label>
        ),
      },
      {
        title: 'Min. Quantity',
        align: 'right',
        dataIndex: 'minimum_quantity',
        key: 'minimum_quantity',
      },
      {
        title: 'Discount',
        align: 'right',
        dataIndex: 'price_discount',
        key: 'price_discount',
        render: text => <label className="text-regular">{`${text}%`}</label>,
      },
    ];
  };

  render() {
    const { isLoading, tableLoading, pagination } = this.state;
    const {
      masterDataMain: { productWholesale },
    } = this.props;
    if (isLoading) {
      return <SkeletonMain />;
    }

    return (
      <div>
        <Helmet title="FITCO | Master Data - Product Wholesale" />
        <div className="container-page-order-summary scroll-container-invisible">
          <div className="container-page-scrolling-area">
            <Grid container direction="column">
              <Grid item lg md className="section-page-header">
                {/* {this.renderFilter()} */}
              </Grid>
              <Grid item lg md className="section-page-body">
                {_.isEmpty(productWholesale.products) ? (
                  <Grid container justify={'center'}>
                    <EmptyState
                      source={Images.emptyStateNoHistory}
                      labelText={'No Product Wholesale Catalog Available'}
                    />
                  </Grid>
                ) : (
                  <Table
                    columns={this.renderColumns()}
                    rowKey={(record, index) => index}
                    dataSource={productWholesale.products}
                    pagination={pagination}
                    loading={tableLoading}
                    onChange={this.handleTableChange}
                  />
                )}
              </Grid>
              <Grid item lg md className="section-page-footer" />
            </Grid>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  retrieveProductWholesale: params => getProductWholesale(dispatch, params),
});

const mapStateToProps = ({ masterDataMain }) => ({ masterDataMain });

ProductWholesalePage.propTypes = {
  masterDataMain: PropTypes.object,
  retrieveProductWholesale: PropTypes.func,
};

const shell = compose(connect(mapStateToProps, mapDispatchToProps));
const core = compose(AuthenticationAccessPages);

export default shell(core(ProductWholesalePage));
