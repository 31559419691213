import React from 'react';
import PropTypes from 'prop-types';
import { Grid, IconButton } from '@material-ui/core';
import { connect } from 'react-redux';
// component
import {
  ModalInformationPopUp,
  ButtonMain,
  SelectInputMain,
  SnackBarSimple,
  ModalAlertInformation,
  SkeletonModalMedia,
} from '../../../../../components/Index';
// Style
import './ModalPopupScreenDetailStyle.scss';
// helper
import { CommonHelper, MasterDataHelper, PermissionAccess, PermissionHelper, PermissionModule, PermissionPage } from '../../../../../helpers/Index';
import {
  getPopupScreenDetails,
  updateStatusPopupScreen,
} from '../../../../../services/api/PopupScreen';

const modalAlertOptionInformation = {
  title: 'Information',
  text: `You can't Perform this Action, Please Cancel all Appointment Thant Already Made, before changing schedule status`,
};

const optionToash = {
  vertical: 'top',
  horizontal: 'right',
};

let actionOptions = MasterDataHelper.statusGlobal;

const hasDeletePermission = PermissionHelper.checkUserHasPermission(PermissionModule.Marketing, PermissionPage.PopUpScreen, PermissionAccess.Delete);
if(!hasDeletePermission) {
  actionOptions = MasterDataHelper.statusGlobal.filter(x => x.value === 10);
}

class ModalPopupScreenDetail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      openModal: false,
      isOpenModalInformation: false,
      isLoading: false,
      toastInformation: {
        isOpen: false,
        message: '',
        snackbarType: 'warning',
      },
    };
  }

  componentDidMount() {
    const { isOpen, popupScreenId, getPopupDetails } = this.props;

    this.setState({ openModal: isOpen, isLoading: true });
    if (popupScreenId) {
      getPopupDetails(popupScreenId).then(() => {
        this.setState({ isLoading: false });
      });
    }
  }

  handleClose = () => {
    const { onClose } = this.props;
    onClose(false);
    this.setState({ openModal: false });
  };

  handleCloseModalInformation = () => {
    this.setState({ isOpenModalInformation: false });
  };

  handleCloseToash = () => {
    const { toastInformation } = this.state;
    this.setState({ toastInformation: { ...toastInformation, isOpen: false } });
  };

  handleClickEdit = () => {
    const { history, popupScreenId } = this.props;
    history.push(`/marketing/popup-screen/edit/${popupScreenId}`);
  };

  handleChangeAction = value => {
    const { updateStatusPopup, popupScreenId, onReload } = this.props;
    const params = {
      data: {
        id: popupScreenId,
        status: value,
      },
    };

    updateStatusPopup(params)
      .then(() => {
        const {
          popupScreenData: { responseMessage },
        } = this.props;

        this.setState(
          {
            isOpenModalInformation: false,
          },
          async () => {
            await this.processMessage(responseMessage, 'success');
            onReload();
          },
        );
      })
      .catch(error => {
        const message = error.data;
        this.processMessage(message.messages, 'error');
      });
  };

  processMessage(messages, type) {
    const convertedMessage = CommonHelper.generateMessage(messages);

    const paramInformation = {
      isOpen: true,
      message: convertedMessage,
      snackbarType: type,
    };

    this.setState({
      toastInformation: paramInformation,
    });
  }

  render() {
    const { openModal, isLoading, toastInformation, isOpenModalInformation } = this.state;

    const {
      popupScreenData: { popupScreenDetails },
    } = this.props;
    const actionSelected = popupScreenDetails.status;
    let renderElement = <SkeletonModalMedia />;

    if (!isLoading) {
      renderElement = (
        <Grid
          container
          direction="column"
          className="container-modal-popup-screen-details flex-wrap-unset"
        >
          <Grid item xs={12} className="image-header-container">
            <img
              src={popupScreenDetails.image}
              alt="placeholder-state"
              className="placeholder-image-size"
            />
            <div className="button-item-container-close">
              <IconButton onClick={this.handleClose}>
                <i className="icon ic-ffo-close custom-icon-close" />
              </IconButton>
            </div>
          </Grid>
          <Grid item className="section-body-modal">
            <Grid container spacing={3}>
              <Grid item lg={12} md={12} style={{ paddingBottom: 0 }}>
                <div className="wrapping-container second line wrapping-container-break">
                  <label className="text-21 title">{popupScreenDetails.title}</label>
                </div>
              </Grid>
              <Grid item xs={10}>
                <div className="flex-column">
                  <label className="text-12 text-rolling-stone">
                    {`Created by ${popupScreenDetails.created_by} `} &bull;
                    {` ${CommonHelper.dateTimeParseNewFormat(
                      popupScreenDetails.created_at,
                      'DD MMMM YYYY, h:MM A',
                    )}`}
                    <br />
                    {`Last modified by ${popupScreenDetails.updated_by} `} &bull;
                    {` ${CommonHelper.dateTimeParseNewFormat(
                      popupScreenDetails.updated_at,
                      'DD MMMM YYYY, h:MM A',
                    )}`}
                  </label>
                </div>
              </Grid>
              <Grid item xs={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <ButtonMain
                  labelText={'Edit'}
                  type={'primary'}
                  size={'sm'}
                  onClick={this.handleClickEdit}
                  requiredPermission={`${PermissionModule.Marketing}.${PermissionPage.PopUpScreen}.${PermissionAccess.Update}`}
                />
              </Grid>
            </Grid>
            <Grid container className="pt-24" alignItems="center" justify="space-between">
              <Grid item lg={4} md={4}>
                <div className="flex-row">
                  <label className="text-12 text-rolling-stone mr-8">ID</label>
                  <label className="text-13 text-bold">{popupScreenDetails.id}</label>
                </div>
              </Grid>
              <Grid item lg={6} md={6}>
                <Grid container justify="flex-end">
                  <Grid item>
                    <div className="flex-row">
                      <label className="text-12 text-rolling-stone mr-8">Set status as</label>
                      <SelectInputMain
                        options={actionOptions}
                        size="small"
                        defaultValue={actionSelected}
                        onChange={this.handleChangeAction}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <SnackBarSimple
            open={toastInformation.isOpen}
            durationHide={2000}
            message={toastInformation.message}
            onClickClose={this.handleCloseToash}
            snackbarType={toastInformation.snackbarType}
            anchor={optionToash}
          />
          <ModalAlertInformation
            optionModal={modalAlertOptionInformation}
            isOpen={isOpenModalInformation}
            onCloseModal={this.handleCloseModalInformation}
            typeModal={'information'}
          />
        </Grid>
      );
    }

    return (
      <ModalInformationPopUp
        isOpen={openModal}
        onClose={this.handleClose}
        customElementProps={renderElement}
      />
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getPopupDetails: popupScreenId => getPopupScreenDetails(dispatch, popupScreenId),
  updateStatusPopup: params => updateStatusPopupScreen(dispatch, params),
});

const mapStateToProps = ({ popupScreenData }) => ({
  popupScreenData,
});

ModalPopupScreenDetail.propTypes = {
  getPopupDetails: PropTypes.func,
  history: PropTypes.object,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onReload: PropTypes.func,
  popupScreenData: PropTypes.object,
  popupScreenId: PropTypes.number,
  updateStatusPopup: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalPopupScreenDetail);
