import { constants } from '../reducers/CampaignReducer';

export function getCampaignPaginationRequest() {
  return { type: constants.getCampaignPaginationRequest };
}
export function getCampaignPaginationSuccess(response) {
  return { type: constants.getCampaignPaginationSuccess, payload: response };
}
export function getCampaignPaginationFail(response) {
  return { type: constants.getCampaignPaginationFail, payload: response.data };
}

export function setDeleteCampaignRequest() {
  return { type: constants.setDeleteCampaignRequest };
}
export function setDeleteCampaignSuccess(response) {
  return { type: constants.setDeleteCampaignSuccess, payload: response };
}
export function setDeleteCampaignFail(response) {
  return { type: constants.setDeleteCampaignFail, payload: response.data };
}

export function getCampaignDetailsRequest() {
  return { type: constants.getCampaignDetailsRequest };
}
export function getCampaignDetailsSuccess(response) {
  return { type: constants.getCampaignDetailsSuccess, payload: response };
}
export function getCampaignDetailsFail(response) {
  return { type: constants.getCampaignDetailsFail, payload: response.data };
}

export function setUpdateCampaignRequest() {
  return { type: constants.setUpdateCampaignRequest };
}
export function setUpdateCampaignSuccess(response) {
  return { type: constants.setUpdateCampaignSuccess, payload: response };
}
export function setUpdateCampaignFail(response) {
  return { type: constants.setUpdateCampaignFail, payload: response.data };
}

export function setCreateCampaignRequest() {
  return { type: constants.setCreateCampaignRequest };
}
export function setCreateCampaignSuccess(response) {
  return { type: constants.setCreateCampaignSuccess, payload: response };
}
export function setCreateCampaignFail(response) {
  return { type: constants.setCreateCampaignFail, payload: response.data };
}

export function setPushNotifCampaignTestRequest() {
  return { type: constants.setPushNotifCampaignTestRequest };
}
export function setPushNotifCampaignTestSuccess(response) {
  return { type: constants.setPushNotifCampaignTestSuccess, payload: response };
}
export function setPushNotifCampaignTestFail(response) {
  return { type: constants.setPushNotifCampaignTestFail, payload: response.data };
}
