import React from 'react';
import PropTypes from 'prop-types';
import { Grid, IconButton } from '@material-ui/core';
import { connect } from 'react-redux';
import { QRCode } from 'react-qrcode-logo';
import clsx from 'clsx';
// style
import './ModalEatMenuQrCodeStyle.scss';
// component
import { ModalInformationPopUp, GeneratorHtmlToPdf } from '../../../../components/Index';
// helper
import { CommonHelper, currencyCode, GeneratorHelper } from '../../../../helpers/Index';
// assets
import { Images } from '../../../../assets/Index';

const qrOption = {
  logoImage: Images.fitcoLogoRounded,
  logoWidth: 50,
  logoHeight: 50,
  size: 360,
  ecLevel: 'Q',
};

class ModalEatMenuQrCode extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  componentWillUnmount() {}

  renderQrCode = singleItem => {
    const { data } = this.props;

    const maxRow = !singleItem ? 4 : 10;

    return (
      <Grid
        container
        spacing={4}
        className={`p-16 container-qr-code-parent ${clsx({
          'scroll-container': !singleItem,
        })} mv-15`}
        justify="center"
      >
        {data.map((item, index) => {
          const wordingPrice = `${currencyCode.idr} ${CommonHelper.formatCurrency(item.price)}`;

          return (
            <Grid
              key={index}
              id={item.id}
              item
              lg={maxRow}
              md={maxRow}
              className="container-qr-code-chile"
            >
              <div className="flex-column text-center">
                <div className="container-qr-code">
                  <QRCode
                    value={item.qrCode}
                    logoImage={qrOption.logoImage}
                    logoWidth={qrOption.logoWidth}
                    logoHeight={qrOption.logoHeight}
                    size={qrOption.size}
                    ecLevel={qrOption.ecLevel}
                  />
                </div>
                <label className="text-14 text-bold mb-4 mt-8 wrapping-container wrapping-container-break">
                  {item.productName}
                </label>
                <label className="text-14 text-bold">{wordingPrice}</label>
              </div>
            </Grid>
          );
        })}
      </Grid>
    );
  };

  render() {
    const { onClose, isOpen, data } = this.props;
    const singleItem = data.length === 1;
    const modifyModalSize = singleItem ? 'small' : 'medium';

    const renderElement = (
      <Grid
        container
        direction="column"
        className="flex-wrap-unset container-modal-eat-menu-qr-code"
      >
        <Grid item lg md className="section-header-modal">
          <Grid container justify="space-between">
            <Grid item lg={11} md={11}>
              <h5 className="wrapping-container second line">QR Code</h5>
            </Grid>
            <Grid item>
              <div className="container-button-close">
                <IconButton onClick={onClose}>
                  <i className="ic-ffo-close container-icon-prefix size-21" />
                </IconButton>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg md className="section-body-modal">
          {this.renderQrCode(singleItem)}
        </Grid>
        <Grid item className="section-footer-modal">
          <Grid container justify="space-around">
            <Grid item lg={3} md={3}>
              <GeneratorHtmlToPdf
                data={data}
                buttonType="ghost"
                buttonLabel="Download"
                size="xl"
                mode="download"
                fileName="menu_eats"
                customLabel="canvas-portrait-three-row"
                functionRetrieveData={GeneratorHelper.MultipleIdHtmlToCanvas}
              />
            </Grid>
            <Grid item lg={3} md={3}>
              <GeneratorHtmlToPdf
                data={data}
                buttonType="primary"
                buttonLabel="Print"
                size="xl"
                customLabel="canvas-portrait-three-row"
                functionRetrieveData={GeneratorHelper.MultipleIdHtmlToCanvas}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );

    return (
      <ModalInformationPopUp
        isOpen={isOpen}
        onClose={onClose}
        customElementProps={renderElement}
        size={modifyModalSize}
      />
    );
  }
}

const mapDispatchToProps = () => ({});

const mapStateToProps = () => ({});

ModalEatMenuQrCode.propTypes = {
  data: PropTypes.array,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalEatMenuQrCode);
