import React from 'react';
import PropTypes from 'prop-types';
import { Grid, FormControl, Checkbox } from '@material-ui/core';
import _ from 'lodash';
// Style
import './CartItemDetailsEatsStyle.scss';
// Component
import {
  GridRowTableFiveColumn,
  GridRowTableOneColumn,
  TextInputNumber,
  ButtonMain,
} from '../../../../../components/Index';
// helper
import { CommonHelper, promoTypeCode } from '../../../../../helpers/Index';
// assets
import { Images } from '../../../../../assets/Index';
import { paymentMethodOptionCode, orderStatusCode } from '../../../../../helpers/MasterDataHelper';

const CartItemDetailsEatsAtStore = props => {
  const {
    orderData,
    form: { promoCode, paymentMethod },
    promoCodeDetails,
    isAllChecked,
    isDraft,
    isValidPromoCode,
    onHandleSelectData,
    onHandleSelectAllData,
    onHandleOpenModalAddFood,
    onHandleAddQuantity,
    orderTaxPercentage,
    orderServiceChargePercentage,
  } = props;

  const orderDetailsData = !_.isEmpty(orderData) ? orderData.order_details : {};
  const customerDetailsData = !_.isEmpty(orderData.customer_details)
    ? orderData.customer_details
    : {};
  const orderSummaryData = !_.isEmpty(orderData.order_summary) ? orderData.order_summary : {};
  const orderPaymentDetails = !_.isEmpty(orderData.payment_details)
    ? orderData.payment_details
    : {};
  const orderItems = orderSummaryData.product_details || [];
  const itemsForPrinting =
    orderData.items_for_printing && !_.isEmpty(orderData.items_for_printing)
      ? orderData.items_for_printing
      : [];

  const isGuest = customerDetailsData.customer_email === 'guest@fitco.id';

  // discount
  const promoCodeInformation =
    orderSummaryData.price_discount > 0 && _.isEmpty(promoCodeDetails)
      ? `Promo - ${orderSummaryData.promo_code}`
      : `Promo - ${promoCode}`;
  const discountAmount =
    orderSummaryData.price_discount > 0 && _.isEmpty(promoCodeDetails)
      ? orderSummaryData.price_discount
      : promoCodeDetails.cashback_amount;

  const showDiscount =
    (isValidPromoCode && promoCodeDetails.promo_code_cashback_type_id === promoTypeCode.Discount) ||
    orderSummaryData.price_discount > 0;

  const renderSpecialTreatment = () => {
    let passRenderElement = false;

    let wording = null;
    let type = null;
    let icon = null;

    if (
      !_.isEmpty(orderPaymentDetails) &&
      orderPaymentDetails.payment_method === paymentMethodOptionCode.membership
    ) {
      icon = Images.membershipUsage;
      wording = 'Membership Package Applied';
      type = 'membership';
      passRenderElement = true;
    }

    return passRenderElement ? (
      <span className={`container-special-label flex-row p-8 ${type}`}>
        <img src={icon} alt="membership_logo" />
        <label className="text-14 text-semi-bold ml-8">{wording}</label>
      </span>
    ) : null;
  };

  const handleSelectPrintCaptainOrder = e => {
    const productId = e.target.value;
    onHandleSelectData(productId);
  };

  const handleSelectAllItem = e => {
    const isChecked = e.target.checked;
    onHandleSelectAllData(isChecked);
  };

  const handleAddFood = () => {
    onHandleOpenModalAddFood();
  };

  const getItemPrice = item => {
    const isDraftOrder = orderDetailsData.order_status === orderStatusCode.Draft;
    if (!isDraftOrder) {
      return item.item_price;
    }

    switch (paymentMethod) {
      case paymentMethodOptionCode.grabfood:
        return item.sales_order_item_id ? item.price_data.grabfood_price : item.grabfood_price;
      case paymentMethodOptionCode.gofood:
        return item.sales_order_item_id ? item.price_data.gofood_price : item.gofood_price;
      case paymentMethodOptionCode.shopeefood:
        return item.sales_order_item_id ? item.price_data.shopeefood_price : item.shopeefood_price;
      default:
        if (item.sales_order_item_id) {
          return isGuest || _.isEmpty(item.price_data.special_price)
            ? item.price_data.normal_price
            : item.price_data.special_price;
        }
        return isGuest || _.isEmpty(item.special_price) ? item.normal_price : item.special_price;
    }
  };

  const getGrandTotal = () => {
    const isDraftOrder = orderDetailsData.order_status === orderStatusCode.Draft;
    if (!isDraftOrder) {
      return orderSummaryData.grand_total;
    }

    let grandTotal = 0;
    orderItems.forEach(item => {
      const itemPrice = getItemPrice(item);
      const itemSubtotal = itemPrice * item.quantity;

      grandTotal += itemSubtotal;
    });

    return grandTotal;
  };

  let orderGrandTotal = getGrandTotal();

  if (showDiscount) {
    orderGrandTotal -= discountAmount;
  }

  let taxAmount = parseFloat(orderSummaryData.tax);
  let serviceChargeAmount = parseFloat(orderSummaryData.service_charge);

  const isOnDemandDelivery =
    orderPaymentDetails.payment_method === paymentMethodOptionCode.gofood ||
    orderPaymentDetails.payment_method === paymentMethodOptionCode.grabfood ||
    orderPaymentDetails.payment_method === paymentMethodOptionCode.shopeefood;

  if (isDraft) {
    taxAmount = isOnDemandDelivery ? 0 : orderGrandTotal * orderTaxPercentage;
    serviceChargeAmount = isOnDemandDelivery
      ? 0
      : (orderGrandTotal + taxAmount) * orderServiceChargePercentage;

    orderGrandTotal += taxAmount + serviceChargeAmount;
  }

  return (
    <FormControl
      component="fieldset"
      fullWidth
      margin="normal"
      className="container-order-cart-item-details-eats"
    >
      <div className="flex-column">
        {renderSpecialTreatment()}
        <GridRowTableFiveColumn
          customElementColumnFirst={
            <div>
              <Checkbox
                color="primary"
                value={isAllChecked}
                checked={isAllChecked}
                onChange={handleSelectAllItem}
              />
            </div>
          }
          customElementColumnSecond={<label className="text-14">Product</label>}
          customElementColumnThird={
            <div className="text-center">
              <label className="text-14">Quantity</label>
            </div>
          }
          customElementColumnFourth={
            <div className="text-center">
              <label className="text-14">Served</label>
            </div>
          }
          customElementColumnFifth={
            <div className="text-right">
              <label className="text-14">Subtotal</label>
            </div>
          }
          columnFirst={1}
          columnSecond={5}
          columnThird={1}
          columnFourth={2}
          columnFifth={3}
        />
        <div className="scroll-container">
          <Grid container direction="column" className="flex-wrap-unset">
            {orderItems.map((item, index) => {
              const itemPrice = getItemPrice(item);
              const itemSubtotal = itemPrice * item.quantity;

              const isItemSelected = itemsForPrinting.some(value => {
                if (!item.sales_order_item_id) {
                  return value.product_id === item.product_id;
                }
                return value.sales_order_item_id === item.sales_order_item_id;
              });

              return (
                <GridRowTableFiveColumn
                  key={index}
                  customElementColumnFirst={
                    <div>
                      <Checkbox
                        color="primary"
                        value={item.sales_order_item_id}
                        checked={isItemSelected}
                        onChange={handleSelectPrintCaptainOrder}
                      />
                    </div>
                  }
                  customElementColumnSecond={
                    <div className="flex-column">
                      <label className="text-14 row-item-title wrapping-container mb-8">
                        {item.product_name}
                      </label>
                      {item.details && !_.isEmpty(item.details.notes) && (
                        <label className="text-12 text-semi-bold">{`Meal notes: ${item.details.notes}`}</label>
                      )}
                    </div>
                  }
                  customElementColumnThird={
                    <div className="text-center">
                      {isDraft ? (
                        <TextInputNumber
                          placeHolderText={'Qty'}
                          currentValue={item.quantity}
                          onChange={onHandleAddQuantity(
                            item.sales_order_item_id ? item.sales_order_item_id : item.product_id,
                          )}
                        />
                      ) : (
                        <label className="text-14 text-semi-bold">{item.quantity}</label>
                      )}
                    </div>
                  }
                  customElementColumnFourth={
                    <div className="text-center">
                      <label className="text-14 text-semi-bold">{`${(item.details &&
                        item.details.served) ||
                        0}/${item.quantity}`}</label>
                    </div>
                  }
                  customElementColumnFifth={
                    <div className="text-right">
                      <label className="text-14 text-semi-bold">
                        {itemSubtotal > 0
                          ? `IDR ${CommonHelper.formatCurrency(itemSubtotal)}`
                          : `Free`}
                      </label>
                    </div>
                  }
                  columnFirst={1}
                  columnSecond={5}
                  columnThird={1}
                  columnFourth={2}
                  columnFifth={3}
                />
              );
            })}
          </Grid>
        </div>
        {isDraft && (
          <Grid item lg={12} md={12} className="mb-16 mt-16">
            <ButtonMain
              type="light"
              size="md"
              labelText="Add Food"
              startIcon="ic-ffo-add"
              onClick={handleAddFood}
            />
          </Grid>
        )}
        {/* <GridRowTableOneColumn
          customElementInitial={
            <div className="flex-column">
              <label className="text-14 row-item-title">Shipping Fee</label>
              <label className="text-10">
                {orderSummaryData.shipping_title || 'Not Applicable'}
              </label>
            </div>
          }
          customElementColumn={
            <div className="text-right">
              <label className="text-14 text-semi-bold">
                {orderSummaryData.shipping_fee > 0
                  ? `IDR ${CommonHelper.formatCurrency(orderSummaryData.shipping_fee)}`
                  : `Free`}
              </label>
            </div>
          }
          columnLeft={10}
          columnRight={2}
        /> */}
        {showDiscount && (
          <GridRowTableOneColumn
            customElementInitial={
              <div className="flex-column">
                <label className="text-14 row-item-title">Discount</label>
                <label className="text-10 ">{promoCodeInformation}</label>
              </div>
            }
            customElementColumn={
              <div className="text-right">
                <label className="text-14 discount">{`IDR ${CommonHelper.formatCurrency(
                  discountAmount,
                )}`}</label>
              </div>
            }
            columnLeft={10}
            columnRight={2}
          />
        )}
        {taxAmount > 0 && (
          <GridRowTableOneColumn
            customElementInitial={
              <div className="flex-column">
                <label className="text-14 row-item-title">Tax</label>
                <label className="text-10 ">
                  {orderTaxPercentage > 0 ? `${orderTaxPercentage * 100}%` : ``}
                </label>
              </div>
            }
            customElementColumn={
              <div className="text-right">
                <label className="text-14">{`IDR ${CommonHelper.formatCurrency(taxAmount)}`}</label>
              </div>
            }
            columnLeft={10}
            columnRight={2}
          />
        )}
        {serviceChargeAmount > 0 && (
          <GridRowTableOneColumn
            customElementInitial={
              <div className="flex-column">
                <label className="text-14 row-item-title">Service Charge</label>
                <label className="text-10 ">
                  {orderServiceChargePercentage > 0 ? `${orderServiceChargePercentage * 100}%` : ``}
                </label>
              </div>
            }
            customElementColumn={
              <div className="text-right">
                <label className="text-14">{`IDR ${CommonHelper.formatCurrency(
                  serviceChargeAmount,
                )}`}</label>
              </div>
            }
            columnLeft={10}
            columnRight={2}
          />
        )}
        {orderPaymentDetails.promo_code && orderPaymentDetails.is_cashback ? (
          <GridRowTableOneColumn
            customElementInitial={
              <div className="flex-column">
                <label className="text-14 row-item-title">Cashback</label>
                <label className="text-10 ">Promo - {orderSummaryData.promo_code}</label>
              </div>
            }
            customElementColumn={
              <div className="text-right">
                <label className="text-14">
                  {CommonHelper.formatCurrency(orderPaymentDetails.cashback_amount)} FIT Points
                </label>
              </div>
            }
            columnLeft={9}
            columnRight={3}
          />
        ) : null}
        <GridRowTableOneColumn
          customElementInitial={
            <Grid container>
              <Grid item lg={5} md={5}>
                <label className="text-16 row-item-title">
                  Total Item(s) : {orderSummaryData.total_items}
                </label>
              </Grid>
            </Grid>
          }
          customElementColumn={
            <div className="text-right">
              <label className="text-16 row-item-title">
                Grand Total &nbsp; &nbsp; &nbsp;{' '}
                {orderGrandTotal > 0
                  ? `IDR ${CommonHelper.formatCurrency(orderGrandTotal)}`
                  : `Free`}
              </label>
            </div>
          }
          columnLeft={7}
          columnRight={5}
        />
      </div>
    </FormControl>
  );
};

CartItemDetailsEatsAtStore.propTypes = {
  form: PropTypes.object,
  isAllChecked: PropTypes.bool,
  isDraft: PropTypes.bool,
  isValidPromoCode: PropTypes.bool,
  onHandleAddQuantity: PropTypes.func,
  onHandleOpenModalAddFood: PropTypes.func,
  onHandleSelectAllData: PropTypes.func,
  onHandleSelectData: PropTypes.func,
  orderData: PropTypes.object,
  orderServiceChargePercentage: PropTypes.number,
  orderTaxPercentage: PropTypes.number,
  promoCodeDetails: PropTypes.object,
};

export default CartItemDetailsEatsAtStore;
