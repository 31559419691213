/* eslint-disable react/no-unused-state */
import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Grid, Breadcrumbs, Link } from '@material-ui/core';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { compose } from 'redux';
// Components
import {
  LabelStatusPayment,
  ButtonMain,
  GridRowTableOneColumn,
  AuthenticationAccessPages,
  GridRowTableFourColumn,
} from '../../../../../components/Index';
// import { CartItemDetailRetails } from './components/Index';
// Style
import './MembershipPurchaseDetailsStyle.scss';
// Helper
import {
  CommonHelper,
  PermissionModule,
  PermissionPage,
  PermissionAccess,
  HttpStatusCode,
} from '../../../../../helpers/Index';
// Action
import { getMembershipPurchaseDetails } from '../../../../../services/api/MembershipApi';

class MembershipPurchaseDetails extends React.Component {
  constructor(props) {
    super(props);
    const {
      match: { params },
    } = this.props;

    props.checkUserAccessPermission(
      PermissionModule.Memberships,
      PermissionPage.Purchase,
      PermissionAccess.View,
    );

    this.state = {
      isLoading: false,
      salesOrderId: CommonHelper.decryptObject(params.param_object).sales_order_id,
      hasAccess: true,
    };
  }

  componentDidMount() {
    this.getMembershipPurchaseDetails();
  }

  async componentDidUpdate() {
    const {
      membership: { fetching, errorMessage },
      history,
    } = this.props;

    if (!fetching && errorMessage && errorMessage.code === HttpStatusCode.Forbidden) {
      history.push('/membership/purchase');
    }
  }

  getMembershipPurchaseDetails() {
    const { membershipPurchaseDetails } = this.props;
    const { salesOrderId } = this.state;

    membershipPurchaseDetails(salesOrderId);
  }

  handleClick = (event, value) => {
    const { history } = this.props;
    event.preventDefault();
    history.push(value);
  };

  handleButtonCancel = () => {
    const { history } = this.props;
    history.push('/membership/purchase');
  };

  renderProductDetails = ProductDetails => {
    let renderElement = null;
    const productItems = !_.isEmpty(ProductDetails) ? ProductDetails.order_item_details : {};

    if (!_.isEmpty(productItems)) {
      const totalItemPrice = productItems.normal_price * productItems.quantity;

      renderElement = (
        <Grid container className="pt-32" direction="column">
          <GridRowTableFourColumn
            customElementColumnFirst={
              <label className="text-14 text-bold text-rolling-stone">Product Name</label>
            }
            customElementColumnSecond={
              <div className="text-center">
                <label className="text-14 text-bold text-rolling-stone">Price</label>
              </div>
            }
            customElementColumnThird={
              <div className="text-center">
                <label className="text-14 text-bold text-rolling-stone">Quantity</label>
              </div>
            }
            customElementColumnFourth={
              <div className="text-right">
                <label className="text-14 text-bold text-rolling-stone">Total Price</label>
              </div>
            }
            columnFirst={5}
            columnSecond={2}
            columnThird={2}
            columnFourth={3}
          />
          <div className="scroll-container container-list-item">
            <Grid container direction="column" className="flex-wrap-unset">
              <GridRowTableFourColumn
                customElementColumnFirst={
                  <label className="text-14 text-bold ">{productItems.name}</label>
                }
                customElementColumnSecond={
                  <div className="text-center">
                    <label className="text-14 text-semi-bold ">
                      IDR {CommonHelper.formatCurrency(productItems.normal_price)}
                    </label>
                  </div>
                }
                customElementColumnThird={
                  <div className="text-center">
                    <label className="text-14 text-semi-bold ">{productItems.quantity}</label>
                  </div>
                }
                customElementColumnFourth={
                  <div className="text-right">
                    <label className="text-14 text-semi-bold ">
                      IDR {CommonHelper.formatCurrency(totalItemPrice)}
                    </label>
                  </div>
                }
                columnFirst={5}
                columnSecond={2}
                columnThird={2}
                columnFourth={3}
              />
            </Grid>
          </div>
          {ProductDetails.price_discount !== 0 && (
            <GridRowTableOneColumn
              customElementInitial={
                <div className="flex-column">
                  <label className="text-14 text-semi-bold ">Discount</label>
                  <label className="text-10 ">{ProductDetails.promo_code}</label>
                </div>
              }
              customElementColumn={
                <div className="text-right">
                  <label className="text-14 text-semi-bold text-cinnabar">
                    IDR {CommonHelper.formatCurrency(ProductDetails.price_discount)}
                  </label>
                </div>
              }
              columnLeft={10}
              columnRight={2}
            />
          )}
          {ProductDetails.monthly_discount !== 0 && (
            <GridRowTableOneColumn
              customElementInitial={
                <div className="flex-column">
                  <label className="text-14 text-semi-bold ">Discount</label>
                  <label className="text-10 ">Special Price</label>
                </div>
              }
              customElementColumn={
                <div className="text-right">
                  <label className="text-14 text-semi-bold text-cinnabar">
                    IDR {CommonHelper.formatCurrency(ProductDetails.monthly_discount)}
                  </label>
                </div>
              }
              columnLeft={10}
              columnRight={2}
            />
          )}
          <GridRowTableOneColumn
            customElementInitial={
              <label className="text-16 text-semi-bold">
                Total Item(s): {productItems.quantity}
              </label>
            }
            customElementColumn={
              <div className="text-right">
                <label className="text-16 text-semi-bold">
                  Grand Total &nbsp; &nbsp; &nbsp; IDR&nbsp;
                  {CommonHelper.formatCurrency(ProductDetails.total_paid)}
                </label>
              </div>
            }
            columnLeft={7}
            columnRight={5}
          />
        </Grid>
      );
    }

    return renderElement;
  };

  renderMembershipCardDetails = membershipDetails => {
    let renderElement = null;

    if (membershipDetails) {
      renderElement = (
        <Grid
          container
          justify="space-between"
          className="container-main-card-shadow p-24"
          alignItems="center"
        >
          <Grid item lg md>
            <div className="flex-column">
              <label className="text-16 text-bold text-rolling-stone mb-24">
                {membershipDetails.product_name}
              </label>
              <Grid container justify="flex-start">
                <Grid item>
                  <div className="flex-column">
                    <label className="text-12 text-rolling-stone text-semi-bold mb-4">
                      Start Date
                    </label>
                    <label className="text-14 text-semi-bold">
                      {CommonHelper.dateTimeParseNewFormat(
                        membershipDetails.start_date,
                        'ddd, DD MMM YYYY',
                      ) || '-'}
                    </label>
                  </div>
                </Grid>
                <Grid item className="ph-10">
                  <div className="divider-hr-vertical " />
                </Grid>
                <Grid item>
                  <div className="flex-column">
                    <label className="text-12 text-rolling-stone text-semi-bold mb-4">
                      End Date
                    </label>
                    <label className="text-14 text-semi-bold">
                      {CommonHelper.dateTimeParseNewFormat(
                        membershipDetails.end_date,
                        'ddd, DD MMM YYYY',
                      ) || '-'}
                    </label>
                  </div>
                </Grid>
                <Grid item className="ph-10">
                  <div className="divider-hr-vertical" />
                </Grid>
                <Grid item>
                  <div className="flex-column">
                    <label className="text-12 text-rolling-stone text-semi-bold mb-4">Renew</label>
                    <label className="text-14 text-semi-bold text-downy">
                      {CommonHelper.dateTimeParseNewFormat(
                        membershipDetails.renew_date,
                        'ddd, DD MMM YYYY',
                      ) || '-'}
                    </label>
                  </div>
                </Grid>
                <Grid item className="ph-10">
                  <div className="divider-hr-vertical " />
                </Grid>
                <Grid item>
                  <div className="flex-column">
                    <label className="text-12 text-semi-bold text-rolling-stone mb-4">Quota</label>
                    <label className="text-14 text-semi-bold">
                      {membershipDetails.quota || '-'}
                    </label>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item lg={2} md={2}>
            <div className="flex-column text-center">
              {/* <label className="text-16  text-semi-bold mb-8"> */}
              <label className="text-16 text-bold">
                {`${membershipDetails.full_period} Days` || '-'}
              </label>
              {/* <label className="text-14  text-semi-bold text-rolling-stone">
                {`${membershipDetails.remaining_period} Remaining` || '-'}
              </label> */}
            </div>
          </Grid>
        </Grid>
      );
    }

    return renderElement;
  };

  render() {
    const {
      membership: { details, fetching },
    } = this.props;

    const orderDetailsData = !_.isEmpty(details) ? details : {};
    const prevUrl = '/membership/purchase';

    return (
      <div>
        <Helmet
          title={`FITCO | Membership - Purchase Details ${
            orderDetailsData ? orderDetailsData.sales_order_id : ''
          } `}
        />
        <div className="container-page-membership-purchase-details scroll-container-invisible">
          <div className="container-page-scrolling-area">
            <Grid container direction="column">
              <Grid item lg md className="section-page-header">
                <div className="breadcrumbs-section">
                  <Breadcrumbs aria-label="breadcrumb">
                    <Link
                      className="text-12"
                      color="inherit"
                      href={prevUrl}
                      onClick={event => {
                        this.handleClick(event, prevUrl);
                      }}
                    >
                      <i className="ic-ffo-memberships container-icon-prefix size-16" />
                      Membership
                    </Link>
                    <Link
                      className="text-12"
                      color="inherit"
                      href={prevUrl}
                      onClick={event => {
                        this.handleClick(event, prevUrl);
                      }}
                    >
                      Purchase
                    </Link>
                    <label className="text-12" color="inherit">
                      Details
                    </label>
                  </Breadcrumbs>
                </div>
              </Grid>
              <Grid item lg md className="section-page-body">
                {fetching ? null : ( // <SkeletonOrderDetails />
                  <div className="container-main-card flex-column p-32">
                    <Grid
                      container
                      justify="space-between"
                      spacing={2}
                      className="mb-16"
                      alignItems="center"
                    >
                      <Grid item lg md>
                        <label className="text-16 text-bold text-rolling-stone">
                          Details Purchase
                        </label>
                      </Grid>
                      <Grid item lg={2} md={2}>
                        <ButtonMain
                          type="ghost"
                          size="xl"
                          labelText="Back"
                          onClick={this.handleButtonCancel}
                        />
                      </Grid>
                    </Grid>
                    <Grid container justify="flex-start" className="mb-24">
                      <Grid item lg={2} md={2}>
                        <div className="flex-column">
                          <label className="text-12 text-rolling-stone text-semi-bold mb-4">
                            Customer
                          </label>
                          <label className="text-14 text-semi-bold wrapping-container third line">
                            {orderDetailsData.user_name || '-'}
                          </label>
                        </div>
                      </Grid>
                      <Grid item lg={2} md={2}>
                        <div className="flex-column">
                          <label className="text-12 text-rolling-stone text-semi-bold mb-4 wrapping-container third line">
                            Create by
                          </label>
                          <label className="text-14 text-semi-bold">
                            {orderDetailsData.created_by || '-'}
                          </label>
                        </div>
                      </Grid>
                      <Grid item lg={2} md={2}>
                        <div className="flex-column">
                          <label className="text-12 text-rolling-stone text-semi-bold mb-4">
                            Purchase Date
                          </label>
                          <label className="text-14 text-semi-bold">
                            {CommonHelper.dateTimeParseNewFormat(
                              orderDetailsData.order_date,
                              'ddd, DD MMM YYYY',
                            ) || '-'}
                          </label>
                        </div>
                      </Grid>
                      <Grid item lg={2} md={2}>
                        <div className="flex-column">
                          <label className="text-12 text-rolling-stone text-semi-bold mb-4">
                            Payment Methods
                          </label>
                          <label className="text-14 text-semi-bold">
                            {orderDetailsData.payment_method_title || '-'}
                          </label>
                        </div>
                      </Grid>
                      <Grid item lg={2} md={2}>
                        <div className="flex-column">
                          <label className="text-12 text-rolling-stone text-semi-bold mb-4">
                            Status
                          </label>
                          <LabelStatusPayment status={orderDetailsData.payment_status} />
                        </div>
                      </Grid>
                    </Grid>
                    {this.renderMembershipCardDetails(orderDetailsData.membership_details)}
                    {this.renderProductDetails(orderDetailsData)}
                    <label className="text-12 text-opacity-50 text-bold mt-32 mb-16">
                      Customer Details
                    </label>
                    <Grid
                      container
                      direction="column"
                      className="container-row-table details flex-wrap-unset"
                    >
                      <GridRowTableOneColumn
                        customElementInitial={
                          <label className="text-14 title text-rolling-stone pl-12">Name</label>
                        }
                        customElementColumn={
                          <label className="text-14">{orderDetailsData.user_name}</label>
                        }
                        columnLeft={3}
                        columnRight={9}
                      />
                      <GridRowTableOneColumn
                        customElementInitial={
                          <label className="text-14 title text-rolling-stone pl-12">Email</label>
                        }
                        customElementColumn={
                          <label className="text-14 wrapping-container-break">
                            {orderDetailsData.user_email || '-'}
                          </label>
                        }
                        columnLeft={3}
                        columnRight={9}
                      />
                      <GridRowTableOneColumn
                        customElementInitial={
                          <label className="text-14 title text-rolling-stone pl-12">
                            Phone Number
                          </label>
                        }
                        customElementColumn={
                          <label className="text-14">{orderDetailsData.user_phone || '-'}</label>
                        }
                        columnLeft={3}
                        columnRight={9}
                      />
                    </Grid>
                  </div>
                )}
              </Grid>
              <Grid item lg md className="section-page-footer" />
            </Grid>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  membershipPurchaseDetails: params => getMembershipPurchaseDetails(dispatch, params),
});

const mapStateToProps = ({ membership }) => ({ membership });

MembershipPurchaseDetails.propTypes = {
  checkUserAccessPermission: PropTypes.func,
  history: PropTypes.object,
  match: PropTypes.object,
  membership: PropTypes.object,
  membershipPurchaseDetails: PropTypes.func,
};

const shell = compose(connect(mapStateToProps, mapDispatchToProps));
const core = compose(AuthenticationAccessPages);

export default shell(core(MembershipPurchaseDetails));
