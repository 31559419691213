import React from 'react';
import PropTypes from 'prop-types';
import GridLayout from 'react-grid-layout';
// Style
import './GridDynamicLayoutStyle.scss';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

const GridDynamicLayout = props => {
  const { layout, width } = props;
  const [valueState, setState] = React.useState(width);

  const option = {
    // margin: [0, 0],
    margin: undefined,
  };

  React.useEffect(() => {
    const updateState = async () => {
      setState(width);
    };
    updateState();
  }, [width]);

  return (
    <GridLayout
      className="container-grid-layout"
      layout={layout}
      cols={12}
      rowHeight={10}
      width={valueState}
      margin={option.margin}
    >
      {props.children}
    </GridLayout>
  );
};

GridDynamicLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  layout: PropTypes.array,
  width: PropTypes.number,
};

export default GridDynamicLayout;
