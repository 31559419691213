/* eslint-disable react/no-access-state-in-setstate */
import React from 'react';
import { connect } from 'react-redux';
import { Grid, FormControl } from '@material-ui/core';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { Table } from 'antd';
// component
import {
  RadioInput,
  SelectInputPaymentMethod,
  SelectInputPaymentPlan,
  SelectInputMerchantV02,
  SelectInputProductType,
  SelectInputProductCategory,
  SelectInputProductMapping,
  SelectInputStoreLocation,
  ButtonIconMain,
  ModalAlertAction,
  SelectInputEatPackage,
  FitcoPopover,
} from '../../../../../../components/Index';
// style
import './StepPromoCodeMappingStyle.scss';
// helper
import {
  CommonHelper,
  MasterDataHelper,
  radioButtonTrueFalseCode,
  FitcoPopoverTrigger,
  FitcoPopoverPlacement,
} from '../../../../../../helpers/Index';

const radioButtonTrueFalseOption = MasterDataHelper.radioButtonTrueFalse;

const initialValidation = {
  paymentPlan: { isError: '', errorMessage: '' },
  paymentMethod: { isError: '', errorMessage: '' },
  merchant: { isError: '', errorMessage: '' },
  eatPackage: { isError: '', errorMessage: '' },
  productType: { isError: '', errorMessage: '' },
  productCategory: { isError: '', errorMessage: '' },
  product: { isError: '', errorMessage: '' },
};

const initialForm = {
  paymentPlanStatus: CommonHelper.objectCloning(radioButtonTrueFalseOption[0]),
  paymentPlan: [],
  paymentMethodStatus: CommonHelper.objectCloning(radioButtonTrueFalseOption[0]),
  paymentMethod: [],
  merchantStatus: CommonHelper.objectCloning(radioButtonTrueFalseOption[0]),
  merchant: [],
  availableAtStore: CommonHelper.objectCloning(radioButtonTrueFalseOption[0]),
  allStore: CommonHelper.objectCloning(radioButtonTrueFalseOption[0]),
  includeExternalBrand: CommonHelper.objectCloning(radioButtonTrueFalseOption[0]),
  store: [],
  eatPackageStatus: CommonHelper.objectCloning(radioButtonTrueFalseOption[0]),
  eatPackage: [],
  productTypeStatus: CommonHelper.objectCloning(radioButtonTrueFalseOption[0]),
  productType: [],
  productCategoryStatus: CommonHelper.objectCloning(radioButtonTrueFalseOption[0]),
  productCategory: [],
  productStatus: CommonHelper.objectCloning(radioButtonTrueFalseOption[0]),
  product: [],
  selectedProduct: [],
};

const initialFilter = {
  type: null,
  merchantId: null,
  productType: null,
  categoryId: null,
};

class StepPromoCodeMapping extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      form: CommonHelper.objectCloning(initialForm),
      filter: CommonHelper.objectCloning(initialFilter),
      validation: CommonHelper.objectCloning(initialValidation),
      limit: 10,
      page: 1,
      pagination: {
        showSizeChanger: true,
        showTotal: (total, range) => `Showing ${range[0]}-${range[1]} From ${total}`,
        pageSizeOptions: ['10', '20', '30', '40'],
      },
      selectedDataDelete: null,
      isOpenModalDelete: false,
      modalDetailDataDelete: {
        title: 'Confirmation',
        body: 'Are you sure want to Company ',
      },
    };
    this.updateParentDebounce = _.debounce(this.setUpdateParentDebounce, 600);
  }

  componentDidMount() {
    const { parentValidation, parentForm } = this.props;
    const { form, validation, filter } = this.state;

    this.setState(
      {
        form: { ...form, ...parentForm },
        filter: {
          ...filter,
          type: parentForm.type,
          merchantId: parentForm.merchant || null,
          productType: parentForm.productType || null,
          categoryId: parentForm.productCategory || null,
        },
        validation: { ...validation, ...parentValidation },
      },
      () => {
        // this.updateParentDebounce();
        if (parentForm.cashbackId && !_.isEmpty(parentForm.product)) {
          setTimeout(() => {
            this.handleSelectProduct(parentForm.product);
          }, 1000);
        }
      },
    );
  }

  componentWillReceiveProps(nextProps) {
    const { validation } = this.state;

    const isValidationChange = _.isEqual(validation, nextProps.parentValidation);

    if (!isValidationChange) {
      const finalMergeValidation = { ...validation, ...nextProps.parentValidation };
      this.setState({
        validation: finalMergeValidation,
      });
    }
  }

  componentDidUpdate(prevProps, nextProps) {
    const { form } = this.state;

    const checkFormIsChanging = _.isEqual(form, nextProps.form);
    if (!checkFormIsChanging) {
      this.updateParentDebounce();
    }
  }

  setUpdateParentDebounce() {
    const { form } = this.state;
    const { onParameterUpdate } = this.props;

    const formNewValue = CommonHelper.objectCloning(form);
    onParameterUpdate(formNewValue);
  }

  handleSelectPaymentPlanStatus = value => {
    const { form } = this.state;

    this.setState({
      form: { ...form, paymentPlanStatus: value, paymentPlan: [] },
    });
  };

  handleSelectPaymentPlan = value => {
    const { form } = this.state;
    this.setState({
      form: { ...form, paymentPlan: value || null },
    });
  };

  handleIsAvailableAtStore = value => {
    const { form } = this.state;

    this.setState({
      form: { ...form, availableAtStore: value, store: [] },
    });
  };

  handleIsIncludeAllStore = value => {
    const { form } = this.state;

    this.setState({
      form: { ...form, allStore: value, store: [] },
    });
  };

  handleIncludeExternalBrand = value => {
    const { form } = this.state;

    this.setState({
      form: { ...form, includeExternalBrand: value, store: [] },
    });
  };

  handleStoreChange = value => {
    const { form } = this.state;

    this.setState({
      form: { ...form, store: value },
    });
  };

  handleSelectPaymentMethodStatus = value => {
    const { form } = this.state;

    const modifyPaymentMethod =
      value.value === radioButtonTrueFalseCode.Yes ? [] : form.paymentMethod;

    this.setState({
      form: { ...form, paymentMethodStatus: value, paymentMethod: modifyPaymentMethod },
    });
  };

  handleSelectPaymentMethod = value => {
    const { form } = this.state;
    this.setState({
      form: { ...form, paymentMethod: value || null },
    });
  };

  handleSelectMerchantStatus = value => {
    const { form, filter } = this.state;
    let modifyEatPackageStatus = form.eatPackageStatus;
    let modifyEatPackage = form.eatPackage;

    const modifyMerchant = value.value === radioButtonTrueFalseCode.Yes ? [] : form.merchant;
    const modifyProductType =
      form.productTypeStatus.value === radioButtonTrueFalseCode.No ? [] : form.productType;
    const modifyProductCategory =
      form.productCategoryStatus.value === radioButtonTrueFalseCode.No ? [] : form.productCategory;
    const modifyProduct =
      form.productStatus.value === radioButtonTrueFalseCode.No ? [] : form.product;

    const findIndex = form.merchant.findIndex(item => item === 6); // fitgourmet id
    const checkValidItemFlow = !!(value.value === radioButtonTrueFalseCode.No && findIndex === -1);
    const checkSecondFlow = _.isEmpty(form.eatPackage) ? false : checkValidItemFlow;

    const isDisableEatRadio =
      value.value === radioButtonTrueFalseCode.Yes ? checkSecondFlow : checkValidItemFlow;

    if (isDisableEatRadio) {
      modifyEatPackageStatus = radioButtonTrueFalseOption.find(
        item => item.value === radioButtonTrueFalseCode.Yes,
      );
      modifyEatPackage = [];
    }

    this.setState(
      {
        form: {
          ...form,
          merchantStatus: value,
          merchant: modifyMerchant,
          productType: modifyProductType,
          productCategory: modifyProductCategory,
          product: modifyProduct,
          eatPackageStatus: modifyEatPackageStatus,
          eatPackage: modifyEatPackage,
        },
        filter: {
          ...filter,
          merchantId: modifyMerchant,
          productType: modifyProductType,
          categoryId: modifyProductCategory,
        },
      },
      () => {
        this.validationField();
      },
    );
  };

  handleSelectMerchant = value => {
    const { form, filter } = this.state;
    let modifyEatPackageStatus = form.eatPackageStatus;
    let modifyEatPackage = form.eatPackage;

    const findIndex = value.findIndex(item => item === 6); // fitgourmet id

    const isDisableEatRadio = !(
      form.merchantStatus.value === radioButtonTrueFalseCode.Yes || findIndex === 1
    );

    if (isDisableEatRadio) {
      modifyEatPackageStatus = radioButtonTrueFalseOption.find(
        item => item.value === radioButtonTrueFalseCode.Yes,
      );
      modifyEatPackage = [];
    }

    this.setState(
      {
        form: {
          ...form,
          merchant: value || null,
          eatPackageStatus: modifyEatPackageStatus,
          eatPackage: modifyEatPackage,
        },
        filter: { ...filter, merchantId: value || null },
      },
      () => {
        this.validationField();
      },
    );
  };

  handleSelectEatPackageStatus = value => {
    const { form } = this.state;

    const modifyPaymentMethod = value.value === radioButtonTrueFalseCode.Yes ? [] : form.eatPackage;

    this.setState({
      form: { ...form, eatPackageStatus: value, eatPackage: modifyPaymentMethod },
    });
  };

  handleSelectEatPackage = value => {
    const { form } = this.state;
    this.setState({
      form: { ...form, eatPackage: value || null },
    });
  };

  handleSelectProductTypeStatus = value => {
    const { form, filter } = this.state;
    const modifyProductType = value.value === radioButtonTrueFalseCode.Yes ? [] : form.productType;
    const modifyProductCategory =
      form.productCategoryStatus.value === radioButtonTrueFalseCode.No ? [] : form.productCategory;
    const modifyProduct =
      form.productStatus.value === radioButtonTrueFalseCode.No ? [] : form.product;

    this.setState(
      {
        form: {
          ...form,
          productTypeStatus: value,
          productType: modifyProductType,
          productCategory: modifyProductCategory,
          product: modifyProduct,
        },
        filter: { ...filter, productType: modifyProductType, categoryId: modifyProductCategory },
      },
      () => {
        this.validationField();
      },
    );
  };

  handleSelectProductType = value => {
    const { form, filter } = this.state;

    this.setState(
      {
        form: { ...form, productType: value || null },
        filter: { ...filter, productType: value || null },
      },
      () => {
        this.validationField();
      },
    );
  };

  handleSelectProductCategoryStatus = value => {
    const { form, filter } = this.state;
    const modifyProductCategory =
      value.value === radioButtonTrueFalseCode.Yes ? [] : form.productCategory;

    this.setState(
      {
        form: { ...form, productCategoryStatus: value, productCategory: modifyProductCategory },
        filter: { ...filter, categoryId: modifyProductCategory },
      },
      () => {
        this.validationField();
      },
    );
  };

  handleSelectProductCategory = value => {
    const { form, filter } = this.state;

    this.setState(
      {
        form: { ...form, productCategory: value || null },
        filter: { ...filter, categoryId: value || null },
      },
      () => {
        this.validationField();
      },
    );
  };

  handleSelectProductStatus = value => {
    const { form } = this.state;
    const modifyProduct = value.value === radioButtonTrueFalseCode.Yes ? [] : form.product;

    this.setState(
      {
        form: { ...form, productStatus: value, product: modifyProduct },
      },
      () => {
        this.validationField();
      },
    );
  };

  handleSelectProduct = value => {
    const { form, filter, pagination, limit } = this.state;
    const {
      masterData: { productMappingList },
    } = this.props;

    const converted = [];

    value.forEach(item => {
      const findProduct = _.find(productMappingList, ['product_id', item]);

      const param = {
        productId: findProduct.product_id,
        productName: findProduct.name,
        merchantId: findProduct.merchant_id,
        merchantName: findProduct.merchant_name,
        type: findProduct.type,
        typeName: CommonHelper.renameRemoveCharacter(findProduct.type, '_'),
      };

      converted.push(param);
    });

    this.setState(
      {
        form: { ...form, product: value || null, selectedProduct: converted },
        filter: { ...filter, product: value || null },
        pagination: { ...pagination, pageSize: limit, total: converted.length },
      },
      () => {
        this.validationField();
      },
    );
  };

  handleTableChange = pagination => {
    this.setState({
      limit: pagination.pageSize,
      page: pagination.current,
      pagination: {
        ...this.state.pagination,
        current: pagination.current,
        pageSize: pagination.pageSize,
      },
    });
  };

  handleButtonDeleted = data => {
    const { modalDetailDataDelete } = this.state;

    this.setState({
      isOpenModalDelete: true,
      selectedDataDelete: data,
      modalDetailDataDelete: {
        ...modalDetailDataDelete,
        body: `Are you sure want to delete Product ${data.productName} ? `,
      },
    });
  };

  handleButtonSubmitDelete = () => {
    const { form, selectedDataDelete } = this.state;

    let modifySelectedProduct = CommonHelper.objectCloning(form.selectedProduct);
    let modifyProduct = CommonHelper.objectCloning(form.product);

    if (selectedDataDelete) {
      modifySelectedProduct = modifySelectedProduct.filter(
        item => item.productId !== selectedDataDelete.productId,
      );

      modifyProduct = modifyProduct.filter(item => item !== selectedDataDelete.productId);

      this.setState(
        {
          form: { ...form, selectedProduct: modifySelectedProduct, product: modifyProduct },
        },
        () => {
          this.handleCloseModalDelete();
        },
      );
    }
  };

  handleCloseModalDelete = () => {
    this.setState({
      isOpenModalDelete: false,
    });
  };

  generatePopoverContent = text => {
    const content = <div className="p-5 text-10">{text}</div>;
    return content;
  };

  validationField() {
    const {
      form: { merchantStatus, productTypeStatus, productCategoryStatus, productStatus },
      validation,
      filter,
    } = this.state;

    const productTypeMessage = CommonHelper.objectCloning(initialValidation.productType);

    if (productTypeStatus.value !== radioButtonTrueFalseCode.Yes) {
      productTypeMessage.isError =
        _.isEmpty(filter.merchantId) && merchantStatus.value !== radioButtonTrueFalseCode.Yes
          ? 'error'
          : '';
      productTypeMessage.errorMessage =
        _.isEmpty(filter.merchantId) && merchantStatus.value !== radioButtonTrueFalseCode.Yes
          ? 'Merchant must be select First'
          : '';
    }
    const productCategoryMessage = CommonHelper.objectCloning(initialValidation.productCategory);

    if (productCategoryStatus.value !== radioButtonTrueFalseCode.Yes) {
      productCategoryMessage.isError =
        (_.isEmpty(filter.merchantId) && merchantStatus.value !== radioButtonTrueFalseCode.Yes) ||
        (_.isEmpty(filter.productType) && productTypeStatus.value !== radioButtonTrueFalseCode.Yes)
          ? 'error'
          : '';
      productCategoryMessage.errorMessage =
        (_.isEmpty(filter.merchantId) && merchantStatus.value !== radioButtonTrueFalseCode.Yes) ||
        (_.isEmpty(filter.productType) && productTypeStatus.value !== radioButtonTrueFalseCode.Yes)
          ? 'Merchant and Product Type must be select First'
          : '';
    }
    const productMessage = CommonHelper.objectCloning(initialValidation.product);

    if (productStatus.value !== radioButtonTrueFalseCode.Yes) {
      productMessage.isError =
        (_.isEmpty(filter.merchantId) && merchantStatus.value !== radioButtonTrueFalseCode.Yes) ||
        (_.isEmpty(filter.productType) &&
          productTypeStatus.value !== radioButtonTrueFalseCode.Yes) ||
        (_.isEmpty(filter.categoryId) &&
          productCategoryStatus.value !== radioButtonTrueFalseCode.Yes)
          ? 'error'
          : '';
      productMessage.errorMessage =
        (_.isEmpty(filter.merchantId) && merchantStatus.value !== radioButtonTrueFalseCode.Yes) ||
        (_.isEmpty(filter.productType) &&
          productTypeStatus.value !== radioButtonTrueFalseCode.Yes) ||
        (_.isEmpty(filter.categoryId) &&
          productCategoryStatus.value !== radioButtonTrueFalseCode.Yes)
          ? 'Merchant, Product Type and Product Category must be select First'
          : '';
    }

    this.setState({
      validation: {
        ...validation,
        productType: productTypeMessage,
        productCategory: productCategoryMessage,
        product: productMessage,
      },
    });
  }

  renderColumns = () => {
    return [
      {
        title: 'PRODUCT ID',
        width: '16%',
        dataIndex: 'productId',
        align: 'center',
        render: (text, row) => {
          return <label className="text-14 text-semi-bold">{row.productId}</label>;
        },
      },
      {
        title: 'NAME',
        dataIndex: 'productName',
        render: (text, row) => {
          return (
            <label className="text-14 text-semi-bold wrapping-container-break wrapping-container second line ">
              {row.productName}
            </label>
          );
        },
      },
      {
        title: 'MERCHANT',
        width: '25%',
        dataIndex: 'merchantName',
        align: 'center',
        render: (text, row) => {
          return (
            <label className="text-14 text-semi-bold wrapping-container-break wrapping-container second line ">
              {row.merchantName}
            </label>
          );
        },
      },
      {
        title: 'TYPE',
        width: '20%',
        dataIndex: 'typeName',
        align: 'center',
        render: (text, row) => {
          return (
            <label className="text-14 text-semi-bold wrapping-container-break wrapping-container second line ">
              {row.typeName}
            </label>
          );
        },
      },
      {
        title: '#',
        width: '5%',
        align: 'center',
        render: (text, row) => {
          const renderElement = (
            <ButtonIconMain
              icon="ic-ffo-bin"
              type="negative"
              size="sm"
              onClick={() => this.handleButtonDeleted(row)}
            />
          );

          return renderElement;
        },
      },
    ];
  };

  renderModalDelete() {
    const { isOpenModalDelete } = this.state;
    let elementRender = null;

    if (isOpenModalDelete) {
      elementRender = (
        <ModalAlertAction
          onButtonSubmit={this.handleButtonSubmitDelete}
          onCloseModal={this.handleCloseModalDelete}
          modalDetail={this.state.modalDetailDataDelete}
          modalType="danger"
          buttonSubmitText="Submit"
          openModal={isOpenModalDelete}
        />
      );
    }

    return elementRender;
  }

  render() {
    const { validation, form, filter, pagination, limit, page } = this.state;
    const { customElementPropsFormLabel, customElementPropsStepper } = this.props;

    const customTablePagination = CommonHelper.getArrayByLimit(form.selectedProduct, limit, page);

    const findIndex = form.merchant.findIndex(item => item === 6); // fitgourmet id
    const checkSecondFlow = !!(
      form.merchantStatus.value === radioButtonTrueFalseCode.No && findIndex === -1
    );

    const isDisableEatRadio =
      form.merchantStatus.value === radioButtonTrueFalseCode.Yes && findIndex === -1
        ? false
        : checkSecondFlow;

    const isDisableEatSelection =
      (form.merchantStatus.value === radioButtonTrueFalseCode.Yes && findIndex === -1
        ? false
        : checkSecondFlow) || form.eatPackageStatus.value === radioButtonTrueFalseCode.Yes;

    return (
      <React.Fragment>
        <Grid item lg md className="container-main-card flex-column p-32 flex-wrap-unset mb-32">
          <div className="mb-32">{customElementPropsFormLabel}</div>
          <div className="mb-48">{customElementPropsStepper}</div>
          <Grid container spacing={2}>
            <Grid item lg={12} md={12}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <label className="text-16 text-bold text-rolling-stone mb-8">
                  Applicable Payment Plan
                  <FitcoPopover
                    content={this.generatePopoverContent(
                      'Use this feature to manage which payment plan is applicable to this promotion.',
                    )}
                    placement={FitcoPopoverPlacement.right}
                    trigger={FitcoPopoverTrigger.Hover}
                  />
                </label>
                <Grid container spacing={2} alignItems="center">
                  <Grid item lg={3} md={3}>
                    <label className="text-12 text-semi-bold text-rolling-stone">
                      All Payment Plan
                    </label>
                  </Grid>
                  <Grid item lg={9} md={9}>
                    <RadioInput
                      data={radioButtonTrueFalseOption}
                      onSelect={this.handleSelectPaymentPlanStatus}
                      direction="row"
                      currentValue={form.paymentPlanStatus}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} alignItems="center" className="mt-4">
                  <Grid item lg={3} md={3}>
                    <label className="text-12 text-semi-bold text-rolling-stone">
                      Select Payment Plan
                    </label>
                  </Grid>
                  <Grid item lg={9} md={9}>
                    <SelectInputPaymentPlan
                      onChange={this.handleSelectPaymentPlan}
                      currentValue={form.paymentPlan}
                      placeholder={'Select Plan'}
                      filter={filter}
                      isMultipleSelection
                      disabled={form.paymentPlanStatus.value === radioButtonTrueFalseCode.Yes}
                      validateStatus={validation.paymentPlan.isError}
                      errorMessage={validation.paymentPlan.errorMessage}
                    />
                  </Grid>
                </Grid>
              </FormControl>
            </Grid>
            <Grid item lg={12} md={12}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <label className="text-16 text-bold text-rolling-stone mb-8">
                  Applicable Payment Method
                  <FitcoPopover
                    content={this.generatePopoverContent(
                      'Use this feature to manage which payment method is applicable to this promotion.',
                    )}
                    placement={FitcoPopoverPlacement.right}
                    trigger={FitcoPopoverTrigger.Hover}
                  />
                </label>
                <Grid container spacing={2} alignItems="center">
                  <Grid item lg={3} md={3}>
                    <label className="text-12 text-semi-bold text-rolling-stone">
                      All Payment Method
                    </label>
                  </Grid>
                  <Grid item lg={9} md={9}>
                    <RadioInput
                      data={radioButtonTrueFalseOption}
                      onSelect={this.handleSelectPaymentMethodStatus}
                      direction="row"
                      currentValue={form.paymentMethodStatus}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} alignItems="center" className="mt-4">
                  <Grid item lg={3} md={3}>
                    <label className="text-12 text-semi-bold text-rolling-stone">
                      Select Payment Method
                    </label>
                  </Grid>
                  <Grid item lg={9} md={9}>
                    <SelectInputPaymentMethod
                      onChange={this.handleSelectPaymentMethod}
                      currentValue={form.paymentMethod}
                      placeholder={'Select Method'}
                      filter={filter}
                      isMultipleSelection
                      disabled={form.paymentMethodStatus.value === radioButtonTrueFalseCode.Yes}
                      validateStatus={validation.paymentMethod.isError}
                      errorMessage={validation.paymentMethod.errorMessage}
                    />
                  </Grid>
                </Grid>
              </FormControl>
            </Grid>
            <Grid item lg={12} md={12}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <label className="text-16 text-bold text-rolling-stone mb-8">
                  Applicable Merchant
                  <FitcoPopover
                    content={this.generatePopoverContent(
                      'Use this feature to manage which merchant is applicable to this promotion.',
                    )}
                    placement={FitcoPopoverPlacement.right}
                    trigger={FitcoPopoverTrigger.Hover}
                  />
                </label>
                <Grid container spacing={2} alignItems="center">
                  <Grid item lg={3} md={3}>
                    <label className="text-12 text-semi-bold text-rolling-stone">
                      All Merchant
                    </label>
                  </Grid>
                  <Grid item lg={9} md={9}>
                    <RadioInput
                      data={radioButtonTrueFalseOption}
                      onSelect={this.handleSelectMerchantStatus}
                      direction="row"
                      currentValue={form.merchantStatus}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} alignItems="center" className="mt-4">
                  <Grid item lg={3} md={3}>
                    <label className="text-12 text-semi-bold text-rolling-stone">
                      Select Merchant
                    </label>
                  </Grid>
                  <Grid item lg={9} md={9}>
                    <SelectInputMerchantV02
                      onChange={this.handleSelectMerchant}
                      currentValue={form.merchant}
                      placeholder={'Select Merchant'}
                      isMultipleSelection
                      disabled={form.merchantStatus.value === radioButtonTrueFalseCode.Yes}
                      validateStatus={validation.merchant.isError}
                      errorMessage={validation.merchant.errorMessage}
                      filter={{ allMerchant: 1 }}
                    />
                  </Grid>
                </Grid>
              </FormControl>
            </Grid>
            <Grid item lg={12} md={12}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <label className="text-16 text-bold text-rolling-stone mb-8">
                  Applicable for At-Store
                  <FitcoPopover
                    content={this.generatePopoverContent(
                      'Use this feature to manage whether this promo is applicable for EAT at-store transaction.',
                    )}
                    placement={FitcoPopoverPlacement.right}
                    trigger={FitcoPopoverTrigger.Hover}
                  />
                </label>
                <Grid container spacing={2} alignItems="center">
                  <Grid item lg={3} md={3}>
                    <label className="text-12 text-semi-bold text-rolling-stone">
                      Is available for at store
                    </label>
                  </Grid>
                  <Grid item lg={9} md={9}>
                    <RadioInput
                      data={radioButtonTrueFalseOption}
                      onSelect={this.handleIsAvailableAtStore}
                      direction="row"
                      currentValue={form.availableAtStore}
                      isDisable={isDisableEatRadio}
                    />
                  </Grid>
                </Grid>
                {form.availableAtStore.value === radioButtonTrueFalseCode.Yes && (
                  <Grid container spacing={2} alignItems="center">
                    <Grid item lg={3} md={3}>
                      <label className="text-12 text-semi-bold text-rolling-stone">
                        Include all store
                      </label>
                    </Grid>
                    <Grid item lg={9} md={9}>
                      <RadioInput
                        data={radioButtonTrueFalseOption}
                        onSelect={this.handleIsIncludeAllStore}
                        direction="row"
                        currentValue={form.allStore}
                        isDisable={isDisableEatRadio}
                      />
                    </Grid>
                  </Grid>
                )}
                {form.availableAtStore.value === radioButtonTrueFalseCode.Yes && (
                  <Grid container spacing={2} alignItems="center" className="mt-4">
                    <Grid item lg={3} md={3}>
                      <label className="text-12 text-semi-bold text-rolling-stone">
                        Select store
                      </label>
                    </Grid>
                    <Grid item lg={9} md={9}>
                      <SelectInputStoreLocation
                        onChange={this.handleStoreChange}
                        currentValue={form.store}
                        placeholder={'Select Store Location'}
                        isMultipleSelection
                        disabled={
                          isDisableEatRadio || form.allStore.value === radioButtonTrueFalseCode.Yes
                        }
                      />
                    </Grid>
                  </Grid>
                )}
              </FormControl>
            </Grid>
            {form.availableAtStore.value === radioButtonTrueFalseCode.Yes && (
              <Grid item lg={12} md={12}>
                <FormControl component="fieldset" fullWidth margin="normal">
                  <label className="text-16 text-bold text-rolling-stone mb-8">
                    Applicable for External Brand
                    <FitcoPopover
                      content={this.generatePopoverContent(
                        "Use this feature to manage whether this promo is applicable for EAT at-store's external brand, eg. Misol, Ask for Patty, etc.",
                      )}
                      placement={FitcoPopoverPlacement.right}
                      trigger={FitcoPopoverTrigger.Hover}
                    />
                  </label>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item lg={3} md={3}>
                      <label className="text-12 text-semi-bold text-rolling-stone">
                        Include External Brand
                      </label>
                    </Grid>
                    <Grid item lg={9} md={9}>
                      <RadioInput
                        data={radioButtonTrueFalseOption}
                        onSelect={this.handleIncludeExternalBrand}
                        direction="row"
                        currentValue={form.includeExternalBrand}
                        isDisable={isDisableEatRadio}
                      />
                    </Grid>
                  </Grid>
                </FormControl>
              </Grid>
            )}
            <Grid item lg={12} md={12}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <label className="text-16 text-bold text-rolling-stone mb-8">
                  Applicable Catering Package
                  <FitcoPopover
                    content={this.generatePopoverContent(
                      'Use this feature to manage which EAT catering package is applicable for this promotion.',
                    )}
                    placement={FitcoPopoverPlacement.right}
                    trigger={FitcoPopoverTrigger.Hover}
                  />
                </label>
                <Grid container spacing={2} alignItems="center">
                  <Grid item lg={3} md={3}>
                    <label className="text-12 text-semi-bold text-rolling-stone">
                      All Catering Package
                    </label>
                  </Grid>
                  <Grid item lg={9} md={9}>
                    <RadioInput
                      data={radioButtonTrueFalseOption}
                      onSelect={this.handleSelectEatPackageStatus}
                      direction="row"
                      currentValue={form.eatPackageStatus}
                      isDisable={isDisableEatRadio}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} alignItems="center" className="mt-4">
                  <Grid item lg={3} md={3}>
                    <label className="text-12 text-semi-bold text-rolling-stone">
                      Select Catering Package
                    </label>
                  </Grid>
                  <Grid item lg={9} md={9}>
                    <SelectInputEatPackage
                      onChange={this.handleSelectEatPackage}
                      currentValue={form.eatPackage}
                      placeholder={'Select Eat Package'}
                      isMultipleSelection
                      disabled={isDisableEatSelection}
                      validateStatus={validation.eatPackage.isError}
                      errorMessage={validation.eatPackage.errorMessage}
                    />
                  </Grid>
                </Grid>
              </FormControl>
            </Grid>
            <Grid item lg={12} md={12}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <label className="text-16 text-bold text-rolling-stone mb-8">
                  Applicable Product Type
                  <FitcoPopover
                    content={this.generatePopoverContent(
                      'Use this feature to manage which product type is applicable to this promotion.',
                    )}
                    placement={FitcoPopoverPlacement.right}
                    trigger={FitcoPopoverTrigger.Hover}
                  />
                </label>
                <Grid container spacing={2} alignItems="center">
                  <Grid item lg={3} md={3}>
                    <label className="text-12 text-semi-bold text-rolling-stone">
                      All Product Type
                    </label>
                  </Grid>
                  <Grid item lg={9} md={9}>
                    <RadioInput
                      data={radioButtonTrueFalseOption}
                      onSelect={this.handleSelectProductTypeStatus}
                      direction="row"
                      currentValue={form.productTypeStatus}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} alignItems="center" className="mt-4">
                  <Grid item lg={3} md={3}>
                    <label className="text-12 text-semi-bold text-rolling-stone">
                      Select Product Type
                    </label>
                  </Grid>
                  <Grid item lg={9} md={9}>
                    <SelectInputProductType
                      onChange={this.handleSelectProductType}
                      currentValue={form.productType}
                      placeholder={'Select Product Type'}
                      filter={filter}
                      isMultipleSelection
                      disabled={
                        form.productTypeStatus.value === radioButtonTrueFalseCode.Yes ||
                        (_.isEmpty(filter.merchantId) &&
                          form.merchantStatus.value !== radioButtonTrueFalseCode.Yes)
                      }
                      validateStatus={validation.productType.isError}
                      errorMessage={validation.productType.errorMessage}
                    />
                  </Grid>
                </Grid>
              </FormControl>
            </Grid>
            <Grid item lg={12} md={12}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <label className="text-16 text-bold text-rolling-stone mb-8">
                  Applicable Product Category
                  <FitcoPopover
                    content={this.generatePopoverContent(
                      'Use this feature to manage which product category is applicable to this promotion.',
                    )}
                    placement={FitcoPopoverPlacement.right}
                    trigger={FitcoPopoverTrigger.Hover}
                  />
                </label>
                <Grid container spacing={2} alignItems="center">
                  <Grid item lg={3} md={3}>
                    <label className="text-12 text-semi-bold text-rolling-stone">
                      All Product Category
                    </label>
                  </Grid>
                  <Grid item lg={9} md={9}>
                    <RadioInput
                      data={radioButtonTrueFalseOption}
                      onSelect={this.handleSelectProductCategoryStatus}
                      direction="row"
                      currentValue={form.productCategoryStatus}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} alignItems="center" className="mt-4">
                  <Grid item lg={3} md={3}>
                    <label className="text-12 text-semi-bold text-rolling-stone">
                      Select Product Category
                    </label>
                  </Grid>
                  <Grid item lg={9} md={9}>
                    <SelectInputProductCategory
                      onChange={this.handleSelectProductCategory}
                      currentValue={form.productCategory}
                      placeholder={'Select Product Category'}
                      filter={filter}
                      isMultipleSelection
                      disabled={
                        form.productCategoryStatus.value === radioButtonTrueFalseCode.Yes ||
                        (_.isEmpty(filter.merchantId) &&
                          form.merchantStatus.value !== radioButtonTrueFalseCode.Yes) ||
                        (_.isEmpty(filter.productType) &&
                          form.productTypeStatus.value !== radioButtonTrueFalseCode.Yes)
                      }
                      validateStatus={validation.productCategory.isError}
                      errorMessage={validation.productCategory.errorMessage}
                    />
                  </Grid>
                </Grid>
              </FormControl>
            </Grid>
            <Grid item lg={12} md={12}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <label className="text-16 text-bold text-rolling-stone mb-8">
                  Applicable Product
                  <FitcoPopover
                    content={this.generatePopoverContent(
                      'Use this feature to manage which product is applicable to this promotion.',
                    )}
                    placement={FitcoPopoverPlacement.right}
                    trigger={FitcoPopoverTrigger.Hover}
                  />
                </label>
                <Grid container spacing={2} alignItems="center">
                  <Grid item lg={3} md={3}>
                    <label className="text-12 text-semi-bold text-rolling-stone">All Product</label>
                  </Grid>
                  <Grid item lg={9} md={9}>
                    <RadioInput
                      data={radioButtonTrueFalseOption}
                      onSelect={this.handleSelectProductStatus}
                      direction="row"
                      currentValue={form.productStatus}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} alignItems="center" className="mt-4">
                  <Grid item lg={3} md={3}>
                    <label className="text-12 text-semi-bold text-rolling-stone">
                      Select Product
                    </label>
                  </Grid>
                  <Grid item lg={9} md={9}>
                    <SelectInputProductMapping
                      onChange={this.handleSelectProduct}
                      currentValue={form.product}
                      placeholder={'Select Product'}
                      filter={filter}
                      isMultipleSelection
                      disabled={
                        form.productStatus.value === radioButtonTrueFalseCode.Yes ||
                        (_.isEmpty(filter.merchantId) &&
                          form.merchantStatus.value !== radioButtonTrueFalseCode.Yes) ||
                        (_.isEmpty(filter.productType) &&
                          form.productTypeStatus.value !== radioButtonTrueFalseCode.Yes) ||
                        (_.isEmpty(filter.categoryId) &&
                          form.productCategoryStatus.value !== radioButtonTrueFalseCode.Yes)
                      }
                      validateStatus={validation.product.isError}
                      errorMessage={validation.product.errorMessage}
                    />
                  </Grid>
                </Grid>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        {form.productStatus.value !== radioButtonTrueFalseCode.Yes && (
          <Table
            className="mb-32"
            columns={this.renderColumns()}
            rowKey={record => record.productId}
            dataSource={customTablePagination}
            pagination={pagination}
            onChange={this.handleTableChange}
          />
        )}
        {this.renderModalDelete()}
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = () => ({});

const mapStateToProps = ({ masterData }) => ({ masterData });

StepPromoCodeMapping.propTypes = {
  customElementPropsFormLabel: PropTypes.element,
  customElementPropsStepper: PropTypes.element,
  form: PropTypes.object,
  masterData: PropTypes.object,
  onParameterUpdate: PropTypes.func,
  parentForm: PropTypes.object,
  parentValidation: PropTypes.object,
};

const shell = compose(connect(mapStateToProps, mapDispatchToProps));

export default shell(StepPromoCodeMapping);
