import React from 'react';
import PropTypes from 'prop-types';
import { Grid, IconButton } from '@material-ui/core';
import { connect } from 'react-redux';
import { QRCode } from 'react-qrcode-logo';
import clsx from 'clsx';
// style
import './ModalOrderQrisStyle.scss';
// component
import { ModalInformationPopUp } from '../../../../../../components/Index';
// assets
import { Images } from '../../../../../../assets/Index';

const qrOption = {
  logoImage: Images.fitcoLogoRounded,
  logoWidth: 50,
  logoHeight: 50,
  size: 360,
  ecLevel: 'Q',
};

class ModalOrderQris extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  componentWillUnmount() {}

  renderQrCode = singleItem => {
    const { data } = this.props;

    return (
      <Grid
        container
        spacing={4}
        className={`p-16 container-qr-code-parent ${clsx({
          'scroll-container': !singleItem,
        })} mv-15`}
        justify="center"
      >
        <div className="container-qr-code">
          <QRCode
            value={data}
            logoImage={qrOption.logoImage}
            logoWidth={qrOption.logoWidth}
            logoHeight={qrOption.logoHeight}
            size={qrOption.size}
            ecLevel={qrOption.ecLevel}
          />
        </div>
      </Grid>
    );
  };

  render() {
    const { onClose, isOpen, data } = this.props;
    const singleItem = data.length === 1;
    const modifyModalSize = singleItem ? 'small' : 'medium';

    const renderElement = (
      <Grid
        container
        direction="column"
        className="flex-wrap-unset container-modal-eat-menu-qr-code"
      >
        <Grid item lg md className="section-header-modal">
          <Grid container justify="space-between">
            <Grid item lg={11} md={11}>
              <h5 className="wrapping-container second line">QRIS Code</h5>
            </Grid>
            <Grid item>
              <div className="container-button-close">
                <IconButton onClick={onClose}>
                  <i className="ic-ffo-close container-icon-prefix size-21" />
                </IconButton>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg md className="section-body-modal">
          {this.renderQrCode(singleItem)}
        </Grid>
      </Grid>
    );

    return (
      <ModalInformationPopUp
        isOpen={isOpen}
        onClose={onClose}
        customElementProps={renderElement}
        size={modifyModalSize}
      />
    );
  }
}

const mapDispatchToProps = () => ({});

const mapStateToProps = () => ({});

ModalOrderQris.propTypes = {
  data: PropTypes.array,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalOrderQris);
