import React from 'react';
import PropTypes from 'prop-types';
import { Chip } from '@material-ui/core';
// Style
import './ChipFormStyle.scss';
import StylesJs from './ChipFormStyle';

const ChipForm = props => {
  const { data, onButtonClickDelete } = props;
  const classes = StylesJs();

  return (
    <div className={classes.root}>
      {data.map((item, index) => (
        <Chip
          key={index}
          className="container-chip"
          label={item.name}
          variant="outlined"
          color="primary"
          onDelete={() => onButtonClickDelete(item)}
        />
      ))}
    </div>
  );
};

ChipForm.propTypes = {
  data: PropTypes.array,
  onButtonClickDelete: PropTypes.func,
};

export default ChipForm;
