import React from 'react';
import _ from 'lodash';
// Style
import './TooltipChatDailyCheckInStyle.scss';
import { CommonHelper } from '../../../../../helpers/Index';

const TooltipChatDailyCheckIn = ({ active, payload, label }) => {
  let renderElement = <div />;

  if (active && !_.isEmpty(payload)) {
    const date = CommonHelper.dateTimeParseNewFormat(payload[0].payload.date, 'DD MMM YYYY');

    renderElement = (
      <div className="custom-tooltip">
        <label className="text-8 pt-8">{date}</label>
        <label className="custom-label-tooltip text-10 pt-8">
          <span className="dot cancel" />
          {`${payload[0].value} Sessions`}
        </label>
        <label className="custom-label-tooltip text-10 pt-8">
          <span className="dot appointment" />
          {`${payload[1].value} Sessions`}
        </label>
        <label className="custom-label-tooltip text-10 pt-8">
          <span className="dot conduct" />
          {`${payload[2].value} Sessions`}
        </label>
      </div>
    );
  }

  return renderElement;
};

export default TooltipChatDailyCheckIn;
