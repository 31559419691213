/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { PDFDownloadLink, BlobProvider } from '@react-pdf/renderer';
import _ from 'lodash';
import { saveAs } from 'file-saver';
// Style
import './GeneratorHtmlToPdfStyle.scss';
// component
import { ButtonMain, ButtonIconMain } from '../../Index';
import { CanvasShippingThreeRow, CanvasPortraitThreeRow } from './components/Index';
import { GeneratorHelper } from '../../../helpers/Index';

const GeneratorHtmlToPdf = props => {
  const {
    mode,
    fileName,
    data,
    buttonSize,
    buttonType,
    buttonLabel,
    customLabel,
    option,
    functionRetrieveData,
    targetId,
    isButtonIcon,
    iconPrefix,
  } = props;

  const [valueState, setValue] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [valueCanvasState, setValueCanvas] = React.useState('');

  const MyDocument = () => {
    let renderElement = <CanvasShippingThreeRow data={valueCanvasState} />;

    switch (customLabel) {
      case 'canvas-shipping-03':
        renderElement = <CanvasShippingThreeRow data={valueCanvasState} />;
        break;
      case 'canvas-portrait-three-row':
        renderElement = <CanvasPortraitThreeRow data={valueCanvasState} />;
        break;

      default:
        break;
    }

    return renderElement;
  };

  const handleButtonClick = async () => {
    await setIsLoading(true);

    if (!functionRetrieveData) {
      GeneratorHelper.htmlToCanvas(targetId, option, async response => {
        if (!valueState) {
          setValueCanvas(response);
        }
        await setValue(true);
      });
    } else {
      await functionRetrieveData(data, async response => {
        if (response) {
          if (!valueState) {
            setValueCanvas(response);
          }
          await setValue(true);
        }
      });
    }
  };

  const PdfAction = () => {
    let renderElement = (
      <BlobProvider document={<MyDocument />}>
        {({ blob, url, loading, error }) => {
          if (!loading) {
            const file = new Blob([blob], { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);
            setTimeout(() => {
              setValue(false);
              setIsLoading(false);
            }, 2000);
          }
          return <div />;
        }}
      </BlobProvider>
    );

    if (mode === 'download') {
      renderElement = (
        <PDFDownloadLink document={<MyDocument />} fileName={fileName}>
          {({ blob, url, loading, error }) =>
            // loading && !_.isEmpty(data) ? (
            //   'Loading document...'
            // ) : (
            //   <ButtonMain type={buttonType} size={buttonSize} labelText={buttonLabel} />
            // )
            {
              if (!loading) {
                saveAs(blob, fileName);
                setTimeout(() => {
                  setValue(false);
                  setIsLoading(false);
                }, 2000);
              }
            }
          }
        </PDFDownloadLink>
      );
    }

    return renderElement;
  };

  const RenderButton = () => {
    let renderElement = (
      <ButtonMain
        type={buttonType}
        size={buttonSize}
        labelText={buttonLabel}
        onClick={handleButtonClick}
        isLoading={valueState || isLoading}
      />
    );

    if (isButtonIcon) {
      renderElement = (
        <ButtonIconMain
          icon={iconPrefix}
          type={buttonType}
          size={buttonSize}
          onClick={handleButtonClick}
          isLoading={valueState || isLoading}
        />
      );
    }

    return renderElement;
  };

  return (
    <div>
      <RenderButton />
      {valueState && <PdfAction />}
    </div>
  );
};

GeneratorHtmlToPdf.propTypes = {
  buttonLabel: PropTypes.string,
  buttonSize: PropTypes.string,
  buttonType: PropTypes.string,
  customLabel: PropTypes.string.isRequired,
  data: PropTypes.array,
  fileName: PropTypes.string,
  functionRetrieveData: PropTypes.func,
  iconPrefix: PropTypes.string,
  isButtonIcon: PropTypes.bool,
  mode: PropTypes.string,
  option: PropTypes.object,
  targetId: PropTypes.string,
};

GeneratorHtmlToPdf.defaultProps = {
  buttonLabel: 'Download',
  buttonType: 'ghost',
  fileName: 'download.pdf',
  mode: 'print',
  isButtonIcon: false,
};

export default GeneratorHtmlToPdf;
