import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Grid, Breadcrumbs, Link } from '@material-ui/core';
import { compose } from 'redux';
// style
import './MerchantDetailsStyle.scss';
// component
import { 
  SnackBarSimple, 
  ButtonMain, 
  GridRowTableOneColumn, 
  AuthenticationAccessPages, 
  PrevStateValue,
  SkeletonDetailsV03, 
} from '../../../../../components/Index';
// api
import { getMerchantDetails } from '../../../../../services/api/MasterDataMainAPi';
// helper
import { MasterDataHelper, CommonHelper, PermissionModule, PermissionPage, PermissionAccess } from '../../../../../helpers/Index';

const initialForm = {
  merchantId: null,
  merchantName: '',
  companyId: 0,
  companyName: '',
  systemIntegrated: null,
  bankName: '',
  bankAccountName: '',
  bankAccountNumber: '',
  commission: 0,
  status: null,
};

const optionToash = {
  vertical: 'top',
  horizontal: 'right',
};

const radioButtonOption = MasterDataHelper.radioButtonTrueFalse;
const radioButtonStatusOption = MasterDataHelper.statusGlobal;

class MerchantDetails extends React.Component {
  constructor(props) {
    super(props);

    props.checkUserAccessPermission(
      PermissionModule.MasterData,
      PermissionPage.Merchant,
      PermissionAccess.View,
    );

    this.state = {
      form: initialForm,
      isLoading: false,
      toastInformation: {
        isOpen: false,
        message: '',
        snackbarType: 'warning',
      },
    };
  }

  componentDidMount() {
    this.getMerchantDetails();
  }

  componentWillUnmount() {}

  getMerchantDetails() {
    const {
      merchantDetails,
      match: { params },
    } = this.props;
    const { form } = this.state;
    const merchantId = params.merchant_id;
    const param = { merchant_id: merchantId };

    merchantDetails(param)
      .then(async response => {
        const data = response.data;
        const index = radioButtonStatusOption.findIndex(item => item.value === data.status);
        const indexSystem = radioButtonOption.findIndex(
          item => item.value === data.is_system_integrated,
        );

        this.setState({
          form: {
            ...form,
            merchantId: data.merchant_id,
            merchantName: data.name,
            companyId: data.company_id,
            companyName: data.company_name,
            bankName: data.bank_name,
            bankAccountName: data.bank_account_name,
            bankAccountNumber: data.bank_account_number,
            commission: `${data.fitco_commission}%`,
            systemIntegrated: radioButtonOption[indexSystem].name,
            status: radioButtonStatusOption[index].name,
          },
        });
      })
      .catch(error => {
        const message = error.data;
        this.processMessage(message.messages, 'error');
      });
  }

  handleCloseToash = () => {
    const { toastInformation } = this.state;
    this.setState({ toastInformation: { ...toastInformation, isOpen: false } });
  };

  handleClick = (event, value) => {
    const { history } = this.props;
    event.preventDefault();
    history.push(value);
  };

  handleButtonBack = () => {
    const { history } = this.props;
    history.push('/master-data/main-data');
  };

  handleButtonEdit = () => {
    const { history } = this.props;
    const {
      form: { merchantId },
    } = this.state;

    history.push(`/master-data/main-data/merchant/edit/${merchantId}`);
  };

  processMessage(messages, type) {
    const convertedMessage = CommonHelper.generateMessage(messages);

    const paramInformation = {
      isOpen: true,
      message: convertedMessage,
      snackbarType: type,
    };

    this.setState({
      toastInformation: paramInformation,
    });
  }

  render() {
    const {
      toastInformation,
      form: {
        merchantName,
        companyName,
        bankName,
        bankAccountName,
        bankAccountNumber,
        commission,
        systemIntegrated,
        status,
      },
      isLoading,
    } = this.state;

    let renderElement = <SkeletonDetailsV03 />;

    if (!isLoading) {
      renderElement = (
        <div>
          <Helmet title="FITCO | Master Data - Merchant Details" />
          <div className="container-page-merchant-details scroll-container">
            <div className="container-page-scrolling-area">
              <Grid container direction="column" className="flex-wrap-unset">
                <Grid item lg md className="section-page-header">
                  <div className="breadcrumbs-section">
                    <Breadcrumbs aria-label="breadcrumb" className="text-12">
                      <Link
                        className="text-12"
                        color="inherit"
                        href="/master-data/main-data"
                        onClick={event => {
                          this.handleClick(event, '/master-data/main-data');
                        }}
                      >
                        <i className="ic-ffo-master-data container-icon-prefix size-16" /> Main Data
                      </Link>
                      <label className="text-12" color="inherit">
                        Merchant details
                      </label>
                    </Breadcrumbs>
                  </div>
                </Grid>
                <Grid item lg md className="section-page-body">
                  <div className="merchant-details-card">
                    <Grid container spacing={3}>
                      <Grid item lg md>
                        <Grid
                          container
                          direction="column"
                          className="container-row-table flex-wrap-unset"
                        >
                          <Grid item>
                            <Grid container justify="flex-end" spacing={3}>
                              <Grid item lg={2} md={2}>
                                <ButtonMain
                                  type="ghost"
                                  size="xl"
                                  labelText="Back"
                                  onClick={this.handleButtonBack}
                                />
                              </Grid>
                              <Grid item lg={2} md={2}>
                                <ButtonMain
                                  type="ghost"
                                  size="xl"
                                  labelText="Edit"
                                  onClick={this.handleButtonEdit}
                                  requiredPermission={`${PermissionModule.MasterData}.${PermissionPage.Merchant}.${PermissionAccess.Update}`}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item className="title-table">
                            <label className="text-12">Merchant Information</label>
                          </Grid>
                          <Grid item>
                            <GridRowTableOneColumn
                              customElementInitial={<label className="text-14">Merchant</label>}
                              customElementColumn={
                                <label className="text-14">{merchantName || '-'}</label>
                              }
                            />
                            <GridRowTableOneColumn
                              customElementInitial={<label className="text-14">Company</label>}
                              customElementColumn={
                                <label className="text-14">{companyName || '-'}</label>
                              }
                            />
                            <GridRowTableOneColumn
                              customElementInitial={<label className="text-14">Status</label>}
                              customElementColumn={
                                <label className="text-14">{status || '-'}</label>
                              }
                            />
                            <GridRowTableOneColumn
                              customElementInitial={
                                <label className="text-14">System Integrated</label>
                              }
                              customElementColumn={
                                <label className="text-14">{systemIntegrated || '-'}</label>
                              }
                            />
                          </Grid>
                          <Grid item className="title-table margin-space">
                            <label className="text-12">Bank Information</label>
                          </Grid>
                          <Grid item>
                            <GridRowTableOneColumn
                              customElementInitial={<label className="text-14">Bank Name</label>}
                              customElementColumn={
                                <label className="text-14">{bankName || '-'}</label>
                              }
                            />
                            <GridRowTableOneColumn
                              customElementInitial={
                                <label className="text-14">Bank Account Name</label>
                              }
                              customElementColumn={
                                <label className="text-14">{bankAccountName || '-'}</label>
                              }
                            />
                            <GridRowTableOneColumn
                              customElementInitial={
                                <label className="text-14">Bank Account Number</label>
                              }
                              customElementColumn={
                                <label className="text-14">{bankAccountNumber || '-'}</label>
                              }
                            />
                            <GridRowTableOneColumn
                              customElementInitial={
                                <label className="text-14">Fitco Commission</label>
                              }
                              customElementColumn={
                                <label className="text-14">{commission || '-'}</label>
                              }
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
                <Grid item lg md className="section-page-footer" />
              </Grid>
            </div>
          </div>
          <SnackBarSimple
            open={toastInformation.isOpen}
            durationHide={2000}
            message={toastInformation.message}
            onClickClose={this.handleCloseToash}
            snackbarType={toastInformation.snackbarType}
            anchor={optionToash}
          />
        </div>
      );
    }

    return renderElement;
  }
}

const mapDispatchToProps = dispatch => ({
  merchantDetails: params => getMerchantDetails(dispatch, params),
});

const mapStateToProps = ({ masterData }) => ({ masterData });

MerchantDetails.propTypes = {
  checkUserAccessPermission: PropTypes.func,
  history: PropTypes.object,
  match: PropTypes.object,
  merchantDetails: PropTypes.func,
};

const shell = compose(connect(mapStateToProps, mapDispatchToProps));
const core = compose(AuthenticationAccessPages, PrevStateValue);

export default shell(core(MerchantDetails));

