import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Skeleton } from '@material-ui/lab';
import { CircularProgress } from '@material-ui/core';
import _ from 'lodash';
// component
import { SelectInputSearchMain } from '../../Index';
// helper
import SelectSearchMultiple from '../../presentational/select-search-multiple/SelectSearchMultiple';
// api
import { searchNearestEatStoreLocation } from '../../../services/api/EatApi';

class SelectInputStoreLocation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
        listItem: []
    };
  }

  componentDidMount() {
    this.getStoreLocation();
  }

  getStoreLocation = async () => {
    try {
      const { getEatStoreLocation } = this.props;

      const { data } = await getEatStoreLocation('', '');

      const listItem = data.map(item => ({ ...item, value: item.branch_id }));
      this.setState({ listItem });

    } catch (error) {
      const errors = error.data;
      this.processMessage(errors.messages, 'error');
    }
  };

  handleSelectItem = value => {
    const { onChange } = this.props;

    onChange(value);
  };


  renderSelector() {
    const {
      placeHolder,
      defaultValue,
      currentValue,
      errorMessage,
      validateStatus,
      size,
      isMultipleSelection,
      disabled,
      masterData: { fetching },
    } = this.props;

    const { listItem } = this.state;

    let renderElement = (
      <Skeleton
        variant="rect"
        width="100%"
        height={40}
        animation="wave"
        className="skeletonRounded"
      />
    );

    if (isMultipleSelection) {
      renderElement = (
        <SelectSearchMultiple
          options={listItem}
          onChange={item => {
            this.handleSelectItem(item);
          }}
          notFoundContent={fetching && <CircularProgress size={14} />}
          currentValue={currentValue}
          defaultValue={defaultValue}
          size={size}
          disabled={!!_.isEmpty(listItem) || disabled}
          placeholder={placeHolder}
          errorMessage={errorMessage}
          validateStatus={validateStatus}
        />
      );
    } else {
      renderElement = (
        <SelectInputSearchMain
          options={listItem}
          onChange={item => {
            this.handleSelectItem(item);
          }}
          currentValue={currentValue}
          defaultValue={defaultValue}
          size={size}
          disabled={!!_.isEmpty(listItem) || disabled}
          placeholder={placeHolder}
          errorMessage={errorMessage}
          validateStatus={validateStatus}
        />
      );
    }

    return renderElement;
  }

  render() {
    return this.renderSelector();
  }
}

const mapDispatchToProps = dispatch => ({
  getEatStoreLocation: (lat, lng) => searchNearestEatStoreLocation(dispatch, lat, lng),
});

const mapStateToProps = ({ masterData }) => ({ masterData });

SelectInputStoreLocation.propTypes = {
  currentValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
  disabled: PropTypes.bool,
  errorMessage: PropTypes.string,
  getEatStoreLocation: PropTypes.func,
  isMultipleSelection: PropTypes.bool,
  masterData: PropTypes.object,
  onChange: PropTypes.func,
  placeHolder: PropTypes.string,
  size: PropTypes.string,
  validateStatus: PropTypes.string,
};

SelectInputStoreLocation.defaultProps = {
  placeHolder: '',
  isMultipleSelection: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectInputStoreLocation);
