import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
// component
import { SelectInputSearchMain } from '../../Index';
// api
import {
  getBranchList,
  getConsultationBranchList,
  getMedicConsultationDetails,
} from '../../../services/api/MasterDataApi';
import { CommonHelper } from '../../../helpers/Index';

class SelectInputBranch extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    this.getMasterDataList();
  }

  componentDidUpdate(prevProps) {
    const { filter } = this.props;

    const isEqualMerchantId = _.isEqual(prevProps.filter.merchantId, filter.merchantId);

    if (!isEqualMerchantId) {
      this.getMasterDataList();
    }
  }

  componentWillUnmount() {}

  getMasterDataList() {
    const {
      apiDataList,
      apiDataListConsultation,
      medicConsultationDetails,
      filter,
      isMedical,
      isMedicalSchedule,
    } = this.props;

    if (isMedicalSchedule) {
      apiDataListConsultation();
      return;
    }

    if (isMedical) {
      medicConsultationDetails();
      return;
    }

    if (filter.merchantId) {
      const param = {
        merchant_id: filter.merchantId,
        category: filter.category,
        status: filter.status,
      };

      apiDataList(param);
    }
  }

  handleSelectItem = value => {
    const { onChange } = this.props;

    onChange(value);
  };

  generateListDataDisplay() {
    const { masterData, includeAllData, isMedical } = this.props;
    const converted = [];
    let param = [];
    let currentData = CommonHelper.renameKeyName(masterData.branchList, 'branch_id', 'value');
    if (isMedical) {
      currentData = CommonHelper.renameKeyName(
        masterData.medicConsultation.branches,
        'branch_id',
        'value',
      );
    }

    if (includeAllData) {
      param = { value: null, name: 'Select Branch' };
      converted.push(param);
    }

    if (currentData.length > 0) {
      currentData.forEach(item => {
        param = { value: item.value, name: item.name };
        converted.push(param);
      });
    }

    return converted;
  }

  render() {
    const {
      placeHolder,
      defaultValue,
      currentValue,
      errorMessage,
      validateStatus,
      size,
      disabled,
    } = this.props;

    const ListCustom = this.generateListDataDisplay();

    return (
      <SelectInputSearchMain
        options={ListCustom}
        onChange={item => {
          this.handleSelectItem(item);
        }}
        currentValue={currentValue}
        defaultValue={defaultValue}
        errorMessage={errorMessage}
        validateStatus={validateStatus}
        size={size}
        placeholder={placeHolder}
        disabled={disabled}
      />
    );
  }
}

const mapDispatchToProps = dispatch => ({
  apiDataList: params => getBranchList(dispatch, params),
  apiDataListConsultation: params => getConsultationBranchList(dispatch, params),
  medicConsultationDetails: () => getMedicConsultationDetails(dispatch),
});

const mapStateToProps = ({ masterData }) => ({
  masterData,
});

SelectInputBranch.propTypes = {
  apiDataList: PropTypes.func,
  apiDataListConsultation: PropTypes.func,
  currentValue: PropTypes.number,
  defaultValue: PropTypes.number,
  disabled: PropTypes.bool,
  errorMessage: PropTypes.string,
  filter: PropTypes.object,
  includeAllData: PropTypes.bool,
  isMedical: PropTypes.bool,
  isMedicalSchedule: PropTypes.bool,
  masterData: PropTypes.object,
  medicConsultationDetails: PropTypes.func,
  onChange: PropTypes.func,
  placeHolder: PropTypes.string,
  size: PropTypes.string,
  validateStatus: PropTypes.string,
};

SelectInputBranch.defaultProps = {
  placeHolder: '',
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectInputBranch);
