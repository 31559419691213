import React from 'react';
import { XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, BarChart, Bar } from 'recharts';
import PropTypes from 'prop-types';
// Style
import './ChartBarGraphicStyle.scss';
import { Skeleton } from '@material-ui/lab';
// import Rectangle from 'recharts/lib/shape/Rectangle';

const ChartBarGraphic = props => {
  const {
    dataArray,
    dataArrayLine,
    tooltipCustom,
    tickYCustom,
    height,
    targetDataX,
    isLoading,
  } = props;

  let renderElement = (
    <Skeleton
      variant="rect"
      width="100%"
      height={height}
      animation="wave"
      className="skeletonRounded"
    />
  );

  if (!isLoading) {
    renderElement = (
      <ResponsiveContainer width="100%" height={height}>
        <BarChart width={400} height={400} data={dataArray}>
          <XAxis dataKey={targetDataX} />
          <YAxis tick={tickYCustom} />
          <Tooltip content={tooltipCustom} />
          <CartesianGrid vertical={false} />
          {dataArrayLine.map((item, index) => (
            <Bar
              key={index}
              stackId="0"
              stackOffset="wiggle"
              dataKey={item.name}
              fill={item.lineColor}
              maxBarSize={8}
              radius={item.radius}
            />
          ))}
        </BarChart>
      </ResponsiveContainer>
    );
  }

  return renderElement;
};

ChartBarGraphic.propTypes = {
  dataArray: PropTypes.array,
  dataArrayLine: PropTypes.array,
  height: PropTypes.number,
  isLoading: PropTypes.bool,
  targetDataX: PropTypes.string,
  targetDataY: PropTypes.string,
  tickYCustom: PropTypes.element,
  tooltipCustom: PropTypes.element,
};

export default ChartBarGraphic;
