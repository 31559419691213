import { makeStyles } from '@material-ui/core/styles';

const StylesJs = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 24,
    height: '100%',
  },
  paper: {
    backgroundColor: theme.palette.white,
    boxShadow: theme.shadows[5],
    borderRadius: 10,
  },
}));

export default StylesJs;
