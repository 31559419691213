export const constants = {
  setListSummary: 'SET_LIST_SUMMARY',
  getSummarySalesMoveRequest: 'GET_SUMMARY_SALES_MOVE_REQUEST',
  getSummarySalesMoveSuccess: 'GET_SUMMARY_SALES_MOVE_SUCCESS',
  getSummarySalesMoveFail: 'GET_SUMMARY_SALES_MOVE_FAIL',
  getSummaryRecurringMembershipRequest: 'GET_SUMMARY_RECURRING_MEMBERSHIP_REQUEST',
  getSummaryRecurringMembershipSuccess: 'GET_SUMMARY_RECURRING_MEMBERSHIP_SUCCESS',
  getSummaryRecurringMembershipFail: 'GET_SUMMARY_RECURRING_MEMBERSHIP_FAIL',
  getSummaryConductRequest: 'GET_SUMMARY_CONDUCT_REQUEST',
  getSummaryConductSuccess: 'GET_SUMMARY_CONDUCT_SUCCESS',
  getSummaryConductFail: 'GET_SUMMARY_CONDUCT_FAIL',
  getSummaryExpiredMembershipRequest: 'GET_SUMMARY_EXPIRE_MEMBERSHIP_REQUEST',
  getSummaryExpiredMembershipSuccess: 'GET_SUMMARY_EXPIRE_MEMBERSHIP_SUCCESS',
  getSummaryExpiredMembershipFail: 'GET_SUMMARY_EXPIRE_MEMBERSHIP_FAIL',
  getDailyReportStatisticRequest: 'GET_DAILY_REPORT_STATISTIC_REQUEST',
  getDailyReportStatisticSuccess: 'GET_DAILY_REPORT_STATISTIC_SUCCESS',
  getDailyReportStatisticFail: 'GET_DAILY_REPORT_STATISTIC_FAIL',
  getMembershipStatusRecurringListPaginationRequest:
    'GET_MEMBERSHIP_STATUS_RECURRING_LIST_PAGINATION_REQUEST',
  getMembershipStatusRecurringListPaginationSuccess:
    'GET_MEMBERSHIP_STATUS_RECURRING_LIST_PAGINATION_SUCCESS',
  getMembershipStatusRecurringListPaginationFail:
    'GET_MEMBERSHIP_STATUS_RECURRING_LIST_PAGINATION_FAIL',
  getMembershipStatusRecurringPrintRequest: 'GET_MEMBERSHIP_STATUS_RECURRING_PRINT_REQUEST',
  getMembershipStatusRecurringPrintSuccess: 'GET_MEMBERSHIP_STATUS_RECURRING_PRINT_SUCCESS',
  getMembershipStatusRecurringPrintFail: 'GET_MEMBERSHIP_STATUS_RECURRING_PRINT_FAIL',
  getRecentCheckInListPaginationRequest: 'GET_RECENT_CHECK_IN_LIST_PAGINATION_REQUEST',
  getRecentCheckInListPaginationSuccess: 'GET_RECENT_CHECK_IN_LIST_PAGINATION_SUCCESS',
  getRecentCheckInListPaginationFail: 'GET_RECENT_CHECK_IN_LIST_PAGINATION_FAIL',
  getTopSpenderListRequest: 'GET_TOP_SPENDER_LIST_REQUEST',
  getTopSpenderListSuccess: 'GET_TOP_SPENDER_LIST_SUCCESS',
  getTopSpenderListFail: 'GET_TOP_SPENDER_LIST_FAIL',
  getTopSellingMembershipListRequest: 'GET_TOP_SELLING_MEMBERSHIP_LIST_REQUEST',
  getTopSellingMembershipListSuccess: 'GET_TOP_SELLING_MEMBERSHIP_LIST_SUCCESS',
  getTopSellingMembershipListFail: 'GET_ TOP_SELLING_MEMBERSHIP_LIST_FAIL',
  getDemoGraphicBookListRequest: 'GET_DEMOGRAPHIC_BOOK_LIST_REQUEST',
  getDemoGraphicBookListSuccess: 'GET_DEMOGRAPHIC_BOOK_LIST_SUCCESS',
  getDemoGraphicBookListFail: 'GET_DEMOGRAPHIC_BOOK_LIST_FAIL',
};

const initState = {
  fetchingSalesMove: false,
  fetchingRecurringMembership: false,
  fetchingConduct: false,
  fetchingExpiredMembership: false,
  fetchingListRecurring: false,
  fetchingGraphic: false,
  fetchingCheckIn: false,
  fetchingTopSpender: false,
  fetchingSellingMembershipList: false,
  fetchingDemoGraphicList: false,
  fetchingPrint: false,
  listSummary: [],
  dataGraphic: {},
  listRecurring: [],
  listCheckIn: [],
  listTopSpender: [],
  listTopSellingMembership: [],
  listDemoGraphicBook: [],
  documentPrint: [],
  errorMessage: null,
  responseMessage: null,
  details: {},
};

export default function dashboardReducer(state = initState, action) {
  const { type, payload } = action;
  switch (type) {
    case constants.setListSummary:
      return { ...state, listSummary: payload };

    case constants.getSummarySalesMoveRequest:
      return { ...state, fetchingSalesMove: true, responseMessage: null };
    case constants.getSummarySalesMoveSuccess:
      return {
        ...state,
        fetchingSalesMove: false,
        listSummary: [...state.listSummary, { ...payload.data, order: 1 }],
      };
    case constants.getSummarySalesMoveFail:
      return { ...state, fetchingSalesMove: false, errorMessage: payload };

    case constants.getSummaryRecurringMembershipRequest:
      return { ...state, fetchingRecurringMembership: true, responseMessage: null };
    case constants.getSummaryRecurringMembershipSuccess:
      return {
        ...state,
        fetchingRecurringMembership: false,
        listSummary: [...state.listSummary, { ...payload.data, order: 2 }],
      };
    case constants.getSummaryRecurringMembershipFail:
      return { ...state, fetchingRecurringMembership: false, errorMessage: payload };

    case constants.getSummaryConductRequest:
      return { ...state, fetchingConduct: true, responseMessage: null };
    case constants.getSummaryConductSuccess:
      return {
        ...state,
        fetchingConduct: false,
        listSummary: [...state.listSummary, { ...payload.data, order: 3 }],
      };
    case constants.getSummaryConductFail:
      return { ...state, fetchingConduct: false, errorMessage: payload };

    case constants.getSummaryExpiredMembershipRequest:
      return { ...state, fetchingExpiredMembership: true, responseMessage: null };
    case constants.getSummaryExpiredMembershipSuccess:
      return {
        ...state,
        fetchingExpiredMembership: false,
        listSummary: [...state.listSummary, { ...payload.data, order: 4 }],
      };
    case constants.getSummaryExpiredMembershipFail:
      return { ...state, fetchingExpiredMembership: false, errorMessage: payload };

    case constants.getDailyReportStatisticRequest:
      return { ...state, fetchingGraphic: true, responseMessage: null, dataGraphic: [] };
    case constants.getDailyReportStatisticSuccess:
      return {
        ...state,
        fetchingGraphic: false,
        dataGraphic: payload.data,
      };
    case constants.getDailyReportStatisticFail:
      return { ...state, fetchingGraphic: false, errorMessage: payload };

    case constants.getMembershipStatusRecurringListPaginationRequest:
      return { ...state, fetchingListRecurring: true, responseMessage: null };
    case constants.getMembershipStatusRecurringListPaginationSuccess:
      return {
        ...state,
        fetchingListRecurring: false,
        listRecurring: payload.data.data,
      };
    case constants.getMembershipStatusRecurringListPaginationFail:
      return { ...state, fetchingListRecurring: false, errorMessage: payload };

    case constants.getMembershipStatusRecurringPrintRequest:
      return { ...state, fetchingPrint: true, responseMessage: null, documentPrint: [] };
    case constants.getMembershipStatusRecurringPrintSuccess:
      return {
        ...state,
        fetchingPrint: false,
        documentPrint: payload.data,
      };
    case constants.getMembershipStatusRecurringPrintFail:
      return { ...state, fetchingPrint: false, errorMessage: payload, documentPrint: [] };

    case constants.getRecentCheckInListPaginationRequest:
      return { ...state, fetchingCheckIn: true, responseMessage: null };
    case constants.getRecentCheckInListPaginationSuccess:
      return {
        ...state,
        fetchingCheckIn: false,
        listCheckIn: payload.data.data,
      };
    case constants.getRecentCheckInListPaginationFail:
      return { ...state, fetchingCheckIn: false, errorMessage: payload };

    case constants.getTopSpenderListRequest:
      return { ...state, fetchingTopSpender: true, responseMessage: null };
    case constants.getTopSpenderListSuccess:
      return {
        ...state,
        fetchingTopSpender: false,
        listTopSpender: payload.data || [],
      };
    case constants.getTopSpenderListFail:
      return { ...state, fetchingTopSpender: false, errorMessage: payload };

    case constants.getTopSellingMembershipListRequest:
      return { ...state, fetchingSellingMembershipList: true, responseMessage: null };
    case constants.getTopSellingMembershipListSuccess:
      return {
        ...state,
        fetchingSellingMembershipList: false,
        listTopSellingMembership: payload.data || [],
      };
    case constants.getTopSellingMembershipListFail:
      return { ...state, fetchingSellingMembershipList: false, errorMessage: payload };

    case constants.getDemoGraphicBookListRequest:
      return { ...state, fetchingDemoGraphicList: true, responseMessage: null };
    case constants.getDemoGraphicBookListSuccess:
      return {
        ...state,
        fetchingDemoGraphicList: false,
        listDemoGraphicBook: payload.data || [],
      };
    case constants.getDemoGraphicBookListFail:
      return { ...state, fetchingDemoGraphicList: false, errorMessage: payload };

    default:
      return state;
  }
}
