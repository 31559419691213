import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid, Breadcrumbs, Link, FormControl, FormLabel } from '@material-ui/core';
import { compose } from 'redux';
// style
import './MerchantAddStyle.scss';
// component
import {
  TextInput,
  SnackBarSimple,
  SelectInputCompany,
  ButtonMain,
  RadioInput,
  TextInputPrePost,
  AuthenticationAccessPages,
  PrevStateValue,
} from '../../../../../components/Index';
// api
import { setCreateMerchant } from '../../../../../services/api/MasterDataMainAPi';
// helper
import {
  MasterDataHelper,
  CommonHelper,
  ValidationHelper,
  PermissionModule,
  PermissionPage,
  PermissionAccess,
} from '../../../../../helpers/Index';

const optionToash = {
  vertical: 'top',
  horizontal: 'right',
};

const resetValidation = { isError: false, errorMessage: '' };

const radioButtonOption = MasterDataHelper.radioButtonTrueFalse;
const radioButtonStatusOption = MasterDataHelper.statusGlobal.slice(
  0,
  MasterDataHelper.statusGlobal.length - 1,
);

const initialValidation = {
  merchantName: { isError: false, errorMessage: '' },
  bankName: { isError: false, errorMessage: '' },
  bankAccountName: { isError: false, errorMessage: '' },
  bankAccountNumber: { isError: false, errorMessage: '' },
};

const initialForm = {
  merchantName: '',
  companyId: null,
  systemIntegrated: radioButtonOption[1],
  bankName: '',
  bankAccountName: '',
  bankAccountNumber: '',
  commission: 0,
  status: radioButtonStatusOption[0],
};

class MerchantAdd extends React.Component {
  constructor(props) {
    super(props);

    props.checkUserAccessPermission(
      PermissionModule.MasterData,
      PermissionPage.Merchant,
      PermissionAccess.Add,
    );

    this.state = {
      validation: initialValidation,
      form: initialForm,
      isLoading: false,
      toastInformation: {
        isOpen: false,
        message: '',
        snackbarType: 'warning',
      },
    };
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {
    const { masterData } = this.props;

    if (prevProps.masterData.companyList.length !== masterData.companyList.length) {
      this.loadDefaultValue();
    }
  }

  componentWillUnmount() {}

  handleCloseToash = () => {
    const { toastInformation } = this.state;
    this.setState({ toastInformation: { ...toastInformation, isOpen: false } });
  };

  handleClick = (event, value) => {
    const { history } = this.props;
    event.preventDefault();
    history.push(value);
  };

  handleSelectStatus = value => {
    const { form } = this.state;
    this.setState({ form: { ...form, status: value } });
  };

  handleTextChangeMerchantName = value => {
    const { validation, form } = this.state;

    const message = {
      isError: false,
      errorMessage: '',
    };

    this.setState({
      form: { ...form, merchantName: value },
      validation: { ...validation, merchantName: message },
    });
  };

  handleSelectCompany = value => {
    const { form } = this.state;

    this.setState({
      form: {
        ...form,
        companyId: value || null,
      },
    });
  };

  handleSelectSystemIntegrated = value => {
    const { form } = this.state;
    this.setState({ form: { ...form, systemIntegrated: value } });
  };

  handleTextChangeBankName = value => {
    const { validation, form } = this.state;

    const message = {
      isError: false,
      errorMessage: '',
    };

    this.setState(
      {
        form: { ...form, bankName: value },
      },
      () => {
        if (!value) {
          message.isError = true;
          message.errorMessage = 'Bank Name is Empty...';
        }
        this.setState({
          validation: { ...validation, bankName: message },
        });
      },
    );
  };

  handleTextChangeBankAccountName = value => {
    const { validation, form } = this.state;

    const message = {
      isError: false,
      errorMessage: '',
    };

    this.setState(
      {
        form: { ...form, bankAccountName: value },
      },
      () => {
        if (!value) {
          message.isError = true;
          message.errorMessage = 'Bank Account Name is Empty...';
        }
        this.setState({
          validation: { ...validation, bankAccountName: message },
        });
      },
    );
  };

  handleTextChangeBankAccountNumber = value => {
    const { validation, form } = this.state;
    const checkIsNumber = CommonHelper.checkNumberOnly(value.trim());

    const message = {
      isError: false,
      errorMessage: '',
    };

    if (checkIsNumber) {
      this.setState({
        form: { ...form, bankAccountNumber: value.trim() },
        validation: { ...validation, bankAccountNumber: message },
      });
    } else {
      message.isError = true;
      message.errorMessage = 'Bank Account Number must number';

      if (!value) {
        message.isError = true;
        message.errorMessage = 'Bank Account Number must number and not empty...';
      }
      this.setState({
        validation: { ...validation, bankAccountNumber: message },
      });
    }
  };

  handleTextChangeFitcoCommision = value => {
    const { form } = this.state;

    const commissionValidation = ValidationHelper.validateNumberPercentage(value.trim());

    if (commissionValidation) {
      this.setState({
        form: { ...form, commission: parseInt(value.trim(), 10) },
      });
    } else {
      let forceValue = 0;
      if (parseInt(value, 10) > 100) forceValue = 100;

      this.setState({
        form: { ...form, commission: forceValue },
      });
    }
  };

  handleButtonCancel = () => {
    const { history } = this.props;
    history.push('/master-data/main-data');
  };

  handleButtonSubmit = () => {
    const { createMerchant, history } = this.props;
    const { form } = this.state;

    if (this.validationEmptyField()) {
      const systemIntegrated = form.systemIntegrated.value;
      const statusCode = form.status.value;

      const param = {
        data: {
          company_id: form.companyId,
          name: form.merchantName.trim(),
          fitco_commission: form.commission,
          bank_name: form.bankName.trim(),
          bank_account_name: form.bankAccountName.trim(),
          bank_account_number: form.bankAccountNumber,
          status: statusCode,
          is_system_integrated: systemIntegrated,
        },
      };

      this.setState({ isLoading: true }, () => {
        createMerchant(param)
          .then(response => {
            const message = response.messages;

            this.processMessage(message, 'success');
            this.setState({ isLoading: false }, () => {
              setTimeout(async () => {
                await history.push('/master-data/main-data');
              }, 2000);
            });
          })
          .catch(error => {
            const message = error.data;
            this.setState({ isLoading: false });
            this.processMessage(message.messages, 'error');
          });
      });
    }
  };

  loadDefaultValue() {
    const { form } = this.state;
    const { masterData } = this.props;

    this.setState({ form: { ...form, companyId: masterData.companyList[0].company_id } });
  }

  processMessage(messages, type) {
    const convertedMessage = CommonHelper.generateMessage(messages);

    const paramInformation = {
      isOpen: true,
      message: convertedMessage,
      snackbarType: type,
    };

    this.setState({
      toastInformation: paramInformation,
    });
  }

  validationEmptyField() {
    const {
      form: { merchantName, bankName, bankAccountName, bankAccountNumber },
      validation,
    } = this.state;

    let passCheck = true;
    let paramValidation01 = resetValidation;
    let paramValidation02 = resetValidation;
    let paramValidation03 = resetValidation;
    let paramValidation04 = resetValidation;

    if (merchantName === '') {
      if (!paramValidation01.isError) {
        paramValidation01 = {
          isError: true,
          errorMessage: 'merchantName Empty...',
        };
      }
      passCheck = false;
    }

    if (bankName === '') {
      if (!paramValidation02.isError) {
        paramValidation02 = {
          isError: true,
          errorMessage: 'Bank Name Empty...',
        };
      }
      passCheck = false;
    }

    if (bankAccountName === '') {
      paramValidation03 = {
        isError: true,
        errorMessage: 'Bank Account Name Empty',
      };
      passCheck = false;
    }

    if (bankAccountNumber === '') {
      paramValidation04 = {
        isError: true,
        errorMessage: 'Bank Account Number Empty',
      };
      passCheck = false;
    }

    if (!passCheck) {
      this.setState({
        validation: {
          ...validation,
          merchantName: paramValidation01,
          bankName: paramValidation02,
          bankAccountName: paramValidation03,
          bankAccountNumber: paramValidation04,
        },
      });
    }

    return passCheck;
  }

  render() {
    const { toastInformation } = this.state;

    return (
      <div className="container-page-merchant-add  scroll-container">
        <div className="container-page-scrolling-area">
          <Grid container direction="column">
            <Grid item lg md className="section-page-header">
              <Grid container>
                <Grid item>
                  <div className="breadcrumbs-section">
                    <Breadcrumbs aria-label="breadcrumb">
                      <Link
                        className="text-12"
                        color="inherit"
                        href="/master-data/main-data"
                        onClick={event => {
                          this.handleClick(event, '/master-data/main-data');
                        }}
                      >
                        <i className="ic-ffo-branch container-icon-prefix size-16" /> Main Data
                      </Link>
                      <label className="text-12" color="inherit">
                        Create Merchant
                      </label>
                    </Breadcrumbs>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg md className="section-page-body">
              <div className="merchant-details-card">
                <Grid container spacing={3}>
                  <Grid item lg={6} md={6}>
                    <Grid container direction="column">
                      <Grid item className="main-title">
                        <label className="text-16">Merchant Information</label>
                      </Grid>
                      <Grid item>
                        <FormControl component="fieldset" fullWidth margin="normal">
                          <FormLabel component="label" className="text-12 title">
                            Merchant Name
                          </FormLabel>
                          <TextInput
                            placeHolderText="Merchant Name"
                            onChange={this.handleTextChangeMerchantName}
                            currentValue={this.state.form.merchantName}
                            errorMessage={this.state.validation.merchantName.errorMessage}
                            isError={this.state.validation.merchantName.isError}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item>
                        <FormControl component="fieldset" fullWidth margin="normal">
                          <FormLabel component="label" className="text-12 title">
                            Company Name
                          </FormLabel>
                          <div className="container-remove-margin">
                            <SelectInputCompany
                              placeHolder="Select Company"
                              currentValue={this.state.form.companyId}
                              onChange={this.handleSelectCompany}
                            />
                          </div>
                        </FormControl>
                      </Grid>
                      <Grid item>
                        <FormControl component="fieldset" fullWidth margin={'normal'}>
                          <FormLabel component="label" className="text-12 title">
                            Status
                          </FormLabel>
                          <RadioInput
                            data={radioButtonStatusOption}
                            onSelect={this.handleSelectStatus}
                            direction="column"
                            currentValue={this.state.form.status}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item>
                        <FormControl component="fieldset" fullWidth margin="normal">
                          <FormLabel component="label" className="text-12 title">
                            System Integrated
                          </FormLabel>
                          <Grid container direction="column">
                            <Grid item>
                              <div className="container-remove-margin">
                                <RadioInput
                                  data={radioButtonOption}
                                  onSelect={this.handleSelectSystemIntegrated}
                                  direction="column"
                                  defaultValue={this.state.form.systemIntegrated}
                                />
                              </div>
                            </Grid>
                            <Grid item>
                              <label className="text-12 note">
                                {`If set to Yes, all 'MOVE' bookings made to this merchant will be
                                automatically set into 'Confirmed' status, otherwise it will be on
                                'Waiting Confirmation' status`}
                              </label>
                            </Grid>
                          </Grid>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item lg={6} md={6}>
                    <Grid item className="main-title">
                      <label className="text-16">Bank Information</label>
                    </Grid>
                    <Grid item>
                      <FormControl component="fieldset" fullWidth margin="normal">
                        <FormLabel component="label" className="text-12 title">
                          Bank Name
                        </FormLabel>
                        <TextInput
                          placeHolderText="Bank Name"
                          onChange={this.handleTextChangeBankName}
                          currentValue={this.state.form.bankName}
                          errorMessage={this.state.validation.bankName.errorMessage}
                          isError={this.state.validation.bankName.isError}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item>
                      <FormControl component="fieldset" fullWidth margin="normal">
                        <FormLabel component="label" className="text-12 title">
                          Bank Account Name
                        </FormLabel>
                        <TextInput
                          placeHolderText="Bank Account Name"
                          onChange={this.handleTextChangeBankAccountName}
                          currentValue={this.state.form.bankAccountName}
                          errorMessage={this.state.validation.bankAccountName.errorMessage}
                          isError={this.state.validation.bankAccountName.isError}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item>
                      <FormControl component="fieldset" fullWidth margin="normal">
                        <FormLabel component="label" className="text-12 title">
                          Bank Account Number
                        </FormLabel>
                        <TextInput
                          placeHolderText="Bank Account Number"
                          onChange={this.handleTextChangeBankAccountNumber}
                          currentValue={this.state.form.bankAccountNumber}
                          errorMessage={this.state.validation.bankAccountNumber.errorMessage}
                          isError={this.state.validation.bankAccountNumber.isError}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item>
                      <FormControl component="fieldset" fullWidth margin="normal">
                        <FormLabel component="label" className="text-12 title">
                          Fitco Commission
                        </FormLabel>
                        <TextInputPrePost
                          placeHolderText="Fitco Commission"
                          onChange={this.handleTextChangeFitcoCommision}
                          currentValue={this.state.form.commission}
                          customElementsAfter={'%'}
                          size="large"
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid item lg md className="section-page-footer">
              <Grid container justify="flex-end" spacing={3}>
                <Grid item lg={2} md={2}>
                  <ButtonMain
                    type="ghost"
                    size="xl"
                    labelText="Cancel"
                    onClick={this.handleButtonCancel}
                  />
                </Grid>
                <Grid item lg={2} md={2}>
                  <ButtonMain
                    type="primary"
                    size="xl"
                    labelText="Create"
                    onClick={this.handleButtonSubmit}
                    isLoading={this.state.isLoading}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <SnackBarSimple
            open={toastInformation.isOpen}
            durationHide={2000}
            message={toastInformation.message}
            onClickClose={this.handleCloseToash}
            snackbarType={toastInformation.snackbarType}
            anchor={optionToash}
          />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  createMerchant: params => setCreateMerchant(dispatch, params),
});

const mapStateToProps = ({ masterData }) => ({ masterData });

MerchantAdd.propTypes = {
  checkUserAccessPermission: PropTypes.func,
  createMerchant: PropTypes.func,
  history: PropTypes.object,
  masterData: PropTypes.object,
};

const shell = compose(connect(mapStateToProps, mapDispatchToProps));
const core = compose(AuthenticationAccessPages, PrevStateValue);

export default shell(core(MerchantAdd));
