/* eslint-disable import/prefer-default-export */
import * as usersAction from '../../redux/actions/UsersAction';
import Api from '../../middleware/UploadMiddleware';
import { HttpAction } from '../../helpers/HttpHelper';

export const OCRScanner = (dispatch, body) => {
  return new Promise((resolve, reject) => {
    Api.callUploadApi('/ocr-ktp', HttpAction.Post, {}, body)
      .then(response => {
        if (response && !response.data) {
          throw response;
        }
        dispatch(usersAction.uploadOcrSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(usersAction.uploadOcrFail(e));
        reject(e);
      });
  });
};

export const SelfieScanner = body => {
  return new Promise((resolve, reject) => {
    Api.callUploadApi('/face-comparison', HttpAction.Post, {}, body)
      .then(response => {
        if (response && !response.data) {
          throw response;
        }
        return resolve(response);
      })
      .catch(e => {
        reject(e);
      });
  });
};

export const setIdentityImage = (dispatch, payload) => {
  dispatch(usersAction.setIdentityImage(payload));
};

export const setSelfieImage = (dispatch, payload) => {
  dispatch(usersAction.setSelfieImage(payload));
};

export const uploadFile = body => {
  return new Promise((resolve, reject) => {
    Object.assign(body, {
      environment: process.env.NODE_ENV === 'test' ? 'staging' : process.env.NODE_ENV,
    });
    Api.callUploadApi('/upload', HttpAction.Post, {}, body)
      .then(response => {
        if (response && !response.data) {
          throw response;
        }
        return resolve(response);
      })
      .catch(e => {
        reject(e);
      });
  });
};

export const setFaceSimilarity = (dispatch, payload) => {
  dispatch(usersAction.setFaceSimilarity(payload));
};
