export const constants = {
  // #region membership request
  getMembershipRequestPaginationRequest: 'GET_MEMBERSHIP_REQUEST_PAGINATION_REQUEST',
  getMembershipRequestPaginationSuccess: 'GET_MEMBERSHIP_REQUEST_PAGINATION_SUCCESS',
  getMembershipRequestPaginationFail: 'GET_MEMBERSHIP_REQUEST_PAGINATION_FAIL',
  getMembershipRequestDetailsRequest: 'GET_MEMBERSHIP_REQUEST_DETAILS_REQUEST',
  getMembershipRequestDetailsSuccess: 'GET_MEMBERSHIP_REQUEST_DETAILS_SUCCESS',
  getMembershipRequestDetailsFail: 'GET_MEMBERSHIP_REQUEST_DETAILS_FAIL',
  setUpdateMembershipRequestApproveDeclineRequest:
    'SET_UPDATE_MEMBERSHIP_REQUEST_APPROVE_DECLINE_REQUEST',
  setUpdateMembershipRequestApproveDeclineSuccess:
    'SET_UPDATE_MEMBERSHIP_REQUEST_APPROVE_DECLINE_SUCCESS',
  setUpdateMembershipRequestApproveDeclineFail:
    'SET_UPDATE_MEMBERSHIP_REQUEST_APPROVE_DECLINE_FAIL',
  // #endregion
  // #region membership purchase
  getMembershipPurchasePaginationRequest: 'GET_MEMBERSHIP_PURCHASE_PAGINATION_PURCHASE',
  getMembershipPurchasePaginationSuccess: 'GET_MEMBERSHIP_PURCHASE_PAGINATION_SUCCESS',
  getMembershipPurchasePaginationFail: 'GET_MEMBERSHIP_PURCHASE_PAGINATION_FAIL',
  getMembershipPurchaseDetailsRequest: 'GET_MEMBERSHIP_PURCHASE_DETAILS_PURCHASE',
  getMembershipPurchaseDetailsSuccess: 'GET_MEMBERSHIP_PURCHASE_DETAILS_SUCCESS',
  getMembershipPurchaseDetailsFail: 'GET_MEMBERSHIP_PURCHASE_DETAILS_FAIL',
  getMembershipPurchaseDetailsByPhoneRequest: 'GET_MEMBERSHIP_PURCHASE_DETAILS_BY_PHONE_REQUEST',
  getMembershipPurchaseDetailsByPhoneSuccess: 'GET_MEMBERSHIP_PURCHASE_DETAILS_BY_PHONE_SUCCESS',
  getMembershipPurchaseDetailsByPhoneFail: 'GET_MEMBERSHIP_PURCHASE_DETAILS_BY_PHONE_FAIL',
  getMembershipPurchaseRenewalListRequest: 'GET_MEMBERSHIP_PURCHASE_RENEWAL_LIST_REQUEST',
  getMembershipPurchaseRenewalListSuccess: 'GET_MEMBERSHIP_PURCHASE_RENEWAL_LIST_SUCCESS',
  getMembershipPurchaseRenewalListFail: 'GET_MEMBERSHIP_PURCHASE_RENEWAL_LIST_FAIL',
  getMembershipPurchaseNewListRequest: 'GET_MEMBERSHIP_PURCHASE_NEW_LIST_REQUEST',
  getMembershipPurchaseNewListSuccess: 'GET_MEMBERSHIP_PURCHASE_NEW_LIST_SUCCESS',
  getMembershipPurchaseNewListFail: 'GET_MEMBERSHIP_PURCHASE_NEW_LIST_FAIL',
  setProcessCreateUserMembershipRequest: 'SET_PROCESS_CREATE_USER_MEMBERSHIP_REQUEST',
  setProcessCreateUserMembershipSuccess: 'SET_PROCESS_CREATE_USER_MEMBERSHIP_SUCCESS',
  setProcessCreateUserMembershipFail: 'SET_PROCESS_CREATE_USER_MEMBERSHIP_FAIL',
  getMembershipPurchaseReportExportRequest: 'GET_MEMBERSHIP_PURCHASE_REPORT_REQUEST',
  getMembershipPurchaseReportExportSuccess: 'GET_MEMBERSHIP_PURCHASE_REPORT_SUCCESS',
  getMembershipPurchaseReportExportFail: 'GET_MEMBERSHIP_PURCHASE_REPORT_FAIL',
  // #endregion
  // #region membership change payment type
  getMembershipDetailsByIdRequest: 'GET_MEMBERSHIP_DETAILS_BY_ID_REQUEST',
  getMembershipDetailsByIdSuccess: 'GET_MEMBERSHIP_DETAILS_BY_ID_SUCCESS',
  getMembershipDetailsByIdFail: 'GET_MEMBERSHIP_DETAILS_BY_ID_FAIL',
  setMembershipRecurringByIdRequest: 'SET_MEMBERSHIP_RECURRING_BY_ID_REQUEST',
  setMembershipRecurringByIdSuccess: 'SET_MEMBERSHIP_RECURRING_BY_ID_SUCCESS',
  setMembershipRecurringByIdFail: 'SET_MEMBERSHIP_RECURRING_BY_ID_FAIL',
  stopMembershipRecurringByIdRequest: 'STOP_MEMBERSHIP_RECURRING_BY_ID_REQUEST',
  stopMembershipRecurringByIdSuccess: 'STOP_MEMBERSHIP_RECURRING_BY_ID_SUCCESS',
  stopMembershipRecurringByIdFail: 'STOP_MEMBERSHIP_RECURRING_BY_ID_FAIL',
  // #endregion
  // #region membership unfreeze
  unfreezeMembershipRequest: 'UNFREEZE_MEMBERSHIP_REQUEST',
  unfreezeMembershipSuccess: 'UNFREEZE_MEMBERSHIP_SUCCESS',
  unfreezeMembershipFail: 'UNFREEZE_MEMBERSHIP_FAIL',
  // #endregion
};

const initState = {
  fetching: false,
  fetchingExport: false,
  list: [],
  reportExport: [],
  total: 0,
  errorMessage: null,
  responseMessage: null,
  details: {},
};

export default function membershipRequest(state = initState, action) {
  const { type, payload } = action;
  switch (type) {
    // #region membership request
    case constants.getMembershipRequestPaginationRequest:
      return { ...state, fetching: true, responseMessage: null };
    case constants.getMembershipRequestPaginationSuccess:
      return {
        ...state,
        list: payload.data,
        total: payload.total,
        fetching: false,
      };
    case constants.getMembershipRequestPaginationFail:
      return { ...state, fetching: false, errorMessage: payload };

    case constants.getMembershipRequestDetailsRequest:
      return { ...state, fetching: true, responseMessage: null };
    case constants.getMembershipRequestDetailsSuccess:
      return {
        ...state,
        details: payload.data,
        fetching: false,
      };
    case constants.getMembershipRequestDetailsFail:
      return { ...state, fetching: false, errorMessage: payload };

    case constants.setUpdateMembershipRequestApproveDeclineRequest:
      return { ...state, fetching: true, responseMessage: null };
    case constants.setUpdateMembershipRequestApproveDeclineSuccess:
      return {
        ...state,
        responseMessage: payload.messages,
        fetching: false,
      };
    case constants.setUpdateMembershipRequestApproveDeclineFail:
      return { ...state, fetching: false, errorMessage: payload };
    // #endregion

    // #region membership purchase
    case constants.getMembershipPurchasePaginationRequest:
      return { ...state, fetching: true, responseMessage: null };
    case constants.getMembershipPurchasePaginationSuccess:
      return {
        ...state,
        list: payload.data,
        total: payload.total,
        fetching: false,
      };
    case constants.getMembershipPurchasePaginationFail:
      return { ...state, fetching: false, errorMessage: payload };

    case constants.getMembershipPurchaseDetailsRequest:
      return { ...state, fetching: true, responseMessage: null };
    case constants.getMembershipPurchaseDetailsSuccess:
      return {
        ...state,
        details: payload.data,
        fetching: false,
      };
    case constants.getMembershipPurchaseDetailsFail:
      return { ...state, fetching: false, errorMessage: payload };

    case constants.getMembershipPurchaseDetailsByPhoneRequest:
      return { ...state, fetching: true, responseMessage: null };
    case constants.getMembershipPurchaseDetailsByPhoneSuccess:
      return {
        ...state,
        details: payload.data,
        fetching: false,
      };
    case constants.getMembershipPurchaseDetailsByPhoneFail:
      return { ...state, fetching: false, errorMessage: payload };

    case constants.getMembershipPurchaseRenewalListRequest:
      return { ...state, fetching: true, responseMessage: null };
    case constants.getMembershipPurchaseRenewalListSuccess:
      return {
        ...state,
        list: payload.data,
        fetching: false,
      };
    case constants.getMembershipPurchaseRenewalListFail:
      return { ...state, fetching: false, errorMessage: payload };

    case constants.getMembershipPurchaseNewListRequest:
      return { ...state, fetching: true, responseMessage: null };
    case constants.getMembershipPurchaseNewListSuccess:
      return {
        ...state,
        list: payload.data,
        fetching: false,
      };
    case constants.getMembershipPurchaseNewListFail:
      return { ...state, fetching: false, errorMessage: payload };

    case constants.setProcessCreateUserMembershipRequest:
      return { ...state, fetching: true };
    case constants.setProcessCreateUserMembershipSuccess:
      return {
        ...state,
        fetching: false,
      };
    case constants.setProcessCreateUserMembershipFail:
      return {
        ...state,
        fetching: false,
        errorFetch: true,
        errorMessage: payload,
      };

    case constants.getMembershipPurchaseReportExportRequest:
      return {
        ...state,
        fetchingExport: true,
        responseMessage: null,
        reportExport: [],
      };
    case constants.getMembershipPurchaseReportExportSuccess:
      return {
        ...state,
        reportExport: payload.data,
        fetchingExport: false,
      };
    case constants.getMembershipPurchaseReportExportFail:
      return { ...state, fetchingExport: false, errorMessage: payload, reportExport: [] };
    // #endregion

    // #region membership change payment type
    case constants.getMembershipDetailsByIdRequest:
      return {
        ...state,
        fetching: true,
        responseMessage: null,
      };
    case constants.getMembershipDetailsByIdSuccess:
      return {
        ...state,
        details: payload.data,
        fetching: false,
      };
    case constants.getMembershipDetailsByIdFail:
      return { ...state, fetching: false, errorMessage: payload };

    case constants.setMembershipRecurringByIdRequest:
      return { ...state, fetching: true, responseMessage: null };
    case constants.setMembershipRecurringByIdSuccess:
      return {
        ...state,
        responseMessage: payload.messages,
        fetching: false,
      };
    case constants.setMembershipRecurringByIdFail:
      return { ...state, fetching: false, errorMessage: payload };

    // #endregion
    default:
      return state;
  }
}
