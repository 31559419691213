import * as TncAction from '../../redux/actions/TncAction';
import Api from '../../middleware/FetchMiddleware';
import { baseUrl } from '../../environments/Environment';
import { HttpAction } from '../../helpers/HttpHelper';

export const getTncSectionList = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(TncAction.getTncSectionListRequest());
    Api.callApi(`${baseUrl}private/pos/v2/tnc/list`, HttpAction.Get, params, {})
      .then(response => {
        dispatch(TncAction.getTncSectionListSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(TncAction.getTncSectionListFail(e));
        reject(e);
      });
  });
};

export const getTncSectionDetails = (dispatch, tncId) => {
  return new Promise((resolve, reject) => {
    dispatch(TncAction.getTncSectionDetailsRequest());
    Api.callApi(`${baseUrl}private/pos/v2/tnc/details/${tncId}`, HttpAction.Get, {}, {})
      .then(response => {
        dispatch(TncAction.getTncSectionDetailsSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(TncAction.getTncSectionDetailsFail(e));
        reject(e);
      });
  });
};

export const createTncSection = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(TncAction.createTncSectionRequest());
    Api.callApi(`${baseUrl}private/pos/v2/tnc/create`, HttpAction.Post, {}, params)
      .then(response => {
        dispatch(TncAction.createTncSectionSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(TncAction.createTncSectionFail(e));
        reject(e);
      });
  });
};

export const updateStatusTncSection = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(TncAction.updateStatusTncSectionRequest());
    Api.callApi(`${baseUrl}private/pos/v2/tnc/update-status`, HttpAction.Post, {}, params)
      .then(response => {
        dispatch(TncAction.updateStatusTncSectionSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(TncAction.updateStatusTncSectionFail(e));
        reject(e);
      });
  });
};

export const editTncSection = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(TncAction.editTncSectionRequest());
    Api.callApi(`${baseUrl}private/pos/v2/tnc/edit`, HttpAction.Post, {}, params)
      .then(response => {
        dispatch(TncAction.editTncSectionSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(TncAction.editTncSectionFail(e));
        reject(e);
      });
  });
};
