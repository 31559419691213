/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
// import _ from 'lodash';
// Style
import './PurchaseOrderAttachmentListStyle.scss';
// Component
import { GridRowTableThreeColumn } from '../../../../../components/Index';
import { CommonHelper } from '../../../../../helpers/Index';
// helper

const PurchaseOrderAttachmentList = props => {
  const { orderData } = props;

  const orderItems = orderData.log_audits || [];

  // const handleButtonClick = url => {
  //   window.open(url, '_blank');
  // };

  return (
    <Grid container direction="row" spacing={4}>
      <Grid item lg={12} md={12} className="container-order-activity-attachment-list pt-32">
        <div className="table-head">
          <GridRowTableThreeColumn
            customElementColumnFirst={
              <div className="text-left">
                <label className="text-14 header-title">USER</label>
              </div>
            }
            customElementColumnSecond={
              <div className="text-center">
                <label className="text-14 header-title">ACTIVITY</label>
              </div>
            }
            customElementColumnThird={
              <div className="text-center">
                <label className="text-14 header-title">DOCUMENT</label>
              </div>
            }
            columnFirst={4}
            columnSecond={4}
            columnThird={4}
          />
        </div>
        <div className="scroll-container">
          <Grid container direction="column" className="flex-wrap-unset">
            {orderItems.map((item, index) => {

              const string = item.tags; // item.tags
              const activity = string.replace(/_/g, ' ');

              return (
                <GridRowTableThreeColumn
                  key={index}
                  customElementColumnFirst={
                    <div>
                      <label className="text-14 row-item-title wrapping-container wrapping-container-break">
                        {item.user_name}
                      </label>
                    </div>
                  }
                  customElementColumnSecond={
                    <div className="flex-column text-center">
                      <label className="text-14 row-item-title">{activity}</label>
                      <label className="text-12">
                        {CommonHelper.dateTimeParseNewFormat(item.created_at, 'DD MMM YYYY') || '-'}
                      </label>
                    </div>
                  }
                  customElementColumnThird={
                    <div className="text-center">
                        {item.document.map((file, fileIndex) => {
                          
                          const URL = require('url').parse(JSON.stringify(file.attachment));
                          const decode = decodeURIComponent(URL.pathname.replace(/^.*[\\\\/]/, ''));
                          const fileName = decode.replace(/"/g, '');

                          return (
                            <div className="text-14">
                              <a key={fileIndex} href={file.attachment} target="_blank" className="content">
                                {fileName}
                              </a>
                            </div> 
                          );
                        })}
                    </div>
                  }
                  columnFirst={4}
                  columnSecond={4}
                  columnThird={4}
                />
              );
            })}
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
};

PurchaseOrderAttachmentList.propTypes = {
  orderData: PropTypes.object,
};

export default PurchaseOrderAttachmentList;
