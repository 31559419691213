import React from 'react';
import PropTypes from 'prop-types';
import { Menu, Dropdown, Button } from 'antd';

// Style
import './ButtonMultipleMainStyle.scss';

const ButtonMultipleMain = props => {
  const { onClick, options, icon, type, size, rounded, loading } = props;

  const handleMenuClick = selectedValue => {
    onClick(selectedValue.key);
  };

  let currentIcon = 'size-16';

  switch (size) {
    case 'xxl':
      currentIcon = 'size-20';
      break;
    case 'xl':
      currentIcon = 'size-16';
      break;
    case 'md':
      currentIcon = 'size-14';
      break;
    default:
      currentIcon = 'size-12';
      break;
  }

  return (
    <Dropdown
      overlay={
        <Menu onClick={handleMenuClick}>
          {options
            ? options.map(item => <Menu.Item key={item.value}>{item.name}</Menu.Item>)
            : null}
        </Menu>
      }
      placement="bottomRight"
    >
      <Button
        className={
          rounded
            ? `btn-rounded main-btn-multiple-icon btn-${type}`
            : ` main-btn-multiple-icon btn-${type}`
        }
        loading={loading}
      >
        <i className={`${icon} container-icon-prefix ${currentIcon}`} />
      </Button>
    </Dropdown>
  );
};

ButtonMultipleMain.propTypes = {
  icon: PropTypes.string,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  options: PropTypes.array.isRequired,
  rounded: PropTypes.bool,
  size: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default ButtonMultipleMain;
