import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
// Style
import './UserDetailInfoPageStyle.scss';
// component
import { ButtonMain, GridRowTableOneColumn, CardAlert } from '../../../../../../components/Index';
// Helper
import { CommonHelper, MasterDataHelper, UserStatus } from '../../../../../../helpers/Index';

class UserDetailInfoPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderUserDetailsDeleted() {
    const { userDetailsInfo, onButtonActive } = this.props;

    return (
      <Grid item lg md>
        <CardAlert
          iconPrefix="ic-ffo-alert"
          alertType="danger"
          customElements={
            <Grid container justify="space-between">
              <Grid item lg={10} md={10}>
                <label className="text-14">{userDetailsInfo.lockout_reason || '-'}</label>
              </Grid>
              <Grid item lg={2} md={2}>
                <div>
                  <ButtonMain
                    type="complete"
                    labelText="Set Active"
                    size="md"
                    onClick={onButtonActive}
                  />
                </div>
              </Grid>
            </Grid>
          }
        />
      </Grid>
    );
  }

  renderContactInformation() {
    const { userDetailsInfo } = this.props;

    return (
      <Grid item className="container-section-information">
        <Grid container direction="column" className="flex-wrap-unset">
          <Grid item className="title-table">
            <label className="text-12">CONTACT INFORMATION</label>
          </Grid>
          <Grid item>
            <Grid container direction="column" className="container-row-table flex-wrap-unset">
              <GridRowTableOneColumn
                customElementInitial={<label className="text-14">Phone Number</label>}
                customElementColumn={
                  <label className="text-14">{userDetailsInfo.phone || '-'}</label>
                }
              />
              <GridRowTableOneColumn
                customElementInitial={<label className="text-14">Email</label>}
                customElementColumn={
                  <label className="text-14">{userDetailsInfo.email || '-'}</label>
                }
              />
              <GridRowTableOneColumn
                customElementInitial={<label className="text-14">Install Code</label>}
                customElementColumn={
                  <label className="text-14">{userDetailsInfo.referral || '-'}</label>
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  renderPersonalInformation() {
    const { userDetailsInfo } = this.props;
    const personalInformation = userDetailsInfo.personal_informations;
    const birthDay = personalInformation.birthday
      ? CommonHelper.dateTimeParseNewFormat(personalInformation.birthday, 'DD MMM YYYY')
      : '-';
    const gender = personalInformation.gender
      ? MasterDataHelper.gender[personalInformation.gender - 1].name
      : '-';

    return (
      <Grid item className="container-section-information">
        <Grid container direction="column" className="flex-wrap-unset">
          <Grid item className="title-table">
            <label className="text-12">PERSONAL INFORMATION</label>
          </Grid>
          <Grid item>
            <Grid container direction="column" className="container-row-table flex-wrap-unset">
              <GridRowTableOneColumn
                customElementInitial={<label className="text-14">Name</label>}
                customElementColumn={
                  <label className="text-14">{personalInformation.name || '-'}</label>
                }
              />
              <GridRowTableOneColumn
                customElementInitial={<label className="text-14">Identity Type</label>}
                customElementColumn={
                  <label className="text-14 uppercase">
                    {personalInformation.identity_type || '-'}
                  </label>
                }
              />
              <GridRowTableOneColumn
                customElementInitial={<label className="text-14">Identity Number</label>}
                customElementColumn={
                  <label className="text-14">{personalInformation.identity_number || '-'}</label>
                }
              />
              <GridRowTableOneColumn
                customElementInitial={<label className="text-14">Place and Date of Birth</label>}
                customElementColumn={
                  <label className="text-14">
                    {`${personalInformation.place_of_birth}, ${birthDay}` || '-'}
                  </label>
                }
              />
              <GridRowTableOneColumn
                customElementInitial={<label className="text-14">Gender</label>}
                customElementColumn={<label className="text-14">{gender}</label>}
              />
              <GridRowTableOneColumn
                customElementInitial={<label className="text-14">Religion</label>}
                customElementColumn={
                  <label className="text-14 capitalize">
                    {personalInformation.religion || '-'}
                  </label>
                }
              />
              <GridRowTableOneColumn
                customElementInitial={<label className="text-14">Address</label>}
                customElementColumn={
                  <label className="text-14">
                    {personalInformation.identity_address_info.address || '-'}
                  </label>
                }
              />
              <GridRowTableOneColumn
                customElementInitial={<label className="text-14">RT/RW</label>}
                customElementColumn={
                  <label className="text-14">{`${
                    personalInformation.identity_address_info.rt
                  }/${personalInformation.identity_address_info.rw || '-'}`}</label>
                }
              />
              <GridRowTableOneColumn
                customElementInitial={<label className="text-14">Province</label>}
                customElementColumn={
                  <label className="text-14">
                    {personalInformation.identity_address_info.province || '-'}
                  </label>
                }
              />
              <GridRowTableOneColumn
                customElementInitial={<label className="text-14">City</label>}
                customElementColumn={
                  <label className="text-14">
                    {personalInformation.identity_address_info.city || '-'}
                  </label>
                }
              />
              <GridRowTableOneColumn
                customElementInitial={<label className="text-14">Region</label>}
                customElementColumn={
                  <label className="text-14">
                    {personalInformation.identity_address_info.district || '-'}
                  </label>
                }
              />
              <GridRowTableOneColumn
                customElementInitial={<label className="text-14">Village</label>}
                customElementColumn={
                  <label className="text-14">
                    {personalInformation.identity_address_info.village || '-'}
                  </label>
                }
              />
              <GridRowTableOneColumn
                customElementInitial={<label className="text-14">Nationality</label>}
                customElementColumn={
                  <label className="text-14">{personalInformation.country || '-'}</label>
                }
              />
              <GridRowTableOneColumn
                customElementInitial={<label className="text-14">Marital Status</label>}
                customElementColumn={
                  <label className="text-14 capitalize">
                    {personalInformation.marriage_status || '-'}
                  </label>
                }
              />
              <GridRowTableOneColumn
                customElementInitial={<label className="text-14">Occupation</label>}
                customElementColumn={
                  <label className="text-14">{personalInformation.occupation || '-'}</label>
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  renderLatestCovidTest() {
    const { userDetailsInfo } = this.props;
    const covidInformation = userDetailsInfo.lastest_covid_test;

    return (
      <Grid item className="container-section-information">
        <Grid container direction="column" className="flex-wrap-unset">
          <Grid item className="title-table">
            <label className="text-12">LATEST COVID TEST</label>
          </Grid>
          <Grid item>
            <Grid container direction="column" className="container-row-table flex-wrap-unset">
              <GridRowTableOneColumn
                customElementInitial={<label className="text-14">Test Method</label>}
                customElementColumn={
                  <label className="text-14">
                    {covidInformation.medical_record_type_desc || '-'}
                  </label>
                }
              />
              <GridRowTableOneColumn
                customElementInitial={<label className="text-14">Location</label>}
                customElementColumn={
                  <label className="text-14">{covidInformation.record_location || '-'}</label>
                }
              />
              <GridRowTableOneColumn
                customElementInitial={<label className="text-14">Test Date</label>}
                customElementColumn={
                  <label className="text-14">
                    {CommonHelper.dateTimeParseNewFormat(
                      covidInformation.updated_at,
                      'DD MMM YYYY, HH:mm',
                    ) || '-'}
                  </label>
                }
              />
              <GridRowTableOneColumn
                customElementInitial={<label className="text-14">Result</label>}
                customElementColumn={
                  <label className="text-14 capitalize">
                    {covidInformation.test_result || '-'}
                  </label>
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  renderCompanyInformation() {
    const { userDetailsInfo } = this.props;

    return (
      <Grid item className="container-section-information">
        <Grid container direction="column" className="flex-wrap-unset">
          <Grid item className="title-table">
            <label className="text-12">COMPANY INFORMATION</label>
          </Grid>
          <Grid item>
            <Grid container direction="column" className="container-row-table flex-wrap-unset">
              <GridRowTableOneColumn
                customElementInitial={<label className="text-14">Company Name</label>}
                customElementColumn={
                  <label className="text-14">{userDetailsInfo.company_name || '-'}</label>
                }
              />
              {userDetailsInfo.company_details.user_merchants &&
                this.renderUserInformationMerchant()}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  renderUserInformationMerchant() {
    const { userDetailsInfo } = this.props;
    const companyMerchant = userDetailsInfo.company_details.user_merchants;

    return companyMerchant.map((item, index) => (
      <div key={index}>
        <Grid item className="row-table-merchant">
          <Grid container direction="row">
            <Grid item lg={3} md={3} className="left">
              <label className="text-14 pl-8">Merchant</label>
            </Grid>
            <Grid item lg={9} md={9} className="right">
              <label className="text-14">{item.name}</label>
            </Grid>
          </Grid>
        </Grid>
        <Grid item className="row-table-merchant">
          <Grid container direction="row">
            <Grid item lg={3} md={3} className="left">
              <label className="text-14 pl-8">Branch</label>
            </Grid>
            <Grid item lg={9} md={9} className="right">
              {item.branch_id ? (
                item.branch_id.map((itemBranch, indexBranch) => {
                  const branchName = CommonHelper.renameFirstUpperCase(itemBranch.name);

                  return indexBranch < item.branch_id.length - 1 ? (
                    <label className="text-14" key={indexBranch}>{`${branchName}, `}</label>
                  ) : (
                    <label className="text-14" key={indexBranch}>
                      {branchName}.
                    </label>
                  );
                })
              ) : (
                <label className="text-14">-</label>
              )}
            </Grid>
          </Grid>
        </Grid>
      </div>
    ));
  }

  renderDeviceInformation() {
    const { userDetailsInfo } = this.props;

    return (
      <Grid item className="container-section-information">
        <Grid container direction="column" className="flex-wrap-unset">
          <Grid item className="title-table">
            <label className="text-12">DEVICE INFORMATION</label>
          </Grid>
          <Grid item>
            <Grid container direction="column" className="container-row-table flex-wrap-unset">
              <GridRowTableOneColumn
                customElementInitial={<label className="text-14">Brand</label>}
                customElementColumn={
                  <label className="text-14">{userDetailsInfo.brand || '-'}</label>
                }
              />
              <GridRowTableOneColumn
                customElementInitial={<label className="text-14">System Version</label>}
                customElementColumn={
                  <label className="text-14">{userDetailsInfo.system_version || '-'}</label>
                }
              />
              <GridRowTableOneColumn
                customElementInitial={<label className="text-14">Device ID</label>}
                customElementColumn={
                  <label className="text-14">
                    {userDetailsInfo.device_code ? userDetailsInfo.device_code : '-'}
                  </label>
                }
              />
              <GridRowTableOneColumn
                customElementInitial={<label className="text-14">Manufacture</label>}
                customElementColumn={
                  <label className="text-14">
                    {userDetailsInfo.manufacture ? userDetailsInfo.manufacture : '-'}
                  </label>
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  render() {
    const { userDetailsInfo } = this.props;
    let renderElement = null;

    if (userDetailsInfo) {
      renderElement = (
        <Grid
          container
          className="user-details-card container-tab flex-wrap-unset"
          direction="column"
          justify="flex-start"
        >
          {userDetailsInfo.status === UserStatus.Deleted && this.renderUserDetailsDeleted()}
          {this.renderContactInformation()}
          {userDetailsInfo.personal_informations && this.renderPersonalInformation()}
          {userDetailsInfo.lastest_covid_test && this.renderLatestCovidTest()}
          {userDetailsInfo.company_details && this.renderCompanyInformation()}
          {this.renderDeviceInformation()}
        </Grid>
      );
    }

    return renderElement;
  }
}

UserDetailInfoPage.propTypes = {
  onButtonActive: PropTypes.func,
  userDetailsInfo: PropTypes.object,
};

export default UserDetailInfoPage;
