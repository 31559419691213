/* eslint-disable no-restricted-properties */
import Moment from 'moment';
import _ from 'lodash';

Moment.locale('id');

const RoundToDecimals = (value, precision) => {
  const pow = Math.pow(10, precision);
  return Math.floor(value * pow) / pow;
};

const CommonHelper = {
  currentDate(formatDateTime) {
    const result = Moment()
      .format(formatDateTime || 'DD-MM-YYYY')
      .toString();
    return result;
  },
  intervalDate(dateTime, formatDateTime, number, dateType) {
    let result = null;
    const dateNow = new Date(dateTime);

    if (dateNow) {
      if (dateType === 'day') dateNow.setDate(dateNow.getDate() + number);
      else if (dateType === 'month') dateNow.setMonth(dateNow.getMonth() + number);
      else if (dateType === 'year') dateNow.setFullYear(dateNow.getFullYear() + number);

      result = Moment(dateNow.toString()).format(formatDateTime);
    }

    return result;
  },
  intervalTime(dateTime, formatDateTime, number, dateType) {
    let result = null;
    const dateNow = new Date(dateTime);

    if (dateNow) {
      if (dateType === 'hour') dateNow.setHours(dateNow.getHours() + number);
      else if (dateType === 'minute') dateNow.setMinutes(dateNow.getMinutes() + number);

      result = Moment(dateNow.toString()).format(formatDateTime);
    }

    return result;
  },
  dateTimeParseNewFormat(dateTime, formatDateTime) {
    if (!dateTime) {
      return '';
    }

    const result = Moment(dateTime)
      .locale('en')
      .format(formatDateTime || 'DD-MM-YYYY')
      .toString();
    return result;
  },
  getTimeDuration(startTime, endTime) {
    let result = null;
    const startTimeDuration = Moment(
      CommonHelper.dateTimeParseNewFormat(startTime, 'HH:mm:ss'),
      'HH:mm:ss',
    );
    const endTimeDuration = Moment(
      CommonHelper.dateTimeParseNewFormat(endTime, 'HH:mm:ss'),
      'HH:mm:ss',
    );
    const duration = Moment.duration(endTimeDuration.diff(startTimeDuration));
    // duration in hours
    const hours = parseInt(duration.asHours(), 10);
    // duration in minute
    const minutes = parseInt(duration.asMinutes(), 10) % 60;
    const calcAllInMinute = hours * 60 + minutes;

    result = calcAllInMinute;
    return result;
  },
  definedIndexOfArrayTrainerId(dataArray, trainerId) {
    const index = dataArray.findIndex(item => item.trainer_id === parseInt(trainerId, 10));
    const result = dataArray[index];
    // const result = dataArray.findIndex(item => item.trainer_id === parseInt(trainerId));

    return result;
  },
  compareDateAndTime(date01, date02, option) {
    let result = false;

    if (option === 'date') {
      const tmp01 = new Date(date01);
      const tmp02 = new Date(date02);

      if (tmp01.getTime() > tmp02.getTime()) result = true;
      else result = false;
    } else if (option === 'time') {
      const tmp01 = date01.split(':');
      const tmp02 = date02.split(':');

      const tmpFormat01 = new Date().setHours(
        parseInt(tmp01[0], 10),
        parseInt(tmp01[1], 10),
        parseInt(tmp01[2] || '00', 10),
      );
      const tmpFormat02 = new Date().setHours(
        parseInt(tmp02[0], 10),
        parseInt(tmp02[1], 10),
        parseInt(tmp02[2] || '00', 10),
      );

      if (tmpFormat01 > tmpFormat02) result = true;
      else result = false;
    }

    return result;
  },
  formatCurrency(value, precision = 0) {
    if (!value) {
      return 0;
    }
    if (precision === 0) {
      value = RoundToDecimals(value, 0);
    }

    const result = value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') || 0;
    return result.toString();
  },
  formatCurrencyWithDecimals(value, precision = 2) {
    const numbers = (Math.round(value * 100) / 100).toFixed(precision);
    const split = numbers.split('.');
    const currency = split[0].length % 3;
    let idr = split[0].substr(0, currency);
    const thousands = split[0].substr(currency).match(/\d{1,3}/gi);

    if (thousands) {
      const separator = currency ? '.' : '';
      idr += separator + thousands.join('.');
    }
    idr = split[1] !== undefined ? `${idr},${split[1]}` : idr;

    return idr;
  },
  renameKeyName(obj = [], oldKeyName, newKeyName) {
    const newObject = [];
    obj.map(item => {
      newObject.push(
        _.mapKeys(item, (value, key) => {
          let newKey = key;

          if (key === oldKeyName) {
            newKey = newKeyName;
          }
          return newKey;
        }),
      );
      return false;
    });
    return newObject;
  },
  countLeftDayInWeek(currentDate) {
    const endOfWeek = Moment(currentDate).endOf('week');
    const today = Moment(currentDate);

    const checkDateWeek = CommonHelper.dateTimeParseNewFormat(endOfWeek.toString(), 'YYYY-MM-DD');
    const checkToday = CommonHelper.dateTimeParseNewFormat(today.toString(), 'YYYY-MM-DD');

    const result = checkDateWeek === checkToday ? 7 : endOfWeek.diff(today, 'days');

    return result;
  },
  generateMessage(data) {
    const isArray = Array.isArray(data);
    let converted = '';
    let result = '';
    let countKey = 0;

    if (isArray) {
      data.forEach((item, index) => {
        if (index === 0) converted = `${item}`;
        else converted += `, ${item}`;
      });
    } else if (typeof data === 'object') {
      Object.keys(data).forEach(key => {
        if (countKey === 0) converted = `${data[key]}`;
        else converted += `, ${data[key]}`;
        countKey += 1;
      });
    } else converted = data;

    result = converted;

    return result;
  },
  renameFirstUpperCase(value) {
    if (_.isEmpty(value)) {
      return '';
    }

    const result = value.charAt(0).toUpperCase() + value.slice(1);
    return result;
  },
  renameRemoveCharacter(value, character) {
    let result = '';
    const reformat = value.split(character);

    if (reformat.length > 0) {
      reformat.forEach(item => {
        result += `${CommonHelper.renameFirstUpperCase(item)} `;
      });
    } else result = value.charAt(0).toUpperCase() + value.slice(1);

    return result.trim();
  },
  removeHTMLTag(value) {
    let result = '';
    const regex = /(<([^>]+)>)/gi;
    result = value.replace(regex, '');
    return result;
  },
  checkStringLengthOver(value, maxLength) {
    let result = false;

    if (value.length > maxLength) result = true;

    return result;
  },
  checkNumberOnly(value) {
    const result = !!(!isNaN(value) && value);

    return result;
  },
  checkValidDate(date) {
    const check = Moment(date, 'YYYY-MM-DD');
    const extraCheck = new Date(date);
    let result = false;

    result = check.isValid() && extraCheck.toString() !== 'Invalid Date';
    return result;
  },
  getStartDatePrevMonth(currentDate) {
    const result = Moment(currentDate)
      .subtract(1, 'months')
      .startOf('month')
      .format('YYYY-MM-DD');
    return result;
  },
  getEndDatePrevMonth(currentDate) {
    const result = Moment(currentDate)
      .subtract(1, 'months')
      .endOf('month')
      .format('YYYY-MM-DD');
    return result;
  },
  getStartDateMonth(currentDate) {
    const result = Moment(currentDate)
      .startOf('month')
      .format('YYYY-MM-DD');
    return result;
  },
  getEndDateMonth(currentDate) {
    const result = Moment(currentDate)
      .endOf('month')
      .format('YYYY-MM-DD');
    return result;
  },
  checkNegativeValue(value) {
    const result = parseInt(value, 10) < 0;

    return result;
  },
  objectCloning(value) {
    const result = _.cloneDeep(value);
    return result;
  },
  encryptObject(value) {
    const encryptJson = JSON.stringify(value);
    const result = btoa(encryptJson);

    return result;
  },
  decryptObject(value) {
    const result = atob(value);
    const decryptJson = JSON.parse(result);

    return decryptJson;
  },
  startWorkingDayInWeek(currentDate) {
    const today = Moment(currentDate);
    let result;

    const checkToday = Moment(currentDate).isoWeekday();

    if (checkToday > 5 && checkToday <= 7) {
      result = this.startWorkingDayInWeek(this.intervalDate(today, 'YYYY-MM-DD', 1, 'day'));
    } else {
      result = this.dateTimeParseNewFormat(today, 'YYYY-MM-DD');
    }

    return result;
  },
  dataArrayOrder(dataArray, field, order) {
    let result = dataArray;

    if (!_.isEmpty(dataArray)) {
      result = _.orderBy(dataArray, [field], [order || '']);
    }

    return result;
  },
  currencyWordingValue(value) {
    const parts = value.toString().split('.');
    const wordingCurrency = ['', 'JT', 'M'];

    const result =
      parts.length > 1
        ? `${Math.round(parseInt(parts.join(''), 10) / Math.pow(1000, parts.length - 1))} ${
            wordingCurrency[parts.length - 2]
          }`
        : parts[0];

    return result;
  },
  getArrayByLimit(dataArray, limit, page) {
    let converted = [];

    if (!_.isEmpty(dataArray)) {
      const startIndex = page > 1 ? (page - 1) * limit : 0;
      converted = dataArray.slice(startIndex, page * limit);
    }
    return converted;
  },
  formatPhone(value) {
    if (!value || isNaN(value)) {
      return null;
    }

    if (typeof value !== 'string') {
      value = value.toString();
    }

    if (value.substr(0, 1) === '0') {
      value = '+62'.concat(value.substr(1));
    } else {
      value = '+'.concat(value);
    }

    return value;
  },
};

export default CommonHelper;
