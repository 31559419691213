import React from 'react';
import PropTypes from 'prop-types';
import { Select, Form } from 'antd';
import clsx from 'clsx';
// Style
import './SelectSearchMultipleStyle.scss';

const { Option } = Select;

const SelectSearchMultiple = props => {
  const {
    onChange,
    options,
    defaultValue,
    currentValue,
    size,
    disabled,
    placeholder,
    validateStatus,
    errorMessage,
    onSearch,
  } = props;

  const handleItemChange = selectedValue => {
    onChange(selectedValue);
  };

  const onBlur = () => {
    // console.log('blur');
  };

  const onFocus = () => {
    // console.log('focus');
  };

  const handleItemSearch = value => {
    onSearch(value);
  };

  return (
    <Form.Item validateStatus={validateStatus} help={errorMessage}>
      <Select
        className={`container-select-input-multiple-search-main ${clsx({
          [`text-${size}`]: size,
        })}`}
        mode={'multiple'}
        showSearch
        placeholder={placeholder}
        optionFilterProp="children"
        onFocus={onFocus}
        onBlur={onBlur}
        onSearch={handleItemSearch}
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        defaultValue={defaultValue}
        value={currentValue}
        size={size}
        style={{
          borderRadius: 20,
          width: '100%',
        }}
        disabled={disabled}
        onChange={handleItemChange}
      >
        {options
          ? options.map((item, index) => (
              <Option key={index} value={item.value}>
                {item.name}
              </Option>
            ))
          : null}
      </Select>
    </Form.Item>
  );
};

SelectSearchMultiple.propTypes = {
  currentValue: PropTypes.array,
  defaultValue: PropTypes.array,
  disabled: PropTypes.bool,
  errorMessage: PropTypes.string,
  onChange: PropTypes.func,
  onSearch: PropTypes.func,
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  size: PropTypes.string,
  validateStatus: PropTypes.string,
};

SelectSearchMultiple.defaultProps = {
  size: 'middle',
};

export default SelectSearchMultiple;
