import React from 'react';
import PropTypes from 'prop-types';
import { Input, Form, Select } from 'antd';
import _ from 'lodash';
// Style
import './InputMainStyle.scss';

const { Option } = Select;

const InputMain = props => {
  const {
    onChange,
    onChangeSelectAddon,
    value,
    size,
    disabled,
    placeholder,
    validateStatus,
    errorMessage,
    isNumber,
    suffix,
    prefix,
    defaultValueSelect,
    optionValueSelect,
  } = props;

  const handleChange = e => {
    onChange(e.target.value);
  };

  const handleChangeNumber = e => {
    const selectedValue = e.target.value;
    const reg = /^-?\d*(\.\d*)?$/;
    if (
      (!isNaN(selectedValue) && reg.test(selectedValue)) ||
      selectedValue === '' ||
      selectedValue === '-'
    ) {
      onChange(selectedValue);
    }
  };

  const handleChangeSelectAddon = e => {
    onChangeSelectAddon(e);
  };

  const renderAddon = () => {
    return (
      <Select defaultValue={defaultValueSelect} onChange={handleChangeSelectAddon}>
        {optionValueSelect.map((item, index) => (
          <Option key={index} value={item.value}>
            {item.name}
          </Option>
        ))}
      </Select>
    );
  };

  const renderPrefix = () => {
    const sizeIcon = size || 'large';
    return (
      <div className="pt-4">
        <i className={`${prefix} container-icon-prefix ${sizeIcon}`} />
      </div>
    );
  };

  return (
    <Form.Item validateStatus={validateStatus} help={errorMessage}>
      <Input
        className="br-4"
        placeholder={placeholder}
        size={size || 'large'}
        value={value}
        onChange={isNumber ? handleChangeNumber : handleChange}
        disabled={disabled}
        suffix={suffix || null}
        prefix={prefix ? renderPrefix() : null}
        addonBefore={!_.isEmpty(optionValueSelect) ? renderAddon() : null}
      />
    </Form.Item>
  );
};

InputMain.propTypes = {
  defaultValueSelect: PropTypes.string,
  disabled: PropTypes.bool,
  errorMessage: PropTypes.string,
  isNumber: PropTypes.bool,
  onChange: PropTypes.func,
  onChangeSelectAddon: PropTypes.func,
  optionValueSelect: PropTypes.array,
  placeholder: PropTypes.string,
  prefix: PropTypes.string,
  size: PropTypes.string,
  suffix: PropTypes.object,
  validateStatus: PropTypes.string,
  value: PropTypes.string,
};

export default InputMain;
