import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Grid, Breadcrumbs, Link, FormControl, FormLabel } from '@material-ui/core';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { compose } from 'redux';
// Components
import {
  ButtonMain,
  GridRowTableOneColumn,
  AuthenticationAccessPages,
  PrevStateValue,
  SkeletonDetailsV03,
  LabelStatusMain,
  PickerInputDate,
  ModalAlertAction,
  SnackBarSimple,
  TextAreaMain,
  TextInputAutocomplete,
  SelectInputMain,
} from '../../../components/Index';
import { DetailsShipping, CartItemDetailsEats } from './components/Index';
// Style
import './MealDeliveryDetailStyle.scss';
// Helper
import {
  CommonHelper,
  HttpStatusCode,
  OrderHelper,
  MasterDataHelper,
} from '../../../helpers/Index';
// Action
import {
  getMealDeliveryDetail,
  changeMealDeliveryAddress,
  changeMealDeliveryDetails,
  skipMealDelivery,
} from '../../../services/api/EatApi';
import {
  getOrderShippingAddress,
  getOrderShippingCost,
  getOrderShippingAddressInfo,
} from '../../../services/api/OrdersApi';
// Assets
import { Icons } from '../../../assets/Index';
import { paymentMethodOptionCode } from '../../../helpers/MasterDataHelper';

const optionToast = {
  vertical: 'top',
  horizontal: 'right',
};

const mealTimeOption = MasterDataHelper.eatSessionType.filter(item => item.value !== 'extra');

const initialForm = {
  address: '',
  addressInfo: {},
  placeId: '',
  note: '',
  shippingCost: 0,
};

const initialValidation = {
  address: { isError: false, errorMessage: '' },
  note: { isError: '', errorMessage: '' },
};

class MealDeliveryDetail extends React.Component {
  constructor(props) {
    super(props);

    const {
      match: { params },
    } = this.props;

    const mealObject = {
      ...CommonHelper.decryptObject(params.param_object),
    };

    this.state = {
      paramObject: mealObject,
      isOpenModal: false,
      editType: null,
      date: mealObject.deliveryDate,
      mealTime: null,
      mealNotes: null,
      userCateringScheduleId: null,
      form: CommonHelper.objectCloning(initialForm),
      validation: CommonHelper.objectCloning(initialValidation),
      toastInformation: {
        isOpen: false,
        message: '',
        snackbarType: 'warning',
      },
      isError: false,
    };
    this.searchAddressDebounce = _.debounce(this.searchAddressDebounce, 400);
  }

  async componentDidMount() {
    const { getDetails } = this;
    await getDetails();
  }

  onProcessPlaceId() {
    const {
      form: { placeId },
      form,
    } = this.state;
    const { getShippingAddressInfo } = this.props;

    const param = {
      place_id: placeId,
    };

    getShippingAddressInfo(param)
      .then(response => {
        const data = response.data;

        this.setState({ form: { ...form, addressInfo: data } }, () => {
          this.onProcessShippingCost();
        });
      })
      .catch(error => {
        const serverMessage = error.data;
        this.processMessage(serverMessage.messages, 'error');
      });
  }

  onProcessShippingCost() {
    const {
      form: { addressInfo },
      form,
    } = this.state;
    const { getShippingCost } = this.props;
    let param = {};

    param = {
      data: {
        order_type: 'eat',
        weight: null,
        user_address_id: null,
        shipping_address: addressInfo,
        is_contain_fitmarket: false,
        user_id: null,
      },
    };

    if (addressInfo) {
      getShippingCost(param)
        .then(response => {
          const data = response.data;
          const deliveryServiceOption = OrderHelper.reformatDeliveryServiceOption(data);
          this.setState({
            form: { ...form, shippingCost: deliveryServiceOption[0].additional.cost[0].value },
            isError: false,
          });
        })
        .catch(error => {
          this.setState(
            {
              form: { ...form, shippingCost: 0 },
              isError: true,
            },
            async () => {
              const serverMessage = error.data;
              const validationStatus = error.status === HttpStatusCode.InternalServerError;
              await this.processMessage(
                validationStatus ? serverMessage.message : serverMessage.messages,
                'error',
              );
            },
          );
        });
    }
  }

  getDetails = () => {
    const { mealDeliveryDetail } = this.props;
    const { paramObject } = this.state;

    const param = {
      order_no: paramObject.orderNo,
      delivery_date: paramObject.deliveryDate,
    };
    mealDeliveryDetail(param);
  };

  searchAddressDebounce = () => {
    const { form, validation } = this.state;
    const { getShippingAddress } = this.props;

    const param = {
      search_term: form.address,
    };

    const message = CommonHelper.objectCloning(initialValidation.address);
    message.isError = !form.address;
    message.errorMessage = !form.address ? 'Please enter Address' : '';

    this.setState(
      {
        form: { ...form, address: form.address },
        validation: { ...validation, address: message },
      },
      () => {
        getShippingAddress(param).catch(error => {
          const serverMessage = error.data;
          this.processMessage(serverMessage.messages, 'error');
        });
      },
    );
  };

  handleButtonSkipMeal = () => {
    const {
      skipMeal,
      history,
      eatData: { mealDetails },
    } = this.props;
    const userCateringScheduleId = mealDetails.meal_items.map(item => {
      return item.user_catering_schedule_id;
    });
    const param = {
      user_catering_schedule_ids: userCateringScheduleId,
    };
    skipMeal(param)
      .then(async response => {
        const message = response.messages;
        await this.setState({ isOpenModal: false, editType: null, date: null }, () => {
          this.processMessage(message, 'success');
        });
        history.push('/eat/meal-delivery');
      })
      .catch(error => {
        const message = error.data;
        this.processMessage(message.messages, 'error');
      });
  };

  handleClick = (event, value) => {
    const { history } = this.props;
    event.preventDefault();
    history.push(value);
  };

  handleButtonBack = () => {
    const { history } = this.props;
    history.push('/eat/meal-delivery');
  };

  handleCloseToast = () => {
    const { toastInformation } = this.state;
    this.setState({ toastInformation: { ...toastInformation, isOpen: false } });
  };

  handleOpenModalEdit = (type, notes = null, time = null, userCateringScheduleId = null) => {
    this.setState({
      isOpenModal: true,
      editType: type,
      mealNotes: notes,
      mealTime: time,
      userCateringScheduleId,
    });
  };

  handleCloseModalEdit = () => {
    this.setState({ isOpenModal: false });
  };

  handleChangeStartDate = value => {
    this.setState({ date: value });
  };

  handleSelectChangeMealTime = value => {
    this.setState({ mealTime: value });
  };

  handleTextChangeMealNotes = value => {
    this.setState({ mealNotes: value });
  };

  handleTextChangeAddress = value => {
    const { form } = this.state;
    this.setState(
      {
        form: { ...form, address: value },
      },
      () => {
        this.searchAddressDebounce();
      },
    );
  };

  handleSelectedItemAddress = (value, option) => {
    const { form, validation } = this.state;

    this.setState({ form: { ...form, address: option.address, placeId: option.place_id } }, () => {
      const message = CommonHelper.objectCloning(initialValidation.address);
      validation.address = message;

      this.onProcessPlaceId();
    });
  };

  handleTextChangeNote = value => {
    const { form, validation } = this.state;
    const message = CommonHelper.objectCloning(initialValidation.note);

    if (value === '' || value === undefined) {
      message.isError = 'error';
      message.errorMessage = 'Please enter note Address';
    }

    this.setState({
      form: { ...form, note: value },
      validation: { ...validation, note: message },
    });
  };

  handleButtonSaveData = () => {
    const {
      date,
      paramObject,
      editType,
      form,
      isError,
      mealTime,
      mealNotes,
      userCateringScheduleId,
    } = this.state;
    const {
      changeDeliveryAddress,
      changeDeliveryDetails,
      eatData: { mealDetails },
      orderData: { errorMessage },
      history,
    } = this.props;
    const userCateringScheduleIds = mealDetails.meal_items.map(item => {
      return item.user_catering_schedule_id;
    });
    let param = {};

    if (isError) {
      this.processMessage(`${errorMessage.messages[0]}. Please change your address.`, 'error');
    } else {
      switch (editType) {
        case 'date':
          param = {
            user_catering_schedule_id: userCateringScheduleId,
            delivery_date: date,
            meal_time: mealTime,
            meal_notes: mealNotes,
          };
          this.setState({ paramObject: { ...paramObject, deliveryDate: date } });
          changeDeliveryDetails(param)
            .then(async response => {
              const message = response.messages;
              await this.getDetails();
              await this.setState(
                {
                  isOpenModal: false,
                  editType: null,
                  date: mealDetails.delivery_date,
                  mealTime: null,
                  mealNotes: null,
                  userCateringScheduleId: null,
                },
                () => {
                  this.processMessage(message, 'success');
                },
              );
              history.push('/eat/meal-delivery');
            })
            .catch(error => {
              const message = error.data;
              this.processMessage(message.messages, 'error');
            });
          break;
        case 'address':
          param = {
            user_catering_schedule_ids: userCateringScheduleIds,
            address: form.address,
            notes: form.note,
            coordinate: `${form.addressInfo.latitude}, ${form.addressInfo.longitude}`,
            change_remaining_delivery: false,
            shipping_cost: form.shippingCost,
          };
          changeDeliveryAddress(param)
            .then(async response => {
              const message = response.messages;
              await this.getDetails();
              await this.setState(
                {
                  isOpenModal: false,
                  editType: null,
                  form: CommonHelper.objectCloning(initialForm),
                },
                () => {
                  this.processMessage(message, 'success');
                },
              );
              history.push('/eat/meal-delivery');
            })
            .catch(error => {
              const message = error.data;
              this.processMessage(message.messages, 'error');
            });
          break;
        default:
          break;
      }
    }
  };

  processMessage(messages, type) {
    const convertedMessage = CommonHelper.generateMessage(messages);

    const paramInformation = {
      isOpen: true,
      message: convertedMessage,
      snackbarType: type,
    };

    this.setState({
      toastInformation: paramInformation,
    });
  }

  renderOrderCard = () => {
    const {
      eatData: { mealDetails },
    } = this.props;
    const mealDetailsData = !_.isEmpty(mealDetails) ? mealDetails : {};

    const renderValuePayment = () => {
      let renderElement = null;

      switch (mealDetailsData.payment_type) {
        case paymentMethodOptionCode.fit_point:
          renderElement = <img src={Icons.fitPoints} alt="Fit Points" />;
          break;
        default:
          break;
      }

      return renderElement;
    };

    return (
      <Grid container className="order-card-details" direction="row" wrap={'nowrap'}>
        <Grid item lg={10} md={10}>
          <Grid container direction="column">
            <Grid item className="pt-16">
              <Grid container spacing={2} direction="row">
                <Grid item lg={3} md={3} className="order-items-text">
                  <label className="text-12 text-opacity-50">Date</label>
                  <label className="text-12 pt-4 text-bold">
                    {CommonHelper.dateTimeParseNewFormat(
                      mealDetailsData.created_at,
                      'DD MMM YYYY | LT',
                    )}
                  </label>
                </Grid>
                <Grid item lg={3} md={3} className="order-items-text">
                  <label className="text-12 text-opacity-50">Payment Method</label>
                  <label className="text-12 pt-4 text-bold">{mealDetailsData.payment_type}</label>
                </Grid>
                {mealDetailsData.payment_type === paymentMethodOptionCode.bank_transfer && (
                  <Grid item lg={4} md={4} className="order-items-text">
                    <div className="order-va-number-section">
                      <label className="text-12 text-opacity-50">VA Number</label>
                      <label className="text-12 pt-4 text-bold">{mealDetailsData.va_number}</label>
                    </div>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={2} md={2}>
          <Grid container direction="row" className="order-amount-section">
            <Grid item lg={12} md={12}>
              <Grid
                container
                direction="column"
                justify="center"
                className="order-items-amount-text"
              >
                <Grid item lg md>
                  <span className="flex-row text-center">
                    {renderValuePayment()}
                    <label className="text-16 text-bold currency">
                      {mealDetailsData.grand_total !== 0
                        ? `IDR ${CommonHelper.formatCurrency(mealDetailsData.grand_total)}`
                        : 'Free'}
                    </label>
                  </span>
                </Grid>
                <Grid item lg md className="pt-4">
                  <LabelStatusMain value={mealDetailsData.payment_status} type="complete" />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  renderCustomerDetails = () => {
    const {
      eatData: { mealDetails },
    } = this.props;
    const customerDetailsData = !_.isEmpty(mealDetails) ? mealDetails : {};

    return (
      <Grid container className="pt-24 flex-wrap-unset" direction="column">
        <Grid item lg={12} md={12} className="pb-16">
          <label className="text-12 text-opacity-50 text-bold">Customer Details</label>
        </Grid>
        <Grid item lg={12} md={12}>
          <Grid
            container
            direction="column"
            className="container-row-table details flex-wrap-unset"
          >
            <GridRowTableOneColumn
              customElementInitial={
                <label className="text-14 title text-rolling-stone pl-12">Name</label>
              }
              customElementColumn={<label className="text-14">{customerDetailsData.name}</label>}
              columnLeft={5}
              columnRight={7}
            />
            <GridRowTableOneColumn
              customElementInitial={
                <label className="text-14 title text-rolling-stone pl-12">Email</label>
              }
              customElementColumn={
                <label className="text-14 wrapping-container-break">
                  {customerDetailsData.email || '-'}
                </label>
              }
              columnLeft={5}
              columnRight={7}
            />
            <GridRowTableOneColumn
              customElementInitial={
                <label className="text-14 title text-rolling-stone pl-12">Phone Number</label>
              }
              customElementColumn={
                <label className="text-14">{customerDetailsData.phone || '-'}</label>
              }
              columnLeft={5}
              columnRight={7}
            />
            <GridRowTableOneColumn
              customElementInitial={
                <label className="text-14 title text-rolling-stone pl-12">Gender</label>
              }
              customElementColumn={
                <label className="text-14">
                  {_.isEmpty(customerDetailsData.gender) ? '-' : customerDetailsData.gender}
                </label>
              }
              columnLeft={5}
              columnRight={7}
            />
            <GridRowTableOneColumn
              customElementInitial={
                <label className="text-14 text-rolling-stone title text-rolling-stone pl-12">
                  Foodlist
                </label>
              }
              customElementColumn={
                <label className="text-14">{customerDetailsData.foodlist}</label>
              }
              columnLeft={5}
              columnRight={7}
            />
            <GridRowTableOneColumn
              customElementInitial={
                <label className="text-14 text-rolling-stone title text-rolling-stone pl-12">
                  Date
                </label>
              }
              customElementColumn={
                <label className="text-14">{customerDetailsData.delivery_date}</label>
              }
              columnLeft={5}
              columnRight={7}
            />
            <GridRowTableOneColumn
              customElementInitial={
                <label className="text-14 text-rolling-stone title text-rolling-stone pl-12">
                  Catering Status
                </label>
              }
              customElementColumn={
                <label className="text-14">{customerDetailsData.catering_status}</label>
              }
              columnLeft={5}
              columnRight={7}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  };

  renderOrderSummary = () => {
    const {
      eatData: { mealDetails },
    } = this.props;

    return (
      <CartItemDetailsEats
        orderData={mealDetails}
        onClickRefundBtn={() => this.handleOpenModalEdit('cancel')}
        onClickEditMealBtn={(notes, time, userCateringScheduleId) =>
          this.handleOpenModalEdit('date', notes, time, userCateringScheduleId)
        }
      />
    );
  };

  renderModalEdit() {
    const {
      eatData: { mealDetails },
      orderData: { fetchingShippingAddress, listShippingAddress },
    } = this.props;
    const { isOpenModal, editType, mealTime, mealNotes } = this.state;

    const currentValueText = mealDetails ? mealDetails.delivery_date : '';

    const modalDetailDataCreate = {
      title: '',
      body: '',
    };

    let elementRender = null;

    switch (editType) {
      case 'date':
        modalDetailDataCreate.title = 'Edit Meals';
        elementRender = (
          <ModalAlertAction
            onButtonSubmit={this.handleButtonSaveData}
            onCloseModal={this.handleCloseModalEdit}
            modalDetail={modalDetailDataCreate}
            modalType="primary"
            buttonSubmitText="Submit"
            openModal={isOpenModal}
            customElementProps={
              <div>
                <Grid container direction="row" justify="space-between" className="mb-22">
                  <Grid item lg={6} md={6}>
                    <div className="mb-10">
                      <label className="text-14">Delivery Date</label>
                    </div>
                    <div className="container-remove-margin">
                      <PickerInputDate
                        customIcon="ic-ffo-date-pick"
                        dateFormat="dd/MM/yyyy"
                        defaultValue={currentValueText}
                        onChange={this.handleChangeStartDate}
                        toolbar={false}
                        isDisableSunday
                      />
                    </div>
                  </Grid>
                  <Grid item lg={5} md={5}>
                    <div className="mb-10">
                      <label className="text-14">Meal Time</label>
                    </div>
                    <div className="container-remove-margin">
                      <SelectInputMain
                        options={mealTimeOption}
                        currentValue={mealTime}
                        onChange={this.handleSelectChangeMealTime}
                      />
                    </div>
                  </Grid>
                </Grid>
                <Grid container className="mb-22">
                  <Grid item lg={12} md={12}>
                    <div className="mb-10">
                      <label className="text-14">Meal Notes</label>
                    </div>
                    <div className="container-remove-margin">
                      <TextAreaMain
                        onChange={this.handleTextChangeMealNotes}
                        currentValue={mealNotes}
                        placeholder="More spices, herbs, etc."
                        rows={3}
                        maxLength={250}
                      />
                    </div>
                  </Grid>
                </Grid>
              </div>
            }
          />
        );
        break;
      case 'address':
        modalDetailDataCreate.title = 'Edit Delivery Address';
        elementRender = (
          <ModalAlertAction
            onButtonSubmit={this.handleButtonSaveData}
            onCloseModal={this.handleCloseModalEdit}
            modalDetail={modalDetailDataCreate}
            modalType="primary"
            buttonSubmitText="Save"
            openModal={isOpenModal}
            customElementProps={
              <Grid container className="mb-22">
                <Grid item lg md>
                  <div>
                    <label className="text-14">Please input new address</label>
                  </div>
                  <FormControl component="fieldset" fullWidth margin="normal">
                    <FormLabel component="label" className="text-12 filed-title mb-10">
                      Address
                    </FormLabel>
                    <TextInputAutocomplete
                      size="md"
                      onChange={this.handleTextChangeAddress}
                      currentValue={this.state.form.address}
                      placeholder="Address"
                      onSelect={this.handleSelectedItemAddress}
                      isLoading={fetchingShippingAddress}
                      data={listShippingAddress}
                      isCustomDisplay
                      customDisplayClass="custom-address-option-item"
                      isError={this.state.validation.address.isError}
                      errorMessage={this.state.validation.address.errorMessage}
                    />
                  </FormControl>
                  <FormControl component="fieldset" fullWidth margin="normal">
                    <FormLabel component="label" className="text-12 filed-title mb-10">
                      Notes
                    </FormLabel>
                    <TextAreaMain
                      onChange={this.handleTextChangeNote}
                      currentValue={this.state.form.note}
                      placeholder="(exp: Building number, house No. building floor)"
                      rows={3}
                      validateStatus={this.state.validation.note.isError}
                      errorMessage={this.state.validation.note.errorMessage}
                      maxLength={99}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            }
          />
        );
        break;
      case 'cancel':
        modalDetailDataCreate.title = 'Confirmation';
        modalDetailDataCreate.body = 'Are you sure want to refund this meal?';
        elementRender = (
          <ModalAlertAction
            onButtonSubmit={this.handleButtonSkipMeal}
            onCloseModal={this.handleCloseModalEdit}
            modalDetail={modalDetailDataCreate}
            modalType="danger"
            buttonSubmitText="Yes"
            buttonType="negative"
            openModal={isOpenModal}
          />
        );
        break;
      default:
        break;
    }

    return isOpenModal ? elementRender : null;
  }

  render() {
    const {
      eatData: { mealDetails, fetching },
      eatData,
    } = this.props;
    const { paramObject, toastInformation } = this.state;

    const mealDetailsData = !_.isEmpty(mealDetails) ? mealDetails : {};

    return (
      <div>
        <Helmet title={`FITCO | Eats - Meal Details ${paramObject.orderNo || ''} `} />
        <div className="container-page-meal-details scroll-container-invisible">
          <div className="container-page-scrolling-area">
            <Grid container direction="column">
              <Grid item lg md className="section-page-header">
                <Grid container>
                  <Grid item>
                    <div className="breadcrumbs-section">
                      <Breadcrumbs aria-label="breadcrumb">
                        <Link
                          className="text-12"
                          color="inherit"
                          href="/eat/meal-delivery"
                          onClick={event => {
                            this.handleClick(event, '/eat/meal-delivery');
                          }}
                        >
                          <i className="ic-ffo-eats container-icon-prefix size-16" /> Meal Delivery
                        </Link>
                        <label className="text-12" color="inherit">
                          Details
                        </label>
                      </Breadcrumbs>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item lg md className="section-page-body">
                {fetching ? (
                  <SkeletonDetailsV03 />
                ) : (
                  <div className="order-details-card">
                    <Grid
                      className="pb-24"
                      container
                      direction="row"
                      justify="space-between"
                      spacing={3}
                    >
                      <Grid item lg={11} md={11}>
                        <Grid container direction="row">
                          <Grid item className="flex-column order-info">
                            <label className="text-12 text-opacity-50">Order Number</label>
                            <label className="text-12 text-bold pt-4">
                              {mealDetailsData.order_no}
                            </label>
                          </Grid>
                          <Grid item className="flex-column order-info">
                            <label className="text-12 text-opacity-50">Source</label>
                            <label className="text-12 text-bold pt-4">
                              {_.capitalize(mealDetailsData.transaction_source)}
                            </label>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item lg={1} md={1}>
                        <Grid container justify="flex-end">
                          <ButtonMain
                            type="ghost"
                            size="xl"
                            labelText="Back"
                            onClick={this.handleButtonBack}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container>{this.renderOrderCard()}</Grid>
                    <Grid container spacing={4}>
                      <Grid item lg={6} md={6}>
                        {this.renderCustomerDetails()}
                      </Grid>
                      <Grid item lg={6} md={6}>
                        <DetailsShipping
                          orderData={eatData}
                          onClickEditBtn={() => this.handleOpenModalEdit('address')}
                        />
                      </Grid>
                    </Grid>
                    {this.renderOrderSummary()}
                  </div>
                )}
              </Grid>
              <Grid item lg md className="section-page-footer" />
            </Grid>
            <SnackBarSimple
              open={toastInformation.isOpen}
              durationHide={2000}
              message={toastInformation.message}
              onClickClose={this.handleCloseToast}
              snackbarType={toastInformation.snackbarType}
              anchor={optionToast}
            />
            {this.renderModalEdit()}
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  mealDeliveryDetail: params => getMealDeliveryDetail(dispatch, params),
  changeDeliveryAddress: params => changeMealDeliveryAddress(dispatch, params),
  changeDeliveryDetails: params => changeMealDeliveryDetails(dispatch, params),
  skipMeal: params => skipMealDelivery(dispatch, params),
  getShippingAddress: params => getOrderShippingAddress(dispatch, params),
  getShippingAddressInfo: params => getOrderShippingAddressInfo(dispatch, params),
  getShippingCost: params => getOrderShippingCost(dispatch, params),
});

const mapStateToProps = ({ eatData, orderData }) => ({ eatData, orderData });

MealDeliveryDetail.propTypes = {
  changeDeliveryAddress: PropTypes.func,
  changeDeliveryDetails: PropTypes.func,
  eatData: PropTypes.object,
  getShippingAddress: PropTypes.func,
  getShippingAddressInfo: PropTypes.func,
  getShippingCost: PropTypes.func,
  history: PropTypes.object,
  match: PropTypes.object,
  mealDeliveryDetail: PropTypes.func,
  orderData: PropTypes.object,
  skipMeal: PropTypes.func,
};

const shell = compose(connect(mapStateToProps, mapDispatchToProps));
const core = compose(AuthenticationAccessPages, PrevStateValue);

export default shell(core(MealDeliveryDetail));
