import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Radio, FormControl } from '@material-ui/core';
import clsx from 'clsx';
// Style
import './ItemOrderEatCardStyle.scss';
// Component
import { CheckInputButton, TextAreaMain } from '../../../../../components/Index';
// helper
import { CommonHelper, MasterDataHelper } from '../../../../../helpers/Index';

const ItemOrderEatCard = props => {
  const { data, onRadioCheckClick, onButtonAddKindOption, onHandleNotesField } = props;
  const existingItem = CommonHelper.objectCloning(MasterDataHelper.eatSessionType);

  existingItem.forEach(item => {
    item.isVisible = false;
  });

  const handleButtonAddKindOption = (foodListId, pax) => value => {
    onButtonAddKindOption(foodListId, pax, value);
  };

  const handleRadioCheckClick = (foodListId, pax) => {
    const param = { foodListId, pax, isChecked: !data.isChecked };
    onRadioCheckClick(param);
  };

  const handleAddNotes = () => value => {
    onHandleNotesField(value);
  };

  const isSelected = data.isChecked || false;

  return (
    <Grid
      container
      direction="column"
      className={`container-item-order-eat-card p-16 ${clsx({ 'card-active': isSelected })}`}
    >
      <Grid item lg md className="container-header-order-eat">
        <div className="flex-column">
          <Grid container justify="flex-start" className="mb-22">
            <Grid item>
              <Radio
                color="primary"
                checked={isSelected}
                onChange={() => handleRadioCheckClick(data.foodlist_id, data.pax)}
              />
            </Grid>
            <Grid
              item
              lg={10}
              md={10}
              onClick={() => handleRadioCheckClick(data.foodlist_id, data.pax)}
            >
              <div className="flex-column">
                <label className="text-14 text-bold mb-4">{data.pax} Days</label>
                <label className="text-18 text-bold label-price">
                  IDR {CommonHelper.formatCurrency(data.totalPriceExtraSession)}
                </label>
              </div>
            </Grid>
          </Grid>
          <CheckInputButton
            data={data.extraSession || []}
            dataOption={existingItem}
            onButtonClickAdd={handleButtonAddKindOption(data.foodlist_id, data.pax)}
            col={4}
            disabled={!data.isChecked}
          />
          <Grid item className="mt-8 mb-8">
            <FormControl component="fieldset" fullWidth margin="dense">
              <TextAreaMain
                onChange={handleAddNotes(data.foodlist_id, data.pax)}
                placeHolderText="Add Notes"
                rows={3}
                maxLength={250}
                isDisable={!isSelected}
              />
            </FormControl>
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
};

ItemOrderEatCard.propTypes = {
  data: PropTypes.object,
  onButtonAddKindOption: PropTypes.func,
  onHandleNotesField: PropTypes.func,
  onRadioCheckClick: PropTypes.func,
};

export default ItemOrderEatCard;
