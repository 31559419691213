import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';

const GridRowTableOneColumn = props => {
  const {
    customElementColumnFirst,
    customElementColumnSecond,
    customElementColumnThird,
    customElementColumnFourth,
    columnFirst,
    columnSecond,
    columnThird,
    columnFourth,
  } = props;

  return (
    <Grid item className="row-table">
      <Grid container alignItems="center">
        <Grid item lg={columnFirst} md={columnFirst} sm={columnFirst} className="left pl-8">
          {customElementColumnFirst}
        </Grid>
        <Grid item lg={columnSecond} md={columnSecond} sm={columnSecond} className="right">
          {customElementColumnSecond}
        </Grid>
        <Grid item lg={columnThird} md={columnThird} sm={columnThird} className="right">
          {customElementColumnThird}
        </Grid>
        <Grid item lg={columnFourth} md={columnFourth} sm={columnFourth} className="right pr-8">
          {customElementColumnFourth}
        </Grid>
      </Grid>
    </Grid>
  );
};

GridRowTableOneColumn.propTypes = {
  columnFirst: PropTypes.number,
  columnFourth: PropTypes.number,
  columnSecond: PropTypes.number,
  columnThird: PropTypes.number,
  customElementColumnFirst: PropTypes.element,
  customElementColumnFourth: PropTypes.element,
  customElementColumnSecond: PropTypes.element,
  customElementColumnThird: PropTypes.element,
};

GridRowTableOneColumn.defaultProps = {
  columnFirst: 3,
  columnSecond: 3,
  columnThird: 3,
  columnFourth: 3,
};

export default GridRowTableOneColumn;
