export const constants = {
  getListBookingLabRequest: 'GET_LIST_BOOKING_LAB_REQUEST',
  getListBookingLabSuccess: 'GET_LIST_BOOKING_LAB_SUCCESS',
  getListBookingLabFail: 'GET_LIST_BOOKING_LAB_FAIL',
  getBranchCovidTestRequest: 'GET_BRANCH_COVID_TEST_REQUEST',
  getBranchCovidTestSuccess: 'GET_BRANCH_COVID_TEST_SUCCESS',
  getBranchCovidTestFail: 'GET_BRANCH_COVID_TEST_FAIL',
  getProductCovidTestRequest: 'GET_PRODUCT_COVID_TEST_REQUEST',
  getProductCovidTestSuccess: 'GET_PRODUCT_COVID_TEST_SUCCESS',
  getProductCovidTestFail: 'GET_PRODUCT_COVID_TEST_FAIL',
  getProductByServiceLocationRequest: 'GET_PRODUCT_BY_SERVICE_LOCATION_REQUEST',
  getProductByServiceLocationSuccess: 'GET_PRODUCT_BY_SERVICE_LOCATION_SUCCESS',
  getProductByServiceLocationFail: 'GET_PRODUCT_BY_SERVICE_LOCATION_FAIL',
  getApdProductRequest: 'GET_APD_PRODUCT_REQUEST',
  getApdProductSuccess: 'GET_APD_PRODUCT_SUCCESS',
  getApdProductFail: 'GET_APD_PRODUCT_FAIL',
  checkHomeServiceAreaCoverageRequest: 'CHECK_HOME_SERVICE_AREA_COVERAGE_REQUEST',
  checkHomeServiceAreaCoverageSuccess: 'CHECK_HOME_SERVICE_AREA_COVERAGE_SUCCESS',
  checkHomeServiceAreaCoverageFail: 'CHECK_HOME_SERVICE_AREA_COVERAGE_FAIL',
  getBookingResultRequest: 'GET_BOOKING_RESULT_REQUEST',
  getBookingResultSuccess: 'GET_BOOKING_RESULT_SUCCESS',
  getBookingResultFail: 'GET_BOOKING_RESULT_FAIL',
  setReschedulBookingLabRequest: 'SET_RESCHEDULE_BOOKING_LAB_REQUEST',
  setReschedulBookingLabSuccess: 'SET_RESCHEDULE_BOOKING_LAB_SUCCESS',
  setReschedulBookingLabFail: 'SET_RESCHEDULE_BOOKING_LAB_FAIL',
  sendResultLabRequest: 'SEND_RESULT_LAB_REQUEST',
  sendResultLabSuccess: 'SEND_RESULT_LAB_SUCCESS',
  sendResultLabFail: 'SEND_RESULT_LAB_FAIL',
  updateUserDetailRequest: 'UPDATE_USER_DETAIL_REQUEST',
  updateUserDetailSuccess: 'UPDATE_USER_DETAIL_SUCCESS',
  updateUserDetailFail: 'UPDATE_USER_DETAIL_FAIL',
  updateUserBookingRequest: 'UPDATE_USER_BOOKING_REQUEST',
  updateUserBookingSuccess: 'UPDATE_USER_BOOKING_SUCCESS',
  updateUserBookingFail: 'UPDATE_USER_BOOKING_FAIL',
  verifyScanFaceRequest: 'VERIFY_SCAN_FACE_REQUEST',
  verifyScanFaceSuccess: 'VERIFY_SCAN_FACE_SUCCESS',
  verifyScanFaceFail: 'VERIFY_SCAN_FACE_FAIL',
  getHealthPassportActivityRequest: 'GET_HEALTH_PASSPORT_ACTIVITY_REQUEST',
  getHealthPassportActivitySuccess: 'GET_HEALTH_PASSPORT_ACTIVITY_SUCCESS',
  getHealthPassportActivityFail: 'GET_HEALTH_PASSPORT_ACTIVITY_FAIL',
};

const initState = {
  fetching: false,
  errorMessage: null,
  responseMessage: null,
  listBooking: [],
  branchList: [],
  productList: [],
  productByService: [],
  apdProduct: {},
  updateFetching: false,
};

export default function medicReducer(state = initState, action) {
  const { type, payload } = action;
  switch (type) {
    case constants.getListBookingLabRequest:
      return { ...state, fetching: true, responseMessage: null };
    case constants.getListBookingLabSuccess:
      return {
        ...state,
        listBooking: payload.data,
        fetching: false,
      };
    case constants.getListBookingLabFail:
      return { ...state, fetching: false, errorMessage: payload, listBooking: [] };

    case constants.getBranchCovidTestRequest:
      return { ...state, fetching: true, responseMessage: null };
    case constants.getBranchCovidTestSuccess:
      return {
        ...state,
        branchList: payload.data,
        fetching: false,
      };
    case constants.getBranchCovidTestFail:
      return { ...state, fetching: false, errorMessage: payload, listBooking: [] };

    case constants.getProductCovidTestRequest:
      return { ...state, fetching: true, responseMessage: null };
    case constants.getProductCovidTestSuccess:
      return {
        ...state,
        productList: payload.data,
        fetching: false,
      };
    case constants.getProductCovidTestFail:
      return { ...state, fetching: false, errorMessage: payload, listBooking: [] };

    case constants.getProductByServiceLocationRequest:
      return { ...state, fetching: true, responseMessage: null };
    case constants.getProductByServiceLocationSuccess:
      return {
        ...state,
        productByService: payload.data,
        fetching: false,
      };
    case constants.getProductByServiceLocationFail:
      return { ...state, fetching: false, errorMessage: payload, listBooking: [] };

    case constants.getApdProductRequest:
      return { ...state, fetching: true, responseMessage: null };
    case constants.getApdProductSuccess:
      return {
        ...state,
        apdProduct: payload.data,
        fetching: false,
      };
    case constants.getApdProductFail:
      return { ...state, fetching: false, errorMessage: payload, listBooking: [] };

    case constants.setReschedulBookingLabRequest:
      return { ...state, fetching: true, responseMessage: null };
    case constants.setReschedulBookingLabSuccess:
      return {
        ...state,
        fetching: false,
      };
    case constants.setReschedulBookingLabFail:
      return { ...state, fetching: false, errorMessage: payload };

    case constants.updateUserDetailRequest:
      return { ...state, updateFetching: true, responseMessage: null };
    case constants.updateUserDetailSuccess:
      return {
        ...state,
        updateFetching: false,
      };
    case constants.updateUserDetailFail:
      return { ...state, updateFetching: false, errorMessage: payload };

    case constants.updateUserBookingRequest:
      return { ...state, updateFetching: true, responseMessage: null };
    case constants.updateUserBookingSuccess:
      return {
        ...state,
        updateFetching: false,
      };
    case constants.updateUserBookingFail:
      return { ...state, updateFetching: false, errorMessage: payload };

    default:
      return state;
  }
}
