import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import _ from 'lodash';
// component
import { HeaderInbox } from './components/Index';
import { AuthenticationAccessPages, PrevStateValue } from '../../components/Index';
// view
import { InboxPage } from './Index';
// helper
import { compose } from 'redux';
import { MasterDataHelper } from '../../helpers/Index';

const dataTabs = MasterDataHelper.inboxStatus;

class InboxMainPage extends React.Component {
  constructor(props) {
    super(props);
    const {
      usersReducer: { prevStateValue },
    } = props;

    this.state = {
      tabsValue: prevStateValue.activeTab || 0,
    };
  }

  componentDidMount() {
    const {
      onResetPrevStateValue,
      history: { location },
    } = this.props;
    onResetPrevStateValue({ url: location.pathname });
  }

  componentWillReceiveProps(nextProps) {
    const { usersReducer } = nextProps;

    if (_.isEmpty(usersReducer.prevStateValue)) {
      this.handleTabChange(0);
    }
  }

  handleTabChange = value => {
    const {
      onSetPrevStateValueTabs,
      history: { location },
    } = this.props;

    this.setState({ tabsValue: value }, () => {
      onSetPrevStateValueTabs({ activeTab: value, url: location.pathname });
    });
  };

  handleButtonCreateClickInbox = () => {
    const { history } = this.props;
    history.push('/marketing/inbox/add');
  };

  renderPages = () => {
    const { tabsValue } = this.state;
    const { history } = this.props;

    let renderElement = null;

    switch (dataTabs[tabsValue]) {
      case dataTabs[0]:
      case dataTabs[1]:
        renderElement = <InboxPage history={history} pageStatus={dataTabs[tabsValue].value} />;
        break;
      default:
        renderElement = <InboxPage history={history} pageStatus={dataTabs[0].value} />;
        break;
    }

    return renderElement;
  };

  render() {
    return (
      <div>
        <Helmet title={`FITCO | Marketing - Inbox`} />
        <div>
          <HeaderInbox
            data={dataTabs}
            currentTab={this.state.tabsValue}
            onChangeTab={this.handleTabChange}
            onButtonClickInbox={this.handleButtonCreateClickInbox}
          />
          {this.renderPages()}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = () => ({});

const mapStateToProps = () => ({});

InboxMainPage.propTypes = {
  history: PropTypes.object,
  onResetPrevStateValue: PropTypes.func,
  onSetPrevStateValueTabs: PropTypes.func,
  usersReducer: PropTypes.object,
};

const shell = compose(connect(mapStateToProps, mapDispatchToProps));
const core = compose(AuthenticationAccessPages, PrevStateValue);

export default shell(core(InboxMainPage));
