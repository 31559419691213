import { StyleSheet } from '@react-pdf/renderer';
import Themes from '../../../../../themes/Index';

const StylesJs = StyleSheet.create({
  divider: {
    width: '100%',
    height: 1.5,
    backgroundColor: Themes.palette.lightGray,
  },
  fontBold: {
    fontWeight: 'bold',
  },
  containerTitle: {
    backgroundColor: Themes.palette.black,
    paddingVertical: 2,
    paddingHorizontal: 5,
  },
  titleText: {
    color: Themes.palette.black,
    fontSize: 6,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
  },
  text: {
    fontSize: 4,
    color: Themes.palette.black,
    fontFamily: 'Roboto',
  },
  listContainer: {
    paddingHorizontal: 2,
    paddingVertical: 3,
    borderBottomWidth: 0.3,
    borderBottomColor: '#C4C4C4',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#ffffff',
  },
  pageContainer: {
    display: 'flex',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  cardWrapper: {
    paddingLeft: 140,
    paddingRight: 8,
    paddingVertical: 44,
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  cardContainer: {
    width: '2.82cm',
    height: '5.52cm',
    position: 'relative',
  },
  cardCover: {
    position: 'absolute',
    width: '9mm',
    height: '18mm',
  },
});

export default StylesJs;
