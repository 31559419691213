import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import _ from 'lodash';
// Style
import './PurchaseOrderItemDetailsStyle.scss';
// Component
import {
  ButtonIconMain,
  GridRowTableFourColumn,
  GridRowTableOneColumn,
} from '../../../../../components/Index';
import { CommonHelper } from '../../../../../helpers/Index';
// helper

const PurchaseOrderItemDetails = props => {
  const { orderData, onEditProduct, disableItemEdit } = props;

  const orderSummaryData = !_.isEmpty(orderData) ? orderData.details : {};
  const orderItems = orderData.items || [];

  return (
    <Grid container className="p-33" direction="row" spacing={4}>
      <Grid item lg={12} md={12} className="container-order-item-details pt-32">
        <GridRowTableFourColumn
          customElementColumnFirst={<label className="text-14 header-title">Product Name</label>}
          customElementColumnSecond={
            <div className="text-center">
              <label className="text-14 header-title">Price</label>
            </div>
          }
          customElementColumnThird={
            <div className="text-center">
              <label className="text-14 header-title">Quantity</label>
            </div>
          }
          customElementColumnFourth={
            <div className="text-right">
              <label className="text-14 header-title">Subtotal</label>
            </div>
          }
          columnFirst={4}
          columnSecond={3}
          columnThird={2}
          columnFourth={3}
        />
        <div className="scroll-container">
          <Grid container direction="column" className="flex-wrap-unset">
            {orderItems.map((item, index) => {
              // const totalPrice = item.normal_price * item.quantity;
              const subTotal = CommonHelper.formatCurrencyWithDecimals(item.quantity * item.price);
              const priceItem = CommonHelper.formatCurrencyWithDecimals(item.price);

              return (
                <GridRowTableFourColumn
                  key={index}
                  customElementColumnFirst={
                    <div className="flex-column">
                      <label className="text-14 row-item-title wrapping-container wrapping-container-break">
                        {item.product_name}
                      </label>
                      <label className="text-12 wrapping-container wrapping-container-break">
                        Product ID : {item.product_id}
                      </label>
                    </div>
                  }
                  customElementColumnSecond={
                    <div className="text-center">
                      <label className="text-14">IDR {priceItem}</label>
                    </div>
                  }
                  customElementColumnThird={
                    <div className="text-center flex-row justify-center align-center order-quantity">
                      <label className="text-14">{item.quantity}</label>
                      <ButtonIconMain
                        icon="ic-ffo-edit"
                        type="secondary"
                        size="sm"
                        onClick={() => onEditProduct(item.product_id, item.purchase_order_item_id)}
                        disabled={disableItemEdit}
                      />
                    </div>
                  }
                  customElementColumnFourth={
                    <div className="text-right">
                      <label className="text-14">IDR {subTotal}</label>
                    </div>
                  }
                  columnFirst={4}
                  columnSecond={3}
                  columnThird={2}
                  columnFourth={3}
                />
              );
            })}
          </Grid>
        </div>
        <GridRowTableOneColumn
          customElementInitial={
            <div className="text-14">
              <label className="text-14 row-item-title">{`PPN (${orderSummaryData.ppn_percentage})`}</label>
            </div>
          }
          customElementColumn={
            <div className="text-right">
              <label className="text-14">
                IDR {CommonHelper.formatCurrencyWithDecimals(orderSummaryData.total_ppn)}
              </label>
            </div>
          }
          columnLeft={10}
          columnRight={2}
        />
        <GridRowTableOneColumn
          customElementInitial={
            <div className="flex-column">
              <label className="text-14 row-item-title">Shipping Cost</label>
              <label className="text-12 shipping-label">{orderSummaryData.shipping_method}</label>
            </div>
          }
          customElementColumn={
            <div className="text-right">
              <label className="text-14">
                IDR {CommonHelper.formatCurrency(orderSummaryData.shipping_cost)}
              </label>
            </div>
          }
          columnLeft={10}
          columnRight={2}
        />
        <GridRowTableOneColumn
          customElementInitial={<label className="text-14">&nbsp;</label>}
          customElementColumn={
            <div className="text-right">
              <label className="text-16 row-item-title">
                Grand Total &nbsp; &nbsp; &nbsp; IDR&nbsp;
                {CommonHelper.formatCurrencyWithDecimals(orderSummaryData.grand_total)}
              </label>
            </div>
          }
          columnLeft={7}
          columnRight={5}
        />
      </Grid>
    </Grid>
  );
};

PurchaseOrderItemDetails.propTypes = {
  disableItemEdit: PropTypes.bool,
  onEditProduct: PropTypes.func,
  orderData: PropTypes.object,
};

export default PurchaseOrderItemDetails;
