/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { Page, Text, View, Document, Font, Image } from '@react-pdf/renderer';
import _ from 'lodash';
// Style
import StylesJs from './LabelPatientStyle';
// assets
import { Images, StyleJs as StyleJsGlobal } from '../../../../../assets/Index';
// Helpers
import { CommonHelper } from '../../../../../helpers/Index';

// Register font
Font.register({
  family: 'Roboto',
  fonts: [
    { src: './src/assets/fonts/montserrat/Montserrat-Regular.ttf' },
    { src: './src/assets/fonts/montserrat/Montserrat-Bold.ttf', fontWeight: 700 },
  ],
});

const LabelPatient = props => {
  const { data, roomName } = props;
  const letterheadLogo = Images.fitcoLogoLocal;

  return (
    <Document>
      <Page size="A4" orientation="portrait" style={{ paddingVertical: 22, paddingHorizontal: 0 }}>
        {roomName && (
          <View style={{ marginLeft: 85, marginBottom: 16 }} fixed>
            <Text style={[StylesJs.textHeader]}>{roomName}</Text>
          </View>
        )}
        <View style={[StyleJsGlobal.gridRow, { width: 845 }]}>
          {data.map((item, index) => {
            const date = CommonHelper.dateTimeParseNewFormat(item.date, 'DD MMMM YYYY');
            const birthday = CommonHelper.dateTimeParseNewFormat(item.birthday, 'DD MMMM YYYY');
            const isOdd = (index + 1) % 2;
            const shouldHideCuisine =
              _.lowerCase(item.cuisine) === 'snack' ||
              _.lowerCase(item.diet_type).includes('makanan cair');

            return (
              <View key={index} style={[StyleJsGlobal.gridItem6, { width: '100%' }]} wrap={false}>
                <View style={[StyleJsGlobal.gridColumn]}>
                  <View
                    style={[
                      StylesJs.containerCardLabel,
                      isOdd
                        ? { alignSelf: 'flex-end', paddingRight: 3 }
                        : { alignSelf: 'flex-start', paddingLeft: 3 },
                    ]}
                  >
                    <View style={[StylesJs.containerLabel]}>
                      <View
                        style={[StyleJsGlobal.flexRow, StyleJsGlobal.justifyContentSpaceBetween]}
                      >
                        <View style={[StylesJs.flex1]}>
                          <Text style={[StylesJs.textCustomerName]}>RSPAD - Gatot Soebroto</Text>
                          <View style={[StyleJsGlobal.flexRow, StyleJsGlobal.alignItemCenter]}>
                            <Text
                              style={[StylesJs.textRoomName]}
                            >{`${item.room_type} ${item.room_name} - `}</Text>
                            <Text style={[StylesJs.textRoomNumber]}>{item.room_number}</Text>
                          </View>
                        </View>
                      </View>

                      <View
                        style={[
                          StylesJs.containerName,
                          StyleJsGlobal.flexRow,
                          StyleJsGlobal.justifyContentSpaceBetween,
                          StyleJsGlobal.alignItemCenter,
                        ]}
                      >
                        <View style={[StylesJs.flex1]}>
                          <Text style={[StylesJs.textCustomerName]}>
                            {item.title ? `${item.title} ` : ``}
                            {item.name}
                          </Text>
                          <View style={[StyleJsGlobal.flexRow, StyleJsGlobal.alignItemCenter]}>
                            <Text style={[StylesJs.textNotes]}>No RM: </Text>
                            <Text style={[StylesJs.textNotesBold]}>
                              {item.medical_record_number}
                            </Text>
                          </View>
                        </View>
                        <View style={{ alignItems: 'flex-end' }}>
                          <Text style={[StylesJs.textSmallHeading]}>Date of Birth</Text>
                          <Text style={[StylesJs.textNotesBold]}>{birthday}</Text>
                        </View>
                      </View>

                      <View
                        style={[
                          StylesJs.containerName,
                          StyleJsGlobal.flexRow,
                          StyleJsGlobal.justifyContentSpaceBetween,
                          StyleJsGlobal.alignItemCenter,
                        ]}
                      >
                        <View style={[StylesJs.flex1]}>
                          <Text style={[StylesJs.textCustomerName]}>{item.diet_type}</Text>
                          <Text style={[StylesJs.textNotes]}>
                            {item.meal_time}
                            {shouldHideCuisine ? `` : ` - ${item.cuisine}`}
                          </Text>
                        </View>
                        <View style={{ alignItems: 'flex-end' }}>
                          <Text style={[StylesJs.textSmallHeading]}>Meal Date</Text>
                          <Text style={[StylesJs.textSmallNotes]}>{date}</Text>
                        </View>
                      </View>

                      <View style={[StylesJs.containerName]}>
                        <Text style={[StylesJs.textCustomerName]}>Notes</Text>
                        <Text style={[StylesJs.textBiggerNotes]}>
                          {item.notes ? item.notes : '-'}
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            );
          })}
        </View>
      </Page>
    </Document>
  );
};

LabelPatient.propTypes = {
  data: PropTypes.array,
  roomName: PropTypes.string,
};

export default LabelPatient;
