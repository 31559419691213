/* eslint-disable react/no-access-state-in-setstate */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import { Table } from 'antd';
import _ from 'lodash';
// Style
import './UserDetailActivityPageStyle.scss';
// asset
import { Icons } from '../../../../../../assets/Index';
// component
import { SelectInputMain, TextInput, LabelStatusPayment } from '../../../../../../components/Index';
import { ModalUserDetailActivity, ModalUserDetailActivityTopUp } from '../../../components/Index';
// Action
import { getUserActivityPagination } from '../../../../../../services/api/UserManagement';
// Helper
import {
  CommonHelper,
  MasterDataHelper,
  paymentMethod,
  UserHelper,
  TransactionStatus,
} from '../../../../../../helpers/Index';

const initialFilter = {
  search: null,
  merchantId: null,
  orderType: undefined,
};

const initialSort = {
  sortField: 'name',
  sortOrder: '',
};

const orderTypeOption = MasterDataHelper.optionOrderType;

class UserDetailActivityPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: CommonHelper.objectCloning(initialFilter),
      page: 1,
      limit: 10,
      sort: CommonHelper.objectCloning(initialSort),
      pagination: {
        showSizeChanger: true,
        showTotal: (total, range) => `Showing ${range[0]}-${range[1]} From ${total}`,
        pageSizeOptions: ['10', '20', '30', '40'],
      },
      loading: true,
      isOpenModal: '',
      selectedModalItemId: '',
      selectedProductType: null,
    };
    this.searchDebounce = _.debounce(this.searchDebounce, 400);
  }

  componentDidMount() {
    this.getUserActivityPagination();
  }

  getUserActivityPagination() {
    const { userDetailsInfo, userActivityPagination } = this.props;
    const userId = userDetailsInfo.user_id;
    const {
      limit,
      pagination,
      page,
      filter: { search, orderType },
      sort,
    } = this.state;

    const params = {
      search,
      user_id: userId,
      order_type: orderType,
      page,
      limit,
      sort: sort.sortField,
      sortOrder: sort.sortOrder,
    };

    this.setState({ loading: true }, () => {
      userActivityPagination(params)
        .then(response => {
          this.setState({
            loading: false,
            pagination: { ...pagination, pageSize: limit, total: response.total },
          });
        })
        .catch(error => {
          const message = error.data;
          this.processMessage(message.messages, 'error');
        });
    });
  }

  handleChangeSearch = value => {
    const { filter } = this.state;

    this.setState(
      {
        filter: { ...filter, search: value },
        page: 1,
        pagination: { ...this.state.pagination, current: 1 },
      },
      () => {
        this.searchDebounce();
      },
    );
  };

  handleSelectOrderType = value => {
    const { filter } = this.state;

    this.setState(
      {
        page: 1,
        pagination: { ...this.state.pagination, current: 1 },
        filter: {
          ...filter,
          orderType: value || null,
        },
      },
      () => {
        this.getUserActivityPagination();
      },
    );
  };

  handleSelectedItem = (valueId, valueProductType) => {
    const { history } = this.props;
    const param = CommonHelper.encryptObject({ salesOrderId: valueId });
    history.push(`/orders/details/${param}`);
    this.setState({
      // isOpenModal: true,
      selectedModalItemId: valueId,
      selectedProductType: valueProductType,
    });
  };

  handleModalActivityCardClose = value => {
    this.setState({ isOpenModal: value });
  };

  handleTableChange = (pagination, filters, sorter) => {
    const { sort } = this.state;

    this.setState(
      {
        limit: pagination.pageSize,
        pagination: {
          ...this.state.pagination,
          current: pagination.current,
          pageSize: pagination.pageSize,
        },
        sort: {
          ...sort,
          sortField: sorter.field,
          sortOrder: sorter.order === 'ascend' ? 'asc' : 'desc',
        },
        page: pagination.current,
      },
      () => {
        this.getUserActivityPagination();
      },
    );
  };

  searchDebounce() {
    this.getUserActivityPagination();
  }

  renderModalDetail() {
    const { isOpenModal, selectedModalItemId, selectedProductType } = this.state;
    let elementRender = null;

    switch (selectedProductType) {
      case TransactionStatus.WorkoutSession:
      case TransactionStatus.CoachSession:
      case TransactionStatus.PointBooster:
      case TransactionStatus.Retail:
      case TransactionStatus.Food:
        elementRender = (
          <ModalUserDetailActivity
            isOpen={isOpenModal}
            salesOrderId={selectedModalItemId}
            onClose={this.handleModalActivityCardClose}
          />
        );
        break;
      case TransactionStatus.TopUpPoint:
        elementRender = (
          <ModalUserDetailActivityTopUp
            isOpen={isOpenModal}
            salesOrderId={selectedModalItemId}
            onClose={this.handleModalActivityCardClose}
          />
        );
        break;
      default:
        break;
    }
    return isOpenModal ? elementRender : null;
  }

  renderColumns() {
    return [
      {
        title: 'Activity Name',
        dataIndex: 'name',
        sorter: true,
        render: (text, row) => {
          const convert = UserHelper.generateUserActivity(row);

          return (
            <Grid container justify="space-between" className="flex-wrap-unset">
              <Grid item>
                <div className="container-icon-transaction">
                  <i
                    className={`${convert.transactionIcon} container-icon-prefix size-40 transaction-icon-${convert.transactionIconColor}`}
                  />
                </div>
              </Grid>
              <Grid item lg={11} md={11} className="container-row-transaction-summary">
                <Grid
                  container
                  direction="column"
                  className="flex-wrap-unset"
                  onClick={() => this.handleSelectedItem(convert.salesOrderId, convert.productType)}
                >
                  <Grid item>
                    <Grid
                      container
                      direction="row"
                      justify="space-between"
                      className="container-text-header"
                    >
                      <Grid item lg={9} md={9} className="left">
                        <Grid container direction="column">
                          <Grid item>
                            <div>
                              <label className="text-14">{convert.activityTypeName}</label>
                              <label className="text-12">{convert.productName}</label>
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item lg={3} md={3} className="right">
                        <Grid container justify="flex-end">
                          <Grid item>
                            <span>
                              {convert.paymentMethod === paymentMethod.FitPoint ? (
                                <img src={Icons.fitPoints} alt="Fit Points" />
                              ) : (
                                convert.totalPaid !== 0 && (
                                  <label className="text-18 currency">IDR</label>
                                )
                              )}
                              <label className="text-18 currency">
                                {convert.totalPaid !== 0
                                  ? CommonHelper.formatCurrency(convert.totalPaid)
                                  : 'Free'}
                              </label>
                              <LabelStatusPayment status={convert.paymentStatus} />
                            </span>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container justify="space-between" className="container-text-body">
                      <Grid item lg={5} md={5} className="left">
                        <Grid container justify="flex-start">
                          {convert.details.map((item, index) => (
                            <Grid key={index} item lg={6} md={6}>
                              <div className="container-column-content">
                                <label className="text-12">{item.name}</label>
                                <span>
                                  <i className={`${item.icon} container-icon-prefix size-24`} />
                                  <label className="text-12 wrapping-container">
                                    {item.value || '-'}
                                  </label>
                                </span>
                              </div>
                            </Grid>
                          ))}
                        </Grid>
                      </Grid>
                      <Grid item lg={6} md={6} className="right">
                        <Grid
                          container
                          direction="row"
                          justify="flex-end"
                          className="flex-wrap-unset"
                        >
                          <Grid item lg={4} md={4}>
                            <div className="container-column-content">
                              <label className="text-10 text-silver-sand text-semi-bold pb-4">
                                Order Number
                              </label>
                              <label className="text-12">{convert.orderNo}</label>
                            </div>
                          </Grid>
                          <Grid>
                            <div className="divider-hr-vertical" />
                          </Grid>
                          <Grid item lg={4} md={4}>
                            <div className="container-column-content">
                              <label className="text-10 text-silver-sand text-semi-bold pb-4">
                                Transaction Date
                              </label>
                              <label className="text-12">{convert.transactionDate}</label>
                            </div>
                          </Grid>
                          <Grid>
                            <div className="divider-hr-vertical" />
                          </Grid>
                          <Grid item lg={4} md={4}>
                            <div className="container-column-content">
                              <label className="text-10 text-silver-sand text-semi-bold pb-4">
                                Payment Method
                              </label>
                              <label className="text-12 text-semi-bold">
                                {convert.paymentMethod}
                              </label>
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          );
        },
      },
    ];
  }

  renderFilter() {
    return (
      <Grid container justify="flex-start" className="container-filter">
        <Grid item lg={4} md={4} className="row-filter">
          <TextInput
            iconPrefix="ic-ffo-search"
            placeHolderText="Search"
            onChange={this.handleChangeSearch}
            size="md"
          />
        </Grid>
        <Grid item lg={3} md={3} className="row-filter">
          <div className="container-remove-margin">
            <SelectInputMain
              options={orderTypeOption}
              currentValue={this.state.filter.orderType}
              onChange={this.handleSelectOrderType}
              placeholder="Select Order Type"
            />
          </div>
        </Grid>
      </Grid>
    );
  }

  render() {
    const { loading, pagination } = this.state;
    const { userManagement } = this.props;
    const ListCustom = userManagement.listActivity;

    return (
      <Grid container direction="column" className="container-tab-activity flex-wrap-unset">
        <Grid item lg md className="section-table-header">
          {this.renderFilter()}
        </Grid>
        <Grid item lg md className="section-table-body">
          <Table
            className="container-table"
            columns={this.renderColumns()}
            rowKey={record => record.sales_order_id}
            dataSource={ListCustom}
            pagination={pagination}
            loading={loading}
            // scroll={{ y: adjustTableHeight }}
            onChange={this.handleTableChange}
          />
        </Grid>
        {this.state.isOpenModal ? this.renderModalDetail() : null}
      </Grid>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  userActivityPagination: params => getUserActivityPagination(dispatch, params),
});

const mapStateToProps = ({ userManagement }) => ({
  userManagement,
});

UserDetailActivityPage.propTypes = {
  history: PropTypes.object,
  userActivityPagination: PropTypes.func,
  userDetailsInfo: PropTypes.object,
  userManagement: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserDetailActivityPage);
