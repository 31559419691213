/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import _ from 'lodash';
// Component
import {
  InputMain,
  SnackBarSimple,
  PickerInputDate,
  EmptyState,
  SkeletonMain,
  AuthenticationAccessPages,
  ButtonMain,
  PrevStateValue,
  ModalAlertAction,
  GeneratorPdf,
  LabelStatusMain,
} from '../../../../components/Index';
// Api
import {
  getHandoverList,
  deleteHandover,
  getDataPrintHandover,
} from '../../../../services/api/OrdersApi';
// Helpers
import { CommonHelper, PermissionAccess, PermissionModule, PermissionPage } from '../../../../helpers/Index';
// Style
import './HandoverPageStyle.scss';
// Assets
import { Images } from '../../../../assets/Index';

const currentDate = CommonHelper.currentDate('YYYY-MM-DD');
const startDateValue = CommonHelper.getStartDateMonth(currentDate);
const endDateValue = CommonHelper.getEndDateMonth(currentDate);

const optionAlert = {
  vertical: 'top',
  horizontal: 'right',
};

const initialSort = {
  sortField: 'order_date',
  sortOrder: '',
};

const initialFilter = {
  search: null,
  startDate: startDateValue,
  endDate: endDateValue,
};
class HandoverPage extends React.Component {
  constructor(props) {
    super(props);
    const {
      usersReducer: { prevStateValue },
    } = props;

    const updateExitingFilter = {
      ...CommonHelper.objectCloning(initialFilter),
      ...prevStateValue,
    };

    this.state = {
      filter: updateExitingFilter,
      page: 1,
      limit: 10,
      sort: initialSort,
      pagination: {
        showSizeChanger: true,
        showTotal: (total, range) => `Showing ${range[0]}-${range[1]} From ${total}`,
        pageSizeOptions: ['10', '20', '30', '40'],
      },
      loading: true,
      isLoading: true,
      toastInformation: {
        isOpen: false,
        message: '',
        snackbarType: 'warning',
      },
      selectedId: null,
      modalDetailData: {
        title: 'Confirmation',
        body: 'Are you sure want to delete User ID',
      },
      isOpenModal: false,
      selectedDataDelete: null,
      dataPrintListHandover: [],
      isLoadingPrint: false,
    };
    this.searchDebounce = _.debounce(this.searchDebounce, 400);
  }

  componentDidMount() {
    const { getOrderHandoverListPagination } = this;
    getOrderHandoverListPagination();
  }

  componentWillReceiveProps(nextProps) {
    const { usersReducer } = nextProps;
    const {
      onSetPrevStateValue,
      history: { location },
    } = this.props;

    if (_.isEmpty(usersReducer.prevStateValue)) {
      const newValue = {
        url: location.pathname,
      };

      onSetPrevStateValue(newValue);

      this.setState({
        filter: CommonHelper.objectCloning(initialFilter),
        page: 1,
      });
    }
  }

  getOrderHandoverListPagination = () => {
    const { orderHandoverListPagination } = this.props;
    const { limit, page, pagination, sort, filter } = this.state;

    const payload = {
      page,
      limit,
      sort: sort.sortField,
      sortOrder: sort.sortOrder,
      search: filter.search,
      start_date: filter.startDate,
      end_date: filter.endDate,
      search_type: 'name',
    };

    this.setState({ loading: true }, () => {
      orderHandoverListPagination(payload).then(response => {
        this.setState({
          loading: false,
          isLoading: false,
          pagination: { ...pagination, pageSize: limit, total: response.data.total },
        });
      });
    });
  };

  handleChangeSearch = value => {
    const { filter, pagination } = this.state;

    this.setState(
      {
        filter: { ...filter, search: value },
        page: 1,
        pagination: { ...pagination, current: 1 },
      },
      () => {
        this.searchDebounce();
      },
    );
  };

  searchDebounce = () => {
    const { getOrderHandoverListPagination } = this;
    this.setState(
      {
        page: 1,
      },
      () => {
        getOrderHandoverListPagination();
      },
    );
  };

  handleChangeStartDate = value => {
    const { filter } = this.state;
    const { getOrderHandoverListPagination } = this;
    const { onSetPrevStateValue } = this.props;
    const endDate = CommonHelper.getEndDateMonth(value);

    onSetPrevStateValue({ ...filter, startDate: value, endDate });

    this.setState(
      {
        filter: { ...filter, startDate: value, endDate },
      },
      () => {
        getOrderHandoverListPagination();
      },
    );
  };

  handleChangeEndDate = value => {
    const { filter } = this.state;
    const { getOrderHandoverListPagination } = this;
    const { onSetPrevStateValue } = this.props;

    onSetPrevStateValue({ ...filter, endDate: value });

    this.setState(
      {
        filter: { ...filter, endDate: value },
      },
      () => {
        getOrderHandoverListPagination();
      },
    );
  };

  handleTableChange = (pagination, filters, sorter) => {
    const { sort } = this.state;

    this.setState(
      {
        limit: pagination.pageSize,
        pagination: {
          ...this.state.pagination,
          current: pagination.current,
          pageSize: pagination.pageSize,
        },
        sort: {
          ...sort,
          sortField: sorter.field,
          sortOrder: sorter.order === 'ascend' ? '' : 'desc',
        },
        page: pagination.current,
      },
      () => {
        this.getOrderHandoverListPagination();
      },
    );
  };

  handleCloseToash = () => {
    const { toastInformation } = this.state;
    this.setState({ toastInformation: { ...toastInformation, isOpen: false } });
  };

  handleClickRow = orderHandoverId => {
    const { history } = this.props;
    history.push(`/order/handover/details/${orderHandoverId}`);
  };

  handleClickHandover = () => {
    const { history } = this.props;
    history.push('/order/handover/create');
  };

  handleMouseEnter = record => {
    this.setState({ selectedId: record.order_handover_id });
  };

  handleMouseLeave = () => {
    this.setState({ selectedId: null });
  };

  handleButtonPrint = dataRow => {
    const { getPrintHandover } = this.props;
    const orderHandoverId = dataRow.order_handover_id;
    this.setState(
      {
        isLoadingPrint: true,
      },
      async () => {
        await getPrintHandover(orderHandoverId)
          .then(response => {
            const data = response.data;
            this.setState({
              dataPrintListHandover: data,
              isLoadingPrint: false,
            });
          })
          .catch(() => {});
      },
    );
  };

  handleButtonDelete = data => {
    const { modalDetailData } = this.state;

    this.setState({
      isOpenModal: true,
      selectedDataDelete: data,
      modalDetailData: {
        ...modalDetailData,
        body: `Are you sure want to delete Handover ID ${data.order_handover_id} ? `,
      },
    });
  };

  handleCloseModal = () => {
    this.setState({
      isOpenModal: false,
    });
  };

  handleButtonSubmitDelete = () => {
    const { deleteHandoverById } = this.props;
    const { selectedDataDelete } = this.state;

    const orderHandoverId = selectedDataDelete.order_handover_id;

    deleteHandoverById(orderHandoverId).then(() => {
      const {
        orderData: { responseMessage },
      } = this.props;

      const paramInformation = {
        isOpen: true,
        message: responseMessage,
        snackbarType: 'success',
      };

      this.setState(
        {
          toastInformation: paramInformation,
          isOpenModal: false,
          selectedDataDelete: null,
        },
        () => {
          this.getOrderHandoverListPagination();
        },
      );
    });
  };

  renderStatus = value => {
    let renderElement = null;
    if (value) {
      renderElement = <LabelStatusMain value="Printed" type="complete" />;
    }
    return <div className="container-status">{renderElement}</div>;
  };

  renderColumns = () => {
    const { selectedId } = this.state;
    return [
      {
        title: 'Product',
        dataIndex: 'order_handover_id',
        sorter: true,
        render: (text, row) => {
          return (
            <Grid
              container
              direction="row"
              className="row-card-handover flex-wrap-unset"
              key={row.order_handover_id}
            >
              <Grid
                item
                lg={5}
                md={5}
                className="left flex-column"
                onClick={() => {
                  this.handleClickRow(row.order_handover_id);
                }}
              >
                <span className="flex-row">
                  <label className="text-14 text-bold mr-8">{row.name}</label>
                  {this.renderStatus(row.is_printed)}
                </span>
                <label className="text-12 text-opacity-50 pt-4">ID: {row.order_handover_id}</label>
              </Grid>
              <Grid item lg={4} md={4} className="flex-column">
                <label className="text-14 text-bold text-opacity-50">Total Order</label>
                <label className="text-12 text-bold pt-4">
                  {row.total_order > 1
                    ? `${row.total_order} Order List`
                    : `${row.total_order} Order`}
                </label>
              </Grid>
              <Grid item lg={4} md={4} className="left flex-column">
                <label className="text-14 text-bold text-opacity-50">Created By</label>
                <label className="text-12 text-bold pt-4">{row.created_by}</label>
              </Grid>
              <Grid item lg={3} md={3} className="right">
                <Grid container spacing={2} justify="space-between">
                  {row.order_handover_id !== selectedId ? (
                    <Grid item lg md className="flex-column">
                      <label className="text-14 text-bold text-opacity-50">Handover Date</label>
                      <label className="text-12 text-bold pt-4">
                        {`${CommonHelper.dateTimeParseNewFormat(row.handover_date, 'DD MMM YYYY')}`}
                      </label>
                    </Grid>
                  ) : (
                    <React.Fragment>
                      <Grid item lg={6} md={6}>
                        <ButtonMain
                          type="danger"
                          labelText="Delete"
                          onClick={() => this.handleButtonDelete(row)}
                          requiredPermission={`${PermissionModule.Orders}.${PermissionPage.Handover}.${PermissionAccess.Delete}`}
                        />
                      </Grid>
                      <Grid item lg={6} md={6}>
                        {/* <ButtonMain
                          type="ghost"
                          size="xl"
                          labelText="Print"
                          onClick={() => this.handleButtonPrint(row)}
                          isLoading={this.state.isLoadingPrint}
                        /> */}
                        <GeneratorPdf
                          data={this.state.dataPrintListHandover}
                          buttonType="primary"
                          buttonLabel="Print"
                          customLabel="canvas-handover-list"
                          isLoading={this.state.isLoadingPrint}
                          onClick={() => this.handleButtonPrint(row)}
                        />
                      </Grid>
                    </React.Fragment>
                  )}
                </Grid>
              </Grid>
            </Grid>
          );
        },
      },
    ];
  };

  renderFilter() {
    return (
      <Grid container direction="column">
        <Grid item>
          <Grid container direction="row" justify="flex-start" className="container-filter">
            <Grid item lg={3} md={3} className="row-filter">
              <InputMain
                placeholder={'Search'}
                value={this.state.filter.search}
                onChange={this.handleChangeSearch}
                prefix={'ic-ffo-search'}
                size="middle"
              />
            </Grid>
            <Grid item lg={7} md={7}>
              <Grid container direction="row" justify="flex-start" alignItems="center">
                <Grid item lg={3} md={3} class="text-right">
                  <label className="text-12 pr-8">Showing Date</label>
                </Grid>
                <Grid item lg={3} md={3}>
                  <div className="container-remove-margin">
                    <PickerInputDate
                      customIcon="ic-ffo-date-pick"
                      dateFormat="dd/MM/yyyy"
                      defaultValue={this.state.filter.startDate}
                      onChange={this.handleChangeStartDate}
                      toolbar={false}
                    />
                  </div>
                </Grid>
                <label className="text-12 pr-8 pl-8"> - </label>
                <Grid item lg={3} md={3}>
                  <div className="container-remove-margin">
                    <PickerInputDate
                      customIcon="ic-ffo-date-pick"
                      dateFormat="dd/MM/yyyy"
                      minDate={this.state.filter.startDate}
                      defaultValue={this.state.filter.endDate}
                      onChange={this.handleChangeEndDate}
                      toolbar={false}
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={2} md={2}>
              <ButtonMain
                type="primary"
                size="md"
                labelText="Create Handover"
                onClick={this.handleClickHandover}
                requiredPermission={`${PermissionModule.Orders}.${PermissionPage.Handover}.${PermissionAccess.Add}`}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  render() {
    const {
      orderData: { handoverList },
    } = this.props;
    const {
      isLoading,
      loading,
      pagination,
      toastInformation,
      isOpenModal,
      modalDetailData,
    } = this.state;

    if (isLoading) {
      return <SkeletonMain />;
    }

    return (
      <div>
        <Helmet title="FITCO | Orders - Handover" />
        <div className="container-page-handover scroll-container-invisible">
          <div className="container-page-scrolling-area">
            <Grid container direction="column" className="flex-wrap-unset">
              <Grid item lg md className="section-page-header">
                {this.renderFilter()}
              </Grid>
              <Grid item lg md className="section-page-body">
                {_.isEmpty(handoverList) ? (
                  <Grid container justify={'center'}>
                    <EmptyState source={Images.emptyStateNoHistory} labelText={'No Order Found'} />
                  </Grid>
                ) : (
                  <Table
                    columns={this.renderColumns()}
                    rowKey={(record, index) => index}
                    dataSource={handoverList}
                    pagination={pagination}
                    loading={loading}
                    onChange={this.handleTableChange}
                    onRow={record => {
                      return {
                        onMouseEnter: () => {
                          this.handleMouseEnter(record);
                        }, // mouse enter row
                        onMouseLeave: () => {
                          this.handleMouseLeave();
                        },
                      };
                    }}
                  />
                )}
              </Grid>
              <Grid item lg md className="section-page-footer" />
            </Grid>
          </div>
          <SnackBarSimple
            open={toastInformation.isOpen}
            durationHide={2000}
            message={toastInformation.message}
            onClickClose={this.handleCloseToash}
            snackbarType={toastInformation.snackbarType}
            anchor={optionAlert}
          />
          {isOpenModal && (
            <ModalAlertAction
              onButtonSubmit={this.handleButtonSubmitDelete}
              onCloseModal={this.handleCloseModal}
              modalDetail={modalDetailData}
              modalType="danger"
              buttonSubmitText="Submit"
              openModal={isOpenModal}
              isLoading={isLoading}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  orderHandoverListPagination: params => getHandoverList(dispatch, params),
  deleteHandoverById: params => deleteHandover(dispatch, params),
  getPrintHandover: params => getDataPrintHandover(dispatch, params),
});

const mapStateToProps = ({ masterDataMain, orderData, usersReducer }) => ({
  masterDataMain,
  orderData,
  usersReducer,
});

HandoverPage.propTypes = {
  deleteHandoverById: PropTypes.func,
  getPrintHandover: PropTypes.func,
  history: PropTypes.object,
  onSetPrevStateValue: PropTypes.func,
  orderData: PropTypes.object,
  orderHandoverListPagination: PropTypes.func,
  usersReducer: PropTypes.object,
};

const shell = compose(connect(mapStateToProps, mapDispatchToProps));

export default shell(AuthenticationAccessPages(PrevStateValue(HandoverPage)));
