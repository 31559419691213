import { constants } from '../reducers/MembershipReducer';

// #region membership request
export function getMembershipRequestPaginationRequest() {
  return { type: constants.getMembershipRequestPaginationRequest };
}
export function getMembershipRequestPaginationSuccess(response) {
  return { type: constants.getMembershipRequestPaginationSuccess, payload: response };
}
export function getMembershipRequestPaginationFail(response) {
  return { type: constants.getMembershipRequestPaginationFail, payload: response.data };
}

export function getMembershipRequestDetailsRequest() {
  return { type: constants.getMembershipRequestDetailsRequest };
}
export function getMembershipRequestDetailsSuccess(response) {
  return { type: constants.getMembershipRequestDetailsSuccess, payload: response };
}
export function getMembershipRequestDetailsFail(response) {
  return { type: constants.getMembershipRequestDetailsFail, payload: response.data };
}

export function setUpdateMembershipRequestApproveDeclineRequest() {
  return { type: constants.setUpdateMembershipRequestApproveDeclineRequest };
}
export function setUpdateMembershipRequestApproveDeclineSuccess(response) {
  return { type: constants.setUpdateMembershipRequestApproveDeclineSuccess, payload: response };
}
export function setUpdateMembershipRequestApproveDeclineFail(response) {
  return { type: constants.setUpdateMembershipRequestApproveDeclineFail, payload: response.data };
}
// #endregion

// #region membership purchase
export function getMembershipPurchasePaginationRequest() {
  return { type: constants.getMembershipPurchasePaginationRequest };
}
export function getMembershipPurchasePaginationSuccess(response) {
  return { type: constants.getMembershipPurchasePaginationSuccess, payload: response };
}
export function getMembershipPurchasePaginationFail(response) {
  return { type: constants.getMembershipPurchasePaginationFail, payload: response.data };
}

export function getMembershipPurchaseDetailsRequest() {
  return { type: constants.getMembershipPurchaseDetailsRequest };
}
export function getMembershipPurchaseDetailsSuccess(response) {
  return { type: constants.getMembershipPurchaseDetailsSuccess, payload: response };
}
export function getMembershipPurchaseDetailsFail(response) {
  return { type: constants.getMembershipPurchaseDetailsFail, payload: response.data };
}

export function getMembershipPurchaseDetailsByPhoneRequest() {
  return { type: constants.getMembershipPurchaseDetailsByPhoneRequest };
}
export function getMembershipPurchaseDetailsByPhoneSuccess(response) {
  return { type: constants.getMembershipPurchaseDetailsByPhoneSuccess, payload: response };
}
export function getMembershipPurchaseDetailsByPhoneFail(response) {
  return { type: constants.getMembershipPurchaseDetailsByPhoneFail, payload: response.data };
}

export function getMembershipPurchaseRenewalListRequest() {
  return { type: constants.getMembershipPurchaseRenewalListRequest };
}
export function getMembershipPurchaseRenewalListSuccess(response) {
  return { type: constants.getMembershipPurchaseRenewalListSuccess, payload: response };
}
export function getMembershipPurchaseRenewalListFail(response) {
  return { type: constants.getMembershipPurchaseRenewalListFail, payload: response.data };
}

export function getMembershipPurchaseNewListRequest() {
  return { type: constants.getMembershipPurchaseNewListRequest };
}
export function getMembershipPurchaseNewListSuccess(response) {
  return { type: constants.getMembershipPurchaseNewListSuccess, payload: response };
}
export function getMembershipPurchaseNewListFail(response) {
  return { type: constants.getMembershipPurchaseNewListFail, payload: response.data };
}

export function setProcessCreateUserMembershipRequest() {
  return { type: constants.setProcessCreateUserMembershipRequest };
}
export function setProcessCreateUserMembershipSuccess() {
  return { type: constants.setProcessCreateUserMembershipSuccess };
}
export function setProcessCreateUserMembershipFail(response) {
  return { type: constants.setProcessCreateUserMembershipFail, payload: response.data };
}

export function getMembershipPurchaseReportExportRequest() {
  return { type: constants.getMembershipPurchaseReportExportRequest };
}
export function getMembershipPurchaseReportExportSuccess(response) {
  return { type: constants.getMembershipPurchaseReportExportSuccess, payload: response };
}
export function getMembershipPurchaseReportExportFail(response) {
  return { type: constants.getMembershipPurchaseReportExportFail, payload: response.data };
}
// #endregion

// #region membership change payment type
export function getMembershipDetailsByIdRequest() {
  return { type: constants.getMembershipDetailsByIdRequest };
}
export function getMembershipDetailsByIdSuccess(response) {
  return { type: constants.getMembershipDetailsByIdSuccess, payload: response };
}
export function getMembershipDetailsByIdFail(response) {
  return { type: constants.getMembershipDetailsByIdFail, payload: response.data };
}
export function setMembershipRecurringByIdRequest() {
  return { type: constants.setMembershipRecurringByIdRequest };
}
export function setMembershipRecurringByIdSuccess(response) {
  return { type: constants.setMembershipRecurringByIdSuccess, payload: response };
}
export function setMembershipRecurringByIdFail(response) {
  return { type: constants.setMembershipRecurringByIdFail, payload: response.data };
}

export function stopMembershipRecurringByIdRequest() {
  return { type: constants.stopMembershipRecurringByIdRequest };
}
export function stopMembershipRecurringByIdSuccess(response) {
  return { type: constants.stopMembershipRecurringByIdSuccess, payload: response };
}
export function stopMembershipRecurringByIdFail(response) {
  return { type: constants.stopMembershipRecurringByIdFail, payload: response.data };
}

export function unfreezeMembershipRequest() {
  return { type: constants.unfreezeMembershipRequest };
}
export function unfreezeMembershipSuccess(response) {
  return { type: constants.unfreezeMembershipSuccess, payload: response };
}
export function unfreezeMembershipFail(response) {
  return { type: constants.unfreezeMembershipFail, payload: response.data };
}
// #endregion
