import { StyleSheet } from '@react-pdf/renderer';
import Themes from '../../themes/Index';

const StylesJs = StyleSheet.create({
  dividerHr: {
    borderTop: 1,
    borderTopColor: Themes.palette.mysticLight,
  },
  tableBorder: {
    borderStyle: 'solid',
    borderWidth: 1,
    padding: 6,
    flex: 1,
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  gridRow: {
    width: '100%',
    flexDirection: 'row',
    flexWrap: 'wrap',
    display: 'flex',
  },
  gridColumn: {
    flex: 1,
    width: '100%',
    flexDirection: 'column',
    flexWrap: 'wrap',
    display: 'flex',
  },
  alignItemCenter: {
    alignItems: 'center',
  },
  justifyContentCenter: {
    justifyContent: 'center',
  },
  justifyContentSpaceBetween: {
    justifyContent: 'space-between',
  },
  justifyContentFlexStart: {
    justifyContent: 'flex-start',
  },
  justifyContentFlexEnd: {
    justifyContent: 'flex-end',
  },
  gridItem12: {
    flexGrow: 0,
    flexBasis: '100%',
    maxWidth: '100%',
  },
  gridItem11: {
    flexGrow: 0,
    flexBasis: '91.666667%',
    maxWidth: '91.666667%',
  },
  gridItem10: {
    flexGrow: 0,
    flexBasis: '83.333333%',
    maxWidth: '83.333333%',
  },
  gridItem9: {
    flexGrow: 0,
    flexBasis: '75%',
    maxWidth: '75%',
  },
  gridItem8: {
    flexGrow: 0,
    flexBasis: '66.666667%',
    maxWidth: '66.666667%',
  },
  gridItem7: {
    flexGrow: 0,
    flexBasis: '58.333333%',
    maxWidth: '58.333333%',
  },
  gridItem6: {
    flexGrow: 0,
    flexBasis: '50%',
    maxWidth: '50%',
  },
  gridItem5: {
    flexGrow: 0,
    flexBasis: '41.666667%',
    maxWidth: '41.666667%',
  },
  gridItem4: {
    flexGrow: 0,
    flexBasis: '33.333333%',
    maxWidth: '33.333333%',
  },
  gridItem3: {
    flexGrow: 0,
    flexBasis: '25%',
    maxWidth: '25%',
  },
  gridItem25: {
    flexGrow: 0,
    flexBasis: '20,83333333333333%',
    maxWidth: '20,83333333333333%',
  },
  gridItem2: {
    flexGrow: 0,
    flexBasis: '16.666667%',
    maxWidth: '16.666667%',
  },
  gridItem15: {
    flexGrow: 0,
    // flexBasis: '12.333333%',
    // maxWidth: '12.333333%',
    flexBasis: '12.233333%',
    maxWidth: '12.233333%',
  },
  gridItem1: {
    flexGrow: 0,
    flexBasis: '8.333333%',
    maxWidth: '8.333333%',
  },
  gridItem08: {
    flexGrow: 0,
    flexBasis: '7.333333%',
    maxWidth: '7.333333%',
  },
  gridItem07: {
    flexGrow: 0,
    flexBasis: '6.89%',
    maxWidth: '6.89%',
  },
  gridItem05: {
    flexGrow: 0,
    flexBasis: '4.333333%',
    maxWidth: '4.333333%',
  },
  text24: {
    fontSize: 24,
    fontWeight: 'normal',
    color: Themes.palette.mineShaft,
  },
  text18: {
    fontSize: 18,
    fontWeight: 'normal',
    color: Themes.palette.mineShaft,
  },
  text16: {
    fontSize: 16,
    fontWeight: 'normal',
    color: Themes.palette.mineShaft,
  },
  text14: {
    fontSize: 14,
    fontWeight: 'normal',
    color: Themes.palette.mineShaft,
  },
  text12: {
    fontSize: 12,
    fontWeight: 'normal',
    color: Themes.palette.mineShaft,
  },
  text10: {
    fontSize: 10,
    fontWeight: 'normal',
    color: Themes.palette.mineShaft,
  },
  text8: {
    fontSize: 8,
    fontWeight: 'normal',
    color: Themes.palette.mineShaft,
  },
  textJustify: {
    textAlign: 'justify',
  },
  textCenter: {
    textAlign: 'center',
  },
  textRight: {
    textAlign: 'right',
  },
  textWeightBold: {
    fontWeight: 'bold',
  },
  textWeightSemiBold: {
    fontWeight: 'semibold',
  },
  colorApricot: {
    color: Themes.palette.apricot,
  },
  mv12: {
    marginTop: 12,
    marginBottom: 12,
  },
  mv8: {
    marginTop: 8,
    marginBottom: 8,
  },
  mv4: {
    marginTop: 4,
    marginBottom: 4,
  },
  mh10: {
    marginTop: 10,
    marginBottom: 10,
  },
  mh8: {
    marginTop: 8,
    marginBottom: 8,
  },
  mh4: {
    marginTop: 4,
    marginBottom: 4,
  },
  mt32: {
    marginTop: 32,
  },
  mt8: {
    marginTop: 8,
  },
  mt4: {
    marginTop: 4,
  },
  mt16: {
    marginTop: 16,
  },
  mb32: {
    marginBottom: 32,
  },
  mb25: {
    marginBottom: 25,
  },
  mb22: {
    marginBottom: 22,
  },
  mb20: {
    marginBottom: 20,
  },
  mb12: {
    marginBottom: 12,
  },
  mb8: {
    marginBottom: 8,
  },
  mb4: {
    marginBottom: 4,
  },
  mb2: {
    marginBottom: 2,
  },
  ml54: {
    marginLeft: 54,
  },
  ml17: {
    marginLeft: 17,
  },
  ml12: {
    marginLeft: 12,
  },
  mr54: {
    marginRight: 54,
  },
  mr24: {
    marginRight: 24,
  },
  mr22: {
    marginRight: 22,
  },
  mr20: {
    marginRight: 20,
  },
  mr17: {
    marginRight: 17,
  },
  pt16: {
    paddingTop: 16,
  },
  pr25: {
    paddingTop: 25,
  },
  p12: {
    padding: 12,
  },
  p8: {
    padding: 8,
  },
  p4: {
    padding: 4,
  },
  pb12: {
    paddingBottom: 12,
  },
  pb24: {
    paddingBottom: 24,
  },
  ph50: {
    paddingLeft: 50,
    paddingRight: 50,
  },
  ph24: {
    paddingLeft: 24,
    paddingRight: 24,
  },
  ph20: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  ph19: {
    paddingLeft: 19,
    paddingRight: 19,
  },
  ph8: {
    paddingLeft: 8,
    paddingRight: 8,
  },
  ph4: {
    paddingLeft: 4,
    paddingRight: 4,
  },
  pv50: {
    paddingLeft: 50,
    paddingRight: 50,
  },
  pv24: {
    paddingTop: 24,
    paddingRight: 24,
  },
  pv20: {
    paddingTop: 20,
    paddingBottom: 20,
  },
  pv16: {
    paddingTop: 16,
    paddingBottom: 16,
  },
  pv12: {
    paddingTop: 12,
    paddingBottom: 12,
  },
  pv8: {
    paddingTop: 8,
    paddingBottom: 8,
  },
  pv4: {
    paddingTop: 4,
    paddingBottom: 4,
  },
  top20: {
    top: 20,
  },
  top10: {
    top: 10,
  },
});

export default StylesJs;
