import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Grid, Breadcrumbs, Link } from '@material-ui/core';
import PropTypes from 'prop-types';
import _ from 'lodash';
// style
import './PromoCodeAddStyle.scss';
// component
import { NavigationStep, SkeletonDetailsV03, SnackBarSimple } from '../../../../components/Index';
import { ButtonActionFooter } from './components/Index';
import {
  StepPromoCodeDetails,
  StepPromoCodeListGenerate,
  StepPromoCodeMapping,
} from './view/Index';
// helper
import {
  MasterDataHelper,
  CommonHelper,
  HttpStatusCode,
  radioButtonTrueFalseCode,
  PermissionModule,
  PermissionPage,
  PermissionAccess,
} from '../../../../helpers/Index';
// api
import {
  getPromoCodeDetails,
  setCreatePromoCode,
  setUpdatePromoCode,
} from '../../../../services/api/PromoCodeApi';

const radioButtonTrueFalseOption = MasterDataHelper.radioButtonTrueFalse;

const stepListOption = [
  { key: 'details', name: 'Details' },
  { key: 'mapping', name: 'Mapping' },
  { key: 'generate_code', name: 'Generate Code' },
];

const optionToast = {
  vertical: 'top',
  horizontal: 'right',
};

const initialForm = {
  cashbackId: null,
};

class PromoCodeAdd extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      current: 0,
      stepList: CommonHelper.objectCloning(stepListOption),
      form: CommonHelper.objectCloning(initialForm),
      validation: {},
      pagesTitle: {
        helmet: 'FITCO | Marketing - Create Promotion',
        breadcrumbs: 'Create Promotion',
        card: 'Marketing - Promotion',
      },
      isLoading: false,
      isLoadingPage: false,
      toastInformation: {
        isOpen: false,
        message: '',
        snackbarType: 'warning',
      },
    };
  }

  componentDidMount() {
    const {
      match: { params },
    } = this.props;
    const { form, pagesTitle } = this.state;
    const modifyStepList = CommonHelper.objectCloning(stepListOption);
    let modifyPagesTitle = pagesTitle;

    const ifExistParamObject = params.param_object
      ? CommonHelper.decryptObject(params.param_object)
      : {};

    const updateExitingForm = {
      ...CommonHelper.objectCloning(form),
      ...ifExistParamObject,
    };

    if (!updateExitingForm.cashbackId) {
      modifyStepList.splice(-1);
    } else {
      modifyPagesTitle = {
        ...pagesTitle,
        helmet: 'FITCO | Marketing - Edit Promotion',
        breadcrumbs: 'Edit Promotion',
        card: 'Marketing - Promotion',
      };
    }

    this.setState(
      {
        form: updateExitingForm,
        stepList: modifyStepList,
        pagesTitle: modifyPagesTitle,
      },
      () => {
        if (updateExitingForm.cashbackId) {
          this.getPromoCodeDetails();
        }
      },
    );
  }

  getPromoCodeDetails() {
    const { promoCodeDetails } = this.props;
    const { form } = this.state;

    this.setState({ isLoadingPage: true }, () => {
      promoCodeDetails(form.cashbackId)
        .then(async response => {
          const data = response.data;

          const discountPeriodStart = CommonHelper.dateTimeParseNewFormat(
            data.data_promo_code.start_time,
            'YYYY-MM-DD',
          );
          const discountPeriodEnd = CommonHelper.dateTimeParseNewFormat(
            data.data_promo_code.end_time,
            'YYYY-MM-DD',
          );
          const discountPeriodTimeStart = CommonHelper.dateTimeParseNewFormat(
            data.data_promo_code.start_time,
            'HH:mm:ss',
          );
          const discountPeriodTimeEnd = CommonHelper.dateTimeParseNewFormat(
            data.data_promo_code.end_time,
            'HH:mm:ss',
          );
          const status = _.find(MasterDataHelper.statusGlobal, [
            'value',
            data.data_promo_code.status,
          ]);

          const isOnTimeUsing = _.find(MasterDataHelper.radioButtonTrueFalse, [
            'value',
            data.data_promo_code.is_one_time_use,
          ]);

          const isMultiCode = Boolean(data.data_promo_code.using_multiple_codes);

          const isReusableCode = data.data_promo_code.is_reusable_unique_code
            ? _.find(MasterDataHelper.radioButtonTrueFalse, [
                'value',
                data.data_promo_code.is_reusable_unique_code,
              ])
            : MasterDataHelper.radioButtonTrueFalse[1];

          const paymentPlanStatus = _.find(MasterDataHelper.radioButtonTrueFalse, [
            'value',
            data.data_promo_code.include_all_payment_plan,
          ]);

          const paymentMethodStatus = _.find(MasterDataHelper.radioButtonTrueFalse, [
            'value',
            data.data_promo_code.is_all_payment_method,
          ]);
          const merchantStatus = _.find(MasterDataHelper.radioButtonTrueFalse, [
            'value',
            data.data_promo_code.include_all_merchant,
          ]);
          const eatPackageStatus = _.find(MasterDataHelper.radioButtonTrueFalse, [
            'value',
            data.data_promo_code.include_all_eat_package_type,
          ]);
          const productTypeStatus = _.find(MasterDataHelper.radioButtonTrueFalse, [
            'value',
            data.data_promo_code.include_all_product_type,
          ]);
          const productCategoryStatus = _.find(MasterDataHelper.radioButtonTrueFalse, [
            'value',
            data.data_promo_code.include_all_category,
          ]);
          const productStatus = _.find(MasterDataHelper.radioButtonTrueFalse, [
            'value',
            data.data_promo_code.include_all_product,
          ]);

          const availableAtStore = _.find(MasterDataHelper.radioButtonTrueFalse, [
            'value',
            data.data_promo_code.is_available_at_store,
          ]);

          const allStore = _.find(MasterDataHelper.radioButtonTrueFalse, [
            'value',
            data.data_promo_code.include_all_store,
          ]);

          const includeExternalBrand = _.find(MasterDataHelper.radioButtonTrueFalse, [
            'value',
            data.data_promo_code.include_external_brand,
          ]);

          const voucherStatus = data.data_promo_code.voucher_product_id
            ? radioButtonTrueFalseOption[0]
            : radioButtonTrueFalseOption[1];
          const voucher =
            (data.cashback_product &&
              data.cashback_product.product_category &&
              data.cashback_product.product_category.category_id) ||
            null;
          const voucherPrice =
            CommonHelper.formatCurrency(
              data.cashback_product && data.cashback_product.point_price,
            ) || 0;

          const modifyStepList = CommonHelper.objectCloning(stepListOption);

          if (_.isEmpty(data.cashback_codes)) {
            modifyStepList.splice(-1);
          }

          this.setState({
            form: {
              ...form,
              cashbackId: data.data_promo_code.cashback_id,
              name: data.data_promo_code.name,
              promoCode: isMultiCode ? '' : data.data_promo_code.promo_code,
              status,
              description: data.data_promo_code.description,
              type: data.data_promo_code.promo_code_type_id,
              quota: data.data_promo_code.quota,
              isMultiCode,
              multiPromoCodePrefix: isMultiCode ? data.data_promo_code.promo_code : '',
              isOnTimeUsing,
              isReusableCode,
              discountPercentage: data.data_promo_code.percentage,
              maximumDiscount: data.data_promo_code.maximum_reward,
              minimumPurchase: data.data_promo_code.minimum_purchase,
              discountPeriodStart,
              discountPeriodEnd,
              discountPeriodTimeStart,
              discountPeriodTimeEnd,
              discountPeriodDayTimeStart: data.data_promo_code.start_duration,
              discountPeriodDayTimeEnd: data.data_promo_code.end_duration,
              cashbackCodes: data.cashback_codes,
              paymentPlanStatus,
              paymentPlan: data.payment_plan,
              paymentMethodStatus,
              paymentMethod: data.payment_method,
              merchantStatus,
              merchant: data.assigned_merchant,
              availableAtStore,
              allStore,
              includeExternalBrand,
              store: data.assigned_stores,
              eatPackageStatus,
              eatPackage: data.assigned_eat_package_type,
              productTypeStatus,
              productType: data.assigned_product_type,
              productCategoryStatus,
              productCategory: data.assigned_categories,
              productStatus,
              product: data.assigned_products,
              voucherStatus,
              voucher,
              voucherPrice,
            },
            isLoadingPage: false,
            stepList: modifyStepList,
          });
        })
        .catch(async error => {
          const serverMessage = error.data;
          const validationStatus = error.status === HttpStatusCode.InternalServerError;
          this.processMessage(
            validationStatus ? serverMessage.message : serverMessage.messages,
            'error',
          );
        });
    });
  }

  setCreatePromoCode(param) {
    const { createPromoCode, history } = this.props;

    this.setState(
      {
        isLoading: true,
      },
      () => {
        createPromoCode(param)
          .then(response => {
            this.processMessage(response.messages, response.status);
            setTimeout(() => {
              history.push('/marketing/promotion');
            }, 2000);
          })
          .catch(error => {
            const serverMessage = error.data;
            const validationStatus = error.status === HttpStatusCode.InternalServerError;
            this.setState({
              isLoading: false,
            });
            this.processMessage(
              validationStatus ? serverMessage.message : serverMessage.messages,
              'error',
            );
          });
      },
    );
  }

  setUpdatePromoCode(param) {
    const { updatePromoCode, history } = this.props;
    const { form } = this.state;

    updatePromoCode(form.cashbackId, param)
      .then(response => {
        this.processMessage(response.messages, response.status);
        setTimeout(() => {
          history.push('/marketing/promotion');
        }, 2000);
      })
      .catch(error => {
        const serverMessage = error.data;
        const validationStatus = error.status === HttpStatusCode.InternalServerError;
        this.processMessage(
          validationStatus ? serverMessage.message : serverMessage.messages,
          'error',
        );
      });
  }

  handleClick = (event, value) => {
    const { history } = this.props;
    event.preventDefault();
    history.push(value);
  };

  handleButtonClickCancel = () => {
    const { history } = this.props;
    history.push('/marketing/promotion');
  };

  handleButtonClickNext = async value => {
    let checkPass = false;

    switch (value - 1) {
      case 0:
        checkPass = this.validationStepPromCodeDetails();
        break;
      case 1:
        checkPass = this.validationStepPromCodeMapping();
        break;
      default:
        break;
    }

    if (checkPass) {
      this.setState({ current: value });
    }
  };

  handleButtonClickPrev = value => {
    this.setState({ current: value });
  };

  handlePopUpInformation = (message, typeMessage) => {
    this.processMessage(message, typeMessage);
  };

  handleParameterUpdateStepPromoCodeDetails = (formValue, ValidationValue) => {
    const { form } = this.state;
    this.setState({
      form: {
        ...form,
        name: formValue.name,
        promoCode: formValue.promoCode,
        status: formValue.status,
        description: formValue.description,
        type: formValue.type,
        quota: formValue.quota,
        isMultiCode: formValue.isMultiCode,
        multiPromoCodePrefix: formValue.multiPromoCodePrefix,
        isOnTimeUsing: formValue.isOnTimeUsing,
        isReusableCode: formValue.isReusableCode,
        discountPercentage: formValue.discountPercentage,
        maximumDiscount: formValue.maximumDiscount,
        minimumPurchase: formValue.minimumPurchase,
        discountPeriodStart: formValue.discountPeriodStart,
        discountPeriodEnd: formValue.discountPeriodEnd,
        discountPeriodTimeStart: formValue.discountPeriodTimeStart,
        discountPeriodTimeEnd: formValue.discountPeriodTimeEnd,
        discountPeriodDayTimeStart: formValue.discountPeriodDayTimeStart,
        discountPeriodDayTimeEnd: formValue.discountPeriodDayTimeEnd,
        voucherStatus: formValue.voucherStatus,
        voucher: formValue.voucher,
        voucherPrice: formValue.voucherPrice,
      },
      validation: ValidationValue,
    });
  };

  handleParameterUpdateStepPromoCodeMapping = formValue => {
    const { form } = this.state;
    this.setState({
      form: {
        ...form,
        paymentPlanStatus: formValue.paymentPlanStatus,
        paymentPlan: formValue.paymentPlan,
        paymentMethodStatus: formValue.paymentMethodStatus,
        paymentMethod: formValue.paymentMethod,
        merchantStatus: formValue.merchantStatus,
        merchant: formValue.merchant,
        eatPackageStatus: formValue.eatPackageStatus,
        eatPackage: formValue.eatPackage,
        availableAtStore: formValue.availableAtStore,
        allStore: formValue.allStore,
        includeExternalBrand: formValue.includeExternalBrand,
        store: formValue.store,
        productTypeStatus: formValue.productTypeStatus,
        productType: formValue.productType,
        productCategoryStatus: formValue.productCategoryStatus,
        productCategory: formValue.productCategory,
        productStatus: formValue.productStatus,
        product: formValue.product,
      },
    });
  };

  handleButtonClickSubmit = () => {
    const { form } = this.state;

    if (this.validationStepPromCodeDetails && this.validationStepPromCodeMapping) {
      const startTime = `${form.discountPeriodStart} ${form.discountPeriodTimeStart}`;
      const EndTime = `${form.discountPeriodEnd} ${form.discountPeriodTimeEnd}`;
      const promoCode = form.isMultiCode ? form.multiPromoCodePrefix : form.promoCode;
      const isMinimumPurchase = parseInt(form.minimumPurchase, 10) > 0 ? 1 : 0;
      let dataVoucher = null;

      if (form.voucherStatus.value) {
        dataVoucher = {
          point_price: parseInt(form.voucherPrice, 10),
          category_id: form.voucher,
        };
      }

      const param = {
        data: {
          is_voucher: form.voucherStatus.value,
          data_voucher: dataVoucher,
          data_promo_code: {
            cashback_type_id: 4,
            promo_code_type_id: form.type,
            name: form.name,
            using_multiple_codes: form.isMultiCode ? 1 : 0,
            promo_code: promoCode,
            percentage: form.discountPercentage,
            quota: form.quota,
            minimum_purchase: form.minimumPurchase,
            maximum_reward: form.maximumDiscount,
            is_reusable_unique_code: form.isReusableCode.value,
            description: form.description,
            start_time: startTime,
            end_time: EndTime,
            start_duration: form.discountPeriodDayTimeStart,
            end_duration: form.discountPeriodDayTimeEnd,
            is_one_time_use: form.isOnTimeUsing.value,
            is_minimum_purchase: isMinimumPurchase,
            status: form.status.value,
            include_all_product_type: form.productTypeStatus.value,
            include_all_product: form.productStatus.value,
            include_all_category: form.productCategoryStatus.value,
            include_all_merchant: form.merchantStatus.value,
            include_all_payment_plan: form.paymentPlanStatus.value,
            is_all_payment_method: form.paymentMethodStatus.value,
            include_all_eat_package_type: form.eatPackageStatus.value,
            is_available_at_store: form.availableAtStore.value,
            include_all_store: form.allStore.value,
            include_external_brand: form.includeExternalBrand.value,
            is_free_shipping: 0,
          },
          payment_plan: form.paymentPlan,
          payment_method: form.paymentMethod,
          assigned_product_type: form.productType,
          assigned_merchant: form.merchant,
          assigned_products: form.product,
          assigned_package_types: form.eatPackage,
          assigned_categories: form.productCategory,
          assigned_stores: form.store,
        },
      };

      if (!form.cashbackId) {
        this.setCreatePromoCode(param);
      } else {
        this.setUpdatePromoCode(param);
      }
    }
  };

  handleCloseToast = () => {
    const { toastInformation } = this.state;
    this.setState({ toastInformation: { ...toastInformation, isOpen: false } });
  };

  validationStepPromCodeDetails() {
    const {
      form: {
        name,
        promoCode,
        description,
        quota,
        isMultiCode,
        multiPromoCodePrefix,
        discountPercentage,
        maximumDiscount,
        minimumPurchase,
        discountPeriodStart,
        discountPeriodEnd,
        discountPeriodTimeStart,
        discountPeriodTimeEnd,
        discountPeriodDayTimeStart,
        discountPeriodDayTimeEnd,
        voucherStatus,
        voucher,
        voucherPrice,
      },
      validation,
    } = this.state;

    const messageV1 = { isError: false, errorMessage: '' };
    const messageV2 = { isError: '', errorMessage: '' };

    let passCheck = false;
    let passCheckGroup01 = true;
    let passCheckGroup02 = true;
    let passCheckGroup03 = true;
    const paramValidation01 = CommonHelper.objectCloning(messageV1);
    const paramValidation02 = CommonHelper.objectCloning(messageV2);
    const paramValidation03 = CommonHelper.objectCloning(messageV1);
    const paramValidation04 = CommonHelper.objectCloning(messageV1);
    const paramValidation05 = CommonHelper.objectCloning(messageV2);
    const paramValidation06 = CommonHelper.objectCloning(messageV2);
    const paramValidation07 = CommonHelper.objectCloning(messageV2);
    const paramValidation08 = CommonHelper.objectCloning(messageV2);
    const paramValidation09 = CommonHelper.objectCloning(messageV2);
    const paramValidation10 = CommonHelper.objectCloning(messageV2);
    const paramValidation11 = CommonHelper.objectCloning(messageV2);
    const paramValidation12 = CommonHelper.objectCloning(messageV1);
    const paramValidation13 = CommonHelper.objectCloning(messageV2);
    const paramValidation14 = CommonHelper.objectCloning(messageV2);

    paramValidation01.isError = !name;
    paramValidation01.errorMessage = !name ? 'Please enter Promo Name' : '';

    paramValidation02.isError = !description ? 'error' : '';
    paramValidation02.errorMessage = !description ? 'Please enter Description' : '';

    if (isMultiCode) {
      paramValidation03.isError = !(quota > 0);
      paramValidation03.errorMessage = !quota > 0 ? 'Please enter Quota' : '';

      paramValidation04.isError = !multiPromoCodePrefix;
      paramValidation04.errorMessage = !multiPromoCodePrefix ? 'Please enter Prefix Code' : '';
      passCheckGroup03 = multiPromoCodePrefix;
    } else {
      const temporaryValidation = !_.isEmpty(validation) ? validation.promoCode.isError : false;

      paramValidation12.isError = !!(!promoCode || temporaryValidation);
      paramValidation12.errorMessage =
        !promoCode || temporaryValidation ? 'Please enter Promo Code' : '';
      passCheckGroup03 = !!(promoCode || !temporaryValidation);
    }

    if (voucherStatus.value === radioButtonTrueFalseCode.Yes) {
      paramValidation13.isError = !voucher ? 'error' : '';
      paramValidation13.errorMessage = !voucher ? 'Please select voucher category' : '';

      paramValidation14.isError = !(voucherPrice > 0) ? 'error' : '';
      paramValidation14.errorMessage = !(voucherPrice > 0) ? 'Please input voucher price' : '';
    }

    paramValidation05.isError = !discountPercentage > 0 ? 'error' : '';
    paramValidation05.errorMessage = !discountPercentage > 0 ? 'Please enter Percentage' : '';
    paramValidation06.isError = !maximumDiscount && maximumDiscount === '' ? 'error' : '';
    paramValidation06.errorMessage =
      !maximumDiscount && maximumDiscount === '' ? 'Please enter Maximum Discount' : '';
    paramValidation07.isError = !minimumPurchase && minimumPurchase === '' ? 'error' : '';
    paramValidation07.errorMessage =
      !minimumPurchase && minimumPurchase === '' ? 'Please enter Minimum Purchase' : '';

    passCheckGroup01 = CommonHelper.checkValidDate(discountPeriodStart);
    passCheckGroup02 = CommonHelper.checkValidDate(discountPeriodEnd);

    const temporaryValidation01 = !_.isEmpty(validation)
      ? validation.discountPeriodTimeStart.isError
      : false;

    paramValidation08.isError = !discountPeriodTimeStart || temporaryValidation01 ? 'error' : '';
    paramValidation08.errorMessage =
      !discountPeriodTimeStart || temporaryValidation01
        ? temporaryValidation01.errorMessage || ''
        : '';

    const temporaryValidation02 = !_.isEmpty(validation)
      ? validation.discountPeriodTimeEnd.isError
      : false;

    paramValidation09.isError = !discountPeriodTimeEnd || temporaryValidation02 ? 'error' : '';
    paramValidation09.errorMessage =
      !discountPeriodTimeEnd || temporaryValidation02 ? temporaryValidation02.errorMessage : '';

    const temporaryValidation03 = !_.isEmpty(validation)
      ? validation.discountPeriodDayTimeStart
      : {};

    paramValidation10.isError =
      !discountPeriodDayTimeStart || temporaryValidation03.isError ? 'error' : '';
    paramValidation10.errorMessage =
      !discountPeriodDayTimeStart || temporaryValidation03.isError
        ? temporaryValidation03.errorMessage
        : '';

    const temporaryValidation04 = !_.isEmpty(validation)
      ? validation.discountPeriodDayTimeEnd.isError
      : false;

    paramValidation11.isError = !discountPeriodDayTimeEnd || temporaryValidation04 ? 'error' : '';
    paramValidation11.errorMessage =
      !discountPeriodDayTimeEnd || temporaryValidation04 ? temporaryValidation04.errorMessage : '';

    if (
      !paramValidation01.isError &&
      !paramValidation02.isError &&
      !paramValidation03.isError &&
      !paramValidation05.isError &&
      !paramValidation06.isError &&
      !paramValidation07.isError &&
      passCheckGroup01 &&
      passCheckGroup02 &&
      passCheckGroup03 &&
      !paramValidation08.isError &&
      !paramValidation09.isError &&
      !paramValidation10.isError &&
      !paramValidation11.isError &&
      !paramValidation13.isError &&
      !paramValidation14.isError
    ) {
      passCheck = true;
    }

    this.setState({
      validation: {
        ...validation,
        name: paramValidation01,
        description: paramValidation02,
        quota: paramValidation03,
        multiPromoCodePrefix: paramValidation04,
        discountPercentage: paramValidation05,
        maximumDiscount: paramValidation06,
        minimumPurchase: paramValidation07,
        discountPeriodTimeStart: paramValidation08,
        discountPeriodTimeEnd: paramValidation09,
        discountPeriodDayTimeStart: paramValidation10,
        discountPeriodDayTimeEnd: paramValidation11,
        promoCode: paramValidation12,
        voucher: paramValidation13,
        voucherPrice: paramValidation14,
      },
    });

    return passCheck;
  }

  validationStepPromCodeMapping() {
    const {
      form: {
        paymentMethodStatus,
        paymentMethod,
        merchantStatus,
        merchant,
        // eatPackageStatus,
        // eatPackage,
        productTypeStatus,
        productType,
        productCategoryStatus,
        productCategory,
        productStatus,
        product,
      },
      validation,
    } = this.state;

    const messageV2 = { isError: '', errorMessage: '' };

    let passCheck = false;
    const paramValidation01 = CommonHelper.objectCloning(messageV2);
    const paramValidation02 = CommonHelper.objectCloning(messageV2);
    // const paramValidation03 = CommonHelper.objectCloning(messageV2);
    const paramValidation04 = CommonHelper.objectCloning(messageV2);
    const paramValidation05 = CommonHelper.objectCloning(messageV2);
    const paramValidation06 = CommonHelper.objectCloning(messageV2);

    if (paymentMethodStatus.value !== radioButtonTrueFalseCode.Yes) {
      paramValidation01.isError = _.isEmpty(paymentMethod) ? 'error' : '';
      paramValidation01.errorMessage = _.isEmpty(paymentMethod)
        ? 'Please Select Payment Method'
        : '';
    }

    if (merchantStatus.value !== radioButtonTrueFalseCode.Yes) {
      paramValidation02.isError = _.isEmpty(merchant) ? 'error' : '';
      paramValidation02.errorMessage = _.isEmpty(merchant) ? 'Please Select Merchant' : '';
    }

    // if (eatPackageStatus !== radioButtonTrueFalseCode.Yes) {
    //   paramValidation03.isError = _.isEmpty(eatPackage) ? 'error' : '';
    //   paramValidation03.errorMessage = _.isEmpty(eatPackage) ? 'Please Select Package' : '';
    // }

    if (productTypeStatus.value !== radioButtonTrueFalseCode.Yes) {
      paramValidation04.isError = _.isEmpty(productType) ? 'error' : '';
      paramValidation04.errorMessage = _.isEmpty(productType) ? 'Please Select Product Type' : '';
    }

    if (productCategoryStatus.value !== radioButtonTrueFalseCode.Yes) {
      paramValidation05.isError = _.isEmpty(productCategory) ? 'error' : '';
      paramValidation05.errorMessage = _.isEmpty(productCategory)
        ? 'Please Select Product Category'
        : '';
    }

    if (productStatus.value !== radioButtonTrueFalseCode.Yes) {
      paramValidation06.isError = _.isEmpty(product) ? 'error' : '';
      paramValidation06.errorMessage = _.isEmpty(product) ? 'Please Select Product' : '';
    }

    if (
      !paramValidation01.isError &&
      !paramValidation02.isError &&
      // !paramValidation03.isError &&
      !paramValidation04.isError &&
      !paramValidation05.isError &&
      !paramValidation06.isError
    ) {
      passCheck = true;
    }

    this.setState({
      validation: {
        ...validation,
        paymentMethod: paramValidation01,
        merchant: paramValidation02,
        // eatPackage: paramValidation03,
        productType: paramValidation04,
        productCategory: paramValidation05,
        product: paramValidation06,
      },
    });

    return passCheck;
  }

  processMessage(messages, type) {
    const convertedMessage = CommonHelper.generateMessage(messages);

    const paramInformation = {
      isOpen: true,
      message: convertedMessage,
      snackbarType: type,
    };

    this.setState({
      toastInformation: paramInformation,
    });
  }

  renderActiveContent() {
    const { stepList, current, form, validation, pagesTitle } = this.state;
    let renderElement = null;

    switch (stepList[current].key) {
      case stepListOption[1].key:
        renderElement = (
          <StepPromoCodeMapping
            customElementPropsFormLabel={
              <label className="text-16 text-rolling-stone text-bold">{pagesTitle.card}</label>
            }
            customElementPropsStepper={
              <NavigationStep currentValue={current} arrayLabel={stepList} />
            }
            parentForm={form}
            parentValidation={validation}
            onParameterUpdate={this.handleParameterUpdateStepPromoCodeMapping}
          />
        );
        break;

      case stepListOption[2].key:
        renderElement = (
          <StepPromoCodeListGenerate
            customElementPropsFormLabel={
              <label className="text-16 text-rolling-stone text-bold">{pagesTitle.card}</label>
            }
            customElementPropsStepper={
              <NavigationStep currentValue={current} arrayLabel={stepList} />
            }
            parentForm={form}
          />
        );
        break;

      default:
        renderElement = (
          <StepPromoCodeDetails
            customElementPropsFormLabel={
              <label className="text-16 text-rolling-stone text-bold">{pagesTitle.card}</label>
            }
            customElementPropsStepper={
              <NavigationStep currentValue={current} arrayLabel={stepList} />
            }
            parentForm={form}
            parentValidation={validation}
            onParameterUpdate={this.handleParameterUpdateStepPromoCodeDetails}
            onPopUpInformation={this.handlePopUpInformation}
          />
        );
        break;
    }

    return renderElement;
  }

  render() {
    const {
      current,
      stepList,
      toastInformation,
      isOpenModalEatSummary,
      pagesTitle,
      isLoading,
      isLoadingPage,
    } = this.state;
    const prevUrl = '/marketing/promotion';

    const {
      promoCodeData: { fetching },
      masterData,
    } = this.props;

    const isPrevButtonVisible = current !== 0;
    const isSubmitButtonVisible = current === stepList.length - 1;

    let renderElement = <SkeletonDetailsV03 />;

    if (!isLoadingPage) {
      renderElement = (
        <div>
          <Helmet title={pagesTitle.helmet} />
          <div className="container-page-promo-code-create scroll-container-invisible">
            <div className={`container-page-scrolling-area include-footer`}>
              <Grid container direction="column" className="flex-wrap-unset">
                <Grid item lg md className="section-page-header">
                  <div className="breadcrumbs-section">
                    <Breadcrumbs aria-label="breadcrumb">
                      <Link
                        className="text-12"
                        color="inherit"
                        href={prevUrl}
                        onClick={event => {
                          this.handleClick(event, prevUrl);
                        }}
                      >
                        <i className="ic-ffo-reports container-icon-prefix size-16 pr-4" />{' '}
                        Marketing
                      </Link>
                      <Link
                        className="text-12"
                        color="inherit"
                        href={prevUrl}
                        onClick={event => {
                          this.handleClick(event, prevUrl);
                        }}
                      >
                        <label className="text-12" color="inherit">
                          Promo Code
                        </label>
                      </Link>
                      <label className="text-12" color="inherit">
                        {pagesTitle.breadcrumbs}
                      </label>
                    </Breadcrumbs>
                  </div>
                </Grid>
                <Grid item lg md className="section-page-body">
                  <Grid container direction="column" className="flex-wrap-unset">
                    {this.renderActiveContent()}
                  </Grid>
                </Grid>
                <Grid item lg md className="section-page-footer" />
              </Grid>
            </div>
          </div>
          <div className="container-page-promo-code-create-floating">
            <ButtonActionFooter
              currentValue={current}
              totalStepper={stepList.length}
              loadingButtonNext={fetching || masterData.fetching || isLoading}
              onButtonClickCancel={this.handleButtonClickCancel}
              onButtonClickPrev={this.handleButtonClickPrev}
              onButtonClickNext={this.handleButtonClickNext}
              onSubmitClick={this.handleButtonClickSubmit}
              visiblePrev={isPrevButtonVisible}
              visibleSubmit={isSubmitButtonVisible}
              requiredSubmitPermission={`${PermissionModule.Marketing}.${PermissionPage.PromoCode}.${PermissionAccess.Add}`}
            />
          </div>
          {isOpenModalEatSummary && this.renderModalSummaryOrderEats()}
          <SnackBarSimple
            open={toastInformation.isOpen}
            durationHide={2000}
            message={toastInformation.message}
            onClickClose={this.handleCloseToast}
            snackbarType={toastInformation.snackbarType}
            anchor={optionToast}
          />
        </div>
      );
    }

    return renderElement;
  }
}

const mapDispatchToProps = dispatch => ({
  promoCodeDetails: id => getPromoCodeDetails(dispatch, id),
  createPromoCode: params => setCreatePromoCode(dispatch, params),
  updatePromoCode: (id, params) => setUpdatePromoCode(dispatch, id, params),
});

const mapStateToProps = ({ promoCodeData, masterData }) => ({
  promoCodeData,
  masterData,
});

PromoCodeAdd.propTypes = {
  createPromoCode: PropTypes.func,
  history: PropTypes.object,
  masterData: PropTypes.object,
  match: PropTypes.object,
  promoCodeData: PropTypes.object,
  promoCodeDetails: PropTypes.func,
  updatePromoCode: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(PromoCodeAdd);
