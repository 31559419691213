import themes from '../themes/Index';
import { CommonHelper } from './Index';

const borderColor = themes.palette.mineShaft.replace('#', '');
const borderWight = 'thin'; // 'medium';

const borderFull = {
  top: { style: borderWight, color: { rgb: borderColor } },
  bottom: { style: borderWight, color: { rgb: borderColor } },
  left: { style: borderWight, color: { rgb: borderColor } },
  right: { style: borderWight, color: { rgb: borderColor } },
};

// eslint-disable-next-line no-unused-vars
const borderVertical = {
  top: { style: borderWight, color: { rgb: borderColor } },
  bottom: { style: borderWight, color: { rgb: borderColor } },
};

const borderHorizontal = {
  left: { style: borderWight, color: { rgb: borderColor } },
  right: { style: borderWight, color: { rgb: borderColor } },
};

const borderBottom = {
  left: { style: borderWight, color: { rgb: borderColor } },
  right: { style: borderWight, color: { rgb: borderColor } },
  bottom: { style: borderWight, color: { rgb: borderColor } },
};

const fontOption = {
  font: { bold: true },
};

const ExportHelper = {
  generateCellStyle(dataArray) {
    const convertedHeader = [];
    const converted = [];

    for (let index = 0; index < dataArray.length; index += 1) {
      const item = dataArray[index];
      const rowStyled = [];
      const rowHeaderStyled = [];
      let activeStyle = {};

      Object.keys(item).forEach(key => {
        if (index === 0) {
          convertedHeader.push('');

          activeStyle = borderHorizontal;

          const paramHeader = {
            value: CommonHelper.renameRemoveCharacter(key, '_'),
            style: { border: borderFull, font: fontOption },
          };

          rowHeaderStyled.push(paramHeader);
        } else if (index + 1 === dataArray.length) {
          activeStyle = borderBottom;
        } else {
          activeStyle = borderHorizontal;
        }

        const paramRow = {
          value: item[key] ? item[key].toString() : '',
          style: { border: activeStyle },
        };

        rowStyled.push(paramRow);
      });

      if (index === 0) {
        converted.push(rowHeaderStyled);
      }

      converted.push(rowStyled);
    }

    const result = { header: convertedHeader, body: converted };
    return result;
  },
};

export default ExportHelper;
