/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { Popover } from 'antd';

// Style
import './FitcoPopoverStyle.scss';

const FitcoPopover = props => {
  const { content, placement, title, trigger } = props;

  return (
    <Popover placement={placement} title={title} content={content} trigger={trigger}>
      <i className="container-icon-prefix ic-ffo-alert popover-info" />
    </Popover>
  );
};

FitcoPopover.propTypes = {
  content: PropTypes.element,
  placement: PropTypes.string,
  title: PropTypes.element,
  trigger: PropTypes.string,
};

export default FitcoPopover;
