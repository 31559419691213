/* eslint-disable no-unused-vars */
import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Grid, Breadcrumbs, Link } from '@material-ui/core';
import PropTypes from 'prop-types';
import { compose } from 'redux';
// style
import './MembershipPurchaseAddStyle.scss';
// component
import {
  NavigationStep,
  SnackBarSimple,
  AuthenticationAccessPages,
} from '../../../../../components/Index';
import {
  StepMembershipPurchaseAddUserDetails,
  StepMembershipPurchaseAddMembershipDetails,
  StepMembershipPurchaseSummaryAndPayment,
} from './views/Index';
// helper
import {
  CommonHelper,
  HttpStatusCode,
  PermissionModule,
  PermissionPage,
  PermissionAccess,
} from '../../../../../helpers/Index';
// api
import { setProcessCreateUserMembership } from '../../../../../services/api/MembershipApi';

const currentDate = CommonHelper.currentDate('YYYY-MM-DD');

const optionToash = {
  vertical: 'top',
  horizontal: 'right',
};

class MembershipPurchaseAdd extends React.Component {
  constructor(props) {
    super(props);

    props.checkUserAccessPermission(
      PermissionModule.Memberships,
      PermissionPage.Purchase,
      PermissionAccess.Add,
    );

    this.state = {
      current: 0,
      form: {},
      validation: {},
      minDate: null,
      membershipId: null,
      stepList: [
        { key: 'user_detail', name: 'User Details' },
        { key: 'membership_detail', name: 'Membership Details' },
        { key: 'payment_detail', name: 'Payment Details' },
      ],
      toastInformation: {
        isOpen: false,
        message: '',
        snackbarType: 'warning',
      },
    };
  }

  componentDidMount() {
    const {
      match: { params },
    } = this.props;
    const { form } = this.state;
    const ifExistParamObject = params.param_object
      ? CommonHelper.decryptObject(params.param_object)
      : {};
    if (ifExistParamObject.phone) {
      this.setState({
        form: { ...form, phone: ifExistParamObject.phone },
        minDate: ifExistParamObject.minDate,
        membershipId: ifExistParamObject.membershipId,
      });
    }
  }

  async componentDidUpdate() {
    const {
      membership: { fetching, errorMessage },
      history,
    } = this.props;

    if (!fetching && errorMessage && errorMessage.code === HttpStatusCode.Forbidden) {
      history.push('/membership/purchase');
    }
  }

  componentWillUnmount() {}

  handleClick = (event, value) => {
    const { history } = this.props;
    event.preventDefault();
    history.push(value);
  };

  handleButtonClickCancel = () => {
    const { history } = this.props;
    history.push('/membership/purchase');
  };

  handleButtonClickNext = value => {
    let checkPass = false;
    // const {
    //   filter: { orderType },
    // } = this.state;
    // let isRetail = null;

    switch (value - 1) {
      case 0:
        checkPass = this.validationStepUserDetails();
        break;
      case 1:
        checkPass = this.validationStepMembershipDetails();
        break;
      default:
        break;
    }

    if (checkPass) {
      this.setState({ current: value });
    }
  };

  handleButtonClickPrev = value => {
    this.setState({ current: value });
  };

  handleParameterUpdateStepUserDetails = (formValue, ValidationValue) => {
    const { form, validation } = this.state;
    this.setState({
      form: {
        ...form,
        isExisting: formValue.isExisting,
        userId: formValue.userId,
        phone: formValue.phone,
        firstName: formValue.firstName,
        lastName: formValue.lastName,
        email: formValue.email,
        gender: formValue.gender,
      },
      validation: { ...validation, email: ValidationValue.email },
    });
  };

  handleParameterUpdateStepMembershipDetails = (formValue, ValidationValue) => {
    const { form } = this.state;
    this.setState({
      form: {
        ...form,
        typeActionBooster: formValue.typeActionBooster,
        merchantId: formValue.merchantId,
        branchId: formValue.branchId,
        membershipId: formValue.membershipId,
        startDate: formValue.startDate,
        selectedMembershipDetails: formValue.selectedMembershipDetails,
        items: formValue.items,
      },
    });
  };

  handleParameterUpdateStepMembershipSummary = (formValue, validationValue) => {
    const { form, validation } = this.state;
    this.setState({
      form: {
        ...form,
        paymentMethod: formValue.paymentMethod,
        bank: formValue.bank,
        paymentTerm: formValue.paymentTerm,
        promoCode: formValue.promoCode,
      },
      validation: {
        ...validation,
        promoCode: validationValue.promoCode,
      },
    });
  };

  handleButtonClickSubmit = () => {
    const { processCreateUserMembership, history } = this.props;
    const {
      form,
      validation: { promoCode },
    } = this.state;

    if (!promoCode.isError) {
      const modifyName = `${form.firstName} ${form.lastName}`;

      const param = {
        newOrExisting: !form.isExisting ? 'New' : 'Existing',
        user_id: form.userId || '',
        name: modifyName.trim(),
        phone: form.phone,
        email: form.email,
        action: form.typeActionBooster,
        merchant_id: form.merchantId,
        branch_id: form.branchId,
        product_id: form.selectedMembershipDetails.option.product_id,
        start_date: form.startDate,
        promo_code: form.promoCode,
        payment_type: form.paymentMethod,
        bank: form.bank.value || '',
        paymentTerm: form.paymentTerm,
      };

      processCreateUserMembership(param)
        .then(async response => {
          const message = response.messages;
          await this.processMessage(message, 'success');
          await setTimeout(async () => {
            await history.push('/membership/purchase');
          }, 2000);
        })
        .catch(error => {
          const serverMessage = error.data;
          const validationStatus = error.status === HttpStatusCode.InternalServerError;
          this.processMessage(
            validationStatus ? serverMessage.message : serverMessage.messages,
            'error',
          );
        });
    } else {
      this.processMessage('Promo Code not Valid, Check your Promo Code', 'warning');
    }
  };

  handlePopUpInformation = (message, typeMessage) => {
    this.processMessage(message, typeMessage);
  };

  handleCloseToash = () => {
    const { toastInformation } = this.state;
    this.setState({ toastInformation: { ...toastInformation, isOpen: false } });
  };

  validationStepUserDetails() {
    const {
      form: { phone, firstName, email },
      validation,
    } = this.state;

    const message = { isError: false, errorMessage: '' };

    let passCheck = false;
    const paramValidation01 = CommonHelper.objectCloning(message);
    const paramValidation02 = CommonHelper.objectCloning(message);
    const paramValidation03 = CommonHelper.objectCloning(message);

    paramValidation01.isError = !phone;
    paramValidation01.errorMessage = !phone ? 'Please enter Phone' : '';

    paramValidation02.isError = !firstName;
    paramValidation02.errorMessage = !firstName ? 'Please enter First Name' : '';

    if (!validation.email.isError) {
      paramValidation03.isError = !email;
      paramValidation03.errorMessage = !email ? 'Please enter Email' : '';
    } else {
      paramValidation03.isError = validation.email.isError;
      paramValidation03.errorMessage = validation.email.errorMessage;
    }

    if (!paramValidation01.isError && !paramValidation02.isError && !paramValidation03.isError) {
      passCheck = true;
    }

    this.setState({
      validation: {
        ...validation,
        phone: paramValidation01,
        firstName: paramValidation02,
        email: paramValidation03,
      },
    });

    return passCheck;
  }

  validationStepMembershipDetails() {
    const {
      form: { membershipId, startDate, branchId },
      validation,
    } = this.state;

    const message = { isError: '', errorMessage: '' };

    let passCheck = false;
    const paramValidation01 = CommonHelper.objectCloning(message);
    const paramValidation02 = CommonHelper.objectCloning(message);
    const paramValidation03 = CommonHelper.objectCloning(message);

    paramValidation01.isError = !membershipId ? 'error' : '';
    paramValidation01.errorMessage = !membershipId ? 'Please select Membership' : '';

    const passCheckGroup01 =
      CommonHelper.checkValidDate(startDate) &&
      CommonHelper.compareDateAndTime(startDate, currentDate, 'date');

    if (!passCheckGroup01) {
      paramValidation02.isError = !startDate ? 'error' : '';
      paramValidation02.errorMessage = !startDate
        ? 'Please check your time, time must greater than now'
        : '';
    }

    paramValidation03.isError = !branchId ? 'error' : '';
    paramValidation03.errorMessage = !branchId ? 'Please select Branch' : '';

    if (!paramValidation01.isError && !paramValidation02.isError && !paramValidation03.isError) {
      passCheck = true;
    }

    this.setState({
      validation: {
        ...validation,
        membershipId: paramValidation01,
        startDate: paramValidation02,
        branchId: paramValidation03,
      },
    });

    return passCheck;
  }

  processMessage(messages, type) {
    const convertedMessage = CommonHelper.generateMessage(messages);

    const paramInformation = {
      isOpen: true,
      message: convertedMessage,
      snackbarType: type,
    };

    this.setState({
      toastInformation: paramInformation,
    });
  }

  renderActiveContent() {
    const {
      // filter,
      stepList,
      current,
      // eatOrderTypeOption,
      // isResellerLogin,
      form,
      validation,
      minDate,
      membershipId,
    } = this.state;
    // const { history } = this.props;
    let renderElement = null;

    // const isVisibleTabOption = filter.orderType === productTypeForOrderCode.eat && current === 0;
    // const isVisibleMargin = filter.orderType === productTypeForOrderCode.eat && current > 0;

    // const ifPackages = filter.orderEatType === productTypeOrderEatsOption[1].value;

    switch (stepList[current].key) {
      case stepList[1].key:
        renderElement = (
          <Grid item lg md>
            <StepMembershipPurchaseAddMembershipDetails
              parentForm={form}
              minDate={minDate}
              membershipId={membershipId}
              parentValidation={validation}
              currentValue={current}
              totalStepper={3}
              onParameterUpdate={this.handleParameterUpdateStepMembershipDetails}
              onButtonClickCancel={this.handleButtonClickCancel}
              onButtonClickNext={this.handleButtonClickNext}
              onButtonClickPrev={this.handleButtonClickPrev}
              onPopUpInformation={this.handlePopUpInformation}
            />
          </Grid>
        );
        break;

      case stepList[2].key:
        renderElement = (
          <Grid item lg md>
            <StepMembershipPurchaseSummaryAndPayment
              parentForm={form}
              parentValidation={validation}
              currentValue={current}
              totalStepper={3}
              onParameterUpdate={this.handleParameterUpdateStepMembershipSummary}
              onButtonClickCancel={this.handleButtonClickCancel}
              onButtonClickPrev={this.handleButtonClickPrev}
              onPopUpInformation={this.handlePopUpInformation}
              onButtonClickSubmit={this.handleButtonClickSubmit}
            />
          </Grid>
        );
        break;

      default:
        renderElement = (
          <Grid item lg md>
            <StepMembershipPurchaseAddUserDetails
              parentForm={form}
              parentValidation={validation}
              currentValue={current}
              totalStepper={3}
              onParameterUpdate={this.handleParameterUpdateStepUserDetails}
              onButtonClickCancel={this.handleButtonClickCancel}
              onButtonClickNext={this.handleButtonClickNext}
              onPopUpInformation={this.handlePopUpInformation}
            />
          </Grid>
        );
        break;
    }

    return renderElement;
  }

  render() {
    const { current, stepList, toastInformation } = this.state;
    const prevUrl = '/membership/purchase';

    return (
      <div>
        <Helmet title="FITCO | Membership - Purchase" />
        <div className="container-page-membership-purchase-add scroll-container-invisible">
          <div className="container-page-scrolling-area">
            <Grid container direction="column">
              <Grid item lg md className="section-page-header mv-24">
                <div className="breadcrumbs-section">
                  <Breadcrumbs aria-label="breadcrumb">
                    <Link
                      className="text-12"
                      color="inherit"
                      href={prevUrl}
                      onClick={event => {
                        this.handleClick(event, prevUrl);
                      }}
                    >
                      <i className="ic-ffo-memberships container-icon-prefix size-16" />
                      Membership
                    </Link>
                    <Link
                      className="text-12"
                      color="inherit"
                      href={prevUrl}
                      onClick={event => {
                        this.handleClick(event, prevUrl);
                      }}
                    >
                      Purchase
                    </Link>
                    <label className="text-12" color="inherit">
                      Create Purchase
                    </label>
                  </Breadcrumbs>
                </div>
              </Grid>
              <Grid item lg md className="section-page-body">
                <div className="flex-column container-main-card p-32">
                  <label className="text-16 text-bold text-rolling-stone  mb-32">
                    Membership Purchase
                  </label>
                  <NavigationStep currentValue={current} arrayLabel={stepList} />
                  {this.renderActiveContent()}
                </div>
              </Grid>
              <Grid item lg md className="section-page-footer" />
            </Grid>
          </div>
        </div>
        <SnackBarSimple
          open={toastInformation.isOpen}
          durationHide={2000}
          message={toastInformation.message}
          onClickClose={this.handleCloseToash}
          snackbarType={toastInformation.snackbarType}
          anchor={optionToash}
        />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  processCreateUserMembership: params => setProcessCreateUserMembership(dispatch, params),
});

const mapStateToProps = ({ membership }) => ({ membership });

MembershipPurchaseAdd.propTypes = {
  checkUserAccessPermission: PropTypes.func,
  history: PropTypes.object,
  match: PropTypes.object,
  membership: PropTypes.object,
  processCreateUserMembership: PropTypes.func,
};

const shell = compose(connect(mapStateToProps, mapDispatchToProps));
const core = compose(AuthenticationAccessPages);

export default shell(core(MembershipPurchaseAdd));
