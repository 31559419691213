import React from 'react';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { compose } from 'redux';
// component
import { PrevNextStepperCount } from '../../../../../../components/Index';
import { ContactInformationForm, PersonalInformationForm } from './form/Index';
// action
import { getCountryList } from '../../../../../../services/api/MasterDataApi';
import { validateEmailOrPhone } from '../../../../../../services/api/UserManagement';
// style
import './StepRegisterUserDetailsStyle.scss';

class StepRegisterUserDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    this.getCountry();
  }

  getCountry = () => {
    const { allCountryList } = this.props;
    allCountryList();
  };

  render() {
    const {
      masterData,
      checkEmailOrPhone,
      ocrResult,
      validate,
      onFormUpdate,
      onValidationUpdate,
      identityImage,
      selfieImage,
      faceSimilarity,
    } = this.props;

    return (
      <Grid container className="flex-column">
        <Grid container className="container-step-register-user-details">
          <Grid item lg={12} md={12} className="container-main-card p-32 mb-22">
            <ContactInformationForm
              checkEmailOrPhone={checkEmailOrPhone}
              onParameterUpdate={onFormUpdate}
              onValidationUpdate={onValidationUpdate}
              validate={validate}
            />
          </Grid>
          <Grid item lg={12} md={12} className="container-main-card p-32 mb-22">
            <PersonalInformationForm
              faceSimilarity={faceSimilarity}
              masterData={masterData}
              ocrResult={ocrResult}
              identityImageValue={identityImage}
              selfieImageValue={selfieImage}
              onParameterUpdate={onFormUpdate}
              onValidationUpdate={onValidationUpdate}
              validate={validate}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  allCountryList: () => getCountryList(dispatch),
  checkEmailOrPhone: params => validateEmailOrPhone(dispatch, params),
});

const mapStateToProps = ({ masterData, usersReducer }) => ({
  masterData,
  ocrResult: usersReducer.ocrResult,
  identityImage: usersReducer.setIdentityImage,
  selfieImage: usersReducer.setSelfieImage,
  faceSimilarity: usersReducer.faceSimilarity,
  latestCovidTest: usersReducer.userCovidTestResult,
});

StepRegisterUserDetails.propTypes = {
  allCountryList: PropTypes.func,
  checkEmailOrPhone: PropTypes.func,
  faceSimilarity: PropTypes.number,
  identityImage: PropTypes.bool,
  masterData: PropTypes.object,
  ocrResult: PropTypes.any,
  onFormUpdate: PropTypes.func,
  onValidationUpdate: PropTypes.func,
  selfieImage: PropTypes.bool,
  validate: PropTypes.bool,
};

const shell = compose(connect(mapStateToProps, mapDispatchToProps));

const core = compose(PrevNextStepperCount);

export default shell(core(StepRegisterUserDetails));
