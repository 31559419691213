/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
// Style
import './ContentMobileDeviceNotificationStyle.scss';
import ReactHtmlParser from 'react-html-parser';
// Assets
import { Images } from '../../../assets/Index';
import { Fade } from '@material-ui/core';

const ContentMobileDeviceNotification = props => {
  const { deviceType, message, messageTitle } = props;
  const timeInterval = 300;

  const [tittleState, setTitle] = React.useState(messageTitle || '');
  const [messageState, setMessage] = React.useState(message || '');
  const [deviceState, setDevice] = React.useState(deviceType || '');

  React.useEffect(() => {
    const updateValueTitle = async () => {
      setTitle(messageTitle);
    };
    const updateValueMessage = async () => {
      setMessage(message);
    };
    const updateValueDevice = async () => {
      let currentImage = Images.imgDeviceAndroid;
      switch (deviceType) {
        case 'ios':
          currentImage = Images.imgDeviceIos;
          break;

        default:
          break;
      }

      setDevice(currentImage);
    };
    updateValueTitle();
    updateValueMessage();
    updateValueDevice();
  }, [messageTitle, message, deviceType]);

  const checkValuePresent = !!(tittleState || messageState);

  return (
    <div className={`container-mobile-device`}>
      <img src={deviceState} alt="devices" />
      <Fade in={checkValuePresent} timeout={timeInterval}>
        <div
          className={`container-message-notification ${deviceType} ${
            checkValuePresent ? 'activeContent' : ''
          }`}
        >
          <div className="flex-column">
            <label className="text-10 title mb-8 wrapping-container">{tittleState}</label>
            <label className="text-8 wrapping-container third line wrapping-container-break">
              {ReactHtmlParser(messageState)}
            </label>
          </div>
        </div>
      </Fade>
    </div>
  );
};

ContentMobileDeviceNotification.propTypes = {
  deviceType: PropTypes.string,
  message: PropTypes.string,
  messageTitle: PropTypes.string,
};

export default ContentMobileDeviceNotification;
