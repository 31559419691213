import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Skeleton } from '@material-ui/lab';
import { CircularProgress } from '@material-ui/core';
import _ from 'lodash';
// component
import { SelectInputSearchMain } from '../../Index';
// helper
import { MasterDataHelper } from '../../../helpers/Index';
import SelectSearchMultiple from '../../presentational/select-search-multiple/SelectSearchMultiple';

class SelectInputPaymentPlan extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
        listItem: MasterDataHelper.paymentPlanOption
    };
  }

  handleSelectItem = value => {
    const { onChange } = this.props;

    onChange(value);
  };


  renderSelector() {
    const {
      placeHolder,
      defaultValue,
      currentValue,
      errorMessage,
      validateStatus,
      size,
      isMultipleSelection,
      disabled,
      masterData: { fetching },
    } = this.props;

    const { listItem } = this.state;

    let renderElement = (
      <Skeleton
        variant="rect"
        width="100%"
        height={40}
        animation="wave"
        className="skeletonRounded"
      />
    );

    if (isMultipleSelection) {
      renderElement = (
        <SelectSearchMultiple
          options={listItem}
          onChange={item => {
            this.handleSelectItem(item);
          }}
          notFoundContent={fetching && <CircularProgress size={14} />}
          currentValue={currentValue}
          defaultValue={defaultValue}
          size={size}
          disabled={!!_.isEmpty(listItem) || disabled}
          placeholder={placeHolder}
          errorMessage={errorMessage}
          validateStatus={validateStatus}
        />
      );
    } else {
      renderElement = (
        <SelectInputSearchMain
          options={listItem}
          onChange={item => {
            this.handleSelectItem(item);
          }}
          currentValue={currentValue}
          defaultValue={defaultValue}
          size={size}
          disabled={!!_.isEmpty(listItem) || disabled}
          placeholder={placeHolder}
          errorMessage={errorMessage}
          validateStatus={validateStatus}
        />
      );
    }

    return renderElement;
  }

  render() {
    return this.renderSelector();
  }
}

const mapDispatchToProps = () => ({});

const mapStateToProps = ({ masterData }) => ({ masterData });

SelectInputPaymentPlan.propTypes = {
  currentValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
  disabled: PropTypes.bool,
  errorMessage: PropTypes.string,
  isMultipleSelection: PropTypes.bool,
  masterData: PropTypes.object,
  onChange: PropTypes.func,
  placeHolder: PropTypes.string,
  size: PropTypes.string,
  validateStatus: PropTypes.string,
};

SelectInputPaymentPlan.defaultProps = {
  placeHolder: '',
  isMultipleSelection: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectInputPaymentPlan);
