import React from 'react';
import PropTypes from 'prop-types';
import { Grid, IconButton } from '@material-ui/core';
import { connect } from 'react-redux';
// component
import { ModalInformationPopUp, ScanFace } from '../../../../../components/Index';
import { verifyScanFace } from '../../../../../services/api/MedicApi';

class ModalFaceScanner extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isSubmitting: false,
    };
  }

  componentDidMount() {}

  componentWillUnmount() {}

  // #region Handler Function
  handleSubmitPhoto = data => {
    const { verifyPhoto, verifyHandler } = this.props;

    this.setState({
      isSubmitting: true,
    });

    const body = {
      image: data,
    };

    verifyPhoto(body)
      .then(response => {
        this.setState({
          isSubmitting: false,
        });

        verifyHandler(response.data);
      })
      .catch(() => {
        this.setState({
          isSubmitting: false,
        });

        verifyHandler(null);
      });
  };
  // #endregion

  // #region Render Function
  // #endregion

  render() {
    const { isSubmitting } = this.state;
    const { onClose, isOpen } = this.props;

    const renderElement = (
      <Grid
        container
        direction="column"
        className="flex-wrap-unset container-modal-eat-menu-qr-code"
      >
        <Grid item lg md className="section-header-modal">
          <Grid container justify="space-between">
            <Grid item lg={11} md={11}>
              <h5 className="wrapping-container second line">Face Scanner</h5>
            </Grid>
            <Grid item lg={1} md={1}>
              <div className="container-button-close">
                <IconButton onClick={onClose}>
                  <i className="ic-ffo-close container-icon-prefix size-21" />
                </IconButton>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg md>
          <label className="text-regular ph-34">Please align your face at the centre.</label>
        </Grid>
        <Grid item lg md>
          <ScanFace onSubmitPhoto={this.handleSubmitPhoto} isSubmitting={isSubmitting} />
        </Grid>
      </Grid>
    );

    return (
      <ModalInformationPopUp isOpen={isOpen} onClose={onClose} customElementProps={renderElement} />
    );
  }
}

const mapDispatchToProps = dispatch => ({
  verifyPhoto: params => verifyScanFace(dispatch, params),
});

const mapStateToProps = () => ({});

ModalFaceScanner.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  verifyHandler: PropTypes.func,
  verifyPhoto: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalFaceScanner);
