/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Grid, Breadcrumbs, Link } from '@material-ui/core';
import _ from 'lodash';
import { compose } from 'redux';
// style
import './EatAddStyle.scss';
// component
import {
  SnackBarSimple,
  AuthenticationAccessPages,
  NavigationStep,
  UploadImage,
} from '../../../components/Index';
import { SkeletonDetails } from '../../../components/skeleton/Index';
import { StepAddEatDetails, StepAddEatPrice, StepAddEatNutrition } from './views/Index';
// api
import { setCreateEat, setUpdateEat, getEatDetails } from '../../../services/api/EatApi';
// helper
import {
  CommonHelper,
  MasterDataHelper,
  HttpStatusCode,
  PermissionModule,
  PermissionPage,
  PermissionAccess,
} from '../../../helpers/Index';
// assets
import { Images } from '../../../assets/Index';

const optionToash = {
  vertical: 'top',
  horizontal: 'right',
};

const initialValidation = {
  imageBase64: { isError: '', errorMessage: '' },
};

const initialForm = {
  imageBase64: null,
};

class EatAdd extends React.Component {
  constructor(props) {
    super(props);

    props.checkUserAccessPermission(
      PermissionModule.Eat,
      PermissionPage.Food,
      PermissionAccess.Add,
    );

    this.state = {
      current: 0,
      form: CommonHelper.objectCloning(initialForm),
      isLoading: false,
      validation: CommonHelper.objectCloning(initialValidation),
      stepList: [
        { key: 'eat_detail', name: 'Details' },
        { key: 'eat_price', name: 'Price' },
        { key: 'eat_nutrition', name: 'Nutrition' },
      ],
      toastInformation: {
        isOpen: false,
        message: '',
        snackbarType: 'warning',
      },
      pagesTitle: {
        helmet: 'FITCO | Eats - Create Food',
        breadcrumbs: 'Create Food',
        card: 'Eats - Food',
      },
    };
  }

  componentDidMount() {
    const { form, pagesTitle } = this.state;
    const {
      match: { params },
    } = this.props;

    const ifExistParamObject = params.param_object
      ? CommonHelper.decryptObject(params.param_object)
      : {};

    const updateExitingForm = {
      ...CommonHelper.objectCloning(form),
      ...ifExistParamObject,
    };

    if (updateExitingForm.foodId) {
      this.setState(
        {
          form: updateExitingForm,
          pagesTitle: {
            ...pagesTitle,
            helmet: 'FITCO | Eats - Edit Food Details',
            breadcrumbs: 'Edit Food Details',
            card: 'Eats - Food',
          },
        },
        () => {
          this.getEatDetails();
        },
      );
    }
  }

  componentWillUnmount() {}

  getEatDetails() {
    const { eatDetails } = this.props;
    const { form } = this.state;

    eatDetails(form.foodId)
      .then(async response => {
        const data = response.data;

        const statusOptionValue = MasterDataHelper.statusGlobal.filter(
          item => item.value === data.status,
        );

        const pickUpOptionValue = MasterDataHelper.radioButtonTrueFalse.filter(
          item => item.value === data.is_pickup_at_store,
        );

        const imageExisting = !_.isEmpty(data.product_images)
          ? data.product_images[0].image_url
          : null;

        this.setState({
          form: {
            ...form,
            imageBase64: imageExisting,
            name: data.product_name,
            sku: data.sku,
            merchantId: data.merchant_id,
            pickUp: pickUpOptionValue[0] || MasterDataHelper.radioButtonTrueFalse[0],
            status: statusOptionValue[0] || MasterDataHelper.statusGlobal[0],
            shortDescription: data.short_description || '',
            description: data.description || '',
            descriptionHtml: data.description || '',
            specialPrice: data.special_price,
            price: data.normal_price,
            startDate: data.special_from,
            endDate: data.special_to,
            calories: data.calories || '',
            carbohydrate: data.carbs || '',
            fat: data.fat || '',
            protein: data.protein || '',
            fiber: data.fibre || '',
            grabFoodPrice: data.grabfood_price,
            goFoodPrice: data.gofood_price,
            shopeeFoodPrice: data.shopeefood_price
          },
        });
      })
      .catch(async error => {
        const serverMessage = error.data;
        const validationStatus = error.status === HttpStatusCode.InternalServerError;
        this.processMessage(
          validationStatus ? serverMessage.message : serverMessage.messages,
          'error',
        );
      });
  }

  setCreateEat(params) {
    const { createEat, history } = this.props;

    createEat(params)
      .then(async response => {
        await this.processMessage(response.messages, response.status);
        this.setState(
          {
            isLoading: false,
          },
          () => {
            if (response.code === HttpStatusCode.Success) {
              setTimeout(async () => {
                await history.push('/eat/food');
              }, 2000);
            }
          },
        );
      })
      .catch(async error => {
        const serverMessage = error.data;
        const validationStatus = error.status === HttpStatusCode.InternalServerError;
        this.processMessage(
          validationStatus ? serverMessage.message : serverMessage.messages,
          'error',
        );
        this.setState({
          isLoading: false,
        });
      });
  }

  setUpdateEat(params, id) {
    const { updateEat } = this.props;

    updateEat(params, id)
      .then(response => {
        this.setState(
          {
            isLoading: false,
          },
          async () => {
            await this.processMessage(response.messages, response.status);
          },
        );
      })
      .catch(async error => {
        const serverMessage = error.data;
        const validationStatus = error.status === HttpStatusCode.InternalServerError;
        this.processMessage(
          validationStatus ? serverMessage.message : serverMessage.messages,
          'error',
        );
        this.setState({
          isLoading: false,
        });
      });
  }

  handleClick = (event, value) => {
    const { history } = this.props;
    event.preventDefault();
    history.push(value);
  };

  handleChangeImage = imageBase64 => {
    const { form, validation } = this.state;
    const message = CommonHelper.objectCloning(initialValidation.imageBase64);
    message.isError = !imageBase64 ? 'error' : '';
    message.errorMessage = !imageBase64 ? 'Please choose Image' : '';

    this.setState({
      form: { ...form, imageBase64 },
      validation: { ...validation, imageBase64: message },
    });
  };

  handleButtonClickCancel = () => {
    const { history } = this.props;
    history.push('/eat/food');
  };

  handleParameterUpdateStepEatsDetails = (formValue, ValidationValue) => {
    const { form } = this.state;
    this.setState({
      form: {
        ...form,
        name: formValue.name,
        sku: formValue.sku,
        merchantId: formValue.merchantId,
        pickUp: formValue.pickUp,
        status: formValue.status,
        shortDescription: formValue.shortDescription,
        description: formValue.description,
        descriptionHtml: formValue.descriptionHtml,
      },
    });
  };

  handleParameterUpdateStepEatsPrice = (formValue, ValidationValue) => {
    const { form } = this.state;
    this.setState({
      form: {
        ...form,
        price: formValue.price,
        specialPrice: formValue.specialPrice,
        startDate: formValue.startDate,
        endDate: formValue.endDate,
        grabFoodPrice: formValue.grabFoodPrice,
        goFoodPrice: formValue.goFoodPrice,
        shopeeFoodPrice: formValue.shopeeFoodPrice
      },
    });
  };

  handleParameterUpdateStepEatsNutrition = (formValue, ValidationValue) => {
    const { form } = this.state;
    this.setState({
      form: {
        ...form,
        calories: formValue.calories,
        carbohydrate: formValue.carbohydrate,
        fat: formValue.fat,
        protein: formValue.protein,
        fiber: formValue.fiber,
      },
    });
  };

  handleButtonClickNext = value => {
    const { form } = this.state;
    let checkPass = false;
    let modifyCurrentValue = value;
    const ifPickUp = form.pickUp.value;

    switch (value - 1) {
      case 0:
        checkPass = this.validationFormEatDetails();
        if (!ifPickUp) {
          modifyCurrentValue = 2;
        }
        break;
      case 1:
        checkPass = ifPickUp ? this.validationFormEatPrice() : true;
        break;
      default:
        break;
    }

    if (checkPass) {
      this.setState({ current: modifyCurrentValue });
    }
  };

  handleButtonClickPrev = value => {
    const { form } = this.state;
    let modifyCurrentValue = value;
    const ifPickUp = form.pickUp.value;

    if (!ifPickUp) {
      modifyCurrentValue -= 1;
    }

    this.setState({ current: modifyCurrentValue });
  };

  handleCloseToash = () => {
    const { toastInformation } = this.state;
    this.setState({ toastInformation: { ...toastInformation, isOpen: false } });
  };

  handleButtonClickSubmit = () => {
    const { form } = this.state;
    const check = this.validationFormEatFinal();

    if (check) {
      const params = {
        merchant_id: form.merchantId,
        product_name: form.name,
        product_sku: form.sku,
        short_description: form.shortDescription,
        description: form.description,
        normal_price: parseInt(form.price, 10),
        special_price: parseInt(form.specialPrice, 10),
        special_from: form.specialPrice ? form.startDate : null,
        special_to: form.specialPrice ? form.endDate : null,
        calories: form.calories,
        protein: form.protein,
        carbs: form.carbohydrate,
        fibre: form.fiber,
        fat: form.fat,
        is_pickup_at_store: form.pickUp.value,
        status: form.status.value,
        image: form.imageBase64,
        gofood_price: form.goFoodPrice,
        grabfood_price: form.grabFoodPrice,
        shopeefood_price: form.shopeeFoodPrice,
      };

      this.setState(
        {
          isLoading: true,
        },
        () => {
          if (form.foodId) {
            this.setUpdateEat(params, form.foodId);
          } else {
            this.setCreateEat(params);
          }
        },
      );
    }
  };

  handlePopUpInformation = (message, typeMessage) => {
    this.processMessage(message, typeMessage);
  };

  validationFormEatDetails() {
    const { form, validation } = this.state;
    let passCheck = false;

    const messageV1 = { isError: false, errorMessage: '' };
    const messageV2 = { isError: '', errorMessage: '' };

    const paramValidation01 = CommonHelper.objectCloning(messageV1);
    const paramValidation02 = CommonHelper.objectCloning(messageV1);
    const paramValidation03 = CommonHelper.objectCloning(messageV2);
    const paramValidation04 = CommonHelper.objectCloning(messageV2);

    paramValidation01.isError = !form.name;
    paramValidation01.errorMessage = !form.name ? 'Please enter Eat Name' : '';

    paramValidation02.isError = !form.sku;
    paramValidation02.errorMessage = !form.sku ? 'Please enter Eat SKU' : '';

    const shortDescriptionTrim = form.shortDescription.trim();
    paramValidation03.isError = shortDescriptionTrim ? '' : 'error';
    paramValidation03.errorMessage = shortDescriptionTrim
      ? ''
      : 'Please enter Eat Short Description';

    const DescriptionTrim = form.description.trim();
    paramValidation04.isError = DescriptionTrim ? '' : 'error';
    paramValidation04.errorMessage = DescriptionTrim ? '' : 'Please enter Eat Description';

    if (
      !paramValidation01.isError &&
      !paramValidation02.isError &&
      !paramValidation03.isError &&
      !paramValidation04.isError
    ) {
      passCheck = true;
    }

    this.setState({
      validation: {
        ...validation,
        name: paramValidation01,
        sku: paramValidation02,
        shortDescription: paramValidation03,
        description: paramValidation04,
      },
    });
    return passCheck;
  }

  validationFormEatPrice() {
    const { form, validation } = this.state;
    let passCheck = false;
    let passCheckGroup01 = true;
    let ifValidDate01 = true;
    let ifValidDate02 = true;

    const messageV2 = { isError: '', errorMessage: '' };

    const paramValidation01 = CommonHelper.objectCloning(messageV2);
    const paramValidation02 = CommonHelper.objectCloning(messageV2);

    paramValidation01.isError = !form.price ? 'error' : '';
    paramValidation01.errorMessage = !form.price ? 'Please enter Price' : '';

    if (form.specialPrice) {
      const ifNumber = CommonHelper.checkNumberOnly(form.specialPrice);
      ifValidDate01 = CommonHelper.checkValidDate(form.startDate);
      ifValidDate02 = CommonHelper.checkValidDate(form.endDate);

      paramValidation02.isError = !ifNumber ? 'error' : '';
      paramValidation02.errorMessage = !ifNumber ? 'Special Price must Number' : '';

      if (!ifValidDate01 || !ifValidDate02 || !ifNumber) {
        passCheckGroup01 = false;
      }
    }

    if (!paramValidation01.isError && passCheckGroup01) {
      passCheck = true;
    }

    this.setState({
      validation: {
        ...validation,
        price: paramValidation01,
        specialPrice: paramValidation02,
      },
    });

    return passCheck;
  }

  validationFormEatFinal() {
    const { form, validation } = this.state;
    let passCheck = false;

    const messageV1 = { isError: false, errorMessage: '' };
    const paramValidation01 = CommonHelper.objectCloning(messageV1);
    const paramValidation02 = CommonHelper.objectCloning(messageV1);
    const paramValidation03 = CommonHelper.objectCloning(messageV1);
    const paramValidation04 = CommonHelper.objectCloning(messageV1);
    const paramValidation05 = CommonHelper.objectCloning(messageV1);
    const paramValidation06 = CommonHelper.objectCloning(messageV1);

    const ifNumber01 = CommonHelper.checkNumberOnly(form.calories);

    paramValidation01.isError = !form.calories;
    paramValidation01.errorMessage = !form.calories ? 'Calories must not be empty.' : '';

    paramValidation02.isError = !form.carbohydrate;
    paramValidation02.errorMessage = !form.carbohydrate ? 'Carbohydrate must not be empty.' : '';

    paramValidation03.isError = !form.fat;
    paramValidation03.errorMessage = !form.fat ? 'Fat must not be empty.' : '';

    paramValidation04.isError = !form.protein;
    paramValidation04.errorMessage = !form.protein ? 'Protein must not be empty.' : '';

    paramValidation05.isError = !form.fiber;
    paramValidation05.errorMessage = !form.fiber ? 'Fiber must not be empty.' : '';

    paramValidation06.isError = _.isEmpty(form.imageBase64);
    paramValidation06.errorMessage = _.isEmpty(form.imageBase64) ? 'Photo of food is required.' : '';

    if (
      !paramValidation01.isError &&
      !paramValidation02.isError &&
      !paramValidation03.isError &&
      !paramValidation04.isError &&
      !paramValidation05.isError &&
      !paramValidation06.isError
    ) {
      passCheck = true;
    }

    this.setState({
      validation: {
        ...validation,
        calories: paramValidation01,
        carbohydrate: paramValidation02,
        fat: paramValidation03,
        protein: paramValidation04,
        fiber: paramValidation05,
        imageBase64: paramValidation06,
      },
    });

    return passCheck;
  }

  processMessage(messages, type) {
    const convertedMessage = CommonHelper.generateMessage(messages);

    const paramInformation = {
      isOpen: true,
      message: convertedMessage,
      snackbarType: type,
    };

    this.setState({
      toastInformation: paramInformation,
    });
  }

  renderActiveContent() {
    const { stepList, current, form, isLoading, validation } = this.state;
    let renderElement = null;

    switch (stepList[current].key) {
      case stepList[1].key:
        renderElement = (
          <Grid item lg md>
            <StepAddEatPrice
              parentForm={form}
              parentValidation={validation}
              currentValue={current}
              totalStepper={stepList.length}
              onParameterUpdate={this.handleParameterUpdateStepEatsPrice}
              onButtonClickCancel={this.handleButtonClickCancel}
              onButtonClickNext={this.handleButtonClickNext}
              onButtonClickPrev={this.handleButtonClickPrev}
            />
          </Grid>
        );
        break;

      case stepList[2].key:
        renderElement = (
          <Grid item lg md>
            <StepAddEatNutrition
              parentForm={form}
              parentValidation={validation}
              currentValue={current}
              totalStepper={3}
              onParameterUpdate={this.handleParameterUpdateStepEatsNutrition}
              onButtonClickCancel={this.handleButtonClickCancel}
              onButtonClickPrev={this.handleButtonClickPrev}
              onButtonClickSubmit={this.handleButtonClickSubmit}
              isLoadingSubmit={isLoading}
            />
          </Grid>
        );
        break;

      default:
        renderElement = (
          <Grid item lg md>
            <StepAddEatDetails
              parentForm={form}
              parentValidation={validation}
              currentValue={current}
              totalStepper={stepList.length}
              onParameterUpdate={this.handleParameterUpdateStepEatsDetails}
              onButtonClickCancel={this.handleButtonClickCancel}
              onButtonClickNext={this.handleButtonClickNext}
              onPopUpInformation={this.handlePopUpInformation}
            />
          </Grid>
        );
        break;
    }

    return renderElement;
  }

  render() {
    const {
      toastInformation,
      current,
      stepList,
      pagesTitle,
      form: { imageBase64 },
      validation,
    } = this.state;
    const {
      eatData: { fetching },
    } = this.props;
    const prevUrl = '/eat/food';

    let renderElement = (
      <div className="container-page-scrolling-area">
        <SkeletonDetails />
      </div>
    );

    if (!fetching) {
      renderElement = (
        <div>
          <Helmet title={pagesTitle.helmet} />
          <div className="container-page-eat-add scroll-container">
            <div className="container-page-scrolling-area">
              <Grid container direction="column" className="flex-wrap-unset">
                <Grid item lg md className="section-page-header">
                  <div className="breadcrumbs-section">
                    <Breadcrumbs aria-label="breadcrumb">
                      <Link
                        className="text-12"
                        color="inherit"
                        href={prevUrl}
                        onClick={event => {
                          this.handleClick(event, prevUrl);
                        }}
                      >
                        <i className="ic-ffo-eats container-icon-prefix size-16" />
                        Eat
                      </Link>
                      <label className="text-12" color="inherit">
                        {pagesTitle.breadcrumbs}
                      </label>
                    </Breadcrumbs>
                  </div>
                </Grid>
                <Grid item lg md className="section-page-body">
                  <div className="container-main-card flex-column p-32">
                    <label className="text-16 text-bold text-rolling-stone mb-32">
                      {pagesTitle.card}
                    </label>
                    <Grid container spacing={2} className="pt-16" justify="space-between">
                      <Grid item lg md>
                        <div className="container-image-action">
                          <UploadImage
                            onChange={item => this.handleChangeImage(item)}
                            defaultValue={imageBase64}
                            maxSize={1}
                            validateStatus={validation.imageBase64.isError}
                            errorMessage={validation.imageBase64.errorMessage}
                          />
                        </div>
                      </Grid>
                      <Grid item xl={10} lg={9} md={9}>
                        <div className="flex-column">
                          <NavigationStep currentValue={current} arrayLabel={stepList} />
                          {this.renderActiveContent()}
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
                <Grid item lg md className="section-page-footer" />
              </Grid>
            </div>
          </div>
          <SnackBarSimple
            open={toastInformation.isOpen}
            durationHide={2000}
            message={toastInformation.message}
            onClickClose={this.handleCloseToash}
            snackbarType={toastInformation.snackbarType}
            anchor={optionToash}
          />
        </div>
      );
    }

    return renderElement;
  }
}

const mapDispatchToProps = dispatch => ({
  createEat: params => setCreateEat(dispatch, params),
  updateEat: (params, id) => setUpdateEat(dispatch, params, id),
  eatDetails: params => getEatDetails(dispatch, params),
});

const mapStateToProps = ({ eatData }) => ({
  eatData,
});

EatAdd.propTypes = {
  checkUserAccessPermission: PropTypes.func,
  createEat: PropTypes.func,
  eatData: PropTypes.object,
  eatDetails: PropTypes.func,
  history: PropTypes.object,
  match: PropTypes.object,
  updateEat: PropTypes.func,
};

const shell = compose(connect(mapStateToProps, mapDispatchToProps));
const core = compose(AuthenticationAccessPages);

export default shell(core(EatAdd));
