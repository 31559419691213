/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, FormControl, FormLabel } from '@material-ui/core';
import _ from 'lodash';
// Style
import './CartItemBookingStyle.scss';
// Component
import {
  GridRowTableFiveColumn,
  GridRowTableOneColumn,
  ButtonIconMain,
  TextInput,
} from '../../../../../../../../components/Index';
// helper
import { CommonHelper, promoTypeCode, OrderHelper } from '../../../../../../../../helpers/Index';

const calculateGrandTotal = (items, isValidPromoCode, promoCodeDetails, transportFee) => {
  let grandTotal = OrderHelper.getGrandTotalLab(items);
  let discountAmount = 0;
  if (!_.isEmpty(promoCodeDetails) && isValidPromoCode) {
    grandTotal = promoCodeDetails.totalPriceAfterReward;
    discountAmount = promoCodeDetails.cashback_amount;
  }
  return {
    grandTotal: grandTotal + (+transportFee || 0),
    discountAmount,
  };
};

const CartItemBooking = props => {
  const {
    form: { promoCode },
    parentForm,
    isValidPromoCode,
    orderData: { promoCodeDetails },
    transportFeeProduct,
    onPriceTransportUpdated,
  } = props;

  const [transportFee, setTransportFee] = useState(transportFeeProduct.price || 0);

  useEffect(() => {
    setTransportFee(transportFeeProduct.price);
  }, [transportFeeProduct.price]);

  const onChangeTransportPriceHandler = price => {
    setTransportFee(price);
    onPriceTransportUpdated(price);
  };

  const items = parentForm.step_booking_customer_details;
  let totalItem = 0;

  // Calculate grand total and discount
  const { grandTotal, discountAmount } = calculateGrandTotal(
    items,
    isValidPromoCode,
    promoCodeDetails,
    transportFee,
  );
  const promoCodeInformation = `Promo Code ( ${promoCode} )`;

  const showDiscount =
    isValidPromoCode && promoCodeDetails.promo_code_cashback_type_id === promoTypeCode.Discount;

  return (
    <FormControl
      component="fieldset"
      fullWidth
      margin="normal"
      className="container-booking-lab-cart-item"
    >
      <FormLabel component="label" className="text-16 filed-title">
        Order Summary
      </FormLabel>

      <div className="flex-column">
        <GridRowTableFiveColumn
          customElementColumnFirst={<label className="text-14 header-title">Product Name</label>}
          customElementColumnSecond={<label className="text-14 header-title">Customer Name</label>}
          customElementColumnThird={
            <div className="text-center">
              <label className="text-14 header-title">Qty</label>
            </div>
          }
          customElementColumnFourth={
            <div className="text-right">
              <label className="text-14 header-title">Total Price</label>
            </div>
          }
          columnFirst={3}
          columnSecond={3}
          columnThird={2}
          columnFourth={2}
          columnFifth={2}
        />
        <div className="scroll-container">
          <Grid container direction="column" className="flex-wrap-unset">
            {items.map((item, index) => {
              totalItem += item.productDetails.qty;
              const price = item.productDetails.price;
              const priceTotal = item.productDetails.qty * parseInt(price, 10);

              return (
                <GridRowTableFiveColumn
                  key={index}
                  customElementColumnFirst={
                    <label className="text-14 row-item-title">{item.productDetails.name}</label>
                  }
                  customElementColumnSecond={
                    <label className="text-14">{item.identity_details.name}</label>
                  }
                  customElementColumnThird={
                    <div className="text-center">
                      <label className="text-14">{item.productDetails.qty}</label>
                    </div>
                  }
                  customElementColumnFourth={
                    <div className="text-right">
                      <label className="text-14">
                        IDR {CommonHelper.formatCurrency(priceTotal)}
                      </label>
                    </div>
                  }
                  columnFirst={3}
                  columnSecond={3}
                  columnThird={2}
                  columnFourth={2}
                  columnFifth={2}
                />
              );
            })}
          </Grid>
        </div>
        {!_.isEmpty(transportFeeProduct) && (
          <TransportItem
            transportItem={transportFeeProduct}
            onChangePrice={onChangeTransportPriceHandler}
          />
        )}
        {showDiscount && (
          <GridRowTableOneColumn
            customElementInitial={
              <div className="flex-column">
                <label className="text-14 row-item-title">Discount</label>
                <label className="text-10 ">{promoCodeInformation}</label>
              </div>
            }
            customElementColumn={
              <div className="text-right">
                <label className="text-14 discount">
                  IDR {CommonHelper.formatCurrency(discountAmount)}
                </label>
              </div>
            }
            columnLeft={8}
            columnRight={2}
            paddingRight=""
          />
        )}
        <GridRowTableOneColumn
          customElementInitial={
            <label className="text-16 row-item-title">Total Item(s) : {totalItem}</label>
          }
          customElementColumn={
            <div className="text-right">
              <label className="text-16 row-item-title">
                Grand Total &nbsp; &nbsp; &nbsp;{' '}
                {grandTotal !== 0 ? `IDR ${CommonHelper.formatCurrency(grandTotal)}` : 'Free'}
              </label>
            </div>
          }
          columnLeft={5}
          columnRight={5}
          paddingRight=""
        />
      </div>
    </FormControl>
  );
};

CartItemBooking.propTypes = {
  form: PropTypes.object,
  isValidPromoCode: PropTypes.bool,
  onPriceTransportUpdated: PropTypes.func,
  orderData: PropTypes.object,
  parentForm: PropTypes.object,
  transportFeeProduct: PropTypes.object,
};

export default CartItemBooking;

const TransportItem = ({ transportItem, onChangePrice }) => {
  const [isEdit, setIsEdit] = useState(false);
  const [remove, setRemove] = useState(false);
  const [transportFee, setTransportFee] = useState(transportItem.price);

  useEffect(() => {
    if (remove) {
      onChangePrice(0);
    }
  }, [remove]);

  useEffect(() => {
    setTransportFee(transportItem.price);
  }, [transportItem.price]);

  const setEditAmount = () => {
    setIsEdit(!isEdit);
  };

  const setDeleteAmount = () => {
    setRemove(true);
  };

  const handleChangeAmount = value => {
    setTransportFee(value);
    onChangePrice(value);
  };

  if (remove) {
    return null;
  }

  return (
    <GridRowTableFiveColumn
      customElementColumnFirst={
        <label className="text-14 row-item-title">{transportItem.name}</label>
      }
      customElementColumnSecond={
        <div className="text-center">
          <label className="text-14">{''}</label>
        </div>
      }
      customElementColumnThird={
        <div className="text-center">
          <label className="text-14">{transportItem.qty || 1}</label>
        </div>
      }
      customElementColumnFourth={
        <div className="text-right">
          {isEdit ? (
            <TextInput
              onChange={value => {
                handleChangeAmount(value);
              }}
              currentValue={transportFee}
              size="md"
              numericOnly
            />
          ) : (
            <label className="text-14">IDR {CommonHelper.formatCurrency(transportFee)}</label>
          )}
        </div>
      }
      customElementColumnFifth={
        <>
          <Grid container justify="flex-end">
            {
              <Grid item lg={3} md={3}>
                <ButtonIconMain
                  icon="ic-ffo-edit"
                  type="secondary"
                  size="xs"
                  onClick={() => {
                    setEditAmount();
                  }}
                  tooltip="Edit"
                />
              </Grid>
            }
            <Grid item lg={3} md={3}>
              <ButtonIconMain
                icon="ic-ffo-bin"
                type="negative"
                size="xs"
                onClick={() => {
                  setDeleteAmount();
                }}
                tooltip="Delete"
              />
            </Grid>
          </Grid>
        </>
      }
      columnFirst={3}
      columnSecond={3}
      columnThird={2}
      columnFourth={2}
      columnFifth={2}
    />
  );
};

TransportItem.propTypes = {
  onChangePrice: PropTypes.func,
  transportItem: PropTypes.object,
};
