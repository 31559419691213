// import React, { useState } from 'react';
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../../redux/actions/SettingAction';
import { withStyles } from '@material-ui/styles';
// import { useTheme } from '@material-ui/styles';
// import { useMediaQuery } from '@material-ui/core';
// component
import { Header, SideBar } from './components/Index';
// styles
import StylesJs from './MainStyle';
// helper
import { UserHelper } from '../../helpers/Index';

// const Main = props => {
//   const { children } = props;

//   const classes = StylesJs();
//   // const theme = useTheme();
//   // const isMobile = useMediaQuery(theme.breakpoints.up('xs'), {
//   //   defaultMatches: true,
//   // });
//   const activeRoute = children.props.location.pathname;
//   const currentUsers = UserHelper.getCurrentUserInformation();
//   const pagesData = currentUsers ? currentUsers.pages_access || currentUsers.page_access : [];
//   const user = {
//     imageUrl: currentUsers ? currentUsers.image : null,
//     name: currentUsers ? currentUsers.name : null,
//     job: currentUsers ? currentUsers.role_name : null,
//     email: currentUsers ? currentUsers.email : null,
//     activeMerchant:
//       currentUsers && currentUsers.user_merchants.length > 0
//         ? currentUsers.user_merchants[0].merchant_id
//         : null,
//     userMerchants: currentUsers ? currentUsers.user_merchants : [],
//     user_id: currentUsers ? currentUsers.user_id : null,
//   };

//   const [openSidebar, setOpenSidebar] = useState(true);

//   const handleSidebarOpen = () => {
//     setOpenSidebar(true);
//   };

//   const handleSidebarClose = () => {
//     setOpenSidebar(false);
//   };

//   const handleClickLogout = () => {
//     localStorage.clear();
//     children.props.history.index = 0;
//     children.props.history.push('/sign-in');
//   };

//   return (
//     <div
//       className={`${clsx({
//         [classes.root]: true,
//       })}`}
//     >
//       <Header
//         open={openSidebar}
//         currentUserLogin={user}
//         onClickLogout={handleClickLogout}
//         currentRoute={activeRoute}
//       />
//       <SideBar
//         onClose={handleSidebarClose}
//         onOpen={handleSidebarOpen}
//         open={openSidebar}
//         // variant={isMobile ? 'persistent' : 'permanent'}
//         variant={'permanent'}
//         pages={pagesData}
//         currentRoute={activeRoute}
//       />
//       <main className={classes.content}>
//         <div className={classes.toolbar}>{children}</div>
//       </main>
//     </div>
//   );
// };

// Main.propTypes = {
//   children: PropTypes.node,
// };

// export default Main;

class Main extends React.Component {
  constructor(props) {
    super(props);

    const {
      settingData: { sideBarOpen },
    } = props;

    this.state = {
      openSidebar: sideBarOpen,
      activeRoute: '',
      pagesData: [],
      user: {},
    };
  }

  componentDidMount() {
    this.setUpdateActiveRoute();
    this.setUpdateUserInformation();
  }

  componentDidUpdate() {
    const { activeRoute, user } = this.state;
    const { children } = this.props;

    const currentUsers = UserHelper.getCurrentUserInformation();

    if (children.props.location.pathname !== activeRoute) {
      this.setUpdateActiveRoute();
    }

    if (currentUsers) {
      if (currentUsers.user_id !== user.user_id) {
        this.setUpdateUserInformation();
      }
    }
  }

  setUpdateActiveRoute() {
    const { children } = this.props;

    const activeRoute = children.props.location.pathname;

    this.setState({
      activeRoute,
    });
  }

  setUpdateUserInformation() {
    const currentUsers = UserHelper.getCurrentUserInformation();
    const pagesData = currentUsers ? currentUsers.pages_access || currentUsers.page_access : [];
    const user = {
      imageUrl: currentUsers ? currentUsers.image : null,
      name: currentUsers ? currentUsers.name : null,
      job: currentUsers ? currentUsers.role_name : null,
      email: currentUsers ? currentUsers.email : null,
      activeMerchant:
        currentUsers && currentUsers.user_merchants.length > 0
          ? currentUsers.user_merchants[0].merchant_id
          : null,
      userMerchants: currentUsers ? currentUsers.user_merchants : [],
      user_id: currentUsers ? currentUsers.user_id : null,
    };

    this.setState({
      pagesData,
      user,
    });
  }

  handleSidebarOpen = () => {
    this.props.setSideBarOpen(true);
    this.setState({ openSidebar: true });
  };

  handleSidebarClose = () => {
    this.props.setSideBarOpen(false);
    this.setState({ openSidebar: false });
  };

  handleClickLogout = () => {
    const { children } = this.props;

    localStorage.clear();
    children.props.history.index = 0;
    children.props.history.push('/sign-in');
  };

  render() {
    const { openSidebar, activeRoute, pagesData, user } = this.state;
    const { classes, children } = this.props;

    return (
      <div
        className={`${clsx({
          [classes.root]: true,
        })}`}
      >
        <Header
          open={openSidebar}
          currentUserLogin={user}
          onClickLogout={this.handleClickLogout}
          currentRoute={activeRoute}
        />
        <SideBar
          onClose={this.handleSidebarClose}
          onOpen={this.handleSidebarOpen}
          open={openSidebar}
          // variant={isMobile ? 'persistent' : 'permanent'}
          variant={'permanent'}
          pages={pagesData}
          currentRoute={activeRoute}
        />
        <main className={classes.content}>
          <div className={classes.toolbar}>{children}</div>
        </main>
      </div>
    );
  }
}

Main.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object.isRequired,
  setSideBarOpen: PropTypes.func,
  settingData: PropTypes.object,
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(Actions, dispatch);
};

const mapStateToProps = ({ settingData }) => ({ settingData });

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(StylesJs)(Main));
