import { constants } from '../reducers/InboxReducer';

export function getInboxPaginationRequest() {
  return { type: constants.getInboxPaginationRequest };
}
export function getInboxPaginationSuccess(response) {
  return { type: constants.getInboxPaginationSuccess, payload: response };
}
export function getInboxPaginationFail(response) {
  return { type: constants.getInboxPaginationFail, payload: response.data };
}

export function setDeleteInboxRequest() {
  return { type: constants.setDeleteInboxRequest };
}
export function setDeleteInboxSuccess(response) {
  return { type: constants.setDeleteInboxSuccess, payload: response };
}
export function setDeleteInboxFail(response) {
  return { type: constants.setDeleteInboxFail, payload: response.data };
}

export function getInboxDetailsRequest() {
  return { type: constants.getInboxDetailsRequest };
}
export function getInboxDetailsSuccess(response) {
  return { type: constants.getInboxDetailsSuccess, payload: response };
}
export function getInboxDetailsFail(response) {
  return { type: constants.getInboxDetailsFail, payload: response.data };
}

export function setUpdateInboxRequest() {
  return { type: constants.setUpdateInboxRequest };
}
export function setUpdateInboxSuccess(response) {
  return { type: constants.setUpdateInboxSuccess, payload: response };
}
export function setUpdateInboxFail(response) {
  return { type: constants.setUpdateInboxFail, payload: response.data };
}

export function setCreateInboxRequest() {
  return { type: constants.setCreateInboxRequest };
}
export function setCreateInboxSuccess(response) {
  return { type: constants.setCreateInboxSuccess, payload: response };
}
export function setCreateInboxFail(response) {
  return { type: constants.setCreateInboxFail, payload: response.data };
}

export function getInboxSendTestRequest() {
  return { type: constants.getInboxSendTestRequest };
}
export function getInboxSendTestSuccess(response) {
  return { type: constants.getInboxSendTestSuccess, payload: response };
}
export function getInboxSendTestFail(response) {
  return { type: constants.getInboxSendTestFail, payload: response.data };
}
