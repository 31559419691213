import * as promoCode from '../../redux/actions/PromoCodeAction';
import Api from '../../middleware/FetchMiddleware';
import { baseUrl } from '../../environments/Environment';
import { HttpAction } from '../../helpers/HttpHelper';

export const getPromoCodePagination = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(promoCode.getPromoCodePaginationRequest());
    Api.callApi(`${baseUrl}private/pos/v2/cashback`, HttpAction.Get, params, {})
      .then(response => {
        dispatch(promoCode.getPromoCodePaginationSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(promoCode.getPromoCodePaginationFail(e));
        reject(e);
      });
  });
};

export const getCheckValidPromoCode = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(promoCode.getCheckValidPromoCodeRequest());
    Api.callApi(
      `${baseUrl}private/pos/v2/cashback/promocode/validation`,
      HttpAction.Post,
      {},
      params,
    )
      .then(response => {
        dispatch(promoCode.getCheckValidPromoCodeSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(promoCode.getCheckValidPromoCodeFail(e));
        reject(e);
      });
  });
};

export const getPromoCodeDetails = (dispatch, PromoCodeId) => {
  return new Promise((resolve, reject) => {
    dispatch(promoCode.getPromoCodeDetailsRequest());
    Api.callApi(`${baseUrl}private/pos/v2/cashback/${PromoCodeId}`, HttpAction.Get, {}, {})
      .then(response => {
        dispatch(promoCode.getPromoCodeDetailsSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(promoCode.getPromoCodeDetailsFail(e));
        reject(e);
      });
  });
};

export const getPromoCodeExportReport = (dispatch, id) => {
  return new Promise((resolve, reject) => {
    dispatch(promoCode.getPromoCodeExportReportRequest());
    Api.callApi(`${baseUrl}private/pos/v2/cashback/${id}/download/code`, HttpAction.Get, {}, {})
      .then(response => {
        dispatch(promoCode.getPromoCodeExportReportSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(promoCode.getPromoCodeExportReportFail(e));
        reject(e);
      });
  });
};

export const setCreatePromoCode = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(promoCode.setCreatePromoCodeRequest());
    Api.callApi(`${baseUrl}private/pos/v2/cashback`, HttpAction.Put, {}, params)
      .then(response => {
        dispatch(promoCode.setCreatePromoCodeSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(promoCode.setCreatePromoCodeFail(e));
        reject(e);
      });
  });
};

export const setUpdatePromoCode = (dispatch, id, params) => {
  return new Promise((resolve, reject) => {
    dispatch(promoCode.setUpdatePromoCodeRequest());
    Api.callApi(`${baseUrl}private/pos/v2/cashback/${id}`, HttpAction.Patch, {}, params)
      .then(response => {
        dispatch(promoCode.setUpdatePromoCodeSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(promoCode.setUpdatePromoCodeFail(e));
        reject(e);
      });
  });
};
