import React from 'react';
import PropTypes from 'prop-types';
import { Page, Text, View, Document, Image, StyleSheet } from '@react-pdf/renderer';
import _ from 'lodash';
import {
  Table,
  TableHeader,
  TableCell,
  TableBody,
  DataTableCell,
} from '@david.kucsai/react-pdf-table';
// Style
import StylesJs from './CanvasPOInvoiceStyle';
// helper
import { CommonHelper, currencyCode, GeneratorHelper } from '../../../../../helpers/Index';
// assets
import { Images, StyleJs as StyleJsGlobal } from '../../../../../assets/Index';
import Themes from '../../../../../themes/Index';

const styles = StyleSheet.create({
  tableRowHeader: {
    backgroundColor: '#7c858b',
    color: '#FFF',
    padding: 4,
    paddingVertical: 8,
  },
  tableRowBody: {
    padding: 4,
  },
  footer: {
    position: 'absolute',
    fontSize: 12,
    top: 10,
    left: 0,
    right: 0,
    color: 'grey',
  },
});

const widthNo = 0.06;
const widthCell01 = 0.4;
const widthCell02 = 0.1;
const widthCell03 = 0.2;
const widthCell05 = 0.2;
const widthCell06 = 0.3;

const CanvasProformaInvoice = props => {
  const { data } = props;

  const dataDetails = !_.isEmpty(data.details) ? data.details : {};
  const dataList = !_.isEmpty(data.items) ? GeneratorHelper.generateNumberTable(data.items) : [];
  const dataInfo = !_.isEmpty(data.additional_info) ? data.additional_info : {};
  const dataInfoCompany = !_.isEmpty(dataInfo.company_details) ? dataInfo.company_details : {};
  const dataInfoCompanyBank = !_.isEmpty(dataInfo.payment_details) ? dataInfo.payment_details : {};
  const dataShipping = !_.isEmpty(data.shipping_details) ? data.shipping_details : {};
  const dataTermsOfPayment = !_.isEmpty(data.termOfPayment) ? data.termOfPayment : {};

  const letterhead = dataInfoCompany.name;
  const letterheadLogo = Images.fitmeLogo;
  const defaultCompany = dataInfoCompany.name;
  const defaultCompanyAddress = dataInfoCompany.address;
  const defaultCompanyPhone = dataInfoCompany.phone;
  const defaultCompanyEmail = dataInfoCompany.email;

  const bankName = dataInfoCompanyBank.bank_name;
  const bankBranch = dataInfoCompanyBank.bank_branch;
  const bankAccountNumber = dataInfoCompanyBank.bank_account_number;
  const bankAccountName = dataInfoCompanyBank.bank_account_name;

  const renderDueDate = () => {
    if (!_.isEmpty(dataTermsOfPayment)) {
      if (dataTermsOfPayment.isSingleInvoice) {
        return (
          <View>
            <Text
              style={[StyleJsGlobal.text12]}
            >{`DUE DATE : ${dataTermsOfPayment.due_date}`}</Text>
            <Text style={[StyleJsGlobal.text12]}>Description : {dataTermsOfPayment.label}</Text>
          </View>
        );
      }
      return (
        <View>
          <Text style={[StyleJsGlobal.text12]}>DUE DATE</Text>
          {dataTermsOfPayment.terms.map((term, termIndex) => {
            const dueDate = CommonHelper.dateTimeParseNewFormat(term.due_date, 'DD MMMM YYYY');
            return (
              <Text key={termIndex} style={[StyleJsGlobal.text12]}>
                - {dueDate}
              </Text>
            );
          })}
        </View>
      );
    }

    return (
      <Text style={[StyleJsGlobal.text12]}>{`DUE DATE : ${CommonHelper.dateTimeParseNewFormat(
        dataDetails.due_date,
        'DD/MM/YYYY',
      )}`}</Text>
    );
  };

  const getBalanceDue = () => {
    let balanceDue;
    if (!_.isEmpty(data.balance_due)) {
      balanceDue = data.balance_due || '0';
    } else {
      balanceDue = CommonHelper.formatCurrencyWithDecimals(dataDetails.grand_total) || '0';
    }
    return balanceDue;
  };

  return (
    <Document>
      <Page size="A4">
        <View style={[StyleJsGlobal.gridColumn, StyleJsGlobal.ph24, { width: 596 }]}>
          <View style={[StyleJsGlobal.gridItem12, StyleJsGlobal.mb20, StyleJsGlobal.pv24]} fixed>
            <View style={[StyleJsGlobal.gridRow, StyleJsGlobal.justifyContentSpaceBetween]}>
              <View style={[StyleJsGlobal.gridItem9]}>
                <Text style={[StyleJsGlobal.text24]}>{letterhead.toUpperCase()}</Text>
              </View>
              <View style={[StyleJsGlobal.gridItem3]}>
                <Image src={letterheadLogo} style={[StylesJs.containerLogo]} />
              </View>
            </View>
          </View>
          <View style={[StyleJsGlobal.gridItem12, StyleJsGlobal.mb12]}>
            <View style={[StyleJsGlobal.gridRow, StyleJsGlobal.justifyContentSpaceBetween]}>
              <View style={[StyleJsGlobal.gridItem6]}>
                <Text style={[StyleJsGlobal.text12]}>{defaultCompany.toUpperCase()}</Text>
                <Text style={[StyleJsGlobal.text12]}>{defaultCompanyAddress}</Text>
                <Text style={[StyleJsGlobal.text12]}>{`Phone : ${defaultCompanyPhone}`}</Text>
                <Text style={[StyleJsGlobal.text12]}>{`Email : ${defaultCompanyEmail}`}</Text>
              </View>
              <View style={[StyleJsGlobal.gridItem5, { flex: 1 }]}>
                <View style={[StyleJsGlobal.flexColumn]}>
                  <View style={[StyleJsGlobal.gridRow]}>
                    <View style={[StyleJsGlobal.gridItem3, { paddingRight: 10, flex: 1 }]}>
                      <Text style={[StyleJsGlobal.text10]}>INVOICE#</Text>
                    </View>
                    <View style={[StyleJsGlobal.gridItem9, { flex: 1 }]}>
                      <Text style={[StyleJsGlobal.text10, StyleJsGlobal.textRight]}>
                        {dataDetails.invoice_no}
                      </Text>
                    </View>
                  </View>
                  <View style={[StyleJsGlobal.gridRow]}>
                    <View style={[StyleJsGlobal.gridItem3, { paddingRight: 10, flex: 1 }]}>
                      <Text style={[StyleJsGlobal.text10]}>DATE</Text>
                    </View>
                    <View style={[StyleJsGlobal.gridItem9]}>
                      <Text style={[StyleJsGlobal.text10, StyleJsGlobal.textRight]}>
                        {CommonHelper.dateTimeParseNewFormat(
                          dataShipping.shipping_date,
                          'DD/MM/YYYY',
                        )}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
          <View style={[StyleJsGlobal.gridItem12, StyleJsGlobal.mb12]}>
            <View
              style={[
                StyleJsGlobal.gridRow,
                StyleJsGlobal.justifyContentSpaceBetween,
                { alignItems: 'center' },
              ]}
            >
              <View
                style={[
                  StyleJsGlobal.gridItem4,
                  StyleJsGlobal.dividerHr,
                  { flex: 1, borderColor: Themes.palette.mineShaft },
                ]}
              />
              <View style={[StyleJsGlobal.gridItem6]}>
                <Text style={[StyleJsGlobal.text24]}>INVOICE</Text>
              </View>
              <View
                style={[
                  StyleJsGlobal.gridItem4,
                  StyleJsGlobal.dividerHr,
                  { flex: 1, borderColor: Themes.palette.mineShaft },
                ]}
              />
            </View>
          </View>
          <View style={[StyleJsGlobal.gridItem12, StyleJsGlobal.mb4]}>
            <Text style={[StyleJsGlobal.text12]}>CUSTOMER</Text>
          </View>
          <View style={[StyleJsGlobal.gridItem12, StyleJsGlobal.mb4]}>
            <View style={[StyleJsGlobal.gridRow, StyleJsGlobal.justifyContentSpaceBetween]}>
              <View style={[StyleJsGlobal.gridItem7, { flex: 1 }]}>
                <View style={[StyleJsGlobal.gridColumn, StyleJsGlobal.tableBorder]}>
                  <View style={[StyleJsGlobal.gridItem12]}>
                    <View style={[StyleJsGlobal.gridRow]}>
                      <View style={[StyleJsGlobal.gridItem3, { marginRight: 20, flex: 1 }]}>
                        <Text style={[StyleJsGlobal.text12]}>NAME</Text>
                      </View>
                      <View style={[StyleJsGlobal.gridItem8]}>
                        <Text style={[StyleJsGlobal.text12]}>{dataDetails.company_name}</Text>
                      </View>
                    </View>
                  </View>
                  <View style={[StyleJsGlobal.gridItem12]}>
                    <View style={[StyleJsGlobal.gridRow]}>
                      <View style={[StyleJsGlobal.gridItem3, { marginRight: 20, flex: 1 }]}>
                        <Text style={[StyleJsGlobal.text12]}>ADDRESS</Text>
                      </View>
                      <View style={[StyleJsGlobal.gridItem8]}>
                        <Text style={[StyleJsGlobal.text12]}>{dataShipping.shipping_address}</Text>
                      </View>
                    </View>
                  </View>
                  <View style={[StyleJsGlobal.gridItem12, { flex: 1 }]}>
                    <View style={[StyleJsGlobal.gridRow]}>
                      <View style={[StyleJsGlobal.gridItem3, { marginRight: 20, flex: 1 }]}>
                        <Text style={[StyleJsGlobal.text12]}>NOTE</Text>
                      </View>
                      <View style={[StyleJsGlobal.gridItem8]}>
                        <Text style={[StyleJsGlobal.text12]}>{dataShipping.shipping_notes}</Text>
                      </View>
                    </View>
                  </View>
                  <View style={[StyleJsGlobal.gridItem12]}>
                    <View style={[StyleJsGlobal.gridRow]}>
                      <View style={[StyleJsGlobal.gridItem3, { marginRight: 20, flex: 1 }]}>
                        <Text style={[StyleJsGlobal.text12]}>PIC PHONE</Text>
                      </View>
                      <View style={[StyleJsGlobal.gridItem8]}>
                        <Text style={[StyleJsGlobal.text12]}>{dataDetails.phone}</Text>
                      </View>
                    </View>
                  </View>
                  <View style={[StyleJsGlobal.gridItem12]}>
                    <View style={[StyleJsGlobal.gridRow]}>
                      <View style={[StyleJsGlobal.gridItem3, { marginRight: 20, flex: 1 }]}>
                        <Text style={[StyleJsGlobal.text12]}>PIC EMAIL</Text>
                      </View>
                      <View style={[StyleJsGlobal.gridItem8]}>
                        <Text style={[StyleJsGlobal.text12]}>{dataDetails.email}</Text>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
              <View style={[StyleJsGlobal.gridItem4, StyleJsGlobal.tableBorder]}>
                <View style={[StyleJsGlobal.flexColumn]}>
                  <Text
                    style={[StyleJsGlobal.text12]}
                  >{`ORDER#   : ${dataDetails.purchase_order_no}`}</Text>
                  {renderDueDate()}
                </View>
              </View>
            </View>
          </View>
          <View style={[StyleJsGlobal.gridItem12, StyleJsGlobal.mb4]}>
            <Table data={dataList}>
              <TableHeader>
                <TableCell
                  weighting={widthNo}
                  style={[StyleJsGlobal.text10, StyleJsGlobal.textCenter, styles.tableRowHeader]}
                >
                  No
                </TableCell>
                <TableCell
                  weighting={widthCell01}
                  style={[StyleJsGlobal.text10, StyleJsGlobal.textCenter, styles.tableRowHeader]}
                >
                  Product Name
                </TableCell>
                <TableCell
                  weighting={widthCell02}
                  style={[StyleJsGlobal.text10, StyleJsGlobal.textCenter, styles.tableRowHeader]}
                >
                  QTY
                </TableCell>
                <TableCell
                  weighting={widthCell03}
                  style={[StyleJsGlobal.text10, StyleJsGlobal.textCenter, styles.tableRowHeader]}
                >
                  {`UNIT PRICE (${currencyCode.idr})`}
                </TableCell>
                <TableCell
                  weighting={widthCell05}
                  style={[
                    StyleJsGlobal.text10,
                    styles.textWhite,
                    StyleJsGlobal.textCenter,
                    styles.tableRowHeader,
                  ]}
                >
                  TAX
                </TableCell>
                <TableCell
                  weighting={widthCell06}
                  style={[
                    StyleJsGlobal.text10,
                    styles.textWhite,
                    StyleJsGlobal.textCenter,
                    styles.tableRowHeader,
                  ]}
                >
                  {`AMOUNT (${currencyCode.idr})`}
                </TableCell>
              </TableHeader>
              <TableBody>
                <DataTableCell
                  weighting={widthNo}
                  style={[StyleJsGlobal.text10, StyleJsGlobal.textCenter, styles.tableRowBody]}
                  getContent={r => r.no}
                />
                <DataTableCell
                  weighting={widthCell01}
                  style={[StyleJsGlobal.text10, styles.tableRowBody]}
                  getContent={r => r.product_name}
                />
                <DataTableCell
                  weighting={widthCell02}
                  style={[StyleJsGlobal.text10, StyleJsGlobal.textCenter, styles.tableRowBody]}
                  getContent={r => `${r.quantity || '-'} Pcs`}
                />
                <DataTableCell
                  weighting={widthCell03}
                  style={[StyleJsGlobal.text10, StyleJsGlobal.textCenter, styles.tableRowBody]}
                  getContent={r => `${CommonHelper.formatCurrencyWithDecimals(r.price) || '0'}`}
                />
                <DataTableCell
                  weighting={widthCell05}
                  s
                  style={[StyleJsGlobal.text10, StyleJsGlobal.textCenter, styles.tableRowBody]}
                  getContent={() => `X`}
                />
                <DataTableCell
                  weighting={widthCell06}
                  style={[StyleJsGlobal.text10, StyleJsGlobal.textRight, styles.tableRowBody]}
                  getContent={r => {
                    const wordingTotal = `${CommonHelper.formatCurrencyWithDecimals(
                      r.total_price,
                    ) || '0'}`;
                    return wordingTotal;
                  }}
                />
              </TableBody>
            </Table>
          </View>
          <View style={[StyleJsGlobal.gridItem12, StyleJsGlobal.mb4]} wrap={false}>
            <View style={[StyleJsGlobal.gridRow, StyleJsGlobal.justifyContentFlexEnd]}>
              <View style={[StyleJsGlobal.gridItem5, { flex: 1, marginRight: 5 }]}>
                <View style={[StyleJsGlobal.gridColumn]}>
                  <View style={[StyleJsGlobal.gridItem12, StyleJsGlobal.mb4]}>
                    <View style={[StyleJsGlobal.gridRow]}>
                      <View style={[StyleJsGlobal.gridItem4, { flex: 1 }]}>
                        <Text
                          style={[StyleJsGlobal.text10]}
                        >{`PPN (${dataDetails.ppn_percentage})`}</Text>
                      </View>
                      <View style={[StyleJsGlobal.gridItem8, { flex: 1 }]}>
                        <Text
                          style={[StyleJsGlobal.text10, StyleJsGlobal.textRight]}
                        >{`${CommonHelper.formatCurrencyWithDecimals(dataDetails.total_ppn) ||
                          '0'}`}</Text>
                      </View>
                    </View>
                  </View>
                  <View style={[StyleJsGlobal.gridItem12, StyleJsGlobal.mb4]}>
                    <View style={[StyleJsGlobal.gridRow]}>
                      <View style={[StyleJsGlobal.gridItem4, { flex: 1 }]}>
                        <Text style={[StyleJsGlobal.text10]}>SHIPPING</Text>
                      </View>
                      <View style={[StyleJsGlobal.gridItem8, { flex: 1 }]}>
                        <Text
                          style={[StyleJsGlobal.text10, StyleJsGlobal.textRight]}
                        >{`${CommonHelper.formatCurrencyWithDecimals(dataDetails.shipping_cost) ||
                          '0'}`}</Text>
                      </View>
                    </View>
                  </View>
                  {!_.isEmpty(dataTermsOfPayment) && (
                    <View style={[StyleJsGlobal.gridItem12, StyleJsGlobal.mb4]}>
                      {dataTermsOfPayment.terms.map((term, termIndex) => {
                        const dueDate = CommonHelper.dateTimeParseNewFormat(
                          term.due_date,
                          'DD MMMM YYYY',
                        );
                        return (
                          <View style={[StyleJsGlobal.gridRow]} key={termIndex}>
                            <View style={[StyleJsGlobal.gridItem8, { flex: 1 }]}>
                              <Text style={[StyleJsGlobal.text10]}>
                                PAYMENT {termIndex + 1} : {dueDate}
                              </Text>
                            </View>
                            <View style={[StyleJsGlobal.gridItem4, { flex: 1 }]}>
                              <Text
                                style={[StyleJsGlobal.text10, StyleJsGlobal.textRight]}
                              >{`${CommonHelper.formatCurrencyWithDecimals(term.amount) ||
                                '0'}`}</Text>
                            </View>
                          </View>
                        );
                      })}
                    </View>
                  )}
                  <View style={[StyleJsGlobal.gridItem12, StyleJsGlobal.mb4]}>
                    <View
                      style={[
                        StyleJsGlobal.gridRow,
                        StyleJsGlobal.dividerHr,
                        { borderColor: Themes.palette.mineShaft },
                      ]}
                    >
                      <View style={[StyleJsGlobal.gridItem4, { flex: 1 }]}>
                        <Text style={[StyleJsGlobal.text10]}>TOTAL</Text>
                      </View>
                      <View style={[StyleJsGlobal.gridItem8, { flex: 1 }]}>
                        <Text
                          style={[StyleJsGlobal.text10, StyleJsGlobal.textRight]}
                        >{`${CommonHelper.formatCurrencyWithDecimals(dataDetails.grand_total) ||
                          '0'}`}</Text>
                      </View>
                    </View>
                  </View>
                  <View style={[StyleJsGlobal.gridItem12, StyleJsGlobal.mb4]}>
                    <View
                      style={[
                        StyleJsGlobal.gridRow,
                        StyleJsGlobal.dividerHr,
                        { borderColor: Themes.palette.mineShaft },
                      ]}
                    >
                      <View style={[StyleJsGlobal.gridItem4, { flex: 1 }]}>
                        <Text style={[StyleJsGlobal.text10]}>BALANCE DUE</Text>
                      </View>
                      <View style={[StyleJsGlobal.gridItem8, { flex: 1 }]}>
                        <Text style={[StyleJsGlobal.text10, StyleJsGlobal.textRight]}>
                          {getBalanceDue()}
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
          <View style={[StyleJsGlobal.gridItem12, StyleJsGlobal.mb8]} wrap={false}>
            <View style={[StyleJsGlobal.gridRow, StyleJsGlobal.justifyContentFlexStart]}>
              <View style={[StyleJsGlobal.gridItem7, StyleJsGlobal.tableBorder, { flex: 1 }]}>
                <View style={[StyleJsGlobal.flexColumn]}>
                  <Text style={[StyleJsGlobal.text10, StyleJsGlobal.mb4]}>MESSAGE</Text>
                  <Text style={[StyleJsGlobal.text10]}>{`${dataInfo.message || '-'}`}</Text>
                </View>
              </View>
            </View>
          </View>
          <View style={[StyleJsGlobal.gridItem12, StyleJsGlobal.mb8]}>
            <View style={[StyleJsGlobal.gridRow, { flexWrap: 'nowrap' }]}>
              <View
                style={[StyleJsGlobal.gridItem7, StyleJsGlobal.tableBorder, StyleJsGlobal.mb8]}
                wrap={false}
              >
                <View style={[StyleJsGlobal.flexColumn]}>
                  <Text style={[StyleJsGlobal.text10, StyleJsGlobal.mb4]}>PAYMENT DETAIL</Text>
                  <View style={[StyleJsGlobal.gridRow]}>
                    <View style={[StyleJsGlobal.gridItem5, { paddingRight: 20, flex: 1 }]}>
                      <Text style={[StyleJsGlobal.text10]}>BANK NAME</Text>
                    </View>
                    <View style={[StyleJsGlobal.gridItem6]}>
                      <Text style={[StyleJsGlobal.text10]}>{bankName}</Text>
                    </View>
                  </View>
                  <View style={[StyleJsGlobal.gridRow]}>
                    <View style={[StyleJsGlobal.gridItem5, { paddingRight: 20, flex: 1 }]}>
                      <Text style={[StyleJsGlobal.text10]}>BANK BRANCH</Text>
                    </View>
                    <View style={[StyleJsGlobal.gridItem6]}>
                      <Text style={[StyleJsGlobal.text10]}>{bankBranch}</Text>
                    </View>
                  </View>
                  <View style={[StyleJsGlobal.gridRow]}>
                    <View style={[StyleJsGlobal.gridItem5, { paddingRight: 20, flex: 1 }]}>
                      <Text style={[StyleJsGlobal.text10]}>BANK ACCOUNT NUMBER</Text>
                    </View>
                    <View style={[StyleJsGlobal.gridItem6]}>
                      <Text style={[StyleJsGlobal.text10]}>{bankAccountNumber}</Text>
                    </View>
                  </View>
                  <View style={[StyleJsGlobal.gridRow]}>
                    <View style={[StyleJsGlobal.gridItem5, { paddingRight: 20, flex: 1 }]}>
                      <Text style={[StyleJsGlobal.text10]}>BANK ACCOUNT NAME</Text>
                    </View>
                    <View style={[StyleJsGlobal.gridItem6]}>
                      <Text style={[StyleJsGlobal.text10]}>{bankAccountName}</Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>
            <View style={[StyleJsGlobal.gridItem12, StyleJsGlobal.mb8]}>
              <View style={[StyleJsGlobal.gridRow, StyleJsGlobal.justifyContentFlexStart]}>
                <View
                  style={[StyleJsGlobal.gridItem7, StyleJsGlobal.tableBorder, { flex: 1 }]}
                  wrap={false}
                >
                  <View style={[StyleJsGlobal.flexColumn]}>
                    <Text style={[StyleJsGlobal.text10, StyleJsGlobal.mb4]}>AMOUNT IN WORD</Text>
                    <Text style={[StyleJsGlobal.text10]}>{`${dataInfo.amount_in_word ||
                      '-'}`}</Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>
        <View style={[styles.footer]}>
          <View
            style={[
              StyleJsGlobal.gridRow,
              StyleJsGlobal.justifyContentSpaceBetween,
              { width: 600 },
            ]}
          >
            <View style={[StyleJsGlobal.gridItem4, { flex: 1, paddingLeft: 24 }]}>
              <Text style={[StyleJsGlobal.text10]} fixed>
                {`Invoice ${dataDetails.invoice_no}`}
              </Text>
            </View>
            <View style={[StyleJsGlobal.gridItem4, { flex: 1, paddingRight: 24 }]}>
              <Text
                style={[StyleJsGlobal.text10, StyleJsGlobal.textRight]}
                render={({ pageNumber, totalPages }) =>
                  `Page ${pageNumber} of ${totalPages || '1'}`
                }
                fixed
              />
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

CanvasProformaInvoice.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default CanvasProformaInvoice;
