import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Grid, Breadcrumbs, Link, FormControl } from '@material-ui/core';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { compose } from 'redux';
import { Table } from 'antd';
// Components
import {
  TextAreaMain,
  ButtonMain,
  SnackBarSimple,
  TextInput,
  AuthenticationAccessPages,
  PrevStateValue,
  LabelInput,
} from '../../../components/Index';
// Action
import {
  createSegment,
  getSegmentPreviewQuery,
  getSegmentDetails,
  editSegment,
} from '../../../services/api/SegmentApi';
import { resetSegmentPreview } from '../../../redux/actions/SegmentAction';
// Style
import './SegmentViewStyle.scss';
// Helper
import {
  HttpStatusCode,
  PermissionModule,
  PermissionPage,
  PermissionAccess,
} from '../../../helpers/Index';

const optionAlert = {
  vertical: 'top',
  horizontal: 'right',
};
const resetValidation = { isError: '', errorMessage: '' };
const errorValidationSegmentTitle = {
  isError: 'error',
  errorMessage: 'Please enter Segment Title',
};
const errorValidationQuery = { isError: 'error', errorMessage: 'Please enter Query' };

class SegmentView extends React.Component {
  constructor(props) {
    super(props);

    props.checkUserAccessPermission(
      PermissionModule.Marketing,
      PermissionPage.Segment,
      props.match.params.segmentId ? PermissionAccess.Add : PermissionAccess.Update,
    );

    this.state = {
      form: {
        segmentTitle: null,
        query: null,
      },
      validation: {
        segmentTitle: { isError: '', errorMessage: '' },
        query: { isError: '', errorMessage: '' },
      },
      isLoading: false,
      isLoadingCreate: false,
      alertInformation: {
        isOpen: false,
        message: '',
        isLoading: true,
        snackbarType: 'warning',
      },
      validSegment: false,
    };
  }

  componentWillMount() {
    const { resetQueryResult } = this.props;
    resetQueryResult();
  }

  componentDidMount() {
    const { getDetailData } = this;
    getDetailData();
  }

  getDetailData = () => {
    const {
      getSegmentDetailById,
      match: { params },
    } = this.props;
    const { form } = this.state;
    const { handleProcessMessage, handleClickGenerate } = this;

    if (!_.isEmpty(params)) {
      const segmentId = params.segment_id;
      this.setState(
        {
          isLoading: true,
        },
        () => {
          getSegmentDetailById(segmentId)
            .then(() => {
              const {
                segmentData: { segmentDetails },
              } = this.props;
              this.setState(
                {
                  form: {
                    ...form,
                    segmentTitle: segmentDetails.title,
                    query: segmentDetails.user_segment_query,
                  },
                },
                () => {
                  handleClickGenerate();
                },
              );
            })
            .catch(() => {
              handleProcessMessage('Something Wrong', 'error');
              this.setState({ isLoading: false });
            });
        },
      );
    }
  };

  handleClickBreadcrumbs = (event, value) => {
    const { history } = this.props;
    event.preventDefault();
    history.push(value);
  };

  handleBack = () => {
    const { history } = this.props;
    history.goBack();
  };

  handleCloseAlert = () => {
    const { alertInformation } = this.state;
    this.setState({ alertInformation: { ...alertInformation, isOpen: false } });
  };

  handleChangeSegmentTitle = value => {
    const { form, validation } = this.state;
    const isError = errorValidationSegmentTitle;
    const checkValidation = _.isEmpty(value) ? isError : resetValidation;
    this.setState({
      form: { ...form, segmentTitle: value },
      validation: { ...validation, segmentTitle: checkValidation },
      // validSegment: !_.isEmpty(value),
    });
  };

  handleChangeQuery = value => {
    const { form, validation } = this.state;
    const isError = errorValidationQuery;
    const checkValidation = _.isEmpty(value) ? isError : resetValidation;
    this.setState({
      form: { ...form, query: value },
      validation: { ...validation, query: checkValidation },
    });
  };

  validationForm = () => {
    const { form, validation } = this.state;
    let passCheck = true;
    let paramValidation01 = resetValidation;
    let paramValidation02 = resetValidation;

    if (_.isEmpty(form.segmentTitle)) {
      paramValidation01 = errorValidationSegmentTitle;
      passCheck = false;
    }

    if (_.isEmpty(form.query)) {
      paramValidation02 = errorValidationQuery;
      passCheck = false;
    }

    if (!passCheck) {
      this.setState({
        validation: {
          ...validation,
          segmentTitle: paramValidation01,
          query: paramValidation02,
        },
      });
    }

    return passCheck;
  };

  handleClickGenerate = () => {
    const { getSegmentPreview } = this.props;
    const { form } = this.state;
    const { validationForm, handleProcessMessage } = this;

    const params = {
      query: form.query,
    };

    let validSegment = true;

    if (validationForm()) {
      this.setState(
        {
          isLoading: true,
        },
        () => {
          getSegmentPreview(params)
            .then(response => {
              if (response.code === HttpStatusCode.BadRequest) {
                handleProcessMessage(response.messages, 'error');
                validSegment = false;
              }
              this.setState({ isLoading: false, validSegment });
            })
            .catch(() => {
              handleProcessMessage('Something Wrong', 'error');
            });
        },
      );
    }
  };

  handleClickCreate = () => {
    const {
      createUserSegment,
      editUserSegment,
      match: { params },
    } = this.props;
    const { validSegment, form } = this.state;
    const { handleProcessMessage, validationForm } = this;

    const payload = {
      title: form.segmentTitle,
      description: form.segmentTitle,
      user_segment_query: form.query,
    };

    if (validSegment && validationForm()) {
      this.setState(
        {
          isLoadingCreate: true,
        },
        () => {
          if (!_.isEmpty(params)) {
            const segmentId = params.segment_id;
            editUserSegment(payload, segmentId)
              .then(response => {
                const {
                  segmentData: { responseMessage },
                  history,
                } = this.props;
                const isErrorQuery =
                  response.code === HttpStatusCode.BadRequest ? 'error' : 'success';
                handleProcessMessage(responseMessage, isErrorQuery);
                this.setState(
                  {
                    isLoadingCreate: false,
                  },
                  () => {
                    if (isErrorQuery === 'success') {
                      setTimeout(() => {
                        history.push('/marketing/segment');
                      }, 2000);
                    }
                  },
                );
              })
              .catch(() => {
                handleProcessMessage('Something Wrong', 'error');
                this.setState({ isLoadingCreate: false });
              });
          } else {
            createUserSegment(payload)
              .then(response => {
                const {
                  segmentData: { responseMessage },
                  history,
                } = this.props;
                const isErrorQuery =
                  response.code === HttpStatusCode.BadRequest ? 'error' : 'success';
                handleProcessMessage(responseMessage, isErrorQuery);
                this.setState(
                  {
                    isLoadingCreate: false,
                  },
                  () => {
                    if (isErrorQuery === 'success') {
                      setTimeout(() => {
                        history.push('/marketing/segment');
                      }, 2000);
                    }
                  },
                );
              })
              .catch(() => {
                handleProcessMessage('Something Wrong', 'error');
                this.setState({ isLoadingCreate: false });
              });
          }
        },
      );
    }
  };

  handleProcessMessage = (messages, type) => {
    const paramInformation = {
      isOpen: true,
      message: messages,
      snackbarType: type,
    };

    this.setState({
      alertInformation: paramInformation,
    });
  };

  renderColumn = () => {
    const {
      segmentData: { previewQueryResult },
    } = this.props;
    let column = {};

    if (!_.isEmpty(previewQueryResult)) {
      const columnKey = _.keys(previewQueryResult[0]);
      column = columnKey.map(key => {
        const objectColumn = {
          title: _.capitalize(key),
          dataIndex: key,
          key,
          responsive: ['md'],
          width: 100,
          sorter: (a, b) => a[key].length - b[key].length,
        };
        return objectColumn;
      });
    }
    return column;
  };

  renderTable = () => {
    const {
      segmentData: { previewQueryResult },
    } = this.props;
    const column = this.renderColumn();
    if (!_.isEmpty(previewQueryResult)) {
      return (
        <Grid container direction={'row'} className="pt-32">
          <Grid item lg={12} md={12}>
            <Table
              style={{ maxWidth: 980 }}
              columns={column}
              dataSource={previewQueryResult}
              scroll={{ x: true, y: 300 }}
            />
          </Grid>
        </Grid>
      );
    }
    return null;
  };

  renderContent = () => {
    const {
      match: { params },
    } = this.props;
    const segmentId = params.segment_id;

    return (
      <div className="segment-view-card">
        <Grid container direction={'column'}>
          <Grid item>
            <label className="text-16 title text-bold text-rolling-stone">
              {segmentId ? 'Edit' : 'Create'} Segment
            </label>
          </Grid>
          <Grid item>
            <Grid container direction={'row'}>
              <Grid item lg={6} md={6}>
                <FormControl component="fieldset" fullWidth margin={'normal'}>
                  <LabelInput labelText={'Segment Title'} isRequired />
                  <TextInput
                    placeHolderText="Message Title"
                    onChange={this.handleChangeSegmentTitle}
                    currentValue={this.state.form.segmentTitle}
                    errorMessage={this.state.validation.segmentTitle.errorMessage}
                    isError={this.state.validation.segmentTitle.isError}
                    size="lg"
                    maxLength={150}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <FormControl component="fieldset" fullWidth margin={'normal'}>
              <LabelInput labelText={'Query'} isRequired />
              <TextAreaMain
                currentValue={this.state.form.query}
                errorMessage={this.state.validation.query.errorMessage}
                validateStatus={this.state.validation.query.isError}
                onChange={this.handleChangeQuery}
                rows={5}
              />
            </FormControl>
          </Grid>
          <Grid item className="pt-32">
            <Grid container direction={'row'}>
              <Grid item lg={2} md={2}>
                <ButtonMain
                  labelText="Generate"
                  onClick={this.handleClickGenerate}
                  type="primary"
                  size="md"
                  isLoading={this.state.isLoading}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {this.renderTable()}
      </div>
    );
  };

  render() {
    const {
      match: { params },
    } = this.props;
    const {
      form: { segmentTitle, query },
      validSegment,
    } = this.state;
    const checkFormValid = !_.isEmpty(segmentTitle) && !_.isEmpty(query);
    const segmentId = params.segment_id;
    const title = segmentId ? 'Edit Segment' : 'Create Segment';

    return (
      <div>
        <Helmet title={`FITCO | Marketing - Segment - ${title}`} />
        <div className="container-page-segment-view scroll-container">
          <div className="container-page-scrolling-area">
            <Grid container direction="column">
              <Grid item lg md className="section-page-header">
                <Grid container>
                  <Grid item>
                    <div className="breadcrumbs-section">
                      <Breadcrumbs aria-label="breadcrumb">
                        <Link
                          className="text-12"
                          color="inherit"
                          href="/marketing/segment"
                          onClick={event => {
                            this.handleClickBreadcrumbs(event, '/marketing/segment');
                          }}
                        >
                          <i className="icon-slot ic-ffo-reports" /> Segment
                        </Link>
                        <label className="text-12" color="inherit">
                          {title}
                        </label>
                      </Breadcrumbs>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item lg md className="section-page-body">
                {this.renderContent()}
              </Grid>
              <Grid item lg md className="section-page-footer">
                <Grid container justify="flex-end" spacing={3}>
                  <Grid item lg={2} md={2}>
                    <ButtonMain
                      type="ghost"
                      size="xl"
                      labelText="Cancel"
                      onClick={this.handleBack}
                    />
                  </Grid>
                  {validSegment && checkFormValid && (
                    <Grid item lg={2} md={2}>
                      <ButtonMain
                        type="primary"
                        size="xl"
                        labelText={segmentId ? 'Update' : 'Create'}
                        onClick={this.handleClickCreate}
                        isLoading={this.state.isLoadingCreate}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <SnackBarSimple
              open={this.state.alertInformation.isOpen}
              durationHide={2000}
              message={this.state.alertInformation.message}
              onClickClose={this.handleCloseAlert}
              snackbarType={this.state.alertInformation.snackbarType}
              anchor={optionAlert}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getSegmentPreview: params => getSegmentPreviewQuery(dispatch, params),
  getSegmentDetailById: params => getSegmentDetails(dispatch, params),
  createUserSegment: params => createSegment(dispatch, params),
  editUserSegment: (params, segmentId) => editSegment(dispatch, params, segmentId),
  resetQueryResult: () => dispatch(resetSegmentPreview()),
});

const mapStateToProps = ({ segmentData }) => ({ segmentData });

SegmentView.propTypes = {
  checkUserAccessPermission: PropTypes.func,
  createUserSegment: PropTypes.func,
  editUserSegment: PropTypes.func,
  getSegmentDetailById: PropTypes.func,
  getSegmentPreview: PropTypes.func,
  history: PropTypes.object,
  match: PropTypes.object,
  resetQueryResult: PropTypes.func,
  segmentData: PropTypes.object,
};

const shell = compose(connect(mapStateToProps, mapDispatchToProps));
const core = compose(AuthenticationAccessPages, PrevStateValue);

export default shell(core(SegmentView));
