import React from 'react';
import { Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

const SkeletonGrid = () => {
  return (
    <Grid container spacing={5} justify="space-between">
      <Grid item lg={4} md={4}>
        <Skeleton
          variant="rect"
          width="100%"
          height={250}
          animation="wave"
          className="skeletonRounded"
        />
      </Grid>
      <Grid item lg={4} md={4}>
        <Skeleton
          variant="rect"
          width="100%"
          height={250}
          animation="wave"
          className="skeletonRounded"
        />
      </Grid>
      <Grid item lg={4} md={4}>
        <Skeleton
          variant="rect"
          width="100%"
          height={250}
          animation="wave"
          className="skeletonRounded"
        />
      </Grid>
      <Grid item lg={4} md={4}>
        <Skeleton
          variant="rect"
          width="100%"
          height={250}
          animation="wave"
          className="skeletonRounded"
        />
      </Grid>
      <Grid item lg={4} md={4}>
        <Skeleton
          variant="rect"
          width="100%"
          height={250}
          animation="wave"
          className="skeletonRounded"
        />
      </Grid>
      <Grid item lg={4} md={4}>
        <Skeleton
          variant="rect"
          width="100%"
          height={250}
          animation="wave"
          className="skeletonRounded"
        />
      </Grid>
    </Grid>
  );
};

export default SkeletonGrid;
