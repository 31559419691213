import React from 'react';
import PropTypes from 'prop-types';
import Barcode from 'react-barcode';
// Style
import './LabelBarcodeStyle.scss';

const LabelBarcode = props => {
  const { value, size, heightBarcode } = props;

  let currentText = 14;

  switch (size) {
    case 'large':
      currentText = 16;
      break;
    case 'middle':
      currentText = 14;
      break;
    default:
      currentText = 12;
      break;
  }

  return (
    <span className="container-label-barcode">
      <Barcode
        value={value}
        font="Montserrat"
        fontSize={currentText}
        fontOptions="bold"
        height={heightBarcode}
        textMargin={10}
      />
    </span>
  );
};

LabelBarcode.propTypes = {
  heightBarcode: PropTypes.number,
  size: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

LabelBarcode.defaultProps = {
  size: 'middle',
};

export default LabelBarcode;
