import React from 'react';
import PropTypes from 'prop-types';
import { Input, Form } from 'antd';
// style
import './TextInputPrePostStyle.scss';
import stylesJs from './TextInputPrePostStyle';

const TextInputPrePost = props => {
  const {
    customElementsAfter,
    customElementsBefore,
    defaultValue,
    currentValue,
    size,
    validateStatus,
    errorMessage,
    onChange,
    onEnter,
    isLoading,
    placeholder,
    prefix,
    disabled,
  } = props;
  const classes = stylesJs();

  const onChangeTextInput = event => {
    const result = event.target.value;
    onChange(result);
  };

  const handleEnterTextInput = event => {
    if (event.key === 'Enter') onEnter();
  };

  const renderPrefix = () => {
    const sizeIcon = size || 'large';
    return (
      <div className="pt-4">
        <i className={`${prefix} container-icon-prefix ${sizeIcon}`} />
      </div>
    );
  };

  return (
    <Form.Item validateStatus={validateStatus} help={errorMessage}>
      <Input
        className={`${classes.root} container-text-custom-input-pre-post `}
        defaultValue={defaultValue}
        value={currentValue}
        placeholder={placeholder}
        addonAfter={customElementsAfter}
        addonBefore={customElementsBefore}
        disabled={disabled}
        loading={isLoading}
        size={size}
        onChange={onChange ? onChangeTextInput : null}
        onPressEnter={onEnter ? handleEnterTextInput : null}
        prefix={prefix ? renderPrefix() : null}
      />
    </Form.Item>
  );
};

TextInputPrePost.propTypes = {
  currentValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  customElementsAfter: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  customElementsBefore: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  disabled: PropTypes.bool,
  errorMessage: PropTypes.string,
  isLoading: PropTypes.bool,
  onChange: PropTypes.func,
  onEnter: PropTypes.func,
  placeholder: PropTypes.string,
  prefix: PropTypes.string,
  size: PropTypes.string,
  validateStatus: PropTypes.string,
};

TextInputPrePost.defaultProps = {
  size: 'middle',
};

export default TextInputPrePost;
