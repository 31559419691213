import React from 'react';
import PropTypes from 'prop-types';
import { Grid, IconButton } from '@material-ui/core';
import { connect } from 'react-redux';
// api
import { getUserActivityDetail } from '../../../../../services/api/UserManagement';
// style
import './ModalUserDetailActivityTopUpStyle.scss';
// component
import {
  ModalInformationPopUp,
  SnackBarSimple,
  LabelStatusMain,
} from '../../../../../components/Index';
import { SkeletonModalUserDetailActivityTopUp } from '../Index';
// helper
import {
  CommonHelper,
  MasterDataHelper,
  UserHelper,
  paymentStatus,
} from '../../../../../helpers/Index';

const optionToash = {
  vertical: 'top',
  horizontal: 'right',
};

class ModalUserDetailActivityTopUp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      toastInformation: {
        isOpen: false,
        message: '',
        snackbarType: 'warning',
      },
    };
  }

  componentDidMount() {
    const { salesOrderId } = this.props;

    if (salesOrderId) {
      this.getActivityDetailsById(salesOrderId);
    }
  }

  componentWillUnmount() {}

  getActivityDetailsById(selectedId) {
    const { userActivityDetail } = this.props;
    const param = {
      sales_order_id: selectedId,
    };

    this.setState({ isLoading: true }, () => {
      userActivityDetail(param)
        .then(() => {
          this.setState({
            isLoading: false,
          });
        })
        .catch(async error => {
          const message = error.data;
          this.processMessage(message.messages, 'error');
        });
    });
  }

  handleClose = () => {
    const { onClose } = this.props;
    onClose(false);
  };

  handleCloseToash = () => {
    const { toastInformation } = this.state;
    this.setState({ toastInformation: { ...toastInformation, isOpen: false } });
  };

  processMessage(messages, type) {
    const convertedMessage = CommonHelper.generateMessage(messages);

    const paramInformation = {
      isOpen: true,
      message: convertedMessage,
      snackbarType: type,
    };

    this.setState({
      toastInformation: paramInformation,
    });
  }

  renderAccount = dataArray => {
    return (
      <Grid
        container
        direction="column"
        className="scroll-container container-account-detail-activity flex-wrap-unset"
      >
        {dataArray.map((item, index) => (
          <Grid key={index} item lg md className="row-account">
            <Grid container>
              <Grid item lg={8} md={8}>
                <label className="text-14">{item.name}</label>
              </Grid>
              <Grid item lg={4} md={4}>
                <Grid container justify="flex-end">
                  <Grid item>
                    {index === 2 ? (
                      <label className="text-14">{`IDR ${CommonHelper.formatCurrency(
                        item.value,
                      )}`}</label>
                    ) : (
                      <label className="text-14">{item.value}</label>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    );
  };

  renderStatus = value => {
    let renderElement = (
      <LabelStatusMain value={MasterDataHelper.statusPayment[11].name} type="complete" />
    );
    if (value === paymentStatus.Pending || value === paymentStatus.Refund) {
      let wording = MasterDataHelper.statusPayment[6].name;
      if (value === paymentStatus.Refund) wording = MasterDataHelper.statusPayment[10].name;

      renderElement = <LabelStatusMain value={wording} type="warning" />;
    } else if (value === paymentStatus.Expired || value === paymentStatus.Declined) {
      let wording = MasterDataHelper.statusPayment[5].name;
      if (value === paymentStatus.Declined) wording = MasterDataHelper.statusPayment[7].name;

      renderElement = <LabelStatusMain value={wording} type="danger" />;
    }
    return <div className="container-status">{renderElement}</div>;
  };

  render() {
    const { isLoading, toastInformation } = this.state;
    const { isOpen, userManagement } = this.props;
    const transactionDetail = UserHelper.generateUserActivityDetails(userManagement.activityDetail);

    let renderElement = <SkeletonModalUserDetailActivityTopUp />;

    if (!isLoading && transactionDetail) {
      renderElement = (
        <Grid container direction={'column'} className="flex-wrap-unset">
          <Grid item lg md className="section-header-modal-activity">
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="center"
              className="flex-wrap-unset"
            >
              <Grid item>
                <div className="container-icon-transaction">
                  <i
                    className={`${transactionDetail.transactionIcon} container-icon-prefix size-40 transaction-icon-${transactionDetail.transactionIconColor}`}
                  />
                </div>
              </Grid>
              <Grid item lg={10} md={10}>
                <label className="text-18 wrapping-container second line">
                  {transactionDetail.activityTypeName}
                </label>
              </Grid>
              <Grid item>
                <Grid container justify="flex-end">
                  <Grid item>
                    <div className="container-button-close">
                      <IconButton onClick={this.handleClose}>
                        <i className="ic-ffo-close custom-icon-close" />
                      </IconButton>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item className="section-body-modal-activity">
            <Grid item lg md>
              <Grid
                container
                justify="flex-start"
                className="flex-wrap-unset container-details container-payment"
              >
                <Grid item lg={9} md={9}>
                  <Grid container>
                    <Grid item lg={4} md={4}>
                      <div className="flex-column">
                        <label className="text-12">Order Number</label>
                        <label className="text-12">{transactionDetail.orderNo}</label>
                      </div>
                    </Grid>
                    <Grid item lg={4} md={4}>
                      <div className="flex-column">
                        <label className="text-12">Transaction Date</label>
                        <label className="text-12">{transactionDetail.transactionDate}</label>
                      </div>
                    </Grid>
                    <Grid item lg={4} md={4}>
                      <div className="flex-column">
                        <label className="text-12">Payment Method</label>
                        <label className="text-12">{transactionDetail.paymentMethod}</label>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item lg={3} md={3}>
                  <Grid container justify="flex-end">
                    <Grid item lg={6} md={6}>
                      <Grid container direction="column" alignItems="center">
                        <Grid item lg md>
                          <div className="flex-column">
                            <label className="text-12">Status</label>
                          </div>
                        </Grid>
                        <Grid item lg md>
                          {this.renderStatus(transactionDetail.paymentStatus)}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg md>
              {this.renderAccount(transactionDetail.details)}
            </Grid>
          </Grid>
          <Grid item className="section-footer-modal-activity" />
          <SnackBarSimple
            open={toastInformation.isOpen}
            durationHide={2000}
            message={toastInformation.message}
            onClickClose={this.handleCloseToash}
            snackbarType={toastInformation.snackbarType}
            anchor={optionToash}
          />
        </Grid>
      );
    }

    return (
      <ModalInformationPopUp
        isOpen={isOpen}
        onClose={this.handleClose}
        size="medium"
        customElementProps={renderElement}
      />
    );
  }
}

const mapDispatchToProps = dispatch => ({
  userActivityDetail: params => getUserActivityDetail(dispatch, params),
});

const mapStateToProps = ({ userManagement }) => ({ userManagement });

ModalUserDetailActivityTopUp.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  salesOrderId: PropTypes.number,
  userActivityDetail: PropTypes.func,
  userManagement: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalUserDetailActivityTopUp);
