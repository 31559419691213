import * as userManagementAction from '../../redux/actions/UserManagement';
import Api from '../../middleware/FetchMiddleware';
import { baseUrl } from '../../environments/Environment';
import { HttpAction } from '../../helpers/Index';

export const getListUser = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    Api.callApi(`${baseUrl}private/pos/v2/user/list`, HttpAction.Get, params, {})
      .then(response => {
        dispatch(userManagementAction.getListSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(userManagementAction.getListFail(e));
        reject(e);
      });
  });
};

export const validateEmailOrPhone = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(userManagementAction.validateEmailOrPhoneRequest());
    Api.callApi(
      `${baseUrl}private/pos/v2/user/verify/email-or-phone-available`,
      HttpAction.Post,
      {},
      params,
    )
      .then(response => {
        dispatch(userManagementAction.validateEmailOrPhoneSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(userManagementAction.validateEmailOrPhoneFail(e));
        reject(e);
      });
  });
};

export const createUser = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(userManagementAction.createUserRequest());
    Api.callApi(`${baseUrl}public/pos/v2/user/register`, HttpAction.Post, {}, params)
      .then(response => {
        dispatch(userManagementAction.createUserSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(userManagementAction.createUserFail(e));
        reject(e);
      });
  });
};

export const updateUser = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(userManagementAction.updateUserRequest());
    Api.callApi(`${baseUrl}private/pos/v2/user`, HttpAction.Put, {}, params)
      .then(response => {
        dispatch(userManagementAction.updateUserSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(userManagementAction.updateUserFail(e));
        reject(e);
      });
  });
};

export const deleteMultipleUser = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(userManagementAction.deleteMultipleRequest());
    Api.callApi(`${baseUrl}private/pos/v2/user/delete/multiple`, HttpAction.Post, {}, params)
      .then(response => {
        dispatch(userManagementAction.deleteMultipleSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(userManagementAction.deleteMultipleFail(e));
        reject(e);
      });
  });
};

export const deleteUser = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(userManagementAction.deleteUserRequest());
    Api.callApi(`${baseUrl}private/pos/v2/user/delete`, HttpAction.Post, {}, params)
      .then(response => {
        dispatch(userManagementAction.deleteUserSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(userManagementAction.deleteUserFail(e));
        reject(e);
      });
  });
};

export const setUpdateUserStatus = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(userManagementAction.setUpdateUserStatusRequest());
    Api.callApi(`${baseUrl}private/pos/v2/user/status`, HttpAction.Put, {}, params)
      .then(response => {
        dispatch(userManagementAction.setUpdateUserStatusSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(userManagementAction.setUpdateUserStatusFail(e));
        reject(e);
      });
  });
};

export const getRolePagination = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(userManagementAction.getRolePaginationRequest());
    Api.callApi(`${baseUrl}private/pos/v2/roles/pagination?`, HttpAction.Get, params, {})
      .then(response => {
        dispatch(userManagementAction.getRolePaginationSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(userManagementAction.getRolePaginationFail(e));
        reject(e);
      });
  });
};

export const setCreateRole = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(userManagementAction.createRoleRequest());
    Api.callApi(`${baseUrl}private/pos/v2/roles`, HttpAction.Post, {}, params)
      .then(response => {
        dispatch(userManagementAction.createRoleSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(userManagementAction.createRoleFail(e));
        reject(e);
      });
  });
};

export const setDeleteRole = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(userManagementAction.deleteRoleRequest());
    Api.callApi(`${baseUrl}private/pos/v2/roles?`, HttpAction.Delete, params, {})
      .then(response => {
        dispatch(userManagementAction.deleteRoleSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(userManagementAction.deleteRoleFail(e));
        reject(e);
      });
  });
};

export const setUpdateRole = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(userManagementAction.updateRoleRequest());
    Api.callApi(`${baseUrl}private/pos/v2/roles`, HttpAction.Put, {}, params)
      .then(response => {
        dispatch(userManagementAction.updateRoleSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(userManagementAction.updateRoleFail(e));
        reject(e);
      });
  });
};

export const getUserDetailsById = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(userManagementAction.getUserDetailsByIdRequest());
    Api.callApi(`${baseUrl}private/pos/v2/user/details`, HttpAction.Get, params, {})
      .then(response => {
        dispatch(userManagementAction.getUserDetailsByIdSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(userManagementAction.getUserDetailsByIdFail(e));
        reject(e);
      });
  });
};

export const getUserActivityPagination = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(userManagementAction.getUserActivityPaginationRequest());
    Api.callApi(`${baseUrl}private/pos/v2/user/activity?`, HttpAction.Get, params, {})
      .then(response => {
        dispatch(userManagementAction.getUserActivityPaginationSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(userManagementAction.getUserActivityPaginationFail(e));
        reject(e);
      });
  });
};

export const getUserActivityDetail = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(userManagementAction.getUserActivityDetailRequest());
    Api.callApi(`${baseUrl}private/pos/v2/user/activity/details?`, HttpAction.Get, params, {})
      .then(response => {
        dispatch(userManagementAction.getUserActivityDetailSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(userManagementAction.getUserActivityDetailFail(e));
        reject(e);
      });
  });
};

export const getMembershipPagination = (dispatch, body) => {
  return new Promise((resolve, reject) => {
    dispatch(userManagementAction.getMembershipPaginationRequest());
    Api.callApi(`${baseUrl}private/pos/v2/user-point-booster/list?`, HttpAction.Post, {}, body)
      .then(response => {
        dispatch(userManagementAction.getMembershipPaginationSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(userManagementAction.getMembershipPaginationFail(e));
        reject(e);
      });
  });
};

export const getMembershipBundleDetail = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(userManagementAction.getMembershipBundleDetailRequest());
    Api.callApi(`${baseUrl}private/pos/v2/user-point-booster/details?`, HttpAction.Get, params, {})
      .then(response => {
        dispatch(userManagementAction.getMembershipBundleDetailSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(userManagementAction.getMembershipBundleDetailFail(e));
        reject(e);
      });
  });
};

export const sendVerifyOtp = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(userManagementAction.sendVerifyOtpRequest());
    Api.callApi(`${baseUrl}private/pos/v2/user/resend-otp`, HttpAction.Post, {}, params)
      .then(response => {
        dispatch(userManagementAction.sendVerifyOtpSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(userManagementAction.sendVerifyOtpFail(e));
        reject(e);
      });
  });
};

export const setCreatePermission = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(userManagementAction.createPermissionRequest());
    Api.callApi(`${baseUrl}private/pos/v2/permission`, HttpAction.Post, {}, params)
      .then(response => {
        dispatch(userManagementAction.createPermissionSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(userManagementAction.createPermissionFail(e));
        reject(e);
      });
  });
};

export const setDeletePermissionRole = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(userManagementAction.deletePermissionRoleRequest());
    Api.callApi(`${baseUrl}private/pos/v2/roles/permission?`, HttpAction.Delete, params, {})
      .then(response => {
        dispatch(userManagementAction.deletePermissionRoleSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(userManagementAction.deletePermissionRoleFail(e));
        reject(e);
      });
  });
};

export const getPermissionGroupByRole = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(userManagementAction.getPermissionGroupByRoleRequest());
    Api.callApi(`${baseUrl}private/pos/v2/permission/list/group?`, HttpAction.Get, params, {})
      .then(response => {
        dispatch(userManagementAction.getPermissionGroupByRoleSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(userManagementAction.getPermissionGroupByRoleFail(e));
        reject(e);
      });
  });
};

export const setPermissionToRole = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(userManagementAction.setPermissionToRoleRequest());
    Api.callApi(`${baseUrl}private/pos/v2/roles/permission`, HttpAction.Post, {}, params)
      .then(response => {
        dispatch(userManagementAction.setPermissionToRoleSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(userManagementAction.setPermissionToRoleFail(e));
        reject(e);
      });
  });
};

export const setEditPermissionToRole = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(userManagementAction.setEditPermissionToRoleRequest());
    Api.callApi(`${baseUrl}private/pos/v2/roles/permission`, HttpAction.Put, {}, params)
      .then(response => {
        dispatch(userManagementAction.setEditPermissionToRoleSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(userManagementAction.setEditPermissionToRoleFail(e));
        reject(e);
      });
  });
};

export const getMembershipDetails = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(userManagementAction.getMembershipDetailsRequest());
    Api.callApi(`${baseUrl}private/pos/v2/user-point-booster/details`, HttpAction.Get, params, {})
      .then(response => {
        dispatch(userManagementAction.getMembershipDetailsSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(userManagementAction.getMembershipDetailsFail(e));
        reject(e);
      });
  });
};

export const getMembershipPurchasedPrice = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(userManagementAction.getMembershipPurchasedPriceRequest());
    Api.callApi(
      `${baseUrl}private/pos/v2/user-point-booster/purchased/price`,
      HttpAction.Get,
      params,
      {},
    )
      .then(response => {
        dispatch(userManagementAction.getMembershipPurchasedPriceSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(userManagementAction.getMembershipPurchasedPriceFail(e));
        reject(e);
      });
  });
};

export const getMemberDetails = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(userManagementAction.getMemberDetailsRequest());
    Api.callApi(
      `${baseUrl}private/pos/v2/user-point-booster/member/details`,
      HttpAction.Get,
      params,
      {},
    )
      .then(response => {
        dispatch(userManagementAction.getMemberDetailsSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(userManagementAction.getMemberDetailsFail(e));
        reject(e);
      });
  });
};

export const requestFreezemembership = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(userManagementAction.requestFreezemembershipRequest());
    Api.callApi(`${baseUrl}private/pos/v2/user-point-booster/freeze`, HttpAction.Post, {}, params)
      .then(response => {
        dispatch(userManagementAction.requestFreezemembershipSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(userManagementAction.requestFreezemembershipFail(e));
        reject(e);
      });
  });
};
