/* eslint-disable no-shadow */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable no-plusplus */
import React from 'react';
import PropTypes from 'prop-types';
// import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Steps, Form } from 'antd';
import { Grid, Breadcrumbs, Link, FormControl, FormLabel } from '@material-ui/core';
import _ from 'lodash';
// Style
import './UserAddStyle.scss';
// import StylesJs from './ScheduleAddStyle';
import {
  ButtonMain,
  UploadImage,
  SelectInputSearchMain,
  SelectSearchMultiple,
  TextAreaMain,
  SnackBarSimple,
  ButtonIconMain,
  PickerInputDate,
  SelectInputMain,
  TextInput,
  CheckInputOutlineBackground,
  SelectInputCompany,
  SelectInputRole,
  CheckInput,
  AuthenticationAccessPages,
} from '../../../../components/Index';
// Helper
import {
  CommonHelper,
  ValidationHelper,
  MasterDataHelper,
  PermissionModule,
  PermissionPage,
  PermissionAccess,
} from '../../../../helpers/Index';
// Action
import { getMerchantList, getBranchList } from '../../../../services/api/MasterDataApi';
import { validateEmailOrPhone, createUser } from '../../../../services/api/UserManagement';
import { compose } from 'redux';

const { Step } = Steps;
const optionToash = {
  vertical: 'top',
  horizontal: 'right',
};

const allMerchantOption = { value: 'all_merchant', name: 'All Merchant' };
const allBranchOption = { value: 'all_branch', name: 'All Branch' };

const initialFilter = {
  companyId: null,
  merchantId: null,
  branchId: null,
};

const initialValidation = {
  firstName: { isError: false, errorMessage: '' },
  lastName: { isError: false, errorMessage: '' },
  birthday: { isError: '', errorMessage: '' },
  company: { isError: '', errorMessage: '' },
  role: { isError: '', errorMessage: '' },
  merchant: { isError: '', errorMessage: '' },
  branch: { isError: '', errorMessage: '' },
  email: { isError: false, errorMessage: '' },
  phone: { isError: false, errorMessage: '' },
  address: { isError: '', errorMessage: '' },
};

const resetValidation = { isError: '', errorMessage: '' };

const nationOptions = MasterDataHelper.nationality;
const genderOptions = MasterDataHelper.gender;
const currentDate = CommonHelper.currentDate('YYYY-MM-DD');
const defaultBirthday = CommonHelper.intervalDate(currentDate, 'YYYY-MM-DD', -3, 'year');

class UserAdd extends React.Component {
  constructor(props) {
    super(props);

    props.checkUserAccessPermission(
      PermissionModule.UserManagement,
      PermissionPage.Users,
      PermissionAccess.Add,
    );

    this.state = {
      current: 0,
      stepList: [
        { key: 'personal_information', name: 'Personal Information' },
        { key: 'company', name: 'Company' },
        { key: 'address', name: 'Address' },
      ],
      form: {
        isStaff: false,
        firstName: '',
        lastName: '',
        merchantId: null,
        branchId: null,
        companyId: null,
        roleId: null,
        birthday: defaultBirthday,
        nation: nationOptions[0].value,
        gender: genderOptions[0].value,
        email: '',
        phone: '',
        address: '',
        imageBase64: null,
      },
      filter: initialFilter,
      setRole: false,
      validation: initialValidation,
      toastInformation: {
        isOpen: false,
        message: '',
        snackbarType: 'warning',
      },
      isLoading: false,
      moreMerchantAndBranch: 0,
      branchListMore: {},
      moreFieldFormMerchant: [],
      moreFieldFormBranch: [],
      userMerchants: [{ merchant_id: 'all_merchant', all_branch: false, branch_id: [] }],
    };
    this.searchDebounce = _.debounce(this.searchDebounce, 400);
  }

  componentDidMount() {}

  componentWillUnmount() {}

  // Start Handle Change Field

  handleClick = (event, value) => {
    const { history } = this.props;
    event.preventDefault();
    history.push(value);
  };

  handleChangeFirstName = value => {
    const { form, validation } = this.state;
    const message = {
      isError: false,
      errorMessage: '',
    };

    if (!value || value === '') {
      message.isError = true;
      message.errorMessage = 'First Name Empty';
      this.setState({
        form: { ...form, firstName: '' },
        validation: { ...validation, firstName: message },
      });
    } else {
      this.setState({
        form: { ...form, firstName: value.trim() },
        validation: { ...validation, firstName: message },
      });
    }
  };

  handleChangeLastName = value => {
    const { form, validation } = this.state;
    const message = {
      isError: false,
      errorMessage: '',
    };

    if (!value || value === '') {
      message.isError = true;
      message.errorMessage = 'Last Name Empty';

      this.setState({
        form: { ...form, lastName: '' },
        validation: { ...validation, lastName: message },
      });
    } else {
      this.setState({
        form: { ...form, lastName: value.trim() },
        validation: { ...validation, lastName: message },
      });
    }
  };

  handleChangeGender = value => {
    const { form } = this.state;
    this.setState({ form: { ...form, gender: value } });
  };

  handleChangeBirthday = value => {
    const { form } = this.state;
    this.setState({
      form: { ...form, birthday: value },
    });
  };

  handleChangeNation = value => {
    const { form } = this.state;
    this.setState({
      form: { ...form, nation: value },
    });
  };

  handleChangeCompany = value => {
    const { filter, form, validation } = this.state;
    const { getMerchants } = this.props;
    const userMerchants = [...this.state.userMerchants];

    const item = { ...userMerchants[0] };
    item.merchant_id = 'all_merchant';
    item.all_branch = true;
    item.branch_id = ['all_branch'];
    userMerchants[0] = item;

    this.setState(
      {
        filter: {
          ...filter,
          companyId: value || null,
        },
        form: {
          ...form,
          companyId: value || null,
        },
        userMerchants,
        validation: {
          ...validation,
          company: resetValidation,
        },
      },
      () => {
        const params = { company_id: value };
        getMerchants(params);
      },
    );
  };

  handleChangeRole = value => {
    const { form, validation } = this.state;
    this.setState({
      form: { ...form, roleId: value },
      validation: {
        ...validation,
        role: resetValidation,
      },
    });
  };

  handleChangeMerchant = (merchantId, index) => {
    const { getBranchs } = this.props;
    const { validation } = this.state;
    let flagPass = true;
    const userMerchants = [...this.state.userMerchants];

    userMerchants.forEach(item => {
      if (item.merchant_id === merchantId) flagPass = false;
    });

    if (flagPass) {
      const item = { ...userMerchants[index] };
      item.merchant_id = merchantId;
      item.all_branch = false;
      item.branch_id = [];
      userMerchants[index] = item;

      this.setState({ userMerchants }, () => {
        if (index === 0) {
          let message = resetValidation;

          if (!this.state.userMerchants[index].merchant_id) {
            message = {
              isError: 'Error',
              errorMessage: 'please input your Merchant',
            };
          }

          this.setState(
            {
              validation: {
                ...validation,
                merchant: message,
              },
            },
            () => {
              if (merchantId === 'all_merchant') {
                for (let i = 0; i < userMerchants.length; i++) {
                  this.handleRemoveMore(userMerchants.length - i);
                }
              }
            },
          );
        } else {
          const moreFieldFormMerchant = [...this.state.moreFieldFormMerchant];
          const currentItem = { ...moreFieldFormMerchant[index - 1] };

          currentItem.validationStatus = !this.state.userMerchants[index].merchant_id
            ? 'error'
            : '';
          currentItem.errorMessage = !this.state.userMerchants[index].merchant_id
            ? 'please input your Merchant'
            : '';
          moreFieldFormMerchant[index - 1] = currentItem;

          this.setState({
            moreFieldFormMerchant,
          });
        }
      });
      if (merchantId) {
        getBranchs({ merchant_id: merchantId === 'all_merchant' ? [] : [merchantId] }).then(
          response => {
            this.setState({
              branchListMore: { ...this.state.branchListMore, [index]: response.data },
            });
          },
        );
      } else {
        this.setState({
          branchListMore: { ...this.state.branchListMore, [index]: [] },
        });
      }
    } else {
      const item = { ...userMerchants[index] };
      item.merchant_id = null;
      item.all_branch = false;
      item.branch_id = [];
      userMerchants[index] = item;

      this.setState({ userMerchants }, () => {
        const message = `Can't Select Same Merchant!!!`;
        this.processMessage(message, 'warning');
      });
    }
  };

  handleChangeBranch = (branchId, index) => {
    const { validation } = this.state;
    const userMerchants = [...this.state.userMerchants];
    const item = { ...userMerchants[index] };
    item.all_branch = branchId[0] === 'all_branch';
    item.branch_id = branchId;
    userMerchants[index] = item;

    this.setState({ userMerchants }, () => {
      if (index === 0) {
        let message = resetValidation;

        if (this.state.userMerchants[index].branch_id.length === 0) {
          message = {
            isError: 'Error',
            errorMessage: 'please input your branch',
          };
        }

        this.setState({
          validation: {
            ...validation,
            branch: message,
          },
        });
      } else {
        const moreFieldFormBranch = [...this.state.moreFieldFormBranch];
        const currentItem = { ...moreFieldFormBranch[index - 1] };
        currentItem.validationStatus =
          this.state.userMerchants[index].branch_id.length === 0 ? 'error' : '';
        currentItem.errorMessage =
          this.state.userMerchants[index].branch_id.length === 0 ? 'please input your Branch' : '';
        moreFieldFormBranch[index - 1] = currentItem;
        this.setState({
          moreFieldFormBranch,
        });
      }
    });
  };

  handleChangeAddress = value => {
    const { form, validation } = this.state;
    const message = {
      isError: '',
      errorMessage: '',
    };

    this.setState(
      {
        form: { ...form, address: value },
      },
      () => {
        if (!value || value === '') {
          message.isError = 'error';
          message.errorMessage = 'Address Empty';
        }
        this.setState({
          validation: { ...validation, address: message },
        });
      },
    );
  };

  handleChangeEmail = value => {
    const emailValidation = ValidationHelper.validateEmail(value.trim());

    const { form, validation } = this.state;
    const message = {
      isError: false,
      errorMessage: '',
    };

    this.setState(
      {
        form: { ...form, email: value.trim() },
      },
      () => {
        if (!emailValidation) {
          message.isError = true;
          message.errorMessage = 'please check your email, and try again';
        }
        this.setState(
          {
            validation: { ...validation, email: message },
          },
          () => {
            const params = {
              data: {
                email: value.trim(),
              },
            };
            this.searchDebounce(params);
          },
        );
      },
    );
  };

  handleChangePhone = value => {
    const phoneValidation = ValidationHelper.validatePhone(value.trim());
    const { form, validation } = this.state;
    const message = {
      isError: false,
      errorMessage: '',
    };

    this.setState(
      {
        form: { ...form, phone: value.trim() },
      },
      () => {
        if (!phoneValidation) {
          message.isError = true;
          message.errorMessage = 'please check your Phone, and try again';
        }
        this.setState(
          {
            validation: { ...validation, phone: message },
          },
          () => {
            const params = {
              data: {
                phone: value.trim(),
              },
            };
            this.searchDebounce(params);
          },
        );
      },
    );
  };

  handleChangeImage = imageBase64 => {
    const { form } = this.state;

    this.setState({
      form: { ...form, imageBase64 },
    });
  };

  handleClose = () => {
    const { toastInformation } = this.state;
    this.setState({ toastInformation: { ...toastInformation, isOpen: false } });
  };

  searchDebounce = params => {
    const { checkEmailOrPhone } = this.props;
    const { validation } = this.state;

    checkEmailOrPhone(params).catch(response => {
      const { messages } = response.data;
      let paramValidation01 = {
        isError: false,
        errorMessage: '',
      };
      let paramValidation02 = {
        isError: false,
        errorMessage: '',
      };

      if (messages.hasOwnProperty('email')) {
        const convertedMessage = CommonHelper.generateMessage(messages);
        paramValidation01 = {
          isError: true,
          errorMessage: convertedMessage,
        };

        this.setState({
          validation: { ...validation, email: paramValidation01 },
        });
      }

      if (messages.hasOwnProperty('phone')) {
        const convertedMessage = CommonHelper.generateMessage(messages);
        paramValidation02 = {
          isError: true,
          errorMessage: convertedMessage,
        };
        this.setState({
          validation: { ...validation, phone: paramValidation02 },
        });
      }
    });
  };

  handleIsStaff = value => {
    const { form, validation } = this.state;
    const message = resetValidation;

    this.setState({
      form: { ...form, isStaff: value },
      validation: { ...validation, company: message, branch: message },
    });
  };

  handleSetRole = value => {
    this.setState({
      setRole: value,
    });
  };

  handleAddMore = () => {
    const { userMerchants } = this.state;
    const {
      masterData: { merchantList },
    } = this.props;

    if (
      userMerchants[0].merchant_id !== undefined &&
      userMerchants[0].merchant_id !== 'all_merchant' &&
      userMerchants.length < merchantList.length
    ) {
      const newMerchant = this.state.moreMerchantAndBranch + 1;

      if (typeof this.state.userMerchants[newMerchant] === 'undefined') {
        this.setState({
          userMerchants: [
            ...this.state.userMerchants,
            { merchant_id: undefined, all_branch: false, branch_id: [] },
          ],
        });
      }

      this.setState({
        moreMerchantAndBranch: newMerchant,
        moreFieldFormMerchant: [
          ...this.state.moreFieldFormMerchant,
          { validationStatus: '', errorMessage: '' },
        ],
        moreFieldFormBranch: [
          ...this.state.moreFieldFormBranch,
          { validationStatus: '', errorMessage: '' },
        ],
      });
    } else {
      const message = `Can't add more than Existing Merchant!!!`;
      this.processMessage(message, 'warning');
    }
  };

  handleRemoveMore = index => {
    const newMerchant = this.state.moreMerchantAndBranch - 1;
    this.setState({ moreMerchantAndBranch: newMerchant });

    this.setState({
      moreFieldFormMerchant: this.state.moreFieldFormMerchant.filter((_, i) => i !== index - 1),
      moreFieldFormBranch: this.state.moreFieldFormBranch.filter((_, i) => i !== index - 1),
      userMerchants: this.state.userMerchants.filter((_, i) => i !== index),
    });
  };

  handleSubmit = () => {
    const { registerUser } = this.props;

    const {
      form: {
        firstName,
        lastName,
        gender,
        birthday,
        nation,
        companyId,
        roleId,
        email,
        phone,
        address,
        isStaff,
        imageBase64,
      },
      userMerchants,
    } = this.state;

    const params = {
      data: {
        first_name: firstName,
        last_name: lastName,
        email,
        phone,
        is_staff: isStaff,
        all_merchant: userMerchants[0].merchant_id === 'all_merchant',
        company_details: {
          company_id: companyId,
          user_merchants: companyId ? userMerchants : [],
          role_id: roleId || '',
        },
        user_details: {
          image: imageBase64 || '',
          gender,
          birthday,
          nation,
          identity_number: '',
        },
        user_address: {
          address: address.trim(),
        },
      },
    };

    if (this.validationStepAddress()) {
      this.setState(
        {
          isLoading: true,
        },
        () => {
          registerUser(params)
            .then(() => {
              const {
                userManagement: { responseMessage },
                history,
              } = this.props;

              this.processMessage(responseMessage, 'success');
              this.setState(
                {
                  isLoading: false,
                },
                () => {
                  setTimeout(() => {
                    history.push('/management/users');
                  }, 2000);
                },
              );
            })
            .catch(async error => {
              const message = error.data;
              await this.processMessage(message.messages, 'error');
              await this.setState({ isLoading: false });
            });
        },
      );
    }
  };
  // End Handle Change Field

  generateMoreMerchantAndBranch = moreMerchantAndBranch => {
    const {
      form: { isStaff },
      branchListMore,
      moreFieldFormMerchant,
      moreFieldFormBranch,
      userMerchants,
    } = this.state;
    const {
      masterData: { merchantList },
    } = this.props;
    const merchantListCustom = CommonHelper.renameKeyName(merchantList, 'merchant_id', 'value');

    if (moreMerchantAndBranch > 0) {
      const moreComponent = [];
      for (let i = 1; i <= moreMerchantAndBranch; i++) {
        const branchListLoop = CommonHelper.renameKeyName(
          branchListMore[i] || [],
          'branch_id',
          'value',
        );

        moreComponent.push(
          <div key={i} style={{ marginTop: 10, padding: 20, backgroundColor: '#F8F9FC' }}>
            <Grid container spacing={2} className={!isStaff ? 'disabled' : ''}>
              <Grid item xs={6}>
                <FormControl component="fieldset" fullWidth margin={'normal'}>
                  <FormLabel component="label" className="form-label">
                    Merchant
                  </FormLabel>
                  <SelectInputSearchMain
                    options={merchantListCustom}
                    onChange={items => {
                      this.handleChangeMerchant(items, i);
                    }}
                    currentValue={
                      userMerchants.length >= 1 ? userMerchants[i].merchant_id : userMerchants
                    }
                    size={'large'}
                    placeholder={'Select Merchant'}
                    validateStatus={moreFieldFormMerchant[i - 1].validationStatus || ''}
                    errorMessage={moreFieldFormMerchant[i - 1].errorMessage || ''}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl component="fieldset" fullWidth margin={'normal'}>
                  <FormLabel component="label" className="form-label">
                    Location/Branch
                  </FormLabel>
                  <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                    <div style={{ width: '90%' }}>
                      <SelectSearchMultiple
                        options={branchListLoop}
                        onChange={items => {
                          this.handleChangeBranch(items, i);
                        }}
                        currentValue={
                          userMerchants.length >= 1 ? userMerchants[i].branch_id : userMerchants
                        }
                        size={'large'}
                        placeholder={'Select Branch'}
                        validateStatus={moreFieldFormBranch[i - 1].validationStatus || ''}
                        errorMessage={moreFieldFormBranch[i - 1].errorMessage || ''}
                      />
                    </div>
                    <div style={{ width: '10%' }}>
                      <ButtonIconMain
                        icon="ic-ffo-close"
                        type="secondary"
                        size="sm"
                        onClick={() => this.handleRemoveMore(i)}
                      />
                    </div>
                  </div>
                </FormControl>
              </Grid>
            </Grid>
          </div>,
        );
      }
      return moreComponent;
    }
    return null;
  };

  generateContentStep = step => {
    const {
      form: {
        firstName,
        lastName,
        gender,
        birthday,
        nation,
        isStaff,
        companyId,
        address,
        email,
        phone,
      },
      moreMerchantAndBranch,
      branchListMore,
      userMerchants,
    } = this.state;
    const {
      masterData: { merchantList },
    } = this.props;

    const merchantListCustom = CommonHelper.renameKeyName(merchantList, 'merchant_id', 'value');
    const branchListCustom = CommonHelper.renameKeyName(
      branchListMore[0] || [],
      'branch_id',
      'value',
    );

    switch (step.key) {
      case 'personal_information':
        return (
          <div>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormControl component="fieldset" fullWidth margin={'normal'}>
                  <FormLabel component="label" className="form-label">
                    First Name
                  </FormLabel>
                  <TextInput
                    placeHolderText={'First Name'}
                    currentValue={firstName}
                    onChange={this.handleChangeFirstName}
                    isError={this.state.validation.firstName.isError}
                    errorMessage={this.state.validation.firstName.errorMessage}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl component="fieldset" fullWidth margin={'normal'}>
                  <FormLabel component="label" className="form-label">
                    Last Name
                  </FormLabel>
                  <TextInput
                    placeHolderText={'Last Name'}
                    currentValue={lastName}
                    onChange={this.handleChangeLastName}
                    isError={this.state.validation.lastName.isError}
                    errorMessage={this.state.validation.lastName.errorMessage}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormControl component="fieldset" fullWidth margin={'normal'}>
                  <FormLabel component="label" className="form-label">
                    Gender
                  </FormLabel>
                  <SelectInputMain
                    options={genderOptions}
                    size="large"
                    defaultValue={gender}
                    onChange={this.handleChangeGender}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl component="fieldset" fullWidth margin={'normal'}>
                  <FormLabel component="label" className="form-label">
                    Birthday
                  </FormLabel>
                  <Form.Item>
                    <PickerInputDate
                      customIcon="ic-ffo-date-pick"
                      dateFormat="dd-MM-yyyy"
                      maxDate={defaultBirthday}
                      defaultValue={birthday}
                      onChange={this.handleChangeBirthday}
                      toolbar={false}
                    />
                  </Form.Item>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormControl component="fieldset" fullWidth margin={'normal'}>
                  <FormLabel component="label" className="form-label">
                    Nation
                  </FormLabel>
                  <SelectInputMain
                    options={nationOptions}
                    size="large"
                    defaultValue={nation}
                    onChange={this.handleChangeNation}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </div>
        );
      case 'company':
        return (
          <div>
            <Grid container>
              <Grid
                item
                xs={6}
                style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}
              >
                <CheckInputOutlineBackground
                  labelText="Make this as staff"
                  onCheck={this.handleIsStaff}
                  currentValue={isStaff}
                />
              </Grid>
              <Grid item xs={6} />
            </Grid>
            <Grid container spacing={2} className={!this.state.form.isStaff ? 'disabled' : ''}>
              <Grid item xs={6}>
                <FormControl component="fieldset" fullWidth margin={'normal'}>
                  <FormLabel component="label" className="form-label">
                    Company
                  </FormLabel>
                  <SelectInputCompany
                    placeHolder="Select Company"
                    onChange={this.handleChangeCompany}
                    currentValue={companyId}
                    errorMessage={this.state.validation.company.errorMessage}
                    validateStatus={this.state.validation.company.isError}
                    includeAllData
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl component="fieldset" fullWidth margin={'normal'}>
                  <FormLabel component="label" className="form-label">
                    <CheckInput
                      colorCheck="primary"
                      labelText="Set user role as"
                      onCheck={this.handleSetRole}
                      currentValue={this.state.setRole}
                    />
                  </FormLabel>
                  <div className={!this.state.setRole ? 'disabled' : ''}>
                    <SelectInputRole
                      onChange={this.handleChangeRole}
                      currentValue={this.state.form.roleId}
                      errorMessage={this.state.validation.role.errorMessage}
                      validateStatus={this.state.validation.role.isError}
                      includeAllData
                    />
                  </div>
                </FormControl>
              </Grid>
            </Grid>
            <div style={{ padding: 20, backgroundColor: '#F8F9FC' }}>
              <Grid container spacing={2} className={!isStaff ? 'disabled' : ''}>
                <Grid item xs={6}>
                  <FormControl component="fieldset" fullWidth margin={'normal'}>
                    <FormLabel component="label" className="form-label">
                      Merchant
                    </FormLabel>
                    <SelectInputSearchMain
                      options={[allMerchantOption, ...merchantListCustom]}
                      onChange={items => {
                        this.handleChangeMerchant(items, 0);
                      }}
                      currentValue={
                        userMerchants.length >= 1 ? userMerchants[0].merchant_id : userMerchants
                      }
                      size={'large'}
                      placeholder={'Select Merchant'}
                      errorMessage={this.state.validation.merchant.errorMessage}
                      validateStatus={this.state.validation.merchant.isError}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl component="fieldset" fullWidth margin={'normal'}>
                    <FormLabel component="label" className="form-label">
                      Location/Branch
                    </FormLabel>
                    <SelectSearchMultiple
                      options={[allBranchOption, ...branchListCustom]}
                      onChange={item => {
                        this.handleChangeBranch(item, 0);
                      }}
                      currentValue={
                        userMerchants.length >= 1 ? userMerchants[0].branch_id : userMerchants
                      }
                      size={'large'}
                      placeholder={'Select Branch'}
                      errorMessage={this.state.validation.branch.errorMessage}
                      validateStatus={this.state.validation.branch.isError}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </div>
            {this.generateMoreMerchantAndBranch(moreMerchantAndBranch)}
            <Grid container style={{ paddingTop: 16 }} className={!isStaff ? 'disabled' : ''}>
              <Grid item xs={12}>
                <ButtonMain
                  startIcon="ic-ffo-add"
                  type="more"
                  size="md"
                  labelText="Add More Merchant And Branch"
                  onClick={this.handleAddMore}
                />
              </Grid>
            </Grid>
          </div>
        );
      case 'address':
        return (
          <div>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormControl component="fieldset" fullWidth margin={'normal'}>
                  <FormLabel component="label" className="form-label">
                    Email
                  </FormLabel>
                  <TextInput
                    placeHolderText={'Email'}
                    onChange={this.handleChangeEmail}
                    currentValue={email}
                    isError={this.state.validation.email.isError}
                    errorMessage={this.state.validation.email.errorMessage}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl component="fieldset" fullWidth margin={'normal'}>
                  <FormLabel component="label" className="form-label">
                    Phone Number
                  </FormLabel>
                  <TextInput
                    placeHolderText={'Phone Number'}
                    onChange={this.handleChangePhone}
                    currentValue={phone}
                    isError={this.state.validation.phone.isError}
                    errorMessage={this.state.validation.phone.errorMessage}
                    maxLength={14}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormControl component="fieldset" fullWidth margin={'normal'}>
                  <FormLabel component="label" className="form-label">
                    Address
                  </FormLabel>
                  <TextAreaMain
                    onChange={item => {
                      this.handleChangeAddress(item);
                    }}
                    currentValue={address}
                    placeholder="Please enter your address"
                    rows={4}
                    validateStatus={this.state.validation.address.isError}
                    errorMessage={this.state.validation.address.errorMessage}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </div>
        );
      default:
        return null;
    }
  };

  validationStepCompany = () => {
    const {
      form: { isStaff, companyId, roleId },
      setRole,
      validation,
      moreMerchantAndBranch,
      userMerchants,
    } = this.state;

    let passCheck = true;
    let paramValidation01 = resetValidation;
    let paramValidation02 = resetValidation;
    let paramValidation03 = resetValidation;
    let paramValidation04 = resetValidation;

    if (!isStaff) {
      return true;
    }

    if (!companyId) {
      paramValidation01 = {
        isError: 'error',
        errorMessage: 'Please enter your Company',
      };

      passCheck = false;
    }

    if (setRole) {
      if (!roleId) {
        paramValidation02 = {
          isError: 'error',
          errorMessage: 'Please enter your Role',
        };

        passCheck = false;
      }
    }

    if (!userMerchants[0].merchant_id) {
      paramValidation03 = {
        isError: 'error',
        errorMessage: 'Please select your Merchant',
      };
      passCheck = false;
    }

    if (userMerchants[0].branch_id.length === 0) {
      paramValidation04 = {
        isError: 'error',
        errorMessage: 'Please select your branch',
      };
      passCheck = false;
    }

    if (moreMerchantAndBranch > 0) {
      const moreFieldFormMerchant = [...this.state.moreFieldFormMerchant];
      const moreFieldFormBranch = [...this.state.moreFieldFormBranch];

      for (let index = 1; index <= moreMerchantAndBranch; index++) {
        const itemMerchant = { ...moreFieldFormMerchant[index - 1] };
        itemMerchant.validationStatus =
          this.state.userMerchants[index - 1].merchant_id === undefined ? 'error' : '';
        itemMerchant.errorMessage =
          this.state.userMerchants[index - 1].merchant_id === undefined
            ? 'please input your Merchant'
            : '';
        moreFieldFormMerchant[index - 1] = itemMerchant;

        const itemBranch = { ...moreFieldFormBranch[index - 1] };
        itemBranch.validationStatus =
          this.state.userMerchants[index].branch_id.length === 0 ? 'error' : '';
        itemBranch.errorMessage =
          this.state.userMerchants[index].branch_id.length === 0 ? 'please input your Branch' : '';
        moreFieldFormBranch[index - 1] = itemBranch;

        if (
          moreFieldFormMerchant[index - 1].validationStatus === 'error' ||
          moreFieldFormBranch[index - 1].validationStatus === 'error'
        ) {
          passCheck = false;
        }

        this.setState({
          moreFieldFormMerchant,
          moreFieldFormBranch,
        });
      }
    }

    if (!passCheck) {
      this.setState({
        isLoading: true,
        validation: {
          ...validation,
          company: paramValidation01,
          role: paramValidation02,
          merchant: paramValidation03,
          branch: paramValidation04,
        },
      });
    }

    return passCheck;
  };

  validationStepAddress() {
    const {
      form: { email, phone, address },
      validation,
    } = this.state;

    let passCheck = true;
    let paramValidation01 = {
      isError: validation.email.isError,
      errorMessage: validation.email.errorMessage,
    };
    let paramValidation02 = {
      isError: validation.phone.isError,
      errorMessage: validation.phone.errorMessage,
    };
    let paramValidation03 = resetValidation;

    if (email === null || email === '') {
      if (!paramValidation01.isError) {
        paramValidation01 = {
          isError: true,
          errorMessage: 'Email Empty',
        };
      }
      passCheck = false;
    }

    if (phone === null || phone === '') {
      if (!paramValidation02.isError) {
        paramValidation02 = {
          isError: true,
          errorMessage: 'Phone Empty',
        };
      }
      passCheck = false;
    }

    if (address === null || address === '') {
      paramValidation03 = {
        isError: 'error',
        errorMessage: 'Address Empty',
      };
      passCheck = false;
    }

    if (!passCheck) {
      this.setState({
        validation: {
          ...validation,
          email: paramValidation01,
          phone: paramValidation02,
          address: paramValidation03,
        },
      });
    }

    return passCheck;
  }

  validationStepPersonalInformation() {
    const { form, validation } = this.state;

    let passCheck = true;
    let paramValidation01 = resetValidation;
    let paramValidation02 = resetValidation;
    let paramValidation03 = resetValidation;

    if (_.isEmpty(form.firstName)) {
      paramValidation01 = {
        isError: true,
        errorMessage: 'Please enter your First Name',
      };

      passCheck = false;
    }

    if (_.isEmpty(form.lastName)) {
      paramValidation02 = {
        isError: true,
        errorMessage: 'Please enter your Last Name',
      };

      passCheck = false;
    }

    if (_.isEmpty(form.birthday)) {
      paramValidation03 = {
        isError: '',
        errorMessage: 'Please select your Birth Day',
      };

      passCheck = false;
    }

    if (!passCheck) {
      this.setState({
        isLoading: true,
        validation: {
          ...validation,
          firstName: paramValidation01,
          lastName: paramValidation02,
          birthday: paramValidation03,
        },
      });
    }

    return passCheck;
  }

  processMessage(messages, type) {
    const convertedMessage = CommonHelper.generateMessage(messages);

    const paramInformation = {
      isOpen: true,
      message: convertedMessage,
      snackbarType: type,
    };

    this.setState({
      toastInformation: paramInformation,
    });
  }

  handleNext() {
    const { current } = this.state;
    let isNext = false;

    switch (current) {
      case 0:
        isNext = this.validationStepPersonalInformation();
        break;
      case 1:
        isNext = this.validationStepCompany();
        break;
      default:
        break;
    }

    if (isNext) {
      const newCurrent = this.state.current + 1;
      this.setState({ current: newCurrent });
    }
  }

  handlePrev() {
    const current = this.state.current - 1;
    this.setState({ current });
  }

  render() {
    const { current, stepList, form, toastInformation } = this.state;

    return (
      <div style={{ height: '100vh', width: '100%', backgroundColor: '#F8F9FC' }}>
        <Helmet title="FITCO | User Managements - Create User" />
        <div className="row" style={{ paddingTop: 24, paddingLeft: 53, paddingRight: 53 }}>
          <div className="breadcrumbs-section" style={{ marginBottom: 24 }}>
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                className="text-12"
                color="inherit"
                href="/management/users"
                onClick={event => {
                  this.handleClick(event, '/management/users');
                }}
              >
                <i className="icon-slot ic-ffo-account" /> User Management
              </Link>
              <label className="text-12" color="inherit">
                Add New User
              </label>
            </Breadcrumbs>
          </div>
          <div className="main-card dotted">
            <Grid container>
              <Grid item xs={12} className="card-title">
                <label className="text-16 text-bold text-rolling-stone" color="inherit">
                  Create New User
                </label>
              </Grid>
            </Grid>
            <Grid container style={{ paddingTop: 24 }}>
              <Grid item xs={3}>
                <div className="container-profile-image">
                  <UploadImage
                    onChange={item => this.handleChangeImage(item)}
                    defaultValue={form.imageBase64}
                    maxSize={1}
                  />
                </div>
              </Grid>
              <Grid item xs={9}>
                <div>
                  <Steps current={current} size="small">
                    {stepList.map(item => (
                      <Step key={item.key} title={item.name} />
                    ))}
                  </Steps>
                  <div className="steps-content" style={{ paddingTop: 32 }}>
                    {this.generateContentStep(stepList[current])}
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
          <Grid container>
            <Grid item xs={6} />
            <Grid item xs={6} className="footer-step">
              <div style={{ width: '30%', padding: 10 }}>
                {current < stepList.length - 1 && (
                  <ButtonMain
                    type="primary"
                    size="md"
                    labelText="Next"
                    onClick={() => this.handleNext()}
                  />
                )}
                {current === stepList.length - 1 && (
                  <ButtonMain
                    type="primary"
                    size="md"
                    labelText="Create"
                    onClick={this.handleSubmit}
                    isLoading={this.state.isLoading}
                  />
                )}
              </div>
              <div style={{ width: '30%', padding: 10 }}>
                <ButtonMain
                  type="ghost"
                  size="md"
                  labelText="Previous"
                  disabled={!(current > 0)}
                  onClick={() => this.handlePrev()}
                />
              </div>
            </Grid>
          </Grid>
        </div>
        <SnackBarSimple
          open={toastInformation.isOpen}
          durationHide={2000}
          message={toastInformation.message}
          onClickClose={this.handleClose}
          snackbarType={toastInformation.snackbarType}
          anchor={optionToash}
        />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getMerchants: params => getMerchantList(dispatch, params),
  getBranchs: params => getBranchList(dispatch, params),
  checkEmailOrPhone: params => validateEmailOrPhone(dispatch, params),
  registerUser: params => createUser(dispatch, params),
});

const mapStateToProps = ({ masterData, userManagement }) => ({
  masterData,
  userManagement,
});

UserAdd.propTypes = {
  checkEmailOrPhone: PropTypes.func,
  checkUserAccessPermission: PropTypes.func,
  getBranchs: PropTypes.func,
  getMerchants: PropTypes.func,
  history: PropTypes.object,
  masterData: PropTypes.object,
  registerUser: PropTypes.func,
  userManagement: PropTypes.object,
};

const shell = compose(connect(mapStateToProps, mapDispatchToProps));

export default shell(AuthenticationAccessPages(UserAdd));
