/* eslint-disable react/no-access-state-in-setstate */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Table } from 'antd';
import _ from 'lodash';
import { Grid } from '@material-ui/core';
import { compose } from 'redux';
// style
import './MembershipPurchasePageStyle.scss';
// component
import {
  TextInput,
  SnackBarSimple,
  SelectInputBranch,
  PickerInputDate,
  GeneratorExcel,
  LabelStatusPayment,
  AuthenticationAccessPages,
  PrevStateValue,
  SkeletonPagination,
  SelectInputMain,
} from '../../../../../components/Index';
// api
import {
  getMembershipPurchasePagination,
  getMembershipPurchaseReportExport,
} from '../../../../../services/api/MembershipApi';
// helper
import {
  CommonHelper,
  membershipRequestTypeCode,
  statusPaymentCode,
  paymentMethodOptionCode,
  MasterDataHelper,
} from '../../../../../helpers/Index';
// assets
import { Icons } from '../../../../../assets/Index';

const currentDate = CommonHelper.currentDate('YYYY-MM-DD');
const startDateValue = CommonHelper.getStartDateMonth(currentDate);
const endDateValue = CommonHelper.getEndDateMonth(currentDate);
const optionTransactionSource = MasterDataHelper.optionTransactionSource;
const optionStatusPayment = MasterDataHelper.statusPaymentOptionV2;

const initialFilter = {
  merchantId: null,
  search: '',
  branchId: null,
  requestType: membershipRequestTypeCode.purchase,
  requestStatus: statusPaymentCode.Complete,
  startDate: startDateValue,
  endDate: endDateValue,
  transactionSource: undefined,
  selectedStatusPayment: undefined,
};

const initialSort = {
  sortField: 'user_name',
  sortOrder: '',
};

const optionToash = {
  vertical: 'top',
  horizontal: 'right',
};

class MembershipPurchasePage extends React.Component {
  constructor(props) {
    super(props);
    const {
      usersReducer: { prevStateValue },
    } = props;

    const updateExitingFilter = {
      ...CommonHelper.objectCloning(initialFilter),
      ...prevStateValue,
    };

    this.state = {
      filter: updateExitingFilter,
      page: 1,
      limit: 10,
      sort: CommonHelper.objectCloning(initialSort),
      pagination: {
        showSizeChanger: true,
        showTotal: (total, range) => `Showing ${range[0]}-${range[1]} From ${total}`,
        pageSizeOptions: ['10', '20', '30', '40'],
      },
      isLoading: true,
      toastInformation: {
        isOpen: false,
        message: '',
        snackbarType: 'warning',
      },
    };
    this.searchDebounce = _.debounce(this.searchDebounce, 400);
  }

  componentDidMount() {
    const { filter } = this.state;
    const { usersReducer } = this.props;

    this.setState(
      {
        filter: {
          ...filter,
          merchantId: usersReducer.activeMerchant,
        },
      },
      () => {
        this.getMembershipRequestPagination();
      },
    );
  }

  componentWillReceiveProps(nextProps) {
    const { filter } = this.state;
    const { usersReducer } = this.props;

    const isEqualActiveMerchantId = _.isEqual(
      usersReducer.activeMerchant,
      nextProps.usersReducer.activeMerchant,
    );

    if (!isEqualActiveMerchantId) {
      this.setState({
        filter: { ...filter, merchantId: nextProps.usersReducer.activeMerchant },
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { filter } = this.state;
    const { usersReducer, masterData } = this.props;
    const currentMerchant = usersReducer.activeMerchant;

    if (
      currentMerchant !== filter.merchantId ||
      prevProps.masterData.branchList.length !== masterData.branchList.length
    ) {
      this.resetMerchantBranch();
    }
  }

  getMembershipRequestPagination() {
    const { membershipPurchasePagination } = this.props;
    const {
      limit,
      pagination,
      page,
      filter: {
        search,
        startDate,
        endDate,
        requestType,
        requestStatus,
        transactionSource,
        selectedStatusPayment,
      },
      sort,
    } = this.state;

    const params = {
      merchant_id: this.state.filter.merchantId,
      branch_id: this.state.filter.branchId,
      search,
      start_date: startDate,
      end_date: endDate,
      request_status: requestStatus,
      request_type: requestType,
      transaction_source: transactionSource,
      payment_status: selectedStatusPayment,
      page,
      limit,
      sort: sort.sortField,
      sortOrder: sort.sortOrder,
    };

    if (requestStatus) {
      membershipPurchasePagination(params).then(response => {
        this.setState({
          isLoading: false,
          pagination: { ...pagination, pageSize: limit, total: response.total },
        });
      });
    }
  }

  handleChangeSearch = value => {
    const { filter } = this.state;

    this.setState(
      {
        filter: { ...filter, search: value, sort: JSON.parse(JSON.stringify(initialSort)) },
        page: 1,
        pagination: { ...this.state.pagination, current: 1 },
      },
      () => {
        this.searchDebounce();
      },
    );
  };

  handleChangeStartDate = value => {
    const { filter } = this.state;
    const endDate = CommonHelper.getEndDateMonth(value);

    this.setState(
      {
        filter: { ...filter, startDate: value, endDate },
      },
      () => {
        this.getMembershipRequestPagination();
      },
    );
  };

  handleChangeEndDate = value => {
    const { filter } = this.state;

    this.setState(
      {
        filter: { ...filter, endDate: value },
      },
      () => {
        this.getMembershipRequestPagination();
      },
    );
  };

  handleCloseToash = () => {
    const { toastInformation } = this.state;
    this.setState({ toastInformation: { ...toastInformation, isOpen: false } });
  };

  handleTableChange = (pagination, filters, sorter) => {
    const { sort } = this.state;

    this.setState(
      {
        limit: pagination.pageSize,
        pagination: {
          ...this.state.pagination,
          current: pagination.current,
          pageSize: pagination.pageSize,
        },
        sort: {
          ...sort,
          sortField: sorter.field,
          sortOrder: sorter.order === 'ascend' ? 'asc' : 'desc',
        },
        page: pagination.current,
      },
      () => {
        this.getMembershipRequestPagination();
      },
    );
  };

  searchDebounce = () => {
    this.getMembershipRequestPagination();
  };

  handleClickCard = value => {
    const { onSetPrevStateValue, history } = this.props;
    const { filter } = this.state;
    const param = CommonHelper.encryptObject({
      sales_order_id: value,
    });

    onSetPrevStateValue({ ...filter, url: history.location.pathname });
    history.push(`/membership/purchase/details/${param}`);
  };

  handleSelectBranch = value => {
    const { filter } = this.state;

    this.setState(
      {
        filter: { ...filter, branchId: value || null },
        page: 1,
        pagination: { ...this.state.pagination, current: 1 },
      },
      () => {
        this.getMembershipRequestPagination();
      },
    );
  };

  handleChangeTransactionSource = value => {
    const { filter } = this.state;

    this.setState(
      {
        filter: { ...filter, transactionSource: value },
      },
      () => {
        this.getMembershipRequestPagination();
      },
    );
  };

  handleChangePaymentStatus = value => {
    const { filter } = this.state;

    this.setState(
      {
        filter: { ...filter, selectedStatusPayment: value },
      },
      () => {
        this.getMembershipRequestPagination();
      },
    );
  };

  handleButtonDownload = () => {
    const {
      filter: { search, startDate, endDate, branchId, merchantId, selectedStatusPayment, transactionSource },
    } = this.state;
    const {
      membershipPurchaseReportExport,
      membership: { fetchingExport },
    } = this.props;

    const params = {
      merchant_id: merchantId,
      start_date: startDate,
      end_date: endDate,
      search,
      branch_id: branchId,
      payment_status: selectedStatusPayment,
      transaction_source: transactionSource,
    };

    if (!fetchingExport) {
      membershipPurchaseReportExport(params);
    }
  };

  resetMerchantBranch() {
    const { filter } = this.state;
    const { usersReducer, masterData } = this.props;

    this.setState(
      {
        isLoading: true,
        filter: {
          ...filter,
          merchantId: usersReducer.activeMerchant,
          branchId: !_.isEmpty(masterData.branchList) ? masterData.branchList[0].branch_id : null,
        },
      },
      () => {
        this.getMembershipRequestPagination();
      },
    );
  }

  processMessage(messages, type) {
    const convertedMessage = CommonHelper.generateMessage(messages);

    const paramInformation = {
      isOpen: true,
      message: convertedMessage,
      snackbarType: type,
    };

    this.setState({
      toastInformation: paramInformation,
    });
  }

  renderRowAdditional = data => {
    const requestDate = CommonHelper.dateTimeParseNewFormat(
      data.requested_date,
      'DD MMM YYYY HH:mm',
    );

    return (
      <Grid container justify="space-between">
        <Grid item lg={3} md={2}>
          <div className="flex-column">
            <label className="text-12 title">Request Date</label>
            <label className="text-12">{requestDate}</label>
          </div>
        </Grid>
        {data.details.map((item, index) => {
          const currentCol = index > 1 ? 3 : 2;

          return (
            <React.Fragment key={index}>
              <Grid item>
                <div className="divider-hr-vertical" />
              </Grid>
              <Grid item lg={currentCol} md={currentCol}>
                <div className="flex-column">
                  <label className="text-12 title">{item.name}</label>
                  <label className="text-12 wrapping-container">{item.value}</label>
                </div>
              </Grid>
            </React.Fragment>
          );
        })}
      </Grid>
    );
  };

  renderColumns = () => {
    return [
      {
        title: 'Username',
        dataIndex: 'user_name',
        sorter: true,
        render: (text, row) => {
          return (
            <Grid
              container
              direction="column"
              onClick={() => this.handleClickCard(row.sales_order_id)}
              className="container-row-membership-purchase flex-wrap-unset"
            >
              <Grid item lg md className="container-info-header">
                <div className="flex-column">
                  <span className="flex-row mb-4">
                    <label className="text-14 title wrapping-container mr-8">{row.user_name}</label>
                    <LabelStatusPayment status={row.payment_status} />
                  </span>
                  <label className="text-10">Phone : {row.user_phone}</label>
                </div>
              </Grid>
              <Grid item lg md className="container-info-body">
                <Grid container justify="flex-start">
                  <Grid item lg={12} md={12}>
                    <Grid container justify="space-between">
                      <Grid item>
                        <div className="flex-column">
                          <label className="text-12 title">Invoice ID</label>
                          <label className="text-12">{row.sales_invoice_id}</label>
                        </div>
                      </Grid>
                      <Grid item>
                        <div className="divider-hr-vertical" />
                      </Grid>
                      <Grid item lg={2} md={2}>
                        <div className="flex-column">
                          <label className="text-12 title">Branch</label>
                          <label className="text-12">{row.branch_name || '-'}</label>
                        </div>
                      </Grid>
                      <Grid item lg={2} md={2}>
                        <div className="flex-column">
                          <label className="text-12 title">Create By</label>
                          <label className="text-12">{row.created_by}</label>
                        </div>
                      </Grid>
                      <Grid item lg={4} md={2}>
                        <div className="flex-column">
                          <label className="text-12 title  wrapping-container second line">
                            Membership
                          </label>
                          <label className="text-12 text-matisse">{row.product_name}</label>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          );
        },
      },
      {
        title: 'Price',
        align: 'center',
        width: '18%',
        render: (text, row) => {
          return (
            <div className="container-main-card-blue p-16 container-price-label flex-column">
              <span className="flex-row">
                {row.payment_method === paymentMethodOptionCode.fit_point ? (
                  <img src={Icons.fitPoints} alt="Fit Points" className="pr-8" />
                ) : (
                  <label className="text-14 text-bold currency pr-8">IDR</label>
                )}
                <label className="text-14 text-bold">
                  {CommonHelper.formatCurrency(row.total_paid)}
                </label>
              </span>
              <label className="text-12 text-semi-bold text-rolling-stone">
                {row.payment_method_title}
              </label>
            </div>
          );
        },
      },
    ];
  };

  renderFilter() {
    const {
      filter: { search, branchId, endDate, startDate, transactionSource, selectedStatusPayment },
    } = this.state;

    const {
      membership: { reportExport, fetchingExport },
    } = this.props;

    return (
      <Grid container direction="column" className="flex-wrap-unset">
        <Grid item>
          <Grid container justify="flex-start" className="container-filter">
            <Grid item lg={3} md={3} className="row-filter">
              <TextInput
                iconPrefix="ic-ffo-search"
                placeHolderText="Search user"
                onChange={this.handleChangeSearch}
                currentValue={search}
                size="md"
              />
            </Grid>
            <Grid item lg={2} md={2} className="row-filter">
              <div className="container-remove-margin">
                <SelectInputBranch
                  placeHolder="Select Branch"
                  onChange={this.handleSelectBranch}
                  currentValue={branchId}
                  filter={this.state.filter}
                  includeAllData
                />
              </div>
            </Grid>
            <Grid item lg md>
              <Grid container justify="flex-end" alignItems="center">
                <Grid item>
                  <label className="text-14 pr-8">Date</label>
                </Grid>
                <Grid item xl={3} lg={4} md={4}>
                  <PickerInputDate
                    customIcon="ic-ffo-date-pick"
                    dateFormat="dd/MM/yyyy"
                    defaultValue={startDate}
                    onChange={this.handleChangeStartDate}
                    toolbar={false}
                  />
                </Grid>
                <Grid item>
                  <label className="text-14 pr-8 pl-8"> - </label>
                </Grid>
                <Grid item xl={3} lg={4} md={4}>
                  <PickerInputDate
                    customIcon="ic-ffo-date-pick"
                    dateFormat="dd/MM/yyyy"
                    minDate={startDate}
                    defaultValue={endDate}
                    onChange={this.handleChangeEndDate}
                    toolbar={false}
                  />
                </Grid>
                <Grid item className="pl-8">
                  <GeneratorExcel
                    buttonSize="xl"
                    dataSetArray={reportExport}
                    fileName={`Membership_Purchase_Period_${this.state.filter.startDate}_${this.state.filter.endDate}`}
                    iconPrefix="ic-ffo-download"
                    isButtonIcon
                    isLoading={fetchingExport}
                    onClick={this.handleButtonDownload}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item className="mt-24 container-main-card p-24">
          <Grid container justify="space-between" className="section-filter" alignItems="center">
            <Grid item lg={8} md={8}>
              <Grid container justify="flex-start" className="container-filter">
                <Grid item lg={4} md={4} className="row-filter">
                  <div className="flex-column">
                    <SelectInputMain
                      options={optionTransactionSource}
                      size="middle"
                      currentValue={transactionSource}
                      onChange={this.handleChangeTransactionSource}
                      placeholder={'Select Source Type'}
                    />
                  </div>
                </Grid>
                <Grid item lg={4} md={4} className="row-filter">
                  <div className="flex-column">
                    <SelectInputMain
                      options={optionStatusPayment}
                      size="middle"
                      currentValue={selectedStatusPayment}
                      onChange={this.handleChangePaymentStatus}
                      placeholder={'Select Payment Status'}
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  render() {
    const { membership } = this.props;
    const { isLoading, pagination, toastInformation } = this.state;
    const ListCustom = membership.list;

    let renderElement = (
      <div className="mt-18">
        <SkeletonPagination />
      </div>
    );

    if (!isLoading) {
      renderElement = (
        <Grid container direction="column" className="flex-wrap-unset">
          <Grid item lg md sm className="section-page-header">
            {this.renderFilter()}
          </Grid>
          <Grid item lg md className="section-table-body">
            <Table
              columns={this.renderColumns()}
              rowKey={record => record.sales_order_id}
              dataSource={ListCustom}
              pagination={pagination}
              loading={membership.fetching}
              onChange={this.handleTableChange}
            />
          </Grid>
        </Grid>
      );
    }
    return (
      <div className="container-page-membership-Purchase scroll-container">
        <div className="container-page-scrolling-area include-tab">{renderElement}</div>
        <SnackBarSimple
          open={toastInformation.isOpen}
          durationHide={2000}
          message={toastInformation.message}
          onClickClose={this.handleCloseToash}
          snackbarType={toastInformation.snackbarType}
          anchor={optionToash}
        />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  membershipPurchasePagination: params => getMembershipPurchasePagination(dispatch, params),
  membershipPurchaseReportExport: params => getMembershipPurchaseReportExport(dispatch, params),
});

const mapStateToProps = ({ usersReducer, membership, masterData }) => ({
  usersReducer,
  membership,
  masterData,
});

MembershipPurchasePage.propTypes = {
  history: PropTypes.object,
  masterData: PropTypes.object,
  membership: PropTypes.object,
  membershipPurchasePagination: PropTypes.func,
  membershipPurchaseReportExport: PropTypes.func,
  onSetPrevStateValue: PropTypes.func,
  // pageStatus: PropTypes.number,
  usersReducer: PropTypes.object,
};

const shell = compose(connect(mapStateToProps, mapDispatchToProps));
const core = compose(AuthenticationAccessPages, PrevStateValue);

export default shell(core(MembershipPurchasePage));
