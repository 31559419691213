/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { Page, Text, View, Document, Image } from '@react-pdf/renderer';
import _ from 'lodash';
// Style
import StylesJs from './LabelShipping02Style';
// helper
import { GeneratorHelper } from '../../../../../helpers/Index';
// assets
import { Images, StyleJs as StyleJsGlobal } from '../../../../../assets/Index';

const LabelShipping02 = props => {
  const { data } = props;

  return (
    <Document>
      <Page size="A4" orientation="landscape">
        <View style={[StyleJsGlobal.gridRow, { width: 845 }]}>
          {data.map((item, index) => {
            item.airway_bill_number_img = item.airway_bill_number
              ? GeneratorHelper.textToBase64Barcode(item.airway_bill_number.toString())
              : '';
            item.external_order_id_img = item.external_order_id
              ? GeneratorHelper.textToBase64Barcode(item.external_order_id.toString())
              : '';
            let ShipMethod = '';
            const recipient = item.recipient_name
              ? `${item.recipient_name} - ${item.recipient_phone}`
              : '-';

            switch (item.shipping_method) {
              case 'jne':
                ShipMethod = Images.ShippingLogoJne;
                break;

              default:
                break;
            }

            return (
              <View
                key={index}
                style={[
                  StyleJsGlobal.gridItem4,
                  {
                    width: '100%',
                  },
                ]}
                wrap={false}
              >
                <View style={[StyleJsGlobal.gridColumn]}>
                  <View style={[StyleJsGlobal.gridItem12]}>
                    <View
                      style={[
                        StyleJsGlobal.gridRow,
                        StyleJsGlobal.alignItemCenter,
                        StyleJsGlobal.pv8,
                        StyleJsGlobal.mh10,
                      ]}
                    >
                      <View style={[StyleJsGlobal.gridItem6, { flex: 1 }]}>
                        <View style={[StyleJsGlobal.flexColumn, StyleJsGlobal.alignItemCenter]}>
                          <Image
                            src={Images.shippingLogoFitco}
                            style={[StylesJs.containerLogo, StyleJsGlobal.mb20]}
                          />
                          {!_.isEmpty(ShipMethod) ? (
                            <Image src={ShipMethod} style={[StylesJs.containerLogo]} />
                          ) : (
                            <Text
                              style={[
                                StyleJsGlobal.text12,
                                StyleJsGlobal.textCenter,
                                StyleJsGlobal.mr20,
                                StyleJsGlobal.ml12,
                              ]}
                            >{`${_.capitalize(item.shipping_method)} - ${
                              item.shipping_title
                            }`}</Text>
                          )}
                        </View>
                      </View>
                      <View style={[StyleJsGlobal.gridItem6, { flex: 1 }]}>
                        <View style={[StyleJsGlobal.flexColumn]}>
                          <Text style={[StyleJsGlobal.text10, StylesJs.title, StyleJsGlobal.mb8]}>
                            W-ORDER ID
                          </Text>
                          <Image
                            src={item.external_order_id_img}
                            style={[StylesJs.containerBarcode]}
                          />
                        </View>
                      </View>
                    </View>
                  </View>
                  <View style={[StyleJsGlobal.dividerHr]} />
                  <View style={[StyleJsGlobal.gridItem12, StyleJsGlobal.pv8]}>
                    <View style={[StyleJsGlobal.flexColumn, StyleJsGlobal.mh10]}>
                      <Text style={[StyleJsGlobal.text10, StylesJs.title, StyleJsGlobal.mb8]}>
                        FROM
                      </Text>
                      <Text
                        style={[
                          StyleJsGlobal.text10,
                          StyleJsGlobal.mb32,
                          StyleJsGlobal.textJustify,
                        ]}
                      >
                        {item.from_address || '-'}
                      </Text>
                      <Text style={[StyleJsGlobal.text10, StylesJs.title, StyleJsGlobal.mb8]}>
                        To
                      </Text>
                      <Text
                        style={[
                          StyleJsGlobal.text10,
                          StyleJsGlobal.textWeightBold,
                          StyleJsGlobal.mb8,
                          StyleJsGlobal.textJustify,
                        ]}
                      >
                        {recipient}
                      </Text>
                      <Text style={[StyleJsGlobal.text10, StyleJsGlobal.textJustify]}>
                        {item.delivery_address || '-'}
                      </Text>
                      {item.notes && (
                        <View style={[StyleJsGlobal.flexColumn]}>
                          <Text style={[StyleJsGlobal.text10, StylesJs.title, StyleJsGlobal.mb8]}>
                            Note
                          </Text>
                          <Text style={[StyleJsGlobal.text10, StyleJsGlobal.textJustify]}>
                            {item.notes || '-'}
                          </Text>
                        </View>
                      )}
                    </View>
                  </View>
                  <View style={[StyleJsGlobal.dividerHr]} />
                  <View style={[StyleJsGlobal.gridItem12, StyleJsGlobal.pv8]}>
                    <View style={[StyleJsGlobal.gridRow, StyleJsGlobal.mh10]}>
                      <View style={[StyleJsGlobal.gridItem4, { flex: 1 }]}>
                        <View style={[StyleJsGlobal.flexColumn]}>
                          <Text style={[StyleJsGlobal.text10, StylesJs.title, StyleJsGlobal.mb8]}>
                            W-ORDER ID
                          </Text>
                          <Text style={[StyleJsGlobal.text10, StyleJsGlobal.textCenter]}>
                            {item.external_order_id || '-'}
                          </Text>
                        </View>
                      </View>
                      <View style={[StyleJsGlobal.gridItem4, { flex: 1 }]}>
                        <View style={[StyleJsGlobal.flexColumn]}>
                          <Text style={[StyleJsGlobal.text10, StylesJs.title, StyleJsGlobal.mb8]}>
                            QTY
                          </Text>
                          <Text style={[StyleJsGlobal.text10, StyleJsGlobal.textCenter]}>
                            {item.item_quantity || '0'}
                          </Text>
                        </View>
                      </View>
                      <View style={[StyleJsGlobal.gridItem4, { flex: 1 }]}>
                        <View style={[StyleJsGlobal.flexColumn]}>
                          <Text style={[StyleJsGlobal.text10, StylesJs.title, StyleJsGlobal.mb8]}>
                            WEIGHT
                          </Text>
                          <Text style={[StyleJsGlobal.text10, StyleJsGlobal.textCenter]}>
                            {parseFloat(item.total_weight, 0).toFixed(2) || '0'} KG
                          </Text>
                        </View>
                      </View>
                    </View>
                  </View>
                  <View
                    style={[
                      StylesJs.containerDestination,
                      StyleJsGlobal.gridItem12,
                      StyleJsGlobal.pv8,
                      StyleJsGlobal.mh10,
                    ]}
                  >
                    <Text
                      style={[
                        StyleJsGlobal.text18,
                        StyleJsGlobal.textWeightBold,
                        StyleJsGlobal.mh8,
                        StyleJsGlobal.textCenter,
                      ]}
                    >
                      {item.destination_code || '-'}
                    </Text>
                  </View>
                  <View
                    style={[
                      StyleJsGlobal.gridItem12,
                      StyleJsGlobal.pv8,
                      StyleJsGlobal.mh10,
                      { flex: 1 },
                    ]}
                  >
                    <View style={[StyleJsGlobal.flexColumn, StyleJsGlobal.pv50]}>
                      <Text style={[StyleJsGlobal.text10, StylesJs.title, StyleJsGlobal.mb8]}>
                        AIRWAY BILL NUMBER
                      </Text>
                      <Image
                        src={item.airway_bill_number_img}
                        style={[StylesJs.containerBarcode]}
                      />
                    </View>
                  </View>
                </View>
              </View>
            );
          })}
        </View>
      </Page>
    </Document>
  );
};

LabelShipping02.propTypes = {
  data: PropTypes.array,
};

export default LabelShipping02;
