import React from 'react';
import PropTypes from 'prop-types';
import { Grid, IconButton } from '@material-ui/core';
import { connect } from 'react-redux';
import _ from 'lodash';
// style
import './ModalScanResultStyle.scss';
// component
import { GridRowTableOneColumn, ModalInformationPopUp } from '../../../../../components/Index';
import { healthStatusCode } from '../../../../../helpers/MasterDataHelper';

class ModalScanResult extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  componentWillUnmount() {}

  // #region Handler Function
  // #endregion

  // #region Render Function
  renderHealthStatusParagraph = healthStatus => {
    let paragraph = '';
    switch (_.lowerCase(healthStatus)) {
      case healthStatusCode.Healthy:
        paragraph = `Awesome! Your health passport is well, but make sure to always wear your mask and follow the health and safety protocol. #YourSafetyIsOurPriority`;
        break;
      case healthStatusCode.Risky:
        paragraph = `Be careful! Our system classifies your health passport as risky. Please take a COVID-19 Test soon and make sure you get vaccinated. Always wear your mask and follow the health and safety protocol. #YourSafetyIsOurPriority`;
        break;
      case healthStatusCode.HighRisk:
        paragraph = `Sorry, but our system classifies your health passport as high risk due to your last COVID-19 test was taken more than 14 days ago. Please take a COVID-19 Test as soon as possible and make sure you get vaccinated. Always wear your mask and follow the health and safety protocol. #YourSafetyIsOurPriority`;
        break;
      case healthStatusCode.Unknown:
      default:
        paragraph = `Sorry, but our system couldn't find anything related to your data, please do registration in FITCO to continue. Always wear your mask and follow the health and safety protocol. #YourSafetyIsOurPriority`;
        break;
    }

    return <p>{paragraph}</p>;
  };
  // #endregion

  render() {
    const { isOpen, onClose, data } = this.props;

    const renderElement = (
      <Grid
        container
        direction="column"
        className="flex-wrap-unset container-modal-eat-menu-qr-code"
      >
        <Grid item lg md className="section-header-modal">
          <Grid container justify="space-between">
            <Grid item lg={11} md={11}>
              <h5 className="wrapping-container second line">Scan Result</h5>
            </Grid>
            <Grid item lg={1} md={1}>
              <div className="container-button-close">
                <IconButton onClick={onClose}>
                  <i className="ic-ffo-close container-icon-prefix size-21" />
                </IconButton>
              </div>
            </Grid>
          </Grid>
        </Grid>
        {data && (
          <Grid item lg md className="text-center">
            <div className="result-container">
              <Grid container direction="column">
                <Grid item lg md>
                  <div className="health-status-container">
                    <div className="image-container">
                      <img
                        src={`https://cdn.fitco.id/cyberpunk/images/health-passport/${data.health_status}.svg`}
                        alt=""
                      />
                    </div>
                    <h3 className={`${data.health_status}`}>
                      {data.health_status === healthStatusCode.Unknown
                        ? 'NOT FOUND'
                        : _.upperCase(data.health_status)}
                    </h3>
                    {this.renderHealthStatusParagraph(data.health_status)}
                  </div>
                </Grid>
                <Grid item lg md>
                  <div className="divider-hr" />
                </Grid>
                <Grid item lg md className="user-detail-container">
                  <div className="label-field">Name</div>
                  <div className="value-field">{data.name ? data.name : '-'}</div>
                </Grid>
                <Grid item lg md className="user-detail-container mt-16">
                  <div className="label-field">
                    {data.identity_type ? _.upperCase(data.identity_type) : 'KTP'}
                  </div>
                  <div className="value-field">
                    {data.identity_number ? data.identity_number : '-'}
                  </div>
                </Grid>
                <Grid item lg md>
                  <div className="divider-hr" />
                </Grid>
                <Grid item lg md className="user-detail-container">
                  <div className="label-field text-bold">Latest COVID-19 Test</div>
                  {!data.last_checkup && (
                    <label className="no-test-record-label">
                      Sorry, but it seems like we did not find any of your test records.
                    </label>
                  )}
                  {data.last_checkup && (
                    <Grid
                      container
                      direction="column"
                      className="container-row-table flex-wrap-unset"
                    >
                      <GridRowTableOneColumn
                        customElementInitial={<label className="text-14">Result</label>}
                        customElementColumn={
                          <label
                            className={`text-14 ${
                              _.lowerCase(data.last_checkup.result) ? 'green-text' : 'red-text'
                            }`}
                          >
                            {_.upperCase(data.last_checkup.result)}
                          </label>
                        }
                        columnLeft={5}
                        columnRight={7}
                      />
                      <GridRowTableOneColumn
                        customElementInitial={<label className="text-14">Method</label>}
                        customElementColumn={
                          <label className="text-14">{data.last_checkup.method}</label>
                        }
                        columnLeft={5}
                        columnRight={7}
                      />
                      <GridRowTableOneColumn
                        customElementInitial={<label className="text-14">Date &amp; Time</label>}
                        customElementColumn={
                          <label className="text-14">{data.last_checkup.date}</label>
                        }
                        columnLeft={5}
                        columnRight={7}
                      />
                      <GridRowTableOneColumn
                        customElementInitial={<label className="text-14">Location</label>}
                        customElementColumn={
                          <label className="text-14">{data.last_checkup.location}</label>
                        }
                        columnLeft={5}
                        columnRight={7}
                      />
                      <GridRowTableOneColumn
                        customElementInitial={<label className="text-14">Result</label>}
                        customElementColumn={
                          <a className="text-14" href={data.last_checkup.document}>
                            View Result
                          </a>
                        }
                        columnLeft={5}
                        columnRight={7}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </div>
          </Grid>
        )}
      </Grid>
    );

    return (
      <ModalInformationPopUp isOpen={isOpen} onClose={onClose} customElementProps={renderElement} />
    );
  }
}

const mapDispatchToProps = () => ({});

const mapStateToProps = () => ({});

ModalScanResult.propTypes = {
  data: PropTypes.object,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalScanResult);
