import React from 'react';
import { Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

const SkeletonDetailsV03 = () => {
  return (
    <div className="row" style={{ padding: 40 }}>
      <Grid container direction="column">
        <Grid item lg md className="section-page-header">
          <Grid container justify="space-between">
            <Grid item lg={6} md={6}>
              <Skeleton
                variant="rect"
                width="100%"
                height={40}
                animation="wave"
                className="skeletonRounded"
              />
            </Grid>
            <Grid item lg={6} md={6}>
              <Grid container direction="row" justify="flex-end" spacing={2}>
                <Grid item lg={4} md={4} >
                  <Skeleton
                    variant="rect"
                    width="100%"
                    height={40}
                    animation="wave"
                    className="skeletonRounded"
                    />
                </Grid>
                <Grid item lg={4} md={4}>
                  <Skeleton
                    variant="rect"
                    width="100%"
                    height={40}
                    animation="wave"
                    className="skeletonRounded"
                    />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg md className="section-page-body">
          <div className="merchant-details-card">
            <Grid container spacing={3}>
              <Grid item lg md>
                <Grid container direction="column" className="container-row-table flex-wrap-unset">
                  <Grid item lg md className="title-table">
                    <Skeleton
                        variant="rect"
                        width="100%"
                        height={250}
                        animation="wave"
                        className="skeletonRounded"
                    />
                  </Grid>
                  <Grid item lg={4} md={4} className="title-table margin-space">
                    <Skeleton
                      variant="rect"
                      width="100%"
                      height={30}
                      animation="wave"
                      className="skeletonRounded"
                    />
                  </Grid>
                  <Grid item>
                    <Skeleton
                      variant="rect"
                      width="100%"
                      height={20}
                      animation="wave"
                      className="skeletonRounded"
                    />
                    <Skeleton
                      variant="rect"
                      width="100%"
                      height={20}
                      animation="wave"
                      className="skeletonRounded"
                    />
                    <Skeleton
                      variant="rect"
                      width="100%"
                      height={20}
                      animation="wave"
                      className="skeletonRounded"
                    />
                    <Skeleton
                      variant="rect"
                      width="100%"
                      height={20}
                      animation="wave"
                      className="skeletonRounded"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid item lg md className="section-page-footer" />
      </Grid>
    </div>
  );
};

export default SkeletonDetailsV03;
