/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';

const PrevNextStepperCount = WrappedComponent => {
  class NewComponent extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        currentIndex: 0,
      };
    }

    componentDidMount() {
      const { currentValue } = this.props;
      this.setState({ currentIndex: currentValue });
    }

    componentDidUpdate(prevProps, nextProps) {
      const { currentValue } = this.props;

      if (nextProps.currentIndex !== currentValue) {
        this.setUpdateCurrentCount(currentValue);
      }
    }

    setUpdateCurrentCount(value) {
      this.setState({ currentIndex: value });
    }

    handleNext = () => {
      const { onButtonClickNext, totalStepper } = this.props;
      const { currentIndex } = this.state;
      let result = currentIndex;

      if (totalStepper - 1 > currentIndex + 1 || totalStepper - 1 === currentIndex + 1) {
        result = currentIndex + 1;
      }

      onButtonClickNext(result);
    };

    handlePrev = () => {
      const { onButtonClickPrev } = this.props;
      const { currentIndex } = this.state;
      let result = currentIndex;

      if (currentIndex - 1 >= 0) {
        result = currentIndex - 1;
      }

      onButtonClickPrev(result);
    };

    render() {
      return (
        <WrappedComponent
          onNextClick={this.handleNext}
          onPrevClick={this.handlePrev}
          {...this.props}
        />
      );
    }
  }

  NewComponent.propTypes = {
    currentIndex: PropTypes.number,
    currentValue: PropTypes.number,
    history: PropTypes.object,
    onButtonClickNext: PropTypes.func,
    onButtonClickPrev: PropTypes.func,
    totalStepper: PropTypes.number,
  };

  return NewComponent;
};

export default PrevNextStepperCount;
