import React from 'react';
import { connect } from 'react-redux';
import { Grid, FormControl } from '@material-ui/core';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { compose } from 'redux';
// component
import {
  ButtonMain,
  PrevNextStepperCount,
  LabelInput,
  PickerInputDate,
  TextInputPrePost,
} from '../../../../../components/Index';
// // style
import './StepAddEatPriceStyle.scss';
// helper
import { CommonHelper, currencyCode } from '../../../../../helpers/Index';

const currentDate = CommonHelper.currentDate('YYYY-MM-DD');
const endDateValue = CommonHelper.getEndDateMonth(currentDate);

const initialValidation = {
  price: { isError: '', errorMessage: '' },
  specialPrice: { isError: '', errorMessage: '' },
  startDate: { isError: '', errorMessage: '' },
  endDate: { isError: '', errorMessage: '' },
  grabFoodPrice: { isError: '', errorMessage: '' },
  goFoodPrice: { isError: '', errorMessage: '' },
  shopeeFoodPrice: { isError: '', errorMessage: '' },
};

const initialForm = {
  specialPrice: '',
  price: '',
  startDate: currentDate,
  endDate: endDateValue,
  grabFoodPrice: '',
  goFoodPrice: '',
  shopeeFoodPrice: '',
};

class StepAddEatPrice extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      form: CommonHelper.objectCloning(initialForm),
      validation: CommonHelper.objectCloning(initialValidation),
    };
  }

  componentDidMount() {
    const { parentForm, parentValidation } = this.props;
    const { form, validation } = this.state;
    this.setState({
      form: { ...form, ...parentForm },
      validation: { ...validation, ...parentValidation },
    });
  }

  componentWillReceiveProps(nextProps) {
    const { validation } = this.state;

    const isValidationChange = _.isEqual(validation, nextProps.parentValidation);

    if (!isValidationChange) {
      const finalMergeValidation = { ...validation, ...nextProps.parentValidation };
      this.setState({
        validation: finalMergeValidation,
      });
    }
  }

  componentWillUnmount() {}

  handleTextChangePrice = value => {
    const { form, validation } = this.state;
    const message = CommonHelper.objectCloning(initialValidation.price);
    message.isError = !value ? 'error' : '';
    message.errorMessage = !value ? 'Price is empty' : '';

    this.setState({
      form: { ...form, price: value },
      validation: {
        ...validation,
        price: message,
      },
    });
  };

  handleTextChangeSpecialPrice = value => {
    const { form } = this.state;
    let modifyStartDate = form.startDate;
    let modifyEndDate = form.endDate;

    if (value) {
      modifyStartDate = !form.startDate ? initialForm.startDate : form.startDate;
      modifyEndDate = !form.endDate ? initialForm.endDate : form.endDate;
    }

    this.setState({
      form: { ...form, specialPrice: value, startDate: modifyStartDate, endDate: modifyEndDate },
    });
  };

  handlePickerChangeStartDate = value => {
    const { form } = this.state;
    const startDate = value || '';
    const endDate = CommonHelper.getEndDateMonth(value);

    this.setState({
      form: { ...form, startDate, endDate },
    });
  };

  handlePickerChangeEndDate = value => {
    const { form } = this.state;
    const endDate = value || '';

    this.setState({
      form: { ...form, endDate },
    });
  };

  handleTextChangeGrabFoodPrice = value => {
    const { form } = this.state;

    this.setState({
      form: { ...form, grabFoodPrice: value },
    });
  };

  handleTextChangeGoFoodPrice = value => {
    const { form } = this.state;

    this.setState({
      form: { ...form, goFoodPrice: value },
    });
  };

  handleTextChangeShopeeFoodPrice = value => {
    const { form } = this.state;

    this.setState({
      form: { ...form, shopeeFoodPrice: value },
    });
  };

  handlePrevClick = async () => {
    const { onPrevClick, onParameterUpdate } = this.props;
    const { form } = this.state;

    await onParameterUpdate(form);
    await onPrevClick();
  };

  handleNextClick = async () => {
    const { onNextClick, onParameterUpdate } = this.props;
    const { form } = this.state;

    await onParameterUpdate(form);
    await onNextClick();
  };

  render() {
    const {
      form: {
        price,
        specialPrice,
        startDate,
        endDate,
        grabFoodPrice,
        goFoodPrice,
        shopeeFoodPrice,
      },
      validation,
    } = this.state;
    const { onButtonClickCancel } = this.props;

    const ifSpecialPriceRequired = !!(specialPrice !== 0 && specialPrice);
    const ifGrabFoodPriceRequired = !!(grabFoodPrice !== 0 && grabFoodPrice);
    const ifGoFoodPriceRequired = !!(goFoodPrice !== 0 && goFoodPrice);
    const ifShopeeFoodPriceRequired = !!(shopeeFoodPrice !== 0 && shopeeFoodPrice);

    return (
      <Grid container className="container-step-eat-price">
        <Grid item lg={12} md={12} className="mt-16">
          <Grid container spacing={2}>
            <Grid item lg={6} md={6}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <LabelInput labelText="Price" isRequired={ifSpecialPriceRequired} />
                <TextInputPrePost
                  placeHolderText="Enter Eat Price"
                  onChange={this.handleTextChangePrice}
                  currentValue={price}
                  customElementsBefore={currencyCode.idr}
                  validateStatus={validation.price.isError}
                  errorMessage={validation.price.errorMessage}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={12} md={12} className="mt-16">
          <Grid container spacing={2}>
            <Grid item lg={6} md={6}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <LabelInput labelText="Special Price" isRequired={ifSpecialPriceRequired} />
                <TextInputPrePost
                  placeHolderText="Enter Eat Special Price"
                  onChange={this.handleTextChangeSpecialPrice}
                  currentValue={specialPrice}
                  customElementsBefore={currencyCode.idr}
                  validateStatus={validation.specialPrice.isError}
                  errorMessage={validation.specialPrice.errorMessage}
                />
              </FormControl>
            </Grid>
            <Grid item lg={6} md={6}>
              <Grid container justify="flex-start" spacing={2}>
                <Grid item xl={3} lg={5} md={5}>
                  <FormControl component="fieldset" fullWidth margin="normal">
                    <LabelInput labelText="Start Date" isRequired={ifSpecialPriceRequired} />
                    <PickerInputDate
                      customIcon="ic-ffo-date-pick"
                      dateFormat="dd/MM/yyyy"
                      minDate={null}
                      defaultValue={startDate}
                      onChange={this.handlePickerChangeStartDate}
                      toolbar={false}
                      disabled={!specialPrice}
                    />
                  </FormControl>
                </Grid>
                <Grid item className="flex-column justify-start ">
                  <label className="text-14 text-rolling-stone mt-54">-</label>
                </Grid>
                <Grid item xl={3} lg={5} md={5}>
                  <FormControl component="fieldset" fullWidth margin="normal">
                    <LabelInput labelText="End Date" isRequired={ifSpecialPriceRequired} />
                    <PickerInputDate
                      customIcon="ic-ffo-date-pick"
                      dateFormat="dd/MM/yyyy"
                      minDate={startDate}
                      defaultValue={endDate}
                      onChange={this.handlePickerChangeEndDate}
                      toolbar={false}
                      disabled={!specialPrice}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={12} md={12} className="mt-16">
          <Grid container spacing={2}>
            <Grid item lg={6} md={6}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <LabelInput labelText="GrabFood Price" isRequired={ifGrabFoodPriceRequired} />
                <TextInputPrePost
                  placeHolderText="Enter GrabFood Price"
                  onChange={this.handleTextChangeGrabFoodPrice}
                  currentValue={grabFoodPrice}
                  customElementsBefore={currencyCode.idr}
                  validateStatus={validation.grabFoodPrice.isError}
                  errorMessage={validation.grabFoodPrice.errorMessage}
                />
              </FormControl>
            </Grid>
            <Grid item lg={6} md={6}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <LabelInput labelText="GoFood Price" isRequired={ifGoFoodPriceRequired} />
                <TextInputPrePost
                  placeHolderText="Enter GoFood Price"
                  onChange={this.handleTextChangeGoFoodPrice}
                  currentValue={goFoodPrice}
                  customElementsBefore={currencyCode.idr}
                  validateStatus={validation.goFoodPrice.isError}
                  errorMessage={validation.goFoodPrice.errorMessage}
                />
              </FormControl>
            </Grid>
            <Grid item lg={6} md={6}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <LabelInput labelText="ShopeeFood Price" isRequired={ifShopeeFoodPriceRequired} />
                <TextInputPrePost
                  placeHolderText="Enter ShopeeFood Price"
                  onChange={this.handleTextChangeShopeeFoodPrice}
                  currentValue={shopeeFoodPrice}
                  customElementsBefore={currencyCode.idr}
                  validateStatus={validation.shopeeFoodPrice.isError}
                  errorMessage={validation.shopeeFoodPrice.errorMessage}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={12} md={12} className="mt-18">
          <Grid container justify="flex-end">
            <Grid item lg={5} md={6}>
              <Grid container justify="flex-end">
                <Grid item lg={4} md={4} className="pl-8">
                  <ButtonMain
                    type="negative"
                    size="xl"
                    labelText="Cancel"
                    onClick={onButtonClickCancel}
                  />
                </Grid>
                <Grid item lg={4} md={4} className="pl-8">
                  <ButtonMain
                    type="ghost"
                    size="xl"
                    labelText="Prev"
                    onClick={this.handlePrevClick}
                  />
                </Grid>
                <Grid item lg={4} md={4} className="pl-8">
                  <ButtonMain
                    type="primary"
                    size="xl"
                    labelText="Next"
                    onClick={this.handleNextClick}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

const mapDispatchToProps = () => ({});

const mapStateToProps = () => ({});

StepAddEatPrice.propTypes = {
  onButtonClickCancel: PropTypes.func,
  onNextClick: PropTypes.func,
  onParameterUpdate: PropTypes.func,
  onPrevClick: PropTypes.func,
  parentForm: PropTypes.object,
  parentValidation: PropTypes.object,
};

const shell = compose(connect(mapStateToProps, mapDispatchToProps));

export default shell(PrevNextStepperCount(StepAddEatPrice));
