/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
// Style
import './ModalSelectVariantStyle.scss';
// Component
import {
  ModalAlertAction,
  GridRowTableFourColumn,
  SelectInputMain,
  TextInputNumber,
} from '../../../../../components/Index';
// helper
import { CommonHelper } from '../../../../../helpers/Index';

const ModalSelectVariant = props => {
  const { isOpen, data, onCloseModal, onHandleAddToCart } = props;

  const [selectedVariant, setSelectedVariant] = useState({});

  useEffect(() => {
    setSelectedVariant({ ...data.variants[0], qty: 1 });
  }, []);

  const modalDetailDataCreate = {
    title: 'Select Variant',
  };

  const variantOption = data.variants.map(item => {
    return { value: item.product_id, name: item.name };
  });

  const handleChangeVariant = value => {
    const findMatchData = data.variants.find(item => item.product_id === value);
    setSelectedVariant({ ...findMatchData, qty: 1 });
  };

  const handleAddQuantity = value => {
    let modifyConvertedValue = parseInt(value, 10);
    if (modifyConvertedValue <= 1) {
      modifyConvertedValue = 1;
    }

    setSelectedVariant({ ...selectedVariant, qty: modifyConvertedValue || 1 });
  };

  const handleButtonAddCart = () => {
    onHandleAddToCart(selectedVariant);
  };

  const subtotal = parseInt(selectedVariant.price, 10) * selectedVariant.qty;

  const elementRenderCustom = (
    <div className="container-modal-select-variant flex-column">
      <div className="flex-row mb-24">
        <img src={data.images} className="custom-image mr-16" alt="item_image" />
        <div className="flex-column">
          <label className="text-16 text-bold main">{data.name}</label>
          <label className="text-12 text-semi-bold main mt-4">{`${data.variants.length} Product Variants`}</label>
        </div>
      </div>
      <GridRowTableFourColumn
        customElementColumnFirst={<label className="text-14 text-semi-bold">Select Variant</label>}
        customElementColumnSecond={
          <div className="text-center">
            <label className="text-14 text-semi-bold">Price</label>
          </div>
        }
        customElementColumnThird={
          <div className="text-center">
            <label className="text-14 text-semi-bold">Quantity</label>
          </div>
        }
        customElementColumnFourth={
          <div className="text-right">
            <label className="text-14 text-semi-bold">Sub Total</label>
          </div>
        }
        columnFirst={6}
        columnSecond={2}
        columnThird={1}
        columnFourth={3}
      />
      <div className="scroll-container mt-16">
        <Grid container direction="column" className="flex-wrap-unset">
          <GridRowTableFourColumn
            customElementColumnFirst={
              <SelectInputMain
                options={variantOption}
                currentValue={selectedVariant.product_id}
                onChange={handleChangeVariant}
              />
            }
            customElementColumnSecond={
              <div className="text-center">
                <label className="text-14 text-semi-bold">{`IDR ${CommonHelper.formatCurrency(
                  selectedVariant.price,
                )}`}</label>
              </div>
            }
            customElementColumnThird={
              <div className="text-center">
                <TextInputNumber
                  placeHolderText={'Qty'}
                  currentValue={selectedVariant.qty}
                  onChange={handleAddQuantity}
                />
              </div>
            }
            customElementColumnFourth={
              <div className="text-right">
                <label className="text-14 text-semi-bold">{`IDR ${CommonHelper.formatCurrency(
                  subtotal,
                )}`}</label>
              </div>
            }
            columnFirst={6}
            columnSecond={2}
            columnThird={1}
            columnFourth={3}
          />
        </Grid>
      </div>
    </div>
  );

  return (
    <ModalAlertAction
      onButtonSubmit={handleButtonAddCart}
      onCloseModal={onCloseModal}
      modalDetail={modalDetailDataCreate}
      buttonSubmitText="Add To Cart"
      openModal={isOpen}
      customElementProps={elementRenderCustom}
      size={'large'}
    />
  );
};

ModalSelectVariant.propTypes = {
  data: PropTypes.object,
  isOpen: PropTypes.bool,
  onCloseModal: PropTypes.func,
  onHandleAddToCart: PropTypes.func,
};

export default ModalSelectVariant;
