/* eslint-disable react/no-access-state-in-setstate */
import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import clsx from 'clsx';
import _ from 'lodash';
import * as Actions from '../../../../redux/actions/ReportAction';
import './ConductPTPageStyle.scss';
// component
import {
  SnackBarSimple,
  PickerInputDate,
  LabelStatusMain,
  TextInput,
  ButtonIconMain,
  GeneratorExcel,
  ChartAreaGraphic,
  AuthenticationAccessPages,
  PrevStateValue,
  SelectInputBranch,
} from '../../../../components/Index';
import {
  SkeletonChartConduct,
  SkeletonConduct,
  SkeletonSessions,
  CardSessionConduct,
  CardConductPTTop,
} from '../components/Index';
// Helper
import { CommonHelper } from '../../../../helpers/Index';
// Action
import {
  getChartConductPT,
  getInfoConducted,
  getTotalAppointmentConductPT,
  getConductReportPagination,
  getConductReportExport,
  getTotalAppointmentConductPTbyMerchantBranch,
} from '../../../../services/api/ReportApi';
import { getBranchList } from '../../../../services/api/MasterDataApi';
// asset
import themes from '../../../../themes/Index';
import { compose } from 'redux';

const optionToash = {
  vertical: 'top',
  horizontal: 'right',
};

const initialSort = {
  sortField: 'trainer_name',
  sortOrder: '',
};

const currentDate = CommonHelper.currentDate('YYYY-MM-DD');
const startDateValue = CommonHelper.getStartDateMonth(currentDate);
const endDateValue = CommonHelper.getEndDateMonth(currentDate);

const initialFilter = {
  merchantId: null,
  branchId: null,
  search: '',
  startDate: startDateValue,
  endDate: endDateValue,
};

class ConductPTPage extends React.Component {
  constructor(props) {
    super(props);
    const {
      usersReducer: { prevStateValue },
    } = props;

    const updateExitingFilter = { ...CommonHelper.objectCloning(initialFilter), ...prevStateValue };

    this.state = {
      pagination: {
        showSizeChanger: true,
        showTotal: (total, range) => `Showing ${range[0]}-${range[1]} from ${total}`,
        pageSizeOptions: ['10', '20', '30', '40'],
      },
      page: 1,
      limit: 10,
      sort: CommonHelper.objectCloning(initialSort),
      toastInformation: {
        isOpen: false,
        message: '',
        snackbarType: 'warning',
      },
      filter: updateExitingFilter,
    };
    this.searchDebounce = _.debounce(this.searchDebounce, 400);
  }

  componentDidMount() {
    const {
      onResetPrevStateValue,
      history: { location },
    } = this.props;

    onResetPrevStateValue({ url: location.pathname });
  }

  componentWillReceiveProps(nextProps) {
    const { filter } = this.state;
    const { usersReducer } = this.props;

    const isEqualActiveMerchantId = _.isEqual(
      usersReducer.activeMerchant,
      nextProps.usersReducer.activeMerchant,
    );

    if (!isEqualActiveMerchantId) {
      this.setState({
        filter: { ...filter, merchantId: nextProps.usersReducer.activeMerchant },
      });
    }
  }

  componentDidUpdate(prevProps, nextProps) {
    const {
      usersReducer,
      masterData,
      reportData: { fetching },
    } = this.props;

    const currentMerchant = usersReducer.activeMerchant;

    const ifMerchantChange = currentMerchant !== nextProps.filter.merchantId;
    const ifBranchEqual = _.isEqual(prevProps.masterData.branchList, masterData.branchList);

    if ((ifMerchantChange || !ifBranchEqual) && !fetching) {
      this.resetMerchantBranch(ifMerchantChange);
    }
  }

  componentWillUnmount() {
    this.props.setDefaultReportExport();
  }

  getAllData = () => {
    const {
      getChart,
      getConducted,
      getTotalAppointment,
      getTotalAppointmentMerchantBranch,
    } = this.props;
    const {
      filter: { startDate, endDate, branchId, merchantId, search },
      page,
      sort: { sortField, sortOrder },
    } = this.state;

    const params = {
      start_date: startDate,
      end_date: endDate,
      merchant_id: merchantId,
      branch_id: branchId,
    };

    getChart(params);
    getConducted(params);
    getTotalAppointment(params);

    params.page = page;
    params.sort = sortField;
    params.sortOrder = sortOrder;
    params.search = search;

    getTotalAppointmentMerchantBranch(params);

    this.getConductReportPagination();
  };

  getConductReportPagination() {
    const { conductReportPagination, getTotalAppointmentMerchantBranch } = this.props;
    const {
      filter: { startDate, endDate, branchId, merchantId, search },
      page,
      sort: { sortField, sortOrder },
      limit,
      pagination,
    } = this.state;

    const params = {
      start_date: startDate,
      end_date: endDate,
      merchant_id: merchantId,
      branch_id: branchId,
      page,
      sort: sortField,
      sortOrder,
      search,
      limit,
    };

    getTotalAppointmentMerchantBranch(params);
    conductReportPagination(params).then(response => {
      this.setState({
        pagination: { ...pagination, pageSize: limit, total: response.total },
      });
    });
  }

  handleChangeStartDate = value => {
    const { filter } = this.state;
    const { getAllData } = this;
    const endDate = CommonHelper.getEndDateMonth(value);

    this.setState(
      {
        filter: { ...filter, startDate: value, endDate },
        page: 1,
        pagination: { ...this.state.pagination, current: 1 },
      },
      () => {
        getAllData();
      },
    );
  };

  handleChangeEndDate = value => {
    const { filter } = this.state;
    const { getAllData } = this;

    this.setState(
      {
        filter: { ...filter, endDate: value },
        page: 1,
        pagination: { ...this.state.pagination, current: 1 },
      },
      () => {
        getAllData();
      },
    );
  };

  handleButtonCreate = () => {
    const { history } = this.props;
    history.push('/master-data/product-category/add');
  };

  handleCloseToash = () => {
    const { toastInformation } = this.state;
    this.setState({ toastInformation: { ...toastInformation, isOpen: false } });
  };

  handleChangeSearch = value => {
    const { filter } = this.state;

    this.setState(
      {
        filter: { ...filter, search: value },
        page: 1,
        pagination: { ...this.state.pagination, current: 1 },
      },
      () => {
        this.searchDebounce();
      },
    );
  };

  handleTableChange = (pagination, filters, sorter) => {
    const { sort } = this.state;

    this.setState(
      {
        limit: pagination.pageSize,
        pagination: {
          ...this.state.pagination,
          current: pagination.current,
          pageSize: pagination.pageSize,
        },
        sort: {
          ...sort,
          sortField: sorter.field || initialSort.sortField,
          sortOrder: sorter.order === 'ascend' || !sorter.order ? 'asc' : 'desc',
        },
        page: pagination.current,
      },
      () => {
        this.getConductReportPagination();
      },
    );
  };

  searchDebounce = () => {
    this.getConductReportPagination();
  };

  handleSelectChangeBranch = value => {
    const { filter } = this.state;

    this.setState(
      {
        filter: { ...filter, branchId: value },
        page: 1,
        pagination: { ...this.state.pagination, current: 1 },
      },
      () => {
        this.getAllData();
      },
    );
  };

  handleButtonRefresh = () => {
    const { filter, sort } = this.state;
    const { masterData } = this.props;
    const resetBranch = masterData.branchList[0].branch_id;

    this.setState(
      {
        filter: {
          ...filter,
          branchId: resetBranch,
          startDate: initialFilter.startDate,
          endDate: initialFilter.endDate,
        },
        sort: { ...sort, sortField: initialSort.sortField, sortOrder: initialSort.sortOrder },
        pagination: { ...this.state.pagination, current: 1 },
        page: 1,
      },
      () => {
        this.getAllData();
      },
    );
  };

  handleClickCard = value => {
    const { history, onSetPrevStateValue } = this.props;
    const { filter } = this.state;
    const param = CommonHelper.encryptObject({
      trainerId: value,
      startDate: filter.startDate,
      endDate: filter.endDate,
      branchId: filter.branchId,
    });

    onSetPrevStateValue({ ...filter });

    history.push(`/reports/conduct-pt/details/${param}`);
  };

  handleButtonDownload = () => {
    const {
      filter: { merchantId, startDate, endDate, branchId },
    } = this.state;
    const {
      getConductExport,
      reportData: { fetchingExport },
    } = this.props;

    const params = {
      start_date: startDate,
      end_date: endDate,
      merchant_id: merchantId,
      branch_id: branchId,
    };

    if (!fetchingExport) {
      getConductExport(params);
    }
  };

  resetMerchantBranch(resetBranch = false) {
    const { filter } = this.state;
    const { usersReducer, masterData } = this.props;

    const ifBranchListExist = !_.isEmpty(masterData.branchList)
      ? masterData.branchList[0].branch_id
      : null;

    this.setState(
      {
        filter: {
          ...filter,
          merchantId: usersReducer.activeMerchant,
          branchId: resetBranch ? null : ifBranchListExist,
        },
      },
      () => {
        this.getAllData();
      },
    );
  }

  processMessage(messages, type) {
    const convertedMessage = CommonHelper.generateMessage(messages);

    const paramInformation = {
      isOpen: true,
      message: convertedMessage,
      snackbarType: type,
    };

    this.setState({
      toastInformation: paramInformation,
    });
  }

  renderFilter = () => {
    const { filter } = this.state;
    const branchFilter = {
      merchantId: filter.merchantId,
    };

    return (
      <Grid container justify="flex-start" className="align-content-center">
        <Grid item lg={2} md={2}>
          <label className="text-14">Showing Statistic</label>
        </Grid>
        <Grid item lg={2} md={2} style={{ marginLeft: -30 }}>
          <PickerInputDate
            customIcon="ic-ffo-date-pick"
            dateFormat="dd/MM/yyyy"
            defaultValue={this.state.filter.startDate}
            onChange={this.handleChangeStartDate}
            toolbar={false}
          />
        </Grid>
        <label className="text-14 pr-8 pl-8"> - </label>
        <Grid item lg={2} md={2}>
          <PickerInputDate
            customIcon="ic-ffo-date-pick"
            dateFormat="dd/MM/yyyy"
            minDate={this.state.filter.startDate}
            defaultValue={this.state.filter.endDate}
            onChange={this.handleChangeEndDate}
            toolbar={false}
          />
        </Grid>
        <Grid item lg md>
          <Grid container justify="flex-end" alignItems="center">
            <Grid item lg={6} md={6}>
              <SelectInputBranch
                placeHolder="Select Branch"
                onChange={this.handleSelectChangeBranch}
                currentValue={this.state.filter.branchId}
                filter={branchFilter}
                includeAllData
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  renderCard = () => {
    const { reportData } = this.props;
    const { filter } = this.state;
    const arryLine = [
      {
        id: 'cancel',
        gradientColor: themes.palette.cinnabar,
        lineColor: themes.palette.cinnabar,
      },
      {
        id: 'appointment',
        gradientColor: themes.palette.seagull,
        lineColor: themes.palette.seagull,
      },
      {
        id: 'conduct',
        gradientColor: themes.palette.downy,
        lineColor: themes.palette.downy,
      },
    ];

    return (
      <Grid container justify="space-around" className="conduct-pt-details-card">
        <Grid item lg={7} md={7} className="container-chat">
          <Grid container direction="column" className="flex-wrap-unset">
            <Grid item lg md className="pl-24">
              <label className="text-12 text-bold">Conduct PT Statistic</label>
            </Grid>
            <Grid item lg md>
              <Grid container spacing={2}>
                <Grid item lg={9} md={9}>
                  {reportData.fetchingChart ? (
                    <SkeletonChartConduct />
                  ) : (
                    <ChartAreaGraphic
                      dataArray={reportData.dataChart}
                      dataArrayLine={arryLine}
                      height={300}
                      tooltipCustom="tooltip-conduct"
                    />
                  )}
                </Grid>
                <Grid item lg={3} md={3}>
                  <Grid container direction="column">
                    {reportData.fetchingConduct ? (
                      <SkeletonConduct />
                    ) : (
                      <Grid item lg={12} className="conduct-pt-statistic-days-card">
                        <label className="text-10 text-center text-opacity-50">
                          Conduct - PT/days
                        </label>
                        <Grid container spacing={2} direction={'row'} className="pt-16 pl-8">
                          <Grid item lg={4} md={4} className="session-day-item">
                            <label className="text-14 text-bold">
                              {reportData.conductedDetails.average}
                            </label>
                            <label className="text-10">Average</label>
                          </Grid>
                          <Grid item lg={4} md={4} className="session-day-item">
                            <label className="text-14 text-bold">
                              {reportData.conductedDetails.max}
                            </label>
                            <label className="text-10">Max</label>
                          </Grid>
                          <Grid item lg={4} md={4} className="session-day-item">
                            <label className="text-14 text-bold">
                              {reportData.conductedDetails.min}
                            </label>
                            <label className="text-10">Min</label>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                    {reportData.fetchingSessions ? (
                      <SkeletonSessions />
                    ) : (
                      <Grid
                        container
                        direction={'column'}
                        spacing={3}
                        className="text-center pt-24"
                      >
                        <Grid item className="session-day-item">
                          <label className="text-14 text-bold">
                            {reportData.totalAppintments.total_booking} Sessions
                          </label>
                          <label className="text-10 pt-4">Total Appointment</label>
                        </Grid>
                        <Grid item className="session-day-item">
                          <label className="text-14 text-bold">
                            {reportData.totalAppintments.total_conduct} Sessions
                          </label>
                          <label className="text-10 pt-4">Total Conduct</label>
                        </Grid>
                        <Grid item className="session-day-item">
                          <label className="text-14 text-bold">
                            {reportData.totalAppintments.total_cancel} Sessions
                          </label>
                          <label className="text-10 pt-4">Total Canceled</label>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <div className="divider-hr-vertical" />
        </Grid>
        <Grid item lg={4} md={4} className="container-chat pr-16">
          <CardConductPTTop parentFilter={filter} />
        </Grid>
      </Grid>
    );
  };

  renderFilterTable() {
    const {
      filter: { startDate, endDate, search },
    } = this.state;
    const {
      reportData: { reportExport, fetchingExport },
    } = this.props;

    return (
      <Grid container justify="space-between">
        <Grid item lg={3} md={4}>
          <TextInput
            iconPrefix="ic-ffo-search"
            placeHolderText="Search"
            currentValue={search}
            onChange={this.handleChangeSearch}
            size="md"
          />
        </Grid>
        <Grid item lg={3} md={3}>
          <Grid container justify="flex-end">
            <Grid item>
              <ButtonIconMain
                icon="ic-ffo-refresh"
                type="ghost"
                size="xl"
                onClick={this.handleButtonRefresh}
              />
            </Grid>
            <Grid item>
              <GeneratorExcel
                buttonSize="xl"
                dataSetArray={reportExport}
                fileName={`Conduct_Report_Period_${startDate}_${endDate}`}
                iconPrefix="ic-ffo-download"
                isButtonIcon
                isLoading={fetchingExport}
                onClick={this.handleButtonDownload}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  renderStatus = value => {
    let renderElement = null;

    if (value) {
      renderElement = <LabelStatusMain value="Projected Value" type="projected" />;
    }

    return <div className="container-status">{renderElement}</div>;
  };

  renderColumns = () => {
    return [
      {
        title: 'Personal Trainer',
        dataIndex: 'trainer_name',
        sorter: true,
        render: (text, row) => {
          const commission = CommonHelper.formatCurrency(row.commission);
          // const penalty = row.penalty ? `IDR ${CommonHelper.formatCurrency(row.penalty)}` : 'None'; will un command in next development

          return (
            <Grid
              container
              onClick={() => {
                this.handleClickCard(row.trainer_id);
              }}
              alignItems="center"
              justify="space-between"
              className="container-table-column-conduct left"
            >
              <Grid item lg={7} md={6}>
                <Grid container direction="column" className="flex-wrap-unset">
                  <Grid item lg={12} md={12} className="container-main">
                    <span className="flex-row">
                      <label className="text-14 title-main">{row.trainer_name || '-'}</label>
                      {this.renderStatus(row.projected_value)}
                    </span>
                  </Grid>
                  <Grid item lg={10} md={10}>
                    <Grid container justify="space-between">
                      <Grid item lg={3} md={3}>
                        <div className="flex-column">
                          <label className="text-12 title">Commission</label>
                          <label
                            className={`text-12 focus ${clsx({
                              'minus-value': CommonHelper.checkNegativeValue(commission),
                            })}`}
                          >
                            IDR {commission}
                          </label>
                        </div>
                      </Grid>
                      {/* <Grid item> will un command in next development
                        <div className="divider-hr-vertical" />
                      </Grid>
                      <Grid item lg={3} md={3}>
                        <div className="flex-column">
                          <label className="text-12 title">Penalty</label>
                          <label className="text-12">{penalty}</label>
                        </div>
                      </Grid> */}
                      <Grid item>
                        <div className="divider-hr-vertical" />
                      </Grid>
                      <Grid item lg={3} md={3}>
                        <div className="flex-column">
                          <label className="text-12 title">Total Participant</label>
                          <label className="text-12">{row.total_participant}</label>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item lg={4} md={5}>
                {/* <Grid container justify="space-around"> will un command in next development */}
                <Grid container justify="flex-end">
                  <Grid item lg={3} md={3}>
                    <div className="flex-column revers">
                      <label className="text-14">{row.conduct}</label>
                      <label className="text-12">Conduct</label>
                    </div>
                  </Grid>
                  {/* <Grid item> will un command in next development
                    <div className="divider-hr-vertical" />
                  </Grid>
                  <Grid item lg={3} md={3}>
                    <div className="flex-column revers">
                      <label className="text-14">{row.target}</label>
                      <label className="text-12">Target</label>
                      <label className="text-10">(Monthly)</label>
                    </div>
                  </Grid> */}
                  {/* <Grid item>
                    <div className="divider-hr-vertical" />
                  </Grid>
                  <Grid item lg={3} md={3}>
                    <div className="flex-column revers">
                      <label className="text-14 deviation">{row.deviation}</label>
                      <label className="text-12">Deviation</label>
                    </div>
                  </Grid> */}
                </Grid>
              </Grid>
            </Grid>
          );
        },
      },
      {
        title: 'Conduct Value',
        dataIndex: 'conduct_value',
        sorter: true,
        width: '18%',
        align: 'center',
        render: (text, row) => {
          const currentValue = CommonHelper.formatCurrency(row.conduct_value);

          return (
            <Grid container className="container-table-column-conduct right">
              <Grid item lg md>
                <div className="flex-column">
                  <label className="text-14 title">IDR {currentValue}</label>
                  <label className="text-12">Conduct Value</label>
                </div>
              </Grid>
            </Grid>
          );
        },
      },
    ];
  };

  render() {
    const { toastInformation, pagination } = this.state;
    const {
      reportData: { reportPagination, fetching, totalAppointmentBranch },
    } = this.props;

    return (
      <div>
        <Helmet title="FITCO | Reports - PT Conduct" />
        <div className="container-page-conduct-pt scroll-container">
          <div className="container-page-scrolling-area">
            <Grid container direction="column" className="flex-wrap-unset">
              <Grid item lg md className="section-page-header">
                {this.renderFilter()}
              </Grid>
              <Grid item lg md className="section-page-body">
                <Grid container direction="column" className="flex-wrap-unset">
                  <Grid item lg md className="mb-15">
                    {this.renderCard()}
                  </Grid>
                  <Grid item lg md className="mv-15">
                    {this.renderFilterTable()}
                  </Grid>
                  <Grid item lg md className="mv-15">
                    <Grid container alignItems="center" justify="space-between">
                      <Grid item lg={4} md={4} style={{ paddingRight: 10 }}>
                        <CardSessionConduct
                          backgroundColor="appointment"
                          bodyText={`${totalAppointmentBranch.total_booking || 0} Session`}
                          iconPrefix="ic-ffo-appointment-date-list"
                          labelText={'Total Appointment'}
                          status={totalAppointmentBranch.projected_value}
                        />
                      </Grid>
                      <Grid item lg={4} md={4} style={{ paddingLeft: 10, paddingRight: 10 }}>
                        <CardSessionConduct
                          backgroundColor="conducted"
                          bodyText={`${totalAppointmentBranch.total_conduct || 0} Session`}
                          iconPrefix="ic-ffo-approve-date"
                          labelText={'Total Conducted'}
                          status={totalAppointmentBranch.projected_value}
                        />
                      </Grid>
                      <Grid item lg={4} md={4} style={{ paddingLeft: 10 }}>
                        <CardSessionConduct
                          backgroundColor="cancel"
                          bodyText={`${totalAppointmentBranch.total_cancel || 0} Session`}
                          iconPrefix="ic-ffo-cancel-date"
                          labelText={'Total Cancel'}
                          status={totalAppointmentBranch.projected_value}
                        />
                      </Grid>
                      <Grid item lg={3} md={3} />
                    </Grid>
                  </Grid>
                  <Grid item lg md className="mv-15">
                    <Table
                      columns={this.renderColumns()}
                      rowKey={record => record.trainer_id}
                      dataSource={reportPagination}
                      pagination={pagination}
                      loading={fetching}
                      // scroll={{ y: adjustTableHeight }}
                      onChange={this.handleTableChange}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item lg md className="section-page-footer" />
            </Grid>
          </div>
        </div>
        <SnackBarSimple
          open={toastInformation.isOpen}
          durationHide={2000}
          message={toastInformation.message}
          onClickClose={this.handleCloseToash}
          snackbarType={toastInformation.snackbarType}
          anchor={optionToash}
        />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getBranchs: params => getBranchList(dispatch, params),
  getChart: params => getChartConductPT(dispatch, params),
  getConducted: params => getInfoConducted(dispatch, params),
  getTotalAppointment: params => getTotalAppointmentConductPT(dispatch, params),
  conductReportPagination: params => getConductReportPagination(dispatch, params),
  getConductExport: params => getConductReportExport(dispatch, params),
  getTotalAppointmentMerchantBranch: params =>
    getTotalAppointmentConductPTbyMerchantBranch(dispatch, params),
  setDefaultReportExport: () => dispatch(Actions.setDefaultReportExport()),
});

const mapStateToProps = ({ reportData, usersReducer, masterData }) => ({
  reportData,
  usersReducer,
  masterData,
});

ConductPTPage.propTypes = {
  conductReportPagination: PropTypes.func,
  filter: PropTypes.object,
  getChart: PropTypes.func,
  getConducted: PropTypes.func,
  getConductExport: PropTypes.func,
  getTotalAppointment: PropTypes.func,
  getTotalAppointmentMerchantBranch: PropTypes.func,
  history: PropTypes.object,
  masterData: PropTypes.object,
  onResetPrevStateValue: PropTypes.func,
  onSetPrevStateValue: PropTypes.func,
  reportData: PropTypes.object,
  setDefaultReportExport: PropTypes.func,
  usersReducer: PropTypes.object,
};

const shell = compose(connect(mapStateToProps, mapDispatchToProps));
const core = compose(AuthenticationAccessPages, PrevStateValue);

export default shell(core(ConductPTPage));
