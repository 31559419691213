import React from 'react';
import { connect } from 'react-redux';
import { Grid, FormControl, FormLabel } from '@material-ui/core';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { compose } from 'redux';
// component
import {
  ButtonMain,
  PrevNextStepperCount,
  SelectInputMain,
  SelectInputMerchant,
  SelectInputBranch,
  PickerInputDate,
  SelectInputSearchMain,
} from '../../../../../../../components/Index';
import { CardMembershipLabel, ModalConfirmationRenewal } from '../components/Index';
// // style
import './StepMembershipPurchaseAddMembershipDetailsStyle.scss';
// helper
import {
  CommonHelper,
  MasterDataHelper,
  pointBoosterActionOptionCode,
  MembershipHelper,
} from '../../../../../../../helpers/Index';
// api
import {
  getMembershipPurchaseRenewalList,
  getMembershipPurchaseNewList,
} from '../../../../../../../services/api/MembershipApi';

const currentDate = CommonHelper.currentDate('YYYY-MM-DD');

const initialValidation = {
  typeActionBooster: { isError: '', errorMessage: '' },
  merchantId: { isError: '', errorMessage: '' },
  branchId: { isError: '', errorMessage: '' },
  membershipId: { isError: '', errorMessage: '' },
  startDate: { isError: '', errorMessage: '' },
};

const initialForm = {
  typeActionBooster: undefined,
  merchantId: undefined,
  branchId: undefined,
  membershipId: undefined,
  startDate: currentDate,
  selectedMembershipDetails: {},
};

class StepMembershipPurchaseAddMembershipDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      form: CommonHelper.objectCloning(initialForm),
      validation: CommonHelper.objectCloning(initialValidation),
      pointBoosterActionOption: [],
      membershipListOption: [],
      isOpenConfirmationRenewalModal: false,
    };
  }

  componentDidMount() {
    const { parentForm, parentValidation, minDate, membershipId } = this.props;
    const { form, validation } = this.state;
    const modifyPointBoosterActionOption = CommonHelper.objectCloning(
      MasterDataHelper.pointBoosterActionOption,
    );

    if (!parentForm.isExisting) {
      modifyPointBoosterActionOption.splice(0, 1);
    }

    this.setState(
      {
        form: { ...form, ...parentForm, startDate: minDate || currentDate, membershipId },
        validation: { ...validation, ...parentValidation },
        pointBoosterActionOption: modifyPointBoosterActionOption,
      },
      () => {
        const checkExistingOption = modifyPointBoosterActionOption.filter(
          item => item.value === parentForm.typeActionBooster,
        );

        const valueChange = !_.isEmpty(checkExistingOption)
          ? parentForm.typeActionBooster
          : modifyPointBoosterActionOption[0].value;
        this.handleChangePointBoosterAction(valueChange);
      },
    );
  }

  componentWillReceiveProps(nextProps) {
    const { validation } = this.state;

    const isValidationChange = _.isEqual(validation, nextProps.parentValidation);

    if (!isValidationChange) {
      const finalMergeValidation = { ...validation, ...nextProps.parentValidation };
      this.setState({
        validation: finalMergeValidation,
      });
    }
  }

  componentDidUpdate(nextProps) {
    const { masterData } = this.props;

    if (nextProps.masterData.merchantList) {
      const ifEqual = _.isEqual(nextProps.masterData.merchantList, masterData.merchantList);
      if (!ifEqual) {
        const valueChange = !_.isEmpty(masterData.merchantList)
          ? masterData.merchantList[0].merchant_id
          : undefined;
        this.handleSelectMerchant(valueChange);
      }
    }
    if (nextProps.masterData.branchList) {
      const ifEqual = _.isEqual(nextProps.masterData.branchList, masterData.branchList);

      if (!ifEqual) {
        const valueChange = !_.isEmpty(masterData.branchList)
          ? masterData.branchList[0].branch_id
          : undefined;

        this.handleSelectBranch(valueChange);
      }
    }
  }

  componentWillUnmount() {}

  getMembershipList() {
    const { form, validation } = this.state;
    const {
      membershipPurchaseNewList,
      membershipPurchaseRenewalList,
      onPopUpInformation,
    } = this.props;
    let executeFunction = null;
    let params = {};
    const userId = form.userId || '';
    let passCheck = false;

    if (form.typeActionBooster === pointBoosterActionOptionCode.renew) {
      executeFunction = membershipPurchaseRenewalList;

      passCheck = true;
    } else if (form.typeActionBooster === pointBoosterActionOptionCode.purchase) {
      params = {
        user_id: userId,
        merchant_id: form.merchantId,
        booster_name: '',
      };
      executeFunction = membershipPurchaseNewList;

      passCheck = !!form.merchantId;
    }

    if (passCheck) {
      executeFunction(userId, params)
        .then(response => {
          const data = response.data;
          const message = CommonHelper.objectCloning(initialValidation.membershipId);
          let modifyMembershipList = [];

          if (_.isEmpty(data)) {
            message.isError = 'error';
            message.errorMessage = "User doesn't have any membership for renewal.";
          } else {
            modifyMembershipList = MembershipHelper.generateMembershipList(data);
          }

          this.setState(
            {
              membershipListOption: modifyMembershipList,
              validation: { ...validation, membershipId: message },
            },
            () => {
              const defaultValue = !_.isEmpty(modifyMembershipList)
                ? modifyMembershipList[0].value
                : undefined;
              const valueExist = _.find(modifyMembershipList, ['value', form.membershipId]);
              const valueChange = !_.isEmpty(valueExist) ? form.membershipId : defaultValue;
              this.handleSelectMembership(valueChange);
            },
          );
        })
        .catch(error => {
          const serverMessage = error.data;
          onPopUpInformation(serverMessage.messages, 'error');
        });
    }
  }

  handleChangePointBoosterAction = value => {
    const { form } = this.state;
    const typeActionBooster = value || null;

    this.setState(
      {
        form: {
          ...form,
          typeActionBooster,
        },
      },
      () => {
        this.getMembershipList();
      },
    );
  };

  handleSelectMerchant = value => {
    const { form } = this.state;
    const merchantId = value || null;

    this.setState(
      {
        form: { ...form, merchantId },
      },
      () => {
        this.getMembershipList();
      },
    );
  };

  handleSelectBranch = value => {
    const { form, validation } = this.state;
    const branchId = value || null;
    const resetValidation = CommonHelper.objectCloning(initialValidation.branchId);

    resetValidation.isError = !branchId ? 'error' : '';
    resetValidation.errorMessage = !branchId ? 'Please select Branch' : '';

    this.setState({
      form: { ...form, branchId },
      validation: { ...validation, branchId: resetValidation },
    });
  };

  handleSelectMembership = value => {
    const { form, validation, membershipListOption } = this.state;
    const membershipId = value || null;
    const resetValidation = CommonHelper.objectCloning(
      value ? initialValidation.membershipId : validation.membershipId,
    );
    const modifySelectedMembershipDetails = value
      ? _.find(membershipListOption, ['value', value])
      : {};

    this.setState({
      form: {
        ...form,
        membershipId,
        selectedMembershipDetails: modifySelectedMembershipDetails,
      },
      validation: { ...validation, membershipId: resetValidation },
    });
  };

  handleChangeStartDate = value => {
    const { form } = this.state;
    const startDate = value || '';

    this.setState(
      {
        form: { ...form, startDate },
      },
      () => {
        this.getMembershipList();
      },
    );
  };

  handlePrevClick = async () => {
    const { onPrevClick, onParameterUpdate } = this.props;
    const { form } = this.state;
    const formNewValue = CommonHelper.objectCloning(form);
    formNewValue.items = !_.isEmpty(form.selectedMembershipDetails)
      ? [form.selectedMembershipDetails.option]
      : [];

    await onParameterUpdate(formNewValue);
    await onPrevClick();
  };

  handleNextClick = () => {
    const { minDate } = this.props;
    if (!minDate) {
      this.handleModalConfirm();
      return;
    }
    this.setState({ isOpenConfirmationRenewalModal: true });
  };

  handleCloseRenewalModal = () => {
    this.setState({
      isOpenConfirmationRenewalModal: false,
    });
  };

  handleModalConfirm = async () => {
    const { onNextClick, onParameterUpdate } = this.props;
    const { form } = this.state;
    const formNewValue = CommonHelper.objectCloning(form);
    formNewValue.items = !_.isEmpty(form.selectedMembershipDetails)
      ? [form.selectedMembershipDetails.option]
      : [];

    await onParameterUpdate(formNewValue);
    await onNextClick();
  };

  renderMembershipCard() {
    const { form } = this.state;
    let renderElement = null;

    if (!_.isEmpty(form.selectedMembershipDetails)) {
      renderElement = <CardMembershipLabel form={form} />;
    }

    return renderElement;
  }

  render() {
    const {
      form: { typeActionBooster, merchantId, branchId, startDate, membershipId },
      pointBoosterActionOption,
      membershipListOption,
      isOpenConfirmationRenewalModal,
    } = this.state;
    const {
      onButtonClickCancel,
      membership: { fetching },
      minDate,
    } = this.props;
    // const ifDisableContent = fetching || typeActionBooster === pointBoosterActionOptionCode.renew;
    const ifDisableContent = fetching;

    return (
      <Grid container className="container-step-membership-details">
        <Grid item lg={12} md={12} className="mt-32">
          <Grid container spacing={2}>
            <Grid item lg={6} md={6}>
              <div className="flex-column">
                <FormControl component="fieldset" fullWidth margin="normal">
                  <FormLabel component="label" className="text-12 filed-title">
                    Purchase Category
                  </FormLabel>
                  <SelectInputMain
                    options={pointBoosterActionOption}
                    currentValue={typeActionBooster}
                    onChange={this.handleChangePointBoosterAction}
                  />
                </FormControl>
                <FormControl component="fieldset" fullWidth margin="normal">
                  <FormLabel component="label" className="text-12 filed-title">
                    Merchant
                  </FormLabel>
                  <SelectInputMerchant
                    placeHolder="Select Merchant"
                    onChange={this.handleSelectMerchant}
                    currentValue={merchantId}
                    disabled={ifDisableContent}
                  />
                </FormControl>
                <FormControl component="fieldset" fullWidth margin="normal">
                  <FormLabel component="label" className="text-12 filed-title">
                    Branch
                  </FormLabel>
                  <SelectInputBranch
                    placeHolder="Select Branch"
                    onChange={this.handleSelectBranch}
                    currentValue={branchId}
                    filter={{ merchantId }}
                    disabled={ifDisableContent}
                    errorMessage={this.state.validation.branchId.errorMessage}
                    validateStatus={this.state.validation.branchId.isError}
                  />
                </FormControl>
                <FormControl component="fieldset" fullWidth margin="normal">
                  <FormLabel component="label" className="text-12 filed-title">
                    Membership Name
                  </FormLabel>
                  <SelectInputSearchMain
                    options={membershipListOption}
                    onChange={this.handleSelectMembership}
                    currentValue={membershipId}
                    placeholder={'Select membership'}
                    disabled={fetching}
                    errorMessage={this.state.validation.membershipId.errorMessage}
                    validateStatus={this.state.validation.membershipId.isError}
                  />
                </FormControl>
                <FormControl component="fieldset" fullWidth margin="normal">
                  <FormLabel component="label" className="text-12 filed-title">
                    Start Date
                  </FormLabel>
                  <PickerInputDate
                    customIcon="ic-ffo-date-pick"
                    dateFormat="dd/MM/yyyy"
                    minDate={minDate || currentDate}
                    defaultValue={minDate || startDate}
                    onChange={this.handleChangeStartDate}
                    toolbar={false}
                  />
                </FormControl>
              </div>
            </Grid>
            <Grid item lg={6} md={6}>
              {this.renderMembershipCard()}
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={12} md={12}>
          <Grid container justify="flex-end">
            <Grid item lg={5} md={6}>
              <Grid container justify="flex-end">
                <Grid item lg={4} md={4} className="pl-8">
                  <ButtonMain
                    type="negative"
                    size="xl"
                    labelText="Cancel"
                    onClick={onButtonClickCancel}
                  />
                </Grid>
                <Grid item lg={4} md={4} className="pl-8">
                  <ButtonMain
                    type="ghost"
                    size="xl"
                    labelText="Prev"
                    onClick={this.handlePrevClick}
                  />
                </Grid>
                <Grid item lg={4} md={4} className="pl-8">
                  <ButtonMain
                    type="primary"
                    size="xl"
                    labelText="Next"
                    onClick={this.handleNextClick}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {isOpenConfirmationRenewalModal && (
          <ModalConfirmationRenewal
            handleConfirm={() => {
              this.handleModalConfirm();
            }}
            isOpen={isOpenConfirmationRenewalModal}
            date={startDate}
            onClose={this.handleCloseRenewalModal}
          />
        )}
      </Grid>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  membershipPurchaseRenewalList: (id, params = {}) =>
    getMembershipPurchaseRenewalList(dispatch, id, params),
  membershipPurchaseNewList: (id, params) => getMembershipPurchaseNewList(dispatch, id, params),
});

const mapStateToProps = ({ masterData, membership }) => ({ masterData, membership });

StepMembershipPurchaseAddMembershipDetails.propTypes = {
  masterData: PropTypes.object,
  membership: PropTypes.object,
  membershipId: PropTypes.number,
  membershipPurchaseNewList: PropTypes.func,
  membershipPurchaseRenewalList: PropTypes.func,
  minDate: PropTypes.string,
  onButtonClickCancel: PropTypes.func,
  onNextClick: PropTypes.func,
  onParameterUpdate: PropTypes.func,
  onPopUpInformation: PropTypes.func,
  onPrevClick: PropTypes.func,
  parentForm: PropTypes.object,
  parentValidation: PropTypes.object,
};

const shell = compose(connect(mapStateToProps, mapDispatchToProps));

export default shell(PrevNextStepperCount(StepMembershipPurchaseAddMembershipDetails));
