import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Grid, FormControl, FormLabel } from '@material-ui/core';
import _ from 'lodash';
// Api
import { setCreateCompany } from '../../../../../../services/api/MasterDataMainAPi';
import {
  getOrderShippingAddress,
  getOrderShippingAddressInfo,
} from '../../../../../../services/api/OrdersApi';
// style
import './ModalCompanyCreateStyle.scss';
// component
import {
  LabelInput,
  ModalAlertAction,
  TextInput,
  TextInputAutocomplete,
} from '../../../../../../components/Index';
// helper
import { CommonHelper, ValidationHelper } from '../../../../../../helpers/Index';

const modalDetailData = {
  title: 'Confirmation',
};

const initialValidation = {
  name: { isError: false, errorMessage: '' },
  email: { isError: false, errorMessage: '' },
  phone: { isError: false, errorMessage: '' },
  address: { isError: false, errorMessage: '' },
};

const initialForm = {
  name: '',
  email: '',
  phone: '',
  address: '',
  placeId: null,
  addressInfo: {},
};

const maxStringLength = 100;

class ModalCompanyCreate extends React.Component {
  constructor(props) {
    super(props);
    const { isOpen } = props;

    this.state = {
      form: CommonHelper.objectCloning(initialForm),
      validation: CommonHelper.objectCloning(initialValidation),
      currentStringLength: 0,
      isLoading: false,
      isOpenModal: isOpen,
    };
    this.searchAddressDebounce = _.debounce(this.searchAddressDebounce, 400);
  }

  componentDidMount() {}

  handleCloseModalCreate = () => {
    const { onModalClose } = this.props;
    const newForm = CommonHelper.objectCloning(initialForm);
    const newValidation = CommonHelper.objectCloning(initialValidation);

    this.setState(
      {
        isOpenModal: false,
        form: newForm,
        validation: newValidation,
      },
      () => {
        onModalClose(false);
      },
    );
  };

  handleTextChangeCompanyName = value => {
    const { validation, form } = this.state;

    const message = CommonHelper.objectCloning(initialValidation.name);
    message.isError = !value;
    message.errorMessage = !value ? 'Company Name Empty' : '';

    if (!CommonHelper.checkStringLengthOver(value, maxStringLength)) {
      this.setState({
        form: { ...form, name: value },
        currentStringLength: value.length,
        validation: { ...validation, name: message },
      });
    }
  };

  handleTextChangeCompanyEmail = value => {
    const { validation, form } = this.state;

    const emailValidation = ValidationHelper.validateEmail(value.trim());

    const message = CommonHelper.objectCloning(initialValidation.email);
    message.isError = !emailValidation;
    message.errorMessage = !emailValidation ? 'please check email, and try again' : '';

    this.setState({
      form: { ...form, email: value.trim() },
      validation: { ...validation, email: message },
    });
  };

  handleTextChangeCompanyPhone = value => {
    const { validation, form } = this.state;

    const phoneValidation = ValidationHelper.validatePhone(value.trim());
    const phoneValidationLocal = ValidationHelper.validatePhoneLocal(value.trim());

    const message = CommonHelper.objectCloning(initialValidation.phone);
    const checkPhoneFist = phoneValidation;
    const finalCheckPhone = !checkPhoneFist ? !phoneValidationLocal : false;
    message.isError = finalCheckPhone;
    message.errorMessage = finalCheckPhone ? 'please check Phone, and try again' : '';

    this.setState({
      form: { ...form, phone: value.trim() },
      validation: { ...validation, phone: message },
    });
  };

  handleTextChangeAddress = value => {
    const { form } = this.state;
    this.setState(
      {
        form: { ...form, address: value },
      },
      () => {
        this.searchAddressDebounce();
      },
    );
  };

  searchAddressDebounce = () => {
    const { form, validation } = this.state;
    const { orderShippingAddress, onPopUpInformation } = this.props;

    const param = {
      search_term: form.address,
    };

    const message = CommonHelper.objectCloning(initialValidation.address);
    message.isError = !form.address;
    message.errorMessage = !form.address ? 'Please enter Address' : '';

    this.setState(
      {
        form: { ...form, address: form.address },
        validation: { ...validation, address: message },
      },
      () => {
        orderShippingAddress(param).catch(error => {
          const serverMessage = error.data;
          onPopUpInformation(serverMessage.messages, 'error');
        });
      },
    );
  };

  handleSelectedItemAddress = (value, option) => {
    const { form } = this.state;

    this.setState({ form: { ...form, address: option.address, placeId: option.place_id } }, () => {
      this.onProcessPlaceId();
    });
  };

  onProcessPlaceId = () => {
    const { form } = this.state;
    const { orderShippingAddressInfo, onPopUpInformation } = this.props;

    const param = {
      place_id: form.placeId,
    };

    orderShippingAddressInfo(param)
      .then(response => {
        const data = response.data;

        this.setState({ form: { ...form, addressInfo: data } });
      })
      .catch(error => {
        const serverMessage = error.data;
        onPopUpInformation(serverMessage.messages, 'error');
      });
  };

  handleButtonSaveCompany = () => {
    const { form } = this.state;
    const { createCompany, onPopUpInformation } = this.props;

    if (this.checkValidationForm()) {
      const param = {
        data: {
          name: form.name.trim(),
          email: form.email,
          phone: form.phone,
          address_details: form.addressInfo,
        },
      };

      this.setState({ isLoading: true }, () => {
        createCompany(param)
          .then(async response => {
            const message = response.messages;

            await onPopUpInformation(message, 'success');
            await this.handleCloseModalCreate();
          })
          .catch(error => {
            const message = error.data;
            onPopUpInformation(message.messages, 'error');
          });
      });
    }
  };

  checkValidationForm() {
    const { form, validation } = this.state;
    let passCheck = true;

    const paramValidation01 = CommonHelper.objectCloning(initialValidation.name);
    const paramValidation02 = CommonHelper.objectCloning(initialValidation.address);

    paramValidation01.isError = !form.name;
    paramValidation01.errorMessage = !form.name ? 'Company Name Still Empty...' : '';

    paramValidation02.isError = !form.name;
    paramValidation02.errorMessage = !form.name ? 'Company Address not valid' : '';

    if (
      paramValidation01.isError ||
      paramValidation02.isError ||
      validation.email.isError ||
      validation.phone.isError
    ) {
      passCheck = false;
    }

    this.setState({
      validation: {
        ...validation,
        name: paramValidation01,
        address: paramValidation02,
      },
    });

    return passCheck;
  }

  render() {
    const { isLoading, isOpenModal, form, validation, currentStringLength } = this.state;
    const {
      orderData: { fetchingShippingAddress, listShippingAddress },
    } = this.props;
    const ListCustom = listShippingAddress;

    return (
      <ModalAlertAction
        onButtonSubmit={this.handleButtonSaveCompany}
        onCloseModal={this.handleCloseModalCreate}
        modalDetail={modalDetailData}
        buttonSubmitText="Save"
        openModal={isOpenModal}
        isLoading={isLoading}
        customElementProps={
          <Grid container direction="column">
            <Grid item lg md>
              <FormControl component="fieldset" fullWidth margin={'normal'}>
                <LabelInput labelText="Company Name" isRequired />
                <Grid container direction="column" justify="flex-end">
                  <Grid item lg md>
                    <TextInput
                      placeHolderText="Enter Name"
                      onChange={this.handleTextChangeCompanyName}
                      currentValue={form.name}
                      errorMessage={validation.name.errorMessage}
                      isError={validation.name.isError}
                      size="md"
                    />
                  </Grid>
                  <Grid item lg md>
                    <Grid container justify="flex-end">
                      <Grid item>
                        <label className="text-10">{`${currentStringLength} / ${maxStringLength}`}</label>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </FormControl>
            </Grid>
            <Grid item lg md>
              <FormControl component="fieldset" fullWidth margin={'normal'}>
                <FormLabel component="label" className="form-label text-12">
                  Company Email <label>( Optional )</label>
                </FormLabel>
                <TextInput
                  placeHolderText="Enter Email"
                  onChange={this.handleTextChangeCompanyEmail}
                  errorMessage={validation.email.errorMessage}
                  isError={validation.email.isError}
                />
              </FormControl>
            </Grid>
            <Grid item lg md>
              <FormControl component="fieldset" fullWidth margin={'normal'}>
                <FormLabel component="label" className="form-label text-12">
                  Company Phone <label>( Optional )</label>
                </FormLabel>
                <TextInput
                  placeHolderText="Enter Phone Number"
                  onChange={this.handleTextChangeCompanyPhone}
                  errorMessage={validation.phone.errorMessage}
                  isError={validation.phone.isError}
                />
              </FormControl>
            </Grid>
            <Grid item lg md>
              <FormControl component="fieldset" fullWidth margin={'normal'}>
                <LabelInput labelText="Shipping Address" isRequired />
                <TextInputAutocomplete
                  size="md"
                  onChange={this.handleTextChangeAddress}
                  currentValue={form.address}
                  placeholder="Enter Address"
                  onSelect={this.handleSelectedItemAddress}
                  isLoading={fetchingShippingAddress}
                  data={ListCustom}
                  isCustomDisplay
                  customDisplayClass="custom-address-option-item"
                  isError={validation.address.isError}
                  errorMessage={validation.address.errorMessage}
                />
              </FormControl>
            </Grid>
          </Grid>
        }
      />
    );
  }
}

const mapDispatchToProps = dispatch => ({
  createCompany: params => setCreateCompany(dispatch, params),
  orderShippingAddress: params => getOrderShippingAddress(dispatch, params),
  orderShippingAddressInfo: params => getOrderShippingAddressInfo(dispatch, params),
});

const mapStateToProps = ({ orderData }) => ({ orderData });

ModalCompanyCreate.propTypes = {
  createCompany: PropTypes.func,
  isOpen: PropTypes.bool,
  onModalClose: PropTypes.func,
  onPopUpInformation: PropTypes.func,
  orderData: PropTypes.object,
  orderShippingAddress: PropTypes.func,
  orderShippingAddressInfo: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalCompanyCreate);
