/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, FormControl } from '@material-ui/core';
// Style
import './TermOfPaymentStyle.scss';
// Component
import { PickerInputDate, TextInputPrePost } from '../../../../../../components/Index';
// helper
import { CommonHelper, currencyCode } from '../../../../../../helpers/Index';

const currentDate = CommonHelper.currentDate('YYYY-MM-DD');
const startDateValue = CommonHelper.intervalDate(currentDate, 'YYYY-MM-DD', 'day', -7);

const TermOfPaymentItemList = ({
  defaultValue,
  handleChangeAmount,
  id,
  label,
  order,
  selectedTerm,
}) => {
  const [amount, setAmount] = useState('');
  const [dueDate, setDueDate] = useState(CommonHelper.objectCloning(currentDate));

  useEffect(() => {
    setAmount('');
    setDueDate(CommonHelper.objectCloning(currentDate));
  }, [selectedTerm]);

  useEffect(() => {
    if (defaultValue) {
      setAmount(+defaultValue.amount);
      setDueDate(CommonHelper.objectCloning(defaultValue.due_date));
    }
  }, [defaultValue]);

  return (
    <Grid container spacing={3} className="container-term-of-payment-item">
      <Grid item lg={12} md={12}>
        <Grid container spacing={3} alignItems={'center'}>
          <Grid item lg={4} md={4}>
            <label className="text-14 text-bold">{label}</label>
          </Grid>
          <Grid item lg={4} md={4}>
            <Grid item lg={8} md={8}>
              <FormControl component="fieldset" fullWidth margin={'normal'}>
                <PickerInputDate
                  customIcon="ic-ffo-date-pick"
                  dateFormat="dd/MM/yyyy"
                  minDate={startDateValue}
                  defaultValue={dueDate}
                  onChange={value => {
                    setDueDate(value);
                    handleChangeAmount(id, {
                      amount: +amount,
                      due_date: value,
                      order,
                    });
                  }}
                  toolbar={false}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid item lg={4} md={4}>
            <Grid item lg={8} md={8}>
              <FormControl component="fieldset" fullWidth margin={'normal'}>
                <TextInputPrePost
                  placeHolderText="Enter Amount"
                  onChange={value => {
                    setAmount(value);
                    handleChangeAmount(id, {
                      amount: +value,
                      due_date: dueDate,
                      order,
                    });
                  }}
                  currentValue={amount}
                  customElementsBefore={currencyCode.idr}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

TermOfPaymentItemList.propTypes = {
  defaultValue: PropTypes.object,
  handleChangeAmount: PropTypes.func,
  id: PropTypes.string,
  label: PropTypes.string,
  order: PropTypes.number,
  selectedTerm: PropTypes.number,
};

const TermOfPaymentItem = ({ defaultTermValue, onChangeAmount, selectedTerm }) => {
  const [selectedTop, setSelectedTop] = React.useState({});
  const termLists = [...new Array(selectedTerm)];

  const handleChangeAmount = (id, value) => {
    const updateSelectedTop = {
      ...selectedTop,
      [id]: value,
    };
    setSelectedTop(updateSelectedTop);
    onChangeAmount(updateSelectedTop);
  };

  return (
    <Grid item lg={12} md={12}>
      <Grid container spacing={3} className="section-page-term-of-payment">
        <Grid item lg={12} md={12} className="term-of-payment-header">
          <Grid container spacing={3}>
            <Grid item lg={4} md={4}>
              <label className="text-14 text-bold">TERM OF PAYMENT</label>
            </Grid>
            <Grid item lg={4} md={4}>
              <label className="text-14 text-bold">DUE DATE</label>
            </Grid>
            <Grid item lg={4} md={4}>
              <label className="text-14 text-bold">AMOUNT</label>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={12} md={12}>
          {termLists.map((_, termIndex) => {
            return (
              <TermOfPaymentItemList
                key={termIndex}
                defaultValue={defaultTermValue[termIndex]}
                handleChangeAmount={handleChangeAmount}
                id={`term-${termIndex + 1}`}
                label={`Payment ${termIndex + 1}`}
                selectedTerm={selectedTerm}
                order={termIndex}
              />
            );
          })}
        </Grid>
      </Grid>
    </Grid>
  );
};

TermOfPaymentItem.propTypes = {
  defaultTermValue: PropTypes.array,
  onChangeAmount: PropTypes.func,
  selectedTerm: PropTypes.number,
};

export default TermOfPaymentItem;
