export const constants = {
  getSegmentPaginationRequest: 'GET_SEGMENT_PAGINATION_REQUEST',
  getSegmentPaginationSuccess: 'GET_SEGMENT_PAGINATION_SUCCESS',
  getSegmentPaginationFail: 'GET_SEGMENT_PAGINATION_FAIL',
  getSegmentPreviewQueryRequest: 'GET_SEGMENT_PREVIEW_QUERY_REQUEST',
  getSegmentPreviewQuerySuccess: 'GET_SEGMENT_PREVIEW_QUERY_SUCCESS',
  getSegmentPreviewQueryFail: 'GET_SEGMENT_PREVIEW_QUERY_FAIL',
  getSegmentDetailsRequest: 'GET_SEGMENT_DETAILS_REQUEST',
  getSegmentDetailsSuccess: 'GET_SEGMENT_DETAILS_SUCCESS',
  getSegmentDetailsFail: 'GET_SEGMENT_DETAILS_FAIL',
  createSegmentRequest: 'CREATE_SEGMENT_REQUEST',
  createSegmentSuccess: 'CREATE_SEGMENT_SUCCESS',
  createSegmentFail: 'CREATE_SEGMENT_FAIL',
  editSegmentRequest: 'EDIT_SEGMENT_REQUEST',
  editSegmentSuccess: 'EDIT_SEGMENT_SUCCESS',
  editSegmentFail: 'EDIT_SEGMENT_FAIL',
  deleteSegmentRequest: 'DELETE_SEGMENT_REQUEST',
  deleteSegmentSuccess: 'DELETE_SEGMENT_SUCCESS',
  deleteSegmentFail: 'DELETE_SEGMENT_FAIL',
  resetSegmentPreview: 'RESET_SEGMENT_PREVIEW',
};

const initState = {
  fetching: false,
  list: [],
  segmentDetails: {},
  errorFetch: false,
  errorMessage: null,
  responseMessage: null,
  previewQueryResult: [],
};

export default function segmentReducer(state = initState, action) {
  const { type, payload } = action;
  switch (type) {
    case constants.getSegmentPaginationRequest:
      return { ...state, fetching: true };
    case constants.getSegmentPaginationSuccess:
      return {
        ...state,
        list: payload.data.data,
        fetching: false,
      };
    case constants.getSegmentPaginationFail:
      return {
        ...state,
        fetching: false,
        errorFetch: true,
        errorMessage: payload,
      };

    case constants.getSegmentPreviewQueryRequest:
      return { ...state, fetching: true };
    case constants.getSegmentPreviewQuerySuccess:
      return {
        ...state,
        previewQueryResult: payload.data,
        fetching: false,
      };
    case constants.getSegmentPreviewQueryFail:
      return {
        ...state,
        fetching: false,
        errorFetch: true,
        errorMessage: payload,
      };

    case constants.getSegmentDetailsRequest:
      return { ...state, fetching: true };
    case constants.getSegmentDetailsSuccess:
      return {
        ...state,
        segmentDetails: payload.data,
        fetching: false,
      };
    case constants.getSegmentDetailsFail:
      return {
        ...state,
        fetching: false,
        errorFetch: true,
        errorMessage: payload,
      };

    case constants.createSegmentRequest:
      return { ...state, fetching: true, responseMessage: null };
    case constants.createSegmentSuccess:
      return {
        ...state,
        responseMessage: payload.messages,
        fetching: false,
      };
    case constants.createSegmentFail:
      return { ...state, fetching: false, errorMessage: payload };

    case constants.editSegmentRequest:
      return { ...state, fetching: true, responseMessage: null };
    case constants.editSegmentSuccess:
      return {
        ...state,
        responseMessage: payload.messages,
        fetching: false,
      };
    case constants.editSegmentFail:
      return { ...state, fetching: false, errorMessage: payload };

    case constants.deleteSegmentRequest:
      return { ...state, fetching: true, responseMessage: null };
    case constants.deleteSegmentSuccess:
      return {
        ...state,
        responseMessage: payload.messages,
        fetching: false,
      };
    case constants.deleteSegmentFail:
      return { ...state, fetching: false, errorMessage: payload };

    case constants.resetSegmentPreview:
      return { ...state, previewQueryResult: [] };
    default:
      return state;
  }
}
