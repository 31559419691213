import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Button, CircularProgress } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
// Style
import './ButtonIconMainStyle.scss';
import { PermissionHelper } from '../../../helpers/Index';

const ButtonIconMain = props => {
  const {
    onClick,
    type,
    rounded,
    size,
    icon,
    disabled,
    isLoading,
    requiredPermission,
    tooltip,
  } = props;

  let hasPermission = true;
  if (!_.isEmpty(requiredPermission)) {
    const permissionKeys = requiredPermission.split('.');
    const module = permissionKeys[0];
    const page = permissionKeys[1];
    const access = permissionKeys[2];

    hasPermission = PermissionHelper.checkUserHasPermission(module, page, access);
  }

  if (!hasPermission) {
    return null;
  }

  let currentIcon = 'size-16';
  let currentCircular = 18;

  switch (size) {
    case 'xl':
      currentIcon = 'size-18';
      currentCircular = 16;
      break;
    case 'md':
      currentIcon = 'size-16';
      currentCircular = 16;
      break;
    case 'sm':
      currentIcon = 'size-14';
      currentCircular = 16;
      break;
    case 'xs':
      currentIcon = 'size-12';
      currentCircular = 14;
      break;
    default:
      currentIcon = 'size-12';
      currentCircular = 16;
      break;
  }

  const ifLoadingStyle = isLoading ? 'pt-7 ph-8 pb-1' : 'p-4';

  if (tooltip) {
    return (
      <Tooltip title={tooltip} placement="top" arrow>
        <Button
          className={
            rounded ? `btn-rounded main-btn-icon btn-${type}` : ` main-btn-icon btn-${type}`
          }
          onClick={onClick}
          disabled={disabled}
        >
          <div className={ifLoadingStyle}>
            {isLoading ? (
              <CircularProgress className="btn-loading" size={currentCircular} />
            ) : (
              <i className={`${icon} container-icon-prefix ${currentIcon}`} />
            )}
          </div>
        </Button>
      </Tooltip>
    );
  }

  return (
    <Button
      className={rounded ? `btn-rounded main-btn-icon btn-${type}` : ` main-btn-icon btn-${type}`}
      onClick={onClick}
      disabled={disabled}
    >
      <div className={ifLoadingStyle}>
        {isLoading ? (
          <CircularProgress className="btn-loading" size={currentCircular} />
        ) : (
          <i className={`${icon} container-icon-prefix ${currentIcon}`} />
        )}
      </div>
    </Button>
  );
};

ButtonIconMain.propTypes = {
  disabled: PropTypes.bool,
  icon: PropTypes.string,
  isLoading: PropTypes.bool,
  onClick: PropTypes.func,
  requiredPermission: PropTypes.string,
  rounded: PropTypes.bool,
  size: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
  type: PropTypes.string.isRequired,
};

export default ButtonIconMain;
