import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import _ from 'lodash';
import * as Actions from '../../redux/actions/DashboardAction';
// style
import './DashboardStyle.scss';
// component
import {
  AuthenticationAccessPages,
  ChartBarGraphic,
  GridDynamicLayout,
  PickerInputDate,
  SelectInputBranch,
} from '../../components/Index';
import {
  ButtonDashboardHeaderCard,
  CardDashboardSummary,
  CardDemographicBookDetails,
  CardMembershipList,
  CardRecentCheckInList,
  CardTopSellingMembershipList,
  CardTopSpender,
} from './components/Index';
import { CardConductPTTop } from '../report/conduct-pt/components/Index';
import {
  TickYAxisCurrency,
  TooltipChatCustom,
} from '../../components/presentational/chart-bar-graphic/components/Index';
// api
import {
  getDailyReportStatistic,
  getSummaryConduct,
  getSummaryExpiredMembership,
  getSummaryRecurringMembership,
  getSummarySalesMove,
} from '../../services/api/DashboardApi';
// helper
import { CommonHelper, currencyCode, DashboardHelper } from '../../helpers/Index';
// assets
import { Images } from '../../assets/Index';
import themes from '../../themes/Index';

const currentDate = CommonHelper.currentDate('YYYY-MM-DD');
const startDateValue = CommonHelper.getStartDateMonth(currentDate);
const endDateValue = CommonHelper.getEndDateMonth(currentDate);

const initialFilter = {
  merchantId: null,
  branchId: null,
  startDate: startDateValue,
  endDate: endDateValue,
  category: null,
};

class Dashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: CommonHelper.objectCloning(initialFilter),
      graphicBar: [],
      graphicBarLine: [],
      graphicPie: [],
      windowsDisplay: { width: 0, height: 0 },
      gridWidth: 0,
      layout: [
        { i: 'dailyReport', x: 0, y: 0, w: 9, h: 21, minW: 9, minH: 21, maxH: 21 },
        { i: 'summaryReport', x: 10, y: 0, w: 3, h: 45, minW: 3, maxW: 3, minH: 45, maxH: 45 },
        { i: 'membershipReport', x: 0, y: 22, w: 9, h: 28, minW: 7, minH: 28, maxH: 28 },
        { i: 'topConductPt', x: 10, y: 38, w: 3, h: 23, minW: 3, maxW: 3, minH: 23, maxH: 23 },
        { i: 'topSpender', x: 10, y: 61, w: 3, h: 23, minW: 3, maxW: 3, minH: 23, maxH: 23 },
        { i: 'topSellingMembershipReport', x: 0, y: 84, w: 9, h: 19, minW: 7, minH: 19, maxH: 19 },
        {
          i: 'demoGraphicReport',
          x: 10,
          y: 103,
          w: 3,
          h: 26,
          minW: 3,
          maxW: 3,
          minH: 26,
          maxH: 26,
        },
        { i: 'recentCheckIn', x: 0, y: 50, w: 9, h: 28, minW: 7, minH: 28, maxH: 28 },
      ],
    };
    this.myRefHeader = React.createRef();
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.getSummaryButton();
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillReceiveProps(nextProps) {
    const { filter } = this.state;
    const { usersReducer } = this.props;

    const isEqualActiveMerchantId = _.isEqual(
      usersReducer.activeMerchant,
      nextProps.usersReducer.activeMerchant,
    );

    if (!isEqualActiveMerchantId) {
      this.setState({
        filter: { ...filter, merchantId: nextProps.usersReducer.activeMerchant },
      });
    }
  }

  componentDidUpdate(prevProps, nextProps) {
    const {
      usersReducer,
      masterData,
      dashboardData: {
        fetchingSalesMove,
        fetchingRecurringMembership,
        fetchingConduct,
        fetchingExpiredMembership,
        listSummary,
      },
      settingData: { sideBarOpen },
    } = this.props;
    const { windowsDisplay } = this.state;

    const currentMerchant = usersReducer.activeMerchant;

    const ifMerchantChange = currentMerchant !== nextProps.filter.merchantId;
    const ifBranchEqual = _.isEqual(prevProps.masterData.branchList, masterData.branchList);

    if (
      (ifMerchantChange || !ifBranchEqual) &&
      !fetchingSalesMove &&
      !fetchingRecurringMembership &&
      !fetchingConduct &&
      !fetchingExpiredMembership
    ) {
      this.resetMerchantBranch(ifMerchantChange);
    }

    const ifDisplayEqual = _.isEqual(nextProps.windowsDisplay, windowsDisplay);

    if (!ifDisplayEqual) {
      const width = this.myRefHeader.current.offsetWidth;
      this.updateGridWidth(width);
    }

    const ifDisplayHeaderButtonEqual = _.isEqual(prevProps.dashboardData.listSummary, listSummary);

    if (!_.isEmpty(listSummary) && !ifDisplayHeaderButtonEqual && listSummary.length === 4) {
      const valueChange = _.find(listSummary, ['order', 1]) || listSummary[0];
      this.handleButtonClickCardHeader(valueChange.category);
    }

    if (sideBarOpen !== prevProps.settingData.sideBarOpen) {
      const width = this.myRefHeader.current.offsetWidth;
      const formulaDrawer = themes.drawerWidth * 0.72;
      const dynamicWidth = sideBarOpen ? width - formulaDrawer : width + formulaDrawer;

      this.updateGridWidth(dynamicWidth);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  getSummaryButton() {
    const {
      filter: { startDate, endDate, branchId },
    } = this.state;
    const {
      summaryConduct,
      summaryExpiredMembership,
      summaryRecurringMembership,
      summarySalesMove,
      resetListSummary,
      dashboardData: {
        fetchingSalesMove,
        fetchingRecurringMembership,
        fetchingConduct,
        fetchingExpiredMembership,
      },
    } = this.props;

    if (
      !fetchingSalesMove &&
      !fetchingRecurringMembership &&
      !fetchingConduct &&
      !fetchingExpiredMembership
    ) {
      const params01 = {
        start_date: startDate,
        end_date: endDate,
      };

      const params02 = {
        start_date: startDate,
        end_date: endDate,
        branch_id: branchId,
      };

      resetListSummary([]);
      summaryConduct(params02);
      summaryExpiredMembership(params01);
      summaryRecurringMembership(params01);
      summarySalesMove(params01);
    }
  }

  getDailyReportStatistic() {
    const {
      filter: { startDate, endDate, category, branchId },
    } = this.state;
    const {
      dailyReportStatistic,
      dashboardData: { fetchingGraphic },
    } = this.props;

    const params = {
      start_date: startDate,
      end_date: endDate,
      category,
      branch_id: branchId,
    };

    if (!fetchingGraphic) {
      dailyReportStatistic(params).then(response => {
        const data = response.data;
        const convertedStatistic = DashboardHelper.generateDataStatisticBar(data.daily_report);
        const convertedStatisticLine = DashboardHelper.generateDataStatisticBarLine(
          data.daily_report,
          ['date'],
        );
        const convertedStatisticPie = DashboardHelper.generateDataStatisticPie(data.summary);

        this.setState({
          graphicBar: convertedStatistic,
          graphicBarLine: convertedStatisticLine,
          graphicPie: convertedStatisticPie,
        });
      });
    }
  }

  handleChangeStartDate = value => {
    const { filter } = this.state;
    const endDate = CommonHelper.getEndDateMonth(value);

    this.setState(
      {
        filter: { ...filter, startDate: value, endDate },
      },
      () => {
        this.getSummaryButton();
      },
    );
  };

  handleChangeEndDate = value => {
    const { filter } = this.state;

    this.setState(
      {
        filter: { ...filter, endDate: value },
      },
      () => {
        this.getSummaryButton();
      },
    );
  };

  handleSelectChangeBranch = value => {
    const { filter } = this.state;

    this.setState(
      {
        filter: { ...filter, branchId: value },
      },
      () => {
        this.getSummaryButton();
      },
    );
  };

  handleButtonClickCardHeader = value => {
    const { filter } = this.state;

    this.setState(
      {
        filter: { ...filter, category: value },
      },
      () => this.getDailyReportStatistic(),
    );
  };

  handleOnButtonClickViewStatusRecurring = value => {
    const { history } = this.props;
    const param = CommonHelper.encryptObject({ ...value });

    history.push(`/dashboard/membership/list/${param}`);
  };

  resetMerchantBranch(resetBranch = false) {
    const { filter } = this.state;
    const {
      usersReducer,
      masterData,
      dashboardData: { fetching },
    } = this.props;

    const ifBranchListExist = !_.isEmpty(masterData.branchList)
      ? masterData.branchList[0].branch_id
      : null;

    this.setState(
      {
        filter: {
          ...filter,
          merchantId: usersReducer.activeMerchant,
          branchId: resetBranch ? null : ifBranchListExist,
        },
      },
      () => {
        if (!fetching && !masterData.fetching) {
          this.getSummaryButton();
        }
      },
    );
  }

  updateWindowDimensions() {
    const { windowsDisplay } = this.state;
    this.setState({
      windowsDisplay: { ...windowsDisplay, width: window.innerWidth, height: window.innerHeight },
    });
  }

  updateGridWidth(value) {
    this.setState({
      gridWidth: value,
    });
  }

  renderHeaderCardButton() {
    const {
      dashboardData: { listSummary },
    } = this.props;
    const { filter } = this.state;

    const converted = !_.isEmpty(listSummary)
      ? CommonHelper.dataArrayOrder(listSummary, 'order', 'asc')
      : [];

    return (
      <Grid container spacing={2} justify="flex-start" alignItems="center" className="pt-24">
        {converted.map((item, index) => {
          const percentage = `${item.percentage}%`;
          const isActive = filter.category === item.category;
          const modifyCurrency = item.is_idr ? currencyCode.idr : '';
          const currencyValue = `${modifyCurrency} ${CommonHelper.formatCurrency(item.total)}`;
          let activeBackground = Images.blueCard;

          if (item.category === 'completed_conduct') {
            activeBackground = Images.blueCard;
          } else if (item.category === 'expired_membership') {
            activeBackground = Images.redCard;
          } else if (item.category === 'recurring_membership') {
            activeBackground = Images.orangeCard;
          } else if (item.category === 'new_sales') {
            activeBackground = Images.purpleCard;
          }

          return (
            <Grid item lg={3} md={3} key={index}>
              <ButtonDashboardHeaderCard
                isImprove={item.is_improve}
                labelText={currencyValue.trim()}
                labelTextPercentage={percentage}
                onClick={() => this.handleButtonClickCardHeader(item.category)}
                isActive={isActive}
                activeBackground={activeBackground}
                subLabelText={item.category_title}
              />
            </Grid>
          );
        })}
      </Grid>
    );
  }

  renderFilter = () => {
    const { filter } = this.state;
    const branchFilter = {
      merchantId: filter.merchantId,
    };

    return (
      <Grid container justify="flex-start" className="align-content-center" alignItems="center">
        <Grid item lg={2} md={2}>
          <label className="text-14">Showing Statistic </label>
        </Grid>
        <Grid item lg={2} md={2} style={{ marginLeft: -30 }}>
          <PickerInputDate
            customIcon="ic-ffo-date-pick"
            dateFormat="dd/MM/yyyy"
            defaultValue={filter.startDate}
            onChange={this.handleChangeStartDate}
            toolbar={false}
          />
        </Grid>
        <label className="text-14 pr-8 pl-8"> - </label>
        <Grid item lg={2} md={2}>
          <PickerInputDate
            customIcon="ic-ffo-date-pick"
            dateFormat="dd/MM/yyyy"
            minDate={filter.startDate}
            defaultValue={filter.endDate}
            onChange={this.handleChangeEndDate}
            toolbar={false}
          />
        </Grid>
        <Grid item lg md>
          <Grid container justify="flex-end" alignItems="center">
            <Grid item lg={6} md={6}>
              <SelectInputBranch
                placeHolder="Select Branch"
                onChange={this.handleSelectChangeBranch}
                currentValue={filter.branchId}
                filter={branchFilter}
                includeAllData
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  render() {
    const { filter, graphicBar, graphicBarLine, graphicPie, gridWidth, layout } = this.state;
    const {
      dashboardData: { fetchingGraphic },
    } = this.props;

    const isContentNotUsingCurrency =
      filter.category === 'completed_conduct' || filter.category === 'expired_membership';

    return (
      <div>
        <Helmet title="FITCO | Dashboard" />
        <div className="container-page-dashboard scroll-container-invisible">
          <div className="container-page-scrolling-area">
            <Grid container direction="column" className="flex-wrap-unset">
              <Grid ref={this.myRefHeader} item lg md className="section-page-header">
                <div className="flex-column">
                  {this.renderFilter()}
                  {this.renderHeaderCardButton()}
                </div>
              </Grid>
              <Grid item lg md className="section-page-body">
                <GridDynamicLayout width={gridWidth} layout={layout}>
                  <div
                    key="dailyReport"
                    className="container-main-card ph-16 pt-24 pb-16 flex-column"
                  >
                    <label className="text-14 text-semi-bold mb-13">Daily Report</label>
                    <ChartBarGraphic
                      dataArray={graphicBar}
                      dataArrayLine={graphicBarLine}
                      height={350}
                      targetDataX="dateDisplay"
                      isLoading={fetchingGraphic}
                      tickYCustom={<TickYAxisCurrency />}
                      tooltipCustom={
                        isContentNotUsingCurrency ? (
                          <TooltipChatCustom />
                        ) : (
                          <TooltipChatCustom currency="IDR" />
                        )
                      }
                    />
                  </div>
                  <div key="summaryReport" id="summaryReport">
                    <CardDashboardSummary
                      data={graphicPie}
                      isContentNotUsingCurrency={isContentNotUsingCurrency}
                      isLoading={fetchingGraphic}
                    />
                  </div>
                  <div key="membershipReport">
                    <CardMembershipList
                      onClickViewAll={this.handleOnButtonClickViewStatusRecurring}
                      parentFilter={filter}
                    />
                  </div>
                  <div key="topSellingMembershipReport">
                    <CardTopSellingMembershipList parentFilter={filter} />
                  </div>
                  <div key="demoGraphicReport">
                    <CardDemographicBookDetails parentFilter={filter} />
                  </div>
                  <div key="topConductPt" className="container-main-card p-16">
                    <CardConductPTTop parentFilter={filter} />
                  </div>
                  <div key="topSpender" className="container-main-card p-16">
                    <CardTopSpender parentFilter={filter} />
                  </div>
                  <div key="recentCheckIn">
                    <CardRecentCheckInList parentFilter={filter} />
                  </div>
                </GridDynamicLayout>
              </Grid>
              <Grid item lg md className="section-page-footer" />
            </Grid>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  summarySalesMove: params => getSummarySalesMove(dispatch, params),
  summaryRecurringMembership: params => getSummaryRecurringMembership(dispatch, params),
  summaryConduct: params => getSummaryConduct(dispatch, params),
  summaryExpiredMembership: params => getSummaryExpiredMembership(dispatch, params),
  resetListSummary: param => dispatch(Actions.setListSummary(param)),
  dailyReportStatistic: params => getDailyReportStatistic(dispatch, params),
});

const mapStateToProps = ({ usersReducer, masterData, dashboardData, settingData }) => ({
  usersReducer,
  masterData,
  dashboardData,
  settingData,
});

Dashboard.propTypes = {
  dailyReportStatistic: PropTypes.func,
  dashboardData: PropTypes.object,
  filter: PropTypes.object,
  history: PropTypes.object,
  masterData: PropTypes.object,
  resetListSummary: PropTypes.func,
  settingData: PropTypes.object,
  summaryConduct: PropTypes.func,
  summaryExpiredMembership: PropTypes.func,
  summaryRecurringMembership: PropTypes.func,
  summarySalesMove: PropTypes.func,
  usersReducer: PropTypes.object,
  windowsDisplay: PropTypes.object,
};

const shell = compose(connect(mapStateToProps, mapDispatchToProps));

export default shell(AuthenticationAccessPages(Dashboard));
