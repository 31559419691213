import { StyleSheet } from '@react-pdf/renderer';
import Themes from '../../../../../themes/Index';

const StylesJs = StyleSheet.create({
  containerLogo: { height: 16, width: '50%' },
  divider: {
    width: '100%',
    height: 0.5,
    backgroundColor: Themes.palette.black,
  },
  fontBold: {
    fontWeight: 'bold',
  },
  titleText: {
    color: Themes.palette.black,
    fontSize: 8,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
  },
  text: {
    fontSize: 6,
    color: Themes.palette.black,
    fontFamily: 'Roboto',
    lineHeight: 1.4,
  },
  textBold: {
    fontSize: 6,
    color: Themes.palette.black,
    fontFamily: 'Roboto',
    lineHeight: 1.4,
    fontWeight: 700,
  },
  textFooter: {
    fontSize: 6,
    color: Themes.palette.black,
    fontFamily: 'Roboto',
  },
});

export default StylesJs;
