import React from 'react';
import PropTypes from 'prop-types';
import { Tabs, Tab } from '@material-ui/core';
// style
import './TabNavigationStyle.scss';

const TabNavigation = props => {
  const { onChange, data, currentTab } = props;
  const [value, setValue] = React.useState(currentTab);

  React.useEffect(() => {
    const updateValue = async () => {
      setValue(currentTab);
    };
    updateValue();
  }, [currentTab]);

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
    onChange(newValue);
  };

  return (
    <Tabs
      value={value}
      indicatorColor="primary"
      textColor="primary"
      onChange={handleChangeTab}
      aria-label="disabled tabs example"
    >
      {data.map((item, index) => (
        <Tab key={index} label={`${item.name}`} />
      ))}
    </Tabs>
  );
};

TabNavigation.propTypes = {
  currentTab: PropTypes.number,
  data: PropTypes.array,
  onChange: PropTypes.func,
};

export default TabNavigation;
