import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import _ from 'lodash';
// Style
import './DetailsShippingStyle.scss';

const DetailsShipping = props => {
  const {
    orderData: { orderDetails },
  } = props;
  const shippingDetailsData = !_.isEmpty(orderDetails) ? orderDetails.shipping_details : {};

  const isAtStore = orderDetails.order_details && orderDetails.order_details.at_store;

  let wordingShipping = null;
  if (isAtStore) {
    wordingShipping = shippingDetailsData.shipping_title;
  } else if (
    !_.isEmpty(shippingDetailsData.shipping_method) &&
    !_.isEmpty(shippingDetailsData.shipping_title)
  ) {
    wordingShipping = `${_.capitalize(shippingDetailsData.shipping_method)} - ${
      shippingDetailsData.shipping_title
    }`;
  }

  let recipient = null;
  if(!_.isEmpty(shippingDetailsData)){
      recipient = shippingDetailsData.phone ? `${shippingDetailsData.shipping_receiver} - ${shippingDetailsData.phone}` : shippingDetailsData.shipping_receiver;
  }

  return (
    <Grid container className="pt-24" direction="column">
      <Grid item lg={12} md={12} className="pb-16">
        <label className="text-12 text-opacity-50 text-bold">
          {isAtStore ? `Store Details` : `Shipping Details`}
        </label>
      </Grid>
      <Grid item lg={12} md={12} className="order-card-details">
        <Grid container direction="column">
          {!_.isEmpty(wordingShipping) && (
            <Grid item lg={12} md={12}>
              <div className="pb-16">
                <label className="text-14 text-bold">{wordingShipping}</label>
              </div>
            </Grid>
          )}
          <Grid item lg={12} md={12}>
            <div className="flex-column">
              {recipient && (
                <label className="text-12 text-bold wrapping-container wrapping-container-break mb-8">
                  {recipient}
                </label>
              )}
              <label className="text-12 wrapping-container wrapping-container-break shipping-address">
                {shippingDetailsData.shipping_address}
              </label>
            </div>
          </Grid>
          {shippingDetailsData.shipping_notes && (
            <Grid item lg={12} md={12}>
              <div className="flex-column pt-8">
                <label className="text-12 text-bold text-opacity-50 mb-8">Notes</label>
                <label className="text-12 wrapping-container wrapping-container-break">
                  {shippingDetailsData.shipping_notes}
                </label>
              </div>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

DetailsShipping.propTypes = {
  orderData: PropTypes.object,
};

export default DetailsShipping;
