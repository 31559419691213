/* eslint-disable import/no-self-import */
/* eslint-disable import/no-unresolved */

switch (process.env.NODE_ENV) {
  case 'production':
    module.exports = require('./Environment.production');
    break;
  case 'development':
    module.exports = require('./Environment.staging');
    break;
  case 'test':
    module.exports = require('./Environment.staging');
    break;
  default:
    module.exports = require('./Environment.staging');
}
