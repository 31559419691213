import * as membershipAction from '../../redux/actions/MembershipAction';
import Api from '../../middleware/FetchMiddleware';
import { HttpAction } from '../../helpers/HttpHelper';
import { baseUrl } from '../../environments/Environment';

// #region membership request
export const getMembershipRequestPagination = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(membershipAction.getMembershipRequestPaginationRequest());
    Api.callApi(
      `${baseUrl}private/pos/v2/user-point-booster/list/requested?`,
      HttpAction.Get,
      params,
      {},
    )
      .then(response => {
        dispatch(membershipAction.getMembershipRequestPaginationSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(membershipAction.getMembershipRequestPaginationFail(e));
        reject(e);
      });
  });
};

export const getMembershipRequestDetails = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(membershipAction.getMembershipRequestDetailsRequest());
    Api.callApi(
      `${baseUrl}private/pos/v2/user-point-booster/requested/details?`,
      HttpAction.Get,
      params,
      {},
    )
      .then(response => {
        dispatch(membershipAction.getMembershipRequestDetailsSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(membershipAction.getMembershipRequestDetailsFail(e));
        reject(e);
      });
  });
};

export const setUpdateMembershipRequestApproveDecline = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(membershipAction.setUpdateMembershipRequestApproveDeclineRequest());
    Api.callApi(
      `${baseUrl}private/pos/v2/user-point-booster/requested/process`,
      HttpAction.Post,
      {},
      params,
    )
      .then(response => {
        dispatch(membershipAction.setUpdateMembershipRequestApproveDeclineSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(membershipAction.setUpdateMembershipRequestApproveDeclineFail(e));
        reject(e);
      });
  });
};

// #endregion

// #region membership purchase
export const getMembershipPurchasePagination = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(membershipAction.getMembershipPurchasePaginationRequest());
    Api.callApi(
      `${baseUrl}private/pos/v2/user-point-booster/list/purchased?`,
      HttpAction.Get,
      params,
      {},
    )
      .then(response => {
        dispatch(membershipAction.getMembershipPurchasePaginationSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(membershipAction.getMembershipPurchasePaginationFail(e));
        reject(e);
      });
  });
};

export const getMembershipPurchaseDetails = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(membershipAction.getMembershipPurchaseDetailsRequest());
    Api.callApi(
      `${baseUrl}private/pos/v2/user-point-booster/purchased/details/${params}`,
      HttpAction.Get,
      params,
      {},
    )
      .then(response => {
        dispatch(membershipAction.getMembershipPurchaseDetailsSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(membershipAction.getMembershipPurchaseDetailsFail(e));
        reject(e);
      });
  });
};

export const getMembershipPurchaseDetailsByPhone = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(membershipAction.getMembershipPurchaseDetailsByPhoneRequest());
    Api.callApi(`${baseUrl}private/pos/v2/orders/check-users-exists?`, HttpAction.Get, params, {})
      .then(response => {
        dispatch(membershipAction.getMembershipPurchaseDetailsByPhoneSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(membershipAction.getMembershipPurchaseDetailsByPhoneFail(e));
        reject(e);
      });
  });
};

export const getMembershipPurchaseRenewalList = (dispatch, id, params) => {
  return new Promise((resolve, reject) => {
    dispatch(membershipAction.getMembershipPurchaseRenewalListRequest());
    Api.callApi(`${baseUrl}private/pos/v2/user-point-booster/${id}`, HttpAction.Get, params, {})
      .then(response => {
        dispatch(membershipAction.getMembershipPurchaseRenewalListSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(membershipAction.getMembershipPurchaseRenewalListFail(e));
        reject(e);
      });
  });
};

export const getMembershipPurchaseNewList = (dispatch, id, params) => {
  return new Promise((resolve, reject) => {
    dispatch(membershipAction.getMembershipPurchaseNewListRequest());
    Api.callApi(
      `${baseUrl}private/pos/v2/point-booster/available-for-purchase?`,
      HttpAction.Get,
      params,
      {},
    )
      .then(response => {
        dispatch(membershipAction.getMembershipPurchaseNewListSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(membershipAction.getMembershipPurchaseNewListFail(e));
        reject(e);
      });
  });
};

export const setProcessCreateUserMembership = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(membershipAction.setProcessCreateUserMembershipRequest());
    Api.callApi(`${baseUrl}private/pos/v2/point-booster/purchase`, HttpAction.Post, {}, params)
      .then(response => {
        dispatch(membershipAction.setProcessCreateUserMembershipSuccess());
        return resolve(response);
      })
      .catch(e => {
        dispatch(membershipAction.setProcessCreateUserMembershipFail(e));
        reject(e);
      });
  });
};

export const getMembershipPurchaseReportExport = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(membershipAction.getMembershipPurchaseReportExportRequest());
    Api.callApi(
      `${baseUrl}/private/pos/v2/user-point-booster/list/purchased/download?`,
      HttpAction.Get,
      params,
      {},
    )
      .then(response => {
        dispatch(membershipAction.getMembershipPurchaseReportExportSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(membershipAction.getMembershipPurchaseReportExportFail(e));
        reject(e);
      });
  });
};
// #endregion

// #region membership change payment type
export const getMembershipDetailsById = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(membershipAction.getMembershipDetailsByIdRequest());
    Api.callApi(`${baseUrl}private/pos/v2/user-point-booster/details`, HttpAction.Get, params, {})
      .then(response => {
        dispatch(membershipAction.getMembershipDetailsByIdSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(membershipAction.getMembershipDetailsByIdFail(e));
        reject(e);
      });
  });
};

export const setMembershipRecurringById = (dispatch, id, params) => {
  return new Promise((resolve, reject) => {
    dispatch(membershipAction.setMembershipRecurringByIdRequest());
    Api.callApi(`${baseUrl}private/payment/recurring/${id}/payment`, HttpAction.Patch, {}, params)
      .then(response => {
        dispatch(membershipAction.setMembershipRecurringByIdSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(membershipAction.setMembershipRecurringByIdFail(e));
        reject(e);
      });
  });
};

export const stopMembershipRecurringById = (dispatch, id, params) => {
  return new Promise((resolve, reject) => {
    dispatch(membershipAction.stopMembershipRecurringByIdRequest());
    Api.callApi(`${baseUrl}private/payment/recurring/${id}/stop`, HttpAction.Post, {}, params)
      .then(response => {
        dispatch(membershipAction.stopMembershipRecurringByIdSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(membershipAction.stopMembershipRecurringByIdFail(e));
        reject(e);
      });
  });
};

export const unfreezeMembership = (dispatch, body) => {
  return new Promise((resolve, reject) => {
    dispatch(membershipAction.unfreezeMembershipRequest());
    Api.callApi(`${baseUrl}private/pos/user/booster/unfreeze`, HttpAction.Post, {}, body)
      .then(response => {
        dispatch(membershipAction.unfreezeMembershipSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(membershipAction.unfreezeMembershipFail(e));
        reject(e);
      });
  });
};
// #endregion
