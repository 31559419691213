import * as segmentAction from '../../redux/actions/SegmentAction';
import Api from '../../middleware/FetchMiddleware';
import { baseUrl } from '../../environments/Environment';
import { HttpAction } from '../../helpers/HttpHelper';

export const getSegmentPagination = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(segmentAction.getSegmentPaginationRequest());
    Api.callApi(`${baseUrl}private/pos/v2/user-segment`, HttpAction.Get, params, {})
      .then(response => {
        dispatch(segmentAction.getSegmentPaginationSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(segmentAction.getSegmentPaginationFail(e));
        reject(e);
      });
  });
};

export const getSegmentDetails = (dispatch, segmentId) => {
  return new Promise((resolve, reject) => {
    dispatch(segmentAction.getSegmentDetailsRequest());
    Api.callApi(`${baseUrl}private/pos/v2/user-segment/${segmentId}`, HttpAction.Get, {}, {})
      .then(response => {
        dispatch(segmentAction.getSegmentDetailsSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(segmentAction.getSegmentDetailsFail(e));
        reject(e);
      });
  });
};

export const getSegmentPreviewQuery = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(segmentAction.getSegmentPreviewQueryRequest());
    Api.callApi(`${baseUrl}private/pos/v2/user-segment/preview`, HttpAction.Post, {}, params)
      .then(response => {
        dispatch(segmentAction.getSegmentPreviewQuerySuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(segmentAction.getSegmentPreviewQueryFail(e));
        reject(e);
      });
  });
};

export const createSegment = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(segmentAction.createSegmentRequest());
    Api.callApi(`${baseUrl}private/pos/v2/user-segment`, HttpAction.Put, {}, params)
      .then(response => {
        dispatch(segmentAction.createSegmentSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(segmentAction.createSegmentFail(e));
        reject(e);
      });
  });
};

export const editSegment = (dispatch, params, segmentId) => {
  return new Promise((resolve, reject) => {
    dispatch(segmentAction.editSegmentRequest());
    Api.callApi(`${baseUrl}private/pos/v2/user-segment/${segmentId}`, HttpAction.Patch, {}, params)
      .then(response => {
        dispatch(segmentAction.editSegmentSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(segmentAction.editSegmentFail(e));
        reject(e);
      });
  });
};

export const deleteSegment = (dispatch, segmentId) => {
  return new Promise((resolve, reject) => {
    dispatch(segmentAction.deleteSegmentRequest());
    Api.callApi(`${baseUrl}private/pos/v2/user-segment/${segmentId}`, HttpAction.Delete, {}, {})
      .then(response => {
        dispatch(segmentAction.deleteSegmentSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(segmentAction.deleteSegmentFail(e));
        reject(e);
      });
  });
};
