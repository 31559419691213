import { StyleSheet } from '@react-pdf/renderer';
import Themes from '../../../../../themes/Index';

const StylesJs = StyleSheet.create({
  containerBarcode: { height: 60 },
  containerLogo: { height: 15 },
  title: {
    color: Themes.palette.rollingStone,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  containerDestination: {
    backgroundColor: Themes.palette.mysticLight,
    borderBottom: 1,
    borderBottomColor: Themes.palette.mysticLight,
  },
});

export default StylesJs;
