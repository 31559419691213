import React from 'react';
import { Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
// style
import './SkeletonScheduleDailySingleStyle.scss';

const SkeletonScheduleDailySingle = () => {
  return (
    <div className="row skeleton-container  calendar-container">
      <Grid container direction={'row'} spacing={3}>
        <Grid item className="custom-grid-col">
          <Skeleton
            variant="rect"
            width="100%"
            animation="wave"
            height={300}
            className="skeletonRounded"
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default SkeletonScheduleDailySingle;
