import React from 'react';
import { Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

const SkeletonPagination = () => {
  return (
    <Grid container spacing={2} direction="column" className="flex-wrap-unset">
      <Grid item lg md>
        <Grid container spacing={5} justify="space-between">
          <Grid item lg={7} md={7}>
            <Grid container spacing={2} justify="flex-start">
              <Grid item lg={4} md={4}>
                <Skeleton
                  variant="rect"
                  width="100%"
                  height={40}
                  animation="wave"
                  className="skeletonRounded"
                />
              </Grid>
              <Grid item lg={4} md={4}>
                <Skeleton
                  variant="rect"
                  width="100%"
                  height={40}
                  animation="wave"
                  className="skeletonRounded"
                />
              </Grid>
              <Grid item lg={4} md={4}>
                <Skeleton
                  variant="rect"
                  width="100%"
                  height={40}
                  animation="wave"
                  className="skeletonRounded"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={4} md={4}>
            <Grid container spacing={2} justify="flex-end">
              <Grid item lg={5} md={5}>
                <Skeleton
                  variant="rect"
                  width="100%"
                  height={40}
                  animation="wave"
                  className="skeletonRounded"
                />
              </Grid>
              <Grid item lg={5} md={5}>
                <Skeleton
                  variant="rect"
                  width="100%"
                  height={40}
                  animation="wave"
                  className="skeletonRounded"
                />
              </Grid>
              <Grid item lg={2} md={2}>
                <Skeleton
                  variant="rect"
                  width="100%"
                  height={40}
                  animation="wave"
                  className="skeletonRounded"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg md>
        <Grid container spacing={3} direction="column">
          <Grid item lg md>
            <Skeleton
              variant="rect"
              width="100%"
              height={200}
              animation="wave"
              className="skeletonRounded"
            />
          </Grid>
          <Grid item lg md>
            <Skeleton
              variant="rect"
              width="100%"
              height={200}
              animation="wave"
              className="skeletonRounded"
            />
          </Grid>
          <Grid item lg md>
            <Skeleton
              variant="rect"
              width="100%"
              height={200}
              animation="wave"
              className="skeletonRounded"
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SkeletonPagination;
