import React from 'react';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import PropTypes from 'prop-types';
// component
import { TooltipChatCustom, TooltipChatDailyCheckIn } from './components/Index';
// Style
import './ChartAreaGraphicStyle.scss';

const ChartAreaGraphic = props => {
  const { dataArray, dataArrayLine, tooltipCustom, height } = props;
  let renderElementTooltip = null;

  switch (tooltipCustom) {
    case 'tooltip-conduct':
      renderElementTooltip = <TooltipChatCustom />;
      break;
    case 'tooltip-daily-check-in':
      renderElementTooltip = <TooltipChatDailyCheckIn />;
      break;
    default:
      break;
  }

  return (
    <ResponsiveContainer width="100%" height={height}>
      <AreaChart
        data={dataArray}
        margin={{
          top: 10,
          right: 8,
          left: 15,
          bottom: 8,
        }}
      >
        <defs>
          {dataArrayLine.map((item, index) => (
            <linearGradient key={index} id={`${item.id}${index + 1}`} x1="0" y1="0" x2="0" y2="1">
              <stop offset="40%" stopColor={item.gradientColor} />
              {/* <stop offset="80%" stopColor="#ffffff" /> */}
              <stop offset="80%" stopColor="rgba(255, 255, 255, 1)" />
            </linearGradient>
          ))}
        </defs>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip content={renderElementTooltip} />
        <Legend verticalAlign="top" iconSize={8} iconType={'circle'} height={36} />
        {dataArrayLine.map((item, index) => (
          <Area
            key={index}
            type="monotone"
            dataKey={item.id}
            stackId="1"
            stroke={item.lineColor}
            dot={false}
            fill={`url(#${item.id}${index + 1})`}
          />
        ))}
      </AreaChart>
    </ResponsiveContainer>
  );
};

ChartAreaGraphic.propTypes = {
  dataArray: PropTypes.array,
  dataArrayLine: PropTypes.array,
  height: PropTypes.number,
  tooltipCustom: PropTypes.string,
};

export default ChartAreaGraphic;
