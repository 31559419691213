import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Grid, Breadcrumbs, Link, FormControl, FormLabel, IconButton } from '@material-ui/core';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { compose } from 'redux';
// Components
import {
  RadioInputImage,
  ButtonMain,
  TextInput,
  GridRowTableOneColumn,
  AuthenticationAccessPages,
  PrevStateValue,
  SkeletonDetailsV03,
  SnackBarSimple,
  SelectInputMain,
  LabelStatusPayment,
  ButtonIconMain,
} from '../../../../components/Index';
import {
  CartItemBooking,
  CustomerItemBooking,
  DetailsAppointment,
  ModalReschedule,
  ModalOrderQris,
  ModalImagePreview,
  ModalUpdateOrder,
  ModalEditData,
} from './components/Index';
// Style
import './LabDetailsStyle.scss';
// Helper
import {
  MasterDataHelper,
  CommonHelper,
  PermissionModule,
  PermissionPage,
  PermissionAccess,
  HttpStatusCode,
  serviceLocationCode,
  OrderHelper,
} from '../../../../helpers/Index';
import {
  paymentMethodOptionCode,
  orderStatusCode,
  statusPaymentCode,
} from '../../../../helpers/MasterDataHelper';
// Action
import {
  getOrderDetails,
  cancelOrder,
  checkPromoCode,
  saveOpenBill,
  submitOpenBill,
  changeOrderPayment,
} from '../../../../services/api/OrdersApi';
// Assets
import { Icons } from '../../../../assets/Index';

const bankTransferOption = MasterDataHelper.bankTransferOption;

const initialForm = {
  paymentMethod: paymentMethodOptionCode.bank_transfer,
  bank: bankTransferOption[0],
  promoCode: '',
  transactionReference: undefined,
};

const initialValidation = {
  promoCode: { isError: false, errorMessage: '' },
};

const optionToast = {
  vertical: 'top',
  horizontal: 'right',
};

class LabDetails extends React.Component {
  constructor(props) {
    super(props);

    props.checkUserAccessPermission(
      PermissionModule.Orders,
      PermissionPage.List,
      PermissionAccess.View,
    );

    const {
      match: { params },
    } = this.props;

    this.state = {
      salesOrderId: CommonHelper.decryptObject(params.sales_order_id).salesOrderId,
      toastInformation: {
        isOpen: false,
        message: '',
        snackbarType: 'warning',
      },
      isFetching: true,
      detailOrderData: {},
      paymentMethodOption: [],
      isSuccess: '',
      isValidPromoCode: false,
      photoPreview: null,
      editType: '',
      selectedCustomer: {},
      form: CommonHelper.objectCloning(initialForm),
      validation: CommonHelper.objectCloning(initialValidation),
      isModalRescheduleOpen: false,
      isOpenQrisModal: false,
      isOpenImagePreviewModal: false,
      isOpenCancelOrderModal: false,
      isOpenChangeOrderPaymentModal: false,
      isOpenEditCompletedModal: false,
    };
    this.searchPromoCodeDebounce = _.debounce(this.searchPromoCodeDebounce, 400);
    this.invoiceRef = React.createRef();
  }

  componentDidMount() {
    const { getDetails } = this;
    getDetails();
  }

  getDetails = async () => {
    const { getOrder } = this.props;
    const { salesOrderId, form } = this.state;

    try {
      const param = {
        sales_order_id: salesOrderId,
      };
      const { data } = await getOrder(param);

      const tempOptionPayment = MasterDataHelper.paymentMethodOption.find(item => {
        if (data.order_details.payment_type) {
          return item.value === data.order_details.payment_type;
        }
        return item.value === form.paymentMethod;
      });
      let tempBankOptionPayment = form.bank;
      if (data.order_details.payment_type === paymentMethodOptionCode.bank_transfer) {
        tempBankOptionPayment = bankTransferOption.find(item => {
          return item.value === data.payment_details.bank;
        });
      }
      let defaultOptionPayment = tempOptionPayment;
      const promoCode =
        data.order_summary.price_discount > 0 || data.order_summary.promo_code
          ? data.order_summary.promo_code
          : '';
      const isSuccess =
        data.order_summary.price_discount > 0 || data.order_summary.promo_code ? 'success' : '';

      defaultOptionPayment = MasterDataHelper.paymentMethodOption.filter(
        item =>
          item.value === paymentMethodOptionCode.fit_point ||
          item.value === paymentMethodOptionCode.bank_transfer ||
          item.value === paymentMethodOptionCode.credit_card ||
          item.value === paymentMethodOptionCode.qris ||
          item.value === paymentMethodOptionCode.corporate_deal ||
          item.value === paymentMethodOptionCode.xendit_invoice,
      );

      const isHomeService =
        data.appointment_details.service_location === serviceLocationCode.AtHome;
      if (!isHomeService) {
        defaultOptionPayment.push(
          MasterDataHelper.paymentMethodOption.find(x => x.value === paymentMethodOptionCode.edc),
        );
        defaultOptionPayment.push(
          MasterDataHelper.paymentMethodOption.find(x => x.value === paymentMethodOptionCode.cash),
        );
      }

      const isMedicOrder = data.order_details.order_type === 'medic';
      if (isMedicOrder) {
        defaultOptionPayment.push(
          MasterDataHelper.paymentMethodOption.find(
            x => x.value === paymentMethodOptionCode.traveloka,
          ),
          MasterDataHelper.paymentMethodOption.find(x => x.value === paymentMethodOptionCode.tiket),
          MasterDataHelper.paymentMethodOption.find(
            x => x.value === paymentMethodOptionCode.tokopedia,
          ),
          MasterDataHelper.paymentMethodOption.find(
            x => x.value === paymentMethodOptionCode.blibli,
          ),
        );
      }

      this.setState(
        {
          isFetching: false,
          detailOrderData: data,
          paymentMethodOption: defaultOptionPayment,
          form: {
            ...form,
            paymentMethod: tempOptionPayment.value,
            bank: tempBankOptionPayment,
            promoCode,
            transactionReference: data.payment_details.transaction_reference,
          },
          isValidPromoCode:
            data.order_summary.price_discount > 0 || data.payment_details.is_cashback,
          isSuccess,
        },
        () => {
          if (!_.isEmpty(this.state.form.promoCode)) {
            this.searchPromoCodeDebounce();
          }
        },
      );
    } catch (error) {
      this.setState({
        isFetching: false,
      });
      const errors = error.data;
      this.processMessage(errors.messages, 'error');
    }
  };

  searchPromoCodeDebounce = async () => {
    const { checkPromo } = this.props;
    const { validation, form } = this.state;
    const params = this.getPayloadPromoCode();
    const message = CommonHelper.objectCloning(initialValidation.promoCode);

    if (_.isEmpty(form.promoCode)) {
      this.setState({
        isValidPromoCode: false,
        isSuccess: '',
        validation: {
          ...validation,
          promoCode: { isError: false, errorMessage: '' },
        },
      });
      return;
    }

    try {
      const { data } = await checkPromo(params);
      const promoData = data;
      const promoMessage =
        promoData.promo_code_cashback_type_id === 2
          ? `Potential cashback of ${CommonHelper.formatCurrency(
              promoData.cashback_amount,
            )} FIT Points`
          : `Potential discount of IDR ${CommonHelper.formatCurrency(promoData.cashback_amount)}`;
      message.errorMessage = `Yay! promotion applied. ${promoMessage}`;

      this.setState({
        isValidPromoCode: true,
        isSuccess: 'success',
        validation: {
          ...validation,
          promoCode: message,
        },
      });
    } catch (e) {
      if (e.status === HttpStatusCode.NotFound) {
        message.isError = true;
        message.errorMessage = `${e.data.messages}, you will get no benefit if Process this transaction`;

        this.setState({
          validation: {
            ...validation,
            promoCode: message,
          },
          isValidPromoCode: false,
          isSuccess: 'error',
        });
      }
    }
  };

  onHandleCancelOrder = async () => {
    const { salesOrderId } = this.state;
    const { processCancelOrder } = this.props;
    try {
      await processCancelOrder(salesOrderId);
      this.getDetails();
      this.processMessage('Order Successfully Canceled', 'success');
      this.handleCloseModalCancelOrder();
    } catch (error) {
      const errors = error.data;
      this.processMessage(errors.messages, 'error');
      this.handleCloseModalCancelOrder();
    }
  };

  onHandleChangeOrderPayment = async () => {
    const { salesOrderId } = this.state;
    const { processChangeOrderPayment } = this.props;
    try {
      await processChangeOrderPayment(salesOrderId);
      this.setState(
        {
          paymentMethodOption: [],
        },
        () => {
          this.getDetails();
          this.processMessage(
            'Order Successfully Updated to Draft, please complete payment',
            'success',
          );
          this.handleCloseModalChangePayment();
        },
      );
    } catch (error) {
      const errors = error.data;
      this.processMessage(errors.messages, 'error');
      this.handleCloseModalChangePayment();
    }
  };

  handleSubmitDraft = async type => {
    const {
      salesOrderId,
      form,
      validation: { promoCode },
      detailOrderData,
    } = this.state;
    const { processSaveOpenBill, processSubmitOpenBill, history } = this.props;
    const isHomeService =
      detailOrderData.appointment_details.service_location === serviceLocationCode.AtHome;

    const serviceDetails = {
      service_location: detailOrderData.appointment_details.service_location,
      date: CommonHelper.dateTimeParseNewFormat(
        detailOrderData.appointment_details.date,
        'YYYY-MM-DD',
      ),
      time: detailOrderData.appointment_details.start_time,
      branch_id: detailOrderData.branch_details.branch_id,
    };
    const apdProduct = detailOrderData.order_summary.product_details.find(item => {
      return item.product_sku === 'fitco-medic-apd-fee';
    });
    const items = detailOrderData.order_summary.product_details.filter(item => {
      return item.product_sku !== 'fitco-medic-apd-fee';
    });
    let userDetails = {
      user_id: detailOrderData.customer_details.customer_id,
    };
    const modifyPayment = this.getPaymentTypePayload();

    const orderItems = OrderHelper.formatItemSubmitLab(items);

    if (isHomeService) {
      userDetails = {
        ...userDetails,
        address: {
          ...detailOrderData.shipping_details.shipping_address_info,
        },
        notes: detailOrderData.shipping_details.shipping_notes,
      };

      if (apdProduct) {
        const apdItems = {
          details: {
            price: apdProduct.item_price,
          },
          qty: 1,
          product_id: apdProduct.product_id,
        };
        if (apdProduct.item_price > 0) {
          orderItems.push(apdItems);
        }
      }
    }

    const body = {
      service_details: serviceDetails,
      items: orderItems,
      order_type: detailOrderData.order_details.order_type,
      user_details: userDetails,
      payment_data: modifyPayment,
      promo_code: form.promoCode,
    };

    if (isHomeService) {
      const shippingDetails = {
        shipping_method: detailOrderData.shipping_details.shipping_method,
        shipping_title: detailOrderData.shipping_details.shipping_title,
        shipping_cost: detailOrderData.order_summary.shipping_fee,
      };
      Object.assign(body, {
        shipping_details: shippingDetails,
      });
    }

    if (promoCode.isError) {
      this.processMessage('Promo Code not Valid, Check your Promo Code', 'warning');
      return;
    }

    try {
      switch (type) {
        case 'save':
          await processSaveOpenBill(salesOrderId, body);
          break;
        default:
          await processSubmitOpenBill(salesOrderId, body);
          break;
      }
      this.processMessage('Order Successfully Submitted', 'success');
      setTimeout(async () => {
        await history.push('/medic/lab');
      }, 2000);
    } catch (error) {
      const errors = error.data;
      this.processMessage(errors.messages, 'error');
    }
  };

  getPaymentTypePayload = () => {
    const { form, detailOrderData } = this.state;
    const customerDetailsData = !_.isEmpty(detailOrderData) ? detailOrderData.customer_details : {};
    const branchDetailsData = !_.isEmpty(detailOrderData) ? detailOrderData.branch_details : {};
    let modifyPayment = {};

    switch (form.paymentMethod) {
      case paymentMethodOptionCode.bank_transfer:
        modifyPayment = {
          payment_type: form.paymentMethod,
          bank: form.bank.value,
          transaction_reference: form.transactionReference,
        };
        break;
      case paymentMethodOptionCode.edc:
        modifyPayment = {
          payment_type: form.paymentMethod,
          bank: `${branchDetailsData.merchant_id}-${branchDetailsData.merchant_name}`,
          account_number: `${branchDetailsData.branch_id}-${branchDetailsData.name}`,
          name: customerDetailsData.customer_name,
          transaction_reference: form.transactionReference,
        };
        break;
      case paymentMethodOptionCode.credit_card:
        modifyPayment = {
          payment_type: form.paymentMethod,
          payment_channel: 'xendit',
          payment_status: 0,
          transaction_reference: form.transactionReference,
        };
        break;
      default:
        modifyPayment = {
          payment_type: form.paymentMethod,
          transaction_reference: form.transactionReference,
        };
        break;
    }

    return modifyPayment;
  };

  getPayloadPromoCode = () => {
    const { detailOrderData, form } = this.state;
    const itemList = [];
    const apdProductFee = detailOrderData.order_summary.product_details.find(item => {
      return item.product_sku === 'fitco-medic-apd-fee';
    });

    let items = detailOrderData.order_summary.product_details;
    if (apdProductFee && apdProductFee.item_price <= 0) {
      items = detailOrderData.order_summary.product_details.filter(item => {
        return item.product_sku !== apdProductFee.product_sku;
      });
    }

    items.forEach(item => {
      itemList.push({
        product_id: item.product_id,
        qty: item.quantity,
      });
    });

    const payload = {
      data: {
        user_id: detailOrderData.customer_details.customer_id,
        promo_code: form.promoCode,
        payment_type: form.paymentMethod,
        items: !_.isEmpty(itemList) ? itemList : null,
      },
    };
    return payload;
  };

  handleButtonClickReschedule = () => {
    this.setState({
      isModalRescheduleOpen: true,
    });
  };

  handleCloseModalCancelOrder = () => {
    this.setState({
      isOpenCancelOrderModal: false,
    });
  };

  handleCloseModalReschedule = () => {
    this.setState({
      isModalRescheduleOpen: false,
    });
  };

  handleSuccessReschedule = async messages => {
    this.setState({ isModalRescheduleOpen: false });
    await this.processMessage(messages, 'success');
    await this.getDetails();
  };

  handleSuccessEditData = async messages => {
    this.setState({ isOpenEditCompletedModal: false });
    await this.processMessage(messages, 'success');
    await this.getDetails();
  };

  handleOpenModalQris = () => {
    this.setState({
      isOpenQrisModal: true,
    });
  };

  handleCloseModalQris = () => {
    this.setState({
      isOpenQrisModal: false,
    });
  };

  handleOpenModalImagePreview = photo => {
    this.setState({
      photoPreview: photo,
      isOpenImagePreviewModal: true,
    });
  };

  handleCloseModalImagePreview = () => {
    this.setState({
      photoPreview: null,
      isOpenImagePreviewModal: false,
    });
  };

  handleCloseModalChangePayment = () => {
    this.setState({
      isOpenChangeOrderPaymentModal: false,
    });
  };

  handleCloseModalEditData = () => {
    this.setState({
      isOpenEditCompletedModal: false,
      editType: '',
    });
  };

  handleRemoveCustomer = async sorting => {
    const { detailOrderData } = this.state;
    let currentIdentityDetails = [...detailOrderData.identity_details];
    let currentBookProduct = [...detailOrderData.order_summary.product_details];
    currentIdentityDetails = detailOrderData.identity_details.filter((item, index) => {
      return index !== sorting;
    });
    currentBookProduct = detailOrderData.order_summary.product_details.filter((item, index) => {
      return index !== sorting;
    });
    this.setState({
      detailOrderData: {
        ...detailOrderData,
        identity_details: currentIdentityDetails,
        order_summary: {
          ...detailOrderData.order_summary,
          product_details: currentBookProduct,
        },
      },
    });
  };

  handleSelectCustomer = item => {
    this.setState({
      isOpenEditCompletedModal: true,
      editType: 'bookingDetails',
      selectedCustomer: item,
    });
  };

  handleUpdatePhoto = async (data, sorting) => {
    const { detailOrderData } = this.state;
    let currentIdentityDetails = [...detailOrderData.identity_details];
    let currentBookProduct = [...detailOrderData.order_summary.product_details];
    currentIdentityDetails = currentIdentityDetails.map((item, index) => {
      let list = item;
      if (index === sorting) {
        list = data;
      }

      return list;
    });
    currentBookProduct = currentBookProduct.map((item, index) => {
      let list = item;
      if (index === sorting) {
        list = { ...list, details: data };
      }

      return list;
    });

    this.setState({
      detailOrderData: {
        ...detailOrderData,
        identity_details: currentIdentityDetails,
        order_summary: {
          ...detailOrderData.order_summary,
          product_details: currentBookProduct,
        },
      },
    });
  };

  handleSelectPaymentMethod = value => {
    const { form } = this.state;
    const promoCode = CommonHelper.objectCloning(form.promoCode);

    this.setState(
      {
        form: { ...form, paymentMethod: value },
      },
      () => {
        if (value === paymentMethodOptionCode.bank_transfer) {
          const valueChange = bankTransferOption[0];
          this.handleChangeBankTransfer(valueChange);
        }
        this.handleChangePromoCodeEntry(promoCode);
      },
    );
  };

  handleChangeBankTransfer = value => {
    const { form } = this.state;
    this.setState({ form: { ...form, bank: value } });
  };

  handleChangeTransactionReference = value => {
    const { form } = this.state;

    this.setState({
      form: { ...form, transactionReference: value },
    });
  };

  handleChangePromoCodeEntry = value => {
    const { form } = this.state;

    this.setState(
      {
        form: { ...form, promoCode: value },
      },
      () => {
        this.searchPromoCodeDebounce();
      },
    );
  };

  handleTransportPriceUpdated = price => {
    const { detailOrderData } = this.state;

    this.setState({
      detailOrderData: {
        ...detailOrderData,
        order_summary: {
          ...detailOrderData.order_summary,
          shipping_fee: price,
        },
      },
    });
  };

  handleClick = (event, value) => {
    const { history } = this.props;
    event.preventDefault();
    history.push(value);
  };

  handleButtonCancel = () => {
    this.props.history.goBack();
  };

  handleCloseToast = () => {
    const { toastInformation } = this.state;
    this.setState({ toastInformation: { ...toastInformation, isOpen: false } });
  };

  processMessage(messages, type) {
    const convertedMessage = CommonHelper.generateMessage(messages);

    const paramInformation = {
      isOpen: true,
      message: convertedMessage,
      snackbarType: type,
    };

    this.setState({
      toastInformation: paramInformation,
    });
  }

  renderCancelButton = () => {
    const {
      orderData: { orderDetails },
    } = this.props;
    const orderDetailsData = !_.isEmpty(orderDetails) ? orderDetails.order_details : {};
    const renderCancel = orderDetailsData.order_status === orderStatusCode.Draft;
    if (!renderCancel) {
      return null;
    }

    return (
      <Grid item className="mr-8">
        <ButtonMain
          type="negative"
          size="md"
          labelText="Cancel Order"
          onClick={() => {
            this.setState({ isOpenCancelOrderModal: true });
          }}
          requiredPermission={`${PermissionModule.Orders}.${PermissionPage.List}.${PermissionAccess.Delete}`}
        />
      </Grid>
    );
  };

  renderUpdatePaymentButton = () => {
    const {
      orderData: { orderDetails },
    } = this.props;
    const orderDetailsData = !_.isEmpty(orderDetails) ? orderDetails.order_details : {};

    const isDraft = orderDetailsData.order_status === orderStatusCode.Draft;
    if (isDraft) {
      return null;
    }

    const paymentStatus = orderDetailsData.payment_status;

    let renderChangePaymentButton = false;

    switch (paymentStatus) {
      case statusPaymentCode.Awaiting_Token:
      case statusPaymentCode.Token_Generated:
      case statusPaymentCode.Virtual_Bank_Account_Generated:
      case statusPaymentCode.Credit_Card_Charged:
      case statusPaymentCode.GoPay_QR_And_Deeplink_Generated:
      case statusPaymentCode.Pending:
      case statusPaymentCode.Expired:
      case statusPaymentCode.Declined:
      case statusPaymentCode.Cancelled:
        renderChangePaymentButton = true;
        break;
      default:
        renderChangePaymentButton = false;
        break;
    }

    if (!renderChangePaymentButton) {
      return null;
    }

    return (
      <Grid item className="mr-8">
        <ButtonMain
          type="light"
          size="md"
          labelText="Change Payment"
          onClick={() => {
            this.setState({ isOpenChangeOrderPaymentModal: true });
          }}
          requiredPermission={`${PermissionModule.Orders}.${PermissionPage.List}.${PermissionAccess.Delete}`}
        />
      </Grid>
    );
  };

  renderOrderCard = () => {
    const {
      orderData: { orderDetails },
    } = this.props;
    const orderDetailsData = !_.isEmpty(orderDetails) ? orderDetails.order_details : {};
    const isDraft = orderDetailsData.order_status === orderStatusCode.Draft;

    return (
      <Grid item lg md>
        <Grid container direction="row">
          <Grid item className="flex-column order-info">
            <label className="text-12 text-opacity-50">Order ID</label>
            <label className="text-12 text-bold pt-4">{orderDetailsData.sales_order_id}</label>
          </Grid>
          <Grid item className="flex-column order-info">
            <label className="text-12 text-opacity-50">Order Number</label>
            <label className="text-12 text-bold pt-4">{orderDetailsData.order_no}</label>
          </Grid>
          <Grid item className="flex-column order-info">
            <label className="text-12 text-opacity-50">Order Type</label>
            <label className="text-12 text-bold pt-4">
              {MasterDataHelper.getOrderTypeDescription(orderDetailsData.order_type)}
            </label>
          </Grid>
          {isDraft && (
            <Grid item className="flex-column order-info">
              <label className="text-12 text-opacity-50">Order Status</label>
              <label className="text-12 text-bold pt-4">
                {orderDetailsData.order_status_title}
              </label>
            </Grid>
          )}
          <Grid item className="flex-column order-info">
            <label className="text-12 text-opacity-50">Source</label>
            <label className="text-12 text-bold pt-4">
              {_.capitalize(orderDetailsData.transaction_source)}
            </label>
          </Grid>
          {orderDetailsData.external_order_id && (
            <Grid item className="flex-column order-info">
              <label className="text-12 text-opacity-50">W-Order ID</label>
              <label className="text-12 text-bold pt-4">{orderDetailsData.external_order_id}</label>
            </Grid>
          )}
          {orderDetailsData.tracking_number && (
            <Grid item className="flex-column order-info">
              <label className="text-12 text-opacity-50">Tracking Number</label>
              <label className="text-12 text-bold pt-4">{orderDetailsData.tracking_number}</label>
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  };

  renderOrderInformation = () => {
    const {
      orderData: { promoCodeDetails },
    } = this.props;
    const { detailOrderData, isValidPromoCode } = this.state;
    const orderDetailsData = !_.isEmpty(detailOrderData) ? detailOrderData.order_details : {};
    const orderSummaryData = !_.isEmpty(detailOrderData) ? detailOrderData.order_summary : {};
    const paymentDetailsData = !_.isEmpty(detailOrderData) ? detailOrderData.payment_details : {};
    const orderItems = orderSummaryData.product_details || [];
    const isDraft = orderDetailsData.order_status === orderStatusCode.Draft;
    const showQRIS =
      orderDetailsData.payment_type === paymentMethodOptionCode.qris &&
      !_.isEmpty(paymentDetailsData.qr_string);

    const renderValuePayment = () => {
      let renderElement = null;

      switch (orderDetailsData.payment_type) {
        case paymentMethodOptionCode.fit_point:
          renderElement = <img src={Icons.fitPoints} alt="Fit Points" />;
          break;
        default:
          break;
      }

      return renderElement;
    };

    const getItemPrice = item => {
      return item.item_price;
    };

    const getGrandTotal = () => {
      if (!isDraft) {
        return orderSummaryData.grand_total;
      }

      if (isValidPromoCode) {
        if (!_.isEmpty(promoCodeDetails)) {
          const total = promoCodeDetails.totalPriceAfterReward + +orderSummaryData.shipping_fee;
          return total;
        }
        return orderSummaryData.grand_total;
      }

      let grandTotal = 0;
      orderItems.forEach(item => {
        const itemPrice = getItemPrice(item);
        const itemSubtotal = itemPrice * item.quantity;

        grandTotal += itemSubtotal;
      });

      if (orderSummaryData.shipping_fee > 0) {
        grandTotal += +orderSummaryData.shipping_fee;
      }

      return grandTotal;
    };
    const orderGrandTotal = getGrandTotal();

    return (
      <Grid container className="order-card-details" direction="row" wrap={'nowrap'}>
        <Grid item lg={10} md={8}>
          <Grid container direction="column">
            <Grid item className="pt-16">
              <Grid container spacing={2} direction="row">
                <Grid item lg={3} md={6} className="order-items-text">
                  <label className="text-12 text-opacity-50">Transaction Date</label>
                  <label className="text-12 pt-4 text-bold">
                    {CommonHelper.dateTimeParseNewFormat(
                      orderDetailsData.order_date,
                      'DD MMM YYYY | LT',
                    )}
                  </label>
                </Grid>
                <Grid item lg={3} md={6} className="order-items-text">
                  <label className="text-12 text-opacity-50">Payment Method</label>
                  <label className="text-12 pt-4 text-bold">
                    {orderDetailsData.payment_type_title}
                  </label>
                </Grid>
                {showQRIS && (
                  <Grid item lg={3} md={6} className="order-items-text">
                    <div className="text-right">
                      <IconButton size="small" onClick={this.handleOpenModalQris}>
                        <i className="ic-ffo-qr-code container-icon-prefix size-20 text-black qr-code" />
                      </IconButton>
                    </div>
                  </Grid>
                )}
                {orderDetailsData.payment_type === paymentMethodOptionCode.bank_transfer && (
                  <Grid item lg={3} md={6} className="order-items-text">
                    <label className="text-12 text-opacity-50">VA Number</label>
                    <label className="text-12 pt-4 text-bold">
                      {orderDetailsData.va_number || '-'}
                    </label>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={2} md={4}>
          <Grid container direction="row" className="order-amount-section">
            <Grid item lg={12} md={12}>
              <Grid
                container
                direction="column"
                justify="center"
                className="order-items-amount-text"
              >
                <Grid item lg md className="vertical-align-center">
                  <span className="flex-row text-center">
                    {renderValuePayment()}
                    <label className="text-16 text-bold currency">
                      {orderDetailsData.total_paid !== 0
                        ? `IDR ${CommonHelper.formatCurrency(orderGrandTotal)}`
                        : 'Free'}
                    </label>
                  </span>
                </Grid>
                <Grid item lg md className="pt-4 vertical-align-start">
                  <LabelStatusPayment status={orderDetailsData.payment_status} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  renderContactPerson = () => {
    const {
      orderData: { orderDetails },
    } = this.props;
    const customerDetailsData = !_.isEmpty(orderDetails) ? orderDetails.customer_details : {};
    const orderDetailsData = !_.isEmpty(orderDetails) ? orderDetails.order_details : {};
    const isComplete = orderDetailsData.order_status === orderStatusCode.Complete;

    return (
      <>
        <FormControl component="fieldset" fullWidth margin="normal">
          <div className="row-between mb-10">
            <FormLabel component="label" className="text-12 filed-title">
              Contact Person
            </FormLabel>
            {isComplete && (
              <ButtonIconMain
                icon="ic-ffo-edit"
                type="primary"
                size="sm"
                onClick={() => {
                  this.setState({ isOpenEditCompletedModal: true, editType: 'contactPerson' });
                }}
                tooltip="Edit"
              />
            )}
          </div>
          <Grid
            container
            direction="column"
            className="container-row-table details flex-wrap-unset"
          >
            <GridRowTableOneColumn
              customElementInitial={<label className="text-14 title">Name</label>}
              customElementColumn={
                <label className="text-14">{customerDetailsData.customer_name}</label>
              }
              columnLeft={5}
              columnRight={7}
            />
            <GridRowTableOneColumn
              customElementInitial={<label className="text-14 title">Email</label>}
              customElementColumn={
                <label className="text-14">{customerDetailsData.customer_email}</label>
              }
              columnLeft={5}
              columnRight={7}
            />
            <GridRowTableOneColumn
              customElementInitial={<label className="text-14 title">Phone Number</label>}
              customElementColumn={
                <label className="text-14">{customerDetailsData.customer_phone}</label>
              }
              columnLeft={5}
              columnRight={7}
            />
            <GridRowTableOneColumn
              customElementInitial={<label className="text-14 title">Gender</label>}
              customElementColumn={
                <label className="text-14">{customerDetailsData.customer_gender}</label>
              }
              columnLeft={5}
              columnRight={7}
            />
            <GridRowTableOneColumn
              customElementInitial={<label className="text-14 title">Order Type</label>}
              customElementColumn={
                <label className="text-14 capitalize">{customerDetailsData.order_type}</label>
              }
              columnLeft={5}
              columnRight={7}
            />
          </Grid>
        </FormControl>
      </>
    );
  };

  renderOrderSummary = () => {
    const { orderData } = this.props;
    const { form, isValidPromoCode } = this.state;
    const { detailOrderData } = this.state;

    const orderDetailsData = !_.isEmpty(orderData.orderDetails)
      ? orderData.orderDetails.order_details
      : {};
    const isDraft = orderDetailsData.order_status === orderStatusCode.Draft;

    return (
      <CartItemBooking
        form={form}
        orderData={orderData}
        detailOrderData={detailOrderData}
        isValidPromoCode={isValidPromoCode}
        isDraft={isDraft}
        onPriceTransportUpdated={this.handleTransportPriceUpdated}
      />
    );
  };

  renderLinkXendit = () => {
    const {
      orderData: { orderDetails },
    } = this.props;

    return (
      <Grid item lg={12} md={12} className="xendit-invoice mt-24 mb-18">
        <Grid container direction="row" justify="space-between" alignItems="center" spacing={3}>
          <Grid item lg={10} md={10}>
            <div className="flex-column">
              <label className="text-14 text-rolling-stone pb-8">
                Please send this payment link to the customer
              </label>
              <input
                ref={this.invoiceRef}
                type="text"
                value={orderDetails.order_details.va_number || '-'}
                readOnly
                className="text-14 text-bold pt-8"
              />
            </div>
          </Grid>
          <Grid item lg={2} md={2}>
            <ButtonMain
              type="primary"
              size="md"
              labelText="Copy Link"
              onClick={() => {
                this.invoiceRef.current.select();
                document.execCommand('copy');
              }}
              disabled={!orderDetails.order_details.va_number}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  };

  renderAdditionalButton = () => {
    const {
      orderData: { fetching },
    } = this.props;
    return (
      <Grid item lg={12} md={12} sm={12} className="mt-24">
        <Grid container justify="flex-end">
          <Grid item lg={4} md={5}>
            <Grid container justify="space-around">
              <Grid item lg={6} md={6} className="pl-8">
                <ButtonMain
                  type="outlined-primary"
                  size="xl"
                  labelText="Save"
                  isLoading={fetching}
                  onClick={() => {
                    this.handleSubmitDraft('save');
                  }}
                  requiredPermission={`${PermissionModule.Orders}.${PermissionPage.List}.${PermissionAccess.Update}`}
                />
              </Grid>
              <Grid item lg={6} md={6} className="pl-8">
                <ButtonMain
                  type="primary"
                  size="xl"
                  labelText="Submit"
                  isLoading={fetching}
                  onClick={() => {
                    this.handleSubmitDraft('submit');
                  }}
                  requiredPermission={`${PermissionModule.Orders}.${PermissionPage.List}.${PermissionAccess.Add}`}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  render() {
    const {
      detailOrderData,
      form,
      validation,
      paymentMethodOption,
      photoPreview,
      isSuccess,
      isValidPromoCode,
      editType,
      selectedCustomer,
      salesOrderId,
      toastInformation,
      isFetching,
      isModalRescheduleOpen,
      isOpenQrisModal,
      isOpenImagePreviewModal,
      isOpenCancelOrderModal,
      isOpenChangeOrderPaymentModal,
      isOpenEditCompletedModal,
    } = this.state;
    const {
      orderData: { orderDetails },
    } = this.props;
    const orderDetailsData = !_.isEmpty(orderDetails) ? orderDetails.order_details : {};
    const paymentDetailsData = !_.isEmpty(orderDetails) ? orderDetails.payment_details : {};
    const isDraft = orderDetailsData.order_status === orderStatusCode.Draft;
    const isComplete = orderDetailsData.order_status === orderStatusCode.Complete;
    const successIcon = 'ic-ffi-check-mark';

    return (
      <div>
        <Helmet title={`FITCO | Lab Details ${orderDetails ? orderDetails.sales_order_id : ''} `} />
        <div className="container-page-order-details scroll-container-invisible">
          <div className="container-page-scrolling-area">
            <Grid container direction="column">
              <Grid item lg md className="section-page-header">
                <Grid container justify="space-between" alignItems="center" spacing={3}>
                  <Grid item sm xs>
                    <div className="breadcrumbs-section">
                      <Breadcrumbs aria-label="breadcrumb">
                        <Link
                          className="text-12"
                          color="inherit"
                          href="/orders"
                          onClick={event => {
                            this.handleClick(event, '/medic/lab');
                          }}
                        >
                          <i className="icon-slot ic-ffo-reports mr-4" /> Lab
                        </Link>
                        <label className="text-12" color="inherit">
                          Lab Details
                        </label>
                      </Breadcrumbs>
                    </div>
                  </Grid>
                  <Grid item lg={'auto'} md={'auto'}>
                    <Grid container direction="row" justify="flex-end" alignItems="center">
                      <Grid item className="mr-8">
                        <ButtonMain
                          type="ghost"
                          size="md"
                          labelText="Back"
                          onClick={this.handleButtonCancel}
                        />
                      </Grid>
                      {this.renderCancelButton()}
                      {this.renderUpdatePaymentButton()}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item lg md className="section-page-body">
                {isFetching ? (
                  <SkeletonDetailsV03 />
                ) : (
                  <div className="flex-column container-main-card p-32">
                    <Grid
                      container
                      direction="column"
                      className="container-lab-details flex-wrap-unset"
                    >
                      <Grid item lg={12} md={12} className="mb-18">
                        <Grid
                          className="pb-24"
                          container
                          justify="space-between"
                          alignItems="center"
                          spacing={3}
                        >
                          {this.renderOrderCard()}
                        </Grid>
                        <Grid container>{this.renderOrderInformation()}</Grid>
                      </Grid>
                      <Grid item lg={12} md={12}>
                        <Grid container spacing={3}>
                          <Grid item lg={6} md={6}>
                            {this.renderContactPerson()}
                          </Grid>
                          <Grid item lg={6} md={6}>
                            <DetailsAppointment
                              orderData={detailOrderData.appointment_details}
                              shippingData={detailOrderData.shipping_details}
                              isDraft={isDraft}
                              isComplete={isComplete}
                              onButtonReschedule={this.handleButtonClickReschedule}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      {!_.isEmpty(detailOrderData.identity_details) && (
                        <Grid item lg={12} md={12}>
                          <FormControl component="fieldset" fullWidth margin="normal">
                            <FormLabel component="label" className="text-12 filed-title mb-16">
                              Booking Details
                            </FormLabel>
                            <CustomerItemBooking
                              customerList={detailOrderData.identity_details}
                              isDraft={isDraft}
                              isComplete={isComplete}
                              onRemove={this.handleRemoveCustomer}
                              onSelect={this.handleSelectCustomer}
                              onUpdatePhoto={this.handleUpdatePhoto}
                              onOpenModalImagePreview={this.handleOpenModalImagePreview}
                            />
                          </FormControl>
                        </Grid>
                      )}
                      <Grid item lg={12} md={12}>
                        <Grid container spacing={2}>
                          <Grid item lg={6} md={6}>
                            <FormControl component="fieldset" fullWidth margin="normal">
                              <FormLabel component="label" className="text-12 filed-title mb-13">
                                Payment Method
                              </FormLabel>
                              <SelectInputMain
                                options={paymentMethodOption}
                                currentValue={form.paymentMethod}
                                onChange={this.handleSelectPaymentMethod}
                                disabled={!isDraft}
                              />
                            </FormControl>
                          </Grid>
                          {form.paymentMethod === paymentMethodOptionCode.bank_transfer && (
                            <Grid item lg={6} md={6} className="mt-27">
                              <FormControl component="fieldset" fullWidth margin="normal">
                                <RadioInputImage
                                  data={bankTransferOption}
                                  onSelect={this.handleChangeBankTransfer}
                                  direction="column"
                                  currentValue={form.bank}
                                  disabled={!isDraft}
                                  imageWidth="60px"
                                />
                              </FormControl>
                            </Grid>
                          )}
                          <Grid item lg={6} md={6}>
                            <FormControl component="fieldset" fullWidth margin="normal">
                              <FormLabel component="label" className="text-12 filed-title mb-13">
                                Transaction Reference
                              </FormLabel>
                              <div className="container-promo">
                                <TextInput
                                  placeHolderText="Please enter Transaction Ref. if any"
                                  size="md"
                                  onChange={this.handleChangeTransactionReference}
                                  currentValue={form.transactionReference}
                                />
                              </div>
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item lg={12} md={12}>
                        <Grid container spacing={2}>
                          <Grid item lg={6} md={6} sm={6}>
                            <FormControl component="fieldset" fullWidth margin="normal">
                              <FormLabel component="label" className="text-12 filed-title mb-13">
                                Promo Code
                              </FormLabel>
                              <div className={`container-promo ${isSuccess}`}>
                                <TextInput
                                  placeHolderText="Promo Code"
                                  size="md"
                                  onChange={this.handleChangePromoCodeEntry}
                                  currentValue={form.promoCode}
                                  errorMessage={validation.promoCode.errorMessage}
                                  isError={validation.promoCode.isError}
                                  iconPrefixEnd={isValidPromoCode ? successIcon : ''}
                                  isDisable={!isDraft}
                                />
                              </div>
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Grid>
                      {this.renderOrderSummary()}
                      {orderDetailsData.payment_type === paymentMethodOptionCode.xendit_invoice &&
                        this.renderLinkXendit()}
                      {isDraft && this.renderAdditionalButton()}
                    </Grid>
                  </div>
                )}
              </Grid>
            </Grid>
            {isModalRescheduleOpen && (
              <ModalReschedule
                isOpen={isModalRescheduleOpen}
                isComplete={isComplete}
                data={detailOrderData}
                onClose={this.handleCloseModalReschedule}
                onSuccessReschedule={this.handleSuccessReschedule}
              />
            )}
            {isOpenQrisModal && (
              <ModalOrderQris
                isOpen={isOpenQrisModal}
                data={paymentDetailsData.qr_string}
                onClose={this.handleCloseModalQris}
              />
            )}
            {isOpenImagePreviewModal && (
              <ModalImagePreview
                isOpen={isOpenImagePreviewModal}
                onClose={this.handleCloseModalImagePreview}
                imageUrl={photoPreview}
              />
            )}
            {isOpenCancelOrderModal && (
              <ModalUpdateOrder
                handleCTA={this.onHandleCancelOrder}
                isOpen={isOpenCancelOrderModal}
                onClose={this.handleCloseModalCancelOrder}
                subtitle={'Are you sure you want to cancel this order?'}
                title={'Cancel Order'}
                ctaStyle={'negative'}
              />
            )}
            {isOpenChangeOrderPaymentModal && (
              <ModalUpdateOrder
                handleCTA={this.onHandleChangeOrderPayment}
                isOpen={isOpenChangeOrderPaymentModal}
                onClose={this.handleCloseModalChangePayment}
                subtitle={
                  "This order will be moved to 'Draft' to enable payment change. Are you sure you want to continue?"
                }
                title={'Change Payment'}
                ctaStyle={'primary'}
              />
            )}
            {isOpenEditCompletedModal && (
              <ModalEditData
                isOpen={isOpenEditCompletedModal}
                salesOrderId={salesOrderId}
                data={detailOrderData}
                selectedCustomer={selectedCustomer}
                editType={editType}
                onClose={this.handleCloseModalEditData}
                onSuccessReschedule={this.handleSuccessEditData}
              />
            )}
            <SnackBarSimple
              open={toastInformation.isOpen}
              durationHide={2000}
              message={toastInformation.message}
              onClickClose={this.handleCloseToast}
              snackbarType={toastInformation.snackbarType}
              anchor={optionToast}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getOrder: params => getOrderDetails(dispatch, params),
  checkPromo: params => checkPromoCode(dispatch, params),
  processSaveOpenBill: (salesOrderID, params) => saveOpenBill(dispatch, salesOrderID, params),
  processSubmitOpenBill: (salesOrderID, params) => submitOpenBill(dispatch, salesOrderID, params),
  processCancelOrder: salesOrderID => cancelOrder(dispatch, salesOrderID),
  processChangeOrderPayment: salesOrderID => changeOrderPayment(dispatch, salesOrderID),
});

const mapStateToProps = ({ orderData, eatData }) => ({ orderData, eatData });

LabDetails.propTypes = {
  checkPromo: PropTypes.func,
  checkUserAccessPermission: PropTypes.func,
  getOrder: PropTypes.func,
  history: PropTypes.object,
  match: PropTypes.object,
  orderData: PropTypes.object,
  processCancelOrder: PropTypes.func,
  processChangeOrderPayment: PropTypes.func,
  processSaveOpenBill: PropTypes.func,
  processSubmitOpenBill: PropTypes.func,
};

const shell = compose(connect(mapStateToProps, mapDispatchToProps));
const core = compose(AuthenticationAccessPages, PrevStateValue);

export default shell(core(LabDetails));
