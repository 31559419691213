import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
// import _ from 'lodash';
import { Grid } from '@material-ui/core';
// style
import './PatientMealPlanPageStyle.scss';
// Component
import { CalendarSummary } from './components/Index';
import {
  AuthenticationAccessPages,
  SnackBarSimple,
  SkeletonMain,
  ButtonMain,
  LabelStatusMain,
  PickerInputDate,
  GeneratorExcel,
} from '../../../components/Index';
// api
import {
  getPatientDetail,
  getPatientMealList,
  setCheckoutPatient,
  getPatientMealPlanExport,
} from '../../../services/api/EatApi';
// helper
import {
  CalendarHelper,
  CommonHelper,
  MasterDataHelper,
  roomTypeCode,
  PermissionModule,
  PermissionPage,
  PermissionAccess
} from '../../../helpers/Index';
import { compose } from 'redux';

const currentDate = CommonHelper.currentDate('YYYY-MM-DD');

const initialFilter = {
  timeRangeStartWeek: null,
  timeRangeEndWeek: null,
  dayValue: currentDate,
  weekDayList: null,
};

const optionToast = {
  vertical: 'top',
  horizontal: 'right',
};

class PatientMealPlan extends React.Component {
  constructor(props) {
    super(props);
    const {
      match: { params },
    } = this.props;

    const parameters = {
      ...CommonHelper.decryptObject(params.param_object),
    };

    this.state = {
      userRoomId: parameters.userRoomId,
      filter: CommonHelper.objectCloning(initialFilter),
      isLoading: true,
      mealPlanList: {},
      toastInformation: {
        isOpen: false,
        message: '',
        snackbarType: 'warning',
      },
    };
  }

  async componentDidMount() {
    this.getPatientData();
  }

  getPatientData = () => {
    const { patientDetail } = this.props;
    const { userRoomId } = this.state;
    patientDetail(userRoomId).then(() => {
      this.setState({ isLoading: false });
      this.getPatientMealPlanSchedule();
    });
  };

  getPatientMealPlanSchedule = () => {
    const { filter } = this.state;
    const weekDay = CalendarHelper.getWeekDayByToday(currentDate);
    const listHeaderTimeLine = CalendarHelper.generateListWeek(weekDay);
    const converted = CalendarHelper.generateCalenderHeaderData(listHeaderTimeLine);
    this.setState(
      {
        filter: {
          ...filter,
          timeRangeStartWeek: weekDay.startWeek,
          timeRangeEndWeek: weekDay.endWeek,
          weekDayList: converted,
        },
      },
      () => {
        this.getCalendarScheduleByWeek(converted);
      },
    );
  };

  getCalendarScheduleByWeek = weekList => {
    const { patientMealList } = this.props;
    const { filter, userRoomId } = this.state;
    if (!weekList) {
      const weekDay = CalendarHelper.getWeekDayByToday(this.state.filter.timeRangeStartWeek);
      const listHeaderTimeLine = CalendarHelper.generateListWeek(weekDay);
      weekList = CalendarHelper.generateCalenderHeaderData(listHeaderTimeLine);
    }
    if (weekList) {
      this.setState({
        filter: { ...filter, weekDayList: weekList },
      });
      weekList.forEach((item, index) => {
        const param = {
          date: item.value,
        };
        patientMealList(userRoomId, param).then(response => {
          this.setState(state => {
            return {
              mealPlanList: {
                ...state.mealPlanList,
                [`summaryData0${index + 1}`]: response.data,
              },
            };
          });
        });
      });
    }
  };

  handleClickEdit = () => {
    const { history } = this.props;
    const { userRoomId } = this.state;
    const param = {
      userRoomId,
    };
    const paramEncrypt = CommonHelper.encryptObject(param);
    history.push(`/eat/in-patient/edit/${paramEncrypt}`);
  };

  handleCheckoutButton = () => {
    const { checkoutPatient } = this.props;
    const { userRoomId } = this.state;
    this.setState({ isLoading: true });
    checkoutPatient(userRoomId).then(() => {
      this.setState({ isLoading: false });
      this.getPatientData();
    });
  };

  handleButtonExport = () => {
    const { patientMealPlanExport } = this.props;
    const { userRoomId } = this.state;
    patientMealPlanExport(userRoomId);
  };

  handleChangeStartDate = value => {
    const { filter } = this.state;
    this.setState(
      {
        filter: { ...filter, timeRangeStartWeek: value, dayValue: value },
      },
      () => {
        this.getCalendarScheduleByWeek();
      },
    );
  };

  handleCalendarHeaderClick = value => {
    const { filter } = this.state;
    this.setState(
      { filter: { ...filter, timeRangeStartWeek: value[0].value }, mealPlanList: {} },
      () => {
        this.getCalendarScheduleByWeek(value);
      },
    );
  };

  handleClickUpdate = date => {
    const { history } = this.props;
    const { userRoomId } = this.state;
    const param = {
      roomId: userRoomId,
      dateSelected: date || currentDate,
    };
    const paramFilter = CommonHelper.encryptObject(param);
    history.push(`/eat/in-patient/meal/create/${paramFilter}`);
  };

  handleReloadColumn = index => {
    const { weekDayList } = this.state;
    this.getCalendarScheduleByWeek(weekDayList[index - 1].value);
  };

  handleCloseToast = () => {
    const { toastInformation } = this.state;
    this.setState({ toastInformation: { ...toastInformation, isOpen: false } });
  };

  processMessage(messages, type) {
    const convertedMessage = CommonHelper.generateMessage(messages);

    const paramInformation = {
      isOpen: true,
      message: convertedMessage,
      snackbarType: type,
    };

    this.setState({
      toastInformation: paramInformation,
    });
  }

  renderPatientDetail() {
    const {
      eatData: { patientDetail, exportPatientMealPlanList, fetching },
      history,
    } = this.props;
    const birthday = CommonHelper.dateTimeParseNewFormat(patientDetail.birthday, 'DD MMMM YYYY');
    const checkInDate = CommonHelper.dateTimeParseNewFormat(
      patientDetail.check_in_time,
      'DD MMMM YYYY | HH:mm',
    );
    const checkOutDate = CommonHelper.dateTimeParseNewFormat(
      patientDetail.check_out_time,
      'DD MMMM YYYY | HH:mm',
    );
    let type = '';
    switch (patientDetail.room_type) {
      case roomTypeCode.VVIP:
        type = 'primary';
        break;
      case roomTypeCode.VIP:
        type = 'complete';
        break;
      case roomTypeCode.Kelas1:
        type = 'warning';
        break;
      default:
        type = 'default';
        break;
    }
    return (
      <Grid item className="section-filter mb-24">
        <Grid container direction="row" justify="space-between" className="mb-16">
          <Grid item lg={8} md={8}>
            <Grid container direction="row">
              <Grid item className="mr-12">
                <div className="flex-column">
                  <label className="text-14 text-bold mb-10">{`${patientDetail.title || ''} ${
                    patientDetail.name
                  }`}</label>
                  <label className="text-11 subtitle">{`Nomor Rekam Medis (NRM) - ${patientDetail.medical_record_number}`}</label>
                </div>
              </Grid>
              <Grid item className="room-status mr-12">
                <LabelStatusMain
                  value={patientDetail.room_status}
                  type={!patientDetail.check_out_time ? 'information' : 'red'}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg="auto" md="auto">
            <Grid container direction="row" justify="flex-end">
              <Grid item className="mr-16">
                <ButtonMain
                  labelText="Back"
                  onClick={() => {
                    history.push('/eat/in-patient');
                  }}
                  type="ghost"
                />
              </Grid>
              {!patientDetail.check_out_time ? (
                <Grid item className="mr-16">
                  <ButtonMain
                    type="primary"
                    labelText="Edit"
                    onClick={() => this.handleClickEdit()}
                    requiredPermission={`${PermissionModule.Eat}.${PermissionPage.InPatient}.${PermissionAccess.Update}`}
                  />
                </Grid>
              ) : null}
              {!patientDetail.check_out_time ? (
                <Grid item>
                  <ButtonMain
                    type="error"
                    labelText="Check Out"
                    onClick={this.handleCheckoutButton}
                    requiredPermission={`${PermissionModule.Eat}.${PermissionPage.InPatient}.${PermissionAccess.Update}`}
                  />
                </Grid>
              ) : (
                <Grid item>
                  <GeneratorExcel
                    labelText="Print Meal Summary"
                    buttonSize="md"
                    dataSetArray={exportPatientMealPlanList}
                    fileName={`Patient_Meal_Plan_${patientDetail.name}_${patientDetail.room_type}_${patientDetail.room_name} - ${patientDetail.room_number}`}
                    isLoading={fetching}
                    onClick={this.handleButtonExport}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid container direction="row" justify="space-between">
          <Grid item lg={8} md={8}>
            <Grid container direction="row">
              <Grid item lg md className="mr-16">
                <div className="flex-column">
                  <label className="text-12 title text-semi-bold">
                    {MasterDataHelper.gender[patientDetail.gender - 1].name}
                  </label>
                  <label className="text-12 text-semi-bold">{birthday}</label>
                </div>
              </Grid>
              <Grid item lg md className="mr-16">
                <div className="flex-column">
                  <LabelStatusMain value={patientDetail.room_type} type={type} />
                  <label className="text-12 text-semi-bold mt-5">{`${patientDetail.room_name} - ${patientDetail.room_number}`}</label>
                </div>
              </Grid>
              <Grid item lg md className="mr-16">
                <div className="flex-column">
                  <label className="text-12 title text-semi-bold">Doctor Name</label>
                  <label className="text-12 text-semi-bold">{patientDetail.doctor || '-'}</label>
                </div>
              </Grid>
              <Grid item lg md className="mr-16">
                <div className="flex-column">
                  <label className="text-12 title text-semi-bold">Diet Type</label>
                  <label className="text-12 text-semi-bold">
                    {patientDetail.diet_type_description}
                  </label>
                </div>
              </Grid>
              <Grid item lg md className="mr-16">
                <div className="flex-column">
                  <label className="text-12 title text-semi-bold">Diet Notes</label>
                  <label className="text-12 text-semi-bold">{patientDetail.diet_notes}</label>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={4} md={4}>
            <Grid container direction="row" justify="flex-end">
              <Grid item>
                <div className="flex-column">
                  <label className="text-12 text-right title text-semi-bold">Check-in Date</label>
                  <label className="text-12 text-semi-bold">{checkInDate}</label>
                </div>
              </Grid>
              {patientDetail.check_out_time && (
                <Grid item className="ml-20">
                  <div className="flex-column">
                    <label className="text-12 text-right title text-semi-bold">
                      Check-out Date
                    </label>
                    <label className="text-12 text-semi-bold">{checkOutDate}</label>
                  </div>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  renderFilter() {
    const {
      filter: { timeRangeStartWeek },
    } = this.state;
    return (
      <Grid item>
        <Grid container direction="row" justify="space-between" alignItems="center">
          <Grid item lg={6} md={6}>
            <Grid container direction="row" alignItems="center">
              <label className="text-12 pr-16">Showing Week Starting from</label>
              <div className="container-remove-margin">
                <PickerInputDate
                  customIcon="ic-ffo-date-pick"
                  dateFormat="dd/MM/yyyy"
                  defaultValue={timeRangeStartWeek}
                  onChange={this.handleChangeStartDate}
                  toolbar={false}
                  customEnableDay={'0'}
                />
              </div>
            </Grid>
          </Grid>
          <Grid item lg="auto" md="auto">
            <Grid item>
              <ButtonMain
                labelText="Add Meal"
                onClick={() => {
                  this.handleClickUpdate(null);
                }}
                type="primary"
                size="md"
                startIcon="ic-ffo-add"
                requiredPermission={`${PermissionModule.Eat}.${PermissionPage.InPatient}.${PermissionAccess.Add}`}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  render() {
    const {
      isLoading,
      mealPlanList,
      toastInformation,
      filter: { dayValue },
    } = this.state;
    let elementRender = <SkeletonMain />;

    if (!isLoading) {
      elementRender = (
        <div className="container-page-patient-in-patient scroll-container">
          <Helmet title="FITCO | Eats - In-Patient Details" />
          <div className="container-page-scrolling-area">
            <Grid container direction="column" className="flex-wrap-unset">
              <Grid item lg md className="section-page-header">
                {this.renderPatientDetail()}
                {this.renderFilter()}
              </Grid>
              <Grid item lg md className="section-page-body">
                <CalendarSummary
                  onClickUpdate={this.handleClickUpdate}
                  onReloadColumn={this.handleReloadColumn}
                  onButtonHeaderClick={this.handleCalendarHeaderClick}
                  data={mealPlanList}
                  dayValue={dayValue}
                />
              </Grid>
            </Grid>
          </div>
          <SnackBarSimple
            open={toastInformation.isOpen}
            durationHide={2000}
            message={toastInformation.message}
            onClickClose={this.handleCloseToast}
            snackbarType={toastInformation.snackbarType}
            anchor={optionToast}
          />
        </div>
      );
    }

    return elementRender;
  }
}

const mapDispatchToProps = dispatch => ({
  patientDetail: userRoomId => getPatientDetail(dispatch, userRoomId),
  patientMealList: (userRoomId, params) => getPatientMealList(dispatch, userRoomId, params),
  checkoutPatient: userRoomId => setCheckoutPatient(dispatch, userRoomId),
  patientMealPlanExport: userRoomId => getPatientMealPlanExport(dispatch, userRoomId),
});

const mapStateToProps = ({ eatData }) => ({
  eatData,
});

PatientMealPlan.propTypes = {
  checkoutPatient: PropTypes.func,
  eatData: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
  patientDetail: PropTypes.func,
  patientMealList: PropTypes.func,
  patientMealPlanExport: PropTypes.func,
};

const shell = compose(connect(mapStateToProps, mapDispatchToProps));

export default shell(AuthenticationAccessPages(PatientMealPlan));
