import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid, Breadcrumbs, Link, FormControl } from '@material-ui/core';
import { Helmet } from 'react-helmet';
// import _ from 'lodash';
import { compose } from 'redux';
// style
import './ProductCategoryAddStyle.scss';
// component
import {
  TextInput,
  SnackBarSimple,
  ButtonMain,
  RadioInput,
  TextAreaMain,
  UploadImage,
  AuthenticationAccessPages,
  PrevStateValue,
  LabelInput,
  SkeletonDetailsV03
} from '../../../../components/Index';
// api
import {
  setCreateProductCategory,
  getProductCategoryDetails,
} from '../../../../services/api/MasterDataMainAPi';
// helper
import {
  MasterDataHelper,
  CommonHelper,
  PermissionModule,
  PermissionPage,
  PermissionAccess,
} from '../../../../helpers/Index';

const optionToash = {
  vertical: 'top',
  horizontal: 'right',
};

const radioButtonOption = MasterDataHelper.statusGlobalWithoutDeleted;

const initialValidation = {
  categoryName: { isError: false, errorMessage: '' },
  identification: { isError: false, errorMessage: '' },
  status: { isError: '', errorMessage: '' },
  description: { isError: '', errorMessage: '' },
  imageIcon: { isError: '', errorMessage: '' },
  imageBanner: { isError: '', errorMessage: '' },
};

const initialForm = {
  categoryName: '',
  identification: '',
  status: radioButtonOption[0],
  description: '',
  imageIcon: null,
  imageBanner: null,
};

class ProductCategoryAdd extends React.Component {
  constructor(props) {
    super(props);

    props.checkUserAccessPermission(
      PermissionModule.MasterData,
      PermissionPage.ProductCategory,
      PermissionAccess.Add,
    );

    this.state = {
      validation: CommonHelper.objectCloning(initialValidation),
      form: CommonHelper.objectCloning(initialForm),
      isLoading: false,
      isLoadingGetData: false,
      toastInformation: {
        isOpen: false,
        message: '',
        snackbarType: 'warning',
      },
    };
  }

  componentDidMount() {
    const { form } = this.state;
    const {
      match: { params },
      productCategoryDetails,
    } = this.props;

    if (params.parent_id) {
      this.setState({ isLoadingGetData: true }, () => {
        const param = { category_id: params.parent_id };
        productCategoryDetails(param)
          .then(async response => {
            const data = response.data;
            this.setState({
              form: {
                ...form,
                parentId: parseInt(params.parent_id, 10) || null,
                parentName: data.name || null,
              },
              isLoadingGetData: false,
            });
          })
          .catch(error => {
            const message = error.data;
            this.processMessage(message.messages, 'error');
          });
      });
    }
  }

  componentWillUnmount() {}

  handleCloseToash = () => {
    const { toastInformation } = this.state;
    this.setState({ toastInformation: { ...toastInformation, isOpen: false } });
  };

  handleClick = (event, value) => {
    const { history } = this.props;
    event.preventDefault();
    history.push(value);
  };

  handleTextChangeCategoryName = value => {
    const { form, validation } = this.state;
    const message = CommonHelper.objectCloning(initialValidation.categoryName);
    const valid = !value;
    message.isError = valid;
    message.errorMessage = valid ? 'CategoryName is Empty' : message.errorMessage;

    this.setState({
      form: { ...form, categoryName: value },
      validation: { ...validation, categoryName: message },
    });
  };

  handleTextIdentificationChanges = value => {
    const { form, validation } = this.state;
    const message = CommonHelper.objectCloning(initialValidation.identification);
    const valid = !value;
    message.isError = valid;
    message.errorMessage = valid ? 'Identification is Empty' : message.errorMessage;

    this.setState({
      form: { ...form, identification: value.trim() },
      validation: { ...validation, identification: message },
    });
  };

  handleChangeDescription = value => {
    const { form } = this.state;
    this.setState({
      form: { ...form, description: value },
    });
  };

  handleSelectStatus = value => {
    const { form } = this.state;
    this.setState({ form: { ...form, status: value } });
  };

  handleChangeImageIcon = imageIcon => {
    const { form, validation } = this.state;
    const message = CommonHelper.objectCloning(initialValidation.imageIcon);
    const valid = !imageIcon;
    message.isError = !valid ? '' : 'error';
    message.errorMessage = valid ? 'Please choose image' : message.errorMessage;

    this.setState({
      form: { ...form, imageIcon },
      validation: { ...validation, imageIcon: message },
    });
  };

  handleChangeImageBanner = imageBanner => {
    const { form, validation } = this.state;
    const message = CommonHelper.objectCloning(initialValidation.imageBanner);
    const valid = !imageBanner;
    message.isError = !valid ? '' : 'error';
    message.errorMessage = valid ? 'Please choose image' : message.errorMessage;

    this.setState({
      form: { ...form, imageBanner },
      validation: { ...validation, imageBanner: message },
    });
  };

  handleButtonCancel = () => {
    const { history } = this.props;
    history.push('/master-data/product-category/');
  };

  handleButtonSubmit = () => {
    const { createProductCategory, history } = this.props;
    const { form } = this.state;

    if (this.validationEmptyField()) {
      const param = {
        data: {
          parent_id: form.parentId,
          name: form.categoryName,
          identification: form.identification,
          status: form.status.value,
          description: form.description,
          icon: form.imageIcon,
          banner: form.imageBanner,
        },
      };

      this.setState({ isLoading: true }, () => {
        createProductCategory(param)
          .then(response => {
            const message = response.messages;

            this.processMessage(message, 'success');
            this.setState({ isLoading: false }, () => {
              setTimeout(async () => {
                await history.push('/master-data/product-category/');
              }, 2000);
            });
          })
          .catch(error => {
            const message = error.data;
            this.setState({ isLoading: false });
            this.processMessage(message.messages, 'error');
          });
      });
    }
  };

  processMessage(messages, type) {
    const convertedMessage = CommonHelper.generateMessage(messages);

    const paramInformation = {
      isOpen: true,
      message: convertedMessage,
      snackbarType: type,
    };

    this.setState({
      toastInformation: paramInformation,
    });
  }

  validationEmptyField() {
    const { form, validation } = this.state;

    let passCheck = true;

    const paramValidationCategoryName = CommonHelper.objectCloning(initialValidation.categoryName);
    const paramValidationIdentification = CommonHelper.objectCloning(
      initialValidation.identification,
    );


    paramValidationCategoryName.isError = !form.categoryName;
    paramValidationCategoryName.errorMessage = form.categoryName ? '' : 'Category Name Empty';

    paramValidationIdentification.isError = !form.identification;
    paramValidationIdentification.errorMessage = form.identification ? '' : 'Identification Empty';

    if (
      !form.categoryName ||
      !form.identification
    ) {
      passCheck = false;
    }

    this.setState({
      validation: {
        ...validation,
        categoryName: paramValidationCategoryName,
        identification: paramValidationIdentification
      },
    });

    return passCheck;
  }

  render() {
    const { toastInformation, form, isLoadingGetData } = this.state;
    let renderElement = <SkeletonDetailsV03 />;

    if (!isLoadingGetData) {
      renderElement = (
        <div className="container-page-category-add scroll-container-invisible">
          <div className="container-scrolling-area">
            <Helmet
              title={
                form.parentId ? 'FITCO | Master Data - Create New Product Sub-Category' : 'FITCO | Master Data - Create New Product Category'
              }
            />
            <Grid container direction="column">
              <Grid item lg md className="section-page-header">
                <Grid container>
                  <Grid item>
                    <div className="breadcrumbs-section">
                      <Breadcrumbs aria-label="breadcrumb">
                        <Link
                          className="text-12"
                          color="inherit"
                          href="/master-data/product-category"
                          onClick={event => {
                            this.handleClick(event, '/master-data/product-category');
                          }}
                        >
                          <i className="icon-slot ic-ffo-master-data" /> Product Category
                        </Link>
                        <label className="text-12" color="inherit">
                          {form.parentId ? 'Create New Sub Category' : 'Create New Category'}
                        </label>
                      </Breadcrumbs>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item lg md className="section-page-body">
                <div className="category-details-card">
                  <Grid container spacing={3}>
                    <label className="text-16 text-bold text-rolling-stone">
                      {form.parentId ? 'Create New Sub Category' : 'Create New Category'}
                    </label>
                  </Grid>
                  <Grid className="pt-24" container spacing={3}>
                    <Grid container spacing={3}>
                      <Grid item lg={6} md={6}>
                        <Grid container direction="column">
                          {form.parentId && (
                            <Grid item>
                              <FormControl component="fieldset" fullWidth margin={'normal'}>
                                <LabelInput labelText={'Parent Category'} />
                                <TextInput
                                  placeHolderText="Enter Category Name"
                                  defaultValue={form.parentName}
                                  isDisable
                                />
                              </FormControl>
                            </Grid>
                          )}
                          <Grid item>
                            <FormControl component="fieldset" fullWidth margin={'normal'}>
                              <LabelInput labelText={'Category Name'} isRequired />
                              <TextInput
                                placeHolderText="Enter Category Name"
                                onChange={this.handleTextChangeCategoryName}
                                currentValue={form.categoryName}
                                isError={this.state.validation.categoryName.isError}
                                errorMessage={this.state.validation.categoryName.errorMessage}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item>
                            <FormControl component="fieldset" fullWidth margin={'normal'}>
                              <LabelInput labelText={'Identification'} isRequired />
                              <TextInput
                                placeHolderText="Enter Identification"
                                onChange={this.handleTextIdentificationChanges}
                                currentValue={form.identification}
                                isError={this.state.validation.identification.isError}
                                errorMessage={this.state.validation.identification.errorMessage}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item>
                            <FormControl component="fieldset" fullWidth margin={'normal'}>
                              <LabelInput labelText={'Status'} />
                              <Grid container direction="column">
                                <Grid item>
                                  <div className="container-remove-margin">
                                    <RadioInput
                                      data={radioButtonOption}
                                      onSelect={this.handleSelectStatus}
                                      direction="column"
                                      defaultValue={this.state.form.status}
                                    />
                                  </div>
                                </Grid>
                              </Grid>
                            </FormControl>
                          </Grid>
                          <Grid item>
                            <FormControl component="fieldset" fullWidth margin={'normal'}>
                              <LabelInput
                                labelText={'Description'}
                                additionalText={' ( Optional )'}
                              />
                              <TextAreaMain
                                onChange={this.handleChangeDescription}
                                currentValue={this.state.form.description}
                                placeholder="Enter Description"
                                rows={5}
                                validateStatus={this.state.validation.description.isError}
                                errorMessage={this.state.validation.description.errorMessage}
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item lg={6} md={6}>
                        <div className="flex-column">
                          <FormControl component="fieldset" fullWidth margin={'normal'}>
                            <LabelInput labelText={'Icon'} isRequired />
                            <div className="container-image-action">
                              <UploadImage
                                onChange={item => this.handleChangeImageIcon(item)}
                                defaultValue={this.state.form.imageIcon}
                                maxSize={1}
                                validateStatus={this.state.validation.imageIcon.isError}
                                errorMessage={this.state.validation.imageIcon.errorMessage}
                              />
                            </div>
                          </FormControl>
                          <FormControl component="fieldset" fullWidth margin={'normal'}>
                            <LabelInput labelText={'Banner'} isRequired />
                            <div className="container-image-action">
                              <UploadImage
                                onChange={item => this.handleChangeImageBanner(item)}
                                defaultValue={this.state.form.imageBanner}
                                maxSize={1}
                                validateStatus={this.state.validation.imageBanner.isError}
                                errorMessage={this.state.validation.imageBanner.errorMessage}
                              />
                            </div>
                          </FormControl>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
              <Grid item lg md className="section-page-footer">
                <Grid container justify="flex-end" spacing={3}>
                  <Grid item lg={2} md={2}>
                    <ButtonMain
                      type="ghost"
                      size="xl"
                      labelText="Cancel"
                      onClick={this.handleButtonCancel}
                    />
                  </Grid>
                  <Grid item lg={2} md={2}>
                    <ButtonMain
                      type="primary"
                      size="xl"
                      labelText="Create"
                      onClick={this.handleButtonSubmit}
                      isLoading={this.state.isLoading}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <SnackBarSimple
              open={toastInformation.isOpen}
              durationHide={2000}
              message={toastInformation.message}
              onClickClose={this.handleCloseToash}
              snackbarType={toastInformation.snackbarType}
              anchor={optionToash}
            />
          </div>
        </div>
      );
    }

    return renderElement;
  }
}

const mapDispatchToProps = dispatch => ({
  createProductCategory: params => setCreateProductCategory(dispatch, params),
  productCategoryDetails: params => getProductCategoryDetails(dispatch, params),
});

const mapStateToProps = ({ masterData }) => ({ masterData });

ProductCategoryAdd.propTypes = {
  checkUserAccessPermission: PropTypes.func,
  createProductCategory: PropTypes.func,
  history: PropTypes.object,
  match: PropTypes.object,
  productCategoryDetails: PropTypes.func,
};

const shell = compose(connect(mapStateToProps, mapDispatchToProps));
const core = compose(AuthenticationAccessPages, PrevStateValue);

export default shell(core(ProductCategoryAdd));
