import React from 'react';
import PropTypes from 'prop-types';
import { Switch } from '@material-ui/core';

const SwitchInput = props => {
  const { currentValue, onChange } = props;

  const handleChange = event => {
    onChange(event.target.checked);
  };

  return (
    <Switch
      checked={currentValue}
      color="primary"
      inputProps={{ 'aria-label': 'primary checkbox' }}
      onChange={handleChange}
    />
  );
};

SwitchInput.propTypes = {
  currentValue: PropTypes.object,
  onChange: PropTypes.func,
};

export default SwitchInput;
