/* eslint-disable no-empty */
/* eslint-disable react/no-access-state-in-setstate */
import React from 'react';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { Table } from 'antd';
// component
import { GeneratorExcel } from '../../../../../../components/Index';
// style
import './StepPromoCodeListGenerateStyle.scss';
// helper
import { CommonHelper, MasterDataHelper } from '../../../../../../helpers/Index';
import { getPromoCodeExportReport } from '../../../../../../services/api/PromoCodeApi';

const radioButtonTrueFalseOption = CommonHelper.objectCloning(
  MasterDataHelper.radioButtonTrueFalse,
);

class StepPromoCodeListGenerate extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      form: {},
      limit: 10,
      page: 1,
      pagination: {
        showSizeChanger: true,
        showTotal: (total, range) => `Showing ${range[0]}-${range[1]} From ${total}`,
        pageSizeOptions: ['10', '20', '30', '40'],
      },
    };
  }

  componentDidMount() {
    const { parentForm } = this.props;
    const { form, pagination, limit } = this.state;

    this.setState({
      form: { ...form, ...parentForm },
      pagination: { ...pagination, pageSize: limit, total: parentForm.cashbackCodes.length },
    });
  }

  handleButtonDownload = async () => {
    const {
      form: { cashbackId },
    } = this.state;
    const {
      promoCodeExportReport,
      promoCodeData: { fetchingExport },
    } = this.props;

    try {
      if (!fetchingExport) {
        await promoCodeExportReport(cashbackId);
      }
    } catch (error) {}
  };

  handleTableChange = pagination => {
    this.setState({
      limit: pagination.pageSize,
      page: pagination.current,
      pagination: {
        ...this.state.pagination,
        current: pagination.current,
        pageSize: pagination.pageSize,
      },
    });
  };

  renderColumns = () => {
    return [
      {
        title: 'USER ID',
        width: '16%',
        dataIndex: 'user_id',
        align: 'center',
        render: (text, row) => {
          return <label className="text-14 text-semi-bold">{row.user_id || '-'}</label>;
        },
      },
      {
        title: 'PROMO CODE',
        dataIndex: 'promo_code',
        render: (text, row) => {
          return (
            <label className="text-14 text-semi-bold wrapping-container-break wrapping-container second line ">
              {row.promo_code}
            </label>
          );
        },
      },
      {
        title: 'ALREADY USAGE',
        width: '10%',
        dataIndex: 'is_used',
        align: 'center',
        render: (text, row) => {
          const wordingUsage = radioButtonTrueFalseOption.filter(
            item => item.value === row.is_used,
          );
          return (
            <label className="text-14 text-semi-bold wrapping-container-break wrapping-container second line ">
              {wordingUsage[0].name || '-'}
            </label>
          );
        },
      },
      {
        title: 'EXPIRED AT',
        width: '16%',
        dataIndex: 'expired_at',
        align: 'center',
        render: (text, row) => {
          const wordingExpired = CommonHelper.dateTimeParseNewFormat(
            row.expired_at,
            'DD MMM YYYY | HH:mm',
          );

          return (
            <label className="text-14 text-semi-bold wrapping-container-break wrapping-container second line ">
              {wordingExpired || '-'}
            </label>
          );
        },
      },
      {
        title: 'LAST UPDATED',
        width: '16%',
        dataIndex: 'updated_at',
        align: 'center',
        render: (text, row) => {
          const wordingModify = CommonHelper.dateTimeParseNewFormat(
            row.updated_at,
            'DD MMM YYYY | HH:mm',
          );
          return (
            <label className="text-14 text-semi-bold wrapping-container-break wrapping-container second line ">
              {wordingModify || '-'}
            </label>
          );
        },
      },
    ];
  };

  render() {
    const { form, pagination, limit, page } = this.state;
    const {
      customElementPropsFormLabel,
      customElementPropsStepper,
      promoCodeData: { fetchingExport, reportExport },
    } = this.props;

    const customTablePagination = CommonHelper.getArrayByLimit(form.cashbackCodes, limit, page);

    return (
      <Grid item lg md className="container-main-card flex-column p-32 flex-wrap-unset mb-32">
        <div className="mb-32">{customElementPropsFormLabel}</div>
        <div className="mb-48">{customElementPropsStepper}</div>
        <Grid container spacing={2}>
          <Grid item lg={3} md={3}>
            <GeneratorExcel
              buttonSize="xl"
              type="ghost"
              isLoading={fetchingExport}
              dataSetArray={reportExport}
              fileName={`promo_code_list_generate_code`}
              onClick={this.handleButtonDownload}
            />
          </Grid>
          <Grid item lg={12} md={12}>
            <Table
              columns={this.renderColumns()}
              rowKey={record => record.id}
              dataSource={customTablePagination}
              pagination={pagination}
              onChange={this.handleTableChange}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  promoCodeExportReport: id => getPromoCodeExportReport(dispatch, id),
});

const mapStateToProps = ({ promoCodeData }) => ({ promoCodeData });

StepPromoCodeListGenerate.propTypes = {
  customElementPropsFormLabel: PropTypes.element,
  customElementPropsStepper: PropTypes.element,
  parentForm: PropTypes.object,
  promoCodeData: PropTypes.object,
  promoCodeExportReport: PropTypes.func,
};

const shell = compose(connect(mapStateToProps, mapDispatchToProps));

export default shell(StepPromoCodeListGenerate);
