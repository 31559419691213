/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Form } from 'antd';
import { Grid, Breadcrumbs, Link, FormControl } from '@material-ui/core';
import _ from 'lodash';
import { compose } from 'redux';
// Style
import './TncSectionEditStyle.scss';
// component
import {
  SnackBarSimple,
  ButtonMain,
  PickerInputDate,
  // TextInput,
  UploadImage,
  TextEditor,
  SelectInputMain,
  InputMain,
  PrevStateValue,
  AuthenticationAccessPages,
  LabelInput,
} from '../../../../components/Index';
// import { SkeletonTncSectionDetails } from '../../components/Index';
// Action
import { getScreenDeeplinkList } from '../../../../services/api/MasterDataApi';
import {
  getPromoCodeDetails
} from '../../../../services/api/PromoCodeApi';
import { editTncSection, getTncSectionDetails } from '../../../../services/api/TncSection';
// Helper
import {
  CommonHelper,
  MasterDataHelper,
  PermissionModule,
  PermissionPage,
  PermissionAccess,
} from '../../../../helpers/Index';

const optionToash = {
  vertical: 'top',
  horizontal: 'right',
};

const resetValidation = { isError: '', errorMessage: '' };

const initialValidation = {
  name: { isError: '', errorMessage: '' },
  starDate: { isError: '', errorMessage: '' },
  endDate: { isError: '', errorMessage: '' },
  description: { isError: '', errorMessage: '' },
  imageBase64: { isError: '', errorMessage: '' },
  cashbackID: { isError: '', errorMessage: ''},
  deeplink: { isError: '', errorMessage: '' },
  identifier: { isError: '', errorMessage: '' },
  screen: { isError: '', errorMessage: '' },
  webUrl: { isError: '', errorMessage: '' },
};

const currentDate = CommonHelper.currentDate('YYYY-MM-DD');
const defaultDate = CommonHelper.intervalDate(currentDate, 'YYYY-MM-DD', -3, 'year');
const deeplinkOptions = MasterDataHelper.deeplinkType;

class TncSectionEdit extends React.Component {
  constructor(props) {
    super(props);

    props.checkUserAccessPermission(
      PermissionModule.Marketing,
      PermissionPage.TnCSection,
      PermissionAccess.Add,
    );

    this.state = {
      toastInformation: {
        isOpen: false,
        message: '',
        isLoading: true,
        snackbarType: 'warning',
      },
      form: {
        name: '',
        description: '',
        startDate: null,
        endDate: null,
        deeplink: null,
        deeplinkType: null,
        identifier: null,
        subidentifier: null,
        webUrl: null,
        screen: null,
        imageBase64: null,
        cashbackID: null,
      },
      validation: initialValidation,
      disabledIdentifier: true
    };
    
    this.getPromo = _.debounce(this.getPromo, 300);
  }

  componentDidMount() {
    const { getScreenList } = this.props;
    const { getTncSectionsDetails } = this;
    getScreenList();
    getTncSectionsDetails();
  }

  getPromo(cashbackID) {
    const { validation } = this.state;
    const { getPromoDetails } = this.props;

    getPromoDetails(cashbackID).then(response => {
      this.setState({
        validation: {
          ...validation, 
          cashbackID: {
            isError: '',
            errorMessage: ''
          }
        }
      });
    }, error => {
      this.setState({
        validation: {
          ...validation, 
          cashbackID: {
            isError: 'error',
            errorMessage: 'Invalid Promo ID'
          }
        }
      });
    });
  };

  getTncSectionsDetails = () => {
    const {
      getTncDetails,
      match: { params },
    } = this.props;
    const tncId = params.tnc_id;
    getTncDetails(tncId).then(respone => {
      const {
        tncData: { tncDetails },
      } = this.props;

      this.setState({
        form: {
          id: tncDetails.id,
          name: tncDetails.title,
          description: tncDetails.description,
          startDate: tncDetails.start_date
            ? CommonHelper.dateTimeParseNewFormat(tncDetails.start_date, 'YYYY-MM-DD')
            : null,
          endDate: tncDetails.end_date
            ? CommonHelper.dateTimeParseNewFormat(tncDetails.end_date, 'YYYY-MM-DD')
            : null,
          deeplink: tncDetails.deeplink,
          deeplinkType: !_.isEmpty(tncDetails.deeplink)
            ? tncDetails.deeplink.type
            : deeplinkOptions[0].value,
          identifier: !_.isEmpty(tncDetails.deeplink) ? tncDetails.deeplink.item_id : null,
          subidentifier: !_.isEmpty(tncDetails.deeplink) ? tncDetails.deeplink.subitem_id : null,
          webUrl: !_.isEmpty(tncDetails.deeplink) ? tncDetails.deeplink.url || null : null,
          screen: !_.isEmpty(tncDetails.deeplink) ? tncDetails.deeplink.screen_name : null,
          imageBase64: tncDetails.image,
          cashbackID: tncDetails.cashback_id,
        },
        disabledIdentifier: !_.isEmpty(tncDetails.deeplink) ? !tncDetails.deeplink.item_id : true,
      });
    });
  };

  handleChangeTNCName = value => {
    const { form, validation } = this.state;
    const message = {
      isError: '',
      errorMessage: '',
    };

    if (!value || value === '') {
      message.isError = 'error';
      message.errorMessage = 'TNC Name Empty';
      this.setState({
        form: { ...form, name: '' },
        validation: { ...validation, name: message },
      });
    } else {
      this.setState({
        form: { ...form, name: value },
        validation: { ...validation, name: message },
      });
    }
  };

  handleChangeIdentifier = value => {
    const { form, validation, disabledIdentifier } = this.state;
    const message = {
      isError: '',
      errorMessage: '',
    };

    if (disabledIdentifier === false) {
      if (!value || value === '') {
        message.isError = 'error';
        message.errorMessage = 'Identifier is empty';
        this.setState({
          form: { ...form, identifier: '' },
          validation: { ...validation, identifier: message },
        });
      } else {
        this.setState({
          form: { ...form, identifier: value.trim() },
          validation: { ...validation, identifier: message },
        });
      }
    } else {
      this.setState({
        form: { ...form, identifier: value.trim() },
        validation: { ...validation, identifier: message },
      });
    }
  };

  handleChangeSubIdentifier = value => {
    const { form } = this.state;
    this.setState({
      form: { ...form, subidentifier: value.trim() },
    });
  };

  handleChangeDescription = value => {
    const { form, validation } = this.state;
    const message = {
      isError: '',
      errorMessage: '',
    };

    if (!value || value === '') {
      message.isError = 'error';
      message.errorMessage = 'Description is empty';
      this.setState({
        validation: { ...validation, description: message },
      });
    } else {
      this.setState({
        form: { ...form, description: value },
        validation: { ...validation, description: message },
      });
    }
  };

  handleChangeWebUrl = value => {
    const { form, validation } = this.state;
    const message = {
      isError: '',
      errorMessage: '',
    };

    if (!value || value === '') {
      message.isError = 'error';
      message.errorMessage = 'Web URL is empty';
      this.setState({
        form: { ...form, webUrl: '' },
        validation: { ...validation, webUrl: message },
      });
    } else {
      this.setState({
        form: { ...form, webUrl: value },
        validation: { ...validation, webUrl: message },
      });
    }
  };

  handleChangeImage = imageBase64 => {
    const { form, validation } = this.state;
    const message = {
      isError: '',
      errorMessage: '',
    };

    if (!imageBase64 || imageBase64 === '') {
      message.isError = 'error';
      message.errorMessage = 'Please choose Image';
      this.setState({
        form: { ...form, imageBase64: '' },
        validation: { ...validation, imageBase64: message },
      });
    } else {
      this.setState({
        form: { ...form, imageBase64 },
        validation: { ...validation, imageBase64: message },
      });
    }
  };

  handleChangeStartDate = value => {
    const { form } = this.state;
    this.setState({
      form: { ...form, startDate: value },
    });
  };

  handleChangeEndDate = value => {
    const { form } = this.state;
    this.setState({
      form: { ...form, endDate: value },
    });
  };

  handleChangePromo = value => {
    const { form } = this.state;
    this.setState({
      form: { ...form, cashbackID: value },
    });

    this.getPromo(value);
  };

  handleChangeDeeplinkType = value => {
    const { form } = this.state;
    this.setState({ form: { ...form, deeplinkType: value } });
  };

  handleChangeScreenType = value => {
    const {
      masterData: { screenList },
    } = this.props;
    const { form, validation } = this.state;

    const message = {
      isError: '',
      errorMessage: '',
    };

    if (!value || value === '') {
      message.isError = 'error';
      message.errorMessage = 'Please choose Screen';
      this.setState({
        form: { ...form, screen: '' },
        validation: { ...validation, screen: message },
      });
    } else {
      const selectedScreen = _.find(screenList, ['value', value]);
      this.setState({
        form: { ...form, screen: value, identifier: null, subidentifier: null },
        disabledIdentifier: !selectedScreen.identifier_mandatory,
        validation: { ...validation, screen: message, identifier: message },
      });
    }
  };

  handleCloseToash = () => {
    const { toastInformation } = this.state;
    this.setState({ toastInformation: { ...toastInformation, isOpen: false } });
  };

  handleBack = () => {
    this.props.history.goBack();
  };

  generateDeeplinkValue = () => {
    const {
      form: { deeplinkType, identifier, subidentifier, webUrl, screen },
    } = this.state;
    let deeplink = null;

    if (deeplinkType === 'screen') {
      deeplink = {
        type: deeplinkType,
        screen_name: screen,
        item_id: identifier,
        subitem_id: subidentifier,
      };
    }

    if (deeplinkType === 'webUrl') {
      deeplink = {
        type: deeplinkType,
        url: webUrl,
      };
    }

    return deeplink;
  };

  handleSubmit = () => {
    const { editTnc } = this.props;

    const {
      form: {
        id,
        name,
        description,
        startDate,
        endDate,
        deeplink,
        deeplinkType,
        identifier,
        subidentifier,
        webUrl,
        screen,
        imageBase64,
        cashbackID
      },
      form,
    } = this.state;

    const params = {
      data: {
        id,
        title: name,
        description,
        image: imageBase64,
        start_date: startDate,
        end_date: endDate,
        cashback_id: cashbackID,
        deeplink: this.generateDeeplinkValue(),
      },
    };

    if (this.validationForm()) {
      this.setState(
        {
          isLoading: true,
        },
        () => {
          editTnc(params)
            .then(() => {
              const {
                tncData: { responseMessage },
                history,
              } = this.props;

              this.processMessage(responseMessage, 'success');
              this.setState(
                {
                  isLoading: false,
                },
                () => {
                  setTimeout(() => {
                    history.push('/marketing/tnc-section');
                  }, 2000);
                },
              );
            })
            .catch(async error => {
              const message = error.data;
              await this.processMessage(message.messages, 'error');
              await this.setState({ isLoading: false });
            });
        },
      );
    }
  };

  processMessage(messages, type) {
    const paramInformation = {
      isOpen: true,
      message: messages,
      snackbarType: type,
    };

    this.setState({
      toastInformation: paramInformation,
    });
  }

  validationForm() {
    this.setState({
      isLoading: true,
    });

    const { form, validation, disabledIdentifier } = this.state;

    let passCheck = true;
    let paramValidation01 = resetValidation;
    let paramValidation02 = resetValidation;
    let paramValidation03 = resetValidation;
    let paramValidation04 = resetValidation;
    let paramValidation05 = resetValidation;
    let paramValidation06 = resetValidation;
    let paramValidation07 = resetValidation;
    let paramValidation08 = resetValidation;

    if (_.isEmpty(form.name)) {
      paramValidation01 = {
        isError: 'error',
        errorMessage: 'Please enter TNC Name',
      };

      passCheck = false;
    }

    if (_.isEmpty(form.startDate)) {
      paramValidation02 = {
        isError: true,
        errorMessage: 'Please enter Start Date',
      };

      passCheck = false;
    }

    if (_.isEmpty(form.endDate)) {
      paramValidation03 = {
        isError: true,
        errorMessage: 'Please enter End Date',
      };

      passCheck = false;
    }

    if (_.isEmpty(form.description)) {
      paramValidation04 = {
        isError: 'error',
        errorMessage: 'Please enter Description',
      };

      passCheck = false;
    }

    if (form.deeplinkType === 'screen') {
      if (_.isEmpty(form.screen)) {
        paramValidation05 = {
          isError: 'error',
          errorMessage: 'Please enter Screen',
        };

        passCheck = false;
      }

      if (disabledIdentifier === false) {
        if (_.isEmpty(form.identifier)) {
          paramValidation06 = {
            isError: 'error',
            errorMessage: 'Please enter Identifier',
          };

          passCheck = false;
        }
      }
    }

    if (form.deeplinkType === 'webUrl') {
      if (_.isEmpty(form.webUrl)) {
        paramValidation07 = {
          isError: 'error',
          errorMessage: 'Please enter WebUrl',
        };

        passCheck = false;
      }
    }

    if (_.isEmpty(form.imageBase64)) {
      paramValidation08 = {
        isError: 'error',
        errorMessage: 'Please choose Image',
      };

      passCheck = false;
    }

    if (!_.isEmpty(form.cashbackID) && !_.isEmpty(validation.cashbackID.isError)) {
      passCheck = false;
    }

    if (!passCheck) {
      this.setState({
        isLoading: false,
        validation: {
          ...validation,
          name: paramValidation01,
          startDate: paramValidation02,
          endDate: paramValidation03,
          description: paramValidation04,
          screen: paramValidation05,
          identifier: paramValidation06,
          webUrl: paramValidation07,
          imageBase64: paramValidation08
        },
      });
    }

    return passCheck;
  }

  render() {
    const {
      toastInformation,
      isLoading,
      form: {
        id,
        name,
        description,
        startDate,
        endDate,
        deeplink,
        deeplinkType,
        screen,
        webUrl,
        identifier,
        subidentifier,
        imageBase64,
        cashbackID,
      },
      form,
      disabledIdentifier,
    } = this.state;

    const { masterData } = this.props;

    const screenListRenameKey = CommonHelper.renameKeyName(
      masterData.screenList,
      'display_text',
      'name',
    );

    // if (isLoading) {
    //   return <SkeletonTncSectionDetails />;
    // }

    // console.log('deeplinkType', deeplinkType);

    return (
      <div className="container-page-tnc-section-edit scroll-container-invisible">
        <div className="container-scrolling-area">
          <Helmet title="FITCO | Marketing - Edit Terms &amp; Conditions" />
          <Grid container direction="column">
            <Grid item lg md className="section-page-header">
              <Grid container>
                <Grid item>
                  <div className="breadcrumbs-section">
                    <Breadcrumbs aria-label="breadcrumb">
                      <Link className="text-12" color="inherit" href="/marketing/tnc-section">
                        <i className="icon-slot ic-ffo-account" /> TNC Section
                      </Link>
                      <label className="text-12" color="inherit">
                        Update
                      </label>
                    </Breadcrumbs>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg md className="section-page-body">
              <div className="tnc-section-details-card dotted">
                <Grid container spacing={3}>
                  <label className="text-16 text-bold text-rolling-stone">Update TNC Section</label>
                </Grid>
                <Grid className="pt-24" container spacing={3}>
                  <Grid container spacing={3}>
                    <Grid item lg={12} md={12}>
                      <FormControl component="fieldset" fullWidth margin={'normal'}>
                        <LabelInput labelText={'TNC Image'} isRequired />
                        <div className="container-image-action">
                          <UploadImage
                            onChange={item => this.handleChangeImage(item)}
                            defaultValue={imageBase64}
                            maxSize={1}
                            validateStatus={this.state.validation.imageBase64.isError}
                            errorMessage={this.state.validation.imageBase64.errorMessage}
                          />
                        </div>
                      </FormControl>
                    </Grid>
                    <Grid item lg={6} md={6}>
                      <Grid container direction="column">
                        <Grid item>
                          <FormControl component="fieldset" fullWidth margin={'normal'}>
                            <LabelInput labelText={'TNC Name'} isRequired />
                            <InputMain
                              placeholder={'TNC Name'}
                              value={name}
                              onChange={this.handleChangeTNCName}
                              validateStatus={this.state.validation.name.isError}
                              errorMessage={this.state.validation.name.errorMessage}
                            />
                          </FormControl>
                        </Grid>
                        <Grid>
                          <FormControl component="fieldset" fullWidth margin={'normal'}>
                            <LabelInput labelText={'Description'} isRequired />
                            <Form.Item>
                              <TextEditor
                                currentValue={description}
                                onChange={this.handleChangeDescription}
                                errorMessage={this.state.validation.description.errorMessage}
                                validateStatus={this.state.validation.description.isError}
                              />
                            </Form.Item>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item lg={6} md={6}>
                      <Grid container direction="row">
                        <Grid container spacing={2}>
                          <Grid item lg={6} md={6}>
                            <FormControl component="fieldset" fullWidth margin={'normal'}>
                              <LabelInput labelText={'Start Date'} />
                              <Form.Item>
                                <PickerInputDate
                                  customIcon="ic-ffo-date-pick"
                                  dateFormat="dd-MM-yyyy"
                                  defaultValue={startDate}
                                  onChange={this.handleChangeStartDate}
                                  toolbar={false}
                                />
                              </Form.Item>
                            </FormControl>
                          </Grid>
                          <Grid item lg={6} md={6}>
                            <FormControl component="fieldset" fullWidth margin={'normal'}>
                              <LabelInput labelText={'End Date'} />
                              <Form.Item>
                                <PickerInputDate
                                  customIcon="ic-ffo-date-pick"
                                  dateFormat="dd-MM-yyyy"
                                  defaultValue={endDate}
                                  onChange={this.handleChangeEndDate}
                                  toolbar={false}
                                />
                              </Form.Item>
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <FormControl component="fieldset" fullWidth margin={'normal'}>
                          <LabelInput labelText={'Promo'} additionalText={' ( Optional ) '} />
                          <Form.Item style={{ marginBottom: 8 }}>
                          <InputMain
                              placeholder={'Enter Promo ID'}
                              value={cashbackID}
                              onChange={this.handleChangePromo}
                              validateStatus={this.state.validation.cashbackID.isError}
                              errorMessage={this.state.validation.cashbackID.errorMessage}
                              isNumber
                            />
                          </Form.Item>
                        </FormControl>
                      </Grid>
                      <Grid item>
                        <FormControl component="fieldset" fullWidth margin={'normal'}>
                          <LabelInput labelText={'Deep Link'} additionalText={' ( Optional ) '} />
                          <Form.Item style={{ marginBottom: 8 }}>
                            <SelectInputMain
                              options={deeplinkOptions}
                              size="large"
                              currentValue={deeplinkType}
                              onChange={this.handleChangeDeeplinkType}
                            />
                          </Form.Item>
                        </FormControl>
                      </Grid>
                      {deeplinkType === 'screen' && (
                        <>
                          <Grid item>
                            <FormControl component="fieldset" fullWidth margin={'normal'}>
                              <LabelInput labelText={'Screen Name'} isRequired />
                              <Form.Item style={{ marginBottom: 8 }}>
                                <SelectInputMain
                                  options={screenListRenameKey}
                                  size="large"
                                  defaultValue={screen}
                                  onChange={this.handleChangeScreenType}
                                  validateStatus={this.state.validation.screen.isError}
                                  errorMessage={this.state.validation.screen.errorMessage}
                                />
                              </Form.Item>
                            </FormControl>
                          </Grid>
                          <Grid item>
                            <FormControl component="fieldset" fullWidth margin={'normal'}>
                              <LabelInput labelText={'Identifier'} isRequired />
                              <Form.Item style={{ marginBottom: 8 }}>
                                <InputMain
                                  placeholder={'Identifier'}
                                  value={identifier}
                                  onChange={this.handleChangeIdentifier}
                                  validateStatus={this.state.validation.identifier.isError}
                                  errorMessage={this.state.validation.identifier.errorMessage}
                                  isNumber
                                  disabled={disabledIdentifier}
                                />
                              </Form.Item>
                            </FormControl>
                          </Grid>
                          <Grid item>
                            <FormControl component="fieldset" fullWidth margin={'normal'}>
                              <LabelInput labelText={'Sub-identifier'} isRequired />
                              <Form.Item style={{ marginBottom: 8 }}>
                                <InputMain
                                  placeholder={'Sub-identifier'}
                                  value={subidentifier}
                                  onChange={this.handleChangeSubIdentifier}
                                  isNumber
                                  disabled={disabledIdentifier}
                                />
                              </Form.Item>
                            </FormControl>
                          </Grid>
                        </>
                      )}
                      {deeplinkType === 'webUrl' && (
                        <Grid item>
                          <FormControl component="fieldset" fullWidth margin={'normal'}>
                            <LabelInput labelText={'Web URL'} isRequired />
                            <Form.Item style={{ marginBottom: 8 }}>
                              <InputMain
                                placeholder={'Web URL'}
                                value={webUrl}
                                onChange={this.handleChangeWebUrl}
                                validateStatus={this.state.validation.webUrl.isError}
                                errorMessage={this.state.validation.webUrl.errorMessage}
                              />
                            </Form.Item>
                          </FormControl>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid item lg md className="section-page-footer">
              <Grid container justify="flex-end" spacing={3}>
                <Grid item lg={2} md={2}>
                  <ButtonMain
                    type="ghost"
                    size="xl"
                    l
                    labelText="Cancel"
                    onClick={this.handleBack}
                  />
                </Grid>
                <Grid item lg={2} md={2}>
                  <ButtonMain
                    type="primary"
                    s
                    size="xl"
                    labelText="Update"
                    onClick={this.handleSubmit}
                    isLoading={this.state.isLoading}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <SnackBarSimple
            open={toastInformation.isOpen}
            durationHide={2000}
            message={toastInformation.message}
            onClickClose={this.handleCloseToash}
            snackbarType={toastInformation.snackbarType}
            anchor={optionToash}
          />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getScreenList: () => getScreenDeeplinkList(dispatch),
  getTncDetails: tncId => getTncSectionDetails(dispatch, tncId),
  editTnc: params => editTncSection(dispatch, params),
  getPromoDetails: promoID => getPromoCodeDetails(dispatch, promoID)
});

const mapStateToProps = ({ masterData, tncData }) => ({
  masterData,
  tncData,
});

TncSectionEdit.propTypes = {
  checkUserAccessPermission: PropTypes.func,
  editTnc: PropTypes.func,
  getPromoDetails: PropTypes.func,
  getScreenList: PropTypes.func,
  getTncDetails: PropTypes.func,
  history: PropTypes.object,
  masterData: PropTypes.object,
};

const shell = compose(connect(mapStateToProps, mapDispatchToProps));
const core = compose(AuthenticationAccessPages, PrevStateValue);

export default shell(core(TncSectionEdit));
