import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
// Style
import './ButtonActionFooterStyle.scss';
// Component
import { ButtonMain, PrevNextStepperCount } from '../../../../../components/Index';

const ButtonActionFooter = props => {
  const {
    customElementProps,
    onButtonClickCancel,
    onNextClick,
    customNote,
    loadingButtonNext,
  } = props;

  return (
    <Grid container className="container-card-button-action">
      <Grid item lg md>
        <Grid container justify="space-between" alignItems="center">
          <Grid item lg={3} md={4}>
            {customElementProps}
          </Grid>
          {customNote && (
            <Grid item lg={6} md={5}>
              <div className="p-24">
                <strong>
                  <sup>*</sup>Note:
                </strong>{' '}
                {customNote}
              </div>
            </Grid>
          )}
          <Grid item lg={3} md={3}>
            <div className="container-button-action">
              <div className="container-button">
                <ButtonMain
                  type="negative"
                  size="xl"
                  labelText="Cancel"
                  onClick={onButtonClickCancel}
                />
              </div>
              <div className="container-button">
                <ButtonMain
                  type="primary"
                  size="xl"
                  labelText="Next"
                  onClick={onNextClick}
                  isLoading={loadingButtonNext}
                  disabled={loadingButtonNext}
                />
              </div>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

ButtonActionFooter.propTypes = {
  customElementProps: PropTypes.element,
  customNote: PropTypes.string,
  loadingButtonNext: PropTypes.bool,
  onButtonClickCancel: PropTypes.func,
  onNextClick: PropTypes.func,
};

export default PrevNextStepperCount(ButtonActionFooter);
