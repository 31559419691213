import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// component
import { SelectInputSearchMain } from '../../Index';
// helpers
import { CommonHelper } from '../../../helpers/Index';

class SelectInputScheduleHour extends React.Component {
  handleSelectItem = value => {
    const { onChange } = this.props;
    onChange(value);
  };

  generateListDataDisplay() {
    const {
      includeAllData,
      availableHours,
      availableClassSchedule,
      isClasses,
      availableSlotOnly,
    } = this.props;
    const converted = [];
    let param = [];
    let currentData = CommonHelper.renameKeyName(availableHours, 'training_schedule_id', 'value');
    if (isClasses) {
      currentData = CommonHelper.renameKeyName(
        availableClassSchedule,
        'training_schedule_id',
        'value',
      );
    }

    if (includeAllData) {
      param = { value: null, name: 'Select Time' };
      converted.push(param);
    }

    if (currentData.length > 0) {
      if (availableSlotOnly) {
        currentData = currentData.filter(item => {
          return !item.is_full;
        });
      }

      currentData.forEach(item => {
        const startTime = item.start_time.replace(' ', 'T');
        const endTime = item.end_time.replace(' ', 'T');
        const hour = startTime.substring(11, 16);
        const endHour = endTime.substring(11, 16);
        param = { value: item.value, name: `${hour} - ${endHour}` };
        converted.push(param);
      });
    }

    return converted;
  }

  render() {
    const {
      placeHolder,
      defaultValue,
      currentValue,
      errorMessage,
      validateStatus,
      size,
      disabled,
    } = this.props;

    const ListCustom = this.generateListDataDisplay();

    return (
      <SelectInputSearchMain
        options={ListCustom}
        onChange={item => {
          this.handleSelectItem(item);
        }}
        currentValue={currentValue}
        defaultValue={defaultValue}
        errorMessage={errorMessage}
        validateStatus={validateStatus}
        size={size}
        placeholder={placeHolder}
        disabled={disabled}
      />
    );
  }
}

const mapDispatchToProps = () => ({});

const mapStateToProps = ({ scheduleSummary }) => ({
  availableHours: scheduleSummary.availableHours,
  availableClassSchedule: scheduleSummary.availableClassSchedule,
});

SelectInputScheduleHour.propTypes = {
  availableClassSchedule: PropTypes.array,
  availableHours: PropTypes.array,
  availableSlotOnly: PropTypes.bool,
  currentValue: PropTypes.number,
  defaultValue: PropTypes.number,
  disabled: PropTypes.bool,
  errorMessage: PropTypes.string,
  includeAllData: PropTypes.bool,
  isClasses: PropTypes.bool,
  onChange: PropTypes.func,
  placeHolder: PropTypes.string,
  size: PropTypes.string,
  validateStatus: PropTypes.string,
};

SelectInputScheduleHour.defaultProps = {
  placeHolder: '',
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectInputScheduleHour);
