export const constants = {
  setSideBarOpen: 'SET_SIDE_BAR_OPEN',
};

const initState = {
  sideBarOpen: true,
};

export default function settingData(state = initState, action) {
  const { type, payload } = action;

  switch (type) {
    case constants.setSideBarOpen:
      return { ...state, sideBarOpen: payload };

    default:
      return state;
  }
}
