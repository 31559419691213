import _ from 'lodash';

const PrintHelper = {
  generateDataPrintMenuEat(key, dataArray) {
    const converted = [];

    key.forEach((item, index) => {
      const param = _.find(dataArray, ['food_id', item]);
      if (param) {
        converted.push({
          id: `qrCode${index}`,
          qrCode: param.qr_code_url,
          productName: param.product_name,
          price: param.price,
        });
      }
    });

    const result = converted;

    return result;
  },
};

export default PrintHelper;
