/* eslint-disable react/no-access-state-in-setstate */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Table } from 'antd';
import _ from 'lodash';
import { Grid } from '@material-ui/core';
// Component
import {
  SnackBarSimple,
  SkeletonMain,
  LabelStatusPayment,
  TextInput,
  PickerInputDate,
  SelectInputGeneral,
} from '../../../../../../components/Index';
// Api
import { getConsultation } from '../../../../../../services/api/ScheduleDataApi';
import { getConsultationBranches } from '../../../../../../services/api/MasterDataApi';
// Helpers
import {
  CommonHelper,
  MasterDataHelper,
  PermissionModule,
  PermissionPage,
  PermissionAccess,
  PermissionHelper,
} from '../../../../../../helpers/Index';
// Style
import './CompletedAppointmentListStyles.scss';

const consultationBookingStatusOption = MasterDataHelper.consultationBookingStatusOption;
const dateFilterStatusOption = MasterDataHelper.dateFilterStatusOption;
const today = CommonHelper.currentDate('YYYY-MM-DD HH:mm:ss');
const currentDate = CommonHelper.dateTimeParseNewFormat(today, 'YYYY-MM-DD');
const eatActionOptions = [];

const hasEditPermission = PermissionHelper.checkUserHasPermission(
  PermissionModule.Eat,
  PermissionPage.Food,
  PermissionAccess.Update,
);
if (hasEditPermission) {
  eatActionOptions.push(MasterDataHelper.eatActionOptions.find(x => x.value === 'edit'));
}

const hasDeletePermission = PermissionHelper.checkUserHasPermission(
  PermissionModule.Eat,
  PermissionPage.Food,
  PermissionAccess.Delete,
);
if (hasDeletePermission) {
  eatActionOptions.push(MasterDataHelper.eatActionOptions.find(x => x.value === 'delete'));
}

const initialSort = {
  sortField: 'product_name',
  sortOrder: 'asc',
};

const optionToash = {
  vertical: 'top',
  horizontal: 'right',
};

const initialFilter = {
  searchTerm: '',
  date: currentDate,
  branchLocation: null,
  bookingStatus: null,
  filterByDate: null,
};

class CompletedAppointmentList extends React.Component {
  constructor(props) {
    super(props);

    const updateExitingFilter = {
      ...CommonHelper.objectCloning(initialFilter),
    };

    this.state = {
      filter: updateExitingFilter,
      page: 1,
      limit: 8,
      sort: CommonHelper.objectCloning(initialSort),
      pagination: {
        showSizeChanger: true,
        showTotal: (total, range) => `Showing ${range[0]}-${range[1]} from ${total}`,
        pageSizeOptions: ['10', '20', '30', '40'],
      },
      loading: false,
      isLoading: true,
      toastInformation: {
        isOpen: false,
        message: '',
        snackbarType: 'warning',
      },
    };

    this.searchDebounce = _.debounce(this.searchDebounce, 400);
  }

  componentDidMount() {
    this.getConsultationApi();
  }

  getConsultationApi = async () => {
    const { callGetConsultationApi } = this.props;
    const { limit, page, filter } = this.state;
    try {
      this.setState({ loading: true });
      const body = {
        is_history: true,
        branch_location: filter.branchLocation,
        date: filter.filterByDate === 'specific_date' ? filter.date : null,
        search_term: filter.searchTerm,
        booking_status: filter.bookingStatus,
        page,
        limit,
        sortBy: '',
        order: 'asc',
      };
      await callGetConsultationApi(body);
      this.setState({ isLoading: false, loading: false });
    } catch (error) {
      this.processMessage('Error', 'error');
    }
  };

  getBranchApi = async () => {
    const { callGetBranchApi } = this.props;
    try {
      await callGetBranchApi('sport-clinic');
    } catch (error) {
      this.processMessage('Error', 'error');
    }
  };

  searchDebounce = () => {
    this.getConsultationApi();
  };

  handleCloseToash = () => {
    const { toastInformation } = this.state;
    this.setState({ toastInformation: { ...toastInformation, isOpen: false } });
  };

  handleTableChange = (pagination, filters, sorter) => {
    const { sort } = this.state;

    this.setState(
      {
        limit: pagination.pageSize,
        pagination: {
          ...this.state.pagination,
          current: pagination.current,
          pageSize: pagination.pageSize,
        },
        sort: {
          ...sort,
          sortField: sorter.field || initialSort.sortField,
          sortOrder: sorter.order === 'ascend' || !sorter.order ? 'asc' : 'desc',
        },
        page: pagination.current,
      },
      () => {
        this.getConsultationApi();
      },
    );
  };

  handleChangeSearch = value => {
    const { filter } = this.state;

    this.setState(
      {
        filter: { ...filter, searchTerm: value },
        page: 1,
        pagination: { ...this.state.pagination, current: 1 },
      },
      () => {
        this.searchDebounce();
      },
    );
  };

  handleChangeBranchLocation = value => {
    const { filter } = this.state;

    this.setState(
      {
        filter: { ...filter, branchLocation: value },
      },
      () => {
        this.getConsultationApi();
      },
    );
  };

  handleChangeDate = value => {
    const { filter } = this.state;

    this.setState(
      {
        filter: { ...filter, date: value },
      },
      () => {
        this.getConsultationApi();
      },
    );
  };

  handleChangeBookingStatus = value => {
    const { filter } = this.state;

    this.setState(
      {
        filter: { ...filter, bookingStatus: value },
      },
      () => {
        this.getConsultationApi();
      },
    );
  };

  handleChangeFilterDate = value => {
    const { filter } = this.state;

    this.setState({ filter: { ...filter, filterByDate: value } }, () => {
      this.getConsultationApi();
    });
  };

  processMessage(messages, type) {
    const convertedMessage = CommonHelper.generateMessage(messages);

    const paramInformation = {
      isOpen: true,
      message: convertedMessage,
      snackbarType: type,
    };

    this.setState({
      toastInformation: paramInformation,
    });
  }

  renderColumns = () => {
    return [
      {
        title: 'Patient',
        dataIndex: 'name',
        render: (text, row) => {
          return (
            <Grid
              container
              direction="column"
              spacing={3}
              className="pv-16 flex-wrap-unset"
              onClick={() => {}}
            >
              <Grid item lg md>
                <div>
                  <div>
                    <label className="text-15 text-bold mb-8">{row.name}</label>
                  </div>
                  <div>
                    <label className="text-13 subtitle">
                      {row.email} | {row.phone}
                    </label>
                  </div>
                </div>
              </Grid>
              <Grid item lg md>
                <Grid container direction="row" spacing={2}>
                  <Grid item>
                    <div>
                      <div>
                        <label className="text-14 text-medium text-rolling-stone mb-8">
                          Order ID
                        </label>
                      </div>
                      <div>
                        <label className="text-12 text-bold text-mine-shaft">
                          {row.sales_order_id}
                        </label>
                      </div>
                    </div>
                  </Grid>
                  <Grid item>
                    <div className="divider-hr-vertical" />
                  </Grid>
                  <Grid item>
                    <div>
                      <div>
                        <label className="text-14 text-medium text-rolling-stone mb-8">
                          Doctor
                        </label>
                      </div>
                      <div>
                        <label className="text-12 text-bold text-mine-shaft">
                          {(row.doctor && row.doctor.name) || '-'}
                        </label>
                      </div>
                    </div>
                  </Grid>
                  <Grid item>
                    <div className="divider-hr-vertical" />
                  </Grid>
                  <Grid item>
                    <div>
                      <div>
                        <label className="text-14 text-medium text-rolling-stone mb-8">
                          Location
                        </label>
                      </div>
                      <div>
                        <label className="text-12 text-bold text-mine-shaft">
                          {row.branch_name}
                        </label>
                      </div>
                    </div>
                  </Grid>
                  <Grid item>
                    <div className="divider-hr-vertical" />
                  </Grid>
                  <Grid item>
                    <div>
                      <div>
                        <label className="text-14 text-medium text-rolling-stone mb-8">Date</label>
                      </div>
                      <div>
                        <label className="text-12 text-bold text-mine-shaft">{row.date}</label>
                      </div>
                    </div>
                  </Grid>
                  <Grid item>
                    <div className="divider-hr-vertical" />
                  </Grid>
                  <Grid item>
                    <div>
                      <div>
                        <label className="text-14 text-medium text-rolling-stone mb-8">Time</label>
                      </div>
                      <div>
                        <label className="text-12 text-bold text-mine-shaft">{row.time}</label>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          );
        },
      },
      {
        title: 'Grand Total',
        align: 'center',
        render: (text, row) => {
          return (
            <Grid item>
              <Grid container direction="row" className="order-amount-section">
                <Grid item lg md>
                  <Grid
                    container
                    direction="column"
                    justify="center"
                    className="order-items-amount-text"
                  >
                    <Grid item lg md className="vertical-align-center">
                      <span className="flex-row text-center">
                        <label className="text-16 text-bold currency">
                          IDR {CommonHelper.formatCurrency(row.total_paid)}
                        </label>
                      </span>
                    </Grid>
                    <Grid item lg md className="pt-4 vertical-align-start">
                      <LabelStatusPayment status={row.payment_status} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          );
        },
      },
    ];
  };

  renderFilter() {
    const { filter } = this.state;
    const {
      masterData: { consultationBranches },
    } = this.props;
    return (
      <Grid container direction="row" justify="flex-start" className="container-filter">
        <Grid item lg={6} md={6}>
          <Grid container direction="row">
            <Grid item className="row-filter">
              <div className="container-remove-margin">
                <SelectInputGeneral
                  placeHolder="Select Status"
                  currentValue={filter.bookingStatus}
                  dataList={consultationBookingStatusOption}
                  primaryKey="value"
                  onChange={this.handleChangeBookingStatus}
                />
              </div>
            </Grid>
            <Grid item lg={4} md={4} className="row-filter">
              <div className="container-remove-margin">
                <SelectInputGeneral
                  placeHolder="All Location"
                  currentValue={filter.branchLocation}
                  dataList={consultationBranches}
                  primaryKey="branch_id"
                  onChange={this.handleChangeBranchLocation}
                  includeAllData
                />
              </div>
            </Grid>
            <Grid item lg md className="row-filter">
              <TextInput
                iconPrefix="ic-ffo-search"
                placeHolderText="Search Patient Name"
                onChange={this.handleChangeSearch}
                size="md"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg md>
          <Grid container direction="row" justify="flex-end">
            <Grid item className="row-filter">
              <div className="container-remove-margin">
                <SelectInputGeneral
                  placeHolder="All Date"
                  currentValue={filter.filterByDate}
                  dataList={dateFilterStatusOption}
                  onChange={this.handleChangeFilterDate}
                />
              </div>
            </Grid>
            <Grid item className="row-filter">
              <PickerInputDate
                customIcon="ic-ffo-date-pick"
                dateFormat="dd/MM/yyyy"
                onChange={this.handleChangeDate}
                defaultValue={filter.date}
                disabled={filter.filterByDate !== 'specific_date'}
                toolbar
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  render() {
    const { isLoading, loading, pagination, toastInformation } = this.state;
    const { consultationList } = this.props;

    let renderElement = <SkeletonMain />;

    if (!isLoading) {
      renderElement = (
        <div className="container-page-consultation scroll-container">
          <div className="container-page-scrolling-area include-tab">
            <Grid container direction="column" className="flex-wrap-unset">
              <Grid item lg md sm className="section-page-header">
                {this.renderFilter()}
              </Grid>
              <Grid item lg md className="section-page-body">
                <Table
                  columns={this.renderColumns()}
                  rowKey={record => record.sales_order_id}
                  dataSource={consultationList}
                  pagination={pagination}
                  loading={loading}
                  onChange={this.handleTableChange}
                />
              </Grid>
            </Grid>
            <SnackBarSimple
              open={toastInformation.isOpen}
              durationHide={2000}
              message={toastInformation.message}
              onClickClose={this.handleCloseToash}
              snackbarType={toastInformation.snackbarType}
              anchor={optionToash}
            />
          </div>
        </div>
      );
    }
    return renderElement;
  }
}

const mapDispatchToProps = dispatch => ({
  callGetConsultationApi: body => getConsultation(dispatch, body),
  callGetBranchApi: category => getConsultationBranches(dispatch, category),
});

const mapStateToProps = ({ scheduleSummary, masterData }) => ({
  consultationList: scheduleSummary.consultationList,
  masterData,
});

CompletedAppointmentList.propTypes = {
  callGetBranchApi: PropTypes.func,
  callGetConsultationApi: PropTypes.func,
  consultationList: PropTypes.array,
  masterData: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(CompletedAppointmentList);
