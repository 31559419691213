/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
// Components
import {
  GridRowTableOneColumn,
  UploadImage,
  ButtonIconMain,
} from '../../../../../../../../components/Index';
// Helpers
import { CommonHelper } from '../../../../../../../../helpers/Index';
// Styles
import './CustomerItemBookingStyle.scss';

const CustomerItemBooking = ({ customerList, onRemove, onUpdatePhoto }) => {
  const [expandedItem, setExpandedItem] = useState(null);

  return (
    <Grid container direction="column" className="container-customer-booking-item">
      {customerList.map((customer, index) => {
        return (
          <CustomerList
            key={customer.id.toString()}
            item={customer}
            index={index}
            expandedItem={expandedItem}
            setExpandedItem={setExpandedItem}
            onRemove={onRemove}
            onUpdatePhoto={onUpdatePhoto}
          />
        );
      })}
    </Grid>
  );
};

const CustomerList = ({ item, index, expandedItem, setExpandedItem, onRemove, onUpdatePhoto }) => {
  const [collapse, setCollapse] = useState(true);

  useEffect(() => {
    const isSelected = item.id === expandedItem;
    setCollapse(!isSelected);
  }, [expandedItem, item.id]);

  const onClickHandler = customer => {
    setCollapse(value => !value);
    setExpandedItem(customer.id);
  };

  const onRemoveHandler = removedItem => {
    onRemove(removedItem.id);
  };

  const onUpdatePhotoHandler = imageBase64 => {
    const datas = {
      ...item,
      imageBase64,
    };
    onUpdatePhoto(datas);
  };

  const iconName = collapse ? 'ic-ffo-chevron-down' : 'ic-ffo-chevron-top';

  return (
    <Grid container className="container-row-booking-item">
      <Grid item lg={12} md={12}>
        <div className="container-row-booking-heading">
          <div
            onClick={() => onClickHandler(item)}
            role="button"
            onKeyDown={() => onClickHandler(item)}
            tabIndex={0}
            className="row-center"
          >
            <span className="header-title">
              {`#${index + 1}`} - {item.identity_details.name}
            </span>
            <div>
              <i className={`${iconName} container-icon-prefix size-16`} />
            </div>
          </div>
          {!item.isPrimary && (
            <ButtonIconMain
              icon="ic-ffo-bin"
              type="negative"
              size="sm"
              onClick={() => onRemoveHandler(item)}
              tooltip="Cancel"
            />
          )}
        </div>
      </Grid>
      {collapse ? null : (
        <Grid item lg={12} md={12} className="container-row-booking-detail">
          <Grid container spacing={3}>
            <Grid item lg={6} md={6}>
              <Grid
                container
                direction="column"
                className="container-row-booking-table details flex-wrap-unset"
              >
                <GridRowTableOneColumn
                  customElementInitial={<label className="text-14 title">Test</label>}
                  customElementColumn={
                    <label className="text-14">
                      {item.productDetails && item.productDetails.name}
                    </label>
                  }
                  columnLeft={5}
                  columnRight={7}
                />
                <GridRowTableOneColumn
                  customElementInitial={<label className="text-14 title">Date of Birth</label>}
                  customElementColumn={
                    <label className="text-14">
                      {CommonHelper.dateTimeParseNewFormat(
                        item.identity_details.birthday,
                        'DD MMM YYYY',
                      )}
                    </label>
                  }
                  columnLeft={5}
                  columnRight={7}
                />
                <GridRowTableOneColumn
                  customElementInitial={<label className="text-14 title">Gender</label>}
                  customElementColumn={
                    <label className="text-14">{item.gender && item.gender.name}</label>
                  }
                  columnLeft={5}
                  columnRight={7}
                />
                <GridRowTableOneColumn
                  customElementInitial={<label className="text-14 title">Identity Type</label>}
                  customElementColumn={
                    <label className="text-14">{item.identity_details.identity_type}</label>
                  }
                  columnLeft={5}
                  columnRight={7}
                />
                <GridRowTableOneColumn
                  customElementInitial={<label className="text-14 title">Nationality</label>}
                  customElementColumn={
                    <label className="text-14">
                      {item.countryDetails && item.countryDetails.name}
                    </label>
                  }
                  columnLeft={5}
                  columnRight={7}
                />
                <GridRowTableOneColumn
                  customElementInitial={<label className="text-14 title">Identity Number</label>}
                  customElementColumn={
                    <label className="text-14">{item.identity_details.identity_number}</label>
                  }
                  columnLeft={5}
                  columnRight={7}
                />
                <GridRowTableOneColumn
                  customElementInitial={<label className="text-14 title">Kode Booking</label>}
                  customElementColumn={<label className="text-14">-</label>}
                  columnLeft={5}
                  columnRight={7}
                />
              </Grid>
            </Grid>
            <Grid item lg={6} md={6}>
              <UploadImage
                defaultValue={item.imageBase64}
                maxSize={5}
                onChange={data => onUpdatePhotoHandler(data)}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default CustomerItemBooking;
