import React from 'react';
import PropTypes from 'prop-types';
import { Grid, IconButton, FormControl, FormLabel } from '@material-ui/core';
import { connect } from 'react-redux';
// component
import {
  ModalInformationPopUp,
  ButtonMain,
  SnackBarSimple,
  PickerInputDate,
  SelectInputScheduleHour,
  SelectInputGeneral,
} from '../../../../../../components/Index';
import { SkeletonScheduleDetails } from '../Index';
// Api
import {
  getAvailableClassSchedule,
  rescheduleConsultation,
} from '../../../../../../services/api/ScheduleDataApi';
import { getBranchesByProduct } from '../../../../../../services/api/MasterDataApi';
// helper
import { CommonHelper } from '../../../../../../helpers/Index';

const today = CommonHelper.currentDate('YYYY-MM-DD HH:mm:ss');
const currentDate = CommonHelper.dateTimeParseNewFormat(today, 'YYYY-MM-DD');

const initialFilter = {
  startDate: currentDate,
  branchId: null,
  productId: null,
  trainingScheduleId: null,
};

const optionToash = {
  vertical: 'top',
  horizontal: 'right',
};

class ModalRescheduleEms extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      filter: initialFilter,
      toastInformation: {
        isOpen: false,
        message: '',
        snackbarType: 'warning',
      },
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.isOpen !== prevProps.isOpen && this.props.isOpen) {
      this.setInitialFilter();
    }
  }

  setInitialFilter = () => {
    const { filter } = this.state;
    const { selectedScheduleDetail } = this.props;
    const { productId, branchId } = selectedScheduleDetail;
    this.setState(
      {
        filter: {
          ...filter,
          branchId,
          productId,
        },
      },
      () => {
        this.getAvailableClassScheduleApi(filter.startDate, branchId);
        this.getBranchByProductIdApi(productId);
      },
    );
  };

  getBranchByProductIdApi = async value => {
    const { callBranchByProductIdApi } = this.props;
    await callBranchByProductIdApi(value);
  };

  getAvailableClassScheduleApi = async (date, branchId) => {
    const { callGetAvailableClassScheduleApi, selectedScheduleDetail } = this.props;
    const { productId } = selectedScheduleDetail;
    if (!branchId) return;
    const params = { date, branch_id: branchId, is_dashboard: true };
    await callGetAvailableClassScheduleApi(productId, params);
    this.setState({
      isLoading: false,
    });
  };

  handleClose = () => {
    const { onClose } = this.props;
    onClose(false);
  };

  handleCloseToash = () => {
    const { toastInformation } = this.state;
    this.setState({ toastInformation: { ...toastInformation, isOpen: false } });
  };

  handleSelectBranch = value => {
    const { filter } = this.state;
    this.setState(
      {
        filter: { ...filter, branchId: value || null },
      },
      () => {
        this.getAvailableClassScheduleApi(filter.startDate, value);
      },
    );
  };

  handleSelectStartDateChange = value => {
    const { filter } = this.state;
    this.setState(
      {
        filter: { ...filter, startDate: value || null },
      },
      () => {
        this.getAvailableClassScheduleApi(value, filter.branchId);
      },
    );
  };

  handleSelectHour = value => {
    const { filter } = this.state;
    this.setState({
      filter: { ...filter, trainingScheduleId: value || null },
    });
  };

  handleReschedule = async () => {
    const { filter } = this.state;
    const {
      selectedScheduleDetail,
      onSuccessReschedule,
      callRescheduleConsultationApi,
    } = this.props;
    try {
      const salesOrderId = selectedScheduleDetail.salesOrderId;
      const body = {
        training_schedule_id: filter.trainingScheduleId,
      };
      const response = await callRescheduleConsultationApi(salesOrderId, body);
      if (response.status === 'error') {
        throw response;
      }
      const message = response.messages;
      onSuccessReschedule(message);
      this.setState({
        filter: { ...initialFilter },
      });
    } catch (error) {
      const message = error.messages;
      this.processMessage(message, 'error');
    }
  };

  processMessage(messages, type) {
    const convertedMessage = CommonHelper.generateMessage(messages);

    const paramInformation = {
      isOpen: true,
      message: convertedMessage,
      snackbarType: type,
    };

    this.setState({
      toastInformation: paramInformation,
    });
  }

  render() {
    const {
      isOpen,
      selectedScheduleDetail,
      masterData: { branchList },
    } = this.props;
    const { isLoading, toastInformation, filter } = this.state;

    let renderElement = <SkeletonScheduleDetails />;

    if (!isLoading) {
      renderElement = (
        <Grid container direction="column" className="flex-wrap-unset">
          <Grid item lg md className="section-header-modal">
            <Grid container>
              <Grid item lg={11} md={11}>
                <h5 className="wrapping-container second line">Reschedule</h5>
              </Grid>
              <Grid item>
                <Grid container direction="column" justify="flex-end">
                  <Grid item>
                    <div className="container-button-close">
                      <IconButton onClick={this.handleClose}>
                        <i className="ic-ffo-close container-icon-prefix size-21" />
                      </IconButton>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg md className="section-body-modal">
            <Grid container direction="column" className="flex-wrap-unset">
              <Grid item lg md>
                <Grid container className="container-time-and-slot-coach">
                  <Grid item lg md>
                    <div className="flex-column">
                      <label className="text-12 title text-rolling-stone">Member Name</label>
                      <label className="text-13">{selectedScheduleDetail.clientName}</label>
                    </div>
                  </Grid>
                  <Grid item lg md>
                    <div className="flex-column">
                      <label className="text-12 title text-rolling-stone">Product</label>
                      <label className="text-13">{selectedScheduleDetail.scheduleName}</label>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item lg md className="row-modal">
                <Grid container className="flex-wrap-unset">
                  <Grid item lg md>
                    <div className="flex-column">
                      <label className="text-12 title text-rolling-stone">
                        Current Appointment
                      </label>
                      <label className="text-13">
                        {selectedScheduleDetail.appointmentDate} |{' '}
                        {selectedScheduleDetail.dateStart}
                      </label>
                    </div>
                  </Grid>
                  <Grid item lg md>
                    <div className="flex-column">
                      <label className="text-12 title text-rolling-stone">Location</label>
                      <label className="text-13">{selectedScheduleDetail.location}</label>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item lg md className="row-modal">
                <Grid container className="flex-wrap-unset">
                  <Grid item lg md>
                    <FormControl component="fieldset" fullWidth margin="normal">
                      <FormLabel component="label" className="text-12 title">
                        Training Location
                      </FormLabel>
                      <SelectInputGeneral
                        placeHolder="Select Branch"
                        currentValue={filter.branchId}
                        dataList={branchList}
                        primaryKey="branch_id"
                        onChange={this.handleSelectBranch}
                        // errorMessage={validation.branchId.errorMessage}
                        // validateStatus={validation.branchId.isError ? 'error' : ''}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item lg md />
                </Grid>
              </Grid>
              <Grid item lg md className="row-modal">
                <Grid container className="flex-wrap-unset" spacing={2}>
                  <Grid item lg md>
                    <FormControl component="fieldset" fullWidth margin="normal">
                      <FormLabel component="label" className="text-12 title">
                        Date
                      </FormLabel>
                      <PickerInputDate
                        customIcon="ic-ffo-date-pick"
                        dateFormat="dd-MM-yyyy"
                        minDate={currentDate}
                        onChange={this.handleSelectStartDateChange}
                        defaultValue={filter.startDate}
                        toolbar
                      />
                    </FormControl>
                  </Grid>
                  <Grid item lg md>
                    <FormControl component="fieldset" fullWidth margin="normal">
                      <FormLabel component="label" className="text-12 title">
                        Time
                      </FormLabel>
                      <SelectInputScheduleHour
                        placeHolder="Select Time"
                        onChange={this.handleSelectHour}
                        availableSlotOnly
                        isClasses
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item className="section-footer-modal">
            <Grid container justify="flex-end" spacing={2}>
              <Grid item lg={3} md={3}>
                <ButtonMain labelText="Cancel" onClick={this.handleClose} type="ghost" size="xl" />
              </Grid>
              <Grid item lg={3} md={3}>
                <ButtonMain
                  labelText="Save"
                  onClick={this.handleReschedule}
                  type="primary"
                  size="xl"
                />
              </Grid>
            </Grid>
          </Grid>
          <SnackBarSimple
            open={toastInformation.isOpen}
            durationHide={2000}
            message={toastInformation.message}
            onClickClose={this.handleCloseToash}
            snackbarType={toastInformation.snackbarType}
            anchor={optionToash}
          />
        </Grid>
      );
    }

    return (
      <ModalInformationPopUp
        isOpen={isOpen}
        onClose={this.handleClose}
        customElementProps={renderElement}
      />
    );
  }
}

const mapDispatchToProps = dispatch => ({
  callGetAvailableClassScheduleApi: (productId, params) =>
    getAvailableClassSchedule(dispatch, productId, params),
  callBranchByProductIdApi: category => getBranchesByProduct(dispatch, category),
  callRescheduleConsultationApi: (salesOrderId, body) =>
    rescheduleConsultation(dispatch, salesOrderId, body),
});

const mapStateToProps = ({ masterData }) => ({ masterData });

ModalRescheduleEms.propTypes = {
  callBranchByProductIdApi: PropTypes.func,
  callGetAvailableClassScheduleApi: PropTypes.func,
  callRescheduleConsultationApi: PropTypes.func,
  isOpen: PropTypes.bool,
  masterData: PropTypes.object,
  onClose: PropTypes.func,
  onSuccessReschedule: PropTypes.func,
  selectedScheduleDetail: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalRescheduleEms);
