import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import _ from 'lodash';
// Style
import './CartItemDetailMembershipStyle.scss';
// Component
import { GridRowTableFourColumn, GridRowTableOneColumn } from '../../../../../components/Index';
import { CommonHelper, OrderHelper } from '../../../../../helpers/Index';
// helper

const CartItemDetailMembership = props => {
  const { orderData } = props;

  const orderSummaryData = !_.isEmpty(orderData) ? orderData.order_summary : {};
  let orderItems = orderSummaryData.product_details || [];

  if (orderSummaryData.is_package) {
    orderItems = OrderHelper.membershipGroupingItem(orderSummaryData.product_details);
  }

  return (
    <Grid container className="pt-24" direction="row" spacing={4}>
      <Grid item lg={12} md={12}>
        <label className="text-16 text-opacity-50">Order Summary</label>
      </Grid>
      <Grid item lg={12} md={12} className="container-order-cart-item-details-retails pt-32">
        <GridRowTableFourColumn
          customElementColumnFirst={
            <label className="text-14 header-title wrapping-container third line wrapping-container-break">
              Product Name
            </label>
          }
          customElementColumnSecond={
            <div className="text-center">
              <label className="text-14 header-title">Price</label>
            </div>
          }
          customElementColumnThird={
            <div className="text-center">
              <label className="text-14 header-title">Quantity</label>
            </div>
          }
          customElementColumnFourth={
            <div className="text-right">
              <label className="text-14 header-title">Subtotal</label>
            </div>
          }
          columnFirst={5}
          columnSecond={2}
          columnThird={2}
          columnFourth={3}
        />
        <div className="scroll-container">
          <Grid container direction="column" className="flex-wrap-unset">
            {orderItems.map((item, index) => {
              const priceTotal = CommonHelper.formatCurrency(
                item.quantity * parseInt(item.item_price, 10),
              );
              const priceItem = CommonHelper.formatCurrency(item.item_price);

              return (
                <GridRowTableFourColumn
                  key={index}
                  customElementColumnFirst={
                    <label className="text-14 row-item-title wrapping-container third line wrapping-container-break">
                      {item.product_name}
                    </label>
                  }
                  customElementColumnSecond={
                    <div className="text-center">
                      <label className="text-14">IDR {priceItem}</label>
                    </div>
                  }
                  customElementColumnThird={
                    <div className="text-center">
                      <label className="text-14">{item.quantity}</label>
                    </div>
                  }
                  customElementColumnFourth={
                    <div className="text-right">
                      <label className="text-14">IDR {priceTotal}</label>
                    </div>
                  }
                  columnFirst={5}
                  columnSecond={2}
                  columnThird={2}
                  columnFourth={3}
                />
              );
            })}
          </Grid>
        </div>
        {orderSummaryData.shipping_title && (
          <GridRowTableOneColumn
            customElementInitial={
              <div className="flex-column">
                <label className="text-14 row-item-title">Shipping Fee</label>
                <label className="text-10">{orderSummaryData.shipping_title}</label>
              </div>
            }
            customElementColumn={
              <label className="text-14">
                {orderSummaryData.shipping_fee > 0
                  ? `IDR ${CommonHelper.formatCurrency(orderSummaryData.shipping_fee)}`
                  : `Free`}
              </label>
            }
            columnLeft={10}
            columnRight={2}
          />
        )}
        {orderSummaryData.price_discount !== 0 && (
          <GridRowTableOneColumn
            customElementInitial={
              <div className="flex-column">
                <label className="text-14 row-item-title">Discount</label>
                <label className="text-10 ">{orderSummaryData.promo_code}</label>
              </div>
            }
            customElementColumn={
              <div className="text-right">
                <label className="text-14 discount">
                  IDR {CommonHelper.formatCurrency(orderSummaryData.price_discount)}
                </label>
              </div>
            }
            columnLeft={10}
            columnRight={2}
          />
        )}
        {orderSummaryData.monthly_discount !== 0 && (
          <GridRowTableOneColumn
            customElementInitial={
              <div className="flex-column">
                <label className="text-14 row-item-title">Discount</label>
                <label className="text-10 ">Special Package</label>
              </div>
            }
            customElementColumn={
              <div className="text-right">
                <label className="text-14 discount">
                  IDR {CommonHelper.formatCurrency(orderSummaryData.monthly_discount)}
                </label>
              </div>
            }
            columnLeft={10}
            columnRight={2}
          />
        )}
        <GridRowTableOneColumn
          customElementInitial={
            <label className="text-16 row-item-title">
              Total Item(s): {orderSummaryData.total_items}
            </label>
          }
          customElementColumn={
            <div className="text-right">
              <label className="text-16 row-item-title">
                Grand Total &nbsp; &nbsp; &nbsp; IDR&nbsp;
                {CommonHelper.formatCurrency(orderSummaryData.grand_total)}
              </label>
            </div>
          }
          columnLeft={7}
          columnRight={5}
        />
      </Grid>
    </Grid>
  );
};

CartItemDetailMembership.propTypes = {
  orderData: PropTypes.object,
};

export default CartItemDetailMembership;
