import React from 'react';
import PropTypes from 'prop-types';
import { Paper, Grid } from '@material-ui/core';
// style
import './HeaderScheduleStyle.scss';
// component
import { ButtonMain, TabNavigation } from '../../../../../../components/Index';

const HeaderScheduleStyle = props => {
  const { onChangeTab, onButtonClick, onButtonClickBook, data, currentTab } = props;

  const handleChangeTab = value => {
    onChangeTab(value);
  };

  const handleButtonClick = value => {
    onButtonClick(value);
  };

  const handleButtonClickBook = () => {
    onButtonClickBook(null);
  };

  return (
    <Paper square className="container-tab-header-schedule">
      <Grid container>
        <Grid item lg md>
          <TabNavigation data={data} onChange={handleChangeTab} currentTab={currentTab} />
        </Grid>
        <Grid item lg={2} md={3} className="container-header-button mr-16">
          <ButtonMain
            labelText="Booking"
            onClick={handleButtonClickBook}
            type="primary"
            size="md"
            startIcon="ic-ffo-add"
          />
        </Grid>
        <Grid item lg={2} md={3} className="container-header-button">
          <ButtonMain
            labelText="Schedule"
            onClick={handleButtonClick}
            type="primary"
            size="md"
            startIcon="ic-ffo-add"
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

HeaderScheduleStyle.propTypes = {
  currentTab: PropTypes.number,
  data: PropTypes.array,
  onButtonClick: PropTypes.func,
  onButtonClickBook: PropTypes.func,
  onChangeTab: PropTypes.func,
};

export default HeaderScheduleStyle;
