/* eslint-disable react/no-access-state-in-setstate */
import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import _ from 'lodash';
// Components
import {
  TextInput,
  ButtonMain,
  ButtonIconMain,
  SnackBarSimple,
  EmptyState,
  ModalAlertAction,
  SkeletonMain,
  AuthenticationAccessPages,
} from '../../../components/Index';
// Actions
import { getSegmentPagination, deleteSegment } from '../../../services/api/SegmentApi';
// Helper
import { HttpStatusCode, CommonHelper, PermissionModule, PermissionPage, PermissionAccess } from '../../../helpers/Index';
// Style
import './SegmentPageStyle.scss';
// Assets
import { Images } from '../../../assets/Index';
import { compose } from 'redux';

const optionAlert = {
  vertical: 'top',
  horizontal: 'right',
};

const initialSort = {
  sortField: 'campaign_title',
  sortOrder: '',
};

class SegmentPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      isLoading: true,
      pagination: {
        showSizeChanger: true,
        showTotal: (total, range) => `Showing ${range[0]}-${range[1]} From ${total}`,
        pageSizeOptions: ['10', '20', '30', '40'],
      },
      filter: {
        search: '',
      },
      sort: CommonHelper.objectCloning(initialSort),
      limit: 10,
      page: 1,
      selectedId: null,
      modalDetailData: {
        title: 'Confirmation',
        body: 'Are you sure want to delete Segment ID',
      },
      isOpenModal: false,
      alertInformation: {
        isOpen: false,
        message: '',
        snackbarType: 'warning',
      },
    };
    this.searchDebounce = _.debounce(this.searchDebounce, 400);
  }

  componentDidMount() {
    const { getDataList } = this;
    getDataList();
  }

  getDataList = () => {
    const { getSegment } = this.props;
    const { filter, pagination, page, sort, limit } = this.state;
    const params = {
      page,
      sort: sort.sortField,
      sortOrder: sort.sortOrder,
      search: filter.search,
      limit,
    };

    this.setState({ loading: true }, () => {
      getSegment(params).then(response => {
        const { data } = response;
        this.setState({
          loading: false,
          isLoading: false,
          pagination: { ...pagination, pageSize: limit, total: data.total },
        });
      });
    });
  };

  handleTableChange = (pagination, filters, sorter) => {
    const { sort } = this.state;

    this.setState(
      {
        limit: pagination.pageSize,
        page: pagination.current,
        pagination: {
          ...this.state.pagination,
          current: pagination.current,
          pageSize: pagination.pageSize,
        },
        sort: {
          ...sort,
          sortField: sorter.field,
          sortOrder: sorter.order === 'ascend' ? 'asc' : 'desc',
        },
      },
      () => {
        this.getDataList();
      },
    );
  };

  handleChangeSearch = value => {
    const { filter } = this.state;
    this.setState(
      {
        filter: { ...filter, search: value, sort: JSON.parse(JSON.stringify(initialSort)) },
        page: 1,
        pagination: { ...this.state.pagination, current: 1 },
      },
      () => {
        this.searchDebounce();
      },
    );
  };

  searchDebounce = () => {
    const { getDataList } = this;
    getDataList();
  };

  setPrevStateValue = prevStateValue => {
    const { setPrevStateValue } = this.props;
    setPrevStateValue(prevStateValue);
  };

  handleMouseEnter = record => {
    this.setState({ selectedId: record.user_segment_id });
  };

  handleMouseLeave = () => {
    this.setState({ selectedId: null });
  };

  handleClickCreate = () => {
    const { history } = this.props;
    history.push('/marketing/segment/create');
  };

  handleClickRowEdit = row => {
    const { history } = this.props;
    history.push(`/marketing/segment/edit/${row.user_segment_id}`);
  };

  handleClickRowDelete = row => {
    const { modalDetailData } = this.state;

    this.setState({
      isOpenModal: true,
      selectedDataDelete: row,
      modalDetailData: {
        ...modalDetailData,
        body: `Are you sure want to delete Segment ID ${row.user_segment_id} ? `,
      },
    });
  };

  handleButtonSubmitDelete = () => {
    const { selectedDataDelete, alertInformation } = this.state;
    const { deleteUserSegment } = this.props;
    const { getDataList } = this;
    const segmentId = selectedDataDelete.user_segment_id;
    deleteUserSegment(segmentId)
      .then(response => {
        const {
          segmentData: { responseMessage },
        } = this.props;
        this.setState(
          {
            alertInformation: {
              ...alertInformation,
              isOpen: true,
              message: responseMessage[0],
              snackbarType: response.code === HttpStatusCode.BadRequest ? 'error' : 'success',
            },
            isOpenModal: false,
            selectedDataDelete: null,
          },
          () => {
            getDataList();
          },
        );
      })
      .catch(error => {
        const serverMessage = error.data;
        const validationStatus = error.status === HttpStatusCode.InternalServerError;
        this.processMessage(
          validationStatus ? serverMessage.message : serverMessage.messages,
          'error',
        );
      });
  };

  handleCloseModal = () => {
    this.setState({
      isOpenModal: false,
    });
  };

  handleCloseAlert = () => {
    const { alertInformation } = this.state;
    this.setState({ alertInformation: { ...alertInformation, isOpen: false } });
  };

  renderColumns = () => {
    const { selectedId } = this.state;
    const { handleClickRowEdit, handleClickRowDelete, handleClickCard } = this;
    return [
      {
        title: 'Segment Name',
        dataIndex: 'title',
        sorter: true,
        render: (text, row) => {
          return (
            <Grid
              container
              onClick={() => {
                handleClickCard(row.user_segment_id);
              }}
              className="container-table-column-segment"
            >
              <Grid item lg={12} md={12} className="flex-column">
                <label className="text-14 text-bold">{row.title}</label>
                <label className="text-10">ID : {row.user_segment_id}</label>
              </Grid>
            </Grid>
          );
        },
      },
      {
        title: 'User Count',
        dataIndex: 'latest_data_count',
        width: '20%',
        align: 'center',
        render: (text, row) => {
          let renderElement = (
            <Grid container justify="space-around">
              <Grid item>
                <label className="text-12 text-bold">{row.latest_data_count}</label>
              </Grid>
            </Grid>
          );
          const isSelectedRow = row.user_segment_id === selectedId;

          if (isSelectedRow) {
            renderElement = (
              <Grid container justify="center">
                <Grid item lg={3} md={3}>
                  <ButtonIconMain
                    icon="ic-ffo-edit"
                    type="secondary"
                    size="sm"
                    onClick={() => handleClickRowEdit(row)}
                    requiredPermission={`${PermissionModule.Marketing}.${PermissionPage.Segment}.${PermissionAccess.Update}`}
                  />
                </Grid>
                <Grid item lg={3} md={3}>
                  <ButtonIconMain
                    icon="ic-ffo-bin"
                    type="negative"
                    size="sm"
                    onClick={() => handleClickRowDelete(row)}
                    requiredPermission={`${PermissionModule.Marketing}.${PermissionPage.Segment}.${PermissionAccess.Delete}`}
                  />
                </Grid>
              </Grid>
            );
          }

          return renderElement;
        },
      },
    ];
  };

  renderFilter() {
    return (
      <Grid container direction="row" justify="flex-start" className="container-filter">
        <Grid item lg={3} md={3} className="row-filter">
          <TextInput
            iconPrefix="ic-ffo-search"
            placeHolderText="Search Name"
            onChange={this.handleChangeSearch}
            currentValue={this.state.filter.search}
            size="md"
          />
        </Grid>
        <Grid item lg={9} md={9}>
          <Grid container direction="row" justify="flex-end" alignItems="center">
            <Grid item lg={4} md={4}>
              <ButtonMain
                labelText="Create Segment"
                onClick={this.handleClickCreate}
                type="primary"
                size="md"
                startIcon="ic-ffo-add"
                requiredPermission={`${PermissionModule.Marketing}.${PermissionPage.Segment}.${PermissionAccess.Add}`}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  render() {
    const {
      segmentData: { list },
    } = this.props;

    const {
      isLoading,
      loading,
      pagination,
      alertInformation,
      isOpenModal,
      modalDetailData,
    } = this.state;

    if (isLoading) {
      return <SkeletonMain />;
    }
    return (
      <div>
        <Helmet title="FITCO | Marketing - Segment" />
        <div className="container-page-segment scroll-container-invisible">
          <div className="container-page-scrolling-area">
            <Grid container direction="column">
              <Grid item lg md className="section-page-header">
                {this.renderFilter()}
              </Grid>
              <Grid item lg md className="section-page-body">
                {_.isEmpty(list) ? (
                  <Grid container justify={'center'}>
                    <EmptyState
                      source={Images.emptyStatePlaceholder}
                      labelText={'No Segment Found yet'}
                    />
                  </Grid>
                ) : (
                  <Table
                    columns={this.renderColumns()}
                    rowKey={record => record.user_segment_id}
                    dataSource={list}
                    pagination={pagination}
                    loading={loading}
                    onChange={this.handleTableChange}
                    onRow={record => {
                      return {
                        onMouseEnter: () => {
                          this.handleMouseEnter(record);
                        }, // mouse enter row
                        onMouseLeave: () => {
                          this.handleMouseLeave();
                        },
                      };
                    }}
                  />
                )}
              </Grid>
              <Grid item lg md className="section-page-footer" />
            </Grid>
          </div>
        </div>
        <SnackBarSimple
          open={alertInformation.isOpen}
          durationHide={2000}
          message={alertInformation.message}
          onClickClose={this.handleCloseAlert}
          snackbarType={alertInformation.snackbarType}
          anchor={optionAlert}
        />
        {isOpenModal ? (
          <ModalAlertAction
            onButtonSubmit={this.handleButtonSubmitDelete}
            onCloseModal={this.handleCloseModal}
            modalDetail={modalDetailData}
            modalType="danger"
            buttonSubmitText="Submit"
            openModal={isOpenModal}
            isLoading={isLoading}
          />
        ) : null}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getSegment: params => getSegmentPagination(dispatch, params),
  deleteUserSegment: segmentId => deleteSegment(dispatch, segmentId),
});

const mapStateToProps = ({ segmentData, usersReducer }) => ({ segmentData, usersReducer });

SegmentPage.propTypes = {
  deleteUserSegment: PropTypes.func,
  getSegment: PropTypes.func,
  history: PropTypes.object,
  segmentData: PropTypes.object,
  setPrevStateValue: PropTypes.func,
};

const shell = compose(connect(mapStateToProps, mapDispatchToProps));

export default shell(AuthenticationAccessPages(SegmentPage));
