export const constants = {
  scheduleDate01Request: 'SCHEDULE_01_REQUEST',
  scheduleDate01Success: 'SCHEDULE_01_SUCCESS',
  scheduleDate01Fail: 'SCHEDULE_01_FAIL',
  scheduleDate02Request: 'SCHEDULE_02_REQUEST',
  scheduleDate02Success: 'SCHEDULE_02_SUCCESS',
  scheduleDate02Fail: 'SCHEDULE_02_FAIL',
  scheduleDate03Request: 'SCHEDULE_03_REQUEST',
  scheduleDate03Success: 'SCHEDULE_03_SUCCESS',
  scheduleDate03Fail: 'SCHEDULE_03_FAIL',
  scheduleDate04Request: 'SCHEDULE_04_REQUEST',
  scheduleDate04Success: 'SCHEDULE_04_SUCCESS',
  scheduleDate04Fail: 'SCHEDULE_04_FAIL',
  scheduleDate05Request: 'SCHEDULE_05_REQUEST',
  scheduleDate05Success: 'SCHEDULE_05_SUCCESS',
  scheduleDate05Fail: 'SCHEDULE_05_FAIL',
  scheduleDate06Request: 'SCHEDULE_06_REQUEST',
  scheduleDate06Success: 'SCHEDULE_06_SUCCESS',
  scheduleDate06Fail: 'SCHEDULE_06_FAIL',
  scheduleDate07Request: 'SCHEDULE_07_REQUEST',
  scheduleDate07Success: 'SCHEDULE_07_SUCCESS',
  scheduleDate07Fail: 'SCHEDULE_07_FAIL',
  getScheduleUserDetailsByPhoneRequest: 'GET_SCHEDULE_USER_DETAILS_BY_PHONE_REQUEST',
  getScheduleUserDetailsByPhoneSuccess: 'GET_SCHEDULE_USER_DETAILS_BY_PHONE_SUCCESS',
  getScheduleUserDetailsByPhoneFail: 'GET_SCHEDULE_USER_DETAILS_BY_PHONE_FAIL',
  setProcessCreateOrderBookingRequest: 'GET_PROCESS_CREATE_ORDER_BOOKING_REQUEST',
  setProcessCreateOrderBookingSuccess: 'GET_PROCESS_CREATE_ORDER_BOOKING_SUCCESS',
  setProcessCreateOrderBookingFail: 'GET_PROCESS_CREATE_ORDER_BOOKING_FAIL',
  setCurrentScheduleTabActive: 'SET_CURRENT_SCHEDULE_TAB_ACTIVE',
  consultationScheduleDate01Request: 'CONSULTATION_SCHEDULE_01_REQUEST',
  consultationScheduleDate01Success: 'CONSULTATION_SCHEDULE_01_SUCCESS',
  consultationScheduleDate01Fail: 'CONSULTATION_SCHEDULE_01_FAIL',
  consultationScheduleDate02Request: 'CONSULTATION_SCHEDULE_02_REQUEST',
  consultationScheduleDate02Success: 'CONSULTATION_SCHEDULE_02_SUCCESS',
  consultationScheduleDate02Fail: 'CONSULTATION_SCHEDULE_02_FAIL',
  consultationScheduleDate03Request: 'CONSULTATION_SCHEDULE_03_REQUEST',
  consultationScheduleDate03Success: 'CONSULTATION_SCHEDULE_03_SUCCESS',
  consultationScheduleDate03Fail: 'CONSULTATION_SCHEDULE_03_FAIL',
  consultationScheduleDate04Request: 'CONSULTATION_SCHEDULE_04_REQUEST',
  consultationScheduleDate04Success: 'CONSULTATION_SCHEDULE_04_SUCCESS',
  consultationScheduleDate04Fail: 'CONSULTATION_SCHEDULE_04_FAIL',
  consultationScheduleDate05Request: 'CONSULTATION_SCHEDULE_05_REQUEST',
  consultationScheduleDate05Success: 'CONSULTATION_SCHEDULE_05_SUCCESS',
  consultationScheduleDate05Fail: 'CONSULTATION_SCHEDULE_05_FAIL',
  consultationScheduleDate06Request: 'CONSULTATION_SCHEDULE_06_REQUEST',
  consultationScheduleDate06Success: 'CONSULTATION_SCHEDULE_06_SUCCESS',
  consultationScheduleDate06Fail: 'CONSULTATION_SCHEDULE_06_FAIL',
  consultationScheduleDate07Request: 'CONSULTATION_SCHEDULE_07_REQUEST',
  consultationScheduleDate07Success: 'CONSULTATION_SCHEDULE_07_SUCCESS',
  consultationScheduleDate07Fail: 'CONSULTATION_SCHEDULE_07_FAIL',
  checkMedicConsultationRequest: 'CHECK_MEDIC_CONSULTATION_REQUEST',
  checkMedicConsultationSuccess: 'CHECK_MEDIC_CONSULTATION_SUCCESS',
  checkMedicConsultationFail: 'CHECK_MEDIC_CONSULTATION_FAIL',
  getBookingAdminFeeRequest: 'GET_BOOKING_ADMIN_FEE_REQUEST',
  getBookingAdminFeeSuccess: 'GET_BOOKING_ADMIN_FEE_SUCCESS',
  getBookingAdminFeeFail: 'GET_BOOKING_ADMIN_FEE_FAIL',
  getAvailableHourByDateRequest: 'GET_AVAILABLE_HOUR_BY_DATE_REQUEST',
  getAvailableHourByDateSuccess: 'GET_AVAILABLE_HOUR_BY_DATE_SUCCESS',
  getAvailableHourByDateFail: 'GET_AVAILABLE_HOUR_BY_DATE_FAIL',
  rescheduleTimeRequest: 'RESCHEDULE_TIME_REQUEST',
  rescheduleTimeSuccess: 'RESCHEDULE_TIME_SUCCESS',
  rescheduleTimeFail: 'RESCHEDULE_TIME_FAIL',
  setRescheduleWorkoutRequest: 'SET_RESCHEDULE_WORKOUT_REQUEST',
  setRescheduleWorkoutSuccess: 'SET_RESCHEDULE_WORKOUT_SUCCESS',
  setRescheduleWorkoutFail: 'SET_RESCHEDULE_WORKOUT_FAIL',
  getAvailableClassHourByDateRequest: 'GET_AVAILABLE_CLASS_HOUR_BY_DATE_REQUEST',
  getAvailableClassHourByDateSuccess: 'GET_AVAILABLE_CLASS_HOUR_BY_DATE_SUCCESS',
  getAvailableClassHourByDateFail: 'GET_AVAILABLE_CLASS_HOUR_BY_DATE_FAIL',
  getConsultationRequest: 'GET_CONSULTATION_REQUEST',
  getConsultationSuccess: 'GET_CONSULTATION_SUCCESS',
  getConsultationFail: 'GET_CONSULTATION_FAIL',
  getAvailableClassScheduleRequest: 'GET_AVAILABLE_CLASS_SCHEDULE_REQUEST',
  getAvailableClassScheduleSuccess: 'GET_AVAILABLE_CLASS_SCHEDULE_SUCCESS',
  getAvailableClassScheduleFail: 'GET_AVAILABLE_CLASS_SCHEDULE_FAIL',
  cancelConsultationScheduleRequest: 'CANCEL_CONSULTATION_SCHEDULE_REQUEST',
  cancelConsultationScheduleSuccess: 'CANCEL_CONSULTATION_SCHEDULE_SUCCESS',
  cancelConsultationScheduleFail: 'CANCEL_CONSULTATION_SCHEDULE_FAIL',
  rescheduleConsultationRequest: 'RESCHEDULE_CONSULTATION_REQUEST',
  rescheduleConsultationSuccess: 'RESCHEDULE_CONSULTATION_SUCCESS',
  rescheduleConsultationFail: 'RESCHEDULE_CONSULTATION_FAIL',
  getLabScheduleRequest: 'GET_LAB_SCHEDULE_REQUEST',
  getLabScheduleSuccess: 'GET_LAB_SCHEDULE_SUCCESS',
  getLabScheduleFail: 'GET_LAB_SCHEDULE_FAIL',
};

const initState = {
  fetching01: false,
  loadMore01: false,
  currentPage01: 0,
  scheduleSummary01Error: false,
  fetching02: false,
  loadMore02: false,
  currentPage02: 0,
  scheduleSummary02Error: false,
  fetching03: false,
  loadMore03: false,
  currentPage03: 0,
  scheduleSummary03Error: false,
  fetching04: false,
  loadMore04: false,
  currentPage04: 0,
  scheduleSummary04Error: false,
  fetching05: false,
  loadMore05: false,
  currentPage05: 0,
  scheduleSummary05Error: false,
  fetching06: false,
  loadMore06: false,
  currentPage06: 0,
  scheduleSummary06Error: false,
  fetching07: false,
  loadMore07: false,
  currentPage07: 0,
  scheduleSummary07Error: false,
  summaryData01: [],
  summaryData02: [],
  summaryData03: [],
  summaryData04: [],
  summaryData05: [],
  summaryData06: [],
  summaryData07: [],
  fetchingConsultation01: false,
  loadMoreConsultation01: false,
  currentPageConsultation01: 0,
  scheduleSummaryConsultation01Error: false,
  fetchingConsultation02: false,
  loadMoreConsultation02: false,
  currentPageConsultation02: 0,
  scheduleSummaryConsultation02Error: false,
  fetchingConsultation03: false,
  loadMoreConsultation03: false,
  currentPageConsultation03: 0,
  scheduleSummaryConsultation03Error: false,
  fetchingConsultation04: false,
  loadMoreConsultation04: false,
  currentPageConsultation04: 0,
  scheduleSummaryConsultation04Error: false,
  fetchingConsultation05: false,
  loadMoreConsultation05: false,
  currentPageConsultation05: 0,
  scheduleSummaryConsultation05Error: false,
  fetchingConsultation06: false,
  loadMoreConsultation06: false,
  currentPageConsultation06: 0,
  scheduleSummaryConsultation06Error: false,
  fetchingConsultation07: false,
  loadMoreConsultation07: false,
  currentPageConsultation07: 0,
  scheduleSummaryConsultation07Error: false,
  summaryDataConsultation01: [],
  summaryDataConsultation02: [],
  summaryDataConsultation03: [],
  summaryDataConsultation04: [],
  summaryDataConsultation05: [],
  summaryDataConsultation06: [],
  summaryDataConsultation07: [],
  fetching: false,
  errorMessage: null,
  responseMessage: null,
  details: {},
  membershipDetails: {},
  scheduleActiveTab: '',
  checkMedicConsultation: false,
  bookingAdminFee: {},
  availableHours: [],
  availableClassHours: [],
  consultationList: [],
  availableClassSchedule: [],
  availableLabSchedule: [],
};

export default function scheduleReducer(state = initState, action) {
  const { type, payload } = action;

  switch (type) {
    case constants.scheduleDate01Request:
      return { ...state, fetching01: true };
    case constants.scheduleDate01Success:
      return {
        ...state,
        summaryData01: [...state.summaryData01, ...payload.data],
        loadMore01: !!payload.next_page_url,
        currentPage01: payload.current_page,
        fetching01: false,
      };
    case constants.scheduleDate01Fail:
      return { ...state, fetching: false, scheduleSummary01Error: payload };
    case constants.scheduleDate02Request:
      return { ...state, fetching02: true };
    case constants.scheduleDate02Success:
      return {
        ...state,
        summaryData02: [...state.summaryData02, ...payload.data],
        loadMore02: !!payload.next_page_url,
        currentPage02: payload.current_page,
        fetching02: false,
      };
    case constants.scheduleDate02Fail:
      return { ...state, fetching02: false, scheduleSummary02Error: payload };
    case constants.scheduleDate03Request:
      return { ...state, fetching03: true };
    case constants.scheduleDate03Success:
      return {
        ...state,
        summaryData03: [...state.summaryData03, ...payload.data],
        loadMore03: !!payload.next_page_url,
        currentPage03: payload.current_page,
        fetching03: false,
      };
    case constants.scheduleDate03Fail:
      return { ...state, fetching03: false, scheduleSummary03Error: payload };
    case constants.scheduleDate04Request:
      return { ...state, fetching04: true };
    case constants.scheduleDate04Success:
      return {
        ...state,
        summaryData04: [...state.summaryData04, ...payload.data],
        loadMore04: !!payload.next_page_url,
        currentPage04: payload.current_page,
        fetching04: false,
      };
    case constants.scheduleDate04Fail:
      return { ...state, fetching04: false, scheduleSummary04Error: payload };
    case constants.scheduleDate05Request:
      return { ...state, fetching05: true };
    case constants.scheduleDate05Success:
      return {
        ...state,
        summaryData05: [...state.summaryData05, ...payload.data],
        loadMore05: !!payload.next_page_url,
        currentPage05: payload.current_page,
        fetching05: false,
      };
    case constants.scheduleDate05Fail:
      return { ...state, fetching05: false, scheduleSummary05Error: payload };
    case constants.scheduleDate06Request:
      return { ...state, fetching06: true };
    case constants.scheduleDate06Success:
      return {
        ...state,
        summaryData06: [...state.summaryData06, ...payload.data],
        loadMore06: !!payload.next_page_url,
        currentPage06: payload.current_page,
        fetching06: false,
      };
    case constants.scheduleDate06Fail:
      return { ...state, fetching06: false, scheduleSummary06Error: payload };
    case constants.scheduleDate07Request:
      return { ...state, fetching07: true };
    case constants.scheduleDate07Success:
      return {
        ...state,
        summaryData07: [...state.summaryData07, ...payload.data],
        loadMore07: !!payload.next_page_url,
        currentPage07: payload.current_page,
        fetching07: false,
      };
    case constants.scheduleDate07Fail:
      return { ...state, fetching07: false, scheduleSummary07Error: payload };

    case constants.getScheduleUserDetailsByPhoneRequest:
      return { ...state, fetching: true };
    case constants.getScheduleUserDetailsByPhoneSuccess:
      return {
        ...state,
        details: payload.data,
        fetching: false,
      };
    case constants.getScheduleUserDetailsByPhoneFail:
      return { ...state, fetching: false, errorFetch: true, errorMessage: payload };

    case constants.setProcessCreateOrderBookingRequest:
      return { ...state, fetching: true };
    case constants.setProcessCreateOrderBookingSuccess:
      return {
        ...state,
        fetching: false,
      };
    case constants.setProcessCreateOrderBookingFail:
      return { ...state, fetching: false, errorFetch: true, errorMessage: payload };

    case constants.setCurrentScheduleTabActive:
      return {
        ...state,
        scheduleActiveTab: payload,
        loadMore01: false,
        loadMore02: false,
        loadMore03: false,
        loadMore04: false,
        loadMore05: false,
        loadMore06: false,
        loadMore07: false,
        loadMoreConsultation01: false,
        loadMoreConsultation02: false,
        loadMoreConsultation03: false,
        loadMoreConsultation04: false,
        loadMoreConsultation05: false,
        loadMoreConsultation06: false,
        loadMoreConsultation07: false,
        summaryData01: [],
        summaryData02: [],
        summaryData03: [],
        summaryData04: [],
        summaryData05: [],
        summaryData06: [],
        summaryData07: [],
        summaryDataConsultation01: [],
        summaryDataConsultation02: [],
        summaryDataConsultation03: [],
        summaryDataConsultation04: [],
        summaryDataConsultation05: [],
        summaryDataConsultation06: [],
        summaryDataConsultation07: [],
      };

    case constants.consultationScheduleDate01Request:
      return { ...state, fetchingConsultation01: true };
    case constants.consultationScheduleDate01Success:
      return {
        ...state,
        summaryDataConsultation01: [...state.summaryDataConsultation01, ...payload.data],
        loadMoreConsultation01: !!payload.next_page_url,
        currentPageConsultation01: payload.current_page,
        fetchingConsultation01: false,
      };
    case constants.consultationScheduleDate01Fail:
      return {
        ...state,
        fetchingConsultation01: false,
        scheduleSummaryConsultation01Error: payload,
      };
    case constants.consultationScheduleDate02Request:
      return { ...state, fetchingConsultation02: true };
    case constants.consultationScheduleDate02Success:
      return {
        ...state,
        summaryDataConsultation02: [...state.summaryDataConsultation02, ...payload.data],
        loadMoreConsultation02: !!payload.next_page_url,
        currentPageConsultation02: payload.current_page,
        fetchingConsultation02: false,
      };
    case constants.consultationScheduleDate02Fail:
      return {
        ...state,
        fetchingConsultation02: false,
        scheduleSummaryConsultation02Error: payload,
      };
    case constants.consultationScheduleDate03Request:
      return { ...state, fetchingConsultation03: true };
    case constants.consultationScheduleDate03Success:
      return {
        ...state,
        summaryDataConsultation03: [...state.summaryDataConsultation03, ...payload.data],
        loadMoreConsultation03: !!payload.next_page_url,
        currentPageConsultation03: payload.current_page,
        fetchingConsultation03: false,
      };
    case constants.consultationScheduleDate03Fail:
      return {
        ...state,
        fetchingConsultation03: false,
        scheduleSummaryConsultation03Error: payload,
      };
    case constants.consultationScheduleDate04Request:
      return { ...state, fetchingConsultation04: true };
    case constants.consultationScheduleDate04Success:
      return {
        ...state,
        summaryDataConsultation04: [...state.summaryDataConsultation04, ...payload.data],
        loadMoreConsultation04: !!payload.next_page_url,
        currentPageConsultation04: payload.current_page,
        fetchingConsultation04: false,
      };
    case constants.consultationScheduleDate04Fail:
      return {
        ...state,
        fetchingConsultation04: false,
        scheduleSummaryConsultation04Error: payload,
      };
    case constants.consultationScheduleDate05Request:
      return { ...state, fetchingConsultation05: true };
    case constants.consultationScheduleDate05Success:
      return {
        ...state,
        summaryDataConsultation05: [...state.summaryDataConsultation05, ...payload.data],
        loadMoreConsultation05: !!payload.next_page_url,
        currentPageConsultation05: payload.current_page,
        fetchingConsultation05: false,
      };
    case constants.consultationScheduleDate05Fail:
      return {
        ...state,
        fetchingConsultation05: false,
        scheduleSummaryConsultation05Error: payload,
      };
    case constants.consultationScheduleDate06Request:
      return { ...state, fetchingConsultation06: true };
    case constants.consultationScheduleDate06Success:
      return {
        ...state,
        summaryDataConsultation06: [...state.summaryDataConsultation06, ...payload.data],
        loadMoreConsultation06: !!payload.next_page_url,
        currentPageConsultation06: payload.current_page,
        fetchingConsultation06: false,
      };
    case constants.consultationScheduleDate06Fail:
      return {
        ...state,
        fetchingConsultation06: false,
        scheduleSummaryConsultation06Error: payload,
      };
    case constants.consultationScheduleDate07Request:
      return { ...state, fetchingConsultation07: true };
    case constants.consultationScheduleDate07Success:
      return {
        ...state,
        summaryDataConsultation07: [...state.summaryDataConsultation07, ...payload.data],
        loadMoreConsultation07: !!payload.next_page_url,
        currentPageConsultation07: payload.current_page,
        fetchingConsultation07: false,
      };
    case constants.consultationScheduleDate07Fail:
      return {
        ...state,
        fetchingConsultation07: false,
        scheduleSummaryConsultation07Error: payload,
      };

    case constants.checkMedicConsultationRequest:
      return { ...state, fetching: true };
    case constants.checkMedicConsultationSuccess:
      return {
        ...state,
        checkMedicConsultation: payload.data,
        fetching: false,
      };
    case constants.checkMedicConsultationFail:
      return {
        ...state,
        fetching: false,
        errorFetch: true,
        errorMessage: payload,
      };

    case constants.getBookingAdminFeeRequest:
      return { ...state, fetching: true };
    case constants.getBookingAdminFeeSuccess:
      return {
        ...state,
        bookingAdminFee: payload.data,
        fetching: false,
      };
    case constants.getBookingAdminFeeFail:
      return {
        ...state,
        fetching: false,
        errorFetch: true,
        errorMessage: payload,
      };

    case constants.setRescheduleWorkoutRequest:
      return { ...state, fetching: true };
    case constants.setRescheduleWorkoutSuccess:
      return {
        ...state,
        fetching: false,
      };
    case constants.setRescheduleWorkoutFail:
      return {
        ...state,
        fetching: false,
        errorFetch: true,
        errorMessage: payload,
      };

    case constants.getAvailableHourByDateRequest:
      return { ...state, fetching: true };
    case constants.getAvailableHourByDateSuccess:
      return {
        ...state,
        availableHours: payload.data,
        fetching: false,
      };
    case constants.getAvailableHourByDateFail:
      return {
        ...state,
        fetching: false,
        errorFetch: true,
        errorMessage: payload,
      };

    case constants.getAvailableClassHourByDateRequest:
      return { ...state, fetching: true, availableClassHours: [] };
    case constants.getAvailableClassHourByDateSuccess:
      return {
        ...state,
        availableClassHours: payload.data,
        fetching: false,
      };
    case constants.getAvailableClassHourByDateFail:
      return {
        ...state,
        fetching: false,
        errorFetch: true,
        errorMessage: payload,
      };

    case constants.getConsultationRequest:
      return { ...state, fetching: true, consultationList: [] };
    case constants.getConsultationSuccess:
      return {
        ...state,
        consultationList: payload.data,
        fetching: false,
      };
    case constants.getConsultationFail:
      return {
        ...state,
        fetching: false,
        errorFetch: true,
        errorMessage: payload,
      };

    case constants.getAvailableClassScheduleRequest:
      return { ...state, fetching: true, availableClassSchedule: [] };
    case constants.getAvailableClassScheduleSuccess:
      return {
        ...state,
        availableClassSchedule: payload,
        fetching: false,
      };
    case constants.getAvailableClassScheduleFail:
      return {
        ...state,
        fetching: false,
        errorFetch: true,
        errorMessage: payload,
      };

    case constants.getLabScheduleRequest:
      return { ...state, fetching: true, availableLabSchedule: [] };
    case constants.getLabScheduleSuccess:
      return {
        ...state,
        availableLabSchedule: payload,
        fetching: false,
      };
    case constants.getLabScheduleFail:
      return {
        ...state,
        fetching: false,
        errorFetch: true,
        errorMessage: payload,
      };

    default:
      return state;
  }
}
