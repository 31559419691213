import React from 'react';
import PropTypes from 'prop-types';
import { Paper, Grid } from '@material-ui/core';
// style
import './HeaderPurchaseOrderPageStyle.scss';
// component
import { TabNavigation, ButtonMain } from '../../../../../components/Index';

const HeaderPurchaseOrderPage = props => {
  const { onChangeTab, data, currentTab, onButtonClickPO, disabled } = props;

  const handleChangeTab = value => {
    onChangeTab(value);
  };

  const handleButtonClickCreate = value => {
    onButtonClickPO(value);
  };

  const renderButtonActive = () => {
    let renderElement = null;

    switch (data[currentTab]) {
      case data[0]:
        renderElement = (
          <ButtonMain
            labelText="Create Purchase Order"
            onClick={handleButtonClickCreate}
            type="primary"
            size="md"
            startIcon="ic-ffo-add"
            disabled={disabled}
          />
        );
        break;
      default:
        renderElement = '';
        break;
    }

    return renderElement;
  };

  return (
    <Paper square className="container-tab-header-purchase-order">
      <Grid container>
        <Grid item lg md>
          <TabNavigation data={data} onChange={handleChangeTab} currentTab={currentTab} />
        </Grid>
        <Grid item lg={3} md={3} className="container-header-button">
          {renderButtonActive()}
        </Grid>
      </Grid>
    </Paper>
  );
};

HeaderPurchaseOrderPage.propTypes = {
  currentTab: PropTypes.number,
  data: PropTypes.array,
  disabled: PropTypes.bool,
  onButtonClickPO: PropTypes.func,
  onChangeTab: PropTypes.func,
};

export default HeaderPurchaseOrderPage;
