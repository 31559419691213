import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Grid, Breadcrumbs, Link, FormControl } from '@material-ui/core';
import _ from 'lodash';
import { compose } from 'redux';
// style
import './InboxAddStyle.scss';
// component
import {
  SnackBarSimple,
  ButtonMain,
  LabelInput,
  TextInput,
  UploadImage,
  TextAreaMain,
  TextEditor,
  SelectInputSegment,
  DeepLink,
  RadioInput,
  PickerInputDateAndTime,
  AuthenticationAccessPages,
  PrevStateValue,
} from '../../../components/Index';
// api
import {
  setCreateInbox,
  getInboxDetails,
  setUpdateInbox,
  getInboxSendTest,
} from '../../../services/api/InboxApi';
// helper
import {
  CommonHelper,
  MasterDataHelper,
  HttpStatusCode,
  PermissionModule,
  PermissionPage,
  PermissionAccess,
  ValidationHelper,
} from '../../../helpers/Index';
import DeepLinkHelper from '../../../helpers/DeepLinkHelper';

const deeplinkOptions = MasterDataHelper.deeplinkType;
const publishTimeOption = MasterDataHelper.publishOption;
const currentDate = CommonHelper.currentDate('YYYY-MM-DD HH:mm');

const optionToash = {
  vertical: 'top',
  horizontal: 'right',
};

const initialForm = {
  inboxId: null,
  image: null,
  inboxTitle: '',
  pushNotifDescription: '',
  shortDescription: '',
  shortDescriptionHtml: '',
  description: '',
  descriptionHtml: '',
  segment: null,
  deeplinkType: deeplinkOptions[0].value,
  identifier: null,
  subIdentifier: null,
  webUrl: null,
  screen: null,
  disabledIdentifier: true,
  publishType: publishTimeOption[0],
  publishDate: currentDate,
};

const initialValidation = {
  inboxTitle: { isError: false, errorMessage: '' },
  pushNotifDescription: { isError: '', errorMessage: '' },
  shortDescription: { isError: '', errorMessage: '' },
  description: { isError: '', errorMessage: '' },
  segment: { isError: '', errorMessage: '' },
  identifier: { isError: '', errorMessage: '' },
  screen: { isError: '', errorMessage: '' },
  webUrl: { isError: '', errorMessage: '' },
};

const shortQuillOption = {
  toolbar: [[{ header: [1, 2, false] }], ['bold', 'italic', 'underline', 'strike'], ['clean']],
};

const quillOption = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ['bold', 'italic', 'underline', 'strike'],
    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
    ['link', 'image'],
    ['clean'],
  ],
};

class InboxAdd extends React.Component {
  constructor(props) {
    super(props);

    props.checkUserAccessPermission(
      PermissionModule.Marketing,
      PermissionPage.Inbox,
      PermissionAccess.Add,
    );

    this.state = {
      form: CommonHelper.objectCloning(initialForm),
      validation: CommonHelper.objectCloning(initialValidation),
      toastInformation: {
        isOpen: false,
        message: '',
        snackbarType: 'warning',
      },
      pagesTitle: {
        helmet: 'FITCO | Marketing - Create Inbox',
        breadcrumbs: 'Create New Inbox',
        card: 'Marketing - Inbox',
      },
    };
    this.validateDebounce = _.debounce(this.validateDebounce, 400);
  }

  componentDidMount() {
    const {
      inboxDetails,
      match: { params },
    } = this.props;

    const { form, pagesTitle } = this.state;

    const ifExistParamObject = params.inbox_id ? CommonHelper.decryptObject(params.inbox_id) : {};

    const updateExistingForm = {
      ...CommonHelper.objectCloning(form),
      ...ifExistParamObject,
    };

    const inboxId = updateExistingForm.inboxId;
    if (inboxId) {
      inboxDetails(inboxId)
        .then(response => {
          const data = response.data;

          const findMatchPublishType = _.find(publishTimeOption, ['value', Boolean(data.send_now)]);
          const publishDate =
            CommonHelper.dateTimeParseNewFormat(data.send_time, 'YYYY-MM-DD HH:mm') || currentDate;
          const deepLink = !_.isEmpty(data.deeplink) ? data.deeplink : {};

          this.setState({
            form: {
              ...form,
              image: data.image,
              inboxId: data.id,
              inboxTitle: data.title,
              pushNotifDescription: data.header,
              shortDescription: data.short_description,
              shortDescriptionHtml: data.short_description,
              description: data.description,
              descriptionHtml: data.description,
              segment: data.user_segment_id || null,
              deeplinkType: deepLink.type || deeplinkOptions[0].value,
              identifier: deepLink.item_id || null,
              subIdentifier: deepLink.subitem_id || null,
              webUrl: deepLink.url || null,
              screen: deepLink.screen_name || null,
              disabledIdentifier: true,
              publishType: findMatchPublishType || publishTimeOption[0],
              publishDate,
            },
            pagesTitle: {
              ...pagesTitle,
              helmet: 'FITCO | Markting - Edit Inbox',
              breadcrumbs: 'Edit Inbox',
              card: 'Marketing - Inbox',
            },
          });
        })
        .catch(error => {
          const serverMessage = error.data;
          const validationStatus = error.status === HttpStatusCode.InternalServerError;
          this.processMessage(
            validationStatus ? serverMessage.message : serverMessage.messages,
            'error',
          );
        });
    }
  }

  componentWillUnmount() {}

  setCreateInbox(params, isSend = false) {
    const { createInbox, history } = this.props;
    const { form } = this.state;

    createInbox(params)
      .then(async response => {
        const message = response.messages;
        if (!isSend) {
          await this.processMessage(message, 'success');
          if (!form.inboxId) {
            setTimeout(async () => {
              await history.push('/marketing/inbox');
            }, 2000);
          }
        } else {
          const newId = response.data.id;
          this.setState({ form: { ...form, inboxId: newId } }, () => {
            this.getInboxSendTest(newId);
          });
        }
      })
      .catch(async error => {
        const serverMessage = error.data;
        const validationStatus = error.status === HttpStatusCode.InternalServerError;
        this.processMessage(
          validationStatus ? serverMessage.message : serverMessage.messages,
          'error',
        );
      });
  }

  setUpdateInbox(params, id, isSend = false) {
    const { updateInbox } = this.props;

    updateInbox(params, id)
      .then(async response => {
        if (!isSend) {
          const message = response.messages;
          await this.processMessage(message, 'success');
        } else {
          this.getInboxSendTest(id);
        }
      })
      .catch(async error => {
        const serverMessage = error.data;
        const validationStatus = error.status === HttpStatusCode.InternalServerError;
        this.processMessage(
          validationStatus ? serverMessage.message : serverMessage.messages,
          'error',
        );
      });
  }

  getInboxSendTest(id) {
    const { inboxSendTest } = this.props;

    inboxSendTest(id)
      .then(async response => {
        const message = response.messages;
        await this.processMessage(message, 'success');
      })
      .catch(async error => {
        const serverMessage = error.data;
        // const validationStatus = error.status === HttpStatusCode.InternalServerError; must be fix in api
        this.processMessage(
          // validationStatus ? serverMessage.message : serverMessage.message,
          serverMessage.message,
          'error',
        );
      });
  }

  handleCloseToash = () => {
    const { toastInformation } = this.state;
    this.setState({ toastInformation: { ...toastInformation, isOpen: false } });
  };

  handleClick = (event, value) => {
    const { history } = this.props;
    event.preventDefault();
    history.push(value);
  };

  handleChangeImage = image => {
    const { form } = this.state;

    this.setState({
      form: { ...form, image },
    });
  };

  handleChangeInboxTitle = value => {
    const { form, validation } = this.state;
    const message = CommonHelper.objectCloning(initialValidation.inboxTitle);
    const valid = !value;
    message.isError = valid;
    message.errorMessage = valid ? 'Inbox Title is Empty' : message.errorMessage;

    this.setState({
      form: { ...form, inboxTitle: value },
      validation: { ...validation, inboxTitle: message },
    });
  };

  handleChangePushNotifDescription = value => {
    const { form, validation } = this.state;
    const message = CommonHelper.objectCloning(initialValidation.pushNotifDescription);
    const valid = !value;
    message.isError = valid ? 'error' : '';
    message.errorMessage = valid ? 'Push Notif Description is Empty' : message.errorMessage;

    this.setState({
      form: { ...form, pushNotifDescription: value },
      validation: { ...validation, pushNotifDescription: message },
    });
  };

  handleChangeShortDescription = (content, string) => {
    const { form, validation } = this.state;
    const message = CommonHelper.objectCloning(initialValidation.shortDescription);

    this.setState({
      form: { ...form, shortDescription: string, shortDescriptionHtml: content },
      validation: { ...validation, message },
    });
  };

  handleChangeDescription = (content, string) => {
    const { form, validation } = this.state;
    const message = CommonHelper.objectCloning(initialValidation.description);

    this.setState({
      form: { ...form, description: string, descriptionHtml: content },
      validation: { ...validation, message },
    });
  };

  handleChangeSegment = value => {
    const { form, validation } = this.state;
    const message = CommonHelper.objectCloning(initialValidation.segment);

    this.setState({
      form: { ...form, segment: value },
      validation: { ...validation, segment: message },
    });
  };

  handleParameterUpdateDeepLink = (formValue, ValidationValue) => {
    this.setState({
      form: {
        ...formValue,
        deeplinkType: formValue.deeplinkType,
        identifier: formValue.identifier,
        subIdentifier: formValue.subIdentifier,
        webUrl: formValue.webUrl,
        screen: formValue.screen,
        disabledIdentifier: formValue.disabledIdentifier,
      },
      validation: ValidationValue,
    });
  };

  handleChangePublishType = value => {
    const { form } = this.state;

    this.setState({ form: { ...form, publishType: value } });
  };

  handleChangePublishDate = value => {
    const { form } = this.state;
    const modifyDate = CommonHelper.checkValidDate(value) ? value : currentDate;

    this.setState({
      form: { ...form, publishDate: modifyDate },
    });
  };

  handleButtonSendTest = () => {
    const { form } = this.state;
    const check = this.validationForm(false);

    if (!check) {
      this.processMessage('Please check your field', 'warning');
    } else {
      const params = {
        title: form.inboxTitle,
        header: form.pushNotifDescription,
        short_description: form.shortDescriptionHtml,
        description: form.descriptionHtml,
        image: form.image,
        deeplink: DeepLinkHelper.generateDeeplinkValue(form),
        is_all_user: !form.segment,
        user_segment_id: form.segment,
        send_now: form.publishType.value,
        send_time: form.publishDate,
        is_draft: true,
      };

      if (form.inboxId) {
        this.setUpdateInbox(params, form.inboxId, true);
      } else {
        this.setCreateInbox(params, true);
      }
    }
  };

  handleButtonCancel = () => {
    const { history } = this.props;
    history.push('/marketing/inbox');
  };

  handleButtonSaveDraft = () => {
    const { form } = this.state;

    const check = this.validationForm(false);
    if (check) {
      const params = {
        title: form.inboxTitle,
        header: form.pushNotifDescription,
        short_description: form.shortDescriptionHtml,
        description: form.descriptionHtml,
        image: form.image,
        deeplink: DeepLinkHelper.generateDeeplinkValue(form),
        is_all_user: !form.segment,
        user_segment_id: form.segment,
        send_now: form.publishType.value,
        send_time: form.publishDate,
        is_draft: true,
      };

      if (form.inboxId) {
        this.setUpdateInbox(params, form.inboxId);
      } else {
        this.setCreateInbox(params);
      }
    }
  };

  handleButtonSend = () => {
    const { form } = this.state;

    const check = this.validationForm(false);

    if (check) {
      const params = {
        image: form.image,
        title: form.inboxTitle,
        header: form.pushNotifDescription,
        short_description: form.shortDescriptionHtml,
        description: form.descriptionHtml,
        deeplink: DeepLinkHelper.generateDeeplinkValue(form),
        is_all_user: !form.segment,
        user_segment_id: form.segment,
        send_now: form.publishType.value,
        send_time: form.publishDate,
        is_draft: false,
      };

      if (form.inboxId) {
        this.setUpdateInbox(params, form.inboxId);
      } else {
        this.setCreateInbox(params);
      }
    }
  };

  validateDebounce() {
    this.validationForm();
  }

  validationForm(includeSegment = true) {
    const { form, validation } = this.state;

    let passCheck = true;
    let passCheckGroup01 = true;
    let passCheckGroup02 = true;
    let passCheckGroup03 = true;
    let passCheckGroup04 = true;

    const paramValidation01 = CommonHelper.objectCloning(initialValidation.inboxTitle);
    const paramValidation02 = CommonHelper.objectCloning(initialValidation.pushNotifDescription);
    const paramValidation03 = CommonHelper.objectCloning(initialValidation.shortDescription);
    const paramValidation04 = CommonHelper.objectCloning(initialValidation.description);
    const paramValidation05 = CommonHelper.objectCloning(initialValidation.screen);
    const paramValidation06 = CommonHelper.objectCloning(initialValidation.identifier);
    const paramValidation07 = CommonHelper.objectCloning(initialValidation.webUrl);
    const paramValidation08 = CommonHelper.objectCloning(initialValidation.segment);

    paramValidation01.isError = !form.inboxTitle;
    paramValidation01.errorMessage = form.inboxTitle ? '' : 'Please enter Inbox Title';

    paramValidation02.isError = !form.pushNotifDescription ? 'error' : '';
    paramValidation02.errorMessage = form.pushNotifDescription
      ? ''
      : 'Please enter Push Notif Description';

    const shortDescriptionTrim = form.shortDescription.trim();
    paramValidation03.isError = shortDescriptionTrim ? '' : 'error';
    paramValidation03.errorMessage = shortDescriptionTrim ? '' : 'Please enter Short Description';

    const descriptionTrim = form.description.trim();
    paramValidation04.isError = descriptionTrim ? '' : 'error';
    paramValidation04.errorMessage = descriptionTrim ? '' : 'Please enter Description';

    if (form.deeplinkType === deeplinkOptions[1].value) {
      paramValidation05.isError = form.screen ? '' : 'error';
      paramValidation05.errorMessage = form.screen ? '' : 'Please enter Screen';
      passCheckGroup01 = !!form.screen;

      if (form.disabledIdentifier === false) {
        paramValidation06.isError = form.identifier ? '' : 'error';
        paramValidation06.errorMessage = form.identifier ? '' : 'Please enter Identifier';
        passCheckGroup01 = !!form.identifier;
      }
    } else if (form.deeplinkType === deeplinkOptions[2].value) {
      paramValidation07.isError = form.webUrl ? '' : 'error';
      paramValidation07.errorMessage = form.webUrl ? '' : 'Please enter WebUrl';
      passCheckGroup01 = !!form.webUrl;
    }

    if (includeSegment) {
      paramValidation08.isError = form.segment ? '' : 'error';
      paramValidation08.errorMessage = form.segment ? '' : 'Please select Existing Segment';
      passCheckGroup02 = !!form.segment;
    }

    if (form.publishType.value === publishTimeOption[1].value) {
      passCheckGroup03 = CommonHelper.checkValidDate(form.publishDate);
    }

    const maxSizeMb = 1;
    passCheckGroup04 = ValidationHelper.validationSizeFile(form.descriptionHtml, maxSizeMb);

    if (
      !form.inboxTitle ||
      !form.pushNotifDescription ||
      !shortDescriptionTrim ||
      !descriptionTrim ||
      !passCheckGroup01 ||
      !passCheckGroup02 ||
      !passCheckGroup03 ||
      !passCheckGroup04
    ) {
      passCheck = false;
    }

    this.setState(
      {
        validation: {
          ...validation,
          inboxTitle: paramValidation01,
          pushNotifDescription: paramValidation02,
          shortDescription: paramValidation03,
          description: paramValidation04,
          screen: paramValidation05,
          identifier: paramValidation06,
          webUrl: paramValidation07,
          segment: paramValidation08,
        },
      },
      () => {
        if (!passCheckGroup04) {
          const imageSizeExcludeContent = maxSizeMb - 0.2;
          this.processMessage(
            `Your Description combination of content and image you trying to upload is too big, please made sure your image less thant ${imageSizeExcludeContent}MB`,
            'warning',
          );
        }
      },
    );

    return passCheck;
  }

  processMessage(messages, type) {
    const convertedMessage = CommonHelper.generateMessage(messages);

    const paramInformation = {
      isOpen: true,
      message: convertedMessage,
      snackbarType: type,
    };

    this.setState({
      toastInformation: paramInformation,
    });
  }

  render() {
    const { toastInformation, form, validation, pagesTitle } = this.state;
    const {
      inboxData: { fetching },
    } = this.props;
    const prevUrl = '/marketing/inbox';

      return (
        <div>
          <Helmet title={pagesTitle.helmet} />
          <div className="container-page-request-details scroll-container">
            <div className="container-page-scrolling-area">
              <Grid container direction="column" className="flex-wrap-unset">
                <Grid item lg md className="section-page-header">
                  <div className="breadcrumbs-section">
                    <Breadcrumbs aria-label="breadcrumb">
                      <Link
                        className="text-12"
                        color="inherit"
                        href={prevUrl}
                        onClick={event => {
                          this.handleClick(event, prevUrl);
                        }}
                      >
                        <i className="ic-ffo-marketing container-icon-prefix size-16" />
                        Inbox
                      </Link>
                      <label className="text-12" color="inherit">
                        {pagesTitle.breadcrumbs}
                      </label>
                    </Breadcrumbs>
                  </div>
                </Grid>
                <Grid item lg md className="section-page-body">
                  <div className="inbox-card">
                    <Grid container spacing={8}>
                      <Grid item lg={6} md={6}>
                        <Grid container direction="column">
                          <label className="text-16 title text-bold text-rolling-stone mb-16">
                            {pagesTitle.card}
                          </label>
                          <Grid item>
                            <FormControl component="fieldset" fullWidth margin={'normal'}>
                              <LabelInput labelText={'Add Image'} />
                              <div className="container-image-action">
                                <UploadImage
                                  onChange={item => this.handleChangeImage(item)}
                                  defaultValue={form.image}
                                  maxSize={1}
                                />
                              </div>
                            </FormControl>
                          </Grid>
                          <Grid item>
                            <FormControl component="fieldset" fullWidth margin={'normal'}>
                              <LabelInput labelText={'Inbox Title'} isRequired />
                              <TextInput
                                placeHolderText="Inbox Title"
                                onChange={this.handleChangeInboxTitle}
                                currentValue={form.inboxTitle}
                                errorMessage={validation.inboxTitle.errorMessage}
                                isError={validation.inboxTitle.isError}
                                size="md"
                                maxLength={50}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item>
                            <FormControl component="fieldset" fullWidth margin={'normal'}>
                              <LabelInput labelText={'Push Notif Description'} isRequired />
                              <TextAreaMain
                                placeholder="Enter Description"
                                onChange={this.handleChangePushNotifDescription}
                                currentValue={form.pushNotifDescription}
                                validateStatus={validation.pushNotifDescription.isError}
                                errorMessage={validation.pushNotifDescription.errorMessage}
                                rows={3}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item>
                            <FormControl component="fieldset" fullWidth margin={'normal'}>
                              <LabelInput labelText={'Short Description'} isRequired />
                              <TextEditor
                                currentValue={form.shortDescriptionHtml}
                                onChange={this.handleChangeShortDescription}
                                modules={shortQuillOption}
                                errorMessage={this.state.validation.shortDescription.errorMessage}
                                validateStatus={this.state.validation.shortDescription.isError}
                                maxLength={24}
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item lg={6} md={6}>
                        <Grid container direction="column">
                          <Grid item lg={5} md={5}>
                            <ButtonMain
                              type="ghost"
                              labelText="Send Test Inbox"
                              onClick={this.handleButtonSendTest}
                              isLoading={fetching}
                            />
                          </Grid>
                          <Grid item>
                            <FormControl component="fieldset" fullWidth margin={'normal'}>
                              <LabelInput labelText={'Description'} isRequired />
                              <TextEditor
                                currentValue={form.descriptionHtml}
                                onChange={this.handleChangeDescription}
                                modules={quillOption}
                                errorMessage={this.state.validation.description.errorMessage}
                                validateStatus={this.state.validation.description.isError}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item>
                            <FormControl component="fieldset" fullWidth margin={'normal'}>
                              <LabelInput labelText={'Segment'} />
                              <SelectInputSegment
                                onChange={this.handleChangeSegment}
                                currentValue={form.segment}
                                errorMessage={this.state.validation.segment.errorMessage}
                                validateStatus={this.state.validation.segment.isError}
                                includeAllData
                              />
                            </FormControl>
                          </Grid>
                          <Grid item>
                            <DeepLink
                              parentForm={this.state.form}
                              parentValidation={this.state.validation}
                              onParameterUpdate={this.handleParameterUpdateDeepLink}
                            />
                          </Grid>
                          <Grid item>
                            <FormControl component="fieldset" fullWidth margin={'normal'}>
                              <LabelInput labelText={'Publish Time'} />
                              <RadioInput
                                data={publishTimeOption}
                                onSelect={this.handleChangePublishType}
                                direction="row"
                                currentValue={form.publishType}
                              />
                            </FormControl>
                          </Grid>
                          {form.publishType.value === publishTimeOption[1].value ? (
                            <Grid item>
                              <FormControl component="fieldset" fullWidth margin={'normal'}>
                                <PickerInputDateAndTime
                                  customIcon="ic-ffo-date-pick"
                                  dateFormat="dd/MM/yyyy hh:mm"
                                  minDate={currentDate}
                                  defaultValue={form.publishDate}
                                  onChange={this.handleChangePublishDate}
                                  toolbar={false}
                                />
                              </FormControl>
                            </Grid>
                          ) : null}
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
                <Grid item lg md className="section-page-footer">
                  <Grid container justify="flex-end" spacing={3}>
                    <Grid item lg={2} md={2}>
                      <ButtonMain
                        type="negative"
                        size="xl"
                        labelText="Cancel"
                        onClick={this.handleButtonCancel}
                        isLoading={fetching}
                      />
                    </Grid>
                    <Grid item lg={2} md={2}>
                      <ButtonMain
                        type="ghost"
                        size="xl"
                        labelText="Draft"
                        onClick={this.handleButtonSaveDraft}
                        isLoading={fetching}
                      />
                    </Grid>
                    <Grid item lg={2} md={2}>
                      <ButtonMain
                        type="primary"
                        size="xl"
                        labelText="Send"
                        onClick={this.handleButtonSend}
                        isLoading={fetching}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </div>
          <SnackBarSimple
            open={toastInformation.isOpen}
            durationHide={2000}
            message={toastInformation.message}
            onClickClose={this.handleCloseToash}
            snackbarType={toastInformation.snackbarType}
            anchor={optionToash}
          />
        </div>
      );
  }
}

const mapDispatchToProps = dispatch => ({
  createInbox: params => setCreateInbox(dispatch, params),
  inboxDetails: params => getInboxDetails(dispatch, params),
  updateInbox: (params, id) => setUpdateInbox(dispatch, params, id),
  inboxSendTest: id => getInboxSendTest(dispatch, id),
});

const mapStateToProps = ({ inboxData }) => ({
  inboxData,
});

InboxAdd.propTypes = {
  checkUserAccessPermission: PropTypes.func,
  createInbox: PropTypes.func,
  history: PropTypes.object,
  inboxData: PropTypes.object,
  inboxDetails: PropTypes.func,
  inboxSendTest: PropTypes.func,
  match: PropTypes.object,
  updateInbox: PropTypes.func,
};

const shell = compose(connect(mapStateToProps, mapDispatchToProps));
const core = compose(AuthenticationAccessPages, PrevStateValue);

export default shell(core(InboxAdd));
