/* eslint-disable react/no-access-state-in-setstate */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Table } from 'antd';
import { Grid } from '@material-ui/core';
import _ from 'lodash';
import { compose } from 'redux';
// style
import './PurchaseOrderPageStyle.scss';
// component
import {
  ButtonMultipleMain,
  GeneratorPdf,
  LabelStatusMain,
  ModalAlertAction,
  PickerInputDate,
  PrevStateValue,
  SelectInputMain,
  SnackBarSimple,
  TextInput,
} from '../../../../components/Index';
import { SkeletonPagination } from '../../../../components/skeleton/Index';
// api
import {
  getPurchaseOrderListPagination,
  getPurchaseOrderPrint,
  setDeletePurchaseOrder,
} from '../../../../services/api/OrdersApi';
// helper
import {
  MasterDataHelper,
  CommonHelper,
  purchaseOrderStatusCode,
  PermissionModule,
  PermissionPage,
  PermissionAccess,
  permissionAccessPurchaseOrder,
  HttpStatusCode,
  currencyCode,
  PurchaseOrderActionCode,
} from '../../../../helpers/Index';

const currentDate = CommonHelper.currentDate('YYYY-MM-DD');
const startDateValue = CommonHelper.getStartDateMonth(currentDate);
const endDateValue = CommonHelper.getEndDateMonth(currentDate);

const initialFilter = {
  search: null,
  purchaseOrderTab: null,
  purchaseOrderStatus: purchaseOrderStatusCode.newPO,
  startDate: startDateValue,
  endDate: endDateValue,
};

const initialSort = {
  sortField: 'user_name',
  sortOrder: '',
};

const optionToash = {
  vertical: 'top',
  horizontal: 'right',
};

const PurchaseOrderStatusInvoice = MasterDataHelper.PurchaseOrderStatusInvoice;
const PurchaseOrderStatusDelivery = MasterDataHelper.PurchaseOrderStatusDelivery;
const purchaseOrderStatusPOCompleted = MasterDataHelper.purchaseOrderStatusPOCompleted;

class PurchaseOrderPage extends React.Component {
  constructor(props) {
    super(props);

    props.checkUserAccessPermission(
      PermissionModule.Orders,
      PermissionPage.PurchaseOrder,
      PermissionAccess.View,
    );

    const {
      usersReducer: { prevStateValue },
    } = props;

    const updateExitingFilter = { ...CommonHelper.objectCloning(initialFilter), ...prevStateValue };

    this.state = {
      filter: updateExitingFilter,
      page: 1,
      limit: 10,
      sort: CommonHelper.objectCloning(initialSort),
      isOpenModalDelete: false,
      selectedDeleteId: '',
      activeCanvasGeneratorPDF: '',
      buttonClickPrint: false,
      pagination: {
        showSizeChanger: true,
        showTotal: (total, range) => `Showing ${range[0]}-${range[1]} From ${total}`,
        pageSizeOptions: ['10', '20', '30', '40'],
      },
      isLoading: true,
      modalDetailData: {
        title: 'Confirmation',
        body: 'Are you sure want to delete',
      },
      toastInformation: {
        isOpen: false,
        message: '',
        snackbarType: 'warning',
      },
      termOfPayment: {},
    };
    this.searchDebounce = _.debounce(this.searchDebounce, 400);
  }

  componentDidMount() {
    const { filter } = this.state;
    const { pageTab } = this.props;

    this.setState(
      {
        filter: {
          ...filter,
          purchaseOrderTab: pageTab,
        },
      },
      () => {
        this.resetPropChange();
      },
    );
  }

  componentDidUpdate() {
    const { filter } = this.state;
    const { pageTab } = this.props;

    if (pageTab !== filter.purchaseOrderTab) {
      this.resetPropChange();
    }
  }

  componentWillUnmount() {}

  getPurchaseOrderListPagination() {
    const { getPOPagination, pageTab } = this.props;
    const {
      limit,
      pagination,
      page,
      filter: { search, startDate, endDate, purchaseOrderStatus },
      sort,
    } = this.state;

    const params = {
      search,
      start_date: startDate,
      end_date: endDate,
      purchase_order_status: purchaseOrderStatus,
      status: pageTab,
      page,
      limit,
      sort: sort.sortField,
      sortOrder: sort.sortOrder,
    };

    getPOPagination(params).then(response => {
      this.setState({
        isLoading: false,
        pagination: { ...pagination, pageSize: limit, total: response.data.total },
      });
    });
  }

  handleChangeSearch = value => {
    const { filter } = this.state;

    this.setState(
      {
        filter: { ...filter, search: value },
        page: 1,
        pagination: { ...this.state.pagination, current: 1 },
      },
      () => {
        this.searchDebounce();
      },
    );
  };

  searchDebounce = () => {
    this.getPurchaseOrderListPagination();
  };

  handleChangeStartDate = value => {
    const { filter } = this.state;
    const endDate = CommonHelper.getEndDateMonth(value);

    this.setState(
      {
        filter: { ...filter, startDate: value, endDate },
      },
      () => {
        this.getPurchaseOrderListPagination();
      },
    );
  };

  handleChangeEndDate = value => {
    const { filter } = this.state;

    this.setState(
      {
        filter: { ...filter, endDate: value },
      },
      () => {
        this.getPurchaseOrderListPagination();
      },
    );
  };

  handleSelectPurchaseOrderStatus = value => {
    const { filter } = this.state;

    this.setState(
      {
        page: 1,
        pagination: { ...this.state.pagination, current: 1 },
        filter: {
          ...filter,
          purchaseOrderStatus: value || null,
        },
      },
      () => {
        this.getPurchaseOrderListPagination();
      },
    );
  };

  handleClickCard = value => {
    const { history, onSetPrevStateValue } = this.props;
    const { filter } = this.state;

    onSetPrevStateValue({ ...filter });
    const param = CommonHelper.encryptObject({ purchaseOrderId: value });
    history.push(`/order/purchase-order/details/${param}`);
  };

  handleClickButtonAction = (value, item) => {
    const { modalDetailData, filter } = this.state;
    const { history, onSetPrevStateValue, purchaseOrderPrint } = this.props;
    let paramEncrypt = '';
    let paramPrint = {};
    let termOfPayment = {};

    if (!_.isEmpty(item.terms)) {
      termOfPayment = {
        terms: item.terms,
      };
    }

    switch (value) {
      case PurchaseOrderActionCode.delete:
        this.setState({
          isOpenModalDelete: true,
          selectedDeleteId: item.purchase_order_id,
          modalDetailData: {
            ...modalDetailData,
            body: `Are you sure want to delete ${item.purchase_order_no} ?`,
          },
        });
        break;
      case PurchaseOrderActionCode.printProformaInvoice:
        paramPrint = {
          document_type: PurchaseOrderActionCode.printProformaInvoice,
          payment_id: null,
        };

        this.setState(
          {
            activeCanvasGeneratorPDF: 'canvas-proforma-invoice',
            buttonClickPrint: true,
            termOfPayment,
          },
          () => {
            purchaseOrderPrint(item.purchase_order_id, paramPrint)
              .then(() => {
                setTimeout(() => {
                  this.setState({
                    buttonClickPrint: false,
                  });
                }, 1400);
              })
              .catch(async error => {
                const serverMessage = error.data;
                const validationStatus = error.status === HttpStatusCode.InternalServerError;
                this.processMessage(
                  validationStatus ? serverMessage.message : serverMessage.messages,
                  'error',
                );
              });
          },
        );

        break;
      case PurchaseOrderActionCode.printDeliverySlip:
        paramPrint = {
          document_type: PurchaseOrderActionCode.printDeliverySlip,
        };

        this.setState(
          {
            activeCanvasGeneratorPDF: 'canvas-purchaser-order-delivery-slip',
            buttonClickPrint: true,
          },
          () => {
            purchaseOrderPrint(item.purchase_order_id, paramPrint)
              .then(() => {
                setTimeout(() => {
                  this.setState({
                    buttonClickPrint: false,
                  });
                }, 1400);
              })
              .catch(async error => {
                const serverMessage = error.data;
                const validationStatus = error.status === HttpStatusCode.InternalServerError;
                this.processMessage(
                  validationStatus ? serverMessage.message : serverMessage.messages,
                  'error',
                );
              });
          },
        );
        break;
      case PurchaseOrderActionCode.printInvoice:
        paramPrint = {
          document_type: PurchaseOrderActionCode.printInvoice,
        };

        this.setState(
          {
            activeCanvasGeneratorPDF: 'canvas-purchaser-order-invoice',
            buttonClickPrint: true,
            termOfPayment,
          },
          () => {
            purchaseOrderPrint(item.purchase_order_id, paramPrint)
              .then(() => {
                setTimeout(() => {
                  this.setState({
                    buttonClickPrint: false,
                  });
                }, 1400);
              })
              .catch(async error => {
                const serverMessage = error.data;
                const validationStatus = error.status === HttpStatusCode.InternalServerError;
                this.processMessage(
                  validationStatus ? serverMessage.message : serverMessage.messages,
                  'error',
                );
              });
          },
        );
        break;
      default:
        paramEncrypt = CommonHelper.encryptObject({ purchaseOrderId: item.purchase_order_id });

        onSetPrevStateValue({ ...filter });
        history.push(`/order/purchase-order/edit/${paramEncrypt}`);
        break;
    }
  };

  handleButtonSubmitDelete = () => {
    const { deletePurchaseOrder } = this.props;
    const { selectedDeleteId } = this.state;

    deletePurchaseOrder(selectedDeleteId)
      .then(response => {
        const message = response.messages;
        this.processMessage(message, 'success');
        this.handleCloseModalDelete();
        this.getPurchaseOrderListPagination();
      })
      .catch(async error => {
        const serverMessage = error.data;
        const validationStatus = error.status === HttpStatusCode.InternalServerError;
        this.processMessage(
          validationStatus ? serverMessage.message : serverMessage.messages,
          'error',
        );
      });
  };

  handleCloseModalDelete = () => {
    this.setState({
      isOpenModalDelete: false,
    });
  };

  handleTableChange = (pagination, filters, sorter) => {
    const { sort } = this.state;

    this.setState(
      {
        limit: pagination.pageSize,
        pagination: {
          ...this.state.pagination,
          current: pagination.current,
          pageSize: pagination.pageSize,
        },
        sort: {
          ...sort,
          sortField: sorter.field,
          sortOrder: sorter.order === 'ascend' ? 'asc' : 'desc',
        },
        page: pagination.current,
      },
      () => {
        this.getPurchaseOrderListPagination();
      },
    );
  };

  handleCloseToash = () => {
    const { toastInformation } = this.state;
    this.setState({ toastInformation: { ...toastInformation, isOpen: false } });
  };

  processMessage(messages, type) {
    const convertedMessage = CommonHelper.generateMessage(messages);

    const paramInformation = {
      isOpen: true,
      message: convertedMessage,
      snackbarType: type,
    };

    this.setState({
      toastInformation: paramInformation,
    });
  }

  reAssignActionButton(status, userId) {
    const { pageTab, checkUserAccessPermissionAction, usersReducer } = this.props;
    const { filter } = this.state;
    const modifyPurchaseOrderActionOptions = [];

    const isEdit =
      !!checkUserAccessPermissionAction(
        PermissionModule.Orders,
        PermissionPage.PurchaseOrder,
        permissionAccessPurchaseOrder.UpdatePurchaseOrder,
      ) && userId === usersReducer.user_id;

    const isDelete =
      !!checkUserAccessPermissionAction(
        PermissionModule.Orders,
        PermissionPage.PurchaseOrder,
        permissionAccessPurchaseOrder.DeletePurchaseOrder,
      ) && userId === usersReducer.user_id;

    const isPrintProformaInvoice = !!checkUserAccessPermissionAction(
      PermissionModule.Orders,
      PermissionPage.PurchaseOrder,
      permissionAccessPurchaseOrder.PrintProformaInvoice,
    );
    const isPrintDeliverySlip = !!checkUserAccessPermissionAction(
      PermissionModule.Orders,
      PermissionPage.PurchaseOrder,
      permissionAccessPurchaseOrder.PrintDeliverySlip,
    );

    const isPrintInvoice = !!checkUserAccessPermissionAction(
      PermissionModule.Orders,
      PermissionPage.PurchaseOrder,
      permissionAccessPurchaseOrder.PrintInvoice,
    );

    switch (pageTab) {
      case 'purchase_order':
        if (isEdit) {
          modifyPurchaseOrderActionOptions.push(
            MasterDataHelper.PurchaseOrderActionOptions.find(
              item => item.value === PurchaseOrderActionCode.edit,
            ),
          );
        }

        if (isDelete) {
          modifyPurchaseOrderActionOptions.push(
            MasterDataHelper.PurchaseOrderActionOptions.find(
              item => item.value === PurchaseOrderActionCode.delete,
            ),
          );
        }
        break;
      case 'delivery':
        if (
          isEdit &&
          (filter.purchaseOrderStatus === purchaseOrderStatusCode.ProcessingPO ||
            filter.purchaseOrderStatus === purchaseOrderStatusCode.OnShipmentPO ||
            filter.purchaseOrderStatus === purchaseOrderStatusCode.DeliveredPO)
        ) {
          modifyPurchaseOrderActionOptions.push(
            MasterDataHelper.PurchaseOrderActionOptions.find(
              item => item.value === PurchaseOrderActionCode.edit,
            ),
          );
        }

        if (isDelete && filter.purchaseOrderStatus === purchaseOrderStatusCode.ProcessingPO) {
          modifyPurchaseOrderActionOptions.push(
            MasterDataHelper.PurchaseOrderActionOptions.find(
              item => item.value === PurchaseOrderActionCode.delete,
            ),
          );
        }

        if (isPrintProformaInvoice) {
          modifyPurchaseOrderActionOptions.push(
            MasterDataHelper.PurchaseOrderActionOptions.find(
              item => item.value === PurchaseOrderActionCode.printProformaInvoice,
            ),
          );
        }

        if (isPrintDeliverySlip) {
          modifyPurchaseOrderActionOptions.push(
            MasterDataHelper.PurchaseOrderActionOptions.find(
              item => item.value === PurchaseOrderActionCode.printDeliverySlip,
            ),
          );
        }
        break;
      case 'invoices':
      case 'completed':
        if (isEdit && status === purchaseOrderStatusCode.DeliveredPO) {
          modifyPurchaseOrderActionOptions.push(
            MasterDataHelper.PurchaseOrderActionOptions.find(
              item => item.value === PurchaseOrderActionCode.edit,
            ),
          );
        }

        if (isPrintProformaInvoice && status === purchaseOrderStatusCode.DeliveredPO) {
          modifyPurchaseOrderActionOptions.push(
            MasterDataHelper.PurchaseOrderActionOptions.find(
              item => item.value === PurchaseOrderActionCode.printProformaInvoice,
            ),
          );
        }

        if (
          isPrintInvoice &&
          status !== purchaseOrderStatusCode.DeliveredPO &&
          status !== purchaseOrderStatusCode.Canceled
        ) {
          modifyPurchaseOrderActionOptions.push(
            MasterDataHelper.PurchaseOrderActionOptions.find(
              item => item.value === PurchaseOrderActionCode.printInvoice,
            ),
          );
        }

        if (isPrintDeliverySlip && status !== purchaseOrderStatusCode.Canceled) {
          modifyPurchaseOrderActionOptions.push(
            MasterDataHelper.PurchaseOrderActionOptions.find(
              item => item.value === PurchaseOrderActionCode.printDeliverySlip,
            ),
          );
        }
        break;
      default:
        break;
    }

    return modifyPurchaseOrderActionOptions;
  }

  resetPropChange() {
    const { pageTab } = this.props;
    const { filter } = this.state;
    let defaultStatus = null;

    switch (pageTab) {
      case 'purchase_order':
        defaultStatus = purchaseOrderStatusCode.newPO;
        break;
      case 'delivery':
        defaultStatus = purchaseOrderStatusCode.ProcessingPO;
        break;
      case 'invoices':
        defaultStatus = purchaseOrderStatusCode.DeliveredPO;
        break;
      default:
        defaultStatus = purchaseOrderStatusCode.CompletedPO;
        break;
    }

    this.setState(
      {
        isLoading: true,
        filter: {
          ...filter,
          purchaseOrderStatus: defaultStatus,
          purchaseOrderTab: pageTab,
          search: null,
        },
      },
      () => {
        this.getPurchaseOrderListPagination();
      },
    );
  }

  renderStatusLabel = value => {
    let renderElement = null;
    if (value === purchaseOrderStatusCode.newPO) {
      renderElement = <LabelStatusMain value="New" type="projected" />;
    } else if (value === purchaseOrderStatusCode.Canceled) {
      renderElement = <LabelStatusMain value="Deleted" type="danger" />;
    } else if (value === purchaseOrderStatusCode.ProcessingPO) {
      renderElement = <LabelStatusMain value="Processing" type="on-progress" />;
    } else if (value === purchaseOrderStatusCode.OnShipmentPO) {
      renderElement = <LabelStatusMain value="On Shipment" type="idle" />;
    } else if (value === purchaseOrderStatusCode.AwaitingPaymentPO) {
      renderElement = <LabelStatusMain value="Awaiting Payment" type="await" />;
    } else if (value === purchaseOrderStatusCode.DeliveredPO) {
      renderElement = <LabelStatusMain value="Delivered" type="complete" />;
    }
    return <div className="container-status">{renderElement}</div>;
  };

  renderRowAdditional = data => {
    const deliveryDate = CommonHelper.dateTimeParseNewFormat(data.shipping_date, 'DD MMM YYYY');

    return (
      <>
        <Grid container justify="space-between">
          <Grid item lg={3} md={3}>
            <div className="flex-column">
              <label className="text-12 title">Invoice Number</label>
              <label className="text-12">{data.invoice_no}</label>
            </div>
          </Grid>
          <Grid item>
            <div className="divider-hr-vertical" />
          </Grid>
          <Grid item lg={3} md={3}>
            <div className="flex-column">
              <label className="text-12 title">Delivery Number</label>
              <label className="text-12">{data.shipping_no}</label>
            </div>
          </Grid>
          <Grid item>
            <div className="divider-hr-vertical" />
          </Grid>
          <Grid item lg={2} md={2}>
            <div className="flex-column">
              <label className="text-12 title">Delivery Date</label>
              <div className="container-status">
                <LabelStatusMain value={deliveryDate} type={data.delivery_date_status_color} />
              </div>
            </div>
          </Grid>
          <Grid item>
            <div className="divider-hr-vertical" />
          </Grid>
          <Grid item lg={2} md={2}>
            <div className="flex-column">
              <label className="text-12 title">PO Number</label>
              <label className="text-12">{data.purchase_order_no}</label>
            </div>
          </Grid>
        </Grid>
        {data.terms && data.terms.length ? (
          <div className="terms-payment-detail-list">
            <Grid container justify="space-between" className="terms-list-row-haading-detail">
              <Grid item lg={3} md={3}>
                <div className="flex-column">
                  <label className="text-12 title">Payment Term</label>
                </div>
              </Grid>
              <Grid item>
                <div className="divider-hr-vertical" />
              </Grid>
              <Grid item lg={3} md={3}>
                <div className="flex-column">
                  <label className="text-12 title">Due Date</label>
                </div>
              </Grid>
              <Grid item>
                <div className="divider-hr-vertical" />
              </Grid>
              <Grid item lg={2} md={2}>
                <div className="flex-column">
                  <label className="text-12 title">Payment Status</label>
                </div>
              </Grid>
              <Grid item>
                <div className="divider-hr-vertical" />
              </Grid>
              <Grid item lg={2} md={2}>
                <div className="flex-column">
                  <label className="text-12 title text-right">Amount</label>
                </div>
              </Grid>
            </Grid>
            {data.terms.map((term, termIndex) => {
              const dueDate = CommonHelper.dateTimeParseNewFormat(term.due_date, 'DD MMMM YYYY');
              const amount = CommonHelper.formatCurrencyWithDecimals(term.amount);
              return (
                <Grid
                  container
                  justify="space-between"
                  key={termIndex}
                  className="terms-list-row-detail"
                >
                  <Grid item lg={3} md={3}>
                    <div className="flex-column">
                      <label className="text-12">Payment {termIndex + 1}</label>
                    </div>
                  </Grid>
                  <Grid item>
                    <div className="divider-hr-vertical" />
                  </Grid>
                  <Grid item lg={3} md={3}>
                    <div className="flex-column">
                      <label className="text-12">{dueDate}</label>
                    </div>
                  </Grid>
                  <Grid item>
                    <div className="divider-hr-vertical" />
                  </Grid>
                  <Grid item lg={2} md={2}>
                    <div className="flex-column">
                      <label className="text-12">{term.payment_status_description}</label>
                    </div>
                  </Grid>
                  <Grid item>
                    <div className="divider-hr-vertical" />
                  </Grid>
                  <Grid item lg={2} md={2}>
                    <div className="flex-column">
                      <label className="text-12 text-right">IDR {amount}</label>
                    </div>
                  </Grid>
                </Grid>
              );
            })}
          </div>
        ) : null}
      </>
    );
  };

  renderColumns = () => {
    const { orderData } = this.props;
    return [
      {
        title: 'Username',
        dataIndex: 'user_name',
        sorter: true,
        render: (text, row) => {
          return (
            <Grid
              container
              direction="column"
              onClick={() => this.handleClickCard(row.purchase_order_id)}
              className="container-row-purchase-order-review flex-wrap-unset"
            >
              <Grid item lg md className="container-info-header">
                <div className="flex-column">
                  <span className="flex-row">
                    <label className="text-14 title wrapping-container">{row.company_name}</label>
                    {this.renderStatusLabel(row.purchase_order_status)}
                  </span>
                  <label className="text-10">Customer</label>
                  <span className="flex-row mt-4">
                    <label className="text-10 mr-4">Purchase Order ID : </label>
                    <label className="text-10">{row.purchase_order_id}</label>
                  </span>
                </div>
              </Grid>
              <Grid item lg md className="container-info-body">
                <Grid container justify="flex-start">
                  <Grid item lg={12} md={12}>
                    {this.renderRowAdditional(row)}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          );
        },
      },
      {
        width: '15%',
        align: 'center',
        title: 'Last Modified',
        dataIndex: 'last_modified',
        sorter: true,
        render: (text, row) => {
          const lastModifyDate = CommonHelper.dateTimeParseNewFormat(
            row.updated_at,
            'DD MMM YYYY HH:mm',
          );
          return (
            <Grid item lg md className="container-info-header">
              <div className="flex-column">
                <label className="text-14-bold title wrapping-container">{row.created_by}</label>
                <label className="text-12 text-bold text-cornflowerBlue pt-12">
                  {lastModifyDate}
                </label>
              </div>
            </Grid>
          );
        },
      },
      {
        width: '15%',
        align: 'center',
        title: 'Order Value',
        dataIndex: 'order_value',
        sorter: true,
        render: (text, row) => {
          const amount = CommonHelper.formatCurrencyWithDecimals(row.grand_total);
          return (
            <label className="text-14 text-bold mb-4">{`${currencyCode.idr} ${amount}`}</label>
          );
        },
      },
      {
        align: 'center',
        render: (text, row) => {
          const actionOption = this.reAssignActionButton(
            row.purchase_order_status,
            row.user_id_created_by,
          );
          const isVisibleActionButton = !_.isEmpty(actionOption);

          return (
            isVisibleActionButton && (
              <ButtonMultipleMain
                options={actionOption}
                icon="ic-ffo-more-dot"
                type="secondary"
                size="xxl"
                loading={orderData.fetchingPrinting}
                onClick={item => this.handleClickButtonAction(item, row)}
              />
            )
          );
        },
      },
    ];
  };

  renderStatus = () => {
    const { pageTab } = this.props;
    let renderValue = null;

    switch (pageTab) {
      case 'delivery':
        renderValue = (
          <SelectInputMain
            options={PurchaseOrderStatusDelivery}
            currentValue={this.state.filter.purchaseOrderStatus}
            onChange={this.handleSelectPurchaseOrderStatus}
          />
        );
        break;
      case 'invoices':
        renderValue = (
          <SelectInputMain
            options={PurchaseOrderStatusInvoice}
            currentValue={this.state.filter.purchaseOrderStatus}
            onChange={this.handleSelectPurchaseOrderStatus}
          />
        );
        break;
      case 'completed':
        renderValue = (
          <SelectInputMain
            options={purchaseOrderStatusPOCompleted}
            currentValue={this.state.filter.purchaseOrderStatus}
            onChange={this.handleSelectPurchaseOrderStatus}
          />
        );
        break;
      default:
        renderValue = null;
        break;
    }

    return renderValue;
  };

  renderFilter() {
    return (
      <Grid container justify="flex-start" className="container-filter">
        <Grid item lg={4} md={4} className="row-filter">
          <TextInput
            iconPrefix="ic-ffo-search"
            placeHolderText="Search user"
            onChange={this.handleChangeSearch}
            size="md"
          />
        </Grid>
        <Grid item lg={2} md={2} className="row-filter">
          <div className="container-remove-margin">{this.renderStatus()}</div>
        </Grid>
        <Grid item lg md>
          <Grid container justify="flex-end" alignItems="center">
            <Grid item>
              <label className="text-14 pr-8">Showing Date</label>
            </Grid>
            <Grid item lg={4} md={4}>
              <PickerInputDate
                customIcon="ic-ffo-date-pick"
                dateFormat="dd/MM/yyyy"
                defaultValue={this.state.filter.startDate}
                onChange={this.handleChangeStartDate}
                toolbar={false}
              />
            </Grid>
            <Grid item>
              <label className="text-14 pr-8 pl-8"> - </label>
            </Grid>
            <Grid item lg={4} md={4}>
              <PickerInputDate
                customIcon="ic-ffo-date-pick"
                dateFormat="dd/MM/yyyy"
                minDate={this.state.filter.startDate}
                defaultValue={this.state.filter.endDate}
                onChange={this.handleChangeEndDate}
                toolbar={false}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  render() {
    const { orderData } = this.props;
    const {
      isLoading,
      pagination,
      isOpenModalDelete,
      modalDetailData,
      toastInformation,
      activeCanvasGeneratorPDF,
      buttonClickPrint,
      termOfPayment,
    } = this.state;

    const ListCustom = orderData.listPO;
    const tableLoading = orderData.fetching;
    const detailsPrint = orderData.detailsPrint;

    if (!_.isEmpty(orderData.detailsPrint) && !_.isEmpty(termOfPayment)) {
      Object.assign(detailsPrint, {
        termOfPayment: { ...termOfPayment },
      });
    }

    let renderElement = (
      <div className="mt-18">
        <SkeletonPagination />
      </div>
    );

    if (!isLoading) {
      renderElement = (
        <Grid container direction="column" className="flex-wrap-unset">
          <Grid item lg md sm className="section-page-header">
            {this.renderFilter()}
          </Grid>
          <Grid item lg md className="section-table-body">
            <Table
              columns={this.renderColumns()}
              rowKey={record => record.purchase_order_id}
              dataSource={ListCustom}
              pagination={pagination}
              loading={tableLoading}
              // scroll={{ y: adjustTableHeight }}
              onChange={this.handleTableChange}
            />
          </Grid>
        </Grid>
      );
    }
    return (
      <div className="container-page-purchase-order-review scroll-container">
        <div className="container-page-scrolling-area include-tab">{renderElement}</div>
        <SnackBarSimple
          open={toastInformation.isOpen}
          durationHide={2000}
          message={toastInformation.message}
          onClickClose={this.handleCloseToash}
          snackbarType={toastInformation.snackbarType}
          anchor={optionToash}
        />
        {isOpenModalDelete && (
          <ModalAlertAction
            onButtonSubmit={this.handleButtonSubmitDelete}
            onCloseModal={this.handleCloseModalDelete}
            modalDetail={modalDetailData}
            modalType="danger"
            buttonSubmitText="Submit"
            openModal={isOpenModalDelete}
          />
        )}
        {!orderData.fetchingPrinting && buttonClickPrint && (
          <GeneratorPdf isFuncOnly data={detailsPrint} customLabel={activeCanvasGeneratorPDF} />
        )}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getPOPagination: params => getPurchaseOrderListPagination(dispatch, params),
  deletePurchaseOrder: params => setDeletePurchaseOrder(dispatch, params),
  purchaseOrderPrint: (id, params) => getPurchaseOrderPrint(dispatch, id, params),
});

const mapStateToProps = ({ usersReducer, orderData, masterData }) => ({
  usersReducer,
  orderData,
  masterData,
});

PurchaseOrderPage.propTypes = {
  checkUserAccessPermission: PropTypes.func,
  checkUserAccessPermissionAction: PropTypes.func,
  deletePurchaseOrder: PropTypes.func,
  getPOPagination: PropTypes.func,
  history: PropTypes.object,
  onSetPrevStateValue: PropTypes.func,
  orderData: PropTypes.object,
  pageTab: PropTypes.string,
  purchaseOrderPrint: PropTypes.func,
  usersReducer: PropTypes.object,
};

const shell = compose(connect(mapStateToProps, mapDispatchToProps));

export default shell(PrevStateValue(PurchaseOrderPage));
