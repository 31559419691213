import { constants } from '../reducers/MasterDataMainReducer';

// #region company
export function createCompanyRequest() {
  return { type: constants.createCompanyRequest };
}
export function createCompanySuccess(response) {
  return { type: constants.createCompanySuccess, payload: response };
}
export function createCompanyFail(response) {
  return { type: constants.createCompanyFail, payload: response.data };
}

export function updateCompanyRequest() {
  return { type: constants.updateCompanyRequest };
}
export function updateCompanySuccess(response) {
  return { type: constants.updateCompanySuccess, payload: response };
}
export function updateCompanyFail(response) {
  return { type: constants.updateCompanyFail, payload: response.data };
}

export function deleteCompanyRequest() {
  return { type: constants.deleteCompanyRequest };
}
export function deleteCompanySuccess(response) {
  return { type: constants.deleteCompanySuccess, payload: response };
}
export function deleteCompanyFail(response) {
  return { type: constants.deleteCompanyFail, payload: response.data };
}

export function getListCompanyPaginationRequest() {
  return { type: constants.getListCompanyPaginationRequest };
}

export function getListCompanyPaginationSuccess(response) {
  return { type: constants.getListCompanyPaginationSuccess, payload: response };
}

export function getListCompanyPaginationFail(response) {
  return { type: constants.getListCompanyPaginationFail, payload: response.data };
}
// #endregion

// #region merchant
export function getListMerchantPaginationRequest() {
  return { type: constants.getListMerchantPaginationRequest };
}
export function getListMerchantPaginationSuccess(response) {
  return { type: constants.getListMerchantPaginationSuccess, payload: response };
}
export function getListMerchantPaginationFail(response) {
  return { type: constants.getListMerchantPaginationFail, payload: response.data };
}

export function deleteMerchantRequest() {
  return { type: constants.deleteMerchantRequest };
}
export function deleteMerchantSuccess(response) {
  return { type: constants.deleteMerchantSuccess, payload: response };
}
export function deleteMerchantFail(response) {
  return { type: constants.deleteMerchantFail, payload: response.data };
}

export function createMerchantRequest() {
  return { type: constants.createMerchantRequest };
}
export function createMerchantSuccess(response) {
  return { type: constants.createMerchantSuccess, payload: response };
}
export function createMerchantFail(response) {
  return { type: constants.createMerchantFail, payload: response.data };
}

export function updateMerchantRequest() {
  return { type: constants.updateMerchantRequest };
}
export function updateMerchantSuccess(response) {
  return { type: constants.updateMerchantSuccess, payload: response };
}
export function updateMerchantFail(response) {
  return { type: constants.updateMerchantFail, payload: response.data };
}

export function getMerchantDetailsRequest() {
  return { type: constants.getMerchantDetailsRequest };
}
export function getMerchantDetailsSuccess(response) {
  return { type: constants.getMerchantDetailsSuccess, payload: response };
}
export function getMerchantDetailsFail(response) {
  return { type: constants.getMerchantDetailsFail, payload: response.data };
}
// #endregion

// #region branch
export function createBranchRequest() {
  return { type: constants.createBranchRequest };
}
export function createBranchSuccess(response) {
  return { type: constants.createBranchSuccess, payload: response };
}
export function createBranchFail(response) {
  return { type: constants.createBranchFail, payload: response.data };
}

export function updateBranchRequest() {
  return { type: constants.updateBranchRequest };
}
export function updateBranchSuccess(response) {
  return { type: constants.updateBranchSuccess, payload: response };
}
export function updateBranchFail(response) {
  return { type: constants.updateBranchFail, payload: response.data };
}
export function getListBranchPaginationRequest() {
  return { type: constants.getListBranchPaginationRequest };
}
export function getListBranchPaginationSuccess(response) {
  return { type: constants.getListBranchPaginationSuccess, payload: response };
}
export function getListBranchPaginationFail(response) {
  return { type: constants.getListBranchPaginationFail, payload: response.data };
}

export function deleteBranchRequest() {
  return { type: constants.deleteBranchRequest };
}
export function deleteBranchSuccess(response) {
  return { type: constants.deleteBranchSuccess, payload: response };
}
export function deleteBranchFail(response) {
  return { type: constants.deleteBranchFail, payload: response.data };
}

export function getBranchDetailsRequest() {
  return { type: constants.getBranchDetailsRequest };
}
export function getBranchDetailsSuccess(response) {
  return { type: constants.getBranchDetailsSuccess, payload: response };
}
export function getBranchDetailsFail(response) {
  return { type: constants.getBranchDetailsFail, payload: response.data };
}
// #endregion

// #region Product Category
export function createProductCategoryRequest() {
  return { type: constants.createProductCategoryRequest };
}
export function createProductCategorySuccess(response) {
  return { type: constants.createProductCategorySuccess, payload: response };
}
export function createProductCategoryFail(response) {
  return { type: constants.createProductCategoryFail, payload: response.data };
}

export function updateProductCategoryRequest() {
  return { type: constants.updateProductCategoryRequest };
}
export function updateProductCategorySuccess(response) {
  return { type: constants.updateProductCategorySuccess, payload: response };
}
export function updateProductCategoryFail(response) {
  return { type: constants.updateProductCategoryFail, payload: response.data };
}

export function getProductCategoryDetailsRequest() {
  return { type: constants.getProductCategoryDetailsRequest };
}
export function getProductCategoryDetailsSuccess(response) {
  return { type: constants.getProductCategoryDetailsSuccess, payload: response };
}
export function getProductCategoryDetailsFail(response) {
  return { type: constants.getProductCategoryDetailsFail, payload: response.data };
}
// #endregion

// #region Product Wholesale
export function getProductWholesaleRequest() {
  return { type: constants.getProductWholesaleRequest };
}
export function getProductWholesaleSuccess(response) {
  return { type: constants.getProductWholesaleSuccess, payload: response };
}
export function getProductWholesaleFail(response) {
  return { type: constants.getProductWholesaleFail, payload: response };
}
// #endregion