import React from 'react';
import PropTypes from 'prop-types';
import { Page, View, Document, Image } from '@react-pdf/renderer';
// Style
import StylesJs from './CanvasShippingThreeRowStyle';
// assets
import { StyleJs as StyleJsGlobal } from '../../../../../assets/Index';

const CanvasShippingThreeRow = props => {
  const { data } = props;

  return (
    <Document>
      <Page size="A4" orientation="landscape">
        <View style={[StyleJsGlobal.gridRow, { width: 840 }]}>
          <View style={[StyleJsGlobal.gridItem4]} wrap={false}>
            <Image src={data} style={[StylesJs.pages]} />
          </View>
        </View>
      </Page>
    </Document>
  );
};

CanvasShippingThreeRow.propTypes = {
  data: PropTypes.string,
};

export default CanvasShippingThreeRow;
