import React from 'react';
import PropTypes from 'prop-types';
import { Steps } from 'antd';

const { Step } = Steps;

const NavigationStep = props => {
  const { arrayLabel, currentValue, size } = props;
  const [isActive, setActive] = React.useState(currentValue);

  React.useEffect(() => {
    if (currentValue || currentValue === 0) {
      setActive(currentValue);
    }
  }, [currentValue, setActive]);

  return (
    <Steps current={isActive} size={size}>
      {arrayLabel.map(item => (
        <Step key={item.key} title={item.name} />
      ))}
    </Steps>
  );
};

NavigationStep.propTypes = {
  arrayLabel: PropTypes.array,
  currentValue: PropTypes.number,
  size: PropTypes.string,
};

NavigationStep.defaultProps = {
  currentValue: 0,
  size: 'small',
};

export default NavigationStep;
