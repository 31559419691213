import * as InboxAction from '../../redux/actions/InboxAction';
import Api from '../../middleware/FetchMiddleware';
import { baseUrl } from '../../environments/Environment';
import { HttpAction } from '../../helpers/HttpHelper';

export const getInboxPagination = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(InboxAction.getInboxPaginationRequest());
    Api.callApi(`${baseUrl}private/pos/v2/inbox`, HttpAction.Get, params, {})
      .then(response => {
        dispatch(InboxAction.getInboxPaginationSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(InboxAction.getInboxPaginationFail(e));
        reject(e);
      });
  });
};

export const setDeleteInbox = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(InboxAction.setDeleteInboxRequest());
    const mergeParam = `${baseUrl}private/pos/v2/inbox/${params}`;
    Api.callApi(mergeParam, HttpAction.Delete, {}, {})
      .then(response => {
        dispatch(InboxAction.setDeleteInboxSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(InboxAction.setDeleteInboxFail(e));
        reject(e);
      });
  });
};

export const getInboxDetails = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(InboxAction.getInboxDetailsRequest());
    const mergeParam = `${baseUrl}private/pos/v2/inbox/${params}`;
    Api.callApi(mergeParam, HttpAction.Get, params, {})
      .then(response => {
        dispatch(InboxAction.getInboxDetailsSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(InboxAction.getInboxDetailsFail(e));
        reject(e);
      });
  });
};

export const setUpdateInbox = (dispatch, params, id) => {
  return new Promise((resolve, reject) => {
    dispatch(InboxAction.setUpdateInboxRequest());
    Api.callApi(`${baseUrl}private/pos/v2/inbox/${id}`, HttpAction.Patch, {}, params)
      .then(response => {
        dispatch(InboxAction.setUpdateInboxSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(InboxAction.setUpdateInboxFail(e));
        reject(e);
      });
  });
};

export const setCreateInbox = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(InboxAction.setCreateInboxRequest());
    Api.callApi(`${baseUrl}private/pos/v2/inbox`, HttpAction.Put, {}, params)
      .then(response => {
        dispatch(InboxAction.setCreateInboxSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(InboxAction.setCreateInboxFail(e));
        reject(e);
      });
  });
};

export const getInboxSendTest = (dispatch, id) => {
  return new Promise((resolve, reject) => {
    dispatch(InboxAction.getInboxSendTestRequest());
    Api.callApi(`${baseUrl}private/pos/v2/inbox/${id}/test`, HttpAction.Get, {}, {})
      .then(response => {
        dispatch(InboxAction.getInboxSendTestSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(InboxAction.getInboxSendTestFail(e));
        reject(e);
      });
  });
};
