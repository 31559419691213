import React from 'react';
import PropTypes from 'prop-types';
import { Page, Text, View, Document, Image } from '@react-pdf/renderer';
// Style
import StylesJs from './LabelShippingStyle';
// helper
import { GeneratorHelper } from '../../../../../helpers/Index';
// assets
import { Images } from '../../../../../assets/Index';

const LabelShipping = props => {
  const { data } = props;

  return (
    <Document>
      <Page size="A4">
        {data.map((item, index) => {
          item.airway_bill_number_img = item.airway_bill_number
            ? GeneratorHelper.textToBase64Barcode(item.airway_bill_number.toString())
            : '';
          item.external_order_id_img = item.external_order_id
            ? GeneratorHelper.textToBase64Barcode(item.external_order_id.toString())
            : '';
          let ShipMethod = Images.ShippingLogoJne;
          const recipient = item.recipient_name
            ? `${item.recipient_name} - ${item.recipient_phone}`
            : '-';

          switch (item.shipping_method) {
            case 'jne':
              ShipMethod = Images.ShippingLogoJne;
              break;

            default:
              break;
          }

          return (
            <View style={[StylesJs.containerColumn, { width: 520 }]} key={index} wrap={false}>
              <View style={[StylesJs.containerRow, StylesJs.alignItem, StylesJs.mb12]}>
                <Image
                  src={Images.shippingLogoFitco}
                  style={[StylesJs.containerLogo, StylesJs.ml17, StylesJs.mr54, StylesJs.pot10]}
                />
                <View style={[StylesJs.containerColumn, StylesJs.justifyContent, { flex: 1 }]}>
                  <Text style={[StylesJs.labelTextTitle, StylesJs.mb4, StylesJs.mt8]}>
                    W-ORDER ID
                  </Text>
                  <Image
                    src={item.external_order_id_img}
                    style={[StylesJs.containerBarcode, { flex: 1 }]}
                  />
                </View>
                <Image
                  src={ShipMethod}
                  style={[StylesJs.containerLogo, StylesJs.ml54, StylesJs.mr17, StylesJs.pot10]}
                />
              </View>
              <View style={[StylesJs.dividerHr]} />
              <View style={[StylesJs.alignItem]}>
                <View style={[StylesJs.containerColumn, StylesJs.p12, { width: 350 }]}>
                  <Text style={[StylesJs.labelTextTitle, StylesJs.mb8, { flex: 1 }]}>FROM</Text>
                  <Text style={[StylesJs.textSize10, StylesJs.mb12, StylesJs.containerWrapping]}>
                    {item.from_address || '-'}
                  </Text>
                  <Text style={[StylesJs.labelTextTitle, StylesJs.mb8, { flex: 1 }]}>TO</Text>
                  <Text
                    style={[
                      StylesJs.labelTextTitleRecipient,
                      StylesJs.containerWrapping,
                      StylesJs.mb4,
                    ]}
                  >
                    {recipient}
                  </Text>
                  <Text style={[StylesJs.textSize10, StylesJs.containerWrapping]}>
                    {item.delivery_address || '-'}
                  </Text>
                </View>
              </View>
              <View style={[StylesJs.dividerHr]} />
              <View style={[StylesJs.alignItem]}>
                <View
                  style={[
                    StylesJs.containerRow,
                    StylesJs.justifyContent,
                    StylesJs.p12,
                    { width: 300, flex: 1 },
                  ]}
                >
                  <View style={[StylesJs.containerColumn, { flex: 0.33 }]}>
                    <Text style={[StylesJs.labelTextTitle, StylesJs.mb4]}>CUSTOMER ID</Text>
                    <Text style={[StylesJs.textSize10, { textAlign: 'center' }]}>
                      {item.customer_id || '-'}
                    </Text>
                  </View>
                  <View style={[StylesJs.containerColumn, { flex: 0.33 }]}>
                    <Text style={[StylesJs.labelTextTitle, StylesJs.mb4]}>QTY</Text>
                    <Text style={[StylesJs.textSize10, { textAlign: 'center' }]}>
                      {item.item_quantity || '0'}
                    </Text>
                  </View>
                  <View style={[StylesJs.containerColumn, { flex: 0.33 }]}>
                    <Text style={[StylesJs.labelTextTitle, StylesJs.mb4]}>WEIGHT</Text>
                    <Text style={[StylesJs.textSize10, { textAlign: 'center' }]}>
                      {item.total_weight || '0'}KG
                    </Text>
                  </View>
                </View>
              </View>
              <View style={[StylesJs.containerDestination]}>
                <Text
                  style={[
                    StylesJs.textSize16,
                    StylesJs.fwBold,
                    StylesJs.mv4,
                    { textAlign: 'center' },
                  ]}
                >
                  {item.destination_code || '-'}
                </Text>
              </View>
              <View style={[StylesJs.dividerHr]} />
              <View style={[StylesJs.alignItem]}>
                <View style={[StylesJs.containerColumn, { width: 300 }]}>
                  <Text style={[StylesJs.labelTextTitle, StylesJs.mv8]}>AIRWAY BILL NUMBER</Text>
                  <Image
                    src={item.airway_bill_number_img}
                    style={[StylesJs.containerBarcode, { width: 200, alignSelf: 'center' }]}
                  />
                </View>
              </View>
            </View>
          );
        })}
      </Page>
    </Document>
  );
};

LabelShipping.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default LabelShipping;
